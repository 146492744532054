import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export type MediaQuery = {
    name: string;
    query: any;
};

export const withMediaQuery =
    (queries: MediaQuery[] = []) =>
    (Component: typeof React.Component) =>
    (props: any) => {
        const mediaProps: any = {};
        queries.forEach((q) => {
            mediaProps[q.name] = useMediaQuery(q.query);
        });
        return <Component {...mediaProps} {...props} />;
    };
