import { makeAutoObservable } from 'mobx';
import { Routing } from 'interfaces';

class RoutingSettingsVehicleTypesFormManager {
    private _vehicleTypes: Routing.Settings.VehicleTypes = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    initSettings(vehicleTypes: Routing.Settings.VehicleTypes) {
        this._vehicleTypes = new Map(vehicleTypes);
    }

    updateVehicleType(formData: Routing.Settings.VehicleTypeFormData) {
        const { vehicleTypeKey, vehicleType } = formData;
        this._vehicleTypes.set(vehicleTypeKey, vehicleType);
    }

    deleteVehicleType(vehicleTypeKey: string) {
        this._vehicleTypes.delete(vehicleTypeKey);
    }

    getUniqueNames(excludeKeys: string[]): Set<string> {
        const names = new Set<string>();
        for (let [key, vehicleType] of this._vehicleTypes.entries()) {
            if (excludeKeys.indexOf(key) !== -1) {
                continue;
            }
            names.add(vehicleType.name);
        }
        return names;
    }

    get vehicleTypes(): Routing.Settings.VehicleTypes {
        return this._vehicleTypes;
    }
}

export default RoutingSettingsVehicleTypesFormManager;
