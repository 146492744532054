import React, { RefObject } from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { userManager } from '../../../service/UserManager';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    AccountData,
    CalendarFixedTimeBooleanToEnumMap,
    CalendarFixedTimeEnumToBooleanMap,
    UserData,
    UserDataCalendarPreferences,
} from '../../../service/types';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import { User } from '../../../interfaces';
import { calendarPreferencesFields, ObjectivePicklistValue } from './fields';
import { calendarManager } from '../../../service/Calendar/CalendarManager';
import { Calendar } from '../../../interfaces/calendar/calendar';
import EditDefaultValuesBlock from '../../EditDefaultValuesBlock/EditDefaultValuesBlock';
import QuestionHint from '../../Hint/QuestionHint';
import { TimePicker } from '@material-ui/pickers';
import { WorkingHoursTime } from '../../types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import GoogleCalendarConnectionButton from './GoogleCalendarConnectionButton';
import usersPermissionsManager from '../../../service/Permissions/UsersPermissionsManager';

type CalendarPreferencesMode = 'personal' | 'default' | 'mass_edit';

interface CalendarPreferencesProps extends WithTranslation {
    user: UserData;
    account: AccountData;
    mode: CalendarPreferencesMode;
    ref: RefObject<CalendarPreferencesForm>;
}

interface CalendarPreferencesState {
    personalPreferences: UserDataCalendarPreferences;
    defaultPreferences: UserDataCalendarPreferences;
    chosenPreferences: Map<keyof UserDataCalendarPreferences, boolean>;
    errors: Map<keyof UserDataCalendarPreferences, string | null>;
    mode: CalendarPreferencesMode;
    showUpdatedAlert: boolean;
    calendars: Calendar[];
}

class CalendarPreferencesForm extends React.PureComponent<CalendarPreferencesProps, CalendarPreferencesState> {
    constructor(props: CalendarPreferencesProps) {
        super(props);

        this.state = {
            personalPreferences: props.user.id
                ? { ...props.user.calendarPreferences }
                : { ...props.account.defaultCalendarPreferences },
            defaultPreferences: { ...props.account.defaultCalendarPreferences },
            chosenPreferences: new Map(),
            errors: new Map(),
            mode: props.mode !== undefined ? props.mode : 'personal', // | 'default' | 'mass_edit'
            showUpdatedAlert: false,
            calendars: [],
        };
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_USER_CHANGED], this, (user: User.User) => {
            if (this.props.user && this.props.user.id === user.id && !this.state.showUpdatedAlert) {
                this.setState({ showUpdatedAlert: true });
            }
        });

        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendars();
        });

        this.updateCalendars();
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    updateCalendars = (): void => {
        calendarManager.get(this.props.account.id).then((res) => {
            this.setState({
                calendars: [...res.values()].filter((calendar: Calendar) => calendar.active),
            });
        });
    };

    handlePropertyChange = (
        e: React.ChangeEvent<
            HTMLInputElement | { name?: string | undefined; value: unknown; type?: string | undefined }
        >,
    ) => {
        const name = e.target.name;
        let value: any;
        if (e.target.type === 'checkbox') {
            // @ts-ignore
            value = e.target.checked;
        } else {
            value = e.target.value !== null ? e.target.value : null;
        }

        const preferencesPropertyName: keyof CalendarPreferencesState =
            this.state.mode === 'personal' ? 'personalPreferences' : 'defaultPreferences';

        if (name === 'fixedTimeAfterDrop') {
            value = CalendarFixedTimeEnumToBooleanMap.get(value);
        }

        // @ts-ignore
        this.setState({
            // @ts-ignore
            [preferencesPropertyName]: { ...this.state[preferencesPropertyName], [name]: value },
            // @ts-ignore
        } as Pick<CalendarPreferencesState, keyof CalendarPreferencesState>);
    };

    getAccountPreferences = () => {
        return this.state.defaultPreferences;
    };

    getUserPreferences = () => {
        return this.state.personalPreferences;
    };

    getChosenPreferences = () => {
        return this.state.chosenPreferences;
    };

    handleSwitchMode = () => {
        this.setState((state) => ({
            mode: state.mode === 'personal' ? 'default' : 'personal',
        }));
    };

    isValid = () => {
        const { mode, chosenPreferences } = this.state;
        const errors = this.state.errors;

        if (mode === 'mass_edit') {
            const errorsOfSelectedFields = new Map();
            for (const field of chosenPreferences.keys()) {
                const fieldsToCheckErrorsMap = [];
                fieldsToCheckErrorsMap.push(field);

                for (const errorField of fieldsToCheckErrorsMap) {
                    // @ts-ignore
                    if (chosenPreferences.get(field) === true && errors.has(errorField)) {
                        // @ts-ignore
                        errorsOfSelectedFields.set(errorField, errors.get(errorField));
                    }
                }
            }
            return errorsOfSelectedFields.size === 0;
        }

        return errors.size === 0;
    };

    renderPreferencesBlock = (
        preferenceKey: keyof UserDataCalendarPreferences,
        children: JSX.Element,
        title?: string,
    ) => {
        // @ts-ignore
        const { mode, chosenPreferences } = this.state;
        if (mode !== 'mass_edit') {
            return children;
        }

        const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.currentTarget.checked;
            const newChosenPreferences = new Map(chosenPreferences);
            newChosenPreferences.set(preferenceKey, checked);
            this.setState({ chosenPreferences: newChosenPreferences });
        };

        const isActive = Boolean(chosenPreferences.get(preferenceKey));

        return (
            <Grid container alignItems="center">
                <Grid container item xs={4} alignItems="center" wrap="nowrap">
                    <Checkbox onChange={onCheckboxChange} checked={isActive} color="primary" />
                    {title}
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={7}>
                    {children}
                </Grid>
            </Grid>
        );
    };

    private hasPreferenceChosen = (preferenceKey: keyof UserDataCalendarPreferences) => {
        const { mode, chosenPreferences } = this.state;
        if (mode === 'mass_edit') {
            return chosenPreferences.get(preferenceKey);
        }
        return true;
    };

    private getTimelineStartAtDate = (timelineStartAt: WorkingHoursTime | null): Date | null => {
        if (!timelineStartAt) {
            return null;
        }

        const timelineStartAtDate = new Date();
        timelineStartAtDate.setHours(timelineStartAt.hours, timelineStartAt.minutes, 0, 0);

        return timelineStartAtDate;
    };

    private handleTimelineStartAtChange = (date: MaterialUiPickersDate): void => {
        const preferencesPropertyName = this.state.mode === 'personal' ? 'personalPreferences' : 'defaultPreferences';

        this.setState((state: CalendarPreferencesState) => {
            return {
                ...state,
                [preferencesPropertyName]: {
                    ...state[preferencesPropertyName],
                    timelineStartAt: date ? { hours: date.getHours(), minutes: date.getMinutes() } : null,
                },
            };
        });
    };

    private renderOptimizationObjectiveMenuItem(objective: ObjectivePicklistValue) {
        const sum = objective.sum ?? '';
        const desc = objective.desc ?? '';
        return (
            <MenuItem key={objective.value} value={objective.value} style={{ display: 'block', maxWidth: '800px' }}>
                <Typography variant="body2" style={{ display: 'block', fontWeight: 'bold' }}>
                    {objective.label}
                </Typography>
                {sum && (
                    <Typography variant="caption" style={{ display: 'block', whiteSpace: 'normal' }}>
                        {sum}
                    </Typography>
                )}
                {desc && (
                    <Typography variant="caption" style={{ display: 'block', whiteSpace: 'normal' }}>
                        {desc}
                    </Typography>
                )}
            </MenuItem>
        );
    }

    render() {
        const { t, user } = this.props;
        const { personalPreferences, defaultPreferences, errors, mode, calendars } = this.state;
        const preferences = mode === 'personal' ? personalPreferences : defaultPreferences;

        const timelineStartAtDate = this.getTimelineStartAtDate(preferences.timelineStartAt);
        const userHasModifyCalendarPreferencesPermission =
            usersPermissionsManager.hasCalendarModifyPreferencesPermission;

        const optimizationObjectives = calendarPreferencesFields(t).getOptimizationObjectives();

        return (
            <div>
                {userManager.isRoleAdmin() && userHasModifyCalendarPreferencesPermission && mode === 'personal' && (
                    <div>
                        <Button color="primary" onClick={this.handleSwitchMode}>
                            {t('users_calendar_preferences.modal.default_link')}
                        </Button>
                    </div>
                )}
                {mode === 'default' && (
                    <EditDefaultValuesBlock
                        mainText={t('route_editor.modal.routing_preferences.modal.default_settings_text')}
                        dottedText={t('route_editor.modal.routing_preferences.modal.return_to_personal_edit')}
                        onSwitch={this.handleSwitchMode}
                    />
                )}

                {mode === 'personal' && userManager.getCurrentUser()?.id === user.id && (
                    <GoogleCalendarConnectionButton />
                )}

                {this.renderPreferencesBlock(
                    'defCalendarUuid',
                    <div className="calendar-preferences-hint-input-block">
                        <FormControl
                            fullWidth
                            margin="dense"
                            error={errors.hasOwnProperty('defCalendarUuid')}
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('defCalendarUuid')
                            }
                        >
                            <InputLabel>{t('users_calendar_preferences.modal.title.preferred_calendar')}</InputLabel>
                            <Select
                                name="defCalendarUuid"
                                value={preferences.defCalendarUuid || ''}
                                onChange={this.handlePropertyChange}
                            >
                                <MenuItem value="">
                                    {t('users_calendar_preferences.modal.title.preferred_calendar.none')}
                                </MenuItem>
                                {calendars.map((calendar) => (
                                    <MenuItem value={calendar.id}>{calendar.name}</MenuItem>
                                ))}
                            </Select>
                            {errors.hasOwnProperty('defCalendarUuid') && (
                                <FormHelperText>{errors.get('defCalendarUuid')}</FormHelperText>
                            )}
                        </FormControl>
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.preferred_calendar')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.preferred_calendar'),
                )}

                <div>
                    {this.renderPreferencesBlock(
                        'autoRoundingEventStartTime',
                        <div className="calendar-preferences-hint-checkbox-block">
                            <FormControlLabel
                                disabled={
                                    !userHasModifyCalendarPreferencesPermission ||
                                    !this.hasPreferenceChosen('autoRoundingEventStartTime')
                                }
                                control={
                                    <Checkbox
                                        checked={preferences.autoRoundingEventStartTime}
                                        onChange={this.handlePropertyChange}
                                        name="autoRoundingEventStartTime"
                                        color="primary"
                                    />
                                }
                                label={t('users_calendar_preferences.modal.title.auto_rounding_event_start_time_step')}
                            />
                            <QuestionHint>
                                {t('users_calendar_preferences.modal.hint.auto_rounding_event_start_time_step')}
                            </QuestionHint>
                        </div>,
                        t('users_calendar_preferences.mass_modal.title.auto_rounding_event_start_time_step'),
                    )}

                    {preferences.autoRoundingEventStartTime && (
                        <div>
                            {this.renderPreferencesBlock(
                                'roundingStep',
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    error={errors.hasOwnProperty('roundingStep')}
                                    style={{ padding: '0 20px' }}
                                    disabled={
                                        !userHasModifyCalendarPreferencesPermission ||
                                        !this.hasPreferenceChosen('roundingStep')
                                    }
                                >
                                    <InputLabel>{t('users_calendar_preferences.modal.title.rounding_step')}</InputLabel>
                                    <Select
                                        name="roundingStep"
                                        value={preferences.roundingStep}
                                        onChange={this.handlePropertyChange}
                                    >
                                        {calendarPreferencesFields(t)
                                            .getRoundingSteps()
                                            .map((mode) => (
                                                <MenuItem value={mode.value}>{mode.label} </MenuItem>
                                            ))}
                                    </Select>
                                    {errors.hasOwnProperty('roundingStep') && (
                                        <FormHelperText>{errors.get('roundingStep')}</FormHelperText>
                                    )}
                                </FormControl>,
                                t('users_calendar_preferences.mass_modal.title.rounding_step'),
                            )}

                            {this.renderPreferencesBlock(
                                'roundLunchStartTime',
                                <div className="calendar-preferences-hint-checkbox-block">
                                    <FormControlLabel
                                        style={{ padding: '0 0 0 20px' }}
                                        disabled={
                                            !userHasModifyCalendarPreferencesPermission ||
                                            !this.hasPreferenceChosen('roundLunchStartTime')
                                        }
                                        control={
                                            <Checkbox
                                                checked={preferences.roundLunchStartTime}
                                                onChange={this.handlePropertyChange}
                                                name="roundLunchStartTime"
                                                color="primary"
                                            />
                                        }
                                        label={t('users_calendar_preferences.modal.title.auto_round_lunch_start_time')}
                                    />
                                    <QuestionHint>
                                        {t('users_calendar_preferences.modal.hint.auto_round_lunch_start_time')}
                                    </QuestionHint>
                                </div>,
                                t('users_calendar_preferences.mass_modal.title.auto_round_lunch_start_time'),
                            )}
                        </div>
                    )}
                </div>

                {this.renderPreferencesBlock(
                    'fixedTimeAfterDrop',
                    <div className="calendar-preferences-hint-input-block">
                        <FormControl
                            fullWidth
                            margin="dense"
                            error={errors.hasOwnProperty('fixedTimeAfterDrop')}
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('fixedTimeAfterDrop')
                            }
                        >
                            <InputLabel>{t('users_calendar_preferences.modal.title.fixed_time_after_drop')}</InputLabel>
                            <Select
                                name="fixedTimeAfterDrop"
                                value={CalendarFixedTimeBooleanToEnumMap.get(preferences.fixedTimeAfterDrop)}
                                onChange={this.handlePropertyChange}
                            >
                                {calendarPreferencesFields(t)
                                    .getFieldsFixedFlexible()
                                    .map((mode, idx) => (
                                        <MenuItem key={idx} value={mode.value}>
                                            {mode.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {errors.hasOwnProperty('fixedTimeAfterDrop') && (
                                <FormHelperText>{errors.get('fixedTimeAfterDrop')}</FormHelperText>
                            )}
                        </FormControl>
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.fixed_time_after_drop')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.fixed_time_after_drop'),
                )}

                {this.renderPreferencesBlock(
                    'fixedTimeAfterOptimization',
                    <div className="calendar-preferences-hint-checkbox-block">
                        <FormControlLabel
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('fixedTimeAfterOptimization')
                            }
                            control={
                                <Checkbox
                                    checked={preferences.fixedTimeAfterOptimization}
                                    onChange={this.handlePropertyChange}
                                    name="fixedTimeAfterOptimization"
                                    color="primary"
                                />
                            }
                            label={t('users_calendar_preferences.modal.title.fixed_time_after_optimization')}
                        />
                        <QuestionHint>
                            {t('users_calendar_preferences.modal.hint.fixed_time_after_optimization')}
                        </QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.fixed_time_after_optimization'),
                )}

                {this.renderPreferencesBlock(
                    'showUserAvatars',
                    <div className="calendar-preferences-hint-checkbox-block">
                        <FormControlLabel
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('showUserAvatars')
                            }
                            control={
                                <Checkbox
                                    checked={preferences.showUserAvatars}
                                    onChange={this.handlePropertyChange}
                                    name="showUserAvatars"
                                    color="primary"
                                />
                            }
                            label={t('users_calendar_preferences.modal.title.show_avatar')}
                        />
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.show_avatar')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.show_avatar'),
                )}

                {this.renderPreferencesBlock(
                    'showTooltip',
                    <div className="calendar-preferences-hint-checkbox-block">
                        <FormControlLabel
                            disabled={
                                !userHasModifyCalendarPreferencesPermission || !this.hasPreferenceChosen('showTooltip')
                            }
                            control={
                                <Checkbox
                                    checked={preferences.showTooltip}
                                    onChange={this.handlePropertyChange}
                                    name="showTooltip"
                                    color="primary"
                                />
                            }
                            label={t('users_calendar_preferences.modal.title.show_event_tooltip')}
                        />
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.show_event_tooltip')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.show_event_tooltip'),
                )}

                {this.renderPreferencesBlock(
                    'firstDayOfWeek',
                    <div className="calendar-preferences-hint-input-block">
                        <FormControl
                            fullWidth
                            margin="dense"
                            error={errors.hasOwnProperty('firstDayOfWeek')}
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('firstDayOfWeek')
                            }
                        >
                            <InputLabel>{t('users_calendar_preferences.modal.title.first_day_of_week')}</InputLabel>
                            <Select
                                name="firstDayOfWeek"
                                value={preferences.firstDayOfWeek}
                                onChange={this.handlePropertyChange}
                            >
                                {calendarPreferencesFields(t)
                                    .getWeekDays()
                                    .map((mode) => (
                                        <MenuItem value={mode.value}>{mode.label}</MenuItem>
                                    ))}
                            </Select>
                            {errors.hasOwnProperty('firstDayOfWeek') && (
                                <FormHelperText>{errors.get('firstDayOfWeek')}</FormHelperText>
                            )}
                        </FormControl>
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.first_day_of_week')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.first_day_of_week'),
                )}

                {this.renderPreferencesBlock(
                    'timelineStartAt',
                    <div className="calendar-preferences-hint-input-block">
                        <FormControl fullWidth margin="dense" error={errors.hasOwnProperty('timelineStartAt')}>
                            <TimePicker
                                autoOk={true}
                                value={timelineStartAtDate}
                                onChange={this.handleTimelineStartAtChange}
                                label={t('users_calendar_preferences.modal.title.timeline_start_at')}
                                disabled={
                                    !userHasModifyCalendarPreferencesPermission ||
                                    !this.hasPreferenceChosen('timelineStartAt')
                                }
                                clearable
                            />
                            {errors.hasOwnProperty('timelineStartAt') && (
                                <FormHelperText>{errors.get('timelineStartAt')}</FormHelperText>
                            )}
                        </FormControl>
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.timeline_start_at')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.timeline_start_at'),
                )}

                {this.renderPreferencesBlock(
                    'optimizationObjective',
                    <div className="calendar-preferences-hint-input-block">
                        <FormControl
                            fullWidth
                            margin="dense"
                            error={errors.hasOwnProperty('optimizationObjective')}
                            disabled={
                                !userHasModifyCalendarPreferencesPermission ||
                                !this.hasPreferenceChosen('optimizationObjective')
                            }
                        >
                            <InputLabel>
                                {t('users_calendar_preferences.modal.title.optimization_objective')}
                            </InputLabel>
                            <Select
                                name="optimizationObjective"
                                value={preferences.optimizationObjective}
                                onChange={this.handlePropertyChange}
                                renderValue={(selected) => {
                                    const objective = optimizationObjectives.find(
                                        (objective) => objective.value === selected,
                                    );
                                    return objective ? objective.label : '';
                                }}
                            >
                                {optimizationObjectives.map((objective) => {
                                    return this.renderOptimizationObjectiveMenuItem(objective);
                                })}
                            </Select>
                            {errors.hasOwnProperty('firstDayOfWeek') && (
                                <FormHelperText>{errors.get('firstDayOfWeek')}</FormHelperText>
                            )}
                        </FormControl>
                        <QuestionHint>{t('users_calendar_preferences.modal.hint.optimization_objective')}</QuestionHint>
                    </div>,
                    t('users_calendar_preferences.mass_modal.title.optimization_objective'),
                )}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(CalendarPreferencesForm);
