import React from 'react';
import clsx from 'clsx';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { FormatTypeWithPrefix } from 'types';

const textFieldStyles = (theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '100%',
            '& label': {
                color: theme.palette.textField.tertiary,
                '&+ .MuiInput-formControl': {
                    marginTop: '12px',
                },
            },
            '& .MuiInput-formControl': {
                ...theme.typography.bodyM,
                '& .MuiInput-input': {
                    height: 'auto',
                    padding: '6px 0px',
                    textOverflow: 'ellipsis',
                    '&::placeholder': {
                        color: theme.palette.textField.tertiary,
                        opacity: 1,
                    },
                },
                '&:before': {
                    borderBottom: `1px solid ${theme.palette.textField.secondary.main}`,
                },
                '&:hover:before': {
                    borderBottom: `1px solid ${theme.palette.textField.secondary.dark}`,
                },
                '&:after': {
                    borderBottom: `1px solid ${theme.palette.textField.primary}`,
                },
            },

            '& .MuiInput-formControl.Mui-disabled': {
                color: theme.palette.textField.tertiary,
                '&:before, &:hover:before': {
                    borderBottom: `1px solid ${theme.palette.textField.secondary.light}`,
                },
            },
        },
        typePrimary: {
            '& label.Mui-focused': {
                color: theme.palette.textField.primary,
            },
            '& .MuiInput-formControl': {
                color: theme.palette.textField.primary,
            },
        },
        typeSecondary: {
            '& label.Mui-focused': {
                color: theme.palette.textField.secondary.dark,
            },
            '& .MuiInput-formControl': {
                color: theme.palette.textField.secondary.dark,
            },
        },
    });

type TextFieldType = 'primary' | 'secondary';

type TypeClasses = FormatTypeWithPrefix<'type', TextFieldType>;

type TextFieldProps = Omit<MUITextFieldProps, 'size'> &
    WithStyles<typeof textFieldStyles> & {
        type: TextFieldType;
        className?: string;
        parentObserver?: HTMLElement;
    };

class TextField extends React.PureComponent<TextFieldProps> {
    static defaultProps: Pick<TextFieldProps, 'type'>;

    render() {
        const { type, className, classes, ...props } = this.props;

        const typeClass = `type${type[0].toUpperCase() + type.slice(1)}` as TypeClasses;

        return <MUITextField {...props} className={clsx('textField', classes.root, classes[typeClass], className)} />;
    }
}

TextField.defaultProps = {
    type: 'secondary',
};

export default withStyles(textFieldStyles)(TextField);
