import React from 'react';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import RoutingServiceError from 'components/RouteEditor/RoutingServiceError';
import {
    enqueueSnackbarService,
    routeEditorManager,
    routeManager,
    routeViewerManager,
    routingSessionManager,
    TripModeStorage,
} from 'service/MapPage';
import { TripModeConfig } from 'service/types';
import { GeocoderApiCustomError, PointPositions } from 'components/types';
import dispatcher from 'service/dispatcher';
import events from '../../../events';
import { Geo, Routing } from 'interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { differenceInSeconds } from 'date-fns';

const DEFAULT_GROUP_INDEX = 1;

const excludeTypes = [Routing.Route.ActivityType.START, Routing.Route.ActivityType.END];

export const defaultTripModeConfig: Readonly<TripModeConfig> = {
    routeId: null,
    isTripStarted: false,
    currentGroupIndex: DEFAULT_GROUP_INDEX,
    areOnlyRoutePointsDisplayed: true,
};

type TripModeManagerKeys = 'config' | '_route' | 'setGroupIndex';

class TripModeManager {
    private readonly storage: TripModeStorage;
    private config: TripModeConfig;
    private _route: Routing.Route.Route | null = null;

    constructor(storage: TripModeStorage) {
        makeObservable<TripModeManager, TripModeManagerKeys>(this, {
            config: observable,
            _route: observable,

            route: computed,
            currentActivity: computed,
            countActivities: computed,
            isTripStarted: computed,

            startTrip: action,
            endTrip: action,
            reset: action,
            currentGroupIndex: computed,
            setNextGroupIndex: action,
            setPreviousGroupIndex: action,
            setGroupIndex: action,
            areOnlyRoutePointsDisplayed: computed,
            toggleAreOnlyRoutePointsDisplayed: action,
            visitActivityPoint: action,
            currentActivityJobId: computed,
            isLastActivity: computed,
            findActivityByJobId: action,
        });
        this.storage = storage;
        this.config = { ...defaultTripModeConfig };
    }

    startTrip(route: Routing.Route.Route): void {
        const shouldCenterCurrentRouteSegment = !this.config.isTripStarted;
        this.config = {
            ...this.loadFromStorage(route.id),
            routeId: route.id,
            isTripStarted: true,
        };
        this._route = this.getUpdatedRoute(route);

        if (shouldCenterCurrentRouteSegment) {
            this.centerCurrentRouteSegment();
        }
        this.storage.save(this.config);
        routeViewerManager.enableRoutesFilter('trip_mode', [route]);
        dispatcher.dispatch(events.EVENT_TRIP_STARTED);
        dispatcher.subscribe(
            events.WS_POINT_MARK_AS_VISITED,
            this,
            async (data: { activity: Routing.Route.Activity }) => {
                await this.visitActivityPoint(data.activity);
            },
        );
    }

    endTrip(route: Routing.Route.Route): void {
        if (!this.config.isTripStarted) {
            return;
        }
        if (this.route.id !== route.id) {
            throw new Error('The trip started on a different route.');
        }

        this.reset();
        dispatcher.dispatch(events.EVENT_TRIP_ENDED);
        dispatcher.unsubscribe(events.WS_POINT_MARK_AS_VISITED, this);
    }

    reset(): void {
        this._route = null;
        this.config = {
            routeId: null,
            currentGroupIndex: DEFAULT_GROUP_INDEX,
            isTripStarted: false,
            areOnlyRoutePointsDisplayed: true,
        };
        this.storage.save(this.config);
        routeViewerManager.disableRoutesFilter('trip_mode');
    }

    async refreshRoute(): Promise<void> {
        routeEditorManager.setIsChanging(true);
        try {
            const activitiesIdsForRefresh = this.getActivitiesIdsForRefresh(null);
            await routeManager.refreshRoute(this.route, activitiesIdsForRefresh);
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    async visitActivityPoint(activity: Routing.Route.Activity): Promise<void> {
        routeEditorManager.setIsChanging(true);

        if (this.countActivities <= 2) {
            await routingSessionManager.endTrip(this.route);
            routeEditorManager.setIsChanging(false);
            dispatcher.dispatch(events.EVENT_TRIP_COMPLETED);
            return;
        }

        const oldIndex = this.currentGroupIndex;
        try {
            const visitedIndex = this.getVisitedActivityIndex(activity);
            const activitiesIdsForRefresh = this.getActivitiesIdsForRefresh(activity);

            if (this.route.activities.length - 1 === oldIndex) {
                this.setGroupIndex(oldIndex - 1); // set previous group index to avoid errors in the case of the last point
            }
            await routeManager.refreshRoute(this.route, activitiesIdsForRefresh, true);

            const nextIndex = this.calcNextIndex(this.route.activities.length, this.currentGroupIndex, visitedIndex);
            this.setGroupIndex(nextIndex);
            this.centerCurrentRouteSegment();
        } catch (error) {
            this.setGroupIndex(oldIndex);

            if (error instanceof GeocoderApiCustomError) {
                const message = React.createElement(RoutingServiceError);
                enqueueSnackbarService.sendErrorMessage(message);
            } else {
                enqueueSnackbarService.sendErrorMessage(error.message);
            }
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    private getActivitiesIdsForRefresh(excludeActivity: Routing.Route.Activity | null): string[] {
        const result: string[] = [];

        this.route.activities.forEach((activity) => {
            if (excludeTypes.includes(activity.type)) {
                return;
            }
            if (excludeActivity !== null && activity.id === excludeActivity.id) {
                return;
            }

            result.push(activity.id);
        });

        return result;
    }

    get isTripStarted(): boolean {
        return this.config.isTripStarted;
    }

    get currentGroupIndex(): number {
        return this.config.currentGroupIndex;
    }

    setNextGroupIndex() {
        this.config.currentGroupIndex++;
        this.storage.save(this.config);
    }

    setPreviousGroupIndex() {
        this.config.currentGroupIndex--;
        this.storage.save(this.config);
    }

    private setGroupIndex(index: number): void {
        this.config.currentGroupIndex = index;
        this.storage.save(this.config);
    }

    private calcNextIndex(countUnvisitedActivities: number, currentIndex: number, visitedIndex: number): number {
        if (visitedIndex < currentIndex) {
            return currentIndex - 1;
        }
        if (visitedIndex > currentIndex) {
            return currentIndex;
        }
        const lastIndex = countUnvisitedActivities - 1;
        if (currentIndex <= lastIndex) {
            return currentIndex;
        }
        return lastIndex;
    }

    private loadFromStorage(routeId: string): TripModeConfig {
        const config = this.storage.load();
        if (config.routeId === routeId) {
            return config;
        }
        return this.storage.getDefaultConfig();
    }

    centerCurrentRouteSegment(): void {
        const { activities } = this.route;
        const firstPoint = activities[this.config.currentGroupIndex - 1];
        const secondPoint = activities[this.config.currentGroupIndex];
        if (!firstPoint || !secondPoint) {
            return;
        }

        const data = [TripModeManager.getPointPositions(firstPoint), TripModeManager.getPointPositions(secondPoint)];
        dispatcher.dispatch(events.EVENT_TRIP_CENTER_CURRENT_ROUTE_SEGMENT, data);
    }

    private static getPointPositions(point: Geo.GeoPoint): PointPositions {
        return {
            position: [point.lat, point.lng],
        };
    }

    get areOnlyRoutePointsDisplayed(): boolean {
        return this.config.areOnlyRoutePointsDisplayed;
    }

    get route(): Routing.Route.Route {
        if (this._route === null) {
            throw new Error('Trip mode is not started.');
        }

        return toJS(this._route);
    }

    get currentActivity(): Routing.Route.Activity | undefined {
        return this.route.activities[this.currentGroupIndex];
    }

    get countActivities(): number {
        return this.route.activities.length;
    }

    toggleAreOnlyRoutePointsDisplayed() {
        this.config.areOnlyRoutePointsDisplayed = !this.config.areOnlyRoutePointsDisplayed;
        this.storage.save(this.config);
        dispatcher.dispatch(events.EVENT_TRIP_ONLY_ROUTE_POINTS_SHOW_TOGGLED);
    }

    getEarlyLateTimeForActivity(activityId: string): number | undefined {
        if (!this._route) {
            return;
        }

        return this._route.activities.find((activity: Routing.Route.Activity) => activity.id === activityId)
            ?.earlyLateTime;
    }

    private getVisitedActivityIndex(visitActivity: Routing.Route.Activity): number {
        return this.route.activities.findIndex((activity) => {
            if (excludeTypes.includes(activity.type)) {
                return false;
            }
            return activity.id === visitActivity.id;
        });
    }

    private getUpdatedRoute(route: Routing.Route.Route): Routing.Route.Route {
        const newRoute = cloneDeep(route);

        newRoute.activities.forEach((activity: Routing.Route.Activity) => {
            if (!activity.expectedArrivalTime || !activity.arrivalTime || excludeTypes.includes(activity.type)) {
                return;
            }

            if (activity.expectedArrivalTime.getTime() === activity.arrivalTime.getTime()) {
                return;
            }

            activity.earlyLateTime = differenceInSeconds(activity.expectedArrivalTime, activity.arrivalTime);
        });

        return newRoute;
    }

    get currentActivityJobId(): string | undefined {
        if (!this.isTripStarted) {
            return undefined;
        }
        return this.currentActivity ? this.currentActivity.job?.id : undefined;
    }

    get isLastActivity(): boolean | undefined {
        if (!this.isTripStarted) {
            return undefined;
        }
        return this.currentActivity?.type === Routing.Route.ActivityType.END;
    }

    public findActivityByJobId(jobId: string): Routing.Route.Activity | undefined {
        if (!this.isTripStarted) {
            return undefined;
        }
        return this.route.activities.find((activity) => activity.job?.id === jobId);
    }
}

export default TripModeManager;
