import { userManager } from '../UserManager';

const STORAGE_KEY_PREFIX = 'mapsly.location_tracking_message.';
const WAS_SHOWN_KEY = 'was_shown';
const WAS_ACCEPTED_KEY = 'was_accepted';
const STRING_VALUE_FOR_TRUE = 'true';

class LocationTrackingMessageStorage {
    getStorageKeyPrefix(): string {
        return STORAGE_KEY_PREFIX + userManager.getCurrentUser().id;
    }

    private rememberActionWasAppeared(key: string) {
        window.localStorage.setItem(this.getStorageKeyPrefix() + key, STRING_VALUE_FOR_TRUE);
    }

    private didActionAppeared(key: string): boolean {
        return window.localStorage.getItem(this.getStorageKeyPrefix() + key) === STRING_VALUE_FOR_TRUE;
    }

    setWasShown() {
        this.rememberActionWasAppeared(WAS_SHOWN_KEY);
    }

    wasShown(): boolean {
        return this.didActionAppeared(WAS_SHOWN_KEY);
    }

    setWasAccepted() {
        this.rememberActionWasAppeared(WAS_ACCEPTED_KEY);
    }

    wasAccepted(): boolean {
        return this.didActionAppeared(WAS_ACCEPTED_KEY);
    }
}

export const locationTrackingMessageStorage = new LocationTrackingMessageStorage();
