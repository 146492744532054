import merge from 'lodash/merge';
import { EnvironmentParams } from './types';

const params: EnvironmentParams = {
    crypto_key: '',
    apiEndpoint: '',
    sentryDSN: '',
    environment: 'local',
    frontAppHost: '',
    wsEndpoint: '',
    wsPath: '',
    version: null,
    recaptchaSiteKey: '',

    defaultPin: {
        color: '#333',
        size: 20,
        fontSize: 12,
        fontWeight: 400,
        fontColor: '#000000',
    },

    techSupportEmail: 'mapsly@sgmsoft.com',

    google: {
        picker: {
            appId: '',
            clientId: '',
            apiKey: '',
        },
    },
};

merge(params, window.params);

export default params;
