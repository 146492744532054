import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../events';
import dispatcher from './dispatcher';

class RoleManager extends BackendService {
    constructor() {
        super();

        this.roles = new Map();
        this.requestedAccounts = new Set();

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.roles.clear();
            this.requestedAccounts.clear();
        });
    }

    getAccountRoles(accountId, fresh) {
        if (!fresh && this.requestedAccounts.has(accountId)) {
            const result = [];
            for (let role of this.roles.values()) {
                if (parseInt(role.accountId) === parseInt(accountId)) {
                    result.push(role);
                }
            }
            return this.constructor.getResolvedPromise(result);
        }

        const url = reverse(apiRoutes.account.roles.index, { accountId: accountId });

        return this.requestApi(url, 'GET').then((roles) => {
            this.requestedAccounts.add(accountId);
            for (let role of roles) {
                this.roles.set(role.id, role);
            }
            return roles;
        });
    }

    getSystemRoles() {
        return [
            {
                id: 1,
                name: 'Superadmin',
                code: 'superadmin',
                accountId: null,
            },
        ];
    }

    saveRolesAndPermissions(accountId, roles, permissions) {
        const url = reverse(apiRoutes.account.roles.withPermissions, { accountId: accountId });
        return this.requestApi(url, 'PUT', { roles, permissions }).then((response) => {
            const roles = response.roles;
            this.roles = new Map();
            for (let role of roles) {
                this.roles.set(role.id, role);
            }
            dispatcher.dispatch(events.EVENT_ROLES_CHANGED, response);
            dispatcher.dispatch(events.EVENT_ACCOUNT_PERMISSIONS_SAVED, accountId);
            return response;
        });
    }

    initRoleUIFeaturesPermissions(accountId, isForSharedMap) {
        const url = reverse(apiRoutes.account.roles.initRoleUIFeaturesPermissionsAction, { accountId: accountId });
        return this.requestApi(url, 'GET', { shared: isForSharedMap });
    }
}

export const roleManager = new RoleManager();
