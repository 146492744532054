// import events from "../events";
// import dispatcher from "./dispatcher";

export class PopupError extends Error {
    constructor(message) {
        if (message === undefined) {
            message = 'Popup window was blocked by your browser. Please enable it.';
        }
        super(message);
    }
}

class PopupJob {
    constructor(url) {
        this.url = url;
        this.sessionContent = null;
    }

    hasSession(sessionId) {
        return this.sessionContent && this.sessionContent.id === sessionId;
    }

    finishSession() {
        if (this.sessionContent) {
            clearInterval(this.sessionContent.interval);
            if (this.sessionContent.isOwnWindow && this.sessionContent.popupWindow) {
                this.sessionContent.popupWindow.close();
            }
            this.sessionContent = null;
        }
        //dispatcher.unsubscribe(events.EVENT_CURRENT_USER_CHANGED, this);
    }

    request(popupWindow) {
        const sessionId = 'popup_' + new Date().getTime();

        let isOwnWindow = false;
        if (popupWindow) {
            popupWindow.name = sessionId;
            popupWindow.location.href = this.url;
        } else {
            popupWindow = window.open(this.url, sessionId, 'left=100,top=100,height=610,width=520');
            if (popupWindow === null) {
                return Promise.reject(new PopupError());
            }
            isOwnWindow = true;
        }

        this.sessionContent = {
            id: sessionId,
            popupWindow: popupWindow,
            isOwnWindow: isOwnWindow,
            resolve: null,
            reject: null,
            interval: setInterval(() => {
                if (popupWindow.closed) {
                    this.reject('The popup window session has been suspended because the window is closed.');
                }
            }, 1000),
        };

        if (window.focus) {
            popupWindow.focus();
        }

        // dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
        //     this.reject('The popup window session has been suspended because current user has changed.');
        // });

        return new Promise((resolve, reject) => {
            this.sessionContent.resolve = resolve;
            this.sessionContent.reject = reject;
        });
    }

    resolve(result) {
        if (this.sessionContent && this.sessionContent.resolve) {
            this.sessionContent.resolve(result);
        }
        this.finishSession();
    }

    reject(error) {
        if (this.sessionContent && this.sessionContent.reject) {
            this.sessionContent.reject(error);
        }
        this.finishSession();
    }

    receive(sessionId, responsePayload) {
        if (this.sessionContent === null) {
            return false;
        }
        if (this.sessionContent.id !== sessionId) {
            return false;
        }

        if (!responsePayload) {
            this.reject('Failed to get required data.');
        } else {
            this.resolve(responsePayload);
        }

        return true;
    }
}

class PopupJobFactory {
    services = new Map();

    getService(url) {
        if (this.services.has(url)) {
            return this.services.get(url);
        }

        const service = new PopupJob(url);

        if (typeof window.receivePopupResponse === 'undefined') {
            window.receivePopupResponse = (sessionId, responsePayload) => {
                for (let service of this.services.values()) {
                    if (service.hasSession(sessionId)) {
                        return service.receive(sessionId, responsePayload);
                    }
                }
                return false;
            };
        }

        this.services.set(url, service);

        return service;
    }
}

export default new PopupJobFactory();
