import {
    AdapterId,
    FieldType,
    IField,
    ProspectStructure,
    WorkingHoursException,
    WorkingHoursPerWeek,
    WorkingHoursTime,
} from 'components/types';
import { IAppointmentConfig } from 'service/MapPage/Appointment/AppointmentConfig';
import { Account, Common, Routing, User } from 'interfaces';
import { OBJECTIVE_MINIMIZE_COMPLETION, OBJECTIVE_MINIMIZE_DRIVING_TIME } from '../components/constants';

export enum POSITION_TYPE {
    AREA,
    CENTER,
    ZOOM,
    RECORD,
}

export enum SHARED_MAP_POSITION_TYPE {
    AREA = 'area',
    CENTER = 'center_point_and_zoom',
}

/**
 * @deprecated
 * @see User.User
 */
export interface UserData {
    id: number;
    routingPreferences: UserDataRoutePreferences;
    calendarPreferences: UserDataCalendarPreferences;
    role: UserDataRole;
    accountId: number | null;
    email: string;
    externalId: string | null;
    isCreatorAccount: boolean;
    language: string;
    name: string;
    ssoId: string | null;
    ssoProvider: AdapterId | null;
    timezone: string | null;
    actualTimezone: string;
    timezoneOffset: number;
    /* @deprecated - because it works incorrectly in DST timezones */
    actualTimezoneOffset: number; // minutes
    username: string;
    review: UserReviewData;
    externalUsers: ExternalUser[];
    hasCrmAccount: boolean | null;
    routingSession: User.RoutingSession;
    avatar?: UserAvatar;
    createdAt: string;
}

export type ExternalUser = {
    dataSourceId: number;
    externalId: string;
    accuracy: number;
};

export type UserReviewData = {
    isWaitingAppReview: boolean;
    isWaitingSourceReview: boolean;
    remindLater: boolean;
    isReminded: boolean;
    isPostponed: boolean;
    leftAppReview: boolean;
    leftSourceReview: boolean;
};

export interface UserAvatar {
    fileID: string;
    thumbnailUrl: string;
}
/**
 * @deprecated
 * @see <Account.Account>
 */
export interface AccountData {
    id: number;
    name: string;
    isTest: boolean;
    routingPreferences: UserDataRoutePreferences;
    defaultCalendarPreferences: UserDataCalendarPreferences;
    timezone: string;
    timezoneOffset: number;
    createdAt: string;
    language: string;
    routingSettings: Routing.Settings.AccountRoutingSettings;
    distanceMetric: Common.DistanceMetric;
    subscription: Account.Subscription;
    routingAndTrackingSettings: Routing.Settings.AccountRoutingAndTrackingSettings;
    calendarUserGroups: CalendarUserGroup[];
}

export interface CalendarUserGroup {
    name: string;
    userIds: number[];
}

export enum CalendarFirstDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}
export enum CalendarRoundingStep {
    STEP_5 = 5,
    STEP_10 = 10,
    STEP_15 = 15,
    STEP_30 = 30,
}
export enum CalendarResourceGrouping {
    USER_DATE = 'user_date',
    DATE_USER = 'date_user',
}
export enum CalendarFixedFlexible {
    FIXED = 'fixed',
    FLEXIBLE = 'flexible',
    ASK = 'ask',
}
export type CalendarOptimizationObjective =
    | typeof OBJECTIVE_MINIMIZE_DRIVING_TIME
    | typeof OBJECTIVE_MINIMIZE_COMPLETION;

export const CalendarFixedTimeBooleanToEnumMap = new Map<boolean | null, CalendarFixedFlexible>([
    [true, CalendarFixedFlexible.FIXED],
    [false, CalendarFixedFlexible.FLEXIBLE],
    [null, CalendarFixedFlexible.ASK],
]);

export const CalendarFixedTimeEnumToBooleanMap = new Map<CalendarFixedFlexible, boolean | null>([
    [CalendarFixedFlexible.FIXED, true],
    [CalendarFixedFlexible.FLEXIBLE, false],
    [CalendarFixedFlexible.ASK, null],
]);

export interface UserDataCalendarPreferences {
    defCalendarUuid: string | null;
    autoRoundingEventStartTime: boolean;
    roundingStep: number | null;
    roundLunchStartTime: boolean;
    fixedTimeAfterDrop: boolean | null;
    fixedTimeAfterOptimization: boolean;
    resourceGrouping: CalendarResourceGrouping | null;
    showUserAvatars: boolean;
    showTooltip: boolean;
    firstDayOfWeek: CalendarFirstDay | null;
    timelineStartAt: WorkingHoursTime | null;
    optimizationObjective: CalendarOptimizationObjective;
}

export const USER_CALENDAR_PREFERENCES_FIELD_LIST: (keyof UserDataCalendarPreferences)[] = [
    'defCalendarUuid',
    'autoRoundingEventStartTime',
    'roundingStep',
    'roundLunchStartTime',
    'fixedTimeAfterDrop',
    'fixedTimeAfterOptimization',
    'showUserAvatars',
    'showTooltip',
    'firstDayOfWeek',
    'timelineStartAt',
    'optimizationObjective',
];

export const USER_ROUTING_PREFERENCES_FIELD_LIST: (keyof UserDataRoutePreferences)[] = [
    'weekTimes',
    'startPoint',
    'endPoint',
    'returnToFinishLocation',
    'defaultDuration',
    'departureDelay',
    'breakType',
    'breakDuration',
    'vehicleType',
    'hasSkills',
    'maxDistance',
    'maxDrivingTime',
    'maxActivities',
    'minJobs',
    'maxJobs',
    'allowedOvertime',
    'lassoAutoZoom',
    'showEarlyLateTags',
];

export enum BreakTypeEnum {
    TIME_WINDOW = 'time_window',
}
export enum ShowEarlyLateTags {
    DO_NOT_SHOW = 'do_not_show',
    CALENDAR_EVENTS_ONLY = 'calendar_events_only',
    ALL_ACTIVITIES = 'all_activities',
}

export interface UserDataRoutePreferences {
    startPoint: Routing.Route.UserLocationPoint | null;
    endPoint: Routing.Route.UserLocationPoint | null;
    lassoAutoZoom: boolean;
    defaultDuration: number;
    departureDelay: number;
    weekTimes: WorkingHoursPerWeek;
    breakType: BreakTypeEnum | null;
    workingHoursExceptions: WorkingHoursException[];
    breakDuration: number | null;
    minJobs: number | null;
    maxJobs: number | null;
    maxActivities: number | null;
    maxDrivingTime: number | null;
    maxDistance: number | null;
    hasSkills: string[] | null;
    vehicleType: string | null;
    returnToFinishLocation: boolean;
    breakLatestTime: string | null;
    breakEarliestTime: string | null;
    showEarlyLateTags: ShowEarlyLateTags | null;
    canJobStartEarlier: boolean;
    allowedOvertime: number | null;
    navigationVariant: MapNavigationOption | null;
}

export interface Duration {
    number: number | null;
    units: string;
}

export interface SessionSettings {
    idleSessionTimeoutForApp: Duration;
    maxSessionDurationForApp: Duration;
    idleSessionTimeoutForWeb: Duration;
    maxSessionDurationForWeb: Duration;
    separateWebAndAppSessions: boolean;
    maxParallelSessions: number | null;
}

export type UserDataRole = {
    id: number;
    code: string;
    name: string;
    accountId: number;
    isSystem: boolean;
    hasUsers: boolean;
    forSharedMap: boolean;
    sessionSettings: SessionSettings;
};

export enum MapNavigationOption {
    GOOGLE = 'google',
    APPLE = 'apple',
    WAZE = 'waze',
}

type DataSourceSpecialEntities = {
    appointment?: null;
};

type DataSourceJobStatus = {};

type DataSourceEntityCounterCounters = {
    okExact: number;
    invalid: number;
    noAddress: number;
    okApprox: number;
    okImported: number;
    processing: number;
};

type DataSourceEntityCounterDataSource = {
    id: number;
    name: string;
    accountId: number;
};

export type DataSourceEntityCounterEntityField = {
    id: number;
    isIncluded: boolean;
    isSystem: boolean;
    isLookup: boolean;
    isLink: boolean;
    isSystemVisible: boolean;
    isVirtual: boolean;
    hasPicklist: boolean;
    name: string;
    label: string;
    type: FieldType;
};

type DataSourceEntityCounterEntity = {
    id: number;
    name: string;
    apiName: string;
    label: string;
    fields: DataSourceEntityCounterEntityField[];
    hasPinField: boolean;
};

export type DataSourceEntityCounter = {
    counters: DataSourceEntityCounterCounters;
    dataSource: DataSourceEntityCounterDataSource;
    entity: DataSourceEntityCounterEntity;
};

type DataSourceCredential = {};

export type AdapterSettingValue = string | number | boolean | null;

export type AdapterSettings = {
    [key: string]: AdapterSettingValue;
};

enum DataSourceSettingType {
    INPUT = 'input',
    SELECT = 'select',
    SLIDER = 'slider',
    CHECKBOX = 'checkbox',
    UUID_GENERATOR = 'uuid_generator',
    SYSTEM = 'system',
    PASSWORD = 'password',
}

export type DataSourceSettings = DataSourceSetting[];

export type DataSourceSetting = {
    name: string;
    label: string;
    placeholder?: string;
    default: AdapterSettingValue;
    required?: boolean;
    options?: DataSourceSettingOptions;
    type: DataSourceSettingType;
    slider_min?: number;
    slider_max?: number;
    slider_label_type?: string;
};

type DataSourceSettingOptions = DataSourceSettingOption[];

type DataSourceSettingOption = {
    value: string;
    label: string;
};

/**
 * @deprecated
 * @see DataSource.DataSource
 */
export type IDataSource = {
    id: number;
    isConnected: boolean;
    isDeactivated: boolean;
    isImportEnabled: boolean;
    isSyncing: boolean;
    isSystem: boolean;
    lastHttpCode: number;
    accountId: number;
    adapterId: string;
    adapterName: string;
    name: string;
    adapterUrl: string;
    apiKey: string | null;
    connectionId: string;
    connectionToggledAt: string; // Date
    credentials: DataSourceCredential[];
    entityCounters: DataSourceEntityCounter[];
    jobStatuses: DataSourceJobStatus[];
    settings: DataSourceSettings;
    specialEntities?: DataSourceSpecialEntities;
    entities?: DataSourceEntityCounterEntity[];
};

export interface IDSManager {
    reset: () => void;
    list: () => Promise<void>;
    getDataSources: () => IDataSource[];
}

export interface IAppointmentStorage {
    saveConfig(config: IAppointmentConfig): void;

    loadConfig(): IAppointmentConfig;
}

export interface IWorkflowActionGroup {
    id: number;
}

export interface IWorkflowButton {
    id: number;
    entities: Array<any>;
    actionGroups: IWorkflowActionGroup[];
    roles: Array<any>;
    isActive: boolean;
    name: string;
    apiName: string;
    visibilityType: string;
    label: string;
    icon: string;
    locations: string[];
    isUserLocationAvailable: string;
}

export interface LocationTrackingSettings {
    trackGeolocation: boolean;
    autoActivateGeolocationDuringWork: boolean;
}

export type TravelingPreferencesFocusElement = 'startPoint' | 'endPoint';

export type TripModeConfig = {
    routeId: string | null;
    isTripStarted: boolean;
    currentGroupIndex: number;
    areOnlyRoutePointsDisplayed: boolean;
};

export type ProspectsEntitiesResponse = {
    apiName: string;
    name: string;
    title: string;
    fields: IField[];
    id: number;
    prospectStructure: ProspectStructure[];
};

export interface EntityFieldSettings {
    id: number;
    name: string;
    columnName: string;
    title: string;
    type: string;
    isPin: boolean;
    isLink: boolean | null;
    defaultValue: any;
}

export interface EntitySettings {
    id: number;
    name: string;
    apiName: string;
    label: string;
    fields: EntityFieldSettings[];
}

export enum SubscriptionStatus {
    CUSTOM = 'custom',
    FREE = 'free',
    TRIAL = 'trial',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    DELETED = 'deleted',
    CANCELLED = 'cancelled',
    PAUSED = 'paused',
}

export enum SubscriptionPlanTier {
    ESSENTIAL = 'essential',
    PRO = 'pro',
    ENTERPRISE = 'enterprise',
}

export interface Subscription {
    accountId: number;
    status: SubscriptionStatus;
    plan: {
        name: string;
        tier: SubscriptionPlanTier;
    };
    gracePeriodRemainingDays: null | number;
}

export const IsAutoUpdateCountUsers = (subscription: Account.Subscription | null) => {
    return (
        subscription &&
        subscription.usersAutoUpdate &&
        (subscription.status === SubscriptionStatus.TRIAL ||
            subscription.status === SubscriptionStatus.FREE ||
            subscription.status === SubscriptionStatus.CUSTOM)
    );
};

/**
 * @deprecated
 * @see FieldType.INTEGER
 */
export const TYPE_INTEGER = 'integer';
/**
 * @deprecated
 * @see FieldType.BIGINT
 */
export const TYPE_BIGINT = 'bigint';
/**
 * @deprecated
 * @see FieldType.FLOAT
 */
export const TYPE_FLOAT = 'float';
/**
 * @deprecated
 * @see FieldType.STRING
 */
export const TYPE_STRING = 'string';
/**
 * @deprecated
 * @see FieldType.TEXT
 */
export const TYPE_TEXT = 'text';
/**
 * @deprecated
 * @see FieldType.BOOLEAN
 */
export const TYPE_BOOLEAN = 'boolean';
/**
 * @deprecated
 * @see FieldType.DATE
 */
export const TYPE_DATE = 'date';
/**
 * @deprecated
 * @see FieldType.DATETIME
 */
export const TYPE_DATETIME = 'datetime';
/**
 * @deprecated
 * @see FieldType.DATETIME_RANGE
 */
export const TYPE_DATETIME_RANGE = 'datetime-range';
/**
 * @deprecated
 * @see FieldType.STRINGS
 */
export const TYPE_TEXT_ARRAY = 'text[]';
export const TYPE_INTEGER_ARRAY = 'integer[]';
export const TYPE_FILES = 'file[]';
export const TYPE_JSON = 'json';

export const DATE_AFTER_WHICH_SHARED_USERS_NOT_COUNTED = '2022-04-01';
export const HEADER_X_CLIENT_PLATFORM = 'x-client-platform';
