import { PicklistValue } from 'components/types';
import { Geo } from 'interfaces';

export enum MappingFieldPicklistType {
    PICKLIST,
    MULTI_PICKLIST,
}

export type MappingFilterFieldsType = {
    types: Set<string>;
    picklistTypes: Set<MappingFieldPicklistType>;
};

export type MappingFilterFieldsTypeMap = Map<string, MappingFilterFieldsType>;

export enum MappingDefaultValueControlType {
    PICKLIST,
    INTEGER,
    TEXT,
    LOOKUP_ADDRESS,
    MULTI_PICKLIST,
    TIME,
    FLOAT,
}

export type MappingDefaultValueControlPicklistOptions = {
    values: PicklistValue[];
};

export type MappingDefaultValueControlIntegerOptions = {
    min: number;
    max: number;
    step?: number | string;
    hideArrows?: boolean;
};

export type MappingDefaultValueControlFloatOptions = {
    min: number;
    max: number;
};

export type MappingDefaultValueControlConfig = {
    type: MappingDefaultValueControlType;
    options?: MappingDefaultValueControlPicklistOptions | MappingDefaultValueControlIntegerOptions;
};

export type MappingDefaultValueControlConfigMap = Map<string, MappingDefaultValueControlConfig>;

export type AccountRoutingSettings = {
    userMapping: FormMappingData;
    objectsMapping: MappingObjects;
    vehicleTypes: VehicleTypes;
    skillsData: SkillsData;
};

export type AccountRoutingAndTrackingSettings = {
    trackingEnabled: boolean;
    reportGenerationHour: number | null;
};

export enum Tabs {
    TAB_OBJECTS,
    TAB_USERS,
    TAB_VEHICLE_TYPES,
    TAB_SKILLS,
    TAB_TRACKING,
}

export enum MappingUserKey {
    VEHICLE_TYPE = 'vehicle_type',
    // TODO переделать формат ключей

    // Start location section
    START_LOCATION_ADDRESS = 'start_location_address',
    START_LOCATION_LATITUDE = 'start_location_latitude',
    START_LOCATION_LONGITUDE = 'start_location_longitude',

    // Finish location section
    FINISH_LOCATION_ADDRESS = 'finish_location_address',
    FINISH_LOCATION_LATITUDE = 'finish_location_latitude',
    FINISH_LOCATION_LONGITUDE = 'finish_location_longitude',

    RETURN_TO_FINISH_LOCATION = 'return_to_finish_location',
    HAS_SKILLS = 'has_skills',
    MAX_DISTANCE = 'max_distance',
    MAX_DRIVING_TIME = 'max_driving_time',
    MAX_JOBS = 'max_jobs',
    MIN_JOBS = 'min_jobs',
    MAX_ACTIVITIES = 'max_activities',

    // Break section
    BREAK_DURATION = 'break_duration',
    BREAK_EARLIEST_TIME = 'break_earliest_time',
    BREAK_LATEST_TIME = 'break_latest_time',

    ALLOWED_OVERTIME = 'allowed_owertime',
}

export const MappingObjectKey = {
    ALL_JOB_TYPES: {
        JOB_TYPE: 'all_job_types.job_type',
        DURATION: 'all_job_types.duration',
        PRIORITY: 'all_job_types.priority',
        PREPARATION_TIME: 'all_job_types.preparation_time',
        DEPARTURE_DELAY: 'all_job_types.departure_delay',
        REQUIRED_SKILLS: 'all_job_types.required_skills',
        REQUIRED_CAPACITY: 'all_job_types.required_capacity',
        ALLOWED_USERS: 'all_job_types.allowed_users',
        DISALLOWED_USERS: 'all_job_types.disallowed_users',
        MAX_TIME_IN_VEHICLE: 'all_job_types.max_time_in_vehicle',
        AVAILABILITY: {
            AVAILABLE_FROM: 'all_job_types.availability.available_from',
            AVAILABLE_TO: 'all_job_types.availability.available_to',
        },
    },
    PICKUP_DELIVERY_JOBS: {
        DURATION: 'pickup_delivery_jobs.duration',
        PREPARATION_TIME: 'pickup_delivery_jobs.preparation_time',
        DEPARTURE_DELAY: 'pickup_delivery_jobs.departure_delay',
        DELIVERY_LOCATION: {
            ADDRESS: 'pickup_delivery_jobs.delivery_location.address',
            LATITUDE: 'pickup_delivery_jobs.delivery_location.latitude',
            LONGITUDE: 'pickup_delivery_jobs.delivery_location.longitude',
        },
        AVAILABILITY: {
            AVAILABLE_FROM: 'pickup_delivery_jobs.availability.available_from',
            AVAILABLE_TO: 'pickup_delivery_jobs.availability.available_to',
        },
    },
};

export type MappingRecordValue = string | number | Geo.GeoLocationPoint | null;

export type MappingDefaultValueForm = number | string | string[] | Geo.GeoLocationPoint | null;
export type MappingFieldValueForm = number | null;

export interface MappingRecordDataStructure {
    entityId: number | null;
    fieldId: number | null;
    recordId: number | null;

    selectedValue: MappingRecordValueStructure;
    crmValue: MappingRecordValueStructure;
    travelingPreferenceValue: MappingRecordValueStructure;
    defaultValue: MappingRecordValueStructure;
}

export interface MappingRecordValueStructure {
    value: MappingRecordValue;
    validationErrors: ValidationError[];
}

export type UsersMappingRecordValueMap = Map<number, MappingRecordValueMap>;

export type MappingRecordValueMap = Map<MappingUserKey, MappingRecordDataStructure>;

export type MappingValueForm = {
    fieldId: MappingFieldValueForm;
    defaultValue: MappingDefaultValueForm;
};

export type MappingValueError = {
    field: string | null;
    defaultValue: string | null;
};

export type MappingForm = Map<string, MappingValueForm>;

export type MappingSelectedObject = {
    dataSourceId: number;
    entityId: number;
};

export type VehicleTypes = Map<string, VehicleType>;

export type VehicleType = {
    name: string;
    travelMode: VehicleTypeTravelMode;
    capacity: string;
    travelSpeedFactor: number;
    serviceSpeedFactor: number;
};

export type Skills = Array<string>;

export type VehicleTypeFormData = {
    vehicleTypeKey: string;
    vehicleType: VehicleType;
};

export enum VehicleTypeTravelMode {
    CAR = 'car',
    CAR_DELIVERY = 'car_delivery',
    CAR_AVOID_FERRY = 'car_avoid_ferry',
    CAR_AVOID_MOTORWAY = 'car_avoid_motorway',
    CAR_AVOID_TOLL = 'car_avoid_toll',
    SMALL_TRUCK = 'small_truck',
    SMALL_TRUCK_DELIVERY = 'small_truck_delivery',
    TRUCK = 'truck',
    SCOOTER = 'scooter',
    SCOOTER_DELIVERY = 'scooter_delivery',
    BIKE = 'bike',
    FOOT = 'foot',
}

export type UpdatedObjectMapping = {
    isActivated: boolean;
    currentObject: MappingSelectedObject;
    mapping: MappingForm;
};

export type MappingObjects = Map<string, FormMappingData>;

export type FormMappingData = {
    isActivated: boolean;
    currentObject: MappingSelectedObject | null;
    mapping: MappingForm;
};

export interface ValidationError {
    type: 'error' | 'warning';
    value: any;
    replaceValue?: any;
    message: string;
    path: string;
    record: string | number;
    recordLabel: string | null;
    code: string | null;
}

export type SkillsData = {
    skills: Skills;
    validation: boolean;
};
