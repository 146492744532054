import React from 'react';
import { Trans } from 'react-i18next';
import { FormControl, FormHelperText, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ParamsItem from './ParamsItem';
import { CallContext } from '../../utils/CallContext';
import AddIconButton from '../../CustomButton/AddIconButton';

const SortableItem = SortableElement(({ itemProps, ...props }) => <ParamsItem {...props} {...itemProps} />);
const SortableList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((item, index) => (
                <SortableItem
                    index={index}
                    key={item[props.valueKey] + '_' + item[props.nameKey] + '_' + index}
                    item={item}
                    callContext={props.callContext}
                    errors={props.errors.get(index)}
                    onChange={(item) => props.onChangeItem(index, item)}
                    onRemove={() => props.onRemoveItem(index)}
                    nameKey={props.nameKey}
                    valueKey={props.valueKey}
                    disabled={props.disabled}
                    itemProps={{ disabled: props.disabled }}
                />
            ))}
        </div>
    );
});

class ParamsList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleChangeItem = this.handleChangeItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
    }

    handleChangeItem(index, item) {
        const items = cloneDeep(this.props.items);
        items[index] = item;

        this.handleChange(items);
    }

    handleAddItem() {
        const items = cloneDeep(this.props.items);

        const item = {};
        item[this.props.nameKey] = '';
        item[this.props.valueKey] = '';

        items.unshift(item);

        this.handleChange(items);
    }

    handleRemoveItem(index) {
        const items = cloneDeep(this.props.items);
        items.splice(index, 1);

        this.handleChange(items);
    }

    handleSortEnd(e) {
        const items = cloneDeep(this.props.items);
        this.handleChange(arrayMove(items, e.oldIndex, e.newIndex));
    }

    handleChange(items) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: items,
            },
        });
    }

    render() {
        return (
            <FormControl fullWidth error={this.props.errors.size > 0}>
                <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                    <Grid item style={{ maxWidth: '30%' }}>
                        <b>{this.props.title}</b>
                    </Grid>
                    {!this.props.hideAddButton && (
                        <Grid item>
                            <AddIconButton small onClick={this.handleAddItem} />
                        </Grid>
                    )}
                    {this.props.helperText && (
                        <Grid item style={{ width: '60%' }}>
                            {this.props.helperText}
                        </Grid>
                    )}
                </Grid>
                {this.props.items.length > 0 && (
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={1} />
                        <Grid item xs={4}>
                            {this.props.nameTitle}
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.valueTitle}
                        </Grid>
                    </Grid>
                )}
                {this.props.items.length === 0 && (
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            {this.props.noItemsText}
                        </Grid>
                    </Grid>
                )}

                <SortableList
                    items={this.props.items}
                    callContext={this.props.callContext}
                    onSortEnd={this.handleSortEnd}
                    useDragHandle
                    onChangeItem={this.handleChangeItem}
                    onRemoveItem={this.handleRemoveItem}
                    errors={this.props.errors}
                    helperClass="sortable-item"
                    nameKey={this.props.nameKey}
                    valueKey={this.props.valueKey}
                    disabled={this.props.disabled}
                />
                {this.props.errors.has('list') && <FormHelperText>{this.props.errors.get('list')}</FormHelperText>}
            </FormControl>
        );
    }
}

ParamsList.defaultProps = {
    errors: new Map(),
    nameKey: 'name',
    valueKey: 'value',
    nameTitle: <Trans>workflow_actions.form.invoke_url.param.name</Trans>,
    valueTitle: <Trans>workflow_actions.form.invoke_url.param.value</Trans>,
    noItemsText: <Trans>workflow_actions.form.invoke_url.param.noItems</Trans>,
    disabled: false,
    hideAddButton: false,
};

ParamsList.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    errors: PropTypes.instanceOf(Map),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    nameKey: PropTypes.string,
    valueKey: PropTypes.string,
    nameTitle: PropTypes.string,
    valueTitle: PropTypes.string,
    noItemsText: PropTypes.string,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    disabled: PropTypes.bool,
    hideAddButton: PropTypes.bool,
};

export default ParamsList;
