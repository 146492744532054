import { DataSourceEntityCounter } from '../../service/types';

export enum Tabs {
    TAB_CALENDARS,
    TAB_USER_GROUPS,
}

/**
 * @type Map<entityId, DataSourceEntityCounter>
 */
export type DataSourceEntityCounterByIdMap = Map<number, DataSourceEntityCounter>;
