import React from 'react';
import { observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PicklistValue } from 'components/types';
import { ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG } from 'service/MapPage/RoutingSettings/config';
import Hint from 'components/Hint';
import { Account, Routing } from 'interfaces';
import { FormControl, FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

interface Props extends WithTranslation {
    account: Account.Account;
    value: string | null;
    onValueChanged: (value: string | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

class VehicleTypeInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled, bareInput, account } = this.props;

        const defaultValueControlConfig = ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG.get(
            Routing.Settings.MappingUserKey.VEHICLE_TYPE,
        );
        if (!defaultValueControlConfig) {
            throw new Error('Not found default value control config.');
        }

        const picklistValues = VehicleTypeInput.fillPicklistValues(account.routingSettings.vehicleTypes);
        const stringValue = value === null ? '' : value;

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin={bareInput ? 'none' : 'dense'} error={error !== undefined}>
                        {!bareInput && (
                            <InputLabel shrink={true}>
                                {t('routing_settings.dialog.tabs.users.form_control.vehicle_type.label')}
                            </InputLabel>
                        )}
                        <Select
                            error={!!error}
                            disabled={disabled}
                            fullWidth
                            displayEmpty
                            onChange={this.handleChange}
                            value={stringValue}
                            margin={bareInput ? 'none' : 'dense'}
                        >
                            <MenuItem key="" value="">
                                <em>{t('undefined_picklist_option')}</em>
                            </MenuItem>
                            {picklistValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.vehicle_type.hint')}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { onValueChanged } = this.props;
        let value = e.target.value as string | null;
        if (value === '') {
            value = null;
        }
        onValueChanged(value).then(() => this.validate(value));
    };

    // @ts-ignore unused variable
    private validate = (value: string | null): Promise<void> => {
        const { onInputValidation } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };

    private static fillPicklistValues(vehicleTypes: Routing.Settings.VehicleTypes): PicklistValue[] {
        const result: PicklistValue[] = [];
        for (let [value, vehicleType] of vehicleTypes.entries()) {
            result.push({
                value,
                label: vehicleType.name,
            });
        }

        return result;
    }
}

export default withTranslation()(observer(VehicleTypeInput));
