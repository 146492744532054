import React from 'react';
import { userManager } from '../../service/UserManager';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import { withRouter } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './style.css';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import { userPropertiesManager } from 'service/UserForm';
import { UserPropertiesTab } from 'components/types';
import DeleteUserDialog from './DeleteUserDialog';
import AddIconButton from 'components/CustomButton/AddIconButton';
import { IsAutoUpdateCountUsers } from 'service/types';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { accountsManager } from 'service/AccountsManager.js';
import { observer } from 'mobx-react';
import Badge from '../Badge';
import Alert from '../Alert';
import TabPanel from '../DataSource/TabPanel';
import UserListTable from './UserListTable';
import { roleManager } from '../../service/RoleManager';

const TAB_USERS_REGISTERED = 'users_registered';
const TAB_USERS_SHARED_MAPS = 'users_shared_maps';

export class UsersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedUserIds: [],
            rolesSharedMaps: [],
            currentUser: null,
            anchorEl: null,
            account: null,
            subscription: null,
            loginAsModeUserId: null,
            loginAsSwitching: false,
            currentTab: TAB_USERS_REGISTERED,
            errorMessage: null,
        };

        this.pageSizes = [10, 25, 50, 100, 0];
    }

    refreshRoles = () => {
        return roleManager.getAccountRoles(this.props.accountId).then((roles) => {
            this.setState({
                rolesSharedMaps: roles.filter((item) => item.forSharedMap),
            });
        });
    };

    refreshAccount = () => {
        accountsManager.load(this.props.accountId).then((account) => {
            this.setState({
                account,
            });
        });
    };

    componentDidMount() {
        dispatcher.subscribe(
            [
                events.EVENT_ROLE_CREATED,
                events.EVENT_ROLE_CHANGED,
                events.EVENT_ROLES_CHANGED,
                events.EVENT_ROLE_DELETED,
                events.EVENT_ACCOUNT_PERMISSIONS_SAVED,
            ],
            this,
            () => {
                this.refreshRoles();
            },
        );
        dispatcher.subscribe([events.EVENT_USER_CHANGED, events.EVENT_USER_CREATED], this, () => {
            this.refreshAccount();
        });
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
            this.refreshAccount();
        });
        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.accountId !== null && this.props.accountId === subscription.accountId) {
                this.setState({
                    subscription,
                });
            }
        });

        this.refreshAccount();
        this.refreshRoles();
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            this.refreshRoles(this.props.accountId);
        }
    }

    handleOpenMenu = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseMenu = (e) => {
        e.stopPropagation();
        this.setState({
            anchorEl: null,
        });
        return true;
    };

    handleOpen = (user) => (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { account } = this.state;
        if (user === null) {
            user = userManager.constructor.getDefaultUser(account.id);
        }
        this.setState({
            currentUser: user,
            anchorEl: null,
        });

        userPropertiesManager.openModal(account, user, UserPropertiesTab.TAB_PERSONAL);
    };

    handleClose = () => {
        this.setState({
            currentUser: null,
        });
    };

    handleChangeTab = (e, tab) => {
        this.setState({
            currentTab: tab,
            selectedUserIds: [],
        });
    };

    handleDeleteUsers = async (userIds, newOwner) => {
        this.setState({ errorMessage: null });

        userIds = userIds instanceof Array ? userIds : [userIds];

        try {
            return await userManager.deleteUsers(this.props.accountId, userIds, newOwner);
        } catch (e) {
            this.setState({
                errorMessage: e.message,
            });
        }
    };

    handleSavedUser = (user) => {
        const tab = !!this.state.rolesSharedMaps.filter((item) => item.id === user.role.id).length
            ? TAB_USERS_SHARED_MAPS
            : TAB_USERS_REGISTERED;

        this.handleChangeTab(null, tab);
    };

    handleSelectionUsersChange = (selectedUserIds) => {
        this.setState({
            selectedUserIds: selectedUserIds,
        });
    };

    render() {
        const { account, currentTab } = this.state;

        if (!account) {
            return null;
        }

        const subscription = this.state.subscription;
        const autoUpdateCountUsers = IsAutoUpdateCountUsers(subscription);
        let countOfPaidUsers = account.countOfPaidUsers;
        const { t } = this.props;

        return (
            <div className="c-users-list">
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <h2 style={{ margin: 0 }}>{t('account.users.title')}</h2>
                    </Grid>
                    <Grid item>
                        <AddIconButton onClick={this.handleOpen(null)} />
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('entity_data_table.table_selection_menu.group_actions.tooltip')}>
                            <div>
                                <IconButton
                                    disabled={!this.state.selectedUserIds.length}
                                    className="entity-data-table__entities__button"
                                    onClick={this.handleOpenMenu}
                                    color="default"
                                    data-menu={'usersGroup'}
                                >
                                    <Badge badgeContent={this.state.selectedUserIds.length} color="primary">
                                        <Icon
                                            className="fas fa-bolt"
                                            style={{ overflow: 'visible' }}
                                            fontSize="small"
                                        />
                                    </Badge>
                                </IconButton>
                            </div>
                        </Tooltip>
                        <Menu
                            anchorEl={this.state.anchorEl}
                            open={this.state.anchorEl !== null && this.state.anchorEl.dataset.menu === 'usersGroup'}
                            onClose={this.handleCloseMenu}
                            disableAutoFocusItem
                            className="user-list-menu"
                        >
                            <MenuItem disabled>
                                {t('account.users.table_selection_menu.selected_count', {
                                    count: this.state.selectedUserIds.length,
                                })}
                            </MenuItem>
                            <DeleteUserDialog
                                onConfirm={(e, newOwner) =>
                                    this.handleCloseMenu(e) &&
                                    this.handleDeleteUsers(this.state.selectedUserIds, newOwner)
                                }
                                onReject={this.handleCloseMenu}
                                deletedUserIds={this.state.selectedUserIds}
                                accountId={this.props.accountId}
                                rolesSharedMaps={this.state.rolesSharedMaps}
                            >
                                <MenuItem data-testid="account.users.delete">{this.props.t('delete')}</MenuItem>
                            </DeleteUserDialog>
                        </Menu>
                    </Grid>
                    {subscription && autoUpdateCountUsers === false && (
                        <Grid item>
                            <Typography variant="subtitle2">
                                {countOfPaidUsers < subscription.usersAllowed && (
                                    <span>
                                        {t('account.users.limit.occupied', {
                                            countUsers: countOfPaidUsers,
                                            usersAllowed: subscription.usersAllowed,
                                        })}
                                    </span>
                                )}

                                {countOfPaidUsers >= subscription.usersAllowed && (
                                    <span>
                                        {t('account.users.limit.all_occupied', {
                                            usersAllowed: subscription.usersAllowed,
                                        })}
                                    </span>
                                )}
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                {this.state.errorMessage && (
                    <React.Fragment>
                        <br />
                        <Alert type="danger">{this.state.errorMessage}</Alert>
                    </React.Fragment>
                )}

                <br />
                <Tabs indicatorColor="primary" value={currentTab} onChange={this.handleChangeTab} variant="scrollable">
                    <Tab value={TAB_USERS_REGISTERED} label={this.props.t('account.tabs.title.users.registered')} />
                    <Tab value={TAB_USERS_SHARED_MAPS} label={this.props.t('account.tabs.title.users.shared_maps')} />
                </Tabs>
                <br />
                <TabPanel value={currentTab} index={TAB_USERS_REGISTERED}>
                    <UserListTable
                        accountId={this.props.accountId}
                        selectedUserIds={this.state.selectedUserIds}
                        sharedMapUsers={false}
                        onSavedUser={this.handleSavedUser}
                        onSelectionUsersChange={this.handleSelectionUsersChange}
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={TAB_USERS_SHARED_MAPS}>
                    <div className={'free-users-message'}>
                        <i
                            style={{ color: 'lightgray', marginRight: '10px', fontSize: '130%' }}
                            className="fas fa-info-circle"
                        />
                        {this.props.t('account.user.shared_maps.free_users_message')}
                    </div>
                    <UserListTable
                        accountId={this.props.accountId}
                        selectedUserIds={this.state.selectedUserIds}
                        sharedMapUsers={true}
                        onSavedUser={this.handleSavedUser}
                        onSelectionUsersChange={this.handleSelectionUsersChange}
                    />
                </TabPanel>
            </div>
        );
    }
}

UsersList.propTypes = {
    accountId: PropTypes.number.isRequired,
    myAccount: PropTypes.bool,
};

export default withTranslation('translations', { withRef: true })(withRouter(observer(UsersList)));
