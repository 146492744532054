import { Geo, Routing } from 'interfaces';
import { isValidLocation } from '../utils';

class GeoPointKey {
    static getKeyByPoint(point: Geo.NullableGeoPoint): string {
        if (isValidLocation(point)) {
            return GeoPointKey.getLocationKey(point.lat, point.lng);
        }

        return GeoPointKey.getVirtualKey();
    }

    private static getLocationKey(lat: number, lng: number): string {
        const fixedLat = parseFloat(lat.toString()).toFixed(6);
        const fixedLng = parseFloat(lng.toString()).toFixed(6);
        return `${fixedLat}_${fixedLng}`;
    }

    private static getVirtualKey(): string {
        return 'virtual';
    }

    static getKeyByEntityPoint(point: Routing.Route.EntityNotSafeGeoPoint): string {
        return `${point.entityId}_${point.recordId}`;
    }
}

export default GeoPointKey;
