import UserPropertiesManager from 'service/UserForm/UserPropertiesManager';
import TravelingPreferences from 'service/UserForm/TravelingPreferences';
import TravelingPreferencesStorage from 'service/UserForm/TravelingPreferencesStorage';
import MassUsersTravelingPreferencesDialogManager from 'service/UserForm/MassUsersTravelingPreferencesDialogManager';

const userPropertiesManager = new UserPropertiesManager();
const travelingPreferences = new TravelingPreferences();
const travelingPreferencesStorage = new TravelingPreferencesStorage();
const massUsersTravelingPreferencesDialogManager = new MassUsersTravelingPreferencesDialogManager();

export {
    userPropertiesManager,
    travelingPreferences,
    travelingPreferencesStorage,
    massUsersTravelingPreferencesDialogManager,
};
