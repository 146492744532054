import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderRow as BaseTableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

export class TableHeaderRow extends React.PureComponent {
    render() {
        const props = {
            ...this.props,
            titleComponent: this.props.columnTitles
                ? ({ children: columnTitle }) => {
                      return (
                          <BaseTableHeaderRow.Title>
                              {this.props.columnTitles.includes(columnTitle) ? columnTitle : null}
                          </BaseTableHeaderRow.Title>
                      );
                  }
                : BaseTableHeaderRow.Title,
            cellComponent: this.props.cellStyles
                ? withStyles(this.props.cellStyles)(BaseTableHeaderRow.Cell)
                : BaseTableHeaderRow.Cell,
        };
        return <BaseTableHeaderRow {...props} />;
    }
}

TableHeaderRow.propTypes = {
    ...BaseTableHeaderRow.propTypes,
    columnTitles: PropTypes.array,
    cellStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
