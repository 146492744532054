import Dexie, { IndexableType } from 'dexie';
import { DbTerritories } from './DbTerritories';
import {
    TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE,
    TERRITORIES_GEO_JSON_WITH_UNION_TABLE,
    TERRITORIES_LAYERS_TABLE,
} from './DbTerritoriesIndexDb';

export class DbTerritoriesMemory implements DbTerritories {
    cache: { [key: string]: any } = {};

    constructor() {
        this.cache = {
            [TERRITORIES_GEO_JSON_WITH_UNION_TABLE]: new Map(),
            [TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE]: new Map(),
            [TERRITORIES_LAYERS_TABLE]: new Map(),
        };
    }

    getLayersProperties(uuids: IndexableType[]) {
        return this.loadData(uuids, TERRITORIES_LAYERS_TABLE);
    }

    loadData(uuids: IndexableType[], tableName: string) {
        let results: (object | undefined)[] = [];

        uuids.forEach((uuid) => {
            if (this.cache[tableName].has(uuid)) {
                results.push(this.cache[tableName].get(uuid));
            } else {
                results.push(undefined);
            }
        });

        return Dexie.Promise.resolve(results);
    }

    getSimplifySourcesData(uuids: IndexableType[]) {
        return this.loadData(uuids, TERRITORIES_GEO_JSON_WITH_UNION_TABLE);
    }

    getFullSourcesData(uuids: IndexableType[]) {
        return this.loadData(uuids, TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE);
    }

    setData(items: any[], tableName: string) {
        let results: string[] = [];
        items.forEach((item) => {
            this.cache[tableName].set(item.uuid, item);
            results.push(item.uuid);
        });

        return Dexie.Promise.resolve(results);
    }

    setTerritoryLayersProperties(items: any[]) {
        return this.setData(items, TERRITORIES_LAYERS_TABLE);
    }

    setSimplifySourcesData(items: IndexableType[]) {
        return this.setData(items, TERRITORIES_GEO_JSON_WITH_UNION_TABLE);
    }

    setFullSourcesData(items: any[]) {
        return this.setData(items, TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE);
    }

    clearTerritoriesSourceData() {
        this.cache[TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE] = new Map();
        this.cache[TERRITORIES_GEO_JSON_WITH_UNION_TABLE] = new Map();
        return Dexie.Promise.resolve();
    }

    clearTerritoriesLayerData() {
        this.cache[TERRITORIES_LAYERS_TABLE] = new Map();
        return Dexie.Promise.resolve();
    }

    isConnect(): boolean {
        return true;
    }

    close(): void {}
}
