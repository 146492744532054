import React from 'react';

import PropTypes from 'prop-types';
import './style.css';
import { logManager } from '../../service/LogManager';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { Button, Checkbox, Icon, MenuItem, TableCell, withStyles } from '@material-ui/core';
import { DataTable } from '../DataTable';
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import debounce from 'lodash/debounce';
import { userTimezoneToUtc } from '../../utils';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import { withTranslation } from 'react-i18next';
import { MultipleSelect } from '../Controls';
import DateTime from '../DateTime';

const LevelFilter = ({ filter, onFilter, classes, t }) => {
    const LEVELS_TYPE = [
        { key: 'info', value: t('event_log.levels_type.info') },
        { key: 'error', value: t('event_log.levels_type.error') },
        { key: 'warning', value: t('event_log.levels_type.warning') },
    ];

    return (
        <TableCell className={classes.cell}>
            <div>
                <MultipleSelect
                    value={filter ? filter.value : []}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
                    className="level"
                    data-testid="event_log.level"
                >
                    {LEVELS_TYPE.map((level) => (
                        <MenuItem key={level.key} value={level.key}>
                            {level.value}
                        </MenuItem>
                    ))}
                </MultipleSelect>
            </div>
        </TableCell>
    );
};

const CategoryFilter = ({ filter, onFilter, classes, t }) => {
    const CATEGORIES_TYPE = [
        { key: 'user actions', value: t('event_log.categories_type.user_actions') },
        { key: 'import events', value: t('event_log.categories_type.import_events') },
    ];

    return (
        <TableCell className={classes.cell}>
            <div>
                <MultipleSelect
                    value={filter ? filter.value : []}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={(e) => onFilter(e.target.value ? { value: e.target.value } : null)}
                    className="category"
                    data-testid="event_log.category"
                >
                    {CATEGORIES_TYPE.map((category) => (
                        <MenuItem key={category.key} value={category.key}>
                            {category.value}
                        </MenuItem>
                    ))}
                </MultipleSelect>
            </div>
        </TableCell>
    );
};

const CheckboxModeFilter = ({ filter, onFilter, classes }) => (
    <TableCell className={classes.cell}>
        <div>
            <Checkbox
                style={{ marginLeft: 0, marginRight: 0 }}
                checked={filter ? filter.value : false}
                onChange={(e) => onFilter({ value: e.target.checked })}
                value="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </div>
    </TableCell>
);

const FilterCell = (props) => {
    const { column, t, tReady, i18n, ...rest } = props;
    if (column.name === 'createdAt') {
        return <DateBetweenFilter {...rest} />;
    }

    if (column.name === 'level') {
        return <LevelFilter {...{ t, ...rest }} />;
    }

    if (column.name === 'channel') {
        return <CategoryFilter {...{ t, ...rest }} />;
    }

    if (column.name === 'isDebug' || column.name === 'ghosting') {
        return <CheckboxModeFilter {...rest} />;
    }

    return <TableFilterRow.Cell {...rest} />;
};

class EventLog extends DataTable {
    constructor(props) {
        super(props);
        this.pageSizes = [50, 100, 200];
        this.state = {
            loading: true,
            rows: [],
            pagination: {
                current: 0,
                size: 50,
            },
            totalCount: 0,
            details: {},
            detailsLoading: {},
        };

        this.filters = [
            {
                columnName: 'createdAt',
                value: {
                    dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                    dateTo: new Date(),
                },
            },
        ];

        this.sorting = [
            {
                columnName: 'createdAt',
                direction: 'desc',
            },
        ];

        this.columns = [
            {
                name: 'createdAt',
                title: props.t('event_log.columns.createdAt.title'),
                getCellValue: (row) => (
                    <span>
                        <DateTime>{row.createdAt}</DateTime>
                    </span>
                ),
            },
            {
                name: 'level',
                title: props.t('event_log.columns.level.title'),
                getCellValue: (row) => (
                    <div style={{ display: 'flex' }}>
                        {row.level === 'Info' && (
                            <Icon className="status-icon" style={{ color: '#87ca8a' }}>
                                info
                            </Icon>
                        )}
                        {row.level === 'Error' && (
                            <Icon className="status-icon" style={{ color: '#e77a7a' }}>
                                error
                            </Icon>
                        )}
                        {row.level === 'Warning' && (
                            <Icon className="status-icon" style={{ color: '#ffd758' }}>
                                warning
                            </Icon>
                        )}
                        <span className="status-name">{row.level}</span>
                    </div>
                ),
            },
            {
                name: 'channel',
                title: props.t('event_log.columns.channel.title'),
                getCellValue: (row) => <span>{row.channel}</span>,
            },
            {
                name: 'messageHuman',
                title: props.t('event_log.columns.message_human.title'),
                getCellValue: (row) => (
                    <div>
                        <span>{row.messageHuman}</span>
                    </div>
                ),
            },
            {
                name: 'context',
                title: props.t('event_log.columns.context.title'),
                getCellValue: (row) => {
                    if (!row.hasContext) {
                        return null;
                    }
                    if (this.state.detailsLoading[row.id]) {
                        return <div style={{ padding: '12px 8px 0 16px' }}>{props.t('loading')}</div>;
                    }
                    if (!this.state.details[row.id]) {
                        return (
                            <Button
                                color="primary"
                                onClick={this.requestDetails(row.id)}
                                data-testid="event_log.see_details"
                            >
                                {props.t('event_log.see_details')}
                            </Button>
                        );
                    }
                    switch (this.state.details[row.id].type) {
                        case 'update_failed_territories':
                            return (
                                <ul className="event-log__context">
                                    {Object.keys(this.state.details[row.id].data).map((key) => {
                                        const details = this.state.details[row.id].data[key];
                                        let id = key;
                                        if (details.link) {
                                            id = (
                                                <a
                                                    href={details.link.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title={details.link.text}
                                                >
                                                    {id}
                                                </a>
                                            );
                                        }
                                        return (
                                            <li key={key}>
                                                {id}: {details.objectName} <small>({details.territory})</small>
                                            </li>
                                        );
                                    })}
                                </ul>
                            );
                        case 'update_failed_geo_fields':
                            return (
                                <ul className="event-log__context">
                                    {Object.keys(this.state.details[row.id].data).map((key) => {
                                        const details = this.state.details[row.id].data[key];
                                        return <li key={key}>{details}</li>;
                                    })}
                                </ul>
                            );
                        default:
                            return null;
                    }
                },
            },
        ];

        this.columnExtensions = [
            {
                columnName: 'createdAt',
                width: 200,
            },
            {
                columnName: 'level',
                width: 100,
            },
            {
                columnName: 'channel',
                width: 100,
            },
            {
                columnName: 'messageHuman',
                wordWrapEnabled: true,
            },
        ];

        if (this.props.isDebugShow) {
            this.columns.push(
                {
                    name: 'ghosting',
                    title: props.t('event_log.columns.ghosting.title'),
                    getCellValue: (row) => <div>{row.ghosting && <span>{row.ghosting.name}</span>}</div>,
                },
                {
                    name: 'isDebug',
                    title: props.t('event_log.columns.is_debug.title'),
                    getCellValue: () => null,
                },
            );

            this.columnExtensions.push(
                {
                    columnName: 'isDebug',
                    width: 80,
                },
                {
                    columnName: 'ghosting',
                    width: 200,
                },
            );
        }

        this.pagingPanelMessages = DataTable.getPagingMessages(props.t);
        this.filterMessages = DataTable.getFilterMessages(props.t);

        this.handleChangeFilters = debounce(this.handleChangeFilters.bind(this), 500);
    }

    componentDidMount() {
        this.loadData();
        this.setupReloadOnTimeZoneChange();
    }

    requestDetails = (id) => (e) => {
        this.setState((state) => {
            return {
                detailsLoading: { ...state.detailsLoading, [id]: true },
            };
        });

        logManager
            .getDetails(this.props.accountId, id)
            .then((response) => {
                this.setState((state) => {
                    return {
                        detailsLoading: { ...state.detailsLoading, [id]: undefined },
                        details: { ...state.details, [id]: response.details },
                    };
                });
            })
            .finally(() => {
                this.setState((state) => {
                    return {
                        detailsLoading: { ...state.detailsLoading, [id]: undefined },
                    };
                });
            });
    };

    handleChangeFilters = (filters) => {
        this.filters = filters;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            () => this.loadData(),
        );
    };

    handleChangeSorting = (sorting) => {
        this.sorting = sorting;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            () => this.loadData(),
        );
    };

    formatFilters(filters) {
        let formatFilters = [];
        filters.forEach((filter) => {
            if (filter.columnName === 'createdAt') {
                formatFilters['dateFrom'] = userTimezoneToUtc(filter.value.dateFrom, this.props.user);
                formatFilters['dateTo'] = userTimezoneToUtc(filter.value.dateTo, this.props.user);
            } else {
                formatFilters[filter.columnName] = filter.value;
            }
        });
        return formatFilters;
    }

    formatSorting(sorting) {
        let formatSorting = [];
        sorting.forEach((sort) => {
            formatSorting['column'] = sort.columnName;
            formatSorting['direction'] = sort.direction;
        });
        return formatSorting;
    }

    loadData() {
        logManager
            .getAuditLog(
                this.props.accountId,
                this.formatFilters(this.getFilters()),
                this.formatSorting(this.sorting),
                this.state.pagination.current + 1,
                this.state.pagination.size,
            )
            .then((data) => {
                this.setState({
                    loading: false,
                    rows: data.logs,
                    totalCount: data.count,
                    details: {},
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });

        this.setState({
            loading: true,
            rows: [],
        });
    }

    render() {
        return (
            <div className="log event-log">
                <Grid rows={[]} columns={this.columns}>
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </Grid>
                <Grid rows={this.state.rows} columns={this.columns}>
                    <FilteringState defaultFilters={this.filters} onFiltersChange={this.handleChangeFilters} />
                    <SortingState defaultSorting={this.sorting} onSortingChange={this.handleChangeSorting} />
                    <Table
                        columnExtensions={this.columnExtensions}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                        )}
                        cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                        rowComponent={StyledRow}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        showSortingControls
                    />
                    <TableFilterRow cellComponent={StyledFilterCell} messages={this.filterMessages} />
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </Grid>
            </div>
        );
    }
}

EventLog.propTypes = {
    accountId: PropTypes.number.isRequired,
    isDebugShow: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
};

EventLog.defaultProps = {
    isDebugShow: false,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
        verticalAlign: 'top',
    },
});

const stylesForCustomFilter = (theme) => ({
    cell: {
        width: '100%',
        padding: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
});

const StyledFilterCell = withTranslation()(withStyles(stylesForCustomFilter)(FilterCell));

const PagingContainer = (props) => <PagingPanel.Container {...props} className="table-pagination" />;

const Row = (props) => {
    const { children, classes, ...rest } = props;

    let className;
    const isDebug = rest.tableRow.row['isDebug'];
    if (isDebug) {
        className = classes.debug;
    }

    return (
        <Table.Row {...rest} className={className}>
            {children}
        </Table.Row>
    );
};

const rowStyles = {
    debug: {
        backgroundColor: '#fff9d4',
    },
};
const StyledRow = withStyles(rowStyles)(Row);

export default withTranslation()(EventLog);
