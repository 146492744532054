import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { v4 as uuidv4 } from 'uuid';
import dispatcher from '../dispatcher';
import events from '../../events';
import { userManager } from '../UserManager';
import debounce from 'lodash/debounce';
import mapFormManager from './MapFormManager';

const DEFAULT_TIMEOUT = 30000;

class DirectActionManager extends BackendService {
    constructor() {
        super();

        this.running = new Map();
        this.buttonRequestMap = new Map();

        dispatcher.subscribe(events.WS_FINISHED_WORKFLOW_CHAIN, this, (payload) => {
            if (!payload.runId) {
                return;
            }

            this.stopAction(payload.runId, payload.recordCountMismatch);
        });
        dispatcher.subscribe(events.WEBSOCKET_IO_SERVER_CONNECT, this, () => {
            if (!mapFormManager.isReady()) {
                this.initSearchBarFilter();
            }
        });

        this.initSearchBarFilter = debounce(this.initSearchBarFilter.bind(this), 1500);
    }

    initSearchBarFilter() {
        const mapForm = userManager.getCurrentMapForm();
        if (mapForm && mapForm.id) {
            this.runSearchBarFilter(userManager.getCurrentAccount().id, mapForm.id, ['openMapForm'], {
                mapFormId: mapForm.id,
            });
        }
    }

    refresh(data) {
        dispatcher.dispatch(events.WORKFLOW_ACTIONS_RUNNING_CHANGED, data);
    }

    hasRunningActions() {
        return this.running.size > 0;
    }

    stopAction(uuid, recordCountMismatch = null) {
        if (!this.running.has(uuid)) {
            return;
        }

        clearTimeout(this.running.get(uuid));

        this.running.delete(uuid);

        this.refresh({ runId: uuid, recordCountMismatch });
    }

    startAction() {
        const uuid = uuidv4();

        this.running.set(
            uuid,
            setTimeout(() => {
                this.stopAction(uuid);
            }, DEFAULT_TIMEOUT),
        );

        this.refresh();

        return uuid;
    }

    /**
     * @param accountId
     * @param buttonId
     * @param entityId
     * @param itemIds
     * @param forAllItems
     * @param additionalContextData
     * @param contextUlid
     * @param filters
     * @param recordsCount
     * @param event
     */
    runButton(
        accountId,
        buttonId,
        entityId,
        itemIds,
        forAllItems,
        additionalContextData = null,
        contextUlid = null,
        filters = {},
        recordsCount = null,
        event = null,
    ) {
        const url = reverse(apiRoutes.account.workflow.run.button, { accountId, buttonId });
        const runId = this.startAction();
        const data = {
            entityId,
            additionalContextData,
            itemIds,
            forAllItems,
            contextUlid,
            runId,
            recordsCount,
            event,
            ...filters,
        };
        this.buttonRequestMap.set(runId, { accountId, buttonId, data });

        return this.requestApi(url, 'POST', data).catch((error) => {
            this.stopAction(runId);
            throw error;
        });
    }

    forceRunButton(runId) {
        const request = this.buttonRequestMap.get(runId);
        if (!request) {
            return;
        }

        const url = reverse(apiRoutes.account.workflow.run.button, {
            accountId: request.accountId,
            buttonId: request.buttonId,
        });
        const data = request.data;
        data.runId = this.startAction();
        data.force = true;
        this.buttonRequestMap.delete(runId);

        return this.requestApi(url, 'POST', data).catch((error) => {
            this.stopAction(runId);
            throw error;
        });
    }

    /**
     * @param accountId
     * @param actionGroupIds
     * @param formActionId
     * @param entityId
     * @param additionalContextData
     * @param contextUlid
     * @param triggerEventData
     */
    runFormEvent(
        accountId,
        formActionId,
        actionGroupIds,
        entityId,
        additionalContextData = null,
        contextUlid = null,
        triggerEventData = null,
        event = null,
    ) {
        const url = reverse(apiRoutes.account.workflow.run.formEvent, { accountId, formActionId });
        const runId = this.startAction();

        return this.requestApi(url, 'POST', {
            formActionId,
            actionGroupIds,
            entityId,
            additionalContextData,
            contextUlid,
            triggerEventData,
            event,
            runId: runId,
        }).catch((error) => {
            this.stopAction(runId);
            throw error;
        });
    }

    /**
     * @param accountId
     * @param actionGroupIds
     * @param formActionId
     * @param additionalContextData
     */
    runSearchBarFilter(accountId, formActionId, actionGroupIds, additionalContextData = null) {
        const url = reverse(apiRoutes.account.workflow.run.searchBarFilter, { accountId, formActionId });

        return this.requestApi(url, 'POST', {
            formActionId,
            actionGroupIds,
            additionalContextData,
        });
    }
}

export const directActionManager = new DirectActionManager();
