import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';

export const PERMISSION_HIDE = 0;
export const PERMISSION_VIEW = 1;
export const PERMISSION_MODIFY = 2;

export const ANALYTICS_MODULE = {
    NAME: 'analytics',
    FEATURES: {
        VIEW_CHARTS_AND_DASHBOARDS: { NAME: 'view_charts_and_dashboards' },
        CREATE_OR_MODIFY: { NAME: 'create_or_modify' },
        ADMIN: { NAME: 'admin' },
        SQL_LAB: { NAME: 'sql_lab' },
    },
};

export const MAP_MODULE = {
    NAME: 'map',
    FEATURES: {
        ISOCIRCLES: { NAME: 'isocircles' },
        ISOCHRONES: { NAME: 'isochrones' },
        HEATMAP: { NAME: 'heatmap' },
        MODIFY_SETTING: { NAME: 'modify_map_settings' },
        LOCATION_HISTORY: { NAME: 'location_history' },
        SEARCH_FILTER: { NAME: 'search_filter' },
        RIGHT_CLICK: {
            NAME: 'map_right_click',
            SUB_FEATURES: {
                CREATE_RECORD_FROM_MAP: {
                    NAME: 'create_record_from_map',
                },
            },
        },
        BASE_LOCATION: { NAME: 'base_location' },
        PRINT_MAP: { NAME: 'print_map' },
    },
};

export const TABLE_MODULE = {
    NAME: 'table',
    FEATURES: {
        INCLUDE_UNMAPPED_RECORDS: { NAME: 'include_unmapped_records' },
        SHOW_HIDE_TABLE_COLUMNS: { NAME: 'show_hide_table_columns' },
        DOWNLOAD_CSV: { NAME: 'download_csv' },
    },
};

export const RECORD_MODULE = {
    NAME: 'record',
    FEATURES: {
        SHOW_HIDE_RECORD_COLUMNS: { NAME: 'show_hide_record_columns' },
        VIEW_ALL_RECORD_COLUMNS: { NAME: 'view_all_record_columns' },
    },
};

export const PROSPECTING_MODULE = {
    NAME: 'prospecting',
    FEATURES: {
        EXPORT: {
            NAME: 'export',
            SUB_FEATURES: {
                MODIFY_FIELD_MAPPING: {
                    NAME: 'modify_field_mapping',
                },
            },
        },
    },
};

export const LAYERS_MODULE = {
    NAME: 'layers',
    FEATURES: {
        SHOW_LAYERS_PANE: { NAME: 'show_layers_pane' },
        MODIFY_MAP_PROPERTIES: { NAME: 'modify_map_properties' },
        MODIFY_VIEW_LAYERS: { NAME: 'modify_view_layers' },
    },
};

export const LIST_VIEW_MODULE = {
    NAME: 'list_view',
    FEATURES: {
        MODIFY_LIST_VIEW_PROPERTIES: { NAME: 'modify_list_view_properties' },
    },
};

export const ROUTING_MODULE = {
    NAME: 'routing',
    FEATURES: {
        SHARE_ROUTES: { NAME: 'share_routes' },
        LIST_ALL_PROFILES: {
            NAME: 'list_all_profiles',
            SUB_FEATURES: {
                MODIFY_ALL_PROFILES: {
                    NAME: 'modify_all_profiles',
                },
            },
        },
        BASIC_TRAVELING_PREFERENCES: {
            NAME: 'basic_traveling_preferences',
            SUB_FEATURES: {
                MODIFY_BASIC_PREFERENCES: {
                    NAME: 'modify_basic_preferences',
                },
            },
        },
        ADVANCED_TRAVELING_PREFERENCES: {
            NAME: 'advanced_traveling_preferences',
            SUB_FEATURES: {
                MODIFY_ADVANCED_PREFERENCES: {
                    NAME: 'modify_advanced_preferences',
                },
                MODIFY_OTHER_USERS_PREFERENCES: {
                    NAME: 'modify_other_users_preferences',
                },
            },
        },
        REDESIGN_ALL_SESSIONS: {
            NAME: 'redesign_all_sessions',
        },
    },
};

export const TERRITORIES_MODULE = {
    NAME: 'territories',
    FEATURES: {
        SHOW_TERRITORIES_PANE: {
            NAME: 'show_territories_pane',
            SUB_FEATURES: {
                MODIFY_TERRITORIES: {
                    NAME: 'modify_territories',
                },
            },
        },
    },
};

export const SHARING_MODULE = {
    NAME: 'sharing',
    FEATURES: {
        BROWSE_USERS: { NAME: 'browse_users' },
        BROWSE_USER_PROFILES: { NAME: 'browse_user_profiles' },
    },
};

export const OTHERS_MODULE = {
    NAME: 'others',
    FEATURES: {
        CHANGE_HISTORY: { NAME: 'change_history' },
    },
};

export const CALENDAR_MODULE = {
    NAME: 'calendar',
    FEATURES: {
        UI: {
            NAME: 'ui',
            SUB_FEATURES: {
                VIEW_CALENDAR_PREFERENCES: {
                    NAME: 'view_calendar_preferences',
                    SUB_SUB_FEATURES: {
                        MODIFY_PREFERENCES: {
                            NAME: 'modify_preferences',
                        },
                        MODIFY_OTHER_USERS_PREFERENCES: {
                            NAME: 'modify_other_users_preferences',
                        },
                    },
                },
            },
        },
    },
};

export const UI_FEATURES_ADMIN = {
    [ANALYTICS_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [ANALYTICS_MODULE.FEATURES.VIEW_CHARTS_AND_DASHBOARDS.NAME]: {
                enable: false,
                subFeatures: {
                    [ANALYTICS_MODULE.FEATURES.CREATE_OR_MODIFY.NAME]: {
                        enable: false,
                        subSubFeatures: {
                            [ANALYTICS_MODULE.FEATURES.ADMIN.NAME]: { enable: false },
                            [ANALYTICS_MODULE.FEATURES.SQL_LAB.NAME]: { enable: false },
                        },
                    },
                },
            },
        },
    },
    [MAP_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [MAP_MODULE.FEATURES.ISOCHRONES.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.HEATMAP.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.MODIFY_SETTING.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.SEARCH_FILTER.NAME]: { enable: false },
            [MAP_MODULE.FEATURES.RIGHT_CLICK.NAME]: {
                enable: true,
                subFeatures: {
                    [MAP_MODULE.FEATURES.RIGHT_CLICK.SUB_FEATURES.CREATE_RECORD_FROM_MAP.NAME]: { enable: true },
                },
            },
            [MAP_MODULE.FEATURES.BASE_LOCATION.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.ISOCIRCLES.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.LOCATION_HISTORY.NAME]: { enable: true },
            [MAP_MODULE.FEATURES.PRINT_MAP.NAME]: { enable: true },
        },
    },
    [TABLE_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [TABLE_MODULE.FEATURES.INCLUDE_UNMAPPED_RECORDS.NAME]: { enable: true },
            [TABLE_MODULE.FEATURES.SHOW_HIDE_TABLE_COLUMNS.NAME]: { enable: true },
            [TABLE_MODULE.FEATURES.DOWNLOAD_CSV.NAME]: { enable: true },
        },
    },
    [RECORD_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [RECORD_MODULE.FEATURES.SHOW_HIDE_RECORD_COLUMNS.NAME]: { enable: true },
            [RECORD_MODULE.FEATURES.VIEW_ALL_RECORD_COLUMNS.NAME]: { enable: true },
        },
    },
    [PROSPECTING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [PROSPECTING_MODULE.FEATURES.EXPORT.NAME]: {
                enable: true,
                subFeatures: {
                    [PROSPECTING_MODULE.FEATURES.EXPORT.SUB_FEATURES.MODIFY_FIELD_MAPPING.NAME]: { enable: true },
                },
            },
        },
    },
    [LAYERS_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [LAYERS_MODULE.FEATURES.SHOW_LAYERS_PANE.NAME]: { enable: true },
            [LAYERS_MODULE.FEATURES.MODIFY_MAP_PROPERTIES.NAME]: { enable: true },
            [LAYERS_MODULE.FEATURES.MODIFY_VIEW_LAYERS.NAME]: { enable: true },
        },
    },
    [ROUTING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [ROUTING_MODULE.FEATURES.LIST_ALL_PROFILES.NAME]: {
                enable: true,
                subFeatures: {
                    [ROUTING_MODULE.FEATURES.LIST_ALL_PROFILES.SUB_FEATURES.MODIFY_ALL_PROFILES.NAME]: { enable: true },
                },
            },
            [ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME]: {
                enable: true,
                subFeatures: {
                    [ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_BASIC_PREFERENCES.NAME]: {
                        enable: true,
                    },
                },
            },
            [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME]: {
                enable: true,
                subFeatures: {
                    [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_ADVANCED_PREFERENCES
                        .NAME]: { enable: true },
                    [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_OTHER_USERS_PREFERENCES
                        .NAME]: { enable: true },
                },
            },
            [ROUTING_MODULE.FEATURES.REDESIGN_ALL_SESSIONS.NAME]: { enable: true },
        },
    },
    [LIST_VIEW_MODULE.NAME]: {
        allowDisableModule: true,
        enable: false,
        features: {
            [LIST_VIEW_MODULE.FEATURES.MODIFY_LIST_VIEW_PROPERTIES.NAME]: { enable: false },
        },
    },
    [TERRITORIES_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.NAME]: {
                enable: true,
                subFeatures: {
                    [TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.SUB_FEATURES.MODIFY_TERRITORIES.NAME]: {
                        enable: true,
                    },
                },
            },
        },
    },
    [SHARING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [SHARING_MODULE.FEATURES.BROWSE_USERS.NAME]: { enable: true },
            [SHARING_MODULE.FEATURES.BROWSE_USER_PROFILES.NAME]: { enable: true },
        },
    },
    [CALENDAR_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [CALENDAR_MODULE.FEATURES.UI.NAME]: {
                enable: true,
                subFeatures: {
                    [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.NAME]: {
                        enable: true,
                        subSubFeatures: {
                            [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES
                                .MODIFY_PREFERENCES.NAME]: {
                                enable: true,
                            },
                            [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES
                                .MODIFY_OTHER_USERS_PREFERENCES.NAME]: { enable: true },
                        },
                    },
                },
            },
        },
    },
    [OTHERS_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [OTHERS_MODULE.FEATURES.CHANGE_HISTORY.NAME]: { enable: true },
        },
    },
};

export const UI_FEATURES_DEFAULT = mergeWith(cloneDeep(UI_FEATURES_ADMIN), {
    [ANALYTICS_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [ANALYTICS_MODULE.FEATURES.VIEW_CHARTS_AND_DASHBOARDS.NAME]: {
                enable: false,
                subFeatures: {
                    [ANALYTICS_MODULE.FEATURES.CREATE_OR_MODIFY.NAME]: {
                        enable: false,
                        subSubFeatures: {
                            [ANALYTICS_MODULE.FEATURES.ADMIN.NAME]: { enable: false },
                            [ANALYTICS_MODULE.FEATURES.SQL_LAB.NAME]: { enable: false },
                        },
                    },
                },
            },
        },
    },
    [MAP_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [MAP_MODULE.FEATURES.LOCATION_HISTORY.NAME]: { enable: false },
            [MAP_MODULE.FEATURES.PRINT_MAP.NAME]: { enable: true },
        },
    },
    [TABLE_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [TABLE_MODULE.FEATURES.DOWNLOAD_CSV.NAME]: { enable: false },
        },
    },
    [RECORD_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [RECORD_MODULE.FEATURES.SHOW_HIDE_RECORD_COLUMNS.NAME]: { enable: true },
            [RECORD_MODULE.FEATURES.VIEW_ALL_RECORD_COLUMNS.NAME]: { enable: true },
        },
    },
    [PROSPECTING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [PROSPECTING_MODULE.FEATURES.EXPORT.NAME]: {
                enable: true,
                subFeatures: {
                    [PROSPECTING_MODULE.FEATURES.EXPORT.SUB_FEATURES.MODIFY_FIELD_MAPPING.NAME]: { enable: false },
                },
            },
        },
    },
    [ROUTING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME]: {
                enable: true,
                subFeatures: {
                    [ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_BASIC_PREFERENCES.NAME]: {
                        enable: true,
                    },
                },
            },
            [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME]: {
                enable: false,
                subFeatures: {
                    [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_ADVANCED_PREFERENCES
                        .NAME]: { enable: false },
                    [ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_OTHER_USERS_PREFERENCES
                        .NAME]: { enable: false },
                },
            },
            [ROUTING_MODULE.FEATURES.REDESIGN_ALL_SESSIONS.NAME]: { enable: false },
        },
    },
    [SHARING_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [SHARING_MODULE.FEATURES.BROWSE_USERS.NAME]: { enable: false },
            [SHARING_MODULE.FEATURES.BROWSE_USER_PROFILES.NAME]: { enable: false },
        },
    },
    [CALENDAR_MODULE.NAME]: {
        allowDisableModule: true,
        enable: true,
        features: {
            [CALENDAR_MODULE.FEATURES.UI.NAME]: {
                enable: true,
                subFeatures: {
                    [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.NAME]: {
                        enable: true,
                        subSubFeatures: {
                            [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES
                                .MODIFY_PREFERENCES.NAME]: {
                                enable: false,
                            },
                            [CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES
                                .MODIFY_OTHER_USERS_PREFERENCES.NAME]: { enable: false },
                        },
                    },
                },
            },
        },
    },
    [OTHERS_MODULE.NAME]: {
        allowDisableModule: false,
        enable: true,
        features: {
            [OTHERS_MODULE.FEATURES.CHANGE_HISTORY.NAME]: { enable: false },
        },
    },
});

export const DISABLED_MODULES_SHARED_MAP = [PROSPECTING_MODULE.NAME, ROUTING_MODULE.NAME, CALENDAR_MODULE.NAME];
export const DISABLED_FEATURES_SHARED_MAP = [
    { module: MAP_MODULE.NAME, feature: MAP_MODULE.FEATURES.MODIFY_SETTING.NAME },
    { module: TABLE_MODULE.NAME, feature: TABLE_MODULE.FEATURES.SHOW_HIDE_TABLE_COLUMNS.NAME },
    { module: RECORD_MODULE.NAME, feature: RECORD_MODULE.FEATURES.SHOW_HIDE_RECORD_COLUMNS.NAME },
    { module: RECORD_MODULE.NAME, feature: RECORD_MODULE.FEATURES.VIEW_ALL_RECORD_COLUMNS.NAME },
    { module: LIST_VIEW_MODULE.NAME, feature: LIST_VIEW_MODULE.FEATURES.MODIFY_LIST_VIEW_PROPERTIES.NAME },
    { module: ROUTING_MODULE.NAME, feature: ROUTING_MODULE.FEATURES.LIST_ALL_PROFILES.NAME },
    { module: LAYERS_MODULE.NAME, feature: LAYERS_MODULE.FEATURES.MODIFY_MAP_PROPERTIES.NAME },
    { module: LAYERS_MODULE.NAME, feature: LAYERS_MODULE.FEATURES.MODIFY_VIEW_LAYERS.NAME },
    { module: PROSPECTING_MODULE.NAME, feature: PROSPECTING_MODULE.FEATURES.EXPORT.NAME },
    { module: OTHERS_MODULE.NAME, feature: OTHERS_MODULE.FEATURES.CHANGE_HISTORY.NAME },
    { module: ANALYTICS_MODULE.NAME, feature: ANALYTICS_MODULE.FEATURES.VIEW_CHARTS_AND_DASHBOARDS.NAME },
];
export const DISABLED_SUB_FEATURES_SHARED_MAP = [
    {
        module: TERRITORIES_MODULE.NAME,
        feature: TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.NAME,
        subFeature: TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.SUB_FEATURES.MODIFY_TERRITORIES.NAME,
    },
];
export const DEPENDENT_FEATURES_MAP = [
    {
        module: MAP_MODULE.NAME,
        feature: MAP_MODULE.FEATURES.ISOCIRCLES.NAME,
        dependsOn: [{ module: MAP_MODULE.NAME, feature: MAP_MODULE.FEATURES.BASE_LOCATION.NAME }],
    },
    {
        module: ROUTING_MODULE.NAME,
        feature: ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
        dependsOn: [{ module: ROUTING_MODULE.NAME, feature: ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME }],
    },
    {
        module: ROUTING_MODULE.NAME,
        feature: ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
        subFeature:
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_ADVANCED_PREFERENCES.NAME,
        dependsOn: [
            { module: ROUTING_MODULE.NAME, feature: ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME },
            {
                module: ROUTING_MODULE.NAME,
                feature: ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME,
                subFeature:
                    ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_BASIC_PREFERENCES.NAME,
            },
        ],
    },
    {
        module: ROUTING_MODULE.NAME,
        feature: ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
        subFeature:
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_OTHER_USERS_PREFERENCES.NAME,
        dependsOn: [
            { module: ROUTING_MODULE.NAME, feature: ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME },
            {
                module: ROUTING_MODULE.NAME,
                feature: ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME,
                subFeature:
                    ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_BASIC_PREFERENCES.NAME,
            },
            {
                module: ROUTING_MODULE.NAME,
                feature: ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
                subFeature:
                    ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_ADVANCED_PREFERENCES
                        .NAME,
            },
        ],
    },
];
