import React from 'react';
import { Grid, MenuItem, Icon, Tooltip } from '@material-ui/core';
import Hint from '../Hint/index';
import { reverse, routes } from '../../routes';
import { Link } from 'react-router-dom';
import { userManager } from '../../service/UserManager';
import AddIconButton from '../CustomButton/AddIconButton';
import AutomationRestriction from './AutomationRestriction';
import i18n from '../../locales/i18n';
import { TYPE_OPEN_FORM } from '../../service/WorkflowActionManager';
import EmailSettingsForm from './EmailSettings/EmailSettingsForm';
import PureFormDialog from '../PureFormDialog';
const t = i18n.t.bind(i18n);

interface CurrentProps {
    title: string;
    type: 'rules' | 'buttons' | 'schedules' | 'actions';
    showAddButton: boolean;
    accountId: number | string;
    myAccount: boolean;
    onOpenModal: (automationElement: null) => void;
    addButtonHint?: string;
}

interface CurrentState {
    showEmailSettings: boolean;
}

const RulesLink = (props: CurrentProps) => (
    <Grid item>
        <MenuItem
            className="link-to-route"
            component={Link}
            to={reverse(routes.admin.account.workflowRules, {
                accountId: props.myAccount ? undefined : props.accountId,
            })}
            data-testid="main_menu.workflow_rules"
        >
            <span>{t('main_menu.workflow_rules')}</span>
        </MenuItem>
    </Grid>
);

const ButtonsLink = (props: CurrentProps) => (
    <Grid item>
        <MenuItem
            className="link-to-route"
            component={Link}
            to={reverse(routes.admin.account.workflowButtons, {
                accountId: props.myAccount ? undefined : props.accountId,
            })}
            data-testid="main_menu.workflow_buttons"
        >
            <span>{t('main_menu.workflow_buttons')}</span>
        </MenuItem>
    </Grid>
);

const SchedulesLink = (props: CurrentProps) => (
    <Grid item>
        <MenuItem
            className="link-to-route"
            component={Link}
            to={reverse(routes.admin.account.workflowSchedules, {
                accountId: props.myAccount ? undefined : props.accountId,
            })}
            data-testid="main_menu.workflow_schedules"
        >
            <span>{t('main_menu.workflow_schedules')}</span>
        </MenuItem>
    </Grid>
);

const ActionsLink = (props: CurrentProps) => (
    <Grid item>
        <MenuItem
            className="link-to-route"
            component={Link}
            to={reverse(routes.admin.account.workflowActions, {
                accountId: props.myAccount ? undefined : props.accountId,
            })}
            data-testid="main_menu.workflow_actions"
        >
            <span>{t('main_menu.workflow_actions')}</span>
        </MenuItem>
    </Grid>
);

const FormActionsLink = (props: CurrentProps) => (
    <Grid item>
        <MenuItem
            className="link-to-route"
            component={Link}
            to={reverse(routes.admin.account.workflowActions, {
                accountId: props.myAccount ? undefined : props.accountId,
                type: TYPE_OPEN_FORM,
            })}
            data-testid="main_menu.workflow_actions.forms"
        >
            <span>{t('main_menu.workflow_actions.forms')}</span>
        </MenuItem>
    </Grid>
);

const HelpLink = () => (
    <Grid item>
        <MenuItem
            component="a"
            href="https://help.mapsly.com/articles/5173729"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Grid container item justifyContent="center" spacing={1}>
                <Grid item>
                    <Hint type="inline" iProps={{ style: { opacity: 0.4 } }} />
                </Grid>
                <Grid item className="link-to-route">
                    <span>{t('help')}</span>
                </Grid>
                <Grid item>
                    <Icon style={{ color: 'gray' }} fontSize="small">
                        launch
                    </Icon>
                </Grid>
            </Grid>
        </MenuItem>
    </Grid>
);

export default class AutomationElementHeader extends React.PureComponent<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            showEmailSettings: false,
        };

        this.renderAddButton = this.renderAddButton.bind(this);
        this.renderLinks = this.renderLinks.bind(this);
        this.renderRulesLinks = this.renderRulesLinks.bind(this);
        this.renderButtonsLinks = this.renderButtonsLinks.bind(this);
        this.renderSchedulesLinks = this.renderSchedulesLinks.bind(this);
        this.renderActionsLinks = this.renderActionsLinks.bind(this);
    }

    renderAddButton() {
        if (this.props.showAddButton) {
            return (
                <Tooltip
                    title={this.props.addButtonHint ? t<string>(this.props.addButtonHint) : ''}
                    disableHoverListener={!this.props.addButtonHint}
                    disableFocusListener={!this.props.addButtonHint}
                    disableTouchListener={!this.props.addButtonHint}
                >
                    <Grid item>
                        <AddIconButton
                            onClick={this.props.onOpenModal.bind(this, null)}
                            disabled={!userManager.automationElementsManagement()}
                        />
                    </Grid>
                </Tooltip>
            );
        }
        return null;
    }

    renderLinks() {
        switch (this.props.type) {
            case 'rules':
                return this.renderRulesLinks();
            case 'buttons':
                return this.renderButtonsLinks();
            case 'schedules':
                return this.renderSchedulesLinks();
            case 'actions':
                return this.renderActionsLinks();
        }
    }

    renderRulesLinks() {
        return (
            <React.Fragment>
                <ActionsLink {...this.props} />
                <ButtonsLink {...this.props} />
                <FormActionsLink {...this.props} />
                <SchedulesLink {...this.props} />
                <HelpLink />
            </React.Fragment>
        );
    }

    renderButtonsLinks() {
        return (
            <React.Fragment>
                <RulesLink {...this.props} />
                <ActionsLink {...this.props} />
                <FormActionsLink {...this.props} />
                <SchedulesLink {...this.props} />
                <HelpLink />
            </React.Fragment>
        );
    }

    renderSchedulesLinks() {
        return (
            <React.Fragment>
                <RulesLink {...this.props} />
                <ActionsLink {...this.props} />
                <ButtonsLink {...this.props} />
                <FormActionsLink {...this.props} />
                <HelpLink />
            </React.Fragment>
        );
    }

    renderActionsLinks() {
        return (
            <React.Fragment>
                <RulesLink {...this.props} />
                <ButtonsLink {...this.props} />
                <SchedulesLink {...this.props} />
                <Grid item>
                    <MenuItem
                        className="link-to-route"
                        data-testid="main_menu.workflow_actions.email_settings"
                        onClick={this.toggleEmailSettingsModal}
                    >
                        <span>{t('main_menu.workflow_actions.email_settings')}</span>
                    </MenuItem>
                </Grid>
                <HelpLink />
            </React.Fragment>
        );
    }

    toggleEmailSettingsModal = () => {
        this.setState((state) => ({ showEmailSettings: !state.showEmailSettings }));
    };

    render() {
        return (
            <React.Fragment>
                <AutomationRestriction />
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <h2>{this.props.title}</h2>
                    </Grid>
                    {this.renderAddButton()}
                    {this.renderLinks()}
                </Grid>

                <PureFormDialog
                    open={this.state.showEmailSettings}
                    title={t('email_settings.title')}
                    onClose={this.toggleEmailSettingsModal}
                    maxWidth="sm"
                    fullWidth
                >
                    <EmailSettingsForm accountId={this.props.accountId} onClose={this.toggleEmailSettingsModal} />
                </PureFormDialog>
            </React.Fragment>
        );
    }
}
