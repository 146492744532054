import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Column } from '@devexpress/dx-react-grid';
import '../style.css';
import { DataTableColumn, DataTableProps, DataTableState } from '../../DataTable/types';
import { Action, DataExchangeLog, dataExchangeManager, Event, LogStatus } from '../../../service/DataExchangeManager';
import DataTable from '../../DataTable/DataTable';
import {
    EntityFieldSettings as EntityField,
    TYPE_DATETIME,
    TYPE_STRING,
    TYPE_TEXT_ARRAY,
    UserData,
} from '../../../service/types';
import { utcToUserTimezone } from '../../../utils';
import EventField from './EventField';
import { userManager } from '../../../service/UserManager';
import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';
import metadataManager from '../../../service/MetadataManager';
import { OPERATOR_JSONB_KEY_EXISTS } from '../../utils/tableFilter';

interface CurrentProps extends DataTableProps, WithTranslation {
    recordId: string;
    accountId: number;
    user: UserData;
    entityId: number;
}
interface CurrentState extends DataTableState<DataExchangeLog> {
    loading: boolean;
    filterFields: EntityField[];
    filterFieldValue: string | null;
}

class ChangeHistory extends DataTable<DataExchangeLog, CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            filterFields: [],
            filterFieldValue: null,
        };

        this.sorting = [
            {
                columnName: 'log.createdAt',
                direction: 'desc',
            },
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        metadataManager.requestEntityForUser(this.props.entityId).then((entity: any) => {
            const { fields } = metadataManager.cropExcludedEntityFields(entity);
            this.setState({
                filterFields: fields.sort((a: EntityField, b: EntityField) => a.title.localeCompare(b.title)),
                loading: false,
            });
        });
    }

    buildStructure(fields: DataTableColumn<DataExchangeLog>[]) {
        const structure: any = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'log.createdAt', width: 200 },
            { columnName: 'log.action', align: 'center', width: 130 },
            { columnName: 'log.data', wordWrapEnabled: true },
            { columnName: 'session.event', align: 'right', width: 250 },
            { columnName: 'session.userId', align: 'right', width: 250 },
            { columnName: 'log.status', align: 'center', width: 130 },
            { columnName: 'log.statusMessage', wordWrapEnabled: true },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'log.data', sortingEnabled: false },
            { columnName: 'session.event', sortingEnabled: false },
            { columnName: 'session.userId', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c: Column) => c.title).map((c: Column) => c.title);
        return structure;
    }

    getFields() {
        const columns: DataTableColumn<DataExchangeLog>[] = [
            {
                columnName: 'log.createdAt',
                type: TYPE_DATETIME,
                title: this.props.t('data_exchange_log.columns.created_at'),
                getCellValue: (row) => utcToUserTimezone(row.createdAt, this.props.user),
            },
            {
                columnName: 'log.action',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_log.columns.action'),
                picklist: [
                    { value: Action.Created, label: this.props.t('data_exchange_log.action.created') },
                    { value: Action.Updated, label: this.props.t('data_exchange_log.action.updated') },
                    { value: Action.Deleted, label: this.props.t('data_exchange_log.action.deleted') },
                ],
                getCellValue: (row) => {
                    switch (row.action) {
                        case Action.Created:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('data_exchange_log.action.created')}
                                </span>
                            );
                        case Action.Updated:
                            return (
                                <span className="log-badge log-badge-warning">
                                    {this.props.t('data_exchange_log.action.updated')}
                                </span>
                            );
                        case Action.Deleted:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_log.action.deleted')}
                                </span>
                            );
                    }
                },
            },
            {
                columnName: 'log.data',
                title: this.props.t('data_exchange_log.columns.data'),
                getCellValue: (row) => {
                    const result = [];
                    for (const field of Object.keys(row.data)) {
                        result.push(this.renderFieldChange(row.action, field, row.data[field]));
                    }
                    return result.map((item) => item);
                },
            },
            {
                columnName: 'session.event',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.event'),
                getCellValue: (row) => {
                    const session = row.session;
                    if (!session) {
                        return null;
                    }
                    return <EventField session={session} accountId={this.props.accountId} />;
                },
                picklist: [
                    { value: Event.Button, label: this.props.t('data_exchange_session.event.button') },
                    {
                        value: Event.CreatedManually,
                        label: this.props.t('data_exchange_session.event.created_manually'),
                    },
                    {
                        value: Event.UpdatedManually,
                        label: this.props.t('data_exchange_session.event.updated_manually'),
                    },
                    {
                        value: Event.RemovedManually,
                        label: this.props.t('data_exchange_session.event.removed_manually'),
                    },
                    {
                        value: Event.ScheduledProcess,
                        label: this.props.t('data_exchange_session.event.scheduled_process'),
                    },
                    {
                        value: Event.SsoAutoRegistered,
                        label: this.props.t('data_exchange_session.event.sso_auto_registered'),
                    },
                    { value: Event.Geocoding, label: this.props.t('data_exchange_session.event.geocoding') },
                    { value: Event.Import, label: this.props.t('data_exchange_session.event.import') },
                    {
                        value: Event.InboundApiCall,
                        label: this.props.t('data_exchange_session.event.inbound_api_call'),
                    },
                    { value: Event.ProspectExport, label: this.props.t('data_exchange_session.event.prospect_export') },
                    {
                        value: Event.TerritoryAssignment,
                        label: this.props.t('data_exchange_session.event.territory_assignment'),
                    },
                ],
            },
            {
                columnName: 'session.userId',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.user'),
                picklist: this.getUsersPicklist(),
                getCellValue: (row) => {
                    const session = row.session;
                    if (!session) {
                        return null;
                    }
                    return <span>{session.userName}</span>;
                },
            },
            {
                columnName: 'log.status',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_log.columns.status'),
                picklist: [
                    { value: LogStatus.Success, label: this.props.t('data_exchange_log.status.success') },
                    { value: LogStatus.Fail, label: this.props.t('data_exchange_log.status.fail') },
                ],
                getCellValue: (row) => {
                    switch (row.status) {
                        case LogStatus.Success:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('data_exchange_log.status.success')}
                                </span>
                            );
                        case LogStatus.Fail:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_log.status.fail')}
                                </span>
                            );
                    }
                },
            },
            {
                columnName: 'log.statusMessage',
                type: TYPE_STRING,
                title: this.props.t('data_exchange_log.columns.status_message'),
            },
        ];
        return Promise.resolve(columns);
    }

    requestData(ignorePage = false, _parentTimer = null) {
        return dataExchangeManager.getRecordLogs({
            accountId: this.props.accountId,
            entityId: this.props.entityId,
            recordId: this.props.recordId,
            filters: this.getFilters(),
            sorting: this.sorting,
            page: ignorePage ? 1 : this.state.pagination.current + 1,
            pageSize: this.state.pagination.size,
        });
    }

    getUsersPicklist = () => {
        return userManager.getAccountUsers(this.props.accountId).then((users: UserData[]) => {
            return users.map((user) => ({ value: user.id, label: user.name }));
        });
    };

    renderFieldChange = (action: Action, field: string, changes: { new: any; old?: any }) => {
        switch (action) {
            case Action.Created:
                return (
                    <div key={field}>
                        <b>{field}: </b>
                        {changes.new}
                    </div>
                );
            case Action.Updated:
                return (
                    <div key={field}>
                        <b>{field}: </b>
                        {changes.old}
                        <i className="fas fa-long-arrow-alt-right" style={{ margin: '0 5px' }} />
                        {changes.new}
                    </div>
                );
            case Action.Deleted:
                return null;
        }
    };

    handleFieldFilterChange = (event: React.ChangeEvent<{ name?: string; value: any }>, _: React.ReactNode) => {
        const fieldApiName = event.target.value;
        this.setState({ filterFieldValue: fieldApiName });
        const filters = this.filters.filter((filter) => filter.columnName !== 'log.data');
        if (fieldApiName) {
            filters.push({
                columnName: 'log.data',
                operation: OPERATOR_JSONB_KEY_EXISTS,
                value: fieldApiName,
            });
        }
        this.handleFiltersChanged(filters);
    };

    renderFieldFilterOptions = () => {
        return this.state.filterFields.map((field) => (
            <MenuItem key={field.id} value={field.name}>
                {field.title}
            </MenuItem>
        ));
    };

    render() {
        let title = null;
        const record = this.state.records?.length ? this.state.records[0] : null;
        if (record) {
            title = record.entityName + ' ID# ' + record.recordId + ' "' + record.recordName + '"';
        }
        return (
            <div className="log">
                <Grid container style={{ marginBottom: 20 }} justify="space-between" alignItems="center">
                    <Grid item>{title}</Grid>
                    <Grid item container spacing={1} alignItems="center" xs={3}>
                        <Grid item>{this.props.t('change_history.modal.field_filter.label')}: </Grid>
                        <Grid item xs>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    displayEmpty
                                    value={this.state.filterFieldValue || ''}
                                    onChange={this.handleFieldFilterChange}
                                    data-testid="change_history.modal.field_filter"
                                >
                                    <MenuItem value="" data-testid="change_history.modal.field_filter.default_value">
                                        {this.props.t('change_history.modal.field_filter.default_value')}
                                    </MenuItem>
                                    {this.renderFieldFilterOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {super.render()}
            </div>
        );
    }
}

export default withTranslation()(ChangeHistory);
