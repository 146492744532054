import React, { createElement } from 'react';
import entityViewManager, { VIEW_TABLE } from '../../service/EntityViewManager';
import BaseCheckbox from '@material-ui/core/Checkbox';
import TableContextMenu from './TableContextMenu';
import { SearchDataTable, serverGeocodeStatuses, StyledTooltipCell } from './SearchDataTable';
import PropTypes from 'prop-types';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';
import { formatAddressForWaitingList, formatWithCommas, getAddressFromRecord, logDebug } from '../../utils';
import basePointManager from '../../service/BasePointManager';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { TABLE_MODULE } from '../Permissions/constants';
import { userManager } from '../../service/UserManager';
import viewRecordManager from '../../service/ViewRecordManager';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { PROSPECTING_ID } from './index';
import { GEO_FIELDS } from '../../references/geoFields';
import { FIELD_DISTANCE } from '../Map/constants';
import { withSnackbar } from 'notistack';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import {
    duplicatePointsDialogManager,
    enqueueSnackbarService,
    routeEditDialogManager,
    routingSessionManager,
} from 'service/MapPage';
import { MAP_MODE } from './constants';
import routeLoaderFactory from '../../service/MapPage/Route/RouteLoader';
import { recordManager, withIdsFilter } from 'service/RecordManager';
import { RouteStatus } from '../../interfaces/routing/route';
import { FieldType } from '../types';
import { waitingListManager } from '../../service/Calendar/WaitingListManager';
import { v4 as uuidv4 } from 'uuid';
import metadataManager from '../../service/MetadataManager';
import { WS } from '../../interfaces';

class EmptyResultError extends Error {}

const Checkbox = withStyles({
    root: {
        padding: 4,
    },
})(BaseCheckbox);

/**
 * Treat lookup fields as text to render simple text filter
 */
function patchRenderFilterCell(Component) {
    return function FrontDataTableRenderFilterCell(props) {
        let nextProps = props;
        if (props.column.type === FieldType.STRINGS) {
            nextProps = {
                ...props,
                column: {
                    ...props.column,
                    type: FieldType.STRING,
                },
            };
        }

        return createElement(Component, nextProps);
    };
}

export class FrontDataTable extends SearchDataTable {
    constructor(props) {
        super(props);

        this.RenderFilterCell = patchRenderFilterCell(this.RenderFilterCell);
        this.loadDataDebounce = debounce(() => this.loadData(), 1000);
        this.loadEntityDebounce = debounce((forceUpdate = false) => this.loadEntity(forceUpdate), 1000);
        this.updatingEntityId = null;

        this.recordsLoadingQueue = new Set();

        this.needToReload = false;
        this.ismounted = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.records !== this.state.records) {
            this.props.onRecordsLoaded(this.state.records, this.state.totalCount);
        }
        if (prevState.pagination !== this.state.pagination) {
            this.props.onEntityUpdated(null);
        }

        if (prevProps.withData !== this.props.withData) {
            const prevWithDataColumns = prevProps.withData?.columns.map(({ columnName }) => columnName) ?? [];
            const withDataColumns = this.props.withData?.columns.map(({ columnName }) => columnName) ?? [];
            const droppedColumns = difference(prevWithDataColumns, withDataColumns);

            if (JSON.stringify(prevWithDataColumns) !== JSON.stringify(withDataColumns)) {
                // may be it would be better to drop any filter and sorting if additional data is changed
                this.sorting = this.sorting.filter(({ columnName }) => !droppedColumns.includes(columnName));
                if (this.sortingState.current !== null) {
                    this.sortingState.current.setState({ sorting: cloneDeep(this.sorting) });
                }

                this.filters = this.filters.filter(({ columnName }) => !droppedColumns.includes(columnName));
                if (this.filteringState.current !== null) {
                    this.filteringState.current.setState({ filters: cloneDeep(this.filters) });
                }

                // rebuild structure and reload data
                // selection is cleared by events.ENTITY_DATA_TABLE_ENTITY_CHANGED
                this.loadEntityDebounce();
                return;
            }
        }

        if (
            prevProps.ids !== this.props.ids ||
            prevProps.sections !== this.props.sections ||
            prevProps.bounds !== this.props.bounds
        ) {
            this.props.onEntityUpdated(null);
            this.loadDataDebounce();
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
        this.ismounted = false;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, (payload) => this.reloadSettings(payload.entityIds));
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) =>
            this.reloadSettings(payload.changesEntities),
        );
        dispatcher.subscribe(events.BASE_POINT, this, this.onBasePointUpdated);
        dispatcher.subscribe(events.UPDATE_RECORDS_REQUEST, this, this.onRecordsUpdateStarted);
        dispatcher.subscribe(events.WS_UPDATE_RECORDS_RESPONSE, this, this.onRecordsUpdated);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_MODIFIED, this, this.onRecordsModified);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_DELETED, this, this.onRecordsDeleted);
        dispatcher.subscribe(events.WS_TERRITORIES_UPDATED, this, this.onTerritoriesUpdated);

        dispatcher.subscribe(events.LAYER_NEED_TO_BE_RELOADED, this, ({ entityId }) => {
            if (!!this.props.sections && entityId === this.props.entityId) {
                this.needToReload = true;
            }
        });

        dispatcher.subscribe(events.LAYERS_NEED_TO_BE_RELOADED, this, () => {
            if (this.needToReload) {
                this.loadData();
            }
            this.needToReload = false;
        });

        this.initialFilters = this.filters = entityViewManager.getEntityTableFilters(this.props.entityId);
        this.initialSorting = this.sorting = entityViewManager.getEntityTableSorting(this.props.entityId);

        dispatcher.subscribe(events.WS_ROUTES_CHANGED, this, this.handleRoutesChanged);

        dispatcher.subscribe(events.MAP_FILTER_UPDATED, this, () => {
            if (this.props.mapMode !== MAP_MODE.LAYERS) {
                return;
            }
            this.loadData(true);
        });

        dispatcher.subscribe(events.EVENT_ENTITY_DATA_FILTER_CLEARED, this, () => {
            this.setState((prevState) => ({ key: prevState.key + 1 }));
        });

        this.loadEntityDebounce();

        this.setupReloadOnTimeZoneChange();
        this.ismounted = true;
    }

    /** @param {WS.Routing.RoutesChangedPayload} payload*/
    handleRoutesChanged = (payload) => {
        let shouldLoadData = this.props.isSystemRouteEntity;
        if (shouldLoadData) {
            shouldLoadData = payload.routes?.some((route) => !route.isSystem);
        }
        if (shouldLoadData && payload.action === WS.Routing.RoutesChangedAction.CREATE) {
            shouldLoadData = payload.routes?.some((route) => route.status === RouteStatus.PUBLISHED);
        }
        if (shouldLoadData) {
            this.loadData(true);
        }
    };

    onRecordsModified = ({ entityId, pointIds, modificationType }) => {
        if (entityId !== this.props.entityId) {
            return;
        }

        const entityUpdated = {
            typeInsert: false,
            typeUpdate: false,
        };

        if (modificationType === 'insert') {
            entityUpdated.typeInsert = true;
            this.props.onEntityUpdated(entityUpdated);
        }

        if (modificationType === 'update') {
            const existingRecords = this.state.records;
            if (null === existingRecords) {
                entityUpdated.typeUpdate = true;
                this.props.onEntityUpdated(entityUpdated);
                return;
            }
            const updatedRecordIds = [];
            for (let existingRecord of existingRecords) {
                let found = false;
                for (let recordId of pointIds) {
                    if (String(recordId) === String(existingRecord.id)) {
                        updatedRecordIds.push(recordId);
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    entityUpdated.typeUpdate = true;
                }
            }

            // No filters, no sorting, records changed outside of current page
            const filters = entityViewManager.getEntityTableFilters(this.props.entityId);
            if (filters.length === 0 && this.sorting.length === 0 && entityUpdated.typeUpdate) {
                entityUpdated.typeUpdate = false;
                logDebug('No filters, no sorting, skip refresh button');
            }

            if (updatedRecordIds.length) {
                updatedRecordIds.forEach((recordId) => this.recordsLoadingQueue.add(recordId));
                this.updateRecordsThrottled();
            }

            this.props.onEntityUpdated(entityUpdated);
        }
    };

    updateRecordsThrottled = throttle(
        () => {
            const updatedRecordIds = Array.from(this.recordsLoadingQueue);
            this.recordsLoadingQueue = new Set();

            const request = {
                entityId: this.props.entityId,
                filters: [...this.filters, { columnName: 'id', operation: 'in', value: updatedRecordIds }],
                resultType: 'by_column_name',
            };

            if (this.props.withData) {
                request.withData = this.props.withData.query;
            }

            recordManager.getRecordsWithCount(request).then((data) => {
                const existingRecords = this.state.records;
                const newRecords = [];
                for (let existingRecord of existingRecords) {
                    let found = false;
                    for (let updatedRecord of data.items) {
                        if (updatedRecord.id === existingRecord.id) {
                            newRecords.push({
                                ...updatedRecord,
                                __isRecordUpdated: true,
                            });
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        newRecords.push({ ...existingRecord });
                    }
                }
                this.setState({
                    records: newRecords,
                });
            });
        },
        5000,
        { leading: true, trailing: false },
    );

    onTerritoriesUpdated = ({ entityId }) => {
        if (entityId !== this.props.entityId) {
            return;
        }

        const existingRecords = this.state.records;
        if (null === existingRecords) {
            return;
        }
        const ids = existingRecords.map((record) => record.id);

        const filters = [
            {
                columnName: 'id',
                operation: 'in',
                value: ids,
            },
        ];
        entityViewManager
            .loadData(entityId, null, filters, [], 0, this.state.pagination.size, this.props.addMainFields, VIEW_TABLE)
            .then((data) => {
                const recordIds = data.items.map((item) => item.id);
                const newRecords = [];
                for (let existingRecord of existingRecords) {
                    let found = false;
                    for (let recordId of recordIds) {
                        if (recordId === existingRecord.id) {
                            newRecords.push({ ...existingRecord });
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        newRecords.push({ ...existingRecord, __isRecordDeleted: true });
                    }
                }

                this.setState({
                    records: newRecords,
                });

                const entityUpdated = {
                    typeInsert: false,
                    typeUpdate: true,
                };

                this.props.onEntityUpdated(entityUpdated);
            });
    };

    onRecordsDeleted = ({ entityId, recordIds }) => {
        if (entityId !== this.props.entityId) {
            return;
        }

        const existingRecords = this.state.records;
        if (null === existingRecords) {
            return;
        }

        const newRecords = [];
        for (let existingRecord of existingRecords) {
            let found = false;
            for (let recordId of recordIds) {
                if (recordId === existingRecord.id) {
                    newRecords.push({ ...existingRecord, __isRecordDeleted: true });
                    found = true;
                    break;
                }
            }
            if (!found) {
                newRecords.push(existingRecord);
            }
        }

        this.setState(
            {
                records: newRecords,
            },
            () => this.props.onEntityDeleted(),
        );
    };

    onBasePointUpdated = () => {
        this.loadData(false);
    };

    onRecordsUpdateStarted = ({ entityId }) => {
        this.updatingEntityId = entityId;
    };

    onRecordsUpdated = ({ error }) => {
        if (!error && this.updatingEntityId === this.props.entityId) {
            this.loadData(false);
        }
        this.updatingEntityId = null;
    };

    reloadSettings = (entityIds) => {
        if (entityIds.includes(this.props.entityId)) {
            this.loadEntity(true);
        }
    };

    handleViewRecord = (record) => {
        viewRecordManager.viewRecord(this.props.entityId, record.id);
    };

    handleFindOnMap = (record) => {
        const lat = record[GEO_FIELDS.LAT];
        const lng = record[GEO_FIELDS.LNG];

        if (typeof lat !== 'number' || typeof lng !== 'number') {
            return;
        }

        const pinColumn = this.state.structure.pinColumn;
        const address = record[pinColumn] ?? null;

        this.props.onFindOnMap([parseFloat(lat), parseFloat(lng)], record, this.props.entityId, address, false);
    };

    handleMakeBase = (record) => {
        const lat = record[GEO_FIELDS.LAT];
        const lng = record[GEO_FIELDS.LNG];

        if (typeof lat !== 'number' || typeof lng !== 'number') {
            return;
        }

        const pinColumn = this.state.structure.pinColumn;
        const address = record[pinColumn] ?? null;

        basePointManager.setBasePoint({
            lat,
            lng,
            address,
            entityId: this.props.entityId,
            recordId: record['id'],
        });
    };

    handleRegeocode = (record) => {
        entityViewManager
            .requestRegeocode(this.props.entityId, record.id)
            .then(() => {
                this.props.enqueueSnackbar(
                    this.props.t('entity_data_table.table_context_menu.regeocode.snack.queued'),
                    { variant: 'success' },
                );
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    handleLoad = (record) => {
        if (routingSessionManager.isEditModeInPublishedRoute) {
            routingSessionManager.cancelEditModePublishedRoute().then(() => {
                enqueueSnackbarService.sendWarningMessage(this.props.t('map_page.snack.route.editing_cancelled'));
                this.loadRoute(record);
            });
        } else if (routingSessionManager.isRebuildRouteMode) {
            routingSessionManager.cancelRebuildMode().then(() => {
                enqueueSnackbarService.sendWarningMessage(this.props.t('map_page.snack.route.redesign_cancelled'));
                this.loadRoute(record);
            });
        } else if (routingSessionManager.isDraftEditMode) {
            routingSessionManager.cancelEditModeInDraftMode().then(() => {
                enqueueSnackbarService.sendWarningMessage(this.props.t('map_page.snack.route.editing_cancelled'));
                this.loadRoute(record);
            });
        } else {
            this.loadRoute(record);
        }
    };

    loadRoute = (record) => {
        const { isSystemRouteEntity, enqueueSnackbar, t } = this.props;
        if (isSystemRouteEntity) {
            const user = userManager.getCurrentUser();
            const account = userManager.getCurrentAccount();
            routeLoaderFactory
                .getManager(user.id, account.id)
                .getRouteById(record.id)
                .then((route) => routingSessionManager.loadSessionRoute(route))
                .catch((error) => {
                    console.error('Load session route', error);
                    enqueueSnackbar(t('entity_data_table.table_context_menu.load.snack.error'), { variant: 'error' });
                });
        }
    };

    handleEdit = (record) => {
        const { isSystemRouteEntity, t } = this.props;
        if (!isSystemRouteEntity) {
            return;
        }
        const user = userManager.getCurrentUser();
        const account = userManager.getCurrentAccount();
        const routeLoader = routeLoaderFactory.getManager(user.id, account.id);
        routeLoader
            .getRouteById(record.id)
            .then((route) => {
                routeEditDialogManager.openModal(
                    ({ name, userId, dateStartAt }) => {
                        const updatedRoute = routeLoader.saveExistsRoute(route.id, { name, userId, dateStartAt });

                        enqueueSnackbarService.sendSuccessMessage(t('map_page.snack.route_saved'));

                        return updatedRoute;
                    },
                    {
                        name: route.name,
                        user: route.user,
                        dateStartAt: route.dateStartAt,
                    },
                );
            })
            .catch(() =>
                enqueueSnackbarService.sendErrorMessage(
                    t('entity_data_table.table_context_menu.edit_route.snack.error'),
                ),
            );
    };

    buildStructure(fields) {
        const structure = super.buildStructure((this.props.withData?.columns ?? []).concat(fields));

        for (let column of structure.columns) {
            if (column.name === FIELD_DISTANCE) {
                column.getCellValue = (row) => {
                    const { com_calculated_distance } = row;
                    if (undefined === com_calculated_distance || null === com_calculated_distance) {
                        return null;
                    }
                    return formatWithCommas(parseFloat(com_calculated_distance).toFixed(2));
                };
            }
        }
        structure.exts.push({
            columnName: FIELD_DISTANCE,
            width: 40,
        });

        const columnNames = structure.columns.map(({ columnName }) => columnName);
        this.initialFilters = this.initialFilters.filter(({ columnName }) => columnNames.includes(columnName));
        this.filters = this.filters.filter(({ columnName }) => columnNames.includes(columnName));
        this.initialSorting = this.initialSorting.filter(({ columnName }) => columnNames.includes(columnName));
        this.sorting = this.sorting.filter(({ columnName }) => columnNames.includes(columnName));

        return structure;
    }

    applyFilters(filters) {
        this.props.onApplyFilters && this.props.onApplyFilters(filters);
        super.applyFilters(filters);
    }

    loadEntity(forceUpdate = false) {
        if (!this.ismounted) {
            return;
        }

        this.includeUnmappedRecords = userManager.userHasAccessTo(
            TABLE_MODULE.NAME,
            TABLE_MODULE.FEATURES.INCLUDE_UNMAPPED_RECORDS.NAME,
        );
        if (this.props.entityId === null) {
            this.setState({
                structure: null,
                records: null,
                pagination: {
                    current: 0,
                    size: this.defaultPageSize,
                },
                totalCount: 0,
            });
            dispatcher.dispatch(events.ENTITY_DATA_TABLE_ENTITY_CHANGED, { entityId: null });
            return;
        }

        super.loadEntity(forceUpdate);
        dispatcher.dispatch(events.ENTITY_DATA_TABLE_ENTITY_CHANGED, { entityId: this.props.entityId });
    }

    /**
     * Clear filters
     */
    clearFilters = () => {
        this.filters = [];
        if (this.filteringState.current !== null) {
            this.filteringState.current.setState({ filters: [] });
        }
        this.props.onApplyFilters && this.props.onApplyFilters([]);
        this.loadData();
    };

    /**
     * @throws EmptyResultError
     */
    getSections() {
        if (this.props.sections === null) {
            throw new EmptyResultError();
        }
        return this.props.sections || null;
    }

    /**
     * @throws EmptyResultError
     */
    getFilters() {
        const filters = super.getFilters(this.filters);
        if (this.props.bounds !== undefined) {
            if (this.props.bounds === null) {
                throw new EmptyResultError();
            }
            filters.push({
                columnName: GEO_FIELDS.LAT,
                operation: 'greaterThanOrEqual',
                value: this.props.bounds.minLat,
            });
            filters.push({
                columnName: GEO_FIELDS.LAT,
                operation: 'lessThanOrEqual',
                value: this.props.bounds.maxLat,
            });
            filters.push({
                columnName: GEO_FIELDS.LNG,
                operation: 'greaterThanOrEqual',
                value: this.props.bounds.minLng,
            });
            filters.push({
                columnName: GEO_FIELDS.LNG,
                operation: 'lessThanOrEqual',
                value: this.props.bounds.maxLng,
            });
        }

        if (this.includeUnmappedRecords) {
            return filters;
        }

        const newFilter = {
            operation: 'in',
            columnName: GEO_FIELDS.STATUS,
            value: [serverGeocodeStatuses.doubt, serverGeocodeStatuses.success, serverGeocodeStatuses.imported],
            hide: true,
        };

        let filterIndex = filters.findIndex((e) => e.columnName === GEO_FIELDS.STATUS);
        if (filterIndex) {
            delete filters[filterIndex];
        }

        filters.push(newFilter);
        return filters;
    }

    loadCsv() {
        let ids;
        let filters;
        let sections;

        try {
            filters = this.getFilters();
            sections = this.getSections();
            ids = this.props.ids;
            if (ids?.length === 0) {
                throw new EmptyResultError();
            }
        } catch (e) {
            if (e instanceof EmptyResultError) {
                this.props.onLoadingError &&
                    this.props.onLoadingError(this.props.t('entity_data_table.front_data_table.errors.no_records'));
                return;
            }
            throw e;
        }

        const params = {
            sections: sections,
            filters: filters.concat(withIdsFilter(ids)),
            sorting: this.sorting,
        };
        if (basePointManager.getBasePoint()) {
            params.basePoint = basePointManager.getBasePoint();
        }
        if (this.props.withData) {
            params.withData = this.props.withData.query;
        }

        return entityViewManager.exportDataToCsv(this.props.entityId, params);
    }

    cellSelectedComponent = (props) => {
        const { row, selected, onToggle } = props;
        return (
            <Table.Cell {...props} style={{ padding: '0 4px' }}>
                <div>
                    <TableContextMenu
                        record={{ ...row, entityId: this.props.entityId }}
                        pinColumn={this.state.structure.pinColumn}
                        onExportProspect={this.props.onExportProspect}
                        onAddToRoute={(record) => this.addToRoute([record])}
                        onViewRecord={this.handleViewRecord}
                        onFindOnMap={this.handleFindOnMap}
                        onMakeBase={this.handleMakeBase}
                        onRegeocode={this.handleRegeocode}
                        onLoad={this.handleLoad}
                        onDelete={this.props.onDelete}
                        onEditRoute={this.handleEdit}
                        onOpenChangeHistory={this.props.onOpenChangeHistory}
                        isRegeocodable={this.isRegeocodable}
                        loadable={(record) => this.getLoadableParams(record)}
                        isDeletable={this.props.isSystemRouteEntity}
                        isRoute={this.props.isSystemRouteEntity}
                        isRoutingSession={this.props.isSystemRoutingSessionEntity}
                        isFile={this.props.isSystemFileEntity}
                        disabled={!!row.__isRecordDeleted}
                        availableAddress={!this.props.isSystemRouteEntity}
                    />
                    <Checkbox
                        checked={selected}
                        onChange={onToggle}
                        color="primary"
                        disabled={!!row.__isRecordDeleted}
                    />
                </div>
            </Table.Cell>
        );
    };

    headerCellSelectedComponent = (props) => {
        const { allSelected, onToggle, someSelected, ...rest } = props;
        return (
            <Table.Cell {...rest} style={{ padding: '0 4px' }}>
                <Checkbox
                    indeterminate={someSelected}
                    checked={allSelected}
                    onChange={() => onToggle(!allSelected)}
                    color="primary"
                />
            </Table.Cell>
        );
    };

    requestData(ignorePage = false, parentTimer = null) {
        const timer =
            parentTimer instanceof Timer ? parentTimer.startChild(TIMER_OPERATIONS.FrontDataTable.requestData) : null;
        if (timer) {
            parentTimer.removeEmptyTerminators();
        }
        const endTimer = timer ? () => timer.end() : () => {};
        let ids;
        let filters;
        let sections;

        try {
            ids = this.props.ids;
            filters = this.getFilters();
            sections = this.getSections();
            this.props.onSelectionChange(new Map());
            if (timer) {
                timer.enrichData({ filters });
                timer.enrichData({ ids });
            }
        } catch (e) {
            endTimer();
            if (e instanceof EmptyResultError) {
                return Promise.resolve({
                    items: [],
                    total: 0,
                });
            }
            throw e;
        }

        return entityViewManager
            .loadData(
                this.props.entityId,
                sections,
                filters,
                this.sorting,
                ignorePage ? 0 : this.state.pagination.current * this.state.pagination.size,
                this.state.pagination.size,
                this.props.addMainFields,
                VIEW_TABLE,
                timer,
                this.props.withData?.query,
                ids,
            )
            .finally(endTimer);
    }

    addToRoute = async (records = undefined) => {
        if (records === undefined) {
            records = this.props.selected.values();
        }

        const entityPoints = [];
        const prospectingPoints = [];

        const pinColumn = this.state.structure.pinColumn;
        const entityId = this.props.entityId === PROSPECTING_ID ? undefined : this.props.entityId;

        for (let record of records) {
            const lat = parseFloat(record[GEO_FIELDS.LAT]);
            const lng = parseFloat(record[GEO_FIELDS.LNG]);
            const geoStatus = parseInt(record[GEO_FIELDS.STATUS], 10);

            if (typeof lat !== 'number' || typeof lng !== 'number' || Number.isNaN(lat) || Number.isNaN(lng)) {
                continue;
            }

            const item = {
                lat: lat,
                lng: lng,
                address: record[pinColumn] ?? null,
                recordId: this.props.entityId === PROSPECTING_ID ? record['uuid'] : record['id'],
                entityId,
                objectName: record.objectName || null,
                countryShort: record.addressData ? record.addressData.countryShort : null,
                addressFields: record.addressFields,
                geoStatus,
            };

            if (entityId) {
                entityPoints.push(item);
            } else {
                prospectingPoints.push(item);
            }
        }

        const points = duplicatePointsDialogManager.getDuplicateAndUniquePoints(entityPoints, prospectingPoints, []);
        if (points.hasDuplicates) {
            duplicatePointsDialogManager.open(points);
            return;
        }

        this.props.onAddToRoute(entityPoints, prospectingPoints);
    };

    addToWaitingList = async (records = undefined) => {
        if (this.props.entityId === PROSPECTING_ID) {
            return;
        }

        if (records === undefined) {
            records = this.props.selected.values();
        }

        const pinColumn = this.state.structure.pinColumn;
        const entityId = this.props.entityId;
        const entity = await metadataManager.requestEntityForUser(entityId);
        if (!entityId) {
            return;
        }

        let pinField = null;
        if (entity.pinField) {
            for (const field of entity.fields) {
                if (field.apiName === entity.pinField) {
                    pinField = field.isIncluded ? field : null;
                    break;
                }
            }
        }

        const itemsToSave = [];
        for (let record of records) {
            const lat = record[GEO_FIELDS.LAT];
            const lng = record[GEO_FIELDS.LNG];
            const geoStatus = record[GEO_FIELDS.STATUS];

            const address = getAddressFromRecord(record, pinField);

            itemsToSave.push({
                id: uuidv4(),
                title: record.objectName ?? '',
                virtual: !record[pinColumn],
                location: address,
                displayLocation: formatAddressForWaitingList(address, record),
                lat: String(lat),
                lng: String(lng),
                relatedTo: {
                    entityId: entityId,
                    entityLabel: entity.label,
                    entityColor: entity.color,
                    id: record['id'],
                    displayText: record.objectName ?? '',
                },
                geocoderStatus: geoStatus,
            });
        }
        waitingListManager.add(...itemsToSave);
    };

    cellStyledTooltipCell = (props) => {
        const { isSystemRouteEntity } = this.props;
        const { row } = props;
        const onClick = () => this.handleViewRecord(row);
        return <StyledTooltipCell {...props} onClick={isSystemRouteEntity ? null : onClick} />;
    };

    getLoadableParams(record) {
        const status = {
            isLoadable: false,
            disabled: false,
            tooltip: '',
        };

        const { isSystemRouteEntity, t } = this.props;
        if (!isSystemRouteEntity) {
            return status;
        }

        status.isLoadable = true;
        const routeStatus = record.status;
        if (routeStatus === RouteStatus.DRAFT) {
            status.disabled = true;
            status.tooltip = t('entity_data_table.table_context_menu.load.tooltip.disabled_route_draft');
        }

        return status;
    }
}

FrontDataTable.defaultProps = {
    addMainFields: true,
};

FrontDataTable.propTypes = {
    entityId: PropTypes.number,
    dsId: PropTypes.number,
    onExportProspect: PropTypes.func,
    onRecordsLoaded: PropTypes.func.isRequired,
    onEntityUpdated: PropTypes.func.isRequired,
    onEntityDeleted: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    onOpenChangeHistory: PropTypes.func,
    onDelete: PropTypes.func,
    entityUpdated: PropTypes.object,
    isSystemRouteEntity: PropTypes.bool,
    isSystemRoutingSessionEntity: PropTypes.bool,
    isSystemFileEntity: PropTypes.bool,
    routeManager: PropTypes.object,
    mapMode: PropTypes.string,
    isRoute: PropTypes.bool,
    ids: PropTypes.array,
    withData: PropTypes.object,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(FrontDataTable));
