import { makeObservable, override } from 'mobx';
import i18n from 'i18next';
import RoutingSettingsMappingFormManager from './RoutingSettingsMappingFormManager';
import { Routing } from 'interfaces';
import apiRoutes, { reverse } from '../../../api/apiRoutes';
import { RouteSettingsValidateResponse } from '../../../interfaces/api/route';

const t = i18n.t.bind(i18n);

export const defaultMappingObject: Routing.Settings.MappingForm = new Map([
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DURATION,
        {
            fieldId: null,
            defaultValue: '30',
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DURATION,
        {
            fieldId: null,
            defaultValue: '30',
        },
    ],
]);

class RoutingSettingsMappingObjectFormManager extends RoutingSettingsMappingFormManager {
    constructor() {
        super();
        makeObservable(this, {
            validate: override,
        });
    }

    getDefaultMapping(): Routing.Settings.MappingForm {
        return defaultMappingObject;
    }

    async validate(): Promise<boolean> {
        this._mappingErrors = new Map();
        this._currentObjectError = null;

        if (this._currentObject === null) {
            this._currentObjectError = t('errors.field.not_empty');
        }

        const url = reverse(apiRoutes.routes.settings.validate);
        const resultPromise = this.requestApi(url, 'POST', this.mapping);

        const result: RouteSettingsValidateResponse = await resultPromise;
        if (result) {
            Object.keys(result).forEach((key) => {
                this._mappingErrors.set(key, {
                    field: null,
                    defaultValue: result[key],
                });
            });
        }

        return this._currentObjectError === null && this._mappingErrors.size === 0;
    }
}

export default RoutingSettingsMappingObjectFormManager;
