import { Common } from 'interfaces';

class Converter {
    static convertByUnit(distanceKm, unit) {
        if (unit === Common.DistanceMetric.KM) {
            return distanceKm;
        }
        return Converter.kmToMi(distanceKm);
    }

    static toKm(length, unit) {
        if (unit === 'mile') {
            return this.miToKm(length);
        }
        return length;
    }

    static fromKm(length, unit) {
        if (unit === 'mile') {
            return this.miToKm(length);
        }
        return length;
    }

    static miToKm(miles) {
        return miles * 1.609344;
    }
    static kmToMi(km) {
        return km / 1.609344;
    }
    static mToFt(meters) {
        return meters / 0.3048;
    }
    static mToMi(meters) {
        return meters / 1609.344;
    }
    static ftToM(feet) {
        return feet * 0.3048;
    }
    static ftToKm(feet) {
        return (feet * 0.3048) / 1000;
    }

    static lbsToKg(lbs) {
        return lbs * 0.45359237;
    }
    static lbsToTon(lbs) {
        return (lbs * 0.45359237) / 1000;
    }
    static kgToLbs(kg) {
        return kg / 0.45359237;
    }
    static tonToLbs(ton) {
        return (ton / 0.45359237) * 1000;
    }
}

export default Converter;
