import subscriptionManagerFactory, { TYPE_CHANGE } from '../../service/SubscriptionManager';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingButton from '../LoadingButton';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from './MoneyFormat';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { withTranslation } from 'react-i18next';
import { SubscriptionStatus } from '../../service/types';

class ChangeSeatsForm extends React.Component {
    constructor(props) {
        super(props);
        const numberSeats = this.props.subscription.usersPurchased;
        this.state = {
            loading: false,
            numberSeats,
            errorInput: null,
        };
        this.subManager = subscriptionManagerFactory.getManager(this.props.account.id);
    }

    handleChangeNumberSeats = (event) => {
        let numberSeats = event.target.value;
        let errorInput = null;
        if (!numberSeats.match(/^\d+$/)) {
            errorInput = this.props.t('billing.change_seats_form.should_integer');
            numberSeats = this.state.numberSeats;
        }
        numberSeats = parseInt(numberSeats);

        this.setState({
            numberSeats,
            errorInput,
        });
    };

    handleConfirmChange = () => {
        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.account.id === null || subscription.accountId !== this.props.account.id) {
                return;
            }
            if (data.type === TYPE_CHANGE.SEATS) {
                this.setState(
                    {
                        loading: false,
                    },
                    () => {
                        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                        this.props.onCloseDialog && this.props.onCloseDialog();
                        this.props.enqueueSnackbar &&
                            this.props.enqueueSnackbar(this.props.t('billing.change_seats_form.seats_change'), {
                                variant: 'success',
                            });
                    },
                );
            }
        });

        this.subManager.updateSeats(this.state.numberSeats).catch((error) => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                    this.props.onCloseDialog && this.props.onCloseDialog();
                    this.props.enqueueSnackbar &&
                        this.props.enqueueSnackbar(error.message, { variant: 'error', persist: true });
                },
            );
        });

        this.setState({
            loading: true,
        });
    };

    render() {
        const numberSeats = this.state.numberSeats;
        const { account, subscription, t } = this.props;
        const plan = subscription.plan;

        let textChange = '';
        let textConfirm = '';
        if (subscription.usersPurchased > numberSeats) {
            let count = subscription.usersPurchased - numberSeats;
            textChange = t('billing.change_seats_form.removing_seats', { count: count });
            textConfirm = t('billing.change_seats_form.removing_seats_confirm');
        }

        if (numberSeats > subscription.usersPurchased) {
            let count = numberSeats - subscription.usersPurchased;
            textChange = t('billing.change_seats_form.adding_seats', { count: count });
            textConfirm = t('billing.change_seats_form.adding_seats_confirm');
        }

        const statusActive =
            subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.PAST_DUE;
        const isCountUserValid = numberSeats >= account.countOfPaidUsers;
        const isNotChangedSeats = subscription.usersPurchased === numberSeats || isCountUserValid === false;

        const newPrice = plan.price * numberSeats + subscription.recordPmCur + subscription.adminPmCur;

        return (
            <Dialog className="change-seats-form" open={true}>
                <DialogTitle>
                    {t('billing.change_seats_form.h1')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.change_seats_form.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="dialog-content">
                        <Grid container alignItems="center" className="row">
                            <Grid item xs={6}>
                                {t('billing.change_seats_form.current_plan')}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>{plan.name}</strong>
                            </Grid>
                        </Grid>
                        <Grid container className="row">
                            <Grid item xs={6}>
                                {t('billing.change_seats_form.current_number_seats')}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>{subscription.usersPurchased}</strong>
                                <Typography variant="subtitle2">
                                    {t('billing.change_seats_form.price_users', {
                                        price: formatMoney(subscription.currency, plan.price),
                                        period: t('billing.plan_form.period_per_' + plan.period),
                                    })}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {t('billing.change_seats_form.price_total', {
                                        price: formatMoney(subscription.currency, subscription.subPriceTotalCur),
                                        period: t('billing.plan_form.period_per_' + plan.period),
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="new-block">
                            <Grid item xs={6}>
                                {t('billing.change_seats_form.new_number_seats')}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    data-testid="change_seats_form.seats_number"
                                    style={{ width: 50 }}
                                    type="number"
                                    error={this.state.errorInput != null}
                                    helperText={this.state.errorInput}
                                    value={this.state.numberSeats}
                                    onChange={(e) => this.handleChangeNumberSeats(e)}
                                    autoFocus
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                        },
                                    }}
                                />
                                {isNotChangedSeats !== true && (
                                    <React.Fragment>
                                        <Typography variant="subtitle2">
                                            {t('billing.change_seats_form.price_total', {
                                                price: formatMoney(subscription.currency, newPrice),
                                                period: t('billing.plan_form.period_per_' + plan.period),
                                            })}
                                        </Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Icon className="icon-info">info</Icon>
                                            <Typography variant="subtitle2">{textChange}</Typography>
                                        </div>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                        {statusActive && isCountUserValid && isNotChangedSeats !== true && (
                            <Grid container className="row">
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex' }}>
                                        <Icon className="icon-info">info</Icon>
                                        <Typography variant="subtitle2">{textConfirm}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        {isCountUserValid === false && (
                            <Grid container className="row">
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex' }}>
                                        <Icon className="icon-warning">warning</Icon>
                                        <Typography variant="subtitle2">
                                            {t('billing.change_seats_form.text', {
                                                usersCount: account.countOfPaidUsers,
                                                removeSeats: account.countOfPaidUsers - numberSeats,
                                            })}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.onCloseDialog} data-testid="change.seats.button.cancel">
                        {t('button.cancel')}
                    </Button>
                    <LoadingButton
                        color="primary"
                        onClick={this.handleConfirmChange}
                        disabled={isNotChangedSeats}
                        loading={this.state.loading}
                        data-testid="change.seats.button.confirm"
                    >
                        {t('button.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

ChangeSeatsForm.propTypes = {
    account: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    onCloseDialog: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
};

export default withTranslation()(ChangeSeatsForm);
