import React, { Ref } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { colors, FormHelperText, Grid, RadioGroup, TextField } from '@material-ui/core';
import './SSUInterestedIn.css';
import InterestedFeatureBlock from './InterestedFeatureBlock';
import FormControl from '@material-ui/core/FormControl';
import SSUManager from './SSUManager';
import { InterestingFeaturesService } from './InterestingFeaturesService';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Hint from '../Hint';
import Radio from '@material-ui/core/Radio';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { FeatureProps } from '../../interfaces/signUpFeatures';
import CircularProgress from '@material-ui/core/CircularProgress';

interface SSUInterestedInProps extends WithTranslation {
    ref: Ref<any>;
    manager: SSUManager;
    featuresService: InterestingFeaturesService;
    onSuccess: () => void;
    onProcessing: (task: string) => void;
    onError: (message: string, details: any) => void;
    onReady: (isReady: boolean) => void;
}

interface SSUInterestedInState {
    problemToSolve: string | null;
    usedSimilarSoft: boolean | null;
    similarSoftNames: string;
    usersCount: number;
    similarSoftNamesInputError: string;
}

class SSUInterestedIn extends React.PureComponent<SSUInterestedInProps, SSUInterestedInState> {
    private selectedFeatures: Array<FeatureProps> = [];

    constructor(props: SSUInterestedInProps) {
        super(props);
        const savedProblemsToSolve = props.manager.getProblemsToSolveState();
        const similarSoft = props.manager.getSimilarSoftToUseState();

        const usedSimilarSoft = similarSoft ? similarSoft.used : null;
        const similarSoftNames = similarSoft ? similarSoft.names : '';

        if (usedSimilarSoft === true && similarSoftNames === '') {
            this.props.onReady(false);
        }

        this.state = {
            usedSimilarSoft: usedSimilarSoft,
            similarSoftNames: similarSoftNames,
            similarSoftNamesInputError: '',
            problemToSolve: savedProblemsToSolve,
            usersCount: props.manager.getExpectedUsersCount() ?? 1,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.DEFAULT_FEATURES_LOADED, this, (selectedFeatures: Array<FeatureProps>) => {
            this.selectedFeatures = selectedFeatures;

            if (this.state.usedSimilarSoft === true && this.state.similarSoftNames === '') {
                this.props.onReady(false);
            } else {
                this.props.onReady(true);
            }

            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.DEFAULT_FEATURES_LOADED);
    }

    async commit() {
        const { usersCount, problemToSolve, similarSoftNames } = this.state;
        const { manager, onSuccess, onProcessing, onError, t, featuresService } = this.props;
        onProcessing && onProcessing(t('signup.initial.form_saving'));

        manager
            .saveInterestedIn({
                features: featuresService.getSelectedFeatures(),
                expectedUsersCount: usersCount,
                problemToSolve: problemToSolve ?? '',
                previouslyUsedSoftware: similarSoftNames ?? '',
            })
            .then(() => {
                manager.clearStateForInterestedInData();
                featuresService.clearStateForFeatures();
                onSuccess();
            })
            .catch((error: any) => {
                onError(t('signup.features.saving_error'), []);
                if (error instanceof Error) {
                    throw error;
                }
            });
    }

    changeNumberOfUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputUsersCount = event.target.value;
        let usersCount = parseInt(inputUsersCount);
        if (!inputUsersCount.match(/^\d+$/)) {
            return;
        }
        if (1 > usersCount) {
            usersCount = 1;
        }

        this.setState({ usersCount });
        this.props.manager.saveExpectedUsersCount(usersCount);
    };

    changeProblemToSolve = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ problemToSolve: event.target.value });
        this.props.manager.saveProblemsToSolveState(event.target.value);
    };

    changeSimilarSoftData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { t } = this.props;
        let usedSimilarSoft = this.state.usedSimilarSoft;
        let softNameInputError = '';

        if (event.target.name === 'usedSimilarSoft') {
            usedSimilarSoft = event.target.value === 'true';
            this.setState({ usedSimilarSoft, similarSoftNamesInputError: softNameInputError });

            this.saveSimilarSoftToUse(usedSimilarSoft, usedSimilarSoft ? this.state.similarSoftNames : '');
        } else if (event.target.name === 'similarSoftNames') {
            if (!usedSimilarSoft) {
                return;
            }

            const similarSoftNames = event.target.value;
            if (!similarSoftNames) {
                softNameInputError = t('validation.value.required');
            }
            this.setState({ similarSoftNames, similarSoftNamesInputError: softNameInputError });

            this.saveSimilarSoftToUse(usedSimilarSoft, similarSoftNames);
        }
    };

    saveSimilarSoftToUse(used: boolean, names: string): void {
        const { onReady, manager } = this.props;

        onReady(true);
        if (used && names === '') {
            onReady(false);
        }
        manager.saveSimilarSoftToUse(used, names);
    }

    render() {
        const { t, manager, featuresService } = this.props;
        if (!this.selectedFeatures.length) {
            return <CircularProgress size={14} />;
        }

        const { usedSimilarSoft, similarSoftNames, usersCount, similarSoftNamesInputError } = this.state;

        return (
            <Grid container style={{ marginTop: 13 }}>
                <InterestedFeatureBlock
                    manager={manager}
                    featuresService={featuresService}
                    selectedFeatures={this.selectedFeatures}
                />
                <Grid container alignItems="center" justifyContent={'flex-start'} style={{ marginBottom: 15 }}>
                    <Grid item container xs={12} style={{ height: 65 }} alignContent="center">
                        <h3> {t('signup.features.problems_to_solve')}</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                key={0}
                                name="problemToSolve"
                                fullWidth
                                value={this.state.problemToSolve ?? ''}
                                multiline
                                minRows={3}
                                type="text"
                                variant={'outlined'}
                                onChange={this.changeProblemToSolve}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" className="interested-in-block">
                    <Grid item sm={7} xs={12}>
                        <h3 style={{ display: 'inline', paddingRight: 10 }}>
                            {' '}
                            {t('signup.features.users_count')}&nbsp;
                            <Hint
                                TooltipProps={{ className: 'tooltip-question' }}
                                color={colors.grey[600]}
                                iProps={{ style: { verticalAlign: 'baseline' } }}
                            >
                                {t('signup.features.users_count.hint')}
                            </Hint>
                        </h3>
                    </Grid>
                    <Grid item sm={1} xs={2}>
                        <FormControl>
                            <TextField
                                name={'usersCount'}
                                fullWidth
                                value={usersCount}
                                type={'number'}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.changeNumberOfUsers}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <h3> {t('signup.features.using_of_similar_soft')}</h3>
                    </Grid>
                    <RadioGroup
                        name="usedSimilarSoft"
                        value={usedSimilarSoft === null ? '' : usedSimilarSoft.toString()}
                        onChange={this.changeSimilarSoftData}
                        row
                    >
                        <FormControlLabel value={'true'} control={<Radio color="primary" />} label={t('yes')} />
                        <FormControlLabel value={'false'} control={<Radio color="primary" />} label={t('no')} />
                    </RadioGroup>
                    {usedSimilarSoft && (
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <FormHelperText> {t('signup.features.using_of_similar_soft.hint')}</FormHelperText>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        name={'similarSoftNames'}
                                        fullWidth
                                        value={similarSoftNames ?? ''}
                                        InputProps={{ disableUnderline: false }}
                                        helperText={similarSoftNamesInputError}
                                        error={similarSoftNamesInputError.length !== 0}
                                        onChange={this.changeSimilarSoftData}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SSUInterestedIn);
