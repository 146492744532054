import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, DialogActions, FormHelperText, Grid } from '@material-ui/core';
import WorkingDays, { prepareWorkingDays, WeekDayList } from '../UserForm/TravelingPreferences/WorkingDays';
import { WorkingHoursException, WorkingHoursPerWeek } from '../types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { countWeekDaysInDateRange, DATETIME_PICKER_FORMAT } from '../../utils';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import BreakTypeInput from '../UserForm/TravelingPreferences/BreakTypeInput';
import BreakDurationInput from '../UserForm/TravelingPreferences/BreakDurationInput';
import { BreakTypeEnum } from '../../service/types';
import BreakEarliestTimeInput from '../UserForm/TravelingPreferences/BreakEarliestTimeInput';
import BreakLatestTimeInput from '../UserForm/TravelingPreferences/BreakLatestTimeInput';
import LoadingButton from '../LoadingButton';
import { validateForm, validateOne } from './ExceptionsValidator';
import Tooltip from '@material-ui/core/Tooltip';
import AllowedOvertimeInput from '../UserForm/TravelingPreferences/AllowedOvertimeInput';

interface State {
    workingHoursException: WorkingHoursException;
    weekDayList?: WeekDayList;
    errors: Map<string, string>;
    loading: boolean;
}

interface Props extends WithTranslation {
    onClose: () => void;
    workingHoursException: WorkingHoursException;
    workingHoursExceptions: WorkingHoursException[];
    onSave: (workingHoursException: WorkingHoursException) => void;
}

class ExceptionDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            workingHoursException: cloneDeep(props.workingHoursException),
            weekDayList: undefined,
            errors: new Map<string, string>(),
            loading: false,
        };
    }

    componentDidMount(): void {
        this.setState((state: State) => {
            return {
                ...state,
                weekDayList: this.getWorkingHours(state.workingHoursException.workingHours),
            };
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.workingHoursException !== this.props.workingHoursException) {
            this.setState((state: State, props: Props) => {
                return {
                    ...state,
                    workingHoursException: cloneDeep(props.workingHoursException),
                    weekDayList: this.getWorkingHours(props.workingHoursException.workingHours),
                };
            });
        }
    }

    private getWorkingHours = (workingHours: WorkingHoursPerWeek): WeekDayList => {
        return prepareWorkingDays(workingHours, this.props.t);
    };

    private handlePeriodFromChange = (date: MaterialUiPickersDate): void => {
        this.handlePeriodChange(date, 'periodFrom');
    };

    private handlePeriodToChange = (date: MaterialUiPickersDate): void => {
        this.handlePeriodChange(date, 'periodTo');
    };

    private handlePeriodChange = (date: MaterialUiPickersDate, period: 'periodFrom' | 'periodTo'): void => {
        if (!date) {
            return;
        }

        this.setState(
            (state: State) => {
                const workingHoursException = { ...state.workingHoursException };
                const dateToSave = new Date(date);
                dateToSave.setHours(
                    period === 'periodFrom' ? 0 : 23,
                    period === 'periodFrom' ? 0 : 59,
                    period === 'periodFrom' ? 0 : 59,
                    period === 'periodFrom' ? 0 : 999,
                );
                workingHoursException[period] = dateToSave.toISOString();

                let weekDayList = state.weekDayList;
                if (!weekDayList) {
                    return { ...state, workingHoursException };
                }

                let weekDayCount = new Map<string, { count: number; date: Date }>();
                if (workingHoursException.periodFrom && workingHoursException.periodTo) {
                    weekDayCount = countWeekDaysInDateRange(
                        new Date(workingHoursException.periodFrom),
                        new Date(workingHoursException.periodTo),
                    );
                }

                weekDayList = { ...weekDayList };
                for (let weekDayName in weekDayList) {
                    if (!weekDayCount.get(weekDayName)?.count) {
                        weekDayList[weekDayName].enable = false;
                    }
                }

                return { ...state, workingHoursException, weekDayList };
            },
            () => {
                let errors = new Map<string, string>(this.state.errors);
                errors.delete('periodFrom');
                errors.delete('periodTo');
                errors.delete('period');
                errors = validateOne(
                    period,
                    errors,
                    this.prepareWorkingHoursException(),
                    this.props.workingHoursExceptions,
                );
                errors = validateOne(
                    'period',
                    errors,
                    this.prepareWorkingHoursException(),
                    this.props.workingHoursExceptions,
                );
                this.setState({ errors });
            },
        );
    };

    private handleScheduleDayStartTimeChanged = (date: MaterialUiPickersDate, day: string) => {
        this.handleScheduleDayTimeChanged(date, day, 'start');
    };

    private handleScheduleDayEndTimeChanged = (date: MaterialUiPickersDate, day: string) => {
        this.handleScheduleDayTimeChanged(date, day, 'end');
    };

    private handleScheduleDayTimeChanged = (date: MaterialUiPickersDate, day: string, time: 'start' | 'end') => {
        this.setState(
            (state: State) => {
                if (!state.weekDayList) {
                    return state;
                }

                const weekDayList = { ...state.weekDayList };
                weekDayList[day][time].setHours(date!.getHours(), date!.getMinutes(), 0, 0);

                return { ...state, weekDayList };
            },
            () => {
                let errors = new Map<string, string>(this.state.errors);
                errors = validateOne(
                    'workingHours',
                    errors,
                    this.prepareWorkingHoursException(),
                    this.props.workingHoursExceptions,
                );
                this.setState({ errors });
            },
        );
    };

    private handleIncludeDayChanged = (day: string) => {
        this.setState(
            (state: State) => {
                if (!state.weekDayList) {
                    return state;
                }

                const weekDayList = { ...state.weekDayList };
                weekDayList[day].enable = !weekDayList[day].enable;

                return { ...state, weekDayList };
            },
            () => {
                let errors = new Map<string, string>(this.state.errors);
                errors = validateOne(
                    'workingHours',
                    errors,
                    this.prepareWorkingHoursException(),
                    this.props.workingHoursExceptions,
                );
                this.setState({ errors });
            },
        );
    };

    private handleAllowedOverTimeChange = (allowedOvertime: number | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((state: State) => {
                const workingHoursException = { ...state.workingHoursException };
                workingHoursException.allowedOvertime = allowedOvertime;

                return { ...state, workingHoursException };
            }, resolve);
        });
    };

    private handleBreakTypeChange = (breakType: BreakTypeEnum | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((state: State) => {
                const workingHoursException = { ...state.workingHoursException };
                workingHoursException.breakType = breakType;
                if (!breakType) {
                    workingHoursException.breakDuration = null;
                    workingHoursException.breakEarliestTime = null;
                    workingHoursException.breakLatestTime = null;
                }

                return { ...state, workingHoursException };
            }, resolve);
        });
    };

    private handleBreakDurationChange = (breakDuration: number | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((state: State) => {
                const workingHoursException = { ...state.workingHoursException };
                if (!workingHoursException.breakType) {
                    return state;
                }

                workingHoursException.breakDuration = breakDuration;

                return { ...state, workingHoursException };
            }, resolve);
        });
    };

    private handleBreakEarliestTimeChange = (breakEarliestTime: string | null): Promise<void> => {
        return this.handleBreakTimeChange(breakEarliestTime, 'breakEarliestTime');
    };

    private handleBreakLatestTimeChange = (breakEarliestTime: string | null): Promise<void> => {
        return this.handleBreakTimeChange(breakEarliestTime, 'breakLatestTime');
    };

    private handleBreakTimeChange = (
        breakTime: string | null,
        field: 'breakEarliestTime' | 'breakLatestTime',
    ): Promise<void> => {
        return new Promise((resolve) => {
            this.setState(
                (state: State) => {
                    const workingHoursException = { ...state.workingHoursException };
                    if (!workingHoursException.breakType) {
                        return state;
                    }

                    workingHoursException[field] = breakTime;

                    return { ...state, workingHoursException };
                },
                () => {
                    let errors = new Map<string, string>(this.state.errors);
                    errors.delete('breakEarliestTime');
                    errors.delete('breakLatestTime');
                    errors = validateOne(
                        field,
                        errors,
                        this.prepareWorkingHoursException(),
                        this.props.workingHoursExceptions,
                    );
                    this.setState({ errors }, resolve);
                },
            );
        });
    };

    private prepareWorkingHoursException = (): WorkingHoursException => {
        const { workingHoursException, weekDayList } = this.state;
        if (!weekDayList) {
            return workingHoursException;
        }

        let workingHours: WorkingHoursPerWeek = {};
        for (let [day, value] of Object.entries(weekDayList)) {
            if (!value.enable) {
                workingHours[day] = null;
            } else {
                workingHours[day] = {
                    start: {
                        hours: value.start.getHours(),
                        minutes: value.start.getMinutes(),
                    },
                    end: {
                        hours: value.end.getHours(),
                        minutes: value.end.getMinutes(),
                    },
                };
            }
        }

        return { ...workingHoursException, workingHours };
    };

    private handleSave = (): void => {
        let errors = new Map<string, string>(this.state.errors);
        errors = validateForm(errors, this.prepareWorkingHoursException(), this.props.workingHoursExceptions);
        this.setState({ errors });
        if (errors.size !== 0) {
            return;
        }

        this.setState({ loading: true });
        this.props.onSave(this.prepareWorkingHoursException());
        this.props.onClose();
    };

    private onInputValidation = (
        field: keyof WorkingHoursException,
        isValid: boolean,
        error?: string,
    ): Promise<void> => {
        return new Promise((resolve) => {
            return this.setState((state: State) => {
                const errors = new Map(state.errors);

                if (isValid) {
                    errors.delete(field);
                } else {
                    const transformedError = error === undefined ? '' : error;
                    if (errors.get(field) !== transformedError) {
                        errors.set(field, transformedError);
                    }
                }

                return { ...state, errors };
            }, resolve);
        });
    };

    render() {
        const { t, onClose } = this.props;
        const { workingHoursException, weekDayList, errors, loading } = this.state;

        if (!weekDayList) {
            return null;
        }

        const saveButton = (
            <LoadingButton
                color="primary"
                name="submit"
                onClick={this.handleSave}
                loading={loading}
                disabled={errors.size !== 0}
            >
                {t('button.save')}
            </LoadingButton>
        );

        return (
            <PureFormDialog
                open={true}
                title={t('working_hour_exceptions.add_exception_modal.title')}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                actions={
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            {t('button.cancel')}
                        </Button>
                        {errors.size !== 0 ? (
                            <Tooltip title={t('working_hour_exceptions.add_exception_modal.save_button_hint')}>
                                <span>{saveButton}</span>
                            </Tooltip>
                        ) : (
                            saveButton
                        )}
                    </DialogActions>
                }
            >
                <div className="working-hours-exceptions-hint">
                    <i className="fas fa-info-circle" />
                    {t('working_hour_exceptions.add_exception_modal.hint_text')}
                </div>

                <h4>{t('working_hour_exceptions.add_exception_modal.period')}</h4>
                <div className="working-hour-exception-period">
                    <div className="working-hour-exception-period-datepicker">
                        <DatePicker
                            className="working-hour-exception-period-datepicker"
                            fullWidth
                            name="periodFrom"
                            label={t('working_hour_exceptions.add_exception_modal.period.from')}
                            value={
                                workingHoursException.periodFrom
                                    ? moment(workingHoursException.periodFrom).format(DATETIME_PICKER_FORMAT)
                                    : null
                            }
                            onChange={this.handlePeriodFromChange}
                            error={errors.has('periodFrom')}
                        />
                        <FormHelperText
                            error
                            className={`working-hours-exceptions-form-error${
                                errors.get('periodFrom') ? ' enabled' : ''
                            }`}
                        >
                            {errors.get('periodFrom') ? t(errors.get('periodFrom')!) : ' '}
                        </FormHelperText>
                    </div>
                    <span className="working-hour-exception-period-dash"> — </span>
                    <div className="working-hour-exception-period-datepicker">
                        <DatePicker
                            className="working-hour-exception-period-datepicker"
                            fullWidth
                            name="periodTo"
                            label={t('working_hour_exceptions.add_exception_modal.period.to')}
                            value={
                                workingHoursException.periodTo
                                    ? moment(workingHoursException.periodTo).format(DATETIME_PICKER_FORMAT)
                                    : null
                            }
                            onChange={this.handlePeriodToChange}
                            error={errors.has('periodTo')}
                        />
                        <FormHelperText
                            error
                            className={`working-hours-exceptions-form-error${errors.get('periodTo') ? ' enabled' : ''}`}
                        >
                            {errors.get('periodTo') ? t(errors.get('periodTo')!) : ' '}
                        </FormHelperText>
                    </div>
                </div>
                <FormHelperText
                    error
                    className={`working-hour-exception-period-error working-hours-exceptions-form-error${
                        errors.get('period') ? ' enabled' : ''
                    }`}
                >
                    {errors.get('period')
                        ? t('working_hour_exceptions.add_exception_modal.error.exceptions_conflict', {
                              ranges: errors.get('period')!,
                          })
                        : ' '}
                </FormHelperText>

                <WorkingDays
                    workingDays={weekDayList}
                    onStartDateChanged={this.handleScheduleDayStartTimeChanged}
                    onEndDateChanged={this.handleScheduleDayEndTimeChanged}
                    onIncludeDayChanged={this.handleIncludeDayChanged}
                    disabled={!workingHoursException.periodFrom || !workingHoursException.periodTo}
                    enableOnlyWithinRanges={true}
                    rangeStart={
                        workingHoursException.periodFrom ? new Date(workingHoursException.periodFrom) : undefined
                    }
                    rangeEnd={workingHoursException.periodTo ? new Date(workingHoursException.periodTo) : undefined}
                />

                <div className="c-form-dialog-route-preference__margin" style={{ maxWidth: 270 }}>
                    <AllowedOvertimeInput
                        value={workingHoursException.allowedOvertime || null}
                        onValueChanged={this.handleAllowedOverTimeChange}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('allowedOvertime', isValid, error)
                        }
                        error={errors.get('allowedOvertime')}
                    />
                </div>

                <div className="c-form-dialog-route-preference__margin" style={{ maxWidth: 270 }}>
                    <BreakTypeInput
                        value={workingHoursException.breakType || null}
                        onValueChanged={this.handleBreakTypeChange}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('breakType', isValid, error)
                        }
                        error={errors.get('breakType')}
                    />
                </div>
                {workingHoursException.breakType !== null && (
                    <div className="c-form-dialog-route-preference__margin" style={{ marginBottom: 5 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <BreakDurationInput
                                    value={workingHoursException.breakDuration || null}
                                    onValueChanged={this.handleBreakDurationChange}
                                    onInputValidation={(isValid: boolean, error?: string) =>
                                        this.onInputValidation('breakDuration', isValid, error)
                                    }
                                    error={errors.get('breakDuration')}
                                />
                            </Grid>
                            {workingHoursException.breakType === BreakTypeEnum.TIME_WINDOW && (
                                <React.Fragment>
                                    <Grid item xs={4}>
                                        <BreakEarliestTimeInput
                                            value={workingHoursException.breakEarliestTime || null}
                                            onValueChanged={this.handleBreakEarliestTimeChange}
                                            error={
                                                errors.get('breakEarliestTime')
                                                    ? t(errors.get('breakEarliestTime')!, {
                                                          max: workingHoursException.breakLatestTime,
                                                      })
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BreakLatestTimeInput
                                            value={workingHoursException.breakLatestTime || null}
                                            onValueChanged={this.handleBreakLatestTimeChange}
                                            error={
                                                errors.get('breakLatestTime')
                                                    ? t(errors.get('breakLatestTime')!, {
                                                          min: workingHoursException.breakEarliestTime,
                                                      })
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </div>
                )}
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(ExceptionDialog);
