import React from 'react';
import qs from 'qs';
import SsuNotification from './SsuNotification';
import SsoUnlinkNotification from './SsoUnlinkNotification';

export default class PopupNotificationFactory extends React.Component {
    getComponentConstructor = (type) => {
        switch (type) {
            case 'ssu':
                return SsuNotification;
            case 'sso_unlink':
                return SsoUnlinkNotification;
            default:
                return null;
        }
    };

    render() {
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const type = params['msg'] || null;
        const data = params['msg_data'] || null;

        const componentConstructor = this.getComponentConstructor(type);
        if (!componentConstructor) {
            return null;
        }

        return React.createElement(componentConstructor, data);
    }
}
