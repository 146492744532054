import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogContent, Icon, IconButton, Typography, withStyles } from '@material-ui/core';
import Canvas from '../Canvas';
import { dataURLtoFile, EImageType } from 'image-conversion';

interface CurrentProps extends WithTranslation {
    open: boolean;
    onSave: (file: Blob, filename: string) => void;
    onCancel: () => void;
    label?: string;
}

interface CurrentState {
    canvas?: fabric.Canvas;
}

const ModalTitle = withStyles({
    root: {
        position: 'absolute',
        top: 10,
        left: 10,
        fontSize: '1rem',
    },
})(Typography);
const CloseButton = withStyles({
    root: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
})(IconButton);
const ButtonPanel = withStyles({
    root: {
        position: 'absolute',
        left: 10,
        bottom: 10,
    },
})(Box);
const SubmitButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        position: 'absolute',
        bottom: 10,
        right: 10,
    },
}))(Button);

const BACKGROUND_COLOR = '#fcfa9f';

class DrawingModal extends React.Component<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            canvas: undefined,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeCanvas);
        window.addEventListener('orientationchange', this.resizeCanvas);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCanvas);
        window.removeEventListener('orientationchange', this.resizeCanvas);
    }

    handleReady = (canvas: fabric.Canvas) => {
        this.setState({ canvas }, this.resizeCanvas);
    };

    resizeCanvas = () => {
        if (this.state.canvas && this.state.canvas.getSelectionElement()) {
            this.state.canvas.setDimensions({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    handleSave = () => {
        if (!this.state.canvas) {
            return;
        }

        dataURLtoFile(this.state.canvas.toDataURL({ format: 'jpeg' }), EImageType.JPEG).then((file) => {
            this.props.onSave(file, (this.props.label || 'drawing') + '.jpg');
        });
    };

    clear = (event: React.MouseEvent) => {
        event.stopPropagation();
        this.state.canvas?.clear().setBackgroundColor(BACKGROUND_COLOR, () => {});
    };

    render() {
        return (
            <Dialog open={this.props.open} fullScreen>
                <DialogContent style={{ padding: 0, position: 'relative' }}>
                    <Canvas onReady={this.handleReady} isDrawingMode backgroundColor={BACKGROUND_COLOR} />
                    <ModalTitle variant="h6">{this.props.label || 'Drawing'}</ModalTitle>
                    <CloseButton
                        onClick={this.props.onCancel}
                        color="default"
                        size="small"
                        data-testid="drawing_modal.close"
                    >
                        <Icon fontSize="small">close_icon</Icon>
                    </CloseButton>
                    <ButtonPanel>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={this.props.onCancel}
                            startIcon={<Icon className="fas fa-ban" fontSize="small" />}
                            style={{ marginRight: 5 }}
                        >
                            {this.props.t('button.cancel')}
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={this.clear}
                            startIcon={<Icon className="fas fa-redo" fontSize="small" />}
                        >
                            {this.props.t('button.clear')}
                        </Button>
                    </ButtonPanel>
                    <SubmitButton
                        size="small"
                        variant="contained"
                        onClick={this.handleSave}
                        startIcon={<Icon className="fas fa-check" fontSize="small" />}
                    >
                        {this.props.t('button.save')}
                    </SubmitButton>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(DrawingModal);
