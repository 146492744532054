import AbstractEvent from './Events/AbstractEvent';
import AddRecord from './Events/AddRecord';
import UpdateRecord from './Events/UpdateRecord';
import SubmitForm from './Events/SubmitForm';
import RunButton from './Events/RunButton';

export default class EventsFactory {
    public static fromStorage(data: any): AbstractEvent {
        switch (data.class) {
            case 'AddRecord':
                return AddRecord.fromStorage(data);
            case 'RunButton':
                return RunButton.fromStorage(data);
            case 'SubmitForm':
                return SubmitForm.fromStorage(data);
            case 'UpdateRecord':
                return UpdateRecord.fromStorage(data);
        }
        throw new Error('Unknown event type: ' + data.class);
    }
}
