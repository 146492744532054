import React from 'react';
import { humanReadableNumber } from '../../../utils';
import { Slider } from '@material-ui/core';
import { Mark } from '@material-ui/core/Slider/Slider';
import {
    SUBSCRIPTION_AI_MAX_PACKAGE_SIZE,
    SUBSCRIPTION_AI_MIN_PACKAGE_SIZE,
    SUBSCRIPTION_AI_PACKAGE_SIZE,
} from '../constants';

interface AiTokenSliderProps {
    value?: number;
    onChange: (value: number) => void;
}

export class AiTokenSlider extends React.Component<AiTokenSliderProps> {
    constructor(props: AiTokenSliderProps) {
        super(props);

        this.state = {
            value: props.value ?? 0,
        };
    }

    handleChangeTokens = (_: React.ChangeEvent<{}>, tokens: number | number[]) => {
        if (tokens < SUBSCRIPTION_AI_MIN_PACKAGE_SIZE) {
            tokens = SUBSCRIPTION_AI_MIN_PACKAGE_SIZE;
        }

        if (tokens > SUBSCRIPTION_AI_MAX_PACKAGE_SIZE) {
            tokens = SUBSCRIPTION_AI_MAX_PACKAGE_SIZE;
        }

        this.props.onChange(tokens as number);
    };

    getSliderMarks(): Mark[] {
        let result: Mark[] = [];
        for (
            let i = SUBSCRIPTION_AI_MIN_PACKAGE_SIZE, index = 0;
            i <= SUBSCRIPTION_AI_MAX_PACKAGE_SIZE;
            i += SUBSCRIPTION_AI_PACKAGE_SIZE, index++
        ) {
            result.push({
                label: index % 2 === 0 ? humanReadableNumber(i) : undefined,
                value: i,
            });
        }

        return result;
    }

    render() {
        return (
            <Slider
                data-testid="ai_tokens_form.new_number_credits"
                step={SUBSCRIPTION_AI_PACKAGE_SIZE}
                marks={this.getSliderMarks()}
                min={SUBSCRIPTION_AI_MIN_PACKAGE_SIZE}
                max={SUBSCRIPTION_AI_MAX_PACKAGE_SIZE}
                valueLabelDisplay="auto"
                value={this.props.value}
                valueLabelFormat={(number: number) => humanReadableNumber(number)}
                onChange={this.handleChangeTokens}
            />
        );
    }
}

export default AiTokenSlider;
