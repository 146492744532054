import { RouteStatus } from '../routing/route';

export enum RoutesChangedAction {
    DELETE = 'delete',
    CREATE = 'create',
}

export interface ChangedRoute {
    id: string;
    name: string;
    isSystem: boolean;
    status: RouteStatus;
}

export interface RoutesChangedPayload {
    action: RoutesChangedAction;
    routes: ChangedRoute[];
}
