import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
} from '@material-ui/core';
import { useSwipeable } from 'react-swipeable';
import Alert from '../Alert';
import Backdrop from '../Backdrop';
import DottedLink from '../DottedLink';
import EditValue from './EditValue';
import InPlaceEdit from './InPlaceEdit';
import LandingLink from '../HelpLink/LandingLink';
import PureFormDialog from '../PureFormDialog';
import RecordViewPointActions from '../PointActions/RecordViewPointActions';
import RecordViewSettings, { RecordViewSettingsTitle } from '../RecordViewSettings';
import RestrictionMessage from '../RestrictionMessage';
import dispatcher from '../../service/dispatcher';
import entityManagerFactory from '../../service/EntityManager';
import { VIEW_RECORD } from '../../service/EntityViewManager';
import { userManager } from '../../service/UserManager';
import viewRecordManager from '../../service/ViewRecordManager';
import events from '../../events';
import { FIELD_CRM_LINK, FIELD_DISTANCE } from '../Map/constants';
import { OTHERS_MODULE, RECORD_MODULE } from '../Permissions/constants';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { FieldLookupType } from 'components/types';
import ChangeHistoryModal from '../Log/DataExchangeLog/ChangeHistoryModal';
import { recordManager } from '../../service/RecordManager';
import basePointManager from '../../service/BasePointManager';
import metadataManager from '../../service/MetadataManager';
import QueueHandler from '../../service/EventsQueue/QueueHandler';
import UpdateRecord, { SelectionType } from '../../service/EventsQueue/Events/UpdateRecord';

const ALERT_TYPE_ESSENTIAL_RESTRICTION = 'essential_restriction';
const TIMEOUT_BEFORE_FADING_UPDATES_OUT = 9000;

class RecordView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = this.emptyState();
        this.updatedTimeout = null;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, (payload) => {
            if (
                this.state.record &&
                this.state.record.entityId &&
                payload.entityIds.includes(this.state.record.entityId)
            ) {
                this.reloadRecord();
            }
        });

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (
                this.state.record &&
                this.state.record.entityId &&
                payload.changesEntities.includes(this.state.record.entityId)
            ) {
                this.reloadRecord();
            }
        });

        dispatcher.subscribe(events.EVENT_VIEW_RECORD, this, () => this.reloadRecord());
        dispatcher.subscribe(events.BASE_POINT, this, () => this.reloadRecord());
        dispatcher.subscribe(events.WS_UPDATE_RECORDS_RESPONSE, this, this.handleRecordUpdated);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_MODIFIED, this, this.handleRecordModified);

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });

        this.reloadRecord(true);
    }

    componentWillUnmount() {
        this.clearUpdatedTimeout();
        dispatcher.unsubscribeFromAllEvents(this);
    }

    clearUpdatedTimeout() {
        if (this.updatedTimeout) {
            clearTimeout(this.updatedTimeout);
            this.updatedTimeout = null;
        }
    }

    emptyState = (record = null) => ({
        alert: null,
        allFields: false,
        columns: null,
        confirmDiscardChangesReason: false,
        editField: null,
        fields: null,
        updatedFields: new Set(),
        filter: '',
        loading: record !== null,
        moving: false,
        record,
        values: {},
        showPersonalSettings: true,
        showPreferences: false,
        updates: {},
        showChangeHistory: false,
    });

    reloadRecord = (initial, isUpdatePending = false) => {
        const { allFields, values, filter } = this.state;
        const record = viewRecordManager.getRecord();
        if (record === null) {
            !initial && this.props.onClose();
            return;
        }

        let reloadingSameRecord = false;
        if (record.entityId !== this.state.record?.entityId || record.recordId !== this.state.record?.recordId) {
            this.setState({ ...this.emptyState(record), allFields, filter });
        } else {
            reloadingSameRecord = true;
            this.setState({ loading: true });
        }

        let settings;
        const view = allFields ? null : VIEW_RECORD;

        metadataManager
            .requestEntityForUser(record.entityId)
            .then((entity) => {
                if (view) {
                    settings = metadataManager.cropEntityFields(entity, view, false);
                } else {
                    settings = metadataManager.cropExcludedEntityFields(entity);
                }
                return recordManager.getRecord(record.entityId, record.recordId, [], isUpdatePending);
            })
            .then((response) => {
                if (record.entityId === this.state.record?.entityId) {
                    const updatedFields = new Set();
                    if (reloadingSameRecord) {
                        for (const fieldName of Object.keys(values)) {
                            if (!isEqual(values[fieldName], response.record[fieldName])) {
                                updatedFields.add(fieldName);
                            }
                        }
                    }
                    this.setState(
                        {
                            loading: false,
                            record,
                            fields: settings.fields,
                            values: response.record,
                            updatedFields,
                        },
                        () => {
                            this.clearUpdatedTimeout();
                            if (updatedFields.size > 0) {
                                this.updatedTimeout = setTimeout(() => {
                                    this.setState({ updatedFields: new Set() });
                                }, TIMEOUT_BEFORE_FADING_UPDATES_OUT);
                            }
                        },
                    );
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.handleError(error);
            });
    };

    getCopiedCoordinates() {
        const clipboardContent = localStorage.getItem('copiedCoordinates');
        if (typeof clipboardContent !== 'string') {
            return null;
        }
        let [lat, lng] = clipboardContent.split(',');
        if (!lat || !lng) {
            return null;
        }
        const regexp = /^\s*([-+]?\d*\.?\d+)\s*$/;
        const mLat = lat.match(regexp);
        const mLng = lng.match(regexp);
        if (mLat === null || mLng === null) {
            return null;
        }
        lat = parseFloat(mLat[1]);
        lng = parseFloat(mLng[1]);

        if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
            return null;
        }

        return [lat, lng];
    }

    handleAllFieldsClick = (event) => {
        event.stopPropagation();
        this.setState(
            (state) => ({
                allFields: !state.allFields,
                filter: '',
            }),
            () => {
                this.reloadRecord();
                window.scrollTo(0, 0);
            },
        );
    };

    handleCloseClick = () => {
        const isUpdatePending = Object.keys(this.state.updates).length > 0;
        const { confirmDiscardChangesReason } = this.state;

        if (isUpdatePending && confirmDiscardChangesReason === false) {
            this.setState({
                confirmDiscardChangesReason: 'close',
            });
            return;
        }

        this.setState({ confirmDiscardChangesReason: false });
        this.props.onClose();
    };

    handleDiscardCancelClick = () => {
        this.setState({ confirmDiscardChangesReason: false });
    };

    handleDiscardChangesClick = () => {
        const { confirmDiscardChangesReason } = this.state;
        this.setState({
            alert: null,
            confirmDiscardChangesReason: false,
            updates: {},
        });
        if (confirmDiscardChangesReason === 'close') {
            this.handleCloseClick();
            return;
        }
        if (confirmDiscardChangesReason === 'update') {
            this.reloadRecord();
        }
    };

    handleEditFieldClick = (event, property) => {
        this.setState({
            editField: {
                anchorEl: event.currentTarget,
                property,
            },
        });
    };

    handleDiscardFieldClick = (event, property) => {
        this.setState((state) => {
            const updates = { ...state.updates };
            for (let update of Object.keys(updates)) {
                if (update === property.name) {
                    delete updates[update];
                }
            }
            return { updates };
        });
    };

    handleError = (error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' });
    };

    handleFilterClear = () => {
        this.setState({
            filter: '',
        });
    };

    handleFilterChange = (event) => {
        this.setState({
            filter: event.target.value,
        });
    };

    handleInPlaceSave = (updates) => {
        this.setState((state) => ({
            updates: { ...state.updates, ...updates },
            editField: null,
        }));
    };

    handleInPlaceCancel = () => {
        this.setState({ editField: null });
    };

    handlePreferencesClick = () => {
        this.setState({ showPreferences: true });
    };

    handlePreferencesClose = () => {
        this.setState({
            showPersonalSettings: true,
            showPreferences: false,
        });
    };

    handlePreferencesSaveClick = () => {
        this.setState({
            showPreferences: false,
        });
    };

    handlePreferencesSave = () => {
        this.setState(
            {
                showPersonalSettings: true,
                showPreferences: false,
            },
            () => {
                this.reloadRecord();
            },
        );
    };

    handleSwitchToPersonalSettings = () => {
        this.setState({ showPersonalSettings: true });
    };

    handleSwitchToGlobalSettings = () => {
        this.setState({ showPersonalSettings: false });
    };

    // n.b.: liveUpdate response message is not sent when update is failed because of adapter connection errors
    handleRecordUpdated = ({ entityId, error }) => {
        const { t } = this.props;
        const { loading, updates, record } = this.state;
        const isUpdatePending = Object.keys(updates).length > 0;

        if (record?.entityId !== entityId) {
            return;
        }

        if (error) {
            this.setState({
                loading: false,
            });
            return;
        }

        if (!loading && isUpdatePending) {
            this.setState({
                alert: {
                    type: 'warning',
                    text: (
                        <>
                            {t('map_page.view_record.was_updated')}&nbsp;
                            <Button onClick={this.handleRefresh} data-testid="map_page.view_record.refresh">
                                {t('map_page.view_record.refresh')}
                            </Button>
                        </>
                    ),
                },
            });
            return;
        }

        this.setState({
            alert: null,
            loading: false,
            updates: {},
        });
        this.reloadRecord(false, isUpdatePending);
    };

    handleRecordModified = ({ entityId, pointIds, modificationType }) => {
        if (modificationType !== 'update') {
            return;
        }

        const { t } = this.props;
        const { loading, moving, updates, record } = this.state;
        const isUpdatePending = Object.keys(updates).length > 0;

        if (record?.entityId !== entityId || !pointIds.includes(record.recordId)) {
            return;
        }
        if (moving && pointIds.length === 1) {
            this.setState({
                moving: false,
            });
        }
        if (!loading && isUpdatePending) {
            this.setState({
                alert: {
                    type: 'warning',
                    text: (
                        <>
                            {t('map_page.view_record.was_updated')}&nbsp;
                            <Button onClick={this.handleRefresh} data-testid="map_page.view_record.refresh">
                                {t('map_page.view_record.refresh')}
                            </Button>
                        </>
                    ),
                },
            });
            return;
        }
        this.reloadRecord(false, isUpdatePending);
    };

    handleRefresh = () => {
        this.setState({
            confirmDiscardChangesReason: 'update',
        });
    };

    handleSaveChangesClick = () => {
        const { updates, record, fields } = this.state;
        const entity = metadataManager.getEntityForUser(record.entityId);

        if (entity === null) {
            return;
        }

        const updatedFields = Object.keys(updates).map((field) => {
            const update = { ...updates[field] };
            if (update.actualValue) {
                update.value = update.actualValue;
                delete update.actualValue;
            }
            return update;
        });

        const request = updatedFields.map((obj1) => {
            let obj2 = fields.find((obj2) => obj2.apiName === obj1.apiName);
            if (obj2) {
                return { ...obj1, ...obj2 };
            } else {
                return obj1;
            }
        });

        this.setState({
            loading: true,
        });

        QueueHandler.addEvent(
            new UpdateRecord(
                this.props.accountId,
                entity.dsId,
                record.entityId,
                request,
                [record.recordId],
                null,
                null,
                1,
                SelectionType.SELECTED,
                null,
            ),
        )
            .then(() => {
                // n.b.: concurrent updates can overlap (one should implement message_id in api response to block component till specific update result message received)
                dispatcher.dispatch(events.UPDATE_RECORDS_REQUEST, {
                    entityId: record.entityId,
                    fields: request,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                this.handleError(error);
            });
    };

    handleDismissAlert = () => {
        this.setState({ alert: null });
    };

    handleMovePoint = () => {
        const { t } = this.props;
        const { record } = this.state;

        if (!record?.entityId || !record?.recordId) {
            return;
        }

        if (userManager.hasEssentialRestrictions()) {
            this.setState({ alert: { type: ALERT_TYPE_ESSENTIAL_RESTRICTION } });
            return;
        }

        const copiedCoordinates = this.getCopiedCoordinates();
        if (!copiedCoordinates) {
            this.setState({
                alert: {
                    type: 'danger',
                    text: (
                        <span>
                            {t('map_page.view_record.move_error.2.copy_location')}&nbsp;
                            <b>{t('map_page.view_record.move_error.2.move')}</b>:&nbsp;
                            {t('map_page.view_record.move_error.2.right_click')}&nbsp;
                            <LandingLink useTrailingIcon article="4312063" style={{ color: 'inherit' }}>
                                {t('map_page.view_record.move_error.learn_more')}
                            </LandingLink>
                        </span>
                    ),
                },
            });
            return;
        }

        this.setState({
            moving: true,
            loading: true,
        });

        metadataManager
            .requestEntityForUser(record.entityId)
            .then((entity) => {
                if (entity.id !== this.state.record?.entityId) {
                    return;
                }
                if (!entity.useLatLngFields || !entity.latField || !entity.lngField) {
                    this.setState({
                        moving: false,
                        loading: false,
                        alert: {
                            type: 'danger',
                            text: (
                                <span>
                                    {t('map_page.view_record.move_error.1', { entityLabel: entity.title })}&nbsp;
                                    <LandingLink useTrailingIcon article="4312038" style={{ color: 'inherit' }}>
                                        {t('map_page.view_record.move_error.learn_more')}
                                    </LandingLink>
                                </span>
                            ),
                        },
                    });
                    return;
                }

                return QueueHandler.addEvent(
                    new UpdateRecord(
                        this.props.accountId,
                        entityManagerFactory.getManager(this.props.accountId, record.entityId).dsId,
                        record.entityId,
                        [
                            { apiName: entity.latField, value: copiedCoordinates[0].toFixed(6) },
                            { apiName: entity.lngField, value: copiedCoordinates[1].toFixed(6) },
                        ],
                        [record.recordId],
                        null,
                        null,
                        1,
                        SelectionType.SELECTED,
                        null,
                    ),
                );
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    moving: false,
                });
                this.handleError(error);
            });
    };

    prepareActiveFields = (showFields) => {
        const lookupNameFields = [];
        const lookupTypeFieldsMap = new Map();
        const activeFieldsMap = new Map();
        for (let field of showFields) {
            if (field.apiName === FIELD_DISTANCE && !basePointManager.getBasePoint()) {
                continue;
            }
            if (field.lookupData === null) {
                activeFieldsMap.set(field.apiName, field);
                continue;
            }

            if (field.lookupData.type !== FieldLookupType.POLYMORPHIC) {
                activeFieldsMap.set(field.apiName, field);
                continue;
            }

            const match = field.apiName.match(/_(ID|NAME|TYPE)$/);
            if (match === null) {
                activeFieldsMap.set(field.apiName, field);
                continue;
            }

            const internalType = match[1].toLowerCase();
            if (internalType === 'id') {
                activeFieldsMap.set(field.apiName, field);
                continue;
            }

            if (internalType === 'type') {
                lookupTypeFieldsMap.set(field.originalApiName, field);
                continue;
            }

            if (internalType === 'name') {
                lookupNameFields.push(field);
                activeFieldsMap.set(field.apiName, field);
            }
        }

        for (let i = 0; i < lookupNameFields.length; i++) {
            const lookupTypeField = lookupTypeFieldsMap.get(lookupNameFields[i].originalApiName);
            if (lookupTypeField) {
                lookupNameFields[i].typeValue = this.getFieldValue(lookupTypeField.apiName);
            }
        }

        return [...activeFieldsMap.values()];
    };

    openChangeHistory = () => {
        this.setState({ showChangeHistory: true });
    };

    closeChangeHistory = () => {
        this.setState({ showChangeHistory: false });
    };

    getFieldValue = (apiName) => {
        return this.state.values[apiName] ?? null;
    };

    render() {
        const {
            alert,
            allFields,
            columns,
            confirmDiscardChangesReason,
            editField,
            fields,
            loading,
            moving,
            record,
            showPersonalSettings,
            showPreferences,
            updates,
            updatedFields,
        } = this.state;
        if (record === null) {
            return null;
        }

        const { accountId, fullScreen, open, classes, t } = this.props;

        const entity = metadataManager.getEntityForUser(record.entityId);
        const entityLabel = entity?.label;
        const isUpdatePending = Object.keys(updates).length > 0;
        const isSavingRestricted = userManager.hasEssentialRestrictions();
        const recordSettingsEnabled = userManager.userHasAccessTo(
            RECORD_MODULE.NAME,
            RECORD_MODULE.FEATURES.SHOW_HIDE_RECORD_COLUMNS.NAME,
        );
        const allFieldsEnabled = userManager.userHasAccessTo(
            RECORD_MODULE.NAME,
            RECORD_MODULE.FEATURES.VIEW_ALL_RECORD_COLUMNS.NAME,
        );
        const changeHistoryEnabled = userManager.userHasAccessTo(
            OTHERS_MODULE.NAME,
            OTHERS_MODULE.FEATURES.CHANGE_HISTORY.NAME,
        );

        let title = record.objectName || t('map_page.view_record.title');
        let address = record.address || null;

        const crmLink = columns?.[FIELD_CRM_LINK] ? JSON.parse(columns[FIELD_CRM_LINK]) : undefined;
        const loadingMessage = moving
            ? t('map_page.view_record.moving_point')
            : isUpdatePending
            ? t('map_page.view_record.updating')
            : t('map_page.view_record.loading');

        const filter = this.state.filter.toLowerCase();
        const filteredFields =
            fields && filter !== '' ? fields.filter((field) => field.title.toLowerCase().indexOf(filter) !== -1) : [];

        const showFields = fields ? (filteredFields.length === 0 ? fields : filteredFields) : [];
        const sortedFields = showFields.sort((fieldA, fieldB) => fieldA.isMapslyField - fieldB.isMapslyField);
        const activeFields = this.prepareActiveFields(sortedFields);

        return (
            <SwipeableDialog
                onSwipedRight={this.handleCloseClick}
                TransitionComponent={SlideFromRight}
                className="c-view-record"
                PaperProps={{ square: true }}
                fullScreen={fullScreen}
                onClose={this.handleCloseClick}
                maxWidth="md"
                scroll="paper"
                open={open}
                fullWidth
            >
                <DialogTitle disableTypography className={classes.title}>
                    <Typography noWrap variant="subtitle1">
                        {entityLabel}
                    </Typography>
                    <Typography noWrap variant="caption">
                        {title}
                    </Typography>
                    <IconButton
                        color="default"
                        className={classes.backButton}
                        onClick={this.handleCloseClick}
                        aria-disabled
                        data-testid="map_page.view_record.close"
                    >
                        <Icon>arrow_back_icon</Icon>
                    </IconButton>
                    {recordSettingsEnabled && (
                        <IconButton
                            color="default"
                            className={classes.preferencesButton}
                            onClick={this.handlePreferencesClick}
                            aria-label="preferences"
                            data-testid="map_page.view_record.preferences"
                        >
                            <Icon>settings_icon</Icon>
                        </IconButton>
                    )}
                    <IconButton
                        color="default"
                        className={classes.closeButton}
                        onClick={this.handleCloseClick}
                        aria-label="close"
                        data-testid="map_page.view_record.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {address !== null && (
                        <DialogContentText variant="body2" noWrap>
                            <Icon style={{ verticalAlign: 'bottom' }}>location_on_icon</Icon>
                            {address}
                        </DialogContentText>
                    )}
                    <Backdrop loading={loading} task={loadingMessage}>
                        {allFields && (
                            <FormControl className={classes.search} fullWidth>
                                <TextField
                                    name="search"
                                    value={this.state.filter}
                                    onChange={this.handleFilterChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.leftAdornment}>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment:
                                            this.state.filter === '' ? null : (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.handleFilterClear}
                                                        data-testid="map_page.view_record.filter.clear"
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                    }}
                                    placeholder={t('map_page.view_record.search.placeholder')}
                                    data-testid="map_page.view_record.search"
                                    fullWidth
                                    autoFocus
                                />
                                <IconButton
                                    className={classes.rightAdornment}
                                    onClick={this.handleAllFieldsClick}
                                    disabled={loading}
                                    data-testid="map_page.view_record.all_fields"
                                >
                                    <Icon className="fas fa-compress-alt" fontSize="small" />
                                </IconButton>
                            </FormControl>
                        )}
                        {this.state.filter !== '' && filteredFields.length === 0 && (
                            <DialogContentText variant="caption" className={classes.searchMessage}>
                                {t('record_view_settings.no_fields_found')}
                            </DialogContentText>
                        )}
                        <Grid container spacing={2}>
                            {activeFields.map((field) => {
                                const cellClassName =
                                    classes[updates[field.apiName] === undefined ? 'original' : 'modified'];
                                const updatedClassName = updatedFields.has(field.apiName) ? classes.updated : undefined;
                                return (
                                    <EditValue
                                        key={field.apiName}
                                        accountId={accountId}
                                        onDiscard={this.handleDiscardFieldClick}
                                        onEdit={this.handleEditFieldClick}
                                        updates={updates}
                                        property={field}
                                        className={clsx(cellClassName, updatedClassName)}
                                        value={this.getFieldValue(field.apiName)}
                                    />
                                );
                            })}
                        </Grid>
                        {fields && fields.length === 0 && (
                            <Typography>{t('map_page.view_record.no_fields')}</Typography>
                        )}
                        {allFieldsEnabled && (
                            <DialogContentText align="center" variant="body1">
                                <DottedLink
                                    onClick={this.handleAllFieldsClick}
                                    disabled={loading}
                                    data-testid="map_page.view_record.compress"
                                >
                                    <Icon
                                        className={allFields ? 'fas fa-compress-alt' : 'fas fa-expand-alt'}
                                        style={{ overflow: 'visible', color: 'gray', fontSize: 16 }}
                                    />
                                    &nbsp;
                                    {t(allFields ? 'map_page.view_record.compress' : 'map_page.view_record.expand')}
                                </DottedLink>
                            </DialogContentText>
                        )}
                    </Backdrop>
                </DialogContent>
                {alert?.type === ALERT_TYPE_ESSENTIAL_RESTRICTION && (
                    <Alert type="warning" placement="context" onClose={this.handleDismissAlert}>
                        <RestrictionMessage text={t('live_update_table_form.locked_message')} />
                    </Alert>
                )}
                {alert !== null && alert.type !== ALERT_TYPE_ESSENTIAL_RESTRICTION && (
                    <Alert type={alert.type} placement="context" onClose={this.handleDismissAlert}>
                        {alert.text}
                    </Alert>
                )}
                {isSavingRestricted && isUpdatePending && (
                    <Alert type="warning" placement="context">
                        <RestrictionMessage text={t('live_update_table_form.locked_message')} />
                    </Alert>
                )}
                {isUpdatePending && (
                    <DialogActions>
                        <Button
                            color="default"
                            disabled={loading}
                            onClick={this.handleDiscardChangesClick}
                            data-testid="map_page.view_record.button.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                        <Button
                            color="primary"
                            disabled={isSavingRestricted || loading}
                            onClick={this.handleSaveChangesClick}
                            data-testid="map_page.view_record.button.save"
                        >
                            {t('button.save')}
                        </Button>
                    </DialogActions>
                )}
                {!isUpdatePending && (
                    <RecordViewPointActions
                        className={classes.dialogPointActions}
                        crmLink={crmLink}
                        record={record}
                        onCloseView={this.handleCloseClick}
                        onFindOnMap={this.props.onFindOnMap}
                        onMovePoint={this.handleMovePoint}
                        onOpenChangeHistory={changeHistoryEnabled ? this.openChangeHistory : undefined}
                    />
                )}
                {editField !== null && entity !== null && (
                    <InPlaceEdit
                        anchorEl={editField.anchorEl}
                        property={editField.property}
                        value={this.getFieldValue(editField.property.apiName)}
                        values={this.state.values}
                        updates={updates}
                        recordId={record.recordId}
                        entityId={record.entityId}
                        dataSourceId={entity.dsId}
                        accountId={accountId}
                        onSave={this.handleInPlaceSave}
                        onClose={this.handleInPlaceCancel}
                    />
                )}
                {showPreferences && entity !== null && (
                    <PureFormDialog
                        className="c-record-view-form"
                        onClose={this.handlePreferencesClose}
                        title={
                            <RecordViewSettingsTitle
                                onOpenGlobalSettings={this.handleSwitchToGlobalSettings}
                                onOpenPersonalSettings={this.handleSwitchToPersonalSettings}
                                showPersonalSettings={showPersonalSettings}
                                entity={entity}
                            />
                        }
                        maxWidth="sm"
                        fullWidth
                        open
                    >
                        <RecordViewSettings
                            onClose={this.handlePreferencesClose}
                            onError={this.handleError}
                            onOpenGlobalSettings={this.handleSwitchToGlobalSettings}
                            onOpenPersonalSettings={this.handleSwitchToPersonalSettings}
                            onSave={this.handlePreferencesSave}
                            onSaveClick={this.handlePreferencesSaveClick}
                            showPersonalSettings={showPersonalSettings}
                            accountId={accountId}
                            entity={entity}
                        />
                    </PureFormDialog>
                )}
                <Dialog open={confirmDiscardChangesReason !== false} onClose={this.handleDiscardCancelClick}>
                    <DialogContent>{t('map_page.view_record.changes.discard.prompt')}</DialogContent>
                    <DialogActions>
                        <Button
                            color="default"
                            onClick={this.handleDiscardChangesClick}
                            data-testid="map_page.view_record.changes.discard"
                        >
                            {t('map_page.view_record.changes.discard')}
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.handleDiscardCancelClick}
                            data-testid="map_page.view_record.changes.cancel"
                        >
                            {t('map_page.view_record.changes.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.showChangeHistory && (
                    <ChangeHistoryModal
                        accountId={this.props.accountId}
                        user={userManager.getCurrentUser()}
                        recordId={record.recordId}
                        onClose={this.closeChangeHistory}
                        entityId={record.entityId}
                    />
                )}
            </SwipeableDialog>
        );
    }
}

RecordView.propTypes = {
    accountId: PropTypes.number.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onFindOnMap: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    routeManager: PropTypes.object, // todo: check routeManager is refactored in MD-1295
};

const dialogStyle = () => ({
    container: {
        justifyContent: 'flex-end',
        paddingTop: 'env(safe-area-inset-top)',
    },
    paper: {
        maxHeight: '100%',
        height: '100%',
        margin: 0,
    },
});

export const FullHeightDialog = withStyles(dialogStyle)(Dialog);

export const SwipeableDialog = (props) => {
    const { open, onSwipedRight, ...rest } = props;
    const swipeHandlers = useSwipeable({
        onSwipedRight: (eventData) => onSwipedRight && onSwipedRight(eventData),
    });
    return <FullHeightDialog open={open} {...swipeHandlers} {...rest} />;
};

export const SlideFromRight = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ResponsiveRecordView = (props) => {
    const theme = useTheme();
    const { breakpoint, ...rest } = props;
    //max action buttons
    const fullScreen = useMediaQuery(theme.breakpoints.down(breakpoint || 'sm'));

    return <RecordView fullScreen={fullScreen} {...rest} />;
};

const style = () => ({
    title: {
        paddingTop: 8,
        paddingRight: 88,
        paddingBottom: 8,
        paddingLeft: 56,
        '& > .MuiTypography-caption': {
            display: 'block',
            lineHeight: 1,
            fontWeight: 500,
            fontSize: 20,
        },
        '& > .MuiTypography-subtitle1': {
            lineHeight: 1,
            fontSize: 16,
            '&:empty::before': {
                content: "'\\a0'",
            },
        },
    },
    backButton: {
        position: 'absolute',
        left: 8,
        top: 8,
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    preferencesButton: {
        position: 'absolute',
        right: 44,
        top: 8,
    },
    content: {
        padding: '0 24px',
        '& .MuiTypography-body2': {
            overflowWrap: 'break-word',
        },
        '& .MuiTypography-body1': {
            marginTop: 12,
        },
        '& > .c-backdrop': {
            margin: '0 -24px',
            padding: '0 24px',
        },
    },
    search: {
        padding: '0 32px !important',
        margin: '0 0 12px !important',
    },
    searchMessage: {
        marginTop: -12,
        marginLeft: 32,
    },
    leftAdornment: {
        position: 'absolute',
        left: -32,
    },
    rightAdornment: {
        position: 'absolute',
        right: 0,
    },
    dialogPointActions: {
        paddingBottom: 0,
    },
});

export default withTranslation()(withStyles(style)(withSnackbar(ResponsiveRecordView)));
