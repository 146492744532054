import { Fab } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles({
    sizeSmall: {
        height: '30px',
        width: '30px',
        minHeight: '30px',
    },
})(Fab);
