import { Icon } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MapNavigationOption } from 'service/types';
import { Geo } from 'interfaces';

interface MapNavigationMenuProps extends WithTranslation {
    onCloseMenu: () => void;
    onClickMenu: (variant: MapNavigationOption, point: Geo.GeoPoint) => void;
    anchorEl: Element;
    point: Geo.GeoPoint;
}

export const NavigationOptionIconMap = {
    [MapNavigationOption.GOOGLE]: 'fab fa-google',
    [MapNavigationOption.APPLE]: 'fab fa-apple',
    [MapNavigationOption.WAZE]: 'fab fa-waze',
};

const MapNavigationMenu = (props: MapNavigationMenuProps) => {
    const { t, anchorEl, point, onCloseMenu, onClickMenu } = props;

    return (
        <Menu
            anchorEl={anchorEl}
            open={true}
            onClose={onCloseMenu}
            className="app-menu-modal"
            onClick={onCloseMenu}
            disableAutoFocusItem={true}
        >
            <MenuItem
                onClick={() => onClickMenu(MapNavigationOption.GOOGLE, point)}
                data-testid="route_report.modal.open_in_google"
            >
                <Icon
                    className={NavigationOptionIconMap[MapNavigationOption.GOOGLE]}
                    color="primary"
                    fontSize="small"
                    style={{ marginRight: '.5em' }}
                />
                <span>{t('route_report.modal.open_in_google')}</span>
            </MenuItem>
            <MenuItem
                onClick={() => onClickMenu(MapNavigationOption.APPLE, point)}
                data-testid="route_report.modal.open_in_apple"
            >
                <Icon
                    className={NavigationOptionIconMap[MapNavigationOption.APPLE]}
                    color="primary"
                    fontSize="small"
                    style={{ marginRight: '.5em' }}
                />
                <span>{t('route_report.modal.open_in_apple')}</span>
            </MenuItem>
            <MenuItem
                onClick={() => onClickMenu(MapNavigationOption.WAZE, point)}
                data-testid="route_report.modal.open_in_waze"
            >
                <Icon
                    className={NavigationOptionIconMap[MapNavigationOption.WAZE]}
                    color="primary"
                    fontSize="small"
                    style={{ marginRight: '.5em' }}
                />
                <span>{t('route_report.modal.open_in_waze')}</span>
            </MenuItem>
        </Menu>
    );
};

export default withTranslation()(MapNavigationMenu);
