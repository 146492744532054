import dispatcher from './dispatcher';
import events from '../events';
import { GooglePlaceStructure } from '../components/Prospecting/GooglePlaceStructure';
import AsyncBackendService from 'api/AsyncBackendService';
import { GeocoderResultStatus } from '../components/types';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { GEO_FIELDS } from '../references/geoFields';
import { userManager } from './UserManager';
import { distance, midpoint, pointInBounds, sleep } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next';
import intersection from 'lodash/intersection';

const MAX_RETRY_COUNT = 5;

const PLACES_DETAILS_FIELD_ID = 'place_id';
const PLACES_DETAILS_FIELD_PHOTOS = 'photos';

const PLACES_DETAILS_POPUP_FIELDS = [
    PLACES_DETAILS_FIELD_ID,
    'geometry/location',
    'name',
    'rating',
    'formatted_address',
    'address_component',
    'type',
    'business_status',
    'formatted_phone_number',
    PLACES_DETAILS_FIELD_PHOTOS,
    'website',
    'url',
    'user_ratings_total',
];

const PLACES_DETAILS_EXPORT_FIELDS = ['place_id', 'geometry/location'];

const waitTwoSeconds = async () => await sleep(2000);

class ProspectingManager extends AsyncBackendService {
    constructor() {
        super(events.WS_GEOCODER_RESPONSE);
        this.prospectingPoints = new Map();
        this.prospectingRecords = [];
        this.prospectingItems = new Map(); // source for this.prospectingPoints and this.prospectingRecords
        this.itemsWithDetails = new Map();
        this.exportedPoints = new Map();
        this.structureBuilder = new GooglePlaceStructure();
        this.prospectingModeEnable = false;
        dispatcher.subscribe(events.WS_PROSPECT_IMPORT_ENDED, this, this.refreshExportedStatuses);
    }

    searchExportedProspects = async (prospectsIds) => {
        const accountId = userManager.getCurrentAccount().id;
        return this.requestApi(reverse(apiRoutes.account.searchExportedProspects, { accountId: accountId }), 'POST', {
            searchingProspects: prospectsIds,
        }).then((response) => {
            response.result.forEach((item) => {
                this.exportedPoints.set(item.prospect_id, item.record_id);
            });
            return Promise.resolve();
        });
    };

    // reverted to load photos details with front app's google api key
    initGoogleApi() {
        return new Promise((resolve, reject) => {
            if (typeof window.google === 'object') {
                this.googleApi = new window.google.maps.places.PlacesService(document.createElement('div'));
                return resolve();
            }
            reject('Google Api not initial');
        });
    }

    /**
     * reverted to load photos details with front app's google api key
     * loads place details with front app's google api key
     * @param pointId
     * @return {Promise<PlaceResult>}
     */
    async loadPhotoDetails(pointId) {
        return this.initGoogleApi().then(() => {
            return new Promise((resolve, reject) => {
                if (this.itemsWithDetails.has(pointId)) {
                    let item = this.itemsWithDetails.get(pointId);
                    this.setExportStatus(item);
                    resolve(this.structureBuilder.formatItemToPoint(item));
                }

                const request = {
                    placeId: pointId,
                    fields: [PLACES_DETAILS_FIELD_ID, PLACES_DETAILS_FIELD_PHOTOS],
                };
                this.googleApi.getDetails(request, (item, status) => {
                    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                        reject('Google send error');
                        return;
                    }
                    resolve(item);
                });
            });
        });
    }

    refreshExportedStatuses = async ({ prospectIds }) => {
        if (this.prospectingItems.size === 0 || prospectIds === undefined || prospectIds.length === 0) {
            return;
        }

        if (!intersection(Array.from(this.prospectingItems.keys()), prospectIds).length) {
            return;
        }

        await this.searchExportedProspects(prospectIds);
        this.processProspectingItems();

        dispatcher.dispatch(events.EVENT_PROSPECTING_POINTS_RELOADED);

        return Promise.resolve();
    };

    loadPoints = async (bounds, searchText, googleApiKey, reload = false) => {
        this.structureBuilder.setApiKey(googleApiKey);

        const prospectingItems = new Map();
        const prospectingIds = [];

        const basePoint = midpoint(
            { lat: bounds.minLat, lng: bounds.minLng },
            { lat: bounds.maxLat, lng: bounds.maxLng },
        );
        const radius = Math.ceil(
            distance({ lat: bounds.minLat, lng: bounds.minLng }, { lat: bounds.maxLat, lng: bounds.maxLng }) / 2,
        ).toFixed(0);

        // session token is used to be sure all consequent page token requests would be done via the same proxy
        const sessionToken = uuidv4();
        const language = userManager.getCurrentUser().language || userManager.getCurrentAccount().language || null;
        const request = {
            term: searchText,
            sessionToken,
            pageToken: null,
            basePoint,
            radius: radius > 50000 ? null : radius,
            language,
        };

        do {
            // there is a lag begore next page is available
            if (request.pageToken !== null) {
                await waitTwoSeconds();
            }

            let attempt = 0,
                response,
                retry;
            do {
                if (attempt > 0) {
                    await waitTwoSeconds();
                }
                response = await this.requestApiAsync(apiRoutes.accountsSearchTextSearch, 'POST', request);
                retry =
                    response.status === GeocoderResultStatus.ERROR_RATE_LIMIT_EXCEEDED ||
                    (request.pageToken !== null && response.status === GeocoderResultStatus.ERROR_OTHER);
            } while (retry && attempt++ < MAX_RETRY_COUNT);

            if (response.status !== GeocoderResultStatus.OK && response.status !== GeocoderResultStatus.NO_RESULT) {
                // n.b.: this Error seems to be dropped silently
                throw new Error(i18n('address_lookup.prospect.internal_error'));
            }

            response.places.forEach((place) => {
                if (pointInBounds(place.geometry.location, bounds)) {
                    const prospectId = this.structureBuilder.getProspectId(place);
                    prospectingItems.set(prospectId, place);
                    prospectingIds.push(prospectId);
                }
            });

            request.pageToken = response.nextPageToken ?? null;
        } while (request.pageToken !== null);

        if (prospectingIds.length === 0) {
            this.prospectingRecords = [];
            this.prospectingPoints = new Map();

            dispatcher.dispatch(events.EVENT_PROSPECTING_POINTS_REMOVED);
            return this.prospectingPoints;
        }

        await this.searchExportedProspects(prospectingIds);
        this.prospectingItems = prospectingItems;
        this.processProspectingItems();

        dispatcher.dispatch(
            reload ? events.EVENT_PROSPECTING_POINTS_RELOADED : events.EVENT_PROSPECTING_POINTS_ADDED,
            this.prospectingItems.size,
        );

        return this.prospectingPoints;
    };

    processProspectingItems = () => {
        this.prospectingRecords = [];
        this.prospectingPoints = new Map();

        this.prospectingItems.forEach((item) => {
            this.setExportStatus(item);
            this.prospectingRecords.push(this.structureBuilder.formatItemToRecords(item));
            this.prospectingPoints.set(item.place_id, this.structureBuilder.formatItemToPoint(item));
        });
    };

    setExportStatus = (item) => {
        const prospectId = this.structureBuilder.getProspectId(item);
        item.exported = this.exportedPoints.has(prospectId);
    };

    async loadPointDetails(pointId, googleApiKey, fields = PLACES_DETAILS_POPUP_FIELDS, raw = false) {
        // this.prospectingPoints
        this.structureBuilder.setApiKey(googleApiKey);

        const itemsWithDetails = this.getItemsWithDetails(fields);
        if (itemsWithDetails.has(pointId)) {
            const place = itemsWithDetails.get(pointId);
            if (raw) {
                return place;
            }

            this.setExportStatus(place);
            return this.structureBuilder.formatItemToPoint(place);
        }

        // photo reference id returned depends on api key used in request and can not be loaded with another api key
        // photo will be requested with (front app's) api key via google places javascript api and loaded with this api key in popup

        let photosDetails;
        const isPhotosRequestNeeded = fields.indexOf(PLACES_DETAILS_FIELD_PHOTOS) !== -1;
        if (isPhotosRequestNeeded) {
            let attempt = 0;
            do {
                try {
                    photosDetails = await this.loadPhotoDetails(pointId);
                } catch (e) {
                    // retry silently
                    console.error(e);
                }
            } while (!photosDetails && attempt++ < MAX_RETRY_COUNT);
        }

        const request = {
            placeId: pointId,
            fields: fields.filter((field) => field !== PLACES_DETAILS_FIELD_PHOTOS),
        };

        let attempt = 0,
            response,
            retry;
        do {
            if (attempt > 0) {
                await waitTwoSeconds();
            }
            response = await this.requestApiAsync(apiRoutes.accountsSearchDetails, 'POST', request);
            retry = response.status === GeocoderResultStatus.ERROR_RATE_LIMIT_EXCEEDED;
        } while (retry && attempt++ < MAX_RETRY_COUNT);

        const { status, details } = response;

        if (status !== GeocoderResultStatus.OK) {
            throw new Error(i18n('address_lookup.prospect.internal_error'));
        }

        details.photos = photosDetails?.place_id === details.place_id ? photosDetails.photos : [];

        itemsWithDetails.set(details.place_id, details);
        if (raw) {
            return details;
        }

        this.setExportStatus(details);
        this.overwriteDetailsPosition(details);
        return this.structureBuilder.formatItemToPoint(details);
    }

    getPoints() {
        return this.prospectingPoints;
    }

    getRecords() {
        return this.prospectingRecords;
    }

    getRecordsByBounds(bounds) {
        let filterResult = [];
        this.prospectingRecords.forEach((item) => {
            if (pointInBounds({ lat: item[GEO_FIELDS.LAT], lng: item[GEO_FIELDS.LNG] }, bounds)) {
                filterResult.push(item);
            }
        });
        return filterResult;
    }

    getRecordsStructure() {
        return this.structureBuilder.buildFieldStructure();
    }

    getMainStructure() {
        return this.structureBuilder.getMainStructure();
    }

    getPoint(pointId) {
        return this.prospectingPoints.get(pointId);
    }

    isEmpty() {
        return this.prospectingPoints.size === 0;
    }

    isProspectModeEnable() {
        return this.prospectingModeEnable;
    }

    enableProspectingMode() {
        this.clear();
        this.prospectingModeEnable = true;
        dispatcher.dispatch(events.EVENT_PROSPECTING_ENABLED);
    }

    clear() {
        this.prospectingModeEnable = false;
        this.prospectingPoints = new Map();
        this.prospectingRecords = [];
        // do not clear this.itemWithDetails cache
        this.exportedPoints = new Map();
    }

    disableProspectingMode() {
        this.clear();
        dispatcher.dispatch(events.EVENT_PROSPECTING_DISABLED);
        dispatcher.dispatch(events.EVENT_PROSPECTING_POINTS_REMOVED);
    }

    getItemsWithDetails = (fields) => {
        const fieldsKey = [...fields].sort().join(',');

        if (!this.itemsWithDetails.has(fieldsKey)) {
            this.itemsWithDetails.set(fieldsKey, new Map());
        }

        return this.itemsWithDetails.get(fieldsKey);
    };

    // todo: give user opportunity to interrupt the process as it could took long time
    async getRecordsForExport(ids, structure, googleApiKey, userDSId) {
        this.structureBuilder.setApiKey(googleApiKey);
        const fields = this.structureBuilder.getRequestFieldsFromProspectingStructure(
            structure,
            PLACES_DETAILS_EXPORT_FIELDS,
        );

        let records = [];
        for (let i = 0; i < ids.length; i++) {
            // if (i > 0) {
            //    await waitTwoSeconds();
            // }

            const pointId = ids[i];
            const details = await this.loadPointDetails(pointId, googleApiKey, fields, true);
            records.push(this.structureBuilder.formatItemToEntityRecords(details, structure, userDSId));
        }

        return records;
    }

    getRecordsForExportIntention(ids, userDSId) {
        console.log('prospectids', ids);
        return ids.map((place_id) => this.structureBuilder.formatItemToEntityRecords({ place_id }, [], userDSId));
    }

    // Places and Details API can have different position for the same place, so we need to overwrite details position with places position
    overwriteDetailsPosition(details) {
        const point = this.getPoint(details.place_id);
        if (point) {
            details.geometry.location.lat = point.position[0];
            details.geometry.location.lng = point.position[1];
        }
    }
}

export const prospectingManager = new ProspectingManager();
