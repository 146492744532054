import React from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../service/workflow_actions/forms/Form';
import Label from '../../../../service/workflow_actions/forms/fields/Label';

import AbstractForm from './AbstractForm';

class LabelForm extends AbstractForm {
    renderFieldInputs(field, errors) {
        return <>{this.renderIsHiddenInput(field, errors)}</>;
    }
}

LabelForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Label).isRequired,
};

export default LabelForm;
