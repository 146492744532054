import BackendService from '../api/BackendService';
import apiRoutes, { reverse } from '../api/apiRoutes';
import { PurposeOfUse } from '../interfaces/account';

class PurposeOfUseManager extends BackendService {
    private purposeOfUse: PurposeOfUse | null = null;

    getPurposeOfUse(accountId: number): Promise<PurposeOfUse> {
        if (null !== this.purposeOfUse) {
            return Promise.resolve(this.purposeOfUse);
        }

        const url = reverse(apiRoutes.account.purposeOfUse, { accountId: accountId });

        return this.requestApi(url, 'GET').then((response: PurposeOfUse | null) => {
            this.purposeOfUse = response;
            return response;
        });
    }
}

export const purposeOfUseManager = new PurposeOfUseManager();
