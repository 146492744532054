import BackendService from 'api/BackendService';

export const TYPE_DEFAULT_RULE_GROUP = 1;
export const TYPE_DEFAULT_ACTION_GROUP = 1;
export const TYPE_MATCH_ACTION_GROUP = 2;

export class WorkflowGroupManager extends BackendService {
    static getDefaultGroup(type) {
        return {
            id: null,
            type,
            expression: null,
            formula: null,
            builderDisabled: false,
            mappings: [],
        };
    }
}

export const workflowGroupManager = new WorkflowGroupManager();
