import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import clsx from 'clsx';
import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@material-ui/core/Checkbox';
import { alpha, withStyles } from '@material-ui/core/styles';

const checkboxStyles = withStyles(
    (theme) => ({
        root: {
            '&.checkbox': {
                padding: '4px',
            },
            '& .MuiTouchRipple-root': {
                color: theme.palette.checkbox.midnight,
                '& .MuiTouchRipple-rippleVisible': {
                    opacity: 0.15,
                },
            },
            '&.Mui-disabled': {
                color: theme.palette.checkbox.grey.light,
                '&.Mui-checked': {
                    color: theme.palette.checkbox.grey.light,
                    '& .MuiIconButton-label': {
                        background: `linear-gradient(to right, ${theme.palette.checkbox.tertiary}, ${theme.palette.checkbox.tertiary}) no-repeat`,
                        backgroundPosition: '50% 50%',
                        backgroundSize: '15px 15px',
                    },
                },
            },
        },
        colorPrimary: {
            color: theme.palette.checkbox.grey.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.checkbox.grey.dark, 0.07),
            },
            '&.Mui-checked': {
                color: theme.palette.checkbox.primary,
            },
        },
    }),
    {
        name: 'MuiCheckbox',
    },
);

const StyledMUICheckbox = checkboxStyles(MUICheckbox);

type CheckboxProps = WithTranslation &
    Omit<MUICheckboxProps, 'size'> & {
        className?: string;
    };

const Checkbox: React.FC<CheckboxProps> = React.memo((props) => {
    const { className, ...otherProps } = props;

    return <StyledMUICheckbox {...otherProps} color="primary" className={clsx('checkbox', className)} />;
});

export default withTranslation()(Checkbox);
