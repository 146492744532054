import AbstractEvent from './AbstractEvent';

export interface Field {
    apiName: string;
    value: string;
}

abstract class AbstractRecord extends AbstractEvent {
    private readonly accountId: number;
    private readonly dataSourceId: number;
    private readonly entityId: number;
    private fields: Array<Field>;

    constructor(accountId: number, dataSourceId: number, entityId: number, fields: Array<Field>) {
        super();

        this.accountId = accountId;
        this.dataSourceId = dataSourceId;
        this.entityId = entityId;
        this.fields = fields;
    }

    public getAccountId(): number {
        return this.accountId;
    }

    public getDataSourceId(): number {
        return this.dataSourceId;
    }

    public getEntityId(): number {
        return this.entityId;
    }

    public setFields(fields: Array<Field>): AbstractRecord {
        this.fields = fields;

        return this;
    }

    public getFields(): Array<Field> {
        return this.fields;
    }

    protected toStorageSpecific(): object {
        return {
            accountId: this.getAccountId(),
            dataSourceId: this.getDataSourceId(),
            entityId: this.getEntityId(),
            fields: this.getFields(),
        };
    }
}

export default AbstractRecord;
