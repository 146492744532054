import React from 'react';
import dispatcher from '../../service/dispatcher';
import FormDialog from '../FormDialog';
import FieldFactory from './Fields/FieldFactory';
import { Grid } from '@material-ui/core';
import events from '../../events';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import i18n from '../../locales/i18n';

const t = i18n.t.bind(i18n);

class BaseWorkflowActionsForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            uploadInProgress: false,
            keepActionButtonsEnabled: false,
            isModified: false,
            showCloseConfirmation: false,
        };

        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.handleFieldHandler = this.handleFieldHandler.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getActionButton = this.getActionButton.bind(this);
        this.handleValidateField = this.handleValidateField.bind(this);
        this.setCloseConfirmation = this.setCloseConfirmation.bind(this);
        this.handleActionButton = this.handleActionButton.bind(this);
    }

    componentDidMount() {
        dispatcher.subscribe(events.WORKFLOW_FILE_UPLOAD_IN_PROGRESS, this, ({ keepActionButtonsEnabled }) => {
            this.setState({ uploadInProgress: true, keepActionButtonsEnabled });
        });
        dispatcher.subscribe(events.WORKFLOW_FILE_UPLOAD_COMPLETE, this, () => {
            this.setState({ uploadInProgress: false });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleCloseForm() {
        if (this.state.isModified) {
            this.setCloseConfirmation(true);
        } else {
            this.closeForm();
        }
    }

    closeForm() {
        this.manager.closeForm();
    }

    handleFieldHandler(field, formContextData = {}) {
        this.manager.handleFieldHandler(field, formContextData);
    }

    handleFieldChange(field, value) {
        this.setState({ isModified: true });
        this.manager.handleFieldChange(field, value);
    }

    getActionButton() {
        return null;
    }

    handleValidateField(field) {
        this.state.errors.set(field.getApiName(), field.validate());

        this.forceUpdate();
    }

    setCloseConfirmation(showCloseConfirmation) {
        this.setState({ showCloseConfirmation });
    }

    handleActionButton(field) {
        throw new Error('Not implemented');
    }

    render() {
        const form = this.manager.getForm();
        const { errors, uploadInProgress, keepActionButtonsEnabled } = this.state;

        let firstInput = true;

        return (
            form && (
                <React.Fragment>
                    <FormDialog
                        title={form.getTitle()}
                        showSaveButton={false}
                        onSave={this.closeForm}
                        onCancel={this.handleCloseForm}
                        maxWidth="md"
                        fullWidth
                        actionsDisabled={uploadInProgress && !keepActionButtonsEnabled}
                        blocks={{
                            actionRightBlock: (
                                <>
                                    {this.getActionButton()}
                                    {form.getActionButtons().map((field) => {
                                        return (
                                            <FieldFactory
                                                accountId={form.getAccountId()}
                                                key={field.getApiName()}
                                                field={field}
                                                errors={errors}
                                                onHandler={this.handleActionButton}
                                                onChange={this.handleFieldChange}
                                            />
                                        );
                                    })}
                                </>
                            ),
                        }}
                    >
                        <Grid container component="form" className="form" onSubmit={(e) => e.preventDefault()}>
                            {form.getFields(true).map((field) => {
                                const autoFocus = firstInput && FieldFactory.isInputField(field);
                                if (autoFocus) {
                                    firstInput = false;
                                }

                                return (
                                    <FieldFactory
                                        accountId={form.getAccountId()}
                                        key={field.getApiName()}
                                        field={field}
                                        errors={errors.get(field.getApiName()) ?? new Map()}
                                        onHandler={this.handleFieldHandler}
                                        onChange={this.handleFieldChange}
                                        onValidate={this.handleValidateField}
                                        autoFocus={autoFocus}
                                        formContext={form.getFormContext()}
                                    />
                                );
                            })}
                        </Grid>
                    </FormDialog>

                    <DialogConfirmation
                        open={this.state.showCloseConfirmation}
                        setOpen={this.setCloseConfirmation}
                        onConfirm={this.closeForm}
                        titleText={t('automation_elements.close.confirmation')}
                        yesBtnText={t('automation_elements.close.confirmation.yes')}
                        noBtnText={t('automation_elements.close.confirmation.no')}
                    />
                </React.Fragment>
            )
        );
    }
}

export default BaseWorkflowActionsForm;
