import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MapNavigationOption } from 'service/types';

interface SelectMapNavigationOptionProps extends WithTranslation {
    value: string;
    onChanged: (value: MapNavigationOption) => void;
}

const SelectMapNavigationOption: React.FC<SelectMapNavigationOptionProps> = (props) => {
    const { t, value, onChanged } = props;

    return (
        <React.Fragment>
            <h4>{t('route_editor.modal.routing_preferences.modal.navigation_app_title')}</h4>
            <p>
                <i style={{ color: 'blue', marginRight: '8px' }} className="fas fa-info-circle" />
                {t('route_editor.modal.routing_preferences.modal.navigation_app_info')}
            </p>
            <div className="c-form-dialog-route-preference__open_map_app">
                <InputLabel id="c-form-dialog-route-preference__open_map_app">
                    {t('route_editor.modal.routing_preferences.modal.navigation_using')}
                </InputLabel>
                <Select
                    labelId="c-form-dialog-route-preference__open_map_app"
                    id="c-form-dialog-route-preference__open_map_app_select"
                    value={value}
                    defaultValue={null}
                    onChange={(event) => onChanged(event.target.value as MapNavigationOption)}
                    fullWidth
                    label={t('route_editor.modal.routing_preferences.modal.navigation_using')}
                >
                    <MenuItem value={MapNavigationOption.GOOGLE} data-testid="route_report.modal.open_in_google">
                        {t('route_report.modal.open_in_google')}
                    </MenuItem>
                    <MenuItem value={MapNavigationOption.APPLE} data-testid="route_report.modal.open_in_apple">
                        {t('route_report.modal.open_in_apple')}
                    </MenuItem>
                    <MenuItem value={MapNavigationOption.WAZE} data-testid="route_report.modal.open_in_waze">
                        {t('route_report.modal.open_in_waze')}
                    </MenuItem>
                </Select>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(SelectMapNavigationOption);
