import React from 'react';
import { intercomManager } from '../../service/IntercomManager';
import './style.css';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { withTranslation } from 'react-i18next';
import { tripModeManager } from 'service/MapPage';
import ReactionsManager from '../../service/MapPage/Reactions/ReactionsManager';

class IntercomLauncherComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            hidden: false,
        };
        this.reactionsManager = new ReactionsManager();
    }

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_INTERCOM_SHOW_MESSAGE, this, () => {
            if (!this.state.open) {
                this.setState({
                    open: true,
                });
            }
        });

        dispatcher.subscribe(events.EVENT_INTERCOM_HIDE_MESSAGE, this, () => {
            if (this.state.open) {
                this.setState({
                    open: false,
                });
            }
        });

        dispatcher.subscribe(events.EVENT_INTERCOM_STATE_CHANGED, this, () => {
            if (this.state.open) {
                this.setState({
                    open: false,
                });
                return;
            }
            this.forceUpdate();
        });

        dispatcher.subscribe(events.EVENT_INTERCOM_STATE_OPENED, this, () => {
            this.setState({ open: true });
            intercomManager.showWidget();
        });

        this.reactionsManager.addReaction(
            this.constructor.name,
            () => tripModeManager.isTripStarted,
            () => {
                this.setState({
                    hidden: tripModeManager.isTripStarted,
                });
            },
        );
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
        this.reactionsManager.dispose(this.constructor.name);
    }

    handleToggle = () => {
        this.setState(
            {
                open: !this.state.open,
            },
            () => {
                if (!this.state.open) {
                    intercomManager.closeWidget();
                } else {
                    intercomManager.showWidget();
                }
            },
        );
    };

    render() {
        if (!intercomManager.isLoaded() || this.state.hidden) {
            return null;
        }

        return (
            <div className="intercom-launcher-custom-app" aria-live="polite" onClick={this.handleToggle}>
                <div
                    className="intercom-launcher-custom-app-launcher intercom-launcher"
                    role="button"
                    tabIndex="0"
                    aria-label={this.props.t('intercom_launcher.aria_label')}
                >
                    {!this.state.open && (
                        <div className="intercom-launcher-custom-app-launcher-icon intercom-launcher-custom-app-launcher-icon-open">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                                <path
                                    d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54
                                    0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0
                                    00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0
                                    00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15
                                    6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"
                                />
                            </svg>
                        </div>
                    )}
                    {this.state.open && (
                        <div className="intercom-launcher-custom-app-launcher-icon intercom-launcher-custom-app-launcher-icon-minimize">
                            <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.116 4.884l1.768-1.768L8 9.232l6.116-6.116 1.768 1.768L8 12.768.116 4.884z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const IntercomLauncher = withTranslation()(IntercomLauncherComponent);
