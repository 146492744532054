import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse } from 'date-fns';
import { FormControl, FormHelperText, Grid } from '@material-ui/core';
import Hint from '../../Hint';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputLabel from '@material-ui/core/InputLabel';

interface Props extends WithTranslation {
    value: string | null;
    onValueChanged: (string: string | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
}

class BreakEarliestTimeInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled } = this.props;
        const parsedDate: Date = value ? parse(value, 'HH:mm', new Date()) : new Date(NaN);
        const dateValue: Date | null = isNaN(Number(parsedDate)) ? null : parsedDate;

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin="dense" error={error !== undefined}>
                        <InputLabel shrink>
                            {t('routing_settings.dialog.tabs.users.form_control.earliest_time.label')}
                        </InputLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                error={!!error}
                                disabled={disabled}
                                margin="normal"
                                autoOk={true}
                                value={dateValue}
                                onChange={this.handleChange}
                                clearable={true}
                            />
                        </MuiPickersUtilsProvider>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 16 }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.break_earliest_time.hint')}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private handleChange = (value: MaterialUiPickersDate) => {
        const { onValueChanged } = this.props;
        const formattedValue = value ? format(value, 'HH:mm') : null;
        onValueChanged(formattedValue).then(() => this.validate(formattedValue));
    };

    // @ts-ignore unused variable
    private validate = (value: string | null): Promise<void> => {
        const { onInputValidation } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };
}

export default withTranslation()(BreakEarliestTimeInput);
