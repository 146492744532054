import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    ListSubheader,
    InputLabel,
    Box,
    Tooltip,
    MenuItem,
    TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../locales/i18n';
import {
    DETAIL_CUSTOM,
    DETAIL_FIELD,
    DETAIL_FIELD_ID,
    DETAIL_FIELDS,
    DETAIL_FORMULA,
    getDetail,
    workflowActionManager,
} from '../../../service/WorkflowActionManager';
import { SortableContainer } from 'react-sortable-hoc';
import './../style.css';
import arrayMove from 'array-move';
import SortableField from './SortableField';
import emFactory from '../../../service/EntityManager';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { CallContext } from '../../utils/CallContext';
import { entityManager } from '../../../service/SimpleEntityManager';
import {
    isFewLookup,
    isLookupFieldId,
    isMultipleLookupField,
    isSafeApiName,
    isSimpleField,
    isReadonlyField,
    isActiveEntity,
    getActiveOrInactiveFieldByApiName,
} from '../../../utils';
import AddIconButton from '../../CustomButton/AddIconButton';

const t = i18n.t.bind(i18n);

const SortableList = SortableContainer((props) => {
    return (
        <div>
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={5} style={{ position: 'relative' }}>
                    <InputLabel shrink={true} style={{ marginLeft: 36 }}>
                        {t('workflow_actions.form.field_update.field')}
                    </InputLabel>
                </Grid>
                <Grid item xs style={{ position: 'relative' }}>
                    <InputLabel shrink={true} style={{ marginLeft: 8 }}>
                        {t('workflow_actions.form.field_update.target_value')}
                    </InputLabel>
                </Grid>
            </Grid>
            {props.items.map((value, index) => (
                <SortableField
                    key={`item-${index}`}
                    index={index}
                    fieldIndex={index}
                    options={props.options}
                    selectedFields={props.selectedFields}
                    details={value}
                    loading={props.loading}
                    onFieldChange={props.onFieldChange}
                    onFormulaChange={props.onFormulaChange}
                    onRemove={props.onRemove}
                    callContext={props.callContext}
                    entities={props.entities}
                    onEntityFetch={props.onEntityFetch}
                    errors={props.errors}
                    accountId={props.accountId}
                    dataSourceId={props.dataSourceId}
                />
            ))}
        </div>
    );
});

class Form extends React.PureComponent {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.currentDataSourceId = props.action.dsId;

        const selectedFields = this.getSelectedFields(getDetail(DETAIL_FIELDS, props.action.details, []));

        this.state = {
            errors: new Map(),
            currentAction: cloneDeep(props.action),
            editCriteria: false,
            loading: false,
            entities: [],
            options: [],
            selectedFields,
        };
    }

    componentDidMount() {
        if (this.state.currentAction && this.state.currentAction.callContext.isValid()) {
            this.initEntities(this.state.currentAction.callContext.entities);
        }
    }

    getEntityManager = (dataSourceId) => {
        return emFactory.getManager(this.props.accountId, dataSourceId);
    };

    getEntityByApiName = (entityApiName, dsId) => {
        return this.getEntityManager(dsId)
            .getEntities()
            .then((entities) => {
                return entities.find((entity) => entity.name === entityApiName);
            });
    };

    initEntities = (entityIds) => {
        if (!(Array.isArray(entityIds) && entityIds.length)) {
            return;
        }

        this.setState({ loading: true });

        const promises = entityIds.map((entityId) =>
            entityManager.get(entityId, true, true).then((entity) => {
                const fields = entity.fields.filter((field) => {
                    if (isSimpleField(field)) {
                        return true;
                    }
                    // TODO Не умеем работать с multiple лукапами.
                    if (isMultipleLookupField(field)) {
                        return false;
                    }
                    return isFewLookup(field) || isLookupFieldId(field);
                });
                return { ...entity, fields };
            }),
        );
        Promise.all(promises).then((entities) => {
            const options = this.prepareOptions(entities);
            this.setState({ entities, options, loading: false });
        });
    };

    prepareOptions = (entities) => {
        const callContext = this.state.currentAction.callContext;

        let options = [
            {
                type: 'custom',
                label: t('workflow_actions.form.field_update.custom'),
                value: 'custom',
            },
        ];

        for (const variable of callContext.vars) {
            const entity = entities.find((entity) => entity.id === variable.entity);
            if (!entity) {
                continue;
            }

            const fieldOptions = [];
            const lookupFieldOptions = [];
            const entityType = variable.type;
            const groupBy = `${callContext.getPrefixLabelByType(entityType)} (${entity.label})`;

            for (const field of entity.fields) {
                if (this.isLookup(field)) {
                    // TODO Не умеем работать с multiple лукапами.
                    if (isMultipleLookupField(field)) {
                        continue;
                    }
                    if (!isFewLookup(field) && !isLookupFieldId(field)) {
                        continue;
                    }

                    lookupFieldOptions.push({
                        type: 'lookup',
                        label: field.label,
                        value: field.id,
                        groupBy,
                        field,
                        dsId: entity.dataSource.id,
                        entityType,
                    });
                } else {
                    fieldOptions.push({
                        type: 'field',
                        label: field.label,
                        value: field.id,
                        groupBy,
                        field,
                        dsId: entity.dataSource.id,
                        entityType,
                    });
                }
            }
            options = [
                ...options,
                ...lookupFieldOptions.sort((a, b) => a.label.localeCompare(b.label)),
                ...fieldOptions.sort((a, b) => a.label.localeCompare(b.label)),
            ];
        }

        return options;
    };

    isLookup = (field) => {
        return !!(field && field.lookupData && field.lookupData.type && field.lookupData.apiName);
    };

    submit() {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then((action) => {
                return workflowActionManager.save(this.props.accountId, action);
            })
            .then((action) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(action);
            })
            .catch((errors) => {
                this.setState({
                    errors: errors.details || errors,
                });
                this.props.onSubmitError && this.props.onSubmitError(t('validation_errors'));
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, entities } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValid()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            for (const entity of entities) {
                if (!isActiveEntity(entity)) {
                    errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
                    break;
                }
            }
            const fields = getDetail(DETAIL_FIELDS, currentAction.details, []);
            if (fields.length === 0) {
                errors.set(DETAIL_FIELDS, t('errors.not_empty'));
            }

            for (const field of fields) {
                if (field[DETAIL_CUSTOM]) {
                    continue;
                }
                if (!field[DETAIL_FIELD]) {
                    errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.empty_error'));
                    break;
                }
                const [type, apiName] = field[DETAIL_FIELD].split('.');
                const entity = this.state.entities.find((entity) => {
                    return currentAction.callContext.getEntityId(type) === entity.id;
                });
                if (entity) {
                    const fieldId = field[DETAIL_FIELD_ID] ?? null;
                    let entityField;
                    if (fieldId) {
                        entityField = entity.fields.find((field) => {
                            return field.id === fieldId;
                        });
                    } else {
                        entityField = getActiveOrInactiveFieldByApiName(entity, apiName);
                    }
                    if (entityField && entityField.isRequired && !field[DETAIL_FORMULA]) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.empty_error'));
                        break;
                    }
                    if (entityField && isReadonlyField(entityField)) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.readonly_error'));
                        break;
                    }
                    if (entityField?.isDeleted || entityField?.isIncluded === false) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.deactivated_error'));
                        break;
                    }
                }
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    handleEntityChange = (event) => {
        const entityId = event.target.value;
        if (entityId) {
            this.initEntities([entityId]);
        }
        this.updateActionState(event.target.name, CallContext.create(entityId));
    };

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (name === 'apiName' && !isSafeApiName(value)) {
            return;
        }
        this.updateActionState(name, value);
    };

    updateActionState = (name, value) => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                currentAction[name] = value;
                let errors = state.errors;
                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                }
                if (errors.size === 0) {
                    this.props.onSubmitError(null);
                }
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    handleDetailsChange = (event) => {
        this.updateDetails(event.target.name, event.target.value);
    };

    handleField = (fieldId, value, index, isCustom) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details);
        fields[index] = {
            ...fields[index],
            [DETAIL_FIELD_ID]: fieldId,
            [DETAIL_FIELD]: value,
            [DETAIL_CUSTOM]: isCustom,
        };
        this.updateDetails(DETAIL_FIELDS, fields);

        const selectedFields = this.getSelectedFields(fields);
        this.setState({ selectedFields });
    };

    handleFormula = (value, index) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details);
        fields[index] = {
            ...fields[index],
            [DETAIL_FORMULA]: value,
        };
        this.updateDetails(DETAIL_FIELDS, fields);
    };

    updateDetails = (name, value) => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (!currentAction.details) {
                    currentAction.details = {};
                }
                currentAction.details[name] = value;
                let errors = state.errors;
                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                }
                if (errors.size === 0) {
                    this.props.onSubmitError(null);
                }
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    onAddDetail = () => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (!currentAction.details || !Array.isArray(currentAction.details[DETAIL_FIELDS])) {
                    currentAction.details = { DETAIL_FIELDS: [] };
                }
                currentAction.details[DETAIL_FIELDS].push({});
                let errors = state.errors;
                if (errors.has(DETAIL_FIELDS)) {
                    errors = new Map(errors);
                    errors.delete(DETAIL_FIELDS);
                    if (errors.size === 0) {
                        this.props.onSubmitError(null);
                    }
                }
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    onRemoveDetail = (index) => {
        const details = this.state.currentAction.details;
        if (details && Array.isArray(details[DETAIL_FIELDS]) && details[DETAIL_FIELDS].length > 0) {
            this.setState(
                (state) => {
                    const currentAction = cloneDeep(state.currentAction);
                    const fields = currentAction.details[DETAIL_FIELDS].filter((detail, i) => i !== index);
                    currentAction.details[DETAIL_FIELDS] = fields;
                    const selectedFields = this.getSelectedFields(fields);
                    return { currentAction, selectedFields };
                },
                () => {
                    this.props.onModified && this.props.onModified(true);
                },
            );
        }
    };

    getSelectedFields = (fields) => {
        return new Set(
            fields
                .map((field) => {
                    const [entityType] = (field[DETAIL_FIELD] ?? '').split('.');
                    return entityType + field[DETAIL_FIELD_ID];
                })
                .filter(Boolean),
        );
    };

    prepareEntityOptions = () => {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem
                        key={entity.id}
                        value={entity.id}
                        onClick={() => (this.currentDataSourceId = dataSource.id)}
                    >
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    };

    hasInvalidEntity = () => {
        for (let entity of this.state.entities) {
            if (entity.deletedAt || !entity.isIncluded || entity.dataSource?.deletedAt) {
                return true;
            }
        }
        return false;
    };

    renderEntityValue = () => {
        const { entities, currentAction } = this.state;
        if (!entities) {
            return t('undefined_picklist_option');
        }

        const callContext = currentAction.callContext;
        const multipleEntities = callContext.vars.length > 1;
        return (
            <span>
                {callContext.vars.map((variable, index) => {
                    const entity = entities.find((entity) => entity.id === variable.entity);
                    if (!entity) {
                        return null;
                    }

                    let label;
                    if (multipleEntities) {
                        const prefix = callContext.getPrefixLabelByType(variable.type);
                        label = `${prefix}: ${entity.dataSource.name} > ${entity.label}`;
                    } else {
                        label = `${entity.dataSource.name} > ${entity.label}`;
                    }

                    let error;
                    if (entity.dataSource?.deletedAt) {
                        error = t('automation_elements.inactive_data_source');
                    } else if (entity.deletedAt || entity.isIncluded === false) {
                        error = t('automation_elements.inactive_entity');
                    }

                    let component = label;
                    if (error) {
                        component = (
                            <Box color="error.main" component="span">
                                <Tooltip title={error}>
                                    <i style={{ marginRight: 3 }} className="fas fa-circle-exclamation" />
                                </Tooltip>
                                {label}
                            </Box>
                        );
                    }

                    return (
                        <React.Fragment key={index}>
                            {index > 0 && index < entities.length && ', '}
                            {component}
                        </React.Fragment>
                    );
                })}
            </span>
        );
    };

    renderEntitySelect = (params = {}) => {
        const error = this.hasInvalidEntity();
        return (
            <FormControlSelect
                fullWidth
                margin="dense"
                label={t('workflow_actions.form.field_update.entity')}
                name="callContext"
                value={this.state.currentAction.callContext.entities}
                onChange={this.handleEntityChange}
                renderValue={this.renderEntityValue}
                error={error || this.state.errors.has('callContext')}
                helperText={this.state.errors.get('callContext')}
                {...params}
                data-testid="workflow_actions.form.field_update.entity"
            >
                {this.prepareEntityOptions()}
            </FormControlSelect>
        );
    };

    onSortFieldsEnd = ({ oldIndex, newIndex }) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details, []);
        const sortedFields = arrayMove([...fields], oldIndex, newIndex);
        this.updateDetails(DETAIL_FIELDS, sortedFields);
    };

    render() {
        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.field_update.entity.modal.title')}
                    onClose={this.props.onCancel}
                    open={!this.state.currentAction.callContext.isValid()}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect()}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off" ref={this.ref}>
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.field_update.name')}
                        data-testid="workflow_actions.form.field_update.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={this.state.currentAction.name || ''}
                        helperText={this.state.errors.get('name') || ''}
                        error={this.state.errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.field_update.api_name')}
                        data-testid="workflow_actions.form.field_update.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={this.state.currentAction.apiName || ''}
                        helperText={this.state.errors.get('apiName') || ''}
                        error={this.state.errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect({ disabled: true, multiple: true })}

                    <FormControl fullWidth error={this.state.errors.has(DETAIL_FIELDS)}>
                        <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                            <Grid item>
                                <b>{t('workflow_actions.form.field_update.fields')}</b>
                            </Grid>
                            <Grid item>
                                <AddIconButton small onClick={this.onAddDetail} />
                            </Grid>
                        </Grid>
                        {this.ref.current && this.state.loading === false && (
                            <SortableList
                                items={getDetail(DETAIL_FIELDS, this.state.currentAction.details, [])}
                                options={this.state.options}
                                selectedFields={this.state.selectedFields}
                                onSortEnd={this.onSortFieldsEnd}
                                useDragHandle
                                loading={this.state.loading}
                                onFieldChange={this.handleField}
                                onFormulaChange={this.handleFormula}
                                onRemove={this.onRemoveDetail}
                                callContext={this.state.currentAction.callContext}
                                entities={this.state.entities}
                                onEntityFetch={this.getEntityByApiName}
                                errors={this.state.errors}
                                accountId={this.props.accountId}
                                dataSourceId={this.currentDataSourceId}
                                helperClass="sortable-item"
                                getContainer={() => this.ref.current.closest('.c-form-dialog__content')}
                            />
                        )}
                        {this.state.errors.get(DETAIL_FIELDS) && (
                            <FormHelperText>{this.state.errors.get(DETAIL_FIELDS)}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="reevaluate"
                                checked={this.state.currentAction.reevaluate}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.form.field_update.reevaluate')}
                    />
                </form>
            </React.Fragment>
        );
    }
}

Form.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export { Form as FieldUpdateForm };
