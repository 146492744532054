import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class LiveUpdateJobManager extends BackendService {
    getLiveUpdateJobs(accountId, dsId, filters = [], sorting = [], page, limit) {
        const url = reverse(apiRoutes.account.dataSource.liveUpdateJob, { accountId, dsId });
        return this.requestApi(url, 'GET', { filters: filters, sorting: sorting, page: page, limit: limit });
    }

    stopLiveUpdateJob(accountId, dsId, jobId) {
        const url = reverse(apiRoutes.account.dataSource.liveUpdateJobStop, { accountId, dsId, jobId });
        return this.requestApi(url, 'POST');
    }
}

export const liveUpdateJobManager = new LiveUpdateJobManager();
