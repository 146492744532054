import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TableColumnName, UsersTravelingPreferencesDialogTableFilter } from './index';
import { USER_ROUTING_PREFERENCES_FIELD_LIST, UserDataRoutePreferences } from 'service/types';
import { User } from 'interfaces';
import { VehicleTypes } from 'interfaces/routing/settings';
import FilterCell from './FilterCell';
import PicklistFilterCell from './PicklistFilterCell';

interface Props {
    users: User.User[];
    vehicleTypes: VehicleTypes;
    filter: UsersTravelingPreferencesDialogTableFilter;
    clientHeight: number;
    handleFilterChange: (fieldName: TableColumnName, value: any) => void;
}

type FieldParams = {
    style?: object;
    convertToNumber?: boolean;
    options?: string[];
    renderValue?: (selected: string[]) => string;
};

interface State {
    fieldsFilterParams: {
        [key: string]: FieldParams;
    };
}

class UsersTravelingPreferencesTableFilters extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fieldsFilterParams: {},
        };
    }

    componentDidMount() {
        this.setFieldsParams();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.users !== this.props.users || prevProps.vehicleTypes !== this.props.vehicleTypes) {
            this.setFieldsParams();
        }
    }

    setFieldsParams = () => {
        const { users, vehicleTypes } = this.props;

        const usersVehicleTypes: string[] = users
            .map((user) => user.routingPreferences.vehicleType || '')
            .filter((value, index, self) => value !== '' && self.indexOf(value) === index);

        const filteredVehicleTypes = Array.from(vehicleTypes).filter(([key]) => usersVehicleTypes.includes(key));

        const usersSkills: string[] = users.reduce((skills, user) => {
            (user.routingPreferences.hasSkills || []).forEach((skill) => {
                if (skills.indexOf(skill) === -1) {
                    skills.push(skill);
                }
            });
            return skills;
        }, [] as string[]);

        const fieldsFilterParams = {
            weekTimes: {
                style: { minWidth: 250 },
            },
            startPoint: {
                style: { minWidth: 200 },
            },
            endPoint: {
                style: { minWidth: 200 },
            },
            defaultDuration: {
                convertToNumber: true,
            },
            departureDelay: {
                convertToNumber: true,
            },
            breakType: {
                style: { minWidth: 200 },
            },
            breakDuration: {
                convertToNumber: true,
            },
            vehicleType: {
                options: filteredVehicleTypes.map(([id]) => id),
                renderValue: (selected: string[]) =>
                    filteredVehicleTypes
                        .filter(([id]) => selected.includes(id))
                        .map(([, type]) => type.name)
                        .join(', '),
                style: { minWidth: 200 },
            },
            hasSkills: {
                options: usersSkills,
            },
            maxDistance: {
                convertToNumber: true,
            },
            maxDrivingTime: {
                convertToNumber: true,
            },
            maxActivities: {
                convertToNumber: true,
            },
            minJobs: {
                convertToNumber: true,
            },
            maxJobs: {
                convertToNumber: true,
            },
        };

        this.setState({ fieldsFilterParams });
    };

    convertStringFilterToNumber = (filter: string): null | Number => {
        if (filter === '') {
            return null;
        }
        return Number(filter);
    };

    onHandleFilterChange =
        (fieldName: keyof UserDataRoutePreferences, params: FieldParams) =>
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const { handleFilterChange } = this.props;

            handleFilterChange(
                fieldName,
                params?.convertToNumber ? this.convertStringFilterToNumber(event.target.value) : event.target.value,
            );
        };

    render() {
        const {
            props: { filter, clientHeight, handleFilterChange },
            state: { fieldsFilterParams },
        } = this;

        return (
            <>
                <FilterCell
                    value={filter.has('user') ? filter.get('user') : ''}
                    clientHeight={clientHeight}
                    onChange={(event) => handleFilterChange('user', event.target.value)}
                />
                {USER_ROUTING_PREFERENCES_FIELD_LIST.map((fieldName) => {
                    const params = fieldsFilterParams?.[fieldName];

                    const props = {
                        value: filter.has(fieldName) ? filter.get(fieldName) : params?.options ? [] : '',
                        clientHeight,
                        onChange: this.onHandleFilterChange(fieldName, params),
                        style: params?.style,
                    };

                    if (params?.options) {
                        return (
                            <PicklistFilterCell
                                key={fieldName}
                                {...props}
                                options={params.options.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                                renderValue={params?.renderValue || ((selected: string[]) => selected.join(', '))}
                            />
                        );
                    }
                    return <FilterCell key={fieldName} {...props} />;
                })}
            </>
        );
    }
}

export default UsersTravelingPreferencesTableFilters;
