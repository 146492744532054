import { LatLngBounds } from 'leaflet';

export interface Bounds {
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
}

export const FixBounds = (bounds: Bounds): Bounds => ({
    minLat: Math.min(bounds.minLat, bounds.maxLat),
    maxLat: Math.max(bounds.minLat, bounds.maxLat),
    minLng: Math.min(bounds.minLng, bounds.maxLng),
    maxLng: Math.max(bounds.minLng, bounds.maxLng),
});

export const FormatLeafletBounds = (bounds: LatLngBounds): Bounds => {
    const minLat = bounds.getSouth();
    const maxLat = bounds.getNorth();
    let minLng = bounds.getWest();
    let maxLng = bounds.getEast();

    return { minLat, maxLat, minLng, maxLng };
};

export const FormatToLeafletBounds = ({ minLat, minLng, maxLat, maxLng }: Bounds): LatLngBounds =>
    new LatLngBounds([minLat, minLng], [maxLat, maxLng]);

const MAX_LAT = 89.999999;
const MAX_LNG = 180;

export const ExpandMapBounds = (mapBounds: Bounds, expandCoefficient: number): Bounds => {
    return {
        minLat: Math.max(mapBounds.minLat - (mapBounds.maxLat - mapBounds.minLat) * expandCoefficient, -MAX_LAT),
        maxLat: Math.min(mapBounds.maxLat + (mapBounds.maxLat - mapBounds.minLat) * expandCoefficient, MAX_LAT),
        minLng: Math.max(mapBounds.minLng - (mapBounds.maxLng - mapBounds.minLng) * expandCoefficient, -MAX_LNG),
        maxLng: Math.min(mapBounds.maxLng + (mapBounds.maxLng - mapBounds.minLng) * expandCoefficient, MAX_LNG),
    };
};
