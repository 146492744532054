import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';
import { FormActions } from '../PureFormDialog/Form';
import PureFormDialog from '../PureFormDialog';
import { getFileExtension, getFileNameWithNoExtension } from '../../service/File/FileManager';
import validator from 'validator';

interface CurrentProps extends WithTranslation {
    open: boolean;
    value: string;
    onSave: (fileName: string) => void;
    onCancel: () => void;
}

interface CurrentState {
    value: string;
    extension: string;
    error: boolean;
}

class FileNameModal extends React.Component<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            value: getFileNameWithNoExtension(props.value),
            extension: getFileExtension(props.value),
            error: false,
        };
    }

    handleInput = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value || '';

        if (validator.isEmpty(value)) {
            this.setState({ error: true, value });
        } else {
            this.setState({ error: false, value });
        }
    };

    handleSave = () => {
        if (this.state.error) {
            return;
        }
        this.props.onSave(this.state.value + '.' + this.state.extension);
    };

    render() {
        return (
            <PureFormDialog
                open={this.props.open}
                title={this.props.t<string>('file_input.file_name_modal.file_name')}
                onClose={this.props.onCancel}
                maxWidth="sm"
                fullWidth
            >
                <form noValidate>
                    <TextField
                        label={this.props.t('file_input.file_name_modal.file_name')}
                        fullWidth
                        autoFocus
                        margin="dense"
                        name="fileName"
                        error={this.state.error}
                        helperText={this.state.error && this.props.t('errors.not_empty')}
                        data-testid="file_input.file_name_modal.filename"
                        value={this.state.value}
                        onChange={this.handleInput}
                    />
                </form>

                <FormActions>
                    <Button color="primary" data-testid="file_input.file_name_modal.save" onClick={this.handleSave}>
                        {this.props.t('button.save')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

export default withTranslation()(FileNameModal);
