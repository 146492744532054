import BackendService from '../api/BackendService';
import apiRoutes, { reverse } from '../api/apiRoutes';

class AiTokenUsageManager extends BackendService {
    getList(accountId: number) {
        const url = reverse(apiRoutes.account.aiTokenUsages.list, { accountId });

        return this.requestApi(url, 'GET');
    }
}

export const aiTokenUsageManager = new AiTokenUsageManager();
