import React, { Suspense } from 'react';
import './style.css';
import { withRouter } from 'react-router';
import { userManager } from '../../service/UserManager';
import LoadingButton from '../LoadingButton';
import PropTypes from 'prop-types';
import { routes } from '../../routes';
import Typography from '@material-ui/core/Typography';
import { FormLabel, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { FormActions, FormAlert, FormBackdrop } from '../PureFormDialog/Form';
import { DialogContext } from '../PureFormDialog/DialogContext';

const ChangePasswordForm = React.lazy(() => import('../ChangePasswordForm'));

class ResetPassword extends React.PureComponent {
    render() {
        const { token, classes } = this.props;
        return (
            <div className="c-reset-password">
                <DialogContext.Provider
                    value={{
                        styles: {
                            actions: classes.actions,
                            alert: classes.alert,
                        },
                    }}
                >
                    <ResetPasswordForm token={token} />
                </DialogContext.Provider>
            </div>
        );
    }
}

export default withStyles({
    actions: {
        margin: '0 -24px',
        padding: '16px 16px 0 16px',
        textAlign: 'right',
    },
    alert: {
        margin: '0 -24px',
        '& > div': {
            padding: 24,
        },
    },
})(ResetPassword);

ResetPassword.propTypes = {
    token: PropTypes.string.isRequired,
};

class BaseResetPasswordForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            loading: false,
            componentLoaded: false,
            errors: new Map(),
            user: {
                id: null,
                email: '',
                name: '',
            },
        };

        this.changePasswordForm = React.createRef();
    }

    componentDidMount() {
        userManager
            .validResetToken(this.props.token)
            .then(({ id, email, name }) => {
                this.setState({
                    componentLoaded: true,
                    user: {
                        id,
                        email,
                        name,
                    },
                });
            })
            .catch(() => {
                this.props.history.push(routes.login + '?reset_token_invalid');
            });
    }

    submit = async () => {
        this.setState({
            errors: new Map(),
            loading: true,
        });

        try {
            await this.changePasswordForm.current.validate();
        } catch (e) {
            this.setState({
                loading: false,
            });
            return;
        }

        const password = this.changePasswordForm.current.getPassword();
        const needToEndUserSessions = this.changePasswordForm.current.getNeedToEndUserSessions();

        try {
            await userManager.resetPassword(password, this.props.token);
        } catch (error) {
            let message = null;
            if (error.message) {
                message = {
                    type: 'danger',
                    text: error.message,
                };
            }
            this.setState(({ errors }) => ({
                loading: false,
                message,
                errors: error.details ?? errors,
            }));

            return;
        }

        this.setState({
            loading: false,
        });

        const result = await userManager.hey();

        if (needToEndUserSessions) {
            await userManager.logoutUser(result.user.id);
        }

        return result;
    };

    handleCloseAlert = () => {
        this.setState({
            errors: new Map(),
            message: null,
        });
    };

    handleReadyStateChanged = () => {
        this.forceUpdate();
    };

    render() {
        const { t } = this.props;

        const { errors, message, user } = this.state;

        return (
            <div>
                <Typography variant="h6" style={{ marginBottom: 18 }}>
                    {t('reset_password.form.header')}
                </Typography>
                {message && (
                    <FormAlert onClose={this.handleCloseAlert} type={message.type}>
                        {message.text}
                    </FormAlert>
                )}
                <FormBackdrop loading={!this.state.componentLoaded || this.state.loading}>
                    <FormLabel>{t('reset_password.form.label')}</FormLabel>
                    <Suspense fallback={'Loading...'}>
                        <ChangePasswordForm
                            ref={this.changePasswordForm}
                            onReadyStateChanged={this.handleReadyStateChanged}
                            errors={errors}
                            user={user}
                        />
                    </Suspense>
                </FormBackdrop>
                <FormActions>
                    <LoadingButton
                        size="medium"
                        color="primary"
                        onClick={this.submit}
                        loading={this.state.loading}
                        disabled={!this.changePasswordForm.current || !this.changePasswordForm.current.isCompleted()}
                        data-testid="reset_password.form.submit"
                    >
                        {t('reset_password.form.submit')}
                    </LoadingButton>
                </FormActions>
            </div>
        );
    }
}

const ResetPasswordForm = withTranslation('translations', { withRef: true })(withRouter(BaseResetPasswordForm));
