import React, { CSSProperties, MouseEventHandler } from 'react';
import { Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

interface LookupTableRecordsFormProps<T> extends WithTranslation {
    records: T[];
    onAdd: MouseEventHandler<HTMLElement>;
    onDelete: MouseEventHandler<HTMLElement>;
    onCancel: MouseEventHandler<HTMLElement>;
    sourceField: string;
    searching: boolean;
    toAdd: any[];
    toDelete: any[];
}

class LookupTableRecordsForm<T extends Record<string, any>> extends React.PureComponent<
    LookupTableRecordsFormProps<T>
> {
    render() {
        const { t, records, toAdd, toDelete } = this.props;

        return (
            <Table className="c-live-search-form__results">
                <TableBody>
                    {records.map((record, _index) => {
                        const id = record.id;
                        const title = record.objectName;
                        const enqueueAdd = !!toAdd.find((r) => r.id === id);
                        const enqueueRemove = !!toDelete.find((r) => r.id === id);
                        const style: CSSProperties = {};
                        const rowStyle: CSSProperties = {};
                        if (enqueueRemove) {
                            style.textDecoration = 'line-through';
                        }
                        if (enqueueAdd && !enqueueRemove) {
                            rowStyle.backgroundColor = '#e7ffe7';
                        }

                        const add = (
                            <Tooltip title={t('live_update_table_form.lookup_table_records_form.add.tooltip')}>
                                <i
                                    className="fas fa-plus"
                                    onClick={this.props.onAdd}
                                    data-record-id={id}
                                    data-record-title={title}
                                />
                            </Tooltip>
                        );
                        const remove = (
                            <Tooltip title={t('live_update_table_form.lookup_table_records_form.delete.tooltip')}>
                                <i
                                    className="fas fa-trash"
                                    onClick={this.props.onDelete}
                                    data-record-id={id}
                                    data-record-title={title}
                                />
                            </Tooltip>
                        );
                        const cancel = (
                            <Tooltip title={t('live_update_table_form.lookup_table_records_form.cancel.tooltip')}>
                                <i
                                    className="fas fa-times"
                                    onClick={this.props.onCancel}
                                    data-record-id={id}
                                    data-record-title={title}
                                />
                            </Tooltip>
                        );

                        return (
                            <TableRow key={id} hover style={rowStyle}>
                                <TableCell align="left">
                                    {enqueueAdd && (
                                        <i className="fa fa-plus" style={{ marginLeft: '2px', marginRight: '8px' }} />
                                    )}
                                    {enqueueRemove && (
                                        <i className="fa fa-minus" style={{ marginLeft: '2px', marginRight: '8px' }} />
                                    )}
                                    <span style={style}>{title}</span>
                                </TableCell>
                                <TableCell align="right" style={{ width: '40px' }}>
                                    {!enqueueAdd && !enqueueRemove && add}
                                    {!enqueueAdd && !enqueueRemove && remove}
                                    {enqueueAdd && remove}
                                    {enqueueRemove && add}
                                </TableCell>
                                <TableCell align="right" style={{ width: '20px' }}>
                                    {enqueueAdd || enqueueRemove ? cancel : null}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LookupTableRecordsForm);
