import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class LeadsManager extends BackendService {
    list(filters, sorting, offset, limit, type, state) {
        const url = reverse(apiRoutes.leads);
        return this.requestApi(url, 'GET', { filters, sorting, offset, limit }).then((response) => {
            return response;
        });
    }
}

export const leadsManager = new LeadsManager();
