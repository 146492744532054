import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';

import events from '../../../events';
import { Calendar } from '../../../interfaces/calendar/calendar';
import { User } from '../../../interfaces/user';
import { CalendarEventManager } from '../../../service/Calendar/CalendarEventManager';
import { CalendarEvent } from '../../../service/Calendar/CalendarEventRepository';
import { userManager } from '../../../service/UserManager';
import dispatcher from '../../../service/dispatcher';
import OneDayExceptionDialog from '../../UsersWorkingHourExceptionsDialog/OneDayExceptionDialog';
import { shouldOpenOneDayExceptionDialog } from '../../UsersWorkingHourExceptionsDialog/helper';
import { validateForm, validateOne } from '../Forms/CalendarFormValidator';

import CalendarSaveDialog from './CalendarSaveDialog';
import QuickInfoPopup from './QuickInfoPopup';
import dsManagerFactory from '../../../service/DsManager';
import { DataSource } from '../../../interfaces';
import { AdapterId } from '../../types';
import GoogleCalendarNotConnectedPopup from './GoogleCalendarNotConnectedPopup';
import googleCalendarNotConnectedPopupManager from '../../../service/Calendar/GoogleCalendarNotConnectedPopupManager';
import { getCalendarDataSource } from '../Helpers/CalendarHelper';

interface Props extends WithTranslation {
    calendarEventManager: CalendarEventManager;
    onClose: () => void;
    calendarEvent: CalendarEvent | null;
    calendar: Calendar | null;
    anchorEl?: Element;
    preferFullForm?: boolean;
    usePopover?: boolean;
}

interface State {
    calendarEvent: CalendarEvent | null;
    errors: Map<string, string>;
    forceFullForm: boolean;
    loading: boolean;
    oneDayExceptionDialogData?: {
        date: Date;
        user: User;
    };
    calendarDataSource?: DataSource.DataSource;
    googleCalendarPopupUser?: User;
}

class CalendarFormPopups extends React.Component<Props, State> {
    private calendarEventManager: CalendarEventManager = new CalendarEventManager();

    constructor(props: Props) {
        super(props);

        this.state = {
            calendarEvent: props.calendarEvent ? cloneDeep(props.calendarEvent) : null,
            errors: new Map<string, string>(),
            forceFullForm: false,
            loading: false,
            oneDayExceptionDialogData: undefined,
        };
    }
    componentDidMount = async (): Promise<void> => {
        dispatcher.subscribe(events.CALENDAR_EVENTS_LIST_UPDATED, this, (data: { manager: CalendarEventManager }) => {
            if (data.manager !== this.calendarEventManager) {
                return;
            }

            this.setState({ loading: false });
        });
        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendarData();
        });
        this.updateCalendarData();

        const { calendarEvent } = this.state;
        if (!calendarEvent) {
            return;
        }

        if (!this.shouldUseOwnCalendarEventManager(calendarEvent.startDatetime, calendarEvent.ownerId)) {
            return;
        }

        const startDate = new Date(calendarEvent.startDatetime);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(calendarEvent.endDatetime);
        endDate.setHours(23, 59, 59, 999);
        this.calendarEventManager.requestGetEvents([calendarEvent.ownerId], startDate, endDate);
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.calendar !== this.props.calendar) {
            this.updateCalendarData();
        }
    }

    componentWillUnmount = (): void => {
        this.calendarEventManager.dispose();
        dispatcher.unsubscribeFromAllEvents(this);
    };

    private updateCalendarData = async (): Promise<void> => {
        const calendar = this.props.calendar;
        if (!calendar) {
            return;
        }

        const ds = await getCalendarDataSource(calendar);

        this.setState({ calendarDataSource: ds });
    };

    private openGoogleCalendarPopupIfNeccessary = async (): Promise<void> => {
        const { calendarDataSource, calendarEvent } = this.state;
        if (!calendarDataSource || !calendarEvent || calendarDataSource.adapterId !== AdapterId.GOOGLECALENDAR) {
            this.onClose();
            return;
        }

        const shouldShow = googleCalendarNotConnectedPopupManager.shouldShowWarning();
        if (!shouldShow) {
            this.onClose();
            return;
        }

        const userCredentials = await dsManagerFactory
            .getManager(userManager.getCurrentAccount().id)
            .userCredentials(calendarDataSource.id);
        if (userCredentials?.calendarId) {
            this.onClose();
            return;
        }

        const user = await userManager.getAccountUser(userManager.getCurrentAccount().id, calendarEvent.ownerId);
        if (!user) {
            this.onClose();
            return;
        }

        this.setState({ googleCalendarPopupUser: user });
    };

    private onClose = (): void => {
        this.props.onClose();
    };

    private onMoreDetailsClick = (): void => {
        this.setState({ forceFullForm: true });
        //TODO: change to opening full form or action form based on configs
    };

    private validateOne = async (fieldName: string): Promise<void> => {
        const { calendarEvent, errors } = this.state;
        if (!calendarEvent) {
            return;
        }

        const updatedErrors = await validateOne(fieldName, errors, calendarEvent);
        this.setState({ errors: new Map(updatedErrors) });
    };

    private clearError = (fieldName: string): void => {
        this.setState((state: State) => {
            const errors = new Map(state.errors);
            errors.delete(fieldName);

            return { ...state, errors };
        });
    };

    private updateCalendarEvent = (calendarEvent: CalendarEvent, validateFieldAfter?: string | null): void => {
        this.refreshEventsInOwnEventManagerIfNecessary(calendarEvent);

        this.setState({ calendarEvent }, () => {
            if (validateFieldAfter) {
                this.validateOne(validateFieldAfter);
            }
        });
    };

    private refreshEventsInOwnEventManagerIfNecessary = (updatedCalendarEvent: CalendarEvent): void => {
        if (
            this.state.calendarEvent?.startDatetime === updatedCalendarEvent.startDatetime &&
            this.state.calendarEvent?.endDatetime === updatedCalendarEvent.endDatetime &&
            this.state.calendarEvent?.ownerId === updatedCalendarEvent.ownerId
        ) {
            return;
        }

        if (!this.shouldUseOwnCalendarEventManager(updatedCalendarEvent.startDatetime, updatedCalendarEvent.ownerId)) {
            return;
        }

        const startDate = new Date(updatedCalendarEvent.startDatetime);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(updatedCalendarEvent.endDatetime);
        endDate.setHours(23, 59, 59, 999);
        this.setState({ loading: true }, () => {
            this.calendarEventManager.requestGetEvents([updatedCalendarEvent.ownerId], startDate, endDate);
        });
    };

    private shouldUseOwnCalendarEventManager = (calendarEventDatetime: string, ownerId: number): boolean => {
        const propsCalendarEventManager = this.props.calendarEventManager;
        if (
            !propsCalendarEventManager.getFrom() ||
            !propsCalendarEventManager.getTo ||
            !propsCalendarEventManager.getOwnersId().length
        ) {
            return true;
        }

        return (
            !moment(calendarEventDatetime).isBetween(
                propsCalendarEventManager.getFrom(),
                propsCalendarEventManager.getTo(),
                'day',
                '[]',
            ) || propsCalendarEventManager.getOwnersId().indexOf(ownerId) === -1
        );
    };

    private onSave = async (): Promise<void> => {
        if (!this.state.calendarEvent) {
            return;
        }

        const errors = await validateForm(this.state.errors, this.state.calendarEvent);
        if (errors.size) {
            this.setState({ errors: new Map(errors) });
            return;
        }

        if (
            this.props.calendarEvent &&
            !this.props.calendarEvent.new &&
            isEqual(this.props.calendarEvent, this.state.calendarEvent)
        ) {
            this.onClose();
            return;
        }

        const user = await userManager.getAccountUser(
            userManager.getCurrentAccount().id,
            this.state.calendarEvent.ownerId,
        );
        if (!user) {
            return;
        }

        const calendarEventManager = this.shouldUseOwnCalendarEventManager(
            this.state.calendarEvent.startDatetime,
            this.state.calendarEvent.ownerId,
        )
            ? this.calendarEventManager
            : this.props.calendarEventManager;

        if (await shouldOpenOneDayExceptionDialog([this.state.calendarEvent], calendarEventManager)) {
            this.openOneDayExceptionDialog(new Date(this.state.calendarEvent.startDatetime), user);
            return;
        }

        this.props.calendarEventManager.save([this.state.calendarEvent]);
        this.openGoogleCalendarPopupIfNeccessary();
    };

    private openOneDayExceptionDialog = (date: Date, user: User): void => {
        this.setState({ oneDayExceptionDialogData: { date, user } });
    };

    private closeOneDayExceptionDialog = (): void => {
        this.setState({ oneDayExceptionDialogData: undefined });

        if (!this.state.calendarEvent) {
            return;
        }

        this.props.calendarEventManager.save([this.state.calendarEvent]);
        this.openGoogleCalendarPopupIfNeccessary();
    };

    private onDelete = async (): Promise<void> => {
        if (!this.state.calendarEvent) {
            return;
        }

        this.onClose();
        await this.props.calendarEventManager.deleteMultiple([this.state.calendarEvent]);
        //TODO: handle errors
    };

    private setLoading = (loading: boolean): void => {
        this.setState({ loading });
    };

    render() {
        const { calendar, anchorEl, preferFullForm, usePopover } = this.props;
        const { calendarEvent, errors, forceFullForm, loading, oneDayExceptionDialogData, googleCalendarPopupUser } =
            this.state;

        return (
            <>
                {!preferFullForm && !forceFullForm && (
                    <QuickInfoPopup
                        anchorEl={anchorEl}
                        onClose={this.onClose}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        calendarEvent={calendarEvent}
                        calendar={calendar}
                        updateCalendarEvent={this.updateCalendarEvent}
                        onMoreDetailsClick={this.onMoreDetailsClick}
                        errors={errors}
                        validateOne={this.validateOne}
                        clearError={this.clearError}
                        setLoading={this.setLoading}
                        loading={loading}
                    />
                )}
                {(preferFullForm || forceFullForm) && (
                    <CalendarSaveDialog
                        anchorEl={anchorEl}
                        onClose={this.onClose}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        calendarEvent={calendarEvent}
                        calendar={calendar}
                        updateCalendarEvent={this.updateCalendarEvent}
                        errors={errors}
                        validateOne={this.validateOne}
                        clearError={this.clearError}
                        setLoading={this.setLoading}
                        loading={loading}
                        usePopover={usePopover}
                    />
                )}
                {!!oneDayExceptionDialogData && (
                    <OneDayExceptionDialog
                        onClose={this.closeOneDayExceptionDialog}
                        onSave={this.closeOneDayExceptionDialog}
                        user={oneDayExceptionDialogData.user}
                        date={oneDayExceptionDialogData.date}
                    />
                )}
                {!!googleCalendarPopupUser && (
                    <GoogleCalendarNotConnectedPopup onClose={this.onClose} user={googleCalendarPopupUser} />
                )}
            </>
        );
    }
}

export default withTranslation()(CalendarFormPopups);
