import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, Select, TextField, MenuItem, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PasswordField from '../../PasswordField';
import { FormActions, FormAlert, FormBackdrop } from '../../PureFormDialog/Form';
import { orgSettingsManager } from 'service/OrgSettings/OrgSettingsManager';
import { SmtpPort, SmtpCredentials } from '../../../interfaces/orgSettings';
import { enqueueSnackbarService } from '../../../service/MapPage';
import FormHelperText from '@material-ui/core/FormHelperText';

interface Props extends WithTranslation {
    accountId: number | string;
    onClose: () => void;
}

const EmailSettingsForm: React.FC<Props> = (props) => {
    const [credentials, setCredentials] = useState<Partial<SmtpCredentials>>({});
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string>();
    const [errors, setErrors] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        setLoading(true);
        orgSettingsManager
            .getSmtpCredentials(+props.accountId)
            .then((credentials) => {
                setCredentials(credentials || {});
            })
            .catch((err) => {
                enqueueSnackbarService.sendErrorMessage(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.accountId]);

    const validate = () => {
        const errors = new Map();
        if (!credentials.host) {
            errors.set('host', props.t('errors.not_empty'));
        }
        if (!credentials.port) {
            errors.set('port', props.t('errors.not_empty'));
        }
        if (!credentials.username) {
            errors.set('username', props.t('errors.not_empty'));
        }
        if (!credentials.password) {
            errors.set('password', props.t('errors.not_empty'));
        }
        setErrors(errors);

        return errors.size;
    };

    const handleSave = () => {
        if (!credentials.host && !credentials.username && !credentials.password) {
            orgSettingsManager
                .removeSmtpCredentials(+props.accountId)
                .then(() => {
                    props.onClose();
                })
                .catch((err) => {
                    setFormError(err.message);
                    setLoading(false);
                });
            return;
        }

        if (validate() > 0) {
            return;
        }

        setLoading(true);
        orgSettingsManager
            .saveSmtpCredentials(+props.accountId, credentials as SmtpCredentials)
            .then(() => {
                props.onClose();
            })
            .catch((err) => {
                setFormError(err.message);
                setLoading(false);
            });
    };

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        setCredentials({ ...credentials, [name!]: value });
        setFormError(undefined);

        if (errors.size) {
            setErrors(new Map());
        }
    };

    return (
        <FormBackdrop loading={loading}>
            <form noValidate autoComplete="off">
                <Alert severity="info">{props.t('email_settings.hint')}</Alert>
                {formError && (
                    <FormAlert onClose={setFormError.bind(this, undefined)} type="danger" style={{ marginTop: 10 }}>
                        {formError}
                    </FormAlert>
                )}

                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={9}>
                        <TextField
                            label={props.t('email_settings.host')}
                            name="host"
                            value={credentials.host || ''}
                            onChange={handleChange}
                            data-testid="email_settings.host"
                            fullWidth
                            error={errors.has('host')}
                            helperText={errors.get('host') || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth margin="dense" error={errors.has('port')}>
                            <InputLabel id="port-label">{props.t('email_settings.port')}</InputLabel>
                            <Select
                                labelId="port-label"
                                name="port"
                                value={credentials.port || ''}
                                onChange={handleChange}
                                data-testid="email_settings.port"
                            >
                                <MenuItem value={SmtpPort.SSL}>{props.t('email_settings.port.ssl')}</MenuItem>
                                <MenuItem value={SmtpPort.TLS}>{props.t('email_settings.port.tls')}</MenuItem>
                            </Select>
                            {errors.has('port') && <FormHelperText>{errors.get('port')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={props.t('email_settings.username')}
                            name="username"
                            value={credentials.username || ''}
                            onChange={handleChange}
                            data-testid="email_settings.username"
                            fullWidth
                            error={errors.has('username')}
                            helperText={errors.get('username') || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            label={props.t('email_settings.password')}
                            name="password"
                            value={credentials.password || ''}
                            onChange={handleChange}
                            data-testid="email_settings.password"
                            fullWidth
                            autoComplete="new-password"
                            error={errors.has('password')}
                            helperText={errors.get('password') || ''}
                        />
                    </Grid>
                </Grid>

                <FormActions>
                    <Button color="primary" onClick={handleSave} disabled={loading} data-testid="email_settings.save">
                        {props.t('button.save')}
                    </Button>
                </FormActions>
            </form>
        </FormBackdrop>
    );
};

export default withTranslation()(EmailSettingsForm);
