import React from 'react';
import './style.css';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

class LocalStorageBlocked extends React.PureComponent<Props> {
    render() {
        return (
            <div className="c-map-blocked">
                <img src="/logo.png" alt="" className="c-logo" />
                <h1 className="text">
                    <span dangerouslySetInnerHTML={{ __html: this.props.t('map_blocked.3rd_party_cookies') }} />
                </h1>
            </div>
        );
    }
}

export default withTranslation()(LocalStorageBlocked);
