import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { formatDateForExport, formatToDateTimeString } from '../../../utils';

interface Props {
    value: ParsableDate;
    label: string;
    error?: string;
    onChangeDate: (date: string | null) => void;
    disabled: boolean;
    autoFocus: boolean;
    clearable?: boolean;
}

class DateField extends React.PureComponent<Props> {
    private handleChange: BasePickerProps['onChange'] = (date) => {
        this.props.onChangeDate(date ? formatToDateTimeString(date).replace(/\s.*/, '') : null);
    };

    private handleDisplayDate = (date: MaterialUiPickersDate): string => {
        return date ? formatDateForExport(date)! : '';
    };

    render() {
        const { value, label, error, disabled, autoFocus, clearable } = this.props;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    error={!!error}
                    label={label}
                    margin="none"
                    inputVariant="outlined"
                    value={value}
                    labelFunc={this.handleDisplayDate}
                    onChange={this.handleChange}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    clearable={!!clearable}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default DateField;
