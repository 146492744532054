import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';
import validator from 'validator';

export default class Decimal extends AbstractField {
    constructor() {
        super(FIELD_TYPES.DECIMAL);

        this.minValue = null;
        this.maxValue = null;
        this.isDecimal = false;
    }

    setMinValue(minValue) {
        this.minValue = minValue;

        return this;
    }

    getMinValue() {
        return this.minValue ? Number.parseInt(this.minValue) : null;
    }

    setMaxValue(maxValue) {
        this.maxValue = maxValue;

        return this;
    }

    getMaxValue() {
        return this.maxValue ? Number.parseInt(this.maxValue) : null;
    }

    setIsDecimal(isDecimal) {
        this.isDecimal = isDecimal;

        return this;
    }

    getIsDecimal() {
        return this.isDecimal;
    }

    getValue() {
        if (!this.value) {
            return this.value;
        }

        return this.getIsDecimal() ? Number.parseFloat(this.value) : Number.parseInt(this.value);
    }

    validateValue() {
        return (
            (this.getIsDecimal() ? validator.isFloat(this.value) : validator.isInt(this.value)) &&
            (this.getMinValue() !== null ? this.getValue() >= this.getMinValue() : true) &&
            (this.getMaxValue() !== null ? this.getValue() <= this.getMaxValue() : true)
        );
    }
}
