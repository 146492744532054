import React from 'react';
import { observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PicklistValue } from 'components/types';
import Hint from 'components/Hint';
import { FormControl, FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

interface Props extends WithTranslation {
    value: string | number | null;
    label: string;
    hint?: string;
    picklistValues: PicklistValue[];
    onValueChanged: (value: string | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    required: boolean;
    disabled?: boolean;
    bareInput?: boolean;
    selectStyle?: object;
}

class SelectTypeInput extends React.Component<Props> {
    static defaultProps = {
        required: true,
    };

    render() {
        const { t, value, label, picklistValues, hint, error, disabled, bareInput, required, selectStyle } = this.props;

        const stringValue = value === null ? '' : value;

        const addEmptyOption = !required && picklistValues.filter((option) => option.value === '').length === 0;
        if (addEmptyOption) {
            picklistValues.unshift({ value: '', label: t('undefined_picklist_option') });
        }

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin={bareInput ? 'none' : 'dense'} error={error !== undefined}>
                        {!bareInput && (
                            <InputLabel shrink={true} style={{ width: 'max-content' }}>
                                {t(label)}
                            </InputLabel>
                        )}
                        <Select
                            error={!!error}
                            disabled={disabled}
                            fullWidth
                            displayEmpty
                            onChange={this.handleChange}
                            value={stringValue}
                            margin={bareInput ? 'none' : 'dense'}
                            style={selectStyle}
                        >
                            {picklistValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                {!!hint && (
                    <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                        <Hint iProps={{ style: { marginLeft: 8 } }}>{t(hint)}</Hint>
                    </Grid>
                )}
            </Grid>
        );
    }

    private handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { onValueChanged } = this.props;
        let value = e.target.value as string | null;
        if (value === '') {
            value = null;
        }
        onValueChanged(value).then(() => this.validate(value));
    };

    // @ts-ignore unused variable
    private validate = (value: string | null): Promise<void> => {
        const { onInputValidation } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };
}

export default withTranslation()(observer(SelectTypeInput));
