import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '../Alert';
import { userManager } from '../../service/UserManager';
import './style.css';
import LoadingButton from '../LoadingButton';
import qs from 'qs';
import ForgotPassword from '../ForgotPassword';
import { withRouter } from 'react-router';
import DottedLink from '../DottedLink';
import { PopupError } from '../../service/PopupJobFactory';
import { withTranslation } from 'react-i18next';
import { weAreInIframe, weAreInNativeApp } from '../../utils';
import Preloader from '../Preloader';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { adapterManager } from 'service/AdapterManager';
import SignInProviders from 'components/Login/SignInProviders';
import { AdapterId } from 'components/types';
import CredentialsDialog from 'components/Login/CredentialsDialog';
import { credentialsDialogManager } from 'service/Login';
import PasswordField from 'components/PasswordField';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../params';
import { withSnackbar } from 'notistack';
import qsManager from 'service/QueryStringManager';
import { getAdapters } from './adapters';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errors: new Map(),
            errorMessage: null,
            loading: false,
            captcha: null,
            dialogForgotPassword: false,
            redirectToProviderLoading: false,
        };

        this.recaptchaRef = React.createRef();

        const queryParams = qsManager.getSearchParams();
        const adapters = getAdapters();

        if (queryParams.SSOLogin) {
            let credentials;

            if (queryParams.SSOLogin === AdapterId.DYNAMICS365) {
                if (!queryParams.domain) {
                    this.props.history.push(window.location.pathname);
                    return;
                } else {
                    credentials = { organization_domain: queryParams.domain };
                }
            }

            const adapter = adapters.find((adapter) => adapter.id === queryParams.SSOLogin);

            if (adapter) {
                this.state.redirectToProviderLoading = true;
                this.handleSignInRequest(adapter, credentials);
            } else {
                this.props.history.push(window.location.pathname);
            }
        }
    }

    componentDidMount() {
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};
        if (params['session_is_over'] !== undefined) {
            this.setState({
                errorMessage: this.props.t('login.error.session_is_over'),
            });
            if (params['reason'] === 'too_many_sessions') {
                this.props.enqueueSnackbar(
                    this.props.t('security_settings.session_management_form.too_many_parallel_sessions'),
                    {
                        persist: true,
                        variant: 'warning',
                    },
                );
            }
        }

        if (params['reset_token_invalid'] !== undefined) {
            this.setState({
                errorMessage: this.props.t('login.error.reset_token_invalid'),
            });
        }

        if (params['error'] !== undefined) {
            this.setState({
                errorMessage: params['error'],
            });
        }

        // show loader while browser redirecting user from login page
        dispatcher.subscribe(events.IAB_CLOSED_AUTOMATICALLY, this, () => {
            this.setState({
                loading: true,
            });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    /**
     * @param {AdapterInfo} adapter
     */
    connectToAdapter = (adapter) => {
        adapterManager
            .forId(adapter.id)
            .then((adapter) => {
                credentialsDialogManager.openModal(adapter);
            })
            .catch((error) => {
                this.setState({
                    errors: error.details,
                });
            });
    };

    handleSubmit = () => {
        const errors = new Map();
        for (let prop of ['username', 'password']) {
            try {
                this.validateProperty(prop);
            } catch (e) {
                errors.set(prop, e.message);
            }
        }

        if (errors.size > 0) {
            this.setState({
                errorMessage: this.props.t('login.error.input'),
                errors: errors,
            });
            return;
        }

        this.setState({
            errorMessage: null,
            errors: errors,
            loading: true,
        });

        userManager
            .login(this.state.username, this.state.password, this.state.captcha)
            .then(() => {
                return userManager.hey();
            })
            .catch((error) => {
                if (this.recaptchaRef.current) {
                    this.recaptchaRef.current.reset();
                }
                this.setState({
                    errorMessage: error.message || 'Unexpected error',
                    loading: false,
                    captcha: null,
                });
            });
    };

    /**
     * @param {AdapterInfo} adapter
     */
    handleSignInRequest = (adapter, credentials) => {
        if (adapter.id === AdapterId.DYNAMICS365 && !credentials) {
            this.connectToAdapter(adapter);
        } else {
            this.loginVia(adapter.id, credentials);
        }
    };

    loginVia = (adapterId, credentials) => {
        this.setState({
            errorMessage: null,
        });

        userManager
            .loginVia(adapterId, credentials)
            .then((url) => {
                if (weAreInIframe()) {
                    window.location.href = url;
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                if (error instanceof PopupError) {
                    this.setState({
                        errorMessage: error.message,
                    });
                    return;
                }
                if (error instanceof Error) {
                    throw error;
                }
                if (typeof error === 'object') {
                    this.setState({
                        errorMessage: error.message,
                    });
                } else if (typeof error === 'string') {
                    this.setState({
                        errorMessage: error,
                    });
                } else {
                    console.error(error);
                    this.setState({
                        errorMessage: 'Unexpected error',
                    });
                }
            });
    };

    validateProperty(name) {
        const value = this.state[name];
        switch (name) {
            case 'username':
            case 'password':
                if (value === '') {
                    throw new Error(this.props.t('login.error.empty'));
                }
                break;
            default:
                break;
        }
    }

    handleInputChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleAlertClose = () => {
        this.setState({
            errors: new Map(),
            errorMessage: null,
        });
    };

    handleClickOpenForgotPasswordDialog = () => {
        this.setState({
            dialogForgotPassword: true,
        });
    };

    handleCloseForgotPasswordDialog = () => {
        this.setState({
            dialogForgotPassword: false,
        });
    };

    isSubmitButtonEnabled() {
        const { username, password, captcha } = this.state;

        if (!username || !password) {
            return false;
        }

        return !config.recaptchaSiteKey || !!captcha;
    }

    handleChangeCaptcha = (token) => {
        this.setState({
            captcha: token,
        });
    };

    handleExpiredCaptcha = () => {
        this.setState({
            captcha: null,
        });
    };

    render() {
        const { t } = this.props;

        if ((this.state.loading && weAreInNativeApp()) || this.state.redirectToProviderLoading) {
            return <Preloader />;
        }

        return (
            <div className="c-login">
                <img src="/logo.png" alt="" className="c-login__logo" />
                <Paper className="c-login__form" elevation={2}>
                    <h3 style={{ margin: 0 }}>{t('login.form.header')}</h3>
                    <form onSubmit={(event) => event.preventDefault()}>
                        <div style={{ position: 'relative' }}>
                            {this.state.errorMessage !== null && (
                                <Alert canClose onClose={this.handleAlertClose}>
                                    {this.state.errorMessage}
                                </Alert>
                            )}

                            {this.state.loading && <div className="backdrop" />}

                            <TextField
                                autoFocus
                                label={t('login.form.username.label')}
                                data-testid="login.form.username"
                                fullWidth
                                margin="dense"
                                name="username"
                                autoComplete="email"
                                value={this.state.username}
                                helperText={this.state.errors.get('username') || ''}
                                error={this.state.errors.has('username')}
                                onChange={this.handleInputChange('username')}
                            />
                            <PasswordField
                                label={t('login.form.password.label')}
                                data-testid="login.form.password"
                                name="password"
                                fullWidth
                                margin="dense"
                                value={this.state.password}
                                helperText={this.state.errors.get('password') || ''}
                                error={this.state.errors.has('password')}
                                onChange={this.handleInputChange('password')}
                                autoComplete="current-password"
                            />
                        </div>
                        {!!config.recaptchaSiteKey && (
                            <div style={{ position: 'relative', left: -12, top: 8 }}>
                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey={config.recaptchaSiteKey}
                                    onChange={this.handleChangeCaptcha}
                                    onExpired={this.handleExpiredCaptcha}
                                />
                            </div>
                        )}
                        <div>
                            <div className="action-bar">
                                <div className="forgot-password-link">
                                    <div>
                                        <DottedLink
                                            onClick={this.handleClickOpenForgotPasswordDialog}
                                            data-testid="login.form.forgot_password"
                                        >
                                            {t('login.form.forgot_password')}
                                        </DottedLink>
                                    </div>
                                </div>
                                <div className="submit-button">
                                    <LoadingButton
                                        name="signInButton"
                                        size="medium"
                                        color="primary"
                                        loading={this.state.loading}
                                        disabled={!this.isSubmitButtonEnabled()}
                                        onClick={this.handleSubmit}
                                        data-testid="login.form.sign_in"
                                        type="submit"
                                        buttonType="link"
                                    >
                                        {t('login.form.sign_in')}
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                    </form>

                    {this.state.dialogForgotPassword && (
                        <ForgotPassword
                            defaultEmail={this.state.username}
                            onClose={this.handleCloseForgotPasswordDialog}
                        />
                    )}
                    <CredentialsDialog onLoginVia={this.loginVia} />
                    <br />
                    <p style={{ margin: 0, fontSize: 'smaller' }}>Or log in with:</p>

                    <SignInProviders onSignInRequest={this.handleSignInRequest} />
                </Paper>

                <Box display="flex" alignItems="center" justifyContent="center" gridGap={8} my={3}>
                    <img width={48} height={48} src={'/image/sign_in/AICPA-s.png'} alt="" />
                    <Typography variant="caption" color="textSecondary">
                        <Box component="span" whiteSpace="pre-line">
                            {t('login.soc_compliance')}
                        </Box>
                    </Typography>
                </Box>
            </div>
        );
    }
}

export default withTranslation()(withRouter(withSnackbar(Login)));
