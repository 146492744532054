import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Icon,
    IconButton,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { clsx } from 'clsx';
import { format, formatInTimeZone } from 'date-fns-tz';
import cloneDeep from 'lodash/cloneDeep';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import events from '../../../events';
import { Geo } from '../../../interfaces';
import {
    Calendar,
    CalendarField,
    CalendarFieldType,
    CalendarSection,
    CalendarVirtualMethod,
} from '../../../interfaces/calendar/calendar';
import { Entity } from '../../../interfaces/entity';
import { LocationValidity } from '../../../interfaces/geo';
import { User } from '../../../interfaces/user';
import {
    CalendarEvent,
    CalendarEventField,
    CalendarEventSyncStatus,
} from '../../../service/Calendar/CalendarEventRepository';
import { calendarManager } from '../../../service/Calendar/CalendarManager';
import dateHelper from '../../../service/Date/DateHelper';
import metadataManager from '../../../service/MetadataManager';
import { userManager } from '../../../service/UserManager';
import dispatcher from '../../../service/dispatcher';
import {
    DATETIME_FORMAT_FOR_EXPORT_DATEFNS,
    DATETIME_FORMAT_NO_TIME_DATEFNS,
    propertyIdToName,
    propertyNameToId,
    UT_DATETIME_PICKER_FORMAT,
} from '../../../utils';
import AddressLookupField from '../../AddressLookupField/AddressLookupField';
import Alert from '../../Alert';
import FieldFactory from '../../LiveUpdateTableForm/FieldFactory';
import TimezonePicker from '../../TimezonePicker/TimezonePicker';
import { FieldLookupType, IField } from '../../types';
import CalendarUserSelectAutocomplete from '../UserSelect/CalendarUserSelectAutocomplete';
import { UserOption } from '../settings';
import usersPermissionsManager from '../../../service/Permissions/UsersPermissionsManager';

export enum TIME_FIELDS {
    START_TIME = 'startDatetime',
    END_TIME = 'endDatetime',
}

interface Props extends WithTranslation {
    calendarEvent: CalendarEvent;
    updateCalendarEvent: (calendarEvent: CalendarEvent, validateFieldAfter?: string | null) => void;
    errors: Map<string, string>;
    validateOne: (fieldName: string) => void;
    clearError: (fieldName: string) => void;
    setLoading: (loading: boolean) => void;
    paddingFirstRow?: boolean;
    dataSourceName?: string;
    onReSync?: () => void;
    isModified?: boolean;
    hasSyncError?: boolean;
}

interface State {
    accountId: number;
    calendar: Calendar | null;
    entity: Entity | null;
    entityFieldsMap: Map<string, IField> | null;
    userTimezone: string;
    polymorphicFieldSelectValues: Map<string, { apiName: string }>;
    hasBrowseUsersPermission: boolean;
    ownerUser?: User;
    syncErrorOpen: boolean;
}

class CalendarForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const userTimezone = userManager.getCurrentUser().actualTimezone;
        this.state = {
            accountId: 0,
            calendar: null,
            entity: null,
            entityFieldsMap: null,
            userTimezone: userTimezone,
            polymorphicFieldSelectValues: new Map<string, { apiName: string }>(),
            hasBrowseUsersPermission: false,
            ownerUser: undefined,
            syncErrorOpen: props.hasSyncError ?? false,
        };
    }

    async componentDidMount() {
        this.props.setLoading(true);
        dispatcher.subscribe([events.EVENT_ROLES_CHANGED, events.WS_ROLES_UPDATED], this, () => {
            const hasBrowseUsersPermission =
                usersPermissionsManager.hasBrowseUsersPermission &&
                (usersPermissionsManager.hasCalendarEventObjectModifyNotOwnedPermission ||
                    usersPermissionsManager.hasCalendarEventObjectRecordSharingRules);
            this.setState({ hasBrowseUsersPermission });
        });

        const accountId = userManager.getCurrentAccount().id;
        const calendars = await calendarManager.get(accountId);
        const calendar = calendars.get(this.props.calendarEvent.calendarId);

        let entityFieldsMap: Map<string, IField> | null = null;
        let entity: Entity | undefined = undefined;
        if (calendar) {
            entity = await metadataManager.requestEntity(calendar.entityId);

            if (entity) {
                entityFieldsMap = new Map<string, IField>();
                entity.fields.forEach((field: IField) => {
                    entityFieldsMap?.set(field.apiName, field);
                });
            }
        }

        const hasBrowseUsersPermission =
            usersPermissionsManager.hasBrowseUsersPermission &&
            (usersPermissionsManager.hasCalendarEventObjectModifyNotOwnedPermission ||
                usersPermissionsManager.hasCalendarEventObjectRecordSharingRules);

        const ownerUser = await userManager.getAccountUser(accountId, this.props.calendarEvent.ownerId);

        this.setState(
            {
                accountId,
                calendar: calendar ?? null,
                entityFieldsMap,
                entity: entity ?? null,
                hasBrowseUsersPermission,
                ownerUser,
            },
            () => {
                this.props.setLoading(false);
            },
        );
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.calendarEvent.ownerId !== this.props.calendarEvent.ownerId) {
            const ownerUser = await userManager.getAccountUser(this.state.accountId, this.props.calendarEvent.ownerId);
            this.setState({ ownerUser });
        }
    }

    private handleLocationChange = (location: string): void => {
        const { calendarEvent, clearError } = this.props;
        clearError('location');
        this.props.updateCalendarEvent({
            ...calendarEvent,
            location: location ? location : null,
            lat: null,
            lng: null,
            locationValidity: LocationValidity.Invalid,
        });
    };

    private handleClearAddress = (): void => {
        const { calendarEvent } = this.props;
        this.props.updateCalendarEvent(
            { ...calendarEvent, location: null, lat: null, lng: null, locationValidity: LocationValidity.Invalid },
            'location',
        );
    };

    private handleLocationSelect = (point: Geo.GeoLocationPoint): void => {
        const { calendarEvent, clearError } = this.props;
        clearError('location');
        this.props.updateCalendarEvent({
            ...calendarEvent,
            location: point.address ? point.address : null,
            lat: point.lat ? point.lat.toString() : null,
            lng: point.lng ? point.lng.toString() : null,
            locationValidity: point.exact ? LocationValidity.Exact : LocationValidity.Valid,
        });
    };

    private handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        this.props.clearError(target.name);
        const newValue = target.type === 'checkbox' ? target.checked : target.value;

        const { calendarEvent } = this.props;
        this.props.updateCalendarEvent({ ...calendarEvent, [target.name]: newValue });
    };

    private handleVirtualChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        this.props.clearError('virtual');
        if (target.checked) {
            this.props.clearError('location');
        }

        this.props.updateCalendarEvent({ ...this.props.calendarEvent, virtual: target.checked });
    };

    private handleStartTimeChange = (date: MaterialUiPickersDate): void => {
        this.handleTimeChange(date, TIME_FIELDS.START_TIME);
    };

    private handleEndTimeChange = (date: MaterialUiPickersDate): void => {
        this.handleTimeChange(date, TIME_FIELDS.END_TIME);
    };

    private handleTimeChange = (date: MaterialUiPickersDate, name: TIME_FIELDS): void => {
        const { calendarEvent } = this.props;

        const currentUser = userManager.getCurrentUser();
        const oldDate = new Date(date as any);
        let timeISO = !calendarEvent.allDay
            ? dateHelper.createFromDeviceDate(oldDate, currentUser).getDate().toISOString()
            : oldDate.toISOString();
        if (!calendarEvent.allDay) {
            if (name === TIME_FIELDS.START_TIME && calendarEvent.startTimezone) {
                timeISO = new Date(
                    format(oldDate, DATETIME_FORMAT_FOR_EXPORT_DATEFNS) +
                        formatInTimeZone(new Date(), calendarEvent.startTimezone, 'XXX'),
                ).toISOString();
            }
            if (name === TIME_FIELDS.END_TIME && calendarEvent.endTimezone) {
                timeISO = new Date(
                    format(oldDate, DATETIME_FORMAT_FOR_EXPORT_DATEFNS) +
                        formatInTimeZone(new Date(), calendarEvent.endTimezone, 'XXX'),
                ).toISOString();
            }
        } else {
            timeISO = format(oldDate, DATETIME_FORMAT_NO_TIME_DATEFNS);
        }

        this.props.clearError(TIME_FIELDS.START_TIME);
        this.props.clearError(TIME_FIELDS.END_TIME);

        this.props.updateCalendarEvent({ ...calendarEvent, [name]: timeISO }, name);
    };

    private handleFixedTimeToggle = (): void => {
        const { calendarEvent } = this.props;
        this.props.updateCalendarEvent({ ...calendarEvent, fixedTime: !calendarEvent.fixedTime });
    };

    private isVirtualDisabled = (): boolean => {
        const { calendar } = this.state;

        return (
            !calendar ||
            !calendar.virtual.enabled ||
            (calendar.virtual.method === CalendarVirtualMethod.CONDITION &&
                (calendar.virtual.formulaDefaultTrueValues === null ||
                    calendar.virtual.formulaDefaultFalseValues === null))
        );
    };

    private getFieldValue = (calendarEventFieldMap: Map<string, CalendarEventField>, entityField: IField): any => {
        const fieldValue = calendarEventFieldMap.get(entityField.apiName);
        if (!fieldValue) {
            return null;
        }

        if (
            [FieldLookupType.FOREIGN_ENTITY, FieldLookupType.ENTITY].includes(
                entityField.lookupData?.type as FieldLookupType,
            )
        ) {
            return {
                id: fieldValue.value,
                textValue: fieldValue.displayValue ?? '',
            };
        }

        if (entityField.lookupData?.type === FieldLookupType.FEW) {
            return Array.isArray(fieldValue.displayValue)
                ? fieldValue.displayValue.join(', ')
                : fieldValue.displayValue;
        }

        if (entityField.lookupData?.type === FieldLookupType.POLYMORPHIC) {
            const valueParsed = typeof fieldValue.value === 'string' ? JSON.parse(fieldValue.value) : fieldValue.value;
            return {
                apiName: valueParsed?.object,
                id: valueParsed?.recordId,
                textValue: fieldValue.displayValue ?? '',
            };
        }

        return fieldValue.value;
    };

    private getFieldValues = (calendarEventFieldMap: Map<string, CalendarEventField>, entityField: IField): any => {
        const fieldValue = calendarEventFieldMap.get(entityField.apiName);
        const idApiName = propertyNameToId(entityField.apiName);
        const valueApiName = propertyIdToName(entityField.apiName);
        const values: Record<string, any> = {
            [entityField.apiName]: calendarEventFieldMap.get(entityField.apiName)?.value,
        };
        if (fieldValue && entityField.lookupData?.type === FieldLookupType.FEW) {
            if (idApiName === entityField.apiName) {
                // first, try to extract value from display text
                values[valueApiName] = ([] as string[])
                    // it works with either string or array
                    .concat(fieldValue.displayValue || [])
                    .join(',')
                    .split(',')
                    .map((it) => it.trim());
                values[valueApiName] = Array.from(fieldValue.value ?? []).map(
                    // if not, filling it with any bullshit just to make it work
                    (it, idx) => values[valueApiName][idx] ?? it ?? '',
                );
            }
            if (valueApiName === entityField.apiName) {
                // if we start using field with valueApiName as a source, fix it
                values[idApiName] = [];
                values[idApiName] = Array.from(fieldValue.value ?? []).map(
                    (it, idx) => it ?? values[idApiName][idx] ?? '',
                );
            }
        }
        return values;
    };

    private handleStartTimezoneChange = (startTimezone: string | null): void => {
        this.props.updateCalendarEvent({
            ...this.props.calendarEvent,
            startTimezone: startTimezone,
            endTimezone: startTimezone,
        });
    };

    private handleEndTimezoneChange = (endTimezone: string | null): void => {
        this.props.updateCalendarEvent({
            ...this.props.calendarEvent,
            endTimezone: endTimezone,
            startTimezone: !endTimezone ? null : this.props.calendarEvent.startTimezone,
        });
    };

    private handleTimezoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        const { userTimezone } = this.state;
        const { calendarEvent } = this.props;
        this.props.updateCalendarEvent({
            ...calendarEvent,
            startTimezone: checked ? calendarEvent.startTimezone ?? userTimezone : null,
            endTimezone: checked ? calendarEvent.endTimezone ?? userTimezone : null,
        });
    };

    private handleAllDayChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        this.props.updateCalendarEvent({ ...this.props.calendarEvent, allDay: target.checked });
        this.props.validateOne(TIME_FIELDS.START_TIME);
        this.props.validateOne(TIME_FIELDS.END_TIME);
    };

    private handleFieldChange = (entityField: IField) => (value: any) => {
        let valueToSave = value[entityField.apiName];
        if (valueToSave === null) {
            this.updateCalendarEventFields(entityField.apiName, valueToSave, '', 'emails');
            return;
        }

        let textValue = value[entityField.apiName].textValue;

        if (entityField.lookupData?.type === FieldLookupType.POLYMORPHIC) {
            valueToSave = JSON.stringify({ object: valueToSave.apiName, recordId: valueToSave.id });
        } else if (entityField.lookupData?.type === FieldLookupType.FEW) {
            textValue = value[propertyIdToName(entityField.apiName)]?.join(', ');
        } else if (entityField.lookupData?.type === FieldLookupType.FOREIGN_ENTITY) {
            valueToSave = valueToSave.id;
        }

        this.updateCalendarEventFields(entityField.apiName, valueToSave, textValue, 'emails');
    };

    private updateCalendarEventFields = (
        name: string,
        value: any,
        displayValue?: string,
        validateFieldAfter?: string,
    ): void => {
        const fields: CalendarEventField[] = this.props.calendarEvent.fields
            ? cloneDeep(this.props.calendarEvent.fields)
            : [];
        for (let i in fields) {
            if (fields[i].name === name) {
                fields[i].value = value;
                fields[i].displayValue = displayValue;

                this.props.updateCalendarEvent({ ...this.props.calendarEvent, fields }, validateFieldAfter);
                return;
            }
        }

        fields.push({ name, value, displayValue });
        this.props.updateCalendarEvent({ ...this.props.calendarEvent, fields }, validateFieldAfter);
    };

    private handleSetSelectedUsers = (selectedUsers: UserOption[]): void => {
        if (!selectedUsers.length) {
            return;
        }

        this.props.updateCalendarEvent({ ...this.props.calendarEvent, ownerId: selectedUsers[0].id });
    };

    private handleSyncErrorClose = (): void => {
        this.setState({ syncErrorOpen: false });
    };

    render() {
        const { t, errors, calendarEvent, dataSourceName, onReSync, isModified } = this.props;
        const { accountId, calendar, entity, entityFieldsMap, hasBrowseUsersPermission, ownerUser, syncErrorOpen } =
            this.state;

        const currentUser = userManager.getCurrentUser();
        let startTime = formatInTimeZone(
            calendarEvent.startDatetime,
            currentUser.actualTimezone,
            UT_DATETIME_PICKER_FORMAT,
        );
        let endTime = formatInTimeZone(
            calendarEvent.endDatetime,
            currentUser.actualTimezone,
            UT_DATETIME_PICKER_FORMAT,
        );
        if (!calendarEvent.allDay) {
            if (calendarEvent.startTimezone) {
                startTime = formatInTimeZone(
                    calendarEvent.startDatetime,
                    calendarEvent.startTimezone,
                    UT_DATETIME_PICKER_FORMAT,
                );
            }
            if (calendarEvent.endTimezone) {
                endTime = formatInTimeZone(
                    calendarEvent.endDatetime,
                    calendarEvent.endTimezone,
                    UT_DATETIME_PICKER_FORMAT,
                );
            }
        }

        const calendarEventFieldMap = new Map<string, CalendarEventField>();
        if (calendarEvent.fields) {
            calendarEvent.fields.forEach((calendarEventField: CalendarEventField) => {
                calendarEventFieldMap.set(calendarEventField.name, calendarEventField);
            });
        }

        const timezone = Boolean(calendarEvent.startTimezone && calendarEvent.endTimezone);
        const validationRuleFormula = calendar?.validationRule?.formula ? calendar.validationRule.formula : '';
        const allDayCheckbox = (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={calendarEvent.allDay}
                        onChange={this.handleAllDayChange}
                        name="allDay"
                        color="primary"
                    />
                }
                label={t('calendar.form.all_day')}
                disabled={!calendar?.time.allDayField}
            />
        );
        const virtualCheckbox = (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={calendarEvent.virtual}
                        onChange={this.handleVirtualChange}
                        name="virtual"
                        color="primary"
                    />
                }
                label={t('calendar.form.virtual')}
                disabled={this.isVirtualDisabled()}
            />
        );

        const reSyncButtonText = t('calendar.context_menu.re_try_sync', {
            dataSourceName: dataSourceName ? dataSourceName : t('calendar.context_menu.data_source'),
        });

        const reSyncButtonTooltip = isModified
            ? t('calendar.form.re_try_sync.disabled_sync_tooltip', {
                  dataSourceName: dataSourceName ?? t('calendar.context_menu.data_source'),
              })
            : '';

        return (
            <div className="calendar-form">
                {syncErrorOpen && (
                    <Alert placement="context" canClose onClose={this.handleSyncErrorClose}>
                        {t('calendar.form.error.sync_error', {
                            dataSourceName: dataSourceName ?? t('calendar.context_menu.data_source'),
                        })}
                        <div>{calendarEvent.syncError}</div>
                        {calendarEvent.syncStatus === CalendarEventSyncStatus.ERROR && !!calendarEvent.syncError && (
                            <Box display="flex" justifyContent="flex-end">
                                <Tooltip title={reSyncButtonTooltip}>
                                    <span>
                                        <Button
                                            className={clsx('btn-re-sync', isModified ? 'disabled' : '')}
                                            color="primary"
                                            onClick={onReSync}
                                            disabled={isModified}
                                        >
                                            {reSyncButtonText}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        )}
                    </Alert>
                )}
                {errors.has('validationRule') && (
                    <Alert placement="context">{t(errors.get('validationRule')!) + ' ' + validationRuleFormula}</Alert>
                )}
                {!!calendarEvent.arrangementError && (
                    <Alert placement="context" type="warning">
                        {calendarEvent.arrangementError}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="title"
                            label={t('calendar.form.event_title')}
                            fullWidth
                            value={calendarEvent.title ?? ''}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                            autoFocus
                            error={errors.has('title')}
                            className="less-padding-top"
                        />
                        <FormHelperText error className={`calendar-form-error${errors.get('title') ? ' enabled' : ''}`}>
                            {errors.has('title') ? t(errors.get('title')!) : ' '}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AddressLookupField
                            address={calendarEvent.location ?? ''}
                            onAddressChange={this.handleLocationChange}
                            onAddressSelect={this.handleLocationSelect}
                            onClearAddress={this.handleClearAddress}
                            label={t('calendar.form.location')}
                            placeholder={t('calendar.form.location.placeholder')}
                            accountId={accountId}
                            error={errors.has('location')}
                            showExactitude={true}
                        />
                        <FormHelperText
                            error
                            className={`calendar-form-error${errors.get('location') ? ' enabled' : ''}`}
                        >
                            {errors.has('location') ? t(errors.get('location')!) : ' '}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        {calendarEvent.allDay ? (
                            <DatePicker
                                fullWidth
                                name="startTime"
                                label={t('calendar.form.start_time')}
                                value={startTime}
                                onChange={this.handleStartTimeChange}
                                error={errors.has(TIME_FIELDS.START_TIME)}
                                className="calendar-form-date-time"
                            />
                        ) : (
                            <DateTimePicker
                                fullWidth
                                name="startTime"
                                label={t('calendar.form.start_time')}
                                value={startTime}
                                onChange={this.handleStartTimeChange}
                                error={errors.has(TIME_FIELDS.START_TIME)}
                                className="calendar-form-date-time"
                            />
                        )}
                        <FormHelperText
                            error
                            className={`calendar-form-error${errors.get(TIME_FIELDS.START_TIME) ? ' enabled' : ''}`}
                        >
                            {errors.has(TIME_FIELDS.START_TIME) ? t(errors.get(TIME_FIELDS.START_TIME)!) : ' '}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {calendarEvent.allDay ? (
                            <DatePicker
                                fullWidth
                                name="endTime"
                                label={t('calendar.form.end_time')}
                                value={endTime}
                                onChange={this.handleEndTimeChange}
                                error={errors.has(TIME_FIELDS.END_TIME)}
                                className="calendar-form-date-time"
                            />
                        ) : (
                            <DateTimePicker
                                fullWidth
                                name="endTime"
                                label={t('calendar.form.end_time')}
                                value={endTime}
                                onChange={this.handleEndTimeChange}
                                error={errors.has(TIME_FIELDS.END_TIME)}
                                className="calendar-form-date-time"
                            />
                        )}
                        <FormHelperText
                            error
                            className={`calendar-form-error${errors.get(TIME_FIELDS.END_TIME) ? ' enabled' : ''}`}
                        >
                            {errors.has(TIME_FIELDS.END_TIME) ? t(errors.get(TIME_FIELDS.END_TIME)!) : ' '}
                        </FormHelperText>
                    </Grid>
                    {hasBrowseUsersPermission && !!calendar && !!ownerUser && (
                        <Grid item xs={12} sm={6}>
                            <CalendarUserSelectAutocomplete
                                setSelectedUsers={this.handleSetSelectedUsers}
                                selectedUsers={[{ id: ownerUser.id, name: ownerUser.name }]}
                                multiselect={false}
                                label={t('calendar.form.owner')}
                                usedInFormCalendar={calendar}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={12} sm={6} alignItems={'center'}>
                        <Grid item xs={12}>
                            <FormGroup row>
                                {this.isVirtualDisabled() ? (
                                    <Tooltip
                                        title={t('calendar.form.virtual.disabled_tooltip', {
                                            dataSourceName: entity?.dataSource.name ?? calendar?.name ?? '',
                                        })}
                                    >
                                        <Box component="span" display="flex" alignItems="center">
                                            {virtualCheckbox}
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    virtualCheckbox
                                )}
                                {!calendar?.time.allDayField ? (
                                    <Tooltip
                                        title={t('calendar.form.all_day.disabled_tooltip', {
                                            dataSourceName: entity?.dataSource.name ?? calendar?.name ?? '',
                                        })}
                                    >
                                        <Box component="span" display="flex" alignItems="center">
                                            {allDayCheckbox}
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    allDayCheckbox
                                )}
                                {!calendarEvent.allDay && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={timezone}
                                                onChange={this.handleTimezoneChange}
                                                name="timezone"
                                                color="primary"
                                            />
                                        }
                                        label={t('calendar.form.timezone')}
                                    />
                                )}
                                <Tooltip
                                    title={
                                        calendarEvent.fixedTime
                                            ? t('calendar.context_menu.fixed_time').toString()
                                            : t('calendar.context_menu.flexible_time').toString()
                                    }
                                >
                                    <IconButton onClick={this.handleFixedTimeToggle}>
                                        <Icon
                                            className={`fixed-time-icon fas fa-lock${
                                                calendarEvent.fixedTime ? '' : '-open'
                                            }`}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={`calendar-form-timezones${!calendarEvent.allDay && timezone ? ' enabled' : ''}`}
                >
                    <Grid item xs={12} sm={6}>
                        <TimezonePicker
                            label={t('calendar.form.start_timezone')}
                            value={calendarEvent.startTimezone ?? ''}
                            onChange={this.handleStartTimezoneChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TimezonePicker
                            label={t('calendar.form.end_timezone')}
                            value={calendarEvent.endTimezone ?? ''}
                            onChange={this.handleEndTimezoneChange}
                        />
                    </Grid>
                </Grid>
                {calendar && calendar.fields && entityFieldsMap && (
                    <div className="calendar-fields">
                        <Grid container>
                            {calendar.fields.map((field: CalendarField | CalendarSection, index: number) => {
                                if (field.type === CalendarFieldType.SECTION) {
                                    return (
                                        <Grid item xs={12} key={index}>
                                            <div className="calendar-fields-section">{field.label}</div>
                                        </Grid>
                                    );
                                }

                                if (!entityFieldsMap.has(field.apiName)) {
                                    return null;
                                }
                                const entityField = entityFieldsMap.get(field.apiName)!;
                                const value = this.getFieldValue(calendarEventFieldMap, entityField);
                                const values = this.getFieldValues(calendarEventFieldMap, entityField);

                                return (
                                    <Grid item xs={12} key={index}>
                                        <FieldFactory
                                            key={index}
                                            accountId={accountId}
                                            onChange={this.handleFieldChange(entityField)}
                                            value={value}
                                            field={entityField}
                                            values={values}
                                            updates={{}}
                                            dataSourceId={entity?.dataSource.id!}
                                            autoFocus={false}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(CalendarForm);
