import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Button } from '@material-ui/core';
import { FormAlert, FormActions } from 'components/PureFormDialog/Form';
import { WithTranslation, withTranslation } from 'react-i18next';
import DurationInput from './DurationInput';
import { FormBackdrop } from '../PureFormDialog/Form';
import { withStyles } from '@material-ui/core/styles';
import Hint from '../Hint';
import { SessionSettings, Duration } from 'service/types';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { IdleSessionManager } from '../../service/IdleSessionManager';
import './style.css';

interface Props extends WithTranslation, WithStyles {
    sessionSettings: SessionSettings;
    onSave: (sessionSettings: SessionSettings) => void;
}

interface Errors {
    [key: string]: string;
}

interface State {
    sessionSettings: SessionSettings;
    message: { type: string; text: string } | null;
    processing: { title: string } | null;
    errors: Errors;
}

type SessionSettingsDurationKeys =
    | 'idleSessionTimeoutForApp'
    | 'maxSessionDurationForApp'
    | 'idleSessionTimeoutForWeb'
    | 'maxSessionDurationForWeb';

const durations: { [key: string]: { min: number; max: number } } = {
    idleSessionTimeoutForWeb: {
        min: 60,
        max: 21 * 24 * 60 * 60,
    },
    maxSessionDurationForWeb: {
        min: 60,
        max: 365 * 24 * 60 * 60,
    },
    idleSessionTimeoutForApp: {
        min: 60,
        max: 21 * 24 * 60 * 60,
    },
    maxSessionDurationForApp: {
        min: 60,
        max: 365 * 24 * 60 * 60,
    },
};

class SessionManagementForm extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sessionSettings: { ...props.sessionSettings },
            errors: {},
            message: null,
            processing: null,
        };
    }

    private stringifyDuration = (n: number): string => {
        const { t } = this.props;
        const m = [60, 60, 24];
        const units = ['second', 'minute', 'hour', 'day'];
        for (let i = 0; i <= m.length; i++) {
            if (n % m[i] !== 0 || i === m.length) {
                return n + ' ' + t('security_settings.session_management_form.' + units[i]);
            }
            n = n / m[i];
        }
        return '';
    };

    private validateDuration(duration: Duration, name: SessionSettingsDurationKeys): void {
        const { t } = this.props;
        if (duration.number === null) {
            throw new Error(t('errors.invalid_value'));
        }

        const minDurationInSeconds = durations[name].min;
        const maxDurationInSeconds = durations[name].max;

        const d = IdleSessionManager.getDurationInSeconds(duration);

        if (d < minDurationInSeconds) {
            throw new Error(
                t('security_settings.session_management_form.validation.duration_is_too_small', {
                    min: this.stringifyDuration(minDurationInSeconds),
                }),
            );
        }

        if (d > maxDurationInSeconds) {
            throw new Error(
                t('security_settings.session_management_form.validation.duration_is_too_large', {
                    max: this.stringifyDuration(maxDurationInSeconds),
                }),
            );
        }
    }

    private validateMaxParallelSessions(maxParallelSessions: number | null): void {
        if (maxParallelSessions === null || maxParallelSessions < 1 || maxParallelSessions > 50) {
            throw new Error(
                this.props.t('security_settings.session_management_form.validation.max_parallel_sessions', {
                    min: 1,
                    max: 50,
                }),
            );
        }
    }

    private validate(): void {
        const { sessionSettings }: State = this.state;
        const errors: Errors = {};
        const x: SessionSettingsDurationKeys[] = [
            'idleSessionTimeoutForWeb',
            'maxSessionDurationForWeb',
            'idleSessionTimeoutForApp',
            'maxSessionDurationForApp',
        ];

        for (let p of x) {
            try {
                this.validateDuration(sessionSettings[p], p);
            } catch (e) {
                errors[p] = e.message;
            }
        }

        try {
            this.validateMaxParallelSessions(sessionSettings['maxParallelSessions']);
        } catch (e) {
            errors['maxParallelSessions'] = e.message;
        }

        if (Object.keys(errors).length > 0) {
            throw errors;
        }
    }

    private handleSave = (): void => {
        const { onSave, t }: Props = this.props;

        try {
            this.validate();
        } catch (errors) {
            this.setState({
                message: {
                    type: 'danger',
                    text: t('validation_errors'),
                },
                errors,
            });
            return;
        }

        this.setState({
            message: null,
            errors: {},
        });

        onSave && onSave(this.state.sessionSettings);
    };

    private handleSeparateSessionsChange = (e: any): void => {
        const sessionSettings = { ...this.state.sessionSettings };
        sessionSettings['separateWebAndAppSessions'] = e.target.checked;
        this.setState({
            sessionSettings,
        });
    };

    private handleMaxParallelSessionsChange = (e: any): void => {
        let value = e.target.value;
        if (!value.match(/^\d*$/)) {
            return;
        }
        value = value === '' ? null : parseInt(value);

        if (this.state.errors['maxParallelSessions']) {
            try {
                this.validateMaxParallelSessions(value);
                const errors = { ...this.state.errors };
                delete errors['maxParallelSessions'];
                this.setState({
                    message: Object.keys(errors).length > 0 ? this.state.message : null,
                    errors,
                });
            } catch (e) {}
        }

        this.setState({
            sessionSettings: { ...this.state.sessionSettings, maxParallelSessions: value },
        });
    };

    private handleDurationChange = (name: string, value: Duration): void => {
        const sessionSettings: SessionSettings = { ...this.state.sessionSettings };
        sessionSettings[name as SessionSettingsDurationKeys] = value;

        if (this.state.errors[name]) {
            try {
                this.validateDuration(value, name as SessionSettingsDurationKeys);
                const errors = { ...this.state.errors };
                delete errors[name];
                this.setState({
                    message: Object.keys(errors).length > 0 ? this.state.message : null,
                    errors,
                });
            } catch (e) {}
        }

        this.setState({
            sessionSettings,
        });
    };

    private handleAlertClose = (): void => {
        this.setState({
            message: null,
            errors: {},
        });
    };

    render() {
        const { sessionSettings, message, errors, processing }: State = this.state;
        const { t, classes }: Props = this.props;

        return (
            <div>
                {message !== null && (
                    <FormAlert type={message.type} onClose={this.handleAlertClose}>
                        {message.text}
                    </FormAlert>
                )}
                <FormBackdrop loading={processing !== null} task={processing ? processing.title : ''}>
                    <h3>{t('security_settings.session_management_form.web.header')}</h3>
                    <Grid container alignItems="flex-start" spacing={1} className={classes.durationRow}>
                        <Grid item xs={6}>
                            <span>
                                {t('security_settings.session_management_form.idle_session_timeout')}{' '}
                                <Hint>{t('security_settings.session_management_form.idle_session_timeout.hint')}</Hint>
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <DurationInput
                                name="idleSessionTimeoutForWeb"
                                value={sessionSettings.idleSessionTimeoutForWeb}
                                onChange={this.handleDurationChange}
                                error={errors.idleSessionTimeoutForWeb}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={1} className={classes.durationRow}>
                        <Grid item xs={6}>
                            <span>
                                {t('security_settings.session_management_form.max_session_duration')}{' '}
                                <Hint>{t('security_settings.session_management_form.max_session_duration.hint')}</Hint>
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <DurationInput
                                name="maxSessionDurationForWeb"
                                value={sessionSettings.maxSessionDurationForWeb}
                                onChange={this.handleDurationChange}
                                error={errors.maxSessionDurationForWeb}
                            />
                        </Grid>
                    </Grid>

                    <h3>{t('security_settings.session_management_form.app.header')}</h3>

                    <Grid container alignItems="flex-start" spacing={1} className={classes.durationRow}>
                        <Grid item xs={6}>
                            <span>
                                {t('security_settings.session_management_form.idle_session_timeout')}{' '}
                                <Hint>{t('security_settings.session_management_form.idle_session_timeout.hint')}</Hint>
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <DurationInput
                                name="idleSessionTimeoutForApp"
                                value={sessionSettings.idleSessionTimeoutForApp}
                                onChange={this.handleDurationChange}
                                error={errors.idleSessionTimeoutForApp}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={1} className={classes.durationRow}>
                        <Grid item xs={6}>
                            <span>
                                {t('security_settings.session_management_form.max_session_duration')}{' '}
                                <Hint>{t('security_settings.session_management_form.max_session_duration.hint')}</Hint>
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <DurationInput
                                name="maxSessionDurationForApp"
                                value={sessionSettings.maxSessionDurationForApp}
                                onChange={this.handleDurationChange}
                                error={errors.maxSessionDurationForApp}
                            />
                        </Grid>
                    </Grid>

                    <h3>{t('security_settings.session_management_form.parallel_sessions.header')}</h3>

                    <div>
                        <TextField
                            label={
                                <span>
                                    {t('security_settings.session_management_form.max_parallel_sessions')}{' '}
                                    <Hint>
                                        {t('security_settings.session_management_form.max_parallel_sessions.hint')}
                                    </Hint>
                                </span>
                            }
                            margin="dense"
                            value={
                                sessionSettings.maxParallelSessions === null ? '' : sessionSettings.maxParallelSessions
                            }
                            helperText={errors['maxParallelSessions'] || ''}
                            error={!!errors['maxParallelSessions']}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleMaxParallelSessionsChange}
                            InputLabelProps={{
                                className: 'session-management__label',
                            }}
                        />
                    </div>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sessionSettings.separateWebAndAppSessions}
                                onChange={this.handleSeparateSessionsChange}
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                {t('security_settings.session_management_form.count_sessions_separately')}{' '}
                                <Hint>
                                    {t('security_settings.session_management_form.count_sessions_separately.hint')}
                                </Hint>
                            </span>
                        }
                    />
                    <FormAlert type="info">
                        <span>{t('security_settings.session_management_form.new_profile_explanation')}</span>
                    </FormAlert>
                </FormBackdrop>
                <FormActions>
                    <Button color="primary" onClick={this.handleSave} disabled={processing !== null}>
                        {t('button.save')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

const styles = {
    durationRow: {
        marginBottom: 8,
        '& > div:first-child': {
            paddingTop: 24,
        },
    },
};

export default withTranslation('translations', { withRef: true })(withStyles(styles)(SessionManagementForm));
