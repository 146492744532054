import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class DsEntityLockedByList extends React.PureComponent {
    render() {
        let { t, isLockedBy, hasActiveCalendars, dataSourceName } = this.props;
        if ((!Array.isArray(isLockedBy) || isLockedBy.length === 0) && !hasActiveCalendars) {
            return null;
        }

        let items = {};
        if (Array.isArray(isLockedBy) && isLockedBy.length !== 0) {
            isLockedBy.forEach((lock, i) => {
                if (!items.hasOwnProperty(lock.entity.label)) {
                    items[lock.entity.label] = {};
                }
                if (!items[lock.entity.label].hasOwnProperty(lock.field.originalApiName)) {
                    items[lock.entity.label][lock.field.originalApiName] = (
                        <li key={i}>
                            {t('data_source.ds_entity.locked_tooltip.item', {
                                labelEntity: lock.entity.label,
                                labelField: lock.field.label.replace(/ \(NAME\)$| \(ID\)$/, ''),
                            })}
                        </li>
                    );
                }
            });
        }
        return (
            <>
                {Object.keys(items).length > 0 && (
                    <div>
                        {t('data_source.ds_entity.locked_tooltip.reference')}
                        <ul style={{ marginBlock: 0 }}>
                            {Object.keys(items)
                                .sort()
                                .map((entity) => {
                                    return Object.keys(items[entity])
                                        .sort()
                                        .map((field) => {
                                            return items[entity][field];
                                        });
                                })}
                        </ul>
                        {t('data_source.ds_entity.locked_tooltip.deselect')}
                    </div>
                )}
                {hasActiveCalendars && (
                    <div>{t('data_source.ds_entity.locked_tooltip.object_used_by_calendar', { dataSourceName })}</div>
                )}
            </>
        );
    }
}

DsEntityLockedByList.propTypes = {
    isLockedBy: PropTypes.array,
    hasActiveCalendars: PropTypes.bool,
    dataSourceName: PropTypes.string,
};

export default withTranslation('translations')(DsEntityLockedByList);
