import { withStyles } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';

export const Select = withStyles(
    (theme) => ({
        root: {
            paddingTop: 8,
            paddingBottom: 8,
        },
        select: {
            ...theme.typography.bodyM,
        },
    }),
    { name: 'MapslyMuiSelect' },
)(MuiSelect);
