import isNil from 'lodash/isNil';
import { userManager } from 'service/UserManager';
import RoutingSettingsMappingService from 'service/MapPage/RoutingSettings/RoutingSettingsMappingService';
import { Geo, Routing, User } from 'interfaces';
import GeoPointTransformer from 'service/GeoPointTransformer';
import { formatCoordinatesToAddress } from 'utils';
import dispatcher from '../../dispatcher';
import events from '../../../events';

class RoutingSettingsMappingHelper {
    private _usersSettingsData: Routing.Settings.UsersMappingRecordValueMap = new Map();
    private _serviceApi: RoutingSettingsMappingService = new RoutingSettingsMappingService();

    private _pendingUsersSettingsDataRequests: Map<string, Promise<Routing.Settings.UsersMappingRecordValueMap>> =
        new Map();

    constructor() {
        dispatcher.subscribe(events.EVENT_USER_CHANGED, this.constructor.name, (user: User.User) =>
            this.removeUserSettingsData(user),
        );
    }

    removeUserSettingsData(user: User.User) {
        this._usersSettingsData.delete(user.id);
    }

    getUserStartLocation(userSettings: Routing.Settings.MappingRecordValueMap): Routing.Route.UserLocationPoint | null {
        const mappingLat = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_LATITUDE)!;
        const mappingLng = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_LONGITUDE)!;
        const mappingAddress = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_ADDRESS)!;

        return this.getUserLocation(mappingLat, mappingLng, mappingAddress);
    }

    getUserFinishLocation(
        userSettings: Routing.Settings.MappingRecordValueMap,
    ): Routing.Route.UserLocationPoint | null {
        const mappingLat = userSettings.get(Routing.Settings.MappingUserKey.FINISH_LOCATION_LATITUDE)!;
        const mappingLng = userSettings.get(Routing.Settings.MappingUserKey.FINISH_LOCATION_LONGITUDE)!;
        const mappingAddress = userSettings.get(Routing.Settings.MappingUserKey.FINISH_LOCATION_ADDRESS)!;

        return this.getUserLocation(mappingLat, mappingLng, mappingAddress);
    }

    hasCoordinatesUserStartLocation(userSettings: Routing.Settings.MappingRecordValueMap): boolean {
        const mappingLat = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_LATITUDE)!;
        const mappingLng = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_LONGITUDE)!;
        return (
            this.hasCoordinatesUserLocation(mappingLat.crmValue, mappingLng.crmValue) ||
            this.hasCoordinatesUserLocation(mappingLat.defaultValue, mappingLng.defaultValue)
        );
    }

    hasAddressUserStartLocation(userSettings: Routing.Settings.MappingRecordValueMap): boolean {
        const mappingAddress = userSettings.get(Routing.Settings.MappingUserKey.START_LOCATION_ADDRESS)!;
        return (
            this.hasAddressUserLocation(mappingAddress.crmValue) ||
            this.hasAddressUserLocation(mappingAddress.travelingPreferenceValue) ||
            this.hasAddressUserLocation(mappingAddress.defaultValue)
        );
    }

    private hasCoordinatesUserLocation(
        mappingLatValue: Routing.Settings.MappingRecordValueStructure,
        mappingLngValue: Routing.Settings.MappingRecordValueStructure,
    ): boolean {
        return mappingLatValue.value !== null && mappingLngValue.value !== null;
    }

    private hasAddressUserLocation(mappingAddressValue: Routing.Settings.MappingRecordValueStructure): boolean {
        return mappingAddressValue.value !== null;
    }

    private getUserLocation(
        mappingLat: Routing.Settings.MappingRecordDataStructure,
        mappingLng: Routing.Settings.MappingRecordDataStructure,
        mappingAddress: Routing.Settings.MappingRecordDataStructure,
    ): Routing.Route.UserLocationPoint | null {
        const crmValue = this.getMappingValue(mappingLat.crmValue, mappingLng.crmValue, mappingAddress.crmValue);
        if (crmValue !== null) {
            return crmValue;
        }

        const travelingPreferencesValue = this.getTravelingPreferencesValue(mappingAddress.travelingPreferenceValue);
        if (travelingPreferencesValue !== null) {
            return travelingPreferencesValue;
        }

        const selectedValue = this.getMappingValue(
            mappingLat.selectedValue,
            mappingLng.selectedValue,
            mappingAddress.selectedValue,
        );
        if (selectedValue !== null) {
            return selectedValue;
        }

        return this.getMappingValue(mappingLat.defaultValue, mappingLng.defaultValue, mappingAddress.defaultValue);
    }

    async requestUserSettingsData(userId: number): Promise<Routing.Settings.MappingRecordValueMap> {
        const usersSettings = await this.requestUsersSettingsData([userId]);
        return usersSettings.get(userId)!;
    }

    async requestUsersSettingsData(userIds: number[]): Promise<Routing.Settings.UsersMappingRecordValueMap> {
        const notExistsUserIds = userIds.filter((userId) => !this._usersSettingsData.has(userId));
        if (notExistsUserIds.length === 0) {
            return this._usersSettingsData;
        }

        const notExistsUserIdsString = notExistsUserIds.join(',');
        const isPendingRequest = this._pendingUsersSettingsDataRequests.has(notExistsUserIdsString);

        let promise;
        if (isPendingRequest) {
            promise = this._pendingUsersSettingsDataRequests.get(notExistsUserIdsString)!;
        } else {
            promise = this._serviceApi.getUsersSettingsData(notExistsUserIds);
            this._pendingUsersSettingsDataRequests.set(notExistsUserIdsString, promise);
        }

        const result = await promise;
        for (const [userId, settings] of result) {
            this._usersSettingsData.set(userId, settings);
        }

        this._pendingUsersSettingsDataRequests.delete(notExistsUserIdsString);
        return this._usersSettingsData;
    }

    extractData(record: any, dataKey: string, entity: any): any | null {
        const account = userManager.getCurrentAccount();
        const objectsMapping = account.routingSettings.objectsMapping;
        let objectMapping: Routing.Settings.FormMappingData | undefined;
        for (const objectMappingItem of objectsMapping.values()) {
            if (objectMappingItem.currentObject?.entityId === entity.id) {
                objectMapping = objectMappingItem;
                break;
            }
        }
        if (!objectMapping) {
            return null;
        }

        const mappings = objectMapping.mapping;
        let mapping: Routing.Settings.MappingValueForm | undefined;
        for (const [mappingItemKey, mappingItemValue] of mappings.entries()) {
            if (mappingItemKey === dataKey) {
                mapping = mappingItemValue;
                break;
            }
        }
        if (!mapping) {
            return null;
        }

        const mappedField = entity.fields.find((field: any) => field.id === mapping!.fieldId);
        let value = mapping.defaultValue;
        if (!mappedField) {
            return value;
        }

        if (!isNil(record[mappedField.name])) {
            value = record[mappedField.name];
        }

        return value;
    }

    private getMappingValue(
        mappingLatValue: Routing.Settings.MappingRecordValueStructure,
        mappingLngValue: Routing.Settings.MappingRecordValueStructure,
        mappingAddressValue: Routing.Settings.MappingRecordValueStructure,
    ): Routing.Route.UserLocationPoint | null {
        if (this.hasCoordinatesUserLocation(mappingLatValue, mappingLngValue)) {
            const point = GeoPointTransformer.transformGeoPoint({
                lat: mappingLatValue.value as number,
                lng: mappingLngValue.value as number,
            });
            return {
                ...point,
                address: formatCoordinatesToAddress(point.lat, point.lng),
            };
        }

        if (this.hasAddressUserLocation(mappingAddressValue)) {
            return GeoPointTransformer.transformGeoLocationPoint(mappingAddressValue.value as Geo.GeoLocationPoint);
        }
        return null;
    }

    private getTravelingPreferencesValue(
        mappingAddressValue: Routing.Settings.MappingRecordValueStructure,
    ): Routing.Route.UserLocationPoint | null {
        if (this.hasAddressUserLocation(mappingAddressValue)) {
            return GeoPointTransformer.transformGeoLocationPoint(mappingAddressValue.value as Geo.GeoLocationPoint);
        }
        return null;
    }
}

export default RoutingSettingsMappingHelper;
