import React from 'react';
import { MapslyFile, SummarizationStatus, TranscriptionStatus } from 'interfaces/file';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Box, CircularProgress, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { fileManager } from '../../../service/File/FileManager';
import copy from 'copy-to-clipboard';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import Button from '@material-ui/core/Button';
import { userManager } from '../../../service/UserManager';

interface SummaryProps extends WithTranslation, WithSnackbarProps {
    accountId: number;
    file: MapslyFile;
    onSummaryUpdate: (status: SummarizationStatus) => void;
}

interface SummaryState {
    loading: boolean;
    content: string;
}

class Summary extends React.Component<SummaryProps, SummaryState> {
    constructor(props: SummaryProps) {
        super(props);

        this.state = {
            loading: false,
            content: '',
        };
    }

    componentDidMount() {
        if (this.props.file.summarizationStatus === SummarizationStatus.Completed) {
            this.loadSummary();
        } else {
            dispatcher.subscribe(events.WS_FILE_SUMMARIZATION, this, (status: any) => {
                if (status.fileId === this.props.file.id) {
                    this.props.onSummaryUpdate(status.status as SummarizationStatus);
                    this.loadSummary();
                }
            });
        }
    }

    loadSummary = () => {
        this.setState({ loading: true });
        fileManager
            .getSummary(this.props.accountId, this.props.file.id!)
            .then((summary) => {
                this.setState({
                    loading: false,
                    content: summary?.content ?? this.props.t('file_input.summary.empty'),
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({ loading: false });
            });
    };

    handleCopy = () => {
        copy(this.state.content);
    };

    renderContent = () => {
        const { file } = this.props;

        if (file.transcriptionStatus === TranscriptionStatus.Error) {
            return this.renderError();
        }

        if (this.state.loading) {
            return (
                <div>
                    <CircularProgress size={20} style={{ marginRight: '5px' }} />
                    {this.props.t('loading')}
                </div>
            );
        }

        switch (file.summarizationStatus) {
            case SummarizationStatus.Completed:
                return this.renderCompleted();
            case SummarizationStatus.Error:
                return this.renderError();
            case SummarizationStatus.Disabled:
                return this.renderDisabled();
            default:
                return this.renderInProgress();
        }
    };

    renderCompleted = () => {
        return <div>{this.state?.content ?? this.props.t('file_input.summary.empty')}</div>;
    };

    renderInProgress = () => {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress size={20} style={{ marginRight: '5px' }} />
                {this.props.t('file_input.summary.in_progress')}
            </Box>
        );
    };

    renderDisabled = () => {
        return (
            <Box display="flex" justifyContent="center">
                {this.props.t('file_input.summary.disabled')}
            </Box>
        );
    };

    renderError = () => {
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <Icon className="fa-light fa-triangle-exclamation" style={{ marginRight: '5px' }} />
                    {this.props.t('file_input.summary.error')}
                </Box>
                <div>{this.props.file.error}</div>
                {this.props.file.transcriptionStatus === TranscriptionStatus.Completed && (
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            onClick={this.handleRetrySummarization}
                            color="primary"
                            data-testid="file_input.summary.retry_summarization"
                        >
                            {this.props.t('file_input.summary.retry_summarization')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    handleRetrySummarization = () => {
        fileManager
            .retrySummarization(userManager.getCurrentUser().accountId, this.props.file.id as string)
            .then(() => {
                this.props.enqueueSnackbar(
                    this.props.t('entity_data_table.table_context_menu.summarization_has_retried_successfully'),
                    { variant: 'success' },
                );
            })
            .catch((error: Error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    render() {
        return (
            <div>
                <Grid container direction="row" alignItems="center" className="summary__header">
                    <Typography variant="h6">{this.props.t('file_input.summary.title')}</Typography>
                    <IconButton onClick={this.handleCopy} disabled={this.state?.content.length === 0}>
                        <Icon className="fa-light fa-copy" fontSize="small" />
                    </IconButton>
                </Grid>

                {this.renderContent()}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(withSnackbar(Summary));
