import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';
import { v4 as uuidv4 } from 'uuid';

class SharedMapManager extends BackendService {
    getSettings(accountId) {
        const url = reverse(apiRoutes.account.sharedMap.settings, { accountId });
        return this.requestApi(url, 'GET');
    }

    getLimits(accountId) {
        const url = reverse(apiRoutes.account.sharedMap.limits, { accountId });
        return this.requestApi(url, 'GET');
    }

    saveSettings(accountId, enabled, apiKey, secretApiKey) {
        const url = reverse(apiRoutes.account.sharedMap.settings, { accountId });
        return this.requestApi(url, 'PUT', { enabled, apiKey, secretApiKey });
    }

    list(accountId, filters, sorting, page, limit) {
        const url = reverse(apiRoutes.account.sharedMap.index, { accountId });
        return this.requestApi(url, 'GET', { filters, sorting, page, limit });
    }

    save(accountId, data) {
        const { id } = data;
        const method = id ? 'PUT' : 'POST';
        const url = id
            ? reverse(apiRoutes.account.sharedMap.detail, { id, accountId })
            : reverse(apiRoutes.account.sharedMap.index, { accountId });
        return this.requestApi(url, method, data).then((response) => {
            dispatcher.dispatch(events.SHARED_MAP_SAVED);
            return response;
        });
    }

    delete(accountId, id) {
        const url = reverse(apiRoutes.account.sharedMap.detail, { id, accountId });
        return this.requestApi(url, 'DELETE').then((response) => {
            dispatcher.dispatch(events.SHARED_MAP_SAVED);
            return response;
        });
    }

    createDefault() {
        const link = uuidv4().replaceAll('-', '');
        return {
            id: 0,
            user: 0,
            positionType: 'center_point_and_zoom',
            location: '',
            locationLatLng: '',
            zoom: 10,
            userLocation: false,
            userLocationZoom: 10,
            link,
            fullLink: this.createFullLink(link),
            northWest: '',
            southEast: '',
            allowLayers: false,
            allowTable: false,
            forceTableModeLayers: true,
            checkSignature: false,
        };
    }

    createNewLink() {
        const link = uuidv4().replaceAll('-', '');
        return {
            link,
            fullLink: this.createFullLink(link),
        };
    }

    createFullLink(link) {
        return window.location.protocol + '//' + window.location.hostname + '/map/' + link;
    }
}

export const sharedMapManager = new SharedMapManager();
