import { userManager } from '../UserManager';
import { STORAGE_KEY_PREFIX } from '../../components/types';
import { DeviceInfo } from '../../types';
import { logDebug } from '../../utils';

class DeviceInfoQueue {
    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.DEVICE_INFO_QUEUE + userManager.getCurrentUser().id;
    }

    push = (newInfo: DeviceInfo) => {
        let storedItems = this.getStoredItems();

        logDebug('New item was added to the device info queue');

        storedItems.push(newInfo);
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(storedItems));
    };

    private getStoredItems = (): DeviceInfo[] => {
        const serializedItems = window.localStorage.getItem(this.getStorageKey());
        if (!serializedItems) {
            return [];
        }

        return JSON.parse(serializedItems);
    };

    getHead = (): DeviceInfo | null => {
        let storedItems = this.getStoredItems();
        if (storedItems.length) {
            return storedItems[0];
        }

        return null;
    };

    removeHead = () => {
        let storedItems = this.getStoredItems();

        if (!storedItems.length) {
            return;
        }

        storedItems.shift();
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(storedItems));
    };
}

export default new DeviceInfoQueue();
