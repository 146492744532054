import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormControl, FormLabel, InputLabel, MenuItem, Select } from '@material-ui/core';

interface Props extends WithTranslation {
    value: number | null;
    values: number[];
    onChange: () => void;
}

class AdditionalPropertiesControl extends React.PureComponent<Props> {
    render() {
        const { t, value, values, onChange } = this.props;

        return (
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{t('table_view_form.additional_settings')}</FormLabel>

                <FormControl margin="dense">
                    <InputLabel>{t('table_view_form.additional_settings.per_page')}</InputLabel>
                    <Select
                        defaultValue={''}
                        value={value ?? ''}
                        onChange={onChange}
                        style={{ maxWidth: 196 }}
                        data-testid="table_view_form.additional_settings.per_page"
                    >
                        {values.map((pageSize) => (
                            <MenuItem key={pageSize} value={pageSize}>
                                {pageSize}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormControl>
        );
    }
}

export default withTranslation()(AdditionalPropertiesControl);
