import { capitalize } from '@material-ui/core';
import MUIIconButton, { IconButtonProps as MUIIconButtonProps } from '@material-ui/core/IconButton';
import { alpha, darken, makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FC, forwardRef } from 'react';

import './styles.css';

const iconButtonStyles = withStyles(
    (theme) => ({
        root: {
            '& .MuiTouchRipple-root': {
                color: theme.palette.button.grey.dark,
                '& .MuiTouchRipple-rippleVisible': {
                    opacity: 0.12,
                },
            },
            '&.Mui-disabled': {
                color: theme.palette.button.grey.main,
            },
        },
    }),
    {
        name: 'MuiIconButton',
    },
);

const useAdditionalStyles = makeStyles(
    (theme) => ({
        shadow: {
            boxShadow: theme.shadows[2],
            '&:hover': {
                boxShadow: theme.shadows[4],
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    boxShadow: theme.shadows[2],
                },
            },
            '&:active': {
                boxShadow: theme.shadows[8],
            },
        },
        colorPrimary: {
            color: theme.palette.button.primary.light,
            '&:hover, &.MuiIconButton-active': {
                backgroundColor: alpha(theme.palette.button.grey.dark, 0.07),
                color: theme.palette.button.primary.main,
            },
        },
        colorPrimaryPositive: {
            color: theme.palette.icon.primaryInverse,
            backgroundColor: theme.palette.text.positive,
            '&:hover, &.MuiIconButton-active': {
                backgroundColor: darken(theme.palette.text.positive, 0.07),
            },
        },
        colorPrimaryNegative: {
            color: theme.palette.text.negative,
            backgroundColor: theme.palette.background.negative,
            '&:hover, &.MuiIconButton-active': {
                backgroundColor: darken(theme.palette.background.negative, 0.07),
            },
        },
        colorSecondary: {
            color: theme.palette.button.boulder,
            '&:hover, &.MuiIconButton-active': {
                backgroundColor: alpha(theme.palette.button.grey.dark, 0.07),
                color: theme.palette.button.grey.dark,
            },
        },
        colorNegative: {
            color: theme.palette.button.negative,
            '&:hover, &.MuiIconButton-active': {
                backgroundColor: alpha(theme.palette.button.negative, 0.07),
                color: theme.palette.button.negative,
            },
        },
    }),
    {
        name: 'MapslyIconButton',
    },
);

const StyledMUIIconButton = iconButtonStyles(MUIIconButton);

type IconButtonColor = 'primary' | 'secondary' | 'negative' | 'primaryPositive' | 'primaryNegative';
type IconButtonSize = 'xsmall' | 'small' | 'medium' | 'large';

type IconButtonProps = Omit<MUIIconButtonProps, 'size' | 'color'> & {
    color?: IconButtonColor;
    active?: boolean;
    size?: IconButtonSize;
    disableElevation?: boolean;
};

const IconButton: FC<IconButtonProps> = forwardRef((props, ref) => {
    const { size = 'medium', className, active, children, color = 'primary', disableElevation = true, ...rest } = props;
    const classes = useAdditionalStyles();

    return (
        <StyledMUIIconButton
            ref={ref}
            {...rest}
            className={clsx(
                'iconButton',
                `MuiIconButton-size${capitalize(size)}`,
                className,
                { 'MuiIconButton-active': active, [classes.shadow]: !disableElevation },
                classes[`color${capitalize(color || '')}` as keyof typeof classes],
            )}
        >
            {children}
        </StyledMUIIconButton>
    );
});

export default IconButton;
