import React from 'react';
import PropTypes from 'prop-types';

import AbstractField from '../../../service/workflow_actions/forms/fields/AbstractField';
import Button from '../../../service/workflow_actions/forms/fields/Button';
import Checkbox from '../../../service/workflow_actions/forms/fields/Checkbox';
import DateTime from '../../../service/workflow_actions/forms/fields/DateTime';
import Decimal from '../../../service/workflow_actions/forms/fields/Decimal';
import File from '../../../service/workflow_actions/forms/fields/File';
import Html from '../../../service/workflow_actions/forms/fields/Html';
import Label from '../../../service/workflow_actions/forms/fields/Label';
import PickList from '../../../service/workflow_actions/forms/fields/PickList';
import Style from '../../../service/workflow_actions/forms/fields/Style';
import Text from '../../../service/workflow_actions/forms/fields/Text';

import ButtonField from './ButtonField';
import CheckboxField from './CheckboxField';
import DateTimeField from './DateTimeField';
import DecimalField from './DecimalField';
import FileField from './FileField';
import HtmlField from './HtmlField';
import LabelField from './LabelField';
import PicklistField from './PicklistField';
import StyleField from './StyleField';
import TextField from './TextField';

class FieldFactory extends React.Component {
    render() {
        if (this.props.field.getIsHidden()) {
            return null;
        }

        if (this.props.field instanceof Button) {
            return <ButtonField {...this.props} />;
        } else if (this.props.field instanceof Checkbox) {
            return <CheckboxField {...this.props} />;
        } else if (this.props.field instanceof DateTime) {
            return <DateTimeField {...this.props} />;
        } else if (this.props.field instanceof Decimal) {
            return <DecimalField {...this.props} />;
        } else if (this.props.field instanceof Label) {
            return <LabelField {...this.props} />;
        } else if (this.props.field instanceof PickList) {
            return <PicklistField {...this.props} />;
        } else if (this.props.field instanceof Text) {
            return <TextField {...this.props} />;
        } else if (this.props.field instanceof File) {
            return <FileField {...this.props} />;
        } else if (this.props.field instanceof Style) {
            return <StyleField {...this.props} />;
        } else if (this.props.field instanceof Html) {
            return <HtmlField {...this.props} />;
        } else {
            throw new Error('Unknown field type');
        }
    }

    static isInputField(field) {
        if (field.getIsHidden()) {
            return false;
        }

        if (
            field instanceof Checkbox ||
            field instanceof DateTime ||
            field instanceof Decimal ||
            field instanceof PickList ||
            field instanceof Text
        ) {
            return true;
        }

        return false;
    }
}

FieldFactory.propTypes = {
    accountId: PropTypes.number.isRequired,
    field: PropTypes.instanceOf(AbstractField).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
};

export default FieldFactory;
