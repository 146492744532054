import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';

class TableLoadingState extends React.PureComponent {
    render() {
        const { loading, columnCount, t } = this.props;
        const name = this.props.name ? this.props.name : t('table_loading_state.no_data');
        return (
            <td
                colSpan={columnCount}
                style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    padding: '40px 0',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                {loading ? <CircularProgress size={24} /> : <span>{name}</span>}
            </td>
        );
    }
}

const TranslatedTableLoadingState = withTranslation('translations', { withRef: true })(TableLoadingState);

export { TranslatedTableLoadingState as TableLoadingState };
