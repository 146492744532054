import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { Button, TextField } from '@material-ui/core';
import LandingLink from '../HelpLink/LandingLink';
import Confirmation from '../Confirmation';
import dsManagerFactory from '../../service/DsManager';
import { FormActions, FormAlert, FormBackdrop } from '../PureFormDialog/Form';
import { withTranslation } from 'react-i18next';
import { userManager } from '../../service/UserManager';

class InboundWebhooksForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            settings: {
                apiKey: this.props.dataSource.apiKey,
                apiRequestsDaily: this.props.dataSource.apiRequestsDaily,
                apiSyncRequestsPerSecond: this.props.dataSource.apiSyncRequestsPerSecond,
                apiAsyncRequestsPerSecond: this.props.dataSource.apiAsyncRequestsPerSecond,
            },
            isApiKeyMasked: true,
            loading: false,
            message: null,
            errors: new Map(),
        };
    }

    handleShow = () => {
        this.setState((state) => {
            return {
                isApiKeyMasked: !state.isApiKeyMasked,
            };
        });
    };

    handleCopy = () => {
        copy(this.state.settings.apiKey);
    };

    handleGenerate = () => {
        const settings = { ...this.state.settings };
        settings.apiKey = uuidv4();

        this.setState({
            settings,
        });
    };

    handleDelete = () => {
        const settings = { ...this.state.settings };
        settings.apiKey = null;

        this.setState({
            settings,
        });
    };

    handleLimitChange = (event) => {
        const name = event.target.name;
        let value = event.target.value ? Math.abs(event.target.value) : null;
        this.setState((state) => {
            const errors = new Map(state.errors);
            errors.delete(name);
            return { settings: { ...state.settings, [name]: value }, errors };
        });
    };

    validate = () => {
        const { settings } = this.state;
        const errors = new Map();

        if (userManager.isRoleSuperAdmin()) {
            if (!settings.apiRequestsDaily) {
                errors.set('apiRequestsDaily', this.props.t('errors.not_empty'));
            }
            if (!settings.apiSyncRequestsPerSecond) {
                errors.set('apiSyncRequestsPerSecond', this.props.t('errors.not_empty'));
            }
            if (!settings.apiAsyncRequestsPerSecond) {
                errors.set('apiAsyncRequestsPerSecond', this.props.t('errors.not_empty'));
            }
        }

        return errors;
    };

    handleSave = () => {
        const errors = this.validate();
        if (errors.size > 0) {
            this.setState({ errors });
            return;
        }

        const dataSource = { ...this.props.dataSource };
        dataSource.apiKey = this.state.settings.apiKey;
        dataSource.apiRequestsDaily = +this.state.settings.apiRequestsDaily;
        dataSource.apiSyncRequestsPerSecond = +this.state.settings.apiSyncRequestsPerSecond;
        dataSource.apiAsyncRequestsPerSecond = +this.state.settings.apiAsyncRequestsPerSecond;

        const dsManager = dsManagerFactory.getManager(this.props.dataSource.accountId);
        this.setState({
            loading: true,
        });
        dsManager
            .save(dataSource, false)
            .then(() => {
                this.setState({
                    loading: false,
                });
                this.props.onSaved && this.props.onSaved();
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger',
                    },
                });
            });
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
        });
    };

    render() {
        const { settings, isApiKeyMasked, loading, message, errors } = this.state;
        const { t } = this.props;

        return (
            <div>
                {message !== null && (
                    <FormAlert onClose={this.handleAlertClose} type={message.type}>
                        {message.text}
                    </FormAlert>
                )}
                <FormBackdrop loading={loading}>
                    {settings.apiKey ? (
                        <div>
                            <TextField
                                label={t('data_source.inbound_api.api_key')}
                                data-testid="data_source.inbound_api.api_key"
                                fullWidth
                                margin="dense"
                                value={settings.apiKey}
                                InputProps={{ disableUnderline: false, readOnly: true }}
                                autoFocus
                                type={isApiKeyMasked ? 'password' : 'text'}
                            />
                            <Button
                                onClick={this.handleShow}
                                color="primary"
                                data-testid="data_source.inbound_api.button.show"
                            >
                                {isApiKeyMasked ? t('button.show') : t('button.hide')}
                            </Button>
                            <Button
                                onClick={this.handleCopy}
                                color="primary"
                                data-testid="data_source.inbound_api.button.copy"
                            >
                                {t('button.copy')}
                            </Button>
                            <Confirmation
                                onConfirm={this.handleGenerate}
                                text={t('data_source.inbound_api.invalidate_warning')}
                            >
                                <Button color="primary" data-testid="data_source.inbound_api.button.regenerate">
                                    {t('data_source.inbound_api.regenerate')}
                                </Button>
                            </Confirmation>
                            <Button
                                onClick={this.handleDelete}
                                color="primary"
                                data-testid="data_source.inbound_api.button.delete"
                            >
                                {t('delete')}
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button onClick={this.handleGenerate} data-testid="data_source.inbound_api.button.generate">
                                {t('data_source.inbound_api.generate')}
                            </Button>
                        </div>
                    )}
                    <br />
                    <LandingLink useLeadingIcon useTrailingIcon article="4785064">
                        {t('data_source.inbound_api.learn_more')}
                    </LandingLink>
                    <br />
                    <br />
                    {userManager.isRoleSuperAdmin() && (
                        <React.Fragment>
                            <TextField
                                label={t('data_source.inbound_api.daily_limit')}
                                data-testid="data_source.inbound_api.daily_limit"
                                type="number"
                                fullWidth
                                margin="dense"
                                name="apiRequestsDaily"
                                value={settings.apiRequestsDaily}
                                helperText={errors.get('apiRequestsDaily') || ''}
                                error={errors.has('apiRequestsDaily')}
                                onChange={this.handleLimitChange}
                            />
                            <TextField
                                label={t('data_source.inbound_api.sync_limit_per_second')}
                                data-testid="data_source.inbound_api.sync_limit_per_second"
                                type="number"
                                fullWidth
                                margin="dense"
                                name="apiSyncRequestsPerSecond"
                                value={settings.apiSyncRequestsPerSecond}
                                helperText={errors.get('apiSyncRequestsPerSecond') || ''}
                                error={errors.has('apiSyncRequestsPerSecond')}
                                onChange={this.handleLimitChange}
                            />
                            <TextField
                                label={t('data_source.inbound_api.async_limit_per_second')}
                                data-testid="data_source.inbound_api.async_limit_per_second"
                                type="number"
                                fullWidth
                                margin="dense"
                                name="apiAsyncRequestsPerSecond"
                                value={settings.apiAsyncRequestsPerSecond}
                                helperText={errors.get('apiAsyncRequestsPerSecond') || ''}
                                error={errors.has('apiAsyncRequestsPerSecond')}
                                onChange={this.handleLimitChange}
                            />
                        </React.Fragment>
                    )}
                </FormBackdrop>

                <FormActions>
                    <Button
                        color="primary"
                        onClick={this.handleSave}
                        disabled={loading}
                        data-testid="data_source.inbound_api.button.save"
                    >
                        {t('button.save')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

InboundWebhooksForm.propTypes = {
    settings: PropTypes.object,
    onSaved: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(InboundWebhooksForm);
