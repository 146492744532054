import React, { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Checkbox, DialogActions, FormControlLabel } from '@material-ui/core';
import PureFormDialog from 'components/PureFormDialog';
import { User } from '../../../interfaces/user';
import { userManager } from '../../../service/UserManager';
import googleCalendarNotConnectedPopupManager from '../../../service/Calendar/GoogleCalendarNotConnectedPopupManager';
import { connectGoogleCalendar } from '../Helpers/CalendarHelper';

interface Props extends WithTranslation {
    onClose: () => void;
    user: User;
}

interface State {
    remember: boolean;
}

class GoogleCalendarNotConnectedPopup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            remember: false,
        };
    }

    private handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        this.setState({ remember: checked });
    };

    private handleClose = (): void => {
        if (this.state.remember) {
            googleCalendarNotConnectedPopupManager.doNotShowUntilTomorrow();
        }

        this.props.onClose();
    };

    private handleConnect = (): void => {
        this.handleClose();
        connectGoogleCalendar();
    };

    render() {
        const { t, user } = this.props;
        const { remember } = this.state;

        const isDispatcher = userManager.getCurrentUser().id === user.id;

        return (
            <PureFormDialog
                open={true}
                title=""
                onClose={this.handleClose}
                maxWidth="sm"
                fullWidth
                actions={
                    <DialogActions>
                        {isDispatcher ? (
                            <>
                                <Button onClick={this.handleClose}>{t('no')}</Button>
                                <Button color="primary" onClick={this.handleConnect}>
                                    {t('calendar.google_calendar_not_connected.dispatcher.button')}
                                </Button>
                            </>
                        ) : (
                            <Button onClick={this.handleClose}>{t('button.understand')}</Button>
                        )}
                    </DialogActions>
                }
            >
                <div>
                    {isDispatcher
                        ? t('calendar.google_calendar_not_connected.dispatcher')
                        : t('calendar.google_calendar_not_connected.not_dispatcher', { userName: user.name })}
                </div>
                <FormControlLabel
                    control={<Checkbox color="primary" onChange={this.handleCheckboxChange} value={remember} />}
                    label={t('calendar.google_calendar_not_connected.do_not_show_until_tomorrow')}
                />
            </PureFormDialog>
        );
    }
}

export default withTranslation()(GoogleCalendarNotConnectedPopup);
