import { CalendarEvent } from '../../../service/Calendar/CalendarEventRepository';
import { Calendar, CalendarEventWitEntityId, WaitingListItem } from '../../../interfaces/calendar/calendar';
import { EntityViewSourceEntity, IField } from '../../types';
import {
    findCalendarRelatedEntityField,
    getCalendarRelatedEntityFields,
    getDefaultCalendarEvent,
    getFormattedCalendarEventFieldValue,
} from './CalendarHelper';
import moment from 'moment/moment';
import { userManager } from '../../../service/UserManager';
import { Entity } from '../../../interfaces/entity';
import { transformGeoStatusToLocationValidity } from '../../../utils';

export const mapWaitingListItemsToCalendarEvents = (
    items: WaitingListItem[],
    calendar: Calendar,
    calendarEntity: Entity,
    entitiesMap: Map<number, EntityViewSourceEntity>,
    ownerId: number,
    startTime: Date,
): CalendarEventWitEntityId[] => {
    if (!items.length) {
        return [];
    }

    const userDefaultDuration = userManager.getCurrentUser().routingPreferences.defaultDuration;
    const calendarRelatedEntityFields: IField[] = getCalendarRelatedEntityFields(calendar, calendarEntity);

    const calendarEvents: CalendarEventWitEntityId[] = [];
    items.forEach((item: WaitingListItem) => {
        let calendarRelatedEntityField: IField | undefined;
        const itemEntity = entitiesMap.get(item.relatedTo.entityId);
        if (calendarRelatedEntityFields && itemEntity) {
            calendarRelatedEntityField = findCalendarRelatedEntityField(calendarRelatedEntityFields, itemEntity);
        }
        const event = mapWaitingListItemToCalendarEvent(
            item,
            calendar,
            ownerId,
            startTime,
            userDefaultDuration,
            itemEntity,
            calendarRelatedEntityField,
        );
        startTime = new Date(event.endDatetime);
        calendarEvents.push({ entityId: item.relatedTo.entityId, calendarEvent: event });
    });

    return calendarEvents;
};

export const mapWaitingListItemToCalendarEvent = (
    item: WaitingListItem,
    calendar: Calendar,
    ownerId: number,
    startTime: Date,
    userDefaultDuration: number,
    itemEntity?: EntityViewSourceEntity,
    calendarRelatedEntityField?: IField,
): CalendarEvent => {
    const event = getDefaultCalendarEvent(calendar.id, ownerId, startTime);
    event.id = item.id;
    event.title = item.title;
    event.location = item.location;
    event.lng = item.lng;
    event.lat = item.lat;
    event.virtual = item.virtual;
    if (calendarRelatedEntityField && itemEntity) {
        event.fields = [
            {
                name: calendarRelatedEntityField.apiName,
                value: getFormattedCalendarEventFieldValue(calendarRelatedEntityField, item.relatedTo.id, itemEntity),
                displayValue: item.relatedTo.displayText,
            },
        ];
    }
    event.endDatetime = moment(event.startDatetime)
        .add(item.duration ?? userDefaultDuration, 'm')
        .toDate()
        .toISOString();
    if (item.fixedTime !== undefined) {
        event.fixedTime = item.fixedTime;
    }
    if (item.geocoderStatus) {
        event.geocoderStatus = item.geocoderStatus;
        event.locationValidity = transformGeoStatusToLocationValidity(item.geocoderStatus);
    }

    return event;
};
