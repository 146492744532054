import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

export const ICON_POSITION_LEFT = 'left';
export const ICON_POSITION_RIGHT = 'right';

class SimpleWebLink extends React.PureComponent {
    getLabelFromChildren = (children) => {
        let label = '';

        React.Children.map(children, (child) => {
            if (typeof child === 'string') {
                label += child;
            }
        });

        return label;
    };

    render() {
        const url = this.getLabelFromChildren(this.props.children);
        const { classes } = this.props;

        return (
            <a href={url} target="_blank" title={this.props.text} rel="noreferrer noopener" className={classes.root}>
                <React.Fragment>
                    {this.props.iconPosition === ICON_POSITION_LEFT && <i className="fas fa-external-link left" />}
                    <span>{this.props.text}</span>
                    {this.props.iconPosition === ICON_POSITION_RIGHT && <i className="fas fa-external-link right" />}
                </React.Fragment>
            </a>
        );
    }
}

SimpleWebLink.propTypes = {
    text: PropTypes.string,
    iconPosition: PropTypes.string,
};

SimpleWebLink.defaultProps = {
    text: '',
    iconPosition: ICON_POSITION_LEFT,
};

const styles = {
    root: {
        color: 'inherit !important',
        '& > i': {
            fontSize: '75%',
            padding: '0 4px',
        },
    },
};

export default withStyles(styles)(SimpleWebLink);
