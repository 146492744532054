import { makeAutoObservable } from 'mobx';
import { departingDateTimeService } from 'service/MapPage';
import { DepartingDateTimeData } from 'service/MapPage/Appointment/DepartingDateTimeData';
import { MyLocations } from 'components/types';
import { Routing } from 'interfaces';

export interface IAppointmentConfig {
    departingCalendarData: DepartingDateTimeData;
    myLocations: MyLocations;
    isAddFromCalendarChecked: boolean;
}

export const defaultAppointmentValues: Readonly<IAppointmentConfig> = {
    departingCalendarData: new DepartingDateTimeData(),
    myLocations: {
        start: {
            buttonType: Routing.Route.MyLocationType.Manually,
            point: null,
        },
        end: {
            buttonType: Routing.Route.MyLocationType.Manually,
            point: null,
        },
        errors: {
            start: false,
            end: false,
        },
    },
    isAddFromCalendarChecked: false,
};

class AppointmentConfig implements IAppointmentConfig {
    initializationCompleted: boolean = false;

    departingCalendarData = defaultAppointmentValues.departingCalendarData;
    myLocations = defaultAppointmentValues.myLocations;
    isAddFromCalendarChecked = defaultAppointmentValues.isAddFromCalendarChecked;
    constructor() {
        makeAutoObservable(this);
    }

    setMyStartLocation(location: Routing.Route.MyLocation) {
        this.myLocations.start = location;
    }

    setMyStartLocationError(isError: boolean) {
        this.myLocations.errors.start = isError;
    }

    setMyEndLocation(location: Routing.Route.MyLocation) {
        this.myLocations.end = location;
    }

    setMyEndLocationError(isError: boolean) {
        this.myLocations.errors.end = isError;
    }

    setCalendarDate(date: Date, isTimeChanged: boolean) {
        this.departingCalendarData = departingDateTimeService.getCalendarData(date, isTimeChanged);
    }

    setCalendarData(data: DepartingDateTimeData) {
        this.departingCalendarData = data;
    }

    loadFromConfig(myLocations: MyLocations, calendar: Routing.Appointment.AppointmentCalendar): void {
        if (!this.initializationCompleted) {
            this.initializationCompleted = true;
        }

        this.departingCalendarData = new DepartingDateTimeData(calendar.departingCalendarData);
        this.myLocations = { ...myLocations };
        this.isAddFromCalendarChecked = calendar.isAddFromCalendarChecked;
    }

    reset() {
        this.initializationCompleted = false;
        this.departingCalendarData = defaultAppointmentValues.departingCalendarData;
        this.myLocations = defaultAppointmentValues.myLocations;
        this.isAddFromCalendarChecked = defaultAppointmentValues.isAddFromCalendarChecked;
    }
}

export default AppointmentConfig;
