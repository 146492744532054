import React from 'react';
import { withRouter } from 'react-router';
import { routes, reverse } from '../../routes';
import { Link, matchPath } from 'react-router-dom';
import { accountsManager } from '../../service/AccountsManager';
import { userManager } from '../../service/UserManager';
import dsManagerFactory from '../../service/DsManager';
import './style.css';
import Icon from '@material-ui/core/Icon';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { weAreInNativeApp } from '../../utils';
const t = i18n.t.bind(i18n);

class CrumbPicker {
    constructor(forSuperAdmin = true, t) {
        this.forSuperAdmin = forSuperAdmin;
        this.crumbs = [];
    }

    getAccountId(account) {
        return this.forSuperAdmin ? account.id : undefined;
    }

    addAccounts() {
        if (this.forSuperAdmin) {
            this.crumbs.push({
                link: reverse(routes.admin.accounts),
                text: t('breadcrumbs.accounts'),
            });
        }
        return this;
    }

    addAccount(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.index, { accountId: this.getAccountId(account) }),
            text: this.forSuperAdmin ? account.name : t('main_menu.account_settings'),
        });
        return this;
    }

    addDataSource(account, dataSource) {
        this.crumbs.push({
            link: reverse(routes.admin.account.dataSource.index, {
                accountId: this.getAccountId(account),
                dataSourceId: dataSource.id,
            }),
            text: dataSource.name,
        });
        return this;
    }

    addLog(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.log, { accountId: this.getAccountId(account) }),
            text: t('breadcrumbs.log'),
        });
        return this;
    }

    addUpdateEventLog(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.updateEventLog, { accountId: this.getAccountId(account) }),
            text: t('breadcrumbs.update_log'),
        });
        return this;
    }

    addLiveUpdateEventLog(account, dataSource) {
        this.crumbs.push({
            link: reverse(routes.admin.account.dataSource.index, {
                accountId: account.id,
                dataSourceId: dataSource.id,
            }),
            text: t('main_menu.update_jobs', { name: dataSource.name }),
        });
        return this;
    }

    addBilling(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.billing.index, { accountId: this.getAccountId(account) }),
            text: t('breadcrumbs.subscription_and_billing'),
        });
        return this;
    }

    addPermissions(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.permissions, { accountId: this.getAccountId(account) }),
            text: t('breadcrumbs.profiles_and_permissions'),
        });
        return this;
    }

    addSharedMaps(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.sharedMaps, { accountId: this.getAccountId(account) }),
            text: t('breadcrumbs.shared_maps'),
        });
        return this;
    }

    addWorkflowRules(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.workflowRules, { accountId: this.getAccountId(account) }),
            text: t('main_menu.workflow_rules'),
        });
        return this;
    }

    addWorkflowActions(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.workflowActions, { accountId: this.getAccountId(account) }),
            text: t('main_menu.workflow_actions'),
        });
        return this;
    }

    addWorkflowButtons(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.workflowButtons, { accountId: this.getAccountId(account) }),
            text: t('main_menu.workflow_buttons'),
        });
        return this;
    }

    addWorkflowSchedules(account) {
        this.crumbs.push({
            link: reverse(routes.admin.account.workflowSchedules, { accountId: this.getAccountId(account) }),
            text: t('main_menu.workflow_schedules'),
        });
        return this;
    }

    release() {
        return this.crumbs;
    }
}

class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            crumbs: null,
        };

        this.unlisten = null;

        this.pages = [
            {
                path: routes.admin.account.dataSource.index,
                handler: this.handleSource.bind(this),
            },
            {
                path: routes.admin.account.index,
                handler: this.handleAccount.bind(this),
            },
            {
                path: routes.admin.accounts,
                handler: this.handleAccounts.bind(this),
            },
            {
                path: routes.admin.account.log,
                handler: this.handleLog.bind(this),
            },
            {
                path: routes.admin.account.updateEventLog,
                handler: this.handleUpdateEventLog.bind(this),
            },
            {
                path: routes.admin.account.dataSource.updateJobs,
                handler: this.handleLiveUpdateEventLog.bind(this),
            },
            {
                path: routes.admin.account.billing.index,
                handler: this.handleBilling.bind(this),
            },
            {
                path: routes.admin.account.billing.subscribe,
                handler: this.handleBilling.bind(this),
            },
            {
                path: routes.admin.account.billing.index,
                handler: this.handleBilling.bind(this),
            },
            {
                path: routes.admin.account.permissions,
                handler: this.handlePermissions.bind(this),
            },
            {
                path: routes.admin.account.sharedMaps,
                handler: this.handleSharedMaps.bind(this),
            },
            {
                path: routes.admin.account.workflowRules,
                handler: this.handleWorkflowRules.bind(this),
            },
            {
                path: routes.admin.account.workflowActions,
                handler: this.handleWorkflowActions.bind(this),
            },
            {
                path: routes.admin.account.workflowButtons,
                handler: this.handleWorkflowButtons.bind(this),
            },
            {
                path: routes.admin.account.workflowSchedules,
                handler: this.handleWorkflowSchedules.bind(this),
            },
        ];
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        this.throwCrumbs(this.props.location);

        this.unlisten = this.props.history.listen((location, action) => {
            this.throwCrumbs(location);
        });
    }

    throwCrumbs = (location) => {
        for (let page of this.pages) {
            const match = matchPath(location.pathname, {
                path: [page.path],
                exact: true,
                strict: true,
            });
            if (match) {
                page.handler(match.params).then((crumbs) => {
                    this.setState({
                        crumbs,
                    });
                });
                return;
            }
        }

        this.setState({
            crumbs: null,
        });
    };

    loadAccount = (accountId) => {
        if (accountId) {
            return accountsManager.load(accountId);
        }
        return userManager.requestCurrentAccount();
    };

    handleAccounts = () => {
        return Promise.resolve(new CrumbPicker(true, this.props.t).addAccounts().release());
    };

    handleLog = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId, this.props.t)
                .addAccounts()
                .addAccount(account)
                .addLog(account)
                .release();
        });
    };

    handleUpdateEventLog = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId, this.props.t)
                .addAccounts()
                .addAccount(account)
                .addUpdateEventLog(account)
                .release();
        });
    };

    handleLiveUpdateEventLog = ({ accountId, dataSourceId }) => {
        let account = null;
        return this.loadAccount(accountId)
            .then((a) => {
                account = a;
                if (!account) {
                    return Promise.resolve(null);
                }
                return dsManagerFactory.getManager(account.id).load(dataSourceId);
            })
            .then((ds) => {
                if (!ds || !account) {
                    return null;
                }
                return new CrumbPicker(!!accountId)
                    .addAccounts()
                    .addAccount(account)
                    .addLiveUpdateEventLog(account, ds)
                    .release();
            });
    };

    handleBilling = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            let crumbPicker = new CrumbPicker(!!accountId, this.props.t).addAccounts();
            if (!weAreInNativeApp()) {
                crumbPicker.addAccount(account);
            }
            return crumbPicker.addBilling(account).release();
        });
    };

    handleAccount = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId, this.props.t).addAccounts().addAccount(account).release();
        });
    };

    handlePermissions = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId, this.props.t)
                .addAccounts()
                .addAccount(account)
                .addPermissions(account)
                .release();
        });
    };

    handleSharedMaps = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId, this.props.t).addAccount(account).addSharedMaps(account).release();
        });
    };

    handleWorkflowRules = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId).addAccounts().addAccount(account).addWorkflowRules(account).release();
        });
    };

    handleWorkflowActions = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId).addAccounts().addAccount(account).addWorkflowActions(account).release();
        });
    };

    handleWorkflowButtons = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId).addAccounts().addAccount(account).addWorkflowButtons(account).release();
        });
    };

    handleWorkflowSchedules = ({ accountId }) => {
        return this.loadAccount(accountId).then((account) => {
            return new CrumbPicker(!!accountId)
                .addAccounts()
                .addAccount(account)
                .addWorkflowSchedules(account)
                .release();
        });
    };

    handleSource({ accountId, dataSourceId }) {
        // accounts > red rose > sources > zoho2
        let account = null;

        return this.loadAccount(accountId)
            .then((a) => {
                account = a;
                if (!account) {
                    return Promise.resolve(null);
                }
                return dsManagerFactory.getManager(account.id).load(dataSourceId);
            })
            .then((ds) => {
                if (!ds || !account) {
                    return null;
                }
                return new CrumbPicker(!!accountId, this.props.t)
                    .addAccounts()
                    .addAccount(account)
                    .addDataSource(account, ds)
                    .release();
            });
    }

    render() {
        const crumbs = this.state.crumbs;
        if (crumbs === null) {
            return null;
        }

        return (
            <div className="c-breadcrumbs">
                {crumbs.map((crumb, index) => {
                    return index < crumbs.length - 1 ? (
                        <span key={index} className={crumb.classNames || 'c-breadcrumbs__item'}>
                            <Link to={crumb.link}>{crumb.text}</Link>
                            <Icon>keyboard_arrow_right</Icon>
                        </span>
                    ) : (
                        <span key={crumb.link}>{crumb.text}</span>
                    );
                })}
            </div>
        );
    }
}

export default withTranslation()(withRouter(Breadcrumbs));
