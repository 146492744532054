import dispatcher from './dispatcher';
import events from '../events';
import { generateLocationStringFromGeoPoint } from '../utils';

class FilterParamsManager {
    constructor() {
        this.filterParams = new Map();

        dispatcher.subscribe(events.WORKFLOW_MAP_ACTION_FORM_FIELD_UPDATED, this, (fields) => {
            for (let field of fields) {
                const { apiName, value } = field;
                if (value === '') {
                    this.filterParams.delete(apiName);
                } else {
                    this.filterParams.set(apiName, value);
                }
            }
            this.trigger();
        });

        dispatcher.subscribe(events.BASE_POINT, this, (point) => {
            if (!point) {
                this.filterParams.delete('location');
                this.trigger();
                return;
            }

            if (!point.address) {
                this.filterParams.delete('location');
                this.trigger();
                return;
            }

            const address = Number.isInteger(point.address) ? generateLocationStringFromGeoPoint(point) : point.address;
            this.filterParams.set('location', address);
            this.trigger();
        });
    }

    trigger() {
        dispatcher.dispatch(events.MAP_FILTER_UPDATED);
    }

    getFilterParams() {
        const result = {};
        for (const [key, value] of this.filterParams) {
            result[key] = value;
        }
        return result;
    }
}

const filterParamsManager = new FilterParamsManager();
export default filterParamsManager;
