import React from 'react';
import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import Checkbox from '../../../service/workflow_actions/forms/fields/Checkbox';
import { Checkbox as CheckboxInput, FormControlLabel } from '@material-ui/core';

class CheckboxField extends AbstractField {
    handleChange(e) {
        this.props.onChange(this.props.field, !this.props.field.getValue());
        this.handleHandler(e);
    }

    renderLabel(field) {
        return null;
    }

    renderInput(field) {
        return (
            <FormControlLabel
                className="field-container"
                control={
                    <CheckboxInput
                        name={field.getApiName()}
                        checked={field.getValue() ?? false}
                        disabled={field.getIsDisabled()}
                        onChange={this.handleChange}
                        color="primary"
                        autoFocus={this.props.autoFocus}
                        className="field-checkbox-input"
                        InputProps={{ className: 'field-input' }}
                    />
                }
                label={super.renderLabel(field)}
            />
        );
    }
}

CheckboxField.defaultProps = {
    autoFocus: false,
};

CheckboxField.propTypes = {
    field: PropTypes.instanceOf(Checkbox).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
};

export default CheckboxField;
