import { PopupData, PopupType } from '../../components/Calendar/settings';
import { userManager } from '../UserManager';
import {
    getCalendarId,
    getDefaultCalendarEvent,
    getStartTimeFromElement,
} from '../../components/Calendar/Helpers/CalendarHelper';
import moment from 'moment';
import { Calendars } from '../../interfaces/calendar/calendar';
import dispatcher from '../dispatcher';
import events from '../../events';
import { format, subDays } from 'date-fns';
import { DATE_FORMAT_DATEFNS } from '../../utils';
import dateHelper from '../Date/DateHelper';

export class CalendarPopupManager {
    private availableCalendars: Calendars;
    private activeCalendars?: Calendars;
    private openPopupType?: PopupType;
    private popupData?: PopupData;

    constructor(availableCalendars: Calendars, activeCalendars?: Calendars) {
        this.availableCalendars = availableCalendars;
        this.activeCalendars = activeCalendars;
    }

    openPopup = async (popupData: PopupData, popupType?: PopupType): Promise<void> => {
        if (popupType) {
            this.setValues(popupType, popupData);
            return;
        }

        if (popupData.calendarEvent) {
            this.openForm(popupData.calendarEvent.calendarId, popupData);
            return;
        }

        if (!this.availableCalendars.size) {
            return;
        }

        if (!popupData.ownerId) {
            return;
        }

        const calendarId = await getCalendarId(this.availableCalendars, popupData.ownerId);
        if (calendarId) {
            this.openForm(calendarId, popupData);
            return;
        }

        this.setValues(PopupType.CONTEXT_LEFT, popupData);
    };

    openForm = (calendarId?: string, popupData?: PopupData): void => {
        if (!calendarId) {
            calendarId = this.popupData?.calendar?.id ?? popupData?.calendarEvent?.calendarId;
            if (!calendarId) {
                return;
            }
        }

        let ownerId = popupData?.ownerId;
        if (!ownerId) {
            ownerId = this.popupData?.ownerId ?? popupData?.calendarEvent?.ownerId;
            if (!ownerId) {
                return;
            }
        }

        if (!this.activeCalendars?.has(calendarId)) {
            return;
        }
        const calendar = this.activeCalendars.get(calendarId);
        if (!calendar) {
            return;
        }

        if (calendar.alwaysUseForm && calendar.form) {
            this.openAutomationForm();
            return;
        }

        let calendarEvent = popupData?.calendarEvent;
        if (!calendarEvent) {
            if (!popupData?.target) {
                return;
            }

            calendarEvent = getDefaultCalendarEvent(calendarId, ownerId);

            const startTime = popupData?.startTime ?? this.getTimezoneStartTimeFromElement(popupData.target);
            let endTime = popupData?.endTime;
            if (startTime) {
                calendarEvent.startDatetime = startTime.toISOString();

                if (!endTime) {
                    const defaultDuration = userManager.getCurrentUser().routingPreferences.defaultDuration;

                    calendarEvent.endDatetime = moment(calendarEvent.startDatetime)
                        .add(defaultDuration, 'm')
                        .toDate()
                        .toISOString();
                }
            }
            if (endTime) {
                calendarEvent.endDatetime = endTime.toISOString();
            }
            if (popupData.relatedToField) {
                calendarEvent.fields = [popupData.relatedToField];
            }
            if (popupData.allDay) {
                if (calendar.time.allDayField) {
                    calendarEvent.allDay = popupData.allDay;
                    calendarEvent.startDatetime = format(new Date(calendarEvent.startDatetime), DATE_FORMAT_DATEFNS);
                    calendarEvent.endDatetime = format(
                        subDays(new Date(calendarEvent.endDatetime), 1),
                        DATE_FORMAT_DATEFNS,
                    );
                } else {
                    calendarEvent.endDatetime = subDays(new Date(calendarEvent.endDatetime), 1).toISOString();
                }
            }
        }

        this.setValues(PopupType.FULL_FORM, { calendarEvent, calendar, target: popupData?.target });
    };

    getTimezoneStartTimeFromElement = (element: Element): Date | null => {
        const startTime = getStartTimeFromElement(element);
        if (!startTime) {
            return null;
        }

        const currentUser = userManager.getCurrentUser();

        return dateHelper.createFromDeviceDate(startTime, currentUser).getDate();
    };

    closePopups = (callback?: () => void): void => {
        this.openPopupType = undefined;

        dispatcher.dispatch(events.CALENDAR_POPUP_UPDATED, { manager: this, callback });
    };

    getOpenPopupType = (): PopupType | undefined => {
        return this.openPopupType;
    };

    getPopupData = (): PopupData | undefined => {
        return this.popupData;
    };

    private openAutomationForm = (): void => {
        //TODO: finish in v2
    };

    private setValues = (openPopupType?: PopupType, popupData?: PopupData): void => {
        this.openPopupType = openPopupType;
        this.popupData = popupData;

        dispatcher.dispatch(events.CALENDAR_POPUP_UPDATED, { manager: this });
    };
}
