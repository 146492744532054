import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './style.css';

export default class Backdrop extends React.Component {
    renderTask = () => {
        const { task } = this.props;

        if (!task) {
            return null;
        }

        return typeof task === 'string' ? <p>{task}</p> : task;
    };

    render() {
        const { className, children, disableProgress, loading, loadingStyle, task, ...rest } = this.props;

        return (
            <div className={clsx('c-backdrop', className)} {...rest}>
                {loading && (
                    <div>
                        <div className="backdrop" />
                        <div className="loading" style={loadingStyle}>
                            {!disableProgress && <CircularProgress size={24} className="c-backdrop__progress" />}
                            {this.renderTask()}
                        </div>
                    </div>
                )}
                {children}
            </div>
        );
    }
}

Backdrop.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingStyle: PropTypes.object,
    task: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    disableProgress: PropTypes.bool,
};

Backdrop.defaultProps = {
    loading: false,
    loadingStyle: {},
};
