import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack/build/index';
import DottedLink from '../DottedLink';
import { userManager } from '../../service/UserManager';
import { adapterManager } from '../../service/AdapterManager';
import { PopupError } from '../../service/PopupJobFactory';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

class Sso extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            adapter: null,
            popupIsEnabled: true,
        };
        this.handleLogin();
    }

    componentDidMount() {
        adapterManager.forId(this.props.provider).then((adapter) => {
            this.setState({
                adapter,
            });
        });
    }

    handleLogin = () => {
        const { provider } = this.props;

        userManager
            .loginVia(provider, true)
            .then((url) => {
                window.location.href = url;
            })
            .catch((error) => {
                if (error instanceof PopupError) {
                    this.setState({
                        popupIsEnabled: false,
                    });
                    this.props.enqueueSnackbar(error.message, { variant: 'error' });
                    return;
                }
                if (error instanceof Error) {
                    throw error;
                }
                this.props.enqueueSnackbar(error, { variant: 'error' });
            });
    };

    render() {
        const { adapter, popupIsEnabled } = this.state;
        if (adapter === null) {
            return null;
        }

        const { t, classes } = this.props;

        if (popupIsEnabled) {
            return (
                <div className={clsx(classes.root, 'wallpaper')}>
                    <img className={classes.logo} src="/logo.png" alt="Mapsly" />

                    <p className={classes.hint} style={{ textAlign: 'center' }}>
                        {t('loading')}
                    </p>
                </div>
            );
        }

        return (
            <div className={clsx(classes.root, 'instruction')}>
                <img className={classes.logo} src="/logo.png" alt="Mapsly" />

                <p className={classes.hint}>
                    {t('login.sso.p.1.1', { adapterName: adapter.name })}
                    {t('login.sso.p.1.2')}
                    <br />
                    <img src="/image/popup-1c.png" alt={t('login.sso.p.1.img.1.alt')} />
                    <br />
                    {t('login.sso.p.1.3')}
                    <br />
                    <img src="/image/popup-2c.png" alt={t('login.sso.p.1.img.2.alt')} />
                    <br />
                    {t('login.sso.p.1.4')}
                </p>
                <p className={classes.hint}>{t('login.sso.p.2')}</p>
                <p className={classes.hint}>
                    {t('login.sso.p.3')}{' '}
                    <DottedLink onClick={this.handleLogin} data-testid="login.sso.login">
                        {t('login.sso.p.3.link', { adapterName: adapter.name })}
                    </DottedLink>
                    .
                </p>
            </div>
        );
    }
}

const styles = {
    root: {
        position: 'absolute',
        width: 440,
        top: '50%',
        left: '50%',
        marginLeft: -220,
        '&.wallpaper': {
            marginTop: -40,
        },
        '&.instruction': {
            marginTop: -200,
        },
    },
    hint: {
        color: '#888',
        fontSize: 'small',
        margin: 0,
    },
    logo: {
        width: 98,
        display: 'block',
        margin: '0 auto 30px',
    },
};

Sso.propTypes = {
    provider: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(withSnackbar(Sso)));
