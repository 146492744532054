import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import BaseIconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import { parseWebLinkToText, parseWebLinkToUrl } from '../utils/webLinkParser';
import { linkColName, serverGeocodeStatuses } from './SearchDataTable';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { GEO_FIELDS } from '../../references/geoFields';
import { LOCATIONS, ORDER } from '../../service/WorkflowButtonManager';
import WorkflowButtonFactory from '../WorkflowButtons/WorkflowButtonFactory';
import Confirmation from '../Confirmation';
import { userManager } from '../../service/UserManager';
import { PROSPECTING_MODULE, RECORD_MODULE, ROUTING_MODULE } from '../Permissions/constants';
import MapNavigationMenu from 'components/Shared/Menu/MapNavigationMenu';
import { travelingPreferences } from 'service/UserForm';
import { nativeAppFriendlyOpenWindow, getUrlNavigateByTypeMap } from 'utils';
import { PROSPECTING_ID } from './index';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { recordManager } from '../../service/RecordManager';
import { Tooltip } from '@material-ui/core';
import { availableRouteActions, enqueueSnackbarService, routingSessionManager } from '../../service/MapPage';
import PureFormDialog from '../PureFormDialog';
import { FormActions } from '../PureFormDialog/Form';
import copy from 'copy-to-clipboard';
import ToCalendarButton from '../Calendar/ToCalendarButton/ToCalendarButton';
import { PopupType } from '../Calendar/settings';
import CalendarFormPopups from '../Calendar/FormPopups/CalendarFormPopups';
import { CalendarEventManager } from '../../service/Calendar/CalendarEventManager';
import { FileType } from '../../interfaces/file';
import AudioVideoWrapper from '../FileInput/AudioVideo/AudioVideoWrapper';

const IconButton = withStyles({
    root: {
        padding: 4,
    },
})(BaseIconButton);

class TableContextMenu extends React.Component {
    calendarEventManager = new CalendarEventManager();

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            navigateAnchorEl: null,
            showAccessDeniedModal: false,
            openPopupType: undefined,
            popupData: undefined,
        };
    }

    componentWillUnmount = () => {
        this.calendarEventManager.dispose();
    };

    handleOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleNavigateClick = () => {
        const { record } = this.props;
        const point = {
            lat: record[GEO_FIELDS.LAT],
            lng: record[GEO_FIELDS.LNG],
        };
        const { mapNavigationOption } = travelingPreferences;
        if (mapNavigationOption) {
            nativeAppFriendlyOpenWindow(getUrlNavigateByTypeMap(point, mapNavigationOption));
            this.setState({
                anchorEl: null,
            });
        } else {
            this.setState({
                anchorEl: null,
                navigateAnchorEl: this.state.anchorEl,
            });
        }
    };

    handleSetNavigationOption = (type, point) => {
        travelingPreferences.setMapNavigationOption(type);

        nativeAppFriendlyOpenWindow(getUrlNavigateByTypeMap(point, type));
    };

    handleCloseNavigateMenu = () => {
        this.setState({
            navigateAnchorEl: null,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleActionClick = (event) => {
        const {
            record,
            onViewRecord,
            onExportProspect,
            onAddToRoute,
            onFindOnMap,
            onMakeBase,
            onRegeocode,
            onLoad,
            onDelete,
            onEditRoute,
            onOpenChangeHistory,
        } = this.props;
        const action = event.currentTarget.getAttribute('data-action');
        switch (action) {
            case 'view-record':
                onViewRecord && onViewRecord(record);
                break;
            case 'export-prospect':
                onExportProspect && onExportProspect(record);
                break;
            case 'add-to-route':
                onAddToRoute && onAddToRoute(record);
                break;
            case 'find-on-map':
                onFindOnMap && onFindOnMap(record);
                break;
            case 'make-base':
                onMakeBase && onMakeBase(record);
                break;
            case 'regeocode':
                onRegeocode && onRegeocode(record);
                break;
            case 'load':
                onLoad && onLoad(record);
                break;
            case 'delete':
                onDelete && onDelete(record);
                break;
            case 'edit-route':
                onEditRoute && onEditRoute(record);
                break;
            case 'change-history':
                onOpenChangeHistory && onOpenChangeHistory(record);
                break;
            default:
                if (this.props.record.entityId !== PROSPECTING_ID) {
                    recordManager.copyAddressToClipboard(this.props.record.entityId, this.props.record.id);
                } else {
                    copy(this.props.record.address);
                }
                break;
        }
        this.setState({
            anchorEl: null,
        });
    };

    handleRebuildRoutingSession = () => {
        return this.handleRebuildClick(this.props.record.id, null);
    };

    handleRebuildRoute = () => {
        return this.handleRebuildClick(null, this.props.record.id);
    };

    handleRebuildClick = async (sessionId, routeId) => {
        try {
            this.handleClose();
            await routingSessionManager.switchToRebuildMode(sessionId, routeId);
        } catch (error) {
            if (error.code === 403) {
                this.setState({ showAccessDeniedModal: true });
                return;
            }
            console.error(error);
            enqueueSnackbarService.sendErrorMessage(error.message);
        }
    };

    closeAccessDeniedModal = () => {
        this.setState({ showAccessDeniedModal: false });
    };

    handleWorkflowButtonClick = () => {
        if (this.props.record.entityId !== PROSPECTING_ID) {
            dispatcher.dispatch(events.UPDATE_RECORDS_REQUEST, { entityId: this.props.record.entityId, fields: [] });
        }
        this.handleClose();
    };

    getLoadableParams = () => {
        const loadableParams = this.props.loadable(this.props.record);
        const showLoad = loadableParams.isLoadable;
        const enableLoad = loadableParams.isLoadable && !loadableParams.disabled;
        const loadTooltip = loadableParams.tooltip;
        return { showLoad, enableLoad, loadTooltip };
    };

    setPopupData = (openPopupType, popupData, callback) => {
        this.setState({ openPopupType, popupData }, callback);
    };

    closePopups = () => {
        this.setState({ openPopupType: undefined, popupData: undefined });
    };

    render() {
        const { t, record, pinColumn, availableAddress, isRoutingSession, isRoute, isFile } = this.props;
        const point = {
            lat: record[GEO_FIELDS.LAT],
            lng: record[GEO_FIELDS.LNG],
        };

        const enableOpenInSource = record[linkColName] !== undefined && record[linkColName] !== null;

        const regeocodableStatuses = [
            serverGeocodeStatuses.success,
            serverGeocodeStatuses.doubt,
            serverGeocodeStatuses.noResult,
        ];

        const lat = record[GEO_FIELDS.LAT];
        const lng = record[GEO_FIELDS.LNG];
        const status = record[GEO_FIELDS.STATUS];
        const address = record[pinColumn];
        const hasCoordinates = typeof lat === 'number' && typeof lng === 'number' && !(lat === 0 && lng === 0);
        const isEntityRecord = record.entityId !== PROSPECTING_ID;
        const isAudioVideoFile = isFile && (record.type === FileType.Audio || record.type === FileType.Video);

        const enableExportProspect =
            this.props.record.entityId === PROSPECTING_ID &&
            userManager.userHasAccessTo(PROSPECTING_MODULE.NAME, PROSPECTING_MODULE.FEATURES.EXPORT.NAME);
        const enableRouting = userManager.userHasAccessTo(ROUTING_MODULE.NAME);
        const enableAddToRoute = hasCoordinates && enableRouting;
        const enableFindOnMap = hasCoordinates;
        const enableMakeBase = hasCoordinates;
        const enableNavigateHere = hasCoordinates;
        const enableCopyToClipboard = hasCoordinates;
        const enableRegeocode =
            this.props.isRegeocodable &&
            (address || '') !== '' &&
            regeocodableStatuses.indexOf(status) !== -1 &&
            !userManager.isSharedMapUser();
        const enableDelete = this.props.isDeletable;
        const enableEditRoute = this.props.isRoute;
        const enableViewRecord = userManager.userHasAccessTo(RECORD_MODULE.NAME);
        const { showLoad, enableLoad, loadTooltip } = this.getLoadableParams();
        const isLoadingRoutesConfirmationRequired =
            availableRouteActions.isLoadingRoutesConfirmationRequired || routingSessionManager.isLoadedRoute;

        const disabled =
            (!enableAddToRoute &&
                !enableFindOnMap &&
                !enableMakeBase &&
                !enableCopyToClipboard &&
                !enableOpenInSource &&
                !enableRegeocode &&
                !enableLoad &&
                !enableViewRecord) ||
            this.props.disabled;
        const modifyRouteDisabled = isRoute && !record.accessData.currentUserIsOwner && !record.accessData.isEditable;

        return (
            <React.Fragment>
                <IconButton
                    onClick={this.handleOpen}
                    disabled={disabled}
                    data-testid="entity_data_table.table_context_menu.open"
                >
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl !== null}
                    onClose={this.handleClose}
                    disableAutoFocusItem
                >
                    {isEntityRecord && (
                        <ToCalendarButton
                            key={'to_calendar_button'}
                            entityId={this.props.record.entityId}
                            pointId={this.props.record.id}
                            onClose={this.handleClose}
                            setPopupData={this.setPopupData}
                        >
                            <MenuItem>{t('calendar.add_to_calendar_button')}</MenuItem>
                        </ToCalendarButton>
                    )}
                    {isEntityRecord && (
                        <WorkflowButtonFactory
                            location={LOCATIONS.TABLE_CONTEXT_MENU}
                            item={this.props.record}
                            order={ORDER.FIRST}
                            onClick={this.handleWorkflowButtonClick}
                        />
                    )}
                    {isAudioVideoFile && (
                        <AudioVideoWrapper
                            accountId={userManager.getCurrentAccount().id}
                            fileId={record.id}
                            onClose={this.handleClose}
                        >
                            <MenuItem data-testid="entity_data_table.table_context_menu.open_file">
                                {t('entity_data_table.table_context_menu.open_file')}
                            </MenuItem>
                        </AudioVideoWrapper>
                    )}
                    {enableExportProspect && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="export-prospect"
                            data-testid="entity_data_table.table_context_menu.export_prospect"
                        >
                            {t('entity_data_table.table_context_menu.export_prospect')}
                        </MenuItem>
                    )}
                    {availableAddress && [
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="add-to-route"
                            key="add-to-route"
                            disabled={!enableAddToRoute}
                            data-testid="entity_data_table.table_context_menu.add_to_route"
                        >
                            {t('entity_data_table.table_context_menu.add_to_route')}
                        </MenuItem>,

                        enableViewRecord && isEntityRecord ? (
                            <MenuItem
                                onClick={this.handleActionClick}
                                data-action="view-record"
                                key="view-record"
                                data-testid="entity_data_table.table_context_menu.view_record"
                            >
                                {t('entity_data_table.table_context_menu.view_record')}
                            </MenuItem>
                        ) : null,

                        <MenuItem
                            onClick={this.handleNavigateClick}
                            data-click-away-ignore={true}
                            disabled={!enableNavigateHere}
                            key="navigate-here"
                            data-testid="entity_data_table.table_context_menu.navigate_here"
                        >
                            {t('entity_data_table.table_context_menu.navigate_here')}
                        </MenuItem>,

                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="find-on-map"
                            key="find-on-map"
                            disabled={!enableFindOnMap}
                            data-testid="entity_data_table.table_context_menu.find_on_map"
                        >
                            {t('entity_data_table.table_context_menu.find_on_map')}
                        </MenuItem>,

                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="make-base"
                            key="make-base"
                            disabled={!enableMakeBase}
                            data-testid="entity_data_table.table_context_menu.make_base"
                        >
                            {t('entity_data_table.table_context_menu.make_base')}
                        </MenuItem>,

                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="copy-to-clipboard"
                            key="copy-to-clipboard"
                            disabled={!enableCopyToClipboard}
                            data-testid="entity_data_table.table_context_menu.copy_to_clipboard"
                        >
                            {t('entity_data_table.table_context_menu.copy_to_clipboard')}
                        </MenuItem>,
                    ]}

                    {enableOpenInSource && (
                        <MenuItem data-click-away-ignore={true}>
                            <a
                                className="open-in-source"
                                href={parseWebLinkToUrl(record[linkColName])}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {parseWebLinkToText(record[linkColName])}
                            </a>
                        </MenuItem>
                    )}

                    {enableRegeocode && isEntityRecord && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="regeocode"
                            disabled={!enableRegeocode}
                            data-testid="entity_data_table.table_context_menu.regeocode"
                        >
                            {t('entity_data_table.table_context_menu.regeocode')}
                        </MenuItem>
                    )}

                    {enableEditRoute && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="edit-route"
                            disabled={modifyRouteDisabled}
                            data-testid="entity_data_table.table_context_menu.edit_route"
                        >
                            {t('entity_data_table.table_context_menu.edit_route')}
                        </MenuItem>
                    )}

                    {this.props.onOpenChangeHistory && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="change-history"
                            key="change-history"
                            data-testid="entity_data_table.table_context_menu.change_history"
                        >
                            {t('entity_data_table.table_context_menu.change_history')}
                        </MenuItem>
                    )}

                    {showLoad && (
                        <Tooltip title={loadTooltip}>
                            <div>
                                <MenuItem
                                    disabled={!enableLoad}
                                    onClick={this.handleActionClick}
                                    data-action="load"
                                    data-testid="entity_data_table.table_context_menu.load"
                                >
                                    {t('entity_data_table.table_context_menu.load')}
                                </MenuItem>
                            </div>
                        </Tooltip>
                    )}

                    {isRoutingSession && enableRouting && isLoadingRoutesConfirmationRequired && (
                        <Confirmation
                            text={t('entity_data_table.table_context_menu.rebuild.confirmation')}
                            onConfirm={this.handleRebuildRoutingSession}
                        >
                            <MenuItem data-testid="entity_data_table.table_context_menu.rebuild">
                                {t('entity_data_table.table_context_menu.rebuild')}
                            </MenuItem>
                        </Confirmation>
                    )}

                    {isRoutingSession && enableRouting && !isLoadingRoutesConfirmationRequired && (
                        <MenuItem
                            onClick={this.handleRebuildRoutingSession}
                            data-testid="entity_data_table.table_context_menu.rebuild"
                        >
                            {t('entity_data_table.table_context_menu.rebuild')}
                        </MenuItem>
                    )}

                    {isRoute && enableRouting && isLoadingRoutesConfirmationRequired && (
                        <Confirmation
                            text={t('entity_data_table.table_context_menu.rebuild.confirmation')}
                            onConfirm={this.handleRebuildRoute}
                        >
                            <MenuItem data-testid="entity_data_table.table_context_menu.rebuild">
                                {t('entity_data_table.table_context_menu.rebuild')}
                            </MenuItem>
                        </Confirmation>
                    )}

                    {isRoute && enableRouting && !isLoadingRoutesConfirmationRequired && (
                        <MenuItem
                            onClick={this.handleRebuildRoute}
                            data-testid="entity_data_table.table_context_menu.rebuild"
                        >
                            {t('entity_data_table.table_context_menu.rebuild')}
                        </MenuItem>
                    )}

                    {enableDelete && (
                        <Confirmation
                            text={t('entity_data_table.table_context_menu.delete.confirmation')}
                            onConfirm={(e) => {
                                e.currentTarget.setAttribute('data-action', 'delete');
                                this.handleActionClick(e);
                            }}
                        >
                            <MenuItem
                                disabled={modifyRouteDisabled}
                                data-testid="entity_data_table.table_context_menu.delete"
                            >
                                {t('entity_data_table.table_context_menu.delete')}
                            </MenuItem>
                        </Confirmation>
                    )}

                    {isEntityRecord && (
                        <WorkflowButtonFactory
                            location={LOCATIONS.TABLE_CONTEXT_MENU}
                            item={this.props.record}
                            order={ORDER.LAST}
                            onClick={this.handleWorkflowButtonClick}
                        />
                    )}
                </Menu>
                {this.state.navigateAnchorEl !== null && (
                    <MapNavigationMenu
                        anchorEl={this.state.navigateAnchorEl}
                        point={point}
                        onCloseMenu={this.handleCloseNavigateMenu}
                        onClickMenu={this.handleSetNavigationOption}
                    />
                )}

                <PureFormDialog
                    open={this.state.showAccessDeniedModal}
                    title={t('account_health.warning')}
                    onClose={this.closeAccessDeniedModal}
                >
                    {t('route_editor.menu_actions.re-build.disabled.tooltip')}
                    <FormActions />
                </PureFormDialog>
                {this.state.openPopupType === PopupType.FULL_FORM && (
                    <CalendarFormPopups
                        calendarEventManager={this.calendarEventManager}
                        onClose={this.closePopups}
                        calendarEvent={this.state.popupData?.calendarEvent ?? null}
                        calendar={this.state.popupData?.calendar ?? null}
                        anchorEl={this.state.popupData?.target}
                        preferFullForm={true}
                        usePopover={true}
                    />
                )}
            </React.Fragment>
        );
    }
}

TableContextMenu.propTypes = {
    record: PropTypes.object.isRequired,
    onViewRecord: PropTypes.func,
    onAddToRoute: PropTypes.func,
    onFindOnMap: PropTypes.func,
    onMakeBase: PropTypes.func,
    onRegeocode: PropTypes.func,
    onDelete: PropTypes.func,
    onEditRoute: PropTypes.func,
    onOpenChangeHistory: PropTypes.func,
    onExportProspect: PropTypes.func,
    isRegeocodable: PropTypes.bool,
    loadable: PropTypes.func,
    isDeletable: PropTypes.bool,
    isRoute: PropTypes.bool,
    isRoutingSession: PropTypes.bool,
    isFile: PropTypes.bool,
    disabled: PropTypes.bool,
    availableAddress: PropTypes.bool.isRequired,
};

TableContextMenu.defaultProps = {
    disabled: false,
};

export default withTranslation()(TableContextMenu);
