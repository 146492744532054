import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, DialogActions, FormHelperText, Grid } from '@material-ui/core';
import { WeekDay, WeekDays, WorkingHoursException, WorkingHoursPerWeek, WorkingHoursTime } from '../types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import BreakTypeInput from '../UserForm/TravelingPreferences/BreakTypeInput';
import BreakDurationInput from '../UserForm/TravelingPreferences/BreakDurationInput';
import { BreakTypeEnum } from '../../service/types';
import BreakEarliestTimeInput from '../UserForm/TravelingPreferences/BreakEarliestTimeInput';
import BreakLatestTimeInput from '../UserForm/TravelingPreferences/BreakLatestTimeInput';
import { validateForm, validateOne } from './ExceptionsValidator';
import Tooltip from '@material-ui/core/Tooltip';
import { User } from '../../interfaces/user';
import WorkingDayTime from '../UserForm/TravelingPreferences/WorkingDayTime';
import { v4 as uuidv4 } from 'uuid';
import './style.css';
import { countWeekDaysInDateRange, formatRouteDateWithYear } from '../../utils';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { saveWorkingHoursExceptions } from './helper';
import LoadingButton from '../LoadingButton';

interface State {
    errors: Map<string, string>;
    loading: boolean;
    workingHoursException?: NullableWorkingHoursException;
}

interface Props extends WithTranslation {
    onClose: () => void;
    onSave: () => void;
    user: User;
    date: Date;
}

export interface NullableWorkingHoursPerDay {
    start: WorkingHoursTime | null;
    end: WorkingHoursTime | null;
}

export interface NullableWorkingHoursPerWeek {
    [key: string]: NullableWorkingHoursPerDay | null;
}

export interface NullableWorkingHoursException extends Omit<WorkingHoursException, 'workingHours'> {
    workingHours: NullableWorkingHoursPerWeek;
}

class OneDayExceptionDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            errors: new Map<string, string>(),
            loading: false,
            workingHoursException: undefined,
        };
    }

    componentDidMount() {
        const { date } = this.props;
        const periodFrom = new Date(date);
        periodFrom.setHours(0, 0, 0, 0);
        const periodTo = new Date(date);
        periodTo.setHours(23, 59, 59, 999);

        const workingHours: WorkingHoursPerWeek = {};
        WeekDays.forEach((name) => {
            workingHours[name] = null;
        });

        const workingHoursException: WorkingHoursException = {
            id: uuidv4(),
            periodFrom: periodFrom.toISOString(),
            periodTo: periodTo.toISOString(),
            allowedOvertime: null,
            workingHours,
            breakType: null,
            breakDuration: null,
            breakEarliestTime: null,
            breakLatestTime: null,
        };

        this.setState({ workingHoursException });
    }

    private handleScheduleDayStartTimeChanged = (date: MaterialUiPickersDate, day: string) => {
        this.handleScheduleDayTimeChanged(date, day, 'start');
    };

    private handleScheduleDayEndTimeChanged = (date: MaterialUiPickersDate, day: string) => {
        this.handleScheduleDayTimeChanged(date, day, 'end');
    };

    private handleScheduleDayTimeChanged = (date: MaterialUiPickersDate, day: string, time: 'start' | 'end') => {
        this.setState(
            (state: State) => {
                if (!state.workingHoursException || !date) {
                    return state;
                }

                const workingHoursException = { ...state.workingHoursException };
                if (!workingHoursException.workingHours[day]) {
                    workingHoursException.workingHours[day] = { start: null, end: null };
                }

                workingHoursException.workingHours[day]![time] = { hours: date.getHours(), minutes: date.getMinutes() };

                return { ...state, workingHoursException };
            },
            () => {
                if (!this.state.workingHoursException) {
                    return;
                }

                let errors = new Map<string, string>(this.state.errors);
                errors = validateOne(
                    'workingHours',
                    errors,
                    this.state.workingHoursException as WorkingHoursException,
                    [],
                );
                this.setState({ errors });
            },
        );
    };

    private handleBreakTypeChange = (breakType: BreakTypeEnum | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((state: State) => {
                if (!state.workingHoursException) {
                    return state;
                }

                const workingHoursException = { ...state.workingHoursException };
                workingHoursException.breakType = breakType;
                if (!breakType) {
                    workingHoursException.breakDuration = null;
                    workingHoursException.breakEarliestTime = null;
                    workingHoursException.breakLatestTime = null;
                }

                return { ...state, workingHoursException };
            }, resolve);
        });
    };

    private handleBreakDurationChange = (breakDuration: number | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((state: State) => {
                if (!state.workingHoursException) {
                    return state;
                }

                const workingHoursException = { ...state.workingHoursException };
                if (!workingHoursException.breakType) {
                    return state;
                }

                workingHoursException.breakDuration = breakDuration;

                return { ...state, workingHoursException };
            }, resolve);
        });
    };

    private handleBreakEarliestTimeChange = (breakEarliestTime: string | null): Promise<void> => {
        return this.handleBreakTimeChange(breakEarliestTime, 'breakEarliestTime');
    };

    private handleBreakLatestTimeChange = (breakEarliestTime: string | null): Promise<void> => {
        return this.handleBreakTimeChange(breakEarliestTime, 'breakLatestTime');
    };

    private handleBreakTimeChange = (
        breakTime: string | null,
        field: 'breakEarliestTime' | 'breakLatestTime',
    ): Promise<void> => {
        return new Promise((resolve) => {
            this.setState(
                (state: State) => {
                    if (!state.workingHoursException) {
                        return state;
                    }

                    const workingHoursException = { ...state.workingHoursException };
                    if (!workingHoursException.breakType) {
                        return state;
                    }

                    workingHoursException[field] = breakTime;

                    return { ...state, workingHoursException };
                },
                () => {
                    if (!this.state.workingHoursException) {
                        return;
                    }

                    let errors = new Map<string, string>(this.state.errors);
                    errors.delete('breakEarliestTime');
                    errors.delete('breakLatestTime');
                    errors = validateOne(field, errors, this.state.workingHoursException as WorkingHoursException, []);
                    this.setState({ errors }, resolve);
                },
            );
        });
    };

    private handleSave = (): void => {
        if (!this.state.workingHoursException) {
            return;
        }
        const { date } = this.props;

        let errors = new Map<string, string>(this.state.errors);
        errors = validateForm(errors, this.state.workingHoursException as WorkingHoursException, []);
        this.setState({ errors });
        if (errors.size !== 0) {
            return;
        }

        const weekDayName = WeekDay[date.getDay()];
        const workingHoursExceptionsToSave = this.state.workingHoursException.workingHours[weekDayName];
        if (!workingHoursExceptionsToSave) {
            return;
        }

        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);

        this.setState({ loading: true }, () => {
            this.saveWorkingHoursExceptions().then(() => {
                this.props.onSave();
            });
        });
    };

    private saveWorkingHoursExceptions = (): Promise<any> => {
        if (!this.state.workingHoursException) {
            return Promise.resolve();
        }

        let userWorkingHourExceptions = this.props.user.routingPreferences.workingHoursExceptions
            ? cloneDeep(this.props.user.routingPreferences.workingHoursExceptions)
            : [];

        userWorkingHourExceptions = this.updateConflictingWorkingHoursExceptions(userWorkingHourExceptions);
        userWorkingHourExceptions.push(this.state.workingHoursException as WorkingHoursException);

        return saveWorkingHoursExceptions(this.props.user, userWorkingHourExceptions);
    };

    private updateConflictingWorkingHoursExceptions = (
        workingHoursExceptions: WorkingHoursException[],
    ): WorkingHoursException[] => {
        const { date } = this.props;
        let conflictingWorkingHoursExceptionIndex: number | undefined;
        for (let i = 0; i < workingHoursExceptions.length; i++) {
            if (
                moment(date).isBetween(
                    workingHoursExceptions[i].periodFrom,
                    workingHoursExceptions[i].periodTo,
                    'day',
                    '[]',
                )
            ) {
                conflictingWorkingHoursExceptionIndex = i;
                break;
            }
        }
        if (conflictingWorkingHoursExceptionIndex === undefined) {
            return workingHoursExceptions;
        }

        const conflictingException = cloneDeep(workingHoursExceptions[conflictingWorkingHoursExceptionIndex]);

        const periodTo = moment(date).subtract(1, 'day').toDate();
        periodTo.setHours(23, 59, 59, 999);
        if (moment(workingHoursExceptions[conflictingWorkingHoursExceptionIndex].periodFrom).isAfter(periodTo, 'day')) {
            workingHoursExceptions.splice(conflictingWorkingHoursExceptionIndex, 1);
        } else {
            workingHoursExceptions[conflictingWorkingHoursExceptionIndex].periodTo = periodTo.toISOString();
            let countWeekDays = countWeekDaysInDateRange(
                new Date(workingHoursExceptions[conflictingWorkingHoursExceptionIndex].periodFrom),
                new Date(workingHoursExceptions[conflictingWorkingHoursExceptionIndex].periodTo),
            );
            for (const dayName of Object.keys(
                workingHoursExceptions[conflictingWorkingHoursExceptionIndex].workingHours,
            )) {
                if (!countWeekDays.get(dayName)) {
                    workingHoursExceptions[conflictingWorkingHoursExceptionIndex].workingHours[dayName] = null;
                }
            }
        }

        const periodFrom = moment(date).add(1, 'day').toDate();
        periodFrom.setHours(0, 0, 0, 0);
        if (moment(periodFrom).isSameOrBefore(conflictingException.periodTo, 'day')) {
            conflictingException.periodFrom = periodFrom.toISOString();
            conflictingException.id = uuidv4();
            let countWeekDays = countWeekDaysInDateRange(
                new Date(conflictingException.periodFrom),
                new Date(conflictingException.periodTo),
            );
            for (const dayName of Object.keys(conflictingException.workingHours)) {
                if (!countWeekDays.get(dayName)) {
                    conflictingException.workingHours[dayName] = null;
                }
            }
            workingHoursExceptions.push(conflictingException);
        }

        return workingHoursExceptions;
    };

    private onInputValidation = (
        field: keyof WorkingHoursException,
        isValid: boolean,
        error?: string,
    ): Promise<void> => {
        return new Promise((resolve) => {
            return this.setState((state: State) => {
                const errors = new Map(state.errors);

                if (isValid) {
                    errors.delete(field);
                } else {
                    const transformedError = error === undefined ? '' : error;
                    if (errors.get(field) !== transformedError) {
                        errors.set(field, transformedError);
                    }
                }

                return { ...state, errors };
            }, resolve);
        });
    };

    render() {
        const { t, onClose, user, date } = this.props;
        const { errors, workingHoursException, loading } = this.state;

        if (!workingHoursException) {
            return null;
        }

        const weekDayName = WeekDay[date.getDay()];
        const exceptionWeekDay = workingHoursException.workingHours[weekDayName];

        let startDate = null;
        let endDate = null;
        if (exceptionWeekDay) {
            if (exceptionWeekDay.start) {
                startDate = new Date();
                startDate.setHours(exceptionWeekDay.start.hours, exceptionWeekDay.start.minutes, 0, 0);
            }
            if (exceptionWeekDay.end) {
                endDate = new Date();
                endDate.setHours(exceptionWeekDay.end.hours, exceptionWeekDay.end.minutes, 0, 0);
            }
        }

        const saveButton = (
            <LoadingButton
                color="primary"
                name="submit"
                onClick={this.handleSave}
                loading={loading}
                disabled={errors.size !== 0}
            >
                {t('working_hour_exceptions.one_day_exception_modal.create_exception_button')}
            </LoadingButton>
        );

        return (
            <PureFormDialog
                open={true}
                title={''}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                actions={
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            {t('button.cancel')}
                        </Button>
                        {errors.size !== 0 ? (
                            <Tooltip title={t('working_hour_exceptions.add_exception_modal.save_button_hint')}>
                                <span>{saveButton}</span>
                            </Tooltip>
                        ) : (
                            saveButton
                        )}
                    </DialogActions>
                }
            >
                <div className="one-day-exception-text">
                    {t('working_hour_exceptions.one_day_exception_modal.text', { username: user.name })}
                </div>
                <div className="one-day-exception-text">
                    {t('working_hour_exceptions.one_day_exception_modal.text_confirm', {
                        date: formatRouteDateWithYear(date, t),
                    })}
                </div>

                <div className="working-day-time">
                    <WorkingDayTime
                        weekDayStart={startDate}
                        weekDayEnd={endDate}
                        onStartDateChanged={(date) => this.handleScheduleDayStartTimeChanged(date, weekDayName)}
                        onEndDateChanged={(date) => this.handleScheduleDayEndTimeChanged(date, weekDayName)}
                        timePickerVariant={'inline'}
                        disabled={false}
                        startTimeLabel={t('working_hour_exceptions.one_day_exception_modal.day_start_time')}
                        endTimeLabel={t('working_hour_exceptions.one_day_exception_modal.day_finish_time')}
                    />
                    <FormHelperText
                        error
                        className={`working-hours-exceptions-form-error${errors.get('workingHours') ? ' enabled' : ''}`}
                    >
                        {errors.get('workingHours') ? t('schedule.end_cannot_precede_start') : ' '}
                    </FormHelperText>
                </div>

                <BreakTypeInput
                    value={workingHoursException.breakType || null}
                    onValueChanged={this.handleBreakTypeChange}
                    onInputValidation={(isValid: boolean, error?: string) =>
                        this.onInputValidation('breakType', isValid, error)
                    }
                    error={errors.get('breakType')}
                />
                {workingHoursException.breakType !== null && (
                    <div style={{ marginBottom: 5 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <BreakDurationInput
                                    value={workingHoursException.breakDuration || null}
                                    onValueChanged={this.handleBreakDurationChange}
                                    onInputValidation={(isValid: boolean, error?: string) =>
                                        this.onInputValidation('breakDuration', isValid, error)
                                    }
                                    error={errors.get('breakDuration')}
                                />
                            </Grid>
                            {workingHoursException.breakType === BreakTypeEnum.TIME_WINDOW && (
                                <React.Fragment>
                                    <Grid item xs={4}>
                                        <BreakEarliestTimeInput
                                            value={workingHoursException.breakEarliestTime || null}
                                            onValueChanged={this.handleBreakEarliestTimeChange}
                                            error={
                                                errors.get('breakEarliestTime')
                                                    ? t(errors.get('breakEarliestTime')!, {
                                                          max: workingHoursException.breakLatestTime,
                                                      })
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BreakLatestTimeInput
                                            value={workingHoursException.breakLatestTime || null}
                                            onValueChanged={this.handleBreakLatestTimeChange}
                                            error={
                                                errors.get('breakLatestTime')
                                                    ? t(errors.get('breakLatestTime')!, {
                                                          min: workingHoursException.breakEarliestTime,
                                                      })
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </div>
                )}
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(OneDayExceptionDialog);
