import { Box, Checkbox, Chip, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import throttle from 'lodash/throttle';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import BackendService from '../../../api/BackendService';
import events from '../../../events';
import { Calendar, Calendars } from '../../../interfaces/calendar/calendar';
import { User } from '../../../interfaces/user';
import { userManager } from '../../../service/UserManager';
import dispatcher from '../../../service/dispatcher';
import { EntityViewSource } from '../../types';
import {
    getAvailableCalendarsForUser,
    getCalendarIdToDataSourceMap,
    getCurrentAccountActiveCalendars,
    isGoogleCalendar,
} from '../Helpers/CalendarHelper';
import { UserOption } from '../settings';

import './style.css';

interface Props extends WithTranslation {
    setSelectedUsers: (selectedUsers: UserOption[]) => void;
    selectedUsers: UserOption[];
    multiselect: boolean;
    label: string;
    usedInFormCalendar?: Calendar;
    loading?: boolean;
    setLoading?: (loading: boolean) => void;
}

interface State {
    users: UserOption[] | null;
    activeCalendars?: Calendars;
    calendarIdToDataSourceMap?: Map<string, EntityViewSource>;
    availableCalendarsByUser: Map<number, Calendars>;
    googleCalendar?: Calendar;
}

class CalendarUserSelectAutocomplete extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            users: null,
            activeCalendars: undefined,
            calendarIdToDataSourceMap: undefined,
            availableCalendarsByUser: new Map<number, Calendars>(),
        };
    }

    componentDidMount = async (): Promise<void> => {
        dispatcher.subscribe(events.EVENT_USER_CHANGED, this, () => {
            this.loadUsersList();
        });
        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendarRelatedData();
        });
        this.loadUsersList();
        if (!this.props.selectedUsers.length) {
            const user = userManager.getCurrentUser();
            this.setSelectedUsers([this.getUserOptionFromUser(user)]);
        }
        await this.updateCalendarRelatedData();
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.multiselect !== this.props.multiselect) {
            this.handleMultiSelectModeChange();
        }
    }

    private updateCalendarRelatedData = async (): Promise<void> => {
        const activeCalendars = await getCurrentAccountActiveCalendars();
        const calendarIdToDataSourceMap = await getCalendarIdToDataSourceMap(Array.from(activeCalendars.values()));

        this.setState({ activeCalendars, calendarIdToDataSourceMap }, () => {
            this.updateAvailableCalendars();
        });
    };

    private updateAvailableCalendars = async (): Promise<void> => {
        const { users, activeCalendars } = this.state;
        if (!users || !activeCalendars) {
            return;
        }

        let googleCalendar;
        const activeCalendarsArray = Array.from(activeCalendars.values());
        for (let i = 0; i < activeCalendars.size; i++) {
            if (await isGoogleCalendar(activeCalendarsArray[i])) {
                googleCalendar = activeCalendarsArray[i];
                break;
            }
        }

        const availableCalendarsByUser = new Map<number, Calendars>();
        for (let i = 0; i < users.length; i++) {
            const availableCalendars = await getAvailableCalendarsForUser(activeCalendars, users[i].id);
            availableCalendarsByUser.set(users[i].id, availableCalendars);
        }

        this.setState({ availableCalendarsByUser, googleCalendar });
    };

    private setSelectedUsers = (selectedUsers: UserOption[]): void => {
        this.props.setSelectedUsers(selectedUsers);
    };

    private getUserOptionFromUser = (user: User): UserOption => {
        return { id: user.id, name: user.name };
    };

    private loadUsersList = (): void => {
        const promise = userManager.getAccountUsers(userManager.getCurrentAccount().id);

        if (!BackendService.isInstantPromise(promise)) {
            this.props.setLoading && this.props.setLoading(true);
        }

        promise
            .then((users) => {
                const userOptionArray: UserOption[] = [];

                users.forEach((user) => {
                    if ((user as User).role.forSharedMap) {
                        return;
                    }

                    const userOption = this.getUserOptionFromUser(user as User);
                    userOptionArray.push(userOption);
                });

                userOptionArray.sort((a: UserOption, b: UserOption) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
                );

                this.setState({ users: userOptionArray }, () => {
                    this.props.setLoading && this.props.setLoading(false);
                });
            })
            .catch(() => {
                //TODO: handle error
                this.props.setLoading && this.props.setLoading(false);
            });
    };

    private handleMultiSelectModeChange = (): void => {
        const { selectedUsers, multiselect } = this.props;
        if (!multiselect && selectedUsers.length > 1) {
            this.handleSelect([selectedUsers[0]]);
        }
    };

    private handleSelect = (selectedUsers: UserOption[] | UserOption | null): void => {
        if (selectedUsers && Array.isArray(selectedUsers) && selectedUsers.length > 10) {
            return; //TODO: probably add an error
        }

        let result: UserOption[] = [];
        if (!selectedUsers || (Array.isArray(selectedUsers) && !selectedUsers.length)) {
            const user = userManager.getCurrentUser();
            result = [this.getUserOptionFromUser(user)];
        }

        if (!result.length) {
            result = Array.isArray(selectedUsers) ? selectedUsers : [selectedUsers as UserOption];
        }

        this.setSelectedUsers(result);
    };

    private getAvailableCalendars = (userId: number): Calendars | undefined => {
        return this.state.availableCalendarsByUser.get(userId);
    };

    private getOptionLabel = (
        option: UserOption,
        { selected }: AutocompleteRenderOptionState,
    ): React.JSX.Element | string => {
        const { multiselect } = this.props;

        const availableCalendars = this.getAvailableCalendars(option.id);
        const noAvailableCalendarsForUser = !availableCalendars || availableCalendars.size === 0;
        if (!noAvailableCalendarsForUser) {
            return (
                <>
                    {!!multiselect && <Checkbox color="primary" style={{ marginRight: 8 }} checked={selected} />}
                    {option.name}
                </>
            );
        }

        const tooltip = this.props.usedInFormCalendar ? this.getInFormTooltip() : this.getNotInFormTooltip(option.id);

        return (
            <>
                {!!multiselect && <Checkbox color="primary" style={{ marginRight: 8 }} checked={selected} />}
                <Tooltip title={tooltip}>
                    <span className="no-available-calendars-icon">⚠️</span>
                </Tooltip>
                {option.name}
            </>
        );
    };

    private getInFormTooltip = (): string => {
        const usedInFormCalendar = this.props.usedInFormCalendar;
        if (!usedInFormCalendar) {
            return '';
        }

        const googleCalendar = this.state.googleCalendar;

        if (!googleCalendar || googleCalendar.id !== usedInFormCalendar.id) {
            const dataSource = this.state.calendarIdToDataSourceMap?.get(usedInFormCalendar.id);
            const dataSourceName = dataSource ? dataSource.name : usedInFormCalendar.name;
            return this.props.t('calendar.multiselect_mode_change.user.no_available_calendars_in_form', {
                dataSourceName,
            });
        }

        return this.props.t('calendar.multiselect_mode_change.user.google_calendar_not_connected_in_form');
    };

    private getNotInFormTooltip = (userId: number): string | React.JSX.Element => {
        const { activeCalendars, googleCalendar } = this.state;

        const calendarNames = Array.from(activeCalendars?.values() ?? [])
            .map((calendar: Calendar) => calendar.name)
            .join(', ');
        let tooltip = this.props.t(
            `calendar.multiselect_mode_change.user.no_available_calendars_${
                userManager.isRoleAdmin() ? 'admin' : 'non_admin'
            }`,
            { calendarNames },
        );

        if (!googleCalendar || !googleCalendar.active) {
            return tooltip;
        }

        if (googleCalendar.connectedUserIds.includes(userId)) {
            return tooltip;
        }

        return (
            <>
                {tooltip}
                <p>{this.props.t('calendar.multiselect_mode_change.user.google_calendar_not_connected')}</p>
            </>
        );
    };

    private getOptionDisabled = () => {
        return this.props.loading;
    };

    render() {
        const { selectedUsers, multiselect, label, loading } = this.props;
        const { users } = this.state;

        const autocompleteProps: any = {
            value: selectedUsers.length > 0 ? selectedUsers[0] : null,
            disableClearable: selectedUsers.length === 1,
        };
        if (multiselect) {
            autocompleteProps.multiple = true;
            autocompleteProps.disableCloseOnSelect = true;
            autocompleteProps.value = selectedUsers;
        }

        return (
            <Autocomplete
                {...autocompleteProps}
                disabled={loading}
                getOptionDisabled={this.getOptionDisabled}
                options={users || []}
                getOptionLabel={(option: UserOption) => option.name}
                renderOption={this.getOptionLabel}
                getOptionSelected={(option: UserOption, value: UserOption) => {
                    return option.id === value.id;
                }}
                renderTags={(values, getProps) => <RenderTags values={values as UserOption[]} getProps={getProps} />}
                renderInput={(params) => (
                    <TextField className="calendar-user-select-autocomplete" {...params} label={label} />
                )}
                onChange={(_: any, selectedUsers: UserOption[] | UserOption | null) => {
                    this.handleSelect(selectedUsers);
                }}
            />
        );
    }
}

const avgCharWidth = 8;
function getChipWidth(text: string) {
    return (
        text.length * avgCharWidth +
        // padding
        24 +
        // close button
        20 +
        // gap
        8
    );
}

interface RenderTagsProps {
    values: UserOption[];
    getProps: (_: { index: number }) => any;
}

const RenderTags: FC<RenderTagsProps> = (props) => {
    const { values, getProps } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    const widths = values.reduce(
        (acc, cur) => {
            acc.push(acc[acc.length - 1] + getChipWidth(cur.name));
            return acc;
        },
        // input width
        [60],
    );

    useEffect(
        () => {
            const div = ref.current;
            if (!div) {
                return;
            }

            const handleResize = throttle(() => {
                const bcr = div.parentElement!.getBoundingClientRect();

                if (width !== bcr.width) {
                    setWidth(bcr.width);
                }
            }, 100);

            handleResize(); // Initial calculation

            const observer = new ResizeObserver(handleResize);
            observer.observe(div.parentElement!);

            return () => {
                observer.disconnect();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            /* width */
        ],
    );

    const maxSpans = useMemo(() => {
        const maxSpans = widths.concat(Infinity).findIndex((it) => it > width) - 1;

        return maxSpans || 1;
    }, [widths, width]);

    return (
        <div ref={ref}>
            {values.slice(0, maxSpans).map((v, index) => (
                <Chip /* key is included in getProps */ {...getProps({ index })} label={v.name} />
            ))}
            {values.length > maxSpans && (
                <Box pl={1} component="span">
                    +{values.length - maxSpans}
                </Box>
            )}
        </div>
    );
};

export default withTranslation()(CalendarUserSelectAutocomplete);
