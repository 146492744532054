import L, { Marker } from 'leaflet';
import { LeafletContext } from 'react-leaflet';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import {
    getMarkerPositionText,
    getRouteEntityHoverIcon,
    getRouteEntityIcon,
    getRouteHoverIcon,
    getRouteIcon,
} from 'components/Map/markers';
import LeafletRoutePointMarker from 'components/Map/LeafletRoutePointMarker';
import { MapPage, Routing } from 'interfaces';
import {
    appointmentConfig,
    routeDesignConfigManager,
    routeDesignManager,
    routingSessionManager,
} from 'service/MapPage';
import dispatcher from '../../../dispatcher';
import events from '../../../../events';
import { isValidLocation, safeString } from 'utils';
import GeoPointKey from 'service/GeoPointKey';
import { ActivityTypedPoint } from 'interfaces/routing/route';
import { GeoStatus } from '../../../../interfaces/geo';

const startPointPin: MapPage.MarkerPinParams = {
    pinIcon: 'home-lg-alt',
    pinColor: 'black',
};

const finishPointPin: MapPage.MarkerPinParams = {
    pinIcon: 'flag-checkered',
    pinColor: 'black',
};

const simplePointPin: MapPage.MarkerPinParams = {
    pinIcon: 'map-marker-alt',
    pinColor: 'blue',
};

type DisposerFunc = () => void;

type DesignRoutePointsLayerKeys = '_isReady';

class DesignRoutePointsLayer {
    protected readonly markers: Map<string, LeafletRoutePointMarker>;
    protected startLocationMarker: Marker | null = null;
    protected finishLocationMarker: Marker | null = null;
    protected clusterGroup?: L.MarkerClusterGroup;

    private _isReady: boolean = false;

    private disposers: DisposerFunc[] = [];

    constructor() {
        makeObservable<DesignRoutePointsLayer, DesignRoutePointsLayerKeys>(this, {
            _isReady: observable,
            isReady: computed,
            createLayer: action,
            removeLayer: action,
        });

        this.markers = new Map();
    }

    get isReady(): boolean {
        return this._isReady;
    }

    createLayer(leaflet: LeafletContext) {
        this.clusterGroup = L.markerClusterGroup({
            disableClusteringAtZoom: undefined,
            zoomToBoundsOnClick: false,
        });
        this.clusterGroup.on('mouseover', this.onMouseOver);

        dispatcher.subscribe(events.CURRENT_ROUTE_MANUAL_POINT_MOUSE_ENTER, 'route_entity', this.onMouseEnter);
        dispatcher.subscribe(events.CURRENT_ROUTE_MANUAL_POINT_MOUSE_LEAVE, 'route_entity', this.onMouseLeave);
        dispatcher.subscribe(events.CURRENT_ROUTE_MAP_POINT_MOUSE_ENTER, 'route_entity', this.onMouseEnter);
        dispatcher.subscribe(events.CURRENT_ROUTE_MAP_POINT_MOUSE_LEAVE, 'route_entity', this.onMouseLeave);

        // TODO нигде не вызываются эти события
        // dispatcher.subscribe(events.LIST_VIEW_RECORD_MOUSE_ENTER, 'route_entity', this.onMouseEnterEntityPoint);
        // dispatcher.subscribe(events.LIST_VIEW_RECORD_MOUSE_LEAVE, 'route_entity', this.onMouseLeaveEntityPoint);

        this.disposers = this.createReactions();

        leaflet.map?.addLayer(this.clusterGroup);
        this._isReady = true;
    }

    removeLayer(leaflet: LeafletContext) {
        if (!this._isReady) {
            return;
        }

        dispatcher.unsubscribeFromAllEvents('route_entity');
        this.clusterGroup!.off('mouseover', this.onMouseOver);

        for (let disposerFunc of this.disposers) {
            disposerFunc();
        }

        this.markers.clear();
        this.startLocationMarker = null;
        this.finishLocationMarker = null;
        leaflet.map?.removeLayer(this.clusterGroup!);
        this.clusterGroup!.clearLayers();

        this._isReady = false;
    }

    protected createReactions(): DisposerFunc[] {
        return [
            reaction(
                () => {
                    if (!routingSessionManager.isDesignMode) {
                        return null;
                    }
                    return toJS(routeDesignManager.pointsArray);
                },
                (points) => {
                    if (points === null || points.length === 0) {
                        this.clearAllMarkers();
                    } else {
                        this.updatePoints(points);
                    }
                },
            ),
            reaction(
                () => {
                    if (!routingSessionManager.isDesignMode) {
                        return null;
                    }
                    if (routeDesignConfigManager.usersIds.length > 1) {
                        return null;
                    }
                    return appointmentConfig.myLocations.start;
                },
                (location) => {
                    if (location === null) {
                        this.clearStartLocation();
                    } else {
                        this.updateStartLocation(location);
                    }
                },
            ),
            reaction(
                () => {
                    if (!routingSessionManager.isDesignMode) {
                        return null;
                    }
                    if (routeDesignConfigManager.usersIds.length > 1) {
                        return null;
                    }
                    return appointmentConfig.myLocations.end;
                },
                (location) => {
                    if (location === null) {
                        this.clearFinishLocation();
                    } else {
                        this.updateFinishLocation(location);
                    }
                },
            ),
        ];
    }

    protected clearAllMarkers(): void {
        for (let [keyMarker, marker] of this.markers) {
            this.clusterGroup!.removeLayer(marker);
            this.markers.delete(keyMarker);
        }
    }

    private onMouseOver = (e: L.LeafletMouseEvent): void => {
        if (!e.layer.hasOwnProperty('routePointsInfo')) {
            return;
        }

        const layer = e.layer as LeafletRoutePointMarker;
        if (layer.routePointsInfo.length === 0) {
            return;
        }
        const routePointInfo = layer.routePointsInfo[0];
        if (!routePointInfo.address) {
            return;
        }

        const marker = this.markers.get(routePointInfo.keyMarker);
        if (!marker) {
            return;
        }

        const safeAddress = safeString(routePointInfo.address);
        if (marker.getTooltip() === undefined) {
            marker.bindTooltip(safeAddress);
        } else {
            marker.setTooltipContent(safeAddress);
        }
        marker.openTooltip();
    };

    private onMouseEnter = (point: ActivityTypedPoint): void => {
        const keyMarker = GeoPointKey.getKeyByPoint(point);
        const marker = this.markers.get(keyMarker);
        if (marker) {
            if (marker.routePointsInfo.length === 0) {
                return;
            }
            const routePointInfo = marker.routePointsInfo[0];
            const { position, entityId, recordId } = routePointInfo;
            const isEntity = entityId !== null && recordId !== null;
            const icon = isEntity
                ? getRouteEntityHoverIcon(position)
                : getRouteHoverIcon({
                      ...simplePointPin,
                      position,
                  });
            marker.setIcon(icon);
        }
    };

    private onMouseLeave = (point: ActivityTypedPoint): void => {
        const keyMarker = GeoPointKey.getKeyByPoint(point);
        const marker = this.markers.get(keyMarker);
        if (marker) {
            if (marker.routePointsInfo.length === 0) {
                return;
            }
            const routePointInfo = marker.routePointsInfo[0];
            const { position, entityId, recordId } = routePointInfo;
            const isEntity = entityId !== null && recordId !== null;
            const icon = isEntity
                ? getRouteEntityIcon(position)
                : getRouteIcon({
                      ...simplePointPin,
                      position,
                  });
            marker.setIcon(icon);
        }
    };

    private updateStartLocation(location: Routing.Route.MyLocation): void {
        this.clearStartLocation();
        if (location.point !== null) {
            const { point } = location;
            const icon = getRouteIcon({
                ...startPointPin,
                position: null,
            });

            const marker = new Marker([point.lat, point.lng], { icon });
            this.startLocationMarker = marker;
            this.clusterGroup!.addLayer(marker);
        }
    }

    private updateFinishLocation(location: Routing.Route.MyLocation): void {
        this.clearFinishLocation();
        if (location.point !== null) {
            const { point } = location;
            const icon = getRouteIcon({
                ...finishPointPin,
                position: null,
            });

            const marker = new Marker([point.lat, point.lng], { icon });
            this.finishLocationMarker = marker;
            this.clusterGroup!.addLayer(marker);
        }
    }

    private updatePoints(pointsArray: Routing.Route.DesignRoutePointsArray) {
        let index = 0;

        const markerTextsMap: Map<string, number[]> = new Map();
        for (let point of pointsArray) {
            if (!isValidLocation(point)) {
                index++;
                continue;
            }

            const keyMarker = point.key;
            if (!markerTextsMap.has(keyMarker)) {
                markerTextsMap.set(keyMarker, []);
            }
            markerTextsMap.get(keyMarker)!.push(++index);
        }

        const processedMarkerKeys = [];
        for (let point of pointsArray) {
            if (!isValidLocation(point)) {
                continue;
            }

            const keyMarker = point.key;
            const position = getMarkerPositionText(markerTextsMap.get(keyMarker)!);

            let entityId = null;
            let recordId = null;
            let isEntity = false;
            let geoStatus = GeoStatus.NoAddress;
            if (routeDesignManager.isEntityPoint(point)) {
                const entityPoint = point as Routing.Route.DesignRouteEntityPoint;
                entityId = entityPoint.entityId;
                recordId = entityPoint.recordId;
                isEntity = true;

                if (entityPoint.geoStatus !== undefined) {
                    geoStatus = entityPoint.geoStatus;
                }
            } else if (routeDesignManager.isProspectPoint(point)) {
                const prospectPoint = point as Routing.Route.DesignRouteProspectPoint;
                recordId = prospectPoint.recordId;
                geoStatus = GeoStatus.OkImported;
            }
            const routePointInfo: MapPage.MarkerRoutePointInfo = {
                lat: point.lat,
                lng: point.lng,
                keyMarker,
                address: point.address,
                position,
                entityId,
                recordId,
                geoStatus,
            };

            const icon = isEntity
                ? getRouteEntityIcon(position)
                : getRouteIcon({
                      ...simplePointPin,
                      position: position,
                  });

            if (!this.markers.has(keyMarker)) {
                const marker = new LeafletRoutePointMarker([point.lat, point.lng], [routePointInfo], { icon });
                this.markers.set(keyMarker, marker);
                this.clusterGroup!.addLayer(marker);
                processedMarkerKeys.push(keyMarker);
            } else {
                const marker = this.markers.get(keyMarker);
                if (!marker) {
                    continue;
                }

                if (processedMarkerKeys.indexOf(keyMarker) === -1) {
                    marker.routePointsInfo = [];
                    processedMarkerKeys.push(keyMarker);
                }

                const existRoutePointInfoIndex = marker.routePointsInfo.findIndex((markerRoutePointInfo) => {
                    return (
                        markerRoutePointInfo.keyMarker === routePointInfo.keyMarker &&
                        markerRoutePointInfo.entityId === routePointInfo.entityId &&
                        markerRoutePointInfo.recordId === routePointInfo.recordId
                    );
                });
                if (existRoutePointInfoIndex === -1) {
                    marker.addRoutePointInfo(routePointInfo);
                } else {
                    marker.routePointsInfo[existRoutePointInfoIndex] = routePointInfo;
                }
                this.markers.set(keyMarker, marker);
                marker.setIcon(icon);
            }
        }

        for (let [keyMarker, marker] of this.markers) {
            if (!markerTextsMap.has(keyMarker)) {
                this.clusterGroup!.removeLayer(marker);
                this.markers.delete(keyMarker);
            }
        }
    }

    private clearStartLocation(): void {
        if (this.startLocationMarker !== null) {
            this.clusterGroup!.removeLayer(this.startLocationMarker);
            this.startLocationMarker = null;
        }
    }

    private clearFinishLocation(): void {
        if (this.finishLocationMarker !== null) {
            this.clusterGroup!.removeLayer(this.finishLocationMarker);
            this.finishLocationMarker = null;
        }
    }
}

export default DesignRoutePointsLayer;
