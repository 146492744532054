import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import AdapterSettingsField from './AdapterSettingsField';
import { AdapterManager } from 'service/AdapterManager';
import { AdapterSettings, AdapterSettingValue, DataSourceSettings } from 'service/types';

interface Props extends WithTranslation {
    settings: DataSourceSettings;
    values: AdapterSettings;
    onSubmitSuccess?: (settings: AdapterSettings) => void;
    onSubmitError?: (message: string) => void;
    ref?: React.RefObject<any>;
}

interface State {
    settings: AdapterSettings;
    errors: Map<string, string>;
}

class AdapterSettingsGroup extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            settings: AdapterManager.initAdapterSettings(props.settings, props.values),
            errors: new Map(),
        };
    }

    handleChange = (name: string, value: AdapterSettingValue) => {
        const settings = { ...this.state.settings };
        settings[name] = value;
        this.setState({
            settings,
        });
    };

    calm() {
        this.setState({
            errors: new Map(),
        });
    }

    submit() {
        this.calm();

        try {
            this.validate();
            this.props.onSubmitSuccess && this.props.onSubmitSuccess(this.state.settings);
        } catch (error) {
            if (this.props.onSubmitError) {
                this.props.onSubmitError(error.message);
                return;
            }

            throw error;
        }

        return this.state.settings;
    }

    validate() {
        const { t } = this.props;
        const errors = new Map();
        for (let parameter of this.props.settings) {
            if (parameter.required && !this.state.settings[parameter.name]) {
                errors.set(parameter.name, t('errors.not_empty'));
            }
        }
        if (errors.size > 0) {
            this.setState({
                errors,
            });
            throw new Error('Invalid input data');
        }
    }

    render() {
        const { settings } = this.props;
        if (!Array.isArray(settings) || settings.length === 0) {
            return null;
        }

        return (
            <div>
                {settings.map((parameter, index) => {
                    const autoFocus = index === 0;

                    return (
                        <div key={parameter.name}>
                            <AdapterSettingsField
                                autoFocus={autoFocus}
                                definition={parameter}
                                value={this.state.settings[parameter.name]}
                                error={this.state.errors.get(parameter.name)}
                                onChange={this.handleChange}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(AdapterSettingsGroup);
