import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { Calendars, Calendar } from '../../interfaces/calendar/calendar';
import dispatcher from '../dispatcher';
import events from '../../events';

class CalendarManager extends BackendService {
    private accountCalendarsById: Map<number, Calendars> = new Map();

    constructor() {
        super();

        dispatcher.subscribe(
            [events.WS_CALENDAR_CHANGE, events.EVENT_DATA_SOURCE_CREATED, events.EVENT_DATA_SOURCE_DELETED],
            this,
            () => {
                this.accountCalendarsById.clear();
                dispatcher.dispatch(events.CALENDAR_UPDATED);
            },
        );
    }

    dispose(): void {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    get(accountId: number): Promise<Calendars> {
        if (this.accountCalendarsById.has(accountId)) {
            return Promise.resolve(this.accountCalendarsById.get(accountId)!);
        }

        const url = reverse(apiRoutes.account.calendars.index, { accountId });
        return this.requestApi(url, 'GET').then((calendars: Calendar[]) => {
            calendars.sort((a: Calendar, b: Calendar) => a.name.localeCompare(b.name));
            this.accountCalendarsById.set(accountId, new Map(calendars.map((calendar) => [calendar.id, calendar])));
            return this.accountCalendarsById.get(accountId);
        });
    }

    create(accountId: number, calendar: Calendar): Promise<void> {
        return this.save(accountId, calendar, true);
    }

    update(accountId: number, calendar: Calendar): Promise<void> {
        return this.save(accountId, calendar, false);
    }

    delete(accountId: number, calendarId: string): Promise<void> {
        const url = reverse(apiRoutes.account.calendars.item, { accountId, calendarId });
        return this.requestApi(url, 'DELETE').then(() => {
            const calendars = this.accountCalendarsById.get(accountId);
            if (calendars) {
                calendars.delete(calendarId);
            }
        });
    }

    private save(accountId: number, calendar: Calendar, isCreation: boolean): Promise<void> {
        const params = this.getSaveRequestParams(accountId, calendar, isCreation);
        const url = this.getSaveRequestUrl(params, isCreation);

        return this.requestApi(url, isCreation ? 'POST' : 'PUT', calendar).then(() => {
            const calendars = this.accountCalendarsById.get(accountId);
            if (calendars) {
                calendars.set(calendar.id, calendar);
                this.accountCalendarsById.set(accountId, calendars);
            }
        });
    }

    private getSaveRequestParams(accountId: number, calendar: Calendar, isCreation: boolean): object {
        if (!isCreation) {
            return { accountId, calendarId: calendar.id };
        }

        return { accountId };
    }

    private getSaveRequestUrl(params: object, isCreation: boolean): string {
        return reverse(apiRoutes.account.calendars[isCreation ? 'index' : 'item'], params);
    }
}

export const calendarManager = new CalendarManager();
