import React from 'react';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import ActionButtonItem from './ActionButtonItem';
import AbstractField from '../../../service/workflow_actions/forms/fields/AbstractField';
import Button from '../../../service/workflow_actions/forms/fields/Button';
import i18n from '../../../locales/i18n';
import AddIconButton from '../../CustomButton/AddIconButton';

const t = i18n.t.bind(i18n);

const SortableList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((item, index) => (
                <ActionButtonItem
                    index={index}
                    key={item.getApiName()}
                    field={item}
                    onChange={props.onChangeItem}
                    onRemove={props.onRemoveItem}
                />
            ))}
        </div>
    );
});

class ActionButtonsList extends React.PureComponent {
    handleAddItem = () => {
        this.props.onAddItem(new Button(true));
    };

    render() {
        return (
            <FormControl fullWidth>
                <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                    <Grid item>
                        <b>{t('workflow_actions.form.open_form.actionButtons')}</b>
                    </Grid>
                    <Grid item>
                        <AddIconButton small onClick={this.handleAddItem} />
                    </Grid>
                </Grid>
                <SortableList
                    items={this.props.items}
                    onSortEnd={this.props.onReorder}
                    useDragHandle
                    onChangeItem={this.props.onChangeItem}
                    onRemoveItem={this.props.onRemoveItem}
                    helperClass="sortable-item"
                />
            </FormControl>
        );
    }
}

ActionButtonsList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.instanceOf(AbstractField)).isRequired,
    onAddItem: PropTypes.func.isRequired,
    onChangeItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired,
};

export default ActionButtonsList;
