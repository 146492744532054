import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, DialogActions } from '@material-ui/core';
import { userManager } from '../../service/UserManager';
import { TravelingPreferencesForm } from '../UserForm/TravelingPreferences';
import WorkSchedule from '../../service/WorkSchedule';
import { WeekDayList } from '../UserForm/TravelingPreferences/WorkingDays';
import { FormBackdrop } from '../PureFormDialog/Form';
import { TravelingPreferencesMode } from '../UserForm/TravelingPreferences/TravelingPreferencesForm';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { UserDataRoutePreferences } from '../../service/types';

interface State {}

interface Props extends WithTranslation, WithSnackbarProps {
    onSubmit: (preferencesData: Object) => void;
    onClose: () => void;
}

class EditSelectedUsersModal extends React.Component<Props, State> {
    /** @ts-ignore */
    private travelingPreferencesForm: React.RefObject<TravelingPreferencesForm>;

    constructor(props: Props) {
        super(props);

        this.state = {
            isScheduleValid: true,
        };

        this.travelingPreferencesForm = React.createRef();
    }

    handleSave = () => {
        if (!this.travelingPreferencesForm.current.isValid()) {
            this.props.enqueueSnackbar(this.props.t('validation_errors'), { variant: 'error' });
            return;
        }
        const preferencesData: UserDataRoutePreferences = {
            ...this.travelingPreferencesForm.current.getUserPreferences(),
        };
        const chosenPreferences: Map<keyof UserDataRoutePreferences, boolean> =
            this.travelingPreferencesForm.current.getChosenPreferences();
        const chosenPreferencesData: Partial<UserDataRoutePreferences> = {};
        for (const field of chosenPreferences.keys()) {
            if (chosenPreferences.get(field) === false) {
                continue;
            }
            // @ts-ignore
            chosenPreferencesData[field] = preferencesData[field];
            if (field === 'breakType') {
                chosenPreferencesData['breakDuration'] = preferencesData['breakDuration'];
                chosenPreferencesData['breakEarliestTime'] = preferencesData['breakEarliestTime'];
                chosenPreferencesData['breakLatestTime'] = preferencesData['breakLatestTime'];
            }
        }
        this.props.onSubmit(chosenPreferencesData);
    };

    handleClose = () => {
        this.props.onClose();
    };

    validateWeekTimes = (workingDays: WeekDayList) => {
        this.setState({
            isScheduleValid: WorkSchedule.isScheduleValid(workingDays),
        });
    };

    render() {
        const { t } = this.props;
        const account = userManager.getCurrentAccount();

        return (
            <PureFormDialog
                open={true}
                title={t('users_traveling_preferences_dialog.edit_selected.modal.title') as string}
                onClose={this.handleClose}
                maxWidth="md"
                fullWidth
                actions={
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.handleClose}
                            data-testid="edit_selected_users.form.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                        <Button color="primary" onClick={this.handleSave} data-testid="edit_selected_users.form.save">
                            {t('button.save')}
                        </Button>
                    </DialogActions>
                }
            >
                <FormBackdrop>
                    <TravelingPreferencesForm
                        account={account}
                        ref={this.travelingPreferencesForm}
                        onScheduleChange={this.validateWeekTimes}
                        mode={TravelingPreferencesMode.MASS_EDIT}
                    />
                </FormBackdrop>
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(withSnackbar(EditSelectedUsersModal));
