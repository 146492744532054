import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';
import validator from 'validator';

export default class Text extends AbstractField {
    constructor() {
        super(FIELD_TYPES.TEXT);

        this.maxLength = null;
        this.htmlType = undefined;
        this.isMultiline = false;
        this.linesCount = 3;
    }

    setLinesCount(linesCount) {
        this.linesCount = linesCount;

        return this;
    }

    setIsBarcodeScaner(is_barcode_data) {
        this.is_barcode_data = is_barcode_data;

        return this;
    }

    setAllowTypeManual(allowTypeManual) {
        this.allowTypeManual = allowTypeManual;

        return this;
    }

    getLinesCount() {
        return this.linesCount;
    }

    setIsMultiline(isMultiline) {
        this.isMultiline = isMultiline;

        return this;
    }

    getIsMultiline() {
        return this.isMultiline;
    }

    setMaxLength(maxLength) {
        this.maxLength = maxLength;

        return this;
    }

    getMaxLength() {
        return this.maxLength ? Number.parseInt(this.maxLength) : null;
    }

    setHtmlType(htmlType) {
        this.htmlType = htmlType;

        return this;
    }

    getHtmlType() {
        return this.htmlType;
    }

    validateValue() {
        return (
            !validator.isEmpty(this.getValue(), { ignore_whitespace: true }) &&
            (this.getMaxLength() !== null ? this.getValue().length <= this.getMaxLength() : true) &&
            (this.getHtmlType() === 'email' ? validator.isEmail(this.getValue(), { ignore_whitespace: true }) : true)
        );
    }
}
