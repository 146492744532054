import i18n from 'i18next';
import LightObjectGenerator from 'service/ImportMessages/LightObjectGenerator';
import { v4 as uuidv4 } from 'uuid';
import { DataSource, ImportMessage, LEVEL, ORIGIN } from 'service/ImportMessages/types';
import { SubscriptionStatus } from 'service/types';
import { Account } from 'interfaces';

const TRIAL_SUBSCRIPTION_GEOCODE_RECORDS_PER_ENTITY_LIMIT = 100000;

const t = i18n.t.bind(i18n);

/**
 * relies on entity counters
 */
export default class GeocodingMessagesGenerator {
    public static getMessages(
        dataSource: DataSource,
        subscription: Account.Subscription | null = null,
    ): ImportMessage[] {
        if (subscription?.status !== SubscriptionStatus.TRIAL) {
            return [];
        }

        return dataSource.entityCounters
            .filter(
                ({ counters }) =>
                    counters.processing >= 0 &&
                    counters.okExact +
                        counters.okApprox +
                        counters.invalid +
                        counters.noAddress +
                        counters.okImported >=
                        TRIAL_SUBSCRIPTION_GEOCODE_RECORDS_PER_ENTITY_LIMIT,
            )
            .map(({ entity }) => ({
                id: uuidv4(),
                ds: LightObjectGenerator.getLightDataSource(dataSource),
                entity: entity,
                field: null,
                origin: ORIGIN.INTERNAL,
                httpCode: 200,
                level: LEVEL.WARNING,
                message: t('data_source.import_messages.geocoding.trial_limit', { entity: entity.label }),
                userId: null,
            }));
    }
}
