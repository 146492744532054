import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Transcript } from 'interfaces/file';
import { TextField } from '@material-ui/core';

interface TranscriptItemContentProps extends WithTranslation {
    item: Transcript.TranscriptItem;
    editMode: boolean;
    disabled: boolean;

    onChange: (content: string) => void;
}

interface TranscriptItemContentState {
    content: string | null;
}

class TranscriptItemContent extends React.Component<TranscriptItemContentProps, TranscriptItemContentState> {
    inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: TranscriptItemContentProps) {
        super(props);

        this.state = {
            content: null,
        };
    }

    handleClick = () => {
        if (!this.props.editMode || this.props.disabled) {
            return;
        }

        this.setState({ content: this.props.item.content }, () => {
            this.inputRef.current?.focus();
            this.inputRef.current?.setSelectionRange(this.props.item.content.length, this.props.item.content.length);
        });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ content: event.currentTarget.value });
    };

    saveEdit = () => {
        if (this.state.content && this.state.content !== this.props.item.content) {
            this.props.onChange(this.state.content);
        }
        this.setState({ content: null });
    };

    render() {
        const { item } = this.props;

        if (this.state.content !== null) {
            return (
                <TextField
                    value={this.state.content}
                    onChange={this.handleChange}
                    onBlur={this.saveEdit}
                    fullWidth
                    multiline
                    minRows={3}
                    variant="outlined"
                    inputRef={this.inputRef}
                />
            );
        }

        return (
            <div className="transcript-item__content" onClick={this.handleClick}>
                {item.content || <i>{this.props.t('file_input.transcript.empty')}</i>}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(TranscriptItemContent);
