import { action, makeAutoObservable } from 'mobx';
import { Routing, User } from 'interfaces';

/**
 * TODO Конфиг перемещен в RouteDesignConfigManager.
 */
class CurrentRouteManager {
    private _route: Routing.Route.Route | null = null;

    constructor() {
        makeAutoObservable<CurrentRouteManager>(this, {
            setId: action,
            setName: action,
            setPlannerApiName: action,
            setUser: action,
        });
    }

    get route(): Routing.Route.Route {
        if (this._route === null) {
            throw new Error('Route is not loaded.');
        }
        return this._route;
    }

    get id(): string | null {
        return this.route.id;
    }

    setId(id: string) {
        this.route.id = id;
    }

    get name(): string | null {
        return this.route.name;
    }

    setName(name: string) {
        this.route.name = name;
    }

    get plannerApiName(): string | null {
        return this.route.plannerApiName;
    }

    setPlannerApiName(plannerApiName: string | null): void {
        this.route.plannerApiName = plannerApiName;
    }

    get user(): User.User {
        return this.route.user;
    }

    setUser(user: User.User) {
        this.route.user = user;
    }

    reset() {
        this._route = null;
    }
}

export default CurrentRouteManager;
