import { userManager } from '../UserManager';
import { STORAGE_KEY_PREFIX } from '../../components/types';
import { addDays, isAfter, isSameDay } from 'date-fns';

class GoogleCalendarNotConnectedPopupManager {
    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.GOOGLE_CALENDAR_NOT_CONNECTED_WARNING_TIME + userManager.getCurrentUser().id;
    }

    shouldShowWarning(): boolean {
        const time = window.localStorage.getItem(this.getStorageKey());
        if (!time) {
            return true;
        }

        return isSameDay(new Date(time), new Date()) || isAfter(new Date(), new Date(time));
    }

    doNotShowUntilTomorrow(): void {
        window.localStorage.setItem(this.getStorageKey(), addDays(new Date(), 1).toISOString());
    }
}

export default new GoogleCalendarNotConnectedPopupManager();
