import subscriptionManagerFactory, { TYPE_CHANGE } from '../../../service/SubscriptionManager';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingButton from '../../LoadingButton';
import Dialog from '@material-ui/core/Dialog';
import React, { SyntheticEvent } from 'react';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Account, Subscription } from '../../../interfaces/account';
import { WithSnackbarProps } from 'notistack';
import { formatMoney } from '../MoneyFormat';
import { formatWithCommas, roundToDecimal } from '../../../utils';
import RoutingCreditSlider from './RoutingCreditSlider';
import { SUBSCRIPTION_ROUTING_MAX_PACKAGE_SIZE } from '../constants';
import { intercomManager } from '../../../service/IntercomManager';

interface ChangeRoutingCreditsProps extends WithTranslation, WithSnackbarProps {
    subscription: Subscription;
    account: Account;
    onCloseDialog: () => void;
}

interface ChangeRoutingCreditsState {
    loading: boolean;
    routingCredits: number;
    newPriceForRouting?: number;
    newSubPrice?: number;
    changeCreditsCount: number;
}

class ChangeRoutingCreditsForm extends React.Component<ChangeRoutingCreditsProps, ChangeRoutingCreditsState> {
    private readonly creditsIncrement = 1000;

    constructor(props: ChangeRoutingCreditsProps) {
        super(props);
        const routingCredits = props.subscription.additionalRoutingCredits;
        this.state = {
            loading: false,
            routingCredits,
            changeCreditsCount: 0,
        };
    }

    handleChangeRoutingCredits = (value: number) => {
        this.setState({
            routingCredits: value as number,
        });
    };

    handleConfirmChange = () => {
        dispatcher.subscribe(
            events.EVENT_SUBSCRIPTION_CHANGED,
            this,
            (data: { subscription: Subscription; type: string }) => {
                const subscription = data.subscription;
                if (this.props.account.id === null || subscription.accountId !== this.props.account.id) {
                    return;
                }
                if (data.type === TYPE_CHANGE.ROUTING_CREDITS) {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                            this.props.onCloseDialog && this.props.onCloseDialog();
                            this.props.enqueueSnackbar &&
                                this.props.enqueueSnackbar(
                                    this.props.t('billing.change_routing_credits_form.credits_changed'),
                                    { variant: 'success' },
                                );
                        },
                    );
                }
            },
        );
        const subManager = subscriptionManagerFactory.getManager(this.props.account.id);
        subManager
            .updateRoutingCredits(this.state.routingCredits)
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.setState(
                    {
                        loading: false,
                    },
                    () => {
                        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                        this.props.onCloseDialog && this.props.onCloseDialog();
                        this.props.enqueueSnackbar &&
                            this.props.enqueueSnackbar(error.message, {
                                variant: 'error',
                                persist: true,
                            });
                    },
                );
            });

        this.setState({
            loading: true,
        });
    };

    formTextAboutChange = (changeCreditsCount: number, newPriceForRouting: number) => {
        const routingCredits = this.state.routingCredits;
        const { subscription, t } = this.props;
        if (changeCreditsCount === 0) {
            return '';
        }

        const textTemplate =
            subscription.additionalRoutingCredits > routingCredits
                ? 'billing.change_routing_credits_form.decreasing'
                : 'billing.change_routing_credits_form.adding_credits';
        return t(textTemplate, {
            total: formatWithCommas(changeCreditsCount),
            sum: formatMoney(subscription.currency, newPriceForRouting),
            period: subscription.plan.period,
        });
    };

    calculateNewPrices() {
        const routingCredits = this.state.routingCredits;
        const { subscription } = this.props;
        if (subscription.additionalRoutingCredits === routingCredits) {
            return {
                newSubPrice: subscription.subPriceTotalCur,
                routingDiffPrice: subscription.routingPmCur,
                changeCreditsCount: 0,
            };
        }
        let changeCreditsCount;
        const routingPmPrice = subscription.plan.priceRoutingPm;
        if (subscription.additionalRoutingCredits > routingCredits) {
            changeCreditsCount = subscription.additionalRoutingCredits - routingCredits;
        } else {
            changeCreditsCount = routingCredits - subscription.additionalRoutingCredits;
        }
        // rounding because of math troubles in js
        const newPriceForRouting = roundToDecimal(routingPmPrice * (routingCredits / this.creditsIncrement), 2);
        const routingDiffPrice = roundToDecimal(routingPmPrice * (changeCreditsCount / this.creditsIncrement), 2);
        let newSubPrice = subscription.subPriceTotalCur - subscription.routingPmCur + newPriceForRouting;
        newSubPrice = roundToDecimal(newSubPrice, 2);

        return { routingDiffPrice, newSubPrice, changeCreditsCount };
    }

    wantMoreCredits = (e: SyntheticEvent) => {
        e.preventDefault();
        intercomManager.showNewMessage(this.props.t('billing.subscription_panel.change_routing_credits_intercom'));
    };

    render() {
        const routingCredits = this.state.routingCredits;
        const { subscription, t } = this.props;
        const plan = subscription.plan;
        const isNotChangedCredits = subscription.additionalRoutingCredits === routingCredits;
        const currency = subscription.currency;
        const routingPmPrice = subscription.plan.priceRoutingPm;
        const { routingDiffPrice, newSubPrice, changeCreditsCount } = this.calculateNewPrices();
        const textChange = this.formTextAboutChange(changeCreditsCount, routingDiffPrice);
        return (
            <Dialog className="change-routing-credits-form" open={true} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    {t('billing.change_routing_credits_form.h1')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.change_routing_credits_form.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" className="row">
                        <Grid item xs={6}>
                            {t('billing.change_seats_form.current_plan')}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>{plan.name}</strong>
                        </Grid>
                    </Grid>
                    <Grid container className="row">
                        <Grid item xs={6}>
                            {t('billing.change_routing_credits_form.current_credits')}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>{formatWithCommas(subscription.additionalRoutingCredits)}</strong>
                            <Typography variant="subtitle2">
                                {t('billing.change_routing_credits_form.price_per', {
                                    price: formatMoney(currency, routingPmPrice),
                                    period: t('billing.plan_form.period_per_' + plan.period),
                                })}
                            </Typography>
                            <Typography variant="subtitle2">
                                {t('billing.change_routing_credits_form.price_total', {
                                    price: formatMoney(subscription.currency, subscription.subPriceTotalCur),
                                    period: t('billing.plan_form.period_per_' + plan.period),
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="new-block">
                        <Grid item xs={6}>
                            {t('billing.change_routing_credits_form.new_number_credits')}
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: -4 }}>
                            <RoutingCreditSlider
                                value={this.state.routingCredits}
                                onChange={this.handleChangeRoutingCredits}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                {!isNotChangedCredits && (
                                    <Typography variant="subtitle2">
                                        {t('billing.change_seats_form.price_total', {
                                            price: formatMoney(subscription.currency, newSubPrice),
                                            period: t('billing.plan_form.period_per_' + plan.period),
                                        })}
                                    </Typography>
                                )}

                                {this.state.routingCredits === SUBSCRIPTION_ROUTING_MAX_PACKAGE_SIZE && (
                                    <Link href="#" onClick={this.wantMoreCredits}>
                                        {t('billing.subscription_panel.change_routing_credits_intercom_button')}
                                    </Link>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isNotChangedCredits && (
                        <Grid container className="row">
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', paddingTop: 4 }}>
                                    <Icon className="icon-info">info</Icon>
                                    <Typography variant="subtitle2">{textChange}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.props.onCloseDialog}
                        data-testid="change.routing_credits.button.cancel"
                    >
                        {t('button.cancel')}
                    </Button>
                    <LoadingButton
                        color="primary"
                        onClick={this.handleConfirmChange}
                        disabled={isNotChangedCredits}
                        loading={this.state.loading}
                        data-testid="change.routing_credits.button.confirm"
                    >
                        {t('button.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(ChangeRoutingCreditsForm);
