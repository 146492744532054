import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { FormActions } from '../PureFormDialog/Form';

interface Profile {
    id: number | null;
    name: string;
    code: string;
    forWhom: string;
    forSharedMap: boolean;
}

interface ProfileEditDialogProps extends WithTranslation {
    profile: Profile;
    title: string;
    onSave: (profile: Profile) => void;
    onClose: () => void;
    existingProfiles: Profile[];
    loading: boolean;
}

interface ProfileEditDialogState {
    profile: Profile;
    errors: { [key: string]: any };
}

export const FOR_WHOM_LOGGED_IN = 'loggedIn';
export const FOR_WHOM_ANONYMOUS = 'anonymous';

class ProfileEditDialog extends React.PureComponent<ProfileEditDialogProps, ProfileEditDialogState> {
    constructor(props: ProfileEditDialogProps) {
        super(props);
        this.state = {
            profile: { ...this.props.profile },
            errors: new Map(),
        };
    }

    handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { profile, existingProfiles, t } = this.props;
        const value = event.target.value;

        const errors = new Map();
        if (!value) {
            errors.set('name', t('permissions.profile_form.error'));
        }
        if (existingProfiles.find((p) => p.code !== profile.code && p.name === value)) {
            errors.set('name', t('permissions.profile_form.form.name.not_unique'));
        }

        this.setState((state: ProfileEditDialogState) => {
            return {
                profile: {
                    ...state.profile,
                    name: value,
                },
                errors,
            };
        });
    };

    handleForWhomChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState((state: ProfileEditDialogState) => {
            return {
                profile: {
                    ...state.profile,
                    forWhom: value,
                    forSharedMap: value === FOR_WHOM_ANONYMOUS,
                },
            };
        });
    };

    handleSave = () => {
        this.props.onSave(this.state.profile);
    };

    render() {
        const { title, onClose, loading, t } = this.props;
        const { profile, errors } = this.state;
        return (
            <PureFormDialog open title={title} onClose={onClose} fullWidth>
                <Grid container direction="column">
                    <Grid item>
                        <TextField
                            label={t('permissions.profile_form.form.name.label')}
                            data-testid="permissions.profile_form.form.name"
                            fullWidth
                            margin="dense"
                            name="name"
                            value={profile.name}
                            onChange={this.handleNameChange}
                            autoFocus
                            helperText={this.state.errors.get('name') || ''}
                            error={this.state.errors.has('name')}
                        />
                    </Grid>
                    {!profile.id && (
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item style={{ padding: '18px 0 18px 8px' }}>
                                    {t('permissions.create_profile.for_whom')}
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                {t('permissions.create_profile.for_whom.hint')}
                                            </React.Fragment>
                                        }
                                    >
                                        <i className="fas fa-question-circle" style={{ marginLeft: '6px' }} />
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        name="forWhom"
                                        value={profile.forWhom}
                                        onChange={this.handleForWhomChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value={FOR_WHOM_LOGGED_IN}
                                            control={<Radio color="primary" />}
                                            label={t('permissions.create_profile.logged_in')}
                                        />
                                        <FormControlLabel
                                            value={FOR_WHOM_ANONYMOUS}
                                            control={<Radio color="primary" />}
                                            label={t('permissions.create_profile.anonymous_users')}
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <FormActions>
                    <Button
                        color="primary"
                        disabled={loading || !profile.name || errors.size > 0}
                        onClick={this.handleSave}
                    >
                        {t('button.save')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ProfileEditDialog);
