import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DETAIL_BOOLEAN_EXPRESSION,
    DETAIL_HUMANIZED_EXPRESSION,
    getDetail,
} from '../../../service/WorkflowActionManager';
import cloneDeep from 'lodash/cloneDeep';
import ExpressionBuilder from '../../ExpressionBuilder';
import { expressionManager } from '../../../service/ExpressionManager';
import { FormHelperText } from '@material-ui/core';
import { CallContext } from '../../utils/CallContext';

class ExpressionBuilderForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
        };

        this.expressionBuilder = React.createRef();
    }

    submit() {
        this.setState({
            errors: new Map(),
        });

        const currentAction = cloneDeep(this.props.action);
        this.validate()
            .then(({ expression, validationResult }) => {
                if (!currentAction.details) {
                    currentAction.details = {};
                }
                currentAction.details[DETAIL_BOOLEAN_EXPRESSION] = expression;
                currentAction.details[DETAIL_HUMANIZED_EXPRESSION] = validationResult;
                this.props.onSubmitSuccess(currentAction);
            })
            .catch((errors) => {
                this.setState({
                    errors,
                });
                this.props.onSubmitError && this.props.onSubmitError();
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            this.expressionBuilder.current.clearErrors();
            this.expressionBuilder.current.getExpression().then((result) => {
                const errors = new Map();
                if (!result || !result.expression) {
                    errors.set('expression', this.props.t('expresion_builder.wrong_expression'));
                }
                if (errors.size === 0) {
                    resolve(result);
                } else {
                    reject(errors);
                }
            });
        });
    }

    validateExpression = (expression) => {
        return expressionManager.toFormula(expression, this.props.action.callContext);
    };

    render() {
        if (this.props.loading) {
            return 'loading...';
        }
        return (
            <React.Fragment>
                <form noValidate autoComplete="off">
                    <ExpressionBuilder
                        callContext={this.props.action.callContext}
                        onValidate={this.validateExpression}
                        defaultExpression={getDetail(DETAIL_BOOLEAN_EXPRESSION, this.props.action.details, [])}
                        ref={this.expressionBuilder}
                        showGroupByPrefix
                        addSpecialSection
                        enableFormulaMode
                        specialSectionOptions={{
                            currentDirectDistance: this.props.t('expresion_builder.special.current_direct_distance'),
                        }}
                    />
                    {this.state.errors.has('expression') && (
                        <FormHelperText error>{this.state.errors.get('expression')}</FormHelperText>
                    )}
                </form>
            </React.Fragment>
        );
    }
}

ExpressionBuilderForm.propTypes = {
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(ExpressionBuilderForm);
