import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { Grid, Icon, IconButton, Switch, Tooltip } from '@material-ui/core';
import DottedLink from '../../DottedLink/index';
import Confirmation from '../../Confirmation/index';
import ActionIcon from '../../WorkflowActions/ActionIcon';
import DataTable, { RequestData, RequestParams } from '../../DataTable/DataTable';
import { userManager } from '../../../service/UserManager';
import { Schedule, ScheduleFrequency } from './types';
import { IDataSource } from '../../../service/types';
import { DataTableColumn, DataTableProps, DataTableState } from '../../DataTable/types';
import { utcToUserTimezone } from '../../../utils';

interface CurrentProps extends DataTableProps<Schedule>, WithTranslation {
    loading: boolean;
    dataSources: IDataSource[];
    onRequestData: (requestParams: RequestParams) => Promise<RequestData<Schedule>>;
    onEdit: (schedule: Schedule) => void;
    onRemove: (schedule: Schedule) => void;
    onToggleActive: (schedule: Schedule) => void;
}

interface CurrentState extends DataTableState<Schedule> {}

class ScheduleList extends DataTable<Schedule, CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.pageSizes = [25, 50, 100];
        this.defaultPageSize = 25;
    }

    buildStructure(fields: DataTableColumn<Schedule>[]) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'isActive', width: 60, align: 'center' },
            { columnName: 'remove', width: 100, align: 'center' },
            { columnName: 'triggerTypes', width: '15%' },
            { columnName: 'actions', width: '20%' },
            { columnName: 'updatedBy', width: '10%' },
            { columnName: 'updatedAt', width: '10%' },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'actions', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c) => c.title).map((c) => c.title as string);
        return structure;
    }

    requestData(ignorePage = false, _parentTimer = null) {
        return this.props.onRequestData({
            filters: this.getFilters(),
            sorting: this.sorting,
            currentPage: ignorePage ? 0 : this.state.pagination.current,
            pageSize: this.state.pagination.size,
        });
    }

    getFields() {
        return Promise.resolve<DataTableColumn<Schedule>[]>([
            {
                columnName: 'isActive',
                title: null,
                getCellValue: (schedule) => (
                    <Tooltip title={this.renderDisabledTooltip(schedule)}>
                        <FormControlLabel
                            className=""
                            control={
                                <Switch
                                    checked={schedule.isActive}
                                    onChange={this.props.onToggleActive.bind(this, schedule)}
                                    disabled={schedule.disabled || !userManager.automationElementsManagement()}
                                    color="primary"
                                    data-testid="automation_elements.toggle_schedule_disabled"
                                />
                            }
                            label=""
                        />
                    </Tooltip>
                ),
            },
            {
                columnName: 'name',
                type: 'string',
                title: this.props.t('automation.schedule.list.name'),
                getCellValue: (schedule) => (
                    <DottedLink
                        onClick={this.props.onEdit.bind(this, schedule)}
                        data-testid="automation.schedule.list.name"
                    >
                        {schedule.name}
                    </DottedLink>
                ),
            },
            {
                columnName: 'startDateTime',
                type: 'datetime',
                title: this.props.t('automation.schedule.list.start_datetime'),
                getCellValue: (schedule) => (
                    <span>{utcToUserTimezone(schedule.startDateTime, userManager.getCurrentUser())}</span>
                ),
            },
            {
                columnName: 'frequency',
                type: 'text[]',
                title: this.props.t('automation.schedule.list.frequency'),
                picklist: Object.values(ScheduleFrequency).map((value) => ({
                    value,
                    label: this.props.t('automation.schedule.form.frequency.' + value),
                })),
                getCellValue: (schedule) => {
                    if (schedule.frequency === ScheduleFrequency.Weekly && schedule.daysOfWeek) {
                        const daysOfWeek = schedule.daysOfWeek
                            .map((dayOfWeek) => this.props.t('weekday.' + dayOfWeek))
                            .join(', ');
                        return <span>{this.props.t('automation.schedule.list.frequency.weekly', { daysOfWeek })}</span>;
                    }
                    if (schedule.frequency === ScheduleFrequency.Custom && schedule.cronExpression) {
                        return (
                            <span>
                                {this.props.t('automation.schedule.list.frequency.custom', {
                                    cronExpression: schedule.cronExpression,
                                })}
                            </span>
                        );
                    }
                    return <span>{this.props.t('automation.schedule.form.frequency.' + schedule.frequency)}</span>;
                },
            },
            {
                columnName: 'actions',
                type: 'string',
                title: this.props.t('automation.schedule.list.actions'),
                getCellValue: (schedule) =>
                    schedule.groups.map((group) => {
                        return group.mappings.map((mapping: any) => {
                            return mapping && mapping.action ? (
                                <Grid container direction="row" alignItems="center">
                                    <ActionIcon type={mapping.action.type} />
                                    {mapping.action.name}
                                </Grid>
                            ) : null;
                        });
                    }),
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: this.props.t('automation.schedule.list.updated_by'),
                getCellValue: (schedule) => <span>{schedule.updatedBy ? schedule.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: this.props.t('automation.schedule.list.updated_at'),
                getCellValue: (schedule) => (
                    <span>{utcToUserTimezone(schedule.startDateTime, userManager.getCurrentUser())}</span>
                ),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (schedule) => (
                    <Confirmation
                        text={this.props.t('automation.schedule.confirm.remove')}
                        onConfirm={this.props.onRemove.bind(this, schedule)}
                    >
                        <Tooltip title={this.props.t<string>('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="automation.schedule.confirm"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ]);
    }

    renderDisabledTooltip = (schedule: Schedule) => {
        if (!schedule.disabled) {
            return '';
        }
        const objectPrefix = this.props.t<string>('automation_elements.disabled_object');
        const fieldPrefix = this.props.t<string>('automation_elements.disabled_field');
        return (
            <React.Fragment>
                <span>{this.props.t<string>('automation_elements.schedule_disabled')}</span>
                <ul>
                    {schedule.disabledEntities.map((entity) => (
                        <li>{`${objectPrefix} ${entity}`}</li>
                    ))}
                    {schedule.disabledFields.map((field) => (
                        <li>{`${fieldPrefix} ${field}`}</li>
                    ))}
                </ul>
            </React.Fragment>
        );
    };
}

export default withTranslation()(ScheduleList);
