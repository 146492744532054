import {
    PagingContainer,
    regularPaddingCellStyles,
    rightLabelHeaderStyles,
    StyledTooltipCell,
} from '../EntityDataTable/SearchDataTable';
import { FrontDataTable } from '../EntityDataTable/FrontDataTable';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import React from 'react';
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
    DataTypeProvider,
    FilteringState,
    PagingState,
    SortingState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    CustomPaging,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    BooleanFilterCell,
    DateFilterCell,
    DateTimeFilterCell,
    FilterIcon,
    HeaderCellContent,
    NumericFilterCell,
    StringFilterCell,
} from '../DataTable';

import BooleanFormatter from '../DataTable/Formatter/BooleanFormatter';
import WebLinkFormatter from '../DataTable/Formatter/WebLinkFormatter';

import { TableLoadingState } from '../TableLoadingState';
import { withStyles } from '@material-ui/core/styles';
import { prospectingManager } from '../../service/ProspectingManager';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import { userManager } from '../../service/UserManager';
import { formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMAT_DATEFNS } from '../../utils';
import { FieldType } from '../types';

const ExportStatuses = {
    any: 'any',
    exported: 'Exported',
    notExported: 'Not Exported',
};

class ProspectingFrontDataTable extends FrontDataTable {
    constructor(props) {
        super(props);
        this.state.filters = [];
        this.state.filterExtensions = [];
        this.loadDataDebounce = debounce(() => this.loadData(), 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ids !== this.props.ids || prevProps.bounds !== this.props.bounds) {
            this.loadDataDebounce();
        }
        if (this.state.records?.length) {
            this.props.onRecordsLoaded(this.state.records, this.state.records.length);
        }
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_PROSPECTING_POINTS_ADDED], this, () => {
            this.loadData();
        });
        dispatcher.subscribe(
            [events.EVENT_PROSPECTING_POINTS_RELOADED, events.EVENT_PROSPECTING_POINTS_REMOVED],
            this,
            () => {
                this.loadData(false);
            },
        );
        dispatcher.subscribe([events.EVENT_PROSPECTING_DISABLED, events.EVENT_PROSPECTING_ENABLED], this, () => {
            this.setState({
                structure: null,
                records: null,
                filters: [],
                filterExtensions: [],
            });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadData(clearFilter = true) {
        const bounds = this.props.bounds;
        const ids = Array.isArray(this.props.ids) ? new Set(this.props.ids) : null;
        let records =
            bounds !== null && bounds !== undefined
                ? prospectingManager.getRecordsByBounds(bounds)
                : prospectingManager.getRecords();
        if (ids) {
            records = records.filter((record) => {
                return ids.has(record.uuid);
            });
        }

        const recordsStructure = prospectingManager.getRecordsStructure();
        const timezone = userManager.currentUser?.actualTimezone;
        const routes = this.props.routes;
        const isMultipleRoutes = routes?.length > 1;

        if (ids && routes !== undefined) {
            const dataMap = new Map();
            routes.forEach((route) =>
                route.activities.forEach(({ entityId, recordId }, index) => {
                    if (!recordId || entityId || dataMap.has(recordId)) {
                        return;
                    }

                    dataMap.set(recordId, {
                        activityNumber: index,
                        activityDate: formatInTimeZone(route.dateStartAt, timezone, DATE_FORMAT_DATEFNS),
                        activityUser: route.user.name,
                    });
                }),
            );

            records = records.map((record) => {
                return {
                    ...record,
                    activityNumber: dataMap.get(record.uuid)?.activityNumber,
                    activityDate: dataMap.get(record.uuid)?.activityDate,
                    activityUser: dataMap.get(record.uuid)?.activityUser,
                };
            });

            if (isMultipleRoutes) {
                recordsStructure.unshift(
                    {
                        name: 'activityDate',
                        columnName: 'activityDate',
                        title: this.props.t('entity_data_table.front_data_table.columns.point_date'),
                        type: FieldType.DATETIME,
                        isPin: false,
                        isLink: false,
                    },
                    {
                        name: 'activityUser',
                        columnName: 'activityUser',
                        title: this.props.t('entity_data_table.front_data_table.columns.point_user'),
                        type: FieldType.STRING,
                        isPin: false,
                        isLink: false,
                    },
                );
            }

            recordsStructure.unshift({
                name: 'activityNumber',
                columnName: 'activityNumber',
                title: this.props.t('entity_data_table.front_data_table.columns.point_number'),
                type: FieldType.INTEGER,
                isPin: false,
                isLink: false,
            });
        }

        const structure = this.buildStructure(recordsStructure);

        let filterExtensions = [{ columnName: ExportStatusField, predicate: ExportStatusFilterPredicate }];
        structure.booleanColumns.forEach((name) => {
            filterExtensions.push({ columnName: name, predicate: BooleanFilterPredicate });
        });

        structure.stringColumns.forEach((name) => {
            if (!filterExtensions.find((item) => item.columnName === name)) {
                filterExtensions.push({
                    columnName: name,
                    predicate: (value, filter, row) => {
                        if (!filter.value.length) {
                            return true;
                        }
                        if (filter && filter.operation === 'isEmpty') {
                            return value === undefined || value === null || value === '' || value === ' ';
                        }
                        if (filter && filter.operation === 'isNotEmpty') {
                            return value !== undefined && value !== null && value !== '' && value !== ' ';
                        }
                        if (filter && filter.operation === 'clearFilter') {
                            return true;
                        }

                        return IntegratedFiltering.defaultPredicate(value, filter, row);
                    },
                });
            }
        });
        const filters = clearFilter ? [] : [...this.state.filters];
        this.setState({
            records,
            structure,
            filters,
            filterExtensions,
        });
    }

    loadCsv() {}

    buildStructure(fields) {
        const structure = super.buildDefaultStructure(fields);

        structure.pinColumn = 'address';
        return structure;
    }

    handleFiltersChanged = (filters) => {
        this.setState(
            {
                filters,
            },
            () => {
                this.props.onApplyFilters && this.props.onApplyFilters(filters);
                this.props.onSelectionChange && this.props.onSelectionChange(new Map());
            },
        );
    };

    clearFilters = () => {
        this.setState(
            {
                filters: [],
            },
            () => {
                this.props.onApplyFilters && this.props.onApplyFilters([]);
                this.props.onSelectionChange && this.props.onSelectionChange(new Map());
            },
        );
    };

    render() {
        const recordsAreLoading = this.state.records === null;

        const { structure, pagination } = this.state;
        const { t } = this.props;

        if (structure === null) {
            return <div>{t('loading')}</div>;
        }

        const selection = this.getSelection();

        return (
            <React.Fragment>
                <div style={{ display: 'none' }}>
                    <DataGrid rows={[]} columns={structure.columns}>
                        <PagingState
                            currentPage={pagination.current}
                            onCurrentPageChange={this.handleCurrentPageChanged}
                            onPageSizeChange={this.handlePageSizeChanged}
                            pageSize={pagination.size}
                        />
                        <CustomPaging totalCount={this.state.totalCount} />
                        <PagingPanel
                            pageSizes={this.pageSizes}
                            containerComponent={PagingContainer}
                            messages={this.pagingPanelMessages}
                        />
                    </DataGrid>
                </div>
                <DataGrid rows={this.state.records || []} columns={structure.columns} getRowId={(row) => row.id}>
                    <SortingState columnExtensions={[{ columnName: 'actions', sortingEnabled: false }]} />
                    <FilteringState onFiltersChange={this.handleFiltersChanged} filters={this.state.filters} />
                    <IntegratedSorting />
                    <IntegratedFiltering columnExtensions={this.state.filterExtensions} />
                    <PagingState
                        currentPage={pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={pagination.size}
                    />
                    <IntegratedPaging />
                    <DataTypeProvider
                        for={structure.numericColumns}
                        availableFilterOperations={this.numericFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.stringColumns}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateColumns}
                        availableFilterOperations={this.dateFilterOperations}
                    />
                    <DataTypeProvider for={structure.booleanColumns} formatterComponent={BooleanFormatter} />
                    <DataTypeProvider
                        for={structure.webLinkColumns}
                        formatterComponent={WebLinkFormatter}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <Table
                        columnExtensions={structure.exts}
                        cellComponent={StyledTooltipCell}
                        rowComponent={StyledRow}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={structure.columns.length + 1} loading={recordsAreLoading} />
                        )}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        contentComponent={HeaderCellContent}
                        sortLabelComponent={withStyles(rightLabelHeaderStyles)(TableHeaderRow.SortLabel)}
                        showSortingControls
                    />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        cellComponent={StyledFilterCell}
                        messages={this.filterMessages}
                    />
                    <SelectionState selection={selection} onSelectionChange={this.handleSelectionChange} />
                    <IntegratedSelection />

                    <TableSelection
                        showSelectAll
                        highlightRow
                        cellComponent={this.cellSelectedComponent}
                        rowComponent={StyledRow}
                        headerCellComponent={this.headerCellSelectedComponent}
                    />
                    <PagingPanel
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                        messages={this.pagingPanelMessages}
                    />
                </DataGrid>
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ProspectingFrontDataTable);

const ExportStatusFilterCell = (props) => {
    const { filter, onFilter, classes, filteringEnabled, t } = props;

    const handleFilter = (e) => {
        const value = e.target.value;
        if (!filteringEnabled || value === ExportStatuses.any) {
            onFilter(null);
            return;
        }
        onFilter({
            value: e.target.value,
        });
    };

    return (
        <TableCell className={classes.cell}>
            <Select
                value={filter ? filter.value : ExportStatuses.any}
                displayEmpty
                onChange={handleFilter}
                fullWidth
                disabled={!filteringEnabled}
                data-testid="prospecting.prospecting_front_data_table.status"
            >
                <MenuItem value={ExportStatuses.any} data-testid="prospecting.prospecting_front_data_table.status.any">
                    {t('prospecting.prospecting_front_data_table.status.any')}
                </MenuItem>
                <MenuItem
                    value={ExportStatuses.exported}
                    data-testid="prospecting.prospecting_front_data_table.status.exported"
                >
                    {t('prospecting.prospecting_front_data_table.status.exported')}
                </MenuItem>
                <MenuItem
                    value={ExportStatuses.notExported}
                    data-testid="prospecting.prospecting_front_data_table.status.not_exported"
                >
                    {t('prospecting.prospecting_front_data_table.status.not_exported')}
                </MenuItem>
            </Select>
        </TableCell>
    );
};

const TranslatedExportStatusFilterCell = withTranslation()(ExportStatusFilterCell);

const ExportStatusField = 'exportStatus';

const ExportStatusFilterPredicate = (value, filter) => {
    return value === filter.value;
};

const BooleanFilterPredicate = (value, filter) => {
    // eslint-disable-next-line
    return value == filter.value;
};

const FilterCell = (props) => {
    const { column } = props;

    if (column.name === 'actions') {
        return <TableCell className={props.classes.cell} />;
    }
    if (column.name === ExportStatusField) {
        return <TranslatedExportStatusFilterCell {...props} />;
    }
    if (column.type === 'boolean') {
        return <BooleanFilterCell {...props} />;
    }
    if (column.type === 'integer' || column.type === 'bigint' || column.type === 'float') {
        return <NumericFilterCell {...props} />;
    }
    if (column.type === 'date') {
        return <DateFilterCell {...props} />;
    }
    if (column.type === 'datetime') {
        return <DateTimeFilterCell {...props} />;
    }
    return <StringFilterCell {...props} />;
};

const Row = (props) => {
    const { children, classes, selectByRowClick, highlighted, ...rest } = props;

    let className = '';
    const status = rest.tableRow.row[ExportStatusField];
    switch (status) {
        case ExportStatuses.exported:
            className = classes.exported;
            break;
        case ExportStatuses.notExport:
            className = classes.notExport;
            break;
        default:
            break;
    }

    return (
        <Table.Row {...rest} className={className}>
            {children}
        </Table.Row>
    );
};

const exportStatusStyles = {
    notExport: {},
    exported: {
        backgroundColor: '#d1ffc3',
    },
};

ProspectingFrontDataTable.defaultProps = {
    onRecordsLoaded: () => {},
    onEntityUpdated: () => {},
    onEntityDeleted: () => {},
};

const rightIconCellStyles = {
    flexContainer: {
        flexFlow: 'row-reverse',
    },
};

export const StyledRow = withStyles(exportStatusStyles)(Row);
export const StyledFilterCell = withStyles(rightIconCellStyles)(FilterCell);
