import React from 'react';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import PropTypes from 'prop-types';
import PaymentHistory from './PaymentHistory';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid as DataGrid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { DataTable } from '../DataTable';
import { withTranslation } from 'react-i18next';
import DateTime from '../DateTime';

class SubscriptionHistory extends PaymentHistory {
    constructor(props) {
        super(props);
        this.subManager = subscriptionManagerFactory.getManager(this.props.subscription.accountId);
        this.pagingPanelMessages = DataTable.getPagingMessages(props.t);
    }

    getFields() {
        const { t, fields } = this.props;
        if (Array.isArray(fields)) {
            return Promise.resolve(fields);
        }
        return Promise.resolve([
            {
                name: 'validFrom',
                title: t('billing.subscription_history.table.valid_from'),
                type: 'date',
                getCellValue: (row) => (
                    <span>
                        <DateTime>{row.validFrom}</DateTime>
                    </span>
                ),
            },
            {
                name: 'validTo',
                title: t('billing.subscription_history.table.valid_to'),
                type: 'date',
                getCellValue: (row) => (
                    <span>
                        <DateTime>{row.validTo}</DateTime>
                    </span>
                ),
            },
            {
                title: t('billing.subscription_history.table.plan'),
                type: 'string',
                getCellValue: (row) => <span>{row.plan.name}</span>,
            },
            {
                name: 'status',
                title: t('billing.subscription_history.table.status'),
                type: 'string',
            },
            {
                name: 'paddleStatus',
                title: t('billing.subscription_history.table.paddle_status'),
                type: 'string',
            },
            {
                name: 'paddleSubscriptionId',
                title: t('billing.subscription_history.table.paddle_id'),
                type: 'string',
            },
            {
                name: 'usersPurchased',
                title: t('billing.subscription_history.table.user_purchased'),
                type: 'number',
            },
            {
                name: 'userAllowed',
                title: t('billing.subscription_history.table.user_allowed'),
                type: 'number',
            },
            {
                name: 'currency',
                title: t('billing.subscription_history.table.currency'),
                type: 'string',
            },
            {
                name: 'subPriceTotalCur',
                title: t('billing.subscription_history.table.full_amount'),
                type: 'number',
            },
            {
                name: 'subPriceTotalUsd',
                title: t('billing.subscription_history.table.full_usd_amount'),
                type: 'number',
            },
            {
                name: 'recordPmCur',
                title: t('billing.subscription_history.table.record_pm'),
                type: 'number',
            },
            {
                name: 'recordPmUsd',
                title: t('billing.subscription_history.table.record_pm_usd'),
                type: 'number',
            },
            {
                name: 'adminPmCur',
                title: t('billing.subscription_history.table.admin_pm'),
                type: 'number',
            },
            {
                name: 'adminPmUsd',
                title: t('billing.subscription_history.table.admin_pm_usd'),
                type: 'number',
            },
            {
                name: 'recordCapFree',
                title: t('billing.subscription_history.table.record_cap_free'),
                type: 'number',
            },
            {
                name: 'recordChunksPurchased',
                title: t('billing.subscription_history.table.record_chunk_purchased'),
                type: 'number',
            },
            {
                name: 'recordChunkPmCur',
                title: t('billing.subscription_history.table.record_chunk_pm_price'),
                type: 'number',
            },
            {
                name: 'unusedPlannerCredits',
                title: t('billing.subscription_history.table.unused_planner_credits'),
                type: 'number',
            },
            {
                name: 'exportProspectMonthly',
                title: t('billing.subscription_history.table.export_prospect_monthly'),
                type: 'number',
            },
            {
                name: 'additionalCustomRoutingCredits',
                title: t('billing.subscription_history.table.additional_custom_routing_credits'),
                type: 'number',
            },
            {
                name: 'monthlyPlannerCredits',
                title: t('billing.subscription_history.table.monthly_planner_credits'),
                type: 'number',
            },
            {
                name: 'recordChunkSize',
                title: t('billing.subscription_history.table.record_chunk_size'),
                type: 'number',
            },
            {
                name: 'customSharedMapsPmCur',
                title: t('billing.edit_form.custom_shared_maps_price'),
                type: 'number',
            },
            {
                name: 'sharedMapsPmCur',
                title: t('billing.edit_form.shared_maps_price'),
                type: 'number',
            },
            {
                name: 'planerPmCur',
                title: t('billing.edit_form.planer_price'),
                type: 'number',
            },
            {
                name: 'routingPmCur',
                title: t('billing.edit_form.routing_price'),
                type: 'number',
            },
            {
                name: 'analyticsPmCur',
                title: t('billing.edit_form.analytics_price'),
                type: 'number',
            },
            {
                name: 'prospectPmCur',
                title: t('billing.edit_form.prospecting_price'),
                type: 'number',
            },
            {
                name: 'additionalAiTokens',
                title: t('billing.edit_form.section.ai.additional_custom_tokens'),
                type: 'number',
            },
            {
                name: 'aiTokensPmCur',
                title: t('billing.edit_form.section.ai.tokens_pm_cur'),
                type: 'number',
            },
            {
                name: 'aiUsers',
                title: t('billing.edit_form.section.ai.users'),
                type: 'number',
            },
            {
                name: 'aiUsersPmCur',
                title: t('billing.edit_form.section.ai.users_pm_cur'),
                type: 'number',
            },
            {
                name: 'nextBillDate',
                title: t('billing.subscription_history.table.next_billing_date'),
                type: 'datetime',
                getCellValue: (row) => (
                    <span>{row.nextBillDate !== null && <DateTime>{row.nextBillDate}</DateTime>}</span>
                ),
            },
        ]);
    }

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            {
                columnName: 'validFrom',
                width: 180,
            },
            {
                columnName: 'validTo',
                width: 180,
            },
            {
                columnName: 'nextBillDate',
                width: 180,
            },
        ];
        return structure;
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.accountId !== this.props.accountId ||
            JSON.stringify(prevProps.subscription) !== JSON.stringify(this.props.subscription)
        ) {
            this.loadData();
        }
    }

    requestData(ignorePage = false, parentTimer = null) {
        return this.subManager.listSubscriptionHistory(
            ignorePage ? 0 : this.state.pagination.current * this.state.pagination.size,
            this.state.pagination.size,
        );
    }

    render() {
        const { t } = this.props;
        const structure = this.state.structure;
        const recordsAreLoading = this.state.records === null;
        const showTable = structure !== null;
        return (
            <Dialog open={this.props.openHistory} scroll="body" maxWidth="lg">
                <DialogTitle>
                    {t('billing.subscription_history.title')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.subscription_history.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {!showTable ? (
                        <div>loading...</div>
                    ) : (
                        <DataGrid className="c-data-table" rows={this.state.records || []} columns={structure.columns}>
                            <Table
                                columnExtensions={structure.exts}
                                noDataCellComponent={() => (
                                    <TableLoadingState
                                        columnCount={structure.columns.length}
                                        loading={recordsAreLoading}
                                    />
                                )}
                            />
                            <TableHeaderRow />
                            <PagingState
                                currentPage={this.state.pagination.current}
                                onCurrentPageChange={this.handleCurrentPageChanged}
                                onPageSizeChange={this.handlePageSizeChanged}
                                pageSize={this.state.pagination.size}
                            />
                            <CustomPaging totalCount={this.state.totalCount} />
                            <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
                        </DataGrid>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

SubscriptionHistory.propTypes = {
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    openHistory: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func,
    fields: PropTypes.array,
};

export default withTranslation('translations', { withRef: true })(SubscriptionHistory);
