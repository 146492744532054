import { AccountData, IDataSource, UserData } from '../types';
import dsManagerFactory from '../DsManager';
import { accountsManager } from '../AccountsManager';
import forEach from 'lodash/forEach';
import { PERMISSION_HIDE, PERMISSION_MODIFY, PERMISSION_VIEW } from '../../components/Permissions/constants';

enum PermissionLevel {
    hide = PERMISSION_HIDE,
    view = PERMISSION_VIEW,
    modify = PERMISSION_MODIFY,
}

type AccountId = number;
type EntityId = number;
type FieldId = number;
type RoleCode = string;

type Access = {
    isAvailable: boolean;
    isRecordSharingAvailable: boolean;
    ownerPermission: PermissionLevel;
    nonOwnerPermission: PermissionLevel;
};

type Permission = {
    access: Map<RoleCode, Access>;
};

type FieldPermission = Permission;

type EntityPermission = Permission & {
    fields: Map<FieldId, FieldPermission>;
};

class EntitiesPermissionsManager {
    private permissionsMap: Map<AccountId, Map<EntityId, EntityPermission>> = new Map();

    loadPermissions(account: AccountData, force: boolean = false): Promise<void> {
        if (this.arePermissionsLoaded(account.id) && !force) {
            return Promise.resolve();
        }
        return Promise.all([
            dsManagerFactory.getManager(account.id).list(),
            accountsManager.getPermissions(account.id),
        ]).then((result) => {
            const dataSources = result[0].map((ds: IDataSource) => {
                const entities = [];
                for (let e of ds.entityCounters) {
                    entities.push({
                        id: e.entity.id,
                        label: e.entity.label,
                        fields: e.entity.fields,
                    });
                }
                return { id: ds.id, name: ds.name, entities };
            });

            let processPermissions = (permissions: any) => {
                const result: any = {};
                for (let permission of permissions) {
                    result[permission.role.code] = result[permission.role.code] || {};

                    const ownerFieldPermissions: any = {};
                    const nonOwnerFieldPermissions: any = {};
                    for (let fieldSetting of permission.settings) {
                        ownerFieldPermissions[fieldSetting.fieldId] = fieldSetting.ownerFieldPermission;
                        nonOwnerFieldPermissions[fieldSetting.fieldId] = fieldSetting.nonOwnerFieldPermission;
                    }
                    result[permission.role.code][permission.entity.id] = {
                        isAvailable: permission.isAvailable,
                        isRecordSharingAvailable: permission.isRecordSharingAvailable,
                        ownerPermission: permission.ownerPermission,
                        nonOwnerPermission: permission.nonOwnerPermission,
                        ownerFieldPermissions,
                        nonOwnerFieldPermissions,
                    };
                }
                return result;
            };

            const permissions = processPermissions(result[1]);

            const accessMap = new Map();
            dataSources.forEach((ds: { entities: any[] }) => {
                ds.entities.forEach((entity) => {
                    const fieldsMap = new Map();
                    forEach(entity.fields, (field) => {
                        const access: Map<RoleCode, Access> = new Map();
                        forEach(permissions, (rolePermissions, roleCode) => {
                            if (rolePermissions[entity.id]) {
                                let ownerFieldPermission = rolePermissions[entity.id].ownerFieldPermissions[field.id];
                                if (undefined === ownerFieldPermission) {
                                    ownerFieldPermission = rolePermissions[entity.id].ownerPermission;
                                }
                                let nonOwnerFieldPermission =
                                    rolePermissions[entity.id].nonOwnerFieldPermissions[field.id];
                                if (undefined === nonOwnerFieldPermission) {
                                    nonOwnerFieldPermission = rolePermissions[entity.id].nonOwnerPermission;
                                }
                                access.set(roleCode, {
                                    isAvailable: true,
                                    isRecordSharingAvailable: rolePermissions[entity.id].isRecordSharingAvailable,
                                    ownerPermission: ownerFieldPermission,
                                    nonOwnerPermission: nonOwnerFieldPermission,
                                });
                            }
                        });

                        fieldsMap.set(field.id, { access });
                    });

                    const access: Map<RoleCode, Access> = new Map();
                    forEach(permissions, (rolePermissions, roleCode) => {
                        if (rolePermissions[entity.id]) {
                            access.set(roleCode, {
                                isAvailable: rolePermissions[entity.id].isAvailable,
                                isRecordSharingAvailable: rolePermissions[entity.id].isRecordSharingAvailable,
                                ownerPermission: rolePermissions[entity.id].ownerPermission,
                                nonOwnerPermission: rolePermissions[entity.id].nonOwnerPermission,
                            });
                        }
                    });

                    accessMap.set(entity.id, {
                        access,
                        fields: fieldsMap,
                    });
                });
            });

            this.permissionsMap.set(account.id, accessMap);
        });
    }

    hasPermission(
        user: UserData,
        permissionLevel: number = PERMISSION_MODIFY,
        isOwner: boolean,
        entityId: number,
        fieldId?: number | undefined,
    ): boolean {
        const entityPermission = this.getEntityPermission(user, entityId);
        if (!entityPermission) {
            return false;
        }

        const entityAccess = entityPermission.access.get(user.role.code);
        if (!entityAccess) {
            return false;
        }

        if (!entityAccess.isAvailable) {
            return false;
        }

        if (!fieldId) {
            if (isOwner) {
                return entityAccess.ownerPermission >= permissionLevel;
            } else {
                return entityAccess.nonOwnerPermission >= permissionLevel;
            }
        }

        const fieldPermission = entityPermission.fields.get(fieldId);
        if (!fieldPermission) {
            return false;
        }

        const fieldAccess = fieldPermission.access.get(user.role.code);
        if (!fieldAccess) {
            return false;
        }

        if (!fieldAccess.isAvailable) {
            return false;
        }

        if (isOwner) {
            return fieldAccess.ownerPermission >= permissionLevel;
        } else {
            return fieldAccess.nonOwnerPermission >= permissionLevel;
        }
    }

    arePermissionsLoaded(accountId: number): boolean {
        return this.permissionsMap.has(accountId);
    }

    hasRecordSharingRules(user: UserData, entityId: number): boolean {
        const entityPermission = this.getEntityPermission(user, entityId);
        if (!entityPermission) {
            return false;
        }

        const entityAccess = entityPermission.access.get(user.role.code);
        if (!entityAccess) {
            return false;
        }

        return entityAccess.isAvailable && entityAccess.isRecordSharingAvailable;
    }

    private getEntityPermission(user: UserData, entityId: number): EntityPermission | null {
        if (user.accountId === null) {
            return null;
        }

        const accessMap = this.permissionsMap.get(user.accountId);
        if (!accessMap) {
            return null;
        }

        const entityPermission = accessMap.get(entityId);

        return entityPermission || null;
    }
}

const entitiesPermissionsManager = new EntitiesPermissionsManager();
export default entitiesPermissionsManager;
