import React, { Suspense } from 'react';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { intercomManager } from '../../service/IntercomManager';
import { userManager } from '../../service/UserManager';
import * as Sentry from '@sentry/browser';
import { STAGES } from '../SelfSignUp/SSUManager';
import { reverse, routes } from '../../routes';
import { isMapPage, logDebug, setCssCustomHeight, setCssCustomWidth, weAreInNativeApp } from '../../utils';
import BgGeoManager from '../../service/BgGeo/BgGeoManager';
import { Deploy } from 'cordova-plugin-ionic';
import InternetConnectionManager from '../../service/InternetConnectionManager';
import LeafletPaneManager from '../../service/Leaflet/LeafletPaneManager';
import { iNoBounceService } from '../../service/INoBounceService';
import params from '../../params';
import Button from '@material-ui/core/Button';
import SharedMapBlocked from '../SharedMapBlocked';
import LocalStorageBlocked from '../LocalStorageBlocked';
import Preloader from '../Preloader';
import { matchPath, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import SelfSignUp from '../SelfSignUp';
import ResetPassword from '../ResetPassword';
import Login from '../Login';
import Sso from '../Login/Sso';
import AccountPage from '../AccountPage';
import DataSource from '../DataSource';
import SearchPage from '../SearchPage';
import Permissions from '../Permissions/Permissions';
import Log from '../Log';
import UpdateEventLog from '../UpdateEventLog';
import Billing from '../Billing';
import SharedMap from '../SharedMap';
import WorkflowRules from '../WorkflowRules/WorkflowRules';
import WorkflowActions from '../WorkflowActions/WorkflowActions';
import WorkflowButtons from '../WorkflowButtons/WorkflowButtons';
import Schedules from '../Workflow/Schedule';
import LiveUpdateEventLog from '../LiveUpdateEventLog';
import ErrorNotificationFactory from '../ErrorNotification/ErrorNotificationFactory';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '../Breadcrumbs';
import Link from 'react-router-dom/Link';
import Icon from '@material-ui/core/Icon';
import PopupNotificationFactory from '../PopupNotification/PopupNotificationFactory';
import WorkflowActionsForm from '../WorkflowActionsForm/WorkflowActionsForm';
import WorkflowMapActionsForm from '../WorkflowActionsForm/WorkflowMapActionsForm';
import WorkflowActionsProcessingSpinner from '../WorkflowActions/WorkflowActionsProcessingSpinner';
import WebLinkIframe from '../WebLink/WebLinkIframe';
import CloseAppModal from '../NativeApp/CloseAppModal';
import BatteryOptimizationModal from '../NativeApp/BatteryOptimizationModal';
import { IntercomLauncher } from '../IntercomLauncher';
import { withProfiler } from '@sentry/react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { SplashScreen } from '@capacitor/splash-screen';
import { Network } from '@capacitor/network';
import { App as AppPlugin } from '@capacitor/app';
import AppUpdateModal from '../NativeApp/AppUpdateModal';
import appUpdateManager from '../../service/MobileApp/AppUpdateManager';
import logRocketManager, { LogRocketEvent } from 'service/LogRocket';
import { INTERCOM_EVENT_LOGROCKET, INTERCOM_EVENTS } from 'interfaces/Intercom';
import backend, { THIRD_PARTY_COOKIES_ACCESS_STATE } from 'api/BackendApi';
import ThirdPartyCookiesAccess from '../ThirdPartyCookiesAccess';
import isMobile from 'is-mobile';
import { IdleSessionManager } from '../../service/IdleSessionManager';
import FileRedirector from '../FileInput/FileRedirector';
import PreparingAccountMessage from '../PreparingAccountMessage';
import Analytics from '../Analytics/Analytics';
import { Tooltip } from '@material-ui/core';
import { ANALYTICS_MODULE } from '../Permissions/constants';
import SuperadminTabs from '../SuperadminTabs';

const MainMenu = React.lazy(() => import(/* webpackChunkName: "C-MainMenu" */ '../MainMenu'));
const MapPage = React.lazy(() => import(/* webpackChunkName: "Pages-MapPage" */ '../MapPage'));

class App extends React.Component {
    deviceIsBackOnlineSnackbarKey = null;
    deviceIsOfflineSnackbarKey = null;

    constructor(props) {
        super(props);
        this.location = null;
        this.persistentMessages = new Map();
        this.state = {
            sharedMapError: null,
            localStorageError: null,
        };
        this.mapPage = React.createRef();

        new IdleSessionManager().init();
    }

    componentDidMount() {
        try {
            window.localStorage.getItem('test');
        } catch (e) {
            console.error(e);
            this.setState({
                localStorageError: true,
            });
            return;
        }
        dispatcher.subscribe(events.ACCOUNT_UPDATED, 'app', () => {
            logRocketManager.changeSession(userManager.getCurrentUser());
        });
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, 'app', (user) => {
            intercomManager.changeSession();

            const account = userManager.getCurrentAccount();
            Sentry.configureScope((scope) => {
                if (user) {
                    scope.setUser({
                        email: user.email,
                        id: user.id,
                        accountId: user.accountId,
                        accountName: account?.name,
                    });
                } else {
                    scope.setUser(null);
                }
            });
            const hubScope = Sentry.getCurrentHub().getScope();
            if (hubScope) {
                hubScope.setTags({
                    accountId: account?.id,
                    accountName: account?.name,
                });
            }

            // if a user did not finish a self-signup then redirect him to the wizard
            if (account) {
                const pathname = window.location.pathname;
                const matchSignUpRoutes = matchPath(pathname, {
                    path: [routes.signup, routes.quickSignup],
                    strict: true,
                    exact: true,
                });
                if (!matchSignUpRoutes) {
                    const stage = account['signUpStage'];
                    if (stage && stage < STAGES.FINISH) {
                        if (user['ssoProvider']) {
                            this.props.history.push(
                                reverse(routes.signup, { provider: user['ssoProvider'], step: stage }),
                            );
                        } else {
                            if (stage === STAGES.FEATURES && !account.purposeOfUse) {
                                this.props.history.push(reverse(routes.quickSignup, { step: STAGES.FEATURES }));
                            }
                        }
                        return;
                    }
                }

                if (weAreInNativeApp()) {
                    // todo move to BgGeoManager.constructor
                    BgGeoManager.onAppLaunch();

                    // for sentry
                    Deploy.getCurrentVersion().then((info) => {
                        console.log('Appflow version ', info);
                    });
                }

                logDebug('window.location.pathname', pathname);
                if (pathname === routes.login) {
                    logDebug('Force redirect to /');
                    this.props.history.push(routes.client);
                }
            }

            this.forceUpdate(() => {
                this.handleNewExternalSession(user);
            });

            this.handleViewportResize();

            if (weAreInNativeApp()) {
                SplashScreen.hide();
            }
        });

        dispatcher.subscribe(events.ADD_MESSAGE_ENQUEUE_SNACKBAR, this, (data) => {
            /** @type {{message: SnackbarMessage, options?: OptionsObject}} data */
            const { message, options } = data;
            this.props.enqueueSnackbar(message, options);
        });

        dispatcher.subscribe(events.CLOSE_MESSAGE_ENQUEUE_SNACKBAR, this, (key) => {
            this.props.closeSnackbar(key);
        });

        dispatcher.subscribe(events.WS_MESSAGE, 'ws', this.onWSMessage);
        dispatcher.subscribe(events.WS_SUCCESS_PERSISTENT, this, this.onWSSuccessPersistent);
        dispatcher.subscribe(events.WS_PERSISTENT_MESSAGE, this, this.onPersistentMessage);
        dispatcher.subscribe(events.WS_UI_VERSION_UPDATED, this, this.onVersionUpdated);
        dispatcher.subscribe(events.SNACKBAR_CLOSED, this, this.onSnackbarClosed);

        dispatcher.subscribe(events.WS_CSV_EXPORT, this, ({ downloadUrl }) => {
            if (downloadUrl) {
                const text = this.props.t('entity_data_table.download_csv.success');
                const m = text.match(/^(.*)<a>(.+)<\/a>(.*)$/);

                const message =
                    m === null ? (
                        <a style={{ color: '#fff' }} href={downloadUrl}>
                            {text}
                        </a>
                    ) : (
                        <span>
                            {m[1]}{' '}
                            <a style={{ color: '#fff' }} href={downloadUrl}>
                                {m[2]}
                            </a>
                            {m[3]}
                        </span>
                    );

                this.props.enqueueSnackbar(message, { variant: 'success', persist: true });
            } else {
                this.props.enqueueSnackbar(this.props.t('entity_data_table.download_csv.failure'), {
                    variant: 'error',
                });
            }
        });

        dispatcher.subscribe(events.THIRD_PARTY_COOKIES_ACCESS_STATE_CHANGED, this, () => {
            this.forceUpdate();
        });

        this.cordovaFriendlyRequestCurrentUser();

        setCssCustomHeight();
        setCssCustomWidth();
        window.addEventListener('resize', this.handleViewportResize);
        window.addEventListener('orientationchange', this.handleViewportResize.bind(this, 200)); // delay for mobile animation
        window.addEventListener('keyboardDidShow', this.handleViewportResize);
        window.addEventListener('keyboardDidHide', this.handleViewportResize);
        if (weAreInNativeApp()) {
            AppPlugin.addListener('appStateChange', (state) => {
                if (state.isActive) {
                    this.handleViewportResize();
                }
            });

            // deep links
            AppPlugin.addListener('appUrlOpen', (event) => {
                window.location.href = event.url.split('.com').pop();
            });
        }

        this.handleNetworkStatus();
    }

    handleNewExternalSession = async (user) => {
        if (userManager.getCurrentAccount()?.isTest === false && !userManager.isSwitchedSuperAdmin()) {
            logRocketManager.init();
        }

        logRocketManager.changeSession(user);

        if (weAreInNativeApp()) {
            Deploy.getCurrentVersion().then((appflowVersion) => {
                if (!appflowVersion?.buildId) {
                    return;
                }

                logRocketManager.trackEvent(LogRocketEvent.APPFLOW, appflowVersion);
            });
        }

        const sessionUrl = await logRocketManager.getSessionUrl();
        Sentry.configureScope((scope) => {
            scope.setExtra('LogRocket', sessionUrl);
        });
        intercomManager.trackEvent(INTERCOM_EVENTS.LOGROCKET, {
            [INTERCOM_EVENT_LOGROCKET.SESSION_URL]: sessionUrl,
        });
    };

    handleNetworkStatus = () => {
        dispatcher.subscribe(events.APP_IS_BACK_ONLINE, this, () => {
            if (this.deviceIsOfflineSnackbarKey) {
                this.props.closeSnackbar(this.deviceIsOfflineSnackbarKey);
                this.deviceIsOfflineSnackbarKey = null;
            }

            this.deviceIsBackOnlineSnackbarKey = this.props.enqueueSnackbar(this.props.t('device_is_no_more_offline'), {
                variant: 'success',
                preventDuplicate: true,
            });

            const pageIsNotLoadedYet = userManager.isRoleUnknown();
            if (pageIsNotLoadedYet) {
                window.location.reload();
            } else {
                this.cordovaFriendlyRequestCurrentUser();
            }
        });

        dispatcher.subscribe(events.APP_WENT_OFFLINE, this, () => {
            if (this.deviceIsBackOnlineSnackbarKey) {
                this.props.closeSnackbar(this.deviceIsBackOnlineSnackbarKey);
                this.deviceIsBackOnlineSnackbarKey = null;
            }

            SplashScreen.hide();

            this.deviceIsOfflineSnackbarKey = this.props.enqueueSnackbar(this.props.t('device_is_offline'), {
                variant: 'error',
                persist: true,
                preventDuplicate: true,
            });
        });
    };

    handleViewportResize = (delay = 0) => {
        setTimeout(() => {
            setCssCustomHeight();
            setCssCustomWidth();
        }, delay);
        if (this.mapPage.current) {
            this.mapPage.current.invalidateSizeAfterSecond();
        }
    };

    cordovaFriendlyRequestCurrentUser() {
        if (weAreInNativeApp()) {
            document.addEventListener(
                // because we make this api call at the app start we need to be sure that cordova's http plugin is already ready. The only way to be sure on React is to use the 'deviceready' event
                'deviceready',
                () => {
                    if (InternetConnectionManager.isOnline) {
                        this.requestCurrentUser();
                    }
                },
                false,
            );
        } else {
            this.requestCurrentUser();
        }
    }

    requestCurrentUser() {
        userManager.requestCurrentUser().catch((error) => {
            if (error.details && error.details.get('sharedMapError')) {
                this.setState({
                    sharedMapError: error.message,
                });
                return;
            }
            this.props.enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents('app');
        dispatcher.unsubscribeFromAllEvents('ws');
        dispatcher.unsubscribeFromAllEvents(this);
        document.removeEventListener('resize', setCssCustomHeight);
        Network.removeAllListeners();
    }

    componentDidUpdate() {
        if (this.location === null || this.location.pathname !== this.props.location.pathname) {
            this.location = this.props.location;
            this.props.onLocation(this.location);
            LeafletPaneManager.resetPane();
        }

        iNoBounceService.init();
    }

    onWSMessage = ({ message, ...props }) => {
        this.props.enqueueSnackbar(<span style={{ whiteSpace: 'pre-line' }}>{message}</span>, props);
    };

    onWSSuccessPersistent = ({ message, ...props }) => {
        this.props.enqueueSnackbar(<span dangerouslySetInnerHTML={{ __html: message }} />, {
            variant: 'success',
            persist: true,
        });
    };

    onVersionUpdated = ({ version }) => {
        if (weAreInNativeApp()) {
            // delay for AppFlow deploying
            setTimeout(() => {
                appUpdateManager.checkForUpdate();
            }, 60000);
            return;
        }
        if (version === params.version) {
            return;
        }
        const { t } = this.props;
        const action = (key) => (
            <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => {
                    window.location.reload();
                }}
                data-testid="reload_on_version_update"
            >
                {t('reload')}
            </Button>
        );
        this.props.enqueueSnackbar(t('ui_updated'), {
            variant: 'info',
            persist: true,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            action,
            preventDuplicate: true,
        });
    };

    onPersistentMessage = ({ message, messageId, variant }) => {
        if (this.persistentMessages.has(messageId)) {
            const messageData = this.persistentMessages.get(messageId);
            if (messageData.variant === variant && message === messageData.message) {
                return;
            }

            this.props.closeSnackbar(messageData.snackbarId);
            this.persistentMessages.delete(messageData.snackbarId);
        }
        if (message) {
            const snackbarId = this.props.enqueueSnackbar(message, {
                variant,
                persist: variant !== 'success',
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            });
            this.persistentMessages.set(messageId, { snackbarId, variant, message });
        }
    };

    onSnackbarClosed = (id) => {
        for (let [messageId, snackbarId] of this.persistentMessages) {
            if (snackbarId.toString() === id.toString()) {
                this.persistentMessages.delete(messageId);
            }
        }
    };

    render() {
        if (this.state.sharedMapError) {
            return <SharedMapBlocked message={this.state.sharedMapError} />;
        }
        if (this.state.localStorageError) {
            return <LocalStorageBlocked />;
        }

        if (userManager.isRoleUnknown()) {
            return <Preloader />;
        }

        if (backend.isNeedThirdPartyCookiesAccess()) {
            const thirdPartyCookiesAccess = backend.getThirdPartyCookiesAccess();
            if (thirdPartyCookiesAccess === THIRD_PARTY_COOKIES_ACCESS_STATE.UNDEFINED) {
                return null;
            }
            if (thirdPartyCookiesAccess === THIRD_PARTY_COOKIES_ACCESS_STATE.NEED_REQUEST) {
                return <ThirdPartyCookiesAccess />;
            }
        }

        const user = userManager.getCurrentUser();
        let component;

        const hasAccessToAnalyticsPage = userManager.userHasAccessTo(
            ANALYTICS_MODULE.NAME,
            ANALYTICS_MODULE.FEATURES.VIEW_CHARTS_AND_DASHBOARDS.NAME,
        );

        const AnalyticsRoute = (
            <Route
                path={routes.analytics}
                render={({ match }) => <Analytics baseUrl={window.params.analyticsEndpoint} />}
            />
        );

        if (userManager.isSharedMapUser()) {
            component = (
                <Switch>
                    <Route
                        path={routes.sharedMap}
                        exact
                        render={({ match }) => {
                            if (match.params.id) {
                                return (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <MapPage accountId={user.accountId} />
                                    </Suspense>
                                );
                            }
                            return <Redirect to={routes.login} />;
                        }}
                    />
                    <Route path={routes.signup} exact render={({ match }) => <Redirect to={routes.client} />} />
                    <Route path={routes.quickSignup} exact render={({ match }) => <Redirect to={routes.client} />} />
                </Switch>
            );
        } else if (userManager.isRoleGuest()) {
            component = (
                <Switch>
                    <Route
                        path={routes.signup}
                        exact
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <SelfSignUp
                                    provider={match.params.provider}
                                    stage={match.params.step ? parseInt(match.params.step) : 0}
                                />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.quickSignup}
                        exact
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <SelfSignUp stage={match.params.step ? parseInt(match.params.step) : STAGES.INTRO} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.resetPassword}
                        render={({ match }) => <ResetPassword token={match.params.token} />}
                    />
                    <Route path={routes.login} exact component={Login} />
                    <Route path={routes.sso} exact render={({ match }) => <Sso provider={match.params.provider} />} />
                    <Redirect to={routes.login} />
                </Switch>
            );
        } else if (userManager.isRoleSuperAdmin()) {
            component = (
                <Switch>
                    <Route path={routes.sso} exact render={({ match }) => <Sso provider={match.params.provider} />} />
                    <Route path={routes.admin.accounts} exact render={() => <SuperadminTabs user={user} />} />
                    <Route
                        path={routes.admin.account.index}
                        exact
                        render={({ match }) => {
                            if (match.params.accountId) {
                                return (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <AccountPage accountId={parseInt(match.params.accountId)} />
                                    </Suspense>
                                );
                            }
                            return <Redirect to={routes.admin.accounts} />;
                        }}
                    />
                    <Route
                        path={routes.admin.account.dataSource.index}
                        render={({ match, history }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <DataSource
                                    history={history}
                                    accountId={parseInt(match.params.accountId)}
                                    dataSourceId={parseInt(match.params.dataSourceId)}
                                />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.search}
                        exact
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <SearchPage accountId={parseInt(match.params.accountId)} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.permissions}
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Permissions
                                    accountId={
                                        match.params !== undefined && match.params.accountId !== undefined
                                            ? match.params.accountId
                                            : user.accountId
                                    }
                                />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.log}
                        render={({ match, location }) => (
                            <Log
                                accountId={parseInt(match.params.accountId)}
                                isDebugShow
                                user={user}
                                location={location}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.updateEventLog}
                        render={({ match }) => (
                            <UpdateEventLog accountId={parseInt(match.params.accountId)} user={user} />
                        )}
                    />
                    <Route
                        path={routes.admin.account.billing.subscribe}
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Billing
                                    accountId={parseInt(match.params.accountId)}
                                    user={user}
                                    subscribeAutoStart={true}
                                />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.billing.update}
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Billing
                                    accountId={parseInt(match.params.accountId)}
                                    user={user}
                                    updateAutoStart={true}
                                />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.billing.index}
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Billing accountId={parseInt(match.params.accountId)} user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.sharedMaps}
                        render={({ match }) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                {match.params.accountId && (
                                    <SharedMap accountId={parseInt(match.params.accountId)} user={user} />
                                )}
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowRules}
                        render={({ match }) => (
                            <WorkflowRules
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowActions}
                        render={({ match }) => (
                            <WorkflowActions
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                                tab={match.params?.type}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowButtons}
                        render={({ match }) => (
                            <WorkflowButtons
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowSchedules}
                        render={({ match }) => (
                            <Schedules
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                            />
                        )}
                    />
                    <Redirect to={routes.admin.accounts} />
                </Switch>
            );
        } else if (userManager.isRoleAdmin()) {
            component = (
                <Switch>
                    <Route path={routes.sso} exact render={({ match }) => <Sso provider={match.params.provider} />} />
                    <Route
                        path={routes.signup}
                        exact
                        render={({ match }) => (
                            <SelfSignUp
                                provider={match.params.provider}
                                stage={match.params.step ? parseInt(match.params.step) : 0}
                            />
                        )}
                    />
                    <Route
                        path={routes.quickSignup}
                        exact
                        render={({ match }) => (
                            <SelfSignUp stage={match.params.step ? parseInt(match.params.step) : 0} />
                        )}
                    />
                    <Route
                        path={routes.client}
                        exact
                        render={() => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <MapPage accountId={user.accountId} ref={this.mapPage} />
                            </Suspense>
                        )}
                    />
                    {!weAreInNativeApp() && (
                        <Route
                            path={routes.admin.account.index}
                            exact
                            render={() => <AccountPage accountId={user.accountId} myAccount />}
                        />
                    )}
                    <Route
                        path={routes.admin.account.permissions}
                        render={({ match }) => (
                            <Permissions
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.dataSource.updateJobs}
                        exact
                        render={({ match }) => (
                            <LiveUpdateEventLog
                                accountId={user.accountId}
                                dsId={parseInt(match.params.dataSourceId)}
                                user={user}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.dataSource.entity}
                        render={({ match, history }) => (
                            <DataSource
                                myAccount
                                history={history}
                                accountId={user.accountId}
                                dataSourceId={parseInt(match.params.dataSourceId)}
                                entityId={parseInt(match.params.entityId)}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.dataSource.index}
                        render={({ match, history }) => (
                            <DataSource
                                myAccount
                                history={history}
                                accountId={user.accountId}
                                dataSourceId={parseInt(match.params.dataSourceId)}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.search}
                        exact
                        render={() => <SearchPage myAccount accountId={user.accountId} />}
                    />
                    <Route
                        path={routes.admin.account.log}
                        render={({ location }) => <Log accountId={user.accountId} user={user} location={location} />}
                    />
                    <Route
                        path={routes.admin.account.billing.subscribe}
                        render={() => <Billing accountId={user.accountId} user={user} subscribeAutoStart={true} />}
                    />
                    <Route
                        path={routes.admin.account.billing.update}
                        render={() => <Billing accountId={user.accountId} user={user} updateAutoStart={true} />}
                    />
                    <Route
                        path={routes.admin.account.billing.index}
                        render={() => <Billing accountId={user.accountId} user={user} />}
                    />
                    <Route
                        path={routes.admin.account.sharedMaps}
                        render={() => <SharedMap accountId={user.accountId} user={user} />}
                    />
                    <Route
                        path={routes.admin.account.workflowRules}
                        render={({ match }) => (
                            <WorkflowRules
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                                myAccount
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowActions}
                        render={({ match }) => (
                            <WorkflowActions
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                                myAccount
                                tab={match.params?.type}
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowButtons}
                        render={({ match }) => (
                            <WorkflowButtons
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                                myAccount
                            />
                        )}
                    />
                    <Route
                        path={routes.admin.account.workflowSchedules}
                        render={({ match }) => (
                            <Schedules
                                accountId={
                                    match.params !== undefined && match.params.accountId !== undefined
                                        ? match.params.accountId
                                        : user.accountId
                                }
                                myAccount
                            />
                        )}
                    />
                    <Route
                        path={routes.file}
                        render={({ match }) => (
                            <FileRedirector accountId={user.accountId} fileId={match.params.fileId} />
                        )}
                    />
                    {hasAccessToAnalyticsPage && AnalyticsRoute}
                    <Route path={routes.resetPassword} render={({ match }) => <Redirect to={routes.client} />} />
                    <Redirect to={routes.client} />
                </Switch>
            );
        } else {
            component = (
                <Switch>
                    <Route path={routes.sso} exact render={({ match }) => <Sso provider={match.params.provider} />} />
                    <Route
                        path={routes.client}
                        exact
                        render={() => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <MapPage accountId={user.accountId} ref={this.mapPage} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={routes.file}
                        render={({ match }) => (
                            <FileRedirector accountId={user.accountId} fileId={match.params.fileId} />
                        )}
                    />
                    {hasAccessToAnalyticsPage && AnalyticsRoute}
                    <Route path={routes.signup} exact render={({ match }) => <Redirect to={routes.client} />} />
                    <Route path={routes.quickSignup} exact render={({ match }) => <Redirect to={routes.client} />} />
                    <Route path={routes.resetPassword} render={({ match }) => <Redirect to={routes.client} />} />
                    <Redirect to={routes.client} />
                </Switch>
            );
        }

        const { pathname } = this.props.location;

        let onAnalyticsPage = pathname.startsWith('/analytics');

        const isSubscriptionBlocked = userManager.isSubscriptionBlocked();
        let mainCssContainer = !isSubscriptionBlocked && isMapPage(pathname) ? 'container__map-page' : '';
        if (onAnalyticsPage) {
            mainCssContainer = 'container__analytics';
        }

        const showNavigationControls =
            pathname !== reverse(routes.client) &&
            !pathname.startsWith('/login') &&
            !pathname.startsWith('/signup/') &&
            !pathname.startsWith('/map/') &&
            !pathname.startsWith('/file/') &&
            !onAnalyticsPage;

        return (
            <main className={mainCssContainer}>
                <ErrorNotificationFactory>
                    <PreparingAccountMessage>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems={isMobile() ? 'center' : 'flex-start'}
                        >
                            <Grid item>
                                {showNavigationControls &&
                                    userManager.getCurrentUser() &&
                                    !userManager.isRoleSuperAdmin() && (
                                        <div className="back-to-map" data-testid="main_menu.back_to_map">
                                            <Tooltip title={this.props.t('main_menu.back_to_map')}>
                                                <Link to={reverse(routes.client)} style={{ alignItems: 'center' }}>
                                                    <Icon>chevron_left</Icon>
                                                    <i className="main_menu__item__icon fa-regular fa-map-location-dot" />
                                                </Link>
                                            </Tooltip>
                                        </div>
                                    )}
                            </Grid>

                            <Grid item>
                                {((isSubscriptionBlocked && isMapPage(pathname)) || showNavigationControls) && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <MainMenu />
                                    </Suspense>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Breadcrumbs />
                        </Grid>

                        <div className="main-container">
                            <PopupNotificationFactory />
                            <WorkflowActionsForm />
                            <WorkflowMapActionsForm />
                            <WorkflowActionsProcessingSpinner />
                            <WebLinkIframe />
                            <CloseAppModal />
                            <BatteryOptimizationModal />
                            <AppUpdateModal />
                            {component}
                        </div>
                    </PreparingAccountMessage>
                </ErrorNotificationFactory>
                <IntercomLauncher />
            </main>
        );
    }
}

export default withProfiler(withTranslation('translations', { withRef: true })(withSnackbar(withRouter(App))));
