import React from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { PicklistValue } from 'components/types';

interface Props {
    value: string | number | null;
    onChangeSelect: (value: string) => void;
    picklist: PicklistValue[];
}

class SinglePicklistConstantInput extends React.PureComponent<Props> {
    handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as string;
        this.props.onChangeSelect(value);
    };

    render() {
        const { value, picklist } = this.props;

        return (
            <FormControl fullWidth>
                <Select value={value || ''} displayEmpty onChange={this.handleChange}>
                    {picklist.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
}

export default SinglePicklistConstantInput;
