import { Api, Routing } from 'interfaces';
import { userManager } from 'service/UserManager';
import { mapLegacyRouteTravelMode, mapLegacySessionTravelMode, utcToUserTimezone } from '../../utils';
import isNil from 'lodash/isNil';

class RouteDenormalizer {
    static denormalizeSession(session: Api.Routing.Session.SessionApiResponse): Routing.Session.Session {
        return {
            ...session,
            input: RouteDenormalizer.denormalizeInput(session.input),
            unassignedJobs: RouteDenormalizer.denormalizeUnassignedJobs(session.unassignedJobs),
        };
    }

    static denormalizeRoutes(routes: Api.Routing.Route.RouteApiResponse[]): Routing.Route.Route[] {
        const denormalizedRoutes: Routing.Route.Route[] = [];
        for (let route of routes) {
            denormalizedRoutes.push(RouteDenormalizer.denormalizeRoute(route));
        }
        return denormalizedRoutes;
    }

    static denormalizeSimpleRoutes(routes: Api.Routing.Route.SimpleRouteApiResponse[]): Routing.Route.SimpleRoute[] {
        const denormalizedRoutes: Routing.Route.SimpleRoute[] = [];
        for (let route of routes) {
            denormalizedRoutes.push(RouteDenormalizer.denormalizeSimpleRoute(route));
        }
        return denormalizedRoutes;
    }

    static denormalizeUnassignedJobs(
        jobs: Api.Routing.Route.UnassignedJobApiResponse[],
    ): Routing.Route.UnassignedJob[] {
        const denormalizedUnassignedJobs: Routing.Route.UnassignedJob[] = [];
        for (const job of jobs) {
            denormalizedUnassignedJobs.push(RouteDenormalizer.denormalizeUnassignedJob(job));
        }
        return denormalizedUnassignedJobs;
    }

    static denormalizeUnassignedJob(job: Api.Routing.Route.UnassignedJobApiResponse): Routing.Route.UnassignedJob {
        return {
            ...job,
        };
    }

    static denormalizeRoute(route: Api.Routing.Route.RouteApiResponse): Routing.Route.Route {
        return {
            ...route,
            dateStartAt: new Date(route.dateStartAt),
            travelMode: mapLegacyRouteTravelMode(route.travelMode),
            activities: RouteDenormalizer.denormalizeRouteActivities(route.activities),
            input: RouteDenormalizer.denormalizeInput(route.input),
            originalRoute: route.originalRoute ? RouteDenormalizer.denormalizeRoute(route.originalRoute) : null,
        };
    }

    static denormalizeSimpleRoute(route: Api.Routing.Route.SimpleRouteApiResponse): Routing.Route.SimpleRoute {
        return {
            ...route,
            dateStartAt: new Date(route.dateStartAt),
        };
    }

    static denormalizeInputPoints(
        inputPoints: Api.Routing.Session.SessionInputPointsApi,
    ): Routing.Route.DesignRoutePointsArray {
        const points: Routing.Route.DesignRoutePointsArray = [];
        for (const i in inputPoints) {
            const point = inputPoints[i][1] as Routing.Route.DesignRouteEntityPoint;
            if (!Number.isInteger(point.index)) {
                point.index = Number(i);
            }
            points.push(point);
        }
        return points;
    }

    private static denormalizeInput(input: Api.Routing.Session.SessionInputApi): Routing.Session.SessionInput {
        const points = RouteDenormalizer.denormalizeInputPoints(input.points);
        const user = userManager.getCurrentUser();

        return {
            ...input,
            points,
            calendar: {
                ...input.calendar,
                departingCalendarData: {
                    ...input.calendar.departingCalendarData,
                    date:
                        typeof input.calendar.departingCalendarData.date === 'string'
                            ? new Date(utcToUserTimezone(input.calendar.departingCalendarData.date, user))
                            : new Date(),
                },
            },
            config: {
                ...input.config,
                travelMode: mapLegacySessionTravelMode(input.config.travelMode),
                vehicleProfile: input.config.vehicleProfile ?? {},
            },
        };
    }

    private static denormalizeRouteActivities(
        activities: Api.Routing.Route.RouteActivityInfoApi[],
    ): Routing.Route.Activity[] {
        const denormalizedActivities: Routing.Route.Activity[] = [];

        for (let activity of activities) {
            denormalizedActivities.push({
                ...activity,
                arrivalTime: activity.arrivalTime !== null ? new Date(activity.arrivalTime) : null,
                departureTime: activity.departureTime !== null ? new Date(activity.departureTime) : null,
                expectedArrivalTime: !isNil(activity.expectedArrivalTime)
                    ? new Date(activity.expectedArrivalTime)
                    : null,
            });
        }
        return denormalizedActivities;
    }
}

export default RouteDenormalizer;
