import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import Hint from '../../Hint';
import { DETAIL_STORE_FIELD, getDetail } from '../../../service/WorkflowActionManager';
import { withTranslation } from 'react-i18next';
import emFactory from '../../../service/EntityManager';
import { CallContext, TYPE_ENTITY_LINK } from '../../utils/CallContext';
import { isLookupField } from '../../../utils';
import FormControlSelect from '../AbstractForm/FormControlSelect';

class StoreRecordsControl extends React.PureComponent {
    prepareOptions = () => {
        return this.props.baseEntity
            ? this.props.baseEntity.fields
                  .filter(isLookupField)
                  .filter((field) => {
                      const targetApiName = field.lookupData.apiName;
                      const baseEntityDsId = this.props.baseEntity.dataSource.id;
                      const targetEntityDsId = this.props.targetEntity && this.props.targetEntity.dataSource.id;
                      if (baseEntityDsId !== targetEntityDsId) {
                          return false;
                      }
                      return this.props.targetEntity && this.props.targetEntity.apiName === targetApiName;
                  })
                  .map((field) => {
                      return (
                          <MenuItem key={field.id} value={field.apiName}>
                              {field.label}
                          </MenuItem>
                      );
                  })
            : [];
    };

    renderInput = (params) => (
        <TextField
            {...params}
            label={
                <>
                    <span style={{ marginRight: 5 }}>
                        {this.props.t('workflow_actions.form.matched_records.store')}
                    </span>
                    <Hint type="tooltip">{this.props.t('workflow_actions.form.matched_records.store.hint')}</Hint>
                </>
            }
        />
    );

    getEntityManager = (dataSourceId) => {
        return emFactory.getManager(this.props.accountId, dataSourceId);
    };

    getEntityByApiName = (entityApiName, dsId) => {
        return this.getEntityManager(dsId)
            .getEntities()
            .then((entities) => {
                return entities.find((entity) => entity.name === entityApiName);
            });
    };

    handleChange = (event) => {
        const fieldApiName = event.target.value;
        const field = this.props.baseEntity.fields.filter((f) => f.apiName === fieldApiName).pop() || null;
        if (field && field.lookupData && field.lookupData.linking_lookup_data) {
            const entityApiName = field.lookupData.linking_lookup_data.entity;
            this.getEntityByApiName(entityApiName, this.props.baseEntity.dataSource.id).then((linkingEntity) => {
                this.props.onCallContextChange(null, TYPE_ENTITY_LINK, linkingEntity.id);
            });
        } else {
            this.props.onCallContextChange(null, TYPE_ENTITY_LINK, null);
        }
        this.props.onChange(DETAIL_STORE_FIELD, field);
    };

    render() {
        return (
            <FormControlSelect
                fullWidth
                margin="dense"
                label={
                    <>
                        <span style={{ marginRight: 5 }}>
                            {this.props.t('workflow_actions.form.matched_records.store')}
                        </span>
                        <Hint type="tooltip">{this.props.t('workflow_actions.form.matched_records.store.hint')}</Hint>
                    </>
                }
                name={DETAIL_STORE_FIELD}
                value={getDetail(DETAIL_STORE_FIELD, this.props.action.details, 0)}
                onChange={this.handleChange}
                error={this.props.errors.has(DETAIL_STORE_FIELD)}
                helperText={this.props.errors.get(DETAIL_STORE_FIELD)}
                data-testid="workflow_actions.form.matched_records.store"
            >
                <MenuItem value="0" data-testid="undefined_picklist_option">
                    {this.props.t('undefined_picklist_option')}
                </MenuItem>
                {this.prepareOptions()}
            </FormControlSelect>
        );
    }
}

StoreRecordsControl.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onCallContextChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    baseEntity: PropTypes.object,
    targetEntity: PropTypes.object,
};

export default withTranslation()(StoreRecordsControl);
