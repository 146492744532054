import React from 'react';
import Grid from '@material-ui/core/Grid';
import SharedMapList from './SharedMapList';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class SharedMap extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Grid container wrap="nowrap" spacing={1}>
                    <Grid item xs={12}>
                        <SharedMapList accountId={this.props.accountId} user={this.props.user} />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

SharedMap.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(SharedMap);
