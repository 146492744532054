import React from 'react';
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Duration } from 'service/types';

interface Props extends WithTranslation {
    name: string;
    value: Duration;
    error: string | undefined;
    onChange: (name: string, value: Duration) => void;
}

class DurationInput extends React.PureComponent<Props> {
    handleInputChange = (e: any) => {
        const inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName === 'number') {
            if (!inputValue.match(/^\d*$/)) {
                return;
            }
            inputValue = inputValue === '' ? null : parseInt(inputValue);
        }

        const { value, name, onChange }: Props = this.props;

        const newValue = { ...value, [inputName]: inputValue };

        onChange(name, newValue);
    };

    render() {
        const { value, error, t }: Props = this.props;

        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        name="number"
                        label={t('security_settings.session_management_form.duration.value')}
                        fullWidth
                        margin="none"
                        value={value.number === null ? '' : value.number}
                        helperText={error || ''}
                        error={!!error}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="none">
                        <InputLabel>{t('security_settings.session_management_form.duration.units')}</InputLabel>
                        <Select name="units" value={value.units} onChange={this.handleInputChange}>
                            {['minute', 'hour', 'day'].map((units) => (
                                <MenuItem key={units} value={units}>
                                    {t('security_settings.session_management_form.duration.units.' + units)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation('translations', { withRef: true })(DurationInput);
