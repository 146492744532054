import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import subscriptionManagerFactory, { TYPE_CHANGE } from '../../service/SubscriptionManager';
import LoadingButton from '../LoadingButton';

class ResumeSubscriptionAlert extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { loading: false };
        this.subManager = subscriptionManagerFactory.getManager(this.props.account.id);
    }

    handleConfirmChange = () => {
        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.account.id === null || subscription.accountId !== this.props.account.id) {
                return;
            }
            if (data.type === TYPE_CHANGE.REFRESH) {
                this.setState(
                    {
                        loading: false,
                    },
                    () => {
                        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                        this.props.onCloseDialog && this.props.onCloseDialog();
                    },
                );
            }
        });

        this.subManager.resume().catch((error) => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                    this.props.onCloseDialog && this.props.onCloseDialog();
                    this.props.enqueueSnackbar &&
                        this.props.enqueueSnackbar(error.message, {
                            variant: 'error',
                            persist: true,
                        });
                },
            );
        });

        this.setState({
            loading: true,
        });
    };

    render() {
        return (
            <Dialog className="resume-subscription-alert" open={true}>
                <DialogTitle className="dialog-title">
                    {this.props.t('billing.resume_alert.title')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.resume_alert.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogActions>
                    {!this.state.loading && (
                        <Button
                            color="primary"
                            onClick={this.props.onCloseDialog}
                            data-testid="billing.resume_alert.button.cancel"
                        >
                            {this.props.t('button.cancel')}
                        </Button>
                    )}
                    <LoadingButton
                        color="primary"
                        onClick={this.handleConfirmChange}
                        loading={this.state.loading}
                        data-testid="billing.resume_alert.button.confirm"
                    >
                        {this.props.t('button.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

ResumeSubscriptionAlert.propTypes = {
    onCloseDialog: PropTypes.func,
};

export default withTranslation()(ResumeSubscriptionAlert);
