import React, { Suspense } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { roleManager } from '../../service/RoleManager';
import { KEY_CONFIRM_PASSWORD, userManager } from 'service/UserManager';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import timezones, { stringifyTimezone } from 'references/timezones';
import dsManagerFactory from '../../service/DsManager';
import { Checkbox, Tab, Tabs, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { LANGUAGES } from '../../locales/i18n';
import { Autocomplete } from '@material-ui/lab';
import TabPanel from '../DataSource/TabPanel';
import { AdapterId, UserPropertiesTab } from '../types';
import LocationTrackingForm from './LocationTrackingForm';
import { TravelingPreferencesForm } from './TravelingPreferences';
import { userPropertiesManager } from 'service/UserForm';
import LandingLink from '../HelpLink/LandingLink';
import ScheduleConsistencePopup from './ScheduleConsistencePopup';
import ScheduleConsistencePopupManager from '../../service/BgGeo/ScheduleConsistencePopupManager';
import { getDeviceTimezoneOffsetInMinutes, weAreInNativeApp } from '../../utils';
import { FormAlert } from '../PureFormDialog/Form';
import RestrictionMessage from '../RestrictionMessage';
import { locationTrackingSettingsStorage } from '../../service/LocationTracking/LocationTrackingSettingsStorage';
import WorkSchedule from '../../service/WorkSchedule';
import YouCanConnectYourUsersViaSSODialog from './YouCanConnectYourUsersViaSSODialog';
import HasCrmAccountControl from './Controls/HasCrmAccountControl';
import { userHasCrmAccountService } from 'service/MapPage';
import { ADAPTERS_WITH_SSO } from 'components/constants';
import { IsAutoUpdateCountUsers } from 'service/types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { reverseWithSearch, routes } from '../../routes';
import cloneDeep from 'lodash/cloneDeep';
import subscriptionManagerFactory from 'service/SubscriptionManager';
import dispatcher from 'service/dispatcher';
import events from '../../events';
import { TravelingPreferencesMode } from './TravelingPreferences/TravelingPreferencesForm';
import { accountsManager } from '../../service/AccountsManager';
import CalendarPreferencesForm from './CalendarPreferences/CalendarPreferencesForm';
import ChangePasswordForm from '../ChangePasswordForm';
import CurrentPasswordForm from '../CurrentPasswordForm';
import PureFormDialog from '../PureFormDialog';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import DialogAvatarEditor, { AvatarEditMode } from '../MainMenu/avatar';
import { avatarService } from '../../service/Avatar/AvatarService';
import { TAB_ACCOUNT_SUBSCRIPTION_BILLING } from '../AccountPage/types';
import { withStyles } from '@material-ui/core/styles';
import usersPermissionsManager from '../../service/Permissions/UsersPermissionsManager';

const EXTERNAL_ID_ACCURACY = {
    MANUAL: 3,
    EXACT: 2,
    PROBABLE: 1,
};

class UserForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.isNewUser = !(props.user.id > 0);

        this.canEditUserHasCrmAccount = false;

        this.state = {
            currentUser: null,
            user: cloneDeep(props.user),
            roles: [],
            dataSources: [],
            errors: new Map(),
            changePasswordMode: !(props.user && props.user.id),
            currentPassword: null,
            saving: false,
            showScheduleConsistencePopup: false,
            userTimeZoneOffsetInHours: props.user.actualTimezoneOffset / 60,
            deviceTimeZoneOffsetInHours: getDeviceTimezoneOffsetInMinutes() / 60,
            isScheduleValid: true,
            isScheduleEnabled: locationTrackingSettingsStorage.isGeolocationTrackingAutoSwitchingEnabled(),
            showDialogYouCanConnectYourUsersViaSSO: false,
            subscription: null,
            users: null,
            showAvatarForm: false,
            showAvatarCondition: false,
        };

        this.locationTrackingForm = React.createRef();
        this.travelingPreferencesForm = React.createRef();
        this.calendarPreferencesForm = React.createRef();
        this.changePasswordForm = React.createRef();
        this.currentPasswordForm = React.createRef();

        /** @type {DataSource.SimpleDataSource|null} */
        this.primaryDataSource = null;
        /** @type {Boolean} */
        this.alreadyShowDialogYouCanConnectYourUsersViaSSO = false;

        this.userHasViewCalendarPreferencesPermission = usersPermissionsManager.hasCalendarViewPreferencesPermission;
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_ACCOUNT_AVATAR_CHANGED], this, () => {
            this.forceUpdate();
        });
        dispatcher.subscribe(events.GOOGLE_CALENDAR_DISCONNECTED, this, (data) => {
            const user = this.state.user;
            const googleCalendarExternalIndex = user.externalUsers.findIndex(
                (externalUser) => externalUser.dataSourceId === data.dsId,
            );

            if (googleCalendarExternalIndex !== -1) {
                user.externalUsers[googleCalendarExternalIndex].externalId = '';
            }

            this.setState({ user: { ...user } });
        });

        const { user } = this.state;
        if (!user.accountId) {
            userManager.requestCurrentUser().then((user) => {
                this.setState({
                    roles: [...roleManager.getSystemRoles()],
                    currentUser: { ...user },
                });
            });
            return;
        }

        const manager = dsManagerFactory.getManager(user.accountId);
        Promise.all([
            roleManager.getAccountRoles(user.accountId),
            userManager.requestCurrentUser(),
            manager.list(),
            this.loadSubscription(),
            this.loadUsersList(),
        ]).then((result) => {
            const dataSources = [...result[2]];

            if (this._canEditUserHasCrmAccount(user)) {
                this.primaryDataSource = userHasCrmAccountService.getPrimaryDataSource(dataSources);
                this.canEditUserHasCrmAccount = this.primaryDataSource !== null;
            }

            this.setState(
                {
                    roles: [...result[0]],
                    currentUser: { ...result[1] },
                    dataSources,
                    subscription: { ...result[3] },
                    users: [...result[4]],
                    avatar: avatarService.getCurrentAvatar().url,
                },
                () => {
                    this.props.onFormLoaded && this.props.onFormLoaded();
                },
            );
        });

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.account !== null && this.props.account.id === subscription.accountId) {
                this.setState({
                    subscription,
                });
            }
        });
        dispatcher.subscribe(
            [events.EVENT_USER_CHANGED, events.EVENT_USER_CREATED, events.WS_USERS_DELETED],
            this,
            () => {
                // todo: refresh this.state.currentUser on change (n.b.: "hey" and "user_list" returns differs)
                this.refreshUsersList();
            },
        );
        dispatcher.subscribe(
            [
                events.EVENT_ROLE_CREATED,
                events.EVENT_ROLE_CHANGED,
                events.EVENT_ROLES_CHANGED,
                events.EVENT_ROLE_DELETED,
            ],
            this,
            () => {
                this.refreshRolesList();
            },
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.errors !== this.state.errors && this.props.onSubmitError) {
            if (this.state.errors.size > 0) {
                this.props.onSubmitError(
                    this.props.t('account.user.not_saved', {
                        user: this.state.user.name ? ` "${this.state.user.name}" ` : ' ',
                    }),
                );
            } else {
                this.props.onSubmitError(null);
            }
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadSubscription = () => {
        let subManager = subscriptionManagerFactory.getManager(this.props.account.id);
        return subManager.getSubscription();
    };

    refreshRolesList = () => {
        const { user } = this.state;
        if (!user.accountId) {
            return;
        }
        const promise = roleManager.getAccountRoles(user.accountId);

        promise.then((roles) => {
            this.setState({
                roles: [...roles],
            });
        });
    };

    refreshUsersList = () => {
        this.loadUsersList().then((users) => {
            this.setState({
                users: [...users],
            });
        });
    };

    handleGetCurrentPassword = (currentPassword) => {
        this.setState(
            {
                currentPassword,
            },
            () => {
                this.submit();
            },
        );
    };

    submit = async () => {
        const { t, onSubmitSuccess, onSubmitError } = this.props;
        const { isScheduleValid, changePasswordMode } = this.state;
        const user = cloneDeep(this.state.user);
        const currentUser = userManager.getCurrentUser();

        // avatar is handled separately from this operation
        if (user.id === currentUser.id) {
            user.avatar = currentUser.avatar;
        }

        if (!this.travelingPreferencesForm.current.isValid()) {
            onSubmitError && onSubmitError(t('account.user.not_saved', { user: user.name ? ` "${user.name}" ` : ' ' }));
            userPropertiesManager.changeTab(UserPropertiesTab.TAB_TRAVELING);
            return;
        }

        if (!isScheduleValid) {
            onSubmitError && onSubmitError(t('account.user.not_saved', { user: user.name ? ` "${user.name}" ` : ' ' }));
            userPropertiesManager.changeTab(UserPropertiesTab.TAB_TRAVELING);
            return;
        }

        try {
            await this.validatePersonalTab();
        } catch (e) {
            onSubmitError && onSubmitError(t('account.user.not_saved', { user: user.name ? ` "${user.name}" ` : ' ' }));
            userPropertiesManager.changeTab(UserPropertiesTab.TAB_PERSONAL);
            return;
        }

        const isSelectedRoleSharedMap = this.isSelectedRoleSharedMap();

        if (
            this.canEditUserHasCrmAccount &&
            !isSelectedRoleSharedMap &&
            ADAPTERS_WITH_SSO.has(this.primaryDataSource.adapterId)
        ) {
            if (!this.alreadyShowDialogYouCanConnectYourUsersViaSSO && user.hasCrmAccount) {
                this.alreadyShowDialogYouCanConnectYourUsersViaSSO = true;
                this.setState({ showDialogYouCanConnectYourUsersViaSSO: true });
                return;
            }
        }

        user.routingPreferences = { ...this.travelingPreferencesForm.current.getUserPreferences() };
        user.calendarPreferences = this.userHasViewCalendarPreferencesPermission
            ? { ...this.calendarPreferencesForm.current.getUserPreferences() }
            : this.props.account.defaultCalendarPreferences;

        if (!this.canEditUserHasCrmAccount || isSelectedRoleSharedMap) {
            delete user.hasCrmAccount;
        }
        if (!userManager.isRoleSuperAdmin()) {
            delete user.leftReview;
        }

        let needToEndUserSessions = false;
        if (changePasswordMode) {
            user.password = this.changePasswordForm.current.getPassword();
            needToEndUserSessions = this.changePasswordForm.current.getNeedToEndUserSessions();
        }

        if (user.email === this.props.user.email) {
            delete user.email;
        }

        this.setState({
            saving: true,
        });

        let updatedUser;
        try {
            updatedUser = await userManager.saveUser(user, this.state.currentPassword);
        } catch (error) {
            let openConfirmPassword = error.details.has(KEY_CONFIRM_PASSWORD) && error.details.size === 1;
            const errors = new Map(error.details);
            if (this.state.currentPassword === null) {
                // Пароль еще не вводился, поэтому не показываем ошибку.
                errors.delete(KEY_CONFIRM_PASSWORD);
            }

            this.setState({
                errors,
                currentPassword: openConfirmPassword ? this.state.currentPassword || '' : null,
                saving: false,
            });
            if (openConfirmPassword) {
                return;
            }
            onSubmitError &&
                onSubmitError(
                    error.message || t('account.user.not_saved', { user: user.name ? ` "${user.name}" ` : ' ' }),
                );
            userPropertiesManager.changeTab(UserPropertiesTab.TAB_PERSONAL);

            return;
        }

        this.setState(
            {
                user: { ...updatedUser },
                currentPassword: null,
                saving: false,
            },
            async () => {
                if (userManager.isRoleAdmin()) {
                    const account = { ...this.props.account };
                    account.routingPreferences = this.travelingPreferencesForm.current.getAccountPreferences();

                    if (this.userHasViewCalendarPreferencesPermission) {
                        account.defaultCalendarPreferences =
                            this.calendarPreferencesForm.current.getAccountPreferences();
                    }

                    accountsManager.save(account);
                }

                try {
                    await this.locationTrackingForm.current.submit();
                } finally {
                    onSubmitSuccess && onSubmitSuccess(updatedUser);
                }
            },
        );

        if (needToEndUserSessions) {
            await userManager.logoutUser(updatedUser.id);
        }
    };

    quiet() {
        this.setState({
            errors: new Map(),
        });
    }

    handleInputChange = (event) => {
        const value = event.target.value || null;
        const name = event.target.name;

        if (name === 'timezone') {
            this.setState({
                showScheduleConsistencePopup: !ScheduleConsistencePopupManager.wasPopupShown(),
            });
        }

        this.setState((state) => {
            const user = { ...state.user };
            user[name] = value;
            let errors = state.errors;
            if (errors.has(name)) {
                errors = new Map(errors);
                errors.delete(name);
            }

            return { user, errors };
        });
    };

    handleTimezoneChange = (value) => {
        if (value === null || value.offset === null) {
            this.setState({
                userTimeZoneOffsetInHours: this.props.account.timezoneOffset / 60,
            });

            return;
        }

        this.setState({
            userTimeZoneOffsetInHours: value.offset,
        });
    };

    handleRoleChange = (event) => {
        const value = event.target.value || null;
        let changePasswordMode = this.state.changePasswordMode;
        const user = cloneDeep(this.state.user);
        let errors = new Map(this.state.errors);
        const role = this.state.roles.find((r) => r.code === value);
        if (user.role && user.role.forSharedMap && user.hasSharedMaps) {
            if (!role || !role.forSharedMap) {
                errors.set('roleSharedMap', true);
                this.setState({
                    errors,
                });
                return;
            }
        }
        if (role) {
            user.role = role;
            if (user.role.forSharedMap && this.isNewUser) {
                user.email = '';
                changePasswordMode = false;
            }
            if (!user.role.forSharedMap && this.isNewUser) {
                changePasswordMode = true;
            }
        } else {
            user.role = { code: null };
        }

        if (errors.has('role')) {
            errors.delete('role');
        }
        errors = this.validateCountUsers(user, errors);
        if (errors.has('roleSharedMap')) {
            errors.delete('roleSharedMap');
        }

        this.setState({
            user,
            errors,
            changePasswordMode,
        });
    };

    validateCountUsers = (user, errorsOld) => {
        const { t } = this.props;
        const { subscription } = this.state;
        if (!subscription) {
            return errorsOld;
        }
        const autoUpdateCountUsers = IsAutoUpdateCountUsers(subscription);
        const countOfPaidUsers = this.calculateCountOfPaidUser(user);
        const isDisableSave =
            subscription && autoUpdateCountUsers === false && countOfPaidUsers > subscription.usersAllowed;

        if (!isDisableSave) {
            return errorsOld;
        }

        let error = (
            <Grid container>
                <Grid item>{t('account.users.limit.reached')}</Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        <span>
                            {t('account.users.limit.all_occupied', { usersAllowed: subscription.usersAllowed })}
                            <Link
                                style={{ marginLeft: 5 }}
                                to={reverseWithSearch(
                                    routes.admin.account.index,
                                    { accountId: this.props.account.id },
                                    { tab: TAB_ACCOUNT_SUBSCRIPTION_BILLING },
                                )}
                                onClick={this.handleOpenSubscriptionTab}
                            >
                                {t('account.users.limit.add')}
                            </Link>
                        </span>
                    </Typography>
                </Grid>
            </Grid>
        );
        let errors = new Map(errorsOld);
        errors.set('role', error);
        return errors;
    };

    handleOpenSubscriptionTab = () => {
        userPropertiesManager.closeModal();

        dispatcher.dispatch(events.CLICK_MENU_OPEN.ACCOUNT, TAB_ACCOUNT_SUBSCRIPTION_BILLING);
    };

    handleExternalChange = (event) => {
        const value = event.target.value;
        const dsId = parseInt(event.target.name);
        this.setState((state) => {
            const user = { ...state.user };
            const externalUsers = [...user.externalUsers];
            for (let i = 0; i < externalUsers.length; i++) {
                if (externalUsers[i].dataSourceId === dsId) {
                    externalUsers[i] = {
                        ...externalUsers[i],
                        externalId: value,
                        accuracy: EXTERNAL_ID_ACCURACY.MANUAL,
                    };
                    user.externalUsers = externalUsers;
                    return {
                        user,
                    };
                }
            }
            externalUsers.push({
                dataSourceId: dsId,
                externalId: value,
                accuracy: EXTERNAL_ID_ACCURACY.MANUAL,
            });
            user.externalUsers = externalUsers;
            return {
                user,
            };
        });
    };

    async validatePersonalTab() {
        if (this.state.changePasswordMode) {
            await this.changePasswordForm.current.validate();
        }

        const { t } = this.props;
        const { user } = this.state;

        let newErrors = new Map();
        newErrors = this.validateCountUsers(user, newErrors);
        if (this.canEditUserHasCrmAccount && !this.isSelectedRoleSharedMap() && user.hasCrmAccount === null) {
            newErrors.set('hasCrmAccount', t('validation.value.required'));
        }

        if (newErrors.size === 0) {
            return;
        }

        this.setState((state) => {
            const errors = new Map([...state.errors, ...newErrors]);
            return { errors };
        });

        throw new Error();
    }

    handleTogglePassword = (event) => {
        const changePasswordMode = event.target.checked;
        this.setState({
            changePasswordMode,
        });
    };

    handleLeftReviewChanged = () => {
        this.setState((state) => {
            return {
                user: { ...state.user, leftReview: !state.user.leftReview },
            };
        });
    };

    handleCloseConfirmPassword = () => {
        const errors = new Map(this.state.errors);
        errors.delete(KEY_CONFIRM_PASSWORD);
        this.setState({
            errors,
            currentPassword: null,
        });
        this.props.onSubmitError(null);
    };

    handleChangeTab = (e, tab) => {
        userPropertiesManager.changeTab(tab);
    };

    handleScheduleChange = (workingDays) => {
        this.props.onSubmitError && this.props.onSubmitError(null);

        this.setState({
            showScheduleConsistencePopup: !ScheduleConsistencePopupManager.wasPopupShown(),
            isScheduleValid: WorkSchedule.isScheduleValid(workingDays),
        });
    };

    handleScheduleEnable = (enabled) => {
        this.setState({
            isScheduleEnabled: enabled,
        });
    };

    handleHasCrmAccount = (hasCrmAccount) => {
        const user = {
            ...this.state.user,
            hasCrmAccount,
        };
        const errors = new Map(this.state.errors);
        errors.delete('hasCrmAccount');
        this.setState({ user, errors });
    };

    handleCloseYouCanConnectYourUsersViaSSODialog = () => {
        this.props.onSubmitError(null);
        this.setState({ showDialogYouCanConnectYourUsersViaSSO: false });
    };

    formatTimezoneOffsetForHuman = (tzOffsetInHours) => {
        let sign = '-';
        if (tzOffsetInHours >= 0) {
            sign = '+';
        }
        tzOffsetInHours = Math.abs(tzOffsetInHours);

        let hours = Math.floor(tzOffsetInHours);
        const minutesInHour = 60;
        let minutes = minutesInHour * (tzOffsetInHours % 1);

        return sign + hours + (minutes > 0 ? ':' + minutes : '');
    };

    _canEditUserHasCrmAccount = (user) => {
        if (userManager.isSharedMapUser()) {
            return false;
        }
        const isAdmin = userManager.isSwitched() || userManager.isRoleSuperAdmin() || userManager.isRoleAdmin();
        if (!isAdmin) {
            return false;
        }
        return this.isNewUser || (user.ssoProvider === null && user.hasCrmAccount === null);
    };

    /**
     * @return {Boolean}
     * @private
     */
    isSelectedRoleSharedMap = () => {
        const { user } = this.state;

        return user.role && user.role.forSharedMap;
    };

    loadUsersList = () => {
        return userManager.getAccountUsers(this.props.account.id);
    };

    calculateCountOfPaidUser(user) {
        const { subscription, users } = this.state;

        let countUsers = 0;

        users.forEach((itemUser) => {
            if (
                user.id !== itemUser.id &&
                (itemUser.role.forSharedMap === false || subscription.isNeedCalculationSharedMapUser)
            ) {
                countUsers++;
            } else if (
                user.id === itemUser.id &&
                (user.role.forSharedMap === false || subscription.isNeedCalculationSharedMapUser)
            ) {
                countUsers++;
            }
        });

        if (
            user.role &&
            this.isNewUser &&
            (user.role.forSharedMap === false || subscription.isNeedCalculationSharedMapUser)
        ) {
            countUsers++;
        }

        return countUsers;
    }

    handleAvatarFormOpen = () => {
        this.setState({
            showAvatarForm: true,
        });
    };

    handleAvatarPencil = (e) => {
        e.stopPropagation();
        this.setState({
            showAvatarForm: true,
            showAvatarCondition: true,
        });
    };

    handleCloseAvatarCondition = () => {
        this.setState({
            showAvatarForm: true,
            showAvatarCondition: false,
        });
    };

    handleCloseAvatarForm = () => {
        this.setState({
            showAvatarForm: false,
            showAvatarCondition: false,
            avatar: avatarService.getCurrentAvatar().url,
        });
    };

    render() {
        const { t, currentTab, bounds, account, focusElement } = this.props;
        const {
            currentUser,
            user,
            errors,
            userTimeZoneOffsetInHours,
            deviceTimeZoneOffsetInHours,
            isScheduleEnabled,
            showDialogYouCanConnectYourUsersViaSSO,
            showAvatarForm,
        } = this.state;

        const avatar = avatarService.getCurrentAvatar().url;

        if (currentUser === null) {
            return t('loading');
        }

        const currentTimezone = timezones.find((timezone) => timezone.name === this.state.user.timezone);

        const isSelectedRoleSharedMap = this.isSelectedRoleSharedMap();
        const canEditUserHasCrmAccount = this.canEditUserHasCrmAccount && !isSelectedRoleSharedMap;
        const showEmail =
            !isSelectedRoleSharedMap ||
            (user.email && user.email !== '') ||
            (this.props.user.email && this.props.user.email !== '');

        const roleError = this.state.errors.get('role');
        const roleErrorText = roleError && typeof roleError === 'string' ? roleError.replace(/^code: /, '') : roleError;
        const isUserEditingSelf = currentUser.id === user.id;
        const isUserNew = !user.id;

        return (
            <>
                <Tabs
                    value={currentTab}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    variant="fullWidth"
                    centered
                >
                    <StyledTab label={t('user_form.form.tab.personal.label')} />
                    <StyledTab label={t('user_form.form.tab.traveling.label')} />
                    <StyledTab label={t('user_form.form.tab.location_tracking.label')} />
                    {this.userHasViewCalendarPreferencesPermission && (
                        <StyledTab label={t('user_form.form.tab.calendar_preferences.label')} />
                    )}
                </Tabs>
                <form
                    className="c-user-form"
                    style={{ minHeight: '65.7vh' }}
                    onSubmit={(event) => event.preventDefault()}
                >
                    {userTimeZoneOffsetInHours !== deviceTimeZoneOffsetInHours && isScheduleEnabled && (
                        <FormAlert type="warning" style={{ marginTop: '20px' }}>
                            <RestrictionMessage
                                text={this.props.t('schedule.device_and_user_timezone_are_different', {
                                    userTimeZoneOffsetInHours:
                                        this.formatTimezoneOffsetForHuman(userTimeZoneOffsetInHours),
                                    deviceTimeZoneOffsetInHours:
                                        this.formatTimezoneOffsetForHuman(deviceTimeZoneOffsetInHours),
                                })}
                            />
                        </FormAlert>
                    )}
                    <TabPanel value={currentTab} index={UserPropertiesTab.TAB_PERSONAL}>
                        {isUserEditingSelf && (
                            <Box className={'preference__big_avatar_box'}>
                                <Avatar
                                    className="preference__big_avatar"
                                    alt={user.name}
                                    src={avatar}
                                    onClick={this.handleAvatarFormOpen}
                                />
                                {avatar.includes('http') && (
                                    <Box onClick={this.handleAvatarPencil} className={'preference__big_avatar_pencil'}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <TextField
                            label={t('user_form.form.name.label')}
                            data-testid="user_form.form.name"
                            fullWidth
                            margin="dense"
                            name="name"
                            value={this.state.user.name || ''}
                            helperText={this.state.errors.get('name') || ''}
                            error={this.state.errors.has('name')}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                            disabled={this.state.user.sso}
                            autoComplete={isUserEditingSelf ? 'name' : 'off'}
                        />
                        <FormControl
                            fullWidth
                            margin="dense"
                            error={this.state.errors.has('role') || this.state.errors.has('roleSharedMap')}
                            disabled={isUserEditingSelf || this.state.user.sso}
                        >
                            <InputLabel>{t('user_form.form.role.label')}</InputLabel>
                            <Select
                                name="role"
                                value={this.state.user?.role?.code}
                                onChange={this.handleRoleChange}
                                data-testid="user_form.form.role"
                                renderValue={(value) =>
                                    this.state.roles.find(({ code }) => code === value)?.name ?? null
                                }
                            >
                                {this.state.roles
                                    .filter((role) => this.isNewUser || isSelectedRoleSharedMap === role.forSharedMap)
                                    .map((role) => (
                                        <MenuItem key={role.id} value={role.code}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {roleError && <FormHelperText>{roleErrorText}</FormHelperText>}
                            {this.state.errors.get('roleSharedMap') && (
                                <FormHelperText>
                                    {this.props.t('user_form.errors.role.shared_map')}{' '}
                                    <LandingLink article="5198246" useTrailingIcon>
                                        {this.props.t('user_form.errors.role.shared_map.learn_more')}
                                    </LandingLink>
                                </FormHelperText>
                            )}
                        </FormControl>
                        {showEmail && (
                            <TextField
                                label={t('user_form.form.email.label')}
                                data-testid="user_form.form.email"
                                fullWidth
                                margin="dense"
                                name="email"
                                value={this.state.user.email || ''}
                                helperText={this.state.errors.get('email') || ''}
                                error={this.state.errors.has('email')}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.handleInputChange}
                                disabled={this.state.user.sso}
                                autoComplete={isUserEditingSelf ? 'email' : 'off'}
                            />
                        )}

                        <FormControl fullWidth margin="dense" error={this.state.errors.has('timezone')}>
                            <InputLabel shrink>{t('user_form.form.timezone.label')}</InputLabel>
                            <Autocomplete
                                options={timezones}
                                value={currentTimezone || null}
                                getOptionLabel={(timezone) => stringifyTimezone(timezone)}
                                onChange={(event, value) => {
                                    this.handleInputChange({ target: { name: 'timezone', value: value?.name } });
                                    this.handleTimezoneChange(value);
                                }}
                                disabled={this.state.user.sso}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        data-testid="user_form.form.timezone"
                                        placeholder={t('user_form.form.timezone.items.default')}
                                        margin="normal"
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="dense" error={this.state.errors.has('language')}>
                            <InputLabel shrink>{t('user_form.form.language.label')}</InputLabel>
                            <Autocomplete
                                options={LANGUAGES}
                                value={this.state.user.language || null}
                                getOptionLabel={(language) => t('user_form.form.language.' + language)}
                                onChange={(event, value) => {
                                    this.handleInputChange({ target: { name: 'language', value } });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        data-testid="user_form.form.language"
                                        placeholder={t('user_form.form.language.items.default')}
                                        margin="normal"
                                    />
                                )}
                            />
                            {this.state.errors.get('language') && (
                                <FormHelperText>{this.state.errors.get('language')}</FormHelperText>
                            )}
                        </FormControl>

                        {canEditUserHasCrmAccount && (
                            <HasCrmAccountControl
                                value={user.hasCrmAccount}
                                crmName={this.primaryDataSource.name}
                                errorMessage={errors.get('hasCrmAccount') ?? null}
                                onChange={this.handleHasCrmAccount}
                            />
                        )}

                        {!this.isNewUser && !user.role.forSharedMap && (
                            <FormControlLabel
                                margin="dense"
                                control={
                                    <Switch
                                        checked={this.state.changePasswordMode}
                                        onChange={this.handleTogglePassword}
                                        color="primary"
                                        data-testid="user_form.form.change_password"
                                    />
                                }
                                label={t('user_form.form.change_password.label')}
                            />
                        )}
                        {this.state.changePasswordMode && (
                            <Suspense fallback={'Loading...'}>
                                <ChangePasswordForm
                                    errors={this.state.errors}
                                    user={user}
                                    ref={this.changePasswordForm}
                                />
                            </Suspense>
                        )}
                        {userManager.isRoleSuperAdmin() && (
                            <FormControl fullWidth>
                                <FormControlLabel
                                    margin="dense"
                                    control={
                                        <Checkbox
                                            checked={this.state.user.leftReview}
                                            onChange={this.handleLeftReviewChanged}
                                            color="primary"
                                            data-testid="user_form.form.change_left_review"
                                        />
                                    }
                                    label={t('account.user.personal_settings.left_review')}
                                />
                            </FormControl>
                        )}
                        <div style={{ marginTop: '30px' }}>{t('user_form.form.ds_crm_user_ids')}</div>
                        {this.state.dataSources.map((ds) => {
                            if (ds.isSystem) {
                                return null;
                            }
                            const externalUser = this.state.user.externalUsers.find(
                                (externalUser) => externalUser.dataSourceId === ds.id,
                            );
                            const disabled =
                                (externalUser && externalUser.accuracy === EXTERNAL_ID_ACCURACY.EXACT) ||
                                ds.adapterId === AdapterId.GOOGLECALENDAR;

                            return (
                                <TextField
                                    key={ds.id}
                                    label={ds.name}
                                    fullWidth
                                    margin="dense"
                                    name={ds.id.toString()}
                                    disabled={disabled}
                                    value={externalUser ? externalUser.externalId : ''}
                                    InputProps={{ disableUnderline: false }}
                                    onChange={this.handleExternalChange}
                                    autoComplete="off"
                                />
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={currentTab} index={UserPropertiesTab.TAB_TRAVELING}>
                        <TravelingPreferencesForm
                            account={account}
                            user={user}
                            bounds={bounds}
                            focusElement={focusElement}
                            ref={this.travelingPreferencesForm}
                            onScheduleChange={this.handleScheduleChange}
                            mode={isUserNew ? TravelingPreferencesMode.NEW_USER : TravelingPreferencesMode.PERSONAL}
                            onClose={this.props.onClose}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={UserPropertiesTab.TAB_LOCATION_TRACKING}>
                        <LocationTrackingForm
                            ref={this.locationTrackingForm}
                            onScheduleEnable={this.handleScheduleEnable}
                        />
                    </TabPanel>
                    {this.userHasViewCalendarPreferencesPermission && (
                        <TabPanel value={currentTab} index={UserPropertiesTab.TAB_CALENDAR}>
                            <CalendarPreferencesForm ref={this.calendarPreferencesForm} account={account} user={user} />
                        </TabPanel>
                    )}
                </form>
                {this.state.showScheduleConsistencePopup && !weAreInNativeApp() && <ScheduleConsistencePopup />}

                <PureFormDialog
                    open={this.state.currentPassword !== null}
                    onClose={this.handleCloseConfirmPassword}
                    title={t('user_form.form.confirm_password.title')}
                >
                    <CurrentPasswordForm
                        error={this.state.errors.get(KEY_CONFIRM_PASSWORD)}
                        loading={this.state.saving}
                        onSave={this.handleGetCurrentPassword}
                        ref={this.currentPasswordForm}
                    />
                </PureFormDialog>

                {showDialogYouCanConnectYourUsersViaSSO && (
                    <YouCanConnectYourUsersViaSSODialog onClose={this.handleCloseYouCanConnectYourUsersViaSSODialog} />
                )}

                {showAvatarForm && (
                    <DialogAvatarEditor
                        user={user}
                        onClose={this.handleCloseAvatarForm}
                        mode={AvatarEditMode.Loading}
                        showAvatarCondition={this.state.showAvatarCondition}
                        onCloseAvatarCondition={this.handleCloseAvatarCondition}
                    />
                )}
            </>
        );
    }
}

UserForm.propTypes = {
    user: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    currentTab: PropTypes.oneOf([
        UserPropertiesTab.TAB_PERSONAL,
        UserPropertiesTab.TAB_TRAVELING,
        UserPropertiesTab.TAB_LOCATION_TRACKING,
        UserPropertiesTab.TAB_CALENDAR,
    ]).isRequired,
    onClose: PropTypes.func,
};

const StyledTab = withStyles({
    root: {
        minWidth: 'auto',
    },
})(Tab);

export default withTranslation('translations', { withRef: true })(UserForm);
