import React from 'react';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import { WithTranslation, withTranslation } from 'react-i18next';
import subscriptionManagerFactory from '../../service/SubscriptionManager';

interface SwitchToCustomProps extends WithTranslation {
    accountId: number;
    onCloseDialog: () => void;
    onSwitchFailure?: (message: string) => void;
    onSwitchSuccess: (message: string) => void;
}

interface SwitchToCustomState {
    showCloseConfirmation: boolean;
}

class SwitchToCustomForm extends React.Component<SwitchToCustomProps, SwitchToCustomState> {
    constructor(props: SwitchToCustomProps) {
        super(props);

        this.state = {
            showCloseConfirmation: true,
        };
    }

    confirmSwitchToCustom = () => {
        const accountId = this.props.accountId;
        const manager = subscriptionManagerFactory.getManager(accountId);
        manager
            .switchToCustom()
            .then(() => {
                this.props.onSwitchSuccess &&
                    this.props.onSwitchSuccess(this.props.t('billing.super_admin_panel.switch_form.success'));
            })
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.props.onSwitchFailure && this.props.onSwitchFailure(error.message);
            });
    };

    setCloseConfirmation = (showCloseConfirmation: boolean) => {
        this.setState({ showCloseConfirmation });
    };

    render() {
        return (
            <DialogConfirmation
                open={this.state.showCloseConfirmation}
                setOpen={this.setCloseConfirmation}
                onReject={this.props.onCloseDialog}
                onConfirm={this.confirmSwitchToCustom}
                titleText={this.props.t('billing.super_admin_panel.switch_form.title')}
            ></DialogConfirmation>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SwitchToCustomForm);
