import React from 'react';
import { Link } from 'react-router-dom';
import { reverse, routes } from '../../routes';
import { userManager } from '../../service/UserManager';
import Alert from '../Alert/index';
import RestrictionMessage from '../RestrictionMessage/index';
import i18n from '../../locales/i18n';
import LandingLink, { ARTICLE_AUTOMATION } from '../HelpLink/LandingLink';
import { Link as MuiLink } from '@material-ui/core';
import { intercomManager } from '../../service/IntercomManager';

const t = i18n.t.bind(i18n);

class AutomationRestriction extends React.PureComponent {
    renderMessage = (message: string, link: (text: string) => React.ReactNode) => {
        const m = message.match(/^(.*)<a>(.+)<\/a>(.*)$/);
        return m === null ? (
            message
        ) : (
            <React.Fragment>
                {m[1]}
                {link(m[2])}
                {m[3]}
            </React.Fragment>
        );
    };

    handleInChatClick = (event: React.MouseEvent) => {
        event.preventDefault();
        intercomManager.showNewMessage(t('automation_elements.no_restriction.implementation_help'));
    };

    render() {
        if (userManager.automationElementsManagement()) {
            return (
                <Alert type="success" placement="context">
                    <div style={{ textAlign: 'center' }}>
                        <i className="fas fa-info-circle" style={{ marginRight: 8, fontSize: '150%' }} />
                        {this.renderMessage(t('automation_elements.no_restriction.part1'), (text) => (
                            <MuiLink href="#" onClick={this.handleInChatClick} underline="always">
                                {text}
                            </MuiLink>
                        ))}
                        <span dangerouslySetInnerHTML={{ __html: t('automation_elements.no_restriction.part2') }} />
                    </div>
                </Alert>
            );
        }

        return (
            userManager.hasEssentialRestrictions() && (
                <Alert type="warning" placement="context">
                    <RestrictionMessage
                        text={
                            <React.Fragment>
                                {this.renderMessage(t('automation_elements.restriction'), (text) => (
                                    <Link to={reverse(routes.admin.account.billing.subscribe)}>{text}</Link>
                                ))}
                                <LandingLink useTrailingIcon article={ARTICLE_AUTOMATION}>
                                    {t('automation_elements.restriction.learn_more')}
                                </LandingLink>
                            </React.Fragment>
                        }
                    />
                </Alert>
            )
        );
    }
}

export default AutomationRestriction;
