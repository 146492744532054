import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ToCalendarContextMenu from './ToCalendarContextMenu';
import './style.css';
import { userManager } from '../../../service/UserManager';
import metadataManager from '../../../service/MetadataManager';
import { Entity } from '../../../interfaces/entity';
import { DataSource } from '../../../interfaces';
import {
    getAvailableCalendarsForUser,
    getCalendarId,
    getCalendarRelatedEntityField,
    getCurrentAccountActiveCalendars,
    getNoCalendarsMessage,
} from '../Helpers/CalendarHelper';
import CalendarSettingsDialog from '../../CalendarSettings/CalendarSettingsDialog/CalendarSettingsDialog';
import PureFormDialog from '../../PureFormDialog';
import { PopupData, PopupType } from '../settings';
import CalendarFormPopups from '../FormPopups/CalendarFormPopups';
import LeftClickContextMenu from '../ContextMenus/LeftClickContextMenu';
import { CalendarPopupManager } from '../../../service/Calendar/CalendarPopupManager';
import { Calendar, Calendars } from '../../../interfaces/calendar/calendar';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import { CalendarEventManager, CalendarEventsSuccessPayload } from '../../../service/Calendar/CalendarEventManager';
import { CalendarEventField } from '../../../service/Calendar/CalendarEventRepository';
import { waitingListManager } from '../../../service/Calendar/WaitingListManager';
import { v4 as uuidv4 } from 'uuid';
import { recordManager } from '../../../service/RecordManager';
import {
    formatAddressForWaitingList,
    getAddressFromRecord,
    transformGeoStatusToLocationValidity,
} from '../../../utils';
import { GEO_FIELDS } from '../../../references/geoFields';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Activity } from '../../../interfaces/routing/route';
import { getStartTimeFromActivity } from '../Helpers/CalendarEventHelper';
import { addSeconds } from 'date-fns';
import usersPermissionsManager from '../../../service/Permissions/UsersPermissionsManager';

interface Props extends WithTranslation, WithSnackbarProps {
    entityId: number;
    pointId?: string;
    onClose?: () => void;
    removeInsteadOfDisabling?: boolean;
    setPopupData?: (openPopupType?: PopupType, popupData?: PopupData, callback?: () => void) => void;
    activity?: Activity;
}

interface State {
    anchorEl?: Element;
    entity?: Entity;
    dataSource?: DataSource.DataSource;
    noCalendarsWarningMessage?: React.JSX.Element | string;
    calendarSettingsOpen: boolean;
    openPopupType?: PopupType;
    popupData?: PopupData;
    availableCalendars: Calendars;
    recordData?: RecordData;
    addToWaitingListButtonLoading: boolean;
}

type RecordData = {
    entityId: number;
    recordId: string;
    objectName: string;
    address: string;
    displayAddress: string;
    lat: string;
    lng: string;
    entityLabel: string;
    entityColor: string;
    geocoderStatus: number;
    startTime?: string;
    endTime?: string;
};

class ToCalendarButton extends React.Component<Props, State> {
    private calendarEventManager: CalendarEventManager = new CalendarEventManager();
    private calendarPopupManager?: CalendarPopupManager;

    constructor(props: Props) {
        super(props);

        this.state = {
            anchorEl: undefined,
            calendarSettingsOpen: false,
            openPopupType: undefined,
            popupData: undefined,
            availableCalendars: new Map<string, Calendar>(),
            addToWaitingListButtonLoading: false,
        };
    }

    async componentDidMount() {
        await this.updateRecordData();
        this.subscribeToEvents();
        await this.updateCalendarRelatedData();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            prevProps.entityId !== this.props.entityId ||
            prevProps.pointId !== this.props.pointId ||
            prevProps.activity !== this.props.activity
        ) {
            await this.updateRecordData();
        }
    }

    componentWillUnmount = (): void => {
        this.calendarEventManager.dispose();
        dispatcher.unsubscribeFromAllEvents(this);
    };

    private updateRecordData = async (): Promise<void> => {
        if (!this.props.pointId) {
            return;
        }

        const entity: Entity | undefined = await metadataManager.requestEntity(this.props.entityId);
        if (!entity) {
            return;
        }
        const dataSource = await metadataManager.requestDataSourceForUser(entity.dataSource.id);
        let pinField: any = null;

        if ((entity as any).pinField) {
            for (const field of entity.fields) {
                if (field.apiName === (entity as any).pinField) {
                    pinField = field.isIncluded ? field : null;
                    break;
                }
            }
        }

        const { record } = await recordManager.getRecord(this.props.entityId, this.props.pointId);
        const address = getAddressFromRecord(record, pinField);
        const recordData: RecordData = {
            entityId: this.props.entityId,
            recordId: this.props.pointId,
            objectName: record.objectName,
            address,
            displayAddress: formatAddressForWaitingList(address, record),
            lat: String(record[GEO_FIELDS.LAT]),
            lng: String(record[GEO_FIELDS.LNG]),
            entityLabel: entity.label,
            entityColor: entity.color,
            geocoderStatus: record[GEO_FIELDS.STATUS],
        };
        if (this.props.activity) {
            const startTime = getStartTimeFromActivity(this.props.activity);
            const endTime = startTime ? addSeconds(startTime, this.props.activity.serviceDuration) : undefined;

            recordData.startTime = startTime?.toISOString();
            recordData.endTime = endTime?.toISOString();
        }

        this.setState({ recordData, entity, dataSource });
    };

    private subscribeToEvents = (): void => {
        dispatcher.subscribe(
            events.CALENDAR_POPUP_UPDATED,
            this,
            (data: { manager: CalendarPopupManager; callback?: () => void }) => {
                if (data.manager !== this.calendarPopupManager) {
                    return;
                }

                let popupData = this.calendarPopupManager?.getPopupData();
                if (this.state.recordData && popupData?.calendarEvent) {
                    popupData.calendarEvent.title = this.state.recordData.objectName;
                    popupData.calendarEvent.location = this.state.recordData.address;
                    popupData.calendarEvent.lat = this.state.recordData.lat;
                    popupData.calendarEvent.lng = this.state.recordData.lng;
                    popupData.calendarEvent.geocoderStatus = this.state.recordData.geocoderStatus;
                    popupData.calendarEvent.locationValidity = transformGeoStatusToLocationValidity(
                        this.state.recordData.geocoderStatus,
                    );
                    if (this.state.recordData.startTime) {
                        popupData.calendarEvent.startDatetime = this.state.recordData.startTime.toString();
                    }
                    if (this.state.recordData.endTime) {
                        popupData.calendarEvent.endDatetime = this.state.recordData.endTime.toString();
                    }
                }
                if (this.calendarPopupManager?.getOpenPopupType() === PopupType.FULL_FORM && this.props.setPopupData) {
                    this.props.setPopupData(this.calendarPopupManager?.getOpenPopupType(), popupData, data.callback);
                    return;
                }

                this.setState(
                    {
                        openPopupType: this.calendarPopupManager?.getOpenPopupType(),
                        popupData,
                    },
                    data.callback,
                );
            },
        );
        dispatcher.subscribe(events.WS_CALENDAR_EVENT_JOB_SUCCESS, this, (data: CalendarEventsSuccessPayload) => {
            for (let i in data.events) {
                if (data.events[i].id === this.state.popupData?.calendarEvent?.id) {
                    this.closePopups();
                    return;
                }
            }
        });
        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendarRelatedData();
        });
    };

    private updateCalendarRelatedData = async (): Promise<void> => {
        const activeCalendars = await getCurrentAccountActiveCalendars();
        const availableCalendars = await getAvailableCalendarsForUser(activeCalendars, userManager.getCurrentUser().id);
        this.calendarPopupManager = new CalendarPopupManager(availableCalendars, activeCalendars);

        this.setState({ availableCalendars });
    };

    private handleClick = async (event: React.MouseEvent): Promise<void> => {
        event.stopPropagation();
        const warningMessage = await getNoCalendarsMessage(this.handleCalendarSettingsOpen);
        if (warningMessage) {
            this.setState({ noCalendarsWarningMessage: warningMessage });
            return;
        }

        const target = event.target;

        this.setState({ anchorEl: target as Element });
    };

    private handleWarningClose = (): void => {
        this.setState({ noCalendarsWarningMessage: undefined }, this.propsClose);
    };

    private handleCalendarSettingsOpen = (): void => {
        this.setState({ calendarSettingsOpen: true });
        this.handleWarningClose();
    };

    private handleCalendarSettingsClose = (): void => {
        this.setState({ calendarSettingsOpen: false }, this.propsClose);
    };

    private handleCloseContextMenu = (): void => {
        this.setState({ anchorEl: undefined }, this.propsClose);
    };

    private propsClose = (): void => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    private isDisabled = (): boolean => {
        const { entity, dataSource } = this.state;

        return !entity || !dataSource || dataSource.isSystem;
    };

    private handleCreateEventIn = async (calendarId: string): Promise<void> => {
        const relatedToField = await this.getCalendarRelatedEntityField(calendarId);
        if (!relatedToField) {
            return;
        }

        this.calendarPopupManager?.openForm(calendarId, {
            ...this.state.popupData,
            relatedToField,
        });
        this.handleCloseContextMenu();
    };

    private closePopups = (): void => {
        this.calendarPopupManager?.closePopups();
        this.handleCloseContextMenu();
    };

    private createEvent = async (): Promise<void> => {
        const { availableCalendars, anchorEl } = this.state;

        const popupData = {
            target: anchorEl,
            ownerId: userManager.getCurrentUser().id,
        };

        const calendarId = await getCalendarId(availableCalendars, popupData.ownerId);
        if (calendarId) {
            const relatedToField = await this.getCalendarRelatedEntityField(calendarId);
            if (!relatedToField) {
                return;
            }

            this.calendarPopupManager?.openForm(calendarId, { ...popupData, relatedToField });
            this.handleCloseContextMenu();
            return;
        }

        this.calendarPopupManager?.openPopup(popupData);
    };

    private getCalendarRelatedEntityField = async (calendarId: string): Promise<CalendarEventField | undefined> => {
        const recordData = this.state.recordData;
        if (!recordData || !this.props.pointId) {
            return;
        }

        const relatedToField = await getCalendarRelatedEntityField(
            calendarId,
            this.props.entityId,
            this.props.pointId,
            recordData.objectName,
        );
        if (!relatedToField) {
            this.props.enqueueSnackbar(
                this.props.t('calendar.calendar_does_not_allow_references_error', {
                    calendarName: this.state.availableCalendars.get(calendarId)?.name,
                }),
                { variant: 'error', persist: true },
            );
        }

        return relatedToField;
    };

    private addToWaitingList = async (): Promise<void> => {
        const recordData = this.state.recordData;
        if (!recordData) {
            return;
        }

        this.setState({ addToWaitingListButtonLoading: true });

        const entity: Entity | undefined = await metadataManager.requestEntity(recordData.entityId);
        if (!entity) {
            return;
        }

        await waitingListManager.add({
            id: uuidv4(),
            title: recordData.objectName,
            virtual: false,
            location: recordData.address,
            displayLocation: recordData.displayAddress,
            lat: recordData.lat,
            lng: recordData.lng,
            relatedTo: {
                entityId: recordData.entityId,
                entityLabel: entity.label,
                entityColor: entity.color,
                id: recordData.recordId,
                displayText: recordData.objectName,
            },
            geocoderStatus: recordData.geocoderStatus,
        });

        this.setState({ addToWaitingListButtonLoading: false }, this.handleCloseContextMenu);
    };

    render() {
        const {
            anchorEl,
            entity,
            noCalendarsWarningMessage,
            calendarSettingsOpen,
            popupData,
            openPopupType,
            availableCalendars,
            addToWaitingListButtonLoading,
        } = this.state;
        const { pointId, removeInsteadOfDisabling } = this.props;

        if (!pointId || !this.props.children || !React.isValidElement(this.props.children)) {
            return null;
        }
        const isDisabled = this.isDisabled();
        if (!usersPermissionsManager.hasCalendarUiPermission || (isDisabled && removeInsteadOfDisabling)) {
            return null;
        }

        return (
            <>
                {React.cloneElement(this.props.children, { onClick: this.handleClick, disabled: isDisabled })}
                {!!anchorEl && !!entity && (
                    <ToCalendarContextMenu
                        anchorEl={anchorEl}
                        onClose={this.handleCloseContextMenu}
                        onCreateEvent={this.createEvent}
                        onAddToWaitingList={this.addToWaitingList}
                        addToWaitingListButtonLoading={addToWaitingListButtonLoading}
                    />
                )}
                {!!noCalendarsWarningMessage && (
                    <PureFormDialog open={true} title="" onClose={this.handleWarningClose} maxWidth="sm" fullWidth>
                        <p className="no-calendars-popup-text">{noCalendarsWarningMessage}</p>
                    </PureFormDialog>
                )}
                {calendarSettingsOpen && (
                    <CalendarSettingsDialog isOpen={calendarSettingsOpen} onClose={this.handleCalendarSettingsClose} />
                )}
                {openPopupType === PopupType.CONTEXT_LEFT && popupData?.target && (
                    <LeftClickContextMenu
                        calendars={availableCalendars}
                        anchorEl={popupData.target}
                        handleClose={this.closePopups}
                        handleSelect={this.handleCreateEventIn}
                    />
                )}
                {(openPopupType === PopupType.FULL_FORM || openPopupType === PopupType.QUICK_FORM) && (
                    <CalendarFormPopups
                        calendarEventManager={this.calendarEventManager}
                        onClose={this.closePopups}
                        calendarEvent={popupData?.calendarEvent ?? null}
                        calendar={popupData?.calendar ?? null}
                        anchorEl={popupData?.target}
                        preferFullForm={openPopupType === PopupType.FULL_FORM}
                        usePopover={true}
                    />
                )}
            </>
        );
    }
}

export default withTranslation('translations', { withRef: true })(withSnackbar(ToCalendarButton));
