import qsManager from 'service/QueryStringManager';
import { logDebug, parseBounds, parseGeoPoint } from 'utils';
import { Bounds } from 'components/types';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import { Geo } from 'interfaces';

// for now all command requires record and ds/entity metadata to be already loaded
export enum Command {
    CENTER = 'center',
    MAP_POPUP = 'openMapPopup',
    // suggested command: SET_BASE_POINT = 'setBasePoint',
}

enum Params {
    AREA = 'center', // mutual exclusive with LOCATION
    COMMANDS = 'cmd',
    DATA_SOURCE_NAME = 'source',
    ENTITY_API_NAME = 'object',
    LOCATION = 'center',
    RECORD_ID = 'id',
    ZOOM = 'zoom',
}

const COMMAND_SEPARATOR = ',';

const POINT_SEPARATOR = ';';

class UrlCommandManager {
    public readonly area?: Readonly<Bounds>;
    public readonly commands: ReadonlyArray<Command>;
    public readonly dataSourceName?: string;
    public readonly entityApiName?: string;
    public readonly location?: Readonly<Geo.GeoPoint>;
    public readonly recordId?: string;
    public readonly zoom?: number;

    private static parseCommands(from?: string): Command[] {
        const commands = Object.values<string>(Command);
        return uniq(
            (from ?? '')
                .split(COMMAND_SEPARATOR)
                .map((command) => command.trim())
                .filter((command) => commands.includes(command)),
        ) as Command[];
    }

    private static parseZoom(from?: string): number | undefined {
        const zoom = Number(from || undefined);
        return zoom ? Math.max(2, Math.min(23, Math.ceil(zoom))) : undefined;
    }

    private get isRecordSet(): boolean {
        return this.recordId !== undefined && this.entityApiName !== undefined;
    }

    constructor() {
        const params = mapValues(
            omitBy(qsManager.getUrlCommandParams(), (value) => !value || !value.toString()),
            (value, _key) => (value as any).toString(), // falsities were omitted
        );

        this.area = parseBounds(params[Params.AREA], POINT_SEPARATOR);
        this.commands = UrlCommandManager.parseCommands(params[Params.COMMANDS]);
        this.dataSourceName = params[Params.DATA_SOURCE_NAME];
        this.entityApiName = params[Params.ENTITY_API_NAME];
        this.location = parseGeoPoint(params[Params.LOCATION]);
        this.recordId = params[Params.RECORD_ID];
        this.zoom = UrlCommandManager.parseZoom(params[Params.ZOOM]);

        logDebug('url commands', {
            _locationZoom: this.locationZoom,
            _raw: params,
            area: this.area,
            commands: this.commands,
            dataSourceName: this.dataSourceName,
            entityApiName: this.entityApiName,
            location: this.location,
            recordId: this.recordId,
            zoom: this.zoom,
        });
    }

    get isPositionSet(): boolean {
        return this.area !== undefined || this.location !== undefined;
    }

    get isRecordPositionSet(): boolean {
        return this.isRecordSet && this.commands.includes(Command.CENTER);
    }

    get isRecordMapPopupSet(): boolean {
        return this.isRecordSet && this.commands.includes(Command.MAP_POPUP);
    }

    get isRecordLoadingRequired(): boolean {
        // for now all command requires record and ds/entity metadata to be already loaded
        return this.isRecordSet && this.commands.length > 0;
    }

    get locationZoom(): number | undefined {
        return this.area === undefined ? this.zoom : undefined;
    }
}

export default new UrlCommandManager();
