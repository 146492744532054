import dispatcher from '../../service/dispatcher';
import events from '../../events';
import formManager from '../../service/workflow_actions/FormManager';
import BaseWorkflowActionsForm from './BaseWorkflowActionsForm';

class WorkflowActionsForm extends BaseWorkflowActionsForm {
    constructor(props) {
        super(props);

        this.manager = formManager;
    }

    componentDidMount() {
        super.componentDidMount();
        dispatcher.subscribe(events.WORKFLOW_ACTIONS_FORM_REFRESHED, this, () => {
            this.forceUpdate();
            this.setState({ errors: new Map() });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleActionButton(field) {
        const form = this.manager.getForm();
        const errors = form ? form.validate() : new Map();

        this.setState({ errors: errors }, () => {
            for (const item of errors.values()) {
                if (item.size !== 0) {
                    return;
                }
            }

            this.handleFieldHandler(field, { uploadInProgress: this.state.uploadInProgress });
        });
    }
}

export default WorkflowActionsForm;
