import { Dialog, DialogContent, DialogProps, DialogTitle, Icon, IconButton } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';

import { DialogContext } from './DialogContext';

interface PureFormDialogProps extends Omit<DialogProps, 'title' | 'classes'>, WithStyles<ReturnType<typeof styles>> {
    title: ReactNode;
    actions?: ReactElement<{ className?: string }>;
    onClose: MouseEventHandler;
    className?: string;
    hideCloseButton?: boolean;
    noDividers?: boolean;
    fullScreen?: boolean;
}

class PureFormDialog extends React.PureComponent<PureFormDialogProps> {
    static defaultProps = {
        scroll: 'body' as const,
        hideCloseButton: false,
    };

    render() {
        const { classes, className, title, actions, scroll, hideCloseButton, noDividers, ...rest } = this.props;
        if (rest.disableEnforceFocus === undefined) {
            rest.disableEnforceFocus = true;
        }
        if (rest.disableBackdropClick === undefined) {
            rest.disableBackdropClick = true;
        }
        return (
            <Dialog scroll={scroll} {...rest} className={clsx(className)}>
                <DialogTitle className={classes!.title}>
                    {title}
                    {!hideCloseButton && (
                        <IconButton
                            color="default"
                            onClick={this.props.onClose}
                            className={classes.closeButton}
                            data-testid="pure_form_dialog.close"
                        >
                            <Icon>close_icon</Icon>
                        </IconButton>
                    )}
                </DialogTitle>

                <DialogContent className={classes.content} dividers={scroll === 'paper' && !noDividers}>
                    <DialogContext.Provider
                        value={{
                            onClose: this.props.onClose as any,
                            styles: {
                                actions: classes.actions,
                                alert: classes.alert,
                                backdrop: classes.backdrop,
                            },
                        }}
                    >
                        {this.props.children}
                    </DialogContext.Provider>
                </DialogContent>
                {actions && React.cloneElement(actions, { className: classes.stickyActions })}
            </Dialog>
        );
    }
}

const styles = () =>
    createStyles({
        title: {
            paddingRight: 56,
        },
        closeButton: {
            position: 'absolute',
            right: 8,
            top: 8,
        },
        actions: {
            margin: '0 -24px',
            padding: '8px 16px',
            textAlign: 'right',
        },
        stickyActions: {
            padding: '8px 16px',
        },
        alert: {
            margin: '0 -24px',
            '& > div': {
                padding: 24,
            },
        },
        content: {
            padding: '0 24px',
        },
        backdrop: {
            margin: '0 -24px',
            padding: '0 24px',
        },
    });

export default withStyles(styles)(PureFormDialog);
