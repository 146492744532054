import React from 'react';
import AvatarEditor, { ImageState, Position } from 'react-avatar-editor';
import Slider from '@material-ui/core/Slider';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    image: string;
    onCrop: (img: HTMLCanvasElement | undefined) => void;
}

interface State {
    image: string;
    allowZoomOut: boolean;
    position: Position;
    scale: number;
    rotate: number;
    borderRadius: number;
    width: number;
    height: number;
    disableCanvasRotation: boolean;
    isTransparent: boolean;
    backgroundColor?: string;
    showGrid: boolean;
}

class AvatarCropper extends React.Component<Props, State> {
    private editor: AvatarEditor | null = null;

    state: State = {
        image: this.props.image,
        allowZoomOut: true,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 50,
        width: 200,
        height: 200,
        disableCanvasRotation: false,
        isTransparent: false,
        backgroundColor: '#fff',
        showGrid: true,
    };

    handleScale = (_: React.ChangeEvent<{}>, value: number | number[]) => {
        if (typeof value !== 'number') {
            return;
        }

        this.setState({ scale: value });
        this.props.onCrop(this.editor?.getImageScaledToCanvas());
    };

    handleLoadImage = (_: ImageState) => {
        this.props.onCrop(this.editor?.getImageScaledToCanvas());
    };

    handlePositionChange = (position: Position) => {
        this.setState({ position });
        this.props.onCrop(this.editor?.getImageScaledToCanvas());
    };

    setEditorRef = (editor: AvatarEditor | null) => {
        this.editor = editor;
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <AvatarEditor
                    ref={this.setEditorRef}
                    scale={this.state.scale}
                    width={this.state.width}
                    height={this.state.height}
                    position={this.state.position}
                    onPositionChange={this.handlePositionChange}
                    onLoadSuccess={this.handleLoadImage}
                    rotate={this.state.rotate}
                    borderRadius={this.state.width / (100 / this.state.borderRadius)}
                    backgroundColor={this.state.backgroundColor}
                    image={this.state.image}
                    disableCanvasRotation={this.state.disableCanvasRotation}
                />

                <div className="">
                    <p>{t('avatar.form.croping.zoom')}</p>

                    <Slider
                        defaultValue={1}
                        min={this.state.allowZoomOut ? 0.5 : 1}
                        max={2}
                        onChange={this.handleScale}
                        step={0.01}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation('translations')(AvatarCropper);
