import { Camera } from '@capacitor/camera';
import { WebPlugin } from '@capacitor/core';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { BrowserQRCodeReader } from '@zxing/browser';
import Quagga from 'quagga';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);
export class BarcodeScannerWeb extends WebPlugin {
    private _video: HTMLVideoElement | null = null;
    private reader: BrowserQRCodeReader;
    private _close: HTMLButtonElement | null = null;
    private _body: HTMLBodyElement | null = null;
    private _app: HTMLElement | null = null;
    private _lastChild: HTMLElement | null = null;

    constructor() {
        super(...arguments);
        this._video = null;
        this.reader = new BrowserQRCodeReader();
        this._close = null;

        this._body = document.querySelector('body');
        this._app = document.getElementById('app');
        this._lastChild = (this._body?.lastChild as HTMLElement) || null;
    }
    async startScan() {
        const video = await this._getVideoElement();
        if (video) {
            return await this._getFirstResultFromReader();
        }
    }
    async stopScan() {
        if (this._video) {
            Quagga.stop();
            this._video.pause();
            const stream = this._video.srcObject as MediaStream;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            if (this._video.parentElement) {
                this._video.parentElement.remove();
            }
            this._video = null;
        }
    }
    async _getVideoElement(): Promise<HTMLVideoElement | null> {
        if (!this._video) {
            await this._startVideo();
        }
        return this._video;
    }
    async _getFirstResultFromReader(): Promise<{ hasContent: boolean; content: string }> {
        const videoElement = await this._getVideoElement();
        return new Promise((resolve) => {
            const qrResults: string[] = [];
            if (videoElement) {
                Quagga.init(
                    {
                        inputStream: {
                            name: 'Live',
                            type: 'LiveStream',
                            target: videoElement,
                            constraints: {
                                facingMode: 'environment',
                            },
                        },
                        decoder: {
                            readers: [
                                'code_128_reader',
                                'ean_reader',
                                'ean_8_reader',
                                'code_39_reader',
                                'code_93_reader',
                                'codabar_reader',
                                'upc_reader',
                                'upc_e_reader',
                            ], // Specify the barcode format you want to decode
                        },
                    },
                    (err: any) => {
                        if (err) {
                            return;
                        }
                        Quagga.start();
                    },
                );

                // zxing for QR code
                this.reader.decodeFromVideoElement(videoElement, (result, _, controls) => {
                    if (result && result.getText()) {
                        resolve({
                            hasContent: true,
                            content: result.getText(),
                        });
                        controls.stop();
                    }
                });

                // Quagga for Barcode
                Quagga.onDetected((data: { codeResult: { code: string } }) => {
                    const result = data.codeResult.code;

                    qrResults.push(result);
                    if (qrResults.length > 3) {
                        qrResults.shift();
                    }
                    if (qrResults.length === 3) {
                        if (qrResults.every((element) => element === qrResults[0])) {
                            resolve({
                                hasContent: true,
                                content: result,
                            });
                            Quagga.stop();
                        }
                    }
                });

                this._close?.addEventListener('click', () => {
                    this.stopScan();
                    resolve({
                        hasContent: false,
                        content: '',
                    });
                });
            }
        });
    }
    async _startVideo() {
        return new Promise(async (resolve, reject) => {
            const permissionStatus = await Camera.checkPermissions();
            if (permissionStatus.camera !== 'granted') {
                reject({ message: 'Camera permission denied' });
            }
            await navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: true,
                })
                .then((stream) => {
                    // Stop any existing stream so we can request media with different constraints based on user input
                    stream.getTracks().forEach((track) => track.stop());
                })
                .catch((error) => {
                    reject(error);
                });
            const body = document.body;
            const video = document.getElementById('video');
            if (!video) {
                const parent = document.createElement('div');
                parent.setAttribute(
                    'style',
                    'position:absolute; top: 0; left: 0; width:100%; height: 100%; background-color: black;',
                );
                const closeButton = document.createElement('button');
                closeButton.innerText = t('workflow_actions.forms.field.text.stopScan');
                closeButton.setAttribute(
                    'style',
                    'position:absolute; bottom: 60px; right: 50px; left: 50px; height: 60px; font-size: 20px; z-index: 1500;',
                );
                this._close = closeButton;
                closeButton.addEventListener('click', () => {
                    this.stopScan();
                    this._hideAndShowBackground('show');
                });
                const line = document.createElement('div');
                line.setAttribute(
                    'style',
                    'position: absolute; z-index: 1500; top: 15%; left: 5%; right: 5%; height: 2px; background-color: #6512BE; animation: animateScannerLine 3s infinite;',
                );
                this._video = document.createElement('video');
                this._video.id = 'video';
                const userAgent = navigator.userAgent.toLowerCase();
                const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome');
                // Safari on iOS needs to have the autoplay, muted and playsinline attributes set for video.play() to be successful
                // Without these attributes this.video.play() will throw a NotAllowedError
                // https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari
                if (isSafari) {
                    this._video.setAttribute('autoplay', 'true');
                    this._video.setAttribute('muted', 'true');
                    this._video.setAttribute('playsinline', 'true');
                }
                parent.appendChild(this._video);
                parent.appendChild(closeButton);
                parent.appendChild(line);
                body.appendChild(parent);
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    const constraints = {
                        video: {
                            facingMode: { exact: 'environment' },
                        },
                    };
                    navigator.mediaDevices.getUserMedia(constraints).then(
                        (stream) => {
                            if (this._video) {
                                this._video.srcObject = stream;
                                this._video.play();
                            }
                            resolve({});
                        },
                        (err) => {
                            reject(err);
                        },
                    );
                }
            } else {
                reject({ message: 'camera already started' });
            }
        });
    }

    _hideAndShowBackground(type: 'hide' | 'show') {
        const setElementStyles = (element: HTMLElement, opacity: string, visibility: string) => {
            element.style.opacity = opacity;
            element.style.visibility = visibility;
        };

        if (type === 'hide' && this._app && this._lastChild) {
            this._body?.classList.add('scanner-active');
            setElementStyles(this._app, '0', 'hidden');
            setElementStyles(this._lastChild, '0', 'hidden');
        }

        if (type === 'show' && this._app && this._lastChild) {
            this._body?.classList.remove('scanner-active');
            setElementStyles(this._app, '1', 'visible');
            setElementStyles(this._lastChild, '1', 'visible');
        }
    }

    async askCameraPermission() {
        try {
            const permissionStatus = await Camera.checkPermissions();
            if (permissionStatus.camera === 'granted') {
                return true;
            } else {
                const permissionResult = await Camera.requestPermissions({ permissions: ['camera'] });
                if (permissionResult) {
                    return true;
                } else {
                    alert(t('workflow_actions.forms.field.text.cameraPermissionDenide'));
                }
            }
        } catch (e) {
            const result = window.confirm(t('workflow_actions.forms.field.text.needPermissionToCamera'));
            if (result) {
                await OpenNativeSettings.open('application_details');
            }
        }
        return false;
    }
}
