import queryString, { ParsedQuery } from 'query-string';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';

const URL_COMMAND_PREFIX = '__';

class QueryStringManager {
    private readonly queryParams: ParsedQuery;

    constructor() {
        this.queryParams = queryString.parse(window.location.search) || {};
    }

    getQueryParams() {
        return this.queryParams;
    }

    getSearchParams(): ParsedQuery {
        return this.queryParams;
    }

    getUrlCommandParams(): ParsedQuery {
        return mapKeys(
            pick(
                this.queryParams,
                Object.keys(this.queryParams).filter((key) => key.startsWith(URL_COMMAND_PREFIX)),
            ),
            (_value, key) => key.substr(URL_COMMAND_PREFIX.length),
        );
    }
}

const qsManager = new QueryStringManager();
export default qsManager;
