import ProcessorInterface from './ProcessorInterface';
import UpdateRecord from '../Events/UpdateRecord';
import { Field } from '../Events/AbstractRecord';
import EntityManagerFactory from '../../EntityManager';
import AbstractProcessor from './AbstractProcessor';
import { logDebug } from '../../../utils';

class UpdateRecordProcessor extends AbstractProcessor implements ProcessorInterface {
    public process(event: UpdateRecord): void {
        const entityManager = EntityManagerFactory.getManager(event.getAccountId(), event.getDataSourceId());

        entityManager
            .startLiveUpdate(
                event.getEntityId(),
                event.getRecordIds(),
                this.transformFields(event.getFields()),
                event.getSelectionType(),
                event.getTotalCount(),
                event.toBackend(),
            )
            .catch((error: object) => {
                this.failEvent(event, error);
            });
    }

    public onComplete(event: UpdateRecord): void {
        logDebug(event);
    }

    public transformFields(fields: Array<Field>) {
        const fieldNames = ['apiName', 'originalApiName', 'value', 'textValue'];

        return fields
            .map((field) => Object.fromEntries(Object.entries(field).filter(([key]) => fieldNames.includes(key))))
            .filter((field) => !!field.apiName);
    }
}

export default UpdateRecordProcessor;
