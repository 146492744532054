import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';
import EntityViewManager from './EntityViewManager';
import { EDIT_SELECTED } from '../components/LiveUpdateTableForm';
import { logDebug, sortFunc } from '../utils';
import map from 'lodash/map';

class EntityManager extends BackendService {
    constructor(accountId, dataSourceId) {
        super();

        this.accountId = accountId;
        this.dsId = dataSourceId;

        this.entities = null;
        this.promisedEntities = null;
        this.entitiesAvailableForImport = null;
        this.dataSource = null;
        this.prospectsEntities = null;

        dispatcher.subscribe(events.EVENT_DATA_SOURCE_CHANGED, this, (dataSource) => {
            if (dataSource.id === dataSourceId) {
                this.entities = null;
                this.promisedEntities = null;
                this.dataSource = null;
                this.prospectsEntities = null;
            }
        });
        dispatcher.subscribe(events.EVENT_DATA_SOURCE_UPDATED, this, (dataSource) => {
            if (dataSource.id === dataSourceId) {
                this.entities = null;
                this.promisedEntities = null;
                this.prospectsEntities = null;
            }
        });
        dispatcher.subscribe(events.EVENT_USER_SWITCH, this, () => {
            this.entities = null;
            this.promisedEntities = null;
            this.dataSource = null;
            this.prospectsEntities = null;
        });
        dispatcher.subscribe(events.EVENT_ACCOUNT_PERMISSIONS_SAVED, this, () => {
            this.entities = null;
            this.promisedEntities = null;
            this.dataSource = null;
            this.prospectsEntities = null;
        });

        dispatcher.subscribe([events.WS_ENTITIES_CHANGED, events.WS_DS_METADATA_IMPORT], this, (payload) => {
            this.prospectsEntities = null;
            this.cleanDs(payload.dsId);
        });

        dispatcher.subscribe(events.WS_ENTITIES_AVAILABLE_FOR_IMPORT_PROGRESS, this, (data) => {
            if (data.dataSourceId !== this.dsId) {
                return;
            }
            if (data.entities && Array.isArray(data.entities)) {
                const newEntities = [];
                for (const entity of data.entities) {
                    const newEntity = {
                        label: entity.label,
                        name: entity.apiName,
                    };
                    if (
                        !newEntity.label ||
                        !newEntity.name ||
                        newEntities.find((item) => item.name === newEntity.name) ||
                        (this.entitiesAvailableForImport || []).find((item) => item.name === newEntity.name)
                    ) {
                        continue;
                    }
                    newEntities.push(newEntity);
                }
                this.entitiesAvailableForImport = newEntities.concat(this.entitiesAvailableForImport || []);
            }
            if (data.finished === true) {
                dispatcher.dispatch(events.ENTITIES_AVAILABLE_FOR_IMPORT_LOADED, {
                    dataSourceId: data.dataSourceId,
                    entities: this.entitiesAvailableForImport || [],
                });
            }
        });

        this.url = reverse(apiRoutes.account.dataSource.entities, { accountId: this.accountId, dsId: this.dsId });
    }

    cleanDs = (dsId) => {
        if (dsId !== this.dsId) {
            return;
        }
        this.entities = null;
        this.promisedEntities = null;
    };

    setup(data) {
        this.entities = data.entities;
        this.dataSource = data.dataSource;
    }

    init() {
        if (this.entities !== null) {
            return Promise.resolve();
        }
        const url = reverse(apiRoutes.account.dataSource.index, { accountId: this.accountId, dsId: this.dsId });
        return this.requestApi(url, 'POST');
    }

    getEntities(includeUnavailableFields = false, entitiesIds = null) {
        if (includeUnavailableFields === true || entitiesIds !== null) {
            return this.requestApi(this.url, 'GET', {
                includeUnavailableFields,
                entitiesIds,
            }).then((data) => this.processFlags(data.entities));
        }

        if (this.entities !== null) {
            return Promise.resolve(this.processFlags(this.entities));
        }

        return this.requestEntities().then((data) => {
            this.setup(data);
            return this.processFlags(this.entities);
        });
    }

    getImportSchedules() {
        const url = reverse(apiRoutes.account.dataSource.importSchedules, {
            accountId: this.accountId,
            dsId: this.dsId,
        });
        return this.requestApi(url, 'GET');
    }

    requestEntities() {
        if (this.promisedEntities !== null) {
            return this.promisedEntities;
        }
        // Кешируем промис, т.к. метод вызывается многократно и часто, результат вызова может не быть получен
        // на момент следующего вызова.
        this.promisedEntities = this.requestApi(this.url, 'GET').then((data) => {
            // Если условие выполняется, значит в процессе получения ответа сущности были сброшены
            // и ответ может быть неактуальным - перезапрашиваем.
            if (this.promisedEntities === null) {
                return this.requestEntities();
            }
            this.promisedEntities = null;

            return Promise.resolve(data);
        });

        return this.promisedEntities;
    }

    loadEntitiesAvailableForImport() {
        if (Array.isArray(this.entitiesAvailableForImport)) {
            return new Promise((resolve, reject) => {
                dispatcher.dispatch(events.ENTITIES_AVAILABLE_FOR_IMPORT_LOADED, {
                    dataSourceId: this.dsId,
                    entities: this.entitiesAvailableForImport,
                });
                resolve();
            });
        }

        const url = reverse(apiRoutes.account.dataSource.entities.availableForImport, {
            accountId: this.accountId,
            dsId: this.dsId,
        });

        return this.requestApi(url, 'GET');
    }

    processFlags(entities) {
        const lookupFields = {};
        for (let entity of entities) {
            entity.fields.sort(sortFunc('label'));
            for (let field of entity.fields) {
                if (!field.isIncluded || field.isDeleted) {
                    continue;
                }
                if (!field.lookupData) {
                    continue;
                }
                if (!field.lookupData.linking_lookup_data || !field.lookupData.linking_lookup_data.entity) {
                    continue;
                }
                const apiName = field.lookupData.linking_lookup_data.entity;
                const fieldApiName = field.lookupData.linking_lookup_data.field;
                if (field.apiName !== fieldApiName) {
                    continue;
                }
                if (lookupFields[apiName] === undefined) {
                    lookupFields[apiName] = [];
                }
                lookupFields[apiName].push({
                    field: {
                        label: field.label,
                        apiName: field.apiName,
                        originalApiName: field.originalApiName,
                    },
                    entity: {
                        label: entity.label,
                        apiName: entity.name,
                    },
                });
            }
        }
        logDebug('lookupFields', lookupFields);
        for (let apiName of Object.keys(lookupFields)) {
            for (let field of lookupFields[apiName]) {
                for (let entity of entities) {
                    if (entity.name === apiName) {
                        if (entity.isLockedBy === undefined) {
                            entity.isLockedBy = [];
                        }
                        entity.isLockedBy.push(field);
                    }
                }
            }
        }
        return entities;
    }

    getDataSource() {
        if (this.dataSource !== null) {
            return Promise.resolve(this.dataSource);
        }

        return this.requestApi(this.url, 'GET').then((data) => {
            this.setup(data);
            return this.dataSource;
        });
    }

    getEntity(entityId, includeUnavailableFields = false, force = false) {
        const entitiesIds = force ? [entityId] : null;
        return this.getEntities(includeUnavailableFields, entitiesIds).then((entities) => {
            return entities.find((entity) => parseInt(entity.id) === entityId);
        });
    }

    startCreateRecord(entityId, fields, latitude, longitude, event = null) {
        const url = reverse(apiRoutes.account.dataSource.entity.createRecord, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });

        return this.requestApi(url, 'POST', { fields, latitude, longitude, event });
    }

    createRecordPrefill(entityId, latitude, longitude) {
        const url = reverse(apiRoutes.account.dataSource.entity.createRecordPrefill, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });

        return this.requestApi(url, 'POST', { latitude, longitude });
    }

    getChanges() {
        const url = reverse(apiRoutes.account.dataSource.changes, { accountId: this.accountId, dsId: this.dsId });
        return this.requestApi(url, 'GET');
    }

    saveEntities(entities, force = true, skipViewsValidation = false) {
        const params = {};
        if (force) {
            params['force'] = true;
        }
        if (skipViewsValidation) {
            params['skipViewsValidation'] = true;
        }
        const url = this.url + '?' + map(params, (value, key) => `${key}=${value}`).join('&');
        return this.requestApi(url, 'POST', entities).then((es) => {
            this.entities = es;
            for (let entity of entities) {
                dispatcher.dispatch(events.ENTITY_SETTINGS_CHANGED, entity.id);
            }
            return es;
        });
    }

    saveEntitySettings(entityId, params) {
        return this.saveEntities([
            {
                id: entityId,
                ...params,
            },
        ]);
    }

    startLiveUpdate(entityId, recordIds, fields, editType, totalCount, event = null) {
        const url = reverse(apiRoutes.account.dataSource.entity.liveUpdate, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });
        const combinedFilters = EntityViewManager.getCombinedFilters(entityId);
        return this.requestApi(url, 'POST', {
            recordIds,
            fields,
            type: editType,
            totalCount: editType === EDIT_SELECTED ? recordIds.length : totalCount,
            ...combinedFilters,
            event,
        });
    }

    getLiveUpdateCount(entityId, recordIds, fields) {
        const url = reverse(apiRoutes.account.dataSource.entity.liveUpdateCount, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });
        const combinedFilters = EntityViewManager.getCombinedFilters(entityId);
        return this.requestApi(url, 'POST', {
            recordIds,
            fields,
            ...combinedFilters,
        });
    }

    importEntities(entityApiNames) {
        const url = reverse(apiRoutes.account.dataSource.entities.import, {
            accountId: this.accountId,
            dsId: this.dsId,
        });
        return this.requestApi(url, 'POST', { entityApiNames });
    }

    exportProspectsToEntity(entityId, records, overwrite) {
        const url = reverse(apiRoutes.account.dataSource.entity.exportProspects, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId: entityId,
        });
        return this.requestApi(url, 'POST', { records: records, overwrite: overwrite });
    }

    exportProspectsToEntityIntention(entityId, records, overwrite) {
        const url = reverse(apiRoutes.account.dataSource.entity.exportProspects, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId: entityId,
        });
        return this.requestApi(url, 'POST', { records: records, overwrite: overwrite, intention: true });
    }

    readChanges(ids) {
        const url = reverse(apiRoutes.account.dataSource.changes, { accountId: this.accountId, dsId: this.dsId });
        return this.requestApi(url, 'POST', ids);
    }

    /**
     * @param {number} id
     * @return {Promise<ProspectsEntitiesResponse>}
     */
    getProspectsEntities(id) {
        if (this.prospectsEntities !== null) {
            return Promise.resolve(this.prospectsEntities);
        }
        const url = reverse(apiRoutes.account.dataSource.prospectsEntities, {
            accountId: this.accountId,
            dsId: this.dsId,
        });
        return this.requestApi(url, 'GET').then((prospectsStructure) => {
            this.prospectsEntities = prospectsStructure;
            return prospectsStructure;
        });
    }

    createUniqueLayers(entityId, fieldId, name) {
        const url = reverse(apiRoutes.account.dataSource.entity.uniqueLayers, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            fieldId,
        });
        return this.requestApi(url, 'POST', { name }).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    refreshUniqueLayers(entityId, layerGroupId) {
        const url = reverse(apiRoutes.account.dataSource.entity.refreshUniqueLayers, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            layerGroupId,
        });
        return this.requestApi(url, 'POST').then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    convertLayerGroup(entityId, layerGroupId) {
        const url = reverse(apiRoutes.account.dataSource.entity.convertLayerGroup, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            layerGroupId,
        });
        return this.requestApi(url, 'POST').then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    startLiveDelete(entityId, recordIds) {
        const url = reverse(apiRoutes.account.dataSource.entity.liveDelete, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });
        return this.requestApi(url, 'POST', { recordIds });
    }

    createLayerGroup(entityId, layerGroup) {
        const url = reverse(apiRoutes.account.dataSource.entity.layerGroups, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });
        return this.requestApi(url, 'POST', layerGroup).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    updateLayerGroupProperties(entityId, layerGroup) {
        const url = reverse(apiRoutes.account.dataSource.entity.layerGroup, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            layerGroupId: layerGroup.id,
        });
        return this.requestApi(url, 'PUT', layerGroup).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    updateLayerGroupPermission(entityId, layerGroup) {
        const url = reverse(apiRoutes.account.dataSource.entity.layerGroup.permission, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            layerGroupId: layerGroup.id,
        });
        return this.requestApi(url, 'PUT', layerGroup).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    deleteLayerGroup(entityId, layerGroupId) {
        const url = reverse(apiRoutes.account.dataSource.entity.layerGroup, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            layerGroupId,
        });
        return this.requestApi(url, 'DELETE').then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    createView(entityId, view) {
        const url = reverse(apiRoutes.account.dataSource.entity.views, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
        });
        return this.requestApi(url, 'POST', view).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    updateViewProperties(entityId, view) {
        const url = reverse(apiRoutes.account.dataSource.entity.view, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            viewId: view.id,
        });
        return this.requestApi(url, 'PUT', view).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    updateViewPermission(entityId, view) {
        const url = reverse(apiRoutes.account.dataSource.entity.view.permission, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            viewId: view.id,
        });
        return this.requestApi(url, 'PUT', view).then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }

    deleteView(entityId, viewId) {
        const url = reverse(apiRoutes.account.dataSource.entity.view, {
            accountId: this.accountId,
            dsId: this.dsId,
            entityId,
            viewId,
        });
        return this.requestApi(url, 'DELETE').then((response) => {
            this.entities = null;
            this.promisedEntities = null;
            return response;
        });
    }
}

class Factory {
    managers = new Map();

    getManager(accountId, dataSourceId) {
        const key = accountId + '_' + dataSourceId;
        if (this.managers.has(key)) {
            return this.managers.get(key);
        }
        const manager = new EntityManager(accountId, dataSourceId);
        this.managers.set(key, manager);

        return manager;
    }
}

export default new Factory();
