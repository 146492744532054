import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { logDebug } from '../../../utils';

export enum EventStatus {
    NEW = 'new',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export enum EventType {
    OFFLINE = 'offline',
    ONLINE = 'online',
}

abstract class AbstractEvent {
    private uid: string;
    private status: EventStatus;
    private result: any;
    private createdAt: Date;
    private completedAt: Date | null;
    private type: EventType;
    private readonly promise: Promise<any>;
    private promiseResolve: (data: any) => void = _.noop;

    constructor(type: EventType = EventType.ONLINE) {
        this.uid = uuidv4();
        this.status = EventStatus.NEW;
        this.createdAt = new Date();
        this.completedAt = null;
        this.type = type;

        this.promise = new Promise((resolve) => {
            this.promiseResolve = resolve;
        });
    }

    public getUid(): string {
        return this.uid;
    }

    public getType(): EventType {
        return this.type;
    }

    public isOffline(): boolean {
        return this.type === EventType.OFFLINE;
    }

    public isOnline(): boolean {
        return !this.isOffline();
    }

    public setStatus(status: EventStatus): this {
        this.status = status;

        if (this.isFinalStatus() || this.status === EventStatus.FAILED) {
            this.completedAt = new Date();
        } else {
            this.completedAt = null;
        }

        return this;
    }

    public getStatus(): EventStatus {
        return this.status;
    }

    public isFinalStatus(): boolean {
        return this.status === EventStatus.SUCCESS || this.status === EventStatus.FAILED;
    }

    public isCompletedStatus(): boolean {
        return this.status === EventStatus.SUCCESS;
    }

    public setResult(result: any): this {
        this.result = result;

        return this;
    }

    public getResult(): any {
        return this.result;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public getCompletedAt(): Date | null {
        return this.completedAt;
    }

    public getPromise(): Promise<any> {
        return this.promise;
    }

    public resolve(data: any) {
        logDebug('Event resolved', data);
        this.promiseResolve(data);
    }

    public toBackend(): object {
        return {
            guid: this.getUid(),
            type: this.getType(),
            createdAt: this.getCreatedAt(),
        };
    }

    public toStorage(): object {
        return {
            class: this.constructor.name,
            uid: this.getUid(),
            status: this.getStatus(),
            result: this.getResult(),
            createdAt: this.getCreatedAt(),
            completedAt: this.getCompletedAt(),
            type: this.getType(),
            ...this.toStorageSpecific(),
        };
    }

    protected toStorageSpecific(): object {
        throw new Error('Not implemented');
    }

    // @ts-ignore
    public static fromStorage(data: any): this {
        throw new Error('Not implemented');
    }

    public static fromStorageBase(event: AbstractEvent, data: any) {
        event.uid = data.uid;
        event.status = data.status;
        event.result = data.result;
        event.createdAt = data.createdAt;
        event.completedAt = data.completedAt;
        event.type = data.type;

        return event;
    }
}

export default AbstractEvent;
