import dispatcher from '../dispatcher';
import events from '../../events';
import { weAreInNativeApp } from '../../utils';
import InAppBrowserManager from '../InAppBrowserManager';

class OpenUrlManager {
    constructor() {
        dispatcher.subscribe(events.WS_OPEN_URL, this, (payload) => {
            this.refresh(payload);
        });
    }

    refresh(message) {
        if (message.target === 'iframe') {
            dispatcher.dispatch(events.EVENT_OPENING_WEB_LINK_IFRAME, {
                url: message.url,
                width: message.iframeWidth,
                height: message.iframeHeight,
            });
        } else {
            this.openWindow(message);
        }
    }

    openWindow(message) {
        if (weAreInNativeApp()) {
            InAppBrowserManager.openDefaultBrowser(message.url);
            return;
        }
        window.open(message.url, '_blank');
    }
}

export default new OpenUrlManager();
