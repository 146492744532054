import React from 'react';
import { TextField, FormHelperText, Grid, Link } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { getDetail } from '../../../service/WorkflowActionManager';
import './../style.css';
import AbstractForm from '../AbstractForm/AbstractForm';
import FormulaInput from '../AbstractForm/FormulaInput';
import Hint from '../../Hint';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import {
    DETAIL_BCC_ADDRESSES,
    DETAIL_BODY,
    DETAIL_CC_ADDRESSES,
    DETAIL_FROM_ADDRESS,
    DETAIL_FROM_NAME,
    DETAIL_REPLY_TO_ADDRESS,
    DETAIL_SUBJECT,
    DETAIL_TO_ADDRESSES,
} from './Action';
import { isActiveEntity, utcToUserTimezone } from '../../../utils';
import { Alert } from '@material-ui/lab';
import { orgSettingsManager } from '../../../service/OrgSettings/OrgSettingsManager';
import { userManager } from '../../../service/UserManager';
import PropTypes from 'prop-types';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import { limitationsManager } from '../../../service/LimitationsManager';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            smtpCredentials: undefined,
            accountLimitations: null,
        };
    }

    componentDidMount() {
        super.componentDidMount();

        this.refreshSmtpCredentials();
        limitationsManager.load(this.props.account.id).then((accountLimitations) => {
            this.setState({ accountLimitations });
        });

        dispatcher.subscribe(events.SMTP_CREDENTIALS_UPDATED, this, this.refreshSmtpCredentials);
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.SMTP_CREDENTIALS_UPDATED, this);
    }

    refreshSmtpCredentials = () => {
        orgSettingsManager.getSmtpCredentials(this.props.account.id).then((smtpCredentials) => {
            this.setState({ smtpCredentials });
        });
    };

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity, smtpCredentials } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            if (null === smtpCredentials) {
                delete currentAction.details?.[DETAIL_FROM_ADDRESS];
                delete currentAction.details?.[DETAIL_FROM_NAME];
                delete currentAction.details?.[DETAIL_REPLY_TO_ADDRESS];
            } else if (!getDetail(DETAIL_FROM_ADDRESS, currentAction.details)) {
                errors.set(DETAIL_FROM_ADDRESS, t('errors.not_empty'));
            }

            if (!getDetail(DETAIL_TO_ADDRESSES, currentAction.details)) {
                errors.set(DETAIL_TO_ADDRESSES, t('errors.not_empty'));
            }

            if (!getDetail(DETAIL_SUBJECT, currentAction.details)) {
                errors.set(DETAIL_SUBJECT, t('errors.not_empty'));
            }
            if (!getDetail(DETAIL_BODY, currentAction.details)) {
                errors.set(DETAIL_BODY, t('errors.not_empty'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    trimDetailsOnChange = (event) => {
        if (event.target.type !== 'checkbox') {
            event.target.value = event.target.value.trim();
        }
        this.handleDetailsChange(event);
    };

    render() {
        const { currentAction, errors, smtpCredentials, accountLimitations } = this.state;
        const showSenderBlock = smtpCredentials === undefined || !!smtpCredentials;

        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.send_email.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.send_email.name')}
                        data-testid="workflow_actions.form.send_email.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.send_email.api_name')}
                        data-testid="workflow_actions.form.send_email.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect(currentAction, errors)}

                    <div style={{ marginTop: 20 }}>
                        <b style={{ marginRight: 5 }}>{t('workflow_actions.form.send_email.sender')}</b>
                    </div>

                    {errors.has('sender') && <FormHelperText error>{errors.get('sender')}</FormHelperText>}

                    {!showSenderBlock && (
                        <Alert severity="warning">
                            {t('workflow_actions.form.send_email.sender.no_smtp_settings.part1')}
                            <Link href="#" onClick={this.props.onOpenEmailSettings} underline="always">
                                {t('workflow_actions.form.send_email.sender.no_smtp_settings.part2')}
                            </Link>
                            {t('workflow_actions.form.send_email.sender.no_smtp_settings.part3', {
                                untilDate: accountLimitations?.sendEmailsWithNoSmtpUntil
                                    ? utcToUserTimezone(
                                          accountLimitations.sendEmailsWithNoSmtpUntil,
                                          userManager.getCurrentUser(),
                                          'D-MMM-YYYY',
                                      )
                                    : null,
                                maxEmails: accountLimitations?.maxEmailsWithNoSmtp,
                            })}
                        </Alert>
                    )}

                    {showSenderBlock && (
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <span style={{ marginRight: 5 }}>
                                        {t('workflow_actions.form.send_email.from_email.warning')}
                                    </span>
                                    <Hint type="tooltip">
                                        {t('workflow_actions.form.send_email.from_email.warning.hint')}
                                    </Hint>
                                </Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <FormulaInput
                                    label={t('workflow_actions.form.send_email.from_email')}
                                    hint={t('workflow_actions.form.send_email.from_email.hint')}
                                    fullWidth
                                    margin="dense"
                                    required
                                    name={DETAIL_FROM_ADDRESS}
                                    onChange={this.trimDetailsOnChange}
                                    value={getDetail(DETAIL_FROM_ADDRESS, currentAction.details, '')}
                                    error={errors.has(DETAIL_FROM_ADDRESS)}
                                    helperText={errors.get(DETAIL_FROM_ADDRESS) || ''}
                                    callContext={currentAction.callContext}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormulaInput
                                    label={t('workflow_actions.form.send_email.from_name')}
                                    hint={t('workflow_actions.form.send_email.from_name.hint')}
                                    fullWidth
                                    margin="dense"
                                    name={DETAIL_FROM_NAME}
                                    onChange={this.trimDetailsOnChange}
                                    value={getDetail(DETAIL_FROM_NAME, currentAction.details, '')}
                                    error={errors.has(DETAIL_FROM_NAME)}
                                    helperText={errors.get(DETAIL_FROM_NAME) || ''}
                                    callContext={currentAction.callContext}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormulaInput
                                    label={t('workflow_actions.form.send_email.reply_to')}
                                    hint={t('workflow_actions.form.send_email.reply_to.hint')}
                                    fullWidth
                                    margin="dense"
                                    name={DETAIL_REPLY_TO_ADDRESS}
                                    onChange={this.trimDetailsOnChange}
                                    value={getDetail(DETAIL_REPLY_TO_ADDRESS, currentAction.details, '')}
                                    error={errors.has(DETAIL_REPLY_TO_ADDRESS)}
                                    helperText={errors.get(DETAIL_REPLY_TO_ADDRESS) || ''}
                                    callContext={currentAction.callContext}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <div style={{ marginTop: 20 }}>
                        <b style={{ marginRight: 5 }}>{t('workflow_actions.form.send_email.recipients')}</b>
                        <Hint type="tooltip">{t('workflow_actions.form.send_email.recipients.hint')}</Hint>
                    </div>

                    {errors.has('recipients') && <FormHelperText error>{errors.get('recipients')}</FormHelperText>}

                    <FormulaInput
                        label={t('workflow_actions.form.send_email.to')}
                        fullWidth
                        margin="dense"
                        required
                        name={DETAIL_TO_ADDRESSES}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_TO_ADDRESSES, currentAction.details, '')}
                        error={errors.has(DETAIL_TO_ADDRESSES)}
                        helperText={errors.get(DETAIL_TO_ADDRESSES) || ''}
                        callContext={currentAction.callContext}
                    />
                    <FormulaInput
                        label={t('workflow_actions.form.send_email.cc')}
                        fullWidth
                        margin="dense"
                        name={DETAIL_CC_ADDRESSES}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_CC_ADDRESSES, currentAction.details, '')}
                        error={errors.has(DETAIL_CC_ADDRESSES)}
                        helperText={errors.get(DETAIL_CC_ADDRESSES) || ''}
                        callContext={currentAction.callContext}
                    />
                    <FormulaInput
                        label={t('workflow_actions.form.send_email.bcc')}
                        fullWidth
                        margin="dense"
                        name={DETAIL_BCC_ADDRESSES}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_BCC_ADDRESSES, currentAction.details, '')}
                        error={errors.has(DETAIL_BCC_ADDRESSES)}
                        helperText={errors.get(DETAIL_BCC_ADDRESSES) || ''}
                        callContext={currentAction.callContext}
                    />

                    <div style={{ marginTop: 20 }}>
                        <b style={{ marginRight: 5 }}>{t('workflow_actions.form.send_email.content')}</b>
                    </div>

                    <FormulaInput
                        label={t('workflow_actions.form.send_email.subject')}
                        fullWidth
                        margin="dense"
                        multiline
                        required
                        name={DETAIL_SUBJECT}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_SUBJECT, currentAction.details, '')}
                        error={errors.has(DETAIL_SUBJECT)}
                        helperText={errors.get(DETAIL_SUBJECT) || ''}
                        callContext={currentAction.callContext}
                    />
                    <FormulaInput
                        label={t('workflow_actions.form.send_email.body')}
                        fullWidth
                        margin="dense"
                        multiline
                        required
                        name={DETAIL_BODY}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_BODY, currentAction.details, '')}
                        error={errors.has(DETAIL_BODY)}
                        helperText={errors.get(DETAIL_BODY) || ''}
                        callContext={currentAction.callContext}
                    />
                </form>
            </React.Fragment>
        );
    }
}

Form.propTypes = {
    ...AbstractForm.propTypes,
    onOpenEmailSettings: PropTypes.func,
};

export { Form as SendEmailForm };
