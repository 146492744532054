import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
} from '@material-ui/core';
import {
    DETAIL_PLANNER_IN_ENTITY_CONDITION,
    DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION,
    DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD,
    DETAIL_PLANNER_IN_ENTITY_DS_ID,
    DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY,
    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION,
    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION,
    DETAIL_PLANNER_IN_ENTITY_ID,
    DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD,
    DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD,
    DETAIL_PLANNER_MODE,
} from '../../../../references/wokflow/VisitPlannerAction';
import { getDetail } from '../../../../service/WorkflowActionManager';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FieldSelect } from '../../../FieldSelect';
import { HintedSelect, PLANNER_MODE } from '../Form';
import ConditionEditor from '../../../WorkflowGroups/ConditionEditor';
import { entityManager } from '../../../../service/SimpleEntityManager';
import Hint from '../../../Hint';

class InSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            entity: null,
        };
    }

    componentDidMount() {
        this.updateStateEntity();
    }

    componentDidUpdate(prevProps) {
        if (
            getDetail(DETAIL_PLANNER_IN_ENTITY_ID, prevProps.currentAction.details) !==
            getDetail(DETAIL_PLANNER_IN_ENTITY_ID, this.props.currentAction.details)
        ) {
            this.updateStateEntity();
        }
    }

    updateStateEntity() {
        const { currentAction } = this.props;
        const inDataSourceId = getDetail(DETAIL_PLANNER_IN_ENTITY_DS_ID, currentAction.details);
        const inEntityId = getDetail(DETAIL_PLANNER_IN_ENTITY_ID, currentAction.details);
        if (inEntityId && inDataSourceId) {
            entityManager.get(inEntityId).then((entity) => {
                if (entity.id === getDetail(DETAIL_PLANNER_IN_ENTITY_ID, this.props.currentAction.details)) {
                    this.setState({ entity });
                }
            });
        } else {
            this.setState({ entity: null });
        }
    }

    prepareEntityOptions() {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={dataSource.id + '.' + entity.id} value={entity.id} data-ds={dataSource.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    }

    saveCondition =
        ({ expressionDetail, formulaDetail }) =>
        ({ expression, formula }) => {
            this.props.updateDetailCallback([expressionDetail, formulaDetail], [expression, formula]);
        };

    renderEntityValue = (entityId) => {
        if (entityId) {
            for (let dataSource of this.props.dataSources) {
                for (let entity of dataSource.entities) {
                    if (entity.id === entityId) {
                        return `${dataSource.name} > ${entity.label}`;
                    }
                }
            }
        }
        return '';
    };

    render() {
        const { t, currentAction, errors, onDetailChange, updateDetailCallback, accountId } = this.props;

        const inDataSourceId = getDetail(DETAIL_PLANNER_IN_ENTITY_DS_ID, currentAction.details);
        const entity = this.state.entity;
        const inEntityId = entity ? entity.id : null;
        const inObjectSelected = Boolean(inEntityId && inDataSourceId);
        const inConditionBuilderDisabled =
            getDetail(DETAIL_PLANNER_IN_ENTITY_CONDITION, currentAction.details) !== null &&
            getDetail(DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION, currentAction.details) == null;
        const inConditionFixedAssignmentBuilderDisabled =
            getDetail(DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION, currentAction.details) !== null &&
            getDetail(DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION, currentAction.details) == null;
        const isTeamMode =
            getDetail(DETAIL_PLANNER_MODE, currentAction.details, PLANNER_MODE.single) === PLANNER_MODE.team;

        return (
            <Grid item container xs={12} spacing={1} alignItems={'flex-end'}>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_IN_ENTITY_ID)}>
                        <InputLabel required>{t('workflow_actions.form.visit_planner.in.object')}</InputLabel>
                        <HintedSelect
                            fullWidth
                            name={DETAIL_PLANNER_IN_ENTITY_ID}
                            value={inEntityId || ''}
                            onChange={onDetailChange}
                            endAdornment={
                                <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                    {t('workflow_actions.form.visit_planner.in.object.tooltip')}
                                </Hint>
                            }
                            renderValue={this.renderEntityValue}
                            data-testid="workflow_actions.form.visit_planner.in.object"
                        >
                            {this.prepareEntityOptions()}
                        </HintedSelect>
                        {errors.has(DETAIL_PLANNER_IN_ENTITY_ID) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_IN_ENTITY_ID)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD)}>
                        <FieldSelect
                            label={t('workflow_actions.form.visit_planner.in.owner_field')}
                            accountId={accountId}
                            disabled={!inObjectSelected}
                            dataSourceId={inDataSourceId}
                            entityId={inEntityId}
                            onChange={(value) =>
                                updateDetailCallback(
                                    DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD,
                                    value ? value.apiName : null,
                                )
                            }
                            value={getDetail(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD, currentAction.details)}
                            error={errors.has(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD)}
                            hint={t('workflow_actions.form.visit_planner.in.owner_field.tooltip')}
                            required={!isTeamMode}
                            data-testid="workflow_actions.form.visit_planner.in.owner_field"
                            disableNotIncluded
                        />
                        {errors.has(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <ConditionEditor
                        action={currentAction}
                        baseEntity={entity}
                        editorDisabled
                        onSave={this.saveCondition({
                            formulaDetail: DETAIL_PLANNER_IN_ENTITY_CONDITION,
                            expressionDetail: DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION,
                        })}
                        formula={getDetail(DETAIL_PLANNER_IN_ENTITY_CONDITION, currentAction.details) || ''}
                        expression={
                            getDetail(DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION, currentAction.details) || []
                        }
                        builderDisabled={inConditionBuilderDisabled}
                        hint={t('workflow_actions.form.visit_planner.in.condition.tooltip')}
                        conditionLabel={t('workflow_actions.form.visit_planner.in.condition')}
                        formulaPlaceholder={t('workflow_actions.form.visit_planner.condition_placeholder')}
                        ExpressionBuilderFormProps={{
                            addSpecialSection: false,
                            addOldEntitySection: false,
                            addUserSection: false,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        style={{ paddingTop: 16 }}
                        fullWidth
                        error={errors.has(DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY)}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={getDetail(
                                        DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY,
                                        currentAction.details,
                                        false,
                                    )}
                                    name={DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY}
                                    onChange={onDetailChange}
                                />
                            }
                            label={
                                <React.Fragment>
                                    <span>{t('workflow_actions.form.visit_planner.in.exact_position')}</span>
                                    <Hint
                                        TooltipProps={{ className: 'tooltip-question' }}
                                        iProps={{ style: { marginLeft: 8 } }}
                                    >
                                        {t('workflow_actions.form.visit_planner.in.exact_position.tooltip')}
                                    </Hint>
                                </React.Fragment>
                            }
                        />
                        {errors.has(DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: 9 }}>
                    {t('workflow_actions.form.visit_planner.in.visit_settings')}
                    <Hint TooltipProps={{ className: 'tooltip-question' }} iProps={{ style: { marginLeft: 8 } }}>
                        {t('workflow_actions.form.visit_planner.in.visit_settings.tooltip')}
                    </Hint>
                </Grid>
                <Grid item container xs={9} spacing={1} alignItems={'center'}>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={errors.has(DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD)}>
                            <FieldSelect
                                label={t(
                                    'workflow_actions.form.visit_planner.in.visit_settings.days_between_visits_field',
                                )}
                                accountId={accountId}
                                disabled={!inObjectSelected}
                                dataSourceId={inDataSourceId}
                                entityId={inEntityId}
                                onChange={(value) =>
                                    updateDetailCallback(
                                        DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD,
                                        value ? value.apiName : null,
                                    )
                                }
                                value={getDetail(
                                    DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD,
                                    currentAction.details,
                                )}
                                error={errors.has(DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD)}
                                hint={t(
                                    'workflow_actions.form.visit_planner.in.visit_settings.days_between_visits_field.tooltip',
                                )}
                                data-testid="workflow_actions.form.visit_planner.in.visit_settings.days_between_visits_field"
                                disableNotIncluded
                            />
                            {errors.has(DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD) && (
                                <FormHelperText>
                                    {errors.get(DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD)}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={errors.has(DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD)}>
                            <FieldSelect
                                label={t('workflow_actions.form.visit_planner.in.visit_settings.visit_duration_field')}
                                accountId={accountId}
                                disabled={!inObjectSelected}
                                dataSourceId={inDataSourceId}
                                entityId={inEntityId}
                                onChange={(value) =>
                                    updateDetailCallback(
                                        DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD,
                                        value ? value.apiName : null,
                                    )
                                }
                                value={getDetail(DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD, currentAction.details)}
                                error={errors.has(DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD)}
                                hint={t(
                                    'workflow_actions.form.visit_planner.in.visit_settings.visit_duration_field.tooltip',
                                )}
                                data-testid="workflow_actions.form.visit_planner.in.visit_settings.visit_duration_field"
                                disableNotIncluded
                            />
                            {errors.has(DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD) && (
                                <FormHelperText>
                                    {errors.get(DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD)}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Collapse style={{ width: '100%' }} in={isTeamMode}>
                        <ConditionEditor
                            action={currentAction}
                            baseEntity={entity}
                            onSave={this.saveCondition({
                                formulaDetail: DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION,
                                expressionDetail: DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION,
                            })}
                            formula={
                                getDetail(DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION, currentAction.details) ||
                                ''
                            }
                            expression={
                                getDetail(
                                    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION,
                                    currentAction.details,
                                ) || []
                            }
                            builderDisabled={inConditionFixedAssignmentBuilderDisabled}
                            hint={t('workflow_actions.form.visit_planner.in.fixed_assignment_condition.tooltip')}
                            conditionLabel={t('workflow_actions.form.visit_planner.in.fixed_assignment_condition')}
                            formulaPlaceholder={t('workflow_actions.form.visit_planner.condition_placeholder')}
                            ExpressionBuilderFormProps={{
                                addSpecialSection: false,
                                addOldEntitySection: false,
                                addUserSection: false,
                            }}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        );
    }
}

InSection.propTypes = {
    currentAction: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    updateDetailCallback: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    dataSources: PropTypes.array.isRequired,
};

const TranslatedInSection = withTranslation('translations')(InSection);
export { TranslatedInSection as InSection };
