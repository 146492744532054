import { FC, SVGAttributes } from 'react';

const _icons = {
    'arrow-right-from-line': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4 6H6V17H4V6Z" />
            <path d="M7 10.5556H16.7444L12.7611 6.57222L14.3333 5L21 11.6667L14.3333 18.3333L12.7611 16.7611L16.7444 12.7778H7V10.5556Z" />
        </svg>
    ),
    'arrow-right-to-line': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4 10.5556H13.7444L9.76111 6.57222L11.3333 5L18 11.6667L11.3333 18.3333L9.76111 16.7611L13.7444 12.7778H4V10.5556Z" />
            <path d="M19 6H21V17H19V6Z" />
        </svg>
    ),
    'finish-flag': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11 6H9V4H11V6ZM15 4H13V6H15V4ZM9 14H11V12H9V14ZM19 10V8H17V10H19ZM19 14V12H17V14H19ZM13 14H15V12H13V14ZM19 4H17V6H19V4ZM13 8V6H11V8H13ZM7 10V8H9V6H7V4H5V20H7V12H9V10H7ZM15 12H17V10H15V12ZM11 10V12H13V10H11ZM9 8V10H11V8H9ZM13 10H15V8H13V10ZM15 6V8H17V6H15Z" />
        </svg>
    ),
    'finish-flag-filled': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="7" y="4" width="12" height="10" fill="transparent" />
            <path d="M11 6H9V4H11V6ZM15 4H13V6H15V4ZM9 14H11V12H9V14ZM19 10V8H17V10H19ZM19 14V12H17V14H19ZM13 14H15V12H13V14ZM19 4H17V6H19V4ZM13 8V6H11V8H13ZM7 10V8H9V6H7V4H5V20H7V12H9V10H7ZM15 12H17V10H15V12ZM11 10V12H13V10H11ZM9 8V10H11V8H9ZM13 10H15V8H13V10ZM15 6V8H17V6H15Z" />
            <rect x="7" y="12" width="12" height="2" />
            <rect x="7" y="4" width="12" height="2" />
            <rect x="17" y="4" width="2" height="8" />
        </svg>
    ),
    folder: (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z" />
        </svg>
    ),
    'folder-filled': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H10L12 6Z"
            />
        </svg>
    ),
    'gps-fixed': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1273 11.0909C19.7091 7.3 16.7 4.29091 12.9091 3.87273V2H11.0909V3.87273C7.3 4.29091 4.29091 7.3 3.87273 11.0909H2V12.9091H3.87273C4.29091 16.7 7.3 19.7091 11.0909 20.1273V22H12.9091V20.1273C16.7 19.7091 19.7091 16.7 20.1273 12.9091H22V11.0909H20.1273ZM12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
            />
        </svg>
    ),
    'gps-fixed-filled': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1273 11.0909C19.7091 7.3 16.7 4.29091 12.9091 3.87273V2H11.0909V3.87273C7.3 4.29091 4.29091 7.3 3.87273 11.0909H2V12.9091H3.87273C4.29091 16.7 7.3 19.7091 11.0909 20.1273V22H12.9091V20.1273C16.7 19.7091 19.7091 16.7 20.1273 12.9091H22V11.0909H20.1273ZM12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
            />
        </svg>
    ),
    'mode-edit': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.06 3.58988L20.41 4.93988C21.2 5.71988 21.2 6.98988 20.41 7.76988L7.18 20.9999H3V16.8199L13.4 6.40988L16.23 3.58988C17.01 2.80988 18.28 2.80988 19.06 3.58988ZM5 18.9999L6.41 19.0599L16.23 9.22988L14.82 7.81988L5 17.6399V18.9999Z"
            />
        </svg>
    ),
    pushpin: (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14 4V9C14 10.12 14.37 11.16 15 12H9C9.65 11.14 10 10.1 10 9V4H14ZM17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H8V9C8 10.66 6.66 12 5 12V14H10.97V21L11.97 22L12.97 21V14H19V12C17.34 12 16 10.66 16 9V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2Z" />
        </svg>
    ),
    'pushpin-filled': (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H8V9C8 10.66 6.66 12 5 12V14H10.97V21L11.97 22L12.97 21V14H19V12C17.34 12 16 10.66 16 9V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2Z" />
        </svg>
    ),
    trash: (props: any) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 3V4H20V6H19V19C19 20.1 18.1 21 17 21H7C5.9 21 5 20.1 5 19V6H4V4H9V3H15ZM7 19H17V6H7V19ZM9 8H11V17H9V8ZM15 8H13V17H15V8Z"
            />
        </svg>
    ),
};

export const icons: Record<keyof typeof _icons, FC<SVGAttributes<SVGSVGElement>>> = _icons;
