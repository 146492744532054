import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { adapterManager } from '../../service/AdapterManager';
import { nativeAppFriendlyRedirect } from '../../utils';
import Preloader from '../Preloader';
import { ssoAuthProviderStorage } from 'service/Login';
import aesEncryptor from 'service/AesEncryptor';

class Oauth extends React.Component {
    componentDidMount() {
        if (!window.opener) {
            const params = qs.parse(this.props.location.search.replace(/^\?+/g, '')) || {};

            const provider = ssoAuthProviderStorage.getAdapter();
            const credentials = ssoAuthProviderStorage.getCredentials(provider);
            ssoAuthProviderStorage.clearAdapter();

            return adapterManager.forId(provider).then(async (adapter) => {
                let url = adapter.interface.ssoauth + '?' + qs.stringify(params);
                if (credentials) {
                    url += '&credentials=' + encodeURIComponent(aesEncryptor.encrypt(JSON.stringify(credentials)));
                }

                nativeAppFriendlyRedirect(url);
            });
        }

        const sessionId = window.name;
        const attributes = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (
            typeof window.opener.receivePopupResponse === 'function' &&
            !window.opener.receivePopupResponse(sessionId, attributes)
        ) {
            document.body.innerText = 'The Oauth provider response is out of date.';
        }
    }

    render() {
        return <Preloader />;
    }
}

export default withTranslation()(withRouter(Oauth));
