import React from 'react';
import Curtain from 'components/Curtain';
import { WithTranslation, withTranslation } from 'react-i18next';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { CircularProgress, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { userManager } from '../../service/UserManager';
import { accountsManager } from '../../service/AccountsManager';

enum ShardStatus {
    Undefined,
    Updated,
    NotUpdated,
    InitiallyUpdated,
    InitiallyNotUpdated,
}

interface Props extends WithTranslation {}

interface State {
    currentAccountShardStatus: ShardStatus;
}

class PreparingAccountMessage extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        let currentAccountShardStatus = ShardStatus.Undefined;
        if (userManager.getCurrentAccountShardUpdated() === true) {
            currentAccountShardStatus = ShardStatus.InitiallyUpdated;
        } else if (userManager.getCurrentAccountShardUpdated() === false) {
            currentAccountShardStatus = ShardStatus.InitiallyNotUpdated;
        }

        this.state = {
            currentAccountShardStatus,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(
            events.SHARD_UPDATED_STATE_CHANGED,
            this,
            ({ isShardUpdated }: { isShardUpdated: boolean | null }) => {
                if (isShardUpdated === null) {
                    this.setState({
                        currentAccountShardStatus: ShardStatus.Undefined,
                    });
                    return;
                }

                if (this.state.currentAccountShardStatus === ShardStatus.InitiallyNotUpdated && isShardUpdated) {
                    window.location.reload();
                    return;
                }

                if (this.state.currentAccountShardStatus === ShardStatus.Undefined) {
                    this.setState({
                        currentAccountShardStatus: isShardUpdated
                            ? ShardStatus.InitiallyUpdated
                            : ShardStatus.InitiallyNotUpdated,
                    });
                } else {
                    this.setState({
                        currentAccountShardStatus: isShardUpdated ? ShardStatus.Updated : ShardStatus.NotUpdated,
                    });
                }
            },
        );
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.SHARD_UPDATED_STATE_CHANGED, this);
    }

    handleLogout = () => {
        userManager.logout();
    };

    handleRestore = () => {
        accountsManager.restoreShard().then(() => {
            window.location.reload();
        });
    };

    render() {
        const { currentAccountShardStatus } = this.state;
        const { t } = this.props;

        if (userManager.getIsShardDeleted() === true) {
            return (
                <Curtain>
                    <div>
                        <img src="/logo.png" alt="" style={{ width: 210 }} />
                    </div>
                    <div style={{ paddingTop: 20, paddingBottom: 10 }}>{t('main_menu.shard_deleted')}</div>
                    <div>
                        <Button onClick={this.handleRestore}>{t('main_menu.restore')}</Button>
                    </div>
                    <div>
                        <Button onClick={this.handleLogout}>{t('main_menu.logout')}</Button>
                    </div>
                </Curtain>
            );
        }

        if (currentAccountShardStatus === ShardStatus.InitiallyNotUpdated) {
            return (
                <Curtain>
                    <div>
                        <img src="/logo.png" alt="" style={{ width: 210 }} />
                    </div>
                    <CircularProgressTask task={t('preparing_account_message')} />
                    <div>
                        <Button onClick={this.handleLogout}>{t('main_menu.logout')}</Button>
                    </div>
                </Curtain>
            );
        }
        return (
            <>
                {currentAccountShardStatus === ShardStatus.NotUpdated && (
                    <Curtain>
                        <CircularProgressTask task={t('preparing_account_message')} />
                    </Curtain>
                )}

                {this.props.children}
            </>
        );
    }
}

interface P extends WithTranslation {
    task: string;
}

class BaseCircularProgressTask extends React.PureComponent<P> {
    render() {
        return (
            <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                    <CircularProgress size={24} />
                </Grid>
                <Grid item>
                    <p>{this.props.task}</p>
                </Grid>
            </Grid>
        );
    }
}

const CircularProgressTask = withTranslation('translations', { withRef: true })(BaseCircularProgressTask);

export default withTranslation('translations', { withRef: true })(PreparingAccountMessage);
