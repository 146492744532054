import React from 'react';
import dispatcher from '../../../service/dispatcher';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Calendar, Calendars } from '../../../interfaces/calendar/calendar';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';

interface Props extends WithTranslation {
    calendars: Calendars;
    anchorEl: Element;
    handleClose: () => void;
    handleSelect: (calendarId: string) => void;
}

interface State {}

class LeftClickContextMenu extends React.Component<Props, State> {
    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    private onClick(calendarId: string): void {
        this.props.handleSelect(calendarId);
    }

    render() {
        const { t, handleClose, anchorEl, calendars } = this.props;

        const menuCalendars: JSX.Element[] = [];
        calendars.forEach((calendar: Calendar) => {
            menuCalendars.push(
                <ListItem key={calendar.id} button onClick={() => this.onClick(calendar.id)}>
                    <ListItemText
                        primary={t('calendar.context_menu.create_in.calendar', { calendarName: calendar.name })}
                    />
                </ListItem>,
            );
        });

        return (
            <Popover
                open={true}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                disableEnforceFocus
            >
                <div>
                    <div className="c-calendar-schedule-context-menu-popup-title">
                        {t('calendar.context_menu.create_in')}
                    </div>
                    <List>{menuCalendars}</List>
                </div>
            </Popover>
        );
    }
}

export default withTranslation()(LeftClickContextMenu);
