import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid, Icon, IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import CalendarForm from '../Forms/CalendarForm';
import Button from '@material-ui/core/Button';
import { CalendarEvent } from '../../../service/Calendar/CalendarEventRepository';
import { Calendar } from '../../../interfaces/calendar/calendar';
import Backdrop from '../../Backdrop';

interface Props extends WithTranslation {
    onClose: () => void;
    onDelete: () => void;
    onSave: () => void;
    calendarEvent: CalendarEvent | null;
    calendar: Calendar | null;
    updateCalendarEvent: (calendarEvent: CalendarEvent, validateFieldAfter?: string | null) => void;
    onMoreDetailsClick: () => void;
    errors: Map<string, string>;
    validateOne: (fieldName: string) => void;
    clearError: (fieldName: string) => void;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    anchorEl?: Element;
}

interface State {}

class QuickInfoPopup extends React.Component<Props, State> {
    render() {
        const {
            t,
            anchorEl,
            onClose,
            onDelete,
            onSave,
            calendarEvent,
            updateCalendarEvent,
            onMoreDetailsClick,
            validateOne,
            clearError,
            errors,
            setLoading,
            loading,
        } = this.props;

        if (!calendarEvent) {
            return null;
        }

        return (
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableEnforceFocus
            >
                <Backdrop loading={loading}>
                    <div className="c-calendar-schedule-quick-info-popup">
                        <IconButton className="quick-info-popup-close" onClick={onClose}>
                            <Icon>close</Icon>
                        </IconButton>
                        <CalendarForm
                            calendarEvent={calendarEvent}
                            updateCalendarEvent={updateCalendarEvent}
                            errors={errors}
                            validateOne={validateOne}
                            clearError={clearError}
                            setLoading={setLoading}
                            paddingFirstRow={true}
                        />
                        <Grid
                            container
                            justify={!calendarEvent.new ? 'space-between' : 'flex-end'}
                            spacing={2}
                            className="calendar-form-buttons"
                        >
                            {!calendarEvent.new && (
                                <Grid item>
                                    <Button color="secondary" onClick={onDelete}>
                                        {t('delete')}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    className={!calendarEvent.new ? '' : 'btn-more-details'}
                                    color="primary"
                                    onClick={onMoreDetailsClick}
                                >
                                    {t(!calendarEvent.new ? 'edit' : 'calendar.form.more_details')}
                                </Button>
                                {calendarEvent.new && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={onSave}
                                        disabled={errors.size !== 0}
                                    >
                                        {t('button.save')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Backdrop>
            </Popover>
        );
    }
}

export default withTranslation()(QuickInfoPopup);
