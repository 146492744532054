import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';

export default class Button extends AbstractField {
    constructor(isActionButton = false) {
        super(FIELD_TYPES.BUTTON);

        this.isActionButton = isActionButton;
        this.userLocationAvailable = false;
    }

    getIsActionButton() {
        return this.isActionButton;
    }

    setUserLocationAvailable(userLocationAvailable) {
        this.userLocationAvailable = userLocationAvailable;

        return this;
    }

    isUserLocationAvailable() {
        return this.userLocationAvailable;
    }

    isEmpty() {
        return false;
    }

    validateValue() {
        return true;
    }
}
