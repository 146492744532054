import i18n from 'i18next';
import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { AdapterFullInfo, AdapterId, GetAdaptersResponse } from 'components/types';
import { AdapterSettings, DataSourceSettings } from 'service/types';

const t = i18n.t.bind(i18n);

class AdapterManager extends BackendService {
    private adapters: AdapterFullInfo[] | null;

    constructor() {
        super();
        this.adapters = null;
    }

    async list(): Promise<AdapterFullInfo[]> {
        if (this.adapters !== null) {
            return Promise.resolve(this.adapters);
        }
        return this.requestApi(apiRoutes.adapters, 'GET').then((adapters: GetAdaptersResponse) => {
            this.adapters = adapters;
            return this.adapters;
        });
    }

    async forId(id: AdapterId): Promise<AdapterFullInfo> {
        const adapters = await this.list();
        const index = adapters.findIndex((adapter) => adapter.id === id);

        if (index === -1) {
            throw new Error(t('adapter_manager.error.adapter_by_id_not_found', { adapterId: id }));
        }

        if (adapters[index].interface !== null) {
            return Promise.resolve(adapters[index]);
        }

        const url = reverse(apiRoutes.standardAdapter, { adapterId: id });
        return this.requestApi(url, 'GET').then((adapter: AdapterFullInfo) => {
            adapters[index] = adapter;
            return adapter;
        });
    }

    async forUrl(url: string) {
        const adapters = await this.list();
        const index = adapters.findIndex((adapter) => adapter.url === url);

        if (index === -1) {
            throw new Error(t('adapter_manager.error.adapter_by_url_not_found', { adapterUrl: url }));
        }

        if (adapters[index].interface !== null) {
            return Promise.resolve(adapters[index]);
        }

        return this.requestApi(apiRoutes.customAdapter, 'GET', { url }).then((adapter: AdapterFullInfo) => {
            adapters[index] = adapter;
            return adapter;
        });
    }

    static initAdapterSettings(params: DataSourceSettings, settings: AdapterSettings): AdapterSettings {
        const result: AdapterSettings = {};
        for (let parameter of params) {
            if (settings && settings.hasOwnProperty(parameter.name)) {
                result[parameter.name] = settings[parameter.name];
            } else if (parameter.default !== undefined) {
                result[parameter.name] = parameter.default;
            } else {
                result[parameter.name] = null;
            }
        }

        return result;
    }
}

export { AdapterManager };
export const adapterManager = new AdapterManager();
