import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Grid, MenuItem, Popover, Select } from '@material-ui/core';
import { FormActions, FormAlert, FormBackdrop } from '../PureFormDialog/Form';
import { withTranslation } from 'react-i18next';
import { sharedMapManager } from '../../service/SharedMapManager';
import { userManager } from '../../service/UserManager';
import copy from 'copy-to-clipboard';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import './style.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    LAYERS_MODULE,
    MAP_MODULE,
    PROSPECTING_MODULE,
    ROUTING_MODULE,
    TABLE_MODULE,
    TERRITORIES_MODULE,
} from '../Permissions/constants';
import Confirmation from '../Confirmation';
import mapStateManagerFactory from '../../service/MapStateManagerFactory';
import entityViewManager from '../../service/EntityViewManager';
import GoogleApi from '../../api/GoogleApi';
import PureFormDialog from '../PureFormDialog';
import ManualLocationInput from '../RouteEditor/RouteForm/ManualLocationInput';
import { INITIAL_BOUNDS } from '../../service/PointsManager';
import { withSnackbar } from 'notistack';
import SignatureModal from './SignatureModal';
import QuestionHint from '../Hint/QuestionHint';
import LandingLink from '../HelpLink/LandingLink';

const ZOOM_LEVELS = Array.from(new Array(18), (x, i) => i + 6);

class SharedMapModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sharedMap: { ...props.sharedMap },
            userWithWriteAccess: {
                value: false,
                features: '',
            },
            loading: false,
            users: [],
            message: null,
            errors: new Map(),
            detailsEl: null,
            isTextCopied: false,
            showMap: false,
            rectangle: null,
            account: null,
            signatureModal: false,
            mapLoaded: false,
        };

        this.copyTimeout = null;
        this.googleApi = null;
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        Promise.all([userManager.getAccountUsers(this.props.accountId), userManager.getAccount(this.props.accountId)])
            .then((result) => {
                this.setState(
                    {
                        loading: false,
                        users: result[0].filter((u) => u.role.forSharedMap === true),
                        account: result[1],
                    },
                    () => {
                        if (this.state.sharedMap.user) {
                            this.checkPermissions(this.state.sharedMap.user);
                        }
                    },
                );
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger',
                    },
                });
            });

        userManager
            .requestCurrentUser()
            .then(() => {
                if (userManager.isRoleSuperAdmin()) {
                    return entityViewManager.loadFrameData(this.props.accountId);
                }
                return entityViewManager.loadFrameData();
            })
            .then((response) => {
                this.googleApi = new GoogleApi(response.apiKeys.google);
                this.googleApi.loadMapScript().then(() => {
                    this.setState({
                        mapLoaded: true,
                    });
                });
            });
    }

    handleSave = () => {
        this.setState({
            loading: true,
        });
        sharedMapManager
            .save(this.props.accountId, this.state.sharedMap)
            .then((response) => {
                this.setState({
                    loading: false,
                });
                this.props.onSaved(response);
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger',
                    },
                    errors: error.details,
                });
            });
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
            errors: new Map(),
        });
    };

    onChange = (field) => (e) => {
        let value = e.target.value;
        if (
            field === 'userLocation' ||
            field === 'allowLayers' ||
            field === 'allowTable' ||
            field === 'forceTableModeLayers' ||
            field === 'checkSignature'
        ) {
            value = e.target.checked;
        }
        if (field === 'user') {
            this.checkPermissions(value);
        }
        let initMap = false;
        this.setState(
            (state) => {
                const map = { ...state.sharedMap };
                if (field === 'positionType' && value === 'center_point_and_zoom') {
                    map.northWest = '';
                    map.southEast = '';
                }
                if (field === 'positionType' && value === 'area') {
                    map.location = '';
                }
                if (field === 'location') {
                    map.locationLatLng = value;
                }
                const newState = {
                    sharedMap: { ...map, [field]: value },
                };
                if (field === 'positionType' && value === 'area') {
                    initMap = true;
                    newState.showMap = true;
                }
                return newState;
            },
            () => {
                if (initMap) {
                    this.googleApi.loadMapScript().then(this.initMap);
                }
            },
        );
    };

    checkPermissions(value) {
        const user = this.state.users.find((u) => u.id === value);
        if (user) {
            const writableEntities = user.entities.filter((e) => e.editable).map((e) => e.label);
            const mapProperties = userManager.userHasAccessTo(
                LAYERS_MODULE.NAME,
                LAYERS_MODULE.FEATURES.MODIFY_MAP_PROPERTIES.NAME,
                null,
                null,
                user,
            );
            const mapLayers = userManager.userHasAccessTo(
                LAYERS_MODULE.NAME,
                LAYERS_MODULE.FEATURES.MODIFY_VIEW_LAYERS.NAME,
                null,
                null,
                user,
            );
            const mapSettings = userManager.userHasAccessTo(
                MAP_MODULE.NAME,
                MAP_MODULE.FEATURES.MODIFY_SETTING.NAME,
                null,
                null,
                user,
            );
            const tableSettings = userManager.userHasAccessTo(
                TABLE_MODULE.NAME,
                TABLE_MODULE.FEATURES.SHOW_HIDE_TABLE_COLUMNS.NAME,
                null,
                null,
                user,
            );
            const prospectingSettings = userManager.userHasAccessTo(
                PROSPECTING_MODULE.NAME,
                PROSPECTING_MODULE.FEATURES.EXPORT.NAME,
                PROSPECTING_MODULE.FEATURES.EXPORT.SUB_FEATURES.MODIFY_FIELD_MAPPING.NAME,
                null,
                user,
            );
            const routingSettings = userManager.userHasAccessTo(ROUTING_MODULE.NAME, null, null, null, user);
            const territoriesSettings = userManager.userHasAccessTo(
                TERRITORIES_MODULE.NAME,
                TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.NAME,
                TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.SUB_FEATURES.MODIFY_TERRITORIES.NAME,
                null,
                user,
            );
            const { t } = this.props;
            const featuresMap = {
                hasWritableEntities: {
                    value: writableEntities.length > 0,
                    trans: t('shared_map.editable.entities', {
                        entities: writableEntities.join(', '),
                    }),
                },
                mapProperties: { value: mapProperties, trans: t('shared_map.editable.map_properties') },
                mapLayers: { value: mapLayers, trans: t('shared_map.editable.map_layers') },
                mapSettings: { value: mapSettings, trans: t('shared_map.editable.map_settings') },
                tableSettings: { value: tableSettings, trans: t('shared_map.editable.table_settings') },
                prospectingSettings: {
                    value: prospectingSettings,
                    trans: t('shared_map.editable.prospecting_settings'),
                },
                routingSettings: { value: routingSettings, trans: t('shared_map.editable.routing_settings') },
                territoriesSettings: {
                    value: territoriesSettings,
                    trans: t('shared_map.editable.territories_settings'),
                },
            };
            const features = [];
            for (const [, v] of Object.entries(featuresMap)) {
                if (v.value) {
                    features.push(v.trans);
                }
            }
            this.setState({
                userWithWriteAccess: {
                    value: !!features.length,
                    features,
                },
            });
        } else {
            this.setState({
                userWithWriteAccess: {
                    value: false,
                    features: [],
                },
            });
        }
    }

    handleCopy = (e) => {
        e.preventDefault();
        copy(this.state.sharedMap.fullLink);
        this.setState({
            isTextCopied: true,
        });
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout);
            this.copyTimeout = null;
        }
        this.copyTimeout = setTimeout(() => {
            this.setState({
                isTextCopied: false,
            });
            this.copyTimeout = null;
        }, 3000);
    };

    handleRegenerate = () => {
        const l = sharedMapManager.createNewLink();
        this.setState((state) => {
            return {
                sharedMap: { ...state.sharedMap, link: l.link, fullLink: l.fullLink },
            };
        });
    };

    handleDetailsOpen = (e) => {
        e.preventDefault();
        const { currentTarget } = e;
        this.setState((state) => ({
            detailsEl: state.detailsEl !== null ? null : currentTarget,
        }));
    };

    handleDetailsClose = () => {
        this.setState({
            detailsEl: null,
        });
    };

    handleSelectArea = (e) => {
        e.preventDefault();
        this.setState(
            {
                showMap: true,
            },
            () => {
                this.googleApi.loadMapScript().then(this.initMap);
            },
        );
    };

    handleCloseSelectArea = () => {
        this.setState({
            showMap: false,
        });
    };

    initMap = () => {
        this.setState({
            rectangle: null,
        });
        const manager = mapStateManagerFactory.getManager();
        const location = manager.getMapLocation();
        const lat = location ? location.lat : 39.086019;
        const lng = location ? location.lng : -95.995674;
        const center = { lat, lng };
        const map = new window.google.maps.Map(document.getElementById('shared-map-google'), {
            zoom: 4,
            center,
            clickableIcons: false,
        });
        const drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.RECTANGLE],
            },
            rectangleOptions: {
                editable: true,
                clickable: true,
                draggable: true,
            },
        });
        window.google.maps.event.addListener(drawingManager, 'rectanglecomplete', (rectangle) => {
            this.setState({
                rectangle,
            });
            drawingManager.setDrawingMode(null);
            drawingManager.setOptions({
                drawingControl: false,
            });
        });
        drawingManager.setMap(map);
    };

    getRectangle = () => {
        const bounds = this.state.rectangle.getBounds();
        const NE = bounds.getNorthEast();
        const SW = bounds.getSouthWest();
        const northWest = { lat: NE.lat(), lng: SW.lng() };
        const southEast = { lat: SW.lat(), lng: NE.lng() };
        return { northWest, southEast };
    };

    handleSelectRectangle = () => {
        const rectangle = this.getRectangle();
        this.setState((state) => {
            const northWest = `${rectangle.northWest.lat},${rectangle.northWest.lng}`;
            const southEast = `${rectangle.southEast.lat},${rectangle.southEast.lng}`;
            return {
                sharedMap: { ...state.sharedMap, northWest, southEast },
                showMap: false,
            };
        });
    };

    handlePointFound = (point) => {
        if (!point.lat || !point.lng) {
            this.props.enqueueSnackbar(this.props.t('address_lookup.search.record_incorrect_address'), {
                variant: 'warning',
            });
            return;
        }
        this.setState((state) => {
            return {
                sharedMap: {
                    ...state.sharedMap,
                    location: point.address,
                    locationLatLng: {
                        lat: point.lat,
                        lng: point.lng,
                    },
                },
            };
        });
    };

    handleOpenGenerateSignature = () => {
        this.setState({
            signatureModal: true,
        });
    };

    handleCloseGenerateSignature = () => {
        this.setState({
            signatureModal: false,
        });
    };

    get point() {
        if (this.state.sharedMap.location && this.state.sharedMap.locationLatLng) {
            return {
                address: this.state.sharedMap.location,
            };
        }
        return null;
    }

    render() {
        const {
            sharedMap,
            loading,
            mapLoaded,
            message,
            users,
            userWithWriteAccess,
            isTextCopied,
            showMap,
            account,
            signatureModal,
        } = this.state;
        const { t } = this.props;
        const {
            user,
            positionType,
            zoom,
            userLocation,
            userLocationZoom,
            fullLink,
            northWest,
            southEast,
            allowLayers,
            allowTable,
            forceTableModeLayers,
            checkSignature,
        } = sharedMap;
        const locationError = this.state.errors.has('locationLatLng') || this.state.errors.has('location');
        let disabledLayers = null;
        let disabledTable = null;
        if (user) {
            const userInfo = this.state.users.find((u) => u.id === user);
            disabledLayers =
                userInfo &&
                !userManager.userHasAccessTo(
                    LAYERS_MODULE.NAME,
                    LAYERS_MODULE.FEATURES.SHOW_LAYERS_PANE.NAME,
                    null,
                    null,
                    userInfo,
                )
                    ? t('shared_map.feature.disabled.profile')
                    : null;
            disabledTable =
                userInfo && !userManager.userHasAccessTo(TABLE_MODULE.NAME, null, null, null, userInfo)
                    ? t('shared_map.feature.disabled.profile')
                    : null;
        }
        if (account && false === account.allowLayers) {
            disabledLayers = t('shared_map.feature.layers.disabled.account');
        }
        if (account && false === account.allowTable) {
            disabledTable = t('shared_map.feature.table.disabled');
        }
        const noUsers = !users.length;

        return (
            <div className="c-shared-map-modal">
                <div id="google-places" style={{ display: 'none' }} />
                {showMap && (
                    <PureFormDialog
                        open
                        title={
                            <React.Fragment>
                                {t('shared_map.select_area.draw')}
                                <br />
                                <span style={{ fontSize: '70%' }}>
                                    <i className="fas fa-exclamation-circle" style={{ color: '#feb81b' }} />{' '}
                                    {t('shared_map.select_area.help')}
                                </span>
                            </React.Fragment>
                        }
                        onClose={this.handleCloseSelectArea}
                        fullWidth
                        maxWidth="lg"
                    >
                        <div id="shared-map-google" />
                        <FormActions>
                            <Button
                                color="primary"
                                onClick={this.handleSelectRectangle}
                                disabled={!this.state.rectangle}
                                data-testid="shared_map.select_area.button.save"
                            >
                                {t('button.save')}
                            </Button>
                        </FormActions>
                    </PureFormDialog>
                )}
                {signatureModal && (
                    <PureFormDialog
                        title={t('shared_map.signature_modal.title')}
                        open
                        onClose={this.handleCloseGenerateSignature}
                        maxWidth="md"
                        fullWidth
                    >
                        <SignatureModal accountId={this.props.accountId} link={fullLink} />
                    </PureFormDialog>
                )}
                {message !== null && (
                    <FormAlert onClose={this.handleAlertClose} type={message.type}>
                        {message.text}
                    </FormAlert>
                )}
                <FormBackdrop loading={loading || !mapLoaded}>
                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                        <FormControl fullWidth>
                            <InputLabel>
                                {t('shared_map.user.title')}
                                <QuestionHint>{t('shared_map.user.hint')}</QuestionHint>
                            </InputLabel>
                            <Select
                                value={user}
                                onChange={this.onChange('user')}
                                fullWidth
                                error={this.state.errors.has('user')}
                                data-testid="shared_map.user"
                            >
                                <MenuItem value="0" data-testid="shared_map.user.not_selected">
                                    {t('shared_map.user.not_selected')}
                                </MenuItem>
                                {users.map((u) => (
                                    <MenuItem key={u.id} value={u.id}>
                                        {u.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {this.state.errors.has('user') && (
                                <FormHelperText>{this.state.errors.get('user')}</FormHelperText>
                            )}
                            {noUsers ? (
                                <FormAlert type="warning" style={{ margin: 0 }}>
                                    <i className="fas fa-exclamation-triangle" style={{ marginRight: 8 }} />
                                    <span dangerouslySetInnerHTML={{ __html: t('shared_map.users.list_empty') }} />
                                </FormAlert>
                            ) : null}
                            {userWithWriteAccess.value && (
                                <FormAlert type="warning" style={{ margin: 0 }}>
                                    {t('shared_map.user.write_access')}&nbsp;
                                    <button
                                        onClick={this.handleDetailsOpen}
                                        className="btn-link"
                                        data-testid="shared_map.editable.details"
                                    >
                                        {t('shared_map.editable.details')}
                                    </button>
                                    <Popover
                                        open={this.state.detailsEl !== null}
                                        anchorEl={this.state.detailsEl}
                                        onClose={this.handleDetailsClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        disableEnforceFocus
                                    >
                                        <ul style={{ listStyleType: 'none', padding: '16px', margin: 0 }}>
                                            {userWithWriteAccess.features.map((f) => (
                                                <li key={f}>{f}</li>
                                            ))}
                                        </ul>
                                    </Popover>
                                </FormAlert>
                            )}
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: '46px' }}>
                            <strong>{t('shared_map.features')}</strong>
                            <Grid item md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allowLayers}
                                            disabled={!!disabledLayers}
                                            onClick={this.onChange('allowLayers')}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>
                                            {t('shared_map.layers.label')}
                                            <QuestionHint>
                                                {!!disabledLayers ? disabledLayers : t('shared_map.layers.label.hint')}
                                            </QuestionHint>
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allowTable}
                                            disabled={!!disabledTable}
                                            onClick={this.onChange('allowTable')}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>
                                            {t('shared_map.table.label')}
                                            <QuestionHint>
                                                {!!disabledTable ? disabledTable : t('shared_map.table.label.hint')}
                                            </QuestionHint>
                                        </span>
                                    }
                                />
                            </Grid>
                            {allowTable && (
                                <Grid className="sub-feature" container item md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={forceTableModeLayers}
                                                onClick={this.onChange('forceTableModeLayers')}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <span>
                                                {t('shared_map.force_table_mode_layers.label')}
                                                <QuestionHint>
                                                    {t('shared_map.force_table_mode_layers.label.hint')}
                                                </QuestionHint>
                                            </span>
                                        }
                                    />
                                </Grid>
                            )}
                        </FormControl>
                        <FormControl style={{ marginTop: '26px' }} fullWidth>
                            <div>
                                <strong>{t('shared_map.default_position')}</strong>
                                <QuestionHint>{t('shared_map.default_position.hint')}</QuestionHint>
                            </div>
                            <RadioGroup
                                name="positionType"
                                value={positionType}
                                onChange={this.onChange('positionType')}
                                row
                            >
                                <FormControlLabel
                                    value="center_point_and_zoom"
                                    control={<Radio color="primary" />}
                                    label={t('shared_map.default_position.center_point')}
                                />
                                <FormControlLabel
                                    value="area"
                                    control={<Radio color="primary" />}
                                    label={t('shared_map.default_position.area')}
                                />
                                {positionType === 'area' && (
                                    <Button
                                        onClick={this.handleSelectArea}
                                        color="primary"
                                        data-testid="shared_map.select_area"
                                    >
                                        {t('shared_map.select_area')}
                                    </Button>
                                )}
                            </RadioGroup>
                        </FormControl>
                        {positionType === 'center_point_and_zoom' && (
                            <FormControl fullWidth>
                                <Grid container style={{ minHeight: '66px', alignItems: 'flex-start' }}>
                                    <Grid item md={9} style={{ paddingRight: '8px' }}>
                                        <ManualLocationInput
                                            bounds={INITIAL_BOUNDS}
                                            placeholder={t('shared_map.default_position.location.label')}
                                            label={t('shared_map.default_position.location')}
                                            hint={t('shared_map.default_position.location.hint')}
                                            onPointFound={this.handlePointFound}
                                            address={this.point?.address ?? null}
                                            onCancel={() => {}}
                                            accountId={account ? account.id : null}
                                            hasError={!!locationError}
                                            clickAwayReset={false}
                                        />
                                        {locationError && <FormHelperText>{locationError}</FormHelperText>}
                                    </Grid>
                                    <Grid item md={3} style={{ paddingLeft: '6px', paddingRight: '18px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                {t('shared_map.default_position.users_location.zoom.label')}
                                            </InputLabel>
                                            <Select
                                                value={zoom}
                                                error={this.state.errors.has('zoom')}
                                                onChange={this.onChange('zoom')}
                                                fullWidth
                                                style={{ height: '100%' }}
                                                data-testid="shared_map.default_position.users_location.zoom"
                                            >
                                                {ZOOM_LEVELS.map((z) => {
                                                    return (
                                                        <MenuItem key={z} value={z}>
                                                            {z}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <QuestionHint
                                                style={{ position: 'absolute', right: '-18px', bottom: '10px' }}
                                            >
                                                {t('shared_map.default_position.users_location.zoom.hint')}
                                            </QuestionHint>
                                        </FormControl>
                                        {locationError && <FormHelperText>&nbsp;</FormHelperText>}
                                        {this.state.errors.has('userLocationZoom') && (
                                            <FormHelperText>{this.state.errors.get('userLocationZoom')}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                        {positionType === 'area' && (
                            <FormControl fullWidth>
                                <Grid container className="shared-map-area">
                                    <Grid item md={6} style={{ paddingRight: '8px' }}>
                                        <TextField
                                            label={t('shared_map.default_position.north_west_corner')}
                                            data-testid="shared_map.default_position.north_west_corner"
                                            fullWidth
                                            value={northWest || ''}
                                            InputProps={{ disableUnderline: false }}
                                            onChange={this.onChange('northWest')}
                                            error={this.state.errors.has('northWest')}
                                            autoFocus
                                        />
                                        {this.state.errors.has('northWest') && (
                                            <FormHelperText>{this.state.errors.get('northWest')}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item md={6} style={{ paddingLeft: '8px' }}>
                                        <TextField
                                            label={t('shared_map.default_position.south_east_corner')}
                                            data-testid="shared_map.default_position.south_east_corner"
                                            fullWidth
                                            value={southEast || ''}
                                            InputProps={{ disableUnderline: false }}
                                            onChange={this.onChange('southEast')}
                                            error={this.state.errors.has('southEast')}
                                        />
                                        {this.state.errors.has('southEast') && (
                                            <FormHelperText>{this.state.errors.get('southEast')}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                        <FormControl fullWidth style={{ marginTop: '26px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userLocation}
                                        onClick={this.onChange('userLocation')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <span>
                                        {t('shared_map.default_position.users_location')}
                                        <QuestionHint>
                                            {t('shared_map.default_position.center_point.hint')}
                                        </QuestionHint>
                                    </span>
                                }
                            />
                            {userLocation && (
                                <Grid item md={4}>
                                    <Grid container spacing={0}>
                                        <Grid item md={8}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    {t('shared_map.default_position.users_location.zoom')}
                                                </InputLabel>
                                                <Select
                                                    fullWidth
                                                    value={userLocationZoom}
                                                    error={this.state.errors.has('userLocationZoom')}
                                                    onChange={this.onChange('userLocationZoom')}
                                                    label={t('shared_map.default_position.users_location.zoom.label')}
                                                    data-testid="shared_map.default_position.users_location.zoom"
                                                >
                                                    <MenuItem
                                                        value={0}
                                                        data-testid="shared_map.default_position.users_location.zoom.dont_change"
                                                    >
                                                        {t(
                                                            'shared_map.default_position.users_location.zoom.dont_change',
                                                        )}
                                                    </MenuItem>
                                                    {ZOOM_LEVELS.map((z) => {
                                                        return (
                                                            <MenuItem key={z} value={z}>
                                                                {z}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            {this.state.errors.has('userLocationZoom') && (
                                                <FormHelperText>
                                                    {this.state.errors.get('userLocationZoom')}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </FormControl>
                        <FormControl style={{ marginTop: '26px' }} fullWidth>
                            <strong>{t('shared_map.link')}</strong>
                            <TextField
                                style={{ marginTop: '26px' }}
                                label={t('shared_map.link.link')}
                                data-testid="shared_map.link.link"
                                fullWidth
                                value={fullLink}
                                InputProps={{ disableUnderline: false, readOnly: true }}
                            />
                            <Grid container direction="row" style={{ marginTop: '16px' }}>
                                <Button onClick={this.handleCopy} color="primary" data-testid="shared_map.link.copy">
                                    {isTextCopied ? t('shared_map.link.copied') : t('shared_map.link.copy')}
                                </Button>
                                <Confirmation
                                    text={t('shared_map.link.regenerate.confirm')}
                                    onConfirm={this.handleRegenerate}
                                >
                                    <Button
                                        style={{ marginLeft: '16px' }}
                                        color="primary"
                                        data-testid="shared_map.link.regenerate"
                                    >
                                        {t('shared_map.link.regenerate')}
                                    </Button>
                                </Confirmation>
                            </Grid>
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: '26px' }}>
                            <Grid container direction="row">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkSignature}
                                                onClick={this.onChange('checkSignature')}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <span>
                                                {t('shared_map.check_signature.label')}
                                                <LandingLink article="5202420">
                                                    <QuestionHint>
                                                        {t('shared_map.check_signature.label.hint')}
                                                    </QuestionHint>
                                                </LandingLink>
                                            </span>
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    {checkSignature && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleOpenGenerateSignature}
                                            data-testid="shared_map.generate_signature.label"
                                        >
                                            {t('shared_map.generate_signature.label')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </FormControl>
                    </form>
                </FormBackdrop>

                <FormActions>
                    {userWithWriteAccess.value ? (
                        <Confirmation
                            text={() => {
                                return (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('shared_map.user.write_access.confirm', {
                                                features: userWithWriteAccess.features.join('; '),
                                            }),
                                        }}
                                    />
                                );
                            }}
                            onConfirm={this.handleSave}
                            disabled={loading}
                            textNo={t('cancel')}
                            textYes={t('button.understand_confirm')}
                        >
                            <Button color="primary" data-testid="shared_map.button.save">
                                {t('button.save')}
                            </Button>
                        </Confirmation>
                    ) : (
                        <Button color="primary" onClick={this.handleSave} data-testid="shared_map.button.save">
                            {t('button.save')}
                        </Button>
                    )}
                </FormActions>
            </div>
        );
    }
}

SharedMapModal.propTypes = {
    sharedMap: PropTypes.object.isRequired,
    onSaved: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(SharedMapModal));
