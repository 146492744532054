import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Props {
    value: boolean;
    name: string;
    label: string;
    onChangeBool: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    disabled: boolean;
    autoFocus: boolean;
}

class BooleanField extends React.PureComponent<Props> {
    render() {
        const { value, name, label, disabled, autoFocus, onChangeBool } = this.props;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        color="primary"
                        onChange={onChangeBool}
                        name={name}
                        autoFocus={autoFocus}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        );
    }
}

export default BooleanField;
