import WorkflowManager, { NAMESPACE_GLOBAL } from './WorkflowManager';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { userManager } from './UserManager';
import dispatcher from './dispatcher';
import events from '../events';
import { Workflow } from '../interfaces';

/**
 * @deprecated Workflow.Locations
 */
export const LOCATIONS = {
    MAP_POPUP: 'mapPopup',
    WAYPOINT: 'waypoint',
    TABLE_CONTEXT_MENU: 'tableContextMenu',
    TABLE_GROUP_ACTIONS: 'tableGroupActions',
    RECORD_VIEW: 'recordView',
    MAP_CLICK: 'mapClick',
    MAIN_MENU: 'mainMenu',
};

export const ORDER = {
    FIRST: 'first',
    LAST: 'last',
    ALL: 'all',
};

const ORDER_LIMIT = 100;

class WorkflowButtonManager extends WorkflowManager {
    constructor() {
        super();

        this.buttons = [];

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, this.reload);
        dispatcher.subscribe(events.WS_WORKFLOW_ACTIONS_BUTTONS_UPDATED, this, this.update);
    }

    reload = (user) => {
        if (!user || !user.accountId) {
            const refreshed = this.buttons.length !== 0;
            this.buttons = [];
            refreshed && this.refresh();
            return;
        }

        this.list(user.accountId)
            .then((data) => {
                this.buttons = data.items.sort((a, b) => a.order - b.order);
            })
            .then(() => {
                this.refresh();
            });
    };

    update = ({ modificationType, buttonIds }) => {
        if (modificationType === 'delete') {
            this.buttons = this.buttons.filter(({ id }) => !buttonIds.includes(id));
            this.refresh();
            return;
        }

        this.reload(userManager.getCurrentUser());
    };

    refresh() {
        dispatcher.dispatch(events.WORKFLOW_ACTIONS_BUTTONS_REFRESHED, {});
    }

    getDefaultButton(namespace = NAMESPACE_GLOBAL) {
        return {
            id: null,
            entities: [],
            actionGroups: [],
            roles: [],
            isActive: true,
            name: '',
            namespace,
            apiName: '',
            visibilityType: Workflow.VisibilityType.ONE,
            label: '',
            icon: '',
            locations: [],
            isUserLocationAvailable: false,
            isSelectedLocationAvailable: false,
            parallelSession: Workflow.ParallelSession.DIFFERENT_RECORD_SET,
            apiEndpointName: '',
            async: null,
            callbackUrl: '',
            auth: null,
            signature: null,
        };
    }

    getButtonsFor(location, order, entityId = null) {
        const result = [];

        const buttons =
            order === ORDER.ALL
                ? this.buttons
                : this.buttons.filter((button) => {
                      if (order === ORDER.FIRST) {
                          return button.order <= ORDER_LIMIT;
                      }
                      return button.order > ORDER_LIMIT;
                  });

        const entityIds = entityId === null ? null : Array.isArray(entityId) ? entityId : [entityId];

        for (let button of buttons) {
            if (
                !button.locations.includes(location) ||
                !button.roles.some((role) => role.id === userManager.getCurrentUser().role.id) ||
                !button.isActive ||
                button.disabled
            ) {
                continue;
            }

            if (entityIds !== null) {
                if (button.entities.length === 0 || button.entities.some((entity) => entityIds.includes(entity.id))) {
                    result.push(button);
                }
            } else {
                result.push(button);
            }
        }

        return result;
    }

    get(accountId, buttonId) {
        const url = reverse(apiRoutes.account.workflowButtons.item, { accountId, buttonId });
        return this.requestApi(url, 'GET').then((button) => {
            return this.denormalizeButton(button);
        });
    }

    list(accountId, filters = [], sorting = [], page = null, pageSize = null) {
        const url = reverse(apiRoutes.account.workflowButtons, { accountId });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize }).then((data) => {
            return {
                ...data,
                items: data.items.map((button) => this.denormalizeButton(button)),
            };
        });
    }

    normalizeButton(button) {
        return { ...button, actionGroups: this.normalizeGroups(button.actionGroups) };
    }

    denormalizeButton(button) {
        return { ...button, actionGroups: this.denormalizeGroups(button.actionGroups) };
    }

    save(accountId, button) {
        if (!button.id) {
            const url = reverse(apiRoutes.account.workflowButtons, { accountId });
            return this.requestApi(url, 'POST', this.normalizeButton(button))
                .then((button) => {
                    return this.denormalizeButton(button);
                })
                .then((button) => {
                    this.buttons.push(button);

                    this.refresh();

                    return button;
                });
        }

        const url = reverse(apiRoutes.account.workflowButtons.item, { accountId, buttonId: button.id });
        return this.requestApi(url, 'PATCH', this.normalizeButton(button))
            .then((button) => {
                return this.denormalizeButton(button);
            })
            .then((button) => {
                this.buttons = this.buttons
                    .map((currentButton) => (currentButton.id === button.id ? button : currentButton))
                    .sort((a, b) => a.order - b.order);

                this.refresh();

                return button;
            });
    }

    delete(accountId, buttonId) {
        const url = reverse(apiRoutes.account.workflowButtons.item, { accountId, buttonId });
        return this.requestApi(url, 'DELETE').then(() => {
            this.buttons = this.buttons.filter((currentButton) => currentButton.id !== buttonId);

            this.refresh();
        });
    }
}

export const workflowButtonManager = new WorkflowButtonManager();
