import React from 'react';
import { Select, InputLabel, FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../locales/i18n';
import CriteriaControl from './CriteriaControl';
import {
    DETAIL_RECORDS_LIMIT,
    DETAIL_STORE_FIELD,
    TYPE_COMMIT,
    getDetail,
} from '../../../service/WorkflowActionManager';
import cloneDeep from 'lodash/cloneDeep';
import Hint from '../../Hint';
import WorkflowGroups from '../../WorkflowGroups';
import { entityManager } from '../../../service/SimpleEntityManager';
import { TYPE_DEFAULT_ACTION_GROUP, TYPE_MATCH_ACTION_GROUP } from '../../../service/WorkflowGroupManager';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { CallContext, TYPE_ENTITY_LINK, TYPE_ENTITY_TARGET } from '../../utils/CallContext';
import StoreRecordsControl from './StoreRecordsControl';
import AbstractForm from '../AbstractForm/AbstractForm';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            currentAction: { ...props.action },
            baseEntity: null,
            targetEntity: null,
            disableRecordsLimit: false,
        };
    }

    componentDidMount() {
        const baseEntityId = this.state.currentAction.callContext.getBaseEntityId();
        if (baseEntityId) {
            entityManager.get(baseEntityId).then((baseEntity) => {
                this.setState({ baseEntity });
            });
        }
        const targetEntityId = this.state.currentAction.callContext.getTargetEntityId();
        if (targetEntityId) {
            entityManager.get(targetEntityId).then((targetEntity) => {
                this.setState({ targetEntity });
            });
        }
    }

    initCurrentEntity(entityId) {
        if (!entityId) {
            return;
        }

        entityManager.get(entityId, true, true).then((entity) => {
            this.setState({ baseEntity: entity });
        });
    }

    handleCallContext(event) {
        const name = event.target.name;
        const value = event.target.value;

        const targetEntityId = this.state.currentAction.callContext.getTargetEntityId();
        const context = CallContext.create(value);
        if (targetEntityId) {
            context.addTargetEntity(targetEntityId);
        }

        if (value) {
            this.initCurrentEntity(value);
        }
        this.updateActionState(name, context);
        this.setState({ entitySelected: true });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, baseEntity, targetEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (baseEntity && !isActiveEntity(baseEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }
            const targetEntityId = currentAction.callContext.getTargetEntityId();
            if (!targetEntityId) {
                errors.set('callContextTarget', t('errors.not_empty'));
            }
            if (targetEntity && !isActiveEntity(targetEntity)) {
                errors.set('callContextTarget', t('automation_elements.form.entity.inactive_error'));
            }
            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    updateCallContext = (inputName, entityType, entityId) => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (entityId) {
                    currentAction.callContext.replace(entityType, entityId);
                } else {
                    currentAction.callContext.remove(entityType);
                }
                let errors = state.errors;
                if (inputName && errors.has(inputName)) {
                    errors = new Map(errors);
                    errors.delete(inputName);
                    if (errors.size === 0) {
                        this.props.onSubmitError(null);
                    }
                }
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    handleTargetEntityChange = (event) => {
        const entityId = event.target.value;
        if (entityId) {
            entityManager.get(entityId).then((targetEntity) => {
                this.setState({ targetEntity });
            });
        }
        if (this.state.currentAction.callContext.getTargetEntityId() !== entityId) {
            this.handleStoreRecordsControl(DETAIL_STORE_FIELD, null);
        }
        this.updateCallContext(event.target.name, TYPE_ENTITY_TARGET, entityId);
    };

    handleStoreRecordsControl = (name, field) => {
        this.updateDetails(name, field && field.apiName, null, { disableRecordsLimit: false });
        if (field && field.lookupData && !field.lookupData.linking_lookup_data) {
            this.updateDetails(DETAIL_RECORDS_LIMIT, 1, null, { disableRecordsLimit: true });
        }
    };

    handleGroupsChange = (groups) => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                currentAction.groups = groups;
                return { currentAction };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    updateAction = (currentAction) => {
        this.setState({ currentAction }, () => {
            this.props.onModified && this.props.onModified(true);
        });
    };

    getEntityToRender() {
        return this.state.baseEntity;
    }

    canOpenExpressionBuilder = () => {
        let canOpen = true;
        const errors = cloneDeep(this.state.errors);
        if (!this.state.currentAction.callContext.getTargetEntityId()) {
            canOpen = false;
            errors.set('callContextTarget', t('errors.not_empty'));
        }
        this.setState({ errors });
        return canOpen;
    };

    getFilteredGroups = (type) => {
        const groups = this.state.currentAction.groups || [];
        return groups.filter((g) => g.type === type);
    };

    render() {
        const { currentAction, errors, targetEntity } = this.state;
        const targetEntityError = this.getErrorToRender(targetEntity);

        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.matched_records.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={this.state.currentAction.callContext.isEmpty() && !this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors, {
                            label: t('workflow_actions.form.matched_records.entity'),
                        })}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.matched_records.name')}
                        data-testid="workflow_actions.form.matched_records.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={this.state.currentAction.name || ''}
                        helperText={this.state.errors.get('name') || ''}
                        error={this.state.errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <Grid container>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={9}>
                                <TextField
                                    label={t('workflow_actions.form.matched_records.api_name')}
                                    data-testid="workflow_actions.form.matched_records.api_name"
                                    fullWidth
                                    margin="dense"
                                    name="apiName"
                                    value={this.state.currentAction.apiName || ''}
                                    helperText={this.state.errors.get('apiName') || ''}
                                    error={this.state.errors.has('apiName')}
                                    InputProps={{ disableUnderline: false }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                {this.renderEntitySelect(currentAction, errors, {
                                    label: t('workflow_actions.form.matched_records.entity'),
                                })}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    error={!!targetEntityError || this.state.errors.has('callContextTarget')}
                                >
                                    <InputLabel required margin="dense">
                                        <span style={{ marginRight: 5 }}>
                                            {t('workflow_actions.form.matched_records.target_entity')}
                                        </span>
                                        <Hint type="tooltip">
                                            {t('workflow_actions.form.matched_records.target_entity.hint')}
                                        </Hint>
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        name="callContextTarget"
                                        value={this.state.currentAction.callContext.getTargetEntityId() || ''}
                                        onChange={this.handleTargetEntityChange}
                                        renderValue={() => this.renderEntityValue(targetEntity, targetEntityError)}
                                        data-testid="workflow_actions.form.matched_records.target_entity"
                                    >
                                        {this.prepareEntityOptions()}
                                    </Select>
                                    {this.state.errors.get('callContextTarget') && (
                                        <FormHelperText>{this.state.errors.get('callContextTarget')}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    label={
                                        <>
                                            <span style={{ marginRight: 5 }}>
                                                {t('workflow_actions.form.matched_records.records_number')}
                                            </span>
                                            <Hint type="tooltip">
                                                {t('workflow_actions.form.matched_records.records_number.hint')}
                                            </Hint>
                                        </>
                                    }
                                    data-testid="workflow_actions.form.matched_records.records_number"
                                    fullWidth
                                    margin="dense"
                                    disabled={this.state.disableRecordsLimit}
                                    name={DETAIL_RECORDS_LIMIT}
                                    value={getDetail(DETAIL_RECORDS_LIMIT, this.state.currentAction.details, '')}
                                    helperText={this.state.errors.get(DETAIL_RECORDS_LIMIT) || ''}
                                    error={this.state.errors.has(DETAIL_RECORDS_LIMIT)}
                                    InputProps={{ disableUnderline: false, type: 'number' }}
                                    onChange={this.handleDetailsChange}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <StoreRecordsControl
                                    accountId={this.props.account.id}
                                    baseEntity={this.state.baseEntity}
                                    targetEntity={this.state.targetEntity}
                                    action={this.state.currentAction}
                                    onCallContextChange={this.updateCallContext}
                                    onChange={this.handleStoreRecordsControl}
                                    errors={this.state.errors}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <CriteriaControl
                        action={this.state.currentAction}
                        onChange={this.updateAction}
                        canOpenExpressionBuilder={this.canOpenExpressionBuilder}
                        errors={this.state.errors}
                    />

                    <WorkflowGroups
                        title={t('workflow_actions.form.matched_record_actions.title')}
                        hint={t('workflow_actions.form.matched_record_actions.hint')}
                        placeholder={t('workflow_actions.form.matched_record_actions.placeholder')}
                        parentNamespace={this.state.currentAction.namespace}
                        groupType={TYPE_MATCH_ACTION_GROUP}
                        groups={this.state.currentAction.groups || []}
                        errors={this.state.errors}
                        onChange={this.handleGroupsChange}
                        account={this.props.account}
                        dataSources={this.props.dataSources}
                        callContext={this.state.currentAction.callContext}
                        excludedActions={[TYPE_COMMIT]}
                    />
                    <WorkflowGroups
                        title={t('workflow_actions.form.match_session_actions.title')}
                        hint={t('workflow_actions.form.match_session_actions.hint')}
                        placeholder={t('workflow_actions.form.match_session_actions.placeholder')}
                        parentNamespace={this.state.currentAction.namespace}
                        groupType={TYPE_DEFAULT_ACTION_GROUP}
                        groups={this.state.currentAction.groups || []}
                        errors={this.state.errors}
                        onChange={this.handleGroupsChange}
                        account={this.props.account}
                        dataSources={this.props.dataSources}
                        callContext={this.state.currentAction.callContext.filter([
                            TYPE_ENTITY_TARGET,
                            TYPE_ENTITY_LINK,
                        ])}
                        excludedActions={[TYPE_COMMIT]}
                    />
                </form>
            </React.Fragment>
        );
    }
}

Form.propTypes = {
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    account: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export { Form as MatchRecordsForm };
