import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Subscription } from '../../../interfaces/account';
import { WithSnackbarProps } from 'notistack';
import Hint from '../../Hint';
import { formatWithCommas } from '../../../utils';

interface RoutingCreditsHintProps extends WithTranslation, WithSnackbarProps {
    subscription: Subscription;
    totalCredits: number;
}

class RoutingCreditsHint extends React.PureComponent<RoutingCreditsHintProps> {
    makeTextTooltip = () => {
        const { subscription, totalCredits, t } = this.props;
        const creditsInPlan = subscription.plan.routingCredits;
        const additionalCredits = subscription.additionalRoutingCredits;

        return (
            <React.Fragment>
                {t('billing.subscription_panel.tooltip_routing_credits_total', {
                    total: formatWithCommas(totalCredits),
                })}
                <br />
                {t('billing.subscription_panel.tooltip_routing_credits_per_user', {
                    total: formatWithCommas(creditsInPlan),
                    users: subscription.usersAllowed,
                })}
                <br />
                {subscription.additionalRoutingCredits > 0 &&
                    t('billing.subscription_panel.tooltip_routing_credits_additional', {
                        total: formatWithCommas(additionalCredits),
                    })}
                {subscription.additionalCustomRoutingCredits > 0 &&
                    t('billing.subscription_panel.tooltip_routing_credits_custom', {
                        total: formatWithCommas(subscription.additionalCustomRoutingCredits),
                    })}
            </React.Fragment>
        );
    };

    render() {
        return (
            <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                {' '}
                {this.makeTextTooltip()}
            </Hint>
        );
    }
}

export default withTranslation()(RoutingCreditsHint);
