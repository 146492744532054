import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import i18n from '../../locales/i18n';
import { MultipleSelect } from '../Controls';
import {
    DATETIME_FORMAT_FOR_EXPORT,
    currentUserDate,
    formatDateForExport,
    formatDateTimeForPicker,
    formatDateToDefault,
    userTimezoneToUtc,
    filterInputCharactersStringToMatchType,
    formatDateForPicker,
} from '../../utils';
import PropTypes from 'prop-types';
import { userManager } from 'service/UserManager';
import SinglePicklistConstantInput from './SinglePicklistConstantInput';
import LookupConstantInput from './LookupConstantInput';
import LookupPolymorphicConstantInput from './LookupPolymorphicConstantInput';
import { FieldLookupType } from 'components/types';

const t = i18n.t.bind(i18n);

class ConstantInput extends React.PureComponent {
    handleChange = (value) => {
        this.props.onChange(value);
    };

    handleShowSearch = () => {
        this.props.onShowSearch();
    };

    onChangeLookupPolymorphicEntity = (entityApiName) => {
        this.props.onChange({ apiName: entityApiName });
    };

    static getDefaultValue(type) {
        switch (type) {
            case 'integer':
            case 'bigint':
            case 'float':
                return 0;
            case 'string':
            case 'text':
                return '';
            case 'date':
                return currentUserDate(userManager.getCurrentUser()).format(DATETIME_FORMAT_FOR_EXPORT);
            case 'datetime':
                return formatDateTimeForPicker();
            case 'text[]':
                return [];
            case 'boolean':
                return false;
            default:
                return '';
        }
    }

    render() {
        const { field, error } = this.props;

        let value = this.props.value;
        if (value === null) {
            value = this.constructor.getDefaultValue(field.type);
            this.handleChange(value);
        }

        if (field.picklist) {
            const picklist = [...field.picklist];
            if (!!value && typeof value === 'string' && !picklist.find((i) => i.value === value)) {
                picklist.push({
                    label: value,
                    value,
                });
            }
            if (field.type === 'integer[]' || field.type === 'text[]') {
                return <MultiplePicklistConstantInput picklist={picklist} value={value} onChange={this.handleChange} />;
            } else {
                return (
                    <SinglePicklistConstantInput picklist={picklist} value={value} onChangeSelect={this.handleChange} />
                );
            }
        }

        if (field.type === 'date') {
            return <DateConstantInput value={value} error={error} onChange={this.handleChange} />;
        }

        if (field.type === 'datetime') {
            return <DateTimeConstantInput value={value} error={error} onChange={this.handleChange} />;
        }

        if (field.type === 'boolean') {
            return (
                <BooleanConstantInput
                    value={value}
                    error={error}
                    onChange={this.handleChange}
                    disabled={field === null}
                    type={field.type}
                />
            );
        }

        if (field.lookupData !== null && field.lookupData.type !== FieldLookupType.FEW) {
            return this.renderLookupConstantInput(field.lookupData);
        }

        if (field.type === 'text[]') {
            return (
                <MultilineConstantInput
                    value={value}
                    error={error}
                    onChange={this.handleChange}
                    disabled={field === null}
                    type={field.type}
                />
            );
        }

        return (
            <CommonConstantInput
                value={value}
                error={error}
                onChange={this.handleChange}
                disabled={field === null}
                type={field.type}
            />
        );
    }

    renderLookupConstantInput(lookupData) {
        const { value, onShowSearch } = this.props;

        if (lookupData.type === FieldLookupType.POLYMORPHIC) {
            return (
                <LookupPolymorphicConstantInput
                    value={value}
                    lookupData={lookupData}
                    onShowSearch={onShowSearch}
                    onChangeEntity={this.onChangeLookupPolymorphicEntity}
                />
            );
        }

        return <LookupConstantInput value={value} onShowSearch={this.handleShowSearch} />;
    }
}

ConstantInput.propTypes = {
    onShowSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool, PropTypes.object]),
};

const DateConstantInput = (props) => {
    const { value, disabled, onChange, error } = props;

    const handleValueChange = (dateTime) => {
        const dateTimeUTC = formatDateForExport(dateTime);
        onChange(dateTimeUTC);
    };

    const pickerValue = formatDateForPicker(value);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                error={!!error}
                margin="none"
                value={pickerValue}
                onChange={handleValueChange}
                style={{ width: '100%' }}
                disabled={disabled}
                TextFieldComponent={TextField}
                helperText={error || ''}
            />
        </MuiPickersUtilsProvider>
    );
};

const DateTimeConstantInput = (props) => {
    const { value, disabled, onChange, error } = props;

    const handleValueChange = (dateTime) => {
        const dateTimeUTC = userTimezoneToUtc(
            formatDateToDefault(dateTime),
            userManager.getCurrentUser(),
            DATETIME_FORMAT_FOR_EXPORT,
        );
        onChange(dateTimeUTC);
    };

    const pickerValue = formatDateTimeForPicker(value, userManager.getCurrentUser());

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                error={!!error}
                margin="none"
                value={pickerValue}
                onChange={handleValueChange}
                disabled={disabled}
                TextFieldComponent={TextField}
                helperText={error || ''}
                style={{ width: '100%' }}
            />
        </MuiPickersUtilsProvider>
    );
};

const CommonConstantInput = (props) => {
    const { value, error, disabled, type, onChange } = props;
    const handleValueChange = (event) => {
        onChange(filterInputCharactersStringToMatchType(event.currentTarget.value, type, value));
    };

    return (
        <FormControl error={!!error} fullWidth>
            <TextField error={!!error} value={value} onChange={handleValueChange} disabled={disabled} margin="none" />

            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

const MultilineConstantInput = (props) => {
    const { value, error, disabled, type, onChange } = props;
    const handleValueChange = (event) => {
        let newValue = event.currentTarget.value;
        if (newValue) {
            newValue = newValue.split('\n');
        } else {
            newValue = [];
        }
        onChange(newValue, type, value);
    };
    return (
        <FormControl error={!!error} fullWidth>
            <TextField
                error={!!error}
                value={value.join('\n')}
                onChange={handleValueChange}
                disabled={disabled}
                margin="none"
                multiline
            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

const BooleanConstantInput = (props) => {
    const { value, error, disabled, onChange } = props;
    const handleValueChange = (event) => {
        onChange(event.currentTarget.checked);
    };

    return (
        <FormControl error={!!error} fullWidth>
            <div style={{ marginLeft: '-9px' }}>
                <Checkbox checked={value} onChange={handleValueChange} disabled={disabled} color="primary" />
            </div>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

class MultiplePicklistConstantInput extends React.PureComponent {
    handleRenderSelected = (selected) => {
        const names = [];
        for (let item of this.props.picklist) {
            if (selected.indexOf(item.value) > -1) {
                names.push(item.label);
            }
        }

        return t('expresion_builder.value_render', { count: names.length }) + ': ' + names.join('; ');
    };

    handleChange = (e) => {
        const value = e.target.value;
        this.props.onChange(value);
    };

    render() {
        const { picklist } = this.props;
        const value = Array.isArray(this.props.value) ? this.props.value : [];
        return (
            <FormControl fullWidth>
                <MultipleSelect
                    displayEmpty
                    value={value}
                    onChange={this.handleChange}
                    renderValue={this.handleRenderSelected}
                    data-testid="expresion_builder.multiple_select"
                >
                    {picklist.map((item) => {
                        const checked = value.indexOf(item.value) > -1;
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                <Checkbox checked={checked} color="primary" />
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </MultipleSelect>
            </FormControl>
        );
    }
}

export default ConstantInput;
