import { appointmentConfig, departingDateTimeService, routingSettingsMappingHelper } from 'service/MapPage';
import { UserData } from 'service/types';
import { Routing } from 'interfaces';

class AppointmentService {
    async updateFromUserRoutingPreferences(user: UserData) {
        const { start, end } = appointmentConfig.myLocations;

        const isStartAtHome = start.buttonType === Routing.Route.MyLocationType.Home;
        const isEndAtFinish = end.buttonType === Routing.Route.MyLocationType.Finish;

        if (isStartAtHome || isEndAtFinish) {
            const settingsData = await routingSettingsMappingHelper.requestUserSettingsData(user.id);
            if (isStartAtHome) {
                const startLocation = routingSettingsMappingHelper.getUserStartLocation(settingsData);
                appointmentConfig.setMyStartLocation({ ...start, point: startLocation });
            }
            if (isEndAtFinish) {
                const endPoint = routingSettingsMappingHelper.getUserFinishLocation(settingsData);
                appointmentConfig.setMyEndLocation({ ...end, point: endPoint });
            }
        }
        const calendarData = departingDateTimeService.refreshCalendarData(appointmentConfig.departingCalendarData);
        appointmentConfig.setCalendarData(calendarData);
    }
}

export default AppointmentService;
