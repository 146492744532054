import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormDialog from '../FormDialog';
import WorkflowRuleForm from './WorkflowRulesForm';
import { FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import { userManager } from '../../service/UserManager';
import NamespaceBadge from '../Workflow/NamespaceBadge';
import LandingLink, { ARTICLE_WORKFLOW } from '../HelpLink/LandingLink';

class WorkflowRuleModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModified: false,
            showCloseConfirmation: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rule !== this.props.rule) {
            this.setState({ isModified: false });
        }
    }

    close = () => {
        if (userManager.automationElementsManagement() && this.state.isModified) {
            this.setCloseConfirmation(true);
        } else {
            this.props.onCancel();
        }
    };

    setCloseConfirmation = (showCloseConfirmation) => {
        this.setState({ showCloseConfirmation });
    };

    onModified = (isModified) => {
        this.setState({ isModified });
    };

    getModalTitle = () => {
        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item container xs spacing={1} alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <span>{this.props.t('workflow_rules.form.title')}</span>
                            <NamespaceBadge
                                tooltipMessage={this.props.t('workflow_rules.namespace.tooltip', {
                                    namespace: this.props.rule.namespace,
                                })}
                            >
                                {this.props.rule.namespace}
                            </NamespaceBadge>
                        </Grid>
                    </Grid>
                    {this.props.onToggleActive && (
                        <Grid item>
                            <Tooltip
                                title={
                                    this.props.rule.disabled
                                        ? this.props.t('automation_elements.workflow_disabled')
                                        : ''
                                }
                            >
                                <FormControlLabel
                                    className=""
                                    control={
                                        <Switch
                                            checked={this.props.rule.isActive}
                                            onChange={this.props.onToggleActive.bind(this, this.props.rule)}
                                            disabled={
                                                this.props.rule.disabled || !userManager.automationElementsManagement()
                                            }
                                            color="primary"
                                            data-testid="workflow_rules.toggle_rule_disabled"
                                        />
                                    }
                                    label=""
                                />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <LandingLink useLeadingIcon useTrailingIcon article={ARTICLE_WORKFLOW} style={{ fontSize: 16 }}>
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.props.rule !== null && (
                    <FormDialog
                        title={this.getModalTitle()}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        saveButtonDisabled={!userManager.automationElementsManagement()}
                    >
                        <WorkflowRuleForm
                            account={this.props.account}
                            rule={this.props.rule}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}

                <DialogConfirmation
                    open={this.state.showCloseConfirmation}
                    setOpen={this.setCloseConfirmation}
                    onConfirm={this.props.onCancel}
                    titleText={this.props.t('automation_elements.close.confirmation')}
                    yesBtnText={this.props.t('automation_elements.close.confirmation.yes')}
                    noBtnText={this.props.t('automation_elements.close.confirmation.no')}
                />
            </React.Fragment>
        );
    }
}

WorkflowRuleModal.propTypes = {
    account: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onToggleActive: PropTypes.func,
    rule: PropTypes.object,
};

export default withTranslation()(WorkflowRuleModal);
