export function textEllipsis(str: string | undefined, maxLength: number, { side = 'end', ellipsis = '...' } = {}) {
    if (!str) {
        return '';
    }

    if (str.length > maxLength) {
        switch (side) {
            case 'start':
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case 'end':
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}

export function toSnakeCase(str: string): string {
    str = str.replace(/^[A-Z]/, (letter) => letter.toLowerCase());
    return str.replace(/[A-Z]/g, (letter) => '_' + letter.toLowerCase());
}
