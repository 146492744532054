import { Icon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import backendApi from '../../api/BackendApi';
import { reverse, routes } from '../../routes';
import Backdrop from '../Backdrop';
import MainMenu from '../MainMenu';
import AnalyticsIframe from './AnalyticsIframe';

interface AnalyticsProps extends WithTranslation {
    baseUrl: string;
}

interface AnalyticsState {
    isJwtReady: boolean;
    loading: boolean;
}

class Analytics extends PureComponent<AnalyticsProps, AnalyticsState> {
    constructor(props: AnalyticsProps) {
        super(props);

        this.state = {
            loading: true,
            isJwtReady: false,
        };
    }

    componentDidMount() {
        backendApi.enableShareJwtToken();
        backendApi.shareJwt().finally(() => {
            this.setState({ isJwtReady: true });
        });
    }

    componentWillUnmount() {
        backendApi.disableShareJwtToken();
    }

    private handleLoaded = () => {
        this.setState({
            loading: false,
        });
    };

    render() {
        const { loading, isJwtReady } = this.state;

        return (
            <div className="analytics-page-container">
                <div className="analytics-back-to-map back-to-map" data-testid="analytics.back_to_map">
                    <Tooltip title={this.props.t('main_menu.back_to_map')}>
                        <Link to={reverse(routes.client)} style={{ alignItems: 'center' }}>
                            <Icon>chevron_left</Icon>
                            <i className="main_menu__item__icon fa-regular fa-map-location-dot" />
                        </Link>
                    </Tooltip>
                </div>

                <Box display="flex" className="analytics-logo">
                    <div className={'map-controls__logo_panel'}>
                        <MainMenu />
                    </div>
                </Box>

                {isJwtReady && (
                    <Backdrop loading={loading}>
                        <AnalyticsIframe baseUrl={this.props.baseUrl} afterLoad={this.handleLoaded} />
                    </Backdrop>
                )}
            </div>
        );
    }
}

export default withTranslation()(Analytics);
