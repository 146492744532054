import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles, CircularProgress } from '@material-ui/core';
import { TreeItem as BaseTreeItem } from '@material-ui/lab';
import { utcToUserTimezone } from '../../utils';
import { Add, Remove } from '@material-ui/icons';

const TreeItem = withStyles((theme) => ({
    root: {},
    content: {
        position: 'relative',
    },
    label: {
        padding: '5px',
        marginLeft: '30px',
        fontFamily: 'monospace',
        fontSize: '1rem',
    },
    iconContainer: {
        width: '30px',
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        opacity: '0.5',
        '&:hover': {
            opacity: '1',
        },
    },
    group: {
        marginLeft: '15px',
        paddingLeft: '15px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    },
}))(BaseTreeItem);

class ExecutionFlowRecord extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.data);
    };

    handleIconClick = () => {
        if (Array.isArray(this.props.data.children) && this.props.data.children.length > 0) {
            this.props.onExpansion(this.props.data.id);
        } else {
            this.setState({ loading: true });
            this.props.onLogsLoad(this.props.data.id).then(() => {
                this.setState({ loading: false });
            });
        }
    };

    renderResult = () => {
        if (this.props.data.result) {
            return (
                <React.Fragment>
                    <span className="text-muted" style={{ margin: '0 10px' }}>
                        {'=>'}
                    </span>
                    <span>{this.prepareResultString()}</span>
                </React.Fragment>
            );
        }
        return null;
    };

    prepareResultString = () => {
        const { maxResultLength, data } = this.props;
        if (maxResultLength && data.result && data.result.length > maxResultLength) {
            return data.result.substring(0, maxResultLength) + '...';
        }
        return data.result;
    };

    renderLabel = () => {
        return (
            <div>
                <span className="text-muted" style={{ marginRight: '10px' }}>
                    {utcToUserTimezone(this.props.data.startedAt, this.props.user, 'MM/DD HH:mm:ss.SSS')}
                </span>
                {this.props.data.message}
                {this.renderResult()}
            </div>
        );
    };

    renderIcon = () => {
        if (this.props.data.isLeaf === true) {
            return null;
        }
        if (this.state.loading === true) {
            return <CircularProgress size={15} />;
        }
        return this.props.expanded.includes(this.props.data.id.toString()) ? <Remove /> : <Add />;
    };

    render() {
        return (
            <TreeItem
                label={this.renderLabel()}
                nodeId={this.props.data.id.toString()}
                onLabelClick={this.handleClick}
                onIconClick={this.handleIconClick}
                icon={this.renderIcon()}
            >
                {Array.isArray(this.props.data.children) &&
                    this.props.data.children.map((log) => (
                        <ExecutionFlowRecord
                            key={log.id}
                            user={this.props.user}
                            data={log}
                            onClick={this.props.onClick}
                            onExpansion={this.props.onExpansion}
                            expanded={this.props.expanded}
                            onLogsLoad={this.props.onLogsLoad}
                        />
                    ))}
            </TreeItem>
        );
    }
}

ExecutionFlowRecord.propTypes = {
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onExpansion: PropTypes.func.isRequired,
    expanded: PropTypes.arrayOf(PropTypes.string).isRequired,
    onLogsLoad: PropTypes.func.isRequired,
    maxResultLength: PropTypes.number,
};

ExecutionFlowRecord.defaultProps = {
    style: {},
    maxResultLength: 300,
};

export default withTranslation()(ExecutionFlowRecord);
