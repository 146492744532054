import Dexie from 'dexie';
import { DbTerritories } from './DbTerritories';
import { IndexableType } from 'dexie';

export const TERRITORIES_LAYERS_TABLE = 'territories_layers_data';
export const TERRITORIES_GEO_JSON_WITH_UNION_TABLE = 'territories_geo_json_with_union';
export const TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE = 'territories_geo_json_without_union';
export const DB_NAME = 'MAPSLY';
export const MAX_SIZE_CACHE = 500;

/** ONLY INTEGER !!!!!! **/
const CURRENT_VERSION = 4;

export class DbTerritoriesIndexDb implements DbTerritories {
    cache: { [key: string]: any } = {};
    db: Dexie = new Dexie(DB_NAME + CURRENT_VERSION);

    constructor() {
        this.cache = {
            [TERRITORIES_GEO_JSON_WITH_UNION_TABLE]: new Map(),
            [TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE]: new Map(),
        };
    }

    async init(): Promise<void> {
        const dexie = new Dexie(DB_NAME + CURRENT_VERSION);
        this.setVersion(dexie);

        try {
            const db = await dexie.open();
            if (!db.isOpen()) {
                return Promise.reject('Index DB not open');
            }
            this.db = db;
            await this.clearOldVersion();
        } catch (error) {
            dexie.delete();
            this.setVersion(dexie);

            const db = await dexie.open();
            if (!db.isOpen()) {
                return Promise.reject('Index DB not open');
            }
            this.db = db;

            return Promise.resolve();
        }
    }

    setVersion(db: Dexie) {
        db.version(1).stores({
            [TERRITORIES_LAYERS_TABLE]: 'uuid',
            [TERRITORIES_GEO_JSON_WITH_UNION_TABLE]: 'uuid',
            [TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE]: 'uuid',
        });
        db.version(2).stores({
            [TERRITORIES_LAYERS_TABLE]: 'uuid',
            [TERRITORIES_GEO_JSON_WITH_UNION_TABLE]: 'uuid',
            [TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE]: 'uuid',
        });
    }

    clearOldVersion = async () => {
        try {
            const result = await Dexie.exists(DB_NAME + (CURRENT_VERSION - 1));
            if (!result) {
                console.info('IndexDB old version not exists');
            }

            const db = new Dexie(DB_NAME + (CURRENT_VERSION - 1));
            await db.delete();
        } catch (e) {
            console.error('IndexDB version clear fail:' + e);
        }
    };

    getLayersProperties(uuids: IndexableType[]) {
        return this.db.table(TERRITORIES_LAYERS_TABLE).bulkGet(uuids);
    }

    getSimplifySourcesData(uuids: IndexableType[]) {
        return this.loadingGeoJson(uuids, TERRITORIES_GEO_JSON_WITH_UNION_TABLE);
    }

    loadingGeoJson(uuids: IndexableType[], tableName: string) {
        let results: object[] = [];
        let uuidsForIndexDb: IndexableType[] = [];
        uuids.forEach((uuid) => {
            if (this.cache[tableName].has(uuid)) {
                results.push(this.cache[tableName].get(uuid));
            } else {
                uuidsForIndexDb.push(uuid);
            }
        });

        return this.db
            .table(tableName)
            .bulkGet(uuidsForIndexDb)
            .then((newResults) => {
                newResults.forEach((geoGsonData) => {
                    if (geoGsonData === undefined) {
                        return;
                    }
                    this.setToCache(geoGsonData.uuid, geoGsonData, tableName);
                });
                return results.concat(newResults);
            });
    }

    setToCache(uuid: IndexableType[], geoJsonData: object, tableName: string) {
        if (this.cache[tableName].size > MAX_SIZE_CACHE) {
            let newCache = new Map();
            this.cache[tableName].forEach((data: object, key: string) => {
                if (newCache.size < MAX_SIZE_CACHE - 400) {
                    newCache.set(key, data);
                }
            });
            this.cache[tableName] = newCache;
        }
        this.cache[tableName].set(uuid, geoJsonData);
    }

    getFullSourcesData(uuids: IndexableType[]) {
        return this.loadingGeoJson(uuids, TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE);
    }

    setTerritoryLayersProperties(items: any[]) {
        return this.db.transaction('rw', TERRITORIES_LAYERS_TABLE, () =>
            this.db.table(TERRITORIES_LAYERS_TABLE).bulkPut(items),
        );
    }

    setSimplifySourcesData(items: IndexableType[]) {
        this.cache[TERRITORIES_GEO_JSON_WITH_UNION_TABLE] = new Map();
        return this.db.transaction('rw', TERRITORIES_GEO_JSON_WITH_UNION_TABLE, () =>
            this.db.table(TERRITORIES_GEO_JSON_WITH_UNION_TABLE).bulkPut(items),
        );
    }

    setFullSourcesData(items: any[]) {
        this.cache[TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE] = new Map();
        return this.db.transaction('rw', TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE, () =>
            this.db.table(TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE).bulkPut(items),
        );
    }

    clearTerritoriesSourceData() {
        return this.db
            .table(TERRITORIES_GEO_JSON_WITH_UNION_TABLE)
            .clear()
            .then(() => {
                return this.db.table(TERRITORIES_GEO_JSON_WITHOUT_UNION_TABLE).clear();
            });
    }

    clearTerritoriesLayerData() {
        return this.db.table(TERRITORIES_LAYERS_TABLE).clear();
    }

    isConnect(): boolean {
        return this.db.isOpen();
    }

    close(): void {
        if (this.db.isOpen()) {
            this.db.close();
            console.info('Close Index DB for Territories');
        }
    }
}
