import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    TextField,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { SingleRowMultipleAutocomplete } from '../SingleRowMultipleAutocomplete';
import { ACTION_TYPE_LIST } from '../../service/WorkflowActionManager';

class AddActionsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionGroups: [],
            currentActions: new Map(),
            typeFilter: [],
            nameFilter: null,
        };

        this.debouncedUpdateActions = debounce(this.debouncedUpdateActions, 500);
    }

    componentDidMount() {
        this.updateActions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.allActions.length !== this.props.allActions.length) {
            this.updateActions();
        }
    }

    addAction = (actionId) => {
        this.setState((state) => {
            const { currentActions } = state;
            const action = this.props.allActions.find((a) => a.id === actionId);
            if (!currentActions.has(actionId)) {
                currentActions.set(actionId, action);
            } else {
                currentActions.delete(actionId);
            }
            return { currentActions };
        });
    };

    handleNameFilter = (event) => {
        const nameFilter = event.target.value;
        this.setState({ nameFilter });
        this.debouncedUpdateActions();
    };

    handleTypeFilter = (option) => {
        const typeFilter = option?.map((o) => o.value);
        this.setState({ typeFilter });
        this.debouncedUpdateActions();
    };

    debouncedUpdateActions = () => {
        this.updateActions();
    };

    updateActions = () => {
        const { selectedActions } = this.props;
        const { nameFilter, typeFilter } = this.state;

        const selectedActionsSet = new Set();
        selectedActions && selectedActions.forEach((a) => selectedActionsSet.add(a.id));
        const visibleActions = this.props.allActions.filter((a) => {
            return !selectedActionsSet.has(a.id) && (typeFilter.length === 0 || typeFilter.includes(a.type));
        });

        const actions =
            typeof nameFilter === 'string' && nameFilter.length
                ? visibleActions.filter((a) => a.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1)
                : visibleActions;

        this.setState({ actionGroups: this.groupActions(actions) });
    };

    groupActions = (actions) => {
        const groups = {
            global: [],
        };
        for (const action of actions) {
            if (!Array.isArray(groups[action.namespace])) {
                groups[action.namespace] = [];
            }
            groups[action.namespace].push(action);
        }
        if (groups.global.length === 0) {
            delete groups.global;
        }
        return groups;
    };

    renderActions = () => {
        if (Object.keys(this.state.actionGroups).length === 0) {
            return (
                <div style={{ textAlign: 'center', marginTop: '10px' }} className="text-muted">
                    {this.props.t('workflow_rules.form.actions.empty_list')}
                </div>
            );
        }
        return (
            <form autoComplete="off" noValidate>
                <List>
                    {Object.keys(this.state.actionGroups).map((group) => (
                        <React.Fragment key={group}>
                            <ListSubheader disableGutters disableSticky>
                                {group}
                            </ListSubheader>
                            {this.state.actionGroups[group].map((a) => (
                                <ListItem key={a.id} button onClick={() => this.addAction(a.id)} disableGutters>
                                    <ListItemIcon>
                                        <Checkbox checked={this.state.currentActions.has(a.id)} color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={a.name} />
                                </ListItem>
                            ))}
                        </React.Fragment>
                    ))}
                </List>
            </form>
        );
    };

    renderPicklistFilter = () => {
        return (
            <SingleRowMultipleAutocomplete
                options={ACTION_TYPE_LIST}
                value={ACTION_TYPE_LIST.filter((o) => this.state.typeFilter.includes(o.value)) || []}
                getOptionLabel={(option) => option.label}
                size="small"
                fullWidth
                disableClearable
                onChange={(e, option) => this.handleTypeFilter(option)}
                renderOption={(option) => (
                    <React.Fragment>
                        <Checkbox color="primary" checked={this.state.typeFilter.includes(option.value)} />
                        {option.label}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={this.props.t('workflow_groups.actions_form.filter.type')}
                        data-testid="workflow_groups.actions_form.filter.type"
                        margin="dense"
                    />
                )}
            />
        );
    };

    submit() {
        this.props.onSubmitSuccess(Array.from(this.state.currentActions.values()));
    }

    render() {
        return (
            <div style={{ minHeight: '350px', maxHeight: '350px' }}>
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            name="nameFilter"
                            value={this.state.nameFilter || ''}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleNameFilter}
                            label={this.props.t('workflow_groups.actions_form.filter.name')}
                            data-testid="workflow_groups.actions_form.filter.name"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderPicklistFilter()}
                    </Grid>
                </Grid>

                {this.renderActions()}
            </div>
        );
    }
}

AddActionsForm.propTypes = {
    allActions: PropTypes.array.isRequired,
    selectedActions: PropTypes.array.isRequired,
};

export default withTranslation('translations', { withRef: true })(AddActionsForm);
