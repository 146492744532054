import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { TreeView, TreeItem } from '@material-ui/lab';
import React from 'react';
import { Alert } from '@material-ui/lab';
import { WithTranslation, withTranslation } from 'react-i18next';
import Calculator from '../../service/ImportMessages/Calculator';
import PureFormDialog from '../PureFormDialog';
import './style.css';
import {
    DataSource,
    ImportMessage,
    LEVEL,
    ORIGIN,
    SortedAccountMessages,
    SortedDsMessages,
    SortedEntityMessages,
    SortedFieldMessages,
} from '../../service/ImportMessages/types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { FormActions } from '../PureFormDialog/Form';
import { Account } from 'interfaces';
import copy from 'copy-to-clipboard';
import { Tooltip } from '@material-ui/core';
import MessageText from './MessageText';

interface Props extends WithTranslation, WithStyles {
    subscription?: null | Account.Subscription;
    dataSources: DataSource[];
    level?: LEVEL;
    onClose: () => void;
}

interface State {
    sortedMessages: SortedDsMessages[];
    sortedAccountMessages: SortedAccountMessages;
}

class MessagesViewDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const levels = this.props.level === undefined ? [LEVEL.INFO, LEVEL.WARNING, LEVEL.ERROR] : [this.props.level];
        this.state = {
            sortedAccountMessages: Calculator.calculateAccountStatusMessages(this.props.subscription),
            sortedMessages: Calculator.calculateSortedMessages(this.props.dataSources, levels, this.props.subscription),
        };
    }

    render() {
        const { onClose, t } = this.props;
        const { sortedMessages, sortedAccountMessages } = this.state;
        return (
            <PureFormDialog
                open
                title={t('data_source.import_messages.modal.title').toString()}
                onClose={onClose}
                maxWidth="md"
                fullWidth
            >
                <div className="c-import-messages-dialog-container">
                    <TreeView
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultExpanded={this.calculateMessageNodeIds(sortedMessages)}
                        disableSelection={true}
                        onNodeSelect={() => {}}
                        className="c-import-messages-dialog-tree-container"
                    >
                        {sortedAccountMessages.messages.map(this.renderMessage)}
                        {sortedMessages.map(this.renderDataSource)}
                    </TreeView>
                </div>
                <FormActions />
            </PureFormDialog>
        );
    }

    renderDataSource = (dsMessages: SortedDsMessages) => {
        const { t } = this.props;
        return (
            <TreeItem
                key={this.calculateDsNodeId(dsMessages)}
                nodeId={this.calculateDsNodeId(dsMessages)}
                label={
                    <span>
                        {dsMessages.ds.label}
                        <span className="c-import-messages-dialog-element-label">
                            {t('data_source.import_messages.modal.data_source_label')}
                        </span>
                    </span>
                }
            >
                {dsMessages.messages.map(this.renderMessage)}
                {dsMessages.entities.map(this.renderEntity)}
            </TreeItem>
        );
    };

    renderEntity = (entityMessages: SortedEntityMessages) => {
        const { t } = this.props;
        return (
            <TreeItem
                key={this.calculateEntityNodeId(entityMessages)}
                nodeId={this.calculateEntityNodeId(entityMessages)}
                label={
                    <span>
                        {entityMessages.entity.label}
                        <span className="c-import-messages-dialog-element-label">
                            {t('data_source.import_messages.modal.entity_label')}
                        </span>
                    </span>
                }
            >
                {entityMessages.messages.map(this.renderMessage)}
                {entityMessages.fields.map(this.renderField)}
            </TreeItem>
        );
    };

    renderField = (fieldMessages: SortedFieldMessages) => {
        const { t } = this.props;
        return (
            <TreeItem
                key={this.calculateFieldNodeId(fieldMessages)}
                nodeId={this.calculateFieldNodeId(fieldMessages)}
                label={
                    <span>
                        {fieldMessages.field.label}
                        <span className="c-import-messages-dialog-element-label">
                            {t('data_source.import_messages.modal.field_label')}
                        </span>
                    </span>
                }
            >
                {fieldMessages.messages.map(this.renderMessage)}
            </TreeItem>
        );
    };

    renderMessage = (message: ImportMessage) => {
        const { t } = this.props;
        const messageText =
            message.origin === ORIGIN.INTERNAL ? message.message : '[' + message.httpCode + '] ' + message.message;

        return (
            <TreeItem
                key={this.calculateMessageNodeId(message)}
                nodeId={this.calculateMessageNodeId(message)}
                label={
                    <Tooltip title={t('data_source.import_messages.tooltip.copy_to_clipboard') as string}>
                        <Alert
                            severity={message.level}
                            className="c-import-messages-dialog-element-alert"
                            onClick={() => copy(messageText)}
                            style={{ cursor: 'pointer' }}
                        >
                            <MessageText text={messageText} />
                        </Alert>
                    </Tooltip>
                }
                classes={this.props.classes}
            />
        );
    };

    calculateDsNodeId = (dsMessages: SortedDsMessages): string => {
        return 'ds-' + dsMessages.ds.id.toString();
    };

    calculateEntityNodeId = (entityMessages: SortedEntityMessages): string => {
        return 'entity-' + entityMessages.entity.id.toString();
    };

    calculateFieldNodeId = (fieldMessages: SortedFieldMessages): string => {
        return 'field-' + fieldMessages.field.id.toString();
    };

    calculateMessageNodeId = (message: ImportMessage): string => {
        return 'message-' + message.id.toString();
    };

    calculateMessageNodeIds = (sortedDsMessages: SortedDsMessages[]): string[] => {
        const result: string[] = [];
        for (let sortedDsMessage of sortedDsMessages) {
            result.push(this.calculateDsNodeId(sortedDsMessage));
            for (let sortedEntityMessage of sortedDsMessage.entities) {
                result.push(this.calculateEntityNodeId(sortedEntityMessage));
                for (let sortedFieldMessage of sortedEntityMessage.fields) {
                    result.push(this.calculateFieldNodeId(sortedFieldMessage));
                }
            }
        }
        return result;
    };
}

const styles = {
    iconContainer: {
        width: 0,
    },
};

export default withTranslation('translations', { withRef: true })(withStyles(styles)(MessagesViewDialog));
