import { Box, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import React, { useState } from 'react';

interface Props {
    name: string;
    list: string;
    onDelete: () => void;
}
const CalendarsTabPanelUserGroupItem: React.FC<Props> = ({ name, list, onDelete }) => {
    const [isDeleting, setDeleting] = useState(false);
    const handleDelete = () => {
        if (isDeleting) return;
        onDelete();
        setDeleting(true);
    };
    return (
        <Box display="grid" marginY="20px" gridGap="20px" gridTemplateColumns={'1fr 2fr'}>
            <Typography>{name}</Typography>
            <Box display={'flex'} justifyContent={'space-between'} gridGap={'10px'}>
                <Typography>{list}</Typography>
                <i
                    style={{
                        fontSize: '16px',
                        display: 'inline-block',
                        width: '16px',
                        height: '16px',
                        color: isDeleting ? grey[500] : red[500],
                        cursor: 'pointer',
                    }}
                    className="fa fa-trash"
                    onClick={handleDelete}
                />
            </Box>
        </Box>
    );
};

export default CalendarsTabPanelUserGroupItem;
