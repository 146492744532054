import React from 'react';
import WebLink from '../../WebLink/WebLink';

class WebLinkFormatter extends React.PureComponent {
    render() {
        const { value } = this.props;
        return <WebLink webLink={value} />;
    }
}

export default WebLinkFormatter;
