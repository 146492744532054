import { Deploy } from 'cordova-plugin-ionic';
import EnqueueSnackbarService from '../MapPage/EnqueueSnackbarService';
import i18n from '../../locales/i18n';
import { TFunction } from 'react-i18next';
import { App as AppPlugin } from '@capacitor/app';
import { logDebug, weAreInNativeApp } from '../../utils';
import dispatcher from '../dispatcher';
import events from '../../events';
import { STORAGE_KEY_PREFIX } from '../../components/types';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AppUpdateAvailability } from '@capawesome/capacitor-app-update/dist/esm/definitions';
import firebase, { Events } from '../../service/Firebase';
import { ISnapshotInfo } from 'cordova-plugin-ionic/dist/IonicCordova';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import BgGeoManager from '../BgGeo/BgGeoManager';

declare const Ionic: any;
declare const Capacitor: any;

const DEFAULT_VERSION_ID = 'mapsly_default_version';

const EXT_REGEX = /\.(jpg|png|gif|bmp|js|html|css|ttf|woff2|svg)$/;

const LOG_PREFIX = '[app update]';

class AppUpdateManager {
    private updating = false;
    private enqueueSnackbarService: EnqueueSnackbarService;
    private readonly t: TFunction;

    constructor() {
        this.enqueueSnackbarService = new EnqueueSnackbarService();
        this.t = i18n.t.bind(i18n);
        if (!weAreInNativeApp()) {
            return;
        }

        this.checkForUpdate();

        // check on returning to foreground
        AppPlugin.addListener('appStateChange', async (state) => {
            if (state.isActive) {
                logDebug('Run app version update check on return from background');
                await this.checkForUpdate();
            }
        });
    }

    async openAppStore(): Promise<void> {
        await AppUpdate.openAppStore();
    }

    async checkAllFiles(): Promise<boolean> {
        const manifestResp = await fetch('/pro-manifest.json');
        const bundledManifest = await manifestResp.json();
        let isValid = true;

        const FileNotFound = async (entity: any) => {
            await firebase.analytics.sendEvent({
                name: Events.FilesFromBuildUnValid,
                params: { file: entity.href },
            });
        };

        for (let entity of bundledManifest) {
            if (!entity.href.match(EXT_REGEX)) {
                continue;
            }
            try {
                const response = await fetch(entity.href);
                if (!response.ok) {
                    isValid = false;
                    await FileNotFound(entity);
                    break;
                }
            } catch (e) {
                isValid = false;
                await FileNotFound(entity);
                break;
            }
        }
        return Promise.resolve(isValid);
    }

    /**
     * The method checks for all files from the manifest. If at least one file is missing,
     * than the current version number is overwritten to undefined in the ionic settings  which,
     * when checking for updates, leads to a re-download of the build
     */
    async checkValidCurrentVersion() {
        if (this.updating) {
            return;
        }
        let id = await Deploy.getCurrentVersion();

        if (!id) {
            return;
        }

        let isValidCurrentState = await this.checkAllFiles();
        if (isValidCurrentState) {
            return;
        }
        console.log('Current version not valid');
        await this.resetCurrentVersion();
    }

    /**
     * Reset current version id and restart app
     */
    async resetCurrentVersion() {
        await BackgroundGeolocation.startBackgroundTask().then(async (taskId: number) => {
            try {
                BgGeoManager.log('Start background reset current version, taskId ' + taskId, LOG_PREFIX);
                let props = await this.getIonicPreferences();
                let currentVersion = await Deploy.getCurrentVersion();

                if (!currentVersion || currentVersion.versionId === DEFAULT_VERSION_ID) {
                    return;
                }

                const newPath = await this.createNewPath(currentVersion);
                await this.createDefaultVersionFolder(newPath);
                // @ts-ignore
                props.currentVersionId = DEFAULT_VERSION_ID;
                // @ts-ignore
                props.availableUpdate = undefined;
                // @ts-ignore
                props.updates[DEFAULT_VERSION_ID] = {
                    // @ts-ignore
                    binaryVersionCode: props.binaryVersionCode,
                    // @ts-ignore
                    binaryVersionName: props.binaryVersionName,
                    // @ts-ignore
                    channel: props.channel,
                    state: 'ready',
                    lastUsed: new Date().toISOString(),
                    url: 'https://',
                    versionId: DEFAULT_VERSION_ID,
                    buildId: 0,
                };

                await this.saveIonicPreferences(props);
                Ionic.WebView.setServerBasePath(newPath);

                await firebase.analytics.sendEvent({ name: Events.ResetCurrentVersionId });
                BgGeoManager.log('End background reset current version, taskId ' + taskId, LOG_PREFIX);
                await BackgroundGeolocation.stopBackgroundTask(taskId);
                document.location.reload();
            } catch (e) {
                await BackgroundGeolocation.stopBackgroundTask(taskId);
                BgGeoManager.log('Fail background reset current version, taskId ' + taskId, LOG_PREFIX);
            }
        });
    }

    async createNewPath(currentVersion: ISnapshotInfo): Promise<string> {
        let currentPath: string = await this.getServerBasePath();
        return currentPath.replace(currentVersion.versionId, DEFAULT_VERSION_ID);
    }

    async createDefaultVersionFolder(path: string) {
        await Deploy.deleteVersionById(DEFAULT_VERSION_ID).catch(() => {
            console.warn('couldn’t remove build ' + DEFAULT_VERSION_ID);
        });

        await this.copyTo({
            source: {
                path: this.getBundledAppDir(),
                directory: 'APPLICATION',
            },
            target: path,
        });
    }

    async checkForUpdate(forceShowModal = false): Promise<void> {
        if (!weAreInNativeApp()) {
            return;
        }

        await this.checkValidCurrentVersion().catch((e) => {
            console.error('Check valid current version end with error:', e);
        });

        let updateInfo = null;
        try {
            updateInfo = await AppUpdate.getAppUpdateInfo();
        } catch (e) {
            logDebug('Error getting app update info: ' + e);
        }

        const isAppBuildUpdateExists =
            updateInfo && updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;

        const lastModalShown = this.getLastModalShown();
        const now = new Date();
        const modalShownToday =
            lastModalShown &&
            lastModalShown.getDate() === now.getDate() &&
            lastModalShown.getMonth() === now.getMonth() &&
            lastModalShown.getFullYear() === now.getFullYear();

        if (isAppBuildUpdateExists && (!modalShownToday || forceShowModal)) {
            this.showUpdateModal();
            return;
        }

        if (!isAppBuildUpdateExists) {
            const appFlowUpdate = await Deploy.checkForUpdate();
            if (appFlowUpdate.available) {
                await this.updateAppFlow();
            }
        }
    }

    private async updateAppFlow(): Promise<void> {
        if (this.updating) {
            return;
        }
        this.updating = true;
        await BackgroundGeolocation.startBackgroundTask().then(async (taskId: number) => {
            BgGeoManager.log('Start background update App, taskId ' + taskId, LOG_PREFIX);
            try {
                this.enqueueSnackbarService.sendCustomMessage(this.t('mobile_app.update.downloading'), {
                    persist: true,
                    preventDuplicate: true,
                    variant: 'info',
                });

                await Deploy.downloadUpdate((progress) => {
                    BgGeoManager.log('Downloading update: ' + progress + '%', LOG_PREFIX);
                });

                await Deploy.extractUpdate((progress) => {
                    BgGeoManager.log('Extracting update: ' + progress + '%', LOG_PREFIX);
                });

                this.enqueueSnackbarService.sendCustomMessage(this.t('mobile_app.update.reloading'), {
                    persist: true,
                    preventDuplicate: true,
                    variant: 'success',
                });

                await Deploy.reloadApp();

                await BackgroundGeolocation.stopBackgroundTask(taskId);
                BgGeoManager.log('Stop background update App, taskId ' + taskId, LOG_PREFIX);

                this.updating = false;
                await this.checkValidCurrentVersion();

                setTimeout(() => {
                    try {
                        document.location.reload();
                    } catch (e) {
                        BgGeoManager.log('Reload application ended with error; error:' + e, LOG_PREFIX);
                    }
                }, 2000);
            } catch (e) {
                this.updating = false;
                BgGeoManager.log('Fail background update App, taskId ' + taskId + '; error:' + e, LOG_PREFIX);
                await firebase.analytics.sendEvent({ name: Events.UpdateBuildFail, params: e });
                await BackgroundGeolocation.stopBackgroundTask(taskId);
            }
        });
    }

    private showUpdateModal(): void {
        const lastModalShown = new Date();
        localStorage.setItem(STORAGE_KEY_PREFIX.UPDATE_MODAL_LAST_SHOWN_DATE, lastModalShown.toString());
        dispatcher.dispatch(events.SHOW_APP_UPDATE_MODAL);
    }

    private getLastModalShown(): Date | null {
        const lastModalShown = localStorage.getItem(STORAGE_KEY_PREFIX.UPDATE_MODAL_LAST_SHOWN_DATE);
        if (lastModalShown) {
            return new Date(lastModalShown);
        }
        return null;
    }

    private async saveIonicPreferences(prefs: any) {
        return new Promise(async (resolve, reject) => {
            try {
                cordova.exec(
                    async (savedPrefs) => {
                        resolve(savedPrefs);
                    },
                    reject,
                    'IonicCordovaCommon',
                    'setPreferences',
                    [prefs],
                );
            } catch (e) {
                reject(e.message);
            }
        });
    }

    private async getIonicPreferences() {
        return new Promise(async (resolve, reject) => {
            cordova.exec(
                async (prefs) => {
                    resolve(prefs);
                },
                reject,
                'IonicCordovaCommon',
                'getPreferences',
            );
        });
    }

    private async copyTo(options: { source: { directory: string; path: string }; target: string }) {
        return new Promise<void>((resolve, reject) => {
            cordova.exec(resolve, reject, 'IonicCordovaCommon', 'copyTo', [options]);
        });
    }

    private async getServerBasePath(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                Ionic.WebView.getServerBasePath(resolve);
            } catch (e) {
                reject(e);
            }
        });
    }

    private getBundledAppDir(): string {
        let folder = 'www';
        if (typeof Capacitor !== 'undefined') {
            folder = 'public';
        }
        return folder;
    }
}

const appUpdateManager = new AppUpdateManager();
export default appUpdateManager;
