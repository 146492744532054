import React, { Suspense } from 'react';
import { fileManager } from '../../service/File/FileManager';
import { weAreInNativeApp } from '../../utils';
import { routes } from '../../routes';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const FileViewer = React.lazy(() => import(/* webpackChunkName: "C-NativeApp-FileViewer" */ '../NativeApp/FileViewer'));

interface CurrentProps extends WithSnackbarProps {
    accountId: number;
    fileId: string;
}

interface CurrentState {
    redirected: boolean;
}

class FileRedirector extends React.Component<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            redirected: false, // Flag to prevent multiple redirects to the same url after component re-rendering
        };
    }

    render() {
        if (weAreInNativeApp()) {
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <FileViewer
                        fileId={this.props.fileId}
                        onClose={() => {
                            window.location.href = routes.client;
                        }}
                    />
                </Suspense>
            );
        }

        if (!this.state.redirected) {
            this.setState({ redirected: true }, () => {
                fileManager
                    .get(this.props.accountId, this.props.fileId)
                    .then((file) => {
                        file.url && window.location.replace(file.url);
                    })
                    .catch((error) => {
                        this.props.enqueueSnackbar(error.message, { variant: 'error' });
                    });
            });
        }
        return null;
    }
}

export default withSnackbar(FileRedirector);
