import {
    BreakTypeEnum,
    DataSourceEntityCounter,
    DataSourceEntityCounterEntityField,
    DataSourceSettings,
} from 'service/types';
import { LatLngTuple } from 'leaflet';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FieldFactoryValueObject } from './LiveUpdateTableForm/FieldFactory/FieldFactory';
import { Common, DataSource, Geo, Routing } from 'interfaces';

export interface Bounds {
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
}

export enum GeocoderResultStatus {
    OK = 'ok',
    OK_WITH_VIOLATIONS = 'ok_with_violations',
    IN_PROGRESS = 'in_progress',
    NO_RESULT = 'no_result',
    ERROR_SERVICE = 'error_service',
    ERROR_TIMEOUT = 'error_timeout',
    ERROR_OTHER = 'error_other',
    ERROR_RATE_LIMIT_EXCEEDED = 'error_rate_limit',
    ERROR_CONTINUOUS_MODE = 'error_continuous_mode',
    NO_FALLBACK = 'error_no_fallback',
    NO_PROXY = 'error_no_proxy',
}

export class GeocoderApiCustomError extends Error {}

export interface MyLocations {
    start: Routing.Route.MyLocation;
    end: Routing.Route.MyLocation;
    errors: {
        start: boolean;
        end: boolean;
    };
}

export interface MyLocationRoutePoint {
    startPointOnRoute: boolean;
    endPointOnRoute: boolean;
}

export interface UserLocation {
    point: Geo.GeoPoint | null;
    time: Date | null;
}

export type GeoCoordinates = Omit<GeolocationCoordinates, 'latitude' | 'longitude'> | Geo.GeoPoint;

export type SavedPlacePoint = {
    latitude: number;
    longitude: number;
    address: string;
    addressFields: Geo.AddressFields;
    name: string;
    countryShort: string | null;
    id: number;
};

export interface ViewRecord {
    entityId: string;
    recordId: string;
    objectName: string;
    address: string | null;
    lat: number;
    lng: number;
    countryShort: string | null;
    addressFields: Geo.AddressFields;
}

export interface PointPositions {
    position: LatLngTuple;
}

export interface Path {
    points: Array<Geo.GeoPoint>;
    legs: Array<PathLeg>;
    legIndexes: number[];
    timestampFrom: number;
    timestampTo: number;
    distance: number;
}

export interface RoutePath extends Path {
    id: string;
    name: string;
    status: number;
    activities: Array<RoutePathActivity>;
}

export interface PathLeg {
    index: number;
    shapes: Routing.Route.LegShape[];
}

export interface RoutePathActivity extends Geo.GeoPoint {
    id: string;
    entityId: number | null;
    recordId: string | null;
    type: Routing.Route.ActivityType;
}

export enum WeekDay {
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
}

export enum WeekDayName {
    'monday' = 'monday',
    'tuesday' = 'tuesday',
    'wednesday' = 'wednesday',
    'thursday' = 'thursday',
    'friday' = 'friday',
    'saturday' = 'saturday',
    'sunday' = 'sunday',
}

export const WeekDays: string[] = [
    WeekDayName.monday,
    WeekDayName.tuesday,
    WeekDayName.wednesday,
    WeekDayName.thursday,
    WeekDayName.friday,
    WeekDayName.saturday,
    WeekDayName.sunday,
];

export type WorkingHoursPerWeek = {
    [key: string]: WorkingHoursPerDay | null;
};

export type WorkingHoursPerDay = {
    start: WorkingHoursTime;
    end: WorkingHoursTime;
};

export type WorkingHoursTime = {
    hours: number;
    minutes: number;
};

export const DefaultWorkingHours: WorkingHoursPerDay = {
    start: {
        hours: 9,
        minutes: 0,
    },
    end: {
        hours: 18,
        minutes: 0,
    },
};

export interface WorkingHoursBreak {
    breakType: BreakTypeEnum;
    breakDuration?: number | null;
    breakLatestTime?: string | null;
    breakEarliestTime?: string | null;
}

export interface WorkingHoursException {
    id: string;
    periodFrom: string; // iso date
    periodTo: string; // iso date
    workingHours: WorkingHoursPerWeek;
    allowedOvertime: number | null;
    breakType: BreakTypeEnum | null;
    breakDuration: number | null;
    breakLatestTime: string | null;
    breakEarliestTime: string | null;
}

export enum UserPropertiesTab {
    TAB_PERSONAL,
    TAB_TRAVELING,
    TAB_LOCATION_TRACKING,
    TAB_CALENDAR,
}

export type PointActionCrmLink = {
    url: string;
    text: string;
};

export type PointDataResponse = {
    mapsly_link: string; // json
};

export enum STORAGE_KEY_PREFIX {
    CURRENT_ROUTE = 'mapsly.ar.current_route.',
    TRAVELING_PREFERENCES = 'mapsly.traveling_preferences.',
    MAP_STATE = 'mapslyMapState_',
    APPOINTMENT_CONFIG = 'mapsly.appointment_config.',
    TRIP_MODE_CONFIG = 'mapsly.trip_mode_config.',
    TURN_ON_TRACKING_POPUP_WAS_SHOWN = 'mapsly.turn_on_tracking_popup_was_shown',
    THERE_WAS_ATTEMPT_TO_CHANGE_SCHEDULE_OUTSIDE_APP = 'mapsly.there_was_attempt_to_change_schedule_outside_app',
    DEVICE_INFO = 'mapsly.device_info',
    DEVICE_INFO_QUEUE = 'mapsly.device_info_queue',
    GEOLOCATION_PERMISSIONS_MODAL_WAS_SHOWN = 'mapsly.geolocation_permissions_modal_was_shown',
    BATTERY_OPTIMIZATION_MODAL_WAS_SHOWN = 'mapsly.battery_optimization_modal_was_shown',
    USER_REVIEW = 'mapsly.user_review.',
    UPDATE_MODAL_LAST_SHOWN_DATE = 'mapsly.update_modal_last_shown_time',
    CALENDAR_CONTEXT_MENU_POPUP_DO_NOT_SHOW_AGAIN = 'mapsly.calendar_context_menu_popup_do_not_show_again',
    WORKING_HOURS_EXCEPTIONS_USER_DATE_TIME = 'mapsly.working_hours_exceptions_user_date_time',
    WAITING_LIST_ITEMS = 'mapsly.waiting_list_items',
    POPUP_SHOW_AND_REMEMBER = 'mapsly.popup_show_and_remember',
    CALENDAR_SCHEDULE_STATE = 'mapsly.calendar_schedule_state',
    GOOGLE_CALENDAR_NOT_CONNECTED_WARNING_TIME = 'mapsly.google_calendar_not_connected_warning_time',
}

export type IField = {
    id: number;
    name: string;
    title: string;
    label: string;
    originalLabel: string;
    apiName: string;
    originalApiName: string | null;
    isCustom: boolean;
    isPin: boolean;
    isIncluded: boolean;
    isVirtual: boolean;
    isReadOnly: boolean;
    isLink: boolean;
    isSystemVisible: boolean;
    isDeleted: boolean;
    lookupData: null | FieldLookupData;
    address: null | ICompositeAddress;
    isAddress: boolean;
    type: FieldType;
    picklist: null;
    length: number;
    value?: string | number;
    isSuitableForAddress: boolean;
    isSuitableForOwner: boolean;
    isSuitableForName: boolean;
};

enum CompositeAddressPart {
    CITY = 'city',
    COUNTRY = 'country',
    STATE = 'state',
    STREET = 'street',
    STREET2 = 'street2',
    ZIP = 'zip',
}

type ICompositeAddress = {
    [part in CompositeAddressPart]: null | string;
};

export type IPopupField = {
    id: number;
    defaultValue: null | string | number;
    field: string;
    isLink: boolean;
    isReadOnly: boolean;
    label: string;
    length: null | number;
    lookupData: null | FieldLookupData | FieldPolymorphicLookupData;
    originalApiName: string | null;
    picklist: null | Array<PicklistValue> | Array<MultiPicklistValue>;
    type: FieldType;
    value: null | string | number | boolean | string[] | number[] | ParsableDate | FieldFactoryValueObject;
    isUpdated?: boolean;
};

export enum FieldLookupType {
    POLYMORPHIC = 'polymorphic',
    OWNER = 'owner',
    SINGLE = 'single',
    ENTITY = 'entity',
    FOREIGN_ENTITY = 'foreign_entity',
    FEW = 'few',
    MULTIPLE = 'multiple',
    UNIVERSAL = 'universal',
}

export type FieldLookupData = {
    type: FieldLookupType;
    apiName: string;
    data: FieldLookupDataDataItem[];
    linking_lookup_data?: {
        entity: string;
        sourceFieldId: string;
        sourceFieldName: string;
        sourceField: string;
        fieldId: string;
        field: string;
        fieldName: string;
    };
};

export type FieldPolymorphicLookupData = {
    apiName?: string;
    type: FieldLookupType;
    columnId: string;
    entities: string[];
    entity_type_field: string;
    sourceColumn: string;
};

type FieldLookupDataDataItem = {
    api_name: string;
    display_label: string;
    id: string | null;
    module: string | null;
};

export enum FieldType {
    INTEGER = 'integer',
    BIGINT = 'bigint',
    FLOAT = 'float',
    TEXT = 'text',
    STRING = 'string',
    BOOLEAN = 'boolean',
    DATE = 'date',
    DATETIME = 'datetime',
    JSON_ARRAY = 'json_array',
    JSON = 'json',
    STRINGS = 'text[]',
    INTEGERS = 'integer[]',
    FILES = 'file[]',
    EMAILS = 'email[]',
    DATETIME_RANGE = 'datetime-range',
}

export enum ApiKeyName {
    GOOGLE = 'google',
    HERE = 'here',
    LOCATION_IQ = 'locationIq',
    MAPBOX = 'mapBox',
    MAPQUEST = 'mapQuest',
}

export type ApiKeys = {
    [key in ApiKeyName]: string;
};

export type ApiKeysBooleanMap = {
    [key in ApiKeyName]: boolean;
};

export type EntityViewLoadFrameDataResponse = {
    apiKeys: ApiKeys;
    customApiKeys: ApiKeysBooleanMap;
    persistentMessages: PersistentMessage[];
    sources: EntityViewSourceResponse[];
    units: string;
};

export interface EntityViewSourceResponse extends Omit<EntityViewSource, 'createdAt'> {
    createdAt: Common.DateString;
}

type PersistentMessage = {};

export interface EntityViewSource extends DataSource.SimpleDataSource {
    insertable: boolean;
    isFirstImportInProgress: boolean;
    entities: EntityViewSourceEntity[];
}

export type EntitySettings = {
    id: number;
    label: string;
    dsName: string;
    fields: Array<IField>;
    mapButtons: null | Array<string>;
    mapButtonsDefault: null | Array<string>;
    isRegeocodable: boolean;
};

export type EntityViewSourceEntity = {
    color: string;
    commonPin: EntityViewSourceEntityPin;
    dsId: number;
    id: number;
    isPinFieldDependent: boolean;
    isRegeocodable: boolean;
    processing: boolean;
    useLatLngFields: boolean;
    userCanEdit: boolean;
    label: string;
    name: string;
    apiName: string;
    pin: string;
    layerGroups: LayerGroup[];
    layers: Layer[];
    views: View[];
};

type LayerGroup = {};
type Layer = {};
type View = {};

type EntityViewSourceEntityPin = {
    color: string;
    fontColor: string;
    fontSize: number;
    fontWeight: number;
    icon: string;
    size: number;
};

export enum AdapterId {
    ZOHO = 'zoho',
    SALESFORCE = 'salesforce',
    HUBSPOT = 'hubspot',
    PIPEDRIVE = 'pipedrive',
    KEAPCRM = 'keapcrm',
    ODOO = 'odoo',
    CREATIO = 'creatio',
    SMARTSHEET = 'smartsheet',
    ZENDESK = 'zendesk',
    DYNAMICS365 = 'dynamics365',
    GOOGLESHEETS = 'googlesheets',
    FRESHWORKSCRM = 'freshworkscrm',
    QUICKBASE = 'quickbase',
    GOOGLECALENDAR = 'googlecalendar',
}

export type AdapterInfo = {
    id: AdapterId;
    name: string;
    info?: string;
};

enum AdapterMethod {
    delete_records,
    get_default_settings,
    get_entities,
    get_entity,
    get_entity_deleted_records,
    get_entity_fields,
    get_entity_records,
    get_interface,
    get_params,
    insert_records,
    mass_update_records,
    oauth,
    ssoauth,
    update_records,
}

export type AdapterCredentials = DataSourceSettings;

export type AdapterResponse = {
    id: AdapterId;
    name: string;
    url: string;
    credentials: AdapterCredentials;
    settings: DataSourceSettings;
    interface: Record<AdapterMethod, string> | null;
};

export type GetAdaptersResponse = AdapterFullInfo[];

export type AdapterFullInfo = AdapterResponse;

export type ProspectStructure = {
    fieldName: string;
    prospectFieldName?: string;
    required: boolean;
    type: ProspectStructureType;
    const?: ProspectStructureConstValue;
};

type ProspectStructureType = 'const' | 'field' | 'dsUserId';

export type ProspectStructureConstValue = string | number | boolean | object | string[];

export type PicklistValue = {
    value: string | number;
    label: string;
};

export type MultiPicklistValue = {
    value: string;
    label: string;
};

export enum UserReviewSourceType {
    SSO = 'sso',
    APP = 'app',
}

export type UserReviewSource = {
    sourceType: UserReviewSourceType;
    adapterId: AdapterId | null;
};

export type DataSourceEntityCounterEntityFieldMap = Map<number, DataSourceEntityCounterEntityField>;

export type DataSourceEntityCounterMap = Map<string, DataSourceEntityCounter>;
