import { DefaultWorkingHours, WorkingHoursPerDay } from 'components/types';
import { DepartingDateTimeData } from './DepartingDateTimeData';
import dateHelper from '../../Date/DateHelper';
import WorkSchedule from '../../WorkSchedule';

class DepartingDateTimeService {
    initCalendarData(departingDateTime: DepartingDateTimeData): DepartingDateTimeData {
        if (!departingDateTime.isToday) {
            return departingDateTime;
        }

        const data = new DepartingDateTimeData(departingDateTime);
        const oldDate = new Date(data.date.getTime());
        data.date = new Date();

        if (data.isTimeChanged) {
            data.date.setHours(oldDate.getHours(), oldDate.getMinutes(), 0, 0);
        } else {
            const startWorkHours = DepartingDateTimeService.getStartWorkHoursByDate(data.date);
            if (data.date.getTime() > startWorkHours.getTime()) {
                data.isNow = true;
            } else {
                data.isNow = false;
                data.date = startWorkHours;
            }
        }

        return data;
    }

    refreshCalendarData(data: DepartingDateTimeData): DepartingDateTimeData {
        return this.getCalendarData(data.date, data.isTimeChanged);
    }

    getCalendarData(date: Date, isTimeChanged: boolean): DepartingDateTimeData {
        const result = new DepartingDateTimeData({
            date: date,
            isToday: false,
            isNow: false,
            isTimeChanged: isTimeChanged,
        });

        const today = new Date();
        result.isToday = today.toDateString() === date.toDateString();

        if (isTimeChanged) {
            if (result.isToday && today.getTime() >= date.getTime()) {
                result.isNow = true;
            }

            return result;
        }

        const startWorkHours = DepartingDateTimeService.getStartWorkHoursByDate(date);
        if (result.isToday) {
            if (today.getTime() > startWorkHours.getTime()) {
                result.isNow = true;
            } else {
                result.date = startWorkHours;
            }
        } else {
            result.date = startWorkHours;
        }

        return result;
    }

    /**
     * @param departingDateTime
     */
    getDateStartForBuildRoute(departingDateTime: DepartingDateTimeData): Date {
        if (departingDateTime.isToday && departingDateTime.isNow) {
            const nowDateWithZeroSeconds = new Date();
            nowDateWithZeroSeconds.setSeconds(0);
            nowDateWithZeroSeconds.setMilliseconds(0);

            return dateHelper.createFromDeviceDate(nowDateWithZeroSeconds).getDisplayDate();
        }

        return dateHelper.createFromDeviceDate(departingDateTime.date).getDate();
    }

    private static getStartWorkHoursByDate(date: Date): Date {
        const workingHoursPerDay = DepartingDateTimeService.getWorkingHoursByDate(date);
        const { hours, minutes } = workingHoursPerDay.start;
        const copiedDate = new Date(date.getTime());

        return new Date(copiedDate.setHours(hours, minutes, 0));
    }

    private static getWorkingHoursByDate(date: Date): WorkingHoursPerDay {
        return WorkSchedule.getWorkingHours(date) || DefaultWorkingHours;
    }
}

export default DepartingDateTimeService;
