import React from 'react';
import PropTypes from 'prop-types';
import {
    TableSelection,
    Grid as DataGrid,
    Table,
    TableHeaderRow,
    TableFilterRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    FilteringState,
    PagingState,
    SortingState,
    DataTypeProvider,
    CustomPaging,
    SelectionState,
    IntegratedSelection,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import { withStyles } from '@material-ui/core/styles';
import { TableLoadingState } from '../TableLoadingState';
import Tooltip from '@material-ui/core/Tooltip';
import entityViewManager, { VIEW_TABLE } from '../../service/EntityViewManager';
import SearchDataTableContextMenu from './SearchDataTableContextMenu';
import { DataTable, FilterIcon, HeaderCellContent } from '../DataTable';
import { StyledFilterCell } from './StyledFilterCell';

import BooleanFormatter from '../DataTable/Formatter/BooleanFormatter';
import StatusFormatter from '../DataTable/Formatter/StatusFormatter';
import DateTimeFormatter from '../DataTable/Formatter/DateTimeFormatter';
import WebLinkFormatter from '../DataTable/Formatter/WebLinkFormatter';
import TerritoriesFormatter from '../DataTable/Formatter/TerritoriesFormatter';
import PicklistFormatter from '../DataTable/Formatter/PicklistFormatter';
import MultiPicklistFormatter from '../DataTable/Formatter/MultiPicklistFormatter';
import { withTranslation } from 'react-i18next';
import { GEO_FIELDS } from '../../references/geoFields';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import { userManager } from 'service/UserManager';
import mapStateManagerFactory from 'service/MapStateManagerFactory';
import metadataManager from '../../service/MetadataManager';

export const linkColName = 'mapsly_link';

export const serverGeocodeStatuses = {
    success: 1,
    doubt: 2,
    noResult: 3,
    noAddress: 4,
    imported: 5,
    unprocessed: 0,
    error: -1,
    restricted: -2,
    other: -3,
};

const frontGeocodeStatuses = {
    any: 'any',
    okExact: 'okExact',
    okApprox: 'okApprox',
    okImported: 'okImported',
    invalid: 'invalid',
    noAddress: 'noAddress',
    processing: 'processing',
};

export const PagingContainer = (props) => <PagingPanel.Container {...props} className="table-pagination" />;

export class SearchDataTable extends DataTable {
    constructor(props) {
        super(props);
        this.filteringState = React.createRef();
        this.sortingState = React.createRef();
        this.defaultPin = null;
        this.isRegeocodable = false;
        this.initialStatus = frontGeocodeStatuses[props.initialProcessingStatus] || null;
        this.initialFilters = [];
        this.initialSorting = [];
        this.columnNameFields = [];
        this.includeUnmappedRecords = false;
    }

    componentDidMount() {
        if (this.props.entityId) {
            this.loadEntity();
        }
        this.setupReloadOnTimeZoneChange();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entityId !== this.props.entityId) {
            this.loadEntity();
        }
    }

    loadEntity(forceUpdate = false) {
        const { entityId } = this.props;
        metadataManager
            .requestEntityForUser(entityId, forceUpdate)
            .then((entity) => {
                entity = metadataManager.cropEntityFields(entity, VIEW_TABLE, false);

                const { fields, pin, columnNameFields, isRegeocodable, pageSize } = entity;

                this.defaultPin = pin;
                this.columnNameFields = columnNameFields;
                this.isRegeocodable = isRegeocodable;

                const user = userManager.getCurrentUser();
                const mapStateManager = mapStateManagerFactory.getManager(user.id);

                let currentPageSize = mapStateManager.getTableViewPageSize(entityId);
                if (!currentPageSize) {
                    mapStateManager.setTableViewPageSize(entityId, pageSize);
                    currentPageSize = pageSize;
                }

                this.setupStructure(fields, currentPageSize);
                if (this.initialStatus) {
                    const defaultFilter = {
                        columnName: GEO_FIELDS.STATUS,
                        value: [this.initialStatus],
                    };
                    this.filters.push({ ...defaultFilter });
                    this.initialFilters.push(defaultFilter);
                } else if (this.filters?.length > 0 && this.initialFilters?.length > 0) {
                    const filtersCopy = [...this.filters];
                    const initialFiltersCopy = [...this.initialFilters];

                    this.filters.splice(0, this.filters.length);
                    this.initialFilters.splice(0, this.initialFilters.length);

                    this.filters.push(...filtersCopy.map((filter) => this.convertServerFilterToFrontFilter(filter)));
                    this.initialFilters.push(
                        ...initialFiltersCopy.map((filter) => this.convertServerFilterToFrontFilter(filter)),
                    );
                }
                this.loadData();
            })
            .catch((error) => {
                this.props.onLoadingError && this.props.onLoadingError(error.message);
            });
    }

    handleViewGeocodingLog = (recordId) => {
        const { onViewGeocodingLog, entityId } = this.props;
        onViewGeocodingLog && onViewGeocodingLog(entityId, recordId);
    };

    buildStructure(fields) {
        const structure = super.buildStructure(fields);

        structure.pinColumn = this.defaultPin;

        structure.statusColumns = [GEO_FIELDS.STATUS];

        if (this.props.sortFieldsComponent) {
            this.props.sortFieldsComponent(this.columnNameFields, structure);
        }

        return structure;
    }

    handleSortingChanged = (sorting) => {
        this.sorting = sorting.map((sort) => ({ ...sort }));

        this.loadData();
    };

    convertServerFilterToFrontFilter = (filter) => {
        if (filter.columnName === GEO_FIELDS.STATUS) {
            if (filter.value && filter.value.length) {
                const values = [];
                for (let v of filter.value) {
                    switch (v) {
                        case serverGeocodeStatuses.success:
                            values.push(frontGeocodeStatuses.okExact);
                            break;
                        case serverGeocodeStatuses.doubt:
                            values.push(frontGeocodeStatuses.okApprox);
                            break;
                        case serverGeocodeStatuses.imported:
                            values.push(frontGeocodeStatuses.okImported);
                            break;
                        case serverGeocodeStatuses.noResult:
                            values.push(frontGeocodeStatuses.invalid);
                            break;
                        case serverGeocodeStatuses.noAddress:
                            values.push(frontGeocodeStatuses.noAddress);
                            break;
                        case serverGeocodeStatuses.unprocessed:
                        case serverGeocodeStatuses.error:
                        case serverGeocodeStatuses.restricted:
                        case serverGeocodeStatuses.other:
                            values.push(frontGeocodeStatuses.processing);
                            break;
                        default:
                            throw new Error();
                    }
                }
                return {
                    operation: 'in',
                    columnName: filter.columnName,
                    value: [...new Set(values)],
                };
            }
        }
        return filter;
    };

    convertFrontFilterToServerFilter(filter) {
        // если есть фильтр по статусу, то преобразовать его
        if (filter.columnName === GEO_FIELDS.STATUS) {
            if (filter.value && filter.value.length) {
                const values = [];
                for (let v of filter.value) {
                    switch (v) {
                        case frontGeocodeStatuses.okExact:
                            values.push(serverGeocodeStatuses.success);
                            break;
                        case frontGeocodeStatuses.okApprox:
                            values.push(serverGeocodeStatuses.doubt);
                            break;
                        case frontGeocodeStatuses.okImported:
                            values.push(serverGeocodeStatuses.imported);
                            break;
                        case frontGeocodeStatuses.invalid:
                            values.push(serverGeocodeStatuses.noResult);
                            break;
                        case frontGeocodeStatuses.noAddress:
                            values.push(serverGeocodeStatuses.noAddress);
                            break;
                        case frontGeocodeStatuses.processing:
                            values.push(serverGeocodeStatuses.unprocessed);
                            values.push(serverGeocodeStatuses.error);
                            values.push(serverGeocodeStatuses.restricted);
                            values.push(serverGeocodeStatuses.other);
                            break;
                        default:
                            throw new Error();
                    }
                }
                return {
                    operation: 'in',
                    columnName: filter.columnName,
                    value: values,
                };
            }
        }

        return super.convertFrontFilterToServerFilter(filter);
    }

    setEmptyDataState() {
        this.setState({
            records: [],
            totalCount: 0,
            pagination: {
                current: 0,
                size: this.state.pagination.size,
            },
        });
    }

    requestData(ignorePage = false, parentTimer = null) {
        const timer =
            parentTimer instanceof Timer ? parentTimer.startChild(TIMER_OPERATIONS.FrontDataTable.requestData) : null;
        if (timer) {
            parentTimer.removeEmptyTerminators();
        }

        const endTimer = timer ? () => timer.end() : () => {};
        return entityViewManager
            .loadData(
                this.props.entityId,
                null,
                this.getFilters(),
                this.sorting,
                ignorePage ? 0 : this.state.pagination.current * this.state.pagination.size,
                this.state.pagination.size,
                this.props.addMainFields,
                VIEW_TABLE,
                timer,
            )
            .finally(endTimer);
    }

    loadData(clearPage = true, timer = null) {
        if (timer instanceof Timer) {
            timer.tryTerminateAfter(3000);
        }
        if (this.state.structure === null) {
            return;
        }

        this.requestData(clearPage, timer)
            .then((data) => {
                this.setState((state) => ({
                    records: data.items,
                    totalCount: parseInt(data.total),
                    pagination: {
                        current: clearPage ? 0 : state.pagination.current,
                        size: state.pagination.size,
                    },
                }));
            })
            .catch((error) => {
                this.props.onLoadingError && this.props.onLoadingError(error.message);
                this.setEmptyDataState();
            })
            .finally(() => {
                if (timer) {
                    timer.end();
                }
            });
    }

    handleExportCSV = () => {
        this.loadCsv();
    };

    handleSelectionChange = (selection) => {
        if (!this.props.onSelectionChange) {
            return;
        }

        const mapSelection = new Map();
        selection.forEach((id) => {
            const row = this.state.records.find((item) => {
                return item.id === id;
            });
            mapSelection.set(id, row);
        });
        this.props.onSelectionChange(mapSelection);
        this.forceUpdate();
    };

    cellSelectedComponent = (props) => {
        const row = props.row;
        return (
            <SearchDataTableContextMenu
                record={row}
                showViewGeocodingLog={row[GEO_FIELDS.RESULT]}
                onViewGeocodingLog={this.handleViewGeocodingLog}
            />
        );
    };

    cellStyledTooltipCell = (props) => {
        return <StyledTooltipCell {...props} />;
    };

    getSelection = () => {
        if (!this.props.selected) {
            return [];
        }
        const selection = [];
        this.props.selected.forEach((value, key) => {
            selection.push(key);
        });
        return selection;
    };

    prepareRecords = (columns, originalRecords) => {
        if (originalRecords.length === 0) {
            return [];
        }

        const lookupFieldColumns = new Map();
        for (let column of columns) {
            if (column.lookup === null) {
                continue;
            }

            const match = column.apiName.match(/_(ID|NAME|TYPE)$/);
            if (match === null) {
                continue;
            }

            const internalType = match[1].toLowerCase();
            let popupField = lookupFieldColumns.get(column.originalApiName);
            if (!popupField) {
                popupField = {
                    ...column,
                    lookupFields: {
                        [internalType]: column,
                    },
                };
            } else {
                popupField.lookupFields[internalType] = { ...column };
            }
            lookupFieldColumns.set(column.originalApiName, popupField);
        }

        if (lookupFieldColumns.size === 0) {
            return originalRecords;
        }

        const records = [];
        for (let i = 0; i < originalRecords.length; i++) {
            records.push({ ...originalRecords[i] });
        }

        lookupFieldColumns.forEach((column) => {
            const { id, name, type } = column.lookupFields;
            const nameField = name || id;
            if (type && nameField) {
                for (let i = 0; i < records.length; i++) {
                    const nameValue = records[i][nameField.name];
                    const typeValue = records[i][type.name];
                    if (nameValue && typeValue) {
                        records[i][nameField.name] = `${nameValue} (${typeValue})`;
                    }
                }
            }
        });

        return records;
    };

    filterColumns = (columns) => {
        return columns.filter((column) => column.lookup === null || !column.apiName.endsWith('_TYPE'));
    };

    RenderFilterCell = StyledFilterCell;

    render() {
        const recordsAreLoading = this.state.records === null;

        const { structure, pagination } = this.state;

        if (!this.props.entityId) {
            return null;
        }

        if (structure === null) {
            return <div>{this.props.t('loading')}</div>;
        }

        const sortingStageColumnsExtension = [{ columnName: 'mapsly_territories', sortingEnabled: false }];
        for (let multiSelectColumn of structure.multiSelectColumns) {
            sortingStageColumnsExtension.push({ columnName: multiSelectColumn, sortingEnabled: false });
        }

        const selection = this.getSelection();
        const showSelectAll = this.props.onSelectionChange !== undefined;
        const hiddenColumnNames = this.includeUnmappedRecords ? [] : [GEO_FIELDS.STATUS];

        const records = this.prepareRecords(structure.columns, this.state.records || []);
        const columns = this.filterColumns(structure.columns);

        return (
            <React.Fragment>
                <DataGrid rows={[]} columns={columns}>
                    <PagingState
                        currentPage={pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </DataGrid>

                <DataGrid rows={records} columns={columns} getRowId={(row) => row.id} key={this.state.key}>
                    <SortingState
                        ref={this.sortingState}
                        defaultSorting={this.initialSorting}
                        onSortingChange={this.handleSortingChanged}
                        columnExtensions={sortingStageColumnsExtension}
                    />
                    <DataTypeProvider
                        for={structure.multiLookupColumns}
                        availableFilterOperations={this.multiLookupFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.polymorphicLookupColumns}
                        availableFilterOperations={this.polymorphicLookupFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.foreignLookupColumns}
                        availableFilterOperations={this.foreignLookupFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.numericColumns}
                        availableFilterOperations={this.numericFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.stringColumns}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateColumns}
                        availableFilterOperations={this.dateFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateTimeColumns}
                        availableFilterOperations={this.dateFilterOperations}
                        formatterComponent={DateTimeFormatter}
                    />
                    <DataTypeProvider
                        key={structure.statusColumns.join(':')}
                        for={structure.statusColumns}
                        formatterComponent={StatusFormatter}
                    />
                    <DataTypeProvider
                        key={structure.booleanColumns.join(':')}
                        for={structure.booleanColumns}
                        formatterComponent={BooleanFormatter}
                    />
                    {structure.webLinkColumns.length > 0 && (
                        <DataTypeProvider
                            key={structure.webLinkColumns.join(':')}
                            for={structure.webLinkColumns}
                            availableFilterOperations={this.stringFilterOperations}
                            formatterComponent={WebLinkFormatter}
                        />
                    )}
                    <DataTypeProvider
                        for={structure.distanceColumns}
                        availableFilterOperations={this.distanceFilterOperations}
                    />
                    <DataTypeProvider
                        key={structure.territoriesColumns.join(':')}
                        for={structure.territoriesColumns}
                        formatterComponent={TerritoriesFormatter}
                    />
                    <DataTypeProvider
                        // при добавлении колонок в структуру не применяется formatter,
                        // поэтому пересоздаю компонент
                        key={structure.multiSelectColumns.join(':')}
                        for={structure.multiSelectColumns}
                        formatterComponent={MultiPicklistFormatter}
                    />
                    <DataTypeProvider
                        key={structure.selectColumns.join(':')}
                        for={structure.selectColumns}
                        formatterComponent={PicklistFormatter}
                    />
                    <FilteringState
                        ref={this.filteringState}
                        onFiltersChange={this.handleFiltersChanged}
                        defaultFilters={this.initialFilters}
                        key={this.state.key}
                    />
                    <Table
                        columnExtensions={structure.exts}
                        cellComponent={this.cellStyledTooltipCell}
                        rowComponent={StyledRow}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={columns.length + 1} loading={recordsAreLoading} />
                        )}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        contentComponent={HeaderCellContent}
                        sortLabelComponent={withStyles(rightLabelHeaderStyles)(TableHeaderRow.SortLabel)}
                        showSortingControls
                    />
                    <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} emptyMessageComponent={() => null} />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        cellComponent={this.RenderFilterCell}
                        messages={this.filterMessages}
                        key={this.state.key}
                    />
                    <SelectionState selection={selection} onSelectionChange={this.handleSelectionChange} />
                    <IntegratedSelection />

                    <TableSelection
                        showSelectAll={showSelectAll}
                        highlightRow={true}
                        cellComponent={this.cellSelectedComponent}
                        headerCellComponent={this.headerCellSelectedComponent}
                        rowComponent={(props) => <StyledRow {...props} highlighted={this.props.highlightStatus} />}
                    />
                </DataGrid>
            </React.Fragment>
        );
    }
}

SearchDataTable.propTypes = {
    entityId: PropTypes.number,
    onViewGeocodingLog: PropTypes.func,
    onSelectionChange: PropTypes.func,
    initialProcessingStatus: PropTypes.string,
    sortFieldsComponent: PropTypes.func,
    addMainFields: PropTypes.bool,
    highlightStatus: PropTypes.bool,
};

SearchDataTable.default = {
    addMainFields: false,
};

const tooltipCellStyles = {
    content: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    cell: {},
};

const TooltipCell = withStyles(tooltipCellStyles)((props) => {
    const { value, classes, ...restProps } = props;
    const { children } = props;
    if (
        children &&
        (children.type === BooleanFormatter ||
            children.type === StatusFormatter ||
            children.type === WebLinkFormatter ||
            children.type === TerritoriesFormatter ||
            children.type === MultiPicklistFormatter ||
            children.type === PicklistFormatter ||
            children.type === DateTimeFormatter)
    ) {
        return <Table.Cell {...restProps}>{children}</Table.Cell>;
    }
    const { type } = restProps.tableColumn.column;
    if (
        (type === 'float' || type === 'smallint' || type === 'integer' || type === 'bigint') &&
        (typeof value === 'number' || typeof value === 'string')
    ) {
        return (
            <Table.Cell {...restProps}>
                <Tooltip title={value} enterTouchDelay={1} leaveTouchDelay={1} placement="top">
                    <div className={classes.content} style={{ textAlign: 'right' }}>
                        {value}
                    </div>
                </Tooltip>
            </Table.Cell>
        );
    }
    if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Table.Cell {...restProps}>
                <Tooltip title={value} enterTouchDelay={1} leaveTouchDelay={1} placement="top">
                    <div className={classes.content}>{value}</div>
                </Tooltip>
            </Table.Cell>
        );
    }
    return <Table.Cell {...restProps} />;
});

const Row = (props) => {
    const { children, classes, selectByRowClick, highlighted, ...rest } = props;

    let className = '';
    if (highlighted) {
        const status = parseInt(rest.tableRow.row[GEO_FIELDS.STATUS]);

        switch (status) {
            case serverGeocodeStatuses.success:
            case serverGeocodeStatuses.imported:
                className = classes.processed;
                break;
            case serverGeocodeStatuses.doubt:
                className = classes.processedApprox;
                break;
            case serverGeocodeStatuses.noResult:
            case serverGeocodeStatuses.noAddress:
                className = classes.declined;
                break;
            default:
                className = classes.unprocessed;
        }
    }

    if (rest.tableRow.row.__isRecordUpdated) {
        className += ' entity-data-table__is-record-updated';
    }
    if (rest.tableRow.row.__isRecordDeleted) {
        className += ' entity-data-table__is-record-deleted';
    }

    return (
        <Table.Row {...rest} className={className}>
            {children}
        </Table.Row>
    );
};

export const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

export const rightLabelHeaderStyles = {
    sortLabelRight: {
        flexDirection: 'row',
    },
};

const processingStatusStyles = {
    processed: {},
    processedApprox: {
        backgroundColor: '#f7f7f7',
    },
    declined: {
        backgroundColor: '#fbdddf',
    },
    unprocessed: {
        backgroundColor: '#fbf5d5',
    },
};

export const StyledTooltipCell = withStyles(regularPaddingCellStyles)(TooltipCell);
export const StyledRow = withStyles(processingStatusStyles)(Row);

export default withTranslation()(SearchDataTable);
