import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Routing } from 'interfaces';
import { appointmentConfig, routeReportInEditModeManager, routingSessionManager } from 'service/MapPage';
import { action, computed, makeObservable, observable } from 'mobx';
import backend from '../../api/BackendApi';
import { BuildRouteEndPoint } from '../../interfaces/geocoder/route';
import { MyLocationType } from '../../interfaces/routing/route';
import { isSameMinute } from 'date-fns';
import { userManager } from '../UserManager';
import geoLocationManager from '../GeoLocationManager';
import GeoPointTransformer from '../GeoPointTransformer';
import DepartingDateTimeService from '../MapPage/Appointment/DepartingDateTimeService';
import { DATE_CHANGE_TYPES } from '../../components/constants';

interface StartMoveActivityData {
    route: Routing.Route.Route;
    activityIndex: number;
}

interface AddPointManuallyData {
    element: HTMLElement;
    route: Routing.Route.Route;
    activityIndex: number;
    action: Routing.Route.EditModeAddPointManuallyAction;
}

interface EditPointAddressData {
    element: HTMLElement;
}

type RouteReportInEditModeManagerKeys =
    | '_startMoveActivityData'
    | '_isOpenAddPointManuallyModal'
    | '_addPointManuallyData'
    | '_isOpenEditStartPointAddressModal'
    | '_editStartPointAddressData'
    | '_isOpenEditEndPointAddressModal'
    | '_editEndPointAddressData';

const excludedTypes = new Set<Routing.Route.ActivityType>([
    Routing.Route.ActivityType.START,
    Routing.Route.ActivityType.END,
]);

class RouteReportInEditModeManager {
    private _startMoveActivityData: StartMoveActivityData | null = null;

    private _isOpenAddPointManuallyModal = false;
    private _addPointManuallyData: AddPointManuallyData | null = null;
    private _isOpenEditStartPointAddressModal = false;
    private _editStartPointAddressData: EditPointAddressData | null = null;
    private _isOpenEditEndPointAddressModal = false;
    private _editEndPointAddressData: EditPointAddressData | null = null;

    constructor() {
        makeObservable<RouteReportInEditModeManager, RouteReportInEditModeManagerKeys>(this, {
            _startMoveActivityData: observable,
            _isOpenAddPointManuallyModal: observable,
            _addPointManuallyData: observable,
            _isOpenEditStartPointAddressModal: observable,
            _editStartPointAddressData: observable,
            _isOpenEditEndPointAddressModal: observable,
            _editEndPointAddressData: observable,

            isActivityMoving: computed,
            isOpenAddPointManuallyModal: computed,
            addPointManuallyData: computed,
            startToMoveActivity: action,
            openAddPointManuallyModal: action,
            closeAddPointManuallyModal: action,
            isOpenEditStartPointAddressModal: computed,
            editStartPointAddressData: computed,
            openEditStartPointAddressModal: action,
            closeEditStartPointAddressModal: action,
            isOpenEditEndPointAddressModal: computed,
            editEndPointAddressData: computed,
            openEditEndPointAddressModal: action,
            closeEditEndPointAddressModal: action,
        });
    }

    async updateStartPoint(route: Routing.Route.Route): Promise<void> {
        const isCurrentUser = route.user.id === userManager.getCurrentUser().id;
        const isToday = route.activities[0].departureTime
            ? route.activities[0].departureTime.toDateString() === new Date().toDateString()
            : false;
        const isStartInPast = route.activities[0].departureTime
            ? route.activities[0].departureTime < new Date()
            : false;
        let startPoint = null;
        if (isToday && isStartInPast && isCurrentUser) {
            const location = await geoLocationManager.getLocationFull();
            if (location && location.lat && location.lng) {
                startPoint = GeoPointTransformer.transformLocationPointToBuildRouteEndPoint(location);
            }
        }

        if (isStartInPast) {
            await routeReportInEditModeManager.handleRouteStartChanged(route, new Date(), startPoint);
        }
    }

    async handleRouteStartChanged(
        route: Routing.Route.Route,
        newStartDate: Date,
        newStartPoint: BuildRouteEndPoint | null,
        changeType?: DATE_CHANGE_TYPES,
    ): Promise<void> {
        const departingDateTimeService = new DepartingDateTimeService();

        let departingDateTime;

        switch (changeType) {
            case DATE_CHANGE_TYPES.date: {
                departingDateTime = departingDateTimeService.getCalendarData(newStartDate, false);

                break;
            }
            case DATE_CHANGE_TYPES.time:
            default: {
                departingDateTime = departingDateTimeService.getCalendarData(newStartDate, true);
            }
        }

        if (departingDateTime.isNow && isSameMinute(new Date(route.dateStartAt), departingDateTime.date)) {
            return;
        }

        await routeReportInEditModeManager.editStart(
            route,
            newStartPoint ?? null,
            departingDateTime.date,
            departingDateTime.isNow,
        );
    }

    async deleteActivity(routeId: string, activityId: string): Promise<void> {
        await routingSessionManager.deleteActivity(routeId, activityId);
        this.reset();
    }

    async resortActivitiesFromRoute(route: Routing.Route.Route, oldIndex: number, newIndex: number): Promise<void> {
        if (oldIndex === newIndex) {
            return;
        }
        await routingSessionManager.moveActivity(route.id, oldIndex, newIndex);
        this.reset();
    }

    async addEntityPoint(
        route: Routing.Route.Route,
        destinationIndex: number,
        waypoint: Routing.Route.DesignInputEntityPoint,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
    ): Promise<void> {
        await this.addPoint(route, waypoint, destinationIndex, addAction);
    }

    async addSimplePoint(
        route: Routing.Route.Route,
        destinationIndex: number,
        waypoint: Routing.Route.DesignSimplePoint,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
    ): Promise<void> {
        await this.addPoint(route, waypoint, destinationIndex, addAction);
    }

    async editStart(
        route: Routing.Route.Route,
        startPoint: BuildRouteEndPoint | null,
        startTime: MaterialUiPickersDate = null,
        isStartNow: boolean = false,
    ): Promise<void> {
        await routingSessionManager.editStartInEditMode(route.id, startPoint, startTime, isStartNow);
        if (isStartNow && startPoint) {
            appointmentConfig.setMyStartLocation({
                buttonType: MyLocationType.Manually,
                point: { ...startPoint, address: `${startPoint.lat},${startPoint.lng}` } as any,
            });
        }
    }

    async editFinishPoint(route: Routing.Route.Route, endPoint: BuildRouteEndPoint | null): Promise<void> {
        return await routingSessionManager.editFinishInEditMode(route.id, endPoint);
    }

    private async addPoint(
        route: Routing.Route.Route,
        waypoint: Routing.Route.DesignSimplePoint | Routing.Route.DesignInputEntityPoint,
        destinationIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
        async: boolean = true,
    ): Promise<any> {
        return await routingSessionManager.addActivity(route.id, waypoint, destinationIndex, addAction, async);
    }

    async moveActivity(
        destinationRoute: Routing.Route.Route,
        destinationActivityIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
    ): Promise<void> {
        const { route, activityIndex } = this.startMoveActivityData;

        if (destinationRoute.id === route.id) {
            await this.moveActivityToCurrentRoute(destinationRoute, destinationActivityIndex, activityIndex, addAction);
        } else {
            await this.moveActivityToOtherRoute(
                destinationRoute,
                destinationActivityIndex,
                route,
                activityIndex,
                addAction,
            );
        }
    }

    private async moveActivityToCurrentRoute(
        destinationRoute: Routing.Route.Route,
        destinationIndex: number,
        sourceIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
    ): Promise<void> {
        let newIndex;
        if (destinationIndex < sourceIndex) {
            newIndex =
                addAction === Routing.Route.EditModeAddPointManuallyAction.ADD_BEFORE
                    ? destinationIndex
                    : destinationIndex + 1;
        } else {
            newIndex =
                addAction === Routing.Route.EditModeAddPointManuallyAction.ADD_BEFORE
                    ? destinationIndex - 1
                    : destinationIndex;
        }
        if (newIndex === sourceIndex) {
            return;
        }
        await this.resortActivitiesFromRoute(destinationRoute, sourceIndex, newIndex);
    }

    private async moveActivityToOtherRoute(
        destinationRoute: Routing.Route.Route,
        destinationActivityIndex: number,
        sourceRoute: Routing.Route.Route,
        sourceActivityIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
    ) {
        const activity = sourceRoute.activities[sourceActivityIndex];
        const addPointResult = await this.addPoint(
            destinationRoute,
            activity,
            destinationActivityIndex,
            addAction,
            false,
        );
        if (!backend.isError(addPointResult)) {
            await this.deleteActivity(sourceRoute.id, activity.id);
        }
    }

    get isActivityMoving(): boolean {
        return this._startMoveActivityData !== null;
    }

    get startMoveActivityData(): StartMoveActivityData {
        if (!this.isActivityMoving) {
            throw new Error('Activity is not moving.');
        }
        return this._startMoveActivityData!;
    }

    startToMoveActivity(route: Routing.Route.Route, activityIndex: number) {
        this._isOpenAddPointManuallyModal = false;
        this._startMoveActivityData = { route, activityIndex };
    }

    get isOpenAddPointManuallyModal(): boolean {
        return this._isOpenAddPointManuallyModal;
    }

    get addPointManuallyData(): AddPointManuallyData {
        if (this._addPointManuallyData === null) {
            throw new Error('Add point manually modal is not open.');
        }
        return this._addPointManuallyData;
    }

    openAddPointManuallyModal = (data: AddPointManuallyData) => {
        this._isOpenAddPointManuallyModal = true;
        this._addPointManuallyData = data;
    };

    closeAddPointManuallyModal = () => {
        this._isOpenAddPointManuallyModal = false;
        this._addPointManuallyData = null;
    };

    get isOpenEditStartPointAddressModal(): boolean {
        return this._isOpenEditStartPointAddressModal;
    }

    get editStartPointAddressData(): EditPointAddressData {
        if (this._editStartPointAddressData === null) {
            throw new Error('Edit point address modal is not open.');
        }
        return this._editStartPointAddressData;
    }

    openEditStartPointAddressModal = (data: EditPointAddressData) => {
        this._isOpenEditStartPointAddressModal = true;
        this._editStartPointAddressData = data;
    };

    closeEditStartPointAddressModal = () => {
        this._isOpenEditStartPointAddressModal = false;
        this._editStartPointAddressData = null;
    };

    get isOpenEditEndPointAddressModal(): boolean {
        return this._isOpenEditEndPointAddressModal;
    }

    get editEndPointAddressData(): EditPointAddressData {
        if (this._editEndPointAddressData === null) {
            throw new Error('Edit point address modal is not open.');
        }
        return this._editEndPointAddressData;
    }

    openEditEndPointAddressModal = (data: EditPointAddressData) => {
        this._isOpenEditEndPointAddressModal = true;
        this._editEndPointAddressData = data;
    };

    closeEditEndPointAddressModal = () => {
        this._isOpenEditEndPointAddressModal = false;
        this._editEndPointAddressData = null;
    };

    canMoveBeforeActivity(route: Routing.Route.Route, activityIndex: number): boolean {
        if (this._startMoveActivityData === null) {
            return true;
        }

        const startMovingIndex = this._startMoveActivityData.activityIndex;
        const currentActivity = this._startMoveActivityData.route.activities[startMovingIndex];
        if (currentActivity.type === 'break' && route.id !== this._startMoveActivityData.route.id) {
            return false;
        }

        if (route.id !== this._startMoveActivityData.route.id) {
            return true;
        }
        if (startMovingIndex === activityIndex) {
            return false;
        }
        return startMovingIndex !== activityIndex - 1;
    }

    canMoveAfterActivity(route: Routing.Route.Route, activityIndex: number): boolean {
        if (this._startMoveActivityData === null) {
            return true;
        }

        const startMovingIndex = this._startMoveActivityData.activityIndex;
        const currentActivity = this._startMoveActivityData.route.activities[startMovingIndex];
        if (currentActivity.type === 'break' && route.id !== this._startMoveActivityData.route.id) {
            return false;
        }

        if (route.id !== this._startMoveActivityData.route.id) {
            return true;
        }

        if (startMovingIndex === activityIndex) {
            return false;
        }
        return startMovingIndex !== activityIndex + 1;
    }

    canDeleteActivity(route: Routing.Route.Route): boolean {
        return route.activities.filter((activity) => this.isServiceActivity(activity)).length > 1;
    }

    isServiceActivity(activity: Routing.Route.Activity): boolean {
        return !excludedTypes.has(activity.type);
    }

    private reset(): void {
        this._isOpenAddPointManuallyModal = false;
        this._startMoveActivityData = null;
        this._addPointManuallyData = null;
        this._isOpenEditStartPointAddressModal = false;
        this._editStartPointAddressData = null;
        this._isOpenEditEndPointAddressModal = false;
        this._editEndPointAddressData = null;
    }
}

export default RouteReportInEditModeManager;
