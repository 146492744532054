import { FormControl, Grid, InputLabel, ListSubheader, MenuItem } from '@material-ui/core';
import {
    DETAIL_PLANNER_VISITED_ENTITY_CONDITION,
    DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION,
    DETAIL_PLANNER_VISITED_ENTITY_DS_ID,
    DETAIL_PLANNER_VISITED_ENTITY_ID,
    DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD,
    DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD,
    DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD,
} from '../../../../references/wokflow/VisitPlannerAction';
import { getDetail } from '../../../../service/WorkflowActionManager';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FieldSelect } from '../../../FieldSelect';
import { HintedSelect } from '../Form';
import ConditionEditor from '../../../WorkflowGroups/ConditionEditor';
import { entityManager } from '../../../../service/SimpleEntityManager';
import Hint from '../../../Hint';

class VisitedSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            entity: null,
        };
    }

    componentDidMount() {
        this.updateStateEntity();
    }

    componentDidUpdate(prevProps) {
        if (
            getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, prevProps.currentAction.details) !==
            getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, this.props.currentAction.details)
        ) {
            this.updateStateEntity();
        }
    }

    updateStateEntity() {
        const { currentAction } = this.props;
        const visitedDataSourceId = getDetail(DETAIL_PLANNER_VISITED_ENTITY_DS_ID, currentAction.details);
        const visitedEntityId = getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, currentAction.details);
        if (visitedEntityId && visitedDataSourceId) {
            entityManager.get(visitedEntityId).then((entity) => {
                if (entity.id === getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, this.props.currentAction.details)) {
                    this.setState({ entity });
                }
            });
        } else {
            this.setState({ entity: null });
        }
    }

    saveCondition =
        ({ expressionDetail, formulaDetail }) =>
        ({ expression, formula }) => {
            this.props.updateDetailCallback([expressionDetail, formulaDetail], [expression, formula]);
        };

    prepareEntityOptions() {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={dataSource.id + '.' + entity.id} value={entity.id} data-ds={dataSource.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    }

    renderEntityValue = (entityId) => {
        if (entityId) {
            for (let dataSource of this.props.dataSources) {
                for (let entity of dataSource.entities) {
                    if (entity.id === entityId) {
                        return `${dataSource.name} > ${entity.label}`;
                    }
                }
            }
        }
        return '';
    };

    render() {
        const { t, currentAction, errors, onDetailChange, updateDetailCallback, accountId } = this.props;

        const visitedDataSourceId = getDetail(DETAIL_PLANNER_VISITED_ENTITY_DS_ID, currentAction.details);
        const entity = this.state.entity;
        const visitedEntityId = entity ? entity.id : null;
        const visitedObjectSelected = Boolean(visitedDataSourceId && visitedEntityId);
        const visitedConditionBuilderDisabled =
            getDetail(DETAIL_PLANNER_VISITED_ENTITY_CONDITION, currentAction.details) !== null &&
            getDetail(DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION, currentAction.details) == null;

        return (
            <Grid item container xs={12} spacing={1} alignItems={'flex-end'}>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_ID)}>
                        <InputLabel>{t('workflow_actions.form.visit_planner.visited.object')}</InputLabel>
                        <HintedSelect
                            fullWidth
                            name={DETAIL_PLANNER_VISITED_ENTITY_ID}
                            value={visitedEntityId || ''}
                            onChange={onDetailChange}
                            endAdornment={
                                <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                    {t('workflow_actions.form.visit_planner.visited.object.tooltip')}
                                </Hint>
                            }
                            renderValue={this.renderEntityValue}
                            data-testid="workflow_actions.form.visit_planner.visited.object"
                        >
                            <MenuItem>
                                <em>{t('undefined_picklist_option')}</em>
                            </MenuItem>
                            {this.prepareEntityOptions()}
                        </HintedSelect>
                        {errors.has(DETAIL_PLANNER_VISITED_ENTITY_ID) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_VISITED_ENTITY_ID)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD)}>
                        <FieldSelect
                            label={t('workflow_actions.form.visit_planner.visited.time_field')}
                            accountId={accountId}
                            disabled={!visitedObjectSelected}
                            required={visitedObjectSelected}
                            dataSourceId={visitedDataSourceId}
                            entityId={visitedEntityId}
                            onChange={(value) =>
                                updateDetailCallback(
                                    DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD,
                                    value ? value.apiName : null,
                                )
                            }
                            value={getDetail(DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD, currentAction.details)}
                            error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD)}
                            hint={t('workflow_actions.form.visit_planner.visited.time_field.tooltip')}
                            filter={(field) =>
                                field.type === 'string' || field.type === 'text' || field.type === 'datetime'
                            }
                            data-testid="workflow_actions.form.visit_planner.visited.time_field"
                            disableNotIncluded
                        />
                        {errors.has(DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD)}>
                        <FieldSelect
                            label={t('workflow_actions.form.visit_planner.visited.user_field')}
                            accountId={accountId}
                            disabled={!visitedObjectSelected}
                            required={visitedObjectSelected}
                            dataSourceId={visitedDataSourceId}
                            entityId={visitedEntityId}
                            onChange={(value) =>
                                updateDetailCallback(
                                    DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD,
                                    value ? value.apiName : null,
                                )
                            }
                            value={getDetail(DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD, currentAction.details)}
                            error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD)}
                            hint={t('workflow_actions.form.visit_planner.visited.user_field.tooltip')}
                            data-testid="workflow_actions.form.visit_planner.visited.user_field"
                            disableNotIncluded
                        />
                        {errors.has(DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD)}>
                        <FieldSelect
                            label={t('workflow_actions.form.visit_planner.visited.record_field')}
                            accountId={accountId}
                            disabled={!visitedObjectSelected}
                            required={visitedObjectSelected}
                            dataSourceId={visitedDataSourceId}
                            entityId={visitedEntityId}
                            onChange={(value) =>
                                updateDetailCallback(
                                    DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD,
                                    value ? value.apiName : null,
                                )
                            }
                            value={getDetail(DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD, currentAction.details)}
                            error={errors.has(DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD)}
                            hint={t('workflow_actions.form.visit_planner.visited.record_field.tooltip')}
                            data-testid="workflow_actions.form.visit_planner.visited.record_field"
                            disableNotIncluded
                        />
                        {errors.has(DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD) && (
                            <FormHelperText>{errors.get(DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD)}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <ConditionEditor
                        action={currentAction}
                        baseEntity={entity}
                        onSave={this.saveCondition({
                            formulaDetail: DETAIL_PLANNER_VISITED_ENTITY_CONDITION,
                            expressionDetail: DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION,
                        })}
                        formula={getDetail(DETAIL_PLANNER_VISITED_ENTITY_CONDITION, currentAction.details) || ''}
                        expression={
                            getDetail(DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION, currentAction.details) || []
                        }
                        builderDisabled={visitedConditionBuilderDisabled}
                        hint={t('workflow_actions.form.visit_planner.in.condition.tooltip')}
                        conditionLabel={t('workflow_actions.form.visit_planner.in.condition')}
                        formulaPlaceholder={t('workflow_actions.form.visit_planner.condition_placeholder')}
                        ExpressionBuilderFormProps={{
                            addSpecialSection: false,
                            addOldEntitySection: false,
                            addUserSection: false,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

VisitedSection.propTypes = {
    currentAction: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    updateDetailCallback: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    dataSources: PropTypes.array.isRequired,
};

const TranslatedVisitedSection = withTranslation('translations')(VisitedSection);
export { TranslatedVisitedSection as VisitedSection };
