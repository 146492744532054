import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Icon, IconButton, InputAdornment } from '@material-ui/core';
import { FieldFactoryValueObject } from 'components/LiveUpdateTableForm/FieldFactory/FieldFactory';

interface Props extends WithTranslation {
    value: string | number | FieldFactoryValueObject | null;
    onShowSearch: () => void;
}

class LookupConstantInput extends React.PureComponent<Props> {
    render() {
        const { value, onShowSearch, t } = this.props;
        const textValue = typeof value === 'object' && value !== null && value.textValue ? value.textValue : null;

        return (
            <TextField
                margin="dense"
                style={{ whiteSpace: 'nowrap' }}
                value={textValue || t('live_update_table_form.field_factory.auto_complete.edit_text')}
                data-testid="live_update_table_form.field_factory.auto_complete.field"
                InputProps={{
                    disableUnderline: false,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                size="small"
                                onClick={onShowSearch}
                                data-testid="live_update_table_form.field_factory.auto_complete.show_search"
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                disabled
            />
        );
    }
}

export default withTranslation()(LookupConstantInput);
