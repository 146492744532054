import React, { MouseEventHandler } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
    isVisible: boolean;
}

interface Props extends StandardTextFieldProps, WithTranslation {}

class PasswordField extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isVisible: false,
        };
    }

    handleToggleVisibility = () => {
        this.setState(({ isVisible }) => ({ isVisible: !isVisible }));
    };

    handleMouseDownPassword: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
    };

    render() {
        const { i18n, t, tReady, InputProps, ...rest } = this.props;
        const { isVisible } = this.state;

        return (
            <TextField
                {...rest}
                type={isVisible ? 'text' : 'password'}
                InputProps={{
                    ...InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                size="small"
                                aria-label={t('password_field.toggle_visibility.aria_label')}
                                onClick={this.handleToggleVisibility}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                            >
                                {isVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

export default withTranslation('translations', { withRef: true })(PasswordField);
