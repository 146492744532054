const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};
const CONTENT_TYPES = {
    EMPTY: '',
    FORM: 'application/x-www-form-urlencoded',
    MULTIPART_FORM: 'multipart/form-data',
    JSON: 'application/json',
    XML: 'application/xml',
};
const DEFAULT_DETAILS = {
    method: HTTP_METHODS.GET,
    content_type: CONTENT_TYPES.EMPTY,
    url: '',
    urlParams: [],
    headerParams: [],
    formParams: [],
    body: '',
    resultMessage: '',
};

export { HTTP_METHODS, CONTENT_TYPES, DEFAULT_DETAILS };
