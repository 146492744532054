import React from 'react';
import { FormControl, InputLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PicklistValue } from 'components/types';

export type ChangeSelectTargetEvent = {
    target: {
        name: string;
        value: string | string[] | number | null;
    };
};

interface Props extends WithTranslation {
    value: string | number | null;
    name: string;
    label: string;
    onChangeSelect: (event: ChangeSelectTargetEvent) => void;
    picklist: PicklistValue[];
    disabled: boolean;
    autoFocus: boolean;
    isSearchOfLabelInPicklist: boolean;
}

class PicklistField extends React.PureComponent<Props> {
    render() {
        const { t, value, name, label, disabled, autoFocus, picklist, isSearchOfLabelInPicklist, onChangeSelect } =
            this.props;

        let element = this.props.picklist.find((item) => item.value === value);
        if (!element && isSearchOfLabelInPicklist) {
            element = this.props.picklist.find((item) => item.label === value);
        }

        return (
            <FormControl fullWidth style={{ minWidth: '200px' }} margin="dense">
                <InputLabel style={{ whiteSpace: 'nowrap' }} shrink>
                    {label}
                </InputLabel>
                <Autocomplete
                    disabled={disabled}
                    options={picklist}
                    value={element || null}
                    getOptionLabel={(item) => item.label}
                    onChange={(_, picklistElement) => {
                        onChangeSelect({ target: { name: name, value: picklistElement?.value ?? null } });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus={autoFocus}
                            placeholder={t('undefined_picklist_option')}
                            margin="dense"
                            style={{ margin: '16px 0 0' }}
                        />
                    )}
                />
            </FormControl>
        );
    }
}

export default withTranslation()(PicklistField);
