import React from 'react';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { getDetail } from '../../../service/WorkflowActionManager';
import './../style.css';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import AbstractForm from '../AbstractForm/AbstractForm';
import validator from 'validator';
import { TARGET } from './Action';
import FormulaInput from '../AbstractForm/FormulaInput';
import FormControlInput from '../AbstractForm/FormControlInput';
import PureFormDialog from '../../PureFormDialog';
import { FormActions } from '../../PureFormDialog/Form';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            const url = getDetail('url', currentAction.details, '');
            const iframeWidth = String(getDetail('iframeWidth', currentAction.details, 0));
            const iframeHeight = String(getDetail('iframeHeight', currentAction.details, 0));

            if (validator.isEmpty(url)) {
                errors.set('message', t('errors.invalid_value'));
            }
            if (!validator.isInt(iframeWidth, { min: 0 })) {
                errors.set('iframeWidth', t('errors.invalid_value'));
            }
            if (!validator.isInt(iframeHeight, { min: 0 })) {
                errors.set('iframeHeight', t('errors.invalid_value'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    render() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        const target = getDetail('target', details, '');

        return (
            <>
                <PureFormDialog
                    title={t('workflow_actions.form.invoke_url.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.open_url.name')}
                        data-testid="workflow_actions.form.open_url.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.open_url.api_name')}
                        data-testid="workflow_actions.form.open_url.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />

                    {this.renderEntitySelect(currentAction, errors)}

                    <FormulaInput
                        fullWidth
                        margin="dense"
                        required
                        multiline
                        label={t('workflow_actions.form.open_url.url')}
                        name="url"
                        value={getDetail('url', details, '')}
                        onChange={this.handleDetailsChange}
                        error={errors.has('url')}
                        helperText={errors.get('url')}
                        callContext={currentAction.callContext}
                    />

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                label={t('workflow_actions.form.open_url.target')}
                                name="target"
                                value={target}
                                onChange={this.handleDetailsChange}
                                error={errors.has('target')}
                                helperText={errors.get('target')}
                                required
                                data-testid="workflow_actions.form.open_url.target"
                            >
                                <MenuItem value={TARGET.IFRAME}>
                                    {t('workflow_actions.form.open_url.target.' + TARGET.IFRAME)}
                                </MenuItem>
                                <MenuItem value={TARGET.NEW_TAB}>
                                    {t('workflow_actions.form.open_url.target.' + TARGET.NEW_TAB)}
                                </MenuItem>
                            </FormControlSelect>
                        </Grid>
                        {target === TARGET.IFRAME && (
                            <Grid item xs={2}>
                                <FormControlInput
                                    margin="dense"
                                    label={t('workflow_actions.form.open_url.iframeWidth')}
                                    name="iframeWidth"
                                    value={getDetail('iframeWidth', details, '')}
                                    onChange={this.handleDetailsChange}
                                    error={errors.has('iframeWidth')}
                                    helperText={errors.get('iframeWidth')}
                                    endAdornment="  px"
                                    type="number"
                                />
                            </Grid>
                        )}
                        {target === TARGET.IFRAME && (
                            <Grid item xs={2}>
                                <FormControlInput
                                    margin="dense"
                                    label={t('workflow_actions.form.open_url.iframeHeight')}
                                    name="iframeHeight"
                                    value={getDetail('iframeHeight', details, '')}
                                    onChange={this.handleDetailsChange}
                                    error={errors.has('iframeHeight')}
                                    helperText={errors.get('iframeHeight')}
                                    endAdornment="  px"
                                    type="number"
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </>
        );
    }
}

export { Form as OpenUrlForm };
