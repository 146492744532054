import { HTTP } from '@ionic-native/http';
import { weAreInNativeApp } from '../utils';
import { isBodiless } from './http';

/**
 * External api
 */
export default class Api {
    static serialize(obj, prefix) {
        const str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + '[' + p + ']' : p;
                const v = obj[p];
                if (v === null) {
                    continue;
                }
                const a =
                    typeof v === 'object' ? this.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v);
                if (a !== '') {
                    str.push(a);
                }
            }
        }
        return str.join('&');
    }

    constructor() {
        this.pendingPromises = new Map();
    }

    createPromise(url, method, postData = null) {
        if (weAreInNativeApp()) {
            return this.createPromiseNative(url, method, postData);
        }

        return this.createPromiseWeb(url, method, postData);
    }

    createPromiseNative(url, method, postData = null) {
        return new Promise((resolve, reject) => {
            const headers = {};
            const options = {
                method: method.toLowerCase(),
                data: postData || [],
                serializer: 'json',
                headers,
                timeout: 20000,
            };

            return HTTP.sendRequest(url, options)
                .then((response) => {
                    if (response.status === 200) {
                        try {
                            const result = JSON.parse(response.data);
                            resolve(result);
                        } catch (e) {
                            reject('Malformed response');
                        }
                    } else {
                        reject('Server error');
                    }
                })
                .catch((response) => {
                    console.error('Request ended with error, code:' + response.status);
                    reject('Connection error');
                });
        });
    }

    createPromiseWeb(url, method, postData = null) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 20000;

            xhr.onload = () => {
                if (xhr.status === 200) {
                    try {
                        const result = JSON.parse(xhr.response);
                        resolve(result);
                    } catch (e) {
                        reject('Malformed response');
                    }
                } else {
                    console.error('Request ended with error, code:' + xhr.status);
                    reject('Server error');
                }
            };
            xhr.onerror = () => {
                console.error('Request ended with error, code:' + xhr.status);
                reject('Connection error');
            };
            xhr.ontimeout = () => {
                reject('Connection timeout');
            };

            xhr.open(method, url);

            if (isBodiless(method)) {
                xhr.send(null);
            } else {
                xhr.send(JSON.stringify(postData || []));
            }
        });
    }

    requestApi(url, method, queryData = null, postData = null) {
        if (queryData) {
            const params = this.constructor.serialize(queryData);
            if (params !== '') {
                if (url.indexOf('?') === -1) {
                    url += '?' + params;
                } else {
                    url += '&' + params;
                }
            }
        }

        if (!isBodiless(method)) {
            return this.createPromise(url, method, postData);
        }

        // Учитываю пока только буквальное совпадение параметров.
        const requestKey = url;
        if (this.pendingPromises.has(requestKey)) {
            return this.pendingPromises.get(requestKey);
        }

        const promise = this.createPromise(url, method, postData)
            .then((response) => {
                this.pendingPromises.delete(requestKey);
                return response;
            })
            .catch((error) => {
                this.pendingPromises.delete(requestKey);
                throw error;
            });

        this.pendingPromises.set(requestKey, promise);

        return promise;
    }
}
