import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import './style.css';
import { accountsManager } from '../../service/AccountsManager';
import FormDialog from '../FormDialog';
import AccountMainForm from '../AccountMainForm';
import AccountStatusForm from './AccountStatusForm';
import AccountSummary from './AccountSummary';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import { withTranslation } from 'react-i18next';

class Account extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editAccount: false,
            accountBlockStatusModal: false,
            account: null,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.ACCOUNT_UPDATED, this, (account) => {
            if (this.state.account && this.state.account.id === account.id) {
                this.setState({
                    account,
                });
            }
        });

        accountsManager
            .load(this.props.accountId)
            .then((account) => {
                this.setState({ account });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                if (error.code === 403) {
                    error.skipSentry = true;
                }
                throw error;
            });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.ACCOUNT_UPDATED, this);
    }

    handleAccountOpen = () => {
        this.setState({
            editAccount: true,
        });
    };

    handleAccountSaved = (account) => {
        this.props.enqueueSnackbar(this.props.t('account.saved'), { variant: 'success' });
        this.setState({
            account: account,
            editAccount: false,
        });
    };

    handleAccountClose = () => {
        this.setState({
            editAccount: false,
        });
    };

    handleAccountBlocked = () => {
        this.setState({
            accountBlockStatusModal: true,
        });
    };

    handleChangeTestState = () => {
        this.setState((state) => {
            return {
                account: { ...state.account, isTest: !state.account.isTest },
            };
        });
    };

    handleAccountBlockedSave = (account) => {
        this.setState({
            accountBlockStatusModal: false,
            account,
        });
    };

    handleAccountBlockedCancel = () => {
        this.setState({
            accountBlockStatusModal: false,
        });
    };

    render() {
        const { account } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <AccountSummary
                    account={account}
                    myAccount={this.props.myAccount}
                    onOpen={this.handleAccountOpen}
                    onBlock={this.handleAccountBlocked}
                    onChangeTestState={this.handleChangeTestState}
                />
                {this.state.editAccount && (
                    <FormDialog
                        title={t('account.profile')}
                        onSave={this.handleAccountSaved}
                        onCancel={this.handleAccountClose}
                    >
                        <AccountMainForm account={account} />
                    </FormDialog>
                )}
                {this.state.accountBlockStatusModal && (
                    <FormDialog
                        title={t('confirm_removal')}
                        onSave={this.handleAccountBlockedSave}
                        onCancel={this.handleAccountBlockedCancel}
                        saveButtonTitle={account.deleted ? t('account.enable.confirm') : t('account.disable.confirm')}
                    >
                        <AccountStatusForm account={account} />
                    </FormDialog>
                )}
            </React.Fragment>
        );
    }
}

Account.propTypes = {
    myAccount: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
};

Account.defaultProps = {
    myAccount: false,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(Account));
