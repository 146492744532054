import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { Api, Routing } from 'interfaces';
import { RouteDenormalizer } from 'service/Serializer';
import { BuildRouteEndPoint } from '../../../interfaces/geocoder/route';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import mapslyGeocoderApi from 'api/MapslyGeocoderApi';

class RoutingSessionService extends BackendService {
    async initSession(): Promise<Api.Routing.Session.InitSessionResponse> {
        const url = reverse(apiRoutes.routes.routeSession.init);
        const response: Api.Routing.Session.InitSessionApiResponse = await this.requestApi(url, 'GET');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async loadSessionRoute(routeId: string): Promise<Api.Routing.Session.LoadSessionRouteResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.routes.loadRoute, { routeId });
        const response: Api.Routing.Session.LoadSessionRouteApiResponse = await this.requestApi(url, 'GET');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async deletePublishedRoute(routeId: string): Promise<void> {
        const url = reverse(apiRoutes.routes.routeSession.published.routes.deleteRoute, { routeId });
        await this.requestApi(url, 'DELETE');
    }

    async deleteActivityInDraftMode(
        routeId: string,
        activityId: string,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.deleteActivity, { routeId, activityId });
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'DELETE', { async: 1 });
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async moveActivityInDraftMode(
        routeId: string,
        oldIndex: number,
        newIndex: number,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.moveActivity);
        const request: Api.Routing.Session.DraftEditModeMoveActivityRequest = {
            routeId,
            oldIndex,
            newIndex,
            async: true,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async addActivitiesInDraftMode(
        routeId: string,
        waypoints: (Routing.Route.DesignEntityPoint | Routing.Route.DesignSimplePoint)[],
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.addActivities);
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', {
            routeId,
            waypoints,
            async: true,
        });
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async addActivityInDraftMode(
        routeId: string,
        waypoint: Routing.Route.DesignSimplePoint | Routing.Route.DesignInputEntityPoint,
        destinationIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
        async: boolean = true,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.addActivity);
        const request: Api.Routing.Session.DraftEditModeAddActivityRequest = {
            routeId,
            waypoint,
            destinationIndex,
            addAction,
            async,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async editStartInDraftMode(
        routeId: string,
        startPoint: BuildRouteEndPoint | null,
        startTime: MaterialUiPickersDate = null,
        isStartNow: boolean = false,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.editStart);
        const request: Api.Routing.Session.DraftEditModeEditStartRequest = {
            routeId,
            startPoint,
            startTime,
            isStartNow,
            async: true,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async editFinishInDraftMode(
        routeId: string,
        endPoint: BuildRouteEndPoint | null,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.editFinish);
        const request: Api.Routing.Session.DraftEditModeEditFinishRequest = { routeId, endPoint, async: true };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async deleteActivityInPublishedMode(
        routeId: string,
        activityId: string,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.deleteActivity, { routeId, activityId });
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'DELETE', { async: 1 });
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async moveActivityInPublishedMode(
        routeId: string,
        oldIndex: number,
        newIndex: number,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.moveActivity);
        const request: Api.Routing.Session.DraftEditModeMoveActivityRequest = {
            routeId,
            oldIndex,
            newIndex,
            async: true,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async addActivitiesInPublishedMode(
        routeId: string,
        waypoints: (Routing.Route.DesignEntityPoint | Routing.Route.DesignSimplePoint)[],
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.addActivities);
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', {
            routeId,
            waypoints,
            async: true,
        });
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async addActivityInPublishedMode(
        routeId: string,
        waypoint: Routing.Route.DesignSimplePoint | Routing.Route.DesignInputEntityPoint,
        destinationIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
        async: boolean = true,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.addActivity);
        const request: Api.Routing.Session.DraftEditModeAddActivityRequest = {
            routeId,
            waypoint,
            destinationIndex,
            addAction,
            async,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async editStartInPublishedMode(
        routeId: string,
        startPoint: BuildRouteEndPoint | null,
        startTime: MaterialUiPickersDate = null,
        isStartNow: boolean = false,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.editStart);
        const request: Api.Routing.Session.DraftEditModeEditStartRequest = {
            routeId,
            startPoint,
            startTime,
            isStartNow,
            async: true,
        };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async editFinishInPublishedMode(
        routeId: string,
        endPoint: BuildRouteEndPoint | null,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.editFinish);
        const request: Api.Routing.Session.DraftEditModeEditFinishRequest = { routeId, endPoint, async: true };
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST', request);
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async reoptimizeRoute(): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.reoptimize);
        const response: Api.Routing.Session.EditModeApiResponse = await this.requestApi(url, 'POST');
        return mapslyGeocoderApi.denormalizeBuildResponse(response);
    }

    async saveDesignModeSession(
        input: Routing.Session.SessionDesignModeInput,
    ): Promise<Api.Routing.Session.SaveSessionApiResponse> {
        const request: Api.Routing.Session.SaveDesignModeSessionRequest = {
            ...input,
            calendar: {
                ...input.calendar,
                departingCalendarData: {
                    ...input.calendar.departingCalendarData,
                    date: input.calendar.departingCalendarData.date,
                },
            },
        };

        const url = reverse(apiRoutes.routes.routeSession.designMode.save);
        return await this.requestApi(url, 'POST', request);
    }

    async savePublishedEditModeSession(
        input: Routing.Session.SessionInput,
    ): Promise<Api.Routing.Session.SaveSessionApiResponse> {
        const request: Api.Routing.Session.SavePublishedEditModeSessionRequest = { config: input.config };
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.updateSession);
        return await this.requestApi(url, 'POST', request);
    }

    async cancelAdvancedRouteBuilding(): Promise<Api.Routing.Session.CancelRoutingBuildingResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.cancelRoutingBuilding);
        const response: Api.Routing.Session.CancelRoutingBuildingApiResponse = await this.requestApi(url, 'GET');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async discardDraftMode(): Promise<Api.Routing.Session.DiscardDraftModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.discard);
        const response: Api.Routing.Session.DiscardDraftModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async clearDraftMode(): Promise<Api.Routing.Session.ClearDraftModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.clear);
        const response: Api.Routing.Session.ClearDraftModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async switchToDesignMode(): Promise<Api.Routing.Session.SwitchToDesignModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.switchToDesign);
        const response: Api.Routing.Session.SwitchToDesignModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async switchToRebuildMode(
        sessionId: string | null = null,
        routeId: string | null = null,
    ): Promise<Api.Routing.Session.SwitchToRebuildModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.rebuildMode.enable);
        const response: Api.Routing.Session.SwitchToRebuildModeApiResponse = await this.requestApi(url, 'POST', {
            sessionId,
            routeId,
        });
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async cancelRebuildMode(): Promise<Api.Routing.Session.CancelRebuildModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.rebuildMode.cancel);
        const response: Api.Routing.Session.CancelRebuildModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async enableEditModeInDraftMode(): Promise<Api.Routing.Session.EnableEditModeInDraftModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.enable);
        const response: Api.Routing.Session.EnableEditModeInDraftModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async cancelEditModeInDraftMode(): Promise<Api.Routing.Session.CancelEditModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.cancel);
        const response: Api.Routing.Session.CancelEditModeInDraftModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async saveEditModeInDraftMode(): Promise<Api.Routing.Session.SaveEditModeResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.save);
        const response: Api.Routing.Session.SaveEditModeInDraftModeApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async publishInDraftMode(
        loadRoute: Routing.Route.Route | null,
        startTrip: boolean,
    ): Promise<Api.Routing.Session.PublishResponse> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.publish);
        const data: Api.Routing.Session.PublishApiRequest = {
            loadRouteId: loadRoute ? loadRoute.id : null,
            startTrip: startTrip,
        };
        const response: Api.Routing.Session.PublishApiResponse = await this.requestApi(url, 'POST', data);
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async publishInRebuildMode(): Promise<Api.Routing.Session.PublishResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.rebuildMode.save);
        const response: Api.Routing.Session.PublishApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async publishAsInRebuildMode(): Promise<Api.Routing.Session.PublishAsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.rebuildMode.saveAs);
        const response: Api.Routing.Session.PublishAsApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async enableEditModePublishedRoute(): Promise<Api.Routing.Session.ResultSessionRoutesResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.enable);
        const response: Api.Routing.Session.ResultSessionRoutesApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async cancelEditModePublishedRoute(): Promise<Api.Routing.Session.ResultSessionRoutesResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.cancel);
        const response: Api.Routing.Session.ResultSessionRoutesApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async saveEditModePublishedRoute(): Promise<Api.Routing.Session.ResultSessionRoutesResponse> {
        const url = reverse(apiRoutes.routes.routeSession.published.editMode.save);
        const response: Api.Routing.Session.ResultSessionRoutesApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async startTrip(): Promise<Api.Routing.Session.StartTripResponse> {
        const url = reverse(apiRoutes.routes.routeSession.trip.start);
        const response: Api.Routing.Session.StartTripApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async endTrip(routeId: string): Promise<Api.Routing.Session.EndTripResponse> {
        const url = reverse(apiRoutes.routes.routeSession.trip.end, { routeId });
        const response: Api.Routing.Session.EndTripApiResponse = await this.requestApi(url, 'POST');
        return this.denormalizeSessionRoutesApiResponse(response);
    }

    async saveSessionName(sessionName: string): Promise<Routing.Session.Session> {
        const url = reverse(apiRoutes.routes.routeSession.saveSessionName);
        const request: Api.Routing.Session.SaveSessionNameApiRequest = { sessionName };
        const response: Api.Routing.Session.SaveSessionNameApiResponse = await this.requestApi(url, 'POST', request);
        return RouteDenormalizer.denormalizeSession(response.session);
    }

    async deleteDraftRoute(route: Routing.Route.Route): Promise<Routing.Route.Route> {
        const url = reverse(apiRoutes.routes.routeSession.draftMode.editMode.routes.deleteRoute, { routeId: route.id });
        return this.requestApi(url, 'DELETE');
    }

    private denormalizeSessionRoutesApiResponse(
        response: Api.Routing.Session.ResultSessionRoutesApiResponse,
    ): Api.Routing.Session.ResultSessionRoutesResponse {
        const result: Api.Routing.Session.InitSessionResponse = {
            currentSession: {
                session: RouteDenormalizer.denormalizeSession(response.currentSession.session),
                routes: RouteDenormalizer.denormalizeRoutes(response.currentSession.routes),
            },
            loadedSessionRoute: null,
        };
        if (response.loadedSessionRoute !== null) {
            result.loadedSessionRoute = {
                session: RouteDenormalizer.denormalizeSession(response.loadedSessionRoute.session),
                route: response.loadedSessionRoute.route
                    ? RouteDenormalizer.denormalizeRoute(response.loadedSessionRoute.route)
                    : null,
            };
        }

        return result;
    }
}

export default RoutingSessionService;
