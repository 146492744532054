import { ParallelSafeDispatcher, Status } from './ParallelSafeDispatcher';

export class ParallelSafeDispatcherStorageBased extends ParallelSafeDispatcher {
    private readonly expirationTimeInSeconds: number;

    constructor(sharedName: string, task: any, expirationTimeInSeconds: number) {
        super(sharedName, task);
        this.expirationTimeInSeconds = expirationTimeInSeconds;

        window.addEventListener('beforeunload', () => {
            const lock = localStorage.getItem(this.sharedName);
            if (lock && this.isMyLock(lock)) {
                localStorage.removeItem(this.sharedName);
            }
        });
    }

    protected vote(): Promise<boolean> {
        return new Promise((resolve) => {
            const lock = localStorage.getItem(this.sharedName);
            if (lock && !this.isExpiredLock(lock)) {
                resolve(false);
                return;
            }
            localStorage.setItem(this.sharedName, String(this.id) + '-' + String(Date.now()));
            setTimeout(() => {
                const lock = localStorage.getItem(this.sharedName);
                resolve(lock !== null && this.isMyLock(lock));
            }, 1000);
        });
    }

    protected setStatus(status: Status): void {
        this.status = status;
        if (status === Status.Idle) {
            localStorage.removeItem(this.sharedName);
        }
    }

    private isExpiredLock(lock: string): boolean {
        if (this.expirationTimeInSeconds === 0) {
            return false;
        }
        const [, ts] = lock.split('-');
        return Date.now() - parseInt(ts) > this.expirationTimeInSeconds * 1000;
    }

    private isMyLock(lock: string): boolean {
        const [id] = lock.split('-');
        return id === String(this.id);
    }
}
