import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

export const ARTICLE_FORMULA_BUILDER = '4309381';
export const ARTICLE_FORMULA_EDITOR = '5193088';
export const ARTICLE_AUTOMATION_EXECUTION_CONTEXT = '5394499';
export const ARTICLE_WORKFLOW = '5171868';
export const ARTICLE_BUTTON = '5141172';
export const ARTICLE_SCHEDULE = '5164426';
export const ARTICLE_UPDATE_RECORD_ACTION = '5193145';
export const ARTICLE_INVOKE_URL_ACTION = '5171848';
export const ARTICLE_CREATE_RECORD_ACTION = '5193298';
export const ARTICLE_DELETE_RECORD_ACTION = '5193322';
export const ARTICLE_SEND_EMAIL_ACTION = '5192748';
export const ARTICLE_SEND_SMS_ACTION = '5193430';
export const ARTICLE_MATCH_RECORDS_ACTION = '5171650';
export const ARTICLE_SHOW_MESSAGE_ACTION = '5193328';
export const ARTICLE_OPEN_URL_ACTION = '5193363';
export const ARTICLE_OPEN_FORM_ACTION = '5173971';
export const ARTICLE_VISIT_PLANNER_ACTION = '5163398';
export const ARTICLE_UPDATE_FORM_ACTION = '5401032';
export const ARTICLE_MARK_AS_VISITED_ACTION = '6550141';
export const ARTICLE_RECORD_SHARING = '4353717';
export const ARTICLE_USER_PREFERENCES_PERSONAL = '5609806';
export const ARTICLE_USER_PREFERENCES_TRAVELING = '5565183';
export const ARTICLE_USER_PREFERENCES_TRACKING = '5584301';
export const ARTICLE_USER_PREFERENCES_CALENDAR = '9456689';
export const ARTICLE_DYNAMICS_MAP_FILTERS = '4360684';
export const ARTICLE_DYNAMICS_MAP_LAYERS = '4361536';
export const ARTICLE_AUTO_ASSIGNMENT_OF_TERRITORIES = '4806882';
export const ARTICLE_AUTOMATION = '5173729';
export const ARTICLE_USER_MANAGEMENT_AND_SSO = '4340451';
export const ARTICLE_ADJUST_BUTTONS = '5141172';
export const ARTICLE_MAP_POPUP_TEMPLATE = '6067554';
export const ARTICLE_COMMIT_ACTION = '6483205';
export const ARTICLE_ROUTING = '5884479';
export const ARTICLE_ADVANCED_ROUTING = '5884340';
export const ARTICLE_ROUTING_SETTINGS = '5895823';
export const ARTICLE_PLANNING_FOR_THE_CURRENT_DAY = '5970420';
export const ARTICLE_EDITING_ROUTE_JOB_PARAMETERS = '7872744';
export const ARTICLE_CALENDAR_SETTINGS = '7832676';
export const ARTICLE_CONTINUOUS_ROUTES = '7208153';

class LandingLink extends React.PureComponent {
    render() {
        const { useLeadingIcon, useTrailingIcon, article, articleHeader, children, classes, ...rest } = this.props;
        const articleHeaderPostfix = articleHeader ? `#${articleHeader}` : '';

        return (
            <a
                className={classes.root}
                href={`https://help.mapsly.com/articles/${article + articleHeaderPostfix}`}
                target="_blank"
                rel="noopener noreferrer"
                {...rest}
            >
                {useLeadingIcon && <i className="fas fa-question-circle" aria-hidden="true" />}
                <span>{children}</span>
                {useTrailingIcon && <i className="fas fa-external-link" aria-hidden="true" />}
            </a>
        );
    }
}

LandingLink.propTypes = {
    useLeadingIcon: PropTypes.bool,
    useTrailingIcon: PropTypes.bool,
    article: PropTypes.string.isRequired,
    articleHeader: PropTypes.string,
    style: PropTypes.object,
};

LandingLink.defaultProps = {
    useLeadingIcon: false,
    useTrailingIcon: false,
};

const styles = {
    root: {
        whiteSpace: 'nowrap',
        '& > i': {
            fontSize: '85%',
            '&:first-child': {
                paddingRight: '0.4em',
            },
            '&:last-child': {
                paddingLeft: '0.4em',
            },
        },
    },
};

export default withStyles(styles)(LandingLink);
