import { Button } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { FormActions } from '../PureFormDialog/Form';

interface WarningOverwriteFieldsDialogProps extends WithTranslation {
    onCancel: () => void;
    onSave: () => void;
}

class WarningOverwriteFieldsDialog extends React.Component<WarningOverwriteFieldsDialogProps> {
    render() {
        const { onCancel, onSave, t } = this.props;
        return (
            <PureFormDialog
                open
                title={`${t('warning_probably_partial_address_field.modal.title')}`}
                onClose={onCancel}
                maxWidth="md"
                fullWidth
            >
                <p dangerouslySetInnerHTML={{ __html: t('warning_probably_partial_address_field.modal.content') }} />
                <FormActions closeText={t('warning_probably_partial_address_field.modal.cancel')}>
                    <Button color="primary" onClick={onSave}>
                        {t('warning_probably_partial_address_field.modal.ok')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

export default withTranslation()(WarningOverwriteFieldsDialog);
