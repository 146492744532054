import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import { nativeAppFriendlyOpenWindow } from '../../utils';
import { UI_FEATURES_DEFAULT } from './constants';
import i18n from 'i18next';
import { formatMoney } from '../Billing/MoneyFormat';

interface Props extends WithTranslation {
    moduleName: string;
    subscription: any;
}

interface Module {
    features: {
        [featureKey: string]: {
            subFeatures?: {
                [subFeatureKey: string]: {
                    subSubFeatures?: {
                        [subSubFeatureKey: string]: {};
                    };
                };
            };
        };
    };
}

class UiTitleFeature extends React.PureComponent<Props> {
    render() {
        const { moduleName, t, subscription } = this.props;
        const moduleObject: Module = UI_FEATURES_DEFAULT[moduleName];

        const params = {
            createModifyPrice: formatMoney(subscription.currency, subscription.analyticsCreateModifyPerUserPmCur),
            viewPrice: formatMoney(subscription.currency, subscription.analyticsViewPerUserPmCur),
            calendarPrice: formatMoney(subscription.currency, subscription.calendarPerUserPmCur),
        };

        const tooltipKey = 'permissions.ui_features.module_' + moduleName + '.tooltip';
        let tooltip = this.translateIfExists(tooltipKey, '', params);
        const tooltipUrlKey = 'permissions.ui_features.feature_' + moduleName + '.tooltip_url';
        const tooltipUrl = this.translateIfExists(tooltipUrlKey, '', params);

        const tooltipStyle = this.getTooltipStyle(tooltipKey);

        const tooltipAdditionalKey = 'permissions.ui_features.module_' + moduleName + '.tooltip_additional';
        let tooltipAdditional = this.translateIfExists(tooltipAdditionalKey, '', params);
        const tooltipAdditionalStyle = this.getTooltipStyle(tooltipAdditionalKey);

        return (
            <Grid container direction="column">
                <Grid item>
                    <span className="c-permissions_ui_features_title_module">
                        {t('permissions.ui_features.module_' + moduleName)}
                        &nbsp;
                        {tooltip && tooltip !== '' && (
                            <Tooltip title={tooltip}>
                                {tooltipUrl === '' ? (
                                    <i className={tooltipStyle} />
                                ) : (
                                    <i
                                        className={tooltipStyle}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            nativeAppFriendlyOpenWindow(tooltipUrl);
                                        }}
                                    />
                                )}
                            </Tooltip>
                        )}
                        {tooltipAdditional && tooltipAdditional !== '' && (
                            <Tooltip title={tooltipAdditional}>
                                <i className={tooltipAdditionalStyle} />
                            </Tooltip>
                        )}
                    </span>
                </Grid>
                {Object.entries(moduleObject.features).map(([featureKey, featureValue]) => {
                    const tooltipKey = 'permissions.ui_features.feature_' + featureKey + '.tooltip';
                    const tooltip = this.translateIfExists(tooltipKey, '', params);
                    const tooltipUrl = this.translateIfExists(
                        'permissions.ui_features.feature_' + featureKey + '.url',
                        '',
                        params,
                    );
                    const tooltipStyle = this.getTooltipStyle(tooltipKey);

                    const tooltipAdditionalKey =
                        'permissions.ui_features.feature_' + featureKey + '.tooltip_additional';
                    let tooltipAdditional = this.translateIfExists(tooltipAdditionalKey, '', params);
                    const tooltipAdditionalStyle = this.getTooltipStyle(tooltipAdditionalKey);

                    return (
                        <React.Fragment key={'feature-' + featureKey}>
                            <Grid item>
                                <span className="c-permissions_ui_features_title_sub" style={{ paddingLeft: '10px' }}>
                                    {t('permissions.ui_features.feature_' + featureKey)}
                                    &nbsp;
                                    {tooltip && (
                                        <Tooltip title={tooltip}>
                                            {tooltipUrl === '' ? (
                                                <i className={tooltipStyle} />
                                            ) : (
                                                <i
                                                    className={tooltipStyle}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        nativeAppFriendlyOpenWindow(tooltipUrl);
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                    )}
                                    {tooltipAdditional && tooltipAdditional !== '' && (
                                        <Tooltip title={tooltipAdditional}>
                                            <i className={tooltipAdditionalStyle} />
                                        </Tooltip>
                                    )}
                                </span>
                            </Grid>
                            {Object.entries(featureValue.subFeatures || []).map(([subFeatureKey, subFeatureValue]) => {
                                const tooltipKey =
                                    'permissions.ui_features.feature_' +
                                    featureKey +
                                    '.subfeature_' +
                                    subFeatureKey +
                                    '.tooltip';
                                const tooltip = this.translateIfExists(tooltipKey, '', params);
                                const tooltipStyle = this.getTooltipStyle(tooltipKey);

                                const tooltipAdditionalKey =
                                    'permissions.ui_features.feature_' +
                                    featureKey +
                                    '.subfeature_' +
                                    subFeatureKey +
                                    '.tooltip_additional';
                                let tooltipAdditional = this.translateIfExists(tooltipAdditionalKey, '', params);
                                const tooltipAdditionalStyle = this.getTooltipStyle(tooltipAdditionalKey);

                                return (
                                    <React.Fragment key={'subFeature-' + subFeatureKey}>
                                        <Grid item>
                                            <span
                                                className="c-permissions_ui_features_title_sub"
                                                style={{ paddingLeft: '20px' }}
                                            >
                                                {t('permissions.ui_features.feature_' + subFeatureKey)}
                                                &nbsp;
                                                {tooltip && (
                                                    <Tooltip title={tooltip}>
                                                        <i className={tooltipStyle} />
                                                    </Tooltip>
                                                )}
                                                {tooltipAdditional && tooltipAdditional !== '' && (
                                                    <Tooltip title={tooltipAdditional}>
                                                        <i className={tooltipAdditionalStyle} />
                                                    </Tooltip>
                                                )}
                                            </span>
                                        </Grid>
                                        {Object.entries(subFeatureValue.subSubFeatures || []).map(
                                            ([subSubFeatureKey, _]) => {
                                                const tooltipKey =
                                                    'permissions.ui_features.feature_' +
                                                    featureKey +
                                                    '.sub_sub_feature_' +
                                                    subSubFeatureKey +
                                                    '.tooltip';
                                                const tooltip = this.translateIfExists(tooltipKey, '', params);
                                                let tooltipStyle = this.getTooltipStyle(tooltipKey);

                                                const tooltipAdditionalKey =
                                                    'permissions.ui_features.feature_' +
                                                    featureKey +
                                                    '.sub_sub_feature_' +
                                                    subSubFeatureKey +
                                                    '.tooltip_additional';
                                                let tooltipAdditional = this.translateIfExists(
                                                    tooltipAdditionalKey,
                                                    '',
                                                    params,
                                                );
                                                const tooltipAdditionalStyle =
                                                    this.getTooltipStyle(tooltipAdditionalKey);

                                                return (
                                                    <Grid item key={'subSubFeature-' + subSubFeatureKey}>
                                                        <span
                                                            className="c-permissions_ui_features_title_sub"
                                                            style={{ paddingLeft: '30px' }}
                                                        >
                                                            {t('permissions.ui_features.feature_' + subSubFeatureKey)}
                                                            &nbsp;
                                                            {tooltip && (
                                                                <Tooltip title={tooltip}>
                                                                    <i className={tooltipStyle} />
                                                                </Tooltip>
                                                            )}
                                                            {tooltipAdditional && tooltipAdditional !== '' && (
                                                                <Tooltip title={tooltipAdditional}>
                                                                    <i className={tooltipAdditionalStyle} />
                                                                </Tooltip>
                                                            )}
                                                        </span>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Grid>
        );
    }

    translateIfExists(key: string, defaultValue = '', params: object) {
        const exists = i18n.exists(key);
        return exists ? this.props.t(key, params) : defaultValue;
    }

    getTooltipStyle(tooltipKey: string) {
        let tooltipStyle = '';

        const chargableList = [
            'permissions.ui_features.module_analytics.tooltip_additional',
            'permissions.ui_features.feature_view_charts_and_dashboards.tooltip_additional',
            'permissions.ui_features.feature_view_charts_and_dashboards.subfeature_create_or_modify.tooltip_additional',
            'permissions.ui_features.module_calendar.tooltip_additional',
        ];

        if (chargableList.includes(tooltipKey)) {
            tooltipStyle = 'fa-solid fa-circle-dollar tooltip-have-price';
        } else {
            tooltipStyle = 'fas fa-question-circle tooltip-default';
        }

        return tooltipStyle;
    }
}

export default withTranslation()(UiTitleFeature);
