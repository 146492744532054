import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FormActions, FormBackdrop } from '../PureFormDialog/Form';
import { withTranslation } from 'react-i18next';
import LandingLink from '../HelpLink/LandingLink';
import { intercomManager } from '../../service/IntercomManager';
import { formatMoney } from '../Billing/MoneyFormat';
import DottedLink from '../DottedLink';

class SharedMapActivation extends React.PureComponent {
    contactSupport = (e) => {
        e.preventDefault();
        intercomManager.showNewMessage('');
    };

    render() {
        const {
            t,
            loading,
            subscription: { currency, customSharedMapsPmCur },
        } = this.props;
        return (
            <div className="c-shared-map-modal">
                <FormBackdrop loading={loading}>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p1', {
                                    totalPrice: formatMoney(currency, customSharedMapsPmCur),
                                }),
                            }}
                        />
                    </p>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p2'),
                            }}
                        />
                        <LandingLink article="5198246" useTrailingIcon>
                            {this.props.t('shared_map.modal.activation.p2.link')}
                        </LandingLink>
                        {t('shared_map.modal.activation.p2.trailing1')}
                        <DottedLink onClick={this.contactSupport}>
                            {t('shared_map.modal.activation.p2.chat_link')}
                        </DottedLink>
                        {t('shared_map.modal.activation.p2.trailing2')}
                    </p>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p3'),
                            }}
                        />
                        <LandingLink article="5344496" useTrailingIcon>
                            {this.props.t('shared_map.modal.activation.p3.link')}
                        </LandingLink>
                        {t('shared_map.modal.activation.p3.trailing')}
                    </p>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p4'),
                            }}
                        />
                        <LandingLink article="5141172" useTrailingIcon>
                            {this.props.t('shared_map.modal.activation.p4.link')}
                        </LandingLink>
                        {t('shared_map.modal.activation.p4.trailing1')}
                        <DottedLink onClick={this.contactSupport}>
                            {t('shared_map.modal.activation.p4.chat_link')}
                        </DottedLink>
                        {t('shared_map.modal.activation.p4.trailing2')}
                    </p>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p5'),
                            }}
                        />
                        <DottedLink onClick={this.contactSupport}>
                            {t('shared_map.modal.activation.p5.chat_link')}
                        </DottedLink>
                        {t('shared_map.modal.activation.p5.trailing')}
                    </p>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('shared_map.modal.activation.p6'),
                            }}
                        />
                    </p>
                </FormBackdrop>

                <FormActions closeText={t('button.cancel')}>
                    <Button
                        color="primary"
                        onClick={this.props.onSave}
                        data-testid="shared_map.modal.activation.confirm"
                    >
                        {t('shared_map.modal.activation.confirm')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

SharedMapActivation.propTypes = {
    onSave: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    subscription: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(SharedMapActivation);
