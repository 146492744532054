import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';
import { subscriptionPlanManager } from './SubscriptionPlanManager';

export const TYPE_CHANGE = {
    REFRESH: 'refresh',
    PLAN: 'plan',
    SEATS: 'seats',
    ROUTING_CREDITS: 'routing_credits',
    SWITCHED_TO_CUSTOM: 'switched to custom',
    PRICE_RECALCULATED: 'price',
    ADDITIONAL_AI_CREDITS: 'additional_ai_credits',
    ADDITIONAL_TRANSCRIPTION_SECONDS: 'additional_transcription_seconds',
};

class SubscriptionManager extends BackendService {
    constructor(accountId) {
        super();
        this.accountId = accountId;
        this.subscriptionPlans = null;
        this.sub = null;
        this.previousSub = null;
        this.refresh();
        dispatcher.subscribe(events.WS_SUBSCRIPTION_UPDATED, this.eventTag, (payload) => {
            this.refresh(payload.updated);
        });
    }

    refresh(type = TYPE_CHANGE.REFRESH) {
        return this.requestApi(reverse(apiRoutes.account.subscription, { accountId: this.accountId }), 'GET').then(
            (response) => {
                this.sub = response;
                dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, { type: type, subscription: this.sub });
                return Promise.resolve(this.sub);
            },
        );
    }

    getSubscription() {
        if (this.sub !== null) {
            return Promise.resolve(this.sub);
        }

        return this.refresh();
    }

    getPreviousSubscription() {
        return this.previousSub;
    }

    listPlan() {
        if (this.subscriptionPlans !== null) {
            return Promise.resolve(this.subscriptionPlans);
        }

        return subscriptionPlanManager.listPlan(this.accountId).then((response) => {
            this.subscriptionPlans = response;
            return response;
        });
    }

    updatePlan(planId) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.changePlan, {
                accountId: this.accountId,
                newPlan: planId,
            }),
            'POST',
        ).then(() => {
            return Promise.resolve();
        });
    }

    updateSeats(numberSeats) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.changeSeats, {
                accountId: this.accountId,
                numberSeats: numberSeats,
            }),
            'POST',
        ).then(() => {
            return Promise.resolve();
        });
    }

    updateRoutingCredits(routingCredits) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.changeRoutingCredits, {
                accountId: this.accountId,
                numberRoutingCredits: routingCredits,
            }),
            'POST',
        ).then((response) => {
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: TYPE_CHANGE.ROUTING_CREDITS,
                subscription: response,
            });
            return Promise.resolve();
        });
    }

    updateAdditionalAiTokens(additionalAiTokens) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.additionalAiTokens, {
                accountId: this.accountId,
            }),
            'PUT',
            { additionalAiTokens },
        ).then((response) => {
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: TYPE_CHANGE.ADDITIONAL_AI_CREDITS,
                subscription: response,
            });
            return Promise.resolve();
        });
    }

    updateAdditionalTranscriptionSeconds(additionalTranscriptionSeconds) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.additionalTranscriptionSeconds, {
                accountId: this.accountId,
            }),
            'PUT',
            { additionalTranscriptionSeconds },
        ).then((response) => {
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: TYPE_CHANGE.ADDITIONAL_TRANSCRIPTION_SECONDS,
                subscription: response,
            });
            return Promise.resolve();
        });
    }

    createPaymentLink() {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.createPaymentLink, {
                accountId: this.accountId,
            }),
            'POST',
        ).then((response) => {
            return response;
        });
    }

    listPaymentHistory(offset, limit) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.paymentHistory, {
                accountId: this.accountId,
            }),
            'GET',
            { offset, limit },
        );
    }

    listSubscriptionHistory(offset, limit) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.history, {
                accountId: this.accountId,
            }),
            'GET',
            { offset, limit },
        );
    }

    subscriptionHasConversion() {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.hasConversions, {
                accountId: this.accountId,
            }),
            'GET',
        );
    }

    customizeSubscription(subscription) {
        this.previousSub = { ...this.sub };
        return this.requestApi(
            reverse(apiRoutes.account.subscription, { accountId: this.accountId }),
            'POST',
            subscription,
        ).then((response) => {
            this.sub = response;
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: 'Updated Subscription',
                subscription: this.sub,
            });
            return Promise.resolve(this.sub);
        });
    }

    switchToCustom() {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.switchToCustom, {
                accountId: this.accountId,
            }),
            'POST',
        ).then((response) => {
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: TYPE_CHANGE.SWITCHED_TO_CUSTOM,
                subscription: response,
            });

            return Promise.resolve(response);
        });
    }

    updatePaymentInformation() {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.updatePaymentInformation, {
                accountId: this.accountId,
            }),
            'POST',
        ).then(() => {
            return Promise.resolve();
        });
    }

    resume() {
        return this.requestApi(reverse(apiRoutes.account.subscription.resume, { accountId: this.accountId }), 'POST');
    }

    recalculatePrice(sendNotification) {
        return this.requestApi(
            reverse(apiRoutes.account.subscription.recalculatePrice, {
                accountId: this.accountId,
            }),
            'POST',
            { notifyByEmail: sendNotification },
        ).then((response) => {
            dispatcher.dispatch(events.EVENT_SUBSCRIPTION_CHANGED, {
                type: TYPE_CHANGE.PRICE_RECALCULATED,
                subscription: response,
            });
            return Promise.resolve(response);
        });
    }
}

class Factory {
    managers = new Map();

    getManager(accountId) {
        accountId = parseInt(accountId);
        if (this.managers.has(accountId)) {
            return this.managers.get(accountId);
        }
        const manager = new SubscriptionManager(accountId);
        this.managers.set(accountId, manager);

        return manager;
    }
}

export default new Factory();
export { SubscriptionManager };
