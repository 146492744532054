import React from 'react';
import { DataExchangeSession } from '../../../service/DataExchangeManager';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    Slide,
    SlideProps,
    Typography,
    withStyles,
} from '@material-ui/core';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { DATETIME_PICKER_FORMAT } from '../../../utils';

const Transition = React.forwardRef(function (props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Method = withStyles({
    root: {
        backgroundColor: '#757575b5',
        padding: '3px 5px',
        textAlign: 'center',
        color: '#ffffff',
        borderRadius: '3px',
        marginRight: '5px',
    },
})(Typography);

interface CurrentProps extends WithTranslation {
    session: DataExchangeSession;
    onClose: (e: React.MouseEvent) => void;
}

class ApiCallDetailsModal extends React.PureComponent<CurrentProps> {
    renderDialogContent = () => {
        const data = this.props.session.data;
        const url = data?.url;
        const method = data?.method;
        const request = data?.request ? JSON.parse(data.request) : {};
        const response = data?.response ? JSON.parse(data.response) : {};

        return (
            <DialogContent>
                <div>
                    <Method variant="button">{method}</Method>
                    {url}
                </div>

                <Grid container spacing={3} className="execution-flow-container">
                    <Grid item xs={6} className="execution-flow-column">
                        <div>
                            <h3>{this.props.t('api_call_details.modal.request')}</h3>
                            <ReactJson
                                src={request}
                                name={false}
                                displayDataTypes={false}
                                enableClipboard={false}
                                style={{ overflowY: 'scroll', height: '100%' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs className="execution-flow-column">
                        <div>
                            <h3>{this.props.t('api_call_details.modal.response')}</h3>
                            <ReactJson
                                src={response}
                                name={false}
                                displayDataTypes={false}
                                enableClipboard={false}
                                style={{ overflowY: 'scroll', height: '100%' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        );
    };

    render() {
        return (
            <Dialog open={true} onClose={this.props.onClose} TransitionComponent={Transition} fullScreen>
                <DialogTitle className="c-form-dialog__title">
                    {this.props.t('api_call_details.modal.title', {
                        createdAt: moment(this.props.session.createdAt).format(DATETIME_PICKER_FORMAT),
                    })}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.props.onClose}
                        className="c-form-dialog__close"
                        data-testid="api_call_details.modal.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                {this.renderDialogContent()}
            </Dialog>
        );
    }
}

export default withTranslation()(ApiCallDetailsModal);
