import dispatcher from '../dispatcher';
import events from '../../events';
import { userManager } from '../UserManager';
import {
    CALENDAR_MODULE,
    PERMISSION_MODIFY,
    PERMISSION_VIEW,
    SHARING_MODULE,
} from '../../components/Permissions/constants';
import dsManagerFactory from '../DsManager';
import entitiesPermissionsManager from './EntitiesPermissionsManager';
import { IDataSource } from '../types';
import { action, computed, makeObservable, observable } from 'mobx';
import { SYSTEM_ENTITIES_API_NAMES } from '../../references/systemEntities';

type UsersPermissionsManagerKeys =
    | '_hasBrowseUsersPermission'
    | 'hasBrowseUsersPermission'
    | 'calculateHasBrowseUsersPermission'
    | '_hasUsersObjectAndLocationFieldsPermission'
    | 'hasUsersObjectAndLocationFieldsPermission'
    | 'calculateEntitiesPermissions'
    | '_entitiesPermissionsLoading'
    | 'entitiesPermissionsLoading'
    | '_hasLocationHistoryViewPermission'
    | 'hasLocationHistoryViewPermission'
    | '_hasCalendarEventObjectModifyNotOwnedPermission'
    | 'hasCalendarEventObjectModifyNotOwnedPermission'
    | '_hasCalendarEventObjectRecordSharingRules'
    | 'hasCalendarEventObjectRecordSharingRules'
    | '_hasCalendarUiPermission'
    | 'hasCalendarUiPermission'
    | '_hasCalendarViewPreferencesPermission'
    | 'hasCalendarViewPreferencesPermission'
    | '_hasCalendarModifyPreferencesPermission'
    | 'hasCalendarModifyPreferencesPermission'
    | '_hasCalendarModifyOtherUsersPreferencesPermission'
    | 'hasCalendarModifyOtherUsersPreferencesPermission';

class UsersPermissionsManager {
    constructor() {
        makeObservable<UsersPermissionsManager, UsersPermissionsManagerKeys>(this, {
            _hasBrowseUsersPermission: observable,
            hasBrowseUsersPermission: computed,
            calculateHasBrowseUsersPermission: action,
            calculateEntitiesPermissions: action,

            _hasUsersObjectAndLocationFieldsPermission: observable,
            hasUsersObjectAndLocationFieldsPermission: computed,
            _entitiesPermissionsLoading: observable,
            entitiesPermissionsLoading: computed,
            _hasLocationHistoryViewPermission: observable,
            hasLocationHistoryViewPermission: computed,
            _hasCalendarEventObjectModifyNotOwnedPermission: observable,
            hasCalendarEventObjectModifyNotOwnedPermission: computed,
            _hasCalendarEventObjectRecordSharingRules: observable,
            hasCalendarEventObjectRecordSharingRules: computed,

            _hasCalendarUiPermission: observable,
            hasCalendarUiPermission: computed,
            _hasCalendarViewPreferencesPermission: observable,
            hasCalendarViewPreferencesPermission: computed,
            _hasCalendarModifyPreferencesPermission: observable,
            hasCalendarModifyPreferencesPermission: computed,
            _hasCalendarModifyOtherUsersPreferencesPermission: observable,
            hasCalendarModifyOtherUsersPreferencesPermission: computed,
        });

        dispatcher.subscribe(
            [events.EVENT_CURRENT_USER_CHANGED, events.EVENT_ACCOUNT_PERMISSIONS_SAVED, events.WS_ROLES_UPDATED],
            this,
            this.calculatePermissions.bind(this),
        );
    }

    private _hasBrowseUsersPermission: boolean = false;
    private _hasUsersObjectAndLocationFieldsPermission: boolean = false;
    private _entitiesPermissionsLoading: boolean = false;
    private _hasLocationHistoryViewPermission: boolean = false;
    private _hasCalendarEventObjectModifyNotOwnedPermission: boolean = false;
    private _hasCalendarEventObjectRecordSharingRules: boolean = false;

    private _hasCalendarUiPermission: boolean = false;
    private _hasCalendarViewPreferencesPermission: boolean = false;
    private _hasCalendarModifyPreferencesPermission: boolean = false;
    private _hasCalendarModifyOtherUsersPreferencesPermission: boolean = false;

    calculatePermissions() {
        this.clearPermissions();

        this.calculateHasBrowseUsersPermission();
        this.calculateEntitiesPermissions();
        this.calculateHasCalendarPermissions();
    }

    private clearPermissions() {
        this._hasBrowseUsersPermission = false;
        this._hasUsersObjectAndLocationFieldsPermission = false;
        this._hasCalendarUiPermission = false;
        this._hasCalendarViewPreferencesPermission = false;
        this._hasCalendarModifyPreferencesPermission = false;
        this._hasCalendarModifyOtherUsersPreferencesPermission = false;
    }

    private calculateHasBrowseUsersPermission() {
        const sharingModulePermission = userManager.userHasAccessTo(SHARING_MODULE.NAME);
        const browseUsersFeature = userManager.userHasAccessTo(
            SHARING_MODULE.NAME,
            SHARING_MODULE.FEATURES.BROWSE_USERS.NAME,
        );
        this._hasBrowseUsersPermission = sharingModulePermission && browseUsersFeature;
    }

    private calculateHasCalendarPermissions() {
        this._hasCalendarUiPermission = userManager.userHasAccessTo(
            CALENDAR_MODULE.NAME,
            CALENDAR_MODULE.FEATURES.UI.NAME,
        );
        this._hasCalendarViewPreferencesPermission = userManager.userHasAccessTo(
            CALENDAR_MODULE.NAME,
            CALENDAR_MODULE.FEATURES.UI.NAME,
            CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.NAME,
        );
        this._hasCalendarModifyPreferencesPermission = userManager.userHasAccessTo(
            CALENDAR_MODULE.NAME,
            CALENDAR_MODULE.FEATURES.UI.NAME,
            CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.NAME,
            CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES.MODIFY_PREFERENCES.NAME,
        );
        this._hasCalendarModifyOtherUsersPreferencesPermission = userManager.userHasAccessTo(
            CALENDAR_MODULE.NAME,
            CALENDAR_MODULE.FEATURES.UI.NAME,
            CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.NAME,
            CALENDAR_MODULE.FEATURES.UI.SUB_FEATURES.VIEW_CALENDAR_PREFERENCES.SUB_SUB_FEATURES
                .MODIFY_OTHER_USERS_PREFERENCES.NAME,
        );
    }

    private calculateEntitiesPermissions() {
        const user = userManager.getCurrentUser();
        const account = userManager.getCurrentAccount();

        if (!user || !account) {
            return;
        }

        this._entitiesPermissionsLoading = true;

        Promise.all([
            dsManagerFactory.getManager(account.id).list(),
            entitiesPermissionsManager.loadPermissions(account, true),
        ])
            .then((result) => {
                const [dsList] = result;
                const systemDS: IDataSource | undefined = dsList.find((ds: IDataSource) => {
                    return ds.isSystem;
                });
                if (!systemDS) {
                    return;
                }

                const locationHistoryEntityCounter = systemDS.entityCounters.find(
                    (entityCounter) => SYSTEM_ENTITIES_API_NAMES.LOCATION_HISTORY === entityCounter.entity.name,
                );
                const locationHistoryEntity = locationHistoryEntityCounter?.entity;

                const userEntityCounter = systemDS.entityCounters.find((entity) => {
                    return entity.entity.apiName === 'User';
                });
                const userEntity = userEntityCounter?.entity;

                const calendarEventEntityCounter = systemDS.entityCounters.find((entity) => {
                    return entity.entity.apiName === SYSTEM_ENTITIES_API_NAMES.CALENDAR_EVENT;
                });
                const calendarEventEntity = calendarEventEntityCounter?.entity;

                const latitudeField = userEntity?.fields.find((field) => {
                    return field.name === 'latitude';
                });
                const longitudeField = userEntity?.fields.find((field) => {
                    return field.name === 'longitude';
                });

                const hasEntityPermission =
                    (userEntity &&
                        entitiesPermissionsManager.hasPermission(user, PERMISSION_VIEW, false, userEntity.id) &&
                        entitiesPermissionsManager.hasPermission(user, PERMISSION_VIEW, true, userEntity.id)) ||
                    false;

                const hasFieldsPermission =
                    (userEntity &&
                        latitudeField &&
                        longitudeField &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_VIEW,
                            false,
                            userEntity.id,
                            latitudeField.id,
                        ) &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_VIEW,
                            true,
                            userEntity.id,
                            latitudeField.id,
                        ) &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_VIEW,
                            false,
                            userEntity.id,
                            longitudeField.id,
                        ) &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_VIEW,
                            true,
                            userEntity.id,
                            longitudeField.id,
                        )) ||
                    false;

                const hasLocationHistoryViewPermission =
                    (locationHistoryEntity &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_VIEW,
                            false,
                            locationHistoryEntity.id,
                        )) ||
                    false;

                this._hasUsersObjectAndLocationFieldsPermission = hasEntityPermission && hasFieldsPermission;
                this._hasLocationHistoryViewPermission = hasLocationHistoryViewPermission;

                this._hasCalendarEventObjectModifyNotOwnedPermission =
                    (calendarEventEntity &&
                        entitiesPermissionsManager.hasPermission(
                            user,
                            PERMISSION_MODIFY,
                            false,
                            calendarEventEntity.id,
                        )) ||
                    false;
                this._hasCalendarEventObjectRecordSharingRules =
                    (calendarEventEntity &&
                        entitiesPermissionsManager.hasRecordSharingRules(user, calendarEventEntity.id)) ||
                    false;
            })
            .finally(() => {
                this._entitiesPermissionsLoading = false;
                dispatcher.dispatch(events.EVENT_ACCOUNT_PERMISSIONS_LOADED);
            });
    }

    get hasBrowseUsersPermission(): boolean {
        return this._hasBrowseUsersPermission;
    }

    get hasUsersObjectAndLocationFieldsPermission(): boolean {
        return this._hasUsersObjectAndLocationFieldsPermission;
    }

    get entitiesPermissionsLoading(): boolean {
        return this._entitiesPermissionsLoading;
    }

    get hasLocationHistoryViewPermission(): boolean {
        return this._hasLocationHistoryViewPermission;
    }

    get hasCalendarEventObjectModifyNotOwnedPermission(): boolean {
        return this._hasCalendarEventObjectModifyNotOwnedPermission;
    }

    get hasCalendarEventObjectRecordSharingRules(): boolean {
        return this._hasCalendarEventObjectRecordSharingRules;
    }

    get hasCalendarUiPermission(): boolean {
        return this._hasCalendarUiPermission;
    }

    get hasCalendarViewPreferencesPermission(): boolean {
        return this._hasCalendarViewPreferencesPermission;
    }

    get hasCalendarModifyPreferencesPermission(): boolean {
        return this._hasCalendarModifyPreferencesPermission;
    }

    get hasCalendarModifyOtherUsersPreferencesPermission(): boolean {
        return this._hasCalendarModifyOtherUsersPreferencesPermission;
    }
}

const usersPermissionsManager = new UsersPermissionsManager();
export default usersPermissionsManager;
