import L, { LeafletEvent, MapOptions, Renderer } from 'leaflet';
import { isCanvasSupported } from '../../utils';
import * as Leaflet from 'leaflet';

export enum RendererName {
    TERRITORIES = 'territories',
    TERRITORIES_ACTIVE = 'territoriesActive',
    GEO_LIBRARY = 'geoLibrary',
    GEO_LIBRARY_ACTIVE = 'geoLibraryActive',
    OTHER = 'other',
    OTHER_ACTIVE = 'other_active',
    PRINT_AREA = 'printArea',
}

interface ILeafletPaneManager extends Pick<LeafletPaneManager, keyof LeafletPaneManager> {}

class LeafletPaneManager {
    private readonly rendererMap: Map<string, Renderer> = new Map<string, Renderer>();
    private preferCanvas = true;

    resetPane() {
        this.rendererMap.clear();
    }

    initPane(map: Leaflet.Map) {
        if (this.rendererMap.size !== 0 || map === undefined) {
            return;
        }

        map.createPane(RendererName.TERRITORIES).style.zIndex = '201';
        map.createPane(RendererName.TERRITORIES_ACTIVE).style.zIndex = '202';
        map.createPane(RendererName.GEO_LIBRARY).style.zIndex = '204';
        map.createPane(RendererName.GEO_LIBRARY_ACTIVE).style.zIndex = '205';
        map.createPane(RendererName.OTHER).style.zIndex = '206';
        map.createPane(RendererName.OTHER_ACTIVE).style.zIndex = '207';
        map.createPane(RendererName.PRINT_AREA).style.zIndex = '208';

        this.preferCanvas = !map.options['printMap' as keyof MapOptions];
        const canvasSupported = isCanvasSupported();
        Object.values(RendererName).forEach((paneName) => {
            const renderer =
                this.preferCanvas && canvasSupported ? L.canvas({ pane: paneName }) : L.svg({ pane: paneName });
            this.rendererMap.set(paneName, renderer);
        });
    }

    getRendererByPaneName(map: Leaflet.Map, pane: RendererName): Renderer {
        this.initPane(map);
        return (
            this.rendererMap.get(pane) ??
            (this.preferCanvas && isCanvasSupported() ? L.canvas({ pane }) : L.svg({ pane }))
        );
    }

    setActivePane(map: Leaflet.Map, names: Array<RendererName>) {
        this.initPane(map);
        Object.values(RendererName).forEach((paneName) => {
            const pane = map.getPane(paneName);
            if (pane !== undefined) {
                pane.style.pointerEvents = names.includes(paneName) ? 'auto' : 'none';
            }
        });
    }
}

class Factory implements ILeafletPaneManager {
    private readonly managerMap: Map<L.Map, LeafletPaneManager> = new Map<L.Map, LeafletPaneManager>();

    private onUnloadMap = (event: LeafletEvent): void => {
        const { target: map }: { target: L.Map } = event;
        if (this.managerMap.has(map)) {
            this.managerMap.get(map)!.resetPane();
            this.managerMap.delete(map);
        }
    };

    private getManager(map: L.Map): LeafletPaneManager {
        if (!this.managerMap.has(map)) {
            this.managerMap.set(map, new LeafletPaneManager());
            map.on('unload', this.onUnloadMap);
        }

        return this.managerMap.get(map)!;
    }

    getRendererByPaneName(map: L.Map, pane: RendererName): Renderer {
        return this.getManager(map).getRendererByPaneName(map, pane);
    }

    initPane(map: L.Map): void {
        return this.getManager(map).initPane(map);
    }

    resetPane(): void {
        this.managerMap.forEach((manager) => {
            manager.resetPane();
        });
        this.managerMap.clear();
    }

    setActivePane(map: L.Map, names: Array<RendererName>): void {
        return this.getManager(map).setActivePane(map, names);
    }
}

export default new Factory();
