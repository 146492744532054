import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';
import isEqual from 'lodash/isEqual';
import hash from 'object-hash';
import { GEO_FIELDS } from '../references/geoFields';
import Timer, { TIMER_OPERATIONS } from '../handlers/TimerHandler';
import mapStateManagerFactory, { LAYER_GROUP_NONE } from './MapStateManagerFactory';
import { userManager } from './UserManager';
import { recordManager, withDataQueryColumnNames, withIdsFilter } from 'service/RecordManager';
import metadataManager from './MetadataManager';

const VIEW_TABLE = 'table';
const LIST_VIEW = 'listView';
const VIEW_LIST = 'list';
const VIEW_RECORD = 'record';

class EntityViewManager extends BackendService {
    constructor() {
        super();
        this.reset();

        dispatcher.subscribe(events.BASE_POINT, this, (point) => {
            this.basePoint = point;
        });

        dispatcher.subscribe(events.WS_CSV_EXPORT, this, ({ csvExportId }) => {
            // когда экспорт заканчивается
            for (const [key, value] of this.csvExportStates) {
                if (value === csvExportId) {
                    this.csvExportStates.delete(key);
                    break;
                }
            }
        });

        this.csvExportStates = new Map();
    }

    reset() {
        this.sources = null;
        this.basePoint = null;
        this.entityDataTableSorting = new Map();
        this.entityDataTableFilter = new Map();
        this.entityDataTableIds = new Map();
        this.entityDataTableWithData = new Map();
        this.entityMapFilter = new Map();
        this.combinedFilters = new Map();
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    getSources() {
        return this.sources;
    }

    isMapMode(entityId) {
        return this.getEntityMapFilters(entityId).length > 0;
    }

    getEntityTableSorting(entityId) {
        return this.entityDataTableSorting.get(entityId) || [];
    }

    getEntityTableFilters(entityId) {
        return this.entityDataTableFilter.get(entityId) || [];
    }

    /**
     * table view ids filter (currently only Route mode)
     * @param entityId
     * @return {undefined|string[]}
     */
    getEntityTableIds(entityId) {
        return this.entityDataTableIds.get(entityId);
    }

    /**
     * table view extra data (currently only built Route mode)
     * @param entityId
     * @return {undefined|WithDataQuery}
     */
    getEntityTableWithData(entityId) {
        return this.entityDataTableWithData.get(entityId);
    }

    getEntityMapFilters(entityId) {
        return this.entityMapFilter.get(entityId) || [];
    }

    getCombinedFilters(entityId) {
        return this.combinedFilters.get(entityId) || {};
    }

    setCombinedFilters(entityId, sections, filters, basePoint, withData, ids) {
        const combinedFilters = {
            sections,
            filters: filters.concat(withIdsFilter(ids)),
            basePoint,
        };
        if (withData !== undefined) {
            combinedFilters.withData = withData;
        }

        this.combinedFilters.set(entityId, combinedFilters);
    }

    static splitDataAndMapFilters(entityId, filters) {
        const dataFilters = [];
        const mapFilters = [];
        for (let filter of filters) {
            if (filter.hide) {
                continue;
            }

            if (filter.columnName === GEO_FIELDS.LAT || filter.columnName === GEO_FIELDS.LNG) {
                mapFilters.push(filter);
            } else {
                dataFilters.push(filter);
            }
        }

        return [dataFilters, mapFilters];
    }

    getEntity(id) {
        if (null === this.sources) {
            return null;
        }
        for (let source of this.sources) {
            const entities = source.entities;
            for (let entity of entities) {
                if (entity.id === id) {
                    return entity;
                }
            }
        }
        return null;
    }

    /**
     * @param {number=} accountId
     * @return {Promise<EntityViewLoadFrameDataResponse>}
     */
    loadFrameData(accountId) {
        const url = accountId ? reverse(apiRoutes.frameDataAccount, { accountId }) : reverse(apiRoutes.frameData);

        clearInterval(this.interval);
        this.interval = null;

        return this.requestApi(url, 'GET').then((response) => {
            this.sources = this._denormalizeDataSources(
                response.sources.sort((ds1, ds2) => ds1.isSystem - ds2.isSystem),
            );
            for (let payload of response.persistentMessages) {
                dispatcher.dispatch(events.WS_PERSISTENT_MESSAGE, payload);
            }
            this.interval = setInterval(this.dispatch, 60000);

            return response;
        });
    }

    getView(entityId, viewId) {
        const entity = this.getEntity(entityId);
        if (!entity) {
            return null;
        }
        for (const view of entity.views) {
            if (view.id === viewId) {
                return view;
            }
        }
        return null;
    }

    getLayer(entityId, layerId) {
        const entity = this.getEntity(entityId);
        if (!entity) {
            return null;
        }
        for (const layer of entity.layers) {
            if (layer.id === layerId) {
                return layer;
            }
        }
        return null;
    }

    dispatch = () => {
        if (!userManager.getCurrentUser()) {
            return;
        }
        const mapStateManager = mapStateManagerFactory.getManager();
        let c = 0;
        if (null === this.sources) {
            return;
        }
        for (let source of this.sources) {
            for (let entity of source.entities) {
                if (entity.recordsCount > 10000) {
                    continue;
                }
                if (mapStateManager.isEntityVisible(entity.id) === false) {
                    continue;
                }

                const nowDependentViews = new Set();
                for (let view of entity.views) {
                    if (view.hasNowVariable) {
                        nowDependentViews.add(view.id);
                    }
                }

                const entityLayerGroup = mapStateManager.getEntityLayerGroup(entity.id);
                if (entityLayerGroup === LAYER_GROUP_NONE) {
                    const viewId = mapStateManager.getEntityView(entity.id);
                    if (viewId && nowDependentViews.has(viewId)) {
                        dispatcher.dispatch(events.LAYER_NEED_TO_BE_RELOADED, { entityId: entity.id });
                        c++;
                    }
                    continue;
                }
                for (let layer of entity.layers) {
                    if (!mapStateManager.isLayerVisible(layer.id)) {
                        continue;
                    }
                    if (entityLayerGroup !== layer.layerGroupId) {
                        continue;
                    }

                    if (layer.hasNowVariable) {
                        dispatcher.dispatch(events.LAYER_NEED_TO_BE_RELOADED, {
                            entityId: entity.id,
                            layerId: layer.id,
                        });
                        c++;
                    } else {
                        const viewId = mapStateManager.getLayerView(entity.id, layer.id);
                        if (viewId && nowDependentViews.has(viewId)) {
                            dispatcher.dispatch(events.LAYER_NEED_TO_BE_RELOADED, {
                                entityId: entity.id,
                                layerId: layer.id,
                            });
                            c++;
                        }
                    }
                }
            }
        }

        if (c > 0) {
            dispatcher.dispatch(events.LAYERS_NEED_TO_BE_RELOADED);
        }
    };

    loadAllEntities() {
        const url = reverse(apiRoutes.frameData);

        return this.requestApi(url, 'GET').then((response) => {
            this.sources = response.sources;
            const entities = [];
            for (let source of response.sources) {
                for (let entity of source.entities) {
                    entities.push({
                        ...entity,
                        dataSource: { id: source.id, name: source.name },
                    });
                }
            }
            return entities;
        });
    }

    loadSourcesData(accountId) {
        const url = reverse(apiRoutes.account.dataSourcesDetailed, { accountId });

        return this.requestApi(url, 'GET');
    }

    getSettings(entityId, view = VIEW_TABLE) {
        return this.entities.get(view).get(entityId) || null;
    }

    loadSettings(entityId, forceUpdate = false, addMainFields = false, view = VIEW_TABLE) {
        if (null !== view && this.entities.get(view).has(entityId) && !forceUpdate) {
            return Promise.resolve(this.entities.get(view).get(entityId));
        }
        const url = reverse(apiRoutes.entity.settings, {
            entityId: entityId,
        });

        return this.requestApi(url, 'GET', { addMainFields, view }).then((structure) => {
            if (null !== view) {
                this.entities.get(view).set(entityId, structure);
            }
            return structure;
        });
    }

    loadData(
        entityId,
        sections,
        filters,
        sorting,
        offset,
        limit,
        addMainFields = false,
        view = VIEW_TABLE,
        parentTimer = null,
        withData = undefined,
        ids = undefined,
    ) {
        const timer =
            parentTimer instanceof Timer
                ? parentTimer.startChild(TIMER_OPERATIONS.EntityViewManager.loadData, {
                      entityId,
                      sections,
                      filters,
                      sorting,
                      offset,
                      limit,
                      addMainFields,
                      view,
                  })
                : null;
        const endTimer = timer ? () => timer.end() : () => {};

        const oldMapFilter = this.getEntityMapFilters(entityId);
        const oldTableFilter = this.getEntityTableFilters(entityId);
        const oldTableWithData = this.getEntityTableWithData(entityId);
        const oldTableIds = this.getEntityTableIds(entityId);

        if (view === VIEW_TABLE) {
            const [newDataTableFilter, newMapFilter] = this.constructor.splitDataAndMapFilters(entityId, filters);

            this.entityMapFilter.set(entityId, newMapFilter);
            this.entityDataTableFilter.set(entityId, newDataTableFilter);
            this.entityDataTableSorting.set(entityId, sorting);
            if (withData === undefined) {
                this.entityDataTableWithData.delete(entityId);
            } else {
                this.entityDataTableWithData.set(entityId, withData);
            }
            if (ids === undefined) {
                this.entityDataTableIds.delete(entityId);
            } else {
                this.entityDataTableIds.set(entityId, ids);
            }

            const isTableFilterChanged = !isEqual(oldTableFilter, newDataTableFilter);
            const isMapFilterChanged = !isEqual(oldMapFilter, newMapFilter);
            const isTableWithDataChanged = !isEqual(oldTableWithData, withData);
            const isTableIdsChanged = !isEqual(oldTableIds, ids);

            if (isTableFilterChanged || isMapFilterChanged || isTableWithDataChanged || isTableIdsChanged) {
                //console.log('EVENT_ENTITY_DATA_FILTER_CHANGED', entityId, isTableFilterChanged, isMapFilterChanged, oldTableFilter, oldMapFilter);
                dispatcher.dispatch(
                    events.EVENT_ENTITY_DATA_FILTER_CHANGED,
                    entityId,
                    isTableFilterChanged,
                    isMapFilterChanged,
                    isTableWithDataChanged,
                    isTableIdsChanged,
                    oldTableFilter,
                    oldMapFilter,
                    oldTableWithData,
                    oldTableIds,
                );
            }
        }

        const basePoint = this.basePoint;

        this.setCombinedFilters(entityId, sections, filters, basePoint, withData, ids);

        if (view === VIEW_TABLE) {
            dispatcher.dispatch(events.EVENT_REQUEST_TABLE_DATA, {
                entityId,
                sections,
                withData,
                filters,
                sorting,
                offset,
                limit,
                ids,
            });
        }

        // const urlParams = qsManager.getSearchParams();
        // const filterParams = filterParamsManager.getFilterParams();
        // const params = {sections, filters, sorting, offset, limit, basePoint, addMainFields, urlParams, filterParams, view};
        // return this
        //     .requestApi(url + '?_h=' + hash.MD5(params), 'POST', params)
        //     .then(response => {
        //         dispatcher.dispatch(
        //             events.CSV_EXPORT_STATE,
        //             entityId,
        //             response.csvExportId
        //         );
        //         return response;
        //     })
        //     .finally(endTimer);

        return metadataManager
            .requestEntityForUser(entityId)
            .then((entity) => {
                const croppedEntity = metadataManager.cropEntityFields(entity, view, addMainFields);
                const apiNames = croppedEntity.fields
                    .map((field) => field.apiName)
                    .concat(withDataQueryColumnNames(withData));
                const request = {
                    entityId,
                    sections,
                    filters: filters.concat(withIdsFilter(ids)),
                    sorting,
                    offset,
                    limit,
                    apiNames,
                    resultType: 'by_column_name',
                };
                if (withData) {
                    request.withData = withData;
                }
                return recordManager.getRecordsWithCount(request);
            })
            .finally(endTimer);
    }

    loadSourceGeocodingResults(accountId, dsId, filters, sorting, offset, limit) {
        const url = reverse(apiRoutes.account.dataSource.geocoding.index, {
            accountId,
            dsId,
        });

        return this.requestApi(url, 'GET', { filters, sorting, offset, limit });
    }

    getSourceGeocodingResultUseCases(accountId, dsId, resultId) {
        const url = reverse(apiRoutes.account.dataSource.geocoding.useCases, {
            accountId,
            dsId,
            resultId,
        });

        return this.requestApi(url, 'GET');
    }

    getGeocodingResult(entityId, recordId) {
        const url = reverse(apiRoutes.entity.point.georesult, {
            entityId,
            recordId: encodeURIComponent(recordId),
        });

        return this.requestApi(url, 'GET');
    }

    requestRegeocode(entityId, recordId) {
        const url = reverse(apiRoutes.entity.point.regeocode, { entityId, recordId: encodeURIComponent(recordId) });

        return this.requestApi(url, 'POST');
    }

    exportDataToCsv(entityId, params) {
        let url = reverse(apiRoutes.entity.recordsCsv, { entityId });

        return this.requestApi(url + '?_h=' + hash.MD5(params), 'POST', params).then((response) => {
            const key = entityId + ' ' + hash.MD5(params);
            this.csvExportStates.set(key, response.csvExportId);
            dispatcher.dispatch(events.CSV_EXPORT_STATE, entityId, response.csvExportId);
            return response;
        });
    }

    requestCsvState(entityId, params) {
        let url = reverse(apiRoutes.entity.recordsCsv, { entityId });
        const key = entityId + ' ' + hash.MD5(params);

        if (this.csvExportStates.has(key)) {
            return Promise.resolve({
                csvExportId: this.csvExportStates.get(key),
            });
        }

        return this.requestApi(url + '?_h=' + hash.MD5(params) + '&mode=check', 'POST', params).then((response) => {
            this.csvExportStates.set(key, response.csvExportId);
            return response;
        });
    }

    saveToLocalStorageSelectedEntityId(view, entityId) {
        const key = 'entity_view.' + view + '.selected';
        return window.localStorage.setItem(key, entityId);
    }

    getFromLocalStorageSelectedEntityId(view) {
        const key = 'entity_view.' + view + '.selected';
        const data = window.localStorage.getItem(key);
        if (data === null) {
            return null;
        }
        const id = parseInt(data);
        if (id === 0 || Number.isNaN(id)) {
            return null;
        }
        return id;
    }

    /**
     *
     * @param {EntityViewSourceResponse[]} dataSourcesResponse
     * @return {EntityViewSource[]}
     * @private
     */
    _denormalizeDataSources(dataSourcesResponse) {
        const dataSources = [];
        for (let ds of dataSourcesResponse) {
            dataSources.push({
                ...ds,
                createdAt: new Date(ds.createdAt),
            });
        }

        return dataSources;
    }
}

export default new EntityViewManager();
export { VIEW_TABLE, VIEW_LIST, LIST_VIEW, VIEW_RECORD };
