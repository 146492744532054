import React from 'react';
import './style.css';
import { Button, Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormDialog from '../FormDialog';
import { FormulaEditor } from '../FormulaEditor';
import ExpressionBuilderForm from './ExpressionBuilderForm';
import Hint from '../Hint';
import { CallContext } from '../utils/CallContext';

class ConditionEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openExpressionBuilder: false,
            openFormulaEditor: false,
        };
    }

    openExpressionBuilder = () => {
        this.setState({ openExpressionBuilder: true });
    };

    closeExpressionBuilder = () => {
        this.setState({ openExpressionBuilder: false });
    };

    handleExpressionBuilderSave = ({ expression, validationResult }) => {
        this.props.onSave({ expression, formula: validationResult, builderDisabled: false });
        this.closeExpressionBuilder();
    };

    openFormulaEditor = () => {
        this.setState({ openFormulaEditor: true });
    };

    closeFormulaEditor = () => {
        this.setState({ openFormulaEditor: false });
    };

    handleFormulaEditorSave = (formula) => {
        this.props.onSave({ expression: null, formula, builderDisabled: true });
        this.closeFormulaEditor();
    };

    clearFormula = () => {
        this.props.onSave({ expression: null, formula: null, builderDisabled: false });
    };

    renderCondition = () => {
        if (this.props.formula) {
            return <span>{this.props.formula}</span>;
        }
        return (
            <div style={{ textAlign: 'center' }} className="text-muted">
                {this.props.formulaPlaceholder || this.props.t('workflow_groups.form.condition.placeholder')}
            </div>
        );
    };

    renderBuilderButton = () => {
        const { t, builderDisabled, baseEntity } = this.props;
        if (builderDisabled || !baseEntity) {
            const tooltip = builderDisabled
                ? t('workflow_groups.form.condition.builder_disabled')
                : t('workflow_groups.form.condition.select_base_entity');
            return (
                <Tooltip title={tooltip}>
                    <span>
                        <Button
                            size="small"
                            color="primary"
                            disabled
                            data-testid="workflow_groups.form.condition.edit_in_builder"
                        >
                            {t('workflow_groups.form.condition.edit_in_builder')}
                        </Button>
                    </span>
                </Tooltip>
            );
        }
        return (
            <Button
                size="small"
                color="primary"
                onClick={this.openExpressionBuilder}
                data-testid="workflow_groups.form.condition.edit_in_builder"
            >
                {t('workflow_groups.form.condition.edit_in_builder')}
            </Button>
        );
    };

    render() {
        const { openExpressionBuilder, openFormulaEditor } = this.state;
        const {
            t,
            baseEntity,
            targetEntity,
            expression,
            formula,
            hint,
            conditionLabel,
            ExpressionBuilderFormProps,
            editorDisabled,
            action,
        } = this.props;
        const overrideBaseEntitiesIds = [];
        if (baseEntity) {
            overrideBaseEntitiesIds.push(baseEntity.id);
        }
        const overrideTargetEntitiesIds = [];
        if (targetEntity) {
            overrideTargetEntitiesIds.push(targetEntity.id);
        }
        return (
            <React.Fragment>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item xs container spacing={1} alignItems="center">
                        <Grid item>
                            <b>{conditionLabel || t('workflow_groups.form.condition')}</b>
                            {hint && <Hint iProps={{ style: { marginLeft: 8 } }}>{hint}</Hint>}
                        </Grid>
                        <Grid item>{this.renderBuilderButton()}</Grid>
                        {!editorDisabled && (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.openFormulaEditor}
                                    disabled={!baseEntity}
                                    data-testid="workflow_groups.form.condition.edit_formula"
                                >
                                    {t('workflow_groups.form.condition.edit_formula')}
                                </Button>
                            </Grid>
                        )}

                        <Grid item>
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.clearFormula}
                                disabled={!baseEntity}
                                data-testid="workflow_groups.form.condition.clear"
                            >
                                {t('workflow_groups.form.condition.clear')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{ padding: 15, background: '#fafafa', borderRadius: 10 }}>{this.renderCondition()}</div>
                {openExpressionBuilder && (
                    <FormDialog
                        title={'Expression builder'}
                        onCancel={this.closeExpressionBuilder}
                        onSave={this.handleExpressionBuilderSave}
                        maxWidth="lg"
                        fullWidth
                    >
                        <ExpressionBuilderForm
                            expression={expression || []}
                            addSpecialSection
                            addOldEntitySection
                            addUserSection
                            callContext={action.callContext}
                            overrideBaseEntitiesIds={overrideBaseEntitiesIds}
                            overrideTargetEntitiesIds={overrideTargetEntitiesIds}
                            {...ExpressionBuilderFormProps}
                        />
                    </FormDialog>
                )}
                {!editorDisabled && (
                    <FormulaEditor
                        open={openFormulaEditor}
                        onSave={this.handleFormulaEditorSave}
                        onCancel={this.closeFormulaEditor}
                        value={formula}
                        callContext={action.callContext}
                    />
                )}
            </React.Fragment>
        );
    }
}

ConditionEditor.propTypes = {
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    builderDisabled: PropTypes.bool,
    editorDisabled: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    ExpressionBuilderFormProps: PropTypes.object,
    expression: PropTypes.array,
    formula: PropTypes.string,
    baseEntity: PropTypes.object,
    targetEntity: PropTypes.object,
    conditionLabel: PropTypes.string,
    formulaPlaceholder: PropTypes.string,
    hint: PropTypes.string,
};

ConditionEditor.defaultProps = {
    builderDisabled: false,
    editorDisabled: false,
};

export default withTranslation('translations', { withRef: true })(ConditionEditor);
