import AbstractEvent from './AbstractEvent';
import AbstractAutomation from './AbstractAutomation';

export interface RunButtonLabels {
    buttonLabel: string;
}

class RunButton extends AbstractAutomation {
    private readonly buttonId: number;
    private readonly itemIds: Array<number>;
    private readonly forAllItems: boolean;
    private readonly filters: object;
    private readonly recordsCount: number | null;
    private readonly labels: RunButtonLabels;

    constructor(
        accountId: number,
        buttonId: number,
        entityId: number,
        itemIds: Array<number>,
        forAllItems: boolean,
        additionalContextData: object | null,
        contextUlid: string | null,
        filters: object,
        recordsCount: number | null,
        labels: RunButtonLabels,
    ) {
        super(accountId, entityId, additionalContextData, contextUlid);

        this.buttonId = buttonId;
        this.itemIds = itemIds;
        this.forAllItems = forAllItems;
        this.filters = filters;
        this.recordsCount = recordsCount;
        this.labels = labels;
    }

    public getButtonId(): number {
        return this.buttonId;
    }

    public getItemIds(): Array<number> {
        return this.itemIds;
    }

    public getForAllItems(): boolean {
        return this.forAllItems;
    }

    public getFilters(): object {
        return this.filters;
    }

    public getRecordsCount(): number | null {
        return this.recordsCount;
    }

    public getLabels(): RunButtonLabels {
        return this.labels;
    }

    protected toStorageSpecific(): object {
        return {
            ...super.toStorageSpecific(),
            buttonId: this.getButtonId(),
            itemIds: this.getItemIds(),
            forAllItems: this.getForAllItems(),
            filters: this.getFilters(),
            recordsCount: this.getRecordsCount(),
            labels: this.getLabels(),
        };
    }

    public static fromStorage(data: any): RunButton {
        // @ts-ignore
        return AbstractEvent.fromStorageBase(
            new RunButton(
                data.accountId,
                data.buttonId,
                data.entityId,
                data.itemIds,
                data.forAllItems,
                data.additionalContextData,
                data.contextUlid,
                data.filters,
                data.recordsCount,
                data.labels,
            ),
            data,
        );
    }
}

export default RunButton;
