import Converter from 'service/converter';
import { Routing } from 'interfaces';

class VehicleProfileConverter {
    static convertToImperialVehicleProfile(vehicleProfile: Routing.Route.VehicleProfile): Routing.Route.VehicleProfile {
        if (vehicleProfile.length) {
            vehicleProfile.length = parseFloat(Converter.mToFt(vehicleProfile.length).toFixed(2));
        }
        if (vehicleProfile.height) {
            vehicleProfile.height = parseFloat(Converter.mToFt(vehicleProfile.height).toFixed(2));
        }
        if (vehicleProfile.width) {
            vehicleProfile.width = parseFloat(Converter.mToFt(vehicleProfile.width).toFixed(2));
        }
        if (vehicleProfile.limitedWeight) {
            vehicleProfile.limitedWeight = parseFloat(Converter.tonToLbs(vehicleProfile.limitedWeight).toFixed(2));
        }
        if (vehicleProfile.weightPerAxle) {
            vehicleProfile.weightPerAxle = parseFloat(Converter.tonToLbs(vehicleProfile.weightPerAxle).toFixed(2));
        }
        if (vehicleProfile.weightsPerSingleAxleGroup) {
            vehicleProfile.weightsPerSingleAxleGroup = parseFloat(
                Converter.tonToLbs(vehicleProfile.weightsPerSingleAxleGroup).toFixed(2),
            );
        }
        if (vehicleProfile.weightsPerTandemAxleGroup) {
            vehicleProfile.weightsPerTandemAxleGroup = parseFloat(
                Converter.tonToLbs(vehicleProfile.weightsPerTandemAxleGroup).toFixed(2),
            );
        }
        return vehicleProfile;
    }

    static convertToMetricVehicleProfile(vehicleProfile: Routing.Route.VehicleProfile): Routing.Route.VehicleProfile {
        if (vehicleProfile.length) {
            vehicleProfile.length = parseFloat(Converter.ftToM(vehicleProfile.length).toFixed(2));
        }
        if (vehicleProfile.height) {
            vehicleProfile.height = parseFloat(Converter.ftToM(vehicleProfile.height).toFixed(2));
        }
        if (vehicleProfile.width) {
            vehicleProfile.width = parseFloat(Converter.ftToM(vehicleProfile.width).toFixed(2));
        }
        if (vehicleProfile.limitedWeight) {
            vehicleProfile.limitedWeight = parseFloat(Converter.lbsToTon(vehicleProfile.limitedWeight).toFixed(2));
        }
        if (vehicleProfile.weightPerAxle) {
            vehicleProfile.weightPerAxle = parseFloat(Converter.lbsToTon(vehicleProfile.weightPerAxle).toFixed(2));
        }
        if (vehicleProfile.weightsPerAxleGroup) {
            vehicleProfile.weightsPerAxleGroup.single = parseFloat(
                Converter.lbsToTon(vehicleProfile.weightsPerAxleGroup.single).toFixed(2),
            );
            vehicleProfile.weightsPerAxleGroup.tandem = parseFloat(
                Converter.lbsToTon(vehicleProfile.weightsPerAxleGroup.tandem).toFixed(2),
            );
        }
        return vehicleProfile;
    }
}

export default VehicleProfileConverter;
