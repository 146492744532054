import { action, computed, makeObservable, observable } from 'mobx';
import { Routing, User } from 'interfaces';
import { UserRecentRouteData, UsersTodayStartLocationAndTime } from 'components/RoutingTodayUsersStartModal';
import {
    OBJECTIVE_MINIMIZE_DRIVING_TIME,
    OBJECTIVE_MINIMIZE_VEHICLES,
    OBJECTIVE_MINIMIZE_VEHICLES_BALANCE,
} from 'components/constants';
import { routeManager } from '../index';
import cloneDeep from 'lodash/cloneDeep';
import { addSeconds, setHours, setMinutes, setSeconds } from 'date-fns';
import { userManager } from '../../UserManager';
import dispatcher from '../../dispatcher';
import events from '../../../events';
import { zonedTimeToUtc } from 'date-fns-tz';
import { SubscriptionPlanTier, SubscriptionStatus } from '../../types';
import { ActivityType, RoundTimeStep, UserTodayRoutes } from '../../../interfaces/routing/route';
import usersPermissionsManager from '../../Permissions/UsersPermissionsManager';
import WorkSchedule from '../../WorkSchedule';
import DateHelper from '../../Date/DateHelper';
import isEqual from 'lodash/isEqual';

const defaultRoutingConfigValues: Readonly<Routing.Route.DesignConfig> = {
    addCalendarEventsToRoute: false,
    includeDraftEvents: true,
    roundStartTimes: false,
    roundStartTimesValue: RoundTimeStep.STEP_15,
    roundBreakStartTime: false,
    ignoreConstraints: false,
    replaceRoutes: false,
    daysPeriod: 1,
    usersIds: [],
    objective: OBJECTIVE_MINIMIZE_DRIVING_TIME,
    todayConfig: [],
    travelMode: Routing.TravelMode.SessionTravelMode.DEFAULT,
    useTraffic: true,
    vehicleProfile: {},
    optimal: true,
    continuousMode: false,
    maxOvertime: null,
    splitJobsLongerThan: null,
};

type RouteDesignConfigManagerKeys = '_config' | '_todayConfigLoading';

class RouteDesignConfigManager {
    private _config: Routing.Route.DesignConfig = { ...defaultRoutingConfigValues };
    private user: User.User | null = null;
    private _todayConfigLoading = false;

    constructor() {
        makeObservable<RouteDesignConfigManager, RouteDesignConfigManagerKeys>(this, {
            _config: observable,
            _todayConfigLoading: observable,

            replaceRoutes: computed,
            optimal: computed,
            config: computed,
            todayConfig: computed,
            addCalendarEventsToRoute: computed,
            includeDraftEvents: computed,
            roundStartTimes: computed,
            roundStartTimesValue: computed,
            roundBreakStartTime: computed,
            ignoreConstraints: computed,
            daysPeriod: computed,
            objective: computed,
            usersIds: computed,
            travelMode: computed,
            useTraffic: computed,
            vehicleProfile: computed,
            todayConfigLoading: computed,

            setReplaceRoutes: action,
            setOptimal: action,
            setConfig: action,
            setTodayConfig: action,
            setTodayConfigLoading: action,
            setAddCalendarEventsToRoute: action,
            setRoundStartTimes: action,
            setRoundStartTimesValue: action,
            setRoundBreakStartTime: action,
            setIgnoreConstraints: action,
            setDaysPeriod: action,
            setObjective: action,
            setUsersIds: action,
            setTravelMode: action,
            setUseTraffic: action,
            setVehicleProfile: action,
            reset: action,
        });

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, this.onCurrentUserUpdate.bind(this));
        dispatcher.subscribe(events.ACCOUNT_UPDATED, this, this.onCurrentAccountUpdate.bind(this));
    }

    private onCurrentUserUpdate = () => {
        const currentUser = userManager.getCurrentUser() || null;
        if (!currentUser) {
            return;
        }
        if (!this.user || this.user.id !== currentUser.id) {
            this.setUsersIds([currentUser.id]);
        }
        this.user = currentUser;
    };

    private onCurrentAccountUpdate = () => {
        const account = userManager.getCurrentAccount();
        if (!account) {
            return;
        }
        if (
            account.subscription.plan.tier === SubscriptionPlanTier.ESSENTIAL &&
            account.subscription.status === SubscriptionStatus.ACTIVE
        ) {
            this.setIgnoreConstraints(true);
        }
    };

    setReplaceRoutes(replaceRoutes: boolean): void {
        this._config.replaceRoutes = replaceRoutes;
    }

    get replaceRoutes(): boolean {
        return this._config.replaceRoutes;
    }

    setOptimal(optimal: boolean): void {
        this._config.optimal = optimal;
    }

    get optimal(): boolean {
        return this._config.optimal;
    }

    setConfig(config: Routing.Route.DesignConfig): void {
        this._config = { ...config, todayConfig: [] }; // remove todayConfig as we create it in routeDesignConfigManager.loadTodayConfig method
    }

    get config(): Routing.Route.DesignConfig {
        return this._config;
    }

    setTodayConfig(todayConfig: UsersTodayStartLocationAndTime[]): void {
        this._config.todayConfig = todayConfig;
    }

    get todayConfig(): UsersTodayStartLocationAndTime[] {
        return this._config.todayConfig;
    }

    setTodayConfigLoading(todayConfigLoading: boolean): void {
        this._todayConfigLoading = todayConfigLoading;
    }

    get todayConfigLoading(): boolean {
        return this._todayConfigLoading;
    }

    setAddCalendarEventsToRoute(addCalendarEventsToRoute: boolean): void {
        this._config.addCalendarEventsToRoute = addCalendarEventsToRoute;
    }

    get addCalendarEventsToRoute(): boolean {
        return this._config.addCalendarEventsToRoute && usersPermissionsManager.hasCalendarUiPermission;
    }

    setIncludeDraftEvents(includeDraftEvents: boolean): void {
        this._config.includeDraftEvents = includeDraftEvents;
    }

    get includeDraftEvents(): boolean {
        return this._config.includeDraftEvents;
    }

    setRoundStartTimes(roundStartTimes: boolean): void {
        this._config.roundStartTimes = roundStartTimes;
    }

    get roundStartTimes(): boolean {
        return this._config.roundStartTimes;
    }

    setRoundStartTimesValue(roundStartTimesValue: RoundTimeStep): void {
        this._config.roundStartTimesValue = roundStartTimesValue;
    }

    get roundStartTimesValue(): RoundTimeStep {
        return this._config.roundStartTimesValue;
    }

    setRoundBreakStartTime(roundBreakStartTime: boolean): void {
        this._config.roundBreakStartTime = roundBreakStartTime;
    }

    get roundBreakStartTime(): boolean {
        return this._config.roundBreakStartTime;
    }

    setIgnoreConstraints(ignoreConstraints: boolean): void {
        this._config.ignoreConstraints = ignoreConstraints;
    }

    get ignoreConstraints(): boolean {
        return this._config.ignoreConstraints;
    }

    setDaysPeriod(daysPeriod: number): void {
        if (this.usersIds.length === 1 && daysPeriod === 1 && this.objective !== OBJECTIVE_MINIMIZE_DRIVING_TIME) {
            this.setObjective(OBJECTIVE_MINIMIZE_DRIVING_TIME);
        }
        this._config.daysPeriod = daysPeriod;
        if (daysPeriod > 1) {
            this.setIgnoreConstraints(false);
        } else {
            this.setContinuousMode(false);
        }
    }

    get daysPeriod(): number {
        return this._config.daysPeriod;
    }

    setObjective(objective: string): void {
        this._config.objective = objective;
    }

    get objective(): string {
        return this._config.objective;
    }

    setUsersIds(usersIds: number[]): void {
        if (isEqual(this._config.usersIds, usersIds)) {
            return;
        }

        if (usersIds.length === 1 && this.daysPeriod === 1 && this.objective !== OBJECTIVE_MINIMIZE_DRIVING_TIME) {
            this.setObjective(OBJECTIVE_MINIMIZE_DRIVING_TIME);
        }
        this._config.usersIds = usersIds;
        if (
            usersIds.length <= 1 &&
            [OBJECTIVE_MINIMIZE_VEHICLES, OBJECTIVE_MINIMIZE_VEHICLES_BALANCE].includes(this.objective)
        ) {
            this.setObjective(OBJECTIVE_MINIMIZE_DRIVING_TIME);
        }
    }

    get usersIds(): number[] {
        return this._config.usersIds;
    }

    setTravelMode(type: Routing.TravelMode.TravelMode): void {
        this._config.travelMode = type;
    }

    get travelMode(): Routing.TravelMode.TravelMode {
        return this._config.travelMode;
    }

    setUseTraffic(useTraffic: boolean): void {
        this._config.useTraffic = useTraffic;
    }

    get useTraffic(): boolean {
        return this._config.useTraffic;
    }

    setVehicleProfile(vehicleProfile: Routing.Route.VehicleProfile): void {
        this._config.vehicleProfile = vehicleProfile;
    }

    get vehicleProfile(): Routing.Route.VehicleProfile {
        return this._config.vehicleProfile;
    }

    setContinuousMode(continuousMode: boolean): void {
        this._config.continuousMode = continuousMode;
    }

    get continuousMode(): boolean {
        return this._config.continuousMode;
    }

    setSplitJobsLongerThan(splitJobsLongerThan: number | null): void {
        this._config.splitJobsLongerThan = splitJobsLongerThan;
    }

    hasSplitJobsLongerThan(): boolean {
        return this._config.splitJobsLongerThan !== null && this._config.splitJobsLongerThan !== undefined;
    }

    get splitJobsLongerThan(): number | null {
        return this._config.splitJobsLongerThan;
    }

    setMaxOvertime(maxOvertime: number | null): void {
        this._config.maxOvertime = maxOvertime;
    }

    hasMaxOvertime(): boolean {
        return this._config.maxOvertime !== null && this._config.maxOvertime !== undefined;
    }

    get maxOvertime(): number | null {
        return this._config.maxOvertime;
    }

    reset(): void {
        this._config = { ...defaultRoutingConfigValues };
    }

    public loadTodayConfig(rawTodayConfigMap: Map<number, UsersTodayStartLocationAndTime>): void {
        const hasBrowseUsersPermission = usersPermissionsManager.hasBrowseUsersPermission;
        const hasUsersObjectAndLocationFieldsPermission =
            usersPermissionsManager.hasUsersObjectAndLocationFieldsPermission;
        const hasUsersPermission = hasBrowseUsersPermission && hasUsersObjectAndLocationFieldsPermission;

        this.setTodayConfigLoading(true);
        const currentUser = userManager.getCurrentUser();
        routeManager
            .getUsersTodayRoutes(userManager.getCurrentAccount().id)
            .then((usersTodayRoutesResponse) => {
                let users: UsersTodayStartLocationAndTime[] = [];

                for (const userID in usersTodayRoutesResponse) {
                    const userData = usersTodayRoutesResponse[userID];
                    // 3 тут прерывается и не добавляет пользователя
                    if (!hasUsersPermission && userData.id !== currentUser.id) {
                        continue;
                    }

                    let user: UsersTodayStartLocationAndTime;
                    if (rawTodayConfigMap.has(userData.id)) {
                        // 4 вот тут идет присвоение пользователя и тут теряется defaultLocation
                        user = rawTodayConfigMap.get(userData.id) as UsersTodayStartLocationAndTime;
                    } else {
                        user = {
                            id: userData.id,
                            name: userData.name,
                            lastLocation: null,
                            defaultLocation: userData.defaultLocation,
                            available: !!userData.workDay,
                            enabled: !!userData.workDay,
                            workDay: null,
                            recentRoutes: [],
                        } as UsersTodayStartLocationAndTime;
                    }

                    users.push(RouteDesignConfigManager.denormalizeUserConfig(user, userData));
                }

                this.setTodayConfig(users);
            })
            .then(() => {
                if (this.usersIds.length === 0) {
                    return;
                }
                userManager.getAccountUsers(currentUser.accountId).then((users: User.User[]) => {
                    users = users.filter((user) => this.usersIds.includes(user.id));
                    this.updateWorkDaysInTodayConfigForUsers(users);
                });
            })
            .finally(() => {
                this.setTodayConfigLoading(false);
            });
    }

    private static denormalizeUserConfig(
        user: UsersTodayStartLocationAndTime,
        userData: UserTodayRoutes,
    ): UsersTodayStartLocationAndTime {
        const now = new Date();

        const recentRoutesData: UserRecentRouteData[] = [];
        userData.routes.forEach((route: Routing.Route.Route) => {
            if (!route.totalDuration) {
                return;
            }

            const routeData: UserRecentRouteData = {
                totalDuration: route.totalDuration,
                startAt: route.dateStartAt,
                endAt: addSeconds(route.dateStartAt, route.totalDuration),
                lastLocation: null,
            };

            if (route.activities.length) {
                route.activities.forEach((activity) => {
                    if (activity.type !== ActivityType.END) {
                        routeData.lastLocation = activity;
                        return;
                    }
                });
            }

            recentRoutesData.push(routeData);
        });

        if (userData.workDay) {
            user.workDay = {
                startAt: zonedTimeToUtc(
                    setSeconds(
                        setMinutes(setHours(now, userData.workDay.start.hours), userData.workDay.start.minutes),
                        0,
                    ),
                    userData.timezone,
                ),
                endAt: zonedTimeToUtc(
                    setSeconds(setMinutes(setHours(now, userData.workDay.end.hours), userData.workDay.end.minutes), 0),
                    userData.timezone,
                ),
            };
            user.available = true;
        }

        if (userData.lastLocation?.address?.lat && userData.lastLocation?.address?.lng && userData.lastLocation?.time) {
            user.lastLocation = {
                time: new Date(userData.lastLocation.time),
                address: userData.lastLocation.address,
            };
        } else {
            user.lastLocation = null;
        }

        user.recentRoutes = recentRoutesData;

        if (user.customStartTime) {
            user.customStartTime = new Date(user.customStartTime);
        }

        return user;
    }

    public updateWorkDaysInTodayConfigForUsers(users: User.User[]) {
        const currentUser = userManager.getCurrentUser();
        if (!currentUser) {
            return;
        }

        const todayConfig = cloneDeep(this.todayConfig);
        for (const i in todayConfig) {
            const user = users.find((user) => user.id === todayConfig[i].id);
            if (!user) {
                continue;
            }
            const dayData = WorkSchedule.getTodayWorkingHours(user);
            let workDay;
            if (dayData) {
                workDay = {
                    startAt: DateHelper.createFromDeviceDate(
                        setSeconds(setMinutes(setHours(new Date(), dayData.start.hours), dayData.start.minutes), 0),
                    ).getDate(),
                    endAt: DateHelper.createFromDeviceDate(
                        setSeconds(setMinutes(setHours(new Date(), dayData.end.hours), dayData.end.minutes), 0),
                    ).getDate(),
                };
            } else {
                workDay = null;
            }

            if (!todayConfig[i].available) {
                todayConfig[i].enabled = !!workDay;
            }
            todayConfig[i].workDay = workDay;
            todayConfig[i].available = !!workDay;
        }

        this.setTodayConfig(todayConfig);
    }
}

export default RouteDesignConfigManager;
