import { capitalize, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

export type LabelProps = PropsWithChildren<{
    color?: 'neutral' | 'positive' | 'negative' | 'blue' | 'yellow' | 'orange';
    size?: 's' | 'm';
}>;

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            display: 'inline-flex',
            minWidth: 'max-content',
            borderRadius: 20,
        },
        sizeS: {
            padding: '0 6px',
        },
        sizeM: {
            padding: '2px 10px',
        },
        colorNeutral: {
            background: theme.palette.background.neutral,
            color: theme.palette.text.secondary,
        },
        colorPositive: {
            background: theme.palette.background.positive,
            color: theme.palette.text.positive,
        },
        colorNegative: {
            background: theme.palette.background.negative,
            color: theme.palette.text.negative,
        },
        colorBlue: {
            background: theme.palette.background.focuse,
            color: theme.palette.colors.blue10,
        },
        colorYellow: {
            background: theme.palette.background.yell,
            color: theme.palette.colors.yellow,
        },
        colorOrange: {
            background: theme.palette.background.orange,
            color: theme.palette.colors.orange,
        },
    }),
    { name: 'UI-Label' },
);

export const Label: FC<LabelProps> = (props: LabelProps) => {
    const classes = useStyles();
    const { color = 'neutral', size = 's', children } = props;

    return (
        <span
            className={clsx([
                classes!.root,
                classes['color'.concat(capitalize(color)) as keyof typeof classes],
                classes['size'.concat(capitalize(size)) as keyof typeof classes],
            ])}
        >
            {children}
        </span>
    );
};
