import ProcessorInterface from './ProcessorInterface';
import AddRecord from '../Events/AddRecord';
import EntityManagerFactory from '../../EntityManager';
import AbstractProcessor from './AbstractProcessor';
import { logDebug } from '../../../utils';

class AddRecordProcessor extends AbstractProcessor implements ProcessorInterface {
    public process(event: AddRecord): void {
        const entityManager = EntityManagerFactory.getManager(event.getAccountId(), event.getDataSourceId());
        entityManager
            .startCreateRecord(
                event.getEntityId(),
                event.getFields(),
                event.getLatitude(),
                event.getLongitude(),
                event.toBackend(),
            )
            .catch((error: object) => {
                this.failEvent(event, error);
            });
    }

    public onComplete(event: AddRecord): void {
        logDebug(event);
    }
}

export default AddRecordProcessor;
