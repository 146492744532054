import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ColorGenerator } from '../ColorGenerator';
import { LineCapShape } from 'leaflet';
import { LineStyle, LineStyleGenerator } from '../LineStyleGenerator';
import { RoutePath } from '../../components/types';
import hash from 'object-hash';
import { DATE_FORMAT_DATEFNS, DATETIME_FORMAT_DATEFNS, validateGeoPoint } from '../../utils';
import { userManager } from '../UserManager';
import addMinutes from 'date-fns/addMinutes';
import { Route, RouteStatus } from '../../interfaces/routing/route';
import { Paths, PathsRequestInitiator, WSRoutePathsBuiltResponse } from 'interfaces/ws/paths';
import dispatcher from '../dispatcher';
import events from '../../events';
import {
    enqueueSnackbarService,
    routeReportLoadedSessionRouteManager,
    routingSessionManager,
    tripModeManager,
} from '../MapPage';
import { Routing, User } from '../../interfaces';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { format } from 'date-fns';
import { CalendarEventPathsViewerSubscriber } from 'service/Calendar/CalendarEventPathsViewerSubscriber';

type RouteId = string;
type UserId = number;
type DateYMD = string;
type DateTimestamp = number;
export type RouteViewerPaths = Map<UserId, Map<DateYMD, Array<RoutePath>>>;

export enum STATUS_FILTER_VALUES {
    ALL = 'all',
    LIVE = 'live',
    DRAFT = 'draft',
    CURRENT_ROUTE = 'current_route',
}

type RequestPathsPayloadType = {
    requestHash: string | null;
    users: Array<UserId>;
    dateStart: string | null;
    dateEnd: string | null;
    initiator: PathsRequestInitiator;
    calendar: boolean;
    calendarSelection?: { [userId: number]: DateYMD[] };
};

export type StyleConfig = {
    color: string;
    dashArray: string;
    lineCap: LineCapShape;
    width: number;
    opacity: number;
    inactiveOpacity: number;
};

type StylesConfig = {
    pathsStyles: {
        [userId: number]: {
            [date: string]: StyleConfig;
        };
    };
    breakColor: string;
};
type RouteViewerManagerKeys =
    | '_paths'
    | '_styles'
    | '_currentRequestId'
    | '_arePathsShowing'
    | '_statusFilter'
    | '_pathsBuffer'
    | '_drawPathsInitiator'
    | '_multiselect'
    | '_loading'
    | '_isOpen'
    | '_isLegendOpen'
    | '_selectedUsers'
    | '_selectedDatetimeIntervalStart'
    | '_selectedDatetimeIntervalEnd'
    | '_mode'
    | '_calendarSelection'
    | 'open'
    | 'close'
    | 'openLegend'
    | 'closeLegend'
    | 'addPathsToBuffer'
    | 'addCalendarSelection'
    | 'removeCalendarSelection'
    | 'reactToCalendarSelectionChange'
    | 'clearCalendarSelection';

export type RouteViewerMode = 'route' | 'calendar';
export const ROUTE_VIEWER_MODES: Array<RouteViewerMode> = ['route', 'calendar'];

export type RouteFilterSource = 'trip_mode';

const STYLE_COLOR_BREAK = '#FFFFE0';

class RouteViewerManager extends BackendService {
    private calendarEventsSubscriber: CalendarEventPathsViewerSubscriber = new CalendarEventPathsViewerSubscriber();

    private _paths: RouteViewerPaths = new Map();
    private _styles: StylesConfig = { pathsStyles: {}, breakColor: STYLE_COLOR_BREAK };
    private _currentRequestId: string | null = null;
    private _arePathsShowing: boolean = false;
    private _loading: boolean = false;

    private _isOpen: boolean = false;
    private _isLegendOpen: boolean = false;

    private _selectedUsers: User.User[] = [];
    private _selectedDatetimeIntervalStart: string | null = null;
    private _selectedDatetimeIntervalEnd: string | null = null;
    private _multiselect: boolean = false;
    private _mode: RouteViewerMode = 'route';
    private _statusFilter: string = STATUS_FILTER_VALUES.ALL;
    private _drawPathsInitiator = PathsRequestInitiator.ROUTE_REPORT;
    private _pathsBuffer: Paths | null = null;
    private _calendarSelection: Map<UserId, Array<DateTimestamp>> = new Map();

    private routesFilter: Map<RouteFilterSource, Array<RouteId>> = new Map();

    constructor() {
        super();
        makeObservable<RouteViewerManager, RouteViewerManagerKeys>(this, {
            _paths: observable,
            paths: computed,

            _styles: observable,
            styles: computed,

            _currentRequestId: observable,
            currentRequestId: computed,

            _arePathsShowing: observable,
            arePathsShowing: computed,

            _loading: observable,
            loading: computed,

            _statusFilter: observable,
            statusFilter: computed,

            _multiselect: observable,
            multiselect: computed,

            _pathsBuffer: observable,
            pathsBuffer: computed,

            _drawPathsInitiator: observable,
            drawPathsInitiator: computed,

            shouldShowLegend: observable,

            _isOpen: observable,
            isOpen: computed,
            open: action,
            close: action,

            _isLegendOpen: observable,
            isLegendOpen: computed,
            openLegend: action,
            closeLegend: action,
            addPathsToBuffer: action,

            _selectedUsers: observable,
            selectedUsers: computed,

            _selectedDatetimeIntervalStart: observable,
            selectedDatetimeIntervalStart: computed,

            _selectedDatetimeIntervalEnd: observable,
            selectedDatetimeIntervalEnd: computed,

            _mode: observable,
            mode: computed,

            _calendarSelection: observable,
            addCalendarSelection: action,
            removeCalendarSelection: action,
            calendarSelection: computed,
            reactToCalendarSelectionChange: action,
            clearCalendarSelection: action,
        });

        dispatcher.subscribe(events.WS_ROUTE_PATHS_BUILT, this, (message: WSRoutePathsBuiltResponse) => {
            if (this.currentRequestId !== message.requestId) {
                return;
            }
            this.addPathsToBuffer(message);
            if (message.error) {
                this.handleMessageError(message);
            }
            if (message.isFinal) {
                this.processNewPaths(message.initiator);
            }
        });

        this.calendarEventsSubscriber.subscribe();
    }

    get currentRequestId(): string | null {
        return this._currentRequestId;
    }

    set currentRequestId(currentRequestHash: string | null) {
        this._currentRequestId = currentRequestHash;
    }

    get styles(): StylesConfig {
        return this._styles;
    }

    set styles(styles: StylesConfig) {
        this._styles = styles;
    }

    get arePathsShowing(): boolean {
        return this._arePathsShowing;
    }

    get paths(): RouteViewerPaths {
        return this._paths;
    }

    set paths(pathsMap: RouteViewerPaths) {
        this._paths = pathsMap;
    }

    get pathsBuffer(): Paths | null {
        return this._pathsBuffer;
    }

    set pathsBuffer(paths: Paths | null) {
        this._pathsBuffer = paths;
    }

    get drawPathsInitiator(): PathsRequestInitiator {
        return this._drawPathsInitiator;
    }

    get selectedUsers(): User.User[] {
        return this._selectedUsers;
    }

    set selectedUsers(value: User.User[]) {
        this._selectedUsers = value;
    }

    get selectedDatetimeIntervalStart(): DateYMD | null {
        return this._selectedDatetimeIntervalStart;
    }

    set selectedDatetimeIntervalStart(value: DateYMD | null) {
        this._selectedDatetimeIntervalStart = value;
    }

    get selectedDatetimeIntervalEnd(): DateYMD | null {
        return this._selectedDatetimeIntervalEnd;
    }

    set selectedDatetimeIntervalEnd(value: DateYMD | null) {
        this._selectedDatetimeIntervalEnd = value;
    }

    get statusFilter(): string {
        return this._statusFilter;
    }

    set statusFilter(value: string) {
        this._statusFilter = value;
    }

    set arePathsShowing(enabled: boolean) {
        this._arePathsShowing = enabled;
    }

    get multiselect(): boolean {
        return this._multiselect;
    }

    set multiselect(value: boolean) {
        this._multiselect = value;
    }

    get mode(): RouteViewerMode {
        return this._mode;
    }

    set mode(value: RouteViewerMode) {
        this._mode = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get isLegendOpen(): boolean {
        return this._isLegendOpen;
    }

    public open(): void {
        this._isOpen = true;
    }

    public close(): void {
        this._isOpen = false;
    }

    public openLegend(): void {
        this._isLegendOpen = true;
    }

    public closeLegend(): void {
        this._isLegendOpen = false;
    }

    get calendarSelection(): Map<UserId, Array<DateTimestamp>> {
        return this._calendarSelection;
    }

    public shouldShowLegend(): boolean {
        if (!this.arePathsShowing) {
            return false;
        }
        const userIds = Object.keys(this.styles);
        // @ts-ignore – accessing first array item by 0
        const firstUserDates = Object.keys(this.styles[userIds[0]] || {});
        return userIds.length > 1 || firstUserDates.length > 1;
    }

    public loadRoutesPaths = (initiator: PathsRequestInitiator = PathsRequestInitiator.ROUTE_REPORT) => {
        if (initiator === PathsRequestInitiator.INIT_SESSION && this.mode !== 'route') {
            // example: exporting activities to calendar should reload activities via `initSession`
            // but mode is routing viewer mode is switched to calendar, so there is no need to update paths for route
            return;
        }
        if (initiator === PathsRequestInitiator.CALENDAR && tripModeManager.isTripStarted) {
            return;
        }

        if (initiator === PathsRequestInitiator.CALENDAR) {
            this.mode = 'calendar';
        } else if (
            [
                PathsRequestInitiator.ROUTE_REPORT,
                PathsRequestInitiator.INIT_SESSION,
                PathsRequestInitiator.TRIP_MODE,
            ].includes(initiator)
        ) {
            this.mode = 'route';
        }

        if (routingSessionManager.isPublishedMode) {
            this.statusFilter = STATUS_FILTER_VALUES.CURRENT_ROUTE;
        } else if (routingSessionManager.isAnyDraftMode) {
            this.statusFilter = STATUS_FILTER_VALUES.DRAFT;
        } else {
            this.statusFilter = STATUS_FILTER_VALUES.ALL;
        }

        if (routingSessionManager.isShowCurrentSession) {
            this.loadBuiltRoutePaths(routingSessionManager.currentSessionRoutes, initiator);
        } else {
            this.loadBuiltRoutePaths([routingSessionManager.loadedSessionRoute], initiator);
        }
    };

    public enableRoutesFilter = (source: RouteFilterSource, routes: Array<Routing.Route.Route>) => {
        const routesIds = routes.map((route) => route.id);
        this.routesFilter.set(source, routesIds);
    };

    public disableRoutesFilter = (source: RouteFilterSource) => {
        this.routesFilter.delete(source);
    };

    public clear() {
        this.selectedDatetimeIntervalStart = null;
        this.selectedDatetimeIntervalEnd = null;
        this.selectedUsers = [];
        this.statusFilter = STATUS_FILTER_VALUES.ALL;
        this.clearPaths();
        this.clearCalendarSelection();

        this.routesFilter = new Map();
    }

    private clearPaths() {
        this.currentRequestId = null;
        this.loading = false;
        this.arePathsShowing = false;
        this.toggleLegendIfNecessary();
        this.paths = new Map();
        this.styles = { pathsStyles: {}, breakColor: STYLE_COLOR_BREAK };
        this.pathsBuffer = null;
    }

    public requestPaths(initiator: PathsRequestInitiator) {
        this.clearPaths();
        this.loading = true;

        const payload: RequestPathsPayloadType = {
            users: [],
            dateStart: null,
            dateEnd: null,
            requestHash: null,
            initiator: initiator,
            calendar: false,
        };

        let currentUser = userManager.getCurrentUser();
        let timeZoneOffset = Math.round(getTimezoneOffset(currentUser.actualTimezone) / 60_000);

        const startDiffersFromEnd = this.selectedDatetimeIntervalStart !== this.selectedDatetimeIntervalEnd;
        const multipleUsersSelected = this.selectedUsers.length > 1;
        this.multiselect = startDiffersFromEnd || multipleUsersSelected;

        payload.dateStart = formatInTimeZone(
            addMinutes(new Date(this.selectedDatetimeIntervalStart + 'T00:00:00+00:00'), -timeZoneOffset),
            'UTC',
            DATETIME_FORMAT_DATEFNS,
        );
        payload.dateEnd = formatInTimeZone(
            addMinutes(new Date(this.selectedDatetimeIntervalEnd + 'T23:59:59+00:00'), -timeZoneOffset),
            'UTC',
            DATETIME_FORMAT_DATEFNS,
        );

        payload.users = this.selectedUsers.map((user) => user.id);

        payload.calendar = Boolean(this.mode === 'calendar');

        if (payload.calendar) {
            payload.calendarSelection = {};
            this.calendarSelection.forEach((dates, userId) => {
                payload.calendarSelection![userId] = dates.map((date) => format(new Date(date), DATE_FORMAT_DATEFNS));
            });
        }

        this.currentRequestId = hash.MD5(JSON.stringify(payload));
        payload.requestHash = this.currentRequestId;

        const url = reverse(apiRoutes.account.routes.getPaths, { accountId: currentUser.accountId });
        return this.requestApi(url, 'POST', payload)
            .catch((e: Error) => {
                this.currentRequestId = null;
                throw e;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public drawCurrentPaths(initiator: PathsRequestInitiator): void {
        runInAction(() => {
            this._drawPathsInitiator = initiator;
            this.paths = this.processBufferPaths();
            this.styles = this.setupStyles();
        });
    }

    public getCurrentRouteForFilter(): Route | null {
        if (routingSessionManager.isShowLoadedSessionRoute) {
            return routeReportLoadedSessionRouteManager.route;
        }
        return null;
    }

    public addCalendarSelection(userId: UserId, dates: Array<Date>): Promise<void> {
        return new Promise<void>((resolve) => {
            const timestamps = dates.map((date) => date.getTime());
            if (!this._calendarSelection.has(userId)) {
                this._calendarSelection.set(userId, timestamps);
                return resolve();
            }

            const newDates = [...(this._calendarSelection.get(userId) || [])];
            timestamps.forEach((timestamp) => {
                if (!newDates!.includes(timestamp)) {
                    newDates!.push(timestamp);
                }
            });

            this._calendarSelection.set(userId, newDates);
            return resolve();
        }).finally(this.reactToCalendarSelectionChange.bind(this));
    }

    public removeCalendarSelection(userId: UserId, dates?: Array<Date>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this._calendarSelection.has(userId)) {
                return reject();
            }
            if (!dates) {
                this._calendarSelection.delete(userId);
                return resolve();
            }
            const timestamps = dates.map((date) => date.getTime());
            const currentDates = this._calendarSelection.get(userId);
            const newDates = currentDates!.filter((date) => !timestamps.includes(date));
            if (!newDates.length) {
                this._calendarSelection.delete(userId);
                return resolve();
            }
            this._calendarSelection.set(userId, newDates);
            return resolve();
        }).finally(this.reactToCalendarSelectionChange.bind(this));
    }

    public hasCalendarFilter(userId: UserId, dates?: Array<Date>): boolean {
        if (!this._calendarSelection.has(userId)) {
            return false;
        }
        if (!dates) {
            return true;
        }
        const timestamps = dates.map((date) => date.getTime());
        const currentDates = this._calendarSelection.get(userId);
        return timestamps.every((date) => currentDates!.includes(date));
    }

    public getFilteredPaths(userIdsFilter?: UserId[], datesFilter?: DateYMD[], routeIds?: RouteId[]): RoutePath[] {
        const routesPaths: RoutePath[] = [];
        for (const userId of this.paths.keys()) {
            if (userIdsFilter && !userIdsFilter.includes(userId)) {
                continue;
            }

            const pathsUserDates = this.paths.get(userId)!;
            for (const date of pathsUserDates.keys()) {
                if (datesFilter && !datesFilter.includes(date)) {
                    continue;
                }

                const pathsUserDateRoutes = pathsUserDates.get(date)!;
                for (const route of pathsUserDateRoutes) {
                    if (routeIds && !routeIds.includes(route.id)) {
                        continue;
                    }

                    routesPaths.push(route);
                }
            }
        }

        return routesPaths;
    }

    private toggleLegendIfNecessary() {
        if (this.shouldShowLegend()) {
            this.openLegend();
        } else {
            this.closeLegend();
        }
    }

    private loadBuiltRoutePaths(routes: Route[], initiator: PathsRequestInitiator) {
        const currentUser = userManager.getCurrentUser();
        const users: Array<User.User> = [];
        let startDate = null,
            endDate = null;

        for (const i in routes) {
            if (!routes[i].dateStartAt) {
                continue;
            }
            const routeStartDate = formatInTimeZone(
                routes[i].dateStartAt,
                currentUser.actualTimezone,
                DATE_FORMAT_DATEFNS,
            );

            if (!startDate || routeStartDate < startDate) {
                startDate = routeStartDate;
            }
            if (!endDate || routeStartDate > endDate) {
                endDate = routeStartDate;
            }
            if (!users.find((user) => user.id === routes[i].user.id)) {
                users.push(routes[i].user);
            }
        }

        this.selectedDatetimeIntervalStart = startDate;
        this.selectedDatetimeIntervalEnd = endDate;
        this.selectedUsers = users;

        if (!this.selectedDatetimeIntervalStart || !this.selectedDatetimeIntervalEnd || !this.selectedUsers.length) {
            this.clearPaths();
            return;
        }
        this.requestPaths(initiator);
    }

    private processNewPaths(initiator: PathsRequestInitiator): void {
        this.drawCurrentPaths(initiator);
        this.arePathsShowing = true;
        this.toggleLegendIfNecessary();
        dispatcher.dispatch(events.ROUTE_VIEWER_PATHS_PROCESSED);
    }

    private addPathsToBuffer = (message: WSRoutePathsBuiltResponse) => {
        const paths = message.paths;
        if (this.pathsBuffer === null) {
            this.pathsBuffer = paths;
        } else {
            for (const userId in paths) {
                if (!this.pathsBuffer.hasOwnProperty(userId)) {
                    this.pathsBuffer[userId] = paths[userId];
                    continue;
                }
                for (const date in paths[userId]) {
                    if (!this.pathsBuffer[userId].hasOwnProperty(date)) {
                        this.pathsBuffer[userId][date] = paths[userId][date];
                        continue;
                    }
                    this.pathsBuffer[userId][date].push(...paths[userId][date]);
                }
            }
        }
    };

    private processBufferPaths(): RouteViewerPaths {
        const paths: RouteViewerPaths = new Map();
        const filterIds: RouteId[] = [];
        this.routesFilter.forEach((ids) => {
            ids.forEach((id) => {
                if (!filterIds.includes(id)) {
                    filterIds.push(id);
                }
            });
        });

        for (const userId in this.pathsBuffer) {
            const datesMap = new Map();
            for (const date in this.pathsBuffer[userId]) {
                const datePaths = [];
                for (const routeIndex in this.pathsBuffer[userId][date]) {
                    const route = this.pathsBuffer[userId][date][routeIndex];

                    if (filterIds.length && !filterIds.includes(route.id)) {
                        continue;
                    }

                    if (this.statusFilter === STATUS_FILTER_VALUES.CURRENT_ROUTE) {
                        const currentRoute = this.getCurrentRouteForFilter();
                        if (currentRoute && currentRoute.id !== route.id) {
                            continue;
                        }
                    }

                    if (this.statusFilter === STATUS_FILTER_VALUES.DRAFT) {
                        if (route.status !== RouteStatus.DRAFT) {
                            continue;
                        }
                    }

                    if (this.statusFilter === STATUS_FILTER_VALUES.LIVE) {
                        if (route.status !== RouteStatus.PUBLISHED) {
                            continue;
                        }
                    }

                    datePaths.push({
                        ...route,
                        points: route.activities.filter(({ lat, lng }) => validateGeoPoint({ lat, lng })),
                    });
                }
                datesMap.set(date, datePaths);
            }
            paths.set(parseInt(userId), datesMap);
        }
        return paths;
    }

    private setupStyles(): StylesConfig {
        const styles: StylesConfig = { pathsStyles: {}, breakColor: STYLE_COLOR_BREAK };
        const colorGenerator = new ColorGenerator();
        const lineStyleGenerator = new LineStyleGenerator();
        let singleUser = false;
        if (this.paths.size === 1) {
            singleUser = true;
        }
        let color: string;
        let lineStyle: LineStyle;
        if (singleUser) {
            lineStyle = lineStyleGenerator.getLineStyle();
        }
        const dateLineStyleMap = new Map();
        this.paths.forEach((datesMap, userId) => {
            if (!singleUser) {
                color = colorGenerator.getColor(); // if multiple users set new color for each user
            }
            styles.pathsStyles[userId] = {};
            datesMap.forEach((_paths, date) => {
                if (singleUser) {
                    color = colorGenerator.getColor(); // if single user set new color for each date
                } else {
                    // if multiple users set new style for each date, but keep user's color
                    if (dateLineStyleMap.has(date)) {
                        lineStyle = dateLineStyleMap.get(date);
                    } else {
                        lineStyle = lineStyleGenerator.getLineStyle();
                        dateLineStyleMap.set(date, lineStyle);
                    }
                }
                styles.pathsStyles[userId][date] = {
                    color,
                    dashArray: lineStyle.dashArray,
                    lineCap: lineStyle.lineCap,
                    width: lineStyle.width,
                    opacity: 0.8,
                    inactiveOpacity: 0.3,
                };
            });
        });
        return styles;
    }

    private async reactToCalendarSelectionChange() {
        if (tripModeManager.isTripStarted) {
            return;
        }

        this.open();

        if (this.mode !== 'calendar') {
            this.mode = 'calendar';
        }
        if (this.statusFilter !== STATUS_FILTER_VALUES.ALL) {
            this.statusFilter = STATUS_FILTER_VALUES.ALL;
        }

        const accountUsers: User.User[] = await userManager.getAccountUsers(userManager.getCurrentAccount().id);
        this.selectedUsers = accountUsers.filter((user) => this._calendarSelection.has(user.id));

        let selectedDatetimeIntervalStart = null;
        let selectedDatetimeIntervalEnd = null;

        const dates = Array.from(this._calendarSelection.values()).flat();
        if (dates.length) {
            const minDate = new Date(Math.min(...dates));
            const maxDate = new Date(Math.max(...dates));
            selectedDatetimeIntervalStart = format(minDate, DATE_FORMAT_DATEFNS);
            selectedDatetimeIntervalEnd = format(maxDate, DATE_FORMAT_DATEFNS);
        }

        this.selectedDatetimeIntervalStart = selectedDatetimeIntervalStart;
        this.selectedDatetimeIntervalEnd = selectedDatetimeIntervalEnd;

        this.multiselect = selectedDatetimeIntervalStart !== selectedDatetimeIntervalEnd;

        if (this.selectedUsers.length && this.selectedDatetimeIntervalStart && this.selectedDatetimeIntervalEnd) {
            this.requestPaths(PathsRequestInitiator.CALENDAR);
        } else {
            this.clearPaths();
        }
    }

    private clearCalendarSelection() {
        this._calendarSelection = new Map();
    }

    private handleMessageError(message: WSRoutePathsBuiltResponse) {
        enqueueSnackbarService.sendErrorMessage(message.error);
    }
}

export default RouteViewerManager;
