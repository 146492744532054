import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormActions } from '../PureFormDialog/Form';

type PicklistItem = {
    value: any;
    label: string;
};

interface InsertPicklistFormProps extends WithTranslation {
    picklist: PicklistItem[];
    multiPicklist: boolean;
    onSave: (value: string | string[]) => void;
}

interface InsertPicklistFormState {
    value: PicklistItem[];
}

class InsertPicklistForm extends React.PureComponent<InsertPicklistFormProps, InsertPicklistFormState> {
    constructor(props: InsertPicklistFormProps) {
        super(props);

        this.state = {
            value: [],
        };
    }

    handleChange = (_e: ChangeEvent<{}>, value: PicklistItem | PicklistItem[]) => {
        if (Array.isArray(value)) {
            this.setState({ value });
        } else {
            this.props.onSave(value.value);
        }
    };

    handleSave = () => {
        const value = this.state.value.map((item) => item.value);
        this.props.onSave(value);
    };

    render() {
        return (
            <form noValidate autoComplete="off">
                <FormControl margin="dense" fullWidth>
                    <Autocomplete
                        options={this.props.picklist}
                        multiple={this.props.multiPicklist || false}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        onChange={this.handleChange}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                label={this.props.t('formula_editor.insert_picklist_value.label')}
                                data-testid="formula_editor.insert_picklist_value"
                            />
                        )}
                    />
                </FormControl>

                <FormActions>
                    {this.props.multiPicklist && (
                        <Button color="primary" onClick={this.handleSave}>
                            {this.props.t('button.save')}
                        </Button>
                    )}
                </FormActions>
            </form>
        );
    }
}

export default withTranslation('translations', { withRef: true })(InsertPicklistForm);
