import BackendService from '../../api/BackendService';
import apiRoutes, { reverse } from '../../api/apiRoutes';
import { FileType, MapslyFile, ProgressCallback } from 'interfaces/file';
import Uppy, { UppyFile } from '@uppy/core';
import getSafeFileId from '@uppy/utils/lib/generateFileID';
import AwsS3 from '@uppy/aws-s3';

interface UploadResponse {
    id: string;
    url: string;
    uploadUrl: string;
}

interface CreateMultipartUploadResponse {
    id: string;
    url: string;
    uploadId: string;
}

interface SignUploadPartResponse {
    uploadUrl: string;
}

class UploadManager extends BackendService {
    fileMap: Map<string, { accountId: number; progressCallback?: ProgressCallback }>;
    uppy: Uppy<any>;

    constructor() {
        super();

        this.fileMap = new Map();
        this.uppy = new Uppy<any>().use(AwsS3, {
            shouldUseMultipart: (file: UppyFile) => file.size > 100 * 0x100000,

            getUploadParameters: (file) => {
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.presign, { accountId });
                return this.requestApi(url, 'POST', {
                    contentType: file.type,
                    type: file.meta.type,
                }).then((response: UploadResponse) => {
                    this.uppy.setFileMeta(file.id, { id: response.id, url: response.url });
                    return {
                        method: 'PUT',
                        url: response.uploadUrl,
                        fields: {},
                        headers: { 'Content-Type': file.type },
                    };
                });
            },

            createMultipartUpload: (file) => {
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.multipart.create, { accountId });
                return this.requestApi(url, 'POST', {
                    contentType: file.type,
                    type: file.meta.type,
                }).then((response: CreateMultipartUploadResponse) => {
                    this.uppy.setFileMeta(file.id, { ...response });
                    return {
                        key: response.url,
                        uploadId: response.uploadId,
                    };
                });
            },

            signPart: (file, { key, uploadId, partNumber }) => {
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.multipart.signUploadPart, {
                    accountId,
                    uploadId,
                    partNumber,
                });
                return this.requestApi(url, 'GET', {
                    url: key,
                    type: file.meta.type,
                }).then((response: SignUploadPartResponse) => {
                    return { url: response.uploadUrl };
                });
            },

            listParts: (file, { key, uploadId }) => {
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.multipart.getUploadParts, { accountId, uploadId });
                return this.requestApi(url, 'GET', { url: key, type: file.meta.type });
            },

            abortMultipartUpload: (file, { key, uploadId }) => {
                if (!uploadId) {
                    return null;
                }
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.multipart.abort, { accountId, uploadId });
                return this.requestApi(url, 'DELETE', { url: key, type: file.meta.type });
            },

            completeMultipartUpload: (file, { key, uploadId, parts }) => {
                const accountId = this.fileMap.get(file.id)!.accountId;
                const url = reverse(apiRoutes.account.file.multipart.complete, { accountId, uploadId });
                return this.requestApi(url, 'POST', { url: key, parts, type: file.meta.type });
            },
        });

        this.uppy.on('upload-progress', (file, progress) => {
            if (!file) {
                return;
            }
            const callback = this.fileMap.get(file.id)?.progressCallback;
            callback &&
                callback(
                    new ProgressEvent('', {
                        total: progress.bytesTotal,
                        loaded: progress.bytesUploaded,
                    }),
                );
        });

        this.uppy.on('file-removed', (file, reason) => {
            if (reason === 'removed-by-user') {
                this.fileMap.delete(file.id);
            }
        });
    }

    upload(accountId: number, mapslyFile: MapslyFile, progressCallback?: ProgressCallback): Promise<MapslyFile> {
        const blob = mapslyFile.blob;
        const thumbnailBlob = mapslyFile.thumbnailBlob;
        if (!blob) {
            return Promise.reject();
        }

        return this.init(mapslyFile, blob)
            .then((mapslyFile) => {
                const id = this.uppy.addFile(this.toUppyFile(mapslyFile));
                this.uppy.setFileMeta(id, mapslyFile);
                this.fileMap.set(id, { accountId, progressCallback });
                progressCallback && progressCallback(new ProgressEvent('', { total: mapslyFile.size, loaded: 0 }));
                return this.uppy.upload();
            })
            .then((files) => {
                if (files.failed.length) {
                    const uppyFile = files.failed[0];
                    this.fileMap.delete(uppyFile.id);
                    throw new Error(uppyFile.error);
                }
                return files.successful[0];
            })
            .then((uppyFile) => {
                this.fileMap.delete(uppyFile.id);
                const url = reverse(apiRoutes.account.file.index, { accountId });
                const { isTranscriptEnabled, isSummaryEnabled, prompt, ...fileData } = uppyFile.meta;

                return this.requestApi(url, 'POST', {
                    isTranscriptEnabled,
                    isSummaryEnabled,
                    prompt,
                    data: fileData,
                }).then((file: MapslyFile) => {
                    return { file, isTranscriptEnabled, isSummaryEnabled };
                });
            })
            .then(
                ({
                    file,
                    isTranscriptEnabled,
                    isSummaryEnabled,
                }: {
                    file: MapslyFile;
                    isTranscriptEnabled: boolean;
                    isSummaryEnabled: boolean;
                }) => {
                    if (file.thumbnailUploadUrl && thumbnailBlob) {
                        return this.uploadBlob(file.thumbnailUploadUrl, thumbnailBlob).then(() => {
                            file.isTranscriptEnabled = isTranscriptEnabled;
                            file.isSummaryEnabled = isSummaryEnabled;
                            return file;
                        });
                    }

                    file.isTranscriptEnabled = isTranscriptEnabled;
                    file.isSummaryEnabled = isSummaryEnabled;

                    return file;
                },
            )
            .finally(() => {
                this.uppy.removeFile(getSafeFileId(this.toUppyFile(mapslyFile)));
            })
            .catch((error) => {
                return Promise.resolve(error);
            });
    }

    uploadAbort(mapslyFile: MapslyFile): Promise<void> {
        const id = getSafeFileId(this.toUppyFile(mapslyFile));
        this.uppy.removeFile(id, 'removed-by-user');
        return Promise.resolve();
    }

    private init(mapslyFile: MapslyFile, blob: Blob): Promise<MapslyFile> {
        return this.initImageSize(mapslyFile, blob).then((file) => this.initDuration(file, blob));
    }

    private initImageSize(mapslyFile: MapslyFile, blob: Blob): Promise<MapslyFile> {
        return new Promise((resolve) => {
            switch (mapslyFile.type) {
                case FileType.Image:
                    const image = new Image();
                    image.onload = () => {
                        URL.revokeObjectURL(image.src);
                        mapslyFile.width = image.width;
                        mapslyFile.height = image.height;
                        resolve(mapslyFile);
                    };
                    image.src = URL.createObjectURL(blob);
                    break;
                case FileType.Video:
                    const element = document.createElement('video');
                    element.preload = 'metadata';
                    element.onloadedmetadata = () => {
                        URL.revokeObjectURL(element.src);
                        mapslyFile.width = element.videoWidth;
                        mapslyFile.height = element.videoHeight;
                        resolve(mapslyFile);
                    };
                    element.src = URL.createObjectURL(blob);
                    break;
                default:
                    resolve(mapslyFile);
            }
        });
    }

    private initDuration(mapslyFile: MapslyFile, blob: Blob): Promise<MapslyFile> {
        return new Promise((resolve) => {
            let element: HTMLVideoElement | HTMLAudioElement;
            switch (mapslyFile.type) {
                case FileType.Video:
                    element = document.createElement('video');
                    break;
                case FileType.Audio:
                    element = document.createElement('audio');
                    break;
                default:
                    resolve(mapslyFile);
                    return;
            }

            element.preload = 'metadata';
            element.onloadedmetadata = () => {
                URL.revokeObjectURL(element.src);
                mapslyFile.duration = element.duration;
                resolve(mapslyFile);
            };
            element.src = URL.createObjectURL(blob);
        });
    }

    private uploadBlob(url: string, blob: Blob, progressCallback?: ProgressCallback) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 120000;

            xhr.onload = () => {
                const result = xhr.response ? JSON.parse(xhr.response) : xhr.response;
                resolve(result);
            };
            xhr.onerror = (e) => {
                console.error('Request ended with error, code:' + xhr.statusText);
                reject(e);
            };
            xhr.ontimeout = (e) => {
                reject(e);
            };
            if (progressCallback) {
                xhr.upload.onprogress = progressCallback;
            }

            xhr.open('PUT', url);
            xhr.setRequestHeader('Content-Type', blob.type);

            xhr.send(blob);
        });
    }

    private toUppyFile(file: MapslyFile): UppyFile {
        return {
            name: file.name,
            type: file.contentType,
            data: file.blob || { size: file.size, lastModified: file.updatedAt },
        } as UppyFile;
    }
}

export const uploadManager = new UploadManager();
