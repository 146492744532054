import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import i18n from '../../locales/i18n';

const t = i18n.t.bind(i18n);

export default class DialogConfirmation extends React.PureComponent {
    confirm = () => {
        this.props.setOpen(false);
        this.props.onConfirm();
    };

    reject = () => {
        this.props.setOpen(false);
        this.props.onReject && this.props.onReject();
    };

    renderContent = () => {
        if (this.props.children) {
            return <DialogContent>{this.props.children}</DialogContent>;
        }
        return null;
    };

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>{this.props.titleText || t('are_you_sure')}</DialogTitle>

                {this.renderContent()}

                <DialogActions>
                    <Button onClick={this.reject} data-testid="dialog_confirmation.btn.no">
                        {this.props.noBtnText || t('no')}
                    </Button>
                    {this.props.onConfirm && (
                        <Button color="primary" onClick={this.confirm} data-testid="dialog_confirmation.btn.yes">
                            {this.props.yesBtnText || t('yes')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

DialogConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
    children: PropTypes.node,
    titleText: PropTypes.string,
    yesBtnText: PropTypes.string,
    noBtnText: PropTypes.string,
};
