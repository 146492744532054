import { makeAutoObservable } from 'mobx';
import { AdapterFullInfo } from 'components/types';

class CredentialsDialogManager {
    private _isOpen = false;
    private _adapter: AdapterFullInfo | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen() {
        return this._isOpen;
    }

    get adapter(): AdapterFullInfo | null {
        return this._adapter;
    }

    openModal(adapter: AdapterFullInfo) {
        this._adapter = adapter;
        this._isOpen = true;
    }

    closeModal() {
        this._isOpen = false;
    }
}

export default CredentialsDialogManager;
