import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SearchFilterForm from './SearchFilterForm';
import PureFormDialog from '../PureFormDialog';
import { IWorkflow } from '../../interfaces';

interface SearchFilterModalProps extends WithTranslation {
    formId: number | null;
    forms: Array<IWorkflow.IForm>;
    onClose: Function;
    onSave(data: IWorkflow.IProfileSearchFilterForm): void;
    searchBarFields: Array<string>;
    invalidSearchBarFields: Array<string>;
    isInvalidFormId: boolean;
}

const SearchFilterModal: React.FC<SearchFilterModalProps> = observer((props) => {
    const { t, forms, formId, searchBarFields, invalidSearchBarFields, isInvalidFormId } = props;

    const handleClose = () => {
        props.onClose();
    };

    return (
        <PureFormDialog
            open
            title={t('search_filters.properties') as string}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
        >
            <SearchFilterForm
                forms={forms}
                formId={formId}
                searchBarFields={searchBarFields}
                onClose={handleClose}
                onSave={props.onSave}
                invalidSearchBarFields={invalidSearchBarFields}
                isInvalidFormId={isInvalidFormId}
            />
        </PureFormDialog>
    );
});

export default withTranslation()(SearchFilterModal);
