import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { intercomManager } from '../../service/IntercomManager';

interface ExportProspectLimitMessageInterface extends WithTranslation {
    count: string;
    limit: string;
}

class ExportProspectLimitMessage extends React.PureComponent<ExportProspectLimitMessageInterface> {
    handleChatLink = () => {
        const { t } = this.props;

        intercomManager.showNewMessage(t('prospecting.export_limit_message.chat_message'));
    };

    render() {
        const { count: exportCount, limit: exportLimit, t } = this.props;

        const part1 = t('prospecting.export_limit_message.part1', { exportCount, exportLimit });
        const part2 = t('prospecting.export_limit_message.part2');
        const chatLink = t('prospecting.export_limit_message.chat_link');

        return (
            <>
                {part1}&nbsp;
                <span onClick={this.handleChatLink} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {chatLink}
                </span>
                .{part2}
            </>
        );
    }
}

export default withTranslation('translations')(ExportProspectLimitMessage);
