import { Event, EventHint } from '@sentry/types/dist/event';
import * as Sentry from '@sentry/browser';
import { Breadcrumb } from '@sentry/browser';
import { SamplingContext } from '@sentry/types/esm/transaction';
import { getRoute, routes } from '../routes';
import { DsnComponents, TransactionContext } from '@sentry/types';
import { Integrations } from '@sentry/tracing';
import { EnvironmentParams } from '../types';
import { STORAGE_KEY_PREFIX } from 'components/types';
import logRocketManager from 'service/LogRocket';

export const MONITORED_ROUTES = [routes.client, routes.admin.account.index];
export const TRACING_ORIGINS = [
    'api.com.local',
    'api.mapsly.com',
    'api-dev1.mapsly.com',
    'api-dev2.mapsly.com',
    'api-staging.mapsly.com',
    /^\//,
];
export const CUSTOM_TRANSACTION = '__custom_transaction';
export const ISOLATED_TRANSACTION = '__isolated_transaction';

const tracesSampleRate = 0.0;

const localStorageKeyPrefixes = [
    STORAGE_KEY_PREFIX.CURRENT_ROUTE,
    STORAGE_KEY_PREFIX.TRAVELING_PREFERENCES,
    STORAGE_KEY_PREFIX.MAP_STATE,
    STORAGE_KEY_PREFIX.APPOINTMENT_CONFIG,
    STORAGE_KEY_PREFIX.TRIP_MODE_CONFIG,
];

type LocalStorageData = { [key: string]: string };

export class SentryHandler {
    static addBreadcrumb(breadcrumb: Breadcrumb) {
        Sentry.addBreadcrumb(breadcrumb);
    }

    beforeSend = (event: Event, hint?: EventHint): PromiseLike<Event | null> | Event | null => {
        if (this.isSkipError(event, hint)) {
            return null;
        }

        const { sessionUrl: LogRocket } = logRocketManager;
        event.extra = { ...event.extra, LogRocket };

        return SentryHandler.transform(event, hint?.originalException);
    };

    isSkipError = (event: Event, hint?: EventHint): boolean => {
        return Boolean(
            hint &&
                hint.originalException &&
                (SentryHandler._hasSkipableExceptionCode(hint.originalException) ||
                    SentryHandler._hasSkipSentryFlag(hint.originalException) ||
                    SentryHandler._hasLeafletExecutedAfterTimeoutException(event, hint.originalException) ||
                    SentryHandler._hasSkipableConnectionError(hint.originalException)),
        );
    };

    private static _hasSkipableExceptionCode(originalException: any): Boolean {
        return Boolean(
            originalException &&
                originalException.hasOwnProperty('code') &&
                originalException.code &&
                [400, 401, 403, 404, 409].includes(originalException.code),
        );
    }

    private static _hasSkipableConnectionError(originalException: any): Boolean {
        return Boolean(
            originalException &&
                ((originalException.hasOwnProperty('code') &&
                    (originalException.code < 1 || originalException.code === null)) ||
                    (originalException.hasOwnProperty('status') && originalException.status < 1)),
        );
    }

    private static _hasSkipSentryFlag(originalException: any): Boolean {
        return Boolean(originalException.skipSentry);
    }

    private static _hasLeafletExecutedAfterTimeoutException(event: Event, originalException: any): Boolean {
        if (originalException === 'Request timed out. The device maybe offline.') {
            return true;
        }
        const exceptionValue = event?.exception?.values?.[0];
        if (!exceptionValue) {
            return false;
        }
        const stackFrames = exceptionValue.stacktrace?.frames;
        if (!Array.isArray(stackFrames) || !stackFrames.length) {
            return false;
        }
        if (!originalException) {
            return false;
        }
        const mechanismFunction = exceptionValue.mechanism?.data?.function;
        const message = originalException.message;
        const isTimeout = mechanismFunction === 'setTimeout';

        return (
            (message === "Cannot read property 'getZoom' of null" ||
                message === "Cannot read property 'getMinZoom' of null" ||
                message === "Cannot read property '_leaflet_pos' of undefined") &&
            isTimeout
        );
    }

    private static transform(event: Event, originalException: any): Event {
        if (originalException) {
            for (const exception of event.exception?.values || []) {
                if (exception.type === 'UnhandledRejection' && originalException.message) {
                    let newMessage = originalException.message;
                    if (originalException.exception) {
                        newMessage += ' (' + originalException.exception + ')';
                    }
                    if (originalException.url) {
                        newMessage += ' at ' + originalException.url;
                    }
                    newMessage += ' / ' + exception.value;
                    exception.value = newMessage;
                }
            }
        }
        return event;
    }

    beforeNavigate = (context: TransactionContext): TransactionContext => {
        const currentRoute = getRoute(window.location.pathname);
        let name = window.location.pathname;
        if (currentRoute?.route) {
            name = '[front] Route: ' + currentRoute.route;
        }
        return { ...context, name };
    };

    tracesSampler = (samplingContext: SamplingContext) => {
        let route = null;
        if (samplingContext.location) {
            route = getRoute(samplingContext.location.pathname);
        }
        if (route && MONITORED_ROUTES.includes(route.pattern)) {
            return tracesSampleRate;
        }
        if (samplingContext.transactionContext.data?.[CUSTOM_TRANSACTION] === true) {
            return tracesSampleRate;
        }
        return 0.0;
    };

    handleAttachmentFileProcessor = (event: Event, hint?: EventHint) => {
        if (this.isSkipError(event, hint)) {
            return null;
        }

        try {
            const client = Sentry.getCurrentHub().getClient();
            if (!client || !event.event_id) {
                return null;
            }
            const dsn = client.getDsn();
            if (!dsn) {
                return null;
            }
            const endpoint = SentryHandler.attachmentUrlFromDsn(dsn, event.event_id);

            const formData = new FormData();
            formData.append(
                'localStorage',
                new Blob([JSON.stringify(this.getLocalStorageData(event))], {
                    type: 'application/json',
                }),
                'localStorage.json',
            );
            fetch(endpoint, {
                method: 'POST',
                body: formData,
            }).catch((ex) => {
                // we have to catch this otherwise it throws an infinite loop in Sentry
                console.error(ex);
            });
            return event;
        } catch (ex) {
            console.error(ex);
            return null;
        }
    };

    private static attachmentUrlFromDsn(dsn: DsnComponents, eventId: string) {
        const { host, path, projectId, port, protocol, user } = dsn;
        return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
            path !== '' ? `/${path}` : ''
        }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
    }

    private getLocalStorageData = (event: Event): LocalStorageData => {
        const localStorageData: LocalStorageData = {};
        if (!event.user) {
            return localStorageData;
        }

        const userId = event.user.id;
        localStorageKeyPrefixes.forEach((prefix) => {
            const key = `${prefix}${userId}`;
            const json = localStorage.getItem(key);
            if (json !== null) {
                try {
                    localStorageData[key] = JSON.parse(json);
                } catch (ex) {
                    console.error(`Error parsing data storage locale by key ${key}`);
                }
            }
        });

        return localStorageData;
    };
}

const initSentry = (params: EnvironmentParams) => {
    const sentryHandler = new SentryHandler();

    Sentry.init({
        dsn: params.sentryDSN,
        environment: params.environment,
        beforeSend: sentryHandler.beforeSend,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: TRACING_ORIGINS,
                beforeNavigate: sentryHandler.beforeNavigate,
            }),
        ],
        ignoreErrors: ['TypeError: Failed to fetch'],
        tracesSampler: sentryHandler.tracesSampler,
    });

    Sentry.addGlobalEventProcessor(sentryHandler.handleAttachmentFileProcessor);
};

export default initSentry;
