import * as Sentry from '@sentry/browser';
import { Span } from '@sentry/types/dist/span';
import { SpanStatus, Transaction } from '@sentry/tracing';
import { Hub } from '@sentry/hub/types/hub';
import { CUSTOM_TRANSACTION, ISOLATED_TRANSACTION } from './SentryHandler';
import events from '../events';
import { logDebug } from '../utils';
import { userManager } from '../service/UserManager';

export const TIMER_OPERATIONS = {
    MapPage: {
        default: 'MapPage',
        loadingPoints: 'MapPage.loadingPoints',
        loadingTerritories: 'MapPage.loadingTerritories',
        leaflet: {
            onZoomEnd: 'MapPage.leaflet.onZoomEnd',
            onDragEnd: 'MapPage.leaflet.onDragEnd',
        },
        popupOpened: 'popupOpened',
    },
    PointsManager: {
        loadFraction: 'PointsManager.loadFraction',
        load: 'PointsManager.load',
    },
    TerritoryManager: {
        requestTerritories: 'TerritoryManager.requestTerritories',
        loadTerritoriesGeoJson: 'TerritoryManager.loadTerritoriesGeoJson',
        saveTerritories: 'TerritoryManager.saveTerritories',
    },
    RouteEditor: {
        buildRoute: 'RouteEditor.buildRoute',
    },
    MapObjects: {
        openMapViewSettingsModal: 'MapObjects.openMapViewSettingsModal',
        openLayersFiltersModal: 'MapObjects.openLayersFiltersModal',
    },
    EntityDataTable: {
        openTableViewSettingsModal: 'EntityDataTable.openTableViewSettingsModal',
    },
    DataTable: {
        applyFilters: 'DataTable.applyFilters',
    },
    FrontDataTable: {
        requestData: 'FrontDataTable.requestData',
    },
    SearchDataTable: {
        requestData: 'SearchDataTable.requestData',
    },
    EntityViewManager: {
        loadData: 'EntityViewManager.loadData',
    },
    Accounts: {
        loadAccountsList: 'Accounts.loadAccountsList',
    },
    events: {
        [events.ENTITY_VISIBILITY_CHANGED]: 'events.entityVisibilityChanged',
        [events.LAYER_VISIBILITY_CHANGED]: 'events.layerVisibilityChanged',
        [events.ENTITY_VIEW_CHANGED]: 'events.entityViewChanged',
        [events.LAYER_VIEW_CHANGED]: 'events.layerViewChanged',
        [events.ENTITY_LAYER_GROUP_CHANGED]: 'events.entityLayerGroupChanged',
    },
    _terminator: '_terminator',
};

export default class Timer {
    private readonly span: Span;
    private readonly parent?: Timer;
    private readonly children: Set<Timer>;
    private terminators: TimerTerminators;
    ended: boolean = false;

    constructor(span: Span, parent?: Timer, children: Set<Timer> = new Set()) {
        this.span = span;
        this.parent = parent;
        this.children = children;
        this.terminators = new TimerTerminators(this);
    }

    private static getTransaction(transactionName: string, isolated: boolean = false): Span {
        let transaction: Span | undefined;
        let hub: Hub | undefined;
        if (!isolated) {
            hub = Sentry.makeMain(Sentry.getCurrentHub());
            const scope = hub.getScope();
            if (scope) {
                transaction = scope.getTransaction();
            }
        }
        transaction = Sentry.startTransaction({
            name: '[front] Custom: ' + transactionName,
            parentSpanId: transaction?.spanId,
            data: {
                [CUSTOM_TRANSACTION]: true,
                [ISOLATED_TRANSACTION]: isolated,
            },
        });

        const account = userManager.getCurrentAccount();
        transaction.setTag('accountId', account?.id);
        transaction.setTag('accountName', account?.name);
        // @ts-ignore
        logDebug('start transaction', transaction.name, transaction.data);
        if (hub) {
            hub.configureScope((scope) => scope.setSpan(transaction));
        }
        return transaction;
    }

    /**
     * @param transactionName
     * @param isolated // makes your transaction isolated from current hub: ongoing transaction won't be canceled
     **/
    static init(transactionName: string, isolated: boolean = false): Timer {
        return new Timer(Timer.getTransaction(transactionName, isolated));
    }

    /**
     *
     * @param operation
     * @param data
     * @param description
     * @param isTerminator // this timer would be stopped after child/children is finished
     */
    public startChild(operation: string, data: any = {}, description?: string, isTerminator: boolean = false): Timer {
        const span = this.startSpan(operation, data, description);
        const child = new Timer(span, this, new Set<Timer>());
        if (this.span.op === TIMER_OPERATIONS._terminator) {
            this.span.sampled = false;
        }
        this.children.add(child);
        if (isTerminator) {
            this.terminators.addTerminator(child);
        }
        return child;
    }

    public enrichData(data: any = {}): void {
        for (let key in data) {
            this.span.setData(key, data[key]);
        }
    }

    private onChildEnd(child: Timer): void {
        if (this.span.transaction?.data?.[ISOLATED_TRANSACTION] !== true) {
            Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(this.span));
        }

        if (this.terminators.isTerminator(child)) {
            this.terminators.tryTerminate();
        }
        if (!this.ended && this.span instanceof Transaction) {
            const transactionData = this.span.data;
            if (true === transactionData[CUSTOM_TRANSACTION]) {
                this.end();
            }
        }
    }

    public end(): void {
        if (this.ended) {
            return;
        }
        this.ended = true;

        for (let child of this.children) {
            child.end();
        }

        this.span.finish();
        if (this.span instanceof Transaction) {
            logDebug('end transaction', this.span.name, this.span.data);
        } else {
            logDebug('end span', this.span.op, this.span.data);
        }

        if (this.parent) {
            this.parent.onChildEnd(this);
        }
    }

    public getSpan(): Span {
        return this.span;
    }

    public getChildren(): Set<Timer> {
        return this.children;
    }

    private startSpan(operation: string, data: any = {}, description?: string): Span {
        const spanContext = {
            op: operation,
            data,
            description,
        };

        const span = this.span.startChild(spanContext);
        if (span.transaction?.data?.[ISOLATED_TRANSACTION] !== true) {
            Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(span));
        }
        logDebug('start span', operation, data);
        return span;
    }

    public tryTerminateAfter(ms: number, terminatorSpanData: any = {}): void {
        this.startChild(
            TIMER_OPERATIONS._terminator,
            terminatorSpanData,
            ms + 'ms terminator for async transaction. ends transaction if there are no active spans',
            true,
        ).setTimeout(ms);
    }

    public setTimeout(ms: number): void {
        setTimeout(() => {
            this.end();
        }, ms);
    }

    public removeEmptyTerminators(): void {
        this.getChildren().forEach((child) => {
            if (Timer.isEmptyTerminator(child)) {
                child.cancel();
            }
        });
    }

    private static isEmptyTerminator(timer: Timer): boolean {
        return timer.getSpan().op === TIMER_OPERATIONS._terminator && timer.getChildren().size === 0;
    }

    public cancel(): void {
        this.span.sampled = false;
        this.span.setStatus(SpanStatus.Cancelled);
        this.ended = true;
        logDebug('silently cancel span', this.span.op, this.span.data);
    }
}

class TimerTerminators {
    public readonly STRATEGY_TERMINATE_IF_NO_ACTIVE_CHILDREN = 0;
    public readonly STRATEGY_TERMINATE_WITH_FIRST_TRY = 1;

    private getTargetTimer: () => Timer;
    private terminators: Set<Timer>;
    private readonly strategy: number;

    constructor(targetTimer: Timer, strategy?: number) {
        this.getTargetTimer = () => targetTimer;
        this.terminators = new Set<Timer>();

        const availableStrategies = [
            this.STRATEGY_TERMINATE_IF_NO_ACTIVE_CHILDREN,
            this.STRATEGY_TERMINATE_WITH_FIRST_TRY,
        ];
        if (strategy === undefined) {
            strategy = this.STRATEGY_TERMINATE_IF_NO_ACTIVE_CHILDREN;
        }
        if (!availableStrategies.includes(strategy)) {
            strategy = this.STRATEGY_TERMINATE_IF_NO_ACTIVE_CHILDREN;
        }
        this.strategy = strategy;
    }

    public addTerminator(terminator: Timer): void {
        this.terminators.add(terminator);
    }

    public isTerminator(timer: Timer): boolean {
        return this.terminators.has(timer);
    }

    public getActiveChildren(): Set<Timer> {
        const activeTerminators = new Set<Timer>();
        this.getTargetTimer()
            .getChildren()
            .forEach((terminator) => {
                if (!terminator.ended) {
                    activeTerminators.add(terminator);
                }
            });
        return activeTerminators;
    }

    public tryTerminate(): boolean {
        switch (this.strategy) {
            case this.STRATEGY_TERMINATE_IF_NO_ACTIVE_CHILDREN:
                if (this.getActiveChildren().size === 0) {
                    this.terminate();
                    return true;
                }
                break;
            case this.STRATEGY_TERMINATE_WITH_FIRST_TRY:
                this.terminate();
                return true;
        }
        return false;
    }

    private terminate(): void {
        if (this.getRealChildren(this.getTargetTimer()).size > 0) {
            this.getTargetTimer().end();
            return;
        }
        this.getTargetTimer().cancel();
    }

    private getRealChildren(timer: Timer): Set<Timer> {
        let realChildren = new Set<Timer>();
        timer.getChildren().forEach((child) => {
            if (child.getSpan().op !== TIMER_OPERATIONS._terminator) {
                realChildren.add(child);
            }
            realChildren = new Set([...realChildren, ...this.getRealChildren(child)]);
        });
        return realChildren;
    }
}
