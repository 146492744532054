import { Grid as DataGrid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../DataTable';
import DateTime from '../DateTime';

class PropertiesTable extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                name: 'name',
                title: this.props.t('billing.properties_table.name_field'),
                type: 'string',
            },
            {
                name: 'value',
                title: this.props.t('billing.properties_table.value'),
            },
        ];

        this.filterMessages = DataTable.getFilterMessages(props.t);
    }

    render() {
        const sub = { ...this.props.subscription };
        let rows = [];
        const notShow = ['plan', 'id', 'trialDays', 'accountId', 'isBlocked', 'isGracePeriodExpired', 'isCustomized'];
        const datesType = [
            'nextBillDate',
            'cancellationEffectiveDate',
            'cancelledAt',
            'expiresAt',
            'paidLastAt',
            'subscriptionStartedAt',
            'subscriptionUpdatedAt',
            'trialStartedAt',
        ];
        Object.entries(sub).forEach(([key, value]) => {
            if (!notShow.includes(key)) {
                value = value !== null ? value + '' : '';
                if (value !== '' && datesType.includes(key)) {
                    value = <DateTime>{value}</DateTime>;
                }
                rows.push({ name: key, value: value });
            }
        });
        rows = rows.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        return (
            <DataGrid className="c-data-table" rows={rows} columns={this.columns}>
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />
                <Table />
                <TableHeaderRow />
                <TableFilterRow messages={this.filterMessages} />
            </DataGrid>
        );
    }
}

PropertiesTable.propTypes = {
    subscription: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(PropertiesTable);
