import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../service/workflow_actions/forms/Form';
import Label from '../../../../service/workflow_actions/forms/fields/Label';

import AbstractForm from './AbstractForm';

const Editor = lazy(() => import('@monaco-editor/react'));

class HtmlForm extends AbstractForm {
    _handleInputChange = (value) => {
        this.handleInputChange({
            target: {
                name: 'value',
                value,
            },
        });
    };

    renderFieldInputs(field, errors) {
        return null;
    }

    renderValueInput(field, errors) {
        return (
            <Suspense fallback={null}>
                <Editor
                    height="50vh"
                    language="html"
                    defaultValue={field.value || '<!-- write html here -->'}
                    onChange={this._handleInputChange}
                />
            </Suspense>
        );
    }
}

HtmlForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Label).isRequired,
};

export default HtmlForm;
