import React from 'react';
import clsx from 'clsx';
import './style.css';
import TextField from '@material-ui/core/TextField';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { withTranslation, WithTranslation } from 'react-i18next';
import Tag from './Tag';
import { Autocomplete } from '@material-ui/lab';

export interface Props extends WithTranslation, WithSnackbarProps {
    value?: string[];
    label?: string;
    fullWidth?: boolean;
    placeHolder?: string;
    onChange?: (tags: string[]) => void;
    separators?: string[];
    onExisting?: (tag: string) => void;
    onRemoved?: (tag: string) => void;
    disabled?: boolean;
    beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
    tagValidate: (tag: string, existingTags: string[]) => string;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    classNames?: {
        input?: string;
        tag?: string;
    };
}

interface State {
    tags: string[];
    errors: Map<string, string>;
}

const defaultSeparators = ['Enter'];

class FieldTagsInput extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tags: [],
            errors: new Map(),
        };

        this.componentDidUpdate(props, this.state);
    }

    componentDidUpdate(_: Readonly<Props>, prevState: Readonly<State>) {
        const value = this.props.value;
        const tags = this.state.tags;
        const oldTags = prevState.tags;

        if (oldTags !== tags) {
            return;
        }

        if (JSON.stringify(value) !== JSON.stringify(tags)) {
            value &&
                value.forEach((tag) => {
                    const err = this.props.tagValidate(tag, tags);
                    if (err !== '') {
                        this.state.errors.set(tag, err);
                    }
                });

            this.updateTags(value || []);
        }
    }

    updateTags(tags: string[]) {
        this.setState({ tags });
        this.props.onChange && this.props.onChange(tags);
    }

    addTag(tag: string) {
        const tags = this.state.tags;

        if (tags.includes(tag)) {
            !!this.props.onExisting && this.props.onExisting(tag);
            return;
        }

        const error = this.props.tagValidate(tag, tags);
        if (error !== '') {
            this.state.errors.set(tag, error);
        }

        this.updateTags([...tags, tag]);
    }

    handleOnKeyUp = (e: any) => {
        e.stopPropagation();

        const text = e.target.value;
        const tags = this.state.tags;

        const separators = [...(this.props.separators || []), ...defaultSeparators];

        if (text && separators.includes(e.key)) {
            e.preventDefault();
            if (!!this.props.beforeAddValidate && !this.props.beforeAddValidate(text, tags)) {
                return;
            }

            this.addTag(text);

            e.target.value = '';
        }
    };

    onBlur = (e: any) => {
        if (e.target.value) {
            this.addTag(e.target.value);
            e.target.value = '';
        }
    };

    onTagRemove = (text: string) => {
        const tags = this.state.tags;

        if (this.state.errors.has(text)) {
            this.state.errors.delete(text);
        }

        this.updateTags(tags.filter((tag) => tag !== text));
        this.props.onRemoved && this.props.onRemoved(text);
    };

    checkErrorTag = (tag: string): boolean => {
        return this.state.errors.has(tag);
    };

    render() {
        const { tags, errors } = this.state;
        const { label, classNames, disabled, placeHolder, onKeyUp } = this.props;

        return (
            <Autocomplete<string, true, true, true>
                multiple
                freeSolo
                disableClearable
                options={[]}
                fullWidth
                value={tags}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={clsx('field_email__input', classNames?.input)}
                        label={label}
                        placeholder={placeHolder}
                        type="text"
                        onKeyDown={this.handleOnKeyUp}
                        onBlur={this.onBlur}
                        disabled={disabled}
                        onKeyUp={onKeyUp}
                        error={errors.size > 0}
                        fullWidth
                        margin={'dense'}
                    />
                )}
                renderTags={(value) => {
                    return (
                        <React.Fragment>
                            {value.map((tag) => (
                                <Tag
                                    key={tag}
                                    className={clsx(
                                        classNames?.tag,
                                        this.checkErrorTag(tag) ? 'field_tag__tag--error' : '',
                                    )}
                                    text={tag}
                                    onRemove={this.onTagRemove}
                                    disabled={disabled}
                                />
                            ))}
                        </React.Fragment>
                    );
                }}
            />
        );
    }
}

export default withTranslation()(withSnackbar(FieldTagsInput));
