import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { isIncluded } from './PointsManager';
import dispatcher from './dispatcher';
import events from '../events';
import { v4 as uuidv4 } from 'uuid';
import chunk from 'lodash/chunk';
import {
    CreateLayer,
    CreateSourceDataFromGeoJson,
    GeoJson,
    Territory,
    TERRITORY_TYPE,
    TypeGeoJson,
} from '../components/Territories/model/Territory';
import { Bounds } from '../components/utils/MapBounds';
import { TerritoryGroup } from '../components/Territories/model/TerritoryGroup';

export const DEFAULT_COUNTRY = 'US';

export const AREA_CONTINENT = -1;

export enum AREA_TYPES {
    AREA_COUNTRY = 0,
    AREA_STATE = 1,
    AREA_COUNTY = 2,
    AREA_SUB_COUNTY = 3,
    AREA_TRUCK = 6,
    AREA_CENSUS_BLOCK_GROUP = 7,
    AREA_SA_4 = 8,
    AREA_DISSEMINATION = 9,
    AREA_ZIP_UK_7 = 10,
    AREA_ZIP = 4,
    AREA_NEIGHBORHOODS_NC = 5,
    AREA_SA_1 = 11,
    LOCAL_ADMINISTRATIVE_UNITS = 12,
    DISTRICTS = 13,
    AREA_SA_3 = 14,
    AREA_SA_2 = 15,
    AREA_ZIP_2021 = 16,
    AREA_MASH = 17,
    AREA_LOCAL_GOVERNMENT_AREAS = 18,
    MUNICIPALITIES = 19,
    NCES_DISTRICTS = 20,
    AREA_ZIP_2023 = 21,
    CANADA_3_DIGIT_POSTAL_CODES_2023 = 22,
}
// AreaType => ZoomLevel
export const RULES_LOADING_LAYER = {
    [AREA_TYPES.AREA_STATE]: null,
    [AREA_TYPES.AREA_COUNTY]: 5,
    [AREA_TYPES.AREA_SUB_COUNTY]: 7,
    [AREA_TYPES.AREA_TRUCK]: 7,
    [AREA_TYPES.AREA_ZIP]: 7,
    [AREA_TYPES.AREA_ZIP_2021]: 7,
    [AREA_TYPES.AREA_CENSUS_BLOCK_GROUP]: 9,
    [AREA_TYPES.AREA_NEIGHBORHOODS_NC]: 9,
    [AREA_TYPES.AREA_MASH]: 9,
    [AREA_TYPES.AREA_SA_4]: 5,
    [AREA_TYPES.AREA_SA_3]: 6,
    [AREA_TYPES.AREA_SA_2]: 7,
    [AREA_TYPES.AREA_SA_1]: 8,
    [AREA_TYPES.LOCAL_ADMINISTRATIVE_UNITS]: 8,
    [AREA_TYPES.AREA_DISSEMINATION]: 10,
    [AREA_TYPES.AREA_ZIP_UK_7]: 13,
    [AREA_TYPES.DISTRICTS]: 6,
    [AREA_TYPES.AREA_LOCAL_GOVERNMENT_AREAS]: 4,
    [AREA_TYPES.MUNICIPALITIES]: 6,
    [AREA_TYPES.NCES_DISTRICTS]: 8,
    [AREA_TYPES.AREA_ZIP_2023]: 7,
    [AREA_TYPES.CANADA_3_DIGIT_POSTAL_CODES_2023]: 7,
};

export interface GeoLibPart {
    id: string;
    typeArea: AREA_TYPES;
    geoJson: GeoJson;
    country: string;
}

export interface LocationName {
    type: number;
    value: string;
}

export interface Area {
    countryCode: string;
    level: number;
    typeArea: AREA_TYPES;
    visibleTypes: AREA_TYPES[];
}

export interface GeoJsonContainer {
    bounds: Bounds;
    geoJson: GeoJson;
}

export interface GeoPartsByNames {
    name: string;
    state?: string;
}

class GeoLibManager extends BackendService {
    private typeAreas: Map<string, Array<Area>> | null = null;
    private selected: Map<string, GeoLibPart> = new Map();
    private geoLibLoaded: Map<string, Map<Bounds, GeoJson>> = new Map();
    private currentCountry: string = DEFAULT_COUNTRY;
    private currentTypeArea: AREA_TYPES = AREA_TYPES.AREA_STATE;
    private currentBounds: Bounds | null = null;
    private currentZoom: number | null = null;
    private currentData: GeoLibPart[] = [];
    private enable = false;
    private loadingData = false;
    private loadingUUID: string | null = null;

    isEnable = () => {
        return this.enable;
    };

    setSelectedData = (selected: Map<string, GeoLibPart>) => {
        this.selected = selected;
        dispatcher.dispatch(events.GEO_LIB_SELECTED_DATA_CHANGE);
    };

    changeCurrentCountry(country: string) {
        this.currentCountry = country;
        this.reloadGeoLib();
    }

    changeCurrentCountryAndTypeArea(country: string, typeArea: AREA_TYPES) {
        this.currentCountry = country;
        this.currentTypeArea = typeArea;
        dispatcher.dispatch(events.GEO_LIB_CHANGE_MAIN_TYPE);
        this.reloadGeoLib();
    }

    changeCurrentTypeArea(typeArea: AREA_TYPES) {
        this.currentTypeArea = typeArea;
        dispatcher.dispatch(events.GEO_LIB_CHANGE_MAIN_TYPE);
        this.reloadGeoLib();
    }

    changeCurrentPosition(bounds: Bounds, zoom: number) {
        if (JSON.stringify(this.currentBounds) === JSON.stringify(bounds) && this.currentZoom === zoom) {
            return;
        }
        this.currentBounds = bounds;
        this.currentZoom = zoom;
        this.reloadGeoLib();
    }

    removeSelectedAreas = (areas: Array<GeoLibPart>) => {
        if (areas.length === 0) {
            return;
        }

        this.selected = new Map(this.selected);
        areas.forEach((item) => {
            let geoPartLib = this.selected.get(item.id);
            if (geoPartLib && geoPartLib.typeArea === this.currentTypeArea) {
                this.selected.delete(item.id);
            }
        });
        dispatcher.dispatch(events.GEO_LIB_SELECTED_DATA_CHANGE);
    };

    removeSelectedArea = (id: string, ignoreType: boolean = false) => {
        const geoLibPart = this.selected.get(id);

        if (geoLibPart === undefined || (geoLibPart.typeArea !== this.currentTypeArea && !ignoreType)) {
            return;
        }

        this.selected = new Map(this.selected);
        this.selected.delete(id);
        dispatcher.dispatch(events.GEO_LIB_SELECTED_DATA_CHANGE);
    };

    addSelectedAreas = (areas: Array<GeoLibPart>) => {
        if (areas.length === 0) {
            return;
        }

        this.selected = new Map(this.selected);
        areas.forEach((item) => {
            if (item.typeArea === this.currentTypeArea) {
                this.selected.set(item.id, item);
            }
        });

        dispatcher.dispatch(events.GEO_LIB_SELECTED_DATA_CHANGE);
    };

    getGeoLibData() {
        return this.currentData;
    }

    getSelectedGeoData() {
        return this.selected;
    }

    getMainTypeArea = () => {
        return this.currentTypeArea;
    };

    enableGeoLib() {
        this.enable = true;
        this.reloadGeoLib();
    }

    disableGeoLib() {
        this.enable = false;
        this.selected = new Map();
        this.currentData = [];
        this.currentCountry = 'US';
        this.currentTypeArea = AREA_TYPES.AREA_STATE;
        dispatcher.dispatch(events.GEO_LIB_DATA_CHANGE);
    }

    getGeoData(bounds: Bounds, country: string, typeArea: AREA_TYPES): Promise<GeoJsonContainer> {
        let data = this.findAlreadyLoadedBound(bounds, country, typeArea);

        if (null !== data) {
            return Promise.resolve(data);
        }
        this.loadingData = true;
        dispatcher.dispatch(events.GEO_LIB_LOAD_DATA);

        return this.requestApi(reverse(apiRoutes.geoLib), 'GET', { bounds, type: typeArea, country }).then(
            (geoJson: GeoJson) => {
                let key = this.getKey(country, typeArea);
                let boundsLoaded: Map<Bounds, GeoJson> = new Map();
                if (this.geoLibLoaded.has(key)) {
                    // @ts-ignore
                    boundsLoaded = this.clearCache(this.geoLibLoaded.get(key));
                }
                if (geoJson.type === TypeGeoJson.FEATURE_COLLECTION || geoJson.type === TypeGeoJson.FEATURE) {
                    geoJson = CreateSourceDataFromGeoJson(geoJson as GeoJson).fullSourceData;
                }
                boundsLoaded.set(bounds, geoJson);
                this.geoLibLoaded.set(key, boundsLoaded);
                return { bounds: bounds, geoJson };
            },
        );
    }

    isLoadingData = () => {
        return this.loadingData;
    };

    clearCache(boundsLoaded: Map<Bounds, GeoJson>) {
        if (boundsLoaded.size < 100) {
            return boundsLoaded;
        }

        let boundsLoadedNew: Map<Bounds, GeoJson> = new Map();

        boundsLoaded.forEach((data, key) => {
            if (boundsLoadedNew.size < 100) {
                boundsLoadedNew.set(key, data);
            }
        });
        boundsLoaded.clear();
        return boundsLoadedNew;
    }

    reloadGeoLib = () => {
        if (!this.enable) {
            return;
        }

        const country = this.currentCountry;
        const typeArea = this.currentTypeArea;
        const bounds = this.currentBounds;
        const zoom = this.currentZoom;
        const uuid = uuidv4();
        this.loadingUUID = uuid;

        if (zoom === null || bounds === null) {
            return;
        }

        let types: AREA_TYPES[] = [];
        this.getAllAreaTypes()
            .then((mapArea) => {
                let countryAreas = mapArea.get(country);
                const currentType = countryAreas?.find((item) => {
                    return item.typeArea === typeArea;
                });

                currentType?.visibleTypes.forEach((type) => {
                    // @ts-ignore
                    if (typeArea >= type && (RULES_LOADING_LAYER[type] == null || zoom > RULES_LOADING_LAYER[type])) {
                        types.push(type);
                    }
                });

                let loadedTypes: GeoLibPart[] = [];
                types.forEach((loadTypeArea) => {
                    this.getGeoData(bounds, country, loadTypeArea).then((response) => {
                        if (uuid !== this.loadingUUID) {
                            return;
                        }

                        loadedTypes.push({
                            typeArea: loadTypeArea,
                            id: this.getKey(country, loadTypeArea) + '_' + JSON.stringify(response.bounds),
                            geoJson: response.geoJson,
                            country: country,
                        });
                        if (loadedTypes.length === types.length) {
                            loadedTypes.sort((a, b) => {
                                return a.typeArea >= b.typeArea ? 1 : 0;
                            });
                            this.currentData = loadedTypes;
                            this.loadingData = false;
                            dispatcher.dispatch(events.GEO_LIB_DATA_CHANGE);
                        }
                    });
                });
            })
            .catch(() => {
                this.currentData = [];
                this.loadingData = false;
                dispatcher.dispatch(events.GEO_LIB_DATA_CHANGE);
            });
    };

    getKey = (country: string, typeArea: AREA_TYPES) => {
        return typeArea + '_' + country;
    };

    findAlreadyLoadedBound(bounds: Bounds, country: string, typeArea: AREA_TYPES): GeoJsonContainer | null {
        let result = null;
        const loadedBounds = this.geoLibLoaded.get(this.getKey(country, typeArea));

        if (!loadedBounds) {
            return result;
        }

        loadedBounds.forEach((geoJson, loadedBound) => {
            if (isIncluded(bounds, loadedBound)) {
                result = { bounds: loadedBound, geoJson };
            }
        });

        return result;
    }

    getAllAreaTypes = (): Promise<Map<string, Area[]>> => {
        if (this.typeAreas !== null) {
            return Promise.resolve(this.typeAreas);
        }
        return this.requestApi(apiRoutes.geoLib.typeAreas, 'GET').then((data: Array<Area>) => {
            const typeAreas: Map<string, Area[]> = new Map();
            data.forEach((item: Area) => {
                if (typeAreas.has(item.countryCode)) {
                    // @ts-ignore
                    typeAreas.get(item.countryCode).push(item);
                } else {
                    typeAreas.set(item.countryCode, [item]);
                }
            });
            this.typeAreas = typeAreas;
            return this.typeAreas;
        });
    };

    findGeoPartsByNames = (elements: GeoPartsByNames[]) => {
        const country = this.currentCountry;
        const typeArea = this.currentTypeArea;

        return this.requestApi(apiRoutes.geoLib.findByNames, 'POST', { country, type: typeArea, elements: elements });
    };

    findGeoParts = (name: string) => {
        const country = this.currentCountry;
        const typeArea = this.currentTypeArea;

        return this.requestApi(apiRoutes.geoLib.find, 'POST', { country, type: typeArea, name: name });
    };

    findGeoPartsByIds = (ids: number[], originalSize = false): Promise<GeoJson> => {
        if (ids === null || ids.length === 0) {
            return Promise.resolve({ features: [] });
        }

        const idsChunks = chunk(ids, 500);
        let result: GeoJson = { features: [] };

        const callBack = (geoJson: GeoJson): Promise<GeoJson> => {
            result.features = result.features.concat(geoJson.features);
            if (idsChunks.length === 0) {
                return Promise.resolve(result);
            }
            return loader();
        };

        const loader = () => {
            if (idsChunks.length === 0) {
                return Promise.resolve(result);
            }
            const idsChunk = idsChunks.pop();
            return this.requestApi(apiRoutes.geoLib.findByIds, 'POST', { ids: idsChunk, originalSize })
                .then((parts: any) => {
                    return callBack(parts);
                })
                .catch((e: Error) => {
                    console.log(e);
                    return Promise.reject(e);
                });
        };

        return loader();
    };

    setMetricsValues(territoryGroup: TerritoryGroup | null, territory: Territory, properties: any) {
        if (!territoryGroup || !territory) {
            return;
        }
        territoryGroup.metrics.forEach((metric) => {
            let metricValue = territory.metricsValues.find((metricValue) => metricValue.code === metric.code);
            properties[metric.code] = metricValue !== undefined ? metricValue.value : 0;
        });
    }

    makeGeoJsonByTerritoryGroup = (territoryGroup: TerritoryGroup, manager: any, addInnerGeoJson: boolean = false) => {
        if (!territoryGroup) {
            return Promise.resolve({});
        }

        let territoriesGeoLib: Territory[] = [];
        let autoTerritoriesGeoLib = new Map();
        let otherTerritories: Territory[] = [];

        const promiseIds = new Promise((resolve) => {
            let ids: number[] = [];
            setTimeout(() => {
                territoryGroup.territories.forEach((territory) => {
                    if (territory.mode === TERRITORY_TYPE.GEO_LIB) {
                        if (!territory.isCreatedAuto) {
                            ids = ids.concat(territory.geoPartIds);
                            territoriesGeoLib.push(territory);
                        } else if (territory.geoPartIds[0] !== undefined) {
                            autoTerritoriesGeoLib.set(territory.geoPartIds[0].toString(), territory);
                        }
                    } else {
                        otherTerritories.push(territory);
                    }
                });
                resolve(ids);
            }, 1000);
        });

        return promiseIds.then((ids) => {
            return Promise.all([
                this.findGeoPartsByIds(territoryGroup.geoPartIds, true),
                // @ts-expect-error
                this.findGeoPartsByIds(ids, true),
            ]).then((result) => {
                let geoJson = result[0];
                geoJson.features = geoJson.features.map((item) => {
                    let territory = autoTerritoriesGeoLib.get(item.properties.partId);
                    item.properties = { NAME: item.properties.NAME, COLOR: territoryGroup.color };
                    this.setMetricsValues(territoryGroup, territory, item.properties);
                    return item;
                });

                geoJson = this.appendToGeoJson(geoJson, territoriesGeoLib, result[1], addInnerGeoJson, territoryGroup);

                // @ts-ignore
                geoJson.type = TypeGeoJson.FEATURE_COLLECTION;
                return new Promise((resolve) => {
                    let promises: Promise<any>[] = [];
                    otherTerritories.forEach((territory) => {
                        promises.push(
                            // @ts-expect-error
                            manager.getTerritoryGeoJson(territory.uuid).then((response) => {
                                return { territory, response };
                            }),
                        );
                    });

                    Promise.all(promises).then((responses) => {
                        responses.forEach((data) => {
                            if (data && data.response) {
                                let layer = CreateLayer(data.territory, data.response.sourceData);
                                if (layer) {
                                    geoJson = this.appendToGeoJson(
                                        geoJson,
                                        [data.territory],
                                        // @ts-expect-error
                                        layer.toGeoJSON(),
                                        addInnerGeoJson,
                                        territoryGroup,
                                    );
                                }
                            }
                        });
                        resolve(geoJson);
                    });
                });
            });
        });
    };

    makeGeoJsonByTerritory = (territory: Territory, manager: any) => {
        if (territory === null) {
            return Promise.resolve({});
        }
        const group = manager.getGroupByTerritory(territory);
        const GetGeoJson = new Promise((resolve, reject) => {
            if (territory.mode !== TERRITORY_TYPE.GEO_LIB) {
                // @ts-ignore
                manager.getTerritoryGeoJson(territory.uuid).then((response) => {
                    let layer = CreateLayer(territory, response.sourceData);
                    if (layer) {
                        resolve(layer.toGeoJSON());
                    }
                });
            } else {
                this.findGeoPartsByIds(territory.geoPartIds, true)
                    .then((geoLibParts) => {
                        resolve(geoLibParts);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
        // @ts-ignore
        return GetGeoJson.then((geoLibParts: GeoJson) => {
            let geoJson = { type: TypeGeoJson.FEATURE_COLLECTION, features: [] };
            return this.appendToGeoJson(geoJson, [territory], geoLibParts, false, group);
        });
    };

    appendToGeoJson(
        geoJson: GeoJson,
        territories: Territory[],
        geoLibParts: GeoJson,
        addInnerGeoJson = false,
        group: TerritoryGroup | null = null,
    ) {
        let otherGeoPartsMap = new Map();

        if (geoLibParts.features) {
            geoLibParts.features.forEach((item) => {
                otherGeoPartsMap.set(item.properties.partId, item);
            });
        }

        territories.forEach((territory) => {
            let territoryGeoJson = [];
            let partsJson: GeoJson[] = [];
            if (territory.mode === TERRITORY_TYPE.GEO_LIB) {
                territory.geoPartIds.forEach((id) => {
                    if (otherGeoPartsMap.has(id.toString())) {
                        territoryGeoJson.push(otherGeoPartsMap.get(id.toString()).geometry);
                        partsJson.push(otherGeoPartsMap.get(id.toString()));
                    }
                });
            } else if (geoJson !== undefined) {
                let geoJsonHand = geoLibParts;
                if (geoJsonHand.features !== undefined) {
                    geoJsonHand.features.forEach((item) => {
                        territoryGeoJson.push(item.geometry);
                    });
                } else {
                    territoryGeoJson.push(geoJsonHand);
                }
            }

            let item = {
                type: 'Feature',
                properties: { NAME: territory.name, COLOR: territory.color },
                geometry: { type: 'GeometryCollection', geometries: territoryGeoJson },
            };
            this.setMetricsValues(group, territory, item.properties);

            if (addInnerGeoJson && territory.mode === TERRITORY_TYPE.GEO_LIB) {
                // @ts-ignore
                item.properties.INNER_GEO_JSON = this.makeInnerGeoJson(partsJson);
            }

            geoJson.features.push(item);
        });
        return geoJson;
    }

    makeInnerGeoJson(partsJson: GeoJson[]) {
        let geoJson = { type: TypeGeoJson.FEATURE_COLLECTION, features: [] };
        partsJson.forEach((item) => {
            let cloneItem = { ...item };
            cloneItem.properties = Object.keys(cloneItem.properties)
                .filter((key) => !['location', 'partId', 'typeArea'].includes(key))
                .reduce((obj, key) => {
                    // @ts-ignore
                    obj[key] = cloneItem.properties[key];
                    return obj;
                }, {});
            // @ts-ignore
            geoJson.features.push(cloneItem);
        });
        return geoJson;
    }
}

export const geoLibManager = new GeoLibManager();
