import dispatcher from './dispatcher';
import events from '../events';
import { GEO_FIELDS } from '../references/geoFields';
import { recordManager } from './RecordManager';
import metadataManager from './MetadataManager';

class ViewRecordManager {
    constructor() {
        /** @type {ViewRecord} */
        this.record = null;
    }

    reset() {
        this.record = null;
    }

    getRecord() {
        return this.record;
    }

    viewRecord(entityId, recordId) {
        // todo: check if typecasting needed (to deal with ts modules and MapPage.handleFindOnMap()
        if (!entityId || !recordId) {
            return;
        }

        let pinField = null;
        metadataManager
            .requestEntityForUser(entityId)
            .then((entity) => {
                if (entity.pinField) {
                    for (const field of entity.fields) {
                        if (field.apiName === entity.pinField) {
                            pinField = field.isIncluded ? field : null;
                            break;
                        }
                    }
                }
                return recordManager.getRecord(entityId, recordId);
            })
            .then(({ record }) => {
                this.record = {
                    entityId,
                    recordId,
                    objectName: record.objectName,
                    address: pinField ? record[pinField.apiName] ?? null : null,
                    lat: record[GEO_FIELDS.LAT],
                    lng: record[GEO_FIELDS.LNG],
                    countryShort: record.addressData?.countryShort ?? null,
                    addressFields: record.addressFields ?? {},
                    mapsly_link: record.mapsly_link ?? null,
                    geoStatus: record[GEO_FIELDS.STATUS],
                };
                dispatcher.dispatch(events.EVENT_VIEW_RECORD, this.record);
            })
            .catch(({ code }) => {
                if (code === 404 || code === 403) {
                    dispatcher.dispatch(events.EVENT_VIEW_RECORD, null);
                }
            });
    }
}

export default new ViewRecordManager();
