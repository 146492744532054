import React from 'react'; // eslint-disable-line no-unused-vars
import FormDialog from '../FormDialog';
import { withTranslation } from 'react-i18next';

class ChangesNotificationFormDialog extends FormDialog {
    constructor(props) {
        super(props);
        this.saveButtonTitle = props.t('data_source.changes_notification_form_dialog.ok');
    }
}

export default withTranslation()(ChangesNotificationFormDialog);
