import React from 'react';
import events from '../../../events';
import dispatcher from '../../../service/dispatcher';
import Tooltip from '@material-ui/core/Tooltip';

class PicklistFormatter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            picklist: this.props.column.picklist || [],
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_FIELD_PICKLIST_CHANGED, this, ({ fieldId, picklist }) => {
            if (fieldId === this.props.column.id) {
                this.setState({
                    picklist: picklist || [],
                });
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { picklist } = this.state;

        const value = this.props.value;
        if (value === undefined || value === null) {
            return null;
        }

        const item = picklist.find((item) => item.value === value);
        if (!item) {
            return value;
        }

        return (
            <Tooltip title={item.label}>
                <span>{item.label}</span>
            </Tooltip>
        );
    }
}

export default PicklistFormatter;
