import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import WorkflowActionsHeader from './WorkflowActionsHeader';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import TabPanel from '../TabPanel';
import {
    ACTION_TYPE_LIST,
    TYPE_CREATE_RECORD,
    TYPE_FIELD_UPDATE,
    TYPE_INVOKE_URL,
    TYPE_MATCH_RECORDS,
    TYPE_OPEN_FORM,
    TYPE_OPEN_URL,
    TYPE_SEND_EMAIL,
    TYPE_SHOW_MESSAGE,
    TYPE_UPDATE_FORM,
    TYPE_DELETE_RECORD,
    TYPE_VISIT_PLANNER,
    workflowActionManager,
    TYPE_SEND_SMS,
} from '../../service/WorkflowActionManager';
import { FieldUpdateList } from './FieldUpdate';
import { InvokeUrlList } from './InvokeUrl';
import ActionModals from './ActionModals';
import dsManagerFactory from '../../service/DsManager';
import { accountsManager } from '../../service/AccountsManager';
import { CreateRecordList } from './CreateRecord';
import { VisitPlannerList } from './VisitPlanner';
import { SendEmailList } from './SendEmail';
import { MatchRecordsList } from './MatchRecords/List';
import { ShowMessageList } from './ShowMessage';
import { OpenUrlList } from './OpenUrl';
import { OpenFormList } from './OpenForm';
import { UpdateFormList } from './UpdateForm';
import { DeleteRecordList } from './DeleteRecord';
import { SendSmsList } from './SendSms';
import dispatcher from '../../service/dispatcher';
import events from '../../events';

const defaultRows = ACTION_TYPE_LIST.map((item) => {
    return { [item]: [] };
});

const StyledTab = withStyles({
    root: {
        minWidth: 'auto',
        padding: '6px 20px',
        '&:hover': {
            opacity: '0.9',
        },
    },
})(Tab);

class WorkflowActions extends React.PureComponent {
    constructor(props) {
        super(props);

        const currentTab =
            props.tab && ACTION_TYPE_LIST.find((item) => item.value === props.tab) ? props.tab : TYPE_CREATE_RECORD;

        this.state = {
            loading: true,
            requestParams: {
                sorting: [{ columnName: 'createdAt', direction: 'desc' }],
                filters: [],
                currentPage: 0,
                pageSize: 10,
            },
            rows: defaultRows,
            account: null,
            dataSources: [],
            totalCount: 0,
            currentAction: null,
            currentTab,
        };
    }

    componentDidMount() {
        this.loadActions();
        this.loadAccount();
        this.loadDataSources();

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.status === 'complete') {
                this.loadDataSources();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadActions = (requestParams = { filters: [], sorting: [], currentPage: 0, pageSize: 25 }) => {
        this.setState({ loading: true, rows: defaultRows, totalCount: 0 });
        const loadType = this.state.currentTab;

        return workflowActionManager
            .list(
                this.props.accountId,
                this.state.currentTab,
                requestParams.filters,
                requestParams.sorting,
                requestParams.currentPage + 1,
                requestParams.pageSize,
            )
            .then((data) => {
                this.setState((state) => {
                    return {
                        loading: false,
                        totalCount: data.total,
                        rows: { ...state.rows, [loadType]: data.items },
                    };
                });
                return data;
            });
    };

    loadAccount = () => {
        accountsManager
            .load(this.props.accountId)
            .then((account) => {
                this.setState({ account });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    loadDataSources = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();

        this.setState({ dataSources: [] });

        manager
            .list()
            .then((data) => {
                data = data.map((source) => {
                    source.entities = source.entityCounters.map((entityCounter) => entityCounter.entity);
                    return source;
                });
                this.setState({ dataSources: data });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    saveAction = (action) => {
        const { currentAction } = this.state;
        this.closeActionModal();
        this.props.enqueueSnackbar(this.props.t('workflow_actions.notification.saved'), { variant: 'success' });
        this.setState((state) => {
            const updateRecordRows = state.rows[currentAction.type] ?? [];
            const currentTabRows = updateRecordRows.filter((a) => a.id !== currentAction.id);
            currentTabRows.unshift(action);
            return {
                rows: {
                    ...state.rows,
                    [action.type]: currentTabRows,
                },
                totalCount: state.totalCount + 1,
            };
        });
    };

    removeAction = (action) => {
        workflowActionManager.delete(this.props.accountId, action.id).then(() => {
            this.setState((state) => {
                return {
                    rows: {
                        ...state.rows,
                        [action.type]: state.rows[action.type].filter((a) => a.id !== action.id),
                    },
                    totalCount: state.totalCount - 1,
                };
            });
        });
    };

    handleChangeTab = (e, tab) => {
        this.setState({ currentTab: tab }, () => {
            this.loadActions();
        });
    };

    openActionModal = (action = null, type = null) => {
        if (action === null) {
            action = workflowActionManager.constructor.getDefaultAction(this.state.account, type);
        }

        this.setState({
            currentAction: action,
        });
    };

    closeActionModal = () => {
        this.setState({ currentAction: null });
    };

    getRows = (type) => {
        return this.state.rows[type] || [];
    };

    render() {
        const showAddButton =
            !!this.state.account && Array.isArray(this.state.dataSources) && this.state.dataSources.length > 0;
        return (
            <React.Fragment>
                <WorkflowActionsHeader
                    showAddButton={showAddButton}
                    accountId={this.props.accountId}
                    myAccount={this.props.myAccount}
                    onOpenModal={this.openActionModal}
                    title={this.props.t('main_menu.workflow_actions')}
                    addButtonHint={this.props.t('workflow_actions.hint.create')}
                    type={'actions'}
                />
                <Tabs
                    indicatorColor="primary"
                    value={this.state.currentTab}
                    onChange={this.handleChangeTab}
                    variant="scrollable"
                >
                    <StyledTab value={TYPE_CREATE_RECORD} label={this.props.t('workflow_actions.tab.create_record')} />
                    <StyledTab value={TYPE_FIELD_UPDATE} label={this.props.t('workflow_actions.tab.field_update')} />
                    <StyledTab value={TYPE_DELETE_RECORD} label={this.props.t('workflow_actions.tab.delete_record')} />
                    <StyledTab value={TYPE_MATCH_RECORDS} label={this.props.t('workflow_actions.tab.match_record')} />
                    <StyledTab value={TYPE_INVOKE_URL} label={this.props.t('workflow_actions.tab.invoke_url')} />
                    <StyledTab value={TYPE_SHOW_MESSAGE} label={this.props.t('workflow_actions.tab.show_message')} />
                    <StyledTab value={TYPE_OPEN_URL} label={this.props.t('workflow_actions.tab.open_url')} />
                    <StyledTab value={TYPE_OPEN_FORM} label={this.props.t('workflow_actions.tab.open_form')} />
                    <StyledTab value={TYPE_UPDATE_FORM} label={this.props.t('workflow_actions.tab.update_form')} />
                    <StyledTab value={TYPE_SEND_EMAIL} label={this.props.t('workflow_actions.tab.send_email')} />
                    <StyledTab value={TYPE_SEND_SMS} label={this.props.t('workflow_actions.tab.send_sms')} />
                    <StyledTab value={TYPE_VISIT_PLANNER} label={this.props.t('workflow_actions.tab.visit_planner')} />
                </Tabs>
                <TabPanel index={TYPE_FIELD_UPDATE} value={this.state.currentTab}>
                    <FieldUpdateList
                        rows={this.getRows(TYPE_FIELD_UPDATE)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_MATCH_RECORDS} value={this.state.currentTab}>
                    <MatchRecordsList
                        rows={this.getRows(TYPE_MATCH_RECORDS)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_INVOKE_URL} value={this.state.currentTab}>
                    <InvokeUrlList
                        rows={this.getRows(TYPE_INVOKE_URL)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_SHOW_MESSAGE} value={this.state.currentTab}>
                    <ShowMessageList
                        rows={this.getRows(TYPE_SHOW_MESSAGE)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_OPEN_URL} value={this.state.currentTab}>
                    <OpenUrlList
                        rows={this.getRows(TYPE_OPEN_URL)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_OPEN_FORM} value={this.state.currentTab}>
                    <OpenFormList
                        rows={this.getRows(TYPE_OPEN_FORM)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_UPDATE_FORM} value={this.state.currentTab}>
                    <UpdateFormList
                        rows={this.getRows(TYPE_UPDATE_FORM)}
                        total={this.state.totalCount}
                        requestParams={this.state.requestParams}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_CREATE_RECORD} value={this.state.currentTab}>
                    <CreateRecordList
                        rows={this.getRows(TYPE_CREATE_RECORD)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_DELETE_RECORD} value={this.state.currentTab}>
                    <DeleteRecordList
                        rows={this.getRows(TYPE_DELETE_RECORD)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_SEND_EMAIL} value={this.state.currentTab}>
                    <SendEmailList
                        rows={this.getRows(TYPE_SEND_EMAIL)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_SEND_SMS} value={this.state.currentTab}>
                    <SendSmsList
                        rows={this.getRows(TYPE_SEND_SMS)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                    />
                </TabPanel>
                <TabPanel index={TYPE_VISIT_PLANNER} value={this.state.currentTab}>
                    <VisitPlannerList
                        rows={this.getRows(TYPE_VISIT_PLANNER)}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        dataSources={this.state.dataSources}
                        onRequestData={this.loadActions}
                        onActionEdit={this.openActionModal}
                        onActionRemove={this.removeAction}
                        accountId={this.props.accountId}
                    />
                </TabPanel>

                {!!this.state.account && (
                    <ActionModals
                        account={this.state.account}
                        action={this.state.currentAction}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources}
                        onSave={this.saveAction}
                        onCancel={this.closeActionModal}
                    />
                )}
            </React.Fragment>
        );
    }
}

WorkflowActions.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    myAccount: PropTypes.bool.isRequired,
    tab: PropTypes.string,
};

WorkflowActions.defaultProps = {
    myAccount: false,
};

export default withTranslation()(withRouter(withSnackbar(WorkflowActions)));
