import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { PERMISSION_HIDE, PERMISSION_MODIFY, PERMISSION_VIEW } from './constants';
import Icon from '@material-ui/core/Icon';
import FormDialog from '../FormDialog';
import FieldsForm from './FieldsForm';
import EntityManager from '../../service/EntityManager';
import Tooltip from '@material-ui/core/Tooltip';
import { sortFunc } from '../../utils';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { USER_ROLE } from '../../service/UserManager';

export const getConfigurablePermissionsFields = (fields) => {
    return fields
        .filter((f) => f.isIncluded && !f.isDeleted && !f.isVirtual && !f.isSystemVisible && f.apiName !== 'id')
        .sort(sortFunc('label'));
};

class ProfilePermissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ownerFields: null,
            nonOwnerFields: null,
        };
    }

    onChangeOwner = (profileCode, entityId) => (e) => {
        this.props.onChangeOwner(profileCode, entityId, e.target.value);
    };

    onChangeOwnerFields = (profile, entity, ownerPermission, fieldsPermissions) => () => {
        this.setState(
            {
                ownerFields: { profile, entity, ownerPermission, fieldsPermissions, fields: null },
            },
            () => {
                EntityManager.getManager(profile.accountId, this.props.dataSourceId)
                    .getEntity(entity.id, true)
                    .then(({ fields }) => {
                        const { ownerFields } = this.state;
                        if (
                            ownerFields === null ||
                            ownerFields.profile.id !== profile.id ||
                            ownerFields.entity.id !== entity.id
                        ) {
                            return;
                        }
                        this.setState((state) => {
                            return {
                                ownerFields: { ...ownerFields, fields: getConfigurablePermissionsFields(fields) },
                            };
                        });
                    });
            },
        );
    };

    onChangeNonOwner = (profileCode, entityId) => (e) => {
        this.props.onChangeNonOwner(profileCode, entityId, e.target.value);
    };

    onChangeNonOwnerFields = (profile, entity, nonOwnerPermission, fieldsPermissions) => () => {
        this.setState(
            {
                nonOwnerFields: { profile, entity, nonOwnerPermission, fieldsPermissions, fields: null },
            },
            () => {
                EntityManager.getManager(profile.accountId, this.props.dataSourceId)
                    .getEntity(entity.id, true)
                    .then(({ fields }) => {
                        const { nonOwnerFields } = this.state;
                        if (
                            nonOwnerFields === null ||
                            nonOwnerFields.profile.id !== profile.id ||
                            nonOwnerFields.entity.id !== entity.id
                        ) {
                            return;
                        }
                        this.setState((state) => {
                            return {
                                nonOwnerFields: { ...nonOwnerFields, fields: getConfigurablePermissionsFields(fields) },
                            };
                        });
                    });
            },
        );
    };

    onToggle = (profileCode, entityId, defaultPermissions) => (e) => {
        this.props.toggle(profileCode, entityId, e.target.checked, defaultPermissions);
    };

    handleSaveOwnerFields = (settings) => {
        const { profile, entity } = this.state.ownerFields;
        this.props.onChangeOwnerFields(profile.code, entity.id, settings);
        this.setState({
            ownerFields: null,
        });
    };

    handleCloseOwnerFields = () => {
        this.setState({
            ownerFields: null,
        });
    };

    handleSaveNonOwnerFields = (settings) => {
        const { profile, entity } = this.state.nonOwnerFields;
        this.props.onChangeNonOwnerFields(profile.code, entity.id, settings);
        this.setState({
            nonOwnerFields: null,
        });
    };

    handleCloseNonOwnerFields = () => {
        this.setState({
            nonOwnerFields: null,
        });
    };

    render() {
        const { profile, entities, permissions, subscription, t } = this.props;
        const sharedMapAllowEdit = profile.forSharedMap && subscription ? subscription.sharedMapAllowDataEditing : true;
        const disableEdit = sharedMapAllowEdit === false ? t('shared_map.feature.edit.disabled') : null;
        return (
            <Grid container direction="column" alignItems="center">
                {entities.map((entity) => {
                    const entityPermissions = permissions[entity.id] || undefined;
                    let ownerPermission = disableEdit ? PERMISSION_VIEW : PERMISSION_MODIFY;
                    let nonOwnerPermission = disableEdit ? PERMISSION_VIEW : PERMISSION_HIDE;
                    const defaultPermissions = {
                        ownerPermission: profile.forSharedMap ? PERMISSION_VIEW : ownerPermission,
                        nonOwnerPermission: profile.forSharedMap
                            ? PERMISSION_VIEW
                            : profile.code === USER_ROLE.ADMIN
                            ? ownerPermission
                            : nonOwnerPermission,
                    };
                    let checked = false;
                    let checkedRecordSharing = false;
                    if (entityPermissions && entityPermissions['ownerPermission'] !== undefined) {
                        ownerPermission = entityPermissions['ownerPermission'];
                    }
                    if (entityPermissions && entityPermissions['nonOwnerPermission'] !== undefined) {
                        nonOwnerPermission = entityPermissions['nonOwnerPermission'];
                    }
                    if (entityPermissions && entityPermissions['isAvailable'] !== undefined) {
                        checked = entityPermissions['isAvailable'];
                    }
                    if (entityPermissions && entityPermissions['isRecordSharingAvailable'] !== undefined) {
                        checkedRecordSharing = entityPermissions['isRecordSharingAvailable'];
                    }
                    const ownerFieldPermissions =
                        entityPermissions && entityPermissions['ownerFieldPermissions']
                            ? entityPermissions['ownerFieldPermissions']
                            : {};
                    const nonOwnerFieldPermissions =
                        entityPermissions && entityPermissions['nonOwnerFieldPermissions']
                            ? entityPermissions['nonOwnerFieldPermissions']
                            : {};
                    const ownerDisabled = ownerPermission === PERMISSION_HIDE;
                    const nonOwnerDisabled = nonOwnerPermission === PERMISSION_HIDE;
                    const rules =
                        entityPermissions && entityPermissions['recordSharingRules'] !== undefined
                            ? entityPermissions['recordSharingRules']
                            : [];
                    return (
                        <Grid item key={entity.id} className="c-permission__profile-entity">
                            <FormControlLabel
                                className="c-permission__profile-entity__switch"
                                margin="dense"
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={this.onToggle(profile.code, entity.id, defaultPermissions)}
                                        color="primary"
                                        data-testid="permissions.profile_permissions.toggle"
                                    />
                                }
                                label=""
                            />
                            {checked ? (
                                <ul>
                                    <li>
                                        <Grid container justifyContent="space-between">
                                            <Select
                                                className="c-permission__profile-entity__select"
                                                value={ownerPermission}
                                                onChange={this.onChangeOwner(profile.code, entity.id)}
                                                data-testid="permissions.profile_permissions.select.owner"
                                            >
                                                <MenuItem
                                                    value={PERMISSION_HIDE}
                                                    data-testid="permissions.profile_permissions.select.hide"
                                                >
                                                    {t('permissions.profile_permissions.select.hide')}
                                                </MenuItem>
                                                <MenuItem
                                                    value={PERMISSION_VIEW}
                                                    data-testid="permissions.profile_permissions.select.view"
                                                >
                                                    {t('permissions.profile_permissions.select.view')}
                                                </MenuItem>
                                                <MenuItem
                                                    value={PERMISSION_MODIFY}
                                                    disabled={!!disableEdit}
                                                    data-testid="permissions.profile_permissions.select.modify"
                                                >
                                                    {t('permissions.profile_permissions.select.modify')}
                                                </MenuItem>
                                            </Select>
                                            {ownerDisabled ? (
                                                <IconButton
                                                    disabled={true}
                                                    data-testid="permissions.profile_permissions.more.owner"
                                                >
                                                    <Icon>more_vert</Icon>
                                                </IconButton>
                                            ) : (
                                                <Tooltip title={t('permissions.profile_permissions.more.tooltip')}>
                                                    <IconButton
                                                        onClick={this.onChangeOwnerFields(
                                                            profile,
                                                            entity,
                                                            ownerPermission,
                                                            ownerFieldPermissions,
                                                        )}
                                                        data-testid="permissions.profile_permissions.more.owner"
                                                    >
                                                        <Icon>more_vert</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container justifyContent="space-between">
                                            <Select
                                                value={nonOwnerPermission}
                                                onChange={this.onChangeNonOwner(profile.code, entity.id)}
                                                className="c-permission__profile-entity__select"
                                                data-testid="permissions.profile_permissions.select.non_owner"
                                            >
                                                <MenuItem
                                                    value={PERMISSION_HIDE}
                                                    data-testid="permissions.profile_permissions.select.hide"
                                                >
                                                    {t('permissions.profile_permissions.select.hide')}
                                                </MenuItem>
                                                <MenuItem
                                                    value={PERMISSION_VIEW}
                                                    data-testid="permissions.profile_permissions.select.view"
                                                >
                                                    {t('permissions.profile_permissions.select.view')}
                                                </MenuItem>
                                                <MenuItem
                                                    value={PERMISSION_MODIFY}
                                                    disabled={!!disableEdit}
                                                    data-testid="permissions.profile_permissions.select.modify"
                                                >
                                                    {t('permissions.profile_permissions.select.modify')}
                                                </MenuItem>
                                            </Select>
                                            {nonOwnerDisabled ? (
                                                <IconButton
                                                    disabled={true}
                                                    onClick={this.onChangeNonOwnerFields(
                                                        profile,
                                                        entity,
                                                        nonOwnerPermission,
                                                        nonOwnerFieldPermissions,
                                                    )}
                                                    data-testid="permissions.profile_permissions.more.non_owner"
                                                >
                                                    <Icon>more_vert</Icon>
                                                </IconButton>
                                            ) : (
                                                <Tooltip title={t('permissions.profile_permissions.more.tooltip')}>
                                                    <IconButton
                                                        onClick={this.onChangeNonOwnerFields(
                                                            profile,
                                                            entity,
                                                            nonOwnerPermission,
                                                            nonOwnerFieldPermissions,
                                                        )}
                                                        data-testid="permissions.profile_permissions.more.non_owner"
                                                    >
                                                        <Icon>more_vert</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            className={
                                                checkedRecordSharing
                                                    ? 'c-permission__record-sharing c-permission__record-sharing--checked'
                                                    : 'c-permission__record-sharing'
                                            }
                                        >
                                            <Grid item>
                                                <FormControlLabel
                                                    margin="dense"
                                                    control={
                                                        <Switch
                                                            checked={checkedRecordSharing}
                                                            onChange={(e) =>
                                                                this.props.toggleRecordSharing(
                                                                    profile,
                                                                    entity,
                                                                    this.props.dataSourceId,
                                                                    e.target.checked,
                                                                    rules,
                                                                )
                                                            }
                                                            color="primary"
                                                            data-testid="permissions.profile_permissions.toggle_record_sharing"
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item className="c-permission__record-sharing__label">
                                                {checkedRecordSharing && (
                                                    <Button
                                                        variant="text"
                                                        color="default"
                                                        fullWidth
                                                        onClick={() =>
                                                            this.props.openRecordSharingModal(
                                                                profile,
                                                                entity,
                                                                this.props.dataSourceId,
                                                                rules,
                                                            )
                                                        }
                                                        data-testid="permissions.profile_permissions.button.edit"
                                                    >
                                                        {t('edit')}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            ) : (
                                <div className="c-permission__no-access">
                                    {t('permissions.profile_permissions.no_access')}
                                </div>
                            )}
                        </Grid>
                    );
                })}
                {this.state.ownerFields !== null && (
                    <FormDialog
                        title={t('permissions.profile_permissions.form.title', {
                            profileName: this.state.ownerFields.profile.name,
                            entityLabel: this.state.ownerFields.entity.label,
                        })}
                        onSave={this.handleSaveOwnerFields}
                        onCancel={this.handleCloseOwnerFields}
                    >
                        <FieldsForm
                            defaultVisibility={this.state.ownerFields.ownerPermission}
                            fields={this.state.ownerFields.fields}
                            settings={this.state.ownerFields.fieldsPermissions}
                            disableEdit={disableEdit}
                        />
                    </FormDialog>
                )}
                {this.state.nonOwnerFields !== null && (
                    <FormDialog
                        title={t('permissions.profile_permissions.form.title', {
                            profileName: this.state.nonOwnerFields.profile.name,
                            entityLabel: this.state.nonOwnerFields.entity.label,
                        })}
                        onSave={this.handleSaveNonOwnerFields}
                        onCancel={this.handleCloseNonOwnerFields}
                    >
                        <FieldsForm
                            defaultVisibility={this.state.nonOwnerFields.nonOwnerPermission}
                            fields={this.state.nonOwnerFields.fields}
                            settings={this.state.nonOwnerFields.fieldsPermissions}
                            disableEdit={disableEdit}
                        />
                    </FormDialog>
                )}
            </Grid>
        );
    }
}

ProfilePermissions.propTypes = {
    entities: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleRecordSharing: PropTypes.func.isRequired,
    onChangeNonOwner: PropTypes.func.isRequired,
    onChangeNonOwnerFields: PropTypes.func.isRequired,
    onChangeOwner: PropTypes.func.isRequired,
    onChangeOwnerFields: PropTypes.func.isRequired,
    dataSourceId: PropTypes.number,
    subscription: PropTypes.object,
    openRecordSharingModal: PropTypes.func.isRequired,
};

export default withTranslation()(ProfilePermissions);
