import { CalendarEventRepository } from './CalendarEventRepository';
import { HttpCalendarEventRepository } from './HttpCalendarEventRepository';

class CalendarEventRepositoryFactory {
    getRepository(): CalendarEventRepository {
        return new HttpCalendarEventRepository();
    }
}

export const repositoryFactory = new CalendarEventRepositoryFactory();
