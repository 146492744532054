import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import qs from 'qs';
import { DataExchangeSession, Event } from '../../../service/DataExchangeManager';
import '../style.css';
import { Button, Tooltip, Typography, withStyles } from '@material-ui/core';
import { reverse, routes } from '../../../routes';
import { EXECUTION_LOG_TAB, PARAM_FILTERS, PARAM_OPEN_SESSION, PARAM_TAB } from '../index';
import ApiCallDetailsModal from './ApiCallDetailsModal';
import { userManager } from '../../../service/UserManager';

interface CurrentProps extends WithTranslation {
    session: DataExchangeSession;
    accountId: number;
}

interface CurrentState {
    showApiCallDetails: boolean;
}

const DefaultEventField = withStyles({
    root: {
        padding: '0 4px',
    },
})(Typography);

const AutomationEventField = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        padding: '0 4px',
    },
})(Button);

const AltAutomationEventField = withStyles({
    root: {
        pointerEvents: 'inherit',
        '& .automation-icon': {
            color: 'rgba(0, 0, 0, .25)',
        },
    },
})(DefaultEventField);

class EventField extends React.PureComponent<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            showApiCallDetails: false,
        };
    }

    openApiCallDetails = () => {
        this.setState({ showApiCallDetails: true });
    };

    closeApiCallDetails = () => {
        this.setState({ showApiCallDetails: false });
    };

    getEventName = (session: DataExchangeSession) => {
        const icon = session.eventType === 'i' ? 'fas fa-sign-in' : 'fas fa-sign-out';
        const title =
            session.eventType === 'i'
                ? 'data_exchange_session.event_type.inbound'
                : 'data_exchange_session.event_type.outbound';
        return (
            <React.Fragment>
                <Tooltip title={<span>{this.props.t(title)}</span>} style={{ marginRight: 5 }}>
                    <i className={icon} />
                </Tooltip>
                {this.props.t('data_exchange_session.event.' + session.event)}
            </React.Fragment>
        );
    };

    renderAutomationEvent = (session: DataExchangeSession) => {
        const params = qs.stringify({
            [PARAM_TAB]: EXECUTION_LOG_TAB,
            [PARAM_FILTERS]: session.automationSessionId
                ? { id: session.automationSessionId }
                : { dataExchangeSession: session.id },
            [PARAM_OPEN_SESSION]: true,
        });
        const accountId = userManager.isRoleSuperAdmin() ? this.props.accountId : undefined;
        const href = reverse(routes.admin.account.log, { accountId }) + '?' + params;
        if (session.automationSessionsAvailable) {
            return (
                <AutomationEventField
                    href={href}
                    // @ts-ignore
                    target="_blank"
                    rel="noreferrer noopener"
                    size="small"
                    color="primary"
                >
                    {this.getEventName(session)}
                    <i className="fas fa-bolt automation-icon" style={{ marginLeft: 10 }} />
                </AutomationEventField>
            );
        }
        return (
            <AltAutomationEventField>
                {this.getEventName(session)}
                <Tooltip title={<span>{this.props.t('data_exchange_session.event.automation_log_unavailable')}</span>}>
                    <i className="fas fa-bolt automation-icon" style={{ marginLeft: 10 }} />
                </Tooltip>
            </AltAutomationEventField>
        );
    };

    renderInboundApiCallEvent = (session: DataExchangeSession) => {
        return (
            <AutomationEventField onClick={this.openApiCallDetails} size="small" color="primary">
                {this.getEventName(session)}
            </AutomationEventField>
        );
    };

    renderField = () => {
        const { session } = this.props;
        if (session.hasAutomation) {
            return this.renderAutomationEvent(session);
        }
        if (session.event === Event.InboundApiCall && (userManager.isRoleSuperAdmin() || userManager.isRoleAdmin())) {
            return this.renderInboundApiCallEvent(session);
        }
        return <DefaultEventField>{this.getEventName(session)}</DefaultEventField>;
    };

    render() {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                {this.renderField()}
                {this.state.showApiCallDetails && (
                    <ApiCallDetailsModal session={this.props.session} onClose={this.closeApiCallDetails} />
                )}
            </div>
        );
    }
}

export default withTranslation()(EventField);
