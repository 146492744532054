import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class Preloader extends React.PureComponent {
    render() {
        return (
            <div className="c-preloader loaded">
                <div className="animation-preloader">
                    <div className="spinner" />
                    <div className="txt-loading">
                        {this.props.text.split('').map((letter, i) => (
                            <span key={i} data-text-preloader={letter} className="letters-loading">
                                {letter}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

Preloader.propTypes = {
    text: PropTypes.string.isRequired,
};

Preloader.defaultProps = {
    text: 'MAPSLY',
};
