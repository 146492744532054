import { logDebug, weAreInNativeApp } from '../../utils';
import { locationTrackingSettingsStorage } from '../LocationTracking/LocationTrackingSettingsStorage';
import { userManager } from '../UserManager';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { DeviceInfo } from '../../types';
import { STORAGE_KEY_PREFIX } from '../../components/types';
import BackendService from 'api/BackendService';
import apiRoutes from 'api/apiRoutes';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';
import dispatcher from '../dispatcher';
import events from '../../events';
import DeviceInfoQueue from './DeviceInfoQueue';
import { App as AppPlugin } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { AppVersion } from '@ionic-native/app-version';
import { Deploy } from 'cordova-plugin-ionic';
import BgGeoManager from 'service/BgGeo/BgGeoManager';

/**
 * todo rewrite: lodash's debounce() and throttle() are not Promise-aware and makes important syncs missing
 */
class DeviceInfoManager extends BackendService {
    constructor() {
        super();

        if (!weAreInNativeApp()) {
            return;
        }

        // @ts-ignore
        this.update = throttle(this.update, 1000, { leading: false, trailing: true });
        this.sync = debounce(this.sync, 1000);

        // check info changes on returning to foreground
        AppPlugin.addListener('appStateChange', async (state) => {
            if (state.isActive) {
                logDebug('Run device info update on return from background');
                await this.update();
            }
        });

        // sync info on internet return
        dispatcher.subscribe(events.APP_IS_BACK_ONLINE, this, async () => {
            logDebug('Run device info syncing on return from offline');
            await this.sync();
        });
    }

    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.DEVICE_INFO + userManager.getCurrentUser().id;
    }

    getCurrentInfo = async (): Promise<DeviceInfo | null> => {
        // todo physical permissions

        if (!weAreInNativeApp()) {
            return null;
        }

        const user = userManager.getCurrentUser();
        if (!user) {
            return null;
        }

        const { manufacturer, model, name, platform, operatingSystem, osVersion, webViewVersion } =
            await Device.getInfo(); // те же данные можно получить из BackgroundGeolocation.getDeviceInfo()
        const { identifier: deviceID } = await Device.getId();

        const { batteryLevel } = await Device.getBatteryInfo();

        const { trackGeolocation, autoActivateGeolocationDuringWork } = locationTrackingSettingsStorage.load();

        const { status: geolocationPermissionStatus } = await BgGeoManager.getProviderState();
        let geolocationPermission;
        switch (geolocationPermissionStatus) {
            case BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS:
                geolocationPermission = 'Always';
                break;
            case BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE:
                geolocationPermission = 'While in use';
                break;
            case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
                geolocationPermission = 'Never';
                break;
            default:
                geolocationPermission = 'Not determined';
                break;
        }

        const nativeVersion = await AppVersion.getVersionNumber();

        const appflowVersion: { buildId: string } | undefined = await Deploy.getCurrentVersion();

        return {
            deviceID,
            device: manufacturer + ' ' + (name || model),
            manufacturer,
            model,
            name: name ?? null,
            os: operatingSystem,
            osVersion,
            platform,
            webViewVersion,
            batteryLevel: batteryLevel ? Math.floor(batteryLevel * 100) : 0,
            trackIsOn: trackGeolocation,
            scheduleIsOn: autoActivateGeolocationDuringWork,
            userID: user.id,
            schedule: user.routingPreferences.weekTimes,
            geolocationPermission,
            time: new Date().toISOString(),
            appNativeVersion: nativeVersion,
            appFrontVersion: appflowVersion ? appflowVersion.buildId : null, // Appflow says that it is a string, but it always an integer so back receives an integer or null
        };
    };

    private cache = (info: DeviceInfo) => {
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(info));
    };

    private getCachedInfo = (): DeviceInfo | null => {
        const cachedValue = window.localStorage.getItem(this.getStorageKey());
        if (!cachedValue) {
            return null;
        }

        return JSON.parse(cachedValue);
    };

    /**
     * todo find out why we send state ONLY if changes are detected
     * additionally reporting changes if schedule term had been crossed to fix no info sent when tracking was triggered on and off while in a deep sleep
     */
    private infoChanged = (info: DeviceInfo) => {
        const savedVersion = this.getCachedInfo();
        if (!savedVersion) {
            return true;
        }

        const savedAt = new Date(savedVersion.time);
        if (!BgGeoManager.isItTheSameScheduleTermNow(savedVersion.schedule, savedAt)) {
            return true;
        }

        for (const key in info) {
            if (!info.hasOwnProperty(key)) {
                continue;
            }
            if (key === 'batteryLevel' || key === 'time') {
                // don't check difference for this params
                continue;
            }

            // @ts-ignore
            if (!isEqual(savedVersion[key], info[key])) {
                // @ts-ignore
                logDebug('Device info changed', key, savedVersion[key], info[key]);
                return true;
            }
        }

        return false;
    };

    update = async (force = false) => {
        if (!userManager.getCurrentUser()) {
            return;
        }

        logDebug('Updating device info');

        const info = await this.getCurrentInfo();
        if (!info) {
            return; // if we are not in app or there is no user
        }

        if (force || this.infoChanged(info)) {
            this.cache(info);
            DeviceInfoQueue.push(info);

            this.sync();
        } else {
            logDebug('Device info does NOT changed');
        }
    };

    sync = (iteration?: number) => {
        if (!userManager.getCurrentUser()) {
            return;
        }

        if (typeof iteration === 'undefined') {
            iteration = 0;
        }
        iteration++;
        logDebug('Run device info syncing', iteration);

        let info = DeviceInfoQueue.getHead();
        if (!info) {
            return;
        }

        this.send(info)
            .then(() => {
                DeviceInfoQueue.removeHead();
                this.sync(iteration);
            })
            .catch((data: any) => {
                logDebug('Device info synchronization failed', data);
            });
    };

    private send = (info: DeviceInfo) => {
        logDebug('Sending device info to the server', info);
        return this.requestApi(apiRoutes.devices.syncInfo, 'POST', info);
    };
}

export default new DeviceInfoManager();
