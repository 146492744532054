import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import './style.css';

class ConfirmationIconButton extends React.Component {
    state = {
        anchorEl: null,
    };

    handleConfirmation = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
        this.props.onClick && this.props.onClick(event);
    };

    handleClick = (event) => {
        event.stopPropagation();
        if (this.props.disabled) {
            return;
        }
        if (this.props.processing) {
            return;
        }
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {
            t,
            tReady,
            i18n,
            iconName,
            processing,
            textYes,
            textNo,
            text,
            label,
            disabled,
            tooltipText,
            iconColor,
            ...rest
        } = this.props;
        const icon = processing ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            <Icon color={disabled ? '' : iconColor}>{iconName + '_icon'}</Icon>
        );

        return (
            <React.Fragment>
                <Tooltip title={tooltipText}>
                    <div className={label ? 'iconButtonWrap' : undefined}>
                        <IconButton
                            disabled={disabled}
                            {...rest}
                            onClick={this.handleClick}
                            data-testid="confirmation_button.popover.btn.open"
                        >
                            {icon}
                        </IconButton>
                        {label && <div>{label}</div>}
                    </div>
                </Tooltip>
                <Popover
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    disableEnforceFocus
                    disableScrollLock
                >
                    <div style={{ padding: '8px 16px', textAlign: 'center', fontSize: 'smaller', maxWidth: '200px' }}>
                        <div>{text || t('confirmation.default_text')}</div>
                        <Button
                            color="default"
                            onClick={this.handleClose}
                            data-testid="confirmation_button.popover.btn.no"
                        >
                            {textNo || t('no')}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={this.handleConfirmation}
                            data-testid="confirmation_button.popover.btn.yes"
                        >
                            {textYes || t('yes')}
                        </Button>
                    </div>
                </Popover>
            </React.Fragment>
        );
    }
}

ConfirmationIconButton.propTypes = {
    processing: PropTypes.bool,
    iconName: PropTypes.string.isRequired,
    text: PropTypes.string,
    label: PropTypes.string,
    textYes: PropTypes.string,
    textNo: PropTypes.string,
    disabled: PropTypes.bool,
    iconColor: PropTypes.string,
    tooltipText: PropTypes.string,
};

ConfirmationIconButton.defaultProps = {
    processing: false,
    label: null,
    disabled: false,
    iconColor: 'inherit',
    tooltipText: '',
};

export default withTranslation('translations', { withRef: true })(ConfirmationIconButton);
