import { GEO_FIELDS } from '../../references/geoFields';
import { PROSPECTING_ID } from '../EntityDataTable';
import uniq from 'lodash/uniq';
import { FieldType } from '../types';

const ToolTipField = ['name'];
const PinField = ['name'];
const TableField = [
    'name',
    'rating',
    'priceLevel',
    'address',
    'types',
    'permanentlyClosed',
    'sourceName',
    'exportStatus',
];
const PopupField = [
    'name',
    'rating',
    'address',
    'types',
    'permanentlyClosed',
    'phone',
    'photo',
    'website',
    'sourceUrl',
    'sourceName',
    'exportStatus',
    'userRatingsTotal',
];

export class DataBuilder {
    constructor(
        structure,
        toolTipFiled = ToolTipField,
        pinField = PinField,
        tableField = TableField,
        popupField = PopupField,
    ) {
        this.structure = structure;
        this.toolTipFiled = toolTipFiled;
        this.pinField = pinField;
        this.tableField = tableField;
        this.popupField = popupField;
    }

    getId = (item) => {
        return this.getValue('id', item);
    };

    getProspectId = (item) => {
        return this.getValue('prospectId', item);
    };

    formatItemToPoint = (item) => {
        let point = {
            id: this.getValue('id', item),
            entityId: PROSPECTING_ID,
            layerId: 0,
            viewId: 0,
            uuid: this.getValue('uuid', item),
            address: this.getValue('address', item),
            addressFields: this.getValue('addressFields', item),
            position: this.getValue('position', item),
            objectName: this.getValue('name', item),
            popup: [],
            tooltip: [],
            pin: [],
        };

        ToolTipField.forEach((field) => {
            point.tooltip.push(this.pointSettings(field, item));
        });

        PinField.forEach((field) => {
            point.pin.push(this.pointSettings(field, item));
        });

        PopupField.forEach((field) => {
            point.popup.push(this.pointSettings(field, item));
        });
        return point;
    };

    formatItemToRecords = (item) => {
        let record = {};
        record.id = this.getValue('id', item);
        record.prospectId = this.getValue('prospectId', item);
        record[GEO_FIELDS.LAT] = this.getValue('position', item)[0];
        record[GEO_FIELDS.LNG] = this.getValue('position', item)[1];
        record.objectName = this.getValue('name', item);
        record.uuid = this.getValue('uuid', item);
        TableField.forEach((field) => {
            record[field] = this.getConvertValue(field, item);
        });
        return record;
    };

    formatItemToEntityRecords = (item, structure, userDSId) => {
        let record = {};
        record.prospectId = this.getValue('prospectId', item);
        record.fields = [];

        structure.forEach((structureItem) => {
            let value;

            switch (structureItem.type) {
                case 'const':
                    value = structureItem.const;
                    const isObject = typeof value === 'object' && value !== null && !Array.isArray(value);
                    if (isObject && value.id) {
                        // Polymorphic lookup
                        if (typeof value.apiName !== 'undefined') {
                            value = JSON.stringify({
                                object: value.apiName,
                                recordId: value.id,
                            });
                        } else {
                            value = value.id;
                        }
                    }
                    break;
                case 'dsUserId':
                    value = userDSId;
                    break;
                default:
                    if (!structureItem.prospectFieldName) {
                        break;
                    }
                    value = this.getValue(structureItem.prospectFieldName, item);
            }

            value = value !== undefined && value !== null ? value : null;

            if (
                [FieldType.STRINGS, FieldType.INTEGERS].includes(structureItem.fieldType) &&
                value !== null &&
                !Array.isArray(value)
            ) {
                value = [value];
            }
            if (
                ![FieldType.STRINGS, FieldType.INTEGERS].includes(structureItem.fieldType) &&
                value !== null &&
                Array.isArray(value)
            ) {
                value = value.join(', ');
            }
            if (typeof value === 'string' && Number.isInteger(structureItem.length)) {
                value = this.truncateValue(value, structureItem.length);
            }
            if (typeof value === 'number') {
                value = value.toString();
            }
            if (structureItem.fieldType === FieldType.BOOLEAN) {
                value = !!value;
            }
            if ([FieldType.BIGINT, FieldType.INTEGERS, FieldType.FLOAT].includes(structureItem.fieldType)) {
                value = +value;
            }
            record.fields.push({
                apiName: structureItem.originalApiName || structureItem.fieldName,
                value: value,
            });
        });

        return record;
    };

    truncateValue(value, length = 255) {
        if (length >= value.length) {
            return value;
        }

        return value.substring(0, length);
    }

    getValue = (field, item) => {
        return this.structure[field].getValue !== undefined ? this.structure[field].getValue(item) : null;
    };

    getRequestField = (field) => {
        return this.structure[field]?.requestField ?? null;
    };

    getConvertValue = (field, item) => {
        let value = this.getValue(field, item);
        return this.convertValue(value, this.getType(field));
    };

    isLink = (field) => {
        return this.structure[field].isLink === true;
    };

    isPin = (field) => {
        return this.structure[field].isPin !== undefined ? this.structure[field].isPin : false;
    };

    getTitle = (field) => {
        return this.structure[field].title !== undefined ? this.structure[field].title : null;
    };

    getType = (field) => {
        return this.structure[field].type !== undefined ? this.structure[field].type : 'string';
    };

    convertValue = (value, type) => {
        if (value === undefined || value === null) {
            return null;
        }

        switch (type) {
            case 'array':
                return value.join(', ');
            case 'boolean':
                // eslint-disable-next-line
                return value == true;
            case 'float':
                return parseFloat(value);
            case 'integer':
                return parseInt(value);
            default:
                return value;
        }
    };

    pointSettings = (field, item) => {
        return {
            field: field,
            label: this.getTitle(field),
            value: this.getValue(field, item),
            isLink: this.isLink(field),
            isReadOnly: true,
        };
    };

    buildFieldStructure = () => {
        let structure = [];

        TableField.forEach((field) => {
            structure.push({
                name: field,
                columnName: field,
                title: this.getTitle(field),
                type: this.getType(field),
                isPin: this.isPin(field),
                isLink: this.isLink(field),
            });
        });

        return structure;
    };

    getMainStructure = () => {
        return this.structure;
    };

    getRequestFieldsFromProspectingStructure(structure, initial = []) {
        const fields = [...initial];
        structure.forEach((structureItem) => {
            fields.push(this.getRequestField(structureItem.prospectFieldName));
        });

        return uniq(fields.filter((field) => !!field));
    }
}
