import Queue from './Queue';
import AbstractEvent, { EventStatus } from './Events/AbstractEvent';
import ProcessorFactory from './ProcessorFactory';
import apiRoutes, { reverse } from '../../api/apiRoutes';
import BackendService from '../../api/BackendService';
import dispatcher from '../dispatcher';
import events from '../../events';
import QueueStorageHandler from './QueueStorageHandler';
import { logDebug } from '../../utils';

interface EventResponse {
    eventId: string;
    status: EventStatus;
    result: any;
}

class QueueHandler extends BackendService {
    private readonly queue: Queue;
    private readonly processorFactory: ProcessorFactory;
    private eventToEdit: AbstractEvent | null = null;
    // @ts-ignore
    private storageHandler: QueueStorageHandler;

    constructor() {
        super();

        this.queue = new Queue();
        this.storageHandler = new QueueStorageHandler(this.queue);
        this.processorFactory = new ProcessorFactory();

        dispatcher.subscribe(events.EVENTS_QUEUE_LOADED, this, () => {
            this.updateLastEventsStatus();
            this.runCurrentEvent();
        });

        dispatcher.subscribe(events.APP_IS_BACK_ONLINE, this, () => {
            this.processOnlineQueue();
        });

        dispatcher.subscribe(events.EVENTS_ONLINE_QUEUE_CHANGED, this, () => {
            this.processOnlineQueue();
        });

        dispatcher.subscribe(events.WS_FRONTEND_EVENT, this, (eventResponse: EventResponse) => {
            logDebug('Event update', eventResponse);
            this.updateEvent(eventResponse.eventId, eventResponse.status, eventResponse.result);
        });
    }

    public getEventToEdit(): AbstractEvent | null {
        return this.eventToEdit;
    }

    public editEvent(event: AbstractEvent): void {
        logDebug('Edit event', event);

        this.eventToEdit = event;

        dispatcher.dispatch(events.EVENTS_QUEUE_EDITING_EVENT_CHANGED);
    }

    public cancelEventEdit(): void {
        this.eventToEdit = null;

        dispatcher.dispatch(events.EVENTS_QUEUE_EDITING_EVENT_CHANGED);
    }

    private completeEditEvent(event: AbstractEvent): void {
        logDebug('Complete edit event', this.eventToEdit, event);

        this.eventToEdit = null;

        dispatcher.dispatch(events.EVENTS_QUEUE_EDITING_EVENT_CHANGED);
    }

    public removeEvent(event: AbstractEvent): void {
        logDebug('Remove event', event);

        if (event.getStatus() === EventStatus.PROCESSING) {
            return;
        }

        this.queue.removeEvent(event);
    }

    public runCurrentEvent = (): void => {
        logDebug('run current');
        if (!this.getCurrentOfflineEvent() || this.getCurrentOfflineEvent()?.getStatus() !== EventStatus.NEW) {
            return;
        }

        this.processCurrentOfflineEvent();
    };

    public getCurrentOfflineEvent(): AbstractEvent | null {
        return this.queue.getCurrentOfflineEvent();
    }

    public getActiveOfflineEvents(): Array<AbstractEvent> {
        logDebug('active events');
        return this.queue.getActiveOfflineEvents();
    }

    public getQueuedEvents(): Array<AbstractEvent> {
        const currentEvent = this.getCurrentOfflineEvent();
        return this.getActiveOfflineEvents().filter(
            (event: AbstractEvent) => event.getUid() !== currentEvent?.getUid(),
        );
    }

    public getOnlineEvents(): Array<AbstractEvent> {
        return this.queue.getOnlineEvents();
    }

    public addEvent(event: AbstractEvent): Promise<any> {
        logDebug('Add Event', event);

        if (this.eventToEdit !== null && this.eventToEdit.constructor.name === event.constructor.name) {
            this.queue.replaceEvent(this.eventToEdit, event);

            this.completeEditEvent(event);
        } else {
            this.queue.addEvent(event);
        }

        setTimeout(() => {
            this.processCurrentOfflineEvent();
        }, 1);

        if (event.isOnline()) {
            return event.getPromise();
        }

        return Promise.resolve();
    }

    private completeEvent(event: AbstractEvent): void {
        logDebug('Complete Event', event);
        this.processorFactory.onComplete(event);
    }

    private processCurrentOfflineEvent(): void {
        const event = this.getCurrentOfflineEvent();
        if (!event || event.getStatus() !== EventStatus.NEW) {
            return;
        }

        logDebug('running event', event);

        this.processorFactory.process(event);

        event.setStatus(EventStatus.PROCESSING);

        dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
    }

    public updateEvent(eventId: string, status: EventStatus, result: any) {
        const event = this.queue.findEvent(eventId);
        if (!event) {
            console.warn('Unknown event ' + eventId);
            return;
        }

        this.onUpdateEvent(event, status, result);
    }

    public onUpdateEvent(event: AbstractEvent, status: EventStatus, result: any): void {
        event.setStatus(status);
        event.setResult(result);

        if (event.isFinalStatus()) {
            this.completeEvent(event);
        }

        if (event.isOffline() && event.isFinalStatus()) {
            this.processCurrentOfflineEvent();
        }

        const isOfflineEvent = event.isOffline();
        if (event.isFinalStatus()) {
            this.removeEvent(event);
        }

        if (isOfflineEvent) {
            dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
        } else {
            dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
        }
    }

    private updateLastEventsStatus(): void {
        const currentEvent = this.getCurrentOfflineEvent();

        const eventIds = this.queue
            .getOnlineEvents()
            .filter((event) => !event.isFinalStatus())
            .map((event) => event.getUid());
        if (currentEvent) {
            eventIds.push(currentEvent.getUid());
        }

        if (eventIds.length === 0) {
            return;
        }

        const url = reverse(apiRoutes.frontend.events.status);
        this.requestApi(url, 'POST', { eventIds: eventIds }).then((data: { events: any }) => {
            // @ts-ignore
            const foundEventGuids = new Set(data.events.map((eventData) => eventData.guid));

            for (let eventId of eventIds) {
                if (foundEventGuids.has(eventId)) {
                    // @ts-ignore
                    const eventData = data.events.find((e) => e.guid === eventId);
                    const event = this.queue.findEvent(eventData.guid);
                    if (event) {
                        this.onUpdateEvent(event, eventData.status, eventData.result ?? null);
                    }
                } else {
                    const event = this.queue.findEvent(eventId);
                    if (event) {
                        this.onUpdateEvent(event, EventStatus.NEW, null);
                    }
                }
            }
        });
    }

    private processOnlineQueue(): void {
        const offlineEvent = this.getCurrentOfflineEvent();
        if (offlineEvent) {
            return;
        }

        const onlineEvents = this.queue.getNewOnlineEvents();
        if (onlineEvents.length === 0) {
            return;
        }

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onlineEvents.forEach((event) => {
            logDebug('running event', event);
            this.processorFactory.process(event);
            event.setStatus(EventStatus.PROCESSING);
        });
        dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
    }
}

export default new QueueHandler();
