import { iOS, logDebug } from '../utils';

export class INoBounceService {
    startY: number = 0;
    startX: number = 0;
    enabled: boolean = false;
    supportsPassiveOption: boolean = false;
    serviceEnabled: boolean = false;

    constructor() {
        if (!iOS()) {
            return;
        }
        this.serviceEnabled = true;
        try {
            let scope = this;
            let opts = Object.defineProperty({}, 'passive', {
                get: function () {
                    scope.supportsPassiveOption = true;
                    return true;
                },
            });
            window.addEventListener('test', () => {}, opts);
        } catch (e) {}
    }

    handleTouchmove(evt: any) {
        let el = evt.target;

        const zoom = window.innerWidth / window.document.documentElement.clientWidth;
        if (evt.touches.length > 1 || zoom !== 1) {
            return;
        }

        while (el !== document.body && el !== document) {
            // Get some style properties
            const style = window.getComputedStyle(el);

            if (!style) {
                break;
            }

            if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
                return;
            }

            //const scrolling = style.getPropertyValue('-webkit-overflow-scrolling');
            const overflowY = style.getPropertyValue('overflow-y');
            const overflowX = style.getPropertyValue('overflow-x');
            const height = parseInt(style.getPropertyValue('height'), 10);
            const width = parseInt(style.getPropertyValue('width'), 10);

            const isScrollable =
                overflowY === 'auto' || overflowY === 'scroll' || overflowX === 'auto' || overflowX === 'scroll';
            const canScrollY = el.scrollHeight > el.offsetHeight;
            const canScrollX = el.scrollWidth > el.offsetWidth;

            if (isScrollable && (canScrollY || canScrollX)) {
                // Get the current Y position of the touch
                const curY = evt.touches ? evt.touches[0].screenY : evt.screenY;
                const curX = evt.touches ? evt.touches[0].screenX : evt.screenX;

                // Determine if the user is trying to scroll past the top or bottom
                // In this case, the window will bounce, so we have to prevent scrolling completely
                const isAtTop = this.startY <= curY && el.scrollTop === 0;
                const isAtBottom = this.startY >= curY && el.scrollHeight - el.scrollTop === height;
                const isAtLeft = this.startX <= curX && el.scrollLeft === 0;
                const isAtRight = this.startX >= curX && el.scrollWidth - el.scrollLeft === width;

                if (canScrollY && (isAtTop || isAtBottom)) {
                    evt.preventDefault();
                }

                if (canScrollX && (isAtLeft || isAtRight)) {
                    evt.preventDefault();
                }

                // No need to continue up the DOM, we've done our job
                return;
            }

            // Test the next parent
            el = el.parentNode;
        }

        // Stop the bouncing -- no parents are scrollable
        evt.preventDefault();
    }

    handleTouchstart(evt: any) {
        // Store the first Y position of the touch
        this.startY = evt.touches ? evt.touches[0].screenY : evt.screenY;
        this.startX = evt.touches ? evt.touches[0].screenX : evt.screenX;
    }

    enable() {
        window.addEventListener(
            'touchstart',
            this.handleTouchstart,
            this.supportsPassiveOption ? { passive: false } : false,
        );
        window.addEventListener(
            'touchmove',
            this.handleTouchmove,
            this.supportsPassiveOption ? { passive: false } : false,
        );
        this.enabled = true;
    }

    disable() {
        window.removeEventListener('touchstart', this.handleTouchstart, false);
        window.removeEventListener('touchmove', this.handleTouchmove, false);
        this.enabled = false;
    }

    isEnabled() {
        return this.enabled;
    }

    init() {
        if (!this.serviceEnabled) {
            return;
        }

        if (window.location.pathname !== '/' && this.isEnabled()) {
            this.disable();
            logDebug('Disable iNoBounce');
        } else if (window.location.pathname === '/' && !this.isEnabled()) {
            this.enable();
            logDebug('Enable iNoBounce');
        }
    }
}

export const iNoBounceService = new INoBounceService();
