import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import { Geo } from 'interfaces';

interface Props extends WithTranslation {
    location: Geo.GeoPointAddress;
    onClose: () => void;
}

interface State {}

class LocationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        // todo мб стоит по-другому вывести карту
        return (
            <PureFormDialog open={true} title={''} maxWidth="sm" onClose={this.handleClose}>
                <div style={{ padding: '0px 0px 20px 0px' }}>
                    <LeafletMap
                        center={this.props.location}
                        zoom={15}
                        // viewport={ this.state.mapViewport }
                        minZoom={2}
                        maxZoom={19}
                        style={{ height: '300px', width: '300px' }}
                        className="c-map"
                        scrollWheelZoom
                        touchZoom={true}
                        zoomControl={false}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[this.props.location.lat, this.props.location.lng]} />
                    </LeafletMap>
                </div>
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(LocationModal);
