import Api from './Api';
import i18n from 'i18next';
import { CombinedMapType, CombinedMapTypes, TilesProviders } from 'references/tilesProviders';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

const TOKENS_API_URL = 'https://api.mapbox.com/tokens/v2';
const STYLES_API_URL = 'https://api.mapbox.com/styles/v1';

export enum TokenType {
    PUBLIC = 'pk',
    SECRET = 'sk',
    TEMPORARY = 'tk',
}

type Visibility = 'private' | 'public';

export type CheckToken = {
    usage: TokenType; // The token type. One of pk, sk, or tk.
    user: string; // The user to whom the token belongs.
    authorization: string; // The token's unique identifier.
    expires?: string; // tk tokens only. The expiration time of the token.
    created?: string; // tk tokens only. The creation time of the token.
    scopes?: string[]; // tk tokens only. The token's assigned scopes.
    client?: 'api'; // tk tokens only. Always "api".
};

type TokenPayload = {
    u: string; // user
    a: string; // authorization
};

type RetrieveToken = {
    code: string; // Indicates whether the token is valid. If the token is invalid, describes the reason. One of:
    // TokenValid: The token is valid and active.
    // TokenMalformed: The token cannot be parsed.
    // TokenInvalid: The signature for the token does not validate.
    // TokenExpired: The token was temporary and has expired.
    // TokenRevoked: The token's authorization has been deleted.
    token: CheckToken | null;
};

type Style = {
    version: Number; // The style specification version number.
    name: string; // A human-readable name for the style.
    metadata?: object; // Information about the style that is used in Mapbox Studio.
    sources: object; // Sources supply the data that will be shown on the map.
    layers?: object[]; // Layers will be drawn in the order of this array.
    created: string; // The date and time the style was created.
    id: string; // The ID of the style.
    modified: string; // The date and time the style was last modified.
    owner: string; // The username of the style owner.
    visibility: Visibility; // Private styles require an access token belonging to the owner. Public styles may be requested with an access token belonging to any user.
    protected: boolean; // Indicates whether the style is protected (true) or not (false). Protected styles cannot be edited and deleted.
    draft?: boolean; // Indicates whether the style is a draft (true) or whether it has been published (false).
    center?: Number[];
    bearing?: Number;
    pitch?: Number;
    zoom?: Number;
};

export const formatStyle: (style: Style) => CombinedMapType = (style) => ({
    tilesProvider: TilesProviders.MAPBOX,
    mapType: `${style.owner}/${style.id}`,
    label: style.name,
});

export const formatStyles: (styles: Style[]) => CombinedMapTypes = (styles) =>
    mapValues(
        keyBy(styles, ({ owner, id }) => `${TilesProviders.MAPBOX}_${owner}/${id}`),
        formatStyle,
    );

class MapBoxGeneralApi extends Api {
    private accessToken: string;
    private username: string | null = null;

    constructor(accessToken: string = '') {
        super();

        this.accessToken = accessToken;
        this.username = this.precheckAccessToken()?.u ?? null;
    }

    getTokenType(jwtHeader?: string): TokenType | null {
        const header = jwtHeader ?? this.accessToken.split('.')[0] ?? '';
        if (Object.values(TokenType).includes(header as TokenType)) {
            return header as TokenType;
        }

        return null;
    }

    precheckAccessToken(): TokenPayload | null {
        const parts = this.accessToken.split('.');
        const [header, payload, signature] = parts;

        if (
            parts.length !== 3 ||
            this.getTokenType(header) === null ||
            payload === undefined ||
            signature === undefined
        ) {
            return null;
        }

        let decoded: TokenPayload | any;
        try {
            decoded = JSON.parse(atob(payload));
        } catch (e: any) {
            console.error(e); // SyntaxError

            return null;
        }

        if (
            typeof decoded?.u === 'string' &&
            typeof decoded?.a === 'string' &&
            decoded.u.length > 0 &&
            decoded.a.length > 0
        ) {
            return decoded as TokenPayload;
        }

        return null;
    }

    async checkAccessToken(): Promise<CheckToken> {
        if (this.precheckAccessToken() === null) {
            return Promise.reject(new Error(i18n.t('shared_map.settings.modal.map_box.empty_token')));
        }

        return this.requestApi(TOKENS_API_URL, 'GET', { access_token: this.accessToken }).then(
            (response: RetrieveToken) => {
                switch (response['code']) {
                    case 'TokenValid':
                        this.username = response.token?.user ?? null;
                        if (this.username === null || response.token === null) {
                            throw new Error('Wrong access token');
                        }

                        return response.token as CheckToken;
                    case 'TokenMalformed':
                    case 'TokenInvalid':
                    case 'TokenExpired':
                    case 'TokenRevoked':
                        const key = response['code'].replace(/[A-Z]/g, (letter: string, index: Number) =>
                            index === 0 ? letter.toLowerCase() : '_' + letter.toLowerCase(),
                        );
                        throw new Error(i18n.t('shared_map.settings.modal.map_box.' + key));
                    default:
                        throw new Error('Wrong access token');
                }
            },
        );
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        this.username = this.precheckAccessToken()?.u ?? null;

        return this;
    }

    async listStyles(): Promise<Style[]> {
        if (this.username === null) {
            return Promise.reject(new Error(i18n.t('shared_map.settings.modal.map_box.empty_token')));
        }

        return this.requestApi(`${STYLES_API_URL}/${this.username}`, 'GET', { access_token: this.accessToken });
    }
}

export default MapBoxGeneralApi;
