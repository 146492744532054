import { makeAutoObservable } from 'mobx';
import validator from 'validator';
import i18n from 'i18next';
import { Routing } from 'interfaces';

type ErrorType = Map<string, string>;

const t = i18n.t.bind(i18n);

class RoutingSettingsVehicleTypeFormManager {
    private readonly _formData: Routing.Settings.VehicleTypeFormData;
    private readonly _errors: ErrorType = new Map();

    constructor(formData: Routing.Settings.VehicleTypeFormData) {
        makeAutoObservable(this);
        this._formData = formData;
    }

    validate(uniqueNames: Set<string>): boolean {
        const { vehicleType } = this._formData;
        this._errors.clear();

        if (validator.isEmpty(vehicleType.name)) {
            this._errors.set('name', t('errors.not_empty'));
        } else if (uniqueNames.has(vehicleType.name)) {
            this._errors.set('name', t('errors.value_should_be_unique'));
        }

        if (Number.isNaN(vehicleType.serviceSpeedFactor)) {
            this._errors.set('serviceSpeedFactor', t('errors.not_empty'));
        }

        if (Number.isNaN(vehicleType.travelSpeedFactor)) {
            this._errors.set('travelSpeedFactor', t('errors.not_empty'));
        }

        return this._errors.size === 0;
    }

    set name(name: string) {
        this._formData.vehicleType.name = name;
    }

    get name(): string {
        return this._formData.vehicleType.name;
    }

    set capacity(capacity: string) {
        this._formData.vehicleType.capacity = capacity;
    }

    get capacity(): string {
        return this._formData.vehicleType.capacity;
    }

    set travelSpeedFactor(travelSpeedFactor: number) {
        this._formData.vehicleType.travelSpeedFactor = travelSpeedFactor;
    }

    get travelSpeedFactor(): number {
        return this._formData.vehicleType.travelSpeedFactor;
    }

    set serviceSpeedFactor(serviceSpeedFactor: number) {
        this._formData.vehicleType.serviceSpeedFactor = serviceSpeedFactor;
    }

    get serviceSpeedFactor(): number {
        return this._formData.vehicleType.serviceSpeedFactor;
    }

    set travelMode(travelMode: Routing.Settings.VehicleTypeTravelMode) {
        this._formData.vehicleType.travelMode = travelMode;
    }

    get travelMode(): Routing.Settings.VehicleTypeTravelMode {
        return this._formData.vehicleType.travelMode;
    }

    getErrorText(key: string): string | null {
        return this._errors.get(key) ?? null;
    }

    get formData(): Routing.Settings.VehicleTypeFormData {
        return this._formData;
    }
}

export default RoutingSettingsVehicleTypeFormManager;
