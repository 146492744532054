import L, { DivIcon } from 'leaflet';
import { MapPage } from 'interfaces';

export const ICON_SIZE_BIG = 34;
export const ICON_SIZE = 30;
export enum MarkerStroke {
    DEFAULT,
    HIGHLIGHTED,
    MUTED,
}

function getBaseIcon(
    classNames: string,
    pinIcon: string,
    pinColor: string,
    pinSize: number,
    position: string | null,
    circle?: string,
) {
    const paddingTop = pinSize / 4.5;
    const charSize = (ICON_SIZE * 0.7) / 1.5; // 0.7 is taken from css .route-index "font-size 70%" rule, 1.5 - leaflet font size
    let width = pinSize;
    if (position && position.length * charSize > width) {
        width = position.length * charSize;
    }

    let positionHtml = '';
    if (position) {
        positionHtml = `<div class="route-index" style="width: ${width}px; height: ${pinSize}px; padding-top: ${paddingTop}px;">${position}</div>`;
    }
    const style = `color: ${pinColor}; font-size: ${pinSize}px; height: ${pinSize}px`;

    return L.divIcon({
        html: `<div class="com-default-pin fas fa-${pinIcon}" style="${style}">
            ${positionHtml}
        </div>${circle || ''}`,
        iconSize: [pinSize, pinSize],
        className: classNames,
        iconAnchor: [pinSize / 2, pinSize],
    });
}

export function getBaseIconWithTextWithin(
    classNames: string,
    pinIcon: string,
    pinColor: string,
    pinSize: number,
    content: string,
    contentLength: number,
    textColor: string,
    stroke: MarkerStroke = MarkerStroke.DEFAULT,
    multiposition: boolean = false,
) {
    const paddingTop = pinSize / 7;
    const textSize = pinSize * 0.5;

    const charSize = (pinSize * 0.7) / 1.5; // 0.7 is taken from css .route-index "font-size 70%" rule, 1.5 - leaflet font size
    let width = pinSize;
    const textWidth = contentLength * textSize;
    if (multiposition && textWidth > width) {
        width = contentLength * charSize;
    }

    let positionHtml = '';
    if (content) {
        let style = [`color: ${textColor}`, `font-size: ${textSize}px`, `padding-top: ${paddingTop}px`];
        if (multiposition) {
            style.push(`width: ${width}px`);
            style.push(`height: ${pinSize - 6}px`);
            style.push(`background-color: ${pinColor}`);
            style.push(`border-radius: 15px`);
            style.push(`border: 1px solid ${pinColor}`);
            style.push(`border-bottom: none`);
        } else {
            style.push(`width: ${width}px`);
            style.push(`height: ${pinSize}px`);
        }
        positionHtml = `<div class="com-default-pin__inner" style="${style.join(';')}">${content}</div>`;
    }
    const style = `color: ${pinColor}; font-size: ${pinSize}px; height: ${pinSize}px`;
    const strokeClass =
        stroke === MarkerStroke.HIGHLIGHTED
            ? multiposition
                ? 'com-multi-pin--stroke'
                : 'com-default-pin--stroke'
            : '';
    const mutedClass = stroke === MarkerStroke.MUTED ? 'com-default-pin--muted' : '';

    return L.divIcon({
        html: `<div class="com-default-pin fas fa-${pinIcon} ${strokeClass} ${mutedClass}" style="${style}">
            ${positionHtml}
        </div>`,
        iconSize: [width, pinSize],
        className: classNames,
        iconAnchor: [width / 2, pinSize / 2],
    });
}

export function getRouteIcon(iconParams: MapPage.MarkerIconParams): DivIcon {
    const { pinIcon, pinColor, position } = iconParams;
    const classNames = 'div-icon div-icon--big div-icon--route';

    return getBaseIcon(classNames, pinIcon, pinColor, ICON_SIZE, position, '');
}

export function addCircleToIcon(icon: DivIcon): void {
    const addHover = 'div-icon--route__hover';
    if (icon.options.className && !icon.options.className.includes(addHover)) {
        icon.options.className = icon.options.className + ' ' + addHover;
    }

    // @ts-ignore
    const circle = getCircleSimplePoint(icon.options.iconSize[0]);
    if (icon.options.html !== undefined && !icon.options.html.toString().includes('simple-point')) {
        icon.options.html = icon.options.html + ' ' + circle;
    }
}

export function getRouteHoverIcon(iconParams: MapPage.MarkerIconParams): DivIcon {
    const { pinIcon, pinColor, position } = iconParams;
    const classNames = 'div-icon div-icon--big div-icon--route div-icon--route__hover';

    const circle = getCircleSimplePoint(ICON_SIZE);
    return getBaseIcon(classNames, pinIcon, pinColor, ICON_SIZE, position, circle);
}

export function getRouteEntityIcon(position: string, classNames: string | null = null): DivIcon {
    if (classNames === null) {
        classNames = 'div-icon div-icon--big div-icon--route';
    }

    const pinSize = ICON_SIZE;
    const charSize = (ICON_SIZE * 0.7) / 1.5; // 0.7 is taken from css .route-index "font-size 70%" rule, 1.5 - leaflet font size
    let width = pinSize;
    if (position.toString().length * charSize > width) {
        width = position.toString().length * charSize;
    }
    const positionHtml = `<div class="route-index" style="width: ${width}px; height: ${pinSize}px;">${position}</div>`;

    return L.divIcon({
        html: `<div style="font-size: ${pinSize}px; height: ${pinSize}px">
            ${positionHtml}
        </div>`,
        iconSize: [pinSize, pinSize],
        className: classNames,
        iconAnchor: [pinSize / 2, pinSize],
    });
}

export function getRouteEntityHoverIcon(position: string): DivIcon {
    const classNames = 'div-icon div-icon--big div-icon--route div-icon--route__hover';

    const pinSize = ICON_SIZE;
    const charSize = (ICON_SIZE * 0.7) / 1.5; // 0.7 is taken from css .route-index "font-size 70%" rule, 1.5 - leaflet font size
    let width = pinSize;
    if (position.toString().length * charSize > width) {
        width = position.toString().length * charSize;
    }
    const positionHtml = `<div class="route-index" style="width: ${width}px; height: ${pinSize}px;">${position}</div>`;
    const circle = getCircleEntityPoint(pinSize);

    return L.divIcon({
        html: `<div style="font-size: ${pinSize}px; height: ${pinSize}px">
            ${positionHtml}
        </div>${circle}`,
        iconSize: [pinSize, pinSize],
        className: classNames,
        iconAnchor: [pinSize / 2, pinSize],
    });
}

export function getMapIcon(pinIcon: string, pinColor: string, pinSize: number, position: string): DivIcon {
    return getBaseIcon('div-icon', pinIcon, pinColor, pinSize, position);
}

export function getMapBasePointIcon(pinIcon: string, pinColor: string, pinSize: number, position: string): DivIcon {
    const circle = getCircle(pinSize);
    return getBaseIcon('div-icon marker-base-point', pinIcon, pinColor, pinSize, position, circle);
}

export function getMarkerPositionText(positions: number[]): string {
    if (positions.length === 0) {
        return '';
    }

    let positionText = '';
    let rangeStart: number | undefined = undefined;
    let prev: number | undefined = undefined;

    positions.forEach((position, index) => {
        const isLast = index === positions.length - 1;

        if (prev === undefined) {
            // This is the first position
            rangeStart = position;
            positionText += position;
        } else if (position - prev === 1) {
            // This position continues the range
            if (isLast) {
                positionText += `-${position}`;
            }
        } else {
            // This position does not continue the range
            if (rangeStart !== prev) {
                positionText += `-${prev}`;
            }
            positionText += `, ${position}`;
            rangeStart = position;
        }

        prev = position;

        // Handle the case where the last element ends a range
        if (isLast && rangeStart !== position) {
            if (position - prev === 1) {
                positionText += `-${position}`;
            }
        }
    });

    return positionText;
}

function getCircle(pinSize: number): string {
    return `<i class="far fa-circle" style="font-size: ${pinSize}px; height: ${pinSize}px" />`;
}

function getCircleSimplePoint(pinSize: number): string {
    return `<i class="far fa-circle simple-point" style="font-size: ${pinSize}px; height: ${pinSize}px" />`;
}

function getCircleEntityPoint(pinSize: number): string {
    return `<i class="far fa-circle entity-point" style="font-size: ${pinSize}px; height: ${pinSize}px" />`;
}
