import { Button, Grid, Typography } from '@material-ui/core';
import { formatWithCommas } from '../../../utils';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, Subscription } from '../../../interfaces/account';
import { LinearProgressWithLabel } from '../../LinearProgress/LinearProgress';
import Hint from '../../Hint';
import AiTokensDailyUsages from './AiTokensDailyUsagesDialog';
import { WithSnackbarProps } from 'notistack';
import { SubscriptionStatus } from '../../../service/types';
import { intercomManager } from '../../../service/IntercomManager';
import AiTokensEditDialog from './AiTokensEditDialog';

interface AiTokensSubscriptionPanelProps extends WithTranslation, WithSnackbarProps {
    account: Account;
    subscription: Subscription;
}

interface AiTokensSubscriptionPanelState {
    hasAiTokensDailyUsagesDialog: boolean;
    hasAiTokensEditDialog: boolean;
}

class AiTokensSubscriptionPanel extends React.Component<
    AiTokensSubscriptionPanelProps,
    AiTokensSubscriptionPanelState
> {
    constructor(props: AiTokensSubscriptionPanelProps) {
        super(props);
        this.state = {
            hasAiTokensDailyUsagesDialog: false,
            hasAiTokensEditDialog: false,
        };
    }

    handleChange = () => {
        const { subscription } = this.props;

        if ([SubscriptionStatus.ACTIVE, SubscriptionStatus.FREE].includes(subscription.status)) {
            this.setState({
                hasAiTokensEditDialog: true,
            });
        } else {
            intercomManager.showNewMessage(this.props.t('billing.subscription_panel.change_ai_tokens_intercom_button'));
        }
    };

    handleHideAiTokensEditDialog = () => {
        this.setState({
            hasAiTokensEditDialog: false,
        });
    };

    handleShowDailyUsageHistory = () => {
        this.setState({
            hasAiTokensDailyUsagesDialog: true,
        });
    };

    handleHideDailyUsageHistory = () => {
        this.setState({
            hasAiTokensDailyUsagesDialog: false,
        });
    };

    private getTotal = () => {
        const perUser = this.props.subscription.plan.aiTokensPerUser;
        const numberOfUsers = this.props.subscription.aiUsers;
        const additional = this.props.subscription.additionalAiTokens;
        const custom = this.props.subscription.additionalCustomAiTokens;

        return perUser * numberOfUsers + additional + custom;
    };

    private getUsage = () => {
        return this.props.subscription.wastedAiTokens;
    };

    private getAvailable = () => {
        return Math.max(this.getTotal() - this.getUsage(), 0);
    };

    private getLinearProgressTooltipText = () => {
        const { t } = this.props;

        return (
            <React.Fragment>
                {t('billing.subscription_panel.ai_tokens_usage.consumption', {
                    usage: formatWithCommas(this.getUsage()),
                    max: formatWithCommas(this.getTotal()),
                })}
                <br />
                {t('billing.subscription_panel.ai_tokens_usage.available', {
                    total: formatWithCommas(this.getAvailable()),
                })}
                <br />
                {t('billing.subscription_panel.ai_tokens_usage.renew_in')}
            </React.Fragment>
        );
    };

    render() {
        const { t, subscription } = this.props;

        return (
            <React.Fragment>
                <Grid className="row" container alignItems="center" direction="row" justifyContent="center">
                    <Grid item xs={3}>
                        <span>{t('billing.available_ai_tokens')}:</span>
                    </Grid>
                    <Grid item xs>
                        <span className="title">{formatWithCommas(this.getTotal())}</span>

                        <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                            {this.getTotal() === 0 && t('billing.subscription_panel.tooltip_ai_tokens_total_zero')}
                            {this.getTotal() > 0 &&
                                t('billing.subscription_panel.tooltip_ai_tokens_total', {
                                    total: formatWithCommas(this.getTotal()),
                                    aiTokensPerUser: formatWithCommas(subscription.plan.aiTokensPerUser),
                                    aiUsers: subscription.aiUsers,
                                    additional:
                                        subscription.additionalAiTokens > 0
                                            ? t('billing.subscription_panel.tooltip_ai_tokens_additional', {
                                                  total: formatWithCommas(subscription.additionalAiTokens),
                                              })
                                            : '',
                                    custom:
                                        subscription.additionalCustomAiTokens > 0
                                            ? t('billing.subscription_panel.tooltip_ai_tokens_custom', {
                                                  total: formatWithCommas(subscription.additionalCustomAiTokens),
                                              })
                                            : '',
                                })}
                        </Hint>
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" onClick={this.handleChange} data-testid="button.change_ai_tokens">
                            <Typography noWrap variant="inherit">
                                {t('billing.button.change_ai_tokens')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span> {t('billing.ai_tokens_usage')}: </span>
                    </Grid>
                    <Grid item xs>
                        <div style={{ width: 100 }}>
                            <LinearProgressWithLabel
                                label={formatWithCommas(this.getTotal())}
                                value={Math.min(Math.round((this.getUsage() / this.getTotal()) * 100), 100)}
                                tooltip={this.getLinearProgressTooltipText()}
                                style={{ width: 100 }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" onClick={this.handleShowDailyUsageHistory}>
                            {t('billing.subscription_panel.ai_tokens_usage.show_usage_button')}
                        </Button>
                    </Grid>
                </Grid>

                {this.state.hasAiTokensEditDialog && (
                    <AiTokensEditDialog
                        subscription={this.props.subscription}
                        account={this.props.account}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        onCloseDialog={this.handleHideAiTokensEditDialog}
                        closeSnackbar={() => void 0}
                    />
                )}

                {this.state.hasAiTokensDailyUsagesDialog && (
                    <AiTokensDailyUsages
                        account={this.props.account}
                        onClose={this.handleHideDailyUsageHistory}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        closeSnackbar={() => void 0}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(AiTokensSubscriptionPanel);
