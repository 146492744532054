import AbstractEvent, { EventStatus } from './Events/AbstractEvent';
import ProcessorInterface from './Processors/ProcessorInterface';
import AddRecord from './Events/AddRecord';
import AddRecordProcessor from './Processors/AddRecordProcessor';
import UpdateRecord from './Events/UpdateRecord';
import SubmitForm from './Events/SubmitForm';
import UpdateRecordProcessor from './Processors/UpdateRecordProcessor';
import SubmitFormProcessor from './Processors/SubmitFormProcessor';
import RunButtonProcessor from './Processors/RunButtonProcessor';
import RunButton from './Events/RunButton';

class ProcessorFactory {
    process(event: AbstractEvent): void {
        this.getProcessor(event).process(event);
    }

    onComplete(event: AbstractEvent): void {
        if (!event.isFinalStatus()) {
            return;
        }

        if (event.getStatus() === EventStatus.SUCCESS) {
            event.resolve(event.getResult());
        }

        this.getProcessor(event).onComplete(event);
    }

    getProcessor(event: AbstractEvent): ProcessorInterface {
        if (event instanceof AddRecord) {
            return new AddRecordProcessor();
        } else if (event instanceof UpdateRecord) {
            return new UpdateRecordProcessor();
        } else if (event instanceof SubmitForm) {
            return new SubmitFormProcessor();
        } else if (event instanceof RunButton) {
            return new RunButtonProcessor();
        }

        throw new Error('Unknown event type');
    }
}

export default ProcessorFactory;
