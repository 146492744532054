import React, { CSSProperties } from 'react';
import { Collapse } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

interface Props {
    title: React.ReactNode | string;
    children: React.ReactNode;
    open?: boolean;
    onClick?: () => void;
    style?: CSSProperties;
}

interface State {
    open: boolean;
}

class Spoiler extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: this.isControlled() ? Boolean(props.open) : false,
        };
    }

    isControlled = () => {
        return this.props.open !== undefined && this.props.onClick !== undefined;
    };

    handleClick = () => {
        if (this.isControlled()) {
            const { onClick } = this.props;
            onClick && onClick();
        } else {
            this.setState((state) => ({
                open: !state.open,
            }));
        }
    };

    render() {
        const { style, title, children } = this.props;
        const { open } = this.state;
        const iconStyle: CSSProperties = { marginLeft: 5, opacity: 0.7 };
        const titleDivStyle: CSSProperties = {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            cursor: 'pointer',
            ...(style || {}),
        };

        return (
            <React.Fragment>
                <div style={titleDivStyle} onClick={this.handleClick}>
                    {title}
                    <Icon fontSize="small" style={iconStyle}>
                        {open ? 'expand_less' : 'expand_more'}
                    </Icon>
                </div>
                <Collapse in={open}>{children}</Collapse>
            </React.Fragment>
        );
    }
}

export default Spoiler;
