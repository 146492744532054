import { LocationTrackingSettings } from 'service/types';
import { logDebug } from '../../utils';
import { userManager } from '../UserManager';

const STORAGE_KEY = 'mapsly.location_tracking_settings';

const defaultValues: Readonly<LocationTrackingSettings> = {
    trackGeolocation: false,
    autoActivateGeolocationDuringWork: false,
};

class LocationTrackingSettingsStorage {
    private getStorageKey(): string {
        return STORAGE_KEY + userManager.getCurrentUser().id;
    }

    save(config: LocationTrackingSettings): void {
        console.log('Saving tracking settings', config);
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(config));
    }

    load(): LocationTrackingSettings {
        let config;
        const json = window.localStorage.getItem(this.getStorageKey());
        if (json === null) {
            config = { ...defaultValues };
        } else {
            try {
                config = JSON.parse(json);
            } catch (e) {
                logDebug(e);
                config = { ...defaultValues };
            }
        }

        return config;
    }

    switchTrackNowSettingOn(): void {
        let currentSettings = this.load();
        if (currentSettings.trackGeolocation) {
            return;
        }
        currentSettings.trackGeolocation = true;
        this.save(currentSettings);
    }

    switchTrackNowSettingOff(): void {
        let currentSettings = this.load();
        if (!currentSettings.trackGeolocation) {
            return;
        }
        currentSettings.trackGeolocation = false;
        this.save(currentSettings);
    }

    switchScheduleSettingOff(): void {
        let currentSettings = this.load();
        if (!currentSettings.autoActivateGeolocationDuringWork) {
            return;
        }
        currentSettings.autoActivateGeolocationDuringWork = false;
        this.save(currentSettings);
    }

    isGeolocationTrackingSwitchedOn = () => {
        return this.load().trackGeolocation;
    };

    isGeolocationTrackingAutoSwitchingEnabled = () => {
        return this.load().autoActivateGeolocationDuringWork;
    };
}

export const locationTrackingSettingsStorage = new LocationTrackingSettingsStorage();
