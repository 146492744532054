import React, { MouseEventHandler } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface LiveSearchResultsFormProps<T> extends WithTranslation {
    records: T[];
    onRowClick: MouseEventHandler<HTMLElement>;
    selectedRecord: Record<string, any>;
    selectedRecords: any[];
    titleFields: string[];
}

class LiveSearchResultsForm<T extends Record<string, any>> extends React.PureComponent<LiveSearchResultsFormProps<T>> {
    render() {
        const { t, records, selectedRecord, selectedRecords, titleFields } = this.props;

        return (
            <Table padding="normal" className="c-live-search-form__results_single">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"> </TableCell>
                        {titleFields.map((title) => (
                            <TableCell align="left" key={title}>
                                {t('live_search_results_form.title_field.' + title)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((record, index) => {
                        const isSelected =
                            (selectedRecord && selectedRecord.id === record.id) ||
                            !!selectedRecords.find((r) => record.id === r.id);
                        const textValues = titleFields
                            .map((t) =>
                                record.data[t] !== undefined && record.data[t] !== null ? '' + record.data[t] : '',
                            )
                            .filter((t) => t.trim().length)
                            .filter((t) => t !== 'id');
                        const textValue = textValues.length ? textValues[0] : t('n_a');

                        return (
                            <TableRow
                                key={record.id}
                                hover
                                style={{ cursor: 'pointer' }}
                                data-record-index={index}
                                data-text-value={textValue}
                                onClick={this.props.onRowClick}
                            >
                                <TableCell align="center">
                                    <RadioGroup value="selected" row>
                                        <FormControlLabel
                                            value={isSelected ? 'selected' : ''}
                                            control={<Radio color="primary" />}
                                            label=""
                                        />
                                    </RadioGroup>
                                </TableCell>
                                {titleFields.map((t) => (
                                    <TableCell align="left" key={t}>
                                        {record.data[t] && record.data[t].name ? record.data[t].name : record.data[t]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LiveSearchResultsForm);
