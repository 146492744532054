import { AdapterId } from 'components/types';

const STORAGE_KEY = 'mapsly.sso_auth_used_provider';

type AdapterCredentialValue = {
    [key: string]: string;
};

type AdapterCredential = {
    [key in AdapterId]?: AdapterCredentialValue;
};

type SsoAuthProviderInfo = {
    adapter: AdapterId | null;
    credentials: AdapterCredential;
};

const defaultValues: Readonly<SsoAuthProviderInfo> = {
    adapter: null,
    credentials: {},
};

class SsoAuthProviderStorage {
    private info: SsoAuthProviderInfo | null = null;

    setAdapter(adapter: AdapterId) {
        const info = this.load();
        info.adapter = adapter;
        SsoAuthProviderStorage.save(info);
    }

    getAdapter(): AdapterId | null {
        const info = this.load();
        return info.adapter;
    }

    clearAdapter() {
        const info = this.load();
        info.adapter = null;
        SsoAuthProviderStorage.save(info);
    }

    setCredentials(adapter: AdapterId, credentials: AdapterCredentialValue) {
        const info = this.load();
        info.credentials[adapter] = credentials;
        SsoAuthProviderStorage.save(info);
    }

    getCredentials(adapter: AdapterId): AdapterCredentialValue | null {
        const info = this.load();
        return info.credentials[adapter] ?? null;
    }

    private static save(info: SsoAuthProviderInfo): void {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(info));
    }

    private load(): SsoAuthProviderInfo {
        if (this.info === null) {
            this.info = SsoAuthProviderStorage.deserialize();
        }

        return this.info;
    }

    private static deserialize(): SsoAuthProviderInfo {
        const json = window.localStorage.getItem(STORAGE_KEY);
        if (json === null) {
            return defaultValues;
        }

        try {
            return JSON.parse(json);
        } catch (e) {
            return defaultValues;
        }
    }
}

export default SsoAuthProviderStorage;
