/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { ORDER, workflowButtonManager } from '../../service/WorkflowButtonManager';
import { Grid, Icon, Link, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { userManager } from '../../service/UserManager';
import geoLocationManager from '../../service/GeoLocationManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import i18n from '../../locales/i18n';
import Confirmation from '../Confirmation';
import { limitationsManager } from '../../service/LimitationsManager';
import isEmpty from 'lodash/isEmpty';
import { withSnackbar } from 'notistack';
import EntityViewManager from '../../service/EntityViewManager';
import { Workflow } from '../../interfaces';
import { tripModeManager } from 'service/MapPage';
import { textEllipsis } from '../utils/Strings';
import QueueHandler from '../../service/EventsQueue/QueueHandler';
import RunButton from '../../service/EventsQueue/Events/RunButton';

const t = i18n.t.bind(i18n);
const TABLE_GROUP_ACTION_WARNING_COUNT = 100;

export function prepareContextRoute(route, point) {
    const { leg, ...currentPoint } = point;
    const { shape, ...result } = route.result || {};

    return {
        route: {
            ...route,
            activities: route.activities.map((activity) => {
                const { leg, ...rest } = activity;
                return rest;
            }),
            input: {
                ...route.input,
                points: Array.from(route.input.points.values()),
            },
            user: route.user.id,
            result,
            currentPoint,
        },
        routeActivity: point.id,
    };
}

function getButtonIconForPopup(button) {
    if (button.icon) {
        return button.icon;
    }

    const label = (button.label || '').toLowerCase();
    const match = label.match(/([a-z]).*/)?.[1];

    return match || 'gear';
}

class WorkflowButtonFactory extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loadingMap: new Map(),
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.WORKFLOW_ACTIONS_BUTTONS_REFRESHED, this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WORKFLOW_ACTIONS_BUTTONS_REFRESHED, this);
    }

    getEntityId() {
        return this.props.entityId
            ? this.props.entityId
            : this.props.item
            ? this.props.item.entityId
            : this.props.items
            ? this.props.items[0].entityId
            : null;
    }

    getItems() {
        if (this.props.forAllItems) {
            return [];
        }

        const ids = this.props.items
            ? this.props.items.map((item) => item.id)
            : this.props.item
            ? [this.props.item.id]
            : [];
        return [...ids, ...(this.props.itemIds ?? [])];
    }

    handleClick(button) {
        if (this.props.demo) {
            return;
        }

        let promise;
        const selectedLocation = button.isSelectedLocationAvailable ? this.props.selectedLocation : null;
        this.toggleLoading(button.id);

        if (button.isUserLocationAvailable) {
            promise = geoLocationManager
                .getLocationFull()
                .then((location) => this.runActions(button, location, selectedLocation));
        } else {
            promise = this.runActions(button, null, selectedLocation);
        }

        promise
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                this.toggleLoading(button.id);
                this.props.onClick && this.props.onClick();
            });
    }

    runActions(button, userLocation = null, selectedLocations = null) {
        let additionalContextData = this.props.context;
        userLocation && (additionalContextData.userLocation = userLocation);
        selectedLocations && (additionalContextData.selectedLocation = selectedLocations);
        if (tripModeManager.isTripStarted) {
            const { route, currentActivity } = tripModeManager;
            additionalContextData = {
                ...additionalContextData,
                ...prepareContextRoute(route, currentActivity),
            };
        }

        const entityId = this.getEntityId();
        const forAllItems = this.props.forAllItems;
        const combinedFilters = entityId && forAllItems ? EntityViewManager.getCombinedFilters(entityId) : {};

        return QueueHandler.addEvent(
            new RunButton(
                userManager.getCurrentAccount().id,
                button.id,
                entityId,
                this.getItems(),
                forAllItems,
                isEmpty(additionalContextData) ? null : additionalContextData,
                null,
                combinedFilters,
                this.props.itemsCount,
                { buttonLabel: button.label },
            ),
        );
    }

    toggleLoading(buttonId) {
        this.setState((state) => {
            const loadingMap = new Map(state.loadingMap);
            if (loadingMap.has(buttonId)) {
                loadingMap.delete(buttonId);
            } else {
                loadingMap.set(buttonId, true);
            }
            return { loadingMap };
        });
    }

    render() {
        const { location, order, onFilter } = this.props;
        let buttons;
        if (onFilter) {
            buttons = workflowButtonManager.getButtonsFor(location, order, this.getEntityId()).filter((button) => {
                return onFilter(button);
            });
        } else {
            buttons = workflowButtonManager.getButtonsFor(location, order, this.getEntityId());
        }

        if (buttons.length === 0) {
            return null;
        }

        return buttons.map(this.renderButton);
    }

    renderButton = (button) => {
        const loading = this.state.loadingMap.has(button.id);
        switch (this.props.location) {
            case Workflow.Locations.MAIN_MENU:
                return (
                    <MenuItem
                        key={button.id}
                        onClick={this.handleClick.bind(this, button)}
                        disabled={loading}
                        className={'main_menu_item'}
                    >
                        <Grid container spacing={1} alignContent="center" wrap="nowrap">
                            <Grid item>
                                <i
                                    style={{ color: 'gray', width: '1rem' }}
                                    className={`fa fa-${button.icon || 'fw'}`}
                                />
                            </Grid>
                            <Grid item>{textEllipsis(button.label, 40)}</Grid>
                        </Grid>
                    </MenuItem>
                );
            case Workflow.Locations.TABLE_CONTEXT_MENU:
            case Workflow.Locations.MAP_CLICK:
            case Workflow.Locations.WAYPOINT:
            case Workflow.Locations.ROUTE_MENU:
                return (
                    <MenuItem key={button.id} onClick={this.handleClick.bind(this, button)} disabled={loading}>
                        {button.label}
                    </MenuItem>
                );
            case Workflow.Locations.MAP_POPUP:
                return (
                    <Grid item key={button.id}>
                        <Tooltip title={button.label}>
                            <Link
                                underline="none"
                                onClick={this.handleClick.bind(this, button)}
                                className={loading && 'disabled'}
                            >
                                <span className="map-view-form-popup-buttons__button-text">{button.label}</span>
                                <Icon
                                    className={`map-view-form-popup-buttons__icon fas fa-${getButtonIconForPopup(
                                        button,
                                    )}`}
                                />
                            </Link>
                        </Tooltip>
                    </Grid>
                );
            case Workflow.Locations.TABLE_GROUP_ACTIONS:
                return this.renderTableGroupActions(button);
            default:
                return null;
        }
    };

    renderTableGroupActions(button) {
        if (button.visibilityType === Workflow.VisibilityType.ONE && this.props.itemsCount > 1) {
            return this.renderInactiveGroupAction(button, t('workflow_buttons.disabled_for_multiple'));
        } else if (
            button.visibilityType === Workflow.VisibilityType.MULTIPLE &&
            limitationsManager.getCurrent() &&
            this.props.itemsCount > limitationsManager.getCurrent().maxRecordsForGroupAction
        ) {
            return this.renderInactiveGroupAction(
                button,
                t('workflow_buttons.disabled_by_max_records_limit', {
                    count: limitationsManager.getCurrent().maxRecordsForGroupAction,
                }) + (userManager.isRoleAdmin() ? ' ' + t('workflow_buttons.disabled_by_max_records_admin') : ''),
            );
        } else {
            return this.renderActiveGroupAction(button);
        }
    }

    renderActiveGroupAction(button) {
        const loading = this.state.loadingMap.has(button.id);
        if (this.props.itemsCount >= TABLE_GROUP_ACTION_WARNING_COUNT) {
            return (
                <Confirmation
                    key={button.id}
                    text={t('workflow_buttons.warning_limit', { count: this.props.itemsCount })}
                    onConfirm={this.handleClick.bind(this, button)}
                >
                    <MenuItem key={button.id} disabled={loading}>
                        {button.label}
                    </MenuItem>
                </Confirmation>
            );
        }
        return (
            <MenuItem key={button.id} onClick={this.handleClick.bind(this, button)} disabled={loading}>
                {button.label}
            </MenuItem>
        );
    }

    renderInactiveGroupAction(button, hint) {
        return (
            <MenuItem key={button.id}>
                <Tooltip title={hint}>
                    <Typography display="inline" component="span" color="textSecondary" variant="inherit">
                        {button.label}
                    </Typography>
                </Tooltip>
            </MenuItem>
        );
    }
}

WorkflowButtonFactory.defaultProps = {
    forAllItems: false,
    context: {},
};

WorkflowButtonFactory.propTypes = {
    order: PropTypes.oneOf([ORDER.FIRST, ORDER.LAST]).isRequired,
    location: PropTypes.string.isRequired,
    entityId: PropTypes.number,
    item: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    itemIds: PropTypes.array,
    forAllItems: PropTypes.bool,
    itemsCount: PropTypes.number,
    context: PropTypes.object,
    onFilter: PropTypes.func,
    onClick: PropTypes.func,
    selectedLocation: PropTypes.object,
    demo: PropTypes.bool,
};

export default withSnackbar(WorkflowButtonFactory);
