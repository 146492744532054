import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { CalendarEvent, CalendarEventRepository, CalendarIdResponse } from './CalendarEventRepository';
import { userManager } from '../UserManager';

enum JOB_TYPES {
    SAVE_DRAFT = 'save_draft',
    COMMIT_DRAFT = 'commit_draft',
    REVERT_DRAFT = 'revert_draft',
    LIST_REQUEST = 'list_request',
    SYNC_REQUEST = 'sync_request',
    ARRANGE_REQUEST = 'arrange_request',
}

export class HttpCalendarEventRepository extends BackendService implements CalendarEventRepository {
    private get accountId(): number {
        return userManager.getCurrentAccount().id;
    }

    private sendRequest(jobType: JOB_TYPES, data?: any): Promise<CalendarIdResponse> {
        const requestData: any = {
            type: jobType,
        };
        if (data) {
            requestData.data = data;
        }

        const url = reverse(apiRoutes.account.calendarEventJobs, { accountId: this.accountId });
        return this.requestApi(url, 'POST', requestData);
    }

    sendGetEventsRequest(
        ownerIds: number[],
        from: Date,
        to: Date,
        includeDraftEvents: boolean,
    ): Promise<CalendarIdResponse> {
        const data: any = {
            ownersIds: ownerIds,
            from: from.toISOString(),
            to: to.toISOString(),
            includeDraftEvents: includeDraftEvents,
        };
        return this.sendRequest(JOB_TYPES.LIST_REQUEST, data);
    }

    sendPutDraftEventsRequest(events: CalendarEvent[]): Promise<CalendarIdResponse> {
        return this.sendRequest(JOB_TYPES.SAVE_DRAFT, events);
    }

    sendCommitDraftRequest(): Promise<CalendarIdResponse> {
        return this.sendRequest(JOB_TYPES.COMMIT_DRAFT);
    }

    sendRevertDraftRequest(): Promise<CalendarIdResponse> {
        return this.sendRequest(JOB_TYPES.REVERT_DRAFT);
    }

    sendSyncRequest(eventIds: string[]): Promise<CalendarIdResponse> {
        return this.sendRequest(JOB_TYPES.SYNC_REQUEST, { eventIds });
    }

    sendArrangementRequest(day: string, ownerId: number): Promise<CalendarIdResponse> {
        return this.sendRequest(JOB_TYPES.ARRANGE_REQUEST, { day, ownerId });
    }
}
