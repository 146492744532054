import React from 'react';
import { InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import MultipleSelect from '../../Controls/MultipleSelect';

class FormControlMultipleSelect extends React.PureComponent {
    render() {
        const { error, label, helperText, children, required, ...props } = this.props;

        return (
            <FormControl fullWidth margin="dense" error={error}>
                <InputLabel required={required}>{label}</InputLabel>
                <MultipleSelect {...props}>{children}</MultipleSelect>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

export default FormControlMultipleSelect;
