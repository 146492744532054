const COLORS: Array<string> = [
    '#1f77b4',
    '#ff7f0f',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#16becf',
    '#558093',
    '#ff8235',
    '#648b64',
    '#a75f61',
    '#887e98',
    '#857a77',
    '#b3809b',
    '#828282',
    '#98985a',
    '#4798a3',
    '#0d314b',
    '#ff3406',
    '#124212',
    '#621011',
    '#3d2b50',
    '#39241f',
    '#6f3153',
    '#343434',
    '#50500e',
    '#09515c',
    '#519dd3',
    '#ffa32f',
    '#65c165',
    '#ee5e5f',
    '#b591dc',
    '#ae8982',
    '#f69de0',
    '#a3a3a3',
    '#dbdc56',
    '#40dcea',
    '#0018ff',
    '#ff1800',
    '#002800',
    '#ff0000',
    '#280cff',
    '#280603',
    '#ff18ff',
    '#181818',
    '#ffff00',
    '#00ffff',
];

export class ColorGenerator {
    private iterator: Iterator<string>;

    constructor() {
        this.iterator = COLORS[Symbol.iterator]();
    }

    getColor(): string {
        let next = this.iterator.next();
        if (next.done) {
            this.iterator = COLORS[Symbol.iterator]();
            next = this.iterator.next();
        }
        return next.value;
    }
}
