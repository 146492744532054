import { ImportMessage, LEVEL } from './types';

class Comparator {
    public static compare(m1: ImportMessage, m2: ImportMessage): number {
        return (
            (m1.ds?.id ?? 0) - (m2.ds?.id ?? 0) ||
            (m1.entity?.id ?? 0) - (m2.entity?.id ?? 0) ||
            (m1.field?.id ?? 0) - (m2.field?.id ?? 0) ||
            Comparator.calculateLevelPriority(m1.level) - Comparator.calculateLevelPriority(m2.level) ||
            Comparator.calculateOriginPriority(m1.origin) - Comparator.calculateOriginPriority(m2.origin) ||
            m1.httpCode - m2.httpCode ||
            m1.origin.localeCompare(m2.origin)
        );
    }

    public static compareLevelPriority(l1: LEVEL | null, l2: LEVEL | null): number {
        return Comparator.calculateLevelPriority(l1) - Comparator.calculateLevelPriority(l2);
    }

    private static calculateLevelPriority(level: LEVEL | null): number {
        switch (level) {
            case LEVEL.ERROR:
                return 0;
            case LEVEL.WARNING:
                return 1;
            case LEVEL.INFO:
                return 2;
            default:
                return 3;
        }
    }

    private static calculateOriginPriority(origin: string): number {
        switch (origin) {
            case 'get_entities':
                return 0;
            case 'get_entity':
                return 1;
            case 'get_entity_records':
                return 2;
            case 'get_entity_deleted_records':
                return 3;
            case 'internal':
                return 10;
            default:
                console.error(new Error('Unknown origin: ' + origin));
                return 99;
        }
    }
}

export default Comparator;
