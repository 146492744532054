import BackendService from 'api/BackendService';
import dispatcher from './dispatcher';
import events from '../events';
import entityViewManager from './EntityViewManager';
import { DEBUG_MODE, isEqual, logDebug, propertyNameToId } from '../utils';
import mapStateManagerFactory from '../service/MapStateManagerFactory';
import throttle from 'lodash/throttle';
import { LAYER_GROUP_NONE } from './MapStateManagerFactory';
import { FIELD_DISTANCE } from '../components/Map/constants';
import Timer, { TIMER_OPERATIONS } from '../handlers/TimerHandler';
import filterParamsManager from './FilterParamsManager';
import { userManager } from './UserManager';
import mapSettingsManager from './MapSettingsManager';
import L from 'leaflet';
import { DEFAULT_INTENSITY } from './HeatmapManager';
import { PromiseQueue } from '../components/utils/PromiseQueue';
import metadataManager from './MetadataManager';
import { recordManager, withIdsFilter } from './RecordManager';
import { STANDARD_FIELDS } from '../standardFields';

export const isIncluded = (bounds1, bounds2) => {
    return (
        bounds2.maxLat >= bounds1.maxLat &&
        bounds2.minLat <= bounds1.minLat &&
        bounds2.maxLng >= bounds1.maxLng &&
        bounds2.minLng <= bounds1.minLng
    );
};

const MULTIPLIER = 4;

export const HEATMAP_INDICES = {
    ID: 0,
    LAT: 1,
    LNG: 2,
    VAL: 3,
};

export const INITIAL_BOUNDS = {
    maxLat: 90 * MULTIPLIER,
    minLat: -90 * MULTIPLIER,
    maxLng: 180 * MULTIPLIER,
    minLng: -180 * MULTIPLIER,
};

export const MAX_CLUSTER_SIZE = 200;
export const MAX_VISIBLE_POINTS = 1000;
export const MAX_ZOOM = 18;
const MAX_DOM_ELEMENTS = 500;

export class PointsManager extends BackendService {
    reset() {
        this.loadingFractions = new Set();
        this.queuedEvents = [];
        this.fractions = new Map();
        this.fractionsCompletedBounds = new Map();

        this.heatmapFractions = new Map();
        this.heatmapFractionsCompletedBounds = new Map();
        this.heatmapLoadingKey = null;

        this.entitiesPoints = new Map();
        this.dataPoints = new Map();
        this.initialLoadCompleted = false;
        this.map = null;
        this.mapStateManager = null;
        this.mapInitialized = false;
        this.mapState = {
            moving: false,
            zooming: false,
        };
        this.ignoreDisableClusteringAtZoom = false;
    }

    constructor() {
        super();

        this.map = null;
        this.mapInitialized = false;
        this.mapStateManager = null;
        this.clusterizeCheckInterval = null;

        this.loadingFractions = new Set();
        this.fractions = new Map();
        this.fractionsCompletedBounds = new Map();
        this.heatmapLoadingKey = null;

        this.heatmapFractions = new Map();
        this.heatmapFractionsCompletedBounds = new Map();

        this.entitiesPoints = new Map();
        this.dataPoints = new Map();
        this.initialLoadCompleted = false;
        this.bounds = INITIAL_BOUNDS;

        this.pointsLoadingQueue = new Map();

        this.promiseQueue = new PromiseQueue(4);

        dispatcher.subscribe(
            events.EVENT_ENTITY_DATA_FILTER_CHANGED,
            this,
            (entityId, isTableFilterChanged, isMapFilterChanged, isTableWithDataChanged, isTableIdsChanged) => {
                // const currentTableFilter = entityViewManager.getEntityTableFilters(entityId);
                // const currentMapFilter = entityViewManager.getEntityMapFilters(entityId);
                // const isMapMode = currentMapFilter.length > 0;
                // const wasMapMode = oldMapFilter.length > 0;
                //
                // if ((isMapMode && isTableFilterChanged) // в режиме Map изменились фильтры таблицы
                //     || (!isMapMode && wasMapMode && oldTableFilter.length > 0) // выключился режим Map при котором были фильтры таблицы
                //     || (isMapMode && !wasMapMode && currentTableFilter.length > 0) // только что включили режим Map и уже есть фильтры в таблице
                // )
                if (isTableFilterChanged || isTableWithDataChanged || isTableIdsChanged) {
                    const promises = [];
                    for (let fractionId of this.fractions.keys()) {
                        if (this.constructor.isFractionOfEntity(fractionId, entityId)) {
                            this.resetFraction(fractionId);
                            promises.push(this.loadFraction(entityId, fractionId, this.bounds));
                        }
                    }
                    if (promises.length > 0) {
                        Promise.all(promises).then(() => {
                            dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
                        });
                    }
                }
            },
        );

        dispatcher.subscribe(events.MAP_FILTER_UPDATED, this, () => {
            const user = userManager.getCurrentUser();
            if (!user) {
                return;
            }
            const manager = mapStateManagerFactory.getManager(user.id);
            const resetFractions = [];
            const resetEntities = new Set();
            for (let fractionId of this.fractions.keys()) {
                const entityId = parseInt(fractionId.split('_')[0]);
                const layerId = parseInt(fractionId.split('_')[1]);
                const layers = entityViewManager.getEntity(entityId).layers;
                const filterId = manager.getEntityView(entityId);
                if (filterId) {
                    resetFractions.push(fractionId);
                    resetEntities.add(entityId);
                }
                if (!layers) {
                    continue;
                }
                const viewId = mapStateManagerFactory.getManager().getLayerView(entityId, layerId);
                if (viewId) {
                    const view = entityViewManager.getView(entityId, viewId);
                    if (view && view.containsFilter) {
                        resetFractions.push(fractionId);
                        resetEntities.add(entityId);
                        continue;
                    }
                }

                const entityLayerGroup = manager.getEntityLayerGroup(entityId);
                const layersWithFilterParam = layers.filter(
                    (layer) =>
                        layer.containsFilter &&
                        manager.isLayerVisible(layer.id) &&
                        layer.layerGroupId === entityLayerGroup,
                );
                if (!layersWithFilterParam.length) {
                    continue;
                }
                const found = !!layersWithFilterParam.find((layer) => layer.id === layerId);
                if (!found) {
                    continue;
                }
                resetFractions.push(fractionId);
                resetEntities.add(entityId);
            }
            for (const fractionId of resetFractions) {
                this.resetFraction(fractionId);
            }
            if (resetEntities.size) {
                for (const entityId of resetEntities.keys()) {
                    setTimeout(() => {
                        dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
                    }, 0);
                }
            }
        });

        dispatcher.subscribe(events.BASE_POINT, this, () => {
            this.dataPoints = new Map();
            const entityIds = new Map();
            for (let fractionId of this.fractions.keys()) {
                const entityId = parseInt(fractionId.split('_')[0]);
                const fractions = entityIds.get(entityId) || [];
                fractions.push(fractionId);
                entityIds.set(entityId, fractions);
            }
            for (let entityId of entityIds.keys()) {
                const filters = entityViewManager.getEntityTableFilters(entityId);
                const hasFilterByDistance = filters.find((f) => f.columnName === FIELD_DISTANCE) !== undefined;
                if (hasFilterByDistance) {
                    for (let fractionId of entityIds.get(entityId)) {
                        this.resetFraction(fractionId);
                    }
                }
            }
            const mapStateManager = mapStateManagerFactory.getManager();
            for (let entityId of entityIds.keys()) {
                const entityLayerGroup = mapStateManager.getEntityLayerGroup(entityId);
                const entity = entityViewManager.getEntity(entityId);
                if (null === entity) {
                    continue;
                }
                if (!entity.layers.length) {
                    return;
                }
                if (entityLayerGroup === LAYER_GROUP_NONE) {
                    return;
                }
                const distanceLayer = entity.layers.find((layer) => {
                    if (mapStateManager.isLayerVisible(layer.id) === false) {
                        return false;
                    }

                    if (entityLayerGroup !== layer.layerGroupId) {
                        return false;
                    }
                    return layer.isDistanceLayer;
                });
                logDebug('distanceLayer', distanceLayer);
                if (distanceLayer) {
                    let reset = false;
                    for (let fractionId of entityIds.get(entityId)) {
                        const layerId = parseInt(fractionId.split('_')[1]);
                        if (layerId === distanceLayer.id) {
                            this.resetFraction(fractionId);
                            reset = true;
                        }
                    }
                    if (reset) {
                        setTimeout(() => {
                            dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
                        }, 0);
                    }
                }
            }
        });

        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, (payload) => {
            this.resetEntities(payload.entityIds);
        });
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            this.resetEntities(payload.changesEntities);
        });
        dispatcher.subscribe(events.ENTITY_SETTINGS_CHANGED, this, (entityId) => {
            this.resetEntity(entityId);
        });

        dispatcher.subscribe(events.WS_UPDATE_RECORDS_RESPONSE, this, ({ entityId }) => {
            this.dataPoints.delete(entityId);
        });

        dispatcher.subscribe(events.WS_ENTITY_RECORDS_GEOCODED, this.constructor.name, this.onNewRecordsGeocoded);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_MODIFIED, this.constructor.name, this.onRecordsModified);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_DELETED, this.constructor.name, this.onRecordsDeleted);

        dispatcher.subscribe(
            [events.MAP_POSITION_CHANGED, events.CLUSTERING_SETTINGS_CHANGED],
            this.constructor.name,
            this.clusterizeApproximationDelayed,
        );
        dispatcher.subscribe(events.ENTITY_VISIBILITY_CHANGED, this, () => {
            this.clusterizeApproximationDelayed();
        });
    }

    resetEntities(entityIds) {
        for (let entityId of entityIds) {
            this.resetEntity(entityId);
            dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
        }
    }

    resetEntity = (entityId) => {
        for (let fractionId of this.fractions.keys()) {
            if (this.constructor.isFractionOfEntity(fractionId, entityId)) {
                this.resetFraction(fractionId);
            }
        }
        this.entitiesPoints.delete(entityId);
        this.dataPoints.delete(entityId);
    };

    /** @param {ActivityTypedPoint} point */
    onHighlighterMouseEnter = (point) => {
        dispatcher.dispatch(events.CURRENT_ROUTE_MAP_POINT_MOUSE_ENTER, point);
    };

    /** @param {ActivityTypedPoint} point */
    onHighlighterMouseLeave = (point) => {
        dispatcher.dispatch(events.CURRENT_ROUTE_MAP_POINT_MOUSE_LEAVE, point);
    };

    resetFraction(fractionId) {
        logDebug('Reset fraction', fractionId);
        this.fractions.delete(fractionId);
        this.fractionsCompletedBounds.delete(fractionId);
    }

    setBounds = (bounds, timer) => {
        if (
            isEqual(bounds.maxLat, this.bounds.maxLat) &&
            isEqual(bounds.minLat, this.bounds.minLat) &&
            isEqual(bounds.maxLng, this.bounds.maxLng) &&
            isEqual(bounds.minLng, this.bounds.minLng)
        ) {
            return;
        }
        this.bounds = bounds;
        dispatcher.dispatch(events.MAP_POSITION_CHANGED, { timer });
    };

    load(entityId, section, bounds, filters = [], ids = undefined, withData = undefined) {
        return metadataManager
            .requestEntityForUser(entityId)
            .then((entity) => {
                const fieldApiNames = [
                    STANDARD_FIELDS.ID,
                    STANDARD_FIELDS.GEO_COUNTRY,
                    STANDARD_FIELDS.GEO_COUNTRY_SHORT,
                    STANDARD_FIELDS.GEO_LAT,
                    STANDARD_FIELDS.GEO_LNG,
                    ...entity.columnNameFields,
                ];

                for (let field of entity.fields) {
                    if (!field.isIncluded) {
                        continue;
                    }
                    if (
                        entity.fieldsets.mapPinView.includes(field.apiName) ||
                        entity.fieldsets.mapHintView.includes(field.apiName)
                    ) {
                        fieldApiNames.push(field.apiName);
                        continue;
                    }
                    if (field.apiName === entity.pinField) {
                        fieldApiNames.push(field.apiName);
                    }
                }

                const task = PromiseQueue.createTask(() => {
                    const request = {
                        entityId,
                        sections: section,
                        filters: [...filters, ...withIdsFilter(ids)],
                        bounds,
                        apiNames: fieldApiNames,
                        limit: 15000,
                    };
                    if (withData) {
                        request.withData = withData;
                    }
                    return recordManager.getRecordsWithHasMore(request);
                });

                return this.promiseQueue.enqueue(task);
            })
            .then(({ items, hasMore }) => {
                const [entityId, layerId, viewId] = section.split('_');

                const points = this.convertEntityRecordsToPoints(parseInt(entityId), items);

                return {
                    entityId,
                    viewId,
                    layerId,
                    points,
                    hasMore,
                };
            });
    }

    getTrimmedLookupFieldLabel(field) {
        let label = field.label;
        if (field.lookupData !== null) {
            if (field.apiName.endsWith('_NAME')) {
                return label.replace(/\s\(NAME\)$/, '');
            }
        }
        return label;
    }

    buildPointFieldsetData(record, fieldset, fieldsMap) {
        const result = [];
        for (const fieldApiName of fieldset) {
            const field = fieldsMap.get(fieldApiName);
            if (field && record.hasOwnProperty(field.apiName)) {
                const value = record[field.apiName];
                result.push({
                    field: field.apiName,
                    label: this.getTrimmedLookupFieldLabel(field),
                    isLink: field.isLink,
                    type: field.type,
                    picklist: field.picklist,
                    value: value,
                });
            }
        }

        return result;
    }

    buildExtendedPointFieldsetData(record, fieldset, fieldsMap) {
        const result = [];
        for (const fieldApiName of fieldset) {
            const field = fieldsMap.get(fieldApiName);
            if (field && record.hasOwnProperty(field.apiName)) {
                result.push({
                    id: field.id,
                    field: field.apiName,
                    label: this.getTrimmedLookupFieldLabel(field),
                    isLink: field.isLink,
                    type: field.type,
                    picklist: field.picklist,
                    isReadOnly: field.isReadOnly,
                    length: field.length,
                    defaultValue: field.defaultValue,
                    originalApiName: field.originalApiName,
                    lookupData: field.lookupData,
                    value: record[field.apiName],
                    valueId: record[propertyNameToId(field.apiName)],
                });
            }
        }

        return result;
    }

    convertEntityRecordsToPoints(entityId, records) {
        const points = [];
        const entity = metadataManager.getEntityForUser(entityId);

        if (!entity) {
            return points;
        }

        const fieldsMap = new Map();
        let pinField = null;
        for (let field of entity.fields) {
            fieldsMap.set(field.apiName, field);
            if (field.apiName === entity.pinField) {
                pinField = field;
            }
        }

        for (const record of records) {
            const pinData = this.buildPointFieldsetData(record, entity.fieldsets.mapPinView, fieldsMap);
            const tooltipData = this.buildPointFieldsetData(record, entity.fieldsets.mapHintView, fieldsMap);
            let address = null;
            if (entity.pinField) {
                if (pinField) {
                    address = record[pinField.apiName] || null;
                } else {
                    address = 'Address hidden';
                }
            }

            points.push({
                id: record[STANDARD_FIELDS.ID],
                entityId,
                address,
                position: [record[STANDARD_FIELDS.GEO_LAT], record[STANDARD_FIELDS.GEO_LNG]],
                lat: record[STANDARD_FIELDS.GEO_LAT],
                lng: record[STANDARD_FIELDS.GEO_LNG],
                pin: pinData,
                tooltip: tooltipData,
                objectName: record.objectName,
                addressFields: record.addressFields,
                addressData: {
                    country: record[STANDARD_FIELDS.GEO_COUNTRY],
                    countryShort: record[STANDARD_FIELDS.GEO_COUNTRY_SHORT],
                },
                geoStatus: record[STANDARD_FIELDS.GEO_STATUS],
            });
        }

        return points;
    }

    reloadPointsByEntity = (entityId, pointIds) => {
        const entityPoints = this.getEntityPoints(entityId);
        const dataPoints = this.getDataPoints(entityId);
        for (let pointId of dataPoints.keys()) {
            dataPoints.delete(pointId);
            entityPoints.delete(pointId);
        }

        for (let fractionId of this.fractions.keys()) {
            if (this.constructor.isFractionOfEntity(fractionId, entityId)) {
                const fraction = this.fractions.get(fractionId);
                for (let pointId of pointIds) {
                    fraction.delete(pointId);
                }
                this.fractions.set(fractionId, fraction);
            }
        }

        const fractionsIdsToLoad = [];
        for (let fractionId of this.fractions.keys()) {
            if (this.constructor.isFractionOfEntity(fractionId, entityId)) {
                fractionsIdsToLoad.push(fractionId);
            }
        }

        if (!fractionsIdsToLoad.length || !pointIds.length) {
            return;
        }

        const tableIds = entityViewManager.getEntityTableIds(entityId);
        const tableWithData = entityViewManager.getEntityTableWithData(entityId);
        const tableFilters = entityViewManager.getEntityTableFilters(entityId);
        const filter = [...tableFilters, { columnName: 'id', operation: 'in', value: pointIds }];

        const d = new Set(fractionsIdsToLoad);
        for (const fractionIdToLoad of fractionsIdsToLoad) {
            this.load(entityId, fractionIdToLoad, this.bounds, filter, tableIds, tableWithData)
                .then((fraction) => {
                    this.processRecord(entityId, fraction);
                })
                .catch((error) => {
                    if (error.code === 404 || error.code === 403 || error.code === 401) {
                        return;
                    }
                    throw error;
                })
                .finally(() => {
                    d.delete(fractionIdToLoad);
                    if (d.size === 0) {
                        dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS_PARTIAL, { entityId });
                    }
                });
        }
    };

    reloadPointsThrottled = throttle(
        () => {
            this.pointsLoadingQueue.forEach((pointsIdsSet, entityId) => {
                const pointIds = Array.from(pointsIdsSet);
                this.pointsLoadingQueue.set(entityId, new Set());
                this.reloadPointsByEntity(entityId, pointIds);
            });
        },
        5000,
        { leading: true, trailing: false },
    );

    isHeatmapFractionLoaded(fractionId) {
        const loadedBounds = this.heatmapFractionsCompletedBounds.get(fractionId) || [];

        for (let loadedBound of loadedBounds) {
            if (isIncluded(this.bounds, loadedBound)) {
                logDebug('Skip loading points, bounds already loaded', fractionId);
                return true;
            }
        }

        return false;
    }

    isFractionLoaded(fractionId) {
        const loadedBounds = this.fractionsCompletedBounds.get(fractionId) || [];

        for (let loadedBound of loadedBounds) {
            if (isIncluded(this.bounds, loadedBound)) {
                logDebug('Skip loading points, bounds already loaded', fractionId);
                return true;
            }
        }

        return false;
    }

    isPointInBounds(lat, lng, bounds) {
        return lat >= bounds.minLat && lat <= bounds.maxLat && lng >= bounds.minLng && lng <= bounds.maxLng;
    }

    loadHeatmapFraction(entityId, layerId, fieldApiName = null) {
        const bounds = this.bounds;

        const fractionId = this.constructor.buildFractionId(entityId, layerId || 0, 0);
        const heatmapFractionId = fieldApiName ? fractionId + '_' + fieldApiName : fractionId;

        if (this.isHeatmapFractionLoaded(heatmapFractionId)) {
            const fractionPoints = this.heatmapFractions.get(heatmapFractionId);
            const points = [];
            for (let [, point] of fractionPoints) {
                if (this.isPointInBounds(point[HEATMAP_INDICES.LAT], point[HEATMAP_INDICES.LNG], bounds)) {
                    points.push(point);
                }
            }
            return Promise.resolve(points);
        }

        const params = {
            e: fractionId,
            property: fieldApiName,
            bounds,
            pageSize: 15000,
            pageNum: 0,
        };

        this.setHeatmapLoadingKey(this.buildHeatmapLoadingKey(params));

        const points = [];
        return this.loadHeatmapFractionPage(params, points)
            .then((isCompleted) => {
                const heatmapLoadingKey = this.buildHeatmapLoadingKey(params);
                if (this.heatmapLoadingKey === heatmapLoadingKey) {
                    this.setHeatmapLoadingKey(null);
                }
                if (!isCompleted) {
                    return [];
                }

                const fractionPoints = this.heatmapFractions.get(heatmapFractionId) || new Map();
                for (let point of points) {
                    fractionPoints.set(point[HEATMAP_INDICES.ID], point);
                }
                this.heatmapFractions.set(heatmapFractionId, fractionPoints);

                const loadedBounds = this.heatmapFractionsCompletedBounds.get(heatmapFractionId) || [];
                loadedBounds.push(bounds);
                this.heatmapFractionsCompletedBounds.set(heatmapFractionId, loadedBounds);

                return points;
            })
            .catch(() => {
                const heatmapLoadingKey = this.buildHeatmapLoadingKey(params);
                if (this.heatmapLoadingKey === heatmapLoadingKey) {
                    this.setHeatmapLoadingKey(null);
                }
            });
    }

    setHeatmapLoadingKey(newHeatmapLoadingKey) {
        const oldHeatmapLoadingKey = this.heatmapLoadingKey;
        this.heatmapLoadingKey = newHeatmapLoadingKey;
        dispatcher.dispatch(events.HEATMAP_LOAD, newHeatmapLoadingKey, oldHeatmapLoadingKey);
    }

    buildHeatmapLoadingKey(params) {
        const heatmapFractionId = params.property ? params.e + '_' + params.property : params.e;
        if (!params.bounds) {
            return heatmapFractionId;
        }
        return (
            heatmapFractionId +
            '_' +
            params.bounds.minLat +
            '_' +
            params.bounds.minLng +
            '_' +
            params.bounds.maxLat +
            '_' +
            params.bounds.maxLng
        );
    }

    loadHeatmapFractionPage(params, result) {
        const heatmapLoadingKey = this.buildHeatmapLoadingKey(params);
        if (heatmapLoadingKey !== this.heatmapLoadingKey) {
            return Promise.resolve(false);
        }
        const fieldApiNames = [STANDARD_FIELDS.ID, STANDARD_FIELDS.GEO_LAT, STANDARD_FIELDS.GEO_LNG];
        if (params.property) {
            fieldApiNames.push(params.property);
        }

        const [entityId] = params.e.split('_');

        const request = {
            entityId,
            sections: params.e,
            apiNames: fieldApiNames,
            limit: params.pageSize,
            bounds: params.bounds,
            offset: params.pageSize * params.pageNum,
            resultType: 'json',
        };

        return recordManager.getRecordsWithHasMore(request).then((response) => {
            const points = JSON.parse(response['items']);

            for (let point of points) {
                if (params.property) {
                    point[HEATMAP_INDICES.VAL] = parseFloat(point[HEATMAP_INDICES.VAL]);
                    if (Number.isNaN(point[HEATMAP_INDICES.VAL])) {
                        continue;
                    }
                } else {
                    point[HEATMAP_INDICES.VAL] = DEFAULT_INTENSITY;
                }
                result.push(point);
            }

            if (response.hasMore) {
                params.pageNum++;
                return this.loadHeatmapFractionPage(params, result);
            }

            return true;
        });
    }

    /**
     * warning: recurrent promise is never returned
     * @param entityId
     * @param fractionId
     * @param bounds
     * @param parentTimer
     * @param forceReload
     * @return {Promise<void>|Promise<boolean>}
     */
    loadFraction(entityId, fractionId, bounds = null, parentTimer = null, forceReload = false) {
        const timer =
            parentTimer instanceof Timer && !parentTimer.ended
                ? parentTimer.startChild(
                      TIMER_OPERATIONS.PointsManager.loadFraction,
                      { entityId, fractionId, bounds },
                      null,
                      true,
                  )
                : null;
        if (timer) {
            parentTimer.removeEmptyTerminators();
        }
        if (bounds === null) {
            bounds = this.bounds;
        }

        if (!forceReload && this.isFractionLoaded(fractionId)) {
            if (timer) {
                timer.end();
            }
            this.queuedEvents.push(() => {
                dispatcher.dispatch(events.EVENT_ENTITY_POINTS_IS_LOADED, {
                    entityId,
                    fractionId,
                    isInitialLoading: !this.initialLoadCompleted,
                    isCropped: false,
                });
            });
            this.clusterizeApproximationDelayed();
            return Promise.resolve(false);
        }

        const filter = entityViewManager.getEntityTableFilters(entityId);
        const ids = entityViewManager.getEntityTableIds(entityId);
        const withData = entityViewManager.getEntityTableWithData(entityId);
        const oldFilter = JSON.stringify(filter);
        const oldFilterParams = JSON.stringify(filterParamsManager.getFilterParams());
        const oldIds = JSON.stringify(ids);
        const oldWithData = JSON.stringify(withData);
        this.loadingFractions.add(fractionId);
        dispatcher.dispatch(events.EVENT_ENTITY_POINTS_IS_LOADING, {
            fractionId,
            isInitialLoading: !this.initialLoadCompleted,
        });

        return this.load(entityId, fractionId, bounds, filter, ids, withData)
            .then((fraction) => {
                const currentIds = JSON.stringify(entityViewManager.getEntityTableIds(entityId));
                const currentWithData = JSON.stringify(entityViewManager.getEntityTableWithData(entityId));
                const currentFilter = JSON.stringify(entityViewManager.getEntityTableFilters(entityId));
                const currentFilterParams = JSON.stringify(filterParamsManager.getFilterParams());
                if (
                    oldFilter !== currentFilter ||
                    oldFilterParams !== currentFilterParams ||
                    oldIds !== currentIds ||
                    oldWithData !== currentWithData
                ) {
                    // this check seems to cause double loading: if filters were changed during load then point, then points loading will be started by event listener
                    // todo: check if double loading is present
                    logDebug('Filters changed during load, need to load with valid filters', {
                        oldFilter,
                        currentFilter,
                        oldFilterParams,
                        currentFilterParams,
                        oldIds,
                        currentIds,
                    });
                    if (timer) {
                        timer.end();
                    }
                    this.loadFraction(entityId, fractionId, bounds, parentTimer);
                    return;
                }

                let hasMore = false;

                //for (let fraction of entityFractions) {
                const fractionPoints = this.fractions.get(fractionId) || new Map();
                if (this.fractions.has(fractionId) && bounds) {
                    const view = metadataManager.getView(fraction.entityId, fraction.viewId);
                    const layer = metadataManager.getLayer(fraction.entityId, fraction.layerId);
                    if ((view && view.hasNowVariable) || (layer && layer.hasNowVariable)) {
                        for (let point of fractionPoints.values()) {
                            if (this.isPointInBounds(point.position[0], point.position[1], bounds)) {
                                fractionPoints.delete(point.id);
                            }
                        }
                        if (fractionPoints.size === 0) {
                            this.resetFraction(fractionId);
                        }
                    }
                }

                const entityPoints = this.getEntityPoints(entityId);
                for (let point of fraction.points) {
                    fractionPoints.set(point.id, point);
                    entityPoints.set(point.id, point);
                }
                this.fractions.set(fractionId, fractionPoints);
                if (!fraction.hasMore) {
                    const loadedBounds = this.fractionsCompletedBounds.get(fractionId) || [];
                    loadedBounds.push(bounds);
                    this.fractionsCompletedBounds.set(fractionId, loadedBounds);
                    logDebug('Loaded all points in bounds', fractionId);
                }

                hasMore = fraction.hasMore;
                //}

                if (timer) {
                    timer.end();
                }

                this.queuedEvents.push(() => {
                    dispatcher.dispatch(events.EVENT_ENTITY_POINTS_IS_LOADED, {
                        entityId,
                        fractionId,
                        isInitialLoading,
                        isCropped: hasMore,
                    });
                });
                this.loadingFractions.delete(fractionId);
                const isInitialLoading = !this.initialLoadCompleted;
                if (this.loadingFractions.size === 0) {
                    this.initialLoadCompleted = true;
                }
                this.clusterizeApproximationDelayed();

                return true;
            })
            .catch((error) => {
                this.loadingFractions.delete(fractionId);
                const isInitialLoading = !this.initialLoadCompleted;
                if (this.loadingFractions.size === 0) {
                    this.initialLoadCompleted = true;
                }
                if (error.code === 404 || error.code === 403 || error.code === 401) {
                    // слой может прекратить существование из-за изменения настроек слоев
                    // user logged out
                    if (timer) {
                        timer.end();
                    }
                    this.queuedEvents.push(() => {
                        dispatcher.dispatch(events.EVENT_ENTITY_POINTS_IS_LOADED, {
                            entityId,
                            fractionId,
                            isInitialLoading,
                            isCropped: false,
                        });
                    });
                    this.clusterizeApproximationDelayed();

                    return false;
                }
                if (timer) {
                    timer.end();
                }

                this.queuedEvents.push(() => {
                    dispatcher.dispatch(events.EVENT_ENTITY_POINTS_IS_LOADED, {
                        entityId,
                        fractionId,
                        isInitialLoading,
                        isCropped: false,
                    });
                });
                this.clusterizeApproximationDelayed();

                throw error;
            });
    }

    onNewRecordsGeocoded = ({ entityId, records }) => {
        records.forEach((record) => this.processRecord(entityId, record));
        dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
    };

    onRecordsModified = ({ entityId, pointIds, modificationType }) => {
        if (modificationType !== 'update') {
            return;
        }

        if (!this.pointsLoadingQueue.get(entityId)) {
            this.pointsLoadingQueue.set(entityId, new Set());
        }

        pointIds.forEach((recordId) => this.pointsLoadingQueue.get(entityId).add(recordId));
        this.reloadPointsThrottled();
    };

    processRecord(entityId, fraction) {
        const fractionId = this.constructor.buildFractionId(entityId, fraction.layerId, fraction.viewId);
        const fractionPoints = this.fractions.get(fractionId) || null;
        if (null === fractionPoints) {
            return;
        }
        const entityPoints = this.getEntityPoints(entityId);
        for (let point of fraction.points) {
            fractionPoints.set(point.id, point);
            entityPoints.set(point.id, point);
        }
        this.fractions.set(fractionId, fractionPoints);
    }

    onRecordsDeleted = ({ entityId, recordIds }) => {
        for (let fractionId of this.fractions.keys()) {
            if (this.constructor.isFractionOfEntity(fractionId, entityId)) {
                const fraction = this.fractions.get(fractionId);
                for (let pointId of recordIds) {
                    fraction.delete(pointId);
                }
            }
        }
        const entityPoints = this.getEntityPoints(entityId);
        const dataPoints = this.getDataPoints(entityId);
        for (let pointId of recordIds) {
            entityPoints.delete(pointId);
            dataPoints.delete(pointId);
        }
        dispatcher.dispatch(events.RESET_ENTITY_FRACTIONS, entityId);
    };

    getPoint(entityId, pointId) {
        return this.getEntityPoints(entityId).get(pointId) || null;
    }

    getEntityPoints(entityId) {
        const id = parseInt(entityId);
        const entityPoints = this.entitiesPoints.get(id) || new Map();
        this.entitiesPoints.set(id, entityPoints);
        return entityPoints;
    }

    getDataPoints(entityId) {
        const id = parseInt(entityId);
        const dataPoints = this.dataPoints.get(id) || new Map();
        this.dataPoints.set(id, dataPoints);
        return dataPoints;
    }

    static isFractionOfEntity(fractionId, entityId) {
        const s = entityId + '_';
        return fractionId.substr(0, s.length) === s;
    }

    static isFractionOfLayer(fractionId, layerId) {
        return fractionId.indexOf('_' + layerId + '_') !== -1;
    }

    getFractionsPoints(fractionsIds) {
        const result = new Map();
        for (let fractionId of fractionsIds) {
            const fractionPoints = this.fractions.get(fractionId);
            if (fractionPoints) {
                for (let [pointId, point] of fractionPoints) {
                    result.set(pointId, point);
                }
            }
        }
        return result.values();
    }

    hasFraction(fractionId) {
        return this.fractions.has(fractionId);
    }

    getFractionPoints(fractionId) {
        return this.fractions.get(fractionId) || null;
    }

    isInitialPointsLoadingCompleted = () => {
        return this.initialLoadCompleted;
    };

    isPointsLoading = () => {
        return this.loadingFractions.size !== 0;
    };

    static buildFractionId = (entityId, layerId, viewId) => {
        return `${entityId}_${layerId}_${viewId}`;
    };

    entityHasPopupFields(entityId) {
        return metadataManager.requestEntityForUser(entityId).then((entity) => {
            return entity.fieldsets.mapPopupView.length > 0;
        });
    }

    /**
     *
     * @param {number} entityId
     * @param {string|null} recordId
     * @return {Promise<PointDataResponse>}
     */
    loadPointInfo(entityId, recordId) {
        const isDemoPoint = recordId === null;
        const dataPoints = this.getDataPoints(entityId);
        if (dataPoints.has(recordId)) {
            return Promise.resolve(dataPoints.get(recordId));
        }

        return metadataManager
            .requestEntityForUser(entityId)
            .then((entity) => {
                return metadataManager.requestDataSourceForUser(entity.dataSource.id);
            })
            .then(() => {
                if (isDemoPoint) {
                    return recordManager.getDemoRecord(entityId);
                }
                const entity = metadataManager.getEntityForUser(entityId);
                const fieldApiNames = [STANDARD_FIELDS.ID, STANDARD_FIELDS.MAPSLY_LINK];

                for (let field of entity.fields) {
                    if (!field.isIncluded) {
                        continue;
                    }
                    if (
                        entity.fieldsets.mapPinView.includes(field.apiName) ||
                        entity.fieldsets.mapHintView.includes(field.apiName) ||
                        entity.fieldsets.mapPopupView.includes(field.apiName)
                    ) {
                        fieldApiNames.push(field.apiName);
                        if (field.apiName.endsWith('_NAME')) {
                            fieldApiNames.push(propertyNameToId(field.apiName));
                        }
                    }
                }
                return recordManager.getRecord(entityId, recordId, fieldApiNames);
            })
            .then(({ record }) => {
                const entity = metadataManager.getEntityForUser(entityId);
                const dataSource = metadataManager.getDataSourceForUser(entity.dataSource.id);
                const fieldsMap = new Map();
                for (let field of entity.fields) {
                    if (!field.isIncluded) {
                        continue;
                    }
                    fieldsMap.set(field.apiName, field);
                }

                const info = {
                    pin: this.buildPointFieldsetData(record, entity.fieldsets.mapPinView, fieldsMap),
                    tooltip: this.buildPointFieldsetData(record, entity.fieldsets.mapHintView, fieldsMap),
                    popup: this.buildExtendedPointFieldsetData(
                        record,
                        isDemoPoint ? [...fieldsMap.keys()] : entity.fieldsets.mapPopupView,
                        fieldsMap,
                    ),
                    isLocked: false,
                    searchTitleFields: dataSource.searchTitleFields || [],
                    mapsly_link: record[STANDARD_FIELDS.MAPSLY_LINK] || null,
                };

                dataPoints.set(recordId, info);

                return info;
            });
    }

    /**
     *
     * @param {number} entityId
     * @return {Promise<PointDataResponse>}
     */
    loadDemoPointInfo(entityId) {
        return this.loadPointInfo(entityId, null);
    }

    getPointInfo(entityId, recordId) {
        const point = this.getPoint(entityId, recordId);
        return Promise.resolve(point ? point : {});
    }

    setMap(map) {
        this.map = map;
        if (!this.mapInitialized) {
            this.map.on('zoomstart', () => {
                this.mapState.zooming = true;
            });
            this.map.on('zoomend', () => {
                this.mapState.zooming = false;
            });
            this.map.on('movestart', () => {
                this.mapState.moving = true;
            });
            this.map.on('moveend', () => {
                this.mapState.moving = false;
            });
            this.mapInitialized = true;
        }
    }

    setMapStateManager(mapStateManager) {
        this.mapStateManager = mapStateManager;
    }

    isMapProcessing() {
        return this.mapState.moving || this.mapState.zooming || this.loadingFractions.size !== 0;
    }

    clusterizeApproximationDelayed = () => {
        if (this.clusterizeCheckInterval) {
            return;
        }
        this.clusterizeCheckInterval = setInterval(() => {
            if (this.isMapProcessing()) {
                return;
            }
            clearInterval(this.clusterizeCheckInterval);
            this.clusterizeCheckInterval = null;
            logDebug('clusterizeApproximation start', new Date());
            this.clusterizeApproximation();
            logDebug('clusterizeApproximation end', new Date());
            const events = this.queuedEvents;
            this.queuedEvents = [];
            events.forEach((event) => event());
        }, 50);
    };

    getVisiblePointsCount = () => {
        let count = 0;
        for (const fractionId of this.fractions.keys()) {
            const entityId = parseInt(fractionId.split('_')[0]);
            const layerId = parseInt(fractionId.split('_')[1]);
            if (this.mapStateManager.isEntityVisible(entityId) === false) {
                continue;
            }
            if (this.mapStateManager.isLayerVisible(layerId) !== true) {
                continue;
            }
            for (const point of this.fractions.get(fractionId)) {
                const lat = point[1].position[0];
                const lng = point[1].position[1];
                if (lat > this.bounds.maxLat || lat < this.bounds.minLat) {
                    continue;
                }
                if (lng > this.bounds.maxLng || lng < this.bounds.minLng) {
                    continue;
                }
                count++;
            }
        }
        return count;
    };
    /**
     * Provides approximate DOM elements count. Updates clustering settings if required.
     */
    clusterizeApproximation = () => {
        if (!this.map || !this.mapStateManager) {
            return;
        }
        let clusterSize = mapSettingsManager.getMaxClusterRadius();
        const zoom = this.mapStateManager.getMapZoom();
        if (!zoom) {
            return;
        }

        const validatePoints = new Map();

        const radiusFn = function () {
            return clusterSize;
        };

        const gridClusters = {};
        const gridUnclustered = {};

        let updateClusterSize = null;
        while (clusterSize < MAX_CLUSTER_SIZE) {
            for (const fractionId of this.fractions.keys()) {
                gridClusters[fractionId] = {};
                gridUnclustered[fractionId] = {};
                for (let z = 24; z >= 1; z--) {
                    gridClusters[fractionId][z] = new L.DistanceGrid(radiusFn(z));
                    gridUnclustered[fractionId][z] = new L.DistanceGrid(radiusFn(z));
                }
            }

            for (const fractionId of this.fractions.keys()) {
                const entityId = parseInt(fractionId.split('_')[0]);
                const layerId = parseInt(fractionId.split('_')[1]);
                if (this.mapStateManager.isEntityVisible(entityId) === false) {
                    continue;
                }
                if (this.mapStateManager.isLayerVisible(layerId) !== true) {
                    continue;
                }
                if (!validatePoints.has(fractionId)) {
                    validatePoints.set(fractionId, []);
                    for (const point of this.fractions.get(fractionId)) {
                        const lat = point[1].position[0];
                        const lng = point[1].position[1];
                        if (lat > this.bounds.maxLat || lat < this.bounds.minLat) {
                            continue;
                        }
                        if (lng > this.bounds.maxLng || lng < this.bounds.minLng) {
                            continue;
                        }
                        validatePoints.get(fractionId).push({
                            lat,
                            lng,
                            _cLatLng: {
                                lat,
                                lng,
                            },
                            options: {
                                clusterPane: null,
                            },
                        });
                    }
                }
            }

            for (const fractionId of validatePoints.keys()) {
                if (this.isMapProcessing()) {
                    this.clusterizeApproximationDelayed();
                    return;
                }
                const points = validatePoints.get(fractionId);
                for (const point of points) {
                    const markerPoint = this.map.project({ lat: point.lat, lng: point.lng }, zoom);
                    let closest = gridClusters[fractionId][zoom].getNearObject(markerPoint);
                    if (closest) {
                        closest._markers.push(point);
                        continue;
                    }
                    closest = gridUnclustered[fractionId][zoom].getNearObject(markerPoint);
                    if (closest) {
                        closest._markers.push(point);
                        const project = this.map.project(closest._cLatLng, zoom);
                        gridClusters[fractionId][zoom].addObject(closest, project);
                        gridUnclustered[fractionId][zoom].removeObject(closest, project);
                        continue;
                    }

                    const newCluster = {
                        _cLatLng: {
                            lat: point.lat,
                            lng: point.lng,
                        },
                        _markers: [point],
                    };
                    gridUnclustered[fractionId][zoom].addObject(newCluster, markerPoint);
                }
            }

            let clustersCount = 0;
            let pointsCount = 0;

            for (let fractionId of Object.keys(gridClusters)) {
                for (let k1 of Object.keys(gridClusters[fractionId][zoom]._grid)) {
                    for (let k2 of Object.keys(gridClusters[fractionId][zoom]._grid[k1])) {
                        clustersCount += gridClusters[fractionId][zoom]._grid[k1][k2].length;
                    }
                }
            }

            for (let fractionId of Object.keys(gridUnclustered)) {
                for (let k1 of Object.keys(gridUnclustered[fractionId][zoom]._grid)) {
                    for (let k2 of Object.keys(gridUnclustered[fractionId][zoom]._grid[k1])) {
                        for (let cluster of gridUnclustered[fractionId][zoom]._grid[k1][k2]) {
                            pointsCount += cluster._markers.length;
                        }
                    }
                }
            }

            if (this.isMapProcessing()) {
                this.clusterizeApproximationDelayed();
                return;
            }

            const totalApproxDOMElements = clustersCount + pointsCount;
            if (DEBUG_MODE) {
                setTimeout(() => {
                    logDebug(
                        'clustersCount',
                        clustersCount,
                        'pointsCount',
                        pointsCount,
                        'totalApproxDOMElements',
                        totalApproxDOMElements,
                    );
                    const realClustersCount = document.querySelectorAll('.leaflet-marker-icon.marker-cluster').length;
                    const realPointsCount = document.querySelectorAll('.leaflet-marker-icon.div-icon').length;
                    const totalRealDOMElements = realClustersCount + realPointsCount;
                    const diff = parseInt((totalRealDOMElements / totalApproxDOMElements) * 100) - 100;
                    logDebug(
                        'realClustersCount',
                        realClustersCount,
                        'realPointsCount',
                        realPointsCount,
                        'totalDOMElements',
                        totalRealDOMElements,
                        'diff',
                        diff + '%',
                    );
                }, 2000);
            }
            if (totalApproxDOMElements < MAX_DOM_ELEMENTS) {
                break;
            }

            clusterSize += totalApproxDOMElements / 2 >= MAX_DOM_ELEMENTS ? 40 : 20;
            if (clusterSize > MAX_CLUSTER_SIZE) {
                clusterSize = MAX_CLUSTER_SIZE;
            }
            updateClusterSize = clusterSize;
            if (updateClusterSize > MAX_CLUSTER_SIZE - 20) {
                break;
            }
        }

        if (this.isMapProcessing()) {
            this.clusterizeApproximationDelayed();
            return;
        }

        if (updateClusterSize) {
            dispatcher.dispatch(events.CLUSTER_SIZE_CHANGED, updateClusterSize);
        } else {
            dispatcher.dispatch(events.CLUSTER_SIZE_CHANGED, null);
        }

        const disableClusteringAtZoom = mapSettingsManager.getDisableClusteringAtZoomEnabled()
            ? mapSettingsManager.getDisableClusteringAtZoomValue()
            : null;
        this.ignoreDisableClusteringAtZoom = false;
        if (disableClusteringAtZoom && disableClusteringAtZoom <= zoom) {
            if (this.getVisiblePointsCount() > MAX_VISIBLE_POINTS) {
                this.ignoreDisableClusteringAtZoom = true;
            }
        }

        dispatcher.dispatch(events.CLUSTERING_RECALCULATED);
    };
}

export default new PointsManager();
