import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { CustomPaging, DataTypeProvider, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid as GridTable, PagingPanel, Table, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { TableHeaderRow } from '../Grid';
import { Icon, Tooltip, Switch, IconButton } from '@material-ui/core';
import DottedLink from '../DottedLink';
import Confirmation from '../Confirmation';
import { withStyles } from '@material-ui/core/styles';
import { DataTable, FilterCell, FilterIcon, HeaderCellContent } from '../DataTable';
import dsManagerFactory from '../../service/DsManager';
import { roleManager } from '../../service/RoleManager';
import { userManager } from '../../service/UserManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import DateTimeFormatter from 'components/DataTable/Formatter/DateTimeFormatter';

const tableCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const StyledFilterCell = withStyles({
    cell: {
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            paddingRight: 0,
        },
    },
    flexContainer: {
        flexFlow: 'row-reverse',
    },
})(FilterCell);

class WorkflowButtonsList extends DataTable {
    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];
        this.defaultPageSize = 25;

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
        };
    }

    componentDidMount() {
        super.componentDidMount();
        dispatcher.subscribe(events.WS_WORKFLOW_ACTIONS_BUTTONS_UPDATED, this, (data) => {
            this.props.onUpdateReceived && this.props.onUpdateReceived(data) && this.loadData(false);
        });
    }

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'isActive', width: 60, align: 'center' },
            { columnName: 'name', width: '20%' },
            { columnName: 'locations', width: '15%' },
            { columnName: 'entities', width: '15%' },
            { columnName: 'remove', width: 100, align: 'center' },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'locations', sortingEnabled: false },
            { columnName: 'entities', sortingEnabled: false },
            { columnName: 'roles', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c) => c.title).map((c) => c.title);
        return structure;
    }

    requestData(ignorePage = false, parentTimer = null) {
        return this.props.onRequestData({
            filters: this.getFilters(),
            sorting: this.sorting,
            currentPage: ignorePage ? 0 : this.state.pagination.current,
            pageSize: this.state.pagination.size,
        });
    }

    getFields() {
        return Promise.resolve([
            {
                columnName: 'isActive',
                title: null,
                getCellValue: (button) => (
                    <Tooltip title={this.renderDisabledTooltip(button)}>
                        <FormControlLabel
                            className=""
                            control={
                                <Switch
                                    checked={button.isActive}
                                    onChange={this.props.onToggleButton.bind(this, button)}
                                    disabled={button.disabled || !userManager.automationElementsManagement()}
                                    color="primary"
                                    data-testid="automation_elements.toggle_button_disabled"
                                />
                            }
                            label=""
                        />
                    </Tooltip>
                ),
            },
            {
                columnName: 'name',
                type: 'string',
                title: this.props.t('workflow_buttons.list.name'),
                getCellValue: (button) => (
                    <DottedLink
                        onClick={this.props.onEditButton.bind(this, button)}
                        data-testid="workflow_buttons.list.name"
                    >
                        {button.name}
                    </DottedLink>
                ),
            },
            {
                columnName: 'locations',
                type: 'text[]',
                title: this.props.t('workflow_buttons.list.locations'),
                picklist: [
                    { label: this.props.t('workflow_buttons.locations.mainMenu'), value: 'mainMenu' },
                    { label: this.props.t('workflow_buttons.locations.mapPopup'), value: 'mapPopup' },
                    { label: this.props.t('workflow_buttons.locations.tableContextMenu'), value: 'tableContextMenu' },
                    { label: this.props.t('workflow_buttons.locations.tableGroupActions'), value: 'tableGroupActions' },
                    { label: this.props.t('workflow_buttons.locations.mapClick'), value: 'mapClick' },
                    { label: this.props.t('workflow_buttons.locations.waypoint'), value: 'waypoint' },
                ],
                getCellValue: (button) => {
                    const value = button.locations
                        .map((location) => this.props.t('workflow_buttons.locations.' + location))
                        .join(', ');
                    return <span title={value}>{value}</span>;
                },
            },
            {
                columnName: 'entities',
                type: 'text[]',
                title: this.props.t('workflow_buttons.list.entities'),
                picklist: this.getEntityPicklist(),
                getCellValue: (button) => {
                    const value = button.entities
                        .map((entity) => entity.dataSource.name + ' > ' + entity.label)
                        .join(', ');
                    return <span title={value}>{value}</span>;
                },
            },
            {
                columnName: 'roles',
                type: 'text[]',
                title: this.props.t('workflow_buttons.list.profiles'),
                picklist: this.getRolesPicklist(),
                getCellValue: (button) => <span>{button.roles.map((role) => role.name).join(', ')}</span>,
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: this.props.t('workflow_buttons.list.updated_by'),
                getCellValue: (button) => <span>{button.updatedBy ? button.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: this.props.t('workflow_buttons.list.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (button) => (
                    <Confirmation
                        text={this.props.t('workflow_buttons.confirm.remove')}
                        onConfirm={this.props.onRemoveButton.bind(this, button)}
                    >
                        <Tooltip title={this.props.t('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="workflow_buttons.list.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ]);
    }

    renderDisabledTooltip = (button) => {
        if (!button.disabled) {
            return '';
        }
        const objectPrefix = this.props.t('automation_elements.disabled_object');
        const fieldPrefix = this.props.t('automation_elements.disabled_field');
        return (
            <React.Fragment>
                <span>{this.props.t('automation_elements.button_disabled')}</span>
                <ul>
                    {button.disabledEntities?.map((entity, index) => (
                        <li key={index}>{`${objectPrefix} ${entity}`}</li>
                    ))}
                    {button.disabledFields?.map((field, index) => (
                        <li key={index}>{`${fieldPrefix} ${field}`}</li>
                    ))}
                </ul>
            </React.Fragment>
        );
    };

    getEntityPicklist() {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        return manager.list().then((dataSources) => {
            const picklist = [];
            for (const dataSource of dataSources) {
                for (const entityCounter of dataSource.entityCounters) {
                    picklist.push({
                        value: entityCounter.entity.id,
                        label: dataSource.name + ' > ' + entityCounter.entity.label,
                    });
                }
            }
            return picklist;
        });
    }

    getRolesPicklist() {
        return roleManager.getAccountRoles(this.props.accountId).then((roles) => {
            return roles.map((role) => ({ value: role.id, label: role.name }));
        });
    }

    render() {
        const structure = this.state.structure;

        if (structure === null) {
            return <div>{this.props.t('loading')}</div>;
        }

        return (
            <React.Fragment>
                <GridTable rows={this.props.buttons} columns={structure.columns}>
                    <SortingState
                        onSortingChange={this.handleSortingChanged}
                        columnExtensions={structure.noSortingColumns}
                    />
                    <DataTypeProvider
                        for={structure.numericColumns}
                        availableFilterOperations={this.numericFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateColumns}
                        availableFilterOperations={this.dateFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateTimeColumns}
                        availableFilterOperations={this.dateFilterOperations}
                        formatterComponent={DateTimeFormatter}
                    />
                    <DataTypeProvider
                        for={structure.stringColumns}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.booleanColumns}
                        availableFilterOperations={this.booleanFilterOperations}
                    />
                    <FilteringState
                        onFiltersChange={this.handleFiltersChanged}
                        columnExtensions={[
                            { columnName: 'isActive', filteringEnabled: false },
                            { columnName: 'remove', filteringEnabled: false },
                        ]}
                    />
                    <Table
                        rowComponent={this.InteractiveRow}
                        columnExtensions={structure.exts}
                        cellComponent={withStyles(tableCellStyles)(Table.Cell)}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={structure.columns.length} loading={this.props.loading} />
                        )}
                    />
                    <TableHeaderRow
                        contentComponent={HeaderCellContent}
                        columnTitles={structure.columnTitles}
                        showSortingControls
                    />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        messages={this.filterMessages}
                        cellComponent={StyledFilterCell}
                    />

                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.props.total} />
                    <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
                </GridTable>
            </React.Fragment>
        );
    }
}

WorkflowButtonsList.propTypes = {
    buttons: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUpdateReceived: PropTypes.func.isRequired,
    onRequestData: PropTypes.func.isRequired,
    onEditButton: PropTypes.func.isRequired,
    onRemoveButton: PropTypes.func.isRequired,
    onToggleButton: PropTypes.func.isRequired,
};

export default withTranslation()(WorkflowButtonsList);
