import { Button, Checkbox, ListSubheader, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useState } from 'react';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);

const MultipleSelect = (props) => {
    const [open, setOpen] = useState(false);
    const { showOkButton, ...rest } = props;

    return (
        <Select
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple={true}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            {...rest}
        >
            {props.children?.map((item) => {
                if (item === null) {
                    return null;
                }
                if (item.type === ListSubheader) {
                    return item;
                }
                for (let child of item.props.children) {
                    if (child.type === Checkbox) {
                        return item;
                    }
                }

                return (
                    <MenuItem
                        key={item.key}
                        value={item.props.value}
                        style={props.wrapItems && { whiteSpace: 'normal' }}
                    >
                        <Checkbox
                            color="primary"
                            checked={
                                props.value.find((value) => value?.toString() === item.key.toString()) !== undefined
                            }
                        />
                        {item.props.children}
                    </MenuItem>
                );
            })}
            {showOkButton && (
                <Paper
                    elevation={0}
                    style={{ position: 'sticky', bottom: '0', textAlign: 'end', padding: '0 10px 10px 0' }}
                >
                    <Button
                        onClick={(e) => {
                            setOpen(false);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t('button.ok')}
                    </Button>
                </Paper>
            )}
        </Select>
    );
};

export default MultipleSelect;
