import { Routing } from 'interfaces';

class RoutingSettingsObjectKeyService {
    static parseObjectKey(key: string): Routing.Settings.MappingSelectedObject {
        const parts = key.split(':');
        return {
            dataSourceId: parseInt(parts[0], 10),
            entityId: parseInt(parts[1], 10),
        };
    }

    static getObjectKey(selectedObject: Routing.Settings.MappingSelectedObject): string {
        return `${selectedObject.dataSourceId}:${selectedObject.entityId}`;
    }
}

export default RoutingSettingsObjectKeyService;
