import BackgroundGeolocation, {
    Config,
    Location as PluginLocation,
    State,
} from '@transistorsoft/capacitor-background-geolocation';
import { userManager } from '../UserManager';
import Location from './Location';
import { weAreInNativeApp } from '../../utils';
import dispatcher from '../dispatcher';
import events from '../../events';
import i18n from 'i18next';
import { WeekDay, WeekDays, WorkingHoursPerWeek } from 'components/types';
import { locationTrackingSettingsStorage } from '../LocationTracking/LocationTrackingSettingsStorage';
import config from '../../params';
import apiRoutes from 'api/apiRoutes';
import backend, { BackendApi } from '../../api/BackendApi';
import DeviceInfoManager from '../MobileApp/DeviceInfoManager';
import WorkSchedule from 'service/BgGeo/WorkSchedule';

// N.b.: just supported subset of plugin schedule format
const PLUGIN_SCHEDULE_REGEXP = /^(\d|\d{4}-\d\d-\d\d) (\d+):(\d+)-(\d+):(\d+)( (location|geofence))?$/;

const TRACKING_ACCESS_TOKEN_TTL = 600;
const TRACKING_REFRESH_TOKEN_TTL = 2592000;

const addDays = (to: Date, days: number): Date => {
    const added = new Date(to);
    added.setDate(to.getDate() + days);

    return added;
};

const addSeconds = (to: Date, seconds: number): Date => {
    const added = new Date(to);
    added.setSeconds(to.getSeconds() + seconds);

    return added;
};

declare global {
    interface Window {
        bgGeoEmailLog: any;
        bgGeoGetState: any;
        bgGeoGetLog: any;
        bgGeoDestroyLog: any;
        bgGeoChangePace: any;
        bgGeoGetLocations: any;
        bgGeoDestroyLocations: any;
        bgGeoHeartBeatTest: any;
        bgGeoOnScheduleTest: any;
        bgGeoSynchronizeLocations: any;
        bgGeoStopScheduleInPlugin: any;
        bgGeoStopTrackingInPlugin: any;
        bgGeoSetScheduleInPlugin: any;
        bgGeoTweakSchedule: any;
    }
}

const MANUAL_TRACKING_STATE_KEY = 'tracking_state';

type StoredTrackingState = {
    enabled: boolean;
    at: Date;
};

/**
 * todo use BackgroundGeolocation.startBackgroundTask to change schedule to be sure it is updated and started
 */
class BgGeoManager {
    private readyMethodWasLaunched = false;
    private _backend: BackendApi;

    /**
     * todo setup handlers on App start (in constructor or on deviceready event) no matter if user is not present
     * todo destroy locations captured in case when tracking off did not triggered in background and user fetch on App start failed (e.g. poor connection)
     */
    constructor() {
        this._backend = backend;

        if (!weAreInNativeApp()) {
            return;
        }

        this.declareGlobalDebugFunctions();

        /**
         * todo update and start schedule on login
         */
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, async (user: any) => {
            if (user) {
                void DeviceInfoManager.update();
                if (!this.readyMethodWasLaunched) {
                    return;
                }

                const { accessToken, refreshToken } = await this._backend.requestTrackingTokens();

                return BackgroundGeolocation.setConfig({
                    authorization: {
                        strategy: 'JWT',
                        accessToken: accessToken,
                        refreshToken: refreshToken,
                        refreshUrl: config.apiEndpoint + apiRoutes.refresh,
                        refreshPayload: {
                            refreshToken: '{refreshToken}',
                        },
                        expires: 600,
                    },
                });

                // don't try to relaunch schedule here because submit of preferences form would fire this event and because schedule stops only on logout
            }
        });
    }

    private declareGlobalDebugFunctions = () => {
        window.bgGeoGetLog = async () => {
            console.log(await BackgroundGeolocation.logger.getLog());
        };
        window.bgGeoEmailLog = async () => await this.emailLog();
        window.bgGeoDestroyLog = async () => {
            await BackgroundGeolocation.logger.destroyLog();
            console.log('Logs cleared');
        };
        window.bgGeoGetState = async () => {
            console.log(await BackgroundGeolocation.getState());
        };
        window.bgGeoChangePace = async (isMoving: boolean) => {
            await BackgroundGeolocation.changePace(isMoving);
            console.log('Pace changed');
        };
        window.bgGeoGetLocations = async () => {
            const locations = await BackgroundGeolocation.getLocations();
            console.log(locations);

            return locations;
        };
        window.bgGeoDestroyLocations = async () => {
            await BackgroundGeolocation.destroyLocations();
            console.log('Locations destroyed');
        };
        window.bgGeoHeartBeatTest = async () => await this.heartbeatHandler();
        window.bgGeoOnScheduleTest = async () => await this.onScheduleHandler(await BackgroundGeolocation.getState());
        window.bgGeoSynchronizeLocations = async () => await this.synchronizeLocations();
        window.bgGeoStopScheduleInPlugin = async () => await BackgroundGeolocation.stopSchedule();
        window.bgGeoStopTrackingInPlugin = async () => await BackgroundGeolocation.stop();
        window.bgGeoSetScheduleInPlugin = async (schedule: string[]) =>
            await BackgroundGeolocation.setConfig({ schedule });
    };

    emailLog = async () => {
        await BackgroundGeolocation.logger.emailLog(config.techSupportEmail);
        console.log('Logs emailed');
    };

    /**
     * todo move to constructor, restore schedule and extras from plugin config, update setting when user data received
     * N.b.: after moving requestTrackingTokens() request will precede /hey request
     */
    onAppLaunch = async () => {
        if (this.readyMethodWasLaunched) {
            // we use object property because we need to run this methods only once in a session
            return;
        }
        this.readyMethodWasLaunched = true;

        // for ios we need to init handlers before call any function what launches them
        this.initBasicHandlers();
        this.initScheduleHandlers();

        // initial state.enabled = false on iOS when app starts in background after schedule evaluation,
        const initialState = await BackgroundGeolocation.getState();
        this.logState(initialState, 'initialState');

        // Need to call this method on every app launch. Also it synchronizes schedule changes on each app start
        // It turned out thar ready() launches onSchedule events. But this events are fired even before a day start (startSchedule() implements another logic)
        // N.b.: ios fires onSchedule event even before resolve of the ready() method
        const readyState = await BackgroundGeolocation.ready({
            ...(await this.getPluginConfig(this.getRememberedForCurrentPeriod(initialState))), // config strongly depends on the fact that schedule always comes from /hey
            locationAuthorizationRequest: 'WhenInUse', // todo find out what happens if schedule starts after "Always" permission was revoked by user via OS/app settings
            params: initialState.params ?? {},
        });
        this.logState(readyState, 'readyState');

        const state = await this.stopIfNotTriggered(readyState);
        await this.checkTrackingSettingsRelevance(state);

        void this.synchronizeLocations();

        this.log('App launch methods finished');
    };

    checkTrackingSettingsRelevance = async (state: State) => {
        this.logState(state, 'checkTrackingSettingsRelevance');
        await this.checkTrackNowSettingRelevance(state);
        await this.checkAndRelaunchSchedule(state);
    };

    /**
     * plugin did not triggered tracking off due to device was stationary (iOS)
     * also starts schedule because of this._stop()
     */
    private stopIfNotTriggered = async (state: State): Promise<State> => {
        if (!state.enabled) {
            return state;
        }

        const rememberedState = this.getRememberedForCurrentPeriod(state);
        this.log('Stop if not triggered. Remembered state for current period is: ' + rememberedState);

        if (rememberedState !== false && this.isItWorkTimeNow(state)) {
            return state;
        }

        return this._stop(true);
    };

    private checkTrackNowSettingRelevance = async (state: State) => {
        if (state.enabled) {
            // if tracking was switched by schedule from plugin while app was in a deep sleep, setting will not be switched in onSchedule(), so we check its accordance on an app start
            locationTrackingSettingsStorage.switchTrackNowSettingOn();
        } else {
            // if tracking was not triggered by plugin in a deep sleep, it will be triggered in onScheduleHandler() if it is working hours now
            locationTrackingSettingsStorage.switchTrackNowSettingOff();
        }
    };

    /**
     * todo prevent tracking if popup had to be shown
     * @see TurnOnTrackingPopup
     */
    private checkAndRelaunchSchedule = async (state: State): Promise<State> => {
        this.logState(state, 'checkAndRelaunchSchedule');
        // if schedule is not enabled in plugin but setting checkbox is checked - start schedule
        const shouldBeEnabled = locationTrackingSettingsStorage.isGeolocationTrackingAutoSwitchingEnabled();
        if (state.schedulerEnabled === shouldBeEnabled) {
            return state;
        }

        return shouldBeEnabled ? BackgroundGeolocation.startSchedule() : BackgroundGeolocation.stopSchedule();
    };

    private getPluginConfig = async (enabled?: boolean, rememberCurrentState?: boolean): Promise<Config> => {
        const { accessToken, refreshToken } = await this._backend.requestTrackingTokens();
        const deviceInfo = await DeviceInfoManager.getCurrentInfo();

        const pluginConfig: Config = {
            locationAuthorizationRequest: 'Always',
            reset: true,
            debug: config.environment !== 'production',
            logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
            desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
            stopOnTerminate: false,
            startOnBoot: true,
            stationaryRadius: 25,

            distanceFilter: 100,
            // alternative behavior - for android
            // distanceFilter: 0, // you should set here 0 to locationUpdateInterval works
            // locationUpdateInterval: 120000,
            // fastestLocationUpdateInterval: 120000,

            // stationary mode settings
            heartbeatInterval: 120,
            stopTimeout: 10,

            // ios
            preventSuspend: true,
            showsBackgroundLocationIndicator: true,

            // android
            enableHeadless: true,
            notification: {
                title: i18n.t('bg_geo.tracking.notification.title'),
                text: i18n.t('bg_geo.tracking.notification.text'),
                color: '#800080',
            },

            // schedule
            scheduleUseAlarmManager: true,

            // http request
            autoSync: true,
            url: config.apiEndpoint + apiRoutes.tracking.save,
            authorization: {
                strategy: 'JWT',
                accessToken: accessToken,
                refreshToken: refreshToken,
                refreshUrl: config.apiEndpoint + apiRoutes.refresh,
                refreshPayload: {
                    refreshToken: '{refreshToken}',
                },
                expires: TRACKING_ACCESS_TOKEN_TTL,
            },
            batchSync: true,
            maxBatchSize: 100,
            httpRootProperty: 'locations',
            maxDaysToPersist: 3,
            extras: {
                ...deviceInfo,
            },

            // permissions
            backgroundPermissionRationale: {
                title: i18n.t('bg_geo.tracking.permission_rationale.title'),
                message: i18n.t('bg_geo.tracking.permission_rationale.message'),
                positiveAction: i18n.t('bg_geo.tracking.permission_rationale.positiveAction'),
                negativeAction: i18n.t('bg_geo.tracking.permission_rationale.negativeAction'),
            },
        };

        const now = addSeconds(new Date(), 2.2); // add some time for saving config and applying schedule
        const schedule = this.formatUserScheduleToPluginFormat(now, enabled);
        if (schedule) {
            pluginConfig.schedule = schedule;
        }
        if (rememberCurrentState && enabled !== undefined) {
            pluginConfig.params = {
                [MANUAL_TRACKING_STATE_KEY]: {
                    enabled,
                    at: now.toISOString(),
                },
            };
        }

        return pluginConfig;
    };

    getBackgroundGeolocationService = (): BackgroundGeolocation => {
        return BackgroundGeolocation;
    };

    public start = async (): Promise<State | void> => {
        if (weAreInNativeApp()) {
            return this._start();
        }
    };

    /**
     * updates schedule and starts tracking, starts scheduler if enabled
     */
    private _start = async (silent?: boolean): Promise<State> => {
        this.log('Background geolocation starting, silent: ' + silent);

        // if (!this.readyMethodWasLaunched) {
        //     return;
        // }

        const stopScheduleState = await BackgroundGeolocation.stopSchedule();
        this.logState(stopScheduleState, 'starting');

        await BackgroundGeolocation.start();
        this.log('Background geolocation started, silent: ' + silent);
        if (!stopScheduleState.enabled) {
            dispatcher.dispatch(events.BG_GEO_TRACKING_STARTED, silent);
        }

        this.log('Start: switching trackNow setting on, silent: ' + silent);
        locationTrackingSettingsStorage.switchTrackNowSettingOn();

        let state = await BackgroundGeolocation.setConfig(await this.getPluginConfig(true, true));
        if (locationTrackingSettingsStorage.load().autoActivateGeolocationDuringWork) {
            state = await BackgroundGeolocation.startSchedule();
        }
        this.logState(state, 'started');

        return state;
    };

    public stop = async (): Promise<State | void> => {
        if (weAreInNativeApp()) {
            return this._stop();
        }
    };

    /**
     * updates schedule and stops tracking, starts scheduler if enabled
     */
    private _stop = async (silent?: boolean): Promise<State> => {
        this.log('Background geolocation stopping, silent: ' + silent);

        // if (!this.readyMethodWasLaunched) {
        //    return;
        // }

        const stopScheduleState = await BackgroundGeolocation.stopSchedule();
        this.logState(stopScheduleState, 'stopping');

        await BackgroundGeolocation.stop();
        this.log('Background geolocation stopped, silent: ' + silent);
        if (stopScheduleState.enabled) {
            dispatcher.dispatch(events.BG_GEO_TRACKING_STOPPED, silent);
        }

        this.log('Stop: switching trackNow setting off, silent: ' + silent);
        locationTrackingSettingsStorage.switchTrackNowSettingOff();

        let state = await BackgroundGeolocation.setConfig(await this.getPluginConfig(false, true));
        if (locationTrackingSettingsStorage.load().autoActivateGeolocationDuringWork) {
            state = await BackgroundGeolocation.startSchedule();
        }
        this.logState(state, 'stopped');

        return state;
    };

    /**
     * todo refactor
     * @see GeoLocationManager
     */
    getCurrentLocation = async (): Promise<Location> => {
        const location = await BackgroundGeolocation.getCurrentPosition({ persist: false });
        if (!location || !location.coords) {
            return Promise.reject(i18n.t('geolocation.common_error'));
        }

        return this.transformPluginLocationsToMapslyLocations([location])[0];
    };

    private transformPluginLocationsToMapslyLocations(locationsFromPlugin: PluginLocation[]): Location[] {
        return locationsFromPlugin.map((locationFromPlugin: PluginLocation) => {
            return BgGeoManager.transformPluginLocationToMapslyLocation(locationFromPlugin);
        });
    }

    private static transformPluginLocationToMapslyLocation(locationFromPlugin: PluginLocation): Location {
        return new Location(
            locationFromPlugin.uuid,
            locationFromPlugin.coords.longitude,
            locationFromPlugin.coords.latitude,
            locationFromPlugin.timestamp,
            locationFromPlugin.coords.accuracy,
            locationFromPlugin.coords.altitude ?? null,
            locationFromPlugin.coords.altitude_accuracy ?? null,
            locationFromPlugin.coords.heading ?? null,
            locationFromPlugin.coords.speed ?? null,
        );
    }

    private synchronizeLocations = async () => {
        this.log('Locations synchronization run');

        await BackgroundGeolocation.sync().catch((error) => {
            this.log('Sync error: ' + error);
        });
    };

    public log = (message: any, prefix = '[bg geo] ') => {
        let text = message;

        console.log(prefix, new Date().toTimeString(), text);

        if (typeof text === 'object') {
            text = JSON.stringify(text);
        }
        BackgroundGeolocation.logger.info(prefix + text);
    };

    private logState = (state: State, tag?: string) => {
        const {
            enabled,
            schedulerEnabled,
            isMoving,
            autoSync,
            didDeviceReboot,
            didLaunchInBackground,
            params,
            schedule,
        } = state;
        this.log({
            [tag ?? 'state']: {
                enabled,
                schedulerEnabled,
                isMoving,
                autoSync,
                didDeviceReboot,
                didLaunchInBackground,
                params,
                schedule,
            },
        });
    };

    private formatUserScheduleToPluginFormat = (now: Date, enabled?: boolean): string[] | undefined => {
        const schedule = userManager.getCurrentUser()?.routingPreferences?.weekTimes;
        if (!schedule) {
            return undefined;
        }

        const preventScheduleTrigger = enabled === false && WorkSchedule.isItWorkTimeNow(schedule, now);

        // geoplugin incorrectly detects nearest period if schedule contains mixed formats (dates and weekdays)
        return preventScheduleTrigger
            ? this.formatScheduleToPluginDates(schedule, now)
            : this.formatScheduleToPluginWeekdays(schedule);
    };

    /**
     * todo add a minute to our schedule, as geoplugin STOPS at end minute
     */
    private formatScheduleToPluginWeekdays = (schedule: WorkingHoursPerWeek): string[] => {
        // https://transistorsoft.github.io/cordova-background-geolocation-lt/interfaces/config.html#schedule
        const formattedSchedule = [];

        const an = (n: number) => {
            return n < 10 ? '0' + n : n;
        };

        for (const dayName in schedule) {
            const daySchedule = schedule[dayName];
            if (!daySchedule) {
                continue;
            }

            const dayNumber = WeekDay[dayName as keyof typeof WeekDay];
            formattedSchedule.push(
                dayNumber +
                    1 +
                    ' ' +
                    daySchedule.start.hours +
                    ':' +
                    an(daySchedule.start.minutes) +
                    '-' +
                    daySchedule.end.hours +
                    ':' +
                    an(daySchedule.end.minutes),
            );
        }

        return formattedSchedule;
    };

    /**
     * TODO generalize behaviour: set tweaked schedule if tracking triggered manually outside of user's schedule
     * TODO e.g.: user schedule starts an hour later but is enabled by button: plugin schedule MUST include current time
     * @param schedule
     * @param now
     */
    formatScheduleToPluginDates = (schedule: WorkingHoursPerWeek, now: Date): string[] => {
        // https://transistorsoft.github.io/cordova-background-geolocation-lt/interfaces/config.html#schedule
        const formattedSchedule = [];

        const an = (n: number) => {
            return n < 10 ? '0' + n : n;
        };

        const todayNumber = now.getDay();
        const refreshTokenExpiresAt = addSeconds(now, TRACKING_REFRESH_TOKEN_TTL); // don't bother with issue date
        for (const dayName in schedule) {
            const daySchedule = schedule[dayName];
            if (!daySchedule) {
                continue;
            }

            const dayNumber = WeekDay[dayName as keyof typeof WeekDay];
            let comingWeekDay = addDays(now, dayNumber - todayNumber - 7);
            do {
                comingWeekDay = addDays(comingWeekDay, 7);
                if (comingWeekDay <= now) {
                    continue;
                }

                formattedSchedule.push(
                    comingWeekDay.toISOString().substring(0, 10) +
                        ' ' +
                        an(daySchedule.start.hours) +
                        ':' +
                        an(daySchedule.start.minutes) +
                        '-' +
                        an(daySchedule.end.hours) +
                        ':' +
                        an(daySchedule.end.minutes),
                );
            } while (comingWeekDay < refreshTokenExpiresAt);
        }

        return formattedSchedule.sort();
    };

    /**
     * parse subset of plugin format; reverts current day schedule tweak
     * @see BgGeoManager.formatScheduleToPluginWeekdays
     */
    private parseScheduleFromPluginConfig = (state: State): WorkingHoursPerWeek => {
        this.logState(state, 'parseScheduleFromPluginConfig');
        const parsed: WorkingHoursPerWeek = {};
        for (const weekday of WeekDays) {
            parsed[weekday] = null;
        }

        const { schedule } = state;
        if (schedule === undefined) {
            return parsed;
        }

        for (const dayString of schedule) {
            const matches = PLUGIN_SCHEDULE_REGEXP.exec(dayString);
            if (!matches) {
                this.log('parseScheduleFromPluginConfig. Fail parsing schedule string: ' + dayString);

                continue;
            }

            const [, dayNumberOrDate, startHours, startMinutes, endHours, endMinutes] = matches;

            let dayNumber;
            if (dayNumberOrDate.length > 1) {
                // restore tweaked schedule: assume specific date schedule was converted from a weekday one
                const timestamp = Date.parse(dayNumberOrDate);
                if (isNaN(timestamp)) {
                    this.log('parseScheduleFromPluginConfig. Fail parsing schedule date: ' + dayNumberOrDate);

                    continue;
                }

                dayNumber = new Date(timestamp).getDay();
            } else {
                dayNumber = parseInt(dayNumberOrDate) - 1;
            }

            parsed[WeekDay[dayNumber]] = {
                start: {
                    hours: parseInt(startHours, 10),
                    minutes: parseInt(startMinutes, 10),
                },
                end: {
                    hours: parseInt(endHours, 10),
                    minutes: parseInt(endMinutes, 10),
                },
            };
        }
        this.log({ parsed });

        return parsed;
    };

    private hadToRestoreTweakedSchedule(state: State): boolean {
        this.logState(state, 'hadToRestoreTweakedSchedule');

        const { schedule } = state;
        if (schedule === undefined) {
            return false;
        }

        for (const dayString of schedule) {
            const matches = PLUGIN_SCHEDULE_REGEXP.exec(dayString);
            if (!matches) {
                this.log('hadToRestoreTweakedSchedule. Fail parsing schedule string: ' + dayString);

                continue;
            }

            const [, dayNumberOrDate] = matches;
            if (dayNumberOrDate.length > 1) {
                this.log('hadToRestoreTweakedSchedule: true');

                return true;
            }
        }

        this.log('hadToRestoreTweakedSchedule: false');

        return false;
    }

    initScheduleHandlers = () => {
        this.log('Initiating schedule handlers');

        BackgroundGeolocation.onSchedule(async (state: State) => await this.onScheduleHandler(state));
    };

    private onScheduleHandler = async (state: State) => {
        const user = userManager.getCurrentUser();

        this.log(
            'Run onSchedule handler. User ' + user?.id + '. Ready method was launched: ' + this.readyMethodWasLaunched,
        );
        this.logState(state, 'onScheduleState');

        const { enabled } = state;
        const remembered = this.getRememberedForCurrentPeriod(state);

        // prevent triggering off when tracking state changed by button (as it always updates schedule)
        if (remembered !== undefined && remembered !== enabled) {
            if (remembered) {
                await BackgroundGeolocation.start();

                return;
            }

            await BackgroundGeolocation.stop();

            return;
        }

        // switching "track now" button on map and trigger battery info popup
        if (enabled) {
            this.log('Day start by schedule');
            locationTrackingSettingsStorage.switchTrackNowSettingOn();
            dispatcher.dispatch(events.BG_GEO_TRACKING_STARTED);
        } else {
            this.log('Day stop by schedule');
            locationTrackingSettingsStorage.switchTrackNowSettingOff();
            dispatcher.dispatch(events.BG_GEO_TRACKING_STOPPED);
        }

        /**
         * schedule can be already restored by this.start
         * @see this._start
         * @see this._stop
         */
        if (this.hadToRestoreTweakedSchedule(state)) {
            state = await BackgroundGeolocation.setConfig(await this.getPluginConfig());
            this.logState(state, 'onScheduleStateChanged');
        }
    };

    /**
     * todo onLocation → dispatcher.dispatch(events.GEO_POSITION_UPDATED, location)
     * @see MyLocationMarker
     */
    initBasicHandlers = () => {
        this.log('Initiating basic handlers');

        // heartbeat is fired only in stationary mode when there is no new location
        BackgroundGeolocation.onHeartbeat(() => {
            this.log('Heartbeat');

            this.heartbeatHandler();
        });

        BackgroundGeolocation.onEnabledChange((enabled: boolean) => {
            // don't care if tracking is enabled
            void DeviceInfoManager.update(!enabled);
        });

        BackgroundGeolocation.onHttp(({ status: onHttpStatus, success, responseText }) => {
            this.log({ onHttpStatus, success, responseText });
        });
    };

    /**
     * todo probably we want to get and send fresh location here
     */
    private heartbeatHandler = async () => {
        const taskId = await BackgroundGeolocation.startBackgroundTask();
        try {
            await userManager.heartbeat();
            this.log('userManager.heartbeat()');
        } catch (error) {
            try {
                this.log(error);
            } catch (logError) {
                console.error(error, logError);
            }
        }

        void BackgroundGeolocation.stopBackgroundTask(taskId);
    };

    public onLogout = async () => {
        if (!weAreInNativeApp()) {
            return;
        }
        this.log('Stopping schedule');

        await BackgroundGeolocation.stopSchedule();
        await BackgroundGeolocation.setConfig({ schedule: [] }); // prevent triggering irrelevant schedule
        await BackgroundGeolocation.stop();
    };

    /**
     * @return {?boolean} undefined if there is no remembered state for current period
     */
    private getRememberedForCurrentPeriod(state: State): boolean | undefined {
        const rememberedState = this.getRememberedState(state);
        if (rememberedState === undefined) {
            return rememberedState;
        }

        const weekTimes = this.parseScheduleFromPluginConfig(state);
        if (this.isItTheSameScheduleTermNow(weekTimes, rememberedState.at)) {
            return rememberedState.enabled;
        }

        return undefined;
    }

    private getRememberedState = (state: State): StoredTrackingState | undefined => {
        // @ts-ignore // we need to communicate with BackgroundGeolocationHeadlessTask in plugin's native Android service
        const remembered = state.params?.[MANUAL_TRACKING_STATE_KEY];
        if (remembered) {
            this.log('Last remembered state is from plugin ' + JSON.stringify(remembered));

            return {
                enabled: !!remembered.enabled,
                at: new Date(remembered.at),
            };
        }

        // pre-release backward compatibility
        const json = window.localStorage.getItem(MANUAL_TRACKING_STATE_KEY);
        this.log('Last remembered state is from local storage' + json);

        if (!json) {
            return undefined;
        }

        try {
            const parsed = JSON.parse(json);

            return {
                enabled: !!parsed.enabled,
                at: new Date(parsed.at),
            };
        } catch (error: any) {
            console.error(error); // SyntaxError
        }

        return undefined;
    };

    public isItTheSameScheduleTermNow = (weekTimes: WorkingHoursPerWeek, at: Date): boolean => {
        const now = new Date();
        let day = 0;
        let term;
        do {
            term = addDays(at, day);
            const workingHours = weekTimes[WeekDay[term.getDay()]];
            if (!workingHours) {
                continue;
            }

            term.setHours(workingHours.start.hours, workingHours.start.minutes, 0, 0);
            // eslint-disable-next-line no-mixed-operators
            if (term < at !== term < now) {
                return false;
            }

            term.setHours(workingHours.end.hours, workingHours.end.minutes, 0, 0);
            // eslint-disable-next-line no-mixed-operators
            if (term < at !== term < now) {
                return false;
            }
        } while (++day < 7 && term < now);

        return true;
    };

    private isItWorkTimeNow = (state: State) => {
        const now = new Date();

        const weekTimes = this.parseScheduleFromPluginConfig(state);
        const workingHours = weekTimes[WeekDay[now.getDay()]];
        if (!workingHours) {
            this.log('isItWorkTimeNow: no schedule for today');

            return false;
        }

        const start = new Date(now);
        start.setHours(workingHours.start.hours, workingHours.start.minutes, 0, 0);

        const end = new Date(now);
        end.setHours(workingHours.end.hours, workingHours.end.minutes, 0, 0);

        this.log('isItWorkTimeNow: ' + (now >= start && now < end));

        return now >= start && now < end;
    };

    requestPermissions = async () => {
        await BackgroundGeolocation.setConfig({ locationAuthorizationRequest: 'Always' });
        await BackgroundGeolocation.requestPermission();
    };

    getState = async () => await BackgroundGeolocation.getState();

    getProviderState = async () => await BackgroundGeolocation.getProviderState();
}

export default new BgGeoManager();
