import React from 'react';
import './style.css';
import i18n from '../../locales/i18n';
const t = i18n.t.bind(i18n);

export const getMessageAndDetails = (error) => {
    let message = null,
        details = [];
    if (typeof error === 'string') {
        message = error;
    } else if (typeof error === 'object') {
        message = error.message;
        details = error.details;
    }
    return { message, details };
};

class ErrorMessage extends React.PureComponent {
    static label(key) {
        switch (key) {
            case 'entities':
                return t('data_source.error_message.entities');
            case 'views':
                return t('data_source.error_message.views');
            case 'fields':
                return t('data_source.error_message.fields');
            case 'conflictIcon':
                return t('data_source.error_message.conflict_icon');
            default:
                return key;
        }
    }

    render() {
        const { details } = this.props;
        if (!details || typeof details.map !== 'function') {
            return null;
        }
        return (
            <div className="c-ds-error-msg">
                <ul>
                    {details.map((item) => (
                        <ObjectItem name={item['name']} message={item['message']} details={item['details']} />
                    ))}
                </ul>
            </div>
        );
    }
}

export default ErrorMessage;

const ObjectItem = ({ name, message, details }) => (
    <li>
        {message ? name + ': ' + message : name}
        {details && (
            <ul style={{ paddingLeft: 20 }}>
                {Object.keys(details).map((key) => {
                    if (typeof details[key] !== 'object') {
                        return <ObjectItem name={key} message={details[key]} />;
                    }
                    return <ArrayItem name={ErrorMessage.label(key)} items={details[key]} />;
                })}
            </ul>
        )}
    </li>
);

const ArrayItem = ({ name, items }) => (
    <li>
        {name}
        <ul style={{ paddingLeft: 20 }}>
            {items.map((item) => (
                <ObjectItem name={item['name']} message={item['message']} details={item['details']} />
            ))}
        </ul>
    </li>
);
