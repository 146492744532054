import React from 'react';
import PropTypes from 'prop-types';
import entityManagerFactory from '../../service/EntityManager';
import DottedLink from '../DottedLink';
import { withTranslation } from 'react-i18next';
import { ru, enUS, de, es, fr, it, ptBR } from 'date-fns/locale';
import { FormActions, FormAlert, FormBackdrop } from '../PureFormDialog/Form';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import Hint from '../Hint';
import dsManagerFactory from 'service/DsManager';
import { parse, format, formatDistance } from 'date-fns';
import { DATETIME_FORMAT_DATEFNS } from 'utils';

const locales = {
    ru: ru,
    en: enUS,
    de: de,
    es: es,
    fr: fr,
    it: it,
    pt_BR: ptBR,
};

class DataSyncForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            entities: null,
            loading: true,
            message: null,
            checkedEntities: new Set(),
            forceFullReimport: false,
        };
    }

    async componentDidMount() {
        const manager = entityManagerFactory.getManager(this.props.accountId, this.props.dataSourceId);

        const entities = [];

        try {
            const es = await manager.getEntities();

            for (let entity of es) {
                if (!entity.isIncluded) {
                    continue;
                }
                entities.push({
                    id: entity.id,
                    name: entity.name,
                    label: entity.label,
                    lastImportDate: null,
                    nextImportDate: null,
                });
            }

            const { entities: importSchedules } = await manager.getImportSchedules();
            for (let entity of entities) {
                for (let importSchedule of importSchedules) {
                    if (importSchedule.entityId === entity.id) {
                        entity.lastImportDate = importSchedule.lastImportDate;
                        entity.nextImportDate = importSchedule.nextImportDate;
                        break;
                    }
                }
            }
        } catch (e) {
            this.setState({
                loading: false,
                message: {
                    type: 'danger',
                    text: this.props.t('data_sync_form.preload_error'),
                },
            });
        }

        this.setState({
            loading: false,
            entities,
        });
    }

    handleSubmit = () => {
        const entities = [...this.state.checkedEntities];

        if (entities.length === 0) {
            this.setState({
                message: {
                    type: 'danger',
                    text: this.props.t('data_sync_form.no_objects_have_been_selected'),
                },
            });
            return;
        }

        this.setState({
            loading: true,
            message: null,
        });

        dsManagerFactory
            .getManager(this.props.accountId)
            .update(this.props.dataSourceId, entities, this.state.forceFullReimport)
            .then(() => {
                this.setState({
                    loading: false,
                });

                this.props.onSave && this.props.onSave();
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: 'danger',
                        text: this.props.t('data_source.update.error', { error: error.message }),
                    },
                });

                this.props.onError && this.props.onError(error.message);
            });
    };

    handleCheck = (event) => {
        const checked = event.target.checked;
        const name = event.target.name;

        const checkedEntities = new Set(this.state.checkedEntities);
        if (checked) {
            checkedEntities.add(name);
        } else {
            checkedEntities.delete(name);
        }

        this.setState({
            checkedEntities,
        });
    };

    handleCheckAll = (checked) => {
        const checkedEntities = new Set();
        if (checked) {
            for (let entity of this.state.entities) {
                checkedEntities.add(entity.name);
            }
        }

        this.setState({
            checkedEntities,
        });
    };

    handleCheckFullReimport = (event) => {
        const forceFullReimport = event.target.checked;
        this.setState({
            forceFullReimport,
        });
    };

    getUTCDate(datetimeString) {
        return parse(datetimeString, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date());
    }

    formatDateInUserZone(datetimeString) {
        const d = this.getUTCDate(datetimeString);

        return format(d, DATETIME_FORMAT_DATEFNS);
    }

    getDateDiffInSeconds(datetimeString) {
        const date = this.getUTCDate(datetimeString);
        return Math.round((date.getTime() - Date.now()) / 1000);
    }

    formatDateDistance(datetimeString) {
        const { i18n } = this.props;

        const date = this.getUTCDate(datetimeString);
        return formatDistance(date.getTime(), Date.now(), { locale: locales[i18n.language] });
    }

    handleCloseMessage = () => {
        this.setState({
            message: null,
        });
    };

    render() {
        const { t, classes } = this.props;
        const { entities, loading, message, checkedEntities, forceFullReimport } = this.state;

        return (
            <div className="c-data-sync-form">
                {message !== null && (
                    <FormAlert type={message.type} onClose={this.handleCloseMessage}>
                        {message.text}
                    </FormAlert>
                )}

                <FormBackdrop loading={loading}>
                    <div className={classes.checkTools}>
                        <DottedLink onClick={() => this.handleCheckAll(true)} data-testid="data_sync_form.check_all">
                            {t('data_sync_form.check_all')}
                        </DottedLink>
                        <DottedLink onClick={() => this.handleCheckAll(false)} data-testid="data_sync_form.uncheck_all">
                            {t('data_sync_form.uncheck_all')}
                        </DottedLink>
                    </div>
                    <div className={classes.tableContainer}>
                        {entities !== null && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{t('data_sync_form.table_columns.objects')}</TableCell>
                                        <TableCell align="center">
                                            {t('data_sync_form.table_columns.last_refreshed')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {t('data_sync_form.table_columns.next_autorefresh')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entities.map((entity) => {
                                        const label = (
                                            <span>
                                                {entity.label}{' '}
                                                <span className={classes.entityApiName}> {entity.name}</span>
                                            </span>
                                        );
                                        return (
                                            <TableRow key={entity.name}>
                                                <TableCell align="left">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                name={entity.name}
                                                                checked={checkedEntities.has(entity.name)}
                                                                onChange={this.handleCheck}
                                                            />
                                                        }
                                                        label={label}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {!!entity.lastImportDate ? (
                                                        <Tooltip
                                                            title={this.formatDateInUserZone(entity.lastImportDate)}
                                                        >
                                                            <span>
                                                                {t('data_sync_form.table_columns.last_refreshed.ago', {
                                                                    value: this.formatDateDistance(
                                                                        entity.lastImportDate,
                                                                    ),
                                                                })}
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <span>
                                                            {t('data_sync_form.table_columns.last_refreshed.never')}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {!!entity.nextImportDate ? (
                                                        <Tooltip
                                                            title={this.formatDateInUserZone(entity.nextImportDate)}
                                                        >
                                                            <span>
                                                                {t('data_sync_form.table_columns.next_autorefresh.in', {
                                                                    value: this.formatDateDistance(
                                                                        entity.nextImportDate,
                                                                    ),
                                                                })}
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip
                                                            title={t(
                                                                'data_sync_form.table_columns.next_autorefresh.off_hint',
                                                            )}
                                                        >
                                                            <span>
                                                                {t('data_sync_form.table_columns.next_autorefresh.off')}
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                checked={forceFullReimport}
                                onChange={this.handleCheckFullReimport}
                            />
                        }
                        label={
                            <span>
                                {t('data_sync_form.force_full_reimport')}{' '}
                                <Hint>{t('data_sync_form.force_full_reimport_hint')}</Hint>
                            </span>
                        }
                    />
                </FormBackdrop>
                <FormActions>
                    <Button color="primary" onClick={this.handleSubmit} disabled={loading}>
                        {t('data_sync_form.data_sync_form_dialog.save_button_title')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

DataSyncForm.propTypes = {
    accountId: PropTypes.number.isRequired,
    dataSourceId: PropTypes.number.isRequired,
    onSave: PropTypes.func,
};

const styles = {
    tableContainer: {
        margin: '16px 0 16px -8px',
        minHeight: 100,
    },
    entityApiName: {
        color: '#aaa',
    },
    checkTools: {
        '& > span': {
            marginRight: 16,
            fontSize: 'smaller',
        },
    },
};

export default withTranslation('translations', { withRef: true })(withStyles(styles)(DataSyncForm));
