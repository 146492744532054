import { TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatRangeWithYearFromIso } from '../../utils';
import ConfirmationIconButton from '../CustomButton/ConfirmationIconButton';
import DottedLink from '../DottedLink';
import { WorkingHoursException } from '../types';
import { Typography } from '../UI';
import {
    getWorkingHoursAsString,
    getWorkingHoursBreakAsString,
} from '../UsersTravelingPreferencesDialog/TravelingPreferencesHelper';

interface Props extends WithTranslation {
    workingHoursExceptions: WorkingHoursException[];
    isPast: boolean;
    onEdit: (workingHoursException: WorkingHoursException) => void;
    onDelete: (workingHoursException: WorkingHoursException) => void;
}

interface State {
    page: number;
    rowsPerPage: number;
}

const DEFAULT_ROWS_PER_PAGE = 10;

class CalendarsTable extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        };
    }

    private getAllowedOvertime = (workingHoursException: WorkingHoursException): string => {
        return workingHoursException.allowedOvertime
            ? String(workingHoursException.allowedOvertime)
            : this.props.t('not_set');
    };

    private getWorkingHours = (workingHoursException: WorkingHoursException): string => {
        const value = getWorkingHoursAsString(workingHoursException.workingHours);

        return value === '' ? this.props.t('not_set') : value;
    };

    private handlePageChange = (_: unknown, newPage: number): void => {
        this.setState({ page: newPage });
    };

    private handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({ rowsPerPage: parseInt(value, 10), page: 0 });
    };

    render() {
        const { t, workingHoursExceptions, isPast, onEdit, onDelete } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <>
                <Table className="working-hours-exceptions-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('working_hour_exceptions.modal.table.period')}</TableCell>
                            <TableCell>{t('working_hour_exceptions.modal.table.working_hours')}</TableCell>
                            <TableCell>{t('working_hour_exceptions.modal.table.allowed_overtime')}</TableCell>
                            <TableCell>{t('working_hour_exceptions.modal.table.break')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workingHoursExceptions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((workingHoursException: WorkingHoursException) => {
                                const periodString = formatRangeWithYearFromIso(
                                    workingHoursException.periodFrom,
                                    workingHoursException.periodTo,
                                    t,
                                );

                                return (
                                    <TableRow>
                                        <TableCell>
                                            {isPast ? (
                                                periodString
                                            ) : (
                                                <DottedLink
                                                    onClick={() => {
                                                        onEdit(workingHoursException);
                                                    }}
                                                >
                                                    {periodString}
                                                </DottedLink>
                                            )}
                                        </TableCell>
                                        <TableCell>{this.getWorkingHours(workingHoursException)}</TableCell>
                                        <TableCell>{this.getAllowedOvertime(workingHoursException)}</TableCell>
                                        <TableCell>
                                            {workingHoursException.breakType
                                                ? getWorkingHoursBreakAsString({
                                                      breakType: workingHoursException.breakType,
                                                      breakDuration: workingHoursException.breakDuration,
                                                      breakEarliestTime: workingHoursException.breakEarliestTime,
                                                      breakLatestTime: workingHoursException.breakLatestTime,
                                                  })
                                                : ''}
                                        </TableCell>
                                        <TableCell width="60px">
                                            <ConfirmationIconButton
                                                iconName="delete"
                                                color="default"
                                                component="span"
                                                onClick={() => {
                                                    onDelete(workingHoursException);
                                                }}
                                                textYes={t('confirm')}
                                                textNo={t('cancel')}
                                                text={t('working_hour_exceptions.modal.table.remove_confirm')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        {workingHoursExceptions.length === 0 && (
                            <TableRow>
                                <TableCell align="center" colSpan={5}>
                                    <Typography variant="bodyM400" color="textSecondary">
                                        {t('working_hour_exceptions.modal.table.no_data')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {workingHoursExceptions.length > DEFAULT_ROWS_PER_PAGE && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={workingHoursExceptions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handlePageChange}
                        onRowsPerPageChange={this.handleRowsPerPageChange}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(CalendarsTable);
