import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldPolymorphicLookupData, PicklistValue } from 'components/types';
import PicklistField, { ChangeSelectTargetEvent } from './PicklistField';
import AutoCompleteField, { AutoCompleteFieldProps } from './AutoCompleteField';
import { FieldFactoryValueObject } from './FieldFactory';
import './style.css';

export interface LookupPolymorphicFieldProps extends WithTranslation {
    name: string;
    value: string | FieldFactoryValueObject | null;
    disabled: boolean;
    autoFocus: boolean;
    lookupData: FieldPolymorphicLookupData;
    onChangeEntity: (entity: string | null) => void;
    onShowSearch: AutoCompleteFieldProps['onShowSearch'];
    onEntityClick: (params: { entity: string }) => void;
    label: string;
    selectedEntity?: string;
}

interface States {
    entity: string | null;
}

class LookupPolymorphicField extends React.PureComponent<LookupPolymorphicFieldProps, States> {
    constructor(props: LookupPolymorphicFieldProps) {
        super(props);

        this.state = {
            entity: props.selectedEntity ?? null,
        };
    }

    handleRecordClick = () => {
        this.props.onEntityClick?.({ entity: this.state.entity! });
    };

    private handleShowSearch = () => {
        if (!this.state.entity) {
            return;
        }

        this.props.onShowSearch();
    };

    render() {
        const { value, name, disabled, autoFocus, lookupData, label } = this.props;

        const picklist = this.preparePicklist(lookupData);

        return (
            <React.Fragment>
                <div className="polymorphic-wrap">
                    <PicklistField
                        value={this.state.entity}
                        name={name}
                        label={label}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        onChangeSelect={this.onChangeEntity}
                        picklist={picklist}
                        isSearchOfLabelInPicklist={false}
                    />
                    <AutoCompleteField
                        value={value}
                        name={this.state.entity ?? ''}
                        disabled={this.state.entity === null}
                        label={' '}
                        onShowSearch={this.handleShowSearch}
                        onRecordClick={this.handleRecordClick}
                    />
                </div>
            </React.Fragment>
        );
    }

    private onChangeEntity = (event: ChangeSelectTargetEvent) => {
        const entity = event.target.value as string | null;
        this.setState({ entity }, () => {
            this.props.onChangeEntity(entity);
        });
    };

    private preparePicklist = (lookupData: FieldPolymorphicLookupData): PicklistValue[] => {
        const result: PicklistValue[] = [];
        for (let entityName of lookupData.entities) {
            result.push({
                value: entityName,
                label: entityName,
            });
        }
        return result;
    };
}

export default withTranslation()(LookupPolymorphicField);
