import React from 'react';
import { withStyles } from '@material-ui/core';

class Curtain extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.curtain} />
                <div className={classes.loading}>{this.props.children}</div>
            </div>
        );
    }
}

const style = {
    root: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 9001,
    },
    curtain: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        background: '#fff',
        opacity: 0.8,
    },
    loading: {
        position: 'absolute',
        margin: '0 auto',
        width: '100%',
        left: 0,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        zIndex: 101,
        gap: 10,
    },
};

export default withStyles(style)(Curtain);
