import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import AdapterSettingsGroup from './AdapterSettingsGroup';
import { AdapterResponse } from '../types';
import dsManagerFactory from '../../service/DsManager';
import { userManager } from '../../service/UserManager';
import { DataSource } from '../../interfaces';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';
import Box from '@material-ui/core/Box';

interface Props extends WithTranslation {
    ref?: React.RefObject<any>;
    dataSource: DataSource.DataSource;
    onSuccess: () => void;
    onError: (message: string, detail?: string) => void;
    onLoading: () => void;
}

interface State {
    userParams?: AdapterResponse;
}

class UserParams extends React.PureComponent<Props, State> {
    private readonly credentialsForm: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.credentialsForm = React.createRef();
    }

    async componentDidMount() {
        const userParams = await dsManagerFactory
            .getManager(userManager.getCurrentAccount().id)
            .userParams(this.props.dataSource.id);
        if (!userParams) {
            this.props.onSuccess && this.props.onSuccess();
            return;
        }

        this.setState({ userParams: userParams });
    }

    submit() {
        const { t, onError, onSuccess } = this.props;

        let credentials;
        try {
            if (this.credentialsForm.current) {
                credentials = this.credentialsForm.current.submit();
            }
        } catch (e) {
            onError && onError(t('data_source_form.user_params_form.error'));
            return;
        }

        return dsManagerFactory
            .getManager(userManager.getCurrentAccount().id)
            .saveUserCredentials(this.props.dataSource.id, credentials)
            .then(() => {
                onSuccess && onSuccess();
                return Promise.resolve();
            })
            .catch((error: any) => {
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                throw error;
            });
    }

    render() {
        const { t } = this.props;
        const { userParams } = this.state;

        if (!userParams) {
            return null;
        }

        return (
            <Box width="552px" maxWidth="100%">
                {userParams && userParams.credentials?.length > 0 && (
                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ margin: 0 }}>{t('data_source_form.connection_form.connection_parameters')}</h3>
                        <AdapterSettingsGroup
                            key={this.props.dataSource.adapterId}
                            settings={userParams.credentials}
                            values={this.props.dataSource.credentials as any}
                            ref={this.credentialsForm}
                        />
                    </div>
                )}
            </Box>
        );
    }
}

export default withTranslation('translations', { withRef: true })(UserParams);
