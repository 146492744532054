import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import BaseIconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import { linkColName } from './SearchDataTable';
import { parseWebLinkToText, parseWebLinkToUrl } from '../utils/webLinkParser';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const IconButton = withStyles({
    root: {
        padding: 4,
    },
})(BaseIconButton);

class SearchDataTableContextMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleActionClick = (event) => {
        const { onViewGeocodingLog } = this.props;
        const action = event.currentTarget.getAttribute('data-action');
        // eslint-disable-next-line default-case
        switch (action) {
            case 'view-geocoding-log':
                onViewGeocodingLog && onViewGeocodingLog(this.props.record.id);
                break;
        }
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const { t } = this.props;
        let enableOpenInSource =
            this.props.record[linkColName] !== undefined && this.props.record[linkColName] !== null;
        return (
            <React.Fragment>
                <IconButton
                    onClick={this.handleOpen}
                    data-testid="entity_data_table.search_data_table_context_menu.open"
                >
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu anchorEl={this.state.anchorEl} open={this.state.anchorEl !== null} onClose={this.handleClose}>
                    <MenuItem
                        onClick={this.handleActionClick}
                        data-action="view-geocoding-log"
                        disabled={!this.props.showViewGeocodingLog}
                        data-testid="entity_data_table.search_data_table_context_menu.view_geocoding_log"
                    >
                        {t('entity_data_table.search_data_table_context_menu.view_geocoding_log')}
                    </MenuItem>
                    {enableOpenInSource && (
                        <MenuItem data-click-away-ignore={true}>
                            <a
                                className="open-in-source"
                                href={parseWebLinkToUrl(this.props.record[linkColName])}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {parseWebLinkToText(this.props.record[linkColName])}
                            </a>
                        </MenuItem>
                    )}
                </Menu>
            </React.Fragment>
        );
    }
}

SearchDataTableContextMenu.propTypes = {
    record: PropTypes.object.isRequired,
    showViewGeocodingLog: PropTypes.bool,
    onViewGeocodingLog: PropTypes.func,
};

SearchDataTableContextMenu.defaultProps = {
    showViewGeocodingLog: false,
};

export default withTranslation()(SearchDataTableContextMenu);
