import { TableCell } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { FC } from 'react';

import { formatDateToDefault } from '../../utils';

import BetweenDate from './BetweenDate';

interface DateBetweenFilterProps {
    filter: any;
    onFilter: (arg: { value: { dateFrom: string | null; dateTo: string | null } }) => void;
    classes?: ClassNameMap<'cell'>;
}

const DateBetweenFilter: FC<DateBetweenFilterProps> = ({ filter, onFilter, classes }) => (
    <TableCell className={classes?.cell}>
        <div className="date-from-container">
            <BetweenDate
                onChange={(value: any) =>
                    onFilter({
                        value: {
                            dateFrom: value.dateFrom ? formatDateToDefault(value.dateFrom) : null,
                            dateTo: value.dateTo ? formatDateToDefault(value.dateTo) : null,
                        },
                    })
                }
                value={filter.value}
            />
        </div>
    </TableCell>
);

export default DateBetweenFilter;
