import React from 'react';
import './style.css';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import BillingContainer from './BillingContainer';

class Billing extends React.Component {
    render() {
        return (
            <div>
                <h1>{this.props.t('billing.h1')}</h1>
                <BillingContainer
                    user={this.props.user}
                    accountId={this.props.accountId}
                    subscribeAutoStart={this.props.subscribeAutoStart}
                    updateAutoStart={this.props.updateAutoStart}
                />
            </div>
        );
    }
}

Billing.propTypes = {
    accountId: PropTypes.number,
    user: PropTypes.object.isRequired,
    subscribeAutoStart: PropTypes.bool,
    updateAutoStart: PropTypes.bool,
};

Billing.defaultProps = {
    subscribeAutoStart: false,
    updateAutoStart: false,
};

export default withTranslation()(Billing);
