import React from 'react';
import PropTypes from 'prop-types';
import SSUOauth from './SSUOauth';
import { adapterManager } from '../../service/AdapterManager';
import AdapterSettingsGroup from '../DataSourceForm/AdapterSettingsGroup';
import dsManagerFactory from '../../service/DsManager';
import { withTranslation } from 'react-i18next';
import aesEncryptor from 'service/AesEncryptor';
import { ssoAuthProviderStorage } from 'service/Login';

class SSUConnection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.credentialsForm = React.createRef();

        this.state = {
            adapter: null,
            credentials: null,
        };

        // получить параметры адаптера
        adapterManager.forId(props.manager.getProvider()).then((adapter) => {
            this.setState({
                adapter,
            });
        });
    }

    quiet() {
        if (this.credentialsForm.current) {
            this.credentialsForm.current.calm();
        }
    }

    handleSaveDataSource = (grantToken) => {
        const { manager, onSuccess, onError, onProcessing, t } = this.props;

        onProcessing && onProcessing(t('signup.connection.trying_to_connect'));

        // передаем токен на бекенд, там создается датасоурс, подключенный к источнику, получаем его ид
        // т.е. то же самое что происходит при сохранении ДС обычным образом, через форму.
        const dsManager = dsManagerFactory.getManager(manager.getAccountId());

        const dataSource = dsManager.getDefaultDataSource();
        //dataSource.name = this.provider;
        dataSource.adapterId = this.state.adapter.id;
        dataSource.credentials = this.credentialsForm.current.submit();

        if (grantToken) {
            dataSource.credentials['grant_token'] = grantToken;
        }

        return dsManager
            .save(dataSource, true)
            .then((dataSource) => {
                manager.setDataSourceId(dataSource.id);
                return dataSource;
            })
            .then(() => {
                onSuccess && onSuccess();
            })
            .catch((error) => {
                onError && onError(t('signup.connection.unable_to_connect', { reason: error.message }));
                if (error instanceof Error) {
                    throw error;
                }
            });
    };

    commit() {
        try {
            const credentials = this.credentialsForm.current.submit();
            this.setState({ credentials: credentials });
        } catch (e) {
            this.props.onError && this.props.onError(this.props.t('data_source_form.connection_form.error'));
            return;
        }

        if (this.componentRef.current) {
            this.componentRef.current.commit();
            return;
        }

        this.handleSaveDataSource();
    }

    render() {
        const { t } = this.props;
        const { adapter } = this.state;
        if (!adapter) {
            return t('loading');
        }
        const { onProcessing, onError } = this.props;
        const oauthUrl = this.getOauthUrl();
        const credentials = ssoAuthProviderStorage.getCredentials(this.state.adapter.id) ?? {};

        return (
            <div>
                {oauthUrl && (
                    <SSUOauth
                        oauthUrl={oauthUrl}
                        //autorun
                        onProcessing={onProcessing}
                        onSuccess={this.handleSaveDataSource}
                        onError={onError}
                        ref={this.componentRef}
                    />
                )}
                <AdapterSettingsGroup settings={adapter.credentials} values={credentials} ref={this.credentialsForm} />
            </div>
        );
    }

    getOauthUrl() {
        if (this.state.adapter && this.state.adapter.interface && this.state.adapter.interface.oauth) {
            let url = this.state.adapter.interface.oauth;
            if (this.state.credentials) {
                url += '?' + encodeURIComponent(aesEncryptor.encrypt(JSON.stringify(this.state.credentials)));
            }
            return url;
        }
        return null;
    }
}

SSUConnection.propTypes = {
    manager: PropTypes.object.isRequired,
    onProcessing: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(SSUConnection);
