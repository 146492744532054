import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';

export default class File extends AbstractField {
    constructor() {
        super(FIELD_TYPES.FILE);

        this.minCount = 0;
        this.maxCount = 10;
        this.allowedTypes = [];
        this.allowedMethods = [];
        this.imageResize = false;
        this.targetImageMaxWidth = null;
        this.targetImageMaxHeight = null;
        this.keepActionButtonsEnabled = false;

        this.isTranscriptEnabled = false;
        this.isSummaryEnabled = false;
        this.prompt = null;
        this.language = null;
    }

    setMinCount(minCount) {
        this.minCount = minCount;
        this.isRequired = minCount > 0;

        return this;
    }

    getMinCount() {
        return this.minCount;
    }

    setMaxCount(maxCount) {
        this.maxCount = maxCount;

        return this;
    }

    getMaxCount() {
        return this.maxCount;
    }

    setAllowedTypes(allowedTypes) {
        this.allowedTypes = allowedTypes;

        return this;
    }

    addAllowedType(allowedType) {
        this.allowedTypes.push(allowedType);
    }

    removeAllowedType(allowedType) {
        this.allowedTypes = this.allowedTypes.filter((type) => type !== allowedType);
    }

    getAllowedTypes() {
        return this.allowedTypes;
    }

    hasType(allowedType) {
        return this.allowedTypes.includes(allowedType);
    }

    setAllowedMethods(allowedMethods) {
        this.allowedMethods = allowedMethods;

        return this;
    }

    addAllowedMethod(allowedMethod) {
        this.allowedMethods.push(allowedMethod);
    }

    removeAllowedMethod(allowedMethod) {
        this.allowedMethods = this.allowedMethods.filter((type) => type !== allowedMethod);
    }

    getAllowedMethods() {
        return this.allowedMethods;
    }

    hasMethod(allowedMethod) {
        return this.allowedMethods.includes(allowedMethod);
    }

    setImageResize(resize) {
        this.imageResize = resize;

        return this;
    }

    getImageResize() {
        return this.imageResize;
    }

    setImageTargetMaxWidth(targetMaxWidth) {
        this.targetImageMaxWidth = targetMaxWidth;

        return this;
    }

    getImageTargetMaxWidth() {
        return this.targetImageMaxWidth;
    }

    setImageTargetMaxHeight(targetMaxHeight) {
        this.targetImageMaxHeight = targetMaxHeight;

        return this;
    }

    getImageTargetMaxHeight() {
        return this.targetImageMaxHeight;
    }

    getValue() {
        return Array.isArray(this.value) ? this.value : [];
    }

    getOldValue() {
        return Array.isArray(this.oldValue) ? this.oldValue : [];
    }

    setKeepActionButtonsEnabled(keepActionButtonsEnabled) {
        this.keepActionButtonsEnabled = keepActionButtonsEnabled;

        return this;
    }

    getKeepActionButtonsEnabled() {
        return this.keepActionButtonsEnabled;
    }

    isEmpty() {
        return this.getValue().length === 0;
    }

    setPrompt(prompt) {
        this.prompt = prompt;
        return this;
    }

    getPrompt() {
        return this.prompt;
    }

    setIsTranscriptEnabled(isTranscriptEnabled) {
        this.isTranscriptEnabled = isTranscriptEnabled;
        return this;
    }

    getIsTranscriptEnabled() {
        return this.isTranscriptEnabled;
    }

    setIsSummaryEnabled(isSummaryEnabled) {
        this.isSummaryEnabled = isSummaryEnabled;
        return this;
    }

    getIsSummaryEnabled() {
        return this.isSummaryEnabled;
    }

    setLanguage(language) {
        this.language = language;
        return this;
    }

    getLanguage() {
        return this.language;
    }

    validateValue() {
        return (
            (this.getMinCount() !== null ? this.getValue().length >= this.getMinCount() : true) &&
            (this.getMaxCount() !== null ? this.getValue().length <= this.getMaxCount() : true)
        );
    }
}
