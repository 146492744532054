import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { withTranslation } from 'react-i18next';
import { renderTerritories } from '../MapViewForm/Popup/FieldValueViewFactory';
import WebLink, { ICON_POSITION_RIGHT } from '../WebLink/WebLink';
import { FIELD_DISTANCE, FIELD_TERRITORIES } from '../Map/constants';
import { formatWithCommas, isPicklistTypeNumber, utcToUserTimezone } from '../../utils';
import { FieldLookupType } from 'components/types';
import { FieldType } from '../types';
import FileCollection from '../FileInput/FileCollection';
import { clipboardManager } from '../../service/ClipboardManager';
import { userManager } from '../../service/UserManager';

class ValueView {
    constructor(view, value = null) {
        this.value = value;
        this.view = view;
    }
}

class EditValue extends React.PureComponent {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.defaultTitleCopyMsg = t('map_page.view_record.value_copy');
        this.state = {
            titleCopyMsg: this.defaultTitleCopyMsg,
            timerCopyMsg: null,
            openCopyMsg: false,
        };
    }

    buildLookupPolymorphicField = (property, value) => {
        const { t } = this.props;
        const { typeValue } = property;
        if (!value) {
            return new ValueView(<i>{t('map.entity_layer_points.empty')}</i>);
        }
        if (!typeValue) {
            return new ValueView(value, value);
        }

        const label = typeValue.slice(0, 2).toUpperCase();

        return new ValueView(
            (
                <React.Fragment>
                    {value}&nbsp;
                    <Tooltip title={typeValue} key={property.id}>
                        <span>({label})</span>
                    </Tooltip>
                </React.Fragment>
            ),
            value,
        );
    };

    buildUniversalLookupField = (property, value) => {
        // we're displaying related_to_TYPE field which has raw json in it
        if (property.lookupData && property.lookupData.entity_type_field === property.apiName) {
            try {
                const { data_source_api_name, entity_api_name } = JSON.parse(value);
                const nextValue = `${data_source_api_name}.${entity_api_name}`;

                return new ValueView(nextValue, nextValue);
            } catch (error) {
                //
            }
        }

        return new ValueView(value, value);
    };

    buildValueView = () => {
        const { classes } = this.props;
        const classNames = [classes.label, classes.withEditButton, classes.editable];
        const data = this.buildValue();
        const value = data.value;

        if (value === null) {
            return data.view;
        }

        return (
            <span
                className={clsx(classNames)}
                onClick={() => {
                    this.handleCopyClick(data);
                }}
            >
                <Tooltip title={this.state.titleCopyMsg} open={this.state.openCopyMsg}>
                    <i
                        className="fas fa-copy"
                        onMouseEnter={this.showCopyTooltip}
                        onMouseLeave={this.hideCopyTooltip}
                    />
                </Tooltip>
                <span className={classes.fieldName}>{data.view}</span>
            </span>
        );
    };

    buildValue = () => {
        const { property, updates, t } = this.props;
        const field = property.apiName;
        let value = this.props.value;

        if (updates[field]) {
            value = updates[field].value;
            if (typeof updates[field].textValue === 'string') {
                value = updates[field].textValue;
            } else if (Array.isArray(updates[field])) {
                value = updates[field];
            }
        }

        if (property.lookupData && property.lookupData.type === FieldLookupType.POLYMORPHIC) {
            return this.buildLookupPolymorphicField(property, value);
        }

        if (property.lookupData && property.lookupData.type === FieldLookupType.UNIVERSAL) {
            return this.buildUniversalLookupField(property, value);
        }

        if (Array.isArray(value) && 0 === value.length) {
            return new ValueView(<i>{t('map_page.view_record.field.nothing_selected')}</i>);
        }

        if (value === '' || value === null) {
            return new ValueView(<i>{t('map_page.view_record.field.empty')}</i>);
        }

        if (field === FIELD_TERRITORIES) {
            const territoriesNames = renderTerritories(value);
            const territoriesValue = territoriesNames ? territoriesNames.join(', ') : null;
            return new ValueView(territoriesValue, territoriesValue);
        }

        if (field === FIELD_DISTANCE) {
            if (value === undefined) {
                return new ValueView(null);
            }
            const valueResult = formatWithCommas(parseFloat(value).toFixed(2));
            return new ValueView(valueResult, valueResult);
        }

        if (property.type === 'text[]' && Array.isArray(value)) {
            const picklist = property.picklist || [];
            const valueResult = this.buildPicklistValue(value, picklist);
            return new ValueView(valueResult, valueResult);
        }

        if (property.isLink) {
            return new ValueView(<WebLink webLink={value} iconPosition={ICON_POSITION_RIGHT} />, value);
        }

        if (property.picklist) {
            const item = property.picklist.find((item) => item.value === value);
            const valueResult = item === undefined ? value : item.label;
            return new ValueView(valueResult, valueResult);
        }

        if (property.type === 'boolean') {
            return new ValueView(value ? t('boolean.true') : t('boolean.false'));
        }

        if (property.type === 'datetime') {
            const valueResult = utcToUserTimezone(value, userManager.getCurrentUser());
            return new ValueView(valueResult, valueResult);
        }

        if (property.type === FieldType.FILES) {
            return new ValueView(
                <FileCollection accountId={this.props.accountId} files={value || []} size="small" readonly />,
            );
        }

        return new ValueView(value, value);
    };

    buildPicklistValue = (value, picklist) => {
        if (!value) {
            return null;
        }

        const typePicklistIsNumber = isPicklistTypeNumber(picklist);

        return value
            .map((v) => {
                v = typePicklistIsNumber ? parseInt(v, 10) : v;
                const item = picklist ? picklist.find((item) => item.value === v) : null;
                if (item) {
                    return item.label;
                } else {
                    return v;
                }
            })
            .join(', ');
    };

    showCopyTooltip = () => {
        if (this.state.openCopyMsg) {
            return;
        }

        this.setState({
            titleCopyMsg: this.defaultTitleCopyMsg,
            timerCopyMsg: null,
            openCopyMsg: true,
        });
    };

    hideCopyTooltip = () => {
        if (!this.state.openCopyMsg || this.state.timerCopyMsg) {
            return;
        }

        const timer = setTimeout(() => {
            this.setState({
                openCopyMsg: false,
                timerCopyMsg: null,
            });
        }, 100);

        this.setState({
            timerCopyMsg: timer,
        });
    };

    showCopyMsg = (msg, timeout = 2000) => {
        if (this.state.timerCopyMsg) {
            clearTimeout(this.state.timerCopyMsg);
        }

        const timer = setTimeout(() => {
            this.setState({
                timerCopyMsg: null,
                openCopyMsg: false,
            });
        }, timeout);

        this.setState({
            titleCopyMsg: msg,
            timerCopyMsg: timer,
            openCopyMsg: true,
        });
    };

    handleEditClick = (event) => {
        if (this.props.property.isReadOnly) {
            return;
        }
        this.props.onEdit(event, this.props.property);
    };

    handleCopyClick = (data) => {
        const { t } = this.props;
        let value = data.value;

        if (value === null) {
            return;
        }

        if (typeof value !== 'string' && typeof value.toString === 'function') {
            value = value.toString();
        }

        clipboardManager.writeText(value).then(
            () => {
                this.showCopyMsg(t('map_page.view_record.value_copied'));
            },
            () => {
                this.showCopyMsg(t('map_page.view_record.value_copied_error'));
            },
        );
    };

    handleDiscardClick = (event) => {
        event.stopPropagation();
        this.props.onDiscard(event, this.props.property);
    };

    renderLabel = () => {
        const { classes, property, updates, withEditButton, t } = this.props;
        const hasUpdate = updates[property.apiName] !== undefined;
        const labelText = property.title || property.label;
        const { isReadOnly } = property;

        if (isReadOnly) {
            return labelText;
        }

        const classNames = withEditButton
            ? [classes.label, classes.withEditButton]
            : [classes.label, classes.withoutEditButton];

        if (property.isLink) {
            return (
                <span className={clsx(classNames)}>
                    <i className="fas fa-edit" onClick={this.handleEditClick} />
                    {labelText}
                </span>
            );
        }

        return (
            <span className={clsx(classNames)}>
                {withEditButton && (
                    <Tooltip title={t('map_page.view_record.value_edit')}>
                        <i className="fas fa-edit" onClick={this.handleEditClick} />
                    </Tooltip>
                )}
                {withEditButton && hasUpdate && (
                    <Tooltip
                        title={t('map_page.view_record.value_discard')}
                        onClick={this.handleDiscardClick}
                        data-testid={`record_view.edit_value.${property.name}.discard`}
                    >
                        <i className="fas fa-times" />
                    </Tooltip>
                )}
                {labelText}
            </span>
        );
    };

    render() {
        const { property, classes, updates } = this.props;
        const classNames = [
            classes[updates[property.name] === undefined ? 'original' : 'modified'],
            property.isUpdated ? classes.updated : undefined,
        ];
        const sm = property.type === FieldType.FILES ? 12 : 6;

        return (
            <Grid
                item
                key={property.name}
                xs={12}
                sm={sm}
                className={clsx(classNames)}
                data-testid={`record_view.edit_value.${property.name}`}
            >
                <Typography noWrap variant="caption">
                    {this.renderLabel()}
                </Typography>
                <Typography variant="body2">{this.buildValueView()}</Typography>
            </Grid>
        );
    }
}

EditValue.propTypes = {
    accountId: PropTypes.number.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    updates: PropTypes.object.isRequired,
    property: PropTypes.shape({
        apiName: PropTypes.string.isRequired,
        isLink: PropTypes.bool.isRequired,
        isReadOnly: PropTypes.bool.isRequired,
        length: PropTypes.number,
        picklist: PropTypes.array,
        type: PropTypes.string.isRequired,
        value: PropTypes.any,
    }).isRequired,
    value: PropTypes.any,
    withEditIcon: PropTypes.bool,
};

EditValue.defaultProps = {
    withEditButton: true,
};

const styles = (theme) => ({
    updated: {
        backgroundColor: '#d1ffc3 !important',
        transitionDuration: '.3s',
    },
    original: {
        transition: 'background-color 6s ease-out',
    },
    modified: {
        backgroundColor: alpha(yellow[200], 0.5),
        transition: 'background-color .3s ease-out',
    },
    editable: {
        cursor: 'pointer',
    },
    label: {
        '& > .fas': {
            color: 'gray',
            cursor: 'pointer',
            margin: '0 0 0 4px',
        },
    },
    withEditButton: {
        '& > .fas': {
            float: 'right',
            fontSize: theme.typography.fontSize,
        },
        '& > .fa-times': {
            margin: '0 4px',
        },
    },
    withoutEditButton: {
        textDecoration: 'dotted',
    },
    fieldName: {
        display: 'block',
    },
});

export default withTranslation('translations', { withRef: true })(withStyles(styles)(EditValue));
