import React from 'react';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import './WorkingDay.css';

interface WorkingDayProps extends WithTranslation {
    weekDayStart: Date | null;
    weekDayEnd: Date | null;
    onStartDateChanged: (date: MaterialUiPickersDate) => void;
    onEndDateChanged: (date: MaterialUiPickersDate) => void;
    timePickerVariant: WrapperVariant;
    disabled: boolean;
    startTimeLabel?: string;
    endTimeLabel?: string;
}

const TimePickerStyled = withStyles(() => ({
    '@media (max-width: 352px)': {
        root: {
            width: '50px',
            '& .MuiInputBase-root': {
                '& input': {
                    fontSize: '0.8em',
                },
            },
        },
    },
    '@media (max-width: 400px) and (min-width: 353px)': {
        root: {
            width: '60px',
            '& .MuiInputBase-root': {
                '& input': {
                    fontSize: '0.9em',
                },
            },
        },
    },
    '@media (max-width: 452px) and (min-width: 401px)': {
        root: {
            width: '60px',
            '& .MuiInputBase-root': {
                '& input': {
                    fontSize: '1.0em',
                },
            },
        },
    },
}))(TimePicker);

const WorkingDayTime: React.FC<WorkingDayProps> = (props) => {
    const {
        onStartDateChanged,
        onEndDateChanged,
        timePickerVariant,
        disabled,
        weekDayStart,
        weekDayEnd,
        startTimeLabel,
        endTimeLabel,
    } = props;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePickerStyled
                disabled={disabled}
                autoOk={true}
                variant={timePickerVariant}
                value={weekDayStart}
                onChange={onStartDateChanged}
                label={startTimeLabel}
            />
            <span className="c-form-dialog-route-preference__working-hours-item-time-delimiter" />
            <TimePickerStyled
                disabled={disabled}
                autoOk={true}
                variant={timePickerVariant}
                value={weekDayEnd}
                onChange={onEndDateChanged}
                label={endTimeLabel}
            />
        </MuiPickersUtilsProvider>
    );
};

export default withTranslation('translations')(WorkingDayTime);
