import { CalendarEvent } from '../../service/Calendar/CalendarEventRepository';

export enum CalendarFieldType {
    'SECTION' = 'section',
    'FIELD' = 'field',
}

export enum CalendarLocationValidate {
    Exact = 'exact',
    Valid = 'valid',
    Any = 'any',
}

export type CalendarField = {
    type: CalendarFieldType.FIELD;
    apiName: string;
    related: boolean;
    required: boolean;
    description: string;
    defaultValue?: string | null;
    label?: string | null;
};

export type CalendarSection = {
    type: CalendarFieldType.SECTION;
    label: string;
};

export interface Calendar {
    id: string;
    name: string;
    entityId: number;
    active: boolean;
    textColor: string;
    backgroundColor: string;
    type: {
        field: string;
        fieldValue: string;
    } | null;
    title: {
        field: string;
        template: string | null;
    };
    time: {
        startField: string;
        endField: string;
        allDayField: string | null;
        dateField?: string | null;
    };
    location: {
        field: string;
        validate: CalendarLocationValidate;
    };
    virtual: {
        enabled: boolean;
        method?: string;
        field?: string | null;
        fieldValue?: string;
        formula?: string;
        fieldTrueValue?: string | null;
        fieldFalseValue?: string | null;
        formulaDefaultTrueValues?: { [key: string]: any } | null;
        formulaDefaultFalseValues?: { [key: string]: any } | null;
        formulaJson?: string;
    };
    fields: (CalendarField | CalendarSection)[];
    allowTimelineDrop: boolean;
    form: string | null;
    alwaysUseForm: boolean;
    validationRule?: {
        formula: string | null;
        json: string | null;
    };
    connectedUserIds: number[];
}

export enum CalendarVirtualMethod {
    FIELD = 'field',
    CONDITION = 'condition',
}

export type Calendars = Map<string, Calendar>;

export type WaitingListItem = {
    id: string;
    title: string;
    virtual: boolean;
    location: string | null;
    displayLocation: string;
    lat: string | null;
    lng: string | null;
    relatedTo: {
        entityId: number;
        entityLabel: string;
        entityColor: string;
        id: string;
        displayText: string;
    };
    fixedTime?: boolean;
    duration?: number; //in minutes
    geocoderStatus?: number;
};

export type CalendarEventWitEntityId = { entityId: number; calendarEvent: CalendarEvent };
