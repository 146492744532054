import { DataTable } from '../DataTable';
import React from 'react';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid as DataGrid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { TableLoadingState } from '../TableLoadingState';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { ICON_POSITION_RIGHT } from '../../components/WebLink/WebLink';
import SimpleWebLink from '../../components/WebLink/SimpleWebLink.js';
const t = i18n.t.bind(i18n);

class PaymentHistory extends DataTable {
    constructor(props) {
        super(props);
        this.subManager = subscriptionManagerFactory.getManager(this.props.subscription.accountId);

        this.pagingPanelMessages = DataTable.getPagingMessages(t);
    }

    getFields() {
        return Promise.resolve([
            {
                name: 'date',
                title: this.props.t('billing.payment_history.date'),
                type: 'datetime',
            },
            {
                name: 'amount',
                title: this.props.t('billing.payment_history.amount'),
                type: 'string',
            },
            {
                name: 'currency',
                title: this.props.t('billing.payment_history.currency'),
                type: 'string',
            },
            {
                name: 'url',
                title: this.props.t('billing.payment_history.url'),
                getCellValue: (row) => (
                    <SimpleWebLink
                        text={this.props.t('billing.payment_history.url')}
                        iconPosition={ICON_POSITION_RIGHT}
                    >
                        {row.url}
                    </SimpleWebLink>
                ),
            },
        ]);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            this.loadData();
        }
    }

    requestData(ignorePage = false, parentTimer = null) {
        return this.subManager.listPaymentHistory(
            ignorePage ? 0 : this.state.pagination.current * this.state.pagination.size,
            this.state.pagination.size,
        );
    }

    render() {
        const structure = this.state.structure;
        const recordsAreLoading = this.state.records === null;
        const showTable = structure !== null;

        return (
            <Dialog open={this.props.openHistory} scroll="body">
                <DialogTitle>
                    {this.props.t('billing.payment_history.h1')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.payment_history.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {!showTable ? (
                        <div>{this.props.t('loading')}</div>
                    ) : (
                        <DataGrid className="c-data-table" rows={this.state.records || []} columns={structure.columns}>
                            <Table
                                columnExtensions={structure.exts}
                                noDataCellComponent={() => (
                                    <TableLoadingState
                                        columnCount={structure.columns.length}
                                        loading={recordsAreLoading}
                                    />
                                )}
                            />
                            <TableHeaderRow />
                            <PagingState
                                currentPage={this.state.pagination.current}
                                onCurrentPageChange={this.handleCurrentPageChanged}
                                onPageSizeChange={this.handlePageSizeChanged}
                                pageSize={this.state.pagination.size}
                            />
                            <CustomPaging totalCount={this.state.totalCount} />
                            <PagingPanel messages={this.pagingPanelMessages} pageSizes={this.pageSizes} />
                        </DataGrid>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

PaymentHistory.propTypes = {
    title: PropTypes.func,
    subscription: PropTypes.object.isRequired,
    openHistory: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func,
};

export const PaymentHistoryComponent = withTranslation('translations', { withRef: true })(PaymentHistory);

export default PaymentHistory;
