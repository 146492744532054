import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Box, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Children, CSSProperties, FC, useCallback } from 'react';

import { formatDateForPicker, isValidElementOfType } from '../../utils';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import { getDateFilterValue, OPERATOR_BETWEEN } from '../utils/tableFilter';

import './style.css';

const useCellStyles = makeStyles({ cell: { minWidth: '192px!important' } });

export const DateFilterCell: FC<TableFilterRow.CellProps> = (props) => {
    const style: CSSProperties = {};
    try {
        if (getDateFilterValue(props.filter) !== null) {
            style['backgroundColor'] = 'lavender';
        }
    } catch (e) {
        style['backgroundColor'] = 'mistyrose';
    }

    let isRangeMode = false;
    Children.forEach(props.children, (child: any) => {
        if (child.type === TableFilterRow.FilterSelector) {
            const cp = child.props;
            if (cp.value === OPERATOR_BETWEEN) {
                isRangeMode = true;
            }
        }
    });

    const cellClasses = useCellStyles();

    const filterValueToDateBetweenValue = useCallback((val?: string) => {
        const value = (val || '')
            .concat(',')
            // if we switch mode from single to multiple, there will be only 1 value
            .repeat(2)
            .split(',')
            .map((it) => it.trim())
            .slice(0, 2);
        const [dateFromString, dateToString] = value;
        const valueObj = {} as { dateFrom?: string; dateTo?: string };

        if (dateFromString) {
            valueObj.dateFrom = dateFromString;
        }
        if (dateToString) {
            valueObj.dateTo = dateToString;
        }
        return valueObj;
    }, []);

    const dateBetweenValueTofilterValue = useCallback((value: { dateFrom: string | null; dateTo: string | null }) => {
        const date = [value.dateFrom, value.dateTo, value.dateFrom, value.dateTo]
            .filter((it): it is string => Boolean(it))
            .slice(0, 2)
            .map((it) => it.replace(/ .*/, ''))
            .sort()
            .join(', ');
        return date;
    }, []);

    const dateValueTofilterValue = useCallback((date: MaterialUiPickersDate) => {
        if (!date) {
            return null;
        }

        const formatted = formatDateForPicker(date as Date);

        return formatted;
    }, []);

    if (isRangeMode) {
        style.minWidth = 220;

        return (
            <TableFilterRow.Cell {...props} style={style}>
                {Children.map(props.children, (child: any) => {
                    if (child.type === TableFilterRow.Editor) {
                        const value = filterValueToDateBetweenValue(props.filter?.value);

                        return (
                            <DateBetweenFilter
                                classes={cellClasses}
                                onFilter={({ value }) => {
                                    const dates = dateBetweenValueTofilterValue(value);
                                    child.props.onChange(dates);
                                }}
                                filter={{ value }}
                            />
                        );
                    }

                    return child;
                })}
            </TableFilterRow.Cell>
        );
    }

    return (
        <TableFilterRow.Cell {...props} style={style}>
            {Children.map(props.children, (child) => {
                if (isValidElementOfType(child, TableFilterRow.Editor)) {
                    const childProps = child.props;
                    const value = childProps.value?.replace(/ .*/, '') ?? childProps.value ?? null;

                    return (
                        <Box m={1}>
                            <DatePicker
                                disabled={childProps.disabled}
                                value={value}
                                format={'yyyy-MM-dd'}
                                clearable
                                onChange={(date) => {
                                    const formatted = dateValueTofilterValue(date);
                                    childProps.onChange(formatted ?? '');
                                }}
                            />
                        </Box>
                    );
                }

                return child;
            })}
        </TableFilterRow.Cell>
    );
};
