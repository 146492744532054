import React from 'react';
import events from '../../../events';
import dispatcher from '../../../service/dispatcher';
import territoryManagerFactory from '../../../service/Territories/TerritoryManager';

class TerritoriesFormatter extends React.PureComponent {
    componentDidMount() {
        dispatcher.subscribe(events.TERRITORIES_LOADED, this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const value = this.props.value;
        if (!value) {
            return null;
        }

        const manager = territoryManagerFactory.getCurrentManager();
        if (!manager) {
            return null;
        }

        const names = [];
        for (let id of value) {
            id = parseInt(id);
            const name = manager.getTerritoryNameById(id);
            if (name) {
                names.push(name);
            }
        }

        return names.join(', ');
    }
}

export default TerritoriesFormatter;
