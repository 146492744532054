export default class CounterMap extends Map {
    public increment(key: any): number {
        if (!this.has(key)) {
            this.set(key, { value: 1 });
            return 1;
        }

        return ++this.get(key).value;
    }

    public decrement(key: any): number | false {
        if (!this.has(key)) {
            return false;
        }
        const value = --this.get(key).value;

        if (value < 0) {
            this.get(key).value = 0;
            return false;
        }

        return value;
    }
}
