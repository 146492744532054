import React from 'react';
import {
    TextField,
    FormHelperText,
    Grid,
    FormControl,
    IconButton,
    Tooltip,
    Icon,
    CircularProgress,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DETAIL_CUSTOM, DETAIL_FIELD, DETAIL_FORMULA, getDetail } from '../../../service/WorkflowActionManager';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { withTranslation } from 'react-i18next';
import FormulaInput from '../AbstractForm/FormulaInput';
import { CallContext } from '../../utils/CallContext';
import LiveSearchTableViewDialog from 'components/LiveUpdateTableForm/LiveSearchTableViewDialog';
import FormulaButtonGroup from 'components/WorkflowActions/FormulaButtonGroup';
import { TYPE_TEXT_ARRAY } from '../../../service/types';
import DisabledOptionWithHintAutocomplete from '../../DisabledOptionWithHintAutocomplete';
import { getActiveOrInactiveFieldByApiName, isReadonlyField } from '../../../utils';

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_action.create_record.drag_button">
        <Icon>dehaze</Icon>
    </IconButton>
));

const FieldInput = withStyles((theme) => ({
    root: {
        '& .Mui-error input': {
            color: theme.palette.error.main,
        },
    },
}))(TextField);

class Field extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            field: null,
            showCustomInput: false,
            customField: null,
            showSearch: null,
        };

        this.formulaInputRef = React.createRef();
    }

    componentDidMount() {
        this.initState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldField = getDetail(DETAIL_FIELD, prevProps.details);
        const newField = getDetail(DETAIL_FIELD, this.props.details);
        const oldCustomValue = getDetail(DETAIL_CUSTOM, prevProps.details);
        const newCustomValue = getDetail(DETAIL_CUSTOM, this.props.details);
        if (oldField !== newField || oldCustomValue !== newCustomValue) {
            this.initState();
        }
    }

    initState = () => {
        const detailCustom = getDetail(DETAIL_CUSTOM, this.props.details, false);
        const detailField = getDetail(DETAIL_FIELD, this.props.details);
        if (detailCustom) {
            this.setState({
                showCustomInput: true,
                customField: detailField,
            });
        } else {
            const field = getActiveOrInactiveFieldByApiName(this.props.entity, detailField);
            this.setState({ field, showCustomInput: false, customField: null });
        }
    };

    handleFieldChange = (event, option) => {
        const type = option ? option.type : null;
        const field = option ? option.field : null;

        if (type === 'custom') {
            this.showCustomInput();
            this.props.onFieldChange(null, this.props.fieldIndex, true);
        } else {
            this.setState({ field });
            this.props.onFieldChange(field ? field.apiName : null, this.props.fieldIndex, false);
            this.hideCustomInput();
        }
    };

    handleCustomInput = (event) => {
        this.props.onFieldChange(event.target.value, this.props.fieldIndex, true);
    };

    showCustomInput = () => {
        this.setState({ showCustomInput: true });
    };

    hideCustomInput = () => {
        this.setState({ showCustomInput: false });
    };

    openShowSearch = (event) => {
        event.stopPropagation();
        const field = this.state.field;
        if (field) {
            this.setState({ showSearch: { field } });
        }
    };

    closeShowSearch = () => {
        this.setState({ showSearch: null });
    };

    handleSearchRecord = (record) => {
        this.props.onFormulaChange(record && record.id, this.props.fieldIndex);
        this.closeShowSearch();
    };

    handleFormulaChange = (event) => {
        this.props.onFormulaChange(event.target.value, this.props.fieldIndex);
    };

    findCurrentOption = () => {
        let value;
        if (this.state.showCustomInput) {
            value = 'custom';
        } else {
            const field = this.state.field;
            if (!field) {
                return null;
            }
            value = field.id;
        }
        return this.props.options.find((o) => o.value === value) || null;
    };

    renderInput = (params) => {
        const { field } = this.state;
        const error = field?.isDeleted || field?.isIncluded === false;

        return (
            <FieldInput
                {...params}
                label=""
                error={error}
                InputProps={{
                    ...params.InputProps,
                    style: { paddingTop: '5px', paddingBottom: '7px' },
                    startAdornment: (
                        <React.Fragment>
                            {params.InputProps.startAdornment}
                            {error && (
                                <Tooltip title={this.props.t('automation_elements.inactive_field')}>
                                    <Icon fontSize="small" color="error" className="fas fa-circle-exclamation" />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    ),
                    endAdornment: (
                        <React.Fragment>
                            {this.props.loading && <CircularProgress color="inherit" size={15} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    isMultiPicklist = () => {
        return !!(this.state.field && this.state.field.picklist && this.state.field.type === TYPE_TEXT_ARRAY);
    };

    getOptionDisabled = (option) => {
        const field = option ? option.field : null;
        if (field) {
            return (
                this.props.selectedFields.has(field.apiName) ||
                isReadonlyField(field) ||
                field.isDeleted ||
                !field.isIncluded
            );
        }
        return false;
    };

    getOptionDisabledHint = (option) => {
        if (option?.field && isReadonlyField(option.field)) {
            return this.props.t('automation_elements.form.field.readonly_hint');
        }
        if (option?.field?.isDeleted || option?.field?.isIncluded === false) {
            return this.props.t('automation_elements.form.field.deactivated_hint');
        }
        return this.props.t('workflow_actions.form.field_update.field.disabled_hint');
    };

    render() {
        const { showSearch } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={1} direction="row" alignItems="center">
                    <DragHandle />
                    <Grid item container spacing={1} alignItems="center" xs>
                        <Grid item xs={5}>
                            <FormControl fullWidth margin="dense" error={this.props.errors.has('field')}>
                                <DisabledOptionWithHintAutocomplete
                                    options={this.props.options}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => value && option.value === value.value}
                                    getOptionDisabled={this.getOptionDisabled}
                                    disabledOptionHint={this.getOptionDisabledHint}
                                    value={this.findCurrentOption()}
                                    onChange={this.handleFieldChange}
                                    groupBy={(option) => option && option.groupBy}
                                    loading={this.props.loading}
                                    renderInput={this.renderInput}
                                    style={{ minHeight: '45px' }}
                                />
                                {this.props.errors.get('field') && (
                                    <FormHelperText>{this.props.errors.get('field')}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormulaInput
                                label=""
                                fullWidth
                                multiline
                                name="formula"
                                onChange={this.handleFormulaChange}
                                value={getDetail(DETAIL_FORMULA, this.props.details, '')}
                                error={this.props.errors.has('formula')}
                                helperText={this.props.errors.get('formula') || ''}
                                style={{ minHeight: '45px' }}
                                ref={this.formulaInputRef}
                                callContext={this.props.callContext}
                                picklist={this.state.field && this.state.field.picklist}
                                multiPicklist={this.isMultiPicklist()}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <FormulaButtonGroup
                                            field={this.state.field}
                                            onOpenShowSearch={this.openShowSearch}
                                            onOpenFormulaModal={
                                                this.formulaInputRef.current &&
                                                this.formulaInputRef.current.handleOpenFormulaModal
                                            }
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        {this.state.showCustomInput && (
                            <Grid item xs={12}>
                                <FormulaInput
                                    label=""
                                    placeholder={this.props.t('workflow_actions.form.create_record.custom')}
                                    fullWidth
                                    multiline
                                    name="custom"
                                    onChange={this.handleCustomInput}
                                    value={getDetail(DETAIL_FIELD, this.props.details, '')}
                                    error={this.props.errors.has('custom')}
                                    helperText={this.props.errors.get('custom') || ''}
                                    callContext={this.props.callContext}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Tooltip title={this.props.t('remove')}>
                        <IconButton
                            color="secondary"
                            onClick={() => this.props.onRemove(this.props.fieldIndex)}
                            data-testid="workflow_actions.form.create_record.remove"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>

                {showSearch && (
                    <LiveSearchTableViewDialog
                        accountId={this.props.accountId}
                        dataSourceId={this.props.dataSourceId}
                        onClose={this.closeShowSearch}
                        onSaveRequest={this.handleSearchRecord}
                        field={showSearch.field}
                        value={showSearch.field.value}
                    />
                )}
            </React.Fragment>
        );
    }
}

Field.propTypes = {
    options: PropTypes.array.isRequired,
    selectedFields: PropTypes.instanceOf(Set).isRequired,
    details: PropTypes.object.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    entity: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        label: PropTypes.string,
    }).isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    errors: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onFormulaChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    dataSourceId: PropTypes.number,
};

export default withTranslation('translations', { withRef: true })(SortableElement(Field));
