export const SUBSCRIPTION_AI_PACKAGE_SIZE = 5 * Math.pow(10, 6);
export const SUBSCRIPTION_AI_MIN_PACKAGE_SIZE = 0;
export const SUBSCRIPTION_AI_MAX_PACKAGE_SIZE = 10 * SUBSCRIPTION_AI_PACKAGE_SIZE;

export const SUBSCRIPTION_ROUTING_PACKAGE_SIZE = Math.pow(10, 3);
export const SUBSCRIPTION_ROUTING_MIN_PACKAGE_SIZE = 0;
export const SUBSCRIPTION_ROUTING_MAX_PACKAGE_SIZE = 20 * SUBSCRIPTION_ROUTING_PACKAGE_SIZE;

export const SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE = Math.pow(10, 3);
export const SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE = 0;
export const SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE = 20 * SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE;
