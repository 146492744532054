import L, { LatLngBounds } from 'leaflet';
import { Bounds, FormatLeafletBounds } from '../../utils/MapBounds';
import { RULES_LOADING_LAYER } from '../../../service/GeoLibManager';
import { GeoJsonObject } from 'geojson';
import { DEFAULT_COLOR } from './TerritoryGroup';
import { v4 as uuidv4 } from 'uuid';
import '../Kml/L.KML.js';

export enum TERRITORY_COLOR_TYPE {
    FIXED = 1,
    GROUP = 2,
    COLOR_NOT = 3,
}

export enum TERRITORY_VISUALIZATION_TYPE {
    CUSTOM = 1,
    GROUP = 2,
}

export enum TERRITORY_TOLERANCE_TYPE {
    CUSTOM = 1,
    GROUP = 2,
    TOLERANCE_TYPE_NOT = 3,
}

export enum TERRITORY_TYPE {
    HAND = 1,
    GEO_LIB = 2,
    LOAD = 3,
}

export enum TERRITORY_SOURCE_DATA_FORMAT {
    GEO_GSON = 1,
    KML = 2,
}

export interface Territory {
    id: number | null;
    uuid: string;
    name: string;
    code: string;
    checked: boolean;
    parentId: number | null;
    sourceData: string | null;
    geoJson: string | null;
    color: string | null;
    mode: TERRITORY_TYPE;
    colorType: TERRITORY_COLOR_TYPE;
    visualizationType: TERRITORY_VISUALIZATION_TYPE;
    toleranceType: TERRITORY_TOLERANCE_TYPE;
    geoPartIds: Array<number>;
    showUnionArea: boolean;
    showNameArea: boolean;
    tolerance: number;
    groupId: number | null;
    isCreatedAuto: boolean;
    children: Array<any>;
    metricsValues: Array<any>;
    format: TERRITORY_SOURCE_DATA_FORMAT;
}

export interface FieldsTerritories {
    id: number;
    name: string;
    label: string;
    type: string;
    isReadonly: boolean;
}

export interface EntitiesTerritories {
    id: number;
    name: string;
    label: string;
    hasTerritories: boolean;
    fields: Array<FieldsTerritories>;
}

export interface DataSourcesTerritories {
    id: number;
    name: string;
    entities: Array<EntitiesTerritories>;
}

export interface LayerProperties {
    minZoom: number | null;
    minZoomUnion: number | null;
    bounds: Bounds | null;
    hasSimplifySourceData: boolean;
    hasSourceData: boolean;
}

export interface RawLayer {
    layerProperties: LayerProperties;
    sourceData: SourceData;
}

export interface GeoJson {
    features: Array<any>;
    type?: TypeGeoJson;
    feature?: any;
    geometry?: any;
    properties?: any;
}

export enum TypeGeoJson {
    FEATURE_COLLECTION = 'FeatureCollection',
    FEATURE = 'Feature',
    LINE = 'LineString',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

export interface SourceData {
    simplifySourceData: any;
    fullSourceData: any;
}

export interface MinMax {
    maxLat: number;
    minLat: number;
    maxLng: number;
    minLng: number;
}

export interface Hemisphere {
    minMax: MinMax;
    hasPoint: boolean;
}

export interface MapPart {
    west: Hemisphere;
    east: Hemisphere;
    isMorePI: boolean;
    countShape: number;
}

const CreateCustomBounds = (west: Hemisphere, east: Hemisphere) => {
    return [
        new L.LatLngBounds(
            new L.LatLng(west.minMax.minLat, west.minMax.minLng),
            new L.LatLng(west.minMax.maxLat, west.minMax.maxLng),
        ).getCenter(),
        new L.LatLngBounds(
            new L.LatLng(east.minMax.minLat, east.minMax.minLng),
            new L.LatLng(east.minMax.maxLat, east.minMax.maxLng),
        ).getCenter(),
    ];
};
export const GetCenters = (layer: L.LayerGroup, child = true) => {
    // @ts-ignore
    if (!typeof layer.getBounds === 'function') {
        return [];
    }

    // @ts-ignore
    if (layer.feature && layer.feature.properties) {
        // @ts-ignore
        let properties = layer.feature.properties ?? null;
        if (
            child &&
            properties.mapPart &&
            properties.mapPart.isMorePI &&
            properties.mapPartGlobal.countShape > 1 &&
            properties.mapPart.west.hasPoint &&
            properties.mapPart.east.hasPoint
        ) {
            const west = properties.mapPart.west;
            const east = properties.mapPart.east;
            return CreateCustomBounds(west, east);
        } else if (
            !child &&
            properties.mapPartGlobal &&
            properties.mapPartGlobal.isMorePI &&
            properties.mapPartGlobal.countShape > 1 &&
            properties.mapPartGlobal.west.hasPoint &&
            properties.mapPartGlobal.east.hasPoint
        ) {
            const west = properties.mapPartGlobal.west;
            const east = properties.mapPartGlobal.east;
            return CreateCustomBounds(west, east);
        }
    }

    // @ts-ignore
    let bounds = layer.getBounds();
    return [bounds.getCenter()];
};

const Min = (currentValue: number, testValue: number) => {
    return Math.abs(currentValue) > Math.abs(testValue) ? testValue : currentValue;
};

const Max = (currentValue: number, testValue: number) => {
    return Math.abs(testValue) >= Math.abs(currentValue) ? testValue : currentValue;
};

const SetMapPart = (mapPart: MapPart, point: L.LatLng): MapPart => {
    if (point.lng > 0) {
        mapPart.east = SetMinMax(mapPart.east, point);
    } else {
        mapPart.west = SetMinMax(mapPart.west, point);
    }

    if (
        mapPart.east.hasPoint &&
        mapPart.west.hasPoint &&
        Math.abs(mapPart.east.minMax.maxLng - mapPart.west.minMax.minLng) > 180
    ) {
        mapPart.isMorePI = true;
    }
    return mapPart;
};

const SetMinMax = (hemisphere: Hemisphere, point: L.LatLng): Hemisphere => {
    hemisphere.minMax.maxLat = Max(hemisphere.minMax.maxLat, point.lat);
    hemisphere.minMax.minLat = Min(hemisphere.minMax.minLat, point.lat);
    hemisphere.minMax.maxLng = Max(hemisphere.minMax.maxLng, point.lng);
    hemisphere.minMax.minLng = Min(hemisphere.minMax.minLng, point.lng);
    hemisphere.hasPoint = true;
    return hemisphere;
};

export const CreateLayer = (
    territory: Territory,
    sourceDataOriginal: GeoJson | string | null,
): L.FeatureGroup | null => {
    if (!sourceDataOriginal) {
        return null;
    }

    switch (territory.format) {
        case TERRITORY_SOURCE_DATA_FORMAT.GEO_GSON:
            if ((sourceDataOriginal as GeoJson).features) {
                return new L.GeoJSON(sourceDataOriginal as GeoJsonObject);
            }
            return null;
        case TERRITORY_SOURCE_DATA_FORMAT.KML:
            if (sourceDataOriginal as string) {
                const parser = new DOMParser();
                const kml = parser.parseFromString(sourceDataOriginal as string, 'text/xml');
                if (kml.getElementsByTagName('parsererror')[0]) {
                    return null;
                }
                // @ts-ignore
                return new L.KML(kml);
            }
            return null;
    }

    return null;
};

const CreateEmptyMapPart = (): MapPart => {
    return {
        west: { minMax: { maxLat: 0, minLat: 90, maxLng: 0, minLng: 180 }, hasPoint: false },
        east: { minMax: { maxLat: 0, minLat: 90, maxLng: 0, minLng: 180 }, hasPoint: false },
        countShape: 0,
        isMorePI: false,
    };
};

export const CreateSourceDataFromGeoJson = (geoJson: GeoJson): SourceData => {
    let simplifySourceData: GeoJson | null = null;
    let fullSourceData: GeoJson | null = null;
    if (geoJson !== null) {
        fullSourceData = { type: TypeGeoJson.FEATURE_COLLECTION, features: [] };
        if (geoJson.features !== undefined) {
            simplifySourceData = { type: TypeGeoJson.FEATURE_COLLECTION, features: [] };
            let mapPartGlobal = CreateEmptyMapPart();
            geoJson.features.forEach((item) => {
                let mapPart = CreateEmptyMapPart();
                mapPart.countShape++;
                mapPartGlobal.countShape++;
                new L.GeoJSON(item, {
                    coordsToLatLng: function (coords: any) {
                        const point = new L.LatLng(coords[1], coords[0]);
                        mapPart = SetMapPart(mapPart, point);
                        mapPartGlobal = SetMapPart(mapPartGlobal, point);
                        return point;
                    },
                });
                item.properties['mapPart'] = mapPart;
                item.properties['mapPartGlobal'] = mapPartGlobal;
                if (item.properties && item.properties.isUnion && simplifySourceData) {
                    simplifySourceData.features.push(item);
                } else if (fullSourceData) {
                    fullSourceData.features.push(item);
                }
            });
        } else {
            fullSourceData.features.push(geoJson);
        }
    }

    return { simplifySourceData, fullSourceData };
};

const formatSourceData = (territory: Territory, sourceDataOriginal: string | null): GeoJson | string | null => {
    if (!sourceDataOriginal) {
        return null;
    }

    switch (territory.format) {
        case TERRITORY_SOURCE_DATA_FORMAT.GEO_GSON:
            return JSON.parse(sourceDataOriginal) as GeoJson;

        case TERRITORY_SOURCE_DATA_FORMAT.KML:
            return sourceDataOriginal;
    }
};

export const MakeRawLayer = (territory: Territory, sourceDataOriginal: string | null): RawLayer => {
    const sourceDataOriginalFormat = formatSourceData(territory, sourceDataOriginal);
    const sourceData = MakeSourceData(territory, sourceDataOriginalFormat);

    let terrLayer = CreateLayer(territory, sourceDataOriginalFormat);
    let isBoundValid = terrLayer && terrLayer.getBounds() && terrLayer.getBounds().isValid();
    let layerProperties: LayerProperties = {
        minZoom: null,
        minZoomUnion: null,
        bounds: null,
        hasSimplifySourceData: isBoundValid ? sourceData.simplifySourceData !== null : false,
        hasSourceData: isBoundValid ? sourceDataOriginalFormat !== null : false,
    };

    if (isBoundValid && terrLayer) {
        const bounds = terrLayer.getBounds();
        layerProperties.minZoom = GetMinVisibleZoom(bounds);
        layerProperties.minZoomUnion = FindMinimalUnionZoom(sourceDataOriginalFormat);
        layerProperties.bounds = FormatLeafletBounds(bounds);
    }

    return { layerProperties, sourceData };
};

const MakeSourceData = (territory: Territory, sourceDataOrigin: GeoJson | string | null): SourceData => {
    let sourceData: SourceData = { simplifySourceData: null, fullSourceData: null };

    if (!sourceDataOrigin) {
        return sourceData;
    }

    switch (territory.format) {
        case TERRITORY_SOURCE_DATA_FORMAT.GEO_GSON:
            let geoGson = sourceDataOrigin as GeoJson;
            if (geoGson.type === TypeGeoJson.FEATURE_COLLECTION || geoGson.type === TypeGeoJson.FEATURE) {
                return CreateSourceDataFromGeoJson(sourceDataOrigin as GeoJson);
            }
            return sourceData;

        case TERRITORY_SOURCE_DATA_FORMAT.KML:
            return { fullSourceData: sourceDataOrigin, simplifySourceData: null };
    }

    return sourceData;
};

const GetMinVisibleZoom = (bounds: LatLngBounds): number | null => {
    if (!bounds) {
        return null;
    }
    const coefficient = 70;
    let minLng = bounds.getWest() - (bounds.getEast() - bounds.getWest()) * coefficient;
    let maxLng = bounds.getEast() + (bounds.getEast() - bounds.getWest()) * coefficient;
    let minLat = bounds.getNorth() - (bounds.getSouth() - bounds.getNorth()) * coefficient;
    let maxLat = bounds.getSouth() + (bounds.getSouth() - bounds.getNorth()) * coefficient;

    const w = document.body.clientWidth;
    const h = document.body.clientHeight;

    const m1 = Math.tan((minLat * Math.PI) / 180) + 1 / Math.cos((minLat * Math.PI) / 180);
    const m2 = Math.tan((maxLat * Math.PI) / 180) + 1 / Math.cos((maxLat * Math.PI) / 180);

    const latZoom = maxLat > minLat ? Math.log2((2 * h * Math.PI) / 256 / Math.log(m2 / m1)) : null;
    const lngZoom = maxLng > minLng ? Math.log2((360 * w) / ((maxLng - minLng) * 256)) : null;

    if (!latZoom === null && lngZoom === null) {
        return 4; //// US MAP
    }
    if (lngZoom === null && latZoom !== null) {
        return Math.round(latZoom);
    }
    if (latZoom === null && lngZoom !== null) {
        return Math.round(lngZoom);
    }

    return Math.round(Math.min(latZoom ?? 4, lngZoom ?? 4));
};

const FindMinimalUnionZoom = (sourceDataOriginal: GeoJson | string | null) => {
    let minZoom = 0;

    if (!sourceDataOriginal || !(sourceDataOriginal as GeoJson).features) {
        return minZoom;
    }

    let sourceData = sourceDataOriginal as GeoJson;
    if (sourceData.features) {
        sourceData.features.forEach((item) => {
            if (!item.properties || !item.properties.typeArea) {
                return;
            }

            let typeArea: number = parseInt(item.properties.typeArea);
            // @ts-ignore
            if (RULES_LOADING_LAYER[typeArea] > minZoom) {
                // @ts-ignore
                minZoom = RULES_LOADING_LAYER[typeArea];
            }
        });
    }
    return minZoom + 1;
};

export const CreateTerritory = (): Territory => {
    return {
        id: null,
        uuid: uuidv4(),
        name: '',
        code: '',
        checked: true,
        parentId: null,
        sourceData: null,
        geoJson: null,
        color: DEFAULT_COLOR,
        mode: TERRITORY_TYPE.HAND,
        colorType: TERRITORY_COLOR_TYPE.FIXED,
        visualizationType: TERRITORY_VISUALIZATION_TYPE.CUSTOM,
        toleranceType: TERRITORY_TOLERANCE_TYPE.CUSTOM,
        geoPartIds: [],
        showUnionArea: true,
        showNameArea: false,
        tolerance: 70,
        groupId: null,
        isCreatedAuto: false,
        children: [],
        metricsValues: [],
        format: TERRITORY_SOURCE_DATA_FORMAT.GEO_GSON,
    };
};
