interface Item {
    task: Task;
    resolve: (value: unknown) => void;
    reject: (value: unknown) => void;
}

interface Task {
    run: () => Promise<unknown>;
}

export class PromiseQueue {
    queue: Array<Item> = [];
    pendingPromises: Map<Task, boolean> = new Map();
    maxPendingPromises: number = 3;

    constructor(maxPendingPromises: number = 3) {
        this.maxPendingPromises = maxPendingPromises;
    }

    enqueue(task: Task) {
        return new Promise((resolve, reject) => {
            this.queue.push({
                task,
                resolve,
                reject,
            });
            this.dequeue();
        });
    }

    dequeue() {
        if (this.pendingPromises.size >= this.maxPendingPromises) {
            return false;
        }
        const item = this.queue.shift();
        if (!item) {
            return false;
        }
        try {
            this.pendingPromises.set(item.task, true);

            item.task
                .run()
                .then((value: unknown) => {
                    this.pendingPromises.delete(item.task);
                    item.resolve(value);
                    this.dequeue();
                })
                .catch((err: unknown) => {
                    this.pendingPromises.delete(item.task);
                    item.reject(err);
                    this.dequeue();
                });
        } catch (err) {
            this.pendingPromises.delete(item.task);
            item.reject(err);
            this.dequeue();
        }
        return true;
    }

    static createTask(callback: () => Promise<unknown>): Task {
        return {
            run: callback,
        };
    }
}
