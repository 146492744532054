import React from 'react';
import { Grid, Button, Popover, FormLabel, FormControl, FormHelperText } from '@material-ui/core';
import IconPicker from '../../IconPicker';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);

class FormControlIconSelect extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            iconPickerAnchorEl: null,
        };
    }

    handleOpen = (e) => {
        e.stopPropagation();
        this.setState({ iconPickerAnchorEl: e.currentTarget });
    };

    handleClose = (e) => {
        e.stopPropagation();
        this.setState({ iconPickerAnchorEl: null });
    };

    handleChange = (icon) => {
        this.setState({ iconPickerAnchorEl: null }, () => {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: icon,
                },
            });
        });
    };

    render() {
        return (
            <FormControl fullWidth margin="dense" error={this.props.error}>
                <FormLabel required={this.props.required}>{this.props.label}</FormLabel>
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item xs={1}>
                        <i className={'icon fas ' + (this.props.value ? 'fa-' + this.props.value : '')} />
                    </Grid>
                    <Grid item xs={11}>
                        <Button
                            color="primary"
                            onClick={this.handleOpen}
                            data-testid="map_view_form.map_marker_image.icon_code.select"
                        >
                            {t('map_view_form.map_marker_image.icon_code.select')}
                        </Button>
                        <Popover
                            onClose={this.handleClose}
                            open={this.state.iconPickerAnchorEl !== null}
                            anchorEl={this.state.iconPickerAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <IconPicker onChange={this.handleChange} />
                            <div style={{ padding: '4px 8px', textAlign: 'right' }}>
                                <Button
                                    onClick={this.handleClose}
                                    color="primary"
                                    data-testid="map_view_form.map_marker_image.icon_picker.button.close"
                                >
                                    {t('close')}
                                </Button>
                            </div>
                        </Popover>
                    </Grid>
                </Grid>
                {this.props.helperText && <FormHelperText>{this.props.helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

export default FormControlIconSelect;
