import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../locales/i18n';
import AbstractForm from './AbstractForm';
import validator from 'validator';
import Form from '../../../../service/workflow_actions/forms/Form';
import Button from '../../../../service/workflow_actions/forms/fields/Button';
import WorkflowGroups from '../../../WorkflowGroups/WorkflowGroups';
import { TYPE_DEFAULT_ACTION_GROUP } from '../../../../service/WorkflowGroupManager';
import { CallContext } from '../../../utils/CallContext';
import Hint from '../../../Hint';

const t = i18n.t.bind(i18n);

class ButtonForm extends AbstractForm {
    validate() {
        const field = this.state.field;

        if (validator.isEmpty(field.getLabel() ?? '')) {
            this.state.errors.set('label', t('errors.not_empty'));
        }

        if (field.getHandlers().length === 0) {
            this.state.errors.set('handlers', t('errors.not_empty'));
        }

        field.getHandlers().forEach((handler) => {
            if (handler.mappings.length === 0) {
                this.state.errors.set('handlers', t('errors.not_empty'));
            }
        });
    }

    render() {
        const field = this.state.field;
        const errors = this.state.errors;

        return (
            <form noValidate autoComplete="off">
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                        <TextField
                            autoFocus
                            label={t('workflow_actions.forms.field.label')}
                            data-testid="workflow_actions.forms.field"
                            fullWidth
                            required
                            margin="dense"
                            name="label"
                            value={field.label ?? ''}
                            helperText={errors.get('label') ?? ''}
                            error={errors.has('label')}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={t('workflow_actions.forms.field.apiName')}
                            data-testid="workflow_actions.forms.field.apiName"
                            fullWidth
                            required
                            margin="dense"
                            name="apiName"
                            value={field.apiName ?? ''}
                            helperText={errors.get('apiName') ?? ''}
                            error={errors.has('apiName')}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    {this.renderIsHiddenInput(field, errors)}
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="userLocationAvailable"
                                    checked={field.userLocationAvailable}
                                    onChange={this.handleInputChange}
                                    color="primary"
                                    data-testid="workflow_actions.forms.field.user_location_available"
                                />
                            }
                            label={
                                <span>
                                    {t('workflow_buttons.form.isUserLocationAvailable')}{' '}
                                    <Hint>{t('workflow_buttons.form.isUserLocationAvailable.hint')}</Hint>
                                </span>
                            }
                        />
                    </Grid>
                </Grid>
                <WorkflowGroups
                    name="handlers"
                    title={t('workflow_actions.forms.field.actions')}
                    placeholder={t('workflow_actions.forms.field.actions.placeholder')}
                    parentNamespace={this.props.action.namespace}
                    groupType={TYPE_DEFAULT_ACTION_GROUP}
                    groups={field.getHandlers()}
                    errors={this.state.errors}
                    onChange={this.handlerHandlersChange}
                    account={this.props.account}
                    dataSources={this.props.dataSources}
                    callContext={this.props.callContext}
                />
            </form>
        );
    }
}

ButtonForm.propTypes = {
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    account: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,

    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Button).isRequired,
};

export default ButtonForm;
