import { appointmentConfig, routeDesignManager, routeReportManager, routingSessionManager } from 'service/MapPage';
import { userManager } from 'service/UserManager';
import { TABS } from 'service/RouteReport/RouteReportManager';
import { Geo, Routing, User } from 'interfaces';
import { validateGeoPoint } from '../../../utils';
import { PointType } from '../../../interfaces/routing/route';

const DUMMY_START_POINT = {
    index: 0,
    address: '0,0',
    addressFields: {},
    countryShort: null,
    departureDelay: 0,
    duration: 0,
    entityId: null,
    key: '0,0',
    lat: 100, // invelid
    lng: 200, // invalid
    objectName: '',
    preparationTime: 0,
    recordId: null,
    type: Routing.Route.ActivityType.START,
    pointType: PointType.SIMPLE_POINT,
} as Routing.Route.DesignRouteSimplePoint;

/**
 * todo rewrite as observable-observing service with computed pseudo-routes
 * todo computations must only happen when applicable
 * todo e.g. when table view is in Route mode or autozoom on route(s) option is enabled
 */
export default class PseudoRoutesService {
    get routes(): Routing.Route.PseudoRoute[] | undefined {
        const user = userManager.getCurrentUser() as User.User | undefined;
        if (user === undefined || !routingSessionManager.isReady) {
            return undefined;
        }

        const {
            departingCalendarData: { date: dateStartAt },
        } = appointmentConfig;

        if (routingSessionManager.isDesignMode) {
            const activities = [DUMMY_START_POINT, ...routeDesignManager.points];

            return [
                {
                    activities,
                    dateStartAt,
                    user,
                },
            ];
        }

        if (routeReportManager.currentTab === TABS.UNASSIGNED_JOBS) {
            return [
                {
                    activities: routingSessionManager.unassignedJobs,
                    dateStartAt,
                    user,
                },
            ];
        }

        if (routingSessionManager.isShowLoadedSessionRoute) {
            return [routingSessionManager.loadedSessionRoute];
        }

        if (routeReportManager.isShowRouteReport && routeReportManager.showRouteReportData !== null) {
            const {
                route: { id: routeId },
            } = routeReportManager.showRouteReportData;

            return routingSessionManager.currentSessionRoutes.filter(({ id }) => id === routeId);
        }

        if (routeReportManager.isShowRouteList) {
            return routeReportManager.getFilteredRoutes();
        }

        return routingSessionManager.currentSessionRoutes;
    }

    get points(): Geo.GeoPoint[] | undefined {
        const routes = this.routes;
        if (routes === undefined) {
            return undefined;
        }

        return Array.prototype
            .concat(
                ...routes.map(({ activities }) =>
                    activities.map(({ lat, lng }: Geo.NullableGeoPoint) => ({ lat, lng })),
                ),
            )
            .filter((point) => validateGeoPoint(point));
    }
}
