import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import dispatcher from '../../service/dispatcher';
import TableSelectionMenu from './TableSelectionMenu';
import events from '../../events';
import FrontDataTable from './FrontDataTable';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { userManager } from '../../service/UserManager';
import './style.css';
import { NO_PIN_SYSTEM_ENTITIES, SYSTEM_ENTITIES_API_NAMES } from 'references/systemEntities';
import FormDialog from '../FormDialog';
import PureFormDialog from '../PureFormDialog';
import TableViewForm from '../TableViewForm';
import PropTypes from 'prop-types';
import entityManagerFactory from '../../service/EntityManager';
import Icon from '@material-ui/core/Icon';
import { withSnackbar } from 'notistack';
import { MAP_MODE } from './constants';
import Menu from '@material-ui/core/Menu';
import entityViewManager from '../../service/EntityViewManager';
import Badge from '../Badge';
import LiveUpdateTableViewForm, { EDIT_ALL, EDIT_SELECTED } from '../LiveUpdateTableForm';
import { prospectingManager } from '../../service/ProspectingManager';
import ProspectingFrontDataTable from '../Prospecting/ProspectingFrontDataTable';
import ExportForm from '../Prospecting/ExportForm';
import { Box, Grid } from '@material-ui/core';
import DottedLink from '../DottedLink';
import { reverse, routes } from '../../routes';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { LAYERS_MODULE, OTHERS_MODULE, ROUTING_MODULE, TABLE_MODULE } from '../Permissions/constants';
import mapStateManagerFactory from '../../service/MapStateManagerFactory';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import Hint from '../Hint';
import ChangeHistoryModal from '../Log/DataExchangeLog/ChangeHistoryModal';
import { DATE_FORMAT_DATEFNS, isUpdatableField } from '../../utils';
import { withDataQueryColumnName, withIdsFilter } from 'service/RecordManager';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import { pseudoRoutesService, routeManager, routeReportManager, routingSessionManager } from 'service/MapPage';
import { FieldType } from 'components/types';
import { formatInTimeZone } from 'date-fns-tz';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { TABS } from 'service/RouteReport/RouteReportManager';
import QueueHandler from '../../service/EventsQueue/QueueHandler';
import UpdateRecord from '../../service/EventsQueue/Events/UpdateRecord';

export const PROSPECTING_ID = -1;

const ROUTE_POINT_NUMBER_COLUMN = withDataQueryColumnName(0);
const ROUTE_POINT_DATE_COLUMN = withDataQueryColumnName(1);
const ROUTE_POINT_USER_COLUMN = withDataQueryColumnName(2);

class EntityDataTable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selected: new Map(),
            entityId: null,
            hasRecords: false,
            totalCount: 0,
            entitySettings: false,
            mobileMenuAnchor: null,
            editRecordsSettings: false,
            entityUpdated: null,
            prospectingMode: false,
            prospectingHasFilter: false,
            exportProspectingIds: false,
            anchorEditTableViewEl: null,
            personalTableSettings: false,
            userHasDefaultSettings: false,
            processingPermissions: false,
            updateCountSelected: null,
            updateCountAll: null,
            processingPermissionsError: null,
            recordChangeHistory: null,
            liveUpdateConfirmRequest: null,
            liveUpdateLoading: false,
        };

        this.state.sections = this.getSectionsFilter();
        this.state.ids = this.getIdsFilter();
        this.state.prospectingIds = this.getProspectingIdsFilter();
        this.state.withData = this.getWithData();
        this.state.bounds = this.getBoundsFilter();

        this.dataTable = React.createRef();
        this.dataTableEntity = React.createRef();
        this.dataTableProspecting = React.createRef();
        this.liveUpdateTableForm = React.createRef();

        this.processRoutePointsListChanges = debounce(this.processRoutePointsListChanges, 100);
    }

    componentDidMount() {
        dispatcher.subscribe(
            [
                events.CURRENT_ROUTE_BUILT,
                events.CURRENT_ROUTE_DESIGN,
                events.CURRENT_ROUTE_LOADED,
                events.CURRENT_ROUTE_NAME_AND_USER_UPDATED,
                events.CURRENT_ROUTE_POINTS_GROUPED,
                events.CURRENT_ROUTE_RESET,
                events.CURRENT_ROUTE_SAVED,
                ...Object.values(events.ROUTE_REPORT),
                ...Object.values(events.ROUTING_SESSION),
            ],
            this,
            this.processRoutePointsListChanges,
        );

        dispatcher.subscribe(
            events.EVENT_ENTITY_DATA_FILTER_CHANGED,
            this,
            (entityId, isTableFilterChanged, isMapFilterChanged, isTableWithDataChanged, isTableIdsChanged) => {
                if (
                    entityId === this.state.entityId &&
                    (isTableFilterChanged || isTableWithDataChanged || isTableIdsChanged) &&
                    this.state.entityUpdated === null
                ) {
                    this.forceUpdate();
                    return;
                }
                this.setState({
                    entityUpdated: null,
                });
            },
        );

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });

        dispatcher.subscribe(events.EVENT_PROSPECTING_ENABLED, this, () => {
            let entityId = this.state.entityId;

            if (entityId === PROSPECTING_ID) {
                return;
            }

            this.setState(
                {
                    prospectingMode: true,
                    entityId: PROSPECTING_ID,
                    selected: new Map(),
                },
                () => {
                    this.updateCurrentDataTable();
                },
            );
        });

        dispatcher.subscribe(
            [
                events.EVENT_PROSPECTING_POINTS_RELOADED,
                events.EVENT_PROSPECTING_POINTS_ADDED,
                events.EVENT_PROSPECTING_POINTS_REMOVED,
            ],
            this,
            () => {
                const entityId = this.state.entityId;
                if (entityId !== PROSPECTING_ID) {
                    return;
                }

                this.setState({
                    selected: new Map(),
                });
            },
        );

        dispatcher.subscribe(events.EVENT_PROSPECTING_DISABLED, this, () => {
            let entityId = this.state.entityId;

            if (entityId !== PROSPECTING_ID) {
                this.forceUpdate();
                return;
            }

            this.setState(
                {
                    prospectingMode: false,
                    entityId: null,
                    prospectingHasFilter: false,
                    selected: new Map(),
                },
                () => {
                    this.updateCurrentDataTable();
                },
            );
        });
        let availableEntities = 0;
        let selectedEntityId = null;
        const savedEntityId = this.props.mapStateManager.getBottomPaneEntity();
        for (const ds of this.props.dataSources) {
            for (const entity of ds.entities) {
                availableEntities++;
                if (savedEntityId === entity.id || selectedEntityId === null) {
                    selectedEntityId = entity.id;
                }
            }
        }
        if (availableEntities) {
            this.setState({
                entityId: selectedEntityId,
            });
        }

        dispatcher.subscribe(
            [
                events.ENTITY_VISIBILITY_CHANGED,
                events.ENTITY_VIEW_CHANGED,
                events.LAYER_VISIBILITY_CHANGED,
                events.LAYER_VIEW_CHANGED,
                events.ENTITY_LAYER_GROUP_CHANGED,
            ],
            this,
            ({ entityId }) => {
                if (this.state.entityId === entityId) {
                    const sections = this.getSectionsFilter();
                    if (sections !== this.state.sections) {
                        this.forceUpdate();
                    }
                }
            },
        );

        this.updateCurrentDataTable();
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const sections = this.getSectionsFilter();
        const ids = this.getIdsFilter();
        const prospectingIds = this.getProspectingIdsFilter();
        const withData = this.getWithData();
        const bounds = this.getBoundsFilter();

        const filtersAreChanged =
            sections !== this.state.sections ||
            bounds !== this.state.bounds ||
            JSON.stringify(prospectingIds) !== JSON.stringify(this.state.prospectingIds) ||
            JSON.stringify(ids) !== JSON.stringify(this.state.ids) ||
            JSON.stringify(withData) !== JSON.stringify(this.state.withData);

        if (filtersAreChanged) {
            this.setState({
                sections,
                bounds,
                ids,
                prospectingIds,
                withData,
                selected: new Map(),
            });
        }

        if (prevState.entityId !== this.state.entityId) {
            this.props.mapStateManager.updateBottomPaneEntity(this.state.entityId);
        }
    }

    /**
     * todo rewrite using reactions
     * @see PseudoRoutesService
     */
    processRoutePointsListChanges = () => {
        if (this.props.mode === MAP_MODE.ROUTE) {
            this.forceUpdate();
        }
    };

    getSectionsFilter() {
        if (!this.state.entityId) {
            return undefined;
        }
        if (this.props.mode === MAP_MODE.MAP || this.props.mode === MAP_MODE.LAYERS) {
            const sections = mapStateManagerFactory.getManager().getEntityFractions(this.state.entityId);
            return sections.length > 0 ? sections.join('|') : null;
        }
        return undefined;
    }

    getIdsFilter() {
        const routes = this.getPseudoRoutesForRouteMode();
        if (routes === undefined) {
            return undefined;
        }

        return uniq(
            [].concat(
                ...routes.map(({ activities }) =>
                    activities
                        .filter(({ entityId, recordId }) => entityId === this.state.entityId && recordId)
                        .map(({ recordId }) => recordId),
                ),
            ),
        );
    }

    getProspectingIdsFilter() {
        const routes = this.getPseudoRoutesForRouteMode();
        if (routes === undefined) {
            return undefined;
        }

        return uniq(
            [].concat(
                ...routes.map(({ activities }) =>
                    activities
                        .filter(({ entityId, recordId }) => !entityId && recordId)
                        .map(({ recordId }) => recordId),
                ),
            ),
        );
    }

    /**
     * @return {{columns: DataTableColumn<any>[], query: WithDataQuery}|undefined}
     */
    getWithData() {
        const routes = this.getPseudoRoutesForRouteMode();
        if (routes === undefined || routeReportManager.currentTab !== TABS.ROUTES) {
            return undefined;
        }

        const timezone = userManager.currentUser.actualTimezone;
        const isMultipleRoutes = routes.length > 1;

        const ids = [];
        const activityNumber = [];
        const activityDate = [];
        const activityUser = [];
        routes.forEach((route) =>
            route.activities.forEach(({ entityId, recordId }, index) => {
                if (!recordId || entityId !== this.state.entityId || ids.includes(recordId)) {
                    return;
                }

                ids.push(recordId);
                activityNumber.push(index);
                activityDate.push(formatInTimeZone(route.dateStartAt, timezone, DATE_FORMAT_DATEFNS));
                activityUser.push(route.user.name);
            }),
        );

        const withData = {
            query: {
                recordIds: ids,
                labels: [this.props.t('entity_data_table.front_data_table.columns.point_number')],
                data: [activityNumber],
            },
            columns: [
                {
                    name: ROUTE_POINT_NUMBER_COLUMN,
                    columnName: ROUTE_POINT_NUMBER_COLUMN,
                    apiName: ROUTE_POINT_NUMBER_COLUMN,
                    originalApiName: null,
                    title: this.props.t('entity_data_table.front_data_table.columns.point_number'),
                    type: FieldType.BIGINT,
                    defaultValue: null,
                },
            ],
        };

        if (isMultipleRoutes) {
            withData.query.labels.push(this.props.t('entity_data_table.front_data_table.columns.point_date'));
            withData.query.labels.push(this.props.t('entity_data_table.front_data_table.columns.point_user'));
            withData.query.data.push(activityDate);
            withData.query.data.push(activityUser);
            withData.columns.push({
                name: ROUTE_POINT_DATE_COLUMN,
                columnName: ROUTE_POINT_DATE_COLUMN,
                apiName: ROUTE_POINT_DATE_COLUMN,
                originalApiName: null,
                title: this.props.t('entity_data_table.front_data_table.columns.point_date'),
                type: FieldType.STRING,
                defaultValue: null,
                picklist: routeReportManager.dates.map((date) => ({ label: date, value: date })),
            });
            withData.columns.push({
                name: ROUTE_POINT_USER_COLUMN,
                columnName: ROUTE_POINT_USER_COLUMN,
                apiName: ROUTE_POINT_USER_COLUMN,
                originalApiName: null,
                title: this.props.t('entity_data_table.front_data_table.columns.point_user'),
                type: FieldType.STRING,
                defaultValue: null,
                picklist: routeReportManager.users.map(({ name }) => ({ label: name, value: name })),
            });
        }

        return withData;
    }

    /**
     * @return {?PseudoRoute[]}
     */
    getPseudoRoutesForRouteMode() {
        if (this.props.mode !== MAP_MODE.ROUTE) {
            return;
        }

        return pseudoRoutesService.routes;
    }

    getBoundsFilter() {
        if (this.props.mode === MAP_MODE.MAP) {
            return this.props.bounds;
        }
        return undefined;
    }

    handleSelectionChange = (selected) => {
        this.setState({
            selected,
        });
    };

    handleAddToRoute = async (entityPoints, simplePoints) => {
        await routingSessionManager.addPoints(entityPoints, simplePoints);
    };

    handleAddToRouteRequest = () => {
        this.dataTable.current.addToRoute();
        this.setState({
            selected: new Map(),
        });
    };

    handleAddToWaitingList = () => {
        this.dataTable.current.addToWaitingList();
    };

    /**
     * Get filters by entity id
     *
     * @param entityId {?number} - id entity
     * @returns {Array}
     */
    getFiltersByEntityId = (entityId) => {
        return entityViewManager.getEntityTableFilters(entityId);
    };

    /**
     * @param entityId {?number}
     * @return {boolean}
     */
    hasFiltersOrIdsFilterByEntityId = (entityId) => {
        return (
            entityViewManager.getEntityTableIds(entityId) !== undefined ||
            this.getFiltersByEntityId(entityId).length > 0
        );
    };

    /**
     * Get title for filter icon by entity id
     *
     * @param entityId {number} - id entity
     * @returns {string}
     */
    getTitleForFilterIcon = (entityId) => {
        let count = this.getFiltersByEntityId(entityId).length;
        if (count > 0) {
            return this.props.t('entity_data_table.filters_applied', { count });
        }
        if (entityViewManager.getEntityTableIds(entityId) !== undefined) {
            return this.props.t('entity_data_table.filters_applied', {
                count: this.props.t('entity_data_table.form.modes.route'),
            });
        }
        return '';
    };

    handleClearSelection = () => {
        this.setState({
            selected: new Map(),
        });
    };

    handleChangeMode = (event) => {
        this.props.onChangeMode(event.target.value);
    };

    handleEntityChange = (event) => {
        const entityId = parseInt(event.target.value) || null;
        const prospectingMode = entityId === PROSPECTING_ID;

        const isRoute = this.isSystemEntity(entityId, SYSTEM_ENTITIES_API_NAMES.ROUTE);
        if (isRoute || prospectingMode) {
            this.props.onChangeMode(MAP_MODE.ALL);
        }

        this.setState(
            {
                selected: new Map(),
                entityId: entityId,
                hasRecords: false,
                prospectingMode,
                entityUpdated: null,
            },
            () => {
                this.updateCurrentDataTable();
            },
        );
    };

    updateCurrentDataTable() {
        this.dataTable = this.state.prospectingMode ? this.dataTableProspecting : this.dataTableEntity;
    }

    handleApplyProspectingFilters = (filters) => {
        const prospectingHasFilter = filters.length > 0;
        this.setState({
            prospectingHasFilter,
        });
    };

    handleApplyDataTableFilters = () => {
        this.setState({
            selected: new Map(),
        });
    };

    /**
     * Handle when user want clear filters
     */
    handleClearFilters = () => {
        this.setState({
            mobileMenuAnchor: null,
        });
        this.dataTable.current.clearFilters();
        dispatcher.dispatch(events.EVENT_ENTITY_DATA_FILTER_CLEARED, { entityId: this.state.entityId });
    };

    handleExportCSV = () => {
        this.setState({
            mobileMenuAnchor: null,
        });
        this.dataTable.current
            .loadCsv()
            .then(() => {
                this.props.enqueueSnackbar(this.props.t('entity_data_table.download_csv.processing'), {
                    variant: 'info',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });
            })
            .catch(() => {
                this.props.enqueueSnackbar(this.props.t('entity_data_table.download_csv.failure'), {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });
            });
    };

    handleOpenPersonalTableViewSettings = () => {
        this.handleEditTableView(true);
    };

    handleOpenGlobalTableViewSettings = () => {
        this.handleEditTableView(false);
    };

    handleEditTableView = (personalTableSettings) => {
        const timer = Timer.init(TIMER_OPERATIONS.EntityDataTable.openTableViewSettingsModal, true).startChild(
            TIMER_OPERATIONS.EntityDataTable.openTableViewSettingsModal,
            {
                entityId: this.state.entityId,
            },
        );
        this.setState({
            personalTableSettings,
            entitySettings: false,
            mobileMenuAnchor: null,
            anchorEditTableViewEl: null,
        });
        const activeEntity = this.getActiveEntity();
        this.getManager(activeEntity)
            .getEntity(this.state.entityId)
            .then((entitySettings) => {
                const userHasDefaultSettings =
                    JSON.stringify(entitySettings.tableView) === JSON.stringify(entitySettings.personalTableView);
                this.setState({
                    userHasDefaultSettings,
                    entitySettings,
                });
            })
            .finally(() => {
                timer.end();
            });
    };

    handleResetToDefaultTableView = () => {
        let entitySettings = { ...this.state.entitySettings };
        entitySettings.personalTableView = entitySettings.tableView;
        this.setState({
            entitySettings,
            userHasDefaultSettings: true,
        });
    };

    handleEditRecords = () => {
        const activeEntity = this.getActiveEntity();
        const manager = this.getManager(activeEntity);
        manager.getEntity(this.state.entityId).then((entitySettings) => {
            manager.getDataSource().then((dataSource) => {
                this.setState({
                    editRecordsSettings: {
                        fields: entitySettings.fields,
                        searchTitleFields: dataSource.searchTitleFields,
                        dsId: activeEntity.dsId,
                        type: this.state.selected.size > 0 ? EDIT_SELECTED : EDIT_ALL,
                        updateCounters: false,
                    },
                    updateCountSelected: null,
                    updateCountAll: null,
                });
            });
        });
    };

    handleChangeEditRecordsType = (type) => {
        this.setState((state) => {
            return {
                editRecordsSettings: {
                    ...state.editRecordsSettings,
                    type,
                },
            };
        });
    };

    handleCloseEditRecords = () => {
        this.setState({
            editRecordsSettings: false,
        });
    };

    renderLiveUpdateConfirmation = () => {
        const { liveUpdateConfirmRequest } = this.state;
        if (!liveUpdateConfirmRequest) {
            return null;
        }

        return (
            <DialogConfirmation
                open
                setOpen={this.closeLiveUpdateConfirmation}
                onConfirm={liveUpdateConfirmRequest.newRecordsCount > 0 ? this.confirmLiveUpdate : undefined}
                onReject={this.rejectLiveUpdate}
                titleText={this.props.t('entity_data_table.confirmation.records_count_changed.title')}
                yesBtnText={this.props.t('button.yes_proceed')}
                noBtnText={this.props.t('button.cancel')}
            >
                {liveUpdateConfirmRequest.newRecordsCount > 0
                    ? this.props.t('entity_data_table.confirmation.records_count_changed.body', {
                          count: liveUpdateConfirmRequest.newRecordsCount,
                      })
                    : this.props.t('entity_data_table.confirmation.records_count_changed.body.no_records')}
            </DialogConfirmation>
        );
    };

    handleLiveUpdateSaveRequest = async (fields, editType) => {
        if (!this.liveUpdateTableForm.current) {
            return;
        }

        this.setState({ liveUpdateLoading: true });

        const { countSelected, countAll, prevCountSelected, prevCountAll } = await this.handleLiveUpdateCountRequest(
            this.liveUpdateTableForm.current.buildRequest(),
        );
        let newRecordsCount;
        if (editType === EDIT_SELECTED && countSelected !== prevCountSelected) {
            newRecordsCount = countSelected;
        } else if (editType === EDIT_ALL && countAll !== prevCountAll) {
            newRecordsCount = countAll;
        }
        if (newRecordsCount !== undefined) {
            return new Promise((resolve, reject) => {
                const liveUpdateConfirmRequest = { args: { fields, editType }, resolve, reject, newRecordsCount };
                this.setState({ liveUpdateConfirmRequest, liveUpdateLoading: false });
            });
        }

        return this.startLiveUpdate(fields, editType);
    };

    confirmLiveUpdate = async () => {
        const { liveUpdateConfirmRequest } = this.state;
        if (!liveUpdateConfirmRequest) {
            return;
        }

        try {
            await this.startLiveUpdate(liveUpdateConfirmRequest.args.fields, liveUpdateConfirmRequest.args.editType);
        } catch (error) {
            return liveUpdateConfirmRequest.reject(error);
        }
        liveUpdateConfirmRequest.resolve();
    };

    rejectLiveUpdate = () => {
        if (this.state.liveUpdateConfirmRequest) {
            this.state.liveUpdateConfirmRequest.resolve();
        }
    };

    closeLiveUpdateConfirmation = (showConfirmation) => {
        if (showConfirmation) {
            return;
        }
        this.setState({ liveUpdateConfirmRequest: null });
    };

    startLiveUpdate = (fields, editType) => {
        const activeEntity = this.getActiveEntity();
        let { totalCount } = this.state;
        if (editType === EDIT_ALL && this.state.updateCountAll !== null) {
            totalCount = this.state.updateCountAll;
        }
        if (editType === EDIT_SELECTED && this.state.updateCountSelected !== null) {
            totalCount = this.state.updateCountSelected;
        }
        const manager = this.getManager(activeEntity);

        QueueHandler.addEvent(
            new UpdateRecord(
                manager.accountId,
                manager.dsId,
                this.state.entityId,
                fields,
                Array.from(this.state.selected.keys()),
                null,
                null,
                totalCount,
                editType,
                null,
            ),
        ).then(() => {
            dispatcher.dispatch(events.UPDATE_RECORDS_REQUEST, { entityId: activeEntity.id, fields });
        });

        this.setState({
            selected: new Map(),
            editRecordsSettings: false,
        });
    };

    handleLiveSingleDeleteRequest = (record) => {
        if (this.isSystemRouteEntity()) {
            const ids = [record.id];
            routeManager.deleteRoutes(ids);
            return;
        }
        const activeEntity = this.getActiveEntity();
        return this.getManager(activeEntity).startLiveDelete(this.state.entityId, [record.id]);
    };

    handleLiveBulkDeleteRequest = () => {
        if (this.isSystemRouteEntity()) {
            const ids = Array.from(this.state.selected.keys());
            routeManager.deleteRoutes(ids);
            return;
        }
        const activeEntity = this.getActiveEntity();
        return this.getManager(activeEntity)
            .startLiveDelete(this.state.entityId, Array.from(this.state.selected.keys()))
            .then(() => {
                this.setState({
                    selected: new Map(),
                });
            });
    };

    handleCloseProcessingPermissionsError = () => {
        this.setState({
            processingPermissionsError: null,
        });
    };

    handleLiveUpdateCountRequest = (fields) => {
        const prevCountSelected = this.state.updateCountSelected;
        const prevCountAll = this.state.updateCountAll;
        this.setState({
            processingPermissions: true,
            updateCountSelected: null,
            updateCountAll: null,
            processingPermissionsError: null,
            liveUpdateLoading: true,
        });
        const activeEntity = this.getActiveEntity();
        return this.getManager(activeEntity)
            .getLiveUpdateCount(this.state.entityId, Array.from(this.state.selected.keys()), fields)
            .then((response) => {
                const { countSelected, countAll, selectedRecordIds } = response;
                this.setState((state) => {
                    const result = { updateCountSelected: countSelected, updateCountAll: countAll };
                    if (state.editRecordsSettings && state.selected.size) {
                        const selected = new Map(state.selected);
                        const recordIds = new Set(selectedRecordIds);
                        for (const recordId of selected.keys()) {
                            if (!recordIds.has(recordId)) {
                                selected.delete(recordId);
                            }
                        }
                        result.selected = selected;
                    }
                    return result;
                });

                return { ...response, prevCountSelected, prevCountAll };
            })
            .catch((error) => {
                this.setState({
                    processingPermissionsError: error.message,
                });
            })
            .finally(() => {
                this.setState((state) => {
                    const result = { processingPermissions: false, liveUpdateLoading: false };
                    if (state.editRecordsSettings) {
                        result.editRecordsSettings = { ...state.editRecordsSettings, updateCounters: false };
                    }
                    return result;
                });
            });
    };

    getManager = (entity) => {
        return entityManagerFactory.getManager(userManager.getCurrentUser().accountId, entity.dsId);
    };

    getActiveEntity = () => {
        const { entityId } = this.state;
        for (let dataSource of this.props.dataSources) {
            for (let entity of dataSource.entities) {
                if (entity.id === entityId) {
                    return entity;
                }
            }
        }
        return null;
    };

    handleTableViewSaved = () => {
        this.setState({
            entitySettings: false,
        });
    };

    handleCloseTableView = () => {
        this.setState({
            entitySettings: false,
        });
    };

    handleRecordsLoaded = (records, totalCount) => {
        this.setState({
            hasRecords: records && records.length > 0,
            totalCount,
        });
    };

    onEntityUpdated = (entityUpdated) => {
        if (null === entityUpdated) {
            this.setState({ entityUpdated });
            return;
        }

        this.setState((state) => {
            const oldEntityUpdated = state.entityUpdated;
            let newEntityUpdated;
            if (null === oldEntityUpdated) {
                newEntityUpdated = entityUpdated;
            } else {
                newEntityUpdated = { ...state.entityUpdated };
                if (entityUpdated.typeUpdate) {
                    newEntityUpdated.typeUpdate = entityUpdated.typeUpdate;
                }
                if (entityUpdated.typeInsert) {
                    newEntityUpdated.typeInsert = entityUpdated.typeInsert;
                }
            }

            const result = { entityUpdated: newEntityUpdated };

            if (state.editRecordsSettings) {
                result.editRecordsSettings = { ...state.editRecordsSettings, updateCounters: true };
            }

            return result;
        });
    };

    onEntityDeleted = () => {
        if (this.state.editRecordsSettings) {
            this.setState((state) => {
                return { editRecordsSettings: { ...state.editRecordsSettings, updateCounters: true } };
            });
        }
    };

    handleTableViewSaveRequest = (tableView) => {
        const activeEntity = this.getActiveEntity();
        let entityData = {};
        if (this.state.personalTableSettings) {
            entityData.personalTableView = tableView;
        } else {
            entityData.tableView = tableView;
        }
        return this.getManager(activeEntity).saveEntitySettings(this.state.entityId, entityData);
    };

    handleOpenMobileMenu = (event) => {
        this.setState({
            mobileMenuAnchor: event.currentTarget,
        });
        this.props.onToggleMobileMenu(true);
    };

    handleCloseMobileMenu = () => {
        this.setState({
            mobileMenuAnchor: null,
        });
        this.props.onToggleMobileMenu(false);
    };

    handleOnOpenMobileMenu = () => {
        this.props.onToggleMobileMenu(true);
    };

    handleOnCloseMobileMenu = () => {
        this.props.onToggleMobileMenu(false);
    };

    reloadPage = () => {
        this.dataTable.current.loadData(false);
    };

    handleStartedProspectsExport = (error = null) => {
        this.setState(
            {
                exportProspectingIds: false,
                selected: new Map(),
            },
            () => {
                if (error) {
                    this.props.enqueueSnackbar(error, {
                        variant: 'error',
                        persist: true,
                        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    });
                }
            },
        );
    };

    handleCloseProspectsExport = () => {
        this.setState({
            exportProspectingIds: false,
        });
    };

    handleStartProspectsExport = () => {
        this.setState(({ selected: exportProspectingIds }) => ({
            exportProspectingIds,
        }));
    };

    handleStartProspectExport = (record) => {
        this.setState({
            exportProspectingIds: new Map([[record.id, record]]),
        });
    };

    handleChangedMapView = () => {
        this.setState({
            userHasDefaultSettings: false,
        });
    };

    buildTitleComponentTableViewForm = (linkToGeneralEntitySetting) => {
        if (!this.state.entitySettings) {
            return;
        }

        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item xs>
                    {this.props.t('entity_data_table.properties.title', { label: this.state.entitySettings.label })}
                </Grid>
                {userManager.isRoleAdmin() && (
                    <Grid item container xs className="table-view-setting__buttons" wrap="nowrap">
                        <Grid item>
                            <MenuItem
                                component={Link}
                                to={linkToGeneralEntitySetting}
                                className="table-view-setting-link"
                                data-testid="entity_data_table.all_fields"
                            >
                                <span>{this.props.t('entity_data_table.all_fields')}</span>
                            </MenuItem>
                        </Grid>
                        <Grid item>
                            <MenuItem
                                onClick={
                                    this.state.personalTableSettings
                                        ? this.handleOpenGlobalTableViewSettings
                                        : this.handleOpenPersonalTableViewSettings
                                }
                                className="table-view-setting-link"
                                data-testid={
                                    this.state.personalTableSettings
                                        ? 'map_table.table_properties.default_properties'
                                        : 'map_table.table_properties.personal_properties'
                                }
                            >
                                <span>
                                    {this.props.t(
                                        this.state.personalTableSettings
                                            ? 'map_table.table_properties.default_properties'
                                            : 'map_table.table_properties.personal_properties',
                                    )}
                                </span>
                            </MenuItem>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };

    buildFooterComponentTableViewForm = () => {
        if (!this.state.personalTableSettings) {
            return;
        }
        const tooltipHasPersonalSettings = this.props.t('map_table.table_properties.personal_setting_tooltip');
        const tooltipDefaultSettings = this.props.t('map_table.table_properties.default_setting_tooltip');

        return (
            <Box flex={1} whiteSpace="pre-line">
                {this.state.userHasDefaultSettings ? (
                    <Box ml={2.5} display="flex" alignItems="center" gridGap={8} justify="flex-start">
                        <span>{this.props.t('entity_data_table.using_default_table')}</span>
                        <Hint inheritStyles>{tooltipDefaultSettings}</Hint>
                    </Box>
                ) : (
                    <Box ml={2.5} display="flex" alignItems="center" gridGap={8} justify="flex-start">
                        <DottedLink
                            onClick={this.handleResetToDefaultTableView}
                            data-testid="map_table.table_properties.reset_to_default"
                        >
                            {this.props.t('map_table.table_properties.reset_to_default')}
                        </DottedLink>
                        <Hint inheritStyles>{tooltipHasPersonalSettings}</Hint>
                    </Box>
                )}
            </Box>
        );
    };

    buildLinkToGeneralSettingEntity = (activeEntity) => {
        if (!userManager.isRoleAdmin() || !activeEntity) {
            return null;
        }

        return reverse(routes.admin.account.dataSource.entity, {
            dataSourceId: activeEntity.dsId,
            entityId: activeEntity.id,
        });
    };

    openChangeHistory = (record) => {
        this.setState({ recordChangeHistory: record.id });
    };

    closeChangeHistory = () => {
        this.setState({ recordChangeHistory: null });
    };

    isSystemEntity(entityId, systemEntityApiName) {
        if (entityId === PROSPECTING_ID) {
            return false;
        }

        for (let dataSource of this.props.dataSources) {
            if (!dataSource.isSystem) {
                continue;
            }
            const entity = dataSource.entities.find((entity) => entityId === entity.id);
            return entity?.apiName === systemEntityApiName || false;
        }
        return false;
    }

    isNoPinSystemEntity(entityId) {
        if (entityId === PROSPECTING_ID) {
            return false;
        }

        const entity = this.props.dataSources
            .find(({ isSystem }) => isSystem)
            ?.entities.find(({ id }) => id === entityId);

        return NO_PIN_SYSTEM_ENTITIES.includes(entity?.name);
    }

    isEntityHighlited(entityId) {
        if (this.isNoPinSystemEntity(entityId) === true) {
            return false;
        }

        const entity = this.props.dataSources.flatMap(({ entities }) => entities).find(({ id }) => id === entityId);

        if (!entity) {
            return false;
        }

        if (entity.isPinFieldDependent || entity.useLatLngFields || entity.pin) {
            return true;
        }

        return false;
    }

    isSystemRouteEntity() {
        const entityId = this.state.entityId > 0 ? this.state.entityId : null;
        return this.isSystemEntity(entityId, SYSTEM_ENTITIES_API_NAMES.ROUTE);
    }

    render() {
        const { dataSources, t } = this.props;
        const { prospectingMode, prospectingHasFilter, hasRecords, entityUpdated } = this.state;
        const isOnlyDataSource = dataSources.length === 1;
        const entityId = this.state.entityId > 0 ? this.state.entityId : null;
        const ds = dataSources.find((ds) => ds.entities.filter((entity) => entity.id === entityId).length > 0);
        const dsId = ds ? ds.id : null;
        const selectId = this.state.entityId || 0;
        const hasFilters = this.getFiltersByEntityId(entityId).length > 0 || (prospectingHasFilter && prospectingMode);
        const changeEntityDisabled = entityId === null;
        const downloadCsvDisabled = entityId === null || hasRecords === false;
        const activeEntity = this.getActiveEntity();
        const editRecordsDisabled =
            0 === this.state.selected.size && this.state.totalCount === 0 && null === activeEntity;
        const userCanEdit = activeEntity && activeEntity.userCanEdit === true;
        const showRefreshIcon = entityUpdated && (entityUpdated.typeInsert || entityUpdated.typeUpdate);
        const prospectingEnable = prospectingManager.isProspectModeEnable();
        const exportProspectsAvailable =
            prospectingEnable && this.state.selected.size > 0 && this.state.entityId === PROSPECTING_ID;
        const linkToGeneralEntitySetting = this.buildLinkToGeneralSettingEntity(activeEntity);
        const tableViewFormTitle = this.buildTitleComponentTableViewForm(linkToGeneralEntitySetting);
        const tableViewFormFooter = this.buildFooterComponentTableViewForm();
        const userHasOneEntity = isOnlyDataSource && dataSources[0].entities && dataSources[0].entities.length === 1;
        const showSelect = !userHasOneEntity || (prospectingEnable && userHasOneEntity);
        const tableSettingEnable = userManager.userHasAccessTo(
            TABLE_MODULE.NAME,
            TABLE_MODULE.FEATURES.SHOW_HIDE_TABLE_COLUMNS.NAME,
        );
        const downloadCSVEnable = userManager.userHasAccessTo(
            TABLE_MODULE.NAME,
            TABLE_MODULE.FEATURES.DOWNLOAD_CSV.NAME,
        );
        const layersPanelEnable = userManager.userHasAccessTo(LAYERS_MODULE.NAME);
        const routeModeEnable = userManager.userHasAccessTo(ROUTING_MODULE.NAME);
        const isSystemRouteEntity = this.isSystemRouteEntity();
        const isSystemRoutingSessionEntity = this.isSystemEntity(entityId, SYSTEM_ENTITIES_API_NAMES.ROUTING_SESSION);
        const isSystemFileEntity = this.isSystemEntity(entityId, SYSTEM_ENTITIES_API_NAMES.FILE);
        const isNoPinSystemEntity = this.isNoPinSystemEntity(entityId);
        const deletingEnable = userCanEdit && isSystemRouteEntity;
        const canAddToRoute = !isNoPinSystemEntity;
        const highlightStatus = this.isEntityHighlited(entityId);
        const changeHistoryEnable = userManager.userHasAccessTo(
            OTHERS_MODULE.NAME,
            OTHERS_MODULE.FEATURES.CHANGE_HISTORY.NAME,
        );
        const hideModeSelector = isSystemRouteEntity || this.props.hideModeSelector;
        const canAddToWaitingList = ds && !ds.isSystem;
        return (
            <React.Fragment>
                <div className="entity-data-table">
                    <div className="entity-data-table__entities">
                        {showSelect && (
                            <FormControl fullWidth className="entity-data-table__entities__select">
                                <InputLabel>{t('entity_data_table.form.select.label')}</InputLabel>
                                <Select
                                    value={selectId}
                                    onChange={this.handleEntityChange}
                                    onFocus={this.handleOnOpenMobileMenu}
                                    onBlur={this.handleOnCloseMobileMenu}
                                    data-testid="entity_data_table.form.select"
                                >
                                    <MenuItem
                                        key={0}
                                        value={0}
                                        data-testid="entity_data_table.form.select.0"
                                        disabled={selectId !== 0}
                                    >
                                        {t('entity_data_table.form.select.0')}
                                    </MenuItem>
                                    {prospectingEnable && (
                                        <MenuItem value={PROSPECTING_ID} key="prospecting">
                                            {t('entity_data_table.form.select.-1')}
                                            {prospectingHasFilter && (
                                                <Tooltip
                                                    style={{ color: 'red', marginLeft: 8, fontSize: '16px' }}
                                                    title={t('entity_data_table.form.select.-1.tooltip')}
                                                >
                                                    <Icon className="fas fa-filter" fontSize="small" />
                                                </Tooltip>
                                            )}
                                        </MenuItem>
                                    )}
                                    {dataSources.map((dataSource) => {
                                        const entities = dataSource.entities;
                                        if (entities.length === 0) {
                                            return null;
                                        }
                                        const result = entities.map((entity) => (
                                            <MenuItem key={entity.id} value={entity.id}>
                                                {entity.label}
                                                {this.hasFiltersOrIdsFilterByEntityId(entity.id) && (
                                                    <Tooltip
                                                        style={{ color: 'red', marginLeft: 10, fontSize: '16px' }}
                                                        title={this.getTitleForFilterIcon(entity.id)}
                                                    >
                                                        <Icon className="fas fa-filter" fontSize="small" />
                                                    </Tooltip>
                                                )}
                                            </MenuItem>
                                        ));
                                        if (!isOnlyDataSource) {
                                            result.unshift(
                                                <MenuItem key={`ds-${dataSource.id}`} disabled>
                                                    <span style={{ fontSize: '75%' }}>{dataSource.name}</span>
                                                </MenuItem>,
                                            );
                                        }
                                        return result;
                                    })}
                                </Select>
                            </FormControl>
                        )}
                        {!hideModeSelector && (
                            <div className="entity-data-table__modes">
                                <FormControl fullWidth className="entity-data-table__modes__select">
                                    <InputLabel>
                                        {t('entity_data_table.form.mode.label')}
                                        <Tooltip title={<ModesTooltipContent />}>
                                            <a
                                                href="http://help.mapsly.com/en/articles/4360549-table-view-modes"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <i className="fas fa-question-circle" />
                                            </a>
                                        </Tooltip>
                                    </InputLabel>
                                    <Select
                                        value={this.props.mode}
                                        onChange={this.handleChangeMode}
                                        onFocus={this.handleOnOpenMobileMenu}
                                        onBlur={this.handleOnCloseMobileMenu}
                                        data-testid="entity_data_table.form.mode"
                                    >
                                        <MenuItem value={MAP_MODE.ALL} data-testid="entity_data_table.form.modes.all">
                                            {t('entity_data_table.form.modes.all')}
                                        </MenuItem>
                                        {layersPanelEnable && (
                                            <MenuItem
                                                value={MAP_MODE.LAYERS}
                                                data-testid="map_table.menu.layers_filters"
                                            >
                                                {t('map_table.menu.layers_filters')}
                                            </MenuItem>
                                        )}
                                        <MenuItem value={MAP_MODE.MAP} data-testid="entity_data_table.form.modes.map">
                                            {t('entity_data_table.form.modes.map')}
                                        </MenuItem>
                                        {routeModeEnable && (
                                            <MenuItem
                                                value={MAP_MODE.ROUTE}
                                                data-testid="entity_data_table.form.modes.route"
                                            >
                                                {t('entity_data_table.form.modes.route')}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div className="entity-data-table__icons">
                            <TableSelectionMenu
                                prospectingMode={prospectingMode}
                                entityId={this.state.entityId}
                                items={this.state.selected}
                                ids={this.state.ids}
                                count={this.state.selected.size ? this.state.selected.size : this.state.totalCount}
                                onAddToRoute={this.handleAddToRouteRequest}
                                onClear={this.handleClearSelection}
                                onGroupEdit={this.handleEditRecords}
                                onExportProspects={this.handleStartProspectsExport}
                                onDelete={this.handleLiveBulkDeleteRequest}
                                onAddToWaitingList={this.handleAddToWaitingList}
                                canEditRecords={!editRecordsDisabled && userCanEdit}
                                canExportProspects={exportProspectsAvailable}
                                canDeleteRecords={deletingEnable}
                                canAddToRoute={canAddToRoute}
                                canAddToWaitingList={canAddToWaitingList}
                            />
                            {hasFilters && (
                                <Tooltip style={{ color: 'red' }} title={t('entity_data_table.clear_filters.tooltip')}>
                                    <div>
                                        <IconButton
                                            onClick={this.handleClearFilters}
                                            data-testid="entity_data_table.clear_filters"
                                        >
                                            <Badge badgeContent={<i className="fas fa-times" />} color="secondary">
                                                <Icon
                                                    className="fas fa-filter"
                                                    style={{ overflow: 'visible' }}
                                                    fontSize="small"
                                                />
                                            </Badge>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                            {tableSettingEnable && (
                                <Tooltip title={t('entity_data_table.edit_columns.tooltip')}>
                                    <div>
                                        <IconButton
                                            onClick={this.handleOpenPersonalTableViewSettings}
                                            disabled={changeEntityDisabled}
                                            data-testid="entity_data_table.edit_columns"
                                        >
                                            <Icon className="fas fa-cog" fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                            {downloadCSVEnable && (
                                <CsvExportButton
                                    entityId={this.state.entityId}
                                    disabled={downloadCsvDisabled}
                                    onClick={this.handleExportCSV}
                                    t={t}
                                />
                            )}
                            {showRefreshIcon && (
                                <Tooltip title={t('entity_data_table.refresh.tooltip')}>
                                    <div>
                                        <IconButton onClick={this.reloadPage} data-testid="entity_data_table.refresh">
                                            <Icon fontSize="small">sync-alt_icon</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div className="entity-data-table--mobile">
                        <IconButton
                            onClick={this.handleOpenMobileMenu}
                            data-testid="entity_data_table.open_mobile_menu"
                        >
                            <Icon>more_vert</Icon>
                        </IconButton>
                        <Menu
                            anchorEl={this.state.mobileMenuAnchor}
                            open={this.state.mobileMenuAnchor !== null}
                            onClose={this.handleCloseMobileMenu}
                            disableAutoFocusItem={true}
                        >
                            {hasFilters && (
                                <MenuItem
                                    onClick={this.handleClearFilters}
                                    data-testid="entity_data_table.clear_filters"
                                >
                                    {t('entity_data_table.clear_filters')}
                                </MenuItem>
                            )}
                            {tableSettingEnable && (
                                <MenuItem
                                    onClick={this.handleOpenPersonalTableViewSettings}
                                    disabled={changeEntityDisabled}
                                    data-testid="entity_data_table.edit_columns"
                                >
                                    {t('entity_data_table.edit_columns')}
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={this.handleExportCSV}
                                disabled={downloadCsvDisabled}
                                data-testid="entity_data_table.download_csv"
                            >
                                {t('entity_data_table.download_csv')}
                            </MenuItem>
                            {showRefreshIcon && (
                                <MenuItem onClick={this.reloadPage} data-testid="entity_data_table.refresh">
                                    {t('entity_data_table.refresh')}
                                </MenuItem>
                            )}
                            <TableSelectionMenu
                                entityId={this.state.entityId}
                                items={this.state.selected}
                                ids={this.state.ids}
                                count={this.state.selected.size ? this.state.selected.size : this.state.totalCount}
                                onAddToRoute={this.handleAddToRouteRequest}
                                onClear={this.handleClearSelection}
                                onOpen={this.handleOnOpenMobileMenu}
                                onAction={this.handleCloseMobileMenu}
                                onGroupEdit={this.handleEditRecords}
                                onDelete={this.handleLiveBulkDeleteRequest}
                                onExportProspects={this.handleStartProspectsExport}
                                onAddToWaitingList={this.handleAddToWaitingList}
                                canEditRecords={!editRecordsDisabled && userCanEdit}
                                canDeleteRecords={deletingEnable}
                                canAddToRoute={canAddToRoute}
                                canExportProspects={exportProspectsAvailable}
                                canAddToWaitingList={canAddToWaitingList}
                            />
                        </Menu>
                    </div>
                </div>
                {this.state.entitySettings !== false && (
                    <FormDialog
                        title={tableViewFormTitle}
                        onSave={this.handleTableViewSaved}
                        onCancel={this.handleCloseTableView}
                        blocks={{ actionLeftBlock: tableViewFormFooter }}
                        maxWidth="sm"
                    >
                        <TableViewForm
                            onChangedView={this.handleChangedMapView}
                            onOpenPersonalTableViewSettings={this.handleOpenPersonalTableViewSettings}
                            personalSettings={this.state.personalTableSettings}
                            entity={this.state.entitySettings}
                            onSaveRequest={this.handleTableViewSaveRequest}
                            linkToSetting={linkToGeneralEntitySetting}
                        />
                    </FormDialog>
                )}
                {this.state.editRecordsSettings !== false && (
                    <PureFormDialog
                        open
                        title={t('entity_data_table.edit_records_dialog.title', { label: activeEntity.label })}
                        onClose={this.handleCloseEditRecords}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <LiveUpdateTableViewForm
                            fields={this.state.editRecordsSettings.fields.filter(isUpdatableField)}
                            searchTitleFields={this.state.editRecordsSettings.searchTitleFields}
                            onSaveRequest={this.handleLiveUpdateSaveRequest}
                            accountId={this.props.user.accountId}
                            entityId={entityId}
                            dataSourceId={this.state.editRecordsSettings.dsId}
                            selectedCount={this.state.selected.size}
                            allCount={this.state.totalCount}
                            updateCountSelected={this.state.updateCountSelected}
                            updateCountAll={this.state.updateCountAll}
                            editType={this.state.editRecordsSettings.type}
                            recalculateAvailable={this.handleLiveUpdateCountRequest}
                            processingPermissions={this.state.processingPermissions}
                            processingPermissionsError={this.state.processingPermissionsError}
                            closeProcessingPermissionsError={this.handleCloseProcessingPermissionsError}
                            onChangeEditRecordsType={this.handleChangeEditRecordsType}
                            updateCounters={this.state.editRecordsSettings.updateCounters}
                            saving={this.state.liveUpdateLoading}
                            ref={this.liveUpdateTableForm}
                        />
                    </PureFormDialog>
                )}
                {this.state.exportProspectingIds && (
                    <PureFormDialog
                        open
                        title={t('prospecting.export_form.title')}
                        onClose={this.handleCloseProspectsExport}
                    >
                        <ExportForm
                            dataSources={dataSources.filter((ds) => ds.insertable && !!ds.entities.length)}
                            onSave={this.handleStartedProspectsExport}
                            ids={this.state.exportProspectingIds}
                            apiKeys={this.props.apiKeys}
                        />
                    </PureFormDialog>
                )}
                <div className="front-data-table">
                    {!selectId && <div className="front-data-table--empty">{t('entity_data_table.select_object')}</div>}
                    <div className={prospectingMode ? 'front-data-table-view' : 'front-data-table-view-hidden'}>
                        <ProspectingFrontDataTable
                            apiKeys={this.props.apiKeys}
                            entityId={PROSPECTING_ID}
                            bounds={this.state.bounds}
                            selected={this.state.selected}
                            onAddToRoute={this.handleAddToRoute}
                            onFindOnMap={this.props.onFindOnMap}
                            onSelectionChange={this.handleSelectionChange}
                            ref={this.dataTableProspecting}
                            onApplyFilters={this.handleApplyProspectingFilters}
                            ids={this.state.prospectingIds}
                            routes={
                                routeReportManager.currentTab === TABS.ROUTES
                                    ? this.getPseudoRoutesForRouteMode()
                                    : undefined
                            }
                            onRecordsLoaded={this.handleRecordsLoaded}
                            onExportProspect={this.handleStartProspectExport}
                        />
                    </div>
                    <div className={!prospectingMode ? 'front-data-table-view' : 'front-data-table-view-hidden'}>
                        <FrontDataTable
                            key={entityId}
                            selected={this.state.selected}
                            entityId={entityId}
                            dsId={dsId}
                            sections={this.state.sections}
                            ids={this.state.ids}
                            withData={this.state.withData}
                            bounds={this.state.bounds}
                            onFindOnMap={this.props.onFindOnMap}
                            onAddToRoute={this.handleAddToRoute}
                            onSelectionChange={this.handleSelectionChange}
                            onApplyFilters={this.handleApplyDataTableFilters}
                            onLoadingError={this.props.onLoadingError}
                            onOpenChangeHistory={changeHistoryEnable ? this.openChangeHistory : undefined}
                            ref={this.dataTableEntity}
                            onRecordsLoaded={this.handleRecordsLoaded}
                            onEntityUpdated={this.onEntityUpdated}
                            onEntityDeleted={this.onEntityDeleted}
                            routeManager={this.props.routeManager}
                            highlightStatus={highlightStatus}
                            isSystemRouteEntity={isSystemRouteEntity}
                            isSystemRoutingSessionEntity={isSystemRoutingSessionEntity}
                            isSystemFileEntity={isSystemFileEntity}
                            onDelete={this.handleLiveSingleDeleteRequest}
                            mapMode={this.props.mode}
                        />
                    </div>
                </div>

                {this.state.recordChangeHistory && entityId && (
                    <ChangeHistoryModal
                        accountId={this.props.user.accountId}
                        user={this.props.user}
                        recordId={this.state.recordChangeHistory}
                        onClose={this.closeChangeHistory}
                        entityId={entityId}
                    />
                )}

                {this.renderLiveUpdateConfirmation()}
            </React.Fragment>
        );
    }
}

EntityDataTable.defaultProps = {
    hideModeSelector: false,
};

EntityDataTable.propTypes = {
    onChangeMode: PropTypes.func.isRequired,
    hideModeSelector: PropTypes.bool,
    mode: PropTypes.string.isRequired,
    onToggleMobileMenu: PropTypes.func.isRequired,
    apiKeys: PropTypes.object,
    mapStateManager: PropTypes.object.isRequired,
};

class CsvExportButton extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            csvExportId: null,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_CSV_EXPORT, this, ({ entityId, csvExportId }) => {
            if (entityId === this.props.entityId && csvExportId === this.state.csvExportId) {
                this.setState({
                    csvExportId: null,
                });
            }
        });

        dispatcher.subscribe(events.CSV_EXPORT_STATE, this, (entityId, csvExportId) => {
            if (entityId === this.props.entityId) {
                this.setState({
                    csvExportId,
                });
            }
        });

        dispatcher.subscribe(events.EVENT_REQUEST_TABLE_DATA, this, (params) => {
            if (params.entityId === this.props.entityId) {
                this.setState({
                    csvExportId: '', // undefined state
                });
                const csvParams = {
                    sections: params.sections,
                    filters: params.filters.concat(withIdsFilter(params.ids)),
                    sorting: params.sorting,
                };
                if (params.withData) {
                    csvParams.withData = params.withData;
                }

                entityViewManager.requestCsvState(this.props.entityId, csvParams).then(({ csvExportId }) => {
                    this.setState({
                        csvExportId,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { onClick, disabled, t } = this.props;
        return (
            <Tooltip
                title={
                    this.state.csvExportId !== null
                        ? t('entity_data_table.download_csv.processing')
                        : t('entity_data_table.download_csv.tooltip')
                }
            >
                <div>
                    <IconButton
                        onClick={onClick}
                        disabled={disabled || this.state.csvExportId !== null}
                        data-testid="entity_data_table.download_csv"
                    >
                        <Icon className="fas fa-file-excel" fontSize="small" />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }
}

class ModesTooltipContentBase extends React.PureComponent {
    render() {
        const { t } = this.props;

        return (
            <div className={this.props.classes.root}>
                <p>{t('entity_data_table.modes_tooltip_content.p1')}</p>
                <ul>
                    <li>
                        <b>{t('entity_data_table.modes_tooltip_content.ul.li.1.b')}</b>
                        {t('entity_data_table.modes_tooltip_content.ul.li.1')}
                    </li>
                    <li>
                        <b>{t('entity_data_table.modes_tooltip_content.ul.li.2.b')}</b>
                        {t('entity_data_table.modes_tooltip_content.ul.li.2')}
                    </li>
                    <li>
                        <b>{t('entity_data_table.modes_tooltip_content.ul.li.3.b')}</b>
                        {t('entity_data_table.modes_tooltip_content.ul.li.3')}
                    </li>
                    <li>
                        <b>{t('entity_data_table.modes_tooltip_content.ul.li.4.b')}</b>
                        {t('entity_data_table.modes_tooltip_content.ul.li.4')}
                    </li>
                </ul>
                <p>
                    {t('entity_data_table.modes_tooltip_content.p2.1')} <i className="fas fa-question-circle" />{' '}
                    {t('entity_data_table.modes_tooltip_content.p2.2')}
                </p>
            </div>
        );
    }
}

const modesTooltipContentStyles = {
    root: {
        '& p': {
            margin: '8px 0',
        },
        '& ul': {
            margin: 0,
            paddingLeft: 16,
            listStyle: 'none',
            '& > li': {
                position: 'relative',
                '&::before': {
                    position: 'absolute',
                    content: `"\\2014"`,
                    left: -16,
                },
            },
        },
    },
};

const ModesTooltipContent = withTranslation()(withStyles(modesTooltipContentStyles)(ModesTooltipContentBase));

export default withTranslation('translations', { withRef: true })(withSnackbar(EntityDataTable));
