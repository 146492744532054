import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../locales/i18n';

const t = i18n.t.bind(i18n);

export const TYPE_ENTITY_BASE = 'record';
export const TYPE_ENTITY_BASE_OLD = 'oldRecord';
export const TYPE_ENTITY_TARGET = 'match';
export const TYPE_ENTITY_LINK = 'link';

class Variable {
    static createBase(entityId) {
        return { entity: entityId, type: TYPE_ENTITY_BASE };
    }

    static createBaseOld(entityId) {
        return { entity: entityId, type: TYPE_ENTITY_BASE_OLD };
    }

    static createTarget(entityId) {
        return { entity: entityId, type: TYPE_ENTITY_TARGET };
    }

    static createLink(entityId) {
        return { entity: entityId, type: TYPE_ENTITY_LINK };
    }

    static create(type, entityId) {
        switch (type) {
            case TYPE_ENTITY_BASE:
                return Variable.createBase(entityId);
            case TYPE_ENTITY_BASE_OLD:
                return Variable.createBaseOld(entityId);
            case TYPE_ENTITY_TARGET:
                return Variable.createTarget(entityId);
            case TYPE_ENTITY_LINK:
                return Variable.createLink(entityId);
            default:
                return null;
        }
    }
}

export class CallContext {
    entities;
    vars;

    constructor(entities = [], vars = []) {
        this.entities = entities;
        this.vars = vars;
    }

    add(entityType, entityId) {
        this.vars.push(Variable.create(entityType, entityId));
        this.fixEntities();
    }

    replace(entityType, entityId) {
        if (this.getEntityId(entityType)) {
            this.vars = this.vars.map((v) => {
                return v.type === entityType ? Variable.create(entityType, entityId) : v;
            });
            this.fixEntities();
        } else {
            this.add(entityType, entityId);
        }
    }

    remove(entityType) {
        this.vars = this.vars.filter((v) => v.type !== entityType);
        this.fixEntities();
    }

    addBaseEntity(entityId) {
        this.add(TYPE_ENTITY_BASE, entityId);
    }

    addBaseOldEntity(entityId) {
        this.add(TYPE_ENTITY_BASE_OLD, entityId);
    }

    addTargetEntity(entityId) {
        this.add(TYPE_ENTITY_TARGET, entityId);
    }

    addLinkEntity(entityId) {
        this.add(TYPE_ENTITY_LINK, entityId);
    }

    fixEntities() {
        this.entities = this.vars.map((v) => v.entity);
    }

    isEmpty() {
        return this.vars.length === 0;
    }

    isValid() {
        const baseEntityId = this.getBaseEntityId();
        return baseEntityId && this.entities.includes(baseEntityId);
    }

    isValidForEmpty() {
        if (!this.getBaseEntityId()) {
            return true;
        }

        return this.isValid();
    }

    getBaseEntityId() {
        return this.getEntityId(TYPE_ENTITY_BASE);
    }

    getBaseOldEntityId() {
        return this.getEntityId(TYPE_ENTITY_BASE_OLD);
    }

    getTargetEntityId() {
        return this.getEntityId(TYPE_ENTITY_TARGET);
    }

    getLinkEntityId() {
        return this.getEntityId(TYPE_ENTITY_LINK);
    }

    getEntityId(type, asArray = false) {
        const entityIds = this.vars
            .filter((v) => {
                return v.type === type;
            })
            .map((v) => {
                return v.entity;
            });
        if (asArray) {
            return entityIds;
        }
        return entityIds.pop();
    }

    getPrefixApiName(entityId) {
        for (const v of this.vars) {
            if (v.entity === entityId) {
                return v.type;
            }
        }
        return null;
    }

    getPrefixLabel(entityId) {
        for (const v of this.vars) {
            if (v.entity === entityId) {
                switch (v.type) {
                    case TYPE_ENTITY_BASE:
                        return t('call_context.entity.base');
                    case TYPE_ENTITY_BASE_OLD:
                        return t('call_context.entity.base_old');
                    case TYPE_ENTITY_TARGET:
                        return t('call_context.entity.match');
                    case TYPE_ENTITY_LINK:
                        return t('call_context.entity.link');
                    default:
                        return null;
                }
            }
        }
        return null;
    }

    getPrefixLabelByType(type) {
        switch (type) {
            case TYPE_ENTITY_BASE:
                return t('call_context.entity.base');
            case TYPE_ENTITY_BASE_OLD:
                return t('call_context.entity.base_old');
            case TYPE_ENTITY_TARGET:
                return t('call_context.entity.match');
            case TYPE_ENTITY_LINK:
                return t('call_context.entity.link');
            default:
                return null;
        }
    }

    getRawData() {
        return { entities: this.entities, vars: this.vars };
    }

    compatible(parentCallContext) {
        const parentContextEntityIds = parentCallContext.getEntityId(TYPE_ENTITY_BASE, true);

        if (parentContextEntityIds.length === 0 || this.getEntityId(TYPE_ENTITY_BASE, true).length === 0) {
            return true;
        }

        for (const baseEntityId of this.getEntityId(TYPE_ENTITY_BASE, true)) {
            if (!parentContextEntityIds.includes(baseEntityId)) {
                return false;
            }
        }
        return true;
    }

    filter(entityTypes) {
        const newCallContext = cloneDeep(this);
        for (const entityType of entityTypes) {
            newCallContext.remove(entityType);
        }
        return newCallContext;
    }

    static create(baseEntityId = null) {
        return baseEntityId ? new CallContext([baseEntityId], [Variable.createBase(baseEntityId)]) : new CallContext();
    }

    static createFromArray(baseEntityIds) {
        const callContext = new CallContext();
        for (const baseEntityId of baseEntityIds) {
            callContext.addBaseEntity(baseEntityId);
        }
        return callContext;
    }
}
