import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import isMobile from 'is-mobile';
import { observer } from 'mobx-react';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Link from 'react-router-dom/Link';
import events from '../../events';
import { reverse, reverseWithSearch, routes } from '../../routes';
import { avatarService } from '../../service/Avatar/AvatarService';
import dispatcher from '../../service/dispatcher';
import { intercomManager } from '../../service/IntercomManager';
import appUpdateManager from '../../service/MobileApp/AppUpdateManager';
import pointsManager from '../../service/PointsManager';
import { massUsersTravelingPreferencesDialogManager, userPropertiesManager } from '../../service/UserForm';
import { userManager } from '../../service/UserManager';
import { workingHoursExceptionsDialogManager } from '../../service/UserWorkingHoursExceptions/WorkingHoursExceptionsDialogManager';
import { LOCATIONS, ORDER, workflowButtonManager } from '../../service/WorkflowButtonManager';
import { DEBUG_MODE, weAreInNativeApp } from '../../utils';
import { TAB_ACCOUNT_PERMISSIONS, TAB_ACCOUNT_SUBSCRIPTION_BILLING } from '../AccountPage/types';
import LogRocketMenuItems from '../MainMenu/LogRocketMenuItems';
import AboutAppModal from '../NativeApp/AboutAppModal';
import { ANALYTICS_MODULE, MAP_MODULE, TERRITORIES_MODULE } from '../Permissions/constants';
import PureFormDialog from '../PureFormDialog';
import { UserPropertiesTab } from '../types';
import UserPropertiesDialog from '../UserForm/UserPropertiesDialog';
import UsersTravelingPreferencesDialog from '../UsersTravelingPreferencesDialog';
import UsersWorkingHourExceptionsDialog from '../UsersWorkingHourExceptionsDialog';
import { textEllipsis } from '../utils/Strings';
import WorkflowButtonFactory from '../WorkflowButtons/WorkflowButtonFactory';
import DialogAvatarEditor from './avatar';
import './style.css';

class MainMenu extends React.Component {
    dialogDiscoverCtx = 'MainMenu';

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showAboutAppModal: false,
            loadingPoints: false,
            openAvatarDialog: false,
            workingHoursExceptionsDialogOpen: workingHoursExceptionsDialogManager.isOpen(),
        };
    }

    handleOpenAvatarEditor = () => {
        this.setState({
            openAvatarDialog: true,
        });
    };

    handleCloseAvatarEditor = () => {
        this.setState({
            openAvatarDialog: false,
        });
    };

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });
        dispatcher.subscribe(events.EVENT_ENTITY_POINTS_IS_LOADING, this, () => {
            this.setState({
                loadingPoints: true,
            });
        });
        dispatcher.subscribe(events.EVENT_ENTITY_POINTS_IS_LOADED, this, () => {
            !pointsManager.isPointsLoading() &&
                this.setState({
                    loadingPoints: false,
                });
        });

        dispatcher.subscribe([events.EVENT_ACCOUNT_AVATAR_CHANGED], this, () => {
            this.forceUpdate();
        });

        dispatcher.subscribe([events.EVENT_INTERCOM_STATE_CHANGED], this, () => {
            this.forceUpdate();
        });

        avatarService.loadUserAvatar(userManager.getCurrentUser()).then(() => {});
        dispatcher.subscribe(events.USER_WORKING_HOURS_EXCEPTIONS_POPUP_UPDATED, this, () => {
            this.setState({ workingHoursExceptionsDialogOpen: workingHoursExceptionsDialogManager.isOpen() });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.EVENT_CURRENT_USER_CHANGED, this);
        dispatcher.unsubscribe(events.EVENT_ACCOUNT_AVATAR_CHANGED, this);
    }

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleSwitchBack = () => {
        const accountId = userManager.getCurrentUser()?.accountId;
        userManager.switchBackUser().then(() => {
            if (userManager.isRoleSuperAdmin()) {
                if (accountId) {
                    this.props.history.push(reverse(routes.admin.account.index, { accountId }));
                    return;
                }
                this.props.history.push(routes.admin.accounts);
                return;
            }
            this.props.history.push(reverse(routes.admin.account.index));
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleProfileDialogOpen = () => {
        const account = userManager.getCurrentAccount();
        const user = userManager.getCurrentUser();
        userPropertiesManager.openModal(
            account,
            user,
            UserPropertiesTab.TAB_PERSONAL,
            undefined,
            this.dialogDiscoverCtx,
        );
    };

    handleUserSaved = (user) => {
        this.props.enqueueSnackbar(this.props.t('account.user.saved', { user: user.name }), { variant: 'success' });
    };

    handleRefresh = () => {
        appUpdateManager.resetCurrentVersion().then(() => {
            console.log('User start reload app');
        });
    };

    handleLogout = () => {
        userManager.logout();
    };

    handleOpenTerritories = () => {
        dispatcher.dispatch(events.CLICK_MENU_OPEN.TERRITORIES);
    };

    handleOpenUsers = () => {
        const tab = 'users';

        dispatcher.dispatch(events.CLICK_MENU_OPEN.ACCOUNT, tab);

        this.props.history.push(reverseWithSearch(routes.admin.account.index, undefined, { tab: tab }));
    };

    capitalize = (role) => {
        if (!role) {
            return '';
        }
        return role[0].toUpperCase() + role.slice(1);
    };

    handleShowAboutAppModal = () => {
        this.setState({
            showAboutAppModal: true,
        });
    };

    handleCloseAboutAppModal = () => {
        this.setState({
            showAboutAppModal: false,
        });
    };

    handleChatSupportOpen = () => {
        dispatcher.dispatch(events.EVENT_INTERCOM_STATE_OPENED);
    };

    handlePrintMapClick = () => {
        typeof this.props.onPrintMapClick === 'function' && this.props.onPrintMapClick();
    };

    handleOpenAccountTab = (tab) => {
        dispatcher.dispatch(events.CLICK_MENU_OPEN.ACCOUNT, tab);

        this.props.history.push(reverseWithSearch(routes.admin.account.index, undefined, { tab: tab }));
    };

    render() {
        const { t } = this.props;
        const { workingHoursExceptionsDialogOpen } = this.state;
        const user = userManager.getCurrentUser();
        const account = userManager.getCurrentAccount();
        const avatar = avatarService.getCurrentAvatar().thumbnail;

        if (!user) {
            return null;
        }

        const buttonsTop = workflowButtonManager.getButtonsFor(LOCATIONS.MAIN_MENU, ORDER.FIRST);
        const buttonsBottom = workflowButtonManager.getButtonsFor(LOCATIONS.MAIN_MENU, ORDER.LAST);
        const showMenu = userManager.isSharedMapUser() ? buttonsTop.length + buttonsBottom.length > 0 : true;
        if (!showMenu) {
            return null;
        }

        const { pathname } = this.props.location;
        const territoriesEnable = userManager.userHasAccessTo(TERRITORIES_MODULE.NAME);

        const printMapAvailable =
            pathname === '/' &&
            !weAreInNativeApp() &&
            userManager.userHasAccessTo(MAP_MODULE.NAME, MAP_MODULE.FEATURES.PRINT_MAP.NAME);
        const printMapDisabled = this.state.loadingPoints;

        const showLogRocket = account && (DEBUG_MODE || account?.isTest === true || userManager.isSwitchedSuperAdmin());

        let switchMode = false;
        try {
            // if logout during some long-running loadings there exception can be raised
            switchMode = userManager.getSwitchMode() ? t('switch_mode.' + userManager.getSwitchMode()) : false;
        } catch (e) {
            console.error(e);
        }

        const showWhoAmI = !userManager.isSharedMapUser();
        const showPreferences = !userManager.isSharedMapUser() && !userManager.isRoleSuperAdmin();
        // same dialog open by MapPage on the map route
        const showPreferencesDialog =
            pathname !== '/' &&
            userPropertiesManager.isOpen &&
            userPropertiesManager.discoverContext === this.dialogDiscoverCtx;

        const showAnalytics = userManager.userHasAccessTo(
            ANALYTICS_MODULE.NAME,
            ANALYTICS_MODULE.FEATURES.VIEW_CHARTS_AND_DASHBOARDS.NAME,
        );

        const onAnalyticsPage = pathname.startsWith('/analytics');

        const MenuBlock = React.forwardRef((props, ref) => {
            const { children, withoutBorder } = props;

            const isWithoutChildren = (childrenInput) => {
                return (
                    !childrenInput || (Array.isArray(childrenInput) && childrenInput.filter((ch) => !!ch).length <= 0)
                );
            };

            if (isWithoutChildren(children)) {
                return null;
            }

            return (
                <div className={clsx('main_menu__items_block', withoutBorder && 'main_menu__items_block--border-free')}>
                    {children}
                </div>
            );
        });

        const MenuItemBilling = () => {
            const attrs = isMobile()
                ? { component: Link, to: reverse(routes.admin.account.billing.index) }
                : { onClick: () => this.handleOpenAccountTab(TAB_ACCOUNT_SUBSCRIPTION_BILLING) };

            return (
                <MenuItem {...attrs} data-testid="main_menu.billing" className={'main_menu_item'}>
                    <Grid container spacing={1} alignContent="center" wrap="nowrap">
                        <Grid item>
                            <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-credit-card" />
                        </Grid>
                        <Grid item>{this.props.t('main_menu.billing')}</Grid>
                    </Grid>
                </MenuItem>
            );
        };

        return (
            <React.Fragment>
                {
                    <Box display="flex" className={'menu__button__box'}>
                        <Avatar
                            className={'menu__user_block__avatar'}
                            alt={user.name}
                            src={avatar}
                            onClick={this.handleMenuOpen}
                        />
                    </Box>
                }
                {this.state.showAboutAppModal && (
                    <PureFormDialog open onClose={this.handleCloseAboutAppModal} maxWidth="xs" fullWidth>
                        <AboutAppModal onClose={this.handleCloseAboutAppModal} />
                    </PureFormDialog>
                )}

                <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl !== null}
                    onClose={this.handleMenuClose}
                    className="app-menu-modal"
                    onClick={this.handleMenuClose}
                    disableAutoFocusItem={true}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {/* Logo with open loader/editor*/}
                    <MenuBlock withoutBorder>
                        {showWhoAmI && (
                            <MenuItem
                                className={clsx('main_menu_item', 'menu__user_block')}
                                onClick={showPreferences ? this.handleProfileDialogOpen : this.handleOpenAvatarEditor}
                            >
                                <Box display="flex" style={{ width: '100%' }}>
                                    <Avatar className={'menu__user_block__avatar'} alt={user.name} src={avatar} />

                                    <div className={'menu__user_block__info'}>
                                        <b>
                                            {textEllipsis(user.name, 30)}
                                            {switchMode && <i>&nbsp;({switchMode})</i>}
                                        </b>
                                        <small>{textEllipsis(user.email, 40)}</small>
                                    </div>
                                </Box>
                            </MenuItem>
                        )}
                    </MenuBlock>

                    {/* Personal preferences */}
                    <MenuBlock>
                        {showPreferences && (
                            <MenuItem
                                onClick={this.handleProfileDialogOpen}
                                className={'main_menu_item'}
                                data-testid="main_menu.my_user_properties"
                            >
                                {t('main_menu.my_user_properties')}
                            </MenuItem>
                        )}
                    </MenuBlock>

                    {/* Return user block */}
                    <MenuBlock>
                        {userManager.isSwitched() && (
                            <MenuItem
                                className={'main_menu_item'}
                                onClick={this.handleSwitchBack}
                                data-testid="main_menu.return"
                            >
                                {this.props.t('main_menu.return', {
                                    name: this.capitalize(this.props.t(userManager.getSwitchedUserRole())),
                                })}
                            </MenuItem>
                        )}
                    </MenuBlock>

                    <MenuBlock>
                        {userManager.getCurrentUser() &&
                            !userManager.isRoleSuperAdmin() &&
                            pathname !== reverse(routes.client) &&
                            !pathname.startsWith('/login') &&
                            !pathname.startsWith('/signup/') &&
                            !pathname.startsWith('/map/') &&
                            !pathname.startsWith('/file/') && (
                                <MenuItem
                                    component={Link}
                                    to={reverse(routes.client)}
                                    data-testid="main_menu.back_to_map"
                                    className={'main_menu_item'}
                                >
                                    <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                        <Grid item>
                                            <i
                                                style={{ color: 'gray' }}
                                                className="main_menu__item__icon fa fa-chevron-left"
                                            />
                                        </Grid>
                                        <Grid item>{this.props.t('main_menu.back_to_map')}</Grid>
                                    </Grid>
                                </MenuItem>
                            )}
                    </MenuBlock>

                    {/* Super admin block*/}
                    <MenuBlock>
                        {userManager.isRoleSuperAdmin() && pathname !== reverse(routes.admin.accounts) && (
                            <MenuItem
                                component={Link}
                                to={reverse(routes.admin.accounts)}
                                data-testid="main_menu.accounts"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-fw" />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.accounts')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </MenuBlock>

                    {/* LogRocket block*/}
                    <MenuBlock>{showLogRocket && <LogRocketMenuItems user={user} />}</MenuBlock>

                    {/* Custom items (start position) */}
                    <MenuBlock>
                        {workflowButtonManager.getButtonsFor(LOCATIONS.MAIN_MENU, ORDER.FIRST, null).length > 0 && (
                            <WorkflowButtonFactory location={LOCATIONS.MAIN_MENU} order={ORDER.FIRST} />
                        )}
                    </MenuBlock>

                    {/* Items */}
                    <MenuBlock>
                        {showAnalytics && !onAnalyticsPage && (
                            <MenuItem
                                component={Link}
                                to={reverse(routes.analytics)}
                                data-testid="main_menu.analytics"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray' }}
                                            className="main_menu__item__icon fa fa-chart-simple"
                                        />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.analytics')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                        {pathname === '/' && territoriesEnable && !userManager.isSubscriptionBlocked() && (
                            <MenuItem
                                onClick={this.handleOpenTerritories}
                                data-testid="main_menu.territories"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray' }}
                                            className="main_menu__item__icon fa fa-draw-polygon"
                                        />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.territories')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                        {printMapAvailable && !userManager.isSubscriptionBlocked() && (
                            <MenuItem
                                onClick={this.handlePrintMapClick}
                                disabled={printMapDisabled}
                                data-testid="main_menu.print_map"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-print" />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.print_map')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </MenuBlock>

                    {/* Superadmin/admin items */}
                    <MenuBlock>
                        {userManager.isRoleAdmin() && !weAreInNativeApp() && (
                            <MenuItem
                                component={Link}
                                to={reverse(routes.admin.account.index)}
                                data-testid="main_menu.account_settings"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-cog" />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.account_settings')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}

                        {userManager.isRoleAdmin() && !isMobile() && !weAreInNativeApp() && (
                            <MenuItem
                                onClick={this.handleOpenUsers}
                                data-testid="main_menu.account_settings"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-users" />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.account_settings.users')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}

                        {userManager.isRoleAdmin() && !isMobile() && (
                            <MenuItem
                                onClick={() => this.handleOpenAccountTab(TAB_ACCOUNT_PERMISSIONS)}
                                data-testid="account.user.permissions"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray' }}
                                            className="main_menu__item__icon fa fa-user-shield"
                                        />
                                    </Grid>
                                    <Grid item>{t('permissions.title')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}

                        {userManager.isRoleAdmin() && <MenuItemBilling />}
                    </MenuBlock>

                    {/* Native block */}
                    <MenuBlock>
                        {weAreInNativeApp() && (
                            <MenuItem
                                onClick={this.handleRefresh}
                                data-testid="main_menu.refresh"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray', width: '1rem' }}
                                            className="main_menu__item__icon fa fa-fw"
                                        />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.refresh')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                        {weAreInNativeApp() && (
                            <MenuItem
                                onClick={this.handleShowAboutAppModal}
                                data-testid="main_menu.about_app"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray', width: '1rem' }}
                                            className="main_menu__item__icon fa fa-fw"
                                        />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.about_app')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </MenuBlock>

                    <MenuBlock>
                        {userManager.isSharedMapUser() ? null : (
                            <MenuItem
                                component="a"
                                href="https://help.mapsly.com"
                                rel="noreferrer noopener"
                                target="_blank"
                                data-testid="main_menu.help_center"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i
                                            style={{ color: 'gray' }}
                                            className="main_menu__item__icon fa fa-book-open"
                                        />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.help_center')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                        {intercomManager.isLoaded() && (
                            <MenuItem
                                onClick={this.handleChatSupportOpen}
                                data-testid="main_menu.chat"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-comments" />
                                    </Grid>
                                    <Grid item>{t('main_menu.chat')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}

                        {userManager.isSharedMapUser() ? null : (
                            <MenuItem
                                onClick={this.handleLogout}
                                data-testid="main_menu.logout"
                                className={'main_menu_item'}
                            >
                                <Grid container spacing={1} alignContent="center" wrap="nowrap">
                                    <Grid item>
                                        <i style={{ color: 'gray' }} className="main_menu__item__icon fa fa-sign-out" />
                                    </Grid>
                                    <Grid item>{this.props.t('main_menu.logout')}</Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </MenuBlock>

                    <MenuBlock>
                        {workflowButtonManager.getButtonsFor(LOCATIONS.MAIN_MENU, ORDER.LAST, null).length > 0 && (
                            <WorkflowButtonFactory location={LOCATIONS.MAIN_MENU} order={ORDER.LAST} />
                        )}
                    </MenuBlock>
                </Menu>

                {this.state.openAvatarDialog && (
                    <DialogAvatarEditor user={user} onClose={this.handleCloseAvatarEditor} />
                )}

                {showPreferencesDialog && <UserPropertiesDialog onSaved={this.handleUserSaved} />}
                {workingHoursExceptionsDialogOpen && !!workingHoursExceptionsDialogManager.user && (
                    <UsersWorkingHourExceptionsDialog user={workingHoursExceptionsDialogManager.user} />
                )}
                <UsersTravelingPreferencesDialogContainer />
            </React.Fragment>
        );
    }
}

MainMenu.propTypes = {
    onPrintMapClick: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
};

const UsersTravelingPreferencesDialogContainer = observer(() => {
    return massUsersTravelingPreferencesDialogManager.isOpen ? <UsersTravelingPreferencesDialog /> : null;
});

export default withTranslation()(withRouter(withSnackbar(MainMenu)));
