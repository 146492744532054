import React from 'react';
import { TextField, FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withTranslation } from 'react-i18next';

class SinglePicklistConstantInput extends React.PureComponent {
    handleChange = (event, item) => {
        this.props.onChange(item?.value || '');
    };

    render() {
        const { value, picklist, disabled, t } = this.props;
        if (disabled) {
            return (
                <TextField
                    variant="outlined"
                    fullWidth
                    label={t('expresion_builder.value')}
                    data-testid="expresion_builder.value"
                    value={value}
                    disabled={true}
                />
            );
        }
        return (
            <FormControl variant="outlined">
                <Autocomplete
                    options={picklist}
                    value={picklist.find((item) => item.value === value) || ''}
                    getOptionLabel={(item) => item.label}
                    onChange={this.handleChange}
                    style={{ width: 342 }}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={t('expresion_builder.value')}
                            data-testid="expresion_builder.value"
                        />
                    )}
                />
            </FormControl>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SinglePicklistConstantInput);
