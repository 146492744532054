import React from 'react';
import PropTypes from 'prop-types';
import PopupJobFactory from '../../service/PopupJobFactory';
import { userManager } from '../../service/UserManager';
import { withTranslation } from 'react-i18next';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';

const MOCKING = null;

class SSUOauth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grantToken: null,
        };
    }

    componentDidMount() {
        if (this.props.autorun) {
            this.commit();
        }
    }

    oauthRequest() {
        const { t } = this.props;
        switch (MOCKING) {
            case true:
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve('ABCDEFG12345678980');
                    }, 2000);
                });
            case false:
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(t('signup.connection.oauth.error'));
                    }, 2000);
                });
            default:
                return PopupJobFactory.getService(this.props.oauthUrl).request();
        }
    }

    commit = () => {
        const { onProcessing, onSuccess, onError, t } = this.props;

        if (this.state.grantToken !== null) {
            onSuccess(this.state.grantToken);
            return Promise.resolve(this.state.grantToken);
        }

        onProcessing && onProcessing(t('signup.connection.oauth.receiving_grant_token'));

        return userManager
            .requestCurrentUser()
            .then(() => {
                // approve the user is defined
                return this.oauthRequest();
            })
            .then((grantToken) => {
                this.setState({
                    grantToken,
                });
                onSuccess(grantToken);
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);
                if (error instanceof Error) {
                    throw error;
                }
            });
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <p>{t('signup.connection.authorize_mapsly')}</p>
            </div>
        );
    }
}

SSUOauth.propTypes = {
    oauthUrl: PropTypes.string.isRequired,
    autorun: PropTypes.bool.isRequired,
    onProcessing: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

SSUOauth.defaultProps = {
    autorun: false,
};

export default withTranslation('translations', { withRef: true })(SSUOauth);
