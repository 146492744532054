import L from 'leaflet';

const CreateNewXml = (body) => {
    return (
        '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<kml xmlns="http://www.opengis.net/kml/2.2">  ' +
        '<Document>' +
        '<Folder>' +
        '<Placemark>' +
        body +
        '</Placemark>' +
        '</Folder>' +
        '</Document>' +
        '</kml>'
    );
};

L.KML = L.FeatureGroup.extend({
    initialize: function (kml, addOriginXmlToOptions = false) {
        this._kml = kml;
        this._layers = {};
        this.options = {};

        if (kml) {
            this.addKML(kml, addOriginXmlToOptions);
        }
    },

    addKML: function (xml, addOriginXmlToOptions = false) {
        let layers = L.KML.parseKML(xml, addOriginXmlToOptions);
        if (!layers || !layers.length) return;
        for (let i = 0; i < layers.length; i++) {
            this.fire('addlayer', {
                layer: layers[i],
            });
            this.addLayer(layers[i]);
        }
        this.latLngs = L.KML.getLatLngs(xml);
        this.fire('loaded');
    },

    latLngs: [],
});

L.Util.extend(L.KML, {
    parseKML: function (xml, addOriginXmlToOptions = false) {
        let style = this.parseStyles(xml);
        this.parseStyleMap(xml, style);
        let el = xml.getElementsByTagName('Folder');
        let layers = [],
            l;
        for (let i = 0; i < el.length; i++) {
            if (!this._check_folder(el[i])) {
                continue;
            }
            l = this.parseFolder(el[i], style, addOriginXmlToOptions);
            if (l) {
                layers.push(l);
            }
        }
        el = xml.getElementsByTagName('Placemark');
        for (let j = 0; j < el.length; j++) {
            if (!this._check_folder(el[j])) {
                continue;
            }
            l = this.parsePlacemark(el[j], xml, style, {}, addOriginXmlToOptions);
            if (l) {
                layers.push(l);
            }
        }
        el = xml.getElementsByTagName('GroundOverlay');
        for (let k = 0; k < el.length; k++) {
            l = this.parseGroundOverlay(el[k]);
            if (l) {
                layers.push(l);
            }
        }
        return layers;
    },

    // Return false if e's first parent Folder is not [folder]
    // - returns true if no parent Folders
    _check_folder: function (e, folder) {
        e = e.parentNode;
        while (e && e.tagName !== 'Folder') {
            e = e.parentNode;
        }
        return !e || e === folder;
    },

    parseStyles: function (xml) {
        let styles = {};
        let sl = xml.getElementsByTagName('Style');
        for (let i = 0, len = sl.length; i < len; i++) {
            let style = this.parseStyle(sl[i]);
            if (style) {
                let styleName = '#' + style.id;
                styles[styleName] = style;
            }
        }
        return styles;
    },

    parseOptions: function (xml) {
        let properties = {};

        let options = xml.getElementsByTagName('Data');
        if (options) {
            for (let i = 0, len = options.length; i < len; i++) {
                let option = options[i];
                if (option) {
                    let value = option.getElementsByTagName('value')[0];
                    properties[option.getAttribute('name')] = value ? value.textContent : '';
                }
            }
        }

        options = xml.getElementsByTagName('SimpleData');
        if (options) {
            for (let i = 0, len = options.length; i < len; i++) {
                let option = options[i];
                if (option) {
                    properties[option.getAttribute('name')] = option.textContent ?? '';
                }
            }
        }
        return properties;
    },

    parseStyle: function (xml) {
        let style = {},
            poptions = {},
            ioptions = {},
            el,
            id;

        let attributes = { color: true, width: true, Icon: true, href: true, hotSpot: true };

        function _parse(xml) {
            let options = {};
            for (let i = 0; i < xml.childNodes.length; i++) {
                let e = xml.childNodes[i];
                let key = e.tagName;
                if (!attributes[key]) {
                    continue;
                }
                if (key === 'hotSpot') {
                    for (let j = 0; j < e.attributes.length; j++) {
                        options[e.attributes[j].name] = e.attributes[j].nodeValue;
                    }
                } else {
                    let value = e.childNodes[0].nodeValue;
                    if (key === 'color') {
                        options.opacity = parseInt(value.substring(0, 2), 16) / 255.0;
                        options.color = '#' + value.substring(6, 8) + value.substring(4, 6) + value.substring(2, 4);
                    } else if (key === 'width') {
                        options.weight = value;
                    } else if (key === 'Icon') {
                        ioptions = _parse(e);
                        if (ioptions.href) {
                            options.href = ioptions.href;
                        }
                    } else if (key === 'href') {
                        options.href = value;
                    }
                }
            }
            return options;
        }

        el = xml.getElementsByTagName('LineStyle');
        if (el && el[0]) {
            style = _parse(el[0]);
        }
        el = xml.getElementsByTagName('PolyStyle');
        if (el && el[0]) {
            poptions = _parse(el[0]);
        }
        if (poptions.color) {
            style.fillColor = poptions.color;
        }
        if (poptions.opacity) {
            style.fillOpacity = poptions.opacity;
        }
        el = xml.getElementsByTagName('IconStyle');
        if (el && el[0]) {
            ioptions = _parse(el[0]);
        }
        if (ioptions.href) {
            style.icon = new L.KMLIcon({
                iconUrl: ioptions.href,
                shadowUrl: null,
                anchorRef: { x: ioptions.x, y: ioptions.y },
                anchorType: { x: ioptions.xunits, y: ioptions.yunits },
            });
        }

        id = xml.getAttribute('id');
        if (id && style) {
            style.id = id;
        }

        return style;
    },

    parseStyleMap: function (xml, existingStyles) {
        let sl = xml.getElementsByTagName('StyleMap');

        for (let i = 0; i < sl.length; i++) {
            let e = sl[i],
                el;
            let smKey, smStyleUrl;

            el = e.getElementsByTagName('key');
            if (el && el[0]) {
                smKey = el[0].textContent;
            }
            el = e.getElementsByTagName('styleUrl');
            if (el && el[0]) {
                smStyleUrl = el[0].textContent;
            }

            if (smKey === 'normal') {
                existingStyles['#' + e.getAttribute('id')] = existingStyles[smStyleUrl];
            }
        }

        return;
    },

    parseFolder: function (xml, style, addOriginXmlToOptions = false) {
        let el,
            layers = [],
            l;
        el = xml.getElementsByTagName('Folder');
        for (let i = 0; i < el.length; i++) {
            if (!this._check_folder(el[i], xml)) {
                continue;
            }
            l = this.parseFolder(el[i], style);
            if (l) {
                layers.push(l);
            }
        }
        el = xml.getElementsByTagName('Placemark');
        for (let j = 0; j < el.length; j++) {
            if (!this._check_folder(el[j], xml)) {
                continue;
            }
            l = this.parsePlacemark(el[j], xml, style, {}, addOriginXmlToOptions);
            if (l) {
                layers.push(l);
            }
        }
        el = xml.getElementsByTagName('GroundOverlay');
        for (let k = 0; k < el.length; k++) {
            if (!this._check_folder(el[k], xml)) {
                continue;
            }
            l = this.parseGroundOverlay(el[k]);
            if (l) {
                layers.push(l);
            }
        }
        if (!layers.length) {
            return;
        }
        if (layers.length === 1) {
            return layers[0];
        }
        return new L.FeatureGroup(layers);
    },

    parsePlacemark: function (place, xml, style, options, addOriginXmlToOptions = false, rootProperties = []) {
        let h,
            i,
            j,
            k,
            el,
            il,
            opts = options || {};
        let serializer = new XMLSerializer();
        el = place.getElementsByTagName('styleUrl');
        for (i = 0; i < el.length; i++) {
            let url = el[i].childNodes[0].nodeValue;
            for (let a in style[url]) {
                opts[a] = style[url][a];
            }
        }
        let properties = rootProperties;

        let propsEl = place.getElementsByTagName('ExtendedData')[0];
        if (propsEl) {
            properties = this.parseOptions(propsEl);
        }
        let nameTag = place.getElementsByTagName('name');

        if (nameTag && nameTag.length !== 0) {
            properties['name'] = nameTag[0].textContent ?? '';
        }

        il = place.getElementsByTagName('Style')[0];
        if (il) {
            let inlineStyle = this.parseStyle(place);
            if (inlineStyle) {
                for (k in inlineStyle) {
                    opts[k] = inlineStyle[k];
                }
            }
        }

        let multi = ['MultiGeometry', 'MultiTrack', 'gx:MultiTrack'];
        for (h in multi) {
            el = place.getElementsByTagName(multi[h]);
            for (i = 0; i < el.length; i++) {
                return this.parsePlacemark(el[i], xml, style, opts, addOriginXmlToOptions, properties);
            }
        }

        let layers = [];

        let parse = ['LineString', 'Polygon', 'Point', 'Track', 'gx:Track'];
        for (j in parse) {
            let tag = parse[j];
            el = place.getElementsByTagName(tag);
            for (i = 0; i < el.length; i++) {
                let l = this['parse' + tag.replace(/gx:/, '')](el[i], xml, opts);
                if (!l) {
                    continue;
                }
                let layer = new L.GeoJSON(l.toGeoJSON());
                let element = el[i];
                if (layer instanceof L.LayerGroup) {
                    // eslint-disable-next-line no-loop-func
                    layer.getLayers().forEach((layerChild) => {
                        let propertesNew = { ...properties };
                        if (addOriginXmlToOptions) {
                            propertesNew.xml = CreateNewXml(serializer.serializeToString(el[i]));
                            propertesNew.removeChild = () => {
                                place.removeChild(element);
                            };
                        }
                        layerChild.feature.properties = propertesNew;
                    });
                }
                if (layer) {
                    layers.push(layer);
                }
            }
        }

        if (!layers.length) {
            return;
        }
        let layer = layers[0];

        if (layers.length > 1) {
            layer = new L.FeatureGroup(layers);
        }

        let name,
            descr = '';
        el = place.getElementsByTagName('name');
        if (el.length && el[0].childNodes.length) {
            name = el[0].childNodes[0].nodeValue;
        }
        el = place.getElementsByTagName('description');
        for (i = 0; i < el.length; i++) {
            for (j = 0; j < el[i].childNodes.length; j++) {
                descr = descr + el[i].childNodes[j].nodeValue;
            }
        }

        if (name) {
            layer.on('add', function () {
                layer.bindPopup('<h2>' + name + '</h2>' + descr, { className: 'kml-popup' });
            });
        }

        return layer;
    },

    parseCoords: function (xml) {
        let el = xml.getElementsByTagName('coordinates');
        return this._read_coords(el[0]);
    },

    parseLineString: function (line, xml, options) {
        let coords = this.parseCoords(line);
        if (!coords.length) {
            return;
        }
        return new L.Polyline(coords, options);
    },

    parseTrack: function (line, xml, options) {
        let el = xml.getElementsByTagName('gx:coord');
        if (el.length === 0) {
            el = xml.getElementsByTagName('coord');
        }
        let coords = [];
        for (let j = 0; j < el.length; j++) {
            coords = coords.concat(this._read_gxcoords(el[j]));
        }
        if (!coords.length) {
            return;
        }
        return new L.Polyline(coords, options);
    },

    parsePoint: function (line, xml, options) {
        let el = line.getElementsByTagName('coordinates');
        if (!el.length) {
            return;
        }
        let ll = el[0].childNodes[0].nodeValue.split(',');
        return new L.KMLMarker(new L.LatLng(ll[1], ll[0]), options);
    },

    parsePolygon: function (line, xml, options) {
        let el,
            polys = [],
            inner = [],
            i,
            coords;
        el = line.getElementsByTagName('outerBoundaryIs');
        for (i = 0; i < el.length; i++) {
            coords = this.parseCoords(el[i]);
            if (coords) {
                polys.push(coords);
            }
        }
        el = line.getElementsByTagName('innerBoundaryIs');
        for (i = 0; i < el.length; i++) {
            coords = this.parseCoords(el[i]);
            if (coords) {
                inner.push(coords);
            }
        }
        if (!polys.length) {
            return;
        }
        if (options.fillColor) {
            options.fill = true;
        }
        if (polys.length === 1) {
            return new L.Polygon(polys.concat(inner), options);
        }
        return new L.MultiPolygon(polys, options);
    },

    getLatLngs: function (xml) {
        let el = xml.getElementsByTagName('coordinates');
        let coords = [];
        for (let j = 0; j < el.length; j++) {
            // text might span many childNodes
            coords = coords.concat(this._read_coords(el[j]));
        }
        return coords;
    },

    _read_coords: function (el) {
        let text = '',
            coords = [],
            i;
        for (i = 0; i < el.childNodes.length; i++) {
            text = text + el.childNodes[i].nodeValue;
        }
        text = text.split(/[\s\n]+/);
        for (i = 0; i < text.length; i++) {
            let ll = text[i].split(',');
            if (ll.length < 2) {
                continue;
            }
            coords.push(new L.LatLng(ll[1], ll[0]));
        }
        return coords;
    },

    _read_gxcoords: function (el) {
        let text = '',
            coords = [];
        text = el.firstChild.nodeValue.split(' ');
        coords.push(new L.LatLng(text[1], text[0]));
        return coords;
    },

    parseGroundOverlay: function (xml) {
        let latlonbox = xml.getElementsByTagName('LatLonBox')[0];
        let bounds = new L.LatLngBounds(
            [
                latlonbox.getElementsByTagName('south')[0].childNodes[0].nodeValue,
                latlonbox.getElementsByTagName('west')[0].childNodes[0].nodeValue,
            ],
            [
                latlonbox.getElementsByTagName('north')[0].childNodes[0].nodeValue,
                latlonbox.getElementsByTagName('east')[0].childNodes[0].nodeValue,
            ],
        );
        let attributes = { Icon: true, href: true, color: true };
        function _parse(xml) {
            let options = {},
                ioptions = {};
            for (let i = 0; i < xml.childNodes.length; i++) {
                let e = xml.childNodes[i];
                let key = e.tagName;
                if (!attributes[key]) {
                    continue;
                }
                let value = e.childNodes[0].nodeValue;
                if (key === 'Icon') {
                    ioptions = _parse(e);
                    if (ioptions.href) {
                        options.href = ioptions.href;
                    }
                } else if (key === 'href') {
                    options.href = value;
                } else if (key === 'color') {
                    options.opacity = parseInt(value.substring(0, 2), 16) / 255.0;
                    options.color = '#' + value.substring(6, 8) + value.substring(4, 6) + value.substring(2, 4);
                }
            }
            return options;
        }
        let options = {};
        options = _parse(xml);
        if (latlonbox.getElementsByTagName('rotation')[0] !== undefined) {
            let rotation = latlonbox.getElementsByTagName('rotation')[0].childNodes[0].nodeValue;
            options.rotation = parseFloat(rotation);
        }
        return new L.RotatedImageOverlay(options.href, bounds, { opacity: options.opacity, angle: options.rotation });
    },
});

L.KMLIcon = L.Icon.extend({
    options: {
        iconSize: [32, 32],
        iconAnchor: [16, 16],
    },
    _setIconStyles: function (img, name) {
        L.Icon.prototype._setIconStyles.apply(this, [img, name]);
        if (img.complete) {
            this.applyCustomStyles(img);
        } else {
            img.onload = this.applyCustomStyles.bind(this, img);
        }
    },
    applyCustomStyles: function (img) {
        let options = this.options;
        this.options.popupAnchor = [0, -0.83 * img.height];
        if (options.anchorType.x === 'fraction') img.style.marginLeft = -options.anchorRef.x * img.width + 'px';
        if (options.anchorType.y === 'fraction')
            img.style.marginTop = -(1 - options.anchorRef.y) * img.height + 1 + 'px';
        if (options.anchorType.x === 'pixels') img.style.marginLeft = -options.anchorRef.x + 'px';
        if (options.anchorType.y === 'pixels') img.style.marginTop = options.anchorRef.y - img.height + 1 + 'px';
    },
});

L.KMLMarker = L.Marker.extend({
    options: {
        icon: new L.KMLIcon.Default(),
    },
});

// Inspired by https://github.com/bbecquet/Leaflet.PolylineDecorator/tree/master/src
L.RotatedImageOverlay = L.ImageOverlay.extend({
    options: {
        angle: 0,
    },
    _reset: function () {
        L.ImageOverlay.prototype._reset.call(this);
        this._rotate();
    },
    _animateZoom: function (e) {
        L.ImageOverlay.prototype._animateZoom.call(this, e);
        this._rotate();
    },
    _rotate: function () {
        if (L.DomUtil.TRANSFORM) {
            // use the CSS transform rule if available
            this._image.style[L.DomUtil.TRANSFORM] += ' rotate(' + this.options.angle + 'deg)';
        } else if (L.Browser.ie) {
            // fallback for IE6, IE7, IE8
            let rad = this.options.angle * (Math.PI / 180),
                costheta = Math.cos(rad),
                sintheta = Math.sin(rad);
            this._image.style.filter +=
                " progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=" +
                costheta +
                ', M12=' +
                -sintheta +
                ', M21=' +
                sintheta +
                ', M22=' +
                costheta +
                ')';
        }
    },
    getBounds: function () {
        return this._bounds;
    },
});
