import { format, parse } from 'date-fns';
import { dateFnsLocale } from '../../locales/i18n';
import { WorkingHoursBreak, WorkingHoursPerWeek } from '../types';
import { BreakTypeEnum } from '../../service/types';
import i18n from 'i18next';

export const getWorkingHoursAsString = (workingHoursPerWeek: WorkingHoursPerWeek): string => {
    let value = '';
    let prevTime: string | null = null;
    let dayTime: string | null = null;
    Object.keys(workingHoursPerWeek).forEach((day: string) => {
        const workingHours = workingHoursPerWeek[day];
        if (!workingHours) {
            return;
        }

        const startTime = new Date();
        startTime.setHours(workingHours.start.hours);
        startTime.setMinutes(workingHours.start.minutes);

        const endTime = new Date();
        endTime.setHours(workingHours.end.hours);
        endTime.setMinutes(workingHours.end.minutes);

        dayTime = '';
        dayTime += format(startTime, 'h');
        if (startTime.getMinutes() !== 0) {
            dayTime += `:${format(startTime, 'mm')}`;
        }
        dayTime += format(startTime, 'aaa').toLowerCase();

        dayTime += ' – ';

        dayTime += `${format(endTime, 'h')}`;
        if (startTime.getMinutes() !== 0) {
            dayTime += `:${format(endTime, 'mm')}`;
        }
        dayTime += format(endTime, 'aaa').toLowerCase();

        if (prevTime !== dayTime) {
            if (prevTime !== null) {
                value += ` ${prevTime}, `;
            }
            prevTime = dayTime;
        } else {
            if (prevTime !== null) {
                value += ', ';
            }
        }

        value += `${format(parse(day, 'iiii', new Date()), 'iii', { locale: dateFnsLocale })}`;
    });
    if (dayTime !== null) {
        value += ` ${dayTime}`;
    }

    return value;
};

export const getWorkingHoursBreakAsString = (workingHoursBreak: WorkingHoursBreak): string => {
    let text = '';
    if (workingHoursBreak.breakType === BreakTypeEnum.TIME_WINDOW) {
        text += i18n.t('route_editor.modal.routing_preferences.modal.break_type.time_window.label');
        if (workingHoursBreak.breakEarliestTime && workingHoursBreak.breakLatestTime) {
            const startTime = new Date();
            const startTimeParts = workingHoursBreak.breakEarliestTime.split(':');
            startTime.setHours(parseInt(startTimeParts[0], 10));
            startTime.setMinutes(parseInt(startTimeParts[1], 10));

            const endTime = new Date();
            const endTimeParts = workingHoursBreak.breakLatestTime.split(':');
            endTime.setHours(parseInt(endTimeParts[0], 10));
            endTime.setMinutes(parseInt(endTimeParts[1], 10));

            // add time as 10:30pm-2:00pm
            text += ': ';

            text += format(startTime, 'h');
            text += `:${format(startTime, 'mm')}`;
            text += format(startTime, 'aaa').toLowerCase();

            text += ' – ';

            text += format(endTime, 'h');
            text += `:${format(endTime, 'mm')}`;
            text += format(endTime, 'aaa').toLowerCase();
        }
    }

    return text;
};
