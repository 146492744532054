import React from 'react';
import { Grid, Icon, IconButton, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../locales/i18n';
import { SortableHandle } from 'react-sortable-hoc';
import FormulaInput from './FormulaInput';
import { CallContext } from '../../utils/CallContext';
import cloneDeep from 'lodash/cloneDeep';

const t = i18n.t.bind(i18n);

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_action.params_item.drag">
        <Icon>dehaze</Icon>
    </IconButton>
));

const NAME = 'name';
const VALUE = 'value';

class ParamsItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const item = cloneDeep(this.props.item);

        item[e.target.name] = e.target.value;

        this.props.onChange(item);
    }

    render() {
        return (
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: 20 }}>
                <Grid item xs={1}>
                    <DragHandle />
                </Grid>
                <Grid item xs={4}>
                    <FormulaInput
                        label=""
                        fullWidth
                        multiline
                        name={this.props.nameKey}
                        onChange={this.handleChange}
                        value={this.props.item[this.props.nameKey]}
                        error={this.props.errors.has(this.props.nameKey)}
                        helperText={this.props.errors.get(this.props.nameKey) || ''}
                        callContext={this.props.callContext}
                        disabled={this.props.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormulaInput
                        label=""
                        fullWidth
                        multiline
                        name={this.props.valueKey}
                        onChange={this.handleChange}
                        value={this.props.item[this.props.valueKey]}
                        error={this.props.errors.has(this.props.valueKey)}
                        helperText={this.props.errors.get(this.props.valueKey) || ''}
                        callContext={this.props.callContext}
                        disabled={this.props.disabled}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t('remove')}>
                        <span>
                            <IconButton
                                color="secondary"
                                onClick={this.props.onRemove}
                                disabled={this.props.disabled}
                                data-testid="workflow_action.params_item.remove"
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}

ParamsItem.defaultProps = {
    errors: new Map(),
    nameKey: NAME,
    valueKey: VALUE,
    disabled: false,
};

ParamsItem.propTypes = {
    item: PropTypes.object.isRequired,
    errors: PropTypes.instanceOf(Map),
    nameKey: PropTypes.string,
    valueKey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    disabled: PropTypes.bool,
};

export default ParamsItem;
