import { IDataSource } from '../types';

export interface DataSource extends IDataSource {
    importMessages: ImportMessage[];
}

export interface ImportMessage {
    id: number | string;
    ds: null | LightDataSource;
    entity: null | LightEntity;
    field: null | LightEntityField;
    origin: ORIGIN;
    httpCode: number;
    level: LEVEL;
    message: string;
    userId: null | number;
}

export interface LightDataSource {
    id: number;
    label: string;
}

export interface LightEntity {
    id: number;
    label: string;
}

export interface LightEntityField {
    id: number;
    label: string;
}

export enum ORIGIN {
    INTERNAL = 'internal',
    GET_ENTITIES = 'get_entities',
    GET_ENTITY = 'get_entity',
    GET_ENTITY_FIELDS = 'get_entity_fields',
    GET_ENTITY_RECORDS = 'get_entity_records',
    GET_ENTITY_DELETED_RECORDS = 'get_entity_deleted_records',
}

export enum LEVEL {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

export interface SortedAccountMessages {
    messages: ImportMessage[];
}

export interface SortedDsMessages {
    ds: LightDataSource;
    messages: ImportMessage[];
    entities: SortedEntityMessages[];
}

export interface SortedEntityMessages {
    entity: LightEntity;
    messages: ImportMessage[];
    fields: SortedFieldMessages[];
}

export interface SortedFieldMessages {
    field: LightEntityField;
    messages: ImportMessage[];
}

export enum Scope {
    DS = 'ds',
    ENTITY = 'entity',
    FIELD = 'field',
}
