import { Common, Entity, User } from 'interfaces';
import { AutomationElement } from './index';

export enum VisibilityType {
    ONE = 'one',
    MULTIPLE = 'multiple',
}

export enum Locations {
    MAP_POPUP = 'mapPopup',
    WAYPOINT = 'waypoint',
    TABLE_CONTEXT_MENU = 'tableContextMenu',
    TABLE_GROUP_ACTIONS = 'tableGroupActions',
    RECORD_VIEW = 'recordView',
    MAP_CLICK = 'mapClick',
    MAIN_MENU = 'mainMenu',
    ROUTE_MENU = 'routeMenu',
}

export enum ParallelSession {
    ALWAYS_ALLOW = 'allow',
    DIFFERENT_RECORD_SET = 'differentRecordSet',
    ALWAYS_BLOCK = 'block',
}

export enum Authentication {
    API_KEY = 'apikey',
    URL_SIGNATURE = 'signature',
}

export enum RoutingStatus {
    DESIGN = 'design',
    DRAFT = 'draft',
    ALL = 'all',
}

export enum RoutingMode {
    VIEWING = 'viewing',
    EDITING = 'editing',
    ALL = 'all',
}

export enum RoutingResultType {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
}

export interface EmptyButton extends AutomationElement {
    name: string | null;
    label: string | null;
    entities: Entity.Entity[];
    isActive: boolean;
    icon: string | null;
    visibilityType: VisibilityType | null;
    locations: Locations[] | null;
    isUserLocationAvailable: boolean;
    isSelectedLocationAvailable: boolean;
    order: number | null;
    parallelSession: ParallelSession | null;
    apiEndpointName: string | null;
    async: boolean | null;
    callbackUrl: string | null;
    auth: Authentication | null;
    signature: string | null;
    routingStatus: RoutingStatus | null;
    routingMode: RoutingMode | null;
    routingResultType: RoutingResultType | null;
    actionGroups: any[];
    roles: any[];
    updatedBy: User.User | null;
    createdAt: Common.DateString | null;
    updatedAt: Common.DateString | null;
    deletedAt: Common.DateString | null;
}

export interface Button extends EmptyButton {
    id: number;
    label: string;
    visibilityType: VisibilityType;
    locations: Locations[];
    order: number;
    parallelSession: ParallelSession | null;
    createdAt: Common.DateString;
}
