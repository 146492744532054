import React from 'react';
import Accounts from '../Accounts';
import Leads from '../Leads';
import { Tab, Tabs } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { withRouter, RouteComponentProps } from 'react-router';
import TabPanel from '../TabPanel';

const TAB_LEADS = 'leads';
const TAB_ACCOUNTS = 'accounts';
interface SuperadminTabsProps extends WithTranslation, WithSnackbarProps, RouteComponentProps {
    user: {
        timezone: string;
    };
}

interface SuperadminTabsState {
    activeTab: string;
}

class SuperadminTabs extends React.Component<SuperadminTabsProps, SuperadminTabsState> {
    constructor(props: SuperadminTabsProps) {
        super(props);
        this.state = {
            activeTab: TAB_ACCOUNTS,
        };
    }

    onTabChange = (_e: React.ChangeEvent<{}>, tab: string) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const { t, user } = this.props;
        const { activeTab } = this.state;
        return (
            <div>
                <Tabs indicatorColor="primary" value={activeTab} onChange={this.onTabChange} variant="scrollable">
                    <Tab value={TAB_ACCOUNTS} label={t('admin.tabs.accounts')} />
                    <Tab value={TAB_LEADS} label={t('admin.tabs.leads')} />
                </Tabs>
                <TabPanel value={activeTab} index={TAB_ACCOUNTS}>
                    {/*@ts-ignore*/}
                    <Accounts user={user} />
                </TabPanel>
                <TabPanel value={activeTab} index={TAB_LEADS}>
                    {/*@ts-ignore*/}
                    <Leads user={user} />
                </TabPanel>
            </div>
        );
    }
}

export default withTranslation()(withSnackbar(withRouter(SuperadminTabs)));
