import io from 'socket.io-client';
import { BackendApi } from '../api/BackendApi';
import dispatcher from './dispatcher';
import events from '../events';
import { userManager } from './UserManager';
import { SentryHandler } from '../handlers/SentryHandler';
import { Severity } from '@sentry/types/dist/severity';
// eslint-disable-next-line no-unused-vars
import OpenUrlManager from './workflow_actions/OpenUrlManager'; // Do not remove! Open URL event subscriber
import { logDebug } from '../utils';
import { sharedMapStateManager } from './SharedMapStateManager';

const WEBSOCKET_IO_SERVER_DISCONNECT_TIMEOUT = 1000;

class WSManager {
    constructor() {
        this.ioSocket = null;
        this.recipientId = null;
    }

    connect(getCredentials) {
        const clientId = BackendApi.getClientInstanceId();
        const { userId, accountId } = getCredentials();
        const recipientId = `${userId}:${accountId || ''}:${clientId || ''}`;
        this.recipientId = recipientId;

        this.ioSocket = io(window.params.wsEndpoint, {
            path: window.params.wsPath || '/socket.io',
            withCredentials: true,
            auth: {
                jwt: getCredentials().accessToken,
                recipientId: recipientId,
            },
        });
        this.ioSocket.on('connect', () => {
            if (this.ioSocket.connected) {
                dispatcher.dispatch(events.WEBSOCKET_IO_SERVER_CONNECT);
            }
        });
        this.ioSocket.on('message', (message) => {
            const { type, payload, delay, clientInstanceId } = message;
            logDebug(
                'message',
                type,
                payload,
                delay,
                clientInstanceId,
                'current instance id',
                clientId,
                'is shared map',
                sharedMapStateManager.isSharedMap(),
            );
            if (!!clientInstanceId && clientInstanceId !== clientId && sharedMapStateManager.isSharedMap()) {
                return;
            }
            // prevent account scheduled events persistent messages for shared map user
            if (!clientInstanceId && type === events.WS_PERSISTENT_MESSAGE && sharedMapStateManager.isSharedMap()) {
                return;
            }
            // todo: remove if exceeds sentry limits
            SentryHandler.addBreadcrumb({
                category: 'websocket',
                level: Severity.Debug,
                message: 'Got message',
                data: message,
            });
            switch (type) {
                case events.WS_MESSAGE:
                case events.WS_ENTITY_IMPORT_PROGRESS:
                case events.WS_ENTITIES_IMPORT_PROGRESS:
                case events.WS_ENTITY_RECORDS_ADDED:
                case events.WS_ENTITY_RECORDS_GEOCODE_LEFT:
                case events.WS_FIELD_PICKLIST_CHANGED:
                case events.WS_DS_METADATA_IMPORT:
                case events.WS_ENTITY_RECORDS_GEOCODED:
                case events.WS_PERSISTENT_MESSAGE:
                case events.WS_SEARCH_RECORDS_RESPONSE:
                case events.WS_UPDATE_RECORDS_RESPONSE:
                case events.WS_SUBSCRIPTION_UPDATED:
                case events.WS_USER_UPDATED:
                case events.WS_ENTITY_RECORDS_MODIFIED:
                case events.WS_ENTITY_RECORDS_DELETED:
                case events.WS_PROSPECT_IMPORT_ENDED:
                case events.WS_TERRITORIES_UPDATED:
                case events.WS_ROLES_UPDATED:
                case events.WS_UI_VERSION_UPDATED:
                case events.WS_TERRITORIES_GROUP_SUCCESS_CHANGE:
                case events.WS_TERRITORIES_GROUP_ERROR_CHANGE:
                case events.WS_CSV_EXPORT:
                case events.WS_SAVED_PLACE_CREATED:
                case events.WS_SAVED_PLACE_CHANGED:
                case events.WS_SAVED_PLACE_DELETED:
                case events.WS_ROUTES_CHANGED:
                case events.WS_ROUTING_BUILD_READY:
                case events.WS_ROUTING_REFRESH_READY:
                case events.WS_ROUTING_EDIT_READY:
                case events.WS_OPEN_URL:
                case events.WS_OPEN_FORM:
                case events.WS_UPDATE_FORM:
                case events.WS_INIT_FORM:
                case events.WS_CLOSE_FORM:
                case events.WS_FINISHED_WORKFLOW_CHAIN:
                case events.WS_WORKFLOW_ACTIONS_BUTTONS_UPDATED:
                case events.WS_ENTITIES_AVAILABLE_FOR_IMPORT_PROGRESS:
                case events.WS_ENTITIES_CHANGED:
                case events.WS_FRONTEND_EVENT:
                case events.WS_ROUTE_PATHS_BUILT:
                case events.WS_HISTORY_PATHS_BUILT:
                case events.WS_GEOCODER_RESPONSE:
                case events.WS_POINT_MARK_AS_VISITED:
                case events.WS_SESSION_TERMINATED:
                case events.WS_USERS_DELETED:
                case events.WS_FIX_SHARD_PROGRESS:
                case events.WS_SUCCESS_PERSISTENT:
                case events.WS_CALENDAR_EVENT_JOB_SUCCESS:
                case events.WS_CALENDAR_EVENT_JOB_ERROR:
                case events.WS_CALENDAR_CHANGE:
                case events.WS_CALENDAR_EVENTS_CHANGE:
                case events.WS_FILE_CONVERSION:
                case events.WS_FILE_SUMMARIZATION:
                case events.WS_FILE_TRANSCRIPTION:
                    if (delay?.duration) {
                        this.delayMessage(message);
                    } else {
                        dispatcher.dispatch(type, payload);
                    }
                    break;
                default:
                    console.error('Unknown message type', type, 'with payload', payload);
            }
        });
        this.ioSocket.on('reconnecting', (attemptNumber) => {
            /**
             * Fired upon an attempt to reconnect
             * @link https://socket.io/docs/v2/client-api/#event-reconnecting-1
             */
            console.warn('WS reconnecting', attemptNumber);
        });
        this.ioSocket.on('disconnect', (reason) => {
            console.warn('WS disconnect', reason);
            SentryHandler.addBreadcrumb({
                category: 'websocket',
                level: Severity.Debug,
                message: 'Disconnect',
                data: reason,
            });

            /**
             * The server has forcefully disconnected the socket with socket.disconnect()
             * e.g. when token is invalid or expired or token's payload is invalid (account id or user id doesn't match recipient id)
             * Client will not try to reestablish the connection in this case
             * @link https://socket.io/docs/v2/client-api/#event-disconnect
             */
            if (reason === 'io server disconnect') {
                this.disconnect();
                setTimeout(() => {
                    if (this.ioSocket) {
                        return;
                    }

                    dispatcher.dispatch(events.WEBSOCKET_IO_SERVER_DISCONNECT, userManager.getCurrentUser());
                }, WEBSOCKET_IO_SERVER_DISCONNECT_TIMEOUT);
            }
        });
    }

    disconnect() {
        this.recipientId = null;
        if (this.ioSocket) {
            this.ioSocket.disconnect();
            this.ioSocket = null;
        }
    }

    delayMessage = (message) => {
        const { duration, randomized } = message.delay;
        setTimeout(
            this.dispatchDelayedMessage.bind(this),
            randomized ? duration * Math.random() : duration,
            this.recipientId,
            message,
        );
    };

    dispatchDelayedMessage = (recipientId, { type, payload }) => {
        if (recipientId === this.recipientId) {
            dispatcher.dispatch(type, payload);
        }
    };
}

export default new WSManager();
