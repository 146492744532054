import React from 'react';
import './style.css';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Status from './Status';
import { formatMoney } from './MoneyFormat';
import ChangeSeatsForm from './ChangeSeatsForm';
import { paddleManager } from '../../service/PaddleManager';
import { intercomManager } from '../../service/IntercomManager';
import BillingWidget from './BillingWidget';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import LoadingButton from '../LoadingButton';
import { userManager } from '../../service/UserManager';
import ChangePlanForm from './ChangePlanForm';
import { withTranslation } from 'react-i18next';
import FormDialog from '../FormDialog';
import PlanAlert from './PlanAlert';
import Hint from '../Hint';
import { SubscriptionPlanTier, SubscriptionStatus } from 'service/types';
import ResumeSubscriptionAlert from './ResumeSubscriptionAlert';
import ChangeRoutingCreditsForm from './RoutingCredits/ChangeRoutingCreditsForm';
import RoutingCreditsHint from './RoutingCredits/RoutingCreditsHint';
import { formatWithCommas } from '../../utils';
import RoutingCreditsUsagePanel from './RoutingCredits/RoutingCreditsUsagePanel';
import AnalyticsUsagePanel from './Analytics/AnalyticsUsagePanel';
import AiTokensSubscriptionPanel from './AiTokens/AiTokensSubscriptionPanel';
import TranscriptionMinutesSubscriptionPanel from './TranscriptionMinutes/TranscriptionMinutesSubscriptionPanel';

export const truncateTh = (number) => {
    return Math.trunc(number / 1000);
};

class SubscriptionPanel extends BillingWidget {
    constructor(props) {
        super(props);
        this.state = {
            openChangePlan: false,
            openChangeSeats: false,
            openResumeSubscription: false,
            loading: false,
            openChangeRoutingCredits: false,
            showPlanAlert: false,
        };
        this.subManager = subscriptionManagerFactory.getManager(this.props.account.id);
    }

    isStatusWaiteSubscribe(status) {
        return (
            status === SubscriptionStatus.TRIAL ||
            status === SubscriptionStatus.DELETED ||
            status === SubscriptionStatus.CANCELLED
        );
    }

    componentDidMount() {
        const subscription = this.props.subscription;

        if (this.props.subscribeAutoStart && this.isStatusWaiteSubscribe(subscription.status)) {
            if (subscription.plan.tier === SubscriptionPlanTier.ESSENTIAL && !subscription.isCustomized) {
                this.handleOpenPlanAlert();
            } else {
                this.handleSubscribe();
            }
        }
    }

    makeTextTooltipPlan = (sub, plan) => {
        return (
            <React.Fragment>
                {this.props.t('billing.subscription_panel.tooltip_plan_first_line', {
                    price: formatMoney(sub.currency, plan.price),
                    period: this.props.t('billing.period_' + plan.period),
                })}
                <br />
                {this.props.t('billing.subscription_panel.tooltip_plan_second_line', {
                    recordCap: truncateTh(sub.recordCapFree),
                })}
                <br />
                {this.props.t('billing.subscription_panel.tooltip_plan_third_line', {
                    price: formatMoney(sub.currency, sub.recordChunkPmCur),
                    recordCap: truncateTh(sub.recordChunkSize),
                })}
            </React.Fragment>
        );
    };

    handleCloseChangePlanDialog = () => {
        this.setState({
            openChangePlan: false,
        });
    };

    handleOpenChangePlanDialog = () => {
        this.setState({
            openChangePlan: true,
        });
    };

    handleCloseChangeSeatsDialog = () => {
        this.setState({
            openChangeSeats: false,
        });
    };

    handleOpenChangeSeatsDialog = () => {
        this.setState({
            openChangeSeats: true,
        });
    };

    handleCloseChangeRoutingCreditsDialog = () => {
        this.setState({
            openChangeRoutingCredits: false,
        });
    };
    handleOpenChangeRoutingCreditsDialog = () => {
        const { subscription } = this.props;
        if ([SubscriptionStatus.ACTIVE, SubscriptionStatus.FREE].includes(subscription.status)) {
            this.setState({
                openChangeRoutingCredits: true,
            });
        } else {
            intercomManager.showNewMessage(this.props.t('billing.subscription_panel.change_routing_credits_intercom'));
        }
    };

    handleCloseResumeSubscriptionDialog = () => {
        this.setState({
            openResumeSubscription: false,
        });
    };

    handleOpenResumeSubscriptionDialog = () => {
        this.setState({
            openResumeSubscription: true,
        });
    };

    handleSubscribe = () => {
        const { account, user, subscription } = this.props;

        if (
            subscription.recordPmCur === 0 &&
            subscription.adminPmCur === 0 &&
            subscription.planerPmCur === 0 &&
            subscription.sharedMapsPmCur === 0 &&
            subscription.routingPmCur === 0
        ) {
            paddleManager.openWindowSubscribe(account, user, subscription);
            return;
        }

        this.subManager
            .createPaymentLink()
            .then((response) => {
                paddleManager.openWindowSubscribe(account, user, subscription, response.link);
            })
            .catch((error) => {
                this.props.enqueueSnackbar && this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });

        this.setState({
            loading: true,
        });
    };

    handleClosePlanAlert = () => {
        this.setState({
            showPlanAlert: false,
        });
    };

    handleOpenPlanAlert = () => {
        this.setState({
            showPlanAlert: true,
        });
    };

    handelCancelSubscription = () => {
        const isSwitchedSuperAdmin = userManager.isCurrentUser(this.props.user) && userManager.isSwitchedSuperAdmin();
        if (isSwitchedSuperAdmin) {
            paddleManager.openWindowWithOverloadLink(this.props.account, this.props.subscription.cancelUrl);
            return;
        }
        intercomManager.showNewMessage(this.props.t('billing.message_cancel_subscription'));
    };

    handleChangeSelectPlan = (plan) => {
        const subscription = this.props.subscription;
        if (subscription.plan.tier !== SubscriptionPlanTier.ESSENTIAL && plan.tier === SubscriptionPlanTier.ESSENTIAL) {
            this.setState({
                showPlanAlert: true,
            });
        }
    };

    buildPlanAlert = () => {
        const subscription = this.props.subscription;
        const isEssential = subscription.plan.tier === SubscriptionPlanTier.ESSENTIAL;
        const buttonTitle = isEssential
            ? this.props.t('billing.plan_alert.proceed_with_essential')
            : this.props.t('billing.plan_alert.downgrade_with_essential');
        const titleDialog = this.props.t(
            subscription.status === SubscriptionStatus.TRIAL && isEssential
                ? 'billing.plan_alert.do_more_with_mapsly_pro_plan'
                : 'billing.plan_alert.downgrade_confirmation',
        );
        const action = isEssential
            ? () => {
                  this.handleClosePlanAlert();
                  this.handleSubscribe();
              }
            : () => {
                  this.handleClosePlanAlert();
              };
        let title = this.props.t('billing.change_plan_status_other');
        if (subscription.status === SubscriptionStatus.TRIAL) {
            title = isEssential
                ? this.props.t('billing.plan_alert_status_trial_ess')
                : this.props.t('billing.plan_alert_status_trial_pro');
        }

        return (
            this.state.showPlanAlert && (
                <FormDialog
                    dialogClassName="plan-alert"
                    title={titleDialog}
                    maxWidth="md"
                    onCancel={() => {
                        this.handleClosePlanAlert();
                        this.handleCloseChangePlanDialog();
                    }}
                    blocks={{
                        actionRightBlock: (
                            <>
                                <Button
                                    color="primary"
                                    onClick={action}
                                    data-testid="billing.plan_alert.proceed_with_essential"
                                >
                                    {buttonTitle}
                                </Button>
                                {isEssential && (
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.handleClosePlanAlert();
                                            this.handleOpenChangePlanDialog();
                                        }}
                                        data-testid="billing.plan_alert.upgrade_to_pro"
                                    >
                                        {this.props.t('billing.plan_alert.upgrade_to_pro')}
                                    </Button>
                                )}
                            </>
                        ),
                    }}
                >
                    <PlanAlert title={title} />
                </FormDialog>
            )
        );
    };

    render() {
        const { account, subscription, user } = this.props;
        const plan = subscription.plan;
        const isSwitchedSuperAdmin = userManager.isCurrentUser(user) && userManager.isSwitchedSuperAdmin();

        let disabledChangePlan = false;
        let disabledChangeSeats = false;
        let tooltipButtonPlan = '';
        let tooltipTextSeatsButton = '';
        let tooltipTextQuestion = '';

        if (subscription.isCustomized === true && !isSwitchedSuperAdmin) {
            disabledChangePlan = true;
            tooltipButtonPlan = this.props.t('billing.subscription_panel.disallow_change_plan');
        }

        if (subscription.usersAllowed !== subscription.usersPurchased) {
            disabledChangeSeats = true;
            tooltipTextSeatsButton = this.props.t('billing.subscription_panel.disallow_change_seats');

            tooltipTextQuestion = this.props.t('billing.subscription_panel.disallow_change_seats_tooltip', {
                usersPurchased: subscription.usersPurchased,
                usersAllowed: subscription.usersAllowed,
            });
        }

        if (subscription.status === SubscriptionStatus.PAST_DUE) {
            disabledChangePlan = true;
            disabledChangeSeats = true;
            tooltipButtonPlan = this.props.t('billing.subscription_panel.disallow_change_plan_tooltip_payment_overdue');
            tooltipTextSeatsButton = this.props.t(
                'billing.subscription_panel.disallow_change_seats_tooltip_payment_overdue',
            );
        }

        let tooltipPlan = this.makeTextTooltipPlan(subscription, plan);
        const nameMainButton = this.makeMainButtonName(subscription);
        const isEssential = plan.tier === SubscriptionPlanTier.ESSENTIAL;
        const isCustomStatus = subscription.status === SubscriptionStatus.CUSTOM;
        if (isCustomStatus) {
            disabledChangePlan = true;
            disabledChangeSeats = true;
        }
        const planAlertModal = this.buildPlanAlert();
        const dailyMax =
            subscription.additionalRoutingCredits +
            subscription.additionalCustomRoutingCredits +
            plan.routingCredits * subscription.usersAllowed;

        return (
            <div className="c-subscription-panel">
                <h2>{this.props.t('billing.title')}</h2>
                <Grid className="row" container alignItems="center" direction="row" justify="center">
                    <Grid item xs={3}>
                        <span>{this.props.t('billing.plan')}:</span>
                    </Grid>
                    <Grid item xs>
                        <span className="title">{plan.name}</span>
                        {subscription.isCustomized === true && (
                            <div className="tag">
                                <Status status="customized" color="green" small />
                            </div>
                        )}
                        {!isCustomStatus && (
                            <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                {tooltipPlan}
                            </Hint>
                        )}
                    </Grid>
                    <Grid item xs>
                        <Tooltip title={tooltipButtonPlan} className="change-plan-button">
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={this.handleOpenChangePlanDialog}
                                    disabled={disabledChangePlan}
                                    data-testid="button.change_plan"
                                >
                                    {this.props.t('button.change')}
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center" direction="row" justify="center">
                    <Grid item xs={3}>
                        <span>{this.props.t('billing.seats')}:</span>
                    </Grid>
                    <Grid item xs>
                        {subscription.usersAllowed !== subscription.usersPurchased && (
                            <React.Fragment>
                                <span className="strikethrough">{subscription.usersPurchased}</span>
                                <span className="title">{subscription.usersAllowed}</span>
                                <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                    {tooltipTextQuestion}
                                </Hint>
                            </React.Fragment>
                        )}
                        {subscription.usersAllowed === subscription.usersPurchased && (
                            <span className="title">{subscription.usersAllowed}</span>
                        )}
                    </Grid>
                    <Grid item xs>
                        <Tooltip title={tooltipTextSeatsButton} className="change-seats-button">
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={this.handleOpenChangeSeatsDialog}
                                    disabled={disabledChangeSeats}
                                    data-testid="button.change_seats"
                                >
                                    {this.props.t('button.change')}
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center" direction="row" justify="center">
                    <Grid item xs={3}>
                        <span>{this.props.t('billing.routing_credits')}:</span>
                    </Grid>
                    <Grid item xs>
                        <span className="title">{formatWithCommas(dailyMax)}</span>
                        <RoutingCreditsHint totalCredits={dailyMax} subscription={subscription} />
                    </Grid>
                    <Grid item xs>
                        <Button
                            variant="contained"
                            onClick={this.handleOpenChangeRoutingCreditsDialog}
                            data-testid="button.change_routing_credits"
                        >
                            <Typography noWrap variant="inherit">
                                {this.props.t('billing.button.change_routing_credits')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <RoutingCreditsUsagePanel
                    account={account}
                    user={user}
                    dailyMax={dailyMax}
                    todayUsage={dailyMax - subscription.availableRoutingCredits}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                />
                <AiTokensSubscriptionPanel
                    subscription={subscription}
                    account={account}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                />
                <TranscriptionMinutesSubscriptionPanel
                    subscription={subscription}
                    account={account}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                />
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span>{this.props.t('billing.status')}:</span>
                    </Grid>
                    <Grid item xs>
                        <Status status={subscription.status} />
                    </Grid>
                    <Grid item xs>
                        {subscription.status === SubscriptionStatus.TRIAL && (
                            <span>
                                {this.props.t(
                                    'billing.message_trial_' + (subscription.trialDays === 1 ? 'day' : 'days'),
                                    {
                                        count: subscription.trialDays,
                                    },
                                )}
                            </span>
                        )}
                        {(subscription.status === SubscriptionStatus.ACTIVE ||
                            subscription.status === SubscriptionStatus.PAST_DUE) && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handelCancelSubscription}
                                data-testid="button.cancel_subscription"
                            >
                                {this.props.t('button.cancel')}
                            </Button>
                        )}
                        {subscription.status === SubscriptionStatus.PAUSED && (
                            <Button
                                variant="contained"
                                className="main-button"
                                onClick={this.handleOpenResumeSubscriptionDialog}
                                data-testid="button.resume_subscription"
                            >
                                {this.props.t('billing.button_resume')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <AnalyticsUsagePanel subscription={subscription} />
                <Grid className="row" container alignItems="center">
                    <Grid item xs={2}></Grid>
                    <Grid item xs>
                        {nameMainButton !== null && (
                            <LoadingButton
                                className="main-button paddle_button"
                                onClick={
                                    isEssential && !subscription.isCustomized
                                        ? this.handleOpenPlanAlert
                                        : this.handleSubscribe
                                }
                                variant="contained"
                                loading={this.state.loading}
                                data-testid="paddle_button.main_button"
                            >
                                {nameMainButton}
                            </LoadingButton>
                        )}
                    </Grid>
                </Grid>
                {this.state.openChangePlan && (
                    <ChangePlanForm
                        account={account}
                        user={this.props.user}
                        subscription={subscription}
                        onChangeSelectPlan={this.handleChangeSelectPlan}
                        onCloseDialog={this.handleCloseChangePlanDialog}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                )}
                {this.state.openChangeSeats && (
                    <ChangeSeatsForm
                        account={account}
                        user={this.props.user}
                        subscription={subscription}
                        onCloseDialog={this.handleCloseChangeSeatsDialog}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                )}
                {this.state.openChangeRoutingCredits && (
                    <ChangeRoutingCreditsForm
                        account={account}
                        subscription={subscription}
                        onCloseDialog={this.handleCloseChangeRoutingCreditsDialog}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                )}
                {this.state.openResumeSubscription && (
                    <ResumeSubscriptionAlert
                        account={account}
                        user={this.props.user}
                        subscription={subscription}
                        onCloseDialog={this.handleCloseResumeSubscriptionDialog}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                )}
                {planAlertModal}
            </div>
        );
    }
}

SubscriptionPanel.propTypes = {
    account: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func,
    subscribeAutoStart: PropTypes.bool,
};

SubscriptionPanel.defaultProps = {
    subscribeAutoStart: false,
};

export default withTranslation()(SubscriptionPanel);
