import moment from 'moment/moment';
import { FilterOperation } from '@devexpress/dx-react-grid';

export interface DateTimeRangeFilterValue {
    dateFrom?: Date | null;
    dateTo?: Date | null;
}

export interface Filter {
    columnName: string;
    operation?: FilterOperation;
    value?: DateTimeRangeFilterValue | string | string[] | number | number[] | null;
}

export function isDateTimeRangeFilter(filter: Filter): filter is DateTimeRangeFilter {
    return (
        typeof filter.value === 'object' &&
        ((filter as DateTimeRangeFilter).value?.dateFrom !== undefined ||
            (filter as DateTimeRangeFilter).value?.dateTo !== undefined)
    );
}
export interface DateTimeRangeFilter extends Filter {
    value?: DateTimeRangeFilterValue;
}

export const getNumericFilterValue = (filter: Filter | null) => {
    if (filter?.operation === OPERATOR_IN && Array.isArray(filter?.value)) {
        return filter.value;
    }

    if (filter === null || typeof filter.value !== 'string') {
        return null;
    }

    const regexp = /^-?\d+(\.\d+)?$/;

    if (filter.operation === OPERATOR_IN) {
        if (!Array.isArray(filter.value)) {
            throw new Error();
        }
        return filter.value;
    }

    if (filter.operation !== OPERATOR_BETWEEN) {
        const value = filter.value.trim();
        if (!value.match(regexp)) {
            throw new Error();
        }
        return parseFloat(value);
    }

    let limits = filter.value.split(',');
    if (limits.length !== 2) {
        throw new Error();
    }
    const value1 = limits[0].trim();
    const value2 = limits[1].trim();
    if (!value1.match(regexp) || !value2.match(regexp)) {
        throw new Error();
    }
    return [parseFloat(value1), parseFloat(value2)];
};

export const getDateFilterValue = (filter: Filter | null) => {
    if (filter === null || typeof filter.value !== 'string') {
        return null;
    }

    const outputFormat = 'YYYY-MM-DD';

    const formats = ['YYYY-MM-DD'];
    if (filter.operation !== OPERATOR_BETWEEN) {
        const m = moment(filter.value.trim(), formats, true);
        if (!m.isValid()) {
            throw new Error();
        }
        return m.format(outputFormat);
    }

    let limits = filter.value.split(',');
    if (limits.length !== 2) {
        throw new Error();
    }
    const m1 = moment(limits[0].trim(), formats, true);
    const m2 = moment(limits[1].trim(), formats, true);
    if (!m1.isValid() || !m2.isValid()) {
        throw new Error();
    }
    return [m1.format(outputFormat), m2.format(outputFormat)];
};

export const getDateTimeFilterValue = (filter: Filter | null) => {
    if (filter === null || typeof filter.value !== 'string') {
        return null;
    }

    const outputFormat = 'YYYY-MM-DD HH:mm:ss';

    const formats = ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD'];
    if (filter.operation !== OPERATOR_BETWEEN) {
        const m = moment(filter.value.trim(), formats, true);
        if (!m.isValid()) {
            throw new Error();
        }
        return m.format(outputFormat);
    }

    let limits = filter.value.split(',');
    if (limits.length !== 2) {
        throw new Error();
    }
    const m1 = moment(limits[0].trim(), formats, true);
    const m2 = moment(limits[1].trim(), formats, true);
    if (!m1.isValid() || !m2.isValid()) {
        throw new Error();
    }
    return [m1.format(outputFormat), m2.format(outputFormat)];
};

export const OPERATOR_EQUAL = 'equal';
export const OPERATOR_NOT_EQUAL = 'notEqual';
export const OPERATOR_GREATER_THAN = 'greaterThan';
export const OPERATOR_GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual';
export const OPERATOR_LESS_THAN = 'lessThan';
export const OPERATOR_LESS_THAN_OR_EQUAL = 'lessThanOrEqual';
export const OPERATOR_BETWEEN = 'between';
export const OPERATOR_CONTAINS = 'contains';
export const OPERATOR_NOT_CONTAINS = 'notContains';
export const OPERATOR_STARTS_WITH = 'startsWith';
export const OPERATOR_ENDS_WITH = 'endsWith';
export const OPERATOR_IS_EMPTY = 'isEmpty';
export const OPERATOR_IS_NOT_EMPTY = 'isNotEmpty';
export const OPERATOR_IN = 'in';
export const OPERATOR_JSONB_KEY_EXISTS = 'jsonbKeyExists';

export const INTEGRATED_FILTERS = [
    OPERATOR_CONTAINS,
    OPERATOR_NOT_CONTAINS,
    OPERATOR_STARTS_WITH,
    OPERATOR_ENDS_WITH,
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    OPERATOR_GREATER_THAN,
    OPERATOR_GREATER_THAN_OR_EQUAL,
    OPERATOR_LESS_THAN,
    OPERATOR_LESS_THAN_OR_EQUAL,
];

export const NUMERIC_FILTER_OPERATIONS = [
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    OPERATOR_GREATER_THAN,
    OPERATOR_GREATER_THAN_OR_EQUAL,
    OPERATOR_LESS_THAN,
    OPERATOR_LESS_THAN_OR_EQUAL,
    OPERATOR_BETWEEN,
];

export const DISTANCE_FILTER_OPERATIONS = [
    OPERATOR_GREATER_THAN_OR_EQUAL,
    OPERATOR_LESS_THAN_OR_EQUAL,
    OPERATOR_BETWEEN,
];

export const STRING_FILTER_OPERATIONS = [
    OPERATOR_CONTAINS,
    OPERATOR_NOT_CONTAINS,
    OPERATOR_STARTS_WITH,
    OPERATOR_ENDS_WITH,
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    OPERATOR_IS_EMPTY,
    OPERATOR_IS_NOT_EMPTY,
];

export const MULTI_LOOKUP_FILTER_OPERATIONS = [
    OPERATOR_CONTAINS,
    OPERATOR_NOT_CONTAINS,
    OPERATOR_IS_EMPTY,
    OPERATOR_IS_NOT_EMPTY,
];

export const POLYMORPHIC_LOOKUP_FILTER_OPERATIONS = [OPERATOR_CONTAINS, OPERATOR_NOT_CONTAINS];

export const FOREIGN_LOOKUP_FILTER_OPERATIONS = [OPERATOR_CONTAINS, OPERATOR_NOT_CONTAINS];

export const BOOLEAN_FILTER_OPERATIONS = [OPERATOR_EQUAL];

export const DATE_FILTER_OPERATIONS = [
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    OPERATOR_GREATER_THAN,
    OPERATOR_GREATER_THAN_OR_EQUAL,
    OPERATOR_LESS_THAN,
    OPERATOR_LESS_THAN_OR_EQUAL,
    OPERATOR_BETWEEN,
    OPERATOR_IS_EMPTY,
    OPERATOR_IS_NOT_EMPTY,
];
