import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Transcript } from 'interfaces/file';
import TranscriptItemHeader from './TranscriptItemHeader';
import TranscriptItemContent from './TranscriptItemContent';

interface TranscriptItemProps extends WithTranslation {
    item: Transcript.TranscriptItem;
    ordinalNumber: number;
    speakers: Map<number, Transcript.TranscriptSpeaker>;
    editMode: boolean;

    onRewind: (time: number) => void;
    onAddItem: (ordinalNumber: number, parent: Transcript.TranscriptItem) => void;
    onUpdate: (item: Transcript.TranscriptItem, ordinalNumber: number) => Promise<void>;
}

interface TranscriptItemState {
    item: Transcript.TranscriptItem;
    saving: boolean;
}

class TranscriptItem extends React.Component<TranscriptItemProps, TranscriptItemState> {
    constructor(props: TranscriptItemProps) {
        super(props);

        this.state = {
            item: { ...props.item },
            saving: false,
        };
    }

    addItemAbove = () => {
        this.props.onAddItem(this.props.ordinalNumber, this.props.item);
    };

    addItemBelow = () => {
        this.props.onAddItem(this.props.ordinalNumber + 1, this.props.item);
    };

    updateItem = () => {
        this.setState({ saving: true });
        this.props.onUpdate(this.state.item, this.props.ordinalNumber).finally(() => {
            this.setState({ saving: false });
        });
    };

    handleContentChange = (content: string) => {
        this.setState((state) => {
            return { item: { ...state.item, content } };
        }, this.updateItem);
    };

    getSpeakerLabel = (item: Transcript.TranscriptItem) => {
        return this.props.speakers.has(item.speaker.id)
            ? this.props.speakers.get(item.speaker.id)!.label
            : item.speaker.label;
    };

    render() {
        const { item, saving } = this.state;
        const { editMode, speakers } = this.props;

        return (
            <div className={'transcript-item' + (editMode ? ' transcript-item--edit-mode' : '')}>
                <TranscriptItemHeader
                    item={item}
                    speakers={speakers}
                    editMode={editMode}
                    saving={saving}
                    onRewind={this.props.onRewind}
                    onAddItemAbove={this.addItemAbove}
                    onAddItemBelow={this.addItemBelow}
                />
                <TranscriptItemContent
                    item={item}
                    editMode={editMode}
                    onChange={this.handleContentChange}
                    disabled={saving}
                />
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(TranscriptItem);
