import PropTypes from 'prop-types';
import React from 'react';
import { formatWithCommas } from '../../utils';

const CURRENCY_PREFIX = {
    USD: '$',
    EUR: '€',
    CAD: 'CA$',
    AUD: 'A$',
    BRL: 'R$',
    CNY: 'CN¥',
    HKD: 'HK$',
    INR: '₹',
    JPY: '¥',
    MXN: 'MX$',
    TWD: 'NT$',
    NZD: 'NZ$',
    GBP: '£',
    KRW: '₩',
};

export const formatMoney = (currency, price) => {
    price = Math.floor(price * 100) / 100;

    if (CURRENCY_PREFIX[currency] !== undefined) {
        return (price < 0 ? '-' : '') + CURRENCY_PREFIX[currency] + Math.abs(price);
    }

    return price + ' ' + currency;
};

export const formatMoneyWithCommas = (currency, price) => {
    price = Math.floor(price * 100) / 100;

    if (CURRENCY_PREFIX[currency] !== undefined) {
        return (price < 0 ? '-' : '') + CURRENCY_PREFIX[currency] + formatWithCommas(Math.abs(price));
    }

    return formatWithCommas(price) + ' ' + currency;
};

export class MoneyFormat extends React.PureComponent {
    render() {
        return formatMoney(this.props.currency, this.props.price);
    }
}

MoneyFormat.propTypes = {
    currency: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
};
