import React from 'react';

import { Grid, Table, TableHeaderRow, TableFilterRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import { FilteringState, PagingState, SortingState, DataTypeProvider, CustomPaging } from '@devexpress/dx-react-grid';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { accountsManager } from '../../service/AccountsManager';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { default as LayoutGrid } from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { routes, reverse } from '../../routes';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import { TableLoadingState } from '../TableLoadingState';
import FormDialog from '../FormDialog';
import AccountMainForm from '../AccountMainForm';
import { getDateTimeFilterValue } from '../utils/tableFilter';
import { userTimezoneToUtc, formatWithCommas, DATETIME_FORMAT_NO_SECONDS, DATETIME_FORMAT_NO_TIME } from '../../utils';
import './style.css';
import {
    DATA_SOURCE_ERROR,
    DATA_SOURCE_NOT_CONNECTED,
    DATA_SOURCE_TEMP_ERROR,
    DATA_SOURCE_DEACTIVATED,
    getDataSourceStatus,
} from '../AccountPage/DataSourceIndicator';
import { PicklistFilterCell, AdapterFilterCell } from '../DataTable';
import Link from 'react-router-dom/Link';
import { formatMoneyWithCommas } from '../Billing/MoneyFormat';
import { withTranslation } from 'react-i18next';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import DateTime from '../DateTime';
import ImportMessageComparator from '../../service/ImportMessages/Comparator';
import { SubscriptionStatus } from '../../service/types';
import LoadingButton from '../LoadingButton';

const FilterIcon = ({ type, ...restProps }) => {
    return <TableFilterRow.Icon type={type} {...restProps} />;
};

const FilterCell = (props) => {
    const { column } = props;
    const { t, i18n, tReady, ...rest } = props;
    if (column.name === 'status') {
        const options = [
            {
                value: 'free',
                label: t('free'),
            },
            {
                value: 'custom',
                label: t('custom'),
            },
            {
                value: 'active',
                label: t('active'),
            },
            {
                value: 'trial',
                label: t('trial'),
            },
            {
                value: 'past_due',
                label: t('past_due'),
            },
            {
                value: 'deleted',
                label: t('deleted'),
            },
            {
                value: 'cancelled',
                label: t('cancelled'),
            },
            {
                value: SubscriptionStatus.PAUSED,
                label: t('billing.status_paused'),
            },
        ];
        const columnPicklist = { ...column, picklist: options };
        return <PicklistFilterCell {...rest} column={columnPicklist} />;
    }
    if (column.name === 'planName') {
        const options = [
            {
                value: 'Enterprise',
                label: 'Enterprise',
            },
            {
                value: 'Essential',
                label: 'Essential',
            },
            {
                value: 'Pro',
                label: 'Pro',
            },
        ];
        const columnPicklist = { ...column, picklist: options };
        return <PicklistFilterCell {...rest} column={columnPicklist} />;
    }
    if (column.name === 'accountHealth') {
        const options = [
            {
                value: 'ok',
                label: t('account_health.ok'),
            },
            {
                value: 'info',
                label: t('account_health.info'),
            },
            {
                value: 'warning',
                label: t('account_health.warning'),
            },
            {
                value: 'error',
                label: t('account_health.error'),
            },
        ];
        const columnPicklist = { ...column, picklist: options };
        return <PicklistFilterCell {...rest} column={columnPicklist} />;
    }
    if (column.name === 'sourceTypes') {
        return <AdapterFilterCell {...rest} />;
    }
    return <TableFilterRow.Cell {...rest} />;
};

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            requestParams: {
                sorting: [{ columnName: 'createdAt', direction: 'desc' }],
                filters: [],
                currentPage: 0,
                pageSize: 10,
                accountType: 'real', // test
                accountState: 'live', // removed
            },
            rows: [],
            geocodedCounters: {},
            totalCount: 0,
            currentAccount: null,
            monthlyRecurringRevenue: null,
            loadingAccounts: false,
            loadingUsers: false,
        };

        this.columns = [
            {
                name: 'name',
                title: this.props.t('name'),
                getCellValue: (row) => (
                    <Link to={reverse(routes.admin.account.index, { accountId: row.id })}>{row.name}</Link>
                ),
            },
            {
                name: 'countryCode',
                title: this.props.t('country'),
                getCellValue: (row) => {
                    if (!row.timezoneOffset) {
                        return <div>{row.countryCode}</div>;
                    }
                    const timezoneOffset = 'UTC' === row.timezone ? 'UTC' : 'GMT' + row.timezoneOffset / 60;
                    return (
                        <div>
                            {row.countryCode} {'(' + timezoneOffset + ')'}
                        </div>
                    );
                },
            },
            {
                name: 'createdAt',
                title: this.props.t('created'),
                getCellValue: (row) => {
                    return (
                        <div>
                            <Tooltip title={<DateTime format={DATETIME_FORMAT_NO_SECONDS}>{row.createdAt}</DateTime>}>
                                <span>
                                    <DateTime format={DATETIME_FORMAT_NO_TIME}>{row.createdAt}</DateTime>
                                </span>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                name: 'lastActiveAt',
                title: this.props.t('last_active'),
                getCellValue: (row) => {
                    return (
                        <div>
                            {!!row.lastActiveAt && (
                                <Tooltip
                                    title={<DateTime format={DATETIME_FORMAT_NO_SECONDS}>{row.lastActiveAt}</DateTime>}
                                >
                                    <span>
                                        <DateTime format={DATETIME_FORMAT_NO_TIME}>{row.lastActiveAt}</DateTime>
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'status',
                title: this.props.t('status'),
                getCellValue: (row) => {
                    const { subscription } = row;
                    if (!subscription) {
                        return this.props.t('n_a');
                    }
                    let indicator = null;
                    switch (subscription.status) {
                        case SubscriptionStatus.TRIAL:
                            indicator = <span className="status-indicator blue" />;
                            break;
                        case SubscriptionStatus.CUSTOM:
                        case SubscriptionStatus.ACTIVE:
                        case SubscriptionStatus.FREE:
                            indicator = <span className="status-indicator green" />;
                            break;
                        case SubscriptionStatus.PAST_DUE:
                        case SubscriptionStatus.CANCELLED:
                        case SubscriptionStatus.DELETED:
                        case SubscriptionStatus.PAUSED:
                            indicator = <span className="status-indicator red" />;
                            break;
                        default:
                            break;
                    }
                    return (
                        <span>
                            {indicator}
                            {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                        </span>
                    );
                },
            },
            {
                name: 'planName',
                title: this.props.t('plan_name'),
                getCellValue: (row) => {
                    const { subscription } = row;

                    if (!subscription) {
                        return null;
                    }

                    return subscription.plan.name;
                },
            },
            {
                name: 'subPriceTotalUsd',
                title: this.props.t('mrr'),
                getCellValue: (row) => {
                    const { subscription } = row;
                    if (!subscription) {
                        return null;
                    }

                    return subscription.subPriceTotalUsd;
                },
            },
            {
                name: 'trialDays',
                title: this.props.t('trial_days'),
                getCellValue: (row) => {
                    const { subscription } = row;
                    if (!subscription) {
                        return null;
                    }
                    if (subscription.status === SubscriptionStatus.TRIAL) {
                        return subscription.nonThresholdTrialDays;
                    }
                    return null;
                },
            },
            { name: 'usersCount', title: this.props.t('users') },
            {
                name: 'sourcesCount',
                title: this.props.t('sources'),
                getCellValue: (row) => {
                    if (!row.sourcesCount) {
                        return 0;
                    }
                    let connectionError = false;
                    let connectionTempError = false;
                    for (let ds of row.dataSources) {
                        if (ds.isSystem) {
                            continue;
                        }
                        const status = getDataSourceStatus(ds);
                        if (
                            status === DATA_SOURCE_DEACTIVATED ||
                            status === DATA_SOURCE_ERROR ||
                            status === DATA_SOURCE_NOT_CONNECTED
                        ) {
                            connectionError = true;
                            break;
                        }
                        if (status === DATA_SOURCE_TEMP_ERROR) {
                            connectionTempError = true;
                        }
                    }
                    let indicator = <span className="connection-indicator on" />;
                    if (connectionError) {
                        indicator = <span className="connection-indicator off" />;
                    } else if (connectionTempError) {
                        indicator = <span className="connection-indicator suspended" />;
                    }
                    return (
                        <span>
                            {indicator} {row.sourcesCount}
                        </span>
                    );
                },
            },
            {
                name: 'accountHealth',
                title: this.props.t('account_health'),
                getCellValue: (row) => {
                    const levels = row.dataSources
                        .map((ds) => ds.importMessageMaxLevel)
                        .filter((level) => !!level)
                        .sort(ImportMessageComparator.compareLevelPriority);
                    const commonLevel = levels.length === 0 ? 'ok' : levels[0];
                    return (
                        <div className={'account-health-' + commonLevel}>
                            {this.props.t('account_health.' + commonLevel)}
                        </div>
                    );
                },
            },
            {
                name: 'sourceTypes',
                title: this.props.t('sourceTypes'),
                getCellValue: (row) => {
                    let sources = new Set();
                    for (let ds of row.dataSources.sort((ds1, ds2) => ds1.isSystem - ds2.isSystem)) {
                        sources.add(ds.adapterName || ds.name);
                    }
                    let shortTypesString = '';
                    if (sources.size > 0) {
                        shortTypesString = sources.values().next().value;
                    }
                    if (sources.size > 1) {
                        shortTypesString += ', ...';
                    }
                    return (
                        <div className="source-types">
                            <Tooltip title={[...sources].join(', ')}>
                                <span>{shortTypesString}</span>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                name: 'recordsCount',
                title: this.props.t('records'),
                getCellValue: (row) => {
                    return <div style={{ textAlign: 'right' }}>{formatWithCommas(row.recordsCount)}</div>;
                },
            },
            {
                name: 'geocodedCounters',
                title: this.props.t('geocoded'),
                getCellValue: (row) => {
                    let geocodedCounters = this.state.geocodedCounters[row.id];
                    if (geocodedCounters === undefined) {
                        geocodedCounters = row.geocodedCounters;
                    }
                    const { t } = this.props;
                    return (
                        <div className="geocoded-counters">
                            <Tooltip title={t('geocoder.processing')}>
                                <span style={{ color: 'gray' }}>{formatWithCommas(geocodedCounters.processing)}</span>
                            </Tooltip>
                            <Tooltip title={t('geocoder.ok_exact')}>
                                <span style={{ color: 'green' }}>{formatWithCommas(geocodedCounters.ok)}</span>
                            </Tooltip>
                            <Tooltip title={t('geocoder.ok_approx')}>
                                <span style={{ color: 'black' }}>{formatWithCommas(geocodedCounters.doubt)}</span>
                            </Tooltip>
                            <Tooltip title={t('geocoder.ok_imported')}>
                                <span style={{ color: 'black' }}>{formatWithCommas(geocodedCounters.imported)}</span>
                            </Tooltip>
                            <Tooltip title={t('geocoder.no_address')}>
                                <span style={{ color: 'orange' }}>{formatWithCommas(geocodedCounters.noAddress)}</span>
                            </Tooltip>
                            <Tooltip title={t('geocoder.invalid_address')}>
                                <span style={{ color: 'red' }}>{formatWithCommas(geocodedCounters.invalid)}</span>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                name: 'contact',
                title: this.props.t('contact'),
                getCellValue: (row) => {
                    const { address, email, admins } = row;
                    const admin = admins.length ? admins[0].name : null;
                    const contacts = [admin, email].filter((v) => !!v).join(', ');
                    return (
                        <div>
                            {address ? <div>{address}</div> : ''}
                            <div>{contacts}</div>
                        </div>
                    );
                },
            },
        ];

        this.numericColumns = ['usersCount', 'sourcesCount', 'recordsCount', 'trialDays', 'subPriceTotalUsd'];

        this.numericFilterOperations = [
            'equal',
            'notEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
        ];

        this.dateColumns = ['createdAt', 'lastActiveAt'];
        this.dateFilterOperations = [
            'equal',
            'notEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
            'between',
        ];

        this.timeToApplyFilters = null;
        this.filterDelay = 500;

        this.pageSizes = [10, 25, 50];

        this.refreshCountersInterval = null;
    }

    handleOpen = (accountId) => () => {
        if (accountId === 0) {
            this.setState({
                currentAccount: accountsManager.getDefaultAccount(),
            });
            return;
        }
        accountsManager
            .load(accountId)
            .then((account) => {
                this.setState({
                    currentAccount: { ...account },
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    handleClose = () => {
        this.setState({
            currentAccount: null,
        });
    };

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            for (let filter of this.state.requestParams.filters) {
                if (this.dateColumns.includes(filter.columnName) && !!filter.value) {
                    this.loadAccountsList();
                    return;
                }
            }
        });
        dispatcher.subscribe(
            [
                events.EVENT_USER_CREATED,
                events.EVENT_DATA_SOURCE_CREATED,
                events.EVENT_DATA_SOURCE_DELETED,
                events.WS_USERS_DELETED,
            ],
            this,
            () => {
                this.loadAccountsList();
            },
        );

        this.loadAccountsList();

        this.refreshCountersInterval = setInterval(this.refreshCounters, 30 * 1000);
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
        clearInterval(this.refreshCountersInterval);
    }

    refreshCounters = () => {
        if (this.state.loading) {
            return;
        }
        const accountIds = [];
        this.state.rows.map((row) => accountIds.push(row.id));
        accountsManager
            .getGeocodedCounters(accountIds)
            .then((items) => {
                this.setState({
                    geocodedCounters: this.getGeocodedCounters(items),
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    handleChangeSorting = (sorting) => {
        this.loadAccountsList({ sorting });
    };

    convertFrontFilterToServerFilter(filter) {
        if (this.dateColumns.includes(filter.columnName)) {
            const result = { ...filter };
            result.value = getDateTimeFilterValue(filter);
            if (Array.isArray(result.value)) {
                result.value = result.value.map((v) => userTimezoneToUtc(v, this.props.user));
            } else {
                result.value = userTimezoneToUtc(result.value, this.props.user);
            }
            return result;
        }
        return filter;
    }

    applyFilters(filters) {
        const d = new Date();
        if (d.getTime() >= this.timeToApplyFilters) {
            this.loadAccountsList({ filters });
        }
    }

    handleChangeFilters = (filters) => {
        const d = new Date();
        this.timeToApplyFilters = d.getTime() + this.filterDelay;
        setTimeout(() => {
            this.applyFilters(filters);
        }, this.filterDelay);
    };

    handleChangeCurrentPage = (currentPage) => {
        this.loadAccountsList({ currentPage });
    };

    handleChangePageSize = (pageSize) => {
        this.loadAccountsList({ pageSize });
    };

    handleChangeAccountType = (event) => {
        const value = event.target.value;
        this.loadAccountsList({ accountType: value });
    };

    handleChangeAccountState = (event) => {
        const value = event.target.value;
        this.loadAccountsList({ accountState: value });
    };

    loadAccountsList(requestParams = {}) {
        const timer = Timer.init(TIMER_OPERATIONS.Accounts.loadAccountsList, true).startChild(
            TIMER_OPERATIONS.Accounts.loadAccountsList,
        );

        requestParams = { ...this.state.requestParams, ...requestParams };

        timer.enrichData(requestParams);

        this.setState({
            requestParams,
            loading: true,
            rows: [],
        });

        const convertedFilters = [];
        for (let filter of requestParams.filters ?? []) {
            try {
                convertedFilters.push(this.convertFrontFilterToServerFilter(filter));
            } catch (e) {
                // do nothing
            }
        }

        accountsManager
            .list(
                convertedFilters,
                requestParams.sorting,
                requestParams.currentPage * requestParams.pageSize,
                requestParams.pageSize,
                requestParams.accountType,
                requestParams.accountState,
            )
            .then((data) => {
                this.setState({
                    rows: data.items,
                    geocodedCounters: this.getGeocodedCounters(data.items),
                    totalCount: parseInt(data.total),
                    loading: false,
                    monthlyRecurringRevenue: data.monthlyRecurringRevenue,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    loading: false,
                });
            })
            .finally(() => {
                timer.end();
            });
    }

    getGeocodedCounters(rows) {
        const counters = {};
        rows.map((row) => (counters[row.id] = row.geocodedCounters));
        return counters;
    }

    handleAccountSaved = (account) => {
        this.props.enqueueSnackbar(this.props.t('account.saved.name', { name: account.name }), { variant: 'success' });
        this.setState({
            currentAccount: null,
        });
        this.loadAccountsList();
    };

    loadCsv = (type) => {
        if (type === 'accounts') {
            this.setState({ loadingAccounts: true });
        } else if (type === 'users') {
            this.setState({ loadingUsers: true });
        }
        accountsManager
            .getCsvUrl(type)
            .then((data) => {
                window.open(data.url);
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                if (type === 'accounts') {
                    this.setState({ loadingAccounts: false });
                } else if (type === 'users') {
                    this.setState({ loadingUsers: false });
                }
            });
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="accounts-csv-buttons">
                    <LoadingButton
                        color="primary"
                        loading={this.state.loadingAccounts}
                        onClick={() => this.loadCsv('accounts')}
                        data-testid="accounts.crv_buttons.accounts"
                    >
                        {t('accounts.crv_buttons.accounts')}
                    </LoadingButton>
                    <LoadingButton
                        color="primary"
                        loading={this.state.loadingUsers}
                        onClick={() => this.loadCsv('users')}
                        data-testid="accounts.crv_buttons.users"
                    >
                        {t('accounts.crv_buttons.users')}
                    </LoadingButton>
                </div>

                {this.state.monthlyRecurringRevenue !== null && (
                    <div className="mmr">
                        <Tooltip title={this.props.t('accounts.monthly_recurring_revenue')}>
                            <strong>
                                {' '}
                                MRR {formatMoneyWithCommas('USD', Math.round(this.state.monthlyRecurringRevenue))}{' '}
                            </strong>
                        </Tooltip>
                    </div>
                )}
                <LayoutGrid container>
                    <LayoutGrid item>
                        <RadioGroup
                            value={this.state.requestParams.accountType}
                            onChange={this.handleChangeAccountType}
                            row
                        >
                            <FormControlLabel value="real" control={<Radio color="primary" />} label={t('real')} />
                            <FormControlLabel value="test" control={<Radio />} label={t('test')} />
                        </RadioGroup>
                    </LayoutGrid>
                    <LayoutGrid item style={{ marginLeft: '26px' }}>
                        <RadioGroup
                            value={this.state.requestParams.accountState}
                            onChange={this.handleChangeAccountState}
                            row
                        >
                            <FormControlLabel value="live" control={<Radio color="primary" />} label={t('live')} />
                            <FormControlLabel value="removed" control={<Radio />} label={t('removed')} />
                        </RadioGroup>
                    </LayoutGrid>
                </LayoutGrid>
                <div className="table-accounts">
                    <Grid rows={this.state.rows} columns={this.columns}>
                        <SortingState
                            defaultSorting={this.state.requestParams.sorting}
                            onSortingChange={this.handleChangeSorting}
                            columnExtensions={[
                                { columnName: 'contact', sortingEnabled: false },
                                { columnName: 'geocodedCounters', sortingEnabled: false },
                                { columnName: 'sourceTypes', sortingEnabled: false },
                                { columnName: 'accountHealth', sortingEnabled: false },
                            ]}
                        />
                        <DataTypeProvider
                            for={this.numericColumns}
                            availableFilterOperations={this.numericFilterOperations}
                        />
                        <DataTypeProvider for={['contact']} availableFilterOperations={['contains']} />
                        <DataTypeProvider
                            for={this.dateColumns}
                            availableFilterOperations={this.dateFilterOperations}
                        />
                        <FilteringState
                            defaultFilters={this.state.requestParams.filters}
                            onFiltersChange={this.handleChangeFilters}
                            columnExtensions={[{ columnName: 'geocodedCounters', filteringEnabled: false }]}
                        />
                        <PagingState
                            currentPage={this.state.requestParams.currentPage}
                            onCurrentPageChange={this.handleChangeCurrentPage}
                            onPageSizeChange={this.handleChangePageSize}
                            pageSize={this.state.requestParams.pageSize}
                        />
                        <CustomPaging totalCount={this.state.totalCount} />

                        <Table
                            columnExtensions={[
                                { columnName: 'name', width: 270 },
                                { columnName: 'countryCode', width: 85 },
                                { columnName: 'createdAt', width: 80 },
                                { columnName: 'lastActiveAt', width: 80 },
                                { columnName: 'status', width: 250 },
                                { columnName: 'planName', width: 200 },
                                { columnName: 'subPriceTotalUsd', width: 250, align: 'right' },
                                { columnName: 'trialDays', width: 75, align: 'right' },
                                { columnName: 'usersCount', width: 75, align: 'right' },
                                { columnName: 'sourcesCount', width: 70, align: 'right' },
                                { columnName: 'accountHealth', width: 60 },
                                { columnName: 'sourceTypes', width: 120 },
                                { columnName: 'recordsCount', width: 100 },
                                { columnName: 'contact', width: 150 },
                            ]}
                            cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                            noDataCellComponent={() => (
                                <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                            )}
                        />
                        <TableHeaderRow
                            showSortingControls
                            cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        />
                        <TableFilterRow
                            showFilterSelector
                            iconComponent={FilterIcon}
                            cellComponent={StyledFilterCell}
                            //editorComponent={ToggleableEditor}
                        />
                        <PagingPanel pageSizes={this.pageSizes} />
                    </Grid>
                </div>
                {this.state.currentAccount !== null && (
                    <FormDialog
                        title={t('account.profile')}
                        onSave={this.handleAccountSaved}
                        onCancel={this.handleClose}
                    >
                        <AccountMainForm account={this.state.currentAccount} />
                    </FormDialog>
                )}
            </div>
        );
    }
}

Accounts.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const rightIconCellStyles = {
    flexContainer: {
        flexFlow: 'row-reverse',
    },
};

const StyledFilterCell = withStyles(rightIconCellStyles)(withTranslation()(FilterCell));

export default withTranslation()(withSnackbar(withRouter(Accounts)));
