import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import RoutingSettingsVehicleTypeFormManager from './RoutingSettingsVehicleTypeFormManager';
import { Routing } from 'interfaces';

class RoutingSettingsVehicleTypeDialogManager {
    private _isOpen = false;
    private _loading = false;
    private _editAction = false;
    private _formManager: RoutingSettingsVehicleTypeFormManager | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    openModalAddAction() {
        this._isOpen = true;
        this._editAction = false;
        this._formManager = new RoutingSettingsVehicleTypeFormManager({
            vehicleTypeKey: uuidv4(),
            vehicleType: {
                name: '',
                capacity: '',
                travelSpeedFactor: 1.0,
                serviceSpeedFactor: 1.0,
                travelMode: Routing.Settings.VehicleTypeTravelMode.CAR,
            },
        });
    }

    openModalEditAction(formData: Routing.Settings.VehicleTypeFormData) {
        this._isOpen = true;
        this._editAction = true;
        this._formManager = new RoutingSettingsVehicleTypeFormManager(formData);
    }

    closeModal() {
        this._isOpen = false;
        this._formManager = null;
    }

    get formManager(): RoutingSettingsVehicleTypeFormManager {
        if (this._formManager === null) {
            throw new Error('Vehicle type dialog not open.');
        }
        return this._formManager;
    }

    get editAction(): boolean {
        return this._editAction;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }
}

export default RoutingSettingsVehicleTypeDialogManager;
