import { Button, Tooltip } from '@material-ui/core';
import AccountLimitationsForm from './AccountLimitationsForm';
import SubscriptionEditForm from './SubscriptionEditForm';
import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionHistory from './SubscriptionHistory';
import UpdateBilling from './UpdateBilling';
import PropertiesTable from './PropertiesTable';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import SwitchToCustomForm from './SwitchToCustomForm';
import { SubscriptionStatus } from 'service/types';

class SuperAdminPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionFormEditOpen: false,
            subscriptionHistoryOpen: false,
            switchToCustomFormOpen: false,
            updateBillingOpen: false,
        };
    }

    handleSuccessMessage = (message) => {
        this.props.enqueueSnackbar(message, { variant: 'success', persist: true });
    };

    handleErrorMessage = (message) => {
        this.props.enqueueSnackbar(message, { variant: 'error' });
    };

    handleOpenSubscriptionEditForm = () => {
        this.setState({
            subscriptionFormEditOpen: true,
        });
    };

    handleCloseSubscriptionEditForm = () => {
        this.setState({
            subscriptionFormEditOpen: false,
        });
    };

    handleOpenAccountLimitationsForm = () => {
        this.setState({
            accountLimitationsFormOpen: true,
        });
    };

    handleCloseAccountLimitationsForm = () => {
        this.setState({
            accountLimitationsFormOpen: false,
        });
    };

    handleOpenSwitchToCustomForm = () => {
        this.setState({
            switchToCustomFormOpen: true,
        });
    };

    handleCloseSwitchToCustomForm = () => {
        this.setState({
            switchToCustomFormOpen: false,
        });
    };

    handleCloseSubscriptionHistory = () => {
        this.setState({
            subscriptionHistoryOpen: false,
        });
    };

    handleOpenSubscriptionHistory = () => {
        this.setState({
            subscriptionHistoryOpen: true,
        });
    };

    isSubscriptionPaddle = () => {
        const notPaddleSubscriptionStatuses = [
            SubscriptionStatus.CUSTOM,
            SubscriptionStatus.TRIAL,
            SubscriptionStatus.FREE,
        ];
        return !notPaddleSubscriptionStatuses.includes(this.props.subscription.status);
    };

    handleOpenUpdateBilling = () => {
        this.setState({
            updateBillingOpen: true,
        });
    };

    handleCloseUpdateBilling = () => {
        this.setState({
            updateBillingOpen: false,
        });
    };

    render() {
        const isShowSwitchToCustom = this.isSubscriptionPaddle();

        return (
            <React.Fragment>
                <div>
                    <Grid container spacing={2} alignItems="center" wrap="wrap">
                        <Grid item>
                            <h2>{this.props.t('billing.super_admin_panel.h1')}</h2>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleOpenSubscriptionEditForm}
                                className="btn-left-offset"
                                data-testid="billing.super_admin_panel.button.customize_subscription"
                            >
                                {this.props.t('billing.super_admin_panel.button.customize_subscription')}
                            </Button>
                        </Grid>
                        {isShowSwitchToCustom && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={this.handleOpenSwitchToCustomForm}
                                    className="btn-left-offset"
                                    data-testid="billing.super_admin_panel.button.switch_subscription"
                                >
                                    {this.props.t('billing.super_admin_panel.button.switch_subscription')}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={this.handleOpenAccountLimitationsForm}
                                className="btn-left-offset"
                                data-testid="billing.super_admin_panel.button.account_limitations"
                            >
                                {this.props.t('billing.super_admin_panel.button.account_limitations')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={this.handleOpenSubscriptionHistory}
                                className="btn-left-offset"
                                data-testid="billing.super_admin_panel.button.history"
                            >
                                {this.props.t('billing.super_admin_panel.button.history')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Tooltip title={this.props.t('billing.super_admin_panel.tooltip.update_billing')}>
                                <Button
                                    variant="contained"
                                    onClick={this.handleOpenUpdateBilling}
                                    className="btn-left-offset"
                                    data-testid="billing.super_admin_panel.button.update_billing"
                                >
                                    {this.props.t('billing.super_admin_panel.update_billing')}
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <PropertiesTable subscription={this.props.subscription} user={this.props.user} />
                    {this.state.subscriptionFormEditOpen && (
                        <SubscriptionEditForm
                            accountLimitations={this.props.accountLimitations}
                            onCloseDialog={this.handleCloseSubscriptionEditForm}
                            onCustomizeSuccess={this.handleSuccessMessage}
                            subscription={this.props.subscription}
                            user={this.props.user}
                            sharedMapsEnabled={this.props.sharedMapsEnabled}
                        />
                    )}
                    {this.state.switchToCustomFormOpen && (
                        <SwitchToCustomForm
                            accountId={this.props.subscription.accountId}
                            onCloseDialog={this.handleCloseSwitchToCustomForm}
                            onSwitchSuccess={this.handleSuccessMessage}
                        />
                    )}
                    {this.state.accountLimitationsFormOpen && (
                        <AccountLimitationsForm
                            accountLimitations={this.props.accountLimitations}
                            onCloseDialog={this.handleCloseAccountLimitationsForm}
                            onCustomizeSuccess={this.handleSuccessMessage}
                            user={this.props.user}
                        />
                    )}
                    <SubscriptionHistory
                        subscription={this.props.subscription}
                        openHistory={this.state.subscriptionHistoryOpen}
                        onCloseDialog={this.handleCloseSubscriptionHistory}
                        user={this.props.user}
                    />
                    {this.state.updateBillingOpen && (
                        <UpdateBilling
                            onCloseDialog={this.handleCloseUpdateBilling}
                            accountId={this.props.subscription.accountId}
                            onSuccess={this.handleSuccessMessage}
                            onFailure={this.handleErrorMessage}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

SuperAdminPanel.propTypes = {
    subscription: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.oneOf([null]).isRequired]).isRequired,
    user: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    accountLimitations: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.oneOf([null]).isRequired])
        .isRequired,
    sharedMapsEnabled: PropTypes.bool.isRequired,
};

export default withTranslation('translations', { withRef: true })(SuperAdminPanel);
