import config from '../params';
import dispatcher from './dispatcher';
import events from '../events';
import subscriptionManagerFactory from './SubscriptionManager';

export const CHECKOUT_COMPLETE = 'Checkout.Complete';
export const CHECKOUT_PAYMENT_COMPLETE = 'Checkout.PaymentComplete';

class PaddleManager {
    constructor() {
        this.actionsAfterLoader = [];
        this.vendorId = config.paddle.vendorId;
        this.sandBox = config.paddle.sandbox !== undefined && config.paddle.sandbox === true;
        this.initPaddle();
        this.paddle = null;
    }

    openWindowSubscribe(account, user, subscription, link) {
        const plan = subscription.plan;
        const passthrough = '{"account_id": ' + account.id + '}';
        this.execute(() => {
            let params = {
                product: parseInt(plan.paddleId),
                email: user.email,
                quantity: subscription.usersPurchased,
                allowQuantity: false,
                country: account.countryCode,
                passthrough: passthrough,
            };

            if (link !== undefined) {
                params['override'] = link;
            }

            this.paddle.Checkout.open(params);
            this.waitComplete(account);
        });
    }

    waitComplete(account, event = events.EVENT_PADDLE_CHECKOUT_COMPLETE, isUpdatePaymentMethod = false) {
        dispatcher.subscribe(event, this, () => {
            dispatcher.unsubscribe(event, this);
            if (document.getElementsByClassName('paddle-frame')[0] !== undefined) {
                document.getElementsByClassName('paddle-frame')[0].classList.add('loading-paddle');
            }

            if (isUpdatePaymentMethod) {
                subscriptionManagerFactory.getManager(account.id).updatePaymentInformation();
            }

            this.showSpinner();
        });

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, () => {
            dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
            if (document.getElementsByClassName('paddle-frame')[0] !== undefined) {
                document.getElementsByClassName('paddle-frame')[0].classList.remove('loading-paddle');
            }

            subscriptionManagerFactory.getManager(account.id).updatePaymentInformation();

            this.hideSpinner();
        });
    }

    openWindowUpdatePaymentMethod(account, link) {
        this.execute(() => {
            this.paddle.Checkout.open({
                override: link,
            });
            this.waitComplete(account, events.EVENT_PADDLE_CHECKOUT_COMPLETE, true);
        });
    }

    openWindowUpdatePaymentMethodWithPay(account, link) {
        this.execute(() => {
            this.paddle.Checkout.open({
                override: link,
            });
            this.waitComplete(account, events.EVENT_PADDLE_CHECKOUT_PAYMENT_COMPLETE, true);
        });
    }

    openWindowWithOverloadLink(account, link) {
        this.execute(() => {
            this.paddle.Checkout.open({
                override: link,
            });
            this.waitComplete(account);
        });
    }

    showSpinner() {
        this.paddle.Spinner.show();
    }

    hideSpinner() {
        this.paddle.Spinner.hide();
    }

    execute(func) {
        if (typeof this.paddle === 'object') {
            func.call(this);
        } else {
            this.actionsAfterLoader.push(func);
        }
    }

    initPaddle() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.paddle.com/paddle/paddle.js';
        let firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);

        script.onload = () => {
            this.paddle = window.Paddle;
            if (this.sandBox) {
                this.paddle.Environment.set('sandbox');
            }

            this.paddle.Setup({
                vendor: this.vendorId,
                eventCallback: function (data) {
                    if (data.event === CHECKOUT_COMPLETE) {
                        dispatcher.dispatch(events.EVENT_PADDLE_CHECKOUT_COMPLETE);
                    }

                    if (data.event === CHECKOUT_PAYMENT_COMPLETE) {
                        dispatcher.dispatch(events.EVENT_PADDLE_CHECKOUT_PAYMENT_COMPLETE);
                    }

                    console.log(data.event);
                    console.log(data.eventData);
                },
            });
            this.actionsAfterLoader.forEach((action) => {
                action.call(this);
            });
        };
    }
}

export const paddleManager = new PaddleManager();
