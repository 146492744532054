import { defaultTravelingPreferencesConfig, TravelingPreferencesConfig } from './TravelingPreferences';
import { logDebug } from '../../utils';
import { UserData } from 'service/types';
import { userManager } from 'service/UserManager';
import { STORAGE_KEY_PREFIX } from 'components/types';

class TravelingPreferencesStorage {
    save(config: TravelingPreferencesConfig): void {
        const user = userManager.getCurrentUser();
        window.localStorage.setItem(TravelingPreferencesStorage.getKey(user), JSON.stringify(config));
    }

    load(): TravelingPreferencesConfig {
        const user = userManager.getCurrentUser();

        let preferences;
        const json = window.localStorage.getItem(TravelingPreferencesStorage.getKey(user));
        if (json === null) {
            preferences = { ...defaultTravelingPreferencesConfig };
        } else {
            try {
                preferences = JSON.parse(json);
            } catch (e) {
                logDebug(e);
                preferences = { ...defaultTravelingPreferencesConfig };
            }
        }

        return preferences;
    }

    private static getKey(user: UserData): string {
        return `${STORAGE_KEY_PREFIX.TRAVELING_PREFERENCES}${user.id}`;
    }
}

export default TravelingPreferencesStorage;
