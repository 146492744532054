import { VehicleTypeTravelMode } from './settings';

export enum SessionTravelMode {
    DEFAULT = 'default',
}

export type TravelMode = SessionTravelMode | VehicleTypeTravelMode;

export const TravelModesCar = [
    VehicleTypeTravelMode.CAR,
    VehicleTypeTravelMode.CAR_AVOID_FERRY,
    VehicleTypeTravelMode.CAR_AVOID_MOTORWAY,
    VehicleTypeTravelMode.CAR_AVOID_TOLL,
    VehicleTypeTravelMode.CAR_DELIVERY,
    VehicleTypeTravelMode.SMALL_TRUCK,
    VehicleTypeTravelMode.SMALL_TRUCK_DELIVERY,
];

export const TravelModesScooter = [VehicleTypeTravelMode.SCOOTER, VehicleTypeTravelMode.SCOOTER_DELIVERY];

export const TravelModesTruck = [VehicleTypeTravelMode.TRUCK];

export const TravelModesTraffic = [...TravelModesCar, ...TravelModesScooter, ...TravelModesTruck];
