import React, { RefObject } from 'react';
import debounce from 'lodash/debounce';

const calcItemSize = (containerWidth: number, itemWidth: number, minItemWidth: number, minItemCount: number) => {
    let count = Math.floor(containerWidth / itemWidth);
    if (minItemCount !== undefined && count < minItemCount) {
        const minCount = Math.floor(containerWidth / minItemWidth);
        count = Math.min(minCount, minItemCount);
    }
    return count;
};

interface Props {
    container: RefObject<HTMLDivElement>;
    onChange: (maxButtons: number) => void;
    itemWidth: number;
    minItemWidth: number;
    minItemCount: number;
}

export default function AutofitPointActions(props: Props) {
    const { container, itemWidth, minItemWidth, minItemCount, onChange } = props;

    React.useEffect(() => {
        const handleResize = () => {
            if (!container.current) {
                return;
            }
            onChange(calcItemSize(container.current.clientWidth, itemWidth, minItemWidth, minItemCount));
        };
        const debouncedHandleResize = debounce(handleResize, 100);
        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    });

    React.useEffect(() => {
        if (!container.current) {
            return;
        }
        onChange(calcItemSize(container.current.clientWidth, itemWidth, minItemWidth, minItemCount));
    }, [itemWidth, minItemWidth, minItemCount, container, onChange]);

    return null;
}
