import React from 'react';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';

class CircularLabeledProgress extends React.PureComponent<CircularProgressProps> {
    render() {
        if (!this.props.value) {
            return null;
        }
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" {...this.props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        this.props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }
}

export default CircularLabeledProgress;
