import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';
import moment from 'moment';

export default class DateTime extends AbstractField {
    constructor() {
        super(FIELD_TYPES.DATETIME);

        this.isTimeAllowed = false;
    }

    setIsTimeAllowed(isTimeAllowed) {
        this.isTimeAllowed = isTimeAllowed;

        return this;
    }

    getIsTimeAllowed() {
        return this.isTimeAllowed;
    }

    validateValue() {
        if (this.getValue() === '' || this.getValue() === null || this.getValue() === undefined) {
            return true;
        }
        if (this.getValue() instanceof moment) {
            return this.getValue().isValid();
        } else {
            return moment(this.getValue()).isValid();
        }
    }

    isEmpty() {
        return this.value === null;
    }
}
