import {
    LEVEL_CITY,
    LEVEL_COUNTRY,
    LEVEL_COUNTY,
    LEVEL_HOUSE,
    LEVEL_STATE,
    LEVEL_STREET,
    LEVEL_STREET_2,
    LEVEL_ZIP,
} from 'components/utils/CompositeAddress';
import { Geo, Routing } from 'interfaces';

export interface GeoPoint {
    lat: number;
    lng: number;
}

export interface NullableGeoPoint extends Omit<GeoPoint, 'lat' | 'lng'> {
    lat: number | null;
    lng: number | null;
}

export type AddressFields = {
    [LEVEL_ZIP]?: string | null;
    [LEVEL_COUNTRY]?: string | null;
    [LEVEL_STATE]?: string | null;
    [LEVEL_COUNTY]?: string | null;
    [LEVEL_CITY]?: string | null;
    [LEVEL_STREET]?: string | null;
    [LEVEL_STREET_2]?: string | null;
    [LEVEL_HOUSE]?: string | null;
};

export interface GeoPointAddress extends Routing.Route.PointAddress, Geo.GeoPoint {}

export interface NullableGeoPointAddress extends Routing.Route.PointAddress, Geo.NullableGeoPoint {}

export interface GeoLocationPoint extends Geo.GeoPointAddress {
    entityId: number | null;
    recordId: string | null;
    objectName: string | null;
    exact?: boolean;
}

export enum LocationValidity {
    Exact = 'exact',
    Valid = 'valid',
    Invalid = 'invalid',
}

export enum GeoStatus {
    Unprocessed = 0,
    Ok = 1,
    Doubt = 2,
    NoResult = 3,
    NoAddress = 4,
    OkImported = 5,
    ServiceError = -1,
    AccessError = -2,
    Other = -3,
}
