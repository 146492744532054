import React from 'react';
import { TextField } from '@material-ui/core';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { userManager } from '../../service/UserManager';
import { BrowserRouter as Router, matchPath, Route, Switch } from 'react-router-dom';
import { getRoute, routes } from '../../routes';
import { MAPSLY_MUI_THEME, isInSafari, isMapPage } from '../../utils';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import App from './App';
import Oauth from '../Oauth';
import OauthFinalStep from '../Oauth/OauthFinalStep';
import firebase from '../../service/Firebase';
import { historyPromptManager } from '../../service/HistoryPromptManager';

TextField.defaultProps = { autoComplete: 'off' };

const themeProps2 = cloneDeep(MAPSLY_MUI_THEME);
themeProps2.palette.background.default = '#fff';

const theme = createTheme(MAPSLY_MUI_THEME);
const theme2 = createTheme(themeProps2);

function CloseSnackbar({ notiKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <i
            className="fas fa-times"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                closeSnackbar(notiKey);
                dispatcher.dispatch(events.SNACKBAR_CLOSED, notiKey);
            }}
        />
    );
}

const injectClose = (notiKey) => <CloseSnackbar notiKey={notiKey} />;

class Wrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: theme,
            language: 'en',
        };
        dispatcher.subscribe(events.EVENT_CHANGE_LANGUAGE, this, this.handleChangeLanguage);

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, (user) => {
            if (user) {
                let language = user.language;
                if (language === null) {
                    language =
                        userManager.getCurrentAccount() !== null ? userManager.getCurrentAccount().language : 'en';
                }
                this.handleChangeLanguage(language);
            }
        });
    }

    componentDidMount() {
        if (isInSafari()) {
            document.body.classList.add('safari-browser');
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleChangeLanguage = (language) => {
        this.setState(
            {
                language,
            },
            () => {
                this.props.i18n.changeLanguage(language);
                window.localStorage.setItem('mapsly_language', language);
            },
        );
    };

    onLocation = (loc) => {
        this.chooseTheme(loc);
        this.firebaseAnalytics(loc);
    };

    handleGetUserConfirmation = (message, callback) => {
        historyPromptManager.dispatch(message, callback);
    };

    chooseTheme = (loc) => {
        const match = matchPath(loc['pathname'], {
            path: [routes.login, routes.sso, routes.resetPassword, routes.admin.account.dataSource.index],
            exact: true,
            strict: true,
        });

        if (match) {
            this.setState({
                theme: theme2,
            });
        } else {
            this.setState({
                theme: theme,
            });
        }
    };

    firebaseAnalytics = (loc) => {
        const currentRoute = getRoute(loc['pathname'], false);
        if (!currentRoute) {
            return;
        }
        firebase.analytics.setCurrentScreen(currentRoute.route);
    };

    render() {
        const isMap = isMapPage(window.location.pathname);
        const snackbarClass = isMap
            ? {
                  containerAnchorOriginTopLeft: 'snackbar-container-top',
                  containerAnchorOriginTopCenter: 'snackbar-container-top',
                  containerAnchorOriginTopRight: 'snackbar-container-top snackbar-container__map-page',
                  root: 'snackbar-root__map-page',
              }
            : {
                  containerAnchorOriginTopLeft: 'snackbar-container-top',
                  containerAnchorOriginTopCenter: 'snackbar-container-top',
                  containerAnchorOriginTopRight: 'snackbar-container-top',
                  root: 'snackbar-root__wrapper',
              };
        return (
            <MuiThemeProvider theme={this.state.theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CssBaseline />
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        autoHideDuration={5000}
                        action={injectClose}
                        classes={snackbarClass}
                    >
                        <Router getUserConfirmation={this.handleGetUserConfirmation}>
                            <Switch>
                                <Route path={routes.oauth} exact component={Oauth} onLocation={this.onLocation} />
                                <Route
                                    path={routes.oauth_final_step}
                                    exact
                                    component={OauthFinalStep}
                                    onLocation={this.onLocation}
                                />
                                <Route path="/" render={(props) => <App {...props} onLocation={this.onLocation} />} />
                            </Switch>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default withTranslation()(Wrapper);
