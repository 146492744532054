import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

interface Props {
    value: ParsableDate;
    label: string;
    error?: string;
    onChangeDateTime: (date: MaterialUiPickersDate) => void;
    disabled: boolean;
    autoFocus: boolean;
    clearable?: boolean;
}

class DateTimeField extends React.PureComponent<Props> {
    render() {
        const { value, label, error, disabled, autoFocus, clearable, onChangeDateTime } = this.props;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    error={!!error}
                    label={label}
                    margin="none"
                    inputVariant="outlined"
                    inputProps={{ autoFocus: false }}
                    value={value}
                    onChange={onChangeDateTime}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    clearable={!!clearable}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default DateTimeField;
