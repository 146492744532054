import { userManager } from '../UserManager';
import addMinutes from 'date-fns/addMinutes';
import { parseISO } from 'date-fns';
import { User } from '../../interfaces/user';
import { getTimezoneOffset } from 'date-fns-tz';

class DateHelper {
    /**
     * Creates a new DateContainer with the given string date from server in UTC
     */
    createFromISOString(stringDate: string, user?: User): DateContainer {
        if (!user) {
            user = userManager.getCurrentUser();
        }
        if (!user) {
            throw new Error('User not found');
        }

        const date = parseISO(stringDate);
        const timezoneOffset = Math.floor(getTimezoneOffset(user.actualTimezone, date) / 60_000);

        return new DateContainer(date, timezoneOffset);
    }

    /**
     * Creates a new DateContainer with the given date constructed in browser
     * Corrects date timezone using user timezone offset and browser timezone offset
     */
    createFromDeviceDate(date: Date, user?: User): DateContainer {
        if (!user) {
            user = userManager.getCurrentUser();
        }
        if (!user) {
            return new DateContainer(date, 0);
        }

        const browserTimezoneOffset = -date.getTimezoneOffset();
        const timezoneOffset = Math.floor(getTimezoneOffset(user.actualTimezone, date) / 60_000);
        const offset = timezoneOffset - browserTimezoneOffset;
        const correctedDate = addMinutes(date, -offset);

        return new DateContainer(correctedDate, timezoneOffset);
    }
}

class DateContainer {
    private readonly date: Date;
    private readonly timezoneOffset: number;

    constructor(date: Date, timezoneOffset: number) {
        this.date = date;
        this.timezoneOffset = timezoneOffset;
    }

    /**
     * Use this method to display date in browser
     */
    getDisplayDate(): Date {
        const browserTimezoneOffset = -this.date.getTimezoneOffset();
        const offset = this.timezoneOffset - browserTimezoneOffset;
        return addMinutes(this.date, offset);
    }

    /**
     * Use this method to get correct date
     */
    getDate(): Date {
        return this.date;
    }
}

const dateHelper = new DateHelper();
export default dateHelper;
export { DateContainer };
