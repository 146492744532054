import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Status extends React.PureComponent {
    render() {
        const status = this.props.status;
        const name = this.props.t('billing.status_' + status);
        const color = this.props.color !== undefined ? this.props.color : status;
        const className = 'c-billing status ' + color + (this.props.small ? ' small' : '');
        return (
            <React.Fragment>
                <span className={className}>{name.toLowerCase()}</span>
            </React.Fragment>
        );
    }
}

Status.propTypes = {
    status: PropTypes.string,
    color: PropTypes.string,
    small: PropTypes.bool,
};

Status.defaultProps = {
    small: false,
};

export default withTranslation()(Status);
