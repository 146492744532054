import { DbTerritoriesMemory } from './DbTerritoriesMemory';
import { DbTerritories } from './DbTerritories';
import { DbTerritoriesIndexDb } from './DbTerritoriesIndexDb';

export class DbTerritoriesManager {
    manager: DbTerritories | null = null;

    async getManager(): Promise<DbTerritories> {
        if (this.manager !== null && this.manager.isConnect()) {
            return this.manager;
        }
        this.destroy();
        try {
            let dbManager = new DbTerritoriesIndexDb();
            await dbManager.init();
            this.manager = dbManager;
            console.info('Init IndexDB for Territories');

            return this.manager;
        } catch (e) {
            return this.initMemoryDb();
        }
    }

    initMemoryDb(): Promise<DbTerritories> {
        console.info('Init Memory DB for Territories');
        this.manager = new DbTerritoriesMemory();
        return Promise.resolve(this.manager);
    }

    destroy(): void {
        if (this.manager !== null) {
            this.manager.close();
        }

        this.manager = null;
    }
}

export default new DbTerritoriesManager();
