import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox, colors, FormControl, FormControlLabel, Grid } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';
import { FeatureProps } from '../../interfaces/signUpFeatures';
import Hint from '../Hint';
import SSUManager from './SSUManager';
import { InterestingFeaturesService } from './InterestingFeaturesService';

interface InterestedFeatureBlockProps extends WithTranslation {
    manager: SSUManager;
    featuresService: InterestingFeaturesService;
    selectedFeatures: Array<FeatureProps>;
}

interface InterestedFeatureBlockState {
    selectedFeatures: Array<FeatureProps>;
}

class InterestedFeatureBlock extends React.Component<InterestedFeatureBlockProps, InterestedFeatureBlockState> {
    private readonly maxLengthForFeatureLabel: number;

    constructor(props: InterestedFeatureBlockProps) {
        super(props);
        this.maxLengthForFeatureLabel = 21;
        this.state = {
            selectedFeatures: props.selectedFeatures,
        };
    }

    featureChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { featuresService } = this.props;
        const selectedFeatures = featuresService.updateFeatureProps(event.target.name, event.target.checked);
        this.setState({
            selectedFeatures: selectedFeatures,
        });

        this.props.featuresService.saveSelectedFeaturesState();
    };

    render() {
        const { selectedFeatures } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                {selectedFeatures.map((featureGroup: FeatureProps) => {
                    return (
                        <Grid
                            container
                            className="interested-in-feature-block interested-in-block"
                            alignItems="flex-start"
                            data-testid={'interested-in-feature-block-' + featureGroup.name}
                            key={featureGroup.name}
                        >
                            <Grid item container xs={4} alignItems="center" className="root-feature">
                                {this.renderFormControl(featureGroup)}
                            </Grid>

                            <CSSTransition
                                in={featureGroup.selected}
                                timeout={300}
                                classNames="interested-in-depended-features"
                                unmountOnExit
                            >
                                <Grid item xs={8} container justifyContent="flex-start" className="depended-features">
                                    {featureGroup.dependencies &&
                                        featureGroup.dependencies.map((dependency: FeatureProps) => {
                                            const nameLength = t(dependency.labelKey).length;
                                            const colSize = nameLength > this.maxLengthForFeatureLabel ? 12 : 6;
                                            return (
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={'center'}
                                                    xs={12}
                                                    sm={colSize}
                                                    key={dependency.name}
                                                    className="depended-feature"
                                                >
                                                    {this.renderFormControl(dependency, featureGroup.name)}
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </CSSTransition>
                        </Grid>
                    );
                })}
            </React.Fragment>
        );
    }

    renderFormControl(selectedFeature: FeatureProps, parentName?: string) {
        const { t, featuresService } = this.props;
        const hint = t(selectedFeature.hintKey, { joinArrays: '\n', defaultValue: '' });
        const eventName = (parentName ? parentName + featuresService.nameSeparator : '') + selectedFeature.name;
        return (
            <React.Fragment>
                <FormControl>
                    <FormControlLabel
                        style={{ marginRight: 6 }}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ marginLeft: 0, marginRight: 0 }}
                                checked={selectedFeature.selected}
                                onChange={this.featureChecked}
                                value="primary"
                                name={eventName}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={t(selectedFeature.labelKey)}
                    />
                </FormControl>
                {hint && (
                    <Hint TooltipProps={{ className: 'tooltip-question' }} color={colors.grey[600]}>
                        {hint}
                    </Hint>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations')(InterestedFeatureBlock);
