import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, RoutingCreditsUsageDaily } from '../../../interfaces/account';
import React from 'react';
import { formatWithCommas } from '../../../utils';
import { routingCreditsManager } from '../../../service/RoutingCreditsManager';
import { WithSnackbarProps } from 'notistack';
import { LinearProgressWithLabel } from '../../LinearProgress/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { User } from '../../../interfaces/user';
import { formatInTimeZone } from 'date-fns-tz';
import Button from '@material-ui/core/Button';
import RoutingCreditsUsageChart from './RoutingCreditsUsageChart';

interface RoutingCreditsUsageProps extends WithTranslation, WithSnackbarProps {
    account: Account;
    user: User;
    dailyMax: number;
    todayUsage: number;
}

interface RoutingCreditsUsageState {
    dailyUsages: RoutingCreditsUsageDaily[];
    initialized: boolean;
    showGraph: boolean;
}

class RoutingCreditsUsagePanel extends React.Component<RoutingCreditsUsageProps, RoutingCreditsUsageState> {
    constructor(props: RoutingCreditsUsageProps) {
        super(props);
        this.state = {
            dailyUsages: [],
            initialized: false,
            showGraph: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    private init = () => {
        const accountId = this.props.account.id;
        routingCreditsManager
            .getDailyUsage(accountId)
            .then((results: RoutingCreditsUsageDaily[]) => {
                this.setState({
                    dailyUsages: results,
                    initialized: true,
                });
            })
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    private makeTooltipText = () => {
        const { dailyMax, todayUsage, user, t } = this.props;
        const consumption = t('billing.subscription_panel.routing_credits_usage.consumption', {
            usage: formatWithCommas(todayUsage),
            max: formatWithCommas(dailyMax),
        });
        const available = t('billing.subscription_panel.routing_credits_usage.available', {
            total: formatWithCommas(dailyMax - todayUsage),
        });

        const date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        const timezone = user.actualTimezone ?? 'UTC';
        let time = formatInTimeZone(date, timezone, 'hh a z');
        time += timezone === 'UTC' ? '' : ' (00:00 UTC)';
        const renew = t('billing.subscription_panel.routing_credits_usage.renew_in', {
            time: time,
        });

        return (
            <React.Fragment>
                {consumption}
                <br />
                {available}
                <br />
                {renew}
            </React.Fragment>
        );
    };

    private handleOpenRoutingCreditsUsageGraph = () => {
        this.setState({ showGraph: true });
    };

    private handleCloseRoutingCreditsUsageGraph = () => {
        this.setState({ showGraph: false });
    };

    render() {
        const { t } = this.props;
        const { dailyMax, todayUsage } = this.props;
        return (
            <div className="routing-credits-usage-panel">
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span> {t('billing.subscription_panel.routing_credits_usage.limit')}: </span>
                    </Grid>
                    <Grid item xs>
                        <div style={{ width: 100 }}>
                            <LinearProgressWithLabel
                                label={formatWithCommas(dailyMax)}
                                value={Math.round((todayUsage / dailyMax) * 100)}
                                tooltip={this.makeTooltipText()}
                                style={{ width: 100 }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" onClick={this.handleOpenRoutingCreditsUsageGraph}>
                            {t('billing.subscription_panel.routing_credits_usage.show_usage_button')}
                        </Button>
                    </Grid>
                </Grid>

                {this.state.showGraph && (
                    <RoutingCreditsUsageChart
                        onClose={this.handleCloseRoutingCreditsUsageGraph}
                        dailyUsages={this.state.dailyUsages}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(RoutingCreditsUsagePanel);
