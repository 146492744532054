import React from 'react';
import { adapterManager } from '../../service/AdapterManager';
import { FormControl, FormHelperText, Select, InputLabel, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import PopupJobFactory from '../../service/PopupJobFactory';
import Alert from '../Alert';
import { withTranslation } from 'react-i18next';
import AdapterSettingsGroup from './AdapterSettingsGroup';
import Backdrop from '../Backdrop';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';
import aesEncryptor from 'service/AesEncryptor';
import { AdapterId } from '../types';

class ConnectionForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            adapterId: null,
            adapter: null,
            isReady: false,
        };
        this.state = { ...this.state, ...this.constructor.withDataSource(props.dataSource) };

        this.adapters = [];

        this.credentialsForm = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { onReadyStateChanged } = this.props;
        if (!prevState.adapter && this.state.adapter) {
            onReadyStateChanged && onReadyStateChanged(true);
        } else if (prevState.adapter && !this.state.adapter) {
            onReadyStateChanged && onReadyStateChanged(false);
        }
    }

    static withDataSource(dataSource) {
        return {
            adapterId: dataSource.adapterId,
        };
    }

    componentDidMount() {
        const { onReadyStateChanged, onError } = this.props;
        onReadyStateChanged && onReadyStateChanged(false);
        adapterManager
            .list()
            .then((adapters) => {
                this.adapters = adapters;
                this.setState(
                    {
                        isReady: true,
                    },
                    () => this.connectToAdapter(),
                );
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);
            });

        if (this.isFileMissing()) {
            onError && onError(this.getFileMissingError());
        }
    }

    handleSaveDataSource = (grantToken) => {
        const { t, dataSource, dsManager, onSuccess, onError } = this.props;

        const ds = { ...dataSource };
        ds.adapterId = this.state.adapterId;
        try {
            ds.credentials = this.credentialsForm.current ? this.credentialsForm.current.submit() : {};
        } catch (error) {
            onError && onError(t('data_source_form.connection_form.connect_save_error', { message: error.message }));
            return;
        }

        if (grantToken) {
            ds.credentials['grant_token'] = grantToken;
        }

        dsManager
            .save(ds, true)
            .then((dataSource) => {
                onSuccess && onSuccess(dataSource);
            })
            .catch((error) => {
                onError &&
                    onError(t('data_source_form.connection_form.connect_save_error', { message: error.message }));
                if (error instanceof Error) {
                    throw error;
                }
            });
    };

    getOauthUrl(credentials) {
        if (this.state.adapter && this.state.adapter.interface && this.state.adapter.interface.oauth) {
            let url = this.state.adapter.interface.oauth;
            if (credentials) {
                url += '?' + encodeURIComponent(aesEncryptor.encrypt(JSON.stringify(credentials)));
            }
            return url;
        }
        return null;
    }

    submit() {
        const { onError, t } = this.props;
        this.setState({
            errors: new Map(),
        });

        // validate
        if (this.state.adapter === null) {
            onError && onError(t('data_source_form.connection_form.should_connect'));
            return;
        }

        this.props.onLoading && this.props.onLoading();

        // сначала проверка credentials
        let credentials = null;
        try {
            if (this.credentialsForm.current) {
                credentials = this.credentialsForm.current.submit();
            }
        } catch (e) {
            onError && onError(t('data_source_form.connection_form.error'));
            return;
        }

        let oauthUrl = this.getOauthUrl(credentials);

        const promise = oauthUrl ? PopupJobFactory.getService(oauthUrl).request() : Promise.resolve();

        promise
            .then((grantToken) => {
                this.handleSaveDataSource(grantToken);
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);
            });
    }

    quiet() {
        if (this.credentialsForm.current) {
            this.credentialsForm.current.calm();
        }
        this.setState({
            errors: new Map(),
        });
    }

    connectToAdapter = () => {
        if (!this.state.adapterId) {
            return;
        }

        const { onError } = this.props;

        this.setState({
            errors: new Map(),
            adapter: null,
        });

        adapterManager
            .forId(this.state.adapterId)
            .then((adapter) => {
                this.setState({
                    adapter: adapter,
                });
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);

                if (error.details) {
                    this.setState({
                        errors: error.details,
                    });
                }
            });
    };

    handleAdapterIdChange = (e) => {
        const id = e.target.value !== '' ? e.target.value : null;
        this.setState(
            {
                adapterId: id,
                adapter: null,
            },
            () => this.connectToAdapter(),
        );
    };

    isFileMissing = () => {
        const { adapterId } = this.state;
        const { dataSource } = this.props;

        return (
            adapterId === AdapterId.GOOGLESHEETS &&
            dataSource.importMessages.find((m) => m.httpCode === 404) !== undefined
        );
    };

    getFileMissingError = () => {
        if (!this.isFileMissing()) {
            return null;
        }

        if (this.state.adapterId === AdapterId.GOOGLESHEETS) {
            return this.props.t('data_source_form.connection_form.googlesheets.file_not_found', {
                fileId: this.props.dataSource.credentials.spreadsheet_id,
            });
        }

        return null;
    };

    render() {
        const { t } = this.props;
        if (!this.state.isReady) {
            return t('loading');
        }
        const adapter = this.state.adapter;
        return (
            <div className="c-data-source-connection-form">
                {this.props.dataSource.lastHttpCode === 403 && this.props.dataSource.connectionToggledAt && (
                    <Alert type="warning" placement="modal" canClose>
                        {t('data_source_form.connection_form.connection_failed', {
                            connectionToggledAt: this.props.dataSource.connectionToggledAt,
                        })}
                    </Alert>
                )}
                <FormControl fullWidth margin="dense" error={this.state.errors.has('adapterId')}>
                    <InputLabel shrink>{t('data_source_form.connection_form.select_source')}</InputLabel>
                    <Select
                        name="adapterId"
                        value={this.state.adapterId || ''}
                        onChange={this.handleAdapterIdChange}
                        displayEmpty
                        data-testid="data_source_form.connection_form.select_source"
                    >
                        <MenuItem value="" data-testid="data_source_form.connection_form.select_source.not_selected">
                            {t('data_source_form.connection_form.select_source.not_selected')}
                        </MenuItem>
                        {this.adapters.map((adapter) => {
                            if (!adapter.id) {
                                return null;
                            }
                            return (
                                <MenuItem key={adapter.id} value={adapter.id}>
                                    {adapter.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {this.state.errors.get('adapterId') && (
                        <FormHelperText>{this.state.errors.get('adapterId')}</FormHelperText>
                    )}
                </FormControl>
                <Backdrop
                    loading={this.state.adapterId !== null && !this.state.adapter}
                    task={t('data_source_form.connection_form.getting_connector_parameters')}
                >
                    {this.state.adapter !== null && (
                        <div>
                            {adapter && adapter.credentials.length > 0 && (
                                <div style={{ marginTop: 20 }}>
                                    <h3 style={{ margin: 0 }}>
                                        {t('data_source_form.connection_form.connection_parameters')}
                                    </h3>
                                    <AdapterSettingsGroup
                                        key={this.state.adapterId}
                                        settings={adapter ? adapter.credentials : []}
                                        values={this.props.dataSource.credentials}
                                        ref={this.credentialsForm}
                                    />
                                </div>
                            )}

                            {this.getOauthUrl() && (
                                <div style={{ marginTop: 20 }}>
                                    <span>
                                        {t('data_source_form.connection_form.grant_access_text.oauth', {
                                            button: this.props.dataSource.id
                                                ? this.props.t('data_source_form.data_source_wizard.reconnect')
                                                : this.props.t('data_source_form.data_source_wizard.connect_to_source'),
                                            adapterName: adapter.name,
                                        })}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </Backdrop>
            </div>
        );
    }
}

ConnectionForm.propTypes = {
    dataSource: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onLoading: PropTypes.func,
    dsManager: PropTypes.object.isRequired,
    onReadyStateChanged: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(ConnectionForm);
