import React, { createRef } from 'react';
import entityManagerFactory from '../../service/EntityManager';
import Button from '@material-ui/core/Button';
import { Sticky, StickyContainer } from 'react-sticky';
import { withSnackbar } from 'notistack';
import ChangesNotification from './ChangesNotification';
import cloneDeep from 'lodash/cloneDeep';
import DsEntity from './DsEntity';
import PropTypes from 'prop-types';
import './style.css';
import Alert from '../Alert';
import ErrorMessage from './ErrorMessage';
import { Link, withRouter } from 'react-router-dom';
import Backdrop from '../Backdrop';
import { reverse, routes } from '../../routes';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import dsManagerFactory from '../../service/DsManager';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EntityHandler, { LOOKUP_DATA_FIELD } from '../../handlers/EntityHandler';
import { logDebug } from '../../utils';
import { SAVE_FIELDS } from '../GeoMapperForm';
import { withTranslation } from 'react-i18next';
import LandingLink from '../HelpLink/LandingLink';
import FormDialog from '../FormDialog';
import SearchableEntitiesListForm from '../EntitiesListForm/SearchableEntitiesListForm';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import SyncRefresh from './SyncRefresh';
import { CircularProgress, Icon, IconButton, Tooltip } from '@material-ui/core';
import DsEntityDummy from './DsEntityDummy';
import DataSourceMessages from '../ImportMessage/DataSourceMessages';
import subscriptionManagerFactory from 'service/SubscriptionManager';
import { HistoryBlocker } from '../HistoryBlocker/HistoryBlocker';
import { getAccountActiveCalendars } from '../Calendar/Helpers/CalendarHelper';
import { accountsManager } from '../../service/AccountsManager';
import { CALENDAR_MODULE } from '../Permissions/constants';
import { appointmentMetadataChecker } from 'service/MapPage';

class DataSource extends React.Component {
    saveButtonRef = createRef();

    constructor(props) {
        super(props);
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        this.state = {
            ds: null,
            loading: true,
            saving: false,
            entities: null,
            unchangedEntities: [],
            changeNotes: [],
            error: null,
            importNotification: false,
            reImportNotification: false,
            acceptReImport: false,
            newFieldsMap: new Map(),
            newEntities: [],
            newEntitiesModal: [],
            addObjectFormModal: false,
            entitiesWithUnmappedOwner: [],
            importEntitiesProcesses: new Set(),
            loadingEntities: new Map(),
            interactedEntities: new Set(),
            appointments: {
                warning: false,
                message: '',
                appointmentsEntity: null,
                params: null,
            },
            subscription: null,
            activeCalendars: [],
        };

        this.signUpMode = params.hasOwnProperty('ssu');
        this.forComparisionEntitiesStrings = {};
    }

    getAppointmentEntityFromDatasource(appointmentEntityName) {
        const { ds, unchangedEntities } = this.state;

        let index = ds.entityCounters.findIndex((counter) => appointmentEntityName === counter.entity.apiName);
        if (index !== -1) {
            return ds.entityCounters[index];
        }

        index = unchangedEntities.findIndex((entity) => appointmentEntityName === entity.apiName);

        return index !== -1 ? unchangedEntities[index] : null;
    }

    getAppointmentEntityFromAvailableEntities(appointmentEntityName) {
        const { entities } = this.state;
        const index = entities.findIndex((entity) => appointmentEntityName === entity.name);

        return index !== -1 ? entities[index] : null;
    }

    getAppointmentInNewEntities(appointmentEntity) {
        const { newEntities } = this.state;
        const index = newEntities.findIndex((newEntity) => newEntity.id === appointmentEntity.id);

        return index !== -1 ? newEntities[index] : null;
    }

    getParametersSpecialEntities() {
        const { ds } = this.state;
        const { t } = this.props;

        const defaultParameters = {
            warning: false,
            message: '',
            warningFields: [],
            appointmentsEntity: null,
            params: null,
        };

        if (ds === null || ds.specialEntities === null || ds.specialEntities.appointment === null) {
            return defaultParameters;
        }

        const { appointment } = ds.specialEntities;
        const appointmentEntity = this.getAppointmentEntityFromAvailableEntities(appointment.entity);

        if (appointmentEntity === null || !appointmentEntity.isIncluded) {
            return {
                warning: true,
                message: t('data_source.entity.appointments.settings.warning.not_available_entity', {
                    entityLabel: appointmentEntity ? appointmentEntity.label : appointment.entity,
                    sourceName: ds.name,
                }),
                warningFields: [],
                appointmentsEntity: null,
                params: null,
            };
        }

        const inListEntities = this.getAppointmentEntityFromDatasource(appointment.entity);
        if (inListEntities !== null) {
            return defaultParameters;
        }

        const appointmentInNewEntities = this.getAppointmentInNewEntities(appointmentEntity);

        if (appointmentInNewEntities) {
            return {
                warning: true,
                message: t('data_source.entity.appointments.settings.warning.not_saved', {
                    entityLabel: appointmentEntity.label,
                }),
                warningFields: [],
                appointmentsEntity: null,
                params: null,
            };
        }

        return {
            warning: true,
            message: t('data_source.entity.appointments.settings.warning.not_available_entity', {
                entityLabel: appointmentEntity.label,
                sourceName: ds.name,
            }),
            warningFields: [],
            appointmentsEntity: null,
            params: null,
        };
    }

    getManager() {
        return entityManagerFactory.getManager(this.props.accountId, this.props.dataSourceId);
    }

    static sortByLabel(a, b) {
        if (a.label === b.label) {
            return 0;
        }
        return a.label > b.label ? 1 : -1;
    }

    static cloneEntities(entities) {
        const result = [];
        for (let entity of entities) {
            const e = cloneDeep(entity);
            e.fields.sort(this.sortByLabel);
            result.push(e);
        }
        //result.sort(this.sortByLabel);

        return result;
    }

    isSyncingAvailable = (ds) => {
        return !ds.isDeactivated && ds.isImportEnabled && ds.lastHttpCode !== 403 && !ds.isSystem;
    };

    forceSync = () => {
        if (!this.isSyncingAvailable(this.state.ds)) {
            return;
        }
        this.handleSync(true);
        dsManagerFactory
            .getManager(this.props.accountId)
            .update(this.props.dataSourceId, null)
            .catch(() => {
                this.handleSync(false);
            });
    };

    handleSync = (isSyncing) => {
        const ds = { ...this.state.ds };
        ds.isSyncing = isSyncing;

        this.setState({
            ds,
        });
    };

    handleAcceptRefresh = () => {
        window.location.reload();
    };

    updateNewFieldsAndNewEntities(callback = () => {}) {
        let newEntities = this.getNewEntities();
        let newFieldsMap = this.getNewFieldsMap();
        return this.setState(
            {
                newEntities,
                newFieldsMap,
            },
            () => {
                this.setState({
                    appointments: this.getParametersSpecialEntities(),
                });
                callback();
            },
        );
    }

    updateData() {
        Promise.all([
            this.getManager().getEntities(true),
            this.getManager().getChanges(),
            dsManagerFactory.getManager(this.props.accountId).load(this.props.dataSourceId),
        ])
            .then((result) => {
                const ds = result[2];
                let sortedEntities = this.constructor.cloneEntities(result[0]);
                for (let e of sortedEntities) {
                    e.dsId = ds.id;
                }
                if (this.signUpMode) {
                    sortedEntities = sortedEntities.map((e) => {
                        if (e.isLockedBy && e.isLockedBy.length) {
                            return { ...e, isIncluded: true };
                        }
                        return e;
                    });
                }
                const unchangedEntities = cloneDeep(sortedEntities);

                for (let sortedIncludedEntity of sortedEntities.filter((se) => se.isIncluded)) {
                    this.includeRequiredEntitiesForEntity(sortedEntities, sortedIncludedEntity, ds);
                }

                sortedEntities = this.addLockedByToEntities(sortedEntities);
                sortedEntities = this.addRelatedEntityDataToLookupFields(sortedEntities);

                if (!ds.isSyncing && this.isSyncingAvailable(ds)) {
                    ds.isSyncing = true;
                    dsManagerFactory
                        .getManager(this.props.accountId)
                        .update(this.props.dataSourceId, null)
                        .catch(() => {
                            this.handleSync(false);
                        });
                }

                this.saveEntitiesStringsForComparison(sortedEntities);
                this.setState(
                    {
                        ds: ds,
                        entities: sortedEntities,
                        unchangedEntities,
                        changeNotes: result[1],
                        loading: false,
                    },
                    () => this.updateNewFieldsAndNewEntities(),
                );
            })
            .catch((error) => {
                this.props.enqueueSnackbar(
                    this.props.t('data_source.snack.unable_load_data', { message: error['message'] }),
                    { variant: 'error' },
                );
                this.setState({
                    loading: false,
                });
            });
    }

    componentDidMount() {
        this.updateData();

        window.addEventListener('beforeunload', this.onBeforeUnload);
        dispatcher.subscribe(events.WS_ENTITIES_IMPORT_PROGRESS, this, this.onEntitiesImportProgress);

        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, (payload) => {
            if (this.props.dataSourceId !== payload.dsId) {
                return;
            }
            this.updateData();
        });

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, ({ subscription }) => {
            if (this.props.accountId === subscription.accountId) {
                this.setState({ subscription });
            }
        });
        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendars(this.props.accountId);
        });

        this.updateCalendars(this.props.accountId);

        subscriptionManagerFactory
            .getManager(this.props.accountId)
            .getSubscription()
            .then((subscription) => {
                this.setState({ subscription });
            });
    }

    async updateCalendars(accountId) {
        const isCalendarUiFeatureEnabled = await accountsManager.accountHasAccessTo(
            this.props.accountId,
            CALENDAR_MODULE.NAME,
            CALENDAR_MODULE.FEATURES.UI.NAME,
        );
        if (isCalendarUiFeatureEnabled) {
            const calendars = await getAccountActiveCalendars(accountId);
            this.setState({ activeCalendars: Array.from(calendars.values()) });
        } else {
            this.setState({ activeCalendars: [] });
        }
    }

    saveEntitiesStringsForComparison(sortedEntities) {
        this.forComparisionEntitiesStrings = sortedEntities.reduce((result, entity) => {
            result[entity.id] = EntityHandler.stringify(entity);
            return result;
        }, {});
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

    isModified(entity = null) {
        if (entity !== null) {
            return this.forComparisionEntitiesStrings[entity.id] !== EntityHandler.stringify(entity);
        }

        for (let entity of this.state.entities) {
            if (this.forComparisionEntitiesStrings[entity.id] !== EntityHandler.stringify(entity)) {
                return true;
            }
        }
        return false;
    }

    onBeforeUnload = (e) => {
        if (this.isModified()) {
            e.returnValue = this.props.t('data_source.unsaved_changes');
        }
    };

    getEntityIndex(entity) {
        for (let ei = 0; ei < this.state.entities.length; ei++) {
            if (entity.name === this.state.entities[ei].name) {
                return ei;
            }
        }
        throw new Error(this.props.t('data_source.unsaved_changes', { entity: entity.name }));
    }

    handleScheduleSaved = (entity, schedule) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };
        newEntity.schedule = schedule;

        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    handleEntityActivityChanged = (entity, isIncluded, updateNewFieldsAndNewEntities = true) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };
        const protectedFields = EntityHandler.getProtectedFields(newEntity, this.state.ds);
        newEntity.isIncluded = isIncluded;

        if (isIncluded) {
            entity.fields.map((field) => {
                field.isIncluded =
                    !!protectedFields.get(field.apiName) || (!!entity.ownerField && field.id === entity.ownerField);
                return field;
            });
        }

        let entities = this.constructor.cloneEntities(this.state.entities);
        entities[ei] = newEntity;
        if (isIncluded) {
            entities = this.includeRequiredEntitiesForEntity([...entities], entity, this.state.ds);
        }

        entities = this.addLockedByToEntities(entities);
        entities = this.addRelatedEntityDataToLookupFields(entities);

        return new Promise((resolve) => {
            this.setState(
                {
                    entities,
                },
                () => (updateNewFieldsAndNewEntities ? this.updateNewFieldsAndNewEntities(resolve) : resolve()),
            );
        });
    };

    handleEntityObjectNameChanged = (entity, nameFields) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };
        newEntity.nameFields = nameFields;

        for (let apiName of nameFields) {
            for (let fi = 0; fi < newEntity.fields.length; fi++) {
                const field = newEntity.fields[fi];
                if (field.apiName === apiName && !field.isIncluded) {
                    const newField = { ...field };
                    newField.isIncluded = true;
                    newEntity.fields[fi] = newField;
                    break;
                }
            }
        }
        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    handlePinChanged = (entity, pinField) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };

        let includedLookupFieldOriginalName = null;
        if (pinField !== null && pinField.lookupData !== null && pinField.originalApiName !== null) {
            includedLookupFieldOriginalName = pinField.originalApiName;
        }
        const apiName = pinField !== null ? pinField.apiName : null;

        for (let fi = 0; fi < newEntity.fields.length; fi++) {
            const field = newEntity.fields[fi];
            if (field.apiName !== apiName && field.isPin) {
                newEntity.fields[fi] = {
                    ...field,
                    isPin: false,
                };
                continue;
            }

            if (field.apiName === apiName && !field.isPin) {
                newEntity.fields[fi] = {
                    ...field,
                    isPin: true,
                    isIncluded: true,
                };

                continue;
            }

            if (includedLookupFieldOriginalName !== null && field.originalApiName === includedLookupFieldOriginalName) {
                newEntity.fields[fi] = {
                    ...field,
                    isIncluded: true,
                };
            }
        }

        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    handleOwnerFieldChanged = (entity, fieldId) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity, ownerField: fieldId };

        for (let fi = 0; fi < newEntity.fields.length; fi++) {
            const field = newEntity.fields[fi];
            if (field.id === fieldId) {
                const newField = { ...field };
                newField.isIncluded = true;
                newEntity.fields[fi] = newField;
                break;
            }
        }

        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    addLockedByToEntitiesByField = (fieldOwnerEntity, field, entities, lockedEntityName) => {
        if (lockedEntityName === fieldOwnerEntity.name) {
            return entities;
        }

        const { isIncluded } = field;
        for (let i = 0; i < entities.length; i++) {
            if (entities[i].name === lockedEntityName) {
                let isLockedBy = [];
                if (Array.isArray(entities[i].isLockedBy)) {
                    isLockedBy = [...entities[i].isLockedBy];
                }
                logDebug('before isLockedBy', JSON.stringify(isLockedBy));
                if (
                    isIncluded &&
                    !isLockedBy.find((lockItem) => {
                        return (
                            lockItem.entity.apiName === fieldOwnerEntity.name &&
                            lockItem.field.apiName === field.apiName
                        );
                    })
                ) {
                    isLockedBy.push({
                        field: {
                            label: field.label,
                            apiName: field.apiName,
                            originalApiName: field.originalApiName,
                        },
                        entity: {
                            label: fieldOwnerEntity.label,
                            apiName: fieldOwnerEntity.name,
                        },
                    });
                }
                if (!isIncluded) {
                    isLockedBy = isLockedBy.filter((lockItem) => {
                        return (
                            lockItem.entity.apiName !== fieldOwnerEntity.name ||
                            lockItem.field.apiName !== field.apiName
                        );
                    });
                }
                logDebug('after isLockedBy', JSON.stringify(isLockedBy));
                let fields = entities[i].fields;
                if (!entities[i].isIncluded) {
                    const protectedFields = EntityHandler.getProtectedFields(entities[i], this.state.ds);
                    fields = fields.map((field) => {
                        field.isIncluded =
                            isIncluded &&
                            (!!protectedFields.get(field.apiName) ||
                                (!!fieldOwnerEntity.ownerField && field.id === fieldOwnerEntity.ownerField));
                        return field;
                    });
                }
                entities[i] = {
                    ...entities[i],
                    fields,
                    isIncluded: isIncluded ? !!isLockedBy.length : entities[i].isIncluded,
                    isLockedBy,
                };
                break;
            }
        }
        return entities;
    };

    oneFieldChanged = (entity, oldField, newField) => {
        const ei = this.getEntityIndex(entity);
        const fi = EntityHandler.findField(entity, oldField);

        const entityHandler = new EntityHandler({ ...entity });
        entityHandler.replaceField(fi, newField);
        let entities = [...this.state.entities];
        entities[ei] = entityHandler.getEntity();
        if (oldField && entities[ei].ownerField === oldField.id && (!newField || !newField.isIncluded)) {
            entities[ei].ownerField = null;
        }

        if (newField && newField.lookupData && newField.lookupData.apiName) {
            entities = this.addLockedByToEntitiesByField(entity, newField, entities, newField.lookupData.apiName);
            if (newField.lookupData.linking_lookup_data && newField.lookupData.linking_lookup_data.entity) {
                entities = this.addLockedByToEntitiesByField(
                    entity,
                    newField,
                    entities,
                    newField.lookupData.linking_lookup_data.entity,
                );
            }
        }

        this.addRelatedEntityDataToLookupFields(entities);

        this.setState(
            {
                entities,
            },
            () => this.updateNewFieldsAndNewEntities(),
        );
    };

    handleFieldChanged = (entity, oldField, newField) => {
        if (!oldField.lookupFields || oldField.lookupFields.length === 0) {
            this.oneFieldChanged(entity, oldField, newField);
        } else {
            // TODO Обрабатываем все технические поля, которые принадлежат оригинальному lookup полю в CRM.
            // @see components/DataSource/DsEntity.render
            for (let lookupField of oldField.lookupFields) {
                const replaceField = {
                    ...lookupField,
                    isIncluded: newField.isIncluded,
                    isPin: newField.isPin,
                };
                this.oneFieldChanged(entity, lookupField, replaceField);
            }
        }
    };

    reloadNewFieldsMap = (entity, newField, newFieldsMap = null) => {
        const unchangedField = this.getUnchangedField(entity, newField);
        newFieldsMap = newFieldsMap === null ? cloneDeep(this.state.newFieldsMap) : newFieldsMap;

        if (unchangedField === null) {
            return newFieldsMap;
        }

        if (newField.isIncluded && !unchangedField.isIncluded) {
            let newFields = newFieldsMap.has(entity.label) ? newFieldsMap.get(entity.label) : new Map();

            if (!newFields.has(newField.apiName)) {
                newFields.set(newField.apiName, true);
                newFieldsMap.set(entity.label, newFields);
            }

            return newFieldsMap;
        }

        if (newFieldsMap.has(entity.label) && newFieldsMap.get(entity.label).has(newField.apiName)) {
            newFieldsMap.get(entity.label).delete(newField.apiName);
            if (newFieldsMap.get(entity.label).size === 0) {
                newFieldsMap.delete(entity.label);
            }
        }

        return newFieldsMap;
    };

    getUnchangedField = (entity, newField) => {
        const entityIndex = this.getEntityIndex(entity);
        const unchangedEntity = this.state.unchangedEntities[entityIndex];
        const fieldIndex = EntityHandler.findField(unchangedEntity, newField);
        if (fieldIndex !== -1) {
            return unchangedEntity.fields[fieldIndex];
        }
        return null;
    };

    handleGeoMappingChanged = (entity, mapping) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = {
            ...entity,
            useLatLngFields: mapping.useLatLngFields,
            hasSaveGeoFields: mapping.hasSaveGeoFields,
            autoSaveHouseAndStreetType: mapping.autoSaveHouseAndStreetType,
        };
        for (let saveField of SAVE_FIELDS) {
            newEntity[saveField] = null;
            for (let fi = 0; fi < newEntity.fields.length; fi++) {
                const field = newEntity.fields[fi];
                if (field.apiName === mapping[saveField]) {
                    newEntity.fields[fi].isIncluded = true;
                    newEntity[saveField] = field.id;
                }
            }
        }
        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    handleLayerGroupRefreshed = (layerGroup, entity) => {
        const ei = this.getEntityIndex(entity);
        const { id, name, groupField, layers, permission, rbac, isAutogenerated, type } = layerGroup;

        const newEntity = entity ? { ...entity } : { ...this.state.entity };
        const entityHandler = new EntityHandler(newEntity);

        const vi = EntityHandler.findLayerGroup(newEntity, layerGroup);
        entityHandler.replaceLayerGroup(vi, { id, name, groupField, permission, rbac, isAutogenerated, type });
        newEntity.views = newEntity.views.filter((v) => v.layerGroupId !== layerGroup.id);
        for (let layer of layers) {
            entityHandler.replaceView(-1, layer, true);
        }

        const entities = [...this.state.entities];
        entities[ei] = entityHandler.getEntity();

        this.setState({
            entities,
        });
        entityHandler.notifyGroupRefreshed(layerGroup);
    };

    handleSave = (event) => {
        if (this.state.newFieldsMap.size > 0 && !this.state.acceptReImport) {
            if (event.currentTarget.dataset.reimport === undefined) {
                this.setState({
                    reImportNotification: true,
                });
                return;
            }
            this.setState((state) => {
                state.acceptReImport = true;
                return state;
            });
        }

        let toggleImport = event.currentTarget.dataset.toggleImport;

        if (this.signUpMode) {
            toggleImport = 'on';
        }

        if (!this.state.ds['isImportEnabled'] && toggleImport === undefined) {
            this.setState({
                importNotification: true,
            });
            return;
        }

        const entities = [];
        for (let entity of this.state.entities) {
            if (!this.isModified(entity)) {
                continue;
            }
            const e = {
                id: entity.id,
                isIncluded: entity.isIncluded,
                useLatLngFields: entity.useLatLngFields,
                hasSaveGeoFields: entity.hasSaveGeoFields,
                autoSaveHouseAndStreetType: entity.autoSaveHouseAndStreetType,
                latitudeField: entity.latitudeField,
                longitudeField: entity.longitudeField,
                layerGroups: entity.layerGroups,
                fields: [],
                views: [],
                schedule: entity.schedule,
                mapView: entity.mapView,
                tableView: entity.tableView,
                conflictIcon: entity.conflictIcon,
                nameFields: entity.nameFields,
                ownerField: entity.ownerField,
                advancedSettings: entity.advancedSettings,
            };
            for (let saveField of SAVE_FIELDS) {
                e[saveField] = entity[saveField];
            }

            for (let field of entity.fields) {
                e.fields.push({
                    id: field.id,
                    apiName: field.apiName,
                    label: field.label,
                    isIncluded: entity.isIncluded ? field.isIncluded : true,
                    isAddress: field.isAddress,
                    isCustom: field.isCustom,
                    isPin: field.isPin,
                    address: field.address, // ? {...field.address} : null
                });
            }
            for (let view of entity.views) {
                e.views.push({ ...view });
            }

            entities.push(e);
        }

        this.setState({
            importNotification: false,
            reImportNotification: false,
            acceptReImport: false,
            saving: true,
            error: null,
        });

        const promises = [this.getManager().saveEntities(entities, false, true)];
        if (toggleImport === 'on') {
            const ds = {
                id: this.props.dataSourceId,
                isImportEnabled: true,
            };
            promises.push(dsManagerFactory.getManager(this.props.accountId).save(ds, false));
        }

        Promise.all(promises)
            .then((result) => {
                dsManagerFactory.getManager(this.props.accountId).reset();
                // eslint-disable-next-line no-unused-vars
                let [entities, _] = result;
                entities = this.getManager().processFlags(entities);
                this.props.enqueueSnackbar(this.props.t('data_source.snack.data_saved'), { variant: 'success' });
                if (this.signUpMode) {
                    this.props.history.push(routes.client);
                    return;
                }
                let sortedEntities = this.constructor.cloneEntities(entities);
                for (let e of sortedEntities) {
                    e.dsId = this.state.ds.id;
                }
                const newEntitiesModal = this.state.newEntities.map((e) => e.label);

                sortedEntities = this.addLockedByToEntities(sortedEntities);
                sortedEntities = this.addRelatedEntityDataToLookupFields(sortedEntities);

                this.saveEntitiesStringsForComparison(sortedEntities);
                this.setState({
                    entities: sortedEntities,
                    unchangedEntities: cloneDeep(sortedEntities),
                    newFieldsMap: new Map(),
                    saving: false,
                    newEntities: [],
                    newEntitiesModal,
                });

                dsManagerFactory
                    .getManager(this.props.accountId)
                    .load(this.props.dataSourceId)
                    .then((dataSource) => {
                        this.setState({
                            ds: dataSource,
                        });
                    });
            })
            .catch((error) => {
                this.setState({
                    saving: false,
                    error: error,
                });
                this.props.enqueueSnackbar(
                    this.props.t('data_source.snack.unable_load_data', { message: error['message'] }),
                    { variant: 'error' },
                );
            });
    };

    handleSettingsSaved = (entity, advancedSettings) => {
        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };
        newEntity.advancedSettings = advancedSettings;

        this.setState((state) => {
            state.entities[ei] = newEntity;
            return state;
        });
    };

    handleReadChanges = () => {
        this.setState({
            changeNotes: [],
        });
    };

    handleCheckAll = (entity, checked) => {
        const entityActiveCalendars = this.state.activeCalendars.filter((calendar) => calendar.entityId === entity.id);

        const appointmentsRequiredFields =
            appointmentMetadataChecker.getRequiredAppointmentFieldsWithCalendars(entityActiveCalendars);

        const ei = this.getEntityIndex(entity);
        const newEntity = { ...entity };
        const protectedFields = checked
            ? new Map()
            : EntityHandler.getProtectedFields(entity, this.state.ds, Object.keys(appointmentsRequiredFields));

        const protectedOriginalLookupFieldNames = [];

        [...protectedFields].forEach(([fieldName]) => {
            if (protectedOriginalLookupFieldNames.includes(fieldName)) {
                return;
            }

            const originalFieldName = newEntity.fields.find(
                (field) => field.lookupData && field.apiName === fieldName,
            )?.originalApiName;

            if (originalFieldName) {
                newEntity.fields.forEach((field) => {
                    if (field.originalApiName === originalFieldName) {
                        protectedOriginalLookupFieldNames.push(field.apiName);
                    }
                });
            }
        });

        for (let fi = 0; fi < newEntity.fields.length; fi++) {
            const field = newEntity.fields[fi];

            if (
                !protectedFields.has(field.apiName) &&
                !protectedOriginalLookupFieldNames.includes(field.apiName) &&
                field.isIncluded !== checked
            ) {
                const newField = { ...field };
                newField.isIncluded = checked;
                newEntity.fields[fi] = newField;
            }
        }
        if (newEntity.fields.find((field) => !field.isIncluded && field.id === newEntity.ownerField)) {
            newEntity.ownerField = null;
        }

        let entities = cloneDeep(this.state.entities);
        entities[ei] = newEntity;
        entities = this.includeRequiredEntitiesForEntity(entities, entity, this.state.ds);
        entities = this.addLockedByToEntities(entities);
        entities = this.addRelatedEntityDataToLookupFields(entities);

        this.setState(
            (state) => {
                state.entities = entities;
                return state;
            },
            () => this.updateNewFieldsAndNewEntities(),
        );
    };

    // handleConflictIconChanged = (entity, icon) => {
    //     const ei = this.getEntityIndex(entity);
    //     const newEntity = {...entity};
    //     newEntity.conflictIcon = icon;
    //
    //     this.setState(state => {
    //         state.entities[ei] = newEntity;
    //         return state;
    //     });
    // };

    getNewFieldsMap() {
        let newFieldsMap = new Map();
        for (let entity of [...this.state.entities].filter((entity) => entity.isIncluded)) {
            if (
                !this.state.unchangedEntities.find(
                    (unchangedEntity) => unchangedEntity.id === entity.id && unchangedEntity.isIncluded,
                )
            ) {
                continue;
            }
            for (let field of entity.fields) {
                newFieldsMap = this.reloadNewFieldsMap(entity, field, newFieldsMap);
            }
        }
        return newFieldsMap;
    }

    getNewEntities() {
        let entities = [...this.state.entities];
        let newEntities = [...this.state.newEntities];

        for (let i in entities) {
            if (
                !!this.state.unchangedEntities.find(
                    (unchangedEntity) => unchangedEntity.id === entities[i].id && unchangedEntity.isIncluded,
                )
            ) {
                continue;
            }

            let existsInNewEntities = newEntities.find((entity) => entity.id === entities[i].id);
            if (entities[i].isIncluded) {
                if (!existsInNewEntities) {
                    newEntities.push({
                        id: entities[i].id,
                        label: entities[i].label,
                    });
                }
            } else {
                if (existsInNewEntities) {
                    newEntities = newEntities.filter((entity) => entity.id !== entities[i].id);
                }
            }
        }

        return newEntities;
    }

    handleCloseErrors = () => {
        this.setState({
            error: null,
        });
    };

    handleCloseImportNotification = () => {
        this.setState({
            importNotification: false,
        });
    };

    handleCloseNewEntitiesModal = () => {
        this.setState({
            newEntitiesModal: [],
        });
    };

    handleCloseReimportNotification = () => {
        this.setState({
            reImportNotification: false,
        });
    };

    getReloadingEntitiesToString = () => {
        let entities = [];
        this.state.newFieldsMap.forEach((value, key) => {
            entities.push(key);
        });
        return entities.join(', ');
    };

    handleAddObjectButtonClick = () => {
        this.setState({
            addObjectFormModal: true,
        });
    };

    addLoadingEntities(entities) {
        const loadingEntities = new Map(this.state.loadingEntities);
        for (let entity of entities) {
            loadingEntities.set(entity.name, entity.label);
        }
        this.setState({
            loadingEntities,
        });
    }

    removeLoadingEntitiesByApiNames(loadingEntitiesApiNames) {
        const loadingEntities = new Map(this.state.loadingEntities);
        for (let loadingEntitiesApiName of loadingEntitiesApiNames) {
            loadingEntities.delete(loadingEntitiesApiName);
        }
        this.setState({
            loadingEntities,
        });
    }

    importEntities(entitiesToImport) {
        this.addLoadingEntities(entitiesToImport);
        const entitiesToImportApiNames = entitiesToImport.map((entity) => entity.name);
        return this.getManager()
            .importEntities(entitiesToImportApiNames)
            .then(({ processId, alreadyImportedEntitiesApiNames }) => {
                if (null === processId) {
                    this.finishImportProcess(processId, entitiesToImportApiNames);
                    return;
                }

                this.setState((state) => {
                    const importEntitiesProcesses = new Set(state.importEntitiesProcesses);
                    importEntitiesProcesses.add(processId);
                    return {
                        importEntitiesProcesses,
                    };
                });

                if (Array.isArray(alreadyImportedEntitiesApiNames) && alreadyImportedEntitiesApiNames.length > 0) {
                    this.finishImportProcess(null, alreadyImportedEntitiesApiNames);
                }
            });
    }

    onEntitiesImportProgress = ({ entitiesIds, processId, originalEntitiesApiNames }) => {
        if (!this.state.importEntitiesProcesses.has(processId)) {
            return;
        }

        if (Array.isArray(entitiesIds) && entitiesIds.length === 0) {
            this.finishImportProcess(processId, originalEntitiesApiNames);
            return;
        }

        this.getManager()
            .getEntities(true, entitiesIds)
            .then((entities) => {
                const newEntities = [];
                const stateEntities = [...this.state.entities];
                for (let updatedEntity of entities) {
                    let stateEntityIndex = stateEntities.findIndex(
                        (stateEntity) => stateEntity.id === updatedEntity.id,
                    );
                    if (stateEntityIndex === -1) {
                        updatedEntity.isIncluded = true;
                        newEntities.push(updatedEntity);
                        stateEntities.push(updatedEntity);
                        stateEntityIndex = stateEntities.length - 1;
                    }

                    if (
                        stateEntities[stateEntityIndex].isIncluded &&
                        !this.state.loadingEntities.has(stateEntities[stateEntityIndex].apiName)
                    ) {
                        continue;
                    }

                    const fields = [...stateEntities[stateEntityIndex].fields];
                    for (let updatedField of updatedEntity.fields) {
                        if (!fields.find((field) => field.apiName === updatedField.apiName)) {
                            fields.push(updatedField);
                        }
                    }
                    stateEntities[stateEntityIndex] = {
                        ...stateEntities[stateEntityIndex],
                        fields: updatedEntity.fields,
                    };
                }

                this.setState(
                    {
                        entities: stateEntities,
                    },
                    () => {
                        const promises = [];
                        for (const newEntity of newEntities) {
                            promises.push(this.handleEntityActivityChanged(newEntity, newEntity.isIncluded, false));
                        }
                        Promise.all(promises).then(() => {
                            this.finishImportProcess(processId, originalEntitiesApiNames);
                        });
                    },
                );
            });
    };

    finishImportProcess(processId, importedEntitiesApiNames) {
        const entitiesWithUnmappedOwner = [...this.state.entitiesWithUnmappedOwner];
        for (const stateEntity of this.state.entities) {
            if (stateEntity.isIncluded && importedEntitiesApiNames.includes(stateEntity.name)) {
                this.handleEntityActivityChanged(stateEntity, stateEntity.isIncluded, false);
                if (
                    !stateEntity.fields.find(
                        (field) => stateEntity.ownerField && field.id === stateEntity.ownerField && field.isIncluded,
                    ) &&
                    !entitiesWithUnmappedOwner.find(
                        (entityWithUnmappedOwner) => entityWithUnmappedOwner.id === stateEntity.id,
                    )
                ) {
                    entitiesWithUnmappedOwner.push(stateEntity);
                }
            }
        }
        this.setState((state) => {
            const importEntitiesProcesses = new Set(state.importEntitiesProcesses);
            if (null !== processId) {
                importEntitiesProcesses.delete(processId);
            }
            return {
                importEntitiesProcesses,
                entitiesWithUnmappedOwner,
            };
        });
        this.updateNewFieldsAndNewEntities();
        this.removeLoadingEntitiesByApiNames(importedEntitiesApiNames);
    }

    handleAddObjectFormSave = async (entities) => {
        const entitiesToImport = [];
        for (let entity of entities) {
            let originalEntity = this.state.entities.find((stateEntity) => stateEntity.name === entity.name);
            if (originalEntity) {
                if (originalEntity.isIncluded !== entity.isIncluded) {
                    try {
                        await this.handleEntityActivityChanged(originalEntity, entity.isIncluded, false);
                    } catch (error) {
                        this.props.enqueueSnackbar(
                            this.props.t('entities_list_form.searchable_entities_list_form.error.unable_to_save', {
                                message: error['message'],
                            }),
                            { variant: 'error' },
                        );
                    }
                    if (entity.isIncluded) {
                        entitiesToImport.push(entity);
                    }
                }
            } else if (entity.isIncluded) {
                entitiesToImport.push(entity);
            }
        }
        this.updateNewFieldsAndNewEntities();

        this.setState({
            addObjectFormModal: false,
        });

        if (entitiesToImport.length > 0) {
            this.importEntities(entitiesToImport);
        }
    };

    handleAddObjectFormCancel = () => {
        this.setState({
            addObjectFormModal: false,
        });
    };

    handleCloseUnmappedOwnerModal = () => {
        this.setState({
            entitiesWithUnmappedOwner: [],
        });
    };

    addLockedByToEntities(entities) {
        const lookupFields = {};

        for (let entity of entities) {
            entity.isLockedBy = [];

            for (let field of entity.fields) {
                if (!field.isIncluded || field.isDeleted) {
                    continue;
                }
                if (!field.lookupData || !field.lookupData.apiName) {
                    continue;
                }
                const apiName = field.lookupData.apiName;
                if (lookupFields[apiName] === undefined) {
                    lookupFields[apiName] = [];
                }
                lookupFields[apiName].push({
                    field: {
                        label: field.label,
                        apiName: field.apiName,
                        originalApiName: field.originalApiName,
                    },
                    entity: {
                        label: entity.label,
                        apiName: entity.name,
                    },
                });
                if (field.lookupData.linking_lookup_data && field.lookupData.linking_lookup_data.entity) {
                    const MSLApiName = field.lookupData.linking_lookup_data.entity;
                    if (lookupFields[MSLApiName] === undefined) {
                        lookupFields[MSLApiName] = [];
                    }
                    lookupFields[MSLApiName].push({
                        field: {
                            label: field.label,
                            apiName: field.apiName,
                            originalApiName: field.originalApiName,
                        },
                        entity: {
                            label: entity.label,
                            apiName: entity.name,
                        },
                    });
                }
            }
        }
        logDebug('lookupFields', lookupFields);
        for (let apiName of Object.keys(lookupFields)) {
            for (let field of lookupFields[apiName]) {
                let entity = entities.find((entity) => entity.isIncluded && entity.name === apiName);
                if (entity) {
                    let blockingEntity = entities.find(
                        (blockingEntity) =>
                            blockingEntity.isIncluded &&
                            blockingEntity.name === field.entity.apiName &&
                            blockingEntity.id !== entity.id,
                    );
                    if (blockingEntity) {
                        entity.isLockedBy.push(field);
                    }
                }
            }
        }
        return entities;
    }

    addRelatedEntityDataToLookupFields(entities) {
        for (let entity of entities) {
            for (let field of entity.fields) {
                if (!field.lookupData || !field.lookupData.apiName) {
                    continue;
                }
                let lookupEntity = entities.find((lookupEntity) => lookupEntity.name === field.lookupData.apiName);
                if (lookupEntity) {
                    const data = {
                        label: lookupEntity.label,
                        isIncluded: lookupEntity.isIncluded,
                    };

                    if (field.lookupData.linking_lookup_data && field.lookupData.linking_lookup_data.entity) {
                        const linkedLookupEntity = entities.find(
                            (linkedLookupEntity) =>
                                linkedLookupEntity.name === field.lookupData.linking_lookup_data.entity,
                        );
                        if (linkedLookupEntity) {
                            data.linkedLookupEntity = {
                                label: linkedLookupEntity.label,
                                isIncluded: linkedLookupEntity.isIncluded,
                            };
                        }
                    }
                    field.lookupData[LOOKUP_DATA_FIELD] = data;
                }
            }
        }
        return entities;
    }

    includeRequiredEntitiesForEntity(entities, entity, ds) {
        for (let field of entity.fields.filter(
            (field) => field.isIncluded && field.lookupData && field.lookupData.apiName,
        )) {
            let requiredEntity = entities.find((requiredEntity) => requiredEntity.name === field.lookupData.apiName);
            if (requiredEntity && !requiredEntity.isIncluded) {
                requiredEntity.isIncluded = true;
                const protectedFields = EntityHandler.getProtectedFields(requiredEntity, ds);
                requiredEntity.fields.map((requiredEntityField) => {
                    requiredEntityField.isIncluded =
                        !!protectedFields.get(requiredEntityField.apiName) ||
                        requiredEntityField.id === entity.ownerField;
                    return requiredEntityField;
                });
                this.includeRequiredEntitiesForEntity(entities, requiredEntity, ds);
            }
            if (field.lookupData.linking_lookup_data && field.lookupData.linking_lookup_data.entity) {
                let requiredLinkedEntity = entities.find(
                    (requiredLinkedEntity) => requiredLinkedEntity.name === field.lookupData.linking_lookup_data.entity,
                );
                if (requiredLinkedEntity && !requiredLinkedEntity.isIncluded) {
                    requiredLinkedEntity.isIncluded = true;
                    const protectedFields = EntityHandler.getProtectedFields(requiredLinkedEntity, ds);
                    requiredLinkedEntity.fields.map((requiredLinkedEntityField) => {
                        requiredLinkedEntityField.isIncluded =
                            !!protectedFields.get(requiredLinkedEntityField.apiName) ||
                            requiredLinkedEntityField.id === entity.ownerField;
                        return requiredLinkedEntityField;
                    });
                    this.includeRequiredEntitiesForEntity(entities, requiredLinkedEntity, ds);
                }
            }
        }
        return entities;
    }

    handleHistoryBlocker = (cb) => {
        cb(false);
        this.saveButtonRef.current?.click();
    };

    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return t('loading');
        }
        if (this.state.entities === null) {
            // An error has occurred
            return null;
        }
        let entities = [...this.state.entities];
        if (this.props.entityId !== null) {
            entities = entities.sort((entityA, entityB) => {
                return entityA.id === this.props.entityId ? -1 : entityB.id === this.props.entityId ? 1 : 0;
            });
        }

        return (
            <div className="c-data-source">
                <StickyContainer>
                    <Sticky>
                        {({ style, isSticky }) => {
                            const newStyle = { ...style };
                            if (isSticky) {
                                delete newStyle['transform'];
                                newStyle['zIndex'] = 100;
                                newStyle['boxShadow'] = '1px 2px 5px #b3b3b3';
                                newStyle['padding'] = '16px';
                                newStyle['backgroundColor'] = '#fff';
                                newStyle['left'] = 0;
                                newStyle['right'] = 0;
                                newStyle['width'] = 'auto';
                            } else {
                                newStyle['padding'] = '16px 0';
                            }
                            return (
                                <div style={newStyle}>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Grid container alignItems="center" justify="flex-start">
                                                <Grid item>
                                                    <h1 style={{ margin: 0 }}>
                                                        {t('data_source.header', { dsName: this.state.ds.name })}
                                                    </h1>
                                                </Grid>
                                                <Grid item style={{ paddingLeft: 10 }}>
                                                    {!this.state.ds.isSystem && (
                                                        <Button
                                                            variant="contained"
                                                            color="default"
                                                            onClick={this.handleAddObjectButtonClick}
                                                            data-testid="data_source.entity.add_object"
                                                        >
                                                            <i
                                                                style={{ marginRight: '5px', fontSize: '18px' }}
                                                                className="fas fa-plus-circle"
                                                            />{' '}
                                                            {this.props.t('data_source.entity.add_object')}
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                {this.isSyncingAvailable(this.state.ds) && (
                                                    <SyncRefresh
                                                        dsId={this.props.dataSourceId}
                                                        dsName={this.state.ds.name}
                                                        onRefreshRequest={this.handleAcceptRefresh}
                                                        onSync={this.handleSync}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                this.state.ds.isSyncing
                                                                    ? t(
                                                                          'data_source.sync_refresh.checking_for_metadata_changes',
                                                                      )
                                                                    : t(
                                                                          'data_source.sync_refresh.check_for_metadata_changes',
                                                                      )
                                                            }
                                                        >
                                                            <span style={{ marginRight: 16 }}>
                                                                <IconButton
                                                                    onClick={this.forceSync}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={
                                                                        this.state.ds.isSyncing ||
                                                                        !this.isSyncingAvailable(this.state.ds)
                                                                    }
                                                                    data-testid="data_source.sync_refresh.force_sync"
                                                                >
                                                                    {this.state.ds.isSyncing ? (
                                                                        <CircularProgress size={24} />
                                                                    ) : (
                                                                        <Icon>autorenew</Icon>
                                                                    )}
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </SyncRefresh>
                                                )}

                                                <Button
                                                    onClick={this.handleSave}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.saving}
                                                    data-testid="data_source.save_and_update"
                                                    ref={this.saveButtonRef}
                                                >
                                                    {t('data_source.save_and_update')}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        }}
                    </Sticky>

                    <ChangesNotification
                        changeNotes={this.state.changeNotes}
                        accountId={this.props.accountId}
                        dataSource={this.state.ds}
                        onRead={this.handleReadChanges}
                    />

                    {this.state.error && (
                        <Alert canClose onClose={this.handleCloseErrors} placement="context">
                            <div>{t('data_source.unable_save_data', { message: this.state.error['message'] })}</div>
                            <ErrorMessage details={this.state.error['details'].get('entities')} />
                        </Alert>
                    )}
                    <DataSourceMessages dataSource={this.state.ds} subscription={this.state.subscription} />
                    {entities.filter((entity) => entity.isIncluded).length > 0 && (
                        <Backdrop loading={this.state.saving} task={t('data_source.saving')}>
                            {entities.map((entity) => {
                                return (
                                    entity.isIncluded && (
                                        <Backdrop
                                            key={entity.id}
                                            loading={this.state.loadingEntities.has(entity.name)}
                                            style={{ minHeight: 'unset', marginBottom: 5 }}
                                        >
                                            <DsEntity
                                                entity={entity}
                                                onEntityActivityChanged={this.handleEntityActivityChanged}
                                                onEntityObjectNameChanged={this.handleEntityObjectNameChanged}
                                                onSettingsSaved={this.handleSettingsSaved}
                                                onScheduleSaved={this.handleScheduleSaved}
                                                onFieldChanged={this.handleFieldChanged}
                                                onPinChanged={this.handlePinChanged}
                                                onOwnerFieldChanged={this.handleOwnerFieldChanged}
                                                onCheckAll={this.handleCheckAll}
                                                appointmentsData={this.state.appointments}
                                                //onConflictIconChanged={this.handleConflictIconChanged}
                                                onGeoMapperSaved={this.handleGeoMappingChanged}
                                                open={entity.id === this.props.entityId}
                                                dataSource={this.state.ds}
                                                subscription={this.state.subscription}
                                                entityActiveCalendars={this.state.activeCalendars.filter(
                                                    (calendar) => calendar.entityId === entity.id,
                                                )}
                                            />
                                        </Backdrop>
                                    )
                                );
                            })}
                        </Backdrop>
                    )}
                    {Array.from(this.state.loadingEntities)
                        .filter((loadingEntity) => !entities.find((entity) => entity.name === loadingEntity[0]))
                        .map((loadingEntity) => {
                            const apiName = loadingEntity[0];
                            const label = loadingEntity[1];
                            return (
                                <Backdrop key={apiName} loading style={{ minHeight: 'unset', marginBottom: 5 }}>
                                    <DsEntityDummy label={label} apiName={apiName} />
                                </Backdrop>
                            );
                        })}
                </StickyContainer>

                {this.state.reImportNotification && (
                    <Dialog open onClose={this.handleCloseReimportNotification}>
                        <DialogContent>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('data_source.re_import_notification.text', {
                                        entities: t('data_source.re_import_notification.reloading_entities', {
                                            entities: this.getReloadingEntitiesToString(),
                                        }),
                                        accountSettings: t('data_source.re_import_notification.account_settings'),
                                    }),
                                }}
                            ></div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCloseReimportNotification}
                                color="primary"
                                data-testid="data_source.reimport.button.cancel"
                            >
                                {t('button.cancel')}
                            </Button>
                            <Button
                                data-reimport="on"
                                onClick={this.handleSave}
                                color="primary"
                                autoFocus
                                data-testid="data_source.reimport.button.ok"
                            >
                                {t('button.ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {!!this.state.newEntitiesModal.length && (
                    <Dialog
                        open
                        onClose={this.handleCloseNewEntitiesModal}
                        className="c-form-dialog c-form-dialog--wide"
                    >
                        <DialogTitle>{t('attention')}</DialogTitle>
                        <DialogContent>
                            <div>{t('data_source.modal.new_entities.1')}</div>
                            <div>
                                <ul>
                                    {this.state.newEntitiesModal.map((e) => (
                                        <li key={e}>{e}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>{t('data_source.modal.new_entities.2')}</div>
                            <div>
                                <ul>
                                    <li>{t('data_source.modal.new_entities.3')}</li>
                                    <li>{t('data_source.modal.new_entities.4')}</li>
                                    <li>{t('data_source.modal.new_entities.5')}</li>
                                </ul>
                            </div>
                            <div>
                                {t('data_source.modal.new_entities.6')}{' '}
                                <Link
                                    to={reverse(routes.admin.account.permissions, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                    })}
                                >
                                    <i>{t('permissions.title')}</i>
                                </Link>
                                .{' '}
                                <LandingLink
                                    article="4337620"
                                    useTrailingIcon
                                    style={{ fontSize: 'smaller', marginLeft: '16px' }}
                                >
                                    {t('data_source.modal.new_entities.7')}
                                </LandingLink>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCloseNewEntitiesModal}
                                color="primary"
                                autoFocus
                                data-testid="data_source.new_entities.button.understand"
                            >
                                {t('button.understand')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {this.state.importNotification && (
                    <Dialog open onClose={this.handleCloseImportNotification}>
                        <DialogTitle>{t('data_source.import_notification.title')}</DialogTitle>
                        <DialogContent>
                            <p>{t('data_source.import_notification.ask_turn', { dsName: this.state.ds.name })}</p>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-toggle-import="on"
                                onClick={this.handleSave}
                                color="primary"
                                autoFocus
                                data-testid="data_source.import.button.yes"
                            >
                                {t('yes')}
                            </Button>
                            <Button
                                data-toggle-import="off"
                                onClick={this.handleSave}
                                color="primary"
                                data-testid="data_source.import.button.no"
                            >
                                {t('no')}
                            </Button>
                            <Button
                                onClick={this.handleCloseImportNotification}
                                color="default"
                                data-testid="data_source.import.button.cancel"
                            >
                                {t('button.cancel')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {!!this.state.entitiesWithUnmappedOwner.length && (
                    <Dialog open onClose={this.handleCloseUnmappedOwnerModal}>
                        <DialogContent>
                            <p>{this.props.t('data_source.entity.unmapped_owner.p.1')}</p>
                            <ul>
                                {this.state.entitiesWithUnmappedOwner.map((entity, index) => {
                                    return <li key={index}>{entity.label}</li>;
                                })}
                            </ul>
                            <p>{this.props.t('data_source.entity.unmapped_owner.p.2')}</p>
                            <LandingLink article="4337620" useTrailingIcon>
                                {this.props.t('data_source.entity.unmapped_owner.learn_more')}
                            </LandingLink>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCloseUnmappedOwnerModal}
                                color="default"
                                data-testid="data_source.unmapped_owner.modal.btn.close"
                            >
                                {this.props.t('ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {this.state.addObjectFormModal && (
                    <FormDialog
                        title={this.props.t('data_source.entity.add_object')}
                        onSave={this.handleAddObjectFormSave}
                        onCancel={this.handleAddObjectFormCancel}
                        saveButtonTitle={this.props.t('ok')}
                        maxWidth="md"
                        dialogClassName="c-geo-mapper"
                    >
                        <SearchableEntitiesListForm
                            accountId={this.props.accountId}
                            dataSourceId={this.props.dataSourceId}
                            entities={this.state.entities || []}
                        />
                    </FormDialog>
                )}

                <HistoryBlocker
                    isBlocking={this.isModified()}
                    onSave={this.handleHistoryBlocker}
                    buttonSave={t('button.save')}
                />
            </div>
        );
    }
}

DataSource.propTypes = {
    accountId: PropTypes.number.isRequired,
    dataSourceId: PropTypes.number.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    myAccount: PropTypes.bool,
    entityId: PropTypes.number,
};

DataSource.defaultProps = {
    myAccount: false,
    entityId: null,
};

export default withTranslation('translations', { withRef: true })(withRouter(withSnackbar(DataSource)));
