import React from 'react';
import { FormControl, Grid, MenuItem, Select, InputLabel } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LookupConstantInput from './LookupConstantInput';

class PolymorphicLookupConstantInput extends React.PureComponent {
    handleEntityChange = (event) => {
        const entity = event.target.value;
        if (!entity) {
            this.props.onChange(null);
            return;
        }
        this.props.onChange({
            entity,
            value: null,
        });
    };

    handleValueChange = (value) => {
        const { entity } = this.getValue();
        this.props.onChange({
            entity,
            value,
        });
    };

    getValue() {
        if (this.props.value === null || typeof this.props.value !== 'object') {
            return { entity: null, value: null };
        }
        const { entity, value } = this.props.value;
        if (!entity || !this.props.availableEntities.find((item) => item.api_name === entity)) {
            return { entity: null, value: null };
        }

        return { entity, value };
    }

    render() {
        const { t } = this.props;
        const { entity, value } = this.getValue();

        return (
            <Grid container alignItems="flex-start" direction="row" spacing={1}>
                <Grid item>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>{t('expresion_builder.entity')}</InputLabel>
                        <Select
                            value={entity || ''}
                            onChange={this.handleEntityChange}
                            displayEmpty
                            label={t('expresion_builder.entity')}
                            data-testid="polymorphic_lookup_constant_input.entity_select"
                        >
                            <MenuItem key="" value="">
                                {t('undefined_picklist_option')}
                            </MenuItem>
                            {this.props.availableEntities.map((item) => (
                                <MenuItem key={item.api_name} value={item.api_name}>
                                    {item.display_label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {entity !== null && (
                    <Grid item>
                        <LookupConstantInput
                            lookupEntity={entity}
                            value={value}
                            onChange={this.handleValueChange}
                            multiple={this.props.multiple}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default withTranslation('translations', { withRef: true })(PolymorphicLookupConstantInput);
