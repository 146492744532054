import React, { ComponentProps, createElement, forwardRef, RefObject } from 'react';
import clsx from 'clsx';
import MUIButton, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button';
import throttle from 'lodash/throttle';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import './styles.css';
import { FormatTypeWithPrefix } from 'types';

const ONE_DOT_REG = /\.$/;
const ELLIPSES_REG = /\.\.\.$/;
const LAST_SYMBOL_REG = /.$/;

const buttonStyles = (theme: Theme) => {
    return createStyles({
        root: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            '& .MuiTouchRipple-root': {
                color: theme.palette.button.midnight,
                '& .MuiTouchRipple-rippleVisible': {
                    opacity: 0.15,
                },
            },
            '&.Mui-disabled': {
                color: theme.palette.button.grey.main,
                backgroundColor: theme.palette.button.grey.light,
            },
        },
        typePrimary: {
            backgroundColor: theme.palette.button.primary.light,
            color: theme.palette.button.white,
            '&:hover': {
                backgroundColor: theme.palette.button.primary.main,
            },
        },
        typeSecondary: {
            backgroundColor: theme.palette.button.lavender.light,
            color: theme.palette.button.primary.light,
            '&:hover': {
                backgroundColor: theme.palette.button.lavender.main,
            },
        },
        typeLink: {
            color: theme.palette.button.primary.light,
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
                backgroundColor: 'transparent',
            },
        },
        typeText: {
            color: theme.palette.button.boulder,
            '&:hover': {
                color: theme.palette.button.primary.light,
                backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.button.grey.light,
            },
        },
        typeNegative: {
            color: theme.palette.button.negative,
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
                backgroundColor: 'transparent',
            },
        },
        fullWidth: {
            width: '100%',
        },
    });
};

type ButtonType = 'primary' | 'secondary' | 'link' | 'text' | 'negative';

type TypeClasses = FormatTypeWithPrefix<'type', ButtonType>;

type ButtonProps = Omit<MUIButtonProps, 'variant' | 'size' | 'type' | 'buttonRef'> &
    WithStyles<typeof buttonStyles> & {
        buttonRef?: RefObject<HTMLButtonElement>;
        buttonType: ButtonType;
        parentObserver?: HTMLElement;
        size: 'small' | 'medium';
        children: string;
    };

interface ButtonState {
    text: string;
}

class Button extends React.PureComponent<ButtonProps, ButtonState> {
    private _buttonRef = React.createRef<HTMLButtonElement>();
    // naive fork
    private buttonRef: RefObject<HTMLButtonElement> = (() => {
        const that = this;
        return {
            get current() {
                return that._buttonRef.current;
            },
            set current(val: any) {
                // @ts-expect-error - i really want to set it =)
                that._buttonRef.current = val;
                // @ts-expect-error - i really want to set it =)
                that.props.buttonRef && (that.props.buttonRef.current = val);
            },
        };
    })();
    private resizeObserver?: ResizeObserver;

    static defaultProps: Pick<ButtonProps, 'buttonType' | 'size'>;

    constructor(props: ButtonProps) {
        super(props);

        this.state = {
            text: props.children,
        };
    }

    getVariantByType = (type: string): MUIButtonProps['variant'] => {
        switch (type) {
            default:
            case 'primary':
            case 'secondary': {
                return 'contained';
            }
            case 'text':
            case 'link':
            case 'negative': {
                return 'text';
            }
        }
    };

    componentDidMount(): void {
        if (this.buttonRef.current) {
            if (!this.resizeObserver) {
                this.resizeObserver = new ResizeObserver(
                    throttle(() => {
                        this.setState({ text: this.getText() });
                    }, 100),
                );
                this.resizeObserver.observe(this.buttonRef.current);

                if (this.props.parentObserver) {
                    this.resizeObserver.observe(this.props.parentObserver);
                } else {
                    this.resizeObserver.observe(document.body);
                }
            }

            this.setState({
                text: this.getText(),
            });
        }
    }

    componentWillUnmount(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    componentDidUpdate(prevProps: Readonly<ButtonProps>): void {
        if (prevProps.children !== this.props.children) {
            this.setState({ text: this.getText() });
        }
    }

    getText = () => {
        const { children } = this.props;

        if (this.buttonRef.current) {
            let isTrim = true;
            let text = children;
            let isOneDot = false;

            const [labelElement] = this.buttonRef.current.getElementsByClassName(
                'MuiButton-label',
            ) as HTMLCollectionOf<HTMLElement>;

            while (isTrim) {
                labelElement.innerHTML = text;

                if (labelElement.offsetWidth < labelElement.scrollWidth) {
                    const cleanText = text.replace(isOneDot ? ONE_DOT_REG : ELLIPSES_REG, '');

                    if (cleanText.length < 5 && !isOneDot) {
                        isOneDot = true;

                        text = cleanText + '.';
                    } else {
                        text = cleanText.replace(LAST_SYMBOL_REG, isOneDot ? '.' : '...');
                    }
                } else {
                    isTrim = false;
                }
            }

            return text;
        }

        return children;
    };

    render() {
        const { buttonType: type, size, className, children, classes, disableElevation = true, ...props } = this.props;

        const variant = this.getVariantByType(type);

        const typeClass = `type${type[0].toUpperCase() + type.slice(1)}` as TypeClasses;

        return (
            <MUIButton
                {...props}
                disableElevation={disableElevation}
                ref={this.buttonRef}
                variant={variant}
                size={size}
                className={clsx('button', classes.root, classes[typeClass], className)}
            >
                {this.state.text}
            </MUIButton>
        );
    }
}

Button.defaultProps = {
    buttonType: 'primary',
    size: 'medium',
};

const WithStylesComponent = withStyles(buttonStyles)(Button);
type WithStylesComponentProps = ComponentProps<typeof WithStylesComponent>;
const WithForwardRef = forwardRef<HTMLButtonElement, Omit<WithStylesComponentProps, 'buttonRef'>>((props, ref) =>
    createElement(WithStylesComponent, { ...props, buttonRef: ref as RefObject<HTMLButtonElement> }),
);

export default WithForwardRef;
