import React from 'react';
import { TextField, FormControl, CircularProgress, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { withSnackbar } from 'notistack';
import LiveSearchFactory from '../../../service/LiveSearchManager';
import PropTypes from 'prop-types';
import AccountContext from '../../Context/AccountContext';
import DataSourceContext from '../../Context/DataSourceContext';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';

export default (props) => {
    return (
        <AccountContext.Consumer>
            {(accountId) => (
                <DataSourceContext.Consumer>
                    {(dsId) => <LookupConstantInput accountId={accountId} dsId={dsId} {...props} />}
                </DataSourceContext.Consumer>
            )}
        </AccountContext.Consumer>
    );
};

class BaseLookupConstantInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            options: [],
            inputValue: '',
            value: [],
        };

        this.iv = '';

        this.liveSearchManager = LiveSearchFactory.getManager(this.props.accountId, this.props.dsId);
        this.searchTimeout = null;
        this.searchString = null;
        this.searchIds = null;
        this.valuesHash = null;
    }

    updateOptions(options) {
        const { value } = this.state;
        // сохранить в опциях текущие выбранные значения (добавить в список если их нет)
        for (let option of this.state.options) {
            if (value.includes(option) && !options.find((o) => o.value === option.value)) {
                options.push(option);
            }
        }
        this.setState({
            options,
        });
    }

    getValuesHash(values) {
        if (values.length === 0) {
            return null;
        }
        return [...values].sort().join('|');
    }

    updateValuesHash(values) {
        this.valuesHash = this.getValuesHash(values);
    }

    getValueFromProps() {
        if (!this.props.value) {
            return [];
        } else if (!Array.isArray(this.props.value)) {
            return [this.props.value];
        }
        return this.props.value;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let lookupValue = this.getValueFromProps();

        const valuesHash = this.getValuesHash(lookupValue);
        if (valuesHash !== this.valuesHash) {
            this.updateValuesHash(lookupValue);
            this.initValues();
        }
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_SEARCH_RECORDS_RESPONSE, this, this.onSearchCompleted);

        this.initValues();
    }

    initValues() {
        const { ownerLookup, lookupEntity } = this.props;

        let lookupValue = this.getValueFromProps();

        if (lookupValue.length === 0) {
            this.setState({
                value: [],
            });

            return;
        }

        this.setState({
            loading: true,
        });

        if (ownerLookup) {
            this.searchIds = lookupValue.join('|');

            this.liveSearchManager
                .searchOwnersByIds(lookupValue)
                .then(() => {
                    this.searchTimeout = setTimeout(() => {
                        this.setState({
                            loading: false,
                        });
                        this.searchTimeout = null;
                        this.searchString = null;
                    }, 30000);
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                    });
                    this.searchString = null;
                });

            return;
        }

        // load full values
        this.liveSearchManager
            .searchLookupRecordsByIds(lookupEntity, lookupValue)
            .then((response) => {
                const options = response.items.map((item) => ({
                    value: item.id,
                    label: item.objectName,
                }));

                this.updateOptions(options);

                this.setState({
                    value: [...options],
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
                this.searchString = null;
            });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WS_SEARCH_RECORDS_RESPONSE, this);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (this.state.inputValue.length < 2) {
                return;
            }
            this.handleSearch();
        }
    };

    handleInputChange = (e, value) => {
        this.setState({
            inputValue: value || '',
            loading: false,
        });
    };

    setOpen = (event) => {
        if (event.currentTarget.tagName.toLowerCase() === 'input') {
            return;
        }
        if (this.state.inputValue.length < 2) {
            return;
        }

        if (this.iv === this.state.inputValue) {
            this.setState({
                open: true,
            });
            return;
        }

        this.handleSearch();
    };

    setClose = () => {
        this.setState({
            open: false,
        });
    };

    handleChange = (event, items) => {
        if (Array.isArray(items)) {
            const value = items.map((item) => item.value);
            this.updateValuesHash(value);

            this.setState({
                value: items,
            });

            this.props.onChange(value);
        } else {
            const value = items ? items.value : null;
            this.updateValuesHash(items ? [items.value] : []);

            this.setState({
                value: items ? [items] : [],
            });

            this.props.onChange(value);
        }
    };

    onSearchCompleted = ({ search, ids, error, records }) => {
        const searchIds = ids ? ids.join('|') : '';
        if (search !== this.searchString && this.searchIds !== searchIds) {
            return;
        }

        if (null !== this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }

        this.setState({
            loading: false,
        });

        if (error) {
            //this.props.onSubmitError(error);
            return;
        }

        const options = records.map((record) => ({
            value: record.id,
            label: record.data.fullName,
        }));

        // если был поиск по ид, то...
        if (ids !== null) {
            this.updateOptions(options);

            this.setState({
                value: [...options],
                loading: false,
            });
        } else {
            this.updateOptions(options);
            this.setState({
                open: true,
                loading: false,
            });
        }
        this.searchString = null;
    };

    handleSearch = () => {
        this.updateOptions([]);
        this.setState({
            open: true,
            loading: true,
        });

        const { ownerLookup, lookupEntity } = this.props;

        if (ownerLookup) {
            this.searchString = this.state.inputValue;
            if (null !== this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.liveSearchManager
                .searchOwner(this.state.inputValue)
                .then(() => {
                    this.searchTimeout = setTimeout(() => {
                        this.setState({
                            loading: false,
                        });
                        this.searchTimeout = null;
                    }, 30000);
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                    });
                });
            return;
        }

        this.liveSearchManager
            .searchEntity(lookupEntity, this.state.inputValue)
            .then((response) => {
                this.iv = this.state.inputValue;
                const options = response.records.map((record) => ({
                    value: record.id,
                    label: record.data.objectName,
                }));
                this.updateOptions(options);
                this.setState({
                    open: true,
                    loading: false,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { classes } = this.props;

        return (
            <FormControl variant="outlined">
                <Autocomplete
                    multiple={this.props.multiple}
                    onInputChange={this.handleInputChange}
                    inputValue={this.state.inputValue}
                    className={classes.root}
                    style={{ width: 300 }}
                    open={this.state.open}
                    onOpen={this.setOpen}
                    onClose={this.setClose}
                    onChange={this.handleChange}
                    getOptionSelected={(option, value) => {
                        return value === option;
                    }}
                    options={this.state.options}
                    value={this.props.multiple ? this.state.value : this.state.value[0] || ''}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        return option.label;
                    }}
                    loading={this.state.loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            onKeyDown={this.handleKeyDown}
                            data-testid="lookup_constant_input.value"
                        />
                    )}
                />
            </FormControl>
        );
    }
}

BaseLookupConstantInput.propTypes = {
    ownerLookup: PropTypes.bool,
    multiple: PropTypes.bool,
};

BaseLookupConstantInput.defaultProps = {
    ownerLookup: false,
    multiple: false,
};

const singleLineStyle = () => ({
    root: {
        width: 150,
        '& > .MuiFormControl-root .MuiInputBase-root': {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            position: 'initial',
        },
    },
});

const LookupConstantInput = withTranslation('translations', { withRef: true })(
    withStyles(singleLineStyle)(withSnackbar(BaseLookupConstantInput)),
);
