import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';

type Timezone = {
    label: string;
    name: string;
};

type TimezoneWithOffset = Timezone & {
    offset: number; // in hours
};

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

const timezones: Timezone[] = [
    {
        label: 'Niue Time',
        name: 'Pacific/Niue',
    },
    {
        label: 'Midway Time',
        name: 'Pacific/Midway',
    },
    {
        label: 'Samoa Standard Time',
        name: 'Pacific/Pago_Pago',
    },
    {
        label: 'Cook Islands Standard Time',
        name: 'Pacific/Rarotonga',
    },
    {
        label: 'Hawaii-Aleutian Standard Time',
        name: 'Pacific/Honolulu',
    },
    {
        label: 'Hawaii-Aleutian Time',
        name: 'America/Adak',
    },
    {
        label: 'Tahiti Time',
        name: 'Pacific/Tahiti',
    },
    {
        label: 'Johnston Time',
        name: 'Pacific/Johnston',
    },
    {
        label: 'Marquesas Time',
        name: 'Pacific/Marquesas',
    },
    {
        label: 'Gambier Time',
        name: 'Pacific/Gambier',
    },
    {
        label: 'Alaska Time - Juneau',
        name: 'America/Juneau',
    },
    {
        label: 'Alaska Time - Yakutat',
        name: 'America/Yakutat',
    },
    {
        label: 'Alaska Time - Sitka',
        name: 'America/Sitka',
    },
    {
        label: 'Alaska Time - Nome',
        name: 'America/Nome',
    },
    {
        label: 'Alaska Time - Anchorage',
        name: 'America/Anchorage',
    },
    {
        label: 'Pacific Time - Whitehorse',
        name: 'America/Whitehorse',
    },
    {
        label: 'Pitcairn Time',
        name: 'Pacific/Pitcairn',
    },
    {
        label: 'Pacific Time - Dawson',
        name: 'America/Dawson',
    },
    {
        label: 'Pacific Time - Vancouver',
        name: 'America/Vancouver',
    },
    {
        label: 'Pacific Time - Tijuana',
        name: 'America/Tijuana',
    },
    {
        label: 'Pacific Time - Metlakatla',
        name: 'America/Metlakatla',
    },
    {
        label: 'Pacific Time - Los Angeles',
        name: 'America/Los_Angeles',
    },
    {
        label: 'Mountain Time - Denver',
        name: 'America/Denver',
    },
    {
        label: 'Mountain Time - Yellowknife',
        name: 'America/Yellowknife',
    },
    {
        label: 'Mountain Time - Ojinaga',
        name: 'America/Ojinaga',
    },
    {
        label: 'Mountain Time - Inuvik',
        name: 'America/Inuvik',
    },
    {
        label: 'Mountain Time - Edmonton',
        name: 'America/Edmonton',
    },
    {
        label: 'Mountain Standard Time - Creston',
        name: 'America/Creston',
    },
    {
        label: 'Mountain Time - Cambridge Bay',
        name: 'America/Cambridge_Bay',
    },
    {
        label: 'Mountain Standard Time - Phoenix',
        name: 'America/Phoenix',
    },
    {
        label: 'Mountain Standard Time - Fort Nelson',
        name: 'America/Fort_Nelson',
    },
    {
        label: 'Mountain Standard Time - Dawson Creek',
        name: 'America/Dawson_Creek',
    },
    {
        label: 'Mexican Pacific Time - Mazatlan',
        name: 'America/Mazatlan',
    },
    {
        label: 'Mexican Pacific Time - Chihuahua',
        name: 'America/Chihuahua',
    },
    {
        label: 'Mexican Pacific Standard Time',
        name: 'America/Hermosillo',
    },
    {
        label: 'Mountain Time - Boise',
        name: 'America/Boise',
    },
    {
        label: 'Central Time - Rainy River',
        name: 'America/Rainy_River',
    },
    {
        label: 'Central Time - Mexico City',
        name: 'America/Mexico_City',
    },
    {
        label: 'Central Time - Monterrey',
        name: 'America/Monterrey',
    },
    {
        label: 'Central Time - New Salem, North Dakota',
        name: 'America/North_Dakota/New_Salem',
    },
    {
        label: 'Galapagos Time',
        name: 'Pacific/Galapagos',
    },
    {
        label: 'Central Time - Rankin Inlet',
        name: 'America/Rankin_Inlet',
    },
    {
        label: 'Central Time - Resolute',
        name: 'America/Resolute',
    },
    {
        label: 'Central Time - Tell City, Indiana',
        name: 'America/Indiana/Tell_City',
    },
    {
        label: 'Central Time - Winnipeg',
        name: 'America/Winnipeg',
    },
    {
        label: 'Central Time - Menominee',
        name: 'America/Menominee',
    },
    {
        label: 'Central Time - Merida',
        name: 'America/Merida',
    },
    {
        label: 'Central Standard Time - Guatemala',
        name: 'America/Guatemala',
    },
    {
        label: 'Central Time - Matamoros',
        name: 'America/Matamoros',
    },
    {
        label: 'Central Standard Time - Regina',
        name: 'America/Regina',
    },
    {
        label: 'Central Time - Knox, Indiana',
        name: 'America/Indiana/Knox',
    },
    {
        label: 'Central Standard Time - Costa Rica',
        name: 'America/Costa_Rica',
    },
    {
        label: 'Central Standard Time - El Salvador',
        name: 'America/El_Salvador',
    },
    {
        label: 'Central Standard Time - Managua',
        name: 'America/Managua',
    },
    {
        label: 'Central Standard Time - Belize',
        name: 'America/Belize',
    },
    {
        label: 'Central Standard Time - Swift Current',
        name: 'America/Swift_Current',
    },
    {
        label: 'Central Time - Bahia Banderas',
        name: 'America/Bahia_Banderas',
    },
    {
        label: 'Central Time - Beulah, North Dakota',
        name: 'America/North_Dakota/Beulah',
    },
    {
        label: 'Central Time - Center, North Dakota',
        name: 'America/North_Dakota/Center',
    },
    {
        label: 'Central Time - Chicago',
        name: 'America/Chicago',
    },
    {
        label: 'Central Standard Time - Tegucigalpa',
        name: 'America/Tegucigalpa',
    },
    {
        label: 'Eastern Time - Toronto',
        name: 'America/Toronto',
    },
    {
        label: 'Eastern Time - Pangnirtung',
        name: 'America/Pangnirtung',
    },
    {
        label: 'Eastern Time - Petersburg, Indiana',
        name: 'America/Indiana/Petersburg',
    },
    {
        label: 'Eastern Time - Port-au-Prince',
        name: 'America/Port-au-Prince',
    },
    {
        label: 'Eastern Time - Thunder Bay',
        name: 'America/Thunder_Bay',
    },
    {
        label: 'Ecuador Time',
        name: 'America/Guayaquil',
    },
    {
        label: 'Eastern Time - Vevay, Indiana',
        name: 'America/Indiana/Vevay',
    },
    {
        label: 'Eastern Time - Vincennes, Indiana',
        name: 'America/Indiana/Vincennes',
    },
    {
        label: 'Eastern Time - Winamac, Indiana',
        name: 'America/Indiana/Winamac',
    },
    {
        label: 'Peru Standard Time',
        name: 'America/Lima',
    },
    {
        label: 'Cayman Time',
        name: 'America/Cayman',
    },
    {
        label: 'Eastern Time - New York',
        name: 'America/New_York',
    },
    {
        label: 'Eastern Time - Nipigon',
        name: 'America/Nipigon',
    },
    {
        label: 'Eastern Standard Time - Jamaica',
        name: 'America/Jamaica',
    },
    {
        label: 'Eastern Time - Nassau',
        name: 'America/Nassau',
    },
    {
        label: 'Acre Standard Time - Eirunepe',
        name: 'America/Eirunepe',
    },
    {
        label: 'Eastern Time - Monticello, Kentucky',
        name: 'America/Kentucky/Monticello',
    },
    {
        label: 'Cuba Time',
        name: 'America/Havana',
    },
    {
        label: 'Acre Standard Time - Rio Branco',
        name: 'America/Rio_Branco',
    },
    {
        label: 'Easter Island Time',
        name: 'Pacific/Easter',
    },
    {
        label: 'Colombia Standard Time',
        name: 'America/Bogota',
    },
    {
        label: 'Eastern Standard Time - Cancun',
        name: 'America/Cancun',
    },
    {
        label: 'Eastern Standard Time - Atikokan',
        name: 'America/Atikokan',
    },
    {
        label: 'Eastern Standard Time - Panama',
        name: 'America/Panama',
    },
    {
        label: 'Eastern Time - Detroit',
        name: 'America/Detroit',
    },
    {
        label: 'Eastern Time - Grand Turk',
        name: 'America/Grand_Turk',
    },
    {
        label: 'Eastern Time - Indianapolis',
        name: 'America/Indiana/Indianapolis',
    },
    {
        label: 'Eastern Time - Iqaluit',
        name: 'America/Iqaluit',
    },
    {
        label: 'Eastern Time - Louisville',
        name: 'America/Kentucky/Louisville',
    },
    {
        label: 'Eastern Time - Marengo, Indiana',
        name: 'America/Indiana/Marengo',
    },
    {
        label: 'Aruba Time',
        name: 'America/Aruba',
    },
    {
        label: 'Dominica Time',
        name: 'America/Dominica',
    },
    {
        label: 'Grenada Time',
        name: 'America/Grenada',
    },
    {
        label: 'Guadeloupe Time',
        name: 'America/Guadeloupe',
    },
    {
        label: 'Kralendijk Time',
        name: 'America/Kralendijk',
    },
    {
        label: 'Lower_Princes Time',
        name: 'America/Lower_Princes',
    },
    {
        label: 'St_Thomas Time',
        name: 'America/St_Thomas',
    },
    {
        label: 'Marigot Time',
        name: 'America/Marigot',
    },
    {
        label: 'Montserrat Time',
        name: 'America/Montserrat',
    },
    {
        label: 'St_Barthelemy Time',
        name: 'America/St_Barthelemy',
    },
    {
        label: 'St_Kitts Time',
        name: 'America/St_Kitts',
    },
    {
        label: 'St_Lucia Time',
        name: 'America/St_Lucia',
    },
    {
        label: 'Anguilla Time',
        name: 'America/Anguilla',
    },
    {
        label: 'St_Vincent Time',
        name: 'America/St_Vincent',
    },
    {
        label: 'Tortola Time',
        name: 'America/Tortola',
    },
    {
        label: 'Antigua Time',
        name: 'America/Antigua',
    },
    {
        label: 'Atlantic Time - Thule',
        name: 'America/Thule',
    },
    {
        label: 'Atlantic Standard Time - Martinique',
        name: 'America/Martinique',
    },
    {
        label: 'Atlantic Standard Time - Puerto Rico',
        name: 'America/Puerto_Rico',
    },
    {
        label: 'Amazon Standard Time - Porto Velho',
        name: 'America/Porto_Velho',
    },
    {
        label: 'Atlantic Standard Time - Barbados',
        name: 'America/Barbados',
    },
    {
        label: 'Atlantic Standard Time - Blanc-Sablon',
        name: 'America/Blanc-Sablon',
    },
    {
        label: 'Atlantic Standard Time - Cura\u00e7ao',
        name: 'America/Curacao',
    },
    {
        label: 'Venezuela Time',
        name: 'America/Caracas',
    },
    {
        label: 'Atlantic Standard Time - Port of Spain',
        name: 'America/Port_of_Spain',
    },
    {
        label: 'Atlantic Standard Time - Santo Domingo',
        name: 'America/Santo_Domingo',
    },
    {
        label: 'Amazon Standard Time - Boa Vista',
        name: 'America/Boa_Vista',
    },
    {
        label: 'Atlantic Time - Bermuda',
        name: 'Atlantic/Bermuda',
    },
    {
        label: 'Atlantic Time - Glace Bay',
        name: 'America/Glace_Bay',
    },
    {
        label: 'Atlantic Time - Goose Bay',
        name: 'America/Goose_Bay',
    },
    {
        label: 'Atlantic Time - Halifax',
        name: 'America/Halifax',
    },
    {
        label: 'Atlantic Time - Moncton',
        name: 'America/Moncton',
    },
    {
        label: 'Bolivia Time',
        name: 'America/La_Paz',
    },
    {
        label: 'Guyana Time',
        name: 'America/Guyana',
    },
    {
        label: 'Amazon Standard Time - Manaus',
        name: 'America/Manaus',
    },
    {
        label: 'Newfoundland Time',
        name: 'America/St_Johns',
    },
    {
        label: 'St. Pierre & Miquelon Time',
        name: 'America/Miquelon',
    },
    {
        label: 'Western Argentina Standard Time',
        name: 'America/Argentina/San_Luis',
    },
    {
        label: 'West Greenland Time',
        name: 'America/Godthab',
    },
    {
        label: 'Uruguay Standard Time',
        name: 'America/Montevideo',
    },
    {
        label: 'Suriname Time',
        name: 'America/Paramaribo',
    },
    {
        label: 'Brasilia Standard Time - Belem',
        name: 'America/Belem',
    },
    {
        label: 'Rothera Time',
        name: 'Antarctica/Rothera',
    },
    {
        label: 'Argentina Standard Time - Buenos Aires',
        name: 'America/Argentina/Buenos_Aires',
    },
    {
        label: 'Brasilia Standard Time - Fortaleza',
        name: 'America/Fortaleza',
    },
    {
        label: 'Brasilia Standard Time - Bahia',
        name: 'America/Bahia',
    },
    {
        label: 'Paraguay Time',
        name: 'America/Asuncion',
    },
    {
        label: 'Brasilia Standard Time - Araguaina',
        name: 'America/Araguaina',
    },
    {
        label: 'Brasilia Standard Time - Recife',
        name: 'America/Recife',
    },
    {
        label: 'Amazon Time (Campo Grande)',
        name: 'America/Campo_Grande',
    },
    {
        label: 'Amazon Time (Cuiaba)',
        name: 'America/Cuiaba',
    },
    {
        label: 'Argentina Standard Time - Catamarca',
        name: 'America/Argentina/Catamarca',
    },
    {
        label: 'Brasilia Standard Time - Santarem',
        name: 'America/Santarem',
    },
    {
        label: 'Argentina Standard Time - Cordoba',
        name: 'America/Argentina/Cordoba',
    },
    {
        label: 'Argentina Standard Time - Jujuy',
        name: 'America/Argentina/Jujuy',
    },
    {
        label: 'Argentina Standard Time - La Rioja',
        name: 'America/Argentina/La_Rioja',
    },
    {
        label: 'Argentina Standard Time - Mendoza',
        name: 'America/Argentina/Mendoza',
    },
    {
        label: 'Argentina Standard Time - Rio Gallegos',
        name: 'America/Argentina/Rio_Gallegos',
    },
    {
        label: 'Argentina Standard Time - Salta',
        name: 'America/Argentina/Salta',
    },
    {
        label: 'Argentina Standard Time - San Juan',
        name: 'America/Argentina/San_Juan',
    },
    {
        label: 'Argentina Standard Time - Tucuman',
        name: 'America/Argentina/Tucuman',
    },
    {
        label: 'Brasilia Standard Time - Maceio',
        name: 'America/Maceio',
    },
    {
        label: 'Argentina Standard Time - Ushuaia',
        name: 'America/Argentina/Ushuaia',
    },
    {
        label: 'Palmer Time',
        name: 'Antarctica/Palmer',
    },
    {
        label: 'French Guiana Time',
        name: 'America/Cayenne',
    },
    {
        label: 'Falkland Islands Standard Time',
        name: 'Atlantic/Stanley',
    },
    {
        label: 'Chile Time',
        name: 'America/Santiago',
    },
    {
        label: 'Brasilia Time',
        name: 'America/Sao_Paulo',
    },
    {
        label: 'Fernando de Noronha Standard Time',
        name: 'America/Noronha',
    },
    {
        label: 'South Georgia Time',
        name: 'Atlantic/South_Georgia',
    },
    {
        label: 'Azores Time',
        name: 'Atlantic/Azores',
    },
    {
        label: 'Cape Verde Standard Time',
        name: 'Atlantic/Cape_Verde',
    },
    {
        label: 'East Greenland Time',
        name: 'America/Scoresbysund',
    },
    {
        label: 'United Kingdom Time',
        name: 'Europe/London',
    },
    {
        label: 'Nouakchott Time',
        name: 'Africa/Nouakchott',
    },
    {
        label: 'Coordinated Universal Time',
        name: 'UTC',
    },
    {
        label: 'Greenwich Mean Time - Accra',
        name: 'Africa/Accra',
    },
    {
        label: 'Greenwich Mean Time - Bissau',
        name: 'Africa/Bissau',
    },
    {
        label: 'Ouagadougou Time',
        name: 'Africa/Ouagadougou',
    },
    {
        label: 'Greenwich Mean Time - Danmarkshavn',
        name: 'America/Danmarkshavn',
    },
    {
        label: 'Western European Time - Canary',
        name: 'Atlantic/Canary',
    },
    {
        label: 'Greenwich Mean Time - Monrovia',
        name: 'Africa/Monrovia',
    },
    {
        label: 'Greenwich Mean Time - Reykjavik',
        name: 'Atlantic/Reykjavik',
    },
    {
        label: 'Lome Time',
        name: 'Africa/Lome',
    },
    {
        label: 'Bamako Time',
        name: 'Africa/Bamako',
    },
    {
        label: 'Greenwich Mean Time - S\u00e3o Tom\u00e9',
        name: 'Africa/Sao_Tome',
    },
    {
        label: 'Freetown Time',
        name: 'Africa/Freetown',
    },
    {
        label: 'Ireland Time',
        name: 'Europe/Dublin',
    },
    {
        label: 'Dakar Time',
        name: 'Africa/Dakar',
    },
    {
        label: 'Conakry Time',
        name: 'Africa/Conakry',
    },
    {
        label: 'Troll Time',
        name: 'Antarctica/Troll',
    },
    {
        label: 'Banjul Time',
        name: 'Africa/Banjul',
    },
    {
        label: 'Western European Time - Madeira',
        name: 'Atlantic/Madeira',
    },
    {
        label: 'Western European Time - Lisbon',
        name: 'Europe/Lisbon',
    },
    {
        label: 'Western European Time - Faroe',
        name: 'Atlantic/Faroe',
    },
    {
        label: 'St_Helena Time',
        name: 'Atlantic/St_Helena',
    },
    {
        label: 'Kinshasa Time',
        name: 'Africa/Kinshasa',
    },
    {
        label: 'Porto-Novo Time',
        name: 'Africa/Porto-Novo',
    },
    {
        label: 'Niamey Time',
        name: 'Africa/Niamey',
    },
    {
        label: 'Malabo Time',
        name: 'Africa/Malabo',
    },
    {
        label: 'Luanda Time',
        name: 'Africa/Luanda',
    },
    {
        label: 'Libreville Time',
        name: 'Africa/Libreville',
    },
    {
        label: 'Isle_of_Man Time',
        name: 'Europe/Isle_of_Man',
    },
    {
        label: 'Douala Time',
        name: 'Africa/Douala',
    },
    {
        label: 'Brazzaville Time',
        name: 'Africa/Brazzaville',
    },
    {
        label: 'Guernsey Time',
        name: 'Europe/Guernsey',
    },
    {
        label: 'Bangui Time',
        name: 'Africa/Bangui',
    },
    {
        label: 'Jersey Time',
        name: 'Europe/Jersey',
    },
    {
        label: 'Central European Time - Ceuta',
        name: 'Africa/Ceuta',
    },
    {
        label: 'Central European Time - Paris',
        name: 'Europe/Paris',
    },
    {
        label: 'Central European Time - Oslo',
        name: 'Europe/Oslo',
    },
    {
        label: 'Central European Time - Malta',
        name: 'Europe/Malta',
    },
    {
        label: 'Central European Time - Madrid',
        name: 'Europe/Madrid',
    },
    {
        label: 'Central European Time - Luxembourg',
        name: 'Europe/Luxembourg',
    },
    {
        label: 'Central European Time - Gibraltar',
        name: 'Europe/Gibraltar',
    },
    {
        label: 'Central European Time - Copenhagen',
        name: 'Europe/Copenhagen',
    },
    {
        label: 'Central European Time - Budapest',
        name: 'Europe/Budapest',
    },
    {
        label: 'Central European Time - Rome',
        name: 'Europe/Rome',
    },
    {
        label: 'Central European Time - Brussels',
        name: 'Europe/Brussels',
    },
    {
        label: 'Central European Time - Berlin',
        name: 'Europe/Berlin',
    },
    {
        label: 'Central European Time - Belgrade',
        name: 'Europe/Belgrade',
    },
    {
        label: 'Central European Time - Andorra',
        name: 'Europe/Andorra',
    },
    {
        label: 'Central European Time - Amsterdam',
        name: 'Europe/Amsterdam',
    },
    {
        label: 'Central European Standard Time - Tunis',
        name: 'Africa/Tunis',
    },
    {
        label: 'Central European Standard Time - Algiers',
        name: 'Africa/Algiers',
    },
    {
        label: 'Central European Time - Prague',
        name: 'Europe/Prague',
    },
    {
        label: 'Central European Time - Monaco',
        name: 'Europe/Monaco',
    },
    {
        label: 'Central European Time - Stockholm',
        name: 'Europe/Stockholm',
    },
    {
        label: 'Western Sahara Time',
        name: 'Africa/El_Aaiun',
    },
    {
        label: 'Central European Time - Vienna',
        name: 'Europe/Vienna',
    },
    {
        label: 'Central European Time - Warsaw',
        name: 'Europe/Warsaw',
    },
    {
        label: 'Central European Time - Zurich',
        name: 'Europe/Zurich',
    },
    {
        label: 'Morocco Time',
        name: 'Africa/Casablanca',
    },
    {
        label: 'West Africa Standard Time - Lagos',
        name: 'Africa/Lagos',
    },
    {
        label: 'Central European Time - Tirane',
        name: 'Europe/Tirane',
    },
    {
        label: 'West Africa Standard Time - Ndjamena',
        name: 'Africa/Ndjamena',
    },
    {
        label: 'San_Marino Time',
        name: 'Europe/San_Marino',
    },
    {
        label: 'Longyearbyen Time',
        name: 'Arctic/Longyearbyen',
    },
    {
        label: 'Bratislava Time',
        name: 'Europe/Bratislava',
    },
    {
        label: 'Podgorica Time',
        name: 'Europe/Podgorica',
    },
    {
        label: 'Bujumbura Time',
        name: 'Africa/Bujumbura',
    },
    {
        label: 'Gaborone Time',
        name: 'Africa/Gaborone',
    },
    {
        label: 'Blantyre Time',
        name: 'Africa/Blantyre',
    },
    {
        label: 'Busingen Time',
        name: 'Europe/Busingen',
    },
    {
        label: 'Sarajevo Time',
        name: 'Europe/Sarajevo',
    },
    {
        label: 'Zagreb Time',
        name: 'Europe/Zagreb',
    },
    {
        label: 'Harare Time',
        name: 'Africa/Harare',
    },
    {
        label: 'Kigali Time',
        name: 'Africa/Kigali',
    },
    {
        label: 'Skopje Time',
        name: 'Europe/Skopje',
    },
    {
        label: 'Vaduz Time',
        name: 'Europe/Vaduz',
    },
    {
        label: 'Lubumbashi Time',
        name: 'Africa/Lubumbashi',
    },
    {
        label: 'Lusaka Time',
        name: 'Africa/Lusaka',
    },
    {
        label: 'Maseru Time',
        name: 'Africa/Maseru',
    },
    {
        label: 'Mbabane Time',
        name: 'Africa/Mbabane',
    },
    {
        label: 'Ljubljana Time',
        name: 'Europe/Ljubljana',
    },
    {
        label: 'Vatican Time',
        name: 'Europe/Vatican',
    },
    {
        label: 'South Africa Standard Time',
        name: 'Africa/Johannesburg',
    },
    {
        label: 'Eastern European Time - Athens',
        name: 'Europe/Athens',
    },
    {
        label: 'Eastern European Time - Damascus',
        name: 'Asia/Damascus',
    },
    {
        label: 'Eastern European Time - Sofia',
        name: 'Europe/Sofia',
    },
    {
        label: 'Central Africa Time - Khartoum',
        name: 'Africa/Khartoum',
    },
    {
        label: 'Eastern European Time - Nicosia',
        name: 'Asia/Nicosia',
    },
    {
        label: 'Eastern European Time - Kiev',
        name: 'Europe/Kiev',
    },
    {
        label: 'Eastern European Time - Helsinki',
        name: 'Europe/Helsinki',
    },
    {
        label: 'Eastern European Time - Hebron',
        name: 'Asia/Hebron',
    },
    {
        label: 'Eastern European Time - Gaza',
        name: 'Asia/Gaza',
    },
    {
        label: 'Eastern European Time - Chisinau',
        name: 'Europe/Chisinau',
    },
    {
        label: 'Eastern European Time - Uzhhorod',
        name: 'Europe/Uzhgorod',
    },
    {
        label: 'Eastern European Time - Bucharest',
        name: 'Europe/Bucharest',
    },
    {
        label: 'Eastern European Time - Beirut',
        name: 'Asia/Beirut',
    },
    {
        label: 'Eastern European Time - Amman',
        name: 'Asia/Amman',
    },
    {
        label: 'Eastern European Standard Time - Tripoli',
        name: 'Africa/Tripoli',
    },
    {
        label: 'Eastern European Standard Time - Kaliningrad',
        name: 'Europe/Kaliningrad',
    },
    {
        label: 'Eastern European Standard Time - Cairo',
        name: 'Africa/Cairo',
    },
    {
        label: 'Central Africa Time - Windhoek',
        name: 'Africa/Windhoek',
    },
    {
        label: 'Eastern European Time - Tallinn',
        name: 'Europe/Tallinn',
    },
    {
        label: 'Eastern European Time - Riga',
        name: 'Europe/Riga',
    },
    {
        label: 'Eastern European Time - Vilnius',
        name: 'Europe/Vilnius',
    },
    {
        label: 'Eastern European Time - Zaporozhye',
        name: 'Europe/Zaporozhye',
    },
    {
        label: 'Central Africa Time - Maputo',
        name: 'Africa/Maputo',
    },
    {
        label: 'Israel Time',
        name: 'Asia/Jerusalem',
    },
    {
        label: 'Famagusta Time',
        name: 'Asia/Famagusta',
    },
    {
        label: 'Dar_es_Salaam Time',
        name: 'Africa/Dar_es_Salaam',
    },
    {
        label: 'Mayotte Time',
        name: 'Indian/Mayotte',
    },
    {
        label: 'Comoro Time',
        name: 'Indian/Comoro',
    },
    {
        label: 'Antananarivo Time',
        name: 'Indian/Antananarivo',
    },
    {
        label: 'Syowa Time',
        name: 'Antarctica/Syowa',
    },
    {
        label: 'Turkey Time',
        name: 'Europe/Istanbul',
    },
    {
        label: 'Mogadishu Time',
        name: 'Africa/Mogadishu',
    },
    {
        label: 'Kampala Time',
        name: 'Africa/Kampala',
    },
    {
        label: 'Djibouti Time',
        name: 'Africa/Djibouti',
    },
    {
        label: 'Moscow Standard Time - Moscow',
        name: 'Europe/Moscow',
    },
    {
        label: 'Asmara Time',
        name: 'Africa/Asmara',
    },
    {
        label: 'Mariehamn Time',
        name: 'Europe/Mariehamn',
    },
    {
        label: 'Arabian Standard Time - Baghdad',
        name: 'Asia/Baghdad',
    },
    {
        label: 'Moscow Standard Time - Minsk',
        name: 'Europe/Minsk',
    },
    {
        label: 'Kirov Time',
        name: 'Europe/Kirov',
    },
    {
        label: 'East Africa Time - Nairobi',
        name: 'Africa/Nairobi',
    },
    {
        label: 'East Africa Time - Juba',
        name: 'Africa/Juba',
    },
    {
        label: 'Arabian Standard Time - Riyadh',
        name: 'Asia/Riyadh',
    },
    {
        label: 'Kuwait Time',
        name: 'Asia/Kuwait',
    },
    {
        label: 'Bahrain Time',
        name: 'Asia/Bahrain',
    },
    {
        label: 'Aden Time',
        name: 'Asia/Aden',
    },
    {
        label: 'Arabian Standard Time - Qatar',
        name: 'Asia/Qatar',
    },
    {
        label: 'Addis_Ababa Time',
        name: 'Africa/Addis_Ababa',
    },
    {
        label: 'Moscow Standard Time - Simferopol',
        name: 'Europe/Simferopol',
    },
    {
        label: 'Iran Time',
        name: 'Asia/Tehran',
    },
    {
        label: 'R\u00e9union Time',
        name: 'Indian/Reunion',
    },
    {
        label: 'Georgia Standard Time',
        name: 'Asia/Tbilisi',
    },
    {
        label: 'Seychelles Time',
        name: 'Indian/Mahe',
    },
    {
        label: 'Saratov Time',
        name: 'Europe/Saratov',
    },
    {
        label: 'Samara Standard Time',
        name: 'Europe/Samara',
    },
    {
        label: 'Mauritius Standard Time',
        name: 'Indian/Mauritius',
    },
    {
        label: 'Gulf Standard Time',
        name: 'Asia/Dubai',
    },
    {
        label: 'Azerbaijan Standard Time',
        name: 'Asia/Baku',
    },
    {
        label: 'Volgograd Standard Time',
        name: 'Europe/Volgograd',
    },
    {
        label: 'Astrakhan Time',
        name: 'Europe/Astrakhan',
    },
    {
        label: 'Armenia Standard Time',
        name: 'Asia/Yerevan',
    },
    {
        label: 'Muscat Time',
        name: 'Asia/Muscat',
    },
    {
        label: 'Ulyanovsk Time',
        name: 'Europe/Ulyanovsk',
    },
    {
        label: 'Afghanistan Time',
        name: 'Asia/Kabul',
    },
    {
        label: 'Uzbekistan Standard Time - Samarkand',
        name: 'Asia/Samarkand',
    },
    {
        label: 'Turkmenistan Standard Time',
        name: 'Asia/Ashgabat',
    },
    {
        label: 'West Kazakhstan Time - Atyrau',
        name: 'Asia/Atyrau',
    },
    {
        label: 'Uzbekistan Standard Time - Tashkent',
        name: 'Asia/Tashkent',
    },
    {
        label: 'Pakistan Standard Time',
        name: 'Asia/Karachi',
    },
    {
        label: 'West Kazakhstan Time - Aqtau',
        name: 'Asia/Aqtau',
    },
    {
        label: 'West Kazakhstan Time - Aqtobe',
        name: 'Asia/Aqtobe',
    },
    {
        label: 'West Kazakhstan Time - Oral',
        name: 'Asia/Oral',
    },
    {
        label: 'Tajikistan Time',
        name: 'Asia/Dushanbe',
    },
    {
        label: 'Maldives Time',
        name: 'Indian/Maldives',
    },
    {
        label: 'Mawson Time',
        name: 'Antarctica/Mawson',
    },
    {
        label: 'French Southern & Antarctic Time',
        name: 'Indian/Kerguelen',
    },
    {
        label: 'Yekaterinburg Standard Time',
        name: 'Asia/Yekaterinburg',
    },
    {
        label: 'West Kazakhstan Time - Qyzylorda',
        name: 'Asia/Qyzylorda',
    },
    {
        label: 'India Standard Time - Kolkata',
        name: 'Asia/Kolkata',
    },
    {
        label: 'India Standard Time - Colombo',
        name: 'Asia/Colombo',
    },
    {
        label: 'Nepal Time',
        name: 'Asia/Kathmandu',
    },
    {
        label: 'Omsk Standard Time',
        name: 'Asia/Omsk',
    },
    {
        label: 'Vostok Time',
        name: 'Antarctica/Vostok',
    },
    {
        label: 'Urumqi Time',
        name: 'Asia/Urumqi',
    },
    {
        label: 'Bhutan Time',
        name: 'Asia/Thimphu',
    },
    {
        label: 'Kyrgyzstan Time',
        name: 'Asia/Bishkek',
    },
    {
        label: 'Indian Ocean Time',
        name: 'Indian/Chagos',
    },
    {
        label: 'East Kazakhstan Time - Almaty',
        name: 'Asia/Almaty',
    },
    {
        label: 'Bangladesh Standard Time',
        name: 'Asia/Dhaka',
    },
    {
        label: 'Myanmar Time',
        name: 'Asia/Yangon',
    },
    {
        label: 'Cocos Islands Time',
        name: 'Indian/Cocos',
    },
    {
        label: 'Barnaul Time',
        name: 'Asia/Barnaul',
    },
    {
        label: 'Western Indonesia Time - Jakarta',
        name: 'Asia/Jakarta',
    },
    {
        label: 'Davis Time',
        name: 'Antarctica/Davis',
    },
    {
        label: 'Hovd Standard Time',
        name: 'Asia/Hovd',
    },
    {
        label: 'Indochina Time - Bangkok',
        name: 'Asia/Bangkok',
    },
    {
        label: 'Indochina Time - Ho Chi Minh City',
        name: 'Asia/Ho_Chi_Minh',
    },
    {
        label: 'Krasnoyarsk Standard Time - Krasnoyarsk',
        name: 'Asia/Krasnoyarsk',
    },
    {
        label: 'Krasnoyarsk Standard Time - Novokuznetsk',
        name: 'Asia/Novokuznetsk',
    },
    {
        label: 'Novosibirsk Standard Time',
        name: 'Asia/Novosibirsk',
    },
    {
        label: 'Tomsk Time',
        name: 'Asia/Tomsk',
    },
    {
        label: 'Christmas Island Time',
        name: 'Indian/Christmas',
    },
    {
        label: 'Western Indonesia Time - Pontianak',
        name: 'Asia/Pontianak',
    },
    {
        label: 'Phnom_Penh Time',
        name: 'Asia/Phnom_Penh',
    },
    {
        label: 'Vientiane Time',
        name: 'Asia/Vientiane',
    },
    {
        label: 'Philippine Standard Time',
        name: 'Asia/Manila',
    },
    {
        label: 'Malaysia Time - Kuching',
        name: 'Asia/Kuching',
    },
    {
        label: 'China Standard Time - Shanghai',
        name: 'Asia/Shanghai',
    },
    {
        label: 'Singapore Standard Time',
        name: 'Asia/Singapore',
    },
    {
        label: 'Taipei Standard Time',
        name: 'Asia/Taipei',
    },
    {
        label: 'Ulaanbaatar Standard Time',
        name: 'Asia/Ulaanbaatar',
    },
    {
        label: 'Irkutsk Standard Time',
        name: 'Asia/Irkutsk',
    },
    {
        label: 'Malaysia Time - Kuala Lumpur',
        name: 'Asia/Kuala_Lumpur',
    },
    {
        label: 'Brunei Darussalam Time',
        name: 'Asia/Brunei',
    },
    {
        label: 'Hong Kong Standard Time',
        name: 'Asia/Hong_Kong',
    },
    {
        label: 'Choibalsan Standard Time',
        name: 'Asia/Choibalsan',
    },
    {
        label: 'China Standard Time - Macau',
        name: 'Asia/Macau',
    },
    {
        label: 'Central Indonesia Time',
        name: 'Asia/Makassar',
    },
    {
        label: 'Australian Western Standard Time - Perth',
        name: 'Australia/Perth',
    },
    {
        label: 'Australian Western Standard Time - Casey',
        name: 'Antarctica/Casey',
    },
    {
        label: 'Australian Central Western Standard Time',
        name: 'Australia/Eucla',
    },
    {
        label: 'Yakutsk Standard Time - Yakutsk',
        name: 'Asia/Yakutsk',
    },
    {
        label: 'Yakutsk Standard Time - Chita',
        name: 'Asia/Chita',
    },
    {
        label: 'Yakutsk Standard Time - Khandyga',
        name: 'Asia/Khandyga',
    },
    {
        label: 'Palau Time',
        name: 'Pacific/Palau',
    },
    {
        label: 'Korean Standard Time - Pyongyang',
        name: 'Asia/Pyongyang',
    },
    {
        label: 'Japan Standard Time',
        name: 'Asia/Tokyo',
    },
    {
        label: 'Eastern Indonesia Time',
        name: 'Asia/Jayapura',
    },
    {
        label: 'East Timor Time',
        name: 'Asia/Dili',
    },
    {
        label: 'Korean Standard Time - Seoul',
        name: 'Asia/Seoul',
    },
    {
        label: 'Australian Central Standard Time',
        name: 'Australia/Darwin',
    },
    {
        label: 'Saipan Time',
        name: 'Pacific/Saipan',
    },
    {
        label: 'Vladivostok Standard Time - Ust-Nera',
        name: 'Asia/Ust-Nera',
    },
    {
        label: 'Australian Eastern Standard Time - Brisbane',
        name: 'Australia/Brisbane',
    },
    {
        label: 'Australian Eastern Standard Time - Lindeman',
        name: 'Australia/Lindeman',
    },
    {
        label: 'Chamorro Standard Time',
        name: 'Pacific/Guam',
    },
    {
        label: 'Chuuk Time',
        name: 'Pacific/Chuuk',
    },
    {
        label: 'Dumont-d\u2019Urville Time',
        name: 'Antarctica/DumontDUrville',
    },
    {
        label: 'Papua New Guinea Time',
        name: 'Pacific/Port_Moresby',
    },
    {
        label: 'Vladivostok Standard Time - Vladivostok',
        name: 'Asia/Vladivostok',
    },
    {
        label: 'Central Australia Time - Adelaide',
        name: 'Australia/Adelaide',
    },
    {
        label: 'Central Australia Time - Broken Hill',
        name: 'Australia/Broken_Hill',
    },
    {
        label: 'Kosrae Time',
        name: 'Pacific/Kosrae',
    },
    {
        label: 'Ponape Time',
        name: 'Pacific/Pohnpei',
    },
    {
        label: 'Norfolk Island Time',
        name: 'Pacific/Norfolk',
    },
    {
        label: 'New Caledonia Standard Time',
        name: 'Pacific/Noumea',
    },
    {
        label: 'Magadan Standard Time',
        name: 'Asia/Magadan',
    },
    {
        label: 'Solomon Islands Time',
        name: 'Pacific/Guadalcanal',
    },
    {
        label: 'Sakhalin Standard Time',
        name: 'Asia/Sakhalin',
    },
    {
        label: 'Eastern Australia Time - Currie',
        name: 'Australia/Currie',
    },
    {
        label: 'Srednekolymsk Time',
        name: 'Asia/Srednekolymsk',
    },
    {
        label: 'Vanuatu Standard Time',
        name: 'Pacific/Efate',
    },
    {
        label: 'Eastern Australia Time - Sydney',
        name: 'Australia/Sydney',
    },
    {
        label: 'Eastern Australia Time - Melbourne',
        name: 'Australia/Melbourne',
    },
    {
        label: 'Eastern Australia Time - Hobart',
        name: 'Australia/Hobart',
    },
    {
        label: 'Lord Howe Time',
        name: 'Australia/Lord_Howe',
    },
    {
        label: 'Bougainville Time',
        name: 'Pacific/Bougainville',
    },
    {
        label: 'Macquarie Island Time',
        name: 'Antarctica/Macquarie',
    },
    {
        label: 'Tuvalu Time',
        name: 'Pacific/Funafuti',
    },
    {
        label: 'Petropavlovsk-Kamchatski Standard Time',
        name: 'Asia/Kamchatka',
    },
    {
        label: 'McMurdo Time',
        name: 'Antarctica/McMurdo',
    },
    {
        label: 'Fiji Time',
        name: 'Pacific/Fiji',
    },
    {
        label: 'Gilbert Islands Time',
        name: 'Pacific/Tarawa',
    },
    {
        label: 'Marshall Islands Time - Kwajalein',
        name: 'Pacific/Kwajalein',
    },
    {
        label: 'Marshall Islands Time - Majuro',
        name: 'Pacific/Majuro',
    },
    {
        label: 'Nauru Time',
        name: 'Pacific/Nauru',
    },
    {
        label: 'Anadyr Standard Time',
        name: 'Asia/Anadyr',
    },
    {
        label: 'Wake Island Time',
        name: 'Pacific/Wake',
    },
    {
        label: 'Wallis & Futuna Time',
        name: 'Pacific/Wallis',
    },
    {
        label: 'New Zealand Time',
        name: 'Pacific/Auckland',
    },
    {
        label: 'Phoenix Islands Time',
        name: 'Pacific/Enderbury',
    },
    {
        label: 'Tokelau Time',
        name: 'Pacific/Fakaofo',
    },
    {
        label: 'Tonga Standard Time',
        name: 'Pacific/Tongatapu',
    },
    {
        label: 'Chatham Time',
        name: 'Pacific/Chatham',
    },
    {
        label: 'Apia Time',
        name: 'Pacific/Apia',
    },
    {
        label: 'Line Islands Time',
        name: 'Pacific/Kiritimati',
    },
];

export const stringifyTimezone = (timezone: TimezoneWithOffset, offset: string = 'UTC'): string => {
    const sign = timezone.offset < 0 ? '-' : '+';
    const hours = Math.floor(Math.abs(timezone.offset));
    const minutes = Math.floor(Math.abs((timezone.offset % 1) * 60));

    return `(${offset}${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}) ${timezone.label}`;
};

export default timezones.map((timezone: Timezone): TimezoneWithOffset => {
    return {
        ...timezone,
        offset: getTimezoneOffset(timezone.name) / MILLISECONDS_IN_HOUR,
    };
});
