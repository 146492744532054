import React from 'react';
import events from '../../events';
import { userManager } from '../../service/UserManager';
import dispatcher from '../../service/dispatcher';
import { utcToUserTimezone } from '../../utils';
import { MomentInput } from 'moment';

interface DateTimeProps {
    children: MomentInput;
    format?: string;
}

class DateTime extends React.PureComponent<DateTimeProps> {
    static defaultProps = {
        format: 'DD.MM.YYYY HH:mm',
    };

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { format, children } = this.props;
        const user = userManager.getCurrentUser();
        const res = utcToUserTimezone(children, user, format);

        return res ?? null;
    }
}

export default DateTime;
