import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { default as LayoutGrid } from '@material-ui/core/Grid';
import { accountsManager } from '../../service/AccountsManager';
import './style.css';
import PropTypes from 'prop-types';
import { userManager } from '../../service/UserManager';
import countryManager from '../../service/CountryManager';
import timezones, { stringifyTimezone } from 'references/timezones';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withTranslation } from 'react-i18next';
import { LANGUAGES } from '../../locales/i18n';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { Autocomplete } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';

class AccountMainForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // поскольку форма появляется в модальном окне, каждый раз при открытии вызывается конструктор с нужными props
            account: { ...props.account },
            errors: new Map(),
        };
    }

    submit() {
        this.setState({
            errors: new Map(),
        });

        accountsManager
            .save(this.state.account)
            .then((response) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(response.account);
                if (
                    userManager.getCurrentUser().language === null &&
                    userManager.getCurrentAccount() !== null &&
                    userManager.getCurrentAccount().language !== response.account.language
                ) {
                    dispatcher.dispatch(events.EVENT_CHANGE_LANGUAGE, response.account.language);
                }
            })
            .catch((error) => {
                this.props.onSubmitError && this.props.onSubmitError(error.message);
                if (error.errors.hasOwnProperty('account')) {
                    this.setState({
                        errors: new Map(Object.entries(error.errors['account'])),
                    });
                }
            });
    }

    componentDidMount() {
        Promise.all([userManager.requestCurrentUser(), countryManager.list()]).then(() => {
            this.forceUpdate();
        });
    }

    quiet() {
        this.setState({
            errors: new Map(),
        });
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const newState = { ...this.state.account, [name]: newValue };
        this.setState({
            account: newState,
        });
    };

    render() {
        const { t } = this.props;
        if (countryManager.getCountries() === null) {
            return t('loading');
        }

        const textFields = [
            {
                name: 'name',
                label: t('name'),
            },
            {
                name: 'address',
                label: t('address'),
            },
            {
                name: 'phone',
                label: t('phone'),
            },
        ];

        const serviceKeyFields = [
            // {
            //     name: 'locationIqKey',
            //     label: 'LocationIQ token',
            //     placeholder: 'LocationIQ token',
            // }, {
            //     name: 'mapQuestKey',
            //     label: 'MapQuest API key',
            // }, {
            //     name: 'googleKey',
            //     label: 'Google API key',
            // },
        ];

        const currentTimeZone = timezones.find((timezone) => timezone.name === this.state.account.timezone);
        const currentCountry = countryManager
            .getCountries()
            .find((country) => country.alpha2 === this.state.account.countryCode);

        return (
            <form noValidate autoComplete="off" className="c-account-main-form">
                <Typography variant="h6">{this.props.t('main')}</Typography>
                {textFields.map((item) => {
                    return (
                        <TextField
                            key={item.name}
                            name={item.name}
                            label={item.label}
                            placeholder={item.placeholder || item.label}
                            fullWidth
                            margin="dense"
                            value={this.state.account[item.name] || ''}
                            helperText={this.state.errors.get(item.name) || ''}
                            error={this.state.errors.has(item.name)}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                            multiline={item.multiline}
                            rowsMax="4"
                        />
                    );
                })}

                <FormControl fullWidth margin="dense" error={this.state.errors.has('timezone')}>
                    <InputLabel shrink>{t('timezone')}</InputLabel>
                    <Autocomplete
                        options={timezones}
                        value={currentTimeZone}
                        getOptionLabel={(timezone) => stringifyTimezone(timezone)}
                        onChange={(event, value) => {
                            this.handleInputChange({ target: { name: 'timezone', value: value.name } });
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('user_form.form.timezone.placeholder')}
                                margin="normal"
                                data-testid="user_form.form.timezone"
                            />
                        )}
                    />
                    {this.state.errors.get('timezone') && (
                        <FormHelperText>{this.state.errors.get('timezone')}</FormHelperText>
                    )}
                </FormControl>
                <FormControl fullWidth margin="dense" error={this.state.errors.has('language')}>
                    <InputLabel shrink>{this.props.t('user_form.form.language.label')}</InputLabel>
                    <Autocomplete
                        options={LANGUAGES}
                        value={this.state.account.language}
                        getOptionLabel={(language) => this.props.t('user_form.form.language.' + language)}
                        onChange={(event, value) => {
                            this.handleInputChange({ target: { name: 'language', value } });
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('user_form.form.language.placeholder')}
                                margin="normal"
                                data-testid="user_form.form.language"
                            />
                        )}
                    />
                    {this.state.errors.get('language') && (
                        <FormHelperText>{this.state.errors.get('language')}</FormHelperText>
                    )}
                </FormControl>
                <FormControl fullWidth margin="dense" error={this.state.errors.has('countryCode')}>
                    <InputLabel shrink>{this.props.t('country')} </InputLabel>
                    <Autocomplete
                        options={countryManager.getCountries()}
                        value={currentCountry}
                        getOptionLabel={(country) => country.name}
                        onChange={(event, value) => {
                            this.handleInputChange({ target: { name: 'countryCode', value: value.alpha2 } });
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('user_form.form.country.placeholder')}
                                margin="normal"
                                data-testid="user_form.form.country"
                            />
                        )}
                    />
                    {this.state.errors.get('countryCode') && (
                        <FormHelperText>{this.state.errors.get('countryCode')}</FormHelperText>
                    )}
                </FormControl>

                <LayoutGrid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={3}
                    className="labeled-radio-group"
                >
                    <div>
                        <Typography>{t('distance_metric')}:</Typography>
                    </div>
                    <div>
                        <RadioGroup
                            name="distanceMetric"
                            value={this.state.account.distanceMetric}
                            onChange={this.handleInputChange}
                            row
                        >
                            <FormControlLabel value="km" control={<Radio color="primary" />} label="km" />
                            <FormControlLabel value="mile" control={<Radio color="primary" />} label="mile" />
                        </RadioGroup>
                    </div>
                </LayoutGrid>

                <LayoutGrid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={3}
                    className="labeled-radio-group"
                >
                    <div>
                        <Typography>
                            {t('account_main_form.geocoding_language')}
                            &nbsp;
                            <Tooltip
                                className="tooltip-question"
                                title={t('account_main_form.geocoding_language.hint')}
                            >
                                <i className="fas fa-question-circle" />
                            </Tooltip>
                            :
                        </Typography>
                    </div>
                    <div>
                        <RadioGroup
                            name="geocodingLanguage"
                            value={this.state.account.geocodingLanguage}
                            onChange={this.handleInputChange}
                            row
                        >
                            <FormControlLabel
                                value="local"
                                control={<Radio color="primary" />}
                                label={t('account_main_form.geocoding_language.local')}
                            />
                            <FormControlLabel
                                value="account"
                                control={<Radio color="primary" />}
                                label={t('account_main_form.geocoding_language.account')}
                            />
                        </RadioGroup>
                    </div>
                </LayoutGrid>

                {serviceKeyFields.map((item) => {
                    return (
                        <TextField
                            key={item.name}
                            name={item.name}
                            label={item.label}
                            placeholder={item.placeholder || item.label}
                            fullWidth
                            margin="dense"
                            value={this.state.account[item.name] || ''}
                            helperText={this.state.errors.get(item.name) || ''}
                            error={this.state.errors.has(item.name)}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                    );
                })}
            </form>
        );
    }
}

AccountMainForm.propTypes = {
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    account: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(AccountMainForm);
