import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reverse, reverseWithSearch, routes } from '../../routes';
import './style.css';
import { Switch } from '@material-ui/core';
import countryManager from '../../service/CountryManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import DottedLink from '../../components/DottedLink';
import { accountsManager } from '../../service/AccountsManager';
import Button from '@material-ui/core/Button';
import { BillingWidgetComponent } from '../Billing/BillingWidget';
import FormDialog from '../FormDialog';
import AccountClearCache from './AccountClearCache';
import AccountChangeTest from './AccountChangeTest';
import { withTranslation } from 'react-i18next';
import { getAdapters } from '../Login/adapters';
import Confirmation from '../../components/Confirmation';
import territoryManagerFactory from '../../service/Territories/TerritoryManager.js';
import { TAB_ACCOUNT_SUBSCRIPTION_BILLING } from './types';

const accountFields = ['name', 'address', 'phone', 'timezone', 'countryCode', 'distanceMetric'];
const accountIcons = {
    name: 'building',
    address: 'map-pin',
    phone: 'phone-alt',
    timezone: 'clock',
    countryCode: 'globe-americas',
    distanceMetric: 'ruler-horizontal',
};

class AccountSummary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            removingSsoLink: false,
            clearCache: false,
            cleaningTerritoriesCache: false,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.COUNTRIES_LIST_LOADED, this, () => this.forceUpdate());
        countryManager.list();
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.COUNTRIES_LIST_LOADED, this);
    }

    handleRemoveSsoLink = () => {
        this.setState({
            removingSsoLink: true,
        });
        accountsManager.removeSsoLink(this.props.account.id).then(() => {
            this.setState({
                removingSsoLink: false,
            });
        });
    };

    onClearCache = (e) => {
        e.preventDefault();
        this.setState({
            clearCache: true,
        });
    };

    onClearCacheClose = () => {
        this.setState({
            clearCache: false,
        });
    };

    onClearTerritoriesCache = () => {
        const territoriesManager = territoryManagerFactory.getManager(this.props.account.id);
        territoriesManager.cleanDb().finally(() => {
            this.setState({
                cleaningTerritoriesCache: false,
            });
        });
        this.setState({
            cleaningTerritoriesCache: true,
        });
    };

    onChangeTest = (e) => {
        e.preventDefault();
        this.setState({
            changeTest: true,
        });
    };

    onChangeTestClose = () => {
        this.setState({
            changeTest: false,
        });
    };

    onChangeTestComplete = () => {
        this.setState({
            changeTest: false,
        });
        this.props.onChangeTestState();
    };

    handleOpenSubscriptionTab = (event) => {
        event.preventDefault();

        const tab = TAB_ACCOUNT_SUBSCRIPTION_BILLING;
        dispatcher.dispatch(events.CLICK_MENU_OPEN.ACCOUNT, tab);

        this.props.history.push(
            reverseWithSearch(
                routes.admin.account.index,
                { accountId: this.props.myAccount ? undefined : this.props.account.id },
                { tab: tab },
            ),
        );
    };

    render() {
        const { account, t } = this.props;
        if (!account) {
            return null;
        }
        const ssoProvider = getAdapters().find((adapter) => adapter.id === account.ssoProvider);
        return (
            <div className="c-company">
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <h2 style={{ margin: 0 }}>{t('account.profile')}</h2>
                    </Grid>
                    <Grid item>
                        <Fab color="primary" size="small" onClick={this.props.onOpen}>
                            <Icon>edit_icon</Icon>
                        </Fab>
                        {!this.props.myAccount && (
                            <Switch checked={!account.deleted} onChange={this.props.onBlock} color="primary" />
                        )}
                    </Grid>
                </Grid>
                <div>
                    {accountFields.map((prop) => (
                        <div key={prop} className="c-company__row">
                            <div className="c-company__row__icon">
                                <i className={`fas fa-${accountIcons[prop]}`} />
                            </div>
                            <div className="c-company__row__text">
                                {prop === 'countryCode'
                                    ? countryManager.getCountryName(account[prop]) || account[prop]
                                    : account[prop]}
                            </div>
                        </div>
                    ))}
                    {account['ssoId'] && (
                        <div className="c-company__row">
                            <div className="c-company__row__icon">
                                <Icon className="fas fa-link" style={{ overflow: 'visible' }} fontSize="small" />
                            </div>
                            <div className="c-company__row__text sso-via">
                                <Tooltip
                                    title={t('account.sso.on', {
                                        ssoProvider: ssoProvider ? ssoProvider.name : account.ssoProvider,
                                        ssoId: account.ssoId,
                                    })}
                                >
                                    <span>
                                        {t('account.sso.via', {
                                            ssoProvider: ssoProvider ? ssoProvider.name : account.ssoProvider,
                                            ssoId: account.ssoId,
                                        })}
                                    </span>
                                </Tooltip>
                                &nbsp;
                                <Tooltip
                                    title={t('account.sso.disabled', {
                                        ssoProvider: ssoProvider ? ssoProvider.name : account.ssoProvider,
                                    })}
                                >
                                    <DottedLink
                                        loading={this.state.removingSsoLink}
                                        onClick={this.handleRemoveSsoLink}
                                        data-testid="account.sso.turn_off"
                                    >
                                        {t('turn_off')}
                                    </DottedLink>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
                <br />
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <h2>{t('account_page.account_summary.subscription_and_billing')}</h2>
                    </Grid>
                    <Grid item>
                        <Link to="#" onClick={this.handleOpenSubscriptionTab}>
                            <Fab color="primary" size="small">
                                <Icon>edit_icon</Icon>
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>
                <div>
                    {this.props.account.subscription !== null && (
                        <BillingWidgetComponent
                            subscription={this.props.account.subscription}
                            accountId={this.props.account.id}
                            myAccount={this.props.myAccount}
                        />
                    )}
                </div>
                <br />
                {this.props.myAccount && (
                    <>
                        <div>
                            <Confirmation
                                text={t('account_page.account_summary.clean_cache_territories.confirm')}
                                onConfirm={this.onClearTerritoriesCache}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    data-testid="account_page.account_summary.clean_cache_territories"
                                >
                                    {this.state.cleaningTerritoriesCache
                                        ? t('loading')
                                        : t('account_page.account_summary.clean_cache_territories')}
                                </Button>
                            </Confirmation>
                        </div>
                        <br />
                    </>
                )}

                <div>
                    {this.props.myAccount ? null : (
                        <Button
                            variant="contained"
                            size="small"
                            component={Link}
                            to={reverse(routes.admin.account.updateEventLog, { accountId: this.props.account.id })}
                            data-testid="update_log.title"
                        >
                            {t('update_log.title')}
                        </Button>
                    )}
                    {!this.props.myAccount && (
                        <React.Fragment>
                            <br />
                            <br />
                        </React.Fragment>
                    )}
                    {this.props.myAccount ? null : (
                        <Tooltip title={t('account.cache.clear.message')}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={this.onClearCache}
                                data-testid="account_page.account_summary.clear_cache"
                            >
                                {t('account_page.account_summary.clear_cache')}
                            </Button>
                        </Tooltip>
                    )}
                    <br />
                    <br />
                    {this.props.myAccount ? null : (
                        <Tooltip title={t('account.test.title')}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={this.onChangeTest}
                                data-testid="account.test.change_to_test"
                            >
                                {this.props.account.isTest
                                    ? t('account.test.change_to_real')
                                    : t('account.test.change_to_test')}
                            </Button>
                        </Tooltip>
                    )}
                    {this.state.clearCache && (
                        <FormDialog
                            title={t('account.cache.clear')}
                            onSave={this.onClearCacheClose}
                            onCancel={this.onClearCacheClose}
                            saveButtonTitle={t('button.i_confirm')}
                        >
                            <AccountClearCache account={this.props.account} />
                        </FormDialog>
                    )}
                    {this.state.changeTest && (
                        <FormDialog
                            title={t('account.test.title')}
                            onSave={this.onChangeTestComplete}
                            onCancel={this.onChangeTestClose}
                            saveButtonTitle={t('button.i_confirm')}
                        >
                            <AccountChangeTest account={this.props.account} />
                        </FormDialog>
                    )}
                </div>
            </div>
        );
    }
}

AccountSummary.propTypes = {
    myAccount: PropTypes.bool.isRequired,
    account: PropTypes.object,
    onChangeTestState: PropTypes.func.isRequired,
};

AccountSummary.defaultProps = {
    myAccount: false,
};

export default withTranslation('translations', { withRef: true })(withRouter(AccountSummary));
