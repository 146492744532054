import React from 'react';
import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import DateTime from '../../../service/workflow_actions/forms/fields/DateTime';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import {
    DATETIME_FORMAT_FOR_EXPORT,
    formatDateForPicker,
    formatDateTimeForPicker,
    formatDateToDefault,
    userTimezoneToUtc,
} from '../../../utils';
import { userManager } from '../../../service/UserManager';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);

class DateTimeField extends AbstractField {
    handleChange(date) {
        const { field, onChange } = this.props;
        const newValue =
            field.getIsTimeAllowed() && date
                ? userTimezoneToUtc(formatDateToDefault(date), userManager.getCurrentUser(), DATETIME_FORMAT_FOR_EXPORT)
                : date;
        onChange(field, newValue);
    }

    renderInput(field, errors) {
        if (field.getIsTimeAllowed()) {
            const dateTimeValue = field.getValue()
                ? formatDateTimeForPicker(field.getValue(), userManager.getCurrentUser())
                : null;
            return (
                <DateTimePicker
                    value={dateTimeValue}
                    onChange={this.handleChange}
                    onBlur={this.handleValidate}
                    inputVariant="outlined"
                    autoFocus={this.props.autoFocus}
                    error={errors.size > 0}
                    disabled={field.getIsDisabled()}
                    helperText={errors.get('value') ? t(errors.get('value')) : ''}
                    className="field-datetime-input"
                    InputProps={{ className: 'field-Input' }}
                    inputProps={{ className: 'field-input' }}
                    clearable
                />
            );
        }

        const dateTimeValue = field.getValue() ? formatDateForPicker(field.getValue()) : null;
        return (
            <DatePicker
                value={dateTimeValue}
                onChange={this.handleChange}
                onBlur={this.handleValidate}
                inputVariant="outlined"
                error={errors.size > 0}
                disabled={field.getIsDisabled()}
                helperText={errors.get('value') ? t(errors.get('value')) : ''}
                className="field-datetime-input"
                InputProps={{ className: 'field-Input' }}
                inputProps={{ className: 'field-input' }}
                clearable
            />
        );
    }
}

DateTimeField.defaultProps = {
    autoFocus: false,
};

DateTimeField.propTypes = {
    field: PropTypes.instanceOf(DateTime).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default DateTimeField;
