import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { HeaderCell } from './index';

interface Props {
    value: string | null;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    clientHeight?: number;
    style?: React.CSSProperties;
}

const FilterCell: React.FC<Props> = (props) => {
    const [value, setValue] = useState<string | null>(props.value);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(event.target.value);
        props.onChange(event);
    };

    return (
        <HeaderCell style={{ top: props.clientHeight }}>
            <TextField value={value} onChange={handleChange} style={props.style} />
        </HeaderCell>
    );
};

export default FilterCell;
