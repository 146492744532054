import BackendService from 'api/BackendService';
import { CallContext } from '../components/utils/CallContext';

export const NAMESPACE_GLOBAL = 'global';

export default class WorkflowManager extends BackendService {
    normalizeGroups(groups) {
        if (!groups) {
            return [];
        }
        return groups.map((group) => {
            const mappings = group.mappings
                ? group.mappings.map((mapping) => {
                      const action = mapping.action
                          ? {
                                ...mapping.action,
                                groups: this.normalizeGroups(mapping.action.groups),
                                callContext: this.normalizeCallContext(mapping.action.callContext),
                            }
                          : null;
                      return { ...mapping, action };
                  })
                : [];
            return { ...group, mappings };
        });
    }

    denormalizeGroups(groups) {
        if (!groups) {
            return [];
        }
        return groups.map((group) => {
            const mappings = group.mappings
                ? group.mappings.map((mapping) => {
                      const action = mapping.action
                          ? {
                                ...mapping.action,
                                groups: this.denormalizeGroups(mapping.action.groups),
                                callContext: this.denormalizeCallContext(mapping.action.callContext),
                            }
                          : null;
                      return { ...mapping, action };
                  })
                : [];
            return { ...group, mappings };
        });
    }

    normalizeCallContext(callContext) {
        if (callContext instanceof CallContext) {
            return callContext.getRawData();
        }
        return callContext;
    }

    denormalizeCallContext(callContext) {
        if (callContext instanceof CallContext) {
            return callContext;
        }
        return new CallContext(callContext.entities, callContext.vars);
    }
}
