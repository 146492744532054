import { EImageType, ICompressConfig } from 'image-conversion';
import * as imageConversion from 'image-conversion';

class FileCompressor {
    compress = (blob: Blob, config: ICompressConfig) => {
        if (!config.quality) {
            config.quality = 0.8;
        }
        if (!config.type) {
            config.type = EImageType.JPEG;
        }

        return imageConversion
            .filetoDataURL(blob)
            .then((dataURL) => {
                return imageConversion.dataURLtoImage(dataURL);
            })
            .then((image) => {
                if (config.width && config.height) {
                    if (image.width / image.height >= config.width / config.height) {
                        delete config.height;
                    } else {
                        delete config.width;
                    }
                }
                return imageConversion.imagetoCanvas(image, Object.assign({}, config));
            })
            .then((canvas) => {
                return imageConversion.canvastoDataURL(canvas, config.quality, config.type);
            })
            .then((dataURL) => {
                return imageConversion.dataURLtoFile(dataURL, config.type);
            });
    };
}

export const fileCompressor = new FileCompressor();
