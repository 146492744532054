import { logDebug, weAreInNativeApp } from '../utils';
import dispatcher from './dispatcher';
import events from '../events';
import { HTTP } from '@ionic-native/http';
import debounce from 'lodash/debounce';
import { Network } from '@capacitor/network';
import { ConnectionStatus } from '@capacitor/network/dist/esm/definitions';

export const FAILED_REQUEST_HANDLING_DEBOUNCE_INTERVAL = 1000;

export const IS_ONLINE_REQUEST_TIMEOUT = 10000;

class InternetConnectionManager {
    private _isOnline = true;
    private recheckTimer: NodeJS.Timeout | null = null;

    constructor() {
        if (weAreInNativeApp()) {
            Network.getStatus().then(this.handleNetworkStatus);
            Network.addListener('networkStatusChange', this.handleNetworkStatus);
        }

        dispatcher.subscribe(
            events.REQUEST_FAILED_DUE_TO_INTERNET_CONNECTION,
            this,
            debounce(this.handleFailedRequest.bind(this), FAILED_REQUEST_HANDLING_DEBOUNCE_INTERVAL),
        );

        dispatcher.subscribe(events.APP_WENT_OFFLINE, this, () => {
            logDebug('App went offline');

            this._isOnline = false;

            if (this.recheckTimer) {
                return;
            }
            this.recheckTimer = setInterval(() => {
                this.recheckInternetByRequest().then(() => {
                    dispatcher.dispatch(events.APP_IS_BACK_ONLINE);
                });
            }, 10000);
        });

        dispatcher.subscribe(events.APP_IS_BACK_ONLINE, this, () => {
            logDebug('App is back online');

            this._isOnline = true;

            if (this.recheckTimer) {
                clearInterval(this.recheckTimer);
                this.recheckTimer = null;
            }
        });
    }

    private handleFailedRequest = () => {
        this.recheckInternetByRequest().catch(() => {
            dispatcher.dispatch(events.APP_WENT_OFFLINE);
        });
    };

    private handleNetworkStatus = (status: ConnectionStatus) => {
        const { connected } = status;

        if (connected && !this._isOnline) {
            dispatcher.dispatch(events.APP_IS_BACK_ONLINE);
        }
        if (!connected) {
            dispatcher.dispatch(events.APP_WENT_OFFLINE);
        }
    };

    get isOnline(): boolean {
        return this._isOnline;
    }

    private recheckInternetByRequest = () => {
        if (!weAreInNativeApp()) {
            return Promise.resolve();
        }

        logDebug('Making request for connection test');
        return HTTP.sendRequest('https://app.mapsly.com/logo.png?ts=' + Date.now(), {
            method: 'get',
            timeout: IS_ONLINE_REQUEST_TIMEOUT,
        });
    };
}

export default new InternetConnectionManager();
