import React from 'react';
import { intercomManager } from 'service/IntercomManager';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

class RoutingServiceError extends React.PureComponent<Props> {
    private handleContactSupportClick = () => {
        intercomManager.showNewMessage('');
    };

    render() {
        const { t } = this.props;
        return (
            <>
                {t('geocoder.build_route.result_status.error_service.1')}&nbsp;
                <span
                    onClick={this.handleContactSupportClick}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                    {t('geocoder.build_route.result_status.error_service.contact_support')}
                </span>
                &nbsp;{t('geocoder.build_route.result_status.error_service.2')}
            </>
        );
    }
}

export default withTranslation()(RoutingServiceError);
