import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormGroup,
    FormLabel,
    Grid,
    FormHelperText,
} from '@material-ui/core';
import Hint from '../../Hint';
import { Workflow } from '../../../interfaces';

interface CurrentProps extends WithTranslation {
    button: Workflow.Button;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    errors: Map<string, string>;
    isRouteObjectIncluded: boolean;
}

class Routing extends React.PureComponent<CurrentProps> {
    render() {
        const { button, onChange, errors, isRouteObjectIncluded } = this.props;

        if (!button.locations.includes(Workflow.Locations.ROUTE_MENU) || !isRouteObjectIncluded) {
            return null;
        }

        const isDraft =
            button.routingStatus &&
            [Workflow.RoutingStatus.DRAFT, Workflow.RoutingStatus.ALL].includes(button.routingStatus);

        return (
            <FormGroup>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <FormLabel style={{ display: 'block', marginTop: '16px' }}>
                            {this.props.t('workflow_buttons.form.routing_status')}{' '}
                            <Hint>{this.props.t('workflow_buttons.form.routing_status.hint')}</Hint>:
                        </FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense" error={errors.has('routingStatus')}>
                            <InputLabel>{this.props.t('workflow_buttons.form.routing_status.label')}</InputLabel>
                            <Select name="routingStatus" value={button.routingStatus || ''} onChange={onChange}>
                                {Object.values(Workflow.RoutingStatus).map((value) => (
                                    <MenuItem value={value} key={value}>
                                        {this.props.t('workflow_buttons.form.routing_status.' + value)}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.has('routingStatus') && (
                                <FormHelperText error>{errors.get('routingStatus')}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                {isDraft && (
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <FormLabel>
                                {this.props.t('workflow_buttons.form.routing_mode')}{' '}
                                <Hint>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.t('workflow_buttons.form.routing_mode.hint'),
                                        }}
                                    />
                                </Hint>
                                :
                            </FormLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth margin="dense" error={errors.has('routingMode')}>
                                <Select name="routingMode" value={button.routingMode || ''} onChange={onChange}>
                                    {Object.values(Workflow.RoutingMode).map((value) => (
                                        <MenuItem value={value} key={value}>
                                            {this.props.t('workflow_buttons.form.routing_mode.' + value)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.has('routingMode') && (
                                    <FormHelperText error>{errors.get('routingMode')}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth margin="dense" error={errors.has('routingResultType')}>
                                <Select
                                    name="routingResultType"
                                    value={button.routingResultType || ''}
                                    onChange={onChange}
                                >
                                    {Object.values(Workflow.RoutingResultType).map((value) => (
                                        <MenuItem value={value} key={value}>
                                            {this.props.t('workflow_buttons.form.routing_result_type.' + value)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.has('routingResultType') && (
                                    <FormHelperText error>{errors.get('routingResultType')}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </FormGroup>
        );
    }
}

export default withTranslation()(Routing);
