import { ScheduleCalendarEvent } from '../settings';
import { routeViewerManager } from '../../../service/MapPage';
import { addSeconds, format, startOfDay } from 'date-fns';
import { DATE_FORMAT_DATEFNS } from '../../../utils';
import { Activity, ActivityType, PointType } from '../../../interfaces/routing/route';
import { CalendarEvent } from '../../../service/Calendar/CalendarEventRepository';

export type CalendarEventRouteViewActivityLabel = ActivityType.START | ActivityType.END | number;

export const getCalendarEventRouteViewActivityLabel = (
    event: ScheduleCalendarEvent,
    userId: number,
): CalendarEventRouteViewActivityLabel | null => {
    if (routeViewerManager.mode !== 'calendar') {
        return null;
    }

    const date = startOfDay(event.startTime);
    if (!routeViewerManager.hasCalendarFilter(userId, [date])) {
        return null;
    }

    const dateFormatted = format(date, DATE_FORMAT_DATEFNS);

    const routesPaths = routeViewerManager.getFilteredPaths([userId], [dateFormatted]);

    let activityLabel = undefined;
    for (const route of routesPaths) {
        if (route.activities.length === 0) {
            continue;
        }

        const hasStart = route.activities[0].type === ActivityType.START;
        let position = hasStart ? 0 : 1;

        for (const activity of route.activities.values()) {
            if (![ActivityType.START, ActivityType.END, ActivityType.BREAK].includes(activity.type)) {
                position += 1;
            }

            if (activity.id === event.originalEvent.routeActivity?.id) {
                if ([ActivityType.START, ActivityType.END, ActivityType.BREAK].includes(activity.type)) {
                    activityLabel = activity.type;
                    break;
                }

                activityLabel = position;
                break;
            }
        }

        if (activityLabel !== undefined) {
            break;
        }
    }

    if (activityLabel === undefined) {
        return null;
    }

    return activityLabel as CalendarEventRouteViewActivityLabel;
};

export const isCalendarEventOptimizable = (calendarEvent: CalendarEvent): boolean => {
    if (calendarEvent.isExport) {
        return false;
    }

    if (!calendarEvent.draft) {
        return false;
    }

    return !calendarEvent.allDay && !calendarEvent.fixedTime;
};

export const getCalendarEventIdFromActivity = (activity: Activity): string | undefined => {
    let calendarEventId = (activity as any).job?.advancedRoutingJob?.calendarEventId;
    if (calendarEventId) {
        return calendarEventId;
    }

    if ((activity as any).job?.pointData?.pointType !== PointType.EVENT_POINT) {
        return;
    }

    return (activity as any).job?.pointData?.id;
};

export const getStartTimeFromActivity = (activity: Activity): Date | undefined => {
    if (!activity.arrivalTime) {
        return;
    }

    return addSeconds(new Date(activity.arrivalTime), activity.waitingTime + activity.preparationTime);
};
