import Twig from 'twig';
import { weAreInNativeApp } from 'utils';
import validator from 'validator';

const FIELD_TYPES = {
    TEXT: 'text',
    DECIMAL: 'decimal',
    PICK_LIST: 'picklist',
    DATETIME: 'datetime',
    CHECKBOX: 'checkbox',
    LABEL: 'label',
    BUTTON: 'button',
    FILE: 'file',
    STYLE: 'style',
    HTML: 'html',
    /*PICTURE: 'picture',
    VIDEO: 'video',*/
};

class AbstractField {
    constructor(type) {
        this.fieldType = type;
        this.label = null;
        this.description = null;
        this.value = null;
        this.oldValue = null;
        this.apiName = null;
        this.isHidden = false;
        this.isRequired = false;
        this.condition = null;

        this.handlers = [];
    }

    setHandlers(handlers) {
        this.handlers = handlers;

        return this;
    }

    getHandlers() {
        return this.handlers;
    }

    getType() {
        return this.fieldType;
    }

    setLabel(label) {
        this.label = label;

        return this;
    }

    getLabel() {
        return this.label;
    }

    setDescription(description) {
        this.description = description;

        return this;
    }

    getDescription() {
        return this.description;
    }

    setIsRequired(isRequired) {
        this.isRequired = isRequired;

        return this;
    }

    getIsRequired() {
        return this.isRequired && !this.isHidden && !this.isDisabled;
    }

    setValue(value) {
        this.value = value;

        return this;
    }

    getValue() {
        return this.value;
    }

    setOldValue(oldValue) {
        this.oldValue = oldValue;

        return this;
    }

    getOldValue() {
        return this.oldValue;
    }

    setApiName(apiName) {
        this.apiName = apiName;

        return this;
    }

    getApiName() {
        return this.apiName;
    }

    setIsHidden(isHidden) {
        this.isHidden = isHidden;

        return this;
    }

    getIsHidden() {
        return this.isHidden;
    }

    setIsDisabled(isDisabled) {
        this.isDisabled = isDisabled;

        return this;
    }

    getIsDisabled() {
        if (weAreInNativeApp() && this.is_barcode_data && !this.allowTypeManual) {
            return true;
        }
        return this.isDisabled;
    }

    setCondition(condition) {
        this.condition = condition;

        return this;
    }

    getCondition() {
        return this.condition;
    }

    getConditionAsTwig() {
        return '{{ ' + this.condition + ' }}';
    }

    isEmpty() {
        return validator.isEmpty(this.value ?? '');
    }

    validate(form) {
        const result = new Map();

        const formContext = form?.getFormContext() ?? {};

        if (this.getCondition()) {
            const condition = Twig.twig({ data: this.getConditionAsTwig() });
            const renderResult = condition.render(formContext);
            if (!renderResult || renderResult === 'false') {
                return result;
            }
        }

        if (validator.isEmpty(this.apiName, { ignore_whitespace: true })) {
            result.set('apiName', 'workflow_actions.forms.field.validation.required');
        }

        if (this.isEmpty()) {
            if (this.getIsRequired()) {
                result.set('value', 'workflow_actions.forms.field.validation.required');
            }

            return result;
        }

        const valueValidation = this.validateValue();

        if (valueValidation !== true) {
            result.set('value', 'workflow_actions.forms.field.validation.invalid_value');
        }

        return result;
    }

    validateValue() {
        throw new Error('Not implemented');
    }

    serialize() {
        const result = {};

        for (let key in this) {
            if (this.hasOwnProperty(key)) {
                result[key] = this[key];
            }
        }

        return result;
    }
}

export default AbstractField;

export { FIELD_TYPES };
