import React from 'react';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TableLoadingState } from '../TableLoadingState';
import { formatWithCommas, utcToUserTimezoneNoSeconds } from '../../utils';
import { withTranslation } from 'react-i18next';
import { sharedMapManager } from '../../service/SharedMapManager';
import PureFormDialog from '../PureFormDialog';
import SharedMapModal from './SharedMapModal';
import { Grid as MaterialGrid, Checkbox, IconButton, Icon } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import SharedMapModalSettings from './SharedMapModalSettings';
import { userManager } from '../../service/UserManager';
import { intercomManager } from '../../service/IntercomManager';
import SharedMapActivation from './SharedMapActivation';
import Confirmation from '../Confirmation';
import LandingLink from '../HelpLink/LandingLink';
import AddIconButton from '../CustomButton/AddIconButton';
import { LinearProgressWithLabel } from '../LinearProgress/LinearProgress';

const SETTINGS_MODAL_SWITCH = 'switch'; // settings opened by turning on switch: apiKey is mandatory
const SETTINGS_MODAL_BUTTON = 'button'; // settings opened by clicking button: apiKey is optional when switch is disabled

const FilterIcon = ({ type, ...restProps }) => {
    return <TableFilterRow.Icon type={type} {...restProps} />;
};

const FilterCell = ({ t, tReady, ...rest }) => {
    return <TableFilterRow.Cell {...rest} />;
};

class SharedMapList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requestParams: {
                sorting: [{ columnName: 'createdAt', direction: 'desc' }],
                filters: [],
                currentPage: 0,
                pageSize: 10,
            },
            rows: [],
            totalCount: 0,
            currentMap: null,
            settings: {
                enabled: false,
                apiKey: '',
                secretApiKey: '',
                modal: false,
                enableConfirmationModal: false,
            },
            usage: {
                daily: null,
                monthly: null,
            },
            subscription: null,
            initialized: false,
        };

        this.timeToApplyFilters = null;
        this.filterDelay = 500;

        this.columns = [
            {
                name: 'link',
                title: this.props.t('shared_map.link.link'),
                getCellValue: (row) => {
                    const fullLink = sharedMapManager.createFullLink(row.link);
                    return (
                        <div>
                            <span
                                onClick={this.handleOpen(row)}
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                {fullLink}
                            </span>
                        </div>
                    );
                },
            },
            {
                name: 'user',
                title: this.props.t('shared_map.user.title'),
                getCellValue: (row) => row.userName,
            },
            {
                name: 'urlSignature',
                title: this.props.t('shared_map.list.signature'),
                getCellValue: (row) => <Checkbox color="primary" checked={row.checkSignature} readOnly />,
            },
            { name: 'loadCount', title: this.props.t('shared_map.load_count') },
            {
                name: 'updatedBy',
                title: this.props.t('shared_map.list.modified_by'),
                getCellValue: (row) => {
                    return row.updatedBy ? row.updatedBy.name : null;
                },
            },
            {
                name: 'updatedAt',
                title: this.props.t('shared_map.list.modified_at'),
                getCellValue: (row) => {
                    if (!row.updatedAt) {
                        return null;
                    }
                    return utcToUserTimezoneNoSeconds(row.updatedAt, this.props.user);
                },
            },
            {
                name: 'controls',
                title: ' ',
                getCellValue: (row) => {
                    return (
                        <Confirmation
                            text={this.props.t('shared_map.delete.confirm')}
                            onConfirm={() => this.handleDelete(row)}
                        >
                            <Tooltip title={this.props.t('delete')}>
                                <IconButton data-testid="shared_map.delete">
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </Confirmation>
                    );
                },
            },
        ];

        this.pageSizes = [10, 25, 50];
    }

    componentDidMount() {
        this.load();
        this.init();
    }

    handleChangeSorting = (sorting) => {
        this.load({ sorting });
    };

    applyFilters(filters) {
        const d = new Date();
        if (d.getTime() >= this.timeToApplyFilters) {
            this.load({ filters });
        }
    }

    handleChangeFilters = (filters) => {
        const d = new Date();
        this.timeToApplyFilters = d.getTime() + this.filterDelay;
        setTimeout(() => {
            this.applyFilters(filters);
        }, this.filterDelay);
    };

    handleChangeCurrentPage = (currentPage) => {
        this.load({ currentPage });
    };

    handleChangePageSize = (pageSize) => {
        this.load({ pageSize });
    };

    handleSave = () => {
        this.props.enqueueSnackbar(this.props.t('shared_map.saved'), { variant: 'success' });
        this.setState({
            currentMap: null,
        });
        this.load();
    };

    handleClose = () => {
        this.setState({
            currentMap: null,
        });
    };

    init = () => {
        this.setState((state) => {
            return {
                settings: { ...state.settings, loading: true },
            };
        });
        Promise.all([
            sharedMapManager.getSettings(this.props.accountId),
            sharedMapManager.getLimits(this.props.accountId),
            userManager.getAccount(this.props.accountId),
        ])
            .then((results) => {
                const settings = results[0];
                const usage = results[1];
                const account = results[2];
                this.setState((state) => {
                    return {
                        settings: {
                            ...state.settings,
                            loading: false,
                            enabled: settings.enabled,
                            apiKey: settings.apiKey,
                            secretApiKey: settings.secretApiKey,
                        },
                        usage: {
                            daily: usage.daily,
                            monthly: usage.monthly,
                        },
                        subscription: account.subscription,
                        initialized: true,
                    };
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState((state) => {
                    return {
                        settings: { ...state.settings, loading: false },
                    };
                });
            });
    };

    load(requestParams = {}) {
        requestParams = { ...this.state.requestParams, ...requestParams };
        this.setState({
            requestParams,
            loading: true,
            rows: [],
        });

        sharedMapManager
            .list(
                this.props.accountId,
                requestParams.filters,
                requestParams.sorting,
                requestParams.currentPage + 1,
                requestParams.pageSize,
            )
            .then((data) => {
                this.setState({
                    rows: this.parse(data.items),
                    totalCount: parseInt(data.totalCount),
                    loading: false,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    loading: false,
                });
            });
    }

    parse = (items) => {
        return items.map((item) => {
            return {
                ...item,
                fullLink: sharedMapManager.createFullLink(item.link),
            };
        });
    };

    handleToggleModalOpen = () => {
        if (!this.state.settings.enabled) {
            this.setState((state) => {
                return {
                    settings: { ...state.settings, enableConfirmationModal: true },
                };
            });
        }
    };

    handleToggleModalClose = () => {
        this.setState((state) => {
            return {
                settings: { ...state.settings, enableConfirmationModal: false },
            };
        });
    };

    handleToggleSave = () => {
        const { enabled, apiKey } = this.state.settings;
        if (!enabled && !apiKey) {
            this.setState(
                ({ settings }) => ({
                    settings: {
                        ...settings,
                        enabled: !settings.enabled,
                        modal: false,
                        enableConfirmationModal: false,
                    },
                }),
                () => {
                    if (userManager.isRoleSuperAdmin()) {
                        return;
                    }

                    userManager.hey(); // reload available custom styles
                },
            );
        }

        sharedMapManager.saveSettings(this.props.accountId, !enabled).then((data) => {
            this.setState((state) => {
                return {
                    settings: { ...state.settings, enabled: data.enabled, enableConfirmationModal: false },
                };
            });
        });
    };

    handleDelete = (sharedMap) => {
        sharedMapManager.delete(this.props.accountId, sharedMap.id).then(() => {
            this.load();
        });
    };

    handleOpen = (sharedMap) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (sharedMap === null) {
            sharedMap = sharedMapManager.createDefault();
        }
        this.setState({
            currentMap: sharedMap,
        });
    };

    handleOpenSettings = () => {
        this.setState((state) => {
            return {
                settings: { ...state.settings, modal: SETTINGS_MODAL_BUTTON },
            };
        });
    };

    handleSaveModal = (apiKey, secretApiKey) => {
        this.setState(
            ({ settings }) => ({
                settings: {
                    ...settings,
                    apiKey,
                    secretApiKey,
                    enabled: settings.enabled || settings.modal === SETTINGS_MODAL_SWITCH,
                    modal: false,
                },
            }),
            () => {
                if (userManager.isRoleSuperAdmin()) {
                    return;
                }
                userManager.hey(); // reload available custom styles
            },
        );
    };

    handleCloseSettings = () => {
        this.setState((state) => {
            return {
                settings: { ...state.settings, modal: false },
            };
        });
    };

    openRaiseLimits = () => {
        intercomManager.showNewMessage(this.props.t('shared_map.limits.raise.text'));
    };

    render() {
        const { t } = this.props;
        const { initialized } = this.state;
        if (!initialized) {
            return t('loading');
        }
        const { enabled, loading } = this.state.settings;
        const { daily, monthly } = this.state.usage;
        const { subscription } = this.state;
        const dailyMax = subscription.maxMapLoadsDay;
        const monthlyMax = subscription.maxMapLoadsMonth;
        return (
            <div className="c-shared-maps-list">
                <MaterialGrid container spacing={1} alignItems="center">
                    <MaterialGrid item>
                        <h2>{this.props.t('main_menu.shared_maps')}</h2>
                    </MaterialGrid>
                    <MaterialGrid item>
                        <Tooltip title={enabled ? t('shared_map.add') : t('shared_map.disabled.hint')}>
                            <AddIconButton onClick={enabled ? this.handleOpen(null) : undefined} />
                        </Tooltip>
                    </MaterialGrid>
                    <MaterialGrid item style={{ marginLeft: 8 }}>
                        <FormControlLabel
                            margin="dense"
                            control={
                                enabled ? (
                                    <Confirmation
                                        text={this.props.t('shared_map.confirmation.disable')}
                                        onConfirm={this.handleToggleSave}
                                    >
                                        <Switch
                                            disabled={loading}
                                            checked={enabled}
                                            onChange={this.handleToggleModalOpen}
                                            color="primary"
                                            data-testid="shared_map.toggle_modal_open"
                                        />
                                    </Confirmation>
                                ) : (
                                    <Switch
                                        disabled={loading}
                                        checked={enabled}
                                        onChange={this.handleToggleModalOpen}
                                        color="primary"
                                        data-testid="shared_map.toggle_modal_open"
                                    />
                                )
                            }
                            label=""
                        />
                    </MaterialGrid>
                    <MaterialGrid item>
                        {enabled ? (
                            <span>{t('shared_map.state.active')}</span>
                        ) : (
                            <span>{t('shared_map.state.inactive')}</span>
                        )}
                    </MaterialGrid>
                    <MaterialGrid item style={{ marginLeft: 8 }}>
                        <Button
                            size="medium"
                            color="primary"
                            onClick={this.handleOpenSettings}
                            data-testid="shared_map.settings"
                        >
                            {t('shared_map.settings')}
                        </Button>
                    </MaterialGrid>
                    <MaterialGrid item style={{ marginLeft: 8 }}>
                        <MaterialGrid container spacing={1}>
                            <MaterialGrid item>{t('shared_map.limits.daily')}:</MaterialGrid>
                            <MaterialGrid item>
                                <LinearProgressWithLabel
                                    label={formatWithCommas(dailyMax)}
                                    value={Math.round((daily / dailyMax) * 100)}
                                    tooltip={t('shared_map.limits.daily.hint', { daily, dailyMax })}
                                    style={{ width: 100 }}
                                />
                            </MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>
                    <MaterialGrid item style={{ marginLeft: 8 }}>
                        <MaterialGrid container spacing={1}>
                            <MaterialGrid item>{t('shared_map.limits.monthly')}:</MaterialGrid>
                            <MaterialGrid item>
                                <LinearProgressWithLabel
                                    label={formatWithCommas(monthlyMax)}
                                    value={Math.round((monthly / monthlyMax) * 100)}
                                    tooltip={t('shared_map.limits.monthly.hint', { monthly, monthlyMax })}
                                    style={{ width: 100 }}
                                />
                            </MaterialGrid>
                        </MaterialGrid>
                    </MaterialGrid>
                    <MaterialGrid item style={{ marginLeft: 20 }}>
                        <Button
                            size="medium"
                            color="primary"
                            onClick={this.openRaiseLimits}
                            disabled={!enabled}
                            data-testid="shared_map.limits.raise"
                        >
                            {t('shared_map.limits.raise')}
                        </Button>
                    </MaterialGrid>
                </MaterialGrid>

                {enabled && (
                    <div>
                        <Grid rows={this.state.rows} columns={this.columns}>
                            <SortingState
                                defaultSorting={this.state.requestParams.sorting}
                                onSortingChange={this.handleChangeSorting}
                            />
                            <FilteringState
                                defaultFilters={this.state.requestParams.filters}
                                onFiltersChange={this.handleChangeFilters}
                                columnExtensions={[
                                    { columnName: 'link', filteringEnabled: false },
                                    { columnName: 'user', filteringEnabled: false },
                                    { columnName: 'urlSignature', filteringEnabled: false },
                                    { columnName: 'updatedAt', filteringEnabled: false },
                                    { columnName: 'updatedBy', filteringEnabled: false },
                                    { columnName: 'loadCount', filteringEnabled: false },
                                    { columnName: 'controls', filteringEnabled: false },
                                ]}
                            />
                            <PagingState
                                currentPage={this.state.requestParams.currentPage}
                                onCurrentPageChange={this.handleChangeCurrentPage}
                                onPageSizeChange={this.handleChangePageSize}
                                pageSize={this.state.requestParams.pageSize}
                            />
                            <CustomPaging totalCount={this.state.totalCount} />

                            <Table
                                cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                                columnExtensions={[
                                    { columnName: 'updatedAt', width: 170 },
                                    { columnName: 'updatedBy', width: 200 },
                                    { columnName: 'loadCount', width: 130 },
                                    { columnName: 'urlSignature', width: 150 },
                                    { columnName: 'controls', width: 70 },
                                ]}
                                noDataCellComponent={() => (
                                    <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                                )}
                            />
                            <TableHeaderRow
                                showSortingControls
                                cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                            />
                            <TableFilterRow
                                showFilterSelector
                                iconComponent={FilterIcon}
                                cellComponent={StyledFilterCell}
                            />
                            <PagingPanel pageSizes={this.pageSizes} />
                        </Grid>
                    </div>
                )}
                {this.state.currentMap !== null && (
                    <PureFormDialog
                        title={
                            <React.Fragment>
                                {t('shared_map.title')}
                                <LandingLink
                                    article="5202670"
                                    useLeadingIcon
                                    useTrailingIcon
                                    style={{ marginLeft: 24, fontSize: '70%' }}
                                >
                                    {t('help')}
                                </LandingLink>
                                <LandingLink
                                    article="5198246"
                                    useLeadingIcon
                                    useTrailingIcon
                                    style={{ marginLeft: 24, fontSize: '70%' }}
                                >
                                    {t('shared_map.modal.guide')}
                                </LandingLink>
                            </React.Fragment>
                        }
                        open
                        onClose={this.handleClose}
                        maxWidth="md"
                        fullWidth
                    >
                        <SharedMapModal
                            accountId={this.props.accountId}
                            sharedMap={this.state.currentMap}
                            onSaved={this.handleSave}
                        />
                    </PureFormDialog>
                )}
                {this.state.settings.modal !== false && (
                    <PureFormDialog
                        title={t('shared_map.settings.modal.header')}
                        open
                        onClose={this.handleCloseSettings}
                        maxWidth="sm"
                        fullWidth
                    >
                        <SharedMapModalSettings
                            apiKey={this.state.settings.apiKey}
                            enabled={enabled || this.state.settings.modal === SETTINGS_MODAL_SWITCH}
                            secretApiKey={this.state.settings.secretApiKey}
                            onSave={this.handleSaveModal}
                            accountId={this.props.accountId}
                        />
                    </PureFormDialog>
                )}
                {this.state.settings.enableConfirmationModal && (
                    <PureFormDialog
                        title={<span dangerouslySetInnerHTML={{ __html: t('shared_map.settings.modal.header') }} />}
                        open
                        onClose={this.handleToggleModalClose}
                        maxWidth="sm"
                        fullWidth
                    >
                        <SharedMapActivation
                            onSave={this.handleToggleSave}
                            subscription={subscription}
                            enabled={enabled || this.state.settings.modal === SETTINGS_MODAL_SWITCH}
                            accountId={this.props.accountId}
                        />
                    </PureFormDialog>
                )}
            </div>
        );
    }
}

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const rightIconCellStyles = {
    flexContainer: {
        flexFlow: 'row-reverse',
    },
};

const StyledFilterCell = withStyles(rightIconCellStyles)(withTranslation()(FilterCell));

SharedMapList.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default withTranslation()(withSnackbar(withRouter(SharedMapList)));
