import Tooltip from '@material-ui/core/Tooltip';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgressProps } from '@material-ui/core/LinearProgress';

const LinearProgressHeightened = withStyles({
    root: {
        height: 8,
    },
})(LinearProgress);

interface LinearProgressWithLabelProps extends LinearProgressProps {
    label: string;
    tooltip: NonNullable<React.ReactNode>;
    style?: React.CSSProperties;
}

export function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
    const { tooltip, ...rest } = props;
    return (
        <Tooltip
            title={tooltip}
            placement="top"
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        offset: {
                            enabled: true,
                            offset: '-20px, 0',
                        },
                    },
                },
            }}
        >
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgressHeightened variant="determinate" {...rest} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                        {props.label}
                    </Typography>
                </Box>
            </Box>
        </Tooltip>
    );
}
