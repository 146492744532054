import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { OrgSettings, SmtpCredentials } from 'interfaces/orgSettings';
import dispatcher from '../dispatcher';
import events from '../../events';

class OrgSettingsManager extends BackendService {
    private settings: Map<number, OrgSettings> = new Map();

    get(accountId: number): Promise<OrgSettings> {
        if (this.settings.has(accountId)) {
            return Promise.resolve(this.settings.get(accountId)!);
        }
        const url = reverse(apiRoutes.account.orgSettings.index, { accountId });
        return this.requestApi(url, 'GET').then((settings: OrgSettings) => {
            this.settings.set(accountId, settings);
            return settings;
        });
    }

    getSmtpCredentials(accountId: number): Promise<SmtpCredentials | null> {
        return this.get(accountId).then((settings) => {
            return settings.smtp || null;
        });
    }

    saveSmtpCredentials(accountId: number, smtpCredentials: SmtpCredentials): Promise<void> {
        const url = reverse(apiRoutes.account.orgSettings.smtp, { accountId });
        return this.requestApi(url, 'POST', smtpCredentials).then(() => {
            const settings = this.settings.get(accountId);
            if (settings) {
                this.settings.set(accountId, { ...settings, smtp: smtpCredentials });
                dispatcher.dispatch(events.SMTP_CREDENTIALS_UPDATED);
            }
        });
    }

    removeSmtpCredentials(accountId: number): Promise<void> {
        const url = reverse(apiRoutes.account.orgSettings.smtp, { accountId });
        return this.requestApi(url, 'DELETE').then(() => {
            const settings = this.settings.get(accountId);
            if (settings) {
                this.settings.set(accountId, { ...settings, smtp: null });
                dispatcher.dispatch(events.SMTP_CREDENTIALS_UPDATED);
            }
        });
    }
}

export const orgSettingsManager = new OrgSettingsManager();
