import {
    CustomPaging,
    DataTypeProvider,
    FilteringState,
    IntegratedSelection,
    PagingState,
    SelectionState,
    SortingState,
} from '@devexpress/dx-react-grid';
import DateTimeFormatter from '../DataTable/Formatter/DateTimeFormatter';
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { withStyles } from '@material-ui/core/styles';
import { DataTable, FilterCell, FilterIcon, HeaderCellContent } from '../DataTable';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Checkbox } from '@material-ui/core';
import Backdrop from '../Backdrop';
import { userManager } from '../../service/UserManager';
import { roleManager } from '../../service/RoleManager';
import { accountsManager } from '../../service/AccountsManager';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Confirmation from '../Confirmation';
import DeleteUserDialog from './DeleteUserDialog';
import DottedLink from '../DottedLink';
import Tooltip from '@material-ui/core/Tooltip';
import { userPropertiesManager } from '../../service/UserForm';
import { UserPropertiesTab } from '../types';
import { ImpersonateMode as SwitchMode } from '../../api/types';
import { routes } from '../../routes';
import UserPropertiesDialog from '../UserForm/UserPropertiesDialog';
import PureFormDialog from '../PureFormDialog';
import SwitchModeInfo from './SwitchModeInfo';
import { FormActions } from '../PureFormDialog/Form';
import {
    routeDesignConfigManager,
    routeDesignManager,
    routeReportLoadedSessionRouteManager,
    routeReportManager,
    routeViewerManager,
    routingSessionManager,
    tripModeManager,
} from '../../service/MapPage';

const GridRoot = (props) => <DataGrid.Root {...props} className="c-users-list__table" />;

const tableCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

export class UsersListTable extends DataTable {
    constructor(props) {
        super(props);

        this.state = {
            structure: null,
            loading: true,
            users: [],
            totalUsers: 0,
            selectedUserIds: [],
            roles: [],
            rolesSharedMaps: [],
            rolePicklist: [],
            currentUser: null,
            usersBeingDeleted: new Map(),
            usersBeingReset: new Map(),
            anchorEl: null,
            account: null,
            subscription: null,
            loginAsModeUserId: null,
            loginAsSwitching: false,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
            errorMessage: null,
        };

        this.pageSizes = [10, 25, 50, 100, 0];
    }

    isSwitchUserEnabled = (user) => {
        if (userManager.getCurrentUser().id === user.id) {
            return false;
        }

        return userManager.isRoleSuperAdmin() || userManager.isRoleAdmin();
    };

    isSwitchUserForSetupEnabled = (user) => {
        if (!user.role) {
            return false;
        }
        if (!user.role.forSharedMap) {
            return false;
        }
        if (userManager.getCurrentUser().id === user.id) {
            return false;
        }

        return userManager.isRoleSuperAdmin() || userManager.isRoleAdmin();
    };

    refreshRoles = () => {
        return roleManager.getAccountRoles(this.props.accountId).then((roles) => {
            this.setState({
                roles: [...roles],
                rolesSharedMaps: roles.filter((item) => item.forSharedMap),
                rolePicklist: this.getUserTableRoles(roles),
            });
        });
    };

    getUserTableRoles = (roles = []) => {
        let tabRoles = this.state.roles;

        if (this.props.sharedMapUsers !== null) {
            tabRoles = roles.filter((item) => {
                return this.props.sharedMapUsers ? item.forSharedMap : !item.forSharedMap;
            });
        }

        return tabRoles.map((role) => {
            return {
                label: role.name,
                value: role.id,
            };
        });
    };

    refreshAccount = () => {
        accountsManager.load(this.props.accountId).then((account) => {
            this.setState({
                account,
            });
        });
    };

    /**
     * @param {UserData} user
     */
    handleUserSaved = (user) => {
        this.setState({
            currentUser: null,
        });

        this.handleUserSavingSuccess(user);
        this.props.onSavedUser(user);
    };

    handleUserSavingSuccess = (user) => {
        this.props.enqueueSnackbar(this.props.t('account.user.saved', { user: user.name }), { variant: 'success' });
    };

    loadSubscription = () => {
        let subManager = subscriptionManagerFactory.getManager(this.props.accountId);
        return subManager.getSubscription();
    };

    componentDidMount() {
        dispatcher.subscribe(
            [
                events.EVENT_ROLE_CREATED,
                events.EVENT_ROLE_CHANGED,
                events.EVENT_ROLES_CHANGED,
                events.EVENT_ROLE_DELETED,
                events.EVENT_ACCOUNT_PERMISSIONS_SAVED,
            ],
            this,
            () => {
                this.refreshRoles();
                this.updateTableData();
            },
        );
        dispatcher.subscribe([events.EVENT_USER_CHANGED, events.EVENT_USER_CREATED], this, () => {
            this.requestData();
            this.refreshAccount();
        });
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
            this.refreshAccount();
        });
        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.accountId !== null && this.props.accountId === subscription.accountId) {
                this.setState({
                    subscription,
                });
            }
        });
        dispatcher.subscribe(events.WS_USERS_DELETED, this, ({ message, variant }) => {
            this.refreshAccount();

            if (variant === 'success') {
                this.requestData();
            }
        });

        this.updateTableData();
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            this.refreshRoles(this.props.accountId);
        }
        if (prevProps.selectedUserIds !== this.props.selectedUserIds) {
            this.setState({ selectedUserIds: this.props.selectedUserIds });
        }
    }

    updateTableData = () => {
        Promise.all([
            this.refreshRoles(),
            userManager.requestCurrentUser(),
            userManager.getAccount(this.props.accountId),
            this.loadSubscription(),
        ])
            .then((result) => {
                this.setState({
                    loading: false,
                    account: { ...result[2] },
                    subscription: { ...result[3] },
                });

                super.componentDidMount();
            })
            .catch((error) => {
                if (error.code === 403) {
                    error.skipSentry = true;
                }
                throw error;
            });
    };

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'sso', width: 50, align: 'center' },
            { columnName: 'role', width: 180, align: 'left' },
            { columnName: 'email', width: 260, align: 'left' },
            { columnName: 'leftReview', width: 120, align: 'center' },
        ];
        structure.dateTimeColumns = ['createdAt', 'updatedAt'];

        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'sso', sortingEnabled: false },
            { columnName: 'leftReview', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c) => c.title).map((c) => c.title);
        return structure;
    }

    requestData = async (ignorePage = false, parentTimer = null) => {
        this.setState({
            loading: true,
            users: [],
            selectedUserIds: [],
        });

        this.props.onSelectionUsersChange([]);

        if (this.props.sharedMapUsers && !this.state.rolesSharedMaps.length) {
            this.setState({
                users: [],
                totalUsers: 0,
                errorMessage: null,
                loading: false,
            });

            return [];
        } else {
            let filters = this.getFilters();
            if (
                !filters.find((f) => f.columnName === 'role') &&
                this.state.rolesSharedMaps.length &&
                this.props.sharedMapUsers !== null
            ) {
                filters.push({
                    columnName: 'role',
                    operation: this.props.sharedMapUsers ? 'in' : 'notIn',
                    value: this.state.rolesSharedMaps.map((role) => role.id),
                });
            }

            try {
                const result = await userManager.findAccountUsers(
                    this.props.accountId,
                    filters,
                    this.sorting,
                    this.state.pagination.size ? this.state.pagination.current + 1 : 0,
                    this.state.pagination.size,
                );

                this.setState({
                    users: result.items,
                    totalUsers: result.total,
                    errorMessage: null,
                });

                return result;
            } catch (e) {
                this.setState({
                    errorMessage: e.message,
                });

                return [];
            } finally {
                this.setState({ loading: false });
            }
        }
    };

    getFields() {
        let columns = [
            {
                id: 'name',
                name: 'name',
                columnName: 'name',
                type: 'string',
                title: this.props.t('name'),
                getCellValue: (row) => {
                    const { anchorEl } = this.state;
                    return (
                        <React.Fragment>
                            <IconButton
                                color="primary"
                                component="span"
                                data-user-id={row.id}
                                onClick={this.handleOpenMenu}
                                data-testid="account.user.open_menu"
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={anchorEl !== null && parseInt(anchorEl.dataset.userId) === row.id}
                                onClose={this.handleCloseMenu}
                                disableAutoFocusItem
                                className="user-list-menu"
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: anchorEl?.getBoundingClientRect().y ?? 'top',
                                    horizontal: anchorEl?.getBoundingClientRect().x ?? 'center',
                                }}
                            >
                                {this.isSwitchUserEnabled(row) && (
                                    <MenuItem onClick={this.handleSwitchUser(row)} data-testid="account.user.login_as">
                                        {this.props.t('account.user.login_as')}
                                    </MenuItem>
                                )}
                                {this.isSwitchUserForSetupEnabled(row) && (
                                    <MenuItem
                                        onClick={this.handleSwitchUserForSetup(row)}
                                        data-testid="account.user.login_as.setup"
                                    >
                                        {this.props.t('account.user.login_as.setup')}
                                    </MenuItem>
                                )}
                                <MenuItem onClick={this.handleOpen(row)} data-testid="account.user.edit">
                                    {this.props.t('edit')}
                                </MenuItem>
                                <Confirmation
                                    text={this.props.t('account.user.personal_settings.reset.confirm')}
                                    onConfirm={(e) => this.handleCloseMenu(e) && this.handleResetPersonalSetting(row)}
                                    onReject={this.handleCloseMenu}
                                >
                                    <MenuItem
                                        disabled={this.state.usersBeingReset.has(row.id)}
                                        data-testid="account.user.personal_settings.reset"
                                    >
                                        {this.props.t('account.user.personal_settings.reset')}
                                    </MenuItem>
                                </Confirmation>
                                <DeleteUserDialog
                                    onConfirm={(e, newOwner) =>
                                        this.handleCloseMenu(e) && this.handleDeleteUsers(row.id, newOwner)
                                    }
                                    onReject={this.handleCloseMenu}
                                    deletedUserIds={[row.id]}
                                    accountId={this.props.accountId}
                                    rolesSharedMaps={this.state.rolesSharedMaps}
                                >
                                    <MenuItem
                                        disabled={
                                            this.state.usersBeingDeleted.has(row.id) ||
                                            (userManager.getCurrentUser() && userManager.getCurrentUser().id === row.id)
                                        }
                                        data-testid="account.user.delete"
                                    >
                                        {this.props.t('delete')}
                                    </MenuItem>
                                </DeleteUserDialog>
                            </Menu>
                            <DottedLink
                                onClick={this.handleOpen(row)}
                                data-testid="account.user.personal_settings.settings"
                            >
                                {row.name}
                            </DottedLink>
                        </React.Fragment>
                    );
                },
            },
            {
                id: 'role',
                name: 'role',
                columnName: 'role',
                title: this.props.t('profile'),
                type: 'text[]',
                picklist: this.state.rolePicklist,
                getCellValue: (row) => {
                    const role = this.state.roles.find((item) => item.id === row.role.id);
                    const name = role ? role.name : '?';
                    return <span>{name}</span>;
                },
            },
            {
                id: 'createdAt',
                name: 'createdAt',
                type: 'datetime',
                title: this.props.t('created_at'),
            },
            {
                id: 'createdBy',
                name: 'createdBy',
                columnName: 'createdBy',
                type: 'string',
                title: this.props.t('created_by'),
                getCellValue: (row) => <span>{row.createdBy ? row.createdBy.name : null}</span>,
            },
            {
                id: 'updatedAt',
                name: 'updatedAt',
                type: 'datetime',
                title: this.props.t('modified_at'),
            },
            {
                id: 'updatedBy',
                name: 'updatedBy',
                columnName: 'updatedBy',
                type: 'string',
                title: this.props.t('modified_by'),
                getCellValue: (row) => <span>{row.updatedBy ? row.updatedBy.name : null}</span>,
            },
            {
                id: 'sso',
                name: 'sso',
                columnName: 'sso',
                title: ' ',
                getCellValue: (row) => {
                    if (row.ssoId) {
                        return (
                            <Tooltip title={this.props.t('account.user.sso_id', { id: row.ssoId })}>
                                <span className="fas fa-link" style={{ color: '#777', fontSize: '1.25em' }} />
                            </Tooltip>
                        );
                    }
                    return null;
                },
            },
        ];

        if (!this.props.sharedMapUsers) {
            columns.splice(2, 0, {
                id: 'email',
                name: 'email',
                columnName: 'email',
                type: 'string',
                title: this.props.t('email'),
            });
        }

        if (userManager.isRoleSuperAdmin()) {
            columns.push({
                id: 'leftReview',
                name: 'leftReview',
                columnName: 'leftReview',
                title: this.props.t('account.user.personal_settings.left_review'),
                getCellValue: (row) => {
                    if (row.leftReview) {
                        return this.props.t('yes');
                    }
                    return null;
                },
            });
        }

        return Promise.resolve(columns);
    }

    handleOpenMenu = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseMenu = (e) => {
        e.stopPropagation();
        this.setState({
            anchorEl: null,
        });
        return true;
    };

    handleResetPersonalSetting = (user) => {
        this.setState((state) => {
            state.usersBeingReset.set(user.id, true);
            return state;
        });
        userManager
            .resetPersonalSettings(user)
            .then(() => {
                this.handleResetPersonalSettingSuccess(user);
            })
            .catch((error) => {
                this.handleResetPersonalSettingError(user);
            });
    };

    handleOpen = (user) => (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { account } = this.state;
        if (user === null) {
            user = userManager.constructor.getDefaultUser(account.id);
        }
        this.setState({
            currentUser: user,
            anchorEl: null,
        });

        userPropertiesManager.openModal(account, user, UserPropertiesTab.TAB_PERSONAL);
    };

    handleClose = () => {
        this.setState({
            currentUser: null,
        });
    };

    handleSwitchUser = (user) => (e) => {
        e.stopPropagation();
        this.setState({
            loginAsModeUserId: user.id,
            anchorEl: null,
        });
    };

    handleSwitchUserSafeMode = () => {
        userManager
            .switchUser(this.state.loginAsModeUserId, SwitchMode.SAFE)
            .then(() => {
                this.setState({
                    loginAsModeUserId: null,
                });
                this.props.history.push(routes.client);
                this.resetRoutingPanel();
            })
            .catch((error) => {
                this.handleSwitchUserError(error);
                this.setState({
                    loginAsSwitching: false,
                });
            });
    };

    handleSwitchUserFullMode = () => {
        userManager
            .switchUser(this.state.loginAsModeUserId, SwitchMode.FULL)
            .then(() => {
                this.setState({
                    loginAsModeUserId: null,
                });
                this.props.history.push(routes.client);
                this.resetRoutingPanel();
            })
            .catch((error) => {
                this.handleSwitchUserError(error);
                this.setState({
                    loginAsSwitching: false,
                });
            });
    };

    handleSwitchUserForSetup = (user) => (e) => {
        e.stopPropagation();
        userManager
            .switchUser(user.id, SwitchMode.SETUP)
            .then(() => {
                this.props.history.push(routes.client);
            })
            .catch((error) => {
                this.handleSwitchUserError(error);
            });
    };

    resetRoutingPanel = () => {
        routingSessionManager.reset();
        routeReportManager.reset();
        routeDesignManager.reset();
        routeDesignConfigManager.reset();
        tripModeManager.reset();
        routeReportLoadedSessionRouteManager.reset();
        routeViewerManager.clear();
    };

    tableRow = ({ row, children, ...props }) => {
        return (
            <Table.Row {...props} children={children} onClick={this.handleOpen(row)} style={{ cursor: 'pointer' }} />
        );
    };

    handleResetPersonalSettingError = (user) => {
        this.props.enqueueSnackbar(this.props.t('account.user.personal_settings.reset.error', { user: user.name }), {
            variant: 'error',
        });
    };

    handleResetPersonalSettingSuccess = (user) => {
        this.props.enqueueSnackbar(this.props.t('account.user.personal_settings.reset.success'), {
            variant: 'success',
        });
    };

    handleSwitchUserError = (error) => {
        console.error(error);
    };

    handleSelectionChange = (selection) => {
        this.setState({
            selectedUserIds: selection,
        });
        this.props.onSelectionUsersChange(selection);
    };

    handleDeleteUsers = async (userIds, newOwner) => {
        this.setState({ loading: true, errorMessage: null });

        userIds = userIds instanceof Array ? userIds : [userIds];

        try {
            return await userManager.deleteUsers(this.props.accountId, userIds, newOwner);
        } catch (e) {
            this.setState({
                errorMessage: e.message,
            });
        }
    };

    cellSelectedComponent = (props) => {
        const { selected, onToggle } = props;
        return (
            <Table.Cell {...props} style={{ padding: '0 4px' }}>
                <div>
                    <Checkbox checked={selected} onChange={onToggle} color="primary" />
                </div>
            </Table.Cell>
        );
    };

    headerCellSelectedComponent = (props) => {
        const { allSelected, onToggle, someSelected, ...rest } = props;
        return (
            <Table.Cell {...rest} style={{ padding: '0 4px' }}>
                <Checkbox
                    indeterminate={someSelected}
                    checked={allSelected}
                    onChange={() => onToggle(!allSelected)}
                    color="primary"
                />
            </Table.Cell>
        );
    };

    rowSelectedComponent = (props) => {
        const { children, classes, selectByRowClick, highlighted, ...rest } = props;
        return <Table.Row {...rest}>{children}</Table.Row>;
    };

    render() {
        const { structure, loginAsSwitching } = this.state;
        const { t } = this.props;

        if (structure === null) {
            return <Backdrop loading={true} />;
        }

        const showLoginAsModeDialog = !!this.state.loginAsModeUserId;

        return (
            <React.Fragment>
                <DataGrid
                    rows={this.state.users}
                    columns={this.state.structure.columns}
                    rootComponent={GridRoot}
                    getRowId={(row) => row.id}
                >
                    <SortingState
                        columnExtensions={this.state.structure.noSortingColumns}
                        onSortingChange={this.handleSortingChanged}
                    />
                    <DataTypeProvider
                        for={this.state.structure.dateTimeColumns}
                        availableFilterOperations={this.dateFilterOperations}
                        formatterComponent={DateTimeFormatter}
                    />
                    <DataTypeProvider
                        for={['name', 'role', 'email', 'createdBy', 'updatedBy']}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <FilteringState
                        columnExtensions={[
                            { columnName: 'sso', filteringEnabled: false },
                            { columnName: 'leftReview', filteringEnabled: false },
                        ]}
                        onFiltersChange={this.handleFiltersChanged}
                    />
                    <Table
                        noDataCellComponent={() => (
                            <TableLoadingState
                                columnCount={this.state.structure.columns.length}
                                loading={this.state.loading}
                            />
                        )}
                        rowComponent={this.InteractiveRow}
                        cellComponent={withStyles(tableCellStyles)(Table.Cell)}
                        columnExtensions={this.state.structure.exts}
                    />

                    <TableHeaderRow showSortingControls contentComponent={HeaderCellContent} />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        messages={this.filterMessages}
                        cellComponent={withStyles({
                            cell: {
                                '&:first-child': {
                                    paddingLeft: 0,
                                },
                                '&:last-child': {
                                    paddingRight: 0,
                                },
                            },
                            flexContainer: {
                                flexFlow: 'row-reverse',
                            },
                        })(FilterCell)}
                    />
                    <PagingState
                        pageSize={this.state.pagination.size}
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                    />
                    <CustomPaging totalCount={this.state.totalUsers} />
                    <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />

                    <SelectionState
                        selection={this.state.selectedUserIds}
                        onSelectionChange={this.handleSelectionChange}
                    />
                    <IntegratedSelection />
                    <TableSelection
                        showSelectAll={true}
                        highlightRow={true}
                        cellComponent={this.cellSelectedComponent}
                        rowComponent={this.rowSelectedComponent}
                        headerCellComponent={this.headerCellSelectedComponent}
                    />
                </DataGrid>

                <UserPropertiesDialog onSaved={this.handleUserSaved} onCancel={this.handleClose} />

                <PureFormDialog
                    open={showLoginAsModeDialog}
                    title={t('account.user.login_as.form.title')}
                    onClose={() => {
                        this.setState({ loginAsModeUserId: null });
                    }}
                >
                    <SwitchModeInfo loading={loginAsSwitching} />
                    <FormActions>
                        <Button
                            color="secondary"
                            disabled={loginAsSwitching}
                            onClick={this.handleSwitchUserFullMode}
                            data-testid="account.user.login_as.form.full"
                        >
                            {t('account.user.login_as.form.full')}
                        </Button>
                        <Button
                            color="primary"
                            disabled={loginAsSwitching}
                            onClick={this.handleSwitchUserSafeMode}
                            data-testid="account.user.login_as.form.safe"
                        >
                            {t('account.user.login_as.form.safe')}
                        </Button>
                    </FormActions>
                </PureFormDialog>
            </React.Fragment>
        );
    }
}

UsersListTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    selectedUserIds: PropTypes.array,
    sharedMapUsers: PropTypes.bool,
    onSavedUser: PropTypes.func,
    onSelectionUsersChange: PropTypes.func,
};

UsersListTable.defaultProps = {
    sharedMapUsers: null,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(withRouter(observer(UsersListTable))));
