import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@material-ui/core';
import { FieldLookupType, IField } from 'components/types';

interface Props extends WithTranslation {
    field?: IField;
    onOpenShowSearch: () => void;
    onOpenFormulaModal: () => void;
}

class FormulaButtonGroup extends React.PureComponent<Props> {
    render() {
        const { t, field, onOpenShowSearch, onOpenFormulaModal } = this.props;

        const showButtonFind =
            field &&
            this.isLookup(field) &&
            field.lookupData?.type !== FieldLookupType.POLYMORPHIC &&
            field.lookupData?.type !== FieldLookupType.FEW;

        return (
            <ButtonGroup variant="text" aria-label="formula input button group">
                {showButtonFind && (
                    <Button
                        size="small"
                        color="primary"
                        onClick={onOpenShowSearch}
                        data-testid="workflow_actions.form.create_record.target_value.find"
                    >
                        {t('workflow_actions.form.create_record.target_value.find')}
                    </Button>
                )}
                <Button
                    size="small"
                    color="primary"
                    onClick={onOpenFormulaModal}
                    data-testid="workflow_actions.form.edit"
                >
                    {t('workflow_actions.form.edit')}
                </Button>
            </ButtonGroup>
        );
    }

    private isLookup = (field: IField) => {
        return !!(field && field.lookupData && field.lookupData.type);
    };
}

export default withTranslation()(FormulaButtonGroup);
