import React from 'react';
import PropTypes from 'prop-types';
import {
    TextField,
    Select,
    MenuItem,
    Icon,
    IconButton,
    Tooltip,
    FormControl,
    FormHelperText,
    OutlinedInput,
    InputLabel,
} from '@material-ui/core';
import './style.css';
import { Autocomplete } from '@material-ui/lab';
import {
    default as ExpressionBuilder,
    ExpressionInput,
    dateVariables,
    dateTimeVariables,
    userVariables,
    specialVariables,
} from './index';
import { withTranslation } from 'react-i18next';
import ConstantInput from './ConstantInput/index';

import { CallContext, TYPE_ENTITY_BASE, TYPE_ENTITY_BASE_OLD, TYPE_ENTITY_TARGET } from '../utils/CallContext';

export const isUrlParam = (value) => /{{\s*url\.[a-zA-Z0-9_]+\s*}}/.test(value);
export const isFilterParam = (value) => /{{\s*filter\.[a-zA-Z0-9_]+\s*}}/.test(value);
export const isFieldOperand = (operandType) =>
    [TYPE_ENTITY_BASE, TYPE_ENTITY_BASE_OLD, TYPE_ENTITY_TARGET].includes(operandType);

class Expression extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsX: [],
            optionsY: [],
        };

        this.operators = {
            '=': '=',
            '!=': '!=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
            c: this.props.t('expresion_builder.operators.contains'),
            '!c': this.props.t('expresion_builder.operators.dont_contains'),
            s: this.props.t('expresion_builder.operators.start_with'),
            e: this.props.t('expresion_builder.operators.ends_with'),
            '~': this.props.t('expresion_builder.operators.empty'),
            '!~': this.props.t('expresion_builder.operators.is_not_empty'),
            true: this.props.t('expresion_builder.operators.true'),
            false: this.props.t('expresion_builder.operators.false'),
            in: this.props.t('expresion_builder.operators.in'),
            '!in': this.props.t('expresion_builder.operators.not_in'),
            '@>': this.props.t('expresion_builder.operators.contains_all'),
            '!@>': this.props.t('expresion_builder.operators.does_not_contain_all'),
            '&&': this.props.t('expresion_builder.operators.contains_any'),
            '!&&': this.props.t('expresion_builder.operators.does_not_contain_any'),
        };
    }

    componentDidMount() {
        this.initExpressionOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.expression) !== JSON.stringify(this.props.expression)) {
            this.initExpressionOptions();
        }
    }

    initExpressionOptions = () => {
        const optionsX = this.initOptionsX();
        const optionsY = this.initOptionsY(this.initX(this.props.expression, optionsX));

        this.setState({ optionsX, optionsY });
    };

    initOptionsX = () => {
        const options = [];
        if (this.props.addSpecialSection) {
            const extendedSpecialVariables = { ...specialVariables, ...this.props.specialSectionOptions };
            for (let key of Object.keys(extendedSpecialVariables)) {
                options.push({
                    type: 'var',
                    label: extendedSpecialVariables[key],
                    value: key,
                    groupBy: this.props.t('expresion_builder.special_value'),
                });
            }
        }
        return options.concat(this.filterLookupFields(this.props.fields));
    };

    initOptionsY = (xOperand = null) => {
        const options = [
            {
                label: this.props.t('expresion_builder.constant_value'),
                type: 'const',
                value: 'const',
            },
        ];

        if (xOperand && isFieldOperand(xOperand.type)) {
            if (xOperand.field.lookupData && xOperand.field.lookupData.type === 'polymorphic') {
                return options;
            }
        }

        if (this.props.enableFormulaMode) {
            options.push({
                label: this.props.t('expresion_builder.twig_template'),
                type: 'twig',
                value: 'twig',
            });
        }
        if (this.props.enableUrlFilterParameters) {
            options.push({
                label: this.props.t('expresion_builder.url_parameter'),
                type: 'url',
                value: 'url',
            });
            options.push({
                label: this.props.t('expresion_builder.filter_parameter'),
                type: 'filter',
                value: 'filter',
            });
        }
        if (this.props.addSpecialSection) {
            const extendedSpecialVariables = { ...specialVariables, ...this.props.specialSectionOptions };
            for (let key of Object.keys(extendedSpecialVariables)) {
                options.push({
                    type: 'var',
                    label: extendedSpecialVariables[key],
                    value: key,
                    groupBy: this.props.t('expresion_builder.special_value'),
                });
            }
        }
        let xOperandType;
        if (xOperand && isFieldOperand(xOperand.type)) {
            xOperandType = xOperand.field.type;
        } else if (xOperand && xOperand.type === 'var') {
            xOperandType = ExpressionBuilder.getFunctionType(xOperand.value);
        }
        if (xOperandType === 'date') {
            for (let key of Object.keys(dateVariables)) {
                options.push({
                    type: 'var',
                    label: dateVariables[key],
                    value: key,
                    groupBy: this.props.t('expresion_builder.dynamic_value'),
                });
            }
        }
        if (xOperandType === 'datetime') {
            for (let key of Object.keys(dateTimeVariables)) {
                options.push({
                    type: 'var',
                    label: dateTimeVariables[key],
                    value: key,
                    groupBy: this.props.t('expresion_builder.dynamic_value'),
                });
            }
        }
        if (xOperandType === 'string' || xOperandType === 'text') {
            for (let key of Object.keys(userVariables)) {
                options.push({
                    type: 'var',
                    label: userVariables[key],
                    value: key,
                    groupBy: this.props.t('expresion_builder.user_fields'),
                });
            }
        }
        return options.concat(this.filterLookupFields(this.props.fields));
    };

    initX = (item, options = this.state.optionsX) => {
        if (!item) {
            return null;
        }
        let xOperand;
        if (item.xField) {
            const type = item.xFieldType || TYPE_ENTITY_BASE;
            xOperand = type + '_' + item.xField;
        } else if (item.xFunc) {
            xOperand = item.xFunc;
        }
        return options.filter((o) => o.value === xOperand).pop() || null;
    };

    initY = (item, options = this.state.optionsY) => {
        if (!item) {
            return null;
        }
        let operandValue;
        if (item.yField !== undefined) {
            const type = item.yFieldType || TYPE_ENTITY_BASE;
            operandValue = type + '_' + item.yField;
        } else if (item.yExpr !== undefined) {
            const expr = item.yExpr.match(/^([a-z]+)/i);
            operandValue = expr && expr[1];
        } else if (item.yConst !== undefined) {
            operandValue = item.yIsTwig === true ? 'twig' : 'const';
            if (isUrlParam(item.yConst)) {
                operandValue = 'url';
            } else if (isFilterParam(item.yConst)) {
                operandValue = 'filter';
            }
        }

        return options.filter((o) => o.value === operandValue).pop() || null;
    };

    getFieldById = (id) => {
        for (let field of this.props.fields.filter((f) => f.field && f.field.id).map((f) => f.field)) {
            // eslint-disable-next-line eqeqeq
            if (field.id == id) {
                return field;
            }
        }
        return null;
    };

    getFirstOperandType = (expr) => {
        if (expr && expr.xField) {
            const field = this.getFieldById(expr.xField);
            return field && field.type;
        } else if (expr && expr.xFunc) {
            return ExpressionBuilder.getFunctionType(expr.xFunc);
        }
        return '';
    };

    getAutocompleteOptionLabel = (option) => {
        let label = option.label;
        if (option.type === 'record' || option.type === 'oldRecord') {
            label = option.field.lookupData === null ? option.label : option.label.replace(/\s\((ID)\)$/, '');
        }

        return this.props.groupFieldOptions && isFieldOperand(option.type) && option.groupBy
            ? `${option.groupBy} > ${label}`
            : label;
    };

    getRenderOption = (option) => {
        let label = option.label;
        if (option.type === 'record' || option.type === 'oldRecord') {
            label = option.field.lookupData === null ? option.label : option.label.replace(/\s\((ID)\)$/, '');
        }

        return label;
    };

    handleXOperand = (operandX) => {
        // eslint-disable-next-line default-case
        switch (operandX.type) {
            case TYPE_ENTITY_BASE:
            case TYPE_ENTITY_BASE_OLD:
            case TYPE_ENTITY_TARGET:
                const type = this.props.enableFormulaMode ? operandX.type : null;
                this.props.onExpressionChange(this.props.id, operandX.field && operandX.field.id, 'xField', type);
                break;
            case 'var':
                this.props.onExpressionChange(this.props.id, operandX.value, 'xFunc');
                break;
        }
        this.setState({ optionsY: this.initOptionsY(operandX) });
    };

    handleYOperand = (operandY) => {
        // eslint-disable-next-line default-case
        switch (operandY.type) {
            case 'const':
                const constValue = ExpressionBuilder.getInitialValue(
                    this.getFirstOperandType(this.props.expression),
                    this.props.expression.o,
                );
                this.props.onExpressionChange(this.props.id, constValue, 'yConst');
                break;
            case 'twig':
                this.props.onExpressionChange(this.props.id, '', 'yTwig');
                break;
            case 'url':
                this.props.onExpressionChange(this.props.id, null, 'url');
                break;
            case 'filter':
                this.props.onExpressionChange(this.props.id, null, 'filter');
                break;
            case TYPE_ENTITY_BASE:
            case TYPE_ENTITY_BASE_OLD:
            case TYPE_ENTITY_TARGET:
                const type = this.props.enableFormulaMode ? operandY.type : null;
                this.props.onExpressionChange(this.props.id, operandY.field && operandY.field.id, 'yField', type);
                break;
            case 'var':
                this.props.onExpressionChange(this.props.id, operandY.value, 'yExpr');
                break;
        }
    };

    filterLookupFields = (fields) => {
        return fields.filter((item) => {
            if (item.field.lookupData === null) {
                return true;
            }

            const match = item.field.apiName.match(/_(ID|NAME|TYPE)$/);
            return match === null || match[1] === 'ID';
        });
    };

    render() {
        const item = this.props.expression;
        const disabled = this.props.disabled;
        const field = this.getFieldById(item.xField);

        let operators =
            field === null
                ? ExpressionBuilder.getOperatorsForFunc(item.xFunc)
                : ExpressionBuilder.getOperatorsForField(field);
        const isUnaryOperator = ExpressionBuilder.isUnaryOperator(item.o);
        const isArrayOperator = ExpressionBuilder.isArrayOperator(item.o);
        const isTwig = item.yIsTwig;

        if (typeof item.yConst === 'string' && (isUrlParam(item.yConst) || isFilterParam(item.yConst))) {
            // Skip "among" and "not among" operators for filter/url params
            operators = operators.filter((op) => op !== 'in' && op !== '!in');
        }

        return (
            <div className="exp-operand">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <FormControl
                        variant="outlined"
                        error={this.props.errors.has(this.props.id + '-xField')} // TODO: maybe postfix should be like xOperand?
                        style={{ marginRight: '8px', width: '250px', minWidth: '250px' }}
                    >
                        <Autocomplete
                            id={this.props.id}
                            options={this.state.optionsX}
                            getOptionLabel={this.getAutocompleteOptionLabel}
                            renderOption={this.getRenderOption}
                            getOptionSelected={(option, value) => option.value === value.value}
                            groupBy={(options) => options && options.groupBy}
                            value={this.initX(this.props.expression)}
                            onChange={(e, value) => this.handleXOperand(value)}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={this.props.t('expresion_builder.field_to_compare_its_value')}
                                    data-testid="expresion_builder.field_to_compare_its_value"
                                    placeholder={this.props.t(
                                        'expresion_builder.field_to_compare_its_value.placeholder',
                                    )}
                                    variant="outlined"
                                />
                            )}
                            disabled={disabled}
                        />
                        {this.props.errors.has(this.props.id + '-xField') && (
                            <FormHelperText>{this.props.errors.get(this.props.id + '-xField')}</FormHelperText>
                        )}
                    </FormControl>

                    <FormControl
                        variant="outlined"
                        error={this.props.errors.has(this.props.id + '-o')}
                        style={{ marginRight: '8px', minWidth: '120px' }}
                    >
                        <InputLabel>Operation</InputLabel>
                        <Select
                            value={item.o}
                            onChange={(e) => this.props.onExpressionChange(this.props.id, e.target.value, 'o')}
                            input={<OutlinedInput labelWidth={80} />}
                            disabled={disabled}
                            data-testid="expresion_builder.operator.select"
                        >
                            {operators.map((operator) => (
                                <MenuItem key={operator} value={operator}>
                                    {this.operators[operator]}
                                </MenuItem>
                            ))}
                        </Select>
                        {this.props.errors.has(this.props.id + '-o') && (
                            <FormHelperText>{this.props.errors.get(this.props.id + '-o')}</FormHelperText>
                        )}
                    </FormControl>

                    {!isUnaryOperator && (
                        <React.Fragment>
                            <FormControl
                                variant="outlined"
                                error={this.props.errors.has(this.props.id + '-yField')}
                                style={{ flexShrink: 0 }}
                            >
                                <Autocomplete
                                    id={this.props.id}
                                    options={this.state.optionsY}
                                    getOptionLabel={this.getAutocompleteOptionLabel}
                                    renderOption={this.getRenderOption}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    groupBy={(options) => options && options.groupBy}
                                    value={this.initY(this.props.expression)}
                                    onChange={(e, value) => this.handleYOperand(value)}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            variant="outlined"
                                            data-testid="expresion_builder.operator.autocomplete_field"
                                        />
                                    )}
                                    style={{ width: '250px', marginRight: 8 }}
                                    disabled={disabled}
                                />
                                {this.props.errors.has(this.props.id + '-yField') && (
                                    <FormHelperText>{this.props.errors.get(this.props.id + '-yField')}</FormHelperText>
                                )}
                            </FormControl>
                            {item.yConst !== undefined && (
                                <ConstantInput
                                    multiple={isArrayOperator && isTwig !== true}
                                    isTwig={isTwig}
                                    callContext={this.props.callContext}
                                    defaultValue={ExpressionBuilder.getDefaultValue(this.getFirstOperandType(item))}
                                    type={this.getFirstOperandType(item)}
                                    field={field}
                                    value={item.yConst}
                                    error={this.props.errors.get(this.props.id + '-yConst')}
                                    disabled={disabled}
                                    onChange={(value) =>
                                        this.props.onExpressionChange(this.props.id, value, isTwig ? 'yTwig' : 'yConst')
                                    }
                                />
                            )}
                            {item.yExpr !== undefined && (
                                <ExpressionInput
                                    type={this.getFirstOperandType(item)}
                                    value={item.yExpr}
                                    error={this.props.errors.get(this.props.id + '-yExpr')}
                                    onChange={(value) => this.props.onExpressionChange(this.props.id, value, 'yExpr')}
                                    disabled={disabled}
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>

                <Tooltip title={this.props.t('expresion_builder.button.actions.title')}>
                    <div className="exp-operand-menu-button" style={{ alignSelf: 'center' }}>
                        <IconButton
                            name={'o' + this.props.id}
                            onClick={this.props.onActionsClick}
                            disabled={disabled}
                            data-testid="expresion_builder.button.actions.button"
                        >
                            <Icon>settings</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        );
    }
}

Expression.propTypes = {
    id: PropTypes.string.isRequired,
    expression: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
            field: PropTypes.object,
            groupBy: PropTypes.string,
        }),
    ).isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    onExpressionChange: PropTypes.func.isRequired,
    onActionsClick: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    groupFieldOptions: PropTypes.bool,
    addSpecialSection: PropTypes.bool,
    specialSectionOptions: PropTypes.object,
    enableFormulaMode: PropTypes.bool,
    enableUrlFilterParameters: PropTypes.bool,
};

Expression.defaultProps = {
    fields: [],
    groupFieldOptions: false,
    addSpecialSection: false,
    enableFormulaMode: false,
    enableUrlFilterParameters: false,
};

export default withTranslation('translations', { withRef: true })(Expression);
