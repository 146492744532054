import React from 'react';
import PropTypes from 'prop-types';
import { CustomPaging, DataTypeProvider, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid as GridTable, PagingPanel, Table, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../../TableLoadingState';
import { TableHeaderRow } from '../../Grid';
import { withStyles } from '@material-ui/core/styles';
import { CallContext } from '../../utils/CallContext';
import { DataTable, FilterCell, FilterIcon, HeaderCellContent } from '../../DataTable';
import i18n from '../../../locales/i18n';
import dsManagerFactory from '../../../service/DsManager';
import DateTimeFormatter from 'components/DataTable/Formatter/DateTimeFormatter';

const t = i18n.t.bind(i18n);

const tableCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const StyledFilterCell = withStyles({
    cell: {
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            paddingRight: 0,
        },
    },
    flexContainer: {
        flexFlow: 'row-reverse',
    },
})(FilterCell);

class AbstractList extends DataTable {
    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];
        this.defaultPageSize = 25;

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
            entityPicklist: null,
        };

        this.jsonFilterOperations = ['contains', 'notContains'];
    }

    componentDidMount() {
        this.getFields().then((columns) => {
            this.setupStructure(columns);
        });
        this.setupReloadOnTimeZoneChange();
    }

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = this.initColumnsExtensions();
        structure.noSortingColumns = this.initSortingColumnExtensions();
        structure.filteringColumnExtensions = this.initFilteringColumnExtensions();
        structure.columnTitles = structure.columns.filter((c) => c.title).map((c) => c.title);

        const jsonColumns = [];
        for (let field of fields) {
            if (field.type === 'json') {
                jsonColumns.push(field.columnName);
            }
        }
        structure.jsonColumns = jsonColumns;

        return structure;
    }

    requestData(ignorePage = false, parentTimer = null) {
        return this.props.onRequestData({
            filters: this.getFilters(),
            sorting: this.sorting,
            currentPage: ignorePage ? 0 : this.state.pagination.current,
            pageSize: this.state.pagination.size,
        });
    }

    initColumnsExtensions() {
        return [
            { columnName: 'name', width: '20%' },
            { columnName: 'callContext', width: '15%' },
            { columnName: 'rules', width: '15%' },
            { columnName: 'updatedBy', width: '10%' },
            { columnName: 'updatedAt', width: '10%' },
            { columnName: 'remove', width: 100, align: 'center' },
        ];
    }

    initSortingColumnExtensions() {
        return [{ columnName: 'rules', sortingEnabled: false }];
    }

    initFilteringColumnExtensions() {
        return [{ columnName: 'remove', filteringEnabled: false }];
    }

    initColumns() {
        throw new Error('Not implemented');
    }

    getFields() {
        return Promise.resolve(this.initColumns());
    }

    getEntityPicklist() {
        if (this.state.entityPicklist) {
            return this.state.entityPicklist;
        }

        const manager = dsManagerFactory.getManager(this.props.accountId);
        return manager.list().then((dataSources) => {
            const picklist = [];
            for (const dataSource of dataSources) {
                for (const entityCounter of dataSource.entityCounters) {
                    picklist.push({
                        value: entityCounter.entity.id,
                        label: dataSource.name + ' > ' + entityCounter.entity.label,
                    });
                }
            }
            this.setState({ entityPicklist: picklist });
            return picklist;
        });
    }

    render() {
        const structure = this.state.structure;

        if (structure === null) {
            return <div>{t('loading')}</div>;
        }

        return (
            <React.Fragment>
                <GridTable rows={this.props.rows} columns={structure.columns}>
                    <SortingState
                        onSortingChange={this.handleSortingChanged}
                        columnExtensions={structure.noSortingColumns}
                    />
                    <DataTypeProvider
                        for={structure.numericColumns}
                        availableFilterOperations={this.numericFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateColumns}
                        availableFilterOperations={this.dateFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateTimeColumns}
                        availableFilterOperations={this.dateFilterOperations}
                        formatterComponent={DateTimeFormatter}
                    />
                    <DataTypeProvider
                        for={structure.stringColumns}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.booleanColumns}
                        availableFilterOperations={this.booleanFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.jsonColumns}
                        availableFilterOperations={this.jsonFilterOperations}
                    />
                    <FilteringState
                        onFiltersChange={this.handleFiltersChanged}
                        columnExtensions={structure.filteringColumnExtensions}
                    />
                    <Table
                        rowComponent={this.InteractiveRow}
                        columnExtensions={structure.exts}
                        cellComponent={withStyles(tableCellStyles)(Table.Cell)}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={structure.columns.length} loading={this.props.loading} />
                        )}
                    />
                    <TableHeaderRow
                        contentComponent={HeaderCellContent}
                        columnTitles={structure.columnTitles}
                        cellStyles={tableCellStyles}
                        showSortingControls
                    />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        messages={this.filterMessages}
                        cellComponent={StyledFilterCell}
                    />

                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.props.total} />
                    <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
                </GridTable>
            </React.Fragment>
        );
    }
}

AbstractList.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            callContext: PropTypes.instanceOf(CallContext).isRequired,
        }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRequestData: PropTypes.func.isRequired,
    onActionEdit: PropTypes.func.isRequired,
    onActionRemove: PropTypes.func.isRequired,
};

export default AbstractList;
