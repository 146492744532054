import React from 'react';
import { AdapterId } from 'components/types';
import { Entity } from 'interfaces';
import HubspotDsEntitySettings from './HubspotDsEntitySettings';
import PipedriveDsEntitySettings from './PipedriveDsEntitySettings';

interface Props {
    onSave: (settings: Entity.AdvancedSettings) => void;
    settings: Entity.AdvancedSettings;
    adapterId: AdapterId;
}

class DsEntitySettings extends React.PureComponent<Props> {
    render() {
        const { adapterId, settings, onSave } = this.props;

        if (adapterId === AdapterId.HUBSPOT) {
            return <HubspotDsEntitySettings settings={settings} onSave={onSave} />;
        }

        if (adapterId === AdapterId.PIPEDRIVE) {
            return <PipedriveDsEntitySettings settings={settings} onSave={onSave} />;
        }

        return null;
    }
}

export default DsEntitySettings;
