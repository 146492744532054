import { makeAutoObservable } from 'mobx';
import { DataSourceEntityCounterMap } from 'components/types';
import RoutingSettingsObjectKeyService from './RoutingSettingsObjectKeyService';
import { Routing } from 'interfaces';

class RoutingSettingsObjectsFormManager {
    private _objectsMapping: Routing.Settings.MappingObjects = new Map();
    private _availableCounters: DataSourceEntityCounterMap = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    initSettings(availableCounters: DataSourceEntityCounterMap, objectsMapping: Routing.Settings.MappingObjects) {
        this._availableCounters = new Map(availableCounters);
        this.updateSettings(new Map(objectsMapping));
    }

    updateSettings(objectsMapping: Routing.Settings.MappingObjects) {
        this._objectsMapping = new Map(objectsMapping);
        for (let [key, objectMapping] of this._objectsMapping.entries()) {
            this._objectsMapping.set(key, {
                ...objectMapping,
                ...{
                    mapping: new Map(objectMapping.mapping),
                },
            });
        }
    }

    updateObjectMapping(objectMapping: Routing.Settings.UpdatedObjectMapping) {
        const { mapping, isActivated, currentObject } = objectMapping;
        const key = RoutingSettingsObjectKeyService.getObjectKey(currentObject);

        this._objectsMapping.set(key, {
            isActivated,
            currentObject,
            mapping: new Map(mapping),
        });
    }

    deleteObjectMapping(objectKey: string) {
        this._objectsMapping.delete(objectKey);
    }

    getObjectMapping(objectKey: string): Routing.Settings.FormMappingData {
        const objectMapping = this._objectsMapping.get(objectKey) ?? null;
        if (objectMapping === null) {
            throw new Error('Unknown object key.');
        }
        return objectMapping;
    }

    activateObject(objectKey: string, isActivated: boolean): void {
        const objectMapping = this._objectsMapping.get(objectKey) ?? null;
        if (objectMapping === null) {
            throw new Error('Unknown object key.');
        }
        objectMapping.isActivated = isActivated;
    }

    get availableCounters(): DataSourceEntityCounterMap {
        return this._availableCounters;
    }

    get notAddedCounters(): DataSourceEntityCounterMap {
        return new Map([...this._availableCounters].filter(([key]) => !this._objectsMapping.has(key)));
    }

    get objectsMapping(): Routing.Settings.MappingObjects {
        return this._objectsMapping;
    }
}

export default RoutingSettingsObjectsFormManager;
