import BackendService from '../../api/BackendService';
import { defaultAvatarize } from 'components/MainMenu/avatar/defaultAvatarize';
import { fileManager } from '../File/FileManager';
import { MapslyFile } from 'interfaces/file';
import { User } from '../../interfaces';
import dispatcher from '../dispatcher';
import events from '../../events';
import cloneDeep from 'lodash/cloneDeep';
import { userManager } from '../UserManager';
import { uploadManager } from '../File/UploadManager';

interface AvatarData {
    url: string;
    thumbnail: string;
    isDefault: boolean;
    id?: string;
}

class AvatarService extends BackendService {
    private currentAvatar: AvatarData = {
        url: defaultAvatarize(1),
        thumbnail: defaultAvatarize(1, true),
        isDefault: true,
    };

    private getDefaultAvatar = (userId: number): AvatarData => ({
        url: defaultAvatarize(userId),
        thumbnail: defaultAvatarize(userId, true),
        isDefault: true,
    });

    getCurrentAvatar(): AvatarData {
        return this.currentAvatar;
    }

    loadUserAvatar = (user?: User.User | null): Promise<void> => {
        if (!user || user.accountId === null) {
            return new Promise(() => {});
        }

        if (!user.avatar) {
            return new Promise(() => {
                this.currentAvatar = this.getDefaultAvatar(user.id);

                dispatcher.dispatch(events.EVENT_ACCOUNT_AVATAR_CHANGED);
            });
        }
        return fileManager
            .get(user.accountId, user.avatar.fileID)
            .then((res) => {
                this.currentAvatar = {
                    url: res.url || defaultAvatarize(user.id),
                    thumbnail: res.thumbnailUrl || defaultAvatarize(user.id, true),
                    isDefault: !!res.url && !!res.thumbnailUrl,
                };
            })
            .catch((error) => {
                console.error('loadUserAvatar error:', error);

                this.currentAvatar = this.getDefaultAvatar(user.id);
            })
            .finally(() => {
                dispatcher.dispatch(events.EVENT_ACCOUNT_AVATAR_CHANGED);
            });
    };

    getUserAvatar = async (user: User.User): Promise<AvatarData> => {
        if (!user.avatar || !user.accountId) {
            return new Promise((resolve) => {
                return resolve(this.getDefaultAvatar(user.id));
            });
        }

        return fileManager
            .get(user.accountId, user.avatar.fileID)
            .then((res) => {
                return {
                    url: res.url || defaultAvatarize(user.id),
                    thumbnail: res.thumbnailUrl || defaultAvatarize(user.id, true),
                    isDefault: !!res.url && !!res.thumbnailUrl,
                };
            })
            .catch((error) => {
                console.error('getUserAvatar error:', error);

                return this.getDefaultAvatar(user.id);
            });
    };

    uploadAvatar = (user: User.User, avatarFile: MapslyFile): Promise<void> => {
        if (!user?.accountId) {
            return new Promise(() => {});
        }

        return uploadManager.upload(user.accountId, avatarFile).then((file: MapslyFile) => {
            if (!file.id || !file.url) {
                return;
            }

            const updateUser = { ...cloneDeep(user), avatar: { fileID: file.id } };

            return userManager.updateUsers([updateUser]).then(() => {
                this.currentAvatar = {
                    url: file.url || defaultAvatarize(user.id),
                    thumbnail: file.thumbnailUrl || defaultAvatarize(user.id, true),
                    isDefault: !!file.url && !!file.thumbnailUrl,
                    id: file.id,
                };

                dispatcher.dispatch(events.EVENT_ACCOUNT_AVATAR_CHANGED);
            });
        });
    };

    removeAvatar = (user: User.User, id?: string): Promise<void> => {
        if (!user?.accountId || !id) {
            return new Promise(() => {});
        }

        return fileManager.delete(user.accountId, { id } as MapslyFile).then(() => {
            return userManager.updateUsers([user]).then(() => {
                this.currentAvatar = this.getDefaultAvatar(user.id);

                dispatcher.dispatch(events.EVENT_ACCOUNT_AVATAR_CHANGED);
            });
        });
    };
}

export const avatarService = new AvatarService();
