import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';

class CountryManager extends BackendService {
    constructor() {
        super();
        this.countries = null;
    }

    getCountries() {
        return this.countries;
    }

    getCountryName(alpha2) {
        if (this.countries === null) {
            return null;
        }
        for (let country of this.countries) {
            if (country.alpha2 === alpha2) {
                return country.name;
            }
        }
        return null;
    }

    list() {
        if (this.countries !== null) {
            return Promise.resolve(this.countries);
        }
        const url = reverse(apiRoutes.countries);
        return this.requestApi(url, 'GET').then((response) => {
            this.countries = response;

            dispatcher.dispatch(events.COUNTRIES_LIST_LOADED);

            return response;
        });
    }
}

export default new CountryManager();
