import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/UI';

import Alert from '../Alert';
import { DialogContext } from './DialogContext';
import Backdrop from '../Backdrop';

class FormActions extends React.PureComponent {
    render() {
        // remove this flag (isNewDesign) when the old design is no longer needed
        const { className, closeText, children, isNewDesign, t } = this.props;
        const closeButtonText = closeText || t(!!children ? 'button.cancel' : 'dialog.close');

        return (
            <DialogContext.Consumer>
                {({ onClose, styles }) => (
                    <div className={clsx(styles.actions, className)}>
                        {onClose &&
                            (isNewDesign ? (
                                <Button buttonType="text" onClick={onClose} data-testid="pure_form_dialog.button.close">
                                    {closeButtonText}
                                </Button>
                            ) : (
                                <MUIButton
                                    color="primary"
                                    onClick={onClose}
                                    data-testid="pure_form_dialog.button.close"
                                >
                                    {closeButtonText}
                                </MUIButton>
                            ))}
                        {children}
                    </div>
                )}
            </DialogContext.Consumer>
        );
    }
}

class FormAlert extends React.PureComponent {
    render() {
        const { type, onClose, children, style } = this.props;

        return (
            <DialogContext.Consumer>
                {({ styles }) => (
                    <div className={styles.alert} style={style}>
                        <Alert type={type} onClose={onClose} placement="context">
                            {children}
                        </Alert>
                    </div>
                )}
            </DialogContext.Consumer>
        );
    }
}

class FormBackdrop extends React.PureComponent {
    render() {
        const { children, ...rest } = this.props;
        return (
            <DialogContext.Consumer>
                {({ styles }) => (
                    <div className={styles.backdrop}>
                        <Backdrop {...rest}>{children}</Backdrop>
                    </div>
                )}
            </DialogContext.Consumer>
        );
    }
}

const TranslatedFormActions = withTranslation('translations', { withRef: true })(FormActions);

export { TranslatedFormActions as FormActions, FormBackdrop, FormAlert };
