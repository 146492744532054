import React from 'react';
import './style.css';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class NotePanel extends React.Component {
    render() {
        if (this.props.subscription.note === null) {
            return null;
        }

        return (
            <div className="c-note-panel">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                        <Grid item xs={4}>
                            <h2>{this.props.t('billing.note_panel.title')}</h2>
                        </Grid>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={this.props.subscription.note}
                                    disabled={true}
                                    InputProps={{
                                        style: { color: 'black' },
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

NotePanel.propTypes = {
    subscription: PropTypes.object.isRequired,
};

export default withTranslation()(NotePanel);
