/* eslint-disable jsx-a11y/anchor-is-valid */
/* global BigInt */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import '../../Map/style.css';
import WebLink, { ICON_POSITION_RIGHT } from '../../WebLink/WebLink';
import * as _escape from 'lodash/escape';
import { Tooltip } from '@material-ui/core';
import territoryManagerFactory from '../../../service/Territories/TerritoryManager';
import { isPicklistTypeNumber, safeString } from '../../../utils';
import DateTime from '../../DateTime';
import { FieldLookupType } from 'components/types';
import { FieldType } from '../../types';
import i18n from '../../../locales/i18n';
import FileCollection from '../../FileInput/FileCollection';

const t = i18n.t.bind(i18n);

export const renderTerritories = (value) => {
    if (!value) {
        return null;
    }

    const manager = territoryManagerFactory.getCurrentManager();
    if (!manager) {
        return null;
    }

    const names = [];
    for (let id of value) {
        id = parseInt(id);
        const name = manager.getTerritoryNameById(id);
        if (name) {
            names.push(name);
        }
    }

    if (names.length === 0) {
        return null;
    }
    return names;
};

class FieldValueViewFactory {
    create(accountId, property, update, tooltip = false, isStaticContent = false, escape = true) {
        let { value, field, lookupData } = property;
        if (update) {
            value = update.value;
            if (typeof update.textValue === 'string') {
                value = update.textValue;
            }
        }

        if (property.type === FieldType.INTEGER && !!value) {
            value = Number.parseInt(value).toLocaleString('en-US');
        }
        if (property.type === FieldType.BIGINT && !!value) {
            value = BigInt(value).toLocaleString('en-US');
        }
        if (property.type === FieldType.FLOAT && !!value) {
            value = Number.parseFloat(value).toLocaleString('en-US');
        }

        if (lookupData && lookupData.type === FieldLookupType.POLYMORPHIC) {
            return this.buildLookupPolymorphicField(property, value);
        }

        if (Array.isArray(value) && 0 === value.length) {
            return <i>{t('map.entity_layer_points.nothing_selected')}</i>;
        }
        if ('' === value || null === value) {
            return <i>{t('map.entity_layer_points.empty')}</i>;
        }
        if (field === 'mapsly_territories') {
            return renderTerritories(value);
        }
        if (property.type === 'text[]') {
            const picklist = property.picklist || [];
            return this.buildPicklistValue(value, picklist);
        }
        if (property.isLink) {
            const result = this.buildWebLink(value, tooltip);
            return isStaticContent ? ReactDOMServer.renderToStaticMarkup(result) : result;
        }
        if (property.picklist) {
            return this.findValueInPicklist(property.picklist, value);
        }
        if (property.type === 'boolean') {
            return value ? t('boolean.true') : t('boolean.false');
        }
        if (property.type === 'datetime') {
            const result = <DateTime>{value}</DateTime>;
            return isStaticContent ? ReactDOMServer.renderToStaticMarkup(result) : result;
        }
        if (property.type === FieldType.FILES) {
            return <FileCollection accountId={accountId} files={value || []} size="small" readonly />;
        }
        if (typeof value === 'string') {
            const safeValue = safeString(value);
            if (escape) {
                return safeValue;
            }
            return safeValue
                .replace(/&amp;/g, '&')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&quot;/g, '"')
                .replace(/&#39;/g, "'");
        }
        return _escape(value);
    }

    buildLookupPolymorphicField(property, value) {
        const { typeValue } = property;
        if (!value) {
            return <i>{t('map.entity_layer_points.empty')}</i>;
        }
        if (!typeValue) {
            return value;
        }

        const label = typeValue.slice(0, 2).toUpperCase();

        return (
            <React.Fragment>
                {value}&nbsp;
                <Tooltip title={typeValue} key={property.id}>
                    <span>({label})</span>
                </Tooltip>
            </React.Fragment>
        );
    }

    findValueInPicklist(picklist, value) {
        if (value === null || value === undefined) {
            return value;
        }

        const typePicklistIsNumber = isPicklistTypeNumber(picklist);
        const findValue = typePicklistIsNumber ? parseInt(value, 10) : value;
        const item = picklist.find((item) => item.value === findValue);

        return item === undefined ? value : item.label;
    }

    buildPicklistValue(value, picklist) {
        if (!value) {
            return null;
        }

        const typePicklistIsNumber = isPicklistTypeNumber(picklist);

        return value.map((v) => {
            v = typePicklistIsNumber ? parseInt(v, 10) : v;
            const item = picklist ? picklist.find((item) => item.value === v) : null;
            if (item) {
                return item.label;
            } else {
                return v;
            }
        });
    }

    buildWebLink(value, tooltip = false) {
        if (value == null) {
            return '';
        }

        return <WebLink webLink={value} isTypeSpan={tooltip} iconPosition={ICON_POSITION_RIGHT} />;
    }
}

export default new FieldValueViewFactory();
