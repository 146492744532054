import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../../service/workflow_actions/forms/fields/Label';

import AbstractField from './AbstractField';

class StyleField extends AbstractField {
    render() {
        const field = this.props.field;

        if (!field.getValue()) {
            return null;
        }

        const isScopeSupported = checkIsScopeSupported();

        return (
            <style>
                {isScopeSupported && '@scope (.form) {'}
                {field.getValue()}
                {isScopeSupported && '}'}
            </style>
        );
    }
}

StyleField.propTypes = {
    field: PropTypes.instanceOf(Label).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function checkIsScopeSupported() {
    // Create a temporary element
    const div = document.createElement('div');
    div.id = '_testscope';

    // Apply a CSS @scope rule
    div.innerHTML = '<style>@scope (#_testscope) {.test {display:none}}</style><div class="test">test</div>';

    // Append the element to the document
    document.body.appendChild(div);

    // Check if the browser supports the @scope rule
    const isSupported = !div.innerText;

    // Remove the temporary element
    document.body.removeChild(div);

    return isSupported;
}

export default StyleField;
