import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { addressParts, minimumRequiredAddressParts } from '../../references/addressParts';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { capitalizeFirstLetter, isLookupFieldId } from '../../utils';
import './style.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withTranslation } from 'react-i18next';
import { ORDERED_LEVELS } from '../utils/CompositeAddress';
import DisabledOptionWithHintAutocomplete from '../DisabledOptionWithHintAutocomplete';

class AddressForm extends React.Component {
    constructor(props) {
        super(props);

        const field = { ...props.addressField };
        if (field.address) {
            field.address = { ...AddressForm.getDefaultAddress(), ...field.address };
        } else {
            field.address = AddressForm.getDefaultAddress();
        }

        this.state = {
            field: field,
            errors: new Map(),
        };
    }

    static getDefaultAddress() {
        const address = {};
        for (let sectionName in addressParts) {
            if (addressParts.hasOwnProperty(sectionName)) {
                address[sectionName] = null;
            }
        }
        return address;
    }

    submit() {
        const { onSubmitSuccess, onSubmitError, entity } = this.props;
        const { field } = this.state;

        this.setState({
            errors: new Map(),
        });

        const errors = new Map();
        if (!field.label) {
            errors.set('label', this.props.t('errors.not_empty'));
        }

        const availableFields = new Set();
        for (let f of this.filterFieldsToUseInAddress(entity.fields)) {
            availableFields.add(f.apiName);
        }

        for (let sectionName in field.address) {
            if (
                field.address.hasOwnProperty(sectionName) &&
                field.address[sectionName] &&
                !availableFields.has(field.address[sectionName])
            ) {
                errors.set(sectionName, this.props.t('errors.field_missed'));
            }
        }

        // if (field.apiName === ''){
        //     errors.set('apiName', 'This value should not be empty.');
        // } else {
        //     for (let f of entity.fields){
        //         if (f.id !== field.id && f.apiName === field.apiName){
        //             errors.set('apiName', 'This value should be unique per entity.');
        //             break;
        //         }
        //     }
        // }

        if (errors.size > 0) {
            onSubmitError && onSubmitError(this.props.t('validation_errors'));
            this.setState({
                errors: errors,
            });
            return;
        }

        const usedFields = [];
        for (let sectionName in addressParts) {
            if (!addressParts.hasOwnProperty(sectionName)) {
                continue;
            }
            if (field.address[sectionName] !== null) {
                let f = field.address[sectionName];
                if (usedFields.includes(f)) {
                    onSubmitError && onSubmitError(this.props.t('address_form.errors.field_used_too_much'));
                    return;
                }
                usedFields.push(f);
            }
            if (minimumRequiredAddressParts.includes(sectionName) && !field.address[sectionName]) {
                onSubmitError &&
                    onSubmitError(
                        this.props.t('address_form.select_min_fields', { name: capitalizeFirstLetter(sectionName) }),
                    );
                return;
            }
        }

        onSubmitSuccess && onSubmitSuccess(this.state.field);
    }

    quiet() {
        this.setState({
            errors: new Map(),
        });
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            state.field[name] = value || null;
            state.errors = new Map();
            return state;
        });
    };

    handleSectionChange = (event) => {
        const name = event.target.name;
        const value = event.target.value || null;
        this.setState((state) => {
            state.field.address[name] = value;
            state.errors = new Map();
            return state;
        });
    };

    filterFieldsToUseInAddress = (fields) => {
        const result = [];
        for (let field of fields) {
            if (
                field.type !== 'text' &&
                field.type !== 'string' &&
                field.type !== 'integer' &&
                field.type !== 'float'
            ) {
                continue;
            }
            if (field.isLink) {
                continue;
            }
            if (field.address !== null) {
                continue;
            }

            if (field.lookupData !== null) {
                if (!isLookupFieldId(field)) {
                    continue;
                }
            }

            result.push(field);
        }
        return result;
    };

    getUsageInAddressComposition = (apiName, addressComposition) => {
        const result = new Set();
        for (let sectionName in addressComposition) {
            if (addressComposition.hasOwnProperty(sectionName) && addressComposition[sectionName] === apiName) {
                result.add(sectionName);
            }
        }
        return result;
    };

    filterFieldsBySectionName(availableFields, currentField, sectionName) {
        return availableFields.filter((entityField) => {
            const sectionsUsingTheField = this.getUsageInAddressComposition(entityField.apiName, currentField.address);
            sectionsUsingTheField.delete(sectionName);
            if (sectionsUsingTheField.size > 0) {
                return false;
            }
            if (entityField.isSystemVisible) {
                return false;
            }
            const isZipCode = sectionName === 'zip';
            const isInteger = entityField.type === 'integer';
            const isFloat = entityField.type === 'float';
            const isLookup = entityField.lookupData !== null;
            return isZipCode || isLookup || (!isInteger && !isFloat);
        });
    }

    optionIsExist = (option) => {
        if (!option) {
            return false;
        }
        const { entity } = this.props;
        const field = entity.fields.find((field) => field.apiName === option.apiName);
        return field !== undefined;
    };

    optionIsTerritory = (option) => {
        if (!option) {
            return false;
        }
        const { entity } = this.props;
        if (entity.territoriesFieldId === null) {
            return false;
        }
        if (option.id === entity.territoriesFieldId) {
            return true;
        }
        if (option.address) {
            const territoriesFieldApiName = entity.fields.find(
                (field) => field.id === entity.territoriesFieldId,
            )?.apiName;
            return ORDERED_LEVELS.some((level) => territoriesFieldApiName === option.address[level]);
        }
        return false;
    };

    getOptionDisabled = (option) => {
        if (option === null) {
            return false;
        }
        return !this.optionIsExist(option) || this.optionIsTerritory(option);
    };

    getDisabledOptionHint = (option) => {
        const { t } = this.props;
        if (!this.optionIsExist(option)) {
            return t('errors.field_missed') + '(' + option.apiName + ')';
        }
        if (this.optionIsTerritory(option)) {
            return t('geomapper.record.list_fields.disabled');
        }
        return '';
    };

    render() {
        const { field, errors } = this.state;
        const { entity, t } = this.props;

        const fields = this.filterFieldsToUseInAddress(entity.fields);

        return (
            <form noValidate autoComplete="off" className="c-address-form">
                <TextField
                    name="label"
                    label={t('field_label')}
                    data-testid="address_from.text_input"
                    fullWidth
                    margin="dense"
                    value={field.label || ''}
                    helperText={errors.get('label') || ''}
                    error={errors.has('label')}
                    InputProps={{ disableUnderline: false }}
                    onChange={this.handleInputChange}
                />

                <div style={{ marginTop: '16px' }}>{t('address_form.select_fields')}:</div>

                {Object.keys(addressParts).map((sectionName) => {
                    const availableFields = this.filterFieldsBySectionName(fields, field, sectionName);
                    const currentFieldApiName = field.address[sectionName];
                    const isCurrentFieldAvailable =
                        fields.find((field) => field.apiName === currentFieldApiName) !== undefined;
                    const hasError = currentFieldApiName && !isCurrentFieldAvailable;
                    const currentField = availableFields.find((field) => currentFieldApiName === field.apiName);

                    return (
                        <FormControl key={sectionName} fullWidth margin="dense" error={errors.has(sectionName)}>
                            <InputLabel shrink>{t(sectionName)}</InputLabel>
                            <DisabledOptionWithHintAutocomplete
                                options={availableFields}
                                value={currentField || null}
                                getOptionLabel={(field) => {
                                    let label = field.label;
                                    if (field.lookupData !== null) {
                                        label = label.replace(/\s\((ID)\)$/, '');
                                    }
                                    const apiName = field.originalApiName ?? field.apiName;
                                    return `${label} (${apiName})`;
                                }}
                                onChange={(event, value) => {
                                    this.handleSectionChange({ target: { name: sectionName, value: value?.apiName } });
                                }}
                                getOptionDisabled={this.getOptionDisabled}
                                disabledOptionHint={(option) => {
                                    return hasError
                                        ? t('errors.field_missed') + '(' + currentFieldApiName + ')'
                                        : this.getDisabledOptionHint(option);
                                }}
                                data-testid="address_from.address_field"
                                placeholder={t('undefined_picklist_option')}
                                inputMargin="normal"
                            />
                            {sectionName === 'country' && (
                                <Tooltip title={t('address_form.country_field_optional')}>
                                    <i className="fas fa-question-circle" />
                                </Tooltip>
                            )}
                            {errors.has(sectionName) && <FormHelperText>{errors.get(sectionName)}</FormHelperText>}
                        </FormControl>
                    );
                })}
            </form>
        );
    }
}

AddressForm.propTypes = {
    addressField: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(AddressForm);
