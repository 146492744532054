export enum FileType {
    Image = 'image',
    Video = 'video',
    Audio = 'audio',
    Document = 'document',
}

export enum ConversionStatus {
    New = 'new',
    InProgress = 'in_progress',
    Completed = 'completed',
    Error = 'error',
}

export enum TranscriptionStatus {
    New = 'new',
    InProgress = 'in_progress',
    Completed = 'completed',
    Disabled = 'disabled',
    Error = 'error',
}

export enum SummarizationStatus {
    New = 'new',
    InProgress = 'in_progress',
    Completed = 'completed',
    Disabled = 'disabled',
    Error = 'error',
}

export enum UploadMethod {
    Camera = 'camera',
    Draw = 'draw',
}

export type FileMeta = {
    width?: number | null;
    height?: number | null;
    isTranscriptEnabled?: boolean;
    isSummaryEnabled?: boolean;
    language?: string | null;
    prompt?: string | null;
};
export type ProgressCallback = (progress?: ProgressEvent) => void;

export interface MapslyFile {
    id?: string;
    name: string;
    type: FileType;
    size: number;
    contentType: string;
    url?: string;
    thumbnailUrl?: string;
    thumbnailSize?: number;
    width?: number | null;
    height?: number | null;
    duration?: number | null;
    description?: string | null;
    language?: string | null;
    conversionStatus?: ConversionStatus | null;
    transcriptionStatus?: TranscriptionStatus | null;
    summarizationStatus?: SummarizationStatus | null;
    error?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;

    thumbnailUploadUrl?: string;
    blob?: Blob;
    thumbnailBlob?: Blob;
    isTranscriptEnabled?: boolean;
    isSummaryEnabled?: boolean;
    prompt?: string | null;
}

export interface Summary {
    id: number;
    content: string;
}
