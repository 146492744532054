import { Grid, Tooltip, Typography } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import Calculator from '../../service/ImportMessages/Calculator';
import { ImportMessage, ORIGIN } from '../../service/ImportMessages/types';
import './style.css';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import { Account } from 'interfaces';
import MessageText from './MessageText';

interface Props extends WithTranslation, WithStyles {
    accountId: number;
}

interface State {
    subscription: null | Account.Subscription;
}

class AccountMessages extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            subscription: null,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(
            events.EVENT_SUBSCRIPTION_CHANGED,
            this,
            (data: { subscription: Account.Subscription }) => {
                const subscription = data.subscription;
                if (this.props.accountId === subscription.accountId) {
                    this.setState({ subscription });
                }
            },
        );

        subscriptionManagerFactory
            .getManager(this.props.accountId)
            .getSubscription()
            .then((subscription: Account.Subscription) => {
                this.setState({ subscription });
            });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { subscription } = this.state;
        const sortedInfoMessages = Calculator.calculateAccountStatusMessages(subscription);
        return (
            <>
                {sortedInfoMessages.messages.map((message: ImportMessage) => {
                    return this.renderMessages(message);
                })}
            </>
        );
    }

    renderMessages = (message: ImportMessage) => {
        const messageText =
            message.origin === ORIGIN.INTERNAL ? message.message : '[' + message.httpCode + '] ' + message.message;
        return (
            <Alert severity={message.level} className="c-import-messages-account" classes={this.props.classes}>
                <Grid container direction="row" wrap="nowrap">
                    <Grid item xs zeroMinWidth>
                        <Tooltip title={messageText}>
                            <Typography noWrap>
                                <MessageText text={messageText} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Alert>
        );
    };
}

const styles = {
    message: {
        width: 'calc(100% - 30px)',
    },
};

export default withTranslation('translations', { withRef: true })(withStyles(styles)(AccountMessages));
