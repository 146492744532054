import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { isPast } from 'date-fns';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Calendar } from '../../../interfaces/calendar/calendar';
import { CalendarEventManager } from '../../../service/Calendar/CalendarEventManager';
import { CalendarEvent, CalendarEventSyncStatus } from '../../../service/Calendar/CalendarEventRepository';
import dsManager from '../../../service/DsManager';
import metadataManager from '../../../service/MetadataManager';
import { userManager } from '../../../service/UserManager';
import Backdrop from '../../Backdrop';
import Confirmation from '../../Confirmation';
import CalendarForm from '../Forms/CalendarForm';
import { getBackgroundColor, getTextColor } from '../Helpers/CalendarHelper';

import './style.css';
import PureFormDialog from '../../PureFormDialog';

interface Props extends WithTranslation {
    onClose: () => void;
    onDelete: () => void;
    onSave: () => void;
    calendarEvent: CalendarEvent | null;
    calendar: Calendar | null;
    updateCalendarEvent: (calendarEvent: CalendarEvent, validateFieldAfter?: string | null) => void;
    errors: Map<string, string>;
    validateOne: (fieldName: string) => void;
    clearError: (fieldName: string) => void;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    usePopover?: boolean;
    anchorEl?: Element;
}

interface State {
    dataSourceName?: string;
    isModified: boolean;
}
const MOBILE_SIZE = 500;

class CalendarSaveDialog extends React.Component<Props, State> {
    private calendarEventManager = new CalendarEventManager();

    constructor(props: Props) {
        super(props);

        this.state = {
            dataSourceName: undefined,
            isModified: false,
        };
    }

    async componentDidMount() {
        await this.updateDataSourceName();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.calendar !== this.props.calendar) {
            await this.updateDataSourceName();
        }
    }

    componentWillUnmount() {
        this.calendarEventManager.dispose();
    }

    private updateDataSourceName = async () => {
        const calendar = this.props.calendar;
        if (!calendar) {
            return;
        }

        const entity = await metadataManager.requestEntity(calendar.entityId);
        if (!entity) {
            return;
        }
        const dataSource = await dsManager.getManager(userManager.getCurrentAccount().id).load(entity.dsId);
        this.setState({ dataSourceName: dataSource?.name });
    };

    private onReSync = () => {
        const calendarEvent = this.props.calendarEvent;
        if (!calendarEvent) {
            return;
        }

        this.calendarEventManager.requestSync([calendarEvent.id]);
        this.props.onClose();
    };

    private isSaveButtonDisabled = () => {
        return (
            (this.props.errors.has('validationRule') && this.props.errors.size > 1) ||
            (!this.props.errors.has('validationRule') && this.props.errors.size > 0) ||
            this.isNotModifiedSinceSyncError()
        );
    };

    private isNotModifiedSinceSyncError = () => {
        const calendarEvent = this.props.calendarEvent;
        return calendarEvent !== null && this.hasSyncError(calendarEvent) && !this.state.isModified;
    };

    private getSaveConfirmationText = (): string | void => {
        const calendarEvent = this.props.calendarEvent;
        if (!calendarEvent || this.isSaveButtonDisabled()) {
            return;
        }

        if (!isPast(new Date(calendarEvent.startDatetime))) {
            return;
        }

        return this.props.t('calendar.start_time_is_past').toString();
    };

    private onModified = (): void => {
        if (!this.state.isModified) {
            this.setState({ isModified: true });
        }
    };

    private handleUpdateCalendarEvent = (calendarEvent: CalendarEvent, validateFieldAfter?: string | null): void => {
        this.onModified();
        this.props.updateCalendarEvent(calendarEvent, validateFieldAfter);
    };

    private hasSyncError(calendarEvent: CalendarEvent): boolean {
        return calendarEvent.syncStatus === CalendarEventSyncStatus.ERROR && !!calendarEvent.syncError;
    }

    render() {
        const {
            t,
            anchorEl,
            onClose,
            onDelete,
            onSave,
            calendarEvent,
            calendar,
            validateOne,
            clearError,
            errors,
            setLoading,
            loading,
        } = this.props;
        const { dataSourceName, isModified } = this.state;

        if (!calendarEvent) {
            return null;
        }
        const hasSyncError = this.hasSyncError(calendarEvent);

        const saveButtonTooltip =
            hasSyncError && !isModified
                ? t('calendar.form.save.disabled_sync_tooltip', {
                      dataSourceName: dataSourceName ? dataSourceName : t('calendar.context_menu.data_source'),
                  })
                : '';

        const fullScreen = MOBILE_SIZE >= document.body.clientWidth;

        const form = (
            <Backdrop loading={loading}>
                <CalendarForm
                    calendarEvent={calendarEvent}
                    updateCalendarEvent={this.handleUpdateCalendarEvent}
                    errors={errors}
                    validateOne={validateOne}
                    clearError={clearError}
                    setLoading={setLoading}
                    dataSourceName={dataSourceName}
                    onReSync={this.onReSync}
                    isModified={isModified}
                    hasSyncError={hasSyncError}
                />
            </Backdrop>
        );

        return (
            <PureFormDialog
                title={
                    <div className="calendar-form-title-wrap">
                        <Typography variant="h6">
                            {t(`calendar.${calendarEvent.new ? 'create' : 'edit'}_event`)}
                        </Typography>
                        <Tooltip title={t('calendar.this_event_is_from', { calendarName: calendar?.name ?? '' })}>
                            <Typography
                                variant="caption"
                                className="calendar-form-title-badge"
                                style={{
                                    backgroundColor: getBackgroundColor(calendar ?? undefined),
                                    color: getTextColor(calendar ?? undefined),
                                }}
                            >
                                {calendar?.name ?? ''}
                            </Typography>
                        </Tooltip>
                    </div>
                }
                open={!!anchorEl}
                onClose={this.props.onClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
                noDividers={true}
                fullScreen={fullScreen}
                actions={
                    <Box display="flex" justifyContent={!calendarEvent.new ? 'space-between' : 'flex-end'}>
                        {!calendarEvent.new && (
                            <Confirmation
                                text={t('calendar.context_menu.remove_confirm_popup.delete_one')}
                                onConfirm={onDelete}
                            >
                                <Button color="secondary">{t('delete')}</Button>
                            </Confirmation>
                        )}
                        <Box>
                            <Button color="primary" onClick={onClose} style={{ marginRight: 10 }}>
                                {t('button.cancel')}
                            </Button>
                            <Tooltip title={saveButtonTooltip}>
                                <span>
                                    <Confirmation text={this.getSaveConfirmationText} onConfirm={onSave}>
                                        <Button color="primary" disabled={this.isSaveButtonDisabled()}>
                                            {t('button.save')}
                                        </Button>
                                    </Confirmation>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                }
            >
                {form}
            </PureFormDialog>
        );
    }
}

export default withTranslation()(CalendarSaveDialog);
