import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { weAreInIosNativeApp, weAreInNativeApp } from '../../utils';
import { File } from '@ionic-native/file';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Media, MediaSaveOptions } from '@capacitor-community/media';
import { FileOpener } from '@ionic-native/file-opener';
import { saveAs } from 'file-saver';
import { MapslyFile, FileType, Transcript, FileMeta, ProgressCallback, Summary } from 'interfaces/file';

export const MAX_FILE_SIZE = 20000000;
export const MEDIA_ALBUM = 'Mapsly';

export const getFileExtension = (fileName: string) => {
    return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const getFileNameWithNoExtension = (fileName: string) => {
    return fileName.slice(0, ((fileName.lastIndexOf('.') - 1) >>> 0) + 1);
};

export const isHeic = (file: MapslyFile) => {
    return ['heic', 'heif'].includes(getFileExtension(file.name)) || ['image/heic', 'image/heif'].includes(file.type);
};

export const getFileType = (mimeType: string): FileType => {
    const type = mimeType.split('/').shift();
    switch (type) {
        case FileType.Image:
        case FileType.Video:
        case FileType.Audio:
            return type;
        default:
            return FileType.Document;
    }
};

class FileManager extends BackendService {
    files: { [id: string]: MapslyFile } = {};
    fileTransfer: FileTransfer = new FileTransfer();
    transferObject: FileTransferObject = this.fileTransfer.create();
    downloadController: AbortController | null = null;

    createFile(file: Blob, filename: string, meta?: FileMeta): MapslyFile {
        return {
            name: filename,
            type: getFileType(file.type),
            size: file.size,
            contentType: file.type,
            width: meta?.width,
            height: meta?.height,
            isTranscriptEnabled: meta?.isTranscriptEnabled || false,
            isSummaryEnabled: meta?.isSummaryEnabled || false,
            language: meta?.language,
            prompt: meta?.prompt,
            blob: file,
        };
    }

    get(accountId: number, fileId: string): Promise<MapslyFile> {
        const url = reverse(apiRoutes.account.file.item, { accountId, fileId });
        return this.requestApi(url, 'GET');
    }

    getTranscript(
        accountId: number,
        fileId: string,
        limit?: number,
        offset?: number,
    ): Promise<Transcript.TranscriptItem[]> {
        const url = reverse(apiRoutes.account.file.transcript.index, { accountId, fileId });
        const data: any = {};
        if (undefined !== limit) {
            data.limit = limit;
        }
        if (undefined !== offset) {
            data.offset = offset;
        }
        return this.requestApi(url, 'GET', data);
    }

    getTranscriptSpeakers(accountId: number, fileId: string): Promise<Transcript.TranscriptSpeaker[]> {
        const url = reverse(apiRoutes.account.file.transcript.speakers.index, { accountId, fileId });
        return this.requestApi(url, 'GET');
    }

    createTranscriptItem(
        accountId: number,
        fileId: string,
        item: Transcript.TranscriptItem,
    ): Promise<Transcript.TranscriptItem> {
        const url = reverse(apiRoutes.account.file.transcript.index, { accountId, fileId });
        return this.requestApi(url, 'POST', item);
    }

    updateTranscriptItem(
        accountId: number,
        fileId: string,
        item: Transcript.TranscriptItem,
    ): Promise<Transcript.TranscriptItem> {
        const url = reverse(apiRoutes.account.file.transcript.item, { accountId, fileId, itemId: item.id });
        return this.requestApi(url, 'POST', item);
    }

    deleteTranscriptItem(accountId: number, fileId: string, item: Transcript.TranscriptItem): Promise<void> {
        const url = reverse(apiRoutes.account.file.transcript.item, { accountId, fileId, itemId: item.id });
        return this.requestApi(url, 'DELETE');
    }

    getSummary(accountId: number, fileId: string): Promise<Summary> {
        const url = reverse(apiRoutes.account.file.summary, { accountId, fileId });
        return this.requestApi(url, 'GET');
    }

    updateSummary(accountId: number, fileId: string, summary: Summary): Promise<Summary> {
        const url = reverse(apiRoutes.account.file.summary, { accountId, fileId });
        return this.requestApi(url, 'PATCH', { content: summary.content });
    }

    transformFileIdsToFiles(accountId: number, files: string[] | MapslyFile[]): Promise<MapslyFile[]> {
        const promises = [];
        for (const file of files) {
            if (typeof file === 'string') {
                const promise = this.files[file]
                    ? Promise.resolve(this.files[file])
                    : this.get(accountId, file).then((result) => {
                          this.files[file] = result;
                          return result;
                      });
                promises.push(promise);
            } else {
                if (file.id && !this.files[file.id]) {
                    this.files[file.id] = file;
                }
                promises.push(Promise.resolve(file));
            }
        }
        return Promise.all(promises);
    }

    transformFilesToFileIds(files: MapslyFile[] | string[]): Promise<string[]> {
        const fileIds = [];
        for (const file of files) {
            if (typeof file === 'object' && file.id) {
                fileIds.push(file.id);
            } else if (typeof file === 'string') {
                fileIds.push(file);
            }
        }
        return Promise.resolve(fileIds);
    }

    download(accountId: number, file: MapslyFile, progressCallback?: ProgressCallback): Promise<unknown> {
        if (weAreInNativeApp()) {
            return this.downloadApp(file, progressCallback);
        }
        return this.downloadWeb(accountId, file, progressCallback);
    }

    private downloadWeb(accountId: number, file: MapslyFile, progressCallback?: ProgressCallback): Promise<unknown> {
        if (!file.url) {
            return Promise.reject();
        }
        this.downloadController = new AbortController();
        return fetch(file.url, { method: 'GET', cache: 'no-cache', signal: this.downloadController.signal })
            .then((response) => {
                if (!response.ok && response.status === 403 && file.id) {
                    return this.get(accountId, file.id).then((response) => {
                        return fetch(response.url as string, {
                            method: 'GET',
                            cache: 'no-cache',
                            signal: this.downloadController?.signal,
                        });
                    });
                }
                return response;
            })
            .then(async (response) => {
                const contentLength = response.headers.get('Content-Length');
                const total = contentLength ? +contentLength : null;
                if (!total || !progressCallback) {
                    return response.blob();
                }

                const reader = response.body?.getReader();
                if (!reader) {
                    return Promise.reject(new Error('Can not download the file, reader is undefined'));
                }

                let loaded = 0;
                const data = [];
                while (true) {
                    const { done, value } = await reader.read();
                    if (done || !value) {
                        break;
                    }
                    data.push(value);
                    loaded += value.length;
                    progressCallback(new ProgressEvent('', { total, loaded }));
                }
                return new Blob(data);
            })
            .then((blob) => {
                this.downloadController = null;
                saveAs(blob, file.name);
            })
            .catch((error: any) => {
                if (error?.name !== 'AbortError') {
                    throw error;
                }
            });
    }

    private downloadApp(file: MapslyFile, progressCallback?: ProgressCallback): Promise<unknown> {
        if (!file.url) {
            return Promise.reject();
        }
        const path = weAreInIosNativeApp() ? File.documentsDirectory : File.externalDataDirectory;
        if (progressCallback) {
            this.transferObject.onProgress(progressCallback);
        }

        return this.transferObject
            .download(file.url, path + file.name)
            .then((entry) => {
                return this.doDownload(file, entry.toURL());
            })
            .catch((error: any) => {
                const aborted =
                    error.constructor.name === 'FileTransferError' &&
                    error.code === this.fileTransfer.FileTransferErrorCode.ABORT_ERR;
                if (!aborted) {
                    throw error;
                }
            });
    }

    private getMediaOptions(file: MapslyFile, path: string): Promise<MediaSaveOptions> {
        if (file.type !== FileType.Image && file.type !== FileType.Video) {
            return Promise.resolve({ path });
        }
        if (weAreInIosNativeApp()) {
            return Media.getAlbums()
                .then(({ albums }) => {
                    const mediaAlbum = albums.find((album) => album.name === MEDIA_ALBUM);
                    if (mediaAlbum) {
                        return mediaAlbum.identifier;
                    }
                    return Media.createAlbum({ name: MEDIA_ALBUM })
                        .then(() => {
                            return Media.getAlbums();
                        })
                        .then(({ albums }) => {
                            return albums.find((album) => album.name === MEDIA_ALBUM)?.identifier;
                        });
                })
                .then((album) => ({ path, album }));
        } else {
            return Promise.resolve({ path, album: MEDIA_ALBUM });
        }
    }

    private doDownload(file: MapslyFile, path: string) {
        const isMedia = [FileType.Image, FileType.Video].includes(file.type);
        const promise = this.getMediaOptions(file, path).then((options) => {
            switch (file.type) {
                case FileType.Image:
                    return Media.savePhoto(options).then(() => options.path);
                case FileType.Video:
                    return Media.saveVideo(options).then(() => options.path);
                default:
                    return options.path;
            }
        });
        if (weAreInIosNativeApp()) {
            return promise
                .then((fileUrl) => {
                    if (isMedia) {
                        return Promise.resolve();
                    }
                    return FileOpener.showOpenWithDialog(fileUrl, file.contentType);
                })
                .catch((error: Error | string) => {
                    if (isMedia) {
                        return FileOpener.showOpenWithDialog(path, file.contentType);
                    }
                    throw error;
                });
        } else {
            return promise.then((fileUrl) => {
                return FileOpener.showOpenWithDialog(fileUrl, file.contentType);
            });
        }
    }

    downloadAbort() {
        if (weAreInNativeApp()) {
            this.transferObject.abort();
        } else {
            this.downloadController && this.downloadController.abort();
        }
    }

    update(accountId: number, mapslyFile: MapslyFile): Promise<MapslyFile> {
        const url = reverse(apiRoutes.account.file.item, { accountId, fileId: mapslyFile.id });
        return this.requestApi(url, 'PATCH', mapslyFile);
    }

    delete(accountId: number, mapslyFile: MapslyFile) {
        if (mapslyFile.id) {
            const url = reverse(apiRoutes.account.file.item, { accountId, fileId: mapslyFile.id });
            return this.requestApi(url, 'DELETE');
        }
        return Promise.resolve();
    }

    retryTranscription(accountId: number, fileId: string) {
        const url = reverse(apiRoutes.account.file.retryTranscription, { accountId, fileId: fileId });

        return this.requestApi(url, 'POST');
    }

    retrySummarization(accountId: number, fileId: string) {
        const url = reverse(apiRoutes.account.file.retrySummarization, { accountId, fileId: fileId });

        return this.requestApi(url, 'POST');
    }
}

export const fileManager = new FileManager();
