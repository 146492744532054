import React from 'react';
import { Tooltip, IconButton, Icon } from '@material-ui/core';
import DottedLink from '../../DottedLink';
import { DETAIL_FIELDS, getDetail } from '../../../service/WorkflowActionManager';
import Confirmation from '../../Confirmation';
import AbstractList from '../AbstractList/AbstractList';
import i18n from '../../../locales/i18n';
import { userManager } from '../../../service/UserManager';

const t = i18n.t.bind(i18n);

class List extends AbstractList {
    initColumns() {
        return [
            {
                columnName: 'name',
                type: 'string',
                title: t('workflow_actions.list.field_update.name'),
                getCellValue: (action) => (
                    <React.Fragment>
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, action)}
                            data-testid="workflow_actions.list.field_update.name"
                        >
                            {action.name}
                        </DottedLink>
                    </React.Fragment>
                ),
            },
            {
                columnName: 'callContext',
                type: 'text[]',
                title: t('workflow_actions.list.field_update.entity'),
                picklist: this.getEntityPicklist(),
                getCellValue: (action) => {
                    let entityLabel;
                    for (const dataSource of this.props.dataSources) {
                        for (const entity of dataSource.entities) {
                            if (entity.id === action.callContext.getBaseEntityId()) {
                                entityLabel = dataSource.name + ' > ' + entity.label;
                                break;
                            }
                        }
                    }
                    return <span>{entityLabel}</span>;
                },
            },
            {
                columnName: 'formula',
                type: 'json',
                title: t('workflow_actions.list.field_update.formula'),
                getCellValue: (action) => {
                    const fields = getDetail(DETAIL_FIELDS, action.details, []);
                    return (
                        <ul>
                            {fields.map((field, index) => (
                                <li key={index}>
                                    <span style={{ textDecoration: 'underline' }}>{field.field}</span>
                                    <span>: {field.formula}</span>
                                </li>
                            ))}
                        </ul>
                    );
                },
            },
            {
                columnName: 'rules',
                type: 'string',
                title: t('workflow_actions.list.field_update.rules'),
                getCellValue: (action) => <span>{action.rules.map((r) => r.name).join(', ')}</span>,
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: t('workflow_actions.list.field_update.updated_by'),
                getCellValue: (action) => <span>{action.updatedBy ? action.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: t('workflow_actions.list.field_update.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (action) => (
                    <Confirmation
                        text={t('workflow_actions.confirm.remove')}
                        onConfirm={this.props.onActionRemove.bind(this, action)}
                    >
                        <Tooltip title={t('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="workflow_actions.field_update.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ];
    }

    initSortingColumnExtensions() {
        return [
            { columnName: 'callContext', sortingEnabled: false },
            { columnName: 'formula', sortingEnabled: false },
            { columnName: 'rules', sortingEnabled: false },
        ];
    }
}

export { List as FieldUpdateList };
