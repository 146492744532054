import { Feature, FeatureProps } from '../../interfaces/signUpFeatures';
import { toSnakeCase } from '../utils/Strings';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { defaultFeaturesManager } from '../../service/DefaultFeaturesManager';

export class InterestingFeaturesService {
    public readonly nameSeparator = '.';
    private defaultStructure: Array<Feature> = [];
    private featureProps: Array<FeatureProps> = [];
    public readonly featuresStorageKey: string = 'selected_features';
    private readonly signUpPrefix: string = 'signup.features.';
    private readonly hintPostfix: string = '.hint';

    constructor() {
        this.requestDefaultStructure();
    }

    updateFeatureProps(name: string, value: boolean): Array<FeatureProps> {
        let [parentName, childName] = name.split(this.nameSeparator);
        let rootFeature = this.findFeatureProps(this.featureProps, parentName);
        if (!rootFeature) {
            return this.featureProps;
        }
        if (childName) {
            this.setDependencyValue(rootFeature, value, childName);
        }

        rootFeature.selected = value;
        if (!value) {
            this.resetDependenciesValues(rootFeature);
        }
        return this.featureProps;
    }

    getSelectedFeatures(): Array<Feature> {
        let featureMap = [] as Array<Feature>;
        this.featureProps.forEach((value: FeatureProps) => {
            if (!value.selected) {
                return;
            }
            let dependencies = [] as Array<Feature>;
            value.dependencies &&
                value.dependencies.forEach((dependencyValue: FeatureProps) => {
                    if (!dependencyValue.selected) {
                        return;
                    }
                    dependencies.push({
                        name: dependencyValue.name,
                    });
                });
            featureMap.push({
                name: value.name,
                dependencies: dependencies,
            });
        });

        return featureMap;
    }

    saveSelectedFeaturesState(): void {
        window.localStorage.setItem(this.featuresStorageKey, JSON.stringify(this.getSelectedFeatures()));
    }

    clearStateForFeatures(): void {
        window.localStorage.removeItem(this.featuresStorageKey);
    }

    private requestDefaultStructure(): void {
        if (0 !== this.defaultStructure.length) {
            return;
        }
        defaultFeaturesManager.getDefaultFeaturesStructure().then((features: Array<Feature>) => {
            this.defaultStructure = features;
            this.initFeatureProps();
            dispatcher.dispatch(events.DEFAULT_FEATURES_LOADED, this.featureProps);
        });
    }

    private initFeatureProps(): void {
        if (this.featureProps.length) {
            return;
        }
        const savedState = this.getSelectedFeaturesState();
        this.formFeatureProps(savedState);
    }

    private formFeatureProps(source?: Array<Feature> | null): void {
        let featureProps: Array<FeatureProps> = [];
        this.defaultStructure.forEach((feature: Feature) => {
            const nameInCamelCase = toSnakeCase(feature.name);
            const featureLabel = this.signUpPrefix + nameInCamelCase;
            let featureFromSource = null;
            if (source) {
                featureFromSource = this.findFeatureInArray(source, feature.name);
            }
            featureProps.push({
                name: feature.name,
                labelKey: featureLabel,
                selected: !!featureFromSource,
                dependencies: this.createDependenciesForFeatureProps(feature, featureLabel, featureFromSource),
                hintKey: featureLabel + this.hintPostfix,
            });
        });

        this.featureProps = featureProps;
        return;
    }

    private findFeatureInArray(
        features: Array<Feature> | undefined,
        name: string,
        remove: boolean = true,
    ): Feature | null {
        if (!features) {
            return null;
        }
        let index = features.findIndex((feature: Feature) => {
            return feature.name === name;
        });
        if (index === -1) {
            return null;
        }
        if (remove) {
            return features.splice(index, 1)[0];
        }
        return features[index];
    }

    private findFeatureProps(featureProps: Array<FeatureProps> | undefined, name: string): FeatureProps | null {
        const feature = this.findFeatureInArray(featureProps, name, false);
        if (featureProps) {
            return feature as FeatureProps;
        }
        return null;
    }

    private createDependenciesForFeatureProps(
        feature: Feature,
        featureLabel: string,
        sourceParentFeature: Feature | null,
    ): Array<FeatureProps> {
        let featureDependencies: Array<FeatureProps> = [];
        feature.dependencies?.forEach((dependency: Feature) => {
            const nameInCamelCase = toSnakeCase(dependency.name).replace(feature.name + '_', '');
            const label = featureLabel + '.' + nameInCamelCase;
            let foundElemInSource;
            if (sourceParentFeature && sourceParentFeature.dependencies) {
                foundElemInSource = this.findFeatureInArray(sourceParentFeature.dependencies, dependency.name);
            }
            featureDependencies.push({
                name: dependency.name,
                labelKey: label,
                selected: !!foundElemInSource,
                hintKey: label + this.hintPostfix,
            });
        });

        return featureDependencies;
    }

    private setDependencyValue(parent: FeatureProps, value: boolean, dependencyName: string) {
        const dependency = this.findFeatureProps(parent.dependencies, dependencyName);
        if (!dependency) {
            return;
        }

        dependency.selected = value;
    }

    private resetDependenciesValues(featureProps: FeatureProps) {
        featureProps.dependencies?.forEach((dependency: FeatureProps) => {
            dependency.selected = false;
            return dependency;
        });
    }

    private getSelectedFeaturesState(): Array<Feature> | null {
        const storedValue = window.localStorage.getItem(this.featuresStorageKey);

        return storedValue ? JSON.parse(storedValue) : null;
    }
}
