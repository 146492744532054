import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Backdrop from '../../../../Backdrop';
import CalendarsTable from './CalendarsTable';
import { Calendar, Calendars } from 'interfaces/calendar/calendar';
import { userManager } from '../../../../../service/UserManager';
import { calendarManager } from '../../../../../service/Calendar/CalendarManager';
import dispatcher from '../../../../../service/dispatcher';
import events from '../../../../../events';
import metadataManager from 'service/MetadataManager';
import { Entity } from 'interfaces/entity';

interface Props extends WithTranslation {}

interface State {
    entitiesById: Map<number, Entity>;
    calendars: Calendars;
    accountId: number;
    loading: boolean;
}

class CalendarsTabPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            entitiesById: new Map<number, Entity>(),
            calendars: new Map(),
            accountId: 0,
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ ...this.state, loading: true });

        dispatcher.subscribe(events.CALENDAR_UPDATED, this, async () => {
            const calendars = await calendarManager.get(account.id);
            this.setState({ calendars });
        });

        const account = userManager.getCurrentAccount();
        const calendars = await calendarManager.get(account.id);
        const entitiesById = await this.getEntities(calendars);
        this.setState({ entitiesById, calendars, accountId: account.id, loading: false });
    }

    render() {
        const { t } = this.props;
        const { entitiesById, calendars, accountId, loading } = this.state;

        return (
            <Backdrop loading={loading}>
                <Box>
                    {/* TODO: Calendar V2 – https://mapsly.atlassian.net/browse/MD-3894
                    <Box>
                        <Typography variant="h5" gutterBottom>
                            <React.Fragment>
                                <AddIconButton onClick={this.handleClickAddCalendar} />
                            </React.Fragment>
                        </Typography>
                    </Box>
                    */}

                    <Box>
                        {calendars.size === 0 && <div>{t('calendar_settings.dialog.tabs.calendars.no_calendars')}</div>}
                        {calendars.size > 0 && (
                            <CalendarsTable
                                calendars={calendars}
                                entitiesById={entitiesById}
                                accountId={accountId}
                                handleDelete={this.handleDelete}
                            />
                        )}
                    </Box>
                </Box>
            </Backdrop>
        );
    }

    private async getEntities(calendars: Calendars): Promise<Map<number, Entity>> {
        const entitiesByIdMap = new Map<number, Entity>();
        await Promise.all(
            Array.from(calendars.values()).map(async (calendar: Calendar) => {
                const entity: Entity | undefined = await metadataManager.requestEntity(calendar.entityId);
                if (!entity) {
                    return;
                }

                entitiesByIdMap.set(entity.id, entity);
            }),
        );

        return entitiesByIdMap;
    }

    private handleDelete = async (calendarId: string) => {
        const { accountId, calendars } = this.state;

        calendars.delete(calendarId);
        this.setState({ ...this.state, calendars });

        await calendarManager.delete(accountId, calendarId);
    };
}

export default withTranslation()(CalendarsTabPanel);
