import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../locales/i18n';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import DateTime from '../../../../service/workflow_actions/forms/fields/DateTime';
import FormControlSelect from '../../AbstractForm/FormControlSelect';

const t = i18n.t.bind(i18n);

class DateTimeForm extends AbstractForm {
    renderFieldInputs(field, errors) {
        return (
            <>
                {this.renderIsRequiredInput(field, errors)}
                {this.renderIsHiddenInput(field, errors)}
                <Grid item xs={3}>
                    <FormControlSelect
                        fullWidth
                        margin="dense"
                        label={t('workflow_actions.forms.field.datetime.isTimeAllowed')}
                        name="isTimeAllowed"
                        value={field.isTimeAllowed}
                        onChange={this.handleInputChange}
                        error={errors.has('isTimeAllowed')}
                        helperText={errors.get('isTimeAllowed')}
                        data-testid="workflow_actions.forms.field.datetime.isTimeAllowed"
                    >
                        <MenuItem
                            value={false}
                            data-testid="workflow_actions.forms.field.datetime.isTimeAllowed.date_only"
                        >
                            {t('workflow_actions.forms.field.datetime.isTimeAllowed.date_only')}
                        </MenuItem>
                        <MenuItem
                            value={true}
                            data-testid="workflow_actions.forms.field.datetime.isTimeAllowed.datetime_only"
                        >
                            {t('workflow_actions.forms.field.datetime.isTimeAllowed.datetime_only')}
                        </MenuItem>
                    </FormControlSelect>
                </Grid>
            </>
        );
    }
}

DateTimeForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(DateTime).isRequired,
};

export default DateTimeForm;
