import dispatcher from '../../service/dispatcher';
import events from '../../events';
import formManager from '../../service/workflow_actions/MapFormManager';
import BaseWorkflowActionsForm from './BaseWorkflowActionsForm';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import React from 'react';
import filterParamsManager from '../../service/FilterParamsManager';

class WorkflowMapActionsForm extends BaseWorkflowActionsForm {
    constructor(props) {
        super(props);

        this.manager = formManager;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WORKFLOW_MAP_ACTIONS_FORM_REFRESHED, this, () => this.forceUpdate());
    }

    getActionButton() {
        return (
            <Button color="primary" onClick={this.submit} data-testid="workflow_map_actions.button.apply">
                {this.props.t('button.apply')}
            </Button>
        );
    }

    submit = () => {
        this.manager.trigger();
        filterParamsManager.trigger();
    };
}

export default withTranslation('translations', { withRef: true })(WorkflowMapActionsForm);
