import { AdapterId } from 'components/types';
import { Common } from 'interfaces';

export const ROUTE_MAX_GROUPS_TO_OPTIMIZATION_ESSENTIAL = 25;

export const MIN_POINTS_IN_ROUTE = 1;
export const MAX_POINTS_IN_ROUTE = 2000;
export const MAX_POINTS_IN_ROUTE_ESSENTIAL = 100;

export const TABLE_VIEW_PAGE_SIZES = [10, 20, 30, 40, 50, 70, 100];
export const TABLE_VIEW_DEFAULT_PAGE_SIZE = 10;

export const SSO_REVIEW_SYSTEM_LINKS = {
    [AdapterId.HUBSPOT]: 'https://ecosystem.hubspot.com/marketplace/apps/marketing/analytics-data/mapsly-230631',
    [AdapterId.ZOHO]: 'https://marketplace.zoho.com/app/crm/mapsly-extension-for-zoho-crm#ratingsReview',
    [AdapterId.PIPEDRIVE]:
        'https://www.pipedrive.com/en/marketplace/app/mapsly-interactive-map-for-pipedrive/fe8db15bad8850e0#user-reviews',
    [AdapterId.ZENDESK]: 'https://www.zendesk.com/apps/sell/mapsly/',
    [AdapterId.SALESFORCE]: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3u00000OMowmEAD',
    [AdapterId.CREATIO]: 'https://marketplace.creatio.com/app/mapsly-connector-creatio',
    [AdapterId.ODOO]: 'https://apps.odoo.com/apps/modules/13.0/mapsly_connector/',
    [AdapterId.DYNAMICS365]:
        'https://appsource.microsoft.com/sr-cyrl/product/web-apps/mapslyllc1631550223949.mapsly-saas?tab=Reviews',
    [AdapterId.KEAPCRM]:
        'https://marketplace.infusionsoft.com/listing/mapsly-llc-mapsly-interactive-map-routing-territories-geolocation-automation',
    [AdapterId.GOOGLESHEETS]:
        'https://workspace.google.com/marketplace/app/mapsly_map_routing_territories_geoautoma/512164799392',
    [AdapterId.SMARTSHEET]: null,
    [AdapterId.FRESHWORKSCRM]: null,
    [AdapterId.QUICKBASE]: null,
    [AdapterId.GOOGLECALENDAR]: null,
};

export const ADAPTERS_WITH_SSO = new Set<AdapterId>([
    AdapterId.HUBSPOT,
    AdapterId.ZOHO,
    AdapterId.PIPEDRIVE,
    AdapterId.ZENDESK,
    AdapterId.SALESFORCE,
    AdapterId.DYNAMICS365,
    AdapterId.KEAPCRM,
    AdapterId.SMARTSHEET,
]);

export const MOBILE_APP_REVIEW_LINKS = {
    [Common.MobilePlatform.ANDROID]: 'https://play.google.com/store/apps/details?id=com.mapsly.app',
    [Common.MobilePlatform.IOS]: 'https://itunes.apple.com/us/app/mapsly/id1564937868?mt=8&action=write-review',
};

export const MAPSLY_QUICK_DEMO_URL = 'https://calendly.com/mapsly-meeting/quick-demo';

/**
 * @deprecated
 * @link project://src/references/systemEntities
 */
export const SYSTEM_ENTITIES_API_NAMES = {
    user: 'User',
    route: 'Route',
    routingSession: 'Routing Session',
};

export const OBJECTIVE_MINIMIZE_DRIVING_TIME = 'minimize_driving_time';
export const OBJECTIVE_MINIMIZE_VEHICLES = 'minimize_vehicles';
export const OBJECTIVE_MINIMIZE_VEHICLES_BALANCE = 'minimize_vehicles_balance';
export const OBJECTIVE_MINIMIZE_COMPLETION = 'minimize_completion';
export const OBJECTIVE_MINIMIZE_COMPLETION_BALANCE = 'minimize_completion_balance';

export const enum DATE_CHANGE_TYPES {
    time = 'time',
    date = 'date',
}
