import { action, computed, makeObservable, observable } from 'mobx';
import { Routing } from 'interfaces';
import BackendService from '../../../api/BackendService';

type ErrorMap = Map<string, Routing.Settings.MappingValueError>;

const emptyMapping: Routing.Settings.MappingValueForm = {
    fieldId: null,
    defaultValue: null,
};

type RoutingSettingsMappingFormManagerKeys =
    | '_isActivated'
    | '_currentObject'
    | '_currentObjectError'
    | '_mapping'
    | '_mappingErrors';

abstract class RoutingSettingsMappingFormManager extends BackendService {
    protected _currentObject: Routing.Settings.MappingSelectedObject | null = null;
    private _isActivated: boolean = true;
    protected _currentObjectError: string | null = null;
    protected _mapping: Routing.Settings.MappingForm = new Map();
    protected _mappingErrors: ErrorMap = new Map();

    protected constructor() {
        super();

        makeObservable<RoutingSettingsMappingFormManager, RoutingSettingsMappingFormManagerKeys>(this, {
            _isActivated: observable,
            _currentObject: observable,
            _currentObjectError: observable,
            _mapping: observable,
            _mappingErrors: observable,

            isActivated: computed,
            mapping: computed,
            currentObjectError: computed,
            currentObject: computed,

            updateFormData: action,
            clear: action,
            setMappingField: action,
            setMappingDefaultValue: action,
            validate: action,
            setMappingDefaultValueError: action,
            setMappingFieldError: action,
        });
    }

    updateFormData(formData: Routing.Settings.FormMappingData) {
        const { currentObject, mapping, isActivated } = formData;
        this._isActivated = isActivated;
        this._currentObject = currentObject;
        this._mapping = new Map([...this.getDefaultMapping(), ...mapping]);

        this._mappingErrors = new Map();
        this._currentObjectError = null;
    }

    abstract validate(): Promise<boolean>;

    abstract getDefaultMapping(): Routing.Settings.MappingForm;

    clear() {
        this._currentObject = null;
        this._currentObjectError = null;
        this._mapping = new Map();
        this._mappingErrors = new Map();
    }

    getMappingError(key: string): Routing.Settings.MappingValueError {
        return (
            this._mappingErrors.get(key) ?? {
                field: null,
                defaultValue: null,
            }
        );
    }

    setMappingFieldError(key: string, errorText: string): void {
        const mappingError = this.getMappingError(key);
        this._mappingErrors.set(key, {
            ...mappingError,
            field: errorText,
        });
    }

    setMappingDefaultValueError(key: string, errorText: string): void {
        const mappingError = this.getMappingError(key);
        this._mappingErrors.set(key, {
            ...mappingError,
            defaultValue: errorText,
        });
    }

    get isActivated(): boolean {
        return this._isActivated;
    }

    get mapping(): Routing.Settings.MappingForm {
        return this._mapping;
    }

    get currentObjectError(): string | null {
        return this._currentObjectError;
    }

    get currentObject(): Routing.Settings.MappingSelectedObject | null {
        return this._currentObject;
    }

    set currentObject(selectedObject: Routing.Settings.MappingSelectedObject | null) {
        this._currentObject = selectedObject;
        this._mapping = new Map(this.getDefaultMapping());
    }

    getFormData(): Routing.Settings.FormMappingData {
        return {
            isActivated: this._isActivated,
            currentObject: this._currentObject,
            mapping: this._mapping,
        };
    }

    getMappingValue(key: string): Routing.Settings.MappingValueForm {
        return this._mapping.get(key) ?? emptyMapping;
    }

    getMappingField(key: string): Routing.Settings.MappingFieldValueForm {
        const data = this._mapping.get(key) ?? null;
        if (data === null) {
            return null;
        }
        return data.fieldId;
    }

    setMappingField(key: string, fieldId: Routing.Settings.MappingFieldValueForm): void {
        this._mapping.set(key, { ...emptyMapping, ...this._mapping.get(key), ...{ fieldId } });
    }

    getMappingDefaultValue(key: string): Routing.Settings.MappingDefaultValueForm {
        const data = this._mapping.get(key) ?? null;
        if (data === null) {
            return null;
        }
        return data.defaultValue;
    }

    setMappingDefaultValue(key: string, defaultValue: Routing.Settings.MappingDefaultValueForm): void {
        this._mapping.set(key, { ...emptyMapping, ...this._mapping.get(key), ...{ defaultValue } });
    }
}

export default RoutingSettingsMappingFormManager;
