import React from 'react';
import { humanReadableNumber } from '../../../utils';
import { Slider } from '@material-ui/core';
import { Mark } from '@material-ui/core/Slider/Slider';
import {
    SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE,
    SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE,
    SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE,
} from '../constants';

interface TranscriptionMinutesSliderProps {
    value?: number;
    onChange: (value: number) => void;
}

export class TranscriptionMinutesSlider extends React.Component<TranscriptionMinutesSliderProps> {
    constructor(props: TranscriptionMinutesSliderProps) {
        super(props);

        this.state = {
            value: props.value ?? 0,
        };
    }

    handleChange = (_: React.ChangeEvent<{}>, minutes: number | number[]) => {
        if (minutes < SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE) {
            minutes = SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE;
        }

        if (minutes > SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE) {
            minutes = SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE;
        }

        this.props.onChange(minutes as number);
    };

    getSliderMarks(): Mark[] {
        let result: Mark[] = [];
        for (
            let i = SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE, index = 0;
            i <= SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE;
            i += SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE, index++
        ) {
            result.push({
                label: index % 4 === 0 ? humanReadableNumber(i) : undefined,
                value: i,
            });
        }

        return result;
    }

    render() {
        return (
            <Slider
                data-testid="transcription_minutes_form.new_number_credits"
                step={SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE}
                marks={this.getSliderMarks()}
                min={SUBSCRIPTION_TRANSCRIPTION_MINUTES_MIN_PACKAGE_SIZE}
                max={SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE}
                valueLabelDisplay="auto"
                value={this.props.value}
                valueLabelFormat={(number: number) => humanReadableNumber(number)}
                onChange={this.handleChange}
            />
        );
    }
}

export default TranscriptionMinutesSlider;
