import i18n from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { SubscriptionStatus } from '../types';
import { ImportMessage, LEVEL, ORIGIN } from './types';
import { Account } from 'interfaces';

const t = i18n.t.bind(i18n);

class SubscriptionMessagesGenerator {
    public static getMessages(subscription: null | Account.Subscription): ImportMessage[] {
        if (subscription === null) {
            return [];
        }

        if (!this.needToBeExtended(subscription)) {
            return [];
        }

        return [
            {
                id: uuidv4(),
                ds: null,
                entity: null,
                field: null,
                origin: ORIGIN.INTERNAL,
                httpCode: 200,
                level: LEVEL.WARNING,
                message: this.generateMessage(subscription),
                userId: null,
            },
        ];
    }

    public static needToBeExtended(subscription: Account.Subscription): boolean {
        if (subscription.status === SubscriptionStatus.PAST_DUE) {
            return true;
        }
        return subscription.status === SubscriptionStatus.CUSTOM && subscription.gracePeriodRemainingDays !== null;
    }

    public static generateMessage(subscription: Account.Subscription): string {
        if (subscription.status === SubscriptionStatus.CUSTOM && subscription.gracePeriodRemainingDays !== null) {
            return t('account.status_message.custom_subscription_expires', {
                count: subscription.gracePeriodRemainingDays,
            });
        }

        return subscription.gracePeriodRemainingDays !== null && subscription.gracePeriodRemainingDays <= 7
            ? t('account.status_message.subscription_charge_failed_and_account_will_auto_suspend', {
                  count: subscription.gracePeriodRemainingDays,
              })
            : t('account.status_message.subscription_charge_failed');
    }
}

export default SubscriptionMessagesGenerator;
