import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import BatteryOptimizationModalManager from '../../service/BgGeo/BatteryOptimizationModalManager';
import { weAreInAndroidNativeApp } from '../../utils';

interface State {
    showModal: boolean;
    hideSettingsPath: boolean;
}

class BatteryOptimizationModal extends React.PureComponent<WithTranslation, State> {
    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            showModal: false,
            hideSettingsPath: false,
        };
    }

    componentDidMount = () => {
        dispatcher.subscribe(events.BG_GEO_TRACKING_STARTED, this, () => {
            if (!weAreInAndroidNativeApp()) {
                return;
            }
            if (BatteryOptimizationModalManager.wasPopupShown()) {
                return;
            }
            this.setState({ showModal: true, hideSettingsPath: false });
            BatteryOptimizationModalManager.rememberPopupWasShown();
        });
        dispatcher.subscribe(events.SHOW_BATTERY_OPTIMIZATION_MODAL, this, async (hideSettingsPath: boolean) => {
            this.setState({ showModal: true, hideSettingsPath: hideSettingsPath });
        });
    };

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    private closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { t } = this.props;
        const { showModal, hideSettingsPath } = this.state;

        return (
            <Dialog open={showModal} style={{ zIndex: 9010 }}>
                <DialogTitle>{t('battery_optimization_modal.title')}</DialogTitle>
                <DialogContent>
                    <p dangerouslySetInnerHTML={{ __html: t('battery_optimization_modal.text') }} />
                    {!hideSettingsPath && <p>{t('battery_optimization_modal.where_to_find_settings')}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="primary" data-testid="battery_optimization_modal.btn_ok">
                        {t('battery_optimization_modal.btn_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(BatteryOptimizationModal);
