import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const FEATURES = [
    'map_filters',
    'editing_records',
    'routing',
    'prospects',
    'territories',
    'searching',
    'mapsly_data_sync',
    'automation',
];

class PlanAlert extends React.PureComponent {
    render() {
        return (
            <>
                <h4>{this.props.title}</h4>
                <ul>
                    {FEATURES.map((name) => (
                        <li key={name}>{this.props.t('billing.features.' + name)}</li>
                    ))}
                </ul>
            </>
        );
    }
}

PlanAlert.propsTypes = {
    title: PropTypes.string.isRequired,
};

export default withTranslation('translations', { withRef: true })(PlanAlert);
