import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class PopupNotification extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    handleClose = () => {
        // при закрытии поменять url на тот же, но без сообщения?
        this.setState({
            open: false,
        });
    };

    getTitle = () => {
        return null;
    };

    getContent = () => {
        return null;
    };

    getActions = () => {
        return (
            <Button color="primary" onClick={this.handleClose} dta-testid="popup_notification.button.close">
                Ok
            </Button>
        );
    };

    render() {
        if (!this.state.open) {
            return null;
        }

        return (
            <Dialog open onClose={this.handleClose}>
                <DialogTitle>{this.getTitle()}</DialogTitle>
                <DialogContent>{this.getContent()}</DialogContent>
                <DialogActions>{this.getActions()}</DialogActions>
            </Dialog>
        );
    }
}
