import { DataBuilder } from './DataBuilder';
import { v5 as uuidv5 } from 'uuid';
import moment from 'moment';
import i18n from 'locales/i18n';
import { TFunctionResult } from 'i18next';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import {
    LEVEL_CITY,
    LEVEL_COUNTRY,
    LEVEL_HOUSE,
    LEVEL_STATE,
    LEVEL_STREET,
    LEVEL_ZIP,
} from '../utils/CompositeAddress';
import { Geo } from 'interfaces';

const GOOGLE_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export type GooglePlaceStructureField = {
    notVisible?: boolean;
    isPin?: boolean;
    isLink?: boolean;
    title: string;
    type?: string;
    requestField?: string;
    getValue: (item: GetValueItem) => GetValueReturnType;
};

type GetValueReturnType = string | number | boolean | TFunctionResult;

type GetValueItem = {
    place_id: string;
    price_level: number;
    rating: number;
    user_ratings_total?: number;
    name: string;
    url: string;
    icon: string;
    business_status?: string;
    formatted_phone_number: string;
    international_phone_number: string;
    website: string;
    formatted_address: string;
    adr_address?: string;
    address_components: AddressComponent[];
    types: string[];
    exported?: boolean;
    opening_hours: OpeningHours;
    reviews: Review[];
    geometry: Geometry;
    photos: Photo[];
};

type Photo = {
    photo_reference?: string; // google places web service api
    html_attributions: string[];
    getUrl?: (size: { maxWidth: number; maxHeight: number }) => string; // google places javascript api
};

type Geometry = {
    location: GeometryLocation;
};

type GeometryLocation = {
    lat: number;
    lng: number;
};

type Review = {
    author_name: string;
    text: string;
    time: number;
    rating: number;
};

type OpeningHours = {
    weekday_text: string[];
    open_now: boolean;
};

type AddressComponent = {
    long_name: string;
    types: string[];
};

type GooglePlaceStructureType = {
    [key: string]: GooglePlaceStructureField;
};

function fillStructure(apiKey: string, structure?: GooglePlaceStructureType): GooglePlaceStructureType {
    const STRUCTURE: GooglePlaceStructureType = structure ?? {};
    const t = i18n.t.bind(i18n);
    STRUCTURE.position = {
        get title() {
            return t('prospecting.google_place_structure.position.title');
        },
        notVisible: true,
        requestField: 'geometry/location',
        getValue: (item) => {
            return [item.geometry.location.lat, item.geometry.location.lng];
        },
    };
    STRUCTURE.id = {
        get title() {
            return t('prospecting.google_place_structure.id.title');
        },
        requestField: 'place_id',
        getValue: (item) => {
            return item.place_id;
        },
    };
    STRUCTURE.prospectId = {
        get title() {
            return t('prospecting.google_place_structure.prospect_id.title');
        },
        notVisible: true,
        requestField: 'place_id',
        getValue: (item) => {
            return 'google-place-' + item.place_id;
        },
    };
    STRUCTURE.priceLevel = {
        get title() {
            return t('prospecting.google_place_structure.price_level.title');
        },
        requestField: 'price_level',
        getValue: (item) => {
            return item.price_level;
        },
        type: 'float',
    };
    STRUCTURE.name = {
        get title() {
            return t('prospecting.google_place_structure.name.title');
        },
        requestField: 'name',
        getValue: (item) => {
            return item.name;
        },
    };
    STRUCTURE.addressFields = {
        get title() {
            return t('prospecting.google_place_structure.address_fields.title');
        },
        requestField: 'address_component',
        getValue: (item) => {
            if (!item.address_components) {
                return {};
            }
            return parseAddressComponentsToAddressFields(item.address_components);
        },
    };
    STRUCTURE.address = {
        get title() {
            return t('prospecting.google_place_structure.address.title');
        },
        requestField: 'formatted_address',
        getValue: (item) => {
            return item.formatted_address;
        },
        isPin: true,
    };
    STRUCTURE.street = {
        get title() {
            return t('prospecting.google_place_structure.street.title');
        },
        requestField: 'adr_address',
        getValue: (item) => {
            if (item.adr_address === undefined) {
                return null;
            }

            let value = item.adr_address.match(/<span class="street-address">([^<]+)<\/span>/);
            return value !== null && value[1] !== undefined
                ? value[1].replace('&amp;', '&').replace('&gt;', '>').replace('&lt;', '<').replace('&quot;', "'")
                : null;
        },
    };
    STRUCTURE.city = {
        get title() {
            return t('prospecting.google_place_structure.city.title');
        },
        requestField: 'address_component',
        getValue: (item) => {
            const cityObject = item.address_components.find((item) => {
                return item.types.includes('locality');
            });
            return cityObject !== undefined ? cityObject.long_name : null;
        },
    };
    STRUCTURE.zipCode = {
        get title() {
            return t('prospecting.google_place_structure.zip_code.title');
        },
        requestField: 'address_component',
        getValue: (item) => {
            const zipObject = item.address_components.find((item) => {
                return item.types.includes('postal_code');
            });
            return zipObject !== undefined ? zipObject.long_name : null;
        },
    };
    STRUCTURE.country = {
        get title() {
            return t('prospecting.google_place_structure.country.title');
        },
        requestField: 'address_component',
        getValue: (item) => {
            const countryObject = item.address_components.find((item) => {
                return item.types.includes('country');
            });
            return countryObject !== undefined ? countryObject.long_name : null;
        },
    };
    STRUCTURE.state = {
        get title() {
            return t('prospecting.google_place_structure.state.title');
        },
        requestField: 'address_component',
        getValue: (item) => {
            const stateObject = item.address_components.find((item) => {
                return item.types.includes('administrative_area_level_1');
            });
            return stateObject !== undefined ? stateObject.long_name : null;
        },
    };
    STRUCTURE.types = {
        get title() {
            return t('prospecting.google_place_structure.types.title');
        },
        requestField: 'type',
        getValue: (item) => {
            return item.types.map((value) => {
                return value.replace(/_/g, ' ');
            });
        },
        type: 'array',
    };
    STRUCTURE.rating = {
        get title() {
            return t('prospecting.google_place_structure.rating.title');
        },
        requestField: 'rating',
        getValue: (item) => {
            return item.rating;
        },
        type: 'float',
    };
    STRUCTURE.website = {
        get title() {
            return t('prospecting.google_place_structure.website.title');
        },
        requestField: 'website',
        getValue: (item) => {
            let src = item.website;
            if (src === undefined || src === null || src.length > 255) {
                return null;
            }
            return src;
        },
        isLink: true,
    };
    STRUCTURE.phone = {
        get title() {
            return t('prospecting.google_place_structure.phone.title');
        },
        requestField: 'formatted_phone_number',
        getValue: (item) => {
            return item.formatted_phone_number;
        },
    };
    STRUCTURE.internationalPhone = {
        get title() {
            return t('prospecting.google_place_structure.international_phone.title');
        },
        requestField: 'international_phone_number',
        getValue: (item) => {
            return item.international_phone_number;
        },
    };
    STRUCTURE.photo = {
        get title() {
            return t('prospecting.google_place_structure.photo.title');
        },
        notVisible: true, // should not be available for mapping, apikey-dependent
        requestField: 'photos',
        getValue: (item) => {
            // photo from another api requested with another api key
            if (item.photos?.length > 0 && typeof item.photos[0]?.getUrl === 'function') {
                return item.photos[0].getUrl({ maxWidth: 500, maxHeight: 500 });
            }

            const ref = item.photos?.length > 0 ? item.photos[0].photo_reference || null : null;
            if (ref === null) {
                return null;
            }

            return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=500&maxheight=500&photo_reference=${ref}&key=${apiKey}`;
        },
    };
    STRUCTURE.permanentlyClosed = {
        get title() {
            return t('prospecting.google_place_structure.permanently_closed.title');
        },
        type: 'boolean',
        requestField: 'business_status',
        getValue: (item) => {
            if (item.business_status === undefined) {
                return false;
            }
            return item.business_status !== 'OPERATIONAL';
        },
    };
    STRUCTURE.openingHours = {
        get title() {
            return t('prospecting.google_place_structure.opening_hours.title');
        },
        requestField: 'opening_hours',
        getValue: (item) => {
            if (item.opening_hours === undefined) {
                return null;
            }
            return item.opening_hours.weekday_text.join(', ');
        },
    };
    STRUCTURE.openNow = {
        get title() {
            return t('prospecting.google_place_structure.open_now.title');
        },
        requestField: 'opening_hours',
        getValue: (item) => {
            if (item.opening_hours === undefined) {
                return null;
            }
            return item.opening_hours.open_now;
        },
        type: 'boolean',
    };
    STRUCTURE.icon = {
        get title() {
            return t('prospecting.google_place_structure.icon.title');
        },
        requestField: 'icon',
        getValue: (item) => {
            return item.icon;
        },
    };
    STRUCTURE.sourceUrl = {
        get title() {
            return t('prospecting.google_place_structure.source_url.title');
        },
        requestField: 'url',
        getValue: (item) => {
            return item.url;
        },
        isLink: true,
    };
    STRUCTURE.sourceName = {
        get title() {
            return t('prospecting.google_place_structure.source_name.title');
        },
        getValue: () => {
            return t('prospecting.google_place_structure.source_name.value');
        },
    };
    STRUCTURE.exportStatus = {
        get title() {
            return t('prospecting.google_place_structure.export_status.title');
        },
        notVisible: true,
        getValue: (item) => {
            if (item.exported === undefined) {
                return t('prospecting.status.not_exported');
            }

            return item.exported ? t('prospecting.status.exported') : t('prospecting.status.not_exported');
        },
    };
    STRUCTURE.userRatingsTotal = {
        get title() {
            return t('prospecting.google_place_structure.user_ratings_total.title');
        },
        requestField: 'user_ratings_total',
        getValue: (item) => {
            if (item.user_ratings_total === undefined) {
                return 0;
            }

            return item.user_ratings_total;
        },
        type: 'integer',
    };
    STRUCTURE.uuid = {
        get title() {
            return t('prospecting.google_place_structure.uuid.title');
        },
        notVisible: true,
        requestField: 'place_id',
        getValue: (item) => {
            return uuidv5(item.place_id, GOOGLE_NAMESPACE);
        },
    };
    STRUCTURE.description = {
        get title() {
            return t('prospecting.google_place_structure.description.title');
        },
        requestField: 'user_ratings_total',
        getValue: (item) => {
            if (item.user_ratings_total !== undefined) {
                return t('prospecting.google_place_structure.description.value', {
                    userRatingsTotal: item.user_ratings_total,
                });
            }
            return '';
        },
    };
    STRUCTURE.reviews = {
        get title() {
            return t('prospecting.google_place_structure.reviews.title');
        },
        requestField: 'reviews',
        getValue: (item) => {
            if (item.reviews === undefined) {
                return '';
            }
            let reviews = '';
            const star = '⭐';
            item.reviews.forEach((review) => {
                reviews +=
                    review.author_name +
                    ' · ' +
                    moment.unix(review.time).format('DD/MMM/YYYY') +
                    ' · ' +
                    star.repeat(review.rating) +
                    '\n';
                reviews += review.text + '.\n\n\n';
            });
            return reviews;
        },
        type: 'text',
    };

    return STRUCTURE;
}

export function parseAddressComponentsToAddressFields(addressComponents: AddressComponent[]): Geo.AddressFields {
    const houseComponent = addressComponents.find((component) => component.types.includes('street_number'));
    const streetComponent = addressComponents.find((component) => component.types.includes('route'));
    const cityComponent = addressComponents.find((component) => component.types.includes('locality'));
    const stateComponent = addressComponents.find((component) =>
        component.types.includes('administrative_area_level_1'),
    );
    const zipComponent = addressComponents.find((component) => component.types.includes('postal_code'));
    const countryComponent = addressComponents.find((component) => component.types.includes('country'));

    return {
        [LEVEL_HOUSE]: houseComponent?.long_name ?? null,
        [LEVEL_STREET]: streetComponent?.long_name ?? null,
        [LEVEL_CITY]: cityComponent?.long_name ?? null,
        [LEVEL_STATE]: stateComponent?.long_name ?? null,
        [LEVEL_ZIP]: zipComponent?.long_name ?? null,
        [LEVEL_COUNTRY]: countryComponent?.long_name ?? null,
    };
}

export class GooglePlaceStructure extends DataBuilder {
    protected apiKey = '';

    constructor(apiKey: string = '') {
        super(fillStructure(apiKey));
        this.apiKey = apiKey;

        dispatcher.subscribe(events.EVENT_CHANGE_LANGUAGE, this, () => {
            fillStructure(this.apiKey, this.structure);
        });
    }

    public setApiKey(apiKey: string) {
        this.apiKey = apiKey;
        fillStructure(this.apiKey, this.structure);
    }
}
