import ProcessorInterface from './ProcessorInterface';
import { directActionManager } from '../../workflow_actions/DirectActionManager';
import SubmitForm from '../Events/SubmitForm';
import { logDebug } from '../../../utils';

class SubmitFormProcessor implements ProcessorInterface {
    public process(event: SubmitForm): void {
        directActionManager.runFormEvent(
            event.getAccountId(),
            event.getFormActionId(),
            event.getActionGroupIds(),
            event.getEntityId(),
            event.getAdditionalContextData(),
            event.getContextUlid(),
            event.getTriggerEventData(),
            event.toBackend(),
        );
    }

    public onComplete(event: SubmitForm): void {
        logDebug(event);
    }
}

export default SubmitFormProcessor;
