import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { WeekDayInfo } from './WorkingDays';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import { formatRouteDateWithYear } from '../../../utils';
import Tooltip from '@material-ui/core/Tooltip';
import WorkingDayTime from './WorkingDayTime';
import './WorkingDay.css';
import React from 'react';

interface WorkingDayProps extends WithTranslation {
    weekDay: WeekDayInfo;
    onStartDateChanged: (date: MaterialUiPickersDate, weekDayName: string) => void;
    onEndDateChanged: (date: MaterialUiPickersDate, weekDayName: string) => void;
    onIncludeDayChanged: (weekDayName: string) => void;
    disabled: boolean;
    timePickerVariant: WrapperVariant;
    showDate?: Date;
    showTooltip?: string;
}

const WorkingDay: React.FC<WorkingDayProps> = (props) => {
    const {
        weekDay,
        onStartDateChanged,
        onEndDateChanged,
        onIncludeDayChanged,
        t,
        disabled,
        timePickerVariant,
        showDate,
        showTooltip,
    } = props;

    const checkboxWithName = (
        <>
            <div className="c-form-dialog-route-preference__working-hours-item-checkbox">
                <Checkbox
                    disabled={disabled}
                    color="primary"
                    checked={weekDay.enable}
                    onChange={() => onIncludeDayChanged(weekDay.name)}
                />
            </div>
            <div className="c-form-dialog-route-preference__working-hours-item-week-day">
                <div
                    className={`c-form-dialog-route-preference__working-hours-item-week-day-name${
                        disabled ? ' disabled' : ''
                    }`}
                >
                    {weekDay.translate}
                </div>
                {!!showDate && (
                    <div className="c-form-dialog-route-preference__working-hours-item-week-day-date">
                        {formatRouteDateWithYear(showDate, t)}
                    </div>
                )}
            </div>
        </>
    );

    return (
        <React.Fragment>
            <div className="c-form-dialog-route-preference__working-hours-item">
                {showTooltip ? (
                    <Tooltip title={showTooltip}>
                        <div className="c-form-dialog-route-preference__working-hours-item">{checkboxWithName}</div>
                    </Tooltip>
                ) : (
                    checkboxWithName
                )}
                {weekDay.enable && (
                    <div className="c-form-dialog-route-preference__working-hours-item-time">
                        <WorkingDayTime
                            weekDayStart={weekDay.start}
                            weekDayEnd={weekDay.end}
                            onStartDateChanged={(date) => onStartDateChanged(date, weekDay.name)}
                            onEndDateChanged={(date) => onEndDateChanged(date, weekDay.name)}
                            timePickerVariant={timePickerVariant}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
            {weekDay.enable && weekDay.start > weekDay.end && (
                <Typography display="inline" component="span" color="error">
                    {t('schedule.end_cannot_precede_start')}
                </Typography>
            )}
        </React.Fragment>
    );
};

export default withTranslation('translations')(WorkingDay);
