import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Box, makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { Children, CSSProperties, FC } from 'react';

import { formatToDateTimeString, isValidElementOfType, UT_DATETIME_PICKER_FORMAT } from '../../utils';
import DateTimeBetweenFilter from '../utils/DateTimeBetweenFilter';
import {
    getDateTimeFilterValue,
    OPERATOR_BETWEEN,
    OPERATOR_IS_EMPTY,
    OPERATOR_IS_NOT_EMPTY,
} from '../utils/tableFilter';

import './style.css';
import TextField from '@material-ui/core/TextField';

const useCellStyles = makeStyles({ cell: { minWidth: '176px!important' } });

export const DateTimeFilterCell: FC<TableFilterRow.CellProps> = (props) => {
    const style: CSSProperties = {};
    try {
        if (getDateTimeFilterValue(props.filter) !== null) {
            style['backgroundColor'] = 'lavender';
        }
    } catch (e) {
        if (props.filter?.value !== OPERATOR_IS_EMPTY && props.filter?.value !== OPERATOR_IS_NOT_EMPTY) {
            style['backgroundColor'] = 'mistyrose';
        }
    }

    let isRangeMode = false;
    let isTextMode = false;
    Children.forEach(props.children, (child: any) => {
        if (child.type === TableFilterRow.FilterSelector) {
            const cp = child.props;
            if (cp.value === OPERATOR_BETWEEN) {
                isRangeMode = true;
            }
            if (cp.value === OPERATOR_IS_EMPTY || cp.value === OPERATOR_IS_NOT_EMPTY) {
                isTextMode = true;
            }
        }
    });

    const cellClasses = useCellStyles();

    if (isTextMode) {
        style.minWidth = 220;
        return (
            <TableFilterRow.Cell {...props} style={style}>
                {Children.map(props.children, (child: any) => {
                    if (child.type === TableFilterRow.Editor) {
                        const childProps = child.props;
                        if (child.props.value !== 'empty') {
                            childProps.onChange('empty');
                        }
                        return (
                            <Box m={1} flex={1}>
                                <TextField disabled value={props.filter?.operation} />
                            </Box>
                        );
                    }

                    return child;
                })}
            </TableFilterRow.Cell>
        );
    }

    if (isRangeMode) {
        style.minWidth = 220;
        return (
            <TableFilterRow.Cell {...props} style={style}>
                {Children.map(props.children, (child: any) => {
                    if (child.type === TableFilterRow.Editor) {
                        let value = {} as { dateFrom?: string; dateTo?: string };
                        if (child.props.value !== OPERATOR_IS_EMPTY && child.props.value !== OPERATOR_IS_NOT_EMPTY) {
                            value = filterValueToDateBetweenValue(props.filter?.value);
                        } else {
                            value = { dateFrom: new Date().toString(), dateTo: new Date().toString() };
                        }
                        return (
                            <Box m={1} flex={1}>
                                <DateTimeBetweenFilter
                                    classes={cellClasses}
                                    onFilter={(value) => {
                                        const dates = dateBetweenValueTofilterValue(value);
                                        child.props.onChange(dates);
                                    }}
                                    filter={{ value }}
                                />
                            </Box>
                        );
                    }

                    return child;
                })}
            </TableFilterRow.Cell>
        );
    }

    return (
        <TableFilterRow.Cell {...props} style={style}>
            {Children.map(props.children, (child: any) => {
                if (isValidElementOfType(child, TableFilterRow.Editor)) {
                    const childProps = child.props;

                    let value = new Date().toString();
                    if (childProps.value !== OPERATOR_IS_EMPTY && childProps.value !== OPERATOR_IS_NOT_EMPTY) {
                        value =
                            childProps.value
                                ?.split(' ')
                                .filter(Boolean)
                                .slice(0, 2)
                                .join(' ')
                                .replace(/^\D+|\D+$/g, '') ??
                            childProps.value ??
                            null;
                    }

                    return (
                        <Box m={1} flex={1}>
                            <DateTimePicker
                                disabled={childProps.disabled}
                                value={value}
                                format={UT_DATETIME_PICKER_FORMAT}
                                labelFunc={(date, fallback = '') =>
                                    date ? format(date, 'dd.MM.yyyy HH:mm') : fallback
                                }
                                fullWidth
                                clearable
                                onChange={(date) => {
                                    const formatted = date ? formatToDateTimeString(date) : '';
                                    childProps.onChange(formatted);
                                }}
                            />
                        </Box>
                    );
                }

                return child;
            })}
        </TableFilterRow.Cell>
    );
};

const filterValueToDateBetweenValue = (val?: string) => {
    const value = (val || '')
        .concat(',')
        // if we switch mode from single to multiple, there will be only 1 value
        .repeat(2)
        .split(',')
        .map((it) => it.trim())
        .slice(0, 2);
    const [dateFromString, dateToString] = value;
    const valueObj = {} as { dateFrom?: string; dateTo?: string };

    if (dateFromString) {
        valueObj.dateFrom = dateFromString;
    }
    if (dateToString) {
        valueObj.dateTo = dateToString;
    }

    return valueObj;
};

const dateBetweenValueTofilterValue = (value: { dateFrom: string | null; dateTo: string | null }) => {
    return [value.dateFrom, value.dateTo, value.dateFrom, value.dateTo]
        .filter((it): it is string => Boolean(it))
        .slice(0, 2)
        .map((it) => formatToDateTimeString(new Date(it)))
        .sort()
        .join(', ');
};
