import arrayMove from 'array-move';

import { userManager } from '../../UserManager';

import Button from './fields/Button';
import { FIELD_TYPES } from './fields/AbstractField';

class Form {
    constructor() {
        this.accountId = null;
        this.title = '';
        this.formActionId = null;
        this.entityId = null;
        this.contextGuid = null;

        this.fields = new Map();
        this.actionButtons = new Map();
    }

    setAccountId(accountId) {
        this.accountId = accountId;

        return this;
    }

    getAccountId() {
        return this.accountId;
    }

    setContextGuid(contextGuid) {
        this.contextGuid = contextGuid;

        return this;
    }

    getContextGuid() {
        return this.contextGuid;
    }

    setFormActionId(formActionId) {
        this.formActionId = formActionId;

        return this;
    }

    getFormActionId() {
        return this.formActionId;
    }

    setEntityId(entityId) {
        this.entityId = entityId;

        return this;
    }

    getEntityId() {
        return this.entityId;
    }

    setTitle(title) {
        this.title = title;

        return this;
    }

    getTitle() {
        return this.title;
    }

    hasItem(field) {
        return this.actionButtons.has(field.getApiName()) || this.fields.has(field.getApiName());
    }

    addItem(field) {
        if (this.hasItem(field)) {
            throw new Error('workflow_actions.forms.field.validation.apiName.unique');
        }
        const fieldPosition = field.position;
        field.position = null;

        if (field instanceof Button && field.getIsActionButton()) {
            this.actionButtons.set(field.getApiName(), field);
            if (fieldPosition) {
                this.moveLastFieldToPosition(fieldPosition);
            }
            return this;
        }

        this.fields.set(field.getApiName(), field);
        if (fieldPosition) {
            this.moveLastFieldToPosition(fieldPosition);
        }

        return this;
    }

    updateItem(field) {
        if (field instanceof Button && field.getIsActionButton()) {
            return this.actionButtons.set(field.getApiName(), field);
        }

        this.fields.set(field.getApiName(), field);

        return this;
    }

    removeItem(field) {
        if (field instanceof Button && field.getIsActionButton()) {
            return this.actionButtons.delete(field.getApiName(), field);
        }

        return this.fields.delete(field.getApiName());
    }

    clearItems() {
        this.fields.clear();
        this.actionButtons.clear();
    }

    getFields(all = false) {
        const items = [];
        const isRoleSuperAdmin = userManager.isRoleSuperAdmin();

        for (let [, item] of this.fields.entries()) {
            if (all || isRoleSuperAdmin || ![FIELD_TYPES.STYLE, FIELD_TYPES.HTML].includes(item.fieldType)) {
                items.push(item);
            }
        }

        return items;
    }

    getFormContext() {
        const context = {};

        for (const field of this.fields.values()) {
            context[field.getApiName()] = field.getValue();
        }

        return context;
    }

    getActionButtons() {
        const items = [];

        for (let [, item] of this.actionButtons.entries()) {
            items.push(item);
        }

        return items;
    }

    reorderFields(first, second) {
        const fields = arrayMove(this.getFields(), first, second);

        this.fields.clear();

        for (let field of fields) {
            this.addItem(field);
        }
    }

    moveLastFieldToPosition(position) {
        const lastIndex = this.getFields().length;
        this.reorderFields(lastIndex - 1, position);
    }

    reorderActionButtons(first, second) {
        const buttons = arrayMove(this.getActionButtons(), first, second);

        this.actionButtons.clear();

        for (let button of buttons) {
            this.addItem(button);
        }
    }

    validate() {
        const result = new Map();

        for (let [key, field] of this.fields) {
            result.set(key, field.validate(this));
        }

        return result;
    }

    serialize() {
        const result = {
            fields: [],
        };

        for (let [, field] of this.fields) {
            result.fields.push(field.serialize());
        }
        for (let [, field] of this.actionButtons) {
            result.fields.push(field.serialize());
        }

        return result;
    }

    serializeWithApiNames(formContextData = {}) {
        const result = {
            ...formContextData,
            fields: {},
        };

        for (let [, field] of this.fields) {
            result.fields[field.getApiName()] = field.serialize();
        }
        for (let [, field] of this.actionButtons) {
            result.fields[field.getApiName()] = field.serialize();
        }

        return result;
    }
}

export default Form;
