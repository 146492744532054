import React from 'react';
import PropTypes from 'prop-types';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import Checkbox from '../../../../service/workflow_actions/forms/fields/Checkbox';
import WorkflowGroups from '../../../WorkflowGroups/WorkflowGroups';
import { TYPE_DEFAULT_ACTION_GROUP } from '../../../../service/WorkflowGroupManager';
import i18n from '../../../../locales/i18n';
import { CallContext } from '../../../utils/CallContext';
const t = i18n.t.bind(i18n);

class CheckboxForm extends AbstractForm {
    renderFieldInputs(field, errors) {
        return (
            <>
                {this.renderIsHiddenInput(field, errors)}
                <WorkflowGroups
                    name="handlers"
                    title={t('workflow_actions.forms.field.actions.onChange')}
                    placeholder={t('workflow_actions.forms.field.actions.placeholder')}
                    parentNamespace={this.props.action.namespace}
                    groupType={TYPE_DEFAULT_ACTION_GROUP}
                    groups={field.getHandlers()}
                    errors={this.state.errors}
                    onChange={this.handlerHandlersChange}
                    account={this.props.account}
                    dataSources={this.props.dataSources}
                    callContext={this.props.callContext}
                />
            </>
        );
    }
}

CheckboxForm.propTypes = {
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    account: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,

    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Checkbox).isRequired,
};

export default CheckboxForm;
