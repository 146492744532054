export const DEFAULT_LANGUAGE = 'en-US';
export const AUTODETECT_LANGUAGE = 'autodetect_language';
export const AUTODETECT_MULTIPLE_LANGUAGES = 'autodetect_multiple_languages';

export const TRANSCRIBE_LANGUAGES = [
    'ab-GE',
    'af-ZA',
    'ar-AE',
    'ar-SA',
    'hy-AM',
    'ast-ES',
    'az-AZ',
    'ba-RU',
    'eu-ES',
    'be-BY',
    'bn-IN',
    'bs-BA',
    'bg-BG',
    'ca-ES',
    'ckb-IR',
    'ckb-IQ',
    'zh-CN',
    'zh-TW',
    'hr-HR',
    'cs-CZ',
    'da-DK',
    'nl-NL',
    'en-AU',
    'en-GB',
    'en-IN',
    'en-IE',
    'en-NZ',
    'en-AB',
    'en-ZA',
    'en-US',
    'en-WL',
    'et-ET',
    'fa-IR',
    'fi-FI',
    'fr-FR',
    'fr-CA',
    'gl-ES',
    'ka-GE',
    'de-DE',
    'de-CH',
    'el-GR',
    'gu-IN',
    'ha-NG',
    'he-IL',
    'hi-IN',
    'hu-HU',
    'is-IS',
    'id-ID',
    'it-IT',
    'ja-JP',
    'kab-DZ',
    'kn-IN',
    'kk-KZ',
    'rw-RW',
    'ko-KR',
    'ky-KG',
    'lv-LV',
    'lt-LT',
    'lg-IN',
    'mk-MK',
    'ms-MY',
    'ml-IN',
    'mt-MT',
    'mr-IN',
    'mn-MN',
    'no-NO',
    'or-IN',
    'ps-AF',
    'pl-PL',
    'pt-PT',
    'pt-BR',
    'pa-IN',
    'ro-RO',
    'ru-RU',
    'sr-RS',
    'si-LK',
    'sk-SK',
    'sl-SI',
    'so-SO',
    'es-ES',
    'es-US',
    'su-ID',
    'sw-KE',
    'sw-BI',
    'sw-RW',
    'sw-TZ',
    'sw-UG',
    'sv-SE',
    'tl-PH',
    'ta-IN',
    'tt-RU',
    'te-IN',
    'th-TH',
    'tr-TR',
    'uk-UA',
    'ug-CN',
    'uz-UZ',
    'vi-VN',
    'cy-WL',
    'wo-SN',
    'zu-ZA',
];
