import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    TYPE_CREATE_RECORD,
    TYPE_FIELD_UPDATE,
    TYPE_INVOKE_URL,
    TYPE_MATCH_RECORDS,
    TYPE_SEND_EMAIL,
    TYPE_VISIT_PLANNER,
    TYPE_CODE_STATEMENT,
    TYPE_OPEN_FORM,
    TYPE_SHOW_MESSAGE,
    TYPE_OPEN_URL,
    TYPE_UPDATE_FORM,
    TYPE_DELETE_RECORD,
    TYPE_SEND_SMS,
    TYPE_COMMIT,
    TYPE_MARK_AS_VISITED,
} from '../../service/WorkflowActionManager';
import { Tooltip } from '@material-ui/core';
import i18n from '../../locales/i18n';

const t = i18n.t.bind(i18n);

export default class ActionIcon extends React.PureComponent {
    constructor(props) {
        super(props);

        this.icons = new Map([
            [
                TYPE_CREATE_RECORD,
                <Tooltip title={t('workflow_actions.form.create_record.short_title')}>
                    <i className="fas fa-file-plus" />
                </Tooltip>,
            ],
            [
                TYPE_FIELD_UPDATE,
                <Tooltip title={t('workflow_actions.form.field_update.short_title')}>
                    <i className="fas fa-file-edit" />
                </Tooltip>,
            ],
            [
                TYPE_DELETE_RECORD,
                <Tooltip title={t('workflow_actions.form.delete_record.short_title')}>
                    <i className="fas fa-file-times" />
                </Tooltip>,
            ],
            [
                TYPE_MATCH_RECORDS,
                <Tooltip title={t('workflow_actions.form.matched_records.title')}>
                    <i className="fas fa-search" />
                </Tooltip>,
            ],
            [
                TYPE_INVOKE_URL,
                <Tooltip title={t('workflow_actions.form.invoke_url.title')}>
                    <i className="fas fa-exchange" />
                </Tooltip>,
            ],
            [
                TYPE_SEND_EMAIL,
                <Tooltip title={t('workflow_actions.form.send_email.title')}>
                    <i className="fas fa-at" />
                </Tooltip>,
            ],
            [
                TYPE_SEND_SMS,
                <Tooltip title={t('workflow_actions.form.send_sms.title')}>
                    <i className="fas fa-sms" />
                </Tooltip>,
            ],
            [
                TYPE_VISIT_PLANNER,
                <Tooltip title={t('workflow_actions.form.visit_planner.title')}>
                    <i className="fas fa-route" />
                </Tooltip>,
            ],
            [
                TYPE_CODE_STATEMENT,
                <Tooltip title={t('workflow_actions.form.code_statement.title')}>
                    <i className="fas fa-code" />
                </Tooltip>,
            ],
            [
                TYPE_MARK_AS_VISITED,
                <Tooltip title={t('workflow_actions.form.mark_as_visited.title')}>
                    <i className="fas fa-check" />
                </Tooltip>,
            ],
            [
                TYPE_OPEN_FORM,
                <Tooltip title={t('workflow_actions.form.open_form.title')}>
                    <i className="fas fa-align-left" />
                </Tooltip>,
            ],
            [
                TYPE_UPDATE_FORM,
                <Tooltip title={t('workflow_actions.form.update_form.title')}>
                    <i className="fas fa-align-left" />
                </Tooltip>,
            ],
            [
                TYPE_SHOW_MESSAGE,
                <Tooltip title={t('workflow_actions.form.show_message.title')}>
                    <i className="fas fa-comment-alt" />
                </Tooltip>,
            ],
            [
                TYPE_OPEN_URL,
                <Tooltip title={t('workflow_actions.form.open_url.title')}>
                    <i className="fas fa-external-link" />
                </Tooltip>,
            ],
            [
                TYPE_COMMIT,
                <Tooltip title={t('workflow_actions.form.commit.title')}>
                    <i className="fas fa-arrow-right-arrow-left" />
                </Tooltip>,
            ],
        ]);
    }

    renderIcon = () => {
        if (this.icons.has(this.props.type)) {
            return this.icons.get(this.props.type);
        }
        return this.props.unknownTypeIcon;
    };

    render() {
        return <div style={{ marginRight: 5 }}>{this.renderIcon()}</div>;
    }
}

ActionIcon.propTypes = {
    type: PropTypes.string.isRequired,
    unknownTypeIcon: PropTypes.node,
};

ActionIcon.defaultProps = {
    unknownTypeIcon: (
        <Tooltip title={<Trans>workflow_actions.undefined_type</Trans>}>
            <i className="far fa-question-circle" />
        </Tooltip>
    ),
};
