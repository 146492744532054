import { weAreInNativeApp } from '../utils';

import { Clipboard } from '@capacitor/clipboard';

class ClipboardManager {
    public writeText(text: string): Promise<void> {
        if (weAreInNativeApp()) {
            return Clipboard.write({ string: text });
        } else {
            return navigator.clipboard.writeText(text);
        }
    }
}

export const clipboardManager = new ClipboardManager();
