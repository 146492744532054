import { makeAutoObservable } from 'mobx';
import { AccountData } from 'service/types';
import { Routing } from 'interfaces';

class RoutingSettingsDialogManager {
    private _account: AccountData | null = null;
    private _tab: Routing.Settings.Tabs = Routing.Settings.Tabs.TAB_OBJECTS;
    private _isOpen = false;
    private _loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get tab(): Routing.Settings.Tabs {
        return this._tab;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    changeTab(tab: Routing.Settings.Tabs) {
        this._tab = tab;
    }

    openModal(account: AccountData, tab: Routing.Settings.Tabs = Routing.Settings.Tabs.TAB_OBJECTS) {
        this._account = account;
        this._tab = tab;
        this._isOpen = true;
    }

    closeModal() {
        this._isOpen = false;
    }

    get account(): AccountData {
        if (this._account === null) {
            throw new Error('Routing settings dialog is not open.');
        }
        return this._account;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }
}

export default RoutingSettingsDialogManager;
