import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

export const DATA_SOURCE_OK = 'ok';
export const DATA_SOURCE_TEMP_ERROR = 'temp_error';
export const DATA_SOURCE_NOT_CONNECTED = 'not_connected';
export const DATA_SOURCE_ERROR = 'error';
export const DATA_SOURCE_DEACTIVATED = 'deactivated';

export const getDataSourceStatus = (dataSource) => {
    if (dataSource.isSystem) {
        return DATA_SOURCE_OK;
    }
    if (dataSource.isDeactivated) {
        return DATA_SOURCE_DEACTIVATED;
    }
    if (!dataSource.isSystem && dataSource.lastHttpCode === 403) {
        return DATA_SOURCE_ERROR;
    }
    if (!dataSource.isSystem && dataSource.lastHttpCode === 429) {
        return DATA_SOURCE_TEMP_ERROR;
    }
    if (!dataSource.isSystem && !dataSource.isConnected) {
        return DATA_SOURCE_NOT_CONNECTED;
    }
    return DATA_SOURCE_OK;
};

class DataSourceIndicator extends React.PureComponent {
    render() {
        const { dataSource, t } = this.props;
        const status = getDataSourceStatus(dataSource);
        if (status === DATA_SOURCE_ERROR || status === DATA_SOURCE_DEACTIVATED) {
            return (
                <Tooltip title={t('data_source.indicator.connection_lost')}>
                    <span className="connection-indicator off" />
                </Tooltip>
            );
        }
        if (status === DATA_SOURCE_TEMP_ERROR || status === DATA_SOURCE_NOT_CONNECTED) {
            return (
                <Tooltip title={t('data_source.indicator.temp_error')}>
                    <span className="connection-indicator suspended" />
                </Tooltip>
            );
        }
        return <span className="connection-indicator on" />;
    }
}

export default withTranslation('translations', { withRef: true })(DataSourceIndicator);
