import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import entityManagerFactory from '../../service/EntityManager';
import ChangesNotificationFormDialog from './ChangesNotificationFormDialog';
import './style.css';
import { withTranslation } from 'react-i18next';

class ChangesNotification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleRead = () => {
        this.setState({
            open: false,
        });
        this.props.onRead();
    };

    render() {
        const { t } = this.props;

        return (
            <div className="c-changes-notification">
                {this.props.changeNotes.length > 0 && (
                    <div className="alert">
                        <Icon style={{ marginRight: '8px' }}>report_problem</Icon>
                        <span>{t('data_source.changes_notification.text')}</span>
                        <Button
                            onClick={this.handleOpen}
                            color="primary"
                            data-testid="data_source.changes_notification.button"
                        >
                            {t('data_source.changes_notification.button')}
                        </Button>
                    </div>
                )}

                {this.state.open && (
                    <ChangesNotificationFormDialog
                        title={t('data_source.changes_notification.changes_notification_form_dialog.title', {
                            dsName: this.props.dataSource.name,
                        })}
                        onSave={this.handleRead}
                        onCancel={this.handleClose}
                        saveButtonTitle={t(
                            'data_source.changes_notification.changes_notification_form_dialog.save_button_title',
                        )}
                    >
                        <ChangesReviewingForm
                            accountId={this.props.accountId}
                            dataSourceId={this.props.dataSource.id}
                            changeNotes={this.props.changeNotes}
                        />
                    </ChangesNotificationFormDialog>
                )}
            </div>
        );
    }
}

ChangesNotification.propTypes = {
    accountId: PropTypes.number.isRequired,
    dataSource: PropTypes.object.isRequired,
    onRead: PropTypes.func,
    changeNotes: PropTypes.array.isRequired,
};

class ChangesReviewingForm extends React.Component {
    submit() {
        const { changeNotes, accountId, dataSourceId } = this.props;

        const ids = [];
        for (let versionNotes of changeNotes) {
            for (let entityNotes of versionNotes.notes) {
                for (let note of entityNotes.notes) {
                    ids.push(note.id);
                }
            }
        }

        entityManagerFactory
            .getManager(accountId, dataSourceId)
            .readChanges(ids)
            .then(() => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess();
            })
            .catch((error) => {
                this.props.onSubmitError && this.props.onSubmitError(error.message);
            });
    }

    render() {
        return (
            <div className="c-changes-reviewing-form">
                {this.props.changeNotes.map((versionNotes) => (
                    <div key={versionNotes.id}>
                        <h4>Changes from {versionNotes.createdAt}</h4>
                        {versionNotes.notes.map((entityNotes) => (
                            <div key={entityNotes.id}>
                                <h5>{entityNotes.title}</h5>
                                <ul>
                                    {entityNotes.notes.map((note) => {
                                        const c = 'type-' + note.type;
                                        return (
                                            <li key={note.id} className={c}>
                                                <div dangerouslySetInnerHTML={{ __html: note.text }}></div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

ChangesReviewingForm.propTypes = {
    accountId: PropTypes.number.isRequired,
    dataSourceId: PropTypes.number.isRequired,
    changeNotes: PropTypes.array.isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation()(ChangesNotification);
