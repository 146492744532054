import { include, reverse } from 'named-urls';
import { matchPath } from 'react-router-dom';
import { AdapterId } from 'components/types';

type FlatRoute = {
    [key: string]: string;
};

type RouteObject = {
    [key: string]: RouteObject | string;
};

const compile = (pattern: string, params: any = {}) => {
    return reverse(pattern.replace(/\([^()]*\)/g, ''), params);
};

export { compile as reverse };

const identityProviders = [
    AdapterId.PIPEDRIVE,
    AdapterId.ZOHO,
    AdapterId.HUBSPOT,
    AdapterId.SALESFORCE,
    AdapterId.KEAPCRM,
    AdapterId.SMARTSHEET,
    AdapterId.ZENDESK,
    AdapterId.DYNAMICS365,
];
const adapters = [
    AdapterId.PIPEDRIVE,
    AdapterId.ZOHO,
    AdapterId.HUBSPOT,
    AdapterId.SALESFORCE,
    AdapterId.KEAPCRM,
    AdapterId.ODOO,
    AdapterId.CREATIO,
    AdapterId.SMARTSHEET,
    AdapterId.ZENDESK,
    AdapterId.DYNAMICS365,
    AdapterId.QUICKBASE,
];

export const getRoute = (path: string, strict: boolean = true) => {
    const flatRoutes = flattenRoutes();
    for (const route in flatRoutes) {
        if (!flatRoutes.hasOwnProperty(route)) {
            continue;
        }

        const matchRoute = matchPath(path, {
            path: [flatRoutes[route]],
            exact: true,
            strict,
        });
        if (matchRoute) {
            return {
                route,
                pattern: flatRoutes[route],
            };
        }
    }
    return null;
};

export const reverseWithSearch = (pathname: string, params: any = {}, queryParams: any = {}): string => {
    if (!queryParams) {
        return compile(pathname, params);
    }

    return compile(pathname, params) + '?' + new URLSearchParams(queryParams).toString();
};

const flattenRoutes = (routesObject?: RouteObject) => {
    if (routesObject === undefined) {
        routesObject = routes;
    }
    let flatRoutes: FlatRoute = {};
    for (let i in routesObject) {
        if (!routesObject.hasOwnProperty(i)) {
            continue;
        }

        if (typeof routesObject[i] === 'object') {
            const flattenChild = flattenRoutes(routesObject[i] as RouteObject);
            for (let childKey in flattenChild) {
                if (!flattenChild.hasOwnProperty(childKey)) {
                    continue;
                }

                let routeName = i + '.' + childKey;
                while (flatRoutes[routeName] !== undefined) {
                    routeName += '_';
                }
                flatRoutes[routeName] = flattenChild[childKey];
            }
            continue;
        }

        if (typeof routesObject[i] === 'string') {
            let routeName = i;
            while (flatRoutes[routeName] !== undefined) {
                routeName += '_';
            }
            flatRoutes[routeName] = routesObject[i] as string;
        }
    }
    return flatRoutes;
};

export const routes = {
    login: '/login',
    sso: '/login/:provider(' + identityProviders.join('|') + ')',
    signup: '/signup/:provider(' + adapters.join('|') + ')/:step(\\d+)?',
    quickSignup: '/signup/:step(\\d+)?',
    client: '/',
    sharedMap: '/map/:id',
    resetPassword: '/reset_password/:token',
    oauth: '/oauth',
    oauth_final_step: '/sso',
    file: '/file/:fileId',
    analytics: '/analytics',
    admin: include('/admin', {
        accounts: 'accounts',
        leads: 'leads',
        account: include('account/:accountId(\\d+)?', {
            index: '',
            sharedMaps: 'sharedmaps',
            permissions: 'permissions',
            dataSource: include('datasource/:dataSourceId(\\d+)', {
                index: '',
                entity: 'entity/:entityId(\\d+)',
                updateJobs: 'jobs',
            }),
            search: 'search',
            log: 'log',
            eventLog: 'eventlog',
            updateEventLog: 'updateeventlog',
            billing: include('billing', {
                index: '',
                subscribe: 'subscribe',
                update: 'update',
            }),
            workflowRules: 'workflow',
            workflowActions: 'actions/:type?',
            workflowButtons: 'buttons',
            workflowSchedules: 'schedules',
            searchFilters: 'searchFilters',
        }),
    }),
};
