import i18n from 'i18next';
import { AdapterId, AdapterInfo } from 'components/types';

export function getAdapters(): AdapterInfo[] {
    return [
        {
            id: AdapterId.ZOHO,
            name: 'Zoho CRM',
            info: i18n.t('login.form.zoho_sso_asterisk'),
        },
        {
            id: AdapterId.SALESFORCE,
            name: 'SalesForce',
        },
        {
            id: AdapterId.HUBSPOT,
            name: 'HubSpot',
        },
        {
            id: AdapterId.PIPEDRIVE,
            name: 'Pipedrive',
        },
        {
            id: AdapterId.KEAPCRM,
            name: 'Keap CRM',
        },
        {
            id: AdapterId.SMARTSHEET,
            name: 'Smartsheet',
        },
        {
            id: AdapterId.ZENDESK,
            name: 'Zendesk CRM',
        },
        {
            id: AdapterId.DYNAMICS365,
            name: 'Dynamics 365',
        },
    ];
}
