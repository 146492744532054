import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Grid, RadioGroup, Radio, FormLabel } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import Hint from 'components/Hint';

interface Props extends WithTranslation {
    value: boolean;
    onValueChanged: (value?: boolean) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

const VALUE_YES = 'yes';
const VALUE_NO = 'no';

const CanJobStartEarlierInput: React.FC<Props> = (props) => {
    const { t, value, onValueChanged, error, disabled, bareInput } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value === VALUE_YES;
        onValueChanged(value).then(() => validate());
    };

    const validate = (): Promise<void> => {
        const { onInputValidation } = props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };

    const renderValue = value ? VALUE_YES : VALUE_NO;

    return (
        <Grid container>
            <Grid item xs>
                <FormControl fullWidth error={!!error} disabled={disabled} margin="dense">
                    {!bareInput && (
                        <FormLabel className="MuiInputLabel-shrink">
                            {t('routing_settings.dialog.tabs.users.form_control.can_job_start_earlier.label')}
                        </FormLabel>
                    )}
                    <RadioGroup name="canJobStartEarlier" value={renderValue} onChange={handleChange} row>
                        <FormControlLabel
                            value={VALUE_YES}
                            control={<Radio color="primary" />}
                            label={t('routing_settings.dialog.tabs.users.form_control.can_job_start_earlier.yes')}
                        />
                        <FormControlLabel
                            value={VALUE_NO}
                            control={<Radio color="primary" />}
                            label={t('routing_settings.dialog.tabs.users.form_control.can_job_start_earlier.no')}
                        />
                    </RadioGroup>
                    {!!error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
                {error && <FormHelperText>{error}</FormHelperText>}
            </Grid>
            <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                <Hint iProps={{ style: { marginLeft: 8 } }}>
                    {t('routing_settings.dialog.tabs.users.form_control.can_job_start_earlier.hint')}
                </Hint>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(CanJobStartEarlierInput);
