import React from 'react';
import { TFunction, withTranslation, WithTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import WorkingDay from './WorkingDay';
import { DefaultWorkingHours, WeekDays, WorkingHoursPerWeek } from 'components/types';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import { countWeekDaysInDateRange } from '../../../utils';

export interface WeekDayInfo {
    enable: boolean;
    name: string;
    translate: string;
    start: Date;
    end: Date;
}

export interface WeekDayList {
    [key: string]: WeekDayInfo;
}

interface WorkingDaysProps extends WithTranslation {
    workingDays: WeekDayList;
    onStartDateChanged: (date: MaterialUiPickersDate, weekDayName: string) => void;
    onEndDateChanged: (date: MaterialUiPickersDate, weekDayName: string) => void;
    onIncludeDayChanged: (weekDayName: string) => void;
    disabled?: boolean;
    timePickerVariant?: WrapperVariant;
    bareInput?: boolean;
    enableOnlyWithinRanges?: boolean;
    rangeStart?: Date;
    rangeEnd?: Date;
}

export function prepareWorkingDays(routingPreferencesWeekTimes: WorkingHoursPerWeek, t: TFunction): WeekDayList {
    const workingDays: WeekDayList = {};

    WeekDays.forEach((day) => {
        const startDate = new Date();
        const endDate = new Date();
        const weekTime = routingPreferencesWeekTimes[day] || null;
        if (weekTime !== null) {
            const { start, end } = weekTime;
            startDate.setHours(start.hours, start.minutes, 0, 0);
            endDate.setHours(end.hours, end.minutes, 0, 0);
        } else {
            const { start, end } = DefaultWorkingHours;
            startDate.setHours(start.hours, start.minutes, 0, 0);
            endDate.setHours(end.hours, end.minutes, 0, 0);
        }

        workingDays[day] = {
            name: day,
            translate: t(`route_editor.modal.routing_preferences.modal.${day}`),
            enable: weekTime !== null,
            start: startDate,
            end: endDate,
        };
    });

    return workingDays;
}

const WorkingDays: React.FC<WorkingDaysProps> = (props) => {
    const {
        t,
        workingDays,
        onStartDateChanged,
        onEndDateChanged,
        onIncludeDayChanged,
        disabled,
        timePickerVariant,
        bareInput,
        enableOnlyWithinRanges,
        rangeStart,
        rangeEnd,
    } = props;
    const workingDayElements: JSX.Element[] = [];
    const variant = timePickerVariant !== undefined ? timePickerVariant : 'inline';

    let weekDayCount = new Map<string, { count: number; date: Date }>();
    if (!disabled && enableOnlyWithinRanges && rangeStart && rangeEnd) {
        weekDayCount = countWeekDaysInDateRange(rangeStart, rangeEnd);
    }

    WeekDays.forEach((day) => {
        const info = workingDays[day];
        const weekDayCountForDay = weekDayCount.get(info.name);
        const workingDayProps: any = {
            key: info.name,
            weekDay: info,
            onStartDateChanged: onStartDateChanged,
            onEndDateChanged: onEndDateChanged,
            onIncludeDayChanged: onIncludeDayChanged,
            timePickerVariant: variant,
        };

        if (!disabled && enableOnlyWithinRanges && !weekDayCountForDay?.count) {
            workingDayProps.disabled = true;
            workingDayProps.showTooltip = t('working_hour_exceptions.add_exception_modal.working_hours_disabled_hint');
        } else {
            workingDayProps.disabled = Boolean(disabled);
            workingDayProps.showDate =
                !disabled && enableOnlyWithinRanges && weekDayCountForDay?.count === 1
                    ? weekDayCountForDay.date
                    : undefined;
        }

        workingDayElements.push(<WorkingDay {...workingDayProps} />);
    });

    return (
        <div className="c-form-dialog-route-preference__working-hours-container">
            {!bareInput && (
                <span>
                    <h4 style={{ display: 'inline' }}>
                        {t('route_editor.modal.routing_preferences.modal.working_hours_title')}
                    </h4>
                </span>
            )}
            {workingDayElements}
        </div>
    );
};

export default withTranslation()(WorkingDays);
