import { userManager } from 'service/UserManager';
import { TripModeConfig } from 'service/types';
import { defaultTripModeConfig } from 'service/MapPage/TripMode/TripModeManager';
import { logDebug } from '../../../utils';
import { STORAGE_KEY_PREFIX } from 'components/types';
import { User } from 'interfaces';

class TripModeStorage {
    save(config: TripModeConfig): void {
        const user = userManager.getCurrentUser();
        if (!user) {
            throw new Error('Try to save the trip mode config for an unauthorized user.');
        }

        const normalizedConfig = TripModeStorage.normalize(config);
        window.localStorage.setItem(TripModeStorage.getKey(user), JSON.stringify(normalizedConfig));
    }

    load(): TripModeConfig {
        const user = userManager.getCurrentUser();
        if (!user) {
            throw new Error('Try to get the trip mode config for an unauthorized user.');
        }

        let config;
        const json = window.localStorage.getItem(TripModeStorage.getKey(user));
        if (json === null) {
            config = { ...defaultTripModeConfig };
        } else {
            try {
                config = {
                    ...defaultTripModeConfig,
                    ...TripModeStorage.denormalize(JSON.parse(json)),
                };
            } catch (e) {
                logDebug(e);
                config = { ...defaultTripModeConfig };
            }
        }

        return config;
    }

    getDefaultConfig(): TripModeConfig {
        return { ...defaultTripModeConfig };
    }

    private static getKey(user: User.User): string {
        return `${STORAGE_KEY_PREFIX.TRIP_MODE_CONFIG}${user.id}`;
    }

    private static normalize(config: TripModeConfig): TripModeConfig {
        return { ...config };
    }

    private static denormalize(config: TripModeConfig): TripModeConfig {
        return { ...config };
    }
}

export default TripModeStorage;
