import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import Label from '../../../service/workflow_actions/forms/fields/Label';
import { Typography, Box } from '@material-ui/core';
import React from 'react';

class LabelField extends AbstractField {
    renderInput(field) {
        if (!field.getValue()) {
            return null;
        }

        return (
            <Typography
                className="field-label-input"
                display="inline"
                component="span"
                color="textPrimary"
                style={{ whiteSpace: 'pre-line' }}
            >
                <Box fontSize={16}>{field.getValue()}</Box>
            </Typography>
        );
    }
}

LabelField.propTypes = {
    field: PropTypes.instanceOf(Label).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default LabelField;
