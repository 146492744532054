import React from 'react';
import { DataExchangeSession } from '../../../service/DataExchangeManager';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Icon, IconButton, Slide, SlideProps } from '@material-ui/core';
import DataExchangeLogs from './DataExchangeLogs';
import { UserData } from '../../../service/types';
import { Filter } from '../../utils/tableFilter';

const Transition = React.forwardRef(function (props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DataExchangeLogModalProps extends WithTranslation {
    accountId: number;
    user: UserData;
    session: DataExchangeSession;
    onClose: () => void;
    filters?: Filter[];
}

class DataExchangeLogModal extends React.PureComponent<DataExchangeLogModalProps> {
    render() {
        return (
            <Dialog open={true} onClose={this.props.onClose} TransitionComponent={Transition} fullScreen>
                <DialogTitle className="c-form-dialog__title">
                    {this.props.t('data_exchange_log.modal.title')}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.props.onClose}
                        className="c-form-dialog__close"
                        data-testid="data_exchange_log.modal.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DataExchangeLogs
                        accountId={this.props.accountId}
                        user={this.props.user}
                        session={this.props.session}
                        filters={this.props.filters}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(DataExchangeLogModal);
