import { action, makeObservable, observable } from 'mobx';
import { routingSessionManager } from '../index';

type ConfirmReloadSessionDialogKeys = '_isOpen' | '_isProcessing';

class ConfirmReloadSessionDialog {
    private _isOpen: boolean = false;
    private _isProcessing: boolean = false;

    constructor() {
        makeObservable<ConfirmReloadSessionDialog, ConfirmReloadSessionDialogKeys>(this, {
            _isOpen: observable,
            _isProcessing: observable,

            openDialog: action,
            closeDialog: action,
        });
    }

    openDialog(): void {
        this._isOpen = true;
    }

    closeDialog(): void {
        this._isOpen = false;
    }

    async reloadSession(): Promise<void> {
        try {
            this._isProcessing = true;
            await routingSessionManager.initSession();
        } finally {
            this._isProcessing = false;
            this.closeDialog();
        }
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get isProcessing(): boolean {
        return this._isProcessing;
    }
}

export default ConfirmReloadSessionDialog;
