import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import './style.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        };
    }

    handleClick = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            shown: false,
        });
    };

    render() {
        const { type, placement, onClose, children, style, className } = this.props;

        if (!this.state.shown) {
            return null;
        }

        return (
            <div className={clsx('c-alert', type, placement, className)} style={style}>
                {onClose && (
                    <IconButton
                        aria-label={this.props.t('alert.delete.aria_label')}
                        onClick={this.handleClick}
                        data-testid="alert.delete"
                    >
                        <Icon>visibility_off</Icon>
                    </IconButton>
                )}
                <span>{children}</span>
            </div>
        );
    }
}

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'danger', 'info']),
    onClose: PropTypes.func,
    placement: PropTypes.oneOf(['modal', 'popover', 'context']),
    className: PropTypes.string,
};

Alert.defaultProps = {
    type: 'danger',
    placement: 'modal',
};

export default withTranslation()(Alert);
