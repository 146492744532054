import { userManager } from './UserManager';
import { WorkingHoursPerDay } from '../components/types';
import { WeekDayList } from '../components/UserForm/TravelingPreferences/WorkingDays';
import DateHelper from './Date/DateHelper';
import { User } from '../interfaces/user';

class WorkSchedule {
    public getTodayWorkingHours = (user?: User): WorkingHoursPerDay | null => {
        const todayDate = DateHelper.createFromDeviceDate(new Date(), user).getDate();
        return this.getWorkingHours(todayDate, user);
    };

    public getWorkingHours = (date: Date, user?: User): WorkingHoursPerDay | null => {
        if (!user) {
            user = userManager.getCurrentUser();
            if (!user) {
                return null;
            }
        }

        if (!user.routingPreferences) {
            return null;
        }

        let weekHours = this.getWorkingHoursFromExceptions(date, user);
        if (weekHours) {
            return weekHours;
        }

        const weekDay = this.getWeekDay(date, user);
        weekHours = user.routingPreferences.weekTimes[weekDay];

        if (!weekHours) {
            return null;
        }

        return weekHours;
    };

    private getWorkingHoursFromExceptions = (date: Date, user: User): WorkingHoursPerDay | null => {
        const weekDay = this.getWeekDay(date, user);

        let weekHours = null;

        for (const exception of user.routingPreferences.workingHoursExceptions) {
            const exceptionFrom = DateHelper.createFromISOString(exception.periodFrom);
            const exceptionTo = DateHelper.createFromISOString(exception.periodTo);

            if (date >= exceptionFrom.getDate() && date <= exceptionTo.getDate()) {
                if (exception.workingHours[weekDay]) {
                    weekHours = exception.workingHours[weekDay];
                }
            }
        }

        return weekHours;
    };

    isItWorkTimeNow = (user?: User) => {
        let todayWorkingHours = this.getTodayWorkingHours(user);
        if (!todayWorkingHours) {
            return false;
        }

        const userTzNow = DateHelper.createFromDeviceDate(new Date()).getDisplayDate();
        const currentHour = userTzNow.getHours();
        const currentMinute = userTzNow.getMinutes();

        if (!todayWorkingHours.start) {
            // day off
            return false;
        }

        if (todayWorkingHours.start.hours > currentHour) {
            return false;
        }
        if (todayWorkingHours.start.hours === currentHour) {
            if (todayWorkingHours.start.minutes > currentMinute) {
                return false;
            }
        }

        if (todayWorkingHours.end.hours < currentHour) {
            return false;
        }
        if (todayWorkingHours.end.hours === currentHour) {
            if (todayWorkingHours.end.minutes < currentMinute) {
                return false;
            }
        }

        return true;
    };

    isScheduleValid = (weekTimes: WeekDayList) => {
        for (const weekDay in weekTimes) {
            const day = weekTimes[weekDay];
            if (!day) {
                continue;
            }
            // @ts-ignore
            if (day.enable === false) {
                continue;
            }
            if (day.start > day.end) {
                return false;
            }
        }

        return true;
    };

    private getWeekDay(date: Date, user?: User): string {
        const dateContainer = DateHelper.createFromISOString(date.toISOString(), user);
        const userTzDate = dateContainer.getDisplayDate();
        userTzDate.setHours(0, 0, 0, 0);
        return this.getWeekDayNameByNumber(userTzDate.getDay());
    }

    private getWeekDayNameByNumber = (number: number) => {
        const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        return weekDays[number];
    };
}

export default new WorkSchedule();
