import React from 'react';

import TextField from '@material-ui/core/TextField';
import LoadingButton from '../LoadingButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { userManager } from '../../service/UserManager';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: new Map(),
            loading: false,
            forgotPasswordRequested: false,
            email: props.defaultEmail,
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
        this.setState({
            forgotPasswordRequested: false,
        });
    };

    handleRequestForgotPassword = () => {
        if (!this.state.email) {
            let errors = new Map();
            errors.set('email', this.props.t('forgot_password.errors.email'));
            this.setState({
                errors,
            });
            return;
        }

        this.setState({
            errors: new Map(),
            loading: true,
        });

        userManager
            .forgotPassword(this.state.email)
            .then(() => {
                this.setState({
                    forgotPasswordRequested: true,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    errors: error.details,
                    loading: false,
                });
            });
    };

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
        });
    };

    render() {
        const { t } = this.props;
        return (
            <Dialog open={true} onClose={this.handleClose}>
                <DialogTitle>{t('forgot_password.dialog.title')}</DialogTitle>
                <DialogContent>
                    {!this.state.forgotPasswordRequested ? (
                        <React.Fragment>
                            <DialogContentText>{t('forgot_password.dialog.content')}</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t('forgot_password.dialog.email.label')}
                                data-testid="forgot_password.dialog.email"
                                type="email"
                                required={true}
                                error={this.state.errors.has('email')}
                                helperText={this.state.errors.get('email') || ''}
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                fullWidth
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>{t('forgot_password.dialog.content.request_sent')}</React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    {!this.state.forgotPasswordRequested ? (
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={this.handleClose}
                                data-testid="forgot_password.dialog.button.cancel"
                            >
                                {t('button.cancel')}
                            </Button>
                            <LoadingButton
                                size="medium"
                                color="primary"
                                onClick={this.handleRequestForgotPassword}
                                loading={this.state.loading}
                                data-testid="forgot_password.dialog.button.request"
                            >
                                {t('forgot_password.dialog.button.request')}
                            </LoadingButton>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={this.handleClose}
                                data-testid="forgot_password.dialog.button.close"
                            >
                                {t('dialog.close')}
                            </Button>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(ForgotPassword);

ForgotPassword.propTypes = {
    defaultEmail: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
