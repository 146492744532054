import React from 'react';
import PropTypes from 'prop-types';
import AbstractField from '../../../service/workflow_actions/forms/fields/AbstractField';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Twig from 'twig';

const styles = {
    root: {
        fontSize: '1.1rem',
    },
};

export const StyledTypography = withStyles(styles)(Typography);

class AbstractFieldComponent extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleHandler = this.handleHandler.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
        this.renderAsterisk = this.renderAsterisk.bind(this);
    }

    handleChange(e) {
        this.props.onChange(this.props.field, e.target.value);
    }

    handleHandler() {
        this.props.onHandler(this.props.field);
    }

    handleValidate(e) {
        this.props.onValidate(this.props.field, e);
    }

    renderLabel(field) {
        return (
            <StyledTypography variant="h6" style={{ whiteSpace: 'pre-line' }} className="field-label">
                {field.getLabel()}
                {this.renderAsterisk(field)}
            </StyledTypography>
        );
    }

    renderDescription(field) {
        return (
            <Grid item xs={12}>
                <Typography
                    display="inline"
                    component="span"
                    color="textSecondary"
                    style={{ whiteSpace: 'pre-line' }}
                    className="field-description"
                >
                    {field.getDescription()}
                </Typography>
            </Grid>
        );
    }

    renderAsterisk(field) {
        return (
            field.getIsRequired() && (
                <Typography display="inline" component="span" color="error">
                    {' '}
                    *
                </Typography>
            )
        );
    }

    render() {
        const field = this.props.field;
        const errors = this.props.errors;
        const context = this.props.formContext;

        if (field.getCondition()) {
            const condition = Twig.twig({ data: field.getConditionAsTwig() });
            const result = condition.render(context);
            if (!result || result === 'false') {
                return null;
            }
        }

        const apiName = field.getApiName();

        return (
            <Grid item xs={12} className={`${apiName}-field-root`}>
                <Box mt={3}>
                    <Grid container>
                        {field.getLabel() && (
                            <Grid item xs={12}>
                                {this.renderLabel(field)}
                            </Grid>
                        )}
                        {field.getDescription() && this.renderDescription(field)}
                        <Grid item xs={12}>
                            {this.renderInput(field, errors)}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        );
    }
}

AbstractFieldComponent.propTypes = {
    accountId: PropTypes.number.isRequired,
    field: PropTypes.instanceOf(AbstractField).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    formContext: PropTypes.object,
};

export default AbstractFieldComponent;
