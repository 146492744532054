import dispatcher from './dispatcher';
import events from '../events';
import { MapStyles, MapSubTypes, MapTypes, TilesProviders } from '../references/tilesProviders';
import mapStorageManager from './MapStorageManager';
import { formatStyle as formatMapBoxStyle } from 'api/MapBoxGeneralApi';
import { userManager } from './UserManager';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import InternetConnectionManager, {
    FAILED_REQUEST_HANDLING_DEBOUNCE_INTERVAL,
    IS_ONLINE_REQUEST_TIMEOUT,
} from 'service/InternetConnectionManager';
import backend from '../api/BackendApi';
import { ImpersonateMode as SwitchMode } from '../api/types';

/**
 * number of tile loading errors to switch custom mapbox style to default one
 * @type {number}
 */
const FALLBACK_CUSTOM_MAP_STYLE_ERROR_COUNT = 4; // I wonder how much tiles small screens fits

const OSM_DEFAULT_REGION = [
    'AU',
    'AT',
    'BE',
    'CA',
    'DK',
    'FI',
    'FR',
    'DE',
    'GR',
    'IS',
    'IE',
    'IT',
    'JP',
    'LU',
    'NL',
    'NZ',
    'NO',
    'PT',
    'ES',
    'SE',
    'CH',
    'GB',
    'US',
];

const OSM_BANNED_REGION = ['RU', 'UA'];

class MapSettingsManager {
    constructor() {
        this.tileErrorCount = 0;
        this.tileErrorTimeout = null;
        this.customMapTypes = {};
        this.updateMapSettings(null, null);
        this.overridenClusterSize = null;
        this.disabledTilesProviders = [];
        dispatcher.subscribe(events.CLUSTER_SIZE_CHANGED, this, (size) => {
            if (this.overridenClusterSize === size) {
                return;
            }
            this.overridenClusterSize = size;
            dispatcher.dispatch(events.CLUSTERING_SETTINGS_CHANGED);
        });
    }

    init(mapSettings, mapStyles, countryCode) {
        this.customMapTypes = isEmpty(mapStyles) ? {} : mapStyles;
        this.updateMapSettings(mapSettings, countryCode);
    }

    updateMapSettings(mapSettings, countryCode) {
        if (OSM_BANNED_REGION.includes(countryCode)) {
            this.disabledTilesProviders = [TilesProviders.OSM];
        }

        if (mapSettings === null) {
            this.opacity = 1;

            if (OSM_DEFAULT_REGION.includes(countryCode) && backend.getImpersonateMode() !== SwitchMode.SETUP) {
                this.tilesProvider = TilesProviders.OSM;
                this.mapSubType = null;
            } else {
                this.tilesProvider = TilesProviders.GOOGLE;
                this.mapSubType = MapSubTypes.NO_POI;
            }

            this.mapType = MapTypes.ROADMAP;

            this.maxClusterRadius = 80;
            this.disableClusteringAtZoomEnabled = false;
            this.disableClusteringAtZoomValue = 18;
            this.spiderfyOnMaxZoom = true;

            return;
        }

        if (mapSettings.opacity !== undefined) {
            this.opacity = mapSettings.opacity;
        }
        if (mapSettings.tilesProvider !== undefined) {
            this.tilesProvider = this.isValidTileProvider(mapSettings.tilesProvider)
                ? mapSettings.tilesProvider
                : TilesProviders.GOOGLE;
        }
        if (mapSettings.mapType !== undefined) {
            this.mapType = this.isValidMapType(this.tilesProvider, mapSettings.mapType)
                ? mapSettings.mapType
                : MapTypes.ROADMAP;
        }
        if (mapSettings.maxClusterRadius !== undefined) {
            this.maxClusterRadius = mapSettings.maxClusterRadius;
        }
        if (mapSettings.disableClusteringAtZoomEnabled !== undefined) {
            this.disableClusteringAtZoomEnabled = mapSettings.disableClusteringAtZoomEnabled;
        }
        if (mapSettings.disableClusteringAtZoomValue !== undefined) {
            this.disableClusteringAtZoomValue = mapSettings.disableClusteringAtZoomValue;
        }
        if (mapSettings.spiderfyOnMaxZoom !== undefined) {
            this.spiderfyOnMaxZoom = mapSettings.spiderfyOnMaxZoom;
        }
        this.mapSubType = mapSettings.mapSubType ?? null;
    }

    setTransparency(value) {
        this.opacity = 1 - value;

        this.update(events.MAP_SETTINGS_CHANGED);
    }

    getOpacity() {
        return this.opacity;
    }

    getTransparency() {
        return 1 - this.opacity;
    }

    setType(tilesProvider, mapType, mapSubType) {
        this.tilesProvider = tilesProvider;
        this.mapType = this.isValidMapType(tilesProvider, mapType) ? mapType : MapTypes.ROADMAP;
        this.mapSubType = mapSubType;

        this.update(events.MAP_SETTINGS_CHANGED);
    }

    getCombinedType() {
        return `${this.tilesProvider}_${this.mapType}` + (this.mapSubType ? '_' + this.mapSubType : '');
    }

    getTilesProvider() {
        return this.tilesProvider;
    }

    /**
     * @returns {MapTypes|string|null}
     */
    getMapType() {
        return this.mapType;
    }

    getMapSubType() {
        return this.mapSubType;
    }

    getMapStyles() {
        return MapStyles[this.tilesProvider]?.[this.mapType]?.[this.mapSubType] || [];
    }

    /**
     * custom styles, each with different typing
     * Style is api/MapBoxGeneralApi/Style
     * @returns {{[key in TilesProviders]: Style|object|string}}
     */
    getCustomMapTypes() {
        return this.customMapTypes;
    }

    getMaxClusterRadius() {
        return this.maxClusterRadius;
    }

    getOverridenClusterRadius() {
        return this.overridenClusterSize;
    }

    setMaxClusterRadius(value) {
        this.maxClusterRadius = value;
        if (this.overridenClusterSize) {
            this.overridenClusterSize = null;
            dispatcher.dispatch(events.CLUSTER_SIZE_CHANGED, null);
        }

        this.update(events.CLUSTERING_SETTINGS_CHANGED);
    }

    getDisableClusteringAtZoomEnabled() {
        return this.disableClusteringAtZoomEnabled;
    }

    setDisableClusteringAtZoomEnabled(value) {
        this.disableClusteringAtZoomEnabled = value;

        this.update(events.CLUSTERING_SETTINGS_CHANGED);
    }

    getDisableClusteringAtZoomValue() {
        return this.disableClusteringAtZoomValue;
    }

    setDisableClusteringAtZoomValue(value) {
        this.disableClusteringAtZoomValue = value;

        this.update(events.CLUSTERING_SETTINGS_CHANGED);
    }

    getSpiderfyOnMaxZoom() {
        return this.spiderfyOnMaxZoom;
    }

    setSpiderfyOnMaxZoom(value) {
        this.spiderfyOnMaxZoom = value;

        this.update(events.CLUSTERING_SETTINGS_CHANGED);
    }

    getSettings() {
        return {
            opacity: this.opacity,
            tilesProvider: this.tilesProvider,
            mapType: this.mapType,
            mapSubType: this.mapSubType,
            maxClusterRadius: this.maxClusterRadius,
            disableClusteringAtZoomEnabled: this.disableClusteringAtZoomEnabled,
            disableClusteringAtZoomValue: this.disableClusteringAtZoomValue,
            spiderfyOnMaxZoom: this.spiderfyOnMaxZoom,
        };
    }

    getDisabledTilesProviders() {
        return this.disabledTilesProviders;
    }

    update(event) {
        dispatcher.dispatch(event);
        if (!userManager.isModificationAllowed()) {
            return;
        }
        mapStorageManager.save();
    }

    isValidTileProvider(tileProvider) {
        return Object.values(TilesProviders).includes(tileProvider);
    }

    /**
     * @param tileProvider {TilesProviders}
     * @param mapType {MapTypes|string}
     * @return boolean
     */
    isValidMapType(tileProvider, mapType) {
        if (Object.values(MapTypes).includes(mapType)) {
            return true;
        }

        if (tileProvider === TilesProviders.MAPBOX) {
            for (const style of this.customMapTypes[TilesProviders.MAPBOX] ?? []) {
                if (mapType === formatMapBoxStyle(style).mapType) {
                    return true;
                }
            }
        }

        return false;
    }

    incrementTileErrorCount() {
        this.tileErrorCount++;
        clearTimeout(this.tileErrorTimeout);

        if (this.tileErrorCount >= FALLBACK_CUSTOM_MAP_STYLE_ERROR_COUNT) {
            this.resetMapTypeIfOnline();
            return;
        }

        this.tileErrorTimeout = setTimeout(() => {
            this.tileErrorCount = 0;
        }, 10000);
    }

    // todo: make XHR tile request and switch ONLY if error's code is 4xx
    // debounce to be sure InternetConnectionManager knows about connection presence
    resetMapTypeIfOnline = debounce(
        () => {
            if (InternetConnectionManager.isOnline) {
                this.setType(this.tilesProvider, MapTypes.ROADMAP, this.mapSubType);
                // userManager.hey();
            }
        },
        FAILED_REQUEST_HANDLING_DEBOUNCE_INTERVAL + IS_ONLINE_REQUEST_TIMEOUT + 1000,
    );
}

export default new MapSettingsManager();
