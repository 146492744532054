import { fileCompressor } from '../../components/FileInput/FileCompressor';
import { weAreInIosNativeApp } from '../../utils';

export const THUMBNAIL_SIDE_SIZE = 250;

class ThumbnailService {
    createImageThumbnail(blob: Blob): Promise<Blob> {
        return fileCompressor.compress(blob, { width: THUMBNAIL_SIDE_SIZE, height: THUMBNAIL_SIDE_SIZE });
    }

    createVideoThumbnail(blob: Blob): Promise<Blob | null> {
        return new Promise<Blob | null>((resolve, reject) => {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(blob);

            const onLoaded = () => {
                video.currentTime = 0.1;
                video.onseeked = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = THUMBNAIL_SIDE_SIZE;
                    canvas.height = THUMBNAIL_SIDE_SIZE;
                    const ctx = canvas.getContext('2d');
                    if (!ctx) {
                        reject();
                        return;
                    }
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    ctx.canvas.toBlob((thumbnailBlob) => resolve(thumbnailBlob), 'image/jpeg', 0.8);
                    URL.revokeObjectURL(video.src);
                };
            };

            if (weAreInIosNativeApp()) {
                video.onloadeddata = onLoaded;
                setTimeout(() => video.load(), 200);
            } else {
                video.onloadedmetadata = onLoaded;
            }
        });
    }
}

export const thumbnailService = new ThumbnailService();
