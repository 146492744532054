import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import './style.css';
import { withTranslation } from 'react-i18next';
import icons, { IconStyle } from './icons';
import clsx from 'clsx';

class IconPicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            icons: icons.filter(({ styles }) => styles.includes(IconStyle.SOLID)),
            search: '',
        };
    }

    search = (event) => {
        const textSearch = event.currentTarget.value || '';
        const lowerCase = textSearch.toLowerCase();
        const filteredIcons = icons.filter(
            (icon) =>
                icon.name.includes(lowerCase) ||
                (icon.aliases.names ?? []).some((name) => name.toString().includes(lowerCase)) ||
                icon.categories.some((category) => category.toString().includes(lowerCase)) ||
                icon.terms.some((term) => term.toString().includes(lowerCase)),
        );
        this.setState({
            search: textSearch,
            icons: filteredIcons,
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="c-icon-picker-container">
                    <div className="c-icon-picker-select-field">
                        <TextField
                            fullWidth
                            value={this.state.search}
                            onChange={this.search}
                            placeholder={this.props.t('icon_picker.search.placeholder')}
                            data-testid="icon_picker.search"
                            autoFocus={true}
                        />
                    </div>
                    <div className="c-icon-picker-all-icons">
                        {this.state.icons.map((icon) => {
                            return (
                                <span
                                    className="c-icon-picker-icon-container"
                                    onClick={(e) => {
                                        this.props.onChange(icon.name);
                                    }}
                                    key={'icon-' + icon.name}
                                >
                                    <i className={clsx('c-icon-picker-icon icon fas', 'fa-' + icon.name)} />
                                </span>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

IconPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default withTranslation('translations', { withRef: true })(IconPicker);
