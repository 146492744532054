import { Table, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface LiveSearchLookupRecordsFormProps<T extends { id: string }> extends WithTranslation {
    records: T[];
    lookupRecords: Pick<T, 'id'>[];
    onRowClick: (record: T) => void;
    sourceField: string;
}

const TableCellCheckbox = withStyles({
    root: {
        borderBottom: 'none',
        width: 20,
    },
})(TableCell);

const TableCellName = withStyles({
    root: {
        borderBottom: 'none',
    },
})(TableCell);

class LiveSearchLookupRecordsForm<T extends Record<string, any> & { id: string }> extends React.PureComponent<
    LiveSearchLookupRecordsFormProps<T>
> {
    render() {
        const { t, records, lookupRecords, sourceField } = this.props;

        if (!records.length) {
            return <div>{t('live_update_table_form.live_search_lookup_records_form.nothing_found')}</div>;
        }

        return (
            <Table className="c-live-search-form__results">
                <TableBody>
                    {records.map((record) => {
                        const isSelected = !!lookupRecords.find((r) => r.id === record.id);

                        return (
                            <TableRow
                                key={record.id}
                                hover
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.props.onRowClick(record)}
                            >
                                <TableCellCheckbox align="left">
                                    <Checkbox checked={isSelected} color="primary" />
                                </TableCellCheckbox>
                                <TableCellName align="left">
                                    {record['objectName'] || record[sourceField]}
                                </TableCellName>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LiveSearchLookupRecordsForm);
