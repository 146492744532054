import { userManager } from 'service/UserManager';
import { UserData } from 'service/types';
import { logDebug } from '../../../utils';
import { STORAGE_KEY_PREFIX } from 'components/types';

type UserReviewStorageConfig = {
    lastShowLeaveReviewDialog: Date | null;
    lastShowCouldBeBetterDialog: Date | null;
    lastShowEnjoyingMapslyDialog: Date | null;
    lastShowEnjoyingMapslyAppDialog: Date | null;
};

export const defaultUserReviewConfig: Readonly<UserReviewStorageConfig> = {
    lastShowLeaveReviewDialog: null,
    lastShowCouldBeBetterDialog: null,
    lastShowEnjoyingMapslyDialog: null,
    lastShowEnjoyingMapslyAppDialog: null,
};

class UserReviewStorage {
    save(config: UserReviewStorageConfig): void {
        const user = userManager.getCurrentUser();
        if (!user) {
            throw new Error('Try to save the user review config for an unauthorized user.');
        }

        window.localStorage.setItem(UserReviewStorage.getKey(user), JSON.stringify(config));
    }

    load(): UserReviewStorageConfig {
        const user = userManager.getCurrentUser();
        if (!user) {
            throw new Error('Try to get the user review config for an unauthorized user.');
        }

        let config;
        const json = window.localStorage.getItem(UserReviewStorage.getKey(user));
        if (json === null) {
            config = { ...defaultUserReviewConfig };
        } else {
            try {
                config = JSON.parse(json);
                if (config.lastShowEnjoyingMapslyAppDialog !== null) {
                    config.lastShowEnjoyingMapslyAppDialog = new Date(config.lastShowEnjoyingMapslyAppDialog);
                }
                if (config.lastShowEnjoyingMapslyDialog !== null) {
                    config.lastShowEnjoyingMapslyDialog = new Date(config.lastShowEnjoyingMapslyDialog);
                }
                if (config.lastShowLeaveReviewDialog !== null) {
                    config.lastShowLeaveReviewDialog = new Date(config.lastShowLeaveReviewDialog);
                }
                if (config.lastShowCouldBeBetterDialog !== null) {
                    config.lastShowCouldBeBetterDialog = new Date(config.lastShowCouldBeBetterDialog);
                }
            } catch (e) {
                logDebug(e);
                config = { ...defaultUserReviewConfig };
            }
        }

        return config;
    }

    private static getKey(user: UserData): string {
        return `${STORAGE_KEY_PREFIX.USER_REVIEW}${user.id}`;
    }
}

export default UserReviewStorage;
