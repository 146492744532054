import React, { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ScheduleConsistencePopupManager from '../../service/BgGeo/ScheduleConsistencePopupManager';

interface state {
    showModal: boolean;
    checkboxChecked: boolean;
}

class ScheduleConsistencePopup extends React.PureComponent<WithTranslation, state> {
    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            showModal: true,
            checkboxChecked: false,
        };
    }

    handlePopupClose = () => {
        if (this.state.checkboxChecked) {
            ScheduleConsistencePopupManager.rememberPopupWasShown();
        }

        this.setState({ showModal: false });
    };

    handleDontShowAgainChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkboxChecked: e.target.checked });
    };

    render() {
        let { t } = this.props;
        let { showModal, checkboxChecked } = this.state;

        return (
            <PureFormDialog open={showModal} title="" onClose={this.handlePopupClose} maxWidth="xs" fullWidth>
                <p>{t('schedule_change_attempt_outside_app.warning')}</p>
                <FormControlLabel
                    style={{ marginBottom: 20 }}
                    control={
                        <Checkbox color="primary" checked={checkboxChecked} onChange={this.handleDontShowAgainChange} />
                    }
                    label={t('schedule_change_attempt_outside_app.checkbox_label')}
                />
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(ScheduleConsistencePopup);
