import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../events';
import dispatcher from './dispatcher';

class DsManager extends BackendService {
    constructor(accountId) {
        super();
        this.accountId = accountId;
        this.dataSources = null;
    }

    getDataSources() {
        return this.dataSources;
    }

    refreshableInfo() {
        const url = reverse(apiRoutes.account.dataSources, { accountId: this.accountId });
        return this.requestApi(url, 'GET').then((dataSources) => {
            const counters = {};
            const statuses = {};
            dataSources.forEach((ds) => {
                counters[ds.id] = ds.entityCounters;
                const entityStatuses = {};
                ds.jobStatuses.forEach((status) => {
                    entityStatuses[status.entity.id] = status.statuses;
                });
                statuses[ds.id] = entityStatuses;
            });
            return { counters, statuses };
        });
    }

    reset() {
        this.dataSources = null;
    }

    list() {
        if (this.dataSources !== null) {
            return this.constructor.getResolvedPromise(this.dataSources);
        }
        const url = reverse(apiRoutes.account.dataSources, { accountId: this.accountId });
        return this.requestApi(url, 'GET').then((dataSourcesResponse) => {
            const dataSources = this._denormalizeDataSources(dataSourcesResponse);
            this.dataSources = dataSources.sort((ds1, ds2) => ds1.isSystem - ds2.isSystem);
            return dataSources;
        });
    }

    save(dataSource, checkConnection = true, resetRecordsGeocoding = false) {
        let url =
            dataSource.id === null
                ? reverse(apiRoutes.account.dataSources, { accountId: this.accountId })
                : reverse(apiRoutes.account.dataSource, { accountId: this.accountId, dsId: dataSource.id });

        const query = [];
        if (checkConnection) {
            query.push('check=1');
        }
        if (resetRecordsGeocoding) {
            query.push('reset_records_geocoding=1');
        }
        if (query.length > 0) {
            url += '?' + query.join('&');
        }

        if (dataSource.id !== null) {
            return this.requestApi(url, 'PUT', dataSource).then((receivedDataSource) => {
                if (this.dataSources !== null) {
                    for (let i = 0; i < this.dataSources.length; i++) {
                        if (receivedDataSource.id === this.dataSources[i].id) {
                            this.dataSources[i] = receivedDataSource;
                            dispatcher.dispatch(events.EVENT_DATA_SOURCE_CHANGED, receivedDataSource);
                            break;
                        }
                    }
                }
                if (checkConnection) {
                    dispatcher.dispatch(events.EVENT_DATA_SOURCE_CONNECTED, receivedDataSource);
                }
                return receivedDataSource;
            });
        }

        return this.requestApi(url, 'POST', dataSource).then((receivedDataSource) => {
            if (this.dataSources === null) {
                this.dataSources = [];
            }
            this.dataSources.push(receivedDataSource);
            dispatcher.dispatch(events.EVENT_DATA_SOURCE_CREATED, receivedDataSource);
            if (checkConnection) {
                dispatcher.dispatch(events.EVENT_DATA_SOURCE_CONNECTED, receivedDataSource);
            }
            return receivedDataSource;
        });
    }

    load(id) {
        id = parseInt(id);
        return this.list().then((dataSources) => {
            for (let dataSource of dataSources) {
                if (dataSource.id === id) {
                    return dataSource;
                }
            }
            throw this.constructor.getError({
                code: 404,
                message: 'Unknown data source',
            });
        });
    }

    delete(id) {
        const url = reverse(apiRoutes.account.dataSource, { accountId: this.accountId, dsId: id });
        return this.requestApi(url, 'DELETE').then((dataSource) => {
            if (this.dataSources !== null) {
                for (let i = 0; i < this.dataSources.length; i++) {
                    if (id === this.dataSources[i].id) {
                        this.dataSources.splice(i, 1);
                        dispatcher.dispatch(events.EVENT_DATA_SOURCE_DELETED, dataSource);
                        break;
                    }
                }
            }
            return dataSource;
        });
    }

    update(id, entities, forceFullReimport = false) {
        const url = reverse(apiRoutes.account.dataSource, { accountId: this.accountId, dsId: id });
        return this.requestApi(url, 'PATCH', { entities, forceFullReimport }).then((dataSource) => {
            dispatcher.dispatch(events.EVENT_DATA_SOURCE_UPDATED, dataSource);
        });
    }

    getDefaultDataSource() {
        return {
            id: null,
            name: null,
            accountId: this.accountId,
            adapterId: null,
            adapterUrl: null,
            settings: null,
            credentials: null,
            isConnected: false,
            isImportEnabled: false,
            connectionToggledAt: null,
            connectionId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        };
    }

    userParams(id) {
        const url = reverse(apiRoutes.account.dataSource.userParams, { accountId: this.accountId, dsId: id });
        return this.requestApi(url, 'GET').catch(() => {
            return Promise.resolve();
        });
    }

    userCredentials(dsId) {
        const url = reverse(apiRoutes.account.dataSource.userCredentials, { accountId: this.accountId, dsId });
        return this.requestApi(url, 'GET');
    }

    saveUserCredentials(dsId, data) {
        const url = reverse(apiRoutes.account.dataSource.userCredentials, { accountId: this.accountId, dsId });
        return this.requestApi(url, 'POST', data).catch((error) => {
            if (error.status === 200 || error.status === 201) {
                return Promise.resolve();
            }

            return error;
        });
    }

    deleteUserCredentials(dsId) {
        const url = reverse(apiRoutes.account.dataSource.userCredentials, { accountId: this.accountId, dsId });
        return this.requestApi(url, 'DELETE').catch((error) => {
            if (error.status === 200 || error.status === 204) {
                return Promise.resolve();
            }

            return error;
        });
    }

    /**
     *
     * @param {IDataSource.ListDataSourcesStructureResponse[]} dataSourcesResponse
     * @return {IDataSource.DataSource[]}
     * @private
     */
    _denormalizeDataSources(dataSourcesResponse) {
        const dataSources = [];
        for (let ds of dataSourcesResponse) {
            dataSources.push({
                ...ds,
                createdAt: new Date(ds.createdAt),
            });
        }

        return dataSources;
    }
}

class Factory {
    managers = new Map();

    /**
     * @param {*} accountId
     * @returns {DsManager}
     */
    getManager(accountId) {
        accountId = parseInt(accountId);
        if (this.managers.has(accountId)) {
            return this.managers.get(accountId);
        }
        const manager = new DsManager(accountId);
        this.managers.set(accountId, manager);

        return manager;
    }
}

export default new Factory();
