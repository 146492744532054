import { RateApp } from 'capacitor-rate-app';
import BackendService from 'api/BackendService';
import { AdapterId, UserReviewSource, UserReviewSourceType } from 'components/types';
import { MOBILE_APP_REVIEW_LINKS, SSO_REVIEW_SYSTEM_LINKS } from 'components/constants';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { nativeAppFriendlyRedirect, weAreInAndroidNativeApp, weAreInIosNativeApp } from '../../../utils';
import { Common } from 'interfaces';

class UserReviewService extends BackendService {
    leaveReviewWriteReview = async (source: UserReviewSource) => {
        const url = reverse(apiRoutes.userReview.leaveReview.writeReview);
        await this.requestApi(url, 'POST');

        this.redirectToReviewPage(source);
    };

    leaveReviewRemindMeLater = async () => {
        const url = reverse(apiRoutes.userReview.leaveReview.remindMeLater);
        await this.requestApi(url, 'POST');
    };

    leaveReviewIAlreadyDidReview = async () => {
        const url = reverse(apiRoutes.userReview.leaveReview.alreadyDidReview);
        await this.requestApi(url, 'POST');
    };

    leaveReviewNoThanks = async () => {
        const url = reverse(apiRoutes.userReview.leaveReview.noThanks);
        await this.requestApi(url, 'POST');
    };

    couldBeBetter = async () => {
        const url = reverse(apiRoutes.userReview.couldBeBetter.index);
        await this.requestApi(url, 'POST');
    };

    couldBeBetterCancel = async () => {
        const url = reverse(apiRoutes.userReview.couldBeBetter.cancel);
        await this.requestApi(url, 'POST');
    };

    couldBeBetterSubmitFeedback = async (text: string) => {
        const url = reverse(apiRoutes.userReview.couldBeBetter.submitFeedback);
        await this.requestApi(url, 'POST', { text });
    };

    couldBeBetterRemindMeLater = async () => {
        const url = reverse(apiRoutes.userReview.couldBeBetter.remindMeLater);
        await this.requestApi(url, 'POST');
    };

    setHasCrmAccount = async (hasCrmAccount: boolean) => {
        const url = reverse(apiRoutes.userReview.setHasCrmAccount);
        await this.requestApi(url, 'POST', { hasCrmAccount });
    };

    private redirectToReviewPage = (source: UserReviewSource) => {
        if (source.sourceType === UserReviewSourceType.SSO) {
            this.redirectToSSOReviewPage(source.adapterId!);
        } else if (source.sourceType === UserReviewSourceType.APP) {
            this.openNativeReviewPage();
        }
    };

    private redirectToSSOReviewPage = (adapterId: AdapterId) => {
        const url = SSO_REVIEW_SYSTEM_LINKS[adapterId];
        if (url === null) {
            console.error('SSO review system link is not defined.');
            return;
        }

        nativeAppFriendlyRedirect(url);
    };

    private openNativeReviewPage = () => {
        RateApp.requestReview().catch((error: Error) => {
            console.error('RateApp requestReview error', error);
            this.redirectToAppReviewPage();
        });
    };

    private redirectToAppReviewPage() {
        let link = null;
        if (weAreInIosNativeApp()) {
            link = MOBILE_APP_REVIEW_LINKS[Common.MobilePlatform.IOS];
        } else if (weAreInAndroidNativeApp()) {
            link = MOBILE_APP_REVIEW_LINKS[Common.MobilePlatform.ANDROID];
        }
        if (link === null) {
            console.error('It is not a native app. Platform is not iOS or Android.');
            return;
        }

        nativeAppFriendlyRedirect(link);
    }
}

export default UserReviewService;
