import React from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { withTranslation, WithTranslation } from 'react-i18next';

interface AnalyticsAccessAgreementProps extends WithTranslation, WithSnackbarProps {
    profile: {
        name: string;
    };
    onSubmitSuccess: () => void;
}

class AnalyticsAccessAgreement extends React.Component<AnalyticsAccessAgreementProps> {
    // to be used in FormDialog component
    submit = () => {
        this.props.onSubmitSuccess();
    };

    render() {
        const { profile, t } = this.props;

        return (
            <form noValidate autoComplete="off">
                <div>{t('permission.analytics.access_agreement', { profileName: `"${profile.name}"` })}</div>
            </form>
        );
    }
}

export default withTranslation('translations', { withRef: true })(withSnackbar(AnalyticsAccessAgreement));
