import dispatcher from 'service/dispatcher';
import events from '../../events';
import { CalendarEvent, DRAFT_ACTION } from 'service/Calendar/CalendarEventRepository';
import { repositoryFactory } from 'service/Calendar/CalendarEventRepositoryFactory';
import { enqueueSnackbarService } from 'service/MapPage';
import { cloneDeep } from 'lodash';
import { format, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { isCalendarEventOptimizable } from 'components/Calendar/Helpers/CalendarEventHelper';
import { DATE_FORMAT_DATEFNS } from 'utils';
import { userManager } from 'service/UserManager';
import { formatInTimeZone } from 'date-fns-tz';
import i18n from 'locales/i18n';
import dateHelper from 'service/Date/DateHelper';

export enum JOB_ID_TYPE_SUBSTRING {
    DRAFT_OPERATION = 'draft_operation',
    LIST_REQUEST = 'list_request',
}

export type CalendarEventsSuccessPayload = {
    jobId: string;
    events: CalendarEvent[];
    hasDraft: boolean;
    hasSyncErrors: boolean;
};

export type UnassignedEvent = {
    eventId: string;
    reason: string;
};

export type ArrangementPayload = {
    processing: boolean;
    date: string;
    userId: number;
    error: string | null;
    unassignedJobs: UnassignedEvent[] | null;
};

export type CalendarEventsChangePayload = {
    events: CalendarEvent[];
    hasDraft: boolean;
    hasSyncErrors: boolean;
    arrangement: ArrangementPayload | null;
};

export type CalendarEventsErrorPayload = {
    jobId: string;
    error: string;
    details: { [fieldName: string]: string };
};

export type CalendarManagerSync = {
    payload: CalendarEventsSuccessPayload;
    manager: CalendarEventManager;
};

export enum ArrangementDayStatus {
    active,
    loading,
    success,
}

//TODO: add handling all error responses
export class CalendarEventManager {
    private readonly pendingResponses = new Map<string, CalendarEventsSuccessPayload>();
    private readonly pendingErrorResponses = new Map<string, CalendarEventsErrorPayload>();
    private readonly pendingRequests = new Set<string>();
    private readonly eventsById = new Map<string, CalendarEvent>();
    private expectingResponse: boolean = false;
    private from: Date | null = null;
    private to: Date | null = null;
    private ownersId: number[] = [];
    private includeDraftEvents: boolean = true;
    private hasDraftsProp: boolean = false;
    private hasSyncErrorsProp: boolean = false;
    private arrangementDays = new Map<number, Map<string, ArrangementDayStatus>>();

    constructor() {
        dispatcher.subscribe(events.WS_CALENDAR_EVENT_JOB_SUCCESS, this, this.handleCalendarEventsSuccess);
        dispatcher.subscribe(events.WS_CALENDAR_EVENTS_CHANGE, this, this.handleSuccessPayload);
        dispatcher.subscribe(events.WS_CALENDAR_EVENT_JOB_ERROR, this, this.handleCalendarEventsError);
        dispatcher.subscribe(events.CALENDAR_MANAGER_SYNC, this, this.handleCalendarManagerSync);
        dispatcher.subscribe(events.WS_CALENDAR_CHANGE, this, this.handleCalendarChange);
    }

    dispose(): void {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    private handleCalendarChange = (): void => {
        this.refreshEvents();
    };

    private handleCalendarEventsSuccess = (payload: CalendarEventsSuccessPayload): void => {
        if (this.pendingRequests.has(payload.jobId)) {
            this.pendingRequests.delete(payload.jobId);
            this.handleSuccessResponse(payload);

            return;
        }

        if (this.expectingResponse) {
            this.pendingResponses.set(payload.jobId, payload);
            return;
        }

        this.handleNotRequestedByThisManagerSuccessResponse(payload);
    };

    private handleNotRequestedByThisManagerSuccessResponse(payload: CalendarEventsSuccessPayload): void {
        if (payload.jobId.includes(JOB_ID_TYPE_SUBSTRING.LIST_REQUEST)) {
            return;
        }
        if (payload.jobId.includes(JOB_ID_TYPE_SUBSTRING.DRAFT_OPERATION)) {
            this.refreshEvents();
            return;
        }

        this.handleSuccessPayload(payload);
    }

    private handleSuccessPayload = (payload: CalendarEventsSuccessPayload | CalendarEventsChangePayload): void => {
        payload.events.forEach((event: CalendarEvent) => {
            if (event.draftAction === DRAFT_ACTION.DELETE) {
                this.eventsById.delete(event.id);
                return;
            }

            if (this.eventsById.has(event.id)) {
                this.eventsById.set(event.id, event);
                return;
            }

            if (!this.ownersId.length || !this.from || !this.to) {
                return;
            }

            if (
                !this.ownersId.includes(event.ownerId) ||
                isAfter(this.from, new Date(event.endDatetime)) ||
                isBefore(this.to, new Date(event.startDatetime))
            ) {
                return;
            }

            this.eventsById.set(event.id, event);
        });

        this.hasDraftsProp = payload.hasDraft;
        this.hasSyncErrorsProp = payload.hasSyncErrors;
        if ('arrangement' in payload && payload.arrangement) {
            this.handleArrangementChanges(payload.arrangement);
        }
        this.removeUnnecessaryArrangementDayStatuses();
        dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this });
    };

    private removeUnnecessaryArrangementDayStatuses = (): void => {
        let dayStatusDeleted = false;
        Array.from(this.arrangementDays.entries()).forEach(([userId, userDayStatus]) => {
            Array.from(userDayStatus.keys()).forEach((date: string) => {
                if (!this.from || !this.to) {
                    return;
                }
                const startDate = new Date(date);
                startDate.setHours(0, 0, 0, 0);
                const timezoneStartDate = dateHelper.createFromDeviceDate(startDate).getDate();
                const endDate = new Date(date);
                endDate.setHours(23, 59, 59, 999);
                const timezoneEndDate = dateHelper.createFromDeviceDate(endDate).getDate();

                if (isAfter(startDate, this.to) || isAfter(this.from, endDate)) {
                    return;
                }

                if (!this.dayHasOptimizableEvent(userId, timezoneStartDate, timezoneEndDate)) {
                    this.arrangementDays.get(userId)?.delete(date);
                    dayStatusDeleted = true;
                }
            });
        });
        if (dayStatusDeleted) {
            dispatcher.dispatch(events.CALENDAR_ARRANGEMENT_UPDATED, { manager: this });
        }
    };

    private handleArrangementChanges = (payload: ArrangementPayload): void => {
        const arrangementDayStatus = this.arrangementDays.get(payload.userId)?.get(payload.date);
        if (arrangementDayStatus === undefined || arrangementDayStatus === ArrangementDayStatus.active) {
            return;
        }

        this.setArrangementDayStatus(
            payload.date,
            payload.userId,
            payload.processing ? ArrangementDayStatus.loading : ArrangementDayStatus.success,
        );

        if (payload.error) {
            enqueueSnackbarService.sendCustomMessage(payload.error, { variant: 'error', persist: true });
        }

        if (payload.unassignedJobs && Array.isArray(payload.unassignedJobs) && payload.unassignedJobs.length > 0) {
            payload.unassignedJobs.forEach((unassignedEvent: UnassignedEvent) => {
                const event = this.eventsById.get(unassignedEvent.eventId);
                if (!event) {
                    return;
                }

                event.arrangementError = unassignedEvent.reason;
            });
            enqueueSnackbarService.sendCustomMessage(
                i18n.t('calendar.could_not_optimize_events', { count: payload.unassignedJobs.length }),
                {
                    variant: 'warning',
                    persist: true,
                },
            );
        }
    };

    private handleCalendarEventsError = (payload: any): void => {
        if (!this.pendingRequests.has(payload.jobId) && !this.expectingResponse) {
            return;
        }

        if (this.pendingRequests.has(payload.jobId)) {
            this.pendingRequests.delete(payload.jobId);
            this.handleErrorResponse(payload);
        } else if (this.expectingResponse) {
            this.pendingErrorResponses.set(payload.jobId, payload);
        }
    };

    private handleCalendarManagerSync = (data: CalendarManagerSync): void => {
        const { manager, payload } = data;
        if (manager === this) {
            return;
        }
        payload.events.forEach((event: CalendarEvent) => {
            if (event.draftAction === DRAFT_ACTION.DELETE) {
                this.eventsById.delete(event.id);
                return;
            }
            if (event.isExport) {
                const oldCalendarEvent = this.eventsById.get(event.id);
                this.showArrangementButtonIfNecessary(event, oldCalendarEvent);
            }

            this.eventsById.set(event.id, cloneDeep(event));
        });

        Array.from(this.eventsById.values()).forEach((event: CalendarEvent) => {
            event.isExport = false;
        });

        dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this });
    };

    private isSameOwners(ownersId: number[]): boolean {
        if (!ownersId) {
            return false;
        }
        if (this.ownersId === ownersId) {
            return true;
        }
        if (this.ownersId.length !== ownersId.length) {
            return false;
        }

        for (let i = 0, l = this.ownersId.length; i < l; i++) {
            if (this.ownersId[i] !== ownersId[i]) {
                return false;
            }
        }

        return true;
    }

    private setArrangementDayStatus(date: string, userId: number, status: ArrangementDayStatus): void {
        const oldStatus = this.arrangementDays.get(userId)?.get(date);
        if (oldStatus === status) {
            return;
        }

        let arrangementUserMap = this.arrangementDays.get(userId);
        if (!arrangementUserMap) {
            arrangementUserMap = new Map<string, ArrangementDayStatus>();
        }
        arrangementUserMap.set(date, status);

        this.arrangementDays.set(userId, arrangementUserMap);
        dispatcher.dispatch(events.CALENDAR_ARRANGEMENT_UPDATED, { manager: this });

        if (oldStatus === ArrangementDayStatus.loading && status === ArrangementDayStatus.success) {
            setTimeout(() => {
                this.arrangementDays.get(userId)?.delete(date);
                dispatcher.dispatch(events.CALENDAR_ARRANGEMENT_UPDATED, { manager: this });
            }, 3000);
        }
    }

    private showArrangementButtonForOldEventIfNecessary(
        newCalendarEvent: CalendarEvent,
        oldCalendarEvent?: CalendarEvent,
    ): void {
        if (!oldCalendarEvent) {
            return;
        }
        if (
            isSameDay(new Date(newCalendarEvent.startDatetime), new Date(oldCalendarEvent.startDatetime)) &&
            isSameDay(new Date(newCalendarEvent.endDatetime), new Date(oldCalendarEvent.endDatetime)) &&
            newCalendarEvent.ownerId === oldCalendarEvent.ownerId
        ) {
            return;
        }

        if (
            !this.dayHasOptimizableEvent(
                oldCalendarEvent.ownerId,
                new Date(oldCalendarEvent.startDatetime),
                new Date(oldCalendarEvent.endDatetime),
                [oldCalendarEvent.id],
            )
        ) {
            return;
        }

        this.setArrangementDayStatus(
            formatInTimeZone(
                new Date(oldCalendarEvent.startDatetime),
                userManager.getCurrentUser().actualTimezone,
                DATE_FORMAT_DATEFNS,
            ),
            oldCalendarEvent.ownerId,
            ArrangementDayStatus.active,
        );
    }

    private showArrangementButtonIfNecessary(newCalendarEvent: CalendarEvent, oldCalendarEvent?: CalendarEvent): void {
        this.showArrangementButtonForOldEventIfNecessary(newCalendarEvent, oldCalendarEvent);

        let dayHasOptimizableEvent = isCalendarEventOptimizable(newCalendarEvent);

        if (!dayHasOptimizableEvent) {
            dayHasOptimizableEvent = this.dayHasOptimizableEvent(
                newCalendarEvent.ownerId,
                new Date(newCalendarEvent.startDatetime),
                new Date(newCalendarEvent.endDatetime),
                [newCalendarEvent.id],
            );
        }
        if (!dayHasOptimizableEvent) {
            return;
        }

        if (oldCalendarEvent && !this.routeRelatedFieldsChanged(newCalendarEvent, oldCalendarEvent)) {
            return;
        }

        this.setArrangementDayStatus(
            formatInTimeZone(
                new Date(newCalendarEvent.startDatetime),
                userManager.getCurrentUser().actualTimezone,
                DATE_FORMAT_DATEFNS,
            ),
            newCalendarEvent.ownerId,
            ArrangementDayStatus.active,
        );
    }

    private routeRelatedFieldsChanged(newCalendarEvent: CalendarEvent, oldCalendarEvent: CalendarEvent): boolean {
        return (
            newCalendarEvent.location !== oldCalendarEvent.location ||
            newCalendarEvent.lat !== oldCalendarEvent.lat ||
            newCalendarEvent.lng !== oldCalendarEvent.lng ||
            newCalendarEvent.startDatetime !== oldCalendarEvent.startDatetime ||
            newCalendarEvent.endDatetime !== oldCalendarEvent.endDatetime ||
            newCalendarEvent.virtual !== oldCalendarEvent.virtual ||
            newCalendarEvent.fixedTime !== oldCalendarEvent.fixedTime ||
            newCalendarEvent.ownerId !== oldCalendarEvent.ownerId
        );
    }

    private dayHasOptimizableEvent(
        ownerId: number,
        startDate: Date,
        endDate: Date,
        excludeCalendarEventIds: string[] = [],
    ): boolean {
        const timezoneStartDate = dateHelper.createFromISOString(startDate.toISOString()).getDisplayDate();
        let timezoneEndDate = dateHelper.createFromISOString(endDate.toISOString()).getDisplayDate();
        if (format(timezoneEndDate, 'HH:mm') === '00:00') {
            timezoneEndDate.setHours(23, 59);
            timezoneEndDate = subDays(timezoneEndDate, 1);
        }
        const calendarEvents = Array.from(this.eventsById.values());
        for (let calendarEvent of calendarEvents) {
            if (excludeCalendarEventIds.includes(calendarEvent.id)) {
                continue;
            }
            let eventTimezoneEndDate = dateHelper.createFromISOString(calendarEvent.endDatetime).getDisplayDate();
            if (format(eventTimezoneEndDate, 'HH:mm') === '00:00') {
                eventTimezoneEndDate.setHours(23, 59);
                eventTimezoneEndDate = subDays(eventTimezoneEndDate, 1);
            }
            if (
                calendarEvent.ownerId === ownerId &&
                isCalendarEventOptimizable(calendarEvent) &&
                (isSameDay(
                    timezoneStartDate,
                    dateHelper.createFromISOString(calendarEvent.startDatetime).getDisplayDate(),
                ) ||
                    isSameDay(timezoneEndDate, eventTimezoneEndDate))
            ) {
                return true;
            }
        }

        return false;
    }

    getFrom(): Date | null {
        return this.from;
    }

    getTo(): Date | null {
        return this.to;
    }

    getOwnersId(): number[] {
        return this.ownersId;
    }

    getEvents(): CalendarEvent[] {
        return cloneDeep(Array.from(this.eventsById.values()));
    }

    hasDrafts(): boolean {
        return this.hasDraftsProp;
    }

    hasSyncErrors(): boolean {
        return this.hasSyncErrorsProp;
    }

    getArrangementDays(): Map<number, Map<string, ArrangementDayStatus>> {
        return this.arrangementDays;
    }

    async requestGetEvents(
        ownersId: number[],
        from: Date,
        to: Date,
        force: boolean = false,
        includeDraftEvents: boolean = true,
    ): Promise<string | void> {
        if (
            !force &&
            this.isSameOwners(ownersId) &&
            from.getTime() === this.from?.getTime() &&
            to.getTime() === this.to?.getTime() &&
            includeDraftEvents === this.includeDraftEvents
        ) {
            dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this });
            return;
        }

        this.ownersId = ownersId;
        this.from = from;
        this.to = to;
        this.includeDraftEvents = includeDraftEvents;
        this.eventsById.clear();

        const repository = repositoryFactory.getRepository();

        this.beforeRequest();
        return repository
            .sendGetEventsRequest(ownersId, from, to, includeDraftEvents)
            .then(({ id }) => {
                this.afterRequest(id);
                return id;
            })
            .catch(this.catchError);
    }

    async refreshEvents(): Promise<string | void> {
        if (!this.ownersId.length || !this.from || !this.to) {
            return;
        }

        return this.requestGetEvents(this.ownersId, this.from, this.to, true);
    }

    async commit(): Promise<void> {
        const repository = repositoryFactory.getRepository();
        this.beforeRequest();
        repository
            .sendCommitDraftRequest()
            .then(({ id }) => {
                this.afterRequest(id);
            })
            .catch(this.catchError);
    }

    async revert(): Promise<void> {
        const repository = repositoryFactory.getRepository();
        this.beforeRequest();
        repository
            .sendRevertDraftRequest()
            .then(({ id }) => {
                this.afterRequest(id);
            })
            .catch(this.catchError);
    }

    async deleteMultiple(calendarEvents: CalendarEvent[]): Promise<void> {
        const calendarEventsToDelete: CalendarEvent[] = [];
        calendarEvents.forEach((calendarEvent: CalendarEvent) => {
            const calendarEventToDelete: CalendarEvent = { ...calendarEvent, draftAction: DRAFT_ACTION.DELETE };
            if (!calendarEvent.draft) {
                calendarEventToDelete.draft = true;
            }

            calendarEventsToDelete.push(calendarEventToDelete);
        });

        const currentUser = userManager.getCurrentUser();

        return this.requestPutDraftEvents(calendarEventsToDelete).then(() => {
            calendarEventsToDelete.forEach((calendarEvent: CalendarEvent) => {
                this.eventsById.delete(calendarEvent.id);
                if (
                    this.dayHasOptimizableEvent(
                        calendarEvent.ownerId,
                        new Date(calendarEvent.startDatetime),
                        new Date(calendarEvent.endDatetime),
                    )
                ) {
                    this.setArrangementDayStatus(
                        formatInTimeZone(
                            new Date(calendarEvent.startDatetime),
                            currentUser.actualTimezone,
                            DATE_FORMAT_DATEFNS,
                        ),
                        calendarEvent.ownerId,
                        ArrangementDayStatus.active,
                    );
                }
            });
            dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this });
        });
    }

    async save(calendarEvents: CalendarEvent[]): Promise<string | void> {
        const calendarEventsToSave: CalendarEvent[] = [];
        calendarEvents.forEach((calendarEvent: CalendarEvent) => {
            const calendarEventToSave: CalendarEvent = {
                ...calendarEvent,
                draftAction: calendarEvent.draftAction ?? DRAFT_ACTION.CREATE,
            };
            if (!calendarEvent.draft) {
                calendarEventToSave.draft = true;
                calendarEventToSave.draftAction = DRAFT_ACTION.UPDATE;
            }

            calendarEventsToSave.push(calendarEventToSave);
        });

        return this.requestPutDraftEvents(calendarEventsToSave).then((id: string | void) => {
            calendarEventsToSave.forEach((calendarEvent: CalendarEvent) => {
                const oldCalendarEvent = this.eventsById.get(calendarEvent.id);
                this.showArrangementButtonIfNecessary(calendarEvent, oldCalendarEvent);
                this.eventsById.set(calendarEvent.id, cloneDeep(calendarEvent));
            });
            Array.from(this.eventsById.values()).forEach((event: CalendarEvent) => {
                event.isExport = false;
            });
            dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this });
            return id;
        });
    }

    requestSync(eventIds: string[]): void {
        const repository = repositoryFactory.getRepository();
        this.beforeRequest();
        repository
            .sendSyncRequest(eventIds)
            .then(({ id }) => {
                this.afterRequest(id);
            })
            .catch(this.catchError);
    }

    requestArrangement(date: string, userId: number): void {
        this.setArrangementDayStatus(date, userId, ArrangementDayStatus.loading);

        const repository = repositoryFactory.getRepository();
        this.beforeRequest();
        repository
            .sendArrangementRequest(date, userId)
            .then(({ id }) => {
                this.afterRequest(id);
            })
            .catch(this.catchError);
    }

    private async requestPutDraftEvents(drafts: CalendarEvent[]): Promise<string | void> {
        const repository = repositoryFactory.getRepository();

        this.beforeRequest();
        return repository
            .sendPutDraftEventsRequest(drafts)
            .then(({ id }) => {
                this.afterRequest(id);
                dispatcher.dispatch(events.CALENDAR_MANAGER_SYNC, {
                    payload: { events: drafts },
                    manager: this,
                });
                return id;
            })
            .catch(this.catchError);
    }

    private beforeRequest(): void {
        this.expectingResponse = true;
    }

    private afterRequest(id: string): void {
        this.pendingRequests.add(id);
        this.expectingResponse = false;

        if (this.pendingResponses.has(id)) {
            this.handleSuccessResponse(this.pendingResponses.get(id)!);
            this.pendingResponses.delete(id);
        }
        if (this.pendingErrorResponses.has(id)) {
            this.handleErrorResponse(this.pendingErrorResponses.get(id)!);
            this.pendingErrorResponses.delete(id);
        }
    }

    private handleSuccessResponse(payload: CalendarEventsSuccessPayload): void {
        if (payload.jobId.includes(JOB_ID_TYPE_SUBSTRING.DRAFT_OPERATION)) {
            this.refreshEvents();
            return;
        }

        for (const event of payload.events) {
            if (event.draftAction === DRAFT_ACTION.DELETE) {
                this.eventsById.delete(event.id);
                continue;
            }

            this.eventsById.set(event.id, event);
        }
        this.hasDraftsProp = payload.hasDraft;
        this.hasSyncErrorsProp = payload.hasSyncErrors;

        this.removeUnnecessaryArrangementDayStatuses();

        dispatcher.dispatch(events.CALENDAR_EVENTS_LIST_UPDATED, { manager: this, payload });
    }

    private handleErrorResponse(payload: CalendarEventsErrorPayload): void {
        enqueueSnackbarService.sendErrorMessage(payload.error);
    }

    private catchError = ({ message }: { message: string }): void => {
        this.expectingResponse = false;
        enqueueSnackbarService.sendErrorMessage(message);
    };
}
