import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Icon, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import PointActionButton from './PointActionButton';
import { NavigationOptionIconMap } from 'components/Shared/Menu/MapNavigationMenu';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import dispatcher from '../../service/dispatcher';
import { directActionManager } from '../../service/workflow_actions/DirectActionManager';
import geoLocationManager from '../../service/GeoLocationManager';
import { LOCATIONS, ORDER, workflowButtonManager } from '../../service/WorkflowButtonManager';
import { userManager } from '../../service/UserManager';
import { MapNavigationOption } from '../../service/types';
import events from '../../events';
import * as ReactDOM from 'react-dom';
import './style.css';
import { travelingPreferences } from 'service/UserForm';
import { nativeAppFriendlyOpenWindow, getUrlNavigateByTypeMap } from 'utils';
import { routeDesignManager, routeEditorManager, routingSessionManager } from 'service/MapPage';
import { recordManager } from '../../service/RecordManager';
import { parseWebLinkToText, parseWebLinkToUrl } from '../utils/webLinkParser';
import ToCalendarButton from '../Calendar/ToCalendarButton/ToCalendarButton';

class PointActions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showNavigateMenu: false,
            showOverflowMenu: false,
            overflowMenuAnchorEl: null,
            loadingMap: new Map(),
            addressToCopyToClipboard: null,
        };
        this.overflowButtonRef = React.createRef();
    }

    componentDidMount() {
        dispatcher.subscribe(events.WORKFLOW_ACTIONS_BUTTONS_REFRESHED, this, () => {
            typeof this.props.workflowButtonLocation !== 'undefined' && this.forceUpdate();
        });

        this.updateAddressToCopyToClipboard();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.point.entityId !== prevProps.point.entityId ||
            this.props.point.recordId !== prevProps.point.recordId
        ) {
            this.updateAddressToCopyToClipboard();
        }
    }

    updateAddressToCopyToClipboard() {
        if (!this.props.point.entityId || !this.props.point.recordId) {
            this.setState({
                addressToCopyToClipboard: '',
            });
            return;
        }

        if (this.state.addressToCopyToClipboard !== null) {
            this.setState({
                addressToCopyToClipboard: null,
            });
        }

        recordManager
            .getAddressToCopyToClipboard(this.props.point.entityId, this.props.point.recordId)
            .then((address) => {
                this.setState({
                    addressToCopyToClipboard: address,
                });
            })
            .catch(() => {
                this.setState({
                    addressToCopyToClipboard: '',
                });
            });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WORKFLOW_ACTIONS_BUTTONS_REFRESHED, this);
    }

    isEntityRecord() {
        return !!this.props.point.entityId && !!this.props.point.recordId;
    }

    hasLocation() {
        // assume (0, 0) is invalid as in commit 9602905c134fd5944184d35eb023bcb269320d62
        return (
            typeof this.props.point.lat === 'number' &&
            typeof this.props.point.lng === 'number' &&
            this.props.point.lat !== 0 &&
            this.props.point.lng !== 0
        );
    }

    canFindOnMap() {
        return this.hasLocation();
    }

    canAddToRoute() {
        return this.hasLocation();
    }

    canCopyAddress() {
        return !!this.state.addressToCopyToClipboard;
    }

    canGoogleMaps() {
        return this.hasLocation();
    }

    canNavigate() {
        return this.hasLocation();
    }

    canStreetView() {
        return this.hasLocation();
    }

    canMove() {
        return this.isEntityRecord();
    }

    toggleButtonLoading(buttonId) {
        this.setState((state) => {
            const loadingMap = new Map(state.loadingMap);
            if (loadingMap.has(buttonId)) {
                loadingMap.delete(buttonId);
            } else {
                loadingMap.set(buttonId, true);
            }
            return { loadingMap };
        });
    }

    getActions() {
        const { crmLink, extraActions, point, workflowButtonLocation, t } = this.props;
        const { mapNavigationOption } = travelingPreferences;

        const showWorkflowButtons = typeof workflowButtonLocation === 'string' && this.isEntityRecord();
        const workflowButtonsLast = showWorkflowButtons
            ? workflowButtonManager.getButtonsFor(workflowButtonLocation, ORDER.LAST, point.entityId)
            : [];
        const workflowButtonsFirst = showWorkflowButtons
            ? workflowButtonManager.getButtonsFor(workflowButtonLocation, ORDER.FIRST, point.entityId)
            : [];

        const actions = [
            {
                disabled: !this.canNavigate(),
                iconClassName: 'fas fa-directions',
                disabledTitle: t('record_action.record_has_no_location'),
                label: t('record_action.navigate'),
                onClick: this.handleNavigate,
                title: t(
                    mapNavigationOption
                        ? `record_action.navigate.tooltip.${mapNavigationOption}`
                        : 'record_action.navigate.tooltip',
                ),
            },
            ...workflowButtonsFirst.map((button) => this.mapWorkflowButton(button)),
            {
                closeAfterClick: true,
                disabled: !this.canFindOnMap(),
                disabledTitle: t('record_action.record_has_no_location'),
                iconClassName: 'fas fa-crosshairs',
                label: t('record_action.find_on_map'),
                onClick: this.handleFindOnMap,
                title: t('record_action.find_on_map.tooltip'),
            },
            ...extraActions,
            {
                disabled: !this.canStreetView(),
                disabledTitle: t('record_action.record_has_no_location'),
                iconClassName: 'fas fa-street-view',
                label: t('record_action.street_view'),
                onClick: this.handleStreetView,
                title: t('record_action.street_view.tooltip'),
            },
            {
                disabled: !this.canGoogleMaps(),
                disabledTitle: t('record_action.record_has_no_location'),
                iconClassName: 'fab fa-google',
                label: t('record_action.google_maps'),
                onClick: this.handleGoogleMaps,
                title: t('record_action.google_maps.tooltip'),
            },
            {
                disabled: !this.canCopyAddress(),
                disabledTitle: t('record_action.record_has_no_address'),
                iconClassName: 'fas fa-copy',
                label: t('record_action.copy_address'),
                onClick: this.handleCopyAddress,
                title: t('record_action.copy_address.tooltip'),
            },
            {
                iconClassName: 'fas fa-calendar-plus',
                label: t('calendar.to_calendar_button'),
                toCalendarButton: true,
            },
        ];

        if (this.props.point.mapsly_link) {
            const link = parseWebLinkToUrl(point.mapsly_link);
            actions.push({
                iconClassName: 'fas fa-external-link',
                label: parseWebLinkToText(point.mapsly_link),
                onClick: () => this.handleOpenInCrm(link),
            });
        }

        if (routeDesignManager.hasEntityPointInRoute(this.props.point)) {
            actions.push({
                disabled: !this.canAddToRoute() || routeEditorManager.isProcessing,
                disabledTitle: t('record_action.record_has_no_location'),
                iconClassName: 'fas fa-route',
                label: t('record_action.remove_route_point'),
                onClick: this.handleRemoveFromRoute,
                title: t('record_action.remove_route_point.tooltip'),
            });
        } else {
            actions.push({
                disabled: !this.canAddToRoute() || routeEditorManager.isProcessing,
                disabledTitle: t('record_action.record_has_no_location'),
                iconClassName: 'fas fa-route',
                label: t('record_action.add_route_point'),
                onClick: this.handleAddToRoute,
                title: t('record_action.add_route_point.tooltip'),
            });
        }

        if (crmLink && crmLink.url) {
            actions.push({
                disabled: false,
                iconClassName: 'fas fa-external-link',
                label: crmLink.text,
                title: crmLink.text,
                onClick: this.handleOpenExternal,
            });
        }

        if (typeof this.props.onMovePoint === 'function') {
            actions.push({
                disabled: !this.canMove(),
                disabledTitle: t('record_action.record_has_no_entity'),
                iconClassName: 'fas fa-arrows-alt',
                label: t('record_action.move_point'),
                onClick: this.handleMove,
                title: t('record_action.move_point.tooltip'),
            });
        }

        if (typeof this.props.onOpenChangeHistory === 'function') {
            actions.push({
                disabled: false,
                iconClassName: 'fas fa-history',
                label: t('record_action.change_history'),
                onClick: () => this.props.onOpenChangeHistory(),
            });
        }

        return actions.concat(workflowButtonsLast.map((button) => this.mapWorkflowButton(button)));
    }

    mapWorkflowButton(button) {
        const loading = this.state.loadingMap.get(button.id);
        return {
            disabled: loading,
            iconClassName: 'fas fa-' + button.icon,
            label: button.label,
            title: button.label,
            onClick: this.handleWorkflowButtonClick.bind(this, button),
        };
    }

    getNavigateActions() {
        const { t } = this.props;

        return Object.keys(MapNavigationOption).map((option) => ({
            disabled: !this.canNavigate(),
            iconClassName: NavigationOptionIconMap[MapNavigationOption[option]],
            disabledTitle: t('record_action.record_has_no_location'),
            label: t(`route_report.modal.open_in_${MapNavigationOption[option]}`),
            onClick: this.handleSetNavigationOption(MapNavigationOption[option]),
            title: t(`record_action.navigate.tooltip.${MapNavigationOption[option]}`),
        }));
    }

    handleActionClick = (action) => {
        return (event) => {
            action.onClick(event);
            (this.state.showOverflowMenu || this.state.showNavigateMenu) && this.handleHideMenu();
            action.closeAfterClick && typeof this.props.onCloseView === 'function' && this.props.onCloseView();
        };
    };

    handleOpenInCrm = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    handleWorkflowButtonClick(button) {
        let promise;
        this.toggleButtonLoading(button.id);

        if (button.isUserLocationAvailable) {
            promise = geoLocationManager
                .getLocationFull()
                .then((location) => this.runWorkflowActions(button, location));
        } else {
            promise = this.runWorkflowActions(button);
        }

        promise
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                this.toggleButtonLoading(button.id);
            });
    }

    runWorkflowActions(button, location = null) {
        return directActionManager.runButton(
            userManager.getCurrentAccount().id,
            button.id,
            this.props.point.entityId,
            [this.props.point.recordId],
            false,
            location ? { userLocation: location } : null,
        );
    }

    handleNavigate = () => {
        const { point } = this.props;
        const { mapNavigationOption } = travelingPreferences;
        if (mapNavigationOption) {
            nativeAppFriendlyOpenWindow(getUrlNavigateByTypeMap(point, mapNavigationOption));
        } else {
            this.setState(
                {
                    showNavigateMenu: true,
                },
                () => {
                    this.setState({
                        overflowMenuAnchorEl: ReactDOM.findDOMNode(this.overflowButtonRef.current),
                    });
                },
            );
        }
    };

    handleSetNavigationOption = (option) => () => {
        const { point } = this.props;
        travelingPreferences.setMapNavigationOption(option);

        nativeAppFriendlyOpenWindow(getUrlNavigateByTypeMap(point, option));
    };

    handleFindOnMap = () => {
        const { point } = this.props;
        this.props.onFindOnMap([point.lat, point.lng], { id: point.recordId }, point.entityId, point.address, false);
    };

    handleStreetView = () => {
        const { lat, lng } = this.props.point;
        dispatcher.dispatch(events.EVENT_OPENING_GOOGLE_STREET_VIEW, { lat, lng });
    };

    handleGoogleMaps = () => {
        const { lat, lng } = this.props.point;
        nativeAppFriendlyOpenWindow(`https://maps.google.com/?q=${lat},${lng}`);
    };

    handleAddToRoute = async () => {
        await routingSessionManager.addEntityPoint(this.props.point);
        this.forceUpdate();
    };

    handleRemoveFromRoute = async () => {
        const { entityId, recordId, lat, lng } = this.props.point;
        const designPoints = [];
        routeDesignManager.pointsArray.forEach((point) => {
            if (point.entityId === entityId && point.recordId === recordId && point.lat === lat && point.lng === lng) {
                designPoints.push(point);
            }
        });

        await routeDesignManager.deleteEntityPoints(designPoints);
        this.forceUpdate();
    };

    handleCopyAddress = () => {
        recordManager.copyAddressToClipboard(this.props.point.entityId, this.props.point.recordId).then((address) => {
            this.props.enqueueSnackbar(this.props.t('record_action.copy_address.copied', { address }), {
                variant: 'success',
            });
        });
    };

    handleOpenExternal = () => {
        const { crmLink } = this.props;
        if (crmLink && crmLink.url) {
            nativeAppFriendlyOpenWindow(this.props.crmLink.url);
        }
    };

    handleMove = () => {
        this.props.onMovePoint();
    };

    handleShowOverflowMenu = () => {
        this.setState(
            {
                showOverflowMenu: true,
            },
            () => {
                this.setState({
                    overflowMenuAnchorEl: ReactDOM.findDOMNode(this.overflowButtonRef.current),
                });
            },
        );
    };

    handleHideMenu = () => {
        this.setState((state) => {
            const { showNavigateMenu, showOverflowMenu } = state;
            if (showNavigateMenu && showOverflowMenu) {
                return {
                    showOverflowMenu: false,
                };
            }
            return {
                showNavigateMenu: false,
                showOverflowMenu: false,
                overflowMenuAnchorEl: null,
            };
        });
    };

    render() {
        const { maxButtons, t } = this.props;
        const { showNavigateMenu, showOverflowMenu, overflowMenuAnchorEl, addressToCopyToClipboard } = this.state;
        if (addressToCopyToClipboard === null) {
            return null;
        }

        const actions = this.getActions();
        const showOverflowButton = actions.length > maxButtons;

        if (showOverflowMenu || showNavigateMenu) {
            const menuActions = showNavigateMenu ? this.getNavigateActions() : actions;
            const actionKey = showNavigateMenu ? 'navigate-' : 'overflow-';
            return (
                <>
                    {typeof this.props.onCloseView === 'function' && (
                        <PointActionButton
                            iconClassName={'far fa-times'}
                            onClick={this.props.onCloseView}
                            label={t('record_action.close_view')}
                            iconFontSize="large"
                            data-testid="record_action.close_view"
                        />
                    )}
                    <PointActionButton
                        ref={this.overflowButtonRef}
                        iconClassName={'far fa-chevron-circle-down'}
                        onClick={this.handleHideMenu}
                        label={t('record_action.close_menu')}
                        iconFontSize="large"
                        data-testid="record_action.close_menu"
                    />
                    <Menu
                        marginThreshold={0}
                        disableEnforceFocus
                        getContentAnchorEl={null}
                        style={{ inset: '0 0 92px 0' }}
                        BackdropProps={{ style: { inset: '0 0 92px 0', backgroundColor: 'transparent' } }}
                        PaperProps={{ className: 'c-overflow-menu' }}
                        open={overflowMenuAnchorEl !== null}
                        anchorEl={overflowMenuAnchorEl}
                        onClose={this.handleHideMenu}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        {menuActions.map((action, index) => {
                            if (action.toCalendarButton) {
                                return (
                                    <ToCalendarButton
                                        key={action.id ?? actionKey + index}
                                        entityId={this.props.point.entityId}
                                        pointId={this.props.point.recordId}
                                    >
                                        <div>
                                            <MenuItem>
                                                <ListItemIcon style={{ minWidth: 36 }}>
                                                    <Icon
                                                        className={action.iconClassName}
                                                        style={{ overflow: 'visible' }}
                                                        color="primary"
                                                    />
                                                </ListItemIcon>
                                                <Typography noWrap>{action.label}</Typography>
                                            </MenuItem>
                                        </div>
                                    </ToCalendarButton>
                                );
                            }

                            return (
                                <Tooltip
                                    title={action.disabled ? action.disabledTitle ?? '' : action.title ?? ''}
                                    key={action.id ?? actionKey + index}
                                >
                                    <div>
                                        <MenuItem
                                            disabled={action.disabled}
                                            onClick={this.handleActionClick(action)}
                                            data-testid={'record_action.action.' + action.id}
                                        >
                                            <ListItemIcon style={{ minWidth: 36 }}>
                                                <Icon
                                                    className={action.iconClassName}
                                                    style={{ overflow: 'visible' }}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <Typography noWrap>{action.label}</Typography>
                                        </MenuItem>
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </Menu>
                </>
            );
        }

        return (
            <>
                {actions.slice(0, showOverflowButton ? maxButtons - 1 : maxButtons).map((action, index) => {
                    if (action.toCalendarButton) {
                        return (
                            <ToCalendarButton
                                key={action.id ?? index}
                                entityId={this.props.point.entityId}
                                pointId={this.props.point.recordId}
                            >
                                <PointActionButton
                                    iconClassName={action.iconClassName}
                                    label={action.label}
                                    iconFontSize="large"
                                    data-testid="record_action.action"
                                />
                            </ToCalendarButton>
                        );
                    }

                    return (
                        <Tooltip
                            title={action.disabled ? action.disabledTitle ?? '' : action.title ?? ''}
                            key={action.id ?? index}
                        >
                            <PointActionButton
                                disabled={action.disabled}
                                iconClassName={action.iconClassName}
                                onClick={this.handleActionClick(action)}
                                label={action.label}
                                iconFontSize="large"
                                data-testid="record_action.action"
                            />
                        </Tooltip>
                    );
                })}
                {showOverflowButton && (
                    <PointActionButton
                        iconClassName={'fal fa-ellipsis-v'}
                        onClick={this.handleShowOverflowMenu}
                        label={t('record_action.more')}
                        iconFontSize="large"
                        data-testid="record_action.more"
                    />
                )}
            </>
        );
    }
}

PointActions.propTypes = {
    crmLink: PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    extraActions: PropTypes.arrayOf(
        PropTypes.shape({
            closeAfterClick: PropTypes.bool,
            disabled: PropTypes.bool,
            iconClassName: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            title: PropTypes.string,
            disabledTitle: PropTypes.string,
        }).isRequired,
    ).isRequired,
    workflowButtonLocation: PropTypes.oneOf(Object.values(LOCATIONS)),
    maxButtons: PropTypes.number.isRequired,
    onCloseView: PropTypes.func,
    onFindOnMap: PropTypes.func,
    onMovePoint: PropTypes.func,
    onOpenChangeHistory: PropTypes.func,
    point: PropTypes.shape({
        address: PropTypes.string,
        addressFields: PropTypes.object,
        countryShort: PropTypes.string,
        entityId: PropTypes.number,
        lat: PropTypes.number,
        link: PropTypes.object,
        lng: PropTypes.number,
        objectName: PropTypes.string,
        recordId: PropTypes.string,
    }),
};

PointActions.defaultProps = {
    extraActions: [],
    maxButtons: 5,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(observer(PointActions)));
