import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, withStyles } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import {
    DETAIL_BOOLEAN_EXPRESSION,
    DETAIL_HUMANIZED_EXPRESSION,
    DETAIL_RANK,
    DETAIL_RANK_MIN_VALUE,
    getDetail,
} from '../../../service/WorkflowActionManager';
import Hint from '../../Hint';
import FormDialog from '../../FormDialog';
import ExpressionBuilderForm from './ExpressionBuilderForm';
import { CallContext } from '../../utils/CallContext';
import FormulaInput from '../AbstractForm/FormulaInput';
import LandingLink, { ARTICLE_FORMULA_BUILDER } from '../../HelpLink/LandingLink';

class CriteriaControl extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showCriteria: this.initShowDetail(DETAIL_BOOLEAN_EXPRESSION),
            showRank: this.initShowDetail(DETAIL_RANK),
            showRankMinValue: this.initShowDetail(DETAIL_RANK_MIN_VALUE),
            openExpressionBuilder: false,
        };
    }

    initShowDetail = (detail) => {
        return !!(this.props.action && getDetail(detail, this.props.action.details));
    };

    openFormulaEditor = (currentFormulaEditorDetail) => {
        this.setState({ openFormulaEditor: true, currentFormulaEditorDetail });
    };

    closeFormulaEditor = () => {
        this.setState({ openFormulaEditor: false, currentFormulaEditorDetail: null });
    };

    openExpressionBuilder = () => {
        if (this.props.canOpenExpressionBuilder()) {
            this.setState({ openExpressionBuilder: true });
        }
    };

    closeExpressionBuilder = () => {
        this.setState({ openExpressionBuilder: false });
    };

    handleCheckbox = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        this.setState({ [name]: value });
    };

    handleFormulaDetail = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const action = cloneDeep(this.props.action);
        if (!action.details) {
            action.details = {};
        }
        action.details[name] = value;
        this.props.onChange(action);
    };

    handleExpressionBuilderForm = (action) => {
        this.props.onChange(action);
        this.closeExpressionBuilder();
    };

    render() {
        return (
            <React.Fragment>
                <div style={{ marginTop: 20, marginBottom: 5 }}>
                    <b style={{ marginRight: 5 }}>
                        {this.props.t('workflow_actions.form.matched_records.criteria.title')}
                    </b>
                    <small>{this.props.t('workflow_actions.form.matched_records.criteria.intro')}</small>
                </div>

                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <FormControl style={{ minWidth: '230px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="showCriteria"
                                    checked={this.state.showCriteria}
                                    onChange={this.handleCheckbox}
                                    color="primary"
                                />
                            }
                            label={
                                <span>
                                    <span style={{ marginRight: 5 }}>
                                        {this.props.t('workflow_actions.form.matched_records.criteria.condition')}
                                    </span>
                                    <Hint type="tooltip">
                                        {this.props.t('workflow_actions.form.matched_records.criteria.condition.hint')}
                                    </Hint>
                                </span>
                            }
                            style={{ marginTop: '23px' }}
                        />
                    </FormControl>
                    {this.state.showCriteria && (
                        <TextField
                            label={this.props.t('workflow_actions.form.matched_records.criteria.condition.label')}
                            data-testid="workflow_actions.form.matched_records.criteria.condition"
                            fullWidth
                            multiline
                            name={DETAIL_BOOLEAN_EXPRESSION}
                            value={getDetail(DETAIL_HUMANIZED_EXPRESSION, this.props.action.details, '')}
                            onClick={this.openExpressionBuilder}
                            helperText={this.props.errors.get(DETAIL_BOOLEAN_EXPRESSION) || ''}
                            error={this.props.errors.has(DETAIL_BOOLEAN_EXPRESSION)}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.openExpressionBuilder}
                                        data-testid="workflow_actions.form.matched_records.edit"
                                    >
                                        {this.props.t('workflow_actions.form.matched_records.edit')}
                                    </Button>
                                ),
                            }}
                        />
                    )}
                </Grid>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <FormControl style={{ minWidth: '230px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="showRank"
                                    checked={this.state.showRank}
                                    onChange={this.handleCheckbox}
                                    color="primary"
                                />
                            }
                            label={
                                <span>
                                    <span style={{ marginRight: 5 }}>
                                        {this.props.t('workflow_actions.form.matched_records.criteria.rank')}
                                    </span>
                                    <Hint type="tooltip">
                                        {this.props.t('workflow_actions.form.matched_records.criteria.rank.hint')}
                                    </Hint>
                                </span>
                            }
                            style={{ marginTop: '17px' }}
                        />
                    </FormControl>
                    {this.state.showRank && (
                        <FormulaInput
                            label={this.props.t('workflow_actions.form.matched_records.criteria.rank.label')}
                            fullWidth
                            name={DETAIL_RANK}
                            value={getDetail(DETAIL_RANK, this.props.action.details, '')}
                            onChange={this.handleFormulaDetail}
                            callContext={this.props.action.callContext}
                            rawFormula
                            helperText={this.props.errors.get(DETAIL_RANK) || ''}
                            error={this.props.errors.has(DETAIL_RANK)}
                        />
                    )}
                </Grid>

                {this.state.showRank && (
                    <Grid container direction="row" alignItems="center" wrap="nowrap">
                        <FormControl style={{ paddingLeft: '30px', minWidth: '230px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="showRankMinValue"
                                        checked={this.state.showRankMinValue}
                                        onChange={this.handleCheckbox}
                                        color="primary"
                                    />
                                }
                                label={
                                    <span>
                                        <span style={{ marginRight: 5 }}>
                                            {this.props.t(
                                                'workflow_actions.form.matched_records.criteria.rank_min_value',
                                            )}
                                        </span>
                                        <Hint type="tooltip">
                                            {this.props.t(
                                                'workflow_actions.form.matched_records.criteria.rank_min_value.hint',
                                            )}
                                        </Hint>
                                    </span>
                                }
                                style={{ marginTop: '17px' }}
                            />
                        </FormControl>
                        {this.state.showRankMinValue && (
                            <FormulaInput
                                label={this.props.t(
                                    'workflow_actions.form.matched_records.criteria.rank_min_value.label',
                                )}
                                fullWidth
                                name={DETAIL_RANK_MIN_VALUE}
                                value={getDetail(DETAIL_RANK_MIN_VALUE, this.props.action.details, '')}
                                onChange={this.handleFormulaDetail}
                                callContext={this.props.action.callContext}
                                rawFormula
                                helperText={this.props.errors.get(DETAIL_RANK_MIN_VALUE) || ''}
                                error={this.props.errors.has(DETAIL_RANK_MIN_VALUE)}
                            />
                        )}
                    </Grid>
                )}

                {this.state.openExpressionBuilder && (
                    <FormDialog
                        title={
                            <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap">
                                <Grid item>
                                    {this.props.t(
                                        'workflow_actions.form.matched_records.criteria.expression_builder.title',
                                    )}
                                </Grid>
                                <Grid item>
                                    <LandingLink
                                        useLeadingIcon
                                        useTrailingIcon
                                        article={ARTICLE_FORMULA_BUILDER}
                                        style={{ fontSize: 16 }}
                                    >
                                        {this.props.t('help')}
                                    </LandingLink>
                                </Grid>
                            </Grid>
                        }
                        onCancel={this.closeExpressionBuilder}
                        onSave={this.handleExpressionBuilderForm}
                        maxWidth="lg"
                        fullWidth
                    >
                        <ExpressionBuilderForm action={this.props.action} />
                    </FormDialog>
                )}
            </React.Fragment>
        );
    }
}

CriteriaControl.propTypes = {
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    canOpenExpressionBuilder: PropTypes.func,
};
const styles = {
    textFieldLabel: {
        transform: 'translate(14px, 29px) scale(1)',
    },
};
export default withTranslation()(withStyles(styles)(CriteriaControl));
