import React from 'react';
import { fileManager } from '../../../../service/File/FileManager';
import { ConversionStatus, MapslyFile, TranscriptionStatus } from 'interfaces/file';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { CircularProgress, Icon, Box, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Transcript as TranscriptModel } from 'interfaces/file';
import copy from 'copy-to-clipboard';
import TranscriptItem from './TranscriptItem';
import dispatcher from '../../../../service/dispatcher';
import events from '../../../../events';
import Button from '@material-ui/core/Button';
import { userManager } from '../../../../service/UserManager';

interface TranscriptProps extends WithTranslation, WithSnackbarProps {
    accountId: number;
    file: MapslyFile;

    onRewind: (time: number) => void;
    onTranscriptUpdate: (status: TranscriptionStatus) => void;
}

interface TranscriptState {
    loading: boolean;
    loadingMore: boolean;
    loadingComplete: boolean;

    limit: number;
    offset: number;
    items: TranscriptModel.TranscriptItem[];
    speakers: Map<number, TranscriptModel.TranscriptSpeaker>;

    editMode: boolean;
}

class Transcript extends React.Component<TranscriptProps, TranscriptState> {
    loadingRequest: Promise<void> | null = null;

    constructor(props: TranscriptProps) {
        super(props);

        this.state = {
            loading: this.isCompleted(),
            loadingMore: false,
            loadingComplete: false,

            limit: 50,
            offset: 0,
            items: [],
            speakers: new Map(),

            editMode: false,
        };
    }

    componentDidMount() {
        if (this.isCompleted()) {
            Promise.all([this.loadTranscript(), this.loadSpeakers()]).finally(() => {
                this.setState({ loading: false });
            });
        } else {
            dispatcher.subscribe(events.WS_FILE_TRANSCRIPTION, this, (status: any) => {
                if (status.fileId === this.props.file.id) {
                    this.props.onTranscriptUpdate(status.status as TranscriptionStatus);
                    Promise.all([this.loadTranscript(), this.loadSpeakers()]).finally(() => {
                        this.setState({ loading: false });
                    });
                }
            });
        }
    }

    isCompleted = () => {
        return this.props.file.transcriptionStatus === TranscriptionStatus.Completed;
    };

    loadTranscript = () => {
        if (!!this.loadingRequest || this.state.loadingComplete) {
            return Promise.resolve();
        }

        this.setState({ loadingMore: true });

        this.loadingRequest = fileManager
            .getTranscript(this.props.accountId, this.props.file.id!)
            .then((response) => {
                this.setState((state) => ({
                    items: [...state.items, ...response],
                    offset: state.offset + state.limit,
                    loadingComplete: response.length < this.state.limit,
                    loadingMore: false,
                }));
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                this.loadingRequest = null;
            });

        return this.loadingRequest;
    };

    loadSpeakers = () => {
        return fileManager
            .getTranscriptSpeakers(this.props.accountId, this.props.file.id!)
            .then((response) => {
                const speakers = new Map();
                for (const speaker of response) {
                    speakers.set(speaker.id, speaker);
                }
                this.setState({ speakers });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    updateItem = (item: TranscriptModel.TranscriptItem) => {
        return fileManager
            .updateTranscriptItem(this.props.accountId, this.props.file.id!, item)
            .then((item) => {
                this.setState((state) => {
                    const items = [...state.items];
                    items[item.ordinalNumber] = item;
                    return { items };
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    addNewItem = (ordinalNumber: number, parent: TranscriptModel.TranscriptItem) => {
        this.setState((state) => {
            const items = [...state.items];
            items.splice(ordinalNumber, 0, { speaker: parent.speaker, time: parent.time, content: '', ordinalNumber });
            return { items };
        });
    };

    toggleEditMode = () => {
        this.setState((state) => ({ editMode: !state.editMode }));
    };

    handleCopy = () => {
        const transcript = this.state.items.map((item) => {
            return `- ${item.speaker.label}: "${item.content}"`;
        });
        copy(transcript.join('\n'));
    };

    renderContent = () => {
        const { file } = this.props;

        if (this.state.loading) {
            return (
                <div className="transcript__content__status-message">
                    <CircularProgress size={20} style={{ marginRight: '5px' }} />
                    {this.props.t('loading')}
                </div>
            );
        }

        switch (file.transcriptionStatus) {
            case TranscriptionStatus.Completed:
                return this.renderItems();
            case TranscriptionStatus.Error:
                return this.renderError();
            case TranscriptionStatus.Disabled:
                return this.renderDisabled();
            default:
                return this.renderInProgress();
        }
    };

    renderItems = () => {
        return (
            <div>
                {this.state.items.length ? (
                    <div>
                        {this.state.items.map((item, index) => (
                            <TranscriptItem
                                item={item}
                                ordinalNumber={index}
                                speakers={this.state.speakers}
                                onRewind={this.props.onRewind}
                                onAddItem={this.addNewItem}
                                onUpdate={this.updateItem}
                                editMode={this.state.editMode}
                                key={item.id || '_' + index}
                            />
                        ))}
                    </div>
                ) : (
                    <div>{this.props.t('file_input.transcript.empty')}</div>
                )}
            </div>
        );
    };

    renderInProgress = () => {
        return (
            <div className="transcript__content__status-message">
                <CircularProgress size={20} style={{ marginRight: '5px' }} />
                {this.props.t('file_input.transcript.in_progress')}
            </div>
        );
    };

    renderDisabled = () => {
        return (
            <div className="transcript__content__status-message">{this.props.t('file_input.transcript.disabled')}</div>
        );
    };

    renderError = () => {
        return (
            <Box className="transcript__content__status-message" flexDirection="column">
                <Box display="flex" alignItems="center" mb={1}>
                    <Icon className="fa-light fa-triangle-exclamation" style={{ marginRight: '5px' }} />
                    {this.props.t('file_input.transcript.error')}
                </Box>
                <div>{this.props.file.error}</div>
                {this.props.file.conversionStatus === ConversionStatus.Completed && (
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            onClick={this.handleRetryTranscription}
                            color="primary"
                            data-testid="file_input.summary.retry_transcription"
                        >
                            {this.props.t('file_input.summary.retry_transcription')}
                        </Button>
                    </div>
                )}
            </Box>
        );
    };

    handleRetryTranscription = () => {
        fileManager
            .retryTranscription(userManager.getCurrentUser().accountId, this.props.file.id as string)
            .then(() => {
                this.props.enqueueSnackbar(
                    this.props.t('entity_data_table.table_context_menu.transcription_has_retried_successfully'),
                    { variant: 'success' },
                );
            })
            .catch((error: Error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    render() {
        return (
            <div className="transcript">
                <Grid container className="transcript__header" justify="space-between" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" alignItems="center">
                            <Typography variant="h6">{this.props.t('file_input.transcript.title')}</Typography>
                            <Tooltip title={this.props.t('file_input.transcript.copy')}>
                                <div>
                                    <IconButton onClick={this.handleCopy}>
                                        <Icon className="fa-light fa-copy" fontSize="small" />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Button size="small" variant="outlined" onClick={ this.toggleEditMode }>*/}
                    {/*        { this.props.t('file_input.transcript.edit_transcript') }*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                </Grid>

                <div className="transcript__content">{this.renderContent()}</div>
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(withSnackbar(Transcript));
