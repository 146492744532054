import moment from 'moment';
import { isString } from 'lodash';
import { WorkingHoursException } from '../types';
import { formatRangeWithYearFromIso } from '../../utils';
import i18n from '../../locales/i18n';

type ValidationRules = {
    [key: string]: ValidationRule;
};

type ValidationRule = {
    required?: boolean;
    customValidationFunc?: (
        errors: Map<string, string>,
        workingHoursException: WorkingHoursException,
        workingHoursExceptions: WorkingHoursException[],
    ) => string | null;
};

const t = i18n.t.bind(i18n);

const validationRules: ValidationRules = {
    periodFrom: {
        required: true,
        customValidationFunc: (
            errors: Map<string, string>,
            workingHoursException: WorkingHoursException,
            _: WorkingHoursException[],
        ): string | null => {
            const inPastError = checkNotInPast(new Date(workingHoursException.periodFrom));
            if (inPastError !== null) {
                return inPastError;
            }

            if (errors.has('periodTo')) {
                return null;
            }

            return timeCustomValidationFunc(workingHoursException);
        },
    },
    periodTo: {
        required: true,
        customValidationFunc: (
            errors: Map<string, string>,
            workingHoursException: WorkingHoursException,
            _: WorkingHoursException[],
        ): string | null => {
            const inPastError = checkNotInPast(new Date(workingHoursException.periodTo));
            if (inPastError !== null) {
                return inPastError;
            }

            if (errors.has('periodFrom')) {
                return null;
            }

            return timeCustomValidationFunc(workingHoursException);
        },
    },
    period: {
        customValidationFunc: (
            _: Map<string, string>,
            workingHoursException: WorkingHoursException,
            workingHoursExceptions: WorkingHoursException[],
        ): string | null => {
            let conflictingExceptionsRanges: string = '';
            workingHoursExceptions.forEach((exception: WorkingHoursException) => {
                if (exception.id === workingHoursException.id) {
                    return;
                }

                if (
                    (workingHoursException.periodFrom &&
                        moment(workingHoursException.periodFrom).isBetween(
                            exception.periodFrom,
                            exception.periodTo,
                            'day',
                            '[]',
                        )) ||
                    (workingHoursException.periodTo &&
                        moment(workingHoursException.periodTo).isBetween(
                            exception.periodFrom,
                            exception.periodTo,
                            'day',
                            '[]',
                        ))
                ) {
                    conflictingExceptionsRanges +=
                        formatRangeWithYearFromIso(exception.periodFrom, exception.periodTo, t) + ', ';
                }
            });

            conflictingExceptionsRanges = conflictingExceptionsRanges.trim().replace(/,$/g, '');

            return conflictingExceptionsRanges ? conflictingExceptionsRanges : null;
        },
    },
    workingHours: {
        customValidationFunc: (_: Map<string, string>, workingHoursException: WorkingHoursException): string | null => {
            for (const day in workingHoursException.workingHours) {
                const dayValue = workingHoursException.workingHours[day];
                if (dayValue == null) {
                    continue;
                }
                if (!dayValue.start || !dayValue.end) {
                    return 'validation.value.invalid';
                }

                if (
                    dayValue.start.hours > dayValue.end.hours ||
                    (dayValue.start.hours === dayValue.end.hours && dayValue.start.minutes > dayValue.end.minutes)
                ) {
                    return 'validation.value.invalid';
                }
            }

            return null;
        },
    },
    breakEarliestTime: {
        customValidationFunc: (
            errors: Map<string, string>,
            workingHoursException: WorkingHoursException,
            _: WorkingHoursException[],
        ): string | null => {
            if (errors.has('breakLatestTime')) {
                return null;
            }

            const { breakEarliestTime, breakLatestTime } = workingHoursException;

            if (!breakEarliestTime || !breakLatestTime) {
                return null;
            }

            return breakLatestTime < breakEarliestTime ? 'validation.value.max' : null;
        },
    },
    breakLatestTime: {
        customValidationFunc: (
            errors: Map<string, string>,
            workingHoursException: WorkingHoursException,
            _: WorkingHoursException[],
        ): string | null => {
            if (errors.has('breakEarliestTime')) {
                return null;
            }

            const { breakEarliestTime, breakLatestTime } = workingHoursException;

            if (!breakEarliestTime || !breakLatestTime) {
                return null;
            }

            return breakLatestTime < breakEarliestTime ? 'validation.value.min' : null;
        },
    },
};

const timeCustomValidationFunc = (workingHoursException: WorkingHoursException): string | null => {
    const { periodFrom, periodTo } = workingHoursException;

    if (!periodFrom || !periodTo) {
        return null;
    }

    if (moment(periodFrom).isAfter(periodTo, 'day')) {
        return 'working_hour_exceptions.add_exception_modal.error.from_bigger_than_to';
    }

    return null;
};

const checkNotInPast = (date: Date): string | null => {
    if (moment().isAfter(date, 'day')) {
        return 'working_hour_exceptions.add_exception_modal.error.date_should_not_be_in_past';
    }

    return null;
};

export const validateOne = (
    name: keyof ValidationRules,
    errors: Map<string, string>,
    workingHoursException: WorkingHoursException,
    workingHoursExceptions: WorkingHoursException[],
): Map<string, string> => {
    if (!validationRules[name]) {
        return errors;
    }

    let error: string | null = null;

    const validationRule = validationRules[name];
    if (validationRule.required) {
        const value: any = workingHoursException[name as keyof WorkingHoursException];
        if (!value || (isString(value) && value.trim() === '')) {
            error = 'working_hour_exceptions.add_exception_modal.error.should_not_be_empty';
        }
    }

    if (error) {
        errors.set(name as string, error);

        return errors;
    }

    if (!validationRule.customValidationFunc) {
        return errors;
    }

    error = validationRule.customValidationFunc(errors, workingHoursException, workingHoursExceptions);
    if (error) {
        errors.set(name as string, error);

        return errors;
    }

    errors.delete(name as string);

    return errors;
};

export const validateForm = (
    errors: Map<string, string>,
    workingHoursException: WorkingHoursException,
    workingHoursExceptions: WorkingHoursException[],
): Map<string, string> => {
    for (let name in validationRules) {
        errors = validateOne(name, errors, workingHoursException, workingHoursExceptions);
    }

    return errors;
};
