import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import './style.css';
import TabPanel from '../../TabPanel';
import LandingLink, { ARTICLE_CALENDAR_SETTINGS } from 'components/HelpLink/LandingLink';
import PureFormDialog from '../../PureFormDialog';
import { Tabs as CalendarTabs } from 'interfaces/calendar/settings';
import CalendarsTabPanel from './TabPanels/CalendarsTabPanel/CalendarsTabPanel';
import { withMediaQuery } from '../../utils/withMediaQuery';
import { Theme } from '@material-ui/core/styles/createTheme';
import CalendarsTabPanelUserGroups from './TabPanels/CalendarTabUserGroups/CalendarsTabPanelUserGroups';

interface Props extends WithTranslation {
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
}

interface State {
    tab: CalendarTabs;
}

class CalendarSettingsDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tab: CalendarTabs.TAB_CALENDARS,
        };
    }

    render() {
        const { t, isOpen, isMobile } = this.props;
        const { tab } = this.state;

        return (
            <PureFormDialog
                title={
                    <div className={'title'}>
                        <div>{t('calendar_settings.dialog.title')}</div>
                        <div className={'help'}>
                            <LandingLink article={ARTICLE_CALENDAR_SETTINGS} useLeadingIcon useTrailingIcon>
                                {t('help')}
                            </LandingLink>
                        </div>
                    </div>
                }
                open={isOpen}
                onClose={this.props.onClose}
                className="c-calendar-settings-dialog"
                maxWidth="md"
                fullWidth
                fullScreen={isMobile}
                noDividers={true}
                scroll="paper"
            >
                <Tabs indicatorColor="primary" value={tab} onChange={this.handleChangeTab}>
                    <Tab label={t('calendar_settings.dialog.tabs.calendars')} />
                    <Tab label={t('calendar_settings.dialog.tabs.user_groups')} />
                </Tabs>
                <Box pb={4}>
                    <TabPanel value={tab} index={CalendarTabs.TAB_CALENDARS}>
                        <CalendarsTabPanel />
                    </TabPanel>
                    <TabPanel value={tab} index={CalendarTabs.TAB_USER_GROUPS}>
                        <CalendarsTabPanelUserGroups />
                    </TabPanel>
                </Box>
            </PureFormDialog>
        );
    }

    private handleChangeTab = (_: React.ChangeEvent<{}>, tab: number) => {
        this.setState({ tab });
    };
}

export default withTranslation()(
    withMediaQuery([
        {
            name: 'isMobile',
            query: (theme: Theme) => theme.breakpoints.down('xs'),
        },
    ])(CalendarSettingsDialog),
);
