import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldPolymorphicLookupData, PicklistValue } from 'components/types';
import LookupConstantInput from './LookupConstantInput';
import SinglePicklistConstantInput from './SinglePicklistConstantInput';

interface Props extends WithTranslation {
    value: string | null;
    lookupData: FieldPolymorphicLookupData;
    onChangeEntity: (entity: string) => void;
    onShowSearch: () => void;
}

interface States {
    entity: string | null;
}

class LookupPolymorphicConstantInput extends React.PureComponent<Props, States> {
    constructor(props: Props) {
        super(props);

        this.state = {
            entity: null,
        };
    }

    render() {
        const { value, lookupData, onShowSearch } = this.props;
        const picklist = this.preparePicklist(lookupData);

        return (
            <React.Fragment>
                <SinglePicklistConstantInput
                    picklist={picklist}
                    value={this.state.entity}
                    onChangeSelect={this.onChangeEntity}
                />
                {this.state.entity !== null && <LookupConstantInput value={value} onShowSearch={onShowSearch} />}
            </React.Fragment>
        );
    }

    private onChangeEntity = (entity: string) => {
        this.setState({ entity }, () => {
            this.props.onChangeEntity(entity);
        });
    };

    private preparePicklist = (lookupData: FieldPolymorphicLookupData): PicklistValue[] => {
        const result: PicklistValue[] = [];
        for (let entityName of lookupData.entities) {
            result.push({
                value: entityName,
                label: entityName,
            });
        }
        return result;
    };
}

export default withTranslation()(LookupPolymorphicConstantInput);
