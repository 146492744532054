import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid, Link, Popover, TableCell, TableRow, withStyles } from '@material-ui/core';
import { TableColumnName } from './index';
import cloneDeep from 'lodash/cloneDeep';
import { Routing, User } from '../../interfaces';
import { userManager } from '../../service/UserManager';
import Button from '@material-ui/core/Button';
import ReturnToFinishLocationInput from '../UserForm/TravelingPreferences/ReturnToFinishLocationInput';
import MeetingDurationInput from '../UserForm/TravelingPreferences/MeetingDurationInput';
import MaxJobsInput from 'components/UserForm/TravelingPreferences/MaxJobsInput';
import MinJobsInput from '../UserForm/TravelingPreferences/MinJobsInput';
import MaxActivitiesInput from '../UserForm/TravelingPreferences/MaxActivitiesInput';
import MaxDrivingTimeInput from '../UserForm/TravelingPreferences/MaxDrivingTimeInput';
import MaxDistanceInput from '../UserForm/TravelingPreferences/MaxDistanceInput';
import HasSkillsInput from '../UserForm/TravelingPreferences/HasSkillsInput';
import VehicleTypeInput from '../UserForm/TravelingPreferences/VehicleTypeInput';
import BreakTypeInput from '../UserForm/TravelingPreferences/BreakTypeInput';
import DepartureDelayInput from '../UserForm/TravelingPreferences/DepartureDelayInput';
import {
    BreakTypeEnum,
    ShowEarlyLateTags,
    USER_ROUTING_PREFERENCES_FIELD_LIST,
    UserDataRoutePreferences,
} from '../../service/types';
import BreakEarliestTimeInput from '../UserForm/TravelingPreferences/BreakEarliestTimeInput';
import BreakLatestTimeInput from '../UserForm/TravelingPreferences/BreakLatestTimeInput';
import BreakDurationInput from '../UserForm/TravelingPreferences/BreakDurationInput';
import ManualLocationInput from '../RouteEditor/RouteForm/ManualLocationInput';
import GeoPointTransformer from '../../service/GeoPointTransformer';
import WorkingDays from '../UserForm/TravelingPreferences/WorkingDays';
import {
    preparePreferencesResult,
    prepareUserRoutePreferences,
    UserRoutePreferences,
} from '../UserForm/TravelingPreferences/TravelingPreferencesForm';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import WorkSchedule from '../../service/WorkSchedule';
import LocationModal from '../RoutingTodayUsersStartModal/LocationModal';
import DottedLink from '../DottedLink';
import CanJobStartEarlierInput from '../UserForm/TravelingPreferences/CanJobStartEarliernput';
import AllowedOvertimeInput from 'components/UserForm/TravelingPreferences/AllowedOvertimeInput';
import { UserLocationPoint } from 'interfaces/routing/route';
import ShowBooleanInput from 'components/UserForm/CalendarPreferences/ShowBooleanInput';
import SelectTypeInput from 'components/UserForm/CalendarPreferences/SelectTypeInput';

const LeftStickyCell = withStyles({
    root: {
        left: 0,
        position: 'sticky',
        backgroundColor: '#fff',
        whiteSpace: 'nowrap',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
})(TableCell);

interface Props extends WithTranslation {
    user: User.User;
    selected: boolean;
    onSelect: (user: User.User) => void;
    onChange: (user: User.User) => void;
    getRowCellValueAsString: (user: User.User, column: TableColumnName) => string;
}

interface State {
    editPopupAnchorEl: HTMLElement | null;
    editPopupColumn: TableColumnName | null;
    editedUserPreferences: UserRoutePreferences;
    errors: Map<string, string | null>;
    showLocationModal: boolean;
    locationModalPoint: Routing.Route.UserLocationPoint | null;
}

class UserRow extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editPopupColumn: null,
            editPopupAnchorEl: null,
            errors: new Map(),
            editedUserPreferences: prepareUserRoutePreferences(props.user.routingPreferences, props.t),
            showLocationModal: false,
            locationModalPoint: null,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.user.routingPreferences !== prevProps.user.routingPreferences) {
            this.setState({
                editedUserPreferences: prepareUserRoutePreferences(this.props.user.routingPreferences, this.props.t),
            });
        }
    }

    handleUserSelected = (): void => {
        this.props.onSelect(this.props.user);
    };

    openEditPopup = (element: HTMLElement, column: TableColumnName) => {
        this.setState({
            editPopupColumn: column,
            editPopupAnchorEl: element,
            editedUserPreferences: prepareUserRoutePreferences(this.props.user.routingPreferences, this.props.t),
        });
    };

    closeEditPopup = () => {
        this.setState({
            editPopupColumn: null,
            editPopupAnchorEl: null,
            editedUserPreferences: prepareUserRoutePreferences(this.props.user.routingPreferences, this.props.t),
            errors: new Map(),
        });
    };

    saveEditPopup = () => {
        const editedUser = cloneDeep(this.props.user);
        editedUser.routingPreferences = preparePreferencesResult(this.state.editedUserPreferences);
        this.props.onChange(editedUser);
        this.setState({
            editPopupColumn: null,
            editPopupAnchorEl: null,
            editedUserPreferences: prepareUserRoutePreferences(editedUser.routingPreferences, this.props.t),
            errors: new Map(),
        });
    };

    onInputValidation = (field: string, isValid: boolean, error?: string): Promise<void> => {
        return new Promise((resolve) => {
            const editedUserPreferences = this.state.editedUserPreferences;
            const errors = new Map(this.state.errors);

            if (isValid) {
                if (errors.has(field)) {
                    errors.delete(field);
                }
            } else {
                const transformedError = error === undefined ? null : error;
                if (errors.get(field) !== transformedError) {
                    errors.set(field, transformedError);
                }
            }

            switch (field) {
                case 'breakEarliestTime':
                case 'breakLatestTime':
                    if (
                        !errors.has('breakLatestTime') &&
                        !errors.has('breakEarliestTime') &&
                        editedUserPreferences.breakLatestTime !== null &&
                        editedUserPreferences.breakEarliestTime !== null &&
                        editedUserPreferences.breakLatestTime < editedUserPreferences.breakEarliestTime
                    ) {
                        errors.set('breakLatestTime', null);
                    }
                    break;
            }

            this.setState({ errors }, resolve);
            return;
        });
    };

    validateWeekTimes = () => {
        this.onInputValidation('weekTimes', WorkSchedule.isScheduleValid(this.state.editedUserPreferences.workingDays));
    };

    handleStartDateChanged = (date: MaterialUiPickersDate, day: string) => {
        this.setState((prev) => {
            const { workingDays } = prev.editedUserPreferences;
            workingDays[day].start.setHours(date!.getHours(), date!.getMinutes(), 0, 0);

            return {
                editedUserPreferences: { ...prev.editedUserPreferences, workingDays },
            };
        }, this.validateWeekTimes);
    };

    handleEndDateChanged = (date: MaterialUiPickersDate, day: string) => {
        this.setState((prev) => {
            const { workingDays } = prev.editedUserPreferences;
            workingDays[day].end.setHours(date!.getHours(), date!.getMinutes(), 0, 0);

            return {
                editedUserPreferences: { ...prev.editedUserPreferences, workingDays },
            };
        }, this.validateWeekTimes);
    };

    handleIncludeDayChanged = (day: string) => {
        this.setState((prev) => {
            const { workingDays } = prev.editedUserPreferences;
            workingDays[day].enable = !workingDays[day].enable;

            return {
                editedUserPreferences: { ...prev.editedUserPreferences, workingDays },
            };
        }, this.validateWeekTimes);
    };

    handleLocationPinClick = (point: Routing.Route.UserLocationPoint | null) => {
        this.setState({
            showLocationModal: true,
            locationModalPoint: point,
        });
    };

    getEditPopupContent = () => {
        const { editedUserPreferences, editPopupColumn, errors } = this.state;
        const { t } = this.props;
        const account = userManager.getCurrentAccount();

        switch (editPopupColumn) {
            case 'weekTimes':
                return (
                    <WorkingDays
                        workingDays={editedUserPreferences.workingDays}
                        onStartDateChanged={this.handleStartDateChanged}
                        onEndDateChanged={this.handleEndDateChanged}
                        onIncludeDayChanged={this.handleIncludeDayChanged}
                        timePickerVariant="dialog"
                    />
                );
            case 'startPoint':
                return (
                    <ManualLocationInput
                        address={editedUserPreferences.startPoint?.address ?? null}
                        onPointFound={(value) => this.updateEditedUserField('startPoint', value)}
                        onCancel={() => this.updateEditedUserField('startPoint', null)}
                        accountId={account.id}
                        label={t('route_editor.modal.routing_preferences.modal.start_point')}
                        hint={t('route_editor.modal.routing_preferences.modal.start_point.hint')}
                    />
                );
            case 'endPoint':
                return (
                    <ManualLocationInput
                        address={editedUserPreferences.endPoint?.address ?? null}
                        onPointFound={(value) => this.updateEditedUserField('endPoint', value)}
                        onCancel={() => this.updateEditedUserField('endPoint', null)}
                        accountId={account.id}
                        label={t('route_editor.modal.routing_preferences.modal.end_point')}
                        hint={t('route_editor.modal.routing_preferences.modal.end_point.hint')}
                    />
                );
            case 'returnToFinishLocation':
                return (
                    <ReturnToFinishLocationInput
                        value={editedUserPreferences.returnToFinishLocation}
                        onValueChanged={(value) => this.updateEditedUserField('returnToFinishLocation', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('returnToFinishLocation', isValid, error)
                        }
                        error={errors.get('returnToFinishLocation')}
                    />
                );
            case 'defaultDuration':
                return (
                    <MeetingDurationInput
                        value={String(editedUserPreferences.defaultDuration)}
                        onValueChanged={(value) => this.updateEditedUserField('defaultDuration', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('defaultDuration', isValid, error)
                        }
                        error={errors.get('defaultDuration')}
                    />
                );
            case 'departureDelay':
                return (
                    <DepartureDelayInput
                        value={String(editedUserPreferences.departureDelay)}
                        onValueChanged={(value) => this.updateEditedUserField('departureDelay', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('departureDelay', isValid, error)
                        }
                        error={errors.get('departureDelay')}
                    />
                );
            case 'breakType':
                return (
                    <div>
                        <BreakTypeInput
                            value={editedUserPreferences.breakType}
                            onValueChanged={(value) => this.updateEditedUserField('breakType', value)}
                            onInputValidation={(isValid: boolean, error?: string) =>
                                this.onInputValidation('breakType', isValid, error)
                            }
                            error={errors.get('breakType')}
                        />
                        {editedUserPreferences.breakType !== null && (
                            <div>
                                <Grid container spacing={1}>
                                    {editedUserPreferences.breakType === BreakTypeEnum.TIME_WINDOW && (
                                        <React.Fragment>
                                            <Grid item xs={6}>
                                                <BreakEarliestTimeInput
                                                    value={editedUserPreferences.breakEarliestTime}
                                                    onValueChanged={(value) =>
                                                        this.updateEditedUserField('breakEarliestTime', value)
                                                    }
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('breakEarliestTime', isValid, error)
                                                    }
                                                    error={errors.get('breakEarliestTime')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <BreakLatestTimeInput
                                                    value={editedUserPreferences.breakLatestTime}
                                                    onValueChanged={(value) =>
                                                        this.updateEditedUserField('breakLatestTime', value)
                                                    }
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('breakLatestTime', isValid, error)
                                                    }
                                                    error={errors.get('breakLatestTime')}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </div>
                );
            case 'breakDuration':
                return (
                    <BreakDurationInput
                        value={editedUserPreferences.breakDuration}
                        onValueChanged={(value) => this.updateEditedUserField('breakDuration', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('breakDuration', isValid, error)
                        }
                        error={errors.get('departureDelay')}
                    />
                );
            case 'vehicleType':
                return (
                    <VehicleTypeInput
                        account={account}
                        value={editedUserPreferences.vehicleType}
                        onValueChanged={(value) => this.updateEditedUserField('vehicleType', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('vehicleType', isValid, error)
                        }
                        error={errors.get('vehicleType')}
                    />
                );
            case 'hasSkills':
                return (
                    <HasSkillsInput
                        account={account}
                        value={editedUserPreferences.hasSkills}
                        onValueChanged={(value) => this.updateEditedUserField('hasSkills', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('hasSkills', isValid, error)
                        }
                        error={errors.get('hasSkills')}
                    />
                );
            case 'maxDistance':
                return (
                    <MaxDistanceInput
                        account={account}
                        value={editedUserPreferences.maxDistance}
                        onValueChanged={(value) => this.updateEditedUserField('maxDistance', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('maxDistance', isValid, error)
                        }
                        error={errors.get('maxDistance')}
                    />
                );
            case 'maxDrivingTime':
                return (
                    <MaxDrivingTimeInput
                        value={editedUserPreferences.maxDrivingTime}
                        onValueChanged={(value) => this.updateEditedUserField('maxDrivingTime', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('maxDrivingTime', isValid, error)
                        }
                        error={errors.get('maxDrivingTime')}
                    />
                );
            case 'maxActivities':
                return (
                    <MaxActivitiesInput
                        value={editedUserPreferences.maxActivities}
                        onValueChanged={(value) => this.updateEditedUserField('maxActivities', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('maxActivities', isValid, error)
                        }
                        error={errors.get('maxActivities')}
                    />
                );
            case 'minJobs':
                return (
                    <MinJobsInput
                        value={editedUserPreferences.minJobs}
                        onValueChanged={(value) => this.updateEditedUserField('minJobs', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('minJobs', isValid, error)
                        }
                        error={errors.get('minJobs')}
                    />
                );
            case 'maxJobs':
                return (
                    <MaxJobsInput
                        value={editedUserPreferences.maxJobs}
                        onValueChanged={(value) => this.updateEditedUserField('maxJobs', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('maxJobs', isValid, error)
                        }
                        error={errors.get('maxJobs')}
                    />
                );
            case 'canJobStartEarlier':
                return (
                    <CanJobStartEarlierInput
                        value={editedUserPreferences.canJobStartEarlier}
                        onValueChanged={(value) => this.updateEditedUserField('canJobStartEarlier', value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation('canJobStartEarlier', isValid, error)
                        }
                        error={errors.get('canJobStartEarlier')}
                    />
                );
            case 'allowedOvertime':
                return (
                    <AllowedOvertimeInput
                        value={editedUserPreferences[editPopupColumn]}
                        onValueChanged={(value) => this.updateEditedUserField(editPopupColumn, value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation(editPopupColumn, isValid, error)
                        }
                        error={errors.get(editPopupColumn)}
                        inputStyle={{ minWidth: '120px' }}
                    />
                );
            case 'lassoAutoZoom':
                return (
                    <ShowBooleanInput
                        label={'route_editor.modal.routing_preferences.modal.lasso_auto_zoom'}
                        hint={'route_editor.modal.routing_preferences.modal.lasso_auto_zoom.hint'}
                        value={editedUserPreferences[editPopupColumn]}
                        onValueChanged={(value) => this.updateEditedUserField(editPopupColumn, value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation(editPopupColumn, isValid, error)
                        }
                        error={errors.get(editPopupColumn)}
                    />
                );
            case 'showEarlyLateTags':
                return (
                    <SelectTypeInput
                        picklistValues={Object.values(ShowEarlyLateTags).map((tag) => {
                            const label = t(
                                `route_editor.modal.routing_preferences.modal.show_early_late_tags.values.${tag}.label`,
                            );

                            return {
                                label,
                                value: tag,
                            };
                        })}
                        label={'route_editor.modal.routing_preferences.modal.show_early_late_tags'}
                        hint={'route_editor.modal.routing_preferences.modal.show_early_late_tags.hint'}
                        value={editedUserPreferences[editPopupColumn]}
                        onValueChanged={(value) => this.updateEditedUserField(editPopupColumn, value)}
                        onInputValidation={(isValid: boolean, error?: string) =>
                            this.onInputValidation(editPopupColumn, isValid, error)
                        }
                        error={errors.get(editPopupColumn)}
                        selectStyle={{ minWidth: '200px' }}
                    />
                );
            default:
                return null;
        }
    };

    updateEditedUserField = (field: TableColumnName, value: any): Promise<void> => {
        return new Promise((resolve) => {
            const errors = new Map(this.state.errors);
            const update: any = {};
            switch (field) {
                case 'weekTimes':
                    throw new Error('This field can not be edited this way');
                case 'startPoint':
                case 'endPoint':
                    const currentValue = value === null ? value : GeoPointTransformer.transformGeoLocationPoint(value);
                    update[field] = currentValue;
                    break;
                case 'returnToFinishLocation':
                case 'defaultDuration':
                case 'departureDelay':
                case 'breakType':
                case 'breakDuration':
                case 'breakEarliestTime':
                case 'breakLatestTime':
                case 'vehicleType':
                case 'hasSkills':
                case 'maxDistance':
                case 'maxDrivingTime':
                case 'maxActivities':
                case 'minJobs':
                case 'maxJobs':
                case 'allowedOvertime':
                case 'lassoAutoZoom':
                case 'showEarlyLateTags':
                    update[field] = value;
                    break;
                default:
                    throw new Error('Unknown field name');
            }
            console.log('set state.errors in update methods', new Map(errors));
            this.setState((state) => {
                return {
                    editedUserPreferences: { ...state.editedUserPreferences, ...update },
                    errors,
                };
            }, resolve);
        });
    };

    openEditPopupByFieldName = (fieldName: keyof UserDataRoutePreferences) => (event: React.MouseEvent<HTMLElement>) =>
        this.openEditPopup(event.currentTarget, fieldName);

    render() {
        const { user, selected, getRowCellValueAsString } = this.props;
        const { editPopupColumn, editPopupAnchorEl, errors } = this.state;

        const showEditPopup = editPopupColumn !== null && editPopupAnchorEl !== null;
        const disableSaveEditPopup = errors.size !== 0;

        return (
            <TableRow>
                <LeftStickyCell>
                    <FormControlLabel
                        control={<Checkbox checked={selected} onChange={this.handleUserSelected} color="primary" />}
                        label={getRowCellValueAsString(user, 'user')}
                    />
                </LeftStickyCell>
                {USER_ROUTING_PREFERENCES_FIELD_LIST.map((pref) => {
                    const isAddLink = ['startPoint', 'endPoint'].includes(pref);

                    return (
                        <TableCell>
                            <DottedLink onClick={this.openEditPopupByFieldName(pref)}>
                                {getRowCellValueAsString(user, pref)}
                            </DottedLink>
                            {isAddLink && (
                                <>
                                    &nbsp;
                                    {user.routingPreferences[pref] !== null && (
                                        <Link
                                            onClick={this.handleLocationPinClick.bind(
                                                this,
                                                user.routingPreferences[pref] as UserLocationPoint,
                                            )}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className="fas fa-map-pin" />
                                        </Link>
                                    )}
                                </>
                            )}
                        </TableCell>
                    );
                })}
                {showEditPopup && (
                    <Popover
                        onClose={this.closeEditPopup}
                        open={true}
                        anchorEl={editPopupAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ padding: 10 }}>
                            {this.getEditPopupContent()}
                            <div style={{ textAlign: 'end' }}>
                                <Button color="primary" onClick={this.closeEditPopup}>
                                    {this.props.t('button.cancel')}
                                </Button>
                                <Button color="primary" onClick={this.saveEditPopup} disabled={disableSaveEditPopup}>
                                    {this.props.t('button.save')}
                                </Button>
                            </div>
                        </div>
                    </Popover>
                )}
                {this.state.showLocationModal && this.state.locationModalPoint && (
                    <LocationModal
                        location={this.state.locationModalPoint}
                        onClose={() => {
                            this.setState({ showLocationModal: false });
                        }}
                    />
                )}
            </TableRow>
        );
    }
}

export default withTranslation('translations')(UserRow);
