import { action, computed, makeObservable, observable } from 'mobx';
import { isSameDay } from 'date-fns';
import { UserData, UserReviewData } from 'service/types';
import UserReviewStorage from './UserReviewStorage';
import { AdapterId, UserReviewSource, UserReviewSourceType } from 'components/types';
import { userManager } from 'service/UserManager';
import { weAreInNativeApp } from '../../../utils';
import { userHasCrmAccountDialogManager, userHasCrmAccountService } from 'service/MapPage';
import { DataSource } from 'interfaces';

type UserReviewManagerKeys =
    | '_isShowLeaveReviewDialog'
    | '_isShowEnjoyingMapslyDialog'
    | '_isShowCouldBeBetterDialog'
    | '_isShowEnjoyingMapslyAppDialog'
    | 'showEnjoyingMapslyAppDialog'
    | 'showEnjoyingMapslySourceDialog';

class UserReviewDialogManager {
    private readonly storage: UserReviewStorage;

    private _isShowLeaveReviewDialog = false;
    private _isShowCouldBeBetterDialog = false;
    private _isShowEnjoyingMapslyDialog = false;
    private _isShowEnjoyingMapslyAppDialog = false;

    private _source: UserReviewSource | null = null;

    constructor(storage: UserReviewStorage) {
        this.storage = storage;

        makeObservable<UserReviewDialogManager, UserReviewManagerKeys>(this, {
            _isShowLeaveReviewDialog: observable,
            _isShowEnjoyingMapslyDialog: observable,
            _isShowEnjoyingMapslyAppDialog: observable,
            _isShowCouldBeBetterDialog: observable,

            isShowLeaveReviewDialog: computed,
            isShowCouldBeBetterDialog: computed,
            isShowEnjoyingMapslyDialog: computed,
            isShowEnjoyingMapslyAppDialog: computed,

            showEnjoyingMapslyDialog: action,
            showEnjoyingMapslySourceDialog: action,
            showEnjoyingMapslyAppDialog: action,
            showLeaveReviewDialog: action,
            showCouldBeBetterDialog: action,

            initDialogs: action,
            closeAllDialogs: action,
        });
    }

    get source(): UserReviewSource {
        if (this._source === null) {
            throw new Error('User review dialogs are not initialized.');
        }

        return this._source;
    }

    get isShowLeaveReviewDialog(): boolean {
        return this._isShowLeaveReviewDialog;
    }

    get isShowCouldBeBetterDialog(): boolean {
        return this._isShowCouldBeBetterDialog;
    }

    get isShowEnjoyingMapslyDialog(): boolean {
        return this._isShowEnjoyingMapslyDialog;
    }

    get isShowEnjoyingMapslyAppDialog(): boolean {
        return this._isShowEnjoyingMapslyAppDialog;
    }

    showEnjoyingMapslyDialog = () => {
        if (this.source.sourceType === UserReviewSourceType.SSO) {
            this.showEnjoyingMapslySourceDialog();
            return;
        }
        if (this.source.sourceType === UserReviewSourceType.APP) {
            this.showEnjoyingMapslyAppDialog();
            return;
        }
        this.closeAllDialogs();
    };

    private showEnjoyingMapslySourceDialog = () => {
        this._isShowEnjoyingMapslyDialog = true;
        this._isShowEnjoyingMapslyAppDialog = false;
        this._isShowLeaveReviewDialog = false;
        this._isShowCouldBeBetterDialog = false;

        const config = this.storage.load();
        config.lastShowEnjoyingMapslyDialog = new Date();
        this.storage.save(config);
    };

    private showEnjoyingMapslyAppDialog = () => {
        this._isShowEnjoyingMapslyAppDialog = true;
        this._isShowEnjoyingMapslyDialog = false;
        this._isShowLeaveReviewDialog = false;
        this._isShowCouldBeBetterDialog = false;

        const config = this.storage.load();
        config.lastShowEnjoyingMapslyAppDialog = new Date();
        this.storage.save(config);
    };

    showLeaveReviewDialog = () => {
        this._isShowLeaveReviewDialog = true;
        this._isShowCouldBeBetterDialog = false;
        this._isShowEnjoyingMapslyDialog = false;
        this._isShowEnjoyingMapslyAppDialog = false;

        const config = this.storage.load();
        config.lastShowLeaveReviewDialog = new Date();
        this.storage.save(config);
    };

    showCouldBeBetterDialog = () => {
        this._isShowCouldBeBetterDialog = true;
        this._isShowLeaveReviewDialog = false;
        this._isShowEnjoyingMapslyDialog = false;
        this._isShowEnjoyingMapslyAppDialog = false;

        const config = this.storage.load();
        config.lastShowCouldBeBetterDialog = new Date();
        this.storage.save(config);
    };

    closeAllDialogs = () => {
        this._isShowLeaveReviewDialog = false;
        this._isShowCouldBeBetterDialog = false;
        this._isShowEnjoyingMapslyDialog = false;
        this._isShowEnjoyingMapslyAppDialog = false;
    };

    initDialogs = (_: UserReviewData, _1: DataSource.SimpleDataSource[]) => {
        // TODO не показываем диалоги.
    };

    initDialogsTemp = (data: UserReviewData, dataSources: DataSource.SimpleDataSource[]) => {
        // Показываем ревью диалоги только для оригинального юзера.
        if (userManager.isSwitched() || userManager.isSharedMapUser()) {
            return;
        }

        const user = userManager.getCurrentUser();
        if (user.hasCrmAccount === null && user.ssoProvider === null) {
            const primaryDataSource = userHasCrmAccountService.getPrimaryDataSource(dataSources);
            if (primaryDataSource !== null) {
                userHasCrmAccountDialogManager.openModal(primaryDataSource);
                return;
            }
        }

        if (data.remindLater || data.isPostponed) {
            return;
        }

        const config = this.storage.load();
        if (data.isWaitingSourceReview && !data.leftSourceReview && (user.ssoProvider || user.hasCrmAccount)) {
            const sourceAdapterId = this.getSourceAdapterId(user, dataSources);
            if (sourceAdapterId === null) {
                return;
            }

            this._source = {
                sourceType: UserReviewSourceType.SSO,
                adapterId: sourceAdapterId,
            };
            if (data.isReminded) {
                if (this.canShowDialogToday(config.lastShowLeaveReviewDialog)) {
                    this.showLeaveReviewDialog();
                }
            } else {
                if (this.canShowDialogToday(config.lastShowEnjoyingMapslyDialog)) {
                    this.showEnjoyingMapslySourceDialog();
                }
            }
            return;
        }

        if (data.isWaitingAppReview && !data.leftAppReview && weAreInNativeApp()) {
            this._source = {
                sourceType: UserReviewSourceType.APP,
                adapterId: null,
            };
            if (data.isReminded) {
                if (this.canShowDialogToday(config.lastShowLeaveReviewDialog)) {
                    this.showLeaveReviewDialog();
                }
            } else {
                if (this.canShowDialogToday(config.lastShowEnjoyingMapslyAppDialog)) {
                    this.showEnjoyingMapslyAppDialog();
                }
            }
        }
    };

    private canShowDialogToday(lastShowDate: Date | null): boolean {
        if (lastShowDate === null) {
            return true;
        }
        return !isSameDay(lastShowDate, new Date());
    }

    private getSourceAdapterId(user: UserData, dataSources: DataSource.SimpleDataSource[]): AdapterId | null {
        if (user.ssoProvider) {
            return user.ssoProvider;
        }

        if (user.hasCrmAccount) {
            const primaryDataSource = userHasCrmAccountService.getPrimaryDataSource(dataSources);
            if (primaryDataSource !== null) {
                return primaryDataSource.adapterId;
            }
        }

        return null;
    }
}

export default UserReviewDialogManager;
