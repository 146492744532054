import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { FieldLookupType, FieldPolymorphicLookupData, IField } from '../../../components/types';
import entityManagerFactory from '../../../service/EntityManager';
import viewRecordManager from '../../../service/ViewRecordManager';

import LiveSearchTableViewDialog, { LiveSearchTableViewDialogProps } from '../LiveSearchTableViewDialog';
import AutoCompleteField from './AutoCompleteField';
import { FieldFactoryValueObject } from './FieldFactory';
import LookupPolymorphicField from './LookupPolymorphicField';

interface LookupFieldProps extends WithTranslation {
    accountId: LiveSearchTableViewDialogProps['accountId'];
    dataSourceId: LiveSearchTableViewDialogProps['dataSourceId'];
    /** original record id, not related entity's */
    recordId?: string;
    value: string | number | FieldFactoryValueObject | null;
    name: string;
    label: string;
    disabled: boolean;
    autoFocus: boolean;
    lookupData: FieldPolymorphicLookupData;
    field: IField;
    valueId?: string;
    onChange: (value: any) => void;
    selectedEntity?: string;
}

interface LookupFieldState {
    isSearchShown: boolean;
    value: any;
    hasChanged: boolean;
}

class LookupField extends React.PureComponent<LookupFieldProps, LookupFieldState> {
    constructor(props: Readonly<LookupFieldProps>) {
        super(props);

        const state: LookupFieldState = {
            isSearchShown: false,
            value: props.value,
            hasChanged: false,
        };

        if (typeof props.value === 'string' && props.value && props.valueId) {
            state.value = {
                id: props.valueId,
                data: {
                    objectName: props.value,
                },
                textValue: props.value,
            };
        }

        if (state.value && props.selectedEntity) {
            state.value.apiName = props.selectedEntity;
        }

        this.state = state;
    }

    /** when entity is selected in polynorphic picklist */
    handleChangeLookupPolymorphicEntity = (entity: string | null) => {
        this.setState({ value: { apiName: entity } });
        this.props.onChange(null);

        if (entity === null) {
            this.props.onChange(null);
        }
    };

    handleShowSearch = () => {
        this.setState({ isSearchShown: true });
    };

    handleCloseSearch = () => {
        this.setState({ isSearchShown: false });
    };

    handleChangeLookup: LiveSearchTableViewDialogProps['onSaveRequest'] = (value) => {
        this.setState({ value, hasChanged: true, isSearchShown: false });
        this.props.onChange(value);
    };

    handleChangeMultiLookup: LiveSearchTableViewDialogProps['onSaveMultiLookupRequest'] = (toAdd, toDelete) => {
        const { t } = this.props;

        let textValue = '';
        if (toAdd.length) {
            textValue += t('map_page.view_record.edit.add', {
                count: toAdd.length,
            });
        }
        if (toDelete.length) {
            const prefix = toAdd.length ? '; ' : '';
            textValue +=
                prefix +
                t('map_page.view_record.edit.delete', {
                    count: toDelete.length,
                });
        }

        const value = { toAdd, toDelete, textValue };

        this.setState({
            value,
            isSearchShown: false,
        });
        this.props.onChange(value);
    };

    handleRecordClick = async () => {
        const { dataSourceId, accountId, field, valueId, selectedEntity } = this.props;
        const { value } = this.state;
        const { lookupData } = field;
        const loadedEntities = await entityManagerFactory.getManager(accountId, dataSourceId).getEntities();

        const recordId = value.id ?? valueId;

        if ('apiName' in lookupData!) {
            const entity = loadedEntities.find((e: any) => e.name === lookupData.apiName);
            if (!entity) {
                return;
            }

            return viewRecordManager.viewRecord(entity.id, recordId);
        }

        if (lookupData!.type === FieldLookupType.POLYMORPHIC) {
            const entity = loadedEntities.find((e: any) => e.name === value.apiName || e.name === selectedEntity);
            if (!entity) {
                return;
            }

            viewRecordManager.viewRecord(entity.id, recordId);
        }
    };

    render() {
        const { name, label, disabled, autoFocus, lookupData, selectedEntity } = this.props;
        const { value, isSearchShown } = this.state;

        if (lookupData.type === FieldLookupType.POLYMORPHIC) {
            return (
                <>
                    <LookupPolymorphicField
                        name={name}
                        value={
                            typeof value === 'string' ? value : (value as FieldFactoryValueObject)?.textValue ?? null
                        }
                        disabled={disabled}
                        autoFocus={autoFocus}
                        lookupData={lookupData as FieldPolymorphicLookupData}
                        onShowSearch={this.handleShowSearch}
                        onChangeEntity={this.handleChangeLookupPolymorphicEntity}
                        onEntityClick={this.handleRecordClick}
                        selectedEntity={selectedEntity}
                        label={label}
                    />
                    {isSearchShown && <this.RenderSearchBlock />}
                </>
            );
        }

        return (
            <>
                <AutoCompleteField
                    value={value as string | number | FieldFactoryValueObject}
                    name={name}
                    label={label}
                    onShowSearch={this.handleShowSearch}
                    onRecordClick={this.handleRecordClick}
                />
                {isSearchShown && <this.RenderSearchBlock />}
            </>
        );
    }

    RenderSearchBlock = () => {
        const { field, recordId } = this.props;
        const { value } = this.state;

        return (
            <LiveSearchTableViewDialog
                accountId={this.props.accountId}
                dataSourceId={this.props.dataSourceId}
                onClose={this.handleCloseSearch}
                onSaveRequest={this.handleChangeLookup}
                onSaveMultiLookupRequest={this.handleChangeMultiLookup}
                field={field}
                value={value}
                recordId={recordId}
            />
        );
    };
}

const EnhancedComponent = withTranslation('translations', { withRef: true })(LookupField);

export default EnhancedComponent;
