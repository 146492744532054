import { withTranslation, WithTranslation } from 'react-i18next';

import React from 'react';
import { Subscription } from '../../../interfaces/account';
import TextField from '@material-ui/core/TextField';
import TranscriptionMinutesSlider from './TranscriptionMinutesSlider';
import { FormLabel } from '@material-ui/core';
import { formatMoney } from '../MoneyFormat';

interface AiTokensSubscriptionEditFormProps extends WithTranslation {
    subscription: Subscription;
    errors: Map<string, any>;
    onChange: (key: string, value: any) => void;
}

class TranscriptionMinutesSubscriptionEditForm extends React.Component<AiTokensSubscriptionEditFormProps, any> {
    handleInputChange = (event: any) => {
        this.props.onChange(event.target.name as string, event.target.value || null);
    };

    private additionalCustomTranscriptionMinutes = () => {
        return Math.floor(this.props.subscription.additionalCustomTranscriptionSeconds / 60);
    };

    render() {
        const { subscription, errors, onChange, t } = this.props;

        return (
            <React.Fragment>
                <div style={{ marginTop: 32 }}>{t('billing.edit_form.section.transcription')}</div>
                <FormLabel>{t('billing.edit_form.section.transcription_minutes.additional_custom_tokens')}</FormLabel>
                <TranscriptionMinutesSlider
                    value={this.additionalCustomTranscriptionMinutes()}
                    onChange={(value) => onChange('additionalCustomTranscriptionSeconds', value * 60)}
                />
                <TextField
                    label={t('billing.edit_form.section.transcription_minutes.number_of_users_with_price', {
                        price: formatMoney(subscription.currency, subscription.plan.priceTranscriptionUsersPm),
                    })}
                    data-testid="billing.edit_form.section.transcription_minutes.number_of_users"
                    placeholder={t('billing.edit_form.section.transcription_minutes.number_of_users')}
                    type="number"
                    fullWidth
                    margin="dense"
                    name="transcriptionUsers"
                    value={subscription.transcriptionUsers}
                    helperText={errors.get('transcriptionUsers') || ''}
                    error={errors.has('transcriptionUsers')}
                    onChange={this.handleInputChange}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            step: 1,
                        },
                    }}
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(TranscriptionMinutesSubscriptionEditForm);
