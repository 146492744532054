import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { weAreInNativeApp } from '../../utils';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { AppVersion } from '@ionic-native/app-version';
import BgGeoManager from '../../service/BgGeo/BgGeoManager';
import Grid from '@material-ui/core/Grid';
import { Deploy } from 'cordova-plugin-ionic';
import appUpdateManager from '../../service/MobileApp/AppUpdateManager';
import { enqueueSnackbarService } from 'service/MapPage';

interface Props extends WithTranslation {
    onClose: () => void;
}

interface State {
    loading: boolean;
    versionNumber: string;
    appflowVersionNumber: string;
}

class AboutAppModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            versionNumber: '',
            appflowVersionNumber: '',
        };
    }

    handleSendLogs = async () => {
        const { t } = this.props;
        this.setState({
            loading: true,
        });

        try {
            await BgGeoManager.emailLog();
            enqueueSnackbarService.sendSuccessMessage(t('success'));
            this.setState({
                loading: false,
            });
            this.props.onClose();
        } catch (error) {
            enqueueSnackbarService.sendErrorMessage(t('fail'));
            console.error(error);
        }

        this.setState({
            loading: false,
        });
    };

    componentDidMount() {
        appUpdateManager.checkForUpdate(true);

        AppVersion.getVersionNumber().then((version) => {
            this.setState({
                versionNumber: version,
            });
        });

        Deploy.getCurrentVersion().then((info) => {
            console.log('Appflow version ', info);

            if (info?.buildId) {
                this.setState({
                    appflowVersionNumber: info.buildId,
                });
            }
        });

        Deploy.getConfiguration()
            .then((config) => {
                console.log('Appflow config ', config);
            })
            .catch((e) => {
                console.log('Appflow config fail ', e);
            });
    }

    render() {
        const { t } = this.props;
        const { loading, versionNumber, appflowVersionNumber } = this.state;

        if (!weAreInNativeApp()) {
            return null;
        }

        return (
            <div style={{ padding: '20px 0px' }}>
                <Grid container justify="center" direction="column" spacing={2}>
                    <Grid item xs style={{ textAlign: 'center' }}>
                        <img alt="Mapsly" src="/image/mapsly-icon.png" style={{ maxWidth: '150px' }} />
                    </Grid>
                    <Grid item xs style={{ textAlign: 'center' }}>
                        <Typography variant="caption" color="textSecondary">
                            {versionNumber}
                            {appflowVersionNumber ? ' / ' + appflowVersionNumber : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ textAlign: 'center' }}>
                        <Button
                            onClick={this.handleSendLogs}
                            disabled={loading}
                            color="primary"
                            style={{ margin: '30px 0px' }}
                            data-testid="about_app_modal.btn_send_logs"
                        >
                            {t('about_app_modal.btn_send_logs')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation('translations')(AboutAppModal);
