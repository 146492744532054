import noop from 'lodash/noop';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { propertyIdToName, propertyNameToId } from '../../../utils';
import { IField } from '../../types';
import LiveSearchTableViewDialog, { LiveSearchTableViewDialogProps } from '../LiveSearchTableViewDialog';

import AutoCompleteField from './AutoCompleteField';

type SelectedRecordItem = {
    id: string;
    title: string;
};

export interface FewLookupFieldProps extends WithTranslation {
    accountId: number;
    dataSourceId: number;
    recordId?: string;
    values: Record<string, any>;
    field: IField;
    disabled?: boolean;
    autoFocus: boolean;
    onChange: (value: SelectedRecordItem[]) => void;
}

interface FewLookupFieldState {
    isSearchShown: boolean;
    value: { data: SelectedRecordItem[]; textValue: string };
}

class FewLookupField extends React.PureComponent<FewLookupFieldProps, FewLookupFieldState> {
    state: Readonly<FewLookupFieldState>;

    constructor(props: FewLookupFieldProps) {
        super(props);

        let value: FewLookupFieldState['value'] = {
            data: [],
            textValue: '',
        };

        const apiName = props.field.apiName;
        const idApiName = propertyNameToId(apiName);
        const valueApiName = propertyIdToName(apiName);

        // check if we can extract initial value from props
        const values = props.values;

        const currentValue = values[valueApiName];
        const currentIdValue = values[idApiName];
        if (
            // direct value should be array
            Array.isArray(currentValue) &&
            // not empty
            currentValue.length > 0 &&
            // related value should be also array of ids
            Array.isArray(currentIdValue) &&
            // we expect that they are the same length
            currentIdValue.length === values[apiName].length
        ) {
            value = {
                data: values[apiName].map((_: string, idx: number) => ({
                    title: currentValue[idx],
                    id: currentIdValue[idx],
                })),
                textValue: currentValue.join(', '),
            };
        }

        this.state = {
            isSearchShown: false,
            value,
        };
    }

    handleShowSearch = () => {
        this.setState({ isSearchShown: true });
    };

    handleCloseSearch = () => {
        this.setState({ isSearchShown: false });
    };

    handleChangeMultiLookup: LiveSearchTableViewDialogProps['onSaveMultiLookupRequest'] = (toAdd, toDelete) => {
        const toDeleteIds = toDelete.map((it) => it.id);
        const data = toAdd
            .filter((it) => !toDeleteIds.includes(it.id))
            .map((it) => ({
                title: it.title || it.id,
                id: it.id,
            }));
        const textValue = data.map((t) => t.title).join(', ');

        const value = { data, textValue };

        this.setState({
            value,
            isSearchShown: false,
        });
        this.props.onChange(data);
    };

    render() {
        const { field } = this.props;
        const { apiName, originalLabel } = field;
        const { isSearchShown, value } = this.state;

        return (
            <>
                <AutoCompleteField
                    value={value}
                    name={apiName}
                    label={originalLabel}
                    onShowSearch={this.handleShowSearch}
                />
                {isSearchShown && <this.RenderSearchBlock />}
            </>
        );
    }

    RenderSearchBlock = () => {
        const { field, recordId } = this.props;
        const { value } = this.state;

        return (
            <LiveSearchTableViewDialog
                accountId={this.props.accountId}
                dataSourceId={this.props.dataSourceId}
                allowEmptySubmit={true}
                onClose={this.handleCloseSearch}
                onSaveRequest={noop}
                onSaveMultiLookupRequest={this.handleChangeMultiLookup}
                field={field}
                value={{
                    ...value,
                    //@ts-expect-error - wrong typings
                    toAdd: value?.data || [],
                }}
                recordId={recordId}
            />
        );
    };
}

export default withTranslation()(FewLookupField);
