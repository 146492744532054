import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FormDialog from 'components/FormDialog';
import SearchableFieldsListForm from 'components/FieldsListForm/SearchableFieldsListForm';
import { SearchableField } from './types';

interface Props extends WithTranslation {
    fields: SearchableField[];
    onSave: () => void;
    onCancel: () => void;
}

class SearchableFieldsListDialog extends React.PureComponent<Props> {
    render() {
        const { t, fields, onSave, onCancel } = this.props;

        return (
            <FormDialog
                title={t('table_view_form.add_remove_fields')}
                onSave={onSave}
                onCancel={onCancel}
                saveButtonTitle={t('ok')}
                maxWidth="md"
                dialogClassName="c-geo-mapper"
            >
                <SearchableFieldsListForm fields={fields} />
            </FormDialog>
        );
    }
}

export default withTranslation()(SearchableFieldsListDialog);
