import React from 'react';
import { withTranslation } from 'react-i18next';

const serverGeocodeStatuses = {
    success: 1,
    doubt: 2,
    noResult: 3,
    noAddress: 4,
    imported: 5,
    unprocessed: 0,
    error: -1,
    restricted: -2,
    other: -3,
};

class StatusFormatter extends React.PureComponent {
    render() {
        switch (parseInt(this.props.value)) {
            case serverGeocodeStatuses.success:
                return this.props.t('data_table.formatter.status_formatter.success');
            case serverGeocodeStatuses.doubt:
                return this.props.t('data_table.formatter.status_formatter.doubt');
            case serverGeocodeStatuses.imported:
                return this.props.t('data_table.formatter.status_formatter.imported');
            case serverGeocodeStatuses.noResult:
                return this.props.t('data_table.formatter.status_formatter.no_result');
            case serverGeocodeStatuses.noAddress:
                return this.props.t('data_table.formatter.status_formatter.no_address');
            default:
                return this.props.t('data_table.formatter.status_formatter.default');
        }
    }
}

export default withTranslation('translations', { withRef: true })(StatusFormatter);
