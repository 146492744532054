import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, Icon, IconButton } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import subscriptionManagerFactory from '../../service/SubscriptionManager';

interface UpdateBillingProps extends WithTranslation {
    accountId: number;
    onCloseDialog: () => void;
    onFailure?: (message: string) => void;
    onSuccess?: (message: string) => void;
}

interface UpdateBillingState {
    showCloseConfirmation: boolean;
}

class UpdateBilling extends React.Component<UpdateBillingProps, UpdateBillingState> {
    constructor(props: UpdateBillingProps) {
        super(props);
        this.state = {
            showCloseConfirmation: true,
        };
    }

    recalculatePrice = (sendNotification: boolean) => {
        this.props.onCloseDialog();
        const accountId = this.props.accountId;
        const manager = subscriptionManagerFactory.getManager(accountId);
        manager
            .recalculatePrice(sendNotification)
            .then(() => {
                this.props.onSuccess &&
                    this.props.onSuccess(this.props.t('billing.super_admin_panel.update_billing.success'));
            })
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.props.onFailure && this.props.onFailure(error.message);
            });
    };

    render() {
        const { t } = this.props;

        return (
            <Dialog open={this.state.showCloseConfirmation}>
                <DialogTitle>
                    {t('billing.update_billing.title')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.update_billing.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>

                <Grid container wrap="wrap" justify={'center'} alignContent={'stretch'}>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={this.props.onCloseDialog}
                            data-testid="billing.update_billing.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.recalculatePrice(false);
                            }}
                            data-testid="billing.update_billing.no"
                        >
                            {t('no')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.recalculatePrice(true);
                            }}
                            data-testid="billing.update_billing.yes"
                        >
                            {t('yes')}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withTranslation('translations', { withRef: true })(UpdateBilling);
