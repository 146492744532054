import React from 'react';
import {
    colors,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';
import { withTranslation } from 'react-i18next';
import Confirmation from '../Confirmation';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';

class SSUFields extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            answer: 'yes',
            entities: null,
            relatedLookupEntities: [],
        };
    }

    componentDidMount() {
        const { onProcessing, t } = this.props;
        onProcessing && onProcessing(t('ssu.fields.processing.load_entities'));
        this.loadEntities();
    }

    loadEntities() {
        const { entityManager, onReady, onError } = this.props;
        entityManager
            .getEntities(true)
            .then((entities) => {
                const relatedLookupEntities = this.getAlsoImportedEntities([...entities]);
                this.setState({
                    entities,
                    relatedLookupEntities,
                });
                onReady && onReady(true);
            })
            .catch((error) => {
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                if (error instanceof Error) {
                    throw error;
                }
            });
        entityManager
            .getDataSource()
            .then((ds) => {
                this.setState({
                    dataSource: ds,
                });
            })
            .catch((error) => {
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                if (error instanceof Error) {
                    throw error;
                }
            });
    }

    handleChange = (event) => {
        this.setState({
            answer: event.target.value,
        });
    };

    static getDefaultImportSchedule() {
        return {
            interval: 30,
        };
    }

    removeFromRelated = (reference) => {
        this.setState((state) => {
            return {
                relatedLookupEntities: state.relatedLookupEntities.filter(
                    (item) => item.object.id !== reference.objectId,
                ),
            };
        });
    };

    commit() {
        const { entityManager, onProcessing, onSuccess, onError, t } = this.props;

        if (this.state.answer === 'yes' && !!this.state.relatedLookupEntities.length) {
            // собираем данные в структуру, совместимую с saveEntitiesAction
            if (this.state.entities === null) {
                return Promise.reject('');
            }

            onProcessing && onProcessing(t('ssu.fields.processing.commit'));

            const entities = [];

            for (let entity of this.state.entities) {
                if (
                    entity.isIncluded ||
                    this.state.relatedLookupEntities.find(
                        (relatedLookupEntity) => relatedLookupEntity.object.name === entity.name,
                    )
                ) {
                    entity.isIncluded = true;
                    if (!entity.schedule) {
                        entity.schedule = this.constructor.getDefaultImportSchedule();
                    }
                    entities.push(entity);
                } else {
                    entities.push({
                        id: entity.id,
                        isIncluded: false,
                    });
                }
            }

            return entityManager
                .saveEntities(entities)
                .then(() => {
                    onSuccess && onSuccess();
                    return Promise.resolve(this.state.answer);
                })
                .catch((error) => {
                    if (error.details.has('entities')) {
                        this.setState({
                            errors: error.details.get('entities'),
                        });
                    }
                    const { message } = getMessageAndDetails(error);
                    onError && onError(message);
                    throw error;
                });
        }

        return Promise.resolve(this.state.answer);
    }

    getAlsoImportedEntities(entities) {
        let relatedEntities = [];

        if (entities) {
            const importedByDefault = entities.filter((entity) => entity.isIncluded).map((entity) => entity.name);
            let skipRecursionEntities = [...importedByDefault];

            for (let entity of entities.filter((entity) => entity.isIncluded)) {
                this.getRelatedLookupEntitiesForEntity(
                    entities,
                    entity,
                    relatedEntities,
                    skipRecursionEntities,
                    importedByDefault,
                );
            }
        }

        return relatedEntities;
    }

    getRelatedLookupEntitiesForEntity(entities, entity, relatedEntities, skipRecursionEntities, importedByDefault) {
        skipRecursionEntities.push(entity.name);

        for (let field of entity.fields) {
            if (field.lookupData && field.lookupData.apiName && field.lookupData.apiName !== entity.name) {
                const relatedEntitiesNames = [];
                relatedEntitiesNames.push(field.lookupData.apiName);
                if (field.lookupData.linking_lookup_data && field.lookupData.linking_lookup_data.entity) {
                    relatedEntitiesNames.push(field.lookupData.linking_lookup_data.entity);
                }

                for (let relatedEntityName of relatedEntitiesNames) {
                    if (!importedByDefault.includes(relatedEntityName)) {
                        let relatedEntity = entities.find((entity) => entity.name === relatedEntityName);
                        if (relatedEntity) {
                            relatedEntities.push({
                                object: relatedEntity,
                                referenceField: field,
                                referenceObject: entity,
                            });

                            if (!skipRecursionEntities.includes(relatedEntity.name)) {
                                relatedEntities = this.getRelatedLookupEntitiesForEntity(
                                    entities,
                                    relatedEntity,
                                    relatedEntities,
                                    skipRecursionEntities,
                                    importedByDefault,
                                );
                            }
                        }
                    }
                }
            }
        }
        return relatedEntities;
    }

    getReferencedObjects() {
        let referencedObjects = [];

        for (const relation of this.state.relatedLookupEntities) {
            let refObject = {};
            const refIndex = referencedObjects.findIndex((ref) => ref.object === relation.object.label);
            if (refIndex === -1) {
                refObject.objectId = relation.object.id;
                refObject.object = relation.object.label;
                refObject.relations = [];
            } else {
                refObject = referencedObjects[refIndex];
            }

            const sameLookupFieldRelation = !!refObject.relations.find((existingRelation) => {
                return (
                    existingRelation.referenceField.originalApiName &&
                    existingRelation.referenceObject.name === relation.referenceObject.name &&
                    existingRelation.referenceField.originalApiName === relation.referenceField.originalApiName
                );
            });

            if (sameLookupFieldRelation) {
                continue;
            }

            let referenceFieldLabel = relation.referenceField.label;
            if (relation.referenceField.lookupData) {
                referenceFieldLabel = referenceFieldLabel.replace(/ \(NAME\)$| \(ID\)$/, '');
            }

            refObject.relations.push({
                referenceObject: relation.referenceObject,
                referenceObjectLabel: relation.referenceObject.label,
                referenceField: relation.referenceField,
                referenceFieldLabel,
            });

            if (refIndex === -1) {
                referencedObjects.push(refObject);
            } else {
                referencedObjects[refIndex] = refObject;
            }
        }

        for (const i in referencedObjects) {
            referencedObjects[i].relations = referencedObjects[i].relations.sort((a, b) => {
                if (a.referenceObjectLabel > b.referenceObjectLabel) {
                    return 1;
                }
                if (a.referenceObjectLabel < b.referenceObjectLabel) {
                    return -1;
                }
                if (a.referenceFieldLabel > b.referenceFieldLabel) {
                    return 1;
                }
                if (a.referenceFieldLabel < b.referenceFieldLabel) {
                    return -1;
                }
                return 0;
            });
        }

        referencedObjects = referencedObjects.sort((a, b) => {
            if (a.object === b.object) {
                return 0;
            } else {
                return a.object > b.object ? 1 : -1;
            }
        });

        return referencedObjects;
    }

    render() {
        const { relatedLookupEntities } = this.state;
        const { t } = this.props;
        const referencedObjects = this.getReferencedObjects();

        return (
            <div>
                <p>
                    <b>{t('signup.fields.title')}</b>
                </p>
                <p>{t('signup.fields.explanation')}</p>

                <FormControl component="fieldset">
                    <RadioGroup value={this.state.answer} onChange={this.handleChange}>
                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label={<b>{t('signup.fields.import_option.recommended')}</b>}
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label={t('signup.fields.import_option.custom')}
                        />
                    </RadioGroup>
                </FormControl>
                {!!relatedLookupEntities.length && (
                    <div>
                        <p>
                            <b>{this.props.t('ssu.fields.lookup_objects_heading')}</b>
                        </p>
                        <div style={{ display: 'flex' }}>
                            <i
                                style={{ marginRight: 10, fontSize: '20px', color: indigo[500] }}
                                className="fas fa-info-circle"
                            />
                            <Typography variant="body1">{this.props.t('ssu.fields.lookup_objects_text')}</Typography>
                        </div>
                        <Table className="ds-entity-table" padding="dense">
                            <TableHead>
                                <TableRow>
                                    <BorderlessTableCell style={{ width: '30%' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                                            {this.props.t('ssu.fields.lookup_objects_table.head.object')}
                                        </Typography>
                                    </BorderlessTableCell>
                                    <BorderlessTableCell style={{ width: '70%' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                                            {this.props.t('ssu.fields.lookup_objects_table.head.reference')}
                                        </Typography>
                                    </BorderlessTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {referencedObjects.map((reference) => (
                                    <TableRow key={reference.object}>
                                        <BorderlessTableCell style={{ verticalAlign: 'baseline' }}>
                                            <Confirmation
                                                text={t('data_source.entity.remove.popover', {
                                                    name: reference.object,
                                                })}
                                                onConfirm={this.removeFromRelated.bind(this, reference)}
                                            >
                                                <IconButton data-testid="data_source.entity.remove">
                                                    <Icon
                                                        className="fas fa-trash"
                                                        style={{ color: colors.red[500], fontSize: 16 }}
                                                    />
                                                </IconButton>
                                            </Confirmation>
                                            <Typography
                                                display={'inline'}
                                                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                                                variant="subtitle1"
                                            >
                                                {reference.object}
                                            </Typography>
                                        </BorderlessTableCell>
                                        <BorderlessTableCell>
                                            {reference.relations.map((relation) => (
                                                <Typography
                                                    variant="subtitle1"
                                                    key={
                                                        relation.referenceObjectLabel +
                                                        '_' +
                                                        relation.referenceFieldLabel
                                                    }
                                                >
                                                    {relation.referenceObjectLabel} {'>'} {relation.referenceFieldLabel}
                                                </Typography>
                                            ))}
                                        </BorderlessTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        );
    }
}

const BorderlessTableCell = withStyles({
    root: {
        borderBottom: 'none',
        paddingLeft: 0,
    },
})(TableCell);

export default withTranslation('translations', { withRef: true })(SSUFields);
