import { Routing, User } from 'interfaces';

export interface Session {
    id: string;
    status: Routing.Session.Status;
    input: Routing.Session.SessionInput;
    name: string;
    unassignedJobs: Routing.Route.UnassignedJob[];
    inputUsers: User.User[];
    permissions?: { rebuild: boolean };
}

export enum Status {
    DESIGN = 1,
    DRAFT = 2,
    DRAFT_EDIT = 22,
    PUBLISHED = 3,
    PUBLISHED_EDIT = 32,
    BUILDING = 4,
}

export interface SessionDesignModeInput extends Omit<SessionInput, 'points'> {}

export interface SessionInput {
    start: Routing.Route.MyLocation;
    finish: Routing.Route.MyLocation;
    // copied from the user parameters when the route is built
    points: Routing.Route.DesignRoutePointsArray;
    config: Routing.Route.DesignConfig;
    calendar: Routing.Appointment.AppointmentCalendar;
}
