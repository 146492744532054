import dispatcher from '../dispatcher';
import events from '../../events';
import FormFactory from './forms/FormFactory';
import BaseFormManager from './BaseFormManager';
import { userManager } from '../UserManager';

const formFactory = new FormFactory();

class MapFormManager extends BaseFormManager {
    constructor() {
        super();
        this.mapForm = null;
        this.searchBarFields = [];
        this.nonSearchBarFields = [];
        this.showForm = false;
        this.oldValues = new Map();
        this.newValues = new Map();
        this.version = 0;
        this.ready = false;

        dispatcher.subscribe(events.WS_INIT_FORM, this, (payload) => {
            this.mapForm = formFactory.createForm(payload);
            this.searchBarFields = [];
            this.nonSearchBarFields = [];
            const updateFields = [];
            const user = userManager.getCurrentUser();
            const configuredSearchBarFields = user.role.searchBarFields || null;
            for (let field of this.mapForm.fields.values()) {
                if (typeof field.value === 'string' && field.value.trim() !== '') {
                    updateFields.push({
                        apiName: field.apiName,
                        value: field.value,
                    });
                }
                if (
                    null !== configuredSearchBarFields &&
                    Array.isArray(configuredSearchBarFields) &&
                    configuredSearchBarFields.includes(field.apiName) &&
                    field.fieldType === 'picklist'
                ) {
                    this.searchBarFields.push(field);
                    continue;
                }
                this.nonSearchBarFields.push(field);
            }
            this.ready = true;
            this.updateFields(updateFields);
            this.refresh();
        });

        dispatcher.subscribe(events.WORKFLOW_MAP_ACTIONS_FORM_SHOW, this, () => {
            this.showForm = true;
            this.refresh();
        });
    }

    refresh() {
        dispatcher.dispatch(events.WORKFLOW_MAP_ACTIONS_FORM_REFRESHED);
    }

    getForm() {
        return this.showForm ? this.mapForm : null;
    }

    getVersion() {
        return this.version;
    }

    isReady() {
        return this.ready;
    }

    openForm() {
        this.oldValues.clear();
        this.newValues.clear();
        this.showForm = true;
        this.refresh();
    }

    getSearchBarFields() {
        return this.searchBarFields;
    }

    getNonSearchBarFields() {
        return this.nonSearchBarFields;
    }

    trigger() {
        const values = [];
        this.newValues.forEach((x) => {
            values.push({ apiName: x.field.getApiName(), value: x.value });
        });
        this.updateFields(values);
        this.oldValues.clear();
        this.newValues.clear();
        this.showForm = false;
        this.refresh();
    }

    closeForm() {
        this.oldValues.forEach((x) => {
            x.field.setValue(x.value);
        });
        this.oldValues.clear();
        this.newValues.clear();
        this.showForm = false;
        this.refresh();
    }

    handleFieldChange(field, value) {
        const apiName = field.getApiName();
        if (!this.oldValues.has(apiName)) {
            this.oldValues.set(apiName, {
                field,
                value: field.getValue(),
            });
        }
        this.newValues.set(apiName, {
            field,
            value,
        });
        field.setValue(value);
        this.refresh();
    }

    handlePicklistFieldChange(field, value) {
        field.setValue(value);
        this.updateFields([{ apiName: field.apiName, value }]);
        this.refresh();
    }

    updateFields(values) {
        if (values.length) {
            this.version++;
            dispatcher.dispatch(events.WORKFLOW_MAP_ACTION_FORM_FIELD_UPDATED, values);
        }
    }
}

export default new MapFormManager();
