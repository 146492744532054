import Analytics from './Analytics';
import Crashlytics from './Crashlytics';

export enum Events {
    FilesFromBuildUnValid = 'filesFromBuildUnValid',
    ResetCurrentVersionId = 'resetCurrentVersionId',
    UpdateBuildFail = 'updateBuildFail',
}

class Firebase {
    public readonly analytics: Analytics = new Analytics();
    public readonly crashlytics: Crashlytics = new Crashlytics();

    public init() {
        this.analytics.init();
        this.crashlytics.init();
    }
}

const firebase = new Firebase();
export default firebase;
