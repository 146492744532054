import AbstractEvent from './AbstractEvent';
import AbstractRecord, { Field } from './AbstractRecord';

export enum SelectionType {
    SELECTED = 'selected',
    ALL = 'all',
}

export interface UpdateRecordLabels {
    entityLabel: string;
}

class UpdateRecord extends AbstractRecord {
    private readonly recordIds: Array<number>;
    private readonly filters: Array<any> | null;
    private readonly sections: Array<any> | null;
    private readonly totalCount: number;
    private readonly selectionType: SelectionType;
    private readonly labels: UpdateRecordLabels;
    private readonly basePoint: any | null;

    constructor(
        accountId: number,
        dataSourceId: number,
        entityId: number,
        fields: Array<Field>,
        recordIds: Array<number>,
        filters: Array<any> | null,
        sections: Array<any> | null,
        totalCount: number,
        selectionType: SelectionType,
        labels: UpdateRecordLabels,
        basePoint: any | null,
    ) {
        super(accountId, dataSourceId, entityId, fields);

        this.recordIds = recordIds;
        this.filters = filters;
        this.sections = sections;
        this.totalCount = totalCount;
        this.selectionType = selectionType;
        this.labels = labels;
        this.basePoint = basePoint;
    }

    public getRecordIds(): Array<number> {
        return this.recordIds;
    }

    public getFilters(): Array<any> | null {
        return this.filters;
    }

    public getSections(): Array<any> | null {
        return this.sections;
    }

    public getTotalCount(): number {
        return this.totalCount;
    }

    public getSelectionType(): SelectionType {
        return this.selectionType;
    }

    public getLabels(): UpdateRecordLabels {
        return this.labels;
    }

    public getBasePoint(): any | null {
        return this.basePoint;
    }

    protected toStorageSpecific(): object {
        return {
            ...super.toStorageSpecific(),
            recordIds: this.getRecordIds(),
            filters: this.getFilters(),
            sections: this.getSections(),
            totalCount: this.getTotalCount(),
            selectionType: this.getSelectionType(),
            basePoint: this.getBasePoint(),
            labels: this.getLabels(),
        };
    }

    public static fromStorage(data: any): UpdateRecord {
        // @ts-ignore
        return AbstractEvent.fromStorageBase(
            new UpdateRecord(
                data.accountId,
                data.dataSourceId,
                data.entityId,
                data.fields,
                data.recordIds,
                data.filters,
                data.sections,
                data.totalCount,
                data.selectionType,
                data.labels,
                data.basePoint,
            ),
            data,
        );
    }
}

export default UpdateRecord;
