export enum TilesProviders {
    OSM = 'osm',
    GOOGLE = 'google',
    LOCATION_IQ = 'locationIQ',
    MAPBOX = 'mapbox',
    HERE = 'here',
}

export const TilesProvidersLabels = {
    [TilesProviders.OSM]: 'OSM',
    [TilesProviders.GOOGLE]: 'Google',
    [TilesProviders.LOCATION_IQ]: 'LocationIQ',
    [TilesProviders.MAPBOX]: 'MapBox',
    [TilesProviders.HERE]: 'Here',
};

export const TilesProvidersApiKeyNames = {
    [TilesProviders.OSM]: 'osm',
    [TilesProviders.GOOGLE]: 'google',
    [TilesProviders.LOCATION_IQ]: 'locationIq',
    [TilesProviders.MAPBOX]: 'mapBox',
    [TilesProviders.HERE]: 'here',
};

export enum MapTypes {
    ROADMAP = 'roadmap',
    SATELLITE = 'satellite',
    TERRAIN = 'terrain',
    HYBRID = 'hybrid',
    ROADMAP_LIGHT = 'roadmap_light',
    ROADMAP_DARK = 'roadmap_dark',
    NAVIGATION_LIGHT = 'navigation_light',
    NAVIGATION_DARK = 'navigation_dark',
}

export enum MapSubTypes {
    NO_POI = 'no_poi',
}

export type CombinedMapType = {
    tilesProvider: TilesProviders;
    mapType: MapTypes | string;
    mapSubType?: MapSubTypes | null;
    label?: string;
};

export type CombinedMapTypes = {
    [key: string]: CombinedMapType;
};

export const MapStyles = {
    [TilesProviders.GOOGLE]: {
        [MapTypes.ROADMAP]: {
            [MapSubTypes.NO_POI]: [
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        },
    },
};
