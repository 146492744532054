import { DataSource, LightDataSource } from './types';

class LightObjectGenerator {
    public static getLightDataSource(source: DataSource): LightDataSource {
        return {
            id: source.id,
            label: source.name,
        };
    }
}

export default LightObjectGenerator;
