import React from 'react';

import PropTypes from 'prop-types';
import './style.css';
import { logManager } from '../../service/UpdateLogManager';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { withStyles } from '@material-ui/core';
import { DataTable } from '../DataTable';
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import debounce from 'lodash/debounce';
import { userTimezoneToUtc } from '../../utils';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import { withTranslation } from 'react-i18next';
import DateTime from '../DateTime';

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'createdAt') {
        return <DateBetweenFilter {...props} />;
    }

    return <TableFilterRow.Cell {...props} />;
};

class UpdateEventLog extends DataTable {
    constructor(props) {
        super(props);
        this.pageSizes = [50, 100, 200];
        this.state = {
            loading: true,
            rows: [],
            pagination: {
                current: 0,
                size: 50,
            },
            totalCount: 0,
        };

        this.filters = [
            {
                columnName: 'createdAt',
                value: {
                    dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                    dateTo: new Date(),
                },
            },
        ];

        this.sorting = [
            {
                columnName: 'createdAt',
                direction: 'desc',
            },
        ];

        this.columns = [
            {
                name: 'createdAt',
                title: props.t('update_event_log.columns.createdAt.title'),
                getCellValue: (row) => (
                    <span>
                        <DateTime>{row.createdAt}</DateTime>
                    </span>
                ),
            },
            {
                name: 'entity',
                title: props.t('update_event_log.columns.entity.title'),
                getCellValue: (row) => <span>{row.entity}</span>,
            },
            {
                name: 'oldData',
                title: props.t('update_event_log.columns.oldData.title'),
                getCellValue: (row) => {
                    return <div className="event-log__context">{row.oldData ? JSON.stringify(row.oldData) : null}</div>;
                },
            },
            {
                name: 'newData',
                title: props.t('update_event_log.columns.newData.title'),
                getCellValue: (row) => {
                    return <div className="event-log__context">{row.newData ? JSON.stringify(row.newData) : null}</div>;
                },
            },
            {
                name: 'failedIds',
                title: props.t('update_event_log.columns.failedIds.title'),
                getCellValue: (row) => {
                    return (
                        <div className="event-log__context">{row.failedIds ? JSON.stringify(row.failedIds) : null}</div>
                    );
                },
            },
            {
                name: 'updatedIds',
                title: props.t('update_event_log.columns.updatedIds.title'),
                getCellValue: (row) => {
                    return (
                        <div className="event-log__context">
                            {row.updatedIds ? JSON.stringify(row.updatedIds) : null}
                        </div>
                    );
                },
            },
            {
                name: 'user',
                title: props.t('update_event_log.columns.user.title'),
                getCellValue: (row) => {
                    if (!row.user) {
                        return null;
                    }
                    return row.user.email;
                },
            },
            {
                name: 'ghostingUser',
                title: props.t('update_event_log.columns.ghostingUser.title'),
                getCellValue: (row) => {
                    if (!row.ghostingUser) {
                        return null;
                    }
                    return row.ghostingUser.email;
                },
            },
        ];

        this.columnExtensions = [
            {
                columnName: 'createdAt',
                width: 200,
            },
            {
                columnName: 'entity',
                width: 120,
            },
            {
                columnName: 'user',
                width: 150,
            },
            {
                columnName: 'ghostingUser',
                width: 150,
            },
            {
                columnName: 'oldData',
                wordWrapEnabled: true,
            },
            {
                columnName: 'newData',
                wordWrapEnabled: true,
            },
        ];

        this.handleChangeFilters = debounce(this.handleChangeFilters.bind(this), 500);
    }

    componentDidMount() {
        this.loadData();
        this.setupReloadOnTimeZoneChange();
    }

    handleChangeFilters = (filters) => {
        this.filters = filters;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            this.loadData,
        );
    };

    handleChangeSorting = (sorting) => {
        this.sorting = sorting;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            this.loadData,
        );
    };

    formatFilters(filters) {
        const formatFilters = [];
        filters.forEach((filter) => {
            if (filter.columnName === 'createdAt') {
                formatFilters['dateFrom'] = userTimezoneToUtc(filter.value.dateFrom, this.props.user);
                formatFilters['dateTo'] = userTimezoneToUtc(filter.value.dateTo, this.props.user);
            } else {
                formatFilters[filter.columnName] = filter.value;
            }
        });
        return formatFilters;
    }

    formatSorting(sorting) {
        const formatSorting = [];
        sorting.forEach((sort) => {
            formatSorting['column'] = sort.columnName;
            formatSorting['direction'] = sort.direction;
        });
        return formatSorting;
    }

    loadData() {
        logManager
            .getUpdateLog(
                this.props.accountId,
                this.formatFilters(this.getFilters()),
                this.formatSorting(this.sorting),
                this.state.pagination.current + 1,
                this.state.pagination.size,
            )
            .then((data) => {
                this.setState({
                    loading: false,
                    rows: data.logs,
                    totalCount: data.count,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });

        this.setState({
            loading: true,
            rows: [],
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="event-log">
                <h1>{t('update_event_log.h1')}</h1>
                <Grid rows={[]} columns={this.columns}>
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel pageSizes={this.pageSizes} containerComponent={PagingContainer} />
                </Grid>
                <Grid rows={this.state.rows} columns={this.columns}>
                    <FilteringState defaultFilters={this.filters} onFiltersChange={this.handleChangeFilters} />
                    <SortingState
                        defaultSorting={this.sorting}
                        onSortingChange={this.handleChangeSorting}
                        columnExtensions={[
                            { columnName: 'oldData', sortingEnabled: false },
                            { columnName: 'newData', sortingEnabled: false },
                            { columnName: 'failedIds', sortingEnabled: false },
                            { columnName: 'updatedIds', sortingEnabled: false },
                        ]}
                    />
                    <Table
                        columnExtensions={this.columnExtensions}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                        )}
                        cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                        rowComponent={StyledRow}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        showSortingControls
                    />
                    <TableFilterRow cellComponent={StyledFilterCell} />
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel pageSizes={this.pageSizes} containerComponent={PagingContainer} />
                </Grid>
            </div>
        );
    }
}

UpdateEventLog.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
        verticalAlign: 'top',
    },
});

const stylesForCustomFilter = (theme) => ({
    cell: {
        width: '100%',
        padding: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
});

const StyledFilterCell = withStyles(stylesForCustomFilter)(FilterCell);

const PagingContainer = (props) => <PagingPanel.Container {...props} className="table-pagination" />;

const Row = (props) => {
    const { children, classes, ...rest } = props;

    let className;
    const isGhosting = rest.tableRow.row['isGhosting'];
    if (isGhosting) {
        className = classes.ghosting;
    }

    return (
        <Table.Row {...rest} className={className}>
            {children}
        </Table.Row>
    );
};

const rowStyles = {
    ghosting: {
        backgroundColor: '#fff9d4',
    },
};
const StyledRow = withStyles(rowStyles)(Row);

export default withTranslation()(UpdateEventLog);
