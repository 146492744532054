import React, { Suspense } from 'react';
import { render } from 'react-dom';
import './style.css';
import params from './params';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';
import initSentry from './handlers/SentryHandler';
import { Capacitor } from '@capacitor/core';
import Wrapper from './components/Template/Wrapper';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import { pinSafeAreas } from './utils';
import firebase from './service/Firebase';

const pathname = window.location.pathname;
if (pathname.startsWith('/sharedmap/')) {
    window.location.href = pathname.replace('/sharedmap/', '/map/');
}

initSentry(params);

if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios' && Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.setStyle({ style: StatusBarStyle.Light });
    pinSafeAreas();
}

firebase.init();

render(
    <I18nextProvider i18n={i18n}>
        <Suspense fallback={'Loading...'}>
            <Wrapper />
        </Suspense>
    </I18nextProvider>,
    document.getElementById('app'),
);
