import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../locales/i18n';
import AbstractField from '../../../../service/workflow_actions/forms/fields/AbstractField';
import Button from '../../../../service/workflow_actions/forms/fields/Button';
import Checkbox from '../../../../service/workflow_actions/forms/fields/Checkbox';
import DateTime from '../../../../service/workflow_actions/forms/fields/DateTime';
import Decimal from '../../../../service/workflow_actions/forms/fields/Decimal';
import File from '../../../../service/workflow_actions/forms/fields/File';
import Html from '../../../../service/workflow_actions/forms/fields/Html';
import Label from '../../../../service/workflow_actions/forms/fields/Label';
import PickList from '../../../../service/workflow_actions/forms/fields/PickList';
import Style from '../../../../service/workflow_actions/forms/fields/Style';
import Text from '../../../../service/workflow_actions/forms/fields/Text';
import Form from '../../../../service/workflow_actions/forms/Form';
import FormDialog from '../../../FormDialog';
import { CallContext } from '../../../utils/CallContext';

import ButtonForm from './ButtonForm';
import CheckboxForm from './CheckboxForm';
import DateTimeForm from './DateTimeForm';
import DecimalForm from './DecimalForm';
import FileForm from './FileForm';
import HtmlForm from './HtmlForm';
import LabelForm from './LabelForm';
import PickListForm from './PicklistForm';
import StyleForm from './StyleForm';
import TextForm from './TextForm';

const t = i18n.t.bind(i18n);

class FieldFormModal extends React.PureComponent {
    render() {
        return (
            <FormDialog
                title={t('workflow_actions.forms.field.' + this.props.field.getType() + '.title')}
                onSave={this.props.onSave}
                onCancel={this.props.onCancel}
                fullWidth
            >
                {this.renderForm()}
            </FormDialog>
        );
    }

    renderForm() {
        if (this.props.field instanceof Button) {
            return <ButtonForm {...this.props} />;
        } else if (this.props.field instanceof Checkbox) {
            return <CheckboxForm {...this.props} />;
        } else if (this.props.field instanceof DateTime) {
            return <DateTimeForm {...this.props} />;
        } else if (this.props.field instanceof Decimal) {
            return <DecimalForm {...this.props} />;
        } else if (this.props.field instanceof Label) {
            return <LabelForm {...this.props} />;
        } else if (this.props.field instanceof PickList) {
            return <PickListForm {...this.props} />;
        } else if (this.props.field instanceof Text) {
            return <TextForm {...this.props} />;
        } else if (this.props.field instanceof File) {
            return <FileForm {...this.props} />;
        } else if (this.props.field instanceof Style) {
            return <StyleForm {...this.props} />;
        } else if (this.props.field instanceof Html) {
            return <HtmlForm {...this.props} />;
        } else {
            throw new Error('Unknown field type');
        }
    }
}

FieldFormModal.propTypes = {
    account: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,

    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(AbstractField).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default FieldFormModal;
