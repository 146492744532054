import React from 'react';
import PopupNotification from './index';
import { withTranslation } from 'react-i18next';

class SsuNotification extends PopupNotification {
    getTitle = () => {
        return this.props.t('popup_notification.ssu_notification.title');
    };

    getContent = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <p>{t('popup_notification.ssu_notification.p.1')}</p>
                <ul>
                    <li>{t('popup_notification.ssu_notification.ul.li.1')}</li>
                    <li>{t('popup_notification.ssu_notification.ul.li.2')}</li>
                </ul>
                <p>{t('popup_notification.ssu_notification.p.2')}</p>
            </React.Fragment>
        );
    };
}

export default withTranslation()(SsuNotification);
