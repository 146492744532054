export interface OrgSettings {
    smtp?: SmtpCredentials | null;
}

export enum SmtpPort {
    SSL = 465,
    TLS = 587,
}

export interface SmtpCredentials {
    host: string;
    port: SmtpPort;
    username: string;
    password: string;
}
