import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { Api, Routing } from 'interfaces';
import RouteSettingsSerializer from 'service/Serializer/RouteSettingsSerializer';

class RoutingSettingsMappingService extends BackendService {
    async getUsersSettingsData(userIds: number[]): Promise<Routing.Settings.UsersMappingRecordValueMap> {
        const url = reverse(apiRoutes.routes.settings.getUsersSettingsData);
        const response: Api.Routing.Settings.GetUsersSettingsDataApiResponse = await this.requestApi(url, 'POST', {
            userIds,
        });
        return RouteSettingsSerializer.denormalizeUsersSettings(response);
    }
}

export default RoutingSettingsMappingService;
