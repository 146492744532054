const DEFAULT_DETAILS = {
    form: null,
    changes: [],
};

const CHANGE_TYPES = {
    SHOW_FIELD: 'show_field',
    HIDE_FIELD: 'hide_field',
    ENABLE_FIELD: 'enable_field',
    DISABLE_FIELD: 'disable_field',
    SET_ENABLED: 'set_enabled',
    SET_VISIBILITY: 'set_visibility',
    SET_LABEL: 'set_label',
    SET_DESCRIPTION: 'set_description',
    SET_VALUE: 'set_value',
    SET_PICKLIST_ITEMS: 'set_picklist_items',
    MAKE_REQUIRED: 'make_required',
    MAKE_OPTIONAL: 'make_optional',
    SET_REQUIRED: 'set_required',
};

const CHANGE_CUSTOM_API_NAME = '__custom_api_name__';

const DEFAULT_CHANGE = {
    type: null,
    field_api_name: null,
    field_api_name_formula: null,
    value: null,
};

export { DEFAULT_DETAILS, CHANGE_TYPES, CHANGE_CUSTOM_API_NAME, DEFAULT_CHANGE };
