import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormHelperText, FormControl } from '@material-ui/core';
import Hint from '../Hint';
import { withTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import './style.css';
import arrayMove from 'array-move';
import WorkflowGroup from './WorkflowGroup';
import { workflowActionManager } from '../../service/WorkflowActionManager';
import { WorkflowGroupManager } from '../../service/WorkflowGroupManager';
import { CallContext } from '../utils/CallContext';
import AddIconButton from '../CustomButton/AddIconButton';

const SortableList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((group, index) => {
                if (group && group.type === props.groupType) {
                    return (
                        <SortableItem group={group} groupIndex={index} key={`item-${index}`} index={index} {...props} />
                    );
                }
                return null;
            })}
        </div>
    );
});

const SortableItem = SortableElement(WorkflowGroup);

class WorkflowGroups extends React.Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            allActions: [],
        };
    }

    componentDidMount() {
        workflowActionManager.list(this.props.account.id).then((response) => {
            this.setState({
                allActions: response.items,
            });
        });
    }

    addGroup = () => {
        const groups = [...this.props.groups];
        groups.push(WorkflowGroupManager.getDefaultGroup(this.props.groupType));
        this.props.onChange(groups);
    };

    deleteGroup = (index) => {
        const groups = [...this.props.groups].filter((v, i) => i !== index);
        this.props.onChange(groups);
    };

    handleGroupChange = (index, group) => {
        const groups = [...this.props.groups];
        groups[index] = group;
        this.props.onChange(groups);
    };

    onSortGroupsEnd = ({ oldIndex, newIndex }) => {
        const groups = arrayMove([...this.props.groups], oldIndex, newIndex);
        this.props.onChange(groups);
    };

    render() {
        return (
            <FormControl
                ref={this.ref}
                fullWidth
                margin="dense"
                component="fieldset"
                error={this.props.errors.has(this.props.name)}
            >
                <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                    <Grid item>
                        <b style={{ marginRight: 8 }}>{this.props.title}</b>
                        {this.props.hint && <Hint type="tooltip">{this.props.hint}</Hint>}
                    </Grid>
                    <Grid item>
                        <AddIconButton small onClick={this.addGroup} />
                    </Grid>
                </Grid>

                {!(
                    this.props.groups && this.props.groups.filter((g) => g && g.type === this.props.groupType).length
                ) && <div style={{ padding: '10px 0' }}>{this.props.placeholder}</div>}

                {this.ref.current && (
                    <SortableList
                        items={this.props.groups}
                        groupType={this.props.groupType}
                        parentNamespace={this.props.parentNamespace}
                        onSortEnd={this.onSortGroupsEnd}
                        useDragHandle
                        helperClass="sortable-item"
                        allActions={this.state.allActions}
                        account={this.props.account}
                        dataSources={this.props.dataSources}
                        onGroupChange={this.handleGroupChange}
                        onGroupDelete={this.deleteGroup}
                        callContext={this.props.callContext}
                        addSpecialSection={this.props.addSpecialSection}
                        addOldEntitySection={this.props.addOldEntitySection}
                        disableCallContextInheritance={this.props.disableCallContextInheritance}
                        excludedActions={this.props.excludedActions}
                        errors={this.props.errors.get(this.props.name)}
                        getContainer={() =>
                            this.ref?.current?.closest('.c-form-dialog__content') ||
                            this.ref?.current?.closest('.MuiDialog-scrollBody')
                        }
                    />
                )}

                {this.props.errors.has(this.props.name) &&
                    typeof this.props.errors.get(this.props.name) === 'string' && (
                        <FormHelperText>{this.props.errors.get(this.props.name)}</FormHelperText>
                    )}
            </FormControl>
        );
    }
}

WorkflowGroups.propTypes = {
    title: PropTypes.string.isRequired,
    hint: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    parentNamespace: PropTypes.string.isRequired,
    groupType: PropTypes.number.isRequired,
    groups: PropTypes.array,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    addSpecialSection: PropTypes.bool.isRequired,
    addOldEntitySection: PropTypes.bool.isRequired,
    disableCallContextInheritance: PropTypes.bool.isRequired,
    excludedActions: PropTypes.array,
};

WorkflowGroups.defaultProps = {
    groups: [],
    name: 'groups',
    addSpecialSection: true,
    addOldEntitySection: false,
    disableCallContextInheritance: false,
    excludedActions: [],
};

export default withTranslation('translations', { withRef: true })(WorkflowGroups);
