import React from 'react';
import {
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    Grid,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import i18n from '../../../locales/i18n';
import { getDetail, workflowActionManager } from '../../../service/WorkflowActionManager';
import moment from 'moment';
import {
    DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK,
    DETAIL_PLANNER_BUSINESS_HOURS_END_TIME,
    DETAIL_PLANNER_BUSINESS_HOURS_START_TIME,
    DETAIL_PLANNER_CREATE_ROUTES,
    DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS,
    DETAIL_PLANNER_DEFAULT_VISIT_DURATION,
    DETAIL_PLANNER_END_DATE,
    DETAIL_PLANNER_FIRST_DAY_OFFSET,
    DETAIL_PLANNER_IN_ENTITY_CONDITION,
    DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION,
    DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD,
    DETAIL_PLANNER_IN_ENTITY_DS_ID,
    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION,
    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION,
    DETAIL_PLANNER_IN_ENTITY_ID,
    DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD,
    DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD,
    DETAIL_PLANNER_MODE,
    DETAIL_PLANNER_OUT_ENTITY_CREATE,
    DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_DS_ID,
    DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_ID,
    DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD,
    DETAIL_PLANNER_START_DATE,
    DETAIL_PLANNER_USERS,
    DETAIL_PLANNER_VISITED_ENTITY_CONDITION,
    DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION,
    DETAIL_PLANNER_VISITED_ENTITY_DS_ID,
    DETAIL_PLANNER_VISITED_ENTITY_ID,
    DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD,
    DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD,
    DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD,
    DETAIL_PLANNER_WINDOW_INCREASE_STEP,
} from '../../../references/wokflow/VisitPlannerAction';
import MultipleSelect from '../../Controls/MultipleSelect';
import { userManager } from '../../../service/UserManager';
import FormHelperText from '@material-ui/core/FormHelperText';
import { GeneralSection, InSection, OutSection, VisitedSection } from './FormParts';
import Hint from '../../Hint';
import { TYPE_ENTITY_BASE } from '../../utils/CallContext';

const t = i18n.t.bind(i18n);
const HintedSelect = withStyles({ icon: { marginRight: 18 } })(Select);

const PLANNER_MODE = {
    single: 'single',
    team: 'team',
};

const DEFAULT_VALUES = {
    [DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS]: 14,
    [DETAIL_PLANNER_DEFAULT_VISIT_DURATION]: 30,
};

class Form extends React.PureComponent {
    constructor(props) {
        super(props);

        this.props.onPreloadInit();

        const currentAction = { ...props.action };
        if (!currentAction.details[DETAIL_PLANNER_MODE]) {
            currentAction.details[DETAIL_PLANNER_MODE] = PLANNER_MODE.single;
        }
        if (isNil(currentAction.details[DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS])) {
            currentAction.details[DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS] =
                DEFAULT_VALUES[DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS];
        }
        if (isNil(currentAction.details[DETAIL_PLANNER_DEFAULT_VISIT_DURATION])) {
            currentAction.details[DETAIL_PLANNER_DEFAULT_VISIT_DURATION] =
                DEFAULT_VALUES[DETAIL_PLANNER_DEFAULT_VISIT_DURATION];
        }
        if (isNil(currentAction.details[DETAIL_PLANNER_CREATE_ROUTES])) {
            currentAction.details[DETAIL_PLANNER_CREATE_ROUTES] = false;
        }
        if (isNil(currentAction.details[DETAIL_PLANNER_OUT_ENTITY_CREATE])) {
            currentAction.details[DETAIL_PLANNER_OUT_ENTITY_CREATE] = false;
        }

        this.state = {
            errors: new Map(),
            currentAction,
            usersOptions: [],
        };
    }

    componentDidMount() {
        userManager
            .getAccountUsers(this.props.accountId)
            .then((users) => {
                this.setState(
                    {
                        usersOptions: [...users],
                    },
                    () => {
                        this.props.onPreloadSuccess();
                    },
                );
            })
            .catch((error) => this.props.onPreloadError(error.message));
    }

    submit() {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then((action) => {
                action.entity = getDetail(DETAIL_PLANNER_IN_ENTITY_ID, action.details);
                return workflowActionManager.save(this.props.accountId, action);
            })
            .then((action) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(action);
            })
            .catch((errors) => {
                let violations;
                if (errors.details) {
                    violations = errors.details;
                    const details = violations.get('details');
                    if (details) {
                        for (let detail in details) {
                            violations.set(detail, details[detail]);
                        }
                    }
                } else {
                    violations = errors;
                }
                this.setState({
                    errors: violations,
                });
                this.props.onSubmitError && this.props.onSubmitError(t('validation_errors'));
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }

            const requiredDetails = [
                DETAIL_PLANNER_START_DATE,
                DETAIL_PLANNER_END_DATE,
                DETAIL_PLANNER_DEFAULT_VISIT_DURATION,
                DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS,
                DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK,
                DETAIL_PLANNER_BUSINESS_HOURS_START_TIME,
                DETAIL_PLANNER_BUSINESS_HOURS_END_TIME,
                DETAIL_PLANNER_IN_ENTITY_ID,
            ];

            if (getDetail(DETAIL_PLANNER_MODE, currentAction.details, PLANNER_MODE.single) === PLANNER_MODE.team) {
                requiredDetails.push(DETAIL_PLANNER_USERS);
            } else {
                requiredDetails.push(DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD);
            }

            requiredDetails.forEach((detail) => {
                const value = getDetail(detail, currentAction.details);
                if (value === null || (Array.isArray(value) && value.length === 0)) {
                    errors.set(detail, t('errors.not_empty'));
                }
            });

            const constraints = this.formConstraints();
            for (let constraint in constraints) {
                const value = getDetail(constraint, currentAction.details);
                if (value === null) {
                    continue;
                }
                if (constraints[constraint].min !== undefined && value < constraints[constraint].min) {
                    let min = constraints[constraint].min;
                    if (constraints[constraint].minError !== undefined) {
                        min = constraints[constraint].minError;
                    }
                    errors.set(constraint, t('validation.value.min', { min }));
                }
                if (constraints[constraint].max !== undefined && value > constraints[constraint].max) {
                    let max = constraints[constraint].max;
                    if (constraints[constraint].maxError !== undefined) {
                        max = constraints[constraint].maxError;
                    }
                    errors.set(constraint, t('validation.value.max', { max }));
                }
            }

            const checkEntityExistsDetails = [
                DETAIL_PLANNER_IN_ENTITY_ID,
                DETAIL_PLANNER_OUT_ENTITY_ID,
                DETAIL_PLANNER_VISITED_ENTITY_ID,
            ];
            checkEntityExistsDetails.forEach((detail) => {
                if (getDetail(detail, currentAction.details) !== null) {
                    const exists = this.props.dataSources.find((ds) => {
                        return ds.entityCounters.find((entityCounter) => {
                            return entityCounter.entity.id === getDetail(detail, currentAction.details);
                        });
                    });
                    if (!exists) {
                        errors.set(detail, t('errors.not_empty'));
                    }
                }
            });

            const createRoutesEnabled = !!getDetail(DETAIL_PLANNER_CREATE_ROUTES, currentAction.details);
            const outObjectEnabled = !!getDetail(DETAIL_PLANNER_OUT_ENTITY_CREATE, currentAction.details);

            if (!createRoutesEnabled && !outObjectEnabled) {
                errors.set(
                    DETAIL_PLANNER_CREATE_ROUTES,
                    t('workflow_actions.form.visit_planner.validation.nothing_to_create'),
                );
                errors.set(
                    DETAIL_PLANNER_OUT_ENTITY_CREATE,
                    t('workflow_actions.form.visit_planner.validation.nothing_to_create'),
                );
            }

            if (outObjectEnabled) {
                const outRequiredDetails = [
                    DETAIL_PLANNER_OUT_ENTITY_ID,
                    DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
                ];
                outRequiredDetails.forEach((detail) => {
                    if (getDetail(detail, currentAction.details) === null) {
                        errors.set(detail, t('errors.not_empty'));
                    }
                });
            }

            const visitedObjectSelected =
                !!getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, currentAction.details) ||
                !!getDetail(DETAIL_PLANNER_VISITED_ENTITY_ID, currentAction.details);
            if (visitedObjectSelected) {
                const visitedRequiredDetails = [
                    DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD,
                    DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD,
                    DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD,
                ];
                visitedRequiredDetails.forEach((detail) => {
                    if (getDetail(detail, currentAction.details) === null) {
                        errors.set(detail, t('errors.not_empty'));
                    }
                });
            }

            if (!currentAction.callContext.isValid()) {
                errors.set('callContext', t('errors.not_empty'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    formConstraints = () => {
        const { currentAction } = this.state;
        return {
            [DETAIL_PLANNER_WINDOW_INCREASE_STEP]: {
                min: 1,
            },
            [DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS]: {
                min: 1,
            },
            [DETAIL_PLANNER_FIRST_DAY_OFFSET]: {
                min: 0,
            },
            [DETAIL_PLANNER_DEFAULT_VISIT_DURATION]: {
                min: 0,
            },
            [DETAIL_PLANNER_BUSINESS_HOURS_END_TIME]: {
                get min() {
                    const startDate = getDetail(DETAIL_PLANNER_BUSINESS_HOURS_START_TIME, currentAction.details);
                    const orig = new Date(startDate);
                    const date = new Date(
                        orig.getUTCFullYear(),
                        orig.getUTCMonth(),
                        orig.getUTCDate(),
                        orig.getUTCHours(),
                        orig.getUTCMinutes(),
                        orig.getUTCSeconds(),
                        orig.getUTCMilliseconds(),
                    );
                    return moment(date).add(1, 'm').toDate();
                },
                get minError() {
                    return moment(this.min).format('hh:mm A');
                },
            },
            [DETAIL_PLANNER_END_DATE]: {
                get min() {
                    const startDate = getDetail(DETAIL_PLANNER_START_DATE, currentAction.details);
                    return moment(startDate).hour(0).minute(0).second(0).millisecond(0).add(1, 'd').toDate();
                },
                get minError() {
                    return moment(this.min).format('MM/DD/YYYY');
                },
            },
        };
    };

    handleInputChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;

        this.setState(
            (state) => {
                return {
                    currentAction: { ...state.currentAction, [name]: value },
                };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
        this.removeErrorOnChange(name);
    };

    handleUsersChange = (event) => {
        let value = event.target.value;
        if (value === null) {
            value = [];
        }
        const { usersOptions } = this.state;
        value = value.filter((item) => usersOptions.find((user) => user.id === item));
        this.updateDetails(DETAIL_PLANNER_USERS, value);
    };

    handleDetailsChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (event.target.type === 'number') {
            if (value === '') {
                value = null;
            } else {
                value = Number(value);
            }
        }
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        if (value === undefined) {
            value = null;
        }

        if (
            name === DETAIL_PLANNER_IN_ENTITY_ID ||
            name === DETAIL_PLANNER_OUT_ENTITY_ID ||
            name === DETAIL_PLANNER_VISITED_ENTITY_ID
        ) {
            let dsIdDetail;
            if (name === DETAIL_PLANNER_IN_ENTITY_ID) {
                dsIdDetail = DETAIL_PLANNER_IN_ENTITY_DS_ID;
            } else if (name === DETAIL_PLANNER_OUT_ENTITY_ID) {
                dsIdDetail = DETAIL_PLANNER_OUT_ENTITY_DS_ID;
            } else {
                dsIdDetail = DETAIL_PLANNER_VISITED_ENTITY_DS_ID;
            }
            let dsId = event.currentTarget?.dataset?.ds;
            if (dsId !== undefined) {
                dsId = Number(dsId);
            } else {
                dsId = null;
            }

            this.updateDetails([dsIdDetail, name], [dsId, value], () => this.onEntityUpdate(name, value));
            return;
        }

        this.updateDetails(name, value);
    };

    updateCallContext = (entityType, entityId) => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (entityId) {
                    currentAction.callContext.replace(entityType, entityId);
                } else {
                    currentAction.callContext.remove(entityType);
                }
                let errors = state.errors;
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    updateDetails = (name, value, callback = () => {}) => {
        this.setState((state) => {
            const currentAction = cloneDeep(state.currentAction);
            if (!currentAction.details) {
                currentAction.details = {};
            }

            if (Array.isArray(name)) {
                if (name.length !== value.length) {
                    throw new Error();
                }
                for (const i in name) {
                    set(currentAction.details, name[i], value[i]);
                }
            } else {
                set(currentAction.details, name, value);
            }

            return { currentAction };
        }, callback);
        this.removeErrorOnChange(name);
        this.props.onModified && this.props.onModified(true);
    };

    removeErrorOnChange(name) {
        this.setState((state) => {
            let errors = state.errors;
            const names = Array.isArray(name) ? name : [name];
            for (let i in names) {
                if (errors.has(names[i])) {
                    errors = new Map(errors);
                    errors.delete(names[i]);
                    if (errors.size === 0) {
                        this.props.onSubmitError(null);
                    }
                }
            }
            return { errors };
        });
    }

    prepareEntityOptions() {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={dataSource.id + '.' + entity.id} value={entity.id} data-ds={dataSource.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    }

    onEntityUpdate = (entityIdDetail, entityId) => {
        if (entityIdDetail === DETAIL_PLANNER_IN_ENTITY_ID) {
            this.updateDetails(
                [
                    DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD,
                    DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD,
                    DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD,
                    DETAIL_PLANNER_IN_ENTITY_CONDITION,
                    DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION,
                    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION,
                    DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION,
                ],
                [null, null, null, null, null, null, null],
                () => this.updateCallContext(TYPE_ENTITY_BASE, entityId),
            );
        }
        if (entityIdDetail === DETAIL_PLANNER_OUT_ENTITY_ID) {
            this.updateDetails(
                [
                    DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
                    DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD,
                ],
                [null, null, null, null, null, null, null],
            );
        }
        if (entityIdDetail === DETAIL_PLANNER_VISITED_ENTITY_ID) {
            this.updateDetails(
                [
                    DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD,
                    DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD,
                    DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD,
                    DETAIL_PLANNER_VISITED_ENTITY_CONDITION,
                    DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION,
                ],
                [null, null, null, null, null],
            );
        }
    };

    render() {
        const { currentAction, errors, usersOptions } = this.state;
        const constraints = this.formConstraints();

        return (
            <React.Fragment>
                <form noValidate autoComplete="off">
                    <TextField
                        label={t('workflow_actions.form.visit_planner.name')}
                        data-testid="workflow_actions.form.visit_planner.name"
                        fullWidth
                        required
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        margin="normal"
                        label={t('workflow_actions.form.visit_planner.api_name')}
                        data-testid="workflow_actions.form.visit_planner.api_name"
                        fullWidth
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <Grid container>
                        <GeneralSection
                            errors={errors}
                            currentAction={currentAction}
                            formConstraints={constraints}
                            onDetailChange={this.handleDetailsChange}
                            updateDetailCallback={this.updateDetails}
                        />

                        <Grid item xs={12}>
                            <div style={{ marginTop: 40, marginBottom: 8 }}>
                                <span style={{ fontWeight: 600 }}>
                                    {t('workflow_actions.form.visit_planner.in')}
                                    <Hint
                                        iProps={{ style: { marginLeft: 8 } }}
                                        TooltipProps={{ className: 'tooltip-question' }}
                                    >
                                        {t('workflow_actions.form.visit_planner.in.tooltip')}
                                    </Hint>
                                </span>
                            </div>
                        </Grid>
                        <InSection
                            errors={errors}
                            currentAction={currentAction}
                            onDetailChange={this.handleDetailsChange}
                            updateDetailCallback={this.updateDetails}
                            accountId={this.props.accountId}
                            dataSources={this.props.dataSources}
                        />

                        <Grid item xs={12}>
                            <div style={{ marginTop: 40, marginBottom: 8 }}>
                                <span style={{ fontWeight: 600 }}>
                                    {t('workflow_actions.form.visit_planner.out')}
                                    <Hint
                                        iProps={{ style: { marginLeft: 8 } }}
                                        TooltipProps={{ className: 'tooltip-question' }}
                                    >
                                        {t('workflow_actions.form.visit_planner.out.tooltip')}
                                    </Hint>
                                </span>
                            </div>
                        </Grid>
                        <OutSection
                            errors={errors}
                            currentAction={currentAction}
                            onDetailChange={this.handleDetailsChange}
                            updateDetailCallback={this.updateDetails}
                            accountId={this.props.accountId}
                            dataSources={this.props.dataSources}
                        />

                        <Grid item xs={12}>
                            <div style={{ marginTop: 40, marginBottom: 8 }}>
                                <span style={{ fontWeight: 600 }}>
                                    {t('workflow_actions.form.visit_planner.visited')}
                                    <Hint
                                        iProps={{ style: { marginLeft: 8 } }}
                                        TooltipProps={{ className: 'tooltip-question' }}
                                    >
                                        {t('workflow_actions.form.visit_planner.visited.tooltip')}
                                    </Hint>
                                </span>
                            </div>
                        </Grid>
                        <VisitedSection
                            errors={errors}
                            currentAction={currentAction}
                            onDetailChange={this.handleDetailsChange}
                            updateDetailCallback={this.updateDetails}
                            accountId={this.props.accountId}
                            dataSources={this.props.dataSources}
                        />

                        <Collapse
                            style={{ width: '100%' }}
                            in={
                                getDetail(DETAIL_PLANNER_MODE, currentAction.details, PLANNER_MODE.single) ===
                                PLANNER_MODE.team
                            }
                        >
                            <Grid item xs={12}>
                                <div style={{ marginTop: 40, marginBottom: 8 }}>
                                    <span style={{ fontWeight: 600 }}>
                                        {t('workflow_actions.form.visit_planner.users')}
                                        <Hint
                                            iProps={{ style: { marginLeft: 8 } }}
                                            TooltipProps={{ className: 'tooltip-question' }}
                                        >
                                            {t('workflow_actions.form.visit_planner.users.tooltip')}
                                        </Hint>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_USERS)}>
                                    <MultipleSelect
                                        name={DETAIL_PLANNER_USERS}
                                        disabled={
                                            getDetail(
                                                DETAIL_PLANNER_MODE,
                                                currentAction.details,
                                                PLANNER_MODE.single,
                                            ) === PLANNER_MODE.single
                                        }
                                        value={
                                            getDetail(
                                                DETAIL_PLANNER_MODE,
                                                currentAction.details,
                                                PLANNER_MODE.single,
                                            ) === PLANNER_MODE.single
                                                ? [userManager.getCurrentUser().id]
                                                : getDetail(DETAIL_PLANNER_USERS, currentAction.details, [])
                                        }
                                        displayEmpty
                                        onChange={this.handleUsersChange}
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {usersOptions
                                                    .filter((item) => selected.includes(item.id))
                                                    .map((item) => (
                                                        <Chip key={item.id} label={item.name} style={{ margin: 2 }} />
                                                    ))}
                                            </div>
                                        )}
                                        fullWidth
                                        data-testid="workflow_actions.form.visit_planner.users"
                                    >
                                        {usersOptions.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={
                                                            !!getDetail(
                                                                DETAIL_PLANNER_USERS,
                                                                currentAction.details,
                                                                [],
                                                            ).find((arrayItem) => arrayItem === item.id)
                                                        }
                                                    />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            );
                                        })}
                                    </MultipleSelect>
                                    {errors.has(DETAIL_PLANNER_USERS) && (
                                        <FormHelperText>{errors.get(DETAIL_PLANNER_USERS)}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Collapse>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={errors.has(DETAIL_PLANNER_WINDOW_INCREASE_STEP)}>
                                <TextField
                                    required
                                    label={t('workflow_actions.form.visit_planner.detail_planner_window_increase_step')}
                                    data-testid="workflow_actions.form.visit_planner.detail_planner_window_increase_step"
                                    type="number"
                                    fullWidth
                                    name={DETAIL_PLANNER_WINDOW_INCREASE_STEP}
                                    value={getDetail(DETAIL_PLANNER_WINDOW_INCREASE_STEP, currentAction.details)}
                                    helperText={errors.get(DETAIL_PLANNER_WINDOW_INCREASE_STEP) || ''}
                                    error={errors.has(DETAIL_PLANNER_WINDOW_INCREASE_STEP)}
                                    onChange={this.handleDetailsChange}
                                    InputProps={{
                                        endAdornment: (
                                            <React.Fragment>
                                                {t('workflow_actions.form.visit_planner.days')}
                                            </React.Fragment>
                                        ),
                                        inputProps: {
                                            min: constraints[DETAIL_PLANNER_WINDOW_INCREASE_STEP].min,
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

Form.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    action: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onPreloadInit: PropTypes.func,
    onPreloadSuccess: PropTypes.func,
    onPreloadError: PropTypes.func,
};

export { Form as VisitPlannerForm, PLANNER_MODE, HintedSelect };
