import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import dispatcher from '../dispatcher';
import events from '../../events';
import { User } from '../../interfaces';
import { weAreInNativeApp } from '../../utils';
import { LogEventOptions } from '@capacitor-firebase/analytics/dist/esm/definitions';

export default class Analytics {
    private initialized: boolean = false;

    public init() {
        if (!weAreInNativeApp()) {
            return;
        }

        if (this.initialized) {
            return;
        }
        this.initialized = true;

        FirebaseAnalytics.setEnabled({ enabled: true });
        dispatcher.subscribe([events.EVENT_CURRENT_USER_CHANGED], this, (user: User.User | null | undefined) =>
            this.setUser(user),
        );
    }

    public async setCurrentScreen(screenName: string, screenClassOverride?: string | null): Promise<void> {
        if (!this.initialized) {
            return;
        }

        return await FirebaseAnalytics.setCurrentScreen({
            screenName: screenName,
            screenClassOverride: screenClassOverride,
        });
    }

    private setUser = async (user: User.User | null | undefined) => {
        const userId = user ? user.id.toString() : null;
        await FirebaseAnalytics.setUserId({ userId });
    };

    public sendEvent = (option: LogEventOptions): Promise<void> => {
        return FirebaseAnalytics.logEvent(option);
    };
}
