import dispatcher from './dispatcher';
import events from '../events';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { isValidLatLng } from '../utils';

const DEFAULT_RADIUS = 2;
const DEFAULT_CIRCLES = 1;

class BasePointManager {
    reset() {
        this.point = null;
        this.radius = DEFAULT_RADIUS;
        this.circles = DEFAULT_CIRCLES;
    }

    constructor() {
        this.point = null;
        this.radius = DEFAULT_RADIUS;
        this.circles = DEFAULT_CIRCLES;
    }

    getCircles() {
        return this.circles;
    }

    setCircles(circles) {
        if (this.circles === circles) {
            return;
        }
        this.circles = circles;
        //console.log('dispatch', 'BASE_POINT_CIRCLES');
        dispatcher.dispatch(events.BASE_POINT_CIRCLES, circles);
    }

    getRadius() {
        return this.radius;
    }

    setRadius(radius) {
        if (this.radius === radius) {
            return;
        }
        this.radius = radius;
        //console.log('dispatch', 'BASE_POINT_RADIUS');
        dispatcher.dispatch(events.BASE_POINT_RADIUS, radius);
    }

    setBasePoint(point, skipSamenessCheck = false) {
        // most probably we don't want change zoom via BasePointManager, and isGeolocation became unused
        if (
            !skipSamenessCheck &&
            isEqual(omit(this.point, ['isGeolocation', 'zoom']), omit(point, ['isGeolocation', 'zoom']))
        ) {
            //if (isEqual(this.point, point)) {
            return;
        }

        if (point !== null && !isValidLatLng(point)) {
            console.error('Invalid base point', point);

            return;
        }

        this.point = point;
        dispatcher.dispatch(events.BASE_POINT, this.point);
    }

    setAddressChanged(point) {
        dispatcher.dispatch(events.SEARCH_ADDRESS_UPDATED, point);
    }

    getBasePoint() {
        return this.point;
    }
}

export default new BasePointManager();
