import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import Player from 'video.js/dist/types/player';
import { FileType } from 'interfaces/file';

interface MediaPlayerProps {
    url: string;
    type: FileType;
    contentType: string;
}

class MediaPlayer extends React.Component<MediaPlayerProps> {
    player?: Player;
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidMount() {
        this.initPlayer();
    }

    componentDidUpdate(prevProps: Readonly<MediaPlayerProps>) {
        if (this.props.url !== prevProps.url || this.props.contentType !== prevProps.contentType) {
            this.destroyPlayer();
            this.initPlayer();
        }
    }

    componentWillUnmount() {
        this.destroyPlayer();
    }

    initPlayer = () => {
        this.player = videojs(this.videoRef.current!, {
            sources: [{ src: this.props.url, type: this.props.contentType }],
            controls: true,
            aspectRatio: '16:9',
        });
    };

    destroyPlayer = () => {
        if (this.player) {
            this.player.dispose();
        }
    };

    renderVideo() {
        return <video ref={this.videoRef} className="video-js" />;
    }

    renderAudio() {
        return <audio ref={this.videoRef} className="video-js vjs-audio-only-mode" />;
    }

    render() {
        return <div data-vjs-player>{this.props.type === 'video' ? this.renderVideo() : this.renderAudio()}</div>;
    }
}

export default MediaPlayer;
