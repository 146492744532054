import React from 'react';
import { withSnackbar } from 'notistack';
import SearchDataTable, { linkColName } from '../EntityDataTable/SearchDataTable';
import { GEO_FIELDS } from '../../references/geoFields';
import entityViewManager from '../../service/EntityViewManager';
import {
    Grid,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    IconButton,
    Icon,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import territoryManagerFactory from '../../service/Territories/TerritoryManager';
import { withTranslation } from 'react-i18next';
import qsManager from '../../service/QueryStringManager';
import { NO_PIN_SYSTEM_ENTITIES } from 'references/systemEntities';

const SortFieldsComponent = (columnNameFields, structure) => {
    structure.columns.sort((a, b) => {
        if (columnNameFields.includes(b.name) || columnNameFields.includes(a.name)) {
            return columnNameFields.includes(b.name) ? 1 : -1;
        }

        if (structure.pinColumn === b.name || structure.pinColumn === a.name) {
            return structure.pinColumn === b.name ? 1 : -1;
        }

        if (b.name === linkColName || a.name === linkColName) {
            return b.name === linkColName ? 1 : -1;
        }

        if (b.name === GEO_FIELDS.STATUS || a.name === GEO_FIELDS.STATUS) {
            return b.name === GEO_FIELDS.STATUS ? 1 : -1;
        }

        return 0;
    });
};

class SearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entity: null,
            sources: null,

            selectEntityAnchor: null,

            georesult: null,

            queryParams: qsManager.getQueryParams(),
        };
    }

    log(...args) {
        if (this.isDebugMode) {
            console.log(...args);
        }
    }

    handleLoadingError = (message) => {
        this.props.enqueueSnackbar(message, { variant: 'error' });
    };

    componentDidMount() {
        const { t } = this.props;
        entityViewManager.loadSourcesData(this.props.accountId).then((response) => {
            const params = this.state.queryParams;
            const sourceId = params.source ? parseInt(params.source) : null;
            const entityName = params.entity ? params.entity : null;

            let entity = null;
            const sources = [];
            for (let source of response.sources.sort((ds1, ds2) => ds1.isSystem - ds2.isSystem)) {
                const ds = { ...source };
                const entities = [];
                for (let i = 0; i < ds.entities.length; i++) {
                    const e = { ...ds.entities[i] };
                    e.caption = ds.name + ': ' + e.title;
                    e.sourceId = ds.id;
                    entities.push(e);

                    if (sourceId === ds.id && entityName === e.name) {
                        entity = e;
                    }
                }
                ds.entities = entities;
                sources.push(ds);
            }

            if (params.entity && entity === null) {
                this.props.enqueueSnackbar(t('search_page.entity_not_found'), { variant: 'error' });
            }
            this.setState({
                sources,
                entity,
            });
        });

        territoryManagerFactory.getManager(this.props.accountId).requestTerritories();
    }

    handleSelectEntity = (entity) => {
        this.setState({
            entity,
            selectEntityAnchor: null,
        });
    };

    handleOpenEntitiesList = (event) => {
        this.setState({
            selectEntityAnchor: event.currentTarget,
        });
    };

    handleCloseEntitiesList = () => {
        this.setState({
            selectEntityAnchor: null,
        });
    };

    isNoPinSystemEntity(entityId) {
        const entity = this.state.sources.find(({ isSystem }) => isSystem)?.entities.find(({ id }) => id === entityId);

        return NO_PIN_SYSTEM_ENTITIES.includes(entity?.name);
    }

    isEntityHighlited(entityId) {
        if (this.isNoPinSystemEntity(entityId) === true) {
            return false;
        }

        const entity = this.props.dataSources?.flatMap(({ entities }) => entities).find(({ id }) => id === entityId);

        if (!entity) {
            return false;
        }

        if (entity.isPinFieldDependent || entity.useLatLngFields || entity.pin) {
            return true;
        }

        return false;
    }

    handleOpenGeoResponse = (entityId, recordId) => {
        entityViewManager.getGeocodingResult(entityId, recordId).then((georesult) => {
            this.setState({
                georesult,
            });
        });
    };

    handleCloseGeoResponse = () => {
        this.setState({
            georesult: null,
        });
    };

    render() {
        const { t } = this.props;

        if (this.state.sources === null) {
            return t('loading');
        }

        const sources = this.state.sources;

        return (
            <div className="c-search-page">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <h1 style={{ margin: 0 }}>{t('search_page.title')}</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={this.handleOpenEntitiesList}
                            color={this.state.entity ? 'secondary' : 'default'}
                            data-testid="search_page.entity_not_selected"
                        >
                            {this.state.entity ? this.state.entity.caption : t('search_page.entity_not_selected')}
                        </Button>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={this.state.selectEntityAnchor}
                    keepMounted
                    open={this.state.selectEntityAnchor !== null}
                    onClose={this.handleCloseEntitiesList}
                >
                    {sources.map((dataSource) => {
                        return dataSource.entities.map((entity) => (
                            <MenuItem
                                key={entity.id}
                                onClick={() => this.handleSelectEntity(entity)}
                                data-testid={'search_page.entity.' + entity.id}
                            >
                                {entity.caption}
                            </MenuItem>
                        ));
                    })}
                </Menu>

                <hr />

                <SearchDataTable
                    entityId={this.state.entity ? this.state.entity.id : null}
                    initialProcessingStatus={this.state.queryParams.status || null}
                    onLoadingError={this.handleLoadingError}
                    onViewGeocodingLog={this.handleOpenGeoResponse}
                    sortFieldsComponent={SortFieldsComponent}
                    addMainFields={true}
                    highlightStatus={this.isEntityHighlited(this.state.entity?.id)}
                />

                <Dialog open={this.state.georesult !== null} onClose={this.handleCloseGeoResponse} scroll="body">
                    <DialogTitle className="c-form-dialog__title">
                        {t('search_page.geo_response')}
                        <IconButton
                            color="default"
                            component="span"
                            onClick={this.handleCloseGeoResponse}
                            className="c-form-dialog__close"
                            data-testid="search_page.geo_response.close"
                        >
                            <Icon>close_icon</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <pre style={{ fontFamily: 'Courier New, serif', fontSize: '0.75em' }}>
                            {this.state.georesult ? JSON.stringify(this.state.georesult.response, null, 2) : ''}
                        </pre>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="default"
                            onClick={this.handleCloseGeoResponse}
                            data-testid="search_page.dialog.close"
                        >
                            {t('dialog.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

SearchPage.propTypes = {
    accountId: PropTypes.number.isRequired,
    myAccount: PropTypes.bool,
};

SearchPage.defaultProps = {
    myAccount: false,
};

export default withTranslation('translations', { withRef: true })(withRouter(withSnackbar(SearchPage)));
