import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ClickAwayListener, Icon, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Geo } from '../../interfaces';
import AddressLookup from '../AddressLookup';

interface Props extends WithTranslation {
    address: string;
    onAddressChange: (address: string) => void;
    onAddressSelect: (point: Geo.GeoLocationPoint) => void;
    label: string;
    placeholder: string;
    accountId: number;
    endAdornment?: JSX.Element | false;
    readOnly?: boolean | undefined;
    error?: boolean;
    onBlur?: () => void;
    onClearAddress?: () => void;
    showExactitude?: boolean;
}

interface State {
    showSelects: boolean;
    showResults: boolean;
}

class AddressLookupField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showSelects: false,
            showResults: false,
        };
    }

    handleShowSelectsChange = (showSelects: boolean) => {
        this.setState({ showSelects });
    };

    handleShowResultsChange = (showResults: boolean) => {
        this.setState({ showResults });
    };

    handleAddressSelect = (point: Geo.GeoLocationPoint | null) => {
        if (point === null) {
            return;
        }

        this.props.onAddressSelect(point);
        this.closeMenu();
    };

    closeMenu = () => {
        this.setState({ showSelects: false, showResults: false });
    };

    clearAddress = () => {
        if (this.props.onClearAddress) {
            this.props.onClearAddress();
        }
    };

    getEndAdornment = (): JSX.Element => {
        const { address, readOnly } = this.props;
        return (
            <InputAdornment
                style={!address || address.length === 0 || readOnly ? { overflow: 'hidden', height: 0 } : {}}
                position="end"
            >
                <IconButton
                    className="map-controls__search__clear"
                    aria-label="clear base location input"
                    onClick={this.clearAddress}
                >
                    <Icon>clear</Icon>
                </IconButton>
            </InputAdornment>
        );
    };

    render() {
        const {
            address,
            onAddressChange,
            onBlur,
            label,
            placeholder,
            accountId,
            endAdornment,
            readOnly,
            error,
            showExactitude,
        } = this.props;
        const { showResults, showSelects } = this.state;

        const inputProps: any = {
            disableUnderline: false,
            readOnly,
        };
        if (endAdornment !== false) {
            inputProps.endAdornment = endAdornment ?? this.getEndAdornment();
        }

        return (
            <ClickAwayListener onClickAway={this.closeMenu}>
                <AddressLookup
                    prospectingEnable={false}
                    showSelect={showSelects}
                    showResults={showResults}
                    resultAfterInput={true}
                    address={address}
                    onSelectedAddress={this.handleAddressSelect}
                    onChangeAddress={onAddressChange}
                    onChangeShowSelect={this.handleShowSelectsChange}
                    onChangeShowResult={this.handleShowResultsChange}
                    accountId={accountId}
                    fullWidth
                    showExactitude={showExactitude}
                >
                    <TextField
                        placeholder={placeholder}
                        label={label}
                        InputProps={inputProps}
                        fullWidth
                        error={error ?? false}
                        onBlur={onBlur}
                    />
                </AddressLookup>
            </ClickAwayListener>
        );
    }
}

export default withTranslation()(AddressLookupField);
