import React, { MouseEvent, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ProspectStructure } from 'components/types';

interface Props extends WithTranslation {
    fieldTypeDisabled: boolean;
    isOwnerField: boolean;
    item: ProspectStructure;
    onChange: (item: ProspectStructure) => void;
}

const SelectFieldTypeValue = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const { t, item, fieldTypeDisabled, onChange, isOwnerField } = props;

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeOperandType = (e: MouseEvent<HTMLElement>) => {
        const type = e.currentTarget.getAttribute('data-type');

        const changedStructureItem = { ...item };
        switch (type) {
            case 'const':
                changedStructureItem.type = 'const';
                delete changedStructureItem.prospectFieldName;
                break;
            case 'field':
                changedStructureItem.type = 'field';
                delete changedStructureItem.const;
                break;
            case 'dsUserId':
                changedStructureItem.type = 'dsUserId';
                delete changedStructureItem.const;
                delete changedStructureItem.prospectFieldName;
        }
        handleCloseMenu();
        onChange(changedStructureItem);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleOpenMenu} data-testid="prospecting.export_form.open">
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={handleCloseMenu} disableAutoFocusItem>
                <MenuItem
                    data-type="field"
                    onClick={handleChangeOperandType}
                    disabled={fieldTypeDisabled || ['const', 'dsUserId'].indexOf(item.type) === -1}
                    data-testid="prospecting.export_form.import_field"
                >
                    <span style={{ width: 28 }}>
                        {!fieldTypeDisabled && ['const', 'dsUserId'].indexOf(item.type) === -1 && <Icon>check</Icon>}
                    </span>
                    <span>{t('prospecting.export_form.import_field')}</span>
                </MenuItem>

                <MenuItem
                    data-type="const"
                    onClick={handleChangeOperandType}
                    disabled={item.type === 'const'}
                    data-testid="prospecting.export_form.import_fixed_value"
                >
                    <span style={{ width: 28 }}>
                        {(item.type === 'const' || (fieldTypeDisabled && item.type !== 'dsUserId')) && (
                            <Icon>check</Icon>
                        )}
                    </span>
                    <span>{t('prospecting.export_form.import_fixed_value')}</span>
                </MenuItem>

                {isOwnerField && (
                    <div>
                        <div className="prospecting-separator" />
                        <MenuItem
                            data-type="dsUserId"
                            onClick={handleChangeOperandType}
                            disabled={item.type === 'dsUserId'}
                            data-testid="prospecting.export_form.import_user_data_source_id"
                        >
                            <span style={{ width: 28 }}>{item.type === 'dsUserId' && <Icon>check</Icon>}</span>
                            <span>{t('prospecting.export_form.import_user_data_source_id')}</span>
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </React.Fragment>
    );
};

export default withTranslation()(SelectFieldTypeValue);
