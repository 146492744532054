import React from 'react';
import { Checkbox, TextField, FormControl } from '@material-ui/core';
import { SingleRowMultipleAutocomplete } from '../../SingleRowMultipleAutocomplete';
import { withTranslation } from 'react-i18next';

class MultiplePicklistConstantInput extends React.PureComponent {
    handleChange = (event, items) => {
        this.props.onChange(items.map((item) => item.value));
    };

    render() {
        const { picklist, disabled, t } = this.props;
        const value = Array.isArray(this.props.value) ? this.props.value : [];
        return (
            <FormControl variant="outlined" style={{ width: '200px' }}>
                <SingleRowMultipleAutocomplete
                    options={picklist}
                    value={picklist.filter((item) => value.includes(item.value))}
                    getOptionLabel={(item) => item.label}
                    onChange={this.handleChange}
                    disableClearable={true}
                    fullWidth
                    renderOption={(item) => (
                        <React.Fragment>
                            <Checkbox color="primary" checked={value.includes(item.value)} />
                            {item.label}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label={t('expresion_builder.values')}
                            data-testid="expresion_builder.values"
                        />
                    )}
                    disabled={disabled}
                />
            </FormControl>
        );
    }
}

export default withTranslation('translations', { withRef: true })(MultiplePicklistConstantInput);
