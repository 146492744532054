import React from 'react';
import { FormControl, Grid, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import i18n from '../../../locales/i18n';
import FieldFactory from '../../../service/workflow_actions/forms/FieldFactory';
import AbstractField, { FIELD_TYPES } from '../../../service/workflow_actions/forms/fields/AbstractField';
import AddIconButton from '../../CustomButton/AddIconButton';

import FieldItem from './FieldItem';
import { userManager } from 'service/UserManager';

const t = i18n.t.bind(i18n);

const SortableList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((item, index) => (
                <FieldItem
                    index={index}
                    fieldIndex={index}
                    key={item.getApiName()}
                    field={item}
                    onChange={props.onChangeItem}
                    onRemove={props.onRemoveItem}
                    handleCreateItem={props.handleCreateItem}
                />
            ))}
        </div>
    );
});

class FieldItemsList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            anchorElement: null,
        };

        this.fieldFactory = new FieldFactory();
    }

    handleOpenMenu = (event) => {
        this.setState({ anchorElement: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorElement: null });
    };

    handleCreateItem = (type, position = null) => {
        this.setState({ anchorElement: null }, () => {
            this.props.onAddItem(this.fieldFactory.createFieldByType(type), position);
        });
    };

    render() {
        const isRoleSuperAdmin = userManager.isRoleSuperAdmin();
        const fieldTypes = isRoleSuperAdmin
            ? Object.values(FIELD_TYPES)
            : Object.values(FIELD_TYPES).filter((it) => ![FIELD_TYPES.STYLE, FIELD_TYPES.HTML].includes(it));
        return (
            <FormControl fullWidth ref={this.ref}>
                <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                    <Grid item>
                        <b>{t('workflow_actions.form.open_form.fields')}</b>
                    </Grid>
                    <Grid item>
                        <AddIconButton small onClick={this.handleOpenMenu} />
                        <Menu
                            anchorEl={this.state.anchorElement}
                            open={this.state.anchorElement !== null}
                            onClose={this.handleCloseMenu}
                            disableAutoFocusItem
                        >
                            {fieldTypes.map((type) => {
                                return (
                                    <MenuItem
                                        key={type}
                                        onClick={this.handleCreateItem.bind(this, type)}
                                        data-testid={'workflow_actions.forms.field.type.' + type}
                                    >
                                        {t('workflow_actions.forms.field.type.' + type)}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1} style={{ marginBottom: 20 }}>
                    <Grid item xs={1} />
                    <Grid item xs={2}>
                        {t('workflow_actions.form.open_form.fields.type')}
                    </Grid>
                    <Grid item xs={8}>
                        {t('workflow_actions.form.open_form.fields.description')}
                    </Grid>
                </Grid>
                {this.ref.current && (
                    <SortableList
                        items={this.props.items}
                        onSortEnd={this.props.onReorder}
                        useDragHandle
                        onChangeItem={this.props.onChangeItem}
                        onRemoveItem={this.props.onRemoveItem}
                        helperClass="sortable-item"
                        handleCreateItem={this.handleCreateItem}
                        getContainer={() => this.ref?.current?.closest('.c-form-dialog__content')}
                    />
                )}
            </FormControl>
        );
    }
}

FieldItemsList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.instanceOf(AbstractField)).isRequired,
    onAddItem: PropTypes.func.isRequired,
    onChangeItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired,
};

export default FieldItemsList;
