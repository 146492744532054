import React from 'react';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import ParamsList from '../AbstractForm/ParamsList';
import { getDetail } from '../../../service/WorkflowActionManager';
import './../style.css';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import AbstractForm from '../AbstractForm/AbstractForm';
import FormulaInput from '../AbstractForm/FormulaInput';
import Hint from '../../Hint';
import validator from 'validator';
import { HTTP_METHODS, CONTENT_TYPES } from './Action';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            const method = getDetail('method', currentAction.details, '');
            const contentType = getDetail('content_type', currentAction.details, '');
            const url = getDetail('url', currentAction.details, '');
            const urlParams = getDetail('url_params', currentAction.details, []);
            const headerParams = getDetail('header_params', currentAction.details, []);
            const formParams = getDetail('form_params', currentAction.details, []);

            if (!validator.isIn(method, Object.values(HTTP_METHODS))) {
                errors.set('method', t('errors.invalid_value'));
            }
            if (!validator.isIn(contentType, Object.values(CONTENT_TYPES))) {
                console.log(contentType);
                errors.set('content_type', t('errors.invalid_value'));
            }
            if (
                !validator.isURL(url, {
                    require_protocol: true,
                    protocols: ['http', 'https'],
                })
            ) {
                errors.set('url', t('errors.invalid_value'));
            }

            if (urlParams.length) {
                const urlParamsErrors = this.validateParamsArray(urlParams);

                if (urlParamsErrors.size) {
                    errors.set('url_params', urlParamsErrors);
                }
            }
            if (headerParams.length) {
                const headerParamsErrors = this.validateParamsArray(headerParams);

                if (headerParamsErrors.size) {
                    errors.set('url_params', headerParamsErrors);
                }
            }
            if (formParams.length) {
                const formParamsErrors = this.validateParamsArray(formParams);

                if (formParamsErrors.size) {
                    errors.set('url_params', formParamsErrors);
                }
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    validateParamsArray(params) {
        const errors = new Map();

        params.forEach((item, index) => {
            const itemErrors = new Map();
            if (validator.isEmpty(item.name)) {
                itemErrors.set('name', t('errors.not_empty'));
            }
            if (validator.isEmpty(item.value)) {
                itemErrors.set('value', t('errors.not_empty'));
            }

            if (itemErrors.size) {
                errors.set(index, itemErrors);
            }
        });

        return errors;
    }

    handleDetailsChange(event) {
        this.updateDetails(event.target.name, event.target.value);

        if (event.target.name === 'method' && event.target.value === HTTP_METHODS.GET) {
            this.updateDetails('content_type', '');
        }
    }

    render() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        const method = getDetail('method', details, HTTP_METHODS.GET);
        const contentType = getDetail(
            'content_type',
            details,
            method === HTTP_METHODS.POST ? CONTENT_TYPES.FORM : CONTENT_TYPES.JSON,
        );

        return (
            <>
                <PureFormDialog
                    title={t('workflow_actions.form.invoke_url.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.invoke_url.name')}
                        data-testid="workflow_actions.form.invoke_url.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.invoke_url.api_name')}
                        data-testid="workflow_actions.form.invoke_url.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />

                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {this.renderEntitySelect(currentAction, errors)}
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                label={t('workflow_actions.form.invoke_url.method')}
                                name="method"
                                value={method}
                                onChange={this.handleDetailsChange}
                                error={errors.has('method')}
                                helperText={errors.get('method')}
                                data-testid="workflow_actions.form.invoke_url.method"
                            >
                                <MenuItem value="GET">GET</MenuItem>
                                <MenuItem value="POST">POST</MenuItem>
                                <MenuItem value="PUT">PUT</MenuItem>
                                <MenuItem value="PATCH">PATCH</MenuItem>
                                <MenuItem value="DELETE">DELETE</MenuItem>
                            </FormControlSelect>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                label={t('workflow_actions.form.invoke_url.content_type')}
                                name="content_type"
                                value={method === HTTP_METHODS.GET ? '' : contentType}
                                onChange={this.handleDetailsChange}
                                error={errors.has('content_type')}
                                helperText={errors.get('content_type')}
                                disabled={method === HTTP_METHODS.GET}
                                data-testid="workflow_actions.form.invoke_url.content_type"
                            >
                                {method === HTTP_METHODS.GET && <MenuItem value="" />}
                                {method === HTTP_METHODS.POST && (
                                    <MenuItem value={CONTENT_TYPES.FORM}>POST FORM</MenuItem>
                                )}
                                {method === HTTP_METHODS.POST && (
                                    <MenuItem value={CONTENT_TYPES.MULTIPART_FORM}>POST MULTIPART FORM</MenuItem>
                                )}
                                <MenuItem value={CONTENT_TYPES.JSON}>JSON</MenuItem>
                                <MenuItem value={CONTENT_TYPES.XML}>XML</MenuItem>
                            </FormControlSelect>
                        </Grid>
                    </Grid>

                    <FormulaInput
                        fullWidth
                        margin="dense"
                        multiline
                        required
                        label={
                            <span>
                                {t('workflow_actions.form.invoke_url.url')}{' '}
                                <Hint type="tooltip">{t('workflow_actions.form.invoke_url.url.hint')}</Hint>
                            </span>
                        }
                        name="url"
                        value={getDetail('url', details, '')}
                        callContext={this.state.currentAction.callContext}
                        onChange={this.handleDetailsChange}
                        error={errors.has('url')}
                        helperText={errors.get('url')}
                    />

                    <ParamsList
                        items={getDetail('url_params', details, [])}
                        title={t('workflow_actions.form.invoke_url.url_params')}
                        errors={errors.get('url_params')}
                        name="url_params"
                        onChange={this.handleDetailsChange}
                        callContext={this.state.currentAction.callContext}
                    />

                    <ParamsList
                        items={getDetail('header_params', details, [])}
                        title={
                            <span>
                                {t('workflow_actions.form.invoke_url.header_params')}{' '}
                                <Hint type="tooltip">{t('workflow_actions.form.invoke_url.header_params.hint')}</Hint>
                            </span>
                        }
                        errors={errors.get('header_params')}
                        name="header_params"
                        onChange={this.handleDetailsChange}
                        callContext={this.state.currentAction.callContext}
                    />

                    {[CONTENT_TYPES.FORM, CONTENT_TYPES.MULTIPART_FORM].includes(contentType) && (
                        <ParamsList
                            items={getDetail('form_params', details, [])}
                            title={t('workflow_actions.form.invoke_url.form_params')}
                            errors={errors.get('form_params')}
                            name="form_params"
                            onChange={this.handleDetailsChange}
                            callContext={this.state.currentAction.callContext}
                        />
                    )}

                    {method !== HTTP_METHODS.GET &&
                        ![CONTENT_TYPES.FORM, CONTENT_TYPES.MULTIPART_FORM].includes(contentType) && (
                            <FormulaInput
                                fullWidth
                                margin="dense"
                                multiline
                                label={t('workflow_actions.form.invoke_url.body')}
                                name="body"
                                value={getDetail('body', details, '')}
                                callContext={this.state.currentAction.callContext}
                                onChange={this.handleDetailsChange}
                                error={errors.has('body')}
                                helperText={errors.get('body')}
                            />
                        )}

                    <FormulaInput
                        fullWidth
                        margin="dense"
                        multiline
                        label={
                            <span>
                                {t('workflow_actions.form.invoke_url.resultMessage')}{' '}
                                <Hint type="tooltip">{t('workflow_actions.form.invoke_url.resultMessage.hint')}</Hint>
                            </span>
                        }
                        name="result_message"
                        value={getDetail('result_message', details, '')}
                        callContext={this.state.currentAction.callContext}
                        onChange={this.handleDetailsChange}
                        error={errors.has('result_message')}
                        helperText={errors.get('result_message')}
                    />
                </form>
            </>
        );
    }
}

export { Form as InvokeUrlForm };
