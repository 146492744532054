import { makeAutoObservable } from 'mobx';
import { DataSource } from 'interfaces';

class UserHasCrmAccountDialogManager {
    private _isOpen = false;
    private _dataSource: DataSource.SimpleDataSource | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get dataSource(): DataSource.SimpleDataSource {
        if (this._dataSource === null) {
            throw Error('The modal dialog is not open.');
        }
        return this._dataSource;
    }

    openModal = (dataSource: DataSource.SimpleDataSource) => {
        this._dataSource = dataSource;
        this._isOpen = true;
    };

    closeModal = () => {
        this._dataSource = null;
        this._isOpen = false;
    };
}

export default UserHasCrmAccountDialogManager;
