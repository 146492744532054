import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataSourceSetting } from 'service/types';

interface Props extends WithTranslation {
    definition: DataSourceSetting;
    classes: {
        required: string;
    };
}

class BaseFieldLabel extends React.PureComponent<Props> {
    render() {
        const { definition, classes, t } = this.props;

        let name = definition.label || definition.name;
        const translate = t('adapter-settings-field-' + definition.name);
        if (translate !== 'adapter-settings-field-' + definition.name) {
            name = translate;
        }
        return (
            <span style={{ whiteSpace: 'nowrap' }}>
                {name}
                {definition.required && <span className={classes.required}>&nbsp;*</span>}
            </span>
        );
    }
}

const FieldLabel = withStyles((theme) => ({
    required: {
        color: theme.palette.secondary.main,
    },
}))(BaseFieldLabel);

export default withTranslation()(FieldLabel);
