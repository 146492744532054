import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../events';
import dispatcher from './dispatcher';
import { Common } from 'interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { roleManager } from './RoleManager';

class AccountsManager extends BackendService {
    list(filters, sorting, offset, limit, type, state) {
        const url = reverse(apiRoutes.accounts);
        return this.requestApi(url, 'GET', { filters, sorting, offset, limit, type, state }).then((response) => {
            //response.items.map(item => this.state.accounts.set(item.id, item));
            return response;
        });
    }

    removeSsoLink(accountId) {
        const url = reverse(apiRoutes.account.sso, { accountId });
        return this.requestApi(url, 'DELETE').then((account) => {
            dispatcher.dispatch(events.ACCOUNT_UPDATED, this.denormalizeAccount(account));
            return account;
        });
    }

    save(account, user, isSso = false) {
        if (account.id > 0) {
            const url = reverse(apiRoutes.account, { accountId: account.id });
            return this.requestApi(url, 'PUT', { account }).then((response) => {
                dispatcher.dispatch(events.ACCOUNT_UPDATED, this.denormalizeAccount(response.account));
                return response;
            });
        } else {
            const url = reverse(apiRoutes.accounts);
            return this.requestApi(url, 'POST', { account, user, sso: isSso }).then((response) => {
                dispatcher.dispatch(events.ACCOUNT_UPDATED, this.denormalizeAccount(response.account));
                return response;
            });
        }
    }

    saveInterestedIn(accountId, interestedIn) {
        const url = reverse(apiRoutes.account.purposeOfUse, { accountId: accountId });
        return this.requestApi(url, 'POST', interestedIn).then((response) => {
            dispatcher.dispatch(events.ACCOUNT_UPDATED, this.denormalizeAccount(response.account));
            return response;
        });
    }

    updateWorkflowLogging(accountId, workflowLoggingWorksUntil) {
        const url = reverse(apiRoutes.account, { accountId });
        return this.requestApi(url, 'PUT', { account: { workflowLoggingWorksUntil } }).then((response) => {
            dispatcher.dispatch(events.ACCOUNT_UPDATED, this.denormalizeAccount(response.account));
            return response;
        });
    }

    load(id) {
        if (!id) {
            id = 'current';
        }
        const url = reverse(apiRoutes.account, { accountId: id });
        return this.requestApi(url, 'GET');
    }

    getGeocodedCounters(accountIds) {
        const url = reverse(apiRoutes.accountsGeo);
        return this.requestApi(url, 'GET', { accountIds });
    }

    delete(id) {
        const url = reverse(apiRoutes.account, { accountId: id });
        return this.requestApi(url, 'DELETE');
    }

    restore(id) {
        const url = reverse(apiRoutes.account.restore, { accountId: id });
        return this.requestApi(url, 'POST');
    }

    restoreShard() {
        const url = reverse(apiRoutes.accountRestoreShard);
        return this.requestApi(url, 'POST');
    }

    clearCache(id) {
        const url = reverse(apiRoutes.account.clearCache, { accountId: id });
        return this.requestApi(url, 'POST');
    }

    changeTest(id) {
        const url = reverse(apiRoutes.account.changeTest, { accountId: id });
        return this.requestApi(url, 'POST');
    }

    getPermissions(accountId) {
        const url = reverse(apiRoutes.account.permissions, { accountId });
        return this.requestApi(url, 'GET').then((response) => response.permissions);
    }

    getDefaultAccount() {
        return {
            id: 0,
            brief: null,
            name: null,
            address: null,
            phone: null,
            email: null,
            skype: null,
            distanceMetric: Common.DistanceMetric,
            autoSaveSearches: true,
            accessConnection: false,
            accessVisualization: false,
            accessRoles: false,
            googleKey: null,
            mapQuestKey: null,
            locationIqKey: null,
            timezone: 'UTC',
            countryCode: 'USA',
            shardIsDeleted: null,
        };
    }

    getCsvUrl(type) {
        const url = reverse(apiRoutes.accountsCsv, { type });
        return this.requestApi(url, 'POST');
    }

    async accountHasAccessTo(accountId, module, feature = null, subFeature = null) {
        return roleManager.getAccountRoles(accountId).then((roles) => {
            if (!roles.reduce((result, role) => result || role.uiFeaturesPermission[module].enable, false)) {
                return false;
            }

            if (feature !== null && subFeature !== null) {
                return roles.reduce(
                    (result, role) =>
                        result || role.uiFeaturesPermission[module].features[feature].subFeatures[subFeature].enable,
                    false,
                );
            }

            if (feature !== null) {
                return roles.reduce(
                    (result, role) => result || role.uiFeaturesPermission[module].features[feature].enable,
                    false,
                );
            }

            return true;
        });
    }

    denormalizeAccount(rawAccount) {
        const account = cloneDeep(rawAccount);

        const { objectsMapping, userMapping, vehicleTypes } = account.routingSettings;

        account.routingSettings.objectsMapping = new Map(
            objectsMapping.map((objectMapping) => {
                const [key, value] = objectMapping;
                const updatedValue = {
                    ...value,
                    mapping: new Map(value.mapping),
                };
                return [key, updatedValue];
            }),
        );
        account.routingSettings.userMapping = {
            ...userMapping,
            mapping: new Map(userMapping.mapping),
        };
        account.routingSettings.vehicleTypes = new Map(vehicleTypes);

        return account;
    }
}

export const accountsManager = new AccountsManager();
