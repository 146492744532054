import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Icon,
    ListSubheader,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import WorkflowGroups from '../../WorkflowGroups/index';
import { TYPE_DEFAULT_ACTION_GROUP } from '../../../service/WorkflowGroupManager';
import { DayOfWeek, Schedule, ScheduleFrequency } from './types';
import { AccountData, IDataSource } from '../../../service/types';
import { scheduleManager } from '../../../service/ScheduleManager';
import { FormActions, FormBackdrop } from '../../PureFormDialog/Form';
import { userManager } from '../../../service/UserManager';
import { CallContext } from '../../utils/CallContext';
import FormControlSelect from '../../WorkflowActions/AbstractForm/FormControlSelect';
import PureFormDialog from '../../PureFormDialog/index';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTimePicker } from '@material-ui/pickers';
import FormControlMultipleSelect from '../../WorkflowActions/AbstractForm/FormControlMultipleSelect';
import { formatDateTimeForPicker, formatDateToDefault, isActiveEntity, userTimezoneToUtc } from '../../../utils';
import Hint from '../../Hint';
import { TYPE_OPEN_FORM, TYPE_UPDATE_FORM } from '../../../service/WorkflowActionManager';
import { Entity } from '../../../interfaces';
import { entityManager } from '../../../service/SimpleEntityManager';

interface CurrentProps extends WithTranslation {
    account: AccountData;
    schedule: Schedule;
    dataSources: IDataSource[];
    onSaved: (schedule: Schedule) => void;
    onCancel: () => void;
    onModified: () => void;
}

interface CurrentState {
    loading: boolean;
    errors: Map<string, string>;
    currentSchedule: Schedule;
    showEntityModal: boolean;
    currentEntity: Entity.Entity | null;
}

class ScheduleForm extends React.PureComponent<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            loading: false,
            errors: new Map(),
            currentSchedule: cloneDeep(props.schedule),
            showEntityModal: !props.schedule.id,
            currentEntity: null,
        };
    }

    componentDidMount() {
        if (this.state.currentSchedule && this.state.currentSchedule.callContext.isValid()) {
            this.initEntityFields(this.state.currentSchedule.callContext.getBaseEntityId());
        }
    }

    initEntityFields = (entityId: number) => {
        if (!entityId) {
            return;
        }

        entityManager.get(entityId, true).then((currentEntity: Entity.Entity) => {
            this.setState({ currentEntity });
        });
    };

    static getDerivedStateFromProps(props: Readonly<CurrentProps>, state: Readonly<CurrentState>) {
        if (state.currentSchedule.isActive === props.schedule.isActive) {
            return null;
        }

        state.currentSchedule.isActive = props.schedule.isActive;

        return state;
    }

    handleSave = () => {
        this.setState({ loading: true, errors: new Map() });

        this.validate()
            .then((schedule) => {
                return scheduleManager.save(this.props.account.id, schedule);
            })
            .then((schedule) => {
                this.setState({ loading: false });
                this.props.onSaved(schedule);
            })
            .catch((errors) => {
                this.setState({ loading: false, errors: errors.details || errors });
            });
    };

    validate = (): Promise<Schedule> => {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentSchedule, currentEntity } = this.state;
            if (!currentSchedule.name) {
                errors.set('name', this.props.t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', this.props.t('automation_elements.form.entity.inactive_error'));
            }
            if (!currentSchedule.startDateTime) {
                errors.set('startDateTime', this.props.t('errors.not_empty'));
            }
            if (!currentSchedule.frequency) {
                errors.set('frequency', this.props.t('errors.not_empty'));
            }
            if (currentSchedule.frequency === ScheduleFrequency.Weekly) {
                if (!Array.isArray(currentSchedule.daysOfWeek) || currentSchedule.daysOfWeek.length === 0) {
                    errors.set('daysOfWeek', this.props.t('errors.not_empty'));
                }
            }
            if (currentSchedule.frequency === ScheduleFrequency.Custom) {
                if (!currentSchedule.cronExpression) {
                    errors.set('cronExpression', this.props.t('errors.not_empty'));
                }
            }
            if (!Array.isArray(currentSchedule.groups) || currentSchedule.groups.length === 0) {
                errors.set('groups', this.props.t('errors.not_empty'));
            }
            if (errors.size === 0) {
                resolve(currentSchedule);
                return;
            }
            reject(errors);
        });
    };

    handleInputChange = (event: React.ChangeEvent<{ name: string; type: string; value: any; checked?: boolean }>) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.updateState(name, value);
    };

    handleEntitySelect = (event: React.ChangeEvent<{ name: string; value: any }>) => {
        const name = event.target.name;
        const entityId = event.target.value;
        this.initEntityFields(entityId);
        this.updateState(name, CallContext.create(entityId));
        this.closeEntityModal();
    };

    handleDateChange = (value: MaterialUiPickersDate) => {
        const startDateTime = value
            ? userTimezoneToUtc(formatDateToDefault(value), userManager.getCurrentUser())
            : value;
        this.updateState('startDateTime', startDateTime);
    };

    handleGroups = (groups: any[]) => {
        this.updateState('groups', groups);
    };

    updateState = (name: string, value: any) => {
        this.setState(
            (state: CurrentState) => {
                const currentSchedule = cloneDeep(state.currentSchedule);
                currentSchedule[name] = value;
                let errors = state.errors;
                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                }
                return { currentSchedule, errors };
            },
            () => {
                this.props.onModified && this.props.onModified();
            },
        );
    };

    prepareEntityOptions = () => {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entityCounter of dataSource.entityCounters) {
                options.push(
                    <MenuItem key={entityCounter.entity.id} value={entityCounter.entity.id}>
                        {entityCounter.entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    };

    renderEntityValue = (entity: Entity.Entity | null, error?: string) => {
        if (!entity) {
            return this.props.t('undefined_picklist_option');
        }

        const label = `${entity.dataSource.name} > ${entity.label}`;
        if (error) {
            return (
                <Box color="error.main" component="span">
                    <Tooltip title={error}>
                        <i style={{ marginRight: 3 }} className="fas fa-circle-exclamation" />
                    </Tooltip>
                    {label}
                </Box>
            );
        }
        return label;
    };

    renderEntitySelect = () => {
        const { currentEntity } = this.state;
        let error: string | undefined;
        if (currentEntity?.dataSource?.deletedAt) {
            error = this.props.t<string>('automation_elements.inactive_data_source');
        } else if (currentEntity?.deletedAt || currentEntity?.isIncluded === false) {
            error = this.props.t<string>('automation_elements.inactive_entity');
        }
        return (
            <FormControlSelect
                fullWidth
                margin="dense"
                label={this.props.t('automation.schedule.form.entity')}
                name="callContext"
                value={this.state.currentSchedule.callContext.getBaseEntityId() || 0}
                onChange={this.handleEntitySelect}
                renderValue={() => this.renderEntityValue(currentEntity, error)}
                error={!!error || this.state.errors.has('callContext')}
                helperText={this.state.errors.get('callContext')}
                data-testid="automation.schedule.form.entity"
            >
                {this.prepareEntityOptions()}
            </FormControlSelect>
        );
    };

    renderSelectedDaysOfWeek = (daysOfWeek: DayOfWeek[]) => {
        return daysOfWeek.map((dayOfWeek) => this.props.t('weekday.' + dayOfWeek)).join(', ');
    };

    closeEntityModal = () => {
        this.setState({ showEntityModal: false });
    };

    render() {
        let startDateTime = this.state.currentSchedule.startDateTime || null;
        if (startDateTime) {
            startDateTime = formatDateTimeForPicker(startDateTime, userManager.getCurrentUser());
        }

        return (
            <React.Fragment>
                <PureFormDialog
                    title={this.props.t<string>('automation.schedule.form.entity.modal.title')}
                    onClose={this.closeEntityModal}
                    open={this.state.showEntityModal}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect()}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <FormBackdrop loading={this.state.loading}>
                    <form noValidate autoComplete="off">
                        <TextField
                            autoFocus
                            label={this.props.t('automation.schedule.form.name')}
                            data-testid="automation.schedule.form.name"
                            fullWidth
                            required
                            margin="dense"
                            name="name"
                            value={this.state.currentSchedule.name || ''}
                            helperText={this.state.errors.get('name') || ''}
                            error={this.state.errors.has('name')}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            label={this.props.t('automation.schedule.form.api_name')}
                            data-testid="automation.schedule.form.api_name"
                            fullWidth
                            margin="dense"
                            name="apiName"
                            value={this.state.currentSchedule.apiName || ''}
                            helperText={this.state.errors.get('apiName') || ''}
                            error={this.state.errors.has('apiName')}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                        {this.renderEntitySelect()}

                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <FormControl fullWidth error={this.state.errors.has('startDateTime')}>
                                    <DateTimePicker
                                        label={
                                            <span>
                                                {this.props.t('automation.schedule.form.start_datetime')}{' '}
                                                <Hint>
                                                    {this.props.t('automation.schedule.form.start_datetime.hint')}
                                                </Hint>
                                            </span>
                                        }
                                        value={startDateTime}
                                        onChange={this.handleDateChange}
                                        format="MMM. d, yyyy h:mm a"
                                        required
                                        margin="dense"
                                    />
                                    {this.state.errors.has('startDateTime') && (
                                        <FormHelperText>{this.state.errors.get('startDateTime')}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlSelect
                                    label={this.props.t('automation.schedule.form.frequency')}
                                    fullWidth
                                    required
                                    margin="dense"
                                    name="frequency"
                                    value={this.state.currentSchedule.frequency || ''}
                                    helperText={this.state.errors.get('frequency') || ''}
                                    error={this.state.errors.has('frequency')}
                                    onChange={this.handleInputChange}
                                    data-testid="automation.schedule.form.frequency"
                                >
                                    {Object.values(ScheduleFrequency).map((item) => {
                                        return (
                                            <MenuItem key={item} value={item}>
                                                {this.props.t('automation.schedule.form.frequency.' + item)}
                                            </MenuItem>
                                        );
                                    })}
                                </FormControlSelect>
                            </Grid>
                        </Grid>

                        {this.state.currentSchedule.frequency === ScheduleFrequency.Weekly && (
                            <FormControlMultipleSelect
                                label={this.props.t('automation.schedule.form.days_of_week')}
                                fullWidth
                                required
                                margin="dense"
                                name="daysOfWeek"
                                value={this.state.currentSchedule.daysOfWeek || []}
                                helperText={this.state.errors.get('daysOfWeek') || ''}
                                error={this.state.errors.has('daysOfWeek')}
                                onChange={this.handleInputChange}
                                renderValue={this.renderSelectedDaysOfWeek}
                                data-testid="automation.schedule.form.days_of_week"
                            >
                                {Object.values(DayOfWeek).map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {this.props.t('weekday.' + item)}
                                        </MenuItem>
                                    );
                                })}
                            </FormControlMultipleSelect>
                        )}
                        {this.state.currentSchedule.frequency === ScheduleFrequency.Custom && (
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3}>
                                    <TextField
                                        label={this.props.t('automation.schedule.form.cron_expression')}
                                        data-testid="automation.schedule.form.cron_expression"
                                        fullWidth
                                        required
                                        margin="dense"
                                        name="cronExpression"
                                        value={this.state.currentSchedule.cronExpression || ''}
                                        helperText={this.state.errors.get('cronExpression') || ''}
                                        error={this.state.errors.has('cronExpression')}
                                        InputProps={{ disableUnderline: false }}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item container xs direction="row" alignItems="center">
                                    <Icon className="fas fa-exclamation-circle" fontSize="small" color="primary" />
                                    <Typography variant="caption" style={{ marginLeft: 5 }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.t('automation.schedule.form.cron_expression.hint'),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        <WorkflowGroups
                            title={this.props.t('automation.schedule.form.actions')}
                            hint={this.props.t('automation.schedule.form.actions.hint')}
                            placeholder={this.props.t('automation.schedule.form.actions.placeholder')}
                            parentNamespace={this.state.currentSchedule.namespace}
                            groupType={TYPE_DEFAULT_ACTION_GROUP}
                            groups={this.state.currentSchedule.groups}
                            errors={this.state.errors}
                            onChange={this.handleGroups}
                            account={this.props.account}
                            dataSources={this.props.dataSources}
                            callContext={this.state.currentSchedule.callContext}
                            addOldEntitySection
                            excludedActions={[TYPE_OPEN_FORM, TYPE_UPDATE_FORM]}
                        />
                    </form>
                </FormBackdrop>

                <FormActions>
                    <Button
                        color="primary"
                        onClick={this.handleSave}
                        disabled={!userManager.automationElementsManagement() || this.state.loading}
                        data-testid="automation.schedule.form.button.save"
                    >
                        {this.props.t('button.save')}
                    </Button>
                </FormActions>
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ScheduleForm);
