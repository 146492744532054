import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class FormulaManager extends BackendService {
    validate(formula, rawFormula = false) {
        const url = reverse(apiRoutes.formula.validate);
        return this.requestApi(url, 'POST', { formula, rawFormula });
    }

    functions() {
        const url = reverse(apiRoutes.formula.functions);
        return this.requestApi(url, 'GET');
    }
}

export const formulaManager = new FormulaManager();
