import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CallContext, TYPE_ENTITY_BASE } from '../utils/CallContext';
import { entityManager } from '../../service/SimpleEntityManager';

const SPECIAL_RECORD_OPTIONS = {
    __name: 'formula_editor.insert_field.special.name',
    __address: 'formula_editor.insert_field.special.address',
    __objectApiName: 'formula_editor.insert_field.special.object_api_name',
    __objectLabel: 'formula_editor.insert_field.special.object_label',
};

class InsertFieldForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.prepareOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.entities !== this.props.entities) {
            this.prepareOptions();
        }
    }

    handleChange = (option) => {
        this.props.onSave(option.value);
    };

    prepareEntityFields = (options, entity, entityType, groupBy = null) => {
        const sortedFields = entity.fields.sort((fieldA, fieldB) => fieldA.label.localeCompare(fieldB.label));
        for (let field of sortedFields) {
            options.push({
                entityType,
                label: field.label,
                value: entityType + '.' + field.apiName,
                field,
                groupBy: groupBy || entity.label,
            });
        }
        return options;
    };

    getSpecialRecordOptions = () => {
        const specialRecordOptions = [];
        for (const key of Object.keys(SPECIAL_RECORD_OPTIONS)) {
            specialRecordOptions.push({
                entityType: TYPE_ENTITY_BASE,
                label: this.props.t(SPECIAL_RECORD_OPTIONS[key]),
                value: TYPE_ENTITY_BASE + '.' + key,
                groupBy: this.props.t('formula_editor.insert_field.special'),
            });
        }
        return specialRecordOptions;
    };

    prepareOptions = () => {
        this.setState({ loading: true });
        const { callContext, options = [] } = this.props;

        const callContextPromise = callContext ? this.prepareCallContextOptions(callContext) : Promise.resolve([]);
        callContextPromise.then((callContextOptions) => {
            this.setState({ options: [...callContextOptions, ...options], loading: false });
        });
    };

    prepareCallContextOptions = (callContext) => {
        const promises = callContext.entities.map((entityId) => {
            return entityManager.get(entityId);
        });

        return Promise.all(promises)
            .then((entities) => {
                const entityMap = new Map();
                for (const entity of entities) {
                    entityMap.set(entity.id, entity);
                }
                return entityMap;
            })
            .then((entityMap) => {
                const options = [];
                const entitySet = new Set();
                for (const variable of callContext.vars) {
                    const entity = entityMap.get(variable.entity);
                    if (!entity || entitySet.has(variable.entity)) {
                        continue;
                    }

                    const groupBy = `${callContext.getPrefixLabelByType(variable.type)} (${entity.label})`;
                    this.prepareEntityFields(options, entity, variable.type, groupBy);
                    entitySet.add(variable.entity);
                }

                return options.concat(this.getSpecialRecordOptions());
            });
    };

    render() {
        return (
            <form noValidate autoComplete="off">
                <FormControl margin="dense" fullWidth>
                    <Autocomplete
                        options={this.state.options}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        groupBy={(options) => options && options.groupBy}
                        onChange={(e, value) => this.handleChange(value)}
                        disableClearable
                        loading={this.state.loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                label={this.props.t('formula_editor.insert_field.label')}
                                data-testid="formula_editor.insert_field"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
            </form>
        );
    }
}

InsertFieldForm.propTypes = {
    onSave: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
    value: PropTypes.string,
};

export default withTranslation('translations', { withRef: true })(InsertFieldForm);
