import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import Backdrop from '../../Backdrop';

interface Props extends WithTranslation {
    anchorEl: Element;
    onClose: () => void;
    addToWaitingListButtonLoading: boolean;
    onCreateEvent: () => void;
    onAddToWaitingList: () => void;
}

interface State {}

class ToCalendarContextMenu extends React.Component<Props, State> {
    private handleClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
    };

    render() {
        const { t, onClose, anchorEl, addToWaitingListButtonLoading, onCreateEvent, onAddToWaitingList } = this.props;

        return (
            <>
                <ClickAwayListener onClickAway={onClose}>
                    <div onClick={this.handleClick}>
                        <Popper open={true} anchorEl={anchorEl} transition className="to-calendar-button-menu">
                            <Paper elevation={8}>
                                <MenuList>
                                    <MenuItem onClick={onCreateEvent}>{t('calendar.create_event')}</MenuItem>
                                    <Backdrop loading={addToWaitingListButtonLoading}>
                                        <MenuItem onClick={onAddToWaitingList}>
                                            {t('calendar.add_to_waiting_list')}
                                        </MenuItem>
                                    </Backdrop>
                                </MenuList>
                            </Paper>
                        </Popper>
                    </div>
                </ClickAwayListener>
            </>
        );
    }
}

export default withTranslation()(ToCalendarContextMenu);
