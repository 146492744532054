import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import {
    Grid,
    Button,
    Badge,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    DialogActions,
    FormControlLabel,
    Checkbox,
    withStyles,
} from '@material-ui/core';
import { userManager } from '../../service/UserManager';
import { User } from '../../interfaces';
import { ShowEarlyLateTags, USER_ROUTING_PREFERENCES_FIELD_LIST, UserDataRoutePreferences } from '../../service/types';
import UserRow from './UserRow';
import { massUsersTravelingPreferencesDialogManager } from '../../service/UserForm';
import { observer } from 'mobx-react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Backdrop from '../Backdrop';
import EditSelectedUsersModal from './EditSelectedUsersModal';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { getWorkingHoursAsString, getWorkingHoursBreakAsString } from './TravelingPreferencesHelper';
import UsersTravelingPreferencesTableFilters from './UsersTravelingPreferencesTableFilters';
import Hint from 'components/Hint';

export type TableColumnName = keyof UserDataRoutePreferences | 'user';
export type UsersTravelingPreferencesDialogTableFilter = Map<TableColumnName, any>;

export const HeaderCell = withStyles({
    root: {
        left: 'initial',
        top: 0,
        whiteSpace: 'nowrap',
        '&:first-child': {
            left: 0,
            zIndex: 3,
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
})(TableCell);

interface State {
    loading: boolean;
    users: User.User[];
    filteredUserIds: number[];
    selectedUserIds: number[];
    filter: UsersTravelingPreferencesDialogTableFilter;
    showEditSelectedUsersModal: boolean;
}

interface Props extends WithTranslation, WithSnackbarProps {}

class UsersTravelingPreferencesDialog extends React.Component<Props, State> {
    private tableHeaderRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            users: [],
            filteredUserIds: [],
            selectedUserIds: [],
            filter: new Map(),
            showEditSelectedUsersModal: false,
        };

        this.tableHeaderRef = React.createRef();

        this.handleFilterChange = debounce(this.handleFilterChange.bind(this), 500);
    }

    initialize = () => {
        this.setState({ loading: true }, () => {
            this.getUsers().then((users) => {
                this.setState({
                    users,
                    loading: false,
                });
            });
        });
    };

    getUsers = (): Promise<User.User[]> => {
        return userManager.getAccountUsers(userManager.getCurrentAccount().id).then((users) => {
            return users.filter((user) => !user.role.forSharedMap);
        });
    };

    componentDidMount = () => {
        this.initialize();
    };

    handleSave = () => {
        this.setState({
            loading: true,
        });
        userManager
            .updateUsers(this.state.users)
            .then(this.handleClose)
            .catch((error) => {
                this.props.enqueueSnackbar(typeof error === 'object' ? error.message : error, { variant: 'error' });
                this.setState({
                    loading: false,
                });
            });
    };

    handleClose = () => {
        this.setState({ users: [], selectedUserIds: [], filter: new Map(), showEditSelectedUsersModal: false });
        massUsersTravelingPreferencesDialogManager.closeModal();
    };

    handleEditSelectedClick = () => {
        this.setState({ showEditSelectedUsersModal: true });
    };

    handleEditSelectedUsersModalSubmit = (preferencesData: Partial<UserDataRoutePreferences>) => {
        const users = cloneDeep(this.state.users);
        for (const i in users) {
            if (!this.state.selectedUserIds.includes(users[i].id)) {
                continue;
            }
            users[i].routingPreferences = { ...users[i].routingPreferences, ...preferencesData };
        }
        this.setState({ users });
        this.handleCloseEditSelectedUsersModalClose();
    };

    handleCloseEditSelectedUsersModalClose = () => {
        this.setState({ showEditSelectedUsersModal: false });
    };

    handleUserSelect = (user: User.User) => {
        const { selectedUserIds } = this.state;
        const index = selectedUserIds.indexOf(user.id);
        if (index === -1) {
            this.setState({ selectedUserIds: [...selectedUserIds, user.id] });
        } else {
            this.setState({ selectedUserIds: selectedUserIds.filter((id) => id !== user.id) });
        }
    };

    handleUserChange = (user: User.User) => {
        const users = [...this.state.users];
        const index = users.findIndex((u) => u.id === user.id);
        if (index === -1) {
            return;
        }
        users[index] = user;
        this.setState({ users });
    };

    handleAllUsersSelectClick = () => {
        const { users, selectedUserIds } = this.state;
        if (selectedUserIds.length === users.length) {
            this.setState({ selectedUserIds: [] });
        } else {
            this.setState({ selectedUserIds: users.map((user) => user.id) });
        }
    };

    handleFilterChange = (fieldName: TableColumnName, value: any) => {
        const filter = new Map(this.state.filter).set(fieldName, value);
        this.setState({ filter, loading: true });

        const filteredUserIds = this.state.users
            .filter((user) => {
                for (const column of filter.keys()) {
                    if (!this.meetsFilterCondition(user, column as TableColumnName, filter)) {
                        return false;
                    }
                }
                return true;
            })
            .map((user) => user.id);
        this.setState({ filteredUserIds, loading: false });
    };

    meetsFilterCondition = (
        user: User.User,
        column: TableColumnName,
        filter: UsersTravelingPreferencesDialogTableFilter,
    ): boolean => {
        if (!filter.has(column)) {
            return true;
        }
        const filterValue = filter.get(column);
        if (
            filterValue === null ||
            filterValue === undefined ||
            filterValue === '' ||
            (Array.isArray(filterValue) && filterValue.length === 0)
        ) {
            return true;
        }
        if (typeof filterValue === 'number') {
            return Number(this.getRowCellValueAsString(user, column)) === filterValue;
        }
        if (column === 'vehicleType') {
            return filterValue.includes(user.routingPreferences.vehicleType);
        }
        if (column === 'hasSkills') {
            return filterValue.some((skill: string) => (user.routingPreferences.hasSkills || []).includes(skill));
        }
        return this.getRowCellValueAsString(user, column).toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
    };

    getRowCellValueAsString = (user: User.User, column: TableColumnName): string => {
        const { t } = this.props;

        switch (column) {
            case 'user':
                return user.name;
            case 'weekTimes':
                const value = getWorkingHoursAsString(user.routingPreferences.weekTimes || {});

                if (value === '') {
                    return t('not_set');
                }
                return value;
            case 'startPoint':
                if (
                    user.routingPreferences.startPoint === null ||
                    user.routingPreferences.startPoint.address === null
                ) {
                    return t('not_set');
                }
                return user.routingPreferences.startPoint.address;
            case 'endPoint':
                if (user.routingPreferences.endPoint === null || user.routingPreferences.endPoint.address === null) {
                    return t('not_set');
                }
                return user.routingPreferences.endPoint.address;
            case 'returnToFinishLocation':
                return user.routingPreferences.returnToFinishLocation ? t('yes') : t('no');
            case 'defaultDuration':
                return String(user.routingPreferences.defaultDuration);
            case 'departureDelay':
                return String(user.routingPreferences.departureDelay);
            case 'breakType':
                if (!user.routingPreferences.breakType) {
                    return t('not_set');
                }

                return getWorkingHoursBreakAsString({
                    breakType: user.routingPreferences.breakType,
                    breakDuration: user.routingPreferences.breakDuration,
                    breakLatestTime: user.routingPreferences.breakLatestTime,
                    breakEarliestTime: user.routingPreferences.breakEarliestTime,
                });
            case 'breakDuration':
                if (user.routingPreferences.breakDuration === null) {
                    return t('not_set');
                }
                return String(user.routingPreferences.breakDuration);
            case 'vehicleType':
                const account = userManager.getCurrentAccount();
                if (user.routingPreferences.vehicleType === null) {
                    return t('not_set');
                }
                if (!account.routingSettings.vehicleTypes.has(user.routingPreferences.vehicleType)) {
                    return t('not_set');
                }
                return account.routingSettings.vehicleTypes.get(user.routingPreferences.vehicleType)!.name;
            case 'hasSkills':
                const hasSkills = user.routingPreferences.hasSkills || [];
                return hasSkills.length > 0 ? hasSkills.join(', ') : t('not_set');
            case 'maxDistance':
                if (user.routingPreferences.maxDistance === null) {
                    return t('not_set');
                }
                return String(user.routingPreferences.maxDistance);
            case 'maxDrivingTime':
                if (user.routingPreferences.maxDrivingTime === null) {
                    return t('not_set');
                }
                return String(parseFloat((user.routingPreferences.maxDrivingTime / 60).toFixed(1)));
            case 'maxActivities':
                if (user.routingPreferences.maxActivities === null) {
                    return t('not_set');
                }
                return String(user.routingPreferences.maxActivities);
            case 'minJobs':
                if (user.routingPreferences.minJobs === null) {
                    return t('not_set');
                }
                return String(user.routingPreferences.minJobs);
            case 'maxJobs':
                if (user.routingPreferences.maxJobs === null) {
                    return t('not_set');
                }
                return String(user.routingPreferences.maxJobs);
            case 'allowedOvertime':
                return String(user?.routingPreferences?.allowedOvertime || 0);

            case 'lassoAutoZoom':
                return user?.routingPreferences?.lassoAutoZoom ? t('yes') : t('no');

            case 'showEarlyLateTags':
                const currentShowEarlyLateTag = Object.values(ShowEarlyLateTags).find(
                    (showEarlyLateTag) => showEarlyLateTag === user?.routingPreferences?.showEarlyLateTags,
                );

                return t(
                    `route_editor.modal.routing_preferences.modal.show_early_late_tags.values.${currentShowEarlyLateTag}.label`,
                );
            default:
                throw new Error('Unknown column name');
        }
    };

    render() {
        const { t, i18n } = this.props;
        const { users, filteredUserIds, selectedUserIds, filter, showEditSelectedUsersModal, loading } = this.state;
        const account = userManager.getCurrentAccount();

        const filteredUsers = filter.size > 0 ? users.filter((user) => filteredUserIds.includes(user.id)) : users;

        return (
            <React.Fragment>
                <PureFormDialog
                    open={true}
                    title={t<string>('users_traveling_preferences_dialog.title')}
                    onClose={this.handleClose}
                    maxWidth="xl"
                    fullWidth
                    scroll="paper"
                    actions={
                        <DialogActions>
                            <Button onClick={this.handleClose}>{t('button.cancel')}</Button>
                            <Button onClick={this.handleSave} color="primary">
                                {t('button.save')}
                            </Button>
                        </DialogActions>
                    }
                >
                    <Backdrop loading={loading}>
                        <Grid className="row" container spacing={2} alignItems="center" style={{ padding: '20px 0' }}>
                            <Grid item>
                                <Badge badgeContent={selectedUserIds.length} color="primary">
                                    <Button
                                        onClick={this.handleEditSelectedClick}
                                        disabled={selectedUserIds.length <= 0}
                                        color="default"
                                        variant="contained"
                                    >
                                        {t('users_traveling_preferences_dialog.edit_selected')}
                                    </Button>
                                </Badge>
                            </Grid>
                        </Grid>
                        <TableContainer style={{ height: 'calc(100vh - 258px)' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow ref={this.tableHeaderRef}>
                                        <HeaderCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            users.length === selectedUserIds.length && users.length > 0
                                                        }
                                                        onChange={this.handleAllUsersSelectClick}
                                                        color="primary"
                                                    />
                                                }
                                                label={t('users_traveling_preferences_dialog.columns.user')}
                                            />
                                        </HeaderCell>
                                        {USER_ROUTING_PREFERENCES_FIELD_LIST.map((fieldName) => {
                                            const hintTextKey = `users_traveling_preferences_dialog.columns.${fieldName}.hint`;
                                            const isHint = i18n.exists(hintTextKey);

                                            return (
                                                <HeaderCell key={fieldName}>
                                                    {t(`users_traveling_preferences_dialog.columns.${fieldName}`)}
                                                    {isHint && (
                                                        <Hint iProps={{ style: { marginLeft: 8 } }}>
                                                            {t(hintTextKey)}
                                                        </Hint>
                                                    )}
                                                </HeaderCell>
                                            );
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <UsersTravelingPreferencesTableFilters
                                            users={users}
                                            filter={filter}
                                            vehicleTypes={account.routingSettings.vehicleTypes}
                                            clientHeight={this.tableHeaderRef.current?.clientHeight || 0}
                                            handleFilterChange={this.handleFilterChange}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredUsers.map((user: User.User) => {
                                        return (
                                            <UserRow
                                                key={user.id}
                                                user={user}
                                                selected={selectedUserIds.indexOf(user.id) !== -1}
                                                onSelect={this.handleUserSelect}
                                                onChange={this.handleUserChange}
                                                getRowCellValueAsString={this.getRowCellValueAsString}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Backdrop>
                </PureFormDialog>
                {showEditSelectedUsersModal && (
                    <EditSelectedUsersModal
                        onSubmit={this.handleEditSelectedUsersModalSubmit}
                        onClose={this.handleCloseEditSelectedUsersModalClose}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations')(withSnackbar(observer(UsersTravelingPreferencesDialog)));
