export enum IconStyle {
    SOLID = 'solid',
    REGULAR = 'regular',
    LIGHT = 'light',
    THIN = 'thin',
    DUOTONE = 'duotone',
    BRANDS = 'brands',
}

interface Unicodes {
    composite?: Array<string>;
    primary?: Array<string>;
    secondary?: Array<string>;
}

interface Aliases {
    names?: Array<string>;
    unicodes?: Unicodes;
}

export interface Icon {
    name: string;
    categories: Array<string>;
    terms: Array<string>;
    label: string;
    aliases: Aliases;
    styles: IconStyle[];
    unicode: string;
}

export const icons: Array<Icon> = [
    {
        name: '0',
        categories: ['numbers'],
        terms: [],
        label: '0',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '30',
    },
    {
        name: '00',
        categories: ['numbers'],
        terms: [],
        label: '00',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e467',
    },
    {
        name: '1',
        categories: ['numbers'],
        terms: [],
        label: '1',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '31',
    },
    {
        name: '2',
        categories: ['numbers'],
        terms: [],
        label: '2',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '32',
    },
    {
        name: '3',
        categories: ['numbers'],
        terms: [],
        label: '3',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '33',
    },
    {
        name: '360-degrees',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: '360 Degrees',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2dc',
    },
    {
        name: '4',
        categories: ['numbers'],
        terms: [],
        label: '4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '34',
    },
    {
        name: '5',
        categories: ['numbers'],
        terms: [],
        label: '5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '35',
    },
    {
        name: '6',
        categories: ['numbers'],
        terms: [],
        label: '6',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '36',
    },
    {
        name: '7',
        categories: ['numbers'],
        terms: [],
        label: '7',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '37',
    },
    {
        name: '8',
        categories: ['numbers'],
        terms: [],
        label: '8',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '38',
    },
    {
        name: '9',
        categories: ['numbers'],
        terms: [],
        label: '9',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '39',
    },
    {
        name: 'a',
        categories: ['alphabet'],
        terms: [],
        label: 'A',
        aliases: {
            unicodes: {
                composite: ['61'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '41',
    },
    {
        name: 'abacus',
        categories: ['mathematics'],
        terms: ['addition', 'ancient', 'arithmetic', 'calculator', 'counting', 'hexadecimal', 'math', 'subtraction'],
        label: 'Abacus',
        aliases: {
            unicodes: {
                composite: ['1f9ee'],
                secondary: ['10f640'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f640',
    },
    {
        name: 'accent-grave',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Accent Grave',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '60',
    },
    {
        name: 'accessible-icon',
        categories: ['accessibility', 'medical-health', 'transportation', 'users-people'],
        terms: [],
        label: 'Accessible Icon',
        aliases: {
            unicodes: {
                composite: ['f29b'],
            },
        },
        styles: [IconStyle.BRANDS],
        unicode: 'f368',
    },
    {
        name: 'acorn',
        categories: ['camping', 'nature'],
        terms: ['fall', 'nature', 'nut', 'oak', 'seasonal', 'seed', 'squirrel', 'tree'],
        label: 'Acorn',
        aliases: {
            unicodes: {
                secondary: ['10f6ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ae',
    },
    {
        name: 'address-book',
        categories: ['business', 'communication', 'users-people'],
        terms: ['contact', 'directory', 'index', 'little black book', 'rolodex'],
        label: 'Address Book',
        aliases: {
            names: ['contact-book'],
            unicodes: {
                composite: ['f2ba'],
                secondary: ['10f2b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2b9',
    },
    {
        name: 'address-card',
        categories: ['business', 'communication', 'users-people'],
        terms: ['about', 'contact', 'id', 'identification', 'postcard', 'profile'],
        label: 'Address Card',
        aliases: {
            names: ['contact-card', 'vcard'],
            unicodes: {
                composite: ['f2bc'],
                secondary: ['10f2bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2bb',
    },
    {
        name: 'air-conditioner',
        categories: ['household', 'travel-hotel'],
        terms: ['ac', 'cool', 'heat', 'heat pump', 'hvac', 'mini-split', 'temperature', 'unit'],
        label: 'Air Conditioner',
        aliases: {
            unicodes: {
                secondary: ['10f8f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f4',
    },
    {
        name: 'airplay',
        categories: ['film-video', 'music-audio'],
        terms: [],
        label: 'Airplay',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e089',
    },
    {
        name: 'alarm-clock',
        categories: ['alert', 'time', 'travel-hotel'],
        terms: ['date', 'late', 'reminder', 'sleep', 'snooze', 'timer', 'timestamp', 'watch'],
        label: 'Alarm Clock',
        aliases: {
            unicodes: {
                composite: ['23f0'],
                secondary: ['10f34e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f34e',
    },
    {
        name: 'alarm-exclamation',
        categories: ['alert', 'time'],
        terms: ['alert', 'date', 'late', 'reminder', 'sleep', 'snooze', 'timer', 'timestamp', 'watch'],
        label: 'Alarm Exclamation',
        aliases: {
            unicodes: {
                secondary: ['10f843'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f843',
    },
    {
        name: 'alarm-plus',
        categories: ['time'],
        terms: ['alert', 'date', 'late', 'reminder', 'sleep', 'snooze', 'timer', 'timestamp', 'watch'],
        label: 'Alarm Plus',
        aliases: {
            unicodes: {
                secondary: ['10f844'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f844',
    },
    {
        name: 'alarm-snooze',
        categories: ['time', 'travel-hotel'],
        terms: ['alert', 'date', 'late', 'reminder', 'sleep', 'snooze', 'timer', 'timestamp', 'watch'],
        label: 'Alarm Snooze',
        aliases: {
            unicodes: {
                secondary: ['10f845'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f845',
    },
    {
        name: 'album',
        categories: ['devices-hardware', 'music-audio'],
        terms: ['library', 'music', 'record', 'song', 'vinyl'],
        label: 'Album',
        aliases: {
            unicodes: {
                composite: ['1f4bd'],
                secondary: ['10f89f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f89f',
    },
    {
        name: 'album-circle-plus',
        categories: ['devices-hardware', 'music-audio'],
        terms: [],
        label: 'Album Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48c',
    },
    {
        name: 'album-circle-user',
        categories: ['devices-hardware', 'music-audio'],
        terms: [],
        label: 'Album Circle-user',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48d',
    },
    {
        name: 'album-collection',
        categories: ['film-video', 'music-audio'],
        terms: ['catalog', 'library', 'music', 'record', 'song', 'vinyl'],
        label: 'Album Collection',
        aliases: {
            unicodes: {
                secondary: ['10f8a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a0',
    },
    {
        name: 'album-collection-circle-plus',
        categories: ['music-audio'],
        terms: [],
        label: 'Album Collection-circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48e',
    },
    {
        name: 'album-collection-circle-user',
        categories: ['music-audio'],
        terms: [],
        label: 'Album Collection-circle-user',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48f',
    },
    {
        name: 'alicorn',
        categories: ['animals'],
        terms: ['animal', 'fantasy', 'fauna', 'horse', 'mammal', 'pegasus', 'unicorn', 'wing'],
        label: 'Alicorn',
        aliases: {
            unicodes: {
                secondary: ['10f6b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b0',
    },
    {
        name: 'alien',
        categories: ['astronomy', 'science-fiction'],
        terms: ['ET', 'extraterrestrial', 'humanoid', 'monster', 'space', 'strange', 'ufo'],
        label: 'Alien',
        aliases: {
            unicodes: {
                composite: ['1f47d'],
                secondary: ['10f8f5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f5',
    },
    {
        name: 'alien-8bit',
        categories: ['gaming', 'science-fiction'],
        terms: [],
        label: 'Alien 8bit',
        aliases: {
            names: ['alien-monster'],
            unicodes: {
                composite: ['1f47e'],
                secondary: ['10f8f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f6',
    },
    {
        name: 'align-center',
        categories: ['text-formatting'],
        terms: ['format', 'middle', 'paragraph', 'text'],
        label: 'align-center',
        aliases: {
            unicodes: {
                secondary: ['10f037'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f037',
    },
    {
        name: 'align-justify',
        categories: ['text-formatting'],
        terms: ['format', 'paragraph', 'text'],
        label: 'align-justify',
        aliases: {
            unicodes: {
                secondary: ['10f039'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f039',
    },
    {
        name: 'align-left',
        categories: ['text-formatting'],
        terms: ['format', 'paragraph', 'text'],
        label: 'align-left',
        aliases: {
            unicodes: {
                secondary: ['10f036'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f036',
    },
    {
        name: 'align-right',
        categories: ['text-formatting'],
        terms: ['format', 'paragraph', 'text'],
        label: 'align-right',
        aliases: {
            unicodes: {
                secondary: ['10f038'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f038',
    },
    {
        name: 'align-slash',
        categories: ['text-formatting'],
        terms: ['cancel', 'format', 'paragraph', 'remove'],
        label: 'Align Slash',
        aliases: {
            unicodes: {
                secondary: ['10f846'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f846',
    },
    {
        name: 'alipay',
        categories: ['shopping'],
        terms: [],
        label: 'Alipay',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f642',
    },
    {
        name: 'alt',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Alt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e08a',
    },
    {
        name: 'amazon-pay',
        categories: ['shopping'],
        terms: [],
        label: 'Amazon Pay',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f42c',
    },
    {
        name: 'amp-guitar',
        categories: ['film-video', 'music-audio'],
        terms: ['audio', 'guitar', 'loudspeaker', 'music', 'pa', 'volume'],
        label: 'Guitar Amplifier',
        aliases: {
            unicodes: {
                secondary: ['10f8a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a1',
    },
    {
        name: 'ampersand',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Ampersand',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '26',
    },
    {
        name: 'anchor',
        categories: ['humanitarian', 'logistics', 'maps', 'maritime'],
        terms: ['berth', 'boat', 'dock', 'embed', 'link', 'maritime', 'moor', 'secure'],
        label: 'Anchor',
        aliases: {
            unicodes: {
                composite: ['2693'],
                secondary: ['10f13d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f13d',
    },
    {
        name: 'anchor-circle-check',
        categories: ['humanitarian', 'logistics', 'maritime'],
        terms: [],
        label: 'Anchor Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4aa',
    },
    {
        name: 'anchor-circle-exclamation',
        categories: ['humanitarian', 'logistics', 'maritime'],
        terms: [],
        label: 'Anchor Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ab',
    },
    {
        name: 'anchor-circle-xmark',
        categories: ['humanitarian', 'logistics', 'maritime'],
        terms: [],
        label: 'Anchor Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ac',
    },
    {
        name: 'anchor-lock',
        categories: ['humanitarian', 'logistics', 'maritime'],
        terms: [],
        label: 'Anchor Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ad',
    },
    {
        name: 'angel',
        categories: ['holidays', 'users-people'],
        terms: ['christmas', 'decoration', 'halo', 'holiday', 'holy', 'michael landon', 'wings', 'xmas'],
        label: 'Angel',
        aliases: {
            unicodes: {
                secondary: ['10f779'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f779',
    },
    {
        name: 'angle',
        categories: ['construction', 'design'],
        terms: [],
        label: 'Angle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e08c',
    },
    {
        name: 'angle-90',
        categories: ['construction', 'design'],
        terms: [],
        label: 'Angle 90',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e08d',
    },
    {
        name: 'angle-down',
        categories: ['arrows'],
        terms: ['arrow', 'caret', 'download', 'expand'],
        label: 'angle-down',
        aliases: {
            unicodes: {
                composite: ['2304'],
                secondary: ['10f107'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f107',
    },
    {
        name: 'angle-left',
        categories: ['arrows'],
        terms: ['arrow', 'back', 'caret', 'less', 'previous'],
        label: 'angle-left',
        aliases: {
            unicodes: {
                composite: ['2039'],
                secondary: ['10f104'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f104',
    },
    {
        name: 'angle-right',
        categories: ['arrows'],
        terms: ['arrow', 'care', 'forward', 'more', 'next'],
        label: 'angle-right',
        aliases: {
            unicodes: {
                composite: ['203a'],
                secondary: ['10f105'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f105',
    },
    {
        name: 'angle-up',
        categories: ['arrows'],
        terms: ['arrow', 'caret', 'collapse', 'upload'],
        label: 'angle-up',
        aliases: {
            unicodes: {
                composite: ['2303'],
                secondary: ['10f106'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f106',
    },
    {
        name: 'angles-down',
        categories: ['arrows'],
        terms: [],
        label: 'Angles down',
        aliases: {
            names: ['angle-double-down'],
            unicodes: {
                secondary: ['10f103'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f103',
    },
    {
        name: 'angles-left',
        categories: ['arrows'],
        terms: [],
        label: 'Angles left',
        aliases: {
            names: ['angle-double-left'],
            unicodes: {
                composite: ['ab'],
                secondary: ['10f100'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f100',
    },
    {
        name: 'angles-right',
        categories: ['arrows'],
        terms: [],
        label: 'Angles right',
        aliases: {
            names: ['angle-double-right'],
            unicodes: {
                composite: ['bb'],
                secondary: ['10f101'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f101',
    },
    {
        name: 'angles-up',
        categories: ['arrows'],
        terms: [],
        label: 'Angles up',
        aliases: {
            names: ['angle-double-up'],
            unicodes: {
                secondary: ['10f102'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f102',
    },
    {
        name: 'ankh',
        categories: ['religion'],
        terms: ['amulet', 'copper', 'coptic christianity', 'copts', 'crux ansata', 'egypt', 'venus'],
        label: 'Ankh',
        aliases: {
            unicodes: {
                composite: ['2625'],
                secondary: ['10f644'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f644',
    },
    {
        name: 'apartment',
        categories: ['buildings'],
        terms: [],
        label: 'Apartment',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e468',
    },
    {
        name: 'aperture',
        categories: ['photos-images'],
        terms: [],
        label: 'Aperture',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2df',
    },
    {
        name: 'apostrophe',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Apostrophe',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '27',
    },
    {
        name: 'apple-core',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Apple Core',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e08f',
    },
    {
        name: 'apple-pay',
        categories: ['shopping'],
        terms: [],
        label: 'Apple Pay',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f415',
    },
    {
        name: 'apple-whole',
        categories: ['childhood', 'education', 'food-beverage', 'fruits-vegetables'],
        terms: [],
        label: 'Apple whole',
        aliases: {
            names: ['apple-alt'],
            unicodes: {
                composite: ['1f34f', '1f34e'],
                secondary: ['10f5d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d1',
    },
    {
        name: 'archway',
        categories: ['buildings', 'travel-hotel'],
        terms: ['arc', 'monument', 'road', 'street', 'tunnel'],
        label: 'Archway',
        aliases: {
            unicodes: {
                secondary: ['10f557'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f557',
    },
    {
        name: 'arrow-down',
        categories: ['arrows'],
        terms: ['download'],
        label: 'Arrow down',
        aliases: {
            unicodes: {
                composite: ['2193'],
                secondary: ['10f063'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f063',
    },
    {
        name: 'arrow-down-1-9',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down 1 9',
        aliases: {
            names: ['sort-numeric-asc', 'sort-numeric-down'],
            unicodes: {
                secondary: ['10f162'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f162',
    },
    {
        name: 'arrow-down-9-1',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down 9 1',
        aliases: {
            names: ['sort-numeric-desc', 'sort-numeric-down-alt'],
            unicodes: {
                secondary: ['10f886'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f886',
    },
    {
        name: 'arrow-down-a-z',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down a z',
        aliases: {
            names: ['sort-alpha-asc', 'sort-alpha-down'],
            unicodes: {
                secondary: ['10f15d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f15d',
    },
    {
        name: 'arrow-down-arrow-up',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down arrow up',
        aliases: {
            names: ['sort-alt'],
            unicodes: {
                secondary: ['10f883'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f883',
    },
    {
        name: 'arrow-down-big-small',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down big small',
        aliases: {
            names: ['sort-size-down'],
            unicodes: {
                secondary: ['10f88c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88c',
    },
    {
        name: 'arrow-down-from-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down from dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e090',
    },
    {
        name: 'arrow-down-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down from line',
        aliases: {
            names: ['arrow-from-top'],
            unicodes: {
                composite: ['21a7'],
                secondary: ['10f345'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f345',
    },
    {
        name: 'arrow-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e091',
    },
    {
        name: 'arrow-down-left-and-arrow-up-right-to-center',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down left and arrow up right to center',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e092',
    },
    {
        name: 'arrow-down-long',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down long',
        aliases: {
            names: ['long-arrow-down'],
            unicodes: {
                secondary: ['10f175'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f175',
    },
    {
        name: 'arrow-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e093',
    },
    {
        name: 'arrow-down-short-wide',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down short wide',
        aliases: {
            names: ['sort-amount-desc', 'sort-amount-down-alt'],
            unicodes: {
                secondary: ['10f884'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f884',
    },
    {
        name: 'arrow-down-small-big',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down small big',
        aliases: {
            names: ['sort-size-down-alt'],
            unicodes: {
                secondary: ['10f88d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88d',
    },
    {
        name: 'arrow-down-square-triangle',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down square triangle',
        aliases: {
            names: ['sort-shapes-down-alt'],
            unicodes: {
                secondary: ['10f889'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f889',
    },
    {
        name: 'arrow-down-to-arc',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Down to Arc',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ae',
    },
    {
        name: 'arrow-down-to-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down to bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e094',
    },
    {
        name: 'arrow-down-to-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down to dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e095',
    },
    {
        name: 'arrow-down-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down to line',
        aliases: {
            names: ['arrow-to-bottom'],
            unicodes: {
                secondary: ['10f33d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f33d',
    },
    {
        name: 'arrow-down-to-square',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down to square',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e096',
    },
    {
        name: 'arrow-down-triangle-square',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down triangle square',
        aliases: {
            names: ['sort-shapes-down'],
            unicodes: {
                secondary: ['10f888'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f888',
    },
    {
        name: 'arrow-down-up-across-line',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrow Down-up-across-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4af',
    },
    {
        name: 'arrow-down-up-lock',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrow Down-up-lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b0',
    },
    {
        name: 'arrow-down-wide-short',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down wide short',
        aliases: {
            names: ['sort-amount-asc', 'sort-amount-down'],
            unicodes: {
                secondary: ['10f160'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f160',
    },
    {
        name: 'arrow-down-z-a',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow down z a',
        aliases: {
            names: ['sort-alpha-desc', 'sort-alpha-down-alt'],
            unicodes: {
                secondary: ['10f881'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f881',
    },
    {
        name: 'arrow-left',
        categories: ['arrows'],
        terms: ['back', 'previous'],
        label: 'arrow-left',
        aliases: {
            unicodes: {
                composite: ['2190'],
                secondary: ['10f060'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f060',
    },
    {
        name: 'arrow-left-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow left from line',
        aliases: {
            names: ['arrow-from-right'],
            unicodes: {
                composite: ['21a4'],
                secondary: ['10f344'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f344',
    },
    {
        name: 'arrow-left-long',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow left long',
        aliases: {
            names: ['long-arrow-left'],
            unicodes: {
                secondary: ['10f177'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f177',
    },
    {
        name: 'arrow-left-long-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Left-long-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d4',
    },
    {
        name: 'arrow-left-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow left to line',
        aliases: {
            names: ['arrow-to-left'],
            unicodes: {
                composite: ['21e4'],
                secondary: ['10f33e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f33e',
    },
    {
        name: 'arrow-pointer',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow pointer',
        aliases: {
            names: ['mouse-pointer'],
            unicodes: {
                secondary: ['10f245'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f245',
    },
    {
        name: 'arrow-right',
        categories: ['arrows'],
        terms: ['forward', 'next'],
        label: 'arrow right',
        aliases: {
            unicodes: {
                composite: ['2192'],
                secondary: ['10f061'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f061',
    },
    {
        name: 'arrow-right-arrow-left',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right arrow left',
        aliases: {
            names: ['exchange'],
            unicodes: {
                composite: ['21c4'],
                secondary: ['10f0ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ec',
    },
    {
        name: 'arrow-right-from-arc',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Right from Arc',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b1',
    },
    {
        name: 'arrow-right-from-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right from bracket',
        aliases: {
            names: ['sign-out'],
            unicodes: {
                secondary: ['10f08b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f08b',
    },
    {
        name: 'arrow-right-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right from line',
        aliases: {
            names: ['arrow-from-left'],
            unicodes: {
                composite: ['21a6'],
                secondary: ['10f343'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f343',
    },
    {
        name: 'arrow-right-long',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right long',
        aliases: {
            names: ['long-arrow-right'],
            unicodes: {
                secondary: ['10f178'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f178',
    },
    {
        name: 'arrow-right-long-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Right-long-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d5',
    },
    {
        name: 'arrow-right-to-arc',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Right to Arc',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b2',
    },
    {
        name: 'arrow-right-to-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right to bracket',
        aliases: {
            names: ['sign-in'],
            unicodes: {
                secondary: ['10f090'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f090',
    },
    {
        name: 'arrow-right-to-city',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Arrow Right-to-city',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b3',
    },
    {
        name: 'arrow-right-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow right to line',
        aliases: {
            names: ['arrow-to-right'],
            unicodes: {
                composite: ['21e5'],
                secondary: ['10f340'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f340',
    },
    {
        name: 'arrow-rotate-left',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Arrow Rotate Left',
        aliases: {
            names: ['arrow-left-rotate', 'arrow-rotate-back', 'arrow-rotate-backward', 'undo'],
            unicodes: {
                composite: ['21ba'],
                secondary: ['10f0e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e2',
    },
    {
        name: 'arrow-rotate-right',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Arrow Rotate Right',
        aliases: {
            names: ['arrow-right-rotate', 'arrow-rotate-forward', 'redo'],
            unicodes: {
                composite: ['21bb'],
                secondary: ['10f01e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f01e',
    },
    {
        name: 'arrow-trend-down',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow trend down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e097',
    },
    {
        name: 'arrow-trend-up',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow trend up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e098',
    },
    {
        name: 'arrow-turn-down',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow turn down',
        aliases: {
            names: ['level-down'],
            unicodes: {
                secondary: ['10f149'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f149',
    },
    {
        name: 'arrow-turn-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Turn Down Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e1',
    },
    {
        name: 'arrow-turn-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Turn-down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d6',
    },
    {
        name: 'arrow-turn-up',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow turn up',
        aliases: {
            names: ['level-up'],
            unicodes: {
                secondary: ['10f148'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f148',
    },
    {
        name: 'arrow-up',
        categories: ['arrows'],
        terms: ['forward', 'upload'],
        label: 'Arrow up',
        aliases: {
            unicodes: {
                composite: ['2191'],
                secondary: ['10f062'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f062',
    },
    {
        name: 'arrow-up-1-9',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up 1 9',
        aliases: {
            names: ['sort-numeric-up'],
            unicodes: {
                secondary: ['10f163'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f163',
    },
    {
        name: 'arrow-up-9-1',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up 9 1',
        aliases: {
            names: ['sort-numeric-up-alt'],
            unicodes: {
                secondary: ['10f887'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f887',
    },
    {
        name: 'arrow-up-a-z',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up a z',
        aliases: {
            names: ['sort-alpha-up'],
            unicodes: {
                secondary: ['10f15e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f15e',
    },
    {
        name: 'arrow-up-arrow-down',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up arrow down',
        aliases: {
            names: ['sort-up-down'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e099',
    },
    {
        name: 'arrow-up-big-small',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up big small',
        aliases: {
            names: ['sort-size-up'],
            unicodes: {
                secondary: ['10f88e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88e',
    },
    {
        name: 'arrow-up-from-arc',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Up from Arc',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b4',
    },
    {
        name: 'arrow-up-from-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up from bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09a',
    },
    {
        name: 'arrow-up-from-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up from dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09b',
    },
    {
        name: 'arrow-up-from-ground-water',
        categories: ['construction', 'energy', 'humanitarian'],
        terms: [],
        label: 'Arrow Up-from-ground-water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b5',
    },
    {
        name: 'arrow-up-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up from line',
        aliases: {
            names: ['arrow-from-bottom'],
            unicodes: {
                composite: ['21a5'],
                secondary: ['10f342'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f342',
    },
    {
        name: 'arrow-up-from-square',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up from square',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09c',
    },
    {
        name: 'arrow-up-from-water-pump',
        categories: ['household', 'humanitarian'],
        terms: [],
        label: 'Arrow Up-from-water-pump',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b6',
    },
    {
        name: 'arrow-up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09d',
    },
    {
        name: 'arrow-up-left-from-circle',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow Up left from circle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09e',
    },
    {
        name: 'arrow-up-long',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up long',
        aliases: {
            names: ['long-arrow-up'],
            unicodes: {
                secondary: ['10f176'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f176',
    },
    {
        name: 'arrow-up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e09f',
    },
    {
        name: 'arrow-up-right-and-arrow-down-left-from-center',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up right and arrow down left from center',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a0',
    },
    {
        name: 'arrow-up-right-dots',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrow Up-right-dots',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b7',
    },
    {
        name: 'arrow-up-right-from-square',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrow up right from square',
        aliases: {
            names: ['external-link'],
            unicodes: {
                secondary: ['10f08e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f08e',
    },
    {
        name: 'arrow-up-short-wide',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up short wide',
        aliases: {
            names: ['sort-amount-up-alt'],
            unicodes: {
                secondary: ['10f885'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f885',
    },
    {
        name: 'arrow-up-small-big',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up small big',
        aliases: {
            names: ['sort-size-up-alt'],
            unicodes: {
                secondary: ['10f88f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88f',
    },
    {
        name: 'arrow-up-square-triangle',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up square triangle',
        aliases: {
            names: ['sort-shapes-up-alt'],
            unicodes: {
                secondary: ['10f88b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88b',
    },
    {
        name: 'arrow-up-to-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up to dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a1',
    },
    {
        name: 'arrow-up-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up to line',
        aliases: {
            names: ['arrow-to-top'],
            unicodes: {
                secondary: ['10f341'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f341',
    },
    {
        name: 'arrow-up-triangle-square',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up triangle square',
        aliases: {
            names: ['sort-shapes-up'],
            unicodes: {
                secondary: ['10f88a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f88a',
    },
    {
        name: 'arrow-up-wide-short',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up wide short',
        aliases: {
            names: ['sort-amount-up'],
            unicodes: {
                secondary: ['10f161'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f161',
    },
    {
        name: 'arrow-up-z-a',
        categories: ['arrows'],
        terms: [],
        label: 'Arrow up z a',
        aliases: {
            names: ['sort-alpha-up-alt'],
            unicodes: {
                secondary: ['10f882'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f882',
    },
    {
        name: 'arrows-cross',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows Cross',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a2',
    },
    {
        name: 'arrows-down-to-line',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Down-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b8',
    },
    {
        name: 'arrows-down-to-people',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Arrows Down-to-people',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4b9',
    },
    {
        name: 'arrows-from-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows from dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a3',
    },
    {
        name: 'arrows-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows From-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a4',
    },
    {
        name: 'arrows-left-right',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows left right',
        aliases: {
            names: ['arrows-h'],
            unicodes: {
                secondary: ['10f07e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f07e',
    },
    {
        name: 'arrows-left-right-to-line',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Left-right-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ba',
    },
    {
        name: 'arrows-maximize',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Arrows maximize',
        aliases: {
            names: ['expand-arrows'],
            unicodes: {
                secondary: ['10f31d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f31d',
    },
    {
        name: 'arrows-minimize',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows minimize',
        aliases: {
            names: ['compress-arrows'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a5',
    },
    {
        name: 'arrows-repeat',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Arrows repeat',
        aliases: {
            names: ['repeat-alt'],
            unicodes: {
                secondary: ['10f364'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f364',
    },
    {
        name: 'arrows-repeat-1',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Arrows repeat 1',
        aliases: {
            names: ['repeat-1-alt'],
            unicodes: {
                secondary: ['10f366'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f366',
    },
    {
        name: 'arrows-retweet',
        categories: ['arrows', 'social'],
        terms: [],
        label: 'Arrows retweet',
        aliases: {
            names: ['retweet-alt'],
            unicodes: {
                secondary: ['10f361'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f361',
    },
    {
        name: 'arrows-rotate',
        categories: ['arrows', 'editing', 'media-playback'],
        terms: [],
        label: 'Arrows rotate',
        aliases: {
            names: ['refresh', 'sync'],
            unicodes: {
                composite: ['1f5d8'],
                secondary: ['10f021'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f021',
    },
    {
        name: 'arrows-spin',
        categories: ['arrows', 'business', 'humanitarian', 'marketing', 'spinners'],
        terms: [],
        label: 'Arrows Spin',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4bb',
    },
    {
        name: 'arrows-split-up-and-left',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Split-up-and-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4bc',
    },
    {
        name: 'arrows-to-circle',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows To-circle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4bd',
    },
    {
        name: 'arrows-to-dot',
        categories: ['arrows', 'business', 'humanitarian', 'marketing'],
        terms: [],
        label: 'Arrows To-dot',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4be',
    },
    {
        name: 'arrows-to-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows to dotted line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a6',
    },
    {
        name: 'arrows-to-eye',
        categories: ['arrows', 'business', 'humanitarian', 'marketing'],
        terms: [],
        label: 'Arrows To-eye',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4bf',
    },
    {
        name: 'arrows-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows To-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a7',
    },
    {
        name: 'arrows-turn-right',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Turn-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c0',
    },
    {
        name: 'arrows-turn-to-dots',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Turn-to-dots',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c1',
    },
    {
        name: 'arrows-up-down',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows up down',
        aliases: {
            names: ['arrows-v'],
            unicodes: {
                secondary: ['10f07d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f07d',
    },
    {
        name: 'arrows-up-down-left-right',
        categories: ['arrows'],
        terms: [],
        label: 'Arrows up down left right',
        aliases: {
            names: ['arrows'],
            unicodes: {
                secondary: ['10f047'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f047',
    },
    {
        name: 'arrows-up-to-line',
        categories: ['arrows', 'humanitarian'],
        terms: [],
        label: 'Arrows Up-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c2',
    },
    {
        name: 'asterisk',
        categories: ['punctuation-symbols', 'spinners'],
        terms: ['annotation', 'details', 'reference', 'star'],
        label: 'asterisk',
        aliases: {
            unicodes: {
                composite: ['2731', 'f069'],
                primary: ['f069'],
                secondary: ['10f069', '102a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2a',
    },
    {
        name: 'at',
        categories: ['communication', 'punctuation-symbols'],
        terms: ['address', 'author', 'e-mail', 'email', 'handle'],
        label: 'At',
        aliases: {
            unicodes: {
                composite: ['f1fa'],
                primary: ['f1fa'],
                secondary: ['10f1fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '40',
    },
    {
        name: 'atom',
        categories: ['education', 'energy', 'religion', 'science', 'science-fiction', 'spinners'],
        terms: ['atheism', 'chemistry', 'electron', 'ion', 'isotope', 'neutron', 'nuclear', 'proton', 'science'],
        label: 'Atom',
        aliases: {
            unicodes: {
                composite: ['269b'],
                secondary: ['10f5d2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d2',
    },
    {
        name: 'atom-simple',
        categories: ['education', 'energy', 'science', 'science-fiction', 'spinners'],
        terms: [],
        label: 'Atom simple',
        aliases: {
            names: ['atom-alt'],
            unicodes: {
                secondary: ['10f5d3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d3',
    },
    {
        name: 'audio-description',
        categories: ['accessibility', 'film-video'],
        terms: ['blind', 'narration', 'video', 'visual'],
        label: 'Rectangle audio description',
        aliases: {
            unicodes: {
                secondary: ['10f29e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f29e',
    },
    {
        name: 'audio-description-slash',
        categories: ['accessibility', 'film-video'],
        terms: [],
        label: 'Audio Description Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a8',
    },
    {
        name: 'austral-sign',
        categories: ['money'],
        terms: [],
        label: 'Austral Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0a9',
    },
    {
        name: 'avocado',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Avocado',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0aa',
    },
    {
        name: 'award',
        categories: ['education', 'political'],
        terms: ['honor', 'praise', 'prize', 'recognition', 'ribbon', 'trophy'],
        label: 'Award',
        aliases: {
            unicodes: {
                secondary: ['10f559'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f559',
    },
    {
        name: 'award-simple',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Award Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ab',
    },
    {
        name: 'axe',
        categories: ['camping', 'construction'],
        terms: ['blade', 'cut', 'fall', 'lumberjack', 'outdoors', 'sharp', 'swing', 'tool', 'weapon'],
        label: 'Axe',
        aliases: {
            unicodes: {
                composite: ['1fa93'],
                secondary: ['10f6b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b2',
    },
    {
        name: 'axe-battle',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'barbarian', 'd&d', 'dnd', 'fantasy', 'gimli', 'lumberjack', 'melee attack'],
        label: 'Axe Battle',
        aliases: {
            unicodes: {
                secondary: ['10f6b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b3',
    },
    {
        name: 'b',
        categories: ['alphabet'],
        terms: [],
        label: 'B',
        aliases: {
            unicodes: {
                composite: ['62'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '42',
    },
    {
        name: 'baby',
        categories: ['childhood', 'humanitarian', 'users-people'],
        terms: ['child', 'diaper', 'doll', 'human', 'infant', 'kid', 'offspring', 'person', 'sprout'],
        label: 'Baby',
        aliases: {
            unicodes: {
                secondary: ['10f77c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f77c',
    },
    {
        name: 'baby-carriage',
        categories: ['childhood', 'transportation', 'travel-hotel'],
        terms: ['buggy', 'carrier', 'infant', 'push', 'stroller', 'transportation', 'walk', 'wheels'],
        label: 'Baby Carriage',
        aliases: {
            names: ['carriage-baby'],
            unicodes: {
                secondary: ['10f77d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f77d',
    },
    {
        name: 'backpack',
        categories: ['camping', 'childhood', 'education'],
        terms: ['bag', 'book', 'carry', 'hiking', 'school', 'travel'],
        label: 'Backpack',
        aliases: {
            unicodes: {
                composite: ['1f392'],
                secondary: ['10f5d4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d4',
    },
    {
        name: 'backward',
        categories: ['media-playback'],
        terms: ['previous', 'rewind'],
        label: 'backward',
        aliases: {
            unicodes: {
                composite: ['23ea'],
                secondary: ['10f04a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f04a',
    },
    {
        name: 'backward-fast',
        categories: ['media-playback'],
        terms: [],
        label: 'Backward fast',
        aliases: {
            names: ['fast-backward'],
            unicodes: {
                composite: ['23ee'],
                secondary: ['10f049'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f049',
    },
    {
        name: 'backward-step',
        categories: ['media-playback'],
        terms: [],
        label: 'Backward step',
        aliases: {
            names: ['step-backward'],
            unicodes: {
                secondary: ['10f048'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f048',
    },
    {
        name: 'bacon',
        categories: ['food-beverage'],
        terms: ['blt', 'breakfast', 'ham', 'lard', 'meat', 'pancetta', 'pork', 'rasher'],
        label: 'Bacon',
        aliases: {
            unicodes: {
                composite: ['1f953'],
                secondary: ['10f7e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e5',
    },
    {
        name: 'bacteria',
        categories: ['medical-health'],
        terms: ['antibiotic', 'antibody', 'health', 'organism', 'sick'],
        label: 'Bacteria',
        aliases: {
            unicodes: {
                secondary: ['10e059'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e059',
    },
    {
        name: 'bacterium',
        categories: ['humanitarian', 'medical-health'],
        terms: ['antibiotic', 'antibody', 'health', 'organism', 'sick'],
        label: 'Bacterium',
        aliases: {
            unicodes: {
                secondary: ['10e05a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05a',
    },
    {
        name: 'badge',
        categories: ['business', 'marketing', 'shapes', 'shopping', 'spinners'],
        terms: ['discount', 'meatball', 'verified', 'verify'],
        label: 'Badge',
        aliases: {
            unicodes: {
                secondary: ['10f335'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f335',
    },
    {
        name: 'badge-check',
        categories: ['business', 'marketing', 'shopping'],
        terms: [
            'accept',
            'achievement',
            'agree',
            'award',
            'confirm',
            'correct',
            'done',
            'ok',
            'security',
            'select',
            'success',
            'verified',
            'verify',
            'winner',
            'yes',
        ],
        label: 'Check Badge',
        aliases: {
            unicodes: {
                secondary: ['10f336'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f336',
    },
    {
        name: 'badge-dollar',
        categories: ['business', 'marketing', 'money', 'shopping'],
        terms: ['deal', 'discount', 'money', 'save', 'usd'],
        label: 'Badge Dollar',
        aliases: {
            unicodes: {
                secondary: ['10f645'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f645',
    },
    {
        name: 'badge-percent',
        categories: ['business', 'marketing', 'money', 'shopping'],
        terms: ['deal', 'discount', 'money', 'save', 'usd'],
        label: 'Badge Percent',
        aliases: {
            unicodes: {
                secondary: ['10f646'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f646',
    },
    {
        name: 'badge-sheriff',
        categories: ['security'],
        terms: ['cowboy', 'justice', 'law', 'old west', 'western'],
        label: 'Sheriff Badge',
        aliases: {
            unicodes: {
                secondary: ['10f8a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a2',
    },
    {
        name: 'badger-honey',
        categories: ['animals'],
        terms: ['animal', 'care', 'dont', 'ewww', 'fauna', 'mammal', 'nasty'],
        label: 'Honey Badger',
        aliases: {
            unicodes: {
                composite: ['1f9a1'],
                secondary: ['10f6b4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b4',
    },
    {
        name: 'badminton',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Badminton',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33a',
    },
    {
        name: 'bag-shopping',
        categories: ['maps', 'shopping'],
        terms: [],
        label: 'Bag shopping',
        aliases: {
            names: ['shopping-bag'],
            unicodes: {
                secondary: ['10f290'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f290',
    },
    {
        name: 'bagel',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bagel',
        aliases: {
            unicodes: {
                composite: ['1f96f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d7',
    },
    {
        name: 'bags-shopping',
        categories: ['maps', 'shopping', 'social'],
        terms: ['buy', 'checkout', 'order', 'shopping', 'store'],
        label: 'Shopping Bags',
        aliases: {
            unicodes: {
                composite: ['1f6cd'],
                secondary: ['10f847'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f847',
    },
    {
        name: 'baguette',
        categories: ['food-beverage'],
        terms: [],
        label: 'Baguette',
        aliases: {
            unicodes: {
                composite: ['1f956'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d8',
    },
    {
        name: 'bahai',
        categories: ['religion', 'spinners'],
        terms: ['bahai', "bahá'í", 'star'],
        label: "Bahá'í",
        aliases: {
            unicodes: {
                secondary: ['10f666'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f666',
    },
    {
        name: 'baht-sign',
        categories: ['money'],
        terms: [],
        label: 'Baht Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ac',
    },
    {
        name: 'ball-pile',
        categories: ['childhood', 'design', 'shapes'],
        terms: ['balance', 'cannon', 'group', 'pyramid', 'snowball'],
        label: 'Ball Pile',
        aliases: {
            unicodes: {
                secondary: ['10f77e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f77e',
    },
    {
        name: 'balloon',
        categories: ['childhood', 'shopping'],
        terms: [],
        label: 'Balloon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e3',
    },
    {
        name: 'balloons',
        categories: ['childhood', 'shopping'],
        terms: [],
        label: 'Balloons',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e4',
    },
    {
        name: 'ballot',
        categories: ['political'],
        terms: ['checklist', 'choice', 'election', 'vote', 'voting'],
        label: 'Ballot',
        aliases: {
            unicodes: {
                secondary: ['10f732'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f732',
    },
    {
        name: 'ballot-check',
        categories: ['political'],
        terms: [
            'checklist',
            'choice',
            'completed',
            'done',
            'election',
            'finished',
            'select',
            'success',
            'tick',
            'vote',
            'voting',
        ],
        label: 'Ballot Check',
        aliases: {
            unicodes: {
                secondary: ['10f733'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f733',
    },
    {
        name: 'ban',
        categories: ['humanitarian', 'security'],
        terms: ['abort', 'ban', 'block', 'cancel', 'delete', 'hide', 'prohibit', 'remove', 'stop', 'trash'],
        label: 'ban',
        aliases: {
            names: ['cancel'],
            unicodes: {
                composite: ['1f6ab'],
                secondary: ['10f05e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f05e',
    },
    {
        name: 'ban-bug',
        categories: ['coding', 'security'],
        terms: [],
        label: 'Ban bug',
        aliases: {
            names: ['debug'],
            unicodes: {
                secondary: ['10f7f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f9',
    },
    {
        name: 'ban-parking',
        categories: ['maps'],
        terms: [],
        label: 'Ban parking',
        aliases: {
            names: ['parking-circle-slash'],
            unicodes: {
                secondary: ['10f616'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f616',
    },
    {
        name: 'ban-smoking',
        categories: ['medical-health', 'travel-hotel'],
        terms: [],
        label: 'Ban smoking',
        aliases: {
            names: ['smoking-ban'],
            unicodes: {
                composite: ['1f6ad'],
                secondary: ['10f54d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54d',
    },
    {
        name: 'banana',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Banana',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e5',
    },
    {
        name: 'bandage',
        categories: ['editing', 'medical-health'],
        terms: [],
        label: 'Bandage',
        aliases: {
            names: ['band-aid'],
            unicodes: {
                composite: ['1fa79'],
                secondary: ['10f462'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f462',
    },
    {
        name: 'bangladeshi-taka-sign',
        categories: ['money'],
        terms: [],
        label: 'Bangladeshi Taka Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e6',
    },
    {
        name: 'banjo',
        categories: ['music-audio'],
        terms: ['bluegrass', 'folk', 'guitar', 'instrument', 'jazz', 'music'],
        label: 'Banjo',
        aliases: {
            unicodes: {
                composite: ['1fa95'],
                secondary: ['10f8a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a3',
    },
    {
        name: 'barcode',
        categories: ['coding', 'shopping'],
        terms: ['info', 'laser', 'price', 'scan', 'upc'],
        label: 'barcode',
        aliases: {
            unicodes: {
                secondary: ['10f02a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02a',
    },
    {
        name: 'barcode-read',
        categories: ['logistics', 'shopping'],
        terms: ['info', 'laser', 'price', 'scan', 'upc'],
        label: 'Barcode Read',
        aliases: {
            unicodes: {
                secondary: ['10f464'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f464',
    },
    {
        name: 'barcode-scan',
        categories: ['logistics', 'shopping'],
        terms: ['info', 'laser', 'price', 'scan', 'upc'],
        label: 'Barcode Scan',
        aliases: {
            unicodes: {
                secondary: ['10f465'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f465',
    },
    {
        name: 'bars',
        categories: ['coding', 'editing'],
        terms: [
            'checklist',
            'drag',
            'hamburger',
            'list',
            'menu',
            'nav',
            'navigation',
            'ol',
            'reorder',
            'settings',
            'todo',
            'ul',
        ],
        label: 'Bars',
        aliases: {
            names: ['navicon'],
            unicodes: {
                secondary: ['10f0c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c9',
    },
    {
        name: 'bars-filter',
        categories: ['coding', 'editing'],
        terms: [],
        label: 'Bars Filter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ad',
    },
    {
        name: 'bars-progress',
        categories: ['business', 'charts-diagrams'],
        terms: [],
        label: 'Bars progress',
        aliases: {
            names: ['tasks-alt'],
            unicodes: {
                secondary: ['10f828'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f828',
    },
    {
        name: 'bars-sort',
        categories: ['coding', 'editing'],
        terms: [],
        label: 'Bars Sort',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ae',
    },
    {
        name: 'bars-staggered',
        categories: ['business', 'coding'],
        terms: [],
        label: 'Bars staggered',
        aliases: {
            names: ['reorder', 'stream'],
            unicodes: {
                secondary: ['10f550'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f550',
    },
    {
        name: 'baseball',
        categories: ['sports-fitness'],
        terms: ['bat', 'league', 'mlb', 'slugger', 'softball', 'sport'],
        label: 'Baseball Ball',
        aliases: {
            names: ['baseball-ball'],
            unicodes: {
                composite: ['1f94e', '26be'],
                secondary: ['10f433'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f433',
    },
    {
        name: 'baseball-bat-ball',
        categories: ['childhood', 'sports-fitness'],
        terms: [],
        label: 'Baseball bat ball',
        aliases: {
            unicodes: {
                secondary: ['10f432'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f432',
    },
    {
        name: 'basket-shopping',
        categories: ['maps', 'shopping'],
        terms: [],
        label: 'Basket shopping',
        aliases: {
            names: ['shopping-basket'],
            unicodes: {
                secondary: ['10f291'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f291',
    },
    {
        name: 'basket-shopping-simple',
        categories: ['shopping'],
        terms: [],
        label: 'Basket shopping simple',
        aliases: {
            names: ['shopping-basket-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0af',
    },
    {
        name: 'basketball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Basketball Ball',
        aliases: {
            names: ['basketball-ball'],
            unicodes: {
                composite: ['1f3c0'],
                secondary: ['10f434'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f434',
    },
    {
        name: 'basketball-hoop',
        categories: ['childhood', 'sports-fitness'],
        terms: ['backboard', 'dunk', 'hoop', 'nba'],
        label: 'Basketball Hoop',
        aliases: {
            unicodes: {
                secondary: ['10f435'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f435',
    },
    {
        name: 'bat',
        categories: ['animals', 'halloween'],
        terms: ['animal', 'batman', 'bruce wayne', 'flying', 'gotham', 'halloween', 'mammal', 'vampire', 'wings'],
        label: 'Bat',
        aliases: {
            unicodes: {
                secondary: ['10f6b5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b5',
    },
    {
        name: 'bath',
        categories: ['childhood', 'coding', 'household', 'maps', 'travel-hotel'],
        terms: ['clean', 'shower', 'tub', 'wash'],
        label: 'Bath',
        aliases: {
            names: ['bathtub'],
            unicodes: {
                composite: ['1f6c1'],
                secondary: ['10f2cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2cd',
    },
    {
        name: 'battery-bolt',
        categories: ['energy'],
        terms: ['charge', 'power', 'status'],
        label: 'Battery Bolt',
        aliases: {
            unicodes: {
                secondary: ['10f376'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f376',
    },
    {
        name: 'battery-empty',
        categories: ['energy'],
        terms: ['charge', 'dead', 'power', 'status'],
        label: 'Battery Empty',
        aliases: {
            names: ['battery-0'],
            unicodes: {
                secondary: ['10f244'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f244',
    },
    {
        name: 'battery-exclamation',
        categories: ['alert', 'energy'],
        terms: [],
        label: 'Battery Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b0',
    },
    {
        name: 'battery-full',
        categories: ['energy'],
        terms: ['charge', 'power', 'status'],
        label: 'Battery Full',
        aliases: {
            names: ['battery', 'battery-5'],
            unicodes: {
                composite: ['1f50b'],
                secondary: ['10f240'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f240',
    },
    {
        name: 'battery-half',
        categories: ['energy'],
        terms: ['charge', 'power', 'status'],
        label: 'Battery 1/2 Full',
        aliases: {
            names: ['battery-3'],
            unicodes: {
                secondary: ['10f242'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f242',
    },
    {
        name: 'battery-low',
        categories: ['energy'],
        terms: [],
        label: 'Battery Low',
        aliases: {
            names: ['battery-1'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b1',
    },
    {
        name: 'battery-quarter',
        categories: ['energy'],
        terms: ['charge', 'low', 'power', 'status'],
        label: 'Battery 1/4 Full',
        aliases: {
            names: ['battery-2'],
            unicodes: {
                secondary: ['10f243'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f243',
    },
    {
        name: 'battery-slash',
        categories: ['energy'],
        terms: ['charge', 'dead', 'power', 'status'],
        label: 'Battery Slash',
        aliases: {
            unicodes: {
                secondary: ['10f377'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f377',
    },
    {
        name: 'battery-three-quarters',
        categories: ['energy'],
        terms: ['charge', 'power', 'status'],
        label: 'Battery 3/4 Full',
        aliases: {
            names: ['battery-4'],
            unicodes: {
                secondary: ['10f241'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f241',
    },
    {
        name: 'bed',
        categories: ['household', 'humanitarian', 'maps', 'travel-hotel', 'users-people'],
        terms: ['lodging', 'mattress', 'rest', 'sleep', 'travel'],
        label: 'Bed',
        aliases: {
            unicodes: {
                composite: ['1f6cc'],
                secondary: ['10f236'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f236',
    },
    {
        name: 'bed-bunk',
        categories: ['household', 'travel-hotel'],
        terms: ['lodging', 'mattress', 'rest', 'siblings', 'sleep', 'sleepover', 'travel'],
        label: 'Bunkbed',
        aliases: {
            unicodes: {
                secondary: ['10f8f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f8',
    },
    {
        name: 'bed-empty',
        categories: ['household', 'travel-hotel'],
        terms: ['lodging', 'mattress', 'rest', 'sleep', 'travel'],
        label: 'Bed Empty',
        aliases: {
            unicodes: {
                composite: ['1f6cf'],
                secondary: ['10f8f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f9',
    },
    {
        name: 'bed-front',
        categories: ['household'],
        terms: [],
        label: 'Bed front',
        aliases: {
            names: ['bed-alt'],
            unicodes: {
                secondary: ['10f8f7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f7',
    },
    {
        name: 'bed-pulse',
        categories: ['medical-health'],
        terms: [],
        label: 'Bed pulse',
        aliases: {
            names: ['procedures'],
            unicodes: {
                secondary: ['10f487'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f487',
    },
    {
        name: 'bee',
        categories: ['animals'],
        terms: [],
        label: 'Bee',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b2',
    },
    {
        name: 'beer-mug',
        categories: ['food-beverage'],
        terms: [],
        label: 'Beer mug',
        aliases: {
            names: ['beer-foam'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b3',
    },
    {
        name: 'beer-mug-empty',
        categories: ['food-beverage', 'maps'],
        terms: [],
        label: 'Beer mug empty',
        aliases: {
            names: ['beer'],
            unicodes: {
                secondary: ['10f0fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0fc',
    },
    {
        name: 'bell',
        categories: ['alert', 'education', 'household', 'maps', 'shopping', 'social', 'time'],
        terms: ['alarm', 'alert', 'chime', 'notification', 'reminder'],
        label: 'bell',
        aliases: {
            unicodes: {
                composite: ['f0a2', '1f514'],
                secondary: ['10f0f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f3',
    },
    {
        name: 'bell-concierge',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Bell concierge',
        aliases: {
            names: ['concierge-bell'],
            unicodes: {
                composite: ['1f6ce'],
                secondary: ['10f562'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f562',
    },
    {
        name: 'bell-exclamation',
        categories: ['alert', 'time'],
        terms: ['alarm', 'alert', 'chime', 'notification', 'reminder'],
        label: 'Bell Exclamation',
        aliases: {
            unicodes: {
                secondary: ['10f848'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f848',
    },
    {
        name: 'bell-on',
        categories: ['alert', 'household'],
        terms: ['alarm', 'alert', 'chime', 'notification', 'reminder'],
        label: 'Bell On',
        aliases: {
            unicodes: {
                composite: ['1f56d'],
                secondary: ['10f8fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8fa',
    },
    {
        name: 'bell-plus',
        categories: ['time'],
        terms: ['alarm', 'alert', 'chime', 'notification', 'reminder'],
        label: 'Bell Plus',
        aliases: {
            unicodes: {
                secondary: ['10f849'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f849',
    },
    {
        name: 'bell-school',
        categories: ['childhood', 'education'],
        terms: ['alert', 'chime', 'class', 'notification', 'reminder'],
        label: 'Bell School',
        aliases: {
            unicodes: {
                secondary: ['10f5d5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d5',
    },
    {
        name: 'bell-school-slash',
        categories: ['alert', 'childhood', 'education'],
        terms: ['alert', 'cancel', 'class', 'disabled', 'notification', 'off', 'reminder'],
        label: 'Bell School Slash',
        aliases: {
            unicodes: {
                secondary: ['10f5d6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d6',
    },
    {
        name: 'bell-slash',
        categories: ['alert', 'education', 'maps', 'time'],
        terms: ['alert', 'cancel', 'disabled', 'notification', 'off', 'reminder'],
        label: 'Bell Slash',
        aliases: {
            unicodes: {
                composite: ['f1f7', '1f515'],
                secondary: ['10f1f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1f6',
    },
    {
        name: 'bells',
        categories: ['alert', 'holidays', 'music-audio'],
        terms: ['alert', 'christmas', 'holiday', 'notification', 'reminder', 'xmas'],
        label: 'Bells',
        aliases: {
            unicodes: {
                secondary: ['10f77f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f77f',
    },
    {
        name: 'bench-tree',
        categories: ['camping', 'nature'],
        terms: [],
        label: 'Bench Tree',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e7',
    },
    {
        name: 'bezier-curve',
        categories: ['design'],
        terms: ['curves', 'illustrator', 'lines', 'path', 'vector'],
        label: 'Bezier Curve',
        aliases: {
            unicodes: {
                secondary: ['10f55b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55b',
    },
    {
        name: 'bicycle',
        categories: ['maps', 'sports-fitness', 'transportation'],
        terms: ['bike', 'gears', 'pedal', 'transportation', 'vehicle'],
        label: 'Bicycle',
        aliases: {
            unicodes: {
                composite: ['1f6b2'],
                secondary: ['10f206'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f206',
    },
    {
        name: 'binary',
        categories: ['coding'],
        terms: [],
        label: 'Binary',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33b',
    },
    {
        name: 'binary-circle-check',
        categories: ['coding'],
        terms: [],
        label: 'Binary Circle Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33c',
    },
    {
        name: 'binary-lock',
        categories: ['coding'],
        terms: [],
        label: 'Binary Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33d',
    },
    {
        name: 'binary-slash',
        categories: ['coding'],
        terms: [],
        label: 'Binary Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33e',
    },
    {
        name: 'binoculars',
        categories: ['camping', 'maps', 'nature'],
        terms: ['glasses', 'magnify', 'scenic', 'spyglass', 'view'],
        label: 'Binoculars',
        aliases: {
            unicodes: {
                secondary: ['10f1e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e5',
    },
    {
        name: 'biohazard',
        categories: ['disaster', 'humanitarian', 'medical-health', 'science'],
        terms: ['covid-19', 'danger', 'dangerous', 'hazmat', 'medical', 'radioactive', 'toxic', 'waste', 'zombie'],
        label: 'Biohazard',
        aliases: {
            unicodes: {
                composite: ['2623'],
                secondary: ['10f780'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f780',
    },
    {
        name: 'bird',
        categories: ['animals', 'social'],
        terms: [],
        label: 'Bird',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e469',
    },
    {
        name: 'bitcoin',
        categories: ['money', 'shopping'],
        terms: [],
        label: 'Bitcoin',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f379',
    },
    {
        name: 'bitcoin-sign',
        categories: ['money'],
        terms: [],
        label: 'Bitcoin Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b4',
    },
    {
        name: 'blanket',
        categories: ['household', 'moving'],
        terms: ['bed', 'cover', 'cozy', 'sleep', 'warm'],
        label: 'Blanket',
        aliases: {
            unicodes: {
                secondary: ['10f498'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f498',
    },
    {
        name: 'blanket-fire',
        categories: ['household', 'security'],
        terms: [],
        label: 'Blanket Fire',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3da',
    },
    {
        name: 'blender',
        categories: ['food-beverage', 'household'],
        terms: ['cocktail', 'milkshake', 'mixer', 'puree', 'smoothie'],
        label: 'Blender',
        aliases: {
            unicodes: {
                secondary: ['10f517'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f517',
    },
    {
        name: 'blender-phone',
        categories: ['communication', 'devices-hardware'],
        terms: [
            'appliance',
            'cocktail',
            'communication',
            'fantasy',
            'milkshake',
            'mixer',
            'puree',
            'silly',
            'smoothie',
        ],
        label: 'Blender Phone',
        aliases: {
            unicodes: {
                secondary: ['10f6b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b6',
    },
    {
        name: 'blinds',
        categories: ['household'],
        terms: ['privacy', 'shutter', 'window'],
        label: 'Blinds',
        aliases: {
            unicodes: {
                secondary: ['10f8fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8fb',
    },
    {
        name: 'blinds-open',
        categories: ['household'],
        terms: ['privacy', 'shutter', 'window'],
        label: 'Blinds Open',
        aliases: {
            unicodes: {
                secondary: ['10f8fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8fc',
    },
    {
        name: 'blinds-raised',
        categories: ['household'],
        terms: ['privacy', 'shutter', 'window'],
        label: 'Blinds Raised',
        aliases: {
            unicodes: {
                secondary: ['10f8fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8fd',
    },
    {
        name: 'block',
        categories: ['childhood', 'construction', 'gaming'],
        terms: [],
        label: 'Block',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46a',
    },
    {
        name: 'block-brick',
        categories: ['construction', 'gaming', 'household'],
        terms: [],
        label: 'Brick Block',
        aliases: {
            names: ['wall-brick'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3db',
    },
    {
        name: 'block-brick-fire',
        categories: ['household', 'security'],
        terms: [],
        label: 'Block Brick-fire',
        aliases: {
            names: ['firewall'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3dc',
    },
    {
        name: 'block-question',
        categories: ['childhood', 'gaming', 'marketing'],
        terms: [],
        label: 'Block Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3dd',
    },
    {
        name: 'block-quote',
        categories: ['text-formatting'],
        terms: [],
        label: 'Block Quote',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b5',
    },
    {
        name: 'blog',
        categories: ['writing'],
        terms: ['journal', 'log', 'online', 'personal', 'post', 'web 2.0', 'wordpress', 'writing'],
        label: 'Blog',
        aliases: {
            unicodes: {
                secondary: ['10f781'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f781',
    },
    {
        name: 'blueberries',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Blueberries',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e8',
    },
    {
        name: 'bluetooth',
        categories: ['connectivity'],
        terms: [],
        label: 'Bluetooth',
        aliases: {
            unicodes: {
                secondary: ['10f293'],
            },
        },
        styles: [
            IconStyle.SOLID,
            IconStyle.REGULAR,
            IconStyle.LIGHT,
            IconStyle.THIN,
            IconStyle.DUOTONE,
            IconStyle.BRANDS,
        ],
        unicode: 'f293',
    },
    {
        name: 'bluetooth-b',
        categories: ['communication'],
        terms: [],
        label: 'Bluetooth',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f294',
    },
    {
        name: 'bold',
        categories: ['text-formatting'],
        terms: ['emphasis', 'format', 'text'],
        label: 'bold',
        aliases: {
            unicodes: {
                secondary: ['10f032'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f032',
    },
    {
        name: 'bolt',
        categories: ['energy', 'photos-images', 'weather'],
        terms: ['electricity', 'lightning', 'weather', 'zap'],
        label: 'Bolt',
        aliases: {
            names: ['zap'],
            unicodes: {
                composite: ['26a1'],
                secondary: ['10f0e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e7',
    },
    {
        name: 'bolt-auto',
        categories: ['photos-images'],
        terms: [],
        label: 'Bolt Auto',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b6',
    },
    {
        name: 'bolt-lightning',
        categories: ['photos-images', 'weather'],
        terms: [],
        label: 'Lightning Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b7',
    },
    {
        name: 'bolt-slash',
        categories: ['photos-images'],
        terms: [],
        label: 'Bolt Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b8',
    },
    {
        name: 'bomb',
        categories: ['maps'],
        terms: ['error', 'explode', 'fuse', 'grenade', 'warning'],
        label: 'Bomb',
        aliases: {
            unicodes: {
                composite: ['1f4a3'],
                secondary: ['10f1e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e2',
    },
    {
        name: 'bone',
        categories: ['food-beverage', 'medical-health'],
        terms: ['calcium', 'dog', 'skeletal', 'skeleton', 'tibia'],
        label: 'Bone',
        aliases: {
            unicodes: {
                composite: ['1f9b4'],
                secondary: ['10f5d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d7',
    },
    {
        name: 'bone-break',
        categories: ['medical-health'],
        terms: ['broken', 'calcium', 'dog', 'fracture', 'skeletal', 'skeleton', 'tibia'],
        label: 'Bone Break',
        aliases: {
            unicodes: {
                secondary: ['10f5d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d8',
    },
    {
        name: 'bong',
        categories: ['medical-health'],
        terms: ['aparatus', 'cannabis', 'marijuana', 'pipe', 'smoke', 'smoking'],
        label: 'Bong',
        aliases: {
            unicodes: {
                secondary: ['10f55c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55c',
    },
    {
        name: 'book',
        categories: ['business', 'maps', 'writing'],
        terms: ['diary', 'documentation', 'journal', 'library', 'read'],
        label: 'book',
        aliases: {
            unicodes: {
                composite: ['1f4d4'],
                secondary: ['10f02d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02d',
    },
    {
        name: 'book-arrow-right',
        categories: ['writing'],
        terms: [],
        label: 'Book Arrow Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0b9',
    },
    {
        name: 'book-arrow-up',
        categories: ['writing'],
        terms: [],
        label: 'Book Arrow Up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ba',
    },
    {
        name: 'book-atlas',
        categories: ['maps', 'travel-hotel'],
        terms: [],
        label: 'Book atlas',
        aliases: {
            names: ['atlas'],
            unicodes: {
                secondary: ['10f558'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f558',
    },
    {
        name: 'book-bible',
        categories: ['religion'],
        terms: [],
        label: 'Book bible',
        aliases: {
            names: ['bible'],
            unicodes: {
                secondary: ['10f647'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f647',
    },
    {
        name: 'book-blank',
        categories: ['education'],
        terms: [],
        label: 'Book blank',
        aliases: {
            names: ['book-alt'],
            unicodes: {
                composite: ['1f4d7', '1f4d8', '1f4d9', '1f4d5'],
                secondary: ['10f5d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d9',
    },
    {
        name: 'book-bookmark',
        categories: ['humanitarian', 'writing'],
        terms: [],
        label: 'Book Bookmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0bb',
    },
    {
        name: 'book-circle-arrow-right',
        categories: ['writing'],
        terms: [],
        label: 'Book Circle Arrow Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0bc',
    },
    {
        name: 'book-circle-arrow-up',
        categories: ['writing'],
        terms: [],
        label: 'Book Circle Arrow Up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0bd',
    },
    {
        name: 'book-copy',
        categories: ['education', 'writing'],
        terms: [],
        label: 'Book Copy',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0be',
    },
    {
        name: 'book-font',
        categories: ['design', 'writing'],
        terms: [],
        label: 'Book Font',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0bf',
    },
    {
        name: 'book-heart',
        categories: ['charity', 'sports-fitness'],
        terms: ['charity', 'diary', 'documentation', 'donation', 'health', 'history', 'journal', 'library', 'read'],
        label: 'Book with Heart',
        aliases: {
            unicodes: {
                secondary: ['10f499'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f499',
    },
    {
        name: 'book-journal-whills',
        categories: ['religion', 'science-fiction'],
        terms: [],
        label: 'Book journal whills',
        aliases: {
            names: ['journal-whills'],
            unicodes: {
                secondary: ['10f66a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66a',
    },
    {
        name: 'book-medical',
        categories: ['medical-health'],
        terms: ['diary', 'documentation', 'health', 'history', 'journal', 'library', 'read', 'record'],
        label: 'Medical Book',
        aliases: {
            unicodes: {
                secondary: ['10f7e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e6',
    },
    {
        name: 'book-open',
        categories: ['education'],
        terms: ['flyer', 'library', 'notebook', 'open book', 'pamphlet', 'reading'],
        label: 'Book Open',
        aliases: {
            unicodes: {
                composite: ['1f56e', '1f4d6'],
                secondary: ['10f518'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f518',
    },
    {
        name: 'book-open-cover',
        categories: ['education', 'writing'],
        terms: [],
        label: 'Book open cover',
        aliases: {
            names: ['book-open-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c0',
    },
    {
        name: 'book-open-reader',
        categories: ['education'],
        terms: [],
        label: 'Book open reader',
        aliases: {
            names: ['book-reader'],
            unicodes: {
                secondary: ['10f5da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5da',
    },
    {
        name: 'book-quran',
        categories: ['religion'],
        terms: [],
        label: 'Book quran',
        aliases: {
            names: ['quran'],
            unicodes: {
                secondary: ['10f687'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f687',
    },
    {
        name: 'book-section',
        categories: ['business', 'security'],
        terms: [],
        label: 'Law Book',
        aliases: {
            names: ['book-law'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c1',
    },
    {
        name: 'book-skull',
        categories: ['gaming', 'halloween'],
        terms: [],
        label: 'Book skull',
        aliases: {
            names: ['book-dead'],
            unicodes: {
                secondary: ['10f6b7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b7',
    },
    {
        name: 'book-sparkles',
        categories: ['gaming', 'halloween', 'science'],
        terms: [],
        label: 'Book sparkles',
        aliases: {
            names: ['book-spells'],
            unicodes: {
                secondary: ['10f6b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b8',
    },
    {
        name: 'book-tanakh',
        categories: ['religion'],
        terms: [],
        label: 'Book tanakh',
        aliases: {
            names: ['tanakh'],
            unicodes: {
                secondary: ['10f827'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f827',
    },
    {
        name: 'book-user',
        categories: ['medical-health', 'users-people'],
        terms: ['address', 'biography', 'diary', 'documentation', 'journal', 'library', 'read'],
        label: 'Book with User',
        aliases: {
            unicodes: {
                secondary: ['10f7e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e7',
    },
    {
        name: 'bookmark',
        categories: ['maps', 'shapes', 'shopping', 'writing'],
        terms: ['favorite', 'marker', 'read', 'remember', 'save'],
        label: 'bookmark',
        aliases: {
            unicodes: {
                composite: ['f097', '1f516'],
                secondary: ['10f02e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02e',
    },
    {
        name: 'bookmark-slash',
        categories: ['writing'],
        terms: [],
        label: 'Bookmark Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c2',
    },
    {
        name: 'books',
        categories: ['education', 'household'],
        terms: ['diary', 'documentation', 'journal', 'library', 'read'],
        label: 'Books',
        aliases: {
            unicodes: {
                composite: ['1f4da'],
                secondary: ['10f5db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5db',
    },
    {
        name: 'books-medical',
        categories: ['medical-health'],
        terms: ['add', 'diary', 'documentation', 'health', 'journal', 'library', 'read', 'records'],
        label: 'Medical Books',
        aliases: {
            unicodes: {
                secondary: ['10f7e8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e8',
    },
    {
        name: 'boombox',
        categories: ['music-audio'],
        terms: ['cassette', 'jambox', 'john cusack', 'music', 'radio', 'say anything'],
        label: 'Boombox',
        aliases: {
            unicodes: {
                composite: ['1f4fe'],
                secondary: ['10f8a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a5',
    },
    {
        name: 'boot',
        categories: ['camping', 'clothing-fashion'],
        terms: ['clothing', 'foot', 'hiking', 'shoe', 'sturdy'],
        label: 'Boot',
        aliases: {
            unicodes: {
                composite: ['1f97e'],
                secondary: ['10f782'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f782',
    },
    {
        name: 'boot-heeled',
        categories: ['clothing-fashion'],
        terms: [],
        label: 'Boot Heeled',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e33f',
    },
    {
        name: 'booth-curtain',
        categories: ['political', 'shopping'],
        terms: ['changing room', 'election', 'vote', 'voting', 'voting booth'],
        label: 'Booth with Curtain',
        aliases: {
            unicodes: {
                secondary: ['10f734'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f734',
    },
    {
        name: 'border-all',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border All',
        aliases: {
            unicodes: {
                secondary: ['10f84c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84c',
    },
    {
        name: 'border-bottom',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Bottom',
        aliases: {
            unicodes: {
                secondary: ['10f84d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84d',
    },
    {
        name: 'border-bottom-right',
        categories: ['text-formatting'],
        terms: [],
        label: 'Border bottom right',
        aliases: {
            names: ['border-style-alt'],
            unicodes: {
                secondary: ['10f854'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f854',
    },
    {
        name: 'border-center-h',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Center Horizontal',
        aliases: {
            unicodes: {
                secondary: ['10f89c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f89c',
    },
    {
        name: 'border-center-v',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Center Vertical',
        aliases: {
            unicodes: {
                secondary: ['10f89d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f89d',
    },
    {
        name: 'border-inner',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Inner',
        aliases: {
            unicodes: {
                secondary: ['10f84e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84e',
    },
    {
        name: 'border-left',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Left',
        aliases: {
            unicodes: {
                secondary: ['10f84f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84f',
    },
    {
        name: 'border-none',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border None',
        aliases: {
            unicodes: {
                secondary: ['10f850'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f850',
    },
    {
        name: 'border-outer',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Outer',
        aliases: {
            unicodes: {
                secondary: ['10f851'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f851',
    },
    {
        name: 'border-right',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Right',
        aliases: {
            unicodes: {
                secondary: ['10f852'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f852',
    },
    {
        name: 'border-top',
        categories: ['text-formatting'],
        terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
        label: 'Border Top',
        aliases: {
            unicodes: {
                secondary: ['10f855'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f855',
    },
    {
        name: 'border-top-left',
        categories: ['text-formatting'],
        terms: [],
        label: 'Border top left',
        aliases: {
            names: ['border-style'],
            unicodes: {
                secondary: ['10f853'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f853',
    },
    {
        name: 'bore-hole',
        categories: ['construction', 'humanitarian'],
        terms: [],
        label: 'Bore Hole',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c3',
    },
    {
        name: 'bottle-droplet',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Bottle Droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c4',
    },
    {
        name: 'bottle-water',
        categories: ['camping', 'food-beverage', 'humanitarian'],
        terms: [],
        label: 'Bottle Water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c5',
    },
    {
        name: 'bow-arrow',
        categories: ['gaming'],
        terms: [
            'Dungeons & Dragons',
            'archery',
            'd&d',
            'dnd',
            'fantasy',
            'legolas',
            'ranged attack',
            'ranger',
            'weapon',
        ],
        label: 'Bow Arrow',
        aliases: {
            unicodes: {
                composite: ['1f3f9'],
                secondary: ['10f6b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b9',
    },
    {
        name: 'bowl-chopsticks',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bowl Chopsticks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2e9',
    },
    {
        name: 'bowl-chopsticks-noodles',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bowl Chopsticks Noodles',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ea',
    },
    {
        name: 'bowl-food',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Bowl Food',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c6',
    },
    {
        name: 'bowl-hot',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bowl hot',
        aliases: {
            names: ['soup'],
            unicodes: {
                secondary: ['10f823'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f823',
    },
    {
        name: 'bowl-rice',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Bowl Rice',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2eb',
    },
    {
        name: 'bowl-scoop',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bowl Scoop',
        aliases: {
            names: ['bowl-shaved-ice'],
            unicodes: {
                composite: ['1f367'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3de',
    },
    {
        name: 'bowl-scoops',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bowl with Scoops',
        aliases: {
            unicodes: {
                composite: ['1f345'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3df',
    },
    {
        name: 'bowl-soft-serve',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Bowl Soft-Serve',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46b',
    },
    {
        name: 'bowl-spoon',
        categories: ['food-beverage', 'household'],
        terms: [],
        label: 'Bowl Spoon',
        aliases: {
            unicodes: {
                composite: ['1f963'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e0',
    },
    {
        name: 'bowling-ball',
        categories: ['sports-fitness'],
        terms: ['alley', 'candlepin', 'gutter', 'lane', 'strike', 'tenpin'],
        label: 'Bowling Ball',
        aliases: {
            unicodes: {
                secondary: ['10f436'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f436',
    },
    {
        name: 'bowling-ball-pin',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Bowling Ball Pin',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c3',
    },
    {
        name: 'bowling-pins',
        categories: ['sports-fitness'],
        terms: ['alley', 'candlepin', 'gutter', 'lane', 'strike', 'tenpin'],
        label: 'Bowling Pins',
        aliases: {
            unicodes: {
                secondary: ['10f437'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f437',
    },
    {
        name: 'box',
        categories: ['logistics'],
        terms: ['archive', 'container', 'package', 'storage'],
        label: 'Box',
        aliases: {
            unicodes: {
                composite: ['1f4e6'],
                secondary: ['10f466'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f466',
    },
    {
        name: 'box-archive',
        categories: ['business', 'coding', 'files', 'moving', 'writing'],
        terms: [],
        label: 'Box archive',
        aliases: {
            names: ['archive'],
            unicodes: {
                secondary: ['10f187'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f187',
    },
    {
        name: 'box-ballot',
        categories: ['political'],
        terms: ['election', 'politics', 'vote', 'voting'],
        label: 'Box Ballot',
        aliases: {
            unicodes: {
                composite: ['1f5f3'],
                secondary: ['10f735'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f735',
    },
    {
        name: 'box-check',
        categories: ['logistics'],
        terms: [
            'accept',
            'agree',
            'confirm',
            'correct',
            'done',
            'ok',
            'package',
            'select',
            'success',
            'tick',
            'todo',
            'yes',
        ],
        label: 'Box Check',
        aliases: {
            unicodes: {
                secondary: ['10f467'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f467',
    },
    {
        name: 'box-circle-check',
        categories: ['logistics'],
        terms: [],
        label: 'Box Circle Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c4',
    },
    {
        name: 'box-dollar',
        categories: ['charity'],
        terms: [],
        label: 'Box dollar',
        aliases: {
            names: ['box-usd'],
            unicodes: {
                secondary: ['10f4a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a0',
    },
    {
        name: 'box-heart',
        categories: ['charity'],
        terms: ['care', 'donation', 'package', 'storage'],
        label: 'Box with Heart',
        aliases: {
            unicodes: {
                secondary: ['10f49d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49d',
    },
    {
        name: 'box-open',
        categories: ['moving'],
        terms: ['archive', 'container', 'package', 'storage', 'unpack'],
        label: 'Box Open',
        aliases: {
            unicodes: {
                secondary: ['10f49e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49e',
    },
    {
        name: 'box-open-full',
        categories: ['moving', 'shopping'],
        terms: [],
        label: 'Box open full',
        aliases: {
            names: ['box-full'],
            unicodes: {
                secondary: ['10f49c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49c',
    },
    {
        name: 'box-taped',
        categories: ['moving'],
        terms: [],
        label: 'Box taped',
        aliases: {
            names: ['box-alt'],
            unicodes: {
                secondary: ['10f49a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49a',
    },
    {
        name: 'box-tissue',
        categories: ['household'],
        terms: ['cough', 'covid-19', 'kleenex', 'mucus', 'nose', 'sneeze', 'snot'],
        label: 'Tissue Box',
        aliases: {
            unicodes: {
                secondary: ['10e05b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05b',
    },
    {
        name: 'boxes-packing',
        categories: ['business', 'humanitarian', 'logistics', 'moving'],
        terms: [],
        label: 'Boxes Packing',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c7',
    },
    {
        name: 'boxes-stacked',
        categories: ['logistics'],
        terms: [],
        label: 'Boxes stacked',
        aliases: {
            names: ['boxes', 'boxes-alt'],
            unicodes: {
                composite: ['f4a1'],
                primary: ['f4a1'],
                secondary: ['10f4a1', '10f468'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f468',
    },
    {
        name: 'boxing-glove',
        categories: ['sports-fitness'],
        terms: ['boxer', 'fight', 'heavyweight', 'spar'],
        label: 'Boxing Glove',
        aliases: {
            names: ['glove-boxing'],
            unicodes: {
                composite: ['1f94a'],
                secondary: ['10f438'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f438',
    },
    {
        name: 'bracket-curly',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket curly',
        aliases: {
            names: ['bracket-curly-left'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '7b',
    },
    {
        name: 'bracket-curly-right',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket Curly-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '7d',
    },
    {
        name: 'bracket-round',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket Round',
        aliases: {
            names: ['parenthesis'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '28',
    },
    {
        name: 'bracket-round-right',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket Round-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '29',
    },
    {
        name: 'bracket-square',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket square',
        aliases: {
            names: ['bracket', 'bracket-left'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '5b',
    },
    {
        name: 'bracket-square-right',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Bracket Square-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '5d',
    },
    {
        name: 'brackets-curly',
        categories: ['coding'],
        terms: ['code', 'developer', 'development', 'parentheses'],
        label: 'Curly Brackets',
        aliases: {
            unicodes: {
                secondary: ['10f7ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ea',
    },
    {
        name: 'brackets-round',
        categories: ['coding', 'punctuation-symbols'],
        terms: [],
        label: 'Brackets Round',
        aliases: {
            names: ['parentheses'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c5',
    },
    {
        name: 'brackets-square',
        categories: ['coding'],
        terms: [],
        label: 'Brackets square',
        aliases: {
            names: ['brackets'],
            unicodes: {
                secondary: ['10f7e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e9',
    },
    {
        name: 'braille',
        categories: ['accessibility'],
        terms: ['alphabet', 'blind', 'dots', 'raised', 'vision'],
        label: 'Braille',
        aliases: {
            unicodes: {
                secondary: ['10f2a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a1',
    },
    {
        name: 'brain',
        categories: ['medical-health', 'science'],
        terms: ['cerebellum', 'gray matter', 'intellect', 'medulla oblongata', 'mind', 'noodle', 'wit'],
        label: 'Brain',
        aliases: {
            unicodes: {
                composite: ['1f9e0'],
                secondary: ['10f5dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5dc',
    },
    {
        name: 'brain-arrow-curved-right',
        categories: ['business'],
        terms: [],
        label: 'Brain arrow curved right',
        aliases: {
            names: ['mind-share'],
            unicodes: {
                secondary: ['10f677'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f677',
    },
    {
        name: 'brain-circuit',
        categories: ['coding'],
        terms: [],
        label: 'Brain Circuit',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c6',
    },
    {
        name: 'brake-warning',
        categories: ['automotive'],
        terms: [],
        label: 'Brake Warning',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c7',
    },
    {
        name: 'brazilian-real-sign',
        categories: ['money'],
        terms: [],
        label: 'Brazilian Real Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46c',
    },
    {
        name: 'bread-loaf',
        categories: ['food-beverage'],
        terms: [
            'bake',
            'bakery',
            'baking',
            'dough',
            'flour',
            'gluten',
            'grain',
            'sandwich',
            'sourdough',
            'toast',
            'wheat',
            'yeast',
        ],
        label: 'Loaf of Bread',
        aliases: {
            unicodes: {
                composite: ['1f35e'],
                secondary: ['10f7eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7eb',
    },
    {
        name: 'bread-slice',
        categories: ['food-beverage'],
        terms: [
            'bake',
            'bakery',
            'baking',
            'dough',
            'flour',
            'gluten',
            'grain',
            'sandwich',
            'sourdough',
            'toast',
            'wheat',
            'yeast',
        ],
        label: 'Bread Slice',
        aliases: {
            unicodes: {
                secondary: ['10f7ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ec',
    },
    {
        name: 'bread-slice-butter',
        categories: ['food-beverage'],
        terms: [],
        label: 'Bread Slice-butter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e1',
    },
    {
        name: 'bridge',
        categories: ['humanitarian', 'logistics', 'maps'],
        terms: [],
        label: 'Bridge',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c8',
    },
    {
        name: 'bridge-circle-check',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Bridge Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4c9',
    },
    {
        name: 'bridge-circle-exclamation',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Bridge Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ca',
    },
    {
        name: 'bridge-circle-xmark',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Bridge Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4cb',
    },
    {
        name: 'bridge-lock',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Bridge Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4cc',
    },
    {
        name: 'bridge-suspension',
        categories: ['buildings', 'maps', 'maritime', 'transportation'],
        terms: [],
        label: 'Bridge Suspension',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4cd',
    },
    {
        name: 'bridge-water',
        categories: ['humanitarian', 'logistics', 'maps'],
        terms: [],
        label: 'Bridge Water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ce',
    },
    {
        name: 'briefcase',
        categories: ['business', 'maps', 'travel-hotel'],
        terms: ['bag', 'business', 'luggage', 'office', 'work'],
        label: 'Briefcase',
        aliases: {
            unicodes: {
                composite: ['1f4bc'],
                secondary: ['10f0b1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0b1',
    },
    {
        name: 'briefcase-arrow-right',
        categories: ['business'],
        terms: [],
        label: 'Briefcase Arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2f2',
    },
    {
        name: 'briefcase-blank',
        categories: ['business'],
        terms: [],
        label: 'Briefcase Blank',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c8',
    },
    {
        name: 'briefcase-medical',
        categories: ['medical-health'],
        terms: ['doctor', 'emt', 'first aid', 'health'],
        label: 'Medical Briefcase',
        aliases: {
            unicodes: {
                secondary: ['10f469'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f469',
    },
    {
        name: 'brightness',
        categories: ['accessibility', 'toggle'],
        terms: [],
        label: 'Brightness',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0c9',
    },
    {
        name: 'brightness-low',
        categories: ['accessibility', 'toggle'],
        terms: [],
        label: 'Brightness Low',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ca',
    },
    {
        name: 'bring-forward',
        categories: ['design'],
        terms: ['arrange', 'front', 'layer', 'order', 'stack'],
        label: 'Bring Forward',
        aliases: {
            unicodes: {
                secondary: ['10f856'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f856',
    },
    {
        name: 'bring-front',
        categories: ['design'],
        terms: ['arrange', 'forward', 'layer', 'order', 'stack'],
        label: 'Bring Front',
        aliases: {
            unicodes: {
                secondary: ['10f857'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f857',
    },
    {
        name: 'broccoli',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Broccoli',
        aliases: {
            unicodes: {
                composite: ['1f966'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e2',
    },
    {
        name: 'broom',
        categories: ['halloween'],
        terms: ['clean', 'firebolt', 'fly', 'halloween', 'nimbus 2000', 'quidditch', 'sweep', 'witch'],
        label: 'Broom',
        aliases: {
            unicodes: {
                composite: ['1f9f9'],
                secondary: ['10f51a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51a',
    },
    {
        name: 'broom-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Broom and Ball',
        aliases: {
            names: ['quidditch', 'quidditch-broom-ball'],
            unicodes: {
                secondary: ['10f458'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f458',
    },
    {
        name: 'browser',
        categories: ['coding'],
        terms: ['app', 'application', 'desktop', 'explorer', 'internet', 'web', 'website'],
        label: 'Browser',
        aliases: {
            unicodes: {
                composite: ['1f5d4'],
                secondary: ['10f37e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f37e',
    },
    {
        name: 'browsers',
        categories: ['coding'],
        terms: [],
        label: 'Browsers',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0cb',
    },
    {
        name: 'brush',
        categories: ['construction', 'design', 'editing'],
        terms: ['art', 'bristles', 'color', 'handle', 'paint'],
        label: 'Brush',
        aliases: {
            unicodes: {
                secondary: ['10f55d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55d',
    },
    {
        name: 'btc',
        categories: ['money', 'shopping'],
        terms: [],
        label: 'BTC',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f15a',
    },
    {
        name: 'bucket',
        categories: ['camping', 'childhood', 'construction', 'humanitarian'],
        terms: [],
        label: 'Bucket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4cf',
    },
    {
        name: 'bug',
        categories: ['coding', 'nature', 'security'],
        terms: ['beetle', 'error', 'insect', 'report'],
        label: 'Bug',
        aliases: {
            unicodes: {
                secondary: ['10f188'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f188',
    },
    {
        name: 'bug-slash',
        categories: ['coding', 'security'],
        terms: [],
        label: 'Bug Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e490',
    },
    {
        name: 'bugs',
        categories: ['animals', 'disaster', 'humanitarian', 'nature'],
        terms: [],
        label: 'Bugs',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d0',
    },
    {
        name: 'building',
        categories: ['buildings', 'business', 'humanitarian', 'maps'],
        terms: ['apartment', 'business', 'city', 'company', 'office', 'work'],
        label: 'Building',
        aliases: {
            unicodes: {
                composite: ['f0f7', '1f3e2'],
                secondary: ['10f1ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ad',
    },
    {
        name: 'building-circle-arrow-right',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Circle-arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d1',
    },
    {
        name: 'building-circle-check',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d2',
    },
    {
        name: 'building-circle-exclamation',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d3',
    },
    {
        name: 'building-circle-xmark',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d4',
    },
    {
        name: 'building-columns',
        categories: ['buildings', 'humanitarian', 'maps'],
        terms: [],
        label: 'Building with Columns',
        aliases: {
            names: ['bank', 'institution', 'museum', 'university'],
            unicodes: {
                secondary: ['10f19c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f19c',
    },
    {
        name: 'building-flag',
        categories: ['buildings', 'humanitarian', 'political'],
        terms: [],
        label: 'Building Flag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d5',
    },
    {
        name: 'building-lock',
        categories: ['buildings', 'humanitarian', 'security'],
        terms: [],
        label: 'Building Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d6',
    },
    {
        name: 'building-ngo',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Ngo',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d7',
    },
    {
        name: 'building-shield',
        categories: ['buildings', 'humanitarian', 'security'],
        terms: [],
        label: 'Building Shield',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d8',
    },
    {
        name: 'building-un',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Un',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4d9',
    },
    {
        name: 'building-user',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4da',
    },
    {
        name: 'building-wheat',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'Building Wheat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4db',
    },
    {
        name: 'buildings',
        categories: ['buildings'],
        terms: [],
        label: 'Buildings',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0cc',
    },
    {
        name: 'bullhorn',
        categories: ['business', 'communication', 'marketing', 'political', 'shopping'],
        terms: ['announcement', 'broadcast', 'louder', 'megaphone', 'share'],
        label: 'bullhorn',
        aliases: {
            unicodes: {
                composite: ['1f56b', '1f4e2'],
                secondary: ['10f0a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a1',
    },
    {
        name: 'bullseye',
        categories: ['business', 'marketing', 'toggle'],
        terms: ['archery', 'goal', 'objective', 'target'],
        label: 'Bullseye',
        aliases: {
            unicodes: {
                secondary: ['10f140'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f140',
    },
    {
        name: 'bullseye-arrow',
        categories: ['marketing', 'spinners', 'toggle'],
        terms: ['archery', 'arrow', 'goal', 'objective', 'robin hood', 'target'],
        label: 'Bullseye Arrow',
        aliases: {
            unicodes: {
                composite: ['1f3af'],
                secondary: ['10f648'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f648',
    },
    {
        name: 'bullseye-pointer',
        categories: ['marketing'],
        terms: ['archery', 'goal', 'objective', 'target'],
        label: 'Bullseye Pointer',
        aliases: {
            unicodes: {
                secondary: ['10f649'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f649',
    },
    {
        name: 'burger',
        categories: ['food-beverage'],
        terms: [],
        label: 'Burger',
        aliases: {
            names: ['hamburger'],
            unicodes: {
                secondary: ['10f805'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f805',
    },
    {
        name: 'burger-cheese',
        categories: ['food-beverage'],
        terms: [],
        label: 'Burger cheese',
        aliases: {
            names: ['cheeseburger'],
            unicodes: {
                composite: ['1f354'],
                secondary: ['10f7f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f1',
    },
    {
        name: 'burger-fries',
        categories: ['food-beverage'],
        terms: [],
        label: 'Burger Fries',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0cd',
    },
    {
        name: 'burger-glass',
        categories: ['food-beverage'],
        terms: [],
        label: 'Burger Glass',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ce',
    },
    {
        name: 'burger-lettuce',
        categories: ['food-beverage'],
        terms: [],
        label: 'Burger Lettuce',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e3',
    },
    {
        name: 'burger-soda',
        categories: ['food-beverage'],
        terms: [
            'bacon',
            'beef',
            'cheeseburger',
            'fast food',
            'grill',
            'ground beef',
            'hamburger',
            'junk food',
            'pop',
            'sandwich',
            'slider',
        ],
        label: 'Burger and Soda',
        aliases: {
            unicodes: {
                secondary: ['10f858'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f858',
    },
    {
        name: 'burrito',
        categories: ['energy', 'food-beverage'],
        terms: ['beans', 'breakfast', 'chimichanga', 'mexican', 'salsa', 'taco', 'tortilla'],
        label: 'Burrito',
        aliases: {
            unicodes: {
                composite: ['1f32f'],
                secondary: ['10f7ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ed',
    },
    {
        name: 'burst',
        categories: ['disaster', 'humanitarian', 'security', 'shapes'],
        terms: [],
        label: 'Burst',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4dc',
    },
    {
        name: 'bus',
        categories: ['automotive', 'humanitarian', 'logistics', 'transportation', 'travel-hotel'],
        terms: ['public transportation', 'transportation', 'travel', 'vehicle'],
        label: 'Bus',
        aliases: {
            unicodes: {
                composite: ['1f68d'],
                secondary: ['10f207'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f207',
    },
    {
        name: 'bus-school',
        categories: ['education'],
        terms: ['education', 'school', 'transportation', 'vehicle'],
        label: 'Bus School',
        aliases: {
            unicodes: {
                secondary: ['10f5dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5dd',
    },
    {
        name: 'bus-simple',
        categories: ['automotive', 'logistics', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Bus simple',
        aliases: {
            names: ['bus-alt'],
            unicodes: {
                secondary: ['10f55e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55e',
    },
    {
        name: 'business-time',
        categories: ['business'],
        terms: ['alarm', 'briefcase', 'business socks', 'clock', 'flight of the conchords', 'reminder', 'wednesday'],
        label: 'Briefcase clock',
        aliases: {
            names: ['briefcase-clock'],
            unicodes: {
                secondary: ['10f64a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64a',
    },
    {
        name: 'butter',
        categories: ['food-beverage'],
        terms: [],
        label: 'Butter',
        aliases: {
            unicodes: {
                composite: ['1f9c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e4',
    },
    {
        name: 'c',
        categories: ['alphabet'],
        terms: [],
        label: 'C',
        aliases: {
            unicodes: {
                composite: ['63'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '43',
    },
    {
        name: 'cabin',
        categories: ['buildings', 'camping'],
        terms: [],
        label: 'Cabin',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46d',
    },
    {
        name: 'cabinet-filing',
        categories: ['business'],
        terms: ['archive', 'files', 'records', 'register', 'storage'],
        label: 'Filing Cabinet',
        aliases: {
            unicodes: {
                composite: ['1f5c4'],
                secondary: ['10f64b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64b',
    },
    {
        name: 'cable-car',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Cable Car',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0cf',
    },
    {
        name: 'cactus',
        categories: ['travel-hotel'],
        terms: ['cowboy', 'desert', 'flora', 'old west', 'plant', 'succulent', 'western'],
        label: 'Cactus',
        aliases: {
            unicodes: {
                composite: ['1f335'],
                secondary: ['10f8a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a7',
    },
    {
        name: 'cake-candles',
        categories: ['business', 'childhood', 'food-beverage', 'maps', 'social'],
        terms: [],
        label: 'Cake candles',
        aliases: {
            names: ['birthday-cake', 'cake'],
            unicodes: {
                composite: ['1f382'],
                secondary: ['10f1fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1fd',
    },
    {
        name: 'cake-slice',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Cake Slice',
        aliases: {
            names: ['shortcake'],
            unicodes: {
                composite: ['1f370'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e5',
    },
    {
        name: 'calculator',
        categories: ['business', 'mathematics'],
        terms: ['abacus', 'addition', 'arithmetic', 'counting', 'math', 'multiplication', 'subtraction'],
        label: 'Calculator',
        aliases: {
            unicodes: {
                composite: ['1f5a9'],
                secondary: ['10f1ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ec',
    },
    {
        name: 'calculator-simple',
        categories: ['mathematics'],
        terms: [],
        label: 'Calculator simple',
        aliases: {
            names: ['calculator-alt'],
            unicodes: {
                secondary: ['10f64c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64c',
    },
    {
        name: 'calendar',
        categories: ['business', 'shapes', 'time'],
        terms: ['calendar-o', 'date', 'event', 'schedule', 'time', 'when'],
        label: 'Calendar',
        aliases: {
            unicodes: {
                composite: ['1f4c6', '1f4c5'],
                secondary: ['10f133'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f133',
    },
    {
        name: 'calendar-arrow-down',
        categories: ['time'],
        terms: [],
        label: 'Calendar arrow down',
        aliases: {
            names: ['calendar-download'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d0',
    },
    {
        name: 'calendar-arrow-up',
        categories: ['time'],
        terms: [],
        label: 'Calendar arrow up',
        aliases: {
            names: ['calendar-upload'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d1',
    },
    {
        name: 'calendar-check',
        categories: ['time'],
        terms: [
            'accept',
            'agree',
            'appointment',
            'confirm',
            'correct',
            'date',
            'done',
            'event',
            'ok',
            'schedule',
            'select',
            'success',
            'tick',
            'time',
            'todo',
            'when',
        ],
        label: 'Calendar Check',
        aliases: {
            unicodes: {
                secondary: ['10f274'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f274',
    },
    {
        name: 'calendar-circle-exclamation',
        categories: ['time'],
        terms: [],
        label: 'Calendar Circle Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46e',
    },
    {
        name: 'calendar-circle-minus',
        categories: ['time'],
        terms: [],
        label: 'Calendar Circle Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e46f',
    },
    {
        name: 'calendar-circle-plus',
        categories: ['time'],
        terms: [],
        label: 'Calendar Circle Plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e470',
    },
    {
        name: 'calendar-circle-user',
        categories: ['time'],
        terms: [],
        label: 'Calendar Circle User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e471',
    },
    {
        name: 'calendar-clock',
        categories: ['time'],
        terms: [],
        label: 'Calendar clock',
        aliases: {
            names: ['calendar-time'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d2',
    },
    {
        name: 'calendar-day',
        categories: ['time'],
        terms: ['date', 'detail', 'event', 'focus', 'schedule', 'single day', 'time', 'today', 'when'],
        label: 'Calendar with Day Focus',
        aliases: {
            unicodes: {
                secondary: ['10f783'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f783',
    },
    {
        name: 'calendar-days',
        categories: ['business', 'time'],
        terms: [],
        label: 'Calendar Days',
        aliases: {
            names: ['calendar-alt'],
            unicodes: {
                secondary: ['10f073'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f073',
    },
    {
        name: 'calendar-exclamation',
        categories: ['alert', 'time'],
        terms: ['calendar', 'date', 'event', 'important', 'schedule', 'time', 'when'],
        label: 'Calendar Exclamation',
        aliases: {
            unicodes: {
                secondary: ['10f334'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f334',
    },
    {
        name: 'calendar-heart',
        categories: ['holidays', 'time'],
        terms: [],
        label: 'Calendar Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d3',
    },
    {
        name: 'calendar-image',
        categories: ['time'],
        terms: [],
        label: 'Calendar Image',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d4',
    },
    {
        name: 'calendar-lines',
        categories: ['time'],
        terms: [],
        label: 'Calendar lines',
        aliases: {
            names: ['calendar-note'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d5',
    },
    {
        name: 'calendar-lines-pen',
        categories: ['time'],
        terms: [],
        label: 'Calendar Lines Pen',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e472',
    },
    {
        name: 'calendar-minus',
        categories: ['time'],
        terms: ['calendar', 'date', 'delete', 'event', 'negative', 'remove', 'schedule', 'time', 'when'],
        label: 'Calendar Minus',
        aliases: {
            unicodes: {
                secondary: ['10f272'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f272',
    },
    {
        name: 'calendar-pen',
        categories: ['time'],
        terms: [],
        label: 'Calendar pen',
        aliases: {
            names: ['calendar-edit'],
            unicodes: {
                secondary: ['10f333'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f333',
    },
    {
        name: 'calendar-plus',
        categories: ['time'],
        terms: ['add', 'calendar', 'create', 'date', 'event', 'new', 'positive', 'schedule', 'time', 'when'],
        label: 'Calendar Plus',
        aliases: {
            unicodes: {
                secondary: ['10f271'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f271',
    },
    {
        name: 'calendar-range',
        categories: ['time'],
        terms: [],
        label: 'Calendar Range',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d6',
    },
    {
        name: 'calendar-star',
        categories: ['political'],
        terms: ['calendar', 'date', 'event', 'favorite', 'important', 'schedule', 'time', 'when'],
        label: 'Calendar Star',
        aliases: {
            unicodes: {
                secondary: ['10f736'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f736',
    },
    {
        name: 'calendar-week',
        categories: ['time'],
        terms: ['date', 'detail', 'event', 'focus', 'schedule', 'single week', 'time', 'today', 'when'],
        label: 'Calendar with Week Focus',
        aliases: {
            unicodes: {
                secondary: ['10f784'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f784',
    },
    {
        name: 'calendar-xmark',
        categories: ['time'],
        terms: [],
        label: 'Calendar X Mark',
        aliases: {
            names: ['calendar-times'],
            unicodes: {
                secondary: ['10f273'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f273',
    },
    {
        name: 'calendars',
        categories: ['time'],
        terms: [],
        label: 'Calendars',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d7',
    },
    {
        name: 'camcorder',
        categories: ['film-video'],
        terms: ['cinema', 'director', 'film', 'handheld', 'movie', 'retro', 'theater', 'video', 'vintage'],
        label: 'Camcorder',
        aliases: {
            names: ['video-handheld'],
            unicodes: {
                composite: ['1f4f9'],
                secondary: ['10f8a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a8',
    },
    {
        name: 'camera',
        categories: ['devices-hardware', 'photos-images', 'shopping', 'social'],
        terms: ['image', 'lens', 'photo', 'picture', 'record', 'shutter', 'video'],
        label: 'camera',
        aliases: {
            names: ['camera-alt'],
            unicodes: {
                composite: ['f332'],
                primary: ['f332'],
                secondary: ['10f332', '10f030'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f030',
    },
    {
        name: 'camera-cctv',
        categories: ['film-video', 'household', 'maps', 'security'],
        terms: [],
        label: 'Camera cctv',
        aliases: {
            names: ['cctv'],
            unicodes: {
                secondary: ['10f8ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ac',
    },
    {
        name: 'camera-movie',
        categories: ['film-video'],
        terms: ['cinema', 'director', 'film', 'movie', 'theater', 'video'],
        label: 'Movie Camera',
        aliases: {
            unicodes: {
                composite: ['1f3a6', '1f3a5'],
                secondary: ['10f8a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a9',
    },
    {
        name: 'camera-polaroid',
        categories: ['design', 'film-video', 'photos-images'],
        terms: ['capture', 'film', 'instant camera', 'lens', 'photo', 'photography', 'retro', 'snapshot', 'vintage'],
        label: 'Polaroid Camera',
        aliases: {
            unicodes: {
                secondary: ['10f8aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8aa',
    },
    {
        name: 'camera-retro',
        categories: ['devices-hardware', 'photos-images', 'shopping'],
        terms: ['image', 'lens', 'photo', 'picture', 'record', 'shutter', 'video'],
        label: 'Retro Camera',
        aliases: {
            unicodes: {
                composite: ['1f4f7'],
                secondary: ['10f083'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f083',
    },
    {
        name: 'camera-rotate',
        categories: ['photos-images'],
        terms: [],
        label: 'Camera Rotate',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d8',
    },
    {
        name: 'camera-security',
        categories: ['film-video', 'household'],
        terms: [],
        label: 'Camera security',
        aliases: {
            names: ['camera-home'],
            unicodes: {
                secondary: ['10f8fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8fe',
    },
    {
        name: 'camera-slash',
        categories: ['photos-images'],
        terms: [],
        label: 'Camera Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0d9',
    },
    {
        name: 'camera-viewfinder',
        categories: ['photos-images'],
        terms: [],
        label: 'Camera Viewfinder',
        aliases: {
            names: ['screenshot'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0da',
    },
    {
        name: 'camera-web',
        categories: ['devices-hardware', 'film-video', 'household'],
        terms: [],
        label: 'Camera web',
        aliases: {
            names: ['webcam'],
            unicodes: {
                secondary: ['10f832'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f832',
    },
    {
        name: 'camera-web-slash',
        categories: ['devices-hardware', 'film-video'],
        terms: [],
        label: 'Camera web slash',
        aliases: {
            names: ['webcam-slash'],
            unicodes: {
                secondary: ['10f833'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f833',
    },
    {
        name: 'campfire',
        categories: ['camping', 'gaming'],
        terms: [
            'Dungeons & Dragons',
            'caliente',
            'campaign',
            'camping',
            'd&d',
            'dnd',
            'fall',
            'fire',
            'flame',
            'gathering',
            'heat',
            'hot',
            'meeting',
            'outdoors',
            'seasonal',
            'tent',
            'wilderness',
        ],
        label: 'Campfire',
        aliases: {
            unicodes: {
                secondary: ['10f6ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ba',
    },
    {
        name: 'campground',
        categories: ['buildings', 'camping'],
        terms: ['camping', 'fall', 'outdoors', 'teepee', 'tent', 'tipi'],
        label: 'Campground',
        aliases: {
            unicodes: {
                composite: ['26fa'],
                secondary: ['10f6bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6bb',
    },
    {
        name: 'can-food',
        categories: ['food-beverage'],
        terms: [],
        label: 'Can Food',
        aliases: {
            unicodes: {
                composite: ['1f96b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e6',
    },
    {
        name: 'candle-holder',
        categories: ['halloween'],
        terms: ['fire', 'flame', 'halloween', 'holiday', 'hot', 'light', 'lit', 'wick'],
        label: 'Candle Holder',
        aliases: {
            unicodes: {
                composite: ['1f56f'],
                secondary: ['10f6bc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6bc',
    },
    {
        name: 'candy',
        categories: ['childhood', 'food-beverage', 'halloween'],
        terms: [],
        label: 'Candy',
        aliases: {
            unicodes: {
                composite: ['1f36c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e7',
    },
    {
        name: 'candy-bar',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Candy Bar',
        aliases: {
            names: ['chocolate-bar'],
            unicodes: {
                composite: ['1f36b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e8',
    },
    {
        name: 'candy-cane',
        categories: ['food-beverage', 'holidays'],
        terms: ['candy', 'christmas', 'holiday', 'mint', 'peppermint', 'striped', 'xmas'],
        label: 'Candy Cane',
        aliases: {
            unicodes: {
                secondary: ['10f786'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f786',
    },
    {
        name: 'candy-corn',
        categories: ['food-beverage', 'halloween'],
        terms: ['halloween', 'holiday', 'sugar', 'triangles'],
        label: 'Candy Corn',
        aliases: {
            unicodes: {
                secondary: ['10f6bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6bd',
    },
    {
        name: 'cannabis',
        categories: ['medical-health', 'nature'],
        terms: [
            'bud',
            'chronic',
            'drugs',
            'endica',
            'endo',
            'ganja',
            'marijuana',
            'mary jane',
            'pot',
            'reefer',
            'sativa',
            'spliff',
            'weed',
            'whacky-tabacky',
        ],
        label: 'Cannabis',
        aliases: {
            unicodes: {
                secondary: ['10f55f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55f',
    },
    {
        name: 'capsules',
        categories: ['medical-health', 'science'],
        terms: ['drugs', 'medicine', 'pills', 'prescription'],
        label: 'Capsules',
        aliases: {
            unicodes: {
                secondary: ['10f46b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46b',
    },
    {
        name: 'car',
        categories: ['automotive', 'humanitarian', 'logistics', 'maps', 'transportation', 'travel-hotel'],
        terms: ['auto', 'automobile', 'sedan', 'transportation', 'travel', 'vehicle'],
        label: 'Car',
        aliases: {
            names: ['automobile'],
            unicodes: {
                composite: ['1f698'],
                secondary: ['10f1b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1b9',
    },
    {
        name: 'car-battery',
        categories: ['automotive', 'devices-hardware', 'energy'],
        terms: ['auto', 'electric', 'mechanic', 'power'],
        label: 'Car Battery',
        aliases: {
            names: ['battery-car'],
            unicodes: {
                secondary: ['10f5df'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5df',
    },
    {
        name: 'car-bolt',
        categories: ['automotive', 'energy', 'transportation'],
        terms: [],
        label: 'Car Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e341',
    },
    {
        name: 'car-building',
        categories: ['automotive', 'buildings', 'transportation', 'travel-hotel'],
        terms: ['city', 'office', 'transportation', 'travel', 'work'],
        label: 'Car and Building',
        aliases: {
            unicodes: {
                secondary: ['10f859'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f859',
    },
    {
        name: 'car-bump',
        categories: ['automotive', 'transportation'],
        terms: ['auto', 'automobile', 'off-road', 'sedan', 'transportation', 'vehicle'],
        label: 'Car Bump',
        aliases: {
            unicodes: {
                secondary: ['10f5e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e0',
    },
    {
        name: 'car-burst',
        categories: ['automotive', 'transportation'],
        terms: [],
        label: 'Car Crash',
        aliases: {
            names: ['car-crash'],
            unicodes: {
                secondary: ['10f5e1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e1',
    },
    {
        name: 'car-bus',
        categories: ['automotive', 'transportation', 'travel-hotel'],
        terms: ['directions', 'ground transportation', 'map', 'transportation', 'travel'],
        label: 'Car and Bus',
        aliases: {
            unicodes: {
                secondary: ['10f85a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85a',
    },
    {
        name: 'car-circle-bolt',
        categories: ['automotive', 'energy', 'transportation'],
        terms: [],
        label: 'Car Circle Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e342',
    },
    {
        name: 'car-garage',
        categories: ['automotive', 'travel-hotel'],
        terms: ['auto', 'automobile', 'mechanic', 'sedan', 'transportation', 'vehicle'],
        label: 'Car Garage',
        aliases: {
            unicodes: {
                secondary: ['10f5e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e2',
    },
    {
        name: 'car-mirrors',
        categories: ['automotive'],
        terms: [],
        label: 'Car Mirrors',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e343',
    },
    {
        name: 'car-on',
        categories: ['automotive', 'humanitarian', 'security'],
        terms: [],
        label: 'Car On',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4dd',
    },
    {
        name: 'car-rear',
        categories: ['automotive', 'transportation'],
        terms: [],
        label: 'Car rear',
        aliases: {
            names: ['car-alt'],
            unicodes: {
                secondary: ['10f5de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5de',
    },
    {
        name: 'car-side',
        categories: ['automotive', 'transportation'],
        terms: ['auto', 'automobile', 'sedan', 'transportation', 'travel', 'vehicle'],
        label: 'Car Side',
        aliases: {
            unicodes: {
                composite: ['1f697'],
                secondary: ['10f5e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e4',
    },
    {
        name: 'car-side-bolt',
        categories: ['automotive', 'energy', 'transportation'],
        terms: [],
        label: 'Car Side Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e344',
    },
    {
        name: 'car-tilt',
        categories: ['automotive', 'transportation'],
        terms: ['accident', 'auto', 'automobile', 'insurance', 'sedan', 'transportation', 'vehicle', 'wreck'],
        label: 'Car Tilt',
        aliases: {
            unicodes: {
                secondary: ['10f5e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e5',
    },
    {
        name: 'car-tunnel',
        categories: ['automotive', 'humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Car Tunnel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4de',
    },
    {
        name: 'car-wash',
        categories: ['automotive'],
        terms: ['auto', 'automobile', 'clean', 'sedan', 'transportation', 'vehicle'],
        label: 'Car Wash',
        aliases: {
            unicodes: {
                secondary: ['10f5e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e6',
    },
    {
        name: 'car-wrench',
        categories: ['automotive'],
        terms: [],
        label: 'Car wrench',
        aliases: {
            names: ['car-mechanic'],
            unicodes: {
                secondary: ['10f5e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e3',
    },
    {
        name: 'caravan',
        categories: ['automotive', 'camping', 'moving', 'travel-hotel'],
        terms: ['camper', 'motor home', 'rv', 'trailer', 'travel'],
        label: 'Caravan',
        aliases: {
            unicodes: {
                secondary: ['10f8ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ff',
    },
    {
        name: 'caravan-simple',
        categories: ['automotive', 'camping', 'moving', 'travel-hotel'],
        terms: [],
        label: 'Caravan simple',
        aliases: {
            names: ['caravan-alt'],
            unicodes: {
                secondary: ['10e000'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e000',
    },
    {
        name: 'card-club',
        categories: ['gaming'],
        terms: [],
        label: 'Card Club',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3e9',
    },
    {
        name: 'card-diamond',
        categories: ['gaming'],
        terms: [],
        label: 'Card Diamond',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ea',
    },
    {
        name: 'card-heart',
        categories: ['gaming'],
        terms: [],
        label: 'Card Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3eb',
    },
    {
        name: 'card-spade',
        categories: ['gaming'],
        terms: [],
        label: 'Card Spade',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ec',
    },
    {
        name: 'cards',
        categories: ['gaming'],
        terms: [],
        label: 'Cards',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ed',
    },
    {
        name: 'cards-blank',
        categories: ['gaming'],
        terms: [],
        label: 'Cards Blank',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4df',
    },
    {
        name: 'caret-down',
        categories: ['arrows'],
        terms: ['arrow', 'dropdown', 'expand', 'menu', 'more', 'triangle'],
        label: 'Caret Down',
        aliases: {
            unicodes: {
                secondary: ['10f0d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d7',
    },
    {
        name: 'caret-left',
        categories: ['arrows'],
        terms: ['arrow', 'back', 'previous', 'triangle'],
        label: 'Caret Left',
        aliases: {
            unicodes: {
                secondary: ['10f0d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d9',
    },
    {
        name: 'caret-right',
        categories: ['arrows'],
        terms: ['arrow', 'forward', 'next', 'triangle'],
        label: 'Caret Right',
        aliases: {
            unicodes: {
                secondary: ['10f0da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0da',
    },
    {
        name: 'caret-up',
        categories: ['arrows'],
        terms: ['arrow', 'collapse', 'triangle'],
        label: 'Caret Up',
        aliases: {
            unicodes: {
                secondary: ['10f0d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d8',
    },
    {
        name: 'carrot',
        categories: ['food-beverage', 'fruits-vegetables', 'holidays'],
        terms: ['bugs bunny', 'orange', 'vegan', 'vegetable'],
        label: 'Carrot',
        aliases: {
            unicodes: {
                composite: ['1f955'],
                secondary: ['10f787'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f787',
    },
    {
        name: 'cars',
        categories: ['automotive', 'transportation', 'travel-hotel'],
        terms: ['automobiles', 'autos', 'transportation', 'travel'],
        label: 'Cars',
        aliases: {
            unicodes: {
                secondary: ['10f85b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85b',
    },
    {
        name: 'cart-arrow-down',
        categories: ['shopping'],
        terms: ['download', 'save', 'shopping'],
        label: 'Shopping Cart Arrow Down',
        aliases: {
            unicodes: {
                secondary: ['10f218'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f218',
    },
    {
        name: 'cart-arrow-up',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Arrow-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ee',
    },
    {
        name: 'cart-circle-arrow-down',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-arrow-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ef',
    },
    {
        name: 'cart-circle-arrow-up',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-arrow-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f0',
    },
    {
        name: 'cart-circle-check',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f1',
    },
    {
        name: 'cart-circle-exclamation',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f2',
    },
    {
        name: 'cart-circle-plus',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f3',
    },
    {
        name: 'cart-circle-xmark',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f4',
    },
    {
        name: 'cart-flatbed',
        categories: ['logistics'],
        terms: [],
        label: 'Cart flatbed',
        aliases: {
            names: ['dolly-flatbed'],
            unicodes: {
                secondary: ['10f474'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f474',
    },
    {
        name: 'cart-flatbed-boxes',
        categories: ['logistics'],
        terms: [],
        label: 'Cart flatbed boxes',
        aliases: {
            names: ['dolly-flatbed-alt'],
            unicodes: {
                secondary: ['10f475'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f475',
    },
    {
        name: 'cart-flatbed-empty',
        categories: ['logistics'],
        terms: [],
        label: 'Cart flatbed empty',
        aliases: {
            names: ['dolly-flatbed-empty'],
            unicodes: {
                secondary: ['10f476'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f476',
    },
    {
        name: 'cart-flatbed-suitcase',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Cart flatbed suitcase',
        aliases: {
            names: ['luggage-cart'],
            unicodes: {
                secondary: ['10f59d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f59d',
    },
    {
        name: 'cart-minus',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0db',
    },
    {
        name: 'cart-plus',
        categories: ['shopping'],
        terms: ['add', 'create', 'new', 'positive', 'shopping'],
        label: 'Add to Shopping Cart',
        aliases: {
            unicodes: {
                secondary: ['10f217'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f217',
    },
    {
        name: 'cart-shopping',
        categories: ['maps', 'shopping', 'transportation'],
        terms: [],
        label: 'Cart shopping',
        aliases: {
            names: ['shopping-cart'],
            unicodes: {
                composite: ['1f6d2'],
                secondary: ['10f07a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f07a',
    },
    {
        name: 'cart-shopping-fast',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Shopping-fast',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0dc',
    },
    {
        name: 'cart-xmark',
        categories: ['shopping'],
        terms: [],
        label: 'Cart Xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0dd',
    },
    {
        name: 'cash-register',
        categories: ['money', 'shopping'],
        terms: [
            'buy',
            'cha-ching',
            'change',
            'checkout',
            'commerce',
            'leaerboard',
            'machine',
            'pay',
            'payment',
            'purchase',
            'store',
        ],
        label: 'Cash Register',
        aliases: {
            unicodes: {
                secondary: ['10f788'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f788',
    },
    {
        name: 'cassette-betamax',
        categories: ['film-video'],
        terms: [],
        label: 'Betamax Cassette',
        aliases: {
            names: ['betamax'],
            unicodes: {
                secondary: ['10f8a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8a4',
    },
    {
        name: 'cassette-tape',
        categories: ['music-audio'],
        terms: ['mixtape', 'music', 'play', 'recording', 'walkman'],
        label: 'Cassette Tape',
        aliases: {
            unicodes: {
                composite: ['1f5ad'],
                secondary: ['10f8ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ab',
    },
    {
        name: 'cassette-vhs',
        categories: ['film-video'],
        terms: [],
        label: 'VHS Cassette',
        aliases: {
            names: ['vhs'],
            unicodes: {
                composite: ['1f4fc'],
                secondary: ['10f8ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ec',
    },
    {
        name: 'castle',
        categories: ['buildings'],
        terms: [],
        label: 'Castle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0de',
    },
    {
        name: 'cat',
        categories: ['animals', 'halloween'],
        terms: ['feline', 'halloween', 'holiday', 'kitten', 'kitty', 'meow', 'pet'],
        label: 'Cat',
        aliases: {
            unicodes: {
                composite: ['1f408'],
                secondary: ['10f6be'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6be',
    },
    {
        name: 'cat-space',
        categories: ['animals', 'astronomy', 'science-fiction'],
        terms: ['animal', 'astronaut', 'cosmonaut', 'fauna', 'meow', 'space'],
        label: 'Space Cat',
        aliases: {
            unicodes: {
                secondary: ['10e001'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e001',
    },
    {
        name: 'cauldron',
        categories: ['camping', 'halloween', 'medical-health', 'science'],
        terms: [
            'boil',
            'bubble',
            'cooking',
            'halloween',
            'holiday',
            'magic',
            'pot',
            'sorcery',
            'toil',
            'trouble',
            'witch',
            'wizard',
        ],
        label: 'Cauldron',
        aliases: {
            unicodes: {
                secondary: ['10f6bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6bf',
    },
    {
        name: 'cc-amazon-pay',
        categories: ['shopping'],
        terms: [],
        label: 'Amazon Pay Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f42d',
    },
    {
        name: 'cc-amex',
        categories: ['shopping'],
        terms: [],
        label: 'American Express Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f3',
    },
    {
        name: 'cc-apple-pay',
        categories: ['shopping'],
        terms: [],
        label: 'Apple Pay Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f416',
    },
    {
        name: 'cc-diners-club',
        categories: ['shopping'],
        terms: [],
        label: "Diner's Club Credit Card",
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f24c',
    },
    {
        name: 'cc-discover',
        categories: ['shopping'],
        terms: [],
        label: 'Discover Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f2',
    },
    {
        name: 'cc-jcb',
        categories: ['shopping'],
        terms: [],
        label: 'JCB Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f24b',
    },
    {
        name: 'cc-mastercard',
        categories: ['shopping'],
        terms: [],
        label: 'MasterCard Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f1',
    },
    {
        name: 'cc-paypal',
        categories: ['shopping'],
        terms: [],
        label: 'Paypal Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f4',
    },
    {
        name: 'cc-stripe',
        categories: ['shopping'],
        terms: [],
        label: 'Stripe Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f5',
    },
    {
        name: 'cc-visa',
        categories: ['shopping'],
        terms: [],
        label: 'Visa Credit Card',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1f0',
    },
    {
        name: 'cedi-sign',
        categories: ['money'],
        terms: [],
        label: 'Cedi Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0df',
    },
    {
        name: 'cent-sign',
        categories: ['money'],
        terms: [],
        label: 'Cent Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f5',
    },
    {
        name: 'certificate',
        categories: ['business', 'shapes', 'shopping', 'spinners'],
        terms: ['badge', 'star', 'verified'],
        label: 'certificate',
        aliases: {
            unicodes: {
                secondary: ['10f0a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a3',
    },
    {
        name: 'chair',
        categories: ['household'],
        terms: ['furniture', 'seat', 'sit'],
        label: 'Chair',
        aliases: {
            unicodes: {
                composite: ['1fa91'],
                secondary: ['10f6c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c0',
    },
    {
        name: 'chair-office',
        categories: ['household'],
        terms: ['ergonomic', 'furniture', 'seat'],
        label: 'Office Chair',
        aliases: {
            unicodes: {
                secondary: ['10f6c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c1',
    },
    {
        name: 'chalkboard',
        categories: ['education', 'photos-images'],
        terms: ['blackboard', 'learning', 'school', 'teaching', 'whiteboard', 'writing'],
        label: 'Chalkboard',
        aliases: {
            names: ['blackboard'],
            unicodes: {
                secondary: ['10f51b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51b',
    },
    {
        name: 'chalkboard-user',
        categories: ['education', 'users-people'],
        terms: [],
        label: 'Chalkboard user',
        aliases: {
            names: ['chalkboard-teacher'],
            unicodes: {
                secondary: ['10f51c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51c',
    },
    {
        name: 'champagne-glass',
        categories: ['food-beverage', 'holidays'],
        terms: [],
        label: 'Champagne glass',
        aliases: {
            names: ['glass-champagne'],
            unicodes: {
                secondary: ['10f79e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79e',
    },
    {
        name: 'champagne-glasses',
        categories: ['food-beverage', 'holidays'],
        terms: [],
        label: 'Champagne glasses',
        aliases: {
            names: ['glass-cheers'],
            unicodes: {
                composite: ['1f942'],
                secondary: ['10f79f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79f',
    },
    {
        name: 'charging-station',
        categories: ['automotive', 'energy'],
        terms: ['electric', 'ev', 'tesla', 'vehicle'],
        label: 'Charging Station',
        aliases: {
            unicodes: {
                secondary: ['10f5e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e7',
    },
    {
        name: 'chart-area',
        categories: ['charts-diagrams'],
        terms: ['analytics', 'area', 'chart', 'graph'],
        label: 'Area Chart',
        aliases: {
            names: ['area-chart'],
            unicodes: {
                secondary: ['10f1fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1fe',
    },
    {
        name: 'chart-bar',
        categories: ['charts-diagrams'],
        terms: ['analytics', 'bar', 'chart', 'graph'],
        label: 'Bar Chart',
        aliases: {
            names: ['bar-chart'],
            unicodes: {
                secondary: ['10f080'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f080',
    },
    {
        name: 'chart-bullet',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Bullet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e1',
    },
    {
        name: 'chart-candlestick',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Candlestick',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e2',
    },
    {
        name: 'chart-column',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Column',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e3',
    },
    {
        name: 'chart-gantt',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Gantt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e4',
    },
    {
        name: 'chart-line',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: ['activity', 'analytics', 'chart', 'dashboard', 'gain', 'graph', 'increase', 'line'],
        label: 'Line Chart',
        aliases: {
            names: ['line-chart'],
            unicodes: {
                secondary: ['10f201'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f201',
    },
    {
        name: 'chart-line-down',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: ['analytics', 'chart', 'dashboard', 'decline', 'graph', 'line', 'loss'],
        label: 'Line Chart in Down Direction',
        aliases: {
            unicodes: {
                composite: ['1f4c9'],
                secondary: ['10f64d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64d',
    },
    {
        name: 'chart-line-up',
        categories: ['business', 'charts-diagrams'],
        terms: [],
        label: 'Chart Line-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e5',
    },
    {
        name: 'chart-mixed',
        categories: ['charts-diagrams', 'marketing', 'money', 'science'],
        terms: [],
        label: 'Chart mixed',
        aliases: {
            names: ['analytics'],
            unicodes: {
                secondary: ['10f643'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f643',
    },
    {
        name: 'chart-network',
        categories: ['charts-diagrams', 'science'],
        terms: ['activity', 'analytics', 'association', 'dashboard', 'diagram', 'distribution', 'map', 'network'],
        label: 'Network Chart',
        aliases: {
            unicodes: {
                secondary: ['10f78a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f78a',
    },
    {
        name: 'chart-pie',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: ['analytics', 'chart', 'diagram', 'graph', 'pie'],
        label: 'Pie Chart',
        aliases: {
            names: ['pie-chart'],
            unicodes: {
                secondary: ['10f200'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f200',
    },
    {
        name: 'chart-pie-simple',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: [],
        label: 'Chart pie simple',
        aliases: {
            names: ['chart-pie-alt'],
            unicodes: {
                secondary: ['10f64e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64e',
    },
    {
        name: 'chart-pyramid',
        categories: ['business', 'charts-diagrams'],
        terms: [],
        label: 'Chart Pyramid',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e6',
    },
    {
        name: 'chart-radar',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Radar',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e7',
    },
    {
        name: 'chart-scatter',
        categories: ['charts-diagrams', 'science'],
        terms: ['analytics', 'chart', 'diagram', 'graph', 'plot'],
        label: 'Scatter Chart',
        aliases: {
            unicodes: {
                secondary: ['10f7ee'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ee',
    },
    {
        name: 'chart-scatter-3d',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Scatter-3d',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e8',
    },
    {
        name: 'chart-scatter-bubble',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Scatter-bubble',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0e9',
    },
    {
        name: 'chart-simple',
        categories: ['business', 'charts-diagrams', 'editing', 'logistics', 'marketing'],
        terms: [],
        label: 'Chart Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e473',
    },
    {
        name: 'chart-simple-horizontal',
        categories: ['business', 'charts-diagrams', 'logistics', 'marketing'],
        terms: [],
        label: 'Chart Simple Horizontal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e474',
    },
    {
        name: 'chart-tree-map',
        categories: ['business', 'charts-diagrams'],
        terms: [],
        label: 'Chart Tree-map',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ea',
    },
    {
        name: 'chart-user',
        categories: ['business', 'charts-diagrams', 'users-people'],
        terms: [],
        label: 'Chart user',
        aliases: {
            names: ['user-chart'],
            unicodes: {
                secondary: ['10f6a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a3',
    },
    {
        name: 'chart-waterfall',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Chart Waterfall',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0eb',
    },
    {
        name: 'check',
        categories: ['editing', 'punctuation-symbols', 'text-formatting'],
        terms: [
            'accept',
            'agree',
            'checkmark',
            'confirm',
            'correct',
            'done',
            'notice',
            'notification',
            'notify',
            'ok',
            'select',
            'success',
            'tick',
            'todo',
            'yes',
        ],
        label: 'Check',
        aliases: {
            unicodes: {
                composite: ['2714', '2713'],
                secondary: ['10f00c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f00c',
    },
    {
        name: 'check-double',
        categories: ['editing', 'political', 'punctuation-symbols', 'text-formatting'],
        terms: [
            'accept',
            'agree',
            'checkmark',
            'confirm',
            'correct',
            'done',
            'notice',
            'notification',
            'notify',
            'ok',
            'select',
            'success',
            'tick',
            'todo',
        ],
        label: 'Double Check',
        aliases: {
            unicodes: {
                secondary: ['10f560'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f560',
    },
    {
        name: 'check-to-slot',
        categories: ['political'],
        terms: [],
        label: 'Check to Slot',
        aliases: {
            names: ['vote-yea'],
            unicodes: {
                secondary: ['10f772'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f772',
    },
    {
        name: 'cheese',
        categories: ['food-beverage'],
        terms: ['cheddar', 'curd', 'gouda', 'melt', 'parmesan', 'sandwich', 'swiss', 'wedge'],
        label: 'Cheese',
        aliases: {
            unicodes: {
                secondary: ['10f7ef'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ef',
    },
    {
        name: 'cheese-swiss',
        categories: ['food-beverage'],
        terms: ['cheddar', 'curd', 'gouda', 'melt', 'parmesan', 'sandwich', 'swiss', 'wedge'],
        label: 'Swiss Cheese',
        aliases: {
            unicodes: {
                composite: ['1f9c0'],
                secondary: ['10f7f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f0',
    },
    {
        name: 'cherries',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Cherries',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ec',
    },
    {
        name: 'chess',
        categories: ['gaming'],
        terms: ['board', 'castle', 'checkmate', 'game', 'king', 'rook', 'strategy', 'tournament'],
        label: 'Chess',
        aliases: {
            unicodes: {
                secondary: ['10f439'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f439',
    },
    {
        name: 'chess-bishop',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy'],
        label: 'Chess Bishop',
        aliases: {
            unicodes: {
                composite: ['265d'],
                secondary: ['10f43a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43a',
    },
    {
        name: 'chess-bishop-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess bishop piece',
        aliases: {
            names: ['chess-bishop-alt'],
            unicodes: {
                secondary: ['10f43b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43b',
    },
    {
        name: 'chess-board',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy'],
        label: 'Chess Board',
        aliases: {
            unicodes: {
                secondary: ['10f43c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43c',
    },
    {
        name: 'chess-clock',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy', 'timer', 'tournament'],
        label: 'Chess Clock',
        aliases: {
            unicodes: {
                secondary: ['10f43d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43d',
    },
    {
        name: 'chess-clock-flip',
        categories: ['gaming'],
        terms: [],
        label: 'Chess clock flip',
        aliases: {
            names: ['chess-clock-alt'],
            unicodes: {
                secondary: ['10f43e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43e',
    },
    {
        name: 'chess-king',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy'],
        label: 'Chess King',
        aliases: {
            unicodes: {
                composite: ['265a'],
                secondary: ['10f43f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f43f',
    },
    {
        name: 'chess-king-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess king piece',
        aliases: {
            names: ['chess-king-alt'],
            unicodes: {
                secondary: ['10f440'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f440',
    },
    {
        name: 'chess-knight',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'horse', 'strategy'],
        label: 'Chess Knight',
        aliases: {
            unicodes: {
                composite: ['265e'],
                secondary: ['10f441'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f441',
    },
    {
        name: 'chess-knight-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess knight piece',
        aliases: {
            names: ['chess-knight-alt'],
            unicodes: {
                secondary: ['10f442'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f442',
    },
    {
        name: 'chess-pawn',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy'],
        label: 'Chess Pawn',
        aliases: {
            unicodes: {
                composite: ['265f'],
                secondary: ['10f443'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f443',
    },
    {
        name: 'chess-pawn-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess pawn piece',
        aliases: {
            names: ['chess-pawn-alt'],
            unicodes: {
                secondary: ['10f444'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f444',
    },
    {
        name: 'chess-queen',
        categories: ['gaming'],
        terms: ['board', 'checkmate', 'game', 'strategy'],
        label: 'Chess Queen',
        aliases: {
            unicodes: {
                composite: ['265b'],
                secondary: ['10f445'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f445',
    },
    {
        name: 'chess-queen-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess queen piece',
        aliases: {
            names: ['chess-queen-alt'],
            unicodes: {
                secondary: ['10f446'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f446',
    },
    {
        name: 'chess-rook',
        categories: ['gaming'],
        terms: ['board', 'castle', 'checkmate', 'game', 'strategy'],
        label: 'Chess Rook',
        aliases: {
            unicodes: {
                composite: ['265c'],
                secondary: ['10f447'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f447',
    },
    {
        name: 'chess-rook-piece',
        categories: ['gaming'],
        terms: [],
        label: 'Chess rook piece',
        aliases: {
            names: ['chess-rook-alt'],
            unicodes: {
                secondary: ['10f448'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f448',
    },
    {
        name: 'chestnut',
        categories: ['fruits-vegetables', 'holidays', 'nature'],
        terms: [],
        label: 'Chestnut',
        aliases: {
            unicodes: {
                composite: ['1f330'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f6',
    },
    {
        name: 'chevron-down',
        categories: ['arrows'],
        terms: ['arrow', 'download', 'expand'],
        label: 'chevron-down',
        aliases: {
            unicodes: {
                secondary: ['10f078'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f078',
    },
    {
        name: 'chevron-left',
        categories: ['arrows'],
        terms: ['arrow', 'back', 'bracket', 'previous'],
        label: 'chevron-left',
        aliases: {
            unicodes: {
                composite: ['2329'],
                secondary: ['10f053'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f053',
    },
    {
        name: 'chevron-right',
        categories: ['arrows'],
        terms: ['arrow', 'bracket', 'forward', 'next'],
        label: 'chevron-right',
        aliases: {
            unicodes: {
                composite: ['232a'],
                secondary: ['10f054'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f054',
    },
    {
        name: 'chevron-up',
        categories: ['arrows'],
        terms: ['arrow', 'collapse', 'upload'],
        label: 'chevron-up',
        aliases: {
            unicodes: {
                secondary: ['10f077'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f077',
    },
    {
        name: 'chevrons-down',
        categories: ['arrows'],
        terms: [],
        label: 'Chevrons down',
        aliases: {
            names: ['chevron-double-down'],
            unicodes: {
                secondary: ['10f322'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f322',
    },
    {
        name: 'chevrons-left',
        categories: ['arrows'],
        terms: [],
        label: 'Chevrons left',
        aliases: {
            names: ['chevron-double-left'],
            unicodes: {
                secondary: ['10f323'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f323',
    },
    {
        name: 'chevrons-right',
        categories: ['arrows'],
        terms: [],
        label: 'Chevrons right',
        aliases: {
            names: ['chevron-double-right'],
            unicodes: {
                secondary: ['10f324'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f324',
    },
    {
        name: 'chevrons-up',
        categories: ['arrows'],
        terms: [],
        label: 'Chevrons up',
        aliases: {
            names: ['chevron-double-up'],
            unicodes: {
                secondary: ['10f325'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f325',
    },
    {
        name: 'child',
        categories: ['childhood', 'users-people'],
        terms: ['boy', 'girl', 'kid', 'toddler', 'young'],
        label: 'Child',
        aliases: {
            unicodes: {
                secondary: ['10f1ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ae',
    },
    {
        name: 'child-dress',
        categories: ['childhood', 'users-people'],
        terms: [],
        label: 'Child Dress',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e59c',
    },
    {
        name: 'child-reaching',
        categories: ['childhood', 'users-people'],
        terms: [],
        label: 'Child Reaching',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e59d',
    },
    {
        name: 'child-rifle',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'Child Rifle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e0',
    },
    {
        name: 'children',
        categories: ['childhood', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Children',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e1',
    },
    {
        name: 'chimney',
        categories: ['buildings'],
        terms: ['brick', 'exhaust', 'fireplace', 'house', 'roof', 'vent'],
        label: 'Chimney',
        aliases: {
            unicodes: {
                secondary: ['10f78b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f78b',
    },
    {
        name: 'chopsticks',
        categories: ['food-beverage', 'household'],
        terms: [],
        label: 'Chopsticks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f7',
    },
    {
        name: 'church',
        categories: ['buildings', 'humanitarian', 'religion'],
        terms: ['building', 'cathedral', 'chapel', 'community', 'religion'],
        label: 'Church',
        aliases: {
            unicodes: {
                composite: ['26ea'],
                secondary: ['10f51d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51d',
    },
    {
        name: 'circle',
        categories: ['film-video', 'shapes', 'toggle'],
        terms: ['circle-thin', 'diameter', 'dot', 'ellipse', 'notification', 'round'],
        label: 'Circle',
        aliases: {
            unicodes: {
                composite: [
                    '1f534',
                    '1f535',
                    '1f7e0',
                    '1f7e1',
                    '1f7e2',
                    '1f7e3',
                    '1f7e4',
                    '26aa',
                    '26ab',
                    '2b24',
                    'f10c',
                    'f1db',
                    '25cf',
                ],
                secondary: ['10f111'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f111',
    },
    {
        name: 'circle-0',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 0',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ed',
    },
    {
        name: 'circle-1',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 1',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ee',
    },
    {
        name: 'circle-2',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 2',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ef',
    },
    {
        name: 'circle-3',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 3',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f0',
    },
    {
        name: 'circle-4',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f1',
    },
    {
        name: 'circle-5',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f2',
    },
    {
        name: 'circle-6',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 6',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f3',
    },
    {
        name: 'circle-7',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 7',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f4',
    },
    {
        name: 'circle-8',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 8',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f5',
    },
    {
        name: 'circle-9',
        categories: ['numbers'],
        terms: [],
        label: 'Circle 9',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f6',
    },
    {
        name: 'circle-a',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle A',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f7',
    },
    {
        name: 'circle-ampersand',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Circle Ampersand',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f8',
    },
    {
        name: 'circle-arrow-down',
        categories: ['arrows'],
        terms: [],
        label: 'Circle arrow down',
        aliases: {
            names: ['arrow-circle-down'],
            unicodes: {
                secondary: ['10f0ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ab',
    },
    {
        name: 'circle-arrow-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Arrow-down-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0f9',
    },
    {
        name: 'circle-arrow-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Arrow-down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0fa',
    },
    {
        name: 'circle-arrow-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle arrow left',
        aliases: {
            names: ['arrow-circle-left'],
            unicodes: {
                secondary: ['10f0a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a8',
    },
    {
        name: 'circle-arrow-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle arrow right',
        aliases: {
            names: ['arrow-circle-right'],
            unicodes: {
                secondary: ['10f0a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a9',
    },
    {
        name: 'circle-arrow-up',
        categories: ['arrows'],
        terms: [],
        label: 'Circle arrow up',
        aliases: {
            names: ['arrow-circle-up'],
            unicodes: {
                secondary: ['10f0aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0aa',
    },
    {
        name: 'circle-arrow-up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Arrow-up-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0fb',
    },
    {
        name: 'circle-arrow-up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Arrow-up-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0fc',
    },
    {
        name: 'circle-b',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle B',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0fd',
    },
    {
        name: 'circle-bolt',
        categories: ['shapes'],
        terms: [],
        label: 'Circle Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0fe',
    },
    {
        name: 'circle-book-open',
        categories: ['writing'],
        terms: [],
        label: 'Circle book open',
        aliases: {
            names: ['book-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e0ff',
    },
    {
        name: 'circle-bookmark',
        categories: ['writing'],
        terms: [],
        label: 'Circle bookmark',
        aliases: {
            names: ['bookmark-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e100',
    },
    {
        name: 'circle-c',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle C',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e101',
    },
    {
        name: 'circle-calendar',
        categories: ['time'],
        terms: [],
        label: 'Circle calendar',
        aliases: {
            names: ['calendar-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e102',
    },
    {
        name: 'circle-camera',
        categories: ['photos-images'],
        terms: [],
        label: 'Circle camera',
        aliases: {
            names: ['camera-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e103',
    },
    {
        name: 'circle-caret-down',
        categories: ['arrows'],
        terms: [],
        label: 'Circle caret down',
        aliases: {
            names: ['caret-circle-down'],
            unicodes: {
                secondary: ['10f32d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f32d',
    },
    {
        name: 'circle-caret-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle caret left',
        aliases: {
            names: ['caret-circle-left'],
            unicodes: {
                secondary: ['10f32e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f32e',
    },
    {
        name: 'circle-caret-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle caret right',
        aliases: {
            names: ['caret-circle-right'],
            unicodes: {
                secondary: ['10f330'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f330',
    },
    {
        name: 'circle-caret-up',
        categories: ['arrows'],
        terms: [],
        label: 'Circle caret up',
        aliases: {
            names: ['caret-circle-up'],
            unicodes: {
                secondary: ['10f331'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f331',
    },
    {
        name: 'circle-check',
        categories: ['editing', 'text-formatting', 'toggle'],
        terms: [],
        label: 'Circle check',
        aliases: {
            names: ['check-circle'],
            unicodes: {
                composite: ['f05d'],
                secondary: ['10f058'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f058',
    },
    {
        name: 'circle-chevron-down',
        categories: ['arrows'],
        terms: [],
        label: 'Circle chevron down',
        aliases: {
            names: ['chevron-circle-down'],
            unicodes: {
                secondary: ['10f13a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f13a',
    },
    {
        name: 'circle-chevron-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle chevron left',
        aliases: {
            names: ['chevron-circle-left'],
            unicodes: {
                secondary: ['10f137'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f137',
    },
    {
        name: 'circle-chevron-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle chevron right',
        aliases: {
            names: ['chevron-circle-right'],
            unicodes: {
                secondary: ['10f138'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f138',
    },
    {
        name: 'circle-chevron-up',
        categories: ['arrows'],
        terms: [],
        label: 'Circle chevron up',
        aliases: {
            names: ['chevron-circle-up'],
            unicodes: {
                secondary: ['10f139'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f139',
    },
    {
        name: 'circle-d',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle D',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e104',
    },
    {
        name: 'circle-dashed',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Circle Dashed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e105',
    },
    {
        name: 'circle-divide',
        categories: ['mathematics'],
        terms: [],
        label: 'Circle Divide',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e106',
    },
    {
        name: 'circle-dollar',
        categories: ['charity', 'maps', 'money'],
        terms: [],
        label: 'Circle dollar',
        aliases: {
            names: ['dollar-circle', 'usd-circle'],
            unicodes: {
                secondary: ['10f2e8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e8',
    },
    {
        name: 'circle-dollar-to-slot',
        categories: ['charity', 'money', 'political'],
        terms: [],
        label: 'Circle dollar to slot',
        aliases: {
            names: ['donate'],
            unicodes: {
                secondary: ['10f4b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b9',
    },
    {
        name: 'circle-dot',
        categories: ['toggle'],
        terms: [],
        label: 'Circle dot',
        aliases: {
            names: ['dot-circle'],
            unicodes: {
                composite: ['1f518'],
                secondary: ['10f192'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f192',
    },
    {
        name: 'circle-down',
        categories: ['arrows'],
        terms: [],
        label: 'Circle down',
        aliases: {
            names: ['arrow-alt-circle-down'],
            unicodes: {
                composite: ['f01a'],
                secondary: ['10f358'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f358',
    },
    {
        name: 'circle-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Down-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e107',
    },
    {
        name: 'circle-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e108',
    },
    {
        name: 'circle-e',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle E',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e109',
    },
    {
        name: 'circle-ellipsis',
        categories: ['editing'],
        terms: [],
        label: 'Circle Ellipsis',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10a',
    },
    {
        name: 'circle-ellipsis-vertical',
        categories: ['editing'],
        terms: [],
        label: 'Circle Ellipsis-vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10b',
    },
    {
        name: 'circle-envelope',
        categories: ['communication', 'social'],
        terms: [],
        label: 'Circle envelope',
        aliases: {
            names: ['envelope-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10c',
    },
    {
        name: 'circle-exclamation',
        categories: ['alert', 'punctuation-symbols'],
        terms: [],
        label: 'Circle exclamation',
        aliases: {
            names: ['exclamation-circle'],
            unicodes: {
                secondary: ['10f06a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f06a',
    },
    {
        name: 'circle-exclamation-check',
        categories: ['alert'],
        terms: [],
        label: 'Circle Exclamation-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10d',
    },
    {
        name: 'circle-f',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle F',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10e',
    },
    {
        name: 'circle-g',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle G',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e10f',
    },
    {
        name: 'circle-h',
        categories: ['alphabet', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'Circle h',
        aliases: {
            names: ['hospital-symbol'],
            unicodes: {
                composite: ['24bd'],
                secondary: ['10f47e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f47e',
    },
    {
        name: 'circle-half',
        categories: ['shapes'],
        terms: [],
        label: 'Circle Half',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e110',
    },
    {
        name: 'circle-half-stroke',
        categories: ['design', 'editing', 'photos-images'],
        terms: [],
        label: 'Circle half stroke',
        aliases: {
            names: ['adjust'],
            unicodes: {
                composite: ['25d0'],
                secondary: ['10f042'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f042',
    },
    {
        name: 'circle-heart',
        categories: ['charity', 'shapes'],
        terms: [],
        label: 'Circle heart',
        aliases: {
            names: ['heart-circle'],
            unicodes: {
                secondary: ['10f4c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c7',
    },
    {
        name: 'circle-i',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle I',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e111',
    },
    {
        name: 'circle-info',
        categories: ['accessibility', 'maps'],
        terms: [],
        label: 'Circle info',
        aliases: {
            names: ['info-circle'],
            unicodes: {
                secondary: ['10f05a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f05a',
    },
    {
        name: 'circle-j',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle J',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e112',
    },
    {
        name: 'circle-k',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle K',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e113',
    },
    {
        name: 'circle-l',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle L',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e114',
    },
    {
        name: 'circle-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle left',
        aliases: {
            names: ['arrow-alt-circle-left'],
            unicodes: {
                composite: ['f190'],
                secondary: ['10f359'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f359',
    },
    {
        name: 'circle-location-arrow',
        categories: ['maps'],
        terms: [],
        label: 'Circle location arrow',
        aliases: {
            names: ['location-circle'],
            unicodes: {
                secondary: ['10f602'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f602',
    },
    {
        name: 'circle-m',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle M',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e115',
    },
    {
        name: 'circle-microphone',
        categories: ['film-video', 'music-audio'],
        terms: [],
        label: 'Circle microphone',
        aliases: {
            names: ['microphone-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e116',
    },
    {
        name: 'circle-microphone-lines',
        categories: ['film-video', 'music-audio'],
        terms: [],
        label: 'Circle microphone lines',
        aliases: {
            names: ['microphone-circle-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e117',
    },
    {
        name: 'circle-minus',
        categories: ['mathematics'],
        terms: [],
        label: 'Circle minus',
        aliases: {
            names: ['minus-circle'],
            unicodes: {
                secondary: ['10f056'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f056',
    },
    {
        name: 'circle-n',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle N',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e118',
    },
    {
        name: 'circle-nodes',
        categories: ['coding', 'connectivity', 'design', 'humanitarian'],
        terms: [],
        label: 'Circle Nodes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e2',
    },
    {
        name: 'circle-notch',
        categories: ['spinners'],
        terms: ['circle-o-notch', 'diameter', 'dot', 'ellipse', 'round', 'spinner'],
        label: 'Circle Notched',
        aliases: {
            unicodes: {
                secondary: ['10f1ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ce',
    },
    {
        name: 'circle-o',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle O',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e119',
    },
    {
        name: 'circle-p',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle P',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11a',
    },
    {
        name: 'circle-parking',
        categories: ['maps'],
        terms: [],
        label: 'Circle parking',
        aliases: {
            names: ['parking-circle'],
            unicodes: {
                secondary: ['10f615'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f615',
    },
    {
        name: 'circle-pause',
        categories: ['media-playback'],
        terms: [],
        label: 'Circle pause',
        aliases: {
            names: ['pause-circle'],
            unicodes: {
                composite: ['f28c'],
                secondary: ['10f28b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f28b',
    },
    {
        name: 'circle-phone',
        categories: ['communication'],
        terms: [],
        label: 'Circle phone',
        aliases: {
            names: ['phone-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11b',
    },
    {
        name: 'circle-phone-flip',
        categories: ['communication'],
        terms: [],
        label: 'Circle phone flip',
        aliases: {
            names: ['phone-circle-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11c',
    },
    {
        name: 'circle-phone-hangup',
        categories: ['communication'],
        terms: [],
        label: 'Circle phone hangup',
        aliases: {
            names: ['phone-circle-down'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11d',
    },
    {
        name: 'circle-play',
        categories: ['media-playback'],
        terms: [],
        label: 'Circle play',
        aliases: {
            names: ['play-circle'],
            unicodes: {
                composite: ['f01d'],
                secondary: ['10f144'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f144',
    },
    {
        name: 'circle-plus',
        categories: ['mathematics'],
        terms: [],
        label: 'Circle plus',
        aliases: {
            names: ['plus-circle'],
            unicodes: {
                secondary: ['10f055'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f055',
    },
    {
        name: 'circle-q',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle Q',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11e',
    },
    {
        name: 'circle-quarter',
        categories: ['shapes'],
        terms: [],
        label: 'Circle Quarter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e11f',
    },
    {
        name: 'circle-quarters',
        categories: ['alert', 'shapes'],
        terms: [],
        label: 'Circle Quarters',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f8',
    },
    {
        name: 'circle-question',
        categories: ['accessibility', 'punctuation-symbols'],
        terms: [],
        label: 'Circle question',
        aliases: {
            names: ['question-circle'],
            unicodes: {
                composite: ['f29c'],
                secondary: ['10f059'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f059',
    },
    {
        name: 'circle-r',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle R',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e120',
    },
    {
        name: 'circle-radiation',
        categories: ['alert', 'disaster', 'energy', 'medical-health', 'science'],
        terms: [],
        label: 'Circle radiation',
        aliases: {
            names: ['radiation-alt'],
            unicodes: {
                composite: ['2622'],
                secondary: ['10f7ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ba',
    },
    {
        name: 'circle-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle right',
        aliases: {
            names: ['arrow-alt-circle-right'],
            unicodes: {
                composite: ['f18e'],
                secondary: ['10f35a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f35a',
    },
    {
        name: 'circle-s',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle S',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e121',
    },
    {
        name: 'circle-small',
        categories: ['shapes'],
        terms: [],
        label: 'Circle Small',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e122',
    },
    {
        name: 'circle-sort',
        categories: ['household'],
        terms: [],
        label: 'Circle sort',
        aliases: {
            names: ['sort-circle'],
            unicodes: {
                secondary: ['10e030'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e030',
    },
    {
        name: 'circle-sort-down',
        categories: ['household'],
        terms: [],
        label: 'Circle sort down',
        aliases: {
            names: ['sort-circle-down'],
            unicodes: {
                secondary: ['10e031'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e031',
    },
    {
        name: 'circle-sort-up',
        categories: ['household'],
        terms: [],
        label: 'Circle sort up',
        aliases: {
            names: ['sort-circle-up'],
            unicodes: {
                secondary: ['10e032'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e032',
    },
    {
        name: 'circle-star',
        categories: ['shapes'],
        terms: [],
        label: 'Circle star',
        aliases: {
            names: ['star-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e123',
    },
    {
        name: 'circle-stop',
        categories: ['media-playback'],
        terms: [],
        label: 'Circle stop',
        aliases: {
            names: ['stop-circle'],
            unicodes: {
                composite: ['f28e'],
                secondary: ['10f28d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f28d',
    },
    {
        name: 'circle-t',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle T',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e124',
    },
    {
        name: 'circle-three-quarters',
        categories: ['shapes'],
        terms: [],
        label: 'Circle Three-quarters',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e125',
    },
    {
        name: 'circle-trash',
        categories: ['editing'],
        terms: [],
        label: 'Circle trash',
        aliases: {
            names: ['trash-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e126',
    },
    {
        name: 'circle-u',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle U',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e127',
    },
    {
        name: 'circle-up',
        categories: ['arrows'],
        terms: [],
        label: 'Circle up',
        aliases: {
            names: ['arrow-alt-circle-up'],
            unicodes: {
                composite: ['f01b'],
                secondary: ['10f35b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f35b',
    },
    {
        name: 'circle-up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Up-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e128',
    },
    {
        name: 'circle-up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Circle Up-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e129',
    },
    {
        name: 'circle-user',
        categories: ['social', 'users-people'],
        terms: [],
        label: 'Circle user',
        aliases: {
            names: ['user-circle'],
            unicodes: {
                composite: ['f2be'],
                secondary: ['10f2bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2bd',
    },
    {
        name: 'circle-v',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle V',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12a',
    },
    {
        name: 'circle-video',
        categories: ['film-video'],
        terms: [],
        label: 'Circle video',
        aliases: {
            names: ['video-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12b',
    },
    {
        name: 'circle-w',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle W',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12c',
    },
    {
        name: 'circle-waveform-lines',
        categories: ['film-video'],
        terms: [],
        label: 'Circle waveform lines',
        aliases: {
            names: ['waveform-circle'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12d',
    },
    {
        name: 'circle-x',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle X',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12e',
    },
    {
        name: 'circle-xmark',
        categories: ['mathematics'],
        terms: [],
        label: 'Circle X Mark',
        aliases: {
            names: ['times-circle', 'xmark-circle'],
            unicodes: {
                composite: ['f05c'],
                secondary: ['10f057'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f057',
    },
    {
        name: 'circle-y',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle Y',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e12f',
    },
    {
        name: 'circle-z',
        categories: ['alphabet'],
        terms: [],
        label: 'Circle Z',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e130',
    },
    {
        name: 'citrus',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Citrus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2f4',
    },
    {
        name: 'citrus-slice',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Citrus Slice',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2f5',
    },
    {
        name: 'city',
        categories: ['buildings', 'business'],
        terms: ['buildings', 'busy', 'skyscrapers', 'urban', 'windows'],
        label: 'City',
        aliases: {
            unicodes: {
                composite: ['1f3d9'],
                secondary: ['10f64f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f64f',
    },
    {
        name: 'clapperboard',
        categories: ['film-video'],
        terms: [],
        label: 'Clapperboard',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e131',
    },
    {
        name: 'clapperboard-play',
        categories: ['film-video'],
        terms: [],
        label: 'Clapperboard Play',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e132',
    },
    {
        name: 'clarinet',
        categories: ['music-audio'],
        terms: ['instrument', 'jazz', 'music', 'woodwind'],
        label: 'Clarinet',
        aliases: {
            unicodes: {
                secondary: ['10f8ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ad',
    },
    {
        name: 'claw-marks',
        categories: ['halloween'],
        terms: ['attack', 'damage', 'halloween', 'rip', 'scratch', 'snikt', 'tear', 'torn', 'wolverine'],
        label: 'Claw Marks',
        aliases: {
            unicodes: {
                secondary: ['10f6c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c2',
    },
    {
        name: 'clipboard',
        categories: ['business'],
        terms: ['copy', 'notes', 'paste', 'record'],
        label: 'Clipboard',
        aliases: {
            unicodes: {
                composite: ['1f4cb'],
                secondary: ['10f328'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f328',
    },
    {
        name: 'clipboard-check',
        categories: ['logistics', 'science'],
        terms: ['accept', 'agree', 'confirm', 'done', 'ok', 'select', 'success', 'tick', 'todo', 'yes'],
        label: 'Clipboard with Check',
        aliases: {
            unicodes: {
                secondary: ['10f46c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46c',
    },
    {
        name: 'clipboard-list',
        categories: ['logistics'],
        terms: ['checklist', 'completed', 'done', 'finished', 'intinerary', 'ol', 'schedule', 'tick', 'todo', 'ul'],
        label: 'Clipboard List',
        aliases: {
            unicodes: {
                secondary: ['10f46d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46d',
    },
    {
        name: 'clipboard-list-check',
        categories: ['political'],
        terms: [
            'checklist',
            'completed',
            'done',
            'election',
            'finished',
            'intinerary',
            'ol',
            'schedule',
            'tick',
            'todo',
            'ul',
            'vote',
            'voting',
        ],
        label: 'Clipboard List with Check',
        aliases: {
            unicodes: {
                secondary: ['10f737'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f737',
    },
    {
        name: 'clipboard-medical',
        categories: ['medical-health'],
        terms: [],
        label: 'Clipboard Medical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e133',
    },
    {
        name: 'clipboard-prescription',
        categories: ['medical-health'],
        terms: ['copy', 'history', 'medical', 'notes', 'paste', 'record'],
        label: 'Clipboard Prescription',
        aliases: {
            unicodes: {
                secondary: ['10f5e8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e8',
    },
    {
        name: 'clipboard-question',
        categories: ['business', 'humanitarian', 'logistics'],
        terms: [],
        label: 'Clipboard Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e3',
    },
    {
        name: 'clipboard-user',
        categories: ['humanitarian', 'medical-health', 'users-people'],
        terms: ['attendance', 'record', 'roster', 'staff'],
        label: 'Clipboard with User',
        aliases: {
            unicodes: {
                secondary: ['10f7f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f3',
    },
    {
        name: 'clock',
        categories: ['time'],
        terms: ['date', 'late', 'schedule', 'time', 'timer', 'timestamp', 'watch'],
        label: 'Clock',
        aliases: {
            names: ['clock-four'],
            unicodes: {
                composite: ['1f553'],
                secondary: ['10f017'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f017',
    },
    {
        name: 'clock-desk',
        categories: ['household', 'time'],
        terms: [],
        label: 'Clock Desk',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e134',
    },
    {
        name: 'clock-eight',
        categories: ['time'],
        terms: [],
        label: 'Clock Eight',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e345',
    },
    {
        name: 'clock-eight-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Eight-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e346',
    },
    {
        name: 'clock-eleven',
        categories: ['time'],
        terms: [],
        label: 'Clock Eleven',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e347',
    },
    {
        name: 'clock-eleven-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Eleven-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e348',
    },
    {
        name: 'clock-five',
        categories: ['time'],
        terms: [],
        label: 'Clock Five',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e349',
    },
    {
        name: 'clock-five-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Five-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34a',
    },
    {
        name: 'clock-four-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Four-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34b',
    },
    {
        name: 'clock-nine',
        categories: ['time'],
        terms: [],
        label: 'Clock Nine',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34c',
    },
    {
        name: 'clock-nine-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Nine-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34d',
    },
    {
        name: 'clock-one',
        categories: ['time'],
        terms: [],
        label: 'Clock One',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34e',
    },
    {
        name: 'clock-one-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock One-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e34f',
    },
    {
        name: 'clock-rotate-left',
        categories: ['arrows', 'medical-health'],
        terms: [],
        label: 'Clock Rotate Left',
        aliases: {
            names: ['history'],
            unicodes: {
                secondary: ['10f1da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1da',
    },
    {
        name: 'clock-seven',
        categories: ['time'],
        terms: [],
        label: 'Clock Seven',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e350',
    },
    {
        name: 'clock-seven-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Seven-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e351',
    },
    {
        name: 'clock-six',
        categories: ['time'],
        terms: [],
        label: 'Clock Six',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e352',
    },
    {
        name: 'clock-six-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Six-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e353',
    },
    {
        name: 'clock-ten',
        categories: ['time'],
        terms: [],
        label: 'Clock Ten',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e354',
    },
    {
        name: 'clock-ten-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Ten-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e355',
    },
    {
        name: 'clock-three',
        categories: ['time'],
        terms: [],
        label: 'Clock Three',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e356',
    },
    {
        name: 'clock-three-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Three-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e357',
    },
    {
        name: 'clock-twelve',
        categories: ['time'],
        terms: [],
        label: 'Clock Twelve',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e358',
    },
    {
        name: 'clock-twelve-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Twelve-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e359',
    },
    {
        name: 'clock-two',
        categories: ['time'],
        terms: [],
        label: 'Clock Two',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35a',
    },
    {
        name: 'clock-two-thirty',
        categories: ['time'],
        terms: [],
        label: 'Clock Two-Thirty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35b',
    },
    {
        name: 'clone',
        categories: ['design', 'files', 'photos-images'],
        terms: ['arrange', 'copy', 'duplicate', 'paste'],
        label: 'Clone',
        aliases: {
            unicodes: {
                secondary: ['10f24d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f24d',
    },
    {
        name: 'closed-captioning',
        categories: ['accessibility', 'film-video'],
        terms: ['cc', 'deaf', 'hearing', 'subtitle', 'subtitling', 'text', 'video'],
        label: 'Closed Captioning',
        aliases: {
            unicodes: {
                secondary: ['10f20a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f20a',
    },
    {
        name: 'closed-captioning-slash',
        categories: ['accessibility'],
        terms: [],
        label: 'Closed Captioning-slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e135',
    },
    {
        name: 'clothes-hanger',
        categories: ['clothing-fashion', 'household'],
        terms: [],
        label: 'Clothes Hanger',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e136',
    },
    {
        name: 'cloud',
        categories: ['connectivity', 'shapes', 'weather'],
        terms: ['atmosphere', 'fog', 'overcast', 'save', 'upload', 'weather'],
        label: 'Cloud',
        aliases: {
            unicodes: {
                composite: ['2601'],
                secondary: ['10f0c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c2',
    },
    {
        name: 'cloud-arrow-down',
        categories: ['arrows', 'connectivity'],
        terms: [],
        label: 'Cloud arrow down',
        aliases: {
            names: ['cloud-download', 'cloud-download-alt'],
            unicodes: {
                composite: ['f381'],
                primary: ['f381'],
                secondary: ['10f381', '10f0ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ed',
    },
    {
        name: 'cloud-arrow-up',
        categories: ['arrows', 'connectivity'],
        terms: [],
        label: 'Cloud arrow up',
        aliases: {
            names: ['cloud-upload', 'cloud-upload-alt'],
            unicodes: {
                composite: ['f382'],
                primary: ['f382'],
                secondary: ['10f0ee', '10f382'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ee',
    },
    {
        name: 'cloud-bolt',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: [],
        label: 'Cloud bolt',
        aliases: {
            names: ['thunderstorm'],
            unicodes: {
                composite: ['1f329'],
                secondary: ['10f76c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76c',
    },
    {
        name: 'cloud-bolt-moon',
        categories: ['weather'],
        terms: [],
        label: 'Cloud bolt moon',
        aliases: {
            names: ['thunderstorm-moon'],
            unicodes: {
                secondary: ['10f76d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76d',
    },
    {
        name: 'cloud-bolt-sun',
        categories: ['weather'],
        terms: [],
        label: 'Cloud bolt sun',
        aliases: {
            names: ['thunderstorm-sun'],
            unicodes: {
                secondary: ['10f76e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76e',
    },
    {
        name: 'cloud-check',
        categories: ['connectivity'],
        terms: [],
        label: 'Cloud Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35c',
    },
    {
        name: 'cloud-drizzle',
        categories: ['weather'],
        terms: ['precipitation', 'rain', 'sprinkle'],
        label: 'Cloud with Drizzle',
        aliases: {
            unicodes: {
                secondary: ['10f738'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f738',
    },
    {
        name: 'cloud-exclamation',
        categories: ['alert', 'connectivity'],
        terms: [],
        label: 'Cloud Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e491',
    },
    {
        name: 'cloud-fog',
        categories: ['weather'],
        terms: [],
        label: 'Cloud fog',
        aliases: {
            names: ['fog'],
            unicodes: {
                composite: ['1f32b'],
                secondary: ['10f74e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74e',
    },
    {
        name: 'cloud-hail',
        categories: ['weather'],
        terms: ['golf balls', 'ice', 'precipitation', 'sleet', 'snow', 'storm'],
        label: 'Cloud with Hail',
        aliases: {
            unicodes: {
                secondary: ['10f739'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f739',
    },
    {
        name: 'cloud-hail-mixed',
        categories: ['weather'],
        terms: ['freezing', 'ice', 'precipitation', 'rain', 'sleet', 'snow', 'storm'],
        label: 'Cloud with Mixed Hail',
        aliases: {
            unicodes: {
                secondary: ['10f73a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73a',
    },
    {
        name: 'cloud-meatball',
        categories: ['food-beverage', 'weather'],
        terms: ['FLDSMDFR', 'food', 'spaghetti', 'storm'],
        label: 'Cloud with (a chance of) Meatball',
        aliases: {
            unicodes: {
                secondary: ['10f73b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73b',
    },
    {
        name: 'cloud-minus',
        categories: ['connectivity'],
        terms: [],
        label: 'Cloud Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35d',
    },
    {
        name: 'cloud-moon',
        categories: ['halloween', 'weather'],
        terms: ['crescent', 'evening', 'lunar', 'night', 'partly cloudy', 'sky'],
        label: 'Cloud with Moon',
        aliases: {
            unicodes: {
                secondary: ['10f6c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c3',
    },
    {
        name: 'cloud-moon-rain',
        categories: ['weather'],
        terms: ['crescent', 'evening', 'lunar', 'night', 'partly cloudy', 'precipitation', 'rain', 'sky', 'storm'],
        label: 'Cloud with Moon and Rain',
        aliases: {
            unicodes: {
                secondary: ['10f73c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73c',
    },
    {
        name: 'cloud-music',
        categories: ['film-video', 'music-audio'],
        terms: ['download', 'music', 'spotify', 'streaming'],
        label: 'Cloud Music',
        aliases: {
            unicodes: {
                secondary: ['10f8ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ae',
    },
    {
        name: 'cloud-plus',
        categories: ['connectivity'],
        terms: [],
        label: 'Cloud Plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35e',
    },
    {
        name: 'cloud-question',
        categories: ['alert', 'connectivity'],
        terms: [],
        label: 'Cloud Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e492',
    },
    {
        name: 'cloud-rain',
        categories: ['weather'],
        terms: ['precipitation', 'rain', 'sky', 'storm'],
        label: 'Cloud with Rain',
        aliases: {
            unicodes: {
                composite: ['1f327', '26c6'],
                secondary: ['10f73d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73d',
    },
    {
        name: 'cloud-rainbow',
        categories: ['weather'],
        terms: ['gold', 'leprechaun', 'prism', 'rain', 'sky'],
        label: 'Cloud with Rainbow',
        aliases: {
            unicodes: {
                secondary: ['10f73e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73e',
    },
    {
        name: 'cloud-showers',
        categories: ['weather'],
        terms: ['precipitation', 'rain', 'sky', 'storm'],
        label: 'Cloud with Showers',
        aliases: {
            unicodes: {
                secondary: ['10f73f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f73f',
    },
    {
        name: 'cloud-showers-heavy',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: ['precipitation', 'rain', 'sky', 'storm'],
        label: 'Cloud with Heavy Showers',
        aliases: {
            unicodes: {
                secondary: ['10f740'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f740',
    },
    {
        name: 'cloud-showers-water',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: [],
        label: 'Cloud Showers-water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e4',
    },
    {
        name: 'cloud-slash',
        categories: ['connectivity'],
        terms: [],
        label: 'Cloud Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e137',
    },
    {
        name: 'cloud-sleet',
        categories: ['weather'],
        terms: ['freezing', 'hail', 'precipitation', 'rain', 'winter', 'wintry mix'],
        label: 'Cloud with Sleet',
        aliases: {
            unicodes: {
                secondary: ['10f741'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f741',
    },
    {
        name: 'cloud-snow',
        categories: ['weather'],
        terms: ['blizzard', 'noreaster', 'precipitation', 'winter'],
        label: 'Cloud with Snow',
        aliases: {
            unicodes: {
                composite: ['1f328'],
                secondary: ['10f742'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f742',
    },
    {
        name: 'cloud-sun',
        categories: ['nature', 'weather'],
        terms: ['clear', 'day', 'daytime', 'fall', 'outdoors', 'overcast', 'partly cloudy'],
        label: 'Cloud with Sun',
        aliases: {
            unicodes: {
                composite: ['26c5'],
                secondary: ['10f6c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c4',
    },
    {
        name: 'cloud-sun-rain',
        categories: ['weather'],
        terms: ['day', 'overcast', 'precipitation', 'storm', 'summer', 'sunshower'],
        label: 'Cloud with Sun and Rain',
        aliases: {
            unicodes: {
                composite: ['1f326'],
                secondary: ['10f743'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f743',
    },
    {
        name: 'cloud-word',
        categories: ['business'],
        terms: [],
        label: 'Cloud Word',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e138',
    },
    {
        name: 'cloud-xmark',
        categories: ['connectivity'],
        terms: [],
        label: 'Cloud Xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e35f',
    },
    {
        name: 'clouds',
        categories: ['weather'],
        terms: ['cloudy', 'fog', 'haze', 'overcast', 'smoke', 'storm', 'weather'],
        label: 'Clouds',
        aliases: {
            unicodes: {
                secondary: ['10f744'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f744',
    },
    {
        name: 'clouds-moon',
        categories: ['weather'],
        terms: ['cloudy', 'moonlight', 'night', 'overcast', 'sky'],
        label: 'Clouds with Moon',
        aliases: {
            unicodes: {
                secondary: ['10f745'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f745',
    },
    {
        name: 'clouds-sun',
        categories: ['weather'],
        terms: ['cloudy', 'day', 'moonlight', 'overcast', 'sky', 'summer'],
        label: 'Clouds with Sun',
        aliases: {
            unicodes: {
                secondary: ['10f746'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f746',
    },
    {
        name: 'clover',
        categories: ['nature', 'shapes'],
        terms: [],
        label: 'Clover',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e139',
    },
    {
        name: 'club',
        categories: ['gaming', 'shapes'],
        terms: ['card', 'game', 'poker', 'suit'],
        label: 'Club',
        aliases: {
            unicodes: {
                composite: ['2663'],
                secondary: ['10f327'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f327',
    },
    {
        name: 'coconut',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Coconut',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2f6',
    },
    {
        name: 'code',
        categories: ['coding'],
        terms: ['brackets', 'code', 'development', 'html'],
        label: 'Code',
        aliases: {
            unicodes: {
                secondary: ['10f121'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f121',
    },
    {
        name: 'code-branch',
        categories: ['coding'],
        terms: ['branch', 'code-fork', 'fork', 'git', 'github', 'rebase', 'svn', 'vcs', 'version'],
        label: 'Code Branch',
        aliases: {
            unicodes: {
                secondary: ['10f126'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f126',
    },
    {
        name: 'code-commit',
        categories: ['coding'],
        terms: ['commit', 'git', 'github', 'hash', 'rebase', 'svn', 'vcs', 'version'],
        label: 'Code Commit',
        aliases: {
            unicodes: {
                secondary: ['10f386'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f386',
    },
    {
        name: 'code-compare',
        categories: ['coding'],
        terms: [],
        label: 'Code Compare',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13a',
    },
    {
        name: 'code-fork',
        categories: ['coding'],
        terms: [],
        label: 'Code Fork',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13b',
    },
    {
        name: 'code-merge',
        categories: ['coding'],
        terms: ['git', 'github', 'merge', 'pr', 'rebase', 'svn', 'vcs', 'version'],
        label: 'Code Merge',
        aliases: {
            unicodes: {
                secondary: ['10f387'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f387',
    },
    {
        name: 'code-pull-request',
        categories: ['coding'],
        terms: [],
        label: 'Code Pull Request',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13c',
    },
    {
        name: 'code-pull-request-closed',
        categories: ['coding'],
        terms: [],
        label: 'Code Pull Request Closed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3f9',
    },
    {
        name: 'code-pull-request-draft',
        categories: ['coding'],
        terms: [],
        label: 'Code Pull Request Draft',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3fa',
    },
    {
        name: 'code-simple',
        categories: ['coding'],
        terms: [],
        label: 'Code Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13d',
    },
    {
        name: 'coffee-bean',
        categories: ['food-beverage'],
        terms: [],
        label: 'Coffee Bean',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13e',
    },
    {
        name: 'coffee-beans',
        categories: ['food-beverage'],
        terms: [],
        label: 'Coffee Beans',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e13f',
    },
    {
        name: 'coffee-pot',
        categories: ['business', 'food-beverage', 'household'],
        terms: ['beverage', 'breakfast', 'brew', 'cafe', 'carafe', 'drink', 'morning'],
        label: 'Coffee Pot',
        aliases: {
            unicodes: {
                secondary: ['10e002'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e002',
    },
    {
        name: 'coffin',
        categories: ['halloween'],
        terms: ['box', 'burial', 'casket', 'cemetery', 'death', 'eulogy', 'funeral', 'halloween', 'vampire'],
        label: 'Coffin',
        aliases: {
            unicodes: {
                composite: ['26b0'],
                secondary: ['10f6c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c6',
    },
    {
        name: 'coffin-cross',
        categories: ['halloween', 'religion'],
        terms: [
            'box',
            'burial',
            'casket',
            'catholicism',
            'cemetery',
            'christianity',
            'death',
            'eulogy',
            'funeral',
            'halloween',
            'vampire',
        ],
        label: 'Coffin with Cross',
        aliases: {
            unicodes: {
                secondary: ['10e051'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e051',
    },
    {
        name: 'coin',
        categories: ['money'],
        terms: ['dime', 'money', 'nickel', 'penny', 'quarter'],
        label: 'Coin',
        aliases: {
            unicodes: {
                composite: ['1fa99'],
                secondary: ['10f85c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85c',
    },
    {
        name: 'coin-blank',
        categories: ['money'],
        terms: [],
        label: 'Coin Blank',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3fb',
    },
    {
        name: 'coin-front',
        categories: ['money'],
        terms: [],
        label: 'Coin Front',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3fc',
    },
    {
        name: 'coin-vertical',
        categories: ['money'],
        terms: [],
        label: 'Coin Vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3fd',
    },
    {
        name: 'coins',
        categories: ['money'],
        terms: ['currency', 'dime', 'financial', 'gold', 'money', 'penny'],
        label: 'Coins',
        aliases: {
            unicodes: {
                secondary: ['10f51e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51e',
    },
    {
        name: 'colon',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Colon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3a',
    },
    {
        name: 'colon-sign',
        categories: ['money'],
        terms: [],
        label: 'Colon Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e140',
    },
    {
        name: 'columns-3',
        categories: ['design', 'text-formatting'],
        terms: [],
        label: 'Columns 3',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e361',
    },
    {
        name: 'comet',
        categories: ['astronomy'],
        terms: ['asteroid', 'dust', 'ice', 'meteor', 'orbit', 'rock', 'shooting star', 'solar system', 'space'],
        label: 'Comet',
        aliases: {
            unicodes: {
                secondary: ['10e003'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e003',
    },
    {
        name: 'comma',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Comma',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2c',
    },
    {
        name: 'command',
        categories: ['editing', 'text-formatting'],
        terms: [],
        label: 'Command',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e142',
    },
    {
        name: 'comment',
        categories: ['communication', 'shapes', 'social'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'note',
            'notification',
            'sms',
            'speech',
            'texting',
        ],
        label: 'comment',
        aliases: {
            unicodes: {
                composite: ['f0e5', '1f5e9'],
                secondary: ['10f075'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f075',
    },
    {
        name: 'comment-arrow-down',
        categories: ['communication'],
        terms: [],
        label: 'Comment Arrow-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e143',
    },
    {
        name: 'comment-arrow-up',
        categories: ['communication'],
        terms: [],
        label: 'Comment Arrow-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e144',
    },
    {
        name: 'comment-arrow-up-right',
        categories: ['communication'],
        terms: [],
        label: 'Comment Arrow-up-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e145',
    },
    {
        name: 'comment-captions',
        categories: ['accessibility'],
        terms: [],
        label: 'Comment Captions',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e146',
    },
    {
        name: 'comment-check',
        categories: ['communication'],
        terms: [
            'accept',
            'agree',
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'note',
            'notification',
            'select',
            'sms',
            'speech',
            'success',
            'synced',
            'texting',
            'tick',
            'todo',
        ],
        label: 'Comment Check',
        aliases: {
            unicodes: {
                secondary: ['10f4ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ac',
    },
    {
        name: 'comment-code',
        categories: ['communication'],
        terms: [],
        label: 'Comment Code',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e147',
    },
    {
        name: 'comment-dollar',
        categories: ['marketing', 'money'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'money',
            'note',
            'notification',
            'pay',
            'sms',
            'speech',
            'spend',
            'texting',
            'transfer',
        ],
        label: 'Comment Dollar',
        aliases: {
            unicodes: {
                secondary: ['10f651'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f651',
    },
    {
        name: 'comment-dots',
        categories: ['communication'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'more',
            'note',
            'notification',
            'reply',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Dots',
        aliases: {
            names: ['commenting'],
            unicodes: {
                composite: ['f27b', '1f4ac'],
                secondary: ['10f4ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ad',
    },
    {
        name: 'comment-exclamation',
        categories: ['alert', 'communication'],
        terms: [
            'alert',
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'exclaim',
            'feedback',
            'important',
            'message',
            'note',
            'notification',
            'sms',
            'speech',
            'surprise',
            'texting',
        ],
        label: 'Comment Exclamation',
        aliases: {
            unicodes: {
                secondary: ['10f4af'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4af',
    },
    {
        name: 'comment-image',
        categories: ['communication', 'photos-images'],
        terms: [],
        label: 'Comment Image',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e148',
    },
    {
        name: 'comment-lines',
        categories: ['communication'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'note',
            'notification',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Lines',
        aliases: {
            unicodes: {
                secondary: ['10f4b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b0',
    },
    {
        name: 'comment-medical',
        categories: ['communication', 'medical-health'],
        terms: [
            'advice',
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'diagnose',
            'feedback',
            'message',
            'note',
            'notification',
            'prescription',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Alternate Medical Chat',
        aliases: {
            unicodes: {
                secondary: ['10f7f5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f5',
    },
    {
        name: 'comment-middle',
        categories: ['communication'],
        terms: [],
        label: 'Comment Middle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e149',
    },
    {
        name: 'comment-middle-top',
        categories: ['communication'],
        terms: [],
        label: 'Comment Middle-top',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14a',
    },
    {
        name: 'comment-minus',
        categories: ['communication'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'delete',
            'feedback',
            'message',
            'negative',
            'note',
            'notification',
            'remove',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Minus',
        aliases: {
            unicodes: {
                secondary: ['10f4b1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b1',
    },
    {
        name: 'comment-music',
        categories: ['communication', 'music-audio'],
        terms: [
            'chat',
            'commenting',
            'conversation',
            'create',
            'message',
            'music',
            'note',
            'notification',
            'sing',
            'sms',
            'song',
            'texting',
        ],
        label: 'Comment Music',
        aliases: {
            unicodes: {
                secondary: ['10f8b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b0',
    },
    {
        name: 'comment-pen',
        categories: ['communication'],
        terms: [],
        label: 'Comment pen',
        aliases: {
            names: ['comment-edit'],
            unicodes: {
                secondary: ['10f4ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ae',
    },
    {
        name: 'comment-plus',
        categories: ['communication'],
        terms: [
            'add',
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'create',
            'feedback',
            'message',
            'new',
            'note',
            'notification',
            'positive',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Plus',
        aliases: {
            unicodes: {
                secondary: ['10f4b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b2',
    },
    {
        name: 'comment-question',
        categories: ['communication'],
        terms: [],
        label: 'Comment Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14b',
    },
    {
        name: 'comment-quote',
        categories: ['communication'],
        terms: [],
        label: 'Comment Quote',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14c',
    },
    {
        name: 'comment-slash',
        categories: ['communication'],
        terms: [
            'bubble',
            'cancel',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'mute',
            'note',
            'notification',
            'quiet',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Slash',
        aliases: {
            unicodes: {
                secondary: ['10f4b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b3',
    },
    {
        name: 'comment-smile',
        categories: ['communication'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'emoji',
            'feedback',
            'happy',
            'message',
            'note',
            'notification',
            'sms',
            'speech',
            'texting',
        ],
        label: 'Comment Smile',
        aliases: {
            unicodes: {
                secondary: ['10f4b4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b4',
    },
    {
        name: 'comment-sms',
        categories: ['communication'],
        terms: [],
        label: 'Comment sms',
        aliases: {
            names: ['sms'],
            unicodes: {
                secondary: ['10f7cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7cd',
    },
    {
        name: 'comment-text',
        categories: ['communication'],
        terms: [],
        label: 'Comment Text',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14d',
    },
    {
        name: 'comment-xmark',
        categories: ['communication'],
        terms: [],
        label: 'Comment X Mark',
        aliases: {
            names: ['comment-times'],
            unicodes: {
                secondary: ['10f4b5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b5',
    },
    {
        name: 'comments',
        categories: ['communication'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'note',
            'notification',
            'sms',
            'speech',
            'texting',
        ],
        label: 'comments',
        aliases: {
            unicodes: {
                composite: ['f0e6', '1f5ea'],
                secondary: ['10f086'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f086',
    },
    {
        name: 'comments-dollar',
        categories: ['marketing', 'money'],
        terms: [
            'bubble',
            'chat',
            'commenting',
            'conversation',
            'feedback',
            'message',
            'money',
            'note',
            'notification',
            'pay',
            'sms',
            'speech',
            'spend',
            'texting',
            'transfer',
        ],
        label: 'Comments Dollar',
        aliases: {
            unicodes: {
                secondary: ['10f653'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f653',
    },
    {
        name: 'comments-question',
        categories: ['communication'],
        terms: [],
        label: 'Comments Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14e',
    },
    {
        name: 'comments-question-check',
        categories: ['communication'],
        terms: [],
        label: 'Comments Question-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e14f',
    },
    {
        name: 'compact-disc',
        categories: ['devices-hardware', 'film-video', 'music-audio', 'spinners'],
        terms: ['album', 'bluray', 'cd', 'disc', 'dvd', 'media', 'movie', 'music', 'record', 'video', 'vinyl'],
        label: 'Compact Disc',
        aliases: {
            unicodes: {
                composite: ['1f4c0', '1f5b8', '1f4bf'],
                secondary: ['10f51f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f51f',
    },
    {
        name: 'compass',
        categories: ['business', 'camping', 'spinners'],
        terms: ['directions', 'directory', 'location', 'menu', 'navigation', 'safari', 'travel'],
        label: 'Compass',
        aliases: {
            unicodes: {
                composite: ['1f9ed'],
                secondary: ['10f14e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f14e',
    },
    {
        name: 'compass-drafting',
        categories: ['construction', 'design'],
        terms: [],
        label: 'Compass drafting',
        aliases: {
            names: ['drafting-compass'],
            unicodes: {
                secondary: ['10f568'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f568',
    },
    {
        name: 'compass-slash',
        categories: ['maps'],
        terms: ['directions', 'directory', 'location', 'lost', 'menu', 'navigation', 'safari', 'travel'],
        label: 'Compass Slash',
        aliases: {
            unicodes: {
                secondary: ['10f5e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5e9',
    },
    {
        name: 'compress',
        categories: ['media-playback'],
        terms: ['collapse', 'fullscreen', 'minimize', 'move', 'resize', 'shrink', 'smaller'],
        label: 'Compress',
        aliases: {
            unicodes: {
                secondary: ['10f066'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f066',
    },
    {
        name: 'compress-wide',
        categories: ['media-playback'],
        terms: ['collapse', 'fullscreen', 'minimize', 'move', 'resize', 'shrink', 'smaller'],
        label: 'Compress Wide',
        aliases: {
            unicodes: {
                secondary: ['10f326'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f326',
    },
    {
        name: 'computer',
        categories: ['devices-hardware', 'household', 'humanitarian'],
        terms: [],
        label: 'Computer',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e5',
    },
    {
        name: 'computer-classic',
        categories: ['business', 'devices-hardware'],
        terms: ['apple II', 'hardware', 'machine', 'macintosh', 'programming', 'retro', 'vintage'],
        label: 'Classic Computer',
        aliases: {
            unicodes: {
                secondary: ['10f8b1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b1',
    },
    {
        name: 'computer-mouse',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Computer mouse',
        aliases: {
            names: ['mouse'],
            unicodes: {
                composite: ['1f5b1'],
                secondary: ['10f8cc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8cc',
    },
    {
        name: 'computer-mouse-scrollwheel',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Computer mouse scrollwheel',
        aliases: {
            names: ['mouse-alt'],
            unicodes: {
                secondary: ['10f8cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8cd',
    },
    {
        name: 'computer-speaker',
        categories: ['devices-hardware', 'film-video', 'music-audio'],
        terms: ['airplay', 'bluetooth', 'devices', 'music', 'output', 'pair', 'sound', 'sync'],
        label: 'Computer Speaker',
        aliases: {
            unicodes: {
                secondary: ['10f8b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b2',
    },
    {
        name: 'container-storage',
        categories: ['buildings', 'moving'],
        terms: ['archive', 'box', 'inventory', 'shipping', 'warehouse'],
        label: 'Container Storage',
        aliases: {
            unicodes: {
                secondary: ['10f4b7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b7',
    },
    {
        name: 'conveyor-belt',
        categories: ['logistics'],
        terms: ['carousel', 'inventory', 'manufacture', 'packaging', 'shipping'],
        label: 'Conveyor Belt',
        aliases: {
            unicodes: {
                secondary: ['10f46e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46e',
    },
    {
        name: 'conveyor-belt-boxes',
        categories: ['logistics'],
        terms: [],
        label: 'Conveyor belt boxes',
        aliases: {
            names: ['conveyor-belt-alt'],
            unicodes: {
                secondary: ['10f46f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46f',
    },
    {
        name: 'conveyor-belt-empty',
        categories: ['logistics'],
        terms: [],
        label: 'Conveyor Belt-empty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e150',
    },
    {
        name: 'cookie',
        categories: ['childhood', 'food-beverage'],
        terms: ['baked good', 'chips', 'chocolate', 'eat', 'snack', 'sweet', 'treat'],
        label: 'Cookie',
        aliases: {
            unicodes: {
                composite: ['1f36a'],
                secondary: ['10f563'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f563',
    },
    {
        name: 'cookie-bite',
        categories: ['childhood', 'holidays'],
        terms: ['baked good', 'bitten', 'chips', 'chocolate', 'eat', 'snack', 'sweet', 'treat'],
        label: 'Cookie Bite',
        aliases: {
            unicodes: {
                secondary: ['10f564'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f564',
    },
    {
        name: 'copy',
        categories: ['business', 'design', 'files'],
        terms: ['clone', 'duplicate', 'file', 'files-o', 'paper', 'paste'],
        label: 'Copy',
        aliases: {
            unicodes: {
                secondary: ['10f0c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c5',
    },
    {
        name: 'copyright',
        categories: ['business'],
        terms: ['brand', 'mark', 'register', 'trademark'],
        label: 'Copyright',
        aliases: {
            unicodes: {
                composite: ['a9'],
                secondary: ['10f1f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1f9',
    },
    {
        name: 'corn',
        categories: ['food-beverage'],
        terms: ['cob', 'ear', 'fall', 'grain', 'kernel', 'maize', 'popcorn'],
        label: 'Corn',
        aliases: {
            unicodes: {
                composite: ['1f33d'],
                secondary: ['10f6c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c7',
    },
    {
        name: 'corner',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Corner',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3fe',
    },
    {
        name: 'couch',
        categories: ['household', 'moving'],
        terms: ['chair', 'cushion', 'furniture', 'relax', 'sofa'],
        label: 'Couch',
        aliases: {
            unicodes: {
                secondary: ['10f4b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b8',
    },
    {
        name: 'cow',
        categories: ['animals', 'humanitarian'],
        terms: ['agriculture', 'animal', 'beef', 'bovine', 'farm', 'fauna', 'mammal', 'milk', 'moo'],
        label: 'Cow',
        aliases: {
            unicodes: {
                composite: ['1f404'],
                secondary: ['10f6c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c8',
    },
    {
        name: 'cowbell',
        categories: ['music-audio'],
        terms: ['Blue Öyster Cult', 'Christopher Walken', 'SNL', 'The Bruce Dickinson', 'Will Ferrell', 'music'],
        label: 'Cowbell',
        aliases: {
            unicodes: {
                secondary: ['10f8b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b3',
    },
    {
        name: 'cowbell-circle-plus',
        categories: ['music-audio'],
        terms: [],
        label: 'Cowbell circle plus',
        aliases: {
            names: ['cowbell-more'],
            unicodes: {
                secondary: ['10f8b4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b4',
    },
    {
        name: 'crab',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: [],
        label: 'Crab',
        aliases: {
            unicodes: {
                composite: ['1f980'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ff',
    },
    {
        name: 'crate-apple',
        categories: ['food-beverage', 'fruits-vegetables'],
        terms: [],
        label: 'Apple Crate',
        aliases: {
            names: ['apple-crate'],
            unicodes: {
                secondary: ['10f6b1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6b1',
    },
    {
        name: 'crate-empty',
        categories: ['fruits-vegetables', 'logistics'],
        terms: [],
        label: 'Crate Empty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e151',
    },
    {
        name: 'credit-card',
        categories: ['money', 'shopping'],
        terms: ['buy', 'checkout', 'credit-card-alt', 'debit', 'money', 'payment', 'purchase'],
        label: 'Credit Card',
        aliases: {
            names: ['credit-card-alt'],
            unicodes: {
                composite: ['f283', '1f4b3'],
                secondary: ['10f09d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f09d',
    },
    {
        name: 'credit-card-blank',
        categories: ['money', 'shopping'],
        terms: ['buy', 'checkout', 'debit', 'money', 'payment', 'purchase'],
        label: 'Credit Card Blank',
        aliases: {
            unicodes: {
                secondary: ['10f389'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f389',
    },
    {
        name: 'credit-card-front',
        categories: ['money', 'shopping'],
        terms: ['buy', 'checkout', 'chip', 'debit', 'money', 'payment', 'purchase'],
        label: 'Credit Card Front',
        aliases: {
            unicodes: {
                secondary: ['10f38a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f38a',
    },
    {
        name: 'cricket-bat-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Cricket bat ball',
        aliases: {
            names: ['cricket'],
            unicodes: {
                composite: ['1f3cf'],
                secondary: ['10f449'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f449',
    },
    {
        name: 'critical-role',
        categories: ['gaming'],
        terms: [],
        label: 'Critical Role',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f6c9',
    },
    {
        name: 'croissant',
        categories: ['food-beverage'],
        terms: ['bakery', 'breakfast', 'brioche', 'butter', 'crescent', 'dough', 'gluten', 'pastry', 'roll'],
        label: 'Croissant',
        aliases: {
            unicodes: {
                composite: ['1f950'],
                secondary: ['10f7f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f6',
    },
    {
        name: 'crop',
        categories: ['design', 'editing'],
        terms: ['design', 'frame', 'mask', 'resize', 'shrink'],
        label: 'crop',
        aliases: {
            unicodes: {
                secondary: ['10f125'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f125',
    },
    {
        name: 'crop-simple',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Crop simple',
        aliases: {
            names: ['crop-alt'],
            unicodes: {
                secondary: ['10f565'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f565',
    },
    {
        name: 'cross',
        categories: ['religion'],
        terms: ['catholicism', 'christianity', 'church', 'jesus'],
        label: 'Cross',
        aliases: {
            unicodes: {
                composite: ['1f547', '271d'],
                secondary: ['10f654'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f654',
    },
    {
        name: 'crosshairs',
        categories: ['design', 'maps', 'spinners'],
        terms: ['aim', 'bullseye', 'gpd', 'picker', 'position'],
        label: 'Crosshairs',
        aliases: {
            unicodes: {
                secondary: ['10f05b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f05b',
    },
    {
        name: 'crow',
        categories: ['animals', 'halloween'],
        terms: ['bird', 'bullfrog', 'fauna', 'halloween', 'holiday', 'toad'],
        label: 'Crow',
        aliases: {
            unicodes: {
                secondary: ['10f520'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f520',
    },
    {
        name: 'crown',
        categories: ['shapes'],
        terms: ['award', 'favorite', 'king', 'queen', 'royal', 'tiara'],
        label: 'Crown',
        aliases: {
            unicodes: {
                composite: ['1f451'],
                secondary: ['10f521'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f521',
    },
    {
        name: 'crutch',
        categories: ['medical-health'],
        terms: ['cane', 'injury', 'mobility', 'wheelchair'],
        label: 'Crutch',
        aliases: {
            unicodes: {
                secondary: ['10f7f7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f7',
    },
    {
        name: 'crutches',
        categories: ['medical-health'],
        terms: ['cane', 'injury', 'mobility', 'wheelchair'],
        label: 'Crutches',
        aliases: {
            unicodes: {
                secondary: ['10f7f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f8',
    },
    {
        name: 'cruzeiro-sign',
        categories: ['money'],
        terms: [],
        label: 'Cruzeiro Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e152',
    },
    {
        name: 'crystal-ball',
        categories: ['communication', 'gaming'],
        terms: [],
        label: 'Crystal Ball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e362',
    },
    {
        name: 'cube',
        categories: ['coding', 'design', 'editing'],
        terms: ['3d', 'block', 'dice', 'package', 'square', 'tesseract'],
        label: 'Cube',
        aliases: {
            unicodes: {
                secondary: ['10f1b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1b2',
    },
    {
        name: 'cubes',
        categories: ['coding', 'design'],
        terms: ['3d', 'block', 'dice', 'package', 'pyramid', 'square', 'stack', 'tesseract'],
        label: 'Cubes',
        aliases: {
            unicodes: {
                secondary: ['10f1b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1b3',
    },
    {
        name: 'cubes-stacked',
        categories: ['childhood', 'food-beverage', 'humanitarian', 'shapes'],
        terms: [],
        label: 'Cubes Stacked',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e6',
    },
    {
        name: 'cucumber',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Cucumber',
        aliases: {
            unicodes: {
                composite: ['1f952'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e401',
    },
    {
        name: 'cup-straw',
        categories: ['food-beverage'],
        terms: [],
        label: 'Cup Straw',
        aliases: {
            unicodes: {
                composite: ['1f964'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e363',
    },
    {
        name: 'cup-straw-swoosh',
        categories: ['food-beverage'],
        terms: [],
        label: 'Cup Straw with Swoosh',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e364',
    },
    {
        name: 'cup-togo',
        categories: ['food-beverage'],
        terms: [],
        label: 'Cup togo',
        aliases: {
            names: ['coffee-togo'],
            unicodes: {
                secondary: ['10f6c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6c5',
    },
    {
        name: 'cupcake',
        categories: ['childhood', 'food-beverage', 'holidays'],
        terms: [],
        label: 'Cupcake',
        aliases: {
            unicodes: {
                composite: ['1f9c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e402',
    },
    {
        name: 'curling-stone',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Curling stone',
        aliases: {
            names: ['curling'],
            unicodes: {
                composite: ['1f94c'],
                secondary: ['10f44a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f44a',
    },
    {
        name: 'custard',
        categories: ['food-beverage'],
        terms: [],
        label: 'Custard',
        aliases: {
            unicodes: {
                composite: ['1f36e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e403',
    },
    {
        name: 'd',
        categories: ['alphabet'],
        terms: [],
        label: 'D',
        aliases: {
            unicodes: {
                composite: ['64'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '44',
    },
    {
        name: 'd-and-d',
        categories: ['gaming'],
        terms: [],
        label: 'Dungeons & Dragons',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f38d',
    },
    {
        name: 'd-and-d-beyond',
        categories: ['gaming'],
        terms: [],
        label: 'D&D Beyond',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f6ca',
    },
    {
        name: 'dagger',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'blade', 'd&d', 'dnd', 'fantasy', 'melee attack', 'rogue', 'sting', 'weapon'],
        label: 'Dagger',
        aliases: {
            unicodes: {
                secondary: ['10f6cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6cb',
    },
    {
        name: 'dash',
        categories: ['editing', 'punctuation-symbols'],
        terms: [],
        label: 'Dash',
        aliases: {
            names: ['minus-large'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e404',
    },
    {
        name: 'database',
        categories: ['devices-hardware'],
        terms: ['computer', 'development', 'directory', 'memory', 'storage'],
        label: 'Database',
        aliases: {
            unicodes: {
                secondary: ['10f1c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c0',
    },
    {
        name: 'deer',
        categories: ['animals', 'holidays'],
        terms: [
            'animal',
            'antlers',
            'blitzen',
            'comet',
            'cupid',
            'dancer',
            'dasher',
            'donner',
            'fauna',
            'mammal',
            'prancer',
            'reindeer',
            'vixen',
        ],
        label: 'Deer',
        aliases: {
            unicodes: {
                composite: ['1f98c'],
                secondary: ['10f78e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f78e',
    },
    {
        name: 'deer-rudolph',
        categories: ['animals', 'holidays'],
        terms: ['animal', 'antlers', 'fauna', 'mammal', 'reindeer'],
        label: 'Deer Rudolph',
        aliases: {
            unicodes: {
                secondary: ['10f78f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f78f',
    },
    {
        name: 'delete-left',
        categories: ['editing'],
        terms: [],
        label: 'Delete left',
        aliases: {
            names: ['backspace'],
            unicodes: {
                composite: ['232b'],
                secondary: ['10f55a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f55a',
    },
    {
        name: 'delete-right',
        categories: ['editing'],
        terms: [],
        label: 'Delete Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e154',
    },
    {
        name: 'democrat',
        categories: ['political'],
        terms: [
            'american',
            'democratic party',
            'donkey',
            'election',
            'left',
            'left-wing',
            'liberal',
            'politics',
            'usa',
        ],
        label: 'Democrat',
        aliases: {
            unicodes: {
                secondary: ['10f747'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f747',
    },
    {
        name: 'desktop',
        categories: ['devices-hardware'],
        terms: ['computer', 'cpu', 'demo', 'desktop', 'device', 'imac', 'machine', 'monitor', 'pc', 'screen'],
        label: 'Desktop',
        aliases: {
            names: ['desktop-alt'],
            unicodes: {
                composite: ['f108', '1f5a5'],
                primary: ['f108'],
                secondary: ['10f390', '10f108'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f390',
    },
    {
        name: 'desktop-arrow-down',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Desktop Arrow-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e155',
    },
    {
        name: 'dharmachakra',
        categories: ['religion', 'spinners'],
        terms: ['buddhism', 'buddhist', 'wheel of dharma'],
        label: 'Dharmachakra',
        aliases: {
            unicodes: {
                composite: ['2638'],
                secondary: ['10f655'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f655',
    },
    {
        name: 'diagram-cells',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Cells',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e475',
    },
    {
        name: 'diagram-lean-canvas',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Lean-canvas',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e156',
    },
    {
        name: 'diagram-nested',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Nested',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e157',
    },
    {
        name: 'diagram-next',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Next',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e476',
    },
    {
        name: 'diagram-predecessor',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Predecessor',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e477',
    },
    {
        name: 'diagram-previous',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Previous',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e478',
    },
    {
        name: 'diagram-project',
        categories: ['charts-diagrams', 'coding'],
        terms: [],
        label: 'Project Diagram',
        aliases: {
            names: ['project-diagram'],
            unicodes: {
                secondary: ['10f542'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f542',
    },
    {
        name: 'diagram-sankey',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Sankey',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e158',
    },
    {
        name: 'diagram-subtask',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Subtask',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e479',
    },
    {
        name: 'diagram-successor',
        categories: ['charts-diagrams'],
        terms: [],
        label: 'Diagram Successor',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47a',
    },
    {
        name: 'diagram-venn',
        categories: ['business', 'charts-diagrams', 'editing'],
        terms: [],
        label: 'Venn Diagram',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15a',
    },
    {
        name: 'dial',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial',
        aliases: {
            names: ['dial-med-high'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15b',
    },
    {
        name: 'dial-high',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial High',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15c',
    },
    {
        name: 'dial-low',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Low',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15d',
    },
    {
        name: 'dial-max',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Max',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15e',
    },
    {
        name: 'dial-med',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Med',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e15f',
    },
    {
        name: 'dial-med-low',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Med-low',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e160',
    },
    {
        name: 'dial-min',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Min',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e161',
    },
    {
        name: 'dial-off',
        categories: ['editing', 'film-video', 'music-audio', 'toggle'],
        terms: [],
        label: 'Dial Off',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e162',
    },
    {
        name: 'diamond',
        categories: ['gaming', 'shapes'],
        terms: ['cards', 'gem', 'gemstone', 'poker', 'suit'],
        label: 'Diamond',
        aliases: {
            unicodes: {
                composite: ['2666'],
                secondary: ['10f219'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f219',
    },
    {
        name: 'diamond-exclamation',
        categories: ['alert', 'security'],
        terms: [],
        label: 'Diamond Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e405',
    },
    {
        name: 'diamond-turn-right',
        categories: ['maps'],
        terms: [],
        label: 'Diamond turn right',
        aliases: {
            names: ['directions'],
            unicodes: {
                secondary: ['10f5eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5eb',
    },
    {
        name: 'dice',
        categories: ['gaming', 'travel-hotel'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice',
        aliases: {
            unicodes: {
                composite: ['1f3b2'],
                secondary: ['10f522'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f522',
    },
    {
        name: 'dice-d10',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D10',
        aliases: {
            unicodes: {
                secondary: ['10f6cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6cd',
    },
    {
        name: 'dice-d12',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D12',
        aliases: {
            unicodes: {
                secondary: ['10f6ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ce',
    },
    {
        name: 'dice-d20',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D20',
        aliases: {
            unicodes: {
                secondary: ['10f6cf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6cf',
    },
    {
        name: 'dice-d4',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D4',
        aliases: {
            unicodes: {
                secondary: ['10f6d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d0',
    },
    {
        name: 'dice-d6',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D6',
        aliases: {
            unicodes: {
                secondary: ['10f6d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d1',
    },
    {
        name: 'dice-d8',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'chance', 'd&d', 'dnd', 'fantasy', 'gambling', 'game', 'roll'],
        label: 'Dice D8',
        aliases: {
            unicodes: {
                secondary: ['10f6d2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d2',
    },
    {
        name: 'dice-five',
        categories: ['gaming', 'travel-hotel'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice Five',
        aliases: {
            unicodes: {
                composite: ['2684'],
                secondary: ['10f523'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f523',
    },
    {
        name: 'dice-four',
        categories: ['gaming'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice Four',
        aliases: {
            unicodes: {
                composite: ['2683'],
                secondary: ['10f524'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f524',
    },
    {
        name: 'dice-one',
        categories: ['gaming'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice One',
        aliases: {
            unicodes: {
                composite: ['2680'],
                secondary: ['10f525'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f525',
    },
    {
        name: 'dice-six',
        categories: ['gaming'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice Six',
        aliases: {
            unicodes: {
                composite: ['2685'],
                secondary: ['10f526'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f526',
    },
    {
        name: 'dice-three',
        categories: ['gaming'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice Three',
        aliases: {
            unicodes: {
                composite: ['2682'],
                secondary: ['10f527'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f527',
    },
    {
        name: 'dice-two',
        categories: ['gaming'],
        terms: ['chance', 'gambling', 'game', 'roll'],
        label: 'Dice Two',
        aliases: {
            unicodes: {
                composite: ['2681'],
                secondary: ['10f528'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f528',
    },
    {
        name: 'diploma',
        categories: ['education'],
        terms: ['award', 'certificate', 'college', 'education', 'graduate', 'graduation', 'university'],
        label: 'Diploma',
        aliases: {
            names: ['scroll-ribbon'],
            unicodes: {
                secondary: ['10f5ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ea',
    },
    {
        name: 'disc-drive',
        categories: ['devices-hardware', 'film-video', 'music-audio'],
        terms: [
            'blu-ray',
            'cd',
            'cd-rom',
            'computer',
            'dvd',
            'load',
            'music',
            'optical drive',
            'peripheral',
            'tray',
            'video',
        ],
        label: 'Disc Drive',
        aliases: {
            unicodes: {
                secondary: ['10f8b5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b5',
    },
    {
        name: 'disease',
        categories: ['medical-health', 'science'],
        terms: ['bacteria', 'cancer', 'covid-19', 'illness', 'infection', 'sickness', 'virus'],
        label: 'Disease',
        aliases: {
            unicodes: {
                secondary: ['10f7fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7fa',
    },
    {
        name: 'display',
        categories: ['devices-hardware', 'humanitarian'],
        terms: [],
        label: 'Display',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e163',
    },
    {
        name: 'display-arrow-down',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Display Arrow-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e164',
    },
    {
        name: 'display-code',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Display code',
        aliases: {
            names: ['desktop-code'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e165',
    },
    {
        name: 'display-medical',
        categories: ['devices-hardware', 'medical-health'],
        terms: [],
        label: 'Display medical',
        aliases: {
            names: ['desktop-medical'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e166',
    },
    {
        name: 'display-slash',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Display slash',
        aliases: {
            names: ['desktop-slash'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2fa',
    },
    {
        name: 'distribute-spacing-horizontal',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Distribute Spacing Horizontal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e365',
    },
    {
        name: 'distribute-spacing-vertical',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Distribute Spacing Vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e366',
    },
    {
        name: 'ditto',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Ditto',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '22',
    },
    {
        name: 'divide',
        categories: ['mathematics'],
        terms: ['arithmetic', 'calculus', 'division', 'math'],
        label: 'Divide',
        aliases: {
            unicodes: {
                composite: ['2797', 'f7'],
                secondary: ['10f529'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f529',
    },
    {
        name: 'dna',
        categories: ['medical-health', 'science'],
        terms: ['double helix', 'genetic', 'helix', 'molecule', 'protein'],
        label: 'DNA',
        aliases: {
            unicodes: {
                composite: ['1f9ec'],
                secondary: ['10f471'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f471',
    },
    {
        name: 'do-not-enter',
        categories: ['maps', 'security'],
        terms: ['closed', 'traffic', 'warning'],
        label: 'Do Not-enter',
        aliases: {
            unicodes: {
                secondary: ['10f5ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ec',
    },
    {
        name: 'dog',
        categories: ['animals'],
        terms: ['animal', 'canine', 'fauna', 'mammal', 'pet', 'pooch', 'puppy', 'woof'],
        label: 'Dog',
        aliases: {
            unicodes: {
                composite: ['1f415'],
                secondary: ['10f6d3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d3',
    },
    {
        name: 'dog-leashed',
        categories: ['accessibility', 'animals'],
        terms: ['animal', 'canine', 'fauna', 'mammal', 'pet', 'pooch', 'puppy', 'walk', 'woof'],
        label: 'Leashed Dog',
        aliases: {
            unicodes: {
                composite: ['1f9ae'],
                secondary: ['10f6d4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d4',
    },
    {
        name: 'dollar-sign',
        categories: ['charity', 'maps', 'money'],
        terms: ['$', 'cost', 'dollar-sign', 'money', 'price', 'usd'],
        label: 'Dollar Sign',
        aliases: {
            names: ['dollar', 'usd'],
            unicodes: {
                composite: ['1f4b2', 'f155'],
                primary: ['f155'],
                secondary: ['10f155', '1024'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '24',
    },
    {
        name: 'dolly',
        categories: ['logistics', 'moving'],
        terms: ['carry', 'shipping', 'transport'],
        label: 'Dolly',
        aliases: {
            names: ['dolly-box'],
            unicodes: {
                secondary: ['10f472'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f472',
    },
    {
        name: 'dolly-empty',
        categories: ['logistics', 'moving'],
        terms: ['carry', 'inventory', 'shipping', 'transport'],
        label: 'Dolly Empty',
        aliases: {
            unicodes: {
                secondary: ['10f473'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f473',
    },
    {
        name: 'dolphin',
        categories: ['animals', 'maritime'],
        terms: [],
        label: 'Dolphin',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e168',
    },
    {
        name: 'dong-sign',
        categories: ['money'],
        terms: [],
        label: 'Dong Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e169',
    },
    {
        name: 'donut',
        categories: ['food-beverage'],
        terms: [],
        label: 'Donut',
        aliases: {
            names: ['doughnut'],
            unicodes: {
                composite: ['1f369'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e406',
    },
    {
        name: 'door-closed',
        categories: ['household', 'security', 'travel-hotel'],
        terms: ['enter', 'exit', 'locked'],
        label: 'Door Closed',
        aliases: {
            unicodes: {
                composite: ['1f6aa'],
                secondary: ['10f52a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f52a',
    },
    {
        name: 'door-open',
        categories: ['household', 'security', 'travel-hotel'],
        terms: ['enter', 'exit', 'welcome'],
        label: 'Door Open',
        aliases: {
            unicodes: {
                secondary: ['10f52b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f52b',
    },
    {
        name: 'dove',
        categories: ['animals', 'charity', 'political', 'religion'],
        terms: ['bird', 'fauna', 'flying', 'peace', 'war'],
        label: 'Dove',
        aliases: {
            unicodes: {
                composite: ['1f54a'],
                secondary: ['10f4ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ba',
    },
    {
        name: 'down',
        categories: ['arrows'],
        terms: [],
        label: 'Down',
        aliases: {
            names: ['arrow-alt-down'],
            unicodes: {
                secondary: ['10f354'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f354',
    },
    {
        name: 'down-from-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Down From-dotted-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e407',
    },
    {
        name: 'down-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Down from line',
        aliases: {
            names: ['arrow-alt-from-top'],
            unicodes: {
                secondary: ['10f349'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f349',
    },
    {
        name: 'down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Down Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16a',
    },
    {
        name: 'down-left-and-up-right-to-center',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Down left and up right to center',
        aliases: {
            names: ['compress-alt'],
            unicodes: {
                secondary: ['10f422'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f422',
    },
    {
        name: 'down-long',
        categories: ['arrows'],
        terms: [],
        label: 'Down long',
        aliases: {
            names: ['long-arrow-alt-down'],
            unicodes: {
                secondary: ['10f309'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f309',
    },
    {
        name: 'down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Down Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16b',
    },
    {
        name: 'down-to-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Down to Bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e7',
    },
    {
        name: 'down-to-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Down To-dotted-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e408',
    },
    {
        name: 'down-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Down to line',
        aliases: {
            names: ['arrow-alt-to-bottom'],
            unicodes: {
                secondary: ['10f34a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f34a',
    },
    {
        name: 'download',
        categories: ['arrows', 'devices-hardware'],
        terms: ['export', 'hard drive', 'save', 'transfer'],
        label: 'Download',
        aliases: {
            unicodes: {
                secondary: ['10f019'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f019',
    },
    {
        name: 'dragon',
        categories: ['animals', 'gaming'],
        terms: ['Dungeons & Dragons', 'd&d', 'dnd', 'fantasy', 'fire', 'lizard', 'serpent'],
        label: 'Dragon',
        aliases: {
            unicodes: {
                composite: ['1f409'],
                secondary: ['10f6d5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d5',
    },
    {
        name: 'draw-circle',
        categories: ['design', 'maps'],
        terms: ['anchors', 'lines', 'object', 'render', 'shape'],
        label: 'Draw Circle',
        aliases: {
            unicodes: {
                secondary: ['10f5ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ed',
    },
    {
        name: 'draw-polygon',
        categories: ['design', 'maps'],
        terms: ['anchors', 'lines', 'object', 'render', 'shape'],
        label: 'Draw Polygon',
        aliases: {
            unicodes: {
                secondary: ['10f5ee'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ee',
    },
    {
        name: 'draw-square',
        categories: ['design', 'maps'],
        terms: ['anchors', 'lines', 'object', 'render', 'shape'],
        label: 'Draw Square',
        aliases: {
            unicodes: {
                secondary: ['10f5ef'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ef',
    },
    {
        name: 'dreidel',
        categories: ['gaming', 'holidays'],
        terms: ['clay', 'hanukkah', 'holiday', 'jewish', 'judaism', 'toy'],
        label: 'Dreidel',
        aliases: {
            unicodes: {
                secondary: ['10f792'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f792',
    },
    {
        name: 'drone',
        categories: ['film-video', 'science-fiction', 'transportation'],
        terms: ['aerial', 'big brother', 'surveillance', 'uav', 'unmanned', 'vehicle'],
        label: 'Drone',
        aliases: {
            unicodes: {
                secondary: ['10f85f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85f',
    },
    {
        name: 'drone-front',
        categories: ['film-video', 'science-fiction', 'transportation'],
        terms: [],
        label: 'Drone front',
        aliases: {
            names: ['drone-alt'],
            unicodes: {
                secondary: ['10f860'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f860',
    },
    {
        name: 'droplet',
        categories: ['design', 'humanitarian', 'maps', 'photos-images'],
        terms: [],
        label: 'Droplet',
        aliases: {
            names: ['tint'],
            unicodes: {
                composite: ['1f4a7'],
                secondary: ['10f043'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f043',
    },
    {
        name: 'droplet-degree',
        categories: ['weather'],
        terms: [],
        label: 'Droplet degree',
        aliases: {
            names: ['dewpoint'],
            unicodes: {
                secondary: ['10f748'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f748',
    },
    {
        name: 'droplet-percent',
        categories: ['weather'],
        terms: [],
        label: 'Droplet percent',
        aliases: {
            names: ['humidity'],
            unicodes: {
                secondary: ['10f750'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f750',
    },
    {
        name: 'droplet-slash',
        categories: ['design'],
        terms: [],
        label: 'Droplet slash',
        aliases: {
            names: ['tint-slash'],
            unicodes: {
                secondary: ['10f5c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c7',
    },
    {
        name: 'drum',
        categories: ['music-audio'],
        terms: ['instrument', 'music', 'percussion', 'snare', 'sound'],
        label: 'Drum',
        aliases: {
            unicodes: {
                composite: ['1f941'],
                secondary: ['10f569'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f569',
    },
    {
        name: 'drum-steelpan',
        categories: ['music-audio'],
        terms: ['calypso', 'instrument', 'music', 'percussion', 'reggae', 'snare', 'sound', 'steel', 'tropical'],
        label: 'Drum Steelpan',
        aliases: {
            unicodes: {
                secondary: ['10f56a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56a',
    },
    {
        name: 'drumstick',
        categories: ['food-beverage'],
        terms: ['bone', 'chicken', 'leg', 'meat', 'poultry', 'turkey'],
        label: 'Drumstick',
        aliases: {
            unicodes: {
                composite: ['1f357'],
                secondary: ['10f6d6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d6',
    },
    {
        name: 'drumstick-bite',
        categories: ['food-beverage'],
        terms: ['bone', 'chicken', 'leg', 'meat', 'poultry', 'turkey'],
        label: 'Drumstick with Bite Taken Out',
        aliases: {
            unicodes: {
                secondary: ['10f6d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d7',
    },
    {
        name: 'dryer',
        categories: ['household', 'travel-hotel'],
        terms: ['clean', 'clothes', 'laundromat', 'laundry', 'washing machine'],
        label: 'Dryer',
        aliases: {
            unicodes: {
                secondary: ['10f861'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f861',
    },
    {
        name: 'dryer-heat',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Dryer heat',
        aliases: {
            names: ['dryer-alt'],
            unicodes: {
                secondary: ['10f862'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f862',
    },
    {
        name: 'duck',
        categories: ['animals', 'childhood', 'maritime'],
        terms: ['bath', 'bird', 'fauna', 'quack', 'rubber'],
        label: 'Duck',
        aliases: {
            unicodes: {
                composite: ['1f986'],
                secondary: ['10f6d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d8',
    },
    {
        name: 'dumbbell',
        categories: ['sports-fitness', 'travel-hotel'],
        terms: ['exercise', 'gym', 'strength', 'weight', 'weight-lifting'],
        label: 'Dumbbell',
        aliases: {
            unicodes: {
                secondary: ['10f44b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f44b',
    },
    {
        name: 'dumpster',
        categories: ['construction'],
        terms: ['alley', 'bin', 'commercial', 'trash', 'waste'],
        label: 'Dumpster',
        aliases: {
            unicodes: {
                secondary: ['10f793'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f793',
    },
    {
        name: 'dumpster-fire',
        categories: ['construction', 'political'],
        terms: [
            'alley',
            'bin',
            'commercial',
            'danger',
            'dangerous',
            'euphemism',
            'flame',
            'heat',
            'hot',
            'trash',
            'waste',
        ],
        label: 'Dumpster Fire',
        aliases: {
            unicodes: {
                secondary: ['10f794'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f794',
    },
    {
        name: 'dungeon',
        categories: ['buildings', 'gaming', 'household', 'security'],
        terms: ['Dungeons & Dragons', 'building', 'd&d', 'dnd', 'door', 'entrance', 'fantasy', 'gate'],
        label: 'Dungeon',
        aliases: {
            unicodes: {
                secondary: ['10f6d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6d9',
    },
    {
        name: 'e',
        categories: ['alphabet'],
        terms: [],
        label: 'E',
        aliases: {
            unicodes: {
                composite: ['65'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '45',
    },
    {
        name: 'ear',
        categories: ['accessibility', 'medical-health', 'music-audio'],
        terms: ['head', 'hearing', 'listen', 'lobe'],
        label: 'Ear',
        aliases: {
            unicodes: {
                composite: ['1f442'],
                secondary: ['10f5f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f0',
    },
    {
        name: 'ear-deaf',
        categories: ['accessibility'],
        terms: [],
        label: 'Ear deaf',
        aliases: {
            names: ['deaf', 'deafness', 'hard-of-hearing'],
            unicodes: {
                secondary: ['10f2a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a4',
    },
    {
        name: 'ear-listen',
        categories: ['accessibility', 'communication'],
        terms: [],
        label: 'Ear listen',
        aliases: {
            names: ['assistive-listening-systems'],
            unicodes: {
                secondary: ['10f2a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a2',
    },
    {
        name: 'ear-muffs',
        categories: ['clothing-fashion'],
        terms: ['accessory', 'clothing', 'cold', 'head', 'puffy', 'soft', 'winter'],
        label: 'Ear Muffs',
        aliases: {
            unicodes: {
                secondary: ['10f795'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f795',
    },
    {
        name: 'earth-africa',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Earth Africa',
        aliases: {
            names: ['globe-africa'],
            unicodes: {
                composite: ['1f30d'],
                secondary: ['10f57c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57c',
    },
    {
        name: 'earth-americas',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Earth americas',
        aliases: {
            names: ['earth', 'earth-america', 'globe-americas'],
            unicodes: {
                composite: ['1f30e'],
                secondary: ['10f57d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57d',
    },
    {
        name: 'earth-asia',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Earth Asia',
        aliases: {
            names: ['globe-asia'],
            unicodes: {
                composite: ['1f30f'],
                secondary: ['10f57e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57e',
    },
    {
        name: 'earth-europe',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Earth Europe',
        aliases: {
            names: ['globe-europe'],
            unicodes: {
                secondary: ['10f7a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a2',
    },
    {
        name: 'earth-oceania',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Earth Oceania',
        aliases: {
            names: ['globe-oceania'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47b',
    },
    {
        name: 'eclipse',
        categories: ['astronomy', 'weather'],
        terms: ['lunar', 'moon', 'shadow', 'solar', 'sun'],
        label: 'Eclipse',
        aliases: {
            unicodes: {
                secondary: ['10f749'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f749',
    },
    {
        name: 'egg',
        categories: ['food-beverage'],
        terms: ['breakfast', 'chicken', 'easter', 'shell', 'yolk'],
        label: 'Egg',
        aliases: {
            unicodes: {
                composite: ['1f95a'],
                secondary: ['10f7fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7fb',
    },
    {
        name: 'egg-fried',
        categories: ['food-beverage'],
        terms: ['breakfast', 'chicken', 'yolk'],
        label: 'Fried Egg',
        aliases: {
            unicodes: {
                secondary: ['10f7fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7fc',
    },
    {
        name: 'eggplant',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Eggplant',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16c',
    },
    {
        name: 'eject',
        categories: ['media-playback'],
        terms: ['abort', 'cancel', 'cd', 'discharge'],
        label: 'eject',
        aliases: {
            unicodes: {
                composite: ['23cf'],
                secondary: ['10f052'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f052',
    },
    {
        name: 'elephant',
        categories: ['animals'],
        terms: ['animal', 'dumbo', 'fauna', 'mammal', 'pachyderm', 'trunk'],
        label: 'Elephant',
        aliases: {
            unicodes: {
                composite: ['1f418'],
                secondary: ['10f6da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6da',
    },
    {
        name: 'elevator',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'Elevator',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16d',
    },
    {
        name: 'ellipsis',
        categories: ['editing'],
        terms: [],
        label: 'Ellipsis',
        aliases: {
            names: ['ellipsis-h'],
            unicodes: {
                secondary: ['10f141'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f141',
    },
    {
        name: 'ellipsis-stroke',
        categories: ['editing'],
        terms: [],
        label: 'Ellipsis stroke',
        aliases: {
            names: ['ellipsis-h-alt'],
            unicodes: {
                secondary: ['10f39b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f39b',
    },
    {
        name: 'ellipsis-stroke-vertical',
        categories: ['editing'],
        terms: [],
        label: 'Ellipsis stroke vertical',
        aliases: {
            names: ['ellipsis-v-alt'],
            unicodes: {
                secondary: ['10f39c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f39c',
    },
    {
        name: 'ellipsis-vertical',
        categories: ['editing'],
        terms: [],
        label: 'Ellipsis vertical',
        aliases: {
            names: ['ellipsis-v'],
            unicodes: {
                secondary: ['10f142'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f142',
    },
    {
        name: 'empty-set',
        categories: ['mathematics'],
        terms: ['math', 'theory'],
        label: 'Empty Set',
        aliases: {
            unicodes: {
                composite: ['2205', 'd8'],
                secondary: ['10f656'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f656',
    },
    {
        name: 'engine',
        categories: ['automotive', 'devices-hardware'],
        terms: [],
        label: 'Engine',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16e',
    },
    {
        name: 'engine-warning',
        categories: ['alert', 'automotive'],
        terms: ['alert', 'automobile', 'car', 'mechanic', 'warning'],
        label: 'Engine Warning',
        aliases: {
            names: ['engine-exclamation'],
            unicodes: {
                secondary: ['10f5f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f2',
    },
    {
        name: 'envelope',
        categories: ['business', 'communication', 'humanitarian', 'social', 'writing'],
        terms: ['e-mail', 'email', 'letter', 'mail', 'message', 'notification', 'support'],
        label: 'Envelope',
        aliases: {
            unicodes: {
                composite: ['1f582', 'f003', '2709'],
                secondary: ['10f0e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e0',
    },
    {
        name: 'envelope-circle-check',
        categories: ['business', 'communication', 'humanitarian'],
        terms: [],
        label: 'Envelope Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e8',
    },
    {
        name: 'envelope-dot',
        categories: ['business', 'communication'],
        terms: [],
        label: 'Envelope dot',
        aliases: {
            names: ['envelope-badge'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e16f',
    },
    {
        name: 'envelope-open',
        categories: ['business', 'communication', 'writing'],
        terms: ['e-mail', 'email', 'letter', 'mail', 'message', 'notification', 'support'],
        label: 'Envelope Open',
        aliases: {
            unicodes: {
                composite: ['f2b7'],
                secondary: ['10f2b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2b6',
    },
    {
        name: 'envelope-open-dollar',
        categories: ['marketing'],
        terms: ['e-mail', 'email', 'letter', 'mail', 'message', 'money', 'notification', 'pay', 'transfer'],
        label: 'Envelope Open-dollar',
        aliases: {
            unicodes: {
                secondary: ['10f657'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f657',
    },
    {
        name: 'envelope-open-text',
        categories: ['marketing'],
        terms: ['e-mail', 'email', 'letter', 'mail', 'message', 'notification', 'support'],
        label: 'Envelope Open-text',
        aliases: {
            unicodes: {
                secondary: ['10f658'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f658',
    },
    {
        name: 'envelopes',
        categories: ['business', 'communication'],
        terms: [],
        label: 'Envelopes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e170',
    },
    {
        name: 'envelopes-bulk',
        categories: ['marketing'],
        terms: [],
        label: 'Envelopes bulk',
        aliases: {
            names: ['mail-bulk'],
            unicodes: {
                secondary: ['10f674'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f674',
    },
    {
        name: 'equals',
        categories: ['mathematics', 'punctuation-symbols'],
        terms: ['arithmetic', 'even', 'match', 'math'],
        label: 'Equals',
        aliases: {
            unicodes: {
                composite: ['f52c'],
                primary: ['f52c'],
                secondary: ['10f52c', '103d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3d',
    },
    {
        name: 'eraser',
        categories: ['business', 'design', 'writing'],
        terms: ['art', 'delete', 'remove', 'rubber'],
        label: 'eraser',
        aliases: {
            unicodes: {
                secondary: ['10f12d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f12d',
    },
    {
        name: 'escalator',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Escalator',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e171',
    },
    {
        name: 'ethereum',
        categories: ['money', 'shopping'],
        terms: [],
        label: 'Ethereum',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f42e',
    },
    {
        name: 'ethernet',
        categories: ['devices-hardware'],
        terms: ['cable', 'cat 5', 'cat 6', 'connection', 'hardware', 'internet', 'network', 'wired'],
        label: 'Ethernet',
        aliases: {
            unicodes: {
                secondary: ['10f796'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f796',
    },
    {
        name: 'euro-sign',
        categories: ['money'],
        terms: ['currency', 'dollar', 'exchange', 'money'],
        label: 'Euro Sign',
        aliases: {
            names: ['eur', 'euro'],
            unicodes: {
                composite: ['20ac'],
                secondary: ['10f153'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f153',
    },
    {
        name: 'exclamation',
        categories: ['alert', 'punctuation-symbols'],
        terms: ['alert', 'danger', 'error', 'important', 'notice', 'notification', 'notify', 'problem', 'warning'],
        label: 'exclamation',
        aliases: {
            unicodes: {
                composite: ['2755', '2757', 'f12a'],
                primary: ['f12a'],
                secondary: ['10f12a', '1021'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '21',
    },
    {
        name: 'expand',
        categories: ['media-playback'],
        terms: ['arrow', 'bigger', 'enlarge', 'resize'],
        label: 'Expand',
        aliases: {
            unicodes: {
                secondary: ['10f065'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f065',
    },
    {
        name: 'expand-wide',
        categories: ['media-playback'],
        terms: ['bigger', 'enlarge', 'resize'],
        label: 'Expand Wide',
        aliases: {
            unicodes: {
                secondary: ['10f320'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f320',
    },
    {
        name: 'explosion',
        categories: ['energy', 'humanitarian', 'science-fiction', 'security'],
        terms: [],
        label: 'Explosion',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4e9',
    },
    {
        name: 'eye',
        categories: ['accessibility', 'design', 'maps', 'medical-health', 'photos-images', 'security'],
        terms: ['look', 'optic', 'see', 'seen', 'show', 'sight', 'views', 'visible'],
        label: 'Eye',
        aliases: {
            unicodes: {
                composite: ['1f441'],
                secondary: ['10f06e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f06e',
    },
    {
        name: 'eye-dropper',
        categories: ['design', 'editing', 'medical-health', 'photos-images', 'science'],
        terms: ['beaker', 'clone', 'color', 'copy', 'eyedropper', 'pipette'],
        label: 'Eye Dropper',
        aliases: {
            names: ['eye-dropper-empty', 'eyedropper'],
            unicodes: {
                secondary: ['10f1fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1fb',
    },
    {
        name: 'eye-dropper-full',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Eye Dropper-full',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e172',
    },
    {
        name: 'eye-dropper-half',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Eye Dropper-half',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e173',
    },
    {
        name: 'eye-evil',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'd&d', 'dnd', 'fantasy', 'sauron', 'see', 'seen', 'show', 'sight'],
        label: 'Evil Eye',
        aliases: {
            unicodes: {
                secondary: ['10f6db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6db',
    },
    {
        name: 'eye-low-vision',
        categories: ['accessibility', 'maps'],
        terms: [],
        label: 'Eye low vision',
        aliases: {
            names: ['low-vision'],
            unicodes: {
                secondary: ['10f2a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a8',
    },
    {
        name: 'eye-slash',
        categories: ['design', 'editing', 'maps', 'photos-images', 'security'],
        terms: ['blind', 'hide', 'show', 'toggle', 'unseen', 'views', 'visible', 'visiblity'],
        label: 'Eye Slash',
        aliases: {
            unicodes: {
                secondary: ['10f070'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f070',
    },
    {
        name: 'eyes',
        categories: ['medical-health'],
        terms: [],
        label: 'Eyes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e367',
    },
    {
        name: 'f',
        categories: ['alphabet'],
        terms: [],
        label: 'F',
        aliases: {
            unicodes: {
                composite: ['66'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '46',
    },
    {
        name: 'face-angry',
        categories: ['emoji'],
        terms: [],
        label: 'Face angry',
        aliases: {
            names: ['angry'],
            unicodes: {
                composite: ['1f620'],
                secondary: ['10f556'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f556',
    },
    {
        name: 'face-angry-horns',
        categories: ['emoji'],
        terms: [],
        label: 'Face Angry Horns',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e368',
    },
    {
        name: 'face-anguished',
        categories: ['emoji'],
        terms: [],
        label: 'Face Anguished',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e369',
    },
    {
        name: 'face-anxious-sweat',
        categories: ['emoji'],
        terms: [],
        label: 'Face Anxious Sweat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36a',
    },
    {
        name: 'face-astonished',
        categories: ['emoji'],
        terms: [],
        label: 'Face Astonished',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36b',
    },
    {
        name: 'face-awesome',
        categories: ['communication', 'emoji'],
        terms: [],
        label: 'Face Awesome',
        aliases: {
            names: ['gave-dandy'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e409',
    },
    {
        name: 'face-beam-hand-over-mouth',
        categories: ['emoji'],
        terms: [],
        label: 'Face Beam Hand Over Mouth',
        aliases: {
            unicodes: {
                composite: ['1f92d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47c',
    },
    {
        name: 'face-clouds',
        categories: ['emoji'],
        terms: [],
        label: 'Face in Clouds',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47d',
    },
    {
        name: 'face-confounded',
        categories: ['emoji'],
        terms: [],
        label: 'Face Confounded',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36c',
    },
    {
        name: 'face-confused',
        categories: ['emoji'],
        terms: [],
        label: 'Face Confused',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36d',
    },
    {
        name: 'face-cowboy-hat',
        categories: ['emoji'],
        terms: [],
        label: 'Face Cowboy Hat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36e',
    },
    {
        name: 'face-diagonal-mouth',
        categories: ['emoji'],
        terms: [],
        label: 'Face Diagonal Mouth',
        aliases: {
            unicodes: {
                composite: ['1fae4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47e',
    },
    {
        name: 'face-disappointed',
        categories: ['emoji'],
        terms: [],
        label: 'Face Disappointed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e36f',
    },
    {
        name: 'face-disguise',
        categories: ['emoji'],
        terms: [],
        label: 'Face Disguise',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e370',
    },
    {
        name: 'face-dizzy',
        categories: ['emoji'],
        terms: [],
        label: 'Face dizzy',
        aliases: {
            names: ['dizzy'],
            unicodes: {
                secondary: ['10f567'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f567',
    },
    {
        name: 'face-dotted',
        categories: ['emoji'],
        terms: [],
        label: 'Face Dotted',
        aliases: {
            unicodes: {
                composite: ['1fae5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e47f',
    },
    {
        name: 'face-downcast-sweat',
        categories: ['emoji'],
        terms: [],
        label: 'Face Downcast Sweat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e371',
    },
    {
        name: 'face-drooling',
        categories: ['emoji'],
        terms: [],
        label: 'Face Drooling',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e372',
    },
    {
        name: 'face-exhaling',
        categories: ['emoji'],
        terms: [],
        label: 'Face Exhaling',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e480',
    },
    {
        name: 'face-explode',
        categories: ['emoji'],
        terms: [],
        label: 'Face Explode',
        aliases: {
            names: ['exploding-head'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2fe',
    },
    {
        name: 'face-expressionless',
        categories: ['emoji'],
        terms: [],
        label: 'Face Expressionless',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e373',
    },
    {
        name: 'face-eyes-xmarks',
        categories: ['emoji'],
        terms: [],
        label: 'Face Eyes Xmarks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e374',
    },
    {
        name: 'face-fearful',
        categories: ['emoji'],
        terms: [],
        label: 'Face Fearful',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e375',
    },
    {
        name: 'face-flushed',
        categories: ['emoji'],
        terms: [],
        label: 'Face flushed',
        aliases: {
            names: ['flushed'],
            unicodes: {
                composite: ['1f633'],
                secondary: ['10f579'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f579',
    },
    {
        name: 'face-frown',
        categories: ['communication', 'emoji', 'users-people'],
        terms: [],
        label: 'Face frown',
        aliases: {
            names: ['frown'],
            unicodes: {
                composite: ['2639'],
                secondary: ['10f119'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f119',
    },
    {
        name: 'face-frown-open',
        categories: ['emoji'],
        terms: [],
        label: 'Face frown open',
        aliases: {
            names: ['frown-open'],
            unicodes: {
                composite: ['1f626'],
                secondary: ['10f57a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57a',
    },
    {
        name: 'face-frown-slight',
        categories: ['emoji'],
        terms: [],
        label: 'Face Frown Slight',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e376',
    },
    {
        name: 'face-glasses',
        categories: ['emoji'],
        terms: [],
        label: 'Face Glasses and Smile',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e377',
    },
    {
        name: 'face-grimace',
        categories: ['emoji'],
        terms: [],
        label: 'Face grimace',
        aliases: {
            names: ['grimace'],
            unicodes: {
                composite: ['1f62c'],
                secondary: ['10f57f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57f',
    },
    {
        name: 'face-grin',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin',
        aliases: {
            names: ['grin'],
            unicodes: {
                composite: ['1f600'],
                secondary: ['10f580'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f580',
    },
    {
        name: 'face-grin-beam',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin beam',
        aliases: {
            names: ['grin-beam'],
            unicodes: {
                composite: ['1f604'],
                secondary: ['10f582'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f582',
    },
    {
        name: 'face-grin-beam-sweat',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin beam sweat',
        aliases: {
            names: ['grin-beam-sweat'],
            unicodes: {
                composite: ['1f605'],
                secondary: ['10f583'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f583',
    },
    {
        name: 'face-grin-hearts',
        categories: ['emoji', 'holidays'],
        terms: [],
        label: 'Face grin hearts',
        aliases: {
            names: ['grin-hearts'],
            unicodes: {
                composite: ['1f60d'],
                secondary: ['10f584'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f584',
    },
    {
        name: 'face-grin-squint',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin squint',
        aliases: {
            names: ['grin-squint'],
            unicodes: {
                composite: ['1f606'],
                secondary: ['10f585'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f585',
    },
    {
        name: 'face-grin-squint-tears',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin squint tears',
        aliases: {
            names: ['grin-squint-tears'],
            unicodes: {
                composite: ['1f923'],
                secondary: ['10f586'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f586',
    },
    {
        name: 'face-grin-stars',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin stars',
        aliases: {
            names: ['grin-stars'],
            unicodes: {
                composite: ['1f929'],
                secondary: ['10f587'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f587',
    },
    {
        name: 'face-grin-tears',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin tears',
        aliases: {
            names: ['grin-tears'],
            unicodes: {
                composite: ['1f602'],
                secondary: ['10f588'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f588',
    },
    {
        name: 'face-grin-tongue',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin tongue',
        aliases: {
            names: ['grin-tongue'],
            unicodes: {
                composite: ['1f61b'],
                secondary: ['10f589'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f589',
    },
    {
        name: 'face-grin-tongue-squint',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin tongue squint',
        aliases: {
            names: ['grin-tongue-squint'],
            unicodes: {
                composite: ['1f61d'],
                secondary: ['10f58a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58a',
    },
    {
        name: 'face-grin-tongue-wink',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin tongue wink',
        aliases: {
            names: ['grin-tongue-wink'],
            unicodes: {
                composite: ['1f61c'],
                secondary: ['10f58b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58b',
    },
    {
        name: 'face-grin-wide',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin wide',
        aliases: {
            names: ['grin-alt'],
            unicodes: {
                composite: ['1f603'],
                secondary: ['10f581'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f581',
    },
    {
        name: 'face-grin-wink',
        categories: ['emoji'],
        terms: [],
        label: 'Face grin wink',
        aliases: {
            names: ['grin-wink'],
            unicodes: {
                secondary: ['10f58c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58c',
    },
    {
        name: 'face-hand-over-mouth',
        categories: ['emoji'],
        terms: [],
        label: 'Face with Hand over Mouth',
        aliases: {
            unicodes: {
                composite: ['1fae2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e378',
    },
    {
        name: 'face-hand-peeking',
        categories: ['emoji'],
        terms: [],
        label: 'Face Hand Peeking',
        aliases: {
            unicodes: {
                composite: ['1fae3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e481',
    },
    {
        name: 'face-hand-yawn',
        categories: ['emoji'],
        terms: [],
        label: 'Face Hand Yawn',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e379',
    },
    {
        name: 'face-head-bandage',
        categories: ['emoji', 'medical-health'],
        terms: [],
        label: 'Face Head Bandage',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37a',
    },
    {
        name: 'face-holding-back-tears',
        categories: ['emoji'],
        terms: [],
        label: 'Face Holding Back Tears',
        aliases: {
            unicodes: {
                composite: ['1f979'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e482',
    },
    {
        name: 'face-hushed',
        categories: ['emoji'],
        terms: [],
        label: 'Face Hushed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37b',
    },
    {
        name: 'face-icicles',
        categories: ['emoji'],
        terms: [],
        label: 'Face Icicles',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37c',
    },
    {
        name: 'face-kiss',
        categories: ['emoji'],
        terms: [],
        label: 'Face kiss',
        aliases: {
            names: ['kiss'],
            unicodes: {
                composite: ['1f617'],
                secondary: ['10f596'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f596',
    },
    {
        name: 'face-kiss-beam',
        categories: ['emoji'],
        terms: [],
        label: 'Face Kiss Beam',
        aliases: {
            names: ['kiss-beam'],
            unicodes: {
                composite: ['1f619'],
                secondary: ['10f597'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f597',
    },
    {
        name: 'face-kiss-closed-eyes',
        categories: ['emoji'],
        terms: [],
        label: 'Face Kiss Closed Eyes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37d',
    },
    {
        name: 'face-kiss-wink-heart',
        categories: ['emoji', 'holidays'],
        terms: [],
        label: 'Face Kiss Wink Heart',
        aliases: {
            names: ['kiss-wink-heart'],
            unicodes: {
                composite: ['1f618'],
                secondary: ['10f598'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f598',
    },
    {
        name: 'face-laugh',
        categories: ['emoji'],
        terms: [],
        label: 'Face Laugh',
        aliases: {
            names: ['laugh'],
            unicodes: {
                secondary: ['10f599'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f599',
    },
    {
        name: 'face-laugh-beam',
        categories: ['emoji'],
        terms: [],
        label: 'Face Laugh Beam',
        aliases: {
            names: ['laugh-beam'],
            unicodes: {
                composite: ['1f601'],
                secondary: ['10f59a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f59a',
    },
    {
        name: 'face-laugh-squint',
        categories: ['emoji'],
        terms: [],
        label: 'Face Laugh Squint',
        aliases: {
            names: ['laugh-squint'],
            unicodes: {
                secondary: ['10f59b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f59b',
    },
    {
        name: 'face-laugh-wink',
        categories: ['emoji'],
        terms: [],
        label: 'Face Laugh Wink',
        aliases: {
            names: ['laugh-wink'],
            unicodes: {
                secondary: ['10f59c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f59c',
    },
    {
        name: 'face-lying',
        categories: ['emoji'],
        terms: [],
        label: 'Face Lying',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37e',
    },
    {
        name: 'face-mask',
        categories: ['emoji', 'medical-health'],
        terms: [],
        label: 'Face Mask',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e37f',
    },
    {
        name: 'face-meh',
        categories: ['communication', 'emoji', 'users-people'],
        terms: [],
        label: 'Face meh',
        aliases: {
            names: ['meh'],
            unicodes: {
                composite: ['1f610'],
                secondary: ['10f11a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f11a',
    },
    {
        name: 'face-meh-blank',
        categories: ['emoji'],
        terms: [],
        label: 'Face Meh Blank',
        aliases: {
            names: ['meh-blank'],
            unicodes: {
                composite: ['1f636'],
                secondary: ['10f5a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a4',
    },
    {
        name: 'face-melting',
        categories: ['emoji'],
        terms: [],
        label: 'Face Melting',
        aliases: {
            unicodes: {
                composite: ['1fae0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e483',
    },
    {
        name: 'face-monocle',
        categories: ['emoji'],
        terms: [],
        label: 'Face Monocle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e380',
    },
    {
        name: 'face-nauseated',
        categories: ['emoji'],
        terms: [],
        label: 'Face Nauseated',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e381',
    },
    {
        name: 'face-nose-steam',
        categories: ['emoji'],
        terms: [],
        label: 'Face Nose Steam',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e382',
    },
    {
        name: 'face-party',
        categories: ['emoji'],
        terms: [],
        label: 'Face Party',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e383',
    },
    {
        name: 'face-pensive',
        categories: ['emoji'],
        terms: [],
        label: 'Face Pensive',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e384',
    },
    {
        name: 'face-persevering',
        categories: ['emoji'],
        terms: [],
        label: 'Face Persevering',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e385',
    },
    {
        name: 'face-pleading',
        categories: ['emoji'],
        terms: [],
        label: 'Face Pleading',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e386',
    },
    {
        name: 'face-pouting',
        categories: ['emoji'],
        terms: [],
        label: 'Face Pouting',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e387',
    },
    {
        name: 'face-raised-eyebrow',
        categories: ['emoji'],
        terms: [],
        label: 'Face Raised Eyebrow',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e388',
    },
    {
        name: 'face-relieved',
        categories: ['emoji'],
        terms: [],
        label: 'Face Relieved',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e389',
    },
    {
        name: 'face-rolling-eyes',
        categories: ['emoji'],
        terms: [],
        label: 'Face Rolling Eyes',
        aliases: {
            names: ['meh-rolling-eyes'],
            unicodes: {
                composite: ['1f644'],
                secondary: ['10f5a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a5',
    },
    {
        name: 'face-sad-cry',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sad Cry',
        aliases: {
            names: ['sad-cry'],
            unicodes: {
                composite: ['1f62d'],
                secondary: ['10f5b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b3',
    },
    {
        name: 'face-sad-sweat',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sad Sweat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38a',
    },
    {
        name: 'face-sad-tear',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sad Tear',
        aliases: {
            names: ['sad-tear'],
            unicodes: {
                composite: ['1f622'],
                secondary: ['10f5b4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b4',
    },
    {
        name: 'face-saluting',
        categories: ['emoji'],
        terms: [],
        label: 'Face Saluting',
        aliases: {
            unicodes: {
                composite: ['1fae1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e484',
    },
    {
        name: 'face-scream',
        categories: ['emoji'],
        terms: [],
        label: 'Face Scream',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38b',
    },
    {
        name: 'face-shush',
        categories: ['emoji'],
        terms: [],
        label: 'Face Shush',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38c',
    },
    {
        name: 'face-sleeping',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sleeping',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38d',
    },
    {
        name: 'face-sleepy',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sleepy',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38e',
    },
    {
        name: 'face-smile',
        categories: ['communication', 'emoji', 'users-people'],
        terms: [],
        label: 'Face Smile',
        aliases: {
            names: ['smile'],
            unicodes: {
                composite: ['1f642'],
                secondary: ['10f118'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f118',
    },
    {
        name: 'face-smile-beam',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Beam',
        aliases: {
            names: ['smile-beam'],
            unicodes: {
                composite: ['1f60a'],
                secondary: ['10f5b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b8',
    },
    {
        name: 'face-smile-halo',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Halo',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e38f',
    },
    {
        name: 'face-smile-hearts',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Hearts',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e390',
    },
    {
        name: 'face-smile-horns',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Horns',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e391',
    },
    {
        name: 'face-smile-plus',
        categories: ['communication'],
        terms: [],
        label: 'Face Smile Plus',
        aliases: {
            names: ['smile-plus'],
            unicodes: {
                secondary: ['10f5b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b9',
    },
    {
        name: 'face-smile-relaxed',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Relaxed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e392',
    },
    {
        name: 'face-smile-tear',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Tear',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e393',
    },
    {
        name: 'face-smile-tongue',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Tongue',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e394',
    },
    {
        name: 'face-smile-upside-down',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Upside Down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e395',
    },
    {
        name: 'face-smile-wink',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smile Wink',
        aliases: {
            names: ['smile-wink'],
            unicodes: {
                composite: ['1f609'],
                secondary: ['10f4da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4da',
    },
    {
        name: 'face-smiling-hands',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smiling Hands',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e396',
    },
    {
        name: 'face-smirking',
        categories: ['emoji'],
        terms: [],
        label: 'Face Smirking',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e397',
    },
    {
        name: 'face-spiral-eyes',
        categories: ['emoji'],
        terms: [],
        label: 'Face Spiral Eyes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e485',
    },
    {
        name: 'face-sunglasses',
        categories: ['emoji'],
        terms: [],
        label: 'Face Sunglasses',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e398',
    },
    {
        name: 'face-surprise',
        categories: ['emoji'],
        terms: [],
        label: 'Face Surprise',
        aliases: {
            names: ['surprise'],
            unicodes: {
                composite: ['1f62e'],
                secondary: ['10f5c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c2',
    },
    {
        name: 'face-swear',
        categories: ['emoji'],
        terms: [],
        label: 'Face Swear',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e399',
    },
    {
        name: 'face-thermometer',
        categories: ['emoji', 'medical-health'],
        terms: [],
        label: 'Face Thermometer',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39a',
    },
    {
        name: 'face-thinking',
        categories: ['emoji'],
        terms: [],
        label: 'Face Thinking',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39b',
    },
    {
        name: 'face-tired',
        categories: ['emoji'],
        terms: [],
        label: 'Face Tired',
        aliases: {
            names: ['tired'],
            unicodes: {
                composite: ['1f62b'],
                secondary: ['10f5c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c8',
    },
    {
        name: 'face-tissue',
        categories: ['emoji'],
        terms: [],
        label: 'Face Tissue',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39c',
    },
    {
        name: 'face-tongue-money',
        categories: ['emoji'],
        terms: [],
        label: 'Face Tongue Money',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39d',
    },
    {
        name: 'face-tongue-sweat',
        categories: ['emoji'],
        terms: [],
        label: 'Face Tongue Sweat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39e',
    },
    {
        name: 'face-unamused',
        categories: ['emoji'],
        terms: [],
        label: 'Face Unamused',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e39f',
    },
    {
        name: 'face-viewfinder',
        categories: ['film-video', 'photos-images', 'security'],
        terms: [],
        label: 'Face Viewfinder',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ff',
    },
    {
        name: 'face-vomit',
        categories: ['emoji', 'medical-health'],
        terms: [],
        label: 'Face Vomit',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a0',
    },
    {
        name: 'face-weary',
        categories: ['emoji'],
        terms: [],
        label: 'Face Weary',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a1',
    },
    {
        name: 'face-woozy',
        categories: ['emoji'],
        terms: [],
        label: 'Face Woozy',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a2',
    },
    {
        name: 'face-worried',
        categories: ['emoji'],
        terms: [],
        label: 'Face Worried',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a3',
    },
    {
        name: 'face-zany',
        categories: ['emoji'],
        terms: [],
        label: 'Face Zany',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a4',
    },
    {
        name: 'face-zipper',
        categories: ['emoji'],
        terms: [],
        label: 'Face Zipper',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a5',
    },
    {
        name: 'falafel',
        categories: ['food-beverage'],
        terms: [],
        label: 'Falafel',
        aliases: {
            unicodes: {
                composite: ['1f9c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e40a',
    },
    {
        name: 'family',
        categories: ['childhood', 'users-people'],
        terms: [],
        label: 'Family',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e300',
    },
    {
        name: 'family-dress',
        categories: ['childhood', 'users-people'],
        terms: [],
        label: 'Family Dress',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e301',
    },
    {
        name: 'family-pants',
        categories: ['childhood', 'users-people'],
        terms: [],
        label: 'Family Pants',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e302',
    },
    {
        name: 'fan',
        categories: ['energy', 'household', 'spinners'],
        terms: ['ac', 'air conditioning', 'blade', 'blower', 'cool', 'hot'],
        label: 'Fan',
        aliases: {
            unicodes: {
                secondary: ['10f863'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f863',
    },
    {
        name: 'fan-table',
        categories: ['household', 'travel-hotel'],
        terms: ['ac', 'air conditioning', 'blade', 'blower', 'cool', 'hot', 'oscillate'],
        label: 'Fan Table',
        aliases: {
            unicodes: {
                secondary: ['10e004'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e004',
    },
    {
        name: 'fantasy-flight-games',
        categories: ['gaming'],
        terms: [],
        label: 'Fantasy Flight-games',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f6dc',
    },
    {
        name: 'farm',
        categories: ['buildings'],
        terms: ['agriculture', 'barn', 'dairy', 'farming', 'ranch', 'silo'],
        label: 'Farm',
        aliases: {
            names: ['barn-silo'],
            unicodes: {
                secondary: ['10f864'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f864',
    },
    {
        name: 'faucet',
        categories: ['camping', 'household'],
        terms: ['covid-19', 'drip', 'house', 'hygiene', 'kitchen', 'sink', 'water'],
        label: 'Faucet',
        aliases: {
            unicodes: {
                secondary: ['10e005'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e005',
    },
    {
        name: 'faucet-drip',
        categories: ['camping', 'household', 'humanitarian'],
        terms: ['drip', 'house', 'kitchen', 'sink', 'water'],
        label: 'Faucet Drip',
        aliases: {
            unicodes: {
                composite: ['1f6b0'],
                secondary: ['10e006'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e006',
    },
    {
        name: 'fax',
        categories: ['business', 'communication', 'devices-hardware', 'humanitarian'],
        terms: ['business', 'communicate', 'copy', 'facsimile', 'send'],
        label: 'Fax',
        aliases: {
            unicodes: {
                composite: ['1f5b7', '1f4e0'],
                secondary: ['10f1ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ac',
    },
    {
        name: 'feather',
        categories: ['animals', 'nature'],
        terms: ['bird', 'light', 'plucked', 'quill', 'write'],
        label: 'Feather',
        aliases: {
            unicodes: {
                composite: ['1fab6'],
                secondary: ['10f52d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f52d',
    },
    {
        name: 'feather-pointed',
        categories: ['animals', 'nature'],
        terms: [],
        label: 'Feather pointed',
        aliases: {
            names: ['feather-alt'],
            unicodes: {
                secondary: ['10f56b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56b',
    },
    {
        name: 'fence',
        categories: ['buildings'],
        terms: [],
        label: 'Fence',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e303',
    },
    {
        name: 'ferris-wheel',
        categories: ['buildings', 'childhood'],
        terms: [],
        label: 'Ferris Wheel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e174',
    },
    {
        name: 'ferry',
        categories: ['humanitarian', 'logistics', 'maritime', 'transportation'],
        terms: [],
        label: 'Ferry',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ea',
    },
    {
        name: 'field-hockey-stick-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Field hockey stick ball',
        aliases: {
            names: ['field-hockey'],
            unicodes: {
                composite: ['1f3d1'],
                secondary: ['10f44c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f44c',
    },
    {
        name: 'file',
        categories: ['business', 'coding', 'files', 'humanitarian', 'shapes', 'writing'],
        terms: ['document', 'new', 'page', 'pdf', 'resume'],
        label: 'File',
        aliases: {
            unicodes: {
                composite: ['1f5cb', 'f016', '1f4c4'],
                secondary: ['10f15b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f15b',
    },
    {
        name: 'file-arrow-down',
        categories: ['files'],
        terms: [],
        label: 'File arrow down',
        aliases: {
            names: ['file-download'],
            unicodes: {
                secondary: ['10f56d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56d',
    },
    {
        name: 'file-arrow-up',
        categories: ['files'],
        terms: [],
        label: 'File arrow up',
        aliases: {
            names: ['file-upload'],
            unicodes: {
                secondary: ['10f574'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f574',
    },
    {
        name: 'file-audio',
        categories: ['files', 'film-video', 'music-audio'],
        terms: ['document', 'mp3', 'music', 'page', 'play', 'sound'],
        label: 'Audio File',
        aliases: {
            unicodes: {
                secondary: ['10f1c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c7',
    },
    {
        name: 'file-binary',
        categories: ['files'],
        terms: [],
        label: 'File Binary',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e175',
    },
    {
        name: 'file-certificate',
        categories: ['education', 'security'],
        terms: ['award', 'diploma', 'document', 'license'],
        label: 'File Certificate',
        aliases: {
            names: ['file-award'],
            unicodes: {
                secondary: ['10f5f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f3',
    },
    {
        name: 'file-chart-column',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: [],
        label: 'File chart column',
        aliases: {
            names: ['file-chart-line'],
            unicodes: {
                secondary: ['10f659'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f659',
    },
    {
        name: 'file-chart-pie',
        categories: ['business', 'charts-diagrams', 'money'],
        terms: ['analytics', 'data', 'document', 'projection', 'report'],
        label: 'Pie Chart File',
        aliases: {
            unicodes: {
                secondary: ['10f65a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65a',
    },
    {
        name: 'file-check',
        categories: ['files'],
        terms: ['accept', 'agree', 'confirm', 'document', 'done', 'ok', 'select', 'success', 'synced', 'tick', 'todo'],
        label: 'Check File',
        aliases: {
            unicodes: {
                secondary: ['10f316'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f316',
    },
    {
        name: 'file-circle-check',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File Circle-Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e493',
    },
    {
        name: 'file-circle-exclamation',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4eb',
    },
    {
        name: 'file-circle-info',
        categories: ['business', 'files'],
        terms: [],
        label: 'File Circle-Info',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ec',
    },
    {
        name: 'file-circle-minus',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File Circle-minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ed',
    },
    {
        name: 'file-circle-plus',
        categories: ['business', 'files', 'humanitarian'],
        terms: [],
        label: 'File Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ee',
    },
    {
        name: 'file-circle-question',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File Circle-question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ef',
    },
    {
        name: 'file-circle-xmark',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e494',
    },
    {
        name: 'file-code',
        categories: ['coding', 'files'],
        terms: ['css', 'development', 'document', 'html'],
        label: 'Code File',
        aliases: {
            unicodes: {
                secondary: ['10f1c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c9',
    },
    {
        name: 'file-contract',
        categories: ['security'],
        terms: ['agreement', 'binding', 'document', 'legal', 'signature'],
        label: 'File Contract',
        aliases: {
            unicodes: {
                secondary: ['10f56c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56c',
    },
    {
        name: 'file-csv',
        categories: ['files', 'humanitarian'],
        terms: ['document', 'excel', 'numbers', 'spreadsheets', 'table'],
        label: 'File CSV',
        aliases: {
            unicodes: {
                secondary: ['10f6dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6dd',
    },
    {
        name: 'file-dashed-line',
        categories: ['text-formatting'],
        terms: [],
        label: 'File dashed line',
        aliases: {
            names: ['page-break'],
            unicodes: {
                secondary: ['10f877'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f877',
    },
    {
        name: 'file-excel',
        categories: ['files'],
        terms: ['csv', 'document', 'numbers', 'spreadsheets', 'table'],
        label: 'Excel File',
        aliases: {
            unicodes: {
                secondary: ['10f1c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c3',
    },
    {
        name: 'file-exclamation',
        categories: ['alert', 'files'],
        terms: ['alert', 'document', 'important', 'page'],
        label: 'Exclamation File',
        aliases: {
            unicodes: {
                secondary: ['10f31a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f31a',
    },
    {
        name: 'file-export',
        categories: ['files'],
        terms: ['download', 'save'],
        label: 'File Export',
        aliases: {
            names: ['arrow-right-from-file'],
            unicodes: {
                secondary: ['10f56e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56e',
    },
    {
        name: 'file-heart',
        categories: ['files'],
        terms: [],
        label: 'File Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e176',
    },
    {
        name: 'file-image',
        categories: ['files', 'photos-images'],
        terms: ['document', 'image', 'jpg', 'photo', 'png'],
        label: 'Image File',
        aliases: {
            unicodes: {
                composite: ['1f5bb'],
                secondary: ['10f1c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c5',
    },
    {
        name: 'file-import',
        categories: ['files'],
        terms: ['copy', 'document', 'send', 'upload'],
        label: 'File Import',
        aliases: {
            names: ['arrow-right-to-file'],
            unicodes: {
                secondary: ['10f56f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f56f',
    },
    {
        name: 'file-invoice',
        categories: ['money'],
        terms: ['account', 'bill', 'charge', 'document', 'payment', 'receipt'],
        label: 'File Invoice',
        aliases: {
            unicodes: {
                secondary: ['10f570'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f570',
    },
    {
        name: 'file-invoice-dollar',
        categories: ['money'],
        terms: ['$', 'account', 'bill', 'charge', 'document', 'dollar-sign', 'money', 'payment', 'receipt', 'usd'],
        label: 'File Invoice with US Dollar',
        aliases: {
            unicodes: {
                secondary: ['10f571'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f571',
    },
    {
        name: 'file-lines',
        categories: ['business', 'coding', 'files', 'writing'],
        terms: [],
        label: 'File lines',
        aliases: {
            names: ['file-alt', 'file-text'],
            unicodes: {
                composite: ['1f5ce', 'f0f6', '1f5b9'],
                secondary: ['10f15c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f15c',
    },
    {
        name: 'file-lock',
        categories: ['files', 'security'],
        terms: [],
        label: 'File Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a6',
    },
    {
        name: 'file-magnifying-glass',
        categories: ['files'],
        terms: [],
        label: 'File magnifying glass',
        aliases: {
            names: ['file-search'],
            unicodes: {
                secondary: ['10f865'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f865',
    },
    {
        name: 'file-medical',
        categories: ['medical-health'],
        terms: ['document', 'health', 'history', 'prescription', 'record'],
        label: 'Medical File',
        aliases: {
            unicodes: {
                secondary: ['10f477'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f477',
    },
    {
        name: 'file-minus',
        categories: ['files'],
        terms: ['delete', 'document', 'negative', 'remove'],
        label: 'Minus File',
        aliases: {
            unicodes: {
                secondary: ['10f318'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f318',
    },
    {
        name: 'file-music',
        categories: ['files', 'music-audio'],
        terms: ['aac', 'aif', 'audio', 'midi', 'mp3', 'ogg', 'sheet music', 'wav', 'wma'],
        label: 'File Music',
        aliases: {
            unicodes: {
                secondary: ['10f8b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b6',
    },
    {
        name: 'file-pdf',
        categories: ['files', 'humanitarian'],
        terms: ['acrobat', 'document', 'preview', 'save'],
        label: 'PDF File',
        aliases: {
            unicodes: {
                secondary: ['10f1c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c1',
    },
    {
        name: 'file-pen',
        categories: ['files', 'humanitarian'],
        terms: [],
        label: 'File pen',
        aliases: {
            names: ['file-edit'],
            unicodes: {
                composite: ['1f4dd'],
                secondary: ['10f31c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f31c',
    },
    {
        name: 'file-plus',
        categories: ['files'],
        terms: ['add', 'create', 'document', 'medical', 'new', 'positive'],
        label: 'Plus File',
        aliases: {
            unicodes: {
                secondary: ['10f319'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f319',
    },
    {
        name: 'file-plus-minus',
        categories: ['files'],
        terms: [],
        label: 'File Plus Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e177',
    },
    {
        name: 'file-powerpoint',
        categories: ['files'],
        terms: ['display', 'document', 'keynote', 'presentation'],
        label: 'Powerpoint File',
        aliases: {
            unicodes: {
                secondary: ['10f1c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c4',
    },
    {
        name: 'file-prescription',
        categories: ['medical-health'],
        terms: ['document', 'drugs', 'medical', 'medicine', 'rx'],
        label: 'File Prescription',
        aliases: {
            unicodes: {
                secondary: ['10f572'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f572',
    },
    {
        name: 'file-shield',
        categories: ['files', 'humanitarian', 'security'],
        terms: [],
        label: 'File Shield',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f0',
    },
    {
        name: 'file-signature',
        categories: ['security'],
        terms: ['John Hancock', 'contract', 'document', 'name'],
        label: 'File Signature',
        aliases: {
            unicodes: {
                secondary: ['10f573'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f573',
    },
    {
        name: 'file-slash',
        categories: ['files'],
        terms: [],
        label: 'File Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a7',
    },
    {
        name: 'file-spreadsheet',
        categories: ['business'],
        terms: ['csv', 'document', 'excel', 'numbers', 'sheets', 'xls'],
        label: 'Spreadsheet File',
        aliases: {
            unicodes: {
                secondary: ['10f65b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65b',
    },
    {
        name: 'file-user',
        categories: ['business', 'users-people'],
        terms: ['account', 'document', 'page', 'personnel', 'profile', 'resume'],
        label: 'User File',
        aliases: {
            unicodes: {
                secondary: ['10f65c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65c',
    },
    {
        name: 'file-video',
        categories: ['files', 'film-video'],
        terms: ['document', 'm4v', 'movie', 'mp4', 'play'],
        label: 'Video File',
        aliases: {
            unicodes: {
                secondary: ['10f1c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c8',
    },
    {
        name: 'file-waveform',
        categories: ['medical-health'],
        terms: [],
        label: 'File waveform',
        aliases: {
            names: ['file-medical-alt'],
            unicodes: {
                secondary: ['10f478'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f478',
    },
    {
        name: 'file-word',
        categories: ['files'],
        terms: ['document', 'edit', 'page', 'text', 'writing'],
        label: 'Word File',
        aliases: {
            unicodes: {
                secondary: ['10f1c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c2',
    },
    {
        name: 'file-xmark',
        categories: ['files'],
        terms: [],
        label: 'File X Mark',
        aliases: {
            names: ['file-times'],
            unicodes: {
                secondary: ['10f317'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f317',
    },
    {
        name: 'file-zipper',
        categories: ['files'],
        terms: [],
        label: 'File zipper',
        aliases: {
            names: ['file-archive'],
            unicodes: {
                secondary: ['10f1c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1c6',
    },
    {
        name: 'files',
        categories: ['files'],
        terms: [],
        label: 'Files',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e178',
    },
    {
        name: 'files-medical',
        categories: ['medical-health'],
        terms: ['document', 'health', 'history', 'prescription', 'record'],
        label: 'Medical Files',
        aliases: {
            unicodes: {
                secondary: ['10f7fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7fd',
    },
    {
        name: 'fill',
        categories: ['design'],
        terms: ['bucket', 'color', 'paint', 'paint bucket'],
        label: 'Fill',
        aliases: {
            unicodes: {
                secondary: ['10f575'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f575',
    },
    {
        name: 'fill-drip',
        categories: ['design'],
        terms: ['bucket', 'color', 'drop', 'paint', 'paint bucket', 'spill'],
        label: 'Fill Drip',
        aliases: {
            unicodes: {
                secondary: ['10f576'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f576',
    },
    {
        name: 'film',
        categories: ['film-video', 'photos-images'],
        terms: ['cinema', 'movie', 'strip', 'video'],
        label: 'Film',
        aliases: {
            unicodes: {
                composite: ['1f39e'],
                secondary: ['10f008'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f008',
    },
    {
        name: 'film-canister',
        categories: ['film-video', 'photos-images'],
        terms: ['110', '35mm', 'darkroom', 'develop', 'image', 'photo', 'photography', 'retro', 'vintage'],
        label: 'Film Canister',
        aliases: {
            unicodes: {
                secondary: ['10f8b7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b7',
    },
    {
        name: 'film-simple',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: 'Film simple',
        aliases: {
            names: ['film-alt'],
            unicodes: {
                secondary: ['10f3a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3a0',
    },
    {
        name: 'film-slash',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: 'Film Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e179',
    },
    {
        name: 'films',
        categories: ['photos-images'],
        terms: [],
        label: 'Films',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17a',
    },
    {
        name: 'filter',
        categories: ['coding', 'science'],
        terms: ['funnel', 'options', 'separate', 'sort'],
        label: 'Filter',
        aliases: {
            unicodes: {
                secondary: ['10f0b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0b0',
    },
    {
        name: 'filter-circle-dollar',
        categories: ['marketing'],
        terms: [],
        label: 'Filter Circle Dollar',
        aliases: {
            names: ['funnel-dollar'],
            unicodes: {
                secondary: ['10f662'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f662',
    },
    {
        name: 'filter-circle-xmark',
        categories: ['text-formatting'],
        terms: [],
        label: 'Filter Circle X Mark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17b',
    },
    {
        name: 'filter-list',
        categories: ['text-formatting'],
        terms: [],
        label: 'Filter List',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17c',
    },
    {
        name: 'filter-slash',
        categories: ['text-formatting'],
        terms: [],
        label: 'Filter Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17d',
    },
    {
        name: 'filters',
        categories: ['text-formatting'],
        terms: [],
        label: 'Filters',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17e',
    },
    {
        name: 'fingerprint',
        categories: ['accessibility', 'security'],
        terms: ['human', 'id', 'identification', 'lock', 'smudge', 'touch', 'unique', 'unlock'],
        label: 'Fingerprint',
        aliases: {
            unicodes: {
                secondary: ['10f577'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f577',
    },
    {
        name: 'fire',
        categories: ['camping', 'energy', 'maps', 'nature', 'science'],
        terms: ['burn', 'caliente', 'flame', 'heat', 'hot', 'popular'],
        label: 'fire',
        aliases: {
            unicodes: {
                composite: ['1f525'],
                secondary: ['10f06d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f06d',
    },
    {
        name: 'fire-burner',
        categories: ['camping', 'household', 'humanitarian'],
        terms: [],
        label: 'Fire Burner',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f1',
    },
    {
        name: 'fire-extinguisher',
        categories: ['coding', 'maps'],
        terms: ['burn', 'caliente', 'fire fighter', 'flame', 'heat', 'hot', 'rescue'],
        label: 'fire-extinguisher',
        aliases: {
            unicodes: {
                composite: ['1f9ef'],
                secondary: ['10f134'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f134',
    },
    {
        name: 'fire-flame',
        categories: ['gaming'],
        terms: [],
        label: 'Fire flame',
        aliases: {
            names: ['flame'],
            unicodes: {
                secondary: ['10f6df'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6df',
    },
    {
        name: 'fire-flame-curved',
        categories: ['camping', 'energy', 'maps', 'science', 'sports-fitness'],
        terms: [],
        label: 'Fire flame curved',
        aliases: {
            names: ['fire-alt'],
            unicodes: {
                secondary: ['10f7e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e4',
    },
    {
        name: 'fire-flame-simple',
        categories: ['energy', 'humanitarian', 'medical-health', 'science', 'sports-fitness'],
        terms: [],
        label: 'Fire flame simple',
        aliases: {
            names: ['burn'],
            unicodes: {
                secondary: ['10f46a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f46a',
    },
    {
        name: 'fire-hydrant',
        categories: ['household'],
        terms: [],
        label: 'Fire Hydrant',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e17f',
    },
    {
        name: 'fire-smoke',
        categories: ['camping', 'nature', 'weather'],
        terms: ['burn', 'caliente', 'flame', 'fog', 'heat', 'hot'],
        label: 'Fire and Smoke',
        aliases: {
            unicodes: {
                secondary: ['10f74b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74b',
    },
    {
        name: 'fireplace',
        categories: ['holidays', 'household', 'travel-hotel'],
        terms: ['caliente', 'flame', 'hearth', 'heat', 'holiday', 'mantle', 'toasty', 'warmth'],
        label: 'Fireplace',
        aliases: {
            unicodes: {
                secondary: ['10f79a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79a',
    },
    {
        name: 'fish',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: ['fauna', 'gold', 'seafood', 'swimming'],
        label: 'Fish',
        aliases: {
            unicodes: {
                composite: ['1f41f'],
                secondary: ['10f578'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f578',
    },
    {
        name: 'fish-bones',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: [],
        label: 'Fish Bones',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e304',
    },
    {
        name: 'fish-cooked',
        categories: ['food-beverage', 'maritime'],
        terms: ['grilled', 'health', 'salmon', 'seafood', 'tuna'],
        label: 'Cooked Fish',
        aliases: {
            unicodes: {
                secondary: ['10f7fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7fe',
    },
    {
        name: 'fish-fins',
        categories: ['animals', 'food-beverage', 'humanitarian', 'maritime'],
        terms: [],
        label: 'Fish Fins',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f2',
    },
    {
        name: 'fishing-rod',
        categories: ['camping', 'maritime', 'sports-fitness'],
        terms: [],
        label: 'Fishing Rod',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a8',
    },
    {
        name: 'flag',
        categories: ['humanitarian', 'maps'],
        terms: ['country', 'notice', 'notification', 'notify', 'pole', 'report', 'symbol'],
        label: 'flag',
        aliases: {
            unicodes: {
                composite: ['f11d', '1f3f4'],
                secondary: ['10f024'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f024',
    },
    {
        name: 'flag-checkered',
        categories: ['maps'],
        terms: ['notice', 'notification', 'notify', 'pole', 'racing', 'report', 'symbol'],
        label: 'flag-checkered',
        aliases: {
            unicodes: {
                composite: ['1f3c1'],
                secondary: ['10f11e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f11e',
    },
    {
        name: 'flag-pennant',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Flag pennant',
        aliases: {
            names: ['pennant'],
            unicodes: {
                composite: ['1f6a9'],
                secondary: ['10f456'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f456',
    },
    {
        name: 'flag-swallowtail',
        categories: ['political'],
        terms: [],
        label: 'Flag swallowtail',
        aliases: {
            names: ['flag-alt'],
            unicodes: {
                secondary: ['10f74c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74c',
    },
    {
        name: 'flag-usa',
        categories: ['political'],
        terms: ['betsy ross', 'country', 'old glory', 'stars', 'stripes', 'symbol'],
        label: 'United States of America Flag',
        aliases: {
            unicodes: {
                secondary: ['10f74d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74d',
    },
    {
        name: 'flashlight',
        categories: ['camping', 'childhood', 'devices-hardware', 'household'],
        terms: ['camping', 'hide and seek', 'lamp', 'light', 'torch'],
        label: 'Flashlight',
        aliases: {
            unicodes: {
                composite: ['1f526'],
                secondary: ['10f8b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b8',
    },
    {
        name: 'flask',
        categories: ['food-beverage', 'maps', 'medical-health', 'science'],
        terms: ['beaker', 'experimental', 'labs', 'science'],
        label: 'Flask',
        aliases: {
            unicodes: {
                secondary: ['10f0c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c3',
    },
    {
        name: 'flask-round-poison',
        categories: ['food-beverage', 'halloween', 'science'],
        terms: [],
        label: 'Flask round poison',
        aliases: {
            names: ['flask-poison'],
            unicodes: {
                secondary: ['10f6e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e0',
    },
    {
        name: 'flask-round-potion',
        categories: ['food-beverage', 'gaming', 'halloween', 'medical-health', 'science'],
        terms: [],
        label: 'Flask round potion',
        aliases: {
            names: ['flask-potion'],
            unicodes: {
                secondary: ['10f6e1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e1',
    },
    {
        name: 'flask-vial',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: [],
        label: 'Flask and Vial',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f3',
    },
    {
        name: 'flatbread',
        categories: ['food-beverage'],
        terms: [],
        label: 'Flatbread',
        aliases: {
            unicodes: {
                composite: ['1fad3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e40b',
    },
    {
        name: 'flatbread-stuffed',
        categories: ['food-beverage'],
        terms: [],
        label: 'Flatbread Stuffed',
        aliases: {
            unicodes: {
                composite: ['1f959'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e40c',
    },
    {
        name: 'floppy-disk',
        categories: ['business', 'design', 'devices-hardware', 'files'],
        terms: [],
        label: 'Floppy Disk',
        aliases: {
            names: ['save'],
            unicodes: {
                composite: ['1f5aa', '1f4be'],
                secondary: ['10f0c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c7',
    },
    {
        name: 'floppy-disk-circle-arrow-right',
        categories: ['business'],
        terms: [],
        label: 'Floppy Disk Arrow Right',
        aliases: {
            names: ['save-circle-arrow-right'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e180',
    },
    {
        name: 'floppy-disk-circle-xmark',
        categories: ['business'],
        terms: [],
        label: 'Floppy Disk X Mark',
        aliases: {
            names: ['floppy-disk-times', 'save-circle-xmark', 'save-times'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e181',
    },
    {
        name: 'floppy-disk-pen',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Floppy Disk-pen',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e182',
    },
    {
        name: 'floppy-disks',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Floppy Disks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e183',
    },
    {
        name: 'florin-sign',
        categories: ['money'],
        terms: [],
        label: 'Florin Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e184',
    },
    {
        name: 'flower',
        categories: ['nature'],
        terms: ['daffodil', 'nature', 'spring', 'summer'],
        label: 'Flower',
        aliases: {
            unicodes: {
                composite: ['1f33c', '273f'],
                secondary: ['10f7ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ff',
    },
    {
        name: 'flower-daffodil',
        categories: ['nature'],
        terms: ['nature', 'spring', 'summer'],
        label: 'Flower Daffodil',
        aliases: {
            unicodes: {
                composite: ['2698'],
                secondary: ['10f800'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f800',
    },
    {
        name: 'flower-tulip',
        categories: ['nature'],
        terms: ['nature', 'spring', 'summer'],
        label: 'Flower Tulip',
        aliases: {
            unicodes: {
                composite: ['1f337'],
                secondary: ['10f801'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f801',
    },
    {
        name: 'flute',
        categories: ['music-audio'],
        terms: ['aerophone', 'instrument', 'music', 'pied piper', 'woodwind'],
        label: 'Flute',
        aliases: {
            unicodes: {
                secondary: ['10f8b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8b9',
    },
    {
        name: 'flux-capacitor',
        categories: ['automotive', 'devices-hardware', 'science-fiction', 'time', 'transportation', 'travel-hotel'],
        terms: [
            '1.21 gigawatts',
            '88 mph',
            'Emmett Brown',
            'Marty McFly',
            'Y',
            'back to the future',
            'delorean',
            'dmc',
            'time travel',
        ],
        label: 'Flux Capacitor',
        aliases: {
            unicodes: {
                secondary: ['10f8ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ba',
    },
    {
        name: 'flying-disc',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Flying Disc',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3a9',
    },
    {
        name: 'folder',
        categories: ['business', 'coding', 'files', 'shapes', 'writing'],
        terms: ['archive', 'directory', 'document', 'file'],
        label: 'Folder',
        aliases: {
            names: ['folder-blank'],
            unicodes: {
                composite: ['1f5bf', 'f114', '1f4c1'],
                secondary: ['10f07b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f07b',
    },
    {
        name: 'folder-arrow-down',
        categories: ['business'],
        terms: [],
        label: 'Folder arrow down',
        aliases: {
            names: ['folder-download'],
            unicodes: {
                secondary: ['10e053'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e053',
    },
    {
        name: 'folder-arrow-up',
        categories: ['business'],
        terms: [],
        label: 'Folder arrow up',
        aliases: {
            names: ['folder-upload'],
            unicodes: {
                secondary: ['10e054'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e054',
    },
    {
        name: 'folder-bookmark',
        categories: ['files'],
        terms: [],
        label: 'Folder Bookmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e186',
    },
    {
        name: 'folder-closed',
        categories: ['files'],
        terms: [],
        label: 'Folder Closed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e185',
    },
    {
        name: 'folder-gear',
        categories: ['files'],
        terms: [],
        label: 'Folder gear',
        aliases: {
            names: ['folder-cog'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e187',
    },
    {
        name: 'folder-grid',
        categories: ['files'],
        terms: [],
        label: 'Folder Grid',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e188',
    },
    {
        name: 'folder-heart',
        categories: ['files'],
        terms: [],
        label: 'Folder Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e189',
    },
    {
        name: 'folder-image',
        categories: ['files', 'photos-images'],
        terms: [],
        label: 'Folder Image',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18a',
    },
    {
        name: 'folder-magnifying-glass',
        categories: ['files'],
        terms: [],
        label: 'Folder magnifying glass',
        aliases: {
            names: ['folder-search'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18b',
    },
    {
        name: 'folder-medical',
        categories: ['files', 'medical-health'],
        terms: [],
        label: 'Folder Medical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18c',
    },
    {
        name: 'folder-minus',
        categories: ['business'],
        terms: ['archive', 'delete', 'directory', 'document', 'file', 'negative', 'remove'],
        label: 'Folder Minus',
        aliases: {
            unicodes: {
                secondary: ['10f65d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65d',
    },
    {
        name: 'folder-music',
        categories: ['files', 'music-audio'],
        terms: [],
        label: 'Folder Music',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18d',
    },
    {
        name: 'folder-open',
        categories: ['business', 'coding', 'files', 'writing'],
        terms: ['archive', 'directory', 'document', 'empty', 'file', 'new'],
        label: 'Folder Open',
        aliases: {
            unicodes: {
                composite: ['1f5c1', 'f115', '1f4c2'],
                secondary: ['10f07c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f07c',
    },
    {
        name: 'folder-plus',
        categories: ['business'],
        terms: ['add', 'archive', 'create', 'directory', 'document', 'file', 'new', 'positive'],
        label: 'Folder Plus',
        aliases: {
            unicodes: {
                secondary: ['10f65e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65e',
    },
    {
        name: 'folder-tree',
        categories: ['business'],
        terms: ['archive', 'directory', 'document', 'file', 'search', 'structure'],
        label: 'Folder Tree',
        aliases: {
            unicodes: {
                secondary: ['10f802'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f802',
    },
    {
        name: 'folder-user',
        categories: ['files', 'users-people'],
        terms: [],
        label: 'Folder User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18e',
    },
    {
        name: 'folder-xmark',
        categories: ['business'],
        terms: [],
        label: 'Folder X Mark',
        aliases: {
            names: ['folder-times'],
            unicodes: {
                secondary: ['10f65f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f65f',
    },
    {
        name: 'folders',
        categories: ['business'],
        terms: ['archive', 'copy', 'directory', 'document', 'duplicate', 'file'],
        label: 'Folders',
        aliases: {
            unicodes: {
                secondary: ['10f660'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f660',
    },
    {
        name: 'fondue-pot',
        categories: ['food-beverage'],
        terms: [],
        label: 'Fondue Pot',
        aliases: {
            unicodes: {
                composite: ['1fad5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e40d',
    },
    {
        name: 'font',
        categories: ['text-formatting'],
        terms: ['alphabet', 'glyph', 'text', 'type', 'typeface'],
        label: 'font',
        aliases: {
            unicodes: {
                secondary: ['10f031'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f031',
    },
    {
        name: 'font-awesome',
        categories: ['coding', 'design'],
        terms: [],
        label: 'Font Awesome',
        aliases: {
            names: ['font-awesome-flag', 'font-awesome-logo-full'],
            unicodes: {
                composite: ['f4e6'],
                primary: ['f4e6'],
                secondary: ['10f4e6', '10f2b4'],
            },
        },
        styles: [
            IconStyle.SOLID,
            IconStyle.REGULAR,
            IconStyle.LIGHT,
            IconStyle.THIN,
            IconStyle.DUOTONE,
            IconStyle.BRANDS,
        ],
        unicode: 'f2b4',
    },
    {
        name: 'font-case',
        categories: ['text-formatting'],
        terms: ['alphabet', 'glyph', 'lowercase', 'text', 'type', 'typeface', 'uppercase'],
        label: 'Font Case',
        aliases: {
            unicodes: {
                secondary: ['10f866'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f866',
    },
    {
        name: 'football',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Football Ball',
        aliases: {
            names: ['football-ball'],
            unicodes: {
                composite: ['1f3c8'],
                secondary: ['10f44e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f44e',
    },
    {
        name: 'football-helmet',
        categories: ['sports-fitness'],
        terms: ['ball', 'concussion', 'fall', 'nfl', 'pigskin', 'protection', 'seasonal'],
        label: 'Football Helmet',
        aliases: {
            unicodes: {
                secondary: ['10f44f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f44f',
    },
    {
        name: 'fork',
        categories: ['household', 'maps'],
        terms: [],
        label: 'Fork',
        aliases: {
            names: ['utensil-fork'],
            unicodes: {
                secondary: ['10f2e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e3',
    },
    {
        name: 'fork-knife',
        categories: ['household', 'maps', 'travel-hotel'],
        terms: [],
        label: 'Fork knife',
        aliases: {
            names: ['utensils-alt'],
            unicodes: {
                secondary: ['10f2e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e6',
    },
    {
        name: 'forklift',
        categories: ['construction', 'logistics'],
        terms: ['archive', 'inventory', 'lorry', 'pallet', 'shipping', 'tractor', 'warehouse'],
        label: 'Forklift',
        aliases: {
            unicodes: {
                secondary: ['10f47a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f47a',
    },
    {
        name: 'fort',
        categories: ['buildings'],
        terms: [],
        label: 'Fort',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e486',
    },
    {
        name: 'forward',
        categories: ['media-playback'],
        terms: ['forward', 'next', 'skip'],
        label: 'forward',
        aliases: {
            unicodes: {
                composite: ['23e9'],
                secondary: ['10f04e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f04e',
    },
    {
        name: 'forward-fast',
        categories: ['media-playback'],
        terms: [],
        label: 'Forward fast',
        aliases: {
            names: ['fast-forward'],
            unicodes: {
                composite: ['23ed'],
                secondary: ['10f050'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f050',
    },
    {
        name: 'forward-step',
        categories: ['media-playback'],
        terms: [],
        label: 'Forward step',
        aliases: {
            names: ['step-forward'],
            unicodes: {
                secondary: ['10f051'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f051',
    },
    {
        name: 'frame',
        categories: ['construction', 'design', 'film-video'],
        terms: [],
        label: 'Frame',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e495',
    },
    {
        name: 'franc-sign',
        categories: ['money'],
        terms: [],
        label: 'Franc Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e18f',
    },
    {
        name: 'french-fries',
        categories: ['food-beverage'],
        terms: ['chips', 'fast food', 'fried', 'ketchup', 'potato', 'poutine'],
        label: 'French Fries',
        aliases: {
            unicodes: {
                composite: ['1f35f'],
                secondary: ['10f803'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f803',
    },
    {
        name: 'frog',
        categories: ['animals', 'camping', 'nature', 'science'],
        terms: ['amphibian', 'bullfrog', 'fauna', 'hop', 'kermit', 'kiss', 'prince', 'ribbit', 'toad', 'wart'],
        label: 'Frog',
        aliases: {
            unicodes: {
                secondary: ['10f52e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f52e',
    },
    {
        name: 'function',
        categories: ['mathematics'],
        terms: ['math', 'mathematics'],
        label: 'Function',
        aliases: {
            unicodes: {
                secondary: ['10f661'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f661',
    },
    {
        name: 'futbol',
        categories: ['sports-fitness'],
        terms: ['ball', 'football', 'mls', 'soccer'],
        label: 'Futbol ball',
        aliases: {
            names: ['futbol-ball', 'soccer-ball'],
            unicodes: {
                composite: ['26bd'],
                secondary: ['10f1e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e3',
    },
    {
        name: 'g',
        categories: ['alphabet'],
        terms: [],
        label: 'G',
        aliases: {
            unicodes: {
                composite: ['67'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '47',
    },
    {
        name: 'galactic-republic',
        categories: ['science-fiction'],
        terms: [],
        label: 'Galactic Republic',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f50c',
    },
    {
        name: 'galactic-senate',
        categories: ['science-fiction'],
        terms: [],
        label: 'Galactic Senate',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f50d',
    },
    {
        name: 'galaxy',
        categories: ['astronomy'],
        terms: ['black hole', 'cosmic', 'nebula', 'solar system', 'space', 'spiral', 'universe'],
        label: 'Galaxy',
        aliases: {
            unicodes: {
                secondary: ['10e008'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e008',
    },
    {
        name: 'gallery-thumbnails',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Gallery Thumbnails',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3aa',
    },
    {
        name: 'game-board',
        categories: ['gaming'],
        terms: ['checkers', 'chess', 'gaming', 'grid', 'tabletop'],
        label: 'Game Board',
        aliases: {
            unicodes: {
                secondary: ['10f867'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f867',
    },
    {
        name: 'game-board-simple',
        categories: ['gaming'],
        terms: [],
        label: 'Game board simple',
        aliases: {
            names: ['game-board-alt'],
            unicodes: {
                secondary: ['10f868'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f868',
    },
    {
        name: 'game-console-handheld',
        categories: ['childhood', 'devices-hardware', 'gaming'],
        terms: ['cartridge', 'gameboy', 'nintendo', 'retro', 'video games', 'vintage'],
        label: 'Handheld Game Console',
        aliases: {
            unicodes: {
                secondary: ['10f8bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8bb',
    },
    {
        name: 'gamepad',
        categories: ['childhood', 'devices-hardware', 'gaming', 'maps'],
        terms: ['arcade', 'controller', 'd-pad', 'joystick', 'video', 'video game'],
        label: 'Gamepad',
        aliases: {
            unicodes: {
                secondary: ['10f11b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f11b',
    },
    {
        name: 'gamepad-modern',
        categories: ['childhood', 'devices-hardware', 'gaming'],
        terms: [],
        label: 'Gamepad modern',
        aliases: {
            names: ['gamepad-alt'],
            unicodes: {
                composite: ['1f3ae'],
                secondary: ['10f8bc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8bc',
    },
    {
        name: 'garage',
        categories: ['automotive', 'buildings', 'household'],
        terms: ['auto', 'car', 'door', 'storage', 'structure', 'warehouse'],
        label: 'Garage',
        aliases: {
            unicodes: {
                secondary: ['10e009'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e009',
    },
    {
        name: 'garage-car',
        categories: ['automotive', 'buildings', 'household'],
        terms: ['auto', 'car', 'door', 'storage', 'structure', 'warehouse'],
        label: 'Garage Car',
        aliases: {
            unicodes: {
                secondary: ['10e00a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00a',
    },
    {
        name: 'garage-open',
        categories: ['automotive', 'buildings', 'household'],
        terms: ['auto', 'car', 'door', 'storage', 'structure', 'warehouse'],
        label: 'Garage Open',
        aliases: {
            unicodes: {
                secondary: ['10e00b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00b',
    },
    {
        name: 'garlic',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Garlic',
        aliases: {
            unicodes: {
                composite: ['1f9c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e40e',
    },
    {
        name: 'gas-pump',
        categories: ['automotive', 'energy', 'humanitarian', 'logistics'],
        terms: ['car', 'fuel', 'gasoline', 'petrol'],
        label: 'Gas Pump',
        aliases: {
            unicodes: {
                composite: ['26fd'],
                secondary: ['10f52f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f52f',
    },
    {
        name: 'gas-pump-slash',
        categories: ['automotive', 'energy', 'logistics'],
        terms: ['car', 'empty', 'fuel', 'gasoline', 'petrol'],
        label: 'Gas Pump Slash',
        aliases: {
            unicodes: {
                secondary: ['10f5f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f4',
    },
    {
        name: 'gauge',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge med',
        aliases: {
            names: ['dashboard', 'gauge-med', 'tachometer-alt-average'],
            unicodes: {
                secondary: ['10f624'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f624',
    },
    {
        name: 'gauge-circle-bolt',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge Circle-bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e496',
    },
    {
        name: 'gauge-circle-minus',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge Circle-minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e497',
    },
    {
        name: 'gauge-circle-plus',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e498',
    },
    {
        name: 'gauge-high',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge',
        aliases: {
            names: ['tachometer-alt', 'tachometer-alt-fast'],
            unicodes: {
                composite: ['f3fd'],
                primary: ['f3fd'],
                secondary: ['10f3fd', '10f625'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f625',
    },
    {
        name: 'gauge-low',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge low',
        aliases: {
            names: ['tachometer-alt-slow'],
            unicodes: {
                secondary: ['10f627'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f627',
    },
    {
        name: 'gauge-max',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge max',
        aliases: {
            names: ['tachometer-alt-fastest'],
            unicodes: {
                secondary: ['10f626'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f626',
    },
    {
        name: 'gauge-min',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge min',
        aliases: {
            names: ['tachometer-alt-slowest'],
            unicodes: {
                secondary: ['10f628'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f628',
    },
    {
        name: 'gauge-simple',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge simple med',
        aliases: {
            names: ['gauge-simple-med', 'tachometer-average'],
            unicodes: {
                secondary: ['10f629'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f629',
    },
    {
        name: 'gauge-simple-high',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge simple',
        aliases: {
            names: ['tachometer', 'tachometer-fast'],
            unicodes: {
                composite: ['f0e4'],
                primary: ['f0e4'],
                secondary: ['10f0e4', '10f62a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62a',
    },
    {
        name: 'gauge-simple-low',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge simple low',
        aliases: {
            names: ['tachometer-slow'],
            unicodes: {
                secondary: ['10f62c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62c',
    },
    {
        name: 'gauge-simple-max',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge simple max',
        aliases: {
            names: ['tachometer-fastest'],
            unicodes: {
                secondary: ['10f62b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62b',
    },
    {
        name: 'gauge-simple-min',
        categories: ['automotive'],
        terms: [],
        label: 'Gauge simple min',
        aliases: {
            names: ['tachometer-slowest'],
            unicodes: {
                secondary: ['10f62d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62d',
    },
    {
        name: 'gavel',
        categories: ['maps'],
        terms: ['hammer', 'judge', 'law', 'lawyer', 'opinion'],
        label: 'Gavel',
        aliases: {
            names: ['legal'],
            unicodes: {
                secondary: ['10f0e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e3',
    },
    {
        name: 'gear',
        categories: ['spinners'],
        terms: [],
        label: 'Gear',
        aliases: {
            names: ['cog'],
            unicodes: {
                composite: ['2699'],
                secondary: ['10f013'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f013',
    },
    {
        name: 'gears',
        categories: ['coding', 'logistics'],
        terms: [],
        label: 'Gears',
        aliases: {
            names: ['cogs'],
            unicodes: {
                secondary: ['10f085'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f085',
    },
    {
        name: 'gem',
        categories: ['shopping'],
        terms: ['diamond', 'jewelry', 'sapphire', 'stone', 'treasure'],
        label: 'Gem',
        aliases: {
            unicodes: {
                composite: ['1f48e'],
                secondary: ['10f3a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3a5',
    },
    {
        name: 'genderless',
        categories: ['gender'],
        terms: ['androgynous', 'asexual', 'sexless'],
        label: 'Genderless',
        aliases: {
            unicodes: {
                secondary: ['10f22d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f22d',
    },
    {
        name: 'gg',
        categories: ['money'],
        terms: [],
        label: 'GG Currency',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f260',
    },
    {
        name: 'gg-circle',
        categories: ['money'],
        terms: [],
        label: 'GG Currency Circle',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f261',
    },
    {
        name: 'ghost',
        categories: ['gaming', 'halloween'],
        terms: ['apparition', 'blinky', 'clyde', 'floating', 'halloween', 'holiday', 'inky', 'pinky', 'spirit'],
        label: 'Ghost',
        aliases: {
            unicodes: {
                composite: ['1f47b'],
                secondary: ['10f6e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e2',
    },
    {
        name: 'gif',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: 'Gif',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e190',
    },
    {
        name: 'gift',
        categories: ['charity', 'holidays', 'maps', 'shopping'],
        terms: ['christmas', 'generosity', 'giving', 'holiday', 'party', 'present', 'wrapped', 'xmas'],
        label: 'gift',
        aliases: {
            unicodes: {
                composite: ['1f381'],
                secondary: ['10f06b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f06b',
    },
    {
        name: 'gift-card',
        categories: ['marketing', 'shopping'],
        terms: ['christmas', 'generosity', 'giving', 'holiday', 'money', 'party', 'present', 'wrapped', 'xmas'],
        label: 'Gift Card',
        aliases: {
            unicodes: {
                secondary: ['10f663'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f663',
    },
    {
        name: 'gifts',
        categories: ['holidays', 'shopping'],
        terms: ['christmas', 'generosity', 'giving', 'holiday', 'party', 'present', 'wrapped', 'xmas'],
        label: 'Gifts',
        aliases: {
            unicodes: {
                secondary: ['10f79c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79c',
    },
    {
        name: 'gingerbread-man',
        categories: ['food-beverage', 'holidays'],
        terms: ['cookie', 'decoration', 'frosting', 'holiday'],
        label: 'Gingerbread Man',
        aliases: {
            unicodes: {
                secondary: ['10f79d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79d',
    },
    {
        name: 'glass',
        categories: ['food-beverage'],
        terms: ['alcohol', 'beverage', 'drink', 'glass', 'water'],
        label: 'Glass',
        aliases: {
            unicodes: {
                composite: ['1f95b'],
                secondary: ['10f804'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f804',
    },
    {
        name: 'glass-citrus',
        categories: ['food-beverage'],
        terms: ['cocktail', 'drink', 'lemonade', 'refreshing', 'water'],
        label: 'Glass Citrus',
        aliases: {
            unicodes: {
                secondary: ['10f869'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f869',
    },
    {
        name: 'glass-empty',
        categories: ['food-beverage'],
        terms: [],
        label: 'Glass Empty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e191',
    },
    {
        name: 'glass-half',
        categories: ['food-beverage'],
        terms: [],
        label: 'Glass Half',
        aliases: {
            names: ['glass-half-empty', 'glass-half-full'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e192',
    },
    {
        name: 'glass-water',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Glass Water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f4',
    },
    {
        name: 'glass-water-droplet',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Glass Water-droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f5',
    },
    {
        name: 'glasses',
        categories: ['business', 'clothing-fashion'],
        terms: ['hipster', 'nerd', 'reading', 'sight', 'spectacles', 'vision'],
        label: 'Glasses',
        aliases: {
            unicodes: {
                secondary: ['10f530'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f530',
    },
    {
        name: 'glasses-round',
        categories: ['clothing-fashion', 'education'],
        terms: [],
        label: 'Glasses round',
        aliases: {
            names: ['glasses-alt'],
            unicodes: {
                composite: ['1f453'],
                secondary: ['10f5f5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f5',
    },
    {
        name: 'globe',
        categories: ['astronomy', 'business', 'charity', 'connectivity', 'maps'],
        terms: [
            'all',
            'coordinates',
            'country',
            'earth',
            'global',
            'gps',
            'language',
            'localize',
            'location',
            'map',
            'online',
            'place',
            'planet',
            'translate',
            'travel',
            'world',
        ],
        label: 'Globe',
        aliases: {
            unicodes: {
                composite: ['1f310'],
                secondary: ['10f0ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ac',
    },
    {
        name: 'globe-snow',
        categories: ['childhood'],
        terms: ['diorama', 'scene', 'seasonal', 'shake', 'souvenir', 'winter'],
        label: 'Globe Snow',
        aliases: {
            unicodes: {
                secondary: ['10f7a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a3',
    },
    {
        name: 'globe-stand',
        categories: ['education'],
        terms: ['earth', 'geography', 'global', 'localize', 'location', 'map', 'travel', 'world'],
        label: 'Globe Stand',
        aliases: {
            unicodes: {
                secondary: ['10f5f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f6',
    },
    {
        name: 'goal-net',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Goal Net',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ab',
    },
    {
        name: 'golf-ball-tee',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Golf ball tee',
        aliases: {
            names: ['golf-ball'],
            unicodes: {
                secondary: ['10f450'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f450',
    },
    {
        name: 'golf-club',
        categories: ['sports-fitness'],
        terms: ['caddy', 'eagle', 'putt', 'tee'],
        label: 'Golf Club',
        aliases: {
            unicodes: {
                secondary: ['10f451'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f451',
    },
    {
        name: 'golf-flag-hole',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Golf Flag + Hole',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ac',
    },
    {
        name: 'google-pay',
        categories: ['shopping'],
        terms: [],
        label: 'Google Pay',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'e079',
    },
    {
        name: 'google-wallet',
        categories: ['shopping'],
        terms: [],
        label: 'Google Wallet',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1ee',
    },
    {
        name: 'gopuram',
        categories: ['buildings', 'humanitarian', 'religion'],
        terms: ['building', 'entrance', 'hinduism', 'temple', 'tower'],
        label: 'Gopuram',
        aliases: {
            unicodes: {
                secondary: ['10f664'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f664',
    },
    {
        name: 'graduation-cap',
        categories: ['clothing-fashion', 'education', 'maps'],
        terms: ['ceremony', 'college', 'graduate', 'learning', 'school', 'student'],
        label: 'Graduation Cap',
        aliases: {
            names: ['mortar-board'],
            unicodes: {
                composite: ['1f393'],
                secondary: ['10f19d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f19d',
    },
    {
        name: 'gramophone',
        categories: ['music-audio'],
        terms: ['award', 'grammy', 'music', 'phonograph', 'record', 'turntable', 'victrola'],
        label: 'Gramophone',
        aliases: {
            unicodes: {
                secondary: ['10f8bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8bd',
    },
    {
        name: 'grapes',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Grapes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e306',
    },
    {
        name: 'grate',
        categories: ['construction', 'energy'],
        terms: [],
        label: 'Grate',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e193',
    },
    {
        name: 'grate-droplet',
        categories: ['construction', 'energy'],
        terms: [],
        label: 'Grate Droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e194',
    },
    {
        name: 'greater-than',
        categories: ['mathematics', 'punctuation-symbols'],
        terms: ['arithmetic', 'compare', 'math'],
        label: 'Greater Than',
        aliases: {
            unicodes: {
                composite: ['f531'],
                primary: ['f531'],
                secondary: ['10f531', '103e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3e',
    },
    {
        name: 'greater-than-equal',
        categories: ['mathematics'],
        terms: ['arithmetic', 'compare', 'math'],
        label: 'Greater Than Equal To',
        aliases: {
            unicodes: {
                secondary: ['10f532'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f532',
    },
    {
        name: 'grid',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid',
        aliases: {
            names: ['grid-3'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e195',
    },
    {
        name: 'grid-2',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid 2',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e196',
    },
    {
        name: 'grid-2-plus',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid 2-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e197',
    },
    {
        name: 'grid-4',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid 4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e198',
    },
    {
        name: 'grid-5',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid 5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e199',
    },
    {
        name: 'grid-dividers',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Grid Dividers',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ad',
    },
    {
        name: 'grid-horizontal',
        categories: ['editing'],
        terms: [],
        label: 'Grid Horizontal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e307',
    },
    {
        name: 'grip',
        categories: ['editing'],
        terms: [],
        label: 'Grip',
        aliases: {
            names: ['grip-horizontal'],
            unicodes: {
                secondary: ['10f58d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58d',
    },
    {
        name: 'grip-dots',
        categories: ['editing'],
        terms: [],
        label: 'Grip Dots',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e410',
    },
    {
        name: 'grip-dots-vertical',
        categories: ['editing'],
        terms: [],
        label: 'Grip Dots-vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e411',
    },
    {
        name: 'grip-lines',
        categories: ['editing'],
        terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
        label: 'Grip Lines',
        aliases: {
            unicodes: {
                secondary: ['10f7a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a4',
    },
    {
        name: 'grip-lines-vertical',
        categories: ['editing'],
        terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
        label: 'Grip Lines Vertical',
        aliases: {
            unicodes: {
                secondary: ['10f7a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a5',
    },
    {
        name: 'grip-vertical',
        categories: ['editing'],
        terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
        label: 'Grip Vertical',
        aliases: {
            unicodes: {
                secondary: ['10f58e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58e',
    },
    {
        name: 'group-arrows-rotate',
        categories: ['humanitarian', 'marketing'],
        terms: [],
        label: 'Group Arrows-rotate',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f6',
    },
    {
        name: 'guarani-sign',
        categories: ['money'],
        terms: [],
        label: 'Guarani Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19a',
    },
    {
        name: 'guitar',
        categories: ['music-audio'],
        terms: ['acoustic', 'instrument', 'music', 'rock', 'rock and roll', 'song', 'strings'],
        label: 'Guitar',
        aliases: {
            unicodes: {
                secondary: ['10f7a6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a6',
    },
    {
        name: 'guitar-electric',
        categories: ['music-audio'],
        terms: ['axe', 'instrument', 'music', 'rock', 'rock and roll', 'song', 'strings'],
        label: 'Guitar Electric',
        aliases: {
            unicodes: {
                composite: ['1f3b8'],
                secondary: ['10f8be'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8be',
    },
    {
        name: 'guitars',
        categories: ['music-audio'],
        terms: ['acoustic', 'axes', 'electric', 'instrument', 'music', 'rock', 'rock and roll', 'song', 'strings'],
        label: 'Guitars',
        aliases: {
            unicodes: {
                secondary: ['10f8bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8bf',
    },
    {
        name: 'gun',
        categories: ['security'],
        terms: [],
        label: 'Gun',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19b',
    },
    {
        name: 'gun-slash',
        categories: ['security'],
        terms: [],
        label: 'Gun Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19c',
    },
    {
        name: 'gun-squirt',
        categories: ['childhood'],
        terms: [],
        label: 'Squirt Gun',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19d',
    },
    {
        name: 'h',
        categories: ['alphabet'],
        terms: [],
        label: 'H',
        aliases: {
            unicodes: {
                composite: ['68'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '48',
    },
    {
        name: 'h1',
        categories: ['text-formatting'],
        terms: ['format', 'header', 'text', 'title'],
        label: 'H1 Heading',
        aliases: {
            unicodes: {
                secondary: ['10f313'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f313',
    },
    {
        name: 'h2',
        categories: ['text-formatting'],
        terms: ['format', 'header', 'text', 'title'],
        label: 'H2 Heading',
        aliases: {
            unicodes: {
                secondary: ['10f314'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f314',
    },
    {
        name: 'h3',
        categories: ['text-formatting'],
        terms: ['format', 'header', 'text', 'title'],
        label: 'H3 Heading',
        aliases: {
            unicodes: {
                secondary: ['10f315'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f315',
    },
    {
        name: 'h4',
        categories: ['text-formatting'],
        terms: ['format', 'header', 'text', 'title'],
        label: 'H4',
        aliases: {
            unicodes: {
                secondary: ['10f86a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86a',
    },
    {
        name: 'h5',
        categories: ['text-formatting'],
        terms: [],
        label: 'H5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e412',
    },
    {
        name: 'h6',
        categories: ['text-formatting'],
        terms: [],
        label: 'H6',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e413',
    },
    {
        name: 'hammer',
        categories: ['construction', 'humanitarian'],
        terms: ['admin', 'fix', 'repair', 'settings', 'tool'],
        label: 'Hammer',
        aliases: {
            unicodes: {
                composite: ['1f528'],
                secondary: ['10f6e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e3',
    },
    {
        name: 'hammer-crash',
        categories: ['construction'],
        terms: [],
        label: 'Hammer Crash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e414',
    },
    {
        name: 'hammer-war',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'cleric', 'd&d', 'dnd', 'dwarf', 'fantasy', 'melee attack', 'weapon'],
        label: 'Hammer War',
        aliases: {
            unicodes: {
                secondary: ['10f6e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e4',
    },
    {
        name: 'hamsa',
        categories: ['religion'],
        terms: ['amulet', 'christianity', 'islam', 'jewish', 'judaism', 'muslim', 'protection'],
        label: 'Hamsa',
        aliases: {
            unicodes: {
                secondary: ['10f665'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f665',
    },
    {
        name: 'hand',
        categories: ['hands', 'media-playback'],
        terms: [],
        label: 'Paper (Hand)',
        aliases: {
            names: ['hand-paper'],
            unicodes: {
                composite: ['1f91a', '270b'],
                secondary: ['10f256'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f256',
    },
    {
        name: 'hand-back-fist',
        categories: ['hands'],
        terms: [],
        label: 'Rock (Hand)',
        aliases: {
            names: ['hand-rock'],
            unicodes: {
                secondary: ['10f255'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f255',
    },
    {
        name: 'hand-back-point-down',
        categories: ['hands'],
        terms: [],
        label: 'Hand Back-point-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19e',
    },
    {
        name: 'hand-back-point-left',
        categories: ['hands'],
        terms: [],
        label: 'Hand Back-point-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e19f',
    },
    {
        name: 'hand-back-point-ribbon',
        categories: ['hands'],
        terms: [],
        label: 'Hand Back-point-ribbon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a0',
    },
    {
        name: 'hand-back-point-right',
        categories: ['hands'],
        terms: [],
        label: 'Hand Back-point-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a1',
    },
    {
        name: 'hand-back-point-up',
        categories: ['hands'],
        terms: [],
        label: 'Hand Back-point-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a2',
    },
    {
        name: 'hand-dots',
        categories: ['hands', 'medical-health'],
        terms: [],
        label: 'Hand dots',
        aliases: {
            names: ['allergies'],
            unicodes: {
                secondary: ['10f461'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f461',
    },
    {
        name: 'hand-fingers-crossed',
        categories: ['hands'],
        terms: [],
        label: 'Hand Fingers-crossed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a3',
    },
    {
        name: 'hand-fist',
        categories: ['gaming', 'hands', 'political'],
        terms: [],
        label: 'Raised Fist',
        aliases: {
            names: ['fist-raised'],
            unicodes: {
                composite: ['270a'],
                secondary: ['10f6de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6de',
    },
    {
        name: 'hand-heart',
        categories: ['charity', 'hands'],
        terms: ['care', 'charity', 'donate', 'help'],
        label: 'Hand with Heart',
        aliases: {
            unicodes: {
                secondary: ['10f4bc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4bc',
    },
    {
        name: 'hand-holding',
        categories: ['hands'],
        terms: ['carry', 'lift'],
        label: 'Hand Holding',
        aliases: {
            unicodes: {
                secondary: ['10f4bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4bd',
    },
    {
        name: 'hand-holding-box',
        categories: ['hands', 'logistics', 'shopping'],
        terms: ['carry', 'gift', 'handling', 'lift', 'package'],
        label: 'Hand Holding Box',
        aliases: {
            unicodes: {
                secondary: ['10f47b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f47b',
    },
    {
        name: 'hand-holding-dollar',
        categories: ['charity', 'hands', 'money'],
        terms: [],
        label: 'Hand holding dollar',
        aliases: {
            names: ['hand-holding-usd'],
            unicodes: {
                secondary: ['10f4c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c0',
    },
    {
        name: 'hand-holding-droplet',
        categories: ['charity', 'hands'],
        terms: [],
        label: 'Hand holding droplet',
        aliases: {
            names: ['hand-holding-water'],
            unicodes: {
                secondary: ['10f4c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c1',
    },
    {
        name: 'hand-holding-hand',
        categories: ['charity', 'hands', 'humanitarian'],
        terms: [],
        label: 'Hand Holding-hand',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f7',
    },
    {
        name: 'hand-holding-heart',
        categories: ['charity', 'hands'],
        terms: ['carry', 'charity', 'gift', 'lift', 'package'],
        label: 'Hand Holding Heart',
        aliases: {
            unicodes: {
                secondary: ['10f4be'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4be',
    },
    {
        name: 'hand-holding-magic',
        categories: ['gaming', 'hands'],
        terms: [
            'Dungeons & Dragons',
            'carry',
            'cast',
            'd&d',
            'dnd',
            'fantasy',
            'lift',
            'mage',
            'spell',
            'witch',
            'wizard',
        ],
        label: 'Hand Holding-magic',
        aliases: {
            unicodes: {
                secondary: ['10f6e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e5',
    },
    {
        name: 'hand-holding-medical',
        categories: ['hands', 'medical-health'],
        terms: ['care', 'covid-19', 'donate', 'help'],
        label: 'Hand Holding Medical Cross',
        aliases: {
            unicodes: {
                secondary: ['10e05c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05c',
    },
    {
        name: 'hand-holding-seedling',
        categories: ['charity', 'hands'],
        terms: ['carry', 'grow', 'lift', 'plant'],
        label: 'Hand Holding Seedling',
        aliases: {
            unicodes: {
                secondary: ['10f4bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4bf',
    },
    {
        name: 'hand-holding-skull',
        categories: ['hands'],
        terms: [],
        label: 'Hand Holding a Skull',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a4',
    },
    {
        name: 'hand-horns',
        categories: ['hands'],
        terms: [],
        label: 'Hands Horns',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a9',
    },
    {
        name: 'hand-lizard',
        categories: ['hands'],
        terms: ['game', 'roshambo'],
        label: 'Lizard (Hand)',
        aliases: {
            unicodes: {
                secondary: ['10f258'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f258',
    },
    {
        name: 'hand-love',
        categories: ['hands'],
        terms: [],
        label: 'Hand Love',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a5',
    },
    {
        name: 'hand-middle-finger',
        categories: ['hands'],
        terms: ['flip the bird', 'gesture', 'hate', 'rude'],
        label: 'Hand with Middle Finger Raised',
        aliases: {
            unicodes: {
                composite: ['1f595'],
                secondary: ['10f806'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f806',
    },
    {
        name: 'hand-peace',
        categories: ['hands'],
        terms: ['rest', 'truce'],
        label: 'Peace (Hand)',
        aliases: {
            unicodes: {
                composite: ['270c'],
                secondary: ['10f25b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f25b',
    },
    {
        name: 'hand-point-down',
        categories: ['hands'],
        terms: ['finger', 'hand-o-down', 'point'],
        label: 'Hand Pointing Down',
        aliases: {
            unicodes: {
                secondary: ['10f0a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a7',
    },
    {
        name: 'hand-point-left',
        categories: ['hands'],
        terms: ['back', 'finger', 'hand-o-left', 'left', 'point', 'previous'],
        label: 'Hand Pointing Left',
        aliases: {
            unicodes: {
                secondary: ['10f0a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a5',
    },
    {
        name: 'hand-point-ribbon',
        categories: ['hands'],
        terms: [],
        label: 'Hand Point-ribbon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a6',
    },
    {
        name: 'hand-point-right',
        categories: ['hands'],
        terms: ['finger', 'forward', 'hand-o-right', 'next', 'point', 'right'],
        label: 'Hand Pointing Right',
        aliases: {
            unicodes: {
                secondary: ['10f0a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a4',
    },
    {
        name: 'hand-point-up',
        categories: ['hands'],
        terms: ['finger', 'hand-o-up', 'point'],
        label: 'Hand Pointing Up',
        aliases: {
            unicodes: {
                composite: ['261d'],
                secondary: ['10f0a6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a6',
    },
    {
        name: 'hand-pointer',
        categories: ['hands'],
        terms: ['arrow', 'cursor', 'select'],
        label: 'Pointer (Hand)',
        aliases: {
            unicodes: {
                secondary: ['10f25a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f25a',
    },
    {
        name: 'hand-scissors',
        categories: ['hands'],
        terms: ['cut', 'game', 'roshambo'],
        label: 'Scissors (Hand)',
        aliases: {
            unicodes: {
                secondary: ['10f257'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f257',
    },
    {
        name: 'hand-sparkles',
        categories: ['hands'],
        terms: ['clean', 'covid-19', 'hygiene', 'magic', 'soap', 'wash'],
        label: 'Hand Sparkles',
        aliases: {
            unicodes: {
                secondary: ['10e05d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05d',
    },
    {
        name: 'hand-spock',
        categories: ['hands', 'science-fiction'],
        terms: ['live long', 'prosper', 'salute', 'star trek', 'vulcan'],
        label: 'Spock (Hand)',
        aliases: {
            unicodes: {
                composite: ['1f596'],
                secondary: ['10f259'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f259',
    },
    {
        name: 'hand-wave',
        categories: ['hands'],
        terms: [],
        label: 'Hand Wave',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a7',
    },
    {
        name: 'handcuffs',
        categories: ['humanitarian', 'security'],
        terms: [],
        label: 'Handcuffs',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f8',
    },
    {
        name: 'hands',
        categories: ['accessibility'],
        terms: ['carry', 'hold', 'lift'],
        label: 'Hands',
        aliases: {
            names: ['sign-language', 'signing'],
            unicodes: {
                secondary: ['10f2a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a7',
    },
    {
        name: 'hands-asl-interpreting',
        categories: ['accessibility', 'communication'],
        terms: [],
        label: 'Hands american sign language interpreting',
        aliases: {
            names: [
                'american-sign-language-interpreting',
                'asl-interpreting',
                'hands-american-sign-language-interpreting',
            ],
            unicodes: {
                secondary: ['10f2a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a3',
    },
    {
        name: 'hands-bound',
        categories: ['hands', 'humanitarian', 'security'],
        terms: [],
        label: 'Hands Bound',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4f9',
    },
    {
        name: 'hands-bubbles',
        categories: ['hands', 'humanitarian'],
        terms: [],
        label: 'Hands bubbles',
        aliases: {
            names: ['hands-wash'],
            unicodes: {
                secondary: ['10e05e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05e',
    },
    {
        name: 'hands-clapping',
        categories: ['hands'],
        terms: [],
        label: 'Hands Clapping',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1a8',
    },
    {
        name: 'hands-holding',
        categories: ['hands'],
        terms: [],
        label: 'Hands holding',
        aliases: {
            unicodes: {
                secondary: ['10f4c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c2',
    },
    {
        name: 'hands-holding-child',
        categories: ['charity', 'childhood', 'hands', 'humanitarian', 'security'],
        terms: [],
        label: 'Hands Holding-child',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4fa',
    },
    {
        name: 'hands-holding-circle',
        categories: ['charity', 'hands', 'humanitarian', 'security'],
        terms: [],
        label: 'Hands Holding-circle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4fb',
    },
    {
        name: 'hands-holding-diamond',
        categories: ['hands', 'logistics'],
        terms: [],
        label: 'Hands holding diamond',
        aliases: {
            names: ['hand-receiving'],
            unicodes: {
                secondary: ['10f47c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f47c',
    },
    {
        name: 'hands-holding-dollar',
        categories: ['charity', 'hands', 'money'],
        terms: [],
        label: 'Hands holding dollar',
        aliases: {
            names: ['hands-usd'],
            unicodes: {
                secondary: ['10f4c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c5',
    },
    {
        name: 'hands-holding-heart',
        categories: ['charity', 'hands'],
        terms: [],
        label: 'Hands holding heart',
        aliases: {
            names: ['hands-heart'],
            unicodes: {
                secondary: ['10f4c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c3',
    },
    {
        name: 'hands-praying',
        categories: ['hands', 'religion'],
        terms: [],
        label: 'Hands praying',
        aliases: {
            names: ['praying-hands'],
            unicodes: {
                secondary: ['10f684'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f684',
    },
    {
        name: 'handshake',
        categories: ['charity', 'hands', 'political', 'shopping'],
        terms: ['agreement', 'greeting', 'meeting', 'partnership'],
        label: 'Handshake',
        aliases: {
            unicodes: {
                secondary: ['10f2b5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2b5',
    },
    {
        name: 'handshake-angle',
        categories: ['accessibility', 'charity', 'hands'],
        terms: [],
        label: 'Handshake angle',
        aliases: {
            names: ['hands-helping'],
            unicodes: {
                secondary: ['10f4c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c4',
    },
    {
        name: 'handshake-simple',
        categories: ['charity', 'hands', 'humanitarian'],
        terms: [],
        label: 'Handshake simple',
        aliases: {
            names: ['handshake-alt'],
            unicodes: {
                composite: ['1f91d'],
                secondary: ['10f4c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c6',
    },
    {
        name: 'handshake-simple-slash',
        categories: ['hands'],
        terms: [],
        label: 'Handshake simple slash',
        aliases: {
            names: ['handshake-alt-slash'],
            unicodes: {
                secondary: ['10e05f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e05f',
    },
    {
        name: 'handshake-slash',
        categories: ['hands'],
        terms: ['broken', 'covid-19', 'social distance'],
        label: 'Handshake Slash',
        aliases: {
            unicodes: {
                secondary: ['10e060'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e060',
    },
    {
        name: 'hanukiah',
        categories: ['religion'],
        terms: ['candle', 'hanukkah', 'jewish', 'judaism', 'light'],
        label: 'Hanukiah',
        aliases: {
            unicodes: {
                composite: ['1f54e'],
                secondary: ['10f6e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e6',
    },
    {
        name: 'hard-drive',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Hard drive',
        aliases: {
            names: ['hdd'],
            unicodes: {
                composite: ['1f5b4'],
                secondary: ['10f0a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0a0',
    },
    {
        name: 'hashtag',
        categories: ['punctuation-symbols', 'social'],
        terms: ['Twitter', 'instagram', 'pound', 'social media', 'tag'],
        label: 'Hashtag',
        aliases: {
            unicodes: {
                composite: ['f292'],
                primary: ['f292'],
                secondary: ['10f292', '1023'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '23',
    },
    {
        name: 'hashtag-lock',
        categories: ['text-formatting'],
        terms: [],
        label: 'Hashtag Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e415',
    },
    {
        name: 'hat-chef',
        categories: ['food-beverage'],
        terms: ['cook', 'cuisine', 'culinary', 'dining', 'kitchen'],
        label: 'Chef Hat',
        aliases: {
            unicodes: {
                secondary: ['10f86b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86b',
    },
    {
        name: 'hat-cowboy',
        categories: ['clothing-fashion'],
        terms: [
            'buckaroo',
            'horse',
            'jackeroo',
            'john b.',
            'old west',
            'pardner',
            'ranch',
            'rancher',
            'rodeo',
            'western',
            'wrangler',
        ],
        label: 'Cowboy Hat',
        aliases: {
            unicodes: {
                secondary: ['10f8c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c0',
    },
    {
        name: 'hat-cowboy-side',
        categories: ['clothing-fashion'],
        terms: [
            'buckaroo',
            'horse',
            'jackeroo',
            'john b.',
            'old west',
            'pardner',
            'ranch',
            'rancher',
            'rodeo',
            'western',
            'wrangler',
        ],
        label: 'Cowboy Hat Side',
        aliases: {
            unicodes: {
                secondary: ['10f8c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c1',
    },
    {
        name: 'hat-santa',
        categories: ['clothing-fashion', 'holidays'],
        terms: ['accessory', 'christmas', 'claus', 'clothing', 'head', 'holiday', 'santa', 'xmas'],
        label: "Santa's Hat",
        aliases: {
            unicodes: {
                secondary: ['10f7a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a7',
    },
    {
        name: 'hat-winter',
        categories: ['clothing-fashion'],
        terms: ['accessory', 'clothing', 'cold', 'head', 'knitted', 'seasonal'],
        label: 'Hat Winter',
        aliases: {
            unicodes: {
                secondary: ['10f7a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a8',
    },
    {
        name: 'hat-witch',
        categories: ['clothing-fashion', 'halloween'],
        terms: ['accessory', 'clothing', 'halloween', 'head', 'holiday', 'mage', 'magic', 'sorceror'],
        label: "Witch's Hat",
        aliases: {
            unicodes: {
                secondary: ['10f6e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e7',
    },
    {
        name: 'hat-wizard',
        categories: ['clothing-fashion', 'gaming', 'halloween'],
        terms: [
            'Dungeons & Dragons',
            'accessory',
            'buckle',
            'clothing',
            'd&d',
            'dnd',
            'fantasy',
            'halloween',
            'head',
            'holiday',
            'mage',
            'magic',
            'pointy',
            'witch',
        ],
        label: "Wizard's Hat",
        aliases: {
            unicodes: {
                secondary: ['10f6e8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e8',
    },
    {
        name: 'head-side',
        categories: ['users-people'],
        terms: ['bald', 'face', 'profile', 'user'],
        label: 'Head Side',
        aliases: {
            unicodes: {
                secondary: ['10f6e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6e9',
    },
    {
        name: 'head-side-brain',
        categories: ['medical-health', 'science', 'users-people'],
        terms: ['diagnosis', 'face', 'mental health', 'profile', 'psychiatric', 'psychiatry'],
        label: 'Head Side with Brain',
        aliases: {
            unicodes: {
                secondary: ['10f808'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f808',
    },
    {
        name: 'head-side-cough',
        categories: ['medical-health', 'users-people'],
        terms: ['cough', 'covid-19', 'germs', 'lungs', 'respiratory', 'sick'],
        label: 'Head Side Cough',
        aliases: {
            unicodes: {
                secondary: ['10e061'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e061',
    },
    {
        name: 'head-side-cough-slash',
        categories: ['medical-health', 'users-people'],
        terms: ['cough', 'covid-19', 'germs', 'lungs', 'respiratory', 'sick'],
        label: 'Head Side-cough-slash',
        aliases: {
            unicodes: {
                secondary: ['10e062'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e062',
    },
    {
        name: 'head-side-goggles',
        categories: ['users-people'],
        terms: [],
        label: 'Head side goggles',
        aliases: {
            names: ['head-vr'],
            unicodes: {
                secondary: ['10f6ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ea',
    },
    {
        name: 'head-side-headphones',
        categories: ['film-video', 'music-audio', 'users-people'],
        terms: ['amplifier', 'bluetooth', 'earphone', 'headset', 'listen', 'microphone', 'music'],
        label: 'Head Side with Headphones',
        aliases: {
            unicodes: {
                secondary: ['10f8c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c2',
    },
    {
        name: 'head-side-heart',
        categories: ['accessibility', 'holidays', 'users-people'],
        terms: [],
        label: 'Head Side-heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1aa',
    },
    {
        name: 'head-side-mask',
        categories: ['medical-health', 'users-people'],
        terms: ['breath', 'covid-19', 'filter', 'respirator', 'virus'],
        label: 'Head Side Mask',
        aliases: {
            unicodes: {
                secondary: ['10e063'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e063',
    },
    {
        name: 'head-side-medical',
        categories: ['medical-health', 'users-people'],
        terms: ['diagnosis', 'face', 'mental health', 'profile', 'psychiatric', 'psychiatry'],
        label: 'Head Side with Medical Symbol',
        aliases: {
            unicodes: {
                secondary: ['10f809'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f809',
    },
    {
        name: 'head-side-virus',
        categories: ['medical-health', 'users-people'],
        terms: ['cold', 'covid-19', 'flu', 'sick'],
        label: 'Head Side Virus',
        aliases: {
            unicodes: {
                secondary: ['10e064'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e064',
    },
    {
        name: 'heading',
        categories: ['text-formatting'],
        terms: ['format', 'header', 'text', 'title'],
        label: 'heading',
        aliases: {
            names: ['header'],
            unicodes: {
                secondary: ['10f1dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1dc',
    },
    {
        name: 'headphones',
        categories: ['devices-hardware', 'film-video', 'music-audio'],
        terms: ['audio', 'listen', 'music', 'sound', 'speaker'],
        label: 'headphones',
        aliases: {
            unicodes: {
                composite: ['1f3a7'],
                secondary: ['10f025'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f025',
    },
    {
        name: 'headphones-simple',
        categories: ['music-audio'],
        terms: [],
        label: 'Headphones simple',
        aliases: {
            names: ['headphones-alt'],
            unicodes: {
                secondary: ['10f58f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f58f',
    },
    {
        name: 'headset',
        categories: ['gaming', 'humanitarian'],
        terms: [
            'audio',
            'gamer',
            'gaming',
            'listen',
            'live chat',
            'microphone',
            'shot caller',
            'sound',
            'support',
            'telemarketer',
        ],
        label: 'Headset',
        aliases: {
            unicodes: {
                secondary: ['10f590'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f590',
    },
    {
        name: 'heart',
        categories: [
            'charity',
            'gaming',
            'holidays',
            'maps',
            'medical-health',
            'shapes',
            'shopping',
            'social',
            'sports-fitness',
        ],
        terms: ['favorite', 'like', 'love', 'relationship', 'valentine'],
        label: 'Heart',
        aliases: {
            unicodes: {
                composite: [
                    '1f499',
                    '1f49a',
                    '1f49b',
                    '1f49c',
                    '1f5a4',
                    '1f90d',
                    '1f90e',
                    '1f9e1',
                    '2764',
                    'f08a',
                    '2665',
                ],
                secondary: ['10f004'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f004',
    },
    {
        name: 'heart-circle-bolt',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4fc',
    },
    {
        name: 'heart-circle-check',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4fd',
    },
    {
        name: 'heart-circle-exclamation',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4fe',
    },
    {
        name: 'heart-circle-minus',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4ff',
    },
    {
        name: 'heart-circle-plus',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e500',
    },
    {
        name: 'heart-circle-xmark',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Heart Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e501',
    },
    {
        name: 'heart-crack',
        categories: ['shapes'],
        terms: [],
        label: 'Heart crack',
        aliases: {
            names: ['heart-broken'],
            unicodes: {
                composite: ['1f494'],
                secondary: ['10f7a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a9',
    },
    {
        name: 'heart-half',
        categories: ['shapes'],
        terms: [],
        label: 'Heart Half',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ab',
    },
    {
        name: 'heart-half-stroke',
        categories: ['shapes'],
        terms: [],
        label: 'Heart half stroke',
        aliases: {
            names: ['heart-half-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ac',
    },
    {
        name: 'heart-pulse',
        categories: ['maps', 'medical-health', 'sports-fitness'],
        terms: [],
        label: 'Heart pulse',
        aliases: {
            names: ['heartbeat'],
            unicodes: {
                secondary: ['10f21e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f21e',
    },
    {
        name: 'heat',
        categories: ['disaster', 'energy', 'household', 'travel-hotel', 'weather'],
        terms: ['ac', 'air conditioner', 'boiler', 'heating', 'hot', 'sauna', 'spa', 'summer', 'temperature'],
        label: 'Heat',
        aliases: {
            unicodes: {
                secondary: ['10e00c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00c',
    },
    {
        name: 'helicopter',
        categories: ['humanitarian', 'logistics', 'maps', 'transportation'],
        terms: ['airwolf', 'apache', 'chopper', 'flight', 'fly', 'travel'],
        label: 'Helicopter',
        aliases: {
            unicodes: {
                composite: ['1f681'],
                secondary: ['10f533'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f533',
    },
    {
        name: 'helicopter-symbol',
        categories: ['humanitarian', 'logistics', 'maps'],
        terms: [],
        label: 'Helicopter Symbol',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e502',
    },
    {
        name: 'helmet-battle',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'armor', 'clothing', 'd&d', 'dnd', 'fantasy', 'hat', 'knight', 'paladin'],
        label: 'Battle Helmet',
        aliases: {
            unicodes: {
                secondary: ['10f6eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6eb',
    },
    {
        name: 'helmet-safety',
        categories: ['construction', 'logistics'],
        terms: [],
        label: 'Helmet safety',
        aliases: {
            names: ['hard-hat', 'hat-hard'],
            unicodes: {
                secondary: ['10f807'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f807',
    },
    {
        name: 'helmet-un',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'Helmet Un',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e503',
    },
    {
        name: 'hexagon',
        categories: ['shapes'],
        terms: ['geometry', 'honeycomb', 'polygon', 'shape'],
        label: 'Hexagon',
        aliases: {
            unicodes: {
                composite: ['2b23'],
                secondary: ['10f312'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f312',
    },
    {
        name: 'hexagon-check',
        categories: ['editing'],
        terms: [],
        label: 'Hexagon Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e416',
    },
    {
        name: 'hexagon-divide',
        categories: ['mathematics'],
        terms: [],
        label: 'Hexagon Divide',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ad',
    },
    {
        name: 'hexagon-exclamation',
        categories: ['alert'],
        terms: [],
        label: 'Hexagon Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e417',
    },
    {
        name: 'hexagon-image',
        categories: ['photos-images', 'shopping'],
        terms: [],
        label: 'Hexagon Image',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e504',
    },
    {
        name: 'hexagon-minus',
        categories: ['mathematics'],
        terms: [],
        label: 'Hexagon minus',
        aliases: {
            names: ['minus-hexagon'],
            unicodes: {
                secondary: ['10f307'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f307',
    },
    {
        name: 'hexagon-plus',
        categories: ['mathematics'],
        terms: [],
        label: 'Hexagon plus',
        aliases: {
            names: ['plus-hexagon'],
            unicodes: {
                secondary: ['10f300'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f300',
    },
    {
        name: 'hexagon-vertical-nft',
        categories: ['shopping'],
        terms: [],
        label: 'Hexagon NFT',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e505',
    },
    {
        name: 'hexagon-vertical-nft-slanted',
        categories: ['shopping'],
        terms: [],
        label: 'Hexagon NFT Slanted',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e506',
    },
    {
        name: 'hexagon-xmark',
        categories: ['mathematics'],
        terms: [],
        label: 'Hexagon X Mark',
        aliases: {
            names: ['times-hexagon', 'xmark-hexagon'],
            unicodes: {
                secondary: ['10f2ee'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ee',
    },
    {
        name: 'high-definition',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: 'High Definition',
        aliases: {
            names: ['rectangle-hd'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ae',
    },
    {
        name: 'highlighter',
        categories: ['business', 'design', 'text-formatting'],
        terms: ['edit', 'marker', 'sharpie', 'update', 'write'],
        label: 'Highlighter',
        aliases: {
            unicodes: {
                secondary: ['10f591'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f591',
    },
    {
        name: 'highlighter-line',
        categories: ['design', 'writing'],
        terms: [],
        label: 'Highlighter Line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1af',
    },
    {
        name: 'hill-avalanche',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'Hill Avalanche',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e507',
    },
    {
        name: 'hill-rockslide',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'Hill Rockslide',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e508',
    },
    {
        name: 'hippo',
        categories: ['animals'],
        terms: ['animal', 'fauna', 'hippopotamus', 'hungry', 'mammal'],
        label: 'Hippo',
        aliases: {
            unicodes: {
                composite: ['1f99b'],
                secondary: ['10f6ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ed',
    },
    {
        name: 'hockey-mask',
        categories: ['halloween', 'sports-fitness'],
        terms: ['clothing', 'friday', 'halloween', 'ice', 'jason', 'nhl', 'protection', 'sport', 'thirteenth'],
        label: 'Hockey Mask',
        aliases: {
            unicodes: {
                secondary: ['10f6ee'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ee',
    },
    {
        name: 'hockey-puck',
        categories: ['sports-fitness'],
        terms: ['ice', 'nhl', 'sport'],
        label: 'Hockey Puck',
        aliases: {
            unicodes: {
                secondary: ['10f453'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f453',
    },
    {
        name: 'hockey-stick-puck',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Hockey Stick + Puck',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ae',
    },
    {
        name: 'hockey-sticks',
        categories: ['sports-fitness'],
        terms: ['ice', 'nhl', 'sport'],
        label: 'Hockey Sticks',
        aliases: {
            unicodes: {
                secondary: ['10f454'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f454',
    },
    {
        name: 'holly-berry',
        categories: ['holidays'],
        terms: [
            'catwoman',
            'christmas',
            'decoration',
            'flora',
            'halle',
            'holiday',
            'ororo munroe',
            'plant',
            'storm',
            'xmas',
        ],
        label: 'Holly Berry',
        aliases: {
            unicodes: {
                secondary: ['10f7aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7aa',
    },
    {
        name: 'honey-pot',
        categories: ['food-beverage'],
        terms: [],
        label: 'Honey Pot',
        aliases: {
            unicodes: {
                composite: ['1f36f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e418',
    },
    {
        name: 'hood-cloak',
        categories: ['clothing-fashion', 'gaming'],
        terms: ['Dungeons & Dragons', 'clothing', 'd&d', 'dnd', 'fantasy', 'hat', 'rogue', 'stealth'],
        label: 'Hood Cloak',
        aliases: {
            unicodes: {
                secondary: ['10f6ef'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ef',
    },
    {
        name: 'horizontal-rule',
        categories: ['punctuation-symbols', 'text-formatting'],
        terms: ['divider', 'hr', 'line', 'page break'],
        label: 'Horizontal Rule',
        aliases: {
            unicodes: {
                composite: ['2015'],
                secondary: ['10f86c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86c',
    },
    {
        name: 'horse',
        categories: ['animals', 'transportation'],
        terms: ['equus', 'fauna', 'mammmal', 'mare', 'neigh', 'pony'],
        label: 'Horse',
        aliases: {
            unicodes: {
                composite: ['1f40e'],
                secondary: ['10f6f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f0',
    },
    {
        name: 'horse-head',
        categories: ['animals'],
        terms: ['equus', 'fauna', 'mammmal', 'mare', 'neigh', 'pony'],
        label: 'Horse Head',
        aliases: {
            unicodes: {
                secondary: ['10f7ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ab',
    },
    {
        name: 'horse-saddle',
        categories: ['animals', 'transportation', 'travel-hotel'],
        terms: ['cowboy', 'equus', 'fauna', 'mammmal', 'mare', 'neigh', 'pony', 'rodeo', 'western'],
        label: 'Horse Saddle',
        aliases: {
            unicodes: {
                secondary: ['10f8c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c3',
    },
    {
        name: 'hose',
        categories: ['construction', 'household'],
        terms: [],
        label: 'Hose',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e419',
    },
    {
        name: 'hose-reel',
        categories: ['construction', 'household'],
        terms: [],
        label: 'Hose Reel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41a',
    },
    {
        name: 'hospital',
        categories: ['buildings', 'humanitarian', 'maps', 'medical-health'],
        terms: ['building', 'covid-19', 'emergency room', 'medical center'],
        label: 'hospital',
        aliases: {
            names: ['hospital-alt', 'hospital-wide'],
            unicodes: {
                composite: ['1f3e5', 'f47d'],
                primary: ['f47d'],
                secondary: ['10f0f8', '10f47d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f8',
    },
    {
        name: 'hospital-user',
        categories: ['buildings', 'medical-health', 'users-people'],
        terms: ['covid-19', 'doctor', 'network', 'patient', 'primary care'],
        label: 'Hospital with User',
        aliases: {
            unicodes: {
                secondary: ['10f80d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f80d',
    },
    {
        name: 'hospitals',
        categories: ['buildings', 'medical-health'],
        terms: ['emergency', 'insurance', 'network'],
        label: 'Hospitals',
        aliases: {
            unicodes: {
                secondary: ['10f80e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f80e',
    },
    {
        name: 'hot-tub-person',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'Hot tub person',
        aliases: {
            names: ['hot-tub'],
            unicodes: {
                secondary: ['10f593'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f593',
    },
    {
        name: 'hotdog',
        categories: ['food-beverage'],
        terms: [
            'bun',
            'chili',
            'frankfurt',
            'frankfurter',
            'kosher',
            'polish',
            'sandwich',
            'sausage',
            'vienna',
            'weiner',
        ],
        label: 'Hot Dog',
        aliases: {
            unicodes: {
                composite: ['1f32d'],
                secondary: ['10f80f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f80f',
    },
    {
        name: 'hotel',
        categories: ['buildings', 'humanitarian', 'travel-hotel'],
        terms: ['building', 'inn', 'lodging', 'motel', 'resort', 'travel'],
        label: 'Hotel',
        aliases: {
            unicodes: {
                composite: ['1f3e8'],
                secondary: ['10f594'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f594',
    },
    {
        name: 'hourglass',
        categories: ['time'],
        terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
        label: 'Hourglass',
        aliases: {
            names: ['hourglass-2', 'hourglass-half'],
            unicodes: {
                composite: ['f250', '23f3'],
                secondary: ['10f254'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f254',
    },
    {
        name: 'hourglass-clock',
        categories: ['time'],
        terms: [],
        label: 'Hourglass Clock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41b',
    },
    {
        name: 'hourglass-empty',
        categories: ['time'],
        terms: [],
        label: 'Hourglass empty',
        aliases: {
            unicodes: {
                secondary: ['10f252'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f252',
    },
    {
        name: 'hourglass-end',
        categories: ['time'],
        terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
        label: 'Hourglass End',
        aliases: {
            names: ['hourglass-3'],
            unicodes: {
                composite: ['231b'],
                secondary: ['10f253'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f253',
    },
    {
        name: 'hourglass-start',
        categories: ['time'],
        terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
        label: 'Hourglass Start',
        aliases: {
            names: ['hourglass-1'],
            unicodes: {
                secondary: ['10f251'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f251',
    },
    {
        name: 'house',
        categories: ['buildings', 'maps'],
        terms: ['abode', 'building', 'family', 'home', 'residence'],
        label: 'House',
        aliases: {
            names: ['home', 'home-alt', 'home-lg-alt'],
            unicodes: {
                composite: ['f80a', 'f80c', '1f3e0'],
                primary: ['f80a', 'f80c'],
                secondary: ['10f015', '10f80c', '10f80a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f015',
    },
    {
        name: 'house-blank',
        categories: ['buildings'],
        terms: [],
        label: 'House Simple Blank',
        aliases: {
            names: ['home-blank'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e487',
    },
    {
        name: 'house-building',
        categories: ['buildings'],
        terms: [],
        label: 'House Building',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b1',
    },
    {
        name: 'house-chimney',
        categories: ['buildings', 'humanitarian', 'moving'],
        terms: [],
        label: 'House Chimney',
        aliases: {
            names: ['home-lg'],
            unicodes: {
                composite: ['f80b'],
                primary: ['f80b'],
                secondary: ['10f80b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3af',
    },
    {
        name: 'house-chimney-blank',
        categories: ['buildings'],
        terms: [],
        label: 'House Blank',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b0',
    },
    {
        name: 'house-chimney-crack',
        categories: ['buildings', 'disaster', 'humanitarian'],
        terms: [],
        label: 'House crack',
        aliases: {
            names: ['house-damage'],
            unicodes: {
                secondary: ['10f6f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f1',
    },
    {
        name: 'house-chimney-heart',
        categories: ['charity', 'household'],
        terms: [],
        label: 'House Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b2',
    },
    {
        name: 'house-chimney-medical',
        categories: ['buildings', 'medical-health'],
        terms: [],
        label: 'House medical',
        aliases: {
            names: ['clinic-medical'],
            unicodes: {
                secondary: ['10f7f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f2',
    },
    {
        name: 'house-chimney-user',
        categories: ['household', 'users-people'],
        terms: [],
        label: 'House User',
        aliases: {
            unicodes: {
                secondary: ['10e065'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e065',
    },
    {
        name: 'house-chimney-window',
        categories: ['buildings', 'household'],
        terms: [],
        label: 'House with Window + Chimney',
        aliases: {
            unicodes: {
                secondary: ['10e00d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00d',
    },
    {
        name: 'house-circle-check',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'House Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e509',
    },
    {
        name: 'house-circle-exclamation',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'House Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50a',
    },
    {
        name: 'house-circle-xmark',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'House Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50b',
    },
    {
        name: 'house-crack',
        categories: ['buildings', 'disaster'],
        terms: [],
        label: 'House Simple Crack',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b1',
    },
    {
        name: 'house-day',
        categories: ['buildings', 'energy', 'household', 'time'],
        terms: ['abode', 'building', 'family', 'home', 'residence', 'solar', 'sun'],
        label: 'House Day',
        aliases: {
            unicodes: {
                secondary: ['10e00e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00e',
    },
    {
        name: 'house-fire',
        categories: ['buildings', 'disaster', 'household', 'humanitarian', 'security'],
        terms: [],
        label: 'House Fire',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50c',
    },
    {
        name: 'house-flag',
        categories: ['buildings', 'humanitarian'],
        terms: [],
        label: 'House Flag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50d',
    },
    {
        name: 'house-flood-water',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'House Flood',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50e',
    },
    {
        name: 'house-flood-water-circle-arrow-right',
        categories: ['disaster', 'humanitarian'],
        terms: [],
        label: 'House Flood-circle-arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e50f',
    },
    {
        name: 'house-heart',
        categories: ['charity', 'household'],
        terms: [],
        label: 'House Simple Heart',
        aliases: {
            names: ['home-heart'],
            unicodes: {
                secondary: ['10f4c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c9',
    },
    {
        name: 'house-laptop',
        categories: ['business', 'devices-hardware', 'household'],
        terms: [],
        label: 'House laptop',
        aliases: {
            names: ['laptop-house'],
            unicodes: {
                secondary: ['10e066'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e066',
    },
    {
        name: 'house-lock',
        categories: ['buildings', 'household', 'humanitarian', 'security'],
        terms: [],
        label: 'House Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e510',
    },
    {
        name: 'house-medical',
        categories: ['buildings', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'House Simple Medical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b2',
    },
    {
        name: 'house-medical-circle-check',
        categories: ['buildings', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'House Medical-circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e511',
    },
    {
        name: 'house-medical-circle-exclamation',
        categories: ['buildings', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'House Medical-circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e512',
    },
    {
        name: 'house-medical-circle-xmark',
        categories: ['buildings', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'House Medical-circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e513',
    },
    {
        name: 'house-medical-flag',
        categories: ['buildings', 'humanitarian', 'medical-health'],
        terms: [],
        label: 'House Medical-flag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e514',
    },
    {
        name: 'house-night',
        categories: ['buildings', 'energy', 'household', 'time'],
        terms: ['abode', 'building', 'family', 'home', 'moon', 'residence', 'stars'],
        label: 'House Night',
        aliases: {
            unicodes: {
                secondary: ['10e010'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e010',
    },
    {
        name: 'house-person-leave',
        categories: ['household', 'moving', 'users-people'],
        terms: [],
        label: 'House person leave',
        aliases: {
            names: ['house-leave', 'house-person-depart'],
            unicodes: {
                secondary: ['10e00f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e00f',
    },
    {
        name: 'house-person-return',
        categories: ['household', 'moving', 'users-people'],
        terms: [],
        label: 'House person return',
        aliases: {
            names: ['house-person-arrive', 'house-return'],
            unicodes: {
                secondary: ['10e011'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e011',
    },
    {
        name: 'house-signal',
        categories: ['connectivity', 'household', 'humanitarian'],
        terms: ['abode', 'building', 'connect', 'family', 'home', 'residence', 'smart home', 'wifi'],
        label: 'House Signal',
        aliases: {
            unicodes: {
                secondary: ['10e012'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e012',
    },
    {
        name: 'house-tree',
        categories: ['buildings', 'nature'],
        terms: [],
        label: 'House Tree',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b3',
    },
    {
        name: 'house-tsunami',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: [],
        label: 'House Tsunami',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e515',
    },
    {
        name: 'house-turret',
        categories: ['buildings'],
        terms: [],
        label: 'House Turret',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b4',
    },
    {
        name: 'house-user',
        categories: ['household', 'users-people'],
        terms: ['covid-19', 'home', 'isolation', 'quarantine'],
        label: 'Home User',
        aliases: {
            names: ['home-user'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b0',
    },
    {
        name: 'house-water',
        categories: ['buildings', 'disaster', 'weather'],
        terms: [],
        label: 'House with Water',
        aliases: {
            names: ['house-flood'],
            unicodes: {
                secondary: ['10f74f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74f',
    },
    {
        name: 'house-window',
        categories: ['buildings'],
        terms: [],
        label: 'House Window',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b3',
    },
    {
        name: 'hryvnia-sign',
        categories: ['money'],
        terms: [],
        label: 'Hryvnia sign',
        aliases: {
            names: ['hryvnia'],
            unicodes: {
                composite: ['20b4'],
                secondary: ['10f6f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f2',
    },
    {
        name: 'hundred-points',
        categories: ['communication', 'marketing', 'numbers'],
        terms: [],
        label: 'Hundred Points',
        aliases: {
            names: ['100'],
            unicodes: {
                composite: ['1f4af'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41c',
    },
    {
        name: 'hurricane',
        categories: ['disaster', 'humanitarian', 'spinners', 'weather'],
        terms: ['coriolis effect', 'eye', 'storm', 'tropical cyclone', 'typhoon'],
        label: 'Hurricane',
        aliases: {
            unicodes: {
                secondary: ['10f751'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f751',
    },
    {
        name: 'hyphen',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Hyphen',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2d',
    },
    {
        name: 'i',
        categories: ['alphabet'],
        terms: [],
        label: 'I',
        aliases: {
            unicodes: {
                composite: ['69'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '49',
    },
    {
        name: 'i-cursor',
        categories: ['text-formatting'],
        terms: ['editing', 'i-beam', 'type', 'writing'],
        label: 'I Beam Cursor',
        aliases: {
            unicodes: {
                secondary: ['10f246'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f246',
    },
    {
        name: 'ice-cream',
        categories: ['childhood', 'food-beverage'],
        terms: ['chocolate', 'cone', 'dessert', 'frozen', 'scoop', 'sorbet', 'vanilla', 'yogurt'],
        label: 'Ice Cream',
        aliases: {
            unicodes: {
                composite: ['1f368'],
                secondary: ['10f810'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f810',
    },
    {
        name: 'ice-skate',
        categories: ['clothing-fashion', 'sports-fitness'],
        terms: ['blade', 'clothing', 'figure skating', 'hockey', 'seasonal', 'shoe'],
        label: 'Ice Skate',
        aliases: {
            unicodes: {
                composite: ['26f8'],
                secondary: ['10f7ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ac',
    },
    {
        name: 'icicles',
        categories: ['nature', 'weather'],
        terms: ['cold', 'frozen', 'hanging', 'ice', 'seasonal', 'sharp'],
        label: 'Icicles',
        aliases: {
            unicodes: {
                secondary: ['10f7ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ad',
    },
    {
        name: 'icons',
        categories: ['communication', 'design', 'social', 'text-formatting'],
        terms: ['bolt', 'emoji', 'heart', 'image', 'music', 'photo', 'symbols'],
        label: 'Icons',
        aliases: {
            names: ['heart-music-camera-bolt'],
            unicodes: {
                secondary: ['10f86d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86d',
    },
    {
        name: 'id-badge',
        categories: ['photos-images', 'security', 'users-people'],
        terms: ['address', 'contact', 'identification', 'license', 'profile'],
        label: 'Identification Badge',
        aliases: {
            unicodes: {
                secondary: ['10f2c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2c1',
    },
    {
        name: 'id-card',
        categories: ['humanitarian', 'photos-images', 'security', 'users-people'],
        terms: ['contact', 'demographics', 'document', 'identification', 'issued', 'profile'],
        label: 'Identification Card',
        aliases: {
            names: ['drivers-license'],
            unicodes: {
                composite: ['f2c3'],
                secondary: ['10f2c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2c2',
    },
    {
        name: 'id-card-clip',
        categories: ['medical-health', 'security', 'users-people'],
        terms: [],
        label: 'Id card clip',
        aliases: {
            names: ['id-card-alt'],
            unicodes: {
                secondary: ['10f47f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f47f',
    },
    {
        name: 'igloo',
        categories: ['buildings'],
        terms: ['dome', 'dwelling', 'eskimo', 'home', 'house', 'ice', 'snow'],
        label: 'Igloo',
        aliases: {
            unicodes: {
                secondary: ['10f7ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ae',
    },
    {
        name: 'image',
        categories: ['maps', 'photos-images', 'social'],
        terms: ['album', 'landscape', 'photo', 'picture'],
        label: 'Image',
        aliases: {
            unicodes: {
                secondary: ['10f03e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f03e',
    },
    {
        name: 'image-landscape',
        categories: ['photos-images'],
        terms: [],
        label: 'Image landscape',
        aliases: {
            names: ['landscape'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b5',
    },
    {
        name: 'image-polaroid',
        categories: ['design', 'photos-images'],
        terms: ['capture', 'film', 'instant camera', 'lens', 'photo', 'photography', 'retro', 'snapshot', 'vintage'],
        label: 'Polaroid Image',
        aliases: {
            unicodes: {
                secondary: ['10f8c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c4',
    },
    {
        name: 'image-polaroid-user',
        categories: ['photos-images', 'users-people'],
        terms: [],
        label: 'Image Polaroid-user',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b6',
    },
    {
        name: 'image-portrait',
        categories: ['photos-images', 'users-people'],
        terms: [],
        label: 'Image portrait',
        aliases: {
            names: ['portrait'],
            unicodes: {
                secondary: ['10f3e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3e0',
    },
    {
        name: 'image-slash',
        categories: ['photos-images'],
        terms: [],
        label: 'Image Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b7',
    },
    {
        name: 'image-user',
        categories: ['photos-images', 'users-people'],
        terms: [],
        label: 'Image User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b8',
    },
    {
        name: 'images',
        categories: ['maps', 'photos-images', 'social'],
        terms: ['album', 'landscape', 'photo', 'picture'],
        label: 'Images',
        aliases: {
            unicodes: {
                secondary: ['10f302'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f302',
    },
    {
        name: 'images-user',
        categories: ['photos-images', 'users-people'],
        terms: [],
        label: 'Images User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1b9',
    },
    {
        name: 'inbox',
        categories: ['communication'],
        terms: ['archive', 'desk', 'email', 'mail', 'message'],
        label: 'inbox',
        aliases: {
            unicodes: {
                secondary: ['10f01c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f01c',
    },
    {
        name: 'inbox-full',
        categories: ['business'],
        terms: [],
        label: 'Inbox Full',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ba',
    },
    {
        name: 'inbox-in',
        categories: ['arrows', 'communication'],
        terms: ['archive', 'desk', 'email', 'mail', 'message'],
        label: 'Inbox In',
        aliases: {
            names: ['inbox-arrow-down'],
            unicodes: {
                composite: ['1f4e5'],
                secondary: ['10f310'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f310',
    },
    {
        name: 'inbox-out',
        categories: ['arrows', 'communication'],
        terms: ['archive', 'desk', 'email', 'mail', 'message'],
        label: 'Inbox Out',
        aliases: {
            names: ['inbox-arrow-up'],
            unicodes: {
                composite: ['1f4e4'],
                secondary: ['10f311'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f311',
    },
    {
        name: 'inboxes',
        categories: ['business'],
        terms: [],
        label: 'Inboxes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1bb',
    },
    {
        name: 'indent',
        categories: ['text-formatting'],
        terms: ['align', 'justify', 'paragraph', 'tab'],
        label: 'Indent',
        aliases: {
            unicodes: {
                secondary: ['10f03c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f03c',
    },
    {
        name: 'indian-rupee-sign',
        categories: ['money'],
        terms: [],
        label: 'Indian Rupee-sign',
        aliases: {
            names: ['indian-rupee', 'inr'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1bc',
    },
    {
        name: 'industry',
        categories: ['buildings', 'business', 'energy', 'maps'],
        terms: ['building', 'factory', 'industrial', 'manufacturing', 'mill', 'warehouse'],
        label: 'Industry',
        aliases: {
            unicodes: {
                secondary: ['10f275'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f275',
    },
    {
        name: 'industry-windows',
        categories: ['buildings', 'business', 'energy', 'maps'],
        terms: [],
        label: 'Industry windows',
        aliases: {
            names: ['industry-alt'],
            unicodes: {
                composite: ['1f3ed'],
                secondary: ['10f3b3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3b3',
    },
    {
        name: 'infinity',
        categories: ['mathematics', 'travel-hotel'],
        terms: ['eternity', 'forever', 'math'],
        label: 'Infinity',
        aliases: {
            unicodes: {
                composite: ['267e', '221e'],
                secondary: ['10f534'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f534',
    },
    {
        name: 'info',
        categories: ['maps'],
        terms: ['details', 'help', 'information', 'more', 'support'],
        label: 'Info',
        aliases: {
            unicodes: {
                secondary: ['10f129'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f129',
    },
    {
        name: 'inhaler',
        categories: ['medical-health'],
        terms: ['asthma', 'cough', 'lungs', 'nebulizer', 'vaporizer'],
        label: 'Inhaler',
        aliases: {
            unicodes: {
                secondary: ['10f5f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f9',
    },
    {
        name: 'input-numeric',
        categories: ['writing'],
        terms: [],
        label: 'Input Numeric',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1bd',
    },
    {
        name: 'input-pipe',
        categories: ['writing'],
        terms: [],
        label: 'Input Pipe',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1be',
    },
    {
        name: 'input-text',
        categories: ['writing'],
        terms: [],
        label: 'Input Text',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1bf',
    },
    {
        name: 'integral',
        categories: ['mathematics'],
        terms: ['calculus', 'equation', 'function', 'math'],
        label: 'Integral',
        aliases: {
            unicodes: {
                secondary: ['10f667'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f667',
    },
    {
        name: 'intersection',
        categories: ['mathematics'],
        terms: ['calculus', 'equation', 'function', 'math'],
        label: 'Intersection',
        aliases: {
            unicodes: {
                composite: ['22c2'],
                secondary: ['10f668'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f668',
    },
    {
        name: 'island-tropical',
        categories: ['travel-hotel'],
        terms: ['castaway', 'desert', 'palm tree', 'sand', 'stranded', 'summer', 'vacation'],
        label: 'Tropical Island',
        aliases: {
            names: ['island-tree-palm'],
            unicodes: {
                composite: ['1f3dd'],
                secondary: ['10f811'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f811',
    },
    {
        name: 'italic',
        categories: ['text-formatting'],
        terms: ['edit', 'emphasis', 'font', 'format', 'text', 'type'],
        label: 'italic',
        aliases: {
            unicodes: {
                secondary: ['10f033'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f033',
    },
    {
        name: 'j',
        categories: ['alphabet'],
        terms: [],
        label: 'J',
        aliases: {
            unicodes: {
                composite: ['6a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4a',
    },
    {
        name: 'jack-o-lantern',
        categories: ['halloween', 'holidays'],
        terms: ['carve', 'face', 'halloween', 'lantern', 'pumpkin', 'smile'],
        label: "Jack-o'-lantern",
        aliases: {
            unicodes: {
                composite: ['1f383'],
                secondary: ['10f30e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30e',
    },
    {
        name: 'jar',
        categories: ['food-beverage', 'household', 'humanitarian'],
        terms: [],
        label: 'Jar',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e516',
    },
    {
        name: 'jar-wheat',
        categories: ['food-beverage', 'household', 'humanitarian'],
        terms: [],
        label: 'Jar Wheat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e517',
    },
    {
        name: 'jedi',
        categories: ['religion', 'science-fiction'],
        terms: ['crest', 'force', 'sith', 'skywalker', 'star wars', 'yoda'],
        label: 'Jedi',
        aliases: {
            unicodes: {
                secondary: ['10f669'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f669',
    },
    {
        name: 'jedi-order',
        categories: ['science-fiction'],
        terms: [],
        label: 'Jedi Order',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f50e',
    },
    {
        name: 'jet-fighter',
        categories: ['maps', 'transportation'],
        terms: [],
        label: 'Jet fighter',
        aliases: {
            names: ['fighter-jet'],
            unicodes: {
                secondary: ['10f0fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0fb',
    },
    {
        name: 'jet-fighter-up',
        categories: ['humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Jet Fighter Up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e518',
    },
    {
        name: 'joint',
        categories: ['medical-health'],
        terms: ['blunt', 'cannabis', 'doobie', 'drugs', 'marijuana', 'roach', 'smoke', 'smoking', 'spliff'],
        label: 'Joint',
        aliases: {
            unicodes: {
                secondary: ['10f595'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f595',
    },
    {
        name: 'joystick',
        categories: ['gaming'],
        terms: ['arcade', 'atari', 'controller', 'retro', 'video game', 'vintage'],
        label: 'Joystick',
        aliases: {
            unicodes: {
                composite: ['1f579'],
                secondary: ['10f8c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c5',
    },
    {
        name: 'jug',
        categories: ['food-beverage'],
        terms: ['beverage', 'bottle', 'drink', 'moonshine', 'pitcher', 'western'],
        label: 'Jug',
        aliases: {
            unicodes: {
                secondary: ['10f8c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c6',
    },
    {
        name: 'jug-detergent',
        categories: ['household', 'humanitarian'],
        terms: [],
        label: 'Jug Detergent',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e519',
    },
    {
        name: 'k',
        categories: ['alphabet'],
        terms: [],
        label: 'K',
        aliases: {
            unicodes: {
                composite: ['6b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4b',
    },
    {
        name: 'kaaba',
        categories: ['buildings', 'religion'],
        terms: ['building', 'cube', 'islam', 'muslim'],
        label: 'Kaaba',
        aliases: {
            unicodes: {
                composite: ['1f54b'],
                secondary: ['10f66b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66b',
    },
    {
        name: 'kazoo',
        categories: ['music-audio'],
        terms: ['buzz', 'instrument', 'membranophone', 'mirliton', 'music'],
        label: 'Kazoo',
        aliases: {
            unicodes: {
                secondary: ['10f8c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c7',
    },
    {
        name: 'kerning',
        categories: ['text-formatting'],
        terms: ['adjust', 'font', 'spacing', 'text', 'tracking', 'type', 'typography'],
        label: 'Kerning',
        aliases: {
            unicodes: {
                secondary: ['10f86f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86f',
    },
    {
        name: 'key',
        categories: ['maps', 'security', 'shopping', 'travel-hotel'],
        terms: ['lock', 'password', 'private', 'secret', 'unlock'],
        label: 'key',
        aliases: {
            unicodes: {
                composite: ['1f511'],
                secondary: ['10f084'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f084',
    },
    {
        name: 'key-skeleton',
        categories: ['halloween', 'science', 'security'],
        terms: ['halloween', 'lock', 'password', 'private', 'secret', 'unlock'],
        label: 'Key Skeleton',
        aliases: {
            unicodes: {
                composite: ['1f5dd'],
                secondary: ['10f6f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f3',
    },
    {
        name: 'key-skeleton-left-right',
        categories: ['coding'],
        terms: [],
        label: 'Key Skeleton Left Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b4',
    },
    {
        name: 'keyboard',
        categories: ['coding', 'devices-hardware', 'writing'],
        terms: ['accessory', 'edit', 'input', 'text', 'type', 'write'],
        label: 'Keyboard',
        aliases: {
            unicodes: {
                composite: ['2328'],
                secondary: ['10f11c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f11c',
    },
    {
        name: 'keyboard-brightness',
        categories: ['accessibility', 'toggle'],
        terms: [],
        label: 'Keyboard Brightness',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c0',
    },
    {
        name: 'keyboard-brightness-low',
        categories: ['accessibility', 'toggle'],
        terms: [],
        label: 'Keyboard Brightness-low',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c1',
    },
    {
        name: 'keyboard-down',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Keyboard Down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c2',
    },
    {
        name: 'keyboard-left',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Keyboard Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c3',
    },
    {
        name: 'keynote',
        categories: ['business'],
        terms: ['lecture', 'panel', 'seminar', 'speak', 'speaker', 'talk'],
        label: 'Keynote',
        aliases: {
            unicodes: {
                secondary: ['10f66c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66c',
    },
    {
        name: 'khanda',
        categories: ['religion'],
        terms: ['chakkar', 'sikh', 'sikhism', 'sword'],
        label: 'Khanda',
        aliases: {
            unicodes: {
                composite: ['262c'],
                secondary: ['10f66d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66d',
    },
    {
        name: 'kidneys',
        categories: ['medical-health'],
        terms: ['blood', 'body', 'organ', 'surgery', 'urine'],
        label: 'Kidneys',
        aliases: {
            unicodes: {
                secondary: ['10f5fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5fb',
    },
    {
        name: 'kip-sign',
        categories: ['money'],
        terms: [],
        label: 'Kip Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c4',
    },
    {
        name: 'kit-medical',
        categories: ['camping', 'medical-health'],
        terms: [],
        label: 'Kit medical',
        aliases: {
            names: ['first-aid'],
            unicodes: {
                secondary: ['10f479'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f479',
    },
    {
        name: 'kitchen-set',
        categories: ['household', 'humanitarian', 'travel-hotel'],
        terms: [],
        label: 'Kitchen Set',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51a',
    },
    {
        name: 'kite',
        categories: ['science'],
        terms: ['air', 'benjamin', 'fall', 'flying', 'franklin', 'outdoors', 'seasonal', 'sky', 'wind'],
        label: 'Kite',
        aliases: {
            unicodes: {
                composite: ['1fa81'],
                secondary: ['10f6f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f4',
    },
    {
        name: 'kiwi-bird',
        categories: ['animals'],
        terms: ['bird', 'fauna', 'new zealand'],
        label: 'Kiwi Bird',
        aliases: {
            unicodes: {
                secondary: ['10f535'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f535',
    },
    {
        name: 'kiwi-fruit',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Kiwi Fruit',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e30c',
    },
    {
        name: 'knife',
        categories: ['maps'],
        terms: [],
        label: 'Knife',
        aliases: {
            names: ['utensil-knife'],
            unicodes: {
                secondary: ['10f2e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e4',
    },
    {
        name: 'knife-kitchen',
        categories: ['halloween'],
        terms: ['chef', 'cut', 'halloween', 'sharp', 'slice', 'tool'],
        label: 'Knife Kitchen',
        aliases: {
            unicodes: {
                composite: ['1f52a'],
                secondary: ['10f6f5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f5',
    },
    {
        name: 'l',
        categories: ['alphabet'],
        terms: [],
        label: 'L',
        aliases: {
            unicodes: {
                composite: ['6c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4c',
    },
    {
        name: 'lacrosse-stick',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Lacrosse Stick',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b5',
    },
    {
        name: 'lacrosse-stick-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Lacrosse Stick + Ball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b6',
    },
    {
        name: 'lambda',
        categories: ['mathematics'],
        terms: ['calculus', 'equation', 'function', 'math'],
        label: 'Lambda',
        aliases: {
            unicodes: {
                secondary: ['10f66e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66e',
    },
    {
        name: 'lamp',
        categories: ['household', 'moving'],
        terms: ['bright', 'furniture', 'light'],
        label: 'Lamp',
        aliases: {
            unicodes: {
                secondary: ['10f4ca'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ca',
    },
    {
        name: 'lamp-desk',
        categories: ['business', 'household', 'travel-hotel'],
        terms: ['bright', 'furniture', 'light'],
        label: 'Lamp Desk',
        aliases: {
            unicodes: {
                secondary: ['10e014'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e014',
    },
    {
        name: 'lamp-floor',
        categories: ['household'],
        terms: ['bright', 'furniture', 'light'],
        label: 'Lamp Floor',
        aliases: {
            unicodes: {
                secondary: ['10e015'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e015',
    },
    {
        name: 'lamp-street',
        categories: ['energy'],
        terms: [],
        label: 'Lamp Street',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c5',
    },
    {
        name: 'land-mine-on',
        categories: ['humanitarian', 'security'],
        terms: [],
        label: 'Land Mine-on',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51b',
    },
    {
        name: 'landmark',
        categories: ['buildings', 'business', 'humanitarian', 'maps', 'money'],
        terms: ['building', 'historic', 'memorable', 'monument', 'politics'],
        label: 'Landmark',
        aliases: {
            unicodes: {
                composite: ['1f3db'],
                secondary: ['10f66f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f66f',
    },
    {
        name: 'landmark-dome',
        categories: ['buildings', 'humanitarian', 'political'],
        terms: [],
        label: 'Landmark dome',
        aliases: {
            names: ['landmark-alt'],
            unicodes: {
                secondary: ['10f752'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f752',
    },
    {
        name: 'landmark-flag',
        categories: ['buildings', 'humanitarian', 'maps', 'political'],
        terms: [],
        label: 'Landmark Flag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51c',
    },
    {
        name: 'language',
        categories: ['communication'],
        terms: ['dialect', 'idiom', 'localize', 'speech', 'translate', 'vernacular'],
        label: 'Language',
        aliases: {
            unicodes: {
                secondary: ['10f1ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ab',
    },
    {
        name: 'laptop',
        categories: ['devices-hardware', 'humanitarian'],
        terms: ['computer', 'cpu', 'dell', 'demo', 'device', 'mac', 'macbook', 'machine', 'pc'],
        label: 'Laptop',
        aliases: {
            unicodes: {
                composite: ['1f4bb'],
                secondary: ['10f109'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f109',
    },
    {
        name: 'laptop-arrow-down',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Laptop Arrow-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c6',
    },
    {
        name: 'laptop-code',
        categories: ['coding', 'education'],
        terms: ['computer', 'cpu', 'dell', 'demo', 'develop', 'device', 'mac', 'macbook', 'machine', 'pc'],
        label: 'Laptop Code',
        aliases: {
            unicodes: {
                secondary: ['10f5fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5fc',
    },
    {
        name: 'laptop-file',
        categories: ['business', 'devices-hardware', 'education', 'humanitarian'],
        terms: [],
        label: 'Laptop File',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51d',
    },
    {
        name: 'laptop-medical',
        categories: ['medical-health'],
        terms: ['computer', 'device', 'ehr', 'electronic health records', 'history'],
        label: 'Laptop Medical',
        aliases: {
            unicodes: {
                secondary: ['10f812'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f812',
    },
    {
        name: 'laptop-mobile',
        categories: ['coding', 'devices-hardware'],
        terms: [],
        label: 'Laptop mobile',
        aliases: {
            names: ['phone-laptop'],
            unicodes: {
                secondary: ['10f87a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87a',
    },
    {
        name: 'laptop-slash',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Laptop Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c7',
    },
    {
        name: 'lari-sign',
        categories: ['money'],
        terms: [],
        label: 'Lari Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c8',
    },
    {
        name: 'lasso',
        categories: ['design', 'editing'],
        terms: ['cowboy', 'rodeo', 'rope', 'select', 'selection', 'western'],
        label: 'Lasso',
        aliases: {
            unicodes: {
                secondary: ['10f8c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c8',
    },
    {
        name: 'lasso-sparkles',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Lasso Sparkles',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1c9',
    },
    {
        name: 'layer-group',
        categories: ['design', 'maps'],
        terms: ['arrange', 'develop', 'layers', 'map', 'stack'],
        label: 'Layer Group',
        aliases: {
            unicodes: {
                secondary: ['10f5fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5fd',
    },
    {
        name: 'layer-minus',
        categories: ['design', 'maps'],
        terms: ['arrange', 'delete', 'negative', 'remove', 'stack'],
        label: 'Layer Minus',
        aliases: {
            names: ['layer-group-minus'],
            unicodes: {
                secondary: ['10f5fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5fe',
    },
    {
        name: 'layer-plus',
        categories: ['design', 'maps'],
        terms: ['add', 'arrange', 'create', 'new', 'positive', 'stack'],
        label: 'Layer Plus',
        aliases: {
            names: ['layer-group-plus'],
            unicodes: {
                secondary: ['10f5ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ff',
    },
    {
        name: 'leaf',
        categories: ['charity', 'energy', 'fruits-vegetables', 'maps', 'nature'],
        terms: ['eco', 'flora', 'nature', 'plant', 'vegan'],
        label: 'leaf',
        aliases: {
            unicodes: {
                secondary: ['10f06c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f06c',
    },
    {
        name: 'leaf-heart',
        categories: ['charity'],
        terms: ['eco', 'flora', 'nature', 'plant', 'vegan'],
        label: 'Leaf with a Heart',
        aliases: {
            unicodes: {
                secondary: ['10f4cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4cb',
    },
    {
        name: 'leaf-maple',
        categories: ['nature'],
        terms: ['eco', 'fall', 'flora', 'nature', 'plant', 'seasonal'],
        label: 'Maple Leaf',
        aliases: {
            unicodes: {
                composite: ['1f341'],
                secondary: ['10f6f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f6',
    },
    {
        name: 'leaf-oak',
        categories: ['nature'],
        terms: ['eco', 'fall', 'flora', 'nature', 'plant', 'seasonal'],
        label: 'Oak Leaf',
        aliases: {
            unicodes: {
                secondary: ['10f6f7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f7',
    },
    {
        name: 'leafy-green',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Leafy Green',
        aliases: {
            unicodes: {
                composite: ['1f96c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41d',
    },
    {
        name: 'left',
        categories: ['arrows'],
        terms: [],
        label: 'Left',
        aliases: {
            names: ['arrow-alt-left'],
            unicodes: {
                secondary: ['10f355'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f355',
    },
    {
        name: 'left-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Left from line',
        aliases: {
            names: ['arrow-alt-from-right'],
            unicodes: {
                secondary: ['10f348'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f348',
    },
    {
        name: 'left-long',
        categories: ['arrows'],
        terms: [],
        label: 'Left long',
        aliases: {
            names: ['long-arrow-alt-left'],
            unicodes: {
                secondary: ['10f30a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30a',
    },
    {
        name: 'left-long-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Left Long-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41e',
    },
    {
        name: 'left-right',
        categories: ['arrows'],
        terms: [],
        label: 'Left right',
        aliases: {
            names: ['arrows-alt-h'],
            unicodes: {
                composite: ['2194'],
                secondary: ['10f337'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f337',
    },
    {
        name: 'left-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Left to line',
        aliases: {
            names: ['arrow-alt-to-left'],
            unicodes: {
                secondary: ['10f34b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f34b',
    },
    {
        name: 'lemon',
        categories: ['food-beverage', 'fruits-vegetables', 'maps'],
        terms: ['citrus', 'lemonade', 'lime', 'tart'],
        label: 'Lemon',
        aliases: {
            unicodes: {
                composite: ['1f34b'],
                secondary: ['10f094'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f094',
    },
    {
        name: 'less-than',
        categories: ['mathematics', 'punctuation-symbols'],
        terms: ['arithmetic', 'compare', 'math'],
        label: 'Less Than',
        aliases: {
            unicodes: {
                composite: ['f536'],
                primary: ['f536'],
                secondary: ['10f536', '103c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3c',
    },
    {
        name: 'less-than-equal',
        categories: ['mathematics'],
        terms: ['arithmetic', 'compare', 'math'],
        label: 'Less Than Equal To',
        aliases: {
            unicodes: {
                secondary: ['10f537'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f537',
    },
    {
        name: 'life-ring',
        categories: ['humanitarian', 'maps', 'spinners'],
        terms: ['coast guard', 'help', 'overboard', 'save', 'support'],
        label: 'Life Ring',
        aliases: {
            unicodes: {
                secondary: ['10f1cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1cd',
    },
    {
        name: 'light-ceiling',
        categories: ['household'],
        terms: ['bright', 'furniture', 'light', 'overhead'],
        label: 'Light Ceiling',
        aliases: {
            unicodes: {
                secondary: ['10e016'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e016',
    },
    {
        name: 'light-emergency',
        categories: ['alert', 'security'],
        terms: [],
        label: 'Light Emergency',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e41f',
    },
    {
        name: 'light-emergency-on',
        categories: ['alert', 'security'],
        terms: [],
        label: 'Light Emergency-on',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e420',
    },
    {
        name: 'light-switch',
        categories: ['household'],
        terms: ['light', 'off', 'on'],
        label: 'Light Switch',
        aliases: {
            unicodes: {
                secondary: ['10e017'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e017',
    },
    {
        name: 'light-switch-off',
        categories: ['household'],
        terms: ['light', 'off', 'on'],
        label: 'Light Switch-off',
        aliases: {
            unicodes: {
                secondary: ['10e018'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e018',
    },
    {
        name: 'light-switch-on',
        categories: ['household'],
        terms: ['light', 'off', 'on'],
        label: 'Light Switch-on',
        aliases: {
            unicodes: {
                secondary: ['10e019'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e019',
    },
    {
        name: 'lightbulb',
        categories: ['energy', 'household', 'maps', 'marketing'],
        terms: ['energy', 'idea', 'inspiration', 'light'],
        label: 'Lightbulb',
        aliases: {
            unicodes: {
                composite: ['1f4a1'],
                secondary: ['10f0eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0eb',
    },
    {
        name: 'lightbulb-dollar',
        categories: ['marketing'],
        terms: ['energy', 'idea', 'inspiration', 'light', 'money'],
        label: 'Lightbulb Dollar',
        aliases: {
            unicodes: {
                secondary: ['10f670'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f670',
    },
    {
        name: 'lightbulb-exclamation',
        categories: ['alert', 'marketing'],
        terms: ['alert', 'energy', 'idea', 'inspiration', 'light'],
        label: 'Lightbulb Exclamation',
        aliases: {
            unicodes: {
                secondary: ['10f671'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f671',
    },
    {
        name: 'lightbulb-exclamation-on',
        categories: ['alert'],
        terms: [],
        label: 'Lightbulb Exclamation-on',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ca',
    },
    {
        name: 'lightbulb-on',
        categories: ['household', 'marketing'],
        terms: ['energy', 'idea', 'inspiration', 'light', 'shine'],
        label: 'Lightbulb On',
        aliases: {
            unicodes: {
                secondary: ['10f672'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f672',
    },
    {
        name: 'lightbulb-slash',
        categories: ['marketing'],
        terms: ['dark', 'light', 'off'],
        label: 'Lightbulb Slash',
        aliases: {
            unicodes: {
                secondary: ['10f673'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f673',
    },
    {
        name: 'lights-holiday',
        categories: ['holidays'],
        terms: ['bulb', 'christmas', 'decoration', 'holiday', 'string', 'xmas'],
        label: 'Holiday Lights',
        aliases: {
            unicodes: {
                secondary: ['10f7b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b2',
    },
    {
        name: 'line-columns',
        categories: ['text-formatting'],
        terms: ['divide', 'organize', 'panes', 'split'],
        label: 'Line Columns',
        aliases: {
            unicodes: {
                secondary: ['10f870'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f870',
    },
    {
        name: 'line-height',
        categories: ['text-formatting'],
        terms: ['baseline', 'letter', 'spacing', 'text'],
        label: 'Line Height',
        aliases: {
            unicodes: {
                secondary: ['10f871'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f871',
    },
    {
        name: 'lines-leaning',
        categories: ['design', 'humanitarian', 'shapes'],
        terms: [],
        label: 'Lines Leaning',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51e',
    },
    {
        name: 'link',
        categories: ['editing'],
        terms: ['attach', 'attachment', 'chain', 'connect'],
        label: 'Link',
        aliases: {
            names: ['chain'],
            unicodes: {
                composite: ['1f517'],
                secondary: ['10f0c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c1',
    },
    {
        name: 'link-horizontal',
        categories: ['connectivity'],
        terms: [],
        label: 'Link Horizontal',
        aliases: {
            names: ['chain-horizontal'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1cb',
    },
    {
        name: 'link-horizontal-slash',
        categories: ['connectivity'],
        terms: [],
        label: 'Link Horizontal Slash',
        aliases: {
            names: ['chain-horizontal-slash'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1cc',
    },
    {
        name: 'link-simple',
        categories: ['editing'],
        terms: [],
        label: 'Link Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1cd',
    },
    {
        name: 'link-simple-slash',
        categories: ['editing'],
        terms: [],
        label: 'Link Simple Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ce',
    },
    {
        name: 'link-slash',
        categories: ['editing'],
        terms: [],
        label: 'Link Slash',
        aliases: {
            names: ['chain-broken', 'chain-slash', 'unlink'],
            unicodes: {
                secondary: ['10f127'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f127',
    },
    {
        name: 'lips',
        categories: ['medical-health'],
        terms: ['fashion', 'kiss', 'lipstick', 'mouth', 'smile'],
        label: 'Lips',
        aliases: {
            unicodes: {
                composite: ['1f5e2', '1f444'],
                secondary: ['10f600'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f600',
    },
    {
        name: 'lira-sign',
        categories: ['money'],
        terms: ['currency', 'money', 'try', 'turkish'],
        label: 'Turkish Lira Sign',
        aliases: {
            unicodes: {
                composite: ['20a4'],
                secondary: ['10f195'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f195',
    },
    {
        name: 'list',
        categories: ['text-formatting'],
        terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
        label: 'List',
        aliases: {
            names: ['list-squares'],
            unicodes: {
                secondary: ['10f03a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f03a',
    },
    {
        name: 'list-check',
        categories: ['business', 'text-formatting'],
        terms: [],
        label: 'List check',
        aliases: {
            names: ['tasks'],
            unicodes: {
                secondary: ['10f0ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ae',
    },
    {
        name: 'list-dropdown',
        categories: ['business'],
        terms: [],
        label: 'List Dropdown',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1cf',
    },
    {
        name: 'list-music',
        categories: ['music-audio'],
        terms: ['album', 'itunes', 'music', 'playlist', 'podcast', 'soundtrack'],
        label: 'List Music',
        aliases: {
            unicodes: {
                secondary: ['10f8c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8c9',
    },
    {
        name: 'list-ol',
        categories: ['text-formatting'],
        terms: ['checklist', 'completed', 'done', 'finished', 'numbers', 'ol', 'todo', 'ul'],
        label: 'list-ol',
        aliases: {
            names: ['list-1-2', 'list-numeric'],
            unicodes: {
                secondary: ['10f0cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0cb',
    },
    {
        name: 'list-radio',
        categories: ['business'],
        terms: [],
        label: 'List Radio',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d0',
    },
    {
        name: 'list-timeline',
        categories: ['business'],
        terms: [],
        label: 'List Timeline',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d1',
    },
    {
        name: 'list-tree',
        categories: ['business'],
        terms: [],
        label: 'List Tree',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d2',
    },
    {
        name: 'list-ul',
        categories: ['text-formatting'],
        terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
        label: 'list-ul',
        aliases: {
            names: ['list-dots'],
            unicodes: {
                secondary: ['10f0ca'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ca',
    },
    {
        name: 'litecoin-sign',
        categories: ['money'],
        terms: [],
        label: 'Litecoin Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d3',
    },
    {
        name: 'loader',
        categories: ['spinners'],
        terms: [],
        label: 'Loader',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d4',
    },
    {
        name: 'lobster',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: [],
        label: 'Lobster',
        aliases: {
            unicodes: {
                composite: ['1f99e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e421',
    },
    {
        name: 'location-arrow',
        categories: ['arrows', 'maps'],
        terms: ['address', 'compass', 'coordinate', 'direction', 'gps', 'map', 'navigation', 'place'],
        label: 'location-arrow',
        aliases: {
            unicodes: {
                secondary: ['10f124'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f124',
    },
    {
        name: 'location-check',
        categories: ['maps'],
        terms: [],
        label: 'Location check',
        aliases: {
            names: ['map-marker-check'],
            unicodes: {
                secondary: ['10f606'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f606',
    },
    {
        name: 'location-crosshairs',
        categories: ['maps', 'toggle'],
        terms: [],
        label: 'Location Crosshairs',
        aliases: {
            names: ['location'],
            unicodes: {
                secondary: ['10f601'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f601',
    },
    {
        name: 'location-crosshairs-slash',
        categories: ['maps', 'toggle'],
        terms: [],
        label: 'Location crosshairs slash',
        aliases: {
            names: ['location-slash'],
            unicodes: {
                secondary: ['10f603'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f603',
    },
    {
        name: 'location-dot',
        categories: ['maps', 'social'],
        terms: [],
        label: 'Location dot',
        aliases: {
            names: ['map-marker-alt'],
            unicodes: {
                secondary: ['10f3c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3c5',
    },
    {
        name: 'location-dot-slash',
        categories: ['maps'],
        terms: [],
        label: 'Location dot slash',
        aliases: {
            names: ['map-marker-alt-slash'],
            unicodes: {
                secondary: ['10f605'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f605',
    },
    {
        name: 'location-exclamation',
        categories: ['alert', 'maps'],
        terms: [],
        label: 'Location exclamation',
        aliases: {
            names: ['map-marker-exclamation'],
            unicodes: {
                secondary: ['10f608'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f608',
    },
    {
        name: 'location-minus',
        categories: ['maps'],
        terms: [],
        label: 'Location minus',
        aliases: {
            names: ['map-marker-minus'],
            unicodes: {
                secondary: ['10f609'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f609',
    },
    {
        name: 'location-pen',
        categories: ['maps'],
        terms: [],
        label: 'Location pen',
        aliases: {
            names: ['map-marker-edit'],
            unicodes: {
                secondary: ['10f607'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f607',
    },
    {
        name: 'location-pin',
        categories: ['maps', 'shapes', 'social'],
        terms: [],
        label: 'Location',
        aliases: {
            names: ['map-marker'],
            unicodes: {
                secondary: ['10f041'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f041',
    },
    {
        name: 'location-pin-lock',
        categories: ['humanitarian', 'maps'],
        terms: [],
        label: 'Location Pin-lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e51f',
    },
    {
        name: 'location-pin-slash',
        categories: ['maps'],
        terms: [],
        label: 'Location slash',
        aliases: {
            names: ['map-marker-slash'],
            unicodes: {
                secondary: ['10f60c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f60c',
    },
    {
        name: 'location-plus',
        categories: ['maps'],
        terms: [],
        label: 'Location plus',
        aliases: {
            names: ['map-marker-plus'],
            unicodes: {
                secondary: ['10f60a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f60a',
    },
    {
        name: 'location-question',
        categories: ['maps'],
        terms: [],
        label: 'Location question',
        aliases: {
            names: ['map-marker-question'],
            unicodes: {
                secondary: ['10f60b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f60b',
    },
    {
        name: 'location-smile',
        categories: ['maps'],
        terms: [],
        label: 'Location smile',
        aliases: {
            names: ['map-marker-smile'],
            unicodes: {
                secondary: ['10f60d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f60d',
    },
    {
        name: 'location-xmark',
        categories: ['maps'],
        terms: [],
        label: 'Location X Mark',
        aliases: {
            names: ['map-marker-times', 'map-marker-xmark'],
            unicodes: {
                secondary: ['10f60e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f60e',
    },
    {
        name: 'lock',
        categories: ['security'],
        terms: ['admin', 'lock', 'open', 'password', 'private', 'protect', 'security'],
        label: 'lock',
        aliases: {
            unicodes: {
                composite: ['1f512'],
                secondary: ['10f023'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f023',
    },
    {
        name: 'lock-a',
        categories: ['text-formatting'],
        terms: [],
        label: 'Lock A',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e422',
    },
    {
        name: 'lock-hashtag',
        categories: ['text-formatting'],
        terms: [],
        label: 'Lock Hashtag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e423',
    },
    {
        name: 'lock-keyhole',
        categories: ['security'],
        terms: [],
        label: 'Lock keyhole',
        aliases: {
            names: ['lock-alt'],
            unicodes: {
                secondary: ['10f30d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30d',
    },
    {
        name: 'lock-keyhole-open',
        categories: ['security'],
        terms: [],
        label: 'Lock keyhole open',
        aliases: {
            names: ['lock-open-alt'],
            unicodes: {
                secondary: ['10f3c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3c2',
    },
    {
        name: 'lock-open',
        categories: ['security'],
        terms: ['admin', 'lock', 'open', 'password', 'private', 'protect', 'security'],
        label: 'Lock Open',
        aliases: {
            unicodes: {
                secondary: ['10f3c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3c1',
    },
    {
        name: 'locust',
        categories: ['animals', 'disaster', 'humanitarian', 'nature'],
        terms: [],
        label: 'Locust',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e520',
    },
    {
        name: 'lollipop',
        categories: ['childhood', 'food-beverage', 'halloween'],
        terms: [],
        label: 'Lollipop',
        aliases: {
            names: ['lollypop'],
            unicodes: {
                composite: ['1f36d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e424',
    },
    {
        name: 'loveseat',
        categories: ['household', 'moving'],
        terms: ['chair', 'couch', 'cushion', 'furniture', 'relax', 'sofa'],
        label: 'Loveseat',
        aliases: {
            names: ['couch-small'],
            unicodes: {
                secondary: ['10f4cc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4cc',
    },
    {
        name: 'luchador-mask',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Luchador mask',
        aliases: {
            names: ['luchador', 'mask-luchador'],
            unicodes: {
                secondary: ['10f455'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f455',
    },
    {
        name: 'lungs',
        categories: ['humanitarian', 'medical-health'],
        terms: ['air', 'breath', 'covid-19', 'organ', 'respiratory'],
        label: 'Lungs',
        aliases: {
            unicodes: {
                composite: ['1fac1'],
                secondary: ['10f604'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f604',
    },
    {
        name: 'lungs-virus',
        categories: ['medical-health'],
        terms: ['breath', 'covid-19', 'respiratory', 'sick'],
        label: 'Lungs Virus',
        aliases: {
            unicodes: {
                secondary: ['10e067'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e067',
    },
    {
        name: 'm',
        categories: ['alphabet'],
        terms: [],
        label: 'M',
        aliases: {
            unicodes: {
                composite: ['6d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4d',
    },
    {
        name: 'mace',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'd&d', 'dnd', 'fantasy', 'melee attack', 'weapon', 'windu'],
        label: 'Mace',
        aliases: {
            unicodes: {
                secondary: ['10f6f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f8',
    },
    {
        name: 'magnet',
        categories: ['maps', 'science'],
        terms: ['Attract', 'lodestone', 'tool'],
        label: 'magnet',
        aliases: {
            unicodes: {
                composite: ['1f9f2'],
                secondary: ['10f076'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f076',
    },
    {
        name: 'magnifying-glass',
        categories: ['maps'],
        terms: [],
        label: 'Magnifying glass',
        aliases: {
            names: ['search'],
            unicodes: {
                composite: ['1f50d'],
                secondary: ['10f002'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f002',
    },
    {
        name: 'magnifying-glass-arrow-right',
        categories: ['business', 'humanitarian', 'marketing'],
        terms: [],
        label: 'Magnifying Glass-arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e521',
    },
    {
        name: 'magnifying-glass-chart',
        categories: ['business', 'humanitarian', 'marketing'],
        terms: [],
        label: 'Magnifying Glass-chart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e522',
    },
    {
        name: 'magnifying-glass-dollar',
        categories: ['marketing'],
        terms: [],
        label: 'Magnifying glass dollar',
        aliases: {
            names: ['search-dollar'],
            unicodes: {
                secondary: ['10f688'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f688',
    },
    {
        name: 'magnifying-glass-location',
        categories: ['marketing'],
        terms: [],
        label: 'Magnifying glass location',
        aliases: {
            names: ['search-location'],
            unicodes: {
                secondary: ['10f689'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f689',
    },
    {
        name: 'magnifying-glass-minus',
        categories: ['maps'],
        terms: [],
        label: 'Magnifying glass minus',
        aliases: {
            names: ['search-minus'],
            unicodes: {
                secondary: ['10f010'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f010',
    },
    {
        name: 'magnifying-glass-plus',
        categories: ['maps'],
        terms: [],
        label: 'Magnifying glass plus',
        aliases: {
            names: ['search-plus'],
            unicodes: {
                secondary: ['10f00e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f00e',
    },
    {
        name: 'mailbox',
        categories: ['household'],
        terms: ['archive', 'envelope', 'letter', 'post office', 'postal', 'postcard', 'send', 'stamp', 'usps'],
        label: 'Mailbox',
        aliases: {
            unicodes: {
                composite: ['1f4ea'],
                secondary: ['10f813'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f813',
    },
    {
        name: 'manat-sign',
        categories: ['money'],
        terms: [],
        label: 'Manat Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d5',
    },
    {
        name: 'mandolin',
        categories: ['gaming', 'music-audio'],
        terms: [
            'Dungeons & Dragons',
            'bard',
            'd&d',
            'dnd',
            'fantasy',
            'guitar',
            'instrument',
            'lute',
            'music',
            'song',
            'strings',
        ],
        label: 'Mandolin',
        aliases: {
            unicodes: {
                secondary: ['10f6f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6f9',
    },
    {
        name: 'mango',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Mango',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e30f',
    },
    {
        name: 'manhole',
        categories: ['energy'],
        terms: [],
        label: 'Manhole',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d6',
    },
    {
        name: 'map',
        categories: ['camping', 'maps', 'travel-hotel'],
        terms: [
            'address',
            'coordinates',
            'destination',
            'gps',
            'localize',
            'location',
            'map',
            'navigation',
            'paper',
            'pin',
            'place',
            'point of interest',
            'position',
            'route',
            'travel',
        ],
        label: 'Map',
        aliases: {
            unicodes: {
                composite: ['f278', '1f5fa'],
                secondary: ['10f279'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f279',
    },
    {
        name: 'map-location',
        categories: ['camping', 'travel-hotel'],
        terms: [],
        label: 'Map location',
        aliases: {
            names: ['map-marked'],
            unicodes: {
                secondary: ['10f59f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f59f',
    },
    {
        name: 'map-location-dot',
        categories: ['camping', 'travel-hotel'],
        terms: [],
        label: 'Map location dot',
        aliases: {
            names: ['map-marked-alt'],
            unicodes: {
                secondary: ['10f5a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a0',
    },
    {
        name: 'map-pin',
        categories: ['maps'],
        terms: [
            'address',
            'agree',
            'coordinates',
            'destination',
            'gps',
            'localize',
            'location',
            'map',
            'marker',
            'navigation',
            'pin',
            'place',
            'position',
            'travel',
        ],
        label: 'Map Pin',
        aliases: {
            unicodes: {
                composite: ['1f4cd'],
                secondary: ['10f276'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f276',
    },
    {
        name: 'marker',
        categories: ['business', 'design'],
        terms: ['design', 'edit', 'sharpie', 'update', 'write'],
        label: 'Marker',
        aliases: {
            unicodes: {
                secondary: ['10f5a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a1',
    },
    {
        name: 'mars',
        categories: ['gender'],
        terms: ['male'],
        label: 'Mars',
        aliases: {
            unicodes: {
                composite: ['2642'],
                secondary: ['10f222'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f222',
    },
    {
        name: 'mars-and-venus',
        categories: ['gender', 'humanitarian'],
        terms: [],
        label: 'Mars and Venus',
        aliases: {
            unicodes: {
                composite: ['26a5'],
                secondary: ['10f224'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f224',
    },
    {
        name: 'mars-and-venus-burst',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Mars and Venus Burst',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e523',
    },
    {
        name: 'mars-double',
        categories: ['gender'],
        terms: [],
        label: 'Mars Double',
        aliases: {
            unicodes: {
                composite: ['26a3'],
                secondary: ['10f227'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f227',
    },
    {
        name: 'mars-stroke',
        categories: ['gender'],
        terms: [],
        label: 'Mars Stroke',
        aliases: {
            unicodes: {
                composite: ['26a6'],
                secondary: ['10f229'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f229',
    },
    {
        name: 'mars-stroke-right',
        categories: ['gender'],
        terms: [],
        label: 'Mars stroke right',
        aliases: {
            names: ['mars-stroke-h'],
            unicodes: {
                composite: ['26a9'],
                secondary: ['10f22b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f22b',
    },
    {
        name: 'mars-stroke-up',
        categories: ['gender'],
        terms: [],
        label: 'Mars stroke up',
        aliases: {
            names: ['mars-stroke-v'],
            unicodes: {
                composite: ['26a8'],
                secondary: ['10f22a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f22a',
    },
    {
        name: 'martini-glass',
        categories: ['food-beverage', 'travel-hotel'],
        terms: [],
        label: 'Martini glass',
        aliases: {
            names: ['glass-martini-alt'],
            unicodes: {
                composite: ['1f378'],
                secondary: ['10f57b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f57b',
    },
    {
        name: 'martini-glass-citrus',
        categories: ['food-beverage', 'travel-hotel'],
        terms: [],
        label: 'Martini glass citrus',
        aliases: {
            names: ['cocktail'],
            unicodes: {
                secondary: ['10f561'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f561',
    },
    {
        name: 'martini-glass-empty',
        categories: ['food-beverage', 'maps', 'travel-hotel'],
        terms: [],
        label: 'Martini glass empty',
        aliases: {
            names: ['glass-martini'],
            unicodes: {
                secondary: ['10f000'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f000',
    },
    {
        name: 'mask',
        categories: ['halloween', 'security'],
        terms: ['carnivale', 'costume', 'disguise', 'halloween', 'secret', 'super hero'],
        label: 'Mask',
        aliases: {
            unicodes: {
                secondary: ['10f6fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6fa',
    },
    {
        name: 'mask-face',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Face Mask',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d7',
    },
    {
        name: 'mask-snorkel',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Mask Snorkel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b7',
    },
    {
        name: 'mask-ventilator',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Mask Ventilator',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e524',
    },
    {
        name: 'masks-theater',
        categories: ['education'],
        terms: [],
        label: 'Masks theater',
        aliases: {
            names: ['theater-masks'],
            unicodes: {
                composite: ['1f3ad'],
                secondary: ['10f630'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f630',
    },
    {
        name: 'mattress-pillow',
        categories: ['camping', 'household', 'humanitarian'],
        terms: [],
        label: 'Mattress Pillow',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e525',
    },
    {
        name: 'maximize',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Maximize',
        aliases: {
            names: ['expand-arrows-alt'],
            unicodes: {
                secondary: ['10f31e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f31e',
    },
    {
        name: 'meat',
        categories: ['food-beverage'],
        terms: ['beef', 'ham', 'mutton', 'pork', 'veal'],
        label: 'Meat',
        aliases: {
            unicodes: {
                composite: ['1f356'],
                secondary: ['10f814'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f814',
    },
    {
        name: 'medal',
        categories: ['sports-fitness'],
        terms: ['award', 'ribbon', 'star', 'trophy'],
        label: 'Medal',
        aliases: {
            unicodes: {
                composite: ['1f3c5'],
                secondary: ['10f5a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a2',
    },
    {
        name: 'megaphone',
        categories: ['marketing'],
        terms: ['announcement', 'broadcast', 'bullhorn', 'louder', 'share'],
        label: 'Megaphone',
        aliases: {
            unicodes: {
                composite: ['1f4e3'],
                secondary: ['10f675'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f675',
    },
    {
        name: 'melon',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Melon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e310',
    },
    {
        name: 'melon-slice',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Melon Slice',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e311',
    },
    {
        name: 'memo',
        categories: ['files', 'writing'],
        terms: [],
        label: 'Memo',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d8',
    },
    {
        name: 'memo-circle-check',
        categories: ['files', 'writing'],
        terms: [],
        label: 'Memo Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1d9',
    },
    {
        name: 'memo-circle-info',
        categories: ['files', 'writing'],
        terms: [],
        label: 'Memo Circle-info',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e49a',
    },
    {
        name: 'memo-pad',
        categories: ['files', 'writing'],
        terms: [],
        label: 'Memo Pad',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1da',
    },
    {
        name: 'memory',
        categories: ['devices-hardware'],
        terms: ['DIMM', 'RAM', 'hardware', 'storage', 'technology'],
        label: 'Memory',
        aliases: {
            unicodes: {
                secondary: ['10f538'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f538',
    },
    {
        name: 'menorah',
        categories: ['holidays', 'religion'],
        terms: ['candle', 'hanukkah', 'jewish', 'judaism', 'light'],
        label: 'Menorah',
        aliases: {
            unicodes: {
                secondary: ['10f676'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f676',
    },
    {
        name: 'mercury',
        categories: ['gender'],
        terms: ['transgender'],
        label: 'Mercury',
        aliases: {
            unicodes: {
                composite: ['263f'],
                secondary: ['10f223'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f223',
    },
    {
        name: 'merge',
        categories: ['arrows', 'coding'],
        terms: [],
        label: 'Merge',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e526',
    },
    {
        name: 'message',
        categories: ['communication', 'social'],
        terms: [],
        label: 'Message',
        aliases: {
            names: ['comment-alt'],
            unicodes: {
                secondary: ['10f27a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f27a',
    },
    {
        name: 'message-arrow-down',
        categories: ['communication'],
        terms: [],
        label: 'Message arrow down',
        aliases: {
            names: ['comment-alt-arrow-down'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1db',
    },
    {
        name: 'message-arrow-up',
        categories: ['communication'],
        terms: [],
        label: 'Message arrow up',
        aliases: {
            names: ['comment-alt-arrow-up'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1dc',
    },
    {
        name: 'message-arrow-up-right',
        categories: ['communication'],
        terms: [],
        label: 'Message Arrow-up-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1dd',
    },
    {
        name: 'message-bot',
        categories: ['communication'],
        terms: [],
        label: 'Message Bot',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b8',
    },
    {
        name: 'message-captions',
        categories: ['accessibility'],
        terms: [],
        label: 'Message captions',
        aliases: {
            names: ['comment-alt-captions'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1de',
    },
    {
        name: 'message-check',
        categories: ['communication'],
        terms: [],
        label: 'Message check',
        aliases: {
            names: ['comment-alt-check'],
            unicodes: {
                secondary: ['10f4a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a2',
    },
    {
        name: 'message-code',
        categories: ['communication'],
        terms: [],
        label: 'Message Code',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1df',
    },
    {
        name: 'message-dollar',
        categories: ['marketing', 'money'],
        terms: [],
        label: 'Message dollar',
        aliases: {
            names: ['comment-alt-dollar'],
            unicodes: {
                secondary: ['10f650'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f650',
    },
    {
        name: 'message-dots',
        categories: ['communication'],
        terms: [],
        label: 'Message dots',
        aliases: {
            names: ['comment-alt-dots', 'messaging'],
            unicodes: {
                secondary: ['10f4a3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a3',
    },
    {
        name: 'message-exclamation',
        categories: ['alert', 'communication'],
        terms: [],
        label: 'Message exclamation',
        aliases: {
            names: ['comment-alt-exclamation'],
            unicodes: {
                secondary: ['10f4a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a5',
    },
    {
        name: 'message-image',
        categories: ['communication', 'photos-images'],
        terms: [],
        label: 'Message image',
        aliases: {
            names: ['comment-alt-image'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e0',
    },
    {
        name: 'message-lines',
        categories: ['communication'],
        terms: [],
        label: 'Message lines',
        aliases: {
            names: ['comment-alt-lines'],
            unicodes: {
                secondary: ['10f4a6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a6',
    },
    {
        name: 'message-medical',
        categories: ['communication', 'medical-health'],
        terms: [],
        label: 'Message medical',
        aliases: {
            names: ['comment-alt-medical'],
            unicodes: {
                secondary: ['10f7f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7f4',
    },
    {
        name: 'message-middle',
        categories: ['communication'],
        terms: [],
        label: 'Message middle',
        aliases: {
            names: ['comment-middle-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e1',
    },
    {
        name: 'message-middle-top',
        categories: ['communication'],
        terms: [],
        label: 'Message middle top',
        aliases: {
            names: ['comment-middle-top-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e2',
    },
    {
        name: 'message-minus',
        categories: ['communication'],
        terms: [],
        label: 'Message minus',
        aliases: {
            names: ['comment-alt-minus'],
            unicodes: {
                secondary: ['10f4a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a7',
    },
    {
        name: 'message-music',
        categories: ['music-audio'],
        terms: [],
        label: 'Message music',
        aliases: {
            names: ['comment-alt-music'],
            unicodes: {
                secondary: ['10f8af'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8af',
    },
    {
        name: 'message-pen',
        categories: ['communication'],
        terms: [],
        label: 'Message pen',
        aliases: {
            names: ['comment-alt-edit', 'message-edit'],
            unicodes: {
                secondary: ['10f4a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a4',
    },
    {
        name: 'message-plus',
        categories: ['communication'],
        terms: [],
        label: 'Message plus',
        aliases: {
            names: ['comment-alt-plus'],
            unicodes: {
                secondary: ['10f4a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a8',
    },
    {
        name: 'message-question',
        categories: ['communication'],
        terms: [],
        label: 'Message Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e3',
    },
    {
        name: 'message-quote',
        categories: ['communication'],
        terms: [],
        label: 'Message Quote',
        aliases: {
            names: ['comment-alt-quote'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e4',
    },
    {
        name: 'message-slash',
        categories: ['communication'],
        terms: [],
        label: 'Message slash',
        aliases: {
            names: ['comment-alt-slash'],
            unicodes: {
                secondary: ['10f4a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4a9',
    },
    {
        name: 'message-smile',
        categories: ['communication'],
        terms: [],
        label: 'Message smile',
        aliases: {
            names: ['comment-alt-smile'],
            unicodes: {
                secondary: ['10f4aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4aa',
    },
    {
        name: 'message-sms',
        categories: ['communication'],
        terms: [],
        label: 'Message Sms',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e5',
    },
    {
        name: 'message-text',
        categories: ['communication'],
        terms: [],
        label: 'Message text',
        aliases: {
            names: ['comment-alt-text'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e6',
    },
    {
        name: 'message-xmark',
        categories: ['communication'],
        terms: [],
        label: 'Message X Mark',
        aliases: {
            names: ['comment-alt-times', 'message-times'],
            unicodes: {
                secondary: ['10f4ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ab',
    },
    {
        name: 'messages',
        categories: ['communication'],
        terms: [],
        label: 'Messages',
        aliases: {
            names: ['comments-alt'],
            unicodes: {
                secondary: ['10f4b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4b6',
    },
    {
        name: 'messages-dollar',
        categories: ['marketing', 'money'],
        terms: [],
        label: 'Messages dollar',
        aliases: {
            names: ['comments-alt-dollar'],
            unicodes: {
                secondary: ['10f652'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f652',
    },
    {
        name: 'messages-question',
        categories: ['communication'],
        terms: [],
        label: 'Messages Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e7',
    },
    {
        name: 'meteor',
        categories: ['astronomy', 'weather'],
        terms: ['armageddon', 'asteroid', 'comet', 'shooting star', 'space'],
        label: 'Meteor',
        aliases: {
            unicodes: {
                composite: ['2604'],
                secondary: ['10f753'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f753',
    },
    {
        name: 'meter',
        categories: ['devices-hardware', 'energy'],
        terms: [],
        label: 'Meter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e8',
    },
    {
        name: 'meter-bolt',
        categories: ['energy'],
        terms: [],
        label: 'Meter Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1e9',
    },
    {
        name: 'meter-droplet',
        categories: ['energy'],
        terms: [],
        label: 'Meter Droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ea',
    },
    {
        name: 'meter-fire',
        categories: ['energy'],
        terms: [],
        label: 'Meter Fire',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1eb',
    },
    {
        name: 'microchip',
        categories: ['coding', 'devices-hardware'],
        terms: ['cpu', 'hardware', 'processor', 'technology'],
        label: 'Microchip',
        aliases: {
            unicodes: {
                secondary: ['10f2db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2db',
    },
    {
        name: 'microchip-ai',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Microchip Ai',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ec',
    },
    {
        name: 'microphone',
        categories: ['communication', 'film-video', 'music-audio', 'toggle'],
        terms: ['audio', 'podcast', 'record', 'sing', 'sound', 'voice'],
        label: 'microphone',
        aliases: {
            unicodes: {
                secondary: ['10f130'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f130',
    },
    {
        name: 'microphone-lines',
        categories: ['communication', 'film-video', 'music-audio'],
        terms: [],
        label: 'Microphone lines',
        aliases: {
            names: ['microphone-alt'],
            unicodes: {
                composite: ['1f399'],
                secondary: ['10f3c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3c9',
    },
    {
        name: 'microphone-lines-slash',
        categories: ['communication', 'film-video', 'music-audio'],
        terms: [],
        label: 'Microphone lines slash',
        aliases: {
            names: ['microphone-alt-slash'],
            unicodes: {
                secondary: ['10f539'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f539',
    },
    {
        name: 'microphone-slash',
        categories: ['communication', 'film-video', 'music-audio', 'toggle'],
        terms: ['audio', 'disable', 'mute', 'podcast', 'record', 'sing', 'sound', 'voice'],
        label: 'Microphone Slash',
        aliases: {
            unicodes: {
                secondary: ['10f131'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f131',
    },
    {
        name: 'microphone-stand',
        categories: ['film-video', 'music-audio'],
        terms: ['audio', 'podcast', 'record', 'sing', 'sound', 'voice'],
        label: 'Microphone Stand',
        aliases: {
            unicodes: {
                composite: ['1f3a4'],
                secondary: ['10f8cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8cb',
    },
    {
        name: 'microscope',
        categories: ['education', 'humanitarian', 'medical-health', 'science'],
        terms: ['covid-19', 'electron', 'lens', 'optics', 'science', 'shrink'],
        label: 'Microscope',
        aliases: {
            unicodes: {
                composite: ['1f52c'],
                secondary: ['10f610'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f610',
    },
    {
        name: 'microwave',
        categories: ['household', 'travel-hotel'],
        terms: ['chef', 'cook', 'kitchen', 'nuke'],
        label: 'Microwave',
        aliases: {
            unicodes: {
                secondary: ['10e01b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e01b',
    },
    {
        name: 'mill-sign',
        categories: ['money'],
        terms: [],
        label: 'Mill Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ed',
    },
    {
        name: 'minimize',
        categories: ['media-playback', 'photos-images'],
        terms: [],
        label: 'Minimize',
        aliases: {
            names: ['compress-arrows-alt'],
            unicodes: {
                secondary: ['10f78c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f78c',
    },
    {
        name: 'minus',
        categories: ['editing', 'mathematics', 'punctuation-symbols'],
        terms: ['collapse', 'delete', 'hide', 'minify', 'negative', 'remove', 'trash'],
        label: 'minus',
        aliases: {
            names: ['subtract'],
            unicodes: {
                composite: ['2212', '2796', '2013'],
                secondary: ['10f068'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f068',
    },
    {
        name: 'mistletoe',
        categories: ['holidays'],
        terms: ['awkward', 'christmas', 'decoration', 'flora', 'holiday', 'kiss', 'plant', 'tradition', 'trap', 'xmas'],
        label: 'Mistletoe',
        aliases: {
            unicodes: {
                secondary: ['10f7b4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b4',
    },
    {
        name: 'mitten',
        categories: ['childhood', 'clothing-fashion'],
        terms: ['clothing', 'cold', 'glove', 'hands', 'knitted', 'seasonal', 'warmth'],
        label: 'Mitten',
        aliases: {
            unicodes: {
                secondary: ['10f7b5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b5',
    },
    {
        name: 'mobile',
        categories: ['communication', 'devices-hardware'],
        terms: ['apple', 'call', 'cell phone', 'cellphone', 'device', 'iphone', 'number', 'screen', 'telephone'],
        label: 'Mobile',
        aliases: {
            names: ['mobile-android', 'mobile-phone'],
            unicodes: {
                composite: ['1f4f1'],
                secondary: ['10f3ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3ce',
    },
    {
        name: 'mobile-button',
        categories: ['communication', 'devices-hardware'],
        terms: [],
        label: 'Mobile button',
        aliases: {
            unicodes: {
                secondary: ['10f10b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f10b',
    },
    {
        name: 'mobile-notch',
        categories: ['communication', 'devices-hardware'],
        terms: [],
        label: 'Mobile notch',
        aliases: {
            names: ['mobile-iphone'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ee',
    },
    {
        name: 'mobile-retro',
        categories: ['communication', 'devices-hardware', 'humanitarian'],
        terms: [],
        label: 'Mobile Retro',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e527',
    },
    {
        name: 'mobile-screen',
        categories: ['communication', 'devices-hardware', 'humanitarian'],
        terms: [],
        label: 'Mobile screen',
        aliases: {
            names: ['mobile-android-alt'],
            unicodes: {
                secondary: ['10f3cf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3cf',
    },
    {
        name: 'mobile-screen-button',
        categories: ['communication', 'devices-hardware'],
        terms: [],
        label: 'Mobile screen button',
        aliases: {
            names: ['mobile-alt'],
            unicodes: {
                secondary: ['10f3cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3cd',
    },
    {
        name: 'mobile-signal',
        categories: ['connectivity', 'devices-hardware', 'shopping'],
        terms: [],
        label: 'Mobile Signal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ef',
    },
    {
        name: 'mobile-signal-out',
        categories: ['connectivity', 'devices-hardware', 'shopping'],
        terms: [],
        label: 'Mobile Signal Out',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f0',
    },
    {
        name: 'money-bill',
        categories: ['maps', 'money'],
        terms: ['buy', 'cash', 'checkout', 'money', 'payment', 'price', 'purchase'],
        label: 'Money Bill',
        aliases: {
            unicodes: {
                secondary: ['10f0d6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d6',
    },
    {
        name: 'money-bill-1',
        categories: ['maps', 'money'],
        terms: [],
        label: 'Money bill 1',
        aliases: {
            names: ['money-bill-alt'],
            unicodes: {
                secondary: ['10f3d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3d1',
    },
    {
        name: 'money-bill-1-wave',
        categories: ['money'],
        terms: [],
        label: 'Money bill 1 wave',
        aliases: {
            names: ['money-bill-wave-alt'],
            unicodes: {
                secondary: ['10f53b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53b',
    },
    {
        name: 'money-bill-simple',
        categories: ['money'],
        terms: [],
        label: 'Money Bill-simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f1',
    },
    {
        name: 'money-bill-simple-wave',
        categories: ['money'],
        terms: [],
        label: 'Money Bill-simple-wave',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f2',
    },
    {
        name: 'money-bill-transfer',
        categories: ['humanitarian', 'money'],
        terms: [],
        label: 'Money Bill-transfer',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e528',
    },
    {
        name: 'money-bill-trend-up',
        categories: ['humanitarian', 'money'],
        terms: [],
        label: 'Money Bill-trend-up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e529',
    },
    {
        name: 'money-bill-wave',
        categories: ['money'],
        terms: ['buy', 'cash', 'checkout', 'money', 'payment', 'price', 'purchase'],
        label: 'Wavy Money Bill',
        aliases: {
            unicodes: {
                secondary: ['10f53a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53a',
    },
    {
        name: 'money-bill-wheat',
        categories: ['humanitarian', 'money'],
        terms: [],
        label: 'Money Bill-wheat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52a',
    },
    {
        name: 'money-bills',
        categories: ['humanitarian', 'money'],
        terms: [],
        label: 'Money Bills',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f3',
    },
    {
        name: 'money-bills-simple',
        categories: ['money'],
        terms: [],
        label: 'Money bills simple',
        aliases: {
            names: ['money-bills-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f4',
    },
    {
        name: 'money-check',
        categories: ['money', 'shopping'],
        terms: ['bank check', 'buy', 'checkout', 'cheque', 'money', 'payment', 'price', 'purchase'],
        label: 'Money Check',
        aliases: {
            unicodes: {
                secondary: ['10f53c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53c',
    },
    {
        name: 'money-check-dollar',
        categories: ['money', 'shopping'],
        terms: [],
        label: 'Money check dollar',
        aliases: {
            names: ['money-check-alt'],
            unicodes: {
                secondary: ['10f53d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53d',
    },
    {
        name: 'money-check-dollar-pen',
        categories: ['business', 'charity', 'shopping'],
        terms: [],
        label: 'Money check dollar pen',
        aliases: {
            names: ['money-check-edit-alt'],
            unicodes: {
                secondary: ['10f873'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f873',
    },
    {
        name: 'money-check-pen',
        categories: ['business', 'charity', 'shopping'],
        terms: [],
        label: 'Money check pen',
        aliases: {
            names: ['money-check-edit'],
            unicodes: {
                secondary: ['10f872'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f872',
    },
    {
        name: 'money-from-bracket',
        categories: ['money'],
        terms: [],
        label: 'Money From Bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e312',
    },
    {
        name: 'money-simple-from-bracket',
        categories: ['money'],
        terms: [],
        label: 'Money Simple From Bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e313',
    },
    {
        name: 'monitor-waveform',
        categories: ['medical-health', 'sports-fitness'],
        terms: [],
        label: 'Monitor waveform',
        aliases: {
            names: ['monitor-heart-rate'],
            unicodes: {
                secondary: ['10f611'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f611',
    },
    {
        name: 'monkey',
        categories: ['animals'],
        terms: ['animal', 'banana', 'fauna', 'mammal', 'tail'],
        label: 'Monkey',
        aliases: {
            unicodes: {
                composite: ['1f412'],
                secondary: ['10f6fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6fb',
    },
    {
        name: 'monument',
        categories: ['buildings', 'travel-hotel'],
        terms: ['building', 'historic', 'landmark', 'memorable'],
        label: 'Monument',
        aliases: {
            unicodes: {
                secondary: ['10f5a6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a6',
    },
    {
        name: 'moon',
        categories: ['astronomy', 'weather'],
        terms: ['contrast', 'crescent', 'dark', 'lunar', 'night'],
        label: 'Moon',
        aliases: {
            unicodes: {
                composite: ['1f319', '23fe'],
                secondary: ['10f186'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f186',
    },
    {
        name: 'moon-cloud',
        categories: ['weather'],
        terms: ['crescent', 'dark', 'lunar', 'night', 'overcast'],
        label: 'Moon with Cloud',
        aliases: {
            unicodes: {
                secondary: ['10f754'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f754',
    },
    {
        name: 'moon-over-sun',
        categories: ['astronomy', 'spinners', 'weather'],
        terms: [],
        label: 'Moon over sun',
        aliases: {
            names: ['eclipse-alt'],
            unicodes: {
                secondary: ['10f74a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f74a',
    },
    {
        name: 'moon-stars',
        categories: ['astronomy', 'weather'],
        terms: ['clear', 'crescent', 'lunar', 'space', 'star'],
        label: 'Moon with Stars',
        aliases: {
            unicodes: {
                secondary: ['10f755'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f755',
    },
    {
        name: 'moped',
        categories: ['automotive', 'shopping', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Moped',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3b9',
    },
    {
        name: 'mortar-pestle',
        categories: ['medical-health', 'science'],
        terms: ['crush', 'culinary', 'grind', 'medical', 'mix', 'pharmacy', 'prescription', 'spices'],
        label: 'Mortar Pestle',
        aliases: {
            unicodes: {
                secondary: ['10f5a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a7',
    },
    {
        name: 'mosque',
        categories: ['buildings', 'humanitarian', 'religion'],
        terms: ['building', 'islam', 'landmark', 'muslim'],
        label: 'Mosque',
        aliases: {
            unicodes: {
                composite: ['1f54c'],
                secondary: ['10f678'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f678',
    },
    {
        name: 'mosquito',
        categories: ['animals', 'camping', 'disaster', 'humanitarian', 'nature'],
        terms: [],
        label: 'Mosquito',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52b',
    },
    {
        name: 'mosquito-net',
        categories: ['camping', 'humanitarian'],
        terms: [],
        label: 'Mosquito Net',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52c',
    },
    {
        name: 'motorcycle',
        categories: ['automotive', 'maps', 'transportation'],
        terms: ['bike', 'machine', 'transportation', 'vehicle'],
        label: 'Motorcycle',
        aliases: {
            unicodes: {
                composite: ['1f3cd'],
                secondary: ['10f21c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f21c',
    },
    {
        name: 'mound',
        categories: ['construction', 'humanitarian', 'nature', 'sports-fitness', 'transportation'],
        terms: [],
        label: 'Mound',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52d',
    },
    {
        name: 'mountain',
        categories: ['camping', 'nature'],
        terms: ['glacier', 'hiking', 'hill', 'landscape', 'travel', 'view'],
        label: 'Mountain',
        aliases: {
            unicodes: {
                composite: ['1f3d4'],
                secondary: ['10f6fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6fc',
    },
    {
        name: 'mountain-city',
        categories: ['buildings', 'humanitarian', 'nature', 'travel-hotel'],
        terms: [],
        label: 'Mountain City',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52e',
    },
    {
        name: 'mountain-sun',
        categories: ['camping', 'humanitarian', 'maps', 'nature'],
        terms: [],
        label: 'Mountain Sun',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e52f',
    },
    {
        name: 'mountains',
        categories: ['camping', 'nature'],
        terms: ['glacier', 'hiking', 'hill', 'landscape', 'travel', 'view'],
        label: 'Mountains',
        aliases: {
            unicodes: {
                composite: ['26f0'],
                secondary: ['10f6fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6fd',
    },
    {
        name: 'mp3-player',
        categories: ['devices-hardware', 'music-audio'],
        terms: ['audio', 'handheld', 'ipod', 'itunes', 'music', 'zune'],
        label: 'MP3 Player',
        aliases: {
            unicodes: {
                secondary: ['10f8ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ce',
    },
    {
        name: 'mug',
        categories: ['food-beverage'],
        terms: ['coffee', 'cup', 'drink', 'hot chocolate', 'tea'],
        label: 'Mug',
        aliases: {
            unicodes: {
                secondary: ['10f874'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f874',
    },
    {
        name: 'mug-hot',
        categories: ['food-beverage', 'holidays'],
        terms: ['caliente', 'cocoa', 'coffee', 'cup', 'drink', 'holiday', 'hot chocolate', 'steam', 'tea', 'warmth'],
        label: 'Mug Hot',
        aliases: {
            unicodes: {
                composite: ['2615'],
                secondary: ['10f7b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b6',
    },
    {
        name: 'mug-marshmallows',
        categories: ['food-beverage'],
        terms: ['cocoa', 'coffee', 'cup', 'drink', 'hot chocolate', 'seasonal', 'sweet', 'warmth'],
        label: 'Mug with Marshmallows',
        aliases: {
            unicodes: {
                secondary: ['10f7b7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b7',
    },
    {
        name: 'mug-saucer',
        categories: ['business', 'coding', 'food-beverage', 'household', 'maps', 'travel-hotel'],
        terms: [],
        label: 'Mug saucer',
        aliases: {
            names: ['coffee'],
            unicodes: {
                secondary: ['10f0f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f4',
    },
    {
        name: 'mug-tea',
        categories: ['food-beverage'],
        terms: ['brew', 'drink', 'herbal', 'oolong'],
        label: 'Mug Tea',
        aliases: {
            unicodes: {
                secondary: ['10f875'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f875',
    },
    {
        name: 'mug-tea-saucer',
        categories: ['food-beverage'],
        terms: [],
        label: 'Mug Tea-saucer',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f5',
    },
    {
        name: 'mushroom',
        categories: ['fruits-vegetables', 'gaming', 'nature'],
        terms: [],
        label: 'Mushroom',
        aliases: {
            unicodes: {
                composite: ['1f344'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e425',
    },
    {
        name: 'music',
        categories: ['education', 'maps', 'media-playback', 'music-audio'],
        terms: ['lyrics', 'melody', 'note', 'sing', 'sound'],
        label: 'Music',
        aliases: {
            unicodes: {
                composite: ['1f3b5'],
                secondary: ['10f001'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f001',
    },
    {
        name: 'music-note',
        categories: ['music-audio'],
        terms: [],
        label: 'Music note',
        aliases: {
            names: ['music-alt'],
            unicodes: {
                secondary: ['10f8cf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8cf',
    },
    {
        name: 'music-note-slash',
        categories: ['music-audio'],
        terms: [],
        label: 'Music note slash',
        aliases: {
            names: ['music-alt-slash'],
            unicodes: {
                secondary: ['10f8d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d0',
    },
    {
        name: 'music-slash',
        categories: ['music-audio'],
        terms: ['cancel', 'disabled', 'lyrics', 'melody', 'mute', 'note', 'off', 'sing', 'sound'],
        label: 'Music Slash',
        aliases: {
            unicodes: {
                secondary: ['10f8d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d1',
    },
    {
        name: 'n',
        categories: ['alphabet'],
        terms: [],
        label: 'N',
        aliases: {
            unicodes: {
                composite: ['6e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4e',
    },
    {
        name: 'naira-sign',
        categories: ['money'],
        terms: [],
        label: 'Naira Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f6',
    },
    {
        name: 'napster',
        categories: ['music-audio'],
        terms: [],
        label: 'Napster',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f3d2',
    },
    {
        name: 'narwhal',
        categories: ['animals', 'holidays', 'maritime'],
        terms: ['animal', 'fauna', 'holiday', 'ivory', 'mammal', 'tusk', 'unicorn', 'whale'],
        label: 'Narwhal',
        aliases: {
            unicodes: {
                secondary: ['10f6fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6fe',
    },
    {
        name: 'nesting-dolls',
        categories: ['gaming'],
        terms: [],
        label: 'Nesting Dolls',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ba',
    },
    {
        name: 'network-wired',
        categories: ['business', 'coding'],
        terms: ['computer', 'connect', 'ethernet', 'internet', 'intranet'],
        label: 'Wired Network',
        aliases: {
            unicodes: {
                secondary: ['10f6ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ff',
    },
    {
        name: 'neuter',
        categories: ['gender'],
        terms: [],
        label: 'Neuter',
        aliases: {
            unicodes: {
                composite: ['26b2'],
                secondary: ['10f22c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f22c',
    },
    {
        name: 'newspaper',
        categories: ['maps', 'writing'],
        terms: ['article', 'editorial', 'headline', 'journal', 'journalism', 'news', 'press'],
        label: 'Newspaper',
        aliases: {
            unicodes: {
                composite: ['1f4f0'],
                secondary: ['10f1ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ea',
    },
    {
        name: 'nfc',
        categories: ['shopping'],
        terms: [],
        label: 'NFC',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f7',
    },
    {
        name: 'nfc-directional',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Directional',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'e530',
    },
    {
        name: 'nfc-lock',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f8',
    },
    {
        name: 'nfc-magnifying-glass',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Magnifying Glass',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1f9',
    },
    {
        name: 'nfc-pen',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Pen',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1fa',
    },
    {
        name: 'nfc-signal',
        categories: ['connectivity', 'shopping'],
        terms: [],
        label: 'NFC Signal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1fb',
    },
    {
        name: 'nfc-slash',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1fc',
    },
    {
        name: 'nfc-symbol',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Simplified',
        aliases: {},
        styles: [
            IconStyle.SOLID,
            IconStyle.REGULAR,
            IconStyle.LIGHT,
            IconStyle.THIN,
            IconStyle.DUOTONE,
            IconStyle.BRANDS,
        ],
        unicode: 'e531',
    },
    {
        name: 'nfc-trash',
        categories: ['shopping'],
        terms: [],
        label: 'NFC Trash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1fd',
    },
    {
        name: 'not-equal',
        categories: ['mathematics'],
        terms: ['arithmetic', 'compare', 'math'],
        label: 'Not Equal',
        aliases: {
            unicodes: {
                secondary: ['10f53e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53e',
    },
    {
        name: 'notdef',
        categories: ['coding', 'writing'],
        terms: [],
        label: 'Notdef',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1fe',
    },
    {
        name: 'note',
        categories: ['writing'],
        terms: [],
        label: 'Note',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e1ff',
    },
    {
        name: 'note-medical',
        categories: ['medical-health'],
        terms: [],
        label: 'Note Medical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e200',
    },
    {
        name: 'note-sticky',
        categories: ['business', 'files', 'writing'],
        terms: [],
        label: 'Note sticky',
        aliases: {
            names: ['sticky-note'],
            unicodes: {
                composite: ['f24a'],
                secondary: ['10f249'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f249',
    },
    {
        name: 'notebook',
        categories: ['business', 'design', 'writing'],
        terms: [],
        label: 'Notebook',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e201',
    },
    {
        name: 'notes',
        categories: ['writing'],
        terms: [],
        label: 'Notes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e202',
    },
    {
        name: 'notes-medical',
        categories: ['medical-health'],
        terms: ['clipboard', 'doctor', 'ehr', 'health', 'history', 'records'],
        label: 'Medical Notes',
        aliases: {
            unicodes: {
                secondary: ['10f481'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f481',
    },
    {
        name: 'o',
        categories: ['alphabet'],
        terms: [],
        label: 'O',
        aliases: {
            unicodes: {
                composite: ['6f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '4f',
    },
    {
        name: 'object-exclude',
        categories: ['design'],
        terms: [],
        label: 'Object Exclude',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e49c',
    },
    {
        name: 'object-group',
        categories: ['design'],
        terms: ['combine', 'copy', 'design', 'merge', 'select'],
        label: 'Object Group',
        aliases: {
            unicodes: {
                secondary: ['10f247'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f247',
    },
    {
        name: 'object-intersect',
        categories: ['design'],
        terms: [],
        label: 'Object Intersect',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e49d',
    },
    {
        name: 'object-subtract',
        categories: ['design'],
        terms: [],
        label: 'Object Subtract',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e49e',
    },
    {
        name: 'object-ungroup',
        categories: ['design'],
        terms: ['copy', 'design', 'merge', 'select', 'separate'],
        label: 'Object Ungroup',
        aliases: {
            unicodes: {
                secondary: ['10f248'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f248',
    },
    {
        name: 'object-union',
        categories: ['design'],
        terms: [],
        label: 'Object Union',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e49f',
    },
    {
        name: 'objects-align-bottom',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Bottom',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3bb',
    },
    {
        name: 'objects-align-center-horizontal',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Center Horizontal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3bc',
    },
    {
        name: 'objects-align-center-vertical',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Center Vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3bd',
    },
    {
        name: 'objects-align-left',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3be',
    },
    {
        name: 'objects-align-right',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3bf',
    },
    {
        name: 'objects-align-top',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Align Top',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c0',
    },
    {
        name: 'objects-column',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Objects Column',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c1',
    },
    {
        name: 'octagon',
        categories: ['shapes'],
        terms: ['shape', 'stop'],
        label: 'Octagon',
        aliases: {
            unicodes: {
                composite: ['1f6d1'],
                secondary: ['10f306'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f306',
    },
    {
        name: 'octagon-check',
        categories: ['editing'],
        terms: [],
        label: 'Octagon Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e426',
    },
    {
        name: 'octagon-divide',
        categories: ['mathematics'],
        terms: [],
        label: 'Octagon Divide',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e203',
    },
    {
        name: 'octagon-exclamation',
        categories: ['alert', 'coding', 'security'],
        terms: [],
        label: 'Octagon Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e204',
    },
    {
        name: 'octagon-minus',
        categories: ['mathematics'],
        terms: [],
        label: 'Octagon minus',
        aliases: {
            names: ['minus-octagon'],
            unicodes: {
                secondary: ['10f308'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f308',
    },
    {
        name: 'octagon-plus',
        categories: ['mathematics'],
        terms: [],
        label: 'Octagon plus',
        aliases: {
            names: ['plus-octagon'],
            unicodes: {
                secondary: ['10f301'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f301',
    },
    {
        name: 'octagon-xmark',
        categories: ['mathematics'],
        terms: [],
        label: 'Octagon X Mark',
        aliases: {
            names: ['times-octagon', 'xmark-octagon'],
            unicodes: {
                secondary: ['10f2f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f0',
    },
    {
        name: 'oil-can',
        categories: ['automotive'],
        terms: ['auto', 'crude', 'gasoline', 'grease', 'lubricate', 'petroleum'],
        label: 'Oil Can',
        aliases: {
            unicodes: {
                secondary: ['10f613'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f613',
    },
    {
        name: 'oil-can-drip',
        categories: ['automotive'],
        terms: [],
        label: 'Oil Can-drip',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e205',
    },
    {
        name: 'oil-temperature',
        categories: ['automotive'],
        terms: [],
        label: 'Oil temperature',
        aliases: {
            names: ['oil-temp'],
            unicodes: {
                secondary: ['10f614'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f614',
    },
    {
        name: 'oil-well',
        categories: ['buildings', 'energy', 'humanitarian'],
        terms: [],
        label: 'Oil Well',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e532',
    },
    {
        name: 'old-republic',
        categories: ['science-fiction'],
        terms: [],
        label: 'Old Republic',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f510',
    },
    {
        name: 'olive',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Olive',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e316',
    },
    {
        name: 'olive-branch',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Olive Branch',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e317',
    },
    {
        name: 'om',
        categories: ['religion'],
        terms: ['buddhism', 'hinduism', 'jainism', 'mantra'],
        label: 'Om',
        aliases: {
            unicodes: {
                composite: ['1f549'],
                secondary: ['10f679'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f679',
    },
    {
        name: 'omega',
        categories: ['mathematics'],
        terms: ['alphabet', 'greek', 'math'],
        label: 'Omega',
        aliases: {
            unicodes: {
                secondary: ['10f67a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67a',
    },
    {
        name: 'onion',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Onion',
        aliases: {
            unicodes: {
                composite: ['1f9c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e427',
    },
    {
        name: 'option',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Option',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e318',
    },
    {
        name: 'ornament',
        categories: ['holidays'],
        terms: ['christmas', 'decoration', 'holiday', 'xmas'],
        label: 'Ornament',
        aliases: {
            unicodes: {
                secondary: ['10f7b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b8',
    },
    {
        name: 'otter',
        categories: ['animals', 'maritime'],
        terms: ['animal', 'badger', 'fauna', 'fur', 'mammal', 'marten'],
        label: 'Otter',
        aliases: {
            unicodes: {
                composite: ['1f9a6'],
                secondary: ['10f700'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f700',
    },
    {
        name: 'outdent',
        categories: ['text-formatting'],
        terms: ['align', 'justify', 'paragraph', 'tab'],
        label: 'Outdent',
        aliases: {
            names: ['dedent'],
            unicodes: {
                secondary: ['10f03b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f03b',
    },
    {
        name: 'outlet',
        categories: ['energy', 'household', 'travel-hotel'],
        terms: ['electricity', 'energy', 'plug'],
        label: 'Outlet',
        aliases: {
            unicodes: {
                secondary: ['10e01c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e01c',
    },
    {
        name: 'oven',
        categories: ['household', 'travel-hotel'],
        terms: ['bake', 'broiler', 'convection', 'cook', 'kitchen', 'stove'],
        label: 'Oven',
        aliases: {
            unicodes: {
                secondary: ['10e01d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e01d',
    },
    {
        name: 'overline',
        categories: ['text-formatting'],
        terms: ['horizontal', 'line', 'overbar', 'overscore', 'vinculum'],
        label: 'Overline',
        aliases: {
            unicodes: {
                secondary: ['10f876'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f876',
    },
    {
        name: 'p',
        categories: ['alphabet'],
        terms: [],
        label: 'P',
        aliases: {
            unicodes: {
                composite: ['70'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '50',
    },
    {
        name: 'page',
        categories: ['files'],
        terms: [],
        label: 'Page',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e428',
    },
    {
        name: 'page-caret-down',
        categories: ['files'],
        terms: [],
        label: 'Page Caret Down',
        aliases: {
            names: ['file-caret-down'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e429',
    },
    {
        name: 'page-caret-up',
        categories: ['files'],
        terms: [],
        label: 'Page Caret Up',
        aliases: {
            names: ['file-caret-up'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42a',
    },
    {
        name: 'pager',
        categories: ['medical-health'],
        terms: ['beeper', 'cellphone', 'communication'],
        label: 'Pager',
        aliases: {
            unicodes: {
                composite: ['1f4df'],
                secondary: ['10f815'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f815',
    },
    {
        name: 'paint-roller',
        categories: ['construction', 'design'],
        terms: ['acrylic', 'art', 'brush', 'color', 'fill', 'paint', 'pigment', 'watercolor'],
        label: 'Paint Roller',
        aliases: {
            unicodes: {
                secondary: ['10f5aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5aa',
    },
    {
        name: 'paintbrush',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Paint Brush',
        aliases: {
            names: ['paint-brush'],
            unicodes: {
                composite: ['1f58c'],
                secondary: ['10f1fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1fc',
    },
    {
        name: 'paintbrush-fine',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Paint brush fine',
        aliases: {
            names: ['paint-brush-alt', 'paint-brush-fine', 'paintbrush-alt'],
            unicodes: {
                secondary: ['10f5a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5a9',
    },
    {
        name: 'paintbrush-pencil',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Paintbrush Pencil',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e206',
    },
    {
        name: 'palette',
        categories: ['design', 'spinners'],
        terms: ['acrylic', 'art', 'brush', 'color', 'fill', 'paint', 'pigment', 'watercolor'],
        label: 'Palette',
        aliases: {
            unicodes: {
                composite: ['1f3a8'],
                secondary: ['10f53f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f53f',
    },
    {
        name: 'pallet',
        categories: ['logistics'],
        terms: ['archive', 'box', 'inventory', 'shipping', 'warehouse'],
        label: 'Pallet',
        aliases: {
            unicodes: {
                secondary: ['10f482'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f482',
    },
    {
        name: 'pallet-box',
        categories: ['logistics'],
        terms: [],
        label: 'Pallet Box',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e208',
    },
    {
        name: 'pallet-boxes',
        categories: ['logistics'],
        terms: [],
        label: 'Pallet with Boxes',
        aliases: {
            names: ['palette-boxes', 'pallet-alt'],
            unicodes: {
                composite: ['e207'],
                primary: ['e207'],
                secondary: ['10e207', '10f483'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f483',
    },
    {
        name: 'pan-food',
        categories: ['food-beverage'],
        terms: [],
        label: 'Pan Food',
        aliases: {
            unicodes: {
                composite: ['1f958'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42b',
    },
    {
        name: 'pan-frying',
        categories: ['food-beverage', 'household'],
        terms: [],
        label: 'Pan Frying',
        aliases: {
            unicodes: {
                composite: ['1f373'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42c',
    },
    {
        name: 'pancakes',
        categories: ['food-beverage'],
        terms: [],
        label: 'Pancakes',
        aliases: {
            unicodes: {
                composite: ['1f95e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42d',
    },
    {
        name: 'panel-ews',
        categories: ['security'],
        terms: [],
        label: 'EWS Panel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42e',
    },
    {
        name: 'panel-fire',
        categories: ['security'],
        terms: [],
        label: 'Panel Fire',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e42f',
    },
    {
        name: 'panorama',
        categories: ['photos-images'],
        terms: [],
        label: 'Panorama',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e209',
    },
    {
        name: 'paper-plane',
        categories: ['communication', 'transportation', 'writing'],
        terms: ['air', 'float', 'fold', 'mail', 'paper', 'send'],
        label: 'Paper Plane',
        aliases: {
            unicodes: {
                composite: ['f1d9'],
                secondary: ['10f1d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1d8',
    },
    {
        name: 'paper-plane-top',
        categories: ['communication', 'social'],
        terms: [],
        label: 'Paper plane top',
        aliases: {
            names: ['paper-plane-alt', 'send'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20a',
    },
    {
        name: 'paperclip',
        categories: ['business', 'writing'],
        terms: ['attach', 'attachment', 'connect', 'link'],
        label: 'Paperclip',
        aliases: {
            unicodes: {
                composite: ['1f4ce'],
                secondary: ['10f0c6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c6',
    },
    {
        name: 'paperclip-vertical',
        categories: ['business', 'writing'],
        terms: [],
        label: 'Paperclip Vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c2',
    },
    {
        name: 'parachute-box',
        categories: ['charity', 'humanitarian'],
        terms: ['aid', 'assistance', 'rescue', 'supplies'],
        label: 'Parachute Box',
        aliases: {
            unicodes: {
                secondary: ['10f4cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4cd',
    },
    {
        name: 'paragraph',
        categories: ['text-formatting', 'writing'],
        terms: ['edit', 'format', 'text', 'writing'],
        label: 'paragraph',
        aliases: {
            unicodes: {
                composite: ['b6'],
                secondary: ['10f1dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1dd',
    },
    {
        name: 'paragraph-left',
        categories: ['text-formatting'],
        terms: [],
        label: 'Paragraph left',
        aliases: {
            names: ['paragraph-rtl'],
            unicodes: {
                secondary: ['10f878'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f878',
    },
    {
        name: 'party-bell',
        categories: ['alert', 'holidays'],
        terms: [],
        label: 'Party Bell',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e31a',
    },
    {
        name: 'party-horn',
        categories: ['alert', 'holidays'],
        terms: [],
        label: 'Party Horn',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e31b',
    },
    {
        name: 'passport',
        categories: ['security', 'travel-hotel'],
        terms: ['document', 'id', 'identification', 'issued', 'travel'],
        label: 'Passport',
        aliases: {
            unicodes: {
                secondary: ['10f5ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ab',
    },
    {
        name: 'paste',
        categories: ['business', 'design', 'files'],
        terms: ['clipboard', 'copy', 'document', 'paper'],
        label: 'Paste',
        aliases: {
            names: ['file-clipboard'],
            unicodes: {
                secondary: ['10f0ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ea',
    },
    {
        name: 'pause',
        categories: ['media-playback'],
        terms: ['hold', 'wait'],
        label: 'pause',
        aliases: {
            unicodes: {
                composite: ['23f8'],
                secondary: ['10f04c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f04c',
    },
    {
        name: 'paw',
        categories: ['animals', 'maps'],
        terms: ['animal', 'cat', 'dog', 'pet', 'print'],
        label: 'Paw',
        aliases: {
            unicodes: {
                secondary: ['10f1b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1b0',
    },
    {
        name: 'paw-claws',
        categories: ['animals', 'gaming'],
        terms: ['Dungeons & Dragons', 'animal', 'beast', 'd&d', 'dnd', 'fantasy', 'lion', 'pet', 'print'],
        label: 'Paw Claws',
        aliases: {
            unicodes: {
                secondary: ['10f702'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f702',
    },
    {
        name: 'paw-simple',
        categories: ['animals', 'travel-hotel'],
        terms: [],
        label: 'Paw simple',
        aliases: {
            names: ['paw-alt'],
            unicodes: {
                secondary: ['10f701'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f701',
    },
    {
        name: 'paypal',
        categories: ['shopping'],
        terms: [],
        label: 'Paypal',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1ed',
    },
    {
        name: 'peace',
        categories: ['religion'],
        terms: ['serenity', 'tranquility', 'truce', 'war'],
        label: 'Peace',
        aliases: {
            unicodes: {
                composite: ['262e'],
                secondary: ['10f67c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67c',
    },
    {
        name: 'peach',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Peach',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20b',
    },
    {
        name: 'peanut',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Peanut',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e430',
    },
    {
        name: 'peanuts',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Peanuts',
        aliases: {
            unicodes: {
                composite: ['1f95c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e431',
    },
    {
        name: 'peapod',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Peapod',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e31c',
    },
    {
        name: 'pear',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Pear',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20c',
    },
    {
        name: 'pedestal',
        categories: ['energy'],
        terms: [],
        label: 'Pedestal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20d',
    },
    {
        name: 'pegasus',
        categories: ['animals', 'transportation'],
        terms: ['fantasy', 'horse', 'unicorn', 'wings'],
        label: 'Pegasus',
        aliases: {
            unicodes: {
                secondary: ['10f703'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f703',
    },
    {
        name: 'pen',
        categories: ['business', 'design', 'editing', 'writing'],
        terms: ['design', 'edit', 'update', 'write'],
        label: 'Pen',
        aliases: {
            unicodes: {
                composite: ['1f58a'],
                secondary: ['10f304'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f304',
    },
    {
        name: 'pen-circle',
        categories: ['design', 'editing', 'writing'],
        terms: [],
        label: 'Pen Circle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20e',
    },
    {
        name: 'pen-clip',
        categories: ['business', 'design', 'editing', 'writing'],
        terms: [],
        label: 'Pen clip',
        aliases: {
            names: ['pen-alt'],
            unicodes: {
                secondary: ['10f305'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f305',
    },
    {
        name: 'pen-clip-slash',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen clip slash',
        aliases: {
            names: ['pen-alt-slash'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e20f',
    },
    {
        name: 'pen-fancy',
        categories: ['business', 'design', 'editing'],
        terms: ['design', 'edit', 'fountain pen', 'update', 'write'],
        label: 'Pen Fancy',
        aliases: {
            unicodes: {
                composite: ['1f58b', '2712'],
                secondary: ['10f5ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ac',
    },
    {
        name: 'pen-fancy-slash',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen Fancy-slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e210',
    },
    {
        name: 'pen-field',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen Field',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e211',
    },
    {
        name: 'pen-line',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen Line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e212',
    },
    {
        name: 'pen-nib',
        categories: ['business', 'design', 'editing'],
        terms: ['design', 'edit', 'fountain pen', 'update', 'write'],
        label: 'Pen Nib',
        aliases: {
            unicodes: {
                composite: ['2711'],
                secondary: ['10f5ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ad',
    },
    {
        name: 'pen-nib-slash',
        categories: ['business', 'design', 'editing'],
        terms: [],
        label: 'Pen Nib-slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a1',
    },
    {
        name: 'pen-paintbrush',
        categories: ['editing', 'education'],
        terms: [],
        label: 'Pen paintbrush',
        aliases: {
            names: ['pencil-paintbrush'],
            unicodes: {
                secondary: ['10f618'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f618',
    },
    {
        name: 'pen-ruler',
        categories: ['construction', 'design', 'editing'],
        terms: [],
        label: 'Pen ruler',
        aliases: {
            names: ['pencil-ruler'],
            unicodes: {
                secondary: ['10f5ae'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ae',
    },
    {
        name: 'pen-slash',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e213',
    },
    {
        name: 'pen-swirl',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pen Swirl',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e214',
    },
    {
        name: 'pen-to-square',
        categories: ['business', 'design', 'editing', 'writing'],
        terms: [],
        label: 'Pen to square',
        aliases: {
            names: ['edit'],
            unicodes: {
                secondary: ['10f044'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f044',
    },
    {
        name: 'pencil',
        categories: ['business', 'construction', 'design', 'editing', 'writing'],
        terms: ['design', 'edit', 'update', 'write'],
        label: 'pencil',
        aliases: {
            names: ['pencil-alt'],
            unicodes: {
                composite: ['f040', '270f'],
                primary: ['f040'],
                secondary: ['10f303', '10f040'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f303',
    },
    {
        name: 'pencil-slash',
        categories: ['editing', 'writing'],
        terms: [],
        label: 'Pencil Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e215',
    },
    {
        name: 'people',
        categories: ['users-people'],
        terms: [],
        label: 'People',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e216',
    },
    {
        name: 'people-arrows-left-right',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'People arrows left right',
        aliases: {
            names: ['people-arrows'],
            unicodes: {
                secondary: ['10e068'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e068',
    },
    {
        name: 'people-carry-box',
        categories: ['moving', 'users-people'],
        terms: [],
        label: 'People carry box',
        aliases: {
            names: ['people-carry'],
            unicodes: {
                secondary: ['10f4ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ce',
    },
    {
        name: 'people-dress',
        categories: ['users-people'],
        terms: [],
        label: 'People Dress',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e217',
    },
    {
        name: 'people-dress-simple',
        categories: ['users-people'],
        terms: [],
        label: 'People Dress-simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e218',
    },
    {
        name: 'people-group',
        categories: ['humanitarian', 'marketing', 'users-people'],
        terms: [],
        label: 'People Group',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e533',
    },
    {
        name: 'people-line',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'People Line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e534',
    },
    {
        name: 'people-pants',
        categories: ['users-people'],
        terms: [],
        label: 'People Pants',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e219',
    },
    {
        name: 'people-pants-simple',
        categories: ['users-people'],
        terms: [],
        label: 'People Pants-simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21a',
    },
    {
        name: 'people-pulling',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'People Pulling',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e535',
    },
    {
        name: 'people-robbery',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'People Robbery',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e536',
    },
    {
        name: 'people-roof',
        categories: ['camping', 'household', 'humanitarian', 'users-people'],
        terms: [],
        label: 'People Roof',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e537',
    },
    {
        name: 'people-simple',
        categories: ['users-people'],
        terms: [],
        label: 'People Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21b',
    },
    {
        name: 'pepper',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Pepper',
        aliases: {
            unicodes: {
                composite: ['1fad1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e432',
    },
    {
        name: 'pepper-hot',
        categories: ['food-beverage', 'fruits-vegetables'],
        terms: [
            'buffalo wings',
            'capsicum',
            'chili',
            'chilli',
            'habanero',
            'jalapeno',
            'mexican',
            'spicy',
            'tabasco',
            'vegetable',
        ],
        label: 'Hot Pepper',
        aliases: {
            unicodes: {
                composite: ['1f336'],
                secondary: ['10f816'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f816',
    },
    {
        name: 'percent',
        categories: ['business', 'mathematics', 'money', 'punctuation-symbols'],
        terms: ['discount', 'fraction', 'proportion', 'rate', 'ratio'],
        label: 'Percent',
        aliases: {
            names: ['percentage'],
            unicodes: {
                composite: ['f295', 'f541'],
                primary: ['f541', 'f295'],
                secondary: ['10f541', '1025', '10f295'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '25',
    },
    {
        name: 'period',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Period',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2e',
    },
    {
        name: 'person',
        categories: ['humanitarian', 'maps', 'users-people'],
        terms: [],
        label: 'Person',
        aliases: {
            names: ['male'],
            unicodes: {
                composite: ['1f9cd'],
                secondary: ['10f183'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f183',
    },
    {
        name: 'person-arrow-down-to-line',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Arrow-down-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e538',
    },
    {
        name: 'person-arrow-up-from-line',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Arrow-up-from-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e539',
    },
    {
        name: 'person-biking',
        categories: ['childhood', 'sports-fitness', 'users-people'],
        terms: [],
        label: 'Person biking',
        aliases: {
            names: ['biking'],
            unicodes: {
                composite: ['1f6b4'],
                secondary: ['10f84a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84a',
    },
    {
        name: 'person-biking-mountain',
        categories: ['camping', 'sports-fitness', 'users-people'],
        terms: [],
        label: 'Person biking mountain',
        aliases: {
            names: ['biking-mountain'],
            unicodes: {
                composite: ['1f6b5'],
                secondary: ['10f84b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f84b',
    },
    {
        name: 'person-booth',
        categories: ['political', 'shopping', 'users-people'],
        terms: ['changing', 'changing room', 'election', 'human', 'person', 'vote', 'voting'],
        label: 'Person Entering Booth',
        aliases: {
            unicodes: {
                secondary: ['10f756'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f756',
    },
    {
        name: 'person-breastfeeding',
        categories: ['childhood', 'humanitarian', 'medical-health', 'users-people'],
        terms: [],
        label: 'Person Breastfeeding',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53a',
    },
    {
        name: 'person-burst',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Burst',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53b',
    },
    {
        name: 'person-cane',
        categories: ['accessibility', 'humanitarian', 'medical-health', 'users-people'],
        terms: [],
        label: 'Person Cane',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53c',
    },
    {
        name: 'person-carry-box',
        categories: ['logistics', 'moving', 'users-people'],
        terms: [],
        label: 'Person carry box',
        aliases: {
            names: ['person-carry'],
            unicodes: {
                secondary: ['10f4cf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4cf',
    },
    {
        name: 'person-chalkboard',
        categories: ['business', 'education', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person Chalkboard',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53d',
    },
    {
        name: 'person-circle-check',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53e',
    },
    {
        name: 'person-circle-exclamation',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e53f',
    },
    {
        name: 'person-circle-minus',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e540',
    },
    {
        name: 'person-circle-plus',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e541',
    },
    {
        name: 'person-circle-question',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e542',
    },
    {
        name: 'person-circle-xmark',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e543',
    },
    {
        name: 'person-digging',
        categories: ['construction', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person digging',
        aliases: {
            names: ['digging'],
            unicodes: {
                secondary: ['10f85e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85e',
    },
    {
        name: 'person-dolly',
        categories: ['logistics', 'moving', 'users-people'],
        terms: ['human', 'lift', 'mover', 'person', 'transport'],
        label: 'Person and Dolly',
        aliases: {
            unicodes: {
                secondary: ['10f4d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d0',
    },
    {
        name: 'person-dolly-empty',
        categories: ['logistics', 'moving', 'users-people'],
        terms: ['human', 'lift', 'mover', 'person', 'transport'],
        label: 'Person and Empty Dolly',
        aliases: {
            unicodes: {
                secondary: ['10f4d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d1',
    },
    {
        name: 'person-dots-from-line',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'Person dots from line',
        aliases: {
            names: ['diagnoses'],
            unicodes: {
                secondary: ['10f470'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f470',
    },
    {
        name: 'person-dress',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person dress',
        aliases: {
            names: ['female'],
            unicodes: {
                secondary: ['10f182'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f182',
    },
    {
        name: 'person-dress-burst',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Dress BUrst',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e544',
    },
    {
        name: 'person-dress-simple',
        categories: ['users-people'],
        terms: [],
        label: 'Person Dress-simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21c',
    },
    {
        name: 'person-drowning',
        categories: ['disaster', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person Drowning',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e545',
    },
    {
        name: 'person-falling',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Falling',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e546',
    },
    {
        name: 'person-falling-burst',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Falling Burst',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e547',
    },
    {
        name: 'person-from-portal',
        categories: ['science-fiction', 'users-people'],
        terms: [],
        label: 'Person from portal',
        aliases: {
            names: ['portal-exit'],
            unicodes: {
                secondary: ['10e023'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e023',
    },
    {
        name: 'person-half-dress',
        categories: ['gender', 'humanitarian', 'medical-health', 'users-people'],
        terms: [],
        label: 'Person Half-dress',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e548',
    },
    {
        name: 'person-harassing',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Harassing',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e549',
    },
    {
        name: 'person-hiking',
        categories: ['camping', 'nature', 'sports-fitness', 'users-people'],
        terms: [],
        label: 'Person hiking',
        aliases: {
            names: ['hiking'],
            unicodes: {
                secondary: ['10f6ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ec',
    },
    {
        name: 'person-military-pointing',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Military-pointing',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54a',
    },
    {
        name: 'person-military-rifle',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Military-rifle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54b',
    },
    {
        name: 'person-military-to-person',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Military-to-person',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54c',
    },
    {
        name: 'person-pinball',
        categories: ['gaming', 'users-people'],
        terms: [],
        label: 'Person Pinball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21d',
    },
    {
        name: 'person-praying',
        categories: ['religion', 'users-people'],
        terms: [],
        label: 'Person praying',
        aliases: {
            names: ['pray'],
            unicodes: {
                composite: ['1f6d0'],
                secondary: ['10f683'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f683',
    },
    {
        name: 'person-pregnant',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Person Pregnant',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e31e',
    },
    {
        name: 'person-rays',
        categories: ['humanitarian', 'marketing', 'users-people'],
        terms: [],
        label: 'Person Rays',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54d',
    },
    {
        name: 'person-rifle',
        categories: ['disaster', 'humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Rifle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54e',
    },
    {
        name: 'person-running',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person running',
        aliases: {
            names: ['running'],
            unicodes: {
                composite: ['1f3c3'],
                secondary: ['10f70c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70c',
    },
    {
        name: 'person-seat',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'Person in a Seat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21e',
    },
    {
        name: 'person-seat-reclined',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'Person Seat-reclined',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e21f',
    },
    {
        name: 'person-shelter',
        categories: ['camping', 'humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Shelter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e54f',
    },
    {
        name: 'person-sign',
        categories: ['political', 'users-people'],
        terms: ['advocate', 'human', 'information', 'person', 'protest', 'protester', 'volunteer'],
        label: 'Person Holding Sign',
        aliases: {
            unicodes: {
                secondary: ['10f757'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f757',
    },
    {
        name: 'person-simple',
        categories: ['users-people'],
        terms: [],
        label: 'Person Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e220',
    },
    {
        name: 'person-skating',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person skating',
        aliases: {
            names: ['skating'],
            unicodes: {
                secondary: ['10f7c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c5',
    },
    {
        name: 'person-ski-jumping',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person ski jumping',
        aliases: {
            names: ['ski-jump'],
            unicodes: {
                secondary: ['10f7c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c7',
    },
    {
        name: 'person-ski-lift',
        categories: ['transportation', 'users-people'],
        terms: [],
        label: 'Person ski lift',
        aliases: {
            names: ['ski-lift'],
            unicodes: {
                secondary: ['10f7c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c8',
    },
    {
        name: 'person-skiing',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person skiing',
        aliases: {
            names: ['skiing'],
            unicodes: {
                composite: ['26f7'],
                secondary: ['10f7c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c9',
    },
    {
        name: 'person-skiing-nordic',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person skiing nordic',
        aliases: {
            names: ['skiing-nordic'],
            unicodes: {
                secondary: ['10f7ca'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ca',
    },
    {
        name: 'person-sledding',
        categories: ['childhood', 'sports-fitness', 'users-people'],
        terms: [],
        label: 'Person sledding',
        aliases: {
            names: ['sledding'],
            unicodes: {
                secondary: ['10f7cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7cb',
    },
    {
        name: 'person-snowboarding',
        categories: ['sports-fitness', 'users-people'],
        terms: [],
        label: 'Person snowboarding',
        aliases: {
            names: ['snowboarding'],
            unicodes: {
                composite: ['1f3c2'],
                secondary: ['10f7ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7ce',
    },
    {
        name: 'person-snowmobiling',
        categories: ['transportation', 'users-people'],
        terms: [],
        label: 'Person snowmobiling',
        aliases: {
            names: ['snowmobile'],
            unicodes: {
                secondary: ['10f7d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d1',
    },
    {
        name: 'person-swimming',
        categories: ['maritime', 'sports-fitness', 'travel-hotel', 'users-people'],
        terms: [],
        label: 'Person swimming',
        aliases: {
            names: ['swimmer'],
            unicodes: {
                composite: ['1f3ca'],
                secondary: ['10f5c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c4',
    },
    {
        name: 'person-through-window',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Person Through-window',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e433',
    },
    {
        name: 'person-to-door',
        categories: ['household', 'security', 'users-people'],
        terms: [],
        label: 'Person To-door',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e550',
    },
    {
        name: 'person-to-portal',
        categories: ['science-fiction', 'users-people'],
        terms: [],
        label: 'Person to portal',
        aliases: {
            names: ['portal-enter'],
            unicodes: {
                secondary: ['10e022'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e022',
    },
    {
        name: 'person-walking',
        categories: ['humanitarian', 'sports-fitness', 'users-people'],
        terms: [],
        label: 'Person walking',
        aliases: {
            names: ['walking'],
            unicodes: {
                composite: ['1f6b6'],
                secondary: ['10f554'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f554',
    },
    {
        name: 'person-walking-arrow-loop-left',
        categories: ['disaster', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person Walking-arrow-loop-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e551',
    },
    {
        name: 'person-walking-arrow-right',
        categories: ['disaster', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person Walking-arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e552',
    },
    {
        name: 'person-walking-dashed-line-arrow-right',
        categories: ['disaster', 'humanitarian', 'users-people'],
        terms: [],
        label: 'Person Walking-dashed-line-arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e553',
    },
    {
        name: 'person-walking-luggage',
        categories: ['humanitarian', 'travel-hotel', 'users-people'],
        terms: [],
        label: 'Person Walking-luggage',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e554',
    },
    {
        name: 'person-walking-with-cane',
        categories: ['accessibility', 'maps', 'users-people'],
        terms: [],
        label: 'Person walking with cane',
        aliases: {
            names: ['blind'],
            unicodes: {
                secondary: ['10f29d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f29d',
    },
    {
        name: 'peseta-sign',
        categories: ['money'],
        terms: [],
        label: 'Peseta Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e221',
    },
    {
        name: 'peso-sign',
        categories: ['money'],
        terms: [],
        label: 'Peso Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e222',
    },
    {
        name: 'phone',
        categories: ['business', 'communication', 'maps'],
        terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
        label: 'Phone',
        aliases: {
            unicodes: {
                composite: ['1f57b', '1f4de'],
                secondary: ['10f095'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f095',
    },
    {
        name: 'phone-arrow-down-left',
        categories: ['communication'],
        terms: [],
        label: 'Phone arrow down left',
        aliases: {
            names: ['phone-arrow-down', 'phone-incoming'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e223',
    },
    {
        name: 'phone-arrow-up-right',
        categories: ['communication'],
        terms: [],
        label: 'Phone arrow up right',
        aliases: {
            names: ['phone-arrow-up', 'phone-outgoing'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e224',
    },
    {
        name: 'phone-flip',
        categories: ['business', 'communication', 'maps'],
        terms: [],
        label: 'Phone flip',
        aliases: {
            names: ['phone-alt'],
            unicodes: {
                composite: ['1f57d'],
                secondary: ['10f879'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f879',
    },
    {
        name: 'phone-hangup',
        categories: ['communication'],
        terms: [],
        label: 'Phone hangup',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e225',
    },
    {
        name: 'phone-intercom',
        categories: ['business', 'communication', 'household', 'security'],
        terms: [],
        label: 'Phone Intercom',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e434',
    },
    {
        name: 'phone-missed',
        categories: ['communication'],
        terms: [],
        label: 'Phone Missed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e226',
    },
    {
        name: 'phone-office',
        categories: ['business', 'devices-hardware'],
        terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
        label: 'Office Phone',
        aliases: {
            unicodes: {
                secondary: ['10f67d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67d',
    },
    {
        name: 'phone-plus',
        categories: ['communication'],
        terms: ['add', 'call', 'earphone', 'number', 'positive', 'support', 'telephone', 'voice'],
        label: 'Phone Plus',
        aliases: {
            unicodes: {
                secondary: ['10f4d2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d2',
    },
    {
        name: 'phone-rotary',
        categories: ['travel-hotel'],
        terms: ['call', 'earphone', 'number', 'retro', 'support', 'telephone', 'vintage', 'voice'],
        label: 'Rotary Phone',
        aliases: {
            unicodes: {
                composite: ['260e'],
                secondary: ['10f8d3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d3',
    },
    {
        name: 'phone-slash',
        categories: ['business', 'communication'],
        terms: ['call', 'cancel', 'earphone', 'mute', 'number', 'support', 'telephone', 'voice'],
        label: 'Phone Slash',
        aliases: {
            unicodes: {
                secondary: ['10f3dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3dd',
    },
    {
        name: 'phone-volume',
        categories: ['accessibility', 'business', 'communication', 'maps', 'media-playback'],
        terms: ['call', 'earphone', 'number', 'sound', 'support', 'telephone', 'voice', 'volume-control-phone'],
        label: 'Phone Volume',
        aliases: {
            names: ['volume-control-phone'],
            unicodes: {
                secondary: ['10f2a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2a0',
    },
    {
        name: 'phone-xmark',
        categories: ['communication'],
        terms: [],
        label: 'Phone Xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e227',
    },
    {
        name: 'photo-film',
        categories: ['files', 'film-video', 'photos-images', 'social'],
        terms: [],
        label: 'Photo film',
        aliases: {
            names: ['photo-video'],
            unicodes: {
                secondary: ['10f87c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87c',
    },
    {
        name: 'photo-film-music',
        categories: ['film-video', 'music-audio', 'photos-images', 'social'],
        terms: [],
        label: 'Photo film music',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e228',
    },
    {
        name: 'pi',
        categories: ['mathematics'],
        terms: ['alphabet', 'circumference', 'diameter', 'greek', 'math', 'mathematics'],
        label: 'Pi',
        aliases: {
            unicodes: {
                secondary: ['10f67e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67e',
    },
    {
        name: 'piano',
        categories: ['music-audio'],
        terms: ['grand piano', 'instrument', 'keyboard', 'keys', 'midi', 'music'],
        label: 'Piano',
        aliases: {
            unicodes: {
                secondary: ['10f8d4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d4',
    },
    {
        name: 'piano-keyboard',
        categories: ['music-audio'],
        terms: ['instrument', 'keyboard', 'keys', 'midi', 'music'],
        label: 'Piano Keyboard',
        aliases: {
            unicodes: {
                composite: ['1f3b9'],
                secondary: ['10f8d5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d5',
    },
    {
        name: 'pickleball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Pickleball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e435',
    },
    {
        name: 'pie',
        categories: ['food-beverage'],
        terms: ['apple', 'bakery', 'cake', 'crust', 'dessert', 'pumpkin', 'tart'],
        label: 'Pie',
        aliases: {
            unicodes: {
                composite: ['1f967'],
                secondary: ['10f705'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f705',
    },
    {
        name: 'pig',
        categories: ['animals'],
        terms: ['agriculture', 'animal', 'farm', 'fauna', 'mammal', 'oink'],
        label: 'Pig',
        aliases: {
            unicodes: {
                composite: ['1f416'],
                secondary: ['10f706'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f706',
    },
    {
        name: 'piggy-bank',
        categories: ['charity', 'money', 'political'],
        terms: ['bank', 'save', 'savings'],
        label: 'Piggy Bank',
        aliases: {
            unicodes: {
                secondary: ['10f4d3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d3',
    },
    {
        name: 'pills',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: ['drugs', 'medicine', 'prescription', 'tablets'],
        label: 'Pills',
        aliases: {
            unicodes: {
                secondary: ['10f484'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f484',
    },
    {
        name: 'pinata',
        categories: ['childhood', 'gaming', 'holidays'],
        terms: [],
        label: 'Pinata',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c3',
    },
    {
        name: 'pinball',
        categories: ['gaming'],
        terms: [],
        label: 'Pinball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e229',
    },
    {
        name: 'pineapple',
        categories: ['fruits-vegetables', 'travel-hotel'],
        terms: [],
        label: 'Pineapple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e31f',
    },
    {
        name: 'pipe',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Pipe',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '7c',
    },
    {
        name: 'pipe-circle-check',
        categories: ['energy'],
        terms: [],
        label: 'Pipe Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e436',
    },
    {
        name: 'pipe-collar',
        categories: ['energy'],
        terms: [],
        label: 'Pipe Collar',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e437',
    },
    {
        name: 'pipe-section',
        categories: ['energy'],
        terms: [],
        label: 'Pipe Section',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e438',
    },
    {
        name: 'pipe-smoking',
        categories: ['clothing-fashion'],
        terms: [],
        label: 'Pipe Smoking',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c4',
    },
    {
        name: 'pipe-valve',
        categories: ['energy'],
        terms: [],
        label: 'Pipe Valve',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e439',
    },
    {
        name: 'pizza',
        categories: ['food-beverage'],
        terms: [
            'cheese',
            'chicago',
            'italian',
            'mozzarella',
            'new york',
            'pepperoni',
            'pie',
            'slice',
            'teenage mutant ninja turtles',
            'tomato',
        ],
        label: 'Pizza',
        aliases: {
            unicodes: {
                composite: ['1f355'],
                secondary: ['10f817'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f817',
    },
    {
        name: 'pizza-slice',
        categories: ['food-beverage'],
        terms: [
            'cheese',
            'chicago',
            'italian',
            'mozzarella',
            'new york',
            'pepperoni',
            'pie',
            'slice',
            'teenage mutant ninja turtles',
            'tomato',
        ],
        label: 'Pizza Slice',
        aliases: {
            unicodes: {
                secondary: ['10f818'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f818',
    },
    {
        name: 'place-of-worship',
        categories: ['buildings', 'religion'],
        terms: ['building', 'church', 'holy', 'mosque', 'synagogue'],
        label: 'Place of Worship',
        aliases: {
            unicodes: {
                secondary: ['10f67f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67f',
    },
    {
        name: 'plane',
        categories: ['maps', 'transportation', 'travel-hotel'],
        terms: ['airplane', 'destination', 'fly', 'location', 'mode', 'travel', 'trip'],
        label: 'plane',
        aliases: {
            unicodes: {
                secondary: ['10f072'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f072',
    },
    {
        name: 'plane-arrival',
        categories: ['travel-hotel'],
        terms: ['airplane', 'arriving', 'destination', 'fly', 'land', 'landing', 'location', 'mode', 'travel', 'trip'],
        label: 'Plane Arrival',
        aliases: {
            unicodes: {
                composite: ['1f6ec'],
                secondary: ['10f5af'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5af',
    },
    {
        name: 'plane-circle-check',
        categories: ['humanitarian', 'logistics', 'travel-hotel'],
        terms: [],
        label: 'Plane Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e555',
    },
    {
        name: 'plane-circle-exclamation',
        categories: ['humanitarian', 'logistics', 'travel-hotel'],
        terms: [],
        label: 'Plane Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e556',
    },
    {
        name: 'plane-circle-xmark',
        categories: ['humanitarian', 'logistics', 'travel-hotel'],
        terms: [],
        label: 'Plane Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e557',
    },
    {
        name: 'plane-departure',
        categories: ['travel-hotel'],
        terms: [
            'airplane',
            'departing',
            'destination',
            'fly',
            'location',
            'mode',
            'take off',
            'taking off',
            'travel',
            'trip',
        ],
        label: 'Plane Departure',
        aliases: {
            unicodes: {
                composite: ['1f6eb'],
                secondary: ['10f5b0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b0',
    },
    {
        name: 'plane-engines',
        categories: ['maps', 'transportation'],
        terms: [],
        label: 'Plane engines',
        aliases: {
            names: ['plane-alt'],
            unicodes: {
                composite: ['2708'],
                secondary: ['10f3de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3de',
    },
    {
        name: 'plane-lock',
        categories: ['humanitarian', 'logistics', 'travel-hotel'],
        terms: [],
        label: 'Plane Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e558',
    },
    {
        name: 'plane-prop',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Plane Prop',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e22b',
    },
    {
        name: 'plane-slash',
        categories: ['transportation', 'travel-hotel'],
        terms: ['airplane mode', 'canceled', 'covid-19', 'delayed', 'grounded', 'travel'],
        label: 'Plane Slash',
        aliases: {
            unicodes: {
                secondary: ['10e069'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e069',
    },
    {
        name: 'plane-tail',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Plane Tail',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e22c',
    },
    {
        name: 'plane-up',
        categories: ['humanitarian', 'toggle', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Plane Up',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e22d',
    },
    {
        name: 'plane-up-slash',
        categories: ['toggle', 'travel-hotel'],
        terms: [],
        label: 'Plane Up-slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e22e',
    },
    {
        name: 'planet-moon',
        categories: ['astronomy'],
        terms: ['earth', 'natural satellite', 'orbit', 'solar system', 'space', 'universe'],
        label: 'Planet with Moon',
        aliases: {
            unicodes: {
                secondary: ['10e01f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e01f',
    },
    {
        name: 'planet-ringed',
        categories: ['astronomy'],
        terms: ['orbit', 'saturn', 'solar system', 'space', 'universe'],
        label: 'Ringed Planet',
        aliases: {
            unicodes: {
                composite: ['1fa90'],
                secondary: ['10e020'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e020',
    },
    {
        name: 'plant-wilt',
        categories: ['disaster', 'humanitarian', 'nature'],
        terms: [],
        label: 'Plant Wilt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e43b',
    },
    {
        name: 'plate-utensils',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Plate Utensils',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e559',
    },
    {
        name: 'plate-wheat',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Plate Wheat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55a',
    },
    {
        name: 'play',
        categories: ['media-playback', 'shapes'],
        terms: ['audio', 'music', 'playing', 'sound', 'start', 'video'],
        label: 'play',
        aliases: {
            unicodes: {
                composite: ['25b6'],
                secondary: ['10f04b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f04b',
    },
    {
        name: 'play-pause',
        categories: ['media-playback'],
        terms: [],
        label: 'Play Pause',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e22f',
    },
    {
        name: 'playstation',
        categories: ['gaming'],
        terms: [],
        label: 'PlayStation',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f3df',
    },
    {
        name: 'plug',
        categories: ['devices-hardware', 'energy', 'household', 'humanitarian', 'maps'],
        terms: ['connect', 'electric', 'online', 'power'],
        label: 'Plug',
        aliases: {
            unicodes: {
                composite: ['1f50c'],
                secondary: ['10f1e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e6',
    },
    {
        name: 'plug-circle-bolt',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55b',
    },
    {
        name: 'plug-circle-check',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55c',
    },
    {
        name: 'plug-circle-exclamation',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55d',
    },
    {
        name: 'plug-circle-minus',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55e',
    },
    {
        name: 'plug-circle-plus',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e55f',
    },
    {
        name: 'plug-circle-xmark',
        categories: ['energy', 'humanitarian'],
        terms: [],
        label: 'Plug Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e560',
    },
    {
        name: 'plus',
        categories: ['editing', 'maps', 'mathematics', 'medical-health', 'punctuation-symbols'],
        terms: ['add', 'create', 'expand', 'new', 'positive', 'shape'],
        label: 'plus',
        aliases: {
            names: ['add'],
            unicodes: {
                composite: ['2795', 'f067'],
                primary: ['f067'],
                secondary: ['10f067', '102b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2b',
    },
    {
        name: 'plus-large',
        categories: ['editing'],
        terms: [],
        label: 'Plus Large',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e59e',
    },
    {
        name: 'plus-minus',
        categories: ['mathematics', 'media-playback'],
        terms: [],
        label: 'Plus Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e43c',
    },
    {
        name: 'podcast',
        categories: ['film-video'],
        terms: ['audio', 'broadcast', 'music', 'sound'],
        label: 'Podcast',
        aliases: {
            unicodes: {
                secondary: ['10f2ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ce',
    },
    {
        name: 'podium',
        categories: ['business', 'political'],
        terms: [
            'debate',
            'election',
            'keynote',
            'lecture',
            'panel',
            'politics',
            'seminar',
            'speak',
            'speaker',
            'speech',
            'talk',
        ],
        label: 'Podium',
        aliases: {
            unicodes: {
                secondary: ['10f680'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f680',
    },
    {
        name: 'podium-star',
        categories: ['political'],
        terms: [
            'debate',
            'election',
            'keynote',
            'lecture',
            'panel',
            'politics',
            'seminar',
            'speak',
            'speaker',
            'speech',
            'talk',
        ],
        label: 'Podium with Star',
        aliases: {
            unicodes: {
                secondary: ['10f758'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f758',
    },
    {
        name: 'police-box',
        categories: ['science-fiction'],
        terms: ['doctor who', 'tardis', 'time and relative dimension in space', 'time machine'],
        label: 'Police Box',
        aliases: {
            unicodes: {
                secondary: ['10e021'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e021',
    },
    {
        name: 'poll-people',
        categories: ['political', 'users-people'],
        terms: ['candidates', 'election', 'people', 'person', 'results', 'survey', 'trend', 'vote', 'voting'],
        label: 'Poll People',
        aliases: {
            unicodes: {
                secondary: ['10f759'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f759',
    },
    {
        name: 'pompebled',
        categories: ['nature'],
        terms: [],
        label: 'Pompebled',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e43d',
    },
    {
        name: 'poo',
        categories: ['communication', 'users-people'],
        terms: ['crap', 'poop', 'shit', 'smile', 'turd'],
        label: 'Poo',
        aliases: {
            unicodes: {
                composite: ['1f4a9'],
                secondary: ['10f2fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2fe',
    },
    {
        name: 'poo-storm',
        categories: ['weather'],
        terms: ['bolt', 'cloud', 'euphemism', 'lightning', 'mess', 'poop', 'shit', 'turd'],
        label: 'Poo bolt',
        aliases: {
            names: ['poo-bolt'],
            unicodes: {
                secondary: ['10f75a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f75a',
    },
    {
        name: 'pool-8-ball',
        categories: ['childhood', 'gaming'],
        terms: [],
        label: 'Pool 8-ball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c5',
    },
    {
        name: 'poop',
        categories: ['energy', 'medical-health'],
        terms: ['crap', 'poop', 'shit', 'smile', 'turd'],
        label: 'Poop',
        aliases: {
            unicodes: {
                secondary: ['10f619'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f619',
    },
    {
        name: 'popcorn',
        categories: ['food-beverage'],
        terms: ['bucket', 'butter', 'corn', 'junk food', 'kernels', 'movie', 'snack', 'theater'],
        label: 'Popcorn',
        aliases: {
            unicodes: {
                composite: ['1f37f'],
                secondary: ['10f819'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f819',
    },
    {
        name: 'popsicle',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Popsicle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e43e',
    },
    {
        name: 'pot-food',
        categories: ['food-beverage'],
        terms: [],
        label: 'Pot Food',
        aliases: {
            unicodes: {
                composite: ['1f372'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e43f',
    },
    {
        name: 'potato',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Potato',
        aliases: {
            unicodes: {
                composite: ['1f954'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e440',
    },
    {
        name: 'power-off',
        categories: ['devices-hardware', 'energy'],
        terms: ['cancel', 'computer', 'on', 'reboot', 'restart'],
        label: 'Power Off',
        aliases: {
            unicodes: {
                composite: ['23fb'],
                secondary: ['10f011'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f011',
    },
    {
        name: 'prescription',
        categories: ['medical-health'],
        terms: ['drugs', 'medical', 'medicine', 'pharmacy', 'rx'],
        label: 'Prescription',
        aliases: {
            unicodes: {
                secondary: ['10f5b1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b1',
    },
    {
        name: 'prescription-bottle',
        categories: ['medical-health', 'science'],
        terms: ['drugs', 'medical', 'medicine', 'pharmacy', 'rx'],
        label: 'Prescription Bottle',
        aliases: {
            unicodes: {
                secondary: ['10f485'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f485',
    },
    {
        name: 'prescription-bottle-medical',
        categories: ['medical-health'],
        terms: [],
        label: 'Prescription bottle medical',
        aliases: {
            names: ['prescription-bottle-alt'],
            unicodes: {
                secondary: ['10f486'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f486',
    },
    {
        name: 'presentation-screen',
        categories: ['business'],
        terms: [],
        label: 'Presentation screen',
        aliases: {
            names: ['presentation'],
            unicodes: {
                secondary: ['10f685'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f685',
    },
    {
        name: 'pretzel',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Pretzel',
        aliases: {
            unicodes: {
                composite: ['1f968'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e441',
    },
    {
        name: 'print',
        categories: ['business', 'devices-hardware', 'maps'],
        terms: ['business', 'copy', 'document', 'office', 'paper'],
        label: 'print',
        aliases: {
            unicodes: {
                composite: ['1f5a8', '1f5b6', '2399'],
                secondary: ['10f02f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02f',
    },
    {
        name: 'print-magnifying-glass',
        categories: ['business'],
        terms: [],
        label: 'Print magnifying glass',
        aliases: {
            names: ['print-search'],
            unicodes: {
                secondary: ['10f81a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81a',
    },
    {
        name: 'print-slash',
        categories: ['business'],
        terms: ['business', 'copy', 'document', 'office', 'offline', 'paper'],
        label: 'Print Slash',
        aliases: {
            unicodes: {
                secondary: ['10f686'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f686',
    },
    {
        name: 'projector',
        categories: ['business', 'devices-hardware', 'film-video'],
        terms: ['cinema', 'keynote', 'movie', 'powerpoint', 'presentation', 'shadow puppets', 'slides'],
        label: 'Projector',
        aliases: {
            unicodes: {
                secondary: ['10f8d6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d6',
    },
    {
        name: 'pump',
        categories: ['automotive', 'household'],
        terms: [],
        label: 'Pump',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e442',
    },
    {
        name: 'pump-medical',
        categories: ['medical-health'],
        terms: ['anti-bacterial', 'clean', 'covid-19', 'disinfect', 'hygiene', 'medical grade', 'sanitizer', 'soap'],
        label: 'Pump Medical',
        aliases: {
            unicodes: {
                secondary: ['10e06a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06a',
    },
    {
        name: 'pump-soap',
        categories: ['household', 'humanitarian'],
        terms: ['anti-bacterial', 'clean', 'covid-19', 'disinfect', 'hygiene', 'sanitizer', 'soap'],
        label: 'Pump Soap',
        aliases: {
            unicodes: {
                secondary: ['10e06b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06b',
    },
    {
        name: 'pumpkin',
        categories: ['food-beverage', 'fruits-vegetables'],
        terms: ['autumn', 'gourd', 'halloween', 'harvest', 'squash', 'thanksgiving', 'vegetable'],
        label: 'Pumpkin',
        aliases: {
            unicodes: {
                secondary: ['10f707'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f707',
    },
    {
        name: 'puzzle',
        categories: ['childhood', 'gaming'],
        terms: [],
        label: 'Puzzle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e443',
    },
    {
        name: 'puzzle-piece',
        categories: ['childhood', 'gaming'],
        terms: ['add-on', 'addon', 'game', 'section'],
        label: 'Puzzle Piece',
        aliases: {
            unicodes: {
                composite: ['1f9e9'],
                secondary: ['10f12e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f12e',
    },
    {
        name: 'puzzle-piece-simple',
        categories: ['gaming'],
        terms: [],
        label: 'Puzzle piece simple',
        aliases: {
            names: ['puzzle-piece-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e231',
    },
    {
        name: 'q',
        categories: ['alphabet'],
        terms: [],
        label: 'Q',
        aliases: {
            unicodes: {
                composite: ['71'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '51',
    },
    {
        name: 'qrcode',
        categories: ['coding'],
        terms: ['barcode', 'info', 'information', 'scan'],
        label: 'qrcode',
        aliases: {
            unicodes: {
                secondary: ['10f029'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f029',
    },
    {
        name: 'question',
        categories: ['accessibility', 'alert', 'punctuation-symbols'],
        terms: ['help', 'information', 'support', 'unknown'],
        label: 'Question',
        aliases: {
            unicodes: {
                composite: ['2753', '2754', 'f128'],
                primary: ['f128'],
                secondary: ['10f128', '103f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3f',
    },
    {
        name: 'quote-left',
        categories: ['communication', 'punctuation-symbols', 'writing'],
        terms: ['mention', 'note', 'phrase', 'text', 'type'],
        label: 'quote-left',
        aliases: {
            names: ['quote-left-alt'],
            unicodes: {
                composite: ['201c'],
                secondary: ['10f10d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f10d',
    },
    {
        name: 'quote-right',
        categories: ['communication', 'punctuation-symbols', 'writing'],
        terms: ['mention', 'note', 'phrase', 'text', 'type'],
        label: 'quote-right',
        aliases: {
            names: ['quote-right-alt'],
            unicodes: {
                composite: ['201d'],
                secondary: ['10f10e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f10e',
    },
    {
        name: 'quotes',
        categories: ['punctuation-symbols', 'writing'],
        terms: [],
        label: 'Quotes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e234',
    },
    {
        name: 'r',
        categories: ['alphabet'],
        terms: [],
        label: 'R',
        aliases: {
            unicodes: {
                composite: ['72'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '52',
    },
    {
        name: 'rabbit',
        categories: ['animals'],
        terms: ['animal', 'bunny', 'fast', 'fauna', 'hare', 'mammal', 'speed', 'wabbit'],
        label: 'Rabbit',
        aliases: {
            unicodes: {
                composite: ['1f407'],
                secondary: ['10f708'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f708',
    },
    {
        name: 'rabbit-running',
        categories: ['animals', 'editing'],
        terms: [],
        label: 'Rabbit running',
        aliases: {
            names: ['rabbit-fast'],
            unicodes: {
                secondary: ['10f709'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f709',
    },
    {
        name: 'racquet',
        categories: ['sports-fitness'],
        terms: ['badminton', 'racket', 'squash', 'tennis'],
        label: 'Racquet',
        aliases: {
            unicodes: {
                secondary: ['10f45a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45a',
    },
    {
        name: 'radar',
        categories: ['astronomy'],
        terms: ['blip', 'find', 'lidar', 'radio', 'scan', 'search', 'sonar', 'space'],
        label: 'Radar',
        aliases: {
            unicodes: {
                secondary: ['10e024'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e024',
    },
    {
        name: 'radiation',
        categories: ['alert', 'disaster', 'energy', 'humanitarian', 'medical-health', 'science'],
        terms: ['danger', 'dangerous', 'deadly', 'hazard', 'nuclear', 'radioactive', 'warning'],
        label: 'Radiation',
        aliases: {
            unicodes: {
                secondary: ['10f7b9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7b9',
    },
    {
        name: 'radio',
        categories: ['humanitarian', 'music-audio'],
        terms: ['am', 'broadcast', 'fm', 'frequency', 'music', 'news', 'receiver', 'transmitter', 'tuner'],
        label: 'Radio',
        aliases: {
            unicodes: {
                composite: ['1f4fb'],
                secondary: ['10f8d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d7',
    },
    {
        name: 'radio-tuner',
        categories: ['music-audio'],
        terms: [],
        label: 'Radio tuner',
        aliases: {
            names: ['radio-alt'],
            unicodes: {
                secondary: ['10f8d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d8',
    },
    {
        name: 'rainbow',
        categories: ['weather'],
        terms: ['gold', 'leprechaun', 'prism', 'rain', 'sky'],
        label: 'Rainbow',
        aliases: {
            unicodes: {
                composite: ['1f308'],
                secondary: ['10f75b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f75b',
    },
    {
        name: 'raindrops',
        categories: ['nature', 'weather'],
        terms: ['precipitation', 'rain', 'sprinkle', 'storm'],
        label: 'Raindrops',
        aliases: {
            unicodes: {
                secondary: ['10f75c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f75c',
    },
    {
        name: 'ram',
        categories: ['animals'],
        terms: ['animal', 'fauna', 'horns', 'mammal'],
        label: 'Ram',
        aliases: {
            unicodes: {
                composite: ['1f40f'],
                secondary: ['10f70a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70a',
    },
    {
        name: 'ramp-loading',
        categories: ['moving'],
        terms: ['inventory', 'moving', 'shipping', 'storage', 'warehouse'],
        label: 'Ramp Loading',
        aliases: {
            unicodes: {
                secondary: ['10f4d4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d4',
    },
    {
        name: 'ranking-star',
        categories: ['humanitarian', 'marketing', 'sports-fitness'],
        terms: [],
        label: 'Ranking Star',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e561',
    },
    {
        name: 'raygun',
        categories: ['science-fiction'],
        terms: ['flash gordon', 'laser', 'pew pew', 'phaser', 'space', 'stun'],
        label: 'Raygun',
        aliases: {
            unicodes: {
                secondary: ['10e025'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e025',
    },
    {
        name: 'receipt',
        categories: ['medical-health', 'money', 'shopping'],
        terms: ['check', 'invoice', 'money', 'pay', 'table'],
        label: 'Receipt',
        aliases: {
            unicodes: {
                composite: ['1f9fe'],
                secondary: ['10f543'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f543',
    },
    {
        name: 'record-vinyl',
        categories: ['music-audio'],
        terms: ['LP', 'album', 'analog', 'music', 'phonograph', 'sound'],
        label: 'Record Vinyl',
        aliases: {
            unicodes: {
                secondary: ['10f8d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8d9',
    },
    {
        name: 'rectangle',
        categories: ['shapes'],
        terms: [],
        label: 'Rectangle',
        aliases: {
            names: ['rectangle-landscape'],
            unicodes: {
                composite: ['25ac'],
                secondary: ['10f2fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2fa',
    },
    {
        name: 'rectangle-ad',
        categories: ['marketing'],
        terms: [],
        label: 'Rectangle ad',
        aliases: {
            names: ['ad'],
            unicodes: {
                secondary: ['10f641'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f641',
    },
    {
        name: 'rectangle-barcode',
        categories: ['logistics', 'shopping'],
        terms: [],
        label: 'Rectangle barcode',
        aliases: {
            names: ['barcode-alt'],
            unicodes: {
                secondary: ['10f463'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f463',
    },
    {
        name: 'rectangle-code',
        categories: ['coding'],
        terms: [],
        label: 'Rectangle Code',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e322',
    },
    {
        name: 'rectangle-history',
        categories: ['photos-images'],
        terms: [],
        label: 'Rectangle History',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a2',
    },
    {
        name: 'rectangle-history-circle-plus',
        categories: ['photos-images'],
        terms: [],
        label: 'Rectangle History-circle-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a3',
    },
    {
        name: 'rectangle-history-circle-user',
        categories: ['photos-images'],
        terms: [],
        label: 'Rectangle History-circle-user',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a4',
    },
    {
        name: 'rectangle-list',
        categories: ['text-formatting'],
        terms: [],
        label: 'Rectangle list',
        aliases: {
            names: ['list-alt'],
            unicodes: {
                secondary: ['10f022'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f022',
    },
    {
        name: 'rectangle-pro',
        categories: ['business', 'design', 'marketing'],
        terms: [],
        label: 'Rectangle Pro',
        aliases: {
            names: ['pro'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e235',
    },
    {
        name: 'rectangle-terminal',
        categories: ['coding'],
        terms: [],
        label: 'Rectangle Terminal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e236',
    },
    {
        name: 'rectangle-vertical',
        categories: ['shapes'],
        terms: [],
        label: 'Rectangle vertical',
        aliases: {
            names: ['rectangle-portrait'],
            unicodes: {
                secondary: ['10f2fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2fb',
    },
    {
        name: 'rectangle-vertical-history',
        categories: ['photos-images'],
        terms: [],
        label: 'Rectangle Vertical-history',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e237',
    },
    {
        name: 'rectangle-wide',
        categories: ['shapes'],
        terms: ['geometry', 'landscape', 'shape'],
        label: 'Wide Rectangle',
        aliases: {
            unicodes: {
                secondary: ['10f2fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2fc',
    },
    {
        name: 'rectangle-xmark',
        categories: ['coding'],
        terms: [],
        label: 'Rectangle X Mark',
        aliases: {
            names: ['rectangle-times', 'times-rectangle', 'window-close'],
            unicodes: {
                composite: ['f2d4'],
                secondary: ['10f410'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f410',
    },
    {
        name: 'rectangles-mixed',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Rectangles Mixed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e323',
    },
    {
        name: 'recycle',
        categories: ['arrows', 'maps'],
        terms: ['Waste', 'compost', 'garbage', 'reuse', 'trash'],
        label: 'Recycle',
        aliases: {
            unicodes: {
                composite: ['267a', '267b', '2672'],
                secondary: ['10f1b8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1b8',
    },
    {
        name: 'reel',
        categories: ['clothing-fashion', 'construction', 'logistics'],
        terms: [],
        label: 'Reel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e238',
    },
    {
        name: 'refrigerator',
        categories: ['household', 'travel-hotel'],
        terms: ['cold', 'cool', 'freezer', 'fridge', 'icebox', 'kitchen'],
        label: 'Refrigerator',
        aliases: {
            unicodes: {
                secondary: ['10e026'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e026',
    },
    {
        name: 'registered',
        categories: ['business'],
        terms: ['copyright', 'mark', 'trademark'],
        label: 'Registered Trademark',
        aliases: {
            unicodes: {
                composite: ['ae'],
                secondary: ['10f25d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f25d',
    },
    {
        name: 'repeat',
        categories: ['arrows', 'media-playback'],
        terms: ['flip', 'reload', 'rewind', 'switch'],
        label: 'Repeat',
        aliases: {
            unicodes: {
                composite: ['1f501'],
                secondary: ['10f363'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f363',
    },
    {
        name: 'repeat-1',
        categories: ['arrows', 'media-playback'],
        terms: ['flip', 'reload', 'rewind', 'switch'],
        label: 'Repeat 1',
        aliases: {
            unicodes: {
                composite: ['1f502'],
                secondary: ['10f365'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f365',
    },
    {
        name: 'reply',
        categories: ['arrows'],
        terms: ['mail', 'message', 'respond'],
        label: 'Reply',
        aliases: {
            names: ['mail-reply'],
            unicodes: {
                composite: ['f112'],
                secondary: ['10f3e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3e5',
    },
    {
        name: 'reply-all',
        categories: ['arrows'],
        terms: ['mail', 'message', 'respond'],
        label: 'reply-all',
        aliases: {
            names: ['mail-reply-all'],
            unicodes: {
                secondary: ['10f122'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f122',
    },
    {
        name: 'reply-clock',
        categories: ['time'],
        terms: [],
        label: 'Reply clock',
        aliases: {
            names: ['reply-time'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e239',
    },
    {
        name: 'republican',
        categories: ['political'],
        terms: [
            'american',
            'conservative',
            'election',
            'elephant',
            'politics',
            'republican party',
            'right',
            'right-wing',
            'usa',
        ],
        label: 'Republican',
        aliases: {
            unicodes: {
                secondary: ['10f75e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f75e',
    },
    {
        name: 'restroom',
        categories: ['maps', 'users-people'],
        terms: ['bathroom', 'john', 'loo', 'potty', 'washroom', 'waste', 'wc'],
        label: 'Restroom',
        aliases: {
            unicodes: {
                secondary: ['10f7bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7bd',
    },
    {
        name: 'restroom-simple',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'Restroom Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23a',
    },
    {
        name: 'retweet',
        categories: ['arrows', 'social'],
        terms: ['refresh', 'reload', 'share', 'swap'],
        label: 'Retweet',
        aliases: {
            unicodes: {
                secondary: ['10f079'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f079',
    },
    {
        name: 'rhombus',
        categories: ['shapes'],
        terms: [],
        label: 'Rhombus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23b',
    },
    {
        name: 'ribbon',
        categories: ['charity'],
        terms: ['badge', 'cause', 'lapel', 'pin'],
        label: 'Ribbon',
        aliases: {
            unicodes: {
                composite: ['1f397'],
                secondary: ['10f4d6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d6',
    },
    {
        name: 'right',
        categories: ['arrows'],
        terms: [],
        label: 'Right',
        aliases: {
            names: ['arrow-alt-right'],
            unicodes: {
                composite: ['2b95'],
                secondary: ['10f356'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f356',
    },
    {
        name: 'right-from-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Right from bracket',
        aliases: {
            names: ['sign-out-alt'],
            unicodes: {
                secondary: ['10f2f5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f5',
    },
    {
        name: 'right-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Right from line',
        aliases: {
            names: ['arrow-alt-from-left'],
            unicodes: {
                secondary: ['10f347'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f347',
    },
    {
        name: 'right-left',
        categories: ['arrows'],
        terms: [],
        label: 'Right left',
        aliases: {
            names: ['exchange-alt'],
            unicodes: {
                secondary: ['10f362'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f362',
    },
    {
        name: 'right-long',
        categories: ['arrows'],
        terms: [],
        label: 'Right long',
        aliases: {
            names: ['long-arrow-alt-right'],
            unicodes: {
                secondary: ['10f30b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30b',
    },
    {
        name: 'right-long-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Right Long-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e444',
    },
    {
        name: 'right-to-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Right to bracket',
        aliases: {
            names: ['sign-in-alt'],
            unicodes: {
                secondary: ['10f2f6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f6',
    },
    {
        name: 'right-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Right to line',
        aliases: {
            names: ['arrow-alt-to-right'],
            unicodes: {
                secondary: ['10f34c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f34c',
    },
    {
        name: 'ring',
        categories: ['gaming', 'spinners'],
        terms: [
            'Dungeons & Dragons',
            'Gollum',
            'band',
            'binding',
            'd&d',
            'dnd',
            'engagement',
            'fantasy',
            'gold',
            'jewelry',
            'marriage',
            'precious',
        ],
        label: 'Ring',
        aliases: {
            unicodes: {
                secondary: ['10f70b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70b',
    },
    {
        name: 'rings-wedding',
        categories: ['travel-hotel'],
        terms: ['diamond', 'engagement', 'gold', 'marriage', 'matrimony', 'platinum', 'proposal'],
        label: 'Rings Wedding',
        aliases: {
            unicodes: {
                secondary: ['10f81b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81b',
    },
    {
        name: 'road',
        categories: ['humanitarian', 'logistics', 'maps', 'transportation'],
        terms: ['highway', 'map', 'pavement', 'route', 'street', 'travel'],
        label: 'road',
        aliases: {
            unicodes: {
                composite: ['1f6e3'],
                secondary: ['10f018'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f018',
    },
    {
        name: 'road-barrier',
        categories: ['humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Road Barrier',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e562',
    },
    {
        name: 'road-bridge',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Road Bridge',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e563',
    },
    {
        name: 'road-circle-check',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Road Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e564',
    },
    {
        name: 'road-circle-exclamation',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Road Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e565',
    },
    {
        name: 'road-circle-xmark',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Road Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e566',
    },
    {
        name: 'road-lock',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Road Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e567',
    },
    {
        name: 'road-spikes',
        categories: ['humanitarian', 'security', 'transportation'],
        terms: [],
        label: 'Road Spikes',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e568',
    },
    {
        name: 'robot',
        categories: ['childhood', 'science-fiction'],
        terms: ['android', 'automate', 'computer', 'cyborg'],
        label: 'Robot',
        aliases: {
            unicodes: {
                composite: ['1f916'],
                secondary: ['10f544'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f544',
    },
    {
        name: 'robot-astromech',
        categories: ['science-fiction', 'transportation'],
        terms: [],
        label: 'Robot Astromech',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2d2',
    },
    {
        name: 'rocket',
        categories: ['maps', 'science-fiction', 'transportation'],
        terms: ['aircraft', 'app', 'jet', 'launch', 'nasa', 'space'],
        label: 'rocket',
        aliases: {
            unicodes: {
                secondary: ['10f135'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f135',
    },
    {
        name: 'rocket-launch',
        categories: ['science-fiction', 'transportation'],
        terms: ['aircraft', 'app', 'ignition', 'jet', 'launch', 'nasa', 'space', 'thrusters'],
        label: 'Rocket Launch',
        aliases: {
            unicodes: {
                composite: ['1f680'],
                secondary: ['10e027'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e027',
    },
    {
        name: 'roller-coaster',
        categories: ['buildings', 'childhood'],
        terms: [],
        label: 'Roller Coaster',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e324',
    },
    {
        name: 'rotate',
        categories: ['arrows', 'editing', 'media-playback', 'spinners'],
        terms: [],
        label: 'Rotate',
        aliases: {
            names: ['sync-alt'],
            unicodes: {
                composite: ['1f504'],
                secondary: ['10f2f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f1',
    },
    {
        name: 'rotate-exclamation',
        categories: ['alert'],
        terms: [],
        label: 'Rotate Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23c',
    },
    {
        name: 'rotate-left',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Rotate Left',
        aliases: {
            names: ['rotate-back', 'rotate-backward', 'undo-alt'],
            unicodes: {
                secondary: ['10f2ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ea',
    },
    {
        name: 'rotate-right',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Rotate Right',
        aliases: {
            names: ['redo-alt', 'rotate-forward'],
            unicodes: {
                secondary: ['10f2f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f9',
    },
    {
        name: 'route',
        categories: ['camping', 'maps', 'moving'],
        terms: ['directions', 'navigation', 'travel'],
        label: 'Route',
        aliases: {
            unicodes: {
                secondary: ['10f4d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d7',
    },
    {
        name: 'route-highway',
        categories: ['maps'],
        terms: ['directions', 'highway', 'interstate', 'navigation', 'sign', 'travel'],
        label: 'Route Highway',
        aliases: {
            unicodes: {
                secondary: ['10f61a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61a',
    },
    {
        name: 'route-interstate',
        categories: ['maps'],
        terms: ['directions', 'highway', 'interstate', 'navigation', 'sign', 'travel'],
        label: 'Route Interstate',
        aliases: {
            unicodes: {
                secondary: ['10f61b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61b',
    },
    {
        name: 'router',
        categories: ['business', 'devices-hardware'],
        terms: ['bandwidth', 'connection', 'dsl', 'ethernet', 'internet', 'modem', 'switch', 'wifi', 'wireless'],
        label: 'Router',
        aliases: {
            unicodes: {
                secondary: ['10f8da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8da',
    },
    {
        name: 'rss',
        categories: ['connectivity', 'media-playback'],
        terms: ['blog', 'feed', 'journal', 'news', 'writing'],
        label: 'rss',
        aliases: {
            names: ['feed'],
            unicodes: {
                secondary: ['10f09e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f09e',
    },
    {
        name: 'ruble-sign',
        categories: ['money'],
        terms: ['currency', 'money', 'rub'],
        label: 'Ruble Sign',
        aliases: {
            names: ['rouble', 'rub', 'ruble'],
            unicodes: {
                composite: ['20bd'],
                secondary: ['10f158'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f158',
    },
    {
        name: 'rug',
        categories: ['household', 'humanitarian'],
        terms: [],
        label: 'Rug',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e569',
    },
    {
        name: 'rugby-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Rugby Ball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c6',
    },
    {
        name: 'ruler',
        categories: ['construction'],
        terms: ['design', 'draft', 'length', 'measure', 'planning'],
        label: 'Ruler',
        aliases: {
            unicodes: {
                composite: ['1f4cf'],
                secondary: ['10f545'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f545',
    },
    {
        name: 'ruler-combined',
        categories: ['construction', 'design'],
        terms: ['design', 'draft', 'length', 'measure', 'planning'],
        label: 'Ruler Combined',
        aliases: {
            unicodes: {
                secondary: ['10f546'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f546',
    },
    {
        name: 'ruler-horizontal',
        categories: ['construction', 'design'],
        terms: ['design', 'draft', 'length', 'measure', 'planning'],
        label: 'Ruler Horizontal',
        aliases: {
            unicodes: {
                secondary: ['10f547'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f547',
    },
    {
        name: 'ruler-triangle',
        categories: ['construction', 'education'],
        terms: ['design', 'draft', 'length', 'measure', 'planning'],
        label: 'Ruler Triangle',
        aliases: {
            unicodes: {
                composite: ['1f4d0'],
                secondary: ['10f61c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61c',
    },
    {
        name: 'ruler-vertical',
        categories: ['construction', 'design'],
        terms: ['design', 'draft', 'length', 'measure', 'planning'],
        label: 'Ruler Vertical',
        aliases: {
            unicodes: {
                secondary: ['10f548'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f548',
    },
    {
        name: 'rupee-sign',
        categories: ['money'],
        terms: ['currency', 'indian', 'inr', 'money'],
        label: 'Indian Rupee Sign',
        aliases: {
            names: ['rupee'],
            unicodes: {
                composite: ['20a8'],
                secondary: ['10f156'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f156',
    },
    {
        name: 'rupiah-sign',
        categories: ['money'],
        terms: [],
        label: 'Rupiah Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23d',
    },
    {
        name: 'rv',
        categories: ['automotive', 'camping', 'holidays', 'transportation', 'travel-hotel'],
        terms: ['camping', 'cousin eddie', 'recreational', 'travel', 'vehicle'],
        label: 'R.V.',
        aliases: {
            unicodes: {
                secondary: ['10f7be'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7be',
    },
    {
        name: 's',
        categories: ['alphabet'],
        terms: [],
        label: 'S',
        aliases: {
            unicodes: {
                composite: ['73'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '53',
    },
    {
        name: 'sack',
        categories: ['money'],
        terms: ['bag', 'burlap', 'money', 'santa'],
        label: 'Sack',
        aliases: {
            unicodes: {
                secondary: ['10f81c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81c',
    },
    {
        name: 'sack-dollar',
        categories: ['humanitarian', 'money'],
        terms: ['bag', 'burlap', 'cash', 'dollar', 'money', 'robber', 'santa', 'usd'],
        label: 'Sack of Money',
        aliases: {
            unicodes: {
                composite: ['1f4b0'],
                secondary: ['10f81d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81d',
    },
    {
        name: 'sack-xmark',
        categories: ['humanitarian', 'money'],
        terms: [],
        label: 'Sack Xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56a',
    },
    {
        name: 'sailboat',
        categories: ['humanitarian', 'logistics', 'maritime', 'transportation'],
        terms: [],
        label: 'Sailboat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e445',
    },
    {
        name: 'salad',
        categories: ['food-beverage', 'fruits-vegetables'],
        terms: ['bowl', 'health', 'lettuce', 'spinach', 'vegan', 'vegetable', 'vegetarian'],
        label: 'Salad',
        aliases: {
            names: ['bowl-salad'],
            unicodes: {
                composite: ['1f957'],
                secondary: ['10f81e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81e',
    },
    {
        name: 'salt-shaker',
        categories: ['food-beverage'],
        terms: [],
        label: 'Salt Shaker',
        aliases: {
            unicodes: {
                composite: ['1f9c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e446',
    },
    {
        name: 'sandwich',
        categories: ['food-beverage'],
        terms: ['bread', 'deli', 'grilled cheese', 'toast'],
        label: 'Sandwich',
        aliases: {
            unicodes: {
                composite: ['1f96a'],
                secondary: ['10f81f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f81f',
    },
    {
        name: 'satellite',
        categories: ['astronomy', 'devices-hardware'],
        terms: ['communications', 'hardware', 'orbit', 'space'],
        label: 'Satellite',
        aliases: {
            unicodes: {
                composite: ['1f6f0'],
                secondary: ['10f7bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7bf',
    },
    {
        name: 'satellite-dish',
        categories: ['astronomy', 'connectivity', 'devices-hardware', 'humanitarian'],
        terms: ['SETI', 'communications', 'hardware', 'receiver', 'saucer', 'signal', 'space'],
        label: 'Satellite Dish',
        aliases: {
            unicodes: {
                composite: ['1f4e1'],
                secondary: ['10f7c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c0',
    },
    {
        name: 'sausage',
        categories: ['food-beverage'],
        terms: [
            'bratwurst',
            'breakfast',
            'chorizo',
            'frankfurt',
            'frankfurter',
            'hot dog',
            'hotdog',
            'kielbasa',
            'kosher',
            'pepperoni',
            'polish',
            'vienna',
            'weiner',
        ],
        label: 'Sausage',
        aliases: {
            unicodes: {
                secondary: ['10f820'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f820',
    },
    {
        name: 'saxophone',
        categories: ['music-audio'],
        terms: ['brass', 'instrument', 'jazz', 'music', 'woodwind'],
        label: 'Saxophone',
        aliases: {
            unicodes: {
                composite: ['1f3b7'],
                secondary: ['10f8dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8dc',
    },
    {
        name: 'saxophone-fire',
        categories: ['music-audio'],
        terms: [],
        label: 'Saxophone fire',
        aliases: {
            names: ['sax-hot'],
            unicodes: {
                secondary: ['10f8db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8db',
    },
    {
        name: 'scale-balanced',
        categories: ['business', 'humanitarian', 'maps', 'money', 'political'],
        terms: [],
        label: 'Scale balanced',
        aliases: {
            names: ['balance-scale'],
            unicodes: {
                composite: ['2696'],
                secondary: ['10f24e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f24e',
    },
    {
        name: 'scale-unbalanced',
        categories: ['business', 'maps', 'money', 'political'],
        terms: [],
        label: 'Scale unbalanced',
        aliases: {
            names: ['balance-scale-left'],
            unicodes: {
                secondary: ['10f515'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f515',
    },
    {
        name: 'scale-unbalanced-flip',
        categories: ['business', 'maps', 'money', 'political'],
        terms: [],
        label: 'Scale unbalanced flip',
        aliases: {
            names: ['balance-scale-right'],
            unicodes: {
                secondary: ['10f516'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f516',
    },
    {
        name: 'scalpel',
        categories: ['medical-health'],
        terms: ['blade', 'cut', 'doctor', 'knife', 'surgeon', 'surgery'],
        label: 'Scalpel',
        aliases: {
            unicodes: {
                secondary: ['10f61d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61d',
    },
    {
        name: 'scalpel-line-dashed',
        categories: ['medical-health'],
        terms: [],
        label: 'Scalpel line dashed',
        aliases: {
            names: ['scalpel-path'],
            unicodes: {
                secondary: ['10f61e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61e',
    },
    {
        name: 'scanner',
        categories: ['business', 'design', 'devices-hardware'],
        terms: ['barcode', 'checkout', 'gun', 'inventory', 'price', 'upc', 'warehouse'],
        label: 'Scanner',
        aliases: {
            names: ['scanner-image'],
            unicodes: {
                secondary: ['10f8f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f3',
    },
    {
        name: 'scanner-gun',
        categories: ['logistics'],
        terms: [],
        label: 'Scanner gun',
        aliases: {
            unicodes: {
                secondary: ['10f488'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f488',
    },
    {
        name: 'scanner-keyboard',
        categories: ['logistics'],
        terms: ['barcode', 'checkout', 'gun', 'inventory', 'price', 'upc', 'warehouse'],
        label: 'Scanner Keyboard',
        aliases: {
            unicodes: {
                secondary: ['10f489'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f489',
    },
    {
        name: 'scanner-touchscreen',
        categories: ['logistics'],
        terms: ['barcode', 'checkout', 'gun', 'inventory', 'price', 'upc', 'warehouse'],
        label: 'Scanner Touchscreen',
        aliases: {
            unicodes: {
                secondary: ['10f48a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48a',
    },
    {
        name: 'scarecrow',
        categories: ['halloween'],
        terms: ['bird', 'crow', 'farm', 'field', 'halloween', 'strawman'],
        label: 'Scarecrow',
        aliases: {
            unicodes: {
                secondary: ['10f70d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70d',
    },
    {
        name: 'scarf',
        categories: ['clothing-fashion'],
        terms: ['clothing', 'knitted', 'neck', 'seasonal', 'warmth'],
        label: 'Scarf',
        aliases: {
            unicodes: {
                composite: ['1f9e3'],
                secondary: ['10f7c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c1',
    },
    {
        name: 'school',
        categories: ['buildings', 'childhood', 'education', 'humanitarian'],
        terms: ['building', 'education', 'learn', 'student', 'teacher'],
        label: 'School',
        aliases: {
            unicodes: {
                composite: ['1f3eb'],
                secondary: ['10f549'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f549',
    },
    {
        name: 'school-circle-check',
        categories: ['buildings', 'education', 'humanitarian'],
        terms: [],
        label: 'School Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56b',
    },
    {
        name: 'school-circle-exclamation',
        categories: ['buildings', 'education', 'humanitarian'],
        terms: [],
        label: 'School Circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56c',
    },
    {
        name: 'school-circle-xmark',
        categories: ['buildings', 'education', 'humanitarian'],
        terms: [],
        label: 'School Circle-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56d',
    },
    {
        name: 'school-flag',
        categories: ['buildings', 'education', 'humanitarian'],
        terms: [],
        label: 'School Flag',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56e',
    },
    {
        name: 'school-lock',
        categories: ['buildings', 'education', 'humanitarian'],
        terms: [],
        label: 'School Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e56f',
    },
    {
        name: 'scissors',
        categories: ['business', 'design', 'editing', 'files'],
        terms: [],
        label: 'Scissors',
        aliases: {
            names: ['cut'],
            unicodes: {
                composite: ['2702', '2704', '2700'],
                secondary: ['10f0c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c4',
    },
    {
        name: 'screen-users',
        categories: ['education', 'users-people'],
        terms: [],
        label: 'Screen users',
        aliases: {
            names: ['users-class'],
            unicodes: {
                secondary: ['10f63d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f63d',
    },
    {
        name: 'screencast',
        categories: ['film-video', 'music-audio'],
        terms: [],
        label: 'Screencast',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23e',
    },
    {
        name: 'screwdriver',
        categories: ['construction'],
        terms: ['admin', 'fix', 'mechanic', 'repair', 'settings', 'tool'],
        label: 'Screwdriver',
        aliases: {
            unicodes: {
                composite: ['1fa9b'],
                secondary: ['10f54a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54a',
    },
    {
        name: 'screwdriver-wrench',
        categories: ['construction'],
        terms: [],
        label: 'Screwdriver wrench',
        aliases: {
            names: ['tools'],
            unicodes: {
                secondary: ['10f7d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d9',
    },
    {
        name: 'scribble',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Scribble',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e23f',
    },
    {
        name: 'scroll',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'announcement', 'd&d', 'dnd', 'fantasy', 'paper', 'script'],
        label: 'Scroll',
        aliases: {
            unicodes: {
                composite: ['1f4dc'],
                secondary: ['10f70e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70e',
    },
    {
        name: 'scroll-old',
        categories: ['gaming'],
        terms: [
            'Dungeons & Dragons',
            'ancient',
            'announcement',
            'd&d',
            'dnd',
            'fantasy',
            'paper',
            'relic',
            'script',
            'worn',
        ],
        label: 'Scroll Old',
        aliases: {
            unicodes: {
                secondary: ['10f70f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f70f',
    },
    {
        name: 'scroll-torah',
        categories: ['religion'],
        terms: [],
        label: 'Scroll torah',
        aliases: {
            names: ['torah'],
            unicodes: {
                secondary: ['10f6a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a0',
    },
    {
        name: 'scrubber',
        categories: ['media-playback'],
        terms: ['circle', 'dot', 'toggle'],
        label: 'Scrubber',
        aliases: {
            unicodes: {
                secondary: ['10f2f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f8',
    },
    {
        name: 'scythe',
        categories: ['gaming', 'halloween'],
        terms: ['Dungeons & Dragons', 'blade', 'd&d', 'death', 'dnd', 'fantasy', 'grim reaper', 'weapon'],
        label: 'Scythe',
        aliases: {
            unicodes: {
                secondary: ['10f710'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f710',
    },
    {
        name: 'sd-card',
        categories: ['devices-hardware'],
        terms: ['image', 'memory', 'photo', 'save'],
        label: 'Sd Card',
        aliases: {
            unicodes: {
                secondary: ['10f7c2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c2',
    },
    {
        name: 'sd-cards',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Sd Cards',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e240',
    },
    {
        name: 'seal',
        categories: ['shapes'],
        terms: [],
        label: 'Seal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e241',
    },
    {
        name: 'seal-exclamation',
        categories: ['alert'],
        terms: [],
        label: 'Seal Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e242',
    },
    {
        name: 'seal-question',
        categories: ['alert'],
        terms: [],
        label: 'Seal Question',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e243',
    },
    {
        name: 'seat-airline',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Seat Airline',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e244',
    },
    {
        name: 'section',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Section',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e447',
    },
    {
        name: 'seedling',
        categories: ['charity', 'energy', 'food-beverage', 'fruits-vegetables', 'humanitarian', 'nature', 'science'],
        terms: ['flora', 'grow', 'plant', 'vegan'],
        label: 'Seedling',
        aliases: {
            names: ['sprout'],
            unicodes: {
                composite: ['1f331'],
                secondary: ['10f4d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d8',
    },
    {
        name: 'semicolon',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Semicolon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '3b',
    },
    {
        name: 'send-back',
        categories: ['design'],
        terms: ['arrange', 'backward', 'layer', 'order', 'stack'],
        label: 'Send Back',
        aliases: {
            unicodes: {
                secondary: ['10f87e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87e',
    },
    {
        name: 'send-backward',
        categories: ['design'],
        terms: ['arrange', 'back', 'layer', 'order', 'stack'],
        label: 'Send Backward',
        aliases: {
            unicodes: {
                secondary: ['10f87f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87f',
    },
    {
        name: 'sensor',
        categories: ['alert', 'household'],
        terms: ['alarm', 'alert', 'carbon monoxide', 'detector', 'fire', 'smoke'],
        label: 'Sensor',
        aliases: {
            unicodes: {
                secondary: ['10e028'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e028',
    },
    {
        name: 'sensor-cloud',
        categories: ['alert', 'household'],
        terms: [],
        label: 'Sensor cloud',
        aliases: {
            names: ['sensor-smoke'],
            unicodes: {
                secondary: ['10e02c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02c',
    },
    {
        name: 'sensor-fire',
        categories: ['alert', 'household'],
        terms: ['alarm', 'alert', 'carbon monoxide', 'detector', 'fire', 'smoke'],
        label: 'Sensor Fire',
        aliases: {
            unicodes: {
                secondary: ['10e02a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02a',
    },
    {
        name: 'sensor-on',
        categories: ['alert', 'household'],
        terms: ['alarm', 'alert', 'carbon monoxide', 'detector', 'fire', 'smoke'],
        label: 'Sensor On',
        aliases: {
            unicodes: {
                secondary: ['10e02b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02b',
    },
    {
        name: 'sensor-triangle-exclamation',
        categories: ['alert', 'household'],
        terms: [],
        label: 'Sensor triangle exclamation',
        aliases: {
            names: ['sensor-alert'],
            unicodes: {
                secondary: ['10e029'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e029',
    },
    {
        name: 'server',
        categories: ['devices-hardware'],
        terms: ['computer', 'cpu', 'database', 'hardware', 'network'],
        label: 'Server',
        aliases: {
            unicodes: {
                secondary: ['10f233'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f233',
    },
    {
        name: 'shapes',
        categories: ['childhood', 'education', 'shapes'],
        terms: ['blocks', 'build', 'circle', 'square', 'triangle'],
        label: 'Shapes',
        aliases: {
            names: ['triangle-circle-square'],
            unicodes: {
                secondary: ['10f61f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f61f',
    },
    {
        name: 'share',
        categories: ['arrows', 'social'],
        terms: ['forward', 'save', 'send', 'social'],
        label: 'Share',
        aliases: {
            names: ['arrow-turn-right', 'mail-forward'],
            unicodes: {
                secondary: ['10f064'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f064',
    },
    {
        name: 'share-all',
        categories: ['arrows'],
        terms: ['forward', 'save', 'send', 'social'],
        label: 'Share All',
        aliases: {
            unicodes: {
                secondary: ['10f367'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f367',
    },
    {
        name: 'share-from-square',
        categories: ['arrows', 'social'],
        terms: [],
        label: 'Share from square',
        aliases: {
            names: ['share-square'],
            unicodes: {
                composite: ['f045'],
                secondary: ['10f14d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f14d',
    },
    {
        name: 'share-nodes',
        categories: ['social'],
        terms: [],
        label: 'Share nodes',
        aliases: {
            names: ['share-alt'],
            unicodes: {
                secondary: ['10f1e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e0',
    },
    {
        name: 'sheep',
        categories: ['animals'],
        terms: ['agriculture', 'animal', 'farming', 'fauna', 'mammal', 'wool', 'yarn'],
        label: 'Sheep',
        aliases: {
            unicodes: {
                composite: ['1f411'],
                secondary: ['10f711'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f711',
    },
    {
        name: 'sheet-plastic',
        categories: ['construction', 'household', 'humanitarian'],
        terms: [],
        label: 'Sheet Plastic',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e571',
    },
    {
        name: 'shekel-sign',
        categories: ['money'],
        terms: ['currency', 'ils', 'money'],
        label: 'Shekel Sign',
        aliases: {
            names: ['ils', 'shekel', 'sheqel', 'sheqel-sign'],
            unicodes: {
                composite: ['20aa'],
                secondary: ['10f20b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f20b',
    },
    {
        name: 'shelves',
        categories: ['logistics'],
        terms: [],
        label: 'Shelves',
        aliases: {
            names: ['inventory'],
            unicodes: {
                secondary: ['10f480'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f480',
    },
    {
        name: 'shelves-empty',
        categories: ['logistics'],
        terms: [],
        label: 'Shelves Empty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e246',
    },
    {
        name: 'shield',
        categories: ['coding', 'security', 'shapes'],
        terms: [
            'achievement',
            'armor',
            'award',
            'block',
            'cleric',
            'defend',
            'defense',
            'holy',
            'paladin',
            'security',
            'winner',
        ],
        label: 'shield',
        aliases: {
            names: ['shield-blank'],
            unicodes: {
                composite: ['1f6e1'],
                secondary: ['10f132'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f132',
    },
    {
        name: 'shield-cat',
        categories: ['animals', 'humanitarian', 'security'],
        terms: [],
        label: 'Shield Cat',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e572',
    },
    {
        name: 'shield-check',
        categories: ['coding', 'security'],
        terms: ['achievement', 'award', 'block', 'defend', 'security', 'select', 'success', 'tick', 'todo', 'winner'],
        label: 'shield',
        aliases: {
            unicodes: {
                secondary: ['10f2f7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f7',
    },
    {
        name: 'shield-cross',
        categories: ['gaming'],
        terms: [
            'Dungeons & Dragons',
            'block',
            'catholicism',
            'christianity',
            'crusader',
            'd&d',
            'defend',
            'dnd',
            'fantasy',
            'security',
            'templar',
        ],
        label: 'Shield Cross',
        aliases: {
            unicodes: {
                composite: ['26e8'],
                secondary: ['10f712'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f712',
    },
    {
        name: 'shield-dog',
        categories: ['animals', 'humanitarian', 'security'],
        terms: [],
        label: 'Shield Dog',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e573',
    },
    {
        name: 'shield-exclamation',
        categories: ['alert', 'security'],
        terms: [],
        label: 'Shield Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e247',
    },
    {
        name: 'shield-halved',
        categories: ['coding', 'gaming', 'security'],
        terms: [],
        label: 'Shield Halved',
        aliases: {
            names: ['shield-alt'],
            unicodes: {
                secondary: ['10f3ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3ed',
    },
    {
        name: 'shield-heart',
        categories: ['humanitarian', 'security'],
        terms: [],
        label: 'Shield Heart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e574',
    },
    {
        name: 'shield-keyhole',
        categories: ['security'],
        terms: [],
        label: 'Shield Keyhole',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e248',
    },
    {
        name: 'shield-minus',
        categories: ['security'],
        terms: [],
        label: 'Shield Minus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e249',
    },
    {
        name: 'shield-plus',
        categories: ['security'],
        terms: [],
        label: 'Shield Plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24a',
    },
    {
        name: 'shield-quartered',
        categories: ['gaming'],
        terms: [],
        label: 'Shield Quartered',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e575',
    },
    {
        name: 'shield-slash',
        categories: ['security'],
        terms: [],
        label: 'Shield Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24b',
    },
    {
        name: 'shield-virus',
        categories: ['medical-health'],
        terms: ['antibodies', 'barrier', 'covid-19', 'health', 'protect'],
        label: 'Shield Virus',
        aliases: {
            unicodes: {
                secondary: ['10e06c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06c',
    },
    {
        name: 'shield-xmark',
        categories: ['security'],
        terms: [],
        label: 'Shield X Mark',
        aliases: {
            names: ['shield-times'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24c',
    },
    {
        name: 'ship',
        categories: ['humanitarian', 'maps', 'maritime', 'transportation'],
        terms: ['boat', 'sea', 'water'],
        label: 'Ship',
        aliases: {
            unicodes: {
                composite: ['1f6a2'],
                secondary: ['10f21a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f21a',
    },
    {
        name: 'shirt',
        categories: ['clothing-fashion', 'humanitarian', 'shopping'],
        terms: [],
        label: 'T-Shirt',
        aliases: {
            names: ['t-shirt', 'tshirt'],
            unicodes: {
                composite: ['1f455'],
                secondary: ['10f553'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f553',
    },
    {
        name: 'shirt-long-sleeve',
        categories: ['clothing-fashion'],
        terms: [],
        label: 'Long Sleeve Shirt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c7',
    },
    {
        name: 'shirt-running',
        categories: ['clothing-fashion', 'sports-fitness'],
        terms: [],
        label: 'Running Shirt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c8',
    },
    {
        name: 'shirt-tank-top',
        categories: ['clothing-fashion'],
        terms: [],
        label: 'Tank Top Shirt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3c9',
    },
    {
        name: 'shish-kebab',
        categories: ['camping', 'food-beverage'],
        terms: ['grill', 'kabob', 'kebob', 'meat', 'shawarma', 'skewer', 'souvlaki'],
        label: 'Shish Kebab',
        aliases: {
            unicodes: {
                secondary: ['10f821'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f821',
    },
    {
        name: 'shoe-prints',
        categories: ['clothing-fashion', 'maps', 'sports-fitness'],
        terms: ['feet', 'footprints', 'steps', 'walk'],
        label: 'Shoe Prints',
        aliases: {
            unicodes: {
                secondary: ['10f54b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54b',
    },
    {
        name: 'shop',
        categories: ['buildings', 'humanitarian', 'shopping'],
        terms: [],
        label: 'Shop',
        aliases: {
            names: ['store-alt'],
            unicodes: {
                secondary: ['10f54f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54f',
    },
    {
        name: 'shop-lock',
        categories: ['buildings', 'humanitarian', 'shopping'],
        terms: [],
        label: 'Shop Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a5',
    },
    {
        name: 'shop-slash',
        categories: ['shopping'],
        terms: [],
        label: 'Shop slash',
        aliases: {
            names: ['store-alt-slash'],
            unicodes: {
                secondary: ['10e070'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e070',
    },
    {
        name: 'shovel',
        categories: ['camping', 'construction'],
        terms: ['construction', 'dig', 'excavate', 'tool', 'trench'],
        label: 'Shovel',
        aliases: {
            unicodes: {
                secondary: ['10f713'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f713',
    },
    {
        name: 'shovel-snow',
        categories: ['construction'],
        terms: ['construction', 'dig', 'excavate', 'plow', 'tool', 'trench'],
        label: 'Shovel Snow',
        aliases: {
            unicodes: {
                secondary: ['10f7c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c3',
    },
    {
        name: 'shower',
        categories: ['household', 'humanitarian', 'maps', 'travel-hotel'],
        terms: ['bath', 'clean', 'faucet', 'water'],
        label: 'Shower',
        aliases: {
            unicodes: {
                composite: ['1f6bf'],
                secondary: ['10f2cc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2cc',
    },
    {
        name: 'shower-down',
        categories: ['household'],
        terms: [],
        label: 'Shower down',
        aliases: {
            names: ['shower-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24d',
    },
    {
        name: 'shredder',
        categories: ['business', 'devices-hardware'],
        terms: ['destroy', 'document', 'office', 'paper', 'print'],
        label: 'Shredder',
        aliases: {
            unicodes: {
                secondary: ['10f68a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68a',
    },
    {
        name: 'shrimp',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: [],
        label: 'Shrimp',
        aliases: {
            unicodes: {
                composite: ['1f990'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e448',
    },
    {
        name: 'shuffle',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Shuffle',
        aliases: {
            names: ['random'],
            unicodes: {
                composite: ['1f500'],
                secondary: ['10f074'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f074',
    },
    {
        name: 'shutters',
        categories: ['household'],
        terms: [],
        label: 'Shutters',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e449',
    },
    {
        name: 'shuttle-space',
        categories: ['astronomy', 'transportation'],
        terms: [],
        label: 'Shuttle space',
        aliases: {
            names: ['space-shuttle'],
            unicodes: {
                secondary: ['10f197'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f197',
    },
    {
        name: 'shuttlecock',
        categories: ['sports-fitness'],
        terms: ['badminton', 'birdie', 'feather', 'sport'],
        label: 'Shuttlecock',
        aliases: {
            unicodes: {
                secondary: ['10f45b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45b',
    },
    {
        name: 'sickle',
        categories: ['gaming'],
        terms: ['blade', 'd&d', 'dnd', 'dungeons & dragons', 'fantasy', 'weapon'],
        label: 'Sickle',
        aliases: {
            unicodes: {
                secondary: ['10f822'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f822',
    },
    {
        name: 'sidebar',
        categories: ['coding'],
        terms: [],
        label: 'Sidebar',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24e',
    },
    {
        name: 'sidebar-flip',
        categories: ['coding'],
        terms: [],
        label: 'Sidebar Flip',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e24f',
    },
    {
        name: 'sigma',
        categories: ['mathematics'],
        terms: ['alphabet', 'greek', 'math', 'summation'],
        label: 'Sigma (Summation)',
        aliases: {
            unicodes: {
                composite: ['2211'],
                secondary: ['10f68b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68b',
    },
    {
        name: 'sign-hanging',
        categories: ['moving'],
        terms: [],
        label: 'Sign hanging',
        aliases: {
            names: ['sign'],
            unicodes: {
                secondary: ['10f4d9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4d9',
    },
    {
        name: 'signal',
        categories: ['connectivity', 'toggle'],
        terms: ['bars', 'graph', 'online', 'reception', 'status'],
        label: 'signal',
        aliases: {
            names: ['signal-5', 'signal-perfect'],
            unicodes: {
                composite: ['1f4f6'],
                secondary: ['10f012'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f012',
    },
    {
        name: 'signal-bars',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal bars',
        aliases: {
            names: ['signal-alt', 'signal-alt-4', 'signal-bars-strong'],
            unicodes: {
                secondary: ['10f690'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f690',
    },
    {
        name: 'signal-bars-fair',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal bars fair',
        aliases: {
            names: ['signal-alt-2'],
            unicodes: {
                secondary: ['10f692'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f692',
    },
    {
        name: 'signal-bars-good',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal bars good',
        aliases: {
            names: ['signal-alt-3'],
            unicodes: {
                secondary: ['10f693'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f693',
    },
    {
        name: 'signal-bars-slash',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal bars slash',
        aliases: {
            names: ['signal-alt-slash'],
            unicodes: {
                secondary: ['10f694'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f694',
    },
    {
        name: 'signal-bars-weak',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal bars weak',
        aliases: {
            names: ['signal-alt-1'],
            unicodes: {
                secondary: ['10f691'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f691',
    },
    {
        name: 'signal-fair',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal fair',
        aliases: {
            names: ['signal-2'],
            unicodes: {
                secondary: ['10f68d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68d',
    },
    {
        name: 'signal-good',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal good',
        aliases: {
            names: ['signal-3'],
            unicodes: {
                secondary: ['10f68e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68e',
    },
    {
        name: 'signal-slash',
        categories: ['connectivity', 'toggle'],
        terms: ['bars', 'graph', 'online', 'reception', 'status'],
        label: 'Signal Slash',
        aliases: {
            unicodes: {
                secondary: ['10f695'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f695',
    },
    {
        name: 'signal-stream',
        categories: ['connectivity', 'film-video', 'household', 'marketing', 'social'],
        terms: ['podcast', 'radio', 'sisngal', 'streaming', 'wave', 'wifi', 'wireless'],
        label: 'Signal Stream',
        aliases: {
            unicodes: {
                secondary: ['10f8dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8dd',
    },
    {
        name: 'signal-stream-slash',
        categories: ['connectivity', 'film-video', 'marketing'],
        terms: [],
        label: 'Signal Stream Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e250',
    },
    {
        name: 'signal-strong',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal strong',
        aliases: {
            names: ['signal-4'],
            unicodes: {
                secondary: ['10f68f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68f',
    },
    {
        name: 'signal-weak',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Signal weak',
        aliases: {
            names: ['signal-1'],
            unicodes: {
                secondary: ['10f68c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f68c',
    },
    {
        name: 'signature',
        categories: ['business', 'editing', 'writing'],
        terms: ['John Hancock', 'cursive', 'name', 'writing'],
        label: 'Signature',
        aliases: {
            unicodes: {
                secondary: ['10f5b7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b7',
    },
    {
        name: 'signature-lock',
        categories: ['business', 'editing'],
        terms: [],
        label: 'Signature Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ca',
    },
    {
        name: 'signature-slash',
        categories: ['business', 'editing'],
        terms: [],
        label: 'Signature Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3cb',
    },
    {
        name: 'signs-post',
        categories: ['camping', 'maps', 'nature'],
        terms: [],
        label: 'Signs post',
        aliases: {
            names: ['map-signs'],
            unicodes: {
                secondary: ['10f277'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f277',
    },
    {
        name: 'sim-card',
        categories: ['devices-hardware'],
        terms: ['hard drive', 'hardware', 'portable', 'storage', 'technology', 'tiny'],
        label: 'SIM Card',
        aliases: {
            unicodes: {
                secondary: ['10f7c4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7c4',
    },
    {
        name: 'sim-cards',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Sim Cards',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e251',
    },
    {
        name: 'sink',
        categories: ['household'],
        terms: ['bathroom', 'covid-19', 'faucet', 'kitchen', 'wash'],
        label: 'Sink',
        aliases: {
            unicodes: {
                secondary: ['10e06d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06d',
    },
    {
        name: 'siren',
        categories: ['household', 'security'],
        terms: ['alarm', 'alert', 'ambulance', 'loud', 'police', 'warning'],
        label: 'Siren',
        aliases: {
            unicodes: {
                secondary: ['10e02d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02d',
    },
    {
        name: 'siren-on',
        categories: ['household', 'security'],
        terms: ['alarm', 'alert', 'ambulance', 'loud', 'police', 'warning'],
        label: 'Siren On',
        aliases: {
            unicodes: {
                secondary: ['10e02e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02e',
    },
    {
        name: 'sitemap',
        categories: ['business', 'coding'],
        terms: ['directory', 'hierarchy', 'ia', 'information architecture', 'organization'],
        label: 'Sitemap',
        aliases: {
            unicodes: {
                secondary: ['10f0e8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e8',
    },
    {
        name: 'skeleton',
        categories: ['medical-health'],
        terms: ['bones', 'skeletal', 'vertebrate', 'x-ray'],
        label: 'Skeleton',
        aliases: {
            unicodes: {
                secondary: ['10f620'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f620',
    },
    {
        name: 'ski-boot',
        categories: ['clothing-fashion', 'sports-fitness'],
        terms: [],
        label: 'Ski Boot',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3cc',
    },
    {
        name: 'ski-boot-ski',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Ski Boot on Ski',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3cd',
    },
    {
        name: 'skull',
        categories: ['halloween', 'medical-health', 'users-people'],
        terms: ['bones', 'skeleton', 'x-ray', 'yorick'],
        label: 'Skull',
        aliases: {
            unicodes: {
                composite: ['1f480'],
                secondary: ['10f54c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54c',
    },
    {
        name: 'skull-cow',
        categories: ['animals', 'maps'],
        terms: ['barren', 'cowboy', 'dead', 'desert', 'horns', 'old west', 'skeleton', 'western'],
        label: 'Cow Skull',
        aliases: {
            unicodes: {
                secondary: ['10f8de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8de',
    },
    {
        name: 'skull-crossbones',
        categories: ['alert', 'gaming', 'halloween', 'humanitarian', 'medical-health', 'science', 'security'],
        terms: [
            'Dungeons & Dragons',
            'alert',
            'bones',
            'd&d',
            'danger',
            'dead',
            'deadly',
            'death',
            'dnd',
            'fantasy',
            'halloween',
            'holiday',
            'jolly-roger',
            'pirate',
            'poison',
            'skeleton',
            'warning',
        ],
        label: 'Skull & Crossbones',
        aliases: {
            unicodes: {
                composite: ['1f571', '2620'],
                secondary: ['10f714'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f714',
    },
    {
        name: 'slash',
        categories: ['spinners'],
        terms: ['cancel', 'close', 'mute', 'off', 'stop', 'x'],
        label: 'Slash',
        aliases: {
            unicodes: {
                secondary: ['10f715'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f715',
    },
    {
        name: 'slash-back',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Slash Back',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '5c',
    },
    {
        name: 'slash-forward',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Slash Forward',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '2f',
    },
    {
        name: 'sleigh',
        categories: ['holidays', 'transportation'],
        terms: ['christmas', 'claus', 'fly', 'holiday', 'santa', 'sled', 'snow', 'xmas'],
        label: 'Sleigh',
        aliases: {
            unicodes: {
                secondary: ['10f7cc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7cc',
    },
    {
        name: 'slider',
        categories: ['toggle'],
        terms: [],
        label: 'Slider',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e252',
    },
    {
        name: 'sliders',
        categories: ['editing', 'media-playback', 'music-audio', 'photos-images'],
        terms: [],
        label: 'Sliders',
        aliases: {
            names: ['sliders-h'],
            unicodes: {
                secondary: ['10f1de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1de',
    },
    {
        name: 'sliders-simple',
        categories: ['editing', 'toggle'],
        terms: [],
        label: 'Sliders Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e253',
    },
    {
        name: 'sliders-up',
        categories: ['editing', 'media-playback', 'music-audio', 'photos-images'],
        terms: [],
        label: 'Sliders up',
        aliases: {
            names: ['sliders-v'],
            unicodes: {
                secondary: ['10f3f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3f1',
    },
    {
        name: 'slot-machine',
        categories: ['business', 'gaming', 'travel-hotel'],
        terms: [],
        label: 'Slot Machine',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3ce',
    },
    {
        name: 'smog',
        categories: ['weather'],
        terms: ['dragon', 'fog', 'haze', 'pollution', 'smoke', 'weather'],
        label: 'Smog',
        aliases: {
            unicodes: {
                secondary: ['10f75f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f75f',
    },
    {
        name: 'smoke',
        categories: ['weather'],
        terms: ['cloud', 'fire', 'fog', 'haze'],
        label: 'Smoke',
        aliases: {
            unicodes: {
                secondary: ['10f760'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f760',
    },
    {
        name: 'smoking',
        categories: ['medical-health', 'travel-hotel'],
        terms: ['cancer', 'cigarette', 'nicotine', 'smoking status', 'tobacco'],
        label: 'Smoking',
        aliases: {
            unicodes: {
                composite: ['1f6ac'],
                secondary: ['10f48d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48d',
    },
    {
        name: 'snake',
        categories: ['animals'],
        terms: ['cobra', 'fauna', 'reptile', 'slither'],
        label: 'Snake',
        aliases: {
            unicodes: {
                composite: ['1f40d'],
                secondary: ['10f716'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f716',
    },
    {
        name: 'snooze',
        categories: ['time', 'travel-hotel'],
        terms: ['alarm', 'nap', 'rest', 'siesta', 'sleep'],
        label: 'Snooze',
        aliases: {
            names: ['zzz'],
            unicodes: {
                composite: ['1f4a4'],
                secondary: ['10f880'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f880',
    },
    {
        name: 'snow-blowing',
        categories: ['weather'],
        terms: ['blizzard', 'precipitation', 'storm', 'winter'],
        label: 'Snow Blowing',
        aliases: {
            unicodes: {
                secondary: ['10f761'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f761',
    },
    {
        name: 'snowflake',
        categories: ['disaster', 'household', 'humanitarian', 'spinners', 'travel-hotel', 'weather'],
        terms: ['precipitation', 'rain', 'winter'],
        label: 'Snowflake',
        aliases: {
            unicodes: {
                composite: ['2746', '2744'],
                secondary: ['10f2dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2dc',
    },
    {
        name: 'snowflakes',
        categories: ['weather'],
        terms: ['precipitation', 'rain', 'winter'],
        label: 'Snowflakes',
        aliases: {
            unicodes: {
                secondary: ['10f7cf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7cf',
    },
    {
        name: 'snowman',
        categories: ['childhood', 'holidays'],
        terms: ['decoration', 'frost', 'frosty', 'holiday'],
        label: 'Snowman',
        aliases: {
            unicodes: {
                composite: ['26c4', '2603'],
                secondary: ['10f7d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d0',
    },
    {
        name: 'snowman-head',
        categories: ['childhood', 'holidays'],
        terms: [],
        label: 'Snowman head',
        aliases: {
            names: ['frosty-head'],
            unicodes: {
                secondary: ['10f79b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f79b',
    },
    {
        name: 'snowplow',
        categories: ['maps', 'transportation'],
        terms: ['clean up', 'cold', 'road', 'storm', 'winter'],
        label: 'Snowplow',
        aliases: {
            unicodes: {
                secondary: ['10f7d2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d2',
    },
    {
        name: 'soap',
        categories: ['household', 'humanitarian'],
        terms: ['bubbles', 'clean', 'covid-19', 'hygiene', 'wash'],
        label: 'Soap',
        aliases: {
            unicodes: {
                composite: ['1f9fc'],
                secondary: ['10e06e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06e',
    },
    {
        name: 'socks',
        categories: ['business', 'clothing-fashion'],
        terms: ['business socks', 'business time', 'clothing', 'feet', 'flight of the conchords', 'wednesday'],
        label: 'Socks',
        aliases: {
            unicodes: {
                composite: ['1f9e6'],
                secondary: ['10f696'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f696',
    },
    {
        name: 'soft-serve',
        categories: ['childhood', 'food-beverage'],
        terms: [],
        label: 'Soft Serve',
        aliases: {
            names: ['creemee'],
            unicodes: {
                composite: ['1f366'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e400',
    },
    {
        name: 'solar-panel',
        categories: ['energy'],
        terms: ['clean', 'eco-friendly', 'energy', 'green', 'sun'],
        label: 'Solar Panel',
        aliases: {
            unicodes: {
                secondary: ['10f5ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ba',
    },
    {
        name: 'solar-system',
        categories: ['astronomy'],
        terms: ['galaxy', 'orbit', 'planets', 'space', 'sun', 'universe'],
        label: 'Solar System',
        aliases: {
            unicodes: {
                secondary: ['10e02f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e02f',
    },
    {
        name: 'sort',
        categories: ['arrows'],
        terms: ['filter', 'order'],
        label: 'Sort',
        aliases: {
            names: ['unsorted'],
            unicodes: {
                secondary: ['10f0dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0dc',
    },
    {
        name: 'sort-down',
        categories: ['arrows'],
        terms: ['arrow', 'descending', 'filter', 'order', 'sort-desc'],
        label: 'Sort Down (Descending)',
        aliases: {
            names: ['sort-desc'],
            unicodes: {
                secondary: ['10f0dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0dd',
    },
    {
        name: 'sort-up',
        categories: ['arrows'],
        terms: ['arrow', 'ascending', 'filter', 'order', 'sort-asc'],
        label: 'Sort Up (Ascending)',
        aliases: {
            names: ['sort-asc'],
            unicodes: {
                secondary: ['10f0de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0de',
    },
    {
        name: 'soundcloud',
        categories: ['music-audio'],
        terms: [],
        label: 'SoundCloud',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1be',
    },
    {
        name: 'spa',
        categories: ['sports-fitness', 'travel-hotel'],
        terms: ['flora', 'massage', 'mindfulness', 'plant', 'wellness'],
        label: 'Spa',
        aliases: {
            unicodes: {
                secondary: ['10f5bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5bb',
    },
    {
        name: 'space-station-moon',
        categories: ['science-fiction'],
        terms: [
            'death star',
            'empire',
            'galen erso',
            'kyber crystal',
            'laser',
            'not a moon',
            'secret weapon',
            'star wars',
        ],
        label: 'Moon Space Station',
        aliases: {
            unicodes: {
                secondary: ['10e033'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e033',
    },
    {
        name: 'space-station-moon-construction',
        categories: ['science-fiction'],
        terms: [],
        label: 'Space station moon construction',
        aliases: {
            names: ['space-station-moon-alt'],
            unicodes: {
                secondary: ['10e034'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e034',
    },
    {
        name: 'spade',
        categories: ['gaming', 'shapes'],
        terms: ['card', 'game', 'poker', 'suit'],
        label: 'Spade',
        aliases: {
            unicodes: {
                composite: ['2660'],
                secondary: ['10f2f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f4',
    },
    {
        name: 'spaghetti-monster-flying',
        categories: ['religion'],
        terms: [],
        label: 'Spaghetti monster flying',
        aliases: {
            names: ['pastafarianism'],
            unicodes: {
                secondary: ['10f67b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f67b',
    },
    {
        name: 'sparkles',
        categories: ['gaming', 'halloween', 'marketing', 'social', 'weather'],
        terms: ['glitter', 'magic', 'new', 'special', 'twinkle'],
        label: 'Sparkles',
        aliases: {
            unicodes: {
                composite: ['2728'],
                secondary: ['10f890'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f890',
    },
    {
        name: 'speaker',
        categories: ['devices-hardware', 'film-video', 'household', 'music-audio'],
        terms: ['audio', 'device', 'music', 'sound', 'subwoofer', 'transducer', 'tweeter'],
        label: 'Speaker',
        aliases: {
            unicodes: {
                secondary: ['10f8df'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8df',
    },
    {
        name: 'speakers',
        categories: ['devices-hardware', 'film-video', 'household', 'music-audio'],
        terms: ['audio', 'device', 'group', 'music', 'sound', 'subwoofer', 'transducer', 'tweeter'],
        label: 'Speakers',
        aliases: {
            unicodes: {
                secondary: ['10f8e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e0',
    },
    {
        name: 'spell-check',
        categories: ['text-formatting'],
        terms: ['dictionary', 'edit', 'editor', 'grammar', 'text'],
        label: 'Spell Check',
        aliases: {
            unicodes: {
                secondary: ['10f891'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f891',
    },
    {
        name: 'spider',
        categories: ['animals', 'halloween', 'nature'],
        terms: ['arachnid', 'bug', 'charlotte', 'crawl', 'eight', 'halloween'],
        label: 'Spider',
        aliases: {
            unicodes: {
                composite: ['1f577'],
                secondary: ['10f717'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f717',
    },
    {
        name: 'spider-black-widow',
        categories: ['animals', 'halloween', 'nature'],
        terms: [
            'alert',
            'arachnid',
            'bug',
            'charlotte',
            'crawl',
            'danger',
            'dangerous',
            'deadly',
            'eight',
            'error',
            'halloween',
        ],
        label: 'Black Widow Spider',
        aliases: {
            unicodes: {
                secondary: ['10f718'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f718',
    },
    {
        name: 'spider-web',
        categories: ['halloween', 'nature', 'spinners'],
        terms: ['cobweb', 'gossamer', 'halloween'],
        label: 'Spider Web',
        aliases: {
            unicodes: {
                composite: ['1f578'],
                secondary: ['10f719'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f719',
    },
    {
        name: 'spinner',
        categories: ['spinners'],
        terms: ['circle', 'loading', 'progress'],
        label: 'Spinner',
        aliases: {
            unicodes: {
                secondary: ['10f110'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f110',
    },
    {
        name: 'spinner-third',
        categories: ['spinners'],
        terms: ['circle', 'loading', 'progress'],
        label: 'Spinner Third',
        aliases: {
            unicodes: {
                secondary: ['10f3f4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3f4',
    },
    {
        name: 'split',
        categories: ['arrows', 'coding'],
        terms: [],
        label: 'Split',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e254',
    },
    {
        name: 'splotch',
        categories: ['design'],
        terms: ['Ink', 'blob', 'blotch', 'glob', 'stain'],
        label: 'Splotch',
        aliases: {
            unicodes: {
                secondary: ['10f5bc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5bc',
    },
    {
        name: 'spoon',
        categories: ['household', 'maps'],
        terms: [],
        label: 'Spoon',
        aliases: {
            names: ['utensil-spoon'],
            unicodes: {
                composite: ['f1b1', '1f944'],
                secondary: ['10f2e5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e5',
    },
    {
        name: 'sportsball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Sportsball',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e44b',
    },
    {
        name: 'spotify',
        categories: ['music-audio'],
        terms: [],
        label: 'Spotify',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1bc',
    },
    {
        name: 'spray-can',
        categories: ['design'],
        terms: ['Paint', 'aerosol', 'design', 'graffiti', 'tag'],
        label: 'Spray Can',
        aliases: {
            unicodes: {
                secondary: ['10f5bd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5bd',
    },
    {
        name: 'spray-can-sparkles',
        categories: ['automotive'],
        terms: [],
        label: 'Spray Can Sparkles',
        aliases: {
            names: ['air-freshener'],
            unicodes: {
                secondary: ['10f5d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5d0',
    },
    {
        name: 'sprinkler',
        categories: ['household'],
        terms: ['grass', 'lawn', 'shower', 'spray', 'water'],
        label: 'Sprinkler',
        aliases: {
            unicodes: {
                secondary: ['10e035'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e035',
    },
    {
        name: 'sprinkler-ceiling',
        categories: ['household', 'security'],
        terms: [],
        label: 'Sprinkler Ceiling',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e44c',
    },
    {
        name: 'square',
        categories: ['shapes'],
        terms: ['block', 'box', 'shape'],
        label: 'Square',
        aliases: {
            unicodes: {
                composite: ['25fb', '25fc', 'f096', '25a0'],
                secondary: ['10f0c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c8',
    },
    {
        name: 'square-0',
        categories: ['numbers'],
        terms: [],
        label: 'Square 0',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e255',
    },
    {
        name: 'square-1',
        categories: ['numbers'],
        terms: [],
        label: 'Square 1',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e256',
    },
    {
        name: 'square-2',
        categories: ['numbers'],
        terms: [],
        label: 'Square 2',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e257',
    },
    {
        name: 'square-3',
        categories: ['numbers'],
        terms: [],
        label: 'Square 3',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e258',
    },
    {
        name: 'square-4',
        categories: ['numbers'],
        terms: [],
        label: 'Square 4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e259',
    },
    {
        name: 'square-5',
        categories: ['numbers'],
        terms: [],
        label: 'Square 5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25a',
    },
    {
        name: 'square-6',
        categories: ['numbers'],
        terms: [],
        label: 'Square 6',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25b',
    },
    {
        name: 'square-7',
        categories: ['numbers'],
        terms: [],
        label: 'Square 7',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25c',
    },
    {
        name: 'square-8',
        categories: ['numbers'],
        terms: [],
        label: 'Square 8',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25d',
    },
    {
        name: 'square-9',
        categories: ['numbers'],
        terms: [],
        label: 'Square 9',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25e',
    },
    {
        name: 'square-a',
        categories: ['alphabet'],
        terms: [],
        label: 'Square A',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e25f',
    },
    {
        name: 'square-a-lock',
        categories: ['text-formatting'],
        terms: [],
        label: 'Square A-lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e44d',
    },
    {
        name: 'square-ampersand',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Square Ampersand',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e260',
    },
    {
        name: 'square-arrow-down',
        categories: ['arrows'],
        terms: [],
        label: 'Square arrow down',
        aliases: {
            names: ['arrow-square-down'],
            unicodes: {
                secondary: ['10f339'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f339',
    },
    {
        name: 'square-arrow-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square Arrow-down-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e261',
    },
    {
        name: 'square-arrow-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square Arrow-down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e262',
    },
    {
        name: 'square-arrow-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square arrow left',
        aliases: {
            names: ['arrow-square-left'],
            unicodes: {
                secondary: ['10f33a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f33a',
    },
    {
        name: 'square-arrow-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square arrow right',
        aliases: {
            names: ['arrow-square-right'],
            unicodes: {
                secondary: ['10f33b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f33b',
    },
    {
        name: 'square-arrow-up',
        categories: ['arrows'],
        terms: [],
        label: 'Square arrow up',
        aliases: {
            names: ['arrow-square-up'],
            unicodes: {
                secondary: ['10f33c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f33c',
    },
    {
        name: 'square-arrow-up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square Arrow-up-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e263',
    },
    {
        name: 'square-arrow-up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square arrow up right',
        aliases: {
            names: ['external-link-square'],
            unicodes: {
                secondary: ['10f14c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f14c',
    },
    {
        name: 'square-b',
        categories: ['alphabet'],
        terms: [],
        label: 'Square B',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e264',
    },
    {
        name: 'square-bolt',
        categories: ['shapes'],
        terms: [],
        label: 'Square Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e265',
    },
    {
        name: 'square-c',
        categories: ['alphabet'],
        terms: [],
        label: 'Square C',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e266',
    },
    {
        name: 'square-caret-down',
        categories: ['arrows'],
        terms: [],
        label: 'Square caret down',
        aliases: {
            names: ['caret-square-down'],
            unicodes: {
                secondary: ['10f150'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f150',
    },
    {
        name: 'square-caret-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square caret left',
        aliases: {
            names: ['caret-square-left'],
            unicodes: {
                secondary: ['10f191'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f191',
    },
    {
        name: 'square-caret-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square caret right',
        aliases: {
            names: ['caret-square-right'],
            unicodes: {
                secondary: ['10f152'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f152',
    },
    {
        name: 'square-caret-up',
        categories: ['arrows'],
        terms: [],
        label: 'Square caret up',
        aliases: {
            names: ['caret-square-up'],
            unicodes: {
                secondary: ['10f151'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f151',
    },
    {
        name: 'square-check',
        categories: ['editing', 'text-formatting'],
        terms: [],
        label: 'Square check',
        aliases: {
            names: ['check-square'],
            unicodes: {
                composite: ['2705', 'f046', '2611'],
                secondary: ['10f14a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f14a',
    },
    {
        name: 'square-chevron-down',
        categories: ['arrows'],
        terms: [],
        label: 'Square chevron down',
        aliases: {
            names: ['chevron-square-down'],
            unicodes: {
                secondary: ['10f329'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f329',
    },
    {
        name: 'square-chevron-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square chevron left',
        aliases: {
            names: ['chevron-square-left'],
            unicodes: {
                secondary: ['10f32a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f32a',
    },
    {
        name: 'square-chevron-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square chevron right',
        aliases: {
            names: ['chevron-square-right'],
            unicodes: {
                secondary: ['10f32b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f32b',
    },
    {
        name: 'square-chevron-up',
        categories: ['arrows'],
        terms: [],
        label: 'Square chevron up',
        aliases: {
            names: ['chevron-square-up'],
            unicodes: {
                secondary: ['10f32c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f32c',
    },
    {
        name: 'square-code',
        categories: ['coding'],
        terms: [],
        label: 'Square Code',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e267',
    },
    {
        name: 'square-d',
        categories: ['alphabet'],
        terms: [],
        label: 'Square D',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e268',
    },
    {
        name: 'square-dashed',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Square Dashed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e269',
    },
    {
        name: 'square-divide',
        categories: ['mathematics'],
        terms: [],
        label: 'Square Divide',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26a',
    },
    {
        name: 'square-dollar',
        categories: ['charity', 'maps', 'money'],
        terms: [],
        label: 'Square dollar',
        aliases: {
            names: ['dollar-square', 'usd-square'],
            unicodes: {
                secondary: ['10f2e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e9',
    },
    {
        name: 'square-down',
        categories: ['arrows'],
        terms: [],
        label: 'Square down',
        aliases: {
            names: ['arrow-alt-square-down'],
            unicodes: {
                composite: ['2b07'],
                secondary: ['10f350'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f350',
    },
    {
        name: 'square-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square Down-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26b',
    },
    {
        name: 'square-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square Down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26c',
    },
    {
        name: 'square-e',
        categories: ['alphabet'],
        terms: [],
        label: 'Square E',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26d',
    },
    {
        name: 'square-ellipsis',
        categories: ['editing'],
        terms: [],
        label: 'Square Ellipsis',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26e',
    },
    {
        name: 'square-ellipsis-vertical',
        categories: ['editing'],
        terms: [],
        label: 'Square Ellipsis Vertical',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e26f',
    },
    {
        name: 'square-envelope',
        categories: ['business', 'communication'],
        terms: [],
        label: 'Square envelope',
        aliases: {
            names: ['envelope-square'],
            unicodes: {
                secondary: ['10f199'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f199',
    },
    {
        name: 'square-exclamation',
        categories: ['alert'],
        terms: [],
        label: 'Square exclamation',
        aliases: {
            names: ['exclamation-square'],
            unicodes: {
                secondary: ['10f321'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f321',
    },
    {
        name: 'square-f',
        categories: ['alphabet'],
        terms: [],
        label: 'Square F',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e270',
    },
    {
        name: 'square-fragile',
        categories: ['moving'],
        terms: [],
        label: 'Square fragile',
        aliases: {
            names: ['box-fragile', 'square-wine-glass-crack'],
            unicodes: {
                secondary: ['10f49b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49b',
    },
    {
        name: 'square-full',
        categories: ['gaming'],
        terms: ['block', 'box', 'shape'],
        label: 'Square Full',
        aliases: {
            unicodes: {
                composite: ['1f7e5', '1f7e6', '1f7e7', '1f7e8', '1f7e9', '1f7ea', '1f7eb', '2b1c', '2b1b'],
                secondary: ['10f45c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45c',
    },
    {
        name: 'square-g',
        categories: ['alphabet'],
        terms: [],
        label: 'Square G',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e271',
    },
    {
        name: 'square-h',
        categories: ['alphabet', 'maps', 'medical-health'],
        terms: [],
        label: 'Square h',
        aliases: {
            names: ['h-square'],
            unicodes: {
                secondary: ['10f0fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0fd',
    },
    {
        name: 'square-heart',
        categories: ['charity'],
        terms: [],
        label: 'Square heart',
        aliases: {
            names: ['heart-square'],
            unicodes: {
                composite: ['1f49f'],
                secondary: ['10f4c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4c8',
    },
    {
        name: 'square-i',
        categories: ['alphabet'],
        terms: [],
        label: 'Square I',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e272',
    },
    {
        name: 'square-info',
        categories: ['accessibility', 'maps'],
        terms: [],
        label: 'Square info',
        aliases: {
            names: ['info-square'],
            unicodes: {
                composite: ['2139'],
                secondary: ['10f30f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30f',
    },
    {
        name: 'square-j',
        categories: ['alphabet'],
        terms: [],
        label: 'Square J',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e273',
    },
    {
        name: 'square-k',
        categories: ['alphabet'],
        terms: [],
        label: 'Square K',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e274',
    },
    {
        name: 'square-kanban',
        categories: ['business', 'charts-diagrams', 'logistics'],
        terms: [],
        label: 'Square Kanban',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e488',
    },
    {
        name: 'square-l',
        categories: ['alphabet'],
        terms: [],
        label: 'Square L',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e275',
    },
    {
        name: 'square-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square left',
        aliases: {
            names: ['arrow-alt-square-left'],
            unicodes: {
                composite: ['2b05'],
                secondary: ['10f351'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f351',
    },
    {
        name: 'square-list',
        categories: ['text-formatting'],
        terms: [],
        label: 'Square List',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e489',
    },
    {
        name: 'square-m',
        categories: ['alphabet'],
        terms: [],
        label: 'Square M',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e276',
    },
    {
        name: 'square-minus',
        categories: ['mathematics'],
        terms: [],
        label: 'Square minus',
        aliases: {
            names: ['minus-square'],
            unicodes: {
                composite: ['f147'],
                secondary: ['10f146'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f146',
    },
    {
        name: 'square-n',
        categories: ['alphabet'],
        terms: [],
        label: 'Square N',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e277',
    },
    {
        name: 'square-nfi',
        categories: ['humanitarian', 'logistics'],
        terms: [],
        label: 'Square Nfi',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e576',
    },
    {
        name: 'square-o',
        categories: ['alphabet'],
        terms: [],
        label: 'Square O',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e278',
    },
    {
        name: 'square-p',
        categories: ['alphabet'],
        terms: [],
        label: 'Square P',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e279',
    },
    {
        name: 'square-parking',
        categories: ['maps'],
        terms: [],
        label: 'Square parking',
        aliases: {
            names: ['parking'],
            unicodes: {
                composite: ['1f17f'],
                secondary: ['10f540'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f540',
    },
    {
        name: 'square-parking-slash',
        categories: ['maps'],
        terms: [],
        label: 'Square parking slash',
        aliases: {
            names: ['parking-slash'],
            unicodes: {
                secondary: ['10f617'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f617',
    },
    {
        name: 'square-pen',
        categories: ['business', 'editing', 'writing'],
        terms: [],
        label: 'Square pen',
        aliases: {
            names: ['pen-square', 'pencil-square'],
            unicodes: {
                secondary: ['10f14b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f14b',
    },
    {
        name: 'square-person-confined',
        categories: ['humanitarian', 'security', 'users-people'],
        terms: [],
        label: 'Square Person-confined',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e577',
    },
    {
        name: 'square-phone',
        categories: ['business', 'communication', 'maps'],
        terms: [],
        label: 'Square phone',
        aliases: {
            names: ['phone-square'],
            unicodes: {
                secondary: ['10f098'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f098',
    },
    {
        name: 'square-phone-flip',
        categories: ['business', 'communication', 'maps'],
        terms: [],
        label: 'Square phone flip',
        aliases: {
            names: ['phone-square-alt'],
            unicodes: {
                secondary: ['10f87b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87b',
    },
    {
        name: 'square-phone-hangup',
        categories: ['communication'],
        terms: [],
        label: 'Square phone hangup',
        aliases: {
            names: ['phone-square-down'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27a',
    },
    {
        name: 'square-plus',
        categories: ['maps', 'medical-health'],
        terms: [],
        label: 'Square plus',
        aliases: {
            names: ['plus-square'],
            unicodes: {
                composite: ['f196'],
                secondary: ['10f0fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0fe',
    },
    {
        name: 'square-poll-horizontal',
        categories: ['business', 'charts-diagrams', 'marketing', 'social'],
        terms: [],
        label: 'Square poll horizontal',
        aliases: {
            names: ['poll-h'],
            unicodes: {
                secondary: ['10f682'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f682',
    },
    {
        name: 'square-poll-vertical',
        categories: ['business', 'charts-diagrams', 'marketing', 'social'],
        terms: [],
        label: 'Square poll vertical',
        aliases: {
            names: ['poll'],
            unicodes: {
                secondary: ['10f681'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f681',
    },
    {
        name: 'square-q',
        categories: ['alphabet'],
        terms: [],
        label: 'Square Q',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27b',
    },
    {
        name: 'square-quarters',
        categories: ['shapes'],
        terms: [],
        label: 'Square Quarters',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e44e',
    },
    {
        name: 'square-question',
        categories: ['accessibility', 'punctuation-symbols'],
        terms: [],
        label: 'Square question',
        aliases: {
            names: ['question-square'],
            unicodes: {
                secondary: ['10f2fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2fd',
    },
    {
        name: 'square-quote',
        categories: ['communication', 'punctuation-symbols', 'writing'],
        terms: [],
        label: 'Square Quote',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e329',
    },
    {
        name: 'square-r',
        categories: ['alphabet'],
        terms: [],
        label: 'Square R',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27c',
    },
    {
        name: 'square-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square right',
        aliases: {
            names: ['arrow-alt-square-right'],
            unicodes: {
                composite: ['27a1'],
                secondary: ['10f352'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f352',
    },
    {
        name: 'square-ring',
        categories: ['household', 'shapes'],
        terms: [],
        label: 'Square Ring',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e44f',
    },
    {
        name: 'square-root',
        categories: ['mathematics'],
        terms: ['arithmetic', 'calculus', 'division', 'math'],
        label: 'Square Root',
        aliases: {
            unicodes: {
                composite: ['221a'],
                secondary: ['10f697'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f697',
    },
    {
        name: 'square-root-variable',
        categories: ['mathematics'],
        terms: [],
        label: 'Square root variable',
        aliases: {
            names: ['square-root-alt'],
            unicodes: {
                secondary: ['10f698'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f698',
    },
    {
        name: 'square-rss',
        categories: ['communication', 'film-video'],
        terms: [],
        label: 'Square rss',
        aliases: {
            names: ['rss-square'],
            unicodes: {
                secondary: ['10f143'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f143',
    },
    {
        name: 'square-s',
        categories: ['alphabet'],
        terms: [],
        label: 'Square S',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27d',
    },
    {
        name: 'square-share-nodes',
        categories: ['social'],
        terms: [],
        label: 'Square share nodes',
        aliases: {
            names: ['share-alt-square'],
            unicodes: {
                secondary: ['10f1e1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e1',
    },
    {
        name: 'square-sliders',
        categories: ['music-audio', 'photos-images'],
        terms: [],
        label: 'Square sliders',
        aliases: {
            names: ['sliders-h-square'],
            unicodes: {
                secondary: ['10f3f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3f0',
    },
    {
        name: 'square-sliders-vertical',
        categories: ['music-audio', 'photos-images'],
        terms: [],
        label: 'Square sliders vertical',
        aliases: {
            names: ['sliders-v-square'],
            unicodes: {
                secondary: ['10f3f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3f2',
    },
    {
        name: 'square-small',
        categories: ['shapes'],
        terms: [],
        label: 'Square Small',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27e',
    },
    {
        name: 'square-star',
        categories: ['shapes'],
        terms: [],
        label: 'Square Star',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e27f',
    },
    {
        name: 'square-t',
        categories: ['alphabet'],
        terms: [],
        label: 'Square T',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e280',
    },
    {
        name: 'square-terminal',
        categories: ['coding'],
        terms: [],
        label: 'Square Terminal',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e32a',
    },
    {
        name: 'square-this-way-up',
        categories: ['moving'],
        terms: [],
        label: 'Square this way up',
        aliases: {
            names: ['box-up'],
            unicodes: {
                secondary: ['10f49f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f49f',
    },
    {
        name: 'square-u',
        categories: ['alphabet'],
        terms: [],
        label: 'Square U',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e281',
    },
    {
        name: 'square-up',
        categories: ['arrows'],
        terms: [],
        label: 'Square up',
        aliases: {
            names: ['arrow-alt-square-up'],
            unicodes: {
                composite: ['2b06'],
                secondary: ['10f353'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f353',
    },
    {
        name: 'square-up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Square Up-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e282',
    },
    {
        name: 'square-up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Square up right',
        aliases: {
            names: ['external-link-square-alt'],
            unicodes: {
                composite: ['2197'],
                secondary: ['10f360'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f360',
    },
    {
        name: 'square-user',
        categories: ['users-people'],
        terms: [],
        label: 'Square User',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e283',
    },
    {
        name: 'square-v',
        categories: ['alphabet'],
        terms: [],
        label: 'Square V',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e284',
    },
    {
        name: 'square-virus',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: [],
        label: 'Square Virus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e578',
    },
    {
        name: 'square-w',
        categories: ['alphabet'],
        terms: [],
        label: 'Square W',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e285',
    },
    {
        name: 'square-x',
        categories: ['alphabet'],
        terms: [],
        label: 'Square X',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e286',
    },
    {
        name: 'square-xmark',
        categories: ['mathematics'],
        terms: [],
        label: 'Square X Mark',
        aliases: {
            names: ['times-square', 'xmark-square'],
            unicodes: {
                composite: ['274e'],
                secondary: ['10f2d3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2d3',
    },
    {
        name: 'square-y',
        categories: ['alphabet'],
        terms: [],
        label: 'Square Y',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e287',
    },
    {
        name: 'square-z',
        categories: ['alphabet'],
        terms: [],
        label: 'Square Z',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e288',
    },
    {
        name: 'squid',
        categories: ['animals', 'food-beverage', 'maritime'],
        terms: [],
        label: 'Squid',
        aliases: {
            unicodes: {
                composite: ['1f991'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e450',
    },
    {
        name: 'squirrel',
        categories: ['animals', 'camping'],
        terms: ['animal', 'chipmunk', 'mammal', 'marmot', 'rat', 'rodent', 'tree'],
        label: 'Squirrel',
        aliases: {
            unicodes: {
                secondary: ['10f71a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71a',
    },
    {
        name: 'staff',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'd&d', 'dnd', 'fantasy', 'mage', 'stick', 'weapon'],
        label: 'Staff',
        aliases: {
            unicodes: {
                secondary: ['10f71b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71b',
    },
    {
        name: 'staff-aesculapius',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Staff Aesculapius',
        aliases: {
            names: ['rod-asclepius', 'rod-snake', 'staff-snake'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e579',
    },
    {
        name: 'stairs',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Stairs',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e289',
    },
    {
        name: 'stamp',
        categories: ['design', 'money'],
        terms: ['art', 'certificate', 'imprint', 'rubber', 'seal'],
        label: 'Stamp',
        aliases: {
            unicodes: {
                secondary: ['10f5bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5bf',
    },
    {
        name: 'standard-definition',
        categories: ['film-video', 'photos-images'],
        terms: [],
        label: 'Standard Definition',
        aliases: {
            names: ['rectangle-sd'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28a',
    },
    {
        name: 'star',
        categories: ['shapes', 'shopping', 'social', 'toggle'],
        terms: ['achievement', 'award', 'favorite', 'important', 'night', 'rating', 'score'],
        label: 'Star',
        aliases: {
            unicodes: {
                composite: ['f006', '2b50'],
                secondary: ['10f005'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f005',
    },
    {
        name: 'star-and-crescent',
        categories: ['religion'],
        terms: ['islam', 'muslim', 'religion'],
        label: 'Star and Crescent',
        aliases: {
            unicodes: {
                composite: ['262a'],
                secondary: ['10f699'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f699',
    },
    {
        name: 'star-christmas',
        categories: ['holidays', 'spinners'],
        terms: ['bethlehem', 'christmas', 'holiday', 'north', 'xmas'],
        label: 'Christmas Star',
        aliases: {
            unicodes: {
                secondary: ['10f7d4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d4',
    },
    {
        name: 'star-exclamation',
        categories: ['alert', 'shopping'],
        terms: ['achievement', 'alert', 'award', 'favorite', 'night', 'rating', 'score'],
        label: 'Exclamation Star',
        aliases: {
            unicodes: {
                secondary: ['10f2f3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f3',
    },
    {
        name: 'star-half',
        categories: ['toggle'],
        terms: ['achievement', 'award', 'rating', 'score', 'star-half-empty', 'star-half-full'],
        label: 'star-half',
        aliases: {
            unicodes: {
                composite: ['f123'],
                secondary: ['10f089'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f089',
    },
    {
        name: 'star-half-stroke',
        categories: ['toggle'],
        terms: [],
        label: 'Star half stroke',
        aliases: {
            names: ['star-half-alt'],
            unicodes: {
                secondary: ['10f5c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c0',
    },
    {
        name: 'star-of-david',
        categories: ['religion'],
        terms: ['jewish', 'judaism', 'religion'],
        label: 'Star of David',
        aliases: {
            unicodes: {
                composite: ['2721'],
                secondary: ['10f69a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f69a',
    },
    {
        name: 'star-of-life',
        categories: ['medical-health'],
        terms: ['doctor', 'emt', 'first aid', 'health', 'medical'],
        label: 'Star of Life',
        aliases: {
            unicodes: {
                secondary: ['10f621'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f621',
    },
    {
        name: 'star-sharp',
        categories: ['film-video', 'shapes'],
        terms: [],
        label: 'Star Sharp',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28b',
    },
    {
        name: 'star-sharp-half',
        categories: ['film-video', 'shapes'],
        terms: [],
        label: 'Star Sharp-half',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28c',
    },
    {
        name: 'star-sharp-half-stroke',
        categories: ['film-video', 'shapes'],
        terms: [],
        label: 'Star sharp half stroke',
        aliases: {
            names: ['star-sharp-half-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28d',
    },
    {
        name: 'star-shooting',
        categories: ['astronomy'],
        terms: ['asteroid', 'comet', 'meteor', 'space', 'wish'],
        label: 'Star Shooting',
        aliases: {
            unicodes: {
                composite: ['1f320'],
                secondary: ['10e036'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e036',
    },
    {
        name: 'starfighter',
        categories: ['science-fiction', 'transportation'],
        terms: ['luke skywalker', 'rebel', 'spaceship', 'star wars', 'x-wing'],
        label: 'Starfighter',
        aliases: {
            unicodes: {
                secondary: ['10e037'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e037',
    },
    {
        name: 'starfighter-twin-ion-engine',
        categories: ['science-fiction', 'transportation'],
        terms: [],
        label: 'Starfighter twin ion engine',
        aliases: {
            names: ['starfighter-alt'],
            unicodes: {
                secondary: ['10e038'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e038',
    },
    {
        name: 'starfighter-twin-ion-engine-advanced',
        categories: ['science-fiction', 'transportation'],
        terms: [],
        label: 'Starfighter twin ion engine advanced',
        aliases: {
            names: ['starfighter-alt-advanced'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28e',
    },
    {
        name: 'stars',
        categories: ['astronomy', 'weather'],
        terms: ['favorite', 'galaxy', 'magic', 'night', 'space'],
        label: 'Stars',
        aliases: {
            unicodes: {
                secondary: ['10f762'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f762',
    },
    {
        name: 'starship',
        categories: ['science-fiction', 'transportation'],
        terms: [
            'engage',
            'enterprise',
            'james t kirk',
            'jean-luc picard',
            'make it so',
            'ncc-1701',
            'spacecraft',
            'spaceship',
            'star trek',
        ],
        label: 'Starship',
        aliases: {
            unicodes: {
                secondary: ['10e039'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e039',
    },
    {
        name: 'starship-freighter',
        categories: ['science-fiction', 'transportation'],
        terms: ['chewbacca', 'han solo', 'millenium falcon', 'rebel', 'spaceship', 'star wars', 'yt-1300'],
        label: 'Starship Freighter',
        aliases: {
            unicodes: {
                secondary: ['10e03a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03a',
    },
    {
        name: 'steak',
        categories: ['food-beverage'],
        terms: ['beef', 'grill', 'meat', 'sirloin'],
        label: 'Steak',
        aliases: {
            unicodes: {
                composite: ['1f969'],
                secondary: ['10f824'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f824',
    },
    {
        name: 'steam',
        categories: ['gaming'],
        terms: [],
        label: 'Steam',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1b6',
    },
    {
        name: 'steam-square',
        categories: ['gaming'],
        terms: [],
        label: 'Steam Square',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1b7',
    },
    {
        name: 'steam-symbol',
        categories: ['gaming'],
        terms: [],
        label: 'Steam Symbol',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f3f6',
    },
    {
        name: 'steering-wheel',
        categories: ['automotive', 'spinners'],
        terms: ['auto', 'car', 'direction', 'driving', 'navigation'],
        label: 'Wheel Steering',
        aliases: {
            unicodes: {
                secondary: ['10f622'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f622',
    },
    {
        name: 'sterling-sign',
        categories: ['money'],
        terms: [],
        label: 'Sterling sign',
        aliases: {
            names: ['gbp', 'pound-sign'],
            unicodes: {
                composite: ['a3'],
                secondary: ['10f154'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f154',
    },
    {
        name: 'stethoscope',
        categories: ['humanitarian', 'medical-health'],
        terms: [
            'covid-19',
            'diagnosis',
            'doctor',
            'general practitioner',
            'hospital',
            'infirmary',
            'medicine',
            'office',
            'outpatient',
        ],
        label: 'Stethoscope',
        aliases: {
            unicodes: {
                composite: ['1fa7a'],
                secondary: ['10f0f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f1',
    },
    {
        name: 'stocking',
        categories: ['clothing-fashion', 'holidays'],
        terms: ['christmas', 'clothing', 'decoration', 'gift', 'holiday', 'present', 'sock', 'tradition', 'xmas'],
        label: 'Stocking',
        aliases: {
            unicodes: {
                secondary: ['10f7d5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d5',
    },
    {
        name: 'stomach',
        categories: ['medical-health'],
        terms: ['abdomen', 'belly', 'food', 'gut', 'hungry', 'intestine', 'organ', 'tummy'],
        label: 'Stomach',
        aliases: {
            unicodes: {
                secondary: ['10f623'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f623',
    },
    {
        name: 'stop',
        categories: ['media-playback'],
        terms: ['block', 'box', 'square'],
        label: 'stop',
        aliases: {
            unicodes: {
                composite: ['23f9'],
                secondary: ['10f04d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f04d',
    },
    {
        name: 'stopwatch',
        categories: ['time'],
        terms: ['clock', 'reminder', 'time'],
        label: 'Stopwatch',
        aliases: {
            unicodes: {
                composite: ['23f1'],
                secondary: ['10f2f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2f2',
    },
    {
        name: 'stopwatch-20',
        categories: ['sports-fitness', 'time'],
        terms: [
            'ABCs',
            'countdown',
            'covid-19',
            'happy birthday',
            'i will survive',
            'reminder',
            'seconds',
            'time',
            'timer',
        ],
        label: 'Stopwatch 20',
        aliases: {
            unicodes: {
                secondary: ['10e06f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e06f',
    },
    {
        name: 'store',
        categories: ['buildings', 'shopping'],
        terms: ['building', 'buy', 'purchase', 'shopping'],
        label: 'Store',
        aliases: {
            unicodes: {
                secondary: ['10f54e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f54e',
    },
    {
        name: 'store-lock',
        categories: ['buildings', 'shopping'],
        terms: [],
        label: 'Store Lock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a6',
    },
    {
        name: 'store-slash',
        categories: ['shopping'],
        terms: ['building', 'buy', 'closed', 'covid-19', 'purchase', 'shopping'],
        label: 'Store Slash',
        aliases: {
            unicodes: {
                secondary: ['10e071'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e071',
    },
    {
        name: 'strawberry',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Strawberry',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e32b',
    },
    {
        name: 'street-view',
        categories: ['maps', 'users-people'],
        terms: ['directions', 'location', 'map', 'navigation'],
        label: 'Street View',
        aliases: {
            unicodes: {
                secondary: ['10f21d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f21d',
    },
    {
        name: 'stretcher',
        categories: ['medical-health'],
        terms: ['ambulance', 'bed', 'emergency', 'er', 'patient'],
        label: 'Stretcher',
        aliases: {
            unicodes: {
                secondary: ['10f825'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f825',
    },
    {
        name: 'strikethrough',
        categories: ['text-formatting'],
        terms: ['cancel', 'edit', 'font', 'format', 'text', 'type'],
        label: 'Strikethrough',
        aliases: {
            unicodes: {
                secondary: ['10f0cc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0cc',
    },
    {
        name: 'stripe',
        categories: ['shopping'],
        terms: [],
        label: 'Stripe',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f429',
    },
    {
        name: 'stripe-s',
        categories: ['shopping'],
        terms: [],
        label: 'Stripe S',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f42a',
    },
    {
        name: 'stroopwafel',
        categories: ['food-beverage', 'spinners'],
        terms: ['caramel', 'cookie', 'dessert', 'sweets', 'waffle'],
        label: 'Stroopwafel',
        aliases: {
            unicodes: {
                secondary: ['10f551'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f551',
    },
    {
        name: 'subscript',
        categories: ['mathematics', 'text-formatting'],
        terms: ['edit', 'font', 'format', 'text', 'type'],
        label: 'subscript',
        aliases: {
            unicodes: {
                secondary: ['10f12c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f12c',
    },
    {
        name: 'suitcase',
        categories: ['maps', 'moving', 'travel-hotel'],
        terms: ['baggage', 'luggage', 'move', 'suitcase', 'travel', 'trip'],
        label: 'Suitcase',
        aliases: {
            unicodes: {
                composite: ['1f9f3'],
                secondary: ['10f0f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f2',
    },
    {
        name: 'suitcase-medical',
        categories: ['humanitarian', 'maps', 'medical-health'],
        terms: [],
        label: 'Suitcase medical',
        aliases: {
            names: ['medkit'],
            unicodes: {
                secondary: ['10f0fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0fa',
    },
    {
        name: 'suitcase-rolling',
        categories: ['travel-hotel'],
        terms: ['baggage', 'luggage', 'move', 'suitcase', 'travel', 'trip'],
        label: 'Suitcase Rolling',
        aliases: {
            unicodes: {
                secondary: ['10f5c1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c1',
    },
    {
        name: 'sun',
        categories: ['energy', 'spinners', 'weather'],
        terms: ['brighten', 'contrast', 'day', 'lighter', 'sol', 'solar', 'star', 'weather'],
        label: 'Sun',
        aliases: {
            unicodes: {
                composite: ['2600'],
                secondary: ['10f185'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f185',
    },
    {
        name: 'sun-bright',
        categories: ['weather'],
        terms: [],
        label: 'Sun bright',
        aliases: {
            names: ['sun-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e28f',
    },
    {
        name: 'sun-cloud',
        categories: ['weather'],
        terms: ['cloud', 'day', 'lighter', 'sol', 'solar', 'star', 'weather'],
        label: 'Sun with Cloud',
        aliases: {
            unicodes: {
                composite: ['1f324'],
                secondary: ['10f763'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f763',
    },
    {
        name: 'sun-dust',
        categories: ['weather'],
        terms: ['day', 'dry', 'haze', 'heat'],
        label: 'Sun Dust',
        aliases: {
            unicodes: {
                secondary: ['10f764'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f764',
    },
    {
        name: 'sun-haze',
        categories: ['weather'],
        terms: ['fog', 'heat', 'hot', 'sweltering'],
        label: 'Sun Haze',
        aliases: {
            unicodes: {
                secondary: ['10f765'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f765',
    },
    {
        name: 'sun-plant-wilt',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: [],
        label: 'Sun Plant-wilt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57a',
    },
    {
        name: 'sunglasses',
        categories: ['clothing-fashion'],
        terms: ['bright', 'cool', 'mib', 'shades'],
        label: 'Sunglasses',
        aliases: {
            unicodes: {
                composite: ['1f576'],
                secondary: ['10f892'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f892',
    },
    {
        name: 'sunrise',
        categories: ['camping', 'weather'],
        terms: ['dawn', 'day', 'daybreak', 'daylight', 'daytime', 'morning', 'sun up'],
        label: 'Sunrise',
        aliases: {
            unicodes: {
                composite: ['1f305'],
                secondary: ['10f766'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f766',
    },
    {
        name: 'sunset',
        categories: ['camping', 'weather'],
        terms: ['dusk', 'night', 'nighttime', 'sun down'],
        label: 'Sunset',
        aliases: {
            unicodes: {
                composite: ['1f307'],
                secondary: ['10f767'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f767',
    },
    {
        name: 'superscript',
        categories: ['mathematics', 'text-formatting'],
        terms: ['edit', 'exponential', 'font', 'format', 'text', 'type'],
        label: 'superscript',
        aliases: {
            unicodes: {
                secondary: ['10f12b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f12b',
    },
    {
        name: 'sushi',
        categories: ['food-beverage'],
        terms: [],
        label: 'Sushi',
        aliases: {
            names: ['nigiri'],
            unicodes: {
                composite: ['1f363'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48a',
    },
    {
        name: 'sushi-roll',
        categories: ['food-beverage'],
        terms: [],
        label: 'Sushi Roll',
        aliases: {
            names: ['maki-roll', 'makizushi'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e48b',
    },
    {
        name: 'swatchbook',
        categories: ['design'],
        terms: ['Pantone', 'color', 'design', 'hue', 'palette'],
        label: 'Swatchbook',
        aliases: {
            unicodes: {
                secondary: ['10f5c3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c3',
    },
    {
        name: 'sword',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'blade', 'd&d', 'dnd', 'fantasy', 'fight', 'sharp', 'weapon'],
        label: 'Sword',
        aliases: {
            unicodes: {
                composite: ['1f5e1'],
                secondary: ['10f71c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71c',
    },
    {
        name: 'sword-laser',
        categories: ['science-fiction'],
        terms: ['jedi', 'lightsaber', 'luke skywalker', 'rebel', 'star wars'],
        label: 'Sword Laser',
        aliases: {
            unicodes: {
                secondary: ['10e03b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03b',
    },
    {
        name: 'sword-laser-alt',
        categories: ['science-fiction'],
        terms: ['darth vader', 'empire', 'lightsaber', 'sith', 'star wars'],
        label: 'Sword Laser Alternate',
        aliases: {
            unicodes: {
                secondary: ['10e03c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03c',
    },
    {
        name: 'swords',
        categories: ['gaming'],
        terms: ['Dungeons & Dragons', 'blade', 'd&d', 'dnd', 'fantasy', 'fight', 'sharp', 'weapon'],
        label: 'Swords',
        aliases: {
            unicodes: {
                composite: ['2694'],
                secondary: ['10f71d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71d',
    },
    {
        name: 'swords-laser',
        categories: ['science-fiction'],
        terms: [
            'clash',
            'darth vader',
            'duel',
            'fight',
            'jedi',
            'lightsaber',
            'luke skywalker',
            'rebel',
            'sith',
            'star wars',
        ],
        label: 'Swords Laser',
        aliases: {
            unicodes: {
                secondary: ['10e03d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03d',
    },
    {
        name: 'symbols',
        categories: ['communication', 'design', 'social', 'text-formatting'],
        terms: [],
        label: 'Symbols',
        aliases: {
            names: ['icons-alt'],
            unicodes: {
                composite: ['1f523'],
                secondary: ['10f86e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f86e',
    },
    {
        name: 'synagogue',
        categories: ['buildings', 'religion'],
        terms: ['building', 'jewish', 'judaism', 'religion', 'star of david', 'temple'],
        label: 'Synagogue',
        aliases: {
            unicodes: {
                composite: ['1f54d'],
                secondary: ['10f69b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f69b',
    },
    {
        name: 'syringe',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: ['covid-19', 'doctor', 'immunizations', 'medical', 'needle'],
        label: 'Syringe',
        aliases: {
            unicodes: {
                composite: ['1f489'],
                secondary: ['10f48e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48e',
    },
    {
        name: 't',
        categories: ['alphabet'],
        terms: [],
        label: 'T',
        aliases: {
            unicodes: {
                composite: ['74'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '54',
    },
    {
        name: 'table',
        categories: ['business', 'text-formatting'],
        terms: ['data', 'excel', 'spreadsheet'],
        label: 'table',
        aliases: {
            unicodes: {
                secondary: ['10f0ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ce',
    },
    {
        name: 'table-cells',
        categories: ['text-formatting'],
        terms: [],
        label: 'Table cells',
        aliases: {
            names: ['th'],
            unicodes: {
                secondary: ['10f00a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f00a',
    },
    {
        name: 'table-cells-large',
        categories: ['text-formatting'],
        terms: [],
        label: 'Table cells large',
        aliases: {
            names: ['th-large'],
            unicodes: {
                secondary: ['10f009'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f009',
    },
    {
        name: 'table-columns',
        categories: ['business', 'text-formatting'],
        terms: [],
        label: 'Table columns',
        aliases: {
            names: ['columns'],
            unicodes: {
                secondary: ['10f0db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0db',
    },
    {
        name: 'table-layout',
        categories: ['business', 'text-formatting'],
        terms: [],
        label: 'Table Layout',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e290',
    },
    {
        name: 'table-list',
        categories: ['text-formatting'],
        terms: [],
        label: 'Table list',
        aliases: {
            names: ['th-list'],
            unicodes: {
                secondary: ['10f00b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f00b',
    },
    {
        name: 'table-picnic',
        categories: ['camping'],
        terms: [],
        label: 'Table Picnic',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e32d',
    },
    {
        name: 'table-pivot',
        categories: ['business', 'text-formatting'],
        terms: [],
        label: 'Table Pivot',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e291',
    },
    {
        name: 'table-rows',
        categories: ['business', 'text-formatting'],
        terms: [],
        label: 'Table rows',
        aliases: {
            names: ['rows'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e292',
    },
    {
        name: 'table-tennis-paddle-ball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Table tennis paddle ball',
        aliases: {
            names: ['ping-pong-paddle-ball', 'table-tennis'],
            unicodes: {
                composite: ['1f3d3'],
                secondary: ['10f45d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45d',
    },
    {
        name: 'table-tree',
        categories: ['business'],
        terms: [],
        label: 'Table Tree',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e293',
    },
    {
        name: 'tablet',
        categories: ['devices-hardware'],
        terms: ['apple', 'device', 'ipad', 'kindle', 'screen'],
        label: 'Tablet',
        aliases: {
            names: ['tablet-android'],
            unicodes: {
                secondary: ['10f3fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3fb',
    },
    {
        name: 'tablet-button',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Tablet button',
        aliases: {
            unicodes: {
                secondary: ['10f10a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f10a',
    },
    {
        name: 'tablet-rugged',
        categories: ['logistics'],
        terms: ['device', 'durable', 'protection', 'screen', 'tough'],
        label: 'Rugged Tablet',
        aliases: {
            unicodes: {
                secondary: ['10f48f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48f',
    },
    {
        name: 'tablet-screen',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Tablet screen',
        aliases: {
            names: ['tablet-android-alt'],
            unicodes: {
                secondary: ['10f3fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3fc',
    },
    {
        name: 'tablet-screen-button',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Tablet screen button',
        aliases: {
            names: ['tablet-alt'],
            unicodes: {
                secondary: ['10f3fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3fa',
    },
    {
        name: 'tablets',
        categories: ['medical-health', 'science'],
        terms: ['drugs', 'medicine', 'pills', 'prescription'],
        label: 'Tablets',
        aliases: {
            unicodes: {
                secondary: ['10f490'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f490',
    },
    {
        name: 'tachograph-digital',
        categories: ['devices-hardware'],
        terms: [],
        label: 'Tachograph digital',
        aliases: {
            names: ['digital-tachograph'],
            unicodes: {
                secondary: ['10f566'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f566',
    },
    {
        name: 'taco',
        categories: ['food-beverage'],
        terms: ['corn', 'maize', 'mexican', 'salsa', 'tortilla'],
        label: 'Taco',
        aliases: {
            unicodes: {
                composite: ['1f32e'],
                secondary: ['10f826'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f826',
    },
    {
        name: 'tag',
        categories: ['business', 'maps', 'shopping'],
        terms: ['discount', 'label', 'price', 'shopping'],
        label: 'tag',
        aliases: {
            unicodes: {
                composite: ['1f3f7'],
                secondary: ['10f02b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02b',
    },
    {
        name: 'tags',
        categories: ['business', 'maps', 'shopping'],
        terms: ['discount', 'label', 'price', 'shopping'],
        label: 'tags',
        aliases: {
            unicodes: {
                secondary: ['10f02c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f02c',
    },
    {
        name: 'tally',
        categories: ['mathematics', 'money', 'political', 'science'],
        terms: ['count', 'hash', 'numbers'],
        label: 'Tally',
        aliases: {
            names: ['tally-5'],
            unicodes: {
                secondary: ['10f69c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f69c',
    },
    {
        name: 'tally-1',
        categories: ['mathematics'],
        terms: [],
        label: 'Tally 1',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e294',
    },
    {
        name: 'tally-2',
        categories: ['mathematics'],
        terms: [],
        label: 'Tally 2',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e295',
    },
    {
        name: 'tally-3',
        categories: ['mathematics'],
        terms: [],
        label: 'Tally 3',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e296',
    },
    {
        name: 'tally-4',
        categories: ['mathematics'],
        terms: [],
        label: 'Tally 4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e297',
    },
    {
        name: 'tamale',
        categories: ['food-beverage'],
        terms: [],
        label: 'Tamale',
        aliases: {
            unicodes: {
                composite: ['1fad4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e451',
    },
    {
        name: 'tank-water',
        categories: ['automotive', 'household'],
        terms: [],
        label: 'Tank Water',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e452',
    },
    {
        name: 'tape',
        categories: ['moving'],
        terms: ['design', 'package', 'sticky'],
        label: 'Tape',
        aliases: {
            unicodes: {
                secondary: ['10f4db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4db',
    },
    {
        name: 'tarp',
        categories: ['camping', 'construction', 'humanitarian'],
        terms: [],
        label: 'Tarp',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57b',
    },
    {
        name: 'tarp-droplet',
        categories: ['camping', 'construction', 'humanitarian'],
        terms: [],
        label: 'Tarp Droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57c',
    },
    {
        name: 'taxi',
        categories: ['automotive', 'maps', 'transportation', 'travel-hotel'],
        terms: [
            'cab',
            'cabbie',
            'car',
            'car service',
            'lyft',
            'machine',
            'transportation',
            'travel',
            'uber',
            'vehicle',
        ],
        label: 'Taxi',
        aliases: {
            names: ['cab'],
            unicodes: {
                composite: ['1f696'],
                secondary: ['10f1ba'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1ba',
    },
    {
        name: 'taxi-bus',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Taxi Bus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e298',
    },
    {
        name: 'teddy-bear',
        categories: ['animals', 'camping', 'childhood', 'gaming'],
        terms: [],
        label: 'Teddy Bear',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3cf',
    },
    {
        name: 'teeth',
        categories: ['medical-health'],
        terms: ['bite', 'dental', 'dentist', 'gums', 'mouth', 'smile', 'tooth'],
        label: 'Teeth',
        aliases: {
            unicodes: {
                secondary: ['10f62e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62e',
    },
    {
        name: 'teeth-open',
        categories: ['medical-health'],
        terms: ['dental', 'dentist', 'gums bite', 'mouth', 'smile', 'tooth'],
        label: 'Teeth Open',
        aliases: {
            unicodes: {
                secondary: ['10f62f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f62f',
    },
    {
        name: 'telescope',
        categories: ['astronomy'],
        terms: ['astronomy', 'lens', 'look', 'microscope', 'observatory', 'scope', 'search', 'space', 'view'],
        label: 'Telescope',
        aliases: {
            unicodes: {
                composite: ['1f52d'],
                secondary: ['10e03e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03e',
    },
    {
        name: 'temperature-arrow-down',
        categories: ['disaster', 'household', 'humanitarian', 'weather'],
        terms: [],
        label: 'Temperature arrow down',
        aliases: {
            names: ['temperature-down'],
            unicodes: {
                secondary: ['10e03f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e03f',
    },
    {
        name: 'temperature-arrow-up',
        categories: ['disaster', 'household', 'humanitarian', 'weather'],
        terms: [],
        label: 'Temperature arrow up',
        aliases: {
            names: ['temperature-up'],
            unicodes: {
                secondary: ['10e040'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e040',
    },
    {
        name: 'temperature-empty',
        categories: ['weather'],
        terms: [],
        label: 'Temperature empty',
        aliases: {
            names: ['temperature-0', 'thermometer-0', 'thermometer-empty'],
            unicodes: {
                secondary: ['10f2cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2cb',
    },
    {
        name: 'temperature-full',
        categories: ['weather'],
        terms: [],
        label: 'Temperature full',
        aliases: {
            names: ['temperature-4', 'thermometer-4', 'thermometer-full'],
            unicodes: {
                secondary: ['10f2c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2c7',
    },
    {
        name: 'temperature-half',
        categories: ['weather'],
        terms: [],
        label: 'Temperature half',
        aliases: {
            names: ['temperature-2', 'thermometer-2', 'thermometer-half'],
            unicodes: {
                composite: ['1f321'],
                secondary: ['10f2c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2c9',
    },
    {
        name: 'temperature-high',
        categories: ['science', 'weather'],
        terms: ['cook', 'covid-19', 'mercury', 'summer', 'thermometer', 'warm'],
        label: 'High Temperature',
        aliases: {
            unicodes: {
                secondary: ['10f769'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f769',
    },
    {
        name: 'temperature-list',
        categories: ['weather'],
        terms: [],
        label: 'Temperature List',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e299',
    },
    {
        name: 'temperature-low',
        categories: ['science', 'weather'],
        terms: ['cold', 'cool', 'covid-19', 'mercury', 'thermometer', 'winter'],
        label: 'Low Temperature',
        aliases: {
            unicodes: {
                secondary: ['10f76b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76b',
    },
    {
        name: 'temperature-quarter',
        categories: ['weather'],
        terms: [],
        label: 'Temperature quarter',
        aliases: {
            names: ['temperature-1', 'thermometer-1', 'thermometer-quarter'],
            unicodes: {
                secondary: ['10f2ca'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ca',
    },
    {
        name: 'temperature-snow',
        categories: ['weather'],
        terms: [],
        label: 'Temperature snow',
        aliases: {
            names: ['temperature-frigid'],
            unicodes: {
                secondary: ['10f768'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f768',
    },
    {
        name: 'temperature-sun',
        categories: ['weather'],
        terms: [],
        label: 'Temperature Sun',
        aliases: {
            names: ['temperature-hot'],
            unicodes: {
                secondary: ['10f76a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76a',
    },
    {
        name: 'temperature-three-quarters',
        categories: ['weather'],
        terms: [],
        label: 'Temperature three quarters',
        aliases: {
            names: ['temperature-3', 'thermometer-3', 'thermometer-three-quarters'],
            unicodes: {
                secondary: ['10f2c8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2c8',
    },
    {
        name: 'tenge-sign',
        categories: ['money'],
        terms: [],
        label: 'Tenge sign',
        aliases: {
            names: ['tenge'],
            unicodes: {
                composite: ['20b8'],
                secondary: ['10f7d7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d7',
    },
    {
        name: 'tennis-ball',
        categories: ['sports-fitness'],
        terms: ['racket', 'sport', 'tennis', 'us open'],
        label: 'Tennis Ball',
        aliases: {
            unicodes: {
                composite: ['1f3be'],
                secondary: ['10f45e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45e',
    },
    {
        name: 'tent',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tent',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57d',
    },
    {
        name: 'tent-arrow-down-to-line',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tent Arrow-down-to-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57e',
    },
    {
        name: 'tent-arrow-left-right',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tent Arrow-left-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e57f',
    },
    {
        name: 'tent-arrow-turn-left',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tent Arrow-turn-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e580',
    },
    {
        name: 'tent-arrows-down',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tent Arrows-down',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e581',
    },
    {
        name: 'tents',
        categories: ['buildings', 'camping', 'humanitarian'],
        terms: [],
        label: 'Tents',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e582',
    },
    {
        name: 'terminal',
        categories: ['coding'],
        terms: ['code', 'command', 'console', 'development', 'prompt'],
        label: 'Terminal',
        aliases: {
            unicodes: {
                secondary: ['10f120'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f120',
    },
    {
        name: 'text',
        categories: ['text-formatting'],
        terms: ['font', 'format', 'letter', 'style', 'typography'],
        label: 'Text',
        aliases: {
            unicodes: {
                secondary: ['10f893'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f893',
    },
    {
        name: 'text-height',
        categories: ['text-formatting'],
        terms: ['edit', 'font', 'format', 'text', 'type'],
        label: 'text-height',
        aliases: {
            unicodes: {
                secondary: ['10f034'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f034',
    },
    {
        name: 'text-size',
        categories: ['text-formatting'],
        terms: ['decrease', 'edit', 'font', 'format', 'increase', 'text', 'type'],
        label: 'Text Size',
        aliases: {
            unicodes: {
                secondary: ['10f894'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f894',
    },
    {
        name: 'text-slash',
        categories: ['text-formatting'],
        terms: [],
        label: 'Text slash',
        aliases: {
            names: ['remove-format'],
            unicodes: {
                secondary: ['10f87d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f87d',
    },
    {
        name: 'text-width',
        categories: ['text-formatting'],
        terms: ['edit', 'font', 'format', 'text', 'type'],
        label: 'Text Width',
        aliases: {
            unicodes: {
                secondary: ['10f035'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f035',
    },
    {
        name: 'thermometer',
        categories: ['medical-health'],
        terms: ['covid-19', 'mercury', 'status', 'temperature'],
        label: 'Thermometer',
        aliases: {
            unicodes: {
                secondary: ['10f491'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f491',
    },
    {
        name: 'theta',
        categories: ['mathematics'],
        terms: ['alphabet', 'greek', 'math'],
        label: 'Theta',
        aliases: {
            unicodes: {
                secondary: ['10f69e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f69e',
    },
    {
        name: 'thought-bubble',
        categories: ['childhood', 'communication', 'education'],
        terms: [],
        label: 'Thought Bubble',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e32e',
    },
    {
        name: 'thumbs-down',
        categories: ['hands', 'shopping', 'social'],
        terms: ['disagree', 'disapprove', 'dislike', 'hand', 'social', 'thumbs-o-down'],
        label: 'thumbs-down',
        aliases: {
            unicodes: {
                composite: ['f088', '1f44e'],
                secondary: ['10f165'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f165',
    },
    {
        name: 'thumbs-up',
        categories: ['hands', 'shopping', 'social'],
        terms: [
            'agree',
            'approve',
            'favorite',
            'hand',
            'like',
            'ok',
            'okay',
            'social',
            'success',
            'thumbs-o-up',
            'yes',
            'you got it dude',
        ],
        label: 'thumbs-up',
        aliases: {
            unicodes: {
                composite: ['f087', '1f44d'],
                secondary: ['10f164'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f164',
    },
    {
        name: 'thumbtack',
        categories: ['business', 'maps', 'social', 'writing'],
        terms: ['coordinates', 'location', 'marker', 'pin', 'thumb-tack'],
        label: 'Thumbtack',
        aliases: {
            names: ['thumb-tack'],
            unicodes: {
                composite: ['1f588', '1f4cc'],
                secondary: ['10f08d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f08d',
    },
    {
        name: 'tick',
        categories: ['punctuation-symbols'],
        terms: [],
        label: 'Tick',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e32f',
    },
    {
        name: 'ticket',
        categories: ['film-video', 'maps'],
        terms: ['movie', 'pass', 'support', 'ticket'],
        label: 'Ticket',
        aliases: {
            unicodes: {
                composite: ['1f39f'],
                secondary: ['10f145'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f145',
    },
    {
        name: 'ticket-airline',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Ticket Airline',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29a',
    },
    {
        name: 'ticket-simple',
        categories: ['maps', 'shapes'],
        terms: [],
        label: 'Ticket simple',
        aliases: {
            names: ['ticket-alt'],
            unicodes: {
                secondary: ['10f3ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3ff',
    },
    {
        name: 'tickets-airline',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Tickets Airline',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29b',
    },
    {
        name: 'tilde',
        categories: ['mathematics', 'punctuation-symbols'],
        terms: ['accent', 'symbol', 'text', 'type'],
        label: 'Tilde',
        aliases: {
            unicodes: {
                composite: ['f69f'],
                primary: ['f69f'],
                secondary: ['10f69f', '107e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '7e',
    },
    {
        name: 'timeline',
        categories: ['business', 'marketing'],
        terms: [],
        label: 'Timeline',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29c',
    },
    {
        name: 'timeline-arrow',
        categories: ['business', 'marketing'],
        terms: [],
        label: 'Timeline Arrow',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29d',
    },
    {
        name: 'timer',
        categories: ['time'],
        terms: [],
        label: 'Timer',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29e',
    },
    {
        name: 'tire',
        categories: ['automotive', 'spinners'],
        terms: ['auto', 'car', 'rim', 'rubber', 'wheel'],
        label: 'Tire',
        aliases: {
            unicodes: {
                secondary: ['10f631'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f631',
    },
    {
        name: 'tire-flat',
        categories: ['automotive'],
        terms: ['auto', 'car', 'rim', 'rubber', 'wheel'],
        label: 'Tire Flat',
        aliases: {
            unicodes: {
                secondary: ['10f632'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f632',
    },
    {
        name: 'tire-pressure-warning',
        categories: ['automotive'],
        terms: ['alert', 'auto', 'car', 'flat', 'rim', 'rubber', 'wheel'],
        label: 'Tire Pressure-warning',
        aliases: {
            unicodes: {
                secondary: ['10f633'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f633',
    },
    {
        name: 'tire-rugged',
        categories: ['automotive', 'spinners'],
        terms: ['auto', 'car', 'offroad', 'rim', 'rubber', 'wheel'],
        label: 'Tire Rugged',
        aliases: {
            unicodes: {
                secondary: ['10f634'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f634',
    },
    {
        name: 'toggle-off',
        categories: ['toggle'],
        terms: ['switch'],
        label: 'Toggle Off',
        aliases: {
            unicodes: {
                secondary: ['10f204'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f204',
    },
    {
        name: 'toggle-on',
        categories: ['toggle'],
        terms: ['switch'],
        label: 'Toggle On',
        aliases: {
            unicodes: {
                secondary: ['10f205'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f205',
    },
    {
        name: 'toilet',
        categories: ['household', 'humanitarian', 'travel-hotel'],
        terms: [
            'bathroom',
            'flush',
            'john',
            'loo',
            'pee',
            'plumbing',
            'poop',
            'porcelain',
            'potty',
            'restroom',
            'throne',
            'washroom',
            'waste',
            'wc',
        ],
        label: 'Toilet',
        aliases: {
            unicodes: {
                composite: ['1f6bd'],
                secondary: ['10f7d8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7d8',
    },
    {
        name: 'toilet-paper',
        categories: ['camping', 'halloween', 'household'],
        terms: ['bathroom', 'covid-19', 'halloween', 'holiday', 'lavatory', 'prank', 'restroom', 'roll'],
        label: 'Toilet Paper',
        aliases: {
            unicodes: {
                composite: ['1f9fb'],
                secondary: ['10f71e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71e',
    },
    {
        name: 'toilet-paper-blank',
        categories: ['camping', 'halloween', 'household'],
        terms: [],
        label: 'Toilet paper blank',
        aliases: {
            names: ['toilet-paper-alt'],
            unicodes: {
                secondary: ['10f71f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f71f',
    },
    {
        name: 'toilet-paper-blank-under',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Toilet paper blank under',
        aliases: {
            names: ['toilet-paper-reverse-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e29f',
    },
    {
        name: 'toilet-paper-slash',
        categories: ['household'],
        terms: [
            'bathroom',
            'covid-19',
            'halloween',
            'holiday',
            'lavatory',
            'leaves',
            'prank',
            'restroom',
            'roll',
            'trouble',
            'ut oh',
        ],
        label: 'Toilet Paper Slash',
        aliases: {
            unicodes: {
                secondary: ['10e072'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e072',
    },
    {
        name: 'toilet-paper-under',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Toilet paper under',
        aliases: {
            names: ['toilet-paper-reverse'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a0',
    },
    {
        name: 'toilet-paper-under-slash',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Toilet paper under slash',
        aliases: {
            names: ['toilet-paper-reverse-slash'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a1',
    },
    {
        name: 'toilet-portable',
        categories: ['buildings', 'construction', 'humanitarian'],
        terms: [],
        label: 'Toilet Portable',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e583',
    },
    {
        name: 'toilets-portable',
        categories: ['buildings', 'construction', 'humanitarian'],
        terms: [],
        label: 'Toilets Portable',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e584',
    },
    {
        name: 'tomato',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Tomato',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e330',
    },
    {
        name: 'tombstone',
        categories: ['halloween'],
        terms: ['cemetery', 'cross', 'dead', 'death', 'delete', 'grave', 'halloween', 'holiday', 'remove'],
        label: 'Tombstone',
        aliases: {
            unicodes: {
                secondary: ['10f720'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f720',
    },
    {
        name: 'tombstone-blank',
        categories: ['halloween'],
        terms: [],
        label: 'Tombstone blank',
        aliases: {
            names: ['tombstone-alt'],
            unicodes: {
                composite: ['1faa6'],
                secondary: ['10f721'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f721',
    },
    {
        name: 'toolbox',
        categories: ['construction'],
        terms: ['admin', 'container', 'fix', 'repair', 'settings', 'tools'],
        label: 'Toolbox',
        aliases: {
            unicodes: {
                composite: ['1f9f0'],
                secondary: ['10f552'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f552',
    },
    {
        name: 'tooth',
        categories: ['medical-health'],
        terms: ['bicuspid', 'dental', 'dentist', 'molar', 'mouth', 'teeth'],
        label: 'Tooth',
        aliases: {
            unicodes: {
                composite: ['1f9b7'],
                secondary: ['10f5c9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c9',
    },
    {
        name: 'toothbrush',
        categories: ['medical-health'],
        terms: ['bicuspid', 'dental', 'dentist', 'molar', 'mouth', 'teeth'],
        label: 'Toothbrush',
        aliases: {
            unicodes: {
                composite: ['1faa5'],
                secondary: ['10f635'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f635',
    },
    {
        name: 'torii-gate',
        categories: ['buildings', 'religion'],
        terms: ['building', 'shintoism'],
        label: 'Torii Gate',
        aliases: {
            unicodes: {
                composite: ['26e9'],
                secondary: ['10f6a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a1',
    },
    {
        name: 'tornado',
        categories: ['disaster', 'humanitarian', 'weather'],
        terms: ['cyclone', 'dorothy', 'landspout', 'toto', 'twister', 'vortext', 'waterspout', 'weather', 'whirlwind'],
        label: 'Tornado',
        aliases: {
            unicodes: {
                composite: ['1f32a'],
                secondary: ['10f76f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f76f',
    },
    {
        name: 'tower-broadcast',
        categories: ['connectivity', 'energy', 'film-video', 'humanitarian'],
        terms: [],
        label: 'Tower broadcast',
        aliases: {
            names: ['broadcast-tower'],
            unicodes: {
                secondary: ['10f519'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f519',
    },
    {
        name: 'tower-cell',
        categories: ['communication', 'connectivity', 'film-video', 'humanitarian'],
        terms: [],
        label: 'Tower Cell',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e585',
    },
    {
        name: 'tower-control',
        categories: ['transportation', 'travel-hotel'],
        terms: [],
        label: 'Tower Control',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a2',
    },
    {
        name: 'tower-observation',
        categories: ['buildings', 'humanitarian', 'security'],
        terms: [],
        label: 'Tower Observation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e586',
    },
    {
        name: 'tractor',
        categories: ['transportation'],
        terms: ['agriculture', 'farm', 'vehicle'],
        label: 'Tractor',
        aliases: {
            unicodes: {
                composite: ['1f69c'],
                secondary: ['10f722'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f722',
    },
    {
        name: 'trademark',
        categories: ['business'],
        terms: ['copyright', 'register', 'symbol'],
        label: 'Trademark',
        aliases: {
            unicodes: {
                composite: ['2122'],
                secondary: ['10f25c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f25c',
    },
    {
        name: 'traffic-cone',
        categories: ['construction', 'maps'],
        terms: ['alert', 'construction', 'road'],
        label: 'Traffic Cone',
        aliases: {
            unicodes: {
                secondary: ['10f636'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f636',
    },
    {
        name: 'traffic-light',
        categories: ['maps'],
        terms: ['direction', 'road', 'signal', 'travel'],
        label: 'Traffic Light',
        aliases: {
            unicodes: {
                composite: ['1f6a6'],
                secondary: ['10f637'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f637',
    },
    {
        name: 'traffic-light-go',
        categories: ['maps'],
        terms: ['direction', 'green', 'road', 'signal', 'travel'],
        label: 'Traffic Light-go',
        aliases: {
            unicodes: {
                secondary: ['10f638'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f638',
    },
    {
        name: 'traffic-light-slow',
        categories: ['maps'],
        terms: ['direction', 'road', 'signal', 'travel', 'yellow'],
        label: 'Traffic Light-slow',
        aliases: {
            unicodes: {
                secondary: ['10f639'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f639',
    },
    {
        name: 'traffic-light-stop',
        categories: ['maps'],
        terms: ['direction', 'red', 'road', 'signal', 'travel'],
        label: 'Traffic Light-stop',
        aliases: {
            unicodes: {
                secondary: ['10f63a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f63a',
    },
    {
        name: 'trailer',
        categories: ['automotive', 'camping', 'moving'],
        terms: ['carry', 'haul', 'moving', 'travel'],
        label: 'Trailer',
        aliases: {
            unicodes: {
                secondary: ['10e041'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e041',
    },
    {
        name: 'train',
        categories: ['logistics', 'maps', 'transportation'],
        terms: ['bullet', 'commute', 'locomotive', 'railway', 'subway'],
        label: 'Train',
        aliases: {
            unicodes: {
                composite: ['1f686'],
                secondary: ['10f238'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f238',
    },
    {
        name: 'train-subway',
        categories: ['humanitarian', 'logistics', 'maps', 'transportation'],
        terms: [],
        label: 'Train subway',
        aliases: {
            names: ['subway'],
            unicodes: {
                secondary: ['10f239'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f239',
    },
    {
        name: 'train-subway-tunnel',
        categories: ['logistics', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Train subway tunnel',
        aliases: {
            names: ['subway-tunnel'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a3',
    },
    {
        name: 'train-track',
        categories: ['maps', 'transportation'],
        terms: [],
        label: 'Train Track',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e453',
    },
    {
        name: 'train-tram',
        categories: ['maps', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Train tram',
        aliases: {
            names: ['tram'],
            unicodes: {
                composite: ['1f68a'],
                secondary: ['10f7da'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7da',
    },
    {
        name: 'train-tunnel',
        categories: ['transportation'],
        terms: [],
        label: 'Train Tunnel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e454',
    },
    {
        name: 'transformer-bolt',
        categories: ['energy'],
        terms: [],
        label: 'Transformer Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a4',
    },
    {
        name: 'transgender',
        categories: ['gender'],
        terms: ['intersex'],
        label: 'Transgender',
        aliases: {
            names: ['transgender-alt'],
            unicodes: {
                composite: ['26a7'],
                secondary: ['10f225'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f225',
    },
    {
        name: 'transporter',
        categories: ['science-fiction'],
        terms: ['move', 'teleport', 'transfer', 'translocate', 'travel'],
        label: 'Transporter',
        aliases: {
            unicodes: {
                secondary: ['10e042'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e042',
    },
    {
        name: 'transporter-1',
        categories: ['science-fiction'],
        terms: ['move', 'teleport', 'transfer', 'translocate', 'travel'],
        label: 'Transporter 1',
        aliases: {
            unicodes: {
                secondary: ['10e043'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e043',
    },
    {
        name: 'transporter-2',
        categories: ['science-fiction'],
        terms: ['move', 'teleport', 'transfer', 'translocate', 'travel'],
        label: 'Transporter 2',
        aliases: {
            unicodes: {
                secondary: ['10e044'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e044',
    },
    {
        name: 'transporter-3',
        categories: ['science-fiction'],
        terms: ['move', 'teleport', 'transfer', 'translocate', 'travel'],
        label: 'Transporter 3',
        aliases: {
            unicodes: {
                secondary: ['10e045'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e045',
    },
    {
        name: 'transporter-4',
        categories: ['science-fiction'],
        terms: [],
        label: 'Transporter 4',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a5',
    },
    {
        name: 'transporter-5',
        categories: ['science-fiction'],
        terms: [],
        label: 'Transporter 5',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a6',
    },
    {
        name: 'transporter-6',
        categories: ['science-fiction'],
        terms: [],
        label: 'Transporter 6',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a7',
    },
    {
        name: 'transporter-7',
        categories: ['science-fiction'],
        terms: [],
        label: 'Transporter 7',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a8',
    },
    {
        name: 'transporter-empty',
        categories: ['science-fiction'],
        terms: ['complete', 'move', 'teleport', 'transfer', 'translocate', 'travel'],
        label: 'Transporter Empty',
        aliases: {
            unicodes: {
                secondary: ['10e046'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e046',
    },
    {
        name: 'trash',
        categories: ['editing'],
        terms: ['delete', 'garbage', 'hide', 'remove'],
        label: 'Trash',
        aliases: {
            unicodes: {
                secondary: ['10f1f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1f8',
    },
    {
        name: 'trash-arrow-up',
        categories: ['editing'],
        terms: [],
        label: 'Trash arrow up',
        aliases: {
            names: ['trash-restore'],
            unicodes: {
                secondary: ['10f829'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f829',
    },
    {
        name: 'trash-can',
        categories: ['editing', 'humanitarian'],
        terms: [],
        label: 'Trash can',
        aliases: {
            names: ['trash-alt'],
            unicodes: {
                composite: ['f014'],
                secondary: ['10f2ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ed',
    },
    {
        name: 'trash-can-arrow-up',
        categories: ['editing'],
        terms: [],
        label: 'Trash can arrow up',
        aliases: {
            names: ['trash-restore-alt'],
            unicodes: {
                secondary: ['10f82a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82a',
    },
    {
        name: 'trash-can-check',
        categories: ['editing'],
        terms: [],
        label: 'Trash Can-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2a9',
    },
    {
        name: 'trash-can-clock',
        categories: ['editing', 'time'],
        terms: [],
        label: 'Trash Can-clock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2aa',
    },
    {
        name: 'trash-can-list',
        categories: ['editing'],
        terms: [],
        label: 'Trash Can-list',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ab',
    },
    {
        name: 'trash-can-plus',
        categories: ['editing'],
        terms: [],
        label: 'Trash Can-plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ac',
    },
    {
        name: 'trash-can-slash',
        categories: ['editing'],
        terms: [],
        label: 'Trash can slash',
        aliases: {
            names: ['trash-alt-slash'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ad',
    },
    {
        name: 'trash-can-undo',
        categories: ['editing'],
        terms: [],
        label: 'Trash can undo',
        aliases: {
            names: ['trash-can-arrow-turn-left', 'trash-undo-alt'],
            unicodes: {
                secondary: ['10f896'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f896',
    },
    {
        name: 'trash-can-xmark',
        categories: ['editing'],
        terms: [],
        label: 'Trash Can-xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ae',
    },
    {
        name: 'trash-check',
        categories: ['editing'],
        terms: [],
        label: 'Trash Check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2af',
    },
    {
        name: 'trash-clock',
        categories: ['editing', 'time'],
        terms: [],
        label: 'Trash Clock',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b0',
    },
    {
        name: 'trash-list',
        categories: ['editing'],
        terms: [],
        label: 'Trash List',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b1',
    },
    {
        name: 'trash-plus',
        categories: ['editing'],
        terms: [],
        label: 'Trash Plus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b2',
    },
    {
        name: 'trash-slash',
        categories: ['editing'],
        terms: [],
        label: 'Trash Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b3',
    },
    {
        name: 'trash-undo',
        categories: ['editing'],
        terms: ['back', 'control z', 'oops', 'restore'],
        label: 'Trash Undo',
        aliases: {
            names: ['trash-arrow-turn-left'],
            unicodes: {
                secondary: ['10f895'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f895',
    },
    {
        name: 'trash-xmark',
        categories: ['editing'],
        terms: [],
        label: 'Trash Xmark',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b4',
    },
    {
        name: 'treasure-chest',
        categories: ['gaming', 'money'],
        terms: ['Dungeons & Dragons', 'booty', 'd&d', 'dnd', 'fantasy', 'gold', 'hidden', 'loot', 'reward', 'trap'],
        label: 'Treasure Chest',
        aliases: {
            unicodes: {
                secondary: ['10f723'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f723',
    },
    {
        name: 'tree',
        categories: ['camping', 'maps', 'nature'],
        terms: ['bark', 'fall', 'flora', 'forest', 'nature', 'plant', 'seasonal'],
        label: 'Tree',
        aliases: {
            unicodes: {
                composite: ['1f332'],
                secondary: ['10f1bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1bb',
    },
    {
        name: 'tree-christmas',
        categories: ['holidays'],
        terms: ['christmas', 'decorated', 'decorations', 'flora', 'holiday', 'lights', 'plant', 'star', 'xmas'],
        label: 'Christmas Tree',
        aliases: {
            unicodes: {
                composite: ['1f384'],
                secondary: ['10f7db'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7db',
    },
    {
        name: 'tree-city',
        categories: ['buildings', 'humanitarian', 'travel-hotel'],
        terms: [],
        label: 'Tree City',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e587',
    },
    {
        name: 'tree-deciduous',
        categories: ['camping', 'maps', 'nature'],
        terms: [],
        label: 'Tree deciduous',
        aliases: {
            names: ['tree-alt'],
            unicodes: {
                composite: ['1f333'],
                secondary: ['10f400'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f400',
    },
    {
        name: 'tree-decorated',
        categories: ['holidays'],
        terms: ['christmas', 'decorations', 'flora', 'holiday', 'lights', 'plant', 'xmas'],
        label: 'Tree Decorated',
        aliases: {
            unicodes: {
                secondary: ['10f7dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7dc',
    },
    {
        name: 'tree-large',
        categories: ['camping', 'nature'],
        terms: ['bark', 'fall', 'flora', 'forest', 'nature', 'plant', 'seasonal'],
        label: 'Tree Large',
        aliases: {
            unicodes: {
                secondary: ['10f7dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7dd',
    },
    {
        name: 'tree-palm',
        categories: ['nature', 'travel-hotel'],
        terms: ['coconut', 'frond', 'tropical'],
        label: 'Palm Tree',
        aliases: {
            unicodes: {
                composite: ['1f334'],
                secondary: ['10f82b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82b',
    },
    {
        name: 'trees',
        categories: ['camping', 'nature'],
        terms: ['bark', 'fall', 'flora', 'forest', 'nature', 'plant', 'seasonal'],
        label: 'Trees',
        aliases: {
            unicodes: {
                secondary: ['10f724'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f724',
    },
    {
        name: 'triangle',
        categories: ['shapes'],
        terms: ['pyramid', 'shape'],
        label: 'Triangle',
        aliases: {
            unicodes: {
                composite: ['25b2'],
                secondary: ['10f2ec'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2ec',
    },
    {
        name: 'triangle-exclamation',
        categories: ['alert'],
        terms: [],
        label: 'Triangle exclamation',
        aliases: {
            names: ['exclamation-triangle', 'warning'],
            unicodes: {
                composite: ['26a0'],
                secondary: ['10f071'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f071',
    },
    {
        name: 'triangle-instrument',
        categories: ['music-audio'],
        terms: [],
        label: 'Triangle instrument',
        aliases: {
            names: ['triangle-music'],
            unicodes: {
                secondary: ['10f8e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e2',
    },
    {
        name: 'triangle-person-digging',
        categories: ['construction'],
        terms: [],
        label: 'Triangle person digging',
        aliases: {
            names: ['construction'],
            unicodes: {
                secondary: ['10f85d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f85d',
    },
    {
        name: 'trillium',
        categories: ['nature'],
        terms: [],
        label: 'Trillium',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e588',
    },
    {
        name: 'trophy',
        categories: ['maps', 'shopping'],
        terms: ['achievement', 'award', 'cup', 'game', 'winner'],
        label: 'trophy',
        aliases: {
            unicodes: {
                composite: ['1f3c6'],
                secondary: ['10f091'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f091',
    },
    {
        name: 'trophy-star',
        categories: ['maps', 'shopping'],
        terms: [],
        label: 'Trophy star',
        aliases: {
            names: ['trophy-alt'],
            unicodes: {
                secondary: ['10f2eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2eb',
    },
    {
        name: 'trowel',
        categories: ['construction', 'humanitarian'],
        terms: [],
        label: 'Trowel',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e589',
    },
    {
        name: 'trowel-bricks',
        categories: ['construction', 'humanitarian'],
        terms: [],
        label: 'Trowel Bricks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58a',
    },
    {
        name: 'truck',
        categories: ['automotive', 'humanitarian', 'logistics', 'maps', 'shopping', 'transportation'],
        terms: ['cargo', 'delivery', 'shipping', 'vehicle'],
        label: 'truck',
        aliases: {
            unicodes: {
                composite: ['1f69a', '26df'],
                secondary: ['10f0d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d1',
    },
    {
        name: 'truck-arrow-right',
        categories: ['humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Truck Arrow-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58b',
    },
    {
        name: 'truck-bolt',
        categories: ['automotive', 'energy', 'shopping', 'transportation'],
        terms: [],
        label: 'Truck Bolt',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d0',
    },
    {
        name: 'truck-clock',
        categories: ['logistics', 'shopping'],
        terms: [],
        label: 'Truck clock',
        aliases: {
            names: ['shipping-timed'],
            unicodes: {
                secondary: ['10f48c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48c',
    },
    {
        name: 'truck-container',
        categories: ['construction', 'moving', 'shopping'],
        terms: ['cargo', 'delivery', 'shipping', 'vehicle'],
        label: 'Truck Container',
        aliases: {
            unicodes: {
                secondary: ['10f4dc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4dc',
    },
    {
        name: 'truck-container-empty',
        categories: ['logistics', 'transportation'],
        terms: [],
        label: 'Truck Container-empty',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b5',
    },
    {
        name: 'truck-droplet',
        categories: ['humanitarian', 'transportation'],
        terms: [],
        label: 'Truck Droplet',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58c',
    },
    {
        name: 'truck-fast',
        categories: ['logistics', 'shopping'],
        terms: [],
        label: 'Truck fast',
        aliases: {
            names: ['shipping-fast'],
            unicodes: {
                secondary: ['10f48b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f48b',
    },
    {
        name: 'truck-field',
        categories: ['automotive', 'humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Truck Field',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58d',
    },
    {
        name: 'truck-field-un',
        categories: ['automotive', 'humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Truck Field-un',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58e',
    },
    {
        name: 'truck-flatbed',
        categories: ['logistics', 'transportation'],
        terms: [],
        label: 'Truck Flatbed',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b6',
    },
    {
        name: 'truck-front',
        categories: ['humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Truck Front',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b7',
    },
    {
        name: 'truck-medical',
        categories: ['automotive', 'humanitarian', 'maps', 'medical-health', 'transportation'],
        terms: [],
        label: 'Truck medical',
        aliases: {
            names: ['ambulance'],
            unicodes: {
                composite: ['1f691'],
                secondary: ['10f0f9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f9',
    },
    {
        name: 'truck-monster',
        categories: ['automotive', 'transportation'],
        terms: ['offroad', 'vehicle', 'wheel'],
        label: 'Truck Monster',
        aliases: {
            unicodes: {
                secondary: ['10f63b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f63b',
    },
    {
        name: 'truck-moving',
        categories: ['moving'],
        terms: ['cargo', 'inventory', 'rental', 'vehicle'],
        label: 'Truck Moving',
        aliases: {
            unicodes: {
                secondary: ['10f4df'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4df',
    },
    {
        name: 'truck-pickup',
        categories: ['automotive', 'construction', 'transportation'],
        terms: ['cargo', 'vehicle'],
        label: 'Truck Side',
        aliases: {
            unicodes: {
                composite: ['1f6fb'],
                secondary: ['10f63c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f63c',
    },
    {
        name: 'truck-plane',
        categories: ['humanitarian', 'logistics', 'transportation'],
        terms: [],
        label: 'Truck Plane',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e58f',
    },
    {
        name: 'truck-plow',
        categories: ['maps', 'transportation'],
        terms: ['clean up', 'cold', 'seasonal', 'snow', 'winter'],
        label: 'Truck Plow',
        aliases: {
            unicodes: {
                secondary: ['10f7de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7de',
    },
    {
        name: 'truck-ramp',
        categories: ['moving'],
        terms: ['box', 'cargo', 'delivery', 'inventory', 'moving', 'rental', 'vehicle'],
        label: 'Truck Ramp',
        aliases: {
            unicodes: {
                secondary: ['10f4e0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4e0',
    },
    {
        name: 'truck-ramp-box',
        categories: ['moving'],
        terms: [],
        label: 'Truck ramp box',
        aliases: {
            names: ['truck-loading'],
            unicodes: {
                secondary: ['10f4de'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4de',
    },
    {
        name: 'truck-ramp-couch',
        categories: ['moving'],
        terms: [],
        label: 'Truck ramp couch',
        aliases: {
            names: ['truck-couch'],
            unicodes: {
                secondary: ['10f4dd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4dd',
    },
    {
        name: 'truck-tow',
        categories: ['transportation'],
        terms: [],
        label: 'Truck Tow',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b8',
    },
    {
        name: 'trumpet',
        categories: ['music-audio'],
        terms: ['brass', 'bugle', 'classical', 'cornet', 'instrument', 'jazz', 'music', 'orchestra'],
        label: 'Trumpet',
        aliases: {
            unicodes: {
                composite: ['1f3ba'],
                secondary: ['10f8e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e3',
    },
    {
        name: 'tty',
        categories: ['accessibility', 'communication', 'maps'],
        terms: ['communication', 'deaf', 'telephone', 'teletypewriter', 'text'],
        label: 'TTY',
        aliases: {
            names: ['teletype'],
            unicodes: {
                secondary: ['10f1e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1e4',
    },
    {
        name: 'tty-answer',
        categories: ['accessibility', 'communication'],
        terms: [],
        label: 'Tty Answer',
        aliases: {
            names: ['teletype-answer'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2b9',
    },
    {
        name: 'tugrik-sign',
        categories: ['money'],
        terms: [],
        label: 'Tugrik Sign',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ba',
    },
    {
        name: 'turkey',
        categories: ['food-beverage', 'holidays'],
        terms: ['bird', 'chicken', 'meal', 'meat', 'poultry', 'seasonal', 'thanksgiving'],
        label: 'Turkey',
        aliases: {
            unicodes: {
                secondary: ['10f725'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f725',
    },
    {
        name: 'turkish-lira-sign',
        categories: ['money'],
        terms: [],
        label: 'Turkish Lira-sign',
        aliases: {
            names: ['try', 'turkish-lira'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2bb',
    },
    {
        name: 'turn-down',
        categories: ['arrows'],
        terms: [],
        label: 'Turn down',
        aliases: {
            names: ['level-down-alt'],
            unicodes: {
                composite: ['2935'],
                secondary: ['10f3be'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3be',
    },
    {
        name: 'turn-down-left',
        categories: ['arrows'],
        terms: [],
        label: 'Turn Down Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e331',
    },
    {
        name: 'turn-down-right',
        categories: ['arrows'],
        terms: [],
        label: 'Turn Down-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e455',
    },
    {
        name: 'turn-up',
        categories: ['arrows'],
        terms: [],
        label: 'Turn up',
        aliases: {
            names: ['level-up-alt'],
            unicodes: {
                composite: ['2934'],
                secondary: ['10f3bf'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f3bf',
    },
    {
        name: 'turntable',
        categories: ['music-audio'],
        terms: ['album', 'music', 'phonograph', 'record', 'record player', 'vinyl'],
        label: 'Turntable',
        aliases: {
            unicodes: {
                secondary: ['10f8e4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e4',
    },
    {
        name: 'turtle',
        categories: ['animals', 'editing'],
        terms: ['cowabunga', 'donatello', 'fauna', 'leonardo', 'michaelangelo', 'raphael', 'reptile', 'shell', 'slow'],
        label: 'Turtle',
        aliases: {
            unicodes: {
                composite: ['1f422'],
                secondary: ['10f726'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f726',
    },
    {
        name: 'tv',
        categories: ['devices-hardware', 'film-video', 'household', 'travel-hotel'],
        terms: ['computer', 'display', 'monitor', 'television'],
        label: 'Television',
        aliases: {
            names: ['television', 'tv-alt'],
            unicodes: {
                composite: ['f8e5'],
                primary: ['f8e5'],
                secondary: ['10f8e5', '10f26c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f26c',
    },
    {
        name: 'tv-music',
        categories: ['devices-hardware', 'music-audio'],
        terms: ['audio', 'mtv', 'music', 'music video', 'sound', 'television', 'vh1'],
        label: 'TV Music',
        aliases: {
            unicodes: {
                secondary: ['10f8e6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e6',
    },
    {
        name: 'tv-retro',
        categories: ['devices-hardware', 'film-video', 'household', 'travel-hotel'],
        terms: ['computer', 'display', 'monitor', 'television'],
        label: 'Retro Televison',
        aliases: {
            unicodes: {
                composite: ['1f4fa'],
                secondary: ['10f401'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f401',
    },
    {
        name: 'twitch',
        categories: ['gaming'],
        terms: [],
        label: 'Twitch',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f1e8',
    },
    {
        name: 'typewriter',
        categories: ['devices-hardware', 'writing'],
        terms: ['keyboard', 'letterpress', 'movable type', 'newspaper', 'novel', 'retro', 'typing', 'vintage'],
        label: 'Typewriter',
        aliases: {
            unicodes: {
                secondary: ['10f8e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e7',
    },
    {
        name: 'u',
        categories: ['alphabet'],
        terms: [],
        label: 'U',
        aliases: {
            unicodes: {
                composite: ['75'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '55',
    },
    {
        name: 'ufo',
        categories: ['astronomy', 'science-fiction', 'transportation'],
        terms: [
            'abduct',
            'alien',
            'extraterrestrial',
            'flying saucer',
            'space',
            'spacecraft',
            't-craft',
            'travel',
            'unidentified flying object',
        ],
        label: 'U.F.O.',
        aliases: {
            unicodes: {
                composite: ['1f6f8'],
                secondary: ['10e047'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e047',
    },
    {
        name: 'ufo-beam',
        categories: ['astronomy', 'science-fiction', 'transportation'],
        terms: [
            'abduct',
            'alien',
            'extraterrestrial',
            'flying saucer',
            'probe',
            'space',
            'spacecraft',
            't-craft',
            'tractor beam',
            'travel',
            'unidentified flying object',
        ],
        label: 'U.F.O. with Beam',
        aliases: {
            unicodes: {
                secondary: ['10e048'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e048',
    },
    {
        name: 'umbrella',
        categories: ['maps', 'weather'],
        terms: ['protection', 'rain', 'storm', 'wet'],
        label: 'Umbrella',
        aliases: {
            unicodes: {
                secondary: ['10f0e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0e9',
    },
    {
        name: 'umbrella-beach',
        categories: ['travel-hotel'],
        terms: ['protection', 'recreation', 'sand', 'shade', 'summer', 'sun'],
        label: 'Umbrella Beach',
        aliases: {
            unicodes: {
                composite: ['1f3d6'],
                secondary: ['10f5ca'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ca',
    },
    {
        name: 'umbrella-simple',
        categories: ['weather'],
        terms: [],
        label: 'Umbrella simple',
        aliases: {
            names: ['umbrella-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2bc',
    },
    {
        name: 'underline',
        categories: ['text-formatting'],
        terms: ['edit', 'emphasis', 'format', 'text', 'writing'],
        label: 'Underline',
        aliases: {
            unicodes: {
                secondary: ['10f0cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0cd',
    },
    {
        name: 'unicorn',
        categories: ['animals'],
        terms: ['fantasy', 'fauna', 'horn', 'horse'],
        label: 'Unicorn',
        aliases: {
            unicodes: {
                composite: ['1f984'],
                secondary: ['10f727'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f727',
    },
    {
        name: 'uniform-martial-arts',
        categories: ['clothing-fashion', 'sports-fitness'],
        terms: [],
        label: 'Martial Arts Uniform',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d1',
    },
    {
        name: 'union',
        categories: ['mathematics'],
        terms: ['calculus', 'equation', 'function', 'math'],
        label: 'Union',
        aliases: {
            unicodes: {
                composite: ['22c3'],
                secondary: ['10f6a2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a2',
    },
    {
        name: 'universal-access',
        categories: ['accessibility'],
        terms: ['accessibility', 'hearing', 'person', 'seeing', 'visual impairment'],
        label: 'Universal Access',
        aliases: {
            unicodes: {
                secondary: ['10f29a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f29a',
    },
    {
        name: 'unlock',
        categories: ['security'],
        terms: ['admin', 'lock', 'password', 'private', 'protect'],
        label: 'unlock',
        aliases: {
            unicodes: {
                composite: ['1f513'],
                secondary: ['10f09c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f09c',
    },
    {
        name: 'unlock-keyhole',
        categories: ['security'],
        terms: [],
        label: 'Unlock keyhole',
        aliases: {
            names: ['unlock-alt'],
            unicodes: {
                secondary: ['10f13e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f13e',
    },
    {
        name: 'unsplash',
        categories: ['photos-images'],
        terms: [],
        label: 'Unsplash',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'e07c',
    },
    {
        name: 'up',
        categories: ['arrows'],
        terms: [],
        label: 'Up',
        aliases: {
            names: ['arrow-alt-up'],
            unicodes: {
                secondary: ['10f357'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f357',
    },
    {
        name: 'up-down',
        categories: ['arrows'],
        terms: [],
        label: 'Up down',
        aliases: {
            names: ['arrows-alt-v'],
            unicodes: {
                composite: ['2b0d', '2195'],
                secondary: ['10f338'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f338',
    },
    {
        name: 'up-down-left-right',
        categories: ['arrows'],
        terms: [],
        label: 'Up down left right',
        aliases: {
            names: ['arrows-alt'],
            unicodes: {
                secondary: ['10f0b2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0b2',
    },
    {
        name: 'up-from-bracket',
        categories: ['arrows'],
        terms: [],
        label: 'Up from Bracket',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e590',
    },
    {
        name: 'up-from-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Up From-dotted-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e456',
    },
    {
        name: 'up-from-line',
        categories: ['arrows'],
        terms: [],
        label: 'Up from line',
        aliases: {
            names: ['arrow-alt-from-bottom'],
            unicodes: {
                secondary: ['10f346'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f346',
    },
    {
        name: 'up-left',
        categories: ['arrows'],
        terms: [],
        label: 'Up Left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2bd',
    },
    {
        name: 'up-long',
        categories: ['arrows'],
        terms: [],
        label: 'Up long',
        aliases: {
            names: ['long-arrow-alt-up'],
            unicodes: {
                secondary: ['10f30c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f30c',
    },
    {
        name: 'up-right',
        categories: ['arrows'],
        terms: [],
        label: 'Up Right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2be',
    },
    {
        name: 'up-right-and-down-left-from-center',
        categories: ['arrows', 'media-playback'],
        terms: [],
        label: 'Up right and down left from center',
        aliases: {
            names: ['expand-alt'],
            unicodes: {
                secondary: ['10f424'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f424',
    },
    {
        name: 'up-right-from-square',
        categories: ['arrows'],
        terms: [],
        label: 'Up right from square',
        aliases: {
            names: ['external-link-alt'],
            unicodes: {
                secondary: ['10f35d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f35d',
    },
    {
        name: 'up-to-dotted-line',
        categories: ['arrows'],
        terms: [],
        label: 'Up To-dotted-line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e457',
    },
    {
        name: 'up-to-line',
        categories: ['arrows'],
        terms: [],
        label: 'Up to line',
        aliases: {
            names: ['arrow-alt-to-top'],
            unicodes: {
                secondary: ['10f34d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f34d',
    },
    {
        name: 'upload',
        categories: ['arrows', 'devices-hardware'],
        terms: ['hard drive', 'import', 'publish'],
        label: 'Upload',
        aliases: {
            unicodes: {
                secondary: ['10f093'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f093',
    },
    {
        name: 'usb-drive',
        categories: ['devices-hardware', 'security'],
        terms: ['access codes', 'device', 'flashdrive', 'goober', 'ssd', 'storage', 'thumbdrive'],
        label: 'USB Drive',
        aliases: {
            unicodes: {
                secondary: ['10f8e9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8e9',
    },
    {
        name: 'user',
        categories: ['social', 'users-people'],
        terms: ['account', 'avatar', 'head', 'human', 'man', 'person', 'profile'],
        label: 'User',
        aliases: {
            unicodes: {
                composite: ['f2c0', '1f464'],
                secondary: ['10f007'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f007',
    },
    {
        name: 'user-alien',
        categories: ['astronomy', 'science-fiction', 'users-people'],
        terms: ['ET', 'extraterrestrial', 'humanoid', 'monster', 'space', 'strange', 'ufo'],
        label: 'User Alien',
        aliases: {
            unicodes: {
                secondary: ['10e04a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04a',
    },
    {
        name: 'user-astronaut',
        categories: ['astronomy', 'science-fiction', 'users-people'],
        terms: ['avatar', 'clothing', 'cosmonaut', 'nasa', 'space', 'suit'],
        label: 'User Astronaut',
        aliases: {
            unicodes: {
                secondary: ['10f4fb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4fb',
    },
    {
        name: 'user-bounty-hunter',
        categories: ['science-fiction', 'users-people'],
        terms: [],
        label: 'User Bounty Hunter',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2bf',
    },
    {
        name: 'user-check',
        categories: ['users-people'],
        terms: ['accept', 'check', 'person', 'verified'],
        label: 'User Check',
        aliases: {
            unicodes: {
                secondary: ['10f4fc'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4fc',
    },
    {
        name: 'user-chef',
        categories: ['food-beverage', 'travel-hotel', 'users-people'],
        terms: [],
        label: 'User Chef',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d2',
    },
    {
        name: 'user-clock',
        categories: ['users-people'],
        terms: ['alert', 'person', 'remind', 'time'],
        label: 'User Clock',
        aliases: {
            unicodes: {
                secondary: ['10f4fd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4fd',
    },
    {
        name: 'user-cowboy',
        categories: ['users-people'],
        terms: [
            'buckaroo',
            'cowgirl',
            'horse',
            'jackeroo',
            'jessie',
            'old west',
            'pardner',
            'ranch',
            'rancher',
            'rodeo',
            'the lone ranger',
            'western',
            'woody',
            'wrangler',
        ],
        label: 'User Cowboy',
        aliases: {
            unicodes: {
                secondary: ['10f8ea'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ea',
    },
    {
        name: 'user-crown',
        categories: ['marketing', 'social', 'users-people'],
        terms: ['admin', 'avatar', 'person', 'special'],
        label: 'User Crown',
        aliases: {
            unicodes: {
                secondary: ['10f6a4'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a4',
    },
    {
        name: 'user-doctor',
        categories: ['humanitarian', 'medical-health', 'users-people'],
        terms: [],
        label: 'User doctor',
        aliases: {
            names: ['user-md'],
            unicodes: {
                secondary: ['10f0f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0f0',
    },
    {
        name: 'user-doctor-hair',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'User Doctor-hair',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e458',
    },
    {
        name: 'user-doctor-hair-long',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'User Doctor-hair-long',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e459',
    },
    {
        name: 'user-doctor-message',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'User doctor message',
        aliases: {
            names: ['user-md-chat'],
            unicodes: {
                secondary: ['10f82e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82e',
    },
    {
        name: 'user-gear',
        categories: ['users-people'],
        terms: [],
        label: 'User gear',
        aliases: {
            names: ['user-cog'],
            unicodes: {
                secondary: ['10f4fe'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4fe',
    },
    {
        name: 'user-graduate',
        categories: ['education', 'users-people'],
        terms: ['cap', 'clothing', 'commencement', 'gown', 'graduation', 'person', 'student'],
        label: 'User Graduate',
        aliases: {
            unicodes: {
                secondary: ['10f501'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f501',
    },
    {
        name: 'user-group',
        categories: ['social', 'users-people'],
        terms: [],
        label: 'User group',
        aliases: {
            names: ['user-friends'],
            unicodes: {
                composite: ['1f465'],
                secondary: ['10f500'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f500',
    },
    {
        name: 'user-group-crown',
        categories: ['marketing', 'social', 'users-people'],
        terms: [],
        label: 'User group crown',
        aliases: {
            names: ['users-crown'],
            unicodes: {
                secondary: ['10f6a5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a5',
    },
    {
        name: 'user-hair',
        categories: ['users-people'],
        terms: [],
        label: 'User Hair',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45a',
    },
    {
        name: 'user-hair-buns',
        categories: ['science-fiction', 'users-people'],
        terms: [],
        label: 'User Hair in Buns',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e3d3',
    },
    {
        name: 'user-hair-long',
        categories: ['users-people'],
        terms: [],
        label: 'User Hair-long',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45b',
    },
    {
        name: 'user-hair-mullet',
        categories: ['business', 'users-people'],
        terms: [],
        label: 'User Hair Mullet',
        aliases: {
            names: ['business-front', 'party-back', 'trian-balbot'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45c',
    },
    {
        name: 'user-headset',
        categories: ['users-people'],
        terms: ['gaming', 'headphones', 'help', 'person', 'support', 'telemarketer', 'telemarketing'],
        label: 'User Headset',
        aliases: {
            unicodes: {
                secondary: ['10f82d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82d',
    },
    {
        name: 'user-helmet-safety',
        categories: ['construction', 'logistics', 'users-people'],
        terms: [],
        label: 'User helmet safety',
        aliases: {
            names: ['user-construction', 'user-hard-hat'],
            unicodes: {
                composite: ['1f477'],
                secondary: ['10f82c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82c',
    },
    {
        name: 'user-injured',
        categories: ['humanitarian', 'users-people'],
        terms: ['cast', 'injury', 'ouch', 'patient', 'person', 'sling'],
        label: 'User Injured',
        aliases: {
            unicodes: {
                secondary: ['10f728'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f728',
    },
    {
        name: 'user-large',
        categories: ['users-people'],
        terms: [],
        label: 'User large',
        aliases: {
            names: ['user-alt'],
            unicodes: {
                secondary: ['10f406'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f406',
    },
    {
        name: 'user-large-slash',
        categories: ['users-people'],
        terms: [],
        label: 'User large slash',
        aliases: {
            names: ['user-alt-slash'],
            unicodes: {
                secondary: ['10f4fa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4fa',
    },
    {
        name: 'user-lock',
        categories: ['security', 'users-people'],
        terms: ['admin', 'lock', 'person', 'private', 'unlock'],
        label: 'User Lock',
        aliases: {
            unicodes: {
                secondary: ['10f502'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f502',
    },
    {
        name: 'user-minus',
        categories: ['users-people'],
        terms: ['delete', 'negative', 'remove'],
        label: 'User Minus',
        aliases: {
            unicodes: {
                secondary: ['10f503'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f503',
    },
    {
        name: 'user-music',
        categories: ['music-audio', 'users-people'],
        terms: ['artist', 'music', 'musician', 'singer'],
        label: 'User Music',
        aliases: {
            unicodes: {
                secondary: ['10f8eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8eb',
    },
    {
        name: 'user-ninja',
        categories: ['users-people'],
        terms: ['assassin', 'avatar', 'dangerous', 'deadly', 'sneaky'],
        label: 'User Ninja',
        aliases: {
            unicodes: {
                composite: ['1f977'],
                secondary: ['10f504'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f504',
    },
    {
        name: 'user-nurse',
        categories: ['medical-health', 'users-people'],
        terms: ['covid-19', 'doctor', 'midwife', 'practitioner', 'surgeon'],
        label: 'Nurse',
        aliases: {
            unicodes: {
                secondary: ['10f82f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f82f',
    },
    {
        name: 'user-nurse-hair',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'User Nurse-hair',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45d',
    },
    {
        name: 'user-nurse-hair-long',
        categories: ['medical-health', 'users-people'],
        terms: [],
        label: 'User Nurse-hair-long',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45e',
    },
    {
        name: 'user-pen',
        categories: ['users-people'],
        terms: [],
        label: 'User pen',
        aliases: {
            names: ['user-edit'],
            unicodes: {
                secondary: ['10f4ff'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4ff',
    },
    {
        name: 'user-pilot',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'User Pilot',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c0',
    },
    {
        name: 'user-pilot-tie',
        categories: ['travel-hotel', 'users-people'],
        terms: [],
        label: 'User Pilot-tie',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c1',
    },
    {
        name: 'user-plus',
        categories: ['social', 'users-people'],
        terms: ['add', 'avatar', 'positive', 'sign up', 'signup', 'team'],
        label: 'User Plus',
        aliases: {
            unicodes: {
                secondary: ['10f234'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f234',
    },
    {
        name: 'user-police',
        categories: ['security', 'users-people'],
        terms: [],
        label: 'User Police',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e333',
    },
    {
        name: 'user-police-tie',
        categories: ['security', 'users-people'],
        terms: [],
        label: 'User Police Tie',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e334',
    },
    {
        name: 'user-robot',
        categories: ['science-fiction', 'users-people'],
        terms: ['android', 'automate', 'computer', 'cyborg', 'rosie'],
        label: 'User Robot',
        aliases: {
            unicodes: {
                secondary: ['10e04b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04b',
    },
    {
        name: 'user-robot-xmarks',
        categories: ['science-fiction', 'users-people'],
        terms: [],
        label: 'User Robot-xmarks',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a7',
    },
    {
        name: 'user-secret',
        categories: ['coding', 'security', 'users-people'],
        terms: ['clothing', 'coat', 'hat', 'incognito', 'person', 'privacy', 'spy', 'whisper'],
        label: 'User Secret',
        aliases: {
            unicodes: {
                composite: ['1f575'],
                secondary: ['10f21b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f21b',
    },
    {
        name: 'user-shakespeare',
        categories: ['users-people'],
        terms: [],
        label: 'User Shakespeare',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c2',
    },
    {
        name: 'user-shield',
        categories: ['security', 'users-people'],
        terms: ['admin', 'person', 'private', 'protect', 'safe'],
        label: 'User Shield',
        aliases: {
            unicodes: {
                secondary: ['10f505'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f505',
    },
    {
        name: 'user-slash',
        categories: ['users-people'],
        terms: ['ban', 'delete', 'remove'],
        label: 'User Slash',
        aliases: {
            unicodes: {
                secondary: ['10f506'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f506',
    },
    {
        name: 'user-tag',
        categories: ['users-people'],
        terms: ['avatar', 'discount', 'label', 'person', 'role', 'special'],
        label: 'User Tag',
        aliases: {
            unicodes: {
                secondary: ['10f507'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f507',
    },
    {
        name: 'user-tie',
        categories: ['clothing-fashion', 'users-people'],
        terms: ['avatar', 'business', 'clothing', 'formal', 'professional', 'suit'],
        label: 'User Tie',
        aliases: {
            unicodes: {
                secondary: ['10f508'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f508',
    },
    {
        name: 'user-tie-hair',
        categories: ['business', 'users-people'],
        terms: [],
        label: 'User Tie-hair',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e45f',
    },
    {
        name: 'user-tie-hair-long',
        categories: ['business', 'users-people'],
        terms: [],
        label: 'User Tie-hair-long',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e460',
    },
    {
        name: 'user-unlock',
        categories: ['security', 'users-people'],
        terms: ['admin', 'lock', 'person', 'private', 'unlock'],
        label: 'User Unlock',
        aliases: {
            unicodes: {
                secondary: ['10e058'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e058',
    },
    {
        name: 'user-visor',
        categories: ['science-fiction', 'users-people'],
        terms: ['geordi la forge', 'star trek'],
        label: 'User Visor',
        aliases: {
            unicodes: {
                secondary: ['10e04c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04c',
    },
    {
        name: 'user-vneck',
        categories: ['users-people'],
        terms: [],
        label: 'User Vneck',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e461',
    },
    {
        name: 'user-vneck-hair',
        categories: ['users-people'],
        terms: [],
        label: 'User Vneck-hair',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e462',
    },
    {
        name: 'user-vneck-hair-long',
        categories: ['users-people'],
        terms: [],
        label: 'User Vneck-hair-long',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e463',
    },
    {
        name: 'user-xmark',
        categories: ['users-people'],
        terms: [],
        label: 'User X Mark',
        aliases: {
            names: ['user-times'],
            unicodes: {
                secondary: ['10f235'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f235',
    },
    {
        name: 'users',
        categories: ['social', 'users-people'],
        terms: ['friends', 'group', 'people', 'persons', 'profiles', 'team'],
        label: 'Users',
        aliases: {
            unicodes: {
                secondary: ['10f0c0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0c0',
    },
    {
        name: 'users-between-lines',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Users Between-lines',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e591',
    },
    {
        name: 'users-gear',
        categories: ['users-people'],
        terms: [],
        label: 'Users gear',
        aliases: {
            names: ['users-cog'],
            unicodes: {
                secondary: ['10f509'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f509',
    },
    {
        name: 'users-line',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Users Line',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e592',
    },
    {
        name: 'users-medical',
        categories: ['medical-health', 'users-people'],
        terms: ['group', 'patients', 'physician', 'team'],
        label: 'Users with Medical Symbol',
        aliases: {
            unicodes: {
                secondary: ['10f830'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f830',
    },
    {
        name: 'users-rays',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Users Rays',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e593',
    },
    {
        name: 'users-rectangle',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Users Rectangle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e594',
    },
    {
        name: 'users-slash',
        categories: ['users-people'],
        terms: ['disband', 'friends', 'group', 'people', 'persons', 'profiles', 'separate', 'team', 'ungroup'],
        label: 'Users Slash',
        aliases: {
            unicodes: {
                secondary: ['10e073'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e073',
    },
    {
        name: 'users-viewfinder',
        categories: ['humanitarian', 'users-people'],
        terms: [],
        label: 'Users Viewfinder',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e595',
    },
    {
        name: 'utensils',
        categories: ['household', 'maps', 'travel-hotel'],
        terms: ['cutlery', 'dining', 'dinner', 'eat', 'food', 'fork', 'knife', 'restaurant'],
        label: 'Utensils',
        aliases: {
            names: ['cutlery'],
            unicodes: {
                composite: ['f0f5', '1f374'],
                secondary: ['10f2e7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e7',
    },
    {
        name: 'utensils-slash',
        categories: ['household'],
        terms: [],
        label: 'Utensils Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e464',
    },
    {
        name: 'utility-pole',
        categories: ['energy'],
        terms: [],
        label: 'Utility Pole',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c3',
    },
    {
        name: 'utility-pole-double',
        categories: ['energy'],
        terms: [],
        label: 'Utility Pole-double',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c4',
    },
    {
        name: 'v',
        categories: ['alphabet'],
        terms: [],
        label: 'V',
        aliases: {
            unicodes: {
                composite: ['76'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '56',
    },
    {
        name: 'vacuum',
        categories: ['household', 'moving', 'travel-hotel'],
        terms: ['cleaning', 'dyson', 'floor', 'suck', 'suction'],
        label: 'Vacuum',
        aliases: {
            unicodes: {
                secondary: ['10e04d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04d',
    },
    {
        name: 'vacuum-robot',
        categories: ['household'],
        terms: ['cleaning', 'floor', 'roomba', 'suck', 'suction'],
        label: 'Robot Vacuum',
        aliases: {
            unicodes: {
                secondary: ['10e04e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04e',
    },
    {
        name: 'value-absolute',
        categories: ['mathematics'],
        terms: ['Math'],
        label: 'Value Absolute',
        aliases: {
            unicodes: {
                secondary: ['10f6a6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a6',
    },
    {
        name: 'van-shuttle',
        categories: ['automotive', 'transportation', 'travel-hotel'],
        terms: [],
        label: 'Van shuttle',
        aliases: {
            names: ['shuttle-van'],
            unicodes: {
                composite: ['1f690'],
                secondary: ['10f5b6'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5b6',
    },
    {
        name: 'vault',
        categories: ['business', 'money', 'security'],
        terms: [],
        label: 'Vault',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c5',
    },
    {
        name: 'vector-circle',
        categories: ['design'],
        terms: [],
        label: 'Vector Circle',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c6',
    },
    {
        name: 'vector-polygon',
        categories: ['design'],
        terms: [],
        label: 'Vector Polygon',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c7',
    },
    {
        name: 'vector-square',
        categories: ['design'],
        terms: ['anchors', 'lines', 'object', 'render', 'shape'],
        label: 'Vector Square',
        aliases: {
            unicodes: {
                secondary: ['10f5cb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5cb',
    },
    {
        name: 'vent-damper',
        categories: ['energy', 'household'],
        terms: [],
        label: 'Vent Damper',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e465',
    },
    {
        name: 'venus',
        categories: ['gender'],
        terms: ['female'],
        label: 'Venus',
        aliases: {
            unicodes: {
                composite: ['2640'],
                secondary: ['10f221'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f221',
    },
    {
        name: 'venus-double',
        categories: ['gender'],
        terms: ['female'],
        label: 'Venus Double',
        aliases: {
            unicodes: {
                composite: ['26a2'],
                secondary: ['10f226'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f226',
    },
    {
        name: 'venus-mars',
        categories: ['gender'],
        terms: ['Gender'],
        label: 'Venus Mars',
        aliases: {
            unicodes: {
                composite: ['26a4'],
                secondary: ['10f228'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f228',
    },
    {
        name: 'vest',
        categories: ['maps'],
        terms: [],
        label: 'vest',
        aliases: {
            unicodes: {
                secondary: ['10e085'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e085',
    },
    {
        name: 'vest-patches',
        categories: ['maps'],
        terms: [],
        label: 'vest-patches',
        aliases: {
            unicodes: {
                secondary: ['10e086'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e086',
    },
    {
        name: 'vial',
        categories: ['medical-health', 'science'],
        terms: ['experiment', 'lab', 'sample', 'science', 'test', 'test tube'],
        label: 'Vial',
        aliases: {
            unicodes: {
                composite: ['1f9ea'],
                secondary: ['10f492'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f492',
    },
    {
        name: 'vial-circle-check',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: [],
        label: 'Vial Circle-check',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e596',
    },
    {
        name: 'vial-virus',
        categories: ['humanitarian', 'medical-health', 'science'],
        terms: [],
        label: 'Vial Virus',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e597',
    },
    {
        name: 'vials',
        categories: ['medical-health', 'science'],
        terms: ['experiment', 'lab', 'sample', 'science', 'test', 'test tube'],
        label: 'Vials',
        aliases: {
            unicodes: {
                secondary: ['10f493'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f493',
    },
    {
        name: 'video',
        categories: ['communication', 'film-video', 'social'],
        terms: ['camera', 'film', 'movie', 'record', 'video-camera'],
        label: 'Video',
        aliases: {
            names: ['video-camera'],
            unicodes: {
                secondary: ['10f03d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f03d',
    },
    {
        name: 'video-arrow-down-left',
        categories: ['film-video'],
        terms: [],
        label: 'Video Arrow-down-left',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c8',
    },
    {
        name: 'video-arrow-up-right',
        categories: ['film-video'],
        terms: [],
        label: 'Video Arrow-up-right',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2c9',
    },
    {
        name: 'video-plus',
        categories: ['communication', 'film-video'],
        terms: ['add', 'create', 'film', 'new', 'positive', 'record', 'video'],
        label: 'Video Plus',
        aliases: {
            unicodes: {
                secondary: ['10f4e1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4e1',
    },
    {
        name: 'video-slash',
        categories: ['communication', 'film-video'],
        terms: ['add', 'create', 'film', 'new', 'positive', 'record', 'video'],
        label: 'Video Slash',
        aliases: {
            unicodes: {
                secondary: ['10f4e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4e2',
    },
    {
        name: 'vihara',
        categories: ['buildings', 'humanitarian', 'religion'],
        terms: ['buddhism', 'buddhist', 'building', 'monastery'],
        label: 'Vihara',
        aliases: {
            unicodes: {
                secondary: ['10f6a7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a7',
    },
    {
        name: 'violin',
        categories: ['music-audio'],
        terms: ['bow', 'cello', 'fiddle', 'instrument', 'music', 'orchestra', 'string'],
        label: 'Violin',
        aliases: {
            unicodes: {
                composite: ['1f3bb'],
                secondary: ['10f8ed'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ed',
    },
    {
        name: 'virus',
        categories: ['humanitarian', 'medical-health'],
        terms: ['bug', 'covid-19', 'flu', 'health', 'sick', 'viral'],
        label: 'Virus',
        aliases: {
            unicodes: {
                secondary: ['10e074'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e074',
    },
    {
        name: 'virus-covid',
        categories: ['humanitarian', 'medical-health'],
        terms: [],
        label: 'Virus Covid',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a8',
    },
    {
        name: 'virus-covid-slash',
        categories: ['medical-health'],
        terms: [],
        label: 'Virus Covid-slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e4a9',
    },
    {
        name: 'virus-slash',
        categories: ['medical-health'],
        terms: ['bug', 'covid-19', 'cure', 'eliminate', 'flu', 'health', 'sick', 'viral'],
        label: 'Virus Slash',
        aliases: {
            unicodes: {
                secondary: ['10e075'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e075',
    },
    {
        name: 'viruses',
        categories: ['medical-health'],
        terms: ['bugs', 'covid-19', 'flu', 'health', 'multiply', 'sick', 'spread', 'viral'],
        label: 'Viruses',
        aliases: {
            unicodes: {
                secondary: ['10e076'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e076',
    },
    {
        name: 'voicemail',
        categories: ['communication'],
        terms: ['answer', 'inbox', 'message', 'phone'],
        label: 'Voicemail',
        aliases: {
            unicodes: {
                secondary: ['10f897'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f897',
    },
    {
        name: 'volcano',
        categories: ['disaster', 'humanitarian', 'nature', 'weather'],
        terms: ['caldera', 'lava', 'magma', 'mountain', 'smoke'],
        label: 'Volcano',
        aliases: {
            unicodes: {
                composite: ['1f30b'],
                secondary: ['10f770'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f770',
    },
    {
        name: 'volleyball',
        categories: ['sports-fitness'],
        terms: [],
        label: 'Volleyball Ball',
        aliases: {
            names: ['volleyball-ball'],
            unicodes: {
                composite: ['1f3d0'],
                secondary: ['10f45f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f45f',
    },
    {
        name: 'volume',
        categories: ['media-playback', 'music-audio'],
        terms: ['audio', 'control', 'music', 'sound', 'speaker'],
        label: 'Volume',
        aliases: {
            names: ['volume-medium'],
            unicodes: {
                composite: ['1f509'],
                secondary: ['10f6a8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a8',
    },
    {
        name: 'volume-high',
        categories: ['media-playback', 'music-audio'],
        terms: [],
        label: 'Volume high',
        aliases: {
            names: ['volume-up'],
            unicodes: {
                composite: ['1f50a'],
                secondary: ['10f028'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f028',
    },
    {
        name: 'volume-low',
        categories: ['media-playback', 'music-audio'],
        terms: [],
        label: 'Volume low',
        aliases: {
            names: ['volume-down'],
            unicodes: {
                composite: ['1f508'],
                secondary: ['10f027'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f027',
    },
    {
        name: 'volume-off',
        categories: ['media-playback', 'music-audio'],
        terms: ['audio', 'ban', 'music', 'mute', 'quiet', 'silent', 'sound'],
        label: 'Volume Off',
        aliases: {
            unicodes: {
                secondary: ['10f026'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f026',
    },
    {
        name: 'volume-slash',
        categories: ['media-playback', 'music-audio'],
        terms: ['audio', 'ban', 'music', 'mute', 'quiet', 'silent', 'sound'],
        label: 'Volume Slash',
        aliases: {
            unicodes: {
                composite: ['1f507'],
                secondary: ['10f2e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e2',
    },
    {
        name: 'volume-xmark',
        categories: ['media-playback', 'music-audio'],
        terms: [],
        label: 'Volume X Mark',
        aliases: {
            names: ['volume-mute', 'volume-times'],
            unicodes: {
                secondary: ['10f6a9'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6a9',
    },
    {
        name: 'vr-cardboard',
        categories: ['gaming'],
        terms: ['3d', 'augment', 'google', 'reality', 'virtual'],
        label: 'Cardboard VR',
        aliases: {
            unicodes: {
                secondary: ['10f729'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f729',
    },
    {
        name: 'w',
        categories: ['alphabet'],
        terms: [],
        label: 'W',
        aliases: {
            unicodes: {
                composite: ['77'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '57',
    },
    {
        name: 'waffle',
        categories: ['food-beverage'],
        terms: [],
        label: 'Waffle',
        aliases: {
            unicodes: {
                composite: ['1f9c7'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e466',
    },
    {
        name: 'wagon-covered',
        categories: ['automotive', 'travel-hotel'],
        terms: ['contestoga', 'dysentery', 'oregon trail', 'stagecoach', 'vehicle', 'western', 'wheel'],
        label: 'Covered Wagon',
        aliases: {
            unicodes: {
                secondary: ['10f8ee'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ee',
    },
    {
        name: 'walker',
        categories: ['medical-health'],
        terms: ['accessibility', 'assistance', 'wheelchair'],
        label: 'Walker',
        aliases: {
            unicodes: {
                secondary: ['10f831'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f831',
    },
    {
        name: 'walkie-talkie',
        categories: ['communication', 'devices-hardware', 'humanitarian'],
        terms: ['communication', 'copy', 'intercom', 'over', 'portable', 'radio', 'two way radio'],
        label: 'Walkie Talkie',
        aliases: {
            unicodes: {
                secondary: ['10f8ef'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8ef',
    },
    {
        name: 'wallet',
        categories: ['business', 'money'],
        terms: ['billfold', 'cash', 'currency', 'money'],
        label: 'Wallet',
        aliases: {
            unicodes: {
                secondary: ['10f555'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f555',
    },
    {
        name: 'wand',
        categories: ['gaming', 'halloween'],
        terms: ['Dungeons & Dragons', 'd&d', 'dnd', 'fantasy', 'halloween', 'holiday', 'magic', 'weapon'],
        label: 'Wand',
        aliases: {
            unicodes: {
                secondary: ['10f72a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72a',
    },
    {
        name: 'wand-magic',
        categories: ['design', 'editing'],
        terms: ['autocomplete', 'automatic', 'fantasy', 'halloween', 'holiday', 'magic', 'weapon', 'witch', 'wizard'],
        label: 'Wand magic',
        aliases: {
            names: ['magic'],
            unicodes: {
                secondary: ['10f0d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0d0',
    },
    {
        name: 'wand-magic-sparkles',
        categories: ['design', 'editing'],
        terms: [],
        label: 'Wand magic sparkles',
        aliases: {
            names: ['magic-wand-sparkles'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ca',
    },
    {
        name: 'wand-sparkles',
        categories: ['gaming', 'halloween'],
        terms: [],
        label: 'Wand sparkles',
        aliases: {
            unicodes: {
                secondary: ['10f72b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72b',
    },
    {
        name: 'warehouse',
        categories: ['buildings', 'logistics'],
        terms: ['building', 'capacity', 'garage', 'inventory', 'storage'],
        label: 'Warehouse',
        aliases: {
            unicodes: {
                secondary: ['10f494'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f494',
    },
    {
        name: 'warehouse-full',
        categories: ['buildings', 'logistics'],
        terms: [],
        label: 'Warehouse full',
        aliases: {
            names: ['warehouse-alt'],
            unicodes: {
                secondary: ['10f495'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f495',
    },
    {
        name: 'washing-machine',
        categories: ['household', 'travel-hotel'],
        terms: [],
        label: 'Washing machine',
        aliases: {
            names: ['washer'],
            unicodes: {
                secondary: ['10f898'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f898',
    },
    {
        name: 'watch',
        categories: ['time'],
        terms: ['alert', 'clock', 'time', 'wristwatch'],
        label: 'Watch',
        aliases: {
            unicodes: {
                composite: ['231a'],
                secondary: ['10f2e1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2e1',
    },
    {
        name: 'watch-apple',
        categories: ['sports-fitness', 'time'],
        terms: [],
        label: 'Watch Apple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2cb',
    },
    {
        name: 'watch-calculator',
        categories: ['devices-hardware', 'mathematics', 'science-fiction', 'time'],
        terms: ['casio', 'digital', 'math', 'time'],
        label: 'Calculator Watch',
        aliases: {
            unicodes: {
                secondary: ['10f8f0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f0',
    },
    {
        name: 'watch-fitness',
        categories: ['medical-health', 'sports-fitness'],
        terms: ['Fitbit', 'alert', 'apple watch', 'clock', 'pedometer', 'time', 'wristwatch'],
        label: 'Watch Fitness',
        aliases: {
            unicodes: {
                secondary: ['10f63e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f63e',
    },
    {
        name: 'watch-smart',
        categories: ['time'],
        terms: [],
        label: 'Watch Smart',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2cc',
    },
    {
        name: 'water',
        categories: ['energy', 'maritime', 'nature', 'weather'],
        terms: ['lake', 'liquid', 'ocean', 'sea', 'swim', 'wet'],
        label: 'Water',
        aliases: {
            unicodes: {
                secondary: ['10f773'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f773',
    },
    {
        name: 'water-arrow-down',
        categories: ['maritime', 'weather'],
        terms: [],
        label: 'Water arrow down',
        aliases: {
            names: ['water-lower'],
            unicodes: {
                secondary: ['10f774'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f774',
    },
    {
        name: 'water-arrow-up',
        categories: ['maritime', 'weather'],
        terms: [],
        label: 'Water arrow up',
        aliases: {
            names: ['water-rise'],
            unicodes: {
                secondary: ['10f775'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f775',
    },
    {
        name: 'water-ladder',
        categories: ['travel-hotel'],
        terms: [],
        label: 'Water ladder',
        aliases: {
            names: ['ladder-water', 'swimming-pool'],
            unicodes: {
                secondary: ['10f5c5'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5c5',
    },
    {
        name: 'watermelon-slice',
        categories: ['fruits-vegetables'],
        terms: [],
        label: 'Watermelon Slice',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e337',
    },
    {
        name: 'wave-pulse',
        categories: ['medical-health', 'sports-fitness'],
        terms: [],
        label: 'Wave Pulse',
        aliases: {
            names: ['heart-rate'],
            unicodes: {
                secondary: ['10f5f8'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5f8',
    },
    {
        name: 'wave-sine',
        categories: ['mathematics'],
        terms: ['frequency', 'pulse', 'signal'],
        label: 'Sine Wave',
        aliases: {
            unicodes: {
                composite: ['223f'],
                secondary: ['10f899'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f899',
    },
    {
        name: 'wave-square',
        categories: ['mathematics'],
        terms: ['frequency', 'pulse', 'signal'],
        label: 'Square Wave',
        aliases: {
            unicodes: {
                secondary: ['10f83e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f83e',
    },
    {
        name: 'wave-triangle',
        categories: ['mathematics'],
        terms: ['frequency', 'pulse', 'signal'],
        label: 'Triangle Wave',
        aliases: {
            unicodes: {
                secondary: ['10f89a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f89a',
    },
    {
        name: 'waveform',
        categories: ['film-video', 'music-audio'],
        terms: ['amplitude', 'sound', 'soundwave'],
        label: 'Waveform',
        aliases: {
            unicodes: {
                secondary: ['10f8f1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f1',
    },
    {
        name: 'waveform-lines',
        categories: ['film-video', 'music-audio'],
        terms: [],
        label: 'Waveform lines',
        aliases: {
            names: ['waveform-path'],
            unicodes: {
                secondary: ['10f8f2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f8f2',
    },
    {
        name: 'weight-hanging',
        categories: ['sports-fitness'],
        terms: ['anvil', 'heavy', 'measurement'],
        label: 'Hanging Weight',
        aliases: {
            unicodes: {
                secondary: ['10f5cd'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5cd',
    },
    {
        name: 'weight-scale',
        categories: ['medical-health'],
        terms: [],
        label: 'Weight scale',
        aliases: {
            names: ['weight'],
            unicodes: {
                secondary: ['10f496'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f496',
    },
    {
        name: 'whale',
        categories: ['animals', 'maritime'],
        terms: ['fauna', 'mammal', 'swimming'],
        label: 'Whale',
        aliases: {
            unicodes: {
                composite: ['1f40b'],
                secondary: ['10f72c'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72c',
    },
    {
        name: 'wheat',
        categories: ['food-beverage', 'fruits-vegetables'],
        terms: ['argriculture', 'fall', 'farming', 'grain', 'seasonal'],
        label: 'Wheat',
        aliases: {
            unicodes: {
                secondary: ['10f72d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72d',
    },
    {
        name: 'wheat-awn',
        categories: ['food-beverage', 'humanitarian'],
        terms: [],
        label: 'Wheat awn',
        aliases: {
            names: ['wheat-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2cd',
    },
    {
        name: 'wheat-awn-circle-exclamation',
        categories: ['disaster', 'food-beverage', 'humanitarian'],
        terms: [],
        label: 'Wheat Awn-circle-exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e598',
    },
    {
        name: 'wheat-awn-slash',
        categories: ['food-beverage'],
        terms: [],
        label: 'Wheat Awn Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e338',
    },
    {
        name: 'wheat-slash',
        categories: ['food-beverage'],
        terms: [],
        label: 'Wheat Slash',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e339',
    },
    {
        name: 'wheelchair',
        categories: ['accessibility', 'maps', 'medical-health', 'transportation', 'travel-hotel', 'users-people'],
        terms: ['accessible', 'handicap', 'person'],
        label: 'Wheelchair',
        aliases: {
            unicodes: {
                secondary: ['10f193'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f193',
    },
    {
        name: 'wheelchair-move',
        categories: [
            'accessibility',
            'humanitarian',
            'maps',
            'medical-health',
            'transportation',
            'travel-hotel',
            'users-people',
        ],
        terms: [],
        label: 'Wheelchair Move',
        aliases: {
            names: ['wheelchair-alt'],
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2ce',
    },
    {
        name: 'whiskey-glass',
        categories: ['food-beverage'],
        terms: [],
        label: 'Whiskey glass',
        aliases: {
            names: ['glass-whiskey'],
            unicodes: {
                composite: ['1f943'],
                secondary: ['10f7a0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a0',
    },
    {
        name: 'whiskey-glass-ice',
        categories: ['food-beverage'],
        terms: [],
        label: 'Whiskey glass ice',
        aliases: {
            names: ['glass-whiskey-rocks'],
            unicodes: {
                secondary: ['10f7a1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7a1',
    },
    {
        name: 'whistle',
        categories: ['music-audio', 'security', 'sports-fitness'],
        terms: ['alert', 'single', 'sound', 'toot'],
        label: 'Whistle',
        aliases: {
            unicodes: {
                secondary: ['10f460'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f460',
    },
    {
        name: 'wifi',
        categories: ['connectivity', 'humanitarian', 'maps', 'toggle', 'travel-hotel'],
        terms: ['connection', 'hotspot', 'internet', 'network', 'wireless'],
        label: 'WiFi',
        aliases: {
            names: ['wifi-3', 'wifi-strong'],
            unicodes: {
                secondary: ['10f1eb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f1eb',
    },
    {
        name: 'wifi-exclamation',
        categories: ['alert', 'connectivity', 'toggle'],
        terms: [],
        label: 'Wifi Exclamation',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2cf',
    },
    {
        name: 'wifi-fair',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Wifi fair',
        aliases: {
            names: ['wifi-2'],
            unicodes: {
                secondary: ['10f6ab'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ab',
    },
    {
        name: 'wifi-slash',
        categories: ['toggle', 'travel-hotel'],
        terms: ['ban', 'broken', 'connection', 'disabled', 'hotspot', 'internet', 'network', 'unavailable', 'wireless'],
        label: 'Wifi Slash',
        aliases: {
            unicodes: {
                secondary: ['10f6ac'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ac',
    },
    {
        name: 'wifi-weak',
        categories: ['connectivity', 'toggle'],
        terms: [],
        label: 'Wifi weak',
        aliases: {
            names: ['wifi-1'],
            unicodes: {
                secondary: ['10f6aa'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6aa',
    },
    {
        name: 'wind',
        categories: ['disaster', 'energy', 'humanitarian', 'nature', 'weather'],
        terms: ['air', 'blow', 'breeze', 'fall', 'seasonal', 'weather'],
        label: 'Wind',
        aliases: {
            unicodes: {
                secondary: ['10f72e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72e',
    },
    {
        name: 'wind-turbine',
        categories: ['energy'],
        terms: ['electricity', 'energy', 'generator', 'renewable', 'wind power', 'windmill'],
        label: 'Wind Turbine',
        aliases: {
            unicodes: {
                secondary: ['10f89b'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f89b',
    },
    {
        name: 'wind-warning',
        categories: ['alert', 'disaster', 'weather'],
        terms: ['air', 'alert', 'blow', 'breeze', 'fall', 'hurricane', 'seasonal', 'tornado', 'weather'],
        label: 'Wind Warning',
        aliases: {
            names: ['wind-circle-exclamation'],
            unicodes: {
                secondary: ['10f776'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f776',
    },
    {
        name: 'window',
        categories: ['coding'],
        terms: ['browser', 'computer', 'development'],
        label: 'Window',
        aliases: {
            unicodes: {
                secondary: ['10f40e'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f40e',
    },
    {
        name: 'window-flip',
        categories: ['coding'],
        terms: [],
        label: 'Window flip',
        aliases: {
            names: ['window-alt'],
            unicodes: {
                secondary: ['10f40f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f40f',
    },
    {
        name: 'window-frame',
        categories: ['household'],
        terms: ['door', 'glass', 'pane', 'view', 'window'],
        label: 'House Window',
        aliases: {
            unicodes: {
                composite: ['1fa9f'],
                secondary: ['10e04f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e04f',
    },
    {
        name: 'window-frame-open',
        categories: ['household'],
        terms: ['door', 'glass', 'pane', 'view', 'window'],
        label: 'House Window Open',
        aliases: {
            unicodes: {
                secondary: ['10e050'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e050',
    },
    {
        name: 'window-maximize',
        categories: ['coding'],
        terms: ['browser', 'computer', 'development', 'expand'],
        label: 'Window Maximize',
        aliases: {
            unicodes: {
                composite: ['1f5d6'],
                secondary: ['10f2d0'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2d0',
    },
    {
        name: 'window-minimize',
        categories: ['coding'],
        terms: ['browser', 'collapse', 'computer', 'development'],
        label: 'Window Minimize',
        aliases: {
            unicodes: {
                composite: ['1f5d5'],
                secondary: ['10f2d1'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2d1',
    },
    {
        name: 'window-restore',
        categories: ['coding'],
        terms: ['browser', 'computer', 'development'],
        label: 'Window Restore',
        aliases: {
            unicodes: {
                secondary: ['10f2d2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f2d2',
    },
    {
        name: 'windsock',
        categories: ['weather'],
        terms: ['air', 'blow', 'breeze', 'fall', 'seasonal', 'weather'],
        label: 'Windsock',
        aliases: {
            unicodes: {
                secondary: ['10f777'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f777',
    },
    {
        name: 'wine-bottle',
        categories: ['food-beverage'],
        terms: ['alcohol', 'beverage', 'cabernet', 'drink', 'glass', 'grapes', 'merlot', 'sauvignon'],
        label: 'Wine Bottle',
        aliases: {
            unicodes: {
                secondary: ['10f72f'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f72f',
    },
    {
        name: 'wine-glass',
        categories: ['food-beverage', 'maps', 'moving', 'travel-hotel'],
        terms: ['alcohol', 'beverage', 'cabernet', 'drink', 'grapes', 'merlot', 'sauvignon'],
        label: 'Wine Glass',
        aliases: {
            unicodes: {
                composite: ['1f377'],
                secondary: ['10f4e3'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4e3',
    },
    {
        name: 'wine-glass-crack',
        categories: ['moving'],
        terms: [],
        label: 'Wine glass crack',
        aliases: {
            names: ['fragile'],
            unicodes: {
                secondary: ['10f4bb'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f4bb',
    },
    {
        name: 'wine-glass-empty',
        categories: ['food-beverage', 'travel-hotel'],
        terms: [],
        label: 'Wine glass empty',
        aliases: {
            names: ['wine-glass-alt'],
            unicodes: {
                secondary: ['10f5ce'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f5ce',
    },
    {
        name: 'wizards-of-the-coast',
        categories: ['gaming'],
        terms: [],
        label: 'Wizards of the Coast',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f730',
    },
    {
        name: 'won-sign',
        categories: ['money'],
        terms: ['currency', 'krw', 'money'],
        label: 'Won Sign',
        aliases: {
            names: ['krw', 'won'],
            unicodes: {
                composite: ['20a9'],
                secondary: ['10f159'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f159',
    },
    {
        name: 'worm',
        categories: ['animals', 'disaster', 'humanitarian', 'nature'],
        terms: [],
        label: 'Worm',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e599',
    },
    {
        name: 'wreath',
        categories: ['holidays', 'spinners'],
        terms: ['christmas', 'decoration', 'door', 'holiday', 'welcome', 'xmas'],
        label: 'Wreath',
        aliases: {
            unicodes: {
                secondary: ['10f7e2'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f7e2',
    },
    {
        name: 'wrench',
        categories: ['construction', 'maps'],
        terms: ['construction', 'fix', 'mechanic', 'plumbing', 'settings', 'spanner', 'tool', 'update'],
        label: 'Wrench',
        aliases: {
            unicodes: {
                composite: ['1f527'],
                secondary: ['10f0ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f0ad',
    },
    {
        name: 'wrench-simple',
        categories: ['coding', 'construction', 'design'],
        terms: [],
        label: 'Wrench Simple',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e2d1',
    },
    {
        name: 'x',
        categories: ['alphabet'],
        terms: [],
        label: 'X',
        aliases: {
            unicodes: {
                composite: ['78'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '58',
    },
    {
        name: 'x-ray',
        categories: ['medical-health'],
        terms: ['health', 'medical', 'radiological images', 'radiology', 'skeleton'],
        label: 'X-Ray',
        aliases: {
            unicodes: {
                secondary: ['10f497'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f497',
    },
    {
        name: 'xbox',
        categories: ['gaming'],
        terms: [],
        label: 'Xbox',
        aliases: {},
        styles: [IconStyle.BRANDS],
        unicode: 'f412',
    },
    {
        name: 'xmark',
        categories: ['editing', 'mathematics'],
        terms: [],
        label: 'X Mark',
        aliases: {
            names: ['close', 'multiply', 'remove', 'times'],
            unicodes: {
                composite: ['1f5d9', '2715', '2716', '274c', 'd7'],
                secondary: ['10f00d'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f00d',
    },
    {
        name: 'xmark-large',
        categories: ['editing'],
        terms: [],
        label: 'Xmark Large',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e59b',
    },
    {
        name: 'xmark-to-slot',
        categories: ['political'],
        terms: [],
        label: 'X Mark to Slot',
        aliases: {
            names: ['times-to-slot', 'vote-nay'],
            unicodes: {
                secondary: ['10f771'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f771',
    },
    {
        name: 'xmarks-lines',
        categories: ['disaster', 'humanitarian', 'logistics'],
        terms: [],
        label: 'Xmarks Lines',
        aliases: {},
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'e59a',
    },
    {
        name: 'y',
        categories: ['alphabet'],
        terms: [],
        label: 'Y',
        aliases: {
            unicodes: {
                composite: ['79'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '59',
    },
    {
        name: 'yen-sign',
        categories: ['money'],
        terms: ['currency', 'jpy', 'money'],
        label: 'Yen Sign',
        aliases: {
            names: ['cny', 'jpy', 'rmb', 'yen'],
            unicodes: {
                composite: ['a5'],
                secondary: ['10f157'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f157',
    },
    {
        name: 'yin-yang',
        categories: ['religion', 'spinners'],
        terms: ['daoism', 'opposites', 'taoism'],
        label: 'Yin Yang',
        aliases: {
            unicodes: {
                composite: ['262f'],
                secondary: ['10f6ad'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: 'f6ad',
    },
    {
        name: 'youtube',
        categories: ['film-video'],
        terms: [],
        label: 'YouTube',
        aliases: {
            unicodes: {
                composite: ['f16a'],
            },
        },
        styles: [IconStyle.BRANDS],
        unicode: 'f167',
    },
    {
        name: 'z',
        categories: ['alphabet'],
        terms: [],
        label: 'Z',
        aliases: {
            unicodes: {
                composite: ['7a'],
            },
        },
        styles: [IconStyle.SOLID, IconStyle.REGULAR, IconStyle.LIGHT, IconStyle.THIN, IconStyle.DUOTONE],
        unicode: '5a',
    },
];

export default icons;
