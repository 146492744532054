import backend, { BackendApi } from './BackendApi';

export default class BackendService {
    constructor() {
        this._backend = backend;
    }

    requestApi(url, method, data = undefined) {
        return this._backend.requestApi(url, method, data);
    }

    getApiUrl(url, params) {
        return this._backend.getApiUrl(url, params);
    }

    static getError(xhr) {
        return BackendApi.getError(xhr);
    }

    static getResolvedPromise(value) {
        const promise = Promise.resolve(value);
        promise.instant = true;
        return promise;
    }

    static getRejectedPromise(reason) {
        const promise = Promise.reject(reason);
        promise.instant = true;
        return promise;
    }

    static isInstantPromise(promise) {
        return promise.instant === true;
    }
}
