import React from 'react';
import { accountsManager } from '../../service/AccountsManager';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

class AccountClearCache extends React.Component {
    submit() {
        const { onSubmitSuccess, onSubmitError, account, enqueueSnackbar } = this.props;
        accountsManager
            .clearCache(account.id)
            .then((data) => {
                enqueueSnackbar(this.props.t('account.cache.clear.completed', { count: data.count }), {
                    variant: 'success',
                });
                onSubmitSuccess && onSubmitSuccess();
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
                onSubmitError && onSubmitError();
            });
    }

    render() {
        return (
            <form noValidate autoComplete="off">
                {this.props.t('account.cache.clear.confirm_message')}
            </form>
        );
    }
}

AccountClearCache.propTypes = {
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    account: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(AccountClearCache));
