import React from 'react';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import './style.css';
import PropTypes from 'prop-types';
import entityViewConfigManager from '../../service/EntityViewConfigManager';
import { FormControl, FormGroup, FormLabel, Grid, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import './style.css';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';
import AddIconButton from '../CustomButton/AddIconButton';
import AdditionalPropertiesControl from './AdditionalPropertiesControl';
import { TABLE_VIEW_PAGE_SIZES } from 'components/constants';
import { userManager } from 'service/UserManager';
import mapStateManagerFactory from 'service/MapStateManagerFactory';
import SearchableFieldsListDialog from './SearchableFieldsListDialog';
import { weAreInNativeApp } from '../../utils';
import { STANDARD_FIELDS } from '../../standardFields';
import EditDefaultValuesBlock from '../EditDefaultValuesBlock/EditDefaultValuesBlock';

const DragHandle = SortableHandle(() => <Icon className="table-view-form__field__draggable_icon">drag_indicator</Icon>);

const SortableItem = SortableElement((props) => (
    <div className="table-view-form__field">
        <DragHandle />
        <span
            style={{
                padding: 12,
                fontSize: '0.875rem',
                lineHeight: '1.5em',
            }}
        >
            {props.item.label}
        </span>
        <IconButton
            className={'map-view-form__field__trash-icon'}
            component="span"
            onClick={() => props.onFieldRemove(props.item)}
            data-testid="teble_view_form.remove_field"
        >
            <i
                style={{
                    fontSize: '16px',
                    display: 'inline-block',
                    width: '16px',
                    height: '16px',
                    color: red[500],
                }}
                className={'fas fa-trash'}
            />
        </IconButton>
    </div>
));

const SortableList = SortableContainer((props) => {
    return (
        <FormGroup style={{ backgroundColor: '#f3f3f3' }}>
            {props.items.map((item, index) => (
                <SortableItem key={item.name} index={index} item={item} onFieldRemove={props.onFieldRemove} />
            ))}
        </FormGroup>
    );
});

const sortByIncluded = (a, b) => {
    if (a.included === b.included) {
        return 0;
    }
    return a.included ? -1 : 1;
};
const sortNotIncludedAlphabetically = (a, b) => {
    if (a.included || b.included) {
        return 0;
    }
    return a.label.localeCompare(b.label);
};

class TableViewForm extends React.Component {
    constructor(props) {
        super(props);

        this.pageSizeValues = TABLE_VIEW_PAGE_SIZES;

        this.state = {
            fieldsListModal: false,
            errors: new Map(),
            view: this.getTableView(),
        };
    }

    getTableView = () => {
        let tableView = null;
        if (this.props.entity) {
            if (!this.props.personalSettings && this.props.entity.tableView) {
                tableView = this.props.entity.tableView;
            }
            if (this.props.personalSettings && this.props.entity.personalTableView) {
                tableView = this.props.entity.personalTableView;
            }
        }

        if (tableView === null) {
            return null;
        }

        return this.tableViewToStateView(tableView);
    };

    tableViewToStateView(tableView) {
        return {
            fields: this.getSortableCollection(tableView),
            hideMapslyFields: tableView.hideMapslyFields,
            pageSize: tableView.pageSize,
        };
    }

    static stateViewToTableView(view) {
        return {
            fields: TableViewForm.getStoredCollection(view.fields),
            hideMapslyFields: view.hideMapslyFields,
            pageSize: view.pageSize,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entity !== this.props.entity || prevProps.personalSettings !== this.props.personalSettings) {
            this.setState({
                view: this.getTableView(),
            });
        }
    }

    componentDidMount() {
        if (this.state.view === null && this.props.entity) {
            this.loadView();
        }
    }

    loadView() {
        return entityViewConfigManager.loadDefaultTableConfig(this.props.entity.id).then((tableView) => {
            this.setState({
                view: this.tableViewToStateView(tableView),
            });
        });
    }

    submit() {
        this.setState({
            errors: new Map(),
        });

        const tableView = TableViewForm.stateViewToTableView(this.state.view);

        if (this.props.personalSettings) {
            const user = userManager.getCurrentUser();
            const mapStateManager = mapStateManagerFactory.getManager(user.id);
            mapStateManager.setTableViewPageSize(this.props.entity.id, this.state.view.pageSize);
        }

        const { onSubmitSuccess, onSubmitError, onSaveRequest } = this.props;

        if (!onSaveRequest) {
            if (onSubmitSuccess) {
                onSubmitSuccess(tableView);
            }
            return;
        }

        onSaveRequest(tableView)
            .then(() => {
                if (onSubmitSuccess) {
                    onSubmitSuccess(tableView);
                }
            })
            .catch((error) => {
                this.setState({
                    errors: error.details,
                });
                if (onSubmitError) {
                    onSubmitError(error.message);
                }
            });
    }

    quiet() {
        this.setState({
            errors: new Map(),
        });
    }

    static getStoredCollection(sortableCollection) {
        const result = [];
        for (let item of sortableCollection) {
            if (item.included) {
                result.push(item.name);
            }
        }
        return result;
    }

    getEntityFields(tableView) {
        const isSystem = this.props.entity.isSystem;
        return this.props.entity.fields.filter((field) => {
            if (tableView.hideMapslyFields && field.isMapslyField && field.apiName !== STANDARD_FIELDS.ID) {
                return false;
            }
            return field.isIncluded && (!isSystem || field.isVisible);
        });
    }

    getFieldLabel(tableView, apiName) {
        const item = this.getEntityFields(tableView).find((field) => field.apiName === apiName);
        if (!item) {
            return null;
        }
        return item.label;
    }

    getSortableCollection(tableView) {
        const storedCollection = tableView.fields;
        const result = [];
        if (storedCollection) {
            for (let name of storedCollection) {
                const label = this.getFieldLabel(tableView, name);
                if (label === null) {
                    continue;
                }
                result.push({
                    included: true,
                    name: name,
                    label: label,
                });
            }
        }
        for (let field of this.sortByAlphabet(this.getEntityFields(tableView))) {
            if (field.isDeleted) {
                continue;
            }
            if (!storedCollection || storedCollection.indexOf(field.apiName) === -1) {
                result.push({
                    included: false,
                    name: field.apiName,
                    label: field.label,
                });
            }
        }
        return result;
    }

    sortByAlphabet(entityFields) {
        const entityFieldsCopy = cloneDeep(entityFields);
        entityFieldsCopy.sort((a, b) => a.label.localeCompare(b.label));
        return entityFieldsCopy;
    }

    checkAll = (checked) => {
        this.setState(
            (state) => {
                for (let item of state.view.fields) {
                    item.included = checked;
                }
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    handleFieldIncludedChange = (event) => {
        const checked = event.target.checked;
        const name = event.target.value;
        this.setState(
            (state) => {
                state.view.fields.find((item) => item.name === name).included = checked;
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(
            (state) => {
                state.view.fields = arrayMove(state.view.fields, oldIndex, newIndex);
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    handlePageSizeChange = (event) => {
        const pageSize = event.target.value;
        this.setState(
            (state) => {
                state.view.pageSize = pageSize;
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    onChangedView = () => {
        this.props.onChangedView && this.props.onChangedView(TableViewForm.stateViewToTableView(this.state.view));
    };

    handleOpenFieldsListModal = () => {
        this.setState({
            fieldsListModal: true,
        });
    };

    handleFieldsListModalSave = (fields) => {
        this.setState(
            (state) => {
                let collection = cloneDeep(state.view.fields);
                for (let field of fields) {
                    const item = collection.find((item) => item.name === field.name);
                    item.included = field.included;
                }
                collection.sort(sortByIncluded);
                state.view.fields = collection;
                state.fieldsListModal = false;
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    handleFieldsListModalCancel = () => {
        this.setState({
            fieldsListModal: false,
        });
    };

    handleOpenPersonalTableViewSettings = () => {
        this.props.onOpenPersonalTableViewSettings && this.props.onOpenPersonalTableViewSettings();
    };

    onFieldRemove = (item) => {
        this.setState(
            (state) => {
                let collection = cloneDeep(state.view.fields);
                collection.find((field) => field.name === item.name).included = false;
                collection.sort(sortByIncluded);
                state.view.fields = collection;
                return state;
            },
            () => {
                this.onChangedView();
            },
        );
    };

    render() {
        const { personalSettings, refContent, t } = this.props;

        const { view, fieldsListModal } = this.state;
        if (view === null) {
            return t('loading');
        }
        let fieldsListToSearch = view.fields ? cloneDeep(view.fields) : [];
        fieldsListToSearch.sort(sortByIncluded).sort(sortNotIncludedAlphabetically);

        const fieldsList = view.fields.filter((field) => field.included);

        return (
            <div className="c-table-view-form">
                {!personalSettings && (
                    <EditDefaultValuesBlock
                        mainText={t('table_view_form.default_properties.warning')}
                        dottedText={t('table_view_form.default_properties.switch')}
                        onSwitch={this.handleOpenPersonalTableViewSettings}
                        articleNumber={'4361112'}
                    />
                )}
                <Grid container alignItems="center">
                    <Grid container item xs alignItems="center" wrap="nowrap">
                        <Grid item>
                            <FormLabel component="legend">{t('table_view_form.fields_to_show')}</FormLabel>
                        </Grid>
                        <Grid item>
                            &nbsp;
                            <Tooltip title={t('table_view_form.add_remove_fields')}>
                                <AddIconButton
                                    small
                                    onClick={this.handleOpenFieldsListModal}
                                    className="c-table-view-form__fields_list_button"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {this.props.linkToSetting && !weAreInNativeApp() && (
                        <Grid container item xs>
                            <Grid item>
                                <i style={{ color: 'blue', marginRight: '8px' }} className="fas fa-info-circle" />
                            </Grid>
                            <Grid item xs>
                                <span>{t('table_view_form.add_fields.question')}</span>
                                <br />
                                <Link style={{ textDecoration: 'none' }} to={this.props.linkToSetting}>
                                    <span style={{ textDecoration: 'underline' }}>
                                        {t('table_view_form.add_fields.link')}
                                    </span>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <FormControl component="fieldset">
                    {!!fieldsList.length ? (
                        !!refContent.current && (
                            <SortableList
                                helperClass="sortable-helper"
                                hideSortableGhost={false}
                                items={fieldsList}
                                onFieldRemove={this.onFieldRemove}
                                onSortEnd={this.handleSortEnd}
                                onIncludedChange={this.handleFieldIncludedChange}
                                useDragHandle={true}
                                getContainer={() => refContent.current}
                            />
                        )
                    ) : (
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ textAlign: 'center', paddingTop: 15 }}
                        >
                            {t('table_view_form.no_fields_selected')}
                        </Typography>
                    )}
                </FormControl>
                <br />
                <br />

                <AdditionalPropertiesControl
                    value={view.pageSize}
                    values={this.pageSizeValues}
                    onChange={this.handlePageSizeChange}
                />

                {fieldsListModal && (
                    <SearchableFieldsListDialog
                        fields={fieldsListToSearch}
                        onSave={this.handleFieldsListModalSave}
                        onCancel={this.handleFieldsListModalCancel}
                    />
                )}
            </div>
        );
    }
}

TableViewForm.propTypes = {
    entity: PropTypes.object,
    onSubmitSuccess: PropTypes.func,
    personalSettings: PropTypes.bool,
    onSubmitError: PropTypes.func,
    onSaveRequest: PropTypes.func,
    onChangedView: PropTypes.func,
    onOpenPersonalTableViewSettings: PropTypes.func,
    linkToSetting: PropTypes.string,
};

TableViewForm.default = {
    personalSettings: false,
    linkToSetting: null,
};

export default withTranslation('translations', { withRef: true })(TableViewForm);
