import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import MUIAutocomplete, { AutocompleteProps as MUIAutocompleteProps } from '@material-ui/lab/Autocomplete';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import TextField from '../TextField/TextField';
import IconButton from '../IconButton/IconButton';

const autocompleteStyles = () =>
    createStyles({
        root: {
            '& .MuiFormControl-root.textField .MuiAutocomplete-inputRoot': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 0,
                '& .MuiAutocomplete-input': {
                    padding: '6px 0px',
                },
                '& .MuiIconButton-root': {
                    padding: 0,
                },
            },
        },
    });

type AutocompleteProps = MUIAutocompleteProps<any, any, any, any> & WithStyles<typeof autocompleteStyles> & {};

interface AutocompleteState {
    open: boolean;
}

class Autocomplete extends React.PureComponent<AutocompleteProps, AutocompleteState> {
    constructor(props: AutocompleteProps) {
        super(props);

        this.state = {
            open: false,
        };
    }
    onChangeOpen = (value: boolean) => () => {
        this.setState({
            open: value,
        });
    };

    onChange: AutocompleteProps['onChange'] = (...arg) => {
        if (this.props.onChange) {
            this.props.onChange(...arg);
        }

        this.onChangeOpen(false)();
    };

    onInputChange = (event: ChangeEvent<{}>) => {
        if (!this.state.open && event) {
            this.onChangeOpen(true)();
        }
    };

    render() {
        const { className, classes, placeholder, ...props } = this.props;

        return (
            <MUIAutocomplete
                {...props}
                onChange={this.onChange}
                onInputChange={this.onInputChange}
                className={clsx('autocomplete', classes.root, className)}
                open={this.state.open}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...(params?.InputProps || {}),
                                endAdornment: (
                                    <IconButton
                                        onClick={this.onChangeOpen(!this.state.open)}
                                        color="secondary"
                                        size="medium"
                                    >
                                        {this.state.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                ),
                                onBlur: this.onChangeOpen(false),
                                onFocus: this.onChangeOpen(true),
                            }}
                            placeholder={placeholder}
                        />
                    );
                }}
            />
        );
    }
}

export default withStyles(autocompleteStyles)(Autocomplete);
