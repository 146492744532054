export enum LogRocketEvent {
    SHARED_MAP = 'Shared map',
    SWITCH_USER = 'Switch user',
    APPFLOW = 'Appflow',
}

/**
 * The 'revenue' property is a special property that you can use to pass data into our funnels
 * @link https://docs.logrocket.com/docs/revenue-insights-for-funnels
 */
export type LogRocketEventProperties = {
    revenue?: number;
    [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};
