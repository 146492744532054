import BackendService from './BackendService';
import { BackendApi } from './BackendApi';
import dispatcher from 'service/dispatcher';
import i18n from 'i18next';

const DEFAULT_TIMEOUT = 60 * 1000;

type StoredPromise = {
    resolve: (arg0?: any) => void;
    reject: (arg0?: any) => void;
    timerId?: number;
};

// todo: make it more general
// todo: extend Promise with "cancelled" flag and ignore resolve/reject in caller's code
export default class AsyncBackendService extends BackendService {
    protected requests: Map<string, StoredPromise>;

    constructor(asyncResponseEvent: string) {
        super();

        this.requests = new Map();

        dispatcher.subscribe(asyncResponseEvent, this, this.processResponse);
    }

    protected getTimeout(_url: string, _method: string, _data?: any) {
        return DEFAULT_TIMEOUT;
    }

    processResponse = (response: any) => {
        const { messageId }: { messageId?: string } = response;
        if (messageId === undefined) {
            return;
        }

        const { resolve, timerId } = this.requests.get(messageId) ?? {};

        this.requests.delete(messageId);

        window.clearTimeout(timerId);
        if (typeof resolve === 'function') {
            resolve(response);
        }
    };

    async requestApiAsync(url: string, method: string, data?: any, timeout?: number) {
        const waitForResponseMessageTimeout = timeout ?? this.getTimeout(url, method, data);
        return new Promise((resolve, reject) => {
            this.requestApi(url, method, data)
                .then((response: any) => {
                    const { messageId }: { messageId?: string } = response;
                    if (messageId === undefined) {
                        resolve(response);
                        return;
                    }

                    const { reject: oldReject, timerId: oldTimerId } = this.requests.get(messageId) ?? {};

                    window.clearTimeout(oldTimerId);

                    const timerId =
                        waitForResponseMessageTimeout === undefined
                            ? undefined
                            : window.setTimeout(() => {
                                  const timeoutReject = this.requests.get(messageId)?.reject;
                                  if (timeoutReject === reject) {
                                      // worker too busy or queue is not processing
                                      timeoutReject(
                                          BackendApi.createError({
                                              code: 408,
                                              message: i18n.t('http.errors.connection_timeout'),
                                          }),
                                      );
                                      this.requests.delete(messageId);
                                  }
                              }, waitForResponseMessageTimeout);

                    this.requests.set(messageId, { resolve, reject, timerId });

                    if (typeof oldReject === 'function') {
                        // extremely rare, reject previous request with the same message id (UUID)
                        oldReject(BackendApi.createError({ code: 423, message: i18n.t('http.errors.internal_error') }));
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}
