import dispatcher from '../dispatcher';
import events from '../../events';
import FormFactory from './forms/FormFactory';
import BaseFormManager from './BaseFormManager';
import { userManager } from '../UserManager';
import Button from './forms/fields/Button';
import geoLocationManager from '../GeoLocationManager';
import QueueHandler from '../EventsQueue/QueueHandler';
import SubmitForm from '../EventsQueue/Events/SubmitForm';

class FormManager extends BaseFormManager {
    constructor() {
        super();
        this.form = null;

        const formFactory = new FormFactory();

        dispatcher.subscribe(events.WS_OPEN_FORM, this, (payload) => {
            console.log(payload);
            this.form = formFactory.createForm(payload);
            this.refresh();
        });

        dispatcher.subscribe(events.WS_UPDATE_FORM, this, (payload) => {
            this.form = formFactory.updateForm(this.form, payload);
            this.refresh();
        });

        dispatcher.subscribe(events.WS_CLOSE_FORM, this, () => {
            this.form = null;
            this.refresh();
        });
    }

    refresh() {
        dispatcher.dispatch(events.WORKFLOW_ACTIONS_FORM_REFRESHED, {});
    }

    getForm() {
        return this.form;
    }

    closeForm() {
        this.form = null;
        this.refresh();
    }

    handleFieldHandler(field, formContextData = {}) {
        const ids = [];
        const form = this.form.serializeWithApiNames(formContextData);
        form.close = false;
        const triggerEventData = {
            fieldApiName: field.apiName,
            fieldLabel: field.label,
            eventType: 'onChange',
        };

        for (let handler of field.getHandlers()) {
            ids.push(handler.id);
        }

        let promise = Promise.resolve({});
        if (field instanceof Button) {
            if (field.getIsActionButton()) {
                form.close = true;
                triggerEventData.eventType = 'onClick';
            }

            if (field.isUserLocationAvailable()) {
                promise = geoLocationManager.getLocationFull().then((userLocation) => ({ userLocation }));
            }
        }

        if (ids.length === 0) {
            return;
        }

        promise.then((context) => {
            return QueueHandler.addEvent(
                new SubmitForm(
                    userManager.getCurrentAccount().id,
                    this.form.getFormActionId(),
                    ids,
                    this.form.getEntityId(),
                    {
                        form: form,
                        oldValue: field.getOldValue(),
                        newValue: field.getValue(),
                        ...context,
                    },
                    this.form.getContextGuid(),
                    triggerEventData,
                    {
                        formLabel: this.form.title,
                        buttonLabel:
                            this.form.getActionButtons().length > 0 ? this.form.getActionButtons()[0].label : '',
                    },
                ),
            );
        });
    }

    handleFieldChange(field, value) {
        field.setOldValue(field.getValue());
        field.setValue(value);
        this.refresh();
    }
}

export default new FormManager();
