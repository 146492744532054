import React from 'react';
import debounce from 'lodash/debounce';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    TextField,
} from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SearchableField } from 'components/TableViewForm/types';

const MIN_SEARCH_LENGTH_TO_HANDLE = 2;

type FieldExtended = SearchableField & {
    nameLowerCase: string;
    labelLowerCase: string;
    included: boolean;
};

interface Props extends WithTranslation {
    fields: SearchableField[];
    onSubmitSuccess?: (fields: FieldExtended[]) => void;
    disableStyling: boolean;
}

interface States {
    search: string;
    searchProcessing: boolean;
    foundFields: FieldExtended[];
    fields: FieldExtended[];
}

class SearchableFieldsListForm extends React.Component<Props, States> {
    static defaultProps = {
        disableStyling: false,
    };

    private readonly handleSearchBounce: (search: string) => void;

    constructor(props: Props) {
        super(props);

        const fields = this.props.fields.map((field) => {
            return {
                ...field,
                nameLowerCase: field.name.toLowerCase(),
                labelLowerCase: field.label.toLowerCase(),
            };
        });

        this.state = {
            fields,
            foundFields: [],
            search: '',
            searchProcessing: false,
        };

        this.handleSearchBounce = debounce(this.handleSearch, 500);
    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let search = event.currentTarget.value;
        this.setState(
            {
                search,
                searchProcessing: true,
            },
            () => {
                this.handleSearchBounce(search);
            },
        );
    };

    handleSearch = (search: string) => {
        if (search !== this.state.search) {
            return;
        }
        let foundFields: FieldExtended[] = [];
        if (search.length >= MIN_SEARCH_LENGTH_TO_HANDLE) {
            foundFields = this.state.fields.filter((field) => {
                let lowerSearch = search.toLowerCase();
                return field.labelLowerCase.includes(lowerSearch) || field.nameLowerCase.includes(lowerSearch);
            });
        }
        this.setState({
            foundFields,
            searchProcessing: false,
        });
    };

    submit() {
        const { onSubmitSuccess } = this.props;
        onSubmitSuccess && onSubmitSuccess(this.state.fields);
    }

    handleFieldChecked(field: FieldExtended) {
        this.setState((state) => {
            return {
                fields: state.fields.map((stateField) =>
                    stateField.name === field.name
                        ? {
                              ...stateField,
                              included: !stateField.included,
                          }
                        : stateField,
                ),
            };
        });
    }

    handleSearchClear = () => {
        this.setState({
            search: '',
            foundFields: [],
            searchProcessing: false,
        });
    };

    render() {
        const { search, searchProcessing, foundFields, fields } = this.state;
        const { t } = this.props;

        let renderFields = fields;
        let foundLabel = '';

        if (!searchProcessing) {
            if (search.length >= MIN_SEARCH_LENGTH_TO_HANDLE) {
                foundLabel = t('fields_list_form.searchable_fields_list_form.label_found', {
                    count: foundFields.length,
                });
            }
        }

        if (foundFields.length) {
            let foundFieldsNames = foundFields.map((foundField) => foundField.name);
            renderFields = fields.filter((field) => {
                return foundFieldsNames.includes(field.name);
            });
        }

        let showClearButton = false;
        if (search.length) {
            showClearButton = true;
        }

        return (
            <div style={this.props.disableStyling ? {} : { height: '50vh' }}>
                <FormControl fullWidth margin="dense">
                    <InputLabel htmlFor="search" shrink>
                        {t('fields_list_form.searchable_fields_list_form.search.label')} {foundLabel}
                    </InputLabel>
                    <Grid container alignItems="flex-end">
                        <Grid item sm={11}>
                            <TextField
                                onChange={this.handleSearchChange}
                                fullWidth
                                margin="normal"
                                name="search"
                                value={search}
                                placeholder={t('fields_list_form.searchable_fields_list_form.search.placeholder')}
                                autoFocus
                            />
                        </Grid>
                        <Grid item sm={1} style={{ textAlign: 'end' }}>
                            {showClearButton && (
                                <IconButton
                                    onClick={this.handleSearchClear}
                                    data-testid="fields_list_form.searchable_fields_list_form.search.clear"
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </FormControl>
                {renderFields.map((field, index) => {
                    return (
                        <div key={index} className="c-signup-entity">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.included}
                                        onClick={() => {
                                            this.handleFieldChecked(field);
                                        }}
                                        color="primary"
                                    />
                                }
                                label={field.label}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SearchableFieldsListForm);
