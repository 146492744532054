export function extractTextValue(obj: any) {
    if (typeof obj === 'string' || typeof obj === 'number') {
        return obj;
    }

    if (typeof obj !== 'object' || obj === null) {
        return null;
    }

    if (typeof obj.value === 'string') {
        return obj.value;
    }

    return obj.textValue;
}
