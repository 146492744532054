import Api from './Api';

class GoogleApi extends Api {
    constructor(apiKey) {
        super();

        this.apiKey = apiKey;
    }

    /**
     * @return {Promise<void>}
     */
    loadMapScript() {
        return new Promise((resolve) => {
            const src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,geometry,drawing`;
            if (document.querySelector('script[src="' + src + '"]') !== null) {
                resolve();
            } else {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                document.body.appendChild(script);
            }
        });
    }
}

export default GoogleApi;
