import React from 'react';
import DateTime from '../../DateTime';

class DateTimeFormatter extends React.PureComponent {
    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return <DateTime>{this.props.value}</DateTime>;
    }
}

export default DateTimeFormatter;
