import { action, computed, makeObservable, observable } from 'mobx';
import i18n from 'i18next';
import { Api, Routing } from 'interfaces';
import dispatcher from 'service/dispatcher';
import events from '../../../events';
import { isValidLocation } from 'utils';
import { MAX_POINTS_IN_ROUTE, MAX_POINTS_IN_ROUTE_ESSENTIAL } from 'components/constants';
import GeoPointKey from 'service/GeoPointKey';
import RouteWaypointsLimitError from 'service/MapPage/Errors/RouteWaypointsLimitError';
import {
    appointmentConfig,
    confirmReloadSessionManager,
    enqueueSnackbarService,
    routeDesignConfigManager,
    routeDesignManager,
    routeEditorManager,
    routingSessionManager,
} from 'service/MapPage';
import { userManager } from 'service/UserManager';
import RouteDesignService from './RouteDesignService';
import moment from 'moment';
import {
    DesignEventPoint,
    DesignRouteEventPoint,
    DesignRoutePoint,
    DesignRouteProspectPoint,
    PointType,
} from 'interfaces/routing/route';
import { CalendarEvent } from 'service/Calendar/CalendarEventRepository';
import { CalendarEventManager } from 'service/Calendar/CalendarEventManager';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

const t = i18n.t.bind(i18n);

type RouteDesignManagerKeys = '_pointsArray';

interface PointsCount {
    appended: number;
    rejected: number;
    emptyCoordinates: number;
    duplicates: number;
}

interface DefaultPointValues {
    duration: number;
    preparationTime: number;
    departureDelay: number;
    type: Routing.Route.ActivityType;
    settings: any;
}

const defaultPointsCount: Readonly<PointsCount> = {
    appended: 0,
    rejected: 0,
    emptyCoordinates: 0,
    duplicates: 0,
};

class RouteDesignManager {
    private _pointsArray: Routing.Route.DesignRoutePointsArray = [];
    private readonly _apiService = new RouteDesignService();
    private _calendarEventManager: CalendarEventManager = new CalendarEventManager();

    constructor() {
        makeObservable<RouteDesignManager, RouteDesignManagerKeys>(this, {
            _pointsArray: observable,

            emptyPoints: computed,
            pointsArray: computed,
            points: computed,
            allPointsWithStartEndPointsCount: computed,
            nonVirtualPointsArray: computed,
            hasVirtualPoints: computed,

            initSession: action,
            movePoints: action,
            deletePoints: action,
            updatePoints: action,
            deleteEntityPoints: action,
            deleteSimplePoints: action,
            addPoints: action,
            clearPoints: action,
            reset: action,
        });

        dispatcher.subscribe(
            events.CALENDAR_EVENTS_LIST_UPDATED,
            this,
            async (data: { manager: CalendarEventManager }) => {
                if (
                    data.manager !== this._calendarEventManager ||
                    !routingSessionManager.isDesignMode ||
                    !routeDesignConfigManager.addCalendarEventsToRoute
                ) {
                    return;
                }

                await this.updateCalendarEventsInRoute();

                routeDesignConfigManager.setTodayConfigLoading(false);
            },
        );
    }

    reset(): void {
        this._pointsArray = [];
    }

    initSession(session: Routing.Session.Session): void {
        try {
            routeEditorManager.setIsChanging(true);

            const myLocation = {
                start: session.input.start,
                end: session.input.finish,
                errors: {
                    start: false,
                    end: false,
                },
            };
            appointmentConfig.loadFromConfig(myLocation, session.input.calendar);

            this._pointsArray = [...session.input.points];

            const rawTodayConfigMap = new Map();
            // 1 !!!!!!!!!!!!!!!!!!! тут берутся данные из инпута сессии и после изменения они не попадают туда
            for (const config of session.input.config.todayConfig) {
                rawTodayConfigMap.set(config.id, config);
            }

            routeDesignConfigManager.setConfig(session.input.config);
            // 2 потом идут в метод ниже
            routeDesignConfigManager.loadTodayConfig(rawTodayConfigMap);
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    hasProspectingPointInRoute(recordId: string): boolean {
        return (
            this._pointsArray.find((p) => {
                return (
                    this.isProspectPoint(p) &&
                    // @ts-ignore
                    p.recordId === recordId
                );
            }) !== undefined
        );
    }

    hasEntityPointInRoute(point: Routing.Route.EntityNotSafeGeoPoint): boolean {
        if (!isValidLocation(point)) {
            return false;
        }

        const key = GeoPointKey.getKeyByEntityPoint(point as Routing.Route.EntityPoint);
        return this._pointsArray.find((p) => p.key === key) !== undefined;
    }

    async addPoints(
        entityPoints: Routing.Route.DesignEntityPoint[],
        simplePoints: Routing.Route.DesignCombinedSimplePoint[] = [],
    ): Promise<void> {
        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        return this.processBackendRequest(async () => {
            const pointsArray = [...this.pointsArray];
            const pointsKeysMap = new Map<string, Routing.Route.DesignRoutePoint>(
                this.pointsArray.map((p) => [p.key, p]),
            );
            const pointsCount: PointsCount = { ...defaultPointsCount };

            const defaultValues: DefaultPointValues = this.getDefaultPointValues();

            const addedRoutePoints: Routing.Route.DesignAddPoint[] = [];

            simplePoints.forEach((point) => {
                let key: string;
                if (this.isProspectPoint(point)) {
                    // @ts-ignore
                    key = point.recordId;
                } else {
                    key = GeoPointKey.getKeyByPoint(point);
                }
                if (pointsKeysMap.has(key)) {
                    pointsCount.duplicates++;
                }

                RouteDesignManager.throwIfWaypointsLimit(pointsArray, this._pointsArray);

                const routePoint = RouteDesignManager.createModelFromSimplePoint(
                    key,
                    point,
                    defaultValues,
                    pointsArray.length,
                );
                pointsKeysMap.set(key, routePoint);
                pointsArray.push(routePoint);
                pointsCount.appended++;
                routePoint.address = String(routePoint.address);
                addedRoutePoints.push(routePoint);
            });

            entityPoints.forEach((point) => {
                if (!isValidLocation(point)) {
                    pointsCount.emptyCoordinates++;
                    pointsCount.rejected++;
                    return;
                }

                const pointWithCoordinates = point as Routing.Route.DesignInputEntityPoint;
                const key = GeoPointKey.getKeyByEntityPoint(pointWithCoordinates);
                if (pointsKeysMap.has(key)) {
                    pointsCount.duplicates++;
                }

                RouteDesignManager.throwIfWaypointsLimit(pointsArray, this._pointsArray);

                const routePoint = RouteDesignManager.createModelFromEntityPoint(
                    key,
                    pointWithCoordinates,
                    defaultValues,
                    pointsArray.length,
                );
                pointsKeysMap.set(key, routePoint);
                pointsArray.push(routePoint);
                pointsCount.appended++;
                addedRoutePoints.push(routePoint);
            });

            if (addedRoutePoints.length === 0) {
                return;
            }

            this.pointsArray = await this._apiService.addPoints(addedRoutePoints);

            this.triggerRouteUpdated();
            dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_APPENDED, pointsCount);
        });
    }

    async updatePoint(point: Routing.Route.DesignRoutePoint): Promise<void> {
        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        return this.processBackendRequest(async () => {
            this.pointsArray = await this._apiService.updatePoint(point);

            this.triggerRouteUpdated();
            dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_UPDATED, point);
        });
    }

    private getDefaultPointValues(): DefaultPointValues {
        return {
            duration: userManager.getCurrentUser().routingPreferences.defaultDuration,
            departureDelay: userManager.getCurrentUser().routingPreferences.departureDelay,
            preparationTime: 0,
            type: Routing.Route.ActivityType.SERVICE,
            settings: null,
        };
    }

    get emptyPoints(): boolean {
        return this._pointsArray.length === 0;
    }

    get hasVirtualPoints(): boolean {
        return this._pointsArray.some((point) => !isValidLocation(point));
    }

    get pointsArray(): Routing.Route.DesignRoutePointsArray {
        return this._pointsArray;
    }

    get nonVirtualPointsArray(): Routing.Route.DesignRoutePointsArray {
        return this._pointsArray.filter((point) => isValidLocation(point));
    }

    set pointsArray(pointsArray: Routing.Route.DesignRoutePointsArray) {
        this._pointsArray = pointsArray;
    }

    get points(): Routing.Route.DesignRoutePoint[] {
        return [...this._pointsArray];
    }

    get allPointsWithStartEndPointsCount(): number {
        return this.pointsArray.length + this.getUserLocationPointsCount();
    }

    get nonVirtualPointsWithStartEndPointsCount(): number {
        return this.nonVirtualPointsArray.length + this.getUserLocationPointsCount();
    }

    private getUserLocationPointsCount(): number {
        const { start, end } = appointmentConfig.myLocations;
        let count = 0;
        if (start.point !== null || start.buttonType === Routing.Route.MyLocationType.CurrentLocation) {
            count++;
        }
        if (end.point !== null || end.buttonType === Routing.Route.MyLocationType.CurrentLocation) {
            count++;
        }
        return count;
    }

    async movePoints(oldIndex: number, newIndex: number): Promise<void> {
        if (oldIndex === newIndex) {
            return;
        }

        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        if (!this.isAllowedToMovePoints(oldIndex, newIndex)) {
            enqueueSnackbarService.sendErrorMessage(
                i18n.t('route_editor.form.advanced_routing.not_allowed_to_move_point'),
            );
            return;
        }

        return this.processBackendRequest(async () => {
            this.pointsArray = await this._apiService.movePoints(oldIndex, newIndex);

            dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_RESORTED, oldIndex, newIndex);
            this.triggerRouteUpdated();
        });
    }

    isAllowedToMovePoints(oldIndex: number, newIndex: number): boolean {
        if (oldIndex === newIndex) {
            return true;
        }

        const oldPoint = routeDesignManager.points[oldIndex];
        if (
            !oldPoint ||
            oldPoint.pointType !== PointType.EVENT_POINT ||
            !('calendarEvent' in oldPoint) ||
            !oldPoint.calendarEvent.fixedTime
        ) {
            return true;
        }

        const pointsToCheck = routeDesignManager.points.slice(
            oldIndex > newIndex ? newIndex : oldIndex,
            oldIndex > newIndex ? oldIndex + 1 : newIndex + 1,
        );
        for (let pointToCheck of pointsToCheck) {
            if (
                pointToCheck.pointType !== PointType.EVENT_POINT ||
                !('calendarEvent' in pointToCheck) ||
                !pointToCheck.calendarEvent.fixedTime
            ) {
                continue;
            }

            if (
                oldIndex > newIndex &&
                moment(oldPoint.calendarEvent.startDatetime).isAfter(pointToCheck.calendarEvent.startDatetime)
            ) {
                return false;
            }

            if (
                oldIndex < newIndex &&
                moment(oldPoint.calendarEvent.startDatetime).isBefore(pointToCheck.calendarEvent.startDatetime)
            ) {
                return false;
            }
        }

        return true;
    }

    async deletePoints(
        entityPoints: Routing.Route.DesignRouteEntityPoint[],
        simplePoints: (Routing.Route.DesignRouteSimplePoint | DesignRouteProspectPoint)[] = [],
    ): Promise<void> {
        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        return this.processBackendRequest(async () => {
            const deletedPoints: Routing.Route.DesignDeletePoint[] = [];

            const pointsKeysMap = new Map<string, Routing.Route.DesignRoutePoint>(
                this.pointsArray.map((p) => [p.key, p]),
            );

            entityPoints.forEach((point) => {
                if (!pointsKeysMap.has(point.key)) {
                    return;
                }

                deletedPoints.push(point);
            });

            simplePoints.forEach((point) => {
                if (!pointsKeysMap.has(point.key)) {
                    return;
                }

                deletedPoints.push(point);
            });

            if (deletedPoints.length > 0) {
                this.pointsArray = await this._apiService.deletePoints(deletedPoints);
                this.triggerRouteUpdated();
                dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_DELETED, deletedPoints);
            }
        });
    }

    async updatePoints(points: Routing.Route.DesignRoutePoint[] = []): Promise<void> {
        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        return this.processBackendRequest(async () => {
            this.pointsArray = await this._apiService.updatePoints(points);
            this.triggerRouteUpdated();
            dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_UPDATED, points);
        });
    }

    async deleteEntityPoints(points: Routing.Route.DesignRouteEntityPoint[]): Promise<void> {
        await this.deletePoints(points);
    }

    async deleteSimplePoints(points: Routing.Route.DesignRouteSimplePoint[]): Promise<void> {
        await this.deletePoints([], points);
    }

    async clearPoints(): Promise<void> {
        if (!RouteDesignManager.canBeUpdated()) {
            return;
        }

        return this.processBackendRequest(async () => {
            const deletedPoints: Routing.Route.DesignDeletePoint[] = [];
            this.pointsArray.forEach((point) => {
                deletedPoints.push(point);
            });

            if (deletedPoints.length > 0) {
                this.pointsArray = await this._apiService.deletePoints(deletedPoints);
                this.triggerRouteUpdated();
                dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_DELETED, deletedPoints);
            }
        });
    }

    getPointsForBuild(): Routing.Route.PointForBuild[] {
        const result: Routing.Route.PointForBuild[] = [];
        this._pointsArray.forEach((point) => {
            let entityId = null,
                recordId = null,
                pointType = PointType.SIMPLE_POINT;
            const { lat, lng, countryShort, address, addressFields, objectName, objectSettings } = point;
            if (this.isEntityPoint(point)) {
                const entityPoint = point as Routing.Route.DesignRouteEntityPoint;
                entityId = entityPoint.entityId;
                recordId = entityPoint.recordId;
                pointType = PointType.ENTITY_POINT;
            } else if (this.isProspectPoint(point)) {
                recordId = (point as Routing.Route.DesignRouteProspectPoint).recordId;
                pointType = PointType.PROSPECTING_POINT;
            } else if (this.isEventPoint(point)) {
                recordId = (point as Routing.Route.DesignRouteEventPoint).calendarEvent.id;
                pointType = PointType.EVENT_POINT;
            }
            result.push({
                lat,
                lng,
                countryShort,
                entityId,
                id: recordId,
                address,
                addressFields,
                objectName,
                pointType: point.pointType ?? pointType,
                objectSettings,
            });
        });

        return result;
    }

    isEntityPoint(
        point: Routing.Route.DesignRoutePoint | Routing.Route.DesignPoint,
    ): point is Routing.Route.DesignRouteEntityPoint | Routing.Route.DesignEntityPoint {
        return 'entityId' in point && point.entityId !== null && 'recordId' in point && point.recordId !== null;
    }

    isProspectPoint(
        point: Routing.Route.DesignRoutePoint | Routing.Route.DesignPoint,
    ): point is Routing.Route.DesignRouteProspectPoint | Routing.Route.DesignProspectPoint {
        return !('entityId' in point && point.entityId !== null) && 'recordId' in point && point.recordId !== null;
    }

    isEventPoint(
        point: Routing.Route.DesignRoutePoint | Routing.Route.DesignPoint,
    ): point is Routing.Route.DesignRouteEventPoint {
        return point.pointType === PointType.EVENT_POINT;
    }

    requestGetEvents() {
        if (
            routeEditorManager.isProcessing ||
            !routingSessionManager.isDesignMode ||
            !routeDesignConfigManager.addCalendarEventsToRoute
        ) {
            return;
        }

        routeDesignConfigManager.setTodayConfigLoading(true);

        const planningEndDate = moment(appointmentConfig.departingCalendarData.dateUTCString)
            .add(routeDesignConfigManager.daysPeriod - 1, 'day')
            .toDate();
        planningEndDate.setHours(23, 59, 59, 999);
        this._calendarEventManager.requestGetEvents(
            routeDesignConfigManager.usersIds,
            new Date(appointmentConfig.departingCalendarData.dateUTCString),
            planningEndDate,
            true,
            routeDesignConfigManager.includeDraftEvents,
        );
    }

    deleteEventPointsFromRoute(): Promise<void> {
        if (routeEditorManager.isProcessing || !routingSessionManager.isDesignMode) {
            return Promise.resolve();
        }

        const pointsToDelete: DesignRoutePoint[] = [];
        this.points.forEach((point: DesignRoutePoint) => {
            if (point.pointType === PointType.EVENT_POINT) {
                pointsToDelete.push(point);
            }
        });

        if (pointsToDelete.length === 0) {
            return Promise.resolve();
        }

        return this.deleteSimplePoints(pointsToDelete);
    }

    fixedFlexibleToggle = (calendarEventId: string): void => {
        let calendarEvent = this._calendarEventManager.getEvents().filter((calendarEvent: CalendarEvent) => {
            return calendarEvent.id === calendarEventId;
        })[0];
        if (!calendarEvent) {
            return;
        }

        calendarEvent = cloneDeep(calendarEvent);

        this._calendarEventManager.save([{ ...calendarEvent, fixedTime: !calendarEvent.fixedTime }]);
    };

    getCalendarEventById = (calendarEventId: string): CalendarEvent | undefined => {
        return this._calendarEventManager.getEvents().filter((calendarEvent: CalendarEvent) => {
            return calendarEvent.id === calendarEventId;
        })[0];
    };

    private async updateCalendarEventsInRoute() {
        if (routeEditorManager.isProcessing || !routingSessionManager.isDesignMode) {
            return;
        }

        const calendarEventsToAdd = this.getCalendarEventsToAddToRoute();

        let updatedRoutePoints: DesignRoutePoint[] = [];
        routeDesignManager.points.forEach((point: DesignRoutePoint) => {
            if (
                point.pointType !== PointType.EVENT_POINT ||
                !('calendarEvent' in point) ||
                point.calendarEvent === null
            ) {
                updatedRoutePoints.push(cloneDeep(point));
                return;
            }
            const calendarEventToAdd = calendarEventsToAdd.get(point.calendarEvent.id);
            if (!calendarEventToAdd) {
                return;
            }

            updatedRoutePoints.push(
                this.isRouteEventPointChanged(point, calendarEventToAdd)
                    ? this.getUpdatedPointFromCalendarEvent(point, calendarEventToAdd)
                    : cloneDeep(point),
            );
            calendarEventsToAdd.delete(calendarEventToAdd.id);
        });

        const calendarEventsToAddArray = Array.from(calendarEventsToAdd.values());
        calendarEventsToAddArray.sort((event1: CalendarEvent, event2: CalendarEvent) => {
            return new Date(event1.startDatetime).getTime() - new Date(event2.startDatetime).getTime();
        });

        updatedRoutePoints = this.sortAndAddNewCalendarPoints(updatedRoutePoints, calendarEventsToAddArray);
        updatedRoutePoints.forEach((point: DesignRoutePoint, index: number) => {
            point.index = index;
        });

        if (isEqual(routeDesignManager.points, updatedRoutePoints)) {
            return;
        }

        return this.updatePoints(updatedRoutePoints);
    }

    private sortAndAddNewCalendarPoints(
        routePoints: DesignRoutePoint[],
        calendarEventsToAdd: CalendarEvent[],
    ): DesignRoutePoint[] {
        if (this.calendarEventPointsNeedSorting(routePoints)) {
            routePoints = this.sortCalendarEventPoints(routePoints);
        }

        calendarEventsToAdd.forEach((calendarEvent: CalendarEvent) => {
            if (!calendarEvent.fixedTime) {
                routePoints.push(this.calendarEventToRoutePoint(calendarEvent, routePoints.length));
                return;
            }

            let lastFixedTimePointWithSmallerStartTimeIndex = -1;
            for (let i = routePoints.length - 1; i >= 0; i--) {
                const point = routePoints[i];
                if (
                    point.pointType === PointType.EVENT_POINT &&
                    'calendarEvent' in point &&
                    point.calendarEvent.fixedTime &&
                    moment(calendarEvent.startDatetime).isAfter(point.calendarEvent.startDatetime)
                ) {
                    lastFixedTimePointWithSmallerStartTimeIndex = i;
                    break;
                }
            }

            if (lastFixedTimePointWithSmallerStartTimeIndex !== -1) {
                routePoints.splice(
                    lastFixedTimePointWithSmallerStartTimeIndex + 1,
                    0,
                    this.calendarEventToRoutePoint(calendarEvent, routePoints.length),
                );
                return;
            }

            const firstFixedTimePointWithGreaterStartTimeIndex = routePoints.findIndex((point: DesignRoutePoint) => {
                return (
                    point.pointType === PointType.EVENT_POINT &&
                    'calendarEvent' in point &&
                    point.calendarEvent.fixedTime &&
                    moment(calendarEvent.startDatetime).isBefore(point.calendarEvent.startDatetime)
                );
            });
            if (firstFixedTimePointWithGreaterStartTimeIndex !== -1) {
                routePoints.splice(
                    firstFixedTimePointWithGreaterStartTimeIndex,
                    0,
                    this.calendarEventToRoutePoint(calendarEvent, routePoints.length),
                );
                return;
            }

            routePoints.push(this.calendarEventToRoutePoint(calendarEvent, routePoints.length));
        });

        return routePoints;
    }

    private calendarEventPointsNeedSorting(routePoints: DesignRoutePoint[]): boolean {
        const oldCalendarFixedRoutePoints = routeDesignManager.points
            .filter((point: DesignRoutePoint) => {
                return (
                    point.pointType === PointType.EVENT_POINT &&
                    'calendarEvent' in point &&
                    point.calendarEvent.fixedTime
                );
            })
            .map((point: DesignRoutePoint) => {
                return (point as DesignRouteEventPoint).calendarEvent.id;
            });

        const newCalendarFixedRoutePoints = routePoints.filter((point: DesignRoutePoint) => {
            return (
                point.pointType === PointType.EVENT_POINT && 'calendarEvent' in point && point.calendarEvent.fixedTime
            );
        });
        newCalendarFixedRoutePoints.sort((point1: DesignRoutePoint, point2: DesignRoutePoint) => {
            return (
                new Date((point1 as DesignRouteEventPoint).calendarEvent.startDatetime).getTime() -
                new Date((point2 as DesignRouteEventPoint).calendarEvent.startDatetime).getTime()
            );
        });
        const newCalendarFixedRoutePointsIds = newCalendarFixedRoutePoints.map((point: DesignRoutePoint) => {
            return (point as DesignRouteEventPoint).calendarEvent.id;
        });

        return !isEqual(oldCalendarFixedRoutePoints, newCalendarFixedRoutePointsIds);
    }

    private sortCalendarEventPoints(routePoints: DesignRoutePoint[]): DesignRoutePoint[] {
        const firstFixedEventPointIndex = routePoints.findIndex((point: DesignRoutePoint) => {
            return (
                point.pointType === PointType.EVENT_POINT && 'calendarEvent' in point && point.calendarEvent.fixedTime
            );
        });
        if (firstFixedEventPointIndex === -1) {
            return routePoints;
        }

        const fixedEventPoints = routePoints.filter((point: DesignRoutePoint) => {
            return (
                point.pointType === PointType.EVENT_POINT && 'calendarEvent' in point && point.calendarEvent.fixedTime
            );
        });
        fixedEventPoints.sort((point1: DesignRoutePoint, point2: DesignRoutePoint) => {
            return (
                new Date((point1 as DesignEventPoint).calendarEvent.startDatetime).getTime() -
                new Date((point2 as DesignEventPoint).calendarEvent.startDatetime).getTime()
            );
        });
        routePoints = routePoints.filter((point: DesignRoutePoint) => {
            return (
                point.pointType !== PointType.EVENT_POINT ||
                !('calendarEvent' in point) ||
                !point.calendarEvent.fixedTime
            );
        });
        routePoints.splice(firstFixedEventPointIndex, 0, ...fixedEventPoints);

        return routePoints;
    }

    private isRouteEventPointChanged(point: DesignRouteEventPoint, calendarEvent: CalendarEvent): boolean {
        const preparedEvent = this.prepareCalendarEventForRouting(calendarEvent);
        const lat = preparedEvent.lat ? Number.parseFloat(preparedEvent.lat) : null;
        const lng = preparedEvent.lng ? Number.parseFloat(preparedEvent.lng) : null;

        return (
            point.lat !== lat ||
            point.lng !== lng ||
            point.address !== preparedEvent.location ||
            point.objectName !== preparedEvent.title ||
            new Date(point.calendarEvent.startDatetime).toISOString() !==
                new Date(preparedEvent.startDatetime).toISOString() ||
            new Date(point.calendarEvent.endDatetime).toISOString() !==
                new Date(preparedEvent.endDatetime).toISOString() ||
            point.calendarEvent.fixedTime !== preparedEvent.fixedTime ||
            point.geoStatus !== preparedEvent.geoStatus
        );
    }

    private getUpdatedPointFromCalendarEvent(
        point: DesignRouteEventPoint,
        calendarEvent: CalendarEvent,
    ): DesignRouteEventPoint {
        const preparedEvent = this.prepareCalendarEventForRouting(calendarEvent);
        const updatedPoint = cloneDeep(point);
        const lat = preparedEvent.lat ? Number.parseFloat(preparedEvent.lat) : null;
        const lng = preparedEvent.lng ? Number.parseFloat(preparedEvent.lng) : null;

        updatedPoint.lat = lat;
        updatedPoint.lng = lng;
        updatedPoint.address = preparedEvent.location;
        updatedPoint.objectName = preparedEvent.title;
        updatedPoint.calendarEvent = {
            id: preparedEvent.id,
            startDatetime: preparedEvent.startDatetime,
            endDatetime: preparedEvent.endDatetime,
            duration: moment(preparedEvent.endDatetime).diff(preparedEvent.startDatetime, 'minutes'),
            fixedTime: preparedEvent.fixedTime,
        };
        updatedPoint.key = GeoPointKey.getKeyByPoint(updatedPoint);
        updatedPoint.geoStatus = preparedEvent.geoStatus;

        return updatedPoint;
    }

    private calendarEventToRoutePoint(calendarEvent: CalendarEvent, index: number): DesignRouteEventPoint {
        const preparedEvent = this.prepareCalendarEventForRouting(calendarEvent);

        const lat = preparedEvent.lat ? Number.parseFloat(preparedEvent.lat) : null;
        const lng = preparedEvent.lng ? Number.parseFloat(preparedEvent.lng) : null;

        const point: DesignEventPoint = {
            lat: lat,
            lng: lng,
            address: preparedEvent.location,
            addressFields: {},
            countryShort: null,
            objectName: preparedEvent.title,
            pointType: PointType.EVENT_POINT,
            calendarEvent: {
                id: preparedEvent.id,
                startDatetime: preparedEvent.startDatetime,
                endDatetime: preparedEvent.endDatetime,
                duration: moment(preparedEvent.endDatetime).diff(preparedEvent.startDatetime, 'minutes'),
                fixedTime: preparedEvent.fixedTime,
            },
            geoStatus: preparedEvent.geoStatus,
        };

        return RouteDesignManager.createModelFromSimplePoint(
            GeoPointKey.getKeyByPoint(point),
            point,
            this.getDefaultPointValues(),
            index,
        ) as DesignRouteEventPoint;
    }

    private getCalendarEventsToAddToRoute(): Map<string, CalendarEvent> {
        const planningEndDate = moment(appointmentConfig.departingCalendarData.date)
            .add(routeDesignConfigManager.daysPeriod - 1, 'day')
            .toDate();
        planningEndDate.setHours(23, 59, 59, 999);
        const calendarEventsToAdd: CalendarEvent[] = [];
        this._calendarEventManager.getEvents().forEach((calendarEvent: CalendarEvent) => {
            if (
                !routeDesignConfigManager.usersIds.includes(calendarEvent.ownerId) ||
                moment(appointmentConfig.departingCalendarData.date).isSameOrAfter(calendarEvent.endDatetime) ||
                moment(planningEndDate).isSameOrBefore(calendarEvent.startDatetime) ||
                (!routeDesignConfigManager.includeDraftEvents && calendarEvent.draft)
            ) {
                return;
            }

            calendarEventsToAdd.push(calendarEvent);
        });

        calendarEventsToAdd.sort((event1: CalendarEvent, event2: CalendarEvent) => {
            return new Date(event1.startDatetime).getTime() - new Date(event2.startDatetime).getTime();
        });

        const calendarEventsToAddMap = new Map<string, CalendarEvent>();
        calendarEventsToAdd.forEach((calendarEvent: CalendarEvent) => {
            calendarEventsToAddMap.set(calendarEvent.id, calendarEvent);
        });

        return calendarEventsToAddMap;
    }

    private static throwIfWaypointsLimit(
        pointsArray: Routing.Route.DesignRoutePointsArray,
        oldpointsArray: Routing.Route.DesignRoutePointsArray,
    ): void {
        const maxPoints = userManager.hasEssentialRestrictions() ? MAX_POINTS_IN_ROUTE_ESSENTIAL : MAX_POINTS_IN_ROUTE;
        if (pointsArray.length >= maxPoints) {
            const canAddPoints = maxPoints - oldpointsArray.length;
            throw new RouteWaypointsLimitError('Design route points appended over limit', {
                maxPoints,
                canAddPoints: canAddPoints > 0 ? canAddPoints : 0,
            });
        }
    }

    private static createModelFromSimplePoint(
        key: string,
        point: Routing.Route.DesignSimplePoint,
        defaultValues: DefaultPointValues,
        index: number,
    ): Routing.Route.DesignRouteSimplePoint {
        return {
            ...point,
            ...defaultValues,
            key,
            objectName: point.objectName || point.address || '',
            index,
            pointType: point.pointType,
        };
    }

    private static createModelFromEntityPoint(
        key: string,
        point: Routing.Route.DesignInputEntityPoint,
        defaultValues: DefaultPointValues,
        index: number,
    ): Routing.Route.DesignRouteEntityPoint {
        return {
            ...point,
            ...defaultValues,
            key,
            index,
            pointType: PointType.ENTITY_POINT,
        };
    }

    private triggerRouteUpdated(): void {
        const entityPoints: Routing.Route.Entity[] = this.points
            .filter((point) => this.isEntityPoint(point))
            .map((point) => {
                const entityPoint = point as Routing.Route.DesignRouteEntityPoint;
                return { entityId: entityPoint.entityId, recordId: entityPoint.recordId };
            });
        dispatcher.dispatch(events.CURRENT_ROUTE_POINTS_GROUPED, entityPoints);
    }

    private static canBeUpdated(): boolean {
        if (routeEditorManager.isProcessing) {
            enqueueSnackbarService.sendErrorMessage(
                i18n.t('map_page.snack.you_cannot_add_a_point_when_building_a_route'),
            );
            return false;
        }
        return true;
    }

    private async processBackendRequest(callable: Function, ...args: any): Promise<any> {
        try {
            routeEditorManager.setIsChanging(true);

            return await callable.call(this, args);
        } catch (e) {
            if (e instanceof RouteWaypointsLimitError) {
                const { maxPoints, canAddPoints } = e.data;
                enqueueSnackbarService.sendWarningMessage(
                    t('map_page.snack.route_points_appended_over_limit', { maxPoints, canAddPoints }),
                );
                return;
            }

            if (e.code === 400 && e.exception === Api.Exception.ExtraCodes.INVALID_STATUS_ROUTING_SESSION) {
                console.warn(e);
                confirmReloadSessionManager.openDialog();

                return;
            }

            console.error(e);
            enqueueSnackbarService.sendErrorMessage(e.message);
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    private prepareCalendarEventForRouting(calendarEventToAdd: CalendarEvent): CalendarEvent {
        const prepared = cloneDeep(calendarEventToAdd);
        if (prepared.virtual) {
            prepared.lat = null;
            prepared.lng = null;
            prepared.location = null;
        }
        return prepared;
    }
}

export default RouteDesignManager;
