import React, { RefObject, Suspense } from 'react';
import SSUManager, { STAGES } from './SSUManager';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { reverse, routes } from '../../routes';
import logRocketManager from '../../service/LogRocket';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import SSUIntro from './SSUIntro';
import SSUInitialSetup from './SSUInitialSetup';
import SSUInterestedIn from './SSUInterestedIn';
import SSUConnection from './SSUConnection';
import SSUEntities from './SSUEntities';
import entityManagerFactory from '../../service/EntityManager';
import SSUFields from './SSUFields';
import qs from 'qs';
import SSUSelectAccount from './SSUSelectAccount';
import StagesStepper from './StagesStepper';
import Alert from '../Alert';
import ErrorMessage from '../DataSource/ErrorMessage';
import Backdrop from '../Backdrop';
import Button from '@material-ui/core/Button';
import dsManagerFactory from '../../service/DsManager';
import { InterestingFeaturesService } from './InterestingFeaturesService';
import './style.css';
import { MAPSLY_QUICK_DEMO_URL } from '../constants';
import { intercomManager } from 'service/IntercomManager';

interface SelfSignUpProps extends WithTranslation, RouteComponentProps {
    stage: number;
    provider?: string;
}

interface SelfSignUpState {
    stage: number;
    message: null | Message;
    isProcessing: boolean;
    isError: boolean;
    isWarning: boolean;
    processingTask: null | string;
    isReady: boolean;
}

interface Message {
    type: 'success' | 'warning' | 'danger' | 'info';
    message: string;
    ifYouNeedHelp: null | string;
    details: null | [] | Object;
}

class SelfSignUp extends React.PureComponent<SelfSignUpProps, SelfSignUpState> {
    public static defaultProps: Partial<SelfSignUpProps> = {
        stage: STAGES.INTRO,
    };
    private readonly ssuManager: SSUManager;
    private readonly initialSetupRef: RefObject<any>;
    private readonly connRef: RefObject<any>;
    private readonly entitiesRef: RefObject<any>;
    private readonly fieldsRef: RefObject<any>;
    private readonly selectedFeaturesRef: RefObject<any>;
    private readonly eventTag: string;
    private forcedStage: number | null;

    constructor(props: SelfSignUpProps) {
        super(props);

        this.state = {
            stage: props.stage,
            message: null,
            isProcessing: true,
            isError: false,
            processingTask: null,
            isReady: true,
            isWarning: false,
        };
        this.ssuManager = new SSUManager(props.provider, props.stage);
        this.initialSetupRef = React.createRef();
        this.connRef = React.createRef();
        this.entitiesRef = React.createRef();
        this.fieldsRef = React.createRef();
        this.selectedFeaturesRef = React.createRef();
        this.eventTag = this.constructor.name;
        this.forcedStage = null;
    }

    getStepUrl(step: number) {
        const { provider } = this.props;
        if (provider) {
            return reverse(routes.signup, { provider, step });
        }
        return reverse(routes.quickSignup, { step });
    }

    componentDidMount() {
        logRocketManager.init();

        dispatcher.subscribe(
            events.WS_DS_METADATA_IMPORT,
            this.eventTag,
            (payload: { status: string; dsId: string }) => {
                if (payload.status === 'complete' && this.ssuManager.getDataSourceId() === parseInt(payload.dsId)) {
                    this.entitiesRef.current && this.entitiesRef.current.loadEntities();
                }
            },
        );

        this.fixStage();
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WS_DS_METADATA_IMPORT, this.eventTag);
    }

    componentDidUpdate() {
        if (this.forcedStage !== null && this.state.stage !== this.forcedStage) {
            intercomManager.changeSession();
            this.setState({ stage: this.forcedStage });
        }
    }

    handleError = (message: string, details: null | [] | Object) => {
        window.scrollTo(0, 0);
        this.setState({
            processingTask: null,
            isReady: true,
            message: {
                type: 'danger',
                message,
                ifYouNeedHelp: this.props.t('signup.error.if_you_need_help'),
                details,
            },
        });

        setTimeout(() => {
            const stagesFullNames = this.getStagesFullNames();
            const title = stagesFullNames[this.state.stage - 1] ?? '';

            intercomManager.showNewMessage(
                this.props.t('signup.error.intercom_message', { stepTitle: title, errorMessage: message }),
            );
        }, 2000);
    };

    handleWarning = (message: string, details = null) => {
        window.scrollTo(0, 0);
        this.setState({
            isWarning: true,
            processingTask: null,
            isReady: true,
            message: {
                type: 'warning',
                message,
                ifYouNeedHelp: null,
                details,
            },
        });
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
        });

        switch (this.state.stage) {
            case STAGES.INITIAL:
                this.initialSetupRef.current && this.initialSetupRef.current.quiet();
                break;
            case STAGES.AUTH:
                this.connRef.current && this.connRef.current.quiet();
                break;
            case STAGES.ENTITIES:
                this.entitiesRef.current && this.entitiesRef.current.quiet();
                break;
            default:
        }
    };

    handleProcessing = (task: string) => {
        this.setState({
            processingTask: task,
            isReady: false,
        });
    };

    stopProcessing = () => {
        this.setState({
            processingTask: null,
            isReady: true,
        });
    };

    handleSuccess = () => {
        intercomManager.showNewMessage('');
        intercomManager.closeWidget();

        let nextStage: number;
        if (this.state.stage === STAGES.FEATURES && !this.props.provider) {
            nextStage = STAGES.FINISH;
        } else {
            nextStage = this.state.stage + 1;
        }
        this.setState({
            isWarning: false,
            processingTask: null,
            isReady: true,
        });

        this.forcedStage = nextStage;

        if (nextStage > STAGES.INITIAL) {
            this.ssuManager.saveStage(nextStage).then(() => this.props.history.push(this.getStepUrl(nextStage)));
        } else {
            this.props.history.push(this.getStepUrl(nextStage));
        }
    };

    handleReady = (isReady: boolean) => {
        this.setState({
            processingTask: null,
            isReady,
        });
    };

    handleValid = (isValid: boolean) => {
        const { t } = this.props;
        if (isValid) {
            this.setState({
                message: null,
            });
        } else {
            this.setState({
                message: {
                    type: 'danger',
                    message: t('validation_errors'),
                    ifYouNeedHelp: null,
                    details: null,
                },
            });
        }
    };

    getStage = () => {
        switch (this.state.stage) {
            case STAGES.INTRO:
                return <SSUIntro manager={this.ssuManager} />;
            case STAGES.INITIAL:
                return (
                    <Suspense fallback={'Loading...'}>
                        <SSUInitialSetup
                            manager={this.ssuManager}
                            ref={this.initialSetupRef}
                            onProcessing={this.handleProcessing}
                            onSuccess={this.handleSuccess}
                            onError={() => {}}
                            onReady={this.handleReady}
                            onValid={this.handleValid}
                            stopProcessing={this.stopProcessing}
                        />
                    </Suspense>
                );
            case STAGES.FEATURES:
                return (
                    <SSUInterestedIn
                        manager={this.ssuManager}
                        featuresService={new InterestingFeaturesService()}
                        ref={this.selectedFeaturesRef}
                        onSuccess={this.handleSuccess}
                        onProcessing={this.handleProcessing}
                        onError={this.handleError}
                        onReady={this.handleReady}
                    />
                );
            case STAGES.AUTH:
                return (
                    <SSUConnection
                        manager={this.ssuManager}
                        ref={this.connRef}
                        onProcessing={this.handleProcessing}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                    />
                );
            case STAGES.ENTITIES:
                if (this.ssuManager.getDataSourceId() === null) {
                    this.forcedStage = STAGES.AUTH;
                    return;
                }

                // параметр autoload:
                // при загрузке с этого шага сообщение о загрузке сущностей не будет получено,
                // поэтому даем компоненту команду сразу загрузить их при создании
                return (
                    <SSUEntities
                        autoload={this.props.stage === STAGES.ENTITIES}
                        entityManager={entityManagerFactory.getManager(
                            this.ssuManager.getAccountId(),
                            this.ssuManager.getDataSourceId(),
                        )}
                        ref={this.entitiesRef}
                        onReady={this.handleReady}
                        onProcessing={this.handleProcessing}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                        onWarning={this.handleWarning}
                    />
                );
            case STAGES.FIELDS:
                if (this.ssuManager.getDataSourceId() === null) {
                    this.forcedStage = STAGES.AUTH;
                    return;
                }

                return (
                    <SSUFields
                        autoload={this.props.stage === STAGES.FIELDS}
                        entityManager={entityManagerFactory.getManager(
                            this.ssuManager.getAccountId(),
                            this.ssuManager.getDataSourceId(),
                        )}
                        ref={this.fieldsRef}
                        onReady={this.handleReady}
                        onProcessing={this.handleProcessing}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                    />
                );
            default:
                return null;
        }
    };

    handleLinkAccount = (unlinkedAccount: null | { id: number; name: string }) => {
        let url = routes.client;
        if (unlinkedAccount) {
            const msg = {
                unlinkedAccount,
            };
            url += '?msg=sso_unlink&msg_data=' + qs.stringify(msg);
        }
        this.props.history.push(url);
    };

    getStagesFullNames(): Array<string> {
        const { t, provider } = this.props;
        let commonSteps = [
            t('signup.steps_title.welcome_long'),
            t('signup.steps_title.account_long'),
            t('signup.steps_title.features_long'),
        ];
        if (provider) {
            commonSteps.push(
                t('signup.steps_title.access_long'),
                t('signup.steps_title.objects_long'),
                t('signup.steps_title.fields_long'),
            );
        }

        return commonSteps;
    }

    getStagesShortNames(): Array<string> {
        const { t, provider } = this.props;
        let steps = [
            t('signup.steps_title.welcome_short'),
            t('signup.steps_title.account_short'),
            t('signup.steps_title.features_short'),
        ];

        if (provider) {
            steps.push(
                t('signup.steps_title.access_short'),
                t('signup.steps_title.objects_short'),
                t('signup.steps_title.fields_short'),
            );
        }

        return steps;
    }

    async fixStage() {
        const actualState = await this.ssuManager.fixStage(this.state.stage);
        if (actualState !== this.state.stage) {
            this.forcedStage = actualState;
            this.forceUpdate();
        }
    }

    render() {
        const { t } = this.props;
        const stagesFullNames = this.getStagesFullNames();
        const stagesShortNames = this.getStagesShortNames();

        if (this.forcedStage !== null && this.state.stage !== this.forcedStage) {
            return <Redirect to={this.getStepUrl(this.forcedStage)} />;
        }

        if (this.state.stage === STAGES.ACCOUNT) {
            return (
                <SSUSelectAccount
                    manager={this.ssuManager}
                    onCreateAccount={this.handleSuccess}
                    onLinkAccount={this.handleLinkAccount}
                    ref={this.initialSetupRef}
                />
            );
        }

        if (this.state.stage >= STAGES.FINISH) {
            return <Redirect to={reverse(routes.client)} />;
        }

        const showIfYouNeedHelpMessage = this.state.stage !== STAGES.INTRO;

        return (
            <div style={{ maxWidth: 710, margin: '0 auto' }}>
                <StagesStepper stagesShortNames={stagesShortNames} activeStep={this.state.stage} />

                <h1>{stagesFullNames[this.state.stage - 1]}</h1>
                {this.state.stage === STAGES.FEATURES && (
                    <span>{t('signup.features.info_below_help_us.form_hint')}</span>
                )}

                {this.state.message !== null && (
                    <Alert canClose onClose={this.handleAlertClose} type={this.state.message.type}>
                        {this.state.message.message}
                        {this.state.message.details && <ErrorMessage details={this.state.message.details} />}
                        <br />
                        {this.state.message.ifYouNeedHelp !== null && this.state.message.ifYouNeedHelp}
                    </Alert>
                )}

                <Backdrop loading={this.state.processingTask !== null} task={this.state.processingTask}>
                    {this.getStage()}
                </Backdrop>
                <div style={{ textAlign: 'center', margin: '32px 0' }}>
                    <Button
                        onClick={this.next}
                        disabled={!this.state.isReady}
                        color="primary"
                        variant="contained"
                        data-testid="signup.proceed.common"
                    >
                        {this.state.isWarning ? t('signup.proceed.force') : t('signup.proceed.common')}
                    </Button>
                </div>
                {showIfYouNeedHelpMessage && (
                    <div style={{ textAlign: 'center', color: 'gray' }}>
                        <p>
                            {t('signup.if_you_need_help.part_1')}
                            <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => this.openIntercom()}
                            >
                                {t('signup.if_you_need_help.part_2')}
                            </span>
                            {t('signup.if_you_need_help.part_3')}
                            <a
                                href={MAPSLY_QUICK_DEMO_URL}
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{ color: 'gray' }}
                            >
                                {t('signup.if_you_need_help.part_4')}
                            </a>
                            &nbsp;
                            <i style={{ color: 'gray' }} className="fas fa-external-link" aria-hidden="true" />
                            &nbsp;
                            {t('signup.if_you_need_help.part_5')}
                        </p>
                    </div>
                )}
            </div>
        );
    }

    openIntercom = () => {
        intercomManager.showWidget();
    };

    next = () => {
        this.handleAlertClose();

        switch (this.state.stage) {
            case STAGES.INITIAL:
                this.initialSetupRef.current && this.initialSetupRef.current.commit();
                break;
            case STAGES.AUTH:
                this.connRef.current && this.connRef.current.commit();
                break;
            case STAGES.ENTITIES:
                this.entitiesRef.current && this.entitiesRef.current.commit(this.state.isWarning);
                break;
            case STAGES.FEATURES:
                this.selectedFeaturesRef.current && this.selectedFeaturesRef.current.commit();
                break;
            case STAGES.FIELDS:
                this.fieldsRef.current &&
                    this.fieldsRef.current
                        .commit()
                        .then((answer: string) => {
                            if (answer === 'no') {
                                // go to ds settings page
                                const url =
                                    reverse(routes.admin.account.dataSource.index, {
                                        dataSourceId: this.ssuManager.getDataSourceId(),
                                    }) + '?ssu&msg=ssu';
                                this.props.history.push(url);
                                return;
                            } else {
                                // go to map page
                                const ds = {
                                    id: this.ssuManager.getDataSourceId(),
                                    isImportEnabled: true,
                                };
                                dsManagerFactory
                                    .getManager(this.ssuManager.getAccountId())
                                    .save(ds, false)
                                    .then(() => {
                                        this.props.history.push(routes.client);
                                    });
                            }
                        })
                        .then(() => {
                            this.ssuManager.saveStage(STAGES.FINISH);
                        });
                break;
            case STAGES.INTRO:
                this.handleSuccess();
                break;
            default:
                return;
        }
    };
}

export default withTranslation('translations', { withRef: true })(withRouter(SelfSignUp));
