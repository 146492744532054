import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { locationTrackingMessageStorage } from './LocationTrackingMessageStorage';

interface iLocationTrackingMessageProps extends WithTranslation {
    onNotAccept?: () => void;
    onAccept?: () => void;
}

interface iLocationTrackingMessageState {
    open: boolean;
}

class LocationTrackingMessage extends React.PureComponent<
    iLocationTrackingMessageProps,
    iLocationTrackingMessageState
> {
    constructor(props: iLocationTrackingMessageProps) {
        super(props);

        this.state = {
            open: true,
        };
    }

    handleNotAccept = () => {
        locationTrackingMessageStorage.setWasShown();

        const { onNotAccept } = this.props;
        onNotAccept && onNotAccept();

        this.setState({ open: false });
    };

    handleAccept = () => {
        locationTrackingMessageStorage.setWasShown();
        locationTrackingMessageStorage.setWasAccepted();

        const { onAccept } = this.props;
        onAccept && onAccept();

        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;

        return (
            <Dialog open={open}>
                <DialogTitle>{this.props.t('location_tracking_message.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{this.props.t('location_tracking_message.text')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleNotAccept}
                        color="primary"
                        data-testid="location_tracking_message.btn_no"
                    >
                        {this.props.t('location_tracking_message.btn_no')}
                    </Button>
                    <Button
                        onClick={this.handleAccept}
                        color="primary"
                        autoFocus
                        data-testid="location_tracking_message.btn_yes"
                    >
                        {this.props.t('location_tracking_message.btn_yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(LocationTrackingMessage);
