import React from 'react';
import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import File from '../../../service/workflow_actions/forms/fields/File';
import FileCollection from '../../FileInput/FileCollection';
import { fileManager } from '../../../service/File/FileManager';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);

class FileField extends AbstractField {
    constructor(props) {
        super(props);

        this.state = {
            loading: props.field.getValue().length > 0,
            inProgress: false,
            files: [],
        };
    }

    componentDidMount() {
        if (this.props.field.getValue().length > 0) {
            this.initFiles(this.props.field.getValue());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prev = prevProps.field.getValue();
        const curr = this.props.field.getValue();
        const diff = prev.filter((value) => !curr.includes(value));
        if (diff.length > 0) {
            this.setState({ loading: true });
            this.initFiles(curr);
        }
    }

    initFiles = (files) => {
        fileManager.transformFileIdsToFiles(this.props.accountId, files).then((files) => {
            this.setState({ files, loading: false });
        });
    };

    handleFilesChange = (files) => {
        this.setState({ files }, () => {
            const fileIds = files.filter((file) => file.id).map((file) => file.id);
            this.props.onChange(this.props.field, fileIds);
        });
    };

    handleUploadProgress = (inProgress) => {
        const eventType = inProgress ? events.WORKFLOW_FILE_UPLOAD_IN_PROGRESS : events.WORKFLOW_FILE_UPLOAD_COMPLETE;
        dispatcher.dispatch(eventType, {
            keepActionButtonsEnabled: this.props.field.getKeepActionButtonsEnabled(),
        });
    };

    renderInput(field, errors) {
        const fileMeta = {
            width: field.getImageTargetMaxWidth(),
            height: field.getImageTargetMaxHeight(),
            isTranscriptEnabled: field.getIsTranscriptEnabled(),
            isSummaryEnabled: field.getIsSummaryEnabled(),
            language: field.getLanguage(),
            prompt: field.getPrompt(),
        };
        return (
            <FileCollection
                className="field-file-input"
                files={this.state.files}
                accountId={this.props.accountId}
                onChange={this.handleFilesChange}
                onUploadProgress={this.handleUploadProgress}
                minCount={field.getMinCount()}
                maxCount={field.getMaxCount()}
                fileMeta={fileMeta}
                allowedTypes={field.getAllowedTypes()}
                allowedMethods={field.getAllowedMethods()}
                fileFieldLabel={field.getLabel()}
                loading={this.state.loading}
                disabled={field.getIsDisabled()}
                error={errors.size > 0}
                helperText={errors.get('value') ? t(errors.get('value')) : ''}
                instantDelete
            />
        );
    }
}

FileField.defaultProps = {
    autoFocus: false,
};

FileField.propTypes = {
    accountId: PropTypes.number.isRequired,
    field: PropTypes.instanceOf(File).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default FileField;
