import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { icons } from './icons';

const styles = (theme: Theme) =>
    createStyles({
        customIcon: {
            fill: 'currentColor',
        },
        primary: {
            fill: theme.palette.icon.primary,
        },
        secondary: {
            fill: theme.palette.icon.tertiary,
        },
    });

type IconProps = WithStyles<typeof styles> & {
    className?: string;
    style?: object;
    name: keyof typeof icons;
    color?: 'primary' | 'secondary';
    size?: number;
};
const CustomIcon: React.FC<IconProps> = ({ className, name, color, classes, style, size }) => {
    const IconComponent = icons[name] || null;
    const otherProps = { style } as any;

    if (typeof size === 'number') {
        const value = `${size}px`;
        otherProps.width = value;
        otherProps.height = value;
    }

    if (IconComponent) {
        return (
            <IconComponent className={clsx(classes.customIcon, className, color && classes?.[color])} {...otherProps} />
        );
    }

    return null;
};

export default withStyles(styles)(CustomIcon);
