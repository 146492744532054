import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';

class LimitationsManager extends BackendService {
    constructor() {
        super();

        this.limitations = null;

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, (user) => {
            if (!user || !user.accountId) {
                this.limitations = [];
                return;
            }

            this.load(user.accountId)
                .then((limitations) => {
                    this.limitations = limitations;
                })
                .then(() => {
                    this.refresh();
                });
        });
    }

    refresh() {
        dispatcher.dispatch(events.EVENT_ACCOUNT_LIMITATIONS_CHANGED, { limitations: this.limitations });
    }

    load(accountId) {
        const url = reverse(apiRoutes.account.limitations, { accountId });
        return this.requestApi(url, 'GET').then((response) => {
            return response.limitations;
        });
    }

    save(accountId, limitations) {
        const url = reverse(apiRoutes.account.limitations, { accountId });
        return this.requestApi(url, 'POST', limitations).then((response) => {
            this.limitations = response.limitations;
            this.refresh();
            return response.limitations;
        });
    }

    getCurrent() {
        return this.limitations;
    }
}

export const limitationsManager = new LimitationsManager();
