import React from 'react';
import { Grid, IconButton, Tooltip, Icon, Typography, Badge, MenuItem, Menu } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../locales/i18n';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import AbstractField, { FIELD_TYPES } from '../../../service/workflow_actions/forms/fields/AbstractField';
import DottedLink from '../../DottedLink';
import Button from '../../../service/workflow_actions/forms/fields/Button';
import { withStyles } from '@material-ui/core/styles';
import { userManager } from '../../../service/UserManager';

const t = i18n.t.bind(i18n);

const DragHandle = SortableHandle((props) => (
    <IconButton data-testid="workflow_action.field_item.drag_button" onContextMenu={props.onContextMenu}>
        <Icon onContextMenu={props.onContextMenu}>dehaze</Icon>
    </IconButton>
));

const styles = {
    root: {
        fontSize: '1.1rem',
    },
};

const StyledTypography = withStyles(styles)(Typography);

export class FieldItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorPosition: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleChange() {
        this.props.onChange(this.props.field);
    }

    handleRemove() {
        this.props.onRemove(this.props.field);
    }

    handleOpenMenu = (event) => {
        event.preventDefault();
        this.setState({ anchorPosition: { top: event.clientY, left: event.clientX } });
    };

    handleCloseMenu = () => {
        this.setState({ anchorPosition: null });
    };

    onMenuItemClick = (type) => {
        this.props.handleCreateItem(type, this.props.fieldIndex + 1);
        this.handleCloseMenu();
    };

    render() {
        const isRoleSuperAdmin = userManager.isRoleSuperAdmin();
        const fieldTypes = isRoleSuperAdmin
            ? Object.values(FIELD_TYPES)
            : Object.values(FIELD_TYPES).filter((it) => ![FIELD_TYPES.STYLE, FIELD_TYPES.HTML].includes(it));
        return (
            <Grid
                onContextMenu={this.handleOpenMenu}
                container
                alignItems="center"
                spacing={1}
                style={{ marginBottom: 20 }}
            >
                <Grid item xs={1}>
                    <DragHandle />
                </Grid>
                <Menu
                    anchorPosition={this.state.anchorPosition}
                    anchorReference="anchorPosition"
                    open={this.state.anchorPosition !== null}
                    onClose={this.handleCloseMenu}
                    disableAutoFocusItem
                >
                    {fieldTypes.map((type) => {
                        return (
                            <MenuItem
                                key={type}
                                onClick={this.onMenuItemClick.bind(this, type)}
                                data-testid={'workflow_actions.forms.field.type.' + type}
                            >
                                {t('workflow_actions.forms.field.type.' + type)}
                            </MenuItem>
                        );
                    })}
                </Menu>
                <Grid item xs={2}>
                    {t('workflow_actions.forms.field.type.' + this.props.field.getType())}
                </Grid>
                <Grid item xs={8}>
                    <DottedLink onClick={this.handleChange} data-testid="workflow_actions.forms.field">
                        <Grid container spacing={5} alignItems="center">
                            <Grid item>
                                <StyledTypography variant="h6">
                                    {this.props.field.getLabel()
                                        ? this.props.field.getLabel()
                                        : '[' + t('workflow_actions.forms.field.no_label') + ']'}
                                    {this.props.field.getIsRequired() && (
                                        <Typography display="inline" component="span" color="error">
                                            {' '}
                                            *
                                        </Typography>
                                    )}
                                </StyledTypography>
                            </Grid>
                            {this.props.field.getHandlers().length > 0 && (
                                <Grid item>
                                    <Badge
                                        badgeContent={
                                            this.props.field instanceof Button
                                                ? t('workflow_actions.forms.field.onClick')
                                                : t('workflow_actions.forms.field.onChange')
                                        }
                                        color="primary"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DottedLink>
                    {this.props.field.getDescription() && (
                        <Typography display="inline" component="span" color="textSecondary">
                            {this.props.field.getDescription()}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t('remove')}>
                        <IconButton
                            color="secondary"
                            onClick={this.handleRemove}
                            data-testid="workflow_actions.forms.field.remove"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}

FieldItem.propTypes = {
    field: PropTypes.instanceOf(AbstractField).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    handleCreateItem: PropTypes.func.isRequired,
    fieldIndex: PropTypes.number,
};

const SortableItem = SortableElement((props) => <FieldItem {...props} />);

export default SortableItem;
