import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../events';
import dispatcher from './dispatcher';
import subscriptionManagerFactory from './SubscriptionManager';
import { routes } from '../routes';
import { adapterManager } from './AdapterManager';
import mapSettingsManager from './MapSettingsManager';
import mapStateManagerFactory from './MapStateManagerFactory';
import { MAP_MODULE } from '../components/Permissions/constants';
import { logDebug, nativeAppFriendlyRedirect, weAreInIframe } from '../utils';
import PopupJobFactory, { PopupError } from './PopupJobFactory';
import qs from 'qs';
import InAppBrowserManager from './InAppBrowserManager';
import TurnOnTrackingPopupManager from './BgGeo/TurnOnTrackingPopupManager';
import BgGeoManager from './BgGeo/BgGeoManager';
import { sharedMapStateManager } from './SharedMapStateManager';
import aesEncryptor from 'service/AesEncryptor';
import { ssoAuthProviderStorage } from 'service/Login';
import DeviceInfoManager from './MobileApp/DeviceInfoManager';
import { ImpersonateMode as SwitchMode } from 'api/types';
import { SubscriptionPlanTier, SubscriptionStatus } from 'service/types';
import { Account } from '../interfaces/account'; // eslint-disable-line no-unused-vars
import { accountsManager } from './AccountsManager';
import cloneDeep from 'lodash/cloneDeep';
import EnqueueSnackbarService from './MapPage/EnqueueSnackbarService';

export const KEY_CONFIRM_PASSWORD = 'confirmPassword';

export const USER_ROLE = {
    SUPERADMIN: 'superadmin',
    ADMIN: 'admin',
    SHARED_MAP_USER: 'shared_map_user',
};

let trackedSharedMapLoad = false;

class UserManager extends BackendService {
    constructor() {
        super();

        this.enqueueSnackbarService = new EnqueueSnackbarService();

        this.users = new Map();
        this.accounts = new Map();
        this.currentUser = undefined;
        this.currentAccount = undefined;
        this.requestedAccounts = [];
        this.currentMapForm = null;
        this.sharedMapAllowLayers = false;
        this.isCurrentAccountShardUpdated = null;
        this.shardDeleted = null;

        dispatcher.subscribe(events.EVENT_ACCOUNT_PERMISSIONS_SAVED, this, () => {
            this.users = new Map();
            this.requestedAccounts = [];
        });

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.currentAccount === null || this.currentAccount.id !== subscription.accountId) {
                return;
            }
            this.currentAccount.subscription = subscription;
            dispatcher.dispatch(events.ACCOUNT_UPDATED, this.currentAccount);
        });

        dispatcher.subscribe([events.EVENT_ROLES_CHANGED, events.WS_ROLES_UPDATED], this, (data) => {
            const role = data.roles.find((p) => p.id === this.currentUser.role.id);
            if (role) {
                this.currentUser.role = role;
                this.setCurrentUser(this.currentUser);
                dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, this.currentUser);
            }
        });

        dispatcher.subscribe(events.WS_USER_UPDATED, this, () => {
            this.requestCurrentUser(true).then((user) => {
                this.setCurrentUser(user);
                dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, this.currentUser);
            });
        });

        dispatcher.subscribe(events.ACCOUNT_UPDATED, this, (account) => {
            if (this.currentAccount === null || this.currentAccount.id !== account.id) {
                return;
            }

            this.currentAccount = account;
            // todo this causes WSManager to connect twice after hey
            dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, this.currentUser);
        });

        dispatcher.subscribe(
            [events.EVENT_USER_CREATED, events.EVENT_USER_CHANGED, events.SHARED_MAP_SAVED, events.WS_USERS_DELETED],
            this,
            (eventData, eventType) => {
                this.users = new Map();
                this.accounts = new Map();
                this.requestedAccounts = [];

                if (eventType !== events.WS_USERS_DELETED) {
                    return;
                }

                const { message, variant } = eventData;

                switch (variant) {
                    case 'info':
                        this.enqueueSnackbarService.sendCustomMessage(message, { variant: variant });
                        break;
                    case 'success':
                        this.enqueueSnackbarService.sendSuccessMessage(message);
                        break;
                    case 'error':
                        this.enqueueSnackbarService.sendErrorMessage(message);
                        break;
                    default:
                        break;
                }
            },
        );

        dispatcher.subscribe(events.WS_FIX_SHARD_PROGRESS, this, ({ processing }) => {
            this.setCurrentAccountShardUpdated(!processing);
        });
    }

    refreshUser(user) {
        this.users.set(user.id, user);
    }

    /**
     * @return {?UserData}
     */
    getCurrentUser() {
        return this.currentUser;
    }

    isCurrentUser(user) {
        return user && this.currentUser && user.id === this.currentUser.id;
    }

    /**
     * n.b.: return null for super and undefined before hey
     * @return {Account}
     */
    getCurrentAccount() {
        return this.currentAccount;
    }

    getCurrentMapForm() {
        return this.currentMapForm;
    }

    requestCurrentUser(force = false) {
        if (!force && this.currentUser !== undefined) {
            return Promise.resolve(this.currentUser);
        }

        return this.hey().then((response) => {
            if (response.user !== null) {
                this.refreshUser(response.user);
            }

            return response.user;
        });
    }

    requestCurrentAccount() {
        if (this.currentAccount !== undefined) {
            return Promise.resolve(this.currentAccount);
        }

        return this.hey().then((response) => {
            return response.account;
        });
    }

    setCurrentAccountShardUpdated(isShardUpdated) {
        this.isCurrentAccountShardUpdated = isShardUpdated;
        dispatcher.dispatch(events.SHARD_UPDATED_STATE_CHANGED, { isShardUpdated });
    }

    getCurrentAccountShardUpdated() {
        return this.isCurrentAccountShardUpdated;
    }

    getIsShardDeleted() {
        return this.isShardDeleted;
    }

    getTimezone() {
        const user = this.getCurrentUser();
        const account = this.getCurrentAccount();

        const userTimezone = user?.timezone || account?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

        return userTimezone;
    }

    hey() {
        return this.requestApi(apiRoutes.hey, 'GET').then((rawResponse) => {
            const response = this._denormalizeHeyResponse(rawResponse);

            const newUserId = response.user ? response.user.id : null;
            const oldUserId = this.currentUser ? this.currentUser.id : this.currentUser;

            if (newUserId === oldUserId) {
                return response;
            }
            if (response.user) {
                this.refreshUser(response.user);
                this.initMapState(response.user.id, response.map_settings, response.shared_map_settings);
            }

            this.setCurrentUser(response.user);
            this.currentAccount = response.account;
            this.setCurrentAccountShardUpdated(response.isShardUpdated);
            this.isShardDeleted = response.isShardDeleted;

            this.initSubscriptionManager();

            const countryCode = response.account ? response.account.countryCode : null;
            this.initSettings(response.map_settings, response.shared_map_settings, response.map_styles, countryCode);

            if (
                this.userHasAccessTo(MAP_MODULE.NAME, MAP_MODULE.FEATURES.SEARCH_FILTER.NAME) &&
                this.currentUser.role.formId
            ) {
                this.currentMapForm = {
                    id: this.currentUser.role.formId,
                    searchBarFields: this.currentUser.role.searchBarFields,
                };
            } else {
                this.currentMapForm = null;
            }

            dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, response.user);

            if (sharedMapStateManager.isSharedMap() && !trackedSharedMapLoad) {
                this.requestApi(apiRoutes.sharedMaps.track, 'POST').then(() => {
                    trackedSharedMapLoad = true;
                });
            }

            return response;
        });
    }

    initSubscriptionManager() {
        if (this.currentAccount !== null) {
            subscriptionManagerFactory.getManager(this.currentAccount.id);
        }
    }

    initSettings(mapSettings, sharedMapSettings, mapStyles, countryCode) {
        mapSettingsManager.init(mapSettings, mapStyles, countryCode);
        if (this.currentUser) {
            if (sharedMapSettings) {
                this.sharedMapAllowLayers = sharedMapSettings.allowLayers;
            }
        }
    }

    initMapState(userId, mapSettings, sharedMapSettings) {
        const manager = mapStateManagerFactory.getManager(userId);

        logDebug('MapStateManager init map state from backend');
        manager.init(mapSettings?.mapState, mapSettings?.version, sharedMapSettings);
    }

    getSharedMapAllowLayers() {
        return this.sharedMapAllowLayers;
    }

    isRoleUnknown() {
        return this.currentUser === undefined;
    }

    isRoleGuest() {
        return this.currentUser === null;
    }

    isSharedMapUser() {
        return this._backend.isSharedMap();
    }

    isSharedMapUserOrProfile() {
        return this.isSharedMapUser() === true || this.currentUser.role.forSharedMap === true;
    }

    isRoleSuperAdmin() {
        return this.currentUser && this.currentUser.role.code === USER_ROLE.SUPERADMIN;
    }

    isRoleAdmin() {
        return this.currentUser && this.currentUser.role.code === USER_ROLE.ADMIN;
    }

    isSwitched() {
        return this.currentUser && this._backend.getImpersonateMode() !== null;
    }

    isSwitchedSuperAdmin() {
        return this.isSwitched() && this._backend.getUserRoleFromJWTPayload() === 'ROLE_SUPER_ADMIN';
    }

    isSwitchedAdmin() {
        return this.isSwitched() && this._backend.getUserRoleFromJWTPayload() === 'ROLE_ADMIN';
    }

    getSwitchedUserId() {
        return this._backend.getUserIdFromJWTPayload();
    }

    getSwitchedUserRole() {
        return this._backend.getUserRoleFromJWTPayload();
    }

    getSwitchMode() {
        if (this.isSharedMapUser() || !this.isSwitched()) {
            return null;
        }

        return this._backend.getImpersonateMode();
    }

    setSignUpStage(stage, data) {
        return this.requestApi(reverse(apiRoutes.sso.stage, { stage }), 'POST', data).then((response) => {
            this.currentAccount = response.account;
            return response.user;
        });
    }

    forgotPassword(email) {
        return this.requestApi(reverse(apiRoutes.forgotPassword), 'POST', { email: email });
    }

    validResetToken(token) {
        return this.requestApi(reverse(apiRoutes.resetPassword), 'POST', { accessRecoveryToken: token });
    }

    resetPassword(password, token) {
        return this.requestApi(reverse(apiRoutes.resetPassword), 'POST', { accessRecoveryToken: token, password });
    }

    setCurrentUser(user) {
        this.currentUser = user;
    }

    logout() {
        if (!this.currentUser) {
            return Promise.resolve();
        }

        /**
         * todo this must be executed on every BackendApi.endSession() call
         * @see BackendApi.endSession
         */
        InAppBrowserManager.clearData();
        TurnOnTrackingPopupManager.forgetPopupWasShown();
        void BgGeoManager.onLogout();

        return this.requestApi(apiRoutes.logout, 'POST');
    }

    login(login, password, captcha) {
        return this.requestApi(apiRoutes.login, 'POST', { login, password, captcha }).then((response) => {
            dispatcher.dispatch(events.EVENT_USER_LOGIN);

            return response;
        });
    }

    logoutUser(userId) {
        return this.requestApi(reverse(apiRoutes.logoutUser, { userId }), 'POST');
    }

    async linkAccountCheck(username) {
        let response;

        try {
            response = await this.requestApi(apiRoutes.sso.linkCheck, 'POST', { login: username });
        } catch (e) {
            throw e;
        }

        return response;
    }

    async linkAccount(username, password, captcha) {
        await this.login(username, password, captcha);

        let response;

        try {
            response = await this.requestApi(apiRoutes.sso.link, 'POST');
        } catch (e) {
            await this.logout();
            throw e;
        }

        await this.hey();

        return response;
    }

    switchUser(id, mode) {
        dispatcher.dispatch(events.EVENT_USER_SWITCH, id);

        this._backend.switchUser(id, mode);
        return this.hey();
    }

    switchBackUser() {
        this._backend.switchBackUser();
        return this.hey();
    }

    /**
     * @param {number} accountId
     * @return {Promise<AccountData>}
     */
    getAccount(accountId) {
        if (this.accounts.has(accountId)) {
            const account = this.accounts.get(accountId);
            return Promise.resolve(account);
        }

        const url = reverse(apiRoutes.account, { accountId });
        return this.requestApi(url, 'GET')
            .then((account) => accountsManager.denormalizeAccount(account))
            .then((account) => {
                this.accounts.set(accountId, account);
                return account;
            });
    }

    getAccountUser(accountId, userId) {
        if (this.users.has(userId)) {
            const user = this.users.get(userId);
            if (user.accountId !== accountId) {
                throw this.constructor.getError({
                    code: 404,
                    message: 'Unknown user',
                });
            }
            return Promise.resolve(user);
        }
        return this.getAccountUsers(accountId).then((users) => {
            for (let user of users) {
                if (user.id === userId) {
                    return user;
                }
            }
            throw this.constructor.getError({
                code: 404,
                message: 'Unknown user',
            });
        });
    }

    /**
     * @param {number} accountId
     * @return {Promise<User.User[]>}
     */
    getAccountUsers(accountId) {
        if (this.requestedAccounts.indexOf(accountId) !== -1) {
            const result = [];
            for (let user of this.users.values()) {
                if (user.accountId === accountId) {
                    result.push(user);
                }
            }
            return Promise.resolve(result);
        }

        return this.requestApi(reverse(apiRoutes.account.users, { accountId: accountId }), 'GET') //// ? exists?
            .then((users) => {
                this.requestedAccounts.push(accountId);
                for (let user of users) {
                    this.refreshUser(user);
                }
                return users;
            });
    }

    async findAccountUsers(accountId, filters = [], sorting = [], page = null, pageSize = null) {
        return await this.requestApi(reverse(apiRoutes.account.users.find, { accountId }), 'GET', {
            filters,
            sorting,
            page,
            pageSize,
        });
    }

    refreshAccountUsers(accountId) {
        this.requestedAccounts = this.requestedAccounts.filter((id) => id !== accountId);

        return this.getAccountUsers(accountId).then(() => {
            const user = this.users.get(this.currentUser.id);
            if (user) {
                this.currentUser.routingPreferences = user.routingPreferences;
                dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, user);
            }
        });
    }

    // this causes two consequent EVENT_CURRENT_USER_CHANGED
    // when first is fired, userManager.getCurrentUser() returns old data
    // when second is fired, userManager.getCurrentUser() returns updated data
    saveUser(user, password, callback) {
        const data = { ...user };
        if (password !== null) {
            data[KEY_CONFIRM_PASSWORD] = password;
        }
        if (!user.id) {
            const url = user.accountId
                ? reverse(apiRoutes.account.users, { accountId: user.accountId })
                : reverse(apiRoutes.users);
            return this.requestApi(url, 'POST', data).then((user) => {
                this.refreshUser(user);
                dispatcher.dispatch(events.EVENT_USER_CREATED, user);
                return user;
            });
        }

        const url = user.accountId
            ? reverse(apiRoutes.account.user, { accountId: user.accountId, userId: user.id })
            : reverse(apiRoutes.user, { userId: user.id });
        return this.requestApi(url, 'PUT', data).then((user) => {
            this.refreshUser(user);
            dispatcher.dispatch(events.EVENT_USER_CHANGED, user);
            if (this.currentUser && this.currentUser.id === user.id) {
                this.setCurrentUser(user);
                this.currentUser.calendarPreferences = user.calendarPreferences;
                dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, user);
            }

            callback && callback();

            return user;
        });
    }

    async getUsersObjectCounts(accountId, userIds) {
        return await this.requestApi(reverse(apiRoutes.account.users.objectCounts, { accountId: accountId }), 'GET', {
            userIds,
        });
    }

    resetPersonalSettings(user) {
        const url = user.accountId
            ? reverse(apiRoutes.account.user.personalSetting, { accountId: user.accountId, userId: user.id })
            : reverse(apiRoutes.user.personalSetting, { userId: user.id });
        return this.requestApi(url, 'DELETE');
    }

    /**
     * @param {Api.Settings.SaveAccountRoutingSettingsRequest} routingSettings
     * @return {Promise<Api.Settings.SaveAccountRoutingSettingsResponse>}
     */
    async saveRoutingSettings(routingSettings) {
        const accountId = this.currentAccount.id;
        const url = reverse(apiRoutes.routes.settings.save);
        const updatedSettings = await this.requestApi(url, 'POST', routingSettings);
        if (this.accounts.has(accountId)) {
            const account = this.accounts.get(accountId);
            account.routingSettings = routingSettings;
            this.accounts.set(accountId, account);
        }
        const newAccount = cloneDeep(this.currentAccount);
        newAccount.routingSettings = updatedSettings;

        dispatcher.dispatch(events.ACCOUNT_UPDATED, accountsManager.denormalizeAccount(newAccount));
        return updatedSettings;
    }

    async deleteUsers(accountId, userIds, newOwner = null) {
        try {
            return await this.requestApi(reverse(apiRoutes.account.users.delete, { accountId: accountId }), 'POST', {
                userIds: userIds,
                newOwnerId: newOwner?.id,
            });
        } catch (e) {}
    }

    static getDefaultUser(accountId = null) {
        return {
            id: null,
            name: null,
            email: null,
            role: { code: null },
            password: null,
            accountId: accountId,
            sso: false,
            externalUsers: [],
            hasCrmAccount: null,
        };
    }

    getStartUrl() {
        if (this.isRoleSuperAdmin()) {
            return routes.admin.accounts;
        }

        if (this.isRoleAdmin()) {
            return routes.client;
        }

        return routes.login;
    }

    loginVia = (provider, credentials, forceWorkViaChildWindow) => {
        // remember chosen adapter for oauth.html
        ssoAuthProviderStorage.setAdapter(provider);
        ssoAuthProviderStorage.setCredentials(provider, credentials);

        return adapterManager.forId(provider).then((adapter) => {
            if (adapter.interface.hasOwnProperty('sso')) {
                return this.loginViaSso(provider, forceWorkViaChildWindow);
            } else if (adapter.interface.hasOwnProperty('ssoauth')) {
                return this.loginViaOauth(provider, credentials, forceWorkViaChildWindow);
            } else {
                throw new Error('Unable to sign in using the specified identity provider.');
            }
        });
    };

    loginViaSso = (provider, forceWorkViaChildWindow) => {
        if (!weAreInIframe() && !forceWorkViaChildWindow) {
            // same window redirect way
            return adapterManager.forId(provider).then((adapter) => {
                nativeAppFriendlyRedirect(adapter.interface.sso + window.location.search);
            });
        }

        // old way with child window
        let ssoRefreshToken;
        return adapterManager
            .forId(provider)
            .then((adapter) => {
                return adapter.interface.sso;
            })
            .then((url) => {
                // открыть данный url в popup, в результате получим sso-данные или ошибку
                return PopupJobFactory.getService(url + window.location.search).request();
            })
            .then((response) => {
                const { sso_status, signup_stage, signup_data, error, error_data, rt } = response;

                if (sso_status === 'error') {
                    return reverse(routes.client) + '?error=' + error + '&' + qs.stringify(error_data); //snackbar?
                }
                if (sso_status === 'signup') {
                    return reverse(routes.signup, { provider, step: signup_stage }) + '?' + qs.stringify(signup_data);
                }

                ssoRefreshToken = rt;

                return reverse(routes.client);
            })
            .then((url) => {
                if (ssoRefreshToken) {
                    return this._backend.setSsoRefreshToken(ssoRefreshToken);
                }
                return url;
            });
    };

    loginViaOauth(provider, credentials, forceWorkViaChildWindow) {
        return this.requestCurrentUser().then((user) => {
            if (user) {
                return this.getStartUrl();
            }
            return this.doLoginViaOauth(provider, credentials, forceWorkViaChildWindow);
        });
    }

    doLoginViaOauth(provider, credentials, forceWorkViaChildWindow) {
        if (!weAreInIframe() && !forceWorkViaChildWindow) {
            // same window redirect way
            return adapterManager.forId(provider).then((adapter) => {
                let url = adapter.interface.ssoauth + '?state=1';
                if (credentials) {
                    url += '&credentials=' + encodeURIComponent(aesEncryptor.encrypt(JSON.stringify(credentials)));
                }

                nativeAppFriendlyRedirect(url);
            });
        }

        // old way with child window
        let url;
        let popupWindow;
        let ssoRefreshToken;
        return adapterManager
            .forId(provider)
            .then((adapter) => {
                url = adapter.interface.ssoauth;
            })
            .then(() => {
                popupWindow = window.open(url + '?state=1', null, 'left=100,top=100,height=610,width=520');
                if (popupWindow === null) {
                    throw new PopupError();
                }

                return PopupJobFactory.getService(url + '?state=1').request(popupWindow);
            })
            .then((grantToken) => {
                return PopupJobFactory.getService(url + '?' + qs.stringify(grantToken)).request(popupWindow);
            })
            .then((response) => {
                popupWindow.close();

                const { sso_status, signup_stage, signup_data, error, error_data, rt } = response;

                if (sso_status === 'error') {
                    return reverse(routes.client) + '?error=' + error + '&' + qs.stringify(error_data);
                }
                if (sso_status === 'signup') {
                    return reverse(routes.signup, { provider, step: signup_stage }) + '?' + qs.stringify(signup_data);
                }

                ssoRefreshToken = rt;

                return reverse(routes.client);
            })
            .then((url) => {
                if (ssoRefreshToken) {
                    return this._backend.setSsoRefreshToken(ssoRefreshToken);
                }
                return url;
            });
    }

    userHasAccessToBaseLocation = () => {
        return (
            userManager.userHasAccessTo(MAP_MODULE.NAME, MAP_MODULE.FEATURES.BASE_LOCATION.NAME) &&
            mapStateManagerFactory.getManager(this.currentUser.id).isUpdateBasePointFromSearch()
        );
    };

    /**
     * @param {string} module
     * @param {string, null} feature
     * @param {string, null} subFeature
     * @param {string, null} subSubFeature
     * @param {UserData, null} forUser
     * @returns {boolean}
     */
    userHasAccessTo = (module, feature = null, subFeature = null, subSubFeature = null, forUser = null) => {
        const user = null === forUser ? this.getCurrentUser() : forUser;
        if (!user?.role) {
            return false;
        }

        if (this._backend.getImpersonateUserId() && this._backend.getImpersonateMode() === SwitchMode.SETUP) {
            logDebug('Allow switched user setup', module, feature, subFeature, subSubFeature);
            return true;
        }

        const uiFeaturesPermission = user.role.uiFeaturesPermission;

        if (!uiFeaturesPermission[module]?.enable) {
            return false;
        }

        if (feature && !uiFeaturesPermission[module]?.features[feature]?.enable) {
            return false;
        }

        if (
            feature &&
            subFeature &&
            !uiFeaturesPermission[module]?.features[feature]?.subFeatures[subFeature]?.enable
        ) {
            return false;
        }

        if (
            feature &&
            subFeature &&
            subSubFeature &&
            !uiFeaturesPermission[module]?.features[feature]?.subFeatures[subFeature]?.subSubFeatures[subSubFeature]
                ?.enable
        ) {
            return false;
        }

        return true;
    };

    hasEssentialRestrictions() {
        const account = this.currentAccount;
        if (!account) {
            return true;
        }
        const subscription = account.subscription;
        if (!subscription) {
            return true;
        }

        return (
            subscription.plan.tier === SubscriptionPlanTier.ESSENTIAL &&
            subscription.status !== SubscriptionStatus.TRIAL
        );
    }

    isSubscriptionBlocked() {
        return !!this.currentAccount?.subscription?.isBlocked;
    }

    automationElementsManagement() {
        if (this.isRoleSuperAdmin()) {
            return true;
        }
        const subscription = this.getCurrentAccount() ? this.getCurrentAccount().subscription : null;
        return subscription && subscription.automationElementsManagement && !this.hasEssentialRestrictions();
    }

    /**
     * todo move to BgGeoManager, use info from plugin config if user is not available
     */
    heartbeat = async () => {
        const deviceInfo = await DeviceInfoManager.getCurrentInfo();
        return this.requestApi(apiRoutes.tracking.heartbeat, 'POST', deviceInfo);
    };

    isModificationAllowed() {
        const user = this.getCurrentUser();

        if (!user?.role) {
            return false;
        }

        if (this.isSwitchedAdmin() || this.isSwitchedSuperAdmin()) {
            return (
                this._backend.getImpersonateUserId() === user.id &&
                [SwitchMode.SETUP, SwitchMode.FULL].includes(this._backend.getImpersonateMode())
            );
        }

        return !this.isSharedMapUser();
    }

    /**
     * @param {Api.User.HeyRawResponse} rawResponse
     * @return {Api.User.HeyResponse}
     * @private
     */
    _denormalizeHeyResponse(rawResponse) {
        const response = { ...rawResponse };

        if (response.user) {
            response.user.externalUsers = response.user.externalUsers.length === 0 ? {} : response.user.externalUsers;
        }

        if (rawResponse.account !== null) {
            response.account = accountsManager.denormalizeAccount(rawResponse.account);
        }

        return response;
    }

    /**
     * @param {User.User[]} users
     * @return {Promise<User.User[]>}
     */
    updateUsers(users) {
        const accountId = this.currentAccount.id;
        const url = reverse(apiRoutes.account.users, { accountId });

        const data = users.map((user) => ({
            id: user.id,
            routingPreferences: user.routingPreferences,
            avatar: user.avatar,
            calendarPreferences: user.calendarPreferences,
        }));

        return this.requestApi(url, 'PUT', data).then((updatedUsers) => {
            updatedUsers.forEach((user) => {
                this.refreshUser(user);
                dispatcher.dispatch(events.EVENT_USER_CHANGED, user);
                if (this.currentUser && this.currentUser.id === user.id) {
                    this.setCurrentUser(user);
                    this.currentUser.calendarPreferences = user.calendarPreferences;
                    dispatcher.dispatch(events.EVENT_CURRENT_USER_CHANGED, user);
                }
            });
        });
    }

    getLanguage() {
        return this.currentUser.language;
    }
}

export const userManager = new UserManager();
