import { VehicleTypeTravelMode } from '../../interfaces/routing/settings';
import { Activity } from '../../interfaces/routing/route';
import { LocationValidity } from '../../interfaces/geo';

export enum DRAFT_ACTION {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

enum TRAVEL_MODE {
    CAR = VehicleTypeTravelMode.CAR,
    TRUCK = VehicleTypeTravelMode.TRUCK,
}

export enum CONFLICT_TYPE {
    EVENT = 'event',
    WORKING_HOURS = 'working_hours',
    BOTH = 'both',
}

export enum CalendarEventSyncStatus {
    PROCESS = 'process',
    SUCCESS = 'success',
    ERROR = 'error',
}

export type CalendarEventField = {
    name: string;
    value: any;
    displayValue?: string;
};

export type CalendarEvent = {
    id: string;
    title: string;
    allDay: boolean;
    backgroundColor?: string | null;
    textColor?: string | null;
    calendarId: string;
    canEdit?: boolean;
    customData?: null; //TODO: add correct type when actually start using it
    draftAction: DRAFT_ACTION;
    startDatetime: string;
    endDatetime: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    eventId?: string;
    fields?: CalendarEventField[];
    fixedTime: boolean;
    location: string | null;
    lng: string | null;
    lat: string | null;
    ownerId: number;
    draft: boolean;
    travelMode?: TRAVEL_MODE;
    versionId?: string | null;
    virtual: boolean;
    conflictType?: CONFLICT_TYPE;
    processing?: boolean;
    new?: boolean;
    headRouteActivity: Activity | null;
    routeActivity: Activity | null;
    locationValidity: LocationValidity;
    break: boolean;
    breakHash?: string;
    syncStatus?: CalendarEventSyncStatus;
    syncError?: string;
    geocoderStatus?: number;
    arrangementError?: string;
    geoStatus: number;
    routeActivityId?: string;
    isExport?: boolean;
};

export type CalendarIdResponse = {
    id: string;
};

export interface CalendarEventRepository {
    sendGetEventsRequest(
        ownerIds: number[],
        from: Date,
        to: Date,
        includeDraftEvents: boolean,
    ): Promise<CalendarIdResponse>;
    sendPutDraftEventsRequest(events: CalendarEvent[]): Promise<CalendarIdResponse>;
    sendCommitDraftRequest(): Promise<CalendarIdResponse>;
    sendRevertDraftRequest(): Promise<CalendarIdResponse>;
    sendSyncRequest(eventIds: string[]): Promise<CalendarIdResponse>;
    sendArrangementRequest(date: string, ownerId: number): Promise<CalendarIdResponse>;
}
