import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from 'locales/i18n';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import File from '../../../../service/workflow_actions/forms/fields/File';
import Hint from '../../../Hint';
import cloneDeep from 'lodash/cloneDeep';
import { isEmpty } from '../../../../utils';
import { FileType, UploadMethod } from 'interfaces/file';
import FormulaInput from '../../AbstractForm/FormulaInput';
import { Autocomplete } from '@material-ui/lab';
import { CallContext } from '../../../utils/CallContext';
import {
    AUTODETECT_LANGUAGE,
    AUTODETECT_MULTIPLE_LANGUAGES,
    TRANSCRIBE_LANGUAGES,
} from '../../../../service/File/TranscribeLanguages';

const t = i18n.t.bind(i18n);

const FullWidthControl = withStyles({
    label: {
        width: '100%',
    },
})(FormControlLabel);

const NoMarginAutocomplete = withStyles({
    inputRoot: {
        margin: 0,
    },
})(Autocomplete);

class FileForm extends AbstractForm {
    validate() {
        const field = this.state.field;

        if (field.getAllowedTypes().length === 0) {
            this.state.errors.set('allowedTypes', t('errors.not_empty'));
        }

        if (!field.hasType(FileType.Image)) {
            field.setImageResize(false);
        }
        if (!field.getImageResize()) {
            field.setImageTargetMaxWidth(null);
            field.setImageTargetMaxHeight(null);
        } else {
            if (!field.getImageTargetMaxWidth()) {
                this.state.errors.set('targetMaxWidth', t('errors.not_empty'));
            }
            if (!field.getImageTargetMaxHeight()) {
                this.state.errors.set('targetMaxHeight', t('errors.not_empty'));
            }
        }

        if (isEmpty(field.getMinCount()) || isEmpty(field.getMaxCount())) {
            this.state.errors.set('minCount', t('errors.not_empty'));
        }
        if (+field.getMinCount() > +field.getMaxCount()) {
            this.state.errors.set('minCount', t('workflow_actions.forms.field.file.number_of_files.error'));
        }
    }

    handleCountChange = (event) => {
        if (event.target.value < 0) {
            event.target.value *= -1;
        }
        if (event.target.value > 99) {
            event.target.value = 99;
        }
        this.handleInputChange(event);
    };

    handleImageResizeChange = (event, field) => {
        event.target.checked && isEmpty(field.getImageTargetMaxWidth()) && field.setImageTargetMaxWidth(1000);
        event.target.checked && isEmpty(field.getImageTargetMaxHeight()) && field.setImageTargetMaxHeight(1000);
        this.handleInputChange(event);
    };

    handleTypeChange = (event) => {
        const name = event.target.name;
        const type = event.target.value;
        const checked = event.target.checked;

        this.setState((state) => {
            const field = cloneDeep(state.field);
            if (checked) {
                field.addAllowedType(type);
            } else {
                field.removeAllowedType(type);
            }

            let errors = state.errors;

            if (errors.has(name)) {
                errors = new Map(errors);
                errors.delete(name);
            }

            return { field, errors };
        });
    };

    handleMethodChange = (event) => {
        const name = event.target.name;
        const method = event.target.value;
        const checked = event.target.checked;

        this.setState((state) => {
            const field = cloneDeep(state.field);
            if (checked) {
                field.addAllowedMethod(method);
            } else {
                field.removeAllowedMethod(method);
            }

            let errors = state.errors;

            if (errors.has(name)) {
                errors = new Map(errors);
                errors.delete(name);
            }

            return { field, errors };
        });
    };

    handleAIChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        if (name === 'transcript') {
            this.setState((state) => {
                const field = cloneDeep(state.field);
                field.setIsTranscriptEnabled(checked);
                field.setIsSummaryEnabled(false);
                return { field };
            });
        } else if (name === 'summary') {
            this.setState((state) => {
                const field = cloneDeep(state.field);
                field.setIsSummaryEnabled(checked);
                return { field };
            });
        }
    };

    handlePromptChange(event) {
        this.setState((state) => {
            const field = cloneDeep(state.field);
            field.prompt = event.target.value ?? t('workflow_actions.forms.field.file.summary.custom_prompt');
            return { field };
        });
    }

    handleTranscriptLanguageChange(event, value) {
        this.setState((state) => {
            const field = cloneDeep(state.field);
            field.language = value ?? AUTODETECT_LANGUAGE;
            return { field };
        });
    }

    getPromptValue() {
        return !isEmpty(this.state.field.prompt)
            ? this.state.field.prompt
            : t('workflow_actions.forms.field.file.summary.custom_prompt');
    }

    render() {
        return (
            <form noValidate autoComplete="off">
                <Grid container spacing={1} alignItems="flex-end">
                    {this.renderBaseInputs(this.state.field, this.state.errors)}
                    {this.renderFieldInputs(this.state.field, this.state.errors)}
                </Grid>
            </form>
        );
    }

    renderFieldInputs(field, errors) {
        return (
            <React.Fragment>
                <Grid item container xs justify="space-between" alignItems="center">
                    {this.renderIsHiddenInput(field, errors)}
                    <Grid item container xs={8} spacing={1} alignItems="flex-end" wrap="nowrap">
                        <Grid item style={{ marginBottom: 11 }}>
                            <span>{t('workflow_actions.forms.field.file.number_of_files')}: </span>
                            {this.state.errors.get('minCount') && (
                                <FormHelperText error>{this.state.errors.get('minCount')}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t('workflow_actions.forms.field.file.min_count')}
                                fullWidth
                                margin="dense"
                                name="minCount"
                                type="number"
                                data-testid="workflow_actions.open_form.field.min_count"
                                value={field.minCount ?? ''}
                                error={errors.has('minCount')}
                                InputProps={{ inputProps: { min: 0 }, disableUnderline: false }}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleCountChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t('workflow_actions.forms.field.file.max_count')}
                                fullWidth
                                margin="dense"
                                name="maxCount"
                                type="number"
                                data-testid="workflow_actions.open_form.field.max_count"
                                value={field.maxCount ?? ''}
                                error={errors.has('minCount')}
                                InputProps={{ inputProps: { min: 0 }, disableUnderline: false }}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleCountChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <b>{t('workflow_actions.forms.field.file.types.title')}</b>
                </Grid>
                <Grid item xs={12}>
                    <FormControl required fullWidth component="fieldset" error={this.state.errors.has('allowedTypes')}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={4}>
                                <FullWidthControl
                                    control={
                                        <Checkbox
                                            name="allowedTypes"
                                            value={FileType.Image}
                                            checked={field.hasType(FileType.Image)}
                                            onChange={this.handleTypeChange}
                                            color="primary"
                                            data-testid="workflow_actions.open_form.field.allowed_type.image"
                                        />
                                    }
                                    label={t('workflow_actions.forms.field.file.type.image')}
                                />
                            </Grid>
                            {this.renderImageResizeControl(field, errors)}
                        </Grid>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="allowedTypes"
                                    value={FileType.Document}
                                    checked={field.hasType(FileType.Document)}
                                    onChange={this.handleTypeChange}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.allowed_type.document"
                                />
                            }
                            label={t('workflow_actions.forms.field.file.type.document')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="allowedTypes"
                                    value={FileType.Audio}
                                    checked={field.hasType(FileType.Audio)}
                                    onChange={this.handleTypeChange}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.allowed_type.audio"
                                />
                            }
                            label={t('workflow_actions.forms.field.file.type.audio')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="allowedTypes"
                                    value={FileType.Video}
                                    checked={field.hasType(FileType.Video)}
                                    onChange={this.handleTypeChange}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.allowed_type.video"
                                />
                            }
                            label={t('workflow_actions.forms.field.file.type.video')}
                        />
                        {this.state.errors.get('allowedTypes') && (
                            <FormHelperText>{this.state.errors.get('allowedTypes')}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <b>{t('workflow_actions.forms.field.file.methods.title')}</b>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        required
                        fullWidth
                        component="fieldset"
                        error={this.state.errors.has('allowedMethods')}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="allowedMethods"
                                    value={UploadMethod.Camera}
                                    checked={field.hasMethod(UploadMethod.Camera)}
                                    onChange={this.handleMethodChange}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.allowed_method.camera"
                                />
                            }
                            label={
                                <React.Fragment>
                                    <span>{t('workflow_actions.forms.field.file.method.camera')}</span>{' '}
                                    <Hint>{t('workflow_actions.forms.field.file.method.camera.hint')}</Hint>
                                </React.Fragment>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="allowedMethods"
                                    value={UploadMethod.Draw}
                                    checked={field.hasMethod(UploadMethod.Draw)}
                                    onChange={this.handleMethodChange}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.allowed_method.draw"
                                />
                            }
                            label={
                                <React.Fragment>
                                    <span>{t('workflow_actions.forms.field.file.method.draw')}</span>{' '}
                                    <Hint>{t('workflow_actions.forms.field.file.method.draw.hint')}</Hint>
                                </React.Fragment>
                            }
                        />
                        {this.state.errors.get('allowedMethods') && (
                            <FormHelperText>{this.state.errors.get('allowedMethods')}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {(field.hasType(FileType.Audio) || field.hasType(FileType.Video)) && this.renderMediaOptions(field)}
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="keepActionButtonsEnabled"
                                checked={field.keepActionButtonsEnabled}
                                onChange={this.handleInputChange}
                                color="primary"
                                data-testid="workflow_actions.open_form.field.keep_action_buttons_enabled"
                            />
                        }
                        label={
                            <span>
                                {t('workflow_actions.forms.field.file.keep_action_buttons_enabled')}
                                <Hint type="tooltip" iProps={{ style: { marginLeft: 5 } }}>
                                    {t('workflow_actions.forms.field.file.keep_action_buttons_enabled.hint')}
                                </Hint>
                            </span>
                        }
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderMediaOptions = (field) => {
        return (
            <>
                <Grid item xs={12}>
                    <b>{t('workflow_actions.forms.field.file.media_options.title')}</b>
                </Grid>
                <Grid item xs={12}>
                    <FormControl required fullWidth component="fieldset" error={this.state.errors.has('allowedTypes')}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={4}>
                                <FullWidthControl
                                    control={
                                        <Checkbox
                                            name="transcript"
                                            checked={field.getIsTranscriptEnabled()}
                                            onChange={(event) => this.handleAIChange(event)}
                                            color="primary"
                                            data-testid="workflow_actions.open_form.field.transcript"
                                        />
                                    }
                                    label={
                                        <span>
                                            {t('workflow_actions.forms.field.file.transcript')}
                                            <Hint type="tooltip" iProps={{ style: { marginLeft: 5 } }}>
                                                {t('workflow_actions.forms.field.file.transcript.hint')}
                                            </Hint>
                                        </span>
                                    }
                                />
                            </Grid>
                            {field.getIsTranscriptEnabled() && (
                                <Grid item xs={8}>
                                    <NoMarginAutocomplete
                                        options={[
                                            AUTODETECT_LANGUAGE,
                                            AUTODETECT_MULTIPLE_LANGUAGES,
                                            ...TRANSCRIBE_LANGUAGES,
                                        ]}
                                        value={field.language || AUTODETECT_LANGUAGE}
                                        disableClearable
                                        fullWidth
                                        getOptionLabel={(option) => t('media_transcribe_language.' + option)}
                                        onChange={this.handleTranscriptLanguageChange.bind(this)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="language"
                                                label={
                                                    <React.Fragment>
                                                        <span>
                                                            {t('workflow_actions.forms.field.file.transcript.language')}
                                                        </span>{' '}
                                                        <Hint>
                                                            {t(
                                                                'workflow_actions.forms.field.file.transcript.language.hint',
                                                            )}
                                                        </Hint>
                                                    </React.Fragment>
                                                }
                                                data-testid="workflow_actions.open_form.field.transcript.language"
                                                placeholder={t('undefined_picklist_option')}
                                                margin="none"
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item style={{ paddingBottom: 6 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="summary"
                                        disabled={!field.getIsTranscriptEnabled()}
                                        checked={field.getIsSummaryEnabled()}
                                        onChange={(event) => this.handleAIChange(event)}
                                        color="primary"
                                        data-testid="workflow_actions.open_form.field.summary"
                                    />
                                }
                                label={
                                    <span>
                                        {t('workflow_actions.forms.field.file.summary')}
                                        <Hint type="tooltip" iProps={{ style: { marginLeft: 5 } }}>
                                            {t('workflow_actions.forms.field.file.summary.hint')}
                                        </Hint>
                                    </span>
                                }
                            />
                        </Grid>
                        {field.getIsSummaryEnabled() && (
                            <Grid item xs={12}>
                                <FormulaInput
                                    label={t('workflow_actions.forms.field.file.summary.prompt')}
                                    hint={t('workflow_actions.forms.field.file.summary.prompt.hint')}
                                    fullWidth
                                    margin="dense"
                                    name="prompt"
                                    value={this.getPromptValue()}
                                    onChange={this.handlePromptChange.bind(this)}
                                    callContext={CallContext.create()}
                                />
                            </Grid>
                        )}
                    </FormControl>
                </Grid>
            </>
        );
    };

    renderImageResizeControl = (field, errors) => {
        if (field.hasType(FileType.Image)) {
            return (
                <Grid item container xs={8} spacing={1} wrap="nowrap" alignItems="flex-end">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="imageResize"
                                    checked={field.imageResize}
                                    onChange={(event) => this.handleImageResizeChange(event, field)}
                                    color="primary"
                                    data-testid="workflow_actions.open_form.field.resize"
                                />
                            }
                            label={
                                <span>
                                    {t('workflow_actions.forms.field.file.type.image.resize')}
                                    <Hint type="tooltip" iProps={{ style: { marginLeft: 5 } }}>
                                        {t('workflow_actions.forms.field.file.type.image.resize.hint')}
                                    </Hint>
                                </span>
                            }
                        />
                    </Grid>
                    {field.getImageResize() && (
                        <React.Fragment>
                            <Grid item>
                                <TextField
                                    label={t('workflow_actions.forms.field.file.type.image.target_max_width')}
                                    fullWidth
                                    margin="none"
                                    name="targetImageMaxWidth"
                                    type="number"
                                    data-testid="workflow_actions.open_form.field.target_max_width"
                                    value={field.targetImageMaxWidth ?? ''}
                                    helperText={errors.get('targetImageMaxWidth') ?? ''}
                                    error={errors.has('targetImageMaxWidth')}
                                    InputProps={{ disableUnderline: false }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={t('workflow_actions.forms.field.file.type.image.target_max_height')}
                                    fullWidth
                                    margin="none"
                                    name="targetImageMaxHeight"
                                    type="number"
                                    data-testid="workflow_actions.open_form.field.target_max_height"
                                    value={field.targetImageMaxHeight ?? ''}
                                    helperText={errors.get('targetImageMaxHeight') ?? ''}
                                    error={errors.has('targetImageMaxHeight')}
                                    InputProps={{ disableUnderline: false }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
        return null;
    };
}

FileForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(File).isRequired,
};

export default FileForm;
