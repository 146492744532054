import * as React from 'react';
import { Fragment } from 'react';
import debounce from 'lodash/debounce';
import { placesManager } from '../../service/PlacesManager';
import { v4 as uuidv4 } from 'uuid';
import { prospectingManager } from '../../service/ProspectingManager';
import CircularProgress from '@material-ui/core/CircularProgress';
import { default as MUITextField } from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import './style.css';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { MAP_MODULE, PROSPECTING_MODULE, RECORD_MODULE } from '../Permissions/constants';
import { userManager } from '../../service/UserManager';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import viewRecordManager from '../../service/ViewRecordManager';
import MapStateManagerFactory from '../../service/MapStateManagerFactory';
import { Checkbox, Popper } from '@material-ui/core';
import { GeocoderResultStatus } from '../types';
import { centerOfBoundsOrUndefined, distance, getRoutePointAddress, parseGeoPoint } from '../../utils';
import MapBoxApiFactory from 'api/MapBoxApi';
import { parseAddressComponentsToAddressFields } from '../Prospecting/GooglePlaceStructure';
import clsx from 'clsx';
import { TextField } from 'components/UI';

const MAX_GOOGLE_COUNT_RESULT = 60;
const PREDICTION_SESSION_TIMEOUT = 3 * 60 * 1000;

class AddressLookup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            searchProcessing: false,
            prospectingProcessing: false,
            prospectsSearchQuery: null,
            predictionResults: [],
            predictionProcessing: false,
        };

        this.predictionSessionToken = null;
        this.predictionSessionTimeout = null;
        this.input = this.props.inputRef ? this.props.inputRef : React.createRef();
        this.searchAddress = debounce(this.searchAddress, 500);
        this.prospectSnackBarKey = null;
        this.addressLookupRef = React.createRef();
        this.popperRef = React.createRef();

        this.pendingAutocompleteAddress = null;
        this.pendingDetailsPlaceId = null;
    }

    get center() {
        return centerOfBoundsOrUndefined(this.props.mapBounds) ?? null;
    }

    get mapRadius() {
        const { mapBounds } = this.props;
        if (!mapBounds) {
            return null;
        }
        return Math.ceil(
            distance(
                { lat: mapBounds.minLat, lng: mapBounds.minLng },
                { lat: mapBounds.maxLat, lng: mapBounds.maxLng },
            ) / 2,
        ).toFixed(0);
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_PROSPECTING_DISABLED], this, () => {
            this.handleCloseProspectingAlert();
        });
    }

    componentWillUnmount() {
        this.pendingAutocompleteAddress = null;
        this.pendingDetailsPlaceId = null;
        dispatcher.unsubscribeFromAllEvents(this);
        if (this.props.prospectingEnable) {
            prospectingManager.disableProspectingMode();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.address !== this.props.address && this.props.address.length === 0) {
            this.dropPredictionSession();
        }

        if (
            this.props.prospectingEnable &&
            this.prospectSnackBarKey === null &&
            prevProps.mapBounds !== this.props.mapBounds &&
            prospectingManager.isProspectModeEnable()
        ) {
            this.notifyAboutNeedReloadProspecting();
        }
    }

    searchAddress = (address) => {
        if (address.length < 3) {
            return;
        }

        this.pendingAutocompleteAddress = address;
        this.setState({
            searchProcessing: true,
            predictionProcessing: true,
        });

        const { accountId } = this.props;
        const language = userManager.getCurrentUser()?.language ?? userManager.getCurrentAccount()?.language ?? null;

        placesManager
            .searchRecords(address, accountId)
            .then((searchResults) => {
                this.setState(
                    {
                        searchResults: searchResults.length ? searchResults : [],
                        searchProcessing: false,
                    },
                    () => {
                        this.changeShowResults(true);
                    },
                );
            })
            .catch((error) => {
                this.setState({
                    searchProcessing: false,
                });
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });

        const predictionSessionToken = this.initPredictionSession();

        this.getPlacesAutocomplete(
            address,
            this.center,
            this.mapRadius > 50000 ? null : this.mapRadius,
            predictionSessionToken,
            language,
        )
            .then((response) => {
                if (response.status === GeocoderResultStatus.NO_PROXY) {
                    this.dropPredictionSession();
                }
                if (address !== this.pendingAutocompleteAddress) {
                    return;
                }
                this.pendingAutocompleteAddress = null;

                const predictionResults = response?.predictions || [];

                this.setState(
                    {
                        predictionResults,
                        predictionProcessing: false,
                    },
                    () => {
                        this.changeShowResults(true);
                    },
                );
            })
            .catch((error) => {
                if (address !== this.pendingAutocompleteAddress) {
                    return;
                }
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    predictionProcessing: false,
                });
            });
    };

    dropPredictionSession() {
        const predictionSessionToken = this.predictionSessionToken;
        this.predictionSessionToken = null;
        clearTimeout(this.predictionSessionTimeout);

        return predictionSessionToken;
    }

    initPredictionSession() {
        if (this.predictionSessionToken !== null) {
            return this.predictionSessionToken;
        }

        this.predictionSessionToken = uuidv4();

        clearTimeout(this.predictionSessionTimeout);
        this.predictionSessionTimeout = setTimeout(() => {
            this.predictionSessionToken = null;
        }, PREDICTION_SESSION_TIMEOUT);

        return this.predictionSessionToken;
    }

    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            const { predictionResults, searchResults } = this.state;
            if (predictionResults.length) {
                this.handlePrediction(predictionResults[0].placeId);
                return;
            }
            if (searchResults.length) {
                const result = searchResults[0];
                const lat = parseFloat(result.position?.lat);
                const lng = parseFloat(result.position?.lng);
                this.handleSearch({
                    lat: isNaN(lat) ? null : lat,
                    lng: isNaN(lng) ? null : lng,
                    entityId: result.entityId,
                    recordId: result.id,
                    address: result.address || null,
                    objectName: result.name,
                    countryShort: result.addressData ? result.addressData.countryShort : null,
                    addressFields: result.addressFields ?? [],
                });
            }
        }
    };

    handleAddressChange = (event) => {
        const { value } = event.target;
        this.props.onSelectedAddress && this.props.onSelectedAddress(null);
        this.props.onChangeAddress && this.props.onChangeAddress(value);
        this.searchAddress(value);
        if (!value.length) {
            this.dropPredictionSession();
        }
    };

    onFocus = () => {
        this.changeShowSelect(true);
    };

    changeShowSelect(showSelects) {
        this.props.onChangeShowSelect && this.props.onChangeShowSelect(showSelects);
    }

    changeShowResults(showResults) {
        this.props.onChangeShowResult && this.props.onChangeShowResult(showResults);
        this.updatePopper();
    }

    loadProspecting(queryText, reload = false) {
        const apiKeyGoogle = this.props.apiKeys?.google ?? '';
        const prospectsSearchQuery = queryText === null ? this.state.prospectsSearchQuery : queryText;
        if (prospectsSearchQuery === null || !this.props.mapBounds) {
            return;
        }
        prospectingManager
            .loadPoints(this.props.mapBounds, prospectsSearchQuery, apiKeyGoogle, reload)
            .then((prospectingPlaces) => {
                this.handleCloseProspectingAlert();
                if (prospectingManager.isProspectModeEnable()) {
                    if (prospectingPlaces.size === 0) {
                        const message = this.props.t('address_lookup.prospect.prospect_no_found');
                        this.props.enqueueSnackbar && this.props.enqueueSnackbar(message, { variant: 'warning' });
                    }

                    // this is incorrect condition becouse results are filtered by viewport
                    if (prospectingPlaces.size === MAX_GOOGLE_COUNT_RESULT) {
                        const message = this.props.t('address_lookup.prospect.max_60_points');
                        this.props.enqueueSnackbar && this.props.enqueueSnackbar(message, { variant: 'info' });
                    }
                }
                this.setState(
                    {
                        prospectingProcessing: false,
                    },
                    () => {
                        this.changeShowResults(false);
                        this.changeShowSelect(false);
                    },
                );
            })
            .catch(() => {
                prospectingManager.disableProspectingMode();
                this.setState(
                    {
                        searchResults: [],
                        prospectingProcessing: false,
                    },
                    () => {
                        this.changeShowSelect(true);
                    },
                );
            });
        this.setState(
            {
                prospectsSearchQuery,
                prospectingProcessing: true,
            },
            () => {
                this.notifyAboutReloadProspects();
            },
        );
    }

    notifyAboutNeedReloadProspecting() {
        const action = () => (
            <Fragment>
                {
                    <span className="notify-update-prospecting" onClick={this.handleClickReFindProspecting}>
                        {this.props.t('address_lookup.prospect.update_result')}
                    </span>
                }
                <i className="fas fa-times" style={{ cursor: 'pointer' }} onClick={this.handleCloseProspectingAlert} />
            </Fragment>
        );
        this.prospectSnackBarKey = this.props.enqueueSnackbar('', {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            variant: 'info',
            action,
            persist: true,
        });
    }

    notifyAboutReloadProspects = () => {
        this.props.closeSnackbar(this.prospectSnackBarKey);
        this.prospectSnackBarKey = this.props.enqueueSnackbar(
            this.props.t('address_lookup.prospect.updating_search_result'),
            {
                variant: 'info',
                autoHideDuration: undefined,
            },
        );
    };

    handleClickFindProspecting = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.input === null || !this.input?.current?.value) {
            return;
        }

        this.loadProspecting(this.input.current.value);
        prospectingManager.enableProspectingMode();
    };

    handleClickReFindProspecting = () => {
        this.loadProspecting(this.state.prospectsSearchQuery, true);
    };

    handleCloseProspectingAlert = () => {
        if (this.prospectSnackBarKey == null) {
            return;
        }
        this.props.closeSnackbar(this.prospectSnackBarKey);
        this.prospectSnackBarKey = null;
    };

    onClickCoordinate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setCoordinates(this.props.address);
    };

    onClickSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const lat = parseFloat(e.currentTarget.dataset.positionLat);
        const lng = parseFloat(e.currentTarget.dataset.positionLng);
        const entityId = parseInt(e.currentTarget.dataset.entityId);
        const recordId = e.currentTarget.dataset.recordId;
        const address = e.currentTarget.dataset.address;
        const objectName = e.currentTarget.dataset.name;
        const countryShort = e.currentTarget.dataset.countryShort;
        const addressFields = JSON.parse(e.currentTarget.dataset.addressComponents) || {};
        this.handleSearch({
            lat: isNaN(lat) ? null : lat,
            lng: isNaN(lng) ? null : lng,
            entityId,
            recordId,
            address: address || null,
            objectName,
            countryShort,
            addressFields,
        });
    };

    setCoordinates = (value) => {
        const coordinates = parseGeoPoint(value);
        if (coordinates === undefined) {
            return;
        }
        const { lat, lng } = coordinates;
        const addressFields = parseAddressComponentsToAddressFields([]);
        this.handleSearch({
            lat,
            lng,
            entityId: null,
            recordId: null,
            address: null,
            objectName: null,
            countryShort: null,
            addressFields,
        });
    };

    onClickPrediction = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const placeId = e.currentTarget.dataset.placeId;
        this.handlePrediction(placeId);
    };

    updatePopper = () => {
        if (this.popperRef?.current) {
            this.popperRef.current.scheduleUpdate();
        }
    };

    handlePrediction = (placeId) => {
        const language = userManager.getCurrentUser()?.language ?? userManager.getCurrentAccount()?.language ?? null;

        this.pendingDetailsPlaceId = placeId;
        this.setState({
            predictionProcessing: true,
        });

        const predictionSessionToken = this.dropPredictionSession();

        this.getPlacesDetailsAutocomplete(placeId, predictionSessionToken, language, this.props.showExactitude ?? false)
            .then((data) => {
                if (this.pendingDetailsPlaceId !== placeId) {
                    return;
                }

                const lat = parseFloat(data?.lat);
                const lng = parseFloat(data?.lng);

                const dataIsValid = !Number.isNaN(lat) && !Number.isNaN(lng) && !!data?.address;

                dataIsValid &&
                    data.status === GeocoderResultStatus.OK &&
                    this.props.onSelectedAddress &&
                    this.props.onSelectedAddress({
                        lat,
                        lng,
                        addressFields: parseAddressComponentsToAddressFields(data?.details?.address_components ?? []),
                        address: data.address,
                        entityId: null,
                        recordId: null,
                        objectName: null,
                        countryShort: data.addressData ? data.addressData.countryShort : null,
                        exact: data.exact,
                    });
                this.setState(
                    {
                        searchResults: [],
                        predictionResults: [],
                        predictionProcessing: false,
                    },
                    () => {
                        this.changeShowResults(false);
                    },
                );
            })
            .catch((error) => {
                if (this.pendingDetailsPlaceId !== placeId) {
                    return;
                }
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    predictionProcessing: false,
                });
            });
    };

    /**
     * @param {GeoPointAddress} geoPointAddress
     */
    handleSearch = (geoPointAddress) => {
        const { address, entityId, lat, lng, recordId } = geoPointAddress;

        if (null !== lat && null !== lng) {
            this.props.onSelectedAddress &&
                this.props.onSelectedAddress({
                    ...geoPointAddress,
                    address: address ?? getRoutePointAddress(geoPointAddress),
                    exact: true,
                });
            this.setState(
                {
                    searchResults: [],
                    predictionResults: [],
                },
                () => {
                    this.changeShowResults(false);
                },
            );
            return;
        }

        if (userManager.userHasAccessTo(RECORD_MODULE.NAME)) {
            viewRecordManager.viewRecord(entityId, recordId);
        }

        if (!address.length) {
            this.props.enqueueSnackbar(this.props.t('address_lookup.search.record_missing_address'), {
                variant: 'warning',
            });
            return;
        }
        this.props.enqueueSnackbar(this.props.t('address_lookup.search.record_incorrect_address'), {
            variant: 'warning',
        });
    };

    buildChildren = () => {
        return React.Children.map(this.props.children, (child) => {
            if (null === child) {
                return null;
            }
            if ([MUITextField, TextField].includes(child.type)) {
                return React.cloneElement(child, {
                    onChange: this.handleAddressChange,
                    onKeyUp: this.handleKeyUp,
                    inputRef: this.input,
                    value: this.props.address,
                });
            }
            return child;
        });
    };

    isUseMapBoxAutocomplete() {
        return userManager.isSharedMapUserOrProfile();
    }

    getPlacesAutocomplete(term, basePoint, radius, sessionToken, language) {
        if (this.isUseMapBoxAutocomplete()) {
            return MapBoxApiFactory.getApi(this.props.apiKeys.mapBox).autocomplete(term, basePoint, language);
        }

        return placesManager.getPlacesAutocomplete(term, basePoint, radius, sessionToken, language);
    }

    getPlacesDetailsAutocomplete(placeId, sessionToken, language, showExactitude = false) {
        if (this.isUseMapBoxAutocomplete()) {
            const prediction = this.state.predictionResults?.find(({ placeId: id }) => id === placeId);

            return prediction === undefined
                ? Promise.reject(new Error(this.props.t('http.errors.internal_error')))
                : Promise.resolve(prediction.data);
        }

        return placesManager.getPlacesDetailsAutocomplete(placeId, sessionToken, language, showExactitude);
    }

    renderAutocompleteAttribution() {
        if (this.isUseMapBoxAutocomplete()) {
            // attribution example returned in MapBox geocode response
            // NOTICE: © 2022 Mapbox and its suppliers. All rights reserved. Use of this data is subject to the Mapbox Terms of Service (https://www.mapbox.com/about/maps/). This response and the information it contains may not be retained. POI(s) provided by Foursquare.
            return (
                <li style={{ textAlign: 'right' }}>
                    <small>&copy; Mapbox and its suppliers</small>
                </li>
            );
        }

        return (
            <li>
                <img
                    align="right"
                    src="/image/powered_by_google_on_white.png"
                    alt="powered by google"
                    onLoad={this.updatePopper}
                />
            </li>
        );
    }

    render() {
        const children = this.buildChildren();
        const { address, showSelects, showResults, showBasePointMenu, showReversed, containerRef } = this.props;
        const prospectingEnable = this.props.prospectingEnable && userManager.userHasAccessTo(PROSPECTING_MODULE.NAME);
        const { prospectingProcessing, searchResults, predictionResults, searchProcessing, predictionProcessing } =
            this.state;
        const hasCoordinate = parseGeoPoint(address) !== undefined;
        const isShowResults =
            !!address.length &&
            showResults &&
            (searchResults.length ||
                predictionResults.length ||
                searchProcessing ||
                predictionProcessing ||
                hasCoordinate);
        const isNotFoundResult =
            showResults &&
            !searchResults.length &&
            !predictionResults.length &&
            !searchProcessing &&
            !predictionProcessing &&
            !hasCoordinate;

        const resultsMenuStyle = {
            pointerEvents: 'all',
        };
        if (this.addressLookupRef.current) {
            resultsMenuStyle.maxWidth =
                window.innerWidth - this.addressLookupRef.current.getBoundingClientRect().left - 10;
        }

        // displaying more than 4 (Google Pixel 4) or 7 (iPhone XR) <li>s causes popper to reposition under the onscreen keyboard partially overlapped by the latter
        let limitPlacesResults;
        let limitEntityResults;
        if (showReversed) {
            const limitTotalResults = 4 - hasCoordinate - showBasePointMenu - prospectingEnable;
            limitEntityResults = Math.min(
                searchResults.length,
                Math.max(limitTotalResults - predictionResults.length, Math.floor(limitTotalResults / 2)),
            );
            limitPlacesResults = limitTotalResults - limitEntityResults;
        }

        const resultsMenu = (
            <React.Fragment>
                {isShowResults && (
                    <div
                        className={clsx('c_address_lookup_results', showReversed ? 'reversed' : '')}
                        style={resultsMenuStyle}
                    >
                        <ul>
                            {hasCoordinate && (
                                <CoordinateMenuItem address={address} onClickCoordinate={this.onClickCoordinate} />
                            )}
                            {showBasePointMenu && <BasePointMenuItem t={this.props.t} />}
                            {prospectingEnable && (
                                <ProspectsMenuItem
                                    t={this.props.t}
                                    prospectingProcessing={prospectingProcessing}
                                    handleClickFindProspecting={this.handleClickFindProspecting}
                                />
                            )}
                            <PredictionMenuItem
                                limit={limitPlacesResults}
                                predictionProcessing={predictionProcessing}
                                predictionResults={predictionResults}
                                onClickPrediction={this.onClickPrediction}
                            />
                            {predictionResults.length > 0 && this.renderAutocompleteAttribution()}
                            <SearchMenuItem
                                limit={limitEntityResults}
                                searchResults={searchResults}
                                searchProcessing={searchProcessing}
                                onClickSearch={this.onClickSearch}
                            />
                        </ul>
                    </div>
                )}
                {isNotFoundResult && (
                    <div
                        className={clsx('c_address_lookup_results', showReversed ? 'reversed' : '')}
                        style={resultsMenuStyle}
                    >
                        <ul>
                            {showBasePointMenu && <BasePointMenuItem t={this.props.t} />}
                            {prospectingEnable && (
                                <ProspectsMenuItem
                                    t={this.props.t}
                                    prospectingProcessing={prospectingProcessing}
                                    handleClickFindProspecting={this.handleClickFindProspecting}
                                />
                            )}
                            <li>{this.props.t('address_lookup.search.no_result_found')}</li>
                        </ul>
                    </div>
                )}
            </React.Fragment>
        );

        const renderResults = () => {
            const anchorEl = containerRef ? containerRef.current : this.addressLookupRef.current;
            if (!anchorEl || !this.addressLookupRef.current) {
                return null;
            }
            const popperStyle = {
                pointerEvents: 'none',
                zIndex: 1300,
                maxWidth: this.props.maxWidth || this.addressLookupRef.current.offsetWidth,
            };
            let placement = 'bottom-start';
            const popperOptions = {};
            if (showReversed) {
                popperStyle.width = popperStyle.maxWidth;
                placement = 'top-start';
                popperOptions.modifiers = {
                    offset: {
                        offset: '-10,18',
                    },
                };
            }
            return (
                this?.addressLookupRef?.current && (
                    <Popper
                        open={this.props.resultAfterInput && this.props.showResults}
                        anchorEl={anchorEl}
                        placement={placement}
                        transition
                        style={popperStyle}
                        popperOptions={popperOptions}
                        popperRef={this.popperRef}
                    >
                        {resultsMenu}
                    </Popper>
                )
            );
        };

        return (
            <React.Fragment>
                {!this.props.resultAfterInput && resultsMenu}
                <div
                    style={{ display: 'flex' }}
                    ref={this.addressLookupRef}
                    className={
                        this.props.className + ' c_address_lookup ' + (showSelects ? 'c_address_lookup-open' : '')
                    }
                    onFocus={this.onFocus}
                >
                    {children}
                </div>
                {renderResults()}
            </React.Fragment>
        );
    }
}

const CoordinateMenuItem = ({ address, onClickCoordinate }) => {
    return (
        <li onMouseUp={onClickCoordinate} onTouchEnd={onClickCoordinate} className="result-item">
            <div className="result-icon">
                <i className="fas fa-map-marker-alt" />
            </div>
            <strong>{address.replace(',', ' ')}</strong>
        </li>
    );
};

const PredictionMenuItem = ({ predictionResults, predictionProcessing, onClickPrediction, limit }) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    if (predictionProcessing) {
        return (
            <li className="processing-icon" onMouseUp={stopPropagation} onTouchEnd={stopPropagation}>
                <CircularProgress size={14} />
            </li>
        );
    }

    const limited = limit === undefined ? predictionResults : predictionResults.slice(0, limit);

    return limited.map(({ description, placeId }) => (
        <li
            key={placeId}
            data-place-id={placeId}
            onMouseUp={onClickPrediction}
            onTouchEnd={onClickPrediction}
            className="result-item"
        >
            {description}
        </li>
    ));
};

const SearchMenuItem = ({ searchResults, searchProcessing, onClickSearch, limit }) => {
    if (searchProcessing) {
        return (
            <li className="processing-icon">
                <CircularProgress size={14} />
            </li>
        );
    }

    const limited = limit === undefined ? searchResults : searchResults.slice(0, limit);

    return limited.map((result) => {
        const isValid = result.position && result.position.lat !== undefined;
        const style = { color: result.entity.color };
        return (
            <li
                key={`${result.entityId}_${result.id}`}
                data-position-lat={result.position ? result.position.lat : null}
                data-position-lng={result.position ? result.position.lng : null}
                data-status={result.status}
                data-entity-id={result.entityId}
                data-record-id={result.id}
                data-address={result.address}
                data-name={result.name}
                data-country-short={result.addressData.countryShort}
                data-address-components={JSON.stringify(result.addressFields)}
                onMouseUp={onClickSearch}
                onTouchEnd={onClickSearch}
                className={isValid ? 'result-item result-valid' : 'result-item result-invalid'}
            >
                <div className="result-icon">
                    <i className={'fas fa-' + result.entity.icon} style={style} />
                </div>
                {result.name}
                <small style={style}>{result.entity.label.substring(0, 2)}</small>
            </li>
        );
    });
};

class ProspectsMenuItem extends React.PureComponent {
    componentDidMount() {
        dispatcher.subscribe([events.EVENT_CURRENT_USER_CHANGED], this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    render() {
        const isFeatureAccessRestricted = userManager.hasEssentialRestrictions();
        const { prospectingProcessing, handleClickFindProspecting, t } = this.props;

        if (isFeatureAccessRestricted) {
            return (
                <Tooltip title={t('prospecting.locked_message')}>
                    <li
                        className="result-item"
                        style={{ fontSize: 15, textAlign: 'center', color: 'silver' }}
                        onMouseUp={this.stopPropagation}
                        onTouchEnd={this.stopPropagation}
                    >
                        <div className="result-icon">
                            <i className="fas fa-search-location" />
                        </div>
                        <strong style={{ marginLeft: 8 }}>{t('address_lookup.prospect.button')}</strong>
                    </li>
                </Tooltip>
            );
        }

        if (prospectingProcessing) {
            return (
                <li className="processing-icon" onMouseUp={this.stopPropagation} onTouchEnd={this.stopPropagation}>
                    <CircularProgress size={14} />
                </li>
            );
        }

        return (
            <li
                onMouseUp={handleClickFindProspecting}
                onTouchEnd={handleClickFindProspecting}
                className="result-item"
                style={{ fontSize: 15 }}
            >
                <div className="result-icon">
                    <i className="fas fa-search-location" />
                </div>
                <strong style={{ marginLeft: 8 }}>{t('address_lookup.prospect.button')}</strong>
            </li>
        );
    }
}

class BasePointMenuItem extends React.PureComponent {
    componentDidMount() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });
        dispatcher.subscribe(events.UPDATE_BASE_POINT_HANDLE, this, () => {
            this.forceUpdate();
            document.getElementById('map-positioning-input-search').focus();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleClick = (e) => {
        this.stopPropagation(e);
        MapStateManagerFactory.getManager(userManager.getCurrentUser().id).toggleUpdateBasePointFromSearch();
    };

    stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    render() {
        if (!userManager.userHasAccessTo(MAP_MODULE.NAME, MAP_MODULE.FEATURES.BASE_LOCATION.NAME)) {
            return null;
        }
        const checked = MapStateManagerFactory.getManager(
            userManager.getCurrentUser().id,
        ).isUpdateBasePointFromSearch();
        const { t } = this.props;

        return (
            <li
                onClick={this.handleClick}
                className="result-item"
                style={{ fontSize: 15, paddingLeft: 0 }}
                onMouseUp={this.stopPropagation}
                onTouchEnd={this.handleClick}
            >
                <Checkbox checked={checked} color="primary" style={{ paddingLeft: 0 }} />
                {t('address_lookup.search.update_base_location')}
            </li>
        );
    }
}

AddressLookup.propTypes = {
    address: PropTypes.string.isRequired,
    showSelect: PropTypes.bool.isRequired,
    showResults: PropTypes.bool.isRequired,
    resultAfterInput: PropTypes.bool,
    onSelectedAddress: PropTypes.func,
    onChangeAddress: PropTypes.func,
    onChangeShowSelect: PropTypes.func,
    onChangeShowResult: PropTypes.func,
    prospectingEnable: PropTypes.bool,
    mapBounds: PropTypes.shape({
        minLng: PropTypes.number,
        maxLng: PropTypes.number,
        minLat: PropTypes.number,
        maxLat: PropTypes.number,
    }),
    showBasePointMenu: PropTypes.bool,
    apiKeys: PropTypes.object,
    showReversed: PropTypes.bool,
    containerRef: PropTypes.object,
    maxWidth: PropTypes.number,
    showExactitude: PropTypes.bool,
    inputRef: PropTypes.object,
};

AddressLookup.defaultProps = {
    prospectingEnable: true,
    resultAfterInput: false,
    showBasePointMenu: false,
    showReversed: false,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(AddressLookup));
