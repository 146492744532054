import { CalendarEvent, CalendarEventField, CONFLICT_TYPE } from 'service/Calendar/CalendarEventRepository';
import { v4 as uuidv4 } from 'uuid';
import { calendarEventDefaultValues } from '../settings';
import { closest } from '@syncfusion/ej2-base';
import { cloneDeep } from 'lodash';
import { Entity } from 'interfaces/entity';
import metadataManager from 'service/MetadataManager';
import {
    Calendar,
    CalendarEventWitEntityId,
    CalendarField,
    CalendarFieldType,
    Calendars,
    CalendarSection,
    WaitingListItem,
} from 'interfaces/calendar/calendar';
import { userManager } from 'service/UserManager';
import { calendarManager } from 'service/Calendar/CalendarManager';
import {
    AdapterId,
    EntityViewSource,
    EntityViewSourceEntity,
    FieldLookupType,
    FieldPolymorphicLookupData,
    FieldType,
    IField,
    UserPropertiesTab,
    WorkingHoursPerDay,
} from 'components/types';
import { ScheduleComponent } from '@syncfusion/ej2-react-schedule';
import { User } from 'interfaces/user';
import WorkSchedule from 'service/WorkSchedule';
import { addMinutes, addSeconds, format, isAfter, subDays } from 'date-fns';
import { isValidationRuleCheckPasses } from 'components/Calendar/Forms/CalendarFormValidator';
import { mapWaitingListItemToCalendarEvent } from './WaitingListHelper';
import { Button } from '@material-ui/core';
import React from 'react';
import i18n from 'locales/i18n';
import dateHelper from 'service/Date/DateHelper';
import { userToUserTimezone } from 'utils';
import { DataSource } from '../../../interfaces';
import dsManagerFactory from '../../../service/DsManager';
import { userPropertiesManager } from '../../../service/UserForm';
import usersPermissionsManager from '../../../service/Permissions/UsersPermissionsManager';

export const getDefaultCalendarEvent = (
    calendarId: string,
    ownerId: number,
    startTime?: Date | null,
    endTime?: Date | null,
): CalendarEvent => {
    const calendarEvent: CalendarEvent = cloneDeep(calendarEventDefaultValues);
    calendarEvent.id = uuidv4();
    calendarEvent.calendarId = calendarId;
    calendarEvent.ownerId = ownerId;
    calendarEvent.startDatetime = startTime ? startTime.toISOString() : new Date().toISOString();

    const defaultDuration = userManager.getCurrentUser().routingPreferences.defaultDuration;
    calendarEvent.endDatetime = endTime
        ? endTime.toISOString()
        : addMinutes(new Date(calendarEvent.startDatetime), defaultDuration).toISOString();

    if (userManager.getCurrentUser().calendarPreferences.fixedTimeAfterDrop === false) {
        calendarEvent.fixedTime = false;
    }

    return calendarEvent;
};

export const getStartTimeFromElement = (element: Element): Date | null => {
    const startTime = element.getAttribute('data-date');
    if (!startTime) {
        return null;
    }

    return new Date(Number(startTime));
};

export const getCalendarElementFromTarget = (target: HTMLElement): Element | undefined => {
    if (closest(target, '.e-contextmenu')) {
        return undefined;
    }

    return (
        closest(
            target,
            '.e-appointment,.e-work-cells,' +
                '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells',
        ) ?? undefined
    );
};

export const getCalendarId = async (availableCalendars: Calendars, ownerId: number): Promise<string | undefined> => {
    if (availableCalendars.size === 1) {
        return availableCalendars.entries().next().value[0];
    }

    const accountId = userManager.getCurrentAccount().id;
    const user = await userManager.getAccountUser(accountId, ownerId);
    const defaultCalendarUuid = user.calendarPreferences?.defCalendarUuid;
    if (defaultCalendarUuid && availableCalendars.has(defaultCalendarUuid)) {
        return defaultCalendarUuid;
    }
};

export const getCurrentAccountActiveCalendars = async (): Promise<Calendars> => {
    return getAccountActiveCalendars(userManager.getCurrentAccount().id);
};

export const getAccountActiveCalendars = async (accountId: number): Promise<Calendars> => {
    const calendars = await calendarManager.get(accountId);
    const activeCalendars = new Map<string, Calendar>();
    calendars.forEach((calendar: Calendar, key: string) => {
        if (!calendar.active) {
            return;
        }

        activeCalendars.set(key, calendar);
    });

    return activeCalendars;
};

export const getCalendarRelatedEntityField = async (
    calendarId: string,
    entityId: number,
    recordId: string,
    recordLabel: string,
): Promise<CalendarEventField | undefined> => {
    const accountId = userManager.getCurrentAccount().id;
    const calendar = (await calendarManager.get(accountId)).get(calendarId);
    if (!calendar) {
        return;
    }

    const entity: Entity | undefined = await metadataManager.requestEntity(calendar.entityId);
    if (!entity) {
        return;
    }

    const calendarRelatedEntityFields = getCalendarRelatedEntityFields(calendar, entity);

    const itemEntity = await metadataManager.requestEntity(entityId);
    if (!itemEntity || itemEntity.dataSource.id !== entity.dataSource.id) {
        return;
    }

    const calendarRelatedEntityField = findCalendarRelatedEntityField(calendarRelatedEntityFields, itemEntity);
    if (!calendarRelatedEntityField) {
        return;
    }

    return {
        name: calendarRelatedEntityField.apiName,
        value: getFormattedCalendarEventFieldValue(calendarRelatedEntityField, recordId, itemEntity),
        displayValue: recordLabel,
    };
};

export const getCalendarRelatedEntityFields = (calendar: Calendar, entity: Entity): IField[] => {
    const entityFieldsMap: Map<string, IField> = new Map<string, IField>();
    entity.fields.forEach((field: IField) => {
        entityFieldsMap.set(field.apiName, field);
    });

    const calendarRelatedEntityFields: IField[] = [];
    calendar.fields.forEach((field: CalendarField | CalendarSection) => {
        if (field.type === CalendarFieldType.SECTION) {
            return;
        }

        const calendarField = field as CalendarField;
        if (!calendarField.related) {
            return;
        }

        const entityField = entityFieldsMap.get(calendarField.apiName);
        if (!entityField || !entityField.lookupData) {
            return;
        }

        calendarRelatedEntityFields.push(entityField);
    });

    return calendarRelatedEntityFields;
};

export const findCalendarRelatedEntityField = (
    calendarRelatedEntityFields: IField[],
    itemEntity: any,
): IField | undefined => {
    let calendarRelatedEntityField: IField | undefined;

    fieldApiNameSearchingLoops: for (const index in calendarRelatedEntityFields) {
        const lookupData = calendarRelatedEntityFields[index].lookupData!;
        if (lookupData.type === FieldLookupType.POLYMORPHIC) {
            const entities = (lookupData as unknown as FieldPolymorphicLookupData).entities;
            for (const entityIndex in entities) {
                if (entities[entityIndex] === itemEntity.apiName) {
                    calendarRelatedEntityField = calendarRelatedEntityFields[index];
                    break fieldApiNameSearchingLoops;
                }
            }
        } else if (lookupData.apiName === itemEntity.apiName) {
            calendarRelatedEntityField = calendarRelatedEntityFields[index];
            break;
        }
    }

    return calendarRelatedEntityField;
};

export const getUserIdFromScheduleResource = (
    scheduleObj: ScheduleComponent | null,
    resourceId: number,
): number | null => {
    if (!scheduleObj) {
        return null;
    }
    const resourceDetails = scheduleObj.getResourcesByIndex(resourceId);
    if (!resourceDetails) {
        return null;
    }
    return resourceDetails?.resourceData?.id ?? null;
};

export const getEventStartTimeWithTravel = (event: CalendarEvent, user?: User): Date => {
    let startTime = dateHelper.createFromISOString(event.startDatetime, user).getDisplayDate();
    if (event.headRouteActivity?.drivingTime) {
        startTime = addSeconds(startTime, -event.headRouteActivity.drivingTime);
    }
    return startTime;
};
export const getEventEndTimeWithTravel = (event: CalendarEvent, user?: User): Date => {
    let endTime = dateHelper.createFromISOString(event.endDatetime, user).getDisplayDate();
    if (event.routeActivity?.drivingTime) {
        endTime = addSeconds(endTime, event.routeActivity.drivingTime);
    }
    return endTime;
};

export const calculateConflicts = (events: CalendarEvent[], users: User[]): CalendarEvent[] => {
    events.sort((a: CalendarEvent, b: CalendarEvent) => {
        return getEventStartTimeWithTravel(a).getTime() - getEventStartTimeWithTravel(b).getTime();
    });

    const userMap = new Map<number, User>();
    users.forEach((user: User) => {
        userMap.set(user.id, user);
    });

    const eventsByUser = new Map<number, CalendarEvent[]>();
    events.forEach((event: CalendarEvent) => {
        event.conflictType = undefined;
        if (!eventsByUser.has(event.ownerId)) {
            eventsByUser.set(event.ownerId, []);
        }
        eventsByUser.get(event.ownerId)?.push(event);
    });

    const currentUser = userManager.getCurrentUser();

    eventsByUser.forEach((userEvents: CalendarEvent[]) => {
        userEvents.forEach((event: CalendarEvent, index: number) => {
            let user = userMap.get(event.ownerId);
            if (!user) {
                return;
            }

            const startTime = getEventStartTimeWithTravel(event);
            const endTime = getEventEndTimeWithTravel(event);
            let timezoneStartTime = startTime;
            let timezoneEndTime = endTime;
            if (currentUser.id !== user.id) {
                timezoneStartTime = userToUserTimezone(timezoneStartTime, currentUser, user);
                timezoneEndTime = userToUserTimezone(timezoneEndTime, currentUser, user);
            }

            const workStartTime = getWorkTime(startTime, user, 'start');
            const workEndTime = getWorkTime(endTime, user, 'end');
            if (!workStartTime || isAfter(workStartTime, timezoneStartTime)) {
                event.conflictType =
                    event.conflictType === CONFLICT_TYPE.EVENT ? CONFLICT_TYPE.BOTH : CONFLICT_TYPE.WORKING_HOURS;
            }

            if (!workEndTime || isAfter(timezoneEndTime, workEndTime)) {
                event.conflictType =
                    event.conflictType === CONFLICT_TYPE.EVENT ? CONFLICT_TYPE.BOTH : CONFLICT_TYPE.WORKING_HOURS;
            }

            if (event.arrangementError) {
                event.conflictType =
                    event.conflictType === CONFLICT_TYPE.WORKING_HOURS || event.conflictType === CONFLICT_TYPE.BOTH
                        ? CONFLICT_TYPE.BOTH
                        : CONFLICT_TYPE.EVENT;
            }

            const nextEvent = userEvents[index + 1];
            if (nextEvent && isAfter(endTime, getEventStartTimeWithTravel(nextEvent))) {
                if (!event.arrangementError) {
                    nextEvent.conflictType = CONFLICT_TYPE.EVENT;
                }
            }
        });
    });

    return events;
};

const getWorkTime = (date: Date, user: User, startOrEnd: 'start' | 'end'): Date | null => {
    let timezoneDate = new Date(date);
    if (startOrEnd === 'end' && format(timezoneDate, 'HH:mm') === '00:00') {
        timezoneDate.setHours(23, 59);
        timezoneDate = subDays(timezoneDate, 1);
    }
    let workingHoursTimezoneDate = dateHelper.createFromDeviceDate(timezoneDate, user).getDate();
    if (userManager.getCurrentUser().id !== user.id) {
        timezoneDate = userToUserTimezone(timezoneDate, userManager.getCurrentUser(), user);

        workingHoursTimezoneDate = userToUserTimezone(workingHoursTimezoneDate, userManager.getCurrentUser(), user);
    }
    const userDayWorkTime: WorkingHoursPerDay | null = WorkSchedule.getWorkingHours(workingHoursTimezoneDate, user);

    if (!userDayWorkTime) {
        return null;
    }

    const workTime = new Date(timezoneDate);
    workTime.setHours(userDayWorkTime[startOrEnd].hours, userDayWorkTime[startOrEnd].minutes, 0, 0);

    return workTime;
};

export const getAllowedToSaveEventsToCalendar = (
    calendarEvents: CalendarEventWitEntityId[],
    calendar: Calendar,
    calendarEntity: Entity,
    entitiesMap: Map<number, EntityViewSourceEntity>,
): {
    allowed: CalendarEvent[];
    notAllowed: Map<number | 'validationRule', CalendarEvent[]>;
} => {
    const allowed: CalendarEvent[] = [];
    const notAllowed: Map<number | 'validationRule', CalendarEvent[]> = new Map<
        number | 'validationRule',
        CalendarEvent[]
    >();

    calendarEvents.forEach((calendarEventWitEntityId: CalendarEventWitEntityId) => {
        const itemEntity = entitiesMap.get(calendarEventWitEntityId.entityId);
        if (!itemEntity || itemEntity.dsId !== calendarEntity.dataSource.id) {
            if (!notAllowed.get(calendarEventWitEntityId.entityId)) {
                notAllowed.set(calendarEventWitEntityId.entityId, []);
            }
            notAllowed.get(calendarEventWitEntityId.entityId)?.push(calendarEventWitEntityId.calendarEvent);
            return;
        }

        const calendarRelatedEntityFields = getCalendarRelatedEntityFields(calendar, calendarEntity);
        const calendarRelatedEntityField = findCalendarRelatedEntityField(calendarRelatedEntityFields, itemEntity);
        if (!calendarRelatedEntityField) {
            if (!notAllowed.get(calendarEventWitEntityId.entityId)) {
                notAllowed.set(calendarEventWitEntityId.entityId, []);
            }
            notAllowed.get(calendarEventWitEntityId.entityId)?.push(calendarEventWitEntityId.calendarEvent);
            return;
        }

        if (!isValidationRuleCheckPasses(calendar, calendarEventWitEntityId.calendarEvent)) {
            if (!notAllowed.get('validationRule')) {
                notAllowed.set('validationRule', []);
            }
            notAllowed.get('validationRule')?.push(calendarEventWitEntityId.calendarEvent);
            return;
        }

        allowed.push(calendarEventWitEntityId.calendarEvent);
    });

    return { allowed, notAllowed };
};

export const getAllowedToSaveItemsToCalendars = async (
    items: WaitingListItem[],
    calendars: Calendar[],
    entitiesMap: Map<number, EntityViewSourceEntity>,
): Promise<WaitingListItem[]> => {
    const allowed: WaitingListItem[] = [];
    const calendarEntitiesMap = await getCalendarEntities(calendars);

    items.forEach((item: WaitingListItem) => {
        const itemEntity = entitiesMap.get(item.relatedTo.entityId);
        if (!itemEntity) {
            return;
        }

        const allowedCalendar = calendars.find((calendar: Calendar): boolean => {
            const calendarEntity: Entity | undefined = calendarEntitiesMap.get(calendar.entityId);
            if (!calendarEntity || itemEntity.dsId !== calendarEntity.dataSource.id) {
                return false;
            }

            const calendarRelatedEntityFields = getCalendarRelatedEntityFields(calendar, calendarEntity);
            const calendarRelatedEntityField = findCalendarRelatedEntityField(calendarRelatedEntityFields, itemEntity);

            if (!calendarRelatedEntityField) {
                return false;
            }

            const calendarEvent = mapWaitingListItemToCalendarEvent(
                item,
                calendar,
                0,
                new Date(),
                0,
                itemEntity,
                calendarRelatedEntityField,
            );

            return isValidationRuleCheckPasses(calendar, calendarEvent);
        });

        if (!allowedCalendar) {
            return;
        }

        allowed.push(item);
    });

    return allowed;
};

export const getCalendarEntities = async (calendars: Calendar[]): Promise<Map<number, Entity>> => {
    const entities = await Promise.all(
        calendars.map((calendar: Calendar) => {
            return metadataManager.requestEntity(calendar.entityId);
        }),
    );
    const entitiesMap = new Map<number, Entity>();
    entities.forEach((entity: Entity | undefined) => {
        if (!entity) {
            return;
        }
        entitiesMap.set(entity.id, entity);
    });

    return entitiesMap;
};

export const getCalendarIdToDataSourceMap = async (
    calendars: Calendar[],
    dataSources?: EntityViewSource[],
): Promise<Map<string, EntityViewSource>> => {
    if (!dataSources) {
        dataSources = await metadataManager.requestAccountDataSourcesForUser(userManager.getCurrentAccount().id);
    }
    const calendarIdToDataSourceMap = new Map<string, EntityViewSource>();
    if (!dataSources) {
        return calendarIdToDataSourceMap;
    }

    const calendarEntitiesMap = await getCalendarEntities(calendars);
    calendars.forEach((calendar: Calendar) => {
        const entity = calendarEntitiesMap.get(calendar.entityId);
        if (!entity) {
            return;
        }

        const dataSource = dataSources!.find((dataSource: EntityViewSource) => {
            return dataSource.id === entity.dataSource.id;
        });
        if (!dataSource) {
            return;
        }

        calendarIdToDataSourceMap.set(calendar.id, dataSource);
    });

    return calendarIdToDataSourceMap;
};

export const getCalendarDataSource = async (calendar: Calendar): Promise<DataSource.DataSource | undefined> => {
    const entity: Entity | undefined = await metadataManager.requestEntity(calendar.entityId);
    if (!entity) {
        return;
    }

    return await dsManagerFactory.getManager(userManager.getCurrentAccount().id).load(entity.dataSource.id);
};

export const isGoogleCalendar = async (calendar: Calendar): Promise<boolean> => {
    const ds = await getCalendarDataSource(calendar);
    if (!ds) {
        return false;
    }

    return ds.adapterId === AdapterId.GOOGLECALENDAR;
};

export const getAvailableCalendarsForUser = async (calendars: Calendars, userId: number): Promise<Calendars> => {
    return new Map(
        Array.from(calendars.entries()).filter(([_, calendar]) => calendar.connectedUserIds.includes(userId)),
    );
};

export const connectGoogleCalendar = () => {
    userPropertiesManager.openModal(
        userManager.getCurrentAccount(),
        userManager.getCurrentUser(),
        UserPropertiesTab.TAB_CALENDAR,
        undefined,
        undefined,
        true,
    );
};

export const getNoCalendarsMessage = async (
    onCalendarSettingsOpen: () => void,
    activeCalendars?: Calendars,
): Promise<React.JSX.Element | string | undefined> => {
    if (activeCalendars === undefined) {
        activeCalendars = await getCurrentAccountActiveCalendars();
    }
    if (activeCalendars.size === 0) {
        return (
            <div>
                <div>{i18n.t('calendar.no_active_calendars')}</div>
                {userManager.isRoleAdmin() ? (
                    <Button onClick={onCalendarSettingsOpen}>{i18n.t('calendar.enable_calendars')}</Button>
                ) : (
                    <div className="no-active-calendars-small">{i18n.t('calendar.ask_admin_to_enable_calendars')}</div>
                )}
            </div>
        );
    }

    if ((await getAvailableCalendarsForUser(activeCalendars, userManager.getCurrentUser().id)).size !== 0) {
        return;
    }

    const hasBrowseUsersPermission =
        usersPermissionsManager.hasBrowseUsersPermission &&
        (usersPermissionsManager.hasCalendarEventObjectModifyNotOwnedPermission ||
            usersPermissionsManager.hasCalendarEventObjectRecordSharingRules);

    const activeCalendarsArray = Array.from(activeCalendars.values());
    const calendarIdToDataSourceMap = await getCalendarIdToDataSourceMap(activeCalendarsArray);
    const dataSourceNames = activeCalendarsArray
        .map((calendar: Calendar) => {
            const dataSource = calendarIdToDataSourceMap?.get(calendar.id);
            return dataSource ? dataSource.name : calendar.name;
        })
        .join(', ');

    const commonMessage = i18n.t(
        `calendar.need_to_setup_calendars.${userManager.isRoleAdmin() ? '' : 'non_'}admin${
            activeCalendarsArray.length > 1 ? '.plural' : ''
        }`,
        { dataSourceNames },
    );
    if (hasBrowseUsersPermission) {
        return commonMessage;
    }

    let googleCalendar;
    for (let i = 0; i < activeCalendarsArray.length; i++) {
        if (await isGoogleCalendar(activeCalendarsArray[i])) {
            googleCalendar = activeCalendarsArray[i];
            break;
        }
    }
    if (!googleCalendar || !googleCalendar.active) {
        return commonMessage;
    }

    return (
        <div>
            <div>{i18n.t('calendar.google_calendar_not_connected')}</div>
            <Button color="primary" variant="contained" style={{ marginTop: 5 }} onClick={connectGoogleCalendar}>
                {i18n.t('users_calendar_preferences.modal.google_calendar.connect')}
            </Button>
        </div>
    );
};

export const getBackgroundColor = (calendar?: Calendar): string => {
    return calendar?.backgroundColor ?? '#3f51b5';
};

export const getTextColor = (calendar?: Calendar): string => {
    return calendar?.textColor ?? '#fff';
};

export const getFormattedCalendarEventFieldValue = (
    field: IField,
    value: any,
    itemEntity?: EntityViewSourceEntity,
): any => {
    if (field.lookupData?.type === FieldLookupType.POLYMORPHIC && itemEntity) {
        return JSON.stringify({ object: itemEntity.apiName, recordId: value });
    }

    if (field.type === FieldType.STRINGS) {
        return [value];
    }

    return value;
};

export const getConflictTooltipTitle = (conflictType: CONFLICT_TYPE): string => {
    if (conflictType === CONFLICT_TYPE.EVENT) {
        return i18n.t('calendar.conflicting_event.hint.event');
    }

    if (conflictType === CONFLICT_TYPE.WORKING_HOURS) {
        return i18n.t('calendar.conflicting_event.hint.working_hours');
    }

    return `${i18n.t('calendar.conflicting_event.hint.event')} ${i18n.t(
        'calendar.conflicting_event.hint.working_hours',
    )}`;
};

export const calendarOnWindowResize = () => {
    const editModePanel = document.querySelector('.c-calendar .edit-mode-panel.sticky');
    const editModeHeight = editModePanel ? editModePanel.getBoundingClientRect().height - 22 : 0;

    const toolbar = document.querySelector('.c-calendar .e-schedule-toolbar-container') as HTMLElement | undefined;
    if (toolbar) {
        toolbar.style.top = editModeHeight + 'px';
    }

    const waitingListWrap = document.querySelector('.c-calendar .waiting-list-wrap') as HTMLElement | undefined;
    if (waitingListWrap) {
        waitingListWrap.style.top = editModeHeight + 'px';
    }

    const deviceResource = document.querySelector(
        '.c-calendar .e-schedule.e-device .e-schedule-resource-toolbar-container',
    ) as HTMLElement | undefined;
    if (deviceResource) {
        deviceResource.style.top =
            editModeHeight +
            (toolbar?.getBoundingClientRect()?.height ?? 0) -
            deviceResource.getBoundingClientRect().height +
            10 +
            'px';
    }

    const calendarHeaders = document.querySelectorAll(
        '.c-calendar .e-schedule .e-outer-table > tbody > tr:first-child > td',
    ) as NodeListOf<HTMLElement>;
    calendarHeaders.forEach((element) => {
        element.style.top =
            editModeHeight + (toolbar?.getBoundingClientRect()?.height ?? 0) + (deviceResource ? 10 : 0) + 'px';
    });
};
