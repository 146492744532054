import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HoverStyledRow } from '../../DataTable';
import { Column } from '@devexpress/dx-react-grid';
import {
    Action,
    dataExchangeManager,
    DataExchangeSession,
    Event,
    LogStatus,
    SessionStatus,
} from '../../../service/DataExchangeManager';
import { utcToUserTimezone } from '../../../utils';
import '../style.css';
import { Box, Button, Tooltip } from '@material-ui/core';
import { DataTableColumn, DataTableProps, DataTableState } from '../../DataTable/types';
import DataTable from '../../DataTable/DataTable';
import DataExchangeLogModal from './DataExchangeLogModal';
import {
    IDataSource,
    TYPE_DATETIME,
    TYPE_FLOAT,
    TYPE_INTEGER,
    TYPE_STRING,
    TYPE_TEXT_ARRAY,
    UserData,
} from '../../../service/types';
import { userManager } from '../../../service/UserManager';
import dsManagerFactory from '../../../service/DsManager';
import EventField from './EventField';
import { Filter, OPERATOR_IN } from '../../utils/tableFilter';
import { PARAM_FILTERS } from '../index';

interface CurrentProps extends DataTableProps, WithTranslation {
    accountId: number;
    user: UserData;
    searchParams: { [PARAM_FILTERS]?: Filter[] };
}
interface CurrentState extends DataTableState<DataExchangeSession> {
    currentSession: DataExchangeSession | null;
    logsFilters: Filter[];
    showLogs: boolean;
    showApiCallDetails: boolean;
}

class DataExchangeSessions extends DataTable<DataExchangeSession, CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.pageSizes = [50, 100, 200];
        this.defaultPageSize = 50;

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
            totalCount: 0,
            currentSession: null,
            logsFilters: [],
            showLogs: false,
            showApiCallDetails: false,
        };

        this.sorting = [
            {
                columnName: 'createdAt',
                direction: 'desc',
            },
        ];
    }

    buildStructure(fields: DataTableColumn<DataExchangeSession>[]) {
        const structure: any = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'createdAt', width: 200 },
            { columnName: 'userId', width: 250 },
            { columnName: 'newCount', align: 'center', width: 100 },
            { columnName: 'modCount', align: 'center', width: 100 },
            { columnName: 'delCount', align: 'center', width: 100 },
            { columnName: 'failedCount', align: 'center', width: 100 },
            { columnName: 'time', align: 'right', width: 150 },
            { columnName: 'status', align: 'center', width: 150 },
            { columnName: 'statusMessage', wordWrapEnabled: true },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'userId', sortingEnabled: false },
            { columnName: 'entities', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c: Column) => c.title).map((c: Column) => c.title);
        return structure;
    }

    getFields() {
        const columns: DataTableColumn<DataExchangeSession>[] = [
            {
                columnName: 'createdAt',
                type: TYPE_DATETIME,
                title: this.props.t('data_exchange_session.columns.created_at'),
                getCellValue: (row: DataExchangeSession) => utcToUserTimezone(row.createdAt, this.props.user),
            },
            {
                columnName: 'event',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.event'),
                getCellValue: (row: DataExchangeSession) => (
                    <EventField session={row} accountId={this.props.accountId} />
                ),
                picklist: [
                    { value: Event.Button, label: this.props.t('data_exchange_session.event.button') },
                    {
                        value: Event.CreatedManually,
                        label: this.props.t('data_exchange_session.event.created_manually'),
                    },
                    {
                        value: Event.UpdatedManually,
                        label: this.props.t('data_exchange_session.event.updated_manually'),
                    },
                    {
                        value: Event.RemovedManually,
                        label: this.props.t('data_exchange_session.event.removed_manually'),
                    },
                    {
                        value: Event.ScheduledProcess,
                        label: this.props.t('data_exchange_session.event.scheduled_process'),
                    },
                    {
                        value: Event.SsoAutoRegistered,
                        label: this.props.t('data_exchange_session.event.sso_auto_registered'),
                    },
                    { value: Event.Geocoding, label: this.props.t('data_exchange_session.event.geocoding') },
                    { value: Event.Import, label: this.props.t('data_exchange_session.event.import') },
                    {
                        value: Event.InboundApiCall,
                        label: this.props.t('data_exchange_session.event.inbound_api_call'),
                    },
                    { value: Event.ProspectExport, label: this.props.t('data_exchange_session.event.prospect_export') },
                    {
                        value: Event.TerritoryAssignment,
                        label: this.props.t('data_exchange_session.event.territory_assignment'),
                    },
                    {
                        value: Event.AutomationCommit,
                        label: this.props.t('data_exchange_session.event.automation_commit'),
                    },
                    { value: Event.System, label: this.props.t('data_exchange_session.event.system') },
                ],
            },
            {
                columnName: 'userId',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.user'),
                picklist: this.getUsersPicklist(),
                getCellValue: (row: DataExchangeSession) => <span>{row.userName}</span>,
            },
            {
                columnName: 'entities',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.entity'),
                picklist: this.getEntitiesPicklist(),
                getCellValue: (row: DataExchangeSession) => row.entities.join(', '),
            },
            {
                columnName: 'newCount',
                type: TYPE_INTEGER,
                title: this.props.t('data_exchange_session.columns.created'),
                getCellValue: (row: DataExchangeSession) => {
                    const filters: Filter[] = [
                        { columnName: 'action', operation: OPERATOR_IN, value: [Action.Created] },
                    ];
                    return (
                        <Box color="success.main">
                            <Button
                                style={{ padding: 0 }}
                                color="inherit"
                                onClick={(e: React.MouseEvent) => this.openFilteredSessionModal(e, row, filters)}
                                data-testid="data_exchange_session.columns.created.button"
                            >
                                {row.newCount}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                columnName: 'modCount',
                type: TYPE_INTEGER,
                title: this.props.t('data_exchange_session.columns.updated'),
                getCellValue: (row: DataExchangeSession) => {
                    const filters: Filter[] = [
                        { columnName: 'action', operation: OPERATOR_IN, value: [Action.Updated] },
                    ];
                    return (
                        <Box color="warning.main">
                            <Button
                                style={{ padding: 0 }}
                                color="inherit"
                                onClick={(e: React.MouseEvent) => this.openFilteredSessionModal(e, row, filters)}
                                data-testid="data_exchange_session.columns.updated.button"
                            >
                                {row.modCount}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                columnName: 'delCount',
                type: TYPE_INTEGER,
                title: this.props.t('data_exchange_session.columns.deleted'),
                getCellValue: (row: DataExchangeSession) => {
                    const filters: Filter[] = [
                        { columnName: 'action', operation: OPERATOR_IN, value: [Action.Deleted] },
                    ];
                    return (
                        <Box color="error.main">
                            <Button
                                style={{ padding: 0 }}
                                color="inherit"
                                onClick={(e: React.MouseEvent) => this.openFilteredSessionModal(e, row, filters)}
                                data-testid="data_exchange_session.columns.deleted.button"
                            >
                                {row.delCount}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                columnName: 'failedCount',
                type: TYPE_INTEGER,
                title: this.props.t('data_exchange_session.columns.failed'),
                getCellValue: (row: DataExchangeSession) => {
                    const filters: Filter[] = [
                        { columnName: 'status', operation: OPERATOR_IN, value: [LogStatus.Fail] },
                    ];
                    return (
                        <Box color="secondary.main">
                            <Button
                                style={{ padding: 0 }}
                                color="inherit"
                                onClick={(e: React.MouseEvent) => this.openFilteredSessionModal(e, row, filters)}
                                data-testid="data_exchange_session.columns.failed.button"
                            >
                                {row.failedCount}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                columnName: 'time',
                type: TYPE_FLOAT,
                title: this.props.t('data_exchange_session.columns.time'),
            },
            {
                columnName: 'status',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_session.columns.status'),
                picklist: [
                    { value: SessionStatus.Success, label: this.props.t('data_exchange_session.status.success') },
                    {
                        value: SessionStatus.PartialSuccess,
                        label: this.props.t('data_exchange_session.status.partial_success'),
                    },
                    { value: SessionStatus.Fail, label: this.props.t('data_exchange_session.status.fail') },
                    { value: SessionStatus.Suspended, label: this.props.t('data_exchange_session.status.suspended') },
                ],
                getCellValue: (row) => {
                    switch (row.status) {
                        case SessionStatus.Success:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('data_exchange_session.status.success')}
                                </span>
                            );
                        case SessionStatus.PartialSuccess:
                            return (
                                <span className="log-badge log-badge-warning">
                                    {this.props.t('data_exchange_session.status.partial_success')}
                                </span>
                            );
                        case SessionStatus.Fail:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_session.status.fail')}
                                </span>
                            );
                        case SessionStatus.Suspended:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_session.status.suspended')}
                                </span>
                            );
                        case SessionStatus.Cancelled:
                            return (
                                <span className="log-badge log-badge-warning">
                                    {this.props.t('data_exchange_session.status.cancelled')}
                                </span>
                            );
                    }
                },
            },
            {
                columnName: 'statusMessage',
                type: TYPE_STRING,
                title: this.props.t('data_exchange_session.columns.status_message'),
                getCellValue: (row) => {
                    if (!row.statusMessage) {
                        return null;
                    }
                    const message =
                        row.statusMessage.length > 200
                            ? row.statusMessage.substring(0, 200) + '...'
                            : row.statusMessage;
                    return (
                        <Tooltip title={row.statusMessage} placement="bottom-start">
                            <div>{message}</div>
                        </Tooltip>
                    );
                },
            },
        ];
        return Promise.resolve(columns);
    }

    openFilteredSessionModal = (event: React.MouseEvent, session: DataExchangeSession, logsFilters: Filter[]) => {
        event.stopPropagation();
        this.openSessionModal(session, logsFilters);
    };

    openSessionModal = (session: DataExchangeSession, logsFilters: Filter[] = []) => {
        this.setState({ showLogs: true, currentSession: session, logsFilters });
    };

    closeSessionModal = () => {
        this.setState({ showLogs: false, currentSession: null });
    };

    getUsersPicklist = () => {
        return userManager.getAccountUsers(this.props.accountId).then((users: UserData[]) => {
            return users.map((user) => ({ value: user.id, label: user.name }));
        });
    };

    getEntitiesPicklist = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        return manager.list().then((dataSources: IDataSource[]) => {
            const picklist = [];
            for (const dataSource of dataSources) {
                for (const entityCounter of dataSource.entityCounters) {
                    picklist.push({
                        value: entityCounter.entity.id,
                        label: dataSource.name + ' > ' + entityCounter.entity.label,
                    });
                }
            }
            return picklist;
        });
    };

    getFilters(): any[] {
        const defaultFilters = this.props.searchParams
            ? this.convertFrontFiltersToServerFilters(this.props.searchParams[PARAM_FILTERS] ?? [])
            : [];
        return [...defaultFilters, ...super.getFilters()];
    }

    requestData(ignorePage = false, _parentTimer = null) {
        return dataExchangeManager.getSessions(
            this.props.accountId,
            this.getFilters(),
            this.sorting,
            ignorePage ? 1 : this.state.pagination.current + 1,
            this.state.pagination.size,
        );
    }

    InteractiveRow = (props: any) => {
        const { children, row, ...rest } = props;
        return (
            <HoverStyledRow {...rest} onClick={() => this.openSessionModal(row)}>
                {children}
            </HoverStyledRow>
        );
    };

    render() {
        return (
            <div className="log">
                {super.render()}

                {this.state.showLogs && this.state.currentSession && (
                    <DataExchangeLogModal
                        accountId={this.props.accountId}
                        user={this.props.user}
                        session={this.state.currentSession}
                        onClose={this.closeSessionModal}
                        filters={this.state.logsFilters}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(DataExchangeSessions);
