import React from 'react';
import PropTypes from 'prop-types';

class TabPanel extends React.PureComponent {
    render() {
        const { children, value, index } = this.props;
        return (
            <div role="tabpanel" hidden={value !== index} className="layers-views-tab-panel">
                {children}
            </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;
