import React from 'react';
import './style.css';
import {
    TYPE_CODE_STATEMENT,
    workflowActionManager,
    ACTION_TYPE_LIST,
    TYPE_COMMIT,
    TYPE_MARK_AS_VISITED,
} from '../../service/WorkflowActionManager';
import {
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    Icon,
    IconButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
} from '@material-ui/core';
import { Submenu } from '../Menu';
import cloneDeep from 'lodash/cloneDeep';
import ActionModals from '../WorkflowActions/ActionModals';
import PropTypes from 'prop-types';
import { SortableContainer, SortableHandle } from 'react-sortable-hoc';
import { withTranslation } from 'react-i18next';
import FormDialog from '../FormDialog';
import { FormulaEditor } from '../FormulaEditor';
import arrayMove from 'array-move';
import AddActionsForm from './AddActionForm';
import SortableAction from '../WorkflowActions/SortableAction';
import Confirmation from '../Confirmation';
import ExpressionBuilderForm from './ExpressionBuilderForm';
import { CallContext } from '../utils/CallContext';
import CodeStatement from '../WorkflowActions/CodeStatement';
import AddIconButton from '../CustomButton/AddIconButton';
import LandingLink, { ARTICLE_FORMULA_BUILDER } from '../HelpLink/LandingLink';
import Commit from '../WorkflowActions/Commit';
import MarkAsVisited from '../WorkflowActions/MarkAsVisited';

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_groups.drag_button">
        <Icon>dehaze</Icon>
    </IconButton>
));

const SortableActionList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((mapping, index) => {
                switch (mapping.action.type) {
                    case TYPE_CODE_STATEMENT:
                        return (
                            <CodeStatement
                                action={mapping.action}
                                contextName={mapping.contextName}
                                actionIndex={index}
                                key={`item-${index}`}
                                index={index}
                                {...props}
                            />
                        );
                    case TYPE_COMMIT:
                        return (
                            <Commit
                                action={mapping.action}
                                contextName={mapping.contextName}
                                actionIndex={index}
                                key={`item-${index}`}
                                index={index}
                                {...props}
                            />
                        );
                    case TYPE_MARK_AS_VISITED:
                        return (
                            <MarkAsVisited
                                action={mapping.action}
                                contextName={mapping.contextName}
                                actionIndex={index}
                                key={`item-${index}`}
                                index={index}
                                {...props}
                            />
                        );
                    default:
                        return (
                            <SortableAction
                                action={mapping.action}
                                contextName={mapping.contextName}
                                actionIndex={index}
                                key={`item-${index}`}
                                index={index}
                                {...props}
                            />
                        );
                }
            })}
        </div>
    );
});
SortableActionList.propTypes = {
    items: PropTypes.array.isRequired,
    onContextNameChange: PropTypes.func.isRequired,
    onActionEdit: PropTypes.func.isRequired,
    onActionRemove: PropTypes.func.isRequired,
    onActionChanged: PropTypes.func.isRequired,
};

class WorkflowGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            currentAction: null,
            currentActionIndex: null,
            openExpressionBuilder: false,
            openFormulaEditor: false,
            openExistingActionsModal: false,
            showAllActions: false,
        };
    }

    openActionMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeActionMenu = () => {
        this.setState({ anchorEl: null });
    };

    openActionModal = (action = null, type = null) => {
        this.closeActionMenu();

        if (action === null) {
            const callContext = this.props.disableCallContextInheritance ? null : this.props.callContext;
            action = workflowActionManager.constructor.getDefaultAction(
                this.props.account,
                type,
                callContext,
                this.props.parentNamespace,
            );
        }

        this.setState({
            currentAction: action,
        });
    };

    handleEditAction = (action, index) => {
        this.closeActionMenu();
        this.setState({
            currentAction: workflowActionManager.denormalizeAction(action),
            currentActionIndex: index,
        });
    };

    closeActionModal = () => {
        this.setState({ currentAction: null, currentActionIndex: null });
    };

    openExistingActionsModal = () => {
        this.closeActionMenu();
        this.setState({ openExistingActionsModal: true });
    };

    closeExistingActionsModal = () => {
        this.setState({ openExistingActionsModal: false });
    };

    createCodeStatement = () => {
        const action = workflowActionManager.constructor.getDefaultAction(
            this.props.account,
            TYPE_CODE_STATEMENT,
            this.props.callContext,
            this.props.parentNamespace,
        );
        this.addAction(action);
    };

    addMarkAsVisited = () => {
        const action = workflowActionManager.constructor.getDefaultAction(
            this.props.account,
            TYPE_MARK_AS_VISITED,
            this.props.callContext,
            this.props.parentNamespace,
        );
        action.name = this.props.t('workflow_actions.form.mark_as_visited.title');
        this.addAction(action);
    };

    addCommitAction = () => {
        const action = workflowActionManager.constructor.getDefaultAction(
            this.props.account,
            TYPE_COMMIT,
            this.props.callContext,
            this.props.parentNamespace,
        );
        this.addAction(action);
    };

    addAction = (action) => {
        const group = cloneDeep(this.props.group);
        const currentMappings = group.mappings || [];
        group.mappings = [...currentMappings, { contextName: null, action }];
        this.props.onGroupChange(this.props.groupIndex, group);
        this.closeActionMenu();
    };

    addExistingActions = (actions) => {
        const group = cloneDeep(this.props.group);
        const currentMappings = group.mappings || [];
        group.mappings = [...currentMappings, ...actions.map((action) => ({ contextName: null, action }))];
        this.props.onGroupChange(this.props.groupIndex, group);
        this.closeExistingActionsModal();
    };

    saveAction = (action) => {
        const index = this.state.currentActionIndex;
        const group = cloneDeep(this.props.group);
        if (index === null) {
            group.mappings.push({ contextName: null, action });
        } else {
            group.mappings[index] = { ...group.mappings[index], action };
        }
        this.props.onGroupChange(this.props.groupIndex, group);
        this.closeActionModal();
    };

    removeAction = (index) => {
        const group = cloneDeep(this.props.group);
        group.mappings = group.mappings.filter((m, i) => i !== index);
        this.props.onGroupChange(this.props.groupIndex, group);
    };

    changeAction = (index, action) => {
        const group = { ...this.props.group };
        group.mappings = group.mappings.map((mapping, i) => {
            if (i !== index) {
                return mapping;
            }
            mapping.action = action;
            return mapping;
        });

        this.props.onGroupChange(this.props.groupIndex, group);
    };

    openExpressionBuilder = () => {
        this.setState({ openExpressionBuilder: true });
    };

    closeExpressionBuilder = () => {
        this.setState({ openExpressionBuilder: false });
    };

    handleExpressionBuilderSave = ({ expression, validationResult }) => {
        const group = { ...this.props.group };
        group.expression = expression;
        group.formula = validationResult;
        this.props.onGroupChange(this.props.groupIndex, group);
        this.closeExpressionBuilder();
    };

    openFormulaEditor = () => {
        this.setState({ openFormulaEditor: true });
    };

    closeFormulaEditor = () => {
        this.setState({ openFormulaEditor: false });
    };

    handleFormulaEditorSave = (formula) => {
        const group = { ...this.props.group, formula, expression: null, builderDisabled: true };
        this.props.onGroupChange(this.props.groupIndex, group);
        this.closeFormulaEditor();
    };

    clearFormula = () => {
        const group = { ...this.props.group, formula: null, expression: null, builderDisabled: false };
        this.props.onGroupChange(this.props.groupIndex, group);
    };

    handleContextNameChange = (index, contextName) => {
        const group = { ...this.props.group };
        group.mappings[index] = { ...group.mappings[index], contextName };
        this.props.onGroupChange(this.props.groupIndex, group);
    };

    renderCondition = () => {
        if (this.props.group.formula) {
            return <span>{this.props.group.formula}</span>;
        }
        return (
            <div style={{ textAlign: 'center' }} className="text-muted">
                {this.props.t('workflow_groups.form.condition.placeholder')}
            </div>
        );
    };

    sortActions = ({ oldIndex, newIndex }) => {
        const group = { ...this.props.group };
        const mappings = group.mappings || [];
        group.mappings = arrayMove(mappings, oldIndex, newIndex);
        this.props.onGroupChange(this.props.groupIndex, group);
    };

    renderActions = () => {
        if (!(Array.isArray(this.props.group.mappings) && this.props.group.mappings.length)) {
            return <span>{this.props.t('workflow_groups.form.actions.placeholder')}</span>;
        }
        return (
            <SortableActionList
                items={this.props.group.mappings}
                onContextNameChange={this.handleContextNameChange}
                onActionEdit={this.handleEditAction}
                onActionRemove={this.removeAction}
                onActionChanged={this.changeAction}
                onSortEnd={this.sortActions}
                parentNamespace={this.props.parentNamespace}
                useDragHandle
                helperClass="sortable-item"
                t={this.props.t}
            />
        );
    };

    renderBuilderButton = () => {
        if ((this.props.group && this.props.group.builderDisabled) || !this.props.callContext.isValid()) {
            const tooltip =
                this.props.group && this.props.group.builderDisabled
                    ? this.props.t('workflow_groups.form.condition.builder_disabled')
                    : this.props.t('workflow_groups.form.condition.select_base_entity');
            return (
                <Tooltip title={tooltip}>
                    <span>
                        <Button
                            size="small"
                            color="primary"
                            disabled
                            data-testid="workflow_groups.form.condition.edit_in_builder"
                        >
                            {this.props.t('workflow_groups.form.condition.edit_in_builder')}
                        </Button>
                    </span>
                </Tooltip>
            );
        }
        return (
            <Button
                size="small"
                color="primary"
                onClick={this.openExpressionBuilder}
                data-testid="workflow_groups.form.condition.edit_in_builder"
            >
                {this.props.t('workflow_groups.form.condition.edit_in_builder')}
            </Button>
        );
    };

    toggleAllActionsSwitch = () => {
        this.setState((state) => ({ showAllActions: !state.showAllActions }));
    };

    filterAllActions = () => {
        if (this.state.showAllActions) {
            return this.props.allActions.filter((action) => !this.props.excludedActions.includes(action.type));
        }
        return this.props.allActions.filter((action) => {
            return (
                action.callContext.compatible(this.props.callContext) &&
                !this.props.excludedActions.includes(action.type)
            );
        });
    };

    render() {
        return (
            <React.Fragment>
                <Grid className="action-group-container" container spacing={1} alignItems="center">
                    <Grid item>
                        <DragHandle />
                    </Grid>
                    <Grid item xs>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item xs container spacing={1} alignItems="center">
                                <Grid item>
                                    <b>{this.props.t('workflow_groups.form.condition')}</b>
                                </Grid>
                                <Grid item>{this.renderBuilderButton()}</Grid>
                                <Grid item>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.openFormulaEditor}
                                        data-testid="workflow_groups.form.condition.edit_formula"
                                    >
                                        {this.props.t('workflow_groups.form.condition.edit_formula')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.clearFormula}
                                        data-testid="workflow_groups.form.condition.clear"
                                    >
                                        {this.props.t('workflow_groups.form.condition.clear')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ padding: '10px 0 20px 0' }}>{this.renderCondition()}</div>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <b>{this.props.t('workflow_groups.form.actions')}</b>
                            </Grid>
                            <Grid item>
                                <AddIconButton small onClick={this.openActionMenu} />
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={this.state.anchorEl !== null}
                                    onClose={this.closeActionMenu}
                                    disableAutoFocusItem
                                >
                                    <Submenu
                                        parentMenuOpen={this.state.anchorEl !== null}
                                        label={this.props.t('workflow_rules.form.actions.new')}
                                        data-testid="workflow_rules.form.actions.new"
                                    >
                                        {ACTION_TYPE_LIST.filter((type) => {
                                            return !this.props.excludedActions.includes(type.value);
                                        }).map((type) => (
                                            <MenuItem
                                                key={type.value}
                                                onClick={this.openActionModal.bind(this, null, type.value)}
                                            >
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Submenu>
                                    <MenuItem
                                        onClick={this.openExistingActionsModal}
                                        data-testid="workflow_rules.form.actions.existing"
                                    >
                                        {this.props.t('workflow_rules.form.actions.existing')}
                                    </MenuItem>
                                    {!this.props.excludedActions.includes(TYPE_CODE_STATEMENT) && (
                                        <MenuItem
                                            onClick={this.createCodeStatement}
                                            data-testid="workflow_rules.form.actions.code_statement"
                                        >
                                            {this.props.t('workflow_rules.form.actions.code_statement')}
                                        </MenuItem>
                                    )}
                                    {!this.props.excludedActions.includes(TYPE_COMMIT) && (
                                        <MenuItem
                                            onClick={this.addCommitAction}
                                            data-testid="workflow_rules.form.actions.commit"
                                        >
                                            {this.props.t('workflow_rules.form.actions.commit')}
                                        </MenuItem>
                                    )}
                                    {!this.props.excludedActions.includes(TYPE_MARK_AS_VISITED) && (
                                        <MenuItem
                                            onClick={this.addMarkAsVisited}
                                            data-testid="workflow_rules.form.actions.mark_as_visited"
                                        >
                                            {this.props.t('workflow_rules.form.actions.mark_as_visited')}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </Grid>
                        </Grid>
                        <div style={{ padding: '10px 0' }}>
                            {this.renderActions()}
                            {this.props.errors &&
                                this.props.errors[this.props.groupIndex] &&
                                this.props.errors[this.props.groupIndex].mappings && (
                                    <FormHelperText>{this.props.errors[this.props.groupIndex].mappings}</FormHelperText>
                                )}
                        </div>
                    </Grid>
                    <Grid item>
                        <Confirmation
                            text={this.props.t('workflow_groups.confirm.remove')}
                            onConfirm={this.props.onGroupDelete.bind(this, this.props.groupIndex)}
                        >
                            <Tooltip title={this.props.t('workflow_groups.remove.hint')}>
                                <IconButton
                                    className="close-btn"
                                    color="secondary"
                                    data-testid="workflow_groups.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </Confirmation>
                    </Grid>
                </Grid>

                <ActionModals
                    action={this.state.currentAction}
                    account={this.props.account}
                    dataSources={this.props.dataSources}
                    onSave={this.saveAction}
                    onCancel={this.closeActionModal}
                />
                {!!this.state.openExistingActionsModal && (
                    <FormDialog
                        title={
                            <React.Fragment>
                                {this.props.t('workflow_rules.form.actions.existing')}
                                <FormControlLabel
                                    style={{ marginLeft: 10 }}
                                    control={
                                        <Switch
                                            checked={this.state.showAllActions}
                                            onChange={this.toggleAllActionsSwitch}
                                            color="primary"
                                            data-testid="workflow_rules.form.show_all_actions"
                                        />
                                    }
                                    label={
                                        <span className="text-muted">
                                            {this.props.t('workflow_groups.form.show_all_actions')}
                                        </span>
                                    }
                                />
                            </React.Fragment>
                        }
                        onSave={this.addExistingActions}
                        onCancel={this.closeExistingActionsModal}
                        maxWidth="sm"
                        fullWidth
                        saveByPressingEnter
                    >
                        <AddActionsForm
                            selectedActions={this.props.group.mappings.map((m) => m.action)}
                            allActions={this.filterAllActions()}
                        />
                    </FormDialog>
                )}

                {this.state.openExpressionBuilder && (
                    <FormDialog
                        title={
                            <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap">
                                <Grid item>{this.props.t('workflow_rules.form.actions.expression_builder.title')}</Grid>
                                <Grid item>
                                    <LandingLink
                                        useLeadingIcon
                                        useTrailingIcon
                                        article={ARTICLE_FORMULA_BUILDER}
                                        style={{ fontSize: 16 }}
                                    >
                                        {this.props.t('help')}
                                    </LandingLink>
                                </Grid>
                            </Grid>
                        }
                        onCancel={this.closeExpressionBuilder}
                        onSave={this.handleExpressionBuilderSave}
                        maxWidth="lg"
                        fullWidth
                    >
                        <ExpressionBuilderForm
                            expression={this.props.group.expression || []}
                            callContext={this.props.callContext}
                            addSpecialSection={this.props.addSpecialSection}
                            addOldEntitySection={this.props.addOldEntitySection}
                            addUserSection
                        />
                    </FormDialog>
                )}
                <FormulaEditor
                    open={this.state.openFormulaEditor}
                    onSave={this.handleFormulaEditorSave}
                    onCancel={this.closeFormulaEditor}
                    callContext={this.props.callContext}
                    rawFormula
                    value={this.props.group && this.props.group.formula}
                />
            </React.Fragment>
        );
    }
}
WorkflowGroup.propTypes = {
    group: PropTypes.object.isRequired,
    groupIndex: PropTypes.number.isRequired,
    allActions: PropTypes.arrayOf(
        PropTypes.shape({
            callContext: PropTypes.instanceOf(CallContext).isRequired,
        }),
    ).isRequired,
    account: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onGroupChange: PropTypes.func.isRequired,
    onGroupDelete: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    addSpecialSection: PropTypes.bool.isRequired,
    addOldEntitySection: PropTypes.bool.isRequired,
    disableCallContextInheritance: PropTypes.bool.isRequired,
    parentNamespace: PropTypes.string.isRequired,
    excludedActions: PropTypes.array.isRequired,
    errors: PropTypes.object,
};

export default withTranslation('translations', { withRef: true })(WorkflowGroup);
