import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class ExpressionManager extends BackendService {
    validate(expression) {
        console.log(expression);
        const url = reverse(apiRoutes.expression.validate);
        return this.requestApi(url, 'POST', { expression });
    }

    toFormula(expression, callContext = null) {
        const url = reverse(apiRoutes.expression.toFormula);
        return this.requestApi(url, 'POST', {
            expression,
            callContext: callContext ? callContext.getRawData() : null,
        });
    }
}

export const expressionManager = new ExpressionManager();
