import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Badge from '../Badge';
import { PROSPECTING_MODULE } from '../Permissions/constants';
import { userManager } from '../../service/UserManager';
import { withTranslation } from 'react-i18next';
import WorkflowButtonFactory from '../WorkflowButtons/WorkflowButtonFactory';
import { LOCATIONS, ORDER } from '../../service/WorkflowButtonManager';
import Confirmation from '../Confirmation';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import ToCalendarButton from '../Calendar/ToCalendarButton/ToCalendarButton';
import { PopupType } from '../Calendar/settings';
import CalendarFormPopups from '../Calendar/FormPopups/CalendarFormPopups';
import { CalendarEventManager } from '../../service/Calendar/CalendarEventManager';
import usersPermissionsManager from '../../service/Permissions/UsersPermissionsManager';

class TableSelectionMenu extends React.Component {
    calendarEventManager = new CalendarEventManager();

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            openPopupType: undefined,
            popupData: undefined,
        };
    }

    handleOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
        this.props.onOpen && this.props.onOpen();
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
        this.props.onAction && this.props.onAction();
    };

    setPopupData = (openPopupType, popupData, callback) => {
        this.setState({ openPopupType, popupData }, callback);
    };

    closePopups = () => {
        this.setState({ openPopupType: undefined, popupData: undefined });
    };

    handleActionClick = (event) => {
        const action = event.currentTarget.getAttribute('data-action');
        const { onAddToRoute, onClear, onGroupEdit, onExportProspects, onDelete, onAddToWaitingList } = this.props;

        // eslint-disable-next-line default-case
        switch (action) {
            case 'add-to-route':
                onAddToRoute && onAddToRoute();
                break;
            case 'clear':
                onClear && onClear();
                break;
            case 'edit-records':
                onGroupEdit && onGroupEdit();
                break;
            case 'export-prospects':
                onExportProspects && onExportProspects();
                break;
            case 'delete':
                onDelete && onDelete();
                break;
            case 'add-to-waiting-list':
                onAddToWaitingList && onAddToWaitingList();
                break;
        }

        this.setState({
            anchorEl: null,
        });
        this.props.onAction && this.props.onAction();
    };

    handleWorkflowButtonClick = () => {
        this.setState({
            anchorEl: null,
        });
        dispatcher.dispatch(events.UPDATE_RECORDS_REQUEST, { entityId: this.props.entityId, fields: [] });
    };

    getAddToCalendarItem = () => {
        let addToCalendarItem = null;
        if (!usersPermissionsManager.hasCalendarUiPermission) {
            return addToCalendarItem;
        }

        const { t, items, entityId } = this.props;

        if (items.size === 1) {
            const point = items.values().next().value;

            addToCalendarItem = (
                <ToCalendarButton
                    key={'to_calendar_button'}
                    entityId={entityId}
                    pointId={point.id}
                    onClose={this.handleClose}
                    setPopupData={this.setPopupData}
                >
                    <MenuItem>{t('calendar.add_to_calendar_button')}</MenuItem>
                </ToCalendarButton>
            );
        }
        return addToCalendarItem;
    };

    getAddToWaitingListItem = () => {
        let addToWaitingListItem = null;
        if (!usersPermissionsManager.hasCalendarUiPermission) {
            return addToWaitingListItem;
        }

        const { t, items, count, prospectingMode } = this.props;
        const forAllItems = this.isForAllItems();

        if (items.size === 1) {
            return addToWaitingListItem;
        }

        const disabled = forAllItems || count > 50 || !this.props.canAddToWaitingList;
        const showLimitError = !prospectingMode && count > 50;

        addToWaitingListItem = (
            <MenuItem onClick={this.handleActionClick} data-action="add-to-waiting-list" disabled={disabled}>
                {t('calendar.add_to_calendar_waiting_list')}
            </MenuItem>
        );

        if (showLimitError) {
            addToWaitingListItem = (
                <Tooltip title={t('calendar.add_to_waiting_list_limit_error')}>
                    <div>{addToWaitingListItem}</div>
                </Tooltip>
            );
        }

        return addToWaitingListItem;
    };

    isForAllItems = () => {
        const { ids, items, count } = this.props;
        return !ids?.length && items.size === 0 && count > 0;
    };

    render() {
        const { count, prospectingMode, t } = this.props;
        const exportEnable = userManager.userHasAccessTo(
            PROSPECTING_MODULE.NAME,
            PROSPECTING_MODULE.FEATURES.EXPORT.NAME,
        );
        const groupActionsTitle =
            count === 0
                ? t('entity_data_table.table_selection_menu.group_actions.tooltip.disabled')
                : t('entity_data_table.table_selection_menu.group_actions.tooltip');
        const forAllItems = this.isForAllItems();

        return (
            <React.Fragment>
                <Tooltip title={groupActionsTitle}>
                    <div>
                        <IconButton
                            disabled={count === 0}
                            className="entity-data-table__entities__button"
                            onClick={this.handleOpen}
                            color="default"
                            data-testid="entity_data_table.table_selection_menu.group_actions"
                        >
                            <Badge badgeContent={this.props.items.size} color="primary">
                                <Icon className="fas fa-bolt" style={{ overflow: 'visible' }} fontSize="small" />
                            </Badge>
                            <div className="entity-data-table__entities__button__label">
                                {t('entity_data_table.table_selection_menu.group_actions.tooltip')}
                            </div>
                        </IconButton>
                    </div>
                </Tooltip>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl !== null}
                    onClose={this.handleClose}
                    disableAutoFocusItem
                    data-testid="entity_data_table.table_selection_menu.count"
                >
                    <MenuItem disabled>
                        {this.props.items.size > 0
                            ? t('entity_data_table.table_selection_menu.selected_count', { count: count })
                            : t('entity_data_table.table_selection_menu.all_count', { count: count })}
                    </MenuItem>
                    {this.getAddToCalendarItem()}
                    {this.getAddToWaitingListItem()}
                    {!prospectingMode && (
                        <WorkflowButtonFactory
                            location={LOCATIONS.TABLE_GROUP_ACTIONS}
                            entityId={this.props.entityId}
                            items={[...this.props.items.values()]}
                            itemIds={this.props.ids}
                            forAllItems={forAllItems}
                            order={ORDER.FIRST}
                            itemsCount={count}
                            onClick={this.handleWorkflowButtonClick}
                        />
                    )}
                    {this.props.canExportProspects && exportEnable && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="export-prospects"
                            data-testid="entity_data_table.table_selection_menu.export"
                        >
                            {t('entity_data_table.table_selection_menu.export')}
                        </MenuItem>
                    )}
                    {this.props.canAddToRoute && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="add-to-route"
                            disabled={this.props.items.size === 0}
                            data-testid="entity_data_table.table_selection_menu.add_to_route"
                        >
                            {t('entity_data_table.table_selection_menu.add_to_route')}
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={this.handleActionClick}
                        data-action="clear"
                        disabled={this.props.items.size === 0}
                        data-testid="entity_data_table.table_selection_menu.clear"
                    >
                        {t('entity_data_table.table_selection_menu.clear')}
                    </MenuItem>

                    {this.props.canEditRecords && (
                        <MenuItem
                            onClick={this.handleActionClick}
                            data-action="edit-records"
                            data-testid="entity_data_table.table_selection_menu.edit_selected"
                        >
                            {t('entity_data_table.table_selection_menu.edit_selected')}
                        </MenuItem>
                    )}
                    {this.props.canDeleteRecords && (
                        <Confirmation
                            text={t('entity_data_table.table_selection_menu.delete.confirmation', { count: count })}
                            onConfirm={(e) => {
                                e.currentTarget.setAttribute('data-action', 'delete');
                                this.handleActionClick(e);
                            }}
                        >
                            <MenuItem
                                onClick={this.handleActionClick}
                                data-action="delete"
                                data-testid="entity_data_table.table_selection_menu.delete"
                            >
                                {t('entity_data_table.table_selection_menu.delete')}
                            </MenuItem>
                        </Confirmation>
                    )}
                    {!prospectingMode && (
                        <WorkflowButtonFactory
                            location={LOCATIONS.TABLE_GROUP_ACTIONS}
                            entityId={this.props.entityId}
                            items={[...this.props.items.values()]}
                            itemIds={this.props.ids}
                            forAllItems={forAllItems}
                            order={ORDER.LAST}
                            itemsCount={count}
                            onClick={this.handleWorkflowButtonClick}
                        />
                    )}
                </Menu>

                {this.state.openPopupType === PopupType.FULL_FORM && (
                    <CalendarFormPopups
                        calendarEventManager={this.calendarEventManager}
                        onClose={this.closePopups}
                        calendarEvent={this.state.popupData?.calendarEvent ?? null}
                        calendar={this.state.popupData?.calendar ?? null}
                        anchorEl={this.state.popupData?.target}
                        preferFullForm={true}
                        usePopover={true}
                    />
                )}
            </React.Fragment>
        );
    }
}

TableSelectionMenu.propTypes = {
    prospectingMode: PropTypes.bool,
    entityId: PropTypes.number,
    items: PropTypes.instanceOf(Map),
    ids: PropTypes.array,
    count: PropTypes.number.isRequired,
    onAddToRoute: PropTypes.func,
    onClear: PropTypes.func,
    onAction: PropTypes.func,
    onExportProspects: PropTypes.func,
    onOpen: PropTypes.func,
    onAddToWaitingList: PropTypes.func,
    canEditRecords: PropTypes.bool,
    canExportProspects: PropTypes.bool,
    canDeleteRecords: PropTypes.bool,
    canAddToRoute: PropTypes.bool,
    canAddToWaitingList: PropTypes.bool,
};

export default withTranslation()(TableSelectionMenu);
