import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import dsManagerFactory from '../../../service/DsManager';
import { accountsManager } from '../../../service/AccountsManager';
import cloneDeep from 'lodash/cloneDeep';
import { AccountData, IDataSource } from '../../../service/types';
import { Schedule } from './types';
import { ScheduleManager, scheduleManager } from '../../../service/ScheduleManager';
import AutomationElementHeader from '../AutomationElementHeader';
import ScheduleList from './ScheduleList';
import ScheduleModal from './ScheduleModal';
import { RequestData, RequestParams } from '../../DataTable/DataTable';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';

interface CurrentProps extends WithTranslation, WithSnackbarProps {
    accountId: number | string;
    myAccount: boolean;
}

interface CurrentState {
    loading: boolean;
    rows: Schedule[];
    totalCount: number;
    account: AccountData | null;
    dataSources: IDataSource[];
    currentSchedule: Schedule | null;
}

class Schedules extends React.PureComponent<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            totalCount: 0,
            account: null,
            dataSources: [],
            currentSchedule: null,
        };
    }

    componentDidMount() {
        this.loadAccount();
        this.loadDataSources();

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload: { status: string }) => {
            if (payload.status === 'complete') {
                this.loadDataSources();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadSchedules = (requestParams: RequestParams) => {
        this.setState({ loading: true, rows: [], totalCount: 0 });

        return scheduleManager
            .list(
                this.props.accountId,
                requestParams.filters,
                requestParams.sorting,
                requestParams.currentPage + 1,
                requestParams.pageSize,
            )
            .then((data: RequestData<Schedule>) => {
                this.setState({ rows: data.items, loading: false, totalCount: data.total });
                return data;
            });
    };

    loadAccount = () => {
        accountsManager
            .load(this.props.accountId)
            .then((account: AccountData) => {
                this.setState({ account });
            })
            .catch((error: Error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    loadDataSources = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();

        this.setState({ dataSources: [] });

        manager
            .list()
            .then((data: IDataSource[]) => {
                data = data.map((source) => {
                    source.entities = source.entityCounters.map((entityCounter) => entityCounter.entity);
                    return source;
                });
                this.setState({ dataSources: data });
            })
            .catch((error: Error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    toggleActive = (schedule: Schedule) => {
        const index = this.state.rows.indexOf(schedule);
        schedule.isActive = !schedule.isActive;
        this.updateState(schedule, index);
        if (schedule.id) {
            scheduleManager
                .save(this.props.accountId, schedule)
                .then((schedule) => {
                    this.updateState(schedule, index);
                })
                .catch(() => {
                    schedule.isActive = !schedule.isActive;
                    this.updateState(schedule, index);
                });
        }
    };

    updateState = (schedule: Schedule, index: number | null = null) => {
        this.setState((state: CurrentState) => {
            const rows = cloneDeep(state.rows);
            let currentSchedule = null;

            if (index !== null && index >= 0) {
                rows[index] = schedule;
            }
            if (state.currentSchedule) {
                currentSchedule = schedule;
            }

            return { rows, currentSchedule };
        });
    };

    save = (schedule: Schedule) => {
        const { currentSchedule } = this.state;
        if (null === currentSchedule) {
            return;
        }
        this.closeModal();
        this.props.enqueueSnackbar(this.props.t('automation.schedule.notification.saved'), { variant: 'success' });
        this.setState((state) => {
            const rows = [...state.rows].filter((r) => r.id !== currentSchedule.id);
            rows.unshift(schedule);
            return { rows, totalCount: state.totalCount + 1 };
        });
    };

    remove = (schedule: Schedule) => {
        const scheduleId = schedule.id;
        if (null !== scheduleId) {
            this.setState({ loading: true }, () => {
                scheduleManager
                    .delete(this.props.accountId, scheduleId)
                    .then(() => {
                        this.setState((state) => {
                            const rows = [...state.rows].filter((row) => row.id !== scheduleId);
                            return { rows, totalCount: state.totalCount - 1 };
                        });
                    })
                    .catch(() => {
                        // todo notification
                    });
            });
        }
    };

    openModal = (schedule: Schedule | null = null) => {
        if (schedule === null) {
            schedule = ScheduleManager.getDefaultSchedule() as Schedule;
        }

        this.setState({
            currentSchedule: schedule,
        });
    };

    closeModal = () => {
        this.setState({ currentSchedule: null });
    };

    render() {
        const showAddButton =
            !!this.state.account && Array.isArray(this.state.dataSources) && this.state.dataSources.length > 0;
        return (
            <React.Fragment>
                <AutomationElementHeader
                    title={this.props.t('automation.schedule.title')}
                    addButtonHint={this.props.t('automation.schedule.hint.create')}
                    type={'schedules'}
                    showAddButton={showAddButton}
                    accountId={this.props.accountId}
                    myAccount={this.props.myAccount}
                    onOpenModal={this.openModal}
                />
                <div>
                    <ScheduleList
                        onRequestData={this.loadSchedules}
                        records={this.state.rows}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        dataSources={this.state.dataSources}
                        onEdit={this.openModal}
                        onRemove={this.remove}
                        onToggleActive={this.toggleActive}
                    />
                </div>

                {this.state.account && (
                    <ScheduleModal
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        onSaved={this.save}
                        onCancel={this.closeModal}
                        onToggleActive={this.toggleActive}
                        schedule={this.state.currentSchedule}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSnackbar(Schedules));
