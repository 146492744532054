import Form from './Form';
import FieldFactory from './FieldFactory';

class FormFactory {
    createForm(data) {
        const fieldFactory = new FieldFactory();

        const form = new Form()
            .setAccountId(data.accountId ?? null)
            .setTitle(data.label ?? null)
            .setFormActionId(data.actionId ?? null)
            .setEntityId(data.entityId ?? null)
            .setContextGuid(data.context_guid ?? null);
        for (let field of data.fields ?? []) {
            form.addItem(fieldFactory.createField(field));
        }

        return form;
    }

    updateForm(form, data) {
        const fieldFactory = new FieldFactory();
        const newItems = [];

        for (let field of data.fields ?? []) {
            const item = fieldFactory.createField(field);
            form.updateItem(item);
            newItems.push(item.getApiName());
        }

        const deletedItems = Array.from(form.fields.keys()).filter((apiName) => !newItems.includes(apiName));
        for (let apiName of deletedItems) {
            form.fields.delete(apiName);
            form.actionButtons.delete(apiName);
        }

        return form;
    }
}

export default FormFactory;
