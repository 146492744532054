import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Icon, IconButton } from '@material-ui/core';
import DottedLink from 'components/DottedLink';
import { FormBackdrop } from 'components/PureFormDialog/Form';
import clsx from 'clsx';

interface Props extends WithStyles, WithTranslation {
    loading: boolean;
}

interface State {
    expanded: boolean;
}

class SwitchModeInfo extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    handleExpand = () => {
        this.setState(({ expanded }) => ({
            expanded: !expanded,
        }));
    };

    render() {
        const { classes, loading, t } = this.props;
        const { expanded } = this.state;

        return (
            <FormBackdrop loading={loading}>
                <p style={{ marginTop: 0 }} dangerouslySetInnerHTML={{ __html: t('switch_mode.form.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('switch_mode.form.description') }} />
                <p className={classes.expansionControl}>
                    <span dangerouslySetInnerHTML={{ __html: t('switch_mode.form.learn_more') }} />
                    &nbsp;
                    <DottedLink onClick={this.handleExpand}>{t('switch_mode.safe.passive')}</DottedLink>
                    <IconButton
                        onClick={this.handleExpand}
                        className={classes.button}
                        data-testid="map_clustering_settings.expand_2"
                    >
                        <Icon fontSize="small">{expanded ? 'expand_less' : 'expand_more'}</Icon>
                    </IconButton>
                </p>
                <div
                    data-testid="account.user.login_as.form.learn_more"
                    className={clsx(classes.expansionPanel, expanded ? classes.expanded : undefined)}
                >
                    <p dangerouslySetInnerHTML={{ __html: t('switch_mode.form.info') }} />
                    <ul>
                        <li>{t('switch_mode.form.info.1')}</li>
                        <li>{t('switch_mode.form.info.2')}</li>
                        <li>{t('switch_mode.form.info.3')}</li>
                        <li>{t('switch_mode.form.info.4')}</li>
                        <li>{t('switch_mode.form.info.5')}</li>
                        <li>{t('switch_mode.form.info.6')}</li>
                        <li>{t('switch_mode.form.info.7')}</li>
                        <li>{t('switch_mode.form.info.8')}</li>
                    </ul>
                </div>
            </FormBackdrop>
        );
    }
}

const styles = {
    expansionControl: {
        margin: '8px 0',
    },
    expansionPanel: {
        overflow: 'hidden',
        transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        margin: '0 -16px',
        padding: '0 16px',
        maxHeight: 0,
    },
    button: {
        padding: 8,
    },
    expanded: {
        overflow: 'visible',
        maxHeight: 'initial',
    },
};

export default withTranslation()(withStyles(styles)(SwitchModeInfo));
