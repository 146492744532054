import ProcessorInterface from './ProcessorInterface';
import { directActionManager } from '../../workflow_actions/DirectActionManager';
import RunButton from '../Events/RunButton';
import { logDebug } from '../../../utils';

class RunButtonProcessor implements ProcessorInterface {
    public process(event: RunButton): void {
        return directActionManager.runButton(
            event.getAccountId(),
            event.getButtonId(),
            event.getEntityId(),
            event.getItemIds(),
            event.getForAllItems(),
            event.getAdditionalContextData(),
            event.getContextUlid(),
            event.getFilters(),
            event.getRecordsCount(),
            event.toBackend(),
        );
    }

    public onComplete(event: RunButton): void {
        logDebug(event);
    }
}

export default RunButtonProcessor;
