import apiRoutes from './apiRoutes';
import BackendService from 'api/BackendService';
import { Api, Geocoder } from 'interfaces';
import { RouteDenormalizer } from 'service/Serializer';
import { RoundTimeStep } from '../interfaces/routing/route';

class MapslyGeocoderApi extends BackendService {
    async buildRoute(payload: Geocoder.Route.BuildRoutePayload): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = apiRoutes.geocoder.route.build;
        const weightsPerAxleGroup: Geocoder.Route.BuildRouteWeightsPerAxleGroup = {};
        if (payload.vehicleProfile.weightsPerAxleGroup?.single) {
            weightsPerAxleGroup.single = payload.vehicleProfile.weightsPerAxleGroup.single;
        }
        if (payload.vehicleProfile.weightsPerAxleGroup?.tandem) {
            weightsPerAxleGroup.tandem = payload.vehicleProfile.weightsPerAxleGroup.tandem;
        }

        const params: Api.Route.GeocoderRouteResultRequest = {
            points: payload.points,
            optimize: payload.optimize,
            routeType: payload.routeType,
            useTraffic: payload.useTraffic,
            dateStartAt: payload.dateStartAt,
            isDateStartNow: payload.isDateStartNow,
            avoid: payload.vehicleProfile.avoid,
            truckType: payload.vehicleProfile.truckType,
            trailersCount: payload.vehicleProfile.trailersCount || null,
            axleCount: payload.vehicleProfile.axleCount || null,
            shippedHazardousGoods: payload.vehicleProfile.shippedHazardousGoods,
            limitedWeight: payload.vehicleProfile.limitedWeight || null,
            weightPerAxle: payload.vehicleProfile.weightPerAxle || null,
            weightsPerAxleGroup: weightsPerAxleGroup,
            height: payload.vehicleProfile.height || null,
            width: payload.vehicleProfile.width || null,
            length: payload.vehicleProfile.length || null,
            tunnelCategory: payload.vehicleProfile.tunnelCategory,
            addCalendarEventsToRoute: payload.addCalendarEventsToRoute,
            includeDraftEvents: payload.includeDraftEvents,
            roundStartTimes: payload.roundStartTimes,
            roundStartTimesValue: payload.roundStartTimesValue || RoundTimeStep.STEP_15,
            roundBreakStartTime: payload.roundBreakStartTime,
            ignoreConstraints: payload.ignoreConstraints,
            replaceRoutes: payload.replaceRoutes,
            daysPeriod: payload.daysPeriod,
            usersIds: payload.usersIds,
            objective: payload.objective,
            todayConfig: payload.todayConfig,
            ignoreWarnings: payload.ignoreWarnings,
            startPoint: payload.startPoint,
            endPoint: payload.endPoint,
            async: payload.async,
            continuousMode: payload.continuousMode,
            splitJobsLongerThan: payload.splitJobsLongerThan,
            maxOvertime: payload.maxOvertime,
        };

        const response: Api.Route.GeocoderRouteBuildResultApiResponse = await this.requestApi(url, 'POST', params);
        return this.denormalizeBuildResponse(response);
    }

    denormalizeBuildResponse = (
        response: Api.Route.GeocoderRouteBuildResultApiResponse,
    ): Api.Route.GeocoderRouteBuildResultResponse => {
        return {
            ...response,
            session: RouteDenormalizer.denormalizeSession(response.session),
            routes: response.routes !== null ? RouteDenormalizer.denormalizeRoutes(response.routes) : null,
        };
    };

    async refreshRoute(
        payload: Geocoder.Route.RefreshRoutePayload,
    ): Promise<Api.Route.GeocoderRouteBuildResultResponse> {
        const url = apiRoutes.geocoder.route.refresh;
        const params: Api.Route.GeocoderRefreshRouteRequest = { ...payload };

        const response: Api.Route.GeocoderRouteBuildResultApiResponse = await this.requestApi(url, 'POST', params);
        return {
            ...response,
            session: RouteDenormalizer.denormalizeSession(response.session),
            routes: response.routes !== null ? RouteDenormalizer.denormalizeRoutes(response.routes) : null,
        };
    }
}

export default new MapslyGeocoderApi();
