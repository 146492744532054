import React from 'react';
import { TextField } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';

import i18n from '../../../locales/i18n';
import { getDetail } from '../../../service/WorkflowActionManager';
import FormFactory from '../../../service/workflow_actions/forms/FormFactory';
import { isActiveEntity } from '../../../utils';
import PureFormDialog from '../../PureFormDialog';
import { FormActions } from '../../PureFormDialog/Form';
import AbstractForm from '../AbstractForm/AbstractForm';

import ActionButtonsList from './ActionButtonsList';
import FieldItemsList from './FieldItemsList';
import FieldFormModal from './Fields/FieldFormModal';

import '../style.css';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    constructor(props) {
        super(props);

        const formFactory = new FormFactory();
        this.state.form = formFactory.createForm(this.state.currentAction.details);
        this.state.currentField = null;
    }

    handleItemEdit = (item, position = null) => {
        if (position) {
            item.position = position;
        }
        this.setState({ currentField: item });
    };

    handleItemEditSave = (oldItem, item) => {
        this.setState((state) => {
            const form = cloneDeep(state.form);

            if (oldItem.getApiName() !== item.getApiName()) {
                form.removeItem(oldItem);
                form.addItem(item);
            } else {
                form.updateItem(item);
            }

            return this.applyForm(form);
        });
    };

    handleItemEditCancel = () => {
        this.setState({ currentField: null });
    };

    handleItemRemove = (item) => {
        this.setState((state) => {
            const form = cloneDeep(state.form);

            form.removeItem(item);

            return this.applyForm(form);
        });
    };

    handleFieldsReorder = (e) => {
        this.setState((state) => {
            const form = cloneDeep(state.form);

            form.reorderFields(e.oldIndex, e.newIndex);

            return this.applyForm(form);
        });
    };

    handleActionButtonsReorder = (e) => {
        this.setState((state) => {
            const form = cloneDeep(state.form);

            form.reorderActionButtons(e.oldIndex, e.newIndex);

            return this.applyForm(form);
        });
    };

    applyForm = (form) => {
        const action = this.state.currentAction;
        const result = form.serialize();

        action.details.fields = result.fields;

        return { form: form, currentAction: action, currentField: null };
    };

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }

            reject(errors);
        });
    }

    render() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        return (
            <>
                <PureFormDialog
                    title={t('workflow_actions.form.invoke_url.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.open_form.name')}
                        data-testid="workflow_actions.form.open_form.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.open_form.api_name')}
                        data-testid="workflow_actions.form.open_form.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />

                    {this.renderEntitySelect(currentAction, errors)}

                    <TextField
                        fullWidth
                        margin="dense"
                        required
                        label={t('workflow_actions.form.open_form.label')}
                        data-testid="workflow_actions.form.open_form"
                        name="label"
                        value={getDetail('label', details, '')}
                        onChange={this.handleDetailsChange}
                        error={errors.has('label')}
                        helperText={errors.get('label')}
                        InputProps={{ disableUnderline: false }}
                    />

                    <FieldItemsList
                        items={this.state.form.getFields()}
                        onAddItem={this.handleItemEdit}
                        onChangeItem={this.handleItemEdit}
                        onRemoveItem={this.handleItemRemove}
                        onReorder={this.handleFieldsReorder}
                    />

                    <ActionButtonsList
                        items={this.state.form.getActionButtons()}
                        onAddItem={this.handleItemEdit}
                        onChangeItem={this.handleItemEdit}
                        onRemoveItem={this.handleItemRemove}
                        onReorder={this.handleActionButtonsReorder}
                    />
                </form>
                {this.state.currentField !== null && (
                    <FieldFormModal
                        account={this.props.account}
                        action={this.state.currentAction}
                        dataSources={this.props.dataSources}
                        form={this.state.form}
                        field={this.state.currentField}
                        onSave={this.handleItemEditSave}
                        onCancel={this.handleItemEditCancel}
                        callContext={this.state.currentAction.callContext}
                    />
                )}
            </>
        );
    }
}

export { Form as OpenFormForm };
