import { Link } from '@material-ui/core';
import React from 'react';
const matchAll = require('string.prototype.matchall');

interface Props {
    text: string;
}

export default class MessageText extends React.PureComponent<Props> {
    render() {
        const { text } = this.props;

        const wrapped: (string | JSX.Element)[] = [];
        const urlRegex =
            /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*/g;
        const matchIterator = matchAll(text, urlRegex);

        let currentIndex = 0;
        while (true) {
            const part = matchIterator.next();
            if (part.value && part.value.index > currentIndex) {
                const plainText = text.substring(currentIndex, part.value.index);
                wrapped.push(plainText);
                currentIndex = part.value.index;
            }

            if (part.done === true) {
                if (text.length > currentIndex) {
                    const plainText = text.substring(currentIndex, text.length);
                    wrapped.push(plainText);
                }
                break;
            }

            currentIndex += part.value[0].length;

            if (!part.value) {
                continue;
            }

            const aElement = (
                <Link href={part.value[0]} target="_blank" rel="noopener noreferrer" key={currentIndex}>
                    {part.value[0]}
                </Link>
            );
            wrapped.push(aElement);
        }

        return <span>{wrapped}</span>;
    }
}
