import React, { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react';
import { Button, CircularProgress, Icon, IconButton, InputAdornment, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { FormActions } from 'components/PureFormDialog/Form';
import { FieldLookupType, FieldType, IField } from 'components/types';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';

import events from '../../events';
import LiveSearchFactory from '../../service/LiveSearchManager';
import dispatcher from '../../service/dispatcher';
import Alert from '../Alert';
import Backdrop from '../Backdrop';

import LiveSearchLookupRecordsForm from './LiveSearchLookupRecordsForm';
import LiveSearchResultsForm from './LiveSearchResultsForm';
import LookupRecordsForm from './LookupRecordsForm';
import LookupTableRecordsForm from './LookupTableRecordsForm';

interface LiveSearchTableViewFormProps extends WithTranslation, WithSnackbarProps {
    accountId: number;
    dataSourceId: number;
    field: Pick<IField, 'apiName' | 'lookupData' | 'type'> & { lookup?: IField['lookupData'] };
    value: any;
    recordId: string;
    allowEmptySubmit?: boolean;
    onSaveRequest: (record: any) => void;
    onSaveMultiLookupRequest: (toAdd: any[], toDelete: any[]) => void;
    onSubmitError: (error: any) => void;
    tableMode?: boolean;
    noDelete?: boolean;
}

type SelectedRecordItem = {
    id: string;
    title: string;
};

interface LiveSearchTableViewFormState {
    records: any[];
    /** строка поиска */
    search: string;
    /** выполняется ли поиск записей */
    searching: boolean;
    selectedRecord: any;
    selectedRecords: any[];
    nothingFound: boolean;
    /** загружается ли исходное множество записей для multiplelookup */
    loading: boolean;
    lookupRecords: any[];
    /** multiplelookup с возможностью выбора нескольких значений */
    lookupRecordsMode: boolean;
    searchModal: boolean;
    toAdd: SelectedRecordItem[];
    toDelete: SelectedRecordItem[];
}

class LiveSearchTableViewForm extends React.Component<LiveSearchTableViewFormProps, LiveSearchTableViewFormState> {
    liveSearchManager: any;
    searchTimeout: ReturnType<typeof setTimeout> | null;
    entityApiName: string;

    constructor(props: LiveSearchTableViewFormProps) {
        super(props);

        const initialState: LiveSearchTableViewFormState = {
            records: [],
            search: '', // строка поиска
            searching: false, // выполняется ли поиск записей
            selectedRecord: null,
            selectedRecords: [],
            nothingFound: false,
            loading: false, // загружается ли исходное множество записей для multiplelookup
            lookupRecords: [],
            lookupRecordsMode: false, // multiplelookup с возможностью выбора нескольких значений
            searchModal: false,
            toAdd: [],
            toDelete: [],
        };

        this.liveSearchManager = LiveSearchFactory.getManager(this.props.accountId, this.props.dataSourceId);
        this.searchTimeout = null;

        const { field, value } = this.props;

        switch (field.lookupData?.type!) {
            case FieldLookupType.POLYMORPHIC:
            case FieldLookupType.UNIVERSAL: {
                this.entityApiName = value?.apiName;
                break;
            }
            case FieldLookupType.ENTITY:
            case FieldLookupType.FEW:
            default: {
                this.entityApiName = field.lookupData!.apiName;
            }
        }

        switch (field.lookupData?.type!) {
            case FieldLookupType.ENTITY:
            case FieldLookupType.FOREIGN_ENTITY:
            case FieldLookupType.POLYMORPHIC:
            case FieldLookupType.FEW: {
                if (typeof value === 'object' && ['data', 'id', 'textValue'].every((it) => it in (value || {}))) {
                    initialState.selectedRecord = value;
                    // To allow deletion of initially selected value
                    initialState.records.push(value);
                }
                break;
            }
            case FieldLookupType.UNIVERSAL:
            default: {
                break;
            }
        }

        if (Array.isArray(value?.toAdd)) {
            initialState.toAdd.push(...value.toAdd);
        }
        if (Array.isArray(value?.toDelete)) {
            initialState.toDelete.push(...value.toDelete);
        }

        this.state = initialState;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_SEARCH_RECORDS_RESPONSE, this, this.onSearchCompleted);

        const { lookupData } = this.props.field;
        if ((lookupData && lookupData.linking_lookup_data) || lookupData!.type === FieldLookupType.FEW) {
            this.fetchRecords();
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WS_SEARCH_RECORDS_RESPONSE, this);
    }

    fetchRecords = () => {
        if (!this.props.recordId) {
            this.setState({
                lookupRecordsMode: true,
            });
            return;
        }
        this.setState({
            loading: true,
            lookupRecordsMode: true,
        });
        const { lookupData } = this.props.field;
        const { entity, fieldId, fieldName, sourceFieldId } = lookupData!.linking_lookup_data!;
        this.liveSearchManager
            .fetchLookupRecords(entity, fieldId, fieldName, sourceFieldId, this.props.recordId)
            .then((response: any) => {
                this.setState({
                    loading: false,
                    lookupRecords: response.items,
                });
            })
            .catch(this.onError);
    };

    onRemove: MouseEventHandler<HTMLElement> = (e) => {
        const id = e.currentTarget.dataset.recordId!;
        const title = e.currentTarget.dataset.recordTitle!;
        this.setState((state) => {
            return {
                toDelete: this.props.noDelete ? state.toDelete : [...state.toDelete, { id, title }],
                toAdd: state.toAdd.filter((r) => r.id !== id),
            };
        });
    };

    onCancel: MouseEventHandler<HTMLElement> = (e) => {
        const id = e.currentTarget.dataset.recordId;
        this.setState((state) => {
            return {
                toDelete: state.toDelete.filter((r) => r.id !== id),
                toAdd: state.toAdd.filter((r) => r.id !== id),
                lookupRecords: state.lookupRecords.filter((r) => r.id !== id),
            };
        });
    };

    onUndoRemove: MouseEventHandler<HTMLElement> = (e) => {
        const id = e.currentTarget.dataset.recordId;
        this.setState((state) => {
            return {
                toDelete: state.toDelete.filter((r) => r.id !== id),
                toAdd: state.toAdd.filter((r) => r.id !== id),
            };
        });
    };

    onUndoAdd: MouseEventHandler<HTMLElement> = (e) => {
        const recordId = e.currentTarget.dataset.recordId;
        this.setState((state) => {
            return {
                toAdd: state.toAdd.filter((r) => r.id !== recordId),
                lookupRecords: state.lookupRecords.filter((r) => r.id !== recordId),
            };
        });
    };

    onAdd: MouseEventHandler<HTMLElement> = (e) => {
        const id = e.currentTarget.dataset.recordId!;
        const title = e.currentTarget.dataset.recordTitle!;
        this.setState((state) => {
            return {
                toAdd: [...state.toAdd, { id, title }],
                toDelete: state.toDelete.filter((r) => r.id !== id),
            };
        });
    };

    onSearchCompleted = ({ search, error, records }: Record<any, any>) => {
        if (search !== this.state.search) {
            return;
        }
        if (null !== this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
        if (error) {
            this.setState({
                searching: false,
            });
            this.props.onSubmitError(error);
            return;
        }
        this.setState({
            searching: false,
            records,
            nothingFound: records.length === 0,
        });
    };

    setupTimeout = () => {
        if (null !== this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.setState({
                searching: false,
            });
            this.props.onSubmitError(this.props.t('live_update_table_form.live_search_table_view_form.errors.timeout'));
            this.searchTimeout = null;
        }, 30000);
    };

    submit = () => {
        const { t, allowEmptySubmit, onSubmitError, onSaveRequest, onSaveMultiLookupRequest, noDelete } = this.props;
        const { selectedRecord, selectedRecords, toAdd, toDelete, lookupRecordsMode } = this.state;

        if (lookupRecordsMode) {
            if (!toAdd.length && !toDelete.length && !allowEmptySubmit) {
                onSubmitError(t('live_update_table_form.live_search_table_view_form.errors.no_changes'));
                return;
            }
            onSaveMultiLookupRequest(toAdd, toDelete);
            return;
        }

        if (!!noDelete && !selectedRecord && !selectedRecords.length) {
            onSubmitError(t('live_update_table_form.live_search_table_view_form.errors.select_record'));
            return;
        }

        if (selectedRecord || (!noDelete && !selectedRecord && !selectedRecords.length)) {
            onSaveRequest(selectedRecord);
            return;
        }

        const ids: string[] = [];
        const textValues: string[] = [];

        for (let record of selectedRecords) {
            ids.push(record.id);
            textValues.push(record.textValue);
        }
        const result = {
            id: ids,
            textValue: textValues.join(', '),
        };

        onSaveRequest(result);
    };

    handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        this.setState({
            search: event.currentTarget.value,
        });
    };

    handleKeyPress: KeyboardEventHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (this.state.search.length >= 2) {
                this.handleSearch();
            }
        }
    };

    handleClick: MouseEventHandler<HTMLElement> = (event) => {
        const index = parseInt(event.currentTarget.dataset.recordIndex!);
        const textValue = event.currentTarget.dataset.textValue;
        const record = { ...this.state.records[index], textValue };
        if (this.props.field.type === FieldType.JSON) {
            this.setState((state) => {
                const exists = !!state.selectedRecords.find((r) => record.id === r.id);
                if (exists) {
                    return {
                        selectedRecords: state.selectedRecords.filter((r) => r.id !== record.id),
                    };
                }
                return {
                    selectedRecords: [...state.selectedRecords, record],
                };
            });
            return;
        }

        const { lookupData } = this.props.field;
        if (lookupData!.type === FieldLookupType.POLYMORPHIC) {
            record.apiName = this.entityApiName;
        }

        this.setState((state) => {
            return {
                selectedRecord: state.selectedRecord && state.selectedRecord.id === record.id ? null : record,
            };
        });
    };

    handleLookupClick = (record: any) => {
        const { lookupData } = this.props.field;
        const sourceField = lookupData!.linking_lookup_data?.sourceField.toLowerCase();
        const recordId = record.id;
        const recordTitle = sourceField
            ? record[sourceField]
                ? record[sourceField]
                : record.objectName
            : record.objectName;

        this.setState((state) => {
            const exists = !!state.lookupRecords.find((r) => r.id === recordId);
            if (exists) {
                return {
                    lookupRecords: state.lookupRecords.filter((r) => r.id !== recordId),
                    toDelete: this.props.noDelete
                        ? state.toDelete
                        : [...state.toDelete, { id: recordId, title: recordTitle }],
                    toAdd: state.toAdd.filter((r) => r.id !== recordId),
                };
            }
            return {
                lookupRecords: [...state.lookupRecords, record],
                toDelete: state.toDelete.filter((r) => r.id !== recordId),
                toAdd: [...state.toAdd, { id: recordId, title: recordTitle }],
            };
        });
    };

    handleSearchClose = () => {
        this.setState({
            search: '',
            searchModal: false,
            records: [],
        });
    };

    handleSearch = () => {
        this.setState({
            searching: true,
            nothingFound: false,
            searchModal: true,
        });
        const { lookupData, lookup } = this.props.field;
        if (this.state.lookupRecordsMode) {
            const { search, lookupRecords } = this.state;
            const fieldName = lookup?.linking_lookup_data ? lookup.linking_lookup_data.fieldId : 'id';
            const exclude = lookupRecords.map((r) => r[fieldName]);
            this.liveSearchManager
                .searchLookupRecords(this.entityApiName, search, exclude)
                .then((response: any) => {
                    const records = response.items;
                    const lookupRecords = this.state.lookupRecords.concat(
                        records.filter(
                            (record: any) =>
                                !!this.state.toAdd.find((it) => it.id === record.id) &&
                                !this.state.lookupRecords.find((it) => it.id === record.id),
                        ),
                    );
                    this.setState({
                        lookupRecords,
                        records,
                        searching: false,
                    });
                })
                .catch(this.onError);
            return;
        }
        if (lookupData!.type === FieldLookupType.OWNER) {
            this.liveSearchManager.searchOwner(this.state.search).then(this.setupTimeout).catch(this.onError);
        } else {
            this.liveSearchManager
                .searchEntity(this.entityApiName, this.state.search)
                .then(this.setRecords)
                .catch(this.onError);
        }
    };

    setRecords = (response: { records: any }) => {
        this.setState({
            records: response.records,
            searching: false,
            nothingFound: response.records.length === 0,
        });
    };

    onError = (error: Error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' });
        this.setState({
            loading: false,
            searchModal: false,
            searching: false,
        });
    };

    getTitleFields = () => {
        const { lookupData } = this.props.field;
        if (lookupData && lookupData.type === FieldLookupType.OWNER) {
            return ['fullName', 'email'];
        }

        return ['objectName'];
    };

    render() {
        const { t } = this.props;
        const { lookupData } = this.props.field;
        const {
            records,
            search,
            searching,
            selectedRecord,
            selectedRecords,
            nothingFound,
            loading,
            lookupRecords,
            lookupRecordsMode,
            searchModal,
        } = this.state;
        const titleFields = this.getTitleFields();

        return (
            <React.Fragment>
                <Backdrop loading={loading}>
                    <form noValidate autoComplete="off" className="c-live-search-form__body">
                        <FormControl fullWidth margin="dense">
                            <TextField
                                label={t('live_update_table_form.live_search_table_view_form.form.search.label')}
                                data-testid="live_update_table_form.live_search_table_view_form.form.search"
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleSearchChange}
                                onKeyPress={this.handleKeyPress}
                                disabled={searching}
                                fullWidth
                                margin="dense"
                                name="search"
                                value={search}
                                placeholder={t(
                                    'live_update_table_form.live_search_table_view_form.form.search.placeholder',
                                )}
                                autoFocus
                                className="c-live-search-form__search"
                                InputProps={{
                                    disableUnderline: false,
                                    endAdornment: (
                                        <>
                                            {searching ? (
                                                <CircularProgress size={18} />
                                            ) : (
                                                records.length > 0 &&
                                                lookupRecordsMode && (
                                                    <IconButton
                                                        onClick={this.handleSearchClose}
                                                        data-testid="live_update_table_form.live_search_table_view_form.form.search.clear"
                                                    >
                                                        <Icon fontSize="small">close</Icon>
                                                    </IconButton>
                                                )
                                            )}
                                            <InputAdornment
                                                position="end"
                                                className="c-live-search-form__field__search-button"
                                            >
                                                <Button
                                                    onClick={this.handleSearch}
                                                    disabled={searching || search.length < 2}
                                                    color="primary"
                                                    data-testid="live_update_table_form.live_search_table_view_form.form.search.button"
                                                >
                                                    {t(
                                                        'live_update_table_form.live_search_table_view_form.form.search.button',
                                                    )}
                                                </Button>
                                            </InputAdornment>
                                        </>
                                    ),
                                }}
                            />
                        </FormControl>
                    </form>
                    {nothingFound && (
                        <Alert canClose>{t('live_update_table_form.live_search_table_view_form.nothing_found')}</Alert>
                    )}
                    {!lookupRecordsMode && !searching && !!records.length && (
                        <LiveSearchResultsForm
                            records={records}
                            selectedRecord={selectedRecord}
                            selectedRecords={selectedRecords}
                            titleFields={titleFields}
                            onRowClick={this.handleClick}
                        />
                    )}
                    {lookupRecordsMode && searchModal && !searching && (
                        <React.Fragment>
                            <LiveSearchLookupRecordsForm
                                records={records}
                                lookupRecords={lookupRecords}
                                onRowClick={this.handleLookupClick}
                                sourceField={lookupData!.linking_lookup_data?.sourceField.toLowerCase()!}
                            />
                            <br style={{ marginBottom: '20px' }} />
                        </React.Fragment>
                    )}
                    {lookupRecordsMode && !this.props.tableMode && (
                        <LookupRecordsForm
                            records={lookupRecords}
                            fieldName={lookupData!.linking_lookup_data?.fieldName.toLowerCase()}
                            sourceField={lookupData!.linking_lookup_data?.sourceField.toLowerCase()}
                            toAdd={this.state.toAdd}
                            toDelete={this.state.toDelete}
                            onRemove={this.onRemove}
                            onUndoRemove={this.onUndoRemove}
                            onUndoAdd={this.onUndoAdd}
                        />
                    )}
                    {lookupRecordsMode && this.props.tableMode && (
                        <LookupTableRecordsForm
                            records={lookupRecords}
                            sourceField={lookupData!.linking_lookup_data!.sourceField.toLowerCase()}
                            searching={this.state.searching}
                            onAdd={this.onAdd}
                            onDelete={this.onRemove}
                            onCancel={this.onCancel}
                            toAdd={this.state.toAdd}
                            toDelete={this.state.toDelete}
                        />
                    )}
                </Backdrop>

                <FormActions>
                    <Button
                        color="primary"
                        onClick={this.submit}
                        data-testid="live_update_table_form.search.save_button"
                    >
                        {t('live_update_table_form.search.save_button.title')}
                    </Button>
                </FormActions>
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(withSnackbar(LiveSearchTableViewForm));
