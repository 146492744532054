import React, { useState } from 'react';
import { HeaderCell } from './index';
import { MultipleSelect } from '../Controls';

interface Props {
    value: string | null;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    clientHeight?: number;
    options: React.ReactNode[];
    renderValue: (selected: string[]) => string;
    style?: React.CSSProperties;
}

const PicklistFilterCell: React.FC<Props> = (props) => {
    const [value, setValue] = useState<string | null>(props.value);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(event.target.value);
        props.onChange(event);
    };

    return (
        <HeaderCell style={{ top: props.clientHeight }}>
            <MultipleSelect value={value} onChange={handleChange} renderValue={props.renderValue} style={props.style}>
                {props.options}
            </MultipleSelect>
        </HeaderCell>
    );
};

export default PicklistFilterCell;
