import React from 'react';
import { Tooltip, IconButton, Icon } from '@material-ui/core';
import DottedLink from '../../DottedLink';
import { getDetail, TYPE_OPEN_FORM, workflowActionManager } from '../../../service/WorkflowActionManager';
import AbstractList from '../AbstractList/AbstractList';
import i18n from '../../../locales/i18n';
import Confirmation from '../../Confirmation';
import { CHANGE_CUSTOM_API_NAME, CHANGE_TYPES } from './Action';
import { withSnackbar } from 'notistack';

const t = i18n.t.bind(i18n);

class List extends AbstractList {
    constructor(props) {
        super(props);

        this.state.forms = [];
    }
    componentDidMount() {
        this.loadForms().then(() =>
            this.getFields().then((columns) => {
                this.setupStructure(columns);
            }),
        );
    }

    loadForms() {
        return workflowActionManager
            .list(this.props.accountId, TYPE_OPEN_FORM, [], [{ columnName: 'createdAt', direction: 'desc' }], 0, null)
            .then((data) => {
                this.setState({ forms: data.items });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    renderChangeField(form, change) {
        if (change.field_api_name === CHANGE_CUSTOM_API_NAME) {
            return t('workflow_actions.form.update_form.changes.field_api_name.' + CHANGE_CUSTOM_API_NAME);
        }

        const field = form ? form.details.fields.find((field) => field.apiName === change.field_api_name) : null;

        return field
            ? field.label
            : t('workflow_actions.form.update_form.changes.field_api_name.' + CHANGE_CUSTOM_API_NAME);
    }

    renderChanges(action) {
        const changes = getDetail('changes', action.details, []);
        const formId = getDetail('form', action.details, null);
        const form = this.state.forms.find((form) => form.id === formId);

        return (
            <ul>
                {changes.map((change, index) => {
                    const showValueField = ![
                        CHANGE_TYPES.SHOW_FIELD,
                        CHANGE_TYPES.HIDE_FIELD,
                        CHANGE_TYPES.ENABLE_FIELD,
                        CHANGE_TYPES.DISABLE_FIELD,
                        CHANGE_TYPES.MAKE_REQUIRED,
                        CHANGE_TYPES.MAKE_OPTIONAL,
                    ].includes(change.type);
                    return (
                        <li key={index}>
                            <span style={{ textDecoration: 'underline' }}>{this.renderChangeField(form, change)}</span>{' '}
                            {t('workflow_actions.form.update_form.changes.type.' + change.type)} <span></span>{' '}
                            {showValueField && <span>: {change.value}</span>}
                        </li>
                    );
                })}
            </ul>
        );
    }

    initColumns() {
        return [
            {
                columnName: 'name',
                type: 'string',
                title: t('workflow_actions.list.update_form.name'),
                getCellValue: (action) => (
                    <React.Fragment>
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, action)}
                            data-testid="workflow_actions.list.update_form.name"
                        >
                            {action.name}
                        </DottedLink>
                    </React.Fragment>
                ),
            },
            {
                columnName: 'form',
                type: 'string',
                title: t('workflow_actions.list.update_form.form'),
                getCellValue: (action) => {
                    const formId = getDetail('form', action.details, null);
                    const form = this.state.forms.find((form) => form.id === formId);

                    return form ? (
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, form)}
                            data-testid="workflow_actions.list.update_form.form"
                        >
                            {form.name}
                        </DottedLink>
                    ) : null;
                },
            },
            {
                columnName: 'field_target_value',
                type: 'string',
                title: t('workflow_actions.list.update_form.field_target_value'),
                getCellValue: (action) => this.renderChanges(action),
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: t('workflow_actions.list.update_form.updated_by'),
                getCellValue: (action) => <span>{action.updatedBy ? action.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: t('workflow_actions.list.update_form.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (action) => (
                    <Confirmation
                        text={t('workflow_actions.confirm.remove')}
                        onConfirm={this.props.onActionRemove.bind(this, action)}
                    >
                        <Tooltip title={t('remove')}>
                            <IconButton
                                size="small"
                                color="secondary"
                                data-testid="workflow_actions.update_form.confirm.remove"
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ];
    }
}

const SnackbarredList = withSnackbar(List);

export { SnackbarredList as UpdateFormList };
