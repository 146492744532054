import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import LandingLink, { ARTICLE_USER_MANAGEMENT_AND_SSO } from 'components/HelpLink/LandingLink';

interface Props extends WithTranslation {
    onClose(): void;
}

class YouCanConnectYourUsersViaSSODialog extends React.PureComponent<Props> {
    render() {
        const { t, onClose } = this.props;

        return (
            <Dialog open={true}>
                <DialogTitle>{t('user_form.dialogs.you_can_connect_via_sso.title')}</DialogTitle>
                <DialogContent>
                    <span>{t('user_form.dialogs.you_can_connect_via_sso.text')}</span>
                    &nbsp;
                    <LandingLink article={ARTICLE_USER_MANAGEMENT_AND_SSO}>
                        {t('user_form.dialogs.you_can_connect_via_sso.sso')}
                    </LandingLink>
                    &nbsp;
                    <span>?</span>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        {t('button.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(YouCanConnectYourUsersViaSSODialog);
