import React from 'react';
import PopupNotification from './index';
import { withTranslation } from 'react-i18next';

class SsuNotification extends PopupNotification {
    getTitle = () => {
        return this.props.t('popup_notification.sso_unlink_notification.title');
    };

    getContent = () => {
        const { unlinkedAccount, t } = this.props;
        const name = unlinkedAccount ? unlinkedAccount.name : undefined;

        return (
            <React.Fragment>
                <p>{t('popup_notification.sso_unlink_notification.p', { name: name ? name : '' })}</p>
            </React.Fragment>
        );
    };
}

export default withTranslation()(SsuNotification);
