import React from 'react';

interface ParameterizedMessageProps {
    text: string;
    params: { [key: string]: any };
}

/**
 * This component gives a possibility to embed components in your text using HTML tags.
 * Example:
 * <ParameterizedMessage
 *   text="Some <tag1>your text</tag1> is here <tag2/>."
 *   params={{
 *       tag1: (text) => <SomeYourComponent>{text}</SomeYourComponent>, // text = "your text"
 *       tag2: <Icon>warning</Icon>,
 *   }}
 * />
 */
class ParameterizedMessage extends React.Component<ParameterizedMessageProps> {
    private getNodes(): Array<string | Node> {
        let nodes = [];
        let text = this.props.text;
        const regex = /(<([a-z]+)\s*\/>|<([a-z]+)>(.+)<\/\3>)/i;

        do {
            const match = text.match(regex);

            if (!Array.isArray(match)) {
                break;
            }

            const tag = match[2] ?? match[3];
            const content = match[4];

            if (tag in this.props.params) {
                if (this.props.params[tag] && typeof this.props.params[tag] === 'function') {
                    const [prefix, suffix] = text.split(match[1], 2);
                    nodes.push(prefix);
                    nodes.push(this.props.params[tag](content));
                    text = suffix;
                } else if (this.props.params[tag] && typeof this.props.params[tag] === 'object') {
                    const [prefix, suffix] = text.split(match[1], 2);
                    nodes.push(prefix);
                    nodes.push(this.props.params[tag]);
                    text = suffix;
                } else {
                    text = text.replace(match[1], this.props.params[tag]);
                }
            } else {
                const [prefix, suffix] = text.split(match[1], 2);
                nodes.push(prefix + content);
                text = suffix;
            }
        } while (true);

        nodes.push(text);

        return nodes;
    }

    render() {
        return <>{this.getNodes()}</>;
    }
}

export default ParameterizedMessage;
