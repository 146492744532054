/**
 * Rewrite of logrocket-react to include data-testId as name of react-component to allow search by it.
 */
import ReactDom from 'react-dom';
import LogRocket from 'logrocket';

var getInstanceFromNode = void 0;
// @ts-expect-error
var secret = ReactDom.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
if (secret && secret.Events && secret.Events[0]) {
    getInstanceFromNode = secret.Events[0];
} else {
    console.warn('logrocket-react does not work with this version of React');
}

export function setupLogRocketReact(_lr: typeof LogRocket) {
    var listener = function listener(event: MouseEvent) {
        try {
            // @ts-expect-error
            var fiberNode = getInstanceFromNode(event.target);
            var names = [];

            // @ts-expect-error - Here we add testId to component names list
            const dataTestId = event.target?.dataset?.testid;
            if (dataTestId) {
                names.push(dataTestId);
            }

            var currentElement = fiberNode;
            while (currentElement) {
                var name = typeof currentElement.elementType === 'function' && currentElement.elementType.displayName;
                if (name) {
                    names.push(name);
                }
                currentElement = currentElement.return;
            }

            // @ts-expect-error
            event.__lrName = names;
            // console.log('>>>__lrName', event.__lrName);
        } catch (err) {
            console.error(
                'logrocket-react caught an error while hooking into React. Please make sure you are using the correct version of logrocket-react for your version of react-dom.',
            );
        }
    };

    document.body.addEventListener('click', listener, { capture: true, passive: true });
}
