import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DsEntityField, { fieldProtectionReasons } from './DsEntityField';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from '../FormDialog';
import PureFormDialog from '../PureFormDialog';
import AddressForm from '../AddressForm';
import './style.css';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import EntityHandler from '../../handlers/EntityHandler';
import GeoMapperForm from '../GeoMapperForm';
import DottedLink from '../DottedLink';
import { trimLookupIdLabel } from '../../utils';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import { withTranslation } from 'react-i18next';
import { grey, red } from '@material-ui/core/colors';
import Confirmation from '../Confirmation';
import DsEntityLockedByList from './DsEntityLockedByList';
import Hint from '../Hint';
import { Autocomplete } from '@material-ui/lab';
import { MenuItem, TextField } from '@material-ui/core';
import { SingleRowMultipleAutocomplete } from '../SingleRowMultipleAutocomplete';
import WarningOverwriteFieldsDialog from '../WarningOverwriteFieldsDialog';
import DsEntitySettings from './DsEntitySettings';
import EntityAndFieldsMessages from '../ImportMessage/EntityAndFieldsMessages';
import WarningProbablyPartialAddressFieldDialog from '../WarningProbablyPartialAddressFieldDialog';
import { AdapterId } from 'components/types';
import { ORDERED_LEVELS } from '../utils/CompositeAddress';
import DisabledOptionWithHintAutocomplete from '../DisabledOptionWithHintAutocomplete';
import { NO_PIN_SYSTEM_ENTITIES } from 'references/systemEntities';
import { appointmentMetadataChecker } from 'service/MapPage';

const adaptersHaveAdvancedSettings = [AdapterId.HUBSPOT, AdapterId.PIPEDRIVE];

class DsEntity extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isScheduleOpen: false,
            isGeoMapperOpen: false,
            currentAddressField: null,
            expanded: this.props.open,
            warningDialogOpen: false,
            tempGeoMapping: null,
            tempGeoDiffFields: null,
            isSettingsOpen: false,
            warningPinAddressDialogOpen: false,
            selectPinAddress: null,
        };

        this.needRecalculation = true;
        this.protectedFields = new Map();
        this.pinSelect = React.createRef();
    }

    calculateAssignableFields() {
        const lookupFields = this.getLookupFields();
        const nonLookupFields = this.getNonLookupFields();
        this.availableAddressFields = lookupFields
            .concat(nonLookupFields)
            .filter((field) => field.isSuitableForAddress);
        this.availableNameFields = lookupFields.concat(nonLookupFields).filter((field) => field.isSuitableForName);
        this.availableOwnerFields = this.props.entity.fields.filter((field) => field.isSuitableForOwner);
    }

    getPinField() {
        const { entity } = this.props;
        for (let field of entity.fields) {
            if (field.isIncluded && field.isPin) {
                return field;
            }
        }
        return null;
    }

    getLookupFields() {
        return this.props.entity.fields.filter((field) => field.lookupData);
    }

    getNonLookupFields() {
        return this.props.entity.fields.filter((field) => !field.lookupData);
    }

    static createAddressField() {
        const getRandomInt = (min, max) => {
            return Math.floor(Math.random() * (max - min)) + min;
        };

        return {
            id: 0,
            apiName: 'a' + new Date().getTime() + getRandomInt(100, 1000),
            label: '',
            type: 'text',
            address: {},
            picklist: null,
            lookup: null,
            default: null,
            isAddress: false,
            isIncluded: true,
            isCustom: true,
            isPin: false,
        };
    }

    handleEntityActivity = (active) => {
        if (this.state.expanded && !active) {
            this.setState({
                expanded: false,
            });
        }
        const { entity, onEntityActivityChanged } = this.props;
        onEntityActivityChanged(entity, active);
    };

    static time24ToTime12(time24) {
        const match = time24.match(/^(\d?\d):(\d\d)$/);
        let h = parseInt(match[1], 10);
        let m = match[2];
        let a = 'am';
        if (h >= 12) {
            a = 'pm';
            h -= 12;
        }
        if (h === 0) {
            h = 12;
        }
        return h + ':' + m + ' ' + a;
    }

    getScheduleDescription() {
        const { t, entity } = this.props;
        if (!entity.schedule) {
            return t('data_source.ds_entity.schedule.sync_interval', {
                interval: t('data_source.ds_entity.schedule.off'),
            });
        }

        const m = (interval) => {
            if (interval === 0) {
                return t('data_source.ds_entity.schedule.interval.0');
            }
            if (interval === 60) {
                return t('data_source.ds_entity.schedule.interval.60');
            }
            return t('data_source.ds_entity.schedule.interval.minutes', { minutes: interval });
        };

        const a = t('data_source.ds_entity.schedule.sync_interval', { interval: m(entity.schedule.interval) });
        if (!entity.schedule.tune) {
            return a;
        }

        return (
            a +
            ' (' +
            m(entity.schedule.tune.interval) +
            ' ' +
            t('data_source.ds_entity.schedule.tune.from_to', {
                from: DsEntity.time24ToTime12(entity.schedule.tune.from),
                to: DsEntity.time24ToTime12(entity.schedule.tune.to),
            }) +
            ' ' +
            ')'
        );
    }

    handlePinChanged = (field, confirm) => {
        if (confirm || !field || field?.address) {
            this.needRecalculation = true;
            this.props.onPinChanged(this.props.entity, field);
            return;
        }

        this.setState({
            warningPinAddressDialogOpen: true,
            selectPinAddress: field,
        });
    };

    handleWarningPinAddressDialogConfirmed = () => {
        this.handlePinChanged(this.state.selectPinAddress, true);
        this.handleWarningPinAddressDialogClosed();
    };

    handleWarningPinAddressDialogClosed = () => {
        this.setState({
            warningPinAddressDialogOpen: false,
            selectPinAddress: null,
        });
    };

    handleOwnerFieldChanged = (event, field) => {
        this.needRecalculation = true;
        this.props.onOwnerFieldChanged(this.props.entity, field?.id ?? null);
    };

    handleAddPinAddress = (e) => {
        e.stopPropagation();
        if (!this.props.entity.isIncluded) {
            return;
        }
        this.setState({
            expanded: true,
        });
        this.pinSelect.current.focus();
    };

    handleExpand = (event, expanded) => {
        if (expanded && !this.props.entity.isIncluded) {
            return;
        }
        this.setState({
            expanded,
        });
    };

    getCoordinatesFieldsApiNames() {
        let apiNames = [];

        const { entity } = this.props;
        for (let field of entity.fields) {
            if (entity.latitudeField && entity.latitudeField === field.id) {
                apiNames.push(field.apiName);
            }
            if (entity.longitudeField && entity.longitudeField === field.id) {
                apiNames.push(field.apiName);
            }
        }

        return apiNames;
    }

    manageProtectionOfCoordinatesFields() {
        let coordinatesFieldsApiNames = this.getCoordinatesFieldsApiNames();

        // delete coordinate fields from protected
        for (let [fieldApiName, reasons] of this.protectedFields.entries()) {
            if (reasons?.includes(fieldProtectionReasons.coordinates)) {
                if (reasons.length > 1) {
                    this.protectedFields.set(
                        fieldApiName,
                        reasons.filter((reason) => reason !== fieldProtectionReasons.coordinates),
                    );
                } else {
                    this.protectedFields.delete(fieldApiName);
                }
            }
        }

        // add coordinate fields to protected
        coordinatesFieldsApiNames.forEach((fieldApiName) => {
            const fieldReasons = this.protectedFields.get(fieldApiName) || [];

            if (!fieldReasons.includes(fieldProtectionReasons.coordinates)) {
                fieldReasons.push(fieldProtectionReasons.coordinates);
                this.protectedFields.set(fieldApiName, fieldReasons);
            }
        });
    }

    isLookupFieldFn = (field) => this.getLookupFields().find((lookupField) => lookupField.apiName === field.apiName);

    handleWarningDialogCancel = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.entityActiveCalendars.length !== this.props.entityActiveCalendars.length) {
            this.needRecalculation = true;
        }
    }

    getOptionDisabled = (option) => {
        if (!option) {
            return false;
        }
        const { entity } = this.props;
        if (entity.territoriesFieldId === null) {
            return false;
        }
        if (option.id === entity.territoriesFieldId) {
            return true;
        }
        if (option.address) {
            const territoriesFieldApiName = entity.fields.find(
                (field) => field.id === entity.territoriesFieldId,
            )?.apiName;
            if (!territoriesFieldApiName) {
                return false;
            }
            return ORDERED_LEVELS.some((level) => territoriesFieldApiName === option.address[level]);
        }
        return false;
    };

    getInseparable = (field) => {
        let inseparable = this.protectedFields.get(field.realApiName);

        if (!inseparable && field.lookupFields?.length) {
            inseparable = this.protectedFields.get(
                field.lookupFields.find((item) => this.protectedFields.has(item.apiName))?.apiName,
            );
        }
        return inseparable || [];
    };

    getFieldCalendars = (field, appointmentsRequiredFields = {}) => {
        let calendars = appointmentsRequiredFields[field.apiName];

        if (Object.keys(appointmentsRequiredFields).length && !calendars?.length && field.lookupFields?.length) {
            const apiName = field.lookupFields.find((item) => appointmentsRequiredFields[item.apiName])?.apiName;

            if (apiName) {
                calendars = appointmentsRequiredFields[apiName];
            }
        }

        return calendars || [];
    };

    render() {
        const { entity, dataSource, subscription, t, entityActiveCalendars } = this.props;
        let appointmentsRequiredFields = {};

        if (this.needRecalculation) {
            appointmentsRequiredFields =
                appointmentMetadataChecker.getRequiredAppointmentFieldsWithCalendars(entityActiveCalendars);
            this.calculateAssignableFields();
            this.protectedFields = EntityHandler.getProtectedFields(
                entity,
                dataSource,
                Object.keys(appointmentsRequiredFields),
            );
            this.needRecalculation = false;
        }

        this.manageProtectionOfCoordinatesFields();

        const pinField = this.getPinField();
        const ownerFieldId = entity.ownerField;

        const latLngTitle = entity.useLatLngFields
            ? t('data_source.ds_entity.lat_lng_title')
            : t('data_source.ds_entity.address_title');
        const isLocked =
            entity.isIncluded &&
            ((entity.isLockedBy && entity.isLockedBy.length > 0) || entityActiveCalendars.length !== 0);

        const fieldsLabels = new Map();
        const activeFields = new Map();
        for (let field of entity.fields) {
            fieldsLabels.set(field.apiName, field.label);

            if (!field.lookupData) {
                activeFields.set(field.apiName, { ...field, realApiName: field.apiName, lookupFields: [] });
            } else {
                const apiName = field.originalApiName || field.apiName;
                const activeField = activeFields.get(apiName);
                if (!activeField) {
                    activeFields.set(apiName, {
                        ...field,
                        apiName,
                        realApiName: field.apiName,
                        label: field.label.replace(/\s\((ID|NAME|TYPE)\)$/, ''),
                        lookupFields: [field],
                    });
                } else {
                    activeField.lookupFields.push(field);
                    activeFields.set(apiName, activeField);
                }
            }
        }

        const currentNameFields = [];
        entity.nameFields.forEach((nameApiName) => {
            const field = this.availableNameFields.find((field) => nameApiName === field.apiName);
            if (field) {
                currentNameFields.push(field);
            }
        });

        const currentAddressField = this.availableAddressFields.find((field) => field.apiName === pinField?.apiName);
        const currentOwnerField = this.availableOwnerFields.find((field) => field.id === ownerFieldId);

        const hasAdvancedSettings =
            adaptersHaveAdvancedSettings.includes(dataSource.adapterId) && entity.advancedSettings !== null;

        const showPinWarning =
            pinField === null &&
            !entity.useLatLngFields &&
            !(dataSource.isSystem && NO_PIN_SYSTEM_ENTITIES.includes(entity.name));

        return (
            <Accordion className="c-ds-entity" expanded={this.state.expanded} onChange={this.handleExpand}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{ disabled: !entity.isIncluded }}
                    style={{ padding: '0 24px 0 12px', width: '100%' }}
                >
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item sm={4}>
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <div style={{ padding: '12px' }}>
                                        {entity.label} <span className="api-name">{entity.name}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} container alignItems="center" justify="flex-start">
                            {showPinWarning && (
                                <Tooltip title={t('data_source.ds_entity.add_pin.tooltip')}>
                                    <div className="warning" style={{ padding: '0 24px' }}>
                                        <Icon style={{ verticalAlign: 'text-bottom', marginRight: 5 }}>warning</Icon>
                                        <span style={{ verticalAlign: 'text-bottom' }} className="no-address">
                                            {t('data_source.ds_entity.add_pin.text')}
                                            <DottedLink
                                                onClick={this.handleAddPinAddress}
                                                data-testid="data_source.ds_entity.add_pin.link"
                                            >
                                                {t('data_source.ds_entity.add_pin.link')}
                                            </DottedLink>
                                        </span>
                                    </div>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item sm={3} container alignItems="center" justify="flex-end">
                            {!dataSource.isSystem && (
                                <div className="schedule-desc">
                                    <DottedLink
                                        onClick={this.handleOpenSchedule}
                                        data-testid="data_source.ds_entity.schedule.open_modal"
                                    >
                                        {this.getScheduleDescription()}
                                    </DottedLink>
                                </div>
                            )}
                        </Grid>
                        <Grid item sm={1} style={{ textAlign: 'right' }}>
                            {!dataSource.isSystem && (
                                <Tooltip
                                    title={
                                        isLocked ? (
                                            <DsEntityLockedByList
                                                isLockedBy={entity.isLockedBy}
                                                hasActiveCalendars={entityActiveCalendars.length !== 0}
                                                dataSourceName={dataSource.name}
                                            />
                                        ) : (
                                            ''
                                        )
                                    }
                                >
                                    <span>
                                        <Confirmation
                                            disabled={isLocked}
                                            onConfirm={() => {
                                                this.handleEntityActivity(false);
                                            }}
                                            text={t('data_source.entity.remove.popover', { name: entity.label })}
                                        >
                                            <IconButton
                                                style={{ color: isLocked ? grey[500] : red[500] }}
                                                data-testid="data_source.entity.remove.popover.delete"
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Confirmation>
                                    </span>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails style={{ display: 'block' }}>
                    <Grid container spacing={1} alignItems="center" className="toolbar">
                        <Grid item xs={12} md={3}>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title={t('data_source.ds_entity.settings.address')}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => this.handleOpenAddress(null)}
                                        data-testid="data_source.ds_entity.settings.address.open"
                                    >
                                        <Icon
                                            className="fas fa-map-marker-plus"
                                            style={{ overflow: 'visible' }}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={latLngTitle}>
                                    <IconButton
                                        color="primary"
                                        onClick={this.handleOpenGeoMapper}
                                        data-testid="data_source.ds_entity.settings.open_geo_mapper"
                                    >
                                        {entity.useLatLngFields ? (
                                            <span className="lat-lng-fields">
                                                <Icon className="fas fa-crosshairs" fontSize="small" />
                                                <Icon className="fas fa-check" fontSize="small" />
                                            </span>
                                        ) : (
                                            <Icon
                                                className="fas fa-crosshairs"
                                                style={{ overflow: 'visible' }}
                                                fontSize="small"
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                {hasAdvancedSettings && (
                                    <Tooltip title={t('data_source.entity.settings.advanced_settings')}>
                                        <IconButton
                                            color="primary"
                                            onClick={this.handleOpenSettings}
                                            data-testid="data_source.entity.settings.advanced_settings.open"
                                        >
                                            <Icon
                                                className="fas fa-cog"
                                                style={{ overflow: 'visible' }}
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    {t('data_source.ds_entity.name_fields.label')}
                                    <Tooltip title={t('data_source.ds_entity.name_fields.tooltip')}>
                                        <i className="fas fa-question-circle" style={{ marginLeft: 8 }} />
                                    </Tooltip>
                                </InputLabel>
                                <SingleRowMultipleAutocomplete
                                    options={this.availableNameFields}
                                    value={currentNameFields || []}
                                    getOptionLabel={trimLookupIdLabel}
                                    groupBy={(field) => {
                                        return this.isLookupFieldFn(field)
                                            ? t('data_source.settings.pin_address.lookup_fields_group_name')
                                            : t('data_source.settings.pin_address.non_lookup_fields_group_name');
                                    }}
                                    multiple={true}
                                    size="small"
                                    disableClearable={true}
                                    onChange={this.handleObjectNameChanged}
                                    renderOption={(field) => (
                                        <React.Fragment>
                                            <Checkbox
                                                color="primary"
                                                checked={entity.nameFields.includes(field.apiName)}
                                            />
                                            {trimLookupIdLabel(field)}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="data_source.ds_entity.name_fields"
                                            placeholder={
                                                entity.nameFields.length === 0 ? t('undefined_picklist_option') : ''
                                            }
                                            margin="normal"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>{t('data_source.ds_entity.pin_address.label')}</InputLabel>
                                <DisabledOptionWithHintAutocomplete
                                    options={this.availableAddressFields}
                                    value={currentAddressField || null}
                                    getOptionLabel={(field) => {
                                        let label = field.label;
                                        if (this.isLookupFieldFn(field)) {
                                            label = label.replace(' (ID)', '');
                                        }
                                        return label;
                                    }}
                                    groupBy={(field) => {
                                        return this.isLookupFieldFn(field)
                                            ? t('data_source.settings.pin_address.lookup_fields_group_name')
                                            : t('data_source.settings.pin_address.non_lookup_fields_group_name');
                                    }}
                                    onChange={(_, value) => this.handlePinChanged(value, false)}
                                    getOptionDisabled={this.getOptionDisabled}
                                    disabledOptionHint={t('geomapper.record.list_fields.disabled')}
                                    dataTestId="data_source.ds_entity.pin_address"
                                    placeholder={t('undefined_picklist_option')}
                                    inputMargin="normal"
                                    inputRef={this.pinSelect}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>{t('data_source.ds_entity.owner_field.label')}</InputLabel>
                                <Autocomplete
                                    options={this.availableOwnerFields}
                                    value={currentOwnerField || null}
                                    getOptionLabel={trimLookupIdLabel}
                                    onChange={this.handleOwnerFieldChanged}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="data_source.ds_entity.owner_field"
                                            placeholder={t('undefined_picklist_option')}
                                            margin="normal"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <EntityAndFieldsMessages
                        dataSource={this.props.dataSource}
                        entity={entity}
                        subscription={subscription}
                    />
                    <FormGroup style={{ flexDirection: 'unset' }}>
                        <Table className="ds-entity-table" padding="normal" style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '40%', paddingLeft: 16 }}>
                                        {t('data_source.ds_entity.field.name')}
                                        {
                                            <span className="check-tools">
                                                <DottedLink
                                                    onClick={() => this.props.onCheckAll(entity, true)}
                                                    data-testid="data_source.ds_entity.field.check_all"
                                                >
                                                    {t('data_source.ds_entity.field.check_all')}
                                                </DottedLink>
                                                <DottedLink
                                                    onClick={() => this.props.onCheckAll(entity, false)}
                                                    data-testid="data_source.ds_entity.field.uncheck_all"
                                                >
                                                    {t('data_source.ds_entity.field.uncheck_all')}
                                                </DottedLink>
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell style={{ width: '40%' }}>
                                        {t('data_source.ds_entity.field.api_name')}
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        {t('data_source.ds_entity.field.type')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...activeFields].map(([_, field]) => (
                                    <DsEntityField
                                        key={field.apiName}
                                        field={field}
                                        fieldsLabels={fieldsLabels}
                                        inseparable={this.getInseparable(field)}
                                        onAddressFormRequest={() => this.handleOpenAddress(field)}
                                        onFieldChanged={this.handleFieldChanged}
                                        dataSource={dataSource}
                                        subscription={subscription}
                                        fieldCalendars={this.getFieldCalendars(field, appointmentsRequiredFields)}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </FormGroup>

                    {this.state.isScheduleOpen && (
                        <FormDialog
                            title={
                                <Grid container>
                                    <Grid item>
                                        {t('data_source.ds_entity.map_view_settings.auto_import_schedule')} &nbsp;
                                    </Grid>
                                    <Grid item>
                                        <MenuItem
                                            component="a"
                                            href="https://help.mapsly.com/articles/4312788"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-testid="data_source.ds_entity.map_view_settings.auto_import_schedule.help"
                                        >
                                            <Grid container item justify="center" alignItems="center">
                                                <Grid item>
                                                    <Hint type="inline" />
                                                </Grid>
                                                <Grid item>
                                                    <span style={{ color: '#3f51b5' }}>
                                                        {t(
                                                            'data_source.ds_entity.map_view_settings.auto_import_schedule.help',
                                                        )}
                                                    </span>
                                                </Grid>
                                                <Grid item>
                                                    <Icon
                                                        style={{
                                                            color: 'gray',
                                                            marginLeft: 6,
                                                            display: 'block',
                                                            fontSize: '1.1rem',
                                                        }}
                                                    >
                                                        launch
                                                    </Icon>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    </Grid>
                                </Grid>
                            }
                            onSave={this.handleSaveSchedule}
                            onCancel={this.handleCloseSchedule}
                            PaperProps={{ style: { width: 520 } }}
                        >
                            <ScheduleForm schedule={this.props.entity.schedule} />
                        </FormDialog>
                    )}

                    {this.state.isGeoMapperOpen && (
                        <FormDialog
                            title={this.props.t('geomapper.modal.title')}
                            onSave={this.handleGeoMapperSaved}
                            onCancel={this.handleCloseGeoMapper}
                            maxWidth="md"
                            dialogClassName="c-geo-mapper"
                        >
                            <GeoMapperForm entity={this.props.entity} dataSource={this.props.dataSource} />
                        </FormDialog>
                    )}
                    {this.state.warningDialogOpen && (
                        <WarningOverwriteFieldsDialog
                            onSave={this.handleGeoMapperSavedWithConfirmation}
                            onCancel={this.handleWarningDialogCancel}
                            fields={this.state.tempGeoDiffFields}
                        />
                    )}
                    {this.state.warningPinAddressDialogOpen && (
                        <WarningProbablyPartialAddressFieldDialog
                            onSave={this.handleWarningPinAddressDialogConfirmed}
                            onCancel={this.handleWarningPinAddressDialogClosed}
                        />
                    )}

                    {this.state.currentAddressField !== null && (
                        <FormDialog
                            title={t('data_source.ds_entity.address_field_mapper.title')}
                            onSave={this.handleSaveAddress}
                            onCancel={this.handleCloseAddress}
                        >
                            <AddressForm addressField={this.state.currentAddressField} entity={this.props.entity} />
                        </FormDialog>
                    )}

                    {this.state.isSettingsOpen && (
                        <PureFormDialog
                            title={t('data_source.entity.settings.advanced_settings')}
                            open
                            onClose={this.handleCloseSettings}
                            scroll="body"
                            fullWidth
                        >
                            <DsEntitySettings
                                adapterId={dataSource.adapterId}
                                settings={entity.advancedSettings}
                                onSave={this.handleSaveSettings}
                            />
                        </PureFormDialog>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }

    handleFieldChanged = (oldField, newField) => {
        this.needRecalculation = true;
        this.props.onFieldChanged(this.props.entity, oldField, newField);
    };

    handleObjectNameChanged = (event, fields) => {
        const { entity, onEntityObjectNameChanged } = this.props;
        this.needRecalculation = true;
        onEntityObjectNameChanged(
            entity,
            fields?.map((field) => field.apiName),
        );
    };

    handleCloseAddress = () => {
        this.setState({
            currentAddressField: null,
        });
    };

    handleOpenAddress = (field) => {
        if (field === null) {
            field = this.constructor.createAddressField();
        }
        this.setState({
            currentAddressField: field,
        });
    };

    handleSaveAddress = (field) => {
        const oldField = this.state.currentAddressField;
        this.setState({
            currentAddressField: null,
        });
        this.needRecalculation = true;
        this.props.onFieldChanged(this.props.entity, oldField, field);
    };

    // MAP VIEW HANDLERS

    handleOpenGeoMapper = () => {
        this.setState({
            isGeoMapperOpen: true,
        });
    };

    handleCloseGeoMapper = () => {
        this.setState({
            isGeoMapperOpen: false,
        });
    };

    handleGeoMapperSaved = (mapping, diffFields) => {
        if (!this.state.warningDialogOpen && diffFields.length > 0) {
            this.setState({
                warningDialogOpen: true,
                tempGeoMapping: mapping,
                tempGeoDiffFields: diffFields,
            });
            return;
        }

        this.setState({
            isGeoMapperOpen: false,
        });
        this.props.onGeoMapperSaved(this.props.entity, mapping);
    };

    handleGeoMapperSavedWithConfirmation = () => {
        const mapping = this.state.tempGeoMapping;
        this.needRecalculation = true;
        this.setState({
            isGeoMapperOpen: false,
            warningDialogOpen: false,
            tempGeoMapping: null,
            tempGeoDiffFields: null,
        });
        this.props.onGeoMapperSaved(this.props.entity, mapping);
    };

    // SCHEDULE HANDLERS

    handleCloseSchedule = () => {
        this.setState({
            isScheduleOpen: false,
        });
    };

    handleOpenSchedule = (e) => {
        e.stopPropagation();
        this.setState({
            isScheduleOpen: true,
        });
    };

    handleSaveSchedule = (schedule) => {
        this.setState({
            isScheduleOpen: false,
        });
        this.props.onScheduleSaved(this.props.entity, schedule);
    };

    handleOpenSettings = () => {
        this.setState({
            isSettingsOpen: true,
        });
    };

    handleCloseSettings = () => {
        this.setState({
            isSettingsOpen: false,
        });
    };

    handleSaveSettings = (advancedSettings) => {
        this.handleCloseSettings();
        this.props.onSettingsSaved(this.props.entity, advancedSettings);
    };
}

DsEntity.propTypes = {
    entity: PropTypes.object.isRequired,
    dataSource: PropTypes.object.isRequired,
    subscription: PropTypes.object,
    onEntityActivityChanged: PropTypes.func.isRequired,
    onEntityObjectNameChanged: PropTypes.func.isRequired,
    onScheduleSaved: PropTypes.func.isRequired,
    //onConflictIconChanged: PropTypes.func.isRequired,
    onCheckAll: PropTypes.func.isRequired,
    onPinChanged: PropTypes.func.isRequired,
    onOwnerFieldChanged: PropTypes.func.isRequired,
    onFieldChanged: PropTypes.func.isRequired,
    open: PropTypes.bool,
    appointmentsData: PropTypes.object.isRequired,
    entityActiveCalendars: PropTypes.array.isRequired,
};

DsEntity.default = {
    open: false,
};

export default withTranslation('translations', { withRef: true })(DsEntity);
