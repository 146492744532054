import config from '../params';
import { userManager } from './UserManager';
import { reverse, routes } from '../routes';
import dispatcher from './dispatcher';
import events from '../events';
import subscriptionManager from './SubscriptionManager';
import { formatMoney } from '../components/Billing/MoneyFormat';
import { SCREEN_WIDTH_NO_OVERLAP_BARS } from '../components/MapPage/contants';
import logRocketManager from './LogRocket';

class IntercomManager {
    constructor() {
        this.actionsAferLoader = [];
        this.appId = null;
        if (config.intercom !== undefined && config.intercom.appId !== undefined) {
            this.appId = config.intercom.appId;
        }

        this.loaded = false;
        this.initIntercom();
    }

    execute(func) {
        if (this.appId === null) {
            return;
        }

        if (typeof window.Intercom === 'function') {
            func.call(this);
        } else {
            this.actionsAferLoader.push(func);
        }
    }

    showNewMessage(message) {
        this.execute(() => {
            window.Intercom('showNewMessage', message);
        });
    }

    showWidget() {
        this.execute(() => {
            window.Intercom('show');
        });
    }

    closeWidget() {
        this.execute(() => {
            window.Intercom('hide');
        });
    }

    isLoaded = () => {
        return this.loaded;
    };

    trackEvent = (event, properties) => {
        this.execute(() => {
            window.Intercom('trackEvent', event, properties);
        });
    };

    changeSession() {
        const user = userManager.getCurrentUser();
        this.execute(() => {
            window.Intercom('shutdown');
            if (!user) {
                this.handleBoot(null, null);
                return;
            }
            if (userManager.isRoleSuperAdmin() || userManager.isSwitched() || user.role.forSharedMap) {
                this.loaded = false;
                dispatcher.dispatch(events.EVENT_INTERCOM_STATE_CHANGED);
                return;
            }
            if (user.accountId) {
                const manager = subscriptionManager.getManager(user.accountId);
                manager.getSubscription().then((sub) => this.handleBoot(user, sub));
                return;
            }
            this.handleBoot(user, null);
        });
    }

    handleBoot(user, subscription) {
        const props = {
            app_id: this.appId,
            hide_default_launcher: true,
        };

        if (user) {
            props.showTour = document.body.clientWidth > SCREEN_WIDTH_NO_OVERLAP_BARS;
            props.role = user.role.code;
            props.email = user.email;
            props.name = user.name;
            props.user_id = user.id;
            props.accountSettingUrl = this.getUrl(user);
            props.creator = user.isCreatorAccount;

            const account = userManager.getCurrentAccount();
            if (account) {
                props.ssoProvider = user.ssoProvider;
                props.signUpStageNumber = account.signUpStage;
                props.notSystemDataSourcesCount = account.notSystemDataSourcesCount;
                props.notSystemDataSourcesEntityRecordCount = account.notSystemDataSourcesEntityRecordCount;
                props.trialDaysLeft = account.subscription?.trialDays ?? 0;

                if (account.purposeOfUse) {
                    props.expectedUsersCount = account.purposeOfUse.expectedUsersCount;
                    props.previouslyUsedSoftware = account.purposeOfUse.previouslyUsedSoftware;
                    props.problemToSolve = account.purposeOfUse.problemToSolve;

                    account.purposeOfUse.features.forEach((feature) => {
                        const featurePropertyName =
                            'purposeOfUseFeature' + feature.name.charAt(0).toUpperCase() + feature.name.slice(1);
                        props[featurePropertyName] = true;

                        feature.dependencies.forEach((dep) => {
                            const dependencyPropertyName =
                                featurePropertyName + dep.name.charAt(0).toUpperCase() + dep.name.slice(1);
                            props[dependencyPropertyName] = true;
                        });
                    });
                }
            }

            const logRocketUrl = logRocketManager.sessionUrl ?? logRocketManager.getUserUrl(user.id);
            if (typeof logRocketUrl === 'string') {
                props.logRocketUrl = logRocketUrl;
            }
        }
        if (subscription) {
            props.plan = subscription.plan.name;
            props.statusLastChangedAt = subscription.statusLastChangedAt;
            props.status = subscription.status;
            props.monthlyBill = formatMoney(subscription.currency, subscription.subPriceTotalCur);
            props.orgCreated = subscription.subscriptionStartedAt;
        }
        window.Intercom('boot', props);
        this.loaded = true;
        dispatcher.dispatch(events.EVENT_INTERCOM_STATE_CHANGED);
        window.Intercom('onShow', () => {
            dispatcher.dispatch(events.EVENT_INTERCOM_SHOW_MESSAGE);
        });
        window.Intercom('onHide', () => {
            dispatcher.dispatch(events.EVENT_INTERCOM_HIDE_MESSAGE);
        });
    }

    getUrl(user) {
        let url = window.location.origin + reverse(routes.admin.account.index, { accountId: user.accountId });
        return url.replaceAll('capacitor', 'https');
    }

    initIntercom() {
        if (this.appId === null) {
            return;
        }

        let intercom = function () {
            intercom.c(arguments);
        };
        intercom.q = [];
        intercom.c = function (args) {
            intercom.q.push(args);
        };
        window.Intercom = intercom;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://widget.intercom.io/widget/' + this.appId;
        let firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
        script.onload = () => {
            this.actionsAferLoader.forEach((action) => {
                action.call(this);
            });
        };
    }
}

export const intercomManager = new IntercomManager();
