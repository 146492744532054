import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { weAreInNativeApp } from '../../utils';
import appUpdateManager from '../../service/MobileApp/AppUpdateManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import PureFormDialog from '../PureFormDialog';
import { Button, DialogActions, PropTypes, Typography } from '@material-ui/core';

interface State {
    showUpdateModal: boolean;
    updateCancelled: boolean;
}

class AppUpdateModal extends React.PureComponent<WithTranslation, State> {
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            showUpdateModal: false,
            updateCancelled: false,
        };
    }

    componentDidMount() {
        if (weAreInNativeApp()) {
            dispatcher.subscribe(events.SHOW_APP_UPDATE_MODAL, this, this.showAppUpdateModal);
        }
    }

    componentWillUnmount() {
        if (weAreInNativeApp()) {
            dispatcher.unsubscribeFromAllEvents(this);
        }
    }

    showAppUpdateModal = () => {
        this.setState({ showUpdateModal: true });
    };

    handleClickInstallButton = () => {
        appUpdateManager.openAppStore();
    };

    handleClickCancelButton = () => {
        this.setState({ updateCancelled: true });
    };

    handleClickOkIUUnderstandButton = () => {
        this.setState({ showUpdateModal: false, updateCancelled: false });
    };

    render() {
        const { t } = this.props;
        const { showUpdateModal, updateCancelled } = this.state;

        if (!weAreInNativeApp() || !showUpdateModal) {
            return null;
        }

        const actions = [];
        if (!updateCancelled) {
            actions.push({
                title: t('mobile_app.update_modal.button.cancel'),
                onClick: this.handleClickCancelButton,
                color: 'default',
            });
            actions.push({
                title: t('mobile_app.update_modal.button.ok'),
                onClick: this.handleClickInstallButton,
                color: 'primary',
            });
        } else {
            actions.push({
                title: t('mobile_app.update_modal.cancel.button.ok'),
                onClick: this.handleClickOkIUUnderstandButton,
                color: 'primary',
            });
        }

        const dialogActions = (
            <DialogActions>
                {actions.map((action, index) => {
                    return (
                        <Button key={index} onClick={action.onClick} color={action.color as PropTypes.Color}>
                            {action.title}
                        </Button>
                    );
                })}
            </DialogActions>
        );

        return (
            <PureFormDialog
                open
                title={t('mobile_app.update_modal.title') as string}
                actions={dialogActions}
                onClose={this.handleClickCancelButton}
                hideCloseButton={true}
            >
                <Typography variant="body1" color="textSecondary">
                    {!updateCancelled ? t('mobile_app.update_modal.text') : t('mobile_app.update_modal.cancel.text')}
                </Typography>
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(AppUpdateModal);
