export enum GEO_FIELDS {
    LAT = 'mapsly_geo_lat',
    LNG = 'mapsly_geo_lng',
    STATUS = 'mapsly_geo_status',
    RESULT = 'mapsly_geo_result',
}

export enum GEOCODER_SERVICES {
    GOOGLE = 'google',
    HERE = 'here',
    MAPQUEST = 'mapquest',
    MAPBOX = 'mapbox',
}
