import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormHelperText, Grid, InputAdornment, InputLabel } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import Hint from '../../Hint';

interface DelayInputProps extends WithTranslation {
    value: string;
    onValueChanged: (value?: number) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

const MeetingDurationInput: React.FC<DelayInputProps> = (props) => {
    const { t, value, onValueChanged, error, disabled, bareInput } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value !== '' ? parseInt(event.target.value, 10) : undefined;
        onValueChanged(value).then(() => validate(value));
    };

    const validate = (value: number | undefined): Promise<void> => {
        const { onInputValidation } = props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        const min = 0;
        const max = 600;
        const isValid = value === undefined || (value >= min && value <= max);
        let error = undefined;
        if (!isValid) {
            if (value === null) {
                error = t('validation.value.required');
                // @ts-ignore
            } else if (value < min || value > max) {
                error = t('validation.value.min_max', { min, max });
            }
        }
        return onInputValidation(isValid, error);
    };

    return (
        <Grid container>
            <Grid item xs>
                <FormControl fullWidth>
                    {!bareInput && (
                        <InputLabel shrink={true}>
                            {t('route_editor.modal.routing_preferences.modal.default_meeting_duration')}
                        </InputLabel>
                    )}
                    <TextField
                        error={!!error}
                        disabled={disabled}
                        value={value}
                        fullWidth
                        onChange={handleChange}
                        variant="standard"
                        type="number"
                        margin={bareInput ? 'none' : 'normal'}
                        data-testid="route_editor.modal.routing_preferences.modal.input_min_postfix"
                        InputProps={{
                            inputProps: { min: 0, max: 600, style: { textAlign: 'end' } },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {t('route_editor.modal.routing_preferences.modal.input_min_postfix')}
                                </InputAdornment>
                            ),
                        }}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                <Hint iProps={{ style: { marginLeft: 8 } }}>
                    {t('route_editor.modal.routing_preferences.modal.default_meeting_duration.hint')}
                </Hint>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(MeetingDurationInput);
