import { CalendarEvent, CalendarEventField, DRAFT_ACTION } from '../../service/Calendar/CalendarEventRepository';
import { Calendar } from '../../interfaces/calendar/calendar';
import { GeoStatus, LocationValidity } from '../../interfaces/geo';

export const eventFieldsToSchedulerMapping = {
    id: 'id',
    ownerId: 'ownerId',
    subject: { name: 'subject' },
    isAllDay: { name: 'isAllDay' },
    startTime: { name: 'startTime' },
    endTime: { name: 'endTime' },
    location: { name: 'location' },
};

export type ScheduleCalendarEvent = {
    id: string;
    ownerId: number;
    subject: string;
    isAllDay: boolean;
    startTime: Date;
    endTime: Date;
    location: string | null;
    travelHead: boolean;
    travelTail: boolean;
    breakTravelTail?: boolean;
    originalEvent: CalendarEvent;
};

export const calendarEventDefaultValues: CalendarEvent = {
    id: '',
    ownerId: 0,
    draftAction: DRAFT_ACTION.CREATE,
    draft: true,
    title: '',
    allDay: false,
    calendarId: '',
    startDatetime: '',
    endDatetime: '',
    fixedTime: true,
    location: '',
    lng: null,
    lat: null,
    virtual: false,
    conflictType: undefined,
    new: true,
    headRouteActivity: null,
    routeActivity: null,
    locationValidity: LocationValidity.Invalid,
    break: false,
    geoStatus: GeoStatus.NoAddress,
};

export enum ActionCompleteRequestType {
    VIEW_NAVIGATE = 'viewNavigate',
    DATE_NAVIGATE = 'dateNavigate',
    EVENT_REMOVED = 'eventRemoved',
}

export enum PopupType {
    CONTEXT_LEFT = 'context_left',
    CONTEXT_RIGHT = 'context_right',
    QUICK_FORM = 'quick_form',
    FULL_FORM = 'full_form',
}

export type PopupData = {
    calendarEvent?: CalendarEvent;
    calendar?: Calendar;
    target?: Element;
    ownerId?: number;
    startTime?: Date;
    endTime?: Date;
    relatedToField?: CalendarEventField;
    allDay?: boolean;
};

export type UserOption = {
    id: number;
    name: string;
};
