import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import addMinutes from 'date-fns/addMinutes';

import { DATE_CHANGE_TYPES } from 'components/constants';

import { withDateTimePickerTextField } from '../withDateTimePickerTextField/withDateTimePickerTextField';

type TimePickerTextFieldType = 'primary' | 'secondary';

export type TimePickerTextFieldProps = WithTranslation &
    Omit<KeyboardTimePickerProps, 'size' | 'views' | 'openTo' | 'onChange'> & {
        value: Date | null;
        getArrows: (func: (isNext?: boolean) => () => void) => void;
        onChange: (value: Date | null, changeType: DATE_CHANGE_TYPES) => void;
        type: TimePickerTextFieldType;
        className?: string;
        ampmFormat?: boolean;
        isNow?: boolean;
    };
interface TimePickerTextFieldState {}

class TimePickerTextField extends React.PureComponent<TimePickerTextFieldProps, TimePickerTextFieldState> {
    timePickerTextFieldRef = React.createRef();

    onClickArrow = (isNext?: boolean) => () => {
        this.props.onChange(addMinutes(this.props.value || new Date(), isNext ? 15 : -15), DATE_CHANGE_TYPES.time);
    };

    onChangeTime = (value: Date | null) => {
        this.props.onChange(value, DATE_CHANGE_TYPES.time);
    };

    render() {
        const { getArrows, ampmFormat, isNow, t, ...props } = this.props;
        let inputProps = props.inputProps || {};
        const currentDate = new Date();

        if (isNow && (props.value?.getTime() || 0) <= currentDate?.getTime()) {
            inputProps.value = t('expresion_builder.date.now');
        }

        return (
            <>
                <KeyboardTimePicker
                    {...props}
                    onChange={this.onChangeTime}
                    inputProps={inputProps}
                    mask={ampmFormat ? '__:__ _' : '__:__'}
                    format={ampmFormat ? 'hh:mm aaa' : 'HH:mm'}
                    keyboardIcon={<AccessTimeIcon />}
                    showTodayButton={true}
                    todayLabel={t('expresion_builder.date.now')}
                />
                {getArrows(this.onClickArrow)}
            </>
        );
    }
}

export default withTranslation()(withDateTimePickerTextField(TimePickerTextField));
