import { v4 as uuidv4 } from 'uuid';
import { SavedPlacePoint } from 'components/types';
import { Geo, Routing } from 'interfaces';
import { BuildRouteEndPoint } from '../interfaces/geocoder/route';
import { GeoLocationPoint } from '../interfaces/geo';
import { generateLocationStringFromGeoPoint } from '../utils';

class GeoPointTransformer {
    static transformGeoPoint(point: Geo.GeoPoint): Routing.Route.UserLocationPoint {
        return {
            lat: point.lat,
            lng: point.lng,
            address: '',
            addressFields: {},
            entityId: null,
            id: null,
            objectName: null,
            countryShort: null,
            uuid: uuidv4(),
        };
    }

    static transformGeoLocationPoint(point: Geo.GeoLocationPoint): Routing.Route.UserLocationPoint {
        return {
            lat: point.lat,
            lng: point.lng,
            address: point.address,
            addressFields: point.addressFields,
            id: point.recordId,
            entityId: point.entityId,
            objectName: point.objectName || point.address || this.generateObjectName(point),
            countryShort: point.countryShort,
            uuid: uuidv4(),
        };
    }

    static transformSavedPlacePoint(point: SavedPlacePoint): Routing.Route.UserLocationPoint {
        return {
            entityId: null,
            id: null,
            lat: point.latitude,
            lng: point.longitude,
            address: point.address,
            addressFields: point.addressFields,
            objectName: point.name,
            countryShort: null,
            uuid: uuidv4(),
        };
    }

    static transformGeoLocationPointToDesignEntityPoint(
        point: Geo.GeoLocationPoint,
    ): Routing.Route.DesignInputEntityPoint {
        return {
            lat: point.lat,
            lng: point.lng,
            address: point.address,
            addressFields: point.addressFields,
            recordId: point.recordId as string,
            entityId: point.entityId as number,
            objectName: point.objectName || point.address || this.generateObjectName(point),
            countryShort: point.countryShort,
        };
    }

    static transformGeoLocationPointToDesignSimplePoint(point: Geo.GeoLocationPoint): Routing.Route.DesignSimplePoint {
        return {
            lat: point.lat,
            lng: point.lng,
            address: point.address,
            addressFields: point.addressFields,
            objectName: point.objectName || point.address || this.generateObjectName(point),
            countryShort: point.countryShort,
        };
    }

    static transformLocationPointToBuildRouteEndPoint(
        point: Routing.Route.UserLocationPoint | GeoLocationPoint,
    ): BuildRouteEndPoint {
        return {
            lat: point.lat,
            lng: point.lng,
            countryShort: point.countryShort,
            id: 'id' in point ? point.id : null,
            entityId: point.entityId,
            address: point.address,
            addressFields: point.addressFields,
        };
    }

    private static generateObjectName(point: Geo.GeoPoint): string {
        return generateLocationStringFromGeoPoint(point, true);
    }
}

export default GeoPointTransformer;
