import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../locales/i18n';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import Decimal from '../../../../service/workflow_actions/forms/fields/Decimal';

const t = i18n.t.bind(i18n);

class DecimalForm extends AbstractForm {
    renderFieldInputs(field, errors) {
        return (
            <>
                {this.renderIsRequiredInput(field, errors)}
                {this.renderIsHiddenInput(field, errors)}
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isDecimal"
                                checked={field.isDecimal}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.forms.field.decimal.isDecimal')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={t('workflow_actions.forms.field.decimal.minValue')}
                        data-testid="workflow_actions.forms.field.decimal.minValue"
                        fullWidth
                        margin="dense"
                        name="minValue"
                        type="number"
                        value={field.minValue ?? ''}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={t('workflow_actions.forms.field.decimal.maxValue')}
                        data-testid="workflow_actions.forms.field.decimal.maxValue"
                        fullWidth
                        margin="dense"
                        name="maxValue"
                        type="number"
                        value={field.maxValue ?? ''}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
            </>
        );
    }
}

DecimalForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Decimal).isRequired,
};

export default DecimalForm;
