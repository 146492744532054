import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../../locales/i18n';
import AbstractField from '../../../../service/workflow_actions/forms/fields/AbstractField';
import Form from '../../../../service/workflow_actions/forms/Form';
import { Checkbox, FormControlLabel, Grid, Icon, IconButton, TextField, Tooltip } from '@material-ui/core';
import FormulaInput from '../../AbstractForm/FormulaInput';
import { CallContext } from '../../../utils/CallContext';
import { FormulaEditor } from '../../../FormulaEditor';

const t = i18n.t.bind(i18n);

class AbstractFieldForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            field: cloneDeep(props.field),
            openFormulaEditor: false,
        };

        this.submit = this.submit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlerHandlersChange = this.handlerHandlersChange.bind(this);
        this.handleIsHiddenInputChange = this.handleIsHiddenInputChange.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);
        this.toggleFormulaEditor = this.toggleFormulaEditor.bind(this);
        this.getConditionOptions = this.getConditionOptions.bind(this);
    }

    submit() {
        this.setState(
            {
                errors: new Map(),
            },
            () => {
                this.validateApiName();
                this.validate();

                if (this.state.errors.size === 0) {
                    this.props.onSubmitSuccess(this.props.field, this.state.field);
                } else {
                    this.forceUpdate();
                    this.props.onSubmitError();
                }
            },
        );
    }

    validateApiName() {
        if (!this.state.field.getApiName()) {
            this.state.errors.set('apiName', t('workflow_actions.forms.field.validation.required'));
            return false;
        }

        if (
            this.state.field.getApiName() !== this.props.field.getApiName() &&
            this.props.form.hasItem(this.state.field)
        ) {
            this.state.errors.set('apiName', t('workflow_actions.forms.field.validation.apiName.unique'));
            return false;
        }

        return true;
    }

    validate() {
        return true;
    }

    handleInputChange(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;

        this.setState((state) => {
            const field = cloneDeep(state.field);
            field[name] = value;

            let errors = state.errors;

            if (errors.has(name)) {
                errors = new Map(errors);
                errors.delete(name);
            }

            return { field, errors };
        });
    }

    handlerHandlersChange(handlers) {
        this.state.field.setHandlers(handlers);
        this.forceUpdate();
    }

    renderBaseInputs(field, errors) {
        return (
            <>
                <Grid item xs={8}>
                    <FormulaInput
                        autoFocus
                        label={t('workflow_actions.forms.field.label')}
                        fullWidth
                        margin="dense"
                        name="label"
                        value={field.label ?? ''}
                        helperText={errors.get('label') ?? ''}
                        error={errors.has('label')}
                        onChange={this.handleInputChange}
                        callContext={this.props.callContext}
                        multiline
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label={t('workflow_actions.forms.field.apiName')}
                        data-testid="workflow_actions.forms.field.apiName"
                        fullWidth
                        required
                        margin="dense"
                        name="apiName"
                        value={field.apiName ?? ''}
                        helperText={errors.get('apiName') ?? ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormulaInput
                        label={t('workflow_actions.forms.field.description')}
                        fullWidth
                        margin="dense"
                        name="description"
                        value={field.description ?? ''}
                        helperText={errors.get('description') ?? ''}
                        error={errors.has('description')}
                        onChange={this.handleInputChange}
                        callContext={this.props.callContext}
                        multiline
                    />
                </Grid>
            </>
        );
    }

    renderValueInput(field, errors) {
        return (
            <Grid item xs={12}>
                <FormulaInput
                    fullWidth
                    margin="dense"
                    multiline
                    label={t('workflow_actions.forms.field.value')}
                    name="value"
                    value={field.value ?? ''}
                    onChange={this.handleInputChange}
                    error={errors.has('value')}
                    helperText={errors.get('value')}
                    callContext={this.props.callContext}
                />
            </Grid>
        );
    }

    renderFieldInputs() {
        return null;
    }

    renderIsRequiredInput(field, errors) {
        return (
            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="isRequired"
                            checked={field.isRequired}
                            onChange={this.handleInputChange}
                            color="primary"
                        />
                    }
                    label={t('workflow_actions.forms.field.isRequired')}
                />
            </Grid>
        );
    }

    renderIsBarcodeScaner(field, errors) {
        return (
            <Grid item xs={2}>
                <Tooltip title={t('workflow_actions.forms.field.text.barcode.hint')}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_barcode_data"
                                checked={field.is_barcode_data}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.forms.field.text.barcode')}
                    />
                </Tooltip>
            </Grid>
        );
    }

    renderUserCanTypeManually(field, errors) {
        return (
            <Grid item xs={4}>
                <Tooltip title={t('workflow_actions.forms.field.text.allowTypeManual.hint')}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="allowTypeManual"
                                checked={field.allowTypeManual}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.forms.field.text.allowTypeManual')}
                    />
                </Tooltip>
            </Grid>
        );
    }

    renderIsHiddenInput(field, errors) {
        return (
            <React.Fragment>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isHidden"
                                checked={!!field.getCondition() || field.getIsHidden()}
                                indeterminate={!!field.getCondition()}
                                onChange={this.handleIsHiddenInputChange}
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                {t('workflow_actions.forms.field.isHidden')}{' '}
                                <Tooltip title={t('workflow_actions.forms.field.isHidden.condition.hint')}>
                                    <IconButton onClick={this.toggleFormulaEditor} size="small">
                                        <Icon fontSize="small">more_vert</Icon>
                                    </IconButton>
                                </Tooltip>
                            </span>
                        }
                    />
                </Grid>

                <FormulaEditor
                    open={this.state.openFormulaEditor}
                    onSave={this.handleConditionChange}
                    onCancel={this.toggleFormulaEditor}
                    value={field.getCondition()}
                    options={this.getConditionOptions()}
                    modalTitle={t('workflow_actions.forms.field.isHidden.condition_modal.title')}
                    modalDescription={t('workflow_actions.forms.field.isHidden.condition_modal.description')}
                    hideFunctionsButton
                    rawFormula
                />
            </React.Fragment>
        );
    }

    handleIsHiddenInputChange(event) {
        this.state.field.setCondition(null);
        this.handleInputChange(event);
    }

    handleConditionChange(condition) {
        this.state.field.setCondition(condition);
        this.handleInputChange({
            target: { name: 'isHidden', type: 'checkbox', checked: false },
        });
        this.toggleFormulaEditor();
    }

    toggleFormulaEditor() {
        this.setState((state) => ({ openFormulaEditor: !state.openFormulaEditor }));
    }

    getConditionOptions() {
        const options = [];
        for (const field of this.props.form.getFields()) {
            options.push({ value: field.getApiName(), label: field.getLabel() ?? field.getApiName() });
        }
        return options;
    }

    render() {
        const field = this.state.field;
        const errors = this.state.errors;

        return (
            <form noValidate autoComplete="off">
                <Grid container spacing={1} alignItems="flex-end">
                    {this.renderBaseInputs(field, errors)}
                    {this.renderFieldInputs(field, errors)}
                    {this.renderValueInput(field, errors)}
                </Grid>
            </form>
        );
    }
}

AbstractFieldForm.propTypes = {
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(AbstractField).isRequired,
    onSubmitSuccess: PropTypes.func.isRequired,
    onSubmitError: PropTypes.func.isRequired,
};

export default AbstractFieldForm;
