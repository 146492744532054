import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import AbstractList from '../AbstractList/AbstractList';
import DottedLink from '../../DottedLink';
import { getDetail } from '../../../service/WorkflowActionManager';
import { Check } from '@material-ui/icons';
import {
    DETAIL_PLANNER_CREATE_ROUTES,
    DETAIL_PLANNER_END_DATE,
    DETAIL_PLANNER_IN_ENTITY_ID,
    DETAIL_PLANNER_OUT_ENTITY_CREATE,
    DETAIL_PLANNER_START_DATE,
} from '../../../references/wokflow/VisitPlannerAction';
import { VisitPlannerInfo } from './Info';
import moment from 'moment';

class List extends AbstractList {
    initColumns = () => {
        return [
            {
                columnName: 'name',
                type: 'string',
                title: this.props.t('workflow_actions.list.visit_planner.name'),
                getCellValue: (action) => (
                    <React.Fragment>
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, action)}
                            data-testid="workflow_actions.list.visit_planner.name"
                        >
                            {action.name}
                        </DottedLink>
                    </React.Fragment>
                ),
            },
            {
                columnName: 'startDate',
                title: this.props.t('workflow_actions.list.visit_planner.start_date'),
                getCellValue: (action) => {
                    const startDate = moment(getDetail(DETAIL_PLANNER_START_DATE, action.details, []));
                    return startDate.format('YYYY-MM-DD');
                },
            },
            {
                columnName: 'endDate',
                title: this.props.t('workflow_actions.list.visit_planner.end_date'),
                getCellValue: (action) => {
                    const endDate = moment(getDetail(DETAIL_PLANNER_END_DATE, action.details, []));
                    return endDate.format('YYYY-MM-DD');
                },
            },
            {
                columnName: 'inEntity',
                title: this.props.t('workflow_actions.list.visit_planner.in_entity'),
                picklist: this.getEntityPicklist(),
                getCellValue: (action) => {
                    let entityLabel = '';
                    for (const dataSource of this.props.dataSources) {
                        for (const entity of dataSource.entities) {
                            if (entity.id === getDetail(DETAIL_PLANNER_IN_ENTITY_ID, action.details, null)) {
                                entityLabel = dataSource.name + ' > ' + entity.label;
                                break;
                            }
                        }
                    }
                    return entityLabel;
                },
            },
            {
                columnName: 'outCreate',
                title: this.props.t('workflow_actions.list.visit_planner.out_create'),
                getCellValue: (action) => {
                    const outCreate = getDetail(DETAIL_PLANNER_OUT_ENTITY_CREATE, action.details, false);
                    return outCreate ? <Check /> : '';
                },
            },
            {
                columnName: 'routesCreate',
                title: this.props.t('workflow_actions.list.visit_planner.routes_create'),
                getCellValue: (action) => {
                    const routesCreate = getDetail(DETAIL_PLANNER_CREATE_ROUTES, action.details, false);
                    return routesCreate ? <Check /> : '';
                },
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (action) => (
                    <Tooltip title={this.props.t('remove')}>
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={this.props.onActionRemove.bind(this, action)}
                            data-testid="workflow_actions.list.visit_planner.remove"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];
    };

    initSortingColumnExtensions = () => {
        return [
            { columnName: 'inEntity', sortingEnabled: false },
            { columnName: 'startDate', sortingEnabled: false },
            { columnName: 'endDate', sortingEnabled: false },
            { columnName: 'outCreate', sortingEnabled: false },
            { columnName: 'routesCreate', sortingEnabled: false },
        ];
    };

    initFilteringColumnExtensions() {
        return [
            { columnName: 'inEntity', filteringEnabled: false },
            { columnName: 'startDate', filteringEnabled: false },
            { columnName: 'endDate', filteringEnabled: false },
            { columnName: 'outCreate', filteringEnabled: false },
            { columnName: 'routesCreate', filteringEnabled: false },
        ];
    }

    render() {
        return (
            <React.Fragment>
                <VisitPlannerInfo accountId={this.props.accountId} />
                {super.render()}
            </React.Fragment>
        );
    }
}

const DecoratedList = withTranslation()(List);
DecoratedList.propTypes = {
    accountId: PropTypes.number.isRequired,
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onActionEdit: PropTypes.func.isRequired,
    onActionRemove: PropTypes.func.isRequired,
};

export { DecoratedList as VisitPlannerList };
