import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { getDetail, TYPE_OPEN_FORM, workflowActionManager } from '../../../service/WorkflowActionManager';
import './../style.css';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import AbstractForm from '../AbstractForm/AbstractForm';
import ChangesList from './ChangesList';
import { withSnackbar } from 'notistack';
import cloneDeep from 'lodash/cloneDeep';
import { CHANGE_CUSTOM_API_NAME } from './Action';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    constructor(props) {
        super(props);

        this.state.forms = [];
        this.state.loading = true;
    }

    componentDidMount() {
        this.loadForms();
    }

    loadForms() {
        workflowActionManager
            .list(this.props.account.id, TYPE_OPEN_FORM, [], [{ columnName: 'createdAt', direction: 'desc' }], 0, null)
            .then((data) => {
                this.setState(
                    {
                        loading: false,
                        forms: data.items,
                    },
                    () => this.processCustomFields(),
                );
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    processCustomFields() {
        const details = this.state.currentAction.details;
        const form = this.state.forms.find((form) => form.id === getDetail('form', details));
        const formFields = form ? form.details.fields : [];

        const changes = cloneDeep(getDetail('changes', details));

        for (let change of changes) {
            if (!change.field_api_name || change.field_api_name === CHANGE_CUSTOM_API_NAME) {
                continue;
            }

            if (!formFields.find((field) => field.apiName === change.field_api_name)) {
                change.field_api_name_formula = change.field_api_name;
                change.field_api_name = CHANGE_CUSTOM_API_NAME;
            }
        }

        this.updateDetails('changes', changes);
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    handleFormChange = (e) => {
        this.handleDetailsChange(e, () => this.processCustomFields());
    };

    render() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        return (
            <form noValidate autoComplete="off">
                <TextField
                    autoFocus
                    label={t('workflow_actions.form.update_form.name')}
                    data-testid="workflow_actions.form.update_form.name"
                    fullWidth
                    required
                    margin="dense"
                    name="name"
                    value={currentAction.name ?? ''}
                    helperText={errors.get('name') ?? ''}
                    error={errors.has('name')}
                    InputProps={{ disableUnderline: false }}
                    onChange={this.handleInputChange}
                />
                <TextField
                    label={t('workflow_actions.form.update_form.api_name')}
                    data-testid="workflow_actions.form.update_form.api_name"
                    fullWidth
                    margin="dense"
                    name="apiName"
                    value={currentAction.apiName ?? ''}
                    helperText={errors.get('apiName') ?? ''}
                    error={errors.has('apiName')}
                    InputProps={{ disableUnderline: false }}
                    onChange={this.handleInputChange}
                />

                <FormControlSelect
                    fullWidth
                    margin="dense"
                    label={t('workflow_actions.form.update_form.form')}
                    name="form"
                    value={getDetail('form', details) ?? ''}
                    onChange={this.handleFormChange}
                    error={errors.has('form')}
                    helperText={errors.get('form')}
                    data-testid="workflow_actions.form.update_form.form"
                >
                    {this.state.forms.map((form) => {
                        return (
                            <MenuItem key={form.id} value={form.id}>
                                {form.name}
                            </MenuItem>
                        );
                    })}
                </FormControlSelect>

                <ChangesList
                    name="changes"
                    items={getDetail('changes', details)}
                    onChange={this.handleDetailsChange}
                    error={errors.has('changes')}
                    form={this.state.forms.find((form) => form.id === getDetail('form', details))}
                    callContext={this.state.currentAction.callContext}
                />
            </form>
        );
    }
}

const SnackbarredForm = withSnackbar(Form);

export { SnackbarredForm as UpdateFormForm };
