import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/icons/List';
import { addressParts } from '../../references/addressParts';
import PropTypes from 'prop-types';
import './style.css';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationIconButton from '../CustomButton/ConfirmationIconButton';
import { withTranslation } from 'react-i18next';
import Confirmation from '../Confirmation';
import { LOOKUP_DATA_FIELD } from '../../handlers/EntityHandler';
import FieldMessageIcon from '../ImportMessage/FieldMessageIcon';

export const fieldProtectionReasons = {
    id: 1,
    address: 2,
    expression: 3,
    pin: 4,
    partOfRouteName: 5,
    recordPage: 6,
    isSystem: 7,
    coordinates: 8,
    owner: 9,
    appointment: 10,
};

const reasons = {
    [fieldProtectionReasons.id]: 'data_source.ds_entity_field.protection_reason.id',
    [fieldProtectionReasons.owner]: 'data_source.ds_entity_field.protection_reason.owner',
    [fieldProtectionReasons.address]: 'data_source.ds_entity_field.protection_reason.address',
    [fieldProtectionReasons.expression]: 'data_source.ds_entity_field.protection_reason.expression',
    [fieldProtectionReasons.pin]: 'data_source.ds_entity_field.protection_reason.pin',
    [fieldProtectionReasons.partOfRouteName]: 'data_source.ds_entity_field.protection_reason.part_of_route_name',
    [fieldProtectionReasons.recordPage]: 'data_source.ds_entity_field.protection_reason.record_page',
    [fieldProtectionReasons.isSystem]: 'data_source.entity.field.protection.is_system',
    [fieldProtectionReasons.coordinates]: 'data_source.entity.field.protection.coordinates',
    [fieldProtectionReasons.appointment]: 'data_source.ds_entity.locked_tooltip.field_used_by_calendar',
};

class DsEntityField extends React.Component {
    constructor(props) {
        super(props);

        const { field, inseparable } = this.props;

        this.isIncluded = field.isIncluded;
        this.isPin = field.isPin;
        this.address = field.address;
        this.inseparable = inseparable;
        this.needToConfirm = this.constructor.needToConfirm(field);
    }

    static needToConfirm(field) {
        return (
            !field.isIncluded &&
            field.lookupData &&
            field.lookupData[LOOKUP_DATA_FIELD] &&
            (!field.lookupData[LOOKUP_DATA_FIELD].isIncluded ||
                (field.lookupData[LOOKUP_DATA_FIELD].linkedLookupEntity &&
                    !field.lookupData[LOOKUP_DATA_FIELD].linkedLookupEntity.isIncluded))
        );
    }

    shouldComponentUpdate(nextProps) {
        let needToConfirm = this.constructor.needToConfirm(nextProps.field);

        return (
            nextProps.field.isIncluded !== this.isIncluded ||
            nextProps.field.isPin !== this.isPin ||
            needToConfirm !== this.constructor.needToConfirm(this.props.field) ||
            nextProps.field.address !== this.address ||
            nextProps.inseparable !== this.inseparable ||
            nextProps.disabled !== this.props.disabled
        );
    }

    updateField(updateCallback) {
        const { onFieldChanged, field } = this.props;
        const renewedField = { ...field };
        updateCallback(renewedField);
        onFieldChanged(field, renewedField);
    }

    updateFieldCallback(isIncluded) {
        return (field) => {
            field.isIncluded = isIncluded;
            if (!field.isIncluded) {
                field.isPin = false;
            }
        };
    }

    handleIsIncludedChange = (event) => {
        this.updateField(this.updateFieldCallback(event.target.checked));
    };

    handleProceedConfirmation(isIncluded) {
        this.updateField(this.updateFieldCallback(isIncluded));
    }

    handleDelete = () => {
        const { field, onFieldChanged } = this.props;
        field.isCustom && onFieldChanged && onFieldChanged(field, null);
    };

    getTypeLabel() {
        const { t, field } = this.props;
        if (field.picklist) {
            if (field['type'] === 'json_array') {
                return t('data_source.ds_entity_field.type_label.multiple_picklist');
            } else {
                return t('data_source.ds_entity_field.type_label.picklist');
            }
        }
        if (field.lookup) {
            return t('data_source.ds_entity_field.type_label.lookup', { entity: field['lookup']['entity'] });
        }
        // TODO дубликат поля lookup?
        if (field.lookupData !== null) {
            return t('data_source.ds_entity_field.type_label.lookup_title');
        }

        if (field.isAddress || field.address) {
            return t('data_source.ds_entity_field.type_label.address');
        }
        if (field.isLink) {
            return t('data_source.ds_entity_field.type_label.weblink');
        }
        return field['type'];
    }

    getProtectionReason() {
        const reasonMessage = [];
        const { inseparable, t, fieldCalendars } = this.props;

        inseparable.forEach((id) => {
            const message = reasons[id];

            if (message) {
                if (id === fieldProtectionReasons.appointment) {
                    reasonMessage.push(
                        t(message, { calendarNames: fieldCalendars.map((calendar) => calendar.name).join(', ') }),
                    );
                } else {
                    reasonMessage.push(t(message));
                }
            }
        });

        return reasonMessage;
    }

    render() {
        const { t, field, dataSource, subscription } = this.props;

        this.isIncluded = field.isIncluded;
        this.isPin = field.isPin;
        this.address = field.address;
        this.inseparable = this.props.inseparable;

        const protectionReason = this.getProtectionReason();
        const needToConfirm = this.constructor.needToConfirm(field);

        let confirmationObjectsName = '';
        if (needToConfirm) {
            let confirmationObjects = [];
            confirmationObjects.push(field.lookupData[LOOKUP_DATA_FIELD].label);
            if (field.lookupData[LOOKUP_DATA_FIELD].linkedLookupEntity) {
                confirmationObjects.push(field.lookupData[LOOKUP_DATA_FIELD].linkedLookupEntity.label);
            }
            confirmationObjectsName = confirmationObjects.join(', ');
        }

        let tooltipTitle = '';

        if (!this.props.disabled) {
            if (protectionReason.length > 1) {
                tooltipTitle = (
                    <ul className="tooltip-list-wrapper">
                        {protectionReason.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                );
            } else {
                tooltipTitle = protectionReason?.[0] || '';
            }
        }

        return (
            <TableRow className="c-data-source-field">
                <TableCell style={{ paddingLeft: 0, overflow: 'auto' }}>
                    <div className="name-cell">
                        <div className="name">
                            {this.props.inseparable.length && field.isIncluded ? (
                                <Tooltip title={tooltipTitle}>
                                    <span>
                                        <Checkbox color="primary" checked disabled />
                                    </span>
                                </Tooltip>
                            ) : needToConfirm ? (
                                <Confirmation
                                    text={this.props.t('data_source.entity.field.confirmation.text', {
                                        name: confirmationObjectsName,
                                    })}
                                    onConfirm={() => {
                                        this.handleProceedConfirmation(!field.isIncluded);
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={field.isIncluded}
                                        disabled={this.props.disabled}
                                    />
                                </Confirmation>
                            ) : (
                                <Checkbox
                                    color="primary"
                                    checked={field.isIncluded}
                                    onChange={this.handleIsIncludedChange}
                                    disabled={this.props.disabled}
                                />
                            )}
                            <div className="field-label-container">
                                <span>{field.label}</span>&nbsp;
                                <FieldMessageIcon dataSource={dataSource} field={field} subscription={subscription} />
                            </div>
                        </div>

                        {field.address && (
                            <div className="actions">
                                <Tooltip title={t('data_source.ds_entity_field.edit_mapping.tooltip')}>
                                    <IconButton
                                        color="primary"
                                        onClick={this.props.onAddressFormRequest}
                                        component="span"
                                        data-testid="data_source.ds_entity_field.edit_mapping"
                                    >
                                        <List />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        field.isCustom
                                            ? t('data_source.ds_entity_field.delete.tooltip.custom')
                                            : t('data_source.ds_entity_field.delete.tooltip')
                                    }
                                >
                                    <ConfirmationIconButton
                                        iconName="delete"
                                        color="primary"
                                        component="span"
                                        onClick={this.handleDelete}
                                        disabled={!field.isCustom}
                                        text={t('data_source.ds_entity_field.delete.confirmation')}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    {field.address && (
                        <div className="mapping">
                            {Object.keys(field.address).map((sectionName) => {
                                if (
                                    field.address[sectionName] === null ||
                                    !this.props.fieldsLabels.has(field.address[sectionName])
                                ) {
                                    return null;
                                }
                                const apiName = field.address[sectionName];
                                return (
                                    <div key={sectionName}>
                                        {this.props.fieldsLabels.get(apiName)} &#8594; {addressParts[sectionName]}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </TableCell>
                <TableCell style={{ overflow: 'auto' }}>
                    <span>{field.apiName}</span>
                </TableCell>
                <TableCell>
                    <div className={field.address ? 'address-type' : ''}>{this.getTypeLabel()}</div>
                </TableCell>
            </TableRow>
        );
    }
}

DsEntityField.propTypes = {
    field: PropTypes.object.isRequired,
    inseparable: PropTypes.arrayOf(PropTypes.number),
    onFieldChanged: PropTypes.func.isRequired,
    onAddressFormRequest: PropTypes.func,
    disabled: PropTypes.bool,
    fieldsLabels: PropTypes.object,
    dataSource: PropTypes.object.isRequired,
    subscription: PropTypes.object,
    fieldCalendars: PropTypes.arrayOf(PropTypes.object),
};

DsEntityField.defaultProps = {
    inseparable: [],
    fieldCalendars: [],
};

export default withTranslation()(DsEntityField);
