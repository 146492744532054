import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import {
    executionFlowManager,
    isActionEvent,
    isButtonEvent,
    isRuleEvent,
    isScheduleEvent,
    TriggerEvent,
} from '../../service/ExecutionFlowManager';
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import {
    Button,
    FormControlLabel,
    Grid,
    Link,
    Menu,
    MenuItem,
    Switch,
    TableCell,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import { DataTable, PicklistFilterCell } from '../DataTable';
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { calendar, formatDateToDefault, nowIsBefore, userTimezoneToUtc, utcToUserTimezone } from '../../utils';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import { withTranslation } from 'react-i18next';
import ExecutionLogModal from './ExecutionLogModal';
import { userManager } from '../../service/UserManager';
import moment from 'moment';
import { accountsManager } from '../../service/AccountsManager';
import { withSnackbar } from 'notistack';
import { DATA_EXCHANGE_LOG_TAB, PARAM_FILTERS, PARAM_OPEN_SESSION, PARAM_TAB } from './index';
import { workflowActionManager } from '../../service/WorkflowActionManager';
import ActionModals from '../WorkflowActions/ActionModals';
import dsManagerFactory from '../../service/DsManager';
import WorkflowRuleModal from '../WorkflowRules/WorkflowRuleModal';
import WorkflowButtonModal from '../WorkflowButtons/WorkflowButtonModal';
import { roleManager } from '../../service/RoleManager';
import ScheduleModal from '../Workflow/Schedule/ScheduleModal';
import { scheduleManager } from '../../service/ScheduleManager';
import { workflowRuleManager } from '../../service/WorkflowRuleManager';
import { workflowButtonManager } from '../../service/WorkflowButtonManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import qs from 'qs';
import { reverse, routes } from '../../routes';

const SHOW_SEARCH_BAR_FILTER = 'execution_log.show_search_bar_filter';

const TriggerField = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        padding: '0 4px',
    },
})(Button);

const FilterCell = (props) => {
    const { column, filteringEnabled, t, tReady, i18n, ...rest } = props;
    if (column.name === 'startedAt') {
        return <DateBetweenFilter {...rest} />;
    }
    if (column.name === 'triggerEvent' || column.name === 'status') {
        return <PicklistFilterCell {...props} />;
    }
    return filteringEnabled ? (
        <TableFilterRow.Cell {...rest} />
    ) : (
        <TableCell className={props.classes && props.classes.cell} />
    );
};

class ExecutionLog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.pageSizes = [50, 100, 200];

        const workflowLoggingUntil =
            userManager.getCurrentAccount() && userManager.getCurrentAccount().workflowLoggingWorksUntil;
        const showSearchBarFilter = Boolean(localStorage.getItem(SHOW_SEARCH_BAR_FILTER));

        this.state = {
            loading: true,
            rows: [],
            pagination: {
                current: 0,
                size: 50,
            },
            totalCount: 0,
            account: userManager.getCurrentAccount(),
            openSessionModal: false,
            currentSession: null,
            anchorEl: null,
            workflowLogging: !!(workflowLoggingUntil && nowIsBefore(workflowLoggingUntil, props.user)),
            workflowLoggingUntil,
            showSearchBarFilter,
            dataSources: [],
            roles: [],
            currentRule: null,
            currentButton: null,
            currentSchedule: null,
            currentAction: null,
        };

        this.filters = [
            {
                columnName: 'startedAt',
                value: {
                    dateFrom: null,
                    dateTo: null,
                },
            },
            {
                columnName: 'user',
                value: null,
            },
        ];

        this.sorting = [
            {
                columnName: 'startedAt',
                direction: 'desc',
            },
        ];

        this.columnExtensions = [
            {
                columnName: 'startedAt',
                width: 200,
            },
            {
                columnName: 'executionTime',
                width: 130,
            },
            {
                columnName: 'status',
                align: 'center',
                width: 150,
            },
            {
                columnName: 'translatedErrorMessage',
                wordWrapEnabled: true,
            },
        ];

        this.pagingPanelMessages = DataTable.getPagingMessages(props.t);
        this.filterMessages = DataTable.getFilterMessages(props.t);

        this.handleChangeFilters = debounce(this.handleChangeFilters.bind(this), 500);
    }

    componentDidMount() {
        this.loadData();
        this.loadDataSources();
        this.loadProfiles();
        if (null === this.state.account) {
            this.loadAccount();
        }

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.status === 'complete') {
                this.loadDataSources();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    getColumns = () => {
        return [
            {
                name: 'startedAt',
                title: this.props.t('execution_log.columns.started_at'),
                getCellValue: (row) => <span>{utcToUserTimezone(row.startedAt, this.props.user)}</span>,
            },
            {
                name: 'trigger',
                title: this.props.t('execution_log.columns.trigger'),
                getCellValue: (row) => {
                    if (!row.automationElementId || !row.automationElementName) {
                        return null;
                    }
                    switch (row.triggerEvent) {
                        case TriggerEvent.Button:
                        case TriggerEvent.ButtonApi:
                            return this.renderTriggerField(
                                row.automationElementName,
                                row.automationElementDeleted || false,
                                'fas fa-hand-back-point-up',
                                (e) => this.openButtonModal(row.automationElementId, e),
                            );
                        case TriggerEvent.Schedule:
                            return this.renderTriggerField(
                                row.automationElementName,
                                row.automationElementDeleted || false,
                                'fas fa-clock',
                                (e) => this.openScheduleModal(row.automationElementId, e),
                            );
                        case TriggerEvent.FormEvent:
                            const fieldLabel = row.triggerEventData?.fieldLabel;
                            const eventType = row.triggerEventData?.eventType;
                            const name =
                                fieldLabel && eventType
                                    ? `${row.automationElementName.trim()}: ${fieldLabel} ${eventType}`
                                    : row.automationElementName;
                            return this.renderTriggerField(
                                name,
                                row.automationElementDeleted || false,
                                'fas fa-align-left',
                                (e) => this.openActionModal(row.automationElementId, e),
                            );
                        case TriggerEvent.SearchBarFilter:
                            return this.renderTriggerField(
                                this.props.t('execution_log.columns.trigger.search_bar', {
                                    name: row.automationElementName,
                                }),
                                row.automationElementDeleted || false,
                                'fas fa-filter',
                                (e) => this.openActionModal(row.automationElementId, e),
                            );
                        default:
                            return this.renderTriggerField(
                                row.automationElementName,
                                row.automationElementDeleted || false,
                                'fas fa-bolt',
                                (e) => this.openRuleModal(row.automationElementId, e),
                            );
                    }
                },
                disableSorting: true,
            },
            {
                name: 'triggerEvent',
                title: this.props.t('execution_log.columns.trigger_event'),
                getCellValue: (row) => row.triggerEvent,
                picklist: [
                    { value: TriggerEvent.Button, label: this.props.t('execution_log.columns.trigger_event.button') },
                    {
                        value: TriggerEvent.ButtonApi,
                        label: this.props.t('execution_log.columns.trigger_event.button_api'),
                    },
                    {
                        value: TriggerEvent.FormEvent,
                        label: this.props.t('execution_log.columns.trigger_event.form_event'),
                    },
                    this.state.showSearchBarFilter && {
                        value: TriggerEvent.SearchBarFilter,
                        label: this.props.t('execution_log.columns.trigger_event.search_bar_filter'),
                    },
                    {
                        value: TriggerEvent.Schedule,
                        label: this.props.t('execution_log.columns.trigger_event.scheduled_process'),
                    },
                    {
                        value: TriggerEvent.LiveUpdate,
                        label: this.props.t('execution_log.columns.trigger_event.live_update'),
                    },
                    { value: TriggerEvent.Import, label: this.props.t('execution_log.columns.trigger_event.import') },
                    {
                        value: TriggerEvent.Geocoding,
                        label: this.props.t('execution_log.columns.trigger_event.geocoding'),
                    },
                    {
                        value: TriggerEvent.Prospecting,
                        label: this.props.t('execution_log.columns.trigger_event.prospecting'),
                    },
                    {
                        value: TriggerEvent.TerritoryAssignment,
                        label: this.props.t('execution_log.columns.trigger_event.territory_assignment'),
                    },
                    {
                        value: TriggerEvent.ExternalApiCall,
                        label: this.props.t('execution_log.columns.trigger_event.external_api_call'),
                    },
                    {
                        value: TriggerEvent.SystemDataSourceSynchronization,
                        label: this.props.t('execution_log.columns.trigger_event.system_data_source_synchronization'),
                    },
                    { value: TriggerEvent.System, label: this.props.t('execution_log.columns.trigger_event.system') },
                    {
                        value: TriggerEvent.CreateRecord,
                        label: this.props.t('execution_log.columns.trigger_event.create_record'),
                    },
                    {
                        value: TriggerEvent.ExportProspects,
                        label: this.props.t('execution_log.columns.trigger_event.export_prospects'),
                    },
                ].filter((value) => value),
                disableSorting: true,
            },
            {
                name: 'user',
                title: this.props.t('execution_log.columns.triggered_by'),
                getCellValue: (row) => <span>{row.userName}</span>,
            },
            {
                name: 'executionTime',
                title: this.props.t('execution_log.columns.execution_time'),
                getCellValue: (row) => row.executionTime,
            },
            {
                name: 'status',
                title: this.props.t('execution_log.columns.status'),
                getCellValue: (row) => {
                    if (row.status === 0 && row.dataExchangeSession) {
                        return (
                            <span className="log-badge log-badge-warning">
                                {this.props.t('execution_log.status.success')}
                            </span>
                        );
                    }
                    switch (row.status) {
                        case 0:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('execution_log.status.success')}
                                </span>
                            );
                        case 1:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('execution_log.status.fail')}
                                </span>
                            );
                        default:
                            return (
                                <span className="log-badge log-badge-warning">
                                    {this.props.t('execution_log.status.processing')}
                                </span>
                            );
                    }
                },
                picklist: [
                    { value: 0, label: this.props.t('execution_log.status.success') },
                    { value: 1, label: this.props.t('execution_log.status.fail') },
                ],
                disableSorting: true,
            },
            {
                name: 'translatedErrorMessage',
                title: this.props.t('execution_log.columns.status_message'),
                getCellValue: this.renderStatusMessage,
            },
        ];
    };

    renderStatusMessage = (session) => {
        if (session.status === 0 && session.dataExchangeSession) {
            const params = qs.stringify({
                [PARAM_TAB]: DATA_EXCHANGE_LOG_TAB,
                [PARAM_FILTERS]: [{ columnName: 'id', operation: 'equal', value: session.dataExchangeSession.id }],
            });
            const accountId = userManager.isRoleSuperAdmin() ? this.props.accountId : undefined;
            const href = reverse(routes.admin.account.log, { accountId }) + '?' + params;
            return (
                <div>
                    <span>{this.props.t('execution_log.columns.status_message.data_exchange_session.error')}: </span>
                    <Link target="_blank" href={href} onClick={(event) => event.stopPropagation()}>
                        {session.dataExchangeSession.statusMessage?.length > 200
                            ? session.dataExchangeSession.statusMessage?.substring(0, 200) + '...'
                            : session.dataExchangeSession.statusMessage}
                    </Link>
                </div>
            );
        }

        return (
            <Tooltip title={session.errorMessage || ''} placement="bottom-start">
                <div>{session.errorMessage}</div>
            </Tooltip>
        );
    };

    loadAccount = () => {
        accountsManager
            .load(this.props.accountId)
            .then((account) => {
                const workflowLoggingUntil = account.workflowLoggingWorksUntil;
                this.setState({
                    workflowLogging: !!(workflowLoggingUntil && nowIsBefore(workflowLoggingUntil, this.props.user)),
                    workflowLoggingUntil,
                    account,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    loadDataSources = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();

        this.setState({ dataSources: [] });

        manager
            .list()
            .then((data) => {
                data = data.map((source) => {
                    source.entities = source.entityCounters.map((entityCounter) => entityCounter.entity);
                    return source;
                });
                this.setState({ dataSources: data });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    loadProfiles = () => {
        roleManager.getAccountRoles(this.props.accountId).then((roles) => {
            this.setState({ roles: roles });
        });
    };

    handleChangeFilters = (filters) => {
        this.filters = filters;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            () => this.loadData(),
        );
    };

    handleChangeSorting = (sorting) => {
        this.sorting = sorting;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            () => this.loadData(),
        );
    };

    formatFilters(filters) {
        const defaultFilters = this.getSearchParams(PARAM_FILTERS);
        let formatFilters = defaultFilters || {};
        filters.forEach((filter) => {
            if (filter.columnName === 'startedAt' && (filter.value.dateFrom || filter.value.dateTo)) {
                formatFilters['startedAt'] = [];
                formatFilters['startedAt']['from'] = filter.value.dateFrom
                    ? userTimezoneToUtc(filter.value.dateFrom, this.props.user)
                    : null;
                formatFilters['startedAt']['to'] = filter.value.dateTo
                    ? userTimezoneToUtc(filter.value.dateTo, this.props.user)
                    : null;
            } else if (filter.columnName === 'triggerEvent' && !this.state.showSearchBarFilter) {
                formatFilters[filter.columnName] = filter.value.filter(
                    (value) => value !== TriggerEvent.SearchBarFilter,
                );
            } else {
                formatFilters[filter.columnName] = filter.value;
            }
        });
        return formatFilters;
    }

    formatSorting(sorting) {
        let formatSorting = [];
        sorting.forEach((sort) => {
            formatSorting['column'] = sort.columnName;
            formatSorting['direction'] = sort.direction;
        });
        return formatSorting;
    }

    loadData() {
        this.setState({
            loading: true,
            rows: [],
        });

        executionFlowManager
            .getSessions(
                this.props.accountId,
                this.state.showSearchBarFilter,
                this.formatFilters(this.filters),
                this.formatSorting(this.sorting),
                this.state.pagination.current + 1,
                this.state.pagination.size,
            )
            .then((data) => {
                let currentSession = null;
                if (this.getSearchParams(PARAM_OPEN_SESSION) && data.total === 1) {
                    currentSession = data.items[0];
                }
                this.setState({
                    loading: false,
                    rows: data.items,
                    totalCount: data.total,
                    openSessionModal: !!currentSession,
                    currentSession,
                });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    renderTriggerField = (name, isDeleted, icon, onClick) => {
        return (
            <Tooltip
                title={isDeleted ? this.props.t('automation_elements.deleted') : null}
                disableHoverListener={!isDeleted}
                disableFocusListener={!isDeleted}
                disableTouchListener={!isDeleted}
            >
                <div style={{ display: 'inherit' }}>
                    <TriggerField onClick={onClick} disabled={isDeleted} size="small" color="primary">
                        <i className={icon} style={{ marginRight: 10 }} />
                        {name}
                    </TriggerField>
                </div>
            </Tooltip>
        );
    };

    getSearchParams = (key) => {
        if (this.props.searchParams) {
            return this.props.searchParams[key] || null;
        }
        return null;
    };

    openSessionModal = (session) => {
        this.setState({ openSessionModal: true, currentSession: session });
    };

    closeSessionModal = () => {
        this.setState({ openSessionModal: false, currentSession: null });
    };

    toggleWorkflowLogging = (event) => {
        if (true === event.target.checked) {
            this.openWorkflowLoggingMenu(event);
        } else {
            this.handleWorkflowLogging(null);
        }
    };

    toggleShowSearchBarFilter = () => {
        this.setState(
            (state) => ({ showSearchBarFilter: !state.showSearchBarFilter }),
            () => {
                localStorage.setItem(SHOW_SEARCH_BAR_FILTER, this.state.showSearchBarFilter);
                this.loadData();
            },
        );
    };

    openWorkflowLoggingMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeWorkflowLoggingMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleWorkflowLogging = (hours) => {
        const workflowLoggingUntil = hours ? moment.utc().add(hours, 'hours') : null;
        this.setState({
            workflowLogging: !!(workflowLoggingUntil && nowIsBefore(workflowLoggingUntil, this.props.user)),
            workflowLoggingUntil,
        });
        accountsManager.updateWorkflowLogging(
            this.props.accountId,
            workflowLoggingUntil && formatDateToDefault(moment.utc(workflowLoggingUntil)),
        );
        this.closeWorkflowLoggingMenu();
    };

    renderWorkflowLoggingLabel = () => {
        if (this.state.workflowLoggingUntil && nowIsBefore(this.state.workflowLoggingUntil, this.props.user)) {
            const activeUntil = calendar(this.state.workflowLoggingUntil, this.props.user, null, {
                sameDay: '[today] hh:mm a',
                nextDay: '[tomorrow] hh:mm a',
            });
            return this.props.t('execution_log.detailed_logging_active', { activeUntil });
        }
        return this.props.t('execution_log.detailed_logging_inactive');
    };

    handleCurrentPageChanged = (currentPage) => {
        this.setState(
            (state) => {
                state.records = null;
                state.pagination.current = currentPage;
                return state;
            },
            () => this.loadData(false),
        );
    };

    handlePageSizeChanged = (pageSize) => {
        this.setState(
            (state) => {
                state.records = null;
                state.pagination.size = pageSize;
                state.pagination.current = 0;
                return state;
            },
            () => this.loadData(),
        );
    };

    openRuleModal = (ruleId, event) => {
        event.stopPropagation();
        workflowRuleManager.get(this.props.accountId, ruleId).then((rule) => {
            this.setState({ currentRule: rule });
        });
    };

    closeRuleModal = (rule = null) => {
        this.setState({ currentRule: null });
        if (rule) {
            this.setState((state) => {
                const rows = [...state.rows].map((row) => {
                    if (isRuleEvent(row.triggerEvent) && row.automationElementId === rule.id) {
                        row.automationElementName = rule.name;
                    }
                    return row;
                });
                return { rows };
            });
            this.props.enqueueSnackbar(this.props.t('workflow_rules.notification.saved'), { variant: 'success' });
        }
    };

    openButtonModal = (buttonId, event) => {
        event.stopPropagation();
        workflowButtonManager.get(this.props.accountId, buttonId).then((button) => {
            this.setState({ currentButton: button });
        });
    };

    closeButtonModal = (button = null) => {
        this.setState({ currentButton: null });
        if (button) {
            this.setState((state) => {
                const rows = [...state.rows].map((row) => {
                    if (isButtonEvent(row.triggerEvent) && row.automationElementId === button.id) {
                        row.automationElementName = button.name;
                    }
                    return row;
                });
                return { rows };
            });
            this.props.enqueueSnackbar(this.props.t('workflow_buttons.notification.saved'), { variant: 'success' });
        }
    };

    saveButton = (button) => {
        button = cloneDeep(button);
        button.roles = button.roles.map((role) => (Number.isInteger(role) ? role : role.id));
        button.entities = button.entities.map((entity) => (Number.isInteger(entity) ? entity : entity.id));
        button.order = +button.order !== 0 ? button.order : 0;
        return workflowButtonManager.save(this.props.accountId, button).then((button) => {
            this.closeButtonModal(button);
        });
    };

    openScheduleModal = (scheduleId, event) => {
        event.stopPropagation();
        scheduleManager.get(this.props.accountId, scheduleId).then((schedule) => {
            this.setState({ currentSchedule: schedule });
        });
    };

    closeScheduleModal = (schedule = null) => {
        this.setState({ currentSchedule: null });
        if (schedule) {
            this.setState((state) => {
                const rows = [...state.rows].map((row) => {
                    if (isScheduleEvent(row.triggerEvent) && row.automationElementId === schedule.id) {
                        row.automationElementName = schedule.name;
                    }
                    return row;
                });
                return { rows };
            });
            this.props.enqueueSnackbar(this.props.t('automation.schedule.notification.saved'), { variant: 'success' });
        }
    };

    openActionModal = (actionId, event) => {
        event.stopPropagation();
        workflowActionManager.get(this.props.accountId, actionId).then((action) => {
            this.setState({ currentAction: action });
        });
    };

    closeActionModal = (action = null) => {
        this.setState({ currentAction: null });
        if (action) {
            this.setState((state) => {
                const rows = [...state.rows].map((row) => {
                    if (isActionEvent(row.triggerEvent) && row.automationElementId === action.id) {
                        row.automationElementName = action.name;
                    }
                    return row;
                });
                return { rows };
            });
            this.props.enqueueSnackbar(this.props.t('workflow_actions.notification.saved'), { variant: 'success' });
        }
    };

    render() {
        const columns = this.getColumns();

        return (
            <div className="log">
                <Grid container spacing={1} justify="space-between">
                    {this.state.account && (
                        <Grid item container spacing={5} alignItems="center" xs>
                            <Grid item>
                                <b>{this.props.t('execution_log.title')}</b>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.workflowLogging}
                                            onChange={this.toggleWorkflowLogging}
                                            color="primary"
                                            data-testid="execution_log.toggle_workflow_logging"
                                        />
                                    }
                                    label={this.renderWorkflowLoggingLabel()}
                                />
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={this.state.anchorEl !== null}
                                    onClose={this.closeWorkflowLoggingMenu}
                                    disableAutoFocusItem
                                >
                                    <MenuItem
                                        onClick={this.handleWorkflowLogging.bind(this, 3)}
                                        data-testid="execution_log.detailed_logging.hours"
                                    >
                                        {this.props.t('execution_log.detailed_logging.hours', { hours: 3 })}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handleWorkflowLogging.bind(this, 6)}
                                        data-testid="execution_log.detailed_logging.hours"
                                    >
                                        {this.props.t('execution_log.detailed_logging.hours', { hours: 6 })}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handleWorkflowLogging.bind(this, 12)}
                                        data-testid="execution_log.detailed_logging.hours"
                                    >
                                        {this.props.t('execution_log.detailed_logging.hours', { hours: 12 })}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handleWorkflowLogging.bind(this, 24)}
                                        data-testid="execution_log.detailed_logging.hours"
                                    >
                                        {this.props.t('execution_log.detailed_logging.hours', { hours: 24 })}
                                    </MenuItem>
                                </Menu>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.showSearchBarFilter}
                                            onChange={this.toggleShowSearchBarFilter}
                                            color="primary"
                                            data-testid="execution_log.show_search_bar_filter"
                                        />
                                    }
                                    label={this.props.t('execution_log.show_search_bar_filter')}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <DataGrid rows={[]} columns={columns}>
                            <PagingState
                                currentPage={this.state.pagination.current}
                                onCurrentPageChange={this.handleCurrentPageChanged}
                                onPageSizeChange={this.handlePageSizeChanged}
                                pageSize={this.state.pagination.size}
                            />
                            <CustomPaging totalCount={this.state.totalCount} />
                            <PagingPanel
                                messages={this.pagingPanelMessages}
                                pageSizes={this.pageSizes}
                                containerComponent={PagingContainer}
                            />
                        </DataGrid>
                    </Grid>
                </Grid>
                <DataGrid rows={this.state.rows} columns={columns}>
                    <FilteringState
                        defaultFilters={this.filters}
                        onFiltersChange={this.handleChangeFilters}
                        columnExtensions={[{ columnName: 'executionTime', filteringEnabled: false }]}
                    />
                    <SortingState
                        defaultSorting={this.sorting}
                        onSortingChange={this.handleChangeSorting}
                        columnExtensions={[
                            { columnName: 'user', sortingEnabled: false },
                            { columnName: 'trigger', sortingEnabled: false },
                        ]}
                    />
                    <Table
                        columnExtensions={this.columnExtensions}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={columns.length} loading={this.state.loading} />
                        )}
                        rowComponent={({ children, row, ...rest }) => (
                            <StyledRow onClick={() => this.openSessionModal(row)} {...rest}>
                                {children}
                            </StyledRow>
                        )}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        showSortingControls
                    />
                    <TableFilterRow cellComponent={StyledFilterCell} messages={this.filterMessages} />
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </DataGrid>

                {this.state.openSessionModal && (
                    <ExecutionLogModal
                        accountId={this.props.accountId}
                        user={this.props.user}
                        session={this.state.currentSession}
                        open={true}
                        onClose={this.closeSessionModal}
                    />
                )}

                {this.state.account && this.state.currentRule && (
                    <WorkflowRuleModal
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        rule={this.state.currentRule}
                        onSave={this.closeRuleModal}
                        onCancel={this.closeRuleModal}
                    />
                )}
                {this.state.account && this.state.currentButton && (
                    <WorkflowButtonModal
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        roles={this.state.roles}
                        button={this.state.currentButton}
                        onSaved={this.handleClose}
                        onSave={this.saveButton}
                        onCancel={this.closeButtonModal}
                    />
                )}
                {this.state.account && this.state.currentSchedule && (
                    <ScheduleModal
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        schedule={this.state.currentSchedule}
                        onSaved={this.closeScheduleModal}
                        onCancel={this.closeScheduleModal}
                    />
                )}
                {this.state.account && this.state.currentAction && (
                    <ActionModals
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        action={this.state.currentAction}
                        onSave={this.closeActionModal}
                        onCancel={this.closeActionModal}
                    />
                )}
            </div>
        );
    }
}

ExecutionLog.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
    searchParams: PropTypes.object,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
        verticalAlign: 'top',
    },
});

const stylesForCustomFilter = (theme) => ({
    cell: {
        width: '100%',
        padding: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
});

const StyledFilterCell = withTranslation()(withStyles(stylesForCustomFilter)(FilterCell));

const PagingContainer = (props) => <PagingPanel.Container {...props} className="table-pagination" />;

const Row = ({ children, classes, ...rest }) => (
    <Table.Row {...rest} className={classes.row || null}>
        {children}
    </Table.Row>
);

const rowStyles = {
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
};
const StyledRow = withStyles(rowStyles)(Row);

export default withTranslation()(withSnackbar(ExecutionLog));
