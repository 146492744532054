import { Api, Routing } from 'interfaces';
import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { RouteDenormalizer } from 'service/Serializer';

class RouteDesignService extends BackendService {
    async addPoints(
        addedRoutePoints: Routing.Route.DesignAddPoint[],
    ): Promise<Api.Routing.Session.DesignModeAddPointsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.addPoints);
        const request: Api.Routing.Session.DesignModeAddPointsApiRequest = addedRoutePoints;
        const response: Api.Routing.Session.DesignModeAddPointsApiResponse = await this.requestApi(
            url,
            'POST',
            request,
        );
        return RouteDenormalizer.denormalizeInputPoints(response.points);
    }

    async updatePoint(
        updatedRoutePoint: Routing.Route.DesignRoutePoint,
    ): Promise<Api.Routing.Session.DesignModeAddPointsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.updatePoint);
        const response: Api.Routing.Session.DesignModeAddPointsApiResponse = await this.requestApi(
            url,
            'POST',
            updatedRoutePoint,
        );
        return RouteDenormalizer.denormalizeInputPoints(response.points);
    }

    async deletePoints(
        deletedPoints: Routing.Route.DesignDeletePoint[],
    ): Promise<Api.Routing.Session.DesignModeDeletePointsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.deletePoints);
        const request: Api.Routing.Session.DesignModeDeletePointsApiRequest = deletedPoints;
        const response: Api.Routing.Session.DesignModeDeletePointsApiResponse = await this.requestApi(
            url,
            'POST',
            request,
        );
        return RouteDenormalizer.denormalizeInputPoints(response.points);
    }

    async updatePoints(
        updatedPoints: Routing.Route.DesignUpdatePoint[],
    ): Promise<Api.Routing.Session.DesignModeUpdatePointsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.updatePoints);
        const request: Api.Routing.Session.DesignModeUpdatePointsApiRequest = updatedPoints;
        const response: Api.Routing.Session.DesignModeUpdatePointsApiResponse = await this.requestApi(
            url,
            'POST',
            request,
        );
        return RouteDenormalizer.denormalizeInputPoints(response.points);
    }

    async movePoints(oldIndex: number, newIndex: number): Promise<Api.Routing.Session.DesignModeMovePointsResponse> {
        const url = reverse(apiRoutes.routes.routeSession.designMode.movePoints);
        const request: Api.Routing.Session.DesignModeMovePointsApiRequest = { oldIndex, newIndex };
        const response: Api.Routing.Session.DesignModeMovePointsApiResponse = await this.requestApi(
            url,
            'POST',
            request,
        );
        return RouteDenormalizer.denormalizeInputPoints(response.points);
    }
}

export default RouteDesignService;
