import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../locales/i18n';
import ImportMessageComparator from '../../service/ImportMessages/Comparator';
import LightObjectGenerator from '../../service/ImportMessages/LightObjectGenerator';
import { userManager } from '../UserManager';
import SubscriptionMessagesGenerator from './SubscriptionMessagesGenerator';
import GeocodingMessagesGenerator from 'service/ImportMessages/GeocodingMessagesGenerator';
import {
    DataSource,
    ImportMessage,
    LEVEL,
    LightEntity,
    LightEntityField,
    ORIGIN,
    SortedAccountMessages,
    SortedDsMessages,
    SortedEntityMessages,
    SortedFieldMessages,
} from './types';
import { Account } from 'interfaces';

const t = i18n.t.bind(i18n);

class Calculator {
    public static calculateAccountStatusMessages(
        subscription: null | Account.Subscription = null,
    ): SortedAccountMessages {
        const subscriptionMessages = SubscriptionMessagesGenerator.getMessages(subscription);
        return {
            messages: subscriptionMessages,
        };
    }

    public static calculateSortedMessages(
        sources: DataSource[],
        includeLevels: LEVEL[],
        subscription: null | Account.Subscription = null,
    ): SortedDsMessages[] {
        const isSuperAdmin = userManager.isRoleSuperAdmin();
        const error403message = t('data_source.indicator.failed');
        const result: SortedDsMessages[] = [];
        for (let source of sources) {
            let rawMessages = cloneDeep(source.importMessages || []).filter(
                (message) => message.userId === null || message.userId === (userManager.getCurrentUser().id || null),
            );
            rawMessages = rawMessages.concat(GeocodingMessagesGenerator.getMessages(source, subscription));

            for (let message of rawMessages) {
                if (message.httpCode === 403) {
                    message.message = error403message + (isSuperAdmin ? ': ' + message.message : '');
                }
            }
            if (source.isDeactivated) {
                rawMessages.push({
                    id: uuidv4(),
                    ds: LightObjectGenerator.getLightDataSource(source),
                    entity: null,
                    field: null,
                    origin: ORIGIN.INTERNAL,
                    httpCode: 200,
                    level: LEVEL.WARNING,
                    message: t('data_source.indicator.deactivated'),
                    userId: null,
                });
            }

            const messages = Calculator.getDataSourceMessages(rawMessages, includeLevels);
            const entities = Calculator.calculateEntityMessages(rawMessages, includeLevels);
            if (messages.length === 0 && entities.length === 0) {
                continue;
            }
            result.push({
                ds: LightObjectGenerator.getLightDataSource(source),
                messages: messages,
                entities: entities,
            });
        }
        return result;
    }

    private static calculateEntityMessages(
        rawMessages: ImportMessage[],
        includeLevels: LEVEL[],
    ): SortedEntityMessages[] {
        const result: SortedEntityMessages[] = [];
        const messages = rawMessages.filter((m) => m.entity !== null);
        for (let entity of Calculator.getUniqueEntities(messages)) {
            const entityMessages = Calculator.getEntityMessages(messages, entity, includeLevels);
            const fields = Calculator.calculateEntityFieldMessages(messages, entity, includeLevels);
            if (entityMessages.length === 0 && fields.length === 0) {
                continue;
            }
            result.push({
                entity: entity,
                messages: entityMessages,
                fields: fields,
            });
        }
        return result;
    }

    private static calculateEntityFieldMessages(
        messages: ImportMessage[],
        entity: LightEntity,
        includeLevels: LEVEL[],
    ): SortedFieldMessages[] {
        const result: SortedFieldMessages[] = [];
        const fieldsMessages = messages.filter((m) => m.entity!.id === entity.id && m.field !== null);
        for (let field of Calculator.getUniqueFields(fieldsMessages)) {
            const fieldMessages = Calculator.getFieldMessages(fieldsMessages, field, includeLevels);
            if (fieldMessages.length === 0) {
                continue;
            }
            result.push({
                field: field,
                messages: fieldMessages,
            });
        }
        return result;
    }

    private static getUniqueFields(messages: ImportMessage[]): LightEntityField[] {
        const fields = messages.map((m) => m!.field);
        const uniqueFields = new Map<number, LightEntityField>();
        for (let field of fields) {
            if (field === null) {
                continue;
            }
            uniqueFields.set(field!.id, field);
        }
        return [...uniqueFields.values()];
    }

    private static getUniqueEntities(messages: ImportMessage[]): LightEntity[] {
        const entities = messages.map((m) => m!.entity);
        const uniqueEntities = new Map<number, LightEntity>();
        for (let entity of entities) {
            if (entity === null) {
                continue;
            }
            uniqueEntities.set(entity!.id, entity);
        }
        return [...uniqueEntities.values()];
    }

    private static getFieldMessages(
        messages: ImportMessage[],
        field: LightEntityField,
        includeLevels: LEVEL[],
    ): ImportMessage[] {
        const allMessages = messages
            .filter((m) => m.field!.id === field.id && includeLevels.includes(m.level))
            .sort(ImportMessageComparator.compare);
        return uniqBy(allMessages, 'message');
    }

    private static getEntityMessages(
        messages: ImportMessage[],
        entity: LightEntity,
        includeLevels: LEVEL[],
    ): ImportMessage[] {
        const allMessages = messages
            .filter((m) => m.entity!.id === entity.id && m.field === null && includeLevels.includes(m.level))
            .sort(ImportMessageComparator.compare);
        return uniqBy(allMessages, 'message');
    }

    private static getDataSourceMessages(rawMessages: ImportMessage[], includeLevels: LEVEL[]): ImportMessage[] {
        const allMessages = rawMessages
            .filter((m) => m.entity === null && m.field === null && includeLevels.includes(m.level))
            .sort(ImportMessageComparator.compare);
        return uniqBy(allMessages, 'message');
    }
}

export default Calculator;
