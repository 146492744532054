import { withTranslation, WithTranslation } from 'react-i18next';
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { RoutingCreditsUsageDaily } from '../../../interfaces/account';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface RoutingCreditsUsageChartProps extends WithTranslation {
    dailyUsages: RoutingCreditsUsageDaily[];
    onClose: () => void;
}

interface RoutingCreditsUsageChartState {}

class RoutingCreditsUsageChart extends React.Component<RoutingCreditsUsageChartProps, RoutingCreditsUsageChartState> {
    render() {
        const { dailyUsages, t } = this.props;
        const data = {
            datasets: [
                {
                    data: dailyUsages,
                    backgroundColor: '#3f51b5',
                },
            ],
        };
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {},
            },
            parsing: {
                xAxisKey: 'date',
                yAxisKey: 'counter',
            },
        };

        return (
            <Dialog open={true} onClose={this.props.onClose}>
                <DialogTitle>
                    <span style={{ paddingRight: 20 }}>
                        {' '}
                        {t('billing.subscription_panel.routing_credits_usage.daily_usage')}{' '}
                    </span>
                    <IconButton color="default" onClick={this.props.onClose} className="c-form-dialog__close">
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ width: '100%', minHeight: 100 }}>
                    {dailyUsages.length ? (
                        <Paper>
                            <Bar options={options} data={data} width={500} height={300} />
                        </Paper>
                    ) : (
                        <span>{t('billing.subscription_panel.routing_credits_usage.no_statistic', { days: 90 })}</span>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(RoutingCreditsUsageChart);
