import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { weAreInNativeApp } from '../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { App as AppPlugin } from '@capacitor/app';

interface CloseAppModalState {
    showModal: boolean;
}

class CloseAppModal extends React.PureComponent<WithTranslation, CloseAppModalState> {
    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    componentDidMount = () => {
        if (weAreInNativeApp()) {
            AppPlugin.addListener('backButton', this.handleNativeBackBtnPress);
        }
    };

    handleNativeBackBtnPress = () => {
        if (this.state.showModal) {
            this.closeApp();
        }

        this.setState({ showModal: true });
    };

    componentWillUnmount() {
        if (weAreInNativeApp()) {
            AppPlugin.removeAllListeners();
        }
    }

    handleAdios = () => {
        this.closeApp();
    };

    closeApp = () => {
        AppPlugin.exitApp();
    };

    handleCancel = () => {
        this.setState({
            showModal: false,
        });
    };

    render() {
        const { t } = this.props;
        const { showModal } = this.state;

        if (!weAreInNativeApp()) {
            return null;
        }

        return (
            <Dialog open={showModal} style={{ zIndex: 9010 }}>
                <DialogTitle>{t('close_app_modal.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('close_app_modal.text')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary" data-testid="close_app_modal.btn_no">
                        {t('close_app_modal.btn_no')}
                    </Button>
                    <Button onClick={this.handleAdios} color="secondary" data-testid="close_app_modal.btn_yes">
                        {t('close_app_modal.btn_yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation('translations')(CloseAppModal);
