import React from 'react';
import { Button, CircularProgress, Fab, Grid, Icon, Link, Tooltip, Typography, withStyles } from '@material-ui/core';
import {
    fileManager,
    getFileExtension,
    getFileNameWithNoExtension,
    isHeic,
    MAX_FILE_SIZE,
} from '../../service/File/FileManager';
import { ConversionStatus, FileType, MapslyFile, ProgressCallback } from 'interfaces/file';
import SmallFab from '../CustomButton/SmallFab';
import CircularLabeledProgress from './CircularLabeledProgress';
import FileNameModal from './FileNameModal';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Replay } from '@material-ui/icons';
import './style.css';
import clsx from 'clsx';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EImageType } from 'image-conversion';
import { fileCompressor } from './FileCompressor';
import { uploadManager } from '../../service/File/UploadManager';
import { thumbnailService } from '../../service/File/ThumbnailService';
import dispatcher from '../../service/dispatcher';
import events from '../../events';

export const FileButton = withStyles({
    root: {
        width: '100%',
        textTransform: 'none',
    },
    label: {
        display: 'block',
        maxWidth: '150px',
        '.small &': {
            maxWidth: '75px',
        },
    },
})(Button) as typeof Button;
export const FileIcon = withStyles({
    root: {
        fontSize: '7em',
        textAlign: 'center',
        '&.small': {
            fontSize: '3em',
        },
    },
})(Icon);

interface FileProps extends WithTranslation, WithSnackbarProps {
    accountId: number;
    index: number;
    file: MapslyFile;
    onClick: (file: MapslyFile) => void;
    onDownload: (file: MapslyFile, progressCallback?: ProgressCallback) => Promise<unknown>;
    onDelete: (index: number) => void;
    onUploadStart?: (file: MapslyFile, index: number) => void;
    onUploadComplete?: (file: MapslyFile, index: number) => void;
    onUploadError?: (file: MapslyFile, index: number) => void;
    onUpdateComplete?: (file: MapslyFile, index: number) => void;
    size: 'small' | 'medium';
    readonly: boolean;
    instantDelete: boolean;
    disableDownload: boolean;
}

interface FileState {
    objectUrl: string | null;
    uploading: boolean;
    uploadingProgress: number | null;
    downloading: boolean;
    downloadingProgress: number | null;
    error: boolean;
    fileNameModal: boolean;
}

class FileView extends React.Component<FileProps, FileState> {
    constructor(props: FileProps) {
        super(props);

        this.state = {
            objectUrl: null,
            uploading: Boolean(!props.file.url && props.file.blob),
            uploadingProgress: null,
            downloading: false,
            downloadingProgress: null,
            error: false,
            fileNameModal: false,
        };
    }

    async componentDidMount() {
        if (!this.props.file.url && this.props.file.blob) {
            this.props.onUploadStart && this.props.onUploadStart(this.props.file, this.props.index);

            const heic2any = (await import('heic2any')).default;

            new Promise<MapslyFile>((resolve) => {
                if (isHeic(this.props.file)) {
                    return heic2any({ blob: this.props.file.blob as Blob, toType: 'image/jpeg' }).then((blob) => {
                        const name = getFileNameWithNoExtension(this.props.file.name) + '.jpg';
                        blob = new Blob(Array.isArray(blob) ? blob : [blob], { type: 'image/jpeg' });
                        resolve({ ...this.props.file, name, blob, size: blob.size, contentType: 'image/jpeg' });
                    });
                } else {
                    resolve({ ...this.props.file });
                }
            })
                .then((file) => this.processImage(file))
                .then((file) => this.createThumbnail(file))
                .then((file) => this.upload(file))
                .catch((error: Error | string) => {
                    this.props.enqueueSnackbar(typeof error === 'object' ? error.message : error, { variant: 'error' });
                    this.setState({ uploading: false, uploadingProgress: null, error: true });
                    this.props.onUploadError && this.props.onUploadError(this.props.file, this.props.index);
                    throw error;
                });
        } else if (
            this.props.file.conversionStatus === ConversionStatus.InProgress ||
            this.props.file.conversionStatus === ConversionStatus.New
        ) {
            dispatcher.subscribe(events.WS_FILE_CONVERSION, this, (status: any) => {
                if (status.fileId === this.props.file.id) {
                    this.props.file.conversionStatus = status.status as ConversionStatus;
                    this.props.file.url = status.url;
                    this.setState({ uploading: false, uploadingProgress: null });
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<FileProps>) {
        if (prevProps.file !== this.props.file) {
            this.revokeObjectUrl();
            if (this.props.file.thumbnailBlob) {
                this.setState({ objectUrl: URL.createObjectURL(this.props.file.thumbnailBlob) });
            }
        }
    }

    componentWillUnmount() {
        this.revokeObjectUrl();
    }

    revokeObjectUrl = () => {
        const objectUrl = this.state.objectUrl;
        if (objectUrl) {
            this.setState({ objectUrl: null }, () => {
                URL.revokeObjectURL(objectUrl);
            });
        }
    };

    processImage = (mapslyFile: MapslyFile) => {
        if (mapslyFile.blob && mapslyFile.type === FileType.Image) {
            return fileCompressor
                .compress(mapslyFile.blob, {
                    width: mapslyFile.width ?? undefined,
                    height: mapslyFile.height ?? undefined,
                })
                .then((blob) => {
                    const name = getFileNameWithNoExtension(mapslyFile.name) + '.jpg';
                    return { ...mapslyFile, name, blob, size: blob.size, contentType: EImageType.JPEG };
                });
        }

        return Promise.resolve(mapslyFile);
    };

    createThumbnail = (mapslyFile: MapslyFile) => {
        if (mapslyFile.blob && mapslyFile.type === FileType.Image) {
            return thumbnailService.createImageThumbnail(mapslyFile.blob).then((blob) => {
                this.setState({ objectUrl: URL.createObjectURL(blob) });
                mapslyFile.thumbnailBlob = blob;
                mapslyFile.thumbnailSize = blob.size;
                return mapslyFile;
            });
        } else if (mapslyFile.blob && mapslyFile.type === FileType.Video) {
            return thumbnailService.createVideoThumbnail(mapslyFile.blob).then((blob) => {
                if (blob) {
                    this.setState({ objectUrl: URL.createObjectURL(blob) });
                    mapslyFile.thumbnailBlob = blob;
                    mapslyFile.thumbnailSize = blob.size;
                }
                return mapslyFile;
            });
        }

        return Promise.resolve(mapslyFile);
    };

    upload = (file: MapslyFile) => {
        return new Promise<void>((resolve, reject) => {
            if ([FileType.Image, FileType.Document].includes(file.type) && file.size > MAX_FILE_SIZE) {
                reject(
                    new Error(this.props.t('file_input.error.max_file_size', { size: MAX_FILE_SIZE / 1000 / 1000 })),
                );
            } else {
                resolve();
            }
        })
            .then(() => {
                return uploadManager.upload(this.props.accountId, file, this.onUploadProgress);
            })
            .then((file) => {
                this.setState({ uploading: false, uploadingProgress: null });
                this.props.onUploadComplete && file?.type && this.props.onUploadComplete?.(file, this.props.index);
            });
    };

    retry = (event: React.MouseEvent) => {
        event.stopPropagation();
        const file = { ...this.props.file };
        this.props.onUploadStart && this.props.onUploadStart(file, this.props.index);
        this.setState({ uploading: true, error: false }, () =>
            this.upload(file).catch((error: Error | string) => {
                this.props.enqueueSnackbar(typeof error === 'object' ? error.message : error, { variant: 'error' });
                this.setState({ uploading: false, uploadingProgress: null, error: true });
                this.props.onUploadError && this.props.onUploadError(this.props.file, this.props.index);
                throw error;
            }),
        );
    };

    onUploadProgress = (event?: ProgressEvent) => {
        const uploadingProgress = event && event.total ? Math.round((event.loaded / event.total) * 100) : null;
        this.setState({ uploadingProgress });
    };

    onDownloadProgress = (event?: ProgressEvent) => {
        const downloadingProgress = event && event.total ? Math.round((event.loaded / event.total) * 100) : null;
        this.setState({ downloadingProgress });
    };

    handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (this.state.error || this.state.uploading || this.props.disableDownload) {
            return;
        }
        if (
            [FileType.Video, FileType.Audio].includes(this.props.file.type) &&
            this.props.file.conversionStatus !== ConversionStatus.Completed
        ) {
            return;
        }

        if (this.props.file.type === FileType.Document) {
            this.setState({ downloading: true });
            this.props.onDownload(this.props.file, this.onDownloadProgress).finally(() => {
                this.setState({ downloading: false, downloadingProgress: null });
            });
        } else {
            this.props.onClick(this.props.file);
        }
    };

    renderIcon = (file: MapslyFile) => {
        const classNames: string[] = [this.props.size];
        if (this.state.error || this.state.uploading || this.props.disableDownload) {
            classNames.push('inactive');
        }
        switch (file.type) {
            case FileType.Image:
                const url = this.state.objectUrl || file.thumbnailUrl || file.url;
                return url ? <img className={clsx(classNames)} src={url} alt={file.name} /> : null;
            case FileType.Video:
                if (this.state.objectUrl || file.thumbnailUrl) {
                    return (
                        <img
                            className={clsx(classNames)}
                            src={this.state.objectUrl || file.thumbnailUrl}
                            alt={file.name}
                        />
                    );
                }
                classNames.push('fas fa-file-video');
                return <FileIcon className={clsx(classNames)} color="action" />;
            case FileType.Audio:
                classNames.push('fas fa-file-audio');
                return <FileIcon className={clsx(classNames)} color="action" />;
            default:
                classNames.push(this.getFileIcon(getFileExtension(file.name)));
                return <FileIcon className={clsx(classNames)} color="action" />;
        }
    };

    getFileIcon = (extension: string) => {
        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'doc':
                return 'fas fa-file-word';
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
                return 'fas fa-file-excel';
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'csv':
                return 'fas fa-file-csv';
            default:
                return 'fas fa-file';
        }
    };

    renderRetryButton = () => {
        if (this.state.error && !this.state.uploading) {
            return (
                <Fab
                    className="file-badge"
                    aria-label="retry"
                    data-testid={`file_input.file_view.${this.props.index}.retry`}
                    onClick={this.retry}
                >
                    <Replay />
                </Fab>
            );
        }
        return null;
    };

    renderLoader = () => {
        if (this.state.uploading) {
            return (
                <div className="file-badge">
                    {this.state.uploadingProgress === null || this.state.uploadingProgress === 0 ? (
                        <CircularProgress color="inherit" />
                    ) : (
                        <CircularLabeledProgress color="inherit" value={this.state.uploadingProgress} />
                    )}
                </div>
            );
        } else if (this.state.downloading) {
            return (
                <div className="file-badge">
                    {this.state.downloadingProgress === null ? (
                        <CircularProgress color="inherit" />
                    ) : (
                        <CircularLabeledProgress color="inherit" value={this.state.downloadingProgress} />
                    )}
                </div>
            );
        }
    };

    renderAudioVideoStatus = () => {
        switch (this.props.file.conversionStatus) {
            case ConversionStatus.Error:
                return (
                    <div className="backdrop">
                        <div className="file-badge">
                            <Tooltip title={this.props.file.error || ''}>
                                <Icon className="fas fa-triangle-exclamation" />
                            </Tooltip>
                        </div>
                    </div>
                );
            case ConversionStatus.New:
            case ConversionStatus.InProgress:
                return (
                    <div className="backdrop">
                        <div className="file-badge">
                            <Tooltip title={this.props.t('file_input.status.conversion_in_progress')}>
                                <CircularProgress color="inherit" />
                            </Tooltip>
                        </div>
                    </div>
                );
        }
    };

    openFileNameModal = (event: React.MouseEvent) => {
        if (this.props.readonly || this.state.error || this.state.uploading || this.state.downloading) {
            return this.handleClick(event);
        }
        event.stopPropagation();
        this.setState({ fileNameModal: true });
    };

    closeFileNameModal = () => {
        this.setState({ fileNameModal: false });
    };

    handleFileNameChange = (name: string) => {
        if (this.state.error || this.state.uploading || this.state.downloading) {
            return;
        }

        this.setState({ uploading: true });
        fileManager.update(this.props.accountId, { ...this.props.file, name }).then((file) => {
            this.setState({ uploading: false });
            this.props.onUpdateComplete && this.props.onUpdateComplete(file, this.props.index);
            this.closeFileNameModal();
        });
    };

    handleDelete = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (this.state.uploading && this.state.uploadingProgress === null) {
            return;
        } else if (this.state.error || this.state.uploading || !this.props.instantDelete) {
            uploadManager.uploadAbort(this.props.file).then(() => {
                this.props.onDelete(this.props.index);
            });
        } else {
            this.setState({ uploading: true });
            uploadManager
                .uploadAbort(this.props.file)
                .then(() => {
                    return fileManager.delete(this.props.accountId, this.props.file);
                })
                .then(() => {
                    this.props.onDelete(this.props.index);
                    this.setState({ uploading: false });
                })
                .catch((error: Error) => {
                    this.props.enqueueSnackbar(error.message, { variant: 'error' });
                    this.setState({ uploading: false, error: true });
                });
        }
    };

    abortDownloading = (event: React.MouseEvent) => {
        event.stopPropagation();
        fileManager.downloadAbort();
    };

    render() {
        const { file, size, readonly, index } = this.props;
        const { fileNameModal, downloading, uploading, uploadingProgress } = this.state;

        return (
            <Grid item style={{ position: 'relative', marginTop: 20 }}>
                <FileButton
                    onClick={this.handleClick}
                    component="div"
                    size={size}
                    className={size}
                    data-testid={'file_input.file_view.' + index}
                >
                    <div className="file-container">
                        {this.renderIcon(file)}
                        {this.renderRetryButton()}
                        {this.renderLoader()}
                        {this.renderAudioVideoStatus()}
                    </div>
                    <Tooltip title={file.name}>
                        <Link
                            variant="body2"
                            color={this.state.error ? 'error' : 'textSecondary'}
                            style={{ display: 'block', padding: '5px', width: '100%' }}
                            onClick={this.openFileNameModal}
                            data-testid={`file_input.file_view.${index}.name`}
                        >
                            <Typography align="center" noWrap style={{ margin: 0 }}>
                                {file.name}
                            </Typography>
                        </Link>
                    </Tooltip>
                </FileButton>

                {(!readonly || downloading) && (
                    <SmallFab
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        aria-label="delete"
                        color={downloading ? 'default' : 'secondary'}
                        size="small"
                        data-testid={`file_input.file_view.${index}.delete`}
                        onClick={downloading ? this.abortDownloading : this.handleDelete}
                        disabled={uploading && uploadingProgress === null}
                    >
                        <Icon style={{ fontSize: '1rem' }}>close_icon</Icon>
                    </SmallFab>
                )}

                <FileNameModal
                    open={fileNameModal}
                    value={file.name}
                    onSave={this.handleFileNameChange}
                    onCancel={this.closeFileNameModal}
                />
            </Grid>
        );
    }
}

export default withTranslation()(withSnackbar(FileView));
