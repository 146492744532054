import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class EntityViewConfigManager extends BackendService {
    constructor() {
        super();
        this.defaultTableViews = new Map();
        this.defaultMapViews = new Map();
    }

    loadDefaultConfig(entityId) {
        const url = reverse(apiRoutes.entity.defaults, {
            entityId: entityId,
        });
        return this.requestApi(url, 'GET').then((views) => {
            this.defaultTableViews.set(entityId, views['table']);
            this.defaultMapViews.set(entityId, views['map']);
            return views;
        });
    }

    loadDefaultTableConfig(entityId) {
        if (this.defaultTableViews.has(entityId)) {
            return Promise.resolve(this.defaultTableViews.get(entityId));
        }
        return this.loadDefaultConfig(entityId).then((views) => views['table']);
    }

    loadDefaultMapConfig(entityId) {
        if (this.defaultMapViews.has(entityId)) {
            return Promise.resolve(this.defaultMapViews.get(entityId));
        }
        return this.loadDefaultConfig(entityId).then((views) => views['map']);
    }
}

export default new EntityViewConfigManager();
