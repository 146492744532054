import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';

class NoMoreUsersError extends React.PureComponent {
    render() {
        return (
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                    <img src="/logo.png" alt="Mapsly" style={{ width: 98 }} />
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    {this.props.t('error_notification.no_more_users_error.text')}
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation()(NoMoreUsersError);
