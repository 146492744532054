import React from 'react';
import { FormControl, Grid, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import ChangeItem from './ChangeItem';
import i18n from '../../../locales/i18n';
import { CHANGE_TYPES, DEFAULT_CHANGE } from './Action';
import cloneDeep from 'lodash/cloneDeep';
import arrayMove from 'array-move';
import { CallContext } from '../../utils/CallContext';
import AddIconButton from '../../CustomButton/AddIconButton';

const t = i18n.t.bind(i18n);

const SortableList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((item, index) => {
                return (
                    <ChangeItem
                        index={index}
                        itemIndex={index}
                        key={item.type + '_' + item.field_api_name_type + '_' + item.field_api_name + '_' + index}
                        change={item}
                        form={props.form}
                        callContext={props.callContext}
                        onChange={props.onChangeItem}
                        onRemove={props.onRemoveItem}
                    />
                );
            })}
        </div>
    );
});

class ChangesList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anchorElement: null,
        };
    }

    handleOpenMenu = (event) => {
        this.setState({ anchorElement: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorElement: null });
    };

    handleChangeItem = (index, item) => {
        const items = cloneDeep(this.props.items);
        items[index] = item;

        this.handleChange(items);
    };

    handleAddItem = (type) => {
        const items = cloneDeep(this.props.items);

        const item = cloneDeep(DEFAULT_CHANGE);
        item.type = type;

        items.unshift(item);

        this.setState({ anchorElement: null }, () => {
            this.handleChange(items);
        });
    };

    handleRemoveItem = (index) => {
        const items = cloneDeep(this.props.items);
        items.splice(index, 1);

        this.handleChange(items);
    };

    handleSortEnd = (e) => {
        const items = cloneDeep(this.props.items);
        this.handleChange(arrayMove(items, e.oldIndex, e.newIndex));
    };

    handleChange = (items) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: items,
            },
        });
    };

    render() {
        return (
            <FormControl fullWidth>
                <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                    <Grid item>
                        <b>{t('workflow_actions.form.update_form.changes')}</b>
                    </Grid>
                    <Grid item>
                        <AddIconButton small onClick={this.handleOpenMenu} />
                        <Menu
                            anchorEl={this.state.anchorElement}
                            open={this.state.anchorElement !== null}
                            onClose={this.handleCloseMenu}
                            disableAutoFocusItem
                        >
                            {Object.values(CHANGE_TYPES).map((type) => {
                                return (
                                    <MenuItem
                                        key={type}
                                        onClick={this.handleAddItem.bind(this, type)}
                                        data-testid={'workflow_actions.form.update_form.changes.type.' + type}
                                    >
                                        {t('workflow_actions.form.update_form.changes.type.' + type)}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1} style={{ marginBottom: 20 }}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Grid container alignItems="flex-end" spacing={1}>
                            <Grid item xs={4}>
                                {t('workflow_actions.form.update_form.changes.type')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('workflow_actions.form.update_form.changes.field_api_name')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('workflow_actions.form.update_form.changes.value')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <SortableList
                    useDragHandle
                    helperClass="sortable-item"
                    items={this.props.items}
                    callContext={this.props.callContext}
                    onSortEnd={this.handleSortEnd}
                    onChangeItem={this.handleChangeItem}
                    onRemoveItem={this.handleRemoveItem}
                    errors={this.props.errors}
                    form={this.props.form}
                />
            </FormControl>
        );
    }
}

ChangesList.defaultProps = {
    errors: new Map(),
    form: null,
};

ChangesList.propTypes = {
    form: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    errors: PropTypes.instanceOf(Map),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
};

export default ChangesList;
