import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Preloader from '../Preloader';
import { weAreInNativeApp } from '../../utils';

class OauthFinalStep extends React.Component {
    componentDidMount() {
        let attributes = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        if (!window.opener) {
            if (attributes.hasOwnProperty('sso_status')) {
                // Локация меняется. При установленном refresh-токене вновь загруженная страница получит access-token.
                if (attributes['sso_status'] === 'error') {
                    window.location.href =
                        '/?error=' + attributes['error'] + '&' + qs.stringify(attributes['error_data']);
                } else if (attributes['sso_status'] === 'signup') {
                    if (weAreInNativeApp()) {
                        window.location.href = '/login?error=' + this.props.t('oauth.error.user_not_found');
                        return;
                    }

                    window.location.href =
                        '/signup/' +
                        attributes['adapter'] +
                        '/' +
                        attributes['signup_stage'] +
                        '?' +
                        qs.stringify(attributes['signup_data']);
                }
                return;
            }
            document.body.innerText = this.props.t('oauth.error.page_is_out_of_date');
        }

        var sessionId = window.name;
        if (
            window.opener &&
            typeof window.opener.receivePopupResponse !== 'undefined' &&
            !window.opener.receivePopupResponse(sessionId, attributes)
        ) {
            document.body.innerText = this.props.t('oauth.error.sso_response_is_out_of_date');
        }
    }

    render() {
        return <Preloader />;
    }
}

export default withTranslation()(withRouter(OauthFinalStep));
