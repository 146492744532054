import React from 'react';
import ReactDOM from 'react-dom';
import { WithTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TextFieldProps } from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePickerProps, KeyboardTimePickerProps } from '@material-ui/pickers';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { TextField, IconButton } from 'components/UI';
import { DATE_CHANGE_TYPES } from 'components/constants';

import { DatePickerTextFieldProps } from '../DatePickerTextField/DatePickerTextField';
import { TimePickerTextFieldProps } from '../TimePickerTextField/TimePickerTextField';

import './styles.css';

type TimePickerTextFieldType = 'primary' | 'secondary';

type DateTimePickerTextFieldProps = WithTranslation &
    Omit<KeyboardTimePickerProps | KeyboardDatePickerProps, 'size' | 'onChange'> & {
        value: Date | null;
        type: TimePickerTextFieldType;
        className?: string;
        onChange: (value: Date | null, changeType: DATE_CHANGE_TYPES) => void;
        ampmFormat?: boolean;
        isChangeInputDisable?: boolean;
        isNow?: boolean;
    };

type DateTimePickerTextFieldState = {
    date: Date;
    isOpen: boolean;
};

export const withDateTimePickerTextField = (
    Component: React.ComponentType<DatePickerTextFieldProps | TimePickerTextFieldProps>,
) => {
    const dateTimePickerTextFieldStyles = withStyles(
        (theme) => ({
            root: {
                '& .MuiDateTimePickerTextField-rightIcons .MuiIconButton-colorSecondary': {
                    color: theme.palette.textField.tertiary,
                    '&:hover': {
                        color: theme.palette.textField.secondary.dark,
                    },
                },
                '& .MuiInputAdornment-root .MuiIconButton-colorSecondary': {
                    color: theme.palette.textField.tertiary,
                    '&:hover': {
                        color: theme.palette.textField.secondary.dark,
                    },
                },
            },
        }),
        {
            name: 'MuiDateTimePickerTextField',
        },
    );

    const StyledMUIDateTimePickerTextField = dateTimePickerTextFieldStyles(Component);

    return class WithDateTimePickerTextField extends React.PureComponent<
        DateTimePickerTextFieldProps,
        DateTimePickerTextFieldState
    > {
        dateTimePickerTextFieldRef = React.createRef<HTMLElement>();

        static defaultProps = {
            type: 'primary',
            size: 'medium',
            arrowChangeMode: 'minutes',
        };

        constructor(props: DateTimePickerTextFieldProps) {
            super(props);

            this.state = {
                date: props.value || new Date(),
                isOpen: false,
            };
        }

        componentDidMount(): void {
            if (this.dateTimePickerTextFieldRef.current) {
                const timeIcon = this.dateTimePickerTextFieldRef.current.offsetParent?.querySelector(
                    '.MuiInputAdornment-root > .MuiIconButton-root',
                );

                if (timeIcon && !timeIcon.classList.contains('iconButton')) {
                    timeIcon.classList.add('iconButton', 'MuiIconButton-colorSecondary');
                    this.forceUpdate();
                }
            }
        }

        componentDidUpdate(prevProps: Readonly<DateTimePickerTextFieldProps>) {
            if (prevProps.value !== this.props.value && this.props.value !== this.state.date) {
                this.setState({ date: this.props.value as Date });
            }
        }

        onChange = (value: any, changeType: DATE_CHANGE_TYPES) => {
            const { isNow, onChange } = this.props;
            let date = value;

            if (isNow) {
                const currentDate = new Date();

                if (date?.getTime() < currentDate.getTime()) {
                    date = currentDate;
                }
            }

            this.setState({ date });

            if (onChange) {
                onChange(date, changeType);
            }
        };

        onTogglePopover = (value: boolean) => () => {
            this.setState({ isOpen: value });
        };

        getArrows = (func: (isNext?: boolean) => () => void) => {
            if (this.dateTimePickerTextFieldRef.current?.offsetParent) {
                return ReactDOM.createPortal(
                    <div className="MuiDateTimePickerTextField-rightIcons">
                        <IconButton
                            className="MuiDateTimePickerTextField-icon"
                            size="medium"
                            color="secondary"
                            disabled={this.props.disabled}
                            onClick={func()}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                        <IconButton
                            className="MuiDateTimePickerTextField-icon"
                            size="medium"
                            color="secondary"
                            disabled={this.props.disabled}
                            onClick={func(true)}
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    </div>,
                    this.dateTimePickerTextFieldRef.current.offsetParent,
                );
            }
        };

        render() {
            const { className, isChangeInputDisable } = this.props;
            let additionalProps: Pick<DateTimePickerTextFieldProps, 'open' | 'onClose' | 'onClick'> = {};

            if (isChangeInputDisable) {
                additionalProps.open = this.state.isOpen;
                additionalProps.onClose = this.onTogglePopover(false);
                additionalProps.onClick = this.onTogglePopover(true);
            }

            return (
                <StyledMUIDateTimePickerTextField
                    {...this.props}
                    {...additionalProps}
                    TextFieldComponent={TextField as React.ComponentType<TextFieldProps>}
                    className={className}
                    inputProps={{
                        className: clsx('MuiDateTimePickerTextFieldInput-root'),
                        readOnly: isChangeInputDisable,
                    }}
                    inputRef={this.dateTimePickerTextFieldRef}
                    onChange={this.onChange}
                    value={this.state.date}
                    getArrows={this.getArrows}
                />
            );
        }
    };
};
