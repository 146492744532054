import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import './style.css';

export class Submenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subMenuAnchor: null,
        };
    }

    openSubmenu = (e) => {
        e.currentTarget.classList.add('active');
        this.setState({ subMenuAnchor: e.currentTarget });
    };

    closeSubmenu = () => {
        this.setState((state) => {
            const { subMenuAnchor } = state;
            if (subMenuAnchor) {
                subMenuAnchor.classList.remove('active');
            }
            return { subMenuAnchor: null };
        });
    };

    render() {
        const { parentMenuOpen, label, children, ...menuItemProps } = this.props;

        return (
            <MenuItem {...menuItemProps} onClick={this.openSubmenu} onMouseLeave={this.closeSubmenu}>
                {label}
                <Menu
                    anchorEl={this.state.subMenuAnchor}
                    open={parentMenuOpen && this.state.subMenuAnchor !== null}
                    onClose={this.closeSubmenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ pointerEvents: 'none' }}
                    MenuListProps={{ style: { pointerEvents: 'auto' } }}
                    disableAutoFocus
                    disableEnforceFocus
                    disableAutoFocusItem
                >
                    {children}
                </Menu>
            </MenuItem>
        );
    }
}

Submenu.propTypes = {
    parentMenuOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};
