import { centerOfBounds, logDebug, isValidLatLng } from 'utils';
import urlCommandManager from 'service/UrlCommandManager';
import { POSITION_TYPE, SHARED_MAP_POSITION_TYPE } from 'service/types';
import entityViewManager from './EntityViewManager';
import events from '../events';
import dispatcher from './dispatcher';
import territoryManager from './Territories/TerritoryManager';
import { userManager } from './UserManager';
import pointsManager from './PointsManager';
import mapStorageManager from './MapStorageManager';
import geoLocationManager, { PROCESS_TRIGGER, START_WATCH } from './GeoLocationManager';
import { STORAGE_KEY_PREFIX } from 'components/types';
import { sharedMapStateManager } from './SharedMapStateManager';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { enqueueSnackbarService } from 'service/MapPage';
import { MAP_MODE } from 'components/EntityDataTable/constants';

const DEFAULT_ENTITY_VISIBILITY = true;
const DEFAULT_LAYER_VISIBILITY = true;

export const LAYER_GROUP_DEFAULT = null;
export const LAYER_GROUP_NONE = -1;

/**
 * todo decompose storage and business logics: currently it directly saves MapPage's state
 */
class MapStateManager {
    constructor(userId) {
        this.isAttached = false;
        this.locationEvents = [];
        this.isCompleted = false;
        this.leftPane = {
            open: null,
            pinned: null,
            mode: null,
        };
        this.rightPane = {
            open: null,
            pinned: null,
            mode: null,
        };
        this.bottomPane = {
            open: null,
            pinned: null,
            mode: null,
            entity: null,
        };
        this.forceBottomPaneMode = null;
        this.hideBottomPaneMode = false;
        this.map = {
            zoom: null,
            location: null,
            bounds: null,
            basePoint: null,
            basePointRadius: null,
            basePointCircles: null,
            updateBasePointFromSearch: false,
            tableView: {
                pageSizes: new Map(),
            },
        };
        this.entities = {
            visible: null,
            views: null,
        };
        this.layers = {
            visible: null,
            views: null,
        };
        this.territories = {
            visible: {},
            group: 'default',
            legends: {},
        };
        this.geolocationFetched = false;
        this._version = null;
        this._sharedMapHash = null;
        this._previousState = null;
        this.requestedGeolocation = false;
        this.positionPresetPromise = Promise.resolve({ lat: null, lng: null, setBasePoint: true });
        this.positionPresetProcessing = true;
        this.positionPresetPredefined = false;
        this.pendingPopup = null;

        this.userId = userId;

        /**
         * this handler is masked immediately in this.attach()
         * todo make it work and see what happens
         * @link https://mapsly.atlassian.net/browse/MD-5759
         */
        dispatcher.subscribe(events.EVENT_USER_SWITCH, this, this.onUserSwitch);
        this.attach();

        const state = window.localStorage.getItem(this.getKey());
        if (null === state) {
            logDebug('MapStateManager local storage is empty');

            return;
        }
        let data = null;
        try {
            data = JSON.parse(state);
        } catch (e) {
            console.error('MapStateManager local storage', e);

            return;
        }
        this.init(data, data._version);
    }

    clear() {
        window.localStorage.removeItem(this.getKey());
    }

    attach = () => {
        if (this.isAttached) {
            return false;
        }
        dispatcher.subscribe(events.GEO_LOCATION_UPDATED, this, this.onGeoLocation);
        dispatcher.subscribe(events.GEO_LOCATION_ERROR, this, this.onGeoLocation);
        dispatcher.subscribe(events.EVENT_USER_LOGIN, this, this.onUserLogin);
        dispatcher.subscribe(events.EVENT_USER_SSO_LOGIN, this, this.onUserLogin);
        dispatcher.subscribe(events.SWITCH_TERRITORY, this, this.onTerritorySwitch);
        dispatcher.subscribe(events.SWITCH_TERRITORIES, this, this.onTerritoriesSwitch);
        dispatcher.subscribe(events.BASE_POINT, this, this.onBasePointChanged);
        dispatcher.subscribe(events.BASE_POINT_RADIUS, this, this.onBasePointRadiusChanged);
        dispatcher.subscribe(events.BASE_POINT_CIRCLES, this, this.onBasePointCirclesChanged);
        dispatcher.subscribe(events.EVENT_USER_SWITCH, this, (userId) => {
            if (userId === this.userId) {
                this.clear();
            }
        });
        return (this.isAttached = true);
    };

    detach = () => {
        if (!this.isAttached) {
            return false;
        }
        dispatcher.unsubscribe(
            [
                events.GEO_LOCATION_UPDATED,
                events.GEO_LOCATION_ERROR,
                events.EVENT_USER_LOGIN,
                events.EVENT_USER_SSO_LOGIN,
                events.SWITCH_TERRITORY,
                events.SWITCH_TERRITORIES,
                events.BASE_POINT,
                events.BASE_POINT_RADIUS,
                events.BASE_POINT_CIRCLES,
                events.EVENT_USER_SWITCH,
            ],
            this,
        );
        return !(this.isAttached = false);
    };

    // n.b.: would not be saved until any changes are made
    init(data, version, sharedMapSettings) {
        logDebug('MapStateManager state version', version);

        if (!data) {
            data = { ...this.getSettings() };
        }

        let positionType = null;

        this.positionPresetProcessing = urlCommandManager.isRecordPositionSet;
        if (urlCommandManager.isRecordPositionSet) {
            // to fire delayed MAP_POSITION_PRESET event
            positionType = POSITION_TYPE.RECORD;
        }

        if (urlCommandManager.isPositionSet) {
            this.positionPresetPredefined = true;

            if (urlCommandManager.location) {
                positionType = POSITION_TYPE.CENTER;
                data.map.location = { ...urlCommandManager.location };
                data.map.basePoint = { ...urlCommandManager.location };
            }

            if (urlCommandManager.area) {
                positionType = POSITION_TYPE.AREA;
                data.map.bounds = { ...urlCommandManager.area };
                data.map.location = centerOfBounds(urlCommandManager.area);
            }
        }

        if (urlCommandManager.locationZoom) {
            data.map.zoom = urlCommandManager.locationZoom;
        }

        let sharedSettings = false;
        /**
         * todo: shared map position will be applied every time page (re)loads, but not with every .hey()
         * @link https://mapsly.atlassian.net/browse/MD-2939
         */
        if (sharedMapSettings && sharedMapSettings.hash !== this._sharedMapHash) {
            data._sharedMapHash = sharedMapSettings.hash;
            sharedSettings = true;
            if (
                !this.positionPresetPredefined &&
                sharedMapSettings.positionType === SHARED_MAP_POSITION_TYPE.CENTER &&
                sharedMapSettings.locationLatLng &&
                sharedMapSettings.zoom
            ) {
                data.map.zoom = urlCommandManager.zoom ?? sharedMapSettings.zoom;
                const lat = parseFloat(sharedMapSettings.locationLatLng.lat);
                const lng = parseFloat(sharedMapSettings.locationLatLng.lng);
                data.map.location = { lat, lng };
                positionType = POSITION_TYPE.CENTER;
            }
            if (
                !this.positionPresetPredefined &&
                sharedMapSettings.positionType === SHARED_MAP_POSITION_TYPE.AREA &&
                sharedMapSettings.bounds
            ) {
                const { bounds } = sharedMapSettings;
                data.map.bounds = {
                    minLat: bounds.minLat,
                    maxLat: bounds.maxLat,
                    minLng: bounds.minLng,
                    maxLng: bounds.maxLng,
                };
                data.map.location = centerOfBounds(bounds);
                positionType = POSITION_TYPE.AREA;
            }
            if (sharedMapSettings.userLocation) {
                this.setUserLocation(sharedMapSettings, true);
            }
        }

        if (sharedMapSettings?.forceTableModeLayers) {
            this.forceBottomPaneMode = MAP_MODE.LAYERS;
            this.hideBottomPaneMode = true;
        }

        if (!sharedSettings && this._version > 0 && version > 0 && version < this._version) {
            if (!this.geolocationFetched && sharedMapSettings && !sharedMapSettings.userLocation) {
                geoLocationManager.setLocationProcessed();
                this.geolocationFetched = true;
            }
            if (!this.geolocationFetched && sharedMapSettings && sharedMapSettings.userLocation) {
                this.setUserLocation(sharedMapSettings, this.positionPresetProcessing);
            }

            logDebug('MapStateManager', 'local version', this._version, 'skip old version', version);

            return;
        }

        if (data.leftPane !== undefined) {
            if (data.leftPane.open !== undefined) {
                this.leftPane.open = data.leftPane.open;
            }
            if (data.leftPane.pinned !== undefined) {
                this.leftPane.pinned = data.leftPane.pinned;
            }
            if (data.leftPane.mode !== undefined) {
                this.leftPane.mode = data.leftPane.mode;
            }
        }
        if (data.rightPane !== undefined) {
            if (data.rightPane.open !== undefined) {
                this.rightPane.open = data.rightPane.open;
            }
            if (data.rightPane.pinned !== undefined) {
                this.rightPane.pinned = data.rightPane.pinned;
            }
            if (data.rightPane.mode !== undefined) {
                this.rightPane.mode = data.rightPane.mode;
            }
        }
        if (data.bottomPane !== undefined) {
            if (data.bottomPane.open !== undefined) {
                this.bottomPane.open = data.bottomPane.open;
            }
            if (data.bottomPane.pinned !== undefined) {
                this.bottomPane.pinned = data.bottomPane.pinned;
            }
            if (data.bottomPane.mode !== undefined) {
                this.bottomPane.mode = data.bottomPane.mode;
            }
            if (data.bottomPane.entity !== undefined) {
                this.bottomPane.entity = data.bottomPane.entity;
            }
        }
        if (data.map !== undefined) {
            if (data.map.zoom !== undefined) {
                this.map.zoom = data.map.zoom;
            }
            if (data.map.location !== undefined) {
                this.map.location = data.map.location;
            }
            if (data.map.bounds !== undefined) {
                this.map.bounds = data.map.bounds;
            }
            if (isValidLatLng(data.map.basePoint)) {
                this.map.basePoint = data.map.basePoint;
            }
            if (data.map.basePointRadius !== undefined) {
                this.map.basePointRadius = data.map.basePointRadius;
            }
            if (data.map.basePointCircles !== undefined) {
                this.map.basePointCircles = data.map.basePointCircles;
            }
            if (data.map.updateBasePointFromSearch !== undefined) {
                this.map.updateBasePointFromSearch = data.map.updateBasePointFromSearch;
            }

            if (typeof data.map.tableView !== 'undefined') {
                this.map.tableView.pageSizes = new Map(data.map.tableView.pageSizes);
            }
        }
        if (data.entities !== undefined) {
            this.entities = data.entities;
        }
        if (data.layers !== undefined) {
            this.layers = data.layers;
        }
        if (data.geolocationFetched !== undefined) {
            this.geolocationFetched = data.geolocationFetched;
        }
        if (data.territories !== undefined) {
            if (!isEmpty(data.territories.visible)) {
                this.territories.visible = data.territories.visible;
            }
            if (data.territories.group !== undefined) {
                this.territories.group = data.territories.group;
            }
            if (!isEmpty(data.territories.legends)) {
                this.territories.legends = data.territories.legends;
            }
        }
        if (data._sharedMapHash) {
            this._sharedMapHash = data._sharedMapHash;
        }
        if (version > 0) {
            this._version = version;
        }
        if (positionType !== null) {
            // this.init can be called twice, once for locally saved state (if exists), and once for received one, but MAP_POSITION_PRESET should be fired only once
            this.locationEvents = this.locationEvents
                .filter(([type]) => type !== events.MAP_POSITION_PRESET)
                .concat([[events.MAP_POSITION_PRESET, { type: positionType }]]);
        }
        if (sharedMapSettings && !this.geolocationFetched && !sharedMapSettings.userLocation) {
            geoLocationManager.setLocationProcessed();
            this.geolocationFetched = true;
        }
        if (sharedMapSettings && !this.geolocationFetched && sharedMapSettings.userLocation) {
            this.setUserLocation(sharedMapSettings, this.positionPresetProcessing);
        }
        this._previousState = JSON.stringify({ ...data, _version: null });
    }

    setUserLocation(sharedMapSettings, delayProcessing = false) {
        if (this.requestedGeolocation || this.positionPresetPredefined) {
            return;
        }
        this.requestedGeolocation = true;

        // sharedMapSettings.userLocation is checked by caller
        let zoom = urlCommandManager.zoom ?? sharedMapSettings.userLocationZoom;
        if (
            sharedMapSettings.positionType === SHARED_MAP_POSITION_TYPE.CENTER &&
            sharedMapSettings.locationLatLng &&
            sharedMapSettings.zoom
        ) {
            zoom = zoom || sharedMapSettings.zoom;
        }

        const userLocationPromise = geoLocationManager.getLocation(
            delayProcessing ? PROCESS_TRIGGER.DONT_PROCESS : PROCESS_TRIGGER.BASEPOINT,
            START_WATCH.IF_ACCURATE,
            zoom,
        );

        this.positionPresetPromise = delayProcessing
            ? userLocationPromise
            : userLocationPromise.then((location) => {
                  if (zoom) {
                      this.map.zoom = zoom;
                      this.save();
                      dispatcher.dispatch(events.MAP_POSITION_PRESET, { type: POSITION_TYPE.ZOOM });
                  }
                  return location;
              });
    }

    onGeoLocation = () => {
        this.geolocationFetched = true;
        this.save();
    };

    onUserLogin = () => {
        this.geolocationFetched = false;
        this.save();
    };

    // todo: debug user switching, seems to be broken: EVENT_USER_SWITCH is fired before current user is set
    onUserSwitch = (userId) => {
        const currentUserId = userManager.getCurrentUser()?.id;
        if (!currentUserId) {
            return;
        }
        logDebug('MapStateManager switch', this.userId, userId, currentUserId, userManager.getSwitchedUserRole());

        const isSwitchingBack = userId === null && this.userId !== currentUserId && userManager.isSwitched();
        if (userId === this.userId || isSwitchingBack) {
            if (this.attach()) {
                logDebug('MapStateManager attach', this.userId);
                this.geolocationFetched = false;
            }
            return;
        }

        if (this.detach()) {
            logDebug('MapStateManager detach and save', this.userId);
            this.geolocationFetched = false;
            this.save();
        }
    };

    onTerritorySwitch = (territory) => {
        this.territories.visible = this.territories.visible || {};
        this.territories.visible[territory.uuid] = territory.checked;
        this.save();
    };

    onTerritoryGroupLegendSwitch = (group, isOpen) => {
        this.territories.legends = this.territories.legends || {};
        this.territories.legends[group.uuid] = isOpen;
        this.save();
    };

    isTerritoryGroupLegendOpen = (group) => {
        this.territories.legends = this.territories.legends || {};
        return this.territories.legends[group.uuid] ?? true;
    };

    onBasePointChanged = (point) => {
        if (point === null) {
            this.map.basePoint = null;
            this.save();
            return;
        }

        if (!isValidLatLng(point)) {
            return;
        }

        this.map.basePoint = point;
        this.save();
    };

    onBasePointRadiusChanged = (radius) => {
        this.map.basePointRadius = radius;
        this.save();
    };

    onBasePointCirclesChanged = (circles) => {
        this.map.basePointCircles = circles;
        this.save();
    };

    getCurrentTerritoriesGroup = () => {
        return this.territories.group;
    };

    setTerritoriesGroup = (uuid) => {
        this.territories.group = uuid;
        this.save();
        dispatcher.dispatch(events.TERRITORIES_ACTIVE_GROUP_CHANGE);
    };

    onTerritoriesSwitch = (groupId) => {
        const group = territoryManager.getCurrentManager().getTerritoryGroupById(groupId);
        if (null === group) {
            return;
        }
        this.territories.visible = this.territories.visible || {};
        group.territories.forEach((t) => {
            if (!t.uuid) {
                return;
            }
            this.territories.visible[t.uuid] = t.checked;
        });
        this.save();
    };

    isTerritoryVisible = (territory) => {
        const visible = this.territories.visible || {};

        if (visible.hasOwnProperty(territory.uuid)) {
            return visible[territory.uuid];
        }
        return visible.hasOwnProperty(territory.id) ? visible[territory.id] : undefined;
    };

    isLeftPaneOpen() {
        return this.leftPane.open;
    }

    isLeftPanePinned() {
        return this.leftPane.pinned;
    }

    getLeftPaneMode() {
        return this.leftPane.mode;
    }

    isRightPaneOpen() {
        return this.rightPane.open;
    }

    isRightPanePinned() {
        return this.rightPane.pinned;
    }

    getRightPaneMode() {
        return this.rightPane.mode;
    }

    isBottomPaneOpen() {
        return this.bottomPane.open;
    }

    isBottomPanePinned() {
        return this.bottomPane.pinned;
    }

    getBottomPaneMode() {
        return this.forceBottomPaneMode ?? this.bottomPane.mode;
    }

    requestBottomPaneModeTrigger(mode) {
        const bottomPaneMode = this.forceBottomPaneMode ?? mode;
        if (bottomPaneMode === this.bottomPane.mode) {
            return false;
        }

        dispatcher.dispatch(events.ENTITY_DATA_TABLE_MODE_CHANGED, bottomPaneMode);

        return true;
    }

    isHideBottomPaneMode() {
        return this.hideBottomPaneMode;
    }

    getBottomPaneEntity() {
        return this.bottomPane.entity;
    }

    getMapBasePoint() {
        return this.map.basePoint;
    }

    getMapBasePointRadius() {
        return this.map.basePointRadius;
    }

    getMapBasePointCircles() {
        return this.map.basePointCircles;
    }

    getMapZoom() {
        return this.map.zoom;
    }

    getMapLocation() {
        return this.map.location;
    }

    getMapBounds() {
        return this.map.bounds;
    }

    updateLeftPane(open, pinned, mode) {
        this.leftPane = {
            open,
            pinned,
            mode,
        };
        this.save();
    }

    updateRightPane(open, pinned, mode) {
        this.rightPane = {
            open,
            pinned,
            mode,
        };
        this.save();
    }

    updateBottomPane(open, pinned, mode) {
        const { entity } = this.bottomPane;
        this.bottomPane = {
            open,
            pinned,
            mode,
            entity,
        };
        this.save();
    }

    updateBottomPaneEntity(entity) {
        const { open, pinned, mode } = this.bottomPane;
        this.bottomPane = {
            open,
            pinned,
            mode,
            entity,
        };
        this.save();
    }

    isCorrectCoordinate(value) {
        return typeof value === 'number' && !Number.isNaN(value);
    }

    updateLocation(center, zoom) {
        if (this.isCorrectCoordinate(center[0]) && this.isCorrectCoordinate(center[1])) {
            this.map.location = {
                lat: center[0],
                lng: center[1],
            };
        } else {
            this.map.location = undefined;
        }
        this.map.zoom = zoom;
        this.save();
    }

    updateBounds(bounds) {
        this.map.bounds = { ...bounds };
        this.save();
    }

    getEntityFractions(entityId) {
        if (-1 === entityId) {
            return [];
        }
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return [];
        }
        if (this.isEntityVisible(entity.id) === false) {
            return [];
        }
        const layerGroupId = this.getEntityLayerGroup(entity.id);
        if (layerGroupId === LAYER_GROUP_NONE) {
            return [pointsManager.constructor.buildFractionId(entity.id, 0, this.getEntityView(entity.id))];
        }
        const result = [];
        for (let layer of entity.layers) {
            if (layerGroupId !== layer.layerGroupId) {
                continue;
            }
            if (this.isLayerVisible(layer.id)) {
                result.push(
                    pointsManager.constructor.buildFractionId(
                        entity.id,
                        layer.id,
                        this.getLayerView(entity.id, layer.id),
                    ),
                );
            }
        }
        return result;
    }

    setEntityVisible(entityId, isVisible) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return;
        }
        const layerGroupId = this.getEntityLayerGroup(entity.id);

        let hasChanged = true;
        if (layerGroupId === LAYER_GROUP_NONE) {
            this.entities.visible = this.entities.visible || {};

            hasChanged = this.entities.visible[entityId] !== isVisible;

            this.entities.visible[entityId] = isVisible;
        } else {
            if (isVisible !== null) {
                this.layers.visible = this.layers.visible || {};
                for (let layer of entity.layers) {
                    if (layer.layerGroupId !== layerGroupId) {
                        continue;
                    }
                    this.layers.visible[layer.id] = isVisible;
                }
            }
        }
        this.save();

        if (hasChanged) {
            dispatcher.dispatch(events.ENTITY_VISIBILITY_CHANGED, { entityId, isVisible });
        }
    }

    isEntityVisible(entityId) {
        // null - для сущности-группы где есть включенные и выключенные слои внутри активной группы
        // true - для включенной простой сущности или сущности-группы, в которой включены все слои в активной группе
        // false - для выключенной простой сущности или сущности-группы, в которой выключены все слои в активной группе
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return DEFAULT_ENTITY_VISIBILITY;
        }
        const layerGroupId = this.getEntityLayerGroup(entity.id);

        if (layerGroupId === LAYER_GROUP_NONE) {
            if (null === this.entities.visible) {
                return DEFAULT_ENTITY_VISIBILITY;
            }
            const visible = this.entities.visible || {};
            return visible.hasOwnProperty(entityId) ? visible[entityId] === true : DEFAULT_ENTITY_VISIBILITY;
        }

        return this.calculateEntityVisibility(entityId);
    }

    calculateEntityVisibility(entityId) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return null;
        }
        let result = null;
        for (let layer of entity.layers) {
            if (layer.layerGroupId !== this.getEntityLayerGroup(entity.id)) {
                continue;
            }
            const v = this.isLayerVisible(layer.id);
            if (result !== null && v !== result) {
                return null;
            }
            result = v;
        }

        return result;
    }

    setEntityView(entityId, viewId) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return;
        }
        const layerGroupId = this.getEntityLayerGroup(entity.id);

        let hasChanged = true;
        if (layerGroupId === LAYER_GROUP_NONE) {
            this.entities.views = this.entities.views || {};

            hasChanged = this.entities.views[entityId] !== viewId;

            this.entities.views[entityId] = viewId;
        } else {
            if (viewId !== null) {
                this.layers.views = this.layers.views || {};
                for (let layer of entity.layers) {
                    if (layer.layerGroupId !== this.getEntityLayerGroup(entity.id)) {
                        continue;
                    }
                    this.layers.views[layer.id] = viewId;
                }
            }
        }
        this.save();

        if (hasChanged) {
            dispatcher.dispatch(events.ENTITY_VIEW_CHANGED, { entityId, viewId });
        }
    }

    getEntityView(entityId) {
        const layerGroupId = this.getEntityLayerGroup(entityId);
        if (layerGroupId === LAYER_GROUP_NONE) {
            if (null === this.entities.views) {
                return 0;
            }
            const views = this.entities.views || {};

            if (!views.hasOwnProperty(entityId)) {
                return 0;
            }
            const result = views[entityId];

            return this.doesViewExist(entityId, result) ? result : 0;
        }

        return this.calculateEntityView(entityId);
    }

    calculateEntityView(entityId) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return null;
        }
        let result = null;
        for (let layer of entity.layers) {
            if (layer.layerGroupId !== this.getEntityLayerGroup(entity.id)) {
                continue;
            }
            const v = this.getLayerView(entity.id, layer.id);
            if (result !== null && v !== result) {
                return null;
            }
            result = v;
        }

        return result;
    }

    setLayerVisible(entityId, layerId, isVisible) {
        this.layers.visible = this.layers.visible || {};
        this.layers.visible[layerId] = isVisible;

        this.setEntityVisible(entityId, this.calculateEntityVisibility(entityId));

        this.save();
        dispatcher.dispatch(events.LAYER_VISIBILITY_CHANGED, { entityId, layerId, isVisible });
    }

    isLayerVisible(layerId) {
        if (null === this.layers.visible) {
            return DEFAULT_LAYER_VISIBILITY;
        }
        const visible = this.layers.visible || {};
        return visible.hasOwnProperty(layerId) ? visible[layerId] : DEFAULT_LAYER_VISIBILITY;
    }

    setLayerView(entityId, layerId, viewId) {
        this.layers.views = this.layers.views || {};
        this.layers.views[layerId] = viewId;

        this.setEntityView(entityId, this.calculateEntityView(entityId));

        this.save();
        dispatcher.dispatch(events.LAYER_VIEW_CHANGED, { entityId, layerId, viewId });
    }

    getLayerView(entityId, layerId) {
        if (null === this.layers.views) {
            return 0;
        }
        const views = this.layers.views || {};
        if (!views.hasOwnProperty(layerId)) {
            return 0;
        }

        const result = views[layerId];

        return this.doesViewExist(entityId, result) ? result : 0;
    }

    doesViewExist(entityId, viewId) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return false;
        }
        for (let view of entity.views) {
            if (view.id === viewId) {
                return true;
            }
        }
        return false;
    }

    setEntityLayerGroup(entityId, layerGroupId) {
        this.entities.layerGroups = this.entities.layerGroups || {};
        if (this.entities.layerGroups[entityId] !== layerGroupId) {
            this.entities.layerGroups[entityId] = layerGroupId;
            this.save();
            dispatcher.dispatch(events.ENTITY_LAYER_GROUP_CHANGED, { entityId, layerGroupId });
        }
    }

    isGroupAvailable(entityId, groupId) {
        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return false;
        }
        return !!entity.layers.find((layer) => layer.layerGroupId === groupId);
    }

    getEntityLayerGroup(entityId) {
        if (userManager.hasEssentialRestrictions()) {
            return LAYER_GROUP_NONE;
        }
        const layerGroups = this.entities.layerGroups || {};

        const result = layerGroups.hasOwnProperty(entityId) ? layerGroups[entityId] : LAYER_GROUP_DEFAULT;

        if (result === LAYER_GROUP_NONE) {
            return result;
        }
        if (result === LAYER_GROUP_DEFAULT) {
            return this.isGroupAvailable(entityId, LAYER_GROUP_DEFAULT) ? LAYER_GROUP_DEFAULT : LAYER_GROUP_NONE;
        }

        const entity = entityViewManager.getEntity(entityId);
        if (!entity) {
            return LAYER_GROUP_NONE;
        }
        for (let layerGroup of entity.layerGroups) {
            if (layerGroup.id === result) {
                return this.isGroupAvailable(entityId, layerGroup.id) ? layerGroup.id : LAYER_GROUP_NONE;
            }
        }

        return LAYER_GROUP_NONE;
    }

    isGeolocationFetched() {
        return this.geolocationFetched;
    }

    isPositionPresetProcessing() {
        return this.positionPresetProcessing;
    }

    setPositionPresetProcessed() {
        this.positionPresetProcessing = false;
    }

    getPendingPopup() {
        return this.pendingPopup;
    }

    setPendingPopup(entityId, recordId) {
        this.pendingPopup = { entityId, recordId };
    }

    getIsCompleted() {
        return this.isCompleted;
    }

    setIsCompleted() {
        this.isCompleted = true;
    }

    toggleUpdateBasePointFromSearch() {
        this.map.updateBasePointFromSearch = !this.map.updateBasePointFromSearch;
        dispatcher.dispatch(events.UPDATE_BASE_POINT_HANDLE);
    }

    isUpdateBasePointFromSearch() {
        return this.map.updateBasePointFromSearch;
    }

    setTableViewPageSize(entityId, pageSize) {
        this.map.tableView.pageSizes.set(entityId, pageSize);
        this.save();
    }

    getTableViewPageSize(entityId) {
        return this.map.tableView.pageSizes.get(entityId);
    }

    save() {
        const settings = cloneDeep(this.getSettings());

        if (settings.map.tableView) {
            settings.map.tableView.pageSizes = [...settings.map.tableView.pageSizes];
        }

        const newSettings = JSON.stringify({ ...settings, _version: null });
        if (null !== this._previousState && this._previousState === newSettings) {
            return;
        }

        logDebug('MapStateManager settings changed', 'old', this._previousState, 'new', newSettings);

        this._previousState = JSON.stringify({ ...settings, _version: null });
        settings._version = parseInt(new Date().getTime() / 1000);

        // todo: switched user still uses local storage, it could confuse the switcher thinking map state is saved
        window.localStorage.setItem(this.getKey(), JSON.stringify(settings));

        mapStorageManager.save();
    }

    releaseLocationEvents() {
        const locationEvents = this.locationEvents;
        this.locationEvents = [];
        for (let locationEvent of locationEvents) {
            dispatcher.dispatch(locationEvent[0], locationEvent[1]);
        }
        return locationEvents;
    }

    getPositionPresetPromise() {
        return this.positionPresetPromise;
    }

    prependPositionPresetPromise(positionPromise) {
        const positionPresetPromise = this.positionPresetPromise;

        this.positionPresetPromise = new Promise((resolve) => {
            positionPromise
                .then((position) => resolve(position))
                .catch((error) => {
                    console.error('MapStateManager prepend position preset promise', error);
                    enqueueSnackbarService.sendErrorMessage(error.message);

                    positionPresetPromise.then((position) => resolve(position));
                });
        });

        this.setPositionPresetProcessed();
    }

    getSettings() {
        return {
            leftPane: this.leftPane,
            rightPane: this.rightPane,
            bottomPane: this.bottomPane,
            map: this.map,
            entities: this.entities,
            layers: this.layers,
            geolocationFetched: this.geolocationFetched,
            territories: this.territories,
            _sharedMapHash: this._sharedMapHash,
        };
    }

    getKey() {
        const isSharedMap = sharedMapStateManager.isSharedMap();
        if (isSharedMap) {
            return `${STORAGE_KEY_PREFIX.MAP_STATE}${sharedMapStateManager.getSharedMapHash()}`;
        }
        return `${STORAGE_KEY_PREFIX.MAP_STATE}${this.userId}`;
    }
}

class MapStateManagerFactory {
    managers = new Map();

    getManager(userId = undefined) {
        if (!userId) {
            userId = userManager.getCurrentUser().id;
        }
        if (this.managers.has(userId)) {
            return this.managers.get(userId);
        }

        const manager = new MapStateManager(userId);
        this.managers.set(userId, manager);

        return manager;
    }
}

export default new MapStateManagerFactory();
