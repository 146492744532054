class SharedMapStateManager {
    constructor() {
        this.sharedMapId = null;
        this.sharedMapUrl = null;

        const pathname = window.location.pathname;
        if (pathname.startsWith('/map/')) {
            const match = pathname.match(/^\/map\/([a-z0-9]+)/);
            this.sharedMapId = match[1] || null;
            this.sharedMapUrl = window.location.href;
        }
    }

    getSharedMapLink() {
        return this.sharedMapId;
    }

    getSharedMapId() {
        if (null === this.sharedMapId) {
            return null;
        }
        return `${this.sharedMapId}:sharedmap:${this.sharedMapUrl}`;
    }

    isSharedMap() {
        return !!this.sharedMapId;
    }

    getSharedMapHash() {
        return this.sharedMapId;
    }
}

export const sharedMapStateManager = new SharedMapStateManager();
