import { ParallelSafeDispatcher, Status } from './ParallelSafeDispatcher';

type Participant = {
    participantId: number;
    status: Status;
};

enum MessageType {
    VotingRequest,
    VotingResponse,
}

interface Message {
    type: MessageType;
}

interface VotingRequestMessage {
    initiatorId: number;
}

interface VotingResponseMessage {
    initiatorId: number;
    participantId: number;
    status: Status;
}

/**
 * Вкладка, которая собирается выполнить действие, перед этим уведомляет остальные вкладки.
 * Те отвечают, заняты они тем же действием или ожидают очереди или не имеют задачи.
 * На эти уведомления отводится определенное время (1с).
 * Затем вкладка смотрит список желающих выполнить действие, выбирается конкурент с минимальным ид.
 * Если это ид - ее собственный, то она выполняет действие.
 * После его выполнения сигнал другим вкладкам не отправляется. Они находятся в цикле ожидания (setInterval).
 */
export class ParallelSafeDispatcherMessageBased extends ParallelSafeDispatcher {
    private channel: BroadcastChannel;
    private participants: Participant[] = [];

    constructor(sharedName: string, task: any) {
        super(sharedName, task);

        this.channel = new BroadcastChannel(sharedName);

        this.channel.addEventListener(
            'message',
            (event: MessageEvent) => {
                const { type }: Message = event.data;
                switch (type) {
                    case MessageType.VotingRequest:
                        this.handleVotingRequestMessage(event.data);
                        break;
                    case MessageType.VotingResponse:
                        this.handleVotingResponseMessage(event.data);
                        break;
                }
            },
            false,
        );
    }

    private handleVotingRequestMessage({ initiatorId }: VotingRequestMessage): void {
        this.channel.postMessage({
            type: MessageType.VotingResponse,
            initiatorId,
            participantId: this.id,
            status: this.status,
        });
    }

    private handleVotingResponseMessage({ initiatorId, participantId, status }: VotingResponseMessage): void {
        if (initiatorId !== this.id) {
            return;
        }
        this.participants.push({
            participantId,
            status,
        });
    }

    protected vote(): Promise<boolean> {
        this.participants = [];

        this.channel.postMessage({
            type: MessageType.VotingRequest,
            initiatorId: this.id,
        });

        return new Promise((resolve) => {
            setTimeout(() => {
                let minId = this.id;
                for (const { status, participantId } of this.participants) {
                    if (status === Status.Processing) {
                        resolve(false);
                        return;
                    }
                    if (status === Status.Idle) {
                        continue;
                    }
                    if (!minId || participantId < minId) {
                        minId = participantId;
                    }
                }
                resolve(this.id === minId);
            }, 1000);
        });
    }
}
