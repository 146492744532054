import React from 'react';
import { Select, InputLabel, FormHelperText, FormControl } from '@material-ui/core';

class FormControlSelect extends React.PureComponent {
    render() {
        const { error, label, helperText, children, required, ...props } = this.props;

        return (
            <FormControl fullWidth margin={props.margin || 'dense'} error={error}>
                {label && <InputLabel required={required}>{label}</InputLabel>}
                <Select {...props}>{children}</Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

export default FormControlSelect;
