import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { CustomPaging, DataTypeProvider, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid as GridTable, PagingPanel, Table, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { TableHeaderRow } from '../Grid';
import { Icon, Tooltip, Switch, IconButton, Grid } from '@material-ui/core';
import DottedLink from '../DottedLink';
import Confirmation from '../Confirmation';
import { withStyles } from '@material-ui/core/styles';
import ActionIcon from '../WorkflowActions/ActionIcon';
import { CallContext } from '../utils/CallContext';
import { DataTable, FilterCell, FilterIcon, HeaderCellContent } from '../DataTable';
import { userManager } from '../../service/UserManager';
import DateTimeFormatter from 'components/DataTable/Formatter/DateTimeFormatter';
import { TYPE_COMMIT } from '../../service/WorkflowActionManager';

const tableCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const StyledFilterCell = withStyles({
    cell: {
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            paddingRight: 0,
        },
    },
    flexContainer: {
        flexFlow: 'row-reverse',
    },
})(FilterCell);

class WorkflowRulesList extends DataTable {
    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];
        this.defaultPageSize = 25;

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
        };
    }

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'isActive', width: 60, align: 'center' },
            { columnName: 'remove', width: 100, align: 'center' },
            { columnName: 'triggerTypes', width: '15%' },
            { columnName: 'actions', width: '20%' },
            { columnName: 'updatedBy', width: '10%' },
            { columnName: 'updatedAt', width: '10%' },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'actions', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c) => c.title).map((c) => c.title);
        return structure;
    }

    requestData(ignorePage = false, parentTimer = null) {
        return this.props.onRequestData({
            filters: this.getFilters(),
            sorting: this.sorting,
            currentPage: ignorePage ? 0 : this.state.pagination.current,
            pageSize: this.state.pagination.size,
        });
    }

    getFields() {
        return Promise.resolve([
            {
                columnName: 'isActive',
                title: null,
                getCellValue: (rule) => (
                    <Tooltip title={this.renderDisabledTooltip(rule)}>
                        <FormControlLabel
                            className=""
                            control={
                                <Switch
                                    checked={rule.isActive}
                                    onChange={this.props.onToggleActive.bind(this, rule)}
                                    disabled={rule.disabled || !userManager.automationElementsManagement()}
                                    color="primary"
                                    data-testid="automation_elements.toggle_rule_disabled"
                                />
                            }
                            label=""
                        />
                    </Tooltip>
                ),
            },
            {
                columnName: 'name',
                type: 'string',
                title: this.props.t('workflow_rules.list.name'),
                getCellValue: (rule) => (
                    <DottedLink onClick={this.props.onRuleEdit.bind(this, rule)} data-testid="workflow_rules.list.name">
                        {rule.name}
                    </DottedLink>
                ),
            },
            {
                columnName: 'triggerTypes',
                type: 'text[]',
                picklist: [
                    { label: this.props.t('workflow_rules.form.trigger_types.created'), value: 'created' },
                    { label: this.props.t('workflow_rules.form.trigger_types.edited'), value: 'edited' },
                    { label: this.props.t('workflow_rules.form.trigger_types.deleted'), value: 'deleted' },
                ],
                title: this.props.t('workflow_rules.list.trigger_types'),
                getCellValue: (rule) => {
                    let entityLabel;
                    for (const dataSource of this.props.dataSources) {
                        for (const entity of dataSource.entities) {
                            if (entity.id === rule.callContext.getBaseEntityId()) {
                                entityLabel = dataSource.name + ' > ' + entity.label;
                                break;
                            }
                        }
                    }
                    const triggers = rule.triggerTypes.map((type) => {
                        return this.props.t(`workflow_rules.form.trigger_types.${type}`);
                    });
                    return <span>{`${entityLabel}: ${triggers.join(', ')}`}</span>;
                },
            },
            {
                columnName: 'actions',
                type: 'string',
                title: this.props.t('workflow_rules.list.actions'),
                getCellValue: (rule) =>
                    rule.groups.map((group) => {
                        return group.mappings.map((mapping) => {
                            if (!mapping || !mapping.action) {
                                return null;
                            }
                            let name;
                            switch (mapping.action.type) {
                                case TYPE_COMMIT:
                                    name = this.props.t('workflow_actions.form.commit.title');
                                    break;
                                default:
                                    name = mapping.action.name;
                            }
                            return (
                                <Grid container direction="row" alignItems="center">
                                    <ActionIcon type={mapping.action.type} />
                                    {name}
                                </Grid>
                            );
                        });
                    }),
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: this.props.t('workflow_rules.list.updated_by'),
                getCellValue: (rule) => <span>{rule.updatedBy ? rule.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: this.props.t('workflow_rules.list.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (rule) => (
                    <Confirmation
                        text={this.props.t('workflow_rules.confirm.remove')}
                        onConfirm={this.props.onRuleRemove.bind(this, rule)}
                    >
                        <Tooltip title={this.props.t('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="workflow_rules.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ]);
    }

    renderDisabledTooltip = (rule) => {
        if (!rule.disabled) {
            return '';
        }
        const objectPrefix = this.props.t('automation_elements.disabled_object');
        const fieldPrefix = this.props.t('automation_elements.disabled_field');
        return (
            <React.Fragment>
                <span>{this.props.t('automation_elements.workflow_disabled')}</span>
                <ul>
                    {rule.disabledEntities?.map((entity) => (
                        <li>{`${objectPrefix} ${entity}`}</li>
                    ))}
                    {rule.disabledFields?.map((field) => (
                        <li>{`${fieldPrefix} ${field}`}</li>
                    ))}
                </ul>
            </React.Fragment>
        );
    };

    render() {
        const structure = this.state.structure;

        if (structure === null) {
            return <div>{this.props.t('loading')}</div>;
        }

        return (
            <React.Fragment>
                <GridTable rows={this.props.rows} columns={structure.columns}>
                    <SortingState
                        onSortingChange={this.handleSortingChanged}
                        columnExtensions={structure.noSortingColumns}
                    />
                    <DataTypeProvider
                        for={structure.numericColumns}
                        availableFilterOperations={this.numericFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateColumns}
                        availableFilterOperations={this.dateFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.dateTimeColumns}
                        availableFilterOperations={this.dateFilterOperations}
                        formatterComponent={DateTimeFormatter}
                    />
                    <DataTypeProvider
                        for={structure.stringColumns}
                        availableFilterOperations={this.stringFilterOperations}
                    />
                    <DataTypeProvider
                        for={structure.booleanColumns}
                        availableFilterOperations={this.booleanFilterOperations}
                    />
                    <FilteringState
                        onFiltersChange={this.handleFiltersChanged}
                        columnExtensions={[
                            { columnName: 'isActive', filteringEnabled: false },
                            { columnName: 'remove', filteringEnabled: false },
                        ]}
                    />
                    <Table
                        rowComponent={this.InteractiveRow}
                        columnExtensions={structure.exts}
                        cellComponent={withStyles(tableCellStyles)(Table.Cell)}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={structure.columns.length} loading={this.props.loading} />
                        )}
                    />
                    <TableHeaderRow
                        contentComponent={HeaderCellContent}
                        columnTitles={structure.columnTitles}
                        showSortingControls
                    />
                    <TableFilterRow
                        showFilterSelector
                        iconComponent={FilterIcon}
                        messages={this.filterMessages}
                        cellComponent={StyledFilterCell}
                    />

                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.props.total} />
                    <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
                </GridTable>
            </React.Fragment>
        );
    }
}

WorkflowRulesList.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            callContext: PropTypes.instanceOf(CallContext).isRequired,
        }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRequestData: PropTypes.func.isRequired,
    onRuleEdit: PropTypes.func.isRequired,
    onRuleRemove: PropTypes.func.isRequired,
    onToggleActive: PropTypes.func.isRequired,
};

export default withTranslation()(WorkflowRulesList);
