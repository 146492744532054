import React from 'react';
import events from '../../../events';
import dispatcher from '../../../service/dispatcher';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles/index';
import isEqual from 'lodash/isEqual';
import { isPicklistTypeNumber } from '../../../utils';

class MultiPicklistFormatter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            picklist: this.props.column.picklist || [],
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_FIELD_PICKLIST_CHANGED, this, ({ fieldId, picklist }) => {
            if (fieldId === this.props.column.id) {
                this.setState({
                    picklist,
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const picklist = this.props.column.picklist || [];
        if (!isEqual(picklist, prevProps.column.picklist)) {
            this.setState({
                picklist,
            });
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { picklist } = this.state;
        const value = this.props.value;
        if (!value) {
            return null;
        }

        const typePicklistIsNumber = isPicklistTypeNumber(picklist);
        const names = [];
        for (let v of value) {
            v = typePicklistIsNumber ? parseInt(v, 10) : v;
            const item = picklist ? picklist.find((item) => item.value === v) : null;
            if (item) {
                names.push(item.label);
            } else {
                names.push(v);
            }
        }

        const title = (
            <ul className={this.props.classes.ul}>
                {names.map((name) => (
                    <li key={name}>{name}</li>
                ))}
            </ul>
        );

        return (
            <Tooltip title={title}>
                <span>{names.join(', ')}</span>
            </Tooltip>
        );
    }
}

const multiSelectFormatterStyles = {
    ul: {
        margin: 0,
        paddingLeft: 16,
        listStyle: 'none',
        '& > li': {
            position: 'relative',
            '&::before': {
                position: 'absolute',
                content: `"\\2014"`,
                left: -16,
            },
        },
    },
};

export default withStyles(multiSelectFormatterStyles)(MultiPicklistFormatter);
