import RoutingSettingsDialogManager from './RoutingSettingsDialogManager';
import RoutingSettingsVehicleTypeDialogManager from './RoutingSettingsVehicleTypeDialogManager';
import RoutingSettingsObjectDialogManager from './RoutingSettingsObjectDialogManager';
import RoutingSettingsVehicleTypesFormManager from './RoutingSettingsVehicleTypesFormManager';
import RoutingSettingsObjectsFormManager from './RoutingSettingsObjectsFormManager';
import RoutingSettingsFilterFieldsService from './RoutingSettingsFilterFieldsService';
import RoutingSettingsObjectKeyService from './RoutingSettingsObjectKeyService';
import RoutingSettingsVehicleTypeFormManager from './RoutingSettingsVehicleTypeFormManager';
import RoutingSettingsSkillsFormManager from './RoutingSettingsSkillsFormManager';
import RoutingSettingsMappingUserFormManager from './RoutingSettingsMappingUserFormManager';
import RoutingSettingsMappingObjectFormManager from './RoutingSettingsMappingObjectFormManager';

const routingSettingsDialogManager = new RoutingSettingsDialogManager();
const routingSettingsVehicleTypeDialogManager = new RoutingSettingsVehicleTypeDialogManager();
const routingSettingsObjectDialogManager = new RoutingSettingsObjectDialogManager();

const routingSettingsMappingUserFormManager = new RoutingSettingsMappingUserFormManager();
const routingSettingsMappingObjectFormManager = new RoutingSettingsMappingObjectFormManager();
const routingSettingsObjectsFormManager = new RoutingSettingsObjectsFormManager();
const routingSettingsVehicleTypesFormManager = new RoutingSettingsVehicleTypesFormManager();
const routingSettingsSkillsFormManager = new RoutingSettingsSkillsFormManager();

const routingSettingsFilterFieldsService = new RoutingSettingsFilterFieldsService();

export {
    RoutingSettingsObjectKeyService,
    RoutingSettingsVehicleTypeFormManager,
    routingSettingsDialogManager,
    routingSettingsVehicleTypeDialogManager,
    routingSettingsObjectDialogManager,
    routingSettingsMappingUserFormManager,
    routingSettingsMappingObjectFormManager,
    routingSettingsObjectsFormManager,
    routingSettingsVehicleTypesFormManager,
    routingSettingsSkillsFormManager,
    routingSettingsFilterFieldsService,
};
