import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Slide, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import mergeWith from 'lodash/mergeWith';
import ReactJson from 'react-json-view';
import CircularProgress from '@material-ui/core/CircularProgress';
import { executionFlowManager } from '../../service/ExecutionFlowManager';
import ExecutionFlowRecord from './ExecutionFlowRecord';
import { TreeView } from '@material-ui/lab';
import { utcToUserTimezone } from '../../utils';

const Transition = React.forwardRef(function (props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ExecutionLogModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contextLoading: false,
            logs: [],
            searchValue: null,
            selectedLog: null,
            expanded: [],
        };

        this.filterContext = debounce(this.filterContext, 500);
    }

    componentDidMount() {
        executionFlowManager.getSessionLog(this.props.accountId, this.props.session).then((data) => {
            this.setState({ logs: data.items, loading: false });
        });
    }

    handleSearchInput = (event) => {
        const searchValue = event.target.value;
        this.setState({ searchValue });
        this.filterContext(searchValue);
    };

    filterContext = (search) => {
        this.setState({ logs: [], expanded: [], loading: true });
        executionFlowManager.getSessionLog(this.props.accountId, this.props.session, null, search).then((data) => {
            this.setState({ logs: data.items, loading: false });
        });
    };

    handleLogClick = (selectedLog) => {
        if (selectedLog && selectedLog.context) {
            this.setState({ selectedLog });
        } else {
            this.setState({ contextLoading: true });
            executionFlowManager
                .getLogContext(this.props.accountId, this.props.session.id, selectedLog.id)
                .then((contexts) => {
                    let context = {};
                    for (let item of contexts) {
                        context = mergeWith(context, JSON.parse(item), (objValue, srcValue, key) => {
                            if (key === 'lastAction') {
                                return srcValue;
                            }
                        });
                    }
                    selectedLog.context = context;
                    this.setState({ selectedLog, contextLoading: false });
                });
        }
    };

    updateLogs = (logs, logId, children) => {
        const newLogs = [];
        for (const log of logs) {
            if (log.id === logId) {
                log.children = children;
            } else {
                log.children = this.updateLogs(log.children, logId, children);
            }
            newLogs.push({ ...log });
        }
        return newLogs;
    };

    loadLogs = (logId) => {
        return executionFlowManager.getSessionLog(this.props.accountId, this.props.session, logId).then((data) => {
            this.setState(
                (state) => {
                    const logs = this.updateLogs(state.logs, logId, data.items);
                    return { logs };
                },
                () => {
                    this.handleExpansion(logId);
                },
            );
        });
    };

    handleExpansion = (logId) => {
        if (typeof logId !== 'string') {
            logId = logId.toString();
        }
        this.setState((state) => {
            let newExpanded;
            if (state.expanded.indexOf(logId) !== -1) {
                newExpanded = state.expanded.filter((id) => id !== logId);
            } else {
                newExpanded = [logId].concat(state.expanded);
            }
            return { expanded: newExpanded };
        });
    };

    handleCollapse = (item) => {
        const namespace = item.namespace.filter((i) => i).join('.');

        if (!this.state.selectedLog || !this.state.selectedLog.contextCursor) {
            return namespace !== '';
        }
        return namespace !== '' && !this.state.selectedLog.contextCursor.includes(namespace);
    };

    renderContext = () => {
        if (this.state.contextLoading) {
            return (
                <Grid container alignItems="center" justify="center" style={{ height: '100%' }}>
                    <CircularProgress size={30} />
                </Grid>
            );
        }
        return (
            <ReactJson
                src={(this.state.selectedLog && this.state.selectedLog.context) || {}}
                name={false}
                displayDataTypes={false}
                shouldCollapse={this.handleCollapse}
                enableClipboard={false}
                style={{ overflowY: 'scroll', height: '100%' }}
            />
        );
    };

    renderDialogContent = () => {
        if (this.state.loading) {
            return (
                <div className="loading">
                    <CircularProgress size={30} />
                </div>
            );
        }
        return (
            <DialogContent>
                <Grid container spacing={3} className="execution-flow-container">
                    <Grid item xs={7} className="execution-flow-column">
                        <div>
                            <h3>{this.props.t('execution_log.modal.execution_flow')}</h3>
                            <TextField
                                fullWidth
                                margin="dense"
                                name="searchValue"
                                value={this.state.searchValue || ''}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.handleSearchInput}
                                placeholder={this.props.t('execution_log.modal.filter.placeholder')}
                                data-testid="execution_log.modal.filter"
                            />
                            <TreeView
                                selected={this.state.selectedLog && this.state.selectedLog.id.toString()}
                                className="scrollable"
                                expanded={this.state.expanded}
                            >
                                {this.state.logs.map((log) => (
                                    <ExecutionFlowRecord
                                        key={log.id}
                                        user={this.props.user}
                                        data={log}
                                        onClick={this.handleLogClick}
                                        onExpansion={this.handleExpansion}
                                        expanded={this.state.expanded}
                                        onLogsLoad={this.loadLogs}
                                    />
                                ))}
                            </TreeView>
                        </div>
                    </Grid>
                    <Grid item xs className="execution-flow-column">
                        <div>
                            <h3>{this.props.t('execution_log.modal.context')}</h3>
                            {this.renderContext()}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        );
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} TransitionComponent={Transition} fullScreen>
                <DialogTitle className="c-form-dialog__title">
                    {this.props.t('execution_log.modal.title', {
                        startedAt: utcToUserTimezone(this.props.session.startedAt, this.props.user),
                        executionTime: this.props.session.executionTime,
                    })}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.props.onClose}
                        className="c-form-dialog__close"
                        data-testid="execution_log.modal.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                {this.renderDialogContent()}
            </Dialog>
        );
    }
}

ExecutionLogModal.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withTranslation()(ExecutionLogModal);
