import React from 'react';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultChip from '@material-ui/core/Chip';

const Chip = withStyles({
    root: {
        margin: 1,
    },
})(DefaultChip);

export class SelectedValues extends React.PureComponent {
    handleRemove = (event) => {
        const index = parseInt(event.currentTarget.getAttribute('data-index'));
        this.props.onRemove(index);
    };

    getPicklistLabel = (value) => {
        const item = this.props.picklist.find((item) => item.value === value);
        return item ? item.label : '';
    };

    render() {
        const { value, error, picklist, disabled } = this.props;

        return (
            <div className="array-items">
                {value.map((item, index) => {
                    const e = error && error.hasOwnProperty('e-' + index) ? error['e-' + index] : '';
                    return (
                        <Tooltip title={e} key={item}>
                            <Chip
                                label={picklist ? this.getPicklistLabel(item) : item}
                                onDelete={this.handleRemove}
                                color={e ? 'secondary' : 'default'}
                                disabled={disabled}
                            />
                        </Tooltip>
                    );
                })}
            </div>
        );
    }
}
