import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class SubscriptionPlanManager extends BackendService {
    listPlan(accountId) {
        const url = reverse(apiRoutes.account.subscription.plans, { accountId: accountId });

        return this.requestApi(url, 'GET').then((response) => {
            return response;
        });
    }

    listPlanByCountry(countryCode) {
        const url = reverse(apiRoutes.subscriptionPlans, { countryCode: countryCode });

        return this.requestApi(url, 'GET').then((response) => {
            return response;
        });
    }
}

export const subscriptionPlanManager = new SubscriptionPlanManager();
