import React from 'react';
import { withTranslation } from 'react-i18next';

class SsoNoAccessError extends React.PureComponent {
    render() {
        return <React.Fragment>{this.props.t('error_notification.sso_no_access_error.text')}</React.Fragment>;
    }
}

export default withTranslation()(SsoNoAccessError);
