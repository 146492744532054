import { FieldType } from 'components/types';
import { Routing } from 'interfaces';

export const ROUTING_SETTINGS_MAPPING_OBJECT_KEYS = new Set<string>([
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.JOB_TYPE,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DURATION,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PRIORITY,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PREPARATION_TIME,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DEPARTURE_DELAY,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_SKILLS,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_CAPACITY,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.ALLOWED_USERS,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DISALLOWED_USERS,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.MAX_TIME_IN_VEHICLE,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_FROM,
    Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_TO,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DURATION,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.PREPARATION_TIME,
    // Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DEPARTURE_DELAY,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.ADDRESS,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LATITUDE,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LONGITUDE,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_FROM,
    Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_TO,
]);

const routingSettingsMappingFilterAnyTypes = new Set([
    FieldType.STRING,
    FieldType.TEXT,
    FieldType.INTEGER,
    FieldType.BIGINT,
    FieldType.FLOAT,
]);

export const ROUTING_SETTINGS_MAPPING_USER_FILTER_FIELDS: Routing.Settings.MappingFilterFieldsTypeMap = new Map([
    [
        Routing.Settings.MappingUserKey.VEHICLE_TYPE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT]),
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.START_LOCATION_ADDRESS,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.START_LOCATION_LATITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.START_LOCATION_LONGITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.FINISH_LOCATION_ADDRESS,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.FINISH_LOCATION_LATITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.FINISH_LOCATION_LONGITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.RETURN_TO_FINISH_LOCATION,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.BOOLEAN]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.HAS_SKILLS,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([
                Routing.Settings.MappingFieldPicklistType.PICKLIST,
                Routing.Settings.MappingFieldPicklistType.MULTI_PICKLIST,
            ]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.MAX_DISTANCE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.MAX_DRIVING_TIME,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.MAX_JOBS,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.MIN_JOBS,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.MAX_ACTIVITIES,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.BREAK_DURATION,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.BREAK_EARLIEST_TIME,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingUserKey.BREAK_LATEST_TIME,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
]);

export const ROUTING_SETTINGS_MAPPING_OBJECT_FILTER_FIELDS: Routing.Settings.MappingFilterFieldsTypeMap = new Map([
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.JOB_TYPE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DURATION,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PRIORITY,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PREPARATION_TIME,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DEPARTURE_DELAY,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_SKILLS,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([
                Routing.Settings.MappingFieldPicklistType.PICKLIST,
                Routing.Settings.MappingFieldPicklistType.MULTI_PICKLIST,
            ]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_CAPACITY,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.ALLOWED_USERS,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DISALLOWED_USERS,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.MAX_TIME_IN_VEHICLE,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_FROM,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.DATE, FieldType.DATETIME]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_TO,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.DATE, FieldType.DATETIME]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DURATION,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.PREPARATION_TIME,
        {
            types: routingSettingsMappingFilterAnyTypes,
            picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
        },
    ],
    // [
    //     Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DEPARTURE_DELAY,
    //     {
    //         types: routingSettingsMappingFilterAnyTypes,
    //         picklistTypes: new Set([Routing.Settings.MappingFieldPicklistType.PICKLIST]),
    //     },
    // ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.ADDRESS,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LATITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LONGITUDE,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.INTEGER, FieldType.BIGINT, FieldType.FLOAT]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_FROM,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.DATE, FieldType.DATETIME]),
            picklistTypes: new Set([]),
        },
    ],
    [
        Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_TO,
        {
            types: new Set([FieldType.STRING, FieldType.TEXT, FieldType.DATE, FieldType.DATETIME]),
            picklistTypes: new Set([]),
        },
    ],
]);

export const routingSettingsMappingDefaultValueDefaultControlConfig: Routing.Settings.MappingDefaultValueControlConfig =
    {
        type: Routing.Settings.MappingDefaultValueControlType.TEXT,
    };

export const ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG: Routing.Settings.MappingDefaultValueControlConfigMap =
    new Map([
        [
            Routing.Settings.MappingUserKey.VEHICLE_TYPE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.PICKLIST,
                options: {
                    values: [],
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.START_LOCATION_ADDRESS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.LOOKUP_ADDRESS,
            },
        ],
        [
            Routing.Settings.MappingUserKey.START_LOCATION_LATITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -89.999999,
                    max: 89.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.START_LOCATION_LONGITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -179.999999,
                    max: 179.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.FINISH_LOCATION_ADDRESS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.LOOKUP_ADDRESS,
            },
        ],
        [
            Routing.Settings.MappingUserKey.FINISH_LOCATION_LATITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -89.999999,
                    max: 89.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.FINISH_LOCATION_LONGITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -179.999999,
                    max: 179.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.RETURN_TO_FINISH_LOCATION,
            {
                type: Routing.Settings.MappingDefaultValueControlType.PICKLIST,
                options: {
                    values: [
                        {
                            label: 'Yes',
                            value: 'yes',
                        },
                        {
                            label: 'No',
                            value: 'no',
                        },
                    ],
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.HAS_SKILLS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.MULTI_PICKLIST,
                options: {
                    values: [],
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.MAX_DISTANCE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 5,
                    max: 1000,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.MAX_DRIVING_TIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 10,
                    max: 10000,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.MAX_JOBS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 1000,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.MIN_JOBS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 100,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.MAX_ACTIVITIES,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 1000,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.BREAK_DURATION,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingUserKey.BREAK_EARLIEST_TIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.TIME,
            },
        ],
        [
            Routing.Settings.MappingUserKey.BREAK_LATEST_TIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.TIME,
            },
        ],
        [
            Routing.Settings.MappingUserKey.ALLOWED_OVERTIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 0,
                    max: 1440,
                },
            },
        ],
    ]);

export const ROUTING_SETTINGS_MAPPING_OBJECT_DEFAULT_VALUE_CONTROL_CONFIG: Routing.Settings.MappingDefaultValueControlConfigMap =
    new Map([
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.JOB_TYPE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.PICKLIST,
                options: {
                    values: [
                        {
                            label: Routing.Route.ActivityType.SERVICE,
                            value: Routing.Route.ActivityType.SERVICE,
                        },
                        {
                            label: Routing.Route.ActivityType.PICKUP,
                            value: Routing.Route.ActivityType.PICKUP,
                        },
                        {
                            label: Routing.Route.ActivityType.DELIVERY,
                            value: Routing.Route.ActivityType.DELIVERY,
                        },
                        {
                            label: Routing.Route.ActivityType.PICKUP_DELIVERY,
                            value: Routing.Route.ActivityType.PICKUP_DELIVERY,
                        },
                    ],
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DURATION,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PRIORITY,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 10,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.PREPARATION_TIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DEPARTURE_DELAY,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_SKILLS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.MULTI_PICKLIST,
                options: {
                    values: [],
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.REQUIRED_CAPACITY,
            {
                type: Routing.Settings.MappingDefaultValueControlType.TEXT,
                // TODO
                // options: {
                //     validator: ['integer', 'integers']
                // },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.ALLOWED_USERS,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.DISALLOWED_USERS,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.MAX_TIME_IN_VEHICLE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 10,
                    max: 1140,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_FROM,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
        [
            Routing.Settings.MappingObjectKey.ALL_JOB_TYPES.AVAILABILITY.AVAILABLE_TO,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DURATION,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.PREPARATION_TIME,
            {
                type: Routing.Settings.MappingDefaultValueControlType.INTEGER,
                options: {
                    min: 1,
                    max: 600,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.ADDRESS,
            {
                type: Routing.Settings.MappingDefaultValueControlType.LOOKUP_ADDRESS,
            },
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LATITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -89.999999,
                    max: 89.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.DELIVERY_LOCATION.LONGITUDE,
            {
                type: Routing.Settings.MappingDefaultValueControlType.FLOAT,
                options: {
                    min: -179.999999,
                    max: 179.999999,
                },
            },
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_FROM,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
        [
            Routing.Settings.MappingObjectKey.PICKUP_DELIVERY_JOBS.AVAILABILITY.AVAILABLE_TO,
            routingSettingsMappingDefaultValueDefaultControlConfig,
        ],
    ]);
