import BackendService from 'api/BackendService';
import dispatcher from './dispatcher';
import events from '../events';
import { userManager } from './UserManager';
import { logDebug } from '../utils';
import pointsManager, { HEATMAP_INDICES } from './PointsManager';

export const SCOPE = {
    OBJECT: 'object',
    LAYER: 'layer',
};

export const METRIC = {
    COUNT: 'count',
    VALUE: 'value',
};

export const DEFAULT_INTENSITY = 0.5;

class HeatmapManager extends BackendService {
    constructor(userId) {
        super();

        this.userId = userId;

        this.sourceParams = this.getDefaultSourceParams();
        this.displayParams = {
            transparency: 95,
            maxZoom: null,
            radius: 25,
            blur: 15,
            gradient: {
                0.4: '#0000FF',
                0.6: '#00FFFF',
                0.7: '#00FF00',
                0.8: '#FFFF00',
                1.0: '#FF0000',
            },
        };

        this.points = null;
        this.calculatedMax = null;
        this.isShown = false;

        const settings = localStorage.getItem('heatmap-settings-' + this.userId);
        if (settings) {
            try {
                const { sourceParams, displayParams } = JSON.parse(settings);
                this.sourceParams = sourceParams;
                this.displayParams = displayParams;
            } catch (e) {
                logDebug(e);
            }
        }

        dispatcher.subscribe(events.MAP_POSITION_CHANGED, this, () => {
            if (!this.isShown) {
                return;
            }
            this.reload();
        });
    }

    getDefaultSourceParams() {
        return {
            entityId: null,
            scope: SCOPE.OBJECT,
            layerId: null,
            metric: METRIC.COUNT,
            fieldApiName: null,
            maxValue: null,
        };
    }

    saveParams() {
        localStorage.setItem(
            'heatmap-settings-' + this.userId,
            JSON.stringify({
                sourceParams: this.sourceParams,
                displayParams: this.displayParams,
            }),
        );
    }

    setDisplayParams(displayParams) {
        this.displayParams = { ...displayParams };
        this.saveParams();
    }

    getDisplayParams() {
        return this.displayParams;
    }

    getSourceParams() {
        return this.sourceParams;
    }

    getPoints() {
        return this.points;
    }

    getIsShown() {
        return this.isShown;
    }

    draw(sourceParams) {
        this.sourceParams = { ...sourceParams };
        this.saveParams();
        this.reload();
    }

    reload() {
        pointsManager
            .loadHeatmapFraction(
                this.sourceParams.entityId,
                this.sourceParams.scope === SCOPE.LAYER ? this.sourceParams.layerId : 0,
                this.sourceParams.metric === METRIC.VALUE ? this.sourceParams.fieldApiName : null,
            )
            .then((points) => {
                let m = null;
                if (this.sourceParams.metric === METRIC.VALUE && this.sourceParams.maxValue === null) {
                    for (let point of points) {
                        if (m === null || point[HEATMAP_INDICES.VAL] > m) {
                            m = point[HEATMAP_INDICES.VAL];
                        }
                    }
                }
                this.points = points;
                this.calculatedMax = m === null ? DEFAULT_INTENSITY : m;
                this.isShown = true;
                dispatcher.dispatch(events.HEATMAP_DRAW);
            })
            .catch(() => {
                this.isShown = false;
                dispatcher.dispatch(events.HEATMAP_DRAW);
            });
    }

    getCalculatedMax() {
        return this.calculatedMax;
    }

    clear() {
        this.isShown = false;
        dispatcher.dispatch(events.HEATMAP_DRAW);
    }
}

class HeatmapManagerFactory {
    managers = new Map();

    getManager(userId) {
        if (!userId) {
            const user = userManager.getCurrentUser();
            if (!user) {
                return null;
            }
            userId = user.id;
        }
        if (this.managers.has(userId)) {
            return this.managers.get(userId);
        }

        const manager = new HeatmapManager(userId);
        this.managers.set(userId, manager);

        return manager;
    }
}

export default new HeatmapManagerFactory();
