import { fieldProtectionReasons } from '../components/DataSource/DsEntityField';
import dispatcher from '../service/dispatcher';
import events from '../events';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

const LOOKUP_DATA_FIELD = '__lookupEntity';

export { LOOKUP_DATA_FIELD };

export default class EntityHandler {
    constructor(entity) {
        this.entity = entity;
    }

    getEntity() {
        return this.entity;
    }

    notifyGroupCreated(layerGroup) {
        dispatcher.dispatch(events.WS_ENTITIES_CHANGED, { dsId: this.entity.dsId, entityIds: [this.entity.id] });
        dispatcher.dispatch(events.EVENT_LAYER_GROUP_CREATED, { entity: this.entity, layerGroup });
    }

    notifyGroupRefreshed(layerGroup) {
        dispatcher.dispatch(events.WS_ENTITIES_CHANGED, { dsId: this.entity.dsId, entityIds: [this.entity.id] });
        dispatcher.dispatch(events.EVENT_LAYER_GROUP_REFRESHED, { entity: this.entity, layerGroup });
    }

    addResidualLevel(layerGroupId) {
        this.entity.views.push({
            id: null,
            name: 'Other',
            expression: [],
            isIncluded: true,
            isLayer: true,
            isResidualLayer: true,
            isValid: true,
            layerGroupId,
        });
    }

    checkResidualLevel() {
        const layerGroups = {};

        for (let i = 0; i < this.entity.views.length; i++) {
            const view = this.entity.views[i];
            if (false === view.isLayer) {
                continue;
            }
            const layerGroupId = null === view.layerGroupId ? 'null' : view.layerGroupId;
            if (undefined === layerGroups[layerGroupId]) {
                layerGroups[layerGroupId] = [];
            }
            layerGroups[layerGroupId].push(view);
        }

        for (let layerGroupId of Object.keys(layerGroups)) {
            let addResidual = true;
            for (let view of layerGroups[layerGroupId]) {
                if (view.isResidualLayer) {
                    addResidual = false;
                }
            }
            if (addResidual) {
                let groupId = null;
                if (parseInt(layerGroupId, 10).toString() === layerGroupId) {
                    groupId = parseInt(layerGroupId, 10);
                } else if (layerGroupId === 'null') {
                    groupId = null;
                } else {
                    groupId = layerGroupId;
                }
                this.addResidualLevel(groupId);
            }
            if (layerGroups[layerGroupId].length === 1 && layerGroups[layerGroupId][0].isResidualLayer) {
                const view = layerGroups[layerGroupId][0];
                for (let i = 0; i < this.entity.views.length; i++) {
                    if (view === this.entity.views[i]) {
                        this.entity.views.splice(i, 1);
                        break;
                    }
                }
            }
        }
    }

    static findView(entity, view) {
        if (!view) {
            return -1;
        }
        for (let index = 0; index < entity.views.length; index++) {
            if (entity.views[index].id === view.id) {
                return index;
            }
        }
        return -1;
    }

    static findLayerGroup(entity, layerGroup) {
        if (!layerGroup) {
            return -1;
        }
        for (let index = 0; index < entity.layerGroups.length; index++) {
            if (entity.layerGroups[index].id === layerGroup.id) {
                return index;
            }
        }
        return -1;
    }

    static findField(entity, field) {
        if (!field) {
            return -1;
        }
        for (let index = 0; index < entity.fields.length; index++) {
            if (entity.fields[index].apiName === field.apiName) {
                return index;
            }
        }
        return -1;
    }

    replaceView(index, view, skipResidualLevel = false) {
        if (view === null) {
            this.entity.views.splice(index, 1);
            if (!skipResidualLevel) {
                this.checkResidualLevel();
            }

            return;
        }

        if (index === -1) {
            this.entity.views.push(view);
        } else {
            this.entity.views[index] = view;
        }

        if (view.isIncluded) {
            const collection = new Map();
            this.constructor.appendExpressionFieldNames(view.expression, collection, this.entity);
            for (let fi = 0; fi < this.entity.fields.length; fi++) {
                const field = this.entity.fields[fi];
                if (collection.has(field.apiName) && !field.isIncluded) {
                    const newField = { ...field };
                    newField.isIncluded = true;
                    this.entity.fields[fi] = newField;
                }
            }
        }

        if (!skipResidualLevel) {
            this.checkResidualLevel();
        }
    }

    replaceLayerGroup(index, layerGroup) {
        if (layerGroup === null) {
            this.entity.layerGroups.splice(index, 1);

            return;
        }

        if (index === -1) {
            this.entity.layerGroups.push(layerGroup);
        } else {
            this.entity.layerGroups[index] = layerGroup;
        }
    }

    replaceField = (index, field) => {
        if (field === null) {
            this.entity.fields.splice(index, 1);

            return;
        }

        if (field.isIncluded && field.address) {
            const collection = new Map();
            this.constructor.appendAddressFieldNames(field.address, collection);
            for (let fi = 0; fi < this.entity.fields.length; fi++) {
                const field = this.entity.fields[fi];
                if (collection.has(field.apiName) && !field.isIncluded) {
                    const newField = { ...field };
                    newField.isIncluded = true;
                    this.entity.fields[fi] = newField;
                }
            }
        }

        if (index === -1) {
            this.entity.fields.push(field);
        } else {
            this.entity.fields[index] = field;
        }

        this.entity.fields.sort(this.constructor.sortByLabel);
    };

    static sortByLabel(a, b) {
        if (a.label === b.label) {
            return 0;
        }
        return a.label > b.label ? 1 : -1;
    }

    static appendExpressionFieldNames(expression, collection, entity) {
        for (let clause of expression) {
            if (Array.isArray(clause)) {
                this.appendExpressionFieldNames(clause, collection, entity);
            } else if (typeof clause === 'object') {
                const xFieldApiName = entity.fields.find((field) => field.id === clause.xField)?.apiName;
                collection.set(xFieldApiName, [fieldProtectionReasons.expression]);
                if (clause.yField !== undefined) {
                    const yFieldApiName = entity.fields.find((field) => field.id === clause.yField)?.apiName;
                    collection.set(yFieldApiName, [fieldProtectionReasons.expression]);
                }
            }
        }
    }

    static appendAddressFieldNames = (address, collection) => {
        for (let key of Object.keys(address)) {
            const fieldName = address[key];
            if (fieldName) {
                collection.set(fieldName, [fieldProtectionReasons.address]);
            }
        }
    };

    static getProtectedFields(entity, ds, appointmentsFields = []) {
        const result = new Map();

        result.set('id', [fieldProtectionReasons.id]);
        result.set('mapsly_link', [fieldProtectionReasons.recordPage]);

        for (let field of entity.fields) {
            if (field.isIncluded && field.address) {
                this.appendAddressFieldNames(field.address, result);
            }
            if (entity.nameFields.includes(field.apiName)) {
                result.set(field.apiName, [fieldProtectionReasons.partOfRouteName]);
            }
            if (field.isPin) {
                result.set(field.apiName, [fieldProtectionReasons.pin]);
            }
            if (entity.ownerField === field.id) {
                result.set(field.apiName, [fieldProtectionReasons.owner]);
            }
            if (field.isSystemVisible || ds.isSystem) {
                result.set(field.apiName, [fieldProtectionReasons.isSystem]);
            }
            if (field.id === entity.latitudeField || field.id === entity.longitudeField) {
                result.set(field.apiName, [fieldProtectionReasons.coordinates]);
            }

            if (appointmentsFields.includes(field.apiName)) {
                const resultItem = result.get(field.apiName) || [];

                if (!resultItem.includes(fieldProtectionReasons.appointment)) {
                    resultItem.push(fieldProtectionReasons.appointment);
                    result.set(field.apiName, resultItem);
                }
            }
        }

        for (let view of entity.views) {
            if (view.isIncluded) {
                this.appendExpressionFieldNames(view.expression, result, entity);
            }
        }

        return result;
    }

    static stringify(entity) {
        entity = EntityHandler._removePrivateFields(entity);
        return JSON.stringify(entity);
    }

    static _removePrivateFields(entity) {
        const privateFields = [LOOKUP_DATA_FIELD];
        if (typeof entity === 'object') {
            return mapValues(
                pickBy(entity, (value, key) => !privateFields.includes(key)),
                EntityHandler._removePrivateFields,
            );
        }
        return entity;
    }
}
