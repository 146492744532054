import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import React, { SyntheticEvent } from 'react';
import PureFormDialog from '../PureFormDialog';
import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { intercomManager } from '../../service/IntercomManager';
import DialogActions from '@material-ui/core/DialogActions';

interface CalendarEventPermissionModalProps extends WithTranslation {
    onClose: () => {};
    profileName: string;
}

class CalendarEventPermissionModal extends React.Component<CalendarEventPermissionModalProps> {
    openIntercomFromCalendarEventPermissionModal = (e: SyntheticEvent) => {
        e.preventDefault();
        intercomManager.showNewMessage(this.props.t('permission.calendar.browse_users_modal.intercom_message'));
    };

    render() {
        const { t, onClose, profileName } = this.props;
        return (
            <PureFormDialog
                open
                hideCloseButton
                title={t('permission.calendar.browse_users_modal.title')}
                onClose={onClose}
                actions={
                    <DialogActions>
                        <Button onClick={onClose}>{t('button.ok')}</Button>
                    </DialogActions>
                }
            >
                <Trans
                    components={{ i: <i />, b: <b /> }}
                    values={{ profileName }}
                    i18nKey="permission.calendar.browse_users_modal"
                />
                &nbsp;
                <Link
                    href="#"
                    onClick={this.openIntercomFromCalendarEventPermissionModal}
                    style={{ textDecoration: 'none', borderBottom: '1px dotted' }}
                >
                    {t('permission.calendar.browse_users_modal.link')}
                </Link>
                .
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations', { withRef: true })(CalendarEventPermissionModal);
