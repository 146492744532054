import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { Capacitor } from '@capacitor/core';
import dispatcher from './dispatcher';
import events from '../events';
import { weAreInNativeApp } from '../utils';
import i18n from 'locales/i18n';
import config from '../params';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

const t = i18n.t.bind(i18n);
const LOCAL_STORAGE_CLEAR_DATA_KEY = 'in_app_browser.clear_data_key';

class InAppBrowserManager {
    private open: boolean;

    constructor() {
        this.open = false;
    }

    isOpen = (): boolean => {
        return this.open;
    };

    private onOpen = () => {
        this.open = true;
        if (Capacitor.getPlatform() === 'ios') {
            Keyboard.setResizeMode({ mode: KeyboardResize.None });
        }
    };

    private onClose = async () => {
        this.open = false;
        if (Capacitor.getPlatform() === 'ios') {
            await Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        }
    };

    getInAppBrowserOptions = (): InAppBrowserOptions => {
        let options: InAppBrowserOptions = {
            zoom: 'no',
            location: 'no',
            toolbar: 'no',
            hideurlbar: 'yes',
            toolbarposition: 'bottom',
            hidden: 'yes',
        };
        if (Capacitor.getPlatform() === 'ios') {
            options.toolbar = 'yes';
            options.toolbarcolor = '#eeeeee';
            options.closebuttoncaption = t('in_app_browser.close');
            options.toolbarposition = 'top';
            options.hidenavigationbuttons = 'yes';
        }
        if (Boolean(localStorage.getItem(LOCAL_STORAGE_CLEAR_DATA_KEY))) {
            options.clearcache = 'yes';
            options.clearsessioncache = 'yes';
            localStorage.setItem(LOCAL_STORAGE_CLEAR_DATA_KEY, '');
        }
        return options;
    };

    openDefaultBrowser = (url: string) => {
        const browser = InAppBrowser.create(url, '_system', { ...this.getInAppBrowserOptions() });
        browser.show();
    };

    openBrowser = (url: string, options?: InAppBrowserOptions) => {
        if (!weAreInNativeApp()) {
            return;
        }

        let parsedUrl = new URL(url);

        let target = '_self';
        if (Capacitor.getPlatform() === 'ios') {
            target = '_blank';
        }
        if (['tel:', 'mailto:', 'viber:', 'tg:', 'whatsapp:', 'skype:'].indexOf(parsedUrl.protocol) !== -1) {
            target = '_system';
        }

        const browser = InAppBrowser.create(url, target, { ...this.getInAppBrowserOptions(), ...options });
        this.onOpen();

        browser.on('exit').subscribe(this.onClose);

        browser.on('loadstart').subscribe(async (event: InAppBrowserEvent) => {
            if (!event.url) {
                return;
            }

            const redirectURL = new URL(event.url);

            // we should not show redirects to core and adapters
            if (this.isSystemHost(redirectURL.host)) {
                browser.hide();
            } else {
                browser.show();
            }

            if (redirectURL.host === config.frontAppHost) {
                // we are back to the front and the front is the app itself
                browser.close();
                await this.onClose(); // we call this method explicitly because handler can't manage to finish before redirect
                dispatcher.dispatch(events.IAB_CLOSED_AUTOMATICALLY);
                window.location.href = redirectURL.pathname + redirectURL.search + redirectURL.hash;
            }
        });
    };

    clearData = () => {
        if (!weAreInNativeApp()) {
            return;
        }
        localStorage.setItem(LOCAL_STORAGE_CLEAR_DATA_KEY, '1');
    };

    isSystemHost = (host: string) => {
        return new RegExp('.+' + config.frontAppHost).test(host);
    };
}

export default new InAppBrowserManager();
