import { makeAutoObservable } from 'mobx';
import { UserPropertiesTab } from 'components/types';
import { AccountData, TravelingPreferencesFocusElement, UserData } from 'service/types';

class UserPropertiesManager {
    private _tab: UserPropertiesTab = UserPropertiesTab.TAB_PERSONAL;
    private _user?: UserData;
    private _account?: AccountData;
    private _isOpen = false;
    private _focusElement?: TravelingPreferencesFocusElement;
    private _context?: string;
    private _connectGoogleCalendar = false;

    constructor() {
        makeAutoObservable(this);
    }

    get account(): AccountData {
        if (!this._account) {
            throw new Error('Account is not specified.');
        }

        return this._account;
    }

    get user(): UserData {
        if (!this._user) {
            throw new Error('User is not specified.');
        }

        return this._user;
    }

    get tab(): UserPropertiesTab {
        return this._tab;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get discoverContext(): string | undefined {
        return this._context;
    }

    get focusElement(): TravelingPreferencesFocusElement | undefined {
        return this._focusElement;
    }

    get connectGoogleCalendar(): boolean {
        return this._connectGoogleCalendar;
    }

    set connectGoogleCalendar(connect: boolean) {
        this._connectGoogleCalendar = connect;
    }

    changeTab(tab: UserPropertiesTab) {
        this._tab = tab;
    }

    openModal(
        account: AccountData,
        user: UserData,
        tab: UserPropertiesTab,
        focusElement?: TravelingPreferencesFocusElement,
        discoverCtx?: any,
        connectGoogleCalendar: boolean = false,
    ) {
        this._account = account;
        this._user = user;
        this._tab = tab;
        this._isOpen = true;
        this._focusElement = focusElement;
        this._context = discoverCtx;
        this._connectGoogleCalendar = connectGoogleCalendar;
    }

    closeModal() {
        this._isOpen = false;
        this._account = undefined;
        this._user = undefined;
        this._focusElement = undefined;
        this._context = undefined;
        this._connectGoogleCalendar = false;
    }
}

export default UserPropertiesManager;
