import { Checkbox, colors, FormControlLabel, FormGroup, FormHelperText, Grid, Switch } from '@material-ui/core';
import React, { RefObject } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import events from '../../events';
import BgGeoManager from '../../service/BgGeo/BgGeoManager';
import TurnOnTrackingPopupManager from '../../service/BgGeo/TurnOnTrackingPopupManager';
import dispatcher from '../../service/dispatcher';
import LocationTrackingMessage from '../../service/LocationTracking/LocationTrackingMessage';
import { locationTrackingMessageStorage } from '../../service/LocationTracking/LocationTrackingMessageStorage';
import { locationTrackingSettingsStorage } from '../../service/LocationTracking/LocationTrackingSettingsStorage';
import DeviceInfoManager from '../../service/MobileApp/DeviceInfoManager';
import { LocationTrackingSettings } from '../../service/types';
import { userPropertiesManager } from '../../service/UserForm';
import { weAreInAndroidNativeApp, weAreInNativeApp } from '../../utils';
import DottedLink from '../DottedLink';
import { UserPropertiesTab } from '../types';

interface LocationTrackingFormProps extends WithTranslation {
    ref: RefObject<LocationTrackingForm>;
    onScheduleEnable: any;
}

interface LocationTrackingFormState extends LocationTrackingSettings {
    requireLocationTrackingAgreement: boolean;
}

class LocationTrackingForm extends React.Component<LocationTrackingFormProps, LocationTrackingFormState> {
    constructor(props: LocationTrackingFormProps) {
        super(props);

        this.state = {
            autoActivateGeolocationDuringWork: false,
            trackGeolocation: false,
            requireLocationTrackingAgreement: false,
        };
    }

    componentDidMount() {
        if (!weAreInNativeApp()) {
            return;
        }

        BgGeoManager.getState().then(async (state) => {
            // check and change settings if needed
            await BgGeoManager.checkTrackingSettingsRelevance(state);

            // retrieve relevant settings
            const { trackGeolocation, autoActivateGeolocationDuringWork } = locationTrackingSettingsStorage.load();

            this.setState({
                autoActivateGeolocationDuringWork,
                trackGeolocation,
            });
        });
    }

    /**
     * todo stop tracking without updating config and stop schedule immediately not waiting for permissions
     */
    async submit() {
        const { trackGeolocation, autoActivateGeolocationDuringWork } = this.state;

        locationTrackingSettingsStorage.save({
            trackGeolocation,
            autoActivateGeolocationDuringWork,
        });

        // schedule tracking
        if (autoActivateGeolocationDuringWork) {
            // start scheduling regardless of previous state because we need to save new schedule anyway and because we will remove old handlers
            TurnOnTrackingPopupManager.rememberPopupWasShown(); // to not to show TurnOnTrackingPopup without actual relogin
            await BgGeoManager.requestPermissions(); // if we don't request permission here we will got no locations when scheduling starts
        }

        // switch tracking
        if (trackGeolocation) {
            await BgGeoManager.start();
        } else {
            await BgGeoManager.stop();
        }

        //await BgGeoManager.updateSchedule();
        await DeviceInfoManager.update();
    }

    private openTravellingTab = () => {
        userPropertiesManager.changeTab(UserPropertiesTab.TAB_TRAVELING);
    };

    private handleTrackGeolocationChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!locationTrackingMessageStorage.wasAccepted() && checked) {
            this.setState({
                requireLocationTrackingAgreement: true,
            });
        }

        this.setState({
            trackGeolocation: checked,
        });
    };

    private handleAutoActivateGeolocationDuringWorkChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        if (!locationTrackingMessageStorage.wasAccepted() && checked) {
            this.setState({
                requireLocationTrackingAgreement: true,
            });
        }

        this.setState({
            autoActivateGeolocationDuringWork: checked,
        });

        this.props.onScheduleEnable && this.props.onScheduleEnable(checked);
    };

    private handleOnNotAccept = () => {
        this.setState({
            requireLocationTrackingAgreement: false,
            trackGeolocation: false,
            autoActivateGeolocationDuringWork: false,
        });
    };

    private handleOnAccept = () => {
        this.setState({
            requireLocationTrackingAgreement: false,
        });
    };

    private openBatteryOptimizationModal = () => {
        dispatcher.dispatch(events.SHOW_BATTERY_OPTIMIZATION_MODAL, { hideSettingsPath: true });
    };

    render() {
        const { t } = this.props;
        const { trackGeolocation, autoActivateGeolocationDuringWork, requireLocationTrackingAgreement } = this.state;

        return (
            <React.Fragment>
                {!weAreInNativeApp() && (
                    <p>
                        <i style={{ color: colors.grey[600], marginRight: '8px' }} className="fas fa-info-circle" />
                        {this.props.t('location_tracking.form.text_for_browser')}
                    </p>
                )}
                {requireLocationTrackingAgreement && weAreInNativeApp() && (
                    <LocationTrackingMessage onAccept={this.handleOnAccept} onNotAccept={this.handleOnNotAccept} />
                )}
                {weAreInNativeApp() && (
                    <React.Fragment>
                        <p style={{ textAlign: 'center' }}>
                            <i style={{ color: colors.grey[600], marginRight: '8px' }} className="fas fa-info-circle" />
                            {this.props.t('location_tracking.form.warning_text')}
                        </p>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        className="marginDense"
                                        control={
                                            <Switch
                                                name="track_geolocation"
                                                checked={trackGeolocation}
                                                onChange={this.handleTrackGeolocationChange}
                                                color="primary"
                                                data-testid="location_tracking.form.track_geolocation_now"
                                            />
                                        }
                                        label={t('location_tracking.form.track_geolocation_now.label')}
                                    />
                                    <FormHelperText>
                                        {t('location_tracking.form.track_geolocation_now.hint')}
                                    </FormHelperText>
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        className="marginDense"
                                        control={
                                            <Checkbox
                                                name="auto_activate_geolocation_during_work"
                                                checked={autoActivateGeolocationDuringWork}
                                                onChange={this.handleAutoActivateGeolocationDuringWorkChange}
                                                color="primary"
                                            />
                                        }
                                        label={t('location_tracking.form.auto_activate_geolocation_during_work.label')}
                                    />
                                    <FormHelperText>
                                        {t('location_tracking.form.auto_activate_geolocation_during_work.hint')}
                                        &nbsp;
                                        <DottedLink
                                            href="#"
                                            onClick={this.openTravellingTab}
                                            data-testid="location_tracking.form.auto_activate_geolocation_during_work.hint_traveling_section_name"
                                        >
                                            {t(
                                                'location_tracking.form.auto_activate_geolocation_during_work.hint_traveling_section_name',
                                            )}
                                        </DottedLink>
                                        .
                                    </FormHelperText>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {weAreInAndroidNativeApp() && (
                            <p style={{ textAlign: 'center' }}>
                                <i
                                    style={{ color: colors.grey[600], marginRight: '8px' }}
                                    className="fas fa-info-circle"
                                />
                                {t('location_tracking.form.noticed_gap')}&nbsp;
                                <DottedLink
                                    href="#"
                                    onClick={this.openBatteryOptimizationModal}
                                    data-testid="location_tracking.form.noticed_gap.try_this"
                                >
                                    {t('location_tracking.form.noticed_gap.try_this')}
                                </DottedLink>
                                .
                            </p>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LocationTrackingForm);
