import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingButton from 'components/LoadingButton';
import PureFormDialog from 'components/PureFormDialog';
import { FormAlert, FormBackdrop } from 'components/PureFormDialog/Form';
import { limitationsManager } from 'service/LimitationsManager';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { formatDateTimeForPicker, formatDateToDefault, userTimezoneToUtc } from '../../utils';

class AccountLimitationsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errorMessage: undefined,
            errors: new Map(),
            accountLimitations: null,
        };
    }

    componentDidMount() {
        this.setState({
            accountLimitations: cloneDeep(this.props.accountLimitations),
        });
    }

    handleCloseAlert = () => {
        this.setState({
            errorMessage: undefined,
        });
    };

    handleInputChange = (event) => {
        const value = event.target.value || null;
        const name = event.target.name;

        this.setState((state) => {
            const accountLimitations = { ...state.accountLimitations };
            accountLimitations[name] = parseInt(value);

            const errors = new Map(state.errors);
            errors.delete(name);

            const errorMessage = errors.size === 0 ? undefined : state.errorMessage;

            return {
                accountLimitations,
                errorMessage,
                errors,
                saveAccountLimitations:
                    this.props.accountLimitations !== null &&
                    !isEqual(accountLimitations, this.props.accountLimitations),
            };
        });
    };

    handleChangeEmailsUntil = (date, event) => {
        let emailsUntil = formatDateToDefault(date);
        emailsUntil = userTimezoneToUtc(emailsUntil, this.props.user);
        this.setState((state) => {
            const accountLimitations = { ...state.accountLimitations };
            accountLimitations['sendEmailsWithNoSmtpUntil'] = emailsUntil;

            const errors = new Map(state.errors);
            errors.delete('sendEmailsWithNoSmtpUntil');

            const errorMessage = errors.size === 0 ? undefined : state.errorMessage;

            return {
                accountLimitations,
                errorMessage,
                errors,
                saveAccountLimitations:
                    this.props.accountLimitations !== null &&
                    !isEqual(accountLimitations, this.props.accountLimitations),
            };
        });
    };

    handleConfirmCustomize = () => {
        limitationsManager
            .save(this.props.accountLimitations.accountId, this.state.accountLimitations)
            .then(() => {
                this.setState(
                    {
                        errorMessage: undefined,
                        errors: new Map(),
                        loading: false,
                    },
                    () => {
                        this.props.onCloseDialog();
                    },
                );

                this.props.onCustomizeSuccess &&
                    this.props.onCustomizeSuccess(this.props.t('billing.edit_form.limitations_success'));
            })
            .catch((error) => {
                const state = {
                    errorMessage: error.message,
                    loading: false,
                };

                if (error.details) {
                    state.errors = error.details;
                }

                this.setState(state, () => {
                    this.props.onCustomizeFailure && this.props.onCustomizeFailure(error.message);
                });
            });

        this.setState({
            errorMessage: undefined,
            errors: new Map(),
            loading: true,
        });
    };

    render() {
        const { t } = this.props;

        if (this.state.accountLimitations === null) {
            return (
                <PureFormDialog
                    className="change-plan-form"
                    classes={{ content: 'dialog-content' }}
                    onClose={this.props.onCloseDialog}
                    open
                    scroll="paper"
                    title={t('billing.super_admin_panel.form.account_limitations.title')}
                >
                    <FormBackdrop loading>{t('loading')}</FormBackdrop>
                </PureFormDialog>
            );
        }

        const { errorMessage, errors, loading } = this.state;
        const accountLimitations = this.state.accountLimitations || {};
        const isNotChange = isEqual(this.props.accountLimitations, accountLimitations);
        const isHasErrors = errors.size > 0;

        return (
            <PureFormDialog
                actions={
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.props.onCloseDialog}
                            data-testid="account_limitations..button.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                        <LoadingButton
                            color="primary"
                            onClick={this.handleConfirmCustomize}
                            disabled={isNotChange || isHasErrors}
                            loading={loading}
                            data-testid="account_limitations.button.confirm"
                        >
                            {t('button.confirm')}
                        </LoadingButton>
                    </DialogActions>
                }
                alert={errorMessage ? <FormAlert onClose={this.handleCloseAlert}>{errorMessage}</FormAlert> : null}
                className="change-plan-form"
                classes={{ content: 'dialog-content' }}
                onClose={this.props.onCloseDialog}
                open
                scroll="paper"
                title={t('billing.super_admin_panel.form.account_limitations.title')}
            >
                <FormBackdrop loading={loading}>
                    <Typography variant="subtitle1" style={{ fontWeight: '500', marginTop: '10px' }}>
                        {t('billing.edit_form.account_limitation.daily')}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.workflows')}
                                data-testid="billing.edit_form.account_limitation.daily.workflows"
                                placeholder={t('billing.edit_form.account_limitation.daily.workflows')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="workflowsDaily"
                                value={accountLimitations.workflowsDaily}
                                helperText={errors.get('workflowsDaily') || ''}
                                error={errors.has('workflowsDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xd={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.workflows.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.workflows.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.workflows.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="workflowsDailyUsage"
                                value={accountLimitations.workflowsDailyUsage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.actions')}
                                data-testid="billing.edit_form.account_limitation.daily.actions"
                                placeholder={t('billing.edit_form.account_limitation.daily.actions')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="actionsDaily"
                                value={accountLimitations.actionsDaily}
                                helperText={errors.get('actionsDaily') || ''}
                                error={errors.has('actionsDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.actions.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.actions.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.actions.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="actionsDailyUsage"
                                value={accountLimitations.actionsDailyUsage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.route_calls')}
                                data-testid="billing.edit_form.account_limitation.daily.route_calls"
                                placeholder={t('billing.edit_form.account_limitation.daily.route_calls')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="routeCallsDaily"
                                value={accountLimitations.routeCallsDaily}
                                helperText={errors.get('routeCallsDaily') || ''}
                                error={errors.has('routeCallsDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.route_calls.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.route_calls.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.route_calls.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="routeCallsDailyUsage"
                                value={accountLimitations.routeCallsDailyUsage}
                                InputLabelProps={{
                                    style: { whiteSpace: 'nowrap' },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.send_emails')}
                                data-testid="billing.edit_form.account_limitation.daily.send_emails"
                                placeholder={t('billing.edit_form.account_limitation.daily.send_emails')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="sendEmailsDaily"
                                value={accountLimitations.sendEmailsDaily}
                                helperText={errors.get('sendEmailsDaily') || ''}
                                error={errors.has('sendEmailsDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.send_emails.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.send_emails.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.send_emails.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="sendEmailsDailyUsage"
                                value={accountLimitations.sendEmailsDailyUsage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.send_sms')}
                                data-testid="billing.edit_form.account_limitation.daily.send_sms"
                                placeholder={t('billing.edit_form.account_limitation.daily.send_sms')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="sendSmsDaily"
                                value={accountLimitations.sendSmsDaily}
                                helperText={errors.get('sendSmsDaily') || ''}
                                error={errors.has('sendSmsDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.send_sms.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.send_sms.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.send_sms.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="sendSmsDailyUsage"
                                value={accountLimitations.sendSmsDailyUsage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.invoke_url')}
                                data-testid="billing.edit_form.account_limitation.daily.invoke_url"
                                placeholder={t('billing.edit_form.account_limitation.daily.invoke_url')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="invokeUrlDaily"
                                value={accountLimitations.invokeUrlDaily}
                                helperText={errors.get('invokeUrlDaily') || ''}
                                error={errors.has('invokeUrlDaily')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.invoke_url.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.invoke_url.usage"
                                placeholder={t('billing.edit_form.account_limitation.daily.invoke_url.usage')}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="invokeUrlDailyUsage"
                                value={accountLimitations.invokeUrlDailyUsage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.max_emails_with_no_smtp')}
                                data-testid="billing.edit_form.account_limitation.daily.max_emails_with_no_smtp"
                                placeholder={t('billing.edit_form.account_limitation.daily.max_emails_with_no_smtp')}
                                type="number"
                                fullWidth
                                margin="dense"
                                name="maxEmailsWithNoSmtp"
                                value={accountLimitations.maxEmailsWithNoSmtp}
                                helperText={errors.get('maxEmailsWithNoSmtp') || ''}
                                error={errors.has('maxEmailsWithNoSmtp')}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('billing.edit_form.account_limitation.daily.max_emails_with_no_smtp.usage')}
                                data-testid="billing.edit_form.account_limitation.daily.max_emails_with_no_smtp.usage"
                                placeholder={t(
                                    'billing.edit_form.account_limitation.daily.max_emails_with_no_smtp.usage',
                                )}
                                type="number"
                                fullWidth
                                disabled
                                margin="dense"
                                name="maxEmailsWithNoSmtpUsage"
                                value={accountLimitations.maxEmailsWithNoSmtpUsage}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1" style={{ fontWeight: '500', marginTop: '10px' }}>
                        {t('billing.edit_form.account_limitation.per_session')}
                    </Typography>
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.actions')}
                        data-testid="billing.edit_form.account_limitation.per_session.actions"
                        placeholder={t('billing.edit_form.account_limitation.per_session.actions')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="actionsPerSession"
                        value={accountLimitations.actionsPerSession}
                        helperText={errors.get('actionsPerSession') || ''}
                        error={errors.has('actionsPerSession')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.route_calls')}
                        data-testid="billing.edit_form.account_limitation.per_session.route_calls"
                        placeholder={t('billing.edit_form.account_limitation.per_session.route_calls')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="routeCallsPerSession"
                        value={accountLimitations.routeCallsPerSession}
                        helperText={errors.get('routeCallsPerSession') || ''}
                        error={errors.has('routeCallsPerSession')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.session_duration')}
                        data-testid="billing.edit_form.account_limitation.per_session.session_duration"
                        placeholder={t('billing.edit_form.account_limitation.per_session.session_duration')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="sessionDuration"
                        value={accountLimitations.sessionDuration}
                        helperText={errors.get('sessionDuration') || ''}
                        error={errors.has('sessionDuration')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.recursion_depth')}
                        data-testid="billing.edit_form.account_limitation.per_session.recursion_depth"
                        placeholder={t('billing.edit_form.account_limitation.per_session.recursion_depth')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="recursionDepth"
                        value={accountLimitations.recursionDepth}
                        helperText={errors.get('recursionDepth') || ''}
                        error={errors.has('recursionDepth')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.invoke_url')}
                        data-testid="billing.edit_form.account_limitation.per_session.invoke_url"
                        placeholder={t('billing.edit_form.account_limitation.per_session.invoke_url')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="invokeUrlPerSession"
                        value={accountLimitations.invokeUrlPerSession}
                        helperText={errors.get('invokeUrlPerSession') || ''}
                        error={errors.has('invokeUrlPerSession')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.heap_size')}
                        data-testid="billing.edit_form.account_limitation.per_session.heap_size"
                        placeholder={t('billing.edit_form.account_limitation.per_session.heap_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="heapSizePerSession"
                        value={accountLimitations.heapSizePerSession}
                        helperText={errors.get('heapSizePerSession') || ''}
                        error={errors.has('heapSizePerSession')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.per_session.cpu_time')}
                        data-testid="billing.edit_form.account_limitation.per_session.cpu_time"
                        placeholder={t('billing.edit_form.account_limitation.per_session.cpu_time')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="cpuTimePerSession"
                        value={accountLimitations.cpuTimePerSession}
                        helperText={errors.get('cpuTimePerSession') || ''}
                        error={errors.has('cpuTimePerSession')}
                        onChange={this.handleInputChange}
                    />
                    <Typography variant="subtitle1" style={{ fontWeight: '500', marginTop: '10px' }}>
                        {t('billing.edit_form.account_limitation.other')}
                    </Typography>
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_match_candidates')}
                        data-testid="billing.edit_form.account_limitation.other.max_match_candidates"
                        placeholder={t('billing.edit_form.account_limitation.other.max_match_candidates')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxMatchCandidates"
                        value={accountLimitations.maxMatchCandidates}
                        helperText={errors.get('maxMatchCandidates') || ''}
                        error={errors.has('maxMatchCandidates')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.invoke_url_timeout')}
                        data-testid="billing.edit_form.account_limitation.other.invoke_url_timeout"
                        placeholder={t('billing.edit_form.account_limitation.other.invoke_url_timeout')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="invokeUrlTimeout"
                        value={accountLimitations.invokeUrlTimeout}
                        helperText={errors.get('invokeUrlTimeout') || ''}
                        error={errors.has('invokeUrlTimeout')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.get_records_size')}
                        data-testid="billing.edit_form.account_limitation.other.get_records_size"
                        placeholder={t('billing.edit_form.account_limitation.other.get_records_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="getRecordsSize"
                        value={accountLimitations.getRecordsSize}
                        helperText={errors.get('getRecordsSize') || ''}
                        error={errors.has('getRecordsSize')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.update_records_size')}
                        data-testid="billing.edit_form.account_limitation.other.update_records_size"
                        placeholder={t('billing.edit_form.account_limitation.other.update_records_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="updateRecordsSize"
                        value={accountLimitations.updateRecordsSize}
                        helperText={errors.get('updateRecordsSize') || ''}
                        error={errors.has('updateRecordsSize')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_email_body_size')}
                        data-testid="billing.edit_form.account_limitation.other.max_email_body_size"
                        placeholder={t('billing.edit_form.account_limitation.other.max_email_body_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxEmailBodySize"
                        value={accountLimitations.maxEmailBodySize}
                        helperText={errors.get('maxEmailBodySize') || ''}
                        error={errors.has('maxEmailBodySize')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_fields_per_form')}
                        data-testid="billing.edit_form.account_limitation.other.max_fields_per_form"
                        placeholder={t('billing.edit_form.account_limitation.other.max_fields_per_form')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxFieldsPerForm"
                        value={accountLimitations.maxFieldsPerForm}
                        helperText={errors.get('maxFieldsPerForm') || ''}
                        error={errors.has('maxFieldsPerForm')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_records_for_group_actions')}
                        data-testid="billing.edit_form.account_limitation.other.max_records_for_group_actions"
                        placeholder={t('billing.edit_form.account_limitation.other.max_records_for_group_actions')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxRecordsForGroupAction"
                        value={accountLimitations.maxRecordsForGroupAction}
                        helperText={errors.get('maxRecordsForGroupAction') || ''}
                        error={errors.has('maxRecordsForGroupAction')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_execution_log_size')}
                        data-testid="billing.edit_form.account_limitation.other.max_execution_log_size"
                        placeholder={t('billing.edit_form.account_limitation.other.max_execution_log_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxExecutionLogSize"
                        value={accountLimitations.maxExecutionLogSize}
                        helperText={errors.get('maxExecutionLogSize') || ''}
                        error={errors.has('maxExecutionLogSize')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.data_exchange_log_ttl')}
                        data-testid="billing.edit_form.account_limitation.other.data_exchange_log_ttl"
                        placeholder={t('billing.edit_form.account_limitation.other.data_exchange_log_ttl')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="dataExchangeLogTtl"
                        value={accountLimitations.dataExchangeLogTtl}
                        helperText={errors.get('dataExchangeLogTtl') || ''}
                        error={errors.has('dataExchangeLogTtl')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_global_params')}
                        data-testid="billing.edit_form.account_limitation.other.max_global_params"
                        placeholder={t('billing.edit_form.account_limitation.other.max_global_params')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxGlobalParams"
                        value={accountLimitations.maxGlobalParams}
                        helperText={errors.get('maxGlobalParams') || ''}
                        error={errors.has('maxGlobalParams')}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('billing.edit_form.account_limitation.other.max_global_param_size')}
                        data-testid="billing.edit_form.account_limitation.other.max_global_param_size"
                        placeholder={t('billing.edit_form.account_limitation.other.max_global_param_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxGlobalParamSize"
                        value={accountLimitations.maxGlobalParamSize}
                        helperText={errors.get('maxGlobalParamSize') || ''}
                        error={errors.has('maxGlobalParamSize')}
                        onChange={this.handleInputChange}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            label={t('billing.edit_form.account_limitation.other.send_emails_with_no_smtp_until')}
                            data-testid="billing.edit_form.account_limitation.other.send_emails_with_no_smtp_until"
                            format="MM/dd/yyyy"
                            name="sendEmailsWithNoSmtpUntil"
                            margin="dense"
                            variant="inline"
                            autoOk={true}
                            onChange={this.handleChangeEmailsUntil}
                            value={
                                accountLimitations.sendEmailsWithNoSmtpUntil
                                    ? formatDateTimeForPicker(
                                          accountLimitations.sendEmailsWithNoSmtpUntil,
                                          this.props.user,
                                      )
                                    : null
                            }
                            helperText={errors.get('sendEmailsWithNoSmtpUntil') || ''}
                            error={errors.has('sendEmailsWithNoSmtpUntil')}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </FormBackdrop>
            </PureFormDialog>
        );
    }
}

AccountLimitationsForm.propTypes = {
    accountLimitations: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.oneOf([null]).isRequired])
        .isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    onCustomizeSuccess: PropTypes.func,
    onCustomizeFailure: PropTypes.func,
    user: PropTypes.object.isRequired,
};

export default withTranslation()(AccountLimitationsForm);
