import { EventEmitter } from 'events';

const EVENT_PROMPT = Symbol('EVENT_PROMPT');

export type HistoryPromptListener = (message: string, callback: (ok: boolean) => void) => void;

class HistoryPromptManager extends EventEmitter {
    static defaultListener: HistoryPromptListener = (_, cb) => cb(true);

    subscribe(listener: HistoryPromptListener) {
        this.addListener(EVENT_PROMPT, listener);

        return () => this.unsubscribe(listener);
    }

    unsubscribe(listener: HistoryPromptListener) {
        this.removeListener(EVENT_PROMPT, listener);
    }

    dispatch(message: string, callback: (ok: boolean) => void) {
        const listener = [HistoryPromptManager.defaultListener]
            .concat(this.listeners(EVENT_PROMPT) as HistoryPromptListener[])
            .pop()!;

        try {
            listener(message, callback);
        } catch (error) {
            console.error(error);
            callback(true);
        }
    }
}

export const historyPromptManager = new HistoryPromptManager();
