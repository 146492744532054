import { createContext } from 'react';

export const DialogContext = createContext<{
    onClose?: () => void;
    styles: {
        actions: string;
        alert: string;
        backdrop: string;
    };
}>({
    onClose: undefined,
    styles: {
        actions: '',
        alert: '',
        backdrop: '',
    },
});
