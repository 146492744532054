import { userManager } from '../UserManager';
import { STORAGE_KEY_PREFIX } from '../../components/types';

class ScheduleConsistencePopupManager {
    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.THERE_WAS_ATTEMPT_TO_CHANGE_SCHEDULE_OUTSIDE_APP + userManager.getCurrentUser().id;
    }

    wasPopupShown = () => {
        return window.localStorage.getItem(this.getStorageKey());
    };

    rememberPopupWasShown = () => {
        window.localStorage.setItem(this.getStorageKey(), 'was');
    };
}

export default new ScheduleConsistencePopupManager();
