import { StorageInterface, TypeKey } from './StorageInterface';
import Dexie from 'dexie';
import slugify from 'slugify';

const DB_NAME = 'MAPSLY_STORAGE';
const DEFAULT_TABLE = 'default_table';

enum FIELD {
    DOMAIN = 'domain',
    KEY = 'key',
}

interface Object {
    domain: string;
    key: string;
    value: any;
}

export class StorageIndexDB implements StorageInterface {
    db: Dexie = new Dexie(DB_NAME);

    init(): Promise<void> {
        const db = new Dexie(DB_NAME);
        this.setVersion(db);
        return db
            .open()
            .then((db) => {
                if (!db.isOpen()) {
                    return Promise.reject();
                }
                this.db = db;
                return Promise.resolve();
            })
            .catch(Dexie.errnames.Version, () => {
                db.delete();
                this.setVersion(db);
                return db.open().then((db) => {
                    if (!db.isOpen()) {
                        return Promise.reject();
                    }
                    this.db = db;
                    return Promise.resolve();
                });
            });
    }

    getDomainName(domain: string | null): string {
        if (!domain) {
            return DEFAULT_TABLE;
        }
        return slugify(domain);
    }

    getKey(key: TypeKey, domain: string): string {
        return `${domain}-${key}`;
    }

    setVersion(db: Dexie) {
        db.version(1).stores({
            [DEFAULT_TABLE]: `${FIELD.KEY}, ${FIELD.DOMAIN}`,
        });
    }

    openDb = () => {
        if (this.db.isOpen()) {
            return Promise.resolve();
        }
        return this.db.open().then(() => {
            return Promise.resolve();
        });
    };

    clear(domain: string): Promise<void> {
        if (this.db.isOpen()) {
            this.db.close();
        }
        let domainName = this.getDomainName(domain);
        return this.openDb().then(() => {
            return this.db
                .table(DEFAULT_TABLE)
                .where(FIELD.DOMAIN)
                .equals(domainName)
                .delete()
                .then(() => {
                    return Promise.resolve();
                });
        });
    }

    async get(key: TypeKey, domain: string | null): Promise<any> {
        let domainName = this.getDomainName(domain);
        try {
            await this.openDb();
            const result = await this.db.table(DEFAULT_TABLE).get(this.getKey(key, domainName));
            return this.getValue(result);
        } catch (e) {
            console.warn(e);
            return undefined;
        }
    }

    getBulk(keys: TypeKey[], domain: string | null): Promise<Array<any> | undefined> {
        let domainName = this.getDomainName(domain);
        return this.openDb().then(() => {
            return this.db
                .table(DEFAULT_TABLE)
                .bulkGet(keys.map((key) => this.getKey(key, domainName)))
                .then((results) => {
                    if (results === undefined) {
                        return undefined;
                    }
                    return results.map((item) => this.getValue(item));
                });
        });
    }

    createValue(value: any, domain: string, key: TypeKey): Object {
        return { value, domain, key: key.toString() };
    }

    getValue(item: Object | undefined): any | undefined {
        if (item === undefined) {
            return undefined;
        }
        return item.value;
    }

    set(key: TypeKey, value: any, domain: string | null): Promise<TypeKey | undefined> {
        let domainName = this.getDomainName(domain);
        return this.openDb().then(() => {
            const keyNew = this.getKey(key, domainName);
            return this.db
                .table(DEFAULT_TABLE)
                .put(this.createValue(value, domainName, keyNew), keyNew)
                .then(() => {
                    return key;
                })
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
        });
    }

    setBulk(keys: TypeKey[], items: any[], domain: string | null): Promise<Array<any> | undefined> {
        let domainName = this.getDomainName(domain);
        return this.openDb().then(() => {
            let values: Array<any> = [];
            for (let i = 0; i < keys.length; i++) {
                let key = this.getKey(keys[i], domainName);
                values.push(this.createValue(items[i], domainName, key));
            }
            return this.db
                .table(DEFAULT_TABLE)
                .bulkPut(values)
                .then(() => {
                    return keys;
                })
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
        });
    }
}
