import { withTranslation, WithTranslation } from 'react-i18next';
import { Column } from '@devexpress/dx-react-grid';
import '../style.css';
import { DataTableColumn, DataTableProps, DataTableState } from '../../DataTable/types';
import {
    Action,
    DataExchangeLog,
    dataExchangeManager,
    DataExchangeSession,
    LogStatus,
} from '../../../service/DataExchangeManager';
import DataTable from '../../DataTable/DataTable';
import { IDataSource, TYPE_STRING, TYPE_TEXT_ARRAY, UserData } from '../../../service/types';
import { Link } from '@material-ui/core';
import RecordView from '../../RecordView';
import viewRecordManager from '../../../service/ViewRecordManager';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import moment from 'moment';
import { DATETIME_PICKER_FORMAT, utcToUserTimezone } from '../../../utils';
import dsManagerFactory from '../../../service/DsManager';
import { Filter } from '../../utils/tableFilter';

interface CurrentProps extends DataTableProps, WithTranslation {
    accountId: number;
    user: UserData;
    session: DataExchangeSession;
    filters?: Filter[];
}
interface CurrentState extends DataTableState<DataExchangeLog> {
    viewRecord: boolean;
}

class DataExchangeLogs extends DataTable<DataExchangeLog, CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            ...this.state,
            viewRecord: false,
        };

        this.filters = props.filters || [];
        this.sorting = [
            {
                columnName: 'createdAt',
                direction: 'desc',
            },
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        dispatcher.subscribe(events.EVENT_VIEW_RECORD, this, () => {
            this.setState({ viewRecord: true });
        });
    }

    buildStructure(fields: DataTableColumn<DataExchangeLog>[]) {
        const structure: any = super.buildStructure(fields);
        structure.exts = [
            { columnName: 'recordId', width: 250 },
            { columnName: 'entityId', width: 250, wordWrapEnabled: true },
            { columnName: 'recordName', width: 250, wordWrapEnabled: true },
            { columnName: 'action', align: 'center', width: 130 },
            { columnName: 'data', wordWrapEnabled: true },
            { columnName: 'status', align: 'center', width: 130 },
            { columnName: 'statusMessage', wordWrapEnabled: true },
        ];
        structure.noSortingColumns = structure.noSortingColumns.concat([
            { columnName: 'entityId', sortingEnabled: false },
            { columnName: 'recordName', sortingEnabled: false },
            { columnName: 'data', sortingEnabled: false },
        ]);
        structure.columnTitles = structure.columns.filter((c: Column) => c.title).map((c: Column) => c.title);
        return structure;
    }

    getFields() {
        const columns: DataTableColumn<DataExchangeLog>[] = [
            {
                columnName: 'recordId',
                type: TYPE_STRING,
                title: this.props.t('data_exchange_log.columns.record_id'),
                getCellValue: (row) => (
                    <Link component="button" variant="button" onClick={this.openViewRecord.bind(this, row)}>
                        {row.recordId || ''}
                    </Link>
                ),
            },
            {
                columnName: 'entityId',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_log.columns.entity_name'),
                picklist: this.getEntitiesPicklist(),
                getCellValue: (row) => row.entityName,
            },
            {
                columnName: 'recordName',
                title: this.props.t('data_exchange_log.columns.record_name'),
            },
            {
                columnName: 'action',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_log.columns.action'),
                picklist: [
                    { value: Action.Created, label: this.props.t('data_exchange_log.action.created') },
                    { value: Action.Updated, label: this.props.t('data_exchange_log.action.updated') },
                    { value: Action.Deleted, label: this.props.t('data_exchange_log.action.deleted') },
                ],
                getCellValue: (row) => {
                    switch (row.action) {
                        case Action.Created:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('data_exchange_log.action.created')}
                                </span>
                            );
                        case Action.Updated:
                            return (
                                <span className="log-badge log-badge-warning">
                                    {this.props.t('data_exchange_log.action.updated')}
                                </span>
                            );
                        case Action.Deleted:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_log.action.deleted')}
                                </span>
                            );
                    }
                },
            },
            {
                columnName: 'data',
                title: this.props.t('data_exchange_log.columns.data'),
                getCellValue: (row) => {
                    const result = [];
                    for (const field of Object.keys(row.data)) {
                        result.push(this.renderFieldChange(row.action, field, row.data[field]));
                    }
                    return result.map((item) => item);
                },
            },
            {
                columnName: 'status',
                type: TYPE_TEXT_ARRAY,
                title: this.props.t('data_exchange_log.columns.status'),
                picklist: [
                    { value: LogStatus.Success, label: this.props.t('data_exchange_log.status.success') },
                    { value: LogStatus.Fail, label: this.props.t('data_exchange_log.status.fail') },
                ],
                getCellValue: (row) => {
                    switch (row.status) {
                        case LogStatus.Success:
                            return (
                                <span className="log-badge log-badge-success">
                                    {this.props.t('data_exchange_log.status.success')}
                                </span>
                            );
                        case LogStatus.Fail:
                            return (
                                <span className="log-badge log-badge-error">
                                    {this.props.t('data_exchange_log.status.fail')}
                                </span>
                            );
                    }
                },
            },
            {
                columnName: 'statusMessage',
                type: TYPE_STRING,
                title: this.props.t('data_exchange_log.columns.status_message'),
            },
        ];
        return Promise.resolve(columns);
    }

    requestData(ignorePage = false, _parentTimer = null) {
        return dataExchangeManager.getSessionLogs(
            this.props.accountId,
            this.props.session,
            this.getFilters(),
            this.sorting,
            ignorePage ? 1 : this.state.pagination.current + 1,
            this.state.pagination.size,
        );
    }

    getEntitiesPicklist = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        return manager.list().then((dataSources: IDataSource[]) => {
            const picklist = [];
            for (const dataSource of dataSources) {
                for (const entityCounter of dataSource.entityCounters) {
                    picklist.push({
                        value: entityCounter.entity.id,
                        label: dataSource.name + ' > ' + entityCounter.entity.label,
                    });
                }
            }
            return picklist;
        });
    };

    openViewRecord = (row: DataExchangeLog) => {
        viewRecordManager.viewRecord(row.entityId, row.recordId);
    };

    closeViewRecord = () => {
        this.setState({ viewRecord: false });
    };

    stringifyFieldValue(value: any): string {
        if (typeof value === 'string') {
            return value;
        }
        if (typeof value === 'boolean') {
            return value ? this.props.t('boolean.true') : this.props.t('boolean.false');
        }
        if (value?.date !== undefined) {
            return value.date;
        }
        if (Array.isArray(value)) {
            return value.join(', ');
        }

        return value;
    }

    renderFieldChange = (action: Action, field: string, changes: { new: any; old?: any }) => {
        const newValue = this.stringifyFieldValue(changes.new);
        const oldValue = this.stringifyFieldValue(changes.old);
        switch (action) {
            case Action.Created:
                return (
                    <div key={field}>
                        <b>{field}: </b>
                        {newValue}
                    </div>
                );
            case Action.Updated:
                return (
                    <div key={field}>
                        <b>{field}: </b>
                        {oldValue}
                        <i className="fas fa-long-arrow-alt-right" style={{ margin: '0 5px' }} />
                        {newValue}
                    </div>
                );
            case Action.Deleted:
                return null;
        }
    };

    render() {
        const { session } = this.props;

        const finishedAt = session.finishedAt
            ? moment(utcToUserTimezone(session.finishedAt, this.props.user)).format(DATETIME_PICKER_FORMAT)
            : null;
        let modifiedBy = this.props.t('data_exchange_session.event.' + this.props.session.event);
        if (session.userName) {
            modifiedBy += ' / ' + session.userName;
        }
        const title = this.props.t('data_exchange_log.modal.sub_title', { finishedAt, modifiedBy });
        return (
            <div className="log">
                <div style={{ marginBottom: 20 }}>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                </div>

                {super.render()}

                <RecordView
                    accountId={this.props.accountId}
                    open={this.state.viewRecord}
                    onClose={this.closeViewRecord}
                    onFindOnMap={() => {}}
                    breakpoint="sm"
                />
            </div>
        );
    }
}

export default withTranslation()(DataExchangeLogs);
