import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { adapterManager } from '../../service/AdapterManager';
import AdapterSettingsField from './AdapterSettingsField';
import { withTranslation } from 'react-i18next';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';
import { userManager } from '../../service/UserManager';
import { GEOCODER_SERVICES } from '../../references/geoFields';

const LEAVE = 'leave';
const RESET = 'reset';

class GeneralPropertiesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            name: null,
            geocoder: null,
            resetRecordsGeocoding: LEAVE,
            adapter: null,
            settings: null,
        };

        this.state = { ...this.state, ...this.constructor.withDataSource(props.dataSource) };
    }

    componentDidMount() {
        if (this.props.dataSource) {
            this.connectToAdapter();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataSource !== this.props.dataSource) {
            this.setState(this.constructor.withDataSource(this.props.dataSource), () => this.connectToAdapter());
        }
    }

    static withDataSource(dataSource) {
        return {
            name: dataSource.name,
            geocoder: dataSource.geocoder,
            settings: dataSource.settings,
        };
    }

    connectToAdapter = () => {
        const { dataSource, onError } = this.props;
        if (!dataSource.adapterId && !dataSource.adapterUrl) {
            return Promise.reject('');
        }

        const currentSettings = { ...this.state.settings };

        const promise = dataSource.adapterId
            ? adapterManager.forId(dataSource.adapterId)
            : adapterManager.forUrl(dataSource.adapterUrl);

        return promise
            .then((adapter) => {
                this.setState({
                    adapter: adapter,
                    settings: adapterManager.constructor.initAdapterSettings(adapter.settings, currentSettings),
                });
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);
            });
    };

    submit() {
        const { t, onLoading, onSuccess, onError, dsManager } = this.props;

        this.setState({
            errors: new Map(),
        });

        // validate
        if (!this.state.name) {
            onError && onError(t('validation_errors'));
            const errors = new Map();
            errors.set('name', t('data_source_form.general_properties_form.errors.name'));
            this.setState({
                errors,
            });
            return;
        }

        onLoading && onLoading();

        const dataSource = { ...this.props.dataSource };
        dataSource.name = this.state.name.trim();
        dataSource.geocoder = this.state.geocoder;
        dataSource.settings = this.state.settings;

        dsManager
            .save(dataSource, false, this.state.resetRecordsGeocoding === RESET)
            .then(() => {
                onSuccess && onSuccess();
            })
            .catch((error) => {
                const { message, details } = getMessageAndDetails(error);
                onError && onError(message, details);
            });
    }

    quiet() {
        this.setState({
            errors: new Map(),
        });
    }

    handleAdapterSettingChange = (name, value) => {
        this.setState((state) => {
            state.settings[name] = value;
            return state;
        });
    };

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
        });
    };

    handleGeocoderChange = (e) => {
        this.setState({
            geocoder: e.target.value || null,
        });
    };

    handleResetRecordsGeocodingChange = (e) => {
        this.setState({
            resetRecordsGeocoding: e.target.value,
        });
    };

    renderSuperAdminSection = () => {
        if (!userManager.isRoleSuperAdmin()) {
            return null;
        }

        const { t } = this.props;
        return (
            <>
                <Typography style={{ marginTop: 20 }} variant="h6">
                    {t('billing.billing_panel.visible_only_super_admin')}
                </Typography>
                <FormControl fullWidth error={this.state.errors.has('geocoder')}>
                    <InputLabel shrink>{t('data_source_form.general_properties_form.geocoder.label')}</InputLabel>
                    <Select
                        value={this.state.geocoder || ''}
                        onChange={this.handleGeocoderChange}
                        displayEmpty
                        data-testid="data_source_form.general_properties_form.geocoder"
                    >
                        <MenuItem
                            key=""
                            value=""
                            data-testid="data_source_form.general_properties_form.geocoder.selection.default"
                        >
                            {t('data_source_form.general_properties_form.geocoder.selection.default')}
                        </MenuItem>
                        {Object.values(GEOCODER_SERVICES).map((service) => (
                            <MenuItem key={service} value={service}>
                                {t('data_source_form.general_properties_form.geocoder.selection.' + service)}
                            </MenuItem>
                        ))}
                    </Select>
                    {this.state.errors.has('geocoder') && (
                        <FormHelperText>{this.state.errors.get('name') || ''}</FormHelperText>
                    )}
                </FormControl>
                <FormControl margin="dense">
                    <RadioGroup
                        name="resetRecordsGeocoding"
                        value={this.state.resetRecordsGeocoding}
                        onChange={this.handleResetRecordsGeocodingChange}
                        row
                    >
                        <FormControlLabel
                            value={LEAVE}
                            control={<Radio color="primary" />}
                            label={t('data_source_form.general_properties_form.geocoder.leave_records_geocoder')}
                        />
                        <FormControlLabel
                            value={RESET}
                            control={<Radio color="primary" />}
                            label={t('data_source_form.general_properties_form.geocoder.set_records_geocoder')}
                        />
                    </RadioGroup>
                </FormControl>
            </>
        );
    };

    render() {
        const { t } = this.props;
        const { adapter, settings } = this.state;
        return (
            <div className="c-data-source-general-form">
                <TextField
                    label={t('data_source_form.general_properties_form.text_field.label')}
                    data-testid="data_source_form.general_properties_form.text_field"
                    placeholder={t('data_source_form.general_properties_form.text_field.placeholder')}
                    fullWidth
                    margin="dense"
                    value={this.state.name || ''}
                    helperText={this.state.errors.get('name') || ''}
                    error={this.state.errors.has('name')}
                    InputProps={{ disableUnderline: false }}
                    onChange={this.handleNameChange}
                    autoFocus
                />
                {this.renderSuperAdminSection()}
                {adapter &&
                    !!adapter.settings &&
                    adapter.settings.filter((setting) => setting.type !== 'system').length > 0 && (
                        <div style={{ marginTop: 20 }}>
                            <Typography variant="h6">
                                {t('data_source_form.general_properties_form.specific_settings')}
                            </Typography>
                            {adapter.settings &&
                                adapter.settings.map((parameter, index) => {
                                    const value =
                                        settings && settings.hasOwnProperty(parameter.name)
                                            ? settings[parameter.name]
                                            : null;
                                    const autoFocus = index === 0;

                                    return (
                                        <div style={{ marginTop: 20 }} key={parameter.name}>
                                            <AdapterSettingsField
                                                autoFocus={autoFocus}
                                                definition={parameter}
                                                value={value}
                                                error={this.state.errors.get('adp_' + parameter.name)}
                                                onChange={this.handleAdapterSettingChange}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    )}
            </div>
        );
    }
}

GeneralPropertiesForm.propTypes = {
    dataSource: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onLoading: PropTypes.func,
    dsManager: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(GeneralPropertiesForm);
