import React from 'react';
import './style.css';
import PropTypes from 'prop-types';

class SharedMapBlocked extends React.PureComponent {
    render() {
        return (
            <div className="c-map-blocked">
                <img src="/logo.png" alt="" className="c-logo" />
                <h1 className="text">{this.props.message}</h1>
            </div>
        );
    }
}

SharedMapBlocked.propTypes = {
    message: PropTypes.string.isRequired,
};

export default SharedMapBlocked;
