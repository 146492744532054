import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import './style.css';
import { DsEntityDummyProps } from './types';

class DsEntityDummy extends React.PureComponent<DsEntityDummyProps> {
    render() {
        const { label, apiName } = this.props;

        return (
            <Accordion className="c-ds-entity">
                <AccordionSummary
                    disabled
                    expandIcon={<ExpandMore />}
                    IconButtonProps={{ disabled: true }}
                    style={{ padding: '0 24px 0 12px', width: '100%' }}
                >
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item sm={4}>
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <div style={{ padding: '12px' }}>
                                        {label} <span className="api-name">{apiName}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}></AccordionDetails>
            </Accordion>
        );
    }
}

export default DsEntityDummy;
