import React from 'react';
import clsx from 'clsx';
import MUITabs, { TabsTypeMap } from '@material-ui/core/Tabs';
import MUITab, { TabProps as MUITabProps } from '@material-ui/core/Tab';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { OverrideProps } from '@material-ui/core/OverridableComponent';

const useTabsStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: 'auto',
        paddingInline: '24px',
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    indicator: {
        backgroundColor: `${theme.palette.button.primary.light}`,
    },
}));

const useTabStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '8px 24px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        color: theme.palette.button.grey.dark,
        opacity: 1,
    },
    selected: {
        color: `${theme.palette.button.primary.main}`,
    },
}));

type TabsProps = OverrideProps<TabsTypeMap, any> & { tabs: MUITabProps[] };
type TabProps = MUITabProps & {};

export const Tab: React.FC<TabProps> = ({ className, ...props }) => {
    const classes = useTabStyles();

    return <MUITab classes={classes} className={clsx(className)} {...props} />;
};

export const Tabs: React.FC<TabsProps> = ({ tabs, className, children, ...props }) => {
    const classes = useTabsStyles();

    return (
        <MUITabs {...props} classes={classes} className={clsx(className)}>
            {children || tabs.map((tab) => <Tab {...tab} />)}
        </MUITabs>
    );
};
