import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import mapStateManagerFactory from './MapStateManagerFactory';
import mapSettingsManager from './MapSettingsManager';
import { userManager } from './UserManager';
import throttle from 'lodash/throttle';

class MapStorageManager extends BackendService {
    constructor() {
        super();

        this.save = throttle(this.save, 3000, { leading: true, trailing: true });
    }

    save() {
        if (!userManager.isModificationAllowed()) {
            return;
        }
        const stateManager = mapStateManagerFactory.getManager();
        const data = mapSettingsManager.getSettings();
        data.mapState = stateManager.getSettings();
        delete data.mapState._sharedMapHash;
        // response is ignored so concurrent sessions does not interfere
        this.requestApi(reverse(apiRoutes.mapSettings), 'PUT', data);
    }
}

export default new MapStorageManager();
