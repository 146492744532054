import React from 'react';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import './../style.css';
import AbstractForm from '../AbstractForm/AbstractForm';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValid()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    render() {
        const { currentAction, errors } = this.state;
        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.delete_record.entity.modal.title')}
                    onClose={this.props.onCancel}
                    open={!currentAction.callContext.isValid()}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.delete_record.name')}
                        data-testid="workflow_actions.form.delete_record.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.delete_record.api_name')}
                        data-testid="workflow_actions.form.delete_record.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect(currentAction, errors)}

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="reevaluate"
                                checked={currentAction.reevaluate}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.form.delete_record.reevaluate')}
                    />
                </form>
            </React.Fragment>
        );
    }
}

export { Form as DeleteRecordForm };
