import { userManager } from '../UserManager';
import { STORAGE_KEY_PREFIX } from '../../components/types';

class TurnOnTrackingPopupManager {
    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.TURN_ON_TRACKING_POPUP_WAS_SHOWN + userManager.getCurrentUser().id;
    }

    wasPopupShown = () => {
        return !!window.localStorage.getItem(this.getStorageKey());
    };

    rememberPopupWasShown = () => {
        window.localStorage.setItem(this.getStorageKey(), 'was');
    };

    forgetPopupWasShown = () => {
        window.localStorage.removeItem(this.getStorageKey());
    };
}

export default new TurnOnTrackingPopupManager();
