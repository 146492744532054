import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
    value: string | number;
    name: string;
    label: string;
    length: null | number;
    error?: string;
    onChangeText: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    disabled: boolean;
    autoFocus: boolean;
}

class DefaultField extends React.PureComponent<Props> {
    render() {
        const { value, name, label, error, disabled, length, autoFocus, onChangeText } = this.props;

        return (
            <TextField
                name={name}
                label={label}
                margin="dense"
                value={value}
                error={!!error}
                InputProps={{ disableUnderline: false }}
                inputProps={{ maxLength: length }}
                onChange={onChangeText}
                fullWidth
                autoFocus={autoFocus}
                disabled={disabled}
            />
        );
    }
}

export default DefaultField;
