import { logDebug } from '../utils';

class Dispatcher {
    constructor() {
        this.listeners = new Map();
    }

    subscribe(eventType, tag, handler) {
        let eventTypes = eventType;
        if (!Array.isArray(eventType)) {
            eventTypes = [eventType];
        }
        for (eventType of eventTypes) {
            if (!this.listeners.has(eventType)) {
                this.listeners.set(eventType, new Map());
            }
            const eventTypeListeners = this.listeners.get(eventType);
            const registeredHandler = eventTypeListeners.get(tag);
            if (registeredHandler !== undefined && registeredHandler !== handler) {
                console.warn('Listener was overridden', eventType, tag?.constructor?.name ?? typeof tag);
            }

            this.listeners.get(eventType).set(tag, handler);
        }
    }

    unsubscribe(eventType, tag) {
        let eventTypes = eventType;
        if (!Array.isArray(eventType)) {
            eventTypes = [eventType];
        }
        for (eventType of eventTypes) {
            if (this.listeners.has(eventType) && this.listeners.get(eventType).has(tag)) {
                this.listeners.get(eventType).delete(tag);
            }
        }
    }

    unsubscribeFromAllEvents(tag) {
        for (let handlers of this.listeners.values()) {
            handlers.delete(tag);
        }
    }

    dispatch(eventType, ...data) {
        logDebug('dispatch', eventType, data);
        if (this.listeners.has(eventType)) {
            this.listeners.get(eventType).forEach((handler) => {
                handler(...data, eventType);
            });
        }
    }
}

export default new Dispatcher();
