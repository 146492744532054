import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

enum HasCrmAccountValue {
    YES = 'yes',
    NO = 'no',
}

interface Props extends WithTranslation {
    value: boolean | null;
    crmName: string;
    errorMessage: string | null;

    onChange(value: boolean): void;
}

class HasCrmAccountControl extends React.PureComponent<Props> {
    render() {
        const { t, value, crmName, errorMessage } = this.props;
        const label = t('review.does_this_user_have_an_account_in_crm.label', {
            crmName,
        });

        const hasError = errorMessage !== null;
        const radioGroupValue = value !== null ? (value ? HasCrmAccountValue.YES : HasCrmAccountValue.NO) : null;

        return (
            <FormControl fullWidth={true} error={hasError}>
                <FormLabel id="has-crm-account-label">{label}</FormLabel>
                <RadioGroup
                    value={radioGroupValue}
                    row
                    aria-labelledby="has-crm-account-label"
                    name="has-crm-account"
                    onChange={this.handleChange}
                >
                    <FormControlLabel value={HasCrmAccountValue.YES} control={<Radio />} label={t('yes')} />
                    <FormControlLabel value={HasCrmAccountValue.NO} control={<Radio />} label={t('no')} />
                </RadioGroup>
                {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        );
    }

    private handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this.props.onChange(value === HasCrmAccountValue.YES);
    };
}

export default withTranslation()(HasCrmAccountControl);
