import { makeAutoObservable, toJS } from 'mobx';
import { MapNavigationOption } from 'service/types';

export interface TravelingPreferencesConfig {
    mapNavigationOption: MapNavigationOption | null;
}

export const defaultTravelingPreferencesConfig: Readonly<TravelingPreferencesConfig> = {
    mapNavigationOption: null,
};

class TravelingPreferences {
    private _initializationCompleted: boolean;
    private config: TravelingPreferencesConfig;

    constructor() {
        makeAutoObservable(this);
        this.config = { ...defaultTravelingPreferencesConfig };
        this._initializationCompleted = false;
    }

    loadFromStorage(config: TravelingPreferencesConfig) {
        if (!this._initializationCompleted) {
            this._initializationCompleted = true;
        }
        this.config = config;
    }

    get initializationCompleted(): boolean {
        return this._initializationCompleted;
    }

    get mapNavigationOption(): MapNavigationOption | null {
        return this.config.mapNavigationOption;
    }

    setMapNavigationOption(mapNavigationOption: MapNavigationOption) {
        this.config.mapNavigationOption = mapNavigationOption;
    }

    reset() {
        this._initializationCompleted = false;
        this.config = { ...defaultTravelingPreferencesConfig };
    }

    toArray(): TravelingPreferencesConfig {
        const { mapNavigationOption } = this.config;

        return toJS({
            mapNavigationOption,
        });
    }
}

export default TravelingPreferences;
