import React from 'react';
import qs from 'qs';
import SsoNoAccessError from './SsoNoAccessError';
import SsoRequestError from './SsoRequestError';
import SsoNotAdminError from './SsoNotAdminError';
import NoMoreUsersError from './SsoNoMoreUsersError';
import dispatcher from '../../service/dispatcher';
import events from '../../events';

export default class ErrorNotificationFactory extends React.Component {
    getComponentConstructor = (type) => {
        switch (type) {
            case 'sso_no_role':
                return SsoNoAccessError;
            case 'sso_wrong_request':
                return SsoRequestError;
            case 'sso_not_admin':
                return SsoNotAdminError;
            case 'sso_no_more_users':
                return NoMoreUsersError;
            default:
                return null;
        }
    };

    componentDidMount() {
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (params['sso_status'] === 'login') {
            dispatcher.dispatch(events.EVENT_USER_SSO_LOGIN);
        }
    }

    render() {
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const type = params['error'] || null;
        const data = params['error_data'] || null;

        const componentConstructor = this.getComponentConstructor(type);
        if (!componentConstructor) {
            return this.props.children;
        }

        return <div style={{ padding: 16 }}>{React.createElement(componentConstructor, data)}</div>;
    }
}
