import React from 'react';
import { MapslyFile } from '../../../interfaces/file';
import AudioVideoModal from './AudioVideoModal';
import { enqueueSnackbarService } from '../../../service/MapPage';
import { fileManager } from '../../../service/File/FileManager';

interface AudioVideoWrapperProps {
    children: React.ReactElement;
    accountId: number;
    fileId: string;
    onClose?: () => void;
}

interface AudioVideoWrapperState {
    loading: boolean;
    currentFile: MapslyFile | null;
}

class AudioVideoWrapper extends React.PureComponent<AudioVideoWrapperProps, AudioVideoWrapperState> {
    constructor(props: AudioVideoWrapperProps) {
        super(props);

        this.state = {
            loading: false,
            currentFile: null,
        };
    }

    openModal = () => {
        this.setState({ loading: true });
        fileManager
            .get(this.props.accountId, this.props.fileId)
            .then((file) => {
                this.setState({ currentFile: file, loading: false });
            })
            .catch((error: Error) => {
                enqueueSnackbarService.sendErrorMessage(error.message);
                this.setState({ loading: false });
            });
    };

    closeModal = () => {
        this.setState({ currentFile: null });
        this.props.onClose && this.props.onClose();
    };

    render() {
        return (
            <React.Fragment>
                {React.cloneElement(this.props.children, { onClick: this.openModal, disabled: this.state.loading })}

                {this.state.currentFile && (
                    <AudioVideoModal
                        accountId={this.props.accountId}
                        file={this.state.currentFile}
                        onClose={this.closeModal}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default AudioVideoWrapper;
