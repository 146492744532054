import React from 'react';
import { withTranslation } from 'react-i18next';

class BooleanFormatter extends React.PureComponent {
    render() {
        const { value } = this.props;
        return value === true || value === 1 || value === '1' ? this.props.t('yes') : this.props.t('no');
    }
}

export default withTranslation()(BooleanFormatter);
