import React from 'react';
import './style.css';
import { parseToWebLinkProps, parseWebLinkToText, parseWebLinkToUrl, removeProtocol } from '../utils/webLinkParser';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { Link, Tooltip } from '@material-ui/core';

export const ICON_POSITION_LEFT = 'left';
export const ICON_POSITION_RIGHT = 'right';

interface WebLinkProps {
    linkClassName?: string;
    webLink: string;
    isTypeSpan?: boolean;
    iconPosition?: 'left' | 'right';
    underline?: 'none' | 'hover' | 'always';
    demo?: boolean;
}

class WebLink extends React.PureComponent<WebLinkProps> {
    static defaultProps = {
        isTypeSpan: false,
        iconPosition: ICON_POSITION_LEFT,
        underline: 'always',
        demo: false,
    };

    handleOpenLinkInIframe = (webLinkProps: any) => {
        dispatcher.dispatch(events.EVENT_OPENING_WEB_LINK_IFRAME, webLinkProps);
    };

    buildBodyLink = (text: string, isExternal = true) => {
        return (
            <React.Fragment>
                {isExternal && this.props.iconPosition === ICON_POSITION_LEFT && (
                    <i className="fas fa-external-link left" />
                )}
                <span>{text}</span>
                {isExternal && this.props.iconPosition === ICON_POSITION_RIGHT && (
                    <i className="fas fa-external-link right" />
                )}
            </React.Fragment>
        );
    };

    render() {
        if (!this.props.webLink) {
            return null;
        }

        let url = parseWebLinkToUrl(this.props.webLink);
        if (this.props.isTypeSpan) {
            url = removeProtocol(url);
        }

        if (this.props.isTypeSpan) {
            return <span className="web-link">{url}</span>;
        }

        const webLinkProps = parseToWebLinkProps(this.props.webLink);
        const openInIframe = webLinkProps !== null && webLinkProps.openMethod === 'iframe';
        const text = parseWebLinkToText(this.props.webLink);

        if (openInIframe) {
            return (
                <span
                    className="web-link"
                    onClick={(event) => {
                        this.handleOpenLinkInIframe(webLinkProps);
                        event.stopPropagation();
                    }}
                >
                    {this.buildBodyLink(text, false)}
                </span>
            );
        }

        return (
            <Tooltip title={text}>
                <Link
                    className="web-link"
                    href={this.props.demo ? undefined : url}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={(e: any) => e.stopPropagation()}
                    underline={this.props.underline}
                >
                    <span className={this.props.linkClassName}>{this.buildBodyLink(text)}</span>
                    {this.props.children}
                </Link>
            </Tooltip>
        );
    }
}

export default WebLink;
