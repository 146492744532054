import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Badge from '../Badge';
import { EXCEPTION_TABS, filterAndSortWorkingHoursExceptions } from '../UsersWorkingHourExceptionsDialog/helper';
import { workingHoursExceptionsDialogManager } from '../../service/UserWorkingHoursExceptions/WorkingHoursExceptionsDialogManager';
import { User } from '../../interfaces/user';

interface State {}

interface Props extends WithTranslation {
    user: User;
    onClick: () => void;
}

class UsersWorkingHourExceptionsButton extends React.PureComponent<Props, State> {
    private handleWorkingHourExceptionsClick = () => {
        workingHoursExceptionsDialogManager.openDialog(this.props.user);
        this.props.onClick();
    };

    render() {
        const { t, user } = this.props;

        return (
            <>
                <div style={{ marginTop: 10 }}>
                    <Badge
                        badgeContent={
                            filterAndSortWorkingHoursExceptions(
                                EXCEPTION_TABS.TAB_CURRENT_AND_FUTURE,
                                user.routingPreferences.workingHoursExceptions,
                            ).length
                        }
                        color="primary"
                    >
                        <Button color="primary" variant="contained" onClick={this.handleWorkingHourExceptionsClick}>
                            {t('route_editor.modal.routing_preferences.modal.exceptions_button')}
                        </Button>
                    </Badge>
                </div>
            </>
        );
    }
}

export default withTranslation('translations')(UsersWorkingHourExceptionsButton);
