import React from 'react';
import PureFormDialog from 'components/PureFormDialog';
import './style.css';
import Avatar from '@material-ui/core/Avatar';
import { Box, CircularProgress, DialogActions, Grid, Icon, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { fileManager } from '../../../service/File/FileManager';
import { MapslyFile, FileType } from 'interfaces/file';
import AvatarCropper from './AvatarCropper';
import { User } from '../../../interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import clsx from 'clsx';
import LoadingButton from '../../LoadingButton';
import { avatarService } from '../../../service/Avatar/AvatarService';
import FileInputButton from '../../FileInput/FileInputButton';
import { FileButton } from '../../FileInput/FileView';
import { fileCompressor } from '../../FileInput/FileCompressor';

const THUMBNAIL_SIDE_SIZE = 80;

export enum AvatarEditMode {
    Show,
    Loading,
    Cropping,
}

interface Props extends WithTranslation {
    mode?: AvatarEditMode;
    user: User.User;
    onClose: () => any;
    showAvatarCondition?: boolean;
    onCloseAvatarCondition?: () => any;
}

interface State {
    mode: AvatarEditMode;
    image: string;
    resultImg: MapslyFile | null;
    loading: boolean;
    isRemoveMode: boolean;
}

class DialogAvatarEditor extends React.Component<Props, State> {
    state: State = {
        mode: this.props.mode || AvatarEditMode.Show,
        image: avatarService.getCurrentAvatar().url,
        resultImg: null,
        loading: false,
        isRemoveMode: false,
    };

    private handleOnChange = (files: MapslyFile[]) => {
        if (files.length <= 0) {
            return;
        }

        const mapslyFile = files[0];

        // @ts-ignore
        const blob: Blob = mapslyFile.blob;
        if (!blob) {
            return;
        }

        this.setState({
            image: window.URL.createObjectURL(blob),
            mode: AvatarEditMode.Cropping,
            resultImg: mapslyFile,
        });
    };

    private handleOnClickChangeAvatar = () => {
        this.setState({
            mode: AvatarEditMode.Loading,
        });
    };

    private handleSave = () => {
        const { user } = this.props;
        const { mode } = this.state;

        if (mode !== AvatarEditMode.Cropping) {
            return this.props.onClose();
        }

        this.setState({ loading: true });

        const finish = () => {
            this.setState({ loading: false });
            this.props.onClose();
        };

        const result = this.state.resultImg;
        if (!result) {
            return;
        }

        if (!this.state.resultImg || !this.state.resultImg.blob) {
            return finish();
        }

        const resBlob = this.state.resultImg.blob;

        setTimeout(() => {
            fileCompressor
                .compress(resBlob, { quality: 0.9 })
                .then((blob) => {
                    return Promise.resolve({ ...result, blob: blob });
                })
                .then((file) => this.createThumbnail(file))
                .then((compressed) => {
                    avatarService
                        .uploadAvatar(user, compressed)
                        .then(() => {
                            this.setState({
                                mode: AvatarEditMode.Show,
                                resultImg: null,
                                loading: false,
                                image: avatarService.getCurrentAvatar().url,
                            });
                        })
                        .finally(() => {
                            finish();
                        });
                });
        }, 1000);
    };

    private handleOnCrop = (cropped: HTMLCanvasElement | undefined) => {
        if (!cropped) {
            return;
        }

        cropped.toBlob((blob) => {
            if (!blob) {
                return;
            }

            const old = this.state.resultImg;
            if (!!old) {
                const result = fileManager.createFile(blob, old.name);
                this.setState({ resultImg: result });
            }
        });
    };

    private handleRemoveMode = () => {
        this.setState((prev) => ({ isRemoveMode: !prev.isRemoveMode }));
    };

    private handleRemovePicture = () => {
        const { user } = this.props;

        const finish = () => {
            this.setState({ loading: false });
            this.props.onClose();
        };
        avatarService
            .removeAvatar(user, avatarService.getCurrentAvatar().id || user.avatar?.fileID)
            .then(() => {
                this.setState({
                    mode: AvatarEditMode.Show,
                    resultImg: null,
                    loading: false,
                    image: avatarService.getCurrentAvatar().url,
                });
            })
            .finally(() => {
                finish();
            });
    };

    private renderActions = () => {
        const { t, showAvatarCondition, onCloseAvatarCondition } = this.props;
        const { loading, isRemoveMode } = this.state;

        if (isRemoveMode) {
            return (
                <DialogActions>
                    <Button name="cancelBtn" color={'secondary'} onClick={this.handleRemoveMode}>
                        {t('avatar.form.remove.cancel')}
                    </Button>
                    <Button
                        name="removeBtn"
                        color="primary"
                        onClick={this.handleRemovePicture}
                        data-testid="avatar.form.remove"
                    >
                        {t('avatar.form.remove.btn')}
                    </Button>
                </DialogActions>
            );
        }

        if (showAvatarCondition) {
            return (
                <DialogActions>
                    <Button name="cancelBtn" color={'secondary'} onClick={this.handleRemoveMode}>
                        {t('avatar.form.remove.btn')}
                    </Button>

                    <Button
                        name="changeAvatarBtn"
                        color="primary"
                        onClick={onCloseAvatarCondition}
                        data-testid="avatar.form.save"
                    >
                        {t('avatar.form.remove.change')}
                    </Button>
                </DialogActions>
            );
        }
        return (
            <DialogActions>
                <LoadingButton
                    name="changeAvatarBtn"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={this.handleSave}
                    data-testid="avatar.form.save"
                >
                    {t('button.save')}
                </LoadingButton>
            </DialogActions>
        );
    };
    render() {
        const { t, showAvatarCondition } = this.props;
        const { image, loading } = this.state;

        return (
            <PureFormDialog
                open={true}
                onClose={this.props.onClose}
                maxWidth="lg"
                title={t('avatar.form.title')}
                scroll={'paper'}
                actions={this.renderActions()}
            >
                {showAvatarCondition ? (
                    <Grid item className={clsx('avatar_form')}>
                        <Box className={'big_avatar_box'}>
                            <Avatar className="big_avatar" src={image} />
                        </Box>
                        {this.state.isRemoveMode && (
                            <>
                                <Typography variant="h6" align="center">
                                    {t('avatar.form.remove.title')}
                                </Typography>
                                <Typography variant="subtitle1" align="center">
                                    {t('avatar.form.remove.subtitle')}
                                </Typography>
                            </>
                        )}
                    </Grid>
                ) : (
                    <Grid
                        item
                        className={clsx('avatar_form', loading ? 'avatar_form--loading' : '')}
                        aria-disabled={loading}
                    >
                        {this.state.mode === AvatarEditMode.Show && (
                            <>
                                <Box className={'big_avatar_box'}>
                                    <Avatar className="big_avatar" src={image} />

                                    {this.state.loading && <CircularProgress className={'avatar_from__loader'} />}
                                </Box>

                                <Button
                                    variant="contained"
                                    color="default"
                                    fullWidth
                                    data-testid="avatar.change_avatar.btn"
                                    onClick={this.handleOnClickChangeAvatar}
                                >
                                    {t('avatar.form.change.btn')}
                                </Button>
                            </>
                        )}

                        {this.state.mode === AvatarEditMode.Loading && (
                            <>
                                <Grid item style={{ position: 'relative', marginTop: 20 }}>
                                    <FileInputButton
                                        onFileChange={this.handleOnChange}
                                        fileMeta={{ width: 200, height: 200 }}
                                        allowedTypes={[FileType.Image]}
                                        multiple={false}
                                    >
                                        <FileButton
                                            component="div"
                                            size={'medium'}
                                            className={'medium'}
                                            data-testid="file_input.file_collection.input_btn"
                                            disableFocusRipple
                                        >
                                            <div className={clsx('file-container', 'upload-btn')}>
                                                <Icon className="fas fa-upload" fontSize="large" color={'action'} />
                                            </div>
                                        </FileButton>
                                    </FileInputButton>
                                </Grid>
                            </>
                        )}
                        {this.state.mode === AvatarEditMode.Cropping && (
                            <AvatarCropper image={image} onCrop={this.handleOnCrop} />
                        )}
                    </Grid>
                )}
            </PureFormDialog>
        );
    }

    private createThumbnail(mapslyFile: MapslyFile): Promise<MapslyFile> {
        if (mapslyFile.blob && mapslyFile.type === FileType.Image) {
            return fileCompressor
                .compress(mapslyFile.blob, { width: THUMBNAIL_SIDE_SIZE, height: THUMBNAIL_SIDE_SIZE })
                .then((blob) => {
                    mapslyFile.thumbnailBlob = blob;
                    mapslyFile.thumbnailSize = blob.size;
                    return mapslyFile;
                });
        }

        return Promise.resolve(mapslyFile);
    }
}

export default withTranslation('translations')(DialogAvatarEditor);
