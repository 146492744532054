import React from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Bar } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, AiTokenUsage } from '../../../interfaces/account';
import { aiTokenUsageManager } from '../../../service/AiTokenUsageManager';
import { WithSnackbarProps } from 'notistack';

interface AiTokensDailyUsagesProps extends WithTranslation, WithSnackbarProps {
    account: Account;
    onClose: () => void;
}

interface AiTokensDailyUsagesState {
    aiTokenUsages: AiTokenUsage[];
    isLoading: boolean;
}

class AiTokensDailyUsagesDialog extends React.Component<AiTokensDailyUsagesProps, AiTokensDailyUsagesState> {
    constructor(props: AiTokensDailyUsagesProps) {
        super(props);
        this.state = {
            aiTokenUsages: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.loadUsages();
    }

    loadUsages() {
        const accountId = this.props.account.id;

        this.setState({
            isLoading: true,
        });

        aiTokenUsageManager
            .getList(accountId)
            .then((result: AiTokenUsage[]) => {
                this.setState({
                    aiTokenUsages: result,
                });
            })
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    private getChartOptions() {
        return {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {},
            },
            parsing: {
                xAxisKey: 'date',
                yAxisKey: 'counter',
            },
        };
    }

    private getChartDatasets() {
        return {
            datasets: [
                {
                    data: this.state.aiTokenUsages,
                    backgroundColor: '#3f51b5',
                },
            ],
        };
    }

    render() {
        const { t } = this.props;

        return (
            <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={this.props.onClose}>
                <DialogTitle>
                    <span style={{ paddingRight: 20 }}>
                        {t('billing.subscription_panel.ai_tokens_usage.daily_usage')}
                    </span>
                    <IconButton color="default" onClick={this.props.onClose} className="c-form-dialog__close">
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ width: '100%', minHeight: 100 }}>
                    {this.state.isLoading && (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {!this.state.isLoading && this.state.aiTokenUsages.length > 0 && (
                        <Paper>
                            <Bar
                                options={this.getChartOptions()}
                                data={this.getChartDatasets()}
                                width={500}
                                height={300}
                            />
                        </Paper>
                    )}
                    {!this.state.isLoading && !this.state.aiTokenUsages.length && (
                        <span>{t('billing.subscription_panel.ai_tokens_usage.no_statistic', { days: 90 })}</span>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(AiTokensDailyUsagesDialog);
