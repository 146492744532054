import React from 'react';
import { ClickAwayListener, InputAdornment } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';

import { Geo } from 'interfaces';
import AddressLookup from 'components/AddressLookup';
import { Bounds } from 'components/types';
import { TextField, IconButton } from 'components/UI';

import QuestionHint from '../../Hint/QuestionHint';

import '../style.css';

interface Props extends WithTranslation {
    bounds?: Bounds;
    label?: string | null;
    placeholder?: string;
    hint?: string;
    address: string | null;
    onPointFound?: (point: Geo.GeoLocationPoint) => void;
    onCancel?: () => void;
    autoFocus?: boolean;
    accountId?: number | undefined;
    hasError?: boolean;
    showReversed?: boolean;
    containerRef?: React.Ref<any>;
    disabled?: boolean;
    maxWidth?: number | undefined;
    inputRef?: React.Ref<Element>;
}

interface State {
    address: string;
    showSelects: boolean;
    showResults: boolean;
}

class ManualLocationInput extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const { address } = props;
        this.state = {
            address: address ?? '',
            showSelects: false,
            showResults: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.address !== this.props.address) {
            this.setState({ address: this.props.address ?? '' });
        }
    }

    private handleChangeShowSelect = (showSelects: boolean) => {
        this.setState({ showSelects });
    };

    private handleChangeShowResult = (showResults: boolean) => {
        this.setState({ showResults });
    };

    private handleChangeAddress = (address: string) => {
        this.setState({ address });
    };

    private handleSelectedAddress = (point: Geo.GeoLocationPoint | null) => {
        if (point === null) {
            return;
        }

        const { onCancel, onPointFound } = this.props;
        let address = '';
        if (onCancel && point.address) {
            address = point.address;
        }

        onPointFound && onPointFound(point);
        this.setState({
            showResults: false,
            showSelects: false,
            address: address,
        });
    };

    private handleCancel = () => {
        const { onCancel } = this.props;

        this.setState({
            address: '',
        });
        onCancel && onCancel();
    };

    private handleMenuClose = () => {
        const { address } = this.props;

        this.setState({
            showResults: false,
            showSelects: false,
            address: address ?? '',
        });
    };

    private getEndAdornment = () => {
        const { onCancel, disabled } = this.props;
        const { address } = this.state;
        if (!disabled && onCancel && address && address.length > 0) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="map-controls__search__clear"
                        aria-label="clear base address input"
                        onClick={this.handleCancel}
                        data-testid="manual_location_input.clear"
                        size="xsmall"
                        color="secondary"
                    >
                        <CancelIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        return null;
    };

    render() {
        const {
            t,
            bounds,
            label,
            autoFocus,
            accountId,
            placeholder,
            hasError,
            hint,
            containerRef,
            showReversed,
            disabled,
            maxWidth,
            inputRef,
        } = this.props;
        const { address, showSelects, showResults } = this.state;

        return (
            <ClickAwayListener onClickAway={this.handleMenuClose}>
                <AddressLookup
                    prospectingEnable={false}
                    showSelect={showSelects}
                    showResults={showResults}
                    mapBounds={bounds}
                    className="c-address-lookup-route"
                    resultAfterInput={true}
                    address={address}
                    onSelectedAddress={this.handleSelectedAddress}
                    onChangeAddress={this.handleChangeAddress}
                    onChangeShowSelect={this.handleChangeShowSelect}
                    onChangeShowResult={this.handleChangeShowResult}
                    accountId={accountId}
                    containerRef={containerRef}
                    showReversed={Boolean(showReversed)}
                    maxWidth={maxWidth}
                >
                    <TextField
                        disabled={disabled}
                        placeholder={placeholder ? placeholder : t('route_editor.address.text_field.placeholder')}
                        data-testid="route_editor.address.text_field"
                        label={label}
                        fullWidth
                        InputProps={{
                            disableUnderline: false,
                            endAdornment: this.getEndAdornment(),
                        }}
                        inputProps={{
                            ref: inputRef,
                        }}
                        autoFocus={autoFocus !== undefined ? autoFocus : false}
                        autoComplete="off"
                        error={!!hasError}
                    />
                    {hint && <QuestionHint style={{ marginTop: 22 }}>{hint}</QuestionHint>}
                </AddressLookup>
            </ClickAwayListener>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ManualLocationInput);
