import React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export enum ByteUnit {
    GB = 1000 * 1000 * 1000,
    MB = 1000 * 1000,
    KB = 1000,
    B = 1,
}

type CurrentProps = TextFieldProps & {
    children: React.ReactNode;
    value: string | number;
    unit?: ByteUnit;
};

class ByteField extends React.PureComponent<CurrentProps> {
    toBytes = (value: number, unit: ByteUnit) => {
        return value * unit;
    };

    toUnit = (value: number, unit: ByteUnit) => {
        return value / unit;
    };

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { onChange, unit = ByteUnit.GB } = this.props;
        if (!onChange) {
            return;
        }

        event.target.value = this.toBytes(+event.target.value, unit).toString();
        onChange(event);
    };

    render() {
        const { children, value, onChange, unit = ByteUnit.GB, ...rest } = this.props;

        return (
            <TextField value={this.toUnit(+value, unit)} onChange={this.handleChange} {...rest}>
                {children}
            </TextField>
        );
    }
}

export default ByteField;
