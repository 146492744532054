import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './style.css';

export default class TabPanel extends React.PureComponent {
    render() {
        return (
            <div
                role="tabpanel"
                hidden={this.props.value !== this.props.index}
                className={clsx('layers-views-tab-panel', this.props.className)}
            >
                {this.props.children}
            </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
