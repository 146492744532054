import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { FormulaEditorForm } from './FormulaEditorForm';
import PureFormDialog from '../PureFormDialog';
import { CallContext } from '../utils/CallContext';
import { FormBackdrop, FormActions } from '../PureFormDialog/Form';
import { formulaManager } from '../../service/FormulaManager';
import { withSnackbar } from 'notistack';
import LandingLink, { ARTICLE_FORMULA_EDITOR } from '../HelpLink/LandingLink';
import { isMac } from '../../utils';
import isMobile from 'is-mobile';

class FormulaEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formula: props.value,
            lastFunction: null,
            showValidationResult: false,
            validationErrorMessage: null,
            insertPicklistModal: false,
            insertFieldModal: false,
            insertFunctionModal: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({ formula: this.props.value });
        }
    }

    toggleInsertPicklistModal = () => {
        this.setState((state) => ({ insertPicklistModal: !state.insertPicklistModal }));
    };

    toggleInsertFieldModal = () => {
        this.setState((state) => ({ insertFieldModal: !state.insertFieldModal }));
    };

    toggleInsertFunctionModal = () => {
        this.setState((state) => ({ insertFunctionModal: !state.insertFunctionModal }));
    };

    renderFormulaEditorTitle = () => {
        const { modalTitle, modalDescription, picklist, callContext, options, hideFunctionsButton, t } = this.props;
        return (
            <Grid container justify="space-between" alignItems="center">
                <Grid item container xs spacing={1}>
                    <Grid item>{modalTitle || t('formula_editor.title')}</Grid>
                    <Grid item>
                        <LandingLink
                            useLeadingIcon
                            useTrailingIcon
                            article={ARTICLE_FORMULA_EDITOR}
                            style={{ fontSize: 16 }}
                        >
                            {t('help')}
                        </LandingLink>
                    </Grid>
                </Grid>
                <Grid item>
                    {Array.isArray(picklist) && picklist.length && (
                        <Button
                            className="link-to-route"
                            onClick={this.toggleInsertPicklistModal}
                            data-testid="formula_editor.insert_picklist_value"
                        >
                            {t('formula_editor.insert_picklist_value')}
                        </Button>
                    )}
                    {(callContext || options) && (
                        <Button
                            className="link-to-route"
                            onClick={this.toggleInsertFieldModal}
                            data-testid="formula_editor.insert_field"
                        >
                            {t('formula_editor.insert_field')}
                        </Button>
                    )}
                    {!hideFunctionsButton && (
                        <Button
                            className="link-to-route"
                            onClick={this.toggleInsertFunctionModal}
                            data-testid="formula_editor.insert_function"
                        >
                            {t('formula_editor.insert_function')}
                        </Button>
                    )}
                </Grid>
                {!!modalDescription && (
                    <Grid item xs={12}>
                        <Typography variant="caption" className="text-muted">
                            {modalDescription}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    };

    checkSyntax = () => {
        this.setState({ loading: true });

        this.doCheckSyntax()
            .then((response) => {
                if (response.valid) {
                    this.setState({ loading: false, showValidationResult: true, validationErrorMessage: null });
                } else {
                    this.setState({
                        loading: false,
                        showValidationResult: true,
                        validationErrorMessage: response.error,
                    });
                }
            })
            .catch((response) => {
                this.props.enqueueSnackbar(this.props.t('error_boundary.error_message'), { variant: 'error' });
                this.setState({ loading: false });
            });
    };

    handleSave = () => {
        this.setState({ loading: true });

        this.doCheckSyntax()
            .then((response) => {
                if (response.valid) {
                    this.props.onSave(this.state.formula, response);
                    this.setState({ loading: false, showValidationResult: false, validationErrorMessage: null });
                } else {
                    this.setState({
                        loading: false,
                        showValidationResult: true,
                        validationErrorMessage: response.error,
                    });
                }
            })
            .catch((response) => {
                this.props.enqueueSnackbar(this.props.t('error_boundary.error_message'), { variant: 'error' });
                this.setState({ loading: false });
            });
    };

    doCheckSyntax = () => {
        if (!this.state.formula) {
            return Promise.resolve({ valid: true });
        }
        return formulaManager.validate(this.state.formula, this.props.rawFormula);
    };

    handleChange = (formula, lastFunction, callback) => {
        this.setState({ formula, lastFunction }, callback);
    };

    handleKeyDown = (event) => {
        if ((event.which === 13 || event.key === 'Enter') && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            this.handleSave();
        }
    };

    renderSaveButtonHint = () => {
        if (isMobile()) {
            return null;
        }
        const key = isMac() ? <span>&#8984;</span> : <Typography variant="caption">Ctrl</Typography>;
        return (
            <Grid
                container
                style={{ marginLeft: 10, textTransform: 'none', fontSize: '1rem' }}
                alignItems="center"
                className="text-muted"
            >
                {key}
                <Typography variant="caption" style={{ margin: '0 3px' }}>
                    +
                </Typography>
                <span>&#8629;</span>
            </Grid>
        );
    };

    render() {
        return (
            <React.Fragment>
                <PureFormDialog
                    open={this.props.open}
                    title={this.renderFormulaEditorTitle()}
                    onClose={this.props.onCancel}
                    maxWidth="lg"
                    fullWidth
                    onKeyDown={this.handleKeyDown}
                >
                    <FormBackdrop loading={this.state.loading}>
                        <FormulaEditorForm
                            onChange={this.handleChange}
                            formula={this.state.formula}
                            lastFunction={this.state.lastFunction}
                            insertPicklistModal={this.state.insertPicklistModal}
                            onPicklistModalClose={this.toggleInsertPicklistModal}
                            insertFieldModal={this.state.insertFieldModal}
                            onFieldModalClose={this.toggleInsertFieldModal}
                            insertFunctionModal={this.state.insertFunctionModal}
                            onFunctionModalClose={this.toggleInsertFunctionModal}
                            onCheckSyntax={this.checkSyntax}
                            callContext={this.props.callContext}
                            options={this.props.options}
                            showValidationResult={this.state.showValidationResult}
                            validationErrorMessage={this.state.validationErrorMessage}
                            picklist={this.props.picklist}
                            multiPicklist={this.props.multiPicklist}
                            rawFormula={this.props.rawFormula}
                        />
                    </FormBackdrop>

                    <FormActions>
                        <Tooltip title={this.props.t('formula_editor.save.hint')}>
                            <Button
                                color="primary"
                                onClick={this.handleSave}
                                disabled={this.state.loading}
                                data-testid="formula_editor.button.save"
                            >
                                {this.props.t('button.save')}
                                {this.renderSaveButtonHint()}
                            </Button>
                        </Tooltip>
                    </FormActions>
                </PureFormDialog>
            </React.Fragment>
        );
    }
}

FormulaEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    rawFormula: PropTypes.bool.isRequired,
    callContext: PropTypes.instanceOf(CallContext),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    value: PropTypes.string,
    picklist: PropTypes.array,
    multiPicklist: PropTypes.bool,
    hideFunctionsButton: PropTypes.bool,
};

FormulaEditor.defaultProps = {
    rawFormula: false,
    multiPicklist: false,
    hideFunctionsButton: false,
};

const formulaEditor = withTranslation()(withSnackbar(FormulaEditor));
export { formulaEditor as FormulaEditor };
