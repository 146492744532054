import React from 'react';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { directActionManager } from '../../service/workflow_actions/DirectActionManager';
import GeoLocationManager from 'service/GeoLocationManager';
import { Popper, CircularProgress } from '@material-ui/core';
import i18n from '../../locales/i18n';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
const t = i18n.t.bind(i18n);

const SPINNER_SIZE = 100;

class WorkflowActionsProcessingSpinner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            retrievingLocation: GeoLocationManager.isLocationProcessing(),
            recordCountMismatch: null,
        };

        this.manager = directActionManager;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WORKFLOW_ACTIONS_RUNNING_CHANGED, this, (data = {}) => {
            const { runId, recordCountMismatch } = data;
            if (runId && Number.isInteger(parseInt(recordCountMismatch))) {
                this.setState({ recordCountMismatch: { runId, actualRecordCount: recordCountMismatch } });
                return;
            }
            this.forceUpdate();
        });
        dispatcher.subscribe(events.RETRIEVING_USER_LOCATION_TOGGLED, this, () => {
            this.setState(() => ({
                retrievingLocation: GeoLocationManager.isLocationProcessing(),
            }));
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    getBoundingClientRect = () => {
        return new DOMRect(window.innerWidth / 2, window.innerHeight / 2 - SPINNER_SIZE / 2, 0, 0);
    };

    forceRunButton = (runId) => {
        this.manager.forceRunButton(runId);
    };

    handleConfirmationOpen = (showConfirmation) => {
        if (showConfirmation) {
            return;
        }
        this.setState({ recordCountMismatch: null });
    };

    render() {
        const anchor = {
            clientHeight: window.innerHeight,
            clientWidth: window.innerWidth,

            getBoundingClientRect: this.getBoundingClientRect,
        };

        if (this.state.retrievingLocation) {
            return (
                <Popper style={{ zIndex: 100000, textAlign: 'center' }} open={true} anchorEl={anchor}>
                    <CircularProgress size={SPINNER_SIZE} />
                    <div>
                        <b>{t('automation.retrieving_your_location')}</b>
                    </div>
                </Popper>
            );
        }

        if (!this.manager.hasRunningActions() && this.state.recordCountMismatch) {
            const { runId, actualRecordCount } = this.state.recordCountMismatch;
            return (
                <DialogConfirmation
                    open
                    setOpen={this.handleConfirmationOpen}
                    onConfirm={this.forceRunButton.bind(this, runId)}
                    titleText={t('entity_data_table.confirmation.records_count_changed.title')}
                    yesBtnText={t('button.yes_proceed')}
                    noBtnText={t('button.cancel')}
                >
                    {t('entity_data_table.confirmation.records_count_changed.body', {
                        count: actualRecordCount,
                    })}
                </DialogConfirmation>
            );
        }

        return (
            this.manager.hasRunningActions() && (
                <Popper style={{ zIndex: 100000 }} open={true} anchorEl={anchor}>
                    <CircularProgress size={SPINNER_SIZE} />
                </Popper>
            )
        );
    }
}

export default WorkflowActionsProcessingSpinner;
