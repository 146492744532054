import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DataSourceWizard, { STAGES } from './DataSourceWizard';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingButton from '../LoadingButton';
import { withTranslation } from 'react-i18next';

class DataSourceDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            action: '',
            loading: false,
            ready: true,
            autoConnect: props.autoConnect,
        };
        this.dsWizard = React.createRef();
    }

    handleReadyStateChanged = (ready) => {
        this.setState({ ready }, () => {
            if (!ready || !this.state.autoConnect || this.props.stage !== STAGES.CONNECTION) {
                return;
            }

            this.setState({ autoConnect: false }, () => {
                this.handleNext();
            });
        });
    };

    handleClose = () => {
        this.setState({
            loading: false,
        });
        this.props.onClose && this.props.onClose();
    };

    handleStep = (num, caption, action, isLoading = false) => {
        this.setState({
            title: caption,
            action: action,
            loading: isLoading,
        });
    };

    handleNext = () => {
        this.dsWizard.current.next();
    };

    handleLoading = (isLoading) => {
        this.setState({
            loading: isLoading,
        });
    };

    render() {
        const { t, i18n, tReady, dataSource, stage, ...rest } = this.props;
        return (
            <Dialog {...rest} onClose={this.handleClose}>
                <DialogTitle className="c-form-dialog__title">
                    {this.state.title}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.handleClose}
                        className="c-form-dialog__close"
                        data-testid="data_source.dialog.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="c-form-dialog__content">
                    <DataSourceWizard
                        dataSource={dataSource}
                        stage={stage}
                        onStep={this.handleStep}
                        onLoading={this.handleLoading}
                        onFinished={this.handleClose}
                        onReadyStateChanged={this.handleReadyStateChanged}
                        ref={this.dsWizard}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={this.handleClose} data-testid="data_source.dialog.button.close">
                        {t('dialog.close')}
                    </Button>
                    <LoadingButton
                        color="primary"
                        name="submit"
                        onClick={this.handleNext}
                        loading={this.state.loading}
                        disabled={!this.state.ready}
                        data-testid="data_source.dialog.button.save"
                    >
                        {this.state.action || t('button.save')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

DataSourceDialog.propTypes = {
    dataSource: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    stage: PropTypes.number,
    autoConnect: PropTypes.bool,
};

DataSourceDialog.defaultProps = {
    stage: STAGES.CONNECTION,
};

export default withTranslation()(DataSourceDialog);
