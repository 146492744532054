interface Data {
    maxPoints: number;
    canAddPoints: number;
}

class RouteWaypointsLimitError extends Error {
    private readonly _data: Data;

    constructor(message: string, data: Data) {
        super(message);
        this._data = data;
    }

    get data(): Data {
        return this._data;
    }
}

export default RouteWaypointsLimitError;
