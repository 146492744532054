import BackendService from 'api/BackendService';
import { currentRouteManager, routingSessionManager } from 'service/MapPage';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from '../../dispatcher';
import events from '../../../events';
import { Api, Routing, User } from 'interfaces';
import { RouteDenormalizer } from 'service/Serializer';
import { userManager } from 'service/UserManager';

export class RouteLoader extends BackendService {
    private _areRoutesLoaded: boolean = false;
    private routes: Routing.Route.SimpleRoute[] = [];
    private readonly accountId: number;

    constructor(accountId: number) {
        super();
        this.accountId = accountId;
    }

    get areRoutesLoaded(): boolean {
        return this._areRoutesLoaded;
    }

    getRoutes(): Routing.Route.SimpleRoute[] {
        return this.routes;
    }

    async loadRoutes(force = false): Promise<Routing.Route.SimpleRoute[]> {
        if (!force && this.areRoutesLoaded) {
            return RouteLoader.getResolvedPromise(this.routes);
        }
        const url = reverse(apiRoutes.routes.routeSession.published.routes.list);
        const response: Api.Routing.Route.SimpleRouteApiResponse[] = await this.requestApi(url, 'GET');
        this._areRoutesLoaded = true;

        this.routes = RouteDenormalizer.denormalizeSimpleRoutes(response);
        return this.routes;
    }

    // TODO проверить, что возвращается user
    async getRouteById(id: string, force: boolean = false): Promise<Routing.Route.SimpleRoute> {
        if (!force) {
            for (let route of this.routes) {
                if (route.id === id) {
                    return RouteLoader.getResolvedPromise(route);
                }
            }
        }
        const url = reverse(apiRoutes.account.route, { accountId: this.accountId, routeId: id });
        const response: Api.Routing.Route.RouteApiResponse = await this.requestApi(url, 'GET');

        const route = RouteDenormalizer.denormalizeSimpleRoute(response);
        if (route.user.id === userManager.getCurrentUser().id) {
            this.routes.push(route);
        }
        return route;
    }

    updateCurrentRoute(name: string, user: User.User): void {
        currentRouteManager.setName(name);
        currentRouteManager.setUser(user);
        dispatcher.dispatch(events.CURRENT_ROUTE_NAME_AND_USER_UPDATED);
    }

    saveExistsRoute(routeId: number, data: Routing.Route.SaveExistsRouteData): Promise<Routing.Route.Route> {
        return this.loadRoutes()
            .then(() => {
                return this._saveExistsRoute(routeId, data);
            })
            .then((savedRoute) => {
                if (routingSessionManager.isShowLoadedSessionRoute) {
                    const route = routingSessionManager.currentSessionRoutes.find(
                        (route) => route.id === savedRoute.id,
                    );
                    if (route) {
                        route.name = savedRoute.name;
                        route.user = savedRoute.user;
                        route.dateStartAt = savedRoute.dateStartAt;
                    }
                }
                return savedRoute;
            });
    }

    deleteRoute(routeId: string): Promise<void> {
        return this.loadRoutes().then((routes) => {
            const route = routes.find((route) => route.id === routeId);
            if (!route) {
                throw new Error('Route to delete not found.');
            }
            return this._deleteRoute(route);
        });
    }

    private getAccountId(): number {
        return this.accountId;
    }

    private _saveExistsRoute(routeId: number, data: Routing.Route.SaveExistsRouteData): Promise<Routing.Route.Route> {
        const request: Api.Route.EditRouteRequest = { ...data };
        const url = reverse(apiRoutes.account.route, { accountId: this.getAccountId(), routeId });
        return this.requestApi(url, 'PUT', request).then((savedRouteResponse: Api.Routing.Route.RouteApiResponse) => {
            const savedRoute = RouteDenormalizer.denormalizeRoute(savedRouteResponse);

            for (let i = 0; i < this.routes.length; i++) {
                if (this.routes[i].id === savedRoute.id) {
                    this.routes[i] = savedRoute;
                    break;
                }
            }
            dispatcher.dispatch(events.EVENT_ROUTE_CHANGED, savedRoute);
            return savedRoute;
        });
    }

    private _deleteRoute(route: Routing.Route.SimpleRoute): Promise<void> {
        const deletedRoute = { ...route };
        return routingSessionManager.deletePublishedRoute(deletedRoute).catch((error: Error) => {
            dispatcher.dispatch(events.EVENT_ROUTE_NOT_DELETED, route, error);
        });
    }
}

class Factory {
    private readonly managers = new Map();

    getManager(userId: number, accountId: number) {
        if (this.managers.has(userId)) {
            return this.managers.get(userId);
        }
        const manager = new RouteLoader(accountId);
        this.managers.set(userId, manager);

        return manager;
    }
}

export default new Factory();
