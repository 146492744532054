import { LeafletContext } from 'react-leaflet';
import DesignRoutePointsLayer from './DesignRoutePointsLayer';
import { computed, makeObservable } from 'mobx';

class RoutePointsLayer {
    private designRouteLayer: DesignRoutePointsLayer;

    constructor() {
        this.designRouteLayer = new DesignRoutePointsLayer();

        makeObservable(this, {
            isReady: computed,
        });
    }

    createLayer(leaflet: LeafletContext): void {
        this.designRouteLayer.createLayer(leaflet);
    }

    removeLayer(leaflet: LeafletContext): void {
        this.designRouteLayer.removeLayer(leaflet);
    }

    get isReady(): boolean {
        return this.designRouteLayer.isReady;
    }
}

export default RoutePointsLayer;
