import { StorageIndexDB } from './StorageIndexDB';
import { StorageInterface } from './StorageInterface';
import { logDebug } from '../../utils';

class StorageManager {
    storageManager: StorageInterface | null = null;

    getManager(): Promise<StorageInterface> {
        logDebug('start');
        if (this.storageManager !== null) {
            return Promise.resolve(this.storageManager);
        }
        let manager: StorageInterface;
        manager = new StorageIndexDB();

        return manager.init().then(() => {
            this.storageManager = manager;
            return Promise.resolve(manager);
        });
    }
}

export default new StorageManager();
