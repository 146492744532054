import { AdapterId } from 'components/types';
import { SSO_REVIEW_SYSTEM_LINKS } from 'components/constants';
import { DataSource } from 'interfaces';

const excludedAdapters = new Set<AdapterId>([AdapterId.GOOGLESHEETS]);

class UserHasCrmAccountService {
    getPrimaryDataSource(dataSources: DataSource.SimpleDataSource[]): DataSource.SimpleDataSource | null {
        const sortedDataSources = dataSources
            .sort((a: DataSource.SimpleDataSource, b: DataSource.SimpleDataSource) => {
                const timeA = new Date(a.createdAt).getTime();
                const timeB = new Date(b.createdAt).getTime();
                return timeA - timeB;
            })
            .filter((ds: DataSource.SimpleDataSource) => {
                return (
                    !ds.isSystem &&
                    !excludedAdapters.has(ds.adapterId) &&
                    SSO_REVIEW_SYSTEM_LINKS[ds.adapterId] !== null
                );
            });

        return sortedDataSources[0] ?? null;
    }
}

export default UserHasCrmAccountService;
