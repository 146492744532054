import React from 'react';
import { Tooltip, IconButton, Icon } from '@material-ui/core';
import DottedLink from '../../DottedLink';
import {
    getDetail,
    DETAIL_RECORDS_LIMIT,
    DETAIL_HUMANIZED_EXPRESSION,
    DETAIL_RANK,
} from '../../../service/WorkflowActionManager';
import Confirmation from '../../Confirmation';
import i18n from '../../../locales/i18n';
import AbstractList from '../AbstractList/AbstractList';
import { userManager } from '../../../service/UserManager';

const t = i18n.t.bind(i18n);

class List extends AbstractList {
    initColumns() {
        return [
            {
                columnName: 'name',
                type: 'string',
                title: t('workflow_actions.list.matched_records.name'),
                getCellValue: (action) => (
                    <React.Fragment>
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, action)}
                            data-testid="workflow_actions.list.matched_records.name"
                        >
                            {action.name}
                        </DottedLink>
                    </React.Fragment>
                ),
            },
            {
                columnName: 'callContext',
                type: 'text[]',
                title: t('workflow_actions.list.matched_records.entity'),
                picklist: this.getEntityPicklist(),
                getCellValue: (action) => {
                    let entityLabel;
                    for (const dataSource of this.props.dataSources) {
                        for (const entity of dataSource.entities) {
                            if (entity.id === action.callContext.getBaseEntityId()) {
                                entityLabel = dataSource.name + ' > ' + entity.label;
                                break;
                            }
                        }
                    }
                    return <span>{entityLabel}</span>;
                },
            },
            {
                columnName: 'criteria',
                type: 'json',
                title: t('workflow_actions.list.matched_records.criteria'),
                getCellValue: (action) => {
                    const recordsLimit = getDetail(DETAIL_RECORDS_LIMIT, action.details || {}, 'all');
                    const formula = getDetail(DETAIL_HUMANIZED_EXPRESSION, action.details || {});
                    const rank = getDetail(DETAIL_RANK, action.details || {});
                    return (
                        <div style={{ whiteSpace: 'normal' }}>
                            {formula && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('workflow_actions.list.matched_records.criteria.template', {
                                            recordsLimit,
                                            formula,
                                        }),
                                    }}
                                ></div>
                            )}
                            {rank && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('workflow_actions.list.matched_records.rank.template', { rank }),
                                    }}
                                ></div>
                            )}
                        </div>
                    );
                },
            },
            {
                columnName: 'rules',
                type: 'string',
                title: t('workflow_actions.list.matched_records.rules'),
                getCellValue: (action) => <span>{action.rules.map((r) => r.name).join(', ')}</span>,
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: t('workflow_actions.list.matched_records.updated_by'),
                getCellValue: (action) => <span>{action.updatedBy ? action.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: t('workflow_actions.list.matched_records.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (action) => (
                    <Confirmation
                        text={t('workflow_actions.confirm.remove')}
                        onConfirm={this.props.onActionRemove.bind(this, action)}
                    >
                        <Tooltip title={t('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="workflow_actions.matched_records.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ];
    }

    initSortingColumnExtensions() {
        return [
            { columnName: 'callContext', sortingEnabled: false },
            { columnName: 'criteria', sortingEnabled: false },
            { columnName: 'rules', sortingEnabled: false },
        ];
    }
}

export { List as MatchRecordsList };
