export enum Status {
    Idle,
    Waiting,
    Processing,
}

/**
 * Класс позволяет избежать одновременного выполнения действия сразу несколькими вкладками браузера.
 * Варианты с установкой блокировки через localStorage и Broadcast не проходят испытаний.
 * LocalStorage - из-за задержек в установке значений.
 * Broadcast - из-за одновременного получения сообщений сразу всеми ожидающими вкладками.
 */
export abstract class ParallelSafeDispatcher {
    private promise: Promise<any> | null = null;
    private resolve: any = null;
    private reject: any = null;
    protected status: Status = Status.Idle;
    protected readonly id: number = Date.now() + Math.random() * 1000;
    private interval: number | null = null;
    private task: any;
    protected sharedName: string;

    constructor(sharedName: string, task: any) {
        this.task = task;
        this.sharedName = sharedName;
    }

    public execute = async (): Promise<any> => {
        if (this.promise === null) {
            this.promise = new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
            this.setStatus(Status.Waiting);

            this.interval = window.setInterval(() => {
                if (!this.promise) {
                    return;
                }
                this.vote().then((isWinner) => isWinner && this.executeNow());
            }, 5000);

            this.vote().then((isWinner) => isWinner && this.executeNow());
        }

        return this.promise;
    };

    protected abstract vote(): Promise<boolean>;

    protected setStatus(status: Status): void {
        this.status = status;
    }

    private executeNow(): void {
        this.setStatus(Status.Processing);

        const resolve = this.resolve;
        const reject = this.reject;

        this.resolve = null;
        this.reject = null;
        this.promise = null;

        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }

        this.task()
            .finally(() => {
                this.setStatus(Status.Idle);
            })
            .then((result: any) => {
                if (resolve) {
                    resolve(result);
                    return;
                }
            })
            .catch((error: any) => {
                if (reject) {
                    reject(error);
                    return;
                }
                throw error;
            });
    }
}
