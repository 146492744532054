import { routeDesignManager, routeReportManager, routingSessionManager } from 'service/MapPage';
import { userManager } from '../../UserManager';
import usersPermissionsManager from '../../Permissions/UsersPermissionsManager';

class AvailableRouteActions {
    // the same as this.canSwitchToDesignMode but for published session
    get canClearRoutePanel(): boolean {
        return routingSessionManager.isAnyDraftMode || routingSessionManager.isLoadedRoute;
    }

    get canClearRoutePoints(): boolean {
        return (
            !routingSessionManager.isLoadedRoute &&
            routingSessionManager.isDesignMode &&
            !routeDesignManager.emptyPoints
        );
    }

    get canEditRoute(): boolean {
        return routingSessionManager.isShowCurrentSession && routingSessionManager.isDraftMode;
    }

    get canEditPublishedRoute(): boolean {
        return routingSessionManager.isShowLoadedSessionRoute && !routingSessionManager.isEditModeInPublishedRoute;
    }

    get showRebuildRouteAction(): boolean {
        return routingSessionManager.isShowLoadedSessionRoute && !routingSessionManager.isStartedTripRouteMode;
    }

    get canRebuildRoute(): boolean {
        if (routingSessionManager.isShowLoadedSessionRoute && !routingSessionManager.isStartedTripRouteMode) {
            const session = routingSessionManager.loadedSession;
            return session.permissions?.rebuild ?? false;
        }
        return false;
    }

    get canRefreshRoute(): boolean {
        return routingSessionManager.isShowLoadedSessionRoute && routingSessionManager.isStartedTripRouteMode;
    }

    get canSwitchToDesignMode(): boolean {
        return routingSessionManager.isShowCurrentSession && routingSessionManager.isAnyDraftMode;
    }

    get canGoBackFromMultiRoutes(): boolean {
        return (
            routeReportManager.isShowRouteReport &&
            routeReportManager.showMultipleRoutes &&
            routeReportManager.routes.length !== 1
        );
    }

    get isLoadingRoutesConfirmationRequired(): boolean {
        return (
            (routingSessionManager.isShowCurrentSession &&
                (routingSessionManager.isAnyDraftMode ||
                    (routingSessionManager.isDesignMode && !routeDesignManager.emptyPoints))) ||
            routingSessionManager.isLoadedRouteUsed
        );
    }

    get canExportToCalendar(): boolean {
        return (
            (routingSessionManager.isShowCurrentSession || routingSessionManager.isShowLoadedSessionRoute) &&
            (routingSessionManager.currentSessionRoutes.length !== 0 || routingSessionManager.isLoadedRoute) &&
            (userManager.isRoleAdmin() || usersPermissionsManager.hasCalendarUiPermission)
        );
    }
}

export default AvailableRouteActions;
