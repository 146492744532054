import { OptionsObject, SnackbarMessage } from 'notistack';
import dispatcher from '../dispatcher';
import events from '../../events';

class EnqueueSnackbarService {
    sendSuccessMessage(message: SnackbarMessage) {
        this.sendMessage(message, { variant: 'success' });
    }

    sendErrorMessage(message: SnackbarMessage) {
        this.sendMessage(message, { variant: 'error' });
    }

    sendWarningMessage(message: SnackbarMessage) {
        this.sendMessage(message, { variant: 'warning' });
    }

    sendCustomMessage(message: SnackbarMessage, options?: OptionsObject) {
        this.sendMessage(message, options);
    }

    private sendMessage(message: SnackbarMessage, options?: OptionsObject) {
        dispatcher.dispatch(events.ADD_MESSAGE_ENQUEUE_SNACKBAR, {
            message,
            options,
        });
    }

    closeMessage(key: OptionsObject['key']) {
        dispatcher.dispatch(events.CLOSE_MESSAGE_ENQUEUE_SNACKBAR, key);
    }
}

export default EnqueueSnackbarService;
