import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { isUpdatableField, trimLookupIdLabel } from '../../../utils';
import DisabledOptionWithHintAutocomplete from '../../DisabledOptionWithHintAutocomplete';

class LookupFieldForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || null,
        };
    }

    handleFieldChange = (event, field) => {
        this.setState({ value: field });
    };

    submit(event) {
        this.props.onSubmitSuccess(this.state.value, event);
    }

    getOptionDisabled = (option) => {
        const { selectedEntityType, selectedFields } = this.props;
        return selectedFields.has(selectedEntityType + option.id);
    };

    render() {
        const options = this.props.fields.filter(isUpdatableField).sort((a, b) => a.label.localeCompare(b.label));

        return (
            <form autoComplete="off" noValidate>
                <DisabledOptionWithHintAutocomplete
                    options={options}
                    getOptionLabel={trimLookupIdLabel}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionDisabled={this.getOptionDisabled}
                    disabledOptionHint={this.props.t('workflow_actions.form.field_update.field.disabled_hint')}
                    value={this.state.value}
                    onChange={this.handleFieldChange}
                    groupBy={(option) => option && option.groupBy}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={this.props.t('workflow_actions.form.field_update.field')}
                            data-testid="workflow_actions.form.field_update.field"
                        />
                    )}
                />
            </form>
        );
    }
}

LookupFieldForm.propTypes = {
    fields: PropTypes.array.isRequired,
    selectedEntityType: PropTypes.string.isRequired,
    selectedFields: PropTypes.instanceOf(Set).isRequired,
    value: PropTypes.object,
    onSubmitSuccess: PropTypes.func.isRequired,
};

LookupFieldForm.defaultProps = {
    loading: true,
};

export default withTranslation('translations', { withRef: true })(LookupFieldForm);
