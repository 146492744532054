import { configureScope } from '@sentry/browser';
import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import { setupLogRocketReact } from '../../api/setupLogRocketReact';
import { LogRocketManager, LogRocketRequest } from '../../interfaces/LogRocket';
import APP_CONFIG from '../../params';
import timezones, { stringifyTimezone } from '../../references/timezones';
import { EnvironmentParams } from '../../types';
import { sharedMapStateManager } from '../SharedMapStateManager';
import { userManager } from '../UserManager';
import { UserData } from '../types';
import { LogRocketEvent, LogRocketEventProperties } from './events';

interface IUserTraits {
    [propName: string]: string | number | boolean;
}

type Replacement = {
    regexp: RegExp;
    replacement: string;
};

const SENSITIVE_FIELDS = [
    'accessToken',
    'access_token',
    'apiKey',
    'api_key',
    'auth',
    'jwt',
    'jwtToken',
    'jwtTokenImpersonate',
    'key',
    'password',
    'passwordReentry',
    'signature',
    'token',
    'user-password',
    'user-password-reentry',
];

const URL_REPLACEMENTS: Replacement[] = [
    ...SENSITIVE_FIELDS.map(
        (field: string): Replacement => ({
            regexp: new RegExp(`\\b${field}=([^&#]*)`, 'i'),
            replacement: field + '=*',
        }),
    ),
    { regexp: /\/reset_password\/([^/?#]*)/i, replacement: '/reset_password/*' },
    { regexp: /\/valid_reset_token\/([^/?#]*)/i, replacement: 'valid_reset_token/*' },
];

const sanitizeUrl = (url: string) =>
    URL_REPLACEMENTS.reduce(
        (acc: string, { regexp, replacement }: Replacement): string => acc.replace(regexp, replacement),
        url,
    );

// sensitive fields' values are masked with "*", and other sanitizers should also use "*" for consistency
const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(SENSITIVE_FIELDS);

/**
 * @todo update sanitizers in MD-2859 Security issues (e.g. password inputs can be unmasked)
 */
const LOGROCKET_CONFIG: { appId: string; options: object } | undefined = ((appConfig: EnvironmentParams) =>
    appConfig.logrocket?.appId
        ? {
              appId: appConfig.logrocket!.appId,
              options: {
                  browser: {
                      urlSanitizer: sanitizeUrl,
                  },
                  dom: {
                      baseHref: appConfig.logrocket!.baseHref ?? null,
                      privateClassNameBlocklist: ['leaflet-tile'],
                  },
                  network: {
                      requestSanitizer: (request: LogRocketRequest) => {
                          request.url = sanitizeUrl(request.url);

                          if (request.headers?.Authorization) {
                              request.headers.Authorization = request.headers.Authorization.replace(
                                  /([^ ]+ ).+/,
                                  '$1*',
                              );
                          }

                          if (request.headers?.credentials) {
                              request.headers.credentials = '*';
                          }

                          if (request.headers?.['x-jwt-token']) {
                              request.headers['x-jwt-token'] = '*'; // ws
                          }

                          return requestSanitizer(request);
                      },
                      responseSanitizer,
                  },
                  release: String(appConfig.version),
              },
          }
        : undefined)(APP_CONFIG);

class Manager implements LogRocketManager {
    private isInitialized: boolean = false;

    private userId: number | null = null;

    private traits: IUserTraits | undefined = undefined;

    get sessionUrl(): string | null {
        if (!this.isInitialized) {
            return null;
        }

        return LogRocket.sessionURL;
    }

    init() {
        if (this.isInitialized) {
            return false;
        }

        this.isInitialized = true;
        LogRocket.init(LOGROCKET_CONFIG!.appId, LOGROCKET_CONFIG!.options);
        setupLogRocketReact(LogRocket);

        if (this.userId !== null) {
            LogRocket.identify(String(this.userId), this.traits);
        } else if (this.traits !== undefined) {
            LogRocket.identify(this.traits);
        }

        return true;
    }

    changeSession(user: UserData | null | undefined): void {
        // handle logout and ghosting (switching)
        const newUserId = user?.id ?? null;
        const isUserIdChanged = this.userId !== null && newUserId !== null && newUserId !== this.userId;

        if (this.isInitialized && isUserIdChanged) {
            LogRocket.startNewSession();
        }

        if (!user || user.accountId === null) {
            this.traits = undefined;
            return;
        }

        this.userId = newUserId;

        const { accountId, actualTimezone, actualTimezoneOffset, email, name } = user;

        if (isUserIdChanged && userManager.isSwitched()) {
            LogRocket.track(LogRocketEvent.SWITCH_USER, {
                'Original ID': String(userManager.getSwitchedUserId()),
                'Original role': userManager.getSwitchedUserRole(),
                'Switch mode': userManager.getSwitchMode(),
            });
        }

        if (userManager.isSharedMapUser()) {
            const link = sharedMapStateManager.getSharedMapLink();
            if (link !== null) {
                LogRocket.track(LogRocketEvent.SHARED_MAP, { link });
            }
        }

        const timezone = timezones.find(({ name }) => name === actualTimezone);
        const account = userManager.getCurrentAccount();

        this.traits = {
            email,
            name,
            'Account ID': accountId,
            'Time zone': timezone ? stringifyTimezone(timezone) : actualTimezone,
            'Time zone name': actualTimezone,
            'Time zone offset': timezone ? timezone.offset.toPrecision(2) : (actualTimezoneOffset / 60).toPrecision(2),
        };

        if (account) {
            this.traits['Account'] = account.name;
            this.traits['Subscription'] = account.subscription.plan.name;
            this.traits['Subscription status'] = account.subscription.status;
            this.traits['Subscription tier'] = account.subscription.plan.tier;
        }

        if (this.isInitialized) {
            LogRocket.identify(String(user.id), this.traits);
        }
    }

    async getSessionUrl(): Promise<string | null> {
        if (!this.isInitialized) {
            return null;
        }

        const sessionURL = await new Promise<string>((resolve) => LogRocket.getSessionURL(resolve));

        configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL);
        });

        return sessionURL;
    }

    getUserUrl(userId: any): string | null {
        if (!userId) {
            return null;
        }

        return `https://app.logrocket.com/${LOGROCKET_CONFIG!.appId}/sessions?u=${String(userId)}`;
    }

    trackEvent(event: LogRocketEvent, properties: LogRocketEventProperties): void {
        if (!this.isInitialized) {
            return;
        }

        LogRocket.track(event, properties);
    }
}

class Dummy implements LogRocketManager {
    sessionUrl = null;

    changeSession() {}

    init() {
        return false;
    }

    async getSessionUrl() {
        return null;
    }

    getUserUrl(_userId: any): string | null {
        return null;
    }

    trackEvent(_event: LogRocketEvent, _properties: LogRocketEventProperties): void {}
}

const logRocketEnabled = LOGROCKET_CONFIG?.appId && !sharedMapStateManager.isSharedMap();

const logRocketManager = logRocketEnabled ? new Manager() : new Dummy();

export default logRocketManager;
