import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    Slide,
    SlideProps,
    TextField,
    Typography,
} from '@material-ui/core';
import { MapslyFile, SummarizationStatus, TranscriptionStatus } from 'interfaces/file';
import MediaPlayer from './MediaPlayer';
import Summary from './Summary';
import Transcript from './Transcript/Transcript';
import './style.css';
import { fileManager } from '../../../service/File/FileManager';
import { formatSeconds, utcToUserTimezone } from '../../../utils';
import { userManager } from '../../../service/UserManager';

const Transition = React.forwardRef(function (props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface AudioVideoModalProps extends WithTranslation {
    accountId: number;
    file: MapslyFile;
    onClose: () => void;
}

interface AudioVideoModalState {
    file: MapslyFile;
    editName: string | null;
}

class AudioVideoModal extends React.PureComponent<AudioVideoModalProps, AudioVideoModalState> {
    playerRef: React.RefObject<any> = React.createRef();

    constructor(props: AudioVideoModalProps) {
        super(props);

        this.state = {
            file: { ...props.file },
            editName: null,
        };
    }

    saveFile = () => {
        fileManager.update(this.props.accountId, this.state.file).then((file) => {
            this.setState({ file: { ...file } });
        });
    };

    onEdit = () => {
        this.setState({ editName: this.state.file.name });
    };

    handleEditName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editName: event.target.value });
    };

    saveName = () => {
        this.setState((state) => {
            const file = { ...state.file };
            file.name = state.editName!;
            return { file, editName: null };
        }, this.saveFile);
    };

    cancelName = () => {
        this.setState({ editName: null });
    };

    handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((state) => {
            const file = { ...state.file };
            file.description = event.target.value;
            return { file };
        });
    };

    handleRewind = (time: number) => {
        this.playerRef.current?.player.currentTime(time);
        this.playerRef.current?.player.play();
    };

    handleTranscriptUpdate = (status: TranscriptionStatus) => {
        this.setState((state) => {
            const file = { ...state.file };
            file.transcriptionStatus = status;
            return { file };
        });
    };
    handleSummaryUpdate = (status: SummarizationStatus) => {
        this.setState((state) => {
            const file = { ...state.file };
            file.summarizationStatus = status;
            return { file };
        });
    };

    renderFileName = () => {
        if (this.state.editName) {
            return (
                <Grid container direction="row" alignItems="center" className="audio-video-title">
                    <TextField
                        value={this.state.editName}
                        onChange={this.handleEditName}
                        variant="outlined"
                        size="small"
                        style={{ width: '50%' }}
                    />
                    <IconButton onClick={this.saveName} className="audio-video-title__button">
                        <Icon className="fa-light fa-check" style={{ fontSize: '0.75rem' }} />
                    </IconButton>
                    <IconButton onClick={this.cancelName} className="audio-video-title__button">
                        <Icon className="fa-light fa-times" style={{ fontSize: '0.75rem' }} />
                    </IconButton>
                </Grid>
            );
        }

        return (
            <Grid container direction="row" alignItems="center" className="audio-video-title">
                <Typography variant="h6">{this.state.file.name}</Typography>
                <IconButton onClick={this.onEdit} className="audio-video-title__button">
                    <Icon className="fa-light fa-pencil" style={{ fontSize: '0.75rem' }} />
                </IconButton>
            </Grid>
        );
    };

    renderTitle = (file: MapslyFile) => {
        return (
            <DialogTitle className="c-form-dialog__title" disableTypography>
                <div>
                    {this.renderFileName()}
                    <div className="audio-video-meta">
                        <span className="audio-video-meta__field">
                            <i className="audio-video-meta__field__icon fa-light fa-calendar-days" />
                            {utcToUserTimezone(file.createdAt, userManager.getCurrentUser())}
                        </span>
                        <span className="audio-video-meta__field">
                            <i className="audio-video-meta__field__icon fa-light fa-clock" />
                            {file.duration ? formatSeconds(file.duration) : '--:--:--'}
                        </span>
                        <span className="audio-video-meta__field">
                            <i className="audio-video-meta__field__icon fa-light fa-language" />
                            {file.language ? this.props.t('media_transcribe_language.' + file.language) : null}
                        </span>
                    </div>
                </div>
                <IconButton
                    color="default"
                    component="span"
                    onClick={this.props.onClose}
                    className="c-form-dialog__close"
                    data-testid="data_exchange_log.modal.close"
                >
                    <Icon>close_icon</Icon>
                </IconButton>
            </DialogTitle>
        );
    };

    render() {
        const { file } = this.state;

        return (
            <Dialog
                open={true}
                onClose={this.props.onClose}
                TransitionComponent={Transition}
                fullScreen
                onClick={(e) => e.stopPropagation()} // @link https://github.com/facebook/react/issues/11387
            >
                {this.renderTitle(file)}

                <DialogContent>
                    <Grid container spacing={2} className="audio-video-container">
                        <Grid item xs={6} className="audio-video-container__column">
                            <MediaPlayer
                                url={file.url || ''}
                                type={file.type}
                                contentType={file.contentType}
                                ref={this.playerRef}
                            />

                            <TextField
                                value={this.state.file.description || ''}
                                onChange={this.handleDescriptionChange}
                                onBlur={this.saveFile}
                                placeholder="Description"
                                fullWidth
                                multiline
                                minRows={3}
                                variant="outlined"
                                style={{ margin: '20px 0' }}
                            />

                            <Summary
                                accountId={this.props.accountId}
                                file={file}
                                onSummaryUpdate={this.handleSummaryUpdate}
                            />
                        </Grid>
                        <Grid item xs={6} className="audio-video-container__column">
                            <Transcript
                                accountId={this.props.accountId}
                                file={file}
                                onRewind={this.handleRewind}
                                onTranscriptUpdate={this.handleTranscriptUpdate}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(AudioVideoModal);
