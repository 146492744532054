import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { withTranslation } from 'react-i18next';
import { Checkbox, Popper, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SingleRowMultipleAutocomplete } from '../SingleRowMultipleAutocomplete';

export const MAX_COUNT = 50;
export const DEFAULT_ID = 0;

const MultipleOption = (territory, selected) => {
    return (
        <>
            {<Checkbox checked={selected ? selected.value.indexOf(territory.id) > -1 : false} color="primary" />}
            {territory.name}
        </>
    );
};

const Option = (territory) => {
    return territory.name;
};

class BigAutocomplete extends React.PureComponent {
    buildInput = (params) => {
        return React.Children.map(this.props.children, (child) => {
            if (child.type === TextField) {
                return React.cloneElement(child, { ...params });
            }
            return child;
        });
    };

    replaceFromOptionsSelectedData(selectedTerritories, options, selected) {
        const excludeOptionsId = this.props.excludeOptionsId ?? [];
        if (selectedTerritories.length > 0) {
            const result = options.filter((option) => {
                return (
                    !selected.value.includes(option.id) &&
                    option.id !== DEFAULT_ID &&
                    !excludeOptionsId.includes(option.id)
                );
            });
            selectedTerritories.forEach((item) => {
                if (item.id !== DEFAULT_ID) {
                    result.unshift(item);
                }
            });
            return result;
        }
        return options;
    }

    filterOptions(inputValue, options) {
        if (inputValue !== '') {
            return options.filter((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);
        }
        return options;
    }

    render() {
        const {
            multiple,
            selected,
            handleFilter,
            width,
            useEmptyValue,
            emptyText,
            disableClearable,
            overflowText,
            disabled,
        } = this.props;
        let options = [...this.props.options];

        if (useEmptyValue) {
            options.unshift({ id: DEFAULT_ID, name: emptyText });
        }

        let selectedTerritories = selected ? options.filter((option) => selected.value.includes(option.id)) : [];
        const size = multiple ? 'small' : null;

        const AutocompleteComponent = multiple ? SingleRowMultipleAutocomplete : Autocomplete;

        return (
            <AutocompleteComponent
                disabled={disabled}
                fullWidth
                disableClearable={disableClearable}
                options={options}
                multiple={multiple}
                size={size}
                defaultValue={selectedTerritories.length && !multiple ? selectedTerritories[0] : selectedTerritories}
                PopperComponent={(props) =>
                    width ? (
                        <Popper {...props} style={{ width: width }} placement="bottom-start" />
                    ) : (
                        <Popper {...props} placement="bottom-start" />
                    )
                }
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option.isLabel === true}
                filterOptions={(options, state) => {
                    let filteredOptions = this.replaceFromOptionsSelectedData(selectedTerritories, options, selected);
                    filteredOptions = this.filterOptions(state.inputValue, filteredOptions);

                    if (useEmptyValue) {
                        filteredOptions.unshift({ id: DEFAULT_ID, name: emptyText });
                    }

                    if (filteredOptions.length > MAX_COUNT) {
                        filteredOptions.unshift({
                            name: overflowText,
                            isLabel: true,
                        });
                        return filteredOptions.slice(0, MAX_COUNT + 1);
                    }

                    return filteredOptions;
                }}
                renderOption={(territory) => (multiple ? MultipleOption(territory, selected) : Option(territory))}
                onChange={handleFilter}
                renderInput={(params) => this.buildInput(params)}
            />
        );
    }
}

BigAutocomplete.propTypes = {
    overflowText: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    excludeOptionsId: PropTypes.array,
    multiple: PropTypes.bool.isRequired,
    selected: PropTypes.object.isRequired,
    handleFilter: PropTypes.func.isRequired,
    width: PropTypes.number,
    useEmptyValue: PropTypes.bool,
    emptyText: PropTypes.string,
    disableClearable: PropTypes.bool,
    maxCount: PropTypes.number,
    disabled: PropTypes.bool,
};

BigAutocomplete.defaultProps = {
    disabled: false,
};

export default withTranslation('translations', { withRef: true })(BigAutocomplete);
