import dispatcher from 'service/dispatcher';
import events from '../../events';
import { routeViewerManager } from 'service/MapPage';
import { format } from 'date-fns';
import { DATE_FORMAT_DATEFNS } from 'utils';
import { PathsRequestInitiator } from '../../interfaces/ws/paths';
import { ArrangementPayload, CalendarEventsChangePayload, CalendarEventsSuccessPayload } from './CalendarEventManager';

export class CalendarEventPathsViewerSubscriber {
    subscribe(): void {
        dispatcher.unsubscribeFromAllEvents(this);
        dispatcher.subscribe(events.WS_CALENDAR_EVENTS_CHANGE, this, this.handleSuccessPayload);
    }

    private handleSuccessPayload = (payload: CalendarEventsSuccessPayload | CalendarEventsChangePayload): void => {
        if ('arrangement' in payload && payload.arrangement) {
            this.updateRouteViewerPathsOnArrangementChange(payload.arrangement);
        }
    };

    private updateRouteViewerPathsOnArrangementChange = (payload: ArrangementPayload): void => {
        if (payload.processing || payload.error) {
            return;
        }

        if (routeViewerManager.mode !== 'calendar') {
            return;
        }

        let isDateSelectedInRouteViewer = false;
        routeViewerManager.calendarSelection.forEach((userDates: number[]) => {
            for (let date of userDates) {
                if (format(new Date(date), DATE_FORMAT_DATEFNS) === payload.date) {
                    isDateSelectedInRouteViewer = true;
                    return;
                }
            }
        });

        if (!isDateSelectedInRouteViewer) {
            return;
        }

        routeViewerManager.requestPaths(PathsRequestInitiator.CALENDAR);
    };
}
