import React from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';

interface AnalyticsIframeProps {
    baseUrl: string;
    afterLoad: any;
}

interface AnalyticsIframeState {
    loading: boolean;
}

class AnalyticsIframe extends React.Component<AnalyticsIframeProps, AnalyticsIframeState> {
    constructor(props: AnalyticsIframeProps) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    handleOnLoad = () => {
        this.setState({
            loading: false,
        });

        this.props.afterLoad();
    };

    render() {
        const baseUrl = this.props.baseUrl;
        let redirect = queryString.parse(document.location.search)?.redirect || '';

        const url = baseUrl + redirect;

        const loading = this.state.loading;

        return (
            <iframe
                referrerPolicy="strict-origin-when-cross-origin"
                className={loading ? 'analytics-iframe' : 'analytics-iframe-loaded'}
                title={url}
                src={url}
                onLoad={this.handleOnLoad}
            />
        );
    }
}

export default withTranslation()(AnalyticsIframe);
