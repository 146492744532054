import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, Grid, IconButton, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { FormActions } from '../PureFormDialog/Form';
import LandingLink, { ARTICLE_RECORD_SHARING } from '../HelpLink/LandingLink';
import AddIconButton from '../CustomButton/AddIconButton';
import { PERMISSION_MODIFY, PERMISSION_VIEW } from './constants';
import ExpressionBuilderForm from './ExpressionBuilderForm';
import { red } from '@material-ui/core/colors';
import Confirmation from '../Confirmation';
import FormDialog from '../FormDialog';
import FieldsForm from './FieldsForm';
import EntityManager from '../../service/EntityManager';
import { getConfigurablePermissionsFields } from './ProfilePermissions';

export interface SharingRuleField {
    id: number;
    accessLevel: number;
}

export interface SharingRule {
    id: number | null;
    condition: string;
    conditionExpression: Array<object>;
    accessLevel: number;
    fields: SharingRuleField[];
}

interface RecordSharingModalProps extends WithTranslation {
    rules: SharingRule[];
    onSave: (rules: SharingRule[]) => void;
    onClose: () => void;
    entityId: number;
    entityLabel: string;
    profileName: string;
    accountId: number;
    dataSourceId: number;
}

interface EditRuleFields {
    rule: SharingRule;
    fields: Array<object> | null;
    index: number;
    settings: Record<number, number>;
}

interface RecordSharingModalState {
    rules: SharingRule[];
    editRule: SharingRule | null;
    editRuleFields: EditRuleFields | null;
}

class RecordSharingModal extends React.PureComponent<RecordSharingModalProps, RecordSharingModalState> {
    constructor(props: RecordSharingModalProps) {
        super(props);
        this.state = {
            rules: [...this.props.rules],
            editRule: null,
            editRuleFields: null,
        };
    }

    loadFields = () => {
        return EntityManager.getManager(this.props.accountId, this.props.dataSourceId).getEntity(
            this.props.entityId,
            true,
        );
    };

    handleSave = () => {
        this.props.onSave(this.state.rules);
    };

    addRule = () => {
        this.setState({
            editRule: {
                id: null,
                condition: '',
                conditionExpression: [],
                accessLevel: PERMISSION_VIEW,
                fields: [],
            },
        });
    };

    editRule = (rule: SharingRule) => () => {
        this.setState({
            editRule: { ...rule },
        });
    };

    editRuleFields = (rule: SharingRule, index: number) => () => {
        const settings = this.transformRuleFields(rule.fields);
        this.setState(
            {
                editRuleFields: {
                    rule,
                    fields: null,
                    index,
                    settings,
                },
            },
            () => {
                this.loadFields().then((response: { fields: any }) => {
                    const { fields } = response;
                    this.setState({
                        editRuleFields: {
                            rule,
                            fields: getConfigurablePermissionsFields(fields),
                            index,
                            settings,
                        },
                    });
                });
            },
        );
    };

    transformRuleFields = (fields: SharingRuleField[]) => {
        const settings: Record<number, number> = {};
        for (let field of fields) {
            settings[field.id] = field.accessLevel;
        }
        return settings;
    };

    deleteRule = (index: number) => () => {
        this.setState((state) => {
            return {
                rules: state.rules.filter((_, ruleIndex) => ruleIndex !== index),
            };
        });
    };

    changeAccessLevel = (index: number, value: number) => {
        this.setState((state) => {
            return {
                rules: state.rules.map((rule, ruleIndex) => {
                    if (ruleIndex === index) {
                        return {
                            ...rule,
                            accessLevel: value,
                        };
                    }
                    return rule;
                }),
            };
        });
    };

    handleSaveRule = (rule: SharingRule) => {
        this.setState((state) => {
            let found: boolean = false;
            const rules = [...state.rules].map((r) => {
                if (rule.id && r.id === rule.id) {
                    found = true;
                    return rule;
                }
                return r;
            });
            if (!found) {
                rules.push(rule);
            }
            return {
                rules,
                editRule: null,
            };
        });
    };

    handleCloseRule = () => {
        this.setState({
            editRule: null,
        });
    };

    handleSaveSettings = (settings: Record<number, number>) => {
        const { index } = this.state.editRuleFields as EditRuleFields;
        this.setState((state) => {
            return {
                editRuleFields: null,
                rules: [...state.rules].map((rule, ruleIndex) => {
                    if (ruleIndex === index) {
                        return { ...rule, fields: this.transformSettings(settings) };
                    }
                    return rule;
                }),
            };
        });
    };

    transformSettings = (settings: Record<number, number>) => {
        const fields = [];
        for (let fieldId of Object.keys(settings)) {
            if (settings.hasOwnProperty(fieldId)) {
                const id = parseInt(fieldId);
                // @ts-ignore
                const accessLevel = parseInt(settings[fieldId]);
                fields.push({
                    id,
                    accessLevel,
                });
            }
        }
        return fields;
    };

    handleCloseSettings = () => {
        this.setState({
            editRuleFields: null,
        });
    };

    render() {
        const { onClose, t } = this.props;
        const { rules } = this.state;
        const addDisabled = this.state.rules.length === 2;
        return (
            <PureFormDialog open title={t('record_sharing') as string} onClose={onClose} maxWidth="lg" fullWidth>
                <p>
                    <i className="fas fa-exclamation-circle" style={{ marginRight: 8, color: 'blue' }} />
                    {t('record_sharing.settings.help')}{' '}
                    <LandingLink useTrailingIcon article={ARTICLE_RECORD_SHARING}>
                        {t('record_sharing.settings.help.learn_more')}
                    </LandingLink>
                </p>
                {t('record_sharing.rules')}{' '}
                <Tooltip title={addDisabled ? (t('record_sharing.rules.limit') as string) : ''}>
                    <span>
                        <AddIconButton onClick={this.addRule} disabled={addDisabled} style={{ marginLeft: 8 }} />
                    </span>
                </Tooltip>
                <Grid container style={{ marginTop: 16 }} className="record-sharing__rules__header">
                    <Grid item md={7}>
                        {t('record_sharing.rule.condition.label')}
                    </Grid>
                    <Grid item md={4} className="record-sharing__rules__list__access-level">
                        {t('record_sharing.rule.condition.access')}
                    </Grid>
                    <Grid item md={1} className="record-sharing__rules__list__trash">
                        &nbsp;
                    </Grid>
                </Grid>
                {rules.map((rule, index) => {
                    return (
                        <Grid container key={index} className="record-sharing__rules__list">
                            <Grid item md={7}>
                                <TextField
                                    className="record-sharing__rules__input"
                                    fullWidth
                                    multiline
                                    name="condition"
                                    value={rule.condition}
                                    onClick={this.editRule(rule)}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={this.editRule(rule)}
                                                data-testid="record_sharing.rule.condition.edit"
                                            >
                                                {t('record_sharing.rule.condition.edit')}
                                            </Button>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} className="record-sharing__rules__list__access-level">
                                <Select
                                    className="record-sharing__rules__input"
                                    value={rule.accessLevel}
                                    onChange={(e: ChangeEvent<{ value: unknown }>): void =>
                                        this.changeAccessLevel(index, e.target.value as number)
                                    }
                                    data-testid="permissions.fields_form.select"
                                >
                                    <MenuItem value={PERMISSION_VIEW} data-testid="permissions.fields_form.select.view">
                                        {t('permissions.fields_form.select.view')}
                                    </MenuItem>
                                    <MenuItem
                                        value={PERMISSION_MODIFY}
                                        data-testid="permissions.fields_form.select.modify"
                                    >
                                        {t('permissions.fields_form.select.modify')}
                                    </MenuItem>
                                </Select>
                                <IconButton
                                    component="span"
                                    onClick={this.editRuleFields(rule, index)}
                                    data-testid="permissions.fields_form.edit_rule_field"
                                >
                                    <i
                                        style={{
                                            fontSize: '16px',
                                            display: 'inline-block',
                                            width: '16px',
                                            height: '16px',
                                        }}
                                        className="fas fa-ellipsis-h"
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item md={1} className="record-sharing__rules__list__trash">
                                <Confirmation
                                    text={t('record_sharing.rule.delete.confirm')}
                                    onConfirm={this.deleteRule(index)}
                                >
                                    <IconButton component="span" data-testid="record_sharing.rule.delete.confirm">
                                        <i
                                            style={{
                                                fontSize: '16px',
                                                display: 'inline-block',
                                                width: '16px',
                                                height: '16px',
                                                color: red[500],
                                            }}
                                            className="fas fa-trash"
                                        />
                                    </IconButton>
                                </Confirmation>
                            </Grid>
                        </Grid>
                    );
                })}
                <FormActions>
                    <Button color="primary" onClick={this.handleSave} data-testid="record_sharing.button.save">
                        {t('button.save')}
                    </Button>
                </FormActions>
                {this.state.editRule !== null && (
                    <ExpressionBuilderForm
                        rule={this.state.editRule}
                        onSave={this.handleSaveRule}
                        onClose={this.handleCloseRule}
                        entityId={this.props.entityId}
                    />
                )}
                {this.state.editRuleFields !== null && (
                    <FormDialog
                        title={t('record_sharing.rule.fields.permissions', {
                            profileName: this.props.profileName,
                            entityLabel: this.props.entityLabel,
                        })}
                        onSave={this.handleSaveSettings}
                        onCancel={this.handleCloseSettings}
                    >
                        <FieldsForm
                            defaultVisibility={this.state.editRuleFields.rule.accessLevel}
                            fields={this.state.editRuleFields.fields}
                            settings={this.state.editRuleFields.settings}
                        />
                    </FormDialog>
                )}
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations', { withRef: true })(RecordSharingModal);
