import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    TextField,
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK,
    DETAIL_PLANNER_BUSINESS_HOURS_END_TIME,
    DETAIL_PLANNER_BUSINESS_HOURS_START_TIME,
    DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS,
    DETAIL_PLANNER_DEFAULT_VISIT_DURATION,
    DETAIL_PLANNER_END_DATE,
    DETAIL_PLANNER_FIRST_DAY_OFFSET,
    DETAIL_PLANNER_MODE,
    DETAIL_PLANNER_START_DATE,
} from '../../../../references/wokflow/VisitPlannerAction';
import { getDetail } from '../../../../service/WorkflowActionManager';
import Radio from '@material-ui/core/Radio';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MultipleSelect from '../../../Controls/MultipleSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { PLANNER_MODE } from '../Form';
import PropTypes from 'prop-types';
import Hint from '../../../Hint';

class GeneralSection extends React.PureComponent {
    dayOfWeekValues() {
        const { t } = this.props;
        return [
            { value: 1, label: t('weekday.mon') },
            { value: 2, label: t('weekday.tue') },
            { value: 3, label: t('weekday.wed') },
            { value: 4, label: t('weekday.thu') },
            { value: 5, label: t('weekday.fri') },
            { value: 6, label: t('weekday.sat') },
            { value: 7, label: t('weekday.sun') },
        ];
    }

    getAsUTCTime = (time) => {
        if (time === null) {
            return null;
        }
        const orig = new Date(time);
        return new Date(
            orig.getUTCFullYear(),
            orig.getUTCMonth(),
            orig.getUTCDate(),
            orig.getUTCHours(),
            orig.getUTCMinutes(),
            orig.getUTCSeconds(),
            orig.getUTCMilliseconds(),
        );
    };

    saveAsUTCTime = (time) => {
        const orig = new Date(time);
        return new Date(
            Date.UTC(
                orig.getFullYear(),
                orig.getMonth(),
                orig.getDate(),
                orig.getHours(),
                orig.getMinutes(),
                orig.getSeconds(),
                orig.getMilliseconds(),
            ),
        );
    };

    render() {
        const { t, currentAction, errors, formConstraints, onDetailChange, updateDetailCallback } = this.props;

        return (
            <Grid item container xs={12} spacing={1} alignItems={'flex-end'}>
                <Grid item container xs={12} alignItems={'center'} style={{ marginTop: 5, marginBottom: -10 }}>
                    <Grid item xs={3}>
                        {t('workflow_actions.form.visit_planner.mode')}
                        <Hint TooltipProps={{ className: 'tooltip-question' }} iProps={{ style: { marginLeft: 8 } }}>
                            {t('workflow_actions.form.visit_planner.mode.tooltip')}
                        </Hint>
                    </Grid>
                    <Grid item xs={9}>
                        <RadioGroup
                            name={DETAIL_PLANNER_MODE}
                            value={getDetail(DETAIL_PLANNER_MODE, currentAction.details, PLANNER_MODE.single)}
                            onChange={onDetailChange}
                            row
                        >
                            <FormControlLabel
                                value={PLANNER_MODE.single}
                                control={<Radio color="primary" />}
                                label={t('workflow_actions.form.visit_planner.mode.single')}
                            />
                            <FormControlLabel
                                value={PLANNER_MODE.team}
                                control={<Radio color="primary" />}
                                label={t('workflow_actions.form.visit_planner.mode.team')}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: 9 }}>
                    {t('workflow_actions.form.visit_planner.planning_period')}
                    <Hint TooltipProps={{ className: 'tooltip-question' }} iProps={{ style: { marginLeft: 8 } }}>
                        {t('workflow_actions.form.visit_planner.planning_period.tooltip')}
                    </Hint>
                </Grid>
                <Grid item container xs={9} spacing={1} alignItems={'center'}>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                fullWidth
                                required
                                name={DETAIL_PLANNER_START_DATE}
                                helperText={errors.get(DETAIL_PLANNER_START_DATE) || ''}
                                error={errors.has(DETAIL_PLANNER_START_DATE)}
                                emptyLabel={''}
                                label={t('workflow_actions.form.visit_planner.start_date')}
                                InputProps={{
                                    endAdornment: (
                                        <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                            {t('workflow_actions.form.visit_planner.start_date.tooltip')}
                                        </Hint>
                                    ),
                                }}
                                format="MM/dd/yyyy"
                                value={getDetail(DETAIL_PLANNER_START_DATE, currentAction.details)}
                                onChange={(date) => updateDetailCallback(DETAIL_PLANNER_START_DATE, date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                fullWidth
                                required
                                name={DETAIL_PLANNER_END_DATE}
                                helperText={errors.get(DETAIL_PLANNER_END_DATE) || ''}
                                error={errors.has(DETAIL_PLANNER_END_DATE)}
                                label={t('workflow_actions.form.visit_planner.end_date')}
                                InputProps={{
                                    endAdornment: (
                                        <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                            {t('workflow_actions.form.visit_planner.end_date.tooltip')}
                                        </Hint>
                                    ),
                                }}
                                format="MM/dd/yyyy"
                                value={getDetail(DETAIL_PLANNER_END_DATE, currentAction.details)}
                                onChange={(date) => updateDetailCallback(DETAIL_PLANNER_END_DATE, date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={t('workflow_actions.form.visit_planner.first_planning_date')}
                            data-testid="workflow_actions.form.visit_planner.first_planning_date"
                            type="number"
                            fullWidth
                            name={DETAIL_PLANNER_FIRST_DAY_OFFSET}
                            value={getDetail(DETAIL_PLANNER_FIRST_DAY_OFFSET, currentAction.details) || ''}
                            helperText={errors.get(DETAIL_PLANNER_FIRST_DAY_OFFSET) || ''}
                            error={errors.has(DETAIL_PLANNER_FIRST_DAY_OFFSET)}
                            onChange={onDetailChange}
                            InputProps={{
                                endAdornment: (
                                    <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                        {t('workflow_actions.form.visit_planner.first_planning_date.tooltip')}
                                    </Hint>
                                ),
                                inputProps: {
                                    min: formConstraints[DETAIL_PLANNER_FIRST_DAY_OFFSET].min,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: 9 }}>
                    {t('workflow_actions.form.visit_planner.business_hours')}
                    <Hint TooltipProps={{ className: 'tooltip-question' }} iProps={{ style: { marginLeft: 8 } }}>
                        {t('workflow_actions.form.visit_planner.business_hours.tooltip')}
                    </Hint>
                </Grid>
                <Grid item container xs={9} spacing={1} alignItems={'center'}>
                    <Grid item xs={5}>
                        <FormControl fullWidth error={errors.has(DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK)}>
                            <InputLabel shrink required>
                                {t('workflow_actions.form.visit_planner.business_hours.day_of_week')}
                            </InputLabel>
                            <MultipleSelect
                                required
                                name={DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK}
                                value={getDetail(DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK, currentAction.details, [])}
                                displayEmpty
                                onChange={onDetailChange}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>{t('undefined_picklist_option')}</em>;
                                    }
                                    return this.dayOfWeekValues()
                                        .filter((item) => selected.includes(item.value))
                                        .map((item) => item.label)
                                        .join(', ');
                                }}
                                fullWidth
                                data-testid="workflow_actions.form.visit_planner.business_hours.day_of_week"
                            >
                                {this.dayOfWeekValues().map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    !!getDetail(
                                                        DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK,
                                                        currentAction.details,
                                                        [],
                                                    ).find((arrayItem) => Number(arrayItem) === item.value)
                                                }
                                            />
                                            <ListItemText primary={item.label} />
                                        </MenuItem>
                                    );
                                })}
                            </MultipleSelect>
                            {errors.has(DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK) && (
                                <FormHelperText>
                                    {errors.get(DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK)}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                fullWidth
                                required
                                name={DETAIL_PLANNER_BUSINESS_HOURS_START_TIME}
                                helperText={errors.get(DETAIL_PLANNER_BUSINESS_HOURS_START_TIME) || ''}
                                error={errors.has(DETAIL_PLANNER_BUSINESS_HOURS_START_TIME)}
                                label={t('workflow_actions.form.visit_planner.business_hours.start_time')}
                                ampm={true}
                                value={this.getAsUTCTime(
                                    getDetail(DETAIL_PLANNER_BUSINESS_HOURS_START_TIME, currentAction.details),
                                )}
                                onChange={(time) =>
                                    updateDetailCallback(
                                        DETAIL_PLANNER_BUSINESS_HOURS_START_TIME,
                                        this.saveAsUTCTime(time),
                                    )
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                fullWidth
                                required
                                name={DETAIL_PLANNER_BUSINESS_HOURS_END_TIME}
                                helperText={errors.get(DETAIL_PLANNER_BUSINESS_HOURS_END_TIME) || ''}
                                error={errors.has(DETAIL_PLANNER_BUSINESS_HOURS_END_TIME)}
                                label={t('workflow_actions.form.visit_planner.business_hours.end_time')}
                                ampm={true}
                                value={this.getAsUTCTime(
                                    getDetail(DETAIL_PLANNER_BUSINESS_HOURS_END_TIME, currentAction.details),
                                )}
                                onChange={(date) =>
                                    updateDetailCallback(
                                        DETAIL_PLANNER_BUSINESS_HOURS_END_TIME,
                                        this.saveAsUTCTime(date),
                                    )
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: 9 }}>
                    {t('workflow_actions.form.visit_planner.default_visit_settings')}
                    <Hint TooltipProps={{ className: 'tooltip-question' }} iProps={{ style: { marginLeft: 8 } }}>
                        {t('workflow_actions.form.visit_planner.default_visit_settings.tooltip')}
                    </Hint>
                </Grid>
                <Grid item container xs={9} spacing={1} alignItems={'center'}>
                    <Grid item xs={4}>
                        <TextField
                            required
                            label={t('workflow_actions.form.visit_planner.default_visit_settings.days_between_visits')}
                            data-testid="workflow_actions.form.visit_planner.default_visit_settings.days_between_visits"
                            type="number"
                            fullWidth
                            name={DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS}
                            value={getDetail(DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS, currentAction.details)}
                            helperText={errors.get(DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS) || ''}
                            error={errors.has(DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS)}
                            onChange={onDetailChange}
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        {t('workflow_actions.form.visit_planner.days')}
                                        <Hint
                                            TooltipProps={{ className: 'tooltip-question' }}
                                            iProps={{ style: { marginLeft: 8 } }}
                                        >
                                            {t(
                                                'workflow_actions.form.visit_planner.default_visit_settings.days_between_visits.tooltip',
                                            )}
                                        </Hint>
                                    </React.Fragment>
                                ),
                                inputProps: {
                                    min: formConstraints[DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS].min,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            label={t('workflow_actions.form.visit_planner.default_visit_settings.visit_duration')}
                            data-testid="workflow_actions.form.visit_planner.default_visit_settings.visit_duration"
                            type="number"
                            fullWidth
                            name={DETAIL_PLANNER_DEFAULT_VISIT_DURATION}
                            value={getDetail(DETAIL_PLANNER_DEFAULT_VISIT_DURATION, currentAction.details)}
                            helperText={errors.get(DETAIL_PLANNER_DEFAULT_VISIT_DURATION) || ''}
                            error={errors.has(DETAIL_PLANNER_DEFAULT_VISIT_DURATION)}
                            onChange={onDetailChange}
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        {t('workflow_actions.form.visit_planner.minutes')}
                                        <Hint
                                            TooltipProps={{ className: 'tooltip-question' }}
                                            iProps={{ style: { marginLeft: 8 } }}
                                        >
                                            {t(
                                                'workflow_actions.form.visit_planner.default_visit_settings.visit_duration.tooltip',
                                            )}
                                        </Hint>
                                    </React.Fragment>
                                ),
                                inputProps: {
                                    min: formConstraints[DETAIL_PLANNER_DEFAULT_VISIT_DURATION].min,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

GeneralSection.propTypes = {
    currentAction: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    formConstraints: PropTypes.object.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    updateDetailCallback: PropTypes.func.isRequired,
};

const TranslatedGeneralSection = withTranslation('translations')(GeneralSection);
export { TranslatedGeneralSection as GeneralSection };
