import { directActionManager } from './DirectActionManager';
import { userManager } from '../UserManager';
import Button from './forms/fields/Button';

class BaseFormManager {
    handleFieldHandler(field, formContextData = {}) {
        const ids = [];

        for (let handler of field.getHandlers()) {
            ids.push(handler.id);
        }

        if (field instanceof Button && field.getIsActionButton()) {
            ids.push('closeForm');
        }

        if (ids.length === 0) {
            return;
        }

        directActionManager.runFormEvent(
            userManager.getCurrentAccount().id,
            this.form.getFormActionId(),
            ids,
            this.form.getEntityId(),
            { form: this.form.serializeWithApiNames(formContextData) },
            this.form.getContextGuid(),
        );
    }

    handleFieldChange(field, value) {
        field.setValue(value);
        this.refresh();
    }
}

export default BaseFormManager;
