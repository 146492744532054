import React from 'react';
import { FormControl, Grid, Icon, IconButton, ListSubheader, MenuItem, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../locales/i18n';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import { CHANGE_CUSTOM_API_NAME, CHANGE_TYPES } from './Action';
import FormulaInput from '../AbstractForm/FormulaInput';
import { CallContext } from '../../utils/CallContext';
import cloneDeep from 'lodash/cloneDeep';
import Confirmation from '../../Confirmation';

const t = i18n.t.bind(i18n);

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_action.change_item.drag_button">
        <Icon>dehaze</Icon>
    </IconButton>
));

export class ChangeItem extends React.PureComponent {
    handleChange = (e) => {
        const change = cloneDeep(this.props.change);
        change[e.target.name] = e.target.value;

        this.props.onChange(this.props.itemIndex, change);
    };

    handleRemove = () => {
        this.props.onRemove(this.props.itemIndex, this.props.change);
    };

    renderFieldsItems() {
        const items = [
            <MenuItem key={CHANGE_CUSTOM_API_NAME} value={CHANGE_CUSTOM_API_NAME}>
                {t('workflow_actions.form.update_form.changes.field_api_name.' + CHANGE_CUSTOM_API_NAME)}
            </MenuItem>,
        ];

        if (this.props.form && this.props.form.details.fields && this.props.form.details.fields.length > 0) {
            items.push(
                <ListSubheader key="__subheader__">
                    {t('workflow_actions.form.update_form.changes.field_api_name.fields')}
                </ListSubheader>,
            );
            for (let field of this.props.form.details.fields) {
                items.push(
                    <MenuItem key={field.apiName} value={field.apiName}>
                        {field.label}
                    </MenuItem>,
                );
            }
        }

        return items;
    }

    render() {
        const change = this.props.change;
        const showValueField = ![
            CHANGE_TYPES.SHOW_FIELD,
            CHANGE_TYPES.HIDE_FIELD,
            CHANGE_TYPES.ENABLE_FIELD,
            CHANGE_TYPES.DISABLE_FIELD,
            CHANGE_TYPES.MAKE_REQUIRED,
            CHANGE_TYPES.MAKE_OPTIONAL,
        ].includes(change.type);

        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1}>
                    <DragHandle />
                </Grid>
                <Grid item xs={10}>
                    <Grid container alignItems="flex-end" spacing={1}>
                        <Grid item xs={4}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                name="type"
                                value={change.type ?? ''}
                                onChange={this.handleChange}
                                data-testid="workflow_actions.form.update_form.changes.type"
                            >
                                {Object.values(CHANGE_TYPES).map((type) => {
                                    return (
                                        <MenuItem key={type} value={type}>
                                            {t('workflow_actions.form.update_form.changes.type.' + type)}
                                        </MenuItem>
                                    );
                                })}
                            </FormControlSelect>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                name="field_api_name"
                                value={change.field_api_name ?? ''}
                                onChange={this.handleChange}
                                data-testid="workflow_actions.form.update_form.changes.fields"
                            >
                                {this.renderFieldsItems()}
                            </FormControlSelect>
                        </Grid>
                        {showValueField && (
                            <Grid item xs={4}>
                                <FormControl fullWidth margin="dense">
                                    <FormulaInput
                                        fullWidth
                                        name="value"
                                        multiline
                                        label={null}
                                        onChange={this.handleChange}
                                        value={change.value ?? ''}
                                        callContext={this.props.callContext}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {change.field_api_name === CHANGE_CUSTOM_API_NAME && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <FormulaInput
                                        label={t('workflow_actions.form.update_form.changes.field_api_name_formula')}
                                        fullWidth
                                        multiline
                                        name="field_api_name_formula"
                                        onChange={this.handleChange}
                                        value={change.field_api_name_formula ?? ''}
                                        callContext={this.props.callContext}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Confirmation
                        text={t('workflow_actions.form.update_form.changes.remove')}
                        onConfirm={this.handleRemove}
                    >
                        <Tooltip title={t('remove')}>
                            <IconButton
                                color="secondary"
                                data-testid="workflow_actions.form.update_form.changes.remove"
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Tooltip>
                    </Confirmation>
                </Grid>
            </Grid>
        );
    }
}

ChangeItem.defaultProps = {
    form: null,
};

ChangeItem.propTypes = {
    itemIndex: PropTypes.number.isRequired,
    form: PropTypes.object,
    change: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
};

const SortableItem = SortableElement((props) => <ChangeItem {...props} />);

export default SortableItem;
