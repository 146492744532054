export const DETAIL_PLANNER_ACCOUNT_ID = 'accountId';
export const DETAIL_PLANNER_MODE = 'mode';
export const DETAIL_PLANNER_START_DATE = 'startDate';
export const DETAIL_PLANNER_END_DATE = 'endDate';
export const DETAIL_PLANNER_DEFAULT_DAYS_BETWEEN_VISITS = 'defaultDaysBetweenVisits';
export const DETAIL_PLANNER_DEFAULT_VISIT_DURATION = 'defaultVisitDuration';
export const DETAIL_PLANNER_CREATE_ROUTES = 'createRoutes';
export const DETAIL_PLANNER_BUSINESS_HOURS_DAYS_OF_WEEK = 'businessHoursDaysOfWeek';
export const DETAIL_PLANNER_BUSINESS_HOURS_START_TIME = 'businessHoursStartTime';
export const DETAIL_PLANNER_BUSINESS_HOURS_END_TIME = 'businessHoursEndTime';
export const DETAIL_PLANNER_FIRST_DAY_OFFSET = 'firstDayOffset';
export const DETAIL_PLANNER_USERS = 'users';
export const DETAIL_PLANNER_IN_ENTITY_DS_ID = 'inSourceId';
export const DETAIL_PLANNER_IN_ENTITY_ID = 'inId';
export const DETAIL_PLANNER_IN_ENTITY_USER_ID_FIELD = 'inUserIdField';
export const DETAIL_PLANNER_IN_ENTITY_DAYS_BETWEEN_VISITS_FIELD = 'inDaysBetweenVisitsField';
export const DETAIL_PLANNER_IN_ENTITY_VISIT_DURATION_FIELD = 'inVisitDurationField';
export const DETAIL_PLANNER_IN_ENTITY_CONDITION = 'inCondition';
export const DETAIL_PLANNER_IN_ENTITY_CONDITION_EXPRESSION = 'inConditionExpression';
export const DETAIL_PLANNER_IN_ENTITY_EXACT_POSITION_ONLY = 'inExactPositionOnly';
export const DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION = 'inFixedAssignmentCondition';
export const DETAIL_PLANNER_IN_ENTITY_FIXED_ASSIGNMENT_CONDITION_EXPRESSION = 'inFixedAssignmentConditionExpression';
export const DETAIL_PLANNER_OUT_ENTITY_CREATE = 'outCreate';
export const DETAIL_PLANNER_OUT_ENTITY_DS_ID = 'outSourceId';
export const DETAIL_PLANNER_OUT_ENTITY_ID = 'outId';
export const DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD = 'outRecordIdField';
export const DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD = 'outUserIdField';
export const DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD = 'outDateField';
export const DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD = 'outStartDatetimeField';
export const DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD = 'outEndDatetimeField';
export const DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD = 'outVisitDurationField';
export const DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD = 'outPlannerApiNameField';
export const DETAIL_PLANNER_VISITED_ENTITY_DS_ID = 'visitedSourceId';
export const DETAIL_PLANNER_VISITED_ENTITY_ID = 'visitedId';
export const DETAIL_PLANNER_VISITED_ENTITY_RECORD_ID_FIELD = 'visitedRecordIdField';
export const DETAIL_PLANNER_VISITED_ENTITY_USER_ID_FIELD = 'visitedUserIdField';
export const DETAIL_PLANNER_VISITED_ENTITY_TIME_FIELD = 'visitedTimeField';
export const DETAIL_PLANNER_VISITED_ENTITY_CONDITION = 'visitedCondition';
export const DETAIL_PLANNER_VISITED_ENTITY_CONDITION_EXPRESSION = 'visitedConditionExpression';
export const DETAIL_PLANNER_WINDOW_INCREASE_STEP = 'windowIncreaseStep';
