import React from 'react';
import noop from 'lodash/noop';
import { WithTranslation, withTranslation } from 'react-i18next';

import { FieldLookupType, FieldPolymorphicLookupData, IField, PicklistValue } from '../../../components/types';
import { Entity } from '../../../interfaces/entity';
import entityManagerFactory from '../../../service/EntityManager';
import entityViewManager from '../../../service/EntityViewManager';
import viewRecordManager from '../../../service/ViewRecordManager';

import LiveSearchTableViewDialog, { LiveSearchTableViewDialogProps } from '../LiveSearchTableViewDialog';
import AutoCompleteField from './AutoCompleteField';
import { FieldFactoryValueObject } from './FieldFactory';
import PicklistField, { ChangeSelectTargetEvent } from './PicklistField';

export type UniversalLookupReturnValue = {
    id: string;
    textValue?: string;
    data: {
        objectName: string;
        entityApiName: string;
        dataSourceApiName: string;
        typeFieldApiName: string;
    };
};

interface UniversalLookupFieldProps extends WithTranslation {
    accountId: LiveSearchTableViewDialogProps['accountId'];
    /** original record id, not related entity's */
    recordId?: string;
    value: string | number | FieldFactoryValueObject | null;
    name: string;
    label: string;
    disabled: boolean;
    autoFocus: boolean;
    lookupData: FieldPolymorphicLookupData;
    field: IField;
    valueId?: string;
    onChange: (value: UniversalLookupReturnValue) => void;
}

interface UniversalLookupFieldState {
    isSearchShown: boolean;
    value: any;
    hasChanged: boolean;
    entity: (Entity & { dsId: number; apiName: string; dsApiName: string }) | null;
}

class UniversalLookupField extends React.PureComponent<UniversalLookupFieldProps, UniversalLookupFieldState> {
    state: Readonly<UniversalLookupFieldState> = {
        isSearchShown: false,
        value: null,
        hasChanged: false,
        entity: null,
    };

    constructor(props: Readonly<UniversalLookupFieldProps>) {
        super(props);

        Object.assign(this.state, {
            value: props.value,
        });
    }

    handleShowSearch = () => {
        this.setState({ isSearchShown: true });
    };

    handleCloseSearch = () => {
        this.setState({ isSearchShown: false });
    };

    handleChangeLookup: LiveSearchTableViewDialogProps['onSaveRequest'] = (value) => {
        const { lookupData } = this.props;
        this.setState({ value, hasChanged: true, isSearchShown: false });

        this.props.onChange({
            ...value,
            data: {
                ...value.data,
                entityApiName: this.state.entity?.apiName!,
                dataSourceApiName: this.state.entity?.dsApiName!,
                typeFieldApiName: lookupData.entity_type_field,
            },
        });
    };

    handleRecordClick = async () => {
        const { accountId, field, valueId } = this.props;
        const { value, entity } = this.state;
        const dataSourceId = entity?.dsId;
        const { lookupData } = field;
        const loadedEntities = await entityManagerFactory.getManager(accountId, dataSourceId).getEntities();

        const recordId = value.id ?? valueId;

        if ('apiName' in lookupData!) {
            const entity = loadedEntities.find((e: any) => e.name === lookupData.apiName);
            if (!entity) {
                return;
            }

            return viewRecordManager.viewRecord(entity.id, recordId);
        }

        if (lookupData!.type === FieldLookupType.POLYMORPHIC) {
            const entity = loadedEntities.find((e: any) => e.name === value.apiName);
            if (!entity) {
                return;
            }

            viewRecordManager.viewRecord(entity.id, recordId);
        }
    };

    handleChangeEntity = (event: ChangeSelectTargetEvent) => {
        const selectedEntityId = event.target.value as number | null;
        if (!selectedEntityId) {
            return this.setState({ entity: null });
        }

        const entity = entityViewManager.getEntity(selectedEntityId);

        if (entity) {
            this.setState({ entity: entity });
        } else {
            this.setState({ entity: null });
        }
    };

    private preparePicklist = (): PicklistValue[] => {
        const entities = entityViewManager
            .getSources()
            .map((it: any) => it.entities)
            .reduce((acc: Entity[], cur: Entity[]) => acc.concat(cur), []);

        const result: PicklistValue[] = entities.map((entity: Entity) => ({
            value: entity.id,
            label: entity.label,
        }));

        return result;
    };

    render() {
        const { t, name, disabled, autoFocus } = this.props;
        const { value, isSearchShown } = this.state;

        const picklist = this.preparePicklist();

        return (
            <>
                <PicklistField
                    value={this.state.entity?.id ?? null}
                    name={name}
                    label={t('live_update_table_form.field_factory.polymorphic.object_text')}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onChangeSelect={this.handleChangeEntity}
                    picklist={picklist}
                    isSearchOfLabelInPicklist={false}
                />
                {this.state.entity !== null && (
                    <AutoCompleteField
                        value={value ? (value as FieldFactoryValueObject).textValue : null}
                        name={this.state.entity?.label}
                        label={t('live_update_table_form.field_factory.polymorphic.record_text')}
                        onShowSearch={this.handleShowSearch}
                        onRecordClick={this.handleRecordClick}
                    />
                )}
                {isSearchShown && <this.RenderSearchBlock />}
            </>
        );
    }

    RenderSearchBlock = () => {
        const { field, recordId } = this.props;
        const { value, entity } = this.state;

        return (
            <LiveSearchTableViewDialog
                accountId={this.props.accountId}
                dataSourceId={entity?.dsId!}
                onClose={this.handleCloseSearch}
                onSaveRequest={this.handleChangeLookup}
                onSaveMultiLookupRequest={noop}
                field={field}
                value={{ textValue: value, apiName: entity!.apiName }}
                recordId={recordId}
            />
        );
    };
}

const EnhancedComponent = withTranslation('translations', { withRef: true })(UniversalLookupField);

export default EnhancedComponent;
