export const LEVEL_ZIP = 'zip';
export const LEVEL_COUNTRY = 'country';
export const LEVEL_STATE = 'state';
export const LEVEL_COUNTY = 'county';
export const LEVEL_CITY = 'city';
export const LEVEL_STREET = 'street';
export const LEVEL_STREET_2 = 'street2';
export const LEVEL_HOUSE = 'house';

export const ORDERED_LEVELS = [
    LEVEL_HOUSE,
    LEVEL_STREET,
    LEVEL_STREET_2,
    LEVEL_CITY,
    LEVEL_COUNTY,
    LEVEL_STATE,
    LEVEL_ZIP,
    LEVEL_COUNTRY,
];

export class CompositeAddress {
    #zip;
    #country;
    #state;
    #county;
    #city;
    #street;
    #street2;
    #house;

    setLevel(level, value) {
        if (ORDERED_LEVELS.includes(level)) {
            this[level] = value;
        }

        return this;
    }

    isEmpty() {
        let isFilled =
            this.#street ||
            this.#street2 ||
            this.#city ||
            this.#country ||
            this.#state ||
            this.#county ||
            this.#zip ||
            this.#house;

        return !isFilled;
    }

    build() {
        const result = [];
        for (const level of ORDERED_LEVELS) {
            if (this[level]) {
                result[level] = this[level];
            }
        }
        return result;
    }
}
