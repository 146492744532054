import React from 'react';
import { Grid, MenuItem, Menu, Tooltip } from '@material-ui/core';
import { ACTION_TYPE_LIST } from '../../service/WorkflowActionManager';
import { userManager } from '../../service/UserManager';
import AddIconButton from '../CustomButton/AddIconButton';
import AutomationElementHeader from '../Workflow/AutomationElementHeader';
import i18n from '../../locales/i18n';
const t = i18n.t.bind(i18n);

export default class WorkflowActionsHeader extends AutomationElementHeader {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentAction: null,
            anchorEl: null,
        };
    }

    handleOpenMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleOpenModal = (action = null, type = null) => {
        this.handleCloseMenu();
        this.props.onOpenModal(action, type);
    };

    renderAddButton() {
        if (this.props.showAddButton) {
            return (
                <Grid item>
                    <Tooltip
                        title={this.props.addButtonHint ? t(this.props.addButtonHint) : ''}
                        disableHoverListener={!this.props.addButtonHint}
                        disableFocusListener={!this.props.addButtonHint}
                        disableTouchListener={!this.props.addButtonHint}
                    >
                        <div>
                            <AddIconButton
                                onClick={this.handleOpenMenu}
                                disabled={!userManager.automationElementsManagement()}
                            />
                        </div>
                    </Tooltip>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={this.state.anchorEl !== null}
                        onClose={this.handleCloseMenu}
                        disableAutoFocusItem
                    >
                        {ACTION_TYPE_LIST.map((type) => (
                            <MenuItem
                                key={type.value}
                                onClick={this.handleOpenModal.bind(this, null, type.value)}
                                data-testid="workflow_action.header"
                            >
                                {type.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            );
        }
        return null;
    }
}
