import { TIME_FIELDS } from './CalendarForm';
import moment from 'moment';
import { CalendarEvent, CalendarEventField } from '../../../service/Calendar/CalendarEventRepository';
import { isString } from 'lodash';
import { calendarManager } from '../../../service/Calendar/CalendarManager';
import { userManager } from '../../../service/UserManager';
// ts-ignore т.к. иначе выдаёт ошибку "Could not find a declaration file for module 'twig'."
// @ts-ignore
import Twig from 'twig';
import { Calendar, CalendarLocationValidate } from '../../../interfaces/calendar/calendar';
import { LocationValidity } from '../../../interfaces/geo';
import { emailValidate } from '../../../service/emailValidator';

type ValidationRules = {
    [key: string]: ValidationRule;
};

type ValidationRule = {
    required?: boolean;
    customValidationFunc?: (errors: Map<string, string>, calendarEvent: CalendarEvent) => Promise<string | null>;
};

const validationRules: ValidationRules = {
    title: {
        required: true,
    },
    endDatetime: {
        required: true,
        customValidationFunc: async (
            errors: Map<string, string>,
            calendarEvent: CalendarEvent,
        ): Promise<string | null> => {
            if (errors.has(TIME_FIELDS.START_TIME)) {
                return null;
            }

            return timeCustomValidationFunc(calendarEvent, 'calendar.form.error.end_time_smaller_than_start_time');
        },
    },
    startDatetime: {
        required: true,
        customValidationFunc: async (
            errors: Map<string, string>,
            calendarEvent: CalendarEvent,
        ): Promise<string | null> => {
            if (errors.has(TIME_FIELDS.END_TIME)) {
                return null;
            }

            return timeCustomValidationFunc(calendarEvent, 'calendar.form.error.start_time_bigger_than_end_time');
        },
    },
    location: {
        customValidationFunc: async (_: Map<string, string>, calendarEvent: CalendarEvent): Promise<string | null> => {
            if (calendarEvent.virtual) {
                return null;
            }
            const calendars = await calendarManager.get(userManager.getCurrentAccount().id);
            const calendar = calendars.get(calendarEvent.calendarId);
            if (!calendar || calendar.location.validate === CalendarLocationValidate.Any) {
                return null;
            }
            if (
                calendar.location.validate === CalendarLocationValidate.Exact &&
                calendarEvent.locationValidity === LocationValidity.Exact &&
                calendarEvent.lat &&
                calendarEvent.lng
            ) {
                return null;
            }

            if (
                calendar.location.validate === CalendarLocationValidate.Valid &&
                calendarEvent.lat &&
                calendarEvent.lng
            ) {
                return null;
            }

            return 'calendar.form.error.select_location_from_list';
        },
    },
    emails: {
        customValidationFunc: async (_: Map<string, string>, calendarEvent: CalendarEvent): Promise<string | null> => {
            const emailsField = calendarEvent.fields?.find(
                (field: CalendarEventField) => field.name === 'Attendees_Emails',
            );
            if (!emailsField) {
                return null;
            }

            const emails = Array.isArray(emailsField.value) ? emailsField.value : [emailsField.value];
            for (let i = 0; i < emails.length; i++) {
                if (!emailValidate(emails[i])) {
                    return 'validation_errors';
                }
            }

            return null;
        },
    },
};

const timeCustomValidationFunc = (calendarEvent: CalendarEvent, errorMessage: string): string | null => {
    const { startDatetime, endDatetime } = calendarEvent;

    if (!endDatetime || !startDatetime) {
        return null;
    }

    const momentStartTime = moment(startDatetime);
    const momentEndTime = moment(endDatetime);

    if (momentStartTime.isAfter(momentEndTime, calendarEvent.allDay ? 'day' : undefined)) {
        return errorMessage;
    }

    return null;
};

export const validateOne = async (
    name: keyof ValidationRules,
    errors: Map<string, string>,
    calendarEvent: CalendarEvent,
): Promise<Map<string, string>> => {
    if (!validationRules[name]) {
        return errors;
    }

    let error: string | null = null;

    const validationRule = validationRules[name];
    if (validationRule.required) {
        const value: any = calendarEvent[name as keyof CalendarEvent];
        if (!value || (isString(value) && value.trim() === '')) {
            error = 'calendar.form.error.should_not_be_empty';
        }
    }

    if (error) {
        errors.set(name as string, error);

        return errors;
    }

    if (!validationRule.customValidationFunc) {
        return errors;
    }

    error = await validationRule.customValidationFunc(errors, calendarEvent);
    if (error) {
        errors.set(name as string, error);

        return errors;
    }

    errors.delete(name as string);

    return errors;
};

export const validateForm = async (
    errors: Map<string, string>,
    calendarEvent: CalendarEvent,
): Promise<Map<string, string>> => {
    for (let name in validationRules) {
        errors = await validateOne(name, errors, calendarEvent);
    }

    return checkValidationRule(errors, calendarEvent);
};

const checkValidationRule = async (errors: Map<string, string>, calendarEvent: CalendarEvent) => {
    errors.delete('validationRule');
    const accountId = userManager.getCurrentAccount().id;
    const calendar = (await calendarManager.get(accountId)).get(calendarEvent.calendarId);
    if (!calendar) {
        return errors;
    }

    if (!isValidationRuleCheckPasses(calendar, calendarEvent)) {
        errors.set('validationRule', 'calendar.form.error.validation_rule');
    }

    return errors;
};

export const isValidationRuleCheckPasses = (calendar: Calendar, calendarEvent: CalendarEvent): boolean => {
    if (!calendar.validationRule || !calendar.validationRule.formula) {
        return true;
    }

    const context: any = { ...calendarEvent };
    if (calendarEvent.fields) {
        calendarEvent.fields.forEach((calendarEventField: CalendarEventField) => {
            context[calendarEventField.name] = calendarEventField.value;
        });
    }

    const condition = Twig.twig({ data: calendar.validationRule.formula });
    const result = condition.render(context);

    return result && result !== 'false';
};
