import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class UpdateLogManager extends BackendService {
    getUpdateLog(accountId, filters = [], sorting = [], page, limit) {
        const url = reverse(apiRoutes.account.updateLog.index, { accountId: accountId });
        return this.requestApi(url, 'GET', { filters: filters, sorting: sorting, page: page, limit: limit });
    }
}

export const logManager = new UpdateLogManager();
