import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import dsManagerFactory from '../../../service/DsManager';
import { userManager } from '../../../service/UserManager';
import { DataSource } from '../../../interfaces';
import { AdapterId, UserPropertiesTab } from '../../types';
import { Box, Button } from '@material-ui/core';
import DataSourceDialog from '../../DataSourceForm/DataSourceDialog';
import { STAGES } from '../../DataSourceForm/DataSourceWizard';
import { calendarManager } from '../../../service/Calendar/CalendarManager';
import { Calendar } from '../../../interfaces/calendar/calendar';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import DottedLink from '../../DottedLink';
import Hint from '../../Hint';
import { DataSourceEntityCounter, ExternalUser } from '../../../service/types';
import { userPropertiesManager } from '../../../service/UserForm';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

interface Props extends WithTranslation {}

interface State {
    connectedCalendarId?: string;
    googleCalendarDatasource?: DataSource.DataSource;
    isReconnectDialogOpen: boolean;
    reconnectDialogStage?: number;
    googleCalendar?: Calendar;
}

class GoogleCalendarConnectionButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isReconnectDialogOpen: false,
        };
    }

    async componentDidMount() {
        dispatcher.subscribe(events.CALENDAR_UPDATED, this, () => {
            this.updateCalendar();
        });

        const dsManager = dsManagerFactory.getManager(userManager.getCurrentAccount().id);
        const dataSources: DataSource.DataSource[] = await dsManager.list();

        const googleCalendarDatasource = dataSources.find(
            (ds: DataSource.DataSource) => ds.adapterId === AdapterId.GOOGLECALENDAR,
        );
        if (!googleCalendarDatasource) {
            return;
        }

        this.setState({ googleCalendarDatasource: googleCalendarDatasource }, async () => {
            await this.updateConnectedCalendar();
            await this.updateCalendar();
            if (
                userPropertiesManager.connectGoogleCalendar &&
                userPropertiesManager.isOpen &&
                userPropertiesManager.tab === UserPropertiesTab.TAB_CALENDAR
            ) {
                this.connect();
                userPropertiesManager.connectGoogleCalendar = false;
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    private updateCalendar = async (): Promise<void> => {
        const ds = this.state.googleCalendarDatasource;
        if (!ds || !ds.entityCounters) {
            return;
        }

        const entitiesMap = new Map<number, boolean>();
        ds.entityCounters.forEach((entityCounter: DataSourceEntityCounter) => {
            entitiesMap.set(entityCounter.entity.id, true);
        });

        const calendars = await calendarManager.get(userManager.getCurrentAccount().id);
        const googleCalendar = Array.from(calendars.values()).find((calendar: Calendar) =>
            entitiesMap.has(calendar.entityId),
        );

        this.setState({ googleCalendar });
    };

    private updateConnectedCalendar = async (updateExternalUsers?: boolean) => {
        const dsId = this.state.googleCalendarDatasource?.id;
        if (!dsId) {
            return;
        }

        const userCredentials = await dsManagerFactory
            .getManager(userManager.getCurrentAccount().id)
            .userCredentials(dsId);
        this.setState({ connectedCalendarId: userCredentials?.calendarId }, () => {
            if (!updateExternalUsers) {
                return;
            }

            this.updateExternalUsers();
        });
    };

    private connect = (): void => {
        if (this.state.connectedCalendarId || !this.state.googleCalendar?.active) {
            return;
        }

        this.setState({ isReconnectDialogOpen: true, reconnectDialogStage: STAGES.CONNECTION });
    };

    private disconnect = (): void => {
        const dsId = this.state.googleCalendarDatasource?.id;
        if (!dsId) {
            return;
        }

        dsManagerFactory
            .getManager(userManager.getCurrentAccount().id)
            .deleteUserCredentials(dsId)
            .then(() => {
                this.setState({ connectedCalendarId: undefined }, () => {
                    this.updateExternalUsers();
                    dispatcher.dispatch(events.GOOGLE_CALENDAR_DISCONNECTED, { dsId });
                });
            });
    };

    private updateExternalUsers = (): void => {
        const dsId = this.state.googleCalendarDatasource?.id;
        if (!dsId) {
            return;
        }

        const connectedCalendarId = this.state.connectedCalendarId;

        const clonedUser: any = cloneDeep(userManager.getCurrentUser());
        delete clonedUser.email;
        delete clonedUser.hasCrmAccount;
        delete clonedUser.leftReview;
        delete clonedUser.email;
        const googleCalendarExternalIndex = clonedUser.externalUsers.findIndex(
            (externalUser: ExternalUser) => externalUser.dataSourceId === dsId,
        );

        if (connectedCalendarId) {
            if (googleCalendarExternalIndex === -1) {
                clonedUser.externalUsers.push({ dataSourceId: dsId, externalId: String(clonedUser.id), accuracy: 3 });
            } else {
                clonedUser.externalUsers[googleCalendarExternalIndex].externalId = String(clonedUser.id);
            }
        } else {
            if (googleCalendarExternalIndex !== -1) {
                clonedUser.externalUsers[googleCalendarExternalIndex].externalId = '';
            }
        }

        if (!isEqual(clonedUser.externalUsers, userManager.getCurrentUser().externalUsers)) {
            userManager.saveUser(clonedUser);
        }
    };

    private activate = (): void => {
        const googleCalendar = this.state.googleCalendar;
        if (!googleCalendar) {
            return;
        }

        calendarManager.update(userManager.getCurrentAccount().id, {
            ...googleCalendar,
            active: !googleCalendar.active,
        });
    };

    private handleClose = (): void => {
        this.setState(
            (state: State) => {
                return {
                    reconnectDialogStage: !state.reconnectDialogStage ? STAGES.USER_PARAMS : undefined,
                    isReconnectDialogOpen: !state.reconnectDialogStage,
                };
            },
            async () => {
                if (!this.state.reconnectDialogStage) {
                    await this.updateConnectedCalendar(true);
                    userPropertiesManager.closeModal();
                }
            },
        );
    };

    private getButtonBlock = (): React.JSX.Element | null => {
        const { googleCalendarDatasource, googleCalendar } = this.state;
        if (!googleCalendarDatasource || !googleCalendar) {
            return null;
        }

        return googleCalendar.active ? this.getActiveCalendarButtonBlock() : this.getInactiveCalendarButtonBlock();
    };

    private getActiveCalendarButtonBlock = (): React.JSX.Element | null => {
        const { connectedCalendarId } = this.state;

        if (connectedCalendarId) {
            return (
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                    <span>
                        {this.props.t('users_calendar_preferences.modal.google_calendar.connected_to', {
                            calendarId: connectedCalendarId,
                        })}
                    </span>
                    <Button color="secondary" variant="contained" onClick={this.disconnect}>
                        {this.props.t('users_calendar_preferences.modal.google_calendar.disconnect')}
                    </Button>
                </Box>
            );
        }

        return (
            <Button color="primary" variant="contained" onClick={this.connect}>
                {this.props.t('users_calendar_preferences.modal.google_calendar.connect')}
            </Button>
        );
    };

    private getInactiveCalendarButtonBlock = (): React.JSX.Element | null => {
        if (userManager.isRoleAdmin()) {
            return (
                <Box display="flex" alignItems="center" style={{ gap: 15 }}>
                    {this.props.t('users_calendar_preferences.modal.google_calendar.inactive.admin')}
                    <Box display="flex" alignItems="center" gridGap={5} justifyContent="flex-start">
                        <DottedLink onClick={this.activate}>
                            {this.props.t('users_calendar_preferences.modal.google_calendar.inactive.admin.button')}
                        </DottedLink>
                        <Hint inheritStyles>
                            {this.props.t('users_calendar_preferences.modal.google_calendar.inactive.admin.hint')}
                        </Hint>
                    </Box>
                </Box>
            );
        }

        return <div>{this.props.t('users_calendar_preferences.modal.google_calendar.inactive.not_admin')}</div>;
    };

    render() {
        const { t } = this.props;
        const { googleCalendarDatasource, isReconnectDialogOpen, reconnectDialogStage, googleCalendar } = this.state;

        if (!googleCalendarDatasource || !googleCalendar) {
            return null;
        }

        return (
            <>
                <h4>{t('users_calendar_preferences.modal.google_calendar.title')}</h4>
                {this.getButtonBlock()}

                {isReconnectDialogOpen && (
                    <DataSourceDialog
                        open
                        disableEnforceFocus
                        dataSource={googleCalendarDatasource}
                        stage={reconnectDialogStage}
                        onClose={this.handleClose}
                        scroll="body"
                        autoConnect={true}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(GoogleCalendarConnectionButton);
