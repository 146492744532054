import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

interface StagesStepperProps {
    stagesShortNames: string[];
    activeStep: number;
}

class StagesStepper extends React.PureComponent<StagesStepperProps> {
    render() {
        const { stagesShortNames, activeStep } = this.props;

        return (
            <div style={{ margin: '0 -24px' }}>
                <Stepper className="stepper" alternativeLabel activeStep={activeStep - 1}>
                    {stagesShortNames.map((label, index) => {
                        return (
                            <Step key={label} completed={index < activeStep - 1}>
                                <StepLabel
                                    className="step-label"
                                    optional={<span className="step-text">{label}</span>}
                                ></StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
        );
    }
}

export default StagesStepper;
