import React from 'react';
import PropTypes from 'prop-types';
import { TRIGGER_CREATED, TRIGGER_DELETED, TRIGGER_EDITED } from './WorkflowRules';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class TriggerTypeControl extends React.Component {
    handleTriggerTypes = (event) => {
        const type = event.target.value;
        const isChecked = event.target.checked;

        let value = this.props.value;
        if (isChecked) {
            value = this.addTriggerType(value, type);
        } else {
            value = this.removeTriggerType(value, type);
        }

        this.props.onChange(value);
    };

    hasTriggerType = (value, type) => {
        return Array.isArray(value) && value.indexOf(type) !== -1;
    };

    addTriggerType = (value, type) => {
        if (!this.hasTriggerType(value, type)) {
            value.push(type);
        }
        return value;
    };

    removeTriggerType = (value, ...types) => {
        return value.filter((t) => types.indexOf(t) === -1);
    };

    render() {
        const { value } = this.props;
        return (
            <FormControl fullWidth margin="dense" component="fieldset" error={this.props.errors.has('triggerTypes')}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="triggerTypes"
                            value={TRIGGER_CREATED}
                            checked={this.hasTriggerType(value, TRIGGER_CREATED)}
                            onChange={this.handleTriggerTypes}
                            color="primary"
                        />
                    }
                    label={this.props.t('workflow_rules.form.trigger_types.created')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="triggerTypes"
                            value={TRIGGER_EDITED}
                            checked={this.hasTriggerType(value, TRIGGER_EDITED)}
                            onChange={this.handleTriggerTypes}
                            color="primary"
                        />
                    }
                    label={this.props.t('workflow_rules.form.trigger_types.edited')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="triggerTypes"
                            value={TRIGGER_DELETED}
                            checked={this.hasTriggerType(value, TRIGGER_DELETED)}
                            onChange={this.handleTriggerTypes}
                            color="primary"
                        />
                    }
                    label={this.props.t('workflow_rules.form.trigger_types.deleted')}
                />
                {this.props.errors.has('triggerTypes') && (
                    <FormHelperText>{this.props.errors.get('triggerTypes')}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

TriggerTypeControl.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(TriggerTypeControl);
