import { Grid, Tooltip, Typography } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Calculator from '../../service/ImportMessages/Calculator';
import './style.css';
import { DataSource, LEVEL, ORIGIN, SortedDsMessages } from '../../service/ImportMessages/types';
import copy from 'copy-to-clipboard';
import { Account } from 'interfaces';
import DottedLink from '../DottedLink';
import MessagesViewDialog from './MessagesViewDialog';
import MessageText from './MessageText';

interface Props extends WithTranslation, WithStyles {
    dataSource: DataSource;
    subscription?: null | Account.Subscription;
}

interface State {
    showModalLevel: null | LEVEL;
}

class DataSourceMessages extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModalLevel: null,
        };
    }

    handleOpenModal = (level: LEVEL) => {
        this.setState({ showModalLevel: level });
    };

    handleCloseModal = () => {
        this.setState({ showModalLevel: null });
    };

    render() {
        const { dataSource, subscription } = this.props;
        const { showModalLevel } = this.state;

        if (!dataSource.importMessages) {
            return null;
        }
        const sortedInfoMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.INFO], subscription);
        const sortedWarningMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.WARNING], subscription);
        const sortedErrorMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.ERROR], subscription);
        return (
            <>
                {this.renderMessages(sortedErrorMessages, LEVEL.ERROR)}
                {this.renderMessages(sortedWarningMessages, LEVEL.WARNING)}
                {this.renderMessages(sortedInfoMessages, LEVEL.INFO)}
                {showModalLevel && (
                    <MessagesViewDialog
                        dataSources={[dataSource]}
                        level={showModalLevel}
                        onClose={this.handleCloseModal}
                        subscription={subscription ?? null}
                    />
                )}
            </>
        );
    }

    renderMessages = (sortedMessages: SortedDsMessages[], level: LEVEL) => {
        const { t } = this.props;
        if (sortedMessages.length === 0) {
            return null;
        }
        const sortedDsMessages: SortedDsMessages = sortedMessages[0];
        return (
            <>
                {sortedDsMessages.messages.map((message) => {
                    const messageText =
                        message.origin === ORIGIN.INTERNAL
                            ? message.message
                            : '[' + message.httpCode + '] ' + message.message;
                    return (
                        <Alert
                            key={message.id}
                            severity={level}
                            classes={this.props.classes}
                            className="c-import-messages-object-and-fields-alert"
                            onClick={() => copy(messageText)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Grid container direction="row" wrap="nowrap">
                                <Grid item xs zeroMinWidth>
                                    <Tooltip
                                        title={t('data_source.import_messages.tooltip.copy_to_clipboard') as string}
                                    >
                                        <Typography noWrap>
                                            <MessageText text={messageText} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item style={{ marginLeft: 5 }}>
                                    <DottedLink
                                        onClick={() => this.handleOpenModal(message.level)}
                                        data-testid="data_source.import_messages.open_button"
                                    >
                                        {this.props.t('data_source.import_messages.open_button')}
                                    </DottedLink>
                                </Grid>
                            </Grid>
                        </Alert>
                    );
                })}
            </>
        );
    };
}

const styles = {
    message: {
        width: 'calc(100% - 30px)',
    },
};

export default withTranslation('translations', { withRef: true })(withStyles(styles)(DataSourceMessages));
