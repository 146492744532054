import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';

const TYPES = {
    FORMULA: 'formula',
    STATIC: 'static',
    FIELD: 'field',
};

export default class PickList extends AbstractField {
    constructor() {
        super(FIELD_TYPES.PICK_LIST);

        this.items = [];
        this.itemsFormula = '';
        this.itemsType = TYPES.STATIC;
        this.itemsEntityId = null;
        this.itemsFieldId = null;
        this.isMultiple = false;
        this.isAlphabeticallySorted = false;
    }

    setItemsFormula(itemsFormula) {
        this.itemsFormula = itemsFormula;

        return this;
    }

    getItemsFormula() {
        return this.itemsFormula;
    }

    setItemsType(itemsType) {
        this.itemsType = itemsType;

        return this;
    }

    getItemsType() {
        return this.itemsType;
    }

    setItemsEntityId(entityId) {
        this.itemsEntityId = entityId;

        return this;
    }

    getItemsEntityId() {
        return this.itemsEntityId;
    }

    setItemsFieldId(fieldId) {
        this.itemsFieldId = fieldId;

        return this;
    }

    getItemsFieldId() {
        return this.itemsFieldId;
    }

    setItems(items) {
        if (!Array.isArray(items)) {
            try {
                items = JSON.parse(items);
            } catch (error) {
                items = [];
            }
        }

        this.items = items;

        return this;
    }

    getItems() {
        return this.items;
    }

    getItemLabel(value) {
        const item = this.items.find((item) => item.value === value);
        return item ? item.label : value;
    }

    setIsMultiple(isMultiple) {
        this.isMultiple = isMultiple;

        return this;
    }

    getIsMultiple() {
        return this.isMultiple;
    }

    setIsAlphabeticallySorted(isAlphabeticallySorted) {
        this.isAlphabeticallySorted = isAlphabeticallySorted;

        return this;
    }

    getIsAlphabeticallySorted() {
        return this.isAlphabeticallySorted;
    }

    getValue() {
        return this.value ? this.value : this.getIsMultiple() ? [] : null;
    }

    isEmpty() {
        if (this.getIsMultiple()) {
            return this.getValue().length === 0;
        } else {
            return this.getValue() === null;
        }
    }

    validateValue() {
        if (Array.isArray(this.getValue())) {
            let result = 1;
            for (let value of this.getValue()) {
                result &= this.validateSingleValue(value);
            }
            return result === 1;
        } else {
            return this.validateSingleValue(this.getValue());
        }
    }

    validateSingleValue(value) {
        for (let item of this.items) {
            if (String(item.value) === String(value)) {
                return true;
            }
        }

        return false;
    }
}

export { TYPES };
