import { LatLngExpression, Marker, MarkerOptions } from 'leaflet';
import { MapPage } from 'interfaces';

class LeafletRoutePointMarker extends Marker {
    routePointsInfo: MapPage.MarkerRoutePointInfo[];

    constructor(latlng: LatLngExpression, routePointsInfo: MapPage.MarkerRoutePointInfo[], options?: MarkerOptions) {
        super(latlng, options);

        this.routePointsInfo = routePointsInfo;
    }

    addRoutePointInfo(routePointInfo: MapPage.MarkerRoutePointInfo) {
        this.routePointsInfo.push(routePointInfo);
    }
}

export default LeafletRoutePointMarker;
