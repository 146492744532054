import React, { ChangeEvent, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Hint from '../../Hint';
import { FormControl, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { MultipleSelect } from '../../Controls';
import { observer } from 'mobx-react';
import { routingSettingsSkillsFormManager } from 'service/MapPage/RoutingSettings';
import { Account } from '../../../interfaces';

interface HasSkillsInputProps extends WithTranslation {
    account: Account.Account;
    value: string[] | null;
    onValueChanged: (value: string[] | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

const HasSkillsInput: React.FC<HasSkillsInputProps> = (props) => {
    const { t, value, onValueChanged, error, disabled, bareInput, account } = props;

    useEffect(() => {
        const { skillsData } = account.routingSettings;
        routingSettingsSkillsFormManager.initSettings(skillsData);
    }, [account]);

    const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const targetValue = event.target.value as string[];
        const value = targetValue.length > 0 ? targetValue : null;
        onValueChanged(value).then(() => validate(value));
    };

    // @ts-ignore unused variable
    const validate = (value: string[] | null): Promise<void> => {
        const { onInputValidation } = props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };

    const { skills } = routingSettingsSkillsFormManager;

    const arrayValue = value || [];

    return (
        <Grid container>
            <Grid item xs>
                <FormControl fullWidth margin={bareInput ? 'none' : 'dense'} error={error !== undefined}>
                    {!bareInput && (
                        <InputLabel shrink={true}>
                            {t('routing_settings.dialog.tabs.users.form_control.has_skills.label')}
                        </InputLabel>
                    )}
                    <MultipleSelect
                        error={!!error}
                        disabled={disabled}
                        margin={bareInput ? 'none' : 'dense'}
                        fullWidth
                        value={arrayValue}
                        onChange={handleChange}
                        displayEmpty
                        renderValue={(selected: string[]) => {
                            if (selected.length === 0) {
                                return <em>{t('route_editor.truck_settings_form.not_selected')}</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {skills.map((skill) => (
                            <MenuItem key={skill} value={skill}>
                                {skill}
                            </MenuItem>
                        ))}
                    </MultipleSelect>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                <Hint iProps={{ style: { marginLeft: 8 } }}>
                    {t('route_editor.modal.routing_preferences.modal.has_skills.hint')}
                </Hint>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(observer(HasSkillsInput));
