import React from 'react';
import { Grid as DataGrid, PagingPanel, Table, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import {
    Column,
    CustomPaging,
    DataTypeProvider,
    FilteringState,
    PagingPanel as PagingPanelBase,
    PagingState,
    Sorting,
    SortingState,
    Table as TableBase,
    TableFilterRow as TableFilterRowBase,
    TableHeaderRow as TableHeaderRowBase,
} from '@devexpress/dx-react-grid';
import { TableLoadingState } from '../TableLoadingState';
import {
    BOOLEAN_FILTER_OPERATIONS,
    DATE_FILTER_OPERATIONS,
    DISTANCE_FILTER_OPERATIONS,
    Filter,
    FOREIGN_LOOKUP_FILTER_OPERATIONS,
    getDateFilterValue,
    getDateTimeFilterValue,
    getNumericFilterValue,
    MULTI_LOOKUP_FILTER_OPERATIONS,
    NUMERIC_FILTER_OPERATIONS,
    POLYMORPHIC_LOOKUP_FILTER_OPERATIONS,
    STRING_FILTER_OPERATIONS,
} from '../utils/tableFilter';
import './style.css';
import debounce from 'lodash/debounce';
import { TFunction } from 'react-i18next';
import i18n from '../../locales/i18n';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import { FIELD_DISTANCE } from '../Map/constants';
import { DataTableColumn, DataTableProps, DataTableState, DataTableStructure } from './types';
import { FilterIcon, HeaderCellContent, SortLabel, StyledCell, StyledFilterCell, StyledHeaderCell } from './index';
import {
    TYPE_BIGINT,
    TYPE_BOOLEAN,
    TYPE_DATE,
    TYPE_DATETIME,
    TYPE_FLOAT,
    TYPE_INTEGER,
    TYPE_STRING,
    TYPE_TEXT,
    TYPE_TEXT_ARRAY,
    TYPE_FILES,
} from '../../service/types';
import { TableHeaderRow } from '../Grid';
import { userTimezoneToUtc } from 'utils';
import { userManager } from 'service/UserManager';
import dispatcher from 'service/dispatcher';
import events from '../../events';

const t = i18n.t.bind(i18n);

export interface RequestParams {
    filters: Filter[];
    sorting: Sorting[];
    currentPage: number;
    pageSize: number;
}

export interface RequestData<T> {
    items: T[];
    total: number;
}

class DataTable<T, P extends DataTableProps<T>, S extends DataTableState<T>> extends React.PureComponent<P, S> {
    public filterDelay: number = 500;
    public pageSizes: number[] = [10, 25, 50, 100];
    public defaultPageSize: number = 10;

    public sorting: Sorting[] = [];
    public filters: Filter[] = [];

    public numericFilterOperations = NUMERIC_FILTER_OPERATIONS;
    public distanceFilterOperations = DISTANCE_FILTER_OPERATIONS;
    public stringFilterOperations = STRING_FILTER_OPERATIONS;
    public multiLookupFilterOperations = MULTI_LOOKUP_FILTER_OPERATIONS;
    public polymorphicLookupFilterOperations = POLYMORPHIC_LOOKUP_FILTER_OPERATIONS;
    public foreignLookupFilterOperations = FOREIGN_LOOKUP_FILTER_OPERATIONS;
    public booleanFilterOperations = BOOLEAN_FILTER_OPERATIONS;
    public dateFilterOperations = DATE_FILTER_OPERATIONS;

    public typesOfColumns: Map<string, string>;
    public InteractiveRow: React.ComponentType<TableBase.DataRowProps>;
    public filterMessages: TableFilterRowBase.LocalizationMessages;
    public pagingPanelMessages: PagingPanelBase.LocalizationMessages;

    constructor(props: P) {
        super(props);

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
            totalCount: 0,
        } as S;

        this.typesOfColumns = new Map();
        this.InteractiveRow = ({ children, row, ...rest }) => {
            return (
                <Table.Row row={row} {...rest} onClick={() => this.handleRowClick(row)}>
                    {children}
                </Table.Row>
            );
        };

        this.handleFiltersChanged = debounce(this.handleFiltersChanged.bind(this), this.filterDelay);

        this.filterMessages = DataTable.getFilterMessages(t);
        this.pagingPanelMessages = DataTable.getPagingMessages(t);
    }

    getFilters() {
        return this.convertFrontFiltersToServerFilters(this.filters);
    }

    static getFilterMessages = (t: TFunction) => {
        return {
            filterPlaceholder: t('react_grid.filters.filterPlaceholder'),
            contains: t('react_grid.filters.contains'),
            notContains: t('react_grid.filters.notContains'),
            startsWith: t('react_grid.filters.startsWith'),
            endsWith: t('react_grid.filters.endsWith'),
            equal: t('react_grid.filters.equal'),
            notEqual: t('react_grid.filters.notEqual'),
            greaterThan: t('react_grid.filters.greaterThan'),
            greaterThanOrEqual: t('react_grid.filters.greaterThanOrEqual'),
            lessThan: t('react_grid.filters.lessThan'),
            lessThanOrEqual: t('react_grid.filters.lessThanOrEqual'),
            between: t('data_table.between'),
            isEmpty: t('data_table.is_empty'),
            isNotEmpty: t('data_table.is_not_empty'),
            clearFilter: t('data_table.clear_filter'),
        } as TableFilterRowBase.LocalizationMessages;
    };

    static getPagingMessages = (t: TFunction) => {
        return {
            showAll: t('react_grid.paging_panel.show_all'),
            rowsPerPage: t('react_grid.paging_panel.row_of_page'),
            info: t('react_grid.paging_panel.description'),
        } as PagingPanelBase.LocalizationMessages;
    };

    setupStructure(fields: DataTableColumn<T>[]) {
        this.setState({
            structure: this.buildStructure(fields),
            totalCount: 0,
            records: null,
            pagination: {
                current: 0,
                size: this.state.pagination.size,
            },
        });
    }

    buildStructure(fields: DataTableColumn<T>[]): DataTableStructure<T> {
        return this.buildDefaultStructure(fields);
    }

    buildDefaultStructure(fields: DataTableColumn<T>[]): DataTableStructure<T> {
        const numericColumns = [];
        const distanceColumns = [];
        const dateColumns = [];
        const dateTimeColumns = [];
        const stringColumns = [];
        const multiSelectColumns = [];
        const selectColumns = [];
        const booleanColumns = [];
        const noDataColumns = [];
        const columns = [];
        const exts: Table.ColumnExtension[] = [];
        const webLinkColumns = [];
        const territoriesColumns = [];
        const multiLookupColumns = [];
        const polymorphicLookupColumns = [];
        const foreignLookupColumns = [];

        this.typesOfColumns = new Map();

        for (let field of fields) {
            columns.push({
                id: field.id,
                name: field.columnName || field.name,
                title: field.title,
                type: field.type,
                lookup: field.lookup || null,
                picklist: field.picklist || [],
                getCellValue: field.getCellValue || null,
                defaultValue: field.defaultValue,
            } as DataTableColumn<T>);
            if (field.type) {
                this.typesOfColumns.set(field.columnName, field.type);
            }

            if (field.isLink) {
                webLinkColumns.push(field.columnName);
                continue;
            }

            if (field.type === 'json' && field.isMultiLookup) {
                multiLookupColumns.push(field.columnName);
                continue;
            }

            if (field.isPolymorphicLookup) {
                polymorphicLookupColumns.push(field.columnName);
                continue;
            }

            if (field.isForeignLookup) {
                foreignLookupColumns.push(field.columnName);
                continue;
            }

            if (field.name === 'mapsly_territories') {
                territoriesColumns.push(field.columnName);
                continue;
            }

            if (field.columnName === FIELD_DISTANCE) {
                distanceColumns.push(field.columnName);
                continue;
            }

            if (field.type === TYPE_TEXT_ARRAY || field.type === TYPE_FILES) {
                multiSelectColumns.push(field.columnName);
                continue;
            }

            if (field.picklist) {
                selectColumns.push(field.columnName);
                continue;
            }

            switch (field.type) {
                case TYPE_INTEGER:
                case TYPE_BIGINT:
                case TYPE_FLOAT:
                    numericColumns.push(field.columnName);
                    break;
                case TYPE_STRING:
                case TYPE_TEXT:
                    stringColumns.push(field.columnName);
                    break;
                case TYPE_BOOLEAN:
                    booleanColumns.push(field.columnName);
                    break;
                case TYPE_DATE:
                    dateColumns.push(field.columnName);
                    break;
                case TYPE_DATETIME:
                    dateTimeColumns.push(field.columnName);
                    break;
                default:
                    noDataColumns.push(field.columnName);
            }
        }

        const noSortingColumns = noDataColumns.map((column) => ({ columnName: column, sortingEnabled: false }));
        for (let column of polymorphicLookupColumns) {
            noSortingColumns.push({ columnName: column, sortingEnabled: false });
        }

        return {
            numericColumns,
            distanceColumns,
            dateColumns,
            dateTimeColumns,
            stringColumns,
            booleanColumns,
            noDataColumns,
            columns,
            exts,
            webLinkColumns,
            territoriesColumns,
            multiLookupColumns,
            multiSelectColumns,
            selectColumns,
            noSortingColumns,
            polymorphicLookupColumns,
            foreignLookupColumns,
        };
    }

    getFields(): Promise<DataTableColumn<T>[]> {
        return Promise.resolve([]) as any;
    }

    handleRowClick = (row: T) => {
        this.props.onRowClick && this.props.onRowClick(row);
    };

    componentDidMount() {
        this.getFields().then((columns) => {
            this.setupStructure(columns); /// async?
            this.loadData();
        });

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            const filterWithDateTime = this.filters.find(
                (filter) => this.typesOfColumns.get(filter.columnName) === TYPE_DATETIME && !!filter.value,
            );
            if (filterWithDateTime !== undefined) {
                this.loadData();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleSortingChanged = (sorting: Sorting[]) => {
        this.sorting = sorting;
        this.loadData();
    };

    handleFiltersChanged = (filters: Filter[]) => {
        const f = [];
        for (let filter of filters) {
            try {
                // validate only: convertFrontFilterToServerFilter throws Error if filter value is invalid
                this.convertFrontFilterToServerFilter(filter);
                f.push(filter);
            } catch (e) {}
        }
        if (JSON.stringify(this.filters) === JSON.stringify(f)) {
            return;
        }
        this.applyFilters(f);
    };

    convertFrontFiltersToServerFilters(filters: Filter[]) {
        const f = [];
        for (let filter of filters) {
            try {
                f.push(this.convertFrontFilterToServerFilter(filter));
            } catch (e) {}
        }

        return f;
    }

    convertFrontFilterToServerFilter(filter: Filter) {
        const result = { ...filter };
        const type = this.typesOfColumns.get(filter.columnName);
        if (type === TYPE_INTEGER || type === TYPE_BIGINT || type === TYPE_FLOAT) {
            result.value = getNumericFilterValue(filter);
            return result;
        }
        if (type === TYPE_DATE) {
            result.value = getDateFilterValue(filter);
            return result;
        }
        if (type === TYPE_DATETIME) {
            result.value = getDateTimeFilterValue(filter);
            if (result.value === null) {
                return result;
            }

            const user = userManager.getCurrentUser();
            if (!user) {
                return result;
            }

            if (Array.isArray(result.value)) {
                result.value = result.value.map((v) => userTimezoneToUtc(v, user));
            } else {
                result.value = userTimezoneToUtc(result.value, user);
            }
            return result;
        }
        return result;
    }

    applyFilters(filters: Filter[]) {
        const timer = Timer.init(TIMER_OPERATIONS.DataTable.applyFilters, true).startChild(
            TIMER_OPERATIONS.DataTable.applyFilters,
        );
        this.filters = filters;
        this.loadData(true, timer);
    }

    handleCurrentPageChanged = (currentPage: number) => {
        this.setState(
            (state: DataTableState<T>) => {
                const pagination = { ...state.pagination, current: currentPage };
                return {
                    records: null,
                    pagination,
                };
            },
            () => this.loadData(false),
        );
    };

    handlePageSizeChanged = (pageSize: number) => {
        this.setState(
            (state: DataTableState<T>) => {
                const pagination = { ...state.pagination, size: pageSize, current: 0 };
                return {
                    records: null,
                    pagination,
                };
            },
            () => this.loadData(),
        );
    };

    requestData(_ignorePage = false, _parentTimer: Timer | null = null): Promise<RequestData<T>> {
        return null as any;
    }

    loadData(clearPage = true, timer: Timer | null = null) {
        if (timer instanceof Timer) {
            timer.tryTerminateAfter(3000);
        }
        const promise = this.requestData(clearPage, timer);
        if (promise === null) {
            return;
        }

        promise
            .then((data) => {
                this.setState((state: DataTableState<T>) => {
                    return {
                        records: data.items,
                        totalCount: data.total,
                        pagination: {
                            current: clearPage ? 0 : state.pagination.current,
                            size: state.pagination.size,
                        },
                    };
                });
            })
            .catch((error) => {
                this.props.onLoadingError && this.props.onLoadingError(error.message);
                this.setState((state) => {
                    return {
                        records: [],
                        totalCount: 0,
                        pagination: {
                            current: 0,
                            size: state.pagination.size,
                        },
                    };
                });
            })
            .finally(() => {
                if (timer) {
                    timer.end();
                }
            });
    }

    render() {
        const structure = this.state.structure;

        if (structure === null) {
            return <div>{t('loading')}</div>;
        }

        const records = this.props.records || this.state.records;
        const total = this.props.total || this.state.totalCount;

        const recordsAreLoading = records === null;

        return (
            <DataGrid rows={(records || []) as any[]} columns={structure.columns as Column[]}>
                <SortingState
                    onSortingChange={this.handleSortingChanged}
                    columnExtensions={structure.noSortingColumns}
                />
                <DataTypeProvider
                    for={structure.numericColumns}
                    availableFilterOperations={this.numericFilterOperations}
                />
                <DataTypeProvider for={structure.dateColumns} availableFilterOperations={this.dateFilterOperations} />
                <DataTypeProvider
                    for={structure.dateTimeColumns}
                    availableFilterOperations={this.dateFilterOperations}
                />
                <DataTypeProvider
                    for={structure.stringColumns}
                    availableFilterOperations={this.stringFilterOperations}
                />
                <DataTypeProvider
                    for={structure.booleanColumns}
                    availableFilterOperations={this.booleanFilterOperations}
                />
                <FilteringState defaultFilters={this.filters as any[]} onFiltersChange={this.handleFiltersChanged} />
                <Table
                    rowComponent={this.InteractiveRow}
                    columnExtensions={structure.exts}
                    cellComponent={StyledCell as React.ComponentType<TableBase.DataCellProps>}
                    noDataCellComponent={() => (
                        <TableLoadingState columnCount={structure.columns.length} loading={recordsAreLoading} />
                    )}
                />
                <TableHeaderRow
                    cellComponent={StyledHeaderCell as React.ComponentType<TableHeaderRowBase.CellProps>}
                    contentComponent={HeaderCellContent}
                    sortLabelComponent={SortLabel as React.ComponentType<TableHeaderRowBase.SortLabelProps>}
                    columnTitles={structure.columnTitles}
                    showSortingControls
                />
                <TableFilterRow
                    showFilterSelector
                    iconComponent={FilterIcon}
                    cellComponent={StyledFilterCell as React.ComponentType<TableFilterRowBase.CellProps>}
                    messages={this.filterMessages}
                />

                <PagingState
                    currentPage={this.state.pagination.current}
                    onCurrentPageChange={this.handleCurrentPageChanged}
                    onPageSizeChange={this.handlePageSizeChanged}
                    pageSize={this.state.pagination.size}
                />
                <CustomPaging totalCount={total} />
                <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
            </DataGrid>
        );
    }
}

export default DataTable;
