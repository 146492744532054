import { autorun, reaction, IReactionDisposer } from 'mobx';

export interface IReactionPublic {
    dispose(): void;

    trace(enterBreakPoint?: boolean): void;
}

class ReactionsManager {
    private disposers = new Map<string, IReactionDisposer[]>();

    addReaction<T>(
        tag: string,
        expression: (r: IReactionPublic) => T,
        effect: (arg: T, prev: T, r: IReactionPublic) => void,
        opts: any = {},
    ) {
        const disposers = this.disposers.get(tag) || [];

        disposers.push(reaction(expression, effect, opts));
        this.disposers.set(tag, disposers);
    }

    addAutorun(tag: string, view: (r: IReactionPublic) => any, opts: any = {}) {
        const disposers = this.disposers.get(tag) || [];

        disposers.push(autorun(view, opts));
        this.disposers.set(tag, disposers);
    }

    dispose(tag: string) {
        const disposers = this.disposers.get(tag) || [];
        for (let disposer of disposers) {
            disposer();
        }
    }
}

export default ReactionsManager;
