import React from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import Decimal from '../../../service/workflow_actions/forms/fields/Decimal';
import i18n from '../../../locales/i18n';

const t = i18n.t.bind(i18n);

class DecimalField extends AbstractField {
    renderInput(field, errors) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        type="number"
                        value={field.getValue()}
                        error={errors.size > 0}
                        helperText={errors.get('value') ? t(errors.get('value')) : ''}
                        InputProps={{
                            min: field.getMinValue(),
                            max: field.getMaxValue(),
                            step: field.getIsDecimal() ? 0.1 : 1,
                            className: 'field-Input',
                        }}
                        className="field-decimal-input"
                        inputProps={{ className: 'field-input' }}
                        onChange={this.handleChange}
                        onBlur={this.handleValidate}
                        variant="outlined"
                        disabled={field.getIsDisabled()}
                        autoFocus={this.props.autoFocus}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {this.props.field.getMinValue() !== null && (
                            <Grid item>
                                <Typography display="inline" component="span" color="textSecondary">
                                    {t('workflow_actions.forms.field.decimal.minValue')}:{' '}
                                    {this.props.field.getMinValue()}
                                </Typography>
                            </Grid>
                        )}
                        {this.props.field.getMaxValue() !== null && (
                            <Grid item>
                                <Typography display="inline" component="span" color="textSecondary">
                                    {t('workflow_actions.forms.field.decimal.maxValue')}:{' '}
                                    {this.props.field.getMaxValue()}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

DecimalField.defaultProps = {
    autoFocus: false,
};

DecimalField.propTypes = {
    field: PropTypes.instanceOf(Decimal).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default DecimalField;
