import React from 'react';
import { Input, InputLabel, FormHelperText, FormControl } from '@material-ui/core';

class FormControlInput extends React.PureComponent {
    render() {
        const { error, label, helperText, children, ...props } = this.props;

        return (
            <FormControl fullWidth margin="dense" error={error}>
                <InputLabel required>{label}</InputLabel>
                <Input {...props}>{children}</Input>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

export default FormControlInput;
