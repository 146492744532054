import { Tooltip } from '@material-ui/core';
import { ErrorOutlineOutlined, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Calculator from '../../service/ImportMessages/Calculator';
import './style.css';
import { DataSource, ImportMessage, LEVEL, LightEntityField, ORIGIN } from '../../service/ImportMessages/types';
import { Account } from 'interfaces';

interface Props extends WithTranslation {
    dataSource: DataSource;
    field: LightEntityField;
    subscription?: null | Account.Subscription;
}

class FieldMessageIcon extends React.PureComponent<Props> {
    render() {
        const messages = this.getMessages();
        if (messages.length === 0) {
            return null;
        }
        const messagesText = messages
            .map((m) => (m.origin === ORIGIN.INTERNAL ? m.message : '[' + m.httpCode + '] ' + m.message))
            .join(', ');
        const level = messages[0].level;
        return (
            <Tooltip title={messagesText}>
                <span>
                    {level === LEVEL.INFO && <InfoOutlined fontSize="small" style={{ color: '#2196f3' }} />}
                    {level === LEVEL.WARNING && <ReportProblemOutlined fontSize="small" style={{ color: '#ff9800' }} />}
                    {level === LEVEL.ERROR && <ErrorOutlineOutlined fontSize="small" style={{ color: '#f44336' }} />}
                </span>
            </Tooltip>
        );
    }

    getMessages(): ImportMessage[] {
        const { dataSource, field, subscription } = this.props;
        const sortedDsMessages = Calculator.calculateSortedMessages(
            [dataSource],
            [LEVEL.INFO, LEVEL.WARNING, LEVEL.ERROR],
            subscription,
        );
        for (let dsMessages of sortedDsMessages) {
            for (let entityMessages of dsMessages.entities) {
                for (let fieldMessages of entityMessages.fields) {
                    if (fieldMessages.field !== null && fieldMessages.field.id === field.id) {
                        return fieldMessages.messages;
                    }
                }
            }
        }
        return [] as ImportMessage[];
    }
}

export default withTranslation('translations', { withRef: true })(FieldMessageIcon);
