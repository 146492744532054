import AbstractEvent from './AbstractEvent';
import AbstractAutomation from './AbstractAutomation';

export type ActionGroupId = number | 'closeForm';

export interface SubmitFormLabels {
    formLabel: string;
    buttonLabel: string;
}

class SubmitForm extends AbstractAutomation {
    private readonly formActionId: number;
    private readonly actionGroupIds: Array<ActionGroupId>;
    private readonly triggerEventData: object | null;
    private readonly labels: SubmitFormLabels;

    constructor(
        accountId: number,
        formActionId: number,
        actionGroupIds: Array<ActionGroupId>,
        entityId: number,
        additionalContextData: object | null,
        contextUlid: string | null,
        triggerEventData: object | null,
        labels: SubmitFormLabels,
    ) {
        super(accountId, entityId, additionalContextData, contextUlid);

        this.formActionId = formActionId;
        this.actionGroupIds = actionGroupIds;
        this.triggerEventData = triggerEventData;
        this.labels = labels;
    }

    public getFormActionId() {
        return this.formActionId;
    }

    public getActionGroupIds(): Array<ActionGroupId> {
        return this.actionGroupIds;
    }

    public getTriggerEventData() {
        return this.triggerEventData;
    }

    public getLabels(): SubmitFormLabels {
        return this.labels;
    }

    protected toStorageSpecific(): object {
        return {
            ...super.toStorageSpecific(),
            formActionId: this.getFormActionId(),
            actionGroupIds: this.getActionGroupIds(),
            triggerEventData: this.getTriggerEventData(),
            labels: this.getLabels(),
        };
    }

    public static fromStorage(data: any): SubmitForm {
        // @ts-ignore
        return AbstractEvent.fromStorageBase(
            new SubmitForm(
                data.accountId,
                data.formActionId,
                data.actionGroupIds,
                data.entityId,
                data.additionalContextData,
                data.contextUlid,
                data.triggerEventData,
                data.labels,
            ),
            data,
        );
    }
}

export default SubmitForm;
