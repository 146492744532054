import AsyncBackendService from 'api/AsyncBackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../events';
import { GeocoderResultStatus } from 'components/types';
import { sleep } from '../utils';

const PLACES_DETAILS_AUTOCOMPLETE_FIELDS = ['name', 'formatted_address', 'geometry/location', 'address_component'];

const MAX_RETRY_COUNT = 5;

const waitTwoSeconds = async () => await sleep(2000);

class PlacesManager extends AsyncBackendService {
    constructor() {
        super(events.WS_GEOCODER_RESPONSE);
    }

    /**
     * @param {string} search
     * @param {number|undefined} accountId
     * @return {Promise<*>}
     */
    searchRecords(search, accountId = undefined) {
        const url = accountId ? reverse(apiRoutes.account.search, { accountId }) : reverse(apiRoutes.accountsSearch);
        return this.requestApi(url, 'GET', { search });
    }

    async getPlacesAutocomplete(term, basePoint, radius, sessionToken, language) {
        let attempt = 0,
            response,
            retry;
        do {
            if (attempt > 0) {
                await waitTwoSeconds();
            }
            response = await this.requestApiAsync(reverse(apiRoutes.accountsSearchAutocomplete), 'POST', {
                term,
                basePoint,
                sessionToken,
                radius,
                language,
            });
            retry = response.status === GeocoderResultStatus.ERROR_RATE_LIMIT_EXCEEDED;
        } while (retry && attempt++ < MAX_RETRY_COUNT);

        return response;
    }

    getPlacesDetailsAutocomplete(placeId, sessionToken, language, showExactitude = false) {
        return this.getPlacesDetails(
            placeId,
            PLACES_DETAILS_AUTOCOMPLETE_FIELDS,
            sessionToken,
            language,
            showExactitude,
        );
    }

    async getPlacesDetails(placeId, fields, sessionToken, language, showExactitude = false) {
        let attempt = 0,
            response,
            retry;
        do {
            if (attempt > 0) {
                await waitTwoSeconds();
            }
            response = await this.requestApiAsync(reverse(apiRoutes.accountsSearchDetails), 'POST', {
                placeId,
                fields,
                sessionToken,
                language,
                showExactitude,
            });
            retry = response.status === GeocoderResultStatus.ERROR_RATE_LIMIT_EXCEEDED;
        } while (retry && attempt++ < MAX_RETRY_COUNT);

        return response;
    }
}

export const placesManager = new PlacesManager();
