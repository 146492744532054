import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Icon, IconButton } from '@material-ui/core';
import { UserData } from '../../../service/types';
import ChangeHistory from './ChangeHistory';

interface ChangeHistoryModalProps extends WithTranslation {
    accountId: number;
    user: UserData;
    recordId: string;
    onClose: () => void;
    entityId: number;
}

class ChangeHistoryModal extends React.PureComponent<ChangeHistoryModalProps> {
    render() {
        return (
            <Dialog open={true} onClose={this.props.onClose} maxWidth={false}>
                <DialogTitle className="c-form-dialog__title">
                    {this.props.t('change_history.modal.title')}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.props.onClose}
                        className="c-form-dialog__close"
                        data-testid="change_history.modal.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ChangeHistory
                        accountId={this.props.accountId}
                        user={this.props.user}
                        recordId={this.props.recordId}
                        entityId={this.props.entityId}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation()(ChangeHistoryModal);
