import React from 'react';
import { TextField } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { getDetail } from '../../../service/WorkflowActionManager';
import './../style.css';
import AbstractForm from '../AbstractForm/AbstractForm';
import FormulaInput from '../AbstractForm/FormulaInput';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { DETAIL_RECIPIENT, DETAIL_CONTENT } from './Action';
import Hint from '../../Hint';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            if (!getDetail(DETAIL_RECIPIENT, currentAction.details)) {
                errors.set(DETAIL_RECIPIENT, t('errors.not_empty'));
            }
            if (!getDetail(DETAIL_CONTENT, currentAction.details)) {
                errors.set(DETAIL_CONTENT, t('errors.not_empty'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    render() {
        const { currentAction, errors } = this.state;
        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.send_sms.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.send_sms.name')}
                        data-testid="workflow_actions.form.send_sms.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.send_sms.api_name')}
                        data-testid="workflow_actions.form.send_sms.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect(currentAction, errors)}

                    <FormulaInput
                        fullWidth
                        label={
                            <span>
                                {t('workflow_actions.form.send_sms.recipient')}{' '}
                                <Hint type="tooltip">{t('workflow_actions.form.send_sms.recipient.hint')}</Hint>
                            </span>
                        }
                        margin="dense"
                        required
                        name={DETAIL_RECIPIENT}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_RECIPIENT, currentAction.details, '')}
                        error={errors.has(DETAIL_RECIPIENT)}
                        helperText={errors.get(DETAIL_RECIPIENT) || ''}
                        callContext={currentAction.callContext}
                    />

                    <FormulaInput
                        fullWidth
                        label={
                            <span>
                                {t('workflow_actions.form.send_sms.content')}{' '}
                                <Hint type="tooltip">{t('workflow_actions.form.send_sms.content.hint')}</Hint>
                            </span>
                        }
                        margin="dense"
                        multiline
                        required
                        name={DETAIL_CONTENT}
                        onChange={this.handleDetailsChange}
                        value={getDetail(DETAIL_CONTENT, currentAction.details, '')}
                        error={errors.has(DETAIL_CONTENT)}
                        helperText={errors.get(DETAIL_CONTENT) || ''}
                        callContext={currentAction.callContext}
                    />
                </form>
            </React.Fragment>
        );
    }
}

export { Form as SendSmsForm };
