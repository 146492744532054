import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../locales/i18n';
import { workflowActionManager } from '../../../service/WorkflowActionManager';
import './../style.css';
import { Box, ListSubheader, MenuItem, Tooltip } from '@material-ui/core';
import FormControlSelect from './FormControlSelect';
import { CallContext } from '../../utils/CallContext';
import { isSafeApiName } from '../../../utils';
import { entityManager } from '../../../service/SimpleEntityManager';

const t = i18n.t.bind(i18n);

class AbstractForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            currentAction: cloneDeep(props.action),
            currentFormulaIndex: null,
            editCriteria: false,
            currentFields: [],
            entitySelected: props.action.id !== null,
            loading: true,
            currentEntity: null,
        };

        this.initCurrentEntity = this.initCurrentEntity.bind(this);
        this.currentEntityWillUpdate = this.currentEntityWillUpdate.bind(this);
        this.submit = this.submit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateActionState = this.updateActionState.bind(this);
        this.handleDetailsChange = this.handleDetailsChange.bind(this);
        this.updateDetails = this.updateDetails.bind(this);

        this.renderEntityValue = this.renderEntityValue.bind(this);
        this.getEntityToRender = this.getEntityToRender.bind(this);
        this.getErrorToRender = this.getErrorToRender.bind(this);
        this.renderEntitySelect = this.renderEntitySelect.bind(this);
        this.handleCloseEntitySelect = this.handleCloseEntitySelect.bind(this);
        this.handleCallContext = this.handleCallContext.bind(this);
    }

    componentDidMount() {
        if (this.state.currentAction && this.state.currentAction.callContext.isValid()) {
            this.initCurrentEntity(this.state.currentAction.callContext.getBaseEntityId());
        }
    }

    initCurrentEntity(entityId) {
        if (!entityId) {
            return;
        }

        this.setState({ loading: true });

        entityManager.get(entityId, true, true).then((currentEntity) => {
            this.currentEntityWillUpdate(currentEntity);
            this.setState({ currentEntity, loading: false });
        });
    }

    currentEntityWillUpdate(currentEntity) {}

    submit() {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then((action) => {
                return workflowActionManager.save(this.props.account.id, action);
            })
            .then((action) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(action);
            })
            .catch((errors) => {
                this.setState({
                    errors: errors.details || errors,
                });
                this.props.onSubmitError && this.props.onSubmitError(t('validation_errors'));
            });
    }

    validate() {
        throw new Error('Not implemented');
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (name === 'apiName' && !isSafeApiName(value)) {
            return;
        }
        this.updateActionState(name, value);
    }

    updateActionState(name, value) {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                currentAction[name] = value;
                let errors = state.errors;

                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                }
                if (errors.size === 0) {
                    this.props.onSubmitError(null);
                }

                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    }

    handleDetailsChange(event, callback = null) {
        if (event.target.type === 'checkbox') {
            this.updateDetails(event.target.name, event.target.checked, callback);
        } else {
            this.updateDetails(event.target.name, event.target.value, callback);
        }
    }

    updateDetails(name, value, callback = null, extra = {}) {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (typeof currentAction.details !== 'object' || Array.isArray(currentAction.details)) {
                    currentAction.details = {};
                }
                currentAction.details[name] = value;
                let errors = state.errors;
                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                    if (errors.size === 0) {
                        this.props.onSubmitError(null);
                    }
                }
                return { currentAction, errors, ...extra };
            },
            () => {
                callback && typeof callback === 'function' && callback();
                this.props.onModified && this.props.onModified(true);
            },
        );
    }

    prepareEntityOptions() {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={entity.id} value={entity.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    }

    renderEntityValue(entity, error) {
        if (!entity) {
            return t('undefined_picklist_option');
        }

        const label = `${entity.dataSource.name} > ${entity.label}`;
        if (error) {
            return (
                <Box color="error.main" component="span">
                    <Tooltip title={error}>
                        <i style={{ marginRight: 3 }} className="fas fa-circle-exclamation" />
                    </Tooltip>
                    {label}
                </Box>
            );
        }
        return label;
    }

    getEntityToRender() {
        return this.state.currentEntity;
    }

    getErrorToRender(entity) {
        let error;
        if (entity?.dataSource?.deletedAt) {
            error = t('automation_elements.inactive_data_source');
        } else if (entity?.isDeleted || entity?.isIncluded === false) {
            error = t('automation_elements.inactive_entity');
        }
        return error;
    }

    renderEntitySelect(currentAction, errors, params = {}) {
        const entity = this.getEntityToRender();
        const error = this.getErrorToRender(entity);
        return (
            <FormControlSelect
                fullWidth
                margin="dense"
                label={t('automation_elements.form.entity')}
                name="callContext"
                value={currentAction.callContext.getBaseEntityId() || ''}
                onChange={this.handleCallContext}
                renderValue={() => this.renderEntityValue(entity, error)}
                error={!!error || errors.has('callContext')}
                helperText={errors.get('callContext')}
                data-testid="automation_elements.form.entity"
                {...params}
            >
                {this.prepareEntityOptions()}
            </FormControlSelect>
        );
    }

    handleCloseEntitySelect() {
        this.setState({ entitySelected: true });
    }

    handleCallContext(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (value) {
            this.initCurrentEntity(value);
        }
        this.updateActionState(name, CallContext.create(value));
        this.setState({ entitySelected: true });
    }
}

AbstractForm.propTypes = {
    account: PropTypes.object.isRequired,
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default AbstractForm;
