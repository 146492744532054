import Queue from './Queue';
import dispatcher from '../dispatcher';
import events from '../../events';
import { StorageInterface } from '../Storage/StorageInterface';
import StorageManager from '../Storage/StorageManager';
import { logDebug } from '../../utils';

const EVENTS_QUEUE_KEY = 'events_queue';

class QueueStorageHandler {
    private readonly queue: Queue;
    private storage: StorageInterface | null = null;

    constructor(queue: Queue) {
        this.queue = queue;

        dispatcher.subscribe(events.EVENTS_QUEUE_CHANGED, this, () => {
            this.save();
        });

        dispatcher.subscribe(events.EVENTS_ONLINE_QUEUE_CHANGED, this, () => {
            this.save();
        });

        StorageManager.getManager()
            .then((storage) => {
                this.storage = storage;

                this.load();
            })
            .catch((error) => {
                logDebug(error);
            });
    }

    private save() {
        logDebug('save');
        if (!this.storage) {
            return;
        }

        this.storage.set(EVENTS_QUEUE_KEY, this.queue.toStorage());
    }

    private load() {
        logDebug('load');
        if (!this.storage) {
            return;
        }

        this.storage
            .get(EVENTS_QUEUE_KEY)
            .then((data) => {
                this.queue.fromStorage(data);
            })
            .catch((reason) => {
                logDebug(reason);
            });
    }
}

export default QueueStorageHandler;
