import { useState } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from '@material-ui/core';
import './style.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import UserForm from 'components/UserForm';
import FormDialog from 'components/FormDialog';
import { Bounds, UserPropertiesTab } from 'components/types';
import { userPropertiesManager } from 'service/UserForm';
import { UserData } from 'service/types';
import LandingLink, {
    ARTICLE_USER_PREFERENCES_CALENDAR,
    ARTICLE_USER_PREFERENCES_PERSONAL,
    ARTICLE_USER_PREFERENCES_TRACKING,
    ARTICLE_USER_PREFERENCES_TRAVELING,
} from 'components/HelpLink/LandingLink';

interface Props extends WithTranslation {
    bounds?: Bounds;
    onSaved: (user: UserData) => void;
    onCancel?: () => void;
}

const helpArticles = {
    [UserPropertiesTab.TAB_PERSONAL]: ARTICLE_USER_PREFERENCES_PERSONAL,
    [UserPropertiesTab.TAB_TRAVELING]: ARTICLE_USER_PREFERENCES_TRAVELING,
    [UserPropertiesTab.TAB_LOCATION_TRACKING]: ARTICLE_USER_PREFERENCES_TRACKING,
    [UserPropertiesTab.TAB_CALENDAR]: ARTICLE_USER_PREFERENCES_CALENDAR,
};

const UserPropertiesDialog = (props: Props) => {
    const fullScreen = useMediaQuery('(max-width:530px)');
    const contentClassName = fullScreen ? 'fullscreen' : '';

    const [showButtons, setShowButtons] = useState<boolean>(false);

    const handleClose = () => {
        const { onCancel } = props;
        userPropertiesManager.closeModal();
        onCancel && onCancel();
    };

    const handleSaved = (user: UserData) => {
        const { onSaved } = props;
        userPropertiesManager.closeModal();
        onSaved(user);
    };

    const { t, bounds } = props;

    if (!userPropertiesManager.isOpen) {
        return null;
    }

    const currentTab = userPropertiesManager.tab;
    const helpArticleId = helpArticles[currentTab] ?? null;

    return (
        <FormDialog
            title={
                <div className={'c-user-form-properties-title-dialog title'}>
                    <div>{t('main_menu.form_title')}</div>
                    {helpArticleId && (
                        <div className={'help'}>
                            <LandingLink article={helpArticleId} useLeadingIcon useTrailingIcon>
                                {t('help')}
                            </LandingLink>
                        </div>
                    )}
                </div>
            }
            onSave={handleSaved}
            onCancel={handleClose}
            contentClassName={contentClassName}
            fullWidth
            maxWidth={'sm'}
            fullScreen={fullScreen}
            showSaveButton={showButtons}
            showCancelButton={showButtons}
            scroll={'paper'}
        >
            <UserForm
                user={userPropertiesManager.user}
                account={userPropertiesManager.account}
                currentTab={currentTab}
                focusElement={userPropertiesManager.focusElement}
                bounds={bounds}
                onFormLoaded={() => setShowButtons(true)}
                onClose={handleClose}
            />
        </FormDialog>
    );
};

export default withTranslation()(observer(UserPropertiesDialog));
