import { userManager } from '../UserManager';
import { WeekDay, WorkingHoursPerDay, WorkingHoursPerWeek } from 'components/types';
import { WeekDayList } from '../../components/UserForm/TravelingPreferences/WorkingDays';

/**
 * @deprecated
 * This class is used only in BgGeo context
 * Use src/service/WorkSchedule.ts to get timetable with schedule exceptions
 *
 * Should be removed/edited by @a.bimakov
 * */
class WorkSchedule {
    private getTodayTimetable = (weekTimes?: WorkingHoursPerWeek): WorkingHoursPerDay | null => {
        const user = userManager.getCurrentUser();
        if (!user) {
            return null;
        }

        const now = new Date();
        const currentWeekDay = WeekDay[now.getDay()];

        if (!weekTimes) {
            weekTimes = user.routingPreferences.weekTimes;
            if (!weekTimes) {
                return null;
            }
        }

        let todayTimetable = weekTimes[currentWeekDay];
        if (!todayTimetable) {
            return null;
        }

        return todayTimetable;
    };

    /**
     * todo change "less than" to "less" for end minutes after adding 1 minute in geoplugin config
     * @see BgGeoManager.formatScheduleToPluginFormat
     */
    isItWorkTimeNow = (weekTimes?: WorkingHoursPerWeek, at?: Date) => {
        let todayTimetable = this.getTodayTimetable(weekTimes);
        if (!todayTimetable) {
            return false;
        }

        const now = at ?? new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        if (!todayTimetable.start) {
            // day off
            return false;
        }

        if (todayTimetable.start.hours > currentHour) {
            return false;
        }
        if (todayTimetable.start.hours === currentHour) {
            if (todayTimetable.start.minutes > currentMinute) {
                return false;
            }
        }

        if (todayTimetable.end.hours < currentHour) {
            return false;
        }
        if (todayTimetable.end.hours === currentHour) {
            if (todayTimetable.end.minutes <= currentMinute) {
                return false;
            }
        }

        return true;
    };

    isScheduleValid = (weekTimes: WeekDayList) => {
        for (const weekDay in weekTimes) {
            const day = weekTimes[weekDay];
            if (!day) {
                continue;
            }
            // @ts-ignore
            if (day.enable === false) {
                continue;
            }
            if (day.start > day.end) {
                return false;
            }
        }

        return true;
    };
}

export default new WorkSchedule();
