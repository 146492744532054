import React from 'react';
import { Button } from '@material-ui/core';
import params from '../../params';
import { WithTranslation, withTranslation } from 'react-i18next';
import { enqueueSnackbarService } from 'service/MapPage';
import { weAreInIframe } from 'utils';

interface Props extends WithTranslation {
    onPick: (doc: google.picker.DocumentObject) => void;
}

interface State {
    tokenClient: any;
    accessToken: string | null;
    pickerInitialized: boolean;
    gsiInitialized: boolean;
}

const SCOPE_SPREADSHEET = 'https://www.googleapis.com/auth/spreadsheets';
const SCOPE_DRIVE_FILE = 'https://www.googleapis.com/auth/drive.file';

class GooglePicker extends React.Component<Props, State> {
    state: State = {
        tokenClient: null,
        accessToken: null,
        pickerInitialized: false,
        gsiInitialized: false,
    };

    loadScript = (src: string, onLoad: () => void) => {
        if (document.querySelector(`script[src="${src}"]`)) {
            onLoad();
            return;
        }
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = onLoad;
        document.body.appendChild(script);
    };

    componentDidMount() {
        this.loadScript('https://apis.google.com/js/api.js', this.onApiLoad);
        this.loadScript('https://accounts.google.com/gsi/client', this.onGsiLoad);
    }

    onPickerApiLoad = () => {
        this.setState({ pickerInitialized: true });
    };

    onApiLoad = () => {
        window.gapi.load('picker', this.onPickerApiLoad);
    };

    getOrigin() {
        if (!weAreInIframe()) {
            return window.location.origin;
        }

        let urls = [];
        let parentWindow = window;
        if (parentWindow.location.ancestorOrigins !== undefined) {
            urls = [...parentWindow.location.ancestorOrigins];
        } else {
            while (parentWindow !== window.top) {
                try {
                    const url = new URL(parentWindow.document.referrer);
                    urls.push(url.origin);
                } catch (e) {}
                // @ts-ignore
                parentWindow = parentWindow.parent;
            }
        }

        return urls.length > 0 ? urls[urls.length - 1] : null;
    }

    showPicker = () => {
        if (!this.state.accessToken) {
            throw new Error('Access token is null');
        }

        const origin = this.getOrigin();
        if (!origin) {
            enqueueSnackbarService.sendErrorMessage('Failed to open Google Picker. Origin is not found.');
            return;
        }

        const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId.SPREADSHEETS)
            .setOAuthToken(this.state.accessToken)
            .setAppId(params.google.picker.appId)
            .setDeveloperKey(params.google.picker.apiKey)
            .setCallback(this.pickerCallback)
            .setOrigin(origin)
            .build();
        picker.setVisible(true);

        const elements = document.getElementsByClassName('picker-dialog');
        for (const element of elements) {
            (element as HTMLElement).style.zIndex = '1400';
        }
    };

    createPicker = () => {
        if (this.state.accessToken === null) {
            this.state.tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            this.state.tokenClient.requestAccessToken({ prompt: '' });
        }
    };

    onAccessApproved = async (response: any) => {
        if (response.error !== undefined) {
            throw response;
        }
        this.setState({ accessToken: response.access_token }, this.showPicker);
    };

    onGsiLoad = () => {
        const tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: params.google.picker.clientId,
            scope: [SCOPE_DRIVE_FILE, SCOPE_SPREADSHEET].join(' '),
            callback: this.onAccessApproved,
        });
        this.setState({ tokenClient: tokenClient, gsiInitialized: true });
    };

    pickerCallback = (data: google.picker.ResponseObject) => {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
            let doc = data[google.picker.Response.DOCUMENTS][0];
            this.props.onPick(doc);
        }
    };

    render() {
        const buttonDisabled = !this.state.gsiInitialized || !this.state.pickerInitialized;
        const { t } = this.props;

        return (
            <Button color="primary" onClick={this.createPicker} disabled={buttonDisabled}>
                {t('google_picker.button.label')}
            </Button>
        );
    }
}

export default withTranslation()(GooglePicker);
