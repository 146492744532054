import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import './style.css';
import { formatWithCommas, isAddressElementField, isGeopointFriendlyTypeField } from '../../utils';
import cloneDeep from 'lodash/cloneDeep';
import DisabledOptionWithHintAutocomplete from '../DisabledOptionWithHintAutocomplete';

class SelectField extends React.PureComponent {
    render() {
        const { t, name, value, onChange, fields, disabled, getOptionDisabled, disabledOptionHint } = this.props;
        return (
            <DisabledOptionWithHintAutocomplete
                className="geo-mapper-table__select"
                options={fields}
                value={fields.find((field) => value === field.apiName) || null}
                getOptionLabel={(field) => field.label}
                onChange={(event, value) => {
                    onChange({ target: { name: name, value: value?.apiName } });
                }}
                disabled={disabled}
                getOptionDisabled={getOptionDisabled}
                disabledOptionHint={disabledOptionHint}
                dataTestId="geomapper.no_autosaving"
                placeholder={t('geomapper.no_autosaving')}
            />
        );
    }
}

const Field = withTranslation()(SelectField);

export const SAVE_FIELDS = [
    'autoSaveStreetField',
    'autoSaveCityField',
    'autoSaveCountyField',
    'autoSaveStateField',
    'autoSaveZipCodeField',
    'autoSaveCountryField',
    'autoSaveDistrictField',
    'autoSaveLatitudeField',
    'autoSaveLongitudeField',
    'autoSaveHouseField',
    'autoSaveHouseAndStreetField',
    'latitudeField',
    'longitudeField',
];

const TYPE_HOUSE_BEFORE = 1;
const TYPE_HOUSE_AFTER = 2;

class GeoMapperForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            longitudeField: '',
            latitudeField: '',
            useLatLngFields: false,
            hasSaveGeoFields: false,
            autoSaveStreetField: '',
            autoSaveCityField: '',
            autoSaveCountyField: '',
            autoSaveStateField: '',
            autoSaveZipCodeField: '',
            autoSaveCountryField: '',
            autoSaveDistrictField: '',
            autoSaveLatitudeField: '',
            autoSaveLongitudeField: '',
            autoSaveHouseField: '',
            autoSaveHouseAndStreetField: '',
            autoSaveHouseAndStreetType: TYPE_HOUSE_BEFORE,
        };
        this.original = null;
    }

    componentDidMount() {
        const { entity } = this.props;
        const { useLatLngFields, hasSaveGeoFields } = entity;
        this.setState(
            (state) => {
                const newState = { useLatLngFields, hasSaveGeoFields };
                for (let saveField of SAVE_FIELDS) {
                    if (entity[saveField]) {
                        const field = entity.fields.find((f) => f.id === entity[saveField]);
                        if (field) {
                            newState[saveField] = field.apiName;
                        }
                    }
                }
                if (entity['autoSaveHouseAndStreetType']) {
                    newState['autoSaveHouseAndStreetType'] = entity['autoSaveHouseAndStreetType'];
                }
                return { ...state, ...newState };
            },
            () => (this.original = cloneDeep(this.state)),
        );
    }
    submit() {
        this.setState({
            errors: new Map(),
        });
        const errors = new Map();

        const { dataSource, entity, onSubmitSuccess, onSubmitError, t } = this.props;
        const { latitudeField, longitudeField, useLatLngFields, hasSaveGeoFields, autoSaveHouseAndStreetType } =
            this.state;

        if (!latitudeField && useLatLngFields) {
            errors.set('latitudeField', t('errors.field.not_empty'));
        }
        if (!longitudeField && useLatLngFields) {
            errors.set('longitudeField', t('errors.field.not_empty'));
        }
        if (errors.size) {
            this.setState({
                errors,
            });
            onSubmitError(t('validation_errors'));
            return;
        }

        const result = { latitudeField, longitudeField, useLatLngFields, hasSaveGeoFields, autoSaveHouseAndStreetType };
        const diffFields = [];
        for (let saveField of SAVE_FIELDS) {
            result[saveField] = this.state[saveField];
            if (
                this.original !== null &&
                this.state[saveField] !== this.original[saveField] &&
                this.state[saveField] !== ''
            ) {
                diffFields.push({
                    dataSourceLabel: dataSource.name,
                    entityLabel: entity.label,
                    fieldLabel: entity.fields.find((field) => field.apiName === this.state[saveField])?.label || '',
                });
            }
        }

        onSubmitSuccess(result, diffFields);
    }

    handleFieldChanged = (event) => {
        this.changeField(event.target.name, event.target.value);
    };

    changeField = (fieldName, fieldValue) => {
        this.setState((state) => {
            const { errors } = state;
            errors.delete(fieldName);
            return {
                errors,
                [fieldName]: fieldValue,
            };
        });
        const { onShowWarningMessage, entity } = this.props;

        onShowWarningMessage &&
            onShowWarningMessage(
                this.props.t('geomapper.update_many_records', {
                    count: formatWithCommas(this.countAffectedRecords(entity)),
                }),
            );
    };

    countAffectedRecords(entity) {
        let count = entity.recordsCount;
        if (entity.childRecordsCount) {
            count += entity.childRecordsCount;
        }
        return count;
    }

    isAvailableToSelect = (field, selectedFieldApiName) => {
        if (field.apiName === selectedFieldApiName) {
            return true;
        }
        return !SAVE_FIELDS.some((saveField) => field.apiName === this.state[saveField]);
    };

    isMappedAsAddress(field) {
        const pinField = this.props.entity.fields.find((field) => field.isPin);
        if (!pinField) {
            return false;
        }

        if (pinField.apiName === field.apiName) {
            return true;
        }

        if (pinField.address) {
            for (let mapping in pinField.address) {
                if (pinField.address[mapping] === field.apiName) {
                    return true;
                }
            }
        }

        return false;
    }

    entityTrueAddressFields(selectedFieldApiName) {
        return this.props.entity.fields
            .filter(isAddressElementField)
            .filter((field) => this.isAvailableToSelect(field, selectedFieldApiName));
    }

    entityGeopointFields(selectedFieldApiName) {
        return this.props.entity.fields
            .filter(isGeopointFriendlyTypeField)
            .filter((field) => this.isAvailableToSelect(field, selectedFieldApiName));
    }

    handleChecked = (e) => {
        const { checked, name } = e.target;
        this.setState({
            [name]: checked,
        });

        // we reset coordinates fields mapping if we no longer use coordinates - so mapped fields can be deleted
        if (name === 'useLatLngFields' && !checked) {
            this.changeField('latitudeField', '');
            this.changeField('longitudeField', '');
        }
    };

    getOptionDisabled = (option) => {
        if (!option) {
            return false;
        }
        const { entity } = this.props;
        if (option.id === entity.territoriesFieldId) {
            return true;
        }

        return this.isMappedAsAddress(option);
    };

    getDisabledOptionHint = (option) => {
        if (!option) {
            return '';
        }
        const { entity } = this.props;
        if (option.id === entity.territoriesFieldId) {
            return this.props.t('geomapper.record.list_fields.is_used_as_territory');
        }
        if (this.isMappedAsAddress(option)) {
            return this.props.t('geomapper.record.list_fields.is_used_as_address');
        }

        return false;
    };

    render() {
        const { longitudeField, latitudeField, useLatLngFields, errors, hasSaveGeoFields } = this.state;
        const { t } = this.props;

        return (
            <Grid container spacing={1} alignItems="center" className="toolbar">
                <Grid item xs={12}>
                    {t('geomapper.record.coordinates')}
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={useLatLngFields}
                                onChange={this.handleChecked}
                                name="useLatLngFields"
                            />
                        }
                        label={t('geomapper.record.use_coordinates')}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth error={errors.has('latitudeField')}>
                        <InputLabel shrink>{t('geomapper.record.latitude')}</InputLabel>
                        <DisabledOptionWithHintAutocomplete
                            options={this.entityGeopointFields(this.state.latitudeField)}
                            value={
                                this.entityGeopointFields(this.state.latitudeField).find(
                                    (field) => latitudeField === field.apiName,
                                ) || null
                            }
                            getOptionLabel={(field) => field.label}
                            disableClearable={true}
                            disabled={!this.state.useLatLngFields}
                            onChange={(event, value) => {
                                this.handleFieldChanged({ target: { name: 'latitudeField', value: value?.apiName } });
                            }}
                            getOptionDisabled={this.getOptionDisabled}
                            disabledOptionHint={this.getDisabledOptionHint}
                            dataTestId="geomapper.latitude"
                            placeholder={t('undefined_picklist_option')}
                            inputMargin="normal"
                        />
                        {errors.has('latitudeField') && <FormHelperText>{errors.get('latitudeField')}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth error={errors.has('longitudeField')}>
                        <InputLabel shrink>{t('geomapper.record.longitude')}</InputLabel>
                        <DisabledOptionWithHintAutocomplete
                            options={this.entityGeopointFields(this.state.longitudeField)}
                            value={
                                this.entityGeopointFields(this.state.longitudeField).find(
                                    (field) => longitudeField === field.apiName,
                                ) || null
                            }
                            getOptionLabel={(field) => field.label}
                            disableClearable={true}
                            disabled={!this.state.useLatLngFields}
                            onChange={(event, value) => {
                                this.handleFieldChanged({ target: { name: 'longitudeField', value: value?.apiName } });
                            }}
                            getOptionDisabled={this.getOptionDisabled}
                            disabledOptionHint={this.getDisabledOptionHint}
                            dataTestId="geomapper.longitude"
                            placeholder={t('undefined_picklist_option')}
                            inputMargin="normal"
                        />
                        {errors.has('longitudeField') && (
                            <FormHelperText>{errors.get('longitudeField')}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <i className="fas fa-info-circle" style={{ marginRight: '8px', color: '#3f51b5' }} />
                    {t('geomapper.records.move')}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '28px' }}>
                    <strong>{t('geomapper.record.true_coordinates')}</strong>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    {t('geomapper.record.true_coordinates.explanation')}
                </Grid>
                <Grid item xs={12}>
                    <i className="fas fa-exclamation-triangle" style={{ marginRight: '8px', color: '#feb81b' }} />
                    {t('geomapper.record.true_coordinates.warning')}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={hasSaveGeoFields}
                                onChange={this.handleChecked}
                                name="hasSaveGeoFields"
                            />
                        }
                        label={t('geomapper.enable_auto_save_fields')}
                    />
                </Grid>

                <div style={{ display: hasSaveGeoFields ? 'block' : 'none' }}>
                    <Table padding="dense" className="geo-mapper-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('geomapper.coordinates')}</TableCell>
                                <TableCell>{t('geomapper.auto_save_field')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('longitude')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityGeopointFields(this.state.autoSaveLongitudeField)}
                                        value={this.state.autoSaveLongitudeField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveLongitudeField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('latitude')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityGeopointFields(this.state.autoSaveLatitudeField)}
                                        value={this.state.autoSaveLatitudeField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveLatitudeField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table padding="dense" className="geo-mapper-table" style={{ marginTop: '16px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('geomapper.address_field')}</TableCell>
                                <TableCell>{t('geomapper.auto_save_field')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('house_and_street_number')}</TableCell>
                                <TableCell>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Field
                                                fields={this.entityTrueAddressFields(
                                                    this.state.autoSaveHouseAndStreetField,
                                                )}
                                                value={this.state.autoSaveHouseAndStreetField}
                                                onChange={this.handleFieldChanged}
                                                name="autoSaveHouseAndStreetField"
                                                getOptionDisabled={this.getOptionDisabled}
                                                disabledOptionHint={this.getDisabledOptionHint}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                disabled={!this.state.autoSaveHouseAndStreetField}
                                                displayEmpty
                                                onChange={this.handleFieldChanged}
                                                name="autoSaveHouseAndStreetType"
                                                value={this.state.autoSaveHouseAndStreetType}
                                                className="geo-mapper-table__select"
                                                data-testid="auto_save_house_and_street_type"
                                            >
                                                <MenuItem value={TYPE_HOUSE_BEFORE} data-testid="house_number_first">
                                                    {t('house_number_first')}
                                                </MenuItem>
                                                <MenuItem value={TYPE_HOUSE_AFTER} data-testid="street_first">
                                                    {t('street_first')}
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('house_number')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveHouseField)}
                                        value={this.state.autoSaveHouseField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveHouseField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('street')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveStreetField)}
                                        value={this.state.autoSaveStreetField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveStreetField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('district')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveDistrictField)}
                                        value={this.state.autoSaveDistrictField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveDistrictField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('city')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveCityField)}
                                        value={this.state.autoSaveCityField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveCityField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('county')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveCountyField)}
                                        value={this.state.autoSaveCountyField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveCountyField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('state')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveStateField)}
                                        value={this.state.autoSaveStateField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveStateField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('zip_code')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveZipCodeField)}
                                        value={this.state.autoSaveZipCodeField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveZipCodeField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('country')}</TableCell>
                                <TableCell>
                                    <Field
                                        fields={this.entityTrueAddressFields(this.state.autoSaveCountryField)}
                                        value={this.state.autoSaveCountryField}
                                        onChange={this.handleFieldChanged}
                                        name="autoSaveCountryField"
                                        getOptionDisabled={this.getOptionDisabled}
                                        disabledOptionHint={this.getDisabledOptionHint}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Grid>
        );
    }
}

GeoMapperForm.propTypes = {
    entity: PropTypes.object,
    dataSource: PropTypes.object,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(GeoMapperForm);
