import { action, computed, makeObservable, observable } from 'mobx';
import { Routing } from '../../../interfaces';
import { routeDesignManager, routeReportManager, routingSessionManager } from '../index';

type DuplicatePointsDialogManagerKeys =
    | '_isOpen'
    | '_isLoading'
    | '_entityPoints'
    | '_duplicateEntityPoints'
    | '_prospectingPoints'
    | '_duplicateProspectingPoints'
    | '_simplePoints'
    | '_duplicateSimplePoints';

type DuplicateAndUniquePoints = {
    hasDuplicates: boolean;

    duplicateSimplePoints: Routing.Route.DesignSimplePoint[];
    uniqueSimplePoints: Routing.Route.DesignSimplePoint[];

    duplicateEntityPoints: Routing.Route.DesignEntityPoint[];
    uniqueEntityPoints: Routing.Route.DesignEntityPoint[];

    duplicateProspectingPoints: Routing.Route.DesignProspectPoint[];
    uniqueProspectingPoints: Routing.Route.DesignProspectPoint[];
};

class DuplicatePointsDialogManager {
    private _isOpen = false;
    private _isLoading = false;
    private _entityPoints: Routing.Route.DesignEntityPoint[] = [];
    private _duplicateEntityPoints: Routing.Route.DesignEntityPoint[] = [];
    private _prospectingPoints: Routing.Route.DesignProspectPoint[] = [];
    private _duplicateProspectingPoints: Routing.Route.DesignProspectPoint[] = [];
    private _simplePoints: Routing.Route.DesignSimplePoint[] = [];
    private _duplicateSimplePoints: Routing.Route.DesignSimplePoint[] = [];

    constructor() {
        makeObservable<DuplicatePointsDialogManager, DuplicatePointsDialogManagerKeys>(this, {
            _isOpen: observable,
            _isLoading: observable,
            _entityPoints: observable,
            _duplicateEntityPoints: observable,
            _prospectingPoints: observable,
            _duplicateProspectingPoints: observable,
            _simplePoints: observable,
            _duplicateSimplePoints: observable,

            isOpen: computed,
            isLoading: computed,
            entityPoints: computed,
            duplicateEntityPoints: computed,
            prospectingPoints: computed,
            duplicateProspectingPoints: computed,
            simplePoints: computed,
            duplicateSimplePoints: computed,

            open: action,
            skipDuplicates: action,
            addAll: action,
            close: action,
        });
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get entityPoints(): Routing.Route.DesignEntityPoint[] {
        return this._entityPoints;
    }

    get duplicateEntityPoints(): Routing.Route.DesignEntityPoint[] {
        return this._duplicateEntityPoints;
    }

    get prospectingPoints(): Routing.Route.DesignProspectPoint[] {
        return this._prospectingPoints;
    }

    get duplicateProspectingPoints(): Routing.Route.DesignProspectPoint[] {
        return this._duplicateProspectingPoints;
    }

    get simplePoints(): Routing.Route.DesignSimplePoint[] {
        return this._simplePoints;
    }

    get duplicateSimplePoints(): Routing.Route.DesignSimplePoint[] {
        return this._duplicateSimplePoints;
    }

    open = (points: DuplicateAndUniquePoints) => {
        this._entityPoints = points.uniqueEntityPoints;
        this._duplicateEntityPoints = points.duplicateEntityPoints;
        this._prospectingPoints = points.uniqueProspectingPoints;
        this._duplicateProspectingPoints = points.duplicateProspectingPoints;
        this._simplePoints = points.uniqueSimplePoints;
        this._duplicateSimplePoints = points.duplicateSimplePoints;
        this._isOpen = true;
    };

    skipDuplicates = async () => {
        this._isLoading = true;
        await routingSessionManager.addPoints(this._entityPoints, [...this._prospectingPoints, ...this._simplePoints]);
        this._isLoading = false;
    };

    addAll = async () => {
        this._isLoading = true;
        await routingSessionManager.addPoints(
            [...this._entityPoints, ...this._duplicateEntityPoints],
            [
                ...this._prospectingPoints,
                ...this._duplicateProspectingPoints,
                ...this._simplePoints,
                ...this._duplicateSimplePoints,
            ],
        );
        this._isLoading = false;
    };

    close = () => {
        this._isOpen = false;
        this._entityPoints = [];
        this._duplicateEntityPoints = [];
        this._prospectingPoints = [];
        this._duplicateProspectingPoints = [];
    };

    getDuplicateAndUniquePoints(
        entityPoints: Routing.Route.DesignEntityPoint[],
        prospectingPoints: Routing.Route.DesignProspectPoint[],
        simplePoints: Routing.Route.DesignSimplePoint[],
    ): DuplicateAndUniquePoints {
        const points: DuplicateAndUniquePoints = {
            hasDuplicates: false,
            duplicateEntityPoints: [],
            uniqueEntityPoints: [],
            duplicateProspectingPoints: [],
            uniqueProspectingPoints: [],
            uniqueSimplePoints: [],
            duplicateSimplePoints: [],
        };

        for (const entityPointRaw of entityPoints) {
            let hasDuplicate = false;
            for (const routePoint of this.getPointsForComparison()) {
                if (
                    'entityId' in routePoint &&
                    routePoint.entityId === entityPointRaw.entityId &&
                    routePoint.recordId === entityPointRaw.recordId
                ) {
                    points.duplicateEntityPoints.push(entityPointRaw);
                    hasDuplicate = true;
                    break;
                }
            }
            if (!hasDuplicate) {
                points.uniqueEntityPoints.push(entityPointRaw);
            }
        }

        for (const prospectPointRaw of prospectingPoints) {
            let hasDuplicate = false;
            for (const routePoint of this.getPointsForComparison()) {
                if ('recordId' in routePoint && routePoint.recordId === prospectPointRaw.recordId) {
                    points.duplicateProspectingPoints.push(prospectPointRaw);
                    hasDuplicate = true;
                    break;
                }
            }
            if (!hasDuplicate) {
                points.uniqueProspectingPoints.push(prospectPointRaw);
            }
        }

        for (const simplePointRaw of simplePoints) {
            let hasDuplicate = false;
            for (const routePoint of this.getPointsForComparison()) {
                if (
                    'recordId'! in routePoint &&
                    'entityId'! in routePoint &&
                    routePoint.lat === simplePointRaw.lat &&
                    routePoint.lng === simplePointRaw.lng
                ) {
                    points.duplicateSimplePoints.push(simplePointRaw);
                    hasDuplicate = true;
                    break;
                }
            }
            if (!hasDuplicate) {
                points.uniqueSimplePoints.push(simplePointRaw);
            }
        }

        points.hasDuplicates =
            points.duplicateEntityPoints.length > 0 ||
            points.duplicateProspectingPoints.length > 0 ||
            points.duplicateSimplePoints.length > 0;

        return points;
    }

    private getPointsForComparison() {
        if (routingSessionManager.isDraftEditMode || routingSessionManager.isEditModeInPublishedRoute) {
            let route: Routing.Route.Route | undefined;
            if (routingSessionManager.isLoadedRoute) {
                route = routingSessionManager.loadedSessionRoute;
            } else if (routeReportManager.showSingleRoute) {
                route = routeReportManager.singleRoute;
            } else if (routeReportManager.isShowRouteReport) {
                route = routeReportManager.showRouteReportData?.route;
            }

            if (!route) {
                return [];
            }

            return route.activities;
        }

        return routeDesignManager.pointsArray;
    }
}

export default DuplicatePointsDialogManager;
