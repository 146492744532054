import React, { ChangeEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { FormActions, FormAlert, FormBackdrop } from '../PureFormDialog/Form';
import { WithTranslation, withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import './style.css';
import LandingLink from '../HelpLink/LandingLink';
import MapBoxGeneralApi, { TokenType } from 'api/MapBoxGeneralApi';
import { sharedMapManager } from '../../service/SharedMapManager';

interface Message {
    type: 'success' | 'warning' | 'danger' | 'info';
    text: string;
}

interface Props extends WithTranslation {
    apiKey: string;
    enabled: boolean;
    secretApiKey: string;
    accountId: Number;
    onSave: (apiKey: string, secretApiKey: string) => void;
}

interface State {
    apiKey: string;
    secretApiKey: string;
    loading: boolean;
    errors: Map<string, string>;
    message: Message | null;
    showUserMismatchConfirmation: boolean;
}

class SharedMapModalSettings extends React.PureComponent<Props, State> {
    private mapBoxApi: MapBoxGeneralApi;
    private mapBoxSecretApi: MapBoxGeneralApi;

    constructor(props: Props) {
        super(props);
        this.state = {
            apiKey: props.apiKey ?? '',
            secretApiKey: props.secretApiKey ?? '',
            loading: false,
            errors: new Map<string, string>(),
            message: null,
            showUserMismatchConfirmation: false,
        };

        this.mapBoxApi = new MapBoxGeneralApi(props.apiKey);
        this.mapBoxSecretApi = new MapBoxGeneralApi(props.secretApiKey);
    }

    handleSave = (failOnUserMismatch: boolean) => async () => {
        this.setState({
            message: null,
            loading: true,
            errors: new Map<string, string>(),
            showUserMismatchConfirmation: false,
        });

        const { enabled, t } = this.props;

        const publicTokenErrorText = t('shared_map.settings.modal.map_box.public_token');
        const secretTokenErrorText = t('shared_map.settings.modal.map_box.secret_token');
        const checkPublicToken = this.state.apiKey.length > 0;
        const checkSecretToken = this.state.secretApiKey.length > 0;
        const errors = new Map<string, string>();

        let showUserMismatchConfirmation = false;
        let message: Message | null = null;
        let hasErrors = false;

        let publicPrecheck = null;
        if (checkPublicToken) {
            publicPrecheck = this.mapBoxApi.precheckAccessToken();
            if (this.mapBoxApi.getTokenType() !== TokenType.PUBLIC || publicPrecheck === null) {
                console.error('apiKey type', this.mapBoxApi.getTokenType());
                errors.set('apiKey', publicTokenErrorText);
                hasErrors = true;
            }
        }

        if (checkSecretToken) {
            const secretPrecheck = this.mapBoxSecretApi.precheckAccessToken();
            if (this.mapBoxSecretApi.getTokenType() !== TokenType.SECRET || secretPrecheck === null) {
                console.error('secretApiKey type', this.mapBoxSecretApi.getTokenType());
                errors.set('secretApiKey', secretTokenErrorText);
                hasErrors = true;
            }
            if (checkPublicToken && publicPrecheck && secretPrecheck && publicPrecheck.u !== secretPrecheck.u) {
                if (hasErrors) {
                    message = {
                        type: 'warning',
                        text: t('shared_map.settings.modal.map_box.user_mismatch'),
                    };
                } else {
                    showUserMismatchConfirmation = failOnUserMismatch;
                }
            }
        }

        if (hasErrors || message || showUserMismatchConfirmation) {
            this.setState({
                errors,
                loading: false,
                message,
                showUserMismatchConfirmation,
            });

            return;
        }

        let publicToken;
        let secretToken;

        try {
            publicToken = checkPublicToken ? await this.mapBoxApi.checkAccessToken() : undefined;
        } catch (error) {
            hasErrors = true;
            console.error(error);
            this.state.errors.set('apiKey', error.message);
        }

        try {
            secretToken = checkSecretToken ? await this.mapBoxSecretApi.checkAccessToken() : undefined;
        } catch (error) {
            hasErrors = true;
            console.error(error);
            this.state.errors.set('secretApiKey', error.message);
        }

        if (checkPublicToken && !errors.has('apiKey') && publicToken?.usage !== TokenType.PUBLIC) {
            errors.set('apiKey', publicTokenErrorText);
            hasErrors = true;
        }

        if (checkSecretToken) {
            if (!errors.has('secretApiKey') && secretToken?.usage !== TokenType.SECRET) {
                errors.set('secretApiKey', secretTokenErrorText);
                hasErrors = true;
            }
            if (!hasErrors) {
                try {
                    // check is styles are accessible
                    await this.mapBoxSecretApi.listStyles();
                } catch (error) {
                    console.error(error);
                    errors.set('secretApiKey', secretTokenErrorText);
                    hasErrors = true;
                }
            }
            if (checkPublicToken && publicToken && secretToken && publicToken?.user !== secretToken?.user) {
                if (hasErrors) {
                    message = {
                        type: 'warning',
                        text: t('shared_map.settings.modal.map_box.user_mismatch'),
                    };
                } else {
                    showUserMismatchConfirmation = failOnUserMismatch;
                }
            }
        }

        if (hasErrors || message || showUserMismatchConfirmation) {
            this.setState({
                errors,
                loading: false,
                message,
                showUserMismatchConfirmation,
            });
            return;
        }

        try {
            const { apiKey, secretApiKey } = await sharedMapManager.saveSettings(
                this.props.accountId,
                enabled,
                this.state.apiKey,
                this.state.secretApiKey,
            );

            this.setState({
                apiKey,
                secretApiKey,
                loading: false,
            });

            this.props.onSave(apiKey, secretApiKey);
        } catch (error) {
            console.error(error);

            this.setState({
                errors: error.details ?? errors,
                loading: false,
                message: {
                    type: 'danger',
                    text: error.message,
                },
            });
        }
    };

    handleCloseUserMismatchConfirmation = () => {
        this.setState({
            showUserMismatchConfirmation: false,
        });
    };

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState(
            {
                apiKey: e.target.value,
            },
            () => {
                this.mapBoxApi.setAccessToken(this.state.apiKey);
            },
        );
    };

    handleChangeSecret = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState(
            {
                secretApiKey: e.target.value,
            },
            () => {
                this.mapBoxSecretApi.setAccessToken(this.state.secretApiKey);
            },
        );
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
        });
    };

    render() {
        const { apiKey, errors, loading, message, secretApiKey } = this.state;
        const { t } = this.props;

        return (
            <div className="c-shared-map-modal">
                {message !== null && (
                    <FormAlert type={message.type} onClose={this.handleAlertClose}>
                        {message.text}
                    </FormAlert>
                )}
                <FormBackdrop loading={loading}>
                    <FormControl fullWidth>
                        <TextField
                            label={t('shared_map.settings.modal.map_box.public_api_key')}
                            error={errors.has('apiKey')}
                            helperText={errors.get('apiKey') ?? ''}
                            data-testid="shared_map.settings.modal.map_box.api_key"
                            fullWidth
                            value={apiKey}
                            InputProps={{
                                disableUnderline: false,
                            }}
                            onChange={this.handleChange}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label={t('shared_map.settings.modal.map_box.secret_api_key')}
                            error={errors.has('secretApiKey')}
                            helperText={errors.get('secretApiKey') ?? ''}
                            data-testid="shared_map.settings.modal.map_box.secret_api_key"
                            fullWidth
                            value={secretApiKey}
                            InputProps={{
                                disableUnderline: false,
                            }}
                            onChange={this.handleChangeSecret}
                        />
                    </FormControl>
                    <p>
                        <i style={{ color: 'lightgray', marginRight: '8px' }} className="fas fa-info-circle" />
                        {t('shared_map.settings.modal.map_box.help')}&nbsp;
                        <LandingLink article="5344496" useTrailingIcon>
                            {t('shared_map.settings.modal.map_box.help.here')}
                        </LandingLink>
                    </p>
                </FormBackdrop>

                <FormActions>
                    <Button
                        color="primary"
                        disabled={loading}
                        onClick={this.handleSave(true)}
                        data-testid="shared_map.settings.modal.button.save"
                    >
                        {t('button.save')}
                    </Button>
                </FormActions>

                <Dialog
                    open={this.state.showUserMismatchConfirmation}
                    onClose={this.handleCloseUserMismatchConfirmation}
                >
                    <DialogContent>{t('shared_map.settings.modal.map_box.user_mismatch')}</DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.handleSave(false)}
                            data-testid="shared_map.settings.modal.button.warning.save"
                        >
                            {t('button.save')}
                        </Button>
                        <Button
                            color="default"
                            onClick={this.handleCloseUserMismatchConfirmation}
                            data-testid="shared_map.settings.modal.button.warning.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SharedMapModalSettings);
