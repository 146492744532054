import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MenuItemWrapText } from 'components/Menu/MenuItemWrapText';
import { enqueueSnackbarService } from 'service/MapPage';
import logRocketManager from 'service/LogRocket';
import { User } from 'interfaces/user';
import copy from 'copy-to-clipboard';

interface Props extends WithTranslation {
    user: User | null | undefined;
}

interface State {
    userUrl: string | null;
}

class LogRocketMenuItems extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userUrl: null,
        };
    }

    componentDidMount() {
        this.setState({
            userUrl: logRocketManager.getUserUrl(this.props.user?.id),
        });
    }

    handleCopyUserUrl = () => {
        const { userUrl } = this.state;
        if (!userUrl) {
            enqueueSnackbarService.sendErrorMessage(this.props.t('main_menu.logrocket.no_session'));

            return;
        }

        copy(userUrl);
        enqueueSnackbarService.sendSuccessMessage(this.props.t('main_menu.logrocket.copied'));
    };

    handleCopySessionUrl = async () => {
        const sessionIsStarting = logRocketManager.init();

        if (sessionIsStarting) {
            enqueueSnackbarService.sendWarningMessage(this.props.t('main_menu.logrocket.reload'));
            this.setState({
                userUrl: logRocketManager.getUserUrl(this.props.user?.id),
            });
        }

        const sessionUrl = await logRocketManager.getSessionUrl();
        if (sessionUrl === null || !sessionUrl.startsWith('https://')) {
            enqueueSnackbarService.sendErrorMessage(sessionUrl ?? this.props.t('main_menu.logrocket.no_session'));

            return;
        }

        copy(sessionUrl);
        enqueueSnackbarService.sendSuccessMessage(this.props.t('main_menu.logrocket.copied'));
    };

    render() {
        const sessionIsStarted = logRocketManager.sessionUrl !== null;

        return (
            <>
                <MenuItemWrapText onClick={this.handleCopyUserUrl}>
                    <Grid container spacing={1} alignContent="center">
                        <Grid item style={{ paddingTop: 6 }}>
                            <Icon className={'fas fa-copy'} fontSize="small" style={{ color: 'gray' }} />
                        </Grid>
                        <Grid item>{this.props.t('main_menu.logrocket.user_url')}</Grid>
                    </Grid>
                </MenuItemWrapText>
                <MenuItemWrapText onClick={this.handleCopySessionUrl}>
                    <Grid container spacing={1} alignContent="center">
                        <Grid item style={{ paddingTop: 6 }}>
                            <Icon className={'fas fa-copy'} fontSize="small" style={{ color: 'gray' }} />
                        </Grid>
                        <Grid item>
                            {this.props.t(
                                sessionIsStarted
                                    ? 'main_menu.logrocket.session_url'
                                    : 'main_menu.logrocket.start_session',
                            )}
                        </Grid>
                    </Grid>
                </MenuItemWrapText>
            </>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LogRocketMenuItems);
