import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Grid, Button, Chip, TextField, Typography, withStyles } from '@material-ui/core';
import { FormActions } from '../PureFormDialog/Form';
import PureFormDialog from '../PureFormDialog';
import InsertFieldForm from './InsertFieldForm';
import InsertFunctionForm from './InsertFunctionForm';
import { CallContext } from '../utils/CallContext';
import InsertPicklistForm from './InsertPicklistForm';

const ValidationTypography = withStyles({
    colorPrimary: {
        color: 'green',
    },
})(Typography);

class FormulaEditorForm extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.props.formula &&
            this.inputRef.current.setSelectionRange(this.props.formula.length, this.props.formula.length);
    }

    componentDidUpdate() {
        this.inputRef.current.focus();
    }

    handleInputChange = (event) => {
        this.props.onChange(event.target.value, this.props.lastFunction);
    };

    isTwigTemplate = (formula, position) => {
        /**
         * Searches for all twig code blocks inside {% ... %} or {{ ... }}
         */
        const matches = Array.from(formula.matchAll(/{%[^]*?%}|{{.*?}}/g));
        for (const match of matches) {
            if (position > match.index && position < match.index + match[0].length) {
                return true;
            }
        }
        return false;
    };

    handleInsertPicklistValue = (value) => {
        const position = this.inputRef.current.selectionEnd;

        const formula = this.props.formula || '';
        if (this.isTwigTemplate(formula, position)) {
            if (Array.isArray(value)) {
                value = '[' + value.map((item) => '"' + item + '"').join(', ') + ']';
            } else {
                value = '"' + value + '"';
            }
        } else if (Array.isArray(value)) {
            value = value.join(', ');
        }

        const [newFormula, newPosition] = this.insertIntoFormula(formula, value, position, true);

        this.props.onChange(newFormula, this.props.lastFunction, () => {
            this.inputRef.current.setSelectionRange(newPosition, newPosition);
            this.props.onPicklistModalClose();
        });
    };

    handleInsertField = (field) => {
        const position = this.inputRef.current.selectionEnd;

        const [formula, newPosition] = this.insertIntoFormula(this.props.formula || '', field, position);

        this.props.onChange(formula, this.props.lastFunction, () => {
            this.inputRef.current.setSelectionRange(newPosition, newPosition);
            this.props.onFieldModalClose();
        });
    };

    handleInsertFunction = (func) => {
        const position = this.inputRef.current.selectionEnd;

        let [formula, newPosition] = this.insertIntoFormula(this.props.formula || '', func.name + '()', position);
        if (func.args.length > 0) {
            newPosition--;
        }

        this.props.onChange(formula, func, () => {
            this.inputRef.current.setSelectionRange(newPosition, newPosition);
            this.props.onFunctionModalClose();
        });
    };

    insertIntoFormula = (formula, insert, position, insertAsString = false) => {
        let newPosition = position + insert.length;
        if (!this.props.rawFormula && !this.isTwigTemplate(formula, position) && !insertAsString) {
            insert = '{{ ' + insert + ' }}';
            newPosition += 3;
        }

        return [[formula.slice(0, position), insert, formula.slice(position)].join(''), newPosition];
    };

    renderFunction = (func) => {
        return (
            <span>
                {func.name}(
                {func.args.map((arg, index, arr) => (
                    <React.Fragment key={index}>
                        <Chip label={arg} size="small" />
                        {index + 1 < arr.length && <span>, </span>}
                    </React.Fragment>
                ))}
                )
            </span>
        );
    };

    renderValidationResult = () => {
        if (false === this.props.showValidationResult) {
            return null;
        }

        return this.props.validationErrorMessage ? (
            <ValidationTypography variant="caption" color="error">
                {this.props.validationErrorMessage}
            </ValidationTypography>
        ) : (
            <ValidationTypography variant="caption" color="primary">
                {this.props.t('formula_editor.validation.success')}
            </ValidationTypography>
        );
    };

    render() {
        return (
            <form noValidate autoComplete="off" spellCheck="false" onKeyPress={this.handleKeyPress}>
                <TextField
                    label={this.props.t('formula_editor.form.input')}
                    data-testid="formula_editor.form.input"
                    fullWidth
                    multiline
                    autoFocus
                    rowsMax={20}
                    margin="dense"
                    name="formula"
                    value={this.props.formula || ''}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    inputRef={this.inputRef}
                />
                {this.renderValidationResult()}
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Button
                            onClick={this.props.onCheckSyntax}
                            disabled={!this.props.formula}
                            color="primary"
                            data-testid="formula_editor.check_syntax"
                        >
                            {this.props.t('formula_editor.check_syntax')}
                        </Button>
                    </Grid>
                    {this.props.lastFunction && (
                        <Grid item>
                            <b>{this.props.t('formula_editor.last_inserted_function')}: </b>
                            {this.renderFunction(this.props.lastFunction)}
                        </Grid>
                    )}
                </Grid>

                <PureFormDialog
                    open={this.props.insertPicklistModal}
                    title={this.props.t('formula_editor.insert_picklist_value')}
                    onClose={this.props.onPicklistModalClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <InsertPicklistForm
                        picklist={this.props.picklist}
                        multiPicklist={this.props.multiPicklist}
                        onSave={this.handleInsertPicklistValue}
                    />
                </PureFormDialog>

                <PureFormDialog
                    open={this.props.insertFieldModal}
                    title={this.props.t('formula_editor.insert_field')}
                    onClose={this.props.onFieldModalClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <InsertFieldForm
                        entities={this.props.entities}
                        callContext={this.props.callContext}
                        options={this.props.options}
                        onSave={this.handleInsertField}
                    />
                    <FormActions />
                </PureFormDialog>

                <PureFormDialog
                    open={this.props.insertFunctionModal}
                    title={this.props.t('formula_editor.insert_function')}
                    onClose={this.props.onFunctionModalClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <InsertFunctionForm onSave={this.handleInsertFunction} renderFunction={this.renderFunction} />
                    <FormActions />
                </PureFormDialog>
            </form>
        );
    }
}

FormulaEditorForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    insertPicklistModal: PropTypes.bool.isRequired,
    onPicklistModalClose: PropTypes.func.isRequired,
    insertFieldModal: PropTypes.bool.isRequired,
    onFieldModalClose: PropTypes.func.isRequired,
    insertFunctionModal: PropTypes.bool.isRequired,
    onFunctionModalClose: PropTypes.func.isRequired,
    onCheckSyntax: PropTypes.func.isRequired,
    callContext: PropTypes.instanceOf(CallContext),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
    formula: PropTypes.string,
    showValidationResult: PropTypes.bool.isRequired,
    validationErrorMessage: PropTypes.string,
    lastFunction: PropTypes.shape({
        name: PropTypes.string.isRequired,
        args: PropTypes.array.isRequired,
    }),
    picklist: PropTypes.array,
    multiPicklist: PropTypes.bool.isRequired,
    rawFormula: PropTypes.bool.isRequired,
};

const formulaEditorForm = withTranslation('translations', { withRef: true })(FormulaEditorForm);
export { formulaEditorForm as FormulaEditorForm };
