import { Calendar, CalendarField } from 'interfaces/calendar/calendar';

type CalendarsInlcudeFields = { [key: string]: Calendar[] };

class AppointmentMetadataChecker {
    getRequiredAppointmentFieldsWithCalendars(calendars: Calendar[]): CalendarsInlcudeFields {
        if (!calendars || !calendars.length) {
            return {};
        }

        const calendarsInlcudeFields: CalendarsInlcudeFields = {};

        calendars.forEach((calendar) => {
            const { fields, location, time, title, type, virtual } = calendar;

            const otherFields = [
                ...(fields || []).map((field) => (field as CalendarField).apiName),
                location?.field,
                time?.allDayField,
                time?.startField,
                time?.endField,
                time?.dateField,
                title?.field,
                type?.field,
                virtual?.field,
                virtual?.fieldFalseValue,
                virtual?.fieldTrueValue,
            ];

            otherFields.forEach((field) => {
                if (field) {
                    if (calendarsInlcudeFields[field]) {
                        calendarsInlcudeFields[field].push(calendar);
                    } else {
                        calendarsInlcudeFields[field] = [calendar];
                    }
                }
            });
        });

        return calendarsInlcudeFields;
    }
}

export default AppointmentMetadataChecker;
