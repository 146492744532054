import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FieldLabel from './FieldLabel';
import { DataSourceSetting } from 'service/types';

interface Props extends WithTranslation {
    definition: DataSourceSetting;
    value: number | string;
    error: string;
    autoFocus: boolean;
    onValueChanged: () => void;
}

class InputField extends React.PureComponent<Props> {
    render() {
        const { definition, value, error, autoFocus, onValueChanged, t, i18n, tReady, ...rest } = this.props;

        return (
            <TextField
                label={<FieldLabel definition={definition} />}
                fullWidth
                margin="dense"
                value={value || ''}
                helperText={error}
                error={error !== ''}
                InputProps={{ disableUnderline: false }}
                onChange={onValueChanged}
                placeholder={this.getInputPlaceholder(definition)}
                autoFocus={autoFocus}
                {...rest}
            />
        );
    }

    private getInputPlaceholder = (definition: DataSourceSetting) => {
        if (!definition.placeholder) {
            return '';
        }

        const { t } = this.props;
        let placeholder = definition.placeholder;
        const translate = t('adapter-settings-field-placeholder-' + definition.placeholder);
        if (translate !== 'adapter-settings-field-placeholder-' + definition.placeholder) {
            placeholder = translate;
        }

        return placeholder;
    };
}

export default withTranslation()(InputField);
