import { Fab, Icon } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import SmallFab from './SmallFab';

interface Props {
    small: boolean;
    disabled: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className: string | undefined;
    style: object | undefined;
    component?: string | React.ReactNode;
}

class AddIconButton extends React.PureComponent<Props> {
    static defaultProps = {
        small: false,
        disabled: false,
        className: undefined,
        style: undefined,
    };

    render() {
        const { small, disabled, onClick, className, style, ...rest } = this.props;
        const Component = small ? SmallFab : Fab;
        const fontSize = small ? 'small' : 'default';

        return (
            <Component
                color="primary"
                size="small"
                onClick={onClick}
                disabled={disabled}
                className={className}
                style={style}
                {...rest}
            >
                <Icon fontSize={fontSize}>add_icon</Icon>
            </Component>
        );
    }
}

export default AddIconButton;
