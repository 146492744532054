import React from 'react';
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Checkbox,
    Switch,
    FormHelperText,
} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import Hint from '../Hint';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

class ScheduleForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.defaultSchedule = {
            interval: 30,
        };

        this.defaultTune = {
            from: '22:00',
            to: '06:00',
            interval: 60,
        };

        this.state = {
            schedule: this.props.schedule,
        };

        this.intervals = [60, 30, 20, 10];

        this.hoursOfDay = {};
        for (let i = 0; i < 24; i++) {
            const key = i < 10 ? `0${i}:00` : `${i}:00`;
            this.hoursOfDay[key] = this.constructor.getDayTimeLabel(i);
        }
    }

    getIntervalLabel(minutes) {
        const { t } = this.props;
        if (minutes === 60) {
            return t('schedule.frequency.hour');
        }

        return t('schedule.frequency.minutes', { minutes });
    }

    static getDayTimeLabel(i) {
        let a = 'am';
        if (i >= 12) {
            a = 'pm';
            i -= 12;
        }
        if (i === 0) {
            i = 12;
        }
        return i + ':00 ' + a;
    }

    handleToggle = () => {
        this.setState((state) => ({
            schedule: state.schedule ? null : this.defaultSchedule,
        }));
    };

    handleTune = () => {
        this.setState((state) => {
            const schedule = cloneDeep(state.schedule);
            if (schedule.tune) {
                delete schedule.tune;
            } else {
                schedule.tune = this.defaultTune;
            }
            return {
                schedule,
            };
        });
    };

    handleIntervalChange = (event) => {
        this.setState((state) => {
            const schedule = cloneDeep(state.schedule);
            schedule.interval = event.target.value;
            return {
                schedule,
            };
        });
    };

    handleTuning = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState((state) => {
            const schedule = cloneDeep(state.schedule);
            schedule.tune[name] = value;
            return {
                schedule,
            };
        });
    };

    submit() {
        const { onSubmitSuccess, onSubmitError } = this.props;

        if (this.hasIntervalError()) {
            onSubmitError && onSubmitError(this.props.t('schedule.invalid_input'));
            return;
        }

        const schedule = cloneDeep(this.state.schedule);
        if (schedule && schedule.tune && schedule.interval === schedule.tune.interval) {
            delete schedule.tune;
        }

        onSubmitSuccess && onSubmitSuccess(schedule);
    }

    hasIntervalError = () => {
        return (
            this.state.schedule &&
            this.state.schedule.tune &&
            this.state.schedule.tune.from === this.state.schedule.tune.to
        );
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <StyledFormControlLabel
                    control={
                        <Switch
                            checked={this.state.schedule !== null}
                            onChange={this.handleToggle}
                            color="primary"
                            data-testid="schedule.switch"
                        />
                    }
                    label={t('schedule.switch')}
                    labelPlacement="start"
                />
                {this.state.schedule !== null && (
                    <div>
                        <FormControl margin="dense" style={{ width: 200, marginBottom: 8 }}>
                            <InputLabel>{t('schedule.frequency')}</InputLabel>
                            <Select
                                value={this.state.schedule.interval}
                                onChange={this.handleIntervalChange}
                                data-testid="schedule.frequency"
                            >
                                {this.intervals.map((interval) => (
                                    <MenuItem key={interval} value={interval}>
                                        {this.getIntervalLabel(interval)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!this.state.schedule.tune}
                                        onChange={this.handleTune}
                                        color="primary"
                                    />
                                }
                                label={t('schedule.custom')}
                            />
                            <Hint>{t('schedule.custom_hint')}</Hint>
                        </div>

                        {this.state.schedule.tune && (
                            <div style={{ paddingLeft: 30 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControl margin="dense" fullWidth error={this.hasIntervalError()}>
                                            <InputLabel>{t('schedule.from')}</InputLabel>
                                            <Select
                                                value={this.state.schedule.tune.from}
                                                onChange={this.handleTuning}
                                                name="from"
                                                data-testid="schedule.from"
                                            >
                                                {Object.keys(this.hoursOfDay).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {this.hoursOfDay[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {this.hasIntervalError() && (
                                                <FormHelperText>{t('schedule.from_hint')}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl margin="dense" fullWidth>
                                            <InputLabel>{t('schedule.to')}</InputLabel>
                                            <Select
                                                value={this.state.schedule.tune.to}
                                                onChange={this.handleTuning}
                                                name="to"
                                                data-testid="schedule.to"
                                            >
                                                {Object.keys(this.hoursOfDay).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {this.hoursOfDay[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl margin="dense" fullWidth>
                                            <InputLabel>{t('schedule.frequency')}</InputLabel>
                                            <Select
                                                value={this.state.schedule.tune.interval}
                                                onChange={this.handleTuning}
                                                name="interval"
                                                data-testid="schedule.frequency"
                                            >
                                                <MenuItem value={0} data-testid="schedule.frequency.never">
                                                    {t('schedule.frequency.never')}
                                                </MenuItem>
                                                {this.intervals.map((interval) => (
                                                    <MenuItem key={interval} value={interval}>
                                                        {this.getIntervalLabel(interval)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const formControlLabelStyles = {
    labelPlacementStart: {
        marginLeft: 0,
    },
};

const StyledFormControlLabel = withStyles(formControlLabelStyles)(FormControlLabel);

export default withTranslation('translations', { withRef: true })(ScheduleForm);
