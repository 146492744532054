import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ConfirmationIconButton from 'components/CustomButton/ConfirmationIconButton';
import { Calendar } from 'interfaces/calendar/calendar';
import Switch from '@material-ui/core/Switch';
import { calendarManager } from '../../../../../service/Calendar/CalendarManager';
import { Tooltip } from '@material-ui/core';
import { Entity } from 'interfaces/entity';

interface Props extends WithTranslation {
    calendarKey: string;
    calendar: Calendar;
    entity?: Entity;
    accountId: number;
    handleDelete: (calendarId: string) => void;
}

interface State {
    isActive: boolean;
}

class CalendarRow extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isActive: this.props.calendar.active,
        };
    }

    render() {
        const { t, calendar, handleDelete, entity } = this.props;
        const { isActive } = this.state;

        const isDisabled = Boolean(!entity || !entity.isIncluded || entity.deletedAt || entity.dataSource.deletedAt);
        const activeSwitch = (
            <Switch
                checked={isActive}
                onChange={(e) => this.handleCalendarActiveToggle(e.target.checked)}
                color="primary"
                disabled={isDisabled}
            />
        );
        const dataSourceName = entity?.dataSource.name ?? calendar.name;
        const calendarEntityName =
            entity?.label ?? t('calendar_settings_calendars.dialog.form_control.calendar_object.label');

        return (
            <TableRow>
                <TableCell width="60px">
                    {isDisabled ? (
                        <Tooltip
                            title={t('calendar_settings.dialog.tabs.calendars.can_not_enable_calendar', {
                                calendarName: calendar.name,
                                calendarEntityName,
                                dataSourceName,
                            })}
                        >
                            <span>{activeSwitch}</span>
                        </Tooltip>
                    ) : (
                        activeSwitch
                    )}
                </TableCell>
                <TableCell>
                    <Link component="button" onClick={this.handleClickEdit}>
                        {calendar.name}
                    </Link>
                </TableCell>
                <TableCell>{this.getCalendarObjectName(entity)}</TableCell>
                <TableCell align="right">
                    <ConfirmationIconButton
                        iconName="delete"
                        color="primary"
                        component="span"
                        onClick={() => {
                            handleDelete(calendar.id);
                        }}
                        textYes={t('confirm')}
                        textNo={t('cancel')}
                        text={t('calendar_settings.dialog.tabs.calendars.remove.popover')}
                    />
                </TableCell>
            </TableRow>
        );
    }

    private handleCalendarActiveToggle = async (isChecked: boolean) => {
        const { calendar } = this.props;
        this.setState({ ...this.state, isActive: isChecked });
        await calendarManager.update(this.props.accountId, { ...calendar, active: isChecked });
    };

    private handleClickEdit = () => {
        //TODO: finish
    };

    private getCalendarObjectName(entity?: Entity): string {
        if (!entity) {
            return this.props.t('calendar_settings_calendars.dialog.form_control.calendar_object.not_found');
        }

        return `${entity.dataSource.name} -> ${entity.label}`;
    }
}

export default withTranslation()(CalendarRow);
