import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG } from 'service/MapPage/RoutingSettings/config';
import { Routing } from 'interfaces';
import TextField from '@material-ui/core/TextField';
import Hint from '../../Hint';
import {
    MappingDefaultValueControlConfig,
    MappingDefaultValueControlIntegerOptions,
} from '../../../interfaces/routing/settings';
import { FormControl, FormHelperText, Grid, InputAdornment, InputLabel } from '@material-ui/core';

interface Props extends WithTranslation {
    value: number | null;
    onValueChanged: (value: number | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

class MaxDrivingTimeInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled, bareInput } = this.props;
        const transformedValue = value === null ? '' : this.minutesToHours(value);
        const options = MaxDrivingTimeInput.getOptions();
        const min = this.minutesToHours(options.min);
        const max = this.minutesToHours(options.max, true);

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin={bareInput ? 'none' : 'dense'} error={error !== undefined}>
                        {!bareInput && (
                            <InputLabel shrink={true}>
                                {t('route_editor.modal.routing_preferences.modal.max_driving_time.label')}
                            </InputLabel>
                        )}
                        <TextField
                            error={!!error}
                            disabled={disabled}
                            value={transformedValue}
                            fullWidth
                            type="number"
                            InputProps={{
                                inputProps: { min, max, step: 0.1, style: { textAlign: 'end' } },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {t('route_editor.modal.routing_preferences.modal.input_hr_postfix')}
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleChange}
                            variant="standard"
                            margin={bareInput ? 'none' : 'normal'}
                            label={t('route_editor.modal.routing_preferences.modal.max_driving_time.label')}
                        />
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.max_driving_time.hint')}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private static getOptions(): MappingDefaultValueControlIntegerOptions {
        const controlConfig = ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG.get(
            Routing.Settings.MappingUserKey.MAX_DRIVING_TIME,
        ) as MappingDefaultValueControlConfig;
        return controlConfig.options as MappingDefaultValueControlIntegerOptions;
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onValueChanged } = this.props;
        const value = event.target.value === '' ? null : this.hoursToMinutes(parseFloat(event.target.value));
        onValueChanged(value).then(() => this.validate(value));
    };

    private validate = (value: number | null): Promise<void> => {
        const { onInputValidation, t } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        const options = MaxDrivingTimeInput.getOptions();
        const isValid = value === null || (value >= options.min && value <= options.max);
        let error = undefined;
        if (!isValid) {
            if (value === null) {
                error = t('validation.value.required');
                // @ts-ignore
            } else if (value < options.min || value > options.max) {
                error = t('validation.value.min_max', {
                    min: this.minutesToHours(options.min),
                    max: this.minutesToHours(options.max, true),
                });
            }
        }
        return onInputValidation(isValid, error);
    };

    private minutesToHours = (minutes: number, floor: boolean = false): number => {
        if (floor) {
            return Math.floor((minutes / 60) * 10) / 10;
        }
        return parseFloat((minutes / 60).toFixed(1));
    };

    private hoursToMinutes = (hours: number): number => {
        return hours * 60;
    };
}

export default withTranslation()(MaxDrivingTimeInput);
