import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Transcript } from 'interfaces/file';
import { Button, Tooltip, Menu, MenuItem, IconButton, Icon, Box, CircularProgress } from '@material-ui/core';
import { formatSeconds } from '../../../../utils';

interface TranscriptItemHeaderProps extends WithTranslation {
    item: Transcript.TranscriptItem;
    speakers: Map<number, Transcript.TranscriptSpeaker>;
    editMode: boolean;
    saving: boolean;

    onRewind: (time: number) => void;
    onAddItemAbove: () => void;
    onAddItemBelow: () => void;
}

interface TranscriptItemHeaderState {
    speakerMenuAnchorEl: Element | null;
}

class TranscriptItemHeader extends React.Component<TranscriptItemHeaderProps, TranscriptItemHeaderState> {
    constructor(props: TranscriptItemHeaderProps) {
        super(props);

        this.state = {
            speakerMenuAnchorEl: null,
        };
    }

    getSpeakerLabel = (item: Transcript.TranscriptItem) => {
        return this.props.speakers.has(item.speaker.id)
            ? this.props.speakers.get(item.speaker.id)!.label
            : item.speaker.label;
    };

    handleOpenSpeakerMenu = (event: React.MouseEvent) => {
        this.setState({ speakerMenuAnchorEl: event.currentTarget });
    };

    handleCloseSpeakerMenu = () => {
        this.setState({ speakerMenuAnchorEl: null });
    };

    renderTime = (item: Transcript.TranscriptItem) => {
        return (
            <Tooltip title={this.props.t('file_input.transcript.item.play_from_here')}>
                <Button
                    onClick={() => this.props.onRewind(item.time)}
                    size="small"
                    startIcon={<Icon fontSize="inherit" className="fa-light fa-play" />}
                    className="transcript-item__time"
                >
                    {formatSeconds(item.time)}
                </Button>
            </Tooltip>
        );
    };

    renderSpeakerOption = (speaker: Transcript.TranscriptSpeaker) => {
        return (
            <div>
                <Box component="span" mr={1}>
                    {speaker.label}
                </Box>
                <IconButton size="small">
                    <Icon fontSize="inherit" className="fa-light fa-pencil" />
                </IconButton>
            </div>
        );
    };

    renderSpeakerButton = () => {
        return (
            <React.Fragment>
                <IconButton size="small" onClick={this.handleOpenSpeakerMenu} style={{ marginLeft: 5 }}>
                    <Icon fontSize="inherit" className="fa-light fa-pencil" />
                </IconButton>

                <Menu
                    anchorEl={this.state.speakerMenuAnchorEl}
                    open={this.state.speakerMenuAnchorEl !== null}
                    onClose={this.handleCloseSpeakerMenu}
                >
                    {Array.from(this.props.speakers.values()).map((speaker) => (
                        <MenuItem key={speaker.id} onClick={() => {}}>
                            {this.renderSpeakerOption(speaker)}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    };

    render() {
        const { item, editMode } = this.props;

        return (
            <div>
                <div>
                    <span className="transcript-item__speaker">{this.getSpeakerLabel(item)}</span>
                    {editMode && this.renderSpeakerButton()}
                    {this.renderTime(item)}
                </div>
                {editMode && (
                    <div>
                        {this.props.saving && <CircularProgress size={20} style={{ marginRight: '5px' }} />}
                        <IconButton onClick={this.props.onAddItemAbove}>
                            <Icon fontSize="small" className="fa-light fa-turn-up" />
                        </IconButton>
                        <IconButton onClick={this.props.onAddItemBelow}>
                            <Icon fontSize="small" className="fa-light fa-turn-down" />
                        </IconButton>
                        <IconButton color="secondary">
                            <Icon fontSize="small" className="fa-light fa-trash-can" />
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(TranscriptItemHeader);
