import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, colors } from '@material-ui/core';

const TooltipTitle = (props) => <div style={{ whiteSpace: 'pre-wrap' }} {...props} />;

class Hint extends React.PureComponent {
    getTooltipProps = () => {
        return this.props.TooltipProps;
    };

    getIconProps = () => {
        const { iProps, type } = this.props;
        let className = 'fas fa-question-circle';

        let style = {
            color: type === 'inline' ? colors.common.black : colors.grey[600],
        };
        if (!this.props.inheritStyles) {
            style = {
                ...style,
                fontSize: 'small',
                display: 'inline',
                verticalAlign: 'middle',
            };
        }

        if (iProps) {
            if (iProps.className) {
                className += ' ' + iProps.className;
            }
            if (iProps.style) {
                style = { ...style, ...iProps.style };
            }
        }
        return {
            ...iProps,
            className,
            style,
        };
    };

    render() {
        if (this.props.type === 'inline') {
            return (
                <div style={{ display: 'flex' }}>
                    <div>
                        <i {...this.getIconProps()} />
                    </div>
                    <div style={{ marginLeft: 5, lineHeight: 1.2, alignSelf: 'center' }}>
                        <span style={{ fontSize: 'small', color: '#888' }}>{this.props.children}</span>
                    </div>
                </div>
            );
        }

        return (
            <Tooltip
                title={<TooltipTitle>{this.props.children}</TooltipTitle>}
                {...this.getTooltipProps()}
                enterTouchDelay={1}
                enterDelay={1}
            >
                <i {...this.getIconProps()} />
            </Tooltip>
        );
    }
}

Hint.propTypes = {
    type: PropTypes.oneOf(['inline', 'tooltip']),
    TooltipProps: PropTypes.object,
    iProps: PropTypes.object,
    inheritStyles: PropTypes.bool,
};

Hint.defaultProps = {
    type: 'tooltip',
    TooltipProps: {},
    iProps: {},
};

export default Hint;
