import React, { ChangeEvent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button, FormControl, FormHelperText, FormLabel, MenuItem, Tooltip, Select } from '@material-ui/core';
import './style.css';
import { MultipleSelect } from '../Controls';
import { FormActions } from '../PureFormDialog/Form';
import { IWorkflow } from '../../interfaces';

interface SearchFilterFormProps extends WithTranslation {
    formId: number | null;
    searchBarFields: Array<string>;
    forms: Array<IWorkflow.IForm>;
    onClose: Function;
    onSave(data: IWorkflow.IProfileSearchFilterForm): void;
    invalidSearchBarFields: Array<string>;
    isInvalidFormId: boolean;
}

const SearchFilterForm: React.FC<SearchFilterFormProps> = observer((props) => {
    const { t, forms, onClose, onSave, invalidSearchBarFields, isInvalidFormId } = props;
    const [formId, setFormId] = useState(props.formId);
    const [searchBarFields, setSearchBarFields] = useState(props.searchBarFields);
    const [formIdError, setFormIdError] = useState(isInvalidFormId);
    const [searchBarFieldsError, setSearchBarFieldsError] = useState(invalidSearchBarFields);

    const selectedForm = forms.find((form) => form.id === formId);
    const picklistFields = selectedForm
        ? selectedForm.details.fields.filter((field) => field.fieldType === 'picklist')
        : [];
    const picklistMap = new Map();
    for (let picklistField of picklistFields) {
        picklistMap.set(
            picklistField.apiName,
            picklistField.label
                ? picklistField.label
                : '[' + t('workflow_actions.forms.field.no_label') + '] ' + picklistField.apiName,
        );
    }

    const handleFormChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setFormId(parseInt(event.target.value as string) || null);
        setSearchBarFields([]);
        setFormIdError(false);
    };

    const handleSearchBarFieldsChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setSearchBarFields(event.target.value as Array<string>);
        setSearchBarFieldsError([]);
    };

    const handleSave = () => {
        onSave({ formId, searchBarFields });
    };

    const renderSearchBarFields = (selected: Array<string>) => {
        const values: Array<string> = [];
        for (let selectedValue of selected) {
            if (picklistMap.has(selectedValue)) {
                values.push(picklistMap.get(selectedValue));
            }
        }
        return values.join(', ');
    };

    const menuProps: any = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    return (
        <div className="c-search-filter-form">
            <FormControl fullWidth margin="dense">
                <FormLabel>
                    {t('search_filters.form')}
                    <Tooltip title={t('search_filters.form.hint') as string}>
                        <i className="fas fa-question-circle" style={{ marginLeft: 5 }} />
                    </Tooltip>
                </FormLabel>
                <Select
                    value={formId || ''}
                    onChange={handleFormChange}
                    error={formIdError}
                    MenuProps={menuProps}
                    data-testid="search_filters.form"
                >
                    <MenuItem key={0} value="" data-testid="search_filters.form.not_selected">
                        {t('search_filters.form.not_selected')}
                    </MenuItem>
                    {forms.map((form) => (
                        <MenuItem key={form.id} value={form.id}>
                            {form.name}
                        </MenuItem>
                    ))}
                </Select>
                {formIdError && <FormHelperText>{t('search_filters.form.invalid')}</FormHelperText>}
            </FormControl>
            {formId && !!picklistFields.length && (
                <FormControl fullWidth margin="dense" className={'c-search-filter-form-input'}>
                    <FormLabel>
                        {t('search_filters.search_bar_fields')}
                        <Tooltip title={t('search_filters.search_bar_fields.hint') as string}>
                            <i className="fas fa-question-circle" style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </FormLabel>
                    <MultipleSelect
                        value={searchBarFields}
                        onChange={handleSearchBarFieldsChange}
                        error={!!searchBarFieldsError.length}
                        renderValue={renderSearchBarFields}
                        data-testid="search_filters.search_bar_fields"
                    >
                        {picklistFields.map((field) => (
                            <MenuItem key={field.apiName} value={field.apiName}>
                                {field.label
                                    ? field.label
                                    : '[' + t('workflow_actions.forms.field.no_label') + '] ' + field.apiName}
                            </MenuItem>
                        ))}
                    </MultipleSelect>
                    {!!searchBarFieldsError.length && (
                        <FormHelperText>
                            {t('search_filters.search_bar_fields.invalid', { fields: searchBarFieldsError.join(', ') })}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
            {formId && !picklistFields.length && <p>{t('search_filters.search_bar_fields.empty')}</p>}

            <FormActions onClose={onClose}>
                <Button color="primary" onClick={handleSave} data-testid="search_filters.form.button.save">
                    {t('button.save')}
                </Button>
            </FormActions>
        </div>
    );
});

export default withTranslation()(SearchFilterForm);
