import React from 'react';
import { Grid, Button as ButtonElement, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '../../../service/workflow_actions/forms/fields/Button';
import AbstractField from './AbstractField';

class ButtonField extends AbstractField {
    renderInput(field, errors) {
        let hasFormErrors = false;
        for (let subErrors of errors.values()) {
            hasFormErrors |= subErrors.size > 0;
        }
        return (
            <ButtonElement
                className="field-input"
                disabled={(field.getIsActionButton() && hasFormErrors === 1) || field.getIsDisabled()}
                color="primary"
                onClick={this.handleHandler}
            >
                {field.getLabel()}
            </ButtonElement>
        );
    }

    render() {
        const field = this.props.field;
        const errors = this.props.errors;

        if (field.getIsActionButton()) {
            return this.renderInput(field, errors);
        }
        const apiName = field.getApiName();

        return (
            <Grid item xs={12} className={`${apiName}-field-root`}>
                <Box mt={3}>{this.renderInput(field, errors)}</Box>
            </Grid>
        );
    }
}

ButtonField.propTypes = {
    field: PropTypes.instanceOf(Button).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ButtonField;
