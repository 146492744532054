import { useEffect, useRef, useState } from 'react';

/**
 * Used to track if element content is overflowing it's container
 */
export function useIsOverflowing(defaultValue = false) {
    const [isOverflowing, setIsOverflowing] = useState(defaultValue);
    // const textElementRef = useRef<Pick<HTMLElement, 'scrollWidth' | 'clientWidth'>>(null);
    const textElementRef = useRef<HTMLElement>(null);

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        const compareSize = () => {
            if (!textElementRef.current) {
                return;
            }

            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

            setIsOverflowing(compare);
        };

        compareSize();
        window.addEventListener('resize', compareSize);

        return () => {
            window.removeEventListener('resize', compareSize);
        };
    }, []);

    return [isOverflowing, textElementRef] as const;
}
