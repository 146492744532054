import React from 'react';
import PureFormDialog from '../PureFormDialog';
import {
    Button,
    FormControlLabel,
    Grid,
    Icon,
    RadioGroup,
    Radio,
    Typography,
    IconButton,
    Checkbox,
    Select,
    MenuItem,
    Tooltip,
    Box,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { FormActions } from '../PureFormDialog/Form';
import './style.css';
import { USER_ROLE, userManager } from '../../service/UserManager';
import { roleManager } from '../../service/RoleManager';
import * as PropTypes from 'prop-types';
import { PERMISSION_MODIFY, PERMISSION_VIEW, SHARING_MODULE } from '../Permissions/constants';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import LandingLink from '../HelpLink/LandingLink';
import AddIconButton from '../CustomButton/AddIconButton';
import Hint from '../Hint';

const PERMISSIONS = [PERMISSION_VIEW, PERMISSION_MODIFY];

export const OBJECT_TYPE_SAVED_PLACE = 'savedPlace';
export const OBJECT_TYPE_MAP_LAYER_GROUP = 'mapLayerGroup';
export const OBJECT_TYPE_VIEW = 'view';
export const OBJECT_TYPE_TERRITORY_GROUP = 'territoryGroup';

export const LEVEL_PRIVATE = 'private';
export const LEVEL_PUBLIC = 'public';
export const LEVEL_CUSTOM = 'custom';

const TYPE_USER = 'user';
const TYPE_ROLE = 'role';

export function createDefaultPermission(objectType, objectId) {
    return {
        objectType: objectType,
        objectId: objectId,
        level: LEVEL_PRIVATE,
        permissions: null,
    };
}

export function createDefaultRbac() {
    return {
        isPermissionEditable: false,
        isPropertiesEditable: true,
    };
}

export function createCreatedRbac() {
    return {
        isPermissionEditable: true,
        isPropertiesEditable: true,
    };
}

class SharingSettingsDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            permissions: props.permission.permissions === null ? null : [...props.permission.permissions],
            level: props.permission.level,
            users: [],
            roles: [],
            addUsersDialogOpen: false,
            addRolesDialogOpen: false,
            hasBrowseUsersPermission: false,
            hasBrowseUserProfilesPermission: false,
            adminDefaultPermission: null,
        };
    }

    createObjectPermission(objectType, objectId, level, permissions) {
        return {
            objectType: objectType,
            objectId: objectId,
            level: level,
            permissions: permissions,
        };
    }

    createCustomPermissionElement(objectType, objectId, permission) {
        return {
            objectType: objectType,
            objectId: objectId,
            permission: permission,
        };
    }

    componentDidMount() {
        this.updateHasPermissions();

        const account = userManager.getCurrentAccount();
        const user = userManager.getCurrentUser();

        roleManager.getAccountRoles(account.id, false).then((roles) => {
            const adminRoleId = roles.find((role) => role.code === USER_ROLE.ADMIN)?.id;
            this.setState({
                roles,
                adminDefaultPermission: {
                    objectType: TYPE_ROLE,
                    objectId: adminRoleId,
                    permission: PERMISSION_MODIFY,
                },
            });
        });

        userManager.getAccountUsers(account.id).then((users) => {
            this.setState({
                users: users.filter((u) => u.id !== user.id),
            });
        });

        dispatcher.subscribe([events.EVENT_ROLE_CHANGED, events.EVENT_ROLES_CHANGED], this, () => {
            this.updateHasPermissions();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    updateHasPermissions() {
        const hasSharingModule = userManager.userHasAccessTo(SHARING_MODULE.NAME);
        const hasBrowseUsersFeature = userManager.userHasAccessTo(
            SHARING_MODULE.NAME,
            SHARING_MODULE.FEATURES.BROWSE_USERS.NAME,
        );
        const hasBrowseUserProfilesFeature = userManager.userHasAccessTo(
            SHARING_MODULE.NAME,
            SHARING_MODULE.FEATURES.BROWSE_USER_PROFILES.NAME,
        );
        this.setState({
            hasBrowseUsersPermission: hasSharingModule && hasBrowseUsersFeature,
            hasBrowseUserProfilesPermission: hasSharingModule && hasBrowseUserProfilesFeature,
        });
    }

    handleLevelChange = (event) => {
        const level = event.target.value;
        const { hasBrowseUserProfilesPermission, adminDefaultPermission } = this.state;
        let defaultPermissions = null;
        if (level === LEVEL_CUSTOM) {
            defaultPermissions = hasBrowseUserProfilesPermission ? [adminDefaultPermission] : [];
        }
        this.setState({
            level: level,
            permissions: defaultPermissions,
        });
    };

    handleAddUserProfilesDialogOpen = () => {
        this.setState({
            addRolesDialogOpen: true,
        });
    };

    handleAddUserProfilesDialogClose = () => {
        this.setState({
            addRolesDialogOpen: false,
        });
    };

    handleAddUsersDialogOpen = () => {
        this.setState({
            addUsersDialogOpen: true,
        });
    };

    handleAddUsersDialogClose = () => {
        this.setState({
            addUsersDialogOpen: false,
        });
    };

    handleElementAdd = (type, permission, elements) => {
        const allPermissions = this.state.permissions;
        for (let element of elements) {
            allPermissions.push(this.createCustomPermissionElement(type, element.id, permission));
        }
        this.setState({
            permissions: [...allPermissions],
        });
    };

    handleElementPermissionChange = (type, element, permission) => {
        const allPermissions = this.state.permissions;
        const index = allPermissions.findIndex(
            (permission) => permission.objectType === type && permission.objectId === element.id,
        );
        if (index !== -1) {
            allPermissions[index] = this.createCustomPermissionElement(type, element.id, permission);
        }
        this.setState({
            permissions: [...allPermissions],
        });
    };

    handleElementDelete = (type, element) => {
        const allPermissions = this.state.permissions;
        const index = allPermissions.findIndex(
            (permission) => permission.objectType === type && permission.objectId === element.id,
        );
        if (index !== -1) {
            allPermissions.splice(index, 1);
        }
        this.setState({
            permissions: [...allPermissions],
        });
    };

    handleUsersAdd = (permission, users) => {
        this.handleElementAdd(TYPE_USER, permission, users);
        this.handleAddUsersDialogClose();
    };

    handleUserPermissionChange = (element, permission) => {
        this.handleElementPermissionChange(TYPE_USER, element, permission);
    };

    handleUserDelete = (user) => {
        this.handleElementDelete(TYPE_USER, user);
    };

    handleUserProfilesAdd = (permission, roles) => {
        this.handleElementAdd(TYPE_ROLE, permission, roles);
        this.handleAddUserProfilesDialogClose();
    };

    handleUserProfilePermissionChange = (element, permission) => {
        this.handleElementPermissionChange(TYPE_ROLE, element, permission);
    };

    handleUserProfileDelete = (role) => {
        this.handleElementDelete(TYPE_ROLE, role);
    };

    handleSave = () => {
        const { objectType, objectId } = this.props;
        const { level, permissions, hasBrowseUsersPermission, hasBrowseUserProfilesPermission } = this.state;

        let perms;
        if (level === LEVEL_CUSTOM) {
            perms = [];
            for (let permission of permissions) {
                if (permission.objectType === TYPE_USER && hasBrowseUsersPermission) {
                    perms.push(permission);
                }
                if (permission.objectType === TYPE_ROLE && hasBrowseUserProfilesPermission) {
                    perms.push(permission);
                }
            }
        } else {
            perms = null;
        }

        this.props.onSave(this.createObjectPermission(objectType, objectId, level, perms));
    };

    handleClose = () => {
        this.props.onClose();
        this.setState({
            permissions: this.props.permission.permissions,
            level: this.props.permission.level,
        });
    };

    render() {
        const { open, t, unavailablePermissions } = this.props;
        const {
            permissions,
            level,
            users,
            roles,
            adminDefaultPermission,
            addUsersDialogOpen,
            addRolesDialogOpen,
            hasBrowseUsersPermission,
            hasBrowseUserProfilesPermission,
        } = this.state;

        const userPermissions = permissions?.filter((permission) => permission.objectType === TYPE_USER) || [];
        const freeUsers = users.filter(
            (user) => !userPermissions.find((userPermission) => userPermission.objectId === user.id),
        );
        const occupiedUsers = users.filter((user) => !freeUsers.includes(user));

        const rolePermissions = permissions?.filter((permission) => permission.objectType === TYPE_ROLE) || [];
        const freeRoles = roles.filter(
            (role) => !rolePermissions.find((rolePermission) => rolePermission.objectId === role.id),
        );
        const occupiedRoles = roles.filter((role) => !freeRoles.includes(role));

        const loading = level !== LEVEL_CUSTOM && hasBrowseUserProfilesPermission && adminDefaultPermission === null;

        return (
            <PureFormDialog
                className="c-sharing-settings-dialog__dialog-container"
                fullWidth
                open={open}
                onClose={this.handleClose}
                title={
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item zeroMinWidth>
                            {t('sharing_setting.modal.title')}
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <LandingLink
                                article="5138922"
                                useTrailingIcon
                                useLeadingIcon
                                style={{ fontSize: 'smaller', marginLeft: '16px' }}
                            >
                                {t('sharing_setting.modal.help')}
                            </LandingLink>
                        </Grid>
                    </Grid>
                }
            >
                {loading && (
                    <div>
                        <Typography>{t('loading')}</Typography>
                    </div>
                )}
                {!loading && (
                    <React.Fragment>
                        <div>
                            <Typography>{t('sharing_setting.modal.level.title')}</Typography>
                        </div>
                        <div>
                            <RadioGroup name="sharingLevel" onChange={this.handleLevelChange} value={level}>
                                <TranslatedLevelRadio level={LEVEL_PRIVATE} />
                                <TranslatedLevelRadio level={LEVEL_PUBLIC} />
                                <TranslatedLevelRadio level={LEVEL_CUSTOM} />
                            </RadioGroup>
                        </div>
                        <div>
                            <Grid
                                container
                                direction="row"
                                className="c-sharing-settings-dialog__settings-common-container"
                            >
                                <TranslatedSettingsContainer
                                    type={TYPE_USER}
                                    title={t('sharing_setting.modal.add_modal.user.button_title')}
                                    onAddDialogOpen={this.handleAddUsersDialogOpen}
                                    elements={occupiedUsers}
                                    permissions={userPermissions}
                                    onElementDelete={this.handleUserDelete}
                                    onElementPermissionChange={this.handleUserPermissionChange}
                                    isCustom={level === LEVEL_CUSTOM}
                                    hasPermission={hasBrowseUsersPermission}
                                    notHasPermissionTooltip={t(
                                        'sharing_setting.modal.permission.no_access.user.tooltip',
                                    )}
                                    unavailablePermissions={unavailablePermissions}
                                    addDialog={
                                        <TranslatedAddDialog
                                            open={addUsersDialogOpen}
                                            onClose={this.handleAddUsersDialogClose}
                                            onSave={this.handleUsersAdd}
                                            title={t('sharing_setting.modal.add_modal.user.dialog_title')}
                                            selectElementTitle={t('sharing_setting.modal.add_modal.user.select_title')}
                                            selectEmptyTitle={t('sharing_setting.modal.add_modal.user.select_empty')}
                                            elements={freeUsers}
                                            type={TYPE_USER}
                                            unavailablePermissions={unavailablePermissions}
                                        />
                                    }
                                />
                                <TranslatedSettingsContainer
                                    type={TYPE_ROLE}
                                    title={t('sharing_setting.modal.add_modal.profile.button_title')}
                                    onAddDialogOpen={this.handleAddUserProfilesDialogOpen}
                                    elements={occupiedRoles}
                                    permissions={rolePermissions}
                                    onElementDelete={this.handleUserProfileDelete}
                                    onElementPermissionChange={this.handleUserProfilePermissionChange}
                                    isCustom={level === LEVEL_CUSTOM}
                                    hasPermission={hasBrowseUserProfilesPermission}
                                    notHasPermissionTooltip={t(
                                        'sharing_setting.modal.permission.no_access.profile.tooltip',
                                    )}
                                    unavailablePermissions={unavailablePermissions}
                                    addDialog={
                                        <TranslatedAddDialog
                                            open={addRolesDialogOpen}
                                            onClose={this.handleAddUserProfilesDialogClose}
                                            onSave={this.handleUserProfilesAdd}
                                            title={t('sharing_setting.modal.add_modal.profile.dialog_title')}
                                            selectElementTitle={t(
                                                'sharing_setting.modal.add_modal.profile.select_title',
                                            )}
                                            selectEmptyTitle={t('sharing_setting.modal.add_modal.profile.select_empty')}
                                            elements={freeRoles}
                                            type={TYPE_ROLE}
                                            unavailablePermissions={unavailablePermissions}
                                        />
                                    }
                                />
                            </Grid>
                        </div>
                    </React.Fragment>
                )}
                <FormActions>
                    <Button
                        color="primary"
                        disabled={loading}
                        onClick={this.handleSave}
                        data-testid="sharing_setting.modal.button.save"
                    >
                        {t('button.save')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

const SettingsContainer = (props) => {
    const {
        permissions,
        elements,
        type,
        onElementDelete,
        onElementPermissionChange,
        addDialog,
        onAddDialogOpen,
        title,
        t,
        isCustom,
        hasPermission,
        notHasPermissionTooltip,
        unavailablePermissions,
    } = props;
    const disabled = !isCustom || !hasPermission;
    return (
        <Grid item xs={12} sm={6} className="c-sharing-settings-dialog__settings-container">
            <Grid
                item
                container
                spacing={1}
                wrap="nowrap"
                direction="row"
                alignItems="center"
                className="c-sharing-settings-dialog__settings-title"
            >
                <Grid item zeroMinWidth>
                    <Typography noWrap component="h3">
                        <b>{title}</b>
                    </Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                    <Tooltip title={isCustom && !hasPermission ? notHasPermissionTooltip : ''}>
                        <span>
                            <AddIconButton small onClick={onAddDialogOpen} disabled={disabled} />
                        </span>
                    </Tooltip>
                    {addDialog}
                </Grid>
            </Grid>
            <Grid item container direction="column" className="c-sharing-settings-dialog__settings-data">
                <div className="c-sharing-settings-dialog__settings-data-container">
                    {isCustom && !hasPermission && <React.Fragment>{notHasPermissionTooltip}</React.Fragment>}
                    {isCustom &&
                        hasPermission &&
                        elements.map((element) => {
                            const elementPermission = permissions.find(
                                (permission) => permission.objectId === element.id,
                            );
                            return (
                                <Grid item container direction={'row'} key={type + '-' + element.id}>
                                    <Grid
                                        item
                                        className="c-sharing-settings-dialog__settings-data-recycle-bin-container"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                onElementDelete(element);
                                            }}
                                            data-testid="sharing_setting.modal.settings.delete"
                                        >
                                            <Icon color={'error'}>delete</Icon>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs zeroMinWidth style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography noWrap>{element.name}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <Select
                                            value={elementPermission?.permission}
                                            onChange={(event) => {
                                                onElementPermissionChange(element, event.target.value);
                                            }}
                                            fullWidth
                                            data-testid="sharing_setting.modal.permission"
                                        >
                                            {PERMISSIONS.filter((permission) => {
                                                if (!unavailablePermissions) {
                                                    return true;
                                                }

                                                return element?.forSharedMap === true ||
                                                    element?.role?.forSharedMap === true
                                                    ? !unavailablePermissions?.includes(permission)
                                                    : true;
                                            }).map((permission) => {
                                                return (
                                                    <MenuItem
                                                        key={'change-permission-' + permission}
                                                        value={permission}
                                                    >
                                                        {t('sharing_setting.modal.permission.' + permission)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </div>
            </Grid>
        </Grid>
    );
};

class AddDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedPermission: PERMISSION_VIEW,
            checkedElements: [],
        };
    }

    handlePermissionChange = (event) => {
        this.setState({
            selectedPermission: parseInt(event.target.value),
        });
    };

    handleToggleElement = (element) => {
        const checkedElements = this.state.checkedElements;
        const index = checkedElements.indexOf(element);
        if (index !== -1) {
            checkedElements.splice(index, 1);
        } else {
            checkedElements.push(element);
        }
        this.setState({
            checkedElements: [...checkedElements],
        });
    };

    handleClose = () => {
        this.props.onClose();
        this.setState({
            selectedPermission: PERMISSION_VIEW,
            checkedElements: [],
        });
    };

    handleSave = () => {
        this.props.onSave(this.state.selectedPermission, this.state.checkedElements);
        this.setState({
            selectedPermission: PERMISSION_VIEW,
            checkedElements: [],
        });
    };

    render() {
        const { open, elements, type, t, title, selectElementTitle, selectEmptyTitle, unavailablePermissions } =
            this.props;
        const { selectedPermission, checkedElements } = this.state;

        let filteredElements = elements;
        if (unavailablePermissions && unavailablePermissions.includes(selectedPermission)) {
            elements.forEach((element) => {
                const isChecked = checkedElements.some((checkedElement) => checkedElement.id === element.id);

                if ((element?.forSharedMap || element?.role?.forSharedMap) && isChecked) {
                    this.handleToggleElement(element);
                }
            });

            filteredElements = elements.filter((element) => !(element?.forSharedMap || element?.role?.forSharedMap));
        }

        return (
            <PureFormDialog open={open} onClose={this.handleClose} title={title} maxWidth="xs" fullWidth>
                <Grid container direction="column">
                    <Grid item>{t('sharing_setting.modal.add_modal.permission.title')}</Grid>
                    <Grid item>
                        <RadioGroup row value={selectedPermission} onChange={this.handlePermissionChange}>
                            {PERMISSIONS.map((permission) => (
                                <FormControlLabel
                                    key={'add-permission-' + permission}
                                    value={permission}
                                    control={<Radio color="primary" />}
                                    label={t('sharing_setting.modal.permission.' + permission)}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                    <Grid item>{selectElementTitle}</Grid>
                    <Grid item container direction="column" className="c-sharing-settings-dialog__select-element">
                        <div className="c-sharing-settings-dialog__select-element-container">
                            {filteredElements.length === 0 && <div>{selectEmptyTitle}</div>}
                            {filteredElements.map((element) => {
                                const checked = checkedElements.find(
                                    (checkedElement) => checkedElement.id === element.id,
                                );
                                return (
                                    <Grid item key={type + element.id}>
                                        <FormControlLabel
                                            margin="dense"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={!!checked}
                                                    onChange={() => this.handleToggleElement(element)}
                                                />
                                            }
                                            label={element.name}
                                        />
                                    </Grid>
                                );
                            })}
                        </div>
                    </Grid>
                </Grid>
                <FormActions>
                    <Button
                        color="primary"
                        onClick={this.handleSave}
                        disabled={elements.length === 0}
                        data-testid="sharing_setting.modal.button.add"
                    >
                        {t('button.add')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

const LevelRadio = (props) => {
    const { level, t } = props;
    const smDisplay = { xs: 'none', sm: 'block' };
    const xsDisplay = { xs: 'inline', sm: 'none' };
    return (
        <FormControlLabel
            value={level}
            control={<Radio color="primary" />}
            label={
                <div className="c-sharing-settings-dialog__level-label-container">
                    <span className="c-sharing-settings-dialog__level-radio-name">
                        <b>{t('sharing_setting.modal.level.' + level)}</b>
                    </span>
                    <Box component="span" display={smDisplay} className="c-sharing-settings-dialog__level-radio-desc">
                        {t('sharing_setting.modal.level.' + level + '.desc')}
                    </Box>
                    <Box component="span" display={xsDisplay}>
                        &nbsp;
                        <Hint TooltipProps={{ className: 'tooltip-question' }}>
                            {t('sharing_setting.modal.level.' + level + '.desc')}
                        </Hint>
                    </Box>
                </div>
            }
        />
    );
};

const TranslatedAddDialog = withTranslation('translations', { withRef: true })(AddDialog);
const TranslatedSettingsContainer = withTranslation('translations', { withRef: true })(SettingsContainer);
const TranslatedLevelRadio = withTranslation('translations', { withRef: true })(LevelRadio);

SharingSettingsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    permission: PropTypes.object,
    objectType: PropTypes.string.isRequired,
    objectId: PropTypes.number,
    unavailablePermissions: PropTypes.array,
};

export default withTranslation('translations', { withRef: true })(SharingSettingsDialog);
