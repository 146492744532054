import React from 'react';
import { Icon, IconButton, TextField, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { withTranslation, WithTranslation } from 'react-i18next';
import ConstantInput from 'components/ConstantInput';
import { CONSTANT_ONLY_FIELD_TYPES } from 'components/Prospecting/ExportForm';
import { IField, ProspectStructure, ProspectStructureConstValue } from 'components/types';
import { GooglePlaceStructureField } from '../GooglePlaceStructure';
import SelectFieldTypeValue from './SelectFieldTypeValue';

interface Props extends WithTranslation {
    error?: ProspectError;
    onChange: (structureItem: ProspectStructure) => void;
    onGetField: (fieldName: string) => IField | null;
    onRemove: (structureItem: ProspectStructure) => void;
    onShowSearch: (structureItem: ProspectStructure) => void;
    prospectFields: {
        [key: string]: GooglePlaceStructureField;
    };
    structureItem: ProspectStructure;
    classes: {
        [key: string]: string;
    };
    currentUserDSId: string | null;
    dataSourceName: string;
    ownerFieldName: string | null;
}

type ProspectError = {
    error: string;
    text: string;
};

type VisibleField = {
    id: string;
    title: string;
};

class ProspectStructureItem extends React.PureComponent<Props> {
    private readonly visibleFields: VisibleField[];

    constructor(props: Props) {
        super(props);

        this.visibleFields = Object.entries(this.props.prospectFields)
            .filter(([_, item]) => !item.notVisible)
            .map(([id, item]) => {
                return { id: id, title: item.title };
            });
    }

    handleProspectFieldChanged = (_: React.ChangeEvent<{}>, field: VisibleField | null) => {
        const structureItem = { ...this.props.structureItem };
        structureItem.prospectFieldName = field?.id;

        this.props.onChange(structureItem);
    };

    handleRemove = () => {
        this.props.onRemove(this.props.structureItem);
    };

    handleChangeConstant = (value: ProspectStructureConstValue) => {
        const structureItem = { ...this.props.structureItem };
        structureItem.const = value;

        this.props.onChange(structureItem);
    };

    handleShowSearch = () => {
        this.props.onShowSearch(this.props.structureItem);
    };

    render() {
        const { error, t, onChange, currentUserDSId, dataSourceName, ownerFieldName } = this.props;
        const item = this.props.structureItem;
        const field = this.props.onGetField(item.fieldName);
        const isOwnerField = ownerFieldName === item.fieldName;
        const currentField = this.visibleFields.find((visibleField) => visibleField.id === item.prospectFieldName);
        const fieldIsDisabled = field
            ? field.lookupData !== null || CONSTANT_ONLY_FIELD_TYPES.includes(field.type)
            : false;

        const tooltipTitle = t('prospecting.export_form.remove.tooltip');
        const tooltipDataSourceIdNotSet = t('prospecting.export_form.not_set_user_data_source_id.tooltip', {
            dataSourceName: dataSourceName,
        });
        let fieldLabel = '';
        if (field) {
            fieldLabel = field.lookupData ? field.title.replace(/\s\((ID)\)$/, '') : field.title;
        }

        return (
            <React.Fragment>
                <tr>
                    <td>
                        {error && error.error && (
                            <Tooltip title={error.text}>
                                <Icon className={this.props.classes.error}>error</Icon>
                            </Tooltip>
                        )}
                        {error && !error.error && (
                            <Tooltip title={error.text}>
                                <Icon className={this.props.classes.warning}>warning</Icon>
                            </Tooltip>
                        )}
                    </td>
                    <td>
                        <span style={{ wordBreak: 'break-all' }}>{fieldLabel}</span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <i className="fal fa-long-arrow-left fa-1x" />
                    </td>
                    <td>
                        <SelectFieldTypeValue
                            item={item}
                            fieldTypeDisabled={fieldIsDisabled}
                            onChange={onChange}
                            isOwnerField={isOwnerField}
                        />
                    </td>
                    <td>
                        <div style={{ maxWidth: 200 }}>
                            {item.type === 'const' ? (
                                <ConstantInput
                                    field={field}
                                    value={item.const || null}
                                    onChange={this.handleChangeConstant}
                                    onShowSearch={this.handleShowSearch}
                                />
                            ) : item.type === 'dsUserId' || (item.type !== 'field' && isOwnerField) ? (
                                <span>
                                    <span>{t('prospecting.export_form.import_user_data_source_id')}</span>
                                </span>
                            ) : (
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={this.visibleFields}
                                        value={currentField || null}
                                        getOptionLabel={(field) => field.title}
                                        onChange={this.handleProspectFieldChanged}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('prospecting.export_form.select_field.placeholder')}
                                                data-testid="prospecting.export_form.select_field"
                                            />
                                        )}
                                    />
                                </FormControl>
                            )}
                        </div>
                    </td>
                    <td>
                        <Tooltip title={tooltipTitle}>
                            <IconButton
                                color="primary"
                                component="span"
                                disabled={item.required}
                                onClick={this.handleRemove}
                                data-testid="prospecting.export_form.remove"
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td colSpan={6} align="right">
                        {item.type === 'dsUserId' && !currentUserDSId && (
                            <div className="prospecting-warning-ds-user-id-block">
                                <Icon className={`${this.props.classes.warning} prospecting-warning-ds-user-id-icon`}>
                                    warning
                                </Icon>
                                <span className="prospecting-warning-ds-user-id-text">
                                    {t('prospecting.export_form.not_set_user_data_source_id', {
                                        dataSourceName: dataSourceName,
                                    })}
                                </span>
                                <Tooltip title={tooltipDataSourceIdNotSet}>
                                    <i className="fas fa-question-circle" />
                                </Tooltip>
                            </div>
                        )}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ProspectStructureItem);
