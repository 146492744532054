import { Capacitor, CapacitorCookies } from '@capacitor/core';
import Api from './Api';
import config from '../params';
import { routes } from '../routes';
import apiRoutes, { reverse } from './apiRoutes';
import { HTTP } from '@ionic-native/http';
import { logDebug, weAreInIframe, weAreInIosNativeApp, weAreInNativeApp } from '../utils';
import i18n from 'i18next';
import { sharedMapStateManager } from '../service/SharedMapStateManager';
import { v4 as uuidv4 } from 'uuid';
import dispatcher from '../service/dispatcher';
import events from '../events';
import { HEADER_X_CLIENT_PLATFORM } from 'service/types';
import WSManager from '../service/WSManager';
import parallelSafeDispatcherFactory from '../service/ParallelSafeDispatcher/ParallelSafeDispatcherFactory';
import qs from 'qs';
import { isBodiless } from './http';

const LS_IMPERSONATE_KEY = 'impersonate';
const LS_REFRESH_TOKEN = 'rt';

export const THIRD_PARTY_COOKIES_ACCESS_STATE = {
    UNDEFINED: 0b000,
    NEED_REQUEST: 0b001,
    ALLOWED: 0b010,
    DENIED: 0b100,
    DENIED_NO_API: 0b101,
    DENIED_BY_USER: 0b110,
    DENIED_BY_COND: 0b111,
};

const debugNetwork = () => {
    return new Promise((resolve, _reject) => {
        if (typeof fetch !== 'function') {
            resolve();
            return;
        }
        fetch('https://google.com', { mode: 'no-cors' })
            .then((response) => {
                console.log('fetch test status', response.status);
            })
            .catch((error) => {
                console.log('fetch test error', error);
            })
            .finally(() => {
                resolve();
            });
    });
};

let accessToken = null;
let ssoRefreshToken = null;

/**
 * @final Не наследоваться от этого класса.
 * @use service/BackendService
 */
class BackendApi extends Api {
    /**
     * if enabled, every access token update will also be pushed to analytics app.
     * @private
     */
    static shareJwtToken = false;

    setSsoRefreshToken(refreshToken) {
        ssoRefreshToken = refreshToken;
        return this.refreshAccessToken().then(() => {
            window.location.href = '/';
        });
    }

    constructor() {
        super();
        this.apiEndpoint = config.apiEndpoint;

        accessToken = null;

        dispatcher.subscribe(
            [events.EVENT_CURRENT_USER_CHANGED, events.WEBSOCKET_IO_SERVER_DISCONNECT],
            this,
            (user) => {
                WSManager.disconnect();
                if (!user) {
                    return;
                }

                // access token contains no information about switched user
                const { id: userId, accountId } = user;
                WSManager.connect(() => ({ accessToken, accountId, userId }));
            },
        );

        dispatcher.subscribe(events.WS_SESSION_TERMINATED, this, ({ refreshTokenSeries, reason }) => {
            if (this.isExplicitLogoutProcessing) {
                return;
            }
            const currentRefreshTokenSeries = this.getRefreshTokenSeriesFromJWTPayload();
            if (currentRefreshTokenSeries !== null && refreshTokenSeries === currentRefreshTokenSeries) {
                this.endSession(reason);
            }
        });

        window.addEventListener('storage', ({ key }) => {
            if (sharedMapStateManager.isSharedMap()) {
                return;
            }
            if (key === LS_IMPERSONATE_KEY) {
                this.gotoLoginPage(this.getUserSsoProviderFromJWTPayload());
            }
        });

        this.refreshAccessTokenInterval = null;
        this.accessTokenExpirationTime = null;

        this.isInitialRefreshTokenCheckingComplete = this.needThirdPartyCookiesAccess;

        this.accessTokenRefreshDispatcher = parallelSafeDispatcherFactory.getDispatcher('refreshAccessToken', () =>
            this.requestApi(apiRoutes.refresh, 'POST'),
        );

        this.needThirdPartyCookiesAccess = weAreInIframe() && !sharedMapStateManager.isSharedMap();
        this.thirdPartyCookiesAccess = THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED;

        //this.thirdPartyCookiesAccess = THIRD_PARTY_COOKIES_ACCESS_STATE.UNDEFINED;
        //this.initThirdPartyCookiesAccess();

        this.isExplicitLogoutProcessing = false;

        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (queryParams.hasOwnProperty('rt')) {
            if (window.opener) {
            } else {
                // в ff не работало потому что этот код срабатывал в модальном окне и устанавливал токен в ls основного окна а не фрейма
                this.setSsoRefreshToken(queryParams['rt']);
            }
        }
    }

    isNeedThirdPartyCookiesAccess() {
        return this.needThirdPartyCookiesAccess;
    }

    getThirdPartyCookiesAccess() {
        return this.thirdPartyCookiesAccess;
    }

    isThirdPartyCookiesAccessDenied() {
        return (
            (this.thirdPartyCookiesAccess & THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED) ===
            THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED
        );
    }

    setThirdPartyCookiesAccess(thirdPartyCookiesAccess) {
        this.thirdPartyCookiesAccess = thirdPartyCookiesAccess;
        dispatcher.dispatch(events.THIRD_PARTY_COOKIES_ACCESS_STATE_CHANGED, thirdPartyCookiesAccess);
    }

    initThirdPartyCookiesAccess() {
        if (this.getRefreshToken() !== null) {
            this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED);
            return;
        }
        if (!document.hasStorageAccess) {
            console.log('Storage API is unavailable.');
            this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED_NO_API);
            return;
        }

        document
            .hasStorageAccess()
            .then((result) => {
                if (result) {
                    console.log('Third party cookies access is provided.');
                    this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.ALLOWED);
                } else {
                    // If we don't have access we must request it, but the request
                    // must come from a UI event.
                    console.log('Third party cookies access is not provided yet.');
                    this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.NEED_REQUEST);
                }
            })
            .catch((error) => {
                this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED);
                console.warn(error);
            });
    }

    requestThirdPartyCookiesAccess() {
        // On UI event, consume the event by requesting access.
        const start = Date.now();
        document
            .requestStorageAccess()
            .then(() => {
                console.log('Got third party cookies access.');
                this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.ALLOWED);
                // Finally, we are allowed! Reload to get the cookie.
                window.location.reload();
            })
            .catch((error) => {
                // If we get here, it means either our page
                // was never loaded as a first party page,
                // or the user clicked 'Don't Allow'.
                // Either way open that now so the user can request
                // from there (or learn more about us).
                const t = Date.now() - start;
                if (t > 500) {
                    // Пользователь отклонил запрос
                    this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED_BY_USER);
                } else {
                    // Запрос отклонен из-за несоблюдения условий
                    this.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED_BY_COND);
                }
                console.warn(error);
            });
    }

    static getClientInstanceId() {
        const suffix = sharedMapStateManager.isSharedMap() ? sharedMapStateManager.getSharedMapHash() : null;
        const key = suffix ? `clientInstanceId:${suffix}` : 'clientInstanceId';
        let clientInstanceId = window.sessionStorage.getItem(key);
        if (!clientInstanceId) {
            clientInstanceId = uuidv4();
            window.sessionStorage.setItem(key, clientInstanceId);
        }
        return clientInstanceId;
    }

    getRefreshToken() {
        return window.localStorage.getItem(LS_REFRESH_TOKEN);
    }

    setRefreshToken(refreshToken) {
        if (!refreshToken) {
            window.localStorage.removeItem(LS_REFRESH_TOKEN);
        } else {
            window.localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
        }
    }

    gotoLoginPage(identityProvider = null, reason = null) {
        console.info('Finish session.', identityProvider);
        // перейти на страницу логина, с сообщением
        if (identityProvider && weAreInIframe()) {
            window.location.replace(reverse(routes.sso, { provider: identityProvider }));

            return;
        }

        window.location.replace(
            reverse(routes.login) + (this.isExplicitLogoutProcessing ? '' : '?session_is_over&reason=' + reason),
        );
    }

    getUserSsoProviderFromJWTPayload() {
        if (!accessToken) {
            return null;
        }
        const payload = JSON.parse(atob(accessToken.split('.')[1]));
        return payload['ssoProvider'] || null;
    }

    getUserIdFromJWTPayload() {
        if (!accessToken) {
            return null;
        }
        const payload = JSON.parse(atob(accessToken.split('.')[1]));
        return payload['id'] || null;
    }

    getUserRoleFromJWTPayload() {
        if (!accessToken) {
            return null;
        }
        const payload = JSON.parse(atob(accessToken.split('.')[1]));
        return payload.roles ? payload.roles[0] : null;
    }

    getRefreshTokenSeriesFromJWTPayload() {
        if (!accessToken) {
            return null;
        }

        const payload = JSON.parse(atob(accessToken.split('.')[1]));

        return payload['rts'] || null;
    }

    isSharedMap() {
        return sharedMapStateManager.isSharedMap();
    }

    async shareJwt() {
        const urls = config.jwtShareUrls || [];
        for (const url of urls) {
            if (weAreInNativeApp()) {
                await this.shareJwtNative(url, accessToken);
            } else {
                await this.shareJwtWeb(url, accessToken);
            }
        }
    }

    async shareJwtNative(url, jwt) {
        if (weAreInIosNativeApp()) {
            this.shareJwtIos(url, jwt);
            return;
        }
        await HTTP.sendRequest(url, {
            method: 'post',
            data: jwt,
            serializer: 'utf8',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
        });
    }

    shareJwtWeb(url, jwt) {
        return new Promise((onLoad, onError) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
            xhr.withCredentials = true;
            xhr.onload = onLoad;
            xhr.onerror = onError;
            xhr.send(jwt);
        });
    }

    shareJwtIos(url, jwt) {
        const urlData = new URL(url);
        CapacitorCookies.setCookie({
            url: urlData.origin,
            key: 'jwt_token',
            value: jwt,
        });
    }

    enableShareJwtToken() {
        BackendApi.shareJwtToken = true;
    }

    disableShareJwtToken() {
        BackendApi.shareJwtToken = false;
    }

    setUserToken(jwt) {
        console.info('Set user token');

        if (jwt === accessToken) {
            return;
        }

        accessToken = jwt;

        if (BackendApi.shareJwtToken) {
            this.shareJwt();
        }

        if (!jwt) {
            console.info('Clear refresh token interval.');

            clearInterval(this.refreshAccessTokenInterval);
            this.refreshAccessTokenInterval = null;
            this.accessTokenExpirationTime = null;
        } else {
            const payload = JSON.parse(atob(accessToken.split('.')[1]));

            this.accessTokenExpirationTime = new Date().getTime() / 1000 + payload['exp'] - payload['iat'] - 15;

            const accessTokenRefreshingPeriod = payload['exp'] - payload['iat'] - 30;
            if (this.refreshAccessTokenInterval === null) {
                console.info('Periodic token renewal has been started', accessTokenRefreshingPeriod);

                this.refreshAccessTokenInterval = setInterval(() => {
                    this.refreshAccessToken().catch((error) => {
                        console.warn('Unable to refresh access token');
                        if (error.code === 401) {
                            this.endSession();
                        }
                    });
                }, accessTokenRefreshingPeriod * 1000);
            }
        }
    }

    /**
     * todo stop tracking and schedule
     * @see UserManager.logout
     *
     * todo send stop tracking and stop schedule commands to device forced to logout (by access token series) while in a deep sleep, skip locations with timestamp greater than logout datetime
     * @link https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/httpevent.html#controlling-the-sdk-with-http-responses-rpc
     */
    endSession(reason = null) {
        const previousUserId = this.getUserIdFromJWTPayload();
        const previousUserSsoProvider = this.getUserSsoProviderFromJWTPayload();

        this.setRefreshToken(null);
        this.setUserToken(null);
        this.switchBackUser();

        if (previousUserId) {
            this.gotoLoginPage(previousUserSsoProvider, reason);
        }
    }

    // Исключить использование этого метода
    getUserToken() {
        return accessToken;
    }

    getImpersonateData() {
        if (!window.localStorage.getItem(LS_IMPERSONATE_KEY)) {
            return null;
        }

        return JSON.parse(window.localStorage.getItem(LS_IMPERSONATE_KEY));
    }

    getImpersonateUserId() {
        if (this.isSharedMap()) {
            return null;
        }

        const impersonate = this.getImpersonateData();

        return impersonate ? impersonate.id : null;
    }

    getImpersonateMode() {
        if (this.isSharedMap()) {
            return null;
        }

        const impersonate = this.getImpersonateData();

        return impersonate ? impersonate.mode : null;
    }

    /**
     * todo do not track switched user
     */
    switchUser(id, mode) {
        window.localStorage.setItem(LS_IMPERSONATE_KEY, JSON.stringify({ id, mode }));
        this.refreshAccessToken();
    }

    /**
     * todo restore tracking and schedule states
     */
    switchBackUser() {
        window.localStorage.removeItem(LS_IMPERSONATE_KEY);
        this.refreshAccessToken();
    }

    static createError(error, extraData) {
        const emptyError = {
            code: null,
            message: null,
            errors: {},
            details: new Map(),
        };
        const err = { ...emptyError, ...error };
        if (extraData) {
            err.extraData = extraData;
        }
        return err;
    }

    isError(obj) {
        return (
            obj &&
            obj.code !== undefined &&
            obj.message !== undefined &&
            obj.errors !== undefined &&
            obj.details !== undefined
        );
    }

    static createSkipableError(error, extraData) {
        const err = BackendApi.createError(error, extraData);
        err.skipSentry = true;
        return err;
    }

    static getError(xhr) {
        try {
            const result = JSON.parse(xhr.response);
            const details = result.details ? new Map(Object.entries(result.details)) : new Map();
            return this.createError({
                url: xhr.responseURL,
                code: xhr.status,
                message: result.message,
                exception: result.exception,
                errors: result.errors || {},
                trace: result.trace || null,
                details: details,
                status: xhr.status,
                responseText: typeof xhr.responseText === 'string' ? xhr.responseText.substring(0, 512) : 'N/A',
            });
        } catch (e) {
            return this.createError({
                url: xhr.responseURL,
                code: 500,
                message: i18n.t('http.errors.malformed_response'),
                status: xhr.status,
                responseText: typeof xhr.responseText === 'string' ? xhr.responseText.substring(0, 512) : 'N/A',
                serializationError: e.toString(),
            });
        }
    }

    /**
     * todo do not request tracking token if already configured one in available, valid and is the same series as main token (if available) or user is not available
     */
    requestTrackingTokens() {
        return this.requestApi(reverse(apiRoutes.refresh) + '?tracking', 'POST');
    }

    /**
     * todo disconnect expired tokens in WS app
     */
    refreshAccessToken() {
        if (this.isSharedMap()) {
            return this.requestApi(apiRoutes.hey, 'GET');
        }

        return this.accessTokenRefreshDispatcher.execute();
    }

    doWeNeedToRefreshAccessToken() {
        if (!this.isInitialRefreshTokenCheckingComplete) {
            return true;
        }
        if (!accessToken) {
            return false;
        }

        if (this.accessTokenExpirationTime && this.accessTokenExpirationTime < new Date().getTime() / 1000) {
            console.log('Access token is expired.');
            return true;
        }

        return false;
    }

    createPromise(url, method, postData = null) {
        if (this.doWeNeedToRefreshAccessToken() && !this.isRefreshTokenURL(url) && !this.isSharedMapHeyURL(url)) {
            return this.refreshAccessToken()
                .then(() => {
                    this.isInitialRefreshTokenCheckingComplete = true;
                    return this._createPromise(url, method, postData);
                })
                .catch((error) => {
                    if (this.isInitialRefreshTokenCheckingComplete) {
                        if (error.code === 401) {
                            this.endSession();
                        }
                        throw error;
                    }
                    this.isInitialRefreshTokenCheckingComplete = true;
                    return this._createPromise(url, method, postData);
                });
        }

        return this._createPromise(url, method, postData);
    }

    shouldWeUseLocalStorageForRT() {
        return (this.needThirdPartyCookiesAccess && this.isThirdPartyCookiesAccessDenied()) || weAreInNativeApp();
    }

    appendIframeQueryParameter(url) {
        if (this.shouldWeUseLocalStorageForRT()) {
            const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            if (queryParams.hasOwnProperty('iframe')) {
                return url;
            }
            url += url.indexOf('?') === -1 ? '?' : '&';
            url += 'iframe';
        }

        return url;
    }

    _createPromise(url, method, postData = null) {
        if (this.isLoginURL(url)) {
            url = this.appendIframeQueryParameter(url);
        }
        if (this.isRefreshTokenURL(url) || this.isLogoutURL(url)) {
            url = this.appendIframeQueryParameter(url);

            let refreshToken;
            if (ssoRefreshToken) {
                refreshToken = ssoRefreshToken;
                ssoRefreshToken = null;
            } else if (this.shouldWeUseLocalStorageForRT()) {
                refreshToken = this.getRefreshToken();
            }
            if (refreshToken) {
                if (postData === null) {
                    postData = {};
                }
                postData['refreshToken'] = refreshToken;
            }

            if (this.isLogoutURL(url)) {
                this.isExplicitLogoutProcessing = true;
            }
        }

        let promise = weAreInNativeApp()
            ? this.createPromiseNative(url, method, postData)
            : this.createPromiseWeb(url, method, postData);

        if (this.isSharedMapHeyURL(url) || this.isLoginURL(url) || this.isRefreshTokenURL(url)) {
            return promise
                .then((response) => {
                    if (new URL(url, config.apiEndpoint).searchParams.has('tracking')) {
                        return response;
                    }

                    // Запоминаем access-token. Refresh-token передается в cookie.
                    // аутентификация shared maps происходит по hey и не использует refresh-token
                    const previousUserId = this.getUserIdFromJWTPayload();

                    this.setUserToken(response['accessToken']);
                    if (this.shouldWeUseLocalStorageForRT() && response.hasOwnProperty('refreshToken')) {
                        this.setRefreshToken(response['refreshToken']);
                    }

                    const currentUserId = this.getUserIdFromJWTPayload();

                    if (previousUserId && currentUserId !== previousUserId) {
                        // Возврат на страницу логина, где будет автоматически получен текущий пользователь.
                        this.gotoLoginPage();
                    }

                    return response;
                })
                .catch((e) => {
                    console.warn('Access-token is not obtained.');
                    throw e;
                });
        }

        if (this.isResetPasswordURL(url) && method === 'POST' && postData && postData.hasOwnProperty('password')) {
            return promise.then(() => {
                return this.refreshAccessToken();
            });
        }

        if (this.isLogoutURL(url)) {
            promise = promise.then((response) => {
                this.endSession();
                this.isExplicitLogoutProcessing = false;
                return response;
            });
        }

        let isAnAttemptToRefreshToken = false;
        let isTokenRefreshed = false;
        let originalError;

        return promise
            .catch((error) => {
                if (error.code === 401) {
                    isAnAttemptToRefreshToken = true;
                    originalError = error;

                    return this.refreshAccessToken()
                        .then(() => {
                            isTokenRefreshed = true;
                        })
                        .catch((error) => {
                            console.warn('Unable to refresh access token');
                            if (error.code === 401) {
                                this.endSession();
                            }
                        });
                }

                throw error;
            })
            .then((response) => {
                if (isAnAttemptToRefreshToken && isTokenRefreshed) {
                    // Удалось обновить токен.
                    return this._createPromise(url, method, postData);
                }

                return response;
            })
            .catch((error) => {
                if (isAnAttemptToRefreshToken) {
                    // Не удалось обновить токен.
                    throw originalError;
                }
                throw error;
            });
    }

    doesUrlMatchWithPath(url, path) {
        return new RegExp('^' + path + '([?#]|$)').test(url);
    }

    isLoginURL(url) {
        return this.doesUrlMatchWithPath(url, reverse(apiRoutes.login));
    }

    isRefreshTokenURL(url) {
        return this.doesUrlMatchWithPath(url, reverse(apiRoutes.refresh));
    }

    isSharedMapHeyURL(url) {
        return this.isSharedMap() && this.doesUrlMatchWithPath(url, reverse(apiRoutes.hey));
    }

    isLogoutURL(url) {
        return this.doesUrlMatchWithPath(url, reverse(apiRoutes.logout));
    }

    isResetPasswordURL(url) {
        return this.doesUrlMatchWithPath(url, reverse(apiRoutes.resetPassword));
    }

    createPromiseNative(url, method, postData = null) {
        return new Promise((resolve, reject) => {
            const headers = { 'Access-Control-Allow-Credentials': 'true' };

            if (sharedMapStateManager.getSharedMapId()) {
                headers['X-SHARED-MAP-ID'] = sharedMapStateManager.getSharedMapId();
            } else {
                const jwt = this.getUserToken();
                if (jwt) {
                    headers['Authorization'] = 'Bearer ' + jwt;
                }
                const impersonateData = this.getImpersonateData();
                if (impersonateData) {
                    const impersonateMode = impersonateData.mode;
                    const impersonateUserId = impersonateData.id;
                    headers['x-switch-user'] = impersonateMode
                        ? impersonateUserId + '.' + impersonateMode
                        : impersonateUserId;
                }
            }

            headers['x-client-instance-id'] = BackendApi.getClientInstanceId();
            headers[HEADER_X_CLIENT_PLATFORM] = Capacitor.getPlatform();

            const options = {
                method: method.toLowerCase(),
                data: postData || [],
                serializer: 'json',
                headers: headers,
                timeout: 120000,
            };

            /**
             * Native serializer shuffles json keys in unpredictable order but in some strange cases we rely on them
             * on the backend. That's why we serialize json here to be consistent with web fetcher.
             * @see https://mapsly.atlassian.net/browse/MD-4863
             */
            if (['POST', 'PUT', 'PATCH'].includes(method)) {
                try {
                    options.data = postData ? JSON.stringify(postData) : '[]';
                    options.serializer = 'utf8';
                    headers['content-type'] = 'application/json';
                } catch (error) {
                    console.error(error);
                }
            }

            return HTTP.sendRequest(this.apiEndpoint + url, options)
                .then((response) => {
                    logDebug('Http response received', response);

                    if (![200, 204].includes(response.status)) {
                        console.error('Request ended with error, code:' + response.status);
                        reject(
                            this.constructor.createError({
                                code: response.status,
                                message: response.error ? response.error : i18n.t('http.errors.connection_error'),
                            }),
                        );
                    }

                    try {
                        const result = response.data ? JSON.parse(response.data) : response.data;
                        resolve(result);
                    } catch (e) {
                        console.error('Http request success, response fail');
                        reject(
                            this.constructor.createError(
                                {
                                    code: 500,
                                    message: i18n.t('http.errors.malformed_response'),
                                },
                                response,
                            ),
                        );
                    }
                })
                .catch((response) => {
                    console.error('Http request failed');

                    if (
                        /Host could not be resolved/.test(response.error) ||
                        /device maybe offline/.test(response.error)
                    ) {
                        dispatcher.dispatch(events.REQUEST_FAILED_DUE_TO_INTERNET_CONNECTION);
                    }

                    const error = this.parseError(response.error);
                    const details = error.details ? new Map(Object.entries(error.details)) : new Map();
                    reject(
                        this.constructor.createError({
                            code: response.status,
                            message: error.message,
                            exception: error.exception,
                            errors: error.errors || {},
                            trace: error.trace || null,
                            details: details,
                        }),
                    );
                });
        });
    }

    parseError(error) {
        if (!error) {
            return { message: '' };
        }

        try {
            return JSON.parse(error);
        } catch (e) {
            return { message: error };
        }
    }

    createPromiseWeb(url, method, postData = null) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 120000;
            const start = new Date().getTime();
            const states = [];
            let debugTimeout = null;
            const requestId = start.toString() + new Date().getUTCMilliseconds();
            let debugStep = 0;
            const debugTimeoutMS = 5000;
            // eslint-disable-next-line
            const debugLog = () => {
                console.log(requestId, `debug timeout occured ${++debugStep}`, url, method, JSON.stringify(postData));
                debugTimeout = setTimeout(debugLog, debugTimeoutMS);
            };
            // debugTimeout = setTimeout(debugLog, debugTimeoutMS);

            xhr.onload = () => {
                if (debugTimeout) {
                    clearTimeout(debugTimeout);
                    debugTimeout = null;
                }

                if ([200, 204].includes(xhr.status)) {
                    try {
                        const result = xhr.response ? JSON.parse(xhr.response) : xhr.response;
                        resolve(result);
                    } catch (e) {
                        reject(
                            this.constructor.createError({
                                url: xhr.responseURL,
                                code: 500,
                                message: i18n.t('http.errors.malformed_response'),
                            }),
                        );
                    }
                } else {
                    console.error('Request ended with error, code:' + xhr.status);
                    reject(this.constructor.getError(xhr));
                }
            };
            xhr.onerror = (e) => {
                console.error('Request ended with error, code:' + xhr.status);
                if (debugTimeout) {
                    clearTimeout(debugTimeout);
                    debugTimeout = null;
                }
                const end = new Date().getTime();
                console.log('onerror', e);
                debugNetwork().then(() => {
                    const timeDiff = end - start;
                    const createError =
                        timeDiff > 1000 * 120 ? this.constructor.createSkipableError : this.constructor.createError;
                    reject(
                        createError(
                            {
                                message: i18n.t('http.errors.connection_error'),
                            },
                            {
                                errorAfterMilliseconds: timeDiff,
                                states: JSON.stringify(states),
                                url: url,
                                method: method,
                                postData: JSON.stringify(postData || []),
                                error: JSON.stringify(e),
                            },
                        ),
                    );
                });
            };
            xhr.ontimeout = (e) => {
                if (debugTimeout) {
                    clearTimeout(debugTimeout);
                    debugTimeout = null;
                }
                const end = new Date().getTime();
                console.log('ontimeout', e);
                debugNetwork().then(() => {
                    const timeDiff = end - start;
                    const createError =
                        timeDiff > 1000 * 120 ? this.constructor.createSkipableError : this.constructor.createError;
                    reject(
                        createError(
                            {
                                message: i18n.t('http.errors.connection_timeout'),
                            },
                            {
                                timeoutAfterMilliseconds: end - start,
                                states: JSON.stringify(states),
                                url: url,
                                method: method,
                                postData: JSON.stringify(postData || []),
                            },
                        ),
                    );
                });
            };
            xhr.onreadystatechange = () => {
                const now = new Date().getTime();
                states.push({
                    readyState: xhr.readyState,
                    status: xhr.status,
                    statusText: xhr.statusText,
                    timeAfterStartMilliseconds: now - start,
                });
            };

            xhr.withCredentials = true;

            xhr.open(method, this.apiEndpoint + url);
            //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

            if (sharedMapStateManager.getSharedMapId()) {
                xhr.setRequestHeader('X-SHARED-MAP-ID', sharedMapStateManager.getSharedMapId());
            } else {
                const jwt = this.getUserToken();
                if (jwt) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + jwt);
                }
                const impersonateData = this.getImpersonateData();
                if (impersonateData) {
                    const impersonateMode = impersonateData.mode;
                    const impersonateUserId = impersonateData.id;
                    xhr.setRequestHeader(
                        'x-switch-user',
                        impersonateMode ? impersonateUserId + '.' + impersonateMode : impersonateUserId,
                    );
                }
            }

            xhr.setRequestHeader('x-client-instance-id', BackendApi.getClientInstanceId());
            xhr.setRequestHeader(HEADER_X_CLIENT_PLATFORM, Capacitor.getPlatform());

            if (isBodiless(method)) {
                xhr.send(null);
            } else {
                xhr.send(JSON.stringify(postData || []));
            }
        });
    }

    requestApi(url, method, data) {
        return super.requestApi(url, method, isBodiless(method) ? data : [], !isBodiless(method) ? data : []);
    }

    getApiUrl(url, params) {
        if (params) {
            if (url.indexOf('?') === -1) {
                url += '?';
            } else {
                url += '&';
            }
            url += this.constructor.serialize(params);
        }

        return this.apiEndpoint + url;
    }
}

const backend = new BackendApi();
export default backend;
export { BackendApi };
