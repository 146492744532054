import { UserData } from '../../../service/types';
import { CallContext } from '../../utils/CallContext';

export enum ScheduleFrequency {
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Custom = 'custom',
}

export enum DayOfWeek {
    Mon = 'mon',
    Tue = 'tue',
    Wed = 'wed',
    Thu = 'thu',
    Fri = 'fri',
    Sat = 'sat',
    Sun = 'sun',
}

export interface EmptySchedule {
    [index: string]: any;
    id: number | null;
    namespace: string;
    apiName: null;
    callContext: CallContext;
    name: string | null;
    isActive: boolean;
    disabled: boolean;
    disabledEntities: string[];
    disabledFields: string[];
    startDateTime: string | null;
    frequency: ScheduleFrequency | null;
    cronExpression: string | null;
    daysOfWeek: DayOfWeek[] | null;
    groups: any[];
    updatedBy: UserData | null;
}

export interface Schedule extends EmptySchedule {
    name: string;
    isActive: boolean;
    startDateTime: string;
    frequency: ScheduleFrequency;
}
