import React from 'react';
import { LOCATIONS } from 'service/WorkflowButtonManager';
import { PointActionCrmLink, ViewRecord } from 'components/types';
import PointActions from 'components/PointActions';
import AutofitPointActions from 'components/PointActions/AutofitPointActions';
import { DialogActions } from '@material-ui/core';

interface Props {
    onFindOnMap: () => void;
    onCloseView: () => void;
    onMovePoint: () => void;
    onOpenChangeHistory?: () => void;
    crmLink: PointActionCrmLink;
    record: ViewRecord;
    className: string;
}

interface State {
    maxButtons: number;
}

class RecordViewPointActions extends React.PureComponent<Props, State> {
    private readonly containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            maxButtons: 5,
        };

        this.containerRef = React.createRef();
    }

    render() {
        const { crmLink, record, className, onMovePoint, onCloseView, onFindOnMap, onOpenChangeHistory } = this.props;

        return (
            <DialogActions disableSpacing id={'points'} ref={this.containerRef} className={className}>
                <AutofitPointActions
                    container={this.containerRef}
                    itemWidth={95}
                    minItemWidth={59}
                    minItemCount={5}
                    onChange={this.handleChange}
                />
                <PointActions
                    maxButtons={this.state.maxButtons}
                    crmLink={crmLink}
                    point={record}
                    onCloseView={onCloseView}
                    onFindOnMap={onFindOnMap}
                    onMovePoint={onMovePoint}
                    onOpenChangeHistory={onOpenChangeHistory}
                    workflowButtonLocation={LOCATIONS.RECORD_VIEW}
                />
            </DialogActions>
        );
    }

    private handleChange = (maxButtons: number) => {
        this.setState({ maxButtons });
    };
}

export default RecordViewPointActions;
