import React from 'react';
import { userManager } from 'service/UserManager';
import { withTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { FormActions, FormAlert } from '../PureFormDialog/Form';
import LoadingButton from '../LoadingButton';

class CurrentPasswordForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: '',
            error: this.props.error || null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                error: this.props.error || null,
            });
        }
    }

    handleCurrentPasswordChange = (event) => {
        this.setState({
            currentPassword: event.target.value,
            error: null,
        });
    };

    handleCloseAlert = () => {
        this.setState({
            error: null,
        });
    };

    handleSave = () => {
        this.handleCloseAlert();
        this.props.onSave(this.state.currentPassword);
    };

    render() {
        const { currentPassword, error } = this.state;
        const { t } = this.props;

        const switchedRole = userManager.isSwitched() ? ` (${userManager.getSwitchedUserRole()})` : '';

        return (
            <>
                {error && <FormAlert onClose={this.handleCloseAlert}>{error}</FormAlert>}

                <PasswordField
                    label={t('user_form.form.current_password', { switchedRole })}
                    data-testid="user_form.form.current_password"
                    fullWidth
                    margin="dense"
                    name="password"
                    value={currentPassword}
                    helperText={error || ''}
                    error={!!error}
                    InputProps={{ disableUnderline: false }}
                    onChange={this.handleCurrentPasswordChange}
                />
                <FormActions>
                    <LoadingButton
                        color="primary"
                        name="submit"
                        onClick={this.handleSave}
                        loading={this.props.loading}
                        data-testid="current_password_form.button.ok"
                    >
                        {t('button.ok')}
                    </LoadingButton>
                </FormActions>
            </>
        );
    }
}

export default withTranslation('translations', { withRef: true })(CurrentPasswordForm);
