import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import i18n from '../../locales/i18n';
import Alert from '../Alert';
import Backdrop from '../Backdrop';
import { ButtonWithOverflow } from '../ButtonWithOverflow';
import LoadingButton from '../LoadingButton';

const t = i18n.t.bind(i18n);

/**
 * @deprecated
 * Please use the PureFormDialog instead.
 */
class FormDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errorMessage: null,
            noticeMessage: null,
            warningMessage: null,
            refContent: React.createRef(),
        };

        this.form = React.createRef();
        this.content = React.createRef();
    }

    componentDidMount() {
        this.setState({ refContent: this.content });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.content.current !== prevState.refContent && prevState.refContent.current === null) {
            this.forceUpdate();
        }
    }

    handleSubmit = (name) => {
        this.setState({
            loading: true,
            errorMessage: null,
        });
        this.form.current.submit(name);
    };

    handleSubmitError = (message = null) => {
        this.setState({
            errorMessage: message,
            loading: false,
        });
        this.content.current && this.content.current.scrollTo(0, 0);
    };

    handleSubmitSuccess = (...args) => {
        this.setState({
            loading: false,
        });
        if (this.props.onSave) {
            this.props.onSave(...args);
        }
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    handleAlertClose = () => {
        this.setState({
            errorMessage: null,
        });
        this.form.current.quiet && this.form.current.quiet();
    };

    handlePreloadInit = () => {
        this.setState({
            loading: true,
        });
    };

    handlePreloadSuccess = () => {
        this.setState({
            loading: false,
        });
    };

    handlePreloadError = (errorMessage) => {
        this.setState({
            loading: false,
            errorMessage,
        });
        this.content.current && this.content.current.scrollTo(0, 0);
    };

    handlePreloadWarning = (noticeMessage) => {
        this.setState({
            noticeMessage,
        });
        this.content.current && this.content.current.scrollTo(0, 0);
    };

    handleShowWarningMessage = (warningMessage) => {
        this.setState({
            warningMessage,
        });
    };

    keyPressed = (event) => {
        if (this.props.saveByPressingEnter && event.key === 'Enter') {
            this.handleSubmit('submit');
        }
    };

    render() {
        const {
            t,
            tReady,
            i18n,
            title,
            onSave,
            onCancel,
            dialogClassName,
            contentClassName,
            saveButtonTitle,
            cancelButtonTitle,
            cancelButtonColor,
            persistentWarning,
            saveByPressingEnter,
            saveButtonDisabled,
            actionsDisabled,
            showSaveButton,
            showCancelButton,
            fullScreen,
            ...rest
        } = this.props;
        if (rest.disableEnforceFocus === undefined) {
            rest.disableEnforceFocus = true;
        }
        if (rest.disableBackdropClick === undefined) {
            rest.disableBackdropClick = true;
        }
        const className = `c-form-dialog ${dialogClassName || ''}`;
        const contentClassesName = `c-form-dialog__content ${contentClassName || ''}`;
        return (
            <Dialog
                className={className}
                open
                fullWidth
                maxWidth="md"
                scroll="body"
                {...rest}
                onClose={onCancel}
                onKeyPress={this.keyPressed}
                fullScreen={fullScreen}
                //disableEnforceFocus
                //disableBackdropClick
            >
                <DialogTitle className="c-form-dialog__title">
                    {title}
                    <IconButton
                        color="default"
                        component="span"
                        onClick={this.props.onCancel}
                        className="c-form-dialog__close"
                        data-testid="form_dialog.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                {persistentWarning !== undefined && (
                    <Alert type="warning" style={{ margin: 0, fontSize: 'inherit', whiteSpace: 'pre-line' }}>
                        {persistentWarning}
                    </Alert>
                )}
                <DialogContent className={contentClassesName} ref={this.content}>
                    {this.state.errorMessage !== null && (
                        <Alert canClose onClose={this.handleAlertClose}>
                            {this.state.errorMessage}
                        </Alert>
                    )}
                    {this.state.noticeMessage !== null && (
                        <Alert
                            canClose
                            onClose={this.handleAlertClose}
                            type="warning"
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {this.state.noticeMessage}
                        </Alert>
                    )}
                    <Backdrop loading={this.state.loading}>
                        {React.Children.map(this.props.children, (child) =>
                            React.cloneElement(child, this.getPropsToInject()),
                        )}
                    </Backdrop>
                </DialogContent>
                {this.getActions()}
            </Dialog>
        );
    }

    getPropsToInject() {
        return {
            onSubmitSuccess: this.handleSubmitSuccess,
            onSubmitError: this.handleSubmitError,
            onPreloadInit: this.handlePreloadInit,
            onPreloadWarning: this.handlePreloadWarning,
            onPreloadSuccess: this.handlePreloadSuccess,
            onPreloadError: this.handlePreloadError,
            onShowWarningMessage: this.handleShowWarningMessage,
            ref: this.form,
            refContent: this.state.refContent,
        };
    }

    getCancelButton() {
        const buttonColor = this.props.cancelButtonColor ? this.props.cancelButtonColor : 'primary';

        return (
            this.props.showCancelButton && (
                <ButtonWithOverflow
                    color={buttonColor}
                    onClick={this.handleCancel}
                    data-testid="form_dialog.button.cancel"
                >
                    {this.props.cancelButtonTitle ? this.props.cancelButtonTitle : t('button.cancel')}
                </ButtonWithOverflow>
            )
        );
    }

    getSaveButton() {
        return (
            this.props.showSaveButton && (
                <LoadingButton
                    color="primary"
                    name="submit"
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                    disabled={this.props.saveButtonDisabled}
                    data-testid="form_dialog.button.save"
                >
                    {this.props.saveButtonTitle ? this.props.saveButtonTitle : t('button.save')}
                </LoadingButton>
            )
        );
    }

    getActions() {
        const left = this.props.blocks?.actionLeftBlock ?? '';
        const right = this.props.blocks?.actionRightBlock ?? '';
        return (
            <DialogActions className={this.props.actionsDisabled ? 'c-form-dialog__actions_disabled' : undefined}>
                {this.state.warningMessage && (
                    <div className="warning-strip-message">
                        <i className="fas fa-exclamation-triangle" aria-hidden="true" />
                        {this.state.warningMessage}
                    </div>
                )}
                {left}
                {this.getCancelButton()}
                {this.getSaveButton()}
                {right}
            </DialogActions>
        );
    }
}

FormDialog.propTypes = {
    title: PropTypes.node.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    saveButtonTitle: PropTypes.string,
    cancelButtonTitle: PropTypes.string,
    cancelButtonColor: PropTypes.string,
    persistentWarning: PropTypes.string,
    blocks: PropTypes.object,
    saveByPressingEnter: PropTypes.bool,
    saveButtonDisabled: PropTypes.bool,
    actionsDisabled: PropTypes.bool,
    showSaveButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
};

FormDialog.defaultProps = {
    showSaveButton: true,
    showCancelButton: true,
    saveButtonTitle: null,
    cancelButtonTitle: null,
    cancelButtonColor: null,
    saveByPressingEnter: false,
    saveButtonDisabled: false,
    actionsDisabled: false,
};

export default FormDialog;
