import events from '../events';
import dispatcher from '../service/dispatcher';
import { logDebug, weAreInIosNativeApp, weAreInNativeApp } from '../utils';
import BgGeoManager from './BgGeo/BgGeoManager';
import i18n from 'i18next';
import { App as AppPlugin } from '@capacitor/app';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { isValid, parse } from 'date-fns';
import BackendService from 'api/BackendService';

export const START_WATCH = {
    DONT_START: 'no',
    IF_ACCURATE: 'accurate',
    IF_ANY: 'any',
};

export const PROCESS_TRIGGER = {
    DONT_PROCESS: 'no',
    VIEWPORT: 'viewport',
    BASEPOINT: 'basepoint',
};

const MAXIMUM_AGE = 60000;

const getLocation = () => {
    return new Promise((resolve, reject) => {
        if (weAreInNativeApp()) {
            return BgGeoManager.getCurrentLocation()
                .then((position) => {
                    logDebug('got location from device', position);
                    const { latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed } = position;
                    resolve({ latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed });
                })
                .catch(() => {
                    reject(i18n.t('geolocation.common_error'));
                });
        }

        if (!window.navigator.geolocation) {
            reject(i18n.t('geolocation.no_browser_support'));
            return;
        }

        window.navigator.geolocation.getCurrentPosition(
            (position) => {
                logDebug('got location from browser', position);
                const { latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed } = position.coords;
                resolve({ latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed });
            },
            (error) => {
                logDebug('geolocation error', error);
                reject(error.message);
            },
            {
                maximumAge: MAXIMUM_AGE,
                enableHighAccuracy: true,
                timeout: 30000,
            },
        );
    });
};

const isGoodAccuracy = ({ accuracy, altitude, altitudeAccuracy, heading, speed }) =>
    accuracy < 85 || altitude || altitudeAccuracy !== null || heading !== null || speed !== null;

class GeoLocationManager extends BackendService {
    constructor() {
        super();
        this.locationProcessed = false;
        this.locationProcessing = 0;
        this.watchId = null;
        this.needToWatch = false;

        if (weAreInNativeApp()) {
            /**
             * todo DO NOT start watching position if app is launched by schedule in background
             */
            AppPlugin.addListener('appStateChange', (state) => {
                if (state.isActive && this.needToWatch) {
                    this.startWatchingPosition();
                }
                if (!state.isActive) {
                    this.stopWatchingPosition();
                }
            });
        }
    }

    reset() {
        logDebug('GeoLocationManager: reset');

        this.locationProcessed = false;

        this.needToWatch = false;

        this.clearWatcher();
    }

    ignoreLocation() {
        this.locationProcessed = true;
        dispatcher.dispatch(events.GEO_LOCATION_UPDATED, { lat: null, lng: null, setBasePoint: true });
    }

    setLocationProcessed() {
        this.locationProcessed = true;
    }

    startWatchingPosition() {
        logDebug('GeoLocationManager: startWatchingPosition');

        /**
         * todo remove
         * @see BgGeoManager.initBasicHandlers
         */
        if (weAreInIosNativeApp()) {
            // on ios we immediately get location to show blue circle asap
            BgGeoManager.getCurrentLocation().then((position) => {
                /**
                 * always true
                 */
                if (!position || !position.coords) {
                    return;
                }
                dispatcher.dispatch(events.GEO_POSITION_UPDATED, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                    isFairlyAccurate: isGoodAccuracy(position.coords),
                });
            });
        }

        const geolocationService = weAreInNativeApp()
            ? BgGeoManager.getBackgroundGeolocationService()
            : window.navigator.geolocation;
        if (this.watchId !== null || !geolocationService) {
            return;
        }
        this.stopWatchingPosition();

        this.needToWatch = true; // for native app relaunch clause

        let options = {
            maximumAge: MAXIMUM_AGE,
            enableHighAccuracy: true,
        };
        if (weAreInNativeApp()) {
            options = {
                persist: false,
                interval: 15000,
            };
        }

        this.watchId = geolocationService.watchPosition(
            (position) => {
                logDebug('GeoLocationManager: got location from watcher', position);
                if (!position || !position.coords) {
                    return;
                }
                dispatcher.dispatch(events.GEO_POSITION_UPDATED, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                    isFairlyAccurate: isGoodAccuracy(position.coords),
                });
            },
            (error) => {
                logDebug('GeoLocationManager: fail location from watcher', error);
            },
            options,
        );
    }

    stopWatchingPosition() {
        logDebug('GeoLocationManager: stopWatchingPosition');

        this.clearWatcher();

        dispatcher.dispatch(events.GEO_POSITION_UPDATED, null);
    }

    clearWatcher() {
        const geolocationService = weAreInNativeApp()
            ? BgGeoManager.getBackgroundGeolocationService()
            : window.navigator.geolocation;
        if (!geolocationService) {
            return;
        }

        if (!weAreInNativeApp() && window.navigator.geolocation && this.watchId !== null) {
            geolocationService.clearWatch(this.watchId);
        }
        if (weAreInNativeApp()) {
            logDebug('GeoLocationManager: native stopWatchPosition');
            geolocationService.stopWatchPosition();
        }

        this.watchId = null;
    }

    getLocationFull() {
        this.locationProcessing++;
        dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
        return getLocation()
            .then((position) => {
                this.locationProcessing--;
                dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
                const { latitude: lat, longitude: lng, ...rest } = position;
                return { lat, lng, ...rest };
            })
            .catch((error) => {
                this.locationProcessing--;
                dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
                console.error(error);
                return {
                    lat: null,
                    lng: null,
                    accuracy: null,
                    altitude: null,
                    altitudeAccuracy: null,
                    heading: null,
                    speed: null,
                };
            });
    }

    getLocation(location = PROCESS_TRIGGER.DONT_PROCESS, watch = START_WATCH.DONT_START, zoom = undefined) {
        this.locationProcessing++;
        dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
        const processLocation = location !== PROCESS_TRIGGER.DONT_PROCESS;
        const setBasePoint = location === PROCESS_TRIGGER.BASEPOINT;
        return new Promise((resolve) => {
            getLocation()
                .then((position) => {
                    this.locationProcessing--;
                    const { latitude: lat, longitude: lng } = position;
                    if (processLocation) {
                        this.locationProcessed = true;
                        dispatcher.dispatch(events.GEO_LOCATION_UPDATED, { lat, lng, setBasePoint, zoom });
                    }
                    if (weAreInNativeApp() && watch !== START_WATCH.DONT_START) {
                        logDebug('try watch native app location');
                        this.startWatchingPosition();
                    } else if (watch === START_WATCH.IF_ANY) {
                        logDebug('try watch any location');
                        this.startWatchingPosition();
                    } else if (watch === START_WATCH.IF_ACCURATE && isGoodAccuracy(position)) {
                        logDebug('try watch accurate location', position);
                        this.startWatchingPosition();
                    }
                    dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
                    resolve({ lat, lng, zoom });
                })
                .catch((error) => {
                    this.locationProcessing--;
                    if (processLocation) {
                        this.locationProcessed = true;
                        dispatcher.dispatch(events.GEO_LOCATION_ERROR, error);
                    }
                    console.error(error);
                    dispatcher.dispatch(events.RETRIEVING_USER_LOCATION_TOGGLED);
                    resolve({ lat: null, lng: null, setBasePoint });
                });
        });
    }

    isLocationProcessed() {
        return this.locationProcessed;
    }

    isLocationProcessing() {
        return this.locationProcessing > 0;
    }

    /**
     * @param {UserData} user
     * @return Promise
     */
    getCurrentLocation(user) {
        const url = reverse(apiRoutes.tracking.getUserLocation, {
            userId: user.id,
        });
        return new Promise((resolve, reject) => {
            this.requestApi(url, 'GET')
                .then((response) => {
                    const datetimeFormat = 'yyyy-MM-dd HH:mm:ss';
                    const time = parse(response['last_location_at'] + 'Z', datetimeFormat + 'X', new Date());
                    const lat = parseFloat(response['latitude']) || null;
                    const lng = parseFloat(response['longitude']) || null;
                    const userLocation = {
                        point: lat && lng ? { lat, lng } : null,
                        time: isValid(time) ? time : null,
                    };
                    resolve(userLocation);
                })
                .catch((e) => {
                    resolve(null);
                });
        });
    }
}

export default new GeoLocationManager();
