import React, { memo, useCallback, useEffect, useMemo } from 'react';
import './style.css';
import Account from './Account';
import DataSourcesList from './DataSourcesList';
import Grid from '@material-ui/core/Grid';
import { WithTranslation, withTranslation } from 'react-i18next';
import { userManager } from 'service/UserManager';
import entityViewManager from 'service/EntityViewManager';
import GoogleApi from '../../api/GoogleApi';
import { reverse, reverseWithSearch, routes } from '../../routes';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, withRouter } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import ImportMessageAccountMessages from '../ImportMessage/AccountMessages';
import { TYPE_OPEN_FORM } from '../../service/WorkflowActionManager';
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from '../TabPanel';
import UsersList from '../UsersList';
import Tooltip from '@material-ui/core/Tooltip';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import BillingContainer from '../Billing/BillingContainer';
import Permissions from '../Permissions/Permissions';
import { RouteComponentProps } from 'react-router';

const DATA_SOURCES = 'sources';
const DATA_USERS = 'users';
const DATA_PERMISSIONS = 'permissions';
const DATA_SUBSCRIPTION_BILLING = 'subscription_billing';

const ALL_DATA_SOURCES = [DATA_SOURCES, DATA_USERS, DATA_PERMISSIONS, DATA_SUBSCRIPTION_BILLING] as const;
type CurrentTab = (typeof ALL_DATA_SOURCES)[number];

interface AccountPageProps extends WithSnackbarProps, WithTranslation, RouteComponentProps, WithCurrentTabProps {
    accountId: number;
    myAccount?: boolean;
}

class AccountPage extends React.PureComponent<AccountPageProps> {
    static defaultProps = {
        myAccount: false,
    };

    componentDidMount() {
        const { accountId } = this.props;
        userManager
            .requestCurrentUser()
            .then(() => {
                if (userManager.isRoleSuperAdmin()) {
                    return entityViewManager.loadFrameData(accountId);
                }
                return entityViewManager.loadFrameData();
            })
            .then((response: any) => new GoogleApi(response.apiKeys.google).loadMapScript())
            .catch((error: any) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                if (error.code === 403) {
                    error.skipSentry = true;
                }
                throw error;
            });
    }

    render() {
        const { myAccount, accountId, t, currentTab, onChangeCurrentTab, visitedTabs } = this.props;

        const user = userManager.getCurrentUser();
        const isSubscriptionBlocked = userManager.isSubscriptionBlocked();
        const tooltipTextButton = isSubscriptionBlocked ? t('main_menu.subscription_blocked_tooltip') : '';

        return (
            <React.Fragment>
                <ImportMessageAccountMessages accountId={accountId} />
                <Grid container spacing={1}>
                    <div id="google-places" style={{ display: 'none' }} />
                    <Grid item>
                        <h1>{t('main_menu.account_settings')}</h1>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.sharedMaps, {
                                        accountId: myAccount ? undefined : accountId,
                                    })}
                                    data-testid="main_menu.shared_maps"
                                >
                                    <span>{t('main_menu.shared_maps')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.workflowRules, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                    })}
                                    data-testid="main_menu.workflow_rules"
                                >
                                    <span>{t('main_menu.workflow_rules')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.workflowActions, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                    })}
                                    data-testid="main_menu.workflow_actions"
                                >
                                    <span>{t('main_menu.workflow_actions')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.workflowButtons, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                    })}
                                    data-testid="main_menu.workflow_buttons"
                                >
                                    <span>{t('main_menu.workflow_buttons')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.workflowActions, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                        type: TYPE_OPEN_FORM,
                                    })}
                                    data-testid="main_menu.workflow_actions.forms"
                                >
                                    <span>{t('main_menu.workflow_actions.forms')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltipTextButton}>
                            <span>
                                <MenuItem
                                    className="link-to-route"
                                    component={Link}
                                    disabled={isSubscriptionBlocked}
                                    to={reverse(routes.admin.account.workflowSchedules, {
                                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                                    })}
                                    data-testid="main_menu.workflow_schedules"
                                >
                                    <span>{t('main_menu.workflow_schedules')}</span>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <MenuItem
                            className="link-to-route"
                            component={Link}
                            to={reverse(routes.admin.account.log, {
                                accountId: this.props.myAccount ? undefined : this.props.accountId,
                            })}
                            data-testid="main_menu.logs"
                        >
                            <span>{t('main_menu.logs')}</span>
                        </MenuItem>
                    </Grid>
                </Grid>
                <br />
                <Grid>
                    <Tabs
                        indicatorColor="primary"
                        value={currentTab}
                        onChange={onChangeCurrentTab}
                        variant="scrollable"
                    >
                        <Tab
                            value={DATA_SOURCES}
                            label={t('account.tabs.title.sources')}
                            className={'account_page__tab'}
                        />
                        <Tab value={DATA_USERS} label={t('account.tabs.title.users')} />
                        <Tab
                            value={DATA_PERMISSIONS}
                            label={t('account.tabs.title.permissions')}
                            className={'account_page__tab'}
                        />
                        <Tab
                            value={DATA_SUBSCRIPTION_BILLING}
                            label={t('account.tabs.title.billing')}
                            className={'account_page__tab'}
                        />
                    </Tabs>
                    <br />
                    <TabPanel index={DATA_SOURCES} value={currentTab}>
                        {visitedTabs.has(DATA_SOURCES) && (
                            <Grid container wrap="nowrap" spacing={1}>
                                <Grid item xs={12}>
                                    <DataSourcesList myAccount={myAccount} accountId={accountId} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Account myAccount={myAccount} accountId={accountId} />
                                </Grid>
                            </Grid>
                        )}
                    </TabPanel>
                    <TabPanel index={DATA_USERS} value={currentTab}>
                        {visitedTabs.has(DATA_USERS) && <UsersList accountId={accountId} myAccount={myAccount} />}
                    </TabPanel>
                    <TabPanel index={DATA_PERMISSIONS} value={currentTab}>
                        {visitedTabs.has(DATA_PERMISSIONS) && (
                            <div>
                                <div className="c-billing">
                                    <Permissions
                                        // @ts-expect-error - something wrong
                                        accountId={accountId!}
                                        bigHeader={false}
                                        redirectable={false}
                                    />
                                </div>
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel index={DATA_SUBSCRIPTION_BILLING} value={currentTab}>
                        {visitedTabs.has(DATA_SUBSCRIPTION_BILLING) && (
                            <BillingContainer user={user} accountId={accountId} />
                        )}
                    </TabPanel>
                </Grid>
            </React.Fragment>
        );
    }
}

interface WithCurrentTabProps {
    currentTab: CurrentTab;
    onChangeCurrentTab: (event: any, value: any) => void;
    visitedTabs: Set<CurrentTab>;
}

const WithCurrentTab = memo<Omit<AccountPageProps, keyof WithCurrentTabProps>>((props) => {
    const { history, location } = props;

    const visitedTabs = useMemo(() => new Set<CurrentTab>(), []);
    const search = location.search;

    const currentTab = useMemo(() => {
        const tab = new URLSearchParams(search).get('tab') as CurrentTab | null;

        return ALL_DATA_SOURCES.includes(tab!) ? tab! : DATA_SOURCES;
    }, [search]);

    // not using effect here because it is O(1)
    visitedTabs.add(currentTab);

    const onChangeCurrentTab = useCallback(
        (_event: any, tab: any) => {
            history.push(reverseWithSearch(location.pathname, undefined, { tab }));
        },
        [history, location],
    );

    useEffect(() => {
        const tag = Symbol('WithCurrentTab');

        dispatcher.subscribe(events.CLICK_MENU_OPEN.ACCOUNT, tag, (tab: any) => {
            onChangeCurrentTab(null, tab);
        });

        return () => dispatcher.unsubscribe(events.CLICK_MENU_OPEN, tag);
    }, [onChangeCurrentTab]);

    return (
        <AccountPage
            {...props}
            visitedTabs={visitedTabs}
            currentTab={currentTab}
            onChangeCurrentTab={onChangeCurrentTab}
        />
    );
});

export default withTranslation('translations', { withRef: true })(withRouter(withSnackbar(WithCurrentTab)));
