import DateFnsUtils from '@date-io/date-fns';
import { Box } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { format } from 'date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Component } from 'react';

import { formatDateTimeForPicker, formatDateToDefault, UT_DATETIME_PICKER_FORMAT } from '../../utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface DateTimeBetweenFilterProps {
    classes?: ClassNameMap<'cell'>;
    filter: any;
    onFilter: (value: { dateFrom: string | null; dateTo: string | null }) => void;
}

class DateTimeBetweenFilter extends Component<DateTimeBetweenFilterProps> {
    handleChangeFrom = (dateFrom: MaterialUiPickersDate) => {
        dateFrom!.setSeconds(0, 0);
        this.makeBetweenObject(dateFrom!, this.props.filter.value!.dateTo);
    };

    handleChangeTo = (dateTo: MaterialUiPickersDate) => {
        dateTo!.setSeconds(0, 0);
        this.makeBetweenObject(this.props.filter.value!.dateFrom, dateTo!);
    };

    makeBetweenObject(dateFrom: Date, dateTo: Date) {
        const value = {
            dateFrom,
            dateTo,
        };
        this.props.onFilter({
            dateFrom: value.dateFrom ? formatDateToDefault(value.dateFrom) : null,
            dateTo: value.dateTo ? formatDateToDefault(value.dateTo) : null,
        });
    }

    render() {
        const { filter, classes } = this.props;
        const { dateFrom, dateTo } = filter.value;

        return (
            <Box className={'date-time-from-container ' + classes?.cell} display="flex" gridGap={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        format={UT_DATETIME_PICKER_FORMAT}
                        labelFunc={(date, fallback = '') => (date ? format(date, 'dd.MM.yyyy HH:mm') : fallback)}
                        margin="none"
                        variant="inline"
                        autoOk={true}
                        onChange={this.handleChangeFrom}
                        clearable
                        className="date-from"
                        value={dateFrom ? formatDateTimeForPicker(dateFrom) : null}
                    />
                </MuiPickersUtilsProvider>
                <span>–</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        format={UT_DATETIME_PICKER_FORMAT}
                        labelFunc={(date, fallback = '') => (date ? format(date, 'dd.MM.yyyy HH:mm') : fallback)}
                        margin="none"
                        variant="inline"
                        autoOk={true}
                        onChange={this.handleChangeTo}
                        clearable
                        className="date-to"
                        value={dateTo ? formatDateTimeForPicker(dateTo) : null}
                    />
                </MuiPickersUtilsProvider>
            </Box>
        );
    }
}

export default DateTimeBetweenFilter;
