import React from 'react';
import PropTypes from 'prop-types';
import DottedLink from '../DottedLink';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '../Backdrop';
import LoadingButton from '../LoadingButton';
import TextField from '@material-ui/core/TextField';
import Alert from '../Alert';
import { userManager } from '../../service/UserManager';
import './style.css';
import { withRouter } from 'react-router-dom';
import Hint from '../Hint';
import { withTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import config from '../../params';
import ReCAPTCHA from 'react-google-recaptcha';
import { capitalizeFirstLetter } from '../../utils';

class SSUSelectAccount extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: false,
        };
    }

    handleLinkAccountRequest = () => {
        this.setState((state) => ({
            displayForm: !state.displayForm,
        }));
    };

    render() {
        const { t, match } = this.props;
        const userData = this.props.manager.getUserData();

        return (
            <div className="cSignup">
                <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <img src="/logo.png" alt="Mapsly" style={{ width: 98 }} />
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.props.onCreateAccount}
                            fullWidth
                            data-testid="signup.account.create"
                        >
                            {t('signup.account.create')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <DottedLink
                            style={{ color: '#888', fontSize: 'small' }}
                            onClick={this.handleLinkAccountRequest}
                            data-testid="signup.account.link"
                        >
                            {t('signup.account.link')}
                        </DottedLink>
                    </Grid>
                    {this.state.displayForm && (
                        <Grid item>
                            <LinkAccountForm
                                onLink={this.props.onLinkAccount}
                                username={userData && userData.email ? userData.email : ''}
                                t={t}
                                provider={match.params.provider}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

SSUSelectAccount.propTypes = {
    onCreateAccount: PropTypes.func,
    onLinkAccount: PropTypes.func,
};

class LinkAccountForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: props.username || '',
            password: '',
            errors: new Map(),
            message: null,
            loading: false,
            captcha: null,
        };

        this.recaptchaRef = React.createRef();
    }

    handleChangeCaptcha = (token) => {
        this.setState({
            captcha: token,
        });
    };

    handleExpiredCaptcha = () => {
        this.setState({
            captcha: null,
        });
    };

    handleSubmit = () => {
        const { t } = this.props;

        const errors = new Map();
        for (let prop of ['username', 'password']) {
            try {
                this.validateProperty(prop);
            } catch (e) {
                errors.set(prop, e.message);
            }
        }

        if (errors.size > 0) {
            this.setState({
                message: {
                    text: t('validation_errors'),
                    type: 'danger',
                },
                errors: errors,
            });
            return;
        }

        this.setState({
            message: null,
            errors: errors,
            loading: true,
        });

        this.handleAccountLinking();
    };

    async handleAccountLinking() {
        this.setState({ loading: true });

        try {
            await userManager.linkAccountCheck(this.state.username);

            const linkResponse = await userManager.linkAccount(
                this.state.username,
                this.state.password,
                this.state.captcha,
            );

            this.setState({
                loading: false,
            });

            if (this.props.onLink) {
                this.props.onLink(linkResponse['unlinkedAccount']);
            }
        } catch (error) {
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset();
            }

            this.setState({
                message: {
                    text: error.message,
                    type: 'danger',
                },
                errors: error.details || {},
                loading: false,
                captcha: null,
            });
        }
    }

    validateProperty(name) {
        const { t } = this.props;
        const value = this.state[name];
        switch (name) {
            case 'username':
            case 'password':
                if (value === '') {
                    throw new Error(t('signup.account.validation.required'));
                }
                break;
            default:
                break;
        }
    }

    handleInputChange = (name) => (event) => {
        this.handleAlertClose();
        this.setState({
            [name]: event.target.value,
        });
    };

    handleAlertClose = () => {
        this.setState({
            errors: new Map(),
            message: null,
        });
    };

    isSubmitButtonEnabled() {
        const { username, password, captcha } = this.state;

        if (!username || !password) {
            return false;
        }

        return !config.recaptchaSiteKey || !!captcha;
    }

    render() {
        const { t, provider } = this.props;
        return (
            <form onSubmit={(event) => event.preventDefault()} className="cLinkAccountForm">
                <h3 className="cLinkAccountForm__h2">{t('signup.account.sign_in')}</h3>
                <span>
                    <Hint type="inline">
                        {t('signup.account.must_be_admin', { crm: capitalizeFirstLetter(provider) })}
                    </Hint>
                </span>
                {this.state.message !== null && (
                    <Alert canClose onClose={this.handleAlertClose} type={this.state.message.type}>
                        {this.state.message.text}
                    </Alert>
                )}

                <Backdrop loading={this.state.loading}>
                    <TextField
                        autoFocus
                        label={t('signup.account.username')}
                        data-testid="signup.account.username"
                        fullWidth
                        margin="dense"
                        value={this.state.username}
                        helperText={this.state.errors.get('username') || ''}
                        error={this.state.errors.has('username')}
                        onChange={this.handleInputChange('username')}
                    />
                    <PasswordField
                        label={t('signup.account.password')}
                        data-testid="signup.account.password"
                        fullWidth
                        margin="dense"
                        value={this.state.password}
                        helperText={this.state.errors.get('password') || ''}
                        error={this.state.errors.has('password')}
                        onChange={this.handleInputChange('password')}
                    />
                    {!!config.recaptchaSiteKey && (
                        <div>
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                sitekey={config.recaptchaSiteKey}
                                onChange={this.handleChangeCaptcha}
                                onExpired={this.handleExpiredCaptcha}
                            />
                        </div>
                    )}
                </Backdrop>
                <div style={{ marginTop: 8 }}>
                    <LoadingButton
                        onClick={this.handleSubmit}
                        size="medium"
                        variant="contained"
                        color="primary"
                        loading={this.state.loading}
                        fullWidth
                        data-testid="signup.account.login"
                        disabled={!this.isSubmitButtonEnabled()}
                    >
                        {t('signup.account.login')}
                    </LoadingButton>
                </div>
            </form>
        );
    }
}

LinkAccountForm.propTypes = {
    username: PropTypes.string,
    onLink: PropTypes.func,
    provider: PropTypes.string,
};

export default withTranslation('translations', { withRef: true })(withRouter(SSUSelectAccount));
