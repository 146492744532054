export default {
    BASE_POINT: 'base_point',
    SEARCH_ADDRESS_UPDATED: 'search_address_updated',
    UPDATE_BASE_POINT_HANDLE: 'update_base_point_handle',
    BASE_POINT_RADIUS: 'base_point_radius',
    BASE_POINT_CIRCLES: 'base_point_circles',

    COUNTRIES_LIST_LOADED: 'countries_list_loaded',

    DEFAULT_FEATURES_LOADED: 'interesting_features_loaded',

    EVENT_DATA_SOURCE_CHANGED: 'event_data_source_changed',
    EVENT_DATA_SOURCE_CREATED: 'event_data_source_created',
    EVENT_DATA_SOURCE_DELETED: 'event_data_source_deleted',
    EVENT_DATA_SOURCE_UPDATED: 'event_data_source_updated',
    EVENT_DATA_SOURCE_CONNECTED: 'event_data_source_connected',

    EVENT_ROLE_CHANGED: 'event_role_changed',
    EVENT_ROLES_CHANGED: 'event_roles_changed',
    EVENT_ROLE_CREATED: 'event_role_created',
    EVENT_ROLE_DELETED: 'event_role_deleted',

    EVENT_VEHICLE_PROFILES_IS_LOADED: 'event_vehicle_profiles_is_loaded',
    EVENT_VEHICLE_PROFILE_CHANGED: 'event_vehicle_profile_changed',
    EVENT_VEHICLE_PROFILE_CREATED: 'event_vehicle_profile_created',
    EVENT_VEHICLE_PROFILE_DELETED: 'event_vehicle_profile_deleted',

    EVENT_CURRENT_USER_CHANGED: 'event_current_user_changed',
    EVENT_USER_LOGIN: 'user_login',
    EVENT_USER_SSO_LOGIN: 'user_sso_login',
    EVENT_USER_SWITCH: 'user_switch',

    EVENT_USER_CHANGED: 'event_user_changed',
    EVENT_USER_CREATED: 'event_user_created',

    EVENT_USERS_DELETED: 'event_users_deleted',

    EVENT_ROUTE_DELETED: 'event_route_deleted',
    EVENT_ROUTE_CHANGED: 'event_route_changed',
    EVENT_ROUTE_CREATED: 'event_route_created',

    EVENT_ROUTE_NOT_DELETED: 'event_route_not_deleted',

    ACCOUNT_UPDATED: 'account_updated',

    CURRENT_ROUTE_DESIGN: 'current_route_design', // route building result was dropped (e.g., "edit route" button)
    CURRENT_ROUTE_BUILT: 'current_route_built',
    CURRENT_ROUTE_RESET: 'current_route_reset',
    CURRENT_ROUTE_LOADED: 'current_route_loaded',
    CURRENT_ROUTE_SAVED: 'current_route_saved',
    CURRENT_ROUTE_NAME_AND_USER_UPDATED: 'current_route_properties_updated',
    CURRENT_ROUTE_POINTS_APPENDED: 'current_route_points_appended',
    CURRENT_ROUTE_POINTS_GROUPED: 'current_route_points_grouped',
    CURRENT_ROUTE_POINTS_DELETED: 'current_route_points_deleted',
    CURRENT_ROUTE_POINTS_UPDATED: 'current_route_points_updated',
    CURRENT_ROUTE_POINTS_RESORTED: 'current_route_points_resorted',

    CURRENT_ROUTE_MAP_POINT_CLICKED: 'current_route_map_point_clicked',
    CURRENT_ROUTE_MAP_POINT_MOUSE_ENTER: 'current_route_map_point_mouse_enter',
    CURRENT_ROUTE_MANUAL_POINT_MOUSE_ENTER: 'current_route_manual_point_mouse_enter',
    CURRENT_ROUTE_MAP_POINT_MOUSE_LEAVE: 'current_route_map_point_mouse_leave',
    CURRENT_ROUTE_MANUAL_POINT_MOUSE_LEAVE: 'current_route_manual_point_mouse_leave',
    ROUTING_SESSION: {
        CHANGED: 'routing_session_changed',
        DRAFT_DELETED: 'routing_session_route_deleted',
        DRAFT_EDIT_CANCELED: 'routing_draft_edit_canceled',
        DRAFT_EDIT_SAVED: 'routing_draft_edit_saved',
        DRAFT_SAVED: 'routing_edit_saved',
        PUBLISHED_EDIT_CANCELED: 'routing_published_edit_canceled',
        PUBLISHED_EDIT_SAVED: 'routing_published_edit_saved',
        REBUILD_MODE_CANCELED: 'rebuild_mode_canceled',
        REBUILD_MODE_SAVED: 'rebuild_mode_saved',
        REBUILD_MODE_ENABLED: 'rebuild_mode_enabled',
    },
    ROUTE_REPORT: {
        FILTER_CHANGED: 'route_report_filter_changed',
        OPEN_SINGLE: 'route_report_open_single',
        RETURN_TO_LIST: 'route_report_return_to_list',
        TAB_CHANGED: 'route_report_tab_changed',
    },
    WS_ROUTING_BUILD_READY: 'ws_routing_solution_ready',
    WS_ROUTING_REFRESH_READY: 'ws_routing_refresh_ready',
    WS_ROUTING_EDIT_READY: 'ws_routing_edit_ready',
    WS_ROUTES_CHANGED: 'ws_routes_changed',
    WS_POINT_MARK_AS_VISITED: 'ws_point_mark_as_visited',

    WS_MESSAGE: 'ws_message',
    WS_OPEN_FORM: 'ws_open_form',
    WS_UPDATE_FORM: 'ws_update_form',
    WS_INIT_FORM: 'ws_init_form',
    WS_CLOSE_FORM: 'ws_close_form',
    WS_OPEN_URL: 'ws_open_url',
    WS_FRONTEND_EVENT: 'ws_frontend_event',
    WS_FINISHED_WORKFLOW_CHAIN: 'ws_finished_workflow_chain',
    WS_WORKFLOW_ACTIONS_BUTTONS_UPDATED: 'ws_workflow_actions_buttons_updated',
    WS_ENTITY_IMPORT_PROGRESS: 'ws_entity_import_progress',
    WS_ENTITIES_IMPORT_PROGRESS: 'ws_entities_import_progress',
    WS_ENTITY_RECORDS_ADDED: 'ws_entity_records_added',
    WS_ENTITY_RECORDS_GEOCODE_LEFT: 'ws_entity_records_geocode_left',
    WS_DS_METADATA_IMPORT: 'ws_ds_metadata_import',
    WS_ENTITY_RECORDS_GEOCODED: 'ws_entity_records_geocoded',
    WS_PERSISTENT_MESSAGE: 'ws_persistent_message',
    WS_UPDATE_RECORDS_RESPONSE: 'ws_update_records_response',
    UPDATE_RECORDS_REQUEST: 'update_records_request',
    WS_SEARCH_RECORDS_RESPONSE: 'ws_search_records_response',
    WS_ENTITY_RECORDS_MODIFIED: 'ws_entity_records_modified',
    WS_ENTITY_RECORDS_DELETED: 'ws_entity_records_deleted',
    WS_PROSPECT_IMPORT_ENDED: 'ws_prospect_import_ended',
    WS_TERRITORIES_UPDATED: 'ws_territories_updated',
    WS_ROLES_UPDATED: 'ws_roles_updated',
    WS_UI_VERSION_UPDATED: 'ws_ui_version_updated',
    WS_TERRITORIES_GROUP_SUCCESS_CHANGE: 'ws_territories_group_success_change',
    WS_TERRITORIES_GROUP_ERROR_CHANGE: 'ws_territories_group_error_change',
    WS_ENTITIES_AVAILABLE_FOR_IMPORT_PROGRESS: 'ws_entities_available_for_import_progress',
    WS_ROUTE_PATHS_BUILT: 'ws_route_paths_built',
    WS_HISTORY_PATHS_BUILT: 'ws_history_paths_built',
    WS_GEOCODER_RESPONSE: 'ws_geocoder_response',
    WS_USERS_DELETED: 'ws_users_deleted',
    WS_SESSION_TERMINATED: 'ws_session_terminated',
    WS_SUCCESS_PERSISTENT: 'ws_success_persistent',
    SESSION_TERMINATED: 'session_terminated',
    WS_CALENDAR_EVENT_JOB_SUCCESS: 'ws_calendar_event_job_success',
    WS_CALENDAR_EVENT_JOB_ERROR: 'ws_calendar_event_job_error',
    WS_USER_UPDATED: 'ws_user_updated',
    WS_CALENDAR_CHANGE: 'ws_calendar_change',
    WS_CALENDAR_EVENTS_CHANGE: 'ws_calendar_events_change',

    WS_SUBSCRIPTION_UPDATED: 'ws_subscription_updated',
    EVENT_REQUEST_TABLE_DATA: 'request_table_data',
    EVENT_ENTITY_DATA_FILTER_CHANGED: 'entity_data_filter_changed',
    EVENT_ENTITY_DATA_FILTER_CLEARED: 'entity_data_filter_cleared',
    EVENT_ENTITY_DATA_FILTER_CHANGED_MAP_MODE: 'entity_data_filter_changed_map_mode',
    EVENT_ENTITY_POINTS_IS_LOADING: 'event_entity_points_is_loading',
    EVENT_ENTITY_POINTS_IS_LOADED: 'event_entity_points_is_loaded',
    EVENT_ENTITY_POINTS_ADDED: 'event_entity_points_added',
    EVENT_PROSPECTING_ENABLED: 'event_prospecting_enabled',
    EVENT_PROSPECTING_DISABLED: 'event_prospecting_disabled',
    EVENT_PROSPECTING_POINTS_ADDED: 'event_prospecting_points_added',
    EVENT_PROSPECTING_POINTS_RELOADED: 'event_prospecting_points_reloaded',
    EVENT_PROSPECTING_POINTS_REMOVED: 'event_prospecting_points_removed',
    ENTITIES_AVAILABLE_FOR_IMPORT_LOADED: 'entities_available_for_import_loaded',

    SNACKBAR_CLOSED: 'snackbar_closed',

    EVENT_SUBSCRIPTION_CHANGED: 'event_subscription_changed',
    EVENT_ACCOUNT_LIMITATIONS_CHANGED: 'event_account_limitation_changed',
    EVENT_PADDLE_CHECKOUT_COMPLETE: 'event_paddle_checkout_complete',
    EVENT_PADDLE_CHECKOUT_PAYMENT_COMPLETE: 'event_paddle_checkout_payment_complete',
    EVENT_ACCOUNT_AVATAR_CHANGED: 'event_account_avatar_changed',

    ENTITY_DATA_TABLE_MODE_CHANGED: 'entity_data_table_mode_changed',
    ENTITY_DATA_TABLE_ENTITY_CHANGED: 'entity_data_table_entity_changed',
    ENTITY_RELOAD_CURRENT_PAGE: 'entity_reload_current_page',
    RESET_ENTITY_FRACTIONS: 'reset_entity_fractions',

    START_EDIT_TERRITORY_GEOMETRY: 'START_EDIT_TERRITORY_GEOMETRY',
    START_EDIT_GROUP_GEOMETRY: 'START_EDIT_GROUP_GEOMETRY',
    END_EDIT_GEOMETRY: 'END_EDIT_GEOMETRY',
    SAVE_GEOMETRIES: 'SAVE_GEOMETRIES',
    STOP_CHANGE_GEOMETRIES: 'STOP_CHANGE_GEOMETRIES',
    SWITCH_TERRITORY: 'SWITCH_TERRITORY',
    SWITCH_TERRITORIES: 'SWITCH_TERRITORIES',
    TERRITORY_LOOKUP_ON_MAP: 'territory_lookup_on_map',
    TERRITORIES_SAVING: 'SAVING_TERRITORY',
    TERRITORIES_LOADED: 'TERRITORIES_LOADED',
    TERRITORIES_SAVED: 'TERRITORIES_SAVED',
    TERRITORIES_SETTINGS_LOADED: 'TERRITORIES_SETTINGS_LOADED',
    TERRITORIES_ACTIVE_GROUP_CHANGE: 'TERRITORIES_ACTIVE_GROUP_CHANGE',

    RESET_ENTITY_FRACTIONS_PARTIAL: 'reset_entity_fractions_partial',

    MAP_POSITION_CHANGED: 'map_position_changed',

    GEO_POSITION_UPDATED: 'geo_position_updated',
    GEO_LOCATION_UPDATED: 'geo_location_updated',
    GEO_LOCATION_ERROR: 'geo_location_error',

    WS_FIELD_PICKLIST_CHANGED: 'ws_field_picklist_changed',

    EVENT_INTERCOM_STATE_CHANGED: 'event_intercom_state_changed',
    EVENT_INTERCOM_STATE_OPENED: 'EVENT_INTERCOM_STATE_OPENED',
    EVENT_INTERCOM_SHOW_MESSAGE: 'event_intercom_show_message',
    EVENT_INTERCOM_HIDE_MESSAGE: 'event_intercom_hide_message',

    EVENT_OPENING_WEB_LINK_IFRAME: 'event_opening_web_link_iframe',

    EVENT_ACCOUNT_PERMISSIONS_SAVED: 'event_account_permissions_saved',
    EVENT_ACCOUNT_PERMISSIONS_LOADED: 'event_account_permissions_loaded',
    EVENT_OPENING_GOOGLE_STREET_VIEW: 'event_opening_google_street_view',

    EVENT_CHANGE_LANGUAGE: 'event_change_language',

    MAP_SETTINGS_CHANGED: 'map_settings_changed',
    ENTITY_SETTINGS_CHANGED: 'entity_settings_changed',
    MAP_LEFT_PANEL_UPDATED: 'map_left_panel_updated',

    MAP_POSITION_PRESET: 'map_position_preset',

    EVENT_LAYER_GROUP_CREATED: 'event_layer_group_created',
    EVENT_LAYER_GROUP_REFRESHED: 'event_layer_group_refreshed',

    CLUSTERING_SETTINGS_CHANGED: 'clustering_settings_changed',
    CLUSTER_SIZE_CHANGED: 'cluster_size_changed',

    CLUSTERING_RECALCULATED: 'clustering_recalculated',

    ENTITY_VISIBILITY_CHANGED: 'entity_visibility_changed',
    LAYER_VISIBILITY_CHANGED: 'layer_visibility_changed',
    ENTITY_VIEW_CHANGED: 'entity_view_changed',
    LAYER_VIEW_CHANGED: 'layer_view_changed',
    ENTITY_LAYER_GROUP_CHANGED: 'entity_layer_group_changed',
    WS_ENTITIES_CHANGED: 'ws_entities_changed',

    EVENT_VIEW_RECORD: 'view_record',
    EVENT_VIEW_RECORD_LOADED: 'view_record_loaded',

    GEO_LIB_LOAD_DATA: 'geo_lib_load_data',
    GEO_LIB_DATA_CHANGE: 'geo_lib_data_change',
    GEO_LIB_SELECTED_DATA_CHANGE: 'geo_lib_selected_data_change',
    GEO_LIB_CHANGE_MAIN_TYPE: 'geo_lib_change_main_type',

    WS_CSV_EXPORT: 'ws_csv_export',
    CSV_EXPORT_STATE: 'csv_export_state',

    MAP_POPUP_OPENED: 'map_popup_opened',
    MAP_POPUP_CLOSED: 'map_popup_closed',

    WS_SAVED_PLACE_DELETED: 'ws_saved_place_deleted',
    WS_SAVED_PLACE_CHANGED: 'ws_saved_place_changed',
    WS_SAVED_PLACE_CREATED: 'ws_saved_place_created',
    EVENT_SAVED_PLACE_DELETED: 'event_saved_place_deleted',
    EVENT_SAVED_PLACE_CHANGED: 'event_saved_place_changed',
    EVENT_SAVED_PLACE_CREATED: 'event_saved_place_created',
    EVENT_SAVED_PLACE_NOT_DELETED: 'event_saved_place_not_deleted',
    EVENT_SAVED_PLACE_NOT_CHANGED: 'event_saved_place_not_changed',
    EVENT_SAVED_PLACE_NOT_CREATED: 'event_saved_place_not_created',

    SHARED_MAP_SAVED: 'event_shared_map_saved',
    WORKFLOW_ACTIONS_BUTTONS_REFRESHED: 'workflow_actions_buttons_refreshed',
    WORKFLOW_ACTIONS_FORM_REFRESHED: 'workflow_actions_form_refreshed',
    WORKFLOW_ACTIONS_RUNNING_CHANGED: 'workflow_actions_running_changed',
    WORKFLOW_FILE_UPLOAD_IN_PROGRESS: 'workflow_file_upload_in_progress',
    WORKFLOW_FILE_UPLOAD_COMPLETE: 'workflow_file_upload_complete',
    WORKFLOW_MAP_ACTIONS_FORM_REFRESHED: 'workflow_map_actions_form_refreshed',
    WORKFLOW_MAP_ACTIONS_FORM_SHOW: 'workflow_map_actions_form_show',
    WORKFLOW_MAP_ACTION_FORM_FIELD_UPDATED: 'workflow_map_actions_form_field_updated',
    MAP_FILTER_UPDATED: 'map_filter_updated',
    RETRIEVING_USER_LOCATION_TOGGLED: 'retrieving_user_location_toggled',

    MAP_PAGE_LOADED: 'map_page_loaded',

    LAYER_NEED_TO_BE_RELOADED: 'LAYER_NEED_TO_BE_RELOADED',
    LAYERS_NEED_TO_BE_RELOADED: 'LAYERS_NEED_TO_BE_RELOADED',

    EVENT_TRIP_COMPLETED: 'event_trip_completed',
    EVENT_TRIP_CENTER_CURRENT_ROUTE_SEGMENT: 'event_trip_center_current_route_segment',
    EVENT_TRIP_ONLY_ROUTE_POINTS_SHOW_TOGGLED: 'event_trip_only_route_points_show_toggled',
    EVENT_TRIP_STARTED: 'event_trip_started',
    EVENT_TRIP_ENDED: 'event_trip_ended',

    MAP_POPUP_REQUESTED: 'map_popup_requested',
    LIST_VIEW_RECORD_MOUSE_ENTER: 'list_view_record_mouse_enter',
    LIST_VIEW_RECORD_MOUSE_LEAVE: 'list_view_record_mouse_leave',

    IAB_CLOSED_AUTOMATICALLY: 'iab_closed',

    APP_WENT_OFFLINE: 'app_went_offline',
    APP_IS_BACK_ONLINE: 'app_is_back_online',
    REQUEST_FAILED_DUE_TO_INTERNET_CONNECTION: 'request_failed_due_to_internet_connection',

    HEATMAP_LOAD: 'heatmap_load',
    HEATMAP_DRAW: 'heatmap_draw',

    BG_GEO_TRACKING_STARTED: 'bg_geo_tracking_started',
    BG_GEO_TRACKING_STOPPED: 'bg_geo_tracking_stopped',

    SEARCHBAR_HEIGHT_CHANGED: 'searchbar_height_changed',
    SEARCHBAR_WIDTH_CHANGED: 'searchbar_width_changed',
    SEARCHBAR_X_CHANGED: 'searchbar_x_changed',
    SEARCHBAR_TOOLBAR_BUTTON_CLICKED: 'searchbar_toolbar_button_clicked',

    SHOW_BATTERY_OPTIMIZATION_MODAL: 'show_battery_optimization_modal',

    ADD_MESSAGE_ENQUEUE_SNACKBAR: 'add_message_enqueue_snackbar',
    CLOSE_MESSAGE_ENQUEUE_SNACKBAR: 'close_message_enqueue_snackbar',

    SHOW_APP_UPDATE_MODAL: 'show_app_update_modal',

    THIRD_PARTY_COOKIES_ACCESS_STATE_CHANGED: 'third_party_cookies_access_state_changed',
    SMTP_CREDENTIALS_UPDATED: 'smtp_credentials_updated',

    WEBSOCKET_IO_SERVER_CONNECT: 'websocket_io_server_connect',
    WEBSOCKET_IO_SERVER_DISCONNECT: 'websocket_io_server_disconnect',
    WS_FIX_SHARD_PROGRESS: 'ws_fix_shard_progress',
    SHARD_UPDATED_STATE_CHANGED: 'shard_updated_state_changed',

    CLICK_MENU_OPEN: {
        TERRITORIES: 'CLICK_MENU_OPEN_TERRITORIES',
        ACCOUNT: 'CLICK_MENU_OPEN_USERS',
    },

    CLICK_CONTEXT_MENU: {
        SHOW_IN_TERRITORIES: 'CLICK_CONTEXT_MENU_SHOW_IN_TERRITORIES',
    },

    EVENTS_QUEUE_LOADED: 'events_queue_loaded',
    EVENTS_QUEUE_CHANGED: 'events_queue_changed',
    EVENTS_ONLINE_QUEUE_CHANGED: 'events_online_queue_changed',
    EVENTS_QUEUE_EDITING_EVENT_CHANGED: 'events_queue_editing_event_changed',
    SORTING_MANAGER_UPDATED: 'sorting_manager_updated',

    WS_FILE_CONVERSION: 'ws_file_conversion',
    WS_FILE_SUMMARIZATION: 'ws_file_summarization',
    WS_FILE_TRANSCRIPTION: 'ws_file_transcription',

    SIDEBAR_RESIZED: 'sidebar_resized',

    CALENDAR_EVENTS_LIST_UPDATED: 'calendar_events_list_updated',
    CALENDAR_MANAGER_SYNC: 'calendar_manager_sync',
    CALENDAR_ARRANGEMENT_UPDATED: 'calendar_arrangement_updated',

    CALENDAR_CELL_RESIZED: 'calendar_cell_resized',
    CALENDAR_WAITING_LIST_UPDATED: 'calendar_waiting_list_updated',
    CALENDAR_POPUP_UPDATED: 'calendar_popup_updated',
    CALENDAR_STATE_UPDATED: 'calendar_state_updated',
    OPEN_CALENDAR_DIALOG: 'open_calendar_dialog',

    CALENDAR_CENTER_ROUTE_BUTTON_CLICKED: 'calendar_center_route_button_clicked',

    CALENDAR_ROUTE_GROUP: {
        HOVERED: 'calendar_route_group_hovered',
        UNHOVERED: 'calendar_route_group_unhovered',
    },

    USER_WORKING_HOURS_EXCEPTIONS_POPUP_UPDATED: 'user_working_hours_exceptions_popup_updated',

    CALENDAR_UPDATED: 'calendar_updated',
    LASSO_ACTIVATED: 'lasso_activated',
    LASSO_FINISHED: 'lasso_finished',

    ROUTE_VIEWER_PATHS_PROCESSED: 'route_viewer_paths_processed',

    GOOGLE_CALENDAR_DISCONNECTED: 'google_calendar_disconnected',
} as const;
