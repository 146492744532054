import { makeStyles } from '@material-ui/core/styles';

import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

const SingleRowMultipleAutocomplete = <
    T,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>(
    props: AutocompleteProps<T, true, DisableClearable, FreeSolo>,
) => {
    return (
        <Autocomplete<T, true, DisableClearable, FreeSolo>
            multiple={true}
            style={{ width: '100%' }}
            className={singleLineStyle().root}
            options={props.options || []}
            value={props.value}
            defaultValue={props.defaultValue}
            getOptionLabel={props.getOptionLabel}
            getOptionDisabled={props.getOptionDisabled}
            size={props.size}
            loading={props.loading}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            fullWidth={props.fullWidth}
            disableClearable={props.disableClearable}
            filterOptions={props.filterOptions}
            onChange={props.onChange}
            renderOption={props.renderOption}
            renderInput={props.renderInput}
            PopperComponent={props.PopperComponent}
            groupBy={props.groupBy}
            disableCloseOnSelect
        />
    );
};

const singleLineStyle = makeStyles(() => ({
    root: {
        width: 150,
        '& > .MuiFormControl-root .MuiInputBase-root': {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            position: 'initial',
        },
    },
}));

export { SingleRowMultipleAutocomplete };
