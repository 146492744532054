import { Button as MUIButton, ButtonProps } from '@material-ui/core';
import { ComponentProps, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button/Button';

type T = ComponentProps<typeof Button> & Pick<ButtonProps, 'variant'>;
type LoadingButtonProps = Omit<T, 'onClick'> & {
    component?: any;
    onClick?(name?: string): void;
    loading?: boolean;
    name?: string;
    isNewDesign?: boolean;
};

export const LoadingButton: FC<LoadingButtonProps> = (props) => {
    const { t } = useTranslation();
    let { children, disabled, loading, onClick, name, isNewDesign, ...rest } = props;

    const handleClick = useCallback(() => {
        onClick?.(name);
    }, [onClick, name]);

    if (props.loading) {
        disabled = true;
    }

    // remove this flag (isNewDesign) when the old design is no longer needed
    if (isNewDesign) {
        return (
            <Button {...rest} name={name} disabled={disabled} onClick={handleClick}>
                {loading ? t('loading') : children}
            </Button>
        );
    }

    return (
        <MUIButton {...rest} name={name} disabled={disabled} onClick={handleClick}>
            {loading ? t('loading') : children}
        </MUIButton>
    );
};
