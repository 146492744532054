import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import Confirmation from '../Confirmation';
import { userManager } from '../../service/UserManager';
import Backdrop from '../Backdrop';
import { withSnackbar } from 'notistack';

class DeleteUserDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            usersObjectCounts: null,
            objectCountsTotal: null,
            newOwners: null,
        };
    }

    componentDidMount() {
        this.initDeleteUserDialog();
    }

    initDeleteUserDialog = async () => {
        try {
            const usersObjectCounts = await userManager.getUsersObjectCounts(
                this.props.accountId,
                this.props.deletedUserIds,
            );
            const objectCountsTotal =
                usersObjectCounts.mapLayerGroup +
                usersObjectCounts.savedPlace +
                usersObjectCounts.view +
                usersObjectCounts.territoryGroup +
                usersObjectCounts.route;

            this.setState({
                usersObjectCounts: usersObjectCounts,
                objectCountsTotal: objectCountsTotal,
            });

            if (objectCountsTotal) {
                this.refreshNewOwners();
            }
        } catch (e) {
            console.error(e);
        }
    };

    refreshNewOwners = async () => {
        try {
            const requestData = [
                {
                    columnName: 'id',
                    value: this.props.deletedUserIds,
                    operation: 'notIn',
                },
            ];
            if (this.props.rolesSharedMaps.length) {
                requestData.push({
                    columnName: 'role',
                    value: this.props.rolesSharedMaps.map((role) => role.id),
                    operation: 'notIn',
                });
            }

            const result = await userManager.findAccountUsers(this.props.accountId, requestData);

            this.setState({
                newOwners: result.items,
            });
        } catch (e) {
            console.error(e);
        }
    };

    handleChangeUser = (event, user) => {
        this.setState({
            selectedUser: user,
        });
    };

    render() {
        const { onReject, onConfirm, t } = this.props;
        const { usersObjectCounts } = this.state;
        if (!usersObjectCounts) {
            return (
                <Confirmation
                    text={() => <Backdrop loading={true} />}
                    disabledButtons={true}
                    textNo={t('button.cancel')}
                    textYes={t('button.proceed')}
                >
                    {this.props.children}
                </Confirmation>
            );
        }

        const { objectCountsTotal, selectedUser, newOwners } = this.state;
        const title = objectCountsTotal
            ? t('account.user.removed.popup.deleted_user_name_with_objects')
            : t('account.user.removed.popup.confirmation');

        return (
            <Confirmation
                text={() => (
                    <div onClick={(e) => e.stopPropagation()}>
                        <div>
                            <div>{title}</div>
                            {usersObjectCounts.mapLayerGroup !== 0 && (
                                <div>
                                    {t('account.user.removed.popup.object.map_layer_group', {
                                        count: usersObjectCounts.mapLayerGroup,
                                    })}
                                </div>
                            )}
                            {usersObjectCounts.view !== 0 && (
                                <div>
                                    {t('account.user.removed.popup.object.view', { count: usersObjectCounts.view })}
                                </div>
                            )}
                            {usersObjectCounts.savedPlace !== 0 && (
                                <div>
                                    {t('account.user.removed.popup.object.saved_place', {
                                        count: usersObjectCounts.savedPlace,
                                    })}
                                </div>
                            )}
                            {usersObjectCounts.territoryGroup !== 0 && (
                                <div>
                                    {t('account.user.removed.popup.object.territory_group', {
                                        count: usersObjectCounts.territoryGroup,
                                    })}
                                </div>
                            )}
                            {usersObjectCounts.route !== 0 && (
                                <div>
                                    {t('account.user.removed.popup.object.route', { count: usersObjectCounts.route })}
                                </div>
                            )}
                        </div>
                        {objectCountsTotal !== 0 && (
                            <Autocomplete
                                options={newOwners}
                                value={selectedUser}
                                getOptionLabel={(user) => user.name}
                                onChange={this.handleChangeUser}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        data-testid="delete_user_dialog.user_field"
                                        placeholder={t('undefined_picklist_option')}
                                        margin="normal"
                                        autoFocus={true}
                                    />
                                )}
                            />
                        )}
                    </div>
                )}
                onConfirm={(e) => onConfirm(e, selectedUser)}
                onReject={onReject}
                disabledYesButton={objectCountsTotal !== 0 && selectedUser === null}
                textNo={t('button.cancel')}
                textYes={t('button.proceed')}
            >
                {this.props.children}
            </Confirmation>
        );
    }
}

DeleteUserDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    deletedUserIds: PropTypes.array.isRequired,
    rolesSharedMaps: PropTypes.array.isRequired,
};

export default withTranslation()(withSnackbar(DeleteUserDialog));
