import React from 'react';
import {
    Icon,
    IconButton,
    InputAdornment,
    TextField,
    Theme,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

import DottedLink from '../../../components/DottedLink';

import { FieldFactoryValueObject } from './FieldFactory';

export interface AutoCompleteFieldProps extends WithTranslation, WithStyles<typeof styles> {
    value: string | number | FieldFactoryValueObject | null;
    name: string;
    label: string;
    onShowSearch: () => void;
    onRecordClick?: () => void;
    disabled?: boolean;
}

class AutoCompleteField extends React.PureComponent<AutoCompleteFieldProps> {
    render() {
        const { t, value, name, label, onShowSearch, classes, onRecordClick, disabled } = this.props;
        const textValue = getTextValue(value);

        const placeholder = disabled ? '' : t('live_update_table_form.field_factory.auto_complete.edit_text');

        return (
            <TextField
                name={`search_${name}`}
                className={classes.root}
                label={`${label}`}
                margin="dense"
                style={{ whiteSpace: 'nowrap' }}
                value={textValue || placeholder}
                InputProps={{
                    className:
                        // make input black only if there's a value
                        textValue ? classes.input : undefined,
                    disableUnderline: false,
                    inputComponent:
                        !!onRecordClick && !!value
                            ? (props) => (
                                  <span className={`${props.className} ${classes.span}`}>
                                      <DottedLink onClick={onRecordClick}>{props.value}</DottedLink>
                                  </span>
                              )
                            : (props) => (
                                  <span className={`${props.className} ${classes.span}`} onClick={onShowSearch}>
                                      {props.value}
                                  </span>
                              ),
                    endAdornment: disabled ? undefined : (
                        <InputAdornment position="end">
                            <IconButton
                                className="c-live-update-form__field__search-button"
                                onClick={onShowSearch}
                                data-testid="live_update_table_form.field_factory.show_search"
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    className: classes.label,
                    shrink: true,
                }}
                disabled={true}
                fullWidth
            />
        );
    }
}

const styles = (theme: Theme) => {
    return createStyles({
        label: {
            '&.Mui-disabled': {
                color: theme.palette.text.secondary,
            },
        },
        input: {
            '&.Mui-disabled': {
                color: theme.palette.text.primary,
            },
        },
        root: {
            '& .MuiInput-underline.Mui-disabled:before': {
                borderBottomStyle: 'solid',
            },
        },
        span: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    });
};

function getTextValue(value: AutoCompleteFieldProps['value']): string {
    if (typeof value === 'string' || typeof value === 'number') {
        return '' + value;
    }
    if (!value) {
        return '';
    }
    return value.textValue;
}

export default withTranslation()(withStyles(styles)(AutoCompleteField));
