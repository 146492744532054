import { Grid } from '@material-ui/core';
import { BarcodeScannerWeb } from './AbstractBarcode';

interface PropsType {
    handleChange: Function;
}
const BarcodeScanner: React.FC<PropsType> = ({ handleChange }) => {
    const startScan = async () => {
        const Barcode = new BarcodeScannerWeb();
        const res = await Barcode.askCameraPermission();

        if (res) {
            Barcode._hideAndShowBackground('hide');
            const result = await Barcode.startScan();
            if (result?.hasContent) {
                handleChange({ target: { value: result.content } });
            }
            Barcode.stopScan();
            Barcode._hideAndShowBackground('show');
        }
    };

    return (
        <Grid xs={2} onClick={startScan} item alignItems="center" style={{ textAlign: 'end', marginTop: '5px' }}>
            <i style={{ fontSize: '20px' }} className="fas fa-barcode-read"></i>
        </Grid>
    );
};

export default BarcodeScanner;
