import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
} from '@material-ui/core';
import {
    DETAIL_PLANNER_CREATE_ROUTES,
    DETAIL_PLANNER_OUT_ENTITY_CREATE,
    DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_DS_ID,
    DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_ID,
    DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD,
    DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD,
} from '../../../../references/wokflow/VisitPlannerAction';
import { getDetail } from '../../../../service/WorkflowActionManager';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FieldSelect } from '../../../FieldSelect';
import { HintedSelect } from '../Form';
import Hint from '../../../Hint';

class OutSection extends React.PureComponent {
    prepareEntityOptions() {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={dataSource.id + '.' + entity.id} value={entity.id} data-ds={dataSource.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    }

    editableFieldFilter = (field) => {
        return !field.isReadOnly || field.lookupData !== null;
    };

    renderEntityValue = (entityId) => {
        if (entityId) {
            for (let dataSource of this.props.dataSources) {
                for (let entity of dataSource.entities) {
                    if (entity.id === entityId) {
                        return `${dataSource.name} > ${entity.label}`;
                    }
                }
            }
        }
        return '';
    };

    render() {
        const { t, currentAction, errors, onDetailChange, updateDetailCallback, accountId } = this.props;

        const outDataSourceId = getDetail(DETAIL_PLANNER_OUT_ENTITY_DS_ID, currentAction.details);
        const outEntityIdId = getDetail(DETAIL_PLANNER_OUT_ENTITY_ID, currentAction.details);
        const outObjectEnabled = !!getDetail(DETAIL_PLANNER_OUT_ENTITY_CREATE, currentAction.details);
        const outObjectSelected = outEntityIdId || outEntityIdId;

        return (
            <Grid item container xs={12} spacing={1} alignItems={'flex-end'}>
                <Grid item container xs={12}>
                    <Grid item xs={3}>
                        <FormControl
                            style={{ paddingTop: 16 }}
                            fullWidth
                            error={errors.has(DETAIL_PLANNER_CREATE_ROUTES)}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={getDetail(DETAIL_PLANNER_CREATE_ROUTES, currentAction.details, false)}
                                        name={DETAIL_PLANNER_CREATE_ROUTES}
                                        onChange={onDetailChange}
                                        color="primary"
                                    />
                                }
                                label={
                                    <React.Fragment>
                                        <span>{t('workflow_actions.form.visit_planner.create_routes')}</span>
                                        <Hint
                                            TooltipProps={{ className: 'tooltip-question' }}
                                            iProps={{ style: { marginLeft: 8 } }}
                                        >
                                            {t('workflow_actions.form.visit_planner.create_routes.tooltip')}
                                        </Hint>
                                    </React.Fragment>
                                }
                            />
                            {errors.has(DETAIL_PLANNER_CREATE_ROUTES) && (
                                <FormHelperText>{errors.get(DETAIL_PLANNER_CREATE_ROUTES)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            style={{ paddingTop: 16 }}
                            fullWidth
                            error={errors.has(DETAIL_PLANNER_OUT_ENTITY_CREATE)}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_CREATE,
                                            currentAction.details,
                                            false,
                                        )}
                                        name={DETAIL_PLANNER_OUT_ENTITY_CREATE}
                                        onChange={onDetailChange}
                                    />
                                }
                                label={
                                    <React.Fragment>
                                        <span>{t('workflow_actions.form.visit_planner.out.create')}</span>
                                        <Hint
                                            TooltipProps={{ className: 'tooltip-question' }}
                                            iProps={{ style: { marginLeft: 8 } }}
                                        >
                                            {t('workflow_actions.form.visit_planner.out.create.tooltip')}
                                        </Hint>
                                    </React.Fragment>
                                }
                            />
                            {errors.has(DETAIL_PLANNER_OUT_ENTITY_CREATE) && (
                                <FormHelperText>{errors.get(DETAIL_PLANNER_OUT_ENTITY_CREATE)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Collapse
                        style={{ width: '100%' }}
                        in={getDetail(DETAIL_PLANNER_OUT_ENTITY_CREATE, currentAction.details, false)}
                    >
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_OUT_ENTITY_ID)}>
                                    <InputLabel required={outObjectEnabled}>
                                        {t('workflow_actions.form.visit_planner.out.object')}
                                    </InputLabel>
                                    <HintedSelect
                                        fullWidth
                                        disabled={!outObjectEnabled}
                                        name={DETAIL_PLANNER_OUT_ENTITY_ID}
                                        value={outEntityIdId || ''}
                                        onChange={onDetailChange}
                                        endAdornment={
                                            <Hint TooltipProps={{ className: 'tooltip-question' }}>
                                                {t('workflow_actions.form.visit_planner.out.object.tooltip')}
                                            </Hint>
                                        }
                                        renderValue={this.renderEntityValue}
                                        data-testid="workflow_actions.form.visit_planner.out.object"
                                    >
                                        {this.prepareEntityOptions()}
                                    </HintedSelect>
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_ID) && (
                                        <FormHelperText>{errors.get(DETAIL_PLANNER_OUT_ENTITY_ID)}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD)}>
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.user_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.user_field.tooltip')}
                                        filter={this.editableFieldFilter}
                                        data-testid="workflow_actions.form.visit_planner.out.user_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_USER_ID_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.has(DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD)}
                                >
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.planner_api_name_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        required={outObjectEnabled}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD)}
                                        hint={t(
                                            'workflow_actions.form.visit_planner.out.planner_api_name_field.tooltip',
                                        )}
                                        filter={this.editableFieldFilter}
                                        data-testid="workflow_actions.form.visit_planner.out.planner_api_name_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_PLANNER_API_NAME_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD)}>
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.record_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        required={outObjectEnabled}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.record_field.tooltip')}
                                        filter={this.editableFieldFilter}
                                        data-testid="workflow_actions.form.visit_planner.out.record_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_RECORD_ID_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.has(DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD)}
                                >
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.start_datetime_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        required={outObjectEnabled}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.start_datetime_field.tooltip')}
                                        filter={(field) =>
                                            !field.isReadOnly &&
                                            (field.type === 'string' ||
                                                field.type === 'text' ||
                                                field.type === 'datetime')
                                        }
                                        data-testid="workflow_actions.form.visit_planner.out.start_datetime_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_START_DATETIME_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD)}>
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.end_datetime_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        required={outObjectEnabled}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.end_datetime_field.tooltip')}
                                        filter={(field) =>
                                            !field.isReadOnly &&
                                            (field.type === 'string' ||
                                                field.type === 'text' ||
                                                field.type === 'datetime')
                                        }
                                        data-testid="workflow_actions.form.visit_planner.out.end_datetime_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_END_DATETIME_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={errors.has(DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD)}>
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.date_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD, currentAction.details)}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.date_field.tooltip')}
                                        filter={this.editableFieldFilter}
                                        data-testid="workflow_actions.form.visit_planner.out.date_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_DATE_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.has(DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD)}
                                >
                                    <FieldSelect
                                        label={t('workflow_actions.form.visit_planner.out.visit_duration_field')}
                                        accountId={accountId}
                                        disabled={!outObjectEnabled || !outObjectSelected}
                                        dataSourceId={outDataSourceId}
                                        entityId={outEntityIdId}
                                        onChange={(value) =>
                                            updateDetailCallback(
                                                DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD,
                                                value ? value.apiName : null,
                                            )
                                        }
                                        value={getDetail(
                                            DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD,
                                            currentAction.details,
                                        )}
                                        error={errors.has(DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD)}
                                        hint={t('workflow_actions.form.visit_planner.out.visit_duration_field.tooltip')}
                                        filter={(field) =>
                                            !field.isReadOnly &&
                                            ['smallint', 'integer', 'bigint', 'string', 'text'].includes(field.type)
                                        }
                                        data-testid="workflow_actions.form.visit_planner.out.visit_duration_field"
                                        disableNotIncluded
                                    />
                                    {errors.has(DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD) && (
                                        <FormHelperText>
                                            {errors.get(DETAIL_PLANNER_OUT_ENTITY_VISIT_DURATION_FIELD)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        );
    }
}

OutSection.propTypes = {
    currentAction: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    updateDetailCallback: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    dataSources: PropTypes.array.isRequired,
};

const TranslatedOutSection = withTranslation('translations')(OutSection);
export { TranslatedOutSection as OutSection };
