import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { GEO_FIELDS } from '../../references/geoFields';
import { MultipleSelect } from '../Controls';
import {
    BooleanFilterCell,
    DateFilterCell,
    DateTimeFilterCell,
    MultiPicklistFilterCell,
    NumericFilterCell,
    PicklistFilterCell,
    StringFilterCell,
    TerritoriesFilterCell,
} from '../DataTable';

const frontGeocodeStatuses = {
    any: 'any',
    okExact: 'okExact',
    okApprox: 'okApprox',
    okImported: 'okImported',
    invalid: 'invalid',
    noAddress: 'noAddress',
    processing: 'processing',
};

class ProcessingStatusFilterCell extends React.PureComponent {
    constructor(props) {
        super(props);

        this.frontGeocodeStatusesLabels = {
            okExact: props.t('entity_data_table.front_geocode_statuses_labels.okExact'),
            okApprox: props.t('entity_data_table.front_geocode_statuses_labels.okApprox'),
            okImported: props.t('entity_data_table.front_geocode_statuses_labels.okImported'),
            invalid: props.t('entity_data_table.front_geocode_statuses_labels.invalid'),
            noAddress: props.t('entity_data_table.front_geocode_statuses_labels.noAddress'),
            processing: props.t('entity_data_table.front_geocode_statuses_labels.processing'),
        };
    }

    handleFilter = (e) => {
        const value = e.target.value;
        this.props.onFilter(value.length > 0 ? { value, operation: 'in' } : null);
    };

    renderMenuItem = (status) => {
        const { filter } = this.props;
        return (
            <MenuItem value={status}>
                <Checkbox
                    checked={filter && filter.value ? filter.value.indexOf(status) > -1 : false}
                    color="primary"
                />
                {this.frontGeocodeStatusesLabels[status]}
            </MenuItem>
        );
    };

    handleRenderSelected = (selected) => {
        if (!selected.length) {
            return this.props.t('entity_data_table.search_data_table.any');
        }

        return selected.map((value) => this.frontGeocodeStatusesLabels[value]).join(', ');
    };

    render() {
        const { filter, classes, filteringEnabled } = this.props;
        const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
        const { t, i18n, tReady, ...restProps } = this.props;
        return (
            <TableFilterRow.Cell {...restProps} className={classes.cell} style={style}>
                <MultipleSelect
                    value={filter ? filter.value : []}
                    displayEmpty
                    onChange={this.handleFilter}
                    fullWidth
                    disabled={!filteringEnabled}
                    renderValue={this.handleRenderSelected}
                    data-testid="tooltip_cell"
                >
                    {this.renderMenuItem(frontGeocodeStatuses.okExact)}
                    {this.renderMenuItem(frontGeocodeStatuses.okApprox)}
                    {this.renderMenuItem(frontGeocodeStatuses.okImported)}
                    {this.renderMenuItem(frontGeocodeStatuses.invalid)}
                    {this.renderMenuItem(frontGeocodeStatuses.noAddress)}
                    {this.renderMenuItem(frontGeocodeStatuses.processing)}
                </MultipleSelect>
            </TableFilterRow.Cell>
        );
    }
}

const TranslatedProcessingStatusFilterCell = withTranslation()(ProcessingStatusFilterCell);

const FilterCell = (props) => {
    const { column } = props;

    if (column.name === GEO_FIELDS.STATUS) {
        return <TranslatedProcessingStatusFilterCell {...props} />;
    }
    if (column.type === 'text[]') {
        return <MultiPicklistFilterCell {...props} />;
    }
    if (column.picklist && column.picklist.length) {
        return <PicklistFilterCell {...props} />;
    }
    if (column.name === 'mapsly_territories') {
        return <TerritoriesFilterCell {...props} />;
    }
    if (column.type === 'boolean') {
        return <BooleanFilterCell {...props} />;
    }
    if (column.type === 'integer' || column.type === 'bigint' || column.type === 'float') {
        return <NumericFilterCell {...props} />;
    }
    if (column.type === 'date') {
        return <DateFilterCell {...props} />;
    }
    if (column.type === 'datetime') {
        return <DateTimeFilterCell {...props} />;
    }
    return <StringFilterCell {...props} />;
};

const rightIconCellStyles = {
    flexContainer: {
        flexFlow: 'row-reverse',
    },
};

export const StyledFilterCell = withStyles(rightIconCellStyles)(FilterCell);
