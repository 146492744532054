import apiRoutes, { reverse } from 'api/apiRoutes';
import { Filter } from '../components/utils/tableFilter';
import { Sorting } from '@devexpress/dx-react-grid';
import { EmptySchedule, Schedule } from '../components/Workflow/Schedule/types';
import WorkflowManager, { NAMESPACE_GLOBAL } from './WorkflowManager';
import { CallContext } from '../components/utils/CallContext';

export class ScheduleManager extends WorkflowManager {
    static getDefaultSchedule(entity = null, namespace = NAMESPACE_GLOBAL): EmptySchedule {
        return {
            id: null,
            namespace,
            callContext: CallContext.create(entity),
            apiName: null,
            name: null,
            isActive: true,
            disabled: false,
            disabledEntities: [],
            disabledFields: [],
            startDateTime: null,
            frequency: null,
            daysOfWeek: null,
            cronExpression: null,
            groups: [],
            updatedBy: null,
        };
    }

    get(accountId: number | string, scheduleId: number | string): Promise<Schedule> {
        const url = reverse(apiRoutes.account.workflow.schedules.item, { accountId, scheduleId });
        return this.requestApi(url, 'GET').then((schedule: Schedule) => {
            return this.denormalizeSchedule(schedule);
        });
    }

    list(accountId: number | string, filters: Filter[], sorting: Sorting[], page: number, pageSize: number) {
        const url = reverse(apiRoutes.account.workflow.schedules, { accountId });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize }).then((data: any) => {
            return {
                ...data,
                items: data.items.map((schedule: Schedule) => this.denormalizeSchedule(schedule)),
            };
        });
    }

    save(accountId: number | string, schedule: Schedule): Promise<Schedule> {
        if (!schedule.id) {
            const url = reverse(apiRoutes.account.workflow.schedules, { accountId });
            return this.requestApi(url, 'POST', this.normalizeSchedule(schedule)).then((schedule: any) => {
                return this.denormalizeSchedule(schedule);
            });
        }

        const url = reverse(apiRoutes.account.workflow.schedules.item, { accountId, scheduleId: schedule.id });
        return this.requestApi(url, 'PATCH', this.normalizeSchedule(schedule)).then((schedule: any) => {
            return this.denormalizeSchedule(schedule);
        });
    }

    delete(accountId: number | string, scheduleId: number) {
        const url = reverse(apiRoutes.account.workflow.schedules.item, { accountId, scheduleId });
        return this.requestApi(url, 'DELETE');
    }

    normalizeSchedule(schedule: Schedule) {
        return {
            ...schedule,
            groups: this.normalizeGroups(schedule.groups),
            callContext: this.normalizeCallContext(schedule.callContext),
        };
    }

    denormalizeSchedule(schedule: Schedule) {
        return {
            ...schedule,
            groups: this.denormalizeGroups(schedule.groups),
            callContext: this.denormalizeCallContext(schedule.callContext),
        };
    }
}

export const scheduleManager = new ScheduleManager();
