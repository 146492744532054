import { makeAutoObservable } from 'mobx';
import { Routing } from 'interfaces';

class RoutingSettingsSkillsFormManager {
    private _skills: Routing.Settings.Skills = [];
    private _validation: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    initSettings(skillsData: Routing.Settings.SkillsData) {
        this._skills = skillsData.skills;
        this._validation = skillsData.validation;
    }

    addSkill(data: string) {
        this._skills.push(data);
    }

    deleteSkill(data: string) {
        this._skills.splice(this._skills.indexOf(data), 1);
    }

    get skills(): Routing.Settings.Skills {
        return this._skills;
    }

    setValidation(value: boolean) {
        this._validation = value;
    }

    get validation(): boolean {
        return this._validation;
    }

    getFormData() {
        return {
            skills: this._skills,
            validation: this._validation,
        };
    }
}

export default RoutingSettingsSkillsFormManager;
