import apiRoutes from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { UserData } from './types';
import moment from 'moment-timezone';
import { DATETIME_FORMAT } from '../utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { ColorGenerator } from './ColorGenerator';
import { LineCapShape } from 'leaflet';
import { LineStyle, LineStyleGenerator } from './LineStyleGenerator';
import { Path } from '../components/types';
import hash from 'object-hash';
import { userManager } from './UserManager';
import timezones from '../references/timezones';
import dispatcher from './dispatcher';
import events from '../events';

type RequestPathsPayloadType = {
    requestId: string | null;
    users: Array<number>;
    dateStart: string | null;
    dateEnd: string | null;
    time: number | null;
    dates: Array<{
        dateStart: string;
        dateEnd: string;
    }> | null;
};

type StyleConfig = {
    color: string;
    dashArray: string;
    lineCap: LineCapShape;
    width: number;
    opacity: number;
};

type StylesConfig = {
    [userId: number]: {
        [date: string]: StyleConfig;
    };
};
type LocationHistoryManagerKeys = '_isPathsModeEnabled';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'ha';
const HOUR_FORMAT = 'H';

class LocationHistoryManager extends BackendService {
    private _pathsMap: Map<number, Map<string, Array<Path>>> = new Map();
    private _styles: StylesConfig = {};
    private _currentRequestId: string | null = null;
    private _isPathsModeEnabled: boolean = false;

    private _multiselectMode: boolean = false;
    private _intervalMode: boolean = false;
    private _selectedUser: UserData | null = null;
    private _selectedUsers: UserData[] = [];
    private _selectedDate: string | null = null;
    private _selectedDates: string[] = [];
    private _selectedHour: string | null = null;
    private _selectedDatetimeIntervalStart: string | null = null;
    private _selectedDatetimeIntervalEnd: string | null = null;

    constructor() {
        super();
        makeObservable<LocationHistoryManager, LocationHistoryManagerKeys>(this, {
            _isPathsModeEnabled: observable,
            isPathsModeEnabled: computed,
            setPathsModeEnabled: action,
        });
        dispatcher.subscribe(
            [events.EVENT_USER_LOGIN, events.EVENT_USER_SSO_LOGIN, events.EVENT_USER_SWITCH],
            this,
            () => {
                this.reset();
            },
        );
    }

    reset() {
        this._pathsMap.clear();
        this._styles = {};
        this._currentRequestId = null;
        this._isPathsModeEnabled = false;
        this._multiselectMode = false;
        this._intervalMode = false;
        this._selectedUser = null;
        this._selectedUsers = [];
        this._selectedDate = null;
        this._selectedDates = [];
        this._selectedHour = null;
        this._selectedDatetimeIntervalStart = null;
        this._selectedDatetimeIntervalEnd = null;
    }

    requestPathsMap() {
        const payload: RequestPathsPayloadType = {
            users: [],
            dateStart: null,
            dateEnd: null,
            dates: null,
            time: null,
            requestId: null,
        };

        let currentTimezone = timezones.find((timezone) => timezone.name === userManager.getTimezone());
        if (!currentTimezone) {
            currentTimezone = timezones.find((timezone) => timezone.name === 'UTC');
        }

        if (this.intervalMode) {
            payload.dateStart = this.selectedDatetimeIntervalStart;
            payload.dateEnd = this.selectedDatetimeIntervalEnd;
        } else {
            if (this.multiselectMode) {
                const dates: { dateStart: string; dateEnd: string }[] | null = [];
                this.selectedDates.forEach((date) => {
                    const dateStart = moment
                        // @ts-expect-error
                        .tz(date + ' ' + this.selectedHour, DATE_FORMAT + ' ' + TIME_FORMAT, currentTimezone.name)
                        .utc()
                        .format(DATETIME_FORMAT);
                    const dateEnd = moment
                        // @ts-expect-error
                        .tz(date + ' ' + this.selectedHour, DATE_FORMAT + ' ' + TIME_FORMAT, currentTimezone.name)
                        .add(1, 'days')
                        .utc()
                        .format(DATETIME_FORMAT);
                    dates.push({ dateStart, dateEnd });
                });
                if (this.selectedHour) {
                    payload.time = parseInt(
                        // @ts-expect-error
                        moment.tz(this.selectedHour, TIME_FORMAT, currentTimezone.name).utc().format(HOUR_FORMAT),
                    );
                }
                payload.dates = dates;
            } else {
                payload.dateStart = moment
                    .tz(
                        this.selectedDate + ' ' + this.selectedHour,
                        DATE_FORMAT + ' ' + TIME_FORMAT,
                        // @ts-expect-error
                        currentTimezone.name,
                    )
                    .utc()
                    .format(DATETIME_FORMAT);
                payload.dateEnd = moment
                    .tz(
                        this.selectedDate + ' ' + this.selectedHour,
                        DATE_FORMAT + ' ' + TIME_FORMAT,
                        // @ts-expect-error
                        currentTimezone.name,
                    )
                    .add(1, 'days')
                    .utc()
                    .format(DATETIME_FORMAT);
            }
        }

        if (this.multiselectMode) {
            payload.users = this.selectedUsers.map((user) => user.id);
        } else if (this.selectedUser) {
            payload.users = [this.selectedUser.id];
        }

        this._currentRequestId = hash.MD5(JSON.stringify(payload));
        payload.requestId = this._currentRequestId;
        return this.requestApi(apiRoutes.tracking.getPaths, 'POST', payload).catch((e: Error) => {
            this.clearCurrentRequestId();
            throw e;
        });
    }

    disablePathsMode(): void {
        this.setPathsModeEnabled(false);
        this._pathsMap = new Map();
        this._styles = {};
    }

    setupStyles(): StylesConfig {
        const styles: StylesConfig = {};
        const colorGenerator = new ColorGenerator();
        const lineStyleGenerator = new LineStyleGenerator();
        let singleUser = false;
        if (this._pathsMap.size === 1) {
            singleUser = true;
        }
        let color: string;
        let lineStyle: LineStyle;
        if (singleUser || this.intervalMode) {
            lineStyle = lineStyleGenerator.getLineStyle();
        }
        const dateLineStyleMap = new Map();
        this._pathsMap.forEach((datesMap, userId) => {
            if (!singleUser || this.intervalMode) {
                color = colorGenerator.getColor(); // if multiple users set new color for each user
            }
            styles[userId] = {};
            datesMap.forEach((_paths, date) => {
                if (!this.intervalMode) {
                    // if interval mode every date should have same linestyle
                    if (singleUser) {
                        color = colorGenerator.getColor(); // if single user set new color for each date
                    } else {
                        // if multiple users set new style for each date, but keep user's color
                        if (dateLineStyleMap.has(date)) {
                            lineStyle = dateLineStyleMap.get(date);
                        } else {
                            lineStyle = lineStyleGenerator.getLineStyle();
                            dateLineStyleMap.set(date, lineStyle);
                        }
                    }
                }
                const style: StyleConfig = {
                    color,
                    dashArray: lineStyle.dashArray,
                    lineCap: lineStyle.lineCap,
                    width: lineStyle.width,
                    opacity: 0.8,
                };
                styles[userId][date] = style;
            });
        });
        return styles;
    }

    enablePathsMode(rawPathsMap: Array<any>): void {
        this.setPathsModeEnabled(false);
        this._pathsMap = this.getPathsFromResponse(rawPathsMap);
        this._styles = this.setupStyles();
        this.setPathsModeEnabled(true);
    }

    private getPathsFromResponse(rawPathsMap: any): Map<number, Map<string, Array<Path>>> {
        const paths: Map<number, Map<string, Array<Path>>> = new Map();
        for (const userId in rawPathsMap) {
            const datesMap = new Map();
            for (const date in rawPathsMap[userId]) {
                const datePaths = [];
                for (const segmentIndex in rawPathsMap[userId][date]) {
                    const segment = rawPathsMap[userId][date][segmentIndex];
                    if (!segment) {
                        continue;
                    }
                    const legs = segment.legs.map((_leg: any, i: number) => {
                        const shapes = [],
                            legPoints = segment.points.slice(segment.legIndexes[i], segment.legIndexes[i + 1]);

                        let positions = [],
                            iterations = legPoints.length;
                        for (let point of legPoints) {
                            positions.push([point.lat, point.lng]);
                            iterations--;
                            if (iterations === 0 && positions.length >= 2) {
                                shapes.push({ positions });
                            }
                        }
                        return {
                            index: i,
                            shapes,
                        };
                    });
                    segment.legs = legs;
                    rawPathsMap[userId][date][segmentIndex] = segment;

                    let path: Path = {
                        legs,
                        points: segment.points,
                        legIndexes: segment.legIndexes,
                        timestampFrom: segment.timestampFrom,
                        timestampTo: segment.timestampTo,
                        distance: segment.distance,
                    };
                    datePaths.push(path);
                }
                datesMap.set(date, datePaths);
            }
            paths.set(parseInt(userId), datesMap);
        }
        return paths;
    }

    clearCurrentRequestId(): void {
        this._currentRequestId = null;
    }

    get currentRequestId(): string | null {
        return this._currentRequestId;
    }

    get styles(): StylesConfig {
        return this._styles;
    }

    get isPathsModeEnabled(): boolean {
        return this._isPathsModeEnabled;
    }

    get pathsMap(): Map<number, Map<string, Array<Path>>> {
        return this._pathsMap;
    }

    get multiselectMode(): boolean {
        return this._multiselectMode;
    }

    set multiselectMode(value: boolean) {
        this._multiselectMode = value;
    }

    get intervalMode(): boolean {
        return this._intervalMode;
    }

    set intervalMode(value: boolean) {
        this._intervalMode = value;
    }

    get selectedUser(): UserData | null {
        return this._selectedUser;
    }

    set selectedUser(value: UserData | null) {
        this._selectedUser = value;
    }

    get selectedUsers(): UserData[] {
        return this._selectedUsers;
    }

    set selectedUsers(value: UserData[]) {
        this._selectedUsers = value;
    }

    get selectedDate(): string | null {
        return this._selectedDate;
    }

    set selectedDate(value: string | null) {
        this._selectedDate = value;
    }

    get selectedDates(): string[] {
        return this._selectedDates;
    }

    set selectedDates(value: string[]) {
        this._selectedDates = value;
    }

    get selectedHour(): string | null {
        return this._selectedHour;
    }

    set selectedHour(value: string | null) {
        this._selectedHour = value;
    }

    get selectedDatetimeIntervalStart(): string | null {
        return this._selectedDatetimeIntervalStart;
    }

    set selectedDatetimeIntervalStart(value: string | null) {
        this._selectedDatetimeIntervalStart = value;
    }

    get selectedDatetimeIntervalEnd(): string | null {
        return this._selectedDatetimeIntervalEnd;
    }

    set selectedDatetimeIntervalEnd(value: string | null) {
        this._selectedDatetimeIntervalEnd = value;
    }

    setPathsModeEnabled(enabled: boolean) {
        this._isPathsModeEnabled = enabled;
    }

    shouldShowLegend(): boolean {
        if (!this.isPathsModeEnabled) {
            return false;
        }
        const userIds = Object.keys(this.styles);
        // @ts-ignore – accessing first array item by 0
        const firstUserDates = Object.keys(this.styles[userIds[0]]);
        if (userIds.length === 1 && (firstUserDates.length === 1 || this.intervalMode)) {
            return false;
        }
        return true;
    }

    public getFilteredPaths(userIdsFilter?: number[], datesFilter?: string[]): Path[] {
        const routesPaths: Path[] = [];
        for (const userId of this.pathsMap.keys()) {
            if (userIdsFilter && !userIdsFilter.includes(userId)) {
                continue;
            }

            const pathsUserDates = this.pathsMap.get(userId)!;
            for (const date of pathsUserDates.keys()) {
                if (datesFilter && !datesFilter.includes(date)) {
                    continue;
                }

                const pathsUserDateRoutes = pathsUserDates.get(date)!;
                routesPaths.push(...pathsUserDateRoutes);
            }
        }

        return routesPaths;
    }
}

const locationHistoryManager = new LocationHistoryManager();
export { locationHistoryManager, DATE_FORMAT, TIME_FORMAT };
