import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Hint from '../../Hint';
import LandingLink, { ARTICLE_BUTTON } from '../../HelpLink/LandingLink';
import { Workflow } from '../../../interfaces';

interface CurrentProps extends WithTranslation {
    button: Workflow.Button;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

class Location extends React.PureComponent<CurrentProps> {
    render() {
        const { button, onChange } = this.props;
        const showSelectedLocationCheckbox = button && button.locations.includes(Workflow.Locations.MAP_CLICK);

        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="isUserLocationAvailable"
                            checked={button.isUserLocationAvailable}
                            onChange={onChange}
                            color="primary"
                            data-testid="workflow_buttons.form.is_user_location_available"
                        />
                    }
                    label={
                        <span>
                            <strong>{this.props.t('workflow_buttons.form.isUserLocationAvailable')}</strong>{' '}
                            <Hint>{this.props.t('workflow_buttons.form.isUserLocationAvailable.hint')}</Hint>
                        </span>
                    }
                />
                {showSelectedLocationCheckbox && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isSelectedLocationAvailable"
                                checked={button.isSelectedLocationAvailable}
                                onChange={onChange}
                                color="primary"
                                data-testid="workflow_buttons.form.is_selected_location_available"
                            />
                        }
                        label={
                            <span>
                                <strong>{this.props.t('workflow_buttons.form.isSelectedLocationAvailable')}</strong>{' '}
                                <LandingLink article={ARTICLE_BUTTON}>
                                    <Hint iProps={{ style: { color: 'inherit' } }}>
                                        {this.props.t('workflow_buttons.form.isSelectedLocationAvailable.hint')}
                                    </Hint>
                                </LandingLink>
                            </span>
                        }
                    />
                )}
            </FormGroup>
        );
    }
}

export default withTranslation()(Location);
