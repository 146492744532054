// @ts-ignore
import mbxClient from '@mapbox/mapbox-sdk';
// @ts-ignore
import mbxIsochron from '@mapbox/mapbox-sdk/services/isochrone';
import Geocoding, {
    GeocodeProperties,
    GeocodeRequest,
    GeocodeResponse,
    GeocodeService,
} from '@mapbox/mapbox-sdk/services/geocoding';
import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import { Bounds, GeocoderResultStatus } from 'components/types';
import { AutocompleteResult } from 'references/autocomplete';
import { Geo } from 'interfaces';

interface IMapBoxApi extends Pick<MapBoxApi, keyof MapBoxApi> {}

const APART_SLASH_HOUSE_REGEX = /^\s*\d+\/(?=\d+)/;

const mapGeocodeResponse = ({ body }: { body: GeocodeResponse }, language: string): AutocompleteResult => ({
    status: GeocoderResultStatus.OK,
    error: null,
    predictions: body.features.map((feature) => {
        const localizedAddress: string =
            // @ts-expect-error
            feature[`matching_place_name_${language}`] ?? feature[`place_name_${language}`];
        const country =
            feature.context?.find(({ id }) => id.split('.')[0] === 'country') ?? feature.place_type?.includes('country')
                ? feature.properties
                : (undefined as GeocodeProperties | undefined);

        return {
            placeId: feature.id,
            description: feature.place_name,
            data: {
                status: GeocoderResultStatus.OK,
                lat: feature.center[1],
                lng: feature.center[0],
                address: localizedAddress ?? feature.matching_place_name ?? feature.place_name,
                addressData: {
                    countryShort: country?.short_code?.toUpperCase() ?? null,
                },
            },
        };
    }),
});

/**
 * The bounding box cannot cross the 180th meridian
 * @link https://docs.mapbox.com/api/search/geocoding/
 */
const limitBounds = (bounds: Bounds): Bounds => ({
    minLat: Math.max(bounds.minLat, -90),
    minLng: Math.max(bounds.minLng, -180),
    maxLat: Math.min(bounds.maxLat, 90),
    maxLng: Math.min(bounds.maxLng, 180),
});

class MapBoxApi {
    private readonly client: MapiClient;
    private isochroneService: any | undefined; // no types for isochrones in @types/mapbox__mapbox-sdk@0.13.2
    private geocodeService: GeocodeService | undefined;

    constructor(accessToken: string) {
        this.client = mbxClient({ accessToken });
    }

    public makeIsochronesPolygons(properties: { minutes: any; colors: any; profile: any; coordinates: any }) {
        this.isochroneService = this.isochroneService ?? mbxIsochron(this.client);

        return this.isochroneService
            .getContours({
                minutes: properties.minutes,
                colors: properties.colors,
                profile: properties.profile,
                coordinates: properties.coordinates,
                generalize: 0.01,
            })
            .send();
    }

    public autocomplete(
        term: string,
        basePoint: Geo.GeoPoint,
        language: string,
        bounds?: Bounds,
    ): Promise<AutocompleteResult> {
        this.geocodeService = this.geocodeService ?? Geocoding(this.client);

        const geocodeRequest: GeocodeRequest = {
            query: term.replace(APART_SLASH_HOUSE_REGEX, ''),
            proximity: [basePoint.lng, basePoint.lat],
            autocomplete: true,
            // fuzzyMatch: true,
            mode: 'mapbox.places',
            limit: 10,
            language: [language],
            // types: ['address'],
        };

        if (bounds !== undefined) {
            const limitedBounds = limitBounds(bounds);
            geocodeRequest.bbox = [
                limitedBounds.minLng,
                limitedBounds.minLat,
                limitedBounds.maxLng,
                limitedBounds.maxLat,
            ];
        }

        const request = this.geocodeService.forwardGeocode(geocodeRequest);

        return request.send().then((response: MapiResponse) => mapGeocodeResponse(response, language));
    }
}

class MapBoxApiFactory {
    private apiMap: Map<string, MapBoxApi> = new Map<string, MapBoxApi>();

    public getApi(apiKey: string): IMapBoxApi {
        if (!this.apiMap.has(apiKey)) {
            this.apiMap.set(apiKey, new MapBoxApi(apiKey));
        }

        return this.apiMap.get(apiKey)!;
    }
}

export default new MapBoxApiFactory();
