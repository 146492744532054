import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '../Backdrop';
import cloneDeep from 'lodash/cloneDeep';
import { Grid, MenuItem, Select, Tooltip } from '@material-ui/core';
import { PERMISSION_HIDE, PERMISSION_MODIFY, PERMISSION_VIEW } from './constants';
import { withTranslation } from 'react-i18next';

class FieldsForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            settings: cloneDeep(props.settings),
        };
    }

    componentDidMount() {
        this.props.handlePreloadInit && this.props.handlePreloadInit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fields === null && this.props.fields !== null) {
            this.props.handlePreloadSuccess && this.props.handlePreloadSuccess();
        }
    }

    submit() {
        this.props.onSubmitSuccess(this.state.settings);
    }

    onChangeField = (field) => (e) => {
        const fields = field.groupLookupFields ? field.groupLookupFields : [field];
        const settings = {};
        fields.forEach((field) => {
            settings[field.id] = e.target.value;
        });

        this.setState((state) => {
            return {
                settings: { ...state.settings, ...settings },
            };
        });
    };

    groupLookupFields = (fields) => {
        const groupedFields = new Map();
        for (let field of fields) {
            if (field.lookupData === null) {
                groupedFields.set(field.apiName, field);
                continue;
            }

            const groupedField = groupedFields.get(field.originalApiName);
            if (!groupedField) {
                groupedFields.set(field.originalApiName, {
                    ...field,
                    label: field.label.replace(/\s\((ID|NAME|TYPE)\)$/, ''),
                    groupLookupFields: [field],
                });
            } else {
                groupedField.groupLookupFields.push(field);
                groupedFields.set(field.originalApiName, groupedField);
            }
        }

        return [...groupedFields.values()];
    };

    getVisibility = (settings, defaultVisibility, field) => {
        const fields = field.groupLookupFields ? field.groupLookupFields : [field];
        const arrayVisibility = [];
        for (let item of fields) {
            let visibility = settings[item.id] === undefined ? defaultVisibility : settings[item.id];
            if (item.isReadOnly && visibility === PERMISSION_MODIFY) {
                visibility = PERMISSION_VIEW;
            }
            arrayVisibility.push(visibility);
        }
        return Math.max(...arrayVisibility);
    };

    isShowSelectItemPermissionModify = (field) => {
        const fields = field.groupLookupFields ? field.groupLookupFields : [field];
        return fields.some((field) => !field.isReadOnly);
    };

    render() {
        const { fields, defaultVisibility, disableEdit, t } = this.props;

        let groupedFields = null;
        if (fields !== null) {
            groupedFields = this.groupLookupFields(fields);
        }

        const { settings } = this.state;
        return (
            <Backdrop loading={groupedFields === null}>
                <Grid container direction="row">
                    <Grid
                        item
                        style={{
                            padding: '8px 0 0 12px',
                            width: '180px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'gray',
                        }}
                    >
                        {t('field')}
                    </Grid>
                    <Grid item style={{ padding: '8px', minWidth: '110px', color: 'gray' }}>
                        {t('permission.field_form.permission')}
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    {groupedFields &&
                        groupedFields.map((field) => {
                            const visibility = this.getVisibility(settings, defaultVisibility, field);

                            return (
                                <Grid container key={field.id} style={{ marginTop: '16px' }}>
                                    <Grid
                                        item
                                        style={{
                                            padding: '12px 0 0 12px',
                                            width: '180px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Tooltip title={field.label}>
                                            <span>{field.label}</span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item style={{ padding: '8px', minWidth: '110px' }}>
                                        <Select
                                            value={visibility}
                                            onChange={this.onChangeField(field)}
                                            className="c-permission__profile-field"
                                            data-testid="permissions.fields_form.select"
                                        >
                                            <MenuItem
                                                value={PERMISSION_HIDE}
                                                data-testid="permissions.fields_form.select.hide"
                                            >
                                                {t('permissions.fields_form.select.hide')}
                                            </MenuItem>
                                            <MenuItem
                                                value={PERMISSION_VIEW}
                                                data-testid="permissions.fields_form.select.view"
                                            >
                                                {t('permissions.fields_form.select.view')}
                                            </MenuItem>
                                            {this.isShowSelectItemPermissionModify(field) && (
                                                <MenuItem
                                                    value={PERMISSION_MODIFY}
                                                    disabled={!!disableEdit}
                                                    data-testid="permissions.fields_form.select.modify"
                                                >
                                                    {t('permissions.fields_form.select.modify')}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Backdrop>
        );
    }
}

FieldsForm.propTypes = {
    defaultVisibility: PropTypes.number.isRequired,
    fields: PropTypes.array,
    settings: PropTypes.object.isRequired,
    onSubmitSuccess: PropTypes.func,
    disableEdit: PropTypes.string,
};

export default withTranslation('translations', { withRef: true })(FieldsForm);
