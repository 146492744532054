import i18n from 'i18next';
import { Routing } from 'interfaces';
import RoutingSettingsMappingFormManager from './RoutingSettingsMappingFormManager';
import { makeObservable, override } from 'mobx';
import { parse } from 'date-fns';
import { ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG } from './config';
import apiRoutes, { reverse } from '../../../api/apiRoutes';
import { RouteSettingsValidateResponse } from '../../../interfaces/api/route';

const t = i18n.t.bind(i18n);

const defaultMappingUser: Routing.Settings.MappingForm = new Map();

class RoutingSettingsMappingUserFormManager extends RoutingSettingsMappingFormManager {
    constructor() {
        super();
        makeObservable(this, {
            validate: override,
        });
    }

    getDefaultMapping(): Routing.Settings.MappingForm {
        return defaultMappingUser;
    }

    async validate(): Promise<boolean> {
        this._mappingErrors = new Map();
        this._currentObjectError = null;

        // Check start location
        this.checkCoordinates(
            Routing.Settings.MappingUserKey.START_LOCATION_LATITUDE,
            Routing.Settings.MappingUserKey.START_LOCATION_LONGITUDE,
        );
        // Check finish location
        this.checkCoordinates(
            Routing.Settings.MappingUserKey.FINISH_LOCATION_LATITUDE,
            Routing.Settings.MappingUserKey.FINISH_LOCATION_LONGITUDE,
        );
        this.checkBreakDuration();
        this.checkBreakTime();

        const url = reverse(apiRoutes.routes.settings.validate);
        const resultPromise = this.requestApi(url, 'POST', this.mapping);

        const result: RouteSettingsValidateResponse = await resultPromise;
        if (result) {
            Object.keys(result).forEach((key) => {
                if (this._mappingErrors.has(key)) {
                    return; // show errors calculated in this class instead of api if they exist
                }
                this._mappingErrors.set(key, {
                    field: null,
                    defaultValue: result[key],
                });
            });
        }

        return this._currentObjectError === null && this._mappingErrors.size === 0;
    }

    private checkCoordinates(keyLocationLat: string, keyLocationLng: string): void {
        const defaultStartLocationLat = this.getMappingDefaultValue(keyLocationLat);
        const defaultStartLocationLng = this.getMappingDefaultValue(keyLocationLng)!;

        const isFilledLat = defaultStartLocationLat !== null && defaultStartLocationLat.toString().trim() !== '';
        const isFilledLng = defaultStartLocationLng !== null && defaultStartLocationLng.toString().trim() !== '';

        let hasError = false;
        if (isFilledLat) {
            const floatLat = parseFloat(defaultStartLocationLat as string);
            if (isNaN(floatLat)) {
                this.setMappingDefaultValueError(keyLocationLat, t('errors.invalid_value'));
                hasError = true;
            }
        }
        if (isFilledLng) {
            const floatLng = parseFloat(defaultStartLocationLng as string);
            if (isNaN(floatLng)) {
                this.setMappingDefaultValueError(keyLocationLng, t('errors.invalid_value'));
                hasError = true;
            }
        }
        if (hasError) {
            return;
        }

        if ((isFilledLat && !isFilledLng) || (isFilledLng && !isFilledLat)) {
            const keyError = 'routing_settings.dialog.tabs.users.form_control.error.set_both_fields_lat_and_lng';
            this.setMappingDefaultValueError(keyLocationLat, t(keyError));
            this.setMappingDefaultValueError(keyLocationLng, t(keyError));
            return;
        }
    }

    private checkBreakDuration(): void {
        const breakDuration = this.getMappingDefaultValue(Routing.Settings.MappingUserKey.BREAK_DURATION);

        if (!breakDuration) {
            return;
        }

        const { options } =
            ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG.get(
                Routing.Settings.MappingUserKey.BREAK_DURATION,
            ) || {};
        const { min, max } = options as Routing.Settings.MappingDefaultValueControlIntegerOptions;

        if (min && max && (+breakDuration < min || +breakDuration > max)) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_DURATION,
                t('validation.value.min_max', {
                    min,
                    max,
                }),
            );
        } else if (min && +breakDuration < min) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_DURATION,
                t('validation.value.min', {
                    min,
                }),
            );
        } else if (max && +breakDuration > max) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_DURATION,
                t('validation.value.max', {
                    max,
                }),
            );
        }
    }

    private checkBreakTime(): void {
        let hasError = false;
        const earliestTimeValue = this.getMappingDefaultValue(Routing.Settings.MappingUserKey.BREAK_EARLIEST_TIME);
        const breakEarliestTime =
            typeof earliestTimeValue === 'string' ? parse(earliestTimeValue, 'hh:mm a', new Date()) : null;

        const latestTimeValue = this.getMappingDefaultValue(Routing.Settings.MappingUserKey.BREAK_LATEST_TIME);
        const breakLatestTime =
            typeof latestTimeValue === 'string' ? parse(latestTimeValue, 'hh:mm a', new Date()) : null;

        if (breakLatestTime && !breakEarliestTime) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_EARLIEST_TIME,
                t('errors.not_empty'),
            );
            hasError = true;
        } else if (isNaN(Number(breakEarliestTime))) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_EARLIEST_TIME,
                t('errors.invalid_value'),
            );
            hasError = true;
        }

        if (breakEarliestTime && !breakLatestTime) {
            this.setMappingDefaultValueError(Routing.Settings.MappingUserKey.BREAK_LATEST_TIME, t('errors.not_empty'));
            hasError = true;
        } else if (isNaN(Number(breakLatestTime))) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_LATEST_TIME,
                t('errors.invalid_value'),
            );
            hasError = true;
        }

        if (
            !hasError &&
            breakEarliestTime &&
            breakLatestTime &&
            (breakEarliestTime as Date) > (breakLatestTime as Date)
        ) {
            this.setMappingDefaultValueError(
                Routing.Settings.MappingUserKey.BREAK_LATEST_TIME,
                t('validation.value.min', {
                    min: earliestTimeValue,
                }),
            );
        }
    }
}

export default RoutingSettingsMappingUserFormManager;
