import React from 'react';
import { Button, Grid, Icon, IconButton, Tooltip, withStyles } from '@material-ui/core';
import Confirmation from '../Confirmation/index';
import FormDialog from '../FormDialog/index';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import ActionContextNameForm from '../WorkflowGroups/ActionContextNameForm';
import { WithTranslation, withTranslation } from 'react-i18next';
import ActionIcon from './ActionIcon';
import NamespaceBadge from '../Workflow/NamespaceBadge';
import LandingLink, { ARTICLE_AUTOMATION_EXECUTION_CONTEXT } from '../HelpLink/LandingLink';
import { Workflow } from 'interfaces';

export const SmallDragHandle = SortableHandle(() => (
    <IconButton size="small" data-testid="workflow_action.sortable_action.drag_button">
        <Icon fontSize="small">dehaze</Icon>
    </IconButton>
));
export const ContextNameButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: '0.875rem',
        maxWidth: '130px',
    },
    label: {
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})(Button);
export const ActionButton = withStyles({
    label: {
        textTransform: 'none',
    },
})(Button);

export interface ActionProps extends WithTranslation {
    action: Workflow.Action;
    actionIndex: number;
    onContextNameChange: (actionIndex: number, contextName?: string) => void;
    onActionEdit: (action: Workflow.Action, actionIndex: number) => void;
    onActionChanged: (actionIndex: number, action: Workflow.Action) => void;
    onActionRemove: (actionIndex: number) => void;
    parentNamespace: string;
    contextName?: string;
}

export interface ActionState {
    openContextNameModal: boolean;
}

export class Action extends React.PureComponent<ActionProps, ActionState> {
    constructor(props: ActionProps) {
        super(props);

        this.state = {
            openContextNameModal: false,
        };

        this.openContextNameModal = this.openContextNameModal.bind(this);
        this.closeContextNameModal = this.closeContextNameModal.bind(this);
        this.handleContextNameChange = this.handleContextNameChange.bind(this);
        this.renderContextNameDelimiter = this.renderContextNameDelimiter.bind(this);
        this.renderContextName = this.renderContextName.bind(this);
        this.renderContextNameModal = this.renderContextNameModal.bind(this);
    }

    openContextNameModal() {
        this.setState({ openContextNameModal: true });
    }

    closeContextNameModal() {
        this.setState({ openContextNameModal: false });
    }

    handleContextNameChange(contextName?: string) {
        this.props.onContextNameChange(this.props.actionIndex, contextName);
        this.closeContextNameModal();
    }

    renderContextNameDelimiter() {
        return (
            <span className="text-muted" style={{ fontSize: '1.5rem' }}>
                {'⇒'}
            </span>
        );
    }

    renderContextName() {
        if (this.props.contextName) {
            return (
                <Tooltip title={this.props.contextName}>
                    <ContextNameButton size="small" onClick={this.openContextNameModal}>
                        {this.props.contextName}
                    </ContextNameButton>
                </Tooltip>
            );
        }
        return (
            <Button
                variant="outlined"
                size="small"
                onClick={this.openContextNameModal}
                data-testid="workflow_groups.form.context_name.set"
            >
                {this.props.t('workflow_groups.form.context_name.set')}
            </Button>
        );
    }

    renderContextNameModal() {
        return this.state.openContextNameModal ? (
            <FormDialog
                title={this.renderModalTitle()}
                onSave={this.handleContextNameChange}
                onCancel={this.closeContextNameModal}
                maxWidth="sm"
                fullWidth
                saveByPressingEnter
            >
                <ActionContextNameForm contextName={this.props.contextName} />
            </FormDialog>
        ) : null;
    }

    renderModalTitle = () => {
        return (
            <Grid container spacing={1} alignItems="center">
                <Grid item>{this.props.t('workflow_groups.form.context_name.title')}</Grid>
                <Grid item>
                    <LandingLink
                        useLeadingIcon
                        useTrailingIcon
                        article={ARTICLE_AUTOMATION_EXECUTION_CONTEXT}
                        style={{ fontSize: 16 }}
                    >
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={1} alignItems="center" className="action-container">
                    <Grid item>
                        <SmallDragHandle />
                    </Grid>
                    <Grid item>
                        <ActionButton
                            startIcon={<ActionIcon type={this.props.action.type} />}
                            onClick={this.props.onActionEdit.bind(this, this.props.action, this.props.actionIndex)}
                        >
                            {this.props.action.name}
                            <NamespaceBadge
                                tooltipMessage={this.props.t('workflow_actions.namespace.tooltip', {
                                    namespace: this.props.action.namespace,
                                })}
                                visible={this.props.parentNamespace !== this.props.action.namespace}
                            >
                                {this.props.action.namespace}
                            </NamespaceBadge>
                        </ActionButton>
                    </Grid>
                    <Grid item className={this.props.contextName ? '' : 'hideable'}>
                        {this.renderContextNameDelimiter()}
                    </Grid>
                    <Grid item className={this.props.contextName ? '' : 'hideable'}>
                        {this.renderContextName()}
                    </Grid>
                    <Grid item className="hideable">
                        <Confirmation
                            text={this.props.t('workflow_actions.confirm.remove')}
                            onConfirm={this.props.onActionRemove.bind(this, this.props.actionIndex)}
                        >
                            <Tooltip title={this.props.t<string>('workflow_actions.remove.hint')}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    data-testid="workflow_actions.sortable_action.confirm.remove"
                                >
                                    <Icon fontSize="small">delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </Confirmation>
                    </Grid>
                </Grid>

                {this.renderContextNameModal()}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SortableElement(Action));
