import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AccountData } from 'service/types';
import { ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG } from 'service/MapPage/RoutingSettings/config';
import { Account, Common, Routing } from 'interfaces';
import TextField from '@material-ui/core/TextField';
import Hint from '../../Hint';
import {
    MappingDefaultValueControlConfig,
    MappingDefaultValueControlIntegerOptions,
} from '../../../interfaces/routing/settings';
import { FormControl, FormHelperText, Grid, InputAdornment, InputLabel, Typography } from '@material-ui/core';

interface Props extends WithTranslation {
    account: Account.Account;
    value: number | null;
    onValueChanged: (value: number | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

class MaxDistanceInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled, bareInput, account } = this.props;

        const options = MaxDistanceInput.getOptions();

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin={bareInput ? 'none' : 'dense'} error={error !== undefined}>
                        {!bareInput && (
                            <InputLabel shrink={true}>
                                {t('route_editor.modal.routing_preferences.modal.max_distance.label')}
                            </InputLabel>
                        )}
                        <TextField
                            error={!!error}
                            disabled={disabled}
                            value={value}
                            fullWidth
                            type="number"
                            InputProps={{
                                inputProps: { min: options.min, max: options.max, style: { textAlign: 'end' } },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">{this.getUnit(account)}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleChange}
                            variant="standard"
                            margin={bareInput ? 'none' : 'normal'}
                            label={t('route_editor.modal.routing_preferences.modal.max_distance.label')}
                        />
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 16, textAlign: 'center' }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.max_distance.hint', {
                            unit: this.getUnit(account),
                        })}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private static getOptions(): MappingDefaultValueControlIntegerOptions {
        const controlConfig = ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG.get(
            Routing.Settings.MappingUserKey.MAX_DISTANCE,
        ) as MappingDefaultValueControlConfig;
        return controlConfig.options as MappingDefaultValueControlIntegerOptions;
    }

    private validate = (value: number | null): Promise<void> => {
        const { onInputValidation, t } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        const options = MaxDistanceInput.getOptions();
        const isValid = value === null || (value >= options.min && value <= options.max);
        let error = undefined;
        if (!isValid) {
            if (value === null) {
                error = t('validation.value.required');
                // @ts-ignore
            } else if (value < options.min || value > options.max) {
                error = t('validation.value.min_max', { min: options.min, max: options.max });
            }
        }
        return onInputValidation(isValid, error);
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onValueChanged } = this.props;
        const value = event.target.value ? parseInt(event.target.value, 10) : null;
        onValueChanged(value).then(() => this.validate(value));
    };

    private getUnit = (account: AccountData) => {
        const { t } = this.props;
        if (account.distanceMetric === Common.DistanceMetric.KM) {
            return t('routing_settings.dialog.tabs.users.form_control.max_distance.km');
        }
        if (account.distanceMetric === Common.DistanceMetric.MILE) {
            return t('routing_settings.dialog.tabs.users.form_control.max_distance.mile');
        }
        throw new Error('Unknown distance metric.');
    };
}

export default withTranslation()(MaxDistanceInput);
