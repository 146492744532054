import React, { ChangeEvent, ChangeEventHandler, FC, useCallback, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box, Grid, Select, Tooltip, Typography } from '@material-ui/core';
import { appointmentConfig, routeDesignConfigManager, routeDesignManager } from 'service/MapPage';
import { observer } from 'mobx-react';
import { userManager } from 'service/UserManager';
import Hint from '../../../Hint';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    OBJECTIVE_MINIMIZE_COMPLETION,
    OBJECTIVE_MINIMIZE_COMPLETION_BALANCE,
    OBJECTIVE_MINIMIZE_DRIVING_TIME,
    OBJECTIVE_MINIMIZE_VEHICLES,
    OBJECTIVE_MINIMIZE_VEHICLES_BALANCE,
    ROUTE_MAX_GROUPS_TO_OPTIMIZATION_ESSENTIAL,
} from 'components/constants';
import MenuItem from '@material-ui/core/MenuItem';
import { ActivityType } from '../../../../interfaces/routing/route';

interface MultiUserCheckerProps extends WithTranslation {}

export const OptimizationObjectives = [
    {
        value: OBJECTIVE_MINIMIZE_DRIVING_TIME,
        key: 'route_editor.form.advanced_routing.objective.minimize_driving_time',
        sum: 'route_editor.form.advanced_routing.objective.minimize_driving_time.sum',
        sum_single: 'route_editor.form.advanced_routing.objective.minimize_driving_time.sum_single',
        desc_single: 'route_editor.form.advanced_routing.objective.minimize_driving_time.desc_single',
        desc_single_user_multiple_days:
            'route_editor.form.advanced_routing.objective.minimize_driving_time.desc_single_user_multiple_days',
        desc_multi: 'route_editor.form.advanced_routing.objective.minimize_driving_time.desc_multi',
    },
    {
        value: OBJECTIVE_MINIMIZE_VEHICLES,
        key: 'route_editor.form.advanced_routing.objective.minimize_vehicles',
        sum: 'route_editor.form.advanced_routing.objective.minimize_vehicles.sum',
        desc_multi: 'route_editor.form.advanced_routing.objective.minimize_vehicles.desc_multi',
    },
    {
        value: OBJECTIVE_MINIMIZE_VEHICLES_BALANCE,
        key: 'route_editor.form.advanced_routing.objective.minimize_vehicles_balance',
        sum: 'route_editor.form.advanced_routing.objective.minimize_vehicles_balance.sum',
        desc_multi: 'route_editor.form.advanced_routing.objective.minimize_vehicles_balance.desc_multi',
    },
    {
        value: OBJECTIVE_MINIMIZE_COMPLETION,
        key: 'route_editor.form.advanced_routing.objective.minimize_completion',
        sum: 'route_editor.form.advanced_routing.objective.minimize_completion.sum',
        sum_single: 'route_editor.form.advanced_routing.objective.minimize_completion.sum_single',
        desc_single: 'route_editor.form.advanced_routing.objective.minimize_completion.desc_single',
        desc_single_user_multiple_days:
            'route_editor.form.advanced_routing.objective.minimize_completion.desc_single_user_multiple_days',
        desc_multi: 'route_editor.form.advanced_routing.objective.minimize_completion.desc_multi',
    },
    {
        value: OBJECTIVE_MINIMIZE_COMPLETION_BALANCE,
        key: 'route_editor.form.advanced_routing.objective.minimize_completion_balance',
        sum: 'route_editor.form.advanced_routing.objective.minimize_completion_balance.sum',
        desc_single_user_multiple_days:
            'route_editor.form.advanced_routing.objective.minimize_completion_balance.desc_single_user_multiple_days',
        desc_multi: 'route_editor.form.advanced_routing.objective.minimize_completion_balance.desc_multi',
    },
];

const CheckBoxWithTooltip: FC<{ disabledReason: string; handleRouteModeChanged: ChangeEventHandler }> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = useCallback(() => setIsOpen((o) => !o), []);

    if (props.disabledReason) {
        return (
            <Tooltip
                title={props.disabledReason}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            >
                <span onClick={toggleOpen}>
                    <Checkbox
                        checked={routeDesignConfigManager.optimal}
                        disabled={true}
                        onChange={props.handleRouteModeChanged}
                        color="primary"
                        style={{ pointerEvents: 'none' }}
                    />
                </span>
            </Tooltip>
        );
    }

    return (
        <Checkbox
            //
            checked={routeDesignConfigManager.optimal}
            onChange={props.handleRouteModeChanged}
            color="primary"
        />
    );
};

const OptimizeSettings: React.FC<MultiUserCheckerProps> = observer((props) => {
    const { t } = props;

    if (!appointmentConfig.initializationCompleted) {
        return null;
    }

    const groupsCount = routeDesignManager.allPointsWithStartEndPointsCount;
    const hasVirtualPoints = routeDesignManager.hasVirtualPoints;
    const planningForMultipleUsers = routeDesignConfigManager.usersIds.length > 1;
    const periodGreaterThan1Day = routeDesignConfigManager.daysPeriod > 1;
    const points = routeDesignManager.points;
    const ignoreConstraints = routeDesignConfigManager.ignoreConstraints;

    const handleRouteModeChanged = (event: ChangeEvent<HTMLInputElement>) => {
        routeDesignConfigManager.setOptimal(event.target.checked);
    };

    const handleObjectiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        routeDesignConfigManager.setObjective(event.target.value);
    };

    const tooltip = t('route_editor.form.advanced_routing.optimize.tooltip');

    const getDisabledReason = useCallback(() => {
        if (groupsCount > ROUTE_MAX_GROUPS_TO_OPTIMIZATION_ESSENTIAL && userManager.hasEssentialRestrictions()) {
            if (userManager.isRoleAdmin()) {
                return t('route_editor.form.advanced_routing.optimize.disabled_tooltip.essential');
            } else {
                return t('route_editor.form.advanced_routing.optimize.disabled_tooltip.essential.contact_admin');
            }
        }

        if (planningForMultipleUsers || periodGreaterThan1Day) {
            return t('route_editor.form.advanced_routing.optimize.disabled_tooltip.multiple_users_or_days');
        }

        if (hasVirtualPoints) {
            return t('route_editor.form.advanced_routing.optimize.disabled_tooltip.virtual_points');
        }

        if (!ignoreConstraints) {
            for (const point of points) {
                if (point.type === ActivityType.PICKUP_DELIVERY) {
                    return t('route_editor.form.advanced_routing.optimize.disabled_tooltip.pickup_delivery');
                }
            }
        }

        return null;
    }, [hasVirtualPoints, groupsCount, ignoreConstraints, periodGreaterThan1Day, planningForMultipleUsers, points, t]);

    const disabledReason = getDisabledReason();

    const multipleDays = routeDesignConfigManager.daysPeriod > 1;
    const multipleUsers = routeDesignConfigManager.usersIds.length > 1;

    const showSelector = true;
    const getSelectItems = () => {
        const singleUserSingleDayIgnoredObjectiveTypes = [
            OBJECTIVE_MINIMIZE_VEHICLES,
            OBJECTIVE_MINIMIZE_VEHICLES_BALANCE,
            OBJECTIVE_MINIMIZE_COMPLETION_BALANCE,
        ];
        const singleUserMultipleDaysIgnoredObjectiveTypes = [
            OBJECTIVE_MINIMIZE_VEHICLES,
            OBJECTIVE_MINIMIZE_VEHICLES_BALANCE,
        ];
        const items = OptimizationObjectives.filter((objective) => {
            if (!multipleUsers && !multipleDays && singleUserSingleDayIgnoredObjectiveTypes.includes(objective.value)) {
                return false;
            }
            if (
                !multipleUsers &&
                multipleDays &&
                singleUserMultipleDaysIgnoredObjectiveTypes.includes(objective.value)
            ) {
                return false;
            }
            return true;
        });
        return items.map((objective) => {
            let descKey = objective.desc_multi;
            if (!multipleUsers && !multipleDays) {
                descKey = objective.desc_single as string;
            }
            if (!multipleUsers && multipleDays) {
                descKey = objective.desc_single_user_multiple_days as string;
            }

            let sumKey = objective.sum;
            if (!multipleUsers && multipleDays) {
                sumKey = objective.sum_single as string;
            }

            return (
                <MenuItem key={objective.value} value={objective.value} style={{ display: 'block', maxWidth: '800px' }}>
                    <Typography variant="body2" style={{ display: 'block', fontWeight: 'bold' }}>
                        {t(objective.key)}
                    </Typography>
                    <Typography variant="caption" style={{ display: 'block', whiteSpace: 'normal' }}>
                        {t(sumKey)}
                    </Typography>
                    <Typography variant="caption" style={{ display: 'block', whiteSpace: 'normal' }}>
                        {t(descKey)}
                    </Typography>
                </MenuItem>
            );
        });
    };

    return (
        <Grid className="c-route-options-container" container alignItems="center" justify="space-between">
            <Grid item xs={showSelector ? 5 : 12}>
                <FormControlLabel
                    className="c-route-find-checkbox"
                    style={{ marginRight: 0, maxWidth: '100%' }}
                    control={
                        <CheckBoxWithTooltip
                            disabledReason={disabledReason}
                            handleRouteModeChanged={handleRouteModeChanged}
                        />
                    }
                    label={
                        <Box whiteSpace="nowrap" display="flex" alignItems="center" gridGap={4}>
                            <Box component="span" flex="1" overflow="hidden" textOverflow="ellipsis">
                                {t('route_editor.form.advanced_routing.optimize.label')}
                            </Box>
                            <Hint inheritStyles iProps={{ style: { color: '#888' } }}>
                                {tooltip}
                            </Hint>
                        </Box>
                    }
                />
            </Grid>
            {showSelector && (
                <Grid item xs={7}>
                    <Select
                        style={{ maxWidth: '100%' }}
                        renderValue={(selected: any) => {
                            const objective = OptimizationObjectives.find((objective) => objective.value === selected);
                            return objective ? t(objective.key) : '';
                        }}
                        value={routeDesignConfigManager.objective}
                        // @ts-ignore
                        onChange={handleObjectiveChange}
                    >
                        {getSelectItems()}
                    </Select>
                </Grid>
            )}
        </Grid>
    );
});

export default withTranslation()(OptimizeSettings);
