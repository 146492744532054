import dispatcher from './dispatcher';
import events from '../events';
import { weAreInNativeApp } from '../utils';
import { IdleSessionTimeout } from 'idle-session-timeout';
import { userManager } from './UserManager';
import { SessionSettings, UserDataRole, Duration } from './types';
import { User } from 'interfaces';
import { IIdleSessionTimeout } from 'idle-session-timeout/lib/contracts/IIdleSessionTimeout';

export class IdleSessionManager {
    private session: IIdleSessionTimeout | undefined;

    private timeoutInMilliseconds: number | undefined;

    private userId: number | undefined;

    public init(): void {
        dispatcher.subscribe(events.WS_ROLES_UPDATED, this, ({ roles }: { roles: UserDataRole[] }) => {
            const user = userManager.getCurrentUser();
            if (!user) {
                return;
            }
            const role = roles.find((role) => role.id === user.role.id);
            if (!role) {
                return;
            }

            const timeoutInSeconds = this.getTimeoutInMilliseconds(role.sessionSettings);

            if (this.session && this.timeoutInMilliseconds !== timeoutInSeconds) {
                const timeSpent: number = this.timeoutInMilliseconds! - this.session.getTimeLeft();

                this.timeoutInMilliseconds = timeoutInSeconds;

                this.session.dispose();

                this.session = new IdleSessionTimeout(this.timeoutInMilliseconds - timeSpent);
                this.session.onTimeOut = () => {
                    userManager.logout();
                };
                this.session.start();
            }
        });

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, (user: User.User) => {
            const userId = user?.id;
            if (this.userId === userId) {
                return;
            }

            this.userId = userId;

            if (this.session) {
                this.session.dispose();
            }

            if (!user) {
                return;
            }

            this.timeoutInMilliseconds = this.getTimeoutInMilliseconds(user.role.sessionSettings);

            this.session = new IdleSessionTimeout(this.timeoutInMilliseconds);
            this.session.onTimeOut = () => {
                userManager.logout();
            };
            this.session.start();
        });
    }

    private getTimeoutInMilliseconds(sessionSettings: SessionSettings): number {
        const duration = weAreInNativeApp()
            ? sessionSettings.idleSessionTimeoutForApp
            : sessionSettings.idleSessionTimeoutForWeb;

        return IdleSessionManager.getDurationInSeconds(duration) * 1000;
    }

    public static getDurationInSeconds(duration: Duration): number {
        const m: { [key: string]: number } = {
            day: 24 * 60 * 60,
            hour: 60 * 60,
            minute: 60,
        };
        return duration.number! * m[duration.units];
    }
}
