import React from 'react';

import { Grid, Table, TableHeaderRow, TableFilterRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import { PagingState, SortingState, DataTypeProvider, CustomPaging, FilteringState } from '@devexpress/dx-react-grid';

import { withStyles } from '@material-ui/core/styles';

import { Link, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TableLoadingState } from '../TableLoadingState';
import { getDateTimeFilterValue } from '../utils/tableFilter';
import { DATETIME_FORMAT_NO_SECONDS, DATETIME_FORMAT_NO_TIME, userTimezoneToUtc } from '../../utils';
import { withTranslation } from 'react-i18next';
import { leadsManager } from '../../service/LeadsManager';
import { reverse, routes } from '../../routes';
import Tooltip from '@material-ui/core/Tooltip';
import DateTime from '../DateTime';

const FilterIcon = ({ type, ...restProps }) => {
    return <TableFilterRow.Icon type={type} {...restProps} />;
};

const FilterCell = (props) => {
    const { ...rest } = props;
    return <TableFilterRow.Cell {...rest} />;
};

class Leads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            requestParams: {
                sorting: [{ columnName: 'date', direction: 'desc' }],
                filters: [],
                currentPage: 0,
                pageSize: 10,
            },
            rows: [],
            totalCount: 0,
            currentAccount: null,
            loadingAccounts: false,
            loadingUsers: false,
        };

        this.columns = [
            {
                name: 'email',
                title: this.props.t('email'),
                getCellValue: (row) =>
                    row.accountId ? (
                        <Link to={reverse(routes.admin.account.index, { accountId: row.accountId })}>
                            <Tooltip title={row.email}>
                                <span>{row.email}</span>
                            </Tooltip>
                        </Link>
                    ) : (
                        <Tooltip title={row.email}>
                            <div>{row.email}</div>
                        </Tooltip>
                    ),
            },
            {
                name: 'date',
                title: this.props.t('leads.date'),
                getCellValue: (row) => {
                    return (
                        <div>
                            <Tooltip title={<DateTime format={DATETIME_FORMAT_NO_SECONDS}>{row.date.date}</DateTime>}>
                                <span>
                                    <DateTime format={DATETIME_FORMAT_NO_TIME}>{row.date.date}</DateTime>
                                </span>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                name: 'dataSources',
                title: this.props.t('leads.data_sources'),
                getCellValue: (row) => {
                    const paragraphs = row.dataSources.map(function (str) {
                        return <p style={{ margin: 0 }}>{str}</p>;
                    });
                    return <>{paragraphs}</>;
                },
            },
            { name: 'urlReferrer', title: this.props.t('leads.url_referrer') },
            { name: 'browser', title: this.props.t('leads.browser') },
            { name: 'os', title: this.props.t('leads.os') },
            { name: 'country', title: this.props.t('leads.country') },
            { name: 'language', title: this.props.t('leads.language') },
            { name: 'timezone', title: this.props.t('leads.timezone') },
            { name: 'ip', title: this.props.t('leads.ip') },
            { name: 'device', title: this.props.t('leads.device') },
            { name: 'utmSource', title: this.props.t('leads.utm_source') },
            { name: 'utmContent', title: this.props.t('leads.utm_content') },
            { name: 'utmCampaign', title: this.props.t('leads.utm_campaign') },
            { name: 'utmMedium', title: this.props.t('leads.utm_medium') },
            { name: 'utmTerm', title: this.props.t('leads.utm_term') },
        ];

        this.stringColumns = [
            'email',
            'utmSource',
            'utmContent',
            'utmCampaign',
            'utmMedium',
            'utmTerm',
            'urlReferrer',
            'browser',
            'os',
            'country',
            'language',
            'timezone',
            'ip',
            'device',
        ];
        this.stringFilterOperations = ['contains', 'notContains', 'startsWith', 'endsWith', 'equal', 'notEqual'];

        this.dateColumns = ['date'];
        this.dateFilterOperations = [
            'equal',
            'notEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
            'between',
        ];

        this.timeToApplyFilters = null;
        this.filterDelay = 500;

        this.pageSizes = [10, 25, 50];
    }

    handleClose = () => {
        this.setState({
            currentAccount: null,
        });
    };

    componentDidMount() {
        this.loadLeadList();
    }

    handleChangeSorting = (sorting) => {
        this.loadLeadList({ sorting });
    };

    convertFrontFilterToServerFilter(filter) {
        if (this.dateColumns.includes(filter.columnName)) {
            const result = { ...filter };
            result.value = getDateTimeFilterValue(filter);
            if (Array.isArray(result.value)) {
                result.value = result.value.map((v) => userTimezoneToUtc(v, this.props.user));
            } else {
                result.value = userTimezoneToUtc(result.value, this.props.user);
            }
            return result;
        }
        return filter;
    }

    applyFilters(filters) {
        const d = new Date();
        if (d.getTime() >= this.timeToApplyFilters) {
            this.loadLeadList({ filters });
        }
    }

    handleChangeFilters = (filters) => {
        const d = new Date();
        this.timeToApplyFilters = d.getTime() + this.filterDelay;
        setTimeout(() => {
            this.applyFilters(filters);
        }, this.filterDelay);
    };

    handleChangeCurrentPage = (currentPage) => {
        this.loadLeadList({ currentPage });
    };

    handleChangePageSize = (pageSize) => {
        this.loadLeadList({ pageSize });
    };

    loadLeadList(requestParams = {}) {
        requestParams = { ...this.state.requestParams, ...requestParams };

        this.setState({
            requestParams,
            loading: true,
            rows: [],
        });

        const convertedFilters = [];
        for (let filter of requestParams.filters ?? []) {
            try {
                convertedFilters.push(this.convertFrontFilterToServerFilter(filter));
            } catch (e) {
                // do nothing
            }
        }

        leadsManager
            .list(
                convertedFilters,
                requestParams.sorting,
                requestParams.currentPage * requestParams.pageSize,
                requestParams.pageSize,
            )
            .then((data) => {
                this.setState({
                    rows: data.items,
                    totalCount: parseInt(data.total),
                    loading: false,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <div>
                <div className="table-leads">
                    <Grid rows={this.state.rows} columns={this.columns}>
                        <SortingState
                            defaultSorting={this.state.requestParams.sorting}
                            onSortingChange={this.handleChangeSorting}
                            columnExtensions={[]}
                        />
                        <DataTypeProvider
                            for={this.dateColumns}
                            availableFilterOperations={this.dateFilterOperations}
                        />
                        <DataTypeProvider
                            for={this.stringColumns}
                            availableFilterOperations={this.stringFilterOperations}
                        />
                        <FilteringState
                            defaultFilters={this.state.requestParams.filters}
                            onFiltersChange={this.handleChangeFilters}
                        />
                        <PagingState
                            currentPage={this.state.requestParams.currentPage}
                            onCurrentPageChange={this.handleChangeCurrentPage}
                            onPageSizeChange={this.handleChangePageSize}
                            pageSize={this.state.requestParams.pageSize}
                        />
                        <CustomPaging totalCount={this.state.totalCount} />

                        <Table
                            columnExtensions={[{ columnName: 'email', width: 270 }]}
                            cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                            noDataCellComponent={() => (
                                <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                            )}
                        />
                        <TableHeaderRow
                            showSortingControls
                            cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        />
                        <TableFilterRow
                            showFilterSelector
                            iconComponent={FilterIcon}
                            cellComponent={StyledFilterCell}
                        />
                        <PagingPanel pageSizes={this.pageSizes} />
                    </Grid>
                </div>
            </div>
        );
    }
}

Leads.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const rightIconCellStyles = {
    flexContainer: {
        flexFlow: 'row-reverse',
    },
};

const StyledFilterCell = withStyles(rightIconCellStyles)(withTranslation()(FilterCell));

export default withTranslation()(withSnackbar(withRouter(Leads)));
