import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import dispatcher from './dispatcher';
import events from '../events';
import { userManager } from './UserManager';

const VIEW_TABLE = 'table';
const VIEW_LIST = 'list';

// Все хранящиеся метаданные зависимы от текущего пользователя, т.к. определяются его правами доступа к полям/сущностям.
// При смене пользователя загруженные метаданные сбрасываются.
// Методы request* возвращают promise
// Методы get* возвращают имеющееся значение
class MetadataManager extends BackendService {
    constructor() {
        super();

        this.dataSourcesForUser = new Map();
        this.entitiesForUser = new Map();
        this.accountDataSourcesForUser = new Map();

        this.standardFieldNames = null;

        this.userId = null;

        this.entities = new Map();

        dispatcher.subscribe(events.EVENT_DATA_SOURCE_UPDATED, this, (dataSource) => {
            this.dataSourcesForUser.delete(dataSource.id);
            for (let [entityId, entity] of this.entitiesForUser) {
                if (entity.dsId === dataSource.id) {
                    this.entitiesForUser.delete(entityId);
                    this.entities.delete(entityId);
                }
            }
        });

        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, ({ entityIds, dsId }) => {
            this.dataSourcesForUser.delete(dsId);
            for (const entityId of entityIds) {
                this.entitiesForUser.delete(entityId);
                this.entities.delete(entityId);
            }
        });

        dispatcher.subscribe(events.WS_FIELD_PICKLIST_CHANGED, this, ({ entityId }) => {
            this.entitiesForUser.delete(entityId);
            this.entities.delete(entityId);
        });

        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, (user) => {
            const userId = user ? user.id : null;
            if (userId !== this.userId) {
                this.reset();
                this.userId = userId;
            }
        });

        dispatcher.subscribe(events.EVENT_USER_SWITCH, this, (userId) => {
            if (userId !== this.userId) {
                this.reset();
                this.userId = userId;
            }
        });
    }

    reset() {
        this.dataSourcesForUser = new Map();
        this.entitiesForUser = new Map();
        this.accountDataSourcesForUser = new Map();
    }

    checkUser() {
        // если юзер изменился - сбросить и установить
        const currentUser = userManager.getCurrentUser();
        const currentUserId = currentUser ? currentUser.id : null;
        if (currentUserId !== this.userId) {
            this.reset();
            this.userId = currentUserId;
        }
    }

    getStandardFields() {
        return this.standardFieldNames;
    }

    requestStandardFields() {
        if (this.standardFieldNames !== null) {
            return Promise.resolve(this.standardFieldNames);
        }

        return this.requestApi(reverse(apiRoutes.standard_fields), 'GET').then(({ fieldNames }) => {
            this.standardFieldNames = fieldNames;

            return this.standardFieldNames;
        });
    }

    getEntity(entityId) {
        entityId = parseInt(entityId);
        return this.entities.get(entityId) || null;
    }

    requestEntity(entityId, force = false) {
        entityId = parseInt(entityId);
        if (force === false && this.entities.has(entityId)) {
            return Promise.resolve(this.entities.get(entityId));
        }

        const url = reverse(apiRoutes.entity, { entityId });
        return this.requestApi(url, 'GET').then((entity) => {
            this.entities.set(entityId, entity);
            return entity;
        });
    }

    getView(entityId, viewId) {
        let entity = this.getEntity(entityId) || this.getEntityForUser(entityId);
        if (!entity) {
            return null;
        }
        for (const view of entity.views) {
            if (view.id === viewId) {
                return view;
            }
        }
        return null;
    }

    getLayer(entityId, layerId) {
        const entity = this.getEntity(entityId) || this.getEntityForUser(entityId);
        if (!entity) {
            return null;
        }
        for (const layer of entity.layers) {
            if (layer.id === layerId) {
                return layer;
            }
        }
        return null;
    }

    cropExcludedEntityFields(entity) {
        const resultEntity = { ...entity };

        resultEntity.fields = entity.fields.filter((field) => field.isIncluded);

        return resultEntity;
    }

    cropEntityFields(entity, view, addMainFields) {
        const resultEntity = this.cropExcludedEntityFields(entity);

        if (view === null) {
            return resultEntity;
        }

        let fieldset = entity.fieldsets[view + 'View'];
        if (view === VIEW_TABLE && fieldset === null) {
            resultEntity.fields = resultEntity.fields.filter((field) => !field.isMapslyField);

            return resultEntity;
        }

        resultEntity.fields = [];
        if (fieldset) {
            for (let fieldApiName of fieldset) {
                const field = entity.fields.find((field) => fieldApiName === field.apiName);
                if (field) {
                    resultEntity.fields.push(field);
                }
            }
        }

        let namesToAppend = [];

        if (addMainFields) {
            namesToAppend = [...entity.nameFields, 'mapsly_link'];
            if (entity.pinField) {
                namesToAppend.push(entity.pinField);
            }
        }

        for (let fieldName of namesToAppend) {
            const isPresentedInGivenSet = !!resultEntity.fields.find((field) => field.apiName === fieldName);

            if (!isPresentedInGivenSet) {
                for (let field of entity.fields) {
                    if (field.apiName === fieldName) {
                        resultEntity.fields.push(field);
                        break;
                    }
                }
            }
        }

        return resultEntity;
    }

    //
    // FOR CURRENT USER
    //
    requestAccountDataSourcesForUser(accountId) {
        if (!accountId) {
            const currentUser = userManager.getCurrentUser();
            if (currentUser) {
                accountId = currentUser.accountId;
            } else {
                throw new Error();
            }
        }
        accountId = parseInt(accountId);
        if (this.accountDataSourcesForUser.has(accountId)) {
            return Promise.resolve(this.getAccountDataSourcesForUser(accountId));
        }
        const url = reverse(apiRoutes.metadata.account, { accountId });

        return this.requestApi(url, 'GET').then(({ dataSources }) => {
            for (const dataSource of dataSources) {
                this.dataSourcesForUser.set(dataSource.id, dataSource);
                for (const entity of dataSource.entities) {
                    this.entitiesForUser.set(entity.id, entity);
                }
            }
            this.accountDataSourcesForUser.set(accountId, dataSources);

            return dataSources;
        });
    }

    getAccountDataSourcesForUser(accountId) {
        if (!accountId) {
            const currentUser = userManager.getCurrentUser();
            if (currentUser) {
                accountId = currentUser.accountId;
            } else {
                throw new Error();
            }
        }
        accountId = parseInt(accountId);
        if (!this.accountDataSourcesForUser.has(accountId)) {
            return null;
        }

        return this.accountDataSourcesForUser.get(accountId);
    }

    requestDataSourceForUser(dataSourceId) {
        dataSourceId = parseInt(dataSourceId);
        if (this.dataSourcesForUser.has(dataSourceId)) {
            return Promise.resolve(this.dataSourcesForUser.get(dataSourceId));
        }
        const url = reverse(apiRoutes.metadata.dataSource, { dataSourceId });

        return this.requestApi(url, 'GET').then(({ dataSource }) => {
            this.dataSourcesForUser.set(dataSource.id, dataSource);
            for (const entity of dataSource.entities) {
                this.entitiesForUser.set(entity.id, entity);
            }
            return dataSource;
        });
    }

    getDataSourceForUser(dataSourceId) {
        dataSourceId = parseInt(dataSourceId);
        if (!this.dataSourcesForUser.has(dataSourceId)) {
            return null;
        }

        return this.dataSourcesForUser.get(dataSourceId);
    }

    requestEntityForUser(entityId, forceUpdate = false) {
        entityId = parseInt(entityId);
        if (this.entitiesForUser.has(entityId) && !forceUpdate) {
            return Promise.resolve(this.entitiesForUser.get(entityId));
        }
        const url = reverse(apiRoutes.metadata.entity, { entityId: entityId });

        return this.requestApi(url, 'GET').then(({ entity }) => {
            this.entitiesForUser.set(entity.id, entity);

            return entity;
        });
    }

    getEntityForUser(entityId) {
        entityId = parseInt(entityId);
        if (!this.entitiesForUser.has(entityId)) {
            return null;
        }

        return this.entitiesForUser.get(entityId);
    }
}

const metadataManager = new MetadataManager();

for (const method of Object.getOwnPropertyNames(MetadataManager.prototype)) {
    if (/^(get|request)[a-zA-Z]+ForUser$/.test(method)) {
        const m = metadataManager[method];
        metadataManager[method] = function () {
            metadataManager.checkUser();
            return m.apply(metadataManager, arguments);
        };
    }
}

export default metadataManager;
export { VIEW_TABLE, VIEW_LIST };
