import React from 'react';
import { Tooltip, Typography as BaseTypography, withStyles } from '@material-ui/core';

const Typography = withStyles({
    root: {
        color: '#6c757d',
        backgroundColor: '#6c757d21',
        borderRadius: '5px',
        padding: '1px 5px',
        marginLeft: '5px',
    },
})(BaseTypography);

interface NamespaceBadgeProps {
    children: React.ReactNode;
    tooltipMessage: string;
    visible?: boolean;
}

export default class NamespaceBadge extends React.PureComponent<NamespaceBadgeProps> {
    render() {
        const { children, tooltipMessage, visible = true } = this.props;

        if (!visible) {
            return null;
        }

        return (
            <Tooltip title={tooltipMessage}>
                <Typography variant="caption">{children}</Typography>
            </Tooltip>
        );
    }
}
