import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { formulaManager } from '../../service/FormulaManager';

class InsertFunctionForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            options: [],
        };
    }

    componentDidMount() {
        this.prepareOptions();
    }

    handleChange = (func) => {
        this.props.onSave(func);
    };

    prepareOptions = () => {
        this.setState({ loading: true });

        formulaManager.functions().then((options) => {
            this.setState({ options, loading: false });
        });
    };

    render() {
        return (
            <form noValidate autoComplete="off">
                <FormControl margin="dense" fullWidth>
                    <Autocomplete
                        options={this.state.options}
                        getOptionLabel={(option) => option.name}
                        renderOption={this.props.renderFunction}
                        groupBy={(options) => options && options.groupBy}
                        onChange={(e, value) => this.handleChange(value)}
                        disableClearable
                        loading={this.state.loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                label={this.props.t('formula_editor.insert_function.label')}
                                data-testid="formula_editor.insert_function"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
            </form>
        );
    }
}

InsertFunctionForm.propTypes = {
    onSave: PropTypes.func.isRequired,
    renderFunction: PropTypes.func.isRequired,
};

export default withTranslation('translations', { withRef: true })(InsertFunctionForm);
