import { safeString } from '../../utils';

/**
 *  Make text
 * @param value
 * @returns {*}
 */
export const parseWebLinkToText = (value) => {
    let text = value;
    try {
        const webLink = JSON.parse(value);
        text = webLink.text !== undefined ? webLink.text : value;
    } catch (e) {}
    text = safeString(text);

    return removeProtocol(text);
};

/**
 *  Make url
 * @param value
 * @returns string
 */
export const parseWebLinkToUrl = (value) => {
    let url = value;
    try {
        const webLink = JSON.parse(value);
        url = webLink.url !== undefined ? webLink.url : value;
    } catch (e) {}

    return safeString(url);
};

/**
 *  Parse web link to props
 * @param value
 * @returns string
 */
export const parseToWebLinkProps = (value) => {
    let props = null;
    try {
        props = JSON.parse(value);
    } catch (e) {}

    return props;
};

/**
 *  Remove http:// or https://
 * @param value
 * @returns string
 */
export const removeProtocol = (value) => {
    return value !== null ? value.replace(/(^\w+:|^)\/\//, '') : value;
};
