import React from 'react';
import PropTypes from 'prop-types';
import entityViewManager from '../../service/EntityViewManager';
import { DataTable } from './index';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withTranslation } from 'react-i18next';

const SERVER_STATUS = {
    SUCCESS: 1,
    DOUBT: 2,
    NO_RESULT: 3,
    //NO_ADDRESS: 4,
    //UNPROCESSED: 0,
    ERROR: -1,
    RESTRICTED: -2,
    OTHER: -3,
};

const STATUS = {
    ERROR: 0,
    EMPTY: 1,
    APPROX: 2,
    EXACT: 3,
};

class GeocodingLogTable extends DataTable {
    constructor(props) {
        super(props);
        this.state.rowToShowGeoResponse = null;
        this.state.rowToShowLinkedRecords = null;
        this.state.linkedRecords = null;
        this.state.STATUS_LABEL = {
            [STATUS.ERROR]: props.t('data_table.geocoding_log_table.statuses.error'),
            [STATUS.EMPTY]: props.t('data_table.geocoding_log_table.statuses.empty'),
            [STATUS.APPROX]: props.t('data_table.geocoding_log_table.statuses.approx'),
            [STATUS.EXACT]: props.t('data_table.geocoding_log_table.statuses.exact'),
        };
    }

    getFields() {
        const { t } = this.props;
        return Promise.resolve([
            {
                columnName: 'created_at',
                name: 'created_at',
                title: t('data_table.geocoding_log_table.fields.created_at'),
                type: 'datetime',
            },
            {
                name: 'address',
                title: t('data_table.geocoding_log_table.fields.address'),
                type: 'string',
            },
            {
                name: 'service',
                title: t('data_table.geocoding_log_table.fields.service'),
                type: 'string',
            },
            {
                name: 'status',
                title: t('data_table.geocoding_log_table.fields.status'),
                type: 'integer',
                lookup: Object.keys(STATUS).map((key) => ({
                    value: STATUS[key],
                    label: this.state.STATUS_LABEL[STATUS[key]],
                })),
                getCellValue: (row) => {
                    const status = GeocodingLogTable.getClientStatus(row.status);
                    return this.state.STATUS_LABEL[status];
                },
            },
            {
                name: 'actions',
                title: t('data_table.geocoding_log_table.fields.actions'),
                getCellValue: (row) => (
                    <div>
                        <Tooltip
                            title={t('data_table.geocoding_log_table.fields.actions.cell.open_linked_records.tooltip')}
                        >
                            <IconButton
                                onClick={() => this.handleOpenLinkedRecords(row)}
                                color="primary"
                                disabled={
                                    this.state.rowToShowLinkedRecords === row && this.state.linkedRecords === null
                                }
                                data-testid="data_table.geocoding_log_table.fields.actions.cell.open_linked_records"
                            >
                                <Icon>launch</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={t('data_table.geocoding_log_table.fields.actions.cell.open_geo_response.tooltip')}
                            data-testid="data_table.geocoding_log_table.fields.actions.cell.open_geo_response"
                        >
                            <IconButton onClick={() => this.handleOpenGeoResponse(row)} color="primary">
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                ),
            },
        ]);
    }

    handleOpenLinkedRecords = (row) => {
        this.setState({
            rowToShowLinkedRecords: row,
        });
        entityViewManager
            .getSourceGeocodingResultUseCases(this.props.accountId, this.props.dsId, row.id)
            .then((response) => {
                if (response.length === 1) {
                    /// direct to search record page
                    this.setState({
                        rowToShowLinkedRecords: null,
                    });
                    this.directToSearchPage(response[0]);
                } else {
                    this.setState({
                        linkedRecords: response,
                    });
                }
            })
            .catch(() => {
                this.props.onError &&
                    this.props.onError(this.props.t('data_table.geocoding_log_table.handle_open_linked_records.error'));
                this.setState({
                    rowToShowLinkedRecords: null,
                });
            });
    };

    buildStructure(fields) {
        const structure = super.buildStructure(fields);
        structure.exts = [
            {
                columnName: 'created_at',
                width: 180,
                align: 'center',
            },
            {
                columnName: 'address',
                align: 'left',
            },
            {
                columnName: 'service',
                width: 120,
                align: 'center',
            },
            {
                columnName: 'status',
                width: 120,
                align: 'center',
            },
            {
                columnName: 'actions',
                width: 112,
                align: 'center',
            },
        ];
        return structure;
    }

    static getClientStatus(serverStatus) {
        switch (parseInt(serverStatus)) {
            case SERVER_STATUS.SUCCESS:
                return STATUS.EXACT;
            case SERVER_STATUS.DOUBT:
                return STATUS.APPROX;
            case SERVER_STATUS.NO_RESULT:
                return STATUS.EMPTY;
            default:
                return STATUS.ERROR;
        }
    }

    convertFrontFilterToServerFilter(filter) {
        if (filter.columnName === 'status') {
            switch (filter.value) {
                case STATUS.ERROR:
                    return {
                        columnName: 'status',
                        operation: 'lessThanOrEqual',
                        value: SERVER_STATUS.ERROR,
                    };
                case STATUS.EMPTY:
                    return {
                        columnName: 'status',
                        operation: 'equal',
                        value: SERVER_STATUS.NO_RESULT,
                    };
                case STATUS.APPROX:
                    return {
                        columnName: 'status',
                        operation: 'equal',
                        value: SERVER_STATUS.DOUBT,
                    };
                case STATUS.EXACT:
                    return {
                        columnName: 'status',
                        operation: 'equal',
                        value: SERVER_STATUS.SUCCESS,
                    };
                default:
                    throw new Error();
            }
        }

        return super.convertFrontFilterToServerFilter(filter);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dsId !== this.props.dsId) {
            this.loadData();
        }
    }

    requestData(ignorePage = false, parentTimer = null) {
        return entityViewManager.loadSourceGeocodingResults(
            this.props.accountId,
            this.props.dsId,
            this.getFilters(),
            this.sorting,
            ignorePage ? 0 : this.state.pagination.current * this.state.pagination.size,
            this.state.pagination.size,
        );
    }

    handleOpenGeoResponse = (row) => {
        this.setState({
            rowToShowGeoResponse: row,
        });
    };

    handleCloseGeoResponse = () => {
        this.setState({
            rowToShowGeoResponse: null,
        });
    };

    handleCloseLinkedRecords = () => {
        this.setState({
            rowToShowLinkedRecords: null,
            linkedRecords: null,
        });
    };

    handleLinkedRecordClick = (row) => {
        this.handleCloseLinkedRecords();
        this.directToSearchPage(row);
    };

    directToSearchPage(useCaseData) {
        if (this.props.onSelectRecord) {
            this.props.onSelectRecord(this.props.dsId, useCaseData['entityApiName'], useCaseData['recordId']);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                {super.render()}

                <Dialog
                    open={this.state.rowToShowGeoResponse !== null}
                    onClose={this.handleCloseGeoResponse}
                    scroll="body"
                >
                    <DialogTitle className="c-form-dialog__title">
                        {t('data_table.geocoding_log_table.row_to_show_geo_response.title')}
                        <IconButton
                            color="default"
                            component="span"
                            onClick={this.handleCloseGeoResponse}
                            className="c-form-dialog__close"
                            data-testid="data_table.geocoding_log_table.row_to_show_geo_response"
                        >
                            <Icon>close_icon</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <pre style={{ fontFamily: 'Courier New, serif', fontSize: '0.75em' }}>
                            {this.state.rowToShowGeoResponse
                                ? JSON.stringify(JSON.parse(this.state.rowToShowGeoResponse.response), null, 2)
                                : ''}
                        </pre>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="default"
                            onClick={this.handleCloseGeoResponse}
                            data-testid="data_table.geocoding_log_table.dialog.close"
                        >
                            {t('dialog.close')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.linkedRecords !== null} onClose={this.handleCloseLinkedRecords} scroll="body">
                    <DialogTitle className="c-form-dialog__title">
                        {t('data_table.geocoding_log_table.linked_records.title')}
                        <IconButton
                            color="default"
                            component="span"
                            onClick={this.handleCloseLinkedRecords}
                            className="c-form-dialog__close"
                            data-testid="data_table.geocoding_log_table.linked_records"
                        >
                            <Icon>close_icon</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.linkedRecords !== null && this.state.linkedRecords.length === 0 && (
                            <DialogContentText>
                                {t('data_table.geocoding_log_table.linked_records.no_records')}
                            </DialogContentText>
                        )}
                        {this.state.linkedRecords !== null && this.state.linkedRecords.length > 0 && (
                            <Table padding="dense">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t('data_table.geocoding_log_table.linked_records.thead.number')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {t('data_table.geocoding_log_table.linked_records.thead.object')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {t('data_table.geocoding_log_table.linked_records.thead.record_id')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {t('data_table.geocoding_log_table.linked_records.thead.record_name')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.linkedRecords.map((row, i) => (
                                        <TableRow
                                            key={row['entityId'] + '_' + row['id']}
                                            hover
                                            onClick={() => this.handleLinkedRecordClick(row)}
                                        >
                                            <TableCell align="center">{i + 1}</TableCell>
                                            <TableCell align="center">{row['entityApiName']}</TableCell>
                                            <TableCell align="center">{row['recordId']}</TableCell>
                                            <TableCell align="left">{row['recordName']}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="default"
                            onClick={this.handleCloseLinkedRecords}
                            data-testid="linked_records.dialog.close"
                        >
                            {t('dialog.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

GeocodingLogTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    dsId: PropTypes.number.isRequired,
    myAccount: PropTypes.bool,
    onError: PropTypes.func,
    onSelectRecord: PropTypes.func,
};

GeocodingLogTable.defaultProps = {
    myAccount: false,
};

export default withTranslation()(GeocodingLogTable);
