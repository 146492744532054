import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class ActionContextNameForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            contextName: props.contextName,
        };
    }

    handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };

    submit() {
        this.props.onSubmitSuccess(this.state.contextName);
    }

    render() {
        return (
            <form autoComplete="off" noValidate>
                <div>{this.props.t('workflow_groups.form.context_name.hint')}</div>
                <TextField
                    label={this.props.t('workflow_groups.form.context_name.label')}
                    data-testid="workflow_groups.form.context_name"
                    fullWidth
                    autoFocus
                    margin="dense"
                    name="contextName"
                    value={this.state.contextName || ''}
                    onChange={this.handleInput}
                />
            </form>
        );
    }
}

ActionContextNameForm.propTypes = {
    contextName: PropTypes.string,
    onSubmitSuccess: PropTypes.func.isRequired,
};

export default withTranslation('translations', { withRef: true })(ActionContextNameForm);
