import { Api, Routing } from 'interfaces';

class RouteSettingsSerializer {
    static denormalizeUserSettings(
        response: Api.Routing.Settings.GetUserSettingsDataApiResponse,
    ): Routing.Settings.MappingRecordValueMap {
        const result: Routing.Settings.MappingRecordValueMap = new Map();
        Object.entries(response).forEach(([key, value]) => result.set(key as Routing.Settings.MappingUserKey, value));
        return result;
    }

    static denormalizeUsersSettings(
        response: Api.Routing.Settings.GetUsersSettingsDataApiResponse,
    ): Routing.Settings.UsersMappingRecordValueMap {
        const result: Routing.Settings.UsersMappingRecordValueMap = new Map();

        for (const key in response) {
            if (!response.hasOwnProperty(key)) {
                continue;
            }
            result.set(Number(key), RouteSettingsSerializer.denormalizeUserSettings(response[key]));
        }
        return result;
    }
}

export default RouteSettingsSerializer;
