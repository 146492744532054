import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Grid, Slider, Typography } from '@material-ui/core';
import subscriptionManager, { SubscriptionManager } from '../../../service/SubscriptionManager';
import Backdrop from '../../Backdrop';
import SubscriptionHistory from '../../Billing/SubscriptionHistory';
import { userManager } from '../../../service/UserManager';
import { formatDateToDefault, utcToUserTimezone } from '../../../utils';
import { UserData } from '../../../service/types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    thumb: {
        cursor: 'default',
    },
};

const StyledSlider = withStyles(styles)(Slider);

interface SubscriptionObject {
    unusedPlannerCredits: number;
    monthlyPlannerCredits: number;
}

interface InfoProps extends WithTranslation {
    accountId: number;
}

interface InfoState {
    loading: boolean;
    creditHistoryOpen: boolean;
    subscription: SubscriptionObject | null;
}

class Info extends React.PureComponent<InfoProps, InfoState> {
    private subscriptionManager: SubscriptionManager;
    private user: UserData | undefined;

    constructor(props: InfoProps) {
        super(props);

        this.state = {
            loading: true,
            creditHistoryOpen: false,
            subscription: null,
        };

        this.subscriptionManager = subscriptionManager.getManager(props.accountId);
        this.user = userManager.getCurrentUser();
    }

    componentDidMount() {
        this.subscriptionManager.getSubscription().then((subscription: any) => {
            this.setState({
                loading: false,
                subscription,
            });
        });
    }

    handleCreditHistoryOpen = () => {
        this.setState({
            creditHistoryOpen: true,
        });
    };

    handleCreditHistoryClose = () => {
        this.setState({
            creditHistoryOpen: false,
        });
    };

    getHistoryFields = () => {
        const { t } = this.props;
        return [
            {
                name: 'validFrom',
                title: t('workflow_actions.list.visit_planner.info.history.date'),
                type: 'date',
                getCellValue: (row: any) => (
                    <span>
                        {this.user ? utcToUserTimezone(row.validFrom, this.user) : formatDateToDefault(row.validFrom)}
                    </span>
                ),
            },
            {
                name: 'unusedPlannerCredits',
                title: t('billing.subscription_history.table.unused_planner_credits'),
                type: 'number',
            },
        ];
    };

    render() {
        const { loading, subscription, creditHistoryOpen } = this.state;
        const { t } = this.props;
        const marks = [];
        if (subscription !== null) {
            marks.push({
                value: 0,
                label: <span>0</span>,
            });
            marks.push({
                value: subscription.monthlyPlannerCredits,
                label: <span>{subscription.monthlyPlannerCredits}</span>,
            });
        }

        const monthlyPlannerCredits = subscription?.monthlyPlannerCredits;
        const unusedPlannerCredits = subscription?.unusedPlannerCredits;

        const show = monthlyPlannerCredits !== undefined && unusedPlannerCredits !== undefined;

        return (
            <React.Fragment>
                {show && (
                    <Backdrop loading={loading}>
                        <Grid container style={{ paddingLeft: 24 }} spacing={5} alignItems={'center'}>
                            <Grid item xs={4}>
                                <Typography gutterBottom>
                                    {t('workflow_actions.list.visit_planner.info.unused_planner_credits')}
                                </Typography>
                                <StyledSlider
                                    min={0}
                                    max={monthlyPlannerCredits}
                                    value={unusedPlannerCredits}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    onChange={() => {}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className="main-button"
                                    onClick={this.handleCreditHistoryOpen}
                                    variant="contained"
                                    data-testid="workflow_actions.list.visit_planner.info.credits_history"
                                >
                                    {t('workflow_actions.list.visit_planner.info.credits_history')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Backdrop>
                )}
                {subscription && (
                    <SubscriptionHistory
                        user={this.user}
                        subscription={subscription}
                        openHistory={creditHistoryOpen}
                        onCloseDialog={this.handleCreditHistoryClose}
                        fields={this.getHistoryFields()}
                    />
                )}
            </React.Fragment>
        );
    }
}

const TranslatedInfo = withTranslation()(Info);
export { TranslatedInfo as VisitPlannerInfo };
