import React, { Suspense, SyntheticEvent } from 'react';
import { fileManager } from '../../service/File/FileManager';
import { MapslyFile, FileType, UploadMethod, FileMeta, ProgressCallback } from 'interfaces/file';
import FileView, { FileButton } from './FileView';
import { Box, FormHelperText, Grid, Icon, IconButton, Link } from '@material-ui/core';
import './style.css';
import ImgsViewer from 'react-images-viewer';
import FileInputButton from './FileInputButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { WithTranslation, withTranslation } from 'react-i18next';
import DrawingModal from './DrawingModal';
import { isLandscapeOrientation, weAreInNativeApp } from '../../utils';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import AudioVideoModal from './AudioVideo/AudioVideoModal';
import { userManager } from '../../service/UserManager';
import { getTotalTranscriptionSeconds } from '../Billing/utils';
import Alert from '../Alert';
import ParameterizedMessage from '../ParameterizedMessage';
import { intercomManager } from '../../service/IntercomManager';

interface FileCollectionProps extends WithTranslation, WithSnackbarProps {
    accountId: number;
    files: MapslyFile[];
    onChange?: (files: MapslyFile[]) => void;
    onUploadProgress?: (inProgress: boolean) => void;
    minCount?: number;
    maxCount?: number;
    fileMeta?: FileMeta;
    allowedTypes?: FileType[];
    allowedMethods?: UploadMethod[];
    fileFieldLabel?: string;
    size?: 'small' | 'medium';
    readonly?: boolean;
    instantDelete?: boolean;
    loading?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    className?: string;
}

interface FileCollectionState {
    currentImage: number | null;
    currentFile: MapslyFile | null;
    inProgress: Set<number>;
    warning: string | null;
    drawingMode: boolean;
    downloading: boolean;
    hasTranscriptionMinutesLimitReachedAlert: boolean;
}

class FileCollection extends React.PureComponent<FileCollectionProps, FileCollectionState> {
    constructor(props: FileCollectionProps) {
        super(props);

        this.state = {
            currentImage: null,
            currentFile: null,
            inProgress: new Set(),
            warning: null,
            drawingMode: false,
            downloading: false,
            hasTranscriptionMinutesLimitReachedAlert: false,
        };
    }
    componentDidMount() {
        if (weAreInNativeApp()) {
            window.addEventListener('orientationchange', this.orientationChangeListener);
        }
    }

    componentWillUnmount() {
        if (weAreInNativeApp()) {
            window.removeEventListener('orientationchange', this.orientationChangeListener);
        }
    }

    orientationChangeListener = () => {
        if (isLandscapeOrientation()) {
            this.openDrawingModal();
        }
    };

    getImages = () => {
        return this.props.files.filter((file) => file.type === FileType.Image && file.url);
    };

    handleAdd = (files: MapslyFile[]) => {
        let allowedFiles;
        let warning = null;
        let uploaded = 0;

        if (this.props.maxCount) {
            allowedFiles = [...this.props.files];
            for (const file of files) {
                if (allowedFiles.length >= this.props.maxCount) {
                    warning = this.props.t('file_input.warning.partial_upload', {
                        uploaded,
                        selected: files.length,
                        max: this.props.maxCount,
                    });
                    break;
                }
                allowedFiles.push(file);
                uploaded++;
            }
        } else {
            allowedFiles = this.props.files.concat(files);
        }

        this.setState({ warning });
        this.props.onChange && this.props.onChange(allowedFiles);
    };

    private filterFiles = (files: MapslyFile[]): MapslyFile[] => {
        return files.map((file) => {
            if (file.id) {
                delete file.blob;
                delete file.thumbnailBlob;
            }
            return file;
        });
    };

    handleDelete = (index: number) => {
        const files = [...this.props.files];
        files.splice(index, 1);
        this.props.onChange && this.props.onChange(this.filterFiles(files));
    };

    handleUploadStart = (_file: MapslyFile, index: number) => {
        this.setState(
            (state) => {
                const inProgress = new Set(state.inProgress);
                inProgress.add(index);
                return { inProgress };
            },
            () => {
                if (this.props.onUploadProgress && this.state.inProgress.size > 0) {
                    this.props.onUploadProgress(true);
                }
            },
        );
    };

    handleUploadFinish = (file: MapslyFile, index: number) => {
        if (
            !this.state.hasTranscriptionMinutesLimitReachedAlert &&
            file.isTranscriptEnabled &&
            file.duration &&
            (file.duration as number) > 0 &&
            [FileType.Audio, FileType.Video].includes(file.type)
        ) {
            const subscription = userManager.getCurrentAccount().subscription;
            const totalTranscriptionSeconds = getTotalTranscriptionSeconds(subscription);
            const availableTranscriptionSeconds = totalTranscriptionSeconds - subscription.wastedTranscriptionSeconds;
            const duration = Math.ceil(file.duration as number);

            if (availableTranscriptionSeconds < duration) {
                this.setState({
                    hasTranscriptionMinutesLimitReachedAlert: true,
                });
            }
        }

        this.setState(
            (state) => {
                const inProgress = new Set(state.inProgress);
                inProgress.delete(index);
                return { inProgress };
            },
            () => {
                if (this.props.onUploadProgress && this.state.inProgress.size === 0) {
                    this.props.onUploadProgress(false);
                }
            },
        );
        this.handleUpdate(file, index);
    };

    handleUpdate = (file: MapslyFile, index: number) => {
        const files = [...this.props.files];
        files[index] = file;
        this.props.onChange && this.props.onChange(this.filterFiles(files));
    };

    handleClick = (file: MapslyFile) => {
        switch (file.type) {
            case FileType.Video:
            case FileType.Audio:
                this.setState({ currentFile: file });
                break;
            default:
                const index = this.getImages().findIndex((element) => element === file);
                this.setState({ currentImage: index });
        }
    };

    handleDownload = (file: MapslyFile, progressCallback?: ProgressCallback): Promise<unknown> => {
        if (!file.url) {
            return Promise.resolve();
        }

        this.setState({ downloading: true });

        return fileManager
            .download(this.props.accountId, file, progressCallback)
            .catch((error: Error) => {
                this.props.enqueueSnackbar(error.message ?? error, { variant: 'error' });
            })
            .finally(() => {
                this.setState({ downloading: false });
            });
    };

    handleViewerDownload = (images: MapslyFile[], index: number | null) => {
        if (index !== null && images[index]) {
            this.handleDownload(images[index]);
        }
    };

    handleCloseImg = () => {
        this.setState({ currentImage: null });
    };

    handlePrevImg = () => {
        this.setState((state) => {
            if (state.currentImage !== null) {
                return { currentImage: state.currentImage - 1 };
            }
            return { currentImage: null };
        });
    };

    handleNextImg = () => {
        this.setState((state) => {
            if (state.currentImage !== null) {
                return { currentImage: state.currentImage + 1 };
            }
            return { currentImage: null };
        });
    };

    openDrawingModal = () => {
        this.setState({ drawingMode: true });
    };

    closeDrawingModal = () => {
        this.setState({ drawingMode: false });
    };

    handleDrawingSave = (file: Blob, filename: string) => {
        const mapslyFile = fileManager.createFile(file, filename);
        this.handleAdd([mapslyFile]);
        this.closeDrawingModal();
    };

    showUploadButton = (isEditable: boolean) => {
        const { allowedTypes = [], allowedMethods = [] } = this.props;
        if (allowedTypes.length === 0 && allowedMethods.length === 0) {
            return isEditable;
        }

        return isEditable && (allowedTypes.includes(FileType.Document) || allowedMethods.includes(UploadMethod.Camera));
    };

    showDrawButton = (isEditable: boolean) => {
        const { allowedMethods = [] } = this.props;
        return isEditable && allowedMethods.includes(UploadMethod.Draw);
    };

    raiseTranscriptionMinutes = (e: SyntheticEvent) => {
        e.preventDefault();
        intercomManager.showNewMessage(
            this.props.t('billing.subscription_panel.change_transcription_minutes_intercom'),
        );
    };

    render() {
        const { warning } = this.state;
        const {
            files,
            accountId,
            maxCount,
            fileMeta,
            fileFieldLabel,
            allowedTypes = [],
            size = 'medium',
            readonly = false,
            instantDelete = false,
            loading = false,
            error = false,
            helperText,
            className,
        } = this.props;

        const images = this.getImages();

        const isEditable = !readonly && (!maxCount || files.length < maxCount);

        return (
            <React.Fragment>
                {this.state.hasTranscriptionMinutesLimitReachedAlert && (
                    <Alert type="warning" placement="popover">
                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', margin: '0 16px' }}>
                            <div>
                                <Icon className="icon-warning">warning</Icon>
                            </div>
                            <div>
                                <ParameterizedMessage
                                    text={this.props.t(
                                        'file_input.file_collection.transcription_minutes_limit_reached',
                                    )}
                                    params={{
                                        a: (text: Node | string) => (
                                            <Link href="#" underline="always" onClick={this.raiseTranscriptionMinutes}>
                                                {text}
                                            </Link>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </Alert>
                )}

                <Grid
                    container
                    className={className}
                    alignItems="center"
                    spacing={size === 'small' ? 0 : 1}
                    style={{ position: 'relative', opacity: loading ? 0.5 : 1 }}
                >
                    {files
                        .filter((file) => typeof file === 'object' && file?.type)
                        .map((file, index) => {
                            return (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <FileView
                                        accountId={accountId}
                                        key={index}
                                        index={index}
                                        file={file}
                                        onDelete={this.handleDelete}
                                        onUploadStart={this.handleUploadStart}
                                        onUploadComplete={this.handleUploadFinish}
                                        onUploadError={this.handleUploadFinish}
                                        onUpdateComplete={this.handleUpdate}
                                        onClick={this.handleClick}
                                        onDownload={this.handleDownload}
                                        size={size}
                                        readonly={readonly}
                                        instantDelete={instantDelete}
                                        disableDownload={this.state.downloading}
                                    />
                                </Suspense>
                            );
                        })}

                    {this.showUploadButton(isEditable) && (
                        <Grid item style={{ position: 'relative', marginTop: 20 }}>
                            <FileInputButton
                                onFileChange={this.handleAdd}
                                fileMeta={fileMeta}
                                allowedTypes={allowedTypes}
                                multiple={!!(maxCount && maxCount > 1)}
                            >
                                <FileButton
                                    component="div"
                                    size={this.props.size}
                                    className={this.props.size}
                                    data-testid="file_input.file_collection.input_btn"
                                    disableFocusRipple
                                >
                                    <div className={clsx('file-container', 'upload-btn', error && 'error')}>
                                        <Icon
                                            className="fas fa-upload"
                                            fontSize="large"
                                            color={error ? 'error' : 'action'}
                                        />
                                    </div>
                                </FileButton>
                            </FileInputButton>
                        </Grid>
                    )}
                    {this.showDrawButton(isEditable) && (
                        <Grid item style={{ position: 'relative', marginTop: 20 }}>
                            <FileButton
                                component="div"
                                size={this.props.size}
                                className={this.props.size}
                                data-testid="file_input.file_collection.drawing_modal"
                                onClick={this.openDrawingModal}
                                disableFocusRipple
                            >
                                <div className={clsx('file-container', 'upload-btn', error && 'error')}>
                                    <Icon
                                        className="fas fa-signature"
                                        fontSize="large"
                                        color={error ? 'error' : 'action'}
                                    />
                                </div>
                            </FileButton>
                        </Grid>
                    )}

                    {loading && (
                        <div className="file-collection-loading">
                            <CircularProgress size={30} />
                        </div>
                    )}
                </Grid>

                {warning && (
                    <Box color="warning.main">
                        <FormHelperText style={{ color: 'inherit' }}>{warning}</FormHelperText>
                    </Box>
                )}
                {helperText && <FormHelperText error>{helperText}</FormHelperText>}

                <DrawingModal
                    open={this.state.drawingMode}
                    onSave={this.handleDrawingSave}
                    onCancel={this.closeDrawingModal}
                    label={fileFieldLabel}
                />

                {this.state.currentImage !== null && (
                    <ImgsViewer
                        imgs={images.map((file) => ({ src: file.url, caption: file.name }))}
                        currImg={this.state.currentImage}
                        isOpen={true}
                        onClickPrev={this.handlePrevImg}
                        onClickNext={this.handleNextImg}
                        onClose={this.handleCloseImg}
                        customControls={[
                            <IconButton
                                key="download"
                                aria-label="download"
                                onClick={() => this.handleViewerDownload(images, this.state.currentImage)}
                                disabled={this.state.downloading}
                                style={{ color: 'white' }}
                            >
                                {this.state.downloading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <Icon className="fas fa-download" fontSize="small" />
                                )}
                            </IconButton>,
                        ]}
                    />
                )}

                {this.state.currentFile !== null && (
                    <AudioVideoModal
                        accountId={this.props.accountId}
                        file={this.state.currentFile}
                        onClose={() => {
                            this.setState({ currentFile: null });
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSnackbar(FileCollection));
