import QueueHandler from '../QueueHandler';
import AbstractEvent, { EventStatus } from '../Events/AbstractEvent';

class AbstractProcessor {
    protected failEvent(event: AbstractEvent, error: object): void {
        QueueHandler.onUpdateEvent(event, EventStatus.FAILED, error);
    }
}

export default AbstractProcessor;
