import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';
import { addDays, format } from 'date-fns';

import { DATE_CHANGE_TYPES } from 'components/constants';

import { withDateTimePickerTextField } from '../withDateTimePickerTextField/withDateTimePickerTextField';

const REFUSE_REG = /[^. ,[a-zA-Z0-9_]*$]+/;
const MATCH_DATE_REG = /(^[a-zA-Z]{0,3}$)|(^[a-zA-Z]{3} {0,1}$)|(^[a-zA-Z]{3} {1}\d{0,2}$)/;
const INCORRECT_DAY_REG = /(^[a-zA-Z]{3} {1}\d{3}$)/;
const INCORRECT_MONTH_REG = /[a-zA-Z]{4}/;

type DatePickerTextFieldType = 'primary' | 'secondary';

export type DatePickerTextFieldProps = WithTranslation &
    Omit<KeyboardDatePickerProps, 'size' | 'views' | 'openTo' | 'onChange'> & {
        value: Date | null;
        getArrows: (func: (isNext?: boolean) => () => void) => void;
        onChange: (value: Date | null, changeType: DATE_CHANGE_TYPES) => void;
        type: DatePickerTextFieldType;
        className?: string;
    };

interface DatePickerTextFieldState {}

class DatePickerTextField extends React.PureComponent<DatePickerTextFieldProps, DatePickerTextFieldState> {
    getRifmFormatter = (val: string) => {
        if (val.match(MATCH_DATE_REG)) {
            return val;
        }

        if (val.match(INCORRECT_DAY_REG)) {
            return val.replace(/.$/, '');
        }

        const valList = val.split(' ');

        const month = valList[0];

        if (month?.length > 3 && month.match(INCORRECT_MONTH_REG)) {
            return month.replace(/.$/, '') + ' ' + (valList[1] || '');
        }

        return format(this.props.value as Date, 'LLL dd');
    };

    onClickArrow = (isNext?: boolean) => () => {
        this.props.onChange(addDays(this.props.value || new Date(), isNext ? 1 : -1), DATE_CHANGE_TYPES.date);
    };

    onChangeDate = (value: Date | null) => {
        this.props.onChange(value, DATE_CHANGE_TYPES.date);
    };

    render() {
        const { getArrows, ...props } = this.props;

        return (
            <>
                <KeyboardDatePicker
                    {...props}
                    onChange={this.onChangeDate}
                    refuse={REFUSE_REG}
                    rifmFormatter={this.getRifmFormatter}
                    format="LLL dd"
                    keyboardIcon={<TodayIcon />}
                />
                {getArrows(this.onClickArrow)}
            </>
        );
    }
}

export default withTranslation()(withDateTimePickerTextField(DatePickerTextField));
