import { LineCapShape } from 'leaflet';

export interface LineStyle {
    dashArray: string;
    lineCap: LineCapShape;
    width: number;
}

type StyleType = {
    dashArray: string;
    lineCap: LineCapShape;
};

const STYLES: Array<StyleType> = [
    {
        dashArray: '',
        lineCap: 'round',
    },
    {
        dashArray: '6 20',
        lineCap: 'square',
    },
    {
        dashArray: '1 20',
        lineCap: 'round',
    },
    {
        dashArray: '1 20 20 20',
        lineCap: 'round',
    },
    {
        dashArray: '25',
        lineCap: 'square',
    },
    {
        dashArray: '40 20 1 20',
        lineCap: 'round',
    },
    {
        dashArray: '40 20 1 20 1 20',
        lineCap: 'round',
    },
    {
        dashArray: '40 20 1 20 1 20 1 20',
        lineCap: 'round',
    },
    {
        dashArray: '30 25 6 25',
        lineCap: 'square',
    },
];

const WIDTHS: Array<number> = [6, 9, 12];

export class LineStyleGenerator {
    private stylesIterator: Iterator<StyleType>;
    private widthsIterator: Iterator<number>;
    private currentWidthIteration: IteratorResult<number>;

    constructor() {
        this.stylesIterator = STYLES[Symbol.iterator]();
        this.widthsIterator = WIDTHS[Symbol.iterator]();
        this.currentWidthIteration = this.widthsIterator.next();
    }

    getLineStyle(): LineStyle {
        let styleIteration = this.stylesIterator.next();
        if (styleIteration.done) {
            if (this.currentWidthIteration) {
                this.iterateWidth();
            }
            this.stylesIterator = STYLES[Symbol.iterator]();
            styleIteration = this.stylesIterator.next();
        }

        return {
            dashArray: styleIteration.value.dashArray,
            lineCap: styleIteration.value.lineCap,
            width: this.currentWidthIteration.value,
        };
    }

    private iterateWidth() {
        let currentWidthIteration = this.widthsIterator.next();
        if (currentWidthIteration.done) {
            this.widthsIterator = WIDTHS[Symbol.iterator]();
            currentWidthIteration = this.widthsIterator.next();
        }
        this.currentWidthIteration = currentWidthIteration;
    }
}
