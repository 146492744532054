import { action, computed, makeObservable, observable } from 'mobx';
import { Routing } from 'interfaces';

type LoadedSessionRouteManagerKeys = '_loadedSessionRoute';

/**
 * Отображение опубликованного роута.
 */
class LoadedSessionRouteManager {
    private _loadedSessionRoute: Routing.Route.LoadedSessionRoute | null = null;

    constructor() {
        makeObservable<LoadedSessionRouteManager, LoadedSessionRouteManagerKeys>(this, {
            _loadedSessionRoute: observable,
            route: computed,
            init: action,
            reset: action,
        });
    }

    init(loadedSessionRoute: Routing.Route.LoadedSessionRoute): void {
        this._loadedSessionRoute = loadedSessionRoute;
    }

    get isInit(): boolean {
        return this._loadedSessionRoute !== null;
    }

    get route(): Routing.Route.Route {
        if (this._loadedSessionRoute === null || this._loadedSessionRoute.route === null) {
            throw new Error('Route not set in report panel.');
        }
        return this._loadedSessionRoute.route;
    }

    reset(): void {
        this._loadedSessionRoute = null;
    }
}

export default LoadedSessionRouteManager;
