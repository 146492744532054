export default class Location {
    private readonly _id: string;
    private readonly _longitude: number;
    private readonly _latitude: number;
    private readonly _timestamp: string;
    private readonly _accuracy: number;
    private readonly _altitude: number | null;
    private readonly _altitudeAccuracy: number | null;
    private readonly _heading: number | null;
    private readonly _speed: number | null;

    constructor(
        id: string,
        longitude: number,
        latitude: number,
        timestamp: string,
        accuracy: number,
        altitude: number | null,
        altitudeAccuracy: number | null,
        heading: number | null,
        speed: number | null,
    ) {
        this._id = id;
        this._longitude = longitude;
        this._latitude = latitude;
        this._timestamp = timestamp;
        this._accuracy = accuracy;
        this._altitude = altitude;
        this._altitudeAccuracy = altitudeAccuracy;
        this._heading = heading;
        this._speed = speed;
    }

    get id(): string {
        return this._id;
    }

    get longitude(): number {
        return this._longitude;
    }

    get accuracy(): number {
        return this._accuracy;
    }

    get timestamp(): string {
        return this._timestamp;
    }

    get latitude(): number {
        return this._latitude;
    }

    get altitude(): number | null {
        return this._altitude;
    }

    get altitudeAccuracy(): number | null {
        return this._altitudeAccuracy;
    }

    get heading(): number | null {
        return this._heading;
    }

    get speed(): number | null {
        return this._speed;
    }
}
