import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from '../TabPanel';
import EventLog from '../EventLog';
import ExecutionLog from './ExecutionLog';
import DataExchangeSessions from './DataExchangeLog/DataExchangeSessions';
import qs from 'qs';

export const EVENT_LOG_TAB = 'event_log';
export const DATA_EXCHANGE_LOG_TAB = 'data_exchange_log';
export const EXECUTION_LOG_TAB = 'execution_log';

export const PARAM_TAB = 'tab';
export const PARAM_FILTERS = 'filters';
export const PARAM_OPEN_SESSION = 'open_session';

class Log extends React.PureComponent {
    constructor(props) {
        super(props);

        const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true }) || {};
        const { tab, ...rest } = searchParams;

        this.state = {
            currentTab: tab || EVENT_LOG_TAB,
            searchParams: rest,
        };
    }

    handleChangeTab = (e, tab) => {
        this.setState({ currentTab: tab });
    };

    render() {
        const { currentTab, searchParams } = this.state;
        return (
            <React.Fragment>
                <h1>{this.props.t('logs.header')}</h1>

                <Tabs
                    indicatorColor="primary"
                    value={this.state.currentTab}
                    onChange={this.handleChangeTab}
                    variant="scrollable"
                >
                    <Tab value={EVENT_LOG_TAB} label={this.props.t('logs.tabs.event_log')} />
                    <Tab value={DATA_EXCHANGE_LOG_TAB} label={this.props.t('logs.tabs.data_exchange_log')} />
                    <Tab value={EXECUTION_LOG_TAB} label={this.props.t('logs.tabs.execution_log')} />
                </Tabs>

                <TabPanel index={EVENT_LOG_TAB} value={this.state.currentTab}>
                    <EventLog accountId={this.props.accountId} user={this.props.user} isDebugShow />
                </TabPanel>
                <TabPanel index={DATA_EXCHANGE_LOG_TAB} value={this.state.currentTab}>
                    <DataExchangeSessions
                        accountId={this.props.accountId}
                        user={this.props.user}
                        searchParams={currentTab === DATA_EXCHANGE_LOG_TAB && searchParams}
                    />
                </TabPanel>
                <TabPanel index={EXECUTION_LOG_TAB} value={this.state.currentTab}>
                    <ExecutionLog
                        accountId={this.props.accountId}
                        user={this.props.user}
                        searchParams={currentTab === EXECUTION_LOG_TAB && searchParams}
                    />
                </TabPanel>
            </React.Fragment>
        );
    }
}

Log.propTypes = {
    accountId: PropTypes.number.isRequired,
    isDebugShow: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};
Log.defaultProps = {
    isDebugShow: false,
};

export default withTranslation()(withRouter(withSnackbar(Log)));
