import React from 'react';
import { Grid, TextField, Icon, IconButton } from '@material-ui/core';
import { isFilterParam, isUrlParam } from '../Expression';
import MultiplePicklistConstantInput from './MultiplePicklistConstantInput';
import SinglePicklistConstantInput from './SinglePicklistConstantInput';
import DateConstantInput from './DateConstantInput';
import CommonConstantInput from './CommonConstantInput';
import { SelectedValues } from './SelectedValues';
import { withTranslation } from 'react-i18next';
import LookupConstantInput from './LookupConstantInput';
import PolymorphicLookupConstantInput from './PolymorphicLookupConstantInput';

class ConstantInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: null, // в режиме немассива всегда null
        };
    }

    handleChange = (value) => {
        const { field, onChange } = this.props;
        // если не массив, то отправляем событие со значением
        if ((field && field.picklist) || (field && field.lookupData) || !Array.isArray(this.props.value)) {
            onChange(value);
            return;
        }
        // если массив, то сохраняем значение в состоянии и будем отдавать при клике
        this.setState({
            value,
        });
    };

    handleAdd = () => {
        const value = this.state.value || this.props.defaultValue;
        this.setState({
            value: null,
        });

        const n = [...this.props.value];
        n.push(value);

        this.props.onChange(n);
    };

    handleRemove = (index) => {
        const n = [...this.props.value];
        n.splice(index, 1);

        this.props.onChange(n);
    };

    render() {
        const { type, field, error, multiple, isTwig, callContext, disabled, t } = this.props;

        const { value } = this.props;
        if (field && field.picklist && isTwig !== true) {
            const isDynamicParam = isUrlParam(value) || isFilterParam(value);
            const picklist = [...field.picklist];
            if (!!value && typeof value === 'string' && !picklist.find((i) => i.value === value)) {
                picklist.push({
                    label: value,
                    value,
                });
            }
            if (isDynamicParam) {
                return (
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('expresion_builder.value')}
                            data-testid="expresion_builder.value"
                            value={value}
                            disabled={disabled}
                        />
                    </div>
                );
            }
            if (multiple) {
                return (
                    <div>
                        <MultiplePicklistConstantInput
                            picklist={picklist}
                            value={value}
                            onChange={this.handleChange}
                            disabled={disabled}
                        />
                    </div>
                );
            } else {
                return (
                    <div>
                        <SinglePicklistConstantInput
                            picklist={picklist}
                            value={value}
                            onChange={this.handleChange}
                            disabled={disabled}
                        />
                    </div>
                );
            }
        }

        if (field && field.lookupData && field.apiName.endsWith('_ID') && isTwig !== true) {
            const lookupData = field.lookupData;

            let lookupValue = value;

            if (lookupData.type === 'polymorphic') {
                return (
                    <PolymorphicLookupConstantInput
                        availableEntities={lookupData.data}
                        value={lookupValue}
                        onChange={this.handleChange}
                        multiple={multiple || lookupData.type === 'multiple' || lookupData.type === 'few'}
                    />
                );
            }

            return (
                <LookupConstantInput
                    ownerLookup={lookupData.type === 'owner'}
                    lookupEntity={lookupData.apiName}
                    value={lookupValue}
                    onChange={this.handleChange}
                    multiple={multiple || lookupData.type === 'multiple' || lookupData.type === 'few'}
                />
            );
        }

        const currentValue = multiple ? this.state.value || this.props.defaultValue : this.props.value;
        const currentError = multiple || typeof error !== 'string' ? undefined : error;

        return (
            <div>
                <Grid container alignItems="center">
                    <Grid item>
                        {(type === 'date' || type === 'datetime') && isTwig !== true ? (
                            <DateConstantInput
                                value={currentValue}
                                error={currentError}
                                showTimeControl={type === 'datetime'}
                                onChange={this.handleChange}
                                disabled={disabled}
                            />
                        ) : (
                            <CommonConstantInput
                                value={currentValue}
                                error={currentError}
                                onChange={this.handleChange}
                                disabled={disabled}
                                isTwig={isTwig}
                                callContext={callContext}
                            />
                        )}
                    </Grid>
                    {multiple && (
                        <Grid item>
                            <IconButton
                                onClick={this.handleAdd}
                                disabled={disabled}
                                data-testid="expresion_builder.add"
                            >
                                <Icon>add</Icon>
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                {multiple && (
                    <SelectedValues
                        value={this.props.value}
                        error={error}
                        onRemove={this.handleRemove}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ConstantInput);
