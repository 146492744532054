/**
 * Строка с датой в таймзоне UTC. Приходит с бекенда.
 */
export type DateString = string;

export enum DistanceMetric {
    MILE = 'mile',
    KM = 'km',
}

export enum MobilePlatform {
    IOS = 'ios',
    ANDROID = 'android',
}
