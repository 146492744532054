import { alpha, ButtonBase, createStyles, Icon, IconProps, Theme, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps, forwardRef, ForwardRefRenderFunction, memo } from 'react';
import { Typography } from '../UI';

interface PointActionButtonProps extends WithStyles<typeof styles> {
    color?: IconProps['color'];
    textColor?: ComponentProps<typeof Typography>['color'];
    disabled?: boolean;
    iconClassName?: string;
    iconFontSize?: IconProps['fontSize'];
    label: string;
    title?: string;
    onClick?: () => any;
}

const PointActionButton: ForwardRefRenderFunction<HTMLDivElement, PointActionButtonProps> = (props, ref) => {
    const {
        classes,
        color = 'primary',
        disabled = false,
        iconClassName,
        iconFontSize = 'default',
        label,
        textColor = 'linkDefault',
        title,
        ...rest
    } = props;

    return (
        <div className={classes.root} ref={ref}>
            <ButtonBase disabled={disabled} classes={{ root: classes.button, disabled: classes.disabled }} {...rest}>
                {false && <Icon className={clsx(classes.icon, iconClassName)} color={color} fontSize={iconFontSize} />}
                <div className={classes.iconWrapper}>
                    <i className={clsx(classes.icon, iconClassName)} />
                </div>
                <Typography className={classes.label} variant="bodyXS400" color={textColor} noWrap>
                    {label}
                </Typography>
            </ButtonBase>
        </div>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '90px',
            maxWidth: '90px',
            minWidth: '0px',
            paddingInline: '4px',
            paddingBottom: '4px',
            flex: 1,
        },
        button: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            width: '100%',
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '& $disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '& $disabled': {
                color: theme.palette.action.disabled,
            },
        },
        iconWrapper: {
            padding: '4px',
        },
        icon: {
            overflow: 'visible',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            color: theme.palette.button.primary.main,
        },
        label: {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellpsis',
        },
        disabled: {
            '& > $icon': {
                color: theme.palette.action.disabled,
            },
            '& > $label': {
                color: theme.palette.action.disabled,
            },
        },
    });

export default memo(withStyles(styles, { name: 'PointActionButton' })(forwardRef(PointActionButton)));
