import clsx from 'clsx';
import React from 'react';

interface Props {
    text: string;
    onRemove: (text: string) => void;
    disabled?: boolean;
    className?: string;
}

class Tag extends React.PureComponent<Props, any> {
    handleOnRemove = (e: React.MouseEvent<HTMLElement>) => {
        const { text } = this.props;

        e.stopPropagation();
        this.props.onRemove(text);
    };

    render() {
        const { className, text, disabled } = this.props;

        return (
            <span className={clsx('field_tag__tag', className)}>
                <span className={'field_tag__tag__text'}>{text}</span>

                {!disabled && (
                    <button type="button" onClick={this.handleOnRemove} aria-label={`remove ${text}`}>
                        &#10005;
                    </button>
                )}
            </span>
        );
    }
}

export default Tag;
