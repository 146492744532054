import React from 'react';
import debounce from 'lodash/debounce';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import EntityManager from '../../service/EntityManager';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';
import dsManagerFactory from '../../service/DsManager';

const MIN_SEARCH_LENGTH_TO_HANDLE = 2;

class SearchableEntitiesListForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entities: [],
            foundEntities: [],
            search: '',
            searchProcessing: false,
        };

        this.entityManager = EntityManager.getManager(this.props.accountId, this.props.dataSourceId);
        this.dsManager = dsManagerFactory.getManager(this.props.accountId);
        this.handleSearchBounce = debounce(this.handleSearch, 500);
    }

    componentDidMount = () => {
        const { dataSourceId } = this.props;
        this.props.onPreloadInit();

        const ds = this.dsManager.load(dataSourceId);
        if (ds.isSystem) {
            this.setEntities(
                ds.entityCounters.map((counter) => {
                    return {
                        label: counter.entity.label,
                        apiName: counter.entity.apiName,
                    };
                }),
            );
            this.props.onPreloadSuccess();
            return;
        }

        dispatcher.subscribe(events.ENTITIES_AVAILABLE_FOR_IMPORT_LOADED, this, (data) => {
            if (dataSourceId !== data.dataSourceId) {
                return;
            }
            if (this.state.entities.length === 0) {
                this.setEntities(data.entities);
            }
            this.props.onPreloadSuccess();
        });

        this.entityManager.loadEntitiesAvailableForImport(dataSourceId).catch((error) => {
            const { message } = getMessageAndDetails(error);
            this.props.onPreloadError(message);
        });
    };

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    setEntities = (entities) => {
        entities = entities.map((entity) => {
            const propEntity = this.props.entities.find((propEntity) => propEntity.name === entity.name);
            return {
                ...entity,
                nameLowerCase: entity.name.toLowerCase(),
                labelLowerCase: entity.label.toLowerCase(),
                isIncluded: propEntity?.isIncluded || false,
            };
        });
        this.setState({ entities });
    };

    handleSearchChange = (event) => {
        let search = event.currentTarget.value;
        this.setState(
            {
                search,
                searchProcessing: true,
            },
            () => {
                this.handleSearchBounce(search);
            },
        );
    };

    handleSearch = (search) => {
        if (search !== this.state.search) {
            return;
        }
        let foundEntities = [];
        if (search.length >= MIN_SEARCH_LENGTH_TO_HANDLE) {
            foundEntities = this.state.entities.filter((entity) => {
                let lowerSearch = search.toLowerCase();
                return entity.labelLowerCase.includes(lowerSearch) || entity.nameLowerCase.includes(lowerSearch);
            });
        }
        this.setState({
            foundEntities,
            searchProcessing: false,
        });
    };

    submit() {
        const { onSubmitSuccess } = this.props;
        onSubmitSuccess && onSubmitSuccess(this.state.entities);
    }

    handleEntityChecked(entity) {
        let index = this.state.entities.indexOf(entity);
        if (index === -1) {
            return;
        }
        entity = { ...entity };
        entity.isIncluded = !entity.isIncluded;

        this.setState((state) => {
            const entities = [...state.entities];
            entities[index] = entity;
            return {
                entities,
            };
        });
    }

    handleSearchClear = () => {
        this.setState({
            search: '',
            foundEntities: [],
            searchProcessing: false,
        });
    };

    render() {
        const { search, searchProcessing, foundEntities, entities } = this.state;

        let renderEntities = entities;
        let foundLabel = '';

        if (searchProcessing === false) {
            if (search.length >= MIN_SEARCH_LENGTH_TO_HANDLE) {
                foundLabel = this.props.t('ssu.entities.search.label_found', { count: foundEntities.length });
            }
        }

        if (foundEntities.length) {
            let foundEntitiesNames = foundEntities.map((foundEntity) => {
                return foundEntity.name;
            });
            renderEntities = entities.filter((entity) => {
                return foundEntitiesNames.includes(entity.name);
            });
        }

        let showClearButton = false;
        if (search.length) {
            showClearButton = true;
        }

        return (
            <div>
                {!!renderEntities.length && (
                    <FormControl fullWidth margin="dense">
                        <InputLabel htmlFor="search" shrink>
                            {this.props.t('ssu.entities.search.label')} {foundLabel}
                        </InputLabel>
                        <Grid container alignItems="flex-end">
                            <Grid item sm={11}>
                                <TextField
                                    onChange={this.handleSearchChange}
                                    fullWidth
                                    margin="normal"
                                    name="search"
                                    value={search}
                                    placeholder={this.props.t('ssu.entities.search.placeholder')}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item sm={1} style={{ textAlign: 'end' }}>
                                {showClearButton && (
                                    <IconButton
                                        onClick={this.handleSearchClear}
                                        data-testid="ssu.entities.search.clear"
                                    >
                                        <Icon>close</Icon>
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                )}
                {renderEntities.map((entity, index) => {
                    let isLocked = !!entity.isLockedBy && !!entity.isLockedBy.length;
                    return (
                        <div key={index} className="c-signup-entity">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={entity.isIncluded}
                                        onClick={() => {
                                            this.handleEntityChecked(entity);
                                        }}
                                        disabled={isLocked}
                                        color="primary"
                                    />
                                }
                                label={entity.label}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

SearchableEntitiesListForm.propTypes = {
    accountId: PropTypes.number.isRequired,
    dataSourceId: PropTypes.number.isRequired,
    entities: PropTypes.array.isRequired,
    onSubmitSuccess: PropTypes.func,
    onPreloadInit: PropTypes.func.isRequired,
    onPreloadSuccess: PropTypes.func.isRequired,
    onPreloadError: PropTypes.func.isRequired,
};

export default withTranslation('translations', { withRef: true })(SearchableEntitiesListForm);
