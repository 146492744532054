import { Subscription } from '../../interfaces/account';

export const getTotalTranscriptionSeconds = (subscription: Subscription): number => {
    const perUser = subscription.plan.transcriptionSecondsPerUser;
    const numberOfUsers = subscription.transcriptionUsers;
    const additional = subscription.additionalTranscriptionSeconds;
    const custom = subscription.additionalCustomTranscriptionSeconds;

    return perUser * numberOfUsers + additional + custom;
};

export const getTotalTranscriptionMinutes = (subscription: Subscription): number => {
    return Math.floor(getTotalTranscriptionSeconds(subscription) / 60);
};
