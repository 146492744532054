import { DataSourceEntityCounterMap, FieldType } from 'components/types';
import { DataSourceEntityCounterEntityField } from 'service/types';
import { Routing } from 'interfaces';

const picklistFieldTypes = new Set<string>([FieldType.JSON_ARRAY, FieldType.JSON, FieldType.STRINGS]);

class RoutingSettingsFilterFieldsService {
    filterFieldsByType(
        filterFieldsType: Routing.Settings.MappingFilterFieldsType,
        fields: DataSourceEntityCounterEntityField[],
    ): DataSourceEntityCounterEntityField[] {
        const picklistFields = fields.filter((field) => {
            if (!filterFieldsType.picklistTypes.size || !field.hasPicklist) {
                return false;
            }

            if (filterFieldsType.picklistTypes.has(Routing.Settings.MappingFieldPicklistType.MULTI_PICKLIST)) {
                return picklistFieldTypes.has(field.type);
            }

            return filterFieldsType.picklistTypes.has(Routing.Settings.MappingFieldPicklistType.PICKLIST);
        });

        const filteredFields = fields.filter((field) => filterFieldsType.types.has(field.type)).concat(picklistFields);

        const result = new Map<number, DataSourceEntityCounterEntityField>();
        filteredFields.forEach((field) => result.set(field.id, field));

        return [...result.values()];
    }

    filterAvailableFieldsByObject(
        availableCounters: DataSourceEntityCounterMap,
        selectedObject: Routing.Settings.MappingSelectedObject | null,
    ): DataSourceEntityCounterEntityField[] {
        const fields: DataSourceEntityCounterEntityField[] = [];

        if (selectedObject === null) {
            return fields;
        }

        const key = `${selectedObject.dataSourceId}:${selectedObject.entityId}`;
        const counter = availableCounters.get(key);
        if (!counter) {
            return fields;
        }

        return counter.entity.fields.filter((field) => {
            if (
                !field.isIncluded ||
                field.isSystem ||
                field.isLookup ||
                field.isLink ||
                field.isSystemVisible ||
                field.isVirtual
            ) {
                return false;
            }
            return field.name !== 'id';
        });
    }
}

export default RoutingSettingsFilterFieldsService;
