import { TooltipProps as BaseTooltipProps } from '@material-ui/core';
import MUITooltip from '@material-ui/core/Tooltip';
import { cloneElement, FC, useMemo } from 'react';
import { useIsOverflowing } from '../../../hooks/useIsOverflowing';

type TooltipProps = BaseTooltipProps & {
    enableOnlyWhenOverflowing?: boolean;
};

export const Tooltip: FC<TooltipProps> = (props) => {
    const { enableOnlyWhenOverflowing = false, ...rest } = props;

    const [isOverflowing, textElementRef] = useIsOverflowing();
    const child = cloneElement(props.children, {
        ...(enableOnlyWhenOverflowing
            ? {
                  // TODO: implement forwardRef when needed
                  ref: textElementRef,
              }
            : {}),
    });

    const disabledTooltipProps = useMemo<Partial<TooltipProps>>(() => {
        if (!isOverflowing && enableOnlyWhenOverflowing) {
            return {
                disableFocusListener: !isOverflowing,
                disableHoverListener: !isOverflowing,
                disableTouchListener: !isOverflowing,
            };
        }

        return {};
    }, [enableOnlyWhenOverflowing, isOverflowing]);

    return (
        <MUITooltip {...rest} {...disabledTooltipProps}>
            {child}
        </MUITooltip>
    );
};
