import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import { User } from 'interfaces';

class PasswordValidationService extends BackendService {
    private breachesMap = new Map<string, boolean>();

    async getPasswordValidationMessage(password: string, userInputs: User.UserInputs): Promise<string | undefined> {
        if (password.length < 12) {
            return 'user_form.errors.password';
        }

        const utils = await import('utils');
        const { is_too_weak } = await utils.passwordRating(password, userInputs);
        if (is_too_weak) {
            return 'user_form.errors.password_is_too_weak';
        }

        try {
            /**
             * The pwned matcher is an async matcher that will make a k-anonymity password request to the have i been pwned api.
             * @link https://www.npmjs.com/package/@zxcvbn-ts/matcher-pwned
             */
            const isFound = await this.findPasswordInDataBreaches(password);
            if (isFound) {
                return 'user_form.errors.password_is_unsafe';
            }
        } catch (e) {
            console.error(e);
        }

        return undefined;
    }

    private async findPasswordInDataBreaches(password: string): Promise<boolean> {
        if (this.breachesMap.has(password)) {
            return this.breachesMap.get(password) as boolean;
        }

        const { isFound } = await this.requestApi(reverse(apiRoutes.findPasswordInDataBreaches), 'POST', { password });
        this.breachesMap.set(password, isFound);

        return isFound;
    }
}

export default new PasswordValidationService();
