import React from 'react';
import Alert from '../Alert';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MAPSLY_QUICK_DEMO_URL } from '../constants';
import { isString } from 'lodash';
import SSUManager from './SSUManager';

interface SSUIntroProps extends WithTranslation {
    manager: SSUManager;
}
class SSUIntro extends React.PureComponent<SSUIntroProps> {
    render() {
        const { manager, t } = this.props;
        const userData = manager.getUserData();
        const hasProvider = isString(manager.getProvider());
        const isAdmin = userData && userData.role === 'admin';

        return (
            <div>
                {!isAdmin && hasProvider && <Alert type="warning">{t('signup.intro.need_to_be_admin')}</Alert>}
                <p>{t('signup.intro.lets_set_up')}</p>
                <ol>
                    <li>{t('signup.intro.step_1')}</li>
                    <li>{t('signup.intro.step_2')}</li>
                    {hasProvider && <li>{t('signup.intro.step_3')}</li>}
                    {hasProvider && <li>{t('signup.intro.step_4')}</li>}
                </ol>
                <h4>{t('signup.intro.if_you_need_help')}</h4>
                <p>{t('signup.intro.step_5')}</p>
                <p>
                    {t('signup.intro.feel_free')}&nbsp;
                    <a href={MAPSLY_QUICK_DEMO_URL} target="_blank" rel="noreferrer noopener">
                        {t('signup.intro.book_a_call')}
                    </a>
                    &nbsp;
                    <i style={{ color: '#3f51b5' }} className="fas fa-external-link" aria-hidden="true" />
                    &nbsp;
                    {t('signup.intro.to_discuss')}
                </p>
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SSUIntro);
