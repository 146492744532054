import React from 'react';
import { Checkbox, FormControl, InputLabel, TextField } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SingleRowMultipleAutocomplete } from 'components/SingleRowMultipleAutocomplete';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { MultiPicklistValue } from 'components/types';
import { ChangeSelectTargetEvent } from './PicklistField';

interface Props extends WithTranslation {
    value: string[];
    name: string;
    label: string;
    onChangeSelect: (event: ChangeSelectTargetEvent) => void;
    onChangeMultilineText: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    picklist?: MultiPicklistValue[];
    disabled: boolean;
    autoFocus: boolean;
}

class MultiPickListField extends React.PureComponent<Props> {
    render() {
        const { t, value, disabled, autoFocus, picklist, label, name, onChangeSelect, onChangeMultilineText } =
            this.props;

        if (!picklist) {
            return (
                <TextField
                    name={name}
                    label={label}
                    placeholder={t('field_factory.multiline_array_input')}
                    margin="dense"
                    value={value ? value.join('\n') : ''}
                    InputProps={{ disableUnderline: false }}
                    onChange={onChangeMultilineText}
                    multiline
                    fullWidth
                    disabled={disabled}
                    autoFocus={autoFocus}
                />
            );
        }

        const arrValue = value || [];
        const values = picklist.filter((item) => arrValue.includes(item.value));

        return (
            <FormControl fullWidth style={{ minWidth: '200px' }}>
                <InputLabel style={{ whiteSpace: 'nowrap' }} shrink>
                    {label}
                </InputLabel>
                <SingleRowMultipleAutocomplete
                    disabled={disabled}
                    autoFocus={autoFocus}
                    options={picklist}
                    value={values || []}
                    getOptionLabel={(option: MultiPicklistValue) => option.label}
                    multiple={true}
                    fullWidth
                    onChange={(_, value) => {
                        onChangeSelect({
                            target: {
                                name: name,
                                value: value?.map((item) => item.value) || [],
                            },
                        });
                    }}
                    renderOption={(option: MultiPicklistValue) => (
                        <React.Fragment>
                            <Checkbox color="primary" checked={value?.includes(option.value) || false} />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={(params: TextFieldProps) => (
                        <TextField
                            {...params}
                            fullWidth
                            autoFocus={autoFocus}
                            placeholder={values.length === 0 ? t('undefined_picklist_option') : ''}
                            margin="normal"
                        />
                    )}
                />
            </FormControl>
        );
    }
}

export default withTranslation()(MultiPickListField);
