import React, { Fragment } from 'react';
import { Grid as DataGrid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import dsManagerFactory from '../../service/DsManager';
import { adapterManager } from '../../service/AdapterManager';
import PropTypes from 'prop-types';
import { routes, reverse } from '../../routes';
import './style.css';
import { TableLoadingState } from '../TableLoadingState';
import BackendService from 'api/BackendService';
import Tooltip from '@material-ui/core/Tooltip';
import Confirmation from '../Confirmation';
import DataSyncForm from '../DataSyncForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DataSourceDialog from '../DataSourceForm/DataSourceDialog';
import { STAGES } from '../DataSourceForm/DataSourceWizard';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import GeocodingLogTable from '../DataTable/GeocodingLogTable';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatWithCommas } from '../../utils';
import Grid from '@material-ui/core/Grid';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import { withTranslation } from 'react-i18next';
import InboundWebhooksForm from '../DataSourceForm/InboundWebhooksForm';
import PureFormDialog from '../PureFormDialog';
import DataSourceIndicator from './DataSourceIndicator';
import AddIconButton from '../CustomButton/AddIconButton';
import DataSourceFirstMessages from '../ImportMessage/DataSourceFirstMessages';
import { SubscriptionStatus } from '../../service/types';
import SubscriptionContext from 'components/Context/SubscriptionContext';
import { Box } from '@material-ui/core';
import copy from 'copy-to-clipboard';

class EntityCounters extends React.PureComponent {
    render() {
        const { counters, type } = this.props;
        return (
            <React.Fragment>
                {counters.map((record) => (
                    <div key={record.entity.id} className="entity-counter">
                        <EntityCounter type={type} record={record} />
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

class EntityCounter extends React.PureComponent {
    render() {
        const { record, type } = this.props;

        const counter = record.counters[type];
        if (0 === counter) {
            return <span style={{ color: 'gray' }}>0</span>;
        }

        const style = {};
        // eslint-disable-next-line default-case
        switch (type) {
            case 'noAddress':
            case 'invalid':
                style['color'] = 'red';
                break;
            case 'okExact':
            case 'okApprox':
            case 'okImported':
                style['color'] = 'green';
                break;
            case 'processing':
                style['color'] = 'gray';
                break;
        }

        const url =
            reverse(routes.admin.account.search, { accountId: record.dataSource.accountId }) +
            `?status=${type}&source=${record.dataSource.id}&entity=${record.entity.name}`;

        return (
            <a href={url} target="_blank" rel="noreferrer noopener" style={style}>
                {formatWithCommas(counter)}
            </a>
        );
    }
}

const PrintAlertBlock = (subscription, accountId, t) => {
    let textAlert = t('data_source.alert.import_stopped.inactive');
    let textButton = t('data_source.resubscribe');

    if (subscription.isGracePeriodExpired) {
        textAlert = t('data_source.alert.import_stopped.unpaid');
        textButton = t('data_source.update_payment_method');
    }

    if (subscription.status === SubscriptionStatus.TRIAL) {
        textAlert = t('data_source.alert.import_stopped.expired');
    }

    const isCustom = subscription.status === SubscriptionStatus.CUSTOM;
    return (
        <div className="subscription-blocked-message">
            <div style={{ display: 'flex' }}>
                <Icon className="icon-warning">warning</Icon>
                {textAlert}
            </div>
            {isCustom && <span> {t('data_source.alert.import_stopped.contact_to_extend')} </span>}
            {!isCustom && (
                <Fragment>
                    <Link
                        to={reverse(routes.admin.account.billing.index, {
                            accountId: accountId,
                        })}
                    >
                        {textButton}
                    </Link>{' '}
                    {t('data_source.to_resume_import')}.
                </Fragment>
            )}
        </div>
    );
};

const GridRoot = (props) => <DataGrid.Root {...props} className="data-source-table" />;

class ProtoCell extends React.PureComponent {
    render() {
        return <Table.Cell {...this.props} className={`data-source-${this.props.column.name}`} />;
    }
}

const Cell = withStyles({
    cell: {
        lineHeight: '2em',
    },
})(ProtoCell);

const Row = ({ children, row, ...rest }) => {
    return (
        <SubscriptionContext.Consumer>
            {(subscription) => (
                <>
                    <Table.Row>
                        <Table.Cell colSpan={9} style={{ borderBottom: 0, padding: 0 }}>
                            <DataSourceFirstMessages dataSource={row} subscription={subscription} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row {...rest}>{children}</Table.Row>
                </>
            )}
        </SubscriptionContext.Consumer>
    );
};

class DataSourcesList extends React.PureComponent {
    handleToggleImport = (dataSource, isImportEnabled) => {
        if (dataSource.lastHttpCode !== 403 || !isImportEnabled) {
            this.toggleDsImport(dataSource.id, isImportEnabled);
            return;
        }
        this.handleOpen(dataSource, STAGES.CONNECTION);

        dispatcher.subscribe(events.EVENT_DATA_SOURCE_CONNECTED, this, (dataSource) => {
            this.toggleDsImport(dataSource.id, true);
        });
    };

    toggleDsImport(id, isImportEnabled) {
        const ds = {
            id,
            isImportEnabled,
        };
        dsManagerFactory.getManager(this.props.accountId).save(ds, false);
    }

    handleOpenMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null,
        });
        return true;
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dsToEdit: null,
            dsToEditStage: null,
            dsToBrowseGeocodingLogs: null,
            dsToSetupInboundWebhooks: null,
            dsBeingDeleted: new Map(),
            dsToSync: null,
            anchorEl: null,
            account: null,
            geoCounters: {},
            jobStatuses: {},
            subscription: null,
        };

        this.subManager = subscriptionManagerFactory.getManager(this.props.accountId);

        this.adapters = [];

        this.refreshCountersInterval = null;

        this.columns = [
            {
                name: 'dataSource',
                title: this.props.t('data_source'),
                getCellValue: (dataSource) => {
                    const url = reverse(routes.admin.account.dataSource.index, {
                        accountId: this.props.myAccount ? undefined : this.props.accountId,
                        dataSourceId: dataSource.id,
                    });
                    const urlJobs = this.props.myAccount
                        ? reverse(routes.admin.account.dataSource.updateJobs, {
                              accountId: undefined,
                              dataSourceId: dataSource.id,
                          })
                        : null;
                    const { anchorEl } = this.state;
                    const anchorTarget =
                        anchorEl && parseInt(anchorEl.dataset.sourceId) === dataSource.id ? anchorEl : null;
                    const isBlocked =
                        this.state.subscription !== null &&
                        this.state.subscription.isBlocked &&
                        !dataSource.isDeactivated &&
                        dataSource.isImportEnabled;
                    const { t } = this.props;
                    return (
                        <div>
                            {!isBlocked && (
                                <React.Fragment>
                                    <IconButton
                                        color="primary"
                                        component="button"
                                        data-source-id={dataSource.id}
                                        onClick={this.handleOpenMenu}
                                        className="data-source-menu"
                                        data-testid="data_source.open_menu"
                                    >
                                        <Icon>more_vert</Icon>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorTarget}
                                        open={anchorTarget !== null}
                                        onClose={this.handleCloseMenu}
                                        disableAutoFocusItem
                                        className="data-source-menu"
                                    >
                                        <MenuItem component={Link} to={url} data-testid="data_source.objects">
                                            {t('data_source.objects')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleCloseMenu() && this.handleDataSyncDialogOpen(dataSource)
                                            }
                                            disabled={!dataSource.isConnected}
                                            data-testid="data_source.refresh"
                                        >
                                            <Tooltip
                                                title={
                                                    dataSource.isConnected
                                                        ? t('data_source.get_updates')
                                                        : t('data_source.get_updates.error')
                                                }
                                            >
                                                <div>{t('data_source.refresh')}</div>
                                            </Tooltip>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleCloseMenu() && this.handleOpen(dataSource, STAGES.GENERAL)
                                            }
                                            disabled={dataSource.isSystem}
                                            data-testid="data_source.general_properties"
                                        >
                                            {t('data_source.general_properties')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleCloseMenu() && this.handleOpen(dataSource, STAGES.CONNECTION)
                                            }
                                            disabled={dataSource.isSystem}
                                            data-testid="data_source.connection_settings"
                                        >
                                            {t('data_source.connection_settings')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleCloseMenu() && this.handleUpdateGeocodingLogOpen(dataSource)
                                            }
                                            data-testid="data_source.geocoding_log"
                                        >
                                            {t('data_source.geocoding_log')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleCloseMenu() &&
                                                this.handleOpenInboundWebhooksDialog(dataSource)
                                            }
                                            data-testid="data_source.inbound_webhooks_and_api"
                                        >
                                            {t('data_source.inbound_webhooks_and_api')}
                                        </MenuItem>
                                        {urlJobs && (
                                            <MenuItem component={Link} to={urlJobs} data-testid="live_update_log.title">
                                                {t('live_update_log.title')}
                                            </MenuItem>
                                        )}
                                        <Confirmation
                                            text={t('data_source.remove.confirm')}
                                            onConfirm={() => this.handleCloseMenu() && this.handleDelete(dataSource)}
                                            onReject={this.handleCloseMenu}
                                        >
                                            <MenuItem
                                                disabled={
                                                    this.state.dsBeingDeleted.has(dataSource.id) || dataSource.isSystem
                                                }
                                                className="delete"
                                                data-testid="data_source.remove"
                                            >
                                                {t('delete')}
                                            </MenuItem>
                                        </Confirmation>
                                    </Menu>
                                    <div style={{ display: 'inline-block', width: '58px' }}>
                                        {!dataSource.isSystem && (
                                            <Switch
                                                checked={dataSource.isImportEnabled}
                                                onChange={(e) => this.handleToggleImport(dataSource, e.target.checked)}
                                                color="primary"
                                                data-testid="data_source.toggle_import"
                                            />
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                            <span>
                                <DataSourceIndicator dataSource={dataSource} t={this.props.t} />
                                <Box display="inline-block" mb={3.5} position="relative">
                                    <Link to={url} className="data-source-name">
                                        {dataSource.name}
                                    </Link>
                                    <Tooltip
                                        title={
                                            <div
                                                className="text-center"
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props.t('data_source.entity.api_name'),
                                                }}
                                            />
                                        }
                                    >
                                        <Box
                                            position="absolute"
                                            className="text-muted"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                copy(dataSource.apiName);
                                            }}
                                        >
                                            {dataSource.apiName}
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </span>
                            {isBlocked &&
                                PrintAlertBlock(
                                    this.state.subscription,
                                    this.props.myAccount ? undefined : this.props.accountId,
                                    t,
                                )}
                        </div>
                    );
                },
            },
            {
                name: 'objects',
                title: this.props.t('data_source.entity.title_name'),
                getCellValue: (dataSource) => {
                    return this.getEntityCounters(dataSource).map((record) => {
                        const status = this.getEntityStatuses(dataSource, record.entity);
                        let statusTooltips = '';
                        if (status && status.geocoding) {
                            statusTooltips += this.props.t('data_source.entity.calculating_positions');
                        }
                        if (status && status.import) {
                            if ('' !== statusTooltips) {
                                statusTooltips += '\n';
                            }
                            statusTooltips += this.props.t('data_source.entity.refreshing_records');
                        }
                        if (status && status.geoAutoSaving.enabled && status.geoAutoSaving.toProcess) {
                            if ('' !== statusTooltips) {
                                statusTooltips += '\n';
                            }
                            const { toProcess, total } = status.geoAutoSaving;
                            const processed = total - toProcess;
                            statusTooltips += this.props.t('data_source.entity.pushing_records', { processed, total });
                        }
                        return (
                            <div key={record.entity.id} className="entity-name">
                                <div>{record.entity.label}</div>
                                {statusTooltips.length > 0 && (
                                    <Tooltip
                                        title={<div style={{ whiteSpace: 'pre-line' }}>{statusTooltips}</div>}
                                        className="entity-name__progress"
                                    >
                                        <CircularProgress size={14} />
                                    </Tooltip>
                                )}
                            </div>
                        );
                    });
                },
            },
            {
                name: 'processing',
                title: this.props.t('geocoder.processing'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="processing" />
                ),
            },
            {
                name: 'ok_exact',
                title: this.props.t('geocoder.ok_exact'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="okExact" />
                ),
            },
            {
                name: 'ok_approx',
                title: this.props.t('geocoder.ok_approx'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="okApprox" />
                ),
            },
            {
                name: 'ok_imported',
                title: this.props.t('geocoder.ok_imported'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="okImported" />
                ),
            },
            {
                name: 'no_address',
                title: this.props.t('geocoder.no_address'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="noAddress" />
                ),
            },
            {
                name: 'invalid_address',
                title: this.props.t('geocoder.invalid_address'),
                getCellValue: (dataSource) => (
                    <EntityCounters counters={this.getEntityCounters(dataSource)} type="invalid" />
                ),
            },
        ];
    }

    getEntityStatuses = (dataSource, entity) => {
        const { jobStatuses } = this.state;
        if (jobStatuses[dataSource.id] && jobStatuses[dataSource.id][entity.id]) {
            return jobStatuses[dataSource.id][entity.id];
        }
        const status = dataSource.jobStatuses.find((s) => parseInt(s.entity.id) === parseInt(entity.id));
        if (!status) {
            return null;
        }
        return status.statuses;
    };

    getEntityCounters = (dataSource) => {
        return this.state.geoCounters[dataSource.id] || dataSource.entityCounters;
    };

    handleUpdateGeocodingLogOpen = (ds) => {
        this.setState({
            dsToBrowseGeocodingLogs: ds,
        });
    };

    handleOpenInboundWebhooksDialog = (ds) => {
        this.setState({
            dsToSetupInboundWebhooks: ds,
        });
    };

    handleCloseInboundWebhooksDialog = () => {
        this.setState({
            dsToSetupInboundWebhooks: null,
        });
    };

    handleUpdateGeocodingLogClose = () => {
        this.setState({
            dsToBrowseGeocodingLogs: null,
        });
    };

    handleOpen = (ds, stage) => {
        if (ds === null) {
            ds = dsManagerFactory.getManager(this.props.accountId).getDefaultDataSource();
        }
        this.setState({
            dsToEdit: ds,
            dsToEditStage: !ds.id ? null : stage,
        });
    };

    handleClose = () => {
        this.setState({
            dsToEdit: null,
        });
        dispatcher.unsubscribe(events.EVENT_DATA_SOURCE_CONNECTED, this);
    };

    handleDelete = (ds) => {
        this.setState((state) => {
            state.dsBeingDeleted.set(ds.id, true);
            return state;
        });
        dsManagerFactory
            .getManager(this.props.accountId)
            .delete(ds.id)
            .then((ds) => {
                this.props.enqueueSnackbar(this.props.t('data_source.deleted', { name: ds.name }), {
                    variant: 'success',
                });
                this.loadDsList();
            })
            .catch((error) => {
                this.props.enqueueSnackbar(this.props.t('data_source.delete.error', { error: error.message }), {
                    variant: 'error',
                });
                this.setState((state) => {
                    state.dsBeingDeleted.delete(ds.id);
                    return state;
                });
            });
    };

    handleDataSyncDialogOpen = (ds) => {
        this.setState({
            dsToSync: ds,
        });
    };

    handleDataSyncDialogClose = () => {
        this.setState({
            dsToSync: null,
        });
    };

    componentDidMount() {
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.status === 'complete') {
                this.loadDsList();
            }
        });

        dispatcher.subscribe(
            [events.EVENT_DATA_SOURCE_CHANGED, events.EVENT_DATA_SOURCE_CREATED],
            this,
            (dataSource) => {
                if (this.state.dsToEdit && dataSource.connectionId === this.state.dsToEdit.connectionId) {
                    this.setState({
                        dsToEdit: { ...dataSource },
                    });
                }
                if (dataSource.isConnected) {
                    this.props.enqueueSnackbar(this.props.t('data_source.saved', { name: dataSource.name }), {
                        variant: 'success',
                    });
                } else {
                    this.props.enqueueSnackbar(
                        this.props.t('data_source.saved.no_conn_check', { name: dataSource.name }),
                        { variant: 'warning' },
                    );
                }
                this.loadDsList();
            },
        );

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.accountId !== null && subscription.accountId === this.props.accountId) {
                this.setState({
                    subscription,
                });
            }
        });

        this.subManager.getSubscription().then((subscription) => {
            this.setState({
                subscription,
            });
            adapterManager
                .list()
                .then((adapters) => {
                    this.adapters = adapters;
                    this.loadDsList();
                })
                .catch((error) => {
                    this.props.enqueueSnackbar(this.props.t('data_source.load.error', { error: error.message }), {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                    });
                });
        });

        this.refreshCountersInterval = setInterval(this.refreshCounters, 30 * 1000);
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
        if (this.refreshCountersInterval) {
            clearInterval(this.refreshCountersInterval);
            this.refreshCountersInterval = null;
        }
    }

    refreshCounters = () => {
        dsManagerFactory
            .getManager(this.props.accountId)
            .refreshableInfo()
            .then(({ counters, statuses }) => {
                this.setState({ geoCounters: counters, jobStatuses: statuses });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    loadDsList() {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();
        const promise = manager.list();

        if (!BackendService.isInstantPromise(promise)) {
            this.setState({
                loading: true,
            });
        }

        this.setState({
            geoCounters: {},
        });
        promise
            .then(() => {
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({
                    loading: false,
                });
                if (error.code === 403) {
                    error.skipSentry = true;
                }
                throw error;
            });
    }

    handleSelectRecord = (dsId, entityApiName) => {
        const urlParams = this.props.myAccount ? {} : { accountId: this.props.accountId };
        const url = reverse(routes.admin.account.search, urlParams);

        window.open(url + '?source=' + dsId + '&entity=' + encodeURIComponent(entityApiName));
    };

    handleError = (error) => {
        this.props.enqueueSnackbar(error, { variant: 'error' });
    };

    render() {
        const showAddButton = this.state.subscription !== null && !this.state.subscription.isBlocked;

        const rows = dsManagerFactory.getManager(this.props.accountId).getDataSources() || [];

        return (
            <div className="c-data-sources-list">
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <h2 style={{ margin: 0 }}>{this.props.t('data_source.entity.title')}</h2>
                    </Grid>
                    {showAddButton && (
                        <Grid item>
                            <AddIconButton onClick={() => this.handleOpen(null)} className="add-data-source" />
                        </Grid>
                    )}
                </Grid>
                <div className="c-data-sources-list-table">
                    <SubscriptionContext.Provider value={this.state.subscription}>
                        <DataGrid rows={rows} columns={this.columns} rootComponent={GridRoot}>
                            <Table
                                noDataCellComponent={() => (
                                    <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                                )}
                                cellComponent={Cell}
                                rowComponent={Row}
                            />
                            <TableHeaderRow
                                showSortingControls={false}
                                cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                            />
                        </DataGrid>
                    </SubscriptionContext.Provider>
                </div>
                {this.state.dsToEdit !== null && (
                    <DataSourceDialog
                        open
                        disableEnforceFocus
                        dataSource={this.state.dsToEdit}
                        stage={this.state.dsToEditStage}
                        onClose={this.handleClose}
                        scroll="body"
                    />
                )}

                {this.state.dsToSync !== null && (
                    <PureFormDialog
                        open
                        title={this.props.t('data_source.refresh.title', { name: this.state.dsToSync.name })}
                        onClose={this.handleDataSyncDialogClose}
                    >
                        <DataSyncForm
                            accountId={this.props.accountId}
                            dataSourceId={this.state.dsToSync.id}
                            onSave={this.handleDataSyncDialogClose}
                        />
                    </PureFormDialog>
                )}

                {this.state.dsToSetupInboundWebhooks !== null && (
                    <PureFormDialog
                        open
                        title={this.props.t('data_source.inbound_webhooks_and_api')}
                        onClose={this.handleCloseInboundWebhooksDialog}
                        maxWidth="xs"
                        fullWidth
                    >
                        <InboundWebhooksForm
                            dataSource={this.state.dsToSetupInboundWebhooks}
                            onSaved={this.handleCloseInboundWebhooksDialog}
                        />
                    </PureFormDialog>
                )}

                {this.state.dsToBrowseGeocodingLogs !== null && (
                    <Dialog open onClose={this.handleUpdateGeocodingLogClose} maxWidth={false}>
                        <DialogTitle className="c-form-dialog__title">
                            {this.props.t('geocoder.log', { name: this.state.dsToBrowseGeocodingLogs.name })}
                            <IconButton
                                color="default"
                                onClick={this.handleUpdateGeocodingLogClose}
                                className="c-form-dialog__close"
                                data-testid="geocoder.log.close"
                            >
                                <Icon>close_icon</Icon>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <GeocodingLogTable
                                accountId={this.props.accountId}
                                dsId={this.state.dsToBrowseGeocodingLogs.id}
                                myAccount={this.props.myAccount}
                                onError={this.handleError}
                                onSelectRecord={this.handleSelectRecord}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleUpdateGeocodingLogClose}
                                color="default"
                                data-testid="update_geocoding_log.close"
                            >
                                {this.props.t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        );
    }
}

DataSourcesList.propTypes = {
    accountId: PropTypes.number.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    myAccount: PropTypes.bool.isRequired,
};

DataSourcesList.defaultProps = {
    myAccount: false,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

export default withTranslation('translations', { withRef: true })(withSnackbar(DataSourcesList));
