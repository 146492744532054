import { action, computed, makeObservable, observable } from 'mobx';

type RouteEditorManagerKeys =
    | '_isLoading'
    | '_isChanging'
    | '_isBuilding'
    | '_isInProgress'
    | '_isShowVehicleProfileModal'
    | '_isSwitchingToEditModeWithAddPointsLoading';

/**
 * Управляет вкладкой Route в design mode.
 * - Начальная точка @see AppointmentConfig
 * - Список точек - @see RouteDesignManager
 * - Конечная точка @see AppointmentConfig
 * - Настройки @see CurrentRouteManager
 */
class RouteEditorManager {
    private _isLoading = false;
    private _isChanging = false;
    private _isBuilding = false;
    private _isInProgress = false;
    private _isShowVehicleProfileModal = false;
    private _isSwitchingToEditModeWithAddPointsLoading = false;

    constructor() {
        makeObservable<RouteEditorManager, RouteEditorManagerKeys>(this, {
            _isLoading: observable,
            _isChanging: observable,
            _isBuilding: observable,
            _isInProgress: observable,
            _isShowVehicleProfileModal: observable,
            _isSwitchingToEditModeWithAddPointsLoading: observable,

            isLoading: computed,
            isChanging: computed,
            isBuilding: computed,
            isInProgress: computed,
            isProcessing: computed,
            isShowVehicleProfileModal: computed,
            isSwitchingToEditModeWithAddPointsLoading: computed,

            setIsLoading: action,
            setIsChanging: action,
            setIsBuilding: action,
            setIsInProgress: action,
            setIsShowVehicleProfileModal: action,
            setIsSwitchingToEditModeWithAddPointsLoading: action,
        });
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    setIsLoading(isLoading: boolean): void {
        this._isLoading = isLoading;
    }

    get isProcessing(): boolean {
        return this.isChanging || this.isBuilding || this.isLoading || this.isInProgress;
    }

    get isChanging(): boolean {
        return this._isChanging;
    }

    setIsChanging(isChanging: boolean): void {
        this._isChanging = isChanging;
    }

    get isBuilding(): boolean {
        return this._isBuilding;
    }

    setIsBuilding(isBuilding: boolean): void {
        this._isBuilding = isBuilding;
    }

    get isInProgress(): boolean {
        return this._isInProgress;
    }

    setIsInProgress(isInProgress: boolean): void {
        this._isInProgress = isInProgress;
    }

    get isShowVehicleProfileModal(): boolean {
        return this._isShowVehicleProfileModal;
    }

    setIsShowVehicleProfileModal(isShow: boolean): void {
        this._isShowVehicleProfileModal = isShow;
    }

    get isSwitchingToEditModeWithAddPointsLoading(): boolean {
        return this._isSwitchingToEditModeWithAddPointsLoading;
    }

    setIsSwitchingToEditModeWithAddPointsLoading(isSwitchingToEditModeWithAddPointsLoading: boolean): void {
        this._isSwitchingToEditModeWithAddPointsLoading = isSwitchingToEditModeWithAddPointsLoading;
    }
}

export default RouteEditorManager;
