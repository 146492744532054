import { TypographyProps as BaseTypographyProps, capitalize, Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, createElement, forwardRef } from 'react';

const useStyles = makeStyles(
    function styles(theme: Theme) {
        return createStyles({
            /* Styles applied to the root element. */
            root: {
                margin: 0,
                // opacity: (props: Pick<TypographyProps, 'opacity'>) => {
                //     if (typeof props.opacity === 'number') {
                //         return 0.01 * props.opacity;
                //     }
                // },
            },

            /* Styles applied to the root element if `variant="body2"`. */
            body2: theme.typography.body2,

            /* Styles applied to the root element if `variant="body1"`. */
            body1: theme.typography.body1,

            /* Styles applied to the root element if `variant="caption"`. */
            caption: theme.typography.caption,

            /* Styles applied to the root element if `variant="button"`. */
            button: theme.typography.button,

            /* Styles applied to the root element if `variant="h1"`. */
            h1: theme.typography.h1,

            /* Styles applied to the root element if `variant="h2"`. */
            h2: theme.typography.h2,

            /* Styles applied to the root element if `variant="h3"`. */
            h3: theme.typography.h3,

            /* Styles applied to the root element if `variant="h4"`. */
            h4: theme.typography.h4,

            /* Styles applied to the root element if `variant="h5"`. */
            h5: theme.typography.h5,

            /* Styles applied to the root element if `variant="h6"`. */
            h6: theme.typography.h6,

            /* Styles applied to the root element if `variant="subtitle1"`. */
            subtitle1: theme.typography.subtitle1,

            /* Styles applied to the root element if `variant="subtitle2"`. */
            subtitle2: theme.typography.subtitle2,

            /* Styles applied to the root element if `variant="overline"`. */
            overline: theme.typography.overline,

            /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
            srOnly: {
                position: 'absolute',
                height: 1,
                width: 1,
                overflow: 'hidden',
            },

            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: 'left',
            },

            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: 'center',
            },

            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: 'right',
            },

            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: 'justify',
            },

            /* Styles applied to the root element if `nowrap={true}`. */
            noWrap: {
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },

            /* Styles applied to the root element if `gutterBottom={true}`. */
            gutterBottom: {
                marginBottom: '0.35em',
            },

            /* Styles applied to the root element if `paragraph={true}`. */
            paragraph: {
                marginBottom: 16,
            },

            /* Styles applied to the root element if `color="inherit"`. */
            colorInherit: {
                color: 'inherit',
            },

            /* Styles applied to the root element if `color="primary"`. */
            colorPrimary: {
                color: theme.palette.primary.main,
            },

            /* Styles applied to the root element if `color="secondary"`. */
            colorSecondary: {
                color: theme.palette.secondary.main,
            },

            /* Styles applied to the root element if `color="textPrimary"`. */
            colorTextPrimary: {
                color: theme.palette.text.primary,
            },

            /* Styles applied to the root element if `color="textSecondary"`. */
            colorTextSecondary: {
                color: theme.palette.text.secondary,
            },

            /* Styles applied to the root element if `color="error"`. */
            colorError: {
                color: theme.palette.error.main,
            },

            /* Styles applied to the root element if `color="warning"`. */
            colorWarning: {
                color: theme.palette.warning.main,
            },

            /* Styles applied to the root element if `display="inline"`. */
            displayInline: {
                display: 'inline',
            },

            /* Styles applied to the root element if `display="block"`. */
            displayBlock: {
                display: 'block',
            },

            colorTextTertiary: {
                color: theme.palette.text.tertiary,
            },
            colorTextTransparent: {
                color: '#322F3580',
            },
            colorLinkDefault: {
                color: theme.palette.text.linkDefault,
            },
            colorPositive: {
                color: theme.palette.text.positive,
            },

            bodyXS400: {
                ...theme.typography.bodyXS,
                fontWeight: 400,
            },
            bodyS400: {
                ...theme.typography.bodyS,
                fontWeight: 400,
            },
            bodyS500: {
                ...theme.typography.bodyS,
                fontWeight: 500,
            },
            bodyM400: {
                ...theme.typography.bodyM,
                fontWeight: 400,
            },
            bodyM500: {
                ...theme.typography.bodyM,
                fontWeight: 500,
                letterSpacing: '0.1px',
            },
            index: {
                fontFeatureSettings: `'sups' on`,
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
            },
            label: {
                fontSize: '11px',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '-1px',
            },
        });
    },
    { name: 'MapslyMuiTypography' },
);

var defaultVariantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
};
var Typography = forwardRef<unknown, TypographyProps>(function Typography(props, ref) {
    let {
        align = 'inherit',
        classes: _classes,
        className,
        color = 'initial',
        // @ts-expect-error
        component,
        display = 'initial',
        gutterBottom = false,
        noWrap = false,
        paragraph = false,
        variant = 'body1',
        variantMapping = defaultVariantMapping,
        style,
        opacity,
        ...other
    } = props;

    const classes = useStyles(/* { opacity } */);

    var Component =
        //@ts-expect-error
        component || (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) || 'span';
    return createElement(Component, {
        className: clsx(
            classes!.root,
            className,
            variant !== 'inherit' && classes[variant],
            // @ts-expect-error
            color !== 'initial' && classes['color'.concat(capitalize(color))],
            noWrap && classes!.noWrap,
            gutterBottom && classes!.gutterBottom,
            paragraph && classes!.paragraph,
            // @ts-expect-error
            align !== 'inherit' && classes['align'.concat(capitalize(align))],
            // @ts-expect-error
            display !== 'initial' && classes['display'.concat(capitalize(display))],
        ),
        ref: ref,
        style: {
            opacity: typeof props.opacity === 'number' ? 0.01 * props.opacity : undefined,
            ...style,
        },
        ...other,
    });
});
var _default = Typography as ComponentType<TypographyProps>;

export { _default as Typography };

export type TypographyProps = Omit<BaseTypographyProps, 'color' | 'variant'> & {
    color?: BaseTypographyProps['color'] | 'textTertiary' | 'textTransparent' | 'positive' | 'warning' | 'linkDefault';
    variant?:
        | BaseTypographyProps['variant']
        | 'bodyXS400'
        | 'bodyS400'
        | 'bodyM400'
        | 'bodyM500'
        | 'bodyS500'
        | 'index'
        | 'label';
    opacity?: number | boolean | null;
};
