import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { Button, Chip, DialogActions, Tabs } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../TabPanel';
import { WorkingHoursException } from '../types';
import WorkingHourExceptionsTable from './WorkingHourExceptionsTable';
import './style.css';
import { v4 as uuidv4 } from 'uuid';
import { EXCEPTION_TABS, filterAndSortWorkingHoursExceptions, saveWorkingHoursExceptions } from './helper';
import ExceptionDialog from './ExceptionDialog';
import { cloneDeep } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { workingHoursExceptionsDialogManager } from '../../service/UserWorkingHoursExceptions/WorkingHoursExceptionsDialogManager';
import { User } from '../../interfaces/user';

interface State {
    tab: EXCEPTION_TABS;
    workingHoursExceptionForDialog?: WorkingHoursException;
    workingHoursExceptions: WorkingHoursException[];
}

interface Props extends WithTranslation, WithSnackbarProps {
    user: User;
}

class UsersWorkingHourExceptionsDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tab: EXCEPTION_TABS.TAB_CURRENT_AND_FUTURE,
            workingHoursExceptionForDialog: undefined,
            workingHoursExceptions: [],
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_USER_CHANGED, this, (user: User) => {
            if (user.id !== this.props.user.id) {
                return;
            }
            this.setState({ workingHoursExceptions: cloneDeep(user.routingPreferences.workingHoursExceptions) || [] });
        });

        this.setState({
            workingHoursExceptions: cloneDeep(this.props.user.routingPreferences.workingHoursExceptions) || [],
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    private handleAddExceptionClick = (): void => {
        this.setState({
            workingHoursExceptionForDialog: {
                id: uuidv4(),
                periodFrom: '',
                periodTo: '',
                workingHours: {},
                allowedOvertime: null,
                breakType: null,
                breakDuration: null,
                breakEarliestTime: null,
                breakLatestTime: null,
            },
        });
    };

    private handleExceptionEdit = (workingHoursException: WorkingHoursException): void => {
        this.setState({ workingHoursExceptionForDialog: workingHoursException });
    };

    private handleTabChange = (_: React.ChangeEvent<{}>, tab: number): void => {
        this.setState({ tab });
    };

    private handleAddExceptionDialogClose = (): void => {
        this.setState({ workingHoursExceptionForDialog: undefined });
    };

    private saveUser = (workingHoursExceptions: WorkingHoursException[]): void => {
        saveWorkingHoursExceptions(this.props.user, workingHoursExceptions);
    };

    private handleExceptionSave = (workingHoursException: WorkingHoursException): void => {
        const workingHoursExceptions = cloneDeep(this.state.workingHoursExceptions);

        let exceptionFound = false;
        for (let i = 0; i < workingHoursExceptions.length; i++) {
            if (workingHoursExceptions[i].id === workingHoursException.id) {
                workingHoursExceptions[i] = workingHoursException;
                exceptionFound = true;
                break;
            }
        }
        if (!exceptionFound) {
            workingHoursExceptions.push(workingHoursException);
        }
        this.saveUser(workingHoursExceptions);
        this.setState({ tab: EXCEPTION_TABS.TAB_CURRENT_AND_FUTURE });
    };

    private handleExceptionDelete = (workingHoursException: WorkingHoursException): void => {
        const workingHoursExceptions = cloneDeep(this.state.workingHoursExceptions);

        let exceptionFound = false;
        for (let i = 0; i < workingHoursExceptions.length; i++) {
            if (workingHoursExceptions[i].id === workingHoursException.id) {
                workingHoursExceptions.splice(i, 1);
                exceptionFound = true;
                break;
            }
        }
        if (exceptionFound) {
            this.saveUser(workingHoursExceptions);
        }
    };

    private handleClose = (): void => {
        workingHoursExceptionsDialogManager.close();
    };

    render() {
        const { t } = this.props;
        const { tab, workingHoursExceptionForDialog, workingHoursExceptions } = this.state;

        const filteredWorkingHoursExceptions = filterAndSortWorkingHoursExceptions(tab, workingHoursExceptions);
        const currentAndFutureFilteredExceptions =
            tab === EXCEPTION_TABS.TAB_CURRENT_AND_FUTURE
                ? filteredWorkingHoursExceptions
                : filterAndSortWorkingHoursExceptions(EXCEPTION_TABS.TAB_CURRENT_AND_FUTURE, workingHoursExceptions);

        return (
            <PureFormDialog
                open={true}
                title={t('working_hour_exceptions.modal.title')}
                onClose={this.handleClose}
                maxWidth="md"
                fullWidth
                actions={
                    <DialogActions>
                        <Button onClick={this.handleClose}>{t('dialog.close')}</Button>
                    </DialogActions>
                }
            >
                <div className="working-hours-exceptions-hint">
                    <i className="fas fa-info-circle" />
                    {t('working_hour_exceptions.modal.hint_text')}
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddExceptionClick}
                    style={{ margin: '8px 0' }}
                >
                    <Icon className="fas fa-plus" style={{ overflow: 'visible', marginRight: 8 }} fontSize="small" />
                    {t('working_hour_exceptions.modal.add_exception_button')}
                </Button>

                <Tabs indicatorColor="primary" value={tab} onChange={this.handleTabChange}>
                    <Tab
                        label={
                            <div>
                                {t('working_hour_exceptions.modal.tabs.current_and_future')}
                                <Chip
                                    style={{ marginLeft: 5 }}
                                    color="primary"
                                    size="small"
                                    label={currentAndFutureFilteredExceptions.length}
                                />
                            </div>
                        }
                    />
                    <Tab label={t('working_hour_exceptions.modal.tabs.past')} />
                </Tabs>

                {Object.values(EXCEPTION_TABS)
                    .filter((value) => !isNaN(Number(value)))
                    .map((value) => {
                        return (
                            <TabPanel value={tab} index={value} key={value}>
                                <div style={{ overflowX: 'auto' }}>
                                    <WorkingHourExceptionsTable
                                        workingHoursExceptions={filteredWorkingHoursExceptions}
                                        isPast={tab === EXCEPTION_TABS.TAB_PAST}
                                        onEdit={this.handleExceptionEdit}
                                        onDelete={this.handleExceptionDelete}
                                    />
                                </div>
                            </TabPanel>
                        );
                    })}

                {workingHoursExceptionForDialog !== undefined && (
                    <ExceptionDialog
                        onClose={this.handleAddExceptionDialogClose}
                        workingHoursException={workingHoursExceptionForDialog}
                        workingHoursExceptions={workingHoursExceptions}
                        onSave={this.handleExceptionSave}
                    />
                )}
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations')(withSnackbar(UsersWorkingHourExceptionsDialog));
