import React from 'react';
import { TextField, MenuItem, Grid, ListSubheader, Box, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import TriggerTypeControl from './TriggerTypeControl';
import { workflowRuleManager } from '../../service/WorkflowRuleManager';
import WorkflowGroups from '../WorkflowGroups';
import { TYPE_DEFAULT_RULE_GROUP } from '../../service/WorkflowGroupManager';
import PureFormDialog from '../PureFormDialog';
import { FormActions } from '../PureFormDialog/Form';
import FormControlSelect from '../WorkflowActions/AbstractForm/FormControlSelect';
import { CallContext } from '../utils/CallContext';
import { isActiveEntity, isSafeApiName } from '../../utils';
import { entityManager } from '../../service/SimpleEntityManager';

class WorkflowRulesForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
            currentRule: cloneDeep(props.rule),
            currentEntity: null,
        };
    }

    componentDidMount() {
        if (this.state.currentRule && this.state.currentRule.callContext.isValid()) {
            this.initEntityFields(this.state.currentRule.callContext.getBaseEntityId());
        }
    }

    initEntityFields = (entityId) => {
        if (!entityId) {
            return;
        }

        entityManager.get(entityId, true).then((currentEntity) => {
            this.setState({ currentEntity });
        });
    };

    static getDerivedStateFromProps(props, state) {
        if (state.currentRule.isActive === props.rule.isActive) {
            return null;
        }

        state.currentRule.isActive = props.rule.isActive;

        return state;
    }

    submit() {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then((rule) => {
                return workflowRuleManager.save(this.props.account.id, rule);
            })
            .then((rule) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(rule);
            })
            .catch((errors) => {
                this.setState({
                    errors: errors.details || errors,
                });
                this.props.onSubmitError && this.props.onSubmitError(this.props.t('validation_errors'));
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentRule, currentEntity } = this.state;
            if (!currentRule.name) {
                errors.set('name', this.props.t('errors.not_empty'));
            }
            if (!currentRule.callContext.isValid()) {
                errors.set('callContext', this.props.t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', this.props.t('automation_elements.form.entity.inactive_error'));
            }
            if (!Array.isArray(currentRule.triggerTypes) || currentRule.triggerTypes.length === 0) {
                errors.set('triggerTypes', this.props.t('errors.not_empty'));
            }
            if (errors.size === 0) {
                resolve(currentRule);
                return;
            }
            reject(errors);
        });
    }

    handleEntitySelect = (event) => {
        const name = event.target.name;
        const entityId = event.target.value;
        this.initEntityFields(entityId);
        this.updateRuleState(name, CallContext.create(entityId));
    };

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (name === 'apiName' && !isSafeApiName(value)) {
            return;
        }
        this.updateRuleState(name, value);
    };

    handleTriggerTypes = (types) => {
        this.updateRuleState('triggerTypes', types);
    };

    handleGroups = (groups) => {
        this.updateRuleState('groups', groups);
    };

    updateRuleState = (name, value) => {
        this.setState(
            (state) => {
                const currentRule = cloneDeep(state.currentRule);
                currentRule[name] = value;
                let errors = state.errors;
                if (errors.has(name)) {
                    errors = new Map(errors);
                    errors.delete(name);
                    if (errors.size === 0) {
                        this.props.onSubmitError && this.props.onSubmitError(null);
                    }
                }
                return { currentRule, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    prepareEntityOptions = () => {
        const options = [];
        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={entity.id} value={entity.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    };

    renderEntityValue = (entity, error) => {
        if (!entity) {
            return this.props.t('undefined_picklist_option');
        }

        const label = `${entity.dataSource.name} > ${entity.label}`;
        if (error) {
            return (
                <Box color="error.main" component="span">
                    <Tooltip title={error}>
                        <i style={{ marginRight: 3 }} className="fas fa-circle-exclamation" />
                    </Tooltip>
                    {label}
                </Box>
            );
        }
        return label;
    };

    renderEntitySelect = () => {
        const { currentEntity } = this.state;
        let error;
        if (currentEntity?.dataSource?.deletedAt) {
            error = this.props.t('automation_elements.inactive_data_source');
        } else if (currentEntity?.deletedAt || currentEntity?.isIncluded === false) {
            error = this.props.t('automation_elements.inactive_entity');
        }
        return (
            <FormControlSelect
                fullWidth
                required
                margin="dense"
                label={this.props.t('workflow_rules.form.entity')}
                name="callContext"
                value={this.state.currentRule.callContext.getBaseEntityId() || 0}
                onChange={this.handleEntitySelect}
                renderValue={() => this.renderEntityValue(currentEntity, error)}
                error={!!error || this.state.errors.has('callContext')}
                helperText={this.state.errors.get('callContext')}
                data-testid="workflow_rules.form.entity"
            >
                {this.prepareEntityOptions()}
            </FormControlSelect>
        );
    };

    render() {
        return (
            <React.Fragment>
                <PureFormDialog
                    title={this.props.t('workflow_rules.form.entity.modal.title')}
                    onClose={this.props.onCancel}
                    open={!this.state.currentRule.callContext.isValid()}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect()}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={this.props.t('workflow_rules.form.name')}
                        data-testid="workflow_rules.form.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={this.state.currentRule.name || ''}
                        helperText={this.state.errors.get('name') || ''}
                        error={this.state.errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={this.props.t('workflow_rules.form.api_name')}
                        data-testid="workflow_rules.form.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={this.state.currentRule.apiName || ''}
                        helperText={this.state.errors.get('apiName') || ''}
                        error={this.state.errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect()}

                    <Grid container spacing={1} style={{ marginTop: 20 }}>
                        <Grid item>
                            <b>{this.props.t('workflow_rules.form.trigger_types')}</b>
                        </Grid>
                        <Grid item>
                            <small>{this.props.t('workflow_rules.form.trigger_types.hint')}</small>
                        </Grid>
                    </Grid>

                    <TriggerTypeControl
                        value={this.state.currentRule.triggerTypes}
                        onChange={this.handleTriggerTypes}
                        errors={this.state.errors}
                    />
                    <WorkflowGroups
                        title={this.props.t('workflow_rules.form.actions')}
                        hint={this.props.t('workflow_rules.form.actions.hint')}
                        placeholder={this.props.t('workflow_rules.form.actions.placeholder')}
                        parentNamespace={this.state.currentRule.namespace}
                        groupType={TYPE_DEFAULT_RULE_GROUP}
                        groups={this.state.currentRule.groups}
                        errors={this.state.errors}
                        onChange={this.handleGroups}
                        account={this.props.account}
                        dataSources={this.props.dataSources}
                        callContext={this.state.currentRule.callContext}
                        addOldEntitySection
                    />
                </form>
            </React.Fragment>
        );
    }
}

WorkflowRulesForm.propTypes = {
    account: PropTypes.object.isRequired,
    rule: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(WorkflowRulesForm);
