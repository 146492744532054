import { userManager } from '../UserManager';
import { STORAGE_KEY_PREFIX } from '../../components/types';

class BatteryOptimizationModalManager {
    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.BATTERY_OPTIMIZATION_MODAL_WAS_SHOWN + userManager.getCurrentUser().id;
    }

    wasPopupShown = () => {
        return window.localStorage.getItem(this.getStorageKey());
    };

    rememberPopupWasShown = () => {
        window.localStorage.setItem(this.getStorageKey(), 'was');
    };
}

export default new BatteryOptimizationModalManager();
