import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class LogManager extends BackendService {
    getAuditLog(accountId, filters = [], sorting = [], page, limit) {
        const url = reverse(apiRoutes.account.eventLog.index, { accountId: accountId });
        return this.requestApi(url, 'GET', { filters: filters, sorting: sorting, page: page, limit: limit });
    }

    getDetails(accountId, id) {
        const url = reverse(apiRoutes.account.eventLog.details, { accountId: accountId, id: id });
        return this.requestApi(url, 'GET');
    }
}

export const logManager = new LogManager();
