import { PathLeg, RoutePathActivity } from '../../components/types';

export type Paths = {
    [userId: string]: UserPaths;
};

type UserPaths = {
    [date: string]: Array<RoutePath>;
};

type RoutePath = {
    id: string;
    status: number;
    legs: Array<PathLeg>;
    legIndexes: Array<number>;
    activities: Array<RoutePathActivity>;
};

export enum PathsRequestInitiator {
    INIT_SESSION = 'init_session',
    TRIP_MODE = 'trip_mode',
    ROUTE_REPORT = 'route_report',
    ROUTE_VIEWER = 'route_viewer',
    CALENDAR = 'calendar',
}

export interface WSRoutePathsBuiltResponse {
    paths: Paths;
    requestId: string;
    initiator: PathsRequestInitiator;
    error: string | null;
    isFinal: boolean;
}
