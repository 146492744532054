import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingButton from '../LoadingButton';
import subscriptionManagerFactory, { TYPE_CHANGE } from '../../service/SubscriptionManager';
import { MoneyFormat } from './MoneyFormat';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { withTranslation } from 'react-i18next';
import { SubscriptionStatus } from '../../service/types';

class ChangePlanForm extends React.Component {
    constructor(props) {
        super(props);
        const newPlan = { ...this.props.subscription.plan };
        this.state = {
            subscriptionPlan: null,
            newPlan,
            loading: false,
        };
        this.subManager = subscriptionManagerFactory.getManager(this.props.account.id);
    }

    componentDidMount() {
        this.subManager.listPlan().then((response) => {
            this.setState({
                subscriptionPlan: response,
            });
        });
    }

    handleChangePlan = (event) => {
        const id = event.target.value;

        const newPlan = this.state.subscriptionPlan.find((plan) => plan.id === id);
        this.setState({
            newPlan,
        });

        this.props.onChangeSelectPlan && this.props.onChangeSelectPlan(newPlan);
    };

    /**
     * Calculate records counts available for user for new plan
     * @param account
     * @param newPlan
     * @returns number
     */
    calculateRecordCap(account, newPlan) {
        if (newPlan.recordCap > account.recordsCount) {
            return newPlan.recordCap;
        }

        return (
            newPlan.recordCap +
            Math.ceil((account.recordsCount - newPlan.recordCap) / newPlan.recordChunkSize) * newPlan.recordChunkSize
        );
    }

    /**
     *  Calculate price for new plan
     * @param account
     * @param sub
     * @param newPlan
     * @returns number
     */
    calculateNewPrice(account, sub, newPlan) {
        let recordsPm = 0;
        if (account.recordsCount > newPlan.recordCap) {
            recordsPm =
                Math.abs(Math.ceil((account.recordsCount - newPlan.recordCap) / newPlan.recordChunkSize)) *
                newPlan.priceChunkPm;
        }

        return newPlan.price * sub.usersPurchased + (recordsPm + sub.adminPmCur);
    }

    handleConfirmChange = () => {
        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.account.id === null || subscription.accountId !== this.props.account.id) {
                return;
            }
            if (data.type === TYPE_CHANGE.PLAN) {
                this.setState(
                    {
                        loading: false,
                    },
                    () => {
                        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                        this.props.onCloseDialog && this.props.onCloseDialog();
                        this.props.enqueueSnackbar &&
                            this.props.enqueueSnackbar(this.props.t('billing.plan_form.snack_bar_success'), {
                                variant: 'success',
                            });
                    },
                );
            }
        });

        this.subManager.updatePlan(this.state.newPlan.id).catch((error) => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                    this.props.onCloseDialog && this.props.onCloseDialog();
                    this.props.enqueueSnackbar &&
                        this.props.enqueueSnackbar(error.message, {
                            variant: 'error',
                            persist: true,
                        });
                },
            );
        });

        this.setState({
            loading: true,
        });
    };

    render() {
        const { account, subscription } = this.props;
        const plan = subscription.plan;
        const currentPrice = subscription.subPriceTotalCur;
        const recordCapAll = this.calculateRecordCap(account, this.state.newPlan);
        const newPrice = this.calculateNewPrice(account, subscription, this.state.newPlan);
        const textConfirm = this.props.t(
            newPrice > currentPrice ? 'billing.plan_form.confirm' : 'billing.plan_form.confirm_with_refund',
        );
        const isNotChangedPlan = plan.id === this.state.newPlan.id;
        const statusActive =
            subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.PAST_DUE;

        return (
            <Dialog className="change-plan-form" open={true}>
                <DialogTitle>
                    {this.props.t('billing.plan_form.title')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.plan_form.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {this.state.subscriptionPlan === null && (
                        <div style={{ textAlign: 'center', width: 300 }}>
                            <CircularProgress />
                        </div>
                    )}
                    {this.state.subscriptionPlan !== null && (
                        <div className="dialog-content">
                            <Grid container alignItems="center" className="row">
                                <Grid item xs={4}>
                                    {this.props.t('billing.plan_form.current_plan')}:
                                </Grid>
                                <Grid item xs={8}>
                                    <strong>{plan.name}</strong>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 10 }}>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">
                                        <MoneyFormat currency={subscription.currency} price={currentPrice} />{' '}
                                        {this.props.t('billing.plan_form.period_per_' + plan.period)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="new-block">
                                <Grid item xs={4}>
                                    {this.props.t('billing.plan_form.new_plan')}:
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        name="plan"
                                        displayEmpty
                                        value={this.state.newPlan.id}
                                        onChange={this.handleChangePlan}
                                        data-testid="billing.plan_form.new_plan"
                                    >
                                        {this.state.subscriptionPlan &&
                                            this.state.subscriptionPlan.map((plan) => (
                                                <MenuItem key={plan.id} value={plan.id}>
                                                    {plan.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container className="row">
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">
                                        <MoneyFormat currency={subscription.currency} price={newPrice} />{' '}
                                        {this.props.t('billing.plan_form.period_per_' + this.state.newPlan.period)}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Icon className="icon-info">info</Icon>
                                        <Typography variant="subtitle2">
                                            {this.props.t('billing.plan_form.price_for', {
                                                usersAllowed: subscription.usersAllowed,
                                                recordCaps: recordCapAll.toLocaleString(),
                                            })}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            {statusActive && (
                                <Grid container className="row">
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}>
                                            <Icon className="icon-info">info</Icon>
                                            <Typography variant="subtitle2">{textConfirm}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.onCloseDialog} data-testid="change.plan.button.cancel">
                        {this.props.t('button.cancel')}
                    </Button>
                    <LoadingButton
                        color="primary"
                        onClick={this.handleConfirmChange}
                        disabled={isNotChangedPlan}
                        loading={this.state.loading}
                        data-testid="change.plan.button.confirm"
                    >
                        {this.props.t('button.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

ChangePlanForm.propTypes = {
    account: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    onChangeSelectPlan: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
};

export default withTranslation()(ChangePlanForm);
