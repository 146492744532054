import BackendService from '../api/BackendService';
import apiRoutes, { reverse } from '../api/apiRoutes';

class RoutingCreditsManager extends BackendService {
    getDailyUsage(accountId: number) {
        const url = reverse(apiRoutes.account.routingCredits.usageList, { accountId });
        return this.requestApi(url, 'GET');
    }
}

export const routingCreditsManager = new RoutingCreditsManager();
