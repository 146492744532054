import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Popper,
    Switch,
    TextField,
} from '@material-ui/core';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from 'react-i18next';
import InputField from './AdapterSettingsFields/InputField';
import FieldLabel from './AdapterSettingsFields/FieldLabel';
import PasswordField from 'components/PasswordField';
import { Autocomplete } from '@material-ui/lab';
import GooglePicker from './GooglePicker';

class AdapterSettingsField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: undefined,
        };
    }

    handleInputChange = (event) => {
        const { definition, onChange } = this.props;

        if (onChange) {
            const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            this.setState({ value });
            onChange(definition.name, value);
        }
    };

    handleChange = (value) => {
        const { definition, onChange } = this.props;

        if (onChange) {
            this.setState({ value });
            onChange(definition.name, value);
        }
    };

    handleCheckboxChange = (_, checked) => {
        return this.handleChange(checked);
    };

    handlePickerChange = (value) => {
        const { definition, onChange } = this.props;

        if (onChange) {
            this.setState({ value });
            onChange(definition.name, value['id']);
        }
    };

    render() {
        let { definition, error, value, autoFocus } = this.props;
        if (value === null && definition.default !== null) {
            value = definition.default;
        }

        switch (definition.type) {
            case 'google_picker':
                const stateValue = this.state.value;
                let spreadsheetLabel = null;
                if (value) {
                    spreadsheetLabel = 'ID: ' + value;
                }
                if (stateValue) {
                    spreadsheetLabel = stateValue['name'];
                }
                return (
                    <Grid container alignItems="flex-end">
                        <Grid item xs={'auto'}>
                            <Box pr={1}>
                                <GooglePicker onPick={this.handlePickerChange} />
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <InputField definition={definition} disabled value={spreadsheetLabel} error={error} />
                        </Grid>
                    </Grid>
                );
            case 'input':
                return (
                    <InputField
                        definition={definition}
                        value={value}
                        error={error}
                        autoFocus={autoFocus}
                        onValueChanged={this.handleInputChange}
                    />
                );

            case 'select':
                return (
                    <FormControl fullWidth margin="dense" error={error !== ''}>
                        <Autocomplete
                            autoFocus={autoFocus}
                            value={definition.options.find((option) => option.value === value) || null}
                            getOptionLabel={(option) => option?.label || ''}
                            onChange={(event, selected) => this.handleChange(selected ? selected.value : null)}
                            data-testid="adapter_settings_field"
                            options={definition.options}
                            renderInput={(params) => (
                                <TextField {...params} label={<FieldLabel definition={definition} />} />
                            )}
                            PopperComponent={(props) => (
                                <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />
                            )}
                        />
                        {error !== '' && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                );
            case 'slider':
                const { slider_min, slider_max, slider_label_type } = definition;
                let marks = {
                    [slider_min]: { label: slider_min + slider_label_type },
                    [value]: { label: value + slider_label_type },
                    [definition.default]: { label: definition.default + slider_label_type },
                    [slider_max]: { label: slider_max + slider_label_type },
                };
                return (
                    <div>
                        <InputLabel>
                            <FieldLabel definition={definition} />
                        </InputLabel>
                        <Slider
                            autoFocus={autoFocus}
                            style={{ marginTop: 15, height: 32 }}
                            onChange={this.handleChange}
                            min={slider_min}
                            max={slider_max}
                            step={1}
                            defaultValue={value}
                            marks={marks}
                        />
                    </div>
                );
            case 'checkbox':
                return (
                    <div>
                        <FormControlLabel
                            control={<Switch checked={value} onChange={this.handleCheckboxChange} color="primary" />}
                            label={<FieldLabel definition={definition} />}
                        />
                    </div>
                );
            case 'system':
                return null;
            case 'uuid_generator':
                return (
                    <UuidGenerator definition={definition} error={error} value={value} onChange={this.handleChange} />
                );
            case 'password':
                return (
                    <PasswordField
                        autoFocus={autoFocus}
                        label={<FieldLabel definition={definition} />}
                        autoComplete="new-password"
                        fullWidth
                        margin="dense"
                        value={value || ''}
                        helperText={error}
                        error={error !== ''}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                );
            default:
                console.error('Invalid type', definition.type);
        }
    }
}

export default withTranslation('translations', { withRef: true })(AdapterSettingsField);

class UuidGenerator extends React.PureComponent {
    handleGenerate = () => {
        this.props.onChange(uuidv4());
    };

    render() {
        const { definition, error, value } = this.props;
        return (
            <Grid container alignItems="flex-end">
                <Grid item xs={8}>
                    <TextField
                        label={<FieldLabel definition={definition} />}
                        fullWidth
                        margin="dense"
                        value={value || ''}
                        helperText={error}
                        error={error !== ''}
                        InputProps={{ disableUnderline: false, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.handleGenerate}
                        fullWidth
                        style={{ position: 'relative', bottom: 5 }}
                        data-testid="uuid_generator.generate"
                    >
                        Generate
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

AdapterSettingsField.propTypes = {
    definition: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    error: PropTypes.string,
    value: PropTypes.any,
    autoFocus: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

AdapterSettingsField.defaultProps = {
    error: '',
};
