import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import Hint from '../Hint';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { splitOnce } from 'utils';
import passwordValidationService from 'service/PasswordValidationService';

const PasswordMeter = React.lazy(() => import('components/PasswordMeter'));

const mapDetails = (details) =>
    new Map(
        Array.from(details)
            .filter(([key]) => ['password', 'passwordReentry'].includes(key))
            .map(([key, value]) => {
                const [message, hint] = splitOnce(value, '\n');

                return [
                    key,
                    hint ? (
                        <span>
                            {message} <Hint>{hint}</Hint>
                        </span>
                    ) : (
                        message
                    ),
                ];
            }),
    );

class StateChangedError extends Error {}

class ChangePasswordForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordReentry: '',
            errors: mapDetails(props.errors),
            isChecking: false,
            needToEndUserSessions: null,
        };

        this.isFulfilled = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { errors: propsErrors, onReadyStateChanged } = this.props;
        const { errors: stateErrors, isChecking, password, passwordReentry } = this.state;
        let checkErrors = stateErrors;

        if (propsErrors !== prevProps.errors) {
            checkErrors = propsErrors;
            this.setState({
                errors: mapDetails(propsErrors),
            });
        }

        const isFulfilled =
            !isChecking &&
            !!password &&
            password === passwordReentry &&
            !checkErrors.has('password') &&
            !checkErrors.has('passwordReentry');

        if (this.isFulfilled !== isFulfilled) {
            this.isFulfilled = isFulfilled;
            onReadyStateChanged && onReadyStateChanged(isFulfilled);
        }
    }

    isCompleted() {
        return this.isFulfilled;
    }

    validate = async () => {
        const errors = new Map(this.state.errors);

        this.setState({
            isChecking: true,
        });

        try {
            await this.validatePassword(errors);
        } catch (e) {
            if (e instanceof StateChangedError) {
                throw new Error();
            }

            throw e;
        }

        this.validatePasswordReentry(errors);

        this.setState({
            errors,
            isChecking: false,
        });

        if (errors.size !== 0) {
            throw new Error();
        }
    };

    getPassword() {
        return this.state.password;
    }

    getNeedToEndUserSessions() {
        const { needToEndUserSessions } = this.state;
        if (needToEndUserSessions === null) {
            return !this.isUserNew();
        }
        return needToEndUserSessions;
    }

    handlePasswordChange = (event) => {
        const errors = new Map(this.state.errors);
        errors.delete('password');
        errors.delete('passwordReentry');

        this.setState({
            errors,
            password: event.target.value,
            passwordReentry: '',
        });
    };

    handlePasswordReentryChange = (event) => {
        const errors = new Map(this.state.errors);
        errors.delete('passwordReentry');

        this.setState({
            errors,
            passwordReentry: event.target.value,
        });
    };

    handleBlurPassword = async () => {
        const errors = new Map(this.state.errors);

        try {
            await this.validatePassword(errors);
        } catch (e) {
            if (e instanceof StateChangedError) {
                return;
            }

            throw e;
        }

        if (this.state.passwordReentry) {
            this.validatePasswordReentry(errors);
        }
        this.setState({
            errors,
        });
    };

    handleBlurPasswordReentry = () => {
        const errors = new Map(this.state.errors);
        this.validatePasswordReentry(errors);
        this.setState({
            errors,
        });
    };

    async validatePassword(errors) {
        const { password } = this.state;
        const errorMessageKey = await passwordValidationService.getPasswordValidationMessage(
            password,
            this.getUserInputs(),
        );
        if (password !== this.state.password) {
            throw new StateChangedError();
        }

        if (errorMessageKey) {
            const errorHintKey = errorMessageKey + '_hint';
            errors.set(
                'password',
                this.props.i18n.exists(errorHintKey) ? (
                    <span>
                        {this.props.t(errorMessageKey)} <Hint>{this.props.t(errorHintKey)}</Hint>
                    </span>
                ) : (
                    this.props.t(errorMessageKey)
                ),
            );
        } else {
            errors.delete('password');
        }
    }

    validatePasswordReentry(errors) {
        if (this.state.password !== this.state.passwordReentry) {
            errors.set('passwordReentry', this.props.t('user_form.errors.password_reentry'));
        } else {
            errors.delete('passwordReentry');
        }
    }

    handleNeedToEndUserSessionsChange = (event) => {
        const needToEndUserSessions = event.target.checked;
        this.setState({
            needToEndUserSessions,
        });
    };

    getUserInputs() {
        const { user } = this.props;
        return {
            email: user.email || '',
            name: user.name || '',
        };
    }

    isUserNew() {
        return this.props.user.id === null;
    }

    render() {
        const { t } = this.props;

        const { errors, password, passwordReentry } = this.state;

        return (
            <div>
                <PasswordField
                    autoFocus
                    label={t('reset_password.form.password.label')}
                    data-testid="change_password_form.password"
                    fullWidth
                    margin="dense"
                    value={password ?? ''}
                    helperText={errors.get('password') || ''}
                    error={errors.has('password')}
                    onChange={this.handlePasswordChange}
                    onBlur={this.handleBlurPassword}
                />
                {password?.length > 0 && (
                    <Suspense fallback={'Loading...'}>
                        <PasswordMeter password={password || ''} userInputs={this.getUserInputs()} />
                    </Suspense>
                )}
                <PasswordField
                    label={t('reset_password.form.password_reentry.label')}
                    data-testid="change_password_form.password_reentry"
                    fullWidth
                    margin="dense"
                    value={passwordReentry ?? ''}
                    helperText={errors.get('passwordReentry') || ''}
                    error={errors.has('passwordReentry')}
                    onChange={this.handlePasswordReentryChange}
                    onBlur={this.handleBlurPasswordReentry}
                />
                {!this.isUserNew() && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.getNeedToEndUserSessions()}
                                onChange={this.handleNeedToEndUserSessionsChange}
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                {t('reset_password.form.end_user_sessions')}{' '}
                                <Hint>{t('reset_password.form.end_user_sessions_hint')}</Hint>
                            </span>
                        }
                    />
                )}
            </div>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ChangePasswordForm);
