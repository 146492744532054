import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { Filter } from '../components/utils/tableFilter';
import { Sorting } from '@devexpress/dx-react-grid';

export enum SessionStatus {
    Success = 's',
    PartialSuccess = 'p',
    Fail = 'f',
    Suspended = 'd',
    Cancelled = 'c',
}

export enum Event {
    Button = 'button',
    CreatedManually = 'created_manually',
    UpdatedManually = 'updated_manually',
    RemovedManually = 'removed_manually',
    ScheduledProcess = 'scheduled_process',
    SsoAutoRegistered = 'sso_auto_registered',
    Geocoding = 'geocoding',
    Import = 'import',
    InboundApiCall = 'inbound_api_call',
    ProspectExport = 'prospect_export',
    TerritoryAssignment = 'territory_assignment',
    AutomationCommit = 'automation_commit',
    System = 'system',
}

export enum LogStatus {
    Success = 's',
    Fail = 'f',
}

export enum Action {
    Created = '+',
    Updated = '~',
    Deleted = '-',
}

export interface DataExchangeSession {
    id: number;
    event: Event;
    eventType: string;
    userId: number | null;
    userName: string | null;
    hasAutomation: boolean;
    automationSessionsAvailable: boolean | null;
    automationSessionId: string | null;
    entities: any[];
    newCount: number;
    modCount: number;
    delCount: number;
    failedCount: number;
    createdAt: string;
    startedAt: string | null;
    finishedAt: string | null;
    time: string | null;
    status: SessionStatus | null;
    statusMessage: string | null;
    data: { url?: string; method?: string; request?: string; response?: string } | null;
}

export interface DataExchangeLog {
    id: number;
    sessionId: number;
    entityId: number;
    entityName: string | null;
    recordId: string | null;
    recordName: string | null;
    action: Action;
    data: any;
    status: LogStatus;
    statusMessage: string | null;
    createdAt: string;
    session?: DataExchangeSession;
}

export interface RecordLogsRequest {
    accountId: number;
    recordId: string;
    entityId: number;
    filters: Filter[];
    sorting: Sorting[];
    page: number;
    pageSize: number;
}

class DataExchangeManager extends BackendService {
    getSessions(accountId: number, filters: Filter[], sorting: Sorting[], page: number, pageSize: number) {
        const url = reverse(apiRoutes.account.dataExchange.sessions, { accountId });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize });
    }

    getSessionLogs(
        accountId: number,
        session: DataExchangeSession,
        filters: Filter[],
        sorting: Sorting[],
        page: number,
        pageSize: number,
    ) {
        const url = reverse(apiRoutes.account.dataExchange.sessions.logs, {
            accountId,
            sessionId: session.id,
        });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize });
    }

    getRecordLogs(request: RecordLogsRequest) {
        const { accountId, entityId, recordId, filters, sorting, page, pageSize } = request;
        const url = reverse(apiRoutes.account.dataExchange.logs, { accountId, entityId, recordId });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize });
    }
}

export const dataExchangeManager = new DataExchangeManager();
