export const addressParts = {
    street: 'Street',
    street2: 'Street 2',
    city: 'City',
    state: 'State/Province',
    zip: 'ZIP',
    country: 'Country',
    //house: 'House'
};

export const minimumRequiredAddressParts = [];
