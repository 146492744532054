import { createCreatedRbac, createDefaultPermission, OBJECT_TYPE_TERRITORY_GROUP } from '../../SharingSettingsDialog';
import { v4 as uuidv4 } from 'uuid';
import { Territory } from './Territory';

export enum TYPE_METRIC {
    FUNCTION = 'function',
    CUSTOM_TEXT = 'custom',
    CUSTOM_NUMBER = 'custom_number',
    DEMOGRAPHIC = 'demographic',
}

export const DEFAULT_COLOR = '#3388ff';
export const DEFAULT_COLOR_NAME = '#646464';
export const DEFAULT_COLOR_NO_DATA = '#9b9b9b';

export const COLOR_SCALE = [
    { offset: '0.00', color: 'rgb(237, 241, 11)' },
    { offset: '0.279', color: 'rgb(255, 138, 0)' },
    { offset: '0.571', color: 'rgb(255, 5, 104)' },
    { offset: '0.828', color: 'rgb(196, 5, 255)' },
    { offset: '1.00', color: 'rgb(108, 19, 135)' },
];

export enum NAMING_TAG {
    TERRITORY_NAME = 'territory-name',
}

export enum TYPE_FUNCTION {
    SUM = 'sum',
    MAX = 'max',
    AVG = 'avg',
    MIN = 'min',
    COUNT = 'count',
}

export enum COLOR_TYPE {
    FIXED = 1,
    METRIC = 2,
}

export enum NAMING_COLOR_TYPE {
    FIXED = 1,
    TERRITORY = 2,
}

export enum NAMING_SIZE_TYPE {
    AUTO = 1,
    FIXED = 2,
}

export const NAMING_SIZE = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

export interface Color {
    color: string;
    offset: string;
    opacity?: number;
    id?: number;
    active?: boolean;
}

export interface NamingProps {
    showTerritoryName: boolean;
    template: { tags: Array<{}>; value: string };
    colorType: NAMING_COLOR_TYPE;
    color: string | null;
    sizeType: NAMING_SIZE_TYPE;
    size: number | null;
    showDataPopUp: boolean;
    dataPopUp: Array<string>;
}

export const GetMinValue = (props: ColorMetricProps): number | null => {
    return props.minValue ?? props.minValueAuto;
};

export const GetMaxValue = (props: ColorMetricProps): number | null => {
    return props.maxValue ?? props.maxValueAuto;
};

export interface ColorMetricProps {
    metricUuid: string | null;
    colorScale: Array<Color>;
    minValue: number | null;
    maxValue: number | null;
    minValueAuto: number | null;
    maxValueAuto: number | null;
    ranges: number;
    noDataColor: string;
}

export enum DemographicResponseType {
    count = 'count',
    value = 'value',
    percent = 'percent',
}

export interface Metric {
    uuid: string;
    code: string;
    name: string;
    type: TYPE_METRIC;
    function: TYPE_FUNCTION;
    fieldId: number | null;
    entityId: number | null;
    demographicId: number | null;
    demographicResponseType?: DemographicResponseType;
    demographicName: string | null;
}

export interface TerritoryGroup {
    id: number | null;
    uuid: string;
    name: string;
    code: string;
    color: string;
    colorType: COLOR_TYPE;
    colorMetricProps: ColorMetricProps;
    namingProps: NamingProps;
    showUnionArea: boolean;
    showNameArea: boolean;
    isAutoAssign: boolean;
    fillTransparency: number;
    borderTransparency: number;
    tolerance: number;
    territories: Array<Territory>;
    rbac: {};
    permission: {};
    metrics: Array<Metric>;
    geoPartIds: number[];
}

export const CreateDefaultColorMetricProps = () => {
    return {
        metricUuid: null,
        colorScale: COLOR_SCALE,
        minValue: null,
        maxValue: null,
        minValueAuto: null,
        maxValueAuto: null,
        ranges: 10,
        noDataColor: DEFAULT_COLOR_NO_DATA,
    };
};

export const CreateDefaultNamingProps = () => {
    return {
        showTerritoryName: false,
        template: { tags: [], value: '' },
        colorType: NAMING_COLOR_TYPE.FIXED,
        color: DEFAULT_COLOR_NAME,
        sizeType: NAMING_SIZE_TYPE.FIXED,
        size: 14,
        showDataPopUp: false,
        dataPopUp: [],
    };
};

export const CreateTerritoryGroup = (): TerritoryGroup => {
    return {
        id: null,
        uuid: uuidv4(),
        name: '',
        code: '',
        color: DEFAULT_COLOR_NAME,
        colorType: COLOR_TYPE.FIXED,
        colorMetricProps: CreateDefaultColorMetricProps(),
        namingProps: CreateDefaultNamingProps(),
        isAutoAssign: true,
        showUnionArea: true,
        showNameArea: false,
        fillTransparency: 80,
        borderTransparency: 60,
        tolerance: 70,
        territories: [],
        rbac: createCreatedRbac(),
        permission: createDefaultPermission(OBJECT_TYPE_TERRITORY_GROUP, null),
        metrics: [],
        geoPartIds: [],
    };
};

export const FormatValueProperties = (metric: Metric, value: string | null) => {
    if (metric.type === TYPE_METRIC.CUSTOM_NUMBER) {
        const formatValue = Number.parseFloat(value ?? '0');
        return Number.isNaN(formatValue) ? 0 : formatValue;
    }

    return value;
};

export const FormatToLocaleValueProperties = (metric: Metric, value: string | null) => {
    if (
        value &&
        (metric.type === TYPE_METRIC.CUSTOM_NUMBER ||
            metric.type === TYPE_METRIC.DEMOGRAPHIC ||
            metric.type === TYPE_METRIC.FUNCTION)
    ) {
        const formatValue = Number.parseFloat(value ?? '0');
        return Number.isNaN(formatValue) ? 0 : formatValue.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }

    return value;
};
