import CryptoJS from 'crypto-js';
import config from '../params';

class AesEncryptor {
    encrypt(string: string) {
        const key = CryptoJS.enc.Utf8.parse(config.crypto_key);
        const encrypted = CryptoJS.AES.encrypt(string, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
}

const aesEncryptor = new AesEncryptor();
export default aesEncryptor;
