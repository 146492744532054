import React from 'react';
import { Grid, TextField as TextFieldInput, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import i18n from '../../../locales/i18n';
import Text from '../../../service/workflow_actions/forms/fields/Text';
import AbstractField from './AbstractField';
import { weAreInNativeApp } from '../../../utils';
import BarcodeScanner from './BarCodeScaner';

const t = i18n.t.bind(i18n);

class TextField extends AbstractField {
    renderInput(field, errors) {
        return (
            <Grid container>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <Grid xs={weAreInNativeApp() && field.is_barcode_data ? 'auto' : 12} style={{ flexGrow: 1 }} item>
                        <TextFieldInput
                            fullWidth
                            margin="dense"
                            type={field.getHtmlType() ?? 'text'}
                            value={field.getValue() ?? ''}
                            onChange={this.handleChange}
                            onBlur={this.handleValidate}
                            multiline={field.getIsMultiline()}
                            rows={field.getIsMultiline() ? field.getLinesCount() : null}
                            disabled={field.getIsDisabled()}
                            error={errors.size > 0}
                            helperText={errors.get('value') ? t(errors.get('value')) : ''}
                            variant="outlined"
                            className="field-text-input"
                            InputProps={{ className: 'field-Input' }}
                            inputProps={{ className: 'field-input' }}
                            autoFocus={this.props.autoFocus}
                        />
                    </Grid>
                    {field.is_barcode_data && weAreInNativeApp() && <BarcodeScanner handleChange={this.handleChange} />}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {this.props.field.getMaxLength() !== null && (
                            <Grid item>
                                <Typography display="inline" component="span" color="textSecondary">
                                    {t('workflow_actions.forms.field.text.maxLength')}:{' '}
                                    {this.props.field.getMaxLength()}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

TextField.defaultProps = {
    autoFocus: false,
};

TextField.propTypes = {
    field: PropTypes.instanceOf(Text).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default TextField;
