import React from 'react';

import PureFormDialog from '../PureFormDialog';
import { WithTranslation, withTranslation } from 'react-i18next';
import ExpressionBuilder from '../ExpressionBuilder';
import { expressionManager } from '../../service/ExpressionManager';
import { Button, FormHelperText } from '@material-ui/core';
import { CallContext } from '../utils/CallContext';
import { IExpression } from '../../interfaces';
import { SharingRule } from './RecordSharing';
import { FormActions } from '../PureFormDialog/Form';
import Backdrop from '../../components/Backdrop';

interface ExpressionBuilderProps extends WithTranslation {
    rule: SharingRule;
    onSave: (rule: SharingRule) => void;
    onClose: () => void;
    entityId: number;
}

interface ExpressionBuilderState {
    callContext: CallContext;
    errors: Map<string, string>;
    loading: boolean;
}

class ExpressionBuilderForm extends React.PureComponent<ExpressionBuilderProps, ExpressionBuilderState> {
    expressionBuilder: any;

    constructor(props: ExpressionBuilderProps) {
        super(props);

        this.state = {
            errors: new Map(),
            callContext: CallContext.create(this.props.entityId),
            loading: false,
        };

        this.expressionBuilder = React.createRef();
    }

    handleSave = () => {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then((result) => {
                const res = result as IExpression.IValidationResult;
                const rule = {
                    ...this.props.rule,
                    condition: res.validationResult,
                    conditionExpression: res.expression,
                } as SharingRule;
                this.props.onSave(rule);
            })
            .catch((errors) => {
                this.setState({
                    errors,
                });
            });
    };

    validate = () => {
        return new Promise((resolve, reject) => {
            this.expressionBuilder.current.clearErrors();
            this.expressionBuilder.current
                .getExpression()
                .then((result?: IExpression.IValidationResult) => {
                    this.setState({
                        loading: false,
                    });
                    const errors = new Map();
                    if (!result || !result.expression) {
                        errors.set('expression', this.props.t('expresion_builder.wrong_expression'));
                    }
                    if (errors.size === 0) {
                        resolve(result);
                    } else {
                        reject(errors);
                    }
                })
                .catch((e: any) => {
                    this.setState({
                        loading: false,
                    });
                    throw e;
                });
        });
    };

    validateExpression = (expression: any) => {
        this.setState({
            loading: true,
        });
        return expressionManager.toFormula(expression, this.state.callContext);
    };

    render() {
        const { t } = this.props;
        return (
            <PureFormDialog
                open
                title={t('record_sharing.rule') as string}
                onClose={this.props.onClose}
                maxWidth="lg"
                fullWidth
            >
                <Backdrop loading={this.state.loading}>
                    <ExpressionBuilder
                        callContext={this.state.callContext}
                        onValidate={this.validateExpression}
                        defaultExpression={this.props.rule.conditionExpression}
                        ref={this.expressionBuilder}
                        showGroupByPrefix
                    />
                    {this.state.errors.has('expression') && (
                        <FormHelperText error>{this.state.errors.get('expression')}</FormHelperText>
                    )}

                    <FormActions>
                        <Button
                            color="primary"
                            onClick={this.handleSave}
                            data-testid="expresion_builder.modal.button.save"
                        >
                            {t('button.save')}
                        </Button>
                    </FormActions>
                </Backdrop>
            </PureFormDialog>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ExpressionBuilderForm);
