import React from 'react';
import './style.css';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatMoney, MoneyFormat } from './MoneyFormat';
import { truncateTh } from './SubscriptionPanel';
import Icon from '@material-ui/core/Icon';
import { paddleManager } from '../../service/PaddleManager';
import BillingWidget from './BillingWidget';
import { PaymentHistoryComponent } from './PaymentHistory';
import { USER_ROLE } from '../../service/UserManager';
import { withTranslation } from 'react-i18next';
import Hint from '../Hint';
import { SubscriptionStatus } from '../../service/types';
import NotePanel from './NotePanel';

class BillingPanel extends BillingWidget {
    constructor(props) {
        super(props);
        this.state = {
            paymentHistoryOpen: false,
        };
    }

    componentDidMount() {
        const subscription = this.props.subscription;

        if (this.props.updateAutoStart && subscription.status === SubscriptionStatus.PAST_DUE) {
            this.handleChangePaymentMethod();
        }
    }

    makeTextTooltip = (account, sub) => {
        const capPay = sub.recordChunksPurchased * sub.recordChunkSize;

        return (
            <>
                {this.props.t('billing.billing_panel.tooltip', {
                    recordCount: account.recordsCount.toLocaleString(),
                    capPay: truncateTh(capPay),
                    capPayTo: truncateTh(sub.recordCapFree + capPay),
                    chunkSize: truncateTh(sub.recordChunkSize),
                })}
            </>
        );
    };

    makeStorageTooltip = (sub) => {
        return this.props.t('billing.billing_panel.additional_storage.hint', {
            fileStorageUsage: this.bytesToGb(sub.fileStorageUsage),
            usageDiff: this.bytesToGb(sub.fileStorageUsage - sub.storageCapFree),
            storageCapFree: this.bytesToGb(sub.storageCapFree),
            additionalStorage: this.bytesToGb(sub.storageChunks * sub.storageChunkSize),
            totalStorage: this.bytesToGb(sub.storageCapFree + sub.storageChunks * sub.storageChunkSize),
            storageChunkSize: this.bytesToGb(sub.storageChunkSize),
        });
    };

    makeAnalyticsTooltip = (sub) => {
        return this.props.t('billing.billing_panel.analytics.hint', {
            userCountCreateModify: sub.analyticsCreateModifyUserCount,
            userCountView: sub.analyticsViewUserCount,
        });
    };

    makeCalendarTooltip = (sub) => {
        return this.props.t('billing.billing_panel.calendar.hint', {
            userCount: sub.calendarUserCount,
        });
    };

    bytesToGb = (value) => {
        return (value / 1000 / 1000 / 1000).toFixed(2);
    };

    handleChangePaymentMethod = () => {
        if (this.props.subscription.status === SubscriptionStatus.PAST_DUE) {
            paddleManager.openWindowUpdatePaymentMethodWithPay(this.props.account, this.props.subscription.updateUrl);
            return;
        }
        paddleManager.openWindowUpdatePaymentMethod(this.props.account, this.props.subscription.updateUrl);
    };

    handlePaymentHistoryClose = () => {
        this.setState({
            paymentHistoryOpen: false,
        });
    };

    handlePaymentHistoryOpen = () => {
        this.setState({
            paymentHistoryOpen: true,
        });
    };

    render() {
        const { account, subscription, user, t } = this.props;
        const plan = subscription.plan;
        const isDisablePaymentHistory = subscription.paddleSubscriptionId === null;
        const tooltipText = this.makeTextTooltip(account, subscription);
        const currentPrice = subscription.subPriceTotalCur;
        const nextBillingDate = this.formatDate(subscription.nextBillDate, user);
        const paymentFailedLastAt = this.formatDate(subscription.paymentFailedLastAt, user);
        const isShowRecordsCap = subscription.recordChunkPmCur > 0 && subscription.recordChunksPurchased > 0;
        const isShowAdminPm = subscription.adminPmCur !== 0;
        const isShowSharedMapsPm = subscription.sharedMapsPmCur !== 0;
        const isShowPlanerPm = subscription.planerPmCur !== 0;
        const isShowCustomRoutingPm = subscription.routingCustomPmCur !== 0;
        const isShowRoutingPm = subscription.routingPmCur !== 0;
        const isShowAiTokensPmCur = subscription.aiTokensPmCur !== 0;
        const isShowAiUsersPmCur = subscription.aiUsers > 0 && subscription.aiUsersPmCur !== 0;
        const isShowTranscriptionSecondsPmCur = subscription.transcriptionSecondsPmCur !== 0;
        const isShowTranscriptionUsersPmCur =
            subscription.transcriptionUsers > 0 && subscription.transcriptionUsersPmCur !== 0;
        const isShowStoragePm = subscription.storagePmCur !== 0;
        const isShowAnalyticsPm = subscription.analyticsPmCur !== 0;
        const isShowCalendarPm = subscription.calendarPmCur !== 0;
        const isShowProspectPm = subscription.prospectPmCur !== 0;
        const isSuperAdmin =
            this.props.user.switchedRole === USER_ROLE.SUPERADMIN || this.props.user.role.code === USER_ROLE.SUPERADMIN;
        const isNotCustomStatus = subscription.status !== SubscriptionStatus.CUSTOM;
        return (
            <div className="c-billing-panel">
                {!isNotCustomStatus && isSuperAdmin && (
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Icon className="icon-warning">warning</Icon>
                                </Grid>
                                <Grid item style={{ marginLeft: 10 }}>
                                    <h4>{t('billing.billing_panel.visible_only_super_admin')}</h4>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {isNotCustomStatus && (
                    <>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <h2>{t('billing.billing_panel.title')}</h2>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    className="main-button"
                                    disabled={isDisablePaymentHistory}
                                    onClick={this.handlePaymentHistoryOpen}
                                    variant="contained"
                                    data-testid="billing.billing_panel.payment_history.button"
                                >
                                    {t('billing.billing_panel.payment_history')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={5} sm={4}>
                                <strong>
                                    {' '}
                                    {t('billing.billing_panel.price_per_period', {
                                        period: t('billing.period_' + plan.period),
                                    })}
                                </strong>
                                <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                    {t('billing.billing_panel.price_per_title')}
                                </Hint>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={1}>
                                {t('billing.users')}:
                            </Grid>
                            <Grid item xs={7} className="price">
                                <strong>
                                    <MoneyFormat currency={subscription.currency} price={plan.price} /> *{' '}
                                    {subscription.usersPurchased}
                                </strong>
                            </Grid>
                        </Grid>
                        {isShowRecordsCap && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.records_above_cap')}
                                    <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                        {tooltipText}
                                    </Hint>
                                    :
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.recordChunkPmCur}
                                        />{' '}
                                        * {subscription.recordChunksPurchased}
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowAdminPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t(
                                        subscription.adminPmCur > 0
                                            ? 'billing.billing_panel.additional_service'
                                            : 'billing.billing_panel.discount',
                                    )}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat currency={subscription.currency} price={subscription.adminPmCur} />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowSharedMapsPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.shared_maps')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.sharedMapsPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowPlanerPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.planer')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.planerPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowRoutingPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.routing')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.routingPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowCustomRoutingPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.routing_custom')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.routingCustomPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowStoragePm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.additional_storage')}
                                    <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                        {this.makeStorageTooltip(subscription)}
                                    </Hint>
                                    :
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.storageChunkPmCur}
                                        />{' '}
                                        * {subscription.storageChunks}
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowAnalyticsPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.analytics')}
                                    <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                        {this.makeAnalyticsTooltip(subscription)}
                                    </Hint>
                                    :
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        {subscription.analyticsViewUserCount > 0 && (
                                            <>
                                                <MoneyFormat
                                                    currency={subscription.currency}
                                                    price={subscription.analyticsViewPerUserPmCur}
                                                />{' '}
                                                * {subscription.analyticsViewUserCount}{' '}
                                            </>
                                        )}
                                        {subscription.analyticsViewUserCount > 0 &&
                                            subscription.analyticsCreateModifyUserCount > 0 &&
                                            '+ '}
                                        {subscription.analyticsCreateModifyUserCount > 0 && (
                                            <>
                                                <MoneyFormat
                                                    currency={subscription.currency}
                                                    price={subscription.analyticsCreateModifyPerUserPmCur}
                                                />{' '}
                                                * {subscription.analyticsCreateModifyUserCount}
                                            </>
                                        )}
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowCalendarPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.calendar')}
                                    <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                                        {this.makeCalendarTooltip(subscription)}
                                    </Hint>
                                    :
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        {subscription.calendarUserCount > 0 && (
                                            <>
                                                <MoneyFormat
                                                    currency={subscription.currency}
                                                    price={subscription.calendarPerUserPmCur}
                                                />{' '}
                                                * {subscription.calendarUserCount}
                                            </>
                                        )}
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowProspectPm && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.prospect')}:
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.prospectPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowAiTokensPmCur && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.available_ai_tokens')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.aiTokensPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowAiUsersPmCur && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.ai_users')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    {subscription.aiUsers > 1 && (
                                        <strong>
                                            <MoneyFormat
                                                currency={subscription.currency}
                                                price={subscription.aiUsersPmCur / subscription.aiUsers}
                                            />{' '}
                                            * {subscription.aiUsers}
                                        </strong>
                                    )}
                                    {subscription.aiUsers === 1 && (
                                        <strong>
                                            <MoneyFormat
                                                currency={subscription.currency}
                                                price={subscription.aiUsersPmCur}
                                            />
                                        </strong>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {isShowTranscriptionSecondsPmCur && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.available_transcription_minutes')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    <strong>
                                        <MoneyFormat
                                            currency={subscription.currency}
                                            price={subscription.transcriptionSecondsPmCur}
                                        />
                                    </strong>
                                </Grid>
                            </Grid>
                        )}
                        {isShowTranscriptionUsersPmCur && (
                            <Grid className="row" container alignItems="center">
                                <Grid item xs={4}>
                                    {t('billing.billing_panel.transcription_users')}
                                </Grid>
                                <Grid item xs={4} className="price">
                                    {subscription.transcriptionUsers > 1 && (
                                        <strong>
                                            <MoneyFormat
                                                currency={subscription.currency}
                                                price={
                                                    subscription.transcriptionUsersPmCur /
                                                    subscription.transcriptionUsers
                                                }
                                            />{' '}
                                            * {subscription.transcriptionUsers}
                                        </strong>
                                    )}
                                    {subscription.transcriptionUsers === 1 && (
                                        <strong>
                                            <MoneyFormat
                                                currency={subscription.currency}
                                                price={subscription.transcriptionUsersPmCur}
                                            />
                                        </strong>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
                {(isNotCustomStatus || isSuperAdmin) && (
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <hr />
                        </Grid>
                    </Grid>
                )}
                {!isNotCustomStatus && isSuperAdmin && (
                    <Grid container alignItems="center">
                        <Grid item xs={8} className="price">
                            <strong>
                                {t('billing.billing_panel.custom_price', {
                                    price: formatMoney(subscription.currency, subscription.subPriceTotalCur),
                                })}
                                {subscription.currency !== 'USD' && (
                                    <span>
                                        {' '}
                                        (<MoneyFormat currency="USD" price={subscription.subPriceTotalUsd} />)
                                    </span>
                                )}
                            </strong>
                        </Grid>
                    </Grid>
                )}
                {isNotCustomStatus && (
                    <>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={8} className="price">
                                <strong>
                                    <MoneyFormat currency={subscription.currency} price={currentPrice} />
                                </strong>
                            </Grid>
                            <Grid item>
                                <span className="taxes-span"> {t('billing.billing_panel.applicable_taxes')}</span>
                            </Grid>
                        </Grid>
                        {(subscription.status === SubscriptionStatus.ACTIVE ||
                            subscription.status === SubscriptionStatus.PAST_DUE) && (
                            <div className="payment-block">
                                <Grid className="new-block" container alignItems="center">
                                    <Grid item xs={6}>
                                        {t('billing.billing_panel.next_payment_on')}
                                        <strong>{nextBillingDate}</strong>
                                    </Grid>
                                </Grid>
                                {subscription.status === SubscriptionStatus.ACTIVE && (
                                    <Grid className="new-block" container alignItems="center">
                                        <Grid item xs={6}>
                                            {t('billing.billing_panel.payment_method')}
                                            <strong>
                                                {subscription.paymentMethod} *
                                                {subscription.lastFourDigitsOfBankCard || '****'}
                                            </strong>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                onClick={this.handleChangePaymentMethod}
                                                data-testid="billing.billing_panel.payment_method.button.change"
                                            >
                                                {t('button.change')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                                {subscription.status === SubscriptionStatus.PAST_DUE && (
                                    <div>
                                        <Grid className="new-block" container alignItems="center">
                                            <Grid item xs={6}>
                                                <span className="fail-payment-method">
                                                    {t('billing.billing_panel.payment_method_failed')}
                                                    <strong>
                                                        {subscription.paymentMethod} *
                                                        {subscription.lastFourDigitsOfBankCard || '****'}
                                                    </strong>
                                                </span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    className="update-fail-card"
                                                    variant="contained"
                                                    onClick={this.handleChangePaymentMethod}
                                                    data-testid="billing.billing_panel.failed_card.button.update"
                                                >
                                                    Update
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid className="new-block" container alignItems="flex-start">
                                            <Grid item xs={1}>
                                                <Icon className="icon-error">error</Icon>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <span className="fail-payment-method">
                                                    {t('billing.billing_panel.payment_method_failed_title', {
                                                        paymentFailedLastAt,
                                                    })}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        )}
                        {!isDisablePaymentHistory && (
                            <PaymentHistoryComponent
                                subscription={this.props.subscription}
                                openHistory={this.state.paymentHistoryOpen}
                                onCloseDialog={this.handlePaymentHistoryClose}
                            />
                        )}
                    </>
                )}
                {isSuperAdmin && <NotePanel subscription={this.props.subscription} />}
            </div>
        );
    }
}

BillingPanel.propTypes = {
    account: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func,
    updateAutoStart: PropTypes.bool,
};

BillingPanel.defaultProps = {
    updateAutoStart: false,
};

export default withTranslation()(BillingPanel);
