import BackendService from '../../api/BackendService';
import {
    getAllowedToSaveItemsToCalendars,
    getCurrentAccountActiveCalendars,
} from '../../components/Calendar/Helpers/CalendarHelper';
import { LassoActiveType, LassoMode } from '../../components/MapPage/constants';
import { LassoFinishedEventPayload } from '../../components/MapPage/Lasso/MultiLassoBlock';
import { EntityViewSource, EntityViewSourceEntity, STORAGE_KEY_PREFIX } from '../../components/types';
import events from '../../events';
import { WaitingListItem } from '../../interfaces/calendar/calendar';
import i18n from '../../locales/i18n';
import dispatcher from '../dispatcher';
import { enqueueSnackbarService } from '../MapPage';
import metadataManager from '../MetadataManager';
import { userManager } from '../UserManager';

import { CalendarEventsSuccessPayload, JOB_ID_TYPE_SUBSTRING } from './CalendarEventManager';

class WaitingListManager extends BackendService {
    constructor() {
        super();

        dispatcher.subscribe(events.WS_CALENDAR_EVENT_JOB_SUCCESS, this, this.handleCalendarEventsSuccess);
        dispatcher.subscribe(events.LASSO_FINISHED, this, this.handleLassoFinished);
    }

    private getStorageKey(): string {
        return STORAGE_KEY_PREFIX.WAITING_LIST_ITEMS + userManager.getCurrentUser().id;
    }

    private getItemsFromStorage(): Map<string, WaitingListItem> {
        const items = window.localStorage.getItem(this.getStorageKey());
        if (!items) {
            return new Map<string, WaitingListItem>();
        }

        return new Map<string, WaitingListItem>(JSON.parse(items));
    }

    private setItemsToStorage(items: Map<string, WaitingListItem>): void {
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(Array.from(items)));
    }

    private handleCalendarEventsSuccess = (payload: CalendarEventsSuccessPayload): void => {
        if (
            payload.jobId.includes(JOB_ID_TYPE_SUBSTRING.LIST_REQUEST) ||
            payload.jobId.includes(JOB_ID_TYPE_SUBSTRING.DRAFT_OPERATION)
        ) {
            return;
        }

        const itemIdsToDelete: string[] = [];
        for (const event of payload.events) {
            itemIdsToDelete.push(event.id);
        }

        this.remove(itemIdsToDelete, false);
    };

    private handleLassoFinished = (payload: LassoFinishedEventPayload): void => {
        if (payload.type !== LassoActiveType.CALENDAR) {
            return;
        }
        if (payload.mode === LassoMode.MINUS) {
            const itemsToDelete = [] as string[];
            for (const pointData of payload.pointsData) {
                const [entityId, recordId] = pointData.id.split('_');

                const storageItems = this.getItemsFromStorage();
                for (const [key, storageItem] of storageItems) {
                    if (
                        storageItem.relatedTo?.id === recordId &&
                        storageItem.relatedTo?.entityId === Number(entityId)
                    ) {
                        itemsToDelete.push(key);
                    }
                }
            }

            if (itemsToDelete.length) {
                this.remove(itemsToDelete);
            }
        }
    };

    private showWaitingListSnackBarError = (couldNotSaveEventCount: number): void => {
        enqueueSnackbarService.sendErrorMessage(
            i18n.t('waiting_list.failed_to_add_items_no_calendars_available', { count: couldNotSaveEventCount }),
        );
    };

    dispose(): void {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    getItems(): WaitingListItem[] {
        return Array.from(this.getItemsFromStorage().values());
    }

    async add(...items: WaitingListItem[]): Promise<void> {
        if (items.length === 0) {
            return;
        }

        const calendars = Array.from((await getCurrentAccountActiveCalendars()).values());

        const dataSources = await metadataManager.requestAccountDataSourcesForUser(userManager.getCurrentAccount().id);
        const entitiesMap = new Map<number, EntityViewSourceEntity>();
        dataSources.forEach((dataSource: EntityViewSource) => {
            dataSource.entities.forEach((dsEntity: EntityViewSourceEntity) => {
                entitiesMap.set(dsEntity.id, dsEntity);
            });
        });
        const allowedItems = await getAllowedToSaveItemsToCalendars(items, calendars, entitiesMap);

        const storageItems = this.getItemsFromStorage();
        allowedItems.forEach((item) => {
            storageItems.set(item.id, item);
        });
        this.setItemsToStorage(storageItems);

        if (allowedItems.length) {
            dispatcher.dispatch(events.CALENDAR_WAITING_LIST_UPDATED);
            enqueueSnackbarService.sendSuccessMessage(
                i18n.t('waiting_list.added_records_message', { count: allowedItems.length }),
            );
        }

        if (items.length !== allowedItems.length) {
            this.showWaitingListSnackBarError(items.length - allowedItems.length);
        }
    }

    remove(itemIds: string[], showSnackbarMessage: boolean = true): void {
        if (itemIds.length === 0) {
            return;
        }

        const storageItems = this.getItemsFromStorage();
        let deletedCount = 0;
        itemIds.forEach((itemId: string) => {
            if (storageItems.has(itemId)) {
                storageItems.delete(itemId);
                deletedCount++;
            }
        });
        this.setItemsToStorage(storageItems);

        if (deletedCount > 0) {
            dispatcher.dispatch(events.CALENDAR_WAITING_LIST_UPDATED);
        }
        if (!showSnackbarMessage) {
            return;
        }

        if (deletedCount > 0) {
            enqueueSnackbarService.sendSuccessMessage(
                i18n.t('waiting_list.removed_records_message', { count: deletedCount }),
            );
        }
    }

    clear(): void {
        const storageItems = this.getItemsFromStorage();
        storageItems.clear();
        this.setItemsToStorage(storageItems);

        dispatcher.dispatch(events.CALENDAR_WAITING_LIST_UPDATED);
    }

    updateOrder(items: WaitingListItem[]): void {
        const itemsMap = new Map<string, WaitingListItem>();
        items.forEach((item: WaitingListItem) => {
            itemsMap.set(item.id, item);
        });
        this.setItemsToStorage(itemsMap);

        dispatcher.dispatch(events.CALENDAR_WAITING_LIST_UPDATED);
    }
}

export const waitingListManager = new WaitingListManager();
