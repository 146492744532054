import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../locales/i18n';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import Text from '../../../../service/workflow_actions/forms/fields/Text';

const t = i18n.t.bind(i18n);

class TextForm extends AbstractForm {
    renderFieldInputs(field, errors) {
        return (
            <>
                {this.renderIsRequiredInput(field, errors)}
                {this.renderIsHiddenInput(field, errors)}
                <Grid item xs={2}>
                    <TextField
                        label={t('workflow_actions.forms.field.text.maxLength')}
                        data-testid="workflow_actions.forms.field.text.maxLength"
                        fullWidth
                        margin="dense"
                        name="maxLength"
                        type="number"
                        value={field.maxLength ?? ''}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isMultiline"
                                checked={field.isMultiline}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.forms.field.text.isMultiline')}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        style={{ width: '100%' }}
                        label={<Box whiteSpace="nowrap">{t('workflow_actions.forms.field.text.htmlType')}</Box>}
                        control={
                            <Select
                                multiple={false}
                                name="htmlType"
                                onChange={this.handleInputChange}
                                fullWidth
                                value={field.htmlType}
                            >
                                <MenuItem value={'text'}>text</MenuItem>
                                <MenuItem value={'email'}>email</MenuItem>
                            </Select>
                        }
                    />
                </Grid>
                {field.isMultiline && (
                    <Grid item xs={2}>
                        <TextField
                            label={t('workflow_actions.forms.field.text.linesCount')}
                            data-testid="workflow_actions.forms.field.text.linesCount"
                            fullWidth
                            margin="dense"
                            name="linesCount"
                            type="number"
                            value={field.linesCount ?? ''}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                )}
                {this.renderIsBarcodeScaner(field, errors)}
                {field.is_barcode_data && this.renderUserCanTypeManually(field, errors)}
            </>
        );
    }
}

TextForm.propTypes = {
    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(Text).isRequired,
};

export default TextForm;
