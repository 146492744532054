export const STANDARD_FIELDS = {
    DISTANCE: 'com_calculated_distance',
    DISTANCE_QUERY_PLACEHOLDER: '____mapsly_calculated_distance_placeholder____',
    GIS_DISTANCE_QUERY_PLACEHOLDER: '____mapsly_gis_calculated_distance_placeholder____',
    MAPSLY_LINK: 'mapsly_link',
    ID: 'id',
    INTERNAL_ID: 'mapsly_internal_id',
    CREATED_AT: 'mapsly_created_at',
    UPDATED_AT: 'mapsly_updated_at',
    DELETED_AT: 'mapsly_deleted_at',
    TERRITORIES: 'mapsly_territories',
    TERRITORIES_CODES: 'mapsly_territories_codes',
    LAYERS: 'mapsly_layers',
    LAYERS_VERSION: 'mapsly_layers_version',
    SAVED_TERRITORIES: 'mapsly_territories_saved',
    TERRITORIES_CALC_AT: 'mapsly_territories_calc_at',
    PERMISSIONS: 'mapsly_record_permissions',
    PERMISSIONS_CALC_AT: 'mapsly_permissions_calc_at',
    GEO_COUNTRY: 'mapsly_geo_true_country',
    GEO_COUNTRY_SHORT: 'mapsly_geo_true_country_short',
    GEO_STATE: 'mapsly_geo_true_state',
    GEO_STATE_SHORT: 'mapsly_geo_true_state_short',
    GEO_COUNTY: 'mapsly_geo_true_county',
    GEO_COUNTY_SHORT: 'mapsly_geo_true_county_short',
    GEO_CITY: 'mapsly_geo_true_city',
    GEO_DISTRICT: 'mapsly_geo_true_district',
    GEO_ZIP: 'mapsly_geo_true_zip',
    GEO_PROCESSED: 'mapsly_geo_true_processed',
    GEO_STREET: 'mapsly_geo_true_street',
    GEO_HOUSE_NUMBER: 'mapsly_geo_true_house_number',
    RECORD_LAST_FETCH_AT: 'mapsly_last_fetch_at',
    GEOCODER: 'geocoder',
    MAPSLY_CREATED_RECORD: 'mapsly_created_record',
    MAPSLY_META: 'mapsly_meta',
    GEO_LAT: 'mapsly_geo_lat',
    GEO_LNG: 'mapsly_geo_lng',
    GEO_STATUS: 'mapsly_geo_status',
    GEO_RESULT: 'mapsly_geo_result',
    GEO_POINT: 'mapsly_geo_point',
};
