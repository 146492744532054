import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../DataSource/ErrorMessage';
import Alert from '../Alert';
import SSUEntities from '../SelfSignUp/SSUEntities';
import ConnectionForm from './ConnectionForm';
import entityManagerFactory from '../../service/EntityManager';
import dsManagerFactory from '../../service/DsManager';
import GeneralPropertiesForm from './GeneralPropertiesForm';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import Backdrop from '../Backdrop';
import { reverse, routes } from '../../routes';
import SSUFields from '../SelfSignUp/SSUFields';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import UserParams from './UserParams';

const STAGES = {
    CONNECTION: 0,
    GENERAL: 1,
    ENTITIES: 2,
    FIELDS: 3,
    USER_PARAMS: 4,
};

class DataSourceWizard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            stage: this.props.stage || STAGES.CONNECTION,
            loading: this.props.stage === STAGES.ENTITIES,
            warning: false,
            message: null,
        };

        this.connRef = React.createRef();
        this.entitiesRef = React.createRef();
        this.generalRef = React.createRef();
        this.fieldsRef = React.createRef();
        this.userParamsRef = React.createRef();

        this.eventTag = this.constructor.name;
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this.eventTag, (payload) => {
            if (payload.status === 'complete' && this.props.dataSource.id === parseInt(payload.dsId)) {
                this.entitiesRef.current && this.entitiesRef.current.loadEntities();
            }
        });

        this.props.onStep(
            this.state.stage,
            this.constructor.getStageName(this.state.stage, this.props.t),
            this.getActionName(this.state.stage),
            this.state.loading,
        );
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WS_DS_METADATA_IMPORT, this.eventTag);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.stage !== this.state.stage) {
            this.props.onStep(
                this.state.stage,
                this.constructor.getStageName(this.state.stage, this.props.t),
                this.getActionName(this.state.stage),
                this.state.loading,
            );
        } else if (prevState.loading !== this.state.loading) {
            this.props.onLoading(this.state.loading);
        }

        if (prevProps.stage !== this.props.stage && Number.isInteger(this.props.stage)) {
            // если this.props.stage > 0, то должно быть подклчючение к БД (isConnected?)
            this.setState({
                stage: this.props.stage,
            });
        }
    }

    static getStageName(stage, t) {
        switch (stage) {
            case STAGES.CONNECTION:
                return t('data_source_form.data_source_wizard.stages.connection');
            case STAGES.GENERAL:
                return t('data_source_form.data_source_wizard.stages.general');
            case STAGES.ENTITIES:
                return t('data_source_form.data_source_wizard.stages.entities');
            case STAGES.FIELDS:
                return t('data_source_form.data_source_wizard.stages.fields');
            case STAGES.USER_PARAMS:
                return t('data_source_form.data_source_wizard.stages.user_params');
            default:
                return '';
        }
    }

    getActionName(stage) {
        switch (stage) {
            case STAGES.CONNECTION:
                return this.props.dataSource.id
                    ? this.props.t('data_source_form.data_source_wizard.reconnect')
                    : this.props.t('data_source_form.data_source_wizard.connect_to_source');
            default:
                return this.props.t('button.save');
        }
    }

    handleError = (message, details = null) => {
        window.scrollTo(0, 0);
        this.setState({
            loading: false,
            message: {
                type: 'danger',
                message,
                details,
            },
        });
    };

    handleWarning = (message, details = null) => {
        window.scrollTo(0, 0);
        this.setState({
            warning: true,
            loading: false,
            message: {
                type: 'warning',
                message,
                details,
            },
        });
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
        });

        switch (this.state.stage) {
            case STAGES.CONNECTION:
                this.connRef.current.quiet();
                break;
            case STAGES.GENERAL:
                this.generalRef.current.quiet();
                break;
            case STAGES.ENTITIES:
                this.entitiesRef.current.quiet();
                break;
            default:
        }
    };

    handleProcessing = () => {
        this.setState({
            loading: true,
        });
    };

    handleSuccess = () => {
        if (Number.isInteger(this.props.stage) || this.state.stage === STAGES.USER_PARAMS) {
            this.setState({
                warning: false,
                loading: false,
            });

            this.props.onFinished && this.props.onFinished();

            return;
        }

        let nextStage;
        switch (this.state.stage) {
            case STAGES.CONNECTION:
                nextStage = STAGES.ENTITIES;
                break;
            default:
                nextStage = this.state.stage + 1;
        }

        this.setState({
            stage: nextStage,
            warning: false,
            loading: nextStage === STAGES.ENTITIES,
        });
    };

    handleReady = () => {
        this.setState({
            loading: false,
        });
    };

    getStage = () => {
        const entityManager = entityManagerFactory.getManager(
            this.props.dataSource.accountId,
            this.props.dataSource.id,
        );
        switch (this.state.stage) {
            case STAGES.CONNECTION:
                return (
                    <ConnectionForm
                        dsManager={dsManagerFactory.getManager(this.props.dataSource.accountId)}
                        dataSource={this.props.dataSource}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                        onLoading={this.handleProcessing}
                        onReadyStateChanged={this.props.onReadyStateChanged}
                        ref={this.connRef}
                    />
                );
            case STAGES.GENERAL:
                return (
                    <GeneralPropertiesForm
                        dsManager={dsManagerFactory.getManager(this.props.dataSource.accountId)}
                        dataSource={this.props.dataSource}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                        onLoading={this.handleProcessing}
                        ref={this.generalRef}
                    />
                );
            case STAGES.ENTITIES:
                return (
                    <SSUEntities
                        entityManager={entityManager}
                        onReady={this.handleReady}
                        onProcessing={this.handleProcessing}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                        onWarning={this.handleWarning}
                        ref={this.entitiesRef}
                    />
                );
            case STAGES.FIELDS:
                return (
                    <SSUFields
                        ref={this.fieldsRef}
                        entityManager={entityManager}
                        onSuccess={this.handleSuccess}
                        onReady={this.handleReady}
                        onProcessing={this.handleProcessing}
                        onError={this.handleError}
                    />
                );
            case STAGES.USER_PARAMS:
                return (
                    <UserParams
                        ref={this.userParamsRef}
                        dataSource={this.props.dataSource}
                        onSuccess={this.handleSuccess}
                        onError={this.handleError}
                        onLoading={this.handleProcessing}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <div className="c-data-source-wizard">
                {this.state.message !== null && (
                    <Alert canClose onClose={this.handleAlertClose} type={this.state.message.type}>
                        {this.state.message.message}
                        {this.state.message.details !== null && <ErrorMessage details={this.state.message.details} />}
                    </Alert>
                )}
                <div className="step-content" style={{ margin: '0 -24px' }}>
                    <Backdrop loading={this.state.loading}>
                        <div style={{ margin: '0 24px' }}>{this.getStage()}</div>
                    </Backdrop>
                </div>
            </div>
        );
    }

    next = () => {
        this.handleAlertClose();

        switch (this.state.stage) {
            case STAGES.CONNECTION:
                this.connRef.current.submit();
                break;
            case STAGES.GENERAL:
                this.generalRef.current.submit();
                break;
            case STAGES.ENTITIES:
                this.entitiesRef.current.commit(this.state.warning);
                break;
            case STAGES.FIELDS:
                this.fieldsRef.current.commit().then((answer) => {
                    const { dataSource } = this.props;
                    if (answer === 'no') {
                        // go to ds settings page
                        const url =
                            reverse(routes.admin.account.dataSource.index, {
                                accountId: dataSource.accountId,
                                dataSourceId: dataSource.id,
                            }) + '?ssu';
                        this.props.history.push(url);

                        return;
                    }

                    const ds = {
                        id: dataSource.id,
                        isImportEnabled: true,
                    };
                    dsManagerFactory.getManager(dataSource.accountId).save(ds, false);

                    this.handleSuccess();
                });
                break;
            case STAGES.USER_PARAMS:
                this.userParamsRef.current.submit();
                break;
            default:
                this.handleSuccess();
        }
    };
}

DataSourceWizard.propTypes = {
    dataSource: PropTypes.object.isRequired,
    stage: PropTypes.number,
    onStep: PropTypes.func,
    onLoading: PropTypes.func,
    onFinished: PropTypes.func,
    onReadyStateChanged: PropTypes.func,
};

DataSourceWizard.defaultProps = {
    stage: STAGES.CONNECTION,
};

const withRouterAndRef = (WrappedComponent) => {
    class InnerComponentWithRef extends React.Component {
        render() {
            const { forwardRef, ...rest } = this.props;
            return <WrappedComponent {...rest} ref={forwardRef} />;
        }
    }
    const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });
    return React.forwardRef((props, ref) => {
        return <ComponentWithRouter {...props} forwardRef={ref} />;
    });
};

export default withTranslation('translations', { withRef: true })(withRouterAndRef(DataSourceWizard));
export { STAGES };
