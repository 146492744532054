import { withTranslation, WithTranslation } from 'react-i18next';
import React from 'react';
import { WithSnackbarProps } from 'notistack';
import Grid from '@material-ui/core/Grid';
import { Subscription } from '../../../interfaces/account';

interface AnalyticsUsagePanelProps extends WithTranslation, WithSnackbarProps {
    subscription: Subscription;
}

class AnalyticsUsagePanel extends React.Component<AnalyticsUsagePanelProps> {
    render() {
        const { t } = this.props;

        const analyticsCreateModifyUserCount = this.props.subscription.analyticsCreateModifyUserCount;
        const analyticsViewUserCount = this.props.subscription.analyticsViewUserCount;

        return (
            <div className="analytics-usage-panel">
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span> {t('billing.subscription_panel.analytics_create_modify_usage.title')} </span>
                    </Grid>
                    <Grid item xs>
                        <div className="title">{analyticsCreateModifyUserCount}</div>
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span> {t('billing.subscription_panel.analytics_view_usage.title')} </span>
                    </Grid>
                    <Grid item xs>
                        <div className="title">{analyticsViewUserCount}</div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(AnalyticsUsagePanel);
