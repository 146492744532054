import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import AdapterSettingsGroup from 'components/DataSourceForm/AdapterSettingsGroup';
import FormDialog from 'components/FormDialog';
import { credentialsDialogManager, ssoAuthProviderStorage } from 'service/Login';

interface Props extends WithTranslation {
    onLoginVia: (adapterId: string, credentials: AdapterCredentialsForm) => void;
}

type AdapterCredentialsForm = {
    [key: string]: string;
};

const CredentialsDialog: React.FC<Props> = (props) => {
    const { t, onLoginVia } = props;
    const { adapter, isOpen } = credentialsDialogManager;

    const handleSaveCredentials = (credentials: AdapterCredentialsForm) => {
        onLoginVia(adapter!.id, credentials);
        credentialsDialogManager.closeModal();
    };

    const handleCloseCredentials = () => {
        credentialsDialogManager.closeModal();
    };

    if (!isOpen || !adapter) {
        return null;
    }

    const credentialValues = ssoAuthProviderStorage.getCredentials(adapter.id) ?? {};

    return (
        <FormDialog
            title={t('data_source_form.connection_form.connection_parameters')}
            onSave={handleSaveCredentials}
            onCancel={handleCloseCredentials}
        >
            <AdapterSettingsGroup key={adapter.id} settings={adapter.credentials} values={credentialValues} />
        </FormDialog>
    );
};

export default withTranslation()(observer(CredentialsDialog));
