import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';

export default class Html extends AbstractField {
    constructor() {
        super(FIELD_TYPES.HTML);
    }

    validate() {
        return new Map();
    }
}
