import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import timezones, { stringifyTimezone } from 'references/timezones';
import countryManager from '../../service/CountryManager';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { subscriptionPlanManager } from '../../service/SubscriptionPlanManager';
import { withTranslation } from 'react-i18next';
import { LANGUAGES } from '../../locales/i18n';
import { Autocomplete } from '@material-ui/lab';
import { userManager } from '../../service/UserManager';
import Hint from '../Hint';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import PasswordField from 'components/PasswordField';
import { splitOnce } from 'utils';
import passwordValidationService from 'service/PasswordValidationService';

const PasswordMeter = React.lazy(() => import('components/PasswordMeter'));

const mapDetails = (details) => {
    const mapped = {};
    if (!details) {
        return mapped;
    }

    Object.keys(details).forEach((key) => {
        const [message, hint] = splitOnce(details[key], '\n');

        mapped[key] = hint ? (
            <span>
                {message} <Hint>{hint}</Hint>
            </span>
        ) : (
            message
        );
    });

    return mapped;
};

class SSUInitialSetup extends React.Component {
    constructor(props) {
        super(props);

        const userData = props.manager.getUserData();
        const userDataInitialStateSetup = props.manager.getUserDataStateInitialSetup();

        const companyData = this.props.manager.getCompanyData();
        const account = this.props.manager.getAccountStateInitialSetup() || {
            name: companyData.name || null,
            address: companyData.address || null,
            phone: companyData.phone || null,
            timezone: companyData.timezone || null,
            countryCode: companyData.country || null,
            language: companyData.language || null,
            plan: null,
            provider: null,
        };

        const user = {
            name: userData.name || userDataInitialStateSetup?.name || null,
            email: userData.email || userDataInitialStateSetup?.email || null,
        };

        if (!this.props.manager.isSsoUser()) {
            user.password = null;
            user.passwordReentry = null;
        }

        this.state = {
            accountErrors: {},
            userErrors: {},
            account: account,
            user: user,
            subscriptionPlans: null,
            isTermsAccepted: true,
            loadingPlans: false,
            defaultCountry: null,
        };

        this.isCustomValuesProvided = {
            timezone: false,
            countryCode: false,
        };

        this.userValidationRules = {
            name: {
                required: true,
            },
            email: {
                regexp: /^[^@]+@[^@]+\.[^@]+$/,
                messageKey: 'signup.initial.validation.email',
                required: true,
            },
            password: {
                validator: async (password) => await passwordValidationService.getPasswordValidationMessage(password),
                required: true,
            },
            passwordReentry: {
                required: true,
            },
        };
        this.accountValidationRules = {
            name: {
                required: true,
            },
            plan: {
                required: true,
            },
            language: {
                required: true,
            },
        };
    }

    componentDidMount() {
        const { onProcessing, manager, t } = this.props;
        onProcessing && onProcessing(t('signup.initial.form_preparation'));

        countryManager.list().then(() => {
            const account = { ...this.state.account };
            account['provider'] = this.props.manager.getProvider();
            const initSubscriptionPlans = (account) => {
                this.loadingPlansByCountry(account).then(() => {
                    this.setState({
                        account,
                    });
                    if (userManager.getCurrentUser() === null) {
                        this.props.onReady && this.props.onReady(true);
                    }
                });
            };

            // todo loading indicator
            // todo fix React warnings
            manager
                .getIpInfo()
                .then(({ timezone, country }) => {
                    if (!account['timezone'] && !this.isCustomValuesProvided['timezone']) {
                        account['timezone'] = timezone;
                    }
                    if (!account['countryCode'] && !this.isCustomValuesProvided['countryCode']) {
                        account['countryCode'] = country;
                    }
                    this.setState({
                        defaultCountry: country,
                    });
                })
                .finally(() => {
                    initSubscriptionPlans(account);
                });
        });
    }

    loadingPlansByCountry = (account) => {
        let currentCountry = this.getCurrentCountry(account);

        if (!currentCountry) {
            currentCountry = 'US';
        }

        this.setState({
            loadingPlans: true,
        });

        return subscriptionPlanManager.listPlanByCountry(currentCountry.alpha2).then((subscriptionPlans) => {
            const plan = this.props.manager.getPlan();
            if (plan !== null && !account['plan']) {
                for (let item of subscriptionPlans) {
                    if (item.tier === plan) {
                        account['plan'] = item.id;
                        account['planTier'] = item.tier;
                        break;
                    }
                }
            }

            if (account['planTier'] !== null) {
                for (let item of subscriptionPlans) {
                    if (item.tier === account['planTier']) {
                        account['plan'] = item.id;
                        break;
                    }
                }
            }

            this.setState({
                subscriptionPlans,
                account,
                loadingPlans: false,
            });
            return Promise.resolve();
        });
    };

    quiet() {
        this.setState({
            accountErrors: {},
            userErrors: {},
        });
    }

    async commit() {
        const { account, user } = this.state;
        const { manager, onProcessing, stopProcessing, onValid, t } = this.props;

        const accountErrors = {};
        const userErrors = {};
        await Promise.all(
            Object.keys(account).map(async (key) => {
                const error = await this.validateValue(key, account[key], this.accountValidationRules);
                if (error) {
                    accountErrors[key] = error;
                }
            }),
        );
        await Promise.all(
            Object.keys(user).map(async (key) => {
                if (key === 'password' && manager.isSsoUser()) {
                    delete user[key];
                    return;
                }
                const error = await this.validateValue(key, user[key], this.userValidationRules);
                if (error) {
                    userErrors[key] = error;
                }
            }),
        );

        if (user.password !== user.passwordReentry) {
            userErrors['passwordReentry'] = t('signup.initial.validation.password_reentry');
        }

        if (Object.keys(accountErrors).length > 0 || Object.keys(userErrors).length > 0) {
            this.setState({
                accountErrors,
                userErrors,
            });
            onValid && onValid(false);
            return;
        } else {
            onValid && onValid(true);
        }

        onProcessing && onProcessing(t('signup.initial.form_saving'));
        manager.clearStateForObject(manager.keyForAccountState);
        manager.clearStateForObject(manager.keyForUserState);

        return manager
            .saveInitialData(account, user)
            .catch((error) => {
                this.setState({
                    accountErrors: mapDetails(error.errors['account']),
                    userErrors: mapDetails(error.errors['user']),
                });

                onValid && onValid(false);
                stopProcessing();
                throw error;
            })
            .then(() => {
                // Сервер должен установить refresh-token cookie. Делаем запрос access-токена.
                return userManager._backend.refreshAccessToken();
            })
            .then(() => {
                userManager.hey();
            })
            .then(() => {
                this.props.onSuccess();
            });
    }

    handleAccountDataChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'timezone' || name === 'countryCode') {
            this.isCustomValuesProvided[name] = true;
        }

        const account = { ...this.state.account };
        account[name] = value;

        if (name === 'countryCode') {
            this.loadingPlansByCountry(account);
        }

        if (name === 'plan') {
            for (let item of this.state.subscriptionPlans) {
                if (item.id === value) {
                    account['planTier'] = item.tier;
                    break;
                }
            }
        }

        this.setError('accountErrors', name, null);
        this.setState({
            account,
        });

        this.props.manager.saveAccountStateInitialSetup(account);

        if (name === 'language') {
            dispatcher.dispatch(events.EVENT_CHANGE_LANGUAGE, value);
        }
    };

    handleUserDataChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const user = { ...this.state.user };
        user[name] = value;

        this.setError('userErrors', name, null);
        this.setState({
            user,
        });

        this.props.manager.saveUserDataStateInitialSetup(user);
    };

    handleAccountInputBlur = async (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const error = await this.validateValue(name, value, this.accountValidationRules);
        this.setError('accountErrors', name, error);
    };

    handleUserInputBlur = async (event) => {
        const { t } = this.props;
        const value = event.target.value;
        const name = event.target.name;

        let error = null;
        if (name === 'passwordReentry') {
            if (this.state.user['password'] !== value) {
                error = t('signup.initial.validation.password_reentry');
            }
        } else {
            error = await this.validateValue(name, value, this.userValidationRules);
        }
        this.setError('userErrors', name, error);
    };

    setError(key, name, error) {
        error = error || undefined;
        if (this.state[key] === error) {
            return;
        }
        const errors = { ...this.state[key] };
        if (error) {
            errors[name] = error;
        } else {
            delete errors[name];
        }

        if (Object.keys(errors).length > 0) {
            this.props.onValid && this.props.onValid(false);
        }

        this.setState(
            {
                [key]: errors,
            },
            () => {
                if (!Object.keys(this.state.accountErrors).length && !Object.keys(this.state.userErrors).length) {
                    this.props.onValid && this.props.onValid(true);
                }
            },
        );
    }

    async validateValue(name, value, validationRules) {
        const { i18n, t } = this.props;
        if (!validationRules.hasOwnProperty(name)) {
            return;
        }

        let messageKey = false;
        const validationRuleMessage = validationRules[name].messageKey ?? false;
        if (validationRules[name].required && !value) {
            messageKey = validationRuleMessage || 'signup.initial.validation.required';
        } else if (validationRules[name].regexp && (!value || !value.match(validationRules[name].regexp))) {
            messageKey = validationRuleMessage || 'signup.initial.validation.invalid';
        } else if (validationRules[name].length?.min && value.length < validationRules[name].length?.min) {
            messageKey = validationRuleMessage || 'signup.initial.validation.invalid';
        } else if (validationRules[name].length?.max && value.length > validationRules[name].length?.max) {
            messageKey = validationRuleMessage || 'signup.initial.validation.invalid';
        } else if (typeof validationRules[name].validator === 'function') {
            messageKey = await validationRules[name].validator(value);
        }

        if (!messageKey) {
            return null;
        }

        const hintKey = messageKey + '_hint';
        return i18n.exists(hintKey) ? (
            <span>
                {t(messageKey)} <Hint>{t(hintKey)}</Hint>
            </span>
        ) : (
            t(messageKey)
        );
    }

    acceptTerms = (event) => {
        const isTermsAccepted = event.target.checked;
        this.setState({
            isTermsAccepted,
        });
        this.props.onReady && this.props.onReady(isTermsAccepted);
    };

    getCurrentCountry = (account) => {
        return countryManager.getCountries().find((country) => account['countryCode'] === country.alpha2);
    };

    render() {
        if (countryManager.getCountries() === null) {
            return null;
        }
        const { account, user, accountErrors, userErrors, loadingPlans, subscriptionPlans, defaultCountry } =
            this.state;
        const { t } = this.props;

        const accountProps = [
            {
                name: 'name',
                label: t('signup.initial.account.name') + ' *',
            },
            {
                name: 'address',
                label: t('signup.initial.account.address'),
            },
            {
                name: 'phone',
                label: t('signup.initial.account.phone'),
            },
        ];
        const userProps = [
            {
                name: 'email',
                label: t('signup.initial.user.email') + ' *',
                autoComplete: 'email',
            },
            {
                name: 'name',
                label: t('signup.initial.user.name') + ' *',
                autoComplete: 'name',
            },
        ];

        const currentTimezone = timezones.find((timezone) => account['timezone'] === timezone?.name);
        const currentCountry = this.getCurrentCountry(account);

        const userInputs = { email: user.email || '', name: user.name || '' };

        return (
            <div>
                {accountProps.map(({ name, label }) => (
                    <TextField
                        autoFocus={name === 'name'}
                        key={name}
                        label={label}
                        name={name}
                        fullWidth
                        margin="dense"
                        value={account[name] || ''}
                        helperText={accountErrors[name] || ''}
                        error={accountErrors.hasOwnProperty(name)}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleAccountDataChange}
                        onBlur={this.handleAccountInputBlur}
                    />
                ))}
                <FormControl fullWidth margin="dense" error={accountErrors.hasOwnProperty('timezone')}>
                    <InputLabel shrink>{t('signup.initial.timezone') + ' *'}</InputLabel>
                    <Autocomplete
                        options={timezones}
                        value={currentTimezone || null}
                        getOptionLabel={(timezone) => stringifyTimezone(timezone)}
                        onChange={(event, value) => {
                            this.handleAccountDataChange({ target: { name: 'timezone', value: value?.name } });
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                data-testid="signup.initial.timezone"
                                placeholder={t('undefined_picklist_option')}
                                margin="normal"
                            />
                        )}
                    />
                    {accountErrors['timezone'] && <FormHelperText>{accountErrors['timezone']}</FormHelperText>}
                </FormControl>
                <div style={{ position: 'relative' }}>
                    <FormControl fullWidth margin="dense" error={accountErrors.hasOwnProperty('countryCode')}>
                        <InputLabel shrink>{t('signup.initial.country') + ' *'}</InputLabel>
                        <Autocomplete
                            options={countryManager.getCountries()}
                            value={currentCountry || null}
                            getOptionLabel={(country) => country.name}
                            onChange={(event, value) => {
                                this.handleAccountDataChange({ target: { name: 'countryCode', value: value.alpha2 } });
                            }}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-testid="signup.initial.country"
                                    placeholder={t('user_form.form.country.placeholder')}
                                    margin="normal"
                                />
                            )}
                        />
                        {accountErrors['countryCode'] && (
                            <FormHelperText>{accountErrors['countryCode']}</FormHelperText>
                        )}
                    </FormControl>
                    <div style={{ position: 'absolute', right: -25, top: 28 }}>
                        <Hint type="tooltip">{t('signup.initial.country_hint')}</Hint>
                    </div>
                    {currentCountry && currentCountry.alpha2 !== defaultCountry && (
                        <span className="plan-text">
                            {t('signup.initial.country_need_payment_method', { countryName: currentCountry.name })}
                            <Hint type="tooltip">{t('signup.initial.country_need_payment_method_hint')}</Hint>
                        </span>
                    )}
                </div>
                <FormControl fullWidth margin="dense" error={accountErrors.hasOwnProperty('language')}>
                    <InputLabel shrink>{t('signup.initial.language') + ' *'}</InputLabel>
                    <Autocomplete
                        options={LANGUAGES}
                        value={account['language'] || null}
                        disableClearable={true}
                        getOptionLabel={(option) => this.props.t('user_form.form.language.' + option)}
                        onChange={(event, value) => {
                            this.handleAccountDataChange({ target: { name: 'language', value } });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                data-testid="signup.initial.language"
                                placeholder={t('undefined_picklist_option')}
                                margin="normal"
                            />
                        )}
                    />
                    {accountErrors['language'] && <FormHelperText>{accountErrors['language']}</FormHelperText>}
                </FormControl>
                <div>
                    <FormControl fullWidth margin="dense" error={accountErrors.hasOwnProperty('plan')}>
                        <InputLabel>{t('signup.initial.plan') + ' *'}</InputLabel>
                        <Select
                            name="plan"
                            displayEmpty
                            value={account['plan'] || ''}
                            onChange={this.handleAccountDataChange}
                            disabled={loadingPlans}
                            data-testid="signup.initial.plan"
                        >
                            {subscriptionPlans &&
                                subscriptionPlans.map((plan) => (
                                    <MenuItem key={plan.id} value={plan.id}>
                                        {plan.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        {accountErrors['countryCode'] && (
                            <FormHelperText>{accountErrors['countryCode']}</FormHelperText>
                        )}
                    </FormControl>
                    <span className="plan-text">
                        {t('signup.initial.plan_hint')}
                        <a href="https://mapsly.com/pricing/" target="_blank" rel="noreferrer noopener">
                            {t('signup.initial.plans')}
                        </a>
                        <i className="fas fa-external-link" />
                    </span>
                    <span className="plan-text">{t('signup.initial.trial_warning')}</span>
                </div>
                {userProps.map(({ name, label, autoComplete }) => (
                    <TextField
                        key={name}
                        label={label}
                        name={name}
                        fullWidth
                        margin="dense"
                        autoComplete={autoComplete}
                        value={user[name] || ''}
                        helperText={userErrors[name] || ''}
                        error={userErrors.hasOwnProperty(name)}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleUserDataChange}
                        onBlur={this.handleUserInputBlur}
                    />
                ))}

                {!this.props.manager.isSsoUser() && (
                    <Grid container>
                        <Grid item sm={6} style={{ paddingRight: 16 }}>
                            <PasswordField
                                label={t('signup.initial.password') + ' *'}
                                data-testid="signup.initial.password"
                                fullWidth
                                margin="dense"
                                name="password"
                                autoComplete="new-password"
                                value={user['password'] || ''}
                                helperText={userErrors['password'] || ''}
                                error={userErrors.hasOwnProperty('password')}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.handleUserDataChange}
                                onBlur={this.handleUserInputBlur}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PasswordField
                                label={t('signup.initial.password_reentry') + ' *'}
                                data-testid="signup.initial.password_reentry"
                                fullWidth
                                margin="dense"
                                name="passwordReentry"
                                autoComplete="confirm-password"
                                value={user['passwordReentry'] || ''}
                                helperText={userErrors['passwordReentry'] || ''}
                                error={userErrors.hasOwnProperty('passwordReentry')}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.handleUserDataChange}
                                onBlur={this.handleUserInputBlur}
                            />
                        </Grid>
                    </Grid>
                )}
                {!this.props.manager.isSsoUser() && user.password?.length > 0 && (
                    <Suspense fallback={'Loading...'}>
                        <PasswordMeter password={user.password || ''} userInputs={userInputs} />
                    </Suspense>
                )}
                <FormControlLabel
                    control={
                        <Checkbox checked={this.state.isTermsAccepted} onChange={this.acceptTerms} color="primary" />
                    }
                    label={<span dangerouslySetInnerHTML={{ __html: t('signup.initial.accept_terms') }} />}
                />
            </div>
        );
    }
}

SSUInitialSetup.propTypes = {
    manager: PropTypes.object.isRequired,
    onProcessing: PropTypes.func,
    stopProcessing: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func,
    plan: PropTypes.string,
};

export default withTranslation('translations', { withRef: true })(SSUInitialSetup);
