export enum LassoMode {
    OFF = 'off',
    PLUS = 'plus',
    MINUS = 'minus',
}

export enum LassoActiveType {
    NONE = 'none',
    CALENDAR = 'calendar',
    ROUTE = 'route',
}
