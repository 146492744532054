import React from 'react';
import './style.css';
import PropTypes from 'prop-types';
import {
    FormHelperText,
    Icon,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Button,
    FormControl,
    Tooltip,
} from '@material-ui/core';
import {
    doesValueMatchType,
    filterStringValueToMatchType,
    formatWithCommas,
    isSimpleField,
    propertyNameToId,
} from '../../utils';
import FieldFactory, { defaultValue } from './FieldFactory';
import { withTranslation } from 'react-i18next';
import { userManager } from '../../service/UserManager';
import RestrictionMessage from '../RestrictionMessage';
import { FormActions, FormAlert } from '../PureFormDialog/Form';
import { FieldLookupType } from 'components/types';
import SelectFieldControl from 'components/LiveUpdateTableForm/SelectFieldControl';
import { FieldType } from '../types';
import CircularProgress from '@material-ui/core/CircularProgress';

export const EDIT_SELECTED = 'selected';
export const EDIT_ALL = 'all';

class LiveUpdateTableViewForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            errors: new Map(),
            selectedFields: [],
            updates: {},
            unselectedFields: this.props.fields,
            loadingFieldSet: new Set(),
        };

        this.updateCounterTimeout = null;
    }

    componentDidMount() {
        this.recalculateAvailableRecords();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.updateCounters && !prevProps.updateCounters && !this.updateCounterTimeout) {
            this.updateCounterTimeout = setTimeout(() => {
                this.recalculateAvailableRecords();
                this.updateCounterTimeout = null;
            }, 10 * 1000);
        }

        if (prevState.selectedFields !== this.state.selectedFields) {
            this.recalculateAvailableRecords();
        }
    }

    componentWillUnmount() {
        if (this.updateCounterTimeout) {
            clearTimeout(this.updateCounterTimeout);
            this.updateCounterTimeout = null;
        }
    }

    handleSave = () => {
        this.setState({
            message: null,
            errors: new Map(),
        });

        const { t, onSaveRequest } = this.props;
        const { selectedFields } = this.state;

        if (!selectedFields.length) {
            this.setState({
                message: {
                    type: 'danger',
                    text: t('live_update_table_form.errors.add_fields'),
                },
            });
            return;
        }
        const wrongFields = [];
        for (let field of selectedFields) {
            // Не проверяем тип данных для lookup поля
            if (!isSimpleField(field)) {
                continue;
            }
            if (!Array.isArray(field.picklist) && field.value !== '' && !doesValueMatchType(field.value, field.type)) {
                wrongFields.push(field);
            }
        }
        if (wrongFields.length) {
            const text = wrongFields
                .map((wrongField) =>
                    t('map_table.live_update_table_form.validation.type', {
                        field: wrongField.label,
                        type: wrongField.type,
                    }),
                )
                .join('; ');

            this.setState({
                message: {
                    type: 'danger',
                    text: text,
                },
            });
            return;
        }

        const request = this.buildRequest(selectedFields);

        onSaveRequest(request, this.props.editType).catch((error) => {
            this.setState({
                message: {
                    type: 'danger',
                    text: 'Unable to save records',
                },
                errors: error.details || new Map(),
            });
        });
    };

    recalculateAvailableRecords = () => {
        this.props.recalculateAvailable(this.buildRequest(this.state.selectedFields));
    };

    buildRequest(selectedFields = null) {
        if (null === selectedFields) {
            selectedFields = this.state.selectedFields;
        }

        return selectedFields.reduce(
            (result, field) => {
                const isObject = typeof field.value === 'object' && field.value !== null && !Array.isArray(field.value);
                const isFiles = Array.isArray(field.value) && field.type === FieldType.FILES;

                let value;
                if (Array.isArray(field.picklist)) {
                    value = field.value;
                } else if (isObject) {
                    if (field.lookupData.type === FieldLookupType.POLYMORPHIC) {
                        value = JSON.stringify({
                            object: field.value.apiName,
                            recordId: field.value.id,
                        });
                    } else {
                        value = field.value.id ? field.value.id : field.value;
                    }
                } else if (isFiles) {
                    value = field.value.filter((file) => file.id).map((file) => file.id);
                } else {
                    value = filterStringValueToMatchType(field.value, field.type);
                }

                result.push({
                    apiName: field.apiName,
                    originalApiName: field.originalApiName,
                    value,
                    textValue: isObject ? field.value.textValue : null,
                });

                return result;
            },
            Array.from(Object.values(this.state.updates)),
        );
    }

    handleInputChange = (index) => (updates) => {
        const field = this.state.selectedFields[index];
        let value = updates[field.apiName];
        const nextUpdates = { ...updates };
        delete nextUpdates[field.apiName];

        if (field.lookupData?.type === FieldLookupType.FEW) {
            const idApiName = propertyNameToId(field.apiName);
            const idValue = nextUpdates[idApiName];
            if (idValue) {
                value = idValue;
                delete nextUpdates[idApiName];
            }
        }

        this.setState((state) => {
            const { selectedFields } = state;
            selectedFields[index] = { ...selectedFields[index], value };
            return { selectedFields, updates: nextUpdates };
        });
    };

    handleDeleteField = (event) => {
        const index = parseInt(event.currentTarget.dataset.fieldIndex);
        this.setState((state) => {
            const { selectedFields } = state;
            const newSelectedFields = selectedFields.filter((f, i) => i !== index);
            return {
                selectedFields: newSelectedFields,
                unselectedFields: this.calculateUnselectedFields(newSelectedFields),
            };
        });
    };

    calculateUnselectedFields = (selectedFields) => {
        return this.props.fields.filter((f) => {
            for (let selectedField of selectedFields) {
                if (selectedField.apiName === f.apiName) {
                    return false;
                }
                if (selectedField.originalApiName !== null && selectedField.originalApiName === f.originalApiName) {
                    return false;
                }
            }
            return true;
        });
    };

    handleClick = (event, field) => {
        if (!field) {
            return;
        }
        this.setState((state) => {
            const { selectedFields } = state;
            const newSelectedFields = [
                ...selectedFields,
                { ...field, value: defaultValue(field.type, field.picklist, field.lookupData) },
            ];

            return {
                selectedFields: newSelectedFields,
                unselectedFields: this.calculateUnselectedFields(newSelectedFields),
            };
        });
    };

    handleChangeEditType = (event) => {
        this.props.onChangeEditRecordsType(event.target.value);
    };

    handleAlertClose = () => {
        this.setState({
            message: null,
            errors: new Map(),
        });
    };

    handleFieldLoading = (field, loading) => {
        this.setState((state) => {
            const loadingFieldSet = new Set(state.loadingFieldSet);
            if (loading) {
                loadingFieldSet.add(field.apiName);
            } else {
                loadingFieldSet.delete(field.apiName);
            }
            return { loadingFieldSet };
        });
    };

    render() {
        const { selectedFields, unselectedFields, loadingFieldSet } = this.state;
        const {
            t,
            selectedCount,
            allCount,
            updateCountSelected,
            updateCountAll,
            processingPermissions,
            editType,
            saving,
        } = this.props;

        const countSelected = updateCountSelected !== null ? updateCountSelected : selectedCount;
        const tooltipSelected =
            updateCountSelected !== null && updateCountSelected !== selectedCount
                ? t('live_update_table_form.tooltip_selected', { updateCountSelected, selectedCount })
                : null;
        const countAll = updateCountAll !== null ? updateCountAll : allCount;
        const tooltipAll =
            updateCountAll !== null && updateCountAll !== allCount
                ? t('live_update_table_form.tooltip_all', { updateCountAll, allCount })
                : null;

        const isSavingRestricted = userManager.hasEssentialRestrictions();

        return (
            <div className="c-live-update-form">
                {isSavingRestricted && (
                    <FormAlert type="warning">
                        <RestrictionMessage text={t('live_update_table_form.locked_message')} />
                    </FormAlert>
                )}
                {this.state.message !== null && (
                    <FormAlert onClose={this.handleAlertClose} type={this.state.message.type}>
                        {this.state.message.text}
                    </FormAlert>
                )}
                {this.props.processingPermissionsError !== null && (
                    <FormAlert onClose={this.props.closeProcessingPermissionsError}>
                        {this.props.processingPermissionsError}
                    </FormAlert>
                )}
                <p>{t('live_update_table_form.pick_fields')}</p>
                <div>
                    <SelectFieldControl
                        fields={unselectedFields}
                        onChange={this.handleClick}
                        disabled={processingPermissions}
                    />
                </div>
                {selectedFields.map((field, index) => {
                    const error = this.state.errors.get(field.apiName) || null;
                    return (
                        <div key={field.apiName}>
                            <FormControl className="c-live-update-form__field">
                                <div className="c-live-update-form__field__input">
                                    <FieldFactory
                                        accountId={this.props.accountId}
                                        dataSourceId={this.props.dataSourceId}
                                        onChange={this.handleInputChange(index)}
                                        error={error}
                                        value={field.value}
                                        field={field}
                                        onFieldLoading={(loading) => this.handleFieldLoading(field, loading)}
                                    />
                                </div>
                                <IconButton
                                    color="default"
                                    onClick={this.handleDeleteField}
                                    data-field-index={index}
                                    data-testid="live_update_table_form.form.delete_field"
                                >
                                    <Icon>close_icon</Icon>
                                </IconButton>
                            </FormControl>
                            {!!error ? <FormHelperText>{this.state.errors.get(field.apiName)}</FormHelperText> : null}
                        </div>
                    );
                })}
                <FormControl fullWidth margin="dense">
                    <p>
                        <strong>{this.props.t('map_table.live_update_table_form.apply_to')}</strong>
                    </p>
                    <RadioGroup name="editType" value={editType} onChange={this.handleChangeEditType} row>
                        <FormControlLabel
                            value={EDIT_SELECTED}
                            control={
                                <Tooltip title={tooltipSelected || ''}>
                                    <Radio color="primary" />
                                </Tooltip>
                            }
                            disabled={processingPermissions || selectedCount === 0}
                            label={this.props.t('map_table.live_update_table_form.selected_records_only', {
                                count: countSelected,
                                countFormat: formatWithCommas(countSelected),
                            })}
                        />
                        <FormControlLabel
                            value={EDIT_ALL}
                            control={
                                <Tooltip title={tooltipAll || ''}>
                                    <Radio color="primary" />
                                </Tooltip>
                            }
                            disabled={processingPermissions}
                            label={this.props.t('map_table.live_update_table_form.records_in_table', {
                                count: countAll,
                                countFormat: formatWithCommas(countAll),
                            })}
                        />
                    </RadioGroup>
                </FormControl>

                <FormActions>
                    <Button
                        color="primary"
                        onClick={this.handleSave}
                        disabled={
                            saving ||
                            isSavingRestricted ||
                            loadingFieldSet.size > 0 ||
                            (this.props.editType === EDIT_SELECTED && this.props.selectedCount === 0) ||
                            (this.props.editType === EDIT_ALL && this.props.updateCountAll === 0) ||
                            this.props.editType === null
                        }
                        data-testid="map_table.live_update_table_form.button.save"
                    >
                        {saving ? <CircularProgress size={14} /> : t('button.save')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

LiveUpdateTableViewForm.propTypes = {
    fields: PropTypes.array,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onSaveRequest: PropTypes.func,
    accountId: PropTypes.number,
    dataSourceId: PropTypes.number,
    selectedCount: PropTypes.number,
    allCount: PropTypes.number,
    editType: PropTypes.string.isRequired,
    processingPermissions: PropTypes.bool,
    updateCountSelected: PropTypes.number,
    updateCountAll: PropTypes.number,
    processingPermissionsError: PropTypes.string,
    closeProcessingPermissionsError: PropTypes.func,
    onChangeEditRecordsType: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    updateCounters: PropTypes.bool.isRequired,
};

LiveUpdateTableViewForm.defaultProps = {
    saving: false,
    updateCounters: false,
};

export default withTranslation('translations', { withRef: true })(LiveUpdateTableViewForm);
