import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { FormActions } from '../PureFormDialog/Form';
import { withTranslation } from 'react-i18next';
import { isSafeApiName } from '../../utils';

class UrlParamsForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            url: '',
        };
    }

    handleSave = () => {
        const { value } = this.state;

        this.props.onSave([`{{url.${value}}}`]);
    };

    handleChange = (e) => {
        const { value } = e.target;
        if (!isSafeApiName(value)) {
            return;
        }
        const { protocol, hostname } = window.location;
        this.setState({
            value,
            url: value === '' ? '' : `${protocol}//${hostname}/?${value}=yourValue`,
        });
    };

    handleKeyPress = (e) => {
        if (e.charCode === 13 && this.state.value.length) {
            this.handleSave();
        }
    };

    render() {
        const { t } = this.props;
        const { value } = this.state;

        return (
            <div>
                <div className="url-parameter">
                    <TextField
                        label={t('expresion_builder.url_parameter_name')}
                        data-testid="expresion_builder.url_parameter_name_1"
                        fullWidth
                        margin="dense"
                        value={value}
                        InputProps={{ disableUnderline: false }}
                        autoFocus
                        type="text"
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        className="url-parameter__input"
                    />
                </div>
                <br />
                {t('expresion_builder.url_parameter_help')}
                <TextField
                    data-testid="expresion_builder.url_parameter_name_2"
                    fullWidth
                    margin="dense"
                    value={this.state.url}
                    InputProps={{ disableUnderline: false, readOnly: true }}
                    type="text"
                    disabled={value === ''}
                />
                {t('expresion_builder.url_parameter_help_2')}

                <FormActions>
                    <Button
                        disabled={value === ''}
                        color="primary"
                        onClick={this.handleSave}
                        data-testid="expresion_builder.insert_url_parameter"
                    >
                        {t('expresion_builder.insert_url_parameter')}
                    </Button>
                </FormActions>
            </div>
        );
    }
}

UrlParamsForm.propTypes = {
    value: PropTypes.string,
    onSave: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(UrlParamsForm);
