import React from 'react';
import { MenuItem, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import AbstractField from './AbstractField';
import PickList from '../../../service/workflow_actions/forms/fields/PickList';
import i18n from '../../../locales/i18n';
import FormControlMultipleSelect from '../../WorkflowActions/AbstractForm/FormControlMultipleSelect';
import FormControlSelect from '../../WorkflowActions/AbstractForm/FormControlSelect';
import { entityManager } from '../../../service/SimpleEntityManager';
import CircularProgress from '@material-ui/core/CircularProgress';

const t = i18n.t.bind(i18n);

class PicklistField extends AbstractField {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: false,
        };
    }

    componentDidMount() {
        if (this.props.field.itemsEntityId && this.props.field.itemsFieldId) {
            this.setState({ loading: true });
            entityManager
                .get(this.props.field.itemsEntityId)
                .then((entity) => {
                    const field = entity.fields.find((field) => field.id === this.props.field.itemsFieldId);
                    if (field) {
                        this.props.field.setItems(field.picklist || []);
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    }

    handleChange(e) {
        super.handleChange(e);
        this.handleHandler(e);
    }

    handleChangeClear = () => {
        const e = {
            target: {
                value: '',
            },
        };
        super.handleChange(e);
        this.handleHandler(e);
    };

    processItems(field) {
        if (field.getIsAlphabeticallySorted()) {
            return field
                .getItems()
                .sort((a, b) => (a.label !== undefined && b.label !== undefined ? a.label.localeCompare(b.label) : 0));
        } else {
            return field.getItems();
        }
    }

    renderInput(field, errors) {
        if (field.getIsMultiple()) {
            return (
                <FormControlMultipleSelect
                    className="field-picklist-input"
                    value={field.getValue() ?? []}
                    onChange={this.handleChange}
                    onBlur={this.handleValidate}
                    error={errors.size > 0}
                    helperText={errors.get('value') ? t(errors.get('value')) : ''}
                    fullWidth
                    variant="outlined"
                    disavled={field.getIsDisabled()}
                    autoFocus={this.props.autoFocus}
                    renderValue={this.renderInputValue}
                    endAdornment={this.renderEndAdornment(field)}
                    wrapItems
                >
                    {this.processItems(field).map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </FormControlMultipleSelect>
            );
        }

        return (
            <FormControlSelect
                className="field-picklist-input"
                value={field.getValue() ?? ''}
                onChange={this.handleChange}
                onBlur={this.handleValidate}
                fullWidth
                error={errors.size > 0}
                helperText={errors.get('value') ? t(errors.get('value')) : ''}
                variant="outlined"
                autoFocus={this.props.autoFocus}
                endAdornment={this.renderEndAdornment(field)}
            >
                {this.processItems(field).map((item) => (
                    <MenuItem key={item.value} value={item.value} style={{ whiteSpace: 'normal' }}>
                        {item.label}
                    </MenuItem>
                ))}
            </FormControlSelect>
        );
    }

    renderInputValue = (value) => {
        return Array.isArray(value)
            ? value.map((item) => this.props.field.getItemLabel(item)).join(', ')
            : this.props.field.getItemLabel(value);
    };

    renderEndAdornment = (field) => {
        if (this.state.loading) {
            return (
                <InputAdornment position="end">
                    <CircularProgress style={{ marginRight: '20px' }} size={14} />
                </InputAdornment>
            );
        }
        if (field.getIsRequired() || field.getIsMultiple() || !field.getValue()) {
            return null;
        }
        return (
            <InputAdornment position="end">
                <i
                    className="fal fa-times"
                    style={{ marginRight: '20px', cursor: 'pointer' }}
                    onClick={this.handleChangeClear}
                />
            </InputAdornment>
        );
    };
}

PicklistField.defaultProps = {
    autoFocus: false,
};

PicklistField.propTypes = {
    field: PropTypes.instanceOf(PickList).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default PicklistField;
