import AbstractEvent, { EventStatus, EventType } from './Events/AbstractEvent';
import dispatcher from '../dispatcher';
import events from '../../events';
import EventsFactory from './EventsFactory';

class Queue {
    private onlineEvents: Array<AbstractEvent>;
    private offlineEvents: Array<AbstractEvent>;

    constructor() {
        this.onlineEvents = [];
        this.offlineEvents = [];
    }

    public removeEvent(event: AbstractEvent): void {
        if (event.getType() === EventType.ONLINE) {
            this.onlineEvents = this.onlineEvents.filter(
                (existingEvent: AbstractEvent) => existingEvent.getUid() !== event.getUid(),
            );
            dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
        } else {
            this.offlineEvents = this.offlineEvents.filter(
                (existingEvent: AbstractEvent) => existingEvent.getUid() !== event.getUid(),
            );
            dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
        }
    }

    public replaceEvent(oldEvent: AbstractEvent, newEvent: AbstractEvent): void {
        if (oldEvent.getType() === EventType.ONLINE) {
            this.onlineEvents = this.onlineEvents.map((existingEvent: AbstractEvent) => {
                return existingEvent.getUid() === oldEvent.getUid() ? newEvent : existingEvent;
            });

            dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
        } else {
            this.offlineEvents = this.offlineEvents.map((existingEvent: AbstractEvent) => {
                return existingEvent.getUid() === oldEvent.getUid() ? newEvent : existingEvent;
            });

            dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
        }
    }

    public addEvent(event: AbstractEvent): void {
        if (event.getType() === EventType.ONLINE) {
            this.onlineEvents.push(event);

            dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
        } else {
            this.offlineEvents.push(event);

            dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
        }
    }

    public findEvent(eventId: string): AbstractEvent | null {
        let event = this.onlineEvents.find((event) => event.getUid() === eventId);

        if (event) {
            return event;
        }

        event = this.offlineEvents.find((event) => event.getUid() === eventId);
        return event ? event : null;
    }

    public getOnlineEvents(): Array<AbstractEvent> {
        return this.onlineEvents.filter((event) => !event.isCompletedStatus());
    }

    public getNewOnlineEvents(): Array<AbstractEvent> {
        return this.onlineEvents.filter((event) => event.getStatus() === EventStatus.NEW);
    }

    public getActiveOfflineEvents(): Array<AbstractEvent> {
        return this.offlineEvents.filter((event) => !event.isCompletedStatus());
    }

    public getCurrentOfflineEvent(): AbstractEvent | null {
        const activeEvents = this.getActiveOfflineEvents();

        if (activeEvents.length === 0) {
            return null;
        }

        return activeEvents[0];
    }

    public toStorage(): object {
        const result = {
            offlineEvents: [],
            onlineEvents: [],
        };

        for (const event of this.offlineEvents) {
            // @ts-ignore
            result.offlineEvents.push(event.toStorage());
        }

        for (const event of this.onlineEvents) {
            // @ts-ignore
            result.onlineEvents.push(event.toStorage());
        }

        return result;
    }

    public fromStorage(data: any) {
        let hasOfflineEvents = false;
        let hasOnlineEvents = false;

        for (const eventData of data.offlineEvents) {
            this.addOrUpdateEvent(eventData);
            hasOfflineEvents = true;
        }

        for (const eventData of data.onlineEvents) {
            this.addOrUpdateEvent(eventData);
            hasOnlineEvents = true;
        }

        if (hasOfflineEvents) {
            dispatcher.dispatch(events.EVENTS_QUEUE_CHANGED);
        }

        if (hasOnlineEvents) {
            dispatcher.dispatch(events.EVENTS_ONLINE_QUEUE_CHANGED);
        }

        if (hasOnlineEvents || hasOfflineEvents) {
            dispatcher.dispatch(events.EVENTS_QUEUE_LOADED);
        }
    }

    private addOrUpdateEvent(data: any) {
        const event = this.findEvent(data.uid);

        if (event) {
            AbstractEvent.fromStorageBase(event, data);
        } else {
            this.addEvent(EventsFactory.fromStorage(data));
        }
    }
}

export default Queue;
