import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

export const TriggerEvent = {
    Button: 'Button',
    ButtonApi: 'Button (API)',
    FormEvent: 'Form event',
    SearchBarFilter: 'Search bar filter',
    Schedule: 'Scheduled process',
    LiveUpdate: 'Live update',
    Import: 'Import',
    Geocoding: 'Geocoding',
    /**
     * @deprecated Use ExportProspects instead
     */
    Prospecting: 'Prospecting',
    TerritoryAssignment: 'Territory assignment',
    ExternalApiCall: 'External API call',
    CreateRecord: 'Create record',
    ExportProspects: 'Export prospects',
    SystemDataSourceSynchronization: 'System data source synchronization',
    System: 'System',
};

export const isRuleEvent = (triggerEvent) => {
    return ![TriggerEvent.Button, TriggerEvent.Schedule, TriggerEvent.FormEvent, TriggerEvent.SearchBarFilter].includes(
        triggerEvent,
    );
};
export const isButtonEvent = (triggerEvent) => {
    return triggerEvent === TriggerEvent.Button;
};
export const isScheduleEvent = (triggerEvent) => {
    return triggerEvent === TriggerEvent.Schedule;
};
export const isActionEvent = (triggerEvent) => {
    return [TriggerEvent.FormEvent, TriggerEvent.SearchBarFilter].includes(triggerEvent);
};

class ExecutionFlowManager extends BackendService {
    getSessions(accountId, showSearchBarFilter, filters = [], sorting = [], page, pageSize) {
        const url = reverse(apiRoutes.account.workflow.executionFlow.sessions.index, { accountId });
        return this.requestApi(url, 'GET', { showSearchBarFilter, filters, sorting, page, pageSize });
    }

    getSessionLog(accountId, session, logId = null, search = null) {
        const url = reverse(apiRoutes.account.workflow.executionFlow.sessions.logs.index, {
            accountId,
            sessionId: session.id,
        });
        return this.requestApi(url, 'GET', {
            from: session.startedAt,
            to: session.finishedAt || null,
            search,
            logId,
        });
    }

    async getLogContext(accountId, sessionId, logId) {
        let { contextStack, lastDiffId } = await this.fetchContext(accountId, sessionId, logId);
        while (lastDiffId) {
            let response = await this.fetchContext(accountId, sessionId, logId, lastDiffId);
            contextStack = contextStack.concat(response.contextStack);
            lastDiffId = response.lastDiffId;
        }
        return contextStack;
    }

    fetchContext(accountId, sessionId, logId, diffId = null) {
        const url = reverse(apiRoutes.account.workflow.executionFlow.sessions.logs.context, {
            accountId,
            sessionId,
            logId,
        });
        return this.requestApi(url, 'GET', { diffId });
    }
}

export const executionFlowManager = new ExecutionFlowManager();
