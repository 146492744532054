import { Button, Typography } from '@material-ui/core';
import PureFormDialog from 'components/PureFormDialog';
import { FormActions } from 'components/PureFormDialog/Form';
import { noop } from 'lodash';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { HistoryPromptListener, historyPromptManager } from 'service/HistoryPromptManager';

type HistoryCallback = Parameters<HistoryPromptListener>[1];

export interface HistoryBlockerProps extends RouteComponentProps {
    isBlocking: boolean;
    onSave?(callback: HistoryCallback): void;
    onDiscard?(callback: HistoryCallback): void;
    buttonSave: ReactNode;
    buttonDiscard: ReactNode;
}

// default behavior for onSave/onDiscard - just allow navigation
const DEFAULT_HANDLER: HistoryBlockerProps['onSave'] = (cb) => cb(true);

export const BaseHistoryBlocker: FC<HistoryBlockerProps> = (props) => {
    const {
        buttonDiscard,
        buttonSave,
        history,
        isBlocking,
        onDiscard = DEFAULT_HANDLER,
        onSave = DEFAULT_HANDLER,
    } = props;
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    // Ref storing current history prompt callback
    const historyCallbackRef = useRef<HistoryCallback>(noop);

    useEffect(() => {
        if (!isBlocking) {
            // We are just closing popup here.
            setIsOpen(false);
            // We don't release the navigation here because `isBlocking=false` just means that conditions to
            // allow user to navigate are met. But maybe there's maybe more work to be done. So we explicitly
            // give a callback in onSave/onDiscard.
            return;
        }

        // Here we subscribe to prompt request and show dialog. It is not a navigation blocker yet.
        const listener: HistoryPromptListener = (_message, callback) => {
            setIsOpen(true);
            historyCallbackRef.current = callback;
        };
        historyPromptManager.subscribe(listener);

        return () => {
            historyPromptManager.unsubscribe(listener);
            historyCallbackRef.current = noop;
        };
    }, [isBlocking]);

    useEffect(() => {
        if (!isBlocking) {
            return;
        }

        // Here we block the navigation and return an unblock handler.
        return history.block('');
    }, [history, isBlocking]);

    return (
        <PureFormDialog
            title={t('data_source.history_blocker.title')}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
                historyCallbackRef.current?.(false);
            }}
            hideCloseButton
            maxWidth={false}
        >
            <FormActions closeText={t('data_source.history_blocker.btn_cancel')} cancelButtonColor="secondary">
                {buttonDiscard !== null && (
                    <Typography color="error" component="span">
                        <Button
                            color="inherit"
                            onClick={() => {
                                setIsOpen(false);
                                onDiscard(historyCallbackRef.current);
                            }}
                        >
                            {buttonDiscard ?? t('data_source.history_blocker.btn_discard')}
                        </Button>
                    </Typography>
                )}
                {buttonSave !== null && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setIsOpen(false);
                            onSave(historyCallbackRef.current);
                        }}
                    >
                        {buttonSave ?? t('data_source.history_blocker.btn_save')}
                    </Button>
                )}
            </FormActions>
        </PureFormDialog>
    );
};

/**
 * This component blocks history navigation when `isBlocking` prop is true.
 * Shows popup when trying to navigate.
 */
export const HistoryBlocker = withRouter(BaseHistoryBlocker);
