import React from 'react';
import { Tooltip } from '@material-ui/core';
import { getAdapters } from './adapters';
import { AdapterInfo } from 'components/types';

interface Props {
    onSignInRequest: (adapter: AdapterInfo) => void;
}

const SignInProviders: React.FC<Props> = (props) => {
    const { onSignInRequest } = props;

    return (
        <div style={{ marginTop: 8 }}>
            {getAdapters().map((adapter, i) => {
                const p = (i + 1) % 3;
                const c = p === 0 ? 'right' : p === 1 ? 'left' : 'center';
                return (
                    <div key={adapter.id} onClick={() => onSignInRequest(adapter)} className={'crm-icon ' + c}>
                        <Tooltip title={adapter.info || ''}>
                            <img src={'/image/sign_in/' + adapter.id + '.png'} alt={adapter.name} />
                        </Tooltip>
                    </div>
                );
            })}
        </div>
    );
};

export default SignInProviders;
