import i18n from 'i18next';
import events from '../events';
import dispatcher from './dispatcher';
import geoLocationManager from '../service/GeoLocationManager';
import { DATETIME_FORMAT_DATEFNS, isValidLocation } from 'utils';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import cloneDeep from 'lodash/cloneDeep';
import { userManager } from './UserManager';
import GeoPointTransformer from './GeoPointTransformer';
import {
    appointmentConfig,
    confirmReloadSessionManager,
    departingDateTimeService,
    enqueueSnackbarService,
    routeDesignConfigManager,
    routeEditorManager,
    routingSessionManager,
} from 'service/MapPage';
import VehicleProfileConverter from 'service/MapPage/VehicleProfile/VehicleProfileConverter';
import mapslyGeocoderApi from '../api/MapslyGeocoderApi';
import { GeocoderApiCustomError, GeocoderResultStatus } from 'components/types';
import { Api, Common, DataSource, Entity, Geocoder, Routing } from 'interfaces';
import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from 'api/BackendService';
import pick from 'lodash/pick';
import dsManagerFactory from './DsManager';
import { SYSTEM_ENTITIES_API_NAMES } from '../components/constants';
import { MyLocationType, UsersTodayRoutes, UsersTodayRoutesResponse } from '../interfaces/routing/route';
import { RouteDenormalizer } from './Serializer';

export interface MyLocationPoints {
    start: Routing.Route.UserLocationPoint | null;
    end: Routing.Route.UserLocationPoint | null;
}

/**
 * Отвечает за сборку роутов из точек и параметров указанных в DESIGN mode. После успешной сборки,
 * сессия находится в статусе DRAFT.
 * - За дизайн точек отвечает RouteDesignManager.
 * TODO Параметры роута хранятся в CurrentRouteManager. Нужно переименовать в `DesignRouteConfigManager`.
 */
class RouteManager extends BackendService {
    /** @deprecated */
    private systemEntity: Entity.Entity | null = null;
    private isBuilding: boolean = false;
    private isBuildingCanceled: boolean = false;

    initSubscriptions(): void {
        dispatcher.subscribe(
            events.WS_ROUTING_BUILD_READY,
            this,
            (buildResult: Api.Route.GeocoderRouteBuildResultApiResponse) => {
                if (this.isBuildingCanceled) {
                    return;
                }
                const result = mapslyGeocoderApi.denormalizeBuildResponse(buildResult);
                try {
                    this.validateBuildResult(result);
                    routingSessionManager.updateDraftSession(result.session, result.routes!);
                    dispatcher.dispatch(events.CURRENT_ROUTE_BUILT);
                } catch (e) {
                    this.isBuilding = false;
                    routingSessionManager.setSession(result.session);
                    enqueueSnackbarService.sendErrorMessage(e.message);
                } finally {
                    routeEditorManager.setIsBuilding(false);
                }
            },
        );

        dispatcher.subscribe(
            events.WS_ROUTING_REFRESH_READY,
            this,
            (buildResult: Api.Route.GeocoderRouteBuildResultApiResponse) => {
                const result = mapslyGeocoderApi.denormalizeBuildResponse(buildResult);
                try {
                    this.validateBuildResult(result);
                    routingSessionManager.updateLoadedRoute(result.session, result.routes![0]);
                } catch (e) {
                    routingSessionManager.setSession(result.session);
                    enqueueSnackbarService.sendErrorMessage(e.message);
                } finally {
                    routeEditorManager.setIsBuilding(false);
                }
            },
        );

        dispatcher.subscribe(
            events.WS_ROUTING_EDIT_READY,
            this,
            (buildResult: Api.Route.GeocoderRouteBuildResultApiResponse) => {
                const result = mapslyGeocoderApi.denormalizeBuildResponse(buildResult);

                try {
                    this.validateBuildResult(result);

                    if (routingSessionManager.isShowLoadedSessionRoute) {
                        routingSessionManager.updateLoadedRoute(result.session, result.routes![0]);
                    } else {
                        routingSessionManager.updateDraftRoute(result.session, result.routes![0]);
                    }

                    dispatcher.dispatch(events.CURRENT_ROUTE_BUILT);

                    if (routeEditorManager.isSwitchingToEditModeWithAddPointsLoading) {
                        routeEditorManager.setIsSwitchingToEditModeWithAddPointsLoading(false);
                    }
                } catch (e) {
                    routingSessionManager.setSession(result.session);
                    enqueueSnackbarService.sendErrorMessage(e.message);
                } finally {
                    routeEditorManager.setIsChanging(false);
                }
            },
        );
    }

    clearSubscriptions(): void {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    // тут запрос на пользователей и здесь берем defaultLocation
    getUsersTodayRoutes(accountId: number): Promise<UsersTodayRoutes> {
        const url = reverse(apiRoutes.account.routes.users_today_routes, { accountId });
        return this.requestApi(url, 'GET').then((response: UsersTodayRoutesResponse) => {
            const result: UsersTodayRoutes = {};
            for (const userId of Object.keys(response)) {
                result[userId] = {
                    ...response[userId],
                    routes: RouteDenormalizer.denormalizeRoutes(response[userId].routes),
                };
            }
            return result;
        });
    }

    private static async getMyLocationsPoints(
        input: Routing.Route.RouteInput,
        isStarLocationMyCurrentLocation: boolean,
    ): Promise<MyLocationPoints> {
        const [start, end] = await Promise.all([
            RouteManager.getMyStartLocation(input.start, isStarLocationMyCurrentLocation),
            RouteManager.getMyEndLocation(input.finish),
        ]);

        return { start, end };
    }

    private static async getMyStartLocation(
        start: Routing.Route.MyLocation,
        isStarLocationMyCurrentLocation: boolean,
    ): Promise<Routing.Route.UserLocationPoint> {
        if (isStarLocationMyCurrentLocation || start.buttonType === Routing.Route.MyLocationType.CurrentLocation) {
            const geoPoint = await geoLocationManager.getLocation();
            if (!isValidLocation(geoPoint)) {
                throw new Error(i18n.t('map_page.snack.address_lookup_geolocation_failed'));
            }
            return GeoPointTransformer.transformGeoPoint(geoPoint);
        }

        if (start.point === null) {
            throw new Error('Start point is not specified.');
        }
        return start.point;
    }

    private static async getMyEndLocation(
        end: Routing.Route.MyLocation,
    ): Promise<Routing.Route.UserLocationPoint | null> {
        if (end.buttonType === Routing.Route.MyLocationType.CurrentLocation) {
            const geoPoint = await geoLocationManager.getLocation();
            if (!isValidLocation(geoPoint)) {
                throw new Error(i18n.t('map_page.snack.address_lookup_geolocation_failed'));
            }
            return GeoPointTransformer.transformGeoPoint(geoPoint);
        }
        if (end.buttonType === Routing.Route.MyLocationType.Switch) {
            return null;
        }
        return end.point;
    }

    private _getBuildPayload(
        pointsForBuild: Routing.Route.PointForBuild[],
        unit: Common.DistanceMetric,
        ignoreWarnings: boolean,
        myLocationPoints: MyLocationPoints,
        config: Routing.Route.DesignConfig,
    ): Geocoder.Route.BuildRoutePayload {
        const { start, end } = myLocationPoints;

        const points: Geocoder.Route.BuildRoutePoint[] = [];

        for (let point of pointsForBuild) {
            let lat = null;
            let lng = null;
            let { countryShort, entityId, id, address, addressFields, objectName, pointType, objectSettings } = point;
            if (isValidLocation(point)) {
                lat = point.lat;
                lng = point.lng;
                if (Math.abs(lng) > 180) {
                    const sign = Math.sign(lng);
                    lng = Math.abs(lng);
                    while (lng > 180) {
                        lng -= 360;
                    }
                    lng = sign * lng;
                }
            }
            points.push({
                lat,
                lng,
                countryShort,
                entityId,
                id,
                address,
                addressFields,
                objectName,
                pointType,
                objectSettings,
            });
        }

        let vehicleProfile = cloneDeep(config.vehicleProfile);
        if (unit !== Common.DistanceMetric.KM) {
            vehicleProfile = VehicleProfileConverter.convertToMetricVehicleProfile(vehicleProfile);
        }

        const { departingCalendarData } = appointmentConfig;

        const dateStartForBuildRoute = departingDateTimeService.getDateStartForBuildRoute(departingCalendarData);
        const dateStartAt = formatInTimeZone(dateStartForBuildRoute, 'UTC', DATETIME_FORMAT_DATEFNS);

        const startPoint = start
            ? {
                  lat: start.lat,
                  lng: start.lng,
                  countryShort: start.countryShort,
                  id: start.id,
                  entityId: start.entityId,
                  address: start.address,
                  addressFields: start.addressFields,
              }
            : null;

        const endPoint = end ? GeoPointTransformer.transformLocationPointToBuildRouteEndPoint(end) : null;

        return {
            points,
            usersIds: config.usersIds,
            addCalendarEventsToRoute: config.addCalendarEventsToRoute,
            includeDraftEvents: config.includeDraftEvents,
            roundStartTimes: config.roundStartTimes,
            roundStartTimesValue: config.roundStartTimesValue,
            roundBreakStartTime: config.roundBreakStartTime,
            ignoreConstraints: config.ignoreConstraints,
            replaceRoutes: config.replaceRoutes,
            daysPeriod: config.daysPeriod,
            objective: config.objective,
            todayConfig: config.todayConfig,
            routeType: config.travelMode,
            optimize: config.optimal,
            dateStartAt,
            isDateStartNow: departingCalendarData.isNow,
            useTraffic: config.useTraffic,
            vehicleProfile,
            ignoreWarnings,
            startPoint,
            endPoint,
            async: true,
            continuousMode: config.continuousMode,
            splitJobsLongerThan: config.splitJobsLongerThan,
            maxOvertime: config.maxOvertime,
        };
    }

    private async crRefresh(
        route: Routing.Route.Route,
        activitiesIds: string[],
        locationPoints: MyLocationPoints,
    ): Promise<Routing.Route.BuildRouteResult> {
        const { start, end } = locationPoints;
        const pointKeys: Array<keyof Geocoder.Route.BuildRouteStartPoint> = [
            'lat',
            'lng',
            'countryShort',
            'entityId',
            'id',
            'address',
            'addressFields',
        ];
        const startPoint = start ? pick(start, pointKeys) : null;
        const endPoint = end ? pick(end, pointKeys) : null;

        const payload: Geocoder.Route.RefreshRoutePayload = {
            routeId: route.id,
            activitiesIds,
            startPoint,
            endPoint,
            async: true,
        };

        return mapslyGeocoderApi
            .refreshRoute(payload)
            .then((response: Api.Route.GeocoderRouteBuildResultResponse) => {
                if (response.status === GeocoderResultStatus.IN_PROGRESS) {
                    this.isBuilding && routingSessionManager.setSession(response.session);
                    return Promise.resolve({ warning: null });
                }

                const error = this.validateBuildResult(response);
                routingSessionManager.updateLoadedRoute(response.session, response.routes![0]);

                return Promise.resolve({ warning: error });
            })
            .catch((e: any) => {
                routeEditorManager.setIsBuilding(false);
                this.handleResponseError(e);
                return e;
            });
    }

    private async crBuild(
        points: Routing.Route.PointForBuild[],
        unit: Common.DistanceMetric,
        ignoreWarnings: boolean,
        myLocationPoints: MyLocationPoints,
        config: Routing.Route.DesignConfig,
    ): Promise<Routing.Route.BuildRouteResult> {
        const payload = this._getBuildPayload(points, unit, ignoreWarnings, myLocationPoints, config);
        this.isBuilding = true;
        this.isBuildingCanceled = false;

        return mapslyGeocoderApi
            .buildRoute(payload)
            .then((response: Api.Route.GeocoderRouteBuildResultResponse) => {
                if (response.status === GeocoderResultStatus.IN_PROGRESS) {
                    this.isBuilding && routingSessionManager.setSession(response.session);
                    return Promise.resolve({ warning: null });
                }

                const error = this.validateBuildResult(response);
                routingSessionManager.updateDraftSession(response.session, response.routes!);
                dispatcher.dispatch(events.CURRENT_ROUTE_BUILT);

                return Promise.resolve({ warning: error });
            })
            .catch((e: any) => {
                this.isBuilding = false;
                throw e;
            });
    }

    validateBuildResult = (buildResult: Api.Route.GeocoderRouteBuildResultResponse): string | null => {
        this.isBuilding = false;
        const { status, session, routes } = buildResult;

        let { error } = buildResult;
        const { start, finish } = buildResult.session.input;

        const useCurrentLocation =
            start.buttonType === Routing.Route.MyLocationType.CurrentLocation ||
            finish.buttonType === Routing.Route.MyLocationType.CurrentLocation;

        if (status !== GeocoderResultStatus.OK && status !== GeocoderResultStatus.ERROR_CONTINUOUS_MODE) {
            error = null;
        }

        if (status === GeocoderResultStatus.NO_RESULT && useCurrentLocation) {
            throw new Error(i18n.t('geocoder.build_route.result_status.no_result'));
        }

        if (status === GeocoderResultStatus.ERROR_SERVICE) {
            throw new GeocoderApiCustomError();
        }

        if (status === GeocoderResultStatus.ERROR_TIMEOUT) {
            if (session.input.config.optimal) {
                throw new Error(i18n.t('geocoder.build_route.result_status.timeout_opt'));
            } else {
                throw new Error(i18n.t('geocoder.build_route.result_status.timeout'));
            }
        }

        if (status !== GeocoderResultStatus.OK && status !== GeocoderResultStatus.OK_WITH_VIOLATIONS) {
            throw new Error(error || i18n.t('geocoder.build_route.result_error.unable_to_build_route'));
        }

        if (routes === null || (routes.length === 0 && session.unassignedJobs.length === 0)) {
            throw new Error(i18n.t('geocoder.build_route.result_error.unable_to_build_route'));
        }

        return error;
    };

    async getSystemEntity(): Promise<Entity.Entity> {
        if (this.systemEntity) {
            return Promise.resolve(this.systemEntity);
        }
        const account = userManager.getCurrentAccount();
        return dsManagerFactory
            .getManager(account.id)
            .list()
            .then((dataSources: DataSource.DataSource[]) => {
                const systemDataSource = dataSources.find((dataSource) => dataSource.isSystem);
                const entityCounter = systemDataSource
                    ? systemDataSource.entityCounters.find((counter) => {
                          return counter.entity.apiName === SYSTEM_ENTITIES_API_NAMES.route;
                      })
                    : null;
                if (!entityCounter) {
                    throw new Error('System entity was not found');
                }
                this.systemEntity = entityCounter.entity as unknown as Entity.Entity;
                return this.systemEntity;
            });
    }

    async buildRoutingSession(
        input: Routing.Session.SessionInput,
        points: Routing.Route.PointForBuild[],
        ignoreWarnings: boolean = false,
    ): Promise<Routing.Route.BuildRouteResult> {
        routeEditorManager.setIsBuilding(true);
        let myLocationPoints: MyLocationPoints = {
            start: null,
            end: null,
        };
        if (routeDesignConfigManager.usersIds.length === 1) {
            myLocationPoints = await RouteManager.getMyLocationsPoints(input, false);
        }
        const unit = userManager.getCurrentAccount().distanceMetric;
        return await this.crBuild(points, unit, ignoreWarnings, myLocationPoints, input.config).catch((e: any) => {
            routeEditorManager.setIsBuilding(false);
            throw e;
        });
    }

    async refreshRoute(
        route: Routing.Route.Route,
        activitiesIdsForRefresh: string[],
        updateStartLocation = false,
    ): Promise<Routing.Route.BuildRouteResult> {
        routeEditorManager.setIsBuilding(true);
        const { activities } = route;

        const startActivity = activities.length > 0 ? activities[0] : null;
        const lastActivity = activities.length > 0 ? activities[activities.length - 1] : null;

        let locationPoints: MyLocationPoints = {
            start: startActivity ? this.activityToLocationPoint(startActivity) : null,
            end:
                lastActivity?.type === Routing.Route.ActivityType.END
                    ? this.activityToLocationPoint(lastActivity)
                    : null,
        };
        if (updateStartLocation) {
            const geoPoint = await geoLocationManager.getLocation();
            if (!isValidLocation(geoPoint)) {
                throw new Error(i18n.t('map_page.snack.address_lookup_geolocation_failed'));
            }
            locationPoints.start = GeoPointTransformer.transformGeoPoint(geoPoint);
        }
        if (!locationPoints.start && route.input.start.buttonType === MyLocationType.CurrentLocation) {
            const { currentSession } = routingSessionManager;
            let { start } = await RouteManager.getMyLocationsPoints(currentSession.input, false);
            locationPoints.start = start;
        }
        return this.crRefresh(route, activitiesIdsForRefresh, locationPoints);
    }

    cancelBuilding(): void {
        this.isBuildingCanceled = true;
        routeEditorManager.setIsBuilding(false);
    }

    async deleteRoutes(routeIds: string[]): Promise<void> {
        const currentAccount = userManager.getCurrentAccount();
        const url = reverse(apiRoutes.account.routes.delete, { accountId: currentAccount.id });
        const request: Api.Routing.Route.DeleteRoutesRequest = { ids: routeIds };
        await this.requestApi(url, 'POST', request);
    }

    private handleResponseError(e: Api.Exception.HttpError): void {
        const handleableErrorCodes = [400, 403, 404, 500];
        if (!handleableErrorCodes.includes(e.code)) {
            throw e;
        }
        if (e instanceof GeocoderApiCustomError) {
            throw e;
        }
        if (e.exception === Api.Exception.ExtraCodes.INVALID_STATUS_ROUTING_SESSION) {
            confirmReloadSessionManager.openDialog();
        } else {
            enqueueSnackbarService.sendErrorMessage(e.message);
        }
    }

    private activityToLocationPoint(activity: Routing.Route.Activity): Routing.Route.UserLocationPoint {
        return {
            address: activity.address,
            addressFields: activity.addressFields,
            countryShort: activity.countryShort,
            entityId: (activity as any).entityId,
            id: activity.id,
            lat: activity.lat,
            lng: activity.lng,
            objectName: activity.objectName,
            /** @deprecated */
            uuid: '',
        };
    }
}

export default RouteManager;
