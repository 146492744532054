import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CalendarRow from './CalendarRow';
import { Calendars } from 'interfaces/calendar/calendar';
import { Entity } from 'interfaces/entity';

interface Props extends WithTranslation {
    calendars: Calendars;
    entitiesById: Map<number, Entity>;
    accountId: number;
    handleDelete: (calendarId: string) => void;
}

interface State {}

class CalendarsTable extends React.Component<Props, State> {
    render() {
        const { t, calendars, entitiesById } = this.props;
        const rowItems = this.getRowItems(calendars, entitiesById);

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="60px" />
                        <TableCell>
                            {t('calendar_settings_calendars.dialog.form_control.calendar_name.label')}
                        </TableCell>
                        <TableCell>
                            {t('calendar_settings_calendars.dialog.form_control.calendar_object.label')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>{rowItems}</TableBody>
            </Table>
        );
    }

    private getRowItems(calendars: Calendars, entitiesById: Map<number, Entity>): JSX.Element[] {
        const items: JSX.Element[] = [];
        for (let [key, calendar] of calendars.entries()) {
            items.push(
                <CalendarRow
                    key={key}
                    calendarKey={key}
                    calendar={calendar}
                    entity={entitiesById.get(calendar.entityId)}
                    accountId={this.props.accountId}
                    handleDelete={this.props.handleDelete}
                />,
            );
        }
        return items;
    }
}

export default withTranslation()(CalendarsTable);
