const TARGET = {
    IFRAME: 'iframe',
    NEW_TAB: 'new_tab',
};

const DEFAULT_DETAILS = {
    url: '',
    target: TARGET.IFRAME,
    iframeWidth: 500,
    iframeHeight: 500,
};

export { TARGET, DEFAULT_DETAILS };
