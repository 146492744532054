import React from 'react';
import {
    TextField,
    FormHelperText,
    Grid,
    FormControl,
    IconButton,
    Tooltip,
    Icon,
    CircularProgress,
    withStyles,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
    DETAIL_CUSTOM,
    DETAIL_FIELD,
    DETAIL_FIELD_ID,
    DETAIL_FORMULA,
    getDetail,
} from '../../../service/WorkflowActionManager';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { withTranslation } from 'react-i18next';
import FormulaInput from '../AbstractForm/FormulaInput';
import FormDialog from '../../FormDialog';
import LookupFieldForm from '../AbstractForm/LookupFieldForm';
import { CallContext } from '../../utils/CallContext';
import LiveSearchTableViewDialog from 'components/LiveUpdateTableForm/LiveSearchTableViewDialog';
import FormulaButtonGroup from 'components/WorkflowActions/FormulaButtonGroup';
import { TYPE_TEXT_ARRAY } from '../../../service/types';
import DisabledOptionWithHintAutocomplete from '../../DisabledOptionWithHintAutocomplete';
import { isFewLookup, isReadonlyField } from '../../../utils';

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_action.sortable_field.drag_button">
        <Icon>dehaze</Icon>
    </IconButton>
));

const FieldAutocomplete = withStyles({
    option: {
        '&[aria-disabled="true"]': {
            opacity: 'initial',
            color: 'rgba(0, 0, 0, 0.38)',
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
        },
    },
})(DisabledOptionWithHintAutocomplete);

const FieldInput = withStyles((theme) => ({
    root: {
        '& .Mui-error input': {
            color: theme.palette.error.main,
        },
    },
}))(TextField);

class Field extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            field: null,
            lookupField: null,
            lookupFieldEntity: null,
            fieldEntityType: null,
            showLookupModal: false,
            selectedEntityType: null,
            selectedLookupField: null,
            selectedLookupFieldEntity: null,
            showSearch: null,

            showCustomInput: false,
            customField: null,
        };

        this.formulaInputRef = React.createRef();
        this.currentDataSourceId = null;
    }

    componentDidMount() {
        this.initState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldField = getDetail(DETAIL_FIELD, prevProps.details);
        const newField = getDetail(DETAIL_FIELD, this.props.details);
        const oldFieldId = getDetail(DETAIL_FIELD_ID, prevProps.details);
        const newFieldId = getDetail(DETAIL_FIELD_ID, this.props.details);
        const oldCustomValue = getDetail(DETAIL_CUSTOM, prevProps.details);
        const newCustomValue = getDetail(DETAIL_CUSTOM, this.props.details);
        if (oldField !== newField || oldFieldId !== newFieldId || oldCustomValue !== newCustomValue) {
            this.setState(
                {
                    field: null,
                    lookupField: null,
                    lookupFieldEntity: null,
                    fieldEntityType: null,
                    showCustomInput: false,
                    customField: null,
                },
                () => this.initState(),
            );
        }
    }

    initState = () => {
        const custom = getDetail(DETAIL_CUSTOM, this.props.details, false);
        if (custom) {
            this.setState({
                showCustomInput: true,
                customField: getDetail(DETAIL_FIELD, this.props.details),
            });
        } else {
            let fieldId = getDetail(DETAIL_FIELD_ID, this.props.details);
            let [entityType, sourceFieldApiName, fieldApiName] = getDetail(DETAIL_FIELD, this.props.details, '').split(
                '.',
            );
            if (!fieldApiName) {
                fieldApiName = sourceFieldApiName;
                sourceFieldApiName = null;
            }
            if (sourceFieldApiName && fieldApiName) {
                const { lookupField, dsId } = this.findLookupField(sourceFieldApiName);
                lookupField &&
                    dsId &&
                    this.props.onEntityFetch(lookupField.lookupData.apiName, dsId).then((lookupFieldEntity) => {
                        const field = lookupFieldEntity && this.getFieldByApiName([lookupFieldEntity], fieldApiName);
                        this.setState({
                            field,
                            lookupField,
                            lookupFieldEntity,
                            fieldEntityType: entityType,
                            showCustomInput: false,
                            customField: null,
                        });
                    });
            } else {
                const field = fieldId
                    ? this.getFieldById(this.props.entities, fieldId)
                    : this.getFieldByApiName(
                          this.props.entities,
                          fieldApiName,
                          this.props.callContext.getEntityId(entityType),
                      );
                this.setState({
                    field,
                    lookupField: null,
                    lookupFieldEntity: null,
                    fieldEntityType: entityType,
                    showCustomInput: false,
                    customField: null,
                });
            }
        }
    };

    findLookupField = (originalApiName) => {
        for (const entity of this.props.entities) {
            const lookupField = entity.fields.find((f) => {
                return f.lookupData && (f.originalApiName === originalApiName || f.apiName === originalApiName);
            });
            if (lookupField) {
                return { lookupField, dsId: entity.dataSource.id };
            }
        }
        return {};
    };

    getFieldById = (entities, fieldId) => {
        if (!fieldId) {
            return null;
        }
        for (const entity of entities) {
            for (const field of entity.fields) {
                if (field.id === fieldId) {
                    return field;
                }
            }
        }
        return null;
    };

    getFieldByApiName = (entities, apiName, entityId = null) => {
        let inactiveField = null;
        for (const entity of entities) {
            for (const field of entity.fields) {
                if (field.apiName === apiName && (entityId === null || entityId === entity.id)) {
                    if (!field.isDeleted && field.isIncluded) {
                        return field;
                    }
                    inactiveField = field;
                }
            }
        }
        return inactiveField;
    };

    handleFieldChange = (event, option) => {
        const type = option ? option.type : null;
        const field = option ? option.field : null;
        const entityType = option ? option.entityType : null;
        this.currentDataSourceId = option ? option.dsId : null;

        if (type === 'custom') {
            this.showCustomInput();
            this.props.onFieldChange(null, null, this.props.fieldIndex, true);
        } else {
            this.setState({ field, lookupField: null, lookupFieldEntity: null, fieldEntityType: entityType });
            const fieldId = field ? field.id : null;
            const value = field ? this.implodeFieldValue([entityType, field.apiName]) : null;
            this.props.onFieldChange(fieldId, value, this.props.fieldIndex, false);
            this.hideCustomInput();
        }
    };

    implodeFieldValue = (values) => {
        return values.filter((v) => v).join('.');
    };

    handleLookupFieldChange = (field) => {
        const { selectedEntityType, selectedLookupField, selectedLookupFieldEntity } = this.state;
        if (!field || !selectedEntityType || !selectedLookupField || !selectedLookupFieldEntity) {
            return;
        }

        this.setState({
            field,
            lookupField: selectedLookupField,
            lookupFieldEntity: selectedLookupFieldEntity,
            fieldEntityType: selectedEntityType,
            selectedEntityType: null,
            selectedLookupField: null,
            selectedLookupFieldEntity: null,
            showLookupModal: false,
        });

        const lookupFieldApiName = selectedLookupField.originalApiName || selectedLookupField.apiName;
        const value = this.implodeFieldValue([selectedEntityType, lookupFieldApiName, field.apiName]);
        this.props.onFieldChange(field.id, value, this.props.fieldIndex, false);
        this.hideCustomInput();
    };

    handleCustomInput = (event) => {
        this.props.onFieldChange(null, event.target.value, this.props.fieldIndex, true);
    };

    showCustomInput = () => {
        this.setState({ showCustomInput: true });
    };

    hideCustomInput = () => {
        this.setState({ showCustomInput: false });
    };

    openLookupFieldsModal = (event, option) => {
        event.stopPropagation();
        if (!option.field || !option.field.lookupData || !option.field.lookupData.apiName) {
            return;
        }

        this.props.onEntityFetch(option.field.lookupData.apiName, option.dsId).then((selectedLookupFieldEntity) => {
            this.setState({
                showLookupModal: true,
                selectedEntityType: option.entityType,
                selectedLookupField: option.field,
                selectedLookupFieldEntity,
            });
        });
    };

    closeLookupFieldsModal = () => {
        this.setState({
            showLookupModal: false,
            selectedEntityType: null,
            selectedLookupField: null,
            selectedLookupFieldEntity: null,
        });
    };

    handleFormulaChange = (event) => {
        this.props.onFormulaChange(event.target.value, this.props.fieldIndex);
    };

    findCurrentOption = () => {
        let value;
        if (this.state.showCustomInput) {
            value = 'custom';
        } else if (this.state.lookupField) {
            value = this.state.lookupField.id;
        } else {
            const field = this.state.field;
            if (!field) {
                return null;
            }
            value = field.id;
        }
        const option =
            this.props.options.find((opt) => {
                return this.state.fieldEntityType
                    ? opt.value === value && opt.entityType === this.state.fieldEntityType
                    : opt.value === value;
            }) || null;
        if (option) {
            this.currentDataSourceId = option.dsId || null;
        }
        return option;
    };

    renderInput = (params) => {
        const { lookupField, field } = this.state;
        const error =
            lookupField?.isDeleted ||
            lookupField?.isIncluded === false ||
            field?.isDeleted ||
            field?.isIncluded === false;

        return (
            <FieldInput
                {...params}
                label=""
                error={error}
                InputProps={{
                    ...params.InputProps,
                    style: { paddingTop: '5px', paddingBottom: '7px' },
                    startAdornment: (
                        <React.Fragment>
                            {params.InputProps.startAdornment}
                            {error && (
                                <Tooltip title={this.props.t('automation_elements.inactive_field')}>
                                    <Icon fontSize="small" color="error" className="fas fa-circle-exclamation" />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    ),
                    endAdornment: (
                        <React.Fragment>
                            {this.props.loading ? <CircularProgress color="inherit" size={15} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    renderOptionLabel = (option) => {
        if (this.props.callContext.entities.length > 1 && option.groupBy) {
            return `${option.groupBy} > ${this.renderFieldLabel(option)}`;
        }
        return this.renderFieldLabel(option);
    };

    renderFieldLabel = (option) => {
        if (option.type === 'lookup' && this.state.lookupField && this.state.field) {
            const label = option.label.replace(/\s\((ID)\)$/, '');
            return `${label} > ${this.state.field.label}`;
        }

        if (option.type === 'field' || option.type === 'lookup') {
            return option.label.replace(/\s\((ID)\)$/, '');
        }

        return option.label;
    };

    renderOption = (option) => {
        if (option.type === 'lookup' && option.field && !isFewLookup(option.field)) {
            const label = option.label.replace(/\s\((ID|NAME|TYPE)\)$/, '');
            return (
                <Grid container direction="row" alignItems="center" justify="space-between">
                    {label}
                    <IconButton
                        onClick={(e) => this.openLookupFieldsModal(e, option)}
                        data-testid="workflow_actions.form.field_update.open_lookup_field"
                        style={{ pointerEvents: 'initial', color: 'initial' }}
                    >
                        <ArrowForwardIos fontSize="small" />
                    </IconButton>
                </Grid>
            );
        }

        if (option.type === 'field' && option.field.lookupData !== null) {
            return option.label.replace(/\s\((ID)\)$/, '');
        }

        return option.label;
    };

    openShowSearch = (event) => {
        event.stopPropagation();
        const field = this.state.field;
        if (field) {
            this.setState({ showSearch: { field } });
        }
    };

    closeShowSearch = () => {
        this.setState({ showSearch: null });
    };

    handleSearchRecord = (record) => {
        this.props.onFormulaChange(record && record.id, this.props.fieldIndex);
        this.closeShowSearch();
    };

    isMultiPicklist = () => {
        return !!(this.state.field && this.state.field.picklist && this.state.field.type === TYPE_TEXT_ARRAY);
    };

    getOptionDisabled = (option) => {
        const field = option ? option.field : null;
        if (field) {
            return (
                this.props.selectedFields.has(option.entityType + field.id) ||
                isReadonlyField(field) ||
                field.isDeleted ||
                !field.isIncluded
            );
        }
        return false;
    };

    getOptionDisabledHint = (option) => {
        if (option?.field && isReadonlyField(option.field)) {
            return this.props.t('automation_elements.form.field.readonly_hint');
        }
        if (option?.field?.isDeleted || option?.field?.isIncluded === false) {
            return this.props.t('automation_elements.form.field.deactivated_hint');
        }
        return this.props.t('workflow_actions.form.field_update.field.disabled_hint');
    };

    getOptionSelected = (option, value) => {
        return !!value && option.value === value.value && option.entityType === value.entityType;
    };

    render() {
        const { showSearch } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={1} direction="row" alignItems="center">
                    <DragHandle />
                    <Grid item container spacing={1} alignItems="center" xs>
                        <Grid item xs={5}>
                            <FormControl fullWidth margin="dense" error={this.props.errors.has('field')}>
                                <FieldAutocomplete
                                    options={this.props.options}
                                    renderOption={this.renderOption}
                                    getOptionLabel={this.renderOptionLabel}
                                    getOptionSelected={this.getOptionSelected}
                                    getOptionDisabled={this.getOptionDisabled}
                                    disabledOptionHint={this.getOptionDisabledHint}
                                    value={this.findCurrentOption()}
                                    onChange={this.handleFieldChange}
                                    groupBy={(option) => option && option.groupBy}
                                    loading={this.props.loading}
                                    renderInput={this.renderInput}
                                    style={{ minHeight: '45px' }}
                                />
                                {this.props.errors.get('field') && (
                                    <FormHelperText>{this.props.errors.get('field')}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormulaInput
                                label=""
                                fullWidth
                                multiline
                                name="formula"
                                onChange={this.handleFormulaChange}
                                value={getDetail(DETAIL_FORMULA, this.props.details, '')}
                                error={this.props.errors.has('formula')}
                                helperText={this.props.errors.get('formula') || ''}
                                style={{ minHeight: '45px' }}
                                callContext={this.props.callContext}
                                ref={this.formulaInputRef}
                                picklist={this.state.field && this.state.field.picklist}
                                multiPicklist={this.isMultiPicklist()}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <FormulaButtonGroup
                                            field={this.state.field}
                                            onOpenShowSearch={this.openShowSearch}
                                            onOpenFormulaModal={
                                                this.formulaInputRef.current &&
                                                this.formulaInputRef.current.handleOpenFormulaModal
                                            }
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        {this.state.showCustomInput && (
                            <Grid item xs={12}>
                                <FormulaInput
                                    label=""
                                    placeholder={this.props.t('workflow_actions.form.field_update.custom')}
                                    fullWidth
                                    multiline
                                    name="custom"
                                    onChange={this.handleCustomInput}
                                    value={getDetail(DETAIL_FIELD, this.props.details, '')}
                                    error={this.props.errors.has('custom')}
                                    helperText={this.props.errors.get('custom') || ''}
                                    callContext={this.props.callContext}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Tooltip title={this.props.t('remove')}>
                        <IconButton
                            color="secondary"
                            onClick={() => this.props.onRemove(this.props.fieldIndex)}
                            data-testid="workflow_actions.form.field_update.remove"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>

                {this.state.showLookupModal && (
                    <FormDialog
                        title={this.state.selectedLookupFieldEntity ? this.state.selectedLookupFieldEntity.label : null}
                        onSave={this.handleLookupFieldChange}
                        onCancel={this.closeLookupFieldsModal}
                        maxWidth="xs"
                        fullWidth
                    >
                        <LookupFieldForm
                            fields={
                                this.state.selectedLookupFieldEntity ? this.state.selectedLookupFieldEntity.fields : []
                            }
                            selectedEntityType={this.state.selectedEntityType}
                            selectedFields={this.props.selectedFields}
                        />
                    </FormDialog>
                )}

                {showSearch && (
                    <LiveSearchTableViewDialog
                        accountId={this.props.accountId}
                        dataSourceId={this.currentDataSourceId || this.props.dataSourceId}
                        onClose={this.closeShowSearch}
                        onSaveRequest={this.handleSearchRecord}
                        field={showSearch.field}
                        value={showSearch.field.value}
                    />
                )}
            </React.Fragment>
        );
    }
}

Field.propTypes = {
    options: PropTypes.array.isRequired,
    selectedFields: PropTypes.instanceOf(Set).isRequired,
    details: PropTypes.object.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    entities: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onFormulaChange: PropTypes.func.isRequired,
    onEntityFetch: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    dataSourceId: PropTypes.number,
};

export default withTranslation('translations', { withRef: true })(SortableElement(Field));
