import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import './style.css';
import DottedLink from '../DottedLink';
import i18n from '../../locales/i18n';
import Expression, { isFieldOperand } from './Expression';
import PureFormDialog from '../PureFormDialog';
import UrlParamsForm from './UrlParamsForm';
import { FIELD_DISTANCE } from '../Map/constants';
import { CallContext, TYPE_ENTITY_BASE, TYPE_ENTITY_BASE_OLD, TYPE_ENTITY_TARGET } from '../utils/CallContext';
import { entityManager } from '../../service/SimpleEntityManager';
import FilterParamsForm from './FilterParamsForm';
import AccountContext from '../Context/AccountContext';
import DataSourceContext from '../Context/DataSourceContext';
import { FieldLookupType } from 'components/types';
import {
    TYPE_INTEGER,
    TYPE_BIGINT,
    TYPE_FLOAT,
    TYPE_STRING,
    TYPE_TEXT,
    TYPE_BOOLEAN,
    TYPE_TEXT_ARRAY,
    TYPE_DATE,
    TYPE_DATETIME,
    TYPE_INTEGER_ARRAY,
    TYPE_JSON,
} from '../../service/types';

const t = i18n.t.bind(i18n);

const SEARCH_PARAMS = {
    show: false,
    value: '',
    id: null,
};

export const TYPE_CATEGORY_NUMERIC = 'numeric';
export const TYPE_CATEGORY_STRING = 'string';
export const TYPE_CATEGORY_BOOLEAN = 'boolean';
export const TYPE_CATEGORY_DATE = 'date';
export const TYPE_CATEGORY_DATETIME = 'datetime';
export const TYPE_CATEGORY_TEXT_ARRAY = 'text[]';
export const TYPE_CATEGORY_INTEGER_ARRAY = 'integer[]';
export const TYPE_CATEGORY_JSON = 'json';

export default class ExpressionBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            expression: [],
            fields: [],
            buffer: null,
            anchorEl: null,
            operandMenuAnchorEl: null,
            operandMenuId: null,
            selection: null,
            errors: new Map(),
            urlParams: { ...SEARCH_PARAMS },
            filterParams: { ...SEARCH_PARAMS },
        };

        this.dsId = null;
        this.accountId = null;
    }

    componentDidMount() {
        this.initBuilder();
    }

    prepareEntityFields = (entity, fields, type, groupBy = null) => {
        const sortedFields = entity.fields.sort((fieldA, fieldB) => fieldA.label.localeCompare(fieldB.label));
        for (let field of sortedFields) {
            if (
                field.lookupData &&
                ![FieldLookupType.POLYMORPHIC, FieldLookupType.FEW].includes(field.lookupData.type)
            ) {
                if (field.apiName.endsWith('_ID')) {
                    if (field.label.endsWith(' (ID)')) {
                        field = { ...field };
                        field.label = field.label.substring(0, field.label.length - 5);
                    }
                } else {
                    continue;
                }
            }

            if (field.isIncluded && field.isVirtual && field.apiName === FIELD_DISTANCE) {
                if (this.props.enableFormulaMode) {
                    continue;
                }
                fields.push({
                    type,
                    label: field.label,
                    value: type + '_' + field.id,
                    field,
                    groupBy: groupBy || entity.label,
                });
                continue;
            }
            if (field.isIncluded && !field.isVirtual && ExpressionBuilder.getTypeCategory(field.type) !== '') {
                fields.push({
                    type,
                    label: field.label,
                    value: type + '_' + field.id,
                    field,
                    groupBy: groupBy || entity.label,
                });
            }
        }
        return fields;
    };

    initBuilder = () => {
        const fields = [];

        this.initBaseEntities(fields)
            .then(() => {
                return this.initTargetEntities(fields);
            })
            .then(() => {
                let expression = JSON.parse(JSON.stringify(this.props.defaultExpression));
                if (expression.length === 0) {
                    expression = [this.createExpression(fields)];
                }

                this.setState({ fields, expression, loading: false });
            });
    };

    initBaseEntities = (fields) => {
        const entities = [];
        const { overrideBaseEntitiesIds } = this.props;
        let entitiesIds = overrideBaseEntitiesIds;
        if (!Array.isArray(entitiesIds) || entitiesIds.length === 0) {
            entitiesIds = this.props.callContext.getEntityId(TYPE_ENTITY_BASE, true);
        }
        for (const entityId of entitiesIds) {
            entities.push(entityManager.get(entityId));
        }
        return Promise.all(entities).then((entities) => {
            for (const entity of entities) {
                this.dsId = entity.dataSource.id;
                this.accountId = entity.dataSource.account.id;
                this.prepareEntityFields(
                    entity,
                    fields,
                    TYPE_ENTITY_BASE,
                    this.getEntityGroupByLabel(entity, TYPE_ENTITY_BASE),
                );
                if (this.props.addOldEntitySection) {
                    this.prepareEntityFields(
                        entity,
                        fields,
                        TYPE_ENTITY_BASE_OLD,
                        this.getEntityGroupByLabel(entity, TYPE_ENTITY_BASE_OLD),
                    );
                }
            }
        });
    };

    initTargetEntities = (fields) => {
        const entities = [];
        const { overrideTargetEntitiesIds } = this.props;
        let entitiesIds = overrideTargetEntitiesIds;
        if (!Array.isArray(entitiesIds) || entitiesIds.length === 0) {
            entitiesIds = this.props.callContext.getEntityId(TYPE_ENTITY_TARGET, true);
        }
        for (const entityId of entitiesIds) {
            entities.push(entityManager.get(entityId));
        }
        return Promise.all(entities).then((entities) => {
            for (const entity of entities) {
                this.dsId = entity.dataSource.id;
                this.accountId = entity.dataSource.account.id;
                this.prepareEntityFields(
                    entity,
                    fields,
                    TYPE_ENTITY_TARGET,
                    this.getEntityGroupByLabel(entity, TYPE_ENTITY_TARGET),
                );
            }
        });
    };

    getEntityGroupByLabel = (entity, type) => {
        if (this.props.showGroupByPrefix) {
            const prefixLabel = this.props.callContext.getPrefixLabelByType(type);
            if (null !== prefixLabel) {
                return `${prefixLabel} (${entity.label})`;
            }
        }
        return entity.label;
    };

    groupFieldOptions = () => {
        return !!(this.props.callContext.getTargetEntityId() || this.props.addOldEntitySection);
    };

    static isUnaryOperator(operator) {
        return ['~', '!~', 'true', 'false', 'today'].includes(operator);
    }

    static isArrayOperator(operator) {
        return ['in', '!in', '@>', '!@>', '&&', '!&&'].includes(operator);
    }

    static dateToStr(date) {
        const f = (value) => {
            return value < 10 ? '0' + value : value;
        };
        return date.getFullYear() + '-' + f(date.getMonth() + 1) + '-' + f(date.getDate());
    }

    static dateTimeToStr(date) {
        const f = (value) => {
            return value < 10 ? '0' + value : value;
        };
        return (
            date.getFullYear() +
            '-' +
            f(date.getMonth() + 1) +
            '-' +
            f(date.getDate()) +
            ' ' +
            f(date.getHours()) +
            ':' +
            f(date.getMinutes())
        );
    }

    static getOperatorsForField(field) {
        if (field.picklist || (field.lookupData && field.apiName.endsWith('_ID'))) {
            if (field.type === TYPE_TEXT_ARRAY) {
                return ['@>', '!@>', '&&', '!&&'];
            }
            return ['=', '!=', 'in', '!in', '~', '!~'];
        }
        if (field.apiName === FIELD_DISTANCE) {
            return ['>', '<', '>=', '<='];
        }
        switch (field.type) {
            case TYPE_INTEGER:
            case TYPE_BIGINT:
            case TYPE_FLOAT:
                return ['=', '!=', '<', '>', '<=', '>=', '~', '!~', 'in', '!in'];
            case TYPE_STRING:
            case TYPE_TEXT:
                return ['=', '!=', 's', 'e', 'c', '!c', '~', '!~', 'in', '!in'];
            case TYPE_BOOLEAN:
                return ['true', 'false', '~', '!~'];
            case TYPE_DATE:
            case TYPE_DATETIME:
                return ['=', '!=', '<', '>', '<=', '>=', '~', '!~', 'in', '!in'];
            case TYPE_TEXT_ARRAY:
            case TYPE_INTEGER_ARRAY:
                return ['@>', '!@>', '&&', '!&&'];
            case TYPE_JSON:
                return ['~', '!~', 'c', '!c'];
            default:
                return [];
        }
    }

    static getOperatorsForFunc(func) {
        switch (func) {
            case 'currentDirectDistance':
                return ['<', '>', '<=', '>='];
            case 'getDataSourceUserId':
                return ['=', '!=', 'in', '!in'];
            default:
                return [];
        }
    }

    static getDefaultValue(type) {
        switch (type) {
            case TYPE_INTEGER:
            case TYPE_BIGINT:
            case TYPE_FLOAT:
                return 0;
            case TYPE_STRING:
            case TYPE_TEXT:
            case TYPE_JSON:
                return '';
            case TYPE_DATE:
                return this.dateToStr(new Date());
            case TYPE_DATETIME:
                return this.dateTimeToStr(new Date());
            case TYPE_TEXT_ARRAY:
            case TYPE_INTEGER_ARRAY:
                return [];
            default:
                return '';
        }
    }

    static getInitialFieldValue(field, operator) {
        if (field.lookupData && field.lookupData.type === 'polymorphic') {
            return {
                entity: null,
                value: null,
            };
        }
        return this.getInitialValue(field.type, operator);
    }

    static getInitialValue(type, operator) {
        return this.isArrayOperator(operator) ? [] : this.getDefaultValue(type);
    }

    static getTypeCategory(type) {
        switch (type) {
            case TYPE_INTEGER:
            case TYPE_BIGINT:
            case TYPE_FLOAT:
                return TYPE_CATEGORY_NUMERIC;
            case TYPE_STRING:
            case TYPE_TEXT:
                return TYPE_CATEGORY_STRING;
            case TYPE_BOOLEAN:
                return TYPE_CATEGORY_BOOLEAN;
            case TYPE_DATE:
                return TYPE_CATEGORY_DATE;
            case TYPE_DATETIME:
                return TYPE_CATEGORY_DATETIME;
            case TYPE_TEXT_ARRAY:
                return TYPE_CATEGORY_TEXT_ARRAY;
            case TYPE_INTEGER_ARRAY:
                return TYPE_CATEGORY_INTEGER_ARRAY;
            case TYPE_JSON:
                return TYPE_CATEGORY_JSON;
            default:
                return '';
        }
    }

    static getFunctionType = (func) => {
        switch (func) {
            case 'currentDirectDistance':
                return TYPE_FLOAT;
            case 'getDataSourceUserId':
                return TYPE_INTEGER;
            default:
                return '';
        }
    };

    static areTypesCompatible(type1, type2, field1 = null, field2 = null) {
        if ((field1 && field1.apiName === FIELD_DISTANCE) || (field2 && field2.apiName === FIELD_DISTANCE)) {
            return false;
        }
        return this.getTypeCategory(type1) === this.getTypeCategory(type2);
    }

    static prepareFieldLabel = (field, grouped = false) => {
        if (grouped && field.entity) {
            return (
                <React.Fragment>
                    <span className="text-muted">
                        {field.entity.label}
                        <span style={{ marginLeft: 5, marginRight: 5 }}>{'>'}</span>
                    </span>
                    {field.label}
                </React.Fragment>
            );
        }
        return field.label;
    };

    clearErrors() {
        this.setState({
            errors: new Map(),
        });
    }

    getExpression(expression) {
        return this.props
            .onValidate(expression !== undefined ? expression : this.state.expression)
            .then((response) => {
                return {
                    expression: this.state.expression,
                    validationResult: response || null,
                };
            })
            .catch((error) => {
                this.setState({
                    errors: error.details,
                });
                return { expression: null };
            });
    }

    createExpression(fields = null) {
        const xOperand = Array.isArray(fields) && fields.length > 0 ? fields[0] : this.state.fields[0];
        if (!xOperand || !xOperand.field) {
            return {};
        }
        const operators =
            isFieldOperand(xOperand.type) && xOperand.field
                ? ExpressionBuilder.getOperatorsForField(xOperand.field)
                : ExpressionBuilder.getOperatorsForFunc(xOperand.value);
        return {
            xField: xOperand.field.id,
            o: operators[0],
            yConst: this.constructor.getInitialFieldValue(xOperand.field, operators[0]),
        };
    }

    handleLogicalOperatorChange = (id) => {
        this.setState((state) => {
            const indices = id.split('-');
            let exp = state.expression;
            const lastIndex = parseInt(indices.pop());

            for (let index of indices) {
                exp = exp[parseInt(index)];
            }

            exp[lastIndex] = exp[lastIndex] === 'AND' ? 'OR' : 'AND';

            return state;
        });
    };

    getFieldById = (id) => {
        for (let field of this.state.fields.filter((f) => f.field && f.field.id).map((f) => f.field)) {
            if (field.id === id) {
                return field;
            }
        }
        return null;
    };

    static scalarToArray(value) {
        if (!value) {
            return [];
        }
        if (typeof value === 'object' && value.hasOwnProperty('entity')) {
            //polymorphic
            return {
                entity: value.entity,
                value: this.scalarToArray(value.value),
            };
        }
        if (Array.isArray(value)) {
            return value;
        }
        return [value];
    }

    static arrayToScalar(value, defaultValue) {
        if (Array.isArray(value) && value.length > 0) {
            return value[0];
        }
        if (value !== null && typeof value === 'object' && value.hasOwnProperty('entity')) {
            //polymorphic
            return {
                entity: value.entity,
                value: this.arrayToScalar(value.value, defaultValue),
            };
        }
        return defaultValue;
    }

    updateOperator(exp, newValue) {
        const oldValue = exp.o;
        exp.o = newValue;
        if (this.constructor.isUnaryOperator(newValue)) {
            ExpressionBuilder.setUnary(exp);
            return;
        }

        const field = this.getFieldById(exp.xField);
        if (this.constructor.isUnaryOperator(oldValue)) {
            if (field) {
                ExpressionBuilder.setYConst(exp, this.constructor.getInitialFieldValue(field, exp.o));
            } else {
                ExpressionBuilder.setYConst(exp, '');
            }
        }
        // становится массивом
        if (this.constructor.isArrayOperator(newValue) && !this.constructor.isArrayOperator(oldValue)) {
            ExpressionBuilder.setYConst(exp, this.constructor.scalarToArray(exp.yConst));
        }
        // перестает быть массивом
        if (!this.constructor.isArrayOperator(newValue) && this.constructor.isArrayOperator(oldValue)) {
            ExpressionBuilder.setYConst(
                exp,
                this.constructor.arrayToScalar(
                    exp.yConst,
                    field ? this.constructor.getInitialFieldValue(field, exp.o) : '',
                ),
            );
        }
    }

    handleExpressionChange = (id, newValue, property, type) => {
        this.setState((state) => {
            const indices = id.split('-');
            let exp = state.expression;
            for (let index of indices) {
                exp = exp[parseInt(index)];
            }

            if (property === 'xField') {
                const newField = this.getFieldById(+newValue);
                ExpressionBuilder.setYConst(exp, this.constructor.getInitialFieldValue(newField, exp.o));

                ExpressionBuilder.setXField(exp, newValue, type);
                const operators = ExpressionBuilder.getOperatorsForField(newField);
                if (!operators.includes(exp.o)) {
                    this.updateOperator(exp, operators[0]);
                }
            }
            if (property === 'xFunc') {
                ExpressionBuilder.setYConst(
                    exp,
                    this.constructor.getInitialValue(ExpressionBuilder.getFunctionType(newValue), exp.o),
                );
                ExpressionBuilder.setXFunc(exp, newValue);
                const operators = ExpressionBuilder.getOperatorsForFunc(newValue);
                if (!operators.includes(exp.o)) {
                    this.updateOperator(exp, operators[0]);
                }
            }
            if (property === 'url') {
                this.setState({
                    urlParams: { ...SEARCH_PARAMS, show: true, id },
                });
            }
            if (property === 'filter') {
                this.setState({
                    filterParams: { ...SEARCH_PARAMS, show: true, id },
                });
            }
            if (property === 'o') {
                this.updateOperator(exp, newValue);
            }
            if (property === 'yConst') {
                ExpressionBuilder.setYConst(exp, newValue);
                if (type === 'dynamic') {
                    if (exp.o === 'in' || exp.o === '!in') {
                        exp.o = '=';
                    }
                }
            }
            if (property === 'yTwig') {
                ExpressionBuilder.setYConst(exp, newValue, true);
            }
            if (property === 'yField') {
                ExpressionBuilder.setYField(exp, newValue, type);
            }
            if (property === 'yExpr') {
                ExpressionBuilder.setYExpr(exp, newValue);
            }

            return state;
        });
    };

    static setUnary(expr) {
        delete expr.yConst;
        delete expr.yIsTwig;
        delete expr.yField;
        delete expr.yExpr;
    }

    static setXField(expr, value, type) {
        expr.xField = +value;
        type && (expr.xFieldType = type);
        delete expr.xFunc;
    }

    static setXFunc(expr, value) {
        expr.xFunc = value;
        delete expr.xField;
        delete expr.xFieldType;
    }

    static setYConst(expr, value, isTwig = false) {
        expr.yConst = value;
        expr.yIsTwig = isTwig;
        delete expr.yField;
        delete expr.yFieldType;
        delete expr.yExpr;
    }

    static setYField(expr, value, type) {
        expr.yField = +value;
        type && (expr.yFieldType = type);
        delete expr.yConst;
        delete expr.yIsTwig;
        delete expr.yExpr;
    }

    static setYExpr(expr, value) {
        expr.yExpr = value;
        delete expr.yConst;
        delete expr.yIsTwig;
        delete expr.yField;
        delete expr.yFieldType;
    }

    handleIntoBrackets = () => {
        const indices = this.state.selection.id.split('-');
        let exp = this.state.expression;

        const lastIndex = parseInt(indices.pop());
        for (let index of indices) {
            exp = exp[parseInt(index)];
        }

        if (Array.isArray(exp[lastIndex])) {
            // it is brackets already
            return;
        }

        this.setState((state) => {
            exp[lastIndex] = [exp[lastIndex]];
            return state;
        });
    };

    handleFromBrackets = () => {
        const indices = this.state.selection.id.split('-');
        let exp = this.state.expression;

        const lastIndex = parseInt(indices.pop());
        for (let index of indices) {
            exp = exp[parseInt(index)];
        }

        const bracketsContent = exp[lastIndex];
        if (!Array.isArray(bracketsContent)) {
            // it is not brackets
            return;
        }

        this.setState((state) => {
            exp.splice(lastIndex, 1, ...bracketsContent);
            return state;
        });
    };

    handleCopy = () => {
        this.setState({
            buffer: this.cloneOperandById(this.state.selection.id),
        });
    };

    handleCut = () => {
        this.setState({
            buffer: this.cloneOperandById(this.state.selection.id),
        });
        this.handleDelete(true);
    };

    handleDelete = () => {
        const del = (exp, indices) => {
            if (indices.length === 1) {
                const index = parseInt(indices[0]);
                if (exp.length > 1) {
                    if (index === 0) {
                        exp.splice(index, 2);
                    } else {
                        exp.splice(index - 1, 2);
                    }
                } else {
                    exp.splice(index, 1);
                    return true;
                }
                return false;
            }

            const firstIndex = parseInt(indices.shift());
            if (del(exp[firstIndex], indices)) {
                indices = [firstIndex];
                return del(exp, indices);
            }
        };
        this.setState((state) => {
            const indices = this.state.selection.id.split('-');
            del(state.expression, indices);

            return state;
        });
    };

    cloneOperandById = (id) => {
        if (!id) {
            return null;
        }
        const indices = id.split('-');
        let exp = this.state.expression;
        for (let index of indices) {
            exp = exp[parseInt(index)];
        }
        return JSON.parse(JSON.stringify(exp));
    };

    handlePasteBefore = () => {
        this.add(JSON.parse(JSON.stringify(this.state.buffer)), this.state.selection.id, 'before');
    };

    handlePasteAfter = () => {
        this.add(JSON.parse(JSON.stringify(this.state.buffer)), this.state.selection.id, 'after');
    };

    handleAddBefore = () => {
        this.add(this.createExpression(), this.state.selection.id, 'before');
    };

    handleAddAfter = () => {
        this.add(this.createExpression(), this.state.selection.id, 'after');
    };

    add = (val, id, placement) => {
        if (id === '') {
            if (placement === 'before') {
                this.setState((state) => {
                    if (state.expression.length > 0) {
                        state.expression.unshift('AND');
                    }
                    state.expression.unshift(val);
                    return state;
                });
            } else {
                this.setState((state) => {
                    if (state.expression.length > 0) {
                        state.expression.push('AND');
                    }
                    state.expression.push(val);
                });
            }
            return;
        }

        this.setState((state) => {
            const indices = id.split('-');
            const lastIndex = parseInt(indices.pop());

            let exp = state.expression;
            for (let index of indices) {
                exp = exp[parseInt(index)];
            }

            switch (placement) {
                case 'before':
                    exp.splice(lastIndex, 0, val);
                    if (exp.length > 1) {
                        exp.splice(lastIndex + 1, 0, 'AND');
                    }
                    break;
                case 'after':
                    if (exp.length > 0) {
                        exp.splice(lastIndex + 1, 0, 'AND');
                    }
                    exp.splice(lastIndex + 2, 0, val);
                    break;
                default: //same
                    exp[lastIndex] = val;
            }
            return state;
        });
    };

    handleActionsClick = (event) => {
        const selection = event.currentTarget.name;
        this.setState({
            anchorEl: event.currentTarget,
            selection: {
                id: selection.substr(1),
                type: selection.substr(0, 1),
            },
        });
    };

    handleActionsClose = () => {
        this.setState({
            anchorEl: null,
            selection: null,
        });
    };

    drawExpressionBranch = (exp, levelCode = '') => {
        return (
            <React.Fragment>
                {exp.map((item, i) => {
                    const id = levelCode === '' ? i + '' : levelCode + '-' + i;
                    if (Array.isArray(item)) {
                        return (
                            <div className="exp-brackets" key={id}>
                                <div className="brackets-cell">
                                    <div className="abc">
                                        <Tooltip
                                            title={t('expresion_builder.button.actions.title')}
                                            style={{ top: '-4px' }}
                                        >
                                            <IconButton
                                                name={'b' + id}
                                                onClick={this.handleActionsClick}
                                                disabled={this.props.disabled}
                                                data-testid="expresion_builder.button.actions.button"
                                            >
                                                <Icon>settings</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="brackets-view" />
                                </div>
                                <div className="brackets-content">{this.drawExpressionBranch(item, id)}</div>
                            </div>
                        );
                    }
                    if (item === 'AND' || item === 'OR') {
                        return (
                            <div className="exp-operator" key={id}>
                                <DottedLink
                                    onClick={() => this.handleLogicalOperatorChange(id)}
                                    disabled={this.props.disabled}
                                    data-testid="expresion_builder.logical_operator_change"
                                >
                                    {item}
                                </DottedLink>
                            </div>
                        );
                    }

                    return (
                        <Expression
                            key={id}
                            id={id}
                            expression={item}
                            fields={this.state.fields}
                            callContext={this.props.callContext}
                            onExpressionChange={this.handleExpressionChange}
                            onActionsClick={this.handleActionsClick}
                            errors={this.state.errors}
                            groupFieldOptions={this.groupFieldOptions()}
                            addSpecialSection={this.props.addSpecialSection}
                            specialSectionOptions={this.props.specialSectionOptions}
                            enableFormulaMode={this.props.enableFormulaMode}
                            enableUrlFilterParameters={this.props.enableUrlFilterParameters}
                            disabled={this.props.disabled}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    handleStartExpression = () => {
        this.setState({
            expression: [this.createExpression()],
        });
    };

    handleOperandMenuClose = () => {
        this.setState({
            operandMenuAnchorEl: null,
            operandMenuId: null,
        });
    };

    handleOperandMenuOpen = (event) => {
        const id = event.currentTarget.name.substr(18);
        this.setState({
            operandMenuAnchorEl: event.currentTarget,
            operandMenuId: id,
        });
    };

    handleUrlParamsClose = () => {
        this.setState({
            urlParams: { ...SEARCH_PARAMS, show: false },
        });
    };

    handleUrlParamSave = (value) => {
        const { id } = this.state.urlParams;
        this.handleExpressionChange(id, value, 'yConst', 'dynamic');
        this.setState({
            urlParams: { ...SEARCH_PARAMS, show: false },
        });
    };

    handleFilterParamsClose = () => {
        this.setState({
            filterParams: { ...SEARCH_PARAMS, show: false },
        });
    };

    handleFilterParamSave = (value) => {
        const { id } = this.state.filterParams;
        this.handleExpressionChange(id, value, 'yConst', 'dynamic');
        this.setState({
            filterParams: { ...SEARCH_PARAMS, show: false },
        });
    };

    render() {
        if (this.state.loading) {
            return 'loading...';
        }
        const showUrlParams = this.state.urlParams.show;
        const showFilterParams = this.state.filterParams.show;
        return (
            <div className="c-expression-builder">
                {this.state.expression.length === 0 ? (
                    <Button
                        variant="contained"
                        onClick={this.handleStartExpression}
                        disabled={this.props.disabled}
                        data-testid="expresion_builder.button.build"
                    >
                        {t('expresion_builder.button.build')}
                    </Button>
                ) : (
                    <AccountContext.Provider value={this.accountId}>
                        <DataSourceContext.Provider value={this.dsId}>
                            {this.drawExpressionBranch(this.state.expression)}
                        </DataSourceContext.Provider>
                    </AccountContext.Provider>
                )}
                <Popover
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleActionsClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className="c-expression-builder-menu"
                >
                    <div style={{ width: '200px' }} onClick={this.handleActionsClose}>
                        <Button
                            fullWidth
                            onClick={this.handleAddBefore}
                            data-testid="expresion_builder.button.add_before"
                        >
                            {t('expresion_builder.button.add_before')}
                        </Button>
                        <Button
                            fullWidth
                            onClick={this.handleAddAfter}
                            data-testid="expresion_builder.button.add_after"
                        >
                            {t('expresion_builder.button.add_after')}
                        </Button>
                        <hr />
                        <Button
                            fullWidth
                            onClick={this.handleDelete}
                            disabled={this.state.expression.length === 1}
                            data-testid="expresion_builder.button.delete"
                        >
                            {t('expresion_builder.button.delete')}
                        </Button>
                        <Button fullWidth onClick={this.handleCut} data-testid="expresion_builder.button.cut">
                            {t('expresion_builder.button.cut')}
                        </Button>
                        <Button fullWidth onClick={this.handleCopy} data-testid="expresion_builder.button.copy">
                            {t('expresion_builder.button.copy')}
                        </Button>
                        <Button
                            fullWidth
                            onClick={this.handlePasteBefore}
                            disabled={this.state.buffer === null}
                            data-testid="expresion_builder.button.paste_before"
                        >
                            {t('expresion_builder.button.paste_before')}
                        </Button>
                        <Button
                            fullWidth
                            onClick={this.handlePasteAfter}
                            disabled={this.state.buffer === null}
                            data-testid="expresion_builder.button.paste_after"
                        >
                            {t('expresion_builder.button.paste_after')}
                        </Button>
                        <hr />
                        <Button
                            fullWidth
                            onClick={this.handleIntoBrackets}
                            disabled={this.state.selection && this.state.selection.type === 'b'}
                            data-testid="expresion_builder.button.to_brackets"
                        >
                            {t('expresion_builder.button.to_brackets')}
                        </Button>
                        <Button
                            fullWidth
                            onClick={this.handleFromBrackets}
                            disabled={this.state.selection && this.state.selection.type === 'o'}
                            data-testid="expresion_builder.button.remove_brackets"
                        >
                            {t('expresion_builder.button.remove_brackets')}
                        </Button>
                    </div>
                </Popover>
                {showUrlParams && (
                    <PureFormDialog
                        open
                        title={t('expresion_builder.insert_url_parameter')}
                        onClose={this.handleUrlParamsClose}
                        maxWidth="md"
                        fullWidth
                    >
                        <UrlParamsForm
                            value={this.state.urlParams.value}
                            onSave={this.handleUrlParamSave}
                            onClose={this.handleUrlParamsClose}
                        />
                    </PureFormDialog>
                )}
                {showFilterParams && (
                    <PureFormDialog
                        open
                        title={t('expresion_builder.insert_filter_parameter')}
                        onClose={this.handleFilterParamsClose}
                        maxWidth="md"
                        fullWidth
                    >
                        <FilterParamsForm
                            value={this.state.filterParams.value}
                            onSave={this.handleFilterParamSave}
                            onClose={this.handleFilterParamsClose}
                        />
                    </PureFormDialog>
                )}
            </div>
        );
    }
}

ExpressionBuilder.propTypes = {
    defaultExpression: PropTypes.array,
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    showGroupByPrefix: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    addSpecialSection: PropTypes.bool,
    addOldEntitySection: PropTypes.bool,
    specialSectionOptions: PropTypes.object,
    enableFormulaMode: PropTypes.bool,
    enableUrlFilterParameters: PropTypes.bool,
    overrideBaseEntitiesIds: PropTypes.array,
    overrideTargetEntitiesIds: PropTypes.array,
};

ExpressionBuilder.defaultProps = {
    defaultExpression: [],
    showGroupByPrefix: false,
    addSpecialSection: false,
    addOldEntitySection: false,
    specialSectionOptions: {},
    enableFormulaMode: false,
    enableUrlFilterParameters: false,
};

export const dateVariables = {
    get today() {
        return t('expresion_builder.date.today');
    },
    get monthFirstDay() {
        return t('expresion_builder.date.month_first_day');
    },
    get yearFirstDay() {
        return t('expresion_builder.date.month_year_first_day');
    },
};

export const dateTimeVariables = {
    get now() {
        return t('expresion_builder.date.now');
    },
    get dayStart() {
        return t('expresion_builder.date.day_start');
    },
    get weekStart() {
        return t('expresion_builder.date.week_start');
    },
    get monthStart() {
        return t('expresion_builder.date.month_start');
    },
    get yearStart() {
        return t('expresion_builder.date.year_start');
    },
};

export const userVariables = {
    get userId() {
        return t('expresion_builder.user.id');
    },
    get userName() {
        return t('expresion_builder.user.name');
    },
    get userEmail() {
        return t('expresion_builder.user.email');
    },
};

export const specialVariables = {
    get getDataSourceUserId() {
        return t('expresion_builder.special.get_data_source_user_id');
    },
};

export const ExpressionInput = (props) => {
    if (props.type === 'date' || props.type === 'datetime') {
        return <DateExpressionInput {...props} />;
    }
    if (props.type === 'string') {
        return <StringExpressionInput {...props} />;
    }
    return null;
};

const StringExpressionInput = (props) => {
    const { value } = props;
    return (
        <div className="c-expression-input">
            <div className="variable">
                <Tooltip title={userVariables[value]}>
                    <span>{userVariables[value]}</span>
                </Tooltip>
            </div>
        </div>
    );
};

const DateExpressionInput = (props) => {
    const { value, error, onChange, type, disabled } = props;

    const m = value.match(
        /^([a-z]+)(?:([+-])(\d+)?years)?(?:([+-])(\d+)?months)?(?:([+-])(\d+)?days)?(?:([+-])(\d+)?hours)?(?:([+-])(\d+)?minutes)?$/i,
    );
    if (m === null) {
        return null;
    }

    let variable = m[1];
    let yearsSign = m[2] || '+';
    let years = m[3] || 0;
    let monthsSign = m[4] || '+';
    let months = m[5] || 0;
    let daysSign = m[6] || '+';
    let days = m[7] || 0;
    let hoursSign = m[8] || '+';
    let hours = m[9] || 0;
    let minutesSign = m[10] || '+';
    let minutes = m[11] || 0;

    const variables = type === 'date' ? dateVariables : dateTimeVariables;

    if (!variables.hasOwnProperty(variable)) {
        return null;
    }

    const handleChange = (event) => {
        let value = event.target.value;
        if (value !== '') {
            if (!value.match(/^\d+$/)) {
                return;
            }
            value = parseInt(value, 10);
        } else {
            value = 0;
        }

        switch (event.target.name) {
            case 'years':
                years = value;
                break;
            case 'months':
                months = value;
                break;
            case 'days':
                days = value;
                break;
            case 'hours':
                hours = value;
                break;
            case 'minutes':
                minutes = value;
                break;
            default:
                return;
        }
        onChange(
            variable +
                yearsSign +
                years +
                'years' +
                monthsSign +
                months +
                'months' +
                daysSign +
                days +
                'days' +
                hoursSign +
                hours +
                'hours' +
                minutesSign +
                minutes +
                'minutes',
        );
    };

    const handleChangeSign = (event) => {
        const value = event.currentTarget.getAttribute('data-sign') === '-' ? '+' : '-';
        switch (event.currentTarget.getAttribute('data-variable')) {
            case 'years':
                yearsSign = value;
                break;
            case 'months':
                monthsSign = value;
                break;
            case 'days':
                daysSign = value;
                break;
            case 'hours':
                hoursSign = value;
                break;
            case 'minutes':
                minutesSign = value;
                break;
            default:
                return;
        }
        onChange(
            variable +
                yearsSign +
                years +
                'years' +
                monthsSign +
                months +
                'months' +
                daysSign +
                days +
                'days' +
                hoursSign +
                hours +
                'hours' +
                minutesSign +
                minutes +
                'minutes',
        );
    };

    return (
        <div className="c-expression-input">
            <IconButton
                onClick={handleChangeSign}
                data-variable="months"
                data-sign={monthsSign}
                disabled={disabled}
                data-testid="expresion_builder.months.change_sign"
            >
                <Icon>{monthsSign === '-' ? 'remove' : 'add'}</Icon>
            </IconButton>
            <TextField
                error={!!error}
                name="months"
                label={t('expresion_builder.months')}
                data-testid="expresion_builder.months"
                value={months}
                onChange={handleChange}
                type="number"
                margin="none"
                variant="outlined"
                style={{ width: '80px' }}
                disabled={disabled}
            />
            <IconButton
                onClick={handleChangeSign}
                data-variable="days"
                data-sign={daysSign}
                disabled={disabled}
                data-testid="expresion_builder.days.change_sign"
            >
                <Icon>{daysSign === '-' ? 'remove' : 'add'}</Icon>
            </IconButton>
            <TextField
                error={!!error}
                name="days"
                label={t('expresion_builder.days')}
                data-testid="expresion_builder.days"
                value={days}
                onChange={handleChange}
                type="number"
                margin="none"
                variant="outlined"
                style={{ width: '80px' }}
                disabled={disabled}
            />

            <IconButton
                onClick={handleChangeSign}
                data-variable="hours"
                data-sign={hoursSign}
                disabled={disabled}
                data-testid="expresion_builder.hours.change_sign"
            >
                <Icon>{hoursSign === '-' ? 'remove' : 'add'}</Icon>
            </IconButton>
            <TextField
                error={!!error}
                name="hours"
                label={t('expresion_builder.hours')}
                data-testid="expresion_builder.hours"
                value={hours}
                onChange={handleChange}
                type="number"
                margin="none"
                variant="outlined"
                style={{ width: '80px' }}
                disabled={disabled}
            />

            <IconButton
                onClick={handleChangeSign}
                data-variable="minutes"
                data-sign={minutesSign}
                disabled={disabled}
                data-testid="expresion_builder.minutes.change_sign"
            >
                <Icon>{minutesSign === '-' ? 'remove' : 'add'}</Icon>
            </IconButton>
            <TextField
                error={!!error}
                name="minutes"
                label={t('expresion_builder.minutes')}
                data-testid="expresion_builder.minutes"
                value={minutes}
                onChange={handleChange}
                type="number"
                margin="none"
                variant="outlined"
                style={{ width: '80px' }}
                disabled={disabled}
            />
        </div>
    );
};
