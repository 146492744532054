import React from 'react';
import { withTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { FormActions } from '../PureFormDialog/Form';
import Button from '@material-ui/core/Button';
import backend, { THIRD_PARTY_COOKIES_ACCESS_STATE } from 'api/BackendApi';

class ThirdPartyCookiesAccess extends React.Component {
    handleRequestThirdPartyCookiesAccess = () => {
        backend.requestThirdPartyCookiesAccess();
    };

    handleRejectThirdPartyCookiesAccess = () => {
        backend.setThirdPartyCookiesAccess(THIRD_PARTY_COOKIES_ACCESS_STATE.DENIED_BY_USER);
    };

    render() {
        return (
            <div>
                <PureFormDialog
                    open
                    title="Требуется доступ к пользовательским данным сайта Mapsly"
                    onClose={this.handleRejectThirdPartyCookiesAccess}
                >
                    <div>
                        Для корректной работы пользовательских сессий при встраивании страницы сайта Mapsly, Safari в
                        целях безопасности должен запросить у вас доступ к пользовательским данным Mapsly. Нажмите
                        "Запросить доступ" чтобы предоставить его.
                        <br />
                        Обратите внимание, что пользовательские сессии будут работать только если вы предварительно
                        откроете Mapsly в отдельной вкладке браузера.
                    </div>
                    <FormActions>
                        <Button onClick={this.handleRequestThirdPartyCookiesAccess} color="primary">
                            Запросить доступ
                        </Button>
                    </FormActions>
                </PureFormDialog>
            </div>
        );
    }
}

export default withTranslation()(ThirdPartyCookiesAccess);
