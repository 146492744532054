import dispatcher from '../dispatcher';
import events from '../../events';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { User } from '../../interfaces';
import { weAreInNativeApp } from '../../utils';

export default class Crashlytics {
    private initialized: boolean = false;

    public init() {
        if (!weAreInNativeApp()) {
            return;
        }

        if (this.initialized) {
            return;
        }
        this.initialized = true;

        FirebaseCrashlytics.setEnabled({ enabled: true });
        dispatcher.subscribe([events.EVENT_CURRENT_USER_CHANGED], this, (user: User.User | null | undefined) =>
            this.setUser(user),
        );
    }

    private setUser = async (user: User.User | null | undefined) => {
        const userId = user ? user.id.toString() : null;
        if (userId !== null) {
            await FirebaseCrashlytics.setUserId({ userId });
        }
        if (user !== null && user !== undefined && user.accountId !== null) {
            await FirebaseCrashlytics.setCustomKey({ key: 'accountId', value: user.accountId, type: 'int' });
        }
    };
}
