import WorkflowManager, { NAMESPACE_GLOBAL } from './WorkflowManager';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { CallContext } from '../components/utils/CallContext';

class WorkflowRuleManager extends WorkflowManager {
    static getDefaultRule(entity = null, namespace = NAMESPACE_GLOBAL) {
        return {
            id: null,
            namespace,
            name: null,
            isActive: true,
            triggerTypes: [],
            groups: [],
            callContext: CallContext.create(entity),
        };
    }

    get(accountId, ruleId) {
        const url = reverse(apiRoutes.account.workflowRules.item, { accountId, ruleId });
        return this.requestApi(url, 'GET').then((rule) => {
            return this.denormalizeRule(rule);
        });
    }

    list(accountId, filters = [], sorting = [], page = null, pageSize = null) {
        const url = reverse(apiRoutes.account.workflowRules, { accountId });
        return this.requestApi(url, 'GET', { filters, sorting, page, pageSize }).then((data) => {
            return {
                ...data,
                items: data.items.map((rule) => this.denormalizeRule(rule)),
            };
        });
    }

    normalizeRule(rule) {
        return {
            ...rule,
            groups: this.normalizeGroups(rule.groups),
            callContext: this.normalizeCallContext(rule.callContext),
        };
    }

    denormalizeRule(rule) {
        return {
            ...rule,
            groups: this.denormalizeGroups(rule.groups),
            callContext: this.denormalizeCallContext(rule.callContext),
        };
    }

    save(accountId, rule) {
        if (!rule.id) {
            const url = reverse(apiRoutes.account.workflowRules, { accountId });
            return this.requestApi(url, 'POST', this.normalizeRule(rule)).then((rule) => {
                return this.denormalizeRule(rule);
            });
        }

        const url = reverse(apiRoutes.account.workflowRules.item, { accountId, ruleId: rule.id });
        return this.requestApi(url, 'PATCH', this.normalizeRule(rule)).then((rule) => {
            return this.denormalizeRule(rule);
        });
    }

    delete(accountId, ruleId) {
        const url = reverse(apiRoutes.account.workflowRules.item, { accountId, ruleId });
        return this.requestApi(url, 'DELETE');
    }
}

export const workflowRuleManager = new WorkflowRuleManager();
