import React from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { withTranslation } from 'react-i18next';
import FormulaInput from '../../WorkflowActions/AbstractForm/FormulaInput';

class CommonConstantInput extends React.PureComponent {
    render() {
        const { value, error, disabled, onChange, t, isTwig, callContext } = this.props;
        const handleValueChange = (event) => {
            onChange(event.target.value);
        };

        const renderInput = () => {
            if (isTwig) {
                return (
                    <FormulaInput
                        callContext={callContext}
                        error={!!error}
                        label={t('expresion_builder.value')}
                        style={{ width: '150px' }}
                        value={value}
                        onChange={handleValueChange}
                        disabled={disabled}
                        margin="none"
                        variant="outlined"
                        hideEditButton
                    />
                );
            }
            return (
                <TextField
                    error={!!error}
                    label={t('expresion_builder.value')}
                    data-testid="expresion_builder.value"
                    style={{ width: '150px' }}
                    value={value}
                    onChange={handleValueChange}
                    disabled={disabled}
                    margin="none"
                    variant="outlined"
                />
            );
        };

        return (
            <FormControl error={!!error}>
                {renderInput()}
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

export default withTranslation('translations', { withRef: true })(CommonConstantInput);
