import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    DialogActions,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { grey, indigo, red } from '@material-ui/core/colors';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Hint from '../Hint';
import DottedLink from '../DottedLink';
import { FormActions, FormBackdrop } from '../PureFormDialog/Form';
import PureFormDialog from '../PureFormDialog';
import SearchableFieldsListForm from '../FieldsListForm/SearchableFieldsListForm';
import dispatcher from '../../service/dispatcher';
import entityManagerFactory from '../../service/EntityManager';
import { userManager } from '../../service/UserManager';
import events from '../../events';
import { weAreInNativeApp } from '../../utils';
import { reverse, routes } from '../../routes';
import cloneDeep from 'lodash/cloneDeep';
import './style.css';
import AddIconButton from '../CustomButton/AddIconButton';
import EditDefaultValuesBlock from '../EditDefaultValuesBlock/EditDefaultValuesBlock';

const DragHandle = SortableHandle(() => (
    <Icon className="c-record-view-settings__field__draggable_icon">drag_indicator</Icon>
));

const SortableItem = SortableElement((props) => (
    <div className="c-record-view-settings__field">
        <DragHandle />
        <span style={{ padding: 12, fontSize: '0.875rem', lineHeight: '1.5em' }}>{props.item.label}</span>
        <IconButton
            onClick={() => props.onFieldRemove(props.item)}
            className="c-record-view-settings__field__trash-icon"
            component="span"
            data-testid="record_view_settings.remove_field"
        >
            <i
                style={{ fontSize: 16, display: 'inline-block', width: 16, height: 16, color: red[500] }}
                className={'fas fa-trash'}
            />
        </IconButton>
    </div>
));

const SortableList = SortableContainer((props) => {
    return (
        <FormGroup style={{ backgroundColor: grey[100] }}>
            {props.items.map((item, index) => (
                <SortableItem key={item.name} index={index} item={item} onFieldRemove={props.onFieldRemove} />
            ))}
        </FormGroup>
    );
});

const sortByIncluded = (a, b) => {
    if (a.included === b.included) {
        return 0;
    }
    return a.included ? -1 : 1;
};

const sortNotIncludedAlphabetically = (a, b) => {
    if (a.included || b.included) {
        return 0;
    }
    return a.label.localeCompare(b.label);
};

const buildLinkToGeneralEntitySettings = (entity) => {
    if (!userManager.isRoleAdmin() || !entity) {
        return null;
    }

    return reverse(routes.admin.account.dataSource.entity, {
        dataSourceId: entity.dsId,
        entityId: entity.id,
    });
};

class RecordViewSettings extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fieldsListModal: false,
            loading: false,
            entitySettings: null, // ← no need to keep in state?
            usingDefaultSettings: false,
            view: null,
        };

        this.fieldsListForm = React.createRef();
    }

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.loadSettings();
            this.forceUpdate();
        });

        this.loadSettings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.showPersonalSettings !== this.props.showPersonalSettings) {
            this.setState({
                view: this.getViewFromProps(),
            });
        }
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadSettings() {
        this.setState({
            loading: true,
        });

        const entityId = this.props.entity.id;
        return this.getEntityManager()
            .getEntity(entityId)
            .then((entitySettings) => {
                if (entitySettings.id !== entityId) {
                    return;
                }
                this.setState(
                    {
                        entitySettings,
                    },
                    () => {
                        this.setState({
                            loading: false,
                            view: this.getViewFromProps(),
                            usingDefaultSettings:
                                JSON.stringify(entitySettings.personalRecordView) ===
                                JSON.stringify(entitySettings.recordView),
                        });
                    },
                );
            })
            .catch((error) => {
                this.props.onError(error);
                this.props.onClose();
            });
    }

    getViewFromProps = (useDefault = false) => {
        const { showPersonalSettings } = this.props;
        const { entitySettings } = this.state;
        let recordView;

        if (!showPersonalSettings || useDefault) {
            recordView = entitySettings?.recordView;
        } else {
            recordView = entitySettings?.personalRecordView;
        }

        if (recordView === undefined) {
            return null;
        }

        return this.recordViewToStateView(recordView ?? { fields: [] });
    };

    recordViewToStateView(recordView) {
        return {
            fields: this.getSortableCollection(recordView.fields),
        };
    }

    stateViewToRecordView(view) {
        return {
            fields: this.sortableCollectionToArray(view.fields),
        };
    }

    getEntityManager() {
        return entityManagerFactory.getManager(this.props.accountId, this.props.entity.dsId);
    }

    handleSave = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });

        const recordView = this.stateViewToRecordView(this.state.view);
        const entityData = this.props.showPersonalSettings ? { personalRecordView: recordView } : { recordView };

        this.props.onSaveClick?.();

        return this.getEntityManager()
            .saveEntitySettings(this.props.entity.id, entityData)
            .then(() => {
                this.setState({ loading: false });
                this.props.onSave();
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.props.onError(error);
            });
    };

    sortableCollectionToArray(sortableCollection) {
        const result = [];
        for (let item of sortableCollection) {
            if (item.included) {
                result.push(item.name);
            }
        }
        return result;
    }

    getEntityFields() {
        return this.state.entitySettings.fields.filter((field) => field.isIncluded);
    }

    getFieldLabel(apiName) {
        return this.getEntityFields().find((field) => field.apiName === apiName)?.label || null;
    }

    getSortableCollection(storedCollection) {
        const result = [];
        if (storedCollection) {
            for (let name of storedCollection) {
                const label = this.getFieldLabel(name);
                if (label === null) {
                    continue;
                }
                result.push({
                    included: true,
                    name: name,
                    label: label,
                });
            }
        }
        for (let field of this.sortByAlphabet(this.getEntityFields())) {
            if (field.isDeleted) {
                continue;
            }
            if (!storedCollection || storedCollection.indexOf(field.apiName) === -1) {
                result.push({
                    included: false,
                    name: field.apiName,
                    label: field.label,
                });
            }
        }
        return result;
    }

    sortByAlphabet(entityFields) {
        const entityFieldsCopy = cloneDeep(entityFields);
        entityFieldsCopy.sort((a, b) => a.label.localeCompare(b.label));
        return entityFieldsCopy;
    }

    handleFieldIncludedChange = (event) => {
        const checked = event.target.checked;
        const name = event.target.value;
        this.setState(
            (state) => {
                state.view.fields.find((item) => item.name === name).included = checked;
                return state;
            },
            () => {
                this.handleViewChange();
            },
        );
    };

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(
            (state) => {
                const { view } = state;
                view.fields = arrayMove(view.fields, oldIndex, newIndex);
                return { view: { ...view } };
            },
            () => {
                this.handleViewChange();
            },
        );
    };

    handleResetPersonalSettings = () => {
        this.setState(
            {
                view: this.getViewFromProps(true),
            },
            () => {
                this.handleViewChange();
            },
        );
    };

    handleViewChange = () => {
        this.setState((state) => {
            const recordView = this.stateViewToRecordView(state.view);
            const defaultSettings = state.entitySettings?.recordView;
            const usingDefaultSettings =
                JSON.stringify(recordView) ===
                JSON.stringify(defaultSettings === null ? { fields: [] } : defaultSettings);
            return { usingDefaultSettings };
        });
    };

    handleOpenFieldsListModal = () => {
        this.setState({
            fieldsListModal: true,
        });
    };

    handleFieldsListModalSave = () => {
        this.fieldsListForm.current.submit();
    };

    handleFieldsListModalSubmit = (fields) => {
        this.setState(
            (state) => {
                const { view } = state;
                let collection = cloneDeep(view.fields);
                for (let field of fields) {
                    const item = collection.find((item) => item.name === field.name);
                    item.included = field.included;
                }
                collection.sort(sortByIncluded);
                view.fields = collection;
                return {
                    view: { ...view },
                    fieldsListModal: false,
                };
            },
            () => {
                this.handleViewChange();
            },
        );
    };

    handleFieldsListModalCancel = () => {
        this.setState({
            fieldsListModal: false,
        });
    };

    handleFieldRemove = (item) => {
        this.setState(
            (state) => {
                const { view } = state;
                let collection = cloneDeep(view.fields);
                collection.find((field) => field.name === item.name).included = false;
                collection.sort(sortByIncluded);
                view.fields = collection;
                return { view: { ...view } };
            },
            () => {
                this.handleViewChange();
            },
        );
    };

    render() {
        const { entity, onOpenPersonalSettings, showPersonalSettings, t } = this.props;
        const { loading, view, fieldsListModal, usingDefaultSettings } = this.state;
        if (view === null) {
            return <FormBackdrop loading task={t('loading')} />;
        }
        const linkToGeneralEntitySettings = buildLinkToGeneralEntitySettings(entity);

        let fieldsListToSearch = view.fields ? cloneDeep(view.fields) : [];
        fieldsListToSearch.sort(sortByIncluded).sort(sortNotIncludedAlphabetically);

        const fieldsList = view.fields.filter((field) => field.included);

        return (
            <FormBackdrop loading={loading}>
                <Grid container alignItems="center">
                    {!showPersonalSettings && (
                        <EditDefaultValuesBlock
                            mainText={t('record_view_settings.default_properties.warning')}
                            dottedText={t('record_view_settings.default_properties.switch')}
                            onSwitch={onOpenPersonalSettings}
                            articleNumber={'4361112'}
                        />
                    )}
                    <Grid container item xs alignItems="center" wrap="nowrap">
                        <Grid item>
                            <FormLabel component="legend">{t('record_view_settings.fields_to_show')}</FormLabel>
                        </Grid>
                        <Grid item>
                            &nbsp;
                            <Tooltip title={t('record_view_settings.add_remove_fields')}>
                                <AddIconButton
                                    small
                                    onClick={this.handleOpenFieldsListModal}
                                    className="c-record-view-settings__fields_list_button"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        {linkToGeneralEntitySettings && !weAreInNativeApp() && (
                            <div style={{ display: 'flex' }}>
                                <i
                                    style={{ marginRight: 10, fontSize: '20px', color: indigo[500] }}
                                    className="fas fa-info-circle"
                                />
                                <div>
                                    <p style={{ marginBlock: 0 }}>{t('record_view_settings.add_fields.question')}</p>
                                    <p style={{ marginBlock: 0 }}>
                                        <Link style={{ cursor: 'pointer' }} to={linkToGeneralEntitySettings}>
                                            {t('record_view_settings.add_fields.link')}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <FormControl component="fieldset">
                    {fieldsList.length > 0 ? (
                        <SortableList
                            helperClass="sortable-helper"
                            hideSortableGhost={false}
                            items={fieldsList}
                            onFieldRemove={this.handleFieldRemove}
                            onSortEnd={this.handleSortEnd}
                            onIncludedChange={this.handleFieldIncludedChange}
                            useDragHandle={true}
                        />
                    ) : (
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ textAlign: 'center', paddingTop: 15 }}
                        >
                            {t('record_view_settings.no_fields_selected')}
                        </Typography>
                    )}
                </FormControl>
                {fieldsListModal && (
                    <PureFormDialog
                        title={t('record_view_settings.add_remove_fields')}
                        onClose={this.handleFieldsListModalCancel}
                        maxWidth="sm"
                        fullWidth
                        open
                        actions={
                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={this.handleFieldsListModalCancel}
                                    data-testid="record_view_settings.add_remove_fields.button.cancel"
                                >
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={this.handleFieldsListModalSave}
                                    data-testid="record_view_settings.add_remove_fields.button.ok"
                                >
                                    {t('button.ok')}
                                </Button>
                            </DialogActions>
                        }
                    >
                        <SearchableFieldsListForm
                            ref={this.fieldsListForm}
                            onSubmitSuccess={this.handleFieldsListModalSubmit}
                            fields={fieldsListToSearch || []}
                        />
                    </PureFormDialog>
                )}
                <Grid container alignItems="center">
                    <Grid item container spacing={1} justify="flex-start" sm={9}>
                        {showPersonalSettings && (
                            <Grid item>
                                {usingDefaultSettings && (
                                    <Typography>
                                        {t('record_view_settings.using_default')}
                                        &nbsp;
                                        <Hint>{t('record_view_settings.using_default.tooltip')}</Hint>
                                    </Typography>
                                )}
                                {!usingDefaultSettings && (
                                    <>
                                        <DottedLink
                                            onClick={this.handleResetPersonalSettings}
                                            data-testid="record_view_settings.reset_settings"
                                        >
                                            {t('record_view_settings.reset_settings')}
                                        </DottedLink>
                                        &nbsp;
                                        <Hint>{t('record_view_settings.reset_settings.tooltip')}</Hint>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item sm={3}>
                        <FormActions>
                            <Button
                                color="primary"
                                onClick={this.handleSave}
                                data-testid="record_view_settings.button.save"
                            >
                                {t('button.save')}
                            </Button>
                        </FormActions>
                    </Grid>
                </Grid>
            </FormBackdrop>
        );
    }
}

RecordViewSettings.propTypes = {
    accountId: PropTypes.number.isRequired,
    entity: PropTypes.object.isRequired,
    showPersonalSettings: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onOpenGlobalSettings: PropTypes.func.isRequired,
    onOpenPersonalSettings: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func,
};

class RecordViewSettingsTitle extends React.PureComponent {
    render() {
        const { entity, showPersonalSettings, t } = this.props;
        const linkToGeneralEntitySettings = buildLinkToGeneralEntitySettings(entity);

        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item xs className="c-record-view-settings__title">
                    {t('record_view_settings.properties.title', { label: entity.label })}
                </Grid>
                {userManager.isRoleAdmin() && !weAreInNativeApp() && (
                    <Grid item container xs className="c-record-view-settings__title-buttons">
                        <Grid item>
                            <MenuItem
                                component={Link}
                                to={linkToGeneralEntitySettings}
                                className="c-record-view-settings__settings-link"
                                data-testid="record_view_settings.all_fields"
                            >
                                <span>{t('record_view_settings.all_fields')}</span>
                            </MenuItem>
                        </Grid>
                        <Grid item>
                            <MenuItem
                                onClick={
                                    showPersonalSettings
                                        ? this.props.onOpenGlobalSettings
                                        : this.props.onOpenPersonalSettings
                                }
                                className="c-record-view-settings__settings-link"
                                data-testid={
                                    showPersonalSettings
                                        ? 'record_view_settings.default_properties'
                                        : 'record_view_settings.personal_properties'
                                }
                            >
                                <span>
                                    {t(
                                        showPersonalSettings
                                            ? 'record_view_settings.default_properties'
                                            : 'record_view_settings.personal_properties',
                                    )}
                                </span>
                            </MenuItem>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

RecordViewSettingsTitle.propTypes = {
    entity: PropTypes.object.isRequired,
    showPersonalSettings: PropTypes.bool.isRequired,
    onOpenGlobalSettings: PropTypes.func.isRequired,
    onOpenPersonalSettings: PropTypes.func.isRequired,
};

const TranslatedRecordViewSettingsTitle = withTranslation('translations', { withRef: true })(RecordViewSettingsTitle);

export { TranslatedRecordViewSettingsTitle as RecordViewSettingsTitle };

export default withTranslation('translations', { withRef: true })(RecordViewSettings);
