import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Button,
    ListSubheader,
    MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../locales/i18n';
import AbstractForm from './AbstractForm';
import Form from '../../../../service/workflow_actions/forms/Form';
import PickList, { TYPES } from '../../../../service/workflow_actions/forms/fields/PickList';
import Hint from '../../../Hint';
import ParamsList from '../../AbstractForm/ParamsList';
import FormulaInput from '../../AbstractForm/FormulaInput';
import { TYPE_DEFAULT_ACTION_GROUP } from '../../../../service/WorkflowGroupManager';
import WorkflowGroups from '../../../WorkflowGroups/WorkflowGroups';
import { CallContext } from '../../../utils/CallContext';
import FormControlSelect from '../../AbstractForm/FormControlSelect';

const t = i18n.t.bind(i18n);

class PickListForm extends AbstractForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            picklistEntity: null,
        };
    }

    componentDidMount() {
        if (this.state.field.getItemsEntityId()) {
            const picklistEntity = this.getEntityById(this.state.field.getItemsEntityId());
            this.setState({ picklistEntity });
        }
    }

    validate() {
        const field = this.state.field;

        if (field.getItemsType() === TYPES.FIELD) {
            if (!field.getItemsEntityId()) {
                this.state.errors.set('itemsEntityId', t('errors.not_empty'));
            }
            if (!field.getItemsFieldId()) {
                this.state.errors.set('itemsFieldId', t('errors.not_empty'));
            }
        }
    }

    prepareEntityOptions = () => {
        const options = [];
        for (const dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (const entity of dataSource.entities) {
                options.push(
                    <MenuItem key={entity.id} value={entity.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    };

    preparePicklistOptions = () => {
        const options = [];

        if (!this.state.picklistEntity) {
            return options;
        }

        for (const field of this.state.picklistEntity.fields) {
            if (field.hasPicklist && !field.isSystemVisible) {
                options.push(
                    <MenuItem key={field.id} value={field.id}>
                        {field.label}
                    </MenuItem>,
                );
            }
        }

        return options;
    };

    preparePicklistItems = (field) => {
        if (field.getItemsEntityId() && field.getItemsFieldId() && this.state.picklistEntity) {
            const picklistField = this.state.picklistEntity.fields.find((item) => item.id === field.getItemsFieldId());
            if (picklistField) {
                return picklistField.picklist || [];
            }
        }

        return field.getItems();
    };

    changeItemsInput = (type) => {
        if (type === TYPES.FIELD && this.state.field.getItemsFieldId()) {
            const field = this.getFieldById(this.state.field.getItemsFieldId());
            const picklist = field?.picklist || [];
            this.handleInputChange({
                target: { name: 'items', value: picklist },
            });
        }
        this.handleInputChange({
            target: {
                name: 'itemsType',
                value: type,
            },
        });
    };

    handleEntityChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (value) {
            const picklistEntity = this.getEntityById(value);
            this.setState({ picklistEntity });
        } else {
            this.setState({ picklistEntity: null });
        }

        this.handleInputChange({
            target: { name, value },
        });
    };

    handleFieldChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const field = this.getFieldById(value);
        const picklist = field?.picklist || [];

        this.handleInputChange({
            target: { name, value },
        });
        this.handleInputChange({
            target: { name: 'items', value: picklist },
        });
    };

    getEntityById = (entityId) => {
        for (const dataSource of this.props.dataSources) {
            for (const entity of dataSource.entities) {
                if (entity.id === entityId) {
                    return { ...entity, dsId: dataSource.id };
                }
            }
        }
        return null;
    };

    getFieldById = (fieldId) => {
        if (!this.state.picklistEntity || !fieldId) {
            return null;
        }
        for (const field of this.state.picklistEntity.fields) {
            if (field.id === fieldId) {
                return field;
            }
        }
        return null;
    };

    renderPicklistValue = (options) => (value) => {
        if (options.length === 0) {
            return t('workflow_actions.forms.field.picklist.items.field.no_picklist_fields');
        }
        const field = this.getFieldById(value);
        return field?.label;
    };

    renderItemsInput(field, errors) {
        if (field.getItemsType() === TYPES.STATIC) {
            return (
                <Grid item xs={12}>
                    <ParamsList
                        items={field.getItems()}
                        title={t('workflow_actions.forms.field.picklist.items')}
                        errors={errors.get('items')}
                        name="items"
                        onChange={this.handleInputChange}
                        nameKey="label"
                        valueKey="value"
                        nameTitle={t('workflow_actions.forms.field.picklist.items.fixed.label')}
                        valueTitle={t('workflow_actions.forms.field.picklist.items.fixed.value')}
                        helperText={
                            <>
                                <Button
                                    color="primary"
                                    onClick={this.changeItemsInput.bind(this, TYPES.FIELD)}
                                    data-testid="workflow_actions.forms.field.picklist.items.field"
                                >
                                    {t('workflow_actions.forms.field.picklist.items.field')}
                                </Button>{' '}
                                <Hint type="tooltip">
                                    {t('workflow_actions.forms.field.picklist.items.field.hint')}
                                </Hint>
                            </>
                        }
                        callContext={this.props.callContext}
                    />
                </Grid>
            );
        } else if (field.getItemsType() === TYPES.FIELD) {
            const picklistOptions = this.preparePicklistOptions();
            return (
                <Grid item xs={12}>
                    <ParamsList
                        items={this.preparePicklistItems(field)}
                        title={t('workflow_actions.forms.field.picklist.items')}
                        errors={errors.get('items')}
                        name="items"
                        onChange={this.handleInputChange}
                        nameKey="label"
                        valueKey="value"
                        nameTitle={t('workflow_actions.forms.field.picklist.items.fixed.label')}
                        valueTitle={t('workflow_actions.forms.field.picklist.items.fixed.value')}
                        helperText={
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        onClick={this.changeItemsInput.bind(this, TYPES.FORMULA)}
                                        data-testid="workflow_actions.forms.field.picklist.formula.fixed"
                                    >
                                        {t('workflow_actions.forms.field.picklist.items.formula')}
                                    </Button>{' '}
                                    <Hint type="tooltip">
                                        {t('workflow_actions.forms.field.picklist.items.formula.hint')}
                                    </Hint>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlSelect
                                        margin="none"
                                        label={t('workflow_actions.forms.field.picklist.items.field.object')}
                                        name="itemsEntityId"
                                        value={field.getItemsEntityId() || '0'}
                                        onChange={this.handleEntityChange}
                                        fullWidth
                                        error={errors.has('itemsEntityId')}
                                        helperText={errors.get('itemsEntityId')}
                                        data-testid="workflow_actions.forms.field.picklist.items.field.object"
                                        style={{ marginBottom: 16 }}
                                    >
                                        {this.prepareEntityOptions()}
                                    </FormControlSelect>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlSelect
                                        margin="none"
                                        label={t('workflow_actions.forms.field.picklist.items.field.picklist_field')}
                                        name="itemsFieldId"
                                        value={field.getItemsFieldId() || '0'}
                                        onChange={this.handleFieldChange}
                                        renderValue={this.renderPicklistValue(picklistOptions)}
                                        fullWidth
                                        error={errors.has('itemsFieldId')}
                                        helperText={errors.get('itemsFieldId')}
                                        disabled={!this.state.picklistEntity || picklistOptions.length === 0}
                                        data-testid="workflow_actions.forms.field.picklist.items.field.picklist_field"
                                        style={{ marginBottom: 16 }}
                                    >
                                        {picklistOptions}
                                    </FormControlSelect>
                                </Grid>
                            </Grid>
                        }
                        callContext={this.props.callContext}
                        disabled
                        hideAddButton
                    />
                </Grid>
            );
        }

        return (
            <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                    <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                        <Grid item>
                            <b>{t('workflow_actions.forms.field.picklist.items')}</b>{' '}
                            <Button
                                color="primary"
                                onClick={this.changeItemsInput.bind(this, TYPES.STATIC)}
                                data-testid="workflow_actions.forms.field.picklist.items.fixed"
                            >
                                {t('workflow_actions.forms.field.picklist.items.fixed')}
                            </Button>{' '}
                            <Hint type="tooltip">{t('workflow_actions.forms.field.picklist.items.fixed.hint')}</Hint>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormulaInput
                            fullWidth
                            margin="dense"
                            required
                            multiline
                            name="itemsFormula"
                            value={field.getItemsFormula()}
                            onChange={this.handleInputChange}
                            error={errors.has('itemsFormula')}
                            helperText={errors.get('itemsFormula')}
                            callContext={this.props.callContext}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel position="bottom">
                            {t('workflow_actions.forms.field.picklist.items.formula.description')}
                            <Box fontFamily="Monospace">
                                {'[{"label":"My Item 1","value":"item1"},{"label":"My Item 2","value":"item2"}]'}
                            </Box>
                        </FormLabel>
                    </Grid>
                </FormControl>
            </Grid>
        );
    }

    renderFieldInputs(field, errors) {
        return (
            <>
                {this.renderIsRequiredInput(field, errors)}
                {this.renderIsHiddenInput(field, errors)}
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isMultiple"
                                checked={field.isMultiple}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.forms.field.picklist.isMultiple')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isAlphabeticallySorted"
                                checked={field.isAlphabeticallySorted}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                {t('workflow_actions.forms.field.picklist.isAlphabeticallySorted')}{' '}
                                <Hint type="tooltip">
                                    {t('workflow_actions.forms.field.picklist.isAlphabeticallySorted.hint')}
                                </Hint>
                            </span>
                        }
                    />
                </Grid>
                {this.renderItemsInput(field, errors)}
                <WorkflowGroups
                    name="handlers"
                    title={t('workflow_actions.forms.field.actions.onChange')}
                    placeholder={t('workflow_actions.forms.field.actions.placeholder')}
                    parentNamespace={this.props.action.namespace}
                    groupType={TYPE_DEFAULT_ACTION_GROUP}
                    groups={field.getHandlers()}
                    errors={this.state.errors}
                    onChange={this.handlerHandlersChange}
                    account={this.props.account}
                    dataSources={this.props.dataSources}
                    callContext={this.props.callContext}
                />
            </>
        );
    }
}

PickListForm.propTypes = {
    account: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    callContext: PropTypes.instanceOf(CallContext).isRequired,

    form: PropTypes.instanceOf(Form).isRequired,
    field: PropTypes.instanceOf(PickList).isRequired,
};

export default PickListForm;
