import { Api, Common, Geo, Routing, User } from 'interfaces';
import { UsersTodayStartLocationAndTime } from 'components/RoutingTodayUsersStartModal';
import { PointObjectSettingList } from '../../components/RouteEditor/types';
import { NullableGeoPoint } from '../geo';

export interface Entity {
    entityId: number;
    recordId: string;
}

export interface EntityNotSafeGeoPoint extends Routing.Route.Entity, Geo.NullableGeoPoint {}

export interface EntityPoint extends Routing.Route.Entity, Geo.GeoPoint {}

export interface Leg {
    index: number;
    time: number;
    distance: number;
    shapes: LegShape[];
}

export interface LegShape {
    positions: Geo.GeoPoint[];
    violation?: boolean;
}

export interface BuildRouteResult {
    // result: RouteResult | null,
    warning: Error | string | null;
}

export interface VehicleProfile {
    truckType?: string;
    type?: string;
    name?: string;
    trailersCount?: number | null;
    axleCount?: number | null;
    limitedWeight?: number | null;
    weightPerAxle?: number | null;
    weightsPerSingleAxleGroup?: number | null;
    weightsPerTandemAxleGroup?: number | null;
    weightsPerAxleGroup?: { [key: string]: number };
    height?: number | null;
    width?: number | null;
    length?: number | null;
    avoid?: { [key: string]: string };
    shippedHazardousGoods?: string[];
    tunnelCategory?: string | null;
}

export type UsersTodayRoutesResponse = {
    [userId: string]: {
        id: number;
        defaultLocation: UserLocationPoint;
        lastLocation: UsersTodayLocation;
        name: string;
        routes: Api.Routing.Route.RouteApiResponse[];
        timezone: string;
        workDay: any;
    };
};

export type UserTodayRoutes = {
    id: number;
    defaultLocation: UserLocationPoint;
    lastLocation: UsersTodayLocation;
    name: string;
    routes: Route[];
    timezone: string;
    workDay: any;
};

export type UsersTodayRoutes = {
    [userId: string]: UserTodayRoutes;
};

export interface SaveExistsRouteData {
    name: string;
    userId: number;
    dateStartAt: Date;
}

export interface PublishAsRouteData extends SaveExistsRouteData {}

export interface RouteInput extends Routing.Session.SessionInput {}

export type Activity = ActivityEntityPoint | ActivitySimplePoint;

export interface ActivityInfo extends Routing.Route.PointTitle, Routing.Route.PointAddress, ActivityTypedPoint {
    id: string;
    leg: Leg | null;
    locationId: string;
    arrivalTime: Date | null;
    expectedArrivalTime: Date | null;
    departureTime: Date | null;
    waitingTime: number;
    preparationTime: number;
    serviceDuration: number;
    departureDelay?: number;
    distanceKm: number;
    drivingTime: number;
    brokenAvailabilityConstraint: boolean;
    isLocationless: boolean;
    earlyLateTime?: number;
    job?: RouteActivityJob | null;
    activityStatusId?: string;
}

export interface ActivityTypedPoint extends Geo.GeoPoint {
    type: ActivityType;
}

export interface ActivityEntityPoint extends ActivitySimplePoint, Entity {}

export interface ActivityProspectingPoint extends ActivitySimplePoint {
    recordId: string;
}

export interface ActivitySimplePoint extends ActivityInfo {}

export enum ActivityType {
    START = 'start',
    END = 'end',
    SERVICE = 'service',
    PICKUP_SHIPMENT = 'pickupShipment',
    DELIVER_SHIPMENT = 'deliverShipment',
    PICKUP = 'pickup',
    DELIVERY = 'delivery',
    PICKUP_DELIVERY = 'pickup-delivery',
    BREAK = 'break',
}

export interface MyLocation {
    buttonType: MyLocationType;
    point: Routing.Route.UserLocationPoint | null;
}

export enum MyLocationType {
    CurrentLocation = 'currentLocation',
    Home = 'home',
    Manually = 'manually',
    Favorites = 'favorites',
    Finish = 'finish',
    Switch = 'switch',
}

export enum RouteType {
    SIMPLE = 1,
    ADVANCED = 2,
}

export enum RouteStatus {
    DRAFT = 0,
    PUBLISHED = 1,
}

export enum LegacyTravelMode {
    FASTEST = 'fastest',
    SHORTEST = 'shortest',
    TRUCK_FASTEST = 'truck_fastest',
    TRUCK_SHORTEST = 'truck_shortest',
    SCOOTER = 'scooter',
    BICYCLE = 'bicycle',
    PEDESTRIAN = 'pedestrian',
}

export interface PointTitle {
    objectName: string;
}

export interface PointCountryShortAddress {
    countryShort: string | null;
}

export interface PointAddress extends PointCountryShortAddress {
    address: string | null;
    addressFields: Geo.AddressFields;
}

export interface DesignConfig {
    addCalendarEventsToRoute: boolean;
    includeDraftEvents: boolean;
    roundStartTimes: boolean;
    roundStartTimesValue: RoundTimeStep;
    roundBreakStartTime: boolean;
    ignoreConstraints: boolean;
    replaceRoutes: boolean;
    daysPeriod: number;
    usersIds: number[];
    objective: string;
    todayConfig: UsersTodayStartLocationAndTime[];
    travelMode: Routing.TravelMode.TravelMode;
    useTraffic: boolean;
    vehicleProfile: Routing.Route.VehicleProfile;
    optimal: boolean;
    continuousMode: boolean;
    splitJobsLongerThan: number | null;
    maxOvertime: number | null;
}

export type DesignRoutePointsArray = Array<Routing.Route.DesignRoutePoint>;

export interface DesignInputEntityPoint extends Routing.Route.EntityPointWithAddress {}

export interface EntityPointWithAddress extends Routing.Route.PointTitle, Routing.Route.Entity, DesignSimplePoint {}

export interface DesignSimplePoint extends PointTitle, Geo.NullableGeoPointAddress {
    pointType?: PointType;
    geoStatus?: number;
}
export interface DesignEntityPoint extends DesignSimplePoint, Routing.Route.Entity {}

export interface DesignProspectPoint extends DesignSimplePoint {
    recordId: string;
}

export interface DesignEventPoint extends DesignSimplePoint {
    calendarEvent: DesignPointCalendarEvent;
}

export interface DesignPointCalendarEvent {
    id: string;
    startDatetime: string;
    endDatetime: string;
    duration: number;
    fixedTime: boolean;
}

export type DesignCombinedSimplePoint = DesignSimplePoint | DesignProspectPoint | DesignEventPoint;

export interface DesignRouteSimplePoint extends Geo.NullableGeoPointAddress, Routing.Route.PointTitle {
    index: number;
    /** @see GeoPointKey.getKeyByPoint */
    key: string;
    duration: number;
    preparationTime: number;
    departureDelay: number;
    type: Routing.Route.ActivityType;
    pointType?: PointType;
    geoStatus?: number;
    objectSettings?: any;
}

export interface DesignRouteEntityPoint extends DesignRouteSimplePoint, Routing.Route.Entity {}

export interface DesignRouteEventPoint extends DesignRouteSimplePoint {
    calendarEvent: DesignPointCalendarEvent;
}

export interface DesignRouteProspectPoint extends DesignRouteSimplePoint {
    recordId: string;
}

export type DesignAddPoint = Routing.Route.EntityPoint | Geo.NullableGeoPoint;
export type DesignDeletePoint = (Routing.Route.EntityPoint | Geo.NullableGeoPoint) & { index: number | null };
export type DesignUpdatePoint = DesignAddPoint & { index: number };

export type DesignRoutePoint =
    | DesignRouteEntityPoint
    | DesignRouteProspectPoint
    | DesignRouteSimplePoint
    | DesignRouteEventPoint;
export type DesignPoint = DesignEntityPoint | DesignProspectPoint | DesignSimplePoint | DesignEventPoint;

export interface RouteResult {
    // legs: Leg[];
    unit: Common.DistanceMetric;
    distance: number | null;
    time: number | null;
    // nodes: number[];
    boundingBox: BoundingBox;
    shape: number[][];
    hasViolations: boolean;
    image: string;
}

type BoundingBox = {
    topLeft: {
        latitude: number;
        longitude: number;
    };
    bottomRight: {
        latitude: number;
        longitude: number;
    };
};

export interface PointForBuild extends Geo.NullableGeoPoint, Routing.Route.PointAddress, Routing.Route.PointTitle {
    entityId: number | null;
    id: string | null; // TODO rename to recordId
    objectName: string;
    objectSettings?: PointObjectSettingList;
    pointType: PointType;
}

export interface SimpleRoute {
    id: string;
    routingSessionId: string;
    name: string;
    dateStartAt: Date;
    distanceKm: number;
    distanceMi: number;
    totalDuration: number;
    transportTime: number | null;
    waitingTime: number | null;
    serviceDuration: number | null;
    preparationTime: number | null;
    noPoints: number;
    user: User.SimpleUser;
}

/**
 * Роут может существовать только в статусе draft & published сессии.
 */
export interface Route extends SimpleRoute {
    travelMode: Routing.Settings.VehicleTypeTravelMode;
    user: User.User;
    plannerApiName: string | null;

    result: Routing.Route.RouteResult | null;

    // Advanced routing
    input: Routing.Route.RouteInput;
    routeType: Routing.Route.RouteType;
    status: Routing.Route.RouteStatus;
    vehicleId: number | null;
    activities: Activity[];
    actionMode: ActionMode | null;
    isDraftDeleted: boolean;
    originalRoute: Routing.Route.Route | null;
}

export interface UnassignedJob extends PointTitle, NullableGeoPoint, PointAddress {
    id: string;
    entityId: number | null;
    recordId: string | null;
    errorDetail: UnassignedJobErrorDetail | null;
    type: ActivityType;
}

export interface RouteActivityJob {
    id: string;
    breakJob: boolean;
    locationless: boolean;
    virtual: boolean;
}

interface UnassignedJobErrorDetail {
    code: number;
    reason: string;
}

export interface PseudoRoute extends Pick<Route, 'user' | 'dateStartAt'> {
    activities: Activity[] | DesignRoutePoint[] | UnassignedJob[];
}

export enum EditModeAddPointManuallyAction {
    ADD_BEFORE = 'add_before',
    ADD_AFTER = 'add_after',
}

export interface UserLocationPoint extends Geo.GeoPointAddress {
    entityId: number | null;
    id: string | null;
    objectName: string | null;
    /** @deprecated */
    uuid: string;
    objectSettings?: PointObjectSettingList;
}

export interface UsersTodayLocation {
    entityId: number | null;
    id: string | null;
    objectName: string | null;
    address: Geo.GeoPointAddress | null;
    time: string | null;
}

export interface LoadedSessionRoute {
    session: Routing.Session.Session;
    route: Routing.Route.Route | null;
}

export interface CurrentSession {
    session: Routing.Session.Session;
    routes: Routing.Route.Route[];
}

export interface ActionMode {
    action: ActionModeAction;
    userId: number;
    originalRouteId?: string;
}

export enum ActionModeAction {
    REBUILD = 'rebuildMode',
    EDIT = 'editMode',
    TRIP = 'tripMode',
}

export enum PointType {
    SIMPLE_POINT = 'simple_point',
    ENTITY_POINT = 'entity_point',
    EVENT_POINT = 'event_point',
    PROSPECTING_POINT = 'prospecting_point',
}

export enum RoundTimeStep {
    STEP_5 = 5,
    STEP_10 = 10,
    STEP_15 = 15,
    STEP_30 = 30,
}

export type RouteActivityStatusList = Map<string, RouteActivityStatus>;

export interface RouteActivityStatus {
    id: string;
    name: string;
    icon: string | null;
    backgroundColor: string | null;
    textColor: string | null;
    isFinal: boolean | null;
}
