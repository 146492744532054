import { IconButton, Popover, PopoverProps, Tooltip } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { filterStringValueToMatchType, propertyIdToName, propertyNameToId } from '../../utils';
import FieldFactory from '../LiveUpdateTableForm/FieldFactory';
import {
    FieldLookupData,
    FieldLookupType,
    FieldPolymorphicLookupData,
    FieldType,
    IField,
    MultiPicklistValue,
    PicklistValue,
} from '../types';

import '../Alert/style.css';
import { emailValidate } from '../../service/emailValidator';

interface InPlaceEditProps extends WithTranslation {
    accountId: number;
    anchorEl?: PopoverProps['anchorEl'];
    dataSourceId: number;
    recordId: string;
    property: IField & {
        apiName: string;
        isLink: boolean;
        isLookup: boolean;
        isReadOnly: boolean;
        label: string;
        length?: number;
        lookupData: FieldLookupData | FieldPolymorphicLookupData;
        originalApiName?: string;
        picklist?: null | MultiPicklistValue[] | PicklistValue[];
        type: FieldType;
        value: any;
    };
    value: any;
    /** all values of record */
    values: Record<string, unknown>;
    updates: Record<string, any>;
    onSave: (update: Record<string, any>) => void;
    onClose: () => void;
}

interface InPlaceEditState {
    value: any;
    updates: Record<string, any>;
    disablePopoverButtons: boolean;
    hasChanged: boolean;
    hasErrors: boolean;
}

class InPlaceEdit extends React.PureComponent<InPlaceEditProps, InPlaceEditState> {
    constructor(props: Readonly<InPlaceEditProps>) {
        super(props);

        const { property, updates, value, values } = props;
        const { apiName } = property;

        const currentUpdates = { ...updates };

        for (const key in currentUpdates) {
            if (currentUpdates[key].value) {
                currentUpdates[key] = currentUpdates[key].value;
            }
        }
        const currentValues = { ...values, ...currentUpdates };

        const currentValue = currentValues[apiName];
        const nextValue = currentValue ? cloneDeep(currentValue.textValue || currentValue.value) : cloneDeep(value);

        if (apiName in currentUpdates && nextValue) {
            currentUpdates[apiName] = nextValue;
        }

        this.state = {
            value: nextValue,
            updates: currentUpdates,
            disablePopoverButtons: false,
            hasChanged: false,
            hasErrors: false,
        };
    }

    handleSave = () => {
        const { value, updates, hasErrors } = this.state;
        const { property } = this.props;

        if (hasErrors) {
            return;
        }

        if (property.isLookup && property.lookupData.type === FieldLookupType.FEW) {
            const apiName = property.apiName;
            const idApiName = propertyNameToId(apiName);
            const valueApiName = propertyIdToName(apiName);

            const update = {
                ...this.state.updates,
                [idApiName]: {
                    apiName: property.apiName,
                    value: updates[idApiName],
                },
                [valueApiName]: {
                    apiName: property.apiName,
                    value: updates[valueApiName],
                },
            };

            return this.props.onSave(update);
        }

        const isObject = typeof value === 'object' && value !== null && !Array.isArray(value);
        const isFiles = Array.isArray(value) && property.type === FieldType.FILES;

        let result;
        let actualResult;
        if (Array.isArray(property.picklist)) {
            // ← there is typo in EntityLayerPoints.js, so it might be buggy
            result = value;
        } else if (isObject) {
            if (property.lookupData.type === FieldLookupType.POLYMORPHIC) {
                result = JSON.stringify({
                    object: value.apiName,
                    recordId: value.id,
                });
            } else {
                result = value.id ? value.id : value;
            }
        } else if (isFiles) {
            result = value.filter((file: any) => file.id);
            actualResult = result.map((file: any) => file.id);
        } else {
            result = filterStringValueToMatchType(value, property.type);
        }

        const field: Partial<IField> & { textValue: string | null; actualValue?: any[] } = {
            apiName: property.apiName,
            originalApiName: property.originalApiName,
            value: result,
            textValue: isObject ? value.textValue : null,
        };
        if (actualResult) {
            field.actualValue = actualResult;
        }

        const update = {
            ...this.state.updates,
            [property.apiName]: field,
        };

        this.props.onSave(update);
    };

    handleChange = (updates: InPlaceEditState['value']) => {
        const value = updates[this.props.property.apiName];

        this.setState({ value, hasChanged: true, updates });
    };

    handleFieldLoading = (loading: boolean) => {
        this.setState({ disablePopoverButtons: loading });
    };

    handleEmailValidate = (tags: string[]): void => {
        let hasErrors = false;
        for (let i = 0; i < tags.length; i++) {
            if (emailValidate(tags[i])) {
                continue;
            }

            hasErrors = true;
            break;
        }

        this.setState({ hasErrors });
    };

    render() {
        const { accountId, anchorEl, property, dataSourceId, t, values } = this.props;
        const { value, disablePopoverButtons, hasChanged, updates, hasErrors } = this.state;

        return (
            <Popover
                open={true}
                anchorEl={anchorEl}
                onClose={this.props.onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="pin-editable-popover">
                    <FieldFactory
                        accountId={accountId}
                        dataSourceId={dataSourceId}
                        onChange={this.handleChange}
                        value={value}
                        values={values}
                        updates={updates}
                        field={property}
                        onFieldLoading={this.handleFieldLoading}
                        onEmailValidate={this.handleEmailValidate}
                    />
                    <Tooltip title={t('map_page.view_record.edit.save')}>
                        <div>
                            <IconButton
                                onClick={this.handleSave}
                                disabled={disablePopoverButtons || !hasChanged || hasErrors}
                                className="pin-save"
                                data-testid="map_page.view_record.edit.save"
                            >
                                <i className="fa fa-check" />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title={t('map_page.view_record.edit.cancel')}>
                        <div>
                            <IconButton
                                onClick={this.props.onClose}
                                disabled={disablePopoverButtons}
                                className="pin-cancel"
                                data-testid="map_page.view_record.edit.cancel"
                            >
                                <i className="fa fa-times" />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </Popover>
        );
    }
}

export default withTranslation()(InPlaceEdit);
