import {
    Box,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    withStyles,
    ClickAwayListener,
} from '@material-ui/core';
import PureFormDialog from 'components/PureFormDialog';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { accountsManager } from 'service/AccountsManager';
import { roleManager } from 'service/RoleManager';
import { userManager } from 'service/UserManager';
import { UserData, UserDataRole } from 'service/types';
import { withSnackbar, WithSnackbarProps } from 'notistack';

interface Props extends WithTranslation, WithSnackbarProps {
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
}

const CalendarsUserGroupsDialog: React.FC<Props> = ({ t, isOpen, onClose, isMobile, enqueueSnackbar }) => {
    const users: UserData[] = [...userManager.users].map((user) => user[1]);

    const account = userManager.getCurrentAccount();
    const user = userManager.getCurrentAccount();

    const [showDropdown, setShowDropdown] = useState(false);
    const [showRolesDropdown, setShowRolesDropdown] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [selectedUsers, setSelectedUsers] = useState<UserData[]>([]);
    const [groupName, setGroupName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isGroupNameError, setGroupNameError] = useState(false);
    const [isSearchTermError, setSearchTermError] = useState(false);
    const [roles, setRoles] = useState<UserDataRole[]>([]);
    const [isSending, setSending] = useState(false);

    useEffect(() => {
        roleManager.getAccountRoles(user.id).then((res: UserDataRole[]) => {
            setRoles(res.filter((el: UserDataRole) => el.code !== 'shared_map_default'));
        });
    }, [user.id]);

    const clearDatas = () => {
        setSending(false);
        setGroupName('');
        setGroupNameError(false);
        setSelectedUsers([]);
        setSearchTermError(false);
    };

    const handleClose = () => {
        clearDatas();
        onClose();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTermError(false);
        setSearchTerm(value);
        if (!!value) {
            const filtered = users
                .filter(
                    (user) =>
                        user.name.toLowerCase().includes(value.toLowerCase()) &&
                        !selectedUsers.some((selectedUser) => selectedUser.id === user.id),
                )
                .slice(0, 4);
            setFilteredUsers(filtered);
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const handleSelectUser = (users: UserData[]) => {
        const newUsers = users.filter((user) => !selectedUsers.some((selectedUser) => selectedUser.id === user.id));
        const sortAlphaNum = (a: UserData, b: UserData) => a.name.localeCompare(b.name, 'en', { numeric: true });
        setSelectedUsers([...selectedUsers, ...newUsers].sort(sortAlphaNum));
    };

    const handleGroupeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(e.target.value);
        setGroupNameError(false);
    };

    const handleAddUser = (user: UserData) => {
        handleSelectUser([user]);
        setSearchTerm('');
        setShowDropdown(false);
    };

    const handleAddAllUsers = () => {
        setShowRolesDropdown(!showRolesDropdown);
    };

    const handleAddRole = (role: UserDataRole) => {
        const code = role.code;
        const filteredUsers = users.filter((user) => user.role.code === code);
        handleSelectUser(filteredUsers);
        setShowRolesDropdown(false);
    };

    const handleSubmit = async () => {
        if (!groupName) {
            setGroupNameError(true);
            return;
        }
        if (!selectedUsers.length) {
            setSearchTermError(true);
            return;
        }
        setSending(true);
        try {
            await accountsManager.save({
                ...account,
                calendarUserGroups: [
                    ...account.calendarUserGroups,
                    {
                        name: groupName,
                        userIds: selectedUsers.map((user) => user.id),
                    },
                ],
            });

            handleClose();
        } catch (error: any) {
            enqueueSnackbar(error.errors.account.calendarUserGroups, { variant: 'error' });
        } finally {
            setSending(false);
        }
    };

    return (
        <PureFormDialog
            title={
                <div className={'title'}>
                    <div>{t('calendar_settings.dialog.user_groups.dialog.title')}</div>
                </div>
            }
            open={isOpen}
            onClose={handleClose}
            className="c-calendar-settings-dialog"
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
            noDividers={true}
            scroll="paper"
        >
            <Box marginTop={2}>
                <TextField
                    label={t('calendar_settings.dialog.user_groups.dialog.group_name')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={groupName}
                    onChange={handleGroupeName}
                    error={isGroupNameError}
                    helperText={
                        isGroupNameError && t('calendar_settings.dialog.user_groups.dialog.validation.required')
                    }
                    autoFocus
                />
            </Box>

            <Box marginTop={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{t('calendar_settings.dialog.user_groups.dialog.users')}</Typography>
                    <ClickAwayListener onClickAway={() => setShowRolesDropdown(false)}>
                        <Box position={'relative'}>
                            <AddAllUSers variant="button" onClick={handleAddAllUsers}>
                                {t('calendar_settings.dialog.user_groups.dialog.add_all_users')}
                            </AddAllUSers>
                            {showRolesDropdown && (
                                <Droplist>
                                    <List>
                                        {roles.map((role: UserDataRole) => (
                                            <ListItem button key={role.id} onClick={() => handleAddRole(role)}>
                                                <ListItemText primary={role.name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Droplist>
                            )}
                        </Box>
                    </ClickAwayListener>
                </Box>
                <Box position="relative">
                    <TextField
                        variant="outlined"
                        placeholder={t('calendar_settings.dialog.user_groups.dialog.start_typing')}
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                        error={isSearchTermError}
                        helperText={
                            isSearchTermError && t('calendar_settings.dialog.user_groups.dialog.validation.required')
                        }
                    />
                    {showDropdown && (
                        <Droplist>
                            <List>
                                {filteredUsers.map((user) => (
                                    <ListItem button key={user.id} onClick={() => handleAddUser(user)}>
                                        <ListItemText primary={user.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Droplist>
                    )}
                </Box>
            </Box>

            <SelectedUsers>{selectedUsers.map((user) => user.name).join(', ')}</SelectedUsers>

            <Box marginBottom={2} display="flex" justifyContent="flex-end" gridGap={10}>
                <Button color="primary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button color="primary" onClick={handleSubmit} disabled={isSending}>
                    {t('Save')}
                </Button>
            </Box>
        </PureFormDialog>
    );
};

export default withTranslation()(withSnackbar(CalendarsUserGroupsDialog));

const Droplist = withStyles({
    root: {
        position: 'absolute',
        width: '100%',
        zIndex: 10,
    },
})(Paper);

const AddAllUSers = withStyles({
    root: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
})(Typography);

const SelectedUsers = withStyles({
    root: {
        minHeight: '100px',
        margin: '15px 0',
        border: '1px solid #cbcbcb',
        borderRadius: '6px',
        padding: '10px',
    },
})(Box);
