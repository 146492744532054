import React from 'react';
import { withTranslation } from 'react-i18next';

class SsoRequestError extends React.PureComponent {
    render() {
        const message = this.props.message || 'Invalid request data';
        return (
            <React.Fragment>{this.props.t('error_notification.sso_request_error.text', { message })}</React.Fragment>
        );
    }
}

export default withTranslation()(SsoRequestError);
