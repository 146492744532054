import RouteEditorManager from 'service/MapPage/Route/RouteEditorManager';
import AppointmentConfig from 'service/MapPage/Appointment/AppointmentConfig';
import SavedPlacesManager from 'service/MapPage/SavedPlacesManager';
import AppointmentService from 'service/MapPage/Appointment/AppointmentService';
import AppointmentMetadataChecker from 'service/MapPage/Appointment/AppointmentMetadataChecker';
import CurrentRouteManager from 'service/MapPage/Route/CurrentRouteManager';
import PseudoRoutesService from 'service/MapPage/Route/PseudoRoutesService';
import RoutePointsLayer from 'service/MapPage/Route/PointsLayer/RoutePointsLayer';
import DepartingDateTimeService from 'service/MapPage/Appointment/DepartingDateTimeService';
import RouteManager from 'service/RouteManager';
import TripModeManager from 'service/MapPage/TripMode/TripModeManager';
import TripModeStorage from 'service/MapPage/TripMode/TripModeStorage';
import ReactionsManager from 'service/MapPage/Reactions/ReactionsManager';
import MapPageManager from 'service/MapPage/MapPageManager';
import RouteEditDialogManager from './Route/RouteEditDialogManager';
import { locationHistoryManager } from '../LocationHistoryManager';
import UserReviewService from 'service/MapPage/UserReview/UserReviewService';
import UserReviewDialogManager from 'service/MapPage/UserReview/UserReviewDialogManager';
import UserReviewStorage from 'service/MapPage/UserReview/UserReviewStorage';
import UserHasCrmAccountService from './UserReview/UserHasCrmAccountService';
import UserHasCrmAccountDialogManager from './UserReview/UserHasCrmAccountDialogManager';
import RouteReport from 'service/RouteReport';
import RoutingSessionService from 'service/MapPage/RoutingSession/RoutingSessionService';
import RoutingSessionManager from 'service/MapPage/RoutingSession/RoutingSessionManager';
import RouteDesignManager from 'service/MapPage/Route/RouteDesignManager';
import RouteDesignConfigManager from 'service/MapPage/Route/RouteDesignConfigManager';
import RouteViewerManager from '../RouteViewer/RouteViewerManager';
import EnqueueSnackbarService from './EnqueueSnackbarService';
import RoutingSettingsMappingHelper from 'service/MapPage/RoutingSettings/RoutingSettingsMappingHelper';
import AvailableRouteActions from 'service/MapPage/Route/AvailableRouteActions';
import ConfirmReloadSessionManager from 'service/MapPage/Route/ConfirmReloadSessionManager';
import DuplicatePointsDialogManager from './Route/DuplicatePointsDialogManager';

const routeEditorManager = new RouteEditorManager();
const appointmentConfig = new AppointmentConfig();
const savedPlacesManager = new SavedPlacesManager();
const currentRouteManager = new CurrentRouteManager();
const pseudoRoutesService = new PseudoRoutesService();
const routePointsLayer = new RoutePointsLayer();
const routeManager = new RouteManager();
const tripModeManager = new TripModeManager(new TripModeStorage());
const reactionsManager = new ReactionsManager();
const mapPageManager = new MapPageManager();
const routeEditDialogManager = new RouteEditDialogManager();
const userReviewService = new UserReviewService();
const userReviewDialogManager = new UserReviewDialogManager(new UserReviewStorage());
const userHasCrmAccountDialogManager = new UserHasCrmAccountDialogManager();
const userHasCrmAccountService = new UserHasCrmAccountService();
const routeReportManager = new RouteReport.RouteReportManager();
const routeReportLoadedSessionRouteManager = new RouteReport.LoadedSessionRouteManager();
const routeReportInEditModeManager = new RouteReport.RouteReportInEditModeManager();
const routingSettingsMappingHelper = new RoutingSettingsMappingHelper();
const duplicatePointsDialogManager = new DuplicatePointsDialogManager();

const appointmentService = new AppointmentService();
const appointmentMetadataChecker = new AppointmentMetadataChecker();
const departingDateTimeService = new DepartingDateTimeService();

const availableRouteActions = new AvailableRouteActions();
const routingSessionService = new RoutingSessionService();
const routingSessionManager = new RoutingSessionManager();
const routeDesignManager = new RouteDesignManager();
const routeDesignConfigManager = new RouteDesignConfigManager();
const routeViewerManager = new RouteViewerManager();
const confirmReloadSessionManager = new ConfirmReloadSessionManager();

const enqueueSnackbarService = new EnqueueSnackbarService();

export {
    routeEditorManager,
    appointmentConfig,
    savedPlacesManager,
    currentRouteManager,
    pseudoRoutesService,
    routePointsLayer,
    appointmentService,
    appointmentMetadataChecker,
    departingDateTimeService,
    routeManager,
    tripModeManager,
    reactionsManager,
    mapPageManager,
    routeEditDialogManager,
    locationHistoryManager,
    userReviewService,
    userReviewDialogManager,
    userHasCrmAccountDialogManager,
    userHasCrmAccountService,
    availableRouteActions,
    routeReportManager,
    routeReportInEditModeManager,
    routeReportLoadedSessionRouteManager,
    routingSessionService,
    routingSessionManager,
    routeDesignManager,
    routeDesignConfigManager,
    routeViewerManager,
    enqueueSnackbarService,
    routingSettingsMappingHelper,
    confirmReloadSessionManager,
    duplicatePointsDialogManager,
    TripModeStorage,
};
