import React from 'react';
import PropTypes from 'prop-types';
import {
    TYPE_CREATE_RECORD,
    TYPE_FIELD_UPDATE,
    TYPE_INVOKE_URL,
    TYPE_MATCH_RECORDS,
    TYPE_VISIT_PLANNER,
    TYPE_SHOW_MESSAGE,
    TYPE_OPEN_URL,
    TYPE_OPEN_FORM,
    TYPE_SEND_EMAIL,
    TYPE_SEND_SMS,
    TYPE_UPDATE_FORM,
    TYPE_DELETE_RECORD,
} from '../../service/WorkflowActionManager';
import FormDialog from '../FormDialog';
import { FieldUpdateForm } from './FieldUpdate';
import { MatchRecordsForm } from './MatchRecords';
import { InvokeUrlForm } from './InvokeUrl';
import { VisitPlannerForm } from './VisitPlanner';
import { CreateRecordForm } from './CreateRecord';
import { ShowMessageForm } from './ShowMessage';
import { withTranslation } from 'react-i18next';
import { OpenUrlForm } from './OpenUrl';
import { OpenFormForm } from './OpenForm';
import { UpdateFormForm } from './UpdateForm';
import { SendEmailForm } from './SendEmail';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import { userManager } from '../../service/UserManager';
import NamespaceBadge from '../Workflow/NamespaceBadge';
import { Button, Grid } from '@material-ui/core';
import LandingLink, {
    ARTICLE_CREATE_RECORD_ACTION,
    ARTICLE_DELETE_RECORD_ACTION,
    ARTICLE_INVOKE_URL_ACTION,
    ARTICLE_MATCH_RECORDS_ACTION,
    ARTICLE_OPEN_FORM_ACTION,
    ARTICLE_OPEN_URL_ACTION,
    ARTICLE_SEND_EMAIL_ACTION,
    ARTICLE_SEND_SMS_ACTION,
    ARTICLE_SHOW_MESSAGE_ACTION,
    ARTICLE_UPDATE_FORM_ACTION,
    ARTICLE_UPDATE_RECORD_ACTION,
    ARTICLE_VISIT_PLANNER_ACTION,
} from '../HelpLink/LandingLink';
import { DeleteRecordForm } from './DeleteRecord';
import { SendSmsForm } from './SendSms';
import EmailSettingsForm from '../Workflow/EmailSettings/EmailSettingsForm';
import PureFormDialog from '../PureFormDialog';

class ActionModals extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isModified: false,
            showCloseConfirmation: false,
            showEmailSettings: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.action !== this.props.action) {
            this.setState({ isModified: false });
        }
    }

    close = () => {
        if (userManager.automationElementsManagement() && this.state.isModified) {
            this.setCloseConfirmation(true);
        } else {
            this.props.onCancel();
        }
    };

    setCloseConfirmation = (showCloseConfirmation) => {
        this.setState({ showCloseConfirmation });
    };

    onModified = (isModified) => {
        this.setState({ isModified });
    };

    renderTitle = (title, article) => {
        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item container xs alignItems="center">
                    {this.props.t(title)}
                    <NamespaceBadge
                        tooltipMessage={this.props.t('workflow_actions.namespace.tooltip', {
                            namespace: this.props.action.namespace,
                        })}
                    >
                        {this.props.action.namespace}
                    </NamespaceBadge>
                </Grid>
                <Grid item>
                    <LandingLink useLeadingIcon useTrailingIcon article={article} style={{ fontSize: 16 }}>
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    renderSendEmailActionTitle = () => {
        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item container xs alignItems="center">
                    {this.props.t('workflow_actions.form.send_email.title')}
                    <NamespaceBadge
                        tooltipMessage={this.props.t('workflow_actions.namespace.tooltip', {
                            namespace: this.props.action.namespace,
                        })}
                    >
                        {this.props.action.namespace}
                    </NamespaceBadge>
                </Grid>
                <Grid item>
                    <Button
                        onClick={this.toggleEmailSettingsModal}
                        style={{ marginRight: 10, textTransform: 'none', fontSize: 16 }}
                    >
                        {this.props.t('main_menu.workflow_actions.email_settings')}
                    </Button>
                    <LandingLink
                        useLeadingIcon
                        useTrailingIcon
                        article={ARTICLE_SEND_EMAIL_ACTION}
                        style={{ fontSize: 16 }}
                    >
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    toggleEmailSettingsModal = () => {
        this.setState((state) => ({ showEmailSettings: !state.showEmailSettings }));
    };

    render() {
        const automationElementsManagement = userManager.automationElementsManagement();

        return (
            <React.Fragment>
                {this.props.action !== null && this.props.action.type === TYPE_FIELD_UPDATE && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.field_update.title',
                            ARTICLE_UPDATE_RECORD_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <FieldUpdateForm
                            action={this.props.action}
                            accountId={this.props.account.id}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_MATCH_RECORDS && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.matched_records.title',
                            ARTICLE_MATCH_RECORDS_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <MatchRecordsForm
                            action={this.props.action}
                            account={this.props.account}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_CREATE_RECORD && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.create_record.title',
                            ARTICLE_CREATE_RECORD_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <CreateRecordForm
                            action={this.props.action}
                            accountId={this.props.account.id}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_DELETE_RECORD && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.delete_record.title',
                            ARTICLE_DELETE_RECORD_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <DeleteRecordForm
                            action={this.props.action}
                            account={this.props.account}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_INVOKE_URL && (
                    <FormDialog
                        title={this.renderTitle('workflow_actions.form.invoke_url.title', ARTICLE_INVOKE_URL_ACTION)}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <InvokeUrlForm
                            account={this.props.account}
                            action={this.props.action}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_SHOW_MESSAGE && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.show_message.title',
                            ARTICLE_SHOW_MESSAGE_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <ShowMessageForm
                            account={this.props.account}
                            action={this.props.action}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_OPEN_URL && (
                    <FormDialog
                        title={this.renderTitle('workflow_actions.form.open_url.title', ARTICLE_OPEN_URL_ACTION)}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <OpenUrlForm
                            account={this.props.account}
                            action={this.props.action}
                            accountId={this.props.account.id}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_OPEN_FORM && (
                    <FormDialog
                        title={this.renderTitle('workflow_actions.form.open_form.title', ARTICLE_OPEN_FORM_ACTION)}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <OpenFormForm
                            account={this.props.account}
                            action={this.props.action}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_UPDATE_FORM && (
                    <FormDialog
                        title={this.renderTitle('workflow_actions.form.update_form.title', ARTICLE_UPDATE_FORM_ACTION)}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                    >
                        <UpdateFormForm
                            account={this.props.account}
                            action={this.props.action}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_SEND_EMAIL && (
                    <React.Fragment>
                        <FormDialog
                            title={this.renderSendEmailActionTitle()}
                            onSave={this.props.onSave}
                            onCancel={this.close}
                            maxWidth="md"
                            fullWidth
                            saveButtonDisabled={!automationElementsManagement}
                        >
                            <SendEmailForm
                                action={this.props.action}
                                account={this.props.account}
                                dataSources={this.props.dataSources}
                                onCancel={this.close}
                                onModified={this.onModified}
                                onOpenEmailSettings={this.toggleEmailSettingsModal}
                            />
                        </FormDialog>

                        <PureFormDialog
                            open={this.state.showEmailSettings}
                            title={this.props.t('email_settings.title')}
                            onClose={this.toggleEmailSettingsModal}
                            maxWidth="sm"
                            fullWidth
                        >
                            <EmailSettingsForm
                                accountId={this.props.account.id}
                                onClose={this.toggleEmailSettingsModal}
                            />
                        </PureFormDialog>
                    </React.Fragment>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_SEND_SMS && (
                    <FormDialog
                        title={this.renderTitle('workflow_actions.form.send_sms.title', ARTICLE_SEND_SMS_ACTION)}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                        saveButtonDisabled={!automationElementsManagement}
                    >
                        <SendSmsForm
                            action={this.props.action}
                            account={this.props.account}
                            dataSources={this.props.dataSources}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}
                {this.props.action !== null && this.props.action.type === TYPE_VISIT_PLANNER && (
                    <FormDialog
                        title={this.renderTitle(
                            'workflow_actions.form.visit_planner.title',
                            ARTICLE_VISIT_PLANNER_ACTION,
                        )}
                        onSave={this.props.onSave}
                        onCancel={this.close}
                        maxWidth="md"
                        fullWidth
                    >
                        <VisitPlannerForm
                            action={this.props.action}
                            accountId={this.props.account.id}
                            dataSources={this.props.dataSources}
                            onModified={this.onModified}
                        />
                    </FormDialog>
                )}

                <DialogConfirmation
                    open={this.state.showCloseConfirmation}
                    setOpen={this.setCloseConfirmation}
                    onConfirm={this.props.onCancel}
                    titleText={this.props.t('automation_elements.close.confirmation')}
                    yesBtnText={this.props.t('automation_elements.close.confirmation.yes')}
                    noBtnText={this.props.t('automation_elements.close.confirmation.no')}
                />
            </React.Fragment>
        );
    }
}

ActionModals.propTypes = {
    account: PropTypes.object.isRequired,
    action: PropTypes.object,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default withTranslation('translations', { withRef: true })(ActionModals);
