import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG } from 'service/MapPage/RoutingSettings/config';
import { Routing } from 'interfaces';
import TextField from '@material-ui/core/TextField';
import Hint from '../../Hint';
import {
    MappingDefaultValueControlConfig,
    MappingDefaultValueControlIntegerOptions,
} from '../../../interfaces/routing/settings';
import { FormControl, FormHelperText, Grid, InputAdornment } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';

interface Props extends WithTranslation {
    value: number | null;
    onValueChanged: (value: number | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
}

class BreakDurationInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled } = this.props;
        const options = BreakDurationInput.getOptions();

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin="dense" error={error !== undefined}>
                        <InputLabel shrink>
                            {t('routing_settings.dialog.tabs.users.form_control.break_duration.label')}
                        </InputLabel>
                        <TextField
                            error={!!error}
                            disabled={disabled}
                            value={value}
                            fullWidth
                            type="number"
                            onChange={this.handleChange}
                            variant="standard"
                            margin="normal"
                            InputProps={{
                                inputProps: { min: options.min, max: options.max, style: { textAlign: 'end' } },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {t(
                                            'routing_settings.dialog.tabs.users.form_control.break_duration.adornment.minutes',
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 16 }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.break_duration.hint')}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private static getOptions(): MappingDefaultValueControlIntegerOptions {
        const controlConfig = ROUTING_SETTINGS_MAPPING_USER_DEFAULT_VALUE_CONTROL_CONFIG.get(
            Routing.Settings.MappingUserKey.BREAK_DURATION,
        ) as MappingDefaultValueControlConfig;
        return controlConfig.options as MappingDefaultValueControlIntegerOptions;
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onValueChanged } = this.props;
        const value = event.target.value === '' ? null : parseInt(event.target.value, 10);
        onValueChanged(value).then(() => this.validate(value));
    };

    private validate = (value: number | null): Promise<void> => {
        const { onInputValidation, t } = this.props;
        const options = BreakDurationInput.getOptions();
        const isValid = value === null || (value >= options.min && value <= options.max);
        let error = undefined;
        if (value === null) {
            error = t('validation.value.required');
        } else if (value < options.min || value > options.max) {
            error = t('validation.value.min_max', { min: options.min, max: options.max });
        }
        return onInputValidation ? onInputValidation(isValid, error) : Promise.resolve();
    };
}

export default withTranslation()(BreakDurationInput);
