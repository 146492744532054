import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormControl, Icon, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { trimLookupIdLabel } from '../../utils';
import { IField } from 'components/types';

interface Props extends WithTranslation {
    fields: IField[];
    onChange: AutocompleteProps<IField, boolean, boolean, boolean>['onChange'];
    disabled: boolean;
}

class SelectFieldControl extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false,
    };

    render() {
        const { t, fields, disabled, onChange } = this.props;

        return (
            <FormControl margin="dense" className="c-live-update-form__search">
                <Autocomplete
                    options={fields}
                    getOptionLabel={trimLookupIdLabel}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={onChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="search"
                            label={t('live_update_table_form.form.search.label')}
                            data-testid="live_update_table_form.form.search"
                            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                            inputProps={{ ...params.inputProps, style: { paddingTop: 3 } }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start" className="c-live-update-form__search-icon">
                                        <Icon>search</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t('live_update_table_form.form.search.placeholder')}
                            autoFocus
                            disabled={disabled}
                        />
                    )}
                />
            </FormControl>
        );
    }
}

export default withTranslation()(SelectFieldControl);
