import { FIELD_TYPES } from './fields/AbstractField';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';
import DateTime from './fields/DateTime';
import Decimal from './fields/Decimal';
import Html from './fields/Html';
import Label from './fields/Label';
import PickList, { TYPES } from './fields/PickList';
import Style from './fields/Style';
import Text from './fields/Text';
import File from './fields/File';
import { UploadMethod } from 'interfaces/file';
import { AUTODETECT_LANGUAGE } from '../../File/TranscribeLanguages';
import i18n from 'locales/i18n';

const t = i18n.t.bind(i18n);

class FieldFactory {
    createFieldByType(type) {
        return this.createField({ fieldType: type });
    }

    createField(data) {
        switch (data.fieldType) {
            case FIELD_TYPES.BUTTON:
                return this.setBaseItems(data, new Button(data.isActionButton ?? false))
                    .setHandlers(data.handlers ?? [])
                    .setUserLocationAvailable(data.userLocationAvailable ?? false);
            case FIELD_TYPES.TEXT:
                return this.setBaseItems(data, new Text())
                    .setMaxLength(data.maxLength ?? null)
                    .setIsMultiline(data.isMultiline ?? false)
                    .setHtmlType(data.htmlType)
                    .setLinesCount(data.linesCount ?? null)
                    .setIsBarcodeScaner(data.is_barcode_data ?? null)
                    .setAllowTypeManual(data.allowTypeManual ?? null);
            case FIELD_TYPES.CHECKBOX:
                return this.setBaseItems(data, new Checkbox()).setHandlers(data.handlers ?? []);
            case FIELD_TYPES.PICK_LIST:
                return this.setBaseItems(data, new PickList())
                    .setItems(data.items ?? [])
                    .setItemsFormula(data.itemsFormula ?? '')
                    .setItemsType(data.itemsType ?? TYPES.STATIC)
                    .setItemsEntityId(data.itemsEntityId ?? null)
                    .setItemsFieldId(data.itemsFieldId ?? null)
                    .setIsMultiple(data.isMultiple ?? false)
                    .setIsAlphabeticallySorted(data.isAlphabeticallySorted ?? false)
                    .setHandlers(data.handlers ?? []);
            case FIELD_TYPES.DATETIME:
                return this.setBaseItems(data, new DateTime()).setIsTimeAllowed(data.isTimeAllowed ?? false);
            case FIELD_TYPES.DECIMAL:
                return this.setBaseItems(data, new Decimal())
                    .setMinValue(data.minValue ?? null)
                    .setMaxValue(data.maxValue ?? null)
                    .setIsDecimal(data.isDecimal ?? false);
            case FIELD_TYPES.LABEL:
                return this.setBaseItems(data, new Label());
            case FIELD_TYPES.FILE:
                return this.setBaseItems(data, new File())
                    .setMinCount(data.minCount ?? 0)
                    .setMaxCount(data.maxCount ?? 10)
                    .setAllowedTypes(data.allowedTypes ?? [])
                    .setAllowedMethods(data.allowedMethods ?? [UploadMethod.Camera])
                    .setPrompt(data.prompt ?? t('workflow_actions.forms.field.file.summary.custom_prompt'))
                    .setLanguage(data.language ?? AUTODETECT_LANGUAGE)
                    .setIsTranscriptEnabled(data.isTranscriptEnabled ?? false)
                    .setIsSummaryEnabled(data.isSummaryEnabled ?? false)
                    .setImageResize(data.imageResize ?? true)
                    .setImageTargetMaxWidth(data.targetImageMaxWidth ?? 1000)
                    .setImageTargetMaxHeight(data.targetImageMaxHeight ?? 1000)
                    .setKeepActionButtonsEnabled(data.keepActionButtonsEnabled ?? false);
            case FIELD_TYPES.STYLE:
                return this.setBaseItems(data, new Style());
            case FIELD_TYPES.HTML:
                return this.setBaseItems(data, new Html());
            default:
                throw new Error('Undefined type:' + data.fieldType);
        }
    }

    setBaseItems(data, field) {
        return field
            .setLabel(data.label ?? null)
            .setDescription(data.description ?? null)
            .setValue(data.value ?? null)
            .setApiName(data.apiName ?? null)
            .setIsHidden(data.isHidden ?? false)
            .setIsDisabled(data.isDisabled ?? false)
            .setIsRequired(data.isRequired ?? false)
            .setCondition(data.condition ?? null);
    }
}

export default FieldFactory;
