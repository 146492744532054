import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PureFormDialog from '../PureFormDialog';
import { FormActions } from '../PureFormDialog/Form';

interface WarningOverwriteFieldsDialogProps extends WithTranslation {
    onCancel: () => void;
    onSave: () => void;
    fields: OverwriteField[];
}

interface OverwriteField {
    dataSourceLabel: string;
    entityLabel: string;
    fieldLabel: string;
}

class WarningOverwriteFieldsDialog extends React.Component<WarningOverwriteFieldsDialogProps> {
    render() {
        const { fields, onCancel, onSave, t } = this.props;
        return (
            <PureFormDialog open title={t('warning_overwrite_fields.modal.title') as string} onClose={onCancel}>
                <Grid container>
                    <Grid item>{t('warning_overwrite_fields.modal.fields_list')}</Grid>
                    <Grid item>
                        <ul>
                            {fields.map((field, key) => (
                                <li key={key}>
                                    {field.dataSourceLabel} -&gt; {field.entityLabel} -&gt; {field.fieldLabel}
                                </li>
                            ))}
                        </ul>
                    </Grid>
                    <Grid item>{t('warning_overwrite_fields.modal.confirmation')}</Grid>
                </Grid>
                <FormActions closeText={t('button.no_stay')}>
                    <Button
                        color="primary"
                        onClick={onSave}
                        data-testid="warning_overwrite_fields.modal.button.yes_proceed"
                    >
                        {t('button.yes_proceed')}
                    </Button>
                </FormActions>
            </PureFormDialog>
        );
    }
}

export default withTranslation()(WarningOverwriteFieldsDialog);
