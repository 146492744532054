import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormDialog from '../FormDialog';
import WorkflowButtonForm from './WorkflowButtonsForm';
import { FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import { userManager } from '../../service/UserManager';
import NamespaceBadge from '../Workflow/NamespaceBadge';
import LandingLink, { ARTICLE_BUTTON } from '../HelpLink/LandingLink';

class WorkflowButtonModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isModified: false,
            showCloseConfirmation: false,
        };
    }

    close = () => {
        if (userManager.automationElementsManagement() && this.state.isModified) {
            this.setCloseConfirmation(true);
        } else {
            this.props.onCancel();
        }
    };

    setCloseConfirmation = (showCloseConfirmation) => {
        this.setState({ showCloseConfirmation });
    };

    onModified = (isModified) => {
        this.setState({ isModified });
    };

    getModalTitle = () => {
        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item container xs spacing={1} alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <span>{this.props.t('workflow_buttons.form.title')}</span>
                            <NamespaceBadge
                                tooltipMessage={this.props.t('workflow_buttons.namespace.tooltip', {
                                    namespace: this.props.button.namespace,
                                })}
                            >
                                {this.props.button.namespace}
                            </NamespaceBadge>
                        </Grid>
                    </Grid>
                    {this.props.onToggleButton && (
                        <Grid item>
                            <Tooltip
                                title={
                                    this.props.button.disabled
                                        ? this.props.t('automation_elements.button_disabled')
                                        : ''
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.props.button.isActive}
                                            onChange={this.props.onToggleButton.bind(this, this.props.button)}
                                            disabled={
                                                this.props.button.disabled ||
                                                this.props.saving ||
                                                !userManager.automationElementsManagement()
                                            }
                                            color="primary"
                                            data-testid="workflow_buttons.form.toggle_button_disabled"
                                        />
                                    }
                                    label=""
                                />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <LandingLink useLeadingIcon useTrailingIcon article={ARTICLE_BUTTON} style={{ fontSize: 16 }}>
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    getPersistentWarning() {
        if (this.props.buttonUpdate === 'update') {
            return this.props.t('workflow_buttons.form.warning.updated');
        }
        if (this.props.buttonUpdate === 'delete') {
            return this.props.t('workflow_buttons.form.warning.deleted');
        }
        return undefined;
    }

    render() {
        return (
            <React.Fragment>
                <FormDialog
                    title={this.getModalTitle()}
                    onSave={this.props.onSaved}
                    onCancel={this.close}
                    saveButtonDisabled={!this.state.isModified || !userManager.automationElementsManagement()}
                    persistentWarning={this.getPersistentWarning()}
                >
                    <WorkflowButtonForm
                        account={this.props.account}
                        button={this.props.button}
                        buttonUpdate={this.props.buttonUpdate}
                        dataSources={this.props.dataSources}
                        roles={this.props.roles}
                        onSave={this.props.onSave}
                        onModified={this.onModified}
                    />
                </FormDialog>

                <DialogConfirmation
                    open={this.state.showCloseConfirmation}
                    setOpen={this.setCloseConfirmation}
                    onConfirm={this.props.onCancel}
                    titleText={this.props.t('automation_elements.close.confirmation')}
                    yesBtnText={this.props.t('automation_elements.close.confirmation.yes')}
                    noBtnText={this.props.t('automation_elements.close.confirmation.no')}
                />
            </React.Fragment>
        );
    }
}

WorkflowButtonModal.propTypes = {
    account: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSaved: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onToggleButton: PropTypes.func,
    button: PropTypes.object.isRequired,
    buttonUpdate: PropTypes.oneOf([null, 'insert', 'update', 'delete']),
    onSave: PropTypes.func.isRequired,
    saving: PropTypes.bool,
};

WorkflowButtonModal.defaultProps = {
    saving: false,
};

export default withTranslation()(WorkflowButtonModal);
