import { ParallelSafeDispatcher } from './ParallelSafeDispatcher';
import { ParallelSafeDispatcherStorageBased } from './ParallelSafeDispatcherStorageBased';
import { ParallelSafeDispatcherMessageBased } from './ParallelSafeDispatcherMessageBased';

class ParallelSafeDispatcherFactory {
    public getDispatcher(sharedName: string, task: any): ParallelSafeDispatcher {
        if (typeof BroadcastChannel !== 'undefined') {
            return new ParallelSafeDispatcherMessageBased(sharedName, task);
        } else {
            return new ParallelSafeDispatcherStorageBased(sharedName, task, 10);
        }
    }
}

export default new ParallelSafeDispatcherFactory();
