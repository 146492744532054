import { makeAutoObservable } from 'mobx';
import { routingSettingsMappingObjectFormManager } from './index';
import { Routing } from 'interfaces';

class RoutingSettingsObjectDialogManager {
    private _isOpen = false;
    private _loading = false;
    private _editAction = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    openModalAddAction(settings: Routing.Settings.FormMappingData) {
        this._isOpen = true;
        this._editAction = false;
        routingSettingsMappingObjectFormManager.updateFormData(settings);
    }

    openModalEditAction(settings: Routing.Settings.FormMappingData) {
        this._isOpen = true;
        this._editAction = true;
        routingSettingsMappingObjectFormManager.updateFormData(settings);
    }

    closeModal() {
        this._isOpen = false;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get editAction(): boolean {
        return this._editAction;
    }
}

export default RoutingSettingsObjectDialogManager;
