import React from 'react';
import Hint from './index';

interface Props {
    style?: object;
    inheritStyles?: boolean;
}

class QuestionHint extends React.PureComponent<Props> {
    render() {
        const { children, style, ...rest } = this.props;
        return (
            <Hint
                {...rest}
                iProps={{
                    style: { marginLeft: 8, ...style },
                }}
                TooltipProps={{ className: 'tooltip-question' }}
            >
                {children}
            </Hint>
        );
    }
}

export default QuestionHint;
