import AbstractField from './AbstractField';
import { FIELD_TYPES } from './AbstractField';

export default class Checkbox extends AbstractField {
    constructor() {
        super(FIELD_TYPES.CHECKBOX);
    }

    validateValue() {
        return true;
    }

    isEmpty() {
        return false;
    }
}
