import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { FormActions } from 'components/PureFormDialog/Form';
import Hint from 'components/Hint';
import { Entity } from 'interfaces';

interface Props extends WithTranslation {
    onSave: (settings: Entity.AdvancedSettings) => void;
    settings: Entity.AdvancedSettings;
}

interface State {
    settings: Entity.AdvancedSettings;
}

class HubspotDsEntitySettings extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            settings: { ...props.settings },
        };
    }

    onSave = () => {
        const { onSave } = this.props;
        onSave(this.state.settings);
    };

    handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const settings = { ...this.state.settings };
        settings.enableWebhooks = event.target.checked;
        this.setState({
            settings,
        });
    };

    render() {
        const { settings } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                <Checkbox color="primary" checked={settings.enableWebhooks} onChange={this.handleToggle} />
                {t('data_source.entity.settings.advanced_settings.hubspot.webhooks')}
                <span style={{ marginLeft: 8 }}>
                    <Hint>{t('data_source.entity.settings.advanced_settings.hubspot.webhooks.tooltip')}</Hint>
                </span>

                <FormActions>
                    <Button color="primary" onClick={this.onSave} data-testid="data_source.entity.settings.button.save">
                        {t('button.save')}
                    </Button>
                </FormActions>
            </React.Fragment>
        );
    }
}

export default withTranslation()(HubspotDsEntitySettings);
