import React from 'react';
import { Grid, IconButton, Tooltip, Icon, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../locales/i18n';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import AbstractField from '../../../service/workflow_actions/forms/fields/AbstractField';
import DottedLink from '../../DottedLink';
import { FieldItem } from './FieldItem';
const t = i18n.t.bind(i18n);

const DragHandle = SortableHandle(() => (
    <IconButton data-testid="workflow_action.action_button_item.drag_button">
        <Icon>dehaze</Icon>
    </IconButton>
));

class ActionButtonItem extends FieldItem {
    render() {
        return (
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: 20 }}>
                <Grid item xs={1}>
                    <DragHandle />
                </Grid>
                <Grid item xs={10}>
                    <DottedLink onClick={this.handleChange} data-testid="workflow_actions.forms.field">
                        <Grid container spacing={5}>
                            <Grid item>
                                {this.props.field.getLabel()
                                    ? this.props.field.getLabel()
                                    : '[' + t('workflow_actions.forms.field.no_label') + ']'}
                            </Grid>
                            {this.props.field.getHandlers().length > 0 && (
                                <Grid item>
                                    <Badge badgeContent={t('workflow_actions.forms.field.onClick')} color="primary" />
                                </Grid>
                            )}
                        </Grid>
                    </DottedLink>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t('remove')}>
                        <IconButton
                            color="secondary"
                            onClick={this.handleRemove}
                            data-testid="workflow_actions.forms.field.remove"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}

ActionButtonItem.propTypes = {
    field: PropTypes.instanceOf(AbstractField).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

const SortableItem = SortableElement((props) => <ActionButtonItem {...props} />);

export default SortableItem;
