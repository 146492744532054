import { Button, Grid, Typography } from '@material-ui/core';
import { formatWithCommas } from '../../../utils';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, Subscription } from '../../../interfaces/account';
import { LinearProgressWithLabel } from '../../LinearProgress/LinearProgress';
import Hint from '../../Hint';
import { WithSnackbarProps } from 'notistack';
import { SubscriptionStatus } from '../../../service/types';
import { intercomManager } from '../../../service/IntercomManager';
import TranscriptionMinutesEditDialog from './TranscriptionMinutesEditDialog';
import { getTotalTranscriptionMinutes } from '../utils';

interface TranscriptionMinutesSubscriptionPanelProps extends WithTranslation, WithSnackbarProps {
    account: Account;
    subscription: Subscription;
}

interface TranscriptionMinutesSubscriptionPanelState {
    hasEditDialog: boolean;
}

class TranscriptionMinutesSubscriptionPanel extends React.Component<
    TranscriptionMinutesSubscriptionPanelProps,
    TranscriptionMinutesSubscriptionPanelState
> {
    constructor(props: TranscriptionMinutesSubscriptionPanelProps) {
        super(props);
        this.state = {
            hasEditDialog: false,
        };
    }

    handleChange = () => {
        const { subscription } = this.props;

        if ([SubscriptionStatus.ACTIVE, SubscriptionStatus.FREE].includes(subscription.status)) {
            this.setState({
                hasEditDialog: true,
            });
        } else {
            intercomManager.showNewMessage(
                this.props.t('billing.subscription_panel.change_transcription_minutes_intercom'),
            );
        }
    };

    handleHideTranscriptionMinutesEditDialog = () => {
        this.setState({
            hasEditDialog: false,
        });
    };

    private getTotal = () => {
        return getTotalTranscriptionMinutes(this.props.subscription);
    };

    private getUsage = () => {
        return this.convertSecondsToMinutes(this.props.subscription.wastedTranscriptionSeconds);
    };

    private getUsagePercent = () => {
        return Math.min(Math.round((this.getUsage() / this.getTotal()) * 100), 100);
    };

    private getAvailable = () => {
        return Math.max(this.getTotal() - this.getUsage(), 0);
    };

    private convertSecondsToMinutes = (seconds: number) => {
        return Math.floor(seconds / 60);
    };

    private getPlanPerUser(): number {
        return this.convertSecondsToMinutes(this.props.subscription.plan.transcriptionSecondsPerUser);
    }

    private getAdditionalTranscriptionMinutes(): string {
        const result = this.convertSecondsToMinutes(this.props.subscription.additionalTranscriptionSeconds);

        return formatWithCommas(result);
    }

    private getAdditionalCustomTranscriptionMinutes(): string {
        const result = this.convertSecondsToMinutes(this.props.subscription.additionalCustomTranscriptionSeconds);

        return formatWithCommas(result);
    }

    private getLinearProgressTooltipText = () => {
        const { t } = this.props;

        return (
            <React.Fragment>
                {t('billing.subscription_panel.transcription_minutes_usage.consumption', {
                    usage: formatWithCommas(this.getUsage()),
                    max: formatWithCommas(this.getTotal()),
                })}
                <br />
                {t('billing.subscription_panel.transcription_minutes_usage.available', {
                    total: formatWithCommas(this.getAvailable()),
                })}
                <br />
                {t('billing.subscription_panel.transcription_minutes_usage.renew_in')}
            </React.Fragment>
        );
    };

    render() {
        const { t, subscription } = this.props;

        return (
            <React.Fragment>
                <Grid className="row" container alignItems="center" direction="row" justifyContent="center">
                    <Grid item xs={3}>
                        <span>{t('billing.available_transcription_minutes')}:</span>
                    </Grid>
                    <Grid item xs>
                        <span className="title">{formatWithCommas(this.getTotal())}</span>

                        <Hint TooltipProps={{ className: 'tooltip-question' }} inheritStyles>
                            {this.getTotal() === 0 &&
                                t('billing.subscription_panel.tooltip_transcription_minutes_total_zero')}
                            {this.getTotal() > 0 &&
                                t('billing.subscription_panel.tooltip_transcription_minutes_total', {
                                    total: formatWithCommas(this.getTotal()),
                                    perUser: formatWithCommas(this.getPlanPerUser()),
                                    users: subscription.transcriptionUsers,
                                    additional:
                                        subscription.additionalTranscriptionSeconds > 0
                                            ? t('billing.subscription_panel.tooltip_transcription_minutes_additional', {
                                                  total: this.getAdditionalTranscriptionMinutes(),
                                              })
                                            : '',
                                    custom:
                                        subscription.additionalCustomTranscriptionSeconds > 0
                                            ? t('billing.subscription_panel.tooltip_transcription_minutes_custom', {
                                                  total: this.getAdditionalCustomTranscriptionMinutes(),
                                              })
                                            : '',
                                })}
                        </Hint>
                    </Grid>
                    <Grid item xs>
                        <Button
                            variant="contained"
                            onClick={this.handleChange}
                            data-testid="button.change_transcription_minutes"
                        >
                            <Typography noWrap variant="inherit">
                                {t('billing.button.change_transcription_minutes')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center">
                    <Grid item xs={3}>
                        <span> {t('billing.transcription_minutes_usage')}: </span>
                    </Grid>
                    <Grid item xs>
                        <div style={{ width: 100 }}>
                            <LinearProgressWithLabel
                                label={formatWithCommas(this.getTotal())}
                                value={this.getUsagePercent()}
                                tooltip={this.getLinearProgressTooltipText()}
                                style={{ width: 100 }}
                            />
                        </div>
                    </Grid>
                </Grid>

                {this.state.hasEditDialog && (
                    <TranscriptionMinutesEditDialog
                        subscription={this.props.subscription}
                        account={this.props.account}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        onCloseDialog={this.handleHideTranscriptionMinutesEditDialog}
                        closeSnackbar={() => void 0}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(TranscriptionMinutesSubscriptionPanel);
