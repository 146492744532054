import DateFnsUtils from '@date-io/date-fns';
import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';

import { formatDateTimeForPicker } from '../../utils';

interface BetweenDateProps {
    value?: {
        dateTo: Date;
        dateFrom: Date;
    };
    format?: string;
    onChange(value: { dateTo: Date; dateFrom: Date }): void;
}

class BetweenDate extends React.Component<BetweenDateProps> {
    static defaultProps = {
        value: { dateFrom: new Date(), dateTo: new Date() },
        format: 'MM/dd/yyyy',
    };

    handleChangeFrom = (dateFrom: MaterialUiPickersDate) => {
        dateFrom!.setHours(0, 0, 0, 0);
        this.makeBetweenObject(dateFrom!, this.props.value!.dateTo);
    };

    handleChangeTo = (dateTo: MaterialUiPickersDate) => {
        dateTo!.setHours(23, 59, 59, 999);
        this.makeBetweenObject(this.props.value!.dateFrom, dateTo!);
    };

    makeBetweenObject(dateFrom: Date, dateTo: Date) {
        const value = {
            dateFrom,
            dateTo,
        };
        this.props.onChange(value);
    }

    render() {
        const { dateFrom, dateTo } = this.props.value!;
        const { format } = this.props;

        return (
            <Box display="flex" gridGap={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        format={format}
                        margin="none"
                        variant="inline"
                        autoOk={true}
                        onChange={this.handleChangeFrom}
                        clearable
                        className="date-from"
                        value={dateFrom ? formatDateTimeForPicker(dateFrom) : null}
                    />
                </MuiPickersUtilsProvider>
                <span>–</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        format={format}
                        margin="none"
                        variant="inline"
                        autoOk={true}
                        onChange={this.handleChangeTo}
                        clearable
                        className="date-to"
                        value={dateTo ? formatDateTimeForPicker(dateTo) : null}
                    />
                </MuiPickersUtilsProvider>
            </Box>
        );
    }
}

export default BetweenDate;
