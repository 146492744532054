import React from 'react';

import PropTypes from 'prop-types';
import { liveUpdateJobManager } from '../../service/LiveUpdateJobManager';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableLoadingState } from '../TableLoadingState';
import { withStyles } from '@material-ui/core';
import { DataTable } from '../DataTable';
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import debounce from 'lodash/debounce';
import { userTimezoneToUtc } from '../../utils';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import { withTranslation } from 'react-i18next';
import ConfirmationIconButton from '../CustomButton/ConfirmationIconButton';
import './style.css';
import DateTime from '../DateTime';

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'createdAt') {
        return <DateBetweenFilter {...props} />;
    }
    return null;
};

class LiveUpdateEventLog extends DataTable {
    constructor(props) {
        super(props);
        this.pageSizes = [25, 50, 100];
        this.state = {
            loading: true,
            rows: [],
            pagination: {
                current: 0,
                size: 50,
            },
            totalCount: 0,
        };

        this.filters = [
            {
                columnName: 'createdAt',
                value: {
                    dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                    dateTo: new Date(),
                },
            },
        ];

        this.sorting = [
            {
                columnName: 'createdAt',
                direction: 'desc',
            },
        ];

        const { t } = this.props;

        this.columns = [
            {
                name: 'createdAt',
                title: t('live_update_log.created_at'),
                getCellValue: (row) => (
                    <span>
                        <DateTime>{row.createdAt}</DateTime>
                    </span>
                ),
            },
            {
                name: 'completedAt',
                title: t('live_update_log.completed_at'),
                getCellValue: (row) => <span>{!!row.completedAt && <DateTime>{row.completedAt}</DateTime>}</span>,
            },
            {
                name: 'cancelledAt',
                title: t('live_update_log.cancelled_at'),
                getCellValue: (row) => <span>{!!row.cancelledAt && <DateTime>{row.cancelledAt}</DateTime>}</span>,
            },
            {
                name: 'entity',
                title: t('live_update_log.entity'),
                getCellValue: (row) => <span>{row.entity}</span>,
            },
            {
                name: 'totalCount',
                title: t('live_update_log.update_count'),
                getCellValue: (row) => <span>{row.totalCount}</span>,
            },
            {
                name: 'error',
                title: t('live_update_log.error'),
                getCellValue: (row) => <span>{row.error}</span>,
            },
            {
                name: 'filters',
                title: t('live_update_log.filters'),
                getCellValue: (row) => <span>{JSON.stringify(row.filters)}</span>,
            },
            {
                name: 'basePoint',
                title: t('live_update_log.base_point'),
                getCellValue: (row) => <span>{row.basePoint && JSON.stringify(row.basePoint)}</span>,
            },
            {
                name: 'withData',
                title: t('live_update_log.with_data'),
                getCellValue: (row) => <span>{row.withData && JSON.stringify(row.withData)}</span>,
            },
            {
                name: 'editType',
                title: t('live_update_log.edit_type'),
                getCellValue: (row) => <span>{row.editType}</span>,
            },
            {
                name: 'progress',
                title: t('live_update_log.progress'),
                getCellValue: (row) => (
                    <span>
                        {row.progress && row.progress.updated !== null
                            ? t('live_update_log.updated') + ': ' + row.progress.updated
                            : null}
                        <br />
                        {row.progress && row.progress.failed !== null
                            ? t('live_update_log.failed') + ': ' + row.progress.failed
                            : null}
                        <br />
                    </span>
                ),
            },
            {
                name: 'user',
                title: t('live_update_log.user'),
                getCellValue: (row) => {
                    if (!row.user) {
                        return null;
                    }
                    return <span>{row.user.email}</span>;
                },
            },
            {
                name: 'actions',
                title: t('live_update_log.actions'),
                getCellValue: (row) => {
                    return (
                        !row.completedAt &&
                        !row.cancelledAt && (
                            <ConfirmationIconButton
                                iconName="delete"
                                color="primary"
                                component="span"
                                onClick={() => this.handleCancelJob(row.id)}
                                processing={false}
                                textYes={t('button.confirm')}
                                textNo={t('button.cancel')}
                                text={t('live_update_log.cancel_job')}
                            />
                        )
                    );
                },
            },
        ];

        this.columnExtensions = [
            {
                columnName: 'createdAt',
                width: 200,
            },
            {
                columnName: 'completedAt',
                width: 200,
            },
            {
                columnName: 'cancelledAt',
                width: 200,
            },
            {
                columnName: 'progress',
                width: 200,
            },
            {
                columnName: 'entity',
                width: 120,
            },
            {
                columnName: 'user',
                width: 150,
            },
        ];

        this.pagingPanelMessages = DataTable.getPagingMessages(t);
        this.handleChangeFilters = debounce(this.handleChangeFilters.bind(this), 500);
    }

    componentDidMount() {
        this.loadData();
        this.setupReloadOnTimeZoneChange();
    }

    handleChangeFilters = (filters) => {
        this.filters = filters;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            this.loadData,
        );
    };

    handleCancelJob = (jobId) => {
        liveUpdateJobManager.stopLiveUpdateJob(this.props.accountId, this.props.dsId, jobId).then(() => {
            this.loadData();
        });
    };

    handleChangeSorting = (sorting) => {
        this.sorting = sorting;
        let pagination = { ...this.state.pagination };
        pagination.current = 0;

        this.setState(
            {
                pagination,
            },
            this.loadData,
        );
    };

    formatFilters(filters) {
        const formatFilters = [];
        filters.forEach((filter) => {
            if (filter.columnName === 'createdAt') {
                formatFilters['dateFrom'] = userTimezoneToUtc(filter.value.dateFrom, this.props.user);
                formatFilters['dateTo'] = userTimezoneToUtc(filter.value.dateTo, this.props.user);
            } else {
                formatFilters[filter.columnName] = filter.value;
            }
        });
        return formatFilters;
    }

    formatSorting(sorting) {
        const formatSorting = [];
        sorting.forEach((sort) => {
            formatSorting['column'] = sort.columnName;
            formatSorting['direction'] = sort.direction;
        });
        return formatSorting;
    }

    loadData() {
        liveUpdateJobManager
            .getLiveUpdateJobs(
                this.props.accountId,
                this.props.dsId,
                this.formatFilters(this.getFilters()),
                this.formatSorting(this.sorting),
                this.state.pagination.current + 1,
                this.state.pagination.size,
            )
            .then((data) => {
                this.setState({
                    loading: false,
                    rows: data.logs,
                    totalCount: data.count,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });

        this.setState({
            loading: true,
            rows: [],
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="event-log">
                <h1>{t('live_update_log.title')}</h1>
                <Grid rows={[]} columns={this.columns}>
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </Grid>
                <Grid rows={this.state.rows} columns={this.columns}>
                    <FilteringState defaultFilters={this.filters} onFiltersChange={this.handleChangeFilters} />
                    <SortingState
                        defaultSorting={this.sorting}
                        onSortingChange={this.handleChangeSorting}
                        columnExtensions={[
                            { columnName: 'progress', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'entity', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'completedAt', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'cancelledAt', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'filters', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'basePoint', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'editType', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'totalCount', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'error', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'user', sortingEnabled: false, filteringEnabled: false },
                            { columnName: 'actions', sortingEnabled: false, filteringEnabled: false },
                        ]}
                    />
                    <Table
                        columnExtensions={this.columnExtensions}
                        noDataCellComponent={() => (
                            <TableLoadingState columnCount={this.columns.length} loading={this.state.loading} />
                        )}
                        cellComponent={withStyles(regularPaddingCellStyles)(Table.Cell)}
                        rowComponent={StyledRow}
                    />
                    <TableHeaderRow
                        cellComponent={withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell)}
                        showSortingControls
                    />
                    <TableFilterRow cellComponent={StyledFilterCell} />
                    <PagingState
                        currentPage={this.state.pagination.current}
                        onCurrentPageChange={this.handleCurrentPageChanged}
                        onPageSizeChange={this.handlePageSizeChanged}
                        pageSize={this.state.pagination.size}
                    />
                    <CustomPaging totalCount={this.state.totalCount} />
                    <PagingPanel
                        messages={this.pagingPanelMessages}
                        pageSizes={this.pageSizes}
                        containerComponent={PagingContainer}
                    />
                </Grid>
            </div>
        );
    }
}

LiveUpdateEventLog.propTypes = {
    accountId: PropTypes.number.isRequired,
    user: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
    }).isRequired,
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
        verticalAlign: 'top',
    },
});

const stylesForCustomFilter = (theme) => ({
    cell: {
        width: '100%',
        padding: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
});

const StyledFilterCell = withStyles(stylesForCustomFilter)(FilterCell);

const PagingContainer = (props) => <PagingPanel.Container {...props} className="table-pagination" />;

const Row = (props) => {
    const { children, classes, ...rest } = props;

    let className;
    const isGhosting = rest.tableRow.row['isGhosting'];
    if (isGhosting) {
        className = classes.ghosting;
    }

    return (
        <Table.Row {...rest} className={className}>
            {children}
        </Table.Row>
    );
};

const rowStyles = {
    ghosting: {
        backgroundColor: '#fff9d4',
    },
};
const StyledRow = withStyles(rowStyles)(Row);

export default withTranslation('translations', { withRef: true })(LiveUpdateEventLog);
