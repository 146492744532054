import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import DialogConfirmation from '../../Confirmation/DialogConfirmation';
import { userManager } from '../../../service/UserManager';
import NamespaceBadge from '../NamespaceBadge';
import LandingLink, { ARTICLE_SCHEDULE } from '../../HelpLink/LandingLink';
import { AccountData, IDataSource } from '../../../service/types';
import { Schedule } from './types';
import PureFormDialog from '../../PureFormDialog/index';
import ScheduleForm from './ScheduleForm';

interface CurrentProps extends WithTranslation {
    account: AccountData;
    dataSources: IDataSource[];
    onSaved: (schedule: Schedule) => void;
    onCancel: () => void;
    schedule: Schedule | null;
    onToggleActive?: (schedule: Schedule) => void;
}

interface CurrentState {
    isModified: boolean;
    showCloseConfirmation: boolean;
}

class ScheduleModal extends React.Component<CurrentProps, CurrentState> {
    constructor(props: CurrentProps) {
        super(props);

        this.state = {
            isModified: false,
            showCloseConfirmation: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<CurrentProps>) {
        if (prevProps.schedule !== this.props.schedule) {
            this.setState({ isModified: false });
        }
    }

    close = () => {
        if (userManager.automationElementsManagement() && this.state.isModified) {
            this.setCloseConfirmation(true);
        } else {
            this.props.onCancel();
        }
    };

    setCloseConfirmation = (showCloseConfirmation: boolean) => {
        this.setState({ showCloseConfirmation });
    };

    onModified = () => {
        this.setState({ isModified: true });
    };

    getModalTitle = (schedule: Schedule) => {
        return (
            <Grid container alignItems="center" justify="space-between">
                <Grid item container xs spacing={1} alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <span>{this.props.t('automation.schedule.form.title')}</span>
                            <NamespaceBadge
                                tooltipMessage={this.props.t('automation.schedule.namespace.tooltip', {
                                    namespace: schedule.namespace,
                                })}
                            >
                                {schedule.namespace}
                            </NamespaceBadge>
                        </Grid>
                    </Grid>
                    {this.props.onToggleActive && (
                        <Grid item>
                            <Tooltip
                                title={
                                    schedule.disabled
                                        ? this.props.t<string>('automation_elements.schedule_disabled')
                                        : ''
                                }
                            >
                                <FormControlLabel
                                    className=""
                                    control={
                                        <Switch
                                            checked={schedule.isActive}
                                            onChange={this.props.onToggleActive.bind(this, schedule)}
                                            disabled={schedule.disabled || !userManager.automationElementsManagement()}
                                            color="primary"
                                            data-testid="automation.schedule.form.toggle_schedule_disabled"
                                        />
                                    }
                                    label=""
                                />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    {/*@ts-ignore*/}
                    <LandingLink useLeadingIcon useTrailingIcon article={ARTICLE_SCHEDULE} style={{ fontSize: 16 }}>
                        {this.props.t('help')}
                    </LandingLink>
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.props.schedule !== null && (
                    <PureFormDialog
                        open
                        title={this.getModalTitle(this.props.schedule)}
                        onClose={this.close}
                        maxWidth="md"
                        fullWidth
                    >
                        <ScheduleForm
                            account={this.props.account}
                            dataSources={this.props.dataSources}
                            schedule={this.props.schedule}
                            onSaved={this.props.onSaved}
                            onCancel={this.close}
                            onModified={this.onModified}
                        />
                    </PureFormDialog>
                )}

                <DialogConfirmation
                    open={this.state.showCloseConfirmation}
                    setOpen={this.setCloseConfirmation}
                    onConfirm={this.props.onCancel}
                    titleText={this.props.t('automation_elements.close.confirmation')}
                    yesBtnText={this.props.t('automation_elements.close.confirmation.yes')}
                    noBtnText={this.props.t('automation_elements.close.confirmation.no')}
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(ScheduleModal);
