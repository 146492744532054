import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import Status from './Status';
import './style.css';
import { Link } from 'react-router-dom';
import { reverse, routes } from '../../routes';
import { utcToUserTimezone } from '../../utils';
import moment from 'moment';
import { userManager } from '../../service/UserManager';
import { formatMoney } from './MoneyFormat';
import { withTranslation } from 'react-i18next';
import { SubscriptionStatus } from '../../service/types';

class BillingWidget extends React.Component {
    makeMainButtonName = (sub) => {
        let name = null;

        switch (sub.status) {
            case SubscriptionStatus.TRIAL:
                name = this.props.t('billing.billing_widget.subscribe');
                break;
            case SubscriptionStatus.CANCELLED:
            case SubscriptionStatus.DELETED:
                name = this.props.t('billing.billing_widget.re_subscribe');
                break;
            default:
                break;
        }
        return name;
    };

    formatDate = (date, user) => {
        if (date === null) {
            return '';
        }
        date = utcToUserTimezone(date, user);
        return moment.utc(date).format('MM/DD/YYYY');
    };

    createPaymentBlock(user, subscription) {
        const paymentMethod = subscription.paymentMethod + ' *' + (subscription.lastFourDigitsOfBankCard || '****');
        let element = null;
        switch (subscription.status) {
            case SubscriptionStatus.TRIAL:
                const text =
                    subscription.trialDays > 0
                        ? this.props.t('billing.billing_widget.remaining', { count: subscription.trialDays })
                        : this.props.t('billing.billing_widget.trial_expired');
                element = (
                    <React.Fragment>
                        <strong>
                            {this.props.t('billing.billing_widget.trial_ends', {
                                date: this.formatDate(subscription.expiresAt, user),
                            })}
                            <span style={{ color: 'orangered' }}> {text} </span>
                        </strong>
                    </React.Fragment>
                );
                break;
            case SubscriptionStatus.ACTIVE:
                element = (
                    <React.Fragment>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('billing.billing_widget.payment_method', {
                                    method: paymentMethod,
                                }),
                            }}
                        />
                    </React.Fragment>
                );
                break;
            case SubscriptionStatus.PAST_DUE:
                element = (
                    <React.Fragment>
                        <span
                            style={{ color: 'red', marginRight: 10 }}
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('billing.billing_widget.payment_method_failed', {
                                    method: paymentMethod,
                                }),
                            }}
                        ></span>
                        <Link
                            to={reverse(routes.admin.account.billing.update, {
                                accountId: this.props.myAccount ? undefined : this.props.accountId,
                            })}
                        >
                            Update
                        </Link>
                    </React.Fragment>
                );
                break;
            default:
                break;
        }
        return element;
    }

    createNextBillingBlock(user, subscription) {
        let element = null;
        switch (subscription.status) {
            case SubscriptionStatus.ACTIVE:
            case SubscriptionStatus.PAST_DUE:
                element = (
                    <React.Fragment>
                        <span>
                            {this.props.t('billing.billing_widget.next_payment', {
                                price: formatMoney(subscription.currency, subscription.subPriceTotalCur),
                                date: this.formatDate(subscription.nextBillDate, user),
                            })}
                        </span>
                    </React.Fragment>
                );
                break;
            default:
                break;
        }
        return element;
    }

    render() {
        const user = userManager.getCurrentUser();
        const { subscription } = this.props;
        const plan = subscription.plan;
        const nameMainButton = this.makeMainButtonName(subscription);
        const paymentBlock = this.createPaymentBlock(user, subscription);
        const nextBillingBlock = this.createNextBillingBlock(user, subscription);
        return (
            <div className="c-billing-widget">
                <Grid className="row" container alignItems="center" spacing={2}>
                    <Grid item>
                        <span>
                            {this.props.t('billing.plan')}: <strong>{plan.name}</strong>
                        </span>
                    </Grid>
                    <Grid item>
                        <Status status={subscription.status} />
                    </Grid>
                    <Grid item>
                        {nameMainButton !== null && (
                            <Link
                                to={reverse(routes.admin.account.billing.subscribe, {
                                    accountId: this.props.myAccount ? undefined : this.props.accountId,
                                })}
                            >
                                {nameMainButton}
                            </Link>
                        )}
                    </Grid>
                </Grid>
                <Grid className="row" container alignItems="center">
                    <Grid item xs={12}>
                        {paymentBlock}
                    </Grid>
                </Grid>
                {nextBillingBlock !== null && (
                    <Grid className="row" container alignItems="center">
                        <Grid item xs={12}>
                            {nextBillingBlock}
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

BillingWidget.propTypes = {
    subscription: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    myAccount: PropTypes.bool,
};

export const BillingWidgetComponent = withTranslation('translations', { withRef: true })(BillingWidget);

export default BillingWidget;
