import { makeAutoObservable } from 'mobx';
import { Routing, User } from 'interfaces';

interface EditRoutePropertiesData {
    name: string;
    user: User.User;
    dateStartAt: Date;
}

class RouteEditDialogManager {
    private _isOpen = false;
    private _data: EditRoutePropertiesData | null = null;
    private _onSave?: (data: Routing.Route.SaveExistsRouteData) => Promise<Routing.Route.Route>;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get name(): string {
        if (this._data === null) {
            throw new Error('Edit route properties modal window is not open.');
        }
        return this._data.name;
    }

    get user(): User.User {
        if (this._data === null) {
            throw new Error('Edit route properties modal window is not open.');
        }
        return this._data.user;
    }

    get dateStartAt(): Date | null {
        if (this._data === null) {
            throw new Error('Edit route properties modal window is not open.');
        }
        return this._data.dateStartAt;
    }

    save = (data: Routing.Route.SaveExistsRouteData): Promise<Routing.Route.Route> => {
        if (!this._onSave) {
            throw new Error('onSave handler not defined');
        }
        return this._onSave(data);
    };

    openModal = (
        onSave: (data: Routing.Route.SaveExistsRouteData) => Promise<Routing.Route.Route>,
        data: EditRoutePropertiesData,
    ) => {
        this._data = data;
        this._onSave = onSave;
        this._isOpen = true;
    };

    closeModal = () => {
        this._isOpen = false;
        this._onSave = undefined;
        this._data = null;
    };
}

export default RouteEditDialogManager;
