import { makeAutoObservable } from 'mobx';
import { SavedPlacePoint } from 'components/types';

type SelectFunction = (point: SavedPlacePoint) => Promise<void | undefined>;

class SavedPlacesManager {
    private _isOpen = false;
    private _onSelect?: SelectFunction;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    selectSavedPlace = async (point: SavedPlacePoint) => {
        if (this._onSelect) {
            await this._onSelect(point);
        }
        this.closeModal();
    };

    openModal = (onSelect?: SelectFunction) => {
        this._isOpen = true;
        this._onSelect = onSelect;
    };

    closeModal = () => {
        this._isOpen = false;
        this._onSelect = undefined;
    };
}

export default SavedPlacesManager;
