import { STANDARD_FIELDS } from '../../standardFields';
import { Record } from '../../service/RecordManager';
export const ORDERED_LEVELS = [
    STANDARD_FIELDS.GEO_STREET,
    STANDARD_FIELDS.GEO_CITY,
    STANDARD_FIELDS.GEO_COUNTY,
    STANDARD_FIELDS.GEO_STATE,
    STANDARD_FIELDS.GEO_COUNTRY,
];
export const getGeoTrueAddress = (record: Record): string => {
    const address: string[] = [];
    for (const level of ORDERED_LEVELS) {
        if (record[level]) {
            address.push(record[level]);
        }
    }
    let result = address.join(', ');
    if (record[STANDARD_FIELDS.GEO_HOUSE_NUMBER]) {
        result = `${record[STANDARD_FIELDS.GEO_HOUSE_NUMBER]} ${result}`;
    }
    if (record[STANDARD_FIELDS.GEO_ZIP]) {
        result += ' ' + record[STANDARD_FIELDS.GEO_ZIP];
    }
    return result;
};
