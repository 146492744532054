export const TAB_ACCOUNT_SOURCES = 'sources';
export const TAB_ACCOUNT_USERS = 'users';
export const TAB_ACCOUNT_PERMISSIONS = 'permissions';
export const TAB_ACCOUNT_SUBSCRIPTION_BILLING = 'subscription_billing';
export const TABS_ACCOUNT = [
    TAB_ACCOUNT_SOURCES,
    TAB_ACCOUNT_USERS,
    TAB_ACCOUNT_PERMISSIONS,
    TAB_ACCOUNT_SUBSCRIPTION_BILLING,
];
