import React from 'react';
import { Grid, Icon, IconButton, Tooltip } from '@material-ui/core';
import Confirmation from '../../Confirmation';
import { SortableElement } from 'react-sortable-hoc';
import { withTranslation } from 'react-i18next';
import ActionIcon from '../ActionIcon';
import { Action, SmallDragHandle } from '../SortableAction';
import FormulaInput from '../AbstractForm/FormulaInput';
import { getDetail } from 'service/WorkflowActionManager';

class CodeStatement extends Action {
    handleChange = (e: React.ChangeEvent<{ type: string; name: string; value: string; isTwigTemplate: boolean }>) => {
        const action = { ...this.props.action };

        action.details[e.target.name] = e.target.value;

        this.props.onActionChanged(this.props.actionIndex, action);
    };

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={1} alignItems="center" className="action-container">
                    <Grid item>
                        <SmallDragHandle />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container alignItems="center" direction="row" wrap="nowrap">
                            <ActionIcon type={this.props.action.type} />
                            <FormulaInput
                                fullWidth
                                margin="dense"
                                required
                                multiline
                                label=""
                                name="action"
                                value={getDetail('action', this.props.action.details, '')}
                                onChange={this.handleChange}
                                error={false}
                                callContext={this.props.action.callContext}
                                noTemplateWarning
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={this.props.contextName ? '' : 'hideable'}>
                        {this.renderContextNameDelimiter()}
                    </Grid>
                    <Grid item className={this.props.contextName ? '' : 'hideable'}>
                        {this.renderContextName()}
                    </Grid>
                    <Grid item className="hideable">
                        <Confirmation
                            text={this.props.t('workflow_actions.confirm.remove')}
                            onConfirm={this.props.onActionRemove.bind(this, this.props.actionIndex)}
                        >
                            <Tooltip title={this.props.t<string>('workflow_actions.remove.hint')}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    data-testid="workflow_actions.code_statement.confirm.remove"
                                >
                                    <Icon fontSize="small">delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </Confirmation>
                    </Grid>
                </Grid>

                {this.renderContextNameModal()}
            </React.Fragment>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SortableElement(CodeStatement));
