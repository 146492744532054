import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import merge from 'lodash/merge';

const styles = {
    badge: {
        border: '2px solid',
    },
};

const CustomBadge = ({ badgeStyle = {}, ...rest }) => {
    // 'merge' mutates the first argument, so an empty object is used to prevent the 'styles' constant from changing
    const classes = makeStyles(() => merge({}, styles, { badge: badgeStyle }))();
    return <Badge {...rest} classes={{ badge: classes.badge }} />;
};

export default CustomBadge;
