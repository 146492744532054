import React from 'react';
import {
    TextField,
    FormHelperText,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    DialogContentText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../locales/i18n';
import {
    workflowActionManager,
    getDetail,
    DETAIL_FIELDS,
    DETAIL_FIELD,
    DETAIL_FORMULA,
    DETAIL_CUSTOM,
} from '../../../service/WorkflowActionManager';
import { SortableContainer } from 'react-sortable-hoc';
import './../style.css';
import arrayMove from 'array-move';
import SortableField from './SortableField';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import { CallContext } from '../../utils/CallContext';
import {
    getActiveOrInactiveFieldByApiName,
    isActiveEntity,
    isFewLookup,
    isLookupFieldId,
    isLookupFieldName,
    isLookupFieldType,
    isMultipleLookupField,
    isReadonlyField,
    isSimpleField,
} from '../../../utils';
import AddIconButton from '../../CustomButton/AddIconButton';
import DialogConfirmation from '../../Confirmation/DialogConfirmation';
import AbstractForm from '../AbstractForm/AbstractForm';

const t = i18n.t.bind(i18n);

const SortableList = SortableContainer((props) => {
    return (
        <div>
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={5} style={{ position: 'relative' }}>
                    <InputLabel shrink={true} style={{ marginLeft: 36 }}>
                        {t('workflow_actions.form.create_record.field')}
                    </InputLabel>
                </Grid>
                <Grid item xs style={{ position: 'relative' }}>
                    <InputLabel shrink={true} style={{ marginLeft: 8 }}>
                        {t('workflow_actions.form.create_record.target_value')}
                    </InputLabel>
                </Grid>
            </Grid>
            {props.items.map((value, index) => (
                <SortableField
                    key={`item-${index}`}
                    index={index}
                    fieldIndex={index}
                    options={props.options}
                    selectedFields={props.selectedFields}
                    details={value}
                    loading={props.loading}
                    onFieldChange={props.onFieldChange}
                    onFormulaChange={props.onFormulaChange}
                    onRemove={props.onRemove}
                    entity={props.currentEntity}
                    callContext={props.callContext}
                    errors={props.errors}
                    accountId={props.accountId}
                    dataSourceId={props.dataSourceId}
                />
            ))}
        </div>
    );
});

class Form extends AbstractForm {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.currentDataSourceId = props.action.dsId;
        const selectedFields = new Set(
            getDetail(DETAIL_FIELDS, props.action.details, []).map((detail) => detail[DETAIL_FIELD]),
        );

        this.state = {
            ...this.state,
            emptyRequiredFields: null,
            emptyRequiredFieldsResolve: null,
            emptyRequiredFieldsReject: null,
            currentAction: cloneDeep(props.action),
            editCriteria: false,
            options: [],
            selectedFields,
        };
    }

    currentEntityWillUpdate(currentEntity) {
        const currentAction = this.replaceLookupFields(currentEntity.fields);
        const options = this.prepareOptions(currentEntity);
        this.currentDataSourceId = currentEntity.dataSource && currentEntity.dataSource.id;
        this.setState({ currentAction, options });
    }

    // Подменяем поля _NAME & _TYPE на _ID.
    replaceLookupFields = (fields) => {
        const currentAction = cloneDeep(this.state.currentAction);
        const currentActionFields = getDetail(DETAIL_FIELDS, currentAction.details, []);
        if (currentActionFields.length === 0) {
            return currentAction;
        }

        const fieldsMap = new Map();
        const lookupFieldIdMap = new Map();
        for (let field of fields) {
            fieldsMap.set(field.apiName, field);
            if (isLookupFieldId(field)) {
                lookupFieldIdMap.set(field.originalApiName, field);
            }
        }

        for (let i = 0; i < currentActionFields.length; i++) {
            const actionField = currentActionFields[i];
            if (actionField.custom) {
                continue;
            }

            const field = fieldsMap.get(actionField.field);
            if (!field) {
                continue;
            }

            if (isLookupFieldName(field) || isLookupFieldType(field)) {
                const lookupFieldId = lookupFieldIdMap.get(field.originalApiName);
                currentActionFields[i].field = lookupFieldId.apiName;
            }
        }

        if (!currentAction.details) {
            currentAction.details = {};
        }
        currentAction.details[DETAIL_FIELDS] = currentActionFields;
        return currentAction;
    };

    prepareOptions = (entity) => {
        const options = [
            {
                type: 'custom',
                label: t('workflow_actions.form.create_record.custom'),
                value: 'custom',
            },
        ];
        const fieldOptions = [];

        for (let field of entity.fields) {
            let label = field.label;
            if (this.isLookup(field)) {
                // TODO Не умеем работать с multiple лукапами.
                if (isMultipleLookupField(field)) {
                    continue;
                }
                if (!isFewLookup(field) && !isLookupFieldId(field)) {
                    continue;
                }
                label = field.label.replace(/\s\((ID)\)$/, '');
            }

            fieldOptions.push({
                type: 'field',
                label: label,
                value: field.id,
                groupBy: entity.label,
                field,
            });
        }

        return [...options, ...fieldOptions.sort((a, b) => a.label.localeCompare(b.label))];
    };

    isLookup = (field) => {
        return !!(field && field.lookupData && field.lookupData.type);
    };

    submit() {
        this.setState({
            errors: new Map(),
        });

        this.validate()
            .then(() => this.checkRequiredFields())
            .then(() => workflowActionManager.save(this.props.accountId, this.state.currentAction))
            .then((action) => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(action);
            })
            .catch((error) => {
                this.props.onSubmitError && this.props.onSubmitError(error.message);
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValid()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }
            const fields = getDetail(DETAIL_FIELDS, currentAction.details, []);
            if (fields.length === 0) {
                errors.set(DETAIL_FIELDS, t('errors.not_empty'));
            }

            for (const field of fields) {
                if (field[DETAIL_CUSTOM]) {
                    continue;
                }
                if (!field[DETAIL_FIELD]) {
                    errors.set(DETAIL_FIELDS, t('workflow_actions.form.create_record.fields.error'));
                    break;
                }
                const apiName = field[DETAIL_FIELD];
                if (this.state.currentEntity) {
                    const entityField = getActiveOrInactiveFieldByApiName(this.state.currentEntity, apiName);
                    if (entityField && entityField.isRequired && !field[DETAIL_FORMULA]) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.empty_error'));
                        break;
                    }
                    if (entityField && isReadonlyField(entityField)) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.readonly_error'));
                        break;
                    }
                    if (entityField?.isDeleted || entityField?.isIncluded === false) {
                        errors.set(DETAIL_FIELDS, t('automation_elements.form.fields.deactivated_error'));
                        break;
                    }
                }
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        }).catch((errors) => {
            this.setState({
                errors: errors.details || errors,
            });
            throw new Error(t('validation_errors'));
        });
    }

    checkRequiredFields = () => {
        return new Promise((resolve, reject) => {
            const { currentEntity, currentAction } = this.state;

            const emptyRequiredFields = [];
            const actionFields = new Set(
                getDetail(DETAIL_FIELDS, currentAction.details, []).map((field) => field[DETAIL_FIELD]),
            );

            for (let field of currentEntity.fields) {
                if (this.isRequiredField(field) && !actionFields.has(field.apiName)) {
                    emptyRequiredFields.push(field);
                }
            }

            if (emptyRequiredFields.length) {
                this.setState({
                    emptyRequiredFields,
                    emptyRequiredFieldsResolve: resolve,
                    emptyRequiredFieldsReject: reject,
                });
            } else {
                resolve();
            }
        }).catch(() => {
            throw new Error(t('workflow_actions.form.create_record.required_fields.error'));
        });
    };

    closeEmptyRequiredFieldsModal = () => {
        this.setState({ emptyRequiredFields: null, emptyRequiredFieldsResolve: null, emptyRequiredFieldsReject: null });
    };

    isRequiredField = (field) => {
        if (field.isRequired && field.apiName !== 'id') {
            if (isSimpleField(field)) {
                return true;
            }
            if (isMultipleLookupField(field)) {
                return false;
            }
            return isFewLookup(field) || isLookupFieldId(field);
        }
        return false;
    };

    handleField = (value, index, isCustom) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details);
        fields[index] = {
            ...fields[index],
            [DETAIL_FIELD]: value,
            [DETAIL_CUSTOM]: isCustom,
        };
        const selectedFields = new Set(fields.map((field) => field[DETAIL_FIELD]).filter((field) => field));
        this.setState({ selectedFields });
        this.updateDetails(DETAIL_FIELDS, fields);
    };

    handleFormula = (value, index) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details);
        fields[index] = {
            ...fields[index],
            [DETAIL_FORMULA]: value,
        };
        this.updateDetails(DETAIL_FIELDS, fields);
    };

    onAddDetail = () => {
        this.setState(
            (state) => {
                const currentAction = cloneDeep(state.currentAction);
                if (!currentAction.details || !Array.isArray(currentAction.details[DETAIL_FIELDS])) {
                    currentAction.details = { DETAIL_FIELDS: [] };
                }
                currentAction.details[DETAIL_FIELDS].push({});
                let errors = state.errors;
                if (errors.has(DETAIL_FIELDS)) {
                    errors = new Map(errors);
                    errors.delete(DETAIL_FIELDS);
                    if (errors.size === 0) {
                        this.props.onSubmitError(null);
                    }
                }
                return { currentAction, errors };
            },
            () => {
                this.props.onModified && this.props.onModified(true);
            },
        );
    };

    onRemoveDetail = (index) => {
        const details = this.state.currentAction.details;
        if (details && Array.isArray(details[DETAIL_FIELDS]) && details[DETAIL_FIELDS].length > 0) {
            this.setState(
                (state) => {
                    const currentAction = cloneDeep(state.currentAction);
                    const fields = currentAction.details[DETAIL_FIELDS].filter((detail, i) => i !== index);
                    currentAction.details[DETAIL_FIELDS] = fields;
                    const selectedFields = new Set(fields.map((field) => field[DETAIL_FIELD]).filter((field) => field));
                    return { currentAction, selectedFields };
                },
                () => {
                    this.props.onModified && this.props.onModified(true);
                },
            );
        }
    };

    onSortFieldsEnd = ({ oldIndex, newIndex }) => {
        const fields = getDetail(DETAIL_FIELDS, this.state.currentAction.details, []);
        const sortedFields = arrayMove(fields, oldIndex, newIndex);
        this.updateDetails(DETAIL_FIELDS, sortedFields);
    };

    render() {
        const { currentAction, errors, emptyRequiredFields, emptyRequiredFieldsResolve, emptyRequiredFieldsReject } =
            this.state;
        const requiredFields = emptyRequiredFields && emptyRequiredFields.map((field) => field.label).join('<br/>');

        return (
            <React.Fragment>
                <PureFormDialog
                    title={t('workflow_actions.form.create_record.entity.modal.title')}
                    onClose={this.props.onCancel}
                    open={!this.state.currentAction.callContext.isValid()}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off" ref={this.ref}>
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.create_record.name')}
                        data-testid="workflow_actions.form.create_record.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={this.state.currentAction.name || ''}
                        helperText={this.state.errors.get('name') || ''}
                        error={this.state.errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.create_record.api_name')}
                        data-testid="workflow_actions.form.create_record.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={this.state.currentAction.apiName || ''}
                        helperText={this.state.errors.get('apiName') || ''}
                        error={this.state.errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    {this.renderEntitySelect(currentAction, errors, { disabled: true })}

                    <FormControl fullWidth error={this.state.errors.has(DETAIL_FIELDS)}>
                        <Grid container spacing={1} style={{ marginTop: 20 }} alignItems="center">
                            <Grid item>
                                <b>{t('workflow_actions.form.create_record.fields')}</b>
                            </Grid>
                            <Grid item>
                                <AddIconButton small onClick={this.onAddDetail} />
                            </Grid>
                        </Grid>
                        {this.ref.current && this.state.loading === false && (
                            <SortableList
                                items={getDetail(DETAIL_FIELDS, this.state.currentAction.details, [])}
                                options={this.state.options}
                                selectedFields={this.state.selectedFields}
                                onSortEnd={this.onSortFieldsEnd}
                                useDragHandle
                                loading={this.state.loading}
                                onFieldChange={this.handleField}
                                onFormulaChange={this.handleFormula}
                                onRemove={this.onRemoveDetail}
                                currentEntity={this.state.currentEntity}
                                callContext={this.state.currentAction.callContext}
                                errors={this.state.errors}
                                accountId={this.props.accountId}
                                dataSourceId={this.currentDataSourceId}
                                helperClass="sortable-item"
                                getContainer={() => this.ref.current.closest('.c-form-dialog__content')}
                            />
                        )}
                        {this.state.errors.get(DETAIL_FIELDS) && (
                            <FormHelperText>{this.state.errors.get(DETAIL_FIELDS)}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="reevaluate"
                                checked={this.state.currentAction.reevaluate}
                                onChange={this.handleInputChange}
                                color="primary"
                            />
                        }
                        label={t('workflow_actions.form.create_record.reevaluate')}
                    />
                </form>

                <DialogConfirmation
                    open={!!emptyRequiredFields}
                    setOpen={this.closeEmptyRequiredFieldsModal}
                    onConfirm={() => emptyRequiredFieldsResolve && emptyRequiredFieldsResolve()}
                    onReject={emptyRequiredFieldsReject}
                    titleText={t('workflow_actions.form.create_record.required_fields.title')}
                    yesBtnText={t('workflow_actions.form.create_record.required_fields.yes')}
                    noBtnText={t('workflow_actions.form.create_record.required_fields.no')}
                >
                    <DialogContentText
                        dangerouslySetInnerHTML={{
                            __html: t('workflow_actions.form.create_record.required_fields.body', { requiredFields }),
                        }}
                    ></DialogContentText>
                </DialogConfirmation>
            </React.Fragment>
        );
    }
}

Form.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    action: PropTypes.shape({
        callContext: PropTypes.instanceOf(CallContext).isRequired,
    }).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export { Form as CreateRecordForm };
