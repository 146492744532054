import { WorkingHoursException } from '../types';
import moment from 'moment/moment';
import { cloneDeep } from 'lodash';
import { userManager } from '../../service/UserManager';
import { User } from '../../interfaces/user';
import { enqueueSnackbarService } from '../../service/MapPage';
import i18n from '../../locales/i18n';
import { CalendarEvent } from '../../service/Calendar/CalendarEventRepository';
import { CalendarEventManager } from '../../service/Calendar/CalendarEventManager';
import WorkSchedule from '../../service/WorkSchedule';

export enum EXCEPTION_TABS {
    TAB_CURRENT_AND_FUTURE,
    TAB_PAST,
}

export const filterAndSortWorkingHoursExceptions = (
    tab: EXCEPTION_TABS,
    workingHoursExceptions: WorkingHoursException[],
): WorkingHoursException[] => {
    return workingHoursExceptions
        .filter((workingHoursException: WorkingHoursException) => {
            const isPast = moment(workingHoursException.periodTo).isBefore(new Date(), 'day');

            return tab === EXCEPTION_TABS.TAB_PAST ? isPast : !isPast;
        })
        .sort((workingHoursException1: WorkingHoursException, workingHoursException2: WorkingHoursException) => {
            if (tab === EXCEPTION_TABS.TAB_PAST) {
                return (
                    new Date(workingHoursException2.periodTo).getTime() -
                    new Date(workingHoursException1.periodTo).getTime()
                );
            }

            return (
                new Date(workingHoursException1.periodFrom).getTime() -
                new Date(workingHoursException2.periodFrom).getTime()
            );
        });
};

export const saveWorkingHoursExceptions = (
    user: User,
    workingHoursExceptions: WorkingHoursException[],
): Promise<any> => {
    const clonedUser: any = cloneDeep(user);
    delete clonedUser.email;
    delete clonedUser.hasCrmAccount;
    delete clonedUser.leftReview;
    delete clonedUser.email;

    clonedUser.routingPreferences.workingHoursExceptions = workingHoursExceptions;

    return userManager.saveUser(clonedUser).catch(() => {
        enqueueSnackbarService.sendErrorMessage(
            i18n.t('working_hour_exceptions.add_exception_modal.error.could_not_save'),
        );
    });
};

export const shouldOpenOneDayExceptionDialog = async (
    calendarEvents: CalendarEvent[],
    calendarEventManager: CalendarEventManager,
): Promise<boolean> => {
    if (!calendarEvents.length) {
        return false;
    }

    const calendarEvent = calendarEvents[0];
    const user = await userManager.getAccountUser(userManager.getCurrentAccount().id, calendarEvent.ownerId);
    if (!user) {
        return false;
    }

    const userWorkingHours = WorkSchedule.getWorkingHours(new Date(calendarEvents[0].startDatetime), user);
    if (userWorkingHours) {
        return false;
    }

    for (let i = 0; i < calendarEvents.length; i++) {
        if (!calendarEvents[i].fixedTime) {
            return true;
        }
    }

    const eventManagerEvents = calendarEventManager.getEvents();
    const momentDate = moment(calendarEvent.startDatetime);
    for (let i = 0; i < eventManagerEvents.length; i++) {
        if (
            momentDate.isSame(eventManagerEvents[i].startDatetime, 'day') &&
            calendarEvent.id !== eventManagerEvents[i].id &&
            !eventManagerEvents[i].fixedTime
        ) {
            return true;
        }
    }

    return false;
};
