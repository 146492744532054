import React from 'react';
import {
    Box,
    Checkbox,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    ListSubheader,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import WorkflowGroups from '../WorkflowGroups';
import { TYPE_DEFAULT_ACTION_GROUP } from '../../service/WorkflowGroupManager';
import FormControlSelect from '../WorkflowActions/AbstractForm/FormControlSelect';
import FormControlMultipleSelect from '../WorkflowActions/AbstractForm/FormControlMultipleSelect';
import Hint from '../Hint';
import validator from 'validator';
import { CallContext } from '../utils/CallContext';
import FormControlIconSelect from '../WorkflowActions/AbstractForm/FormControlIconSelect';
import { isActiveEntity, isSafeApiName } from '../../utils';
import config from '../../params';
import { v4 as uuidv4 } from 'uuid';
import copy from 'copy-to-clipboard';
import DialogConfirmation from '../Confirmation/DialogConfirmation';
import Icon from '@material-ui/core/Icon';
import dsManagerFactory from '../../service/DsManager';
import { signatureManager } from '../../service/SignatureManager';
import Backdrop from '../Backdrop';
import { debounce } from 'lodash';
import { withSnackbar } from 'notistack';
import { Workflow } from '../../interfaces';
import Location from './FormGroup/Location';
import Routing from './FormGroup/Routing';
import { SYSTEM_ENTITIES_API_NAMES } from '../constants';
import intersection from 'lodash/intersection';

const apiEndpointUrl = (accountId) => `${config.apiEndpoint}/v1/a/${accountId}/button/run/`;
const DEFAULT_ENDPOINT_NAME_WIDTH = 100;
const ENDPOINT_NAME_CHAR_WIDTH = 8.7;
const MODE_SYNC = 'sync';
const MODE_ASYNC = 'async';

const UrlContainer = withStyles({
    root: {
        margin: '10px 0',
        borderRadius: '5px',
        padding: '0 5px',
        backgroundColor: '#f5f5f5',
    },
})(Grid);
const UrlContainerItem = withStyles({
    root: {
        padding: '5px 10px 21px',
        lineBreak: 'anywhere',
        '& > span:first-child': {
            lineHeight: '1.5rem',
            marginTop: '16px',
            display: 'inline-block',
        },
    },
})(Grid);

class WorkflowButtonsForm extends React.PureComponent {
    constructor(props) {
        super(props);

        const systemDataSource = this.getSystemDataSource();

        this.state = {
            errors: new Map(),
            currentButton: cloneDeep(props.button),
            currentEntities: props.button.entities,
            callContext: null,
            showApiKeyModal: false,
            exposeApiEndpoint: !!props.button.apiEndpointName,
            systemApiKey: systemDataSource && systemDataSource.apiKey,
            urlSignature: props.button.signature,
            urlLoading: false,
            copied: false,
            apiEndpointNameWidth: DEFAULT_ENDPOINT_NAME_WIDTH,
        };

        this.state.currentButton.roles = this.state.currentButton.roles.map((role) =>
            Number.isInteger(role) ? role : role.id,
        );
        this.state.currentButton.entities = this.state.currentButton.entities.map((entity) =>
            Number.isInteger(entity) ? entity : entity.id,
        );
        this.state.callContext = CallContext.createFromArray(this.state.currentButton.entities);

        if (this.state.currentButton.roles.length === 0) {
            for (let role of props.roles) {
                if (role.code === 'admin') {
                    this.state.currentButton.roles.push(role.id);
                    break;
                }
            }
        }

        this.copyTimeout = null;

        this.refreshSignatureDebounce = debounce(this.refreshSignature.bind(this), 500);

        this.apiEndpointNameRef = React.createRef();
        this.callbackUrlRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.currentButton.isActive === props.button.isActive) {
            return null;
        }

        state.currentButton.isActive = props.button.isActive;

        return state;
    }

    componentWillUnmount() {
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout);
            this.copyTimeout = null;
        }
    }

    submit() {
        this.setState(
            {
                errors: new Map(),
            },
            () => {
                let promise = Promise.resolve();
                const systemDataSource = this.getSystemDataSource();
                if (systemDataSource && systemDataSource.apiKey !== this.state.systemApiKey) {
                    systemDataSource.apiKey = this.state.systemApiKey;
                    const dsManager = dsManagerFactory.getManager(this.props.account.id);
                    promise = dsManager.save(systemDataSource, false);
                }

                promise
                    .then(() => {
                        return this.validate();
                    })
                    .then((button) => {
                        return this.props.onSave(button);
                    })
                    .then((button) => {
                        this.props.onSubmitSuccess(button);
                    })
                    .catch((errors) => {
                        this.props.onSubmitError();
                        this.setState({
                            errors: errors.details ? new Map(errors.details) : errors,
                        });
                    });
            },
        );
    }

    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentButton, exposeApiEndpoint, currentEntities } = this.state;

            if (validator.isEmpty(currentButton.name)) {
                errors.set('name', this.props.t('errors.not_empty'));
            }
            if (validator.isEmpty(currentButton.apiName)) {
                errors.set('apiName', this.props.t('errors.not_empty'));
            }
            if (validator.isEmpty(currentButton.label)) {
                errors.set('label', this.props.t('errors.not_empty'));
            }
            if (currentButton.actionGroups.length === 0) {
                errors.set('actionGroups', this.props.t('errors.not_empty'));
            }
            if (currentButton.locations.length === 0) {
                errors.set('locations', this.props.t('errors.not_empty'));
            }
            if (
                currentButton.locations.includes(Workflow.Locations.WAYPOINT) &&
                validator.isEmpty(currentButton.icon ?? '')
            ) {
                errors.set('icon', this.props.t('errors.not_empty'));
            }
            if (exposeApiEndpoint && validator.isEmpty(currentButton.apiEndpointName)) {
                errors.set('apiEndpointName', this.props.t('errors.not_empty'));
            }
            if (!currentButton.locations.includes(Workflow.Locations.ROUTE_MENU)) {
                currentButton.routingStatus = null;
                currentButton.routingMode = null;
                currentButton.routingResultType = null;
            } else if (!currentButton.routingStatus) {
                errors.set('routingStatus', this.props.t('errors.not_empty'));
            }
            if (currentButton.routingStatus === Workflow.RoutingStatus.DESIGN) {
                currentButton.routingMode = null;
                currentButton.routingResultType = null;
            }
            if ([Workflow.RoutingStatus.ALL, Workflow.RoutingStatus.DRAFT].includes(currentButton.routingStatus)) {
                if (!currentButton.routingMode) {
                    errors.set('routingMode', this.props.t('errors.not_empty'));
                }
                if (!currentButton.routingResultType) {
                    errors.set('routingResultType', this.props.t('errors.not_empty'));
                }
            }
            if (!exposeApiEndpoint) {
                currentButton.apiEndpointName = '';
                currentButton.async = false;
                currentButton.auth = null;
                currentButton.signature = null;
            }
            if (!currentButton.async) {
                currentButton.callbackUrl = '';
            } else if (!currentButton.callbackUrl || validator.isEmpty(currentButton.callbackUrl)) {
                errors.set('callbackUrl', this.props.t('errors.not_empty'));
            } else if (
                !validator.isURL(currentButton.callbackUrl, { require_protocol: true, protocols: ['http', 'https'] })
            ) {
                errors.set('callbackUrl', this.props.t('errors.invalid_value'));
            }

            for (const entity of currentEntities) {
                if (!isActiveEntity(entity)) {
                    errors.set('entities', this.props.t('automation_elements.form.entity.inactive_error'));
                    break;
                }
            }

            if (errors.size === 0) {
                resolve(currentButton);
                return;
            }

            reject(errors);
        });
    }

    handleEntitiesChange = (event) => {
        const name = event.target.name;
        const value = event.target.value.filter((v) => v);

        const callContext = CallContext.createFromArray(value);
        const currentEntities = this.getEntities(new Set(value));

        const currentButton = cloneDeep(this.state.currentButton);
        currentButton[name] = value;

        if (!this.isRouteObjectIncluded(value)) {
            currentButton.locations = currentButton.locations.filter(
                (location) => location !== Workflow.Locations.ROUTE_MENU,
            );
        }

        let errors = this.state.errors;
        if (errors.has(name)) {
            errors = new Map(errors);
            errors.delete(name);
        }

        this.setState({ currentButton, currentEntities, errors, callContext }, () => {
            this.props.onModified && this.props.onModified(true);
        });
    };

    handleInputChange = (event, callback = null) => {
        const target = event.target;
        if (['apiName', 'apiEndpointName'].includes(target.name) && !isSafeApiName(target.value)) {
            return;
        }

        const currentButton = cloneDeep(this.state.currentButton);

        let value = target.type === 'checkbox' ? !currentButton[target.name] : target.value;
        let callContext = this.state.callContext;
        if (target.name === 'entities') {
            value = value.filter((v) => v);
            callContext = CallContext.createFromArray(value);
        } else if (target.name === 'async') {
            value = !!value;
        }

        currentButton[target.name] = value;

        let errors = this.state.errors;
        if (errors.has(target.name)) {
            errors = new Map(errors);
            errors.delete(target.name);
        }

        this.setState({ currentButton, errors, callContext }, () => {
            callback && callback();
            this.props.onModified && this.props.onModified(true);
        });
    };

    refreshSignature = () => {
        if (
            this.state.currentButton.auth !== Workflow.Authentication.URL_SIGNATURE ||
            !this.state.currentButton.apiEndpointName
        ) {
            return;
        }

        this.setState({ urlLoading: true });
        const url = this.getApiEndpointUrlWithoutSignature();
        signatureManager
            .sign(this.props.account.id, url)
            .then((response) => {
                this.setState((state) => {
                    const currentButton = cloneDeep(state.currentButton);
                    currentButton.signature = response.signature;
                    return { urlLoading: false, urlSignature: response.signature, currentButton };
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({ urlLoading: false });
            });
    };

    handleActionGroups = (actionGroups) => {
        this.handleInputChange({
            target: {
                type: 'actionGroups',
                name: 'actionGroups',
                value: actionGroups,
            },
        });
    };

    handleExposeApiEndpoint = (event) => {
        if (event.target.checked && !this.state.systemApiKey) {
            this.setState({ showApiKeyModal: true });
        } else {
            this.setState({ exposeApiEndpoint: event.target.checked }, () => {
                this.state.exposeApiEndpoint && this.apiEndpointNameRef.current.focus();
                this.props.onModified && this.props.onModified(true);
            });
            if (!this.state.currentButton.auth) {
                this.handleInputChange({ target: { name: 'auth', value: Workflow.Authentication.API_KEY } });
            }
        }
    };

    confirmApiKeyModal = () => {
        const systemApiKey = uuidv4();
        this.setState({ showApiKeyModal: false, exposeApiEndpoint: true, systemApiKey });
    };

    closeApiKeyModal = () => {
        this.setState({ showApiKeyModal: false });
    };

    handleCallMode = (event) => {
        const value = event.target.value === MODE_ASYNC;
        this.handleInputChange(
            {
                target: { name: event.target.name, value },
            },
            () => {
                this.refreshSignature();
                value && this.callbackUrlRef.current.focus();
            },
        );
    };

    handleAuth = (event) => {
        this.handleInputChange(event, this.refreshSignature);
    };

    handleApiEndpointName = (event) => {
        const value = event.target.value;
        if (value.length * ENDPOINT_NAME_CHAR_WIDTH > DEFAULT_ENDPOINT_NAME_WIDTH) {
            this.setState({ apiEndpointNameWidth: value.length * ENDPOINT_NAME_CHAR_WIDTH });
        } else {
            this.setState({ apiEndpointNameWidth: DEFAULT_ENDPOINT_NAME_WIDTH });
        }
        this.handleInputChange(event, this.refreshSignatureDebounce);
    };

    handleCallbackUrl = (event) => {
        this.handleInputChange(event, this.refreshSignatureDebounce);
    };

    prepareEntityOptions = () => {
        const invalidOptions = [];
        const options = [];

        for (let entity of this.state.currentEntities) {
            if (entity.deletedAt || entity.isIncluded === false || entity.dataSource?.deletedAt) {
                invalidOptions.push(
                    <ListSubheader key={'invalid_' + entity.dataSource?.id}>
                        <Box color="error.main">{entity.dataSource?.name}</Box>
                    </ListSubheader>,
                );
                invalidOptions.push(
                    <MenuItem key={entity.id} value={entity.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        for (let dataSource of this.props.dataSources) {
            options.push(<ListSubheader key={dataSource.id}>{dataSource.name}</ListSubheader>);
            for (let entity of dataSource.entities) {
                options.push(
                    <MenuItem key={entity.id} value={entity.id}>
                        {entity.label}
                    </MenuItem>,
                );
            }
        }

        return [...invalidOptions, ...options];
    };

    getEntities = (entityIdsSet) => {
        const entities = [];

        for (let entity of this.state.currentEntities) {
            if (entityIdsSet.has(entity.id)) {
                entities.push(entity);
                entityIdsSet.delete(entity.id);
            }
        }

        dsLoop: for (let dataSource of this.props.dataSources) {
            for (let entityCounter of dataSource.entityCounters) {
                if (entityIdsSet.size === 0) {
                    break dsLoop;
                }
                if (entityIdsSet.has(entityCounter.entity.id)) {
                    entities.push({ ...entityCounter.entity, dataSource: entityCounter.dataSource });
                }
            }
        }

        return entities;
    };

    hasInvalidEntity = () => {
        for (let entity of this.state.currentEntities) {
            if (entity.deletedAt || !entity.isIncluded || entity.dataSource?.deletedAt) {
                return true;
            }
        }
        return false;
    };

    renderSelectedEntityValue = () => {
        const { currentEntities } = this.state;
        return (
            <span>
                {currentEntities.map((entity, index) => {
                    const label = entity.dataSource.name + ' > ' + entity.label;
                    let error;
                    if (entity.dataSource?.deletedAt) {
                        error = this.props.t('automation_elements.inactive_data_source');
                    } else if (entity.deletedAt || !entity.isIncluded) {
                        error = this.props.t('automation_elements.inactive_entity');
                    }

                    let component = label;
                    if (error) {
                        component = (
                            <Box color="error.main" component="span">
                                <Tooltip title={error}>
                                    <i style={{ marginRight: 3 }} className="fas fa-circle-exclamation" />
                                </Tooltip>
                                {label}
                            </Box>
                        );
                    }

                    return (
                        <React.Fragment key={index}>
                            {index > 0 && index < currentEntities.length && ', '}
                            {component}
                        </React.Fragment>
                    );
                })}
            </span>
        );
    };

    renderSelectedLocationValue = (locations) => {
        return locations.map((location) => this.props.t('workflow_buttons.locations.' + location)).join(', ');
    };

    renderSelectedProfileValue = (profileIds) => {
        const items = [];

        for (let profileId of profileIds) {
            for (let profile of this.props.roles) {
                if (profile.id === profileId) {
                    items.push(profile.name);
                }
            }
        }

        return items.join(', ');
    };

    handleCopy = () => {
        copy(this.getApiEndpointUrl());

        this.setState({ copied: true });
        this.copyTimeout = setTimeout(() => {
            this.setState({ copied: false });
            this.copyTimeout = null;
        }, 3000);
    };

    getSystemDataSource = () => {
        for (let dataSource of this.props.dataSources) {
            if (dataSource.isSystem) {
                return dataSource;
            }
        }

        return null;
    };

    getApiEndpointUrl = () => {
        const { currentButton } = this.state;
        if (!currentButton.apiEndpointName) {
            return null;
        }
        return [
            apiEndpointUrl(this.props.account.id),
            currentButton.apiEndpointName,
            this.getApiEndpointUrlParams(),
        ].join('');
    };

    getApiEndpointUrlWithoutSignature = () => {
        const { currentButton } = this.state;
        if (!currentButton.apiEndpointName) {
            return null;
        }
        return [
            apiEndpointUrl(this.props.account.id),
            currentButton.apiEndpointName,
            this.getApiEndpointUrlParams(false, true),
        ].join('');
    };

    getApiEndpointUrlParams = (truncate = false, excludeSignature = false) => {
        let { currentButton, systemApiKey = '', urlSignature = null } = this.state;

        const params = ['?async=' + +currentButton.async];
        if (currentButton.async && currentButton.callbackUrl) {
            params.push('callback_url=' + encodeURIComponent(currentButton.callbackUrl || ''));
        }

        if (truncate) {
            systemApiKey = systemApiKey && systemApiKey.slice(0, 7) + '...';
            urlSignature = urlSignature && urlSignature.slice(0, 7) + '...';
        }

        if (currentButton.auth === Workflow.Authentication.API_KEY) {
            params.push('apikey=' + systemApiKey);
        } else if (urlSignature && !excludeSignature) {
            params.push('signature=' + urlSignature);
        }

        return params.join('&');
    };

    isRouteObjectIncluded = (entities) => {
        if (!entities) {
            entities = this.state.currentButton.entities;
        }
        const systemDataSource = this.props.dataSources.find((dataSource) => dataSource.isSystem);
        const routeObjectIds =
            systemDataSource &&
            systemDataSource.entityCounters
                .filter((counter) => {
                    return [SYSTEM_ENTITIES_API_NAMES.route, SYSTEM_ENTITIES_API_NAMES.routingSession].includes(
                        counter.entity.apiName,
                    );
                })
                .map((counter) => counter.entity.id);
        return !!intersection(routeObjectIds, entities).length;
    };

    renderApiEndpointControl = () => {
        const { currentButton, errors, copied } = this.state;
        const params = this.getApiEndpointUrlParams(true);

        return (
            <FormGroup style={{ padding: '0 28px' }}>
                <Grid container alignItems="center">
                    <Grid item>
                        <FormLabel>
                            {this.props.t('workflow_buttons.form.expose_as_api_endpoint.call_mode')}{' '}
                            <Hint>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.t(
                                            'workflow_buttons.form.expose_as_api_endpoint.call_mode.hint',
                                        ),
                                    }}
                                />
                            </Hint>
                            :
                        </FormLabel>
                    </Grid>
                    <Grid item style={{ padding: '0 10px' }}>
                        <RadioGroup
                            name="async"
                            value={currentButton.async ? MODE_ASYNC : MODE_SYNC}
                            onChange={this.handleCallMode}
                            aria-label="call mode"
                            row
                        >
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                value={MODE_SYNC}
                                label={this.props.t('workflow_buttons.form.expose_as_api_endpoint.sync')}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                value={MODE_ASYNC}
                                label={this.props.t('workflow_buttons.form.expose_as_api_endpoint.async')}
                            />
                        </RadioGroup>
                    </Grid>
                    {currentButton.async && (
                        <Grid item>
                            <TextField
                                label={this.props.t('workflow_buttons.form.expose_as_api_endpoint.callback_url')}
                                fullWidth
                                name="callbackUrl"
                                value={currentButton.callbackUrl || ''}
                                helperText={errors.get('callbackUrl') || ''}
                                error={errors.has('callbackUrl')}
                                InputProps={{ disableUnderline: false }}
                                onChange={this.handleCallbackUrl}
                                size="small"
                                data-testid="workflow_buttons.form.callback_url"
                                inputRef={this.callbackUrlRef}
                                style={{ marginBottom: 13 }}
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid container alignItems="center">
                    <Grid item>
                        <FormLabel>
                            {this.props.t('workflow_buttons.form.expose_as_api_endpoint.authentication')}{' '}
                            <Hint>
                                {this.props.t('workflow_buttons.form.expose_as_api_endpoint.authentication.hint')}
                            </Hint>
                            :
                        </FormLabel>
                    </Grid>
                    <Grid item style={{ padding: '0 10px' }}>
                        <RadioGroup
                            name="auth"
                            value={currentButton.auth}
                            onChange={this.handleAuth}
                            aria-label="authentication"
                            row
                        >
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                value={Workflow.Authentication.API_KEY}
                                label={
                                    <span>
                                        {this.props.t(
                                            'workflow_buttons.form.expose_as_api_endpoint.authentication.api_key',
                                        )}{' '}
                                        <Hint>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props.t(
                                                        'workflow_buttons.form.expose_as_api_endpoint.authentication.api_key.hint',
                                                    ),
                                                }}
                                            />
                                        </Hint>
                                    </span>
                                }
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                value={Workflow.Authentication.URL_SIGNATURE}
                                label={
                                    <span>
                                        {this.props.t(
                                            'workflow_buttons.form.expose_as_api_endpoint.authentication.signature',
                                        )}{' '}
                                        <Hint>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props.t(
                                                        'workflow_buttons.form.expose_as_api_endpoint.authentication.signature.hint',
                                                    ),
                                                }}
                                            />
                                        </Hint>
                                    </span>
                                }
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Backdrop loading={this.state.urlLoading}>
                    <UrlContainer container alignItems="center">
                        <UrlContainerItem item xs>
                            <span className="text-muted">{apiEndpointUrl(this.props.account.id)}</span>
                            <TextField
                                label="Endpoint name"
                                name="apiEndpointName"
                                value={currentButton.apiEndpointName || ''}
                                error={errors.has('apiEndpointName')}
                                InputProps={{
                                    style: {
                                        fontFamily: 'monospace',
                                        lineHeight: 'inherit',
                                        width: this.state.apiEndpointNameWidth,
                                        maxWidth: 250,
                                    },
                                }}
                                size="small"
                                onChange={this.handleApiEndpointName}
                                data-testid="workflow_buttons.form.api_endpoint_name"
                                inputRef={this.apiEndpointNameRef}
                            />
                            <span className="text-muted">{params}</span>
                        </UrlContainerItem>
                        <Grid item>
                            <Tooltip
                                open={copied}
                                title={this.props.t('workflow_buttons.form.expose_as_api_endpoint.copied')}
                            >
                                <Box color={copied ? 'success.main' : 'text.primary'} component="span">
                                    <IconButton
                                        aria-label="copy"
                                        color="inherit"
                                        onClick={this.handleCopy}
                                        disabled={!currentButton.apiEndpointName}
                                    >
                                        <Icon className={copied ? 'fas fa-check' : 'fas fa-copy'} fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Grid>
                    </UrlContainer>
                </Backdrop>
                {errors.has('apiEndpointName') && (
                    <FormHelperText error>{errors.get('apiEndpointName')}</FormHelperText>
                )}
            </FormGroup>
        );
    };

    render() {
        const button = this.state.currentButton;
        const { errors, exposeApiEndpoint } = this.state;
        const { sharedMapAllowButtons } = this.props.account.subscription;
        const isRouteObjectIncluded = this.isRouteObjectIncluded();

        return (
            <form noValidate autoComplete="off">
                <TextField
                    autoFocus
                    label={this.props.t('workflow_buttons.form.name')}
                    data-testid="workflow_buttons.form.name"
                    fullWidth
                    margin="dense"
                    name="name"
                    value={button.name || ''}
                    helperText={errors.get('name') || ''}
                    error={errors.has('name')}
                    InputProps={{ disableUnderline: false }}
                    onChange={(event) => this.handleInputChange(event)}
                />
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <TextField
                            label={this.props.t('workflow_buttons.form.apiName')}
                            data-testid="workflow_buttons.form.apiName"
                            fullWidth
                            margin="dense"
                            name="apiName"
                            value={button.apiName || ''}
                            helperText={errors.get('apiName') || ''}
                            error={errors.has('apiName')}
                            InputProps={{ disableUnderline: false }}
                            onChange={(event) => this.handleInputChange(event)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlSelect
                            label={
                                <span>
                                    {this.props.t('workflow_buttons.form.visibilityType')}{' '}
                                    <Hint>{this.props.t('workflow_buttons.form.visibilityType.hint')}</Hint>
                                </span>
                            }
                            fullWidth
                            margin="dense"
                            name="visibilityType"
                            value={button.visibilityType || ''}
                            helperText={errors.get('visibilityType') || ''}
                            error={errors.has('visibilityType')}
                            onChange={(event) => this.handleInputChange(event)}
                            data-testid="workflow_buttons.form.visibilityType"
                        >
                            {Object.values(Workflow.VisibilityType).map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {this.props.t('workflow_buttons.form.visibilityType.' + item)}
                                    </MenuItem>
                                );
                            })}
                        </FormControlSelect>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label={this.props.t('workflow_buttons.form.label')}
                            data-testid="workflow_buttons.form"
                            fullWidth
                            margin="dense"
                            name="label"
                            value={button.label || ''}
                            helperText={errors.get('label') || ''}
                            error={errors.has('label')}
                            InputProps={{ disableUnderline: false }}
                            onChange={(event) => this.handleInputChange(event)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlIconSelect
                            label={
                                <span>
                                    {this.props.t('workflow_buttons.form.icon')}{' '}
                                    <Hint>{this.props.t('workflow_buttons.form.icon.hint')}</Hint>
                                </span>
                            }
                            fullWidth
                            margin="dense"
                            name="icon"
                            value={button.icon || ''}
                            helperText={errors.get('icon') || ''}
                            error={errors.has('icon')}
                            InputProps={{ disableUnderline: false }}
                            onChange={(event) => this.handleInputChange(event)}
                            data-testid="workflow_buttons.form.icon"
                        />
                    </Grid>
                </Grid>
                <FormControlMultipleSelect
                    label={this.props.t('workflow_buttons.form.entities')}
                    fullWidth
                    margin="dense"
                    name="entities"
                    value={button.entities || []}
                    helperText={errors.get('entities') || ''}
                    error={this.hasInvalidEntity() || errors.has('entities')}
                    onChange={(event) => this.handleEntitiesChange(event)}
                    renderValue={this.renderSelectedEntityValue}
                    data-testid="workflow_buttons.form.entities"
                    showOkButton
                >
                    {this.prepareEntityOptions()}
                </FormControlMultipleSelect>

                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <FormControlMultipleSelect
                            label={this.props.t('workflow_buttons.form.locations')}
                            fullWidth
                            margin="dense"
                            name="locations"
                            value={button.locations || []}
                            helperText={errors.get('locations') || ''}
                            error={errors.has('locations')}
                            onChange={(event) => this.handleInputChange(event)}
                            renderValue={this.renderSelectedLocationValue}
                            data-testid="workflow_buttons.form.locations"
                        >
                            {Object.values(Workflow.Locations)
                                .filter((item) => {
                                    return item !== Workflow.Locations.ROUTE_MENU || isRouteObjectIncluded;
                                })
                                .map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {this.props.t('workflow_buttons.locations.' + item)}
                                        </MenuItem>
                                    );
                                })}
                        </FormControlMultipleSelect>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={
                                <span>
                                    {this.props.t('workflow_buttons.form.order')}{' '}
                                    <Hint>{this.props.t('workflow_buttons.form.order.hint')}</Hint>
                                </span>
                            }
                            data-testid="workflow_buttons.form.order"
                            fullWidth
                            margin="dense"
                            name="order"
                            value={button.order === null ? '' : button.order}
                            helperText={errors.get('order') || ''}
                            error={errors.has('order')}
                            type="number"
                            InputProps={{ disableUnderline: false }}
                            onChange={(event) => this.handleInputChange(event)}
                        />
                    </Grid>
                </Grid>

                <Routing
                    button={button}
                    onChange={(event) => this.handleInputChange(event)}
                    errors={errors}
                    isRouteObjectIncluded={isRouteObjectIncluded}
                />
                <Location button={button} onChange={(event) => this.handleInputChange(event)} />

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="exposeEndpoint"
                                checked={exposeApiEndpoint}
                                onChange={this.handleExposeApiEndpoint}
                                color="primary"
                                data-testid="workflow_buttons.form.expose_endpoint"
                            />
                        }
                        label={
                            <span>
                                <strong>{this.props.t('workflow_buttons.form.expose_as_api_endpoint')}</strong>{' '}
                                <Hint>{this.props.t('workflow_buttons.form.expose_as_api_endpoint.hint')}</Hint>
                            </span>
                        }
                    />
                    {exposeApiEndpoint && this.renderApiEndpointControl()}
                </FormGroup>

                <FormControlSelect
                    label={this.props.t('workflow_buttons.form.parallel_session')}
                    margin="dense"
                    name="parallelSession"
                    value={button.parallelSession || ''}
                    helperText={errors.get('parallelSession') || ''}
                    error={errors.has('parallelSession')}
                    onChange={(event) => this.handleInputChange(event)}
                    data-testid="workflow_buttons.form.parallel_session"
                >
                    {Object.values(Workflow.ParallelSession).map((item) => {
                        return (
                            <MenuItem key={item} value={item}>
                                {this.props.t('workflow_buttons.form.parallel_session.' + item)}
                            </MenuItem>
                        );
                    })}
                </FormControlSelect>

                <WorkflowGroups
                    name="actionGroups"
                    title={this.props.t('workflow_buttons.form.actionGroups')}
                    parentNamespace={button.namespace}
                    groupType={TYPE_DEFAULT_ACTION_GROUP}
                    groups={button.actionGroups}
                    errors={errors}
                    onChange={this.handleActionGroups}
                    account={this.props.account}
                    dataSources={this.props.dataSources}
                    callContext={this.state.callContext}
                    disableCallContextInheritance
                />

                <FormControlMultipleSelect
                    label={this.props.t('workflow_buttons.form.roles')}
                    fullWidth
                    margin="dense"
                    name="roles"
                    value={button.roles || []}
                    helperText={errors.get('roles') || ''}
                    error={errors.has('roles')}
                    onChange={(event) => this.handleInputChange(event)}
                    renderValue={this.renderSelectedProfileValue}
                    data-testid="workflow_buttons.form.roles"
                >
                    {this.props.roles.map((item) => {
                        if (item.forSharedMap && !sharedMapAllowButtons) {
                            return null;
                        }
                        return (
                            <MenuItem key={item.id} value={item.id} disabled={item.code === 'admin'}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </FormControlMultipleSelect>

                <DialogConfirmation
                    open={this.state.showApiKeyModal}
                    setOpen={this.closeApiKeyModal}
                    onConfirm={this.confirmApiKeyModal}
                    titleText={this.props.t('workflow_buttons.form.system_api_key')}
                    yesBtnText={this.props.t('workflow_buttons.form.system_api_key.yes')}
                    noBtnText={this.props.t('workflow_buttons.form.system_api_key.no')}
                >
                    <DialogContentText>{this.props.t('workflow_buttons.form.system_api_key.body')}</DialogContentText>
                </DialogConfirmation>
            </form>
        );
    }
}

WorkflowButtonsForm.propTypes = {
    account: PropTypes.object.isRequired,
    button: PropTypes.object.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSave: PropTypes.func.isRequired,
    onModified: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(WorkflowButtonsForm));
