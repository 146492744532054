import React, { createRef } from 'react';
import PropTypes from 'prop-types';

import Label from '../../../service/workflow_actions/forms/fields/Label';

import AbstractField from './AbstractField';

class HtmlField extends AbstractField {
    ref = createRef();

    state = {
        height: 100,
    };

    componentDidMount() {
        const iframe = this.ref.current;
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        iframeDocument.write(this.props.field.getValue());

        const bcr = iframeDocument.body?.getBoundingClientRect();

        if (bcr) {
            this.setState({
                height: Math.ceil(bcr.bottom + bcr.top),
            });
        }
    }

    renderDescription(field) {
        return null;
    }

    renderInput(field) {
        if (!field.getValue()) {
            return null;
        }

        return (
            <iframe
                ref={this.ref}
                width="100%"
                height={this.state.height}
                style={{ minHeight: 'calc(max-content + 20px)' }}
                title={field.getLabel() || 'html'}
                frameBorder={0}
                seamless={true}
            />
        );
    }
}

HtmlField.propTypes = {
    field: PropTypes.instanceOf(Label).isRequired,
    errors: PropTypes.instanceOf(Map).isRequired,
    onHandler: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default HtmlField;
