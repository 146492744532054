import React, { SyntheticEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatWithCommas, roundToDecimal } from '../../../utils';
import { Account, Subscription } from '../../../interfaces/account';
import { formatMoney } from '../MoneyFormat';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingButton from '../../LoadingButton';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import subscriptionManagerFactory, { TYPE_CHANGE } from '../../../service/SubscriptionManager';
import { WithSnackbarProps } from 'notistack';
import TranscriptionMinutesSlider from './TranscriptionMinutesSlider';
import {
    SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE,
    SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE,
} from '../constants';
import { intercomManager } from '../../../service/IntercomManager';

interface TranscriptionMinutesEditDialogProps extends WithTranslation, WithSnackbarProps {
    subscription: Subscription;
    account: Account;
    onCloseDialog: () => void;
}

interface TranscriptionMinutesEditDialogState {
    additionalTranscriptionMinutes: number;
    isLoading: boolean;
}

class TranscriptionMinutesEditDialog extends React.Component<
    TranscriptionMinutesEditDialogProps,
    TranscriptionMinutesEditDialogState
> {
    constructor(props: TranscriptionMinutesEditDialogProps) {
        super(props);

        this.state = {
            additionalTranscriptionMinutes: Math.floor(props.subscription.additionalTranscriptionSeconds / 60),
            isLoading: false,
        };
    }

    componentDidMount() {
        this.listenSubscriptionChangedEvent();
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
    }

    handleChangeTokens = (minutes: number) => {
        this.setState({
            additionalTranscriptionMinutes: minutes,
        });
    };

    getSubscriptionAdditionalTranscriptionMinutes = () => {
        return Math.floor(this.props.subscription.additionalTranscriptionSeconds / 60);
    };

    handleConfirmChange = () => {
        this.setState({
            isLoading: true,
        });

        const subManager = subscriptionManagerFactory.getManager(this.props.account.id);
        subManager
            .updateAdditionalTranscriptionSeconds(this.state.additionalTranscriptionMinutes * 60)
            .catch((error: { message: string; details: Map<string, string> }) => {
                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        this.props.onCloseDialog && this.props.onCloseDialog();
                        this.props.enqueueSnackbar &&
                            this.props.enqueueSnackbar(error.message, {
                                variant: 'error',
                                persist: true,
                            });
                    },
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    formTextAboutChange = (diffMinutes: number, newPrice: number) => {
        const minutes = this.state.additionalTranscriptionMinutes;
        const { subscription, t } = this.props;

        if (diffMinutes === 0) {
            return '';
        }

        if (this.getSubscriptionAdditionalTranscriptionMinutes() > minutes) {
            return t('billing.change_transcription_minutes_form.decreasing', {
                total: formatWithCommas(diffMinutes),
                sum: formatMoney(subscription.currency, newPrice),
                period: subscription.plan.period,
            });
        }

        return t('billing.change_transcription_minutes_form.adding_minutes', {
            total: formatWithCommas(diffMinutes),
            sum: formatMoney(subscription.currency, newPrice),
            period: subscription.plan.period,
        });
    };

    calculateNewPrices = () => {
        const { subscription } = this.props;
        const minutes = this.state.additionalTranscriptionMinutes;

        if (this.getSubscriptionAdditionalTranscriptionMinutes() === minutes) {
            return {
                newSubPrice: subscription.subPriceTotalCur,
                diffPrice: subscription.transcriptionSecondsPmCur,
                diffMinutes: 0,
            };
        }

        const diffMinutes = Math.abs(this.getSubscriptionAdditionalTranscriptionMinutes() - minutes);
        // transcription minutes have the same price with the routing credits
        const packageSize = subscription.plan.priceTranscriptionPm;
        const newPrice = (minutes / SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE) * packageSize;
        const diffPrice = Math.round((diffMinutes / SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE) * packageSize);
        let newSubPrice = subscription.subPriceTotalCur - subscription.transcriptionSecondsPmCur + newPrice;
        newSubPrice = roundToDecimal(newSubPrice, 2);

        return { diffPrice, newSubPrice, diffMinutes: diffMinutes };
    };

    wantMoreTokens = (e: SyntheticEvent) => {
        e.preventDefault();
        intercomManager.showNewMessage(
            this.props.t('billing.subscription_panel.change_transcription_minutes_intercom'),
        );
    };

    listenSubscriptionChangedEvent = () => {
        dispatcher.subscribe(
            events.EVENT_SUBSCRIPTION_CHANGED,
            this,
            (data: { subscription: Subscription; type: string }) => {
                const subscription = data.subscription;
                if (this.props.account.id === null || subscription.accountId !== this.props.account.id) {
                    return;
                }
                if (data.type === TYPE_CHANGE.ADDITIONAL_TRANSCRIPTION_SECONDS) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
                            this.props.onCloseDialog && this.props.onCloseDialog();
                            this.props.enqueueSnackbar &&
                                this.props.enqueueSnackbar(
                                    this.props.t('billing.change_transcription_minutes_form.minutes_changed'),
                                    { variant: 'success' },
                                );
                        },
                    );
                }
            },
        );
    };

    render() {
        const credits = this.state.additionalTranscriptionMinutes;
        const { subscription, t } = this.props;
        const plan = subscription.plan;
        const isNotChangedCredits = this.getSubscriptionAdditionalTranscriptionMinutes() === credits;
        const currency = subscription.currency;
        const price = subscription.plan.priceTranscriptionPm;
        const { diffPrice, newSubPrice, diffMinutes } = this.calculateNewPrices();
        const textChange = this.formTextAboutChange(diffMinutes, diffPrice);

        return (
            <Dialog className="change-routing-credits-form" open={true} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    {t('billing.change_transcription_minutes_form.h1')}
                    <IconButton
                        color="default"
                        onClick={this.props.onCloseDialog}
                        className="c-form-dialog__close"
                        data-testid="billing.change_transcription_minutes_form.close"
                    >
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" className="row">
                        <Grid item xs={6}>
                            {t('billing.change_seats_form.current_plan')}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>{plan.name}</strong>
                        </Grid>
                    </Grid>
                    <Grid container className="row">
                        <Grid item xs={6}>
                            {t('billing.change_transcription_minutes_form.current_minutes')}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>{formatWithCommas(this.getSubscriptionAdditionalTranscriptionMinutes())}</strong>
                            <Typography variant="subtitle2">
                                {t('billing.change_transcription_minutes_form.price_per', {
                                    price: formatMoney(currency, price),
                                    coefficient: formatWithCommas(SUBSCRIPTION_TRANSCRIPTION_MINUTES_PACKAGE_SIZE),
                                    period: t('billing.plan_form.period_per_' + plan.period),
                                })}
                            </Typography>
                            <Typography variant="subtitle2">
                                {t('billing.change_transcription_minutes_form.price_total', {
                                    price: formatMoney(subscription.currency, subscription.subPriceTotalCur),
                                    period: t('billing.plan_form.period_per_' + plan.period),
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="new-block">
                        <Grid item xs={6}>
                            {t('billing.change_transcription_minutes_form.new_number_minutes')}
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: -4 }}>
                            <TranscriptionMinutesSlider
                                value={this.state.additionalTranscriptionMinutes}
                                onChange={this.handleChangeTokens}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                {!isNotChangedCredits && (
                                    <Typography variant="subtitle2">
                                        {t('billing.change_seats_form.price_total', {
                                            price: formatMoney(subscription.currency, newSubPrice),
                                            period: t('billing.plan_form.period_per_' + plan.period),
                                        })}
                                    </Typography>
                                )}

                                {this.state.additionalTranscriptionMinutes ===
                                    SUBSCRIPTION_TRANSCRIPTION_MINUTES_MAX_PACKAGE_SIZE && (
                                    <Link href="#" onClick={this.wantMoreTokens}>
                                        {t('billing.subscription_panel.change_transcription_minutes_intercom_button')}
                                    </Link>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isNotChangedCredits && textChange && (
                        <Grid container className="row">
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', paddingTop: 4 }}>
                                    <Icon className="icon-info">info</Icon>
                                    <Typography variant="subtitle2">{textChange}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.props.onCloseDialog}
                        data-testid="change.transcription_minutes.button.cancel"
                    >
                        {t('button.cancel')}
                    </Button>
                    <LoadingButton
                        color="primary"
                        onClick={this.handleConfirmChange}
                        disabled={isNotChangedCredits}
                        loading={this.state.isLoading}
                        data-testid="change.transcription_minutes.button.confirm"
                    >
                        {t('button.confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(TranscriptionMinutesEditDialog);
