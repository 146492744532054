const MESSAGE_TYPE = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

const MESSAGE_TYPE_INPUT = {
    FIXED: 'fixed',
    FORMULA: 'formula',
};

const POSITION = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

const DEFAULT_DETAILS = {
    message: '',
    messageType: MESSAGE_TYPE.SUCCESS,
    messageTypeFormula: '',
    messageTypeInput: MESSAGE_TYPE_INPUT.FIXED,
    position: POSITION.TOP,
    autoHide: false,
};

export { MESSAGE_TYPE, MESSAGE_TYPE_INPUT, POSITION, DEFAULT_DETAILS };
