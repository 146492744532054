import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FormActions, FormBackdrop } from '../PureFormDialog/Form';
import { withTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import { signatureManager } from '../../service/SignatureManager';
import { withSnackbar } from 'notistack';

class SignatureModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            link: this.props.link,
            linkWithSignature: '',
            isCopied: false,
            loading: false,
        };

        this.copyTimeout = null;
    }

    handleSign = () => {
        this.setState({
            loading: true,
        });
        signatureManager
            .sign(this.props.accountId, this.state.link)
            .then((response) => {
                this.setState({
                    loading: false,
                    linkWithSignature: response.url,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    handleChange = (e) => {
        const { value } = e.target;
        this.setState({
            link: value,
            linkWithSignature: '',
        });
    };

    handleCopy = (e) => {
        e.preventDefault();
        copy(this.state.linkWithSignature);
        this.setState({
            isCopied: true,
        });
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout);
            this.copyTimeout = null;
        }
        this.copyTimeout = setTimeout(() => {
            this.setState({
                isCopied: false,
            });
            this.copyTimeout = null;
        }, 3000);
    };

    render() {
        const { link, linkWithSignature, isCopied, loading } = this.state;
        const { t } = this.props;

        return (
            <div className="c-shared-map-signature-modal">
                <FormBackdrop loading={loading}>
                    <p>{t('shared_map.signature_modal.p1')}</p>
                    <p>{t('shared_map.signature_modal.p2')}</p>
                    <TextField
                        label={t('shared_map.signature_modal.url.label')}
                        data-testid="shared_map.signature_modal.url"
                        fullWidth
                        value={link}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleChange}
                        autoFocus
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSign}
                        style={{ marginTop: '8px' }}
                        data-testid="shared_map.signature_modal.url.generate"
                    >
                        {t('shared_map.signature_modal.url.generate')}
                    </Button>
                    <TextField
                        style={{ marginTop: '26px' }}
                        label={t('shared_map.signature.modal.generated_url.label')}
                        data-testid="shared_map.signature.modal.generated_url"
                        fullWidth
                        value={linkWithSignature}
                        InputProps={{ disableUnderline: false }}
                        disabled={true}
                    />
                    <button
                        onClick={this.handleCopy}
                        className="btn-link"
                        disabled={!linkWithSignature}
                        style={{ marginTop: '8px' }}
                        data-testid="shared_map.link.copy"
                    >
                        {isCopied ? t('shared_map.link.copied') : t('shared_map.link.copy')}
                    </button>
                </FormBackdrop>
                <FormActions onClose={this.props.onClose} closeText={t('close')} />
            </div>
        );
    }
}

SignatureModal.propTypes = {
    accountId: PropTypes.number.isRequired,
    link: PropTypes.string,
};

export default withTranslation()(withSnackbar(SignatureModal));
