import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { workflowButtonManager } from '../../service/WorkflowButtonManager';
import WorkflowButtonList from './WorkflowButtonsList';
import dsManagerFactory from '../../service/DsManager';
import { accountsManager } from '../../service/AccountsManager';
import WorkflowButtonModal from './WorkflowButtonModal';
import cloneDeep from 'lodash/cloneDeep';
import { roleManager } from '../../service/RoleManager';
import CounterMap from 'service/counterMap';
import AutomationElementHeader from '../Workflow/AutomationElementHeader';
import dispatcher from '../../service/dispatcher';
import events from '../../events';

class WorkflowButtons extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            buttons: [],
            totalCount: 0,
            account: null,
            dataSources: null,
            roles: null,
            currentButton: null,
            currentButtonUpdate: false,
        };
        this.runningUpdate = {
            insert: new CounterMap(),
            update: new CounterMap(),
            delete: new CounterMap(),
        };
    }

    componentDidMount() {
        this.loadAccount();
        this.loadDataSources();
        this.loadProfiles();

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.status === 'complete') {
                this.loadDataSources();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadButtons = (requestParams) => {
        this.setState({ loading: true, buttons: [], totalCount: 0 });

        return workflowButtonManager
            .list(
                this.props.accountId,
                requestParams.filters,
                requestParams.sorting,
                requestParams.currentPage + 1,
                requestParams.pageSize,
            )
            .then((data) => {
                this.setState({ loading: false, buttons: data.items, totalCount: data.total });
                return data;
            });
    };

    loadAccount() {
        accountsManager
            .load(this.props.accountId)
            .then((account) => {
                this.setState({ account });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    loadDataSources() {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();

        this.setState({ dataSources: [] });

        manager
            .list()
            .then((data) => {
                data = data.map((source) => {
                    source.entities = source.entityCounters.map((entityCounter) => entityCounter.entity);
                    return source;
                });
                this.setState({ dataSources: data });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    loadProfiles() {
        roleManager.getAccountRoles(this.props.accountId).then((roles) => {
            this.setState({ roles: roles });
        });
    }

    handleUpdateReceived = ({ modificationType, buttonIds }) =>
        buttonIds.reduce(
            // child DataTable receives WS_WORKFLOW_ACTIONS_BUTTONS_UPDATED update events and can start data refresh
            // this component could decide whether data should be refresh or event must be ignored
            // this component holds set of CounterMap with id-count pair
            // counters are increased in updating methods, and decreasing by id in this method with each received event
            // when specific count goes below zero it means that data was updated elsewhere and child DataTable should load it
            // special case for inserting updates, because event from WS is received *before* api call (with button id in response) ends
            (shouldUpdate, buttonId) => {
                const counterId = modificationType !== 'insert' && buttonId;
                const buttonUpdated = this.runningUpdate[modificationType].decrement(counterId) === false;
                if (buttonUpdated && this.state.currentButton?.id === buttonId) {
                    this.setState({ currentButtonUpdate: modificationType });
                }
                return buttonUpdated || shouldUpdate;
            },
            false,
        );

    handleToggleButton = (button) => {
        button.isActive = !button.isActive;

        if (!button.id) {
            this.setState({ currentButton: cloneDeep(button) });
            return;
        }

        this.handleSave(button);
    };

    handleSave = (button) => {
        const toSave = cloneDeep(button);
        toSave.roles = toSave.roles.map((role) => (Number.isInteger(role) ? role : role.id));
        toSave.entities = toSave.entities.map((entity) => (Number.isInteger(entity) ? entity : entity.id));

        // eslint-disable-next-line
        toSave.order = toSave.order != 0 ? toSave.order : 0;

        const isUpdating = !!button.id;
        if (isUpdating) {
            this.runningUpdate.update.increment(button.id);
        } else {
            //n.b.: WS pingback lag is unpredictable, but in most cases it comes earlier than saving request ends (when not delayed)
            this.runningUpdate.insert.increment(false);
        }
        this.setState({ saving: true });
        return workflowButtonManager
            .save(this.props.accountId, toSave)
            .then((savedButton) => {
                this.setState((state) => {
                    const buttons = cloneDeep(state.buttons);

                    for (let [key, currentButton] of buttons.entries()) {
                        if (currentButton.id === savedButton.id) {
                            buttons[key] = savedButton;
                            return {
                                buttons: buttons,
                                currentButton: state.currentButton ? savedButton : null,
                                currentButtonUpdate: null,
                            };
                        }
                    }

                    buttons.unshift(savedButton);
                    return {
                        buttons: buttons,
                        currentButton: state.currentButton ? savedButton : null,
                        currentButtonUpdate: null,
                    };
                });
            })
            .then(() => {
                this.props.enqueueSnackbar(this.props.t('workflow_buttons.notification.saved'), { variant: 'success' });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    };

    handleRemoveButton = (button) => {
        this.runningUpdate.delete.increment(button.id);
        this.setState({ loading: true }, () => {
            workflowButtonManager.delete(this.props.accountId, button.id).then(() => {
                this.setState((state) => {
                    return {
                        buttons: state.buttons.filter((row) => row.id !== button.id),
                    };
                });
            });
        });
    };

    handleEditButton = (button) => {
        if (button === null) {
            button = workflowButtonManager.getDefaultButton();
        }

        this.setState({
            currentButton: button,
            currentButtonUpdate: null,
        });
    };

    handleClose = () => {
        this.setState({
            currentButton: null,
            currentButtonUpdate: null,
        });
    };

    render() {
        return (
            <React.Fragment>
                <AutomationElementHeader
                    title={this.props.t('main_menu.workflow_buttons')}
                    addButtonHint={this.props.t('workflow_buttons.hint.create')}
                    type={'buttons'}
                    showAddButton={
                        this.state.account !== null && this.state.dataSources !== null && this.state.roles !== null
                    }
                    accountId={this.props.accountId}
                    myAccount={this.props.myAccount}
                    onOpenModal={this.handleEditButton}
                />
                <div>
                    <WorkflowButtonList
                        buttons={this.state.buttons}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        accountId={this.props.accountId}
                        dataSources={this.state.dataSources ?? []}
                        onRequestData={this.loadButtons}
                        onEditButton={this.handleEditButton}
                        onRemoveButton={this.handleRemoveButton}
                        onToggleButton={this.handleToggleButton}
                        onUpdateReceived={this.handleUpdateReceived}
                    />
                </div>

                {this.state.account && this.state.currentButton && (
                    <WorkflowButtonModal
                        account={this.state.account}
                        dataSources={this.state.dataSources ?? []}
                        roles={this.state.roles ?? []}
                        onSaved={this.handleClose}
                        onSave={this.handleSave}
                        onCancel={this.handleClose}
                        onToggleButton={this.handleToggleButton}
                        button={this.state.currentButton}
                        buttonUpdate={this.state.currentButtonUpdate}
                        saving={this.state.saving}
                    />
                )}
            </React.Fragment>
        );
    }
}

WorkflowButtons.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    myAccount: PropTypes.bool.isRequired,
};

WorkflowButtons.defaultProps = {
    myAccount: false,
};

export default withTranslation()(withRouter(withSnackbar(WorkflowButtons)));
