import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { userManager } from '../../service/UserManager';
import { CircularProgress, AccordionDetails, Tooltip } from '@material-ui/core';
import LoadingButton from '../LoadingButton';
import entityManagerFactory from '../../service/EntityManager';
import { prospectingManager } from '../../service/ProspectingManager';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import './style.css';
import { withTranslation } from 'react-i18next';
import { PROSPECTING_MODULE } from '../Permissions/constants';
import { FormActions, FormAlert } from '../PureFormDialog/Form';
import withStyles from '@material-ui/core/styles/withStyles';
import ConstantInput from '../ConstantInput';
import SearchableFieldsListForm from '../FieldsListForm/SearchableFieldsListForm';
import FormDialog from '../FormDialog';
import AddIconButton from '../CustomButton/AddIconButton';
import ProspectStructureItem from './ProspectStructureItem';
import { FieldLookupType } from 'components/types';
import LiveSearchTableViewDialog from 'components/LiveUpdateTableForm/LiveSearchTableViewDialog';
import { Api } from '../../interfaces';
import ExportProspectLimitMessage from './ExportProspectLimitMessage';

const TARGET_FIELD_TYPES = [
    'bigint',
    'date',
    'datetime',
    'float',
    'integer',
    'smallint',
    'string',
    'text',
    'boolean',
    'text[]',
];

export const CONSTANT_ONLY_FIELD_TYPES = ['date', 'datetime'];

function trimFieldLabel(field) {
    return field.lookupData ? field.title.replace(/\s\((ID)\)$/, '') : field.title;
}

class ExportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            dataSourceId: 0,
            overwrite: false,
            loadingEntity: false,
            loading: false,
            entities: [],
            selectedEntity: null,
            showSearch: null,
            expanded: false,
            errors: new Map(),
            anchorEl: null,
            addFieldListModal: false,
            currentUserDSId: null,
            exporting: false,
        };
        this.entityManager = null;
        this.prospectFields = prospectingManager.getMainStructure();
        this.availableCrmFields = null;
    }

    componentDidMount() {
        const dataSourceId = this.props.dataSources.length > 0 ? parseInt(this.props.dataSources[0].id) : 0;
        const currentUserDSId =
            userManager
                .getCurrentUser()
                .externalUsers.find((externalUser) => externalUser.dataSourceId === dataSourceId)?.externalId ?? null;
        this.setState(
            {
                dataSourceId,
                currentUserDSId,
            },
            this.loadEntities(dataSourceId),
        );
    }

    handleChangeDataSource = (event) => {
        const dataSourceId = parseInt(event.target.value) || 0;
        const currentUserDSId =
            userManager
                .getCurrentUser()
                .externalUsers.find((externalUser) => externalUser.dataSourceId === dataSourceId)?.externalId ?? null;
        this.setState(
            {
                dataSourceId,
                selectedEntity: null,
                errors: new Map(),
                currentUserDSId,
            },
            () => {
                this.availableCrmFields = null;
                this.loadEntities(dataSourceId);
            },
        );
    };

    loadEntities = (dataSourceId) => {
        if (dataSourceId === 0) {
            this.setState({
                errorMessage: null,
                entities: [],
                loadingEntity: false,
            });
            return;
        }
        this.entityManager = entityManagerFactory.getManager(userManager.getCurrentUser().accountId, dataSourceId);
        let entities = [];
        let errorMessage = null;

        this.entityManager
            .getProspectsEntities(dataSourceId)
            .then((data) => {
                entities = data;
            })
            .catch(() => {
                errorMessage = this.props.t('prospecting.export_form.error.loading_objects');
            })
            .finally(() => {
                this.setState((state) => {
                    if (state.dataSourceId !== dataSourceId) {
                        return null;
                    }
                    return {
                        errorMessage,
                        entities,
                        loadingEntity: false,
                    };
                });
            });

        this.setState({
            loadingEntity: true,
        });
    };

    handleEntityChange = (event) => {
        let selectedEntity = null;
        const entityId = parseInt(event.target.value) || 0;
        if (entityId !== 0) {
            selectedEntity = this.state.entities.find((entity) => {
                return entity.id === entityId;
            });
        }
        const expanded = selectedEntity && selectedEntity.prospectStructure.length === 0;

        if (selectedEntity) {
            const fieldsMap = new Map();
            selectedEntity.fields.forEach((field) => fieldsMap.set(field.name, field));
            selectedEntity.fieldsMap = fieldsMap;

            selectedEntity.prospectStructure.forEach((prospectStructure) => {
                const field = fieldsMap.get(prospectStructure.fieldName);
                if (field) {
                    if (this.isOwnerField(field, selectedEntity.ownerFieldName)) {
                        prospectStructure.type = 'dsUserId';
                        delete prospectStructure.prospectFieldName;
                        delete prospectStructure.const;
                    } else if (!this.isProspectFieldMappingAvailable(field)) {
                        prospectStructure.type = 'const';
                        delete prospectStructure.prospectFieldName;
                    }
                }
            });
        }

        this.setState({
            selectedEntity,
            expanded: this.state.expanded || expanded,
        });
        this.availableCrmFields = null;
    };

    handleChangeOverwrite = (event) => {
        const overwrite = event.target.checked;

        this.setState({
            overwrite,
        });
    };

    validateFields = (entity) => {
        const errors = new Map(this.state.errors);

        entity.prospectStructure.forEach((item) => {
            if (['const', 'dsUserId'].indexOf(item.type) === -1 && !item.prospectFieldName) {
                errors.set(item.fieldName, {
                    text: this.props.t('prospecting.export_form.error.select_source_and_target'),
                    error: true,
                });
            }
            if (item.required && !item.prospectFieldName && !item.const && typeof item.const !== 'boolean') {
                errors.set(item.fieldName, {
                    text: this.props.t('prospecting.export_form.field_required'),
                    error: true,
                });
            }
        });

        return errors;
    };

    onError = (error, defaultMessage) => {
        console.error(error);

        if (error.exception !== Api.Exception.ExtraCodes.EXPORT_PROSPECT_LIMIT_EXCEEDED) {
            this.props.onSave(defaultMessage);

            return;
        }

        const { count = '', limit = '' } = error.errors || {};

        this.props.onSave(<ExportProspectLimitMessage count={count} limit={limit} />);
    };

    handleSubmit = async () => {
        const { dataSourceId, selectedEntity, overwrite, currentUserDSId } = this.state;

        const errors = this.validateFields(selectedEntity);
        let hasError = false;

        errors.forEach((value) => {
            if (value.error) {
                hasError = true;
            }
        });
        if (hasError) {
            this.setState({
                errors,
                errorMessage: this.props.t('prospecting.export_form.error.fields_not_selected'),
                expanded: true,
            });
            return;
        }

        const ids = [];
        this.props.ids.forEach((value, key) => {
            ids.push(key);
        });

        const dataSources = this.props.dataSources;
        const dataSource = dataSources.find((dataSource) => {
            return dataSource.id === dataSourceId;
        });
        const messageFailed = this.props.t('prospecting.export_form.error.export_failed', {
            size: ids.length,
            dataSource,
        });

        const apiKeyGoogle = this.props.apiKeys?.google ?? '';

        this.setState({
            exporting: true,
        });

        try {
            // first check limit by sending records without fields, only with prospectIds
            const intention = prospectingManager.getRecordsForExportIntention(ids, currentUserDSId);
            await this.entityManager.exportProspectsToEntityIntention(selectedEntity.id, intention, overwrite);

            // then build records for export using places api
            const structure = this.buildStructure(selectedEntity);
            const records = await prospectingManager.getRecordsForExport(ids, structure, apiKeyGoogle, currentUserDSId);

            // todo ui permission is not checked and prospect structure can be overwrited by old one
            const entityToSave = { id: selectedEntity.id, prospectStructure: selectedEntity.prospectStructure };
            await this.entityManager.saveEntities([entityToSave]);

            await this.entityManager.exportProspectsToEntity(selectedEntity.id, records, overwrite);
            this.props.onSave();
        } catch (e) {
            this.onError(e, messageFailed);
        }

        this.setState({
            loading: true,
            expanded: false,
            exporting: false,
        });
    };

    buildStructure = (entity) => {
        let structure = [];

        for (let item of entity.prospectStructure) {
            const crmField = this.getCrmField(entity, item.fieldName);
            if (item.type === 'const') {
                const value = item.const === null ? ConstantInput.getDefaultValue(crmField.type) : item.const;
                structure.push({
                    ...item,
                    const: value,
                    length: crmField.length,
                    originalApiName: crmField.originalApiName,
                    fieldType: crmField.type,
                    picklist: crmField.picklist,
                });
                continue;
            }
            structure.push({
                ...item,
                length: crmField.length,
                originalApiName: crmField.originalApiName,
                fieldType: crmField.type,
                picklist: crmField.picklist,
            });
        }

        return structure;
    };

    makeEntityBlock = (selectedEntity, entities, loadingEntity) => {
        if (loadingEntity) {
            return (
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.dataSourceId === 0) {
            return null;
        }

        if (entities.length === 0) {
            return <p>{this.props.t('prospecting.export_form.ds_not_have_entities_for_export')}</p>;
        }

        return (
            <FormControl fullWidth margin="dense">
                <InputLabel>{this.props.t('prospecting.export_form.object')}:</InputLabel>
                <Select
                    value={selectedEntity ? selectedEntity.id : 0}
                    onChange={this.handleEntityChange}
                    data-testid="prospecting.export_form.object"
                >
                    <MenuItem key={0} value={0} data-testid="prospecting.export_form.select_object">
                        {this.props.t('prospecting.export_form.select_object')}
                    </MenuItem>
                    {entities.map((entity) => {
                        return (
                            <MenuItem key={entity.id} value={entity.id}>
                                {entity.title}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    handleAlertClose = () => {
        this.setState({
            errorMessage: null,
        });
    };

    getProspectStructureItem = (fieldName) => {
        for (let i = 0; i < this.state.selectedEntity.prospectStructure.length; i++) {
            if (this.state.selectedEntity.prospectStructure[i].fieldName === fieldName) {
                return i;
            }
        }
        return -1;
    };

    handleFieldChange = (prospectStructureItem) => {
        const index = this.getProspectStructureItem(prospectStructureItem.fieldName);
        if (index === -1) {
            return;
        }

        const selectedEntity = { ...this.state.selectedEntity };
        selectedEntity.prospectStructure[index] = prospectStructureItem;

        const errors = new Map(this.state.errors);
        const error = this.validateItem(prospectStructureItem);
        if (!error) {
            errors.delete(prospectStructureItem.fieldName);
        } else {
            errors.set(prospectStructureItem.fieldName, error);
        }

        this.availableCrmFields = null;

        this.setState({
            selectedEntity,
            errors,
        });
    };

    handleFieldRemove = (prospectStructureItem) => {
        const index = this.getProspectStructureItem(prospectStructureItem.fieldName);
        if (index === -1) {
            return;
        }

        const selectedEntity = { ...this.state.selectedEntity };
        const fieldName = selectedEntity.prospectStructure[index].fieldName;
        selectedEntity.prospectStructure.splice(index, 1);

        const errors = new Map(this.state.errors);
        errors.delete(fieldName);

        this.availableCrmFields = null;

        this.setState({
            errors,
            selectedEntity,
        });
    };

    handleShowSearch = (prospectStructureItem) => {
        const index = this.getProspectStructureItem(prospectStructureItem.fieldName);
        if (index === -1) {
            return;
        }
        const field = this.state.selectedEntity.fieldsMap.get(prospectStructureItem.fieldName);
        if (!field) {
            return;
        }

        if (field.lookupData && field.lookupData.type === FieldLookupType.POLYMORPHIC) {
            field.value = {
                apiName: prospectStructureItem.const.apiName,
            };
        }

        this.setState({
            showSearch: {
                field,
                index,
            },
        });
    };

    handleCloseSearch = () => {
        this.setState({
            showSearch: null,
        });
    };

    handleSaveSearch = (record) => {
        const selectedEntity = { ...this.state.selectedEntity };
        const prospectStructureItem = {
            ...selectedEntity.prospectStructure[this.state.showSearch.index],
            const: record,
        };

        this.setState(
            {
                showSearch: null,
            },
            this.handleFieldChange(prospectStructureItem),
        );
    };

    validateItem = (item) => {
        const entity = this.state.selectedEntity;

        if (item.type === 'const') {
            return null;
        }

        if (!item.prospectFieldName) {
            return null;
        }

        const prospectField = this.getProspectField(entity, item.prospectFieldName);
        const crmField = this.getCrmField(entity, item.fieldName);
        if (prospectField.type === 'text' && crmField && crmField.length < 2000) {
            return {
                text: this.props.t('prospecting.export_form.error.long_text'),
                error: false,
            };
        }
        const check = this.checkType(entity, item);

        if (check.length) {
            return {
                text: check,
                error: true,
            };
        }

        return null;
    };

    getCrmField = (entity, name) => {
        return entity.fields.find((field) => {
            return field.name === name;
        });
    };

    getProspectField = (entity, name) => {
        const field = this.prospectFields[name];
        return field !== undefined ? field : null;
    };

    isProspectFieldMappingAvailable = (field) => {
        return field && !(field.lookupData !== null || CONSTANT_ONLY_FIELD_TYPES.includes(field.type));
    };

    isOwnerField = (field, ownerFieldName) => {
        return field && this.state.currentUserDSId && field.name === ownerFieldName;
    };

    handleExpand = (event, expanded) => {
        this.setState({
            expanded,
        });
    };

    checkType = (entity, item) => {
        if (!item.prospectFieldName) {
            return true;
        }

        const crmField = this.getCrmField(entity, item.fieldName);
        const prospectField = this.getProspectField(entity, item.prospectFieldName);

        if (!crmField || !prospectField) {
            return true;
        }

        let sourceFieldType = crmField.type === 'text' ? 'string' : crmField.type || 'string';
        const targetFieldType = prospectField.type === 'text' ? 'string' : prospectField.type || 'string';
        const sourceFieldLabel = crmField.title;
        const targetFieldLabel = prospectField.title;

        if (crmField.picklist && crmField.picklist.length) {
            if (sourceFieldType.includes('[]')) {
                sourceFieldType = 'mpicklist';
            } else {
                sourceFieldType = 'picklist';
            }
        }

        const compatibleTypes = {
            string: 'String, Picklist, Boolean, Array, MPicklist, Integer, Float, Bigint',
            picklist: 'String, Picklist',
            mpicklist: 'String, MPicklist, Array',
            array: 'String, MPicklist, Array',
            integer: 'Integer, Float, Bigint, Boolean, String',
            float: 'Integer, Float, Bigint, Boolean, String',
            bigint: 'Integer, Float, Bigint, Boolean, String',
            date: 'String, Date, DateTime',
            dateTime: 'String, Date, DateTime',
            boolean: 'String, Boolean, Text, Integer, Float, Bigint',
        };

        let compatibleTypesList = compatibleTypes[sourceFieldType] || 'String';
        if (['float', 'bigint', 'integer'].includes(sourceFieldType)) {
            compatibleTypesList = compatibleTypesList.replace('String', '');
        }

        if (!compatibleTypesList.toLowerCase().includes(targetFieldType)) {
            let typeList = compatibleTypes[targetFieldType].replace('MPicklist', 'Multi-Picklist');
            if (targetFieldType === 'string') {
                typeList = typeList.replace(', Integer, Float, Bigint', '');
            }

            const errorMessage = this.props.t('prospecting.export_form.error.field_message', {
                sourceFieldType: this.props.t(`prospecting.export_form.types.${sourceFieldType}`),
                targetFieldType: this.props.t(`prospecting.export_form.types.${targetFieldType}`),
                sourceFieldLabel,
                targetFieldLabel,
                typeList,
            });
            return errorMessage;
        }
        return false;
    };

    getAvailableCrmFields = () => {
        const entity = this.state.selectedEntity;
        if (this.availableCrmFields !== null) {
            return this.availableCrmFields;
        }
        this.availableCrmFields = [];
        const prospectFieldNames = new Set();
        entity.prospectStructure.forEach((item) => prospectFieldNames.add(item.fieldName));

        entity.fields.forEach((field) => {
            if (field.lookupData) {
                const match = field.name.match(/_(ID|NAME|TYPE)$/);
                if (match !== null && match[1] !== 'ID') {
                    return;
                }
            } else if (field.isReadOnly || field.isCustom || !TARGET_FIELD_TYPES.includes(field.type)) {
                return;
            }

            if (!prospectFieldNames.has(field.name)) {
                this.availableCrmFields.push(field);
            }
        });

        return this.availableCrmFields;
    };

    getAvailableCrmFieldsWithNameAndLabel = () => {
        return this.getAvailableCrmFields().map((field) => {
            return {
                name: field.name,
                label: trimFieldLabel(field),
                included: false,
            };
        });
    };

    handleAddNewFields = (fields) => {
        const selectedEntity = { ...this.state.selectedEntity };

        selectedEntity.prospectStructure = [...selectedEntity.prospectStructure];
        const ownerFieldName = selectedEntity.ownerFieldName;
        fields.forEach((field) => {
            if (!field.included) {
                return;
            }
            const _field = this.state.selectedEntity.fields.find((f) => f.name === field.name);
            let structureItem = {
                fieldName: field.name,
                required: false,
            };
            if (this.isOwnerField(_field, ownerFieldName)) {
                structureItem.type = 'dsUserId';
            } else if (this.isProspectFieldMappingAvailable(_field)) {
                structureItem.prospectFieldName = null;
                structureItem.type = 'field';
            } else {
                structureItem.const = _field ? ConstantInput.getDefaultValue(_field.type) : null;
                structureItem.type = 'const';
            }

            selectedEntity.prospectStructure.unshift(structureItem);
        });

        this.availableCrmFields = null;

        this.setState({
            selectedEntity,
            anchorEl: null,
            addFieldListModal: false,
        });
    };

    handleGetField = (fieldName) => {
        return this.state.selectedEntity.fieldsMap.get(fieldName) || null;
    };

    handleAddFieldListModalCancel = () => {
        this.setState({
            addFieldListModal: false,
        });
    };

    handleAddFieldListModalOpen = () => {
        this.setState({
            addFieldListModal: true,
        });
    };

    render() {
        const { t } = this.props;
        const {
            dataSourceId,
            overwrite,
            entities,
            loadingEntity,
            selectedEntity,
            showSearch,
            errors,
            currentUserDSId,
        } = this.state;
        const dataSources = this.props.dataSources;
        const EntityBlock =
            dataSourceId !== null ? this.makeEntityBlock(selectedEntity, entities, loadingEntity) : null;
        const showMapping = selectedEntity !== null;
        const mappingEnable = userManager.userHasAccessTo(
            PROSPECTING_MODULE.NAME,
            PROSPECTING_MODULE.FEATURES.EXPORT.NAME,
            PROSPECTING_MODULE.FEATURES.EXPORT.SUB_FEATURES.MODIFY_FIELD_MAPPING.NAME,
        );
        const dataSource = dataSources.find((dataSource) => {
            return dataSource.id === dataSourceId;
        });

        return (
            <div>
                {this.state.errorMessage !== null && (
                    <FormAlert onClose={this.handleAlertClose}>{this.state.errorMessage}</FormAlert>
                )}
                {dataSources.length === 0 && (
                    <FormAlert type="warning">{t('prospecting.export_form.no_insertable_datasource')}</FormAlert>
                )}
                <FormControl fullWidth margin="dense">
                    <InputLabel>{this.props.t('prospecting.export_form.data_sources')}:</InputLabel>
                    <Select
                        value={dataSourceId}
                        onChange={this.handleChangeDataSource}
                        data-testid="prospecting.export_form.data_sources"
                    >
                        <MenuItem key={0} value={0} data-testid="prospecting.export_form.select_data_sources">
                            {this.props.t('prospecting.export_form.select_data_sources')}
                        </MenuItem>
                        {dataSources.map((dataSource) => {
                            return (
                                <MenuItem key={dataSource.id} value={dataSource.id}>
                                    {dataSource.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {EntityBlock}
                <FormControlLabel
                    style={{ paddingTop: 10 }}
                    margin="dense"
                    control={
                        <Switch
                            checked={overwrite}
                            onChange={this.handleChangeOverwrite}
                            color="primary"
                            data-testid="prospecting.export_form.change_overwrite"
                        />
                    }
                    label={this.props.t('prospecting.export_form.label_overloading')}
                />
                {showMapping && mappingEnable && (
                    <Accordion expanded={this.state.expanded} onChange={this.handleExpand}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <span>{this.props.t('prospecting.export_form.field_mapping')}</span>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block' }}>
                            <p>
                                {t('prospecting.export_form.choose_crm_fields', {
                                    entityLabel: selectedEntity.title,
                                    dataSource,
                                })}
                            </p>
                            <table cellSpacing={0} cellPadding={2} className={this.props.classes.mappingTable}>
                                <thead>
                                    <tr>
                                        <th style={{ width: 36 }}>&nbsp;</th>
                                        <th style={{ width: 180 }}>
                                            <div>
                                                <span>{this.props.t('prospecting.export_form.field_crm')}</span>
                                                <Tooltip title={t('prospecting.export_form.add_field')}>
                                                    <AddIconButton
                                                        small
                                                        onClick={this.handleAddFieldListModalOpen}
                                                        disabled={this.getAvailableCrmFields().length === 0}
                                                        style={{ marginLeft: 12, marginRight: -52 }}
                                                    />
                                                </Tooltip>
                                                {this.state.addFieldListModal && (
                                                    <FormDialog
                                                        title={t('prospecting.export_form.add_field')}
                                                        onSave={this.handleAddNewFields}
                                                        onCancel={this.handleAddFieldListModalCancel}
                                                        saveButtonTitle={t('ok')}
                                                        maxWidth="sm"
                                                    >
                                                        <SearchableFieldsListForm
                                                            fields={this.getAvailableCrmFieldsWithNameAndLabel()}
                                                        />
                                                    </FormDialog>
                                                )}
                                            </div>
                                        </th>
                                        <th style={{ width: 38 }}>&nbsp;</th>
                                        <th style={{ width: 38 }}>&nbsp;</th>
                                        <th style={{ width: 200 }}>
                                            <span>{this.props.t('prospecting.export_form.field_prospect')}</span>
                                        </th>
                                        <th style={{ width: 38 }}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedEntity.prospectStructure.map((item) => {
                                        return (
                                            <StyledProspectStructureItem
                                                key={item.fieldName}
                                                structureItem={item}
                                                error={errors.get(item.fieldName)}
                                                onGetField={this.handleGetField}
                                                onChange={this.handleFieldChange}
                                                onRemove={this.handleFieldRemove}
                                                onShowSearch={this.handleShowSearch}
                                                prospectFields={this.prospectFields}
                                                currentUserDSId={currentUserDSId}
                                                dataSourceName={dataSource.name}
                                                ownerFieldName={selectedEntity.ownerFieldName}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                )}
                {showSearch !== null && (
                    <LiveSearchTableViewDialog
                        field={{
                            ...showSearch.field,
                            label: showSearch.field.title,
                            apiName: showSearch.field.name,
                        }}
                        value={showSearch.field.value}
                        accountId={userManager.getCurrentUser().accountId}
                        entityId={selectedEntity.id}
                        dataSourceId={dataSourceId}
                        onClose={this.handleCloseSearch}
                        onSaveRequest={this.handleSaveSearch}
                        searchTitleFields={dataSource.searchTitleFields}
                    />
                )}

                <FormActions>
                    <LoadingButton
                        color="primary"
                        name="submit"
                        onClick={this.handleSubmit}
                        loading={this.state.loading || this.state.exporting}
                        disabled={
                            this.state.exporting ||
                            selectedEntity === null ||
                            this.props.ids.size === 0 ||
                            selectedEntity.prospectStructure.length === 0
                        }
                        data-testid="prospecting.export_form.button_export"
                    >
                        {this.props.t('prospecting.export_form.button_export', { size: this.props.ids.size })}
                    </LoadingButton>
                </FormActions>
            </div>
        );
    }
}

const prospectStructureItemStyle = (theme) => ({
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
});

const StyledProspectStructureItem = withStyles(prospectStructureItemStyle)(ProspectStructureItem);

ExportForm.propTypes = {
    dataSources: PropTypes.array,
    ids: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    apiKeys: PropTypes.object,
};

const s = {
    mappingTable: {
        '& > thead > tr > th': {
            padding: 8,
            textAlign: 'center',
        },
        '& > tbody > tr > td:last-child': {
            width: 40,
        },
    },
};

export default withTranslation()(withStyles(s)(ExportForm));
