import i18n from 'i18next';
import { action, computed, makeObservable, observable } from 'mobx';
import {
    confirmReloadSessionManager,
    enqueueSnackbarService,
    routeDesignConfigManager,
    routeDesignManager,
    routeEditorManager,
    routeManager,
    routeReportInEditModeManager,
    routeReportLoadedSessionRouteManager,
    routeReportManager,
    routeViewerManager,
    routingSessionManager,
    routingSessionService,
    tripModeManager,
} from 'service/MapPage';
import { Api, DataSource, Entity, Geo, Routing } from 'interfaces';
import { userManager } from 'service/UserManager';
import dispatcher from '../../dispatcher';
import events from '../../../events';
import routeLoaderFactory from 'service/MapPage/Route/RouteLoader';
import dsManagerFactory from '../../DsManager';
import { SYSTEM_ENTITIES_API_NAMES } from 'components/constants';
import { isToday } from 'date-fns';
import { RouteDenormalizer } from '../../Serializer';
import { formatCoordinatesToAddress } from '../../../utils';
import { PathsRequestInitiator } from 'interfaces/ws/paths';
import { GeocoderResultStatus } from '../../../components/types';
import { BuildRouteEndPoint } from '../../../interfaces/geocoder/route';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PointType } from '../../../interfaces/routing/route';
import { GeoStatus } from '../../../interfaces/geo';
import usersPermissionsManager from 'service/Permissions/UsersPermissionsManager';

type RoutingSessionManagerKeys = '_currentSession' | 'updateSessions' | '_loadedSessionRoute';

class RoutingSessionManager {
    private _systemEntity: Entity.Entity | null = null;
    private _currentSession: Routing.Route.CurrentSession | null = null;
    private _loadedSessionRoute: Routing.Route.LoadedSessionRoute | null = null;

    private _sessionSavePromise: Promise<Routing.Session.Session | null> | null = null;

    constructor() {
        makeObservable<RoutingSessionManager, RoutingSessionManagerKeys>(this, {
            _currentSession: observable,
            _loadedSessionRoute: observable,

            isReady: computed,
            isShowCurrentSession: computed,
            isShowLoadedSessionRoute: computed,
            isEditModeInPublishedRoute: computed,
            isLoadedRouteUsed: computed,
            isDraftMode: computed,
            isDraftEditMode: computed,
            isDesignMode: computed,
            isBuilding: computed,
            isUpdating: computed,
            isPublishedMode: computed,
            isBuilt: computed,
            isRebuildRouteMode: computed,
            isEditRouteMode: computed,
            isStartedTripRouteMode: computed,
            hasUnassignedJobs: computed,
            unassignedJobs: computed,
            isLoadedRoute: computed,
            loadedSession: computed,
            loadedSessionRoute: computed,

            initSession: action,
            setSession: action,
            loadSessionRoute: action,
            deletePublishedRoute: action,
            updateDraftSession: action,
            updateDraftRoute: action,
            updateLoadedRoute: action,
            saveInputDesignModeSession: action,
            discardDraftMode: action,
            clearDraftMode: action,
            deleteDraftRoute: action,
            switchToDesignMode: action,
            switchToRebuildMode: action,
            cancelRebuildMode: action,
            updateSessions: action,
            publishInDraftMode: action,
            publishInRebuildMode: action,
            publishAsInRebuildMode: action,
            startTrip: action,
            endTrip: action,
            saveRoute: action,
            enableEditModeInDraftMode: action,
            cancelEditModeInDraftMode: action,
            saveEditModeInDraftMode: action,

            enableEditModePublishedRoute: action,
            cancelEditModePublishedRoute: action,
            saveEditModePublishedRoute: action,

            deleteActivity: action,
            moveActivity: action,
            addActivity: action,
            editStartInEditMode: action,
            editFinishInEditMode: action,
            saveSessionName: action,
        });
    }

    get unassignedJobs(): Routing.Route.UnassignedJob[] {
        return this.currentSession.unassignedJobs;
    }

    get hasUnassignedJobs(): boolean {
        return this.currentSession.unassignedJobs.length > 0;
    }

    async initSession(): Promise<void> {
        try {
            routeEditorManager.setIsChanging(true);

            const result = await routingSessionService.initSession();
            this.updateSessions(result.currentSession, result.loadedSessionRoute, PathsRequestInitiator.INIT_SESSION);
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    async loadSessionRoute(route: Routing.Route.SimpleRoute): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.loadSessionRoute(route.id);
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            dispatcher.dispatch(events.CURRENT_ROUTE_RESET);
            dispatcher.dispatch(events.CURRENT_ROUTE_LOADED);

            routeViewerManager.loadRoutesPaths();
        });
    }

    async addEntityPoint(point: Routing.Route.DesignEntityPoint): Promise<void> {
        await this.addPoints([point]);
    }

    async addSimplePoint(point: Routing.Route.DesignSimplePoint): Promise<void> {
        await this.addPoints([], [point]);
    }

    async addGeoPoint(geoPoint: Geo.GeoPoint): Promise<void> {
        const point = RoutingSessionManager.prepareSimplePoint(geoPoint);
        await this.addPoints([], [point]);
    }

    async addPoints(
        entityPoints: Routing.Route.DesignEntityPoint[],
        simplePoints: Routing.Route.DesignCombinedSimplePoint[] = [],
    ): Promise<void> {
        if (entityPoints.length === 0 && simplePoints.length === 0) {
            return;
        }

        if (!this.isDesignMode && !this.isDraftEditMode && !this.isEditModeInPublishedRoute) {
            routeEditorManager.setIsSwitchingToEditModeWithAddPointsLoading(true);

            if (this.isPublishedMode) {
                let prepare = Promise.resolve();

                if (this.isStartedTripRouteMode) {
                    prepare = this.endTrip(tripModeManager.route);
                }

                await prepare.then(this.enableEditModePublishedRoute.bind(this));
            } else {
                await this.enableEditModeInDraftMode();
            }
        }

        entityPoints.forEach((point: Routing.Route.DesignEntityPoint) => {
            point.pointType ??= PointType.ENTITY_POINT;

            const { address: addresses } = point;
            const firstAddress = Array.isArray(addresses) ? addresses.find(() => true) ?? null : addresses;
            point.address = firstAddress === null ? null : String(firstAddress);
        });
        simplePoints.forEach((point: Routing.Route.DesignCombinedSimplePoint) => {
            if (point.pointType !== undefined) {
                return;
            }

            point.pointType = point.hasOwnProperty('recordId') ? PointType.PROSPECTING_POINT : PointType.SIMPLE_POINT;
        });

        if (routingSessionManager.isDraftEditMode || routingSessionManager.isEditModeInPublishedRoute) {
            let route: Routing.Route.Route | undefined;

            if (routingSessionManager.isDraftEditMode && routingSessionManager.isRebuildRouteMode) {
                if (routeReportManager.showSingleRoute) {
                    route = routeReportManager.singleRoute;
                }
            }

            if (!route) {
                if (routingSessionManager.isLoadedRoute) {
                    route = routingSessionManager.loadedSessionRoute;
                } else if (routeReportManager.showSingleRoute) {
                    route = routeReportManager.singleRoute;
                } else if (routeReportManager.isShowRouteReport) {
                    route = routeReportManager.showRouteReportData?.route;
                }
            }

            if (!route) {
                enqueueSnackbarService.sendWarningMessage(
                    i18n.t('map_page.snack.you_cannot_add_a_point_in_a_multiple_route_mode'),
                );
                return;
            }
            await this.addActivities(route.id, [...entityPoints, ...simplePoints]);
            return;
        }

        return routeDesignManager.addPoints(entityPoints, simplePoints);
    }

    async deletePublishedRoute(deletedRoute: Routing.Route.SimpleRoute): Promise<void> {
        return this.processBackendRequest(async () => {
            await routingSessionService.deletePublishedRoute(deletedRoute.id);
        });
    }

    async deleteActivity(routeId: string, activityId: string): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            if (this.isShowLoadedSessionRoute) {
                result = await routingSessionService.deleteActivityInPublishedMode(routeId, activityId);
            } else {
                result = await routingSessionService.deleteActivityInDraftMode(routeId, activityId);
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async moveActivity(routeId: string, oldIndex: number, newIndex: number): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            if (this.isShowLoadedSessionRoute) {
                result = await routingSessionService.moveActivityInPublishedMode(routeId, oldIndex, newIndex);
            } else {
                result = await routingSessionService.moveActivityInDraftMode(routeId, oldIndex, newIndex);
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async addActivities(
        routeId: string,
        waypoints: (
            | Routing.Route.DesignSimplePoint
            | Routing.Route.DesignEntityPoint
            | Routing.Route.DesignProspectPoint
        )[],
    ): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            const resultPromise: Promise<Api.Route.GeocoderRouteBuildResultResponse> = this.isShowLoadedSessionRoute
                ? routingSessionService.addActivitiesInPublishedMode(routeId, waypoints)
                : routingSessionService.addActivitiesInDraftMode(routeId, waypoints);

            try {
                result = await resultPromise;
            } catch (e) {
                console.error(e);
                enqueueSnackbarService.sendErrorMessage(e.message);

                throw e;
            }

            if (result.status === GeocoderResultStatus.NO_RESULT) {
                enqueueSnackbarService.sendCustomMessage(
                    i18n.t('map_page.snack.new_point_was_not_added_not_build_route'),
                    { variant: 'error', persist: true },
                );
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            if (
                result.status === GeocoderResultStatus.NO_RESULT ||
                result.status === GeocoderResultStatus.ERROR_OTHER
            ) {
                enqueueSnackbarService.sendCustomMessage(
                    i18n.t('map_page.snack.new_point_was_not_added_not_build_route'),
                    { variant: 'error', persist: true },
                );
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async addActivity(
        routeId: string,
        waypoint: Routing.Route.DesignSimplePoint | Routing.Route.DesignInputEntityPoint,
        destinationIndex: number,
        addAction: Routing.Route.EditModeAddPointManuallyAction,
        async: boolean = true,
    ): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            if (this.isShowLoadedSessionRoute) {
                result = await routingSessionService.addActivityInPublishedMode(
                    routeId,
                    waypoint,
                    destinationIndex,
                    addAction,
                    async,
                );
            } else {
                result = await routingSessionService.addActivityInDraftMode(
                    routeId,
                    waypoint,
                    destinationIndex,
                    addAction,
                    async,
                );
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async editStartInEditMode(
        routeId: string,
        startPoint: BuildRouteEndPoint | null,
        startTime: MaterialUiPickersDate = null,
        isStartNow: boolean = false,
    ): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            if (this.isShowLoadedSessionRoute) {
                result = await routingSessionService.editStartInPublishedMode(
                    routeId,
                    startPoint,
                    startTime,
                    isStartNow,
                );
            } else {
                result = await routingSessionService.editStartInDraftMode(routeId, startPoint, startTime, isStartNow);
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async editFinishInEditMode(routeId: string, endPoint: BuildRouteEndPoint | null): Promise<void> {
        return this.processBackendRequest(async () => {
            let result: Api.Route.GeocoderRouteBuildResultResponse;
            if (this.isShowLoadedSessionRoute) {
                result = await routingSessionService.editFinishInPublishedMode(routeId, endPoint);
            } else {
                result = await routingSessionService.editFinishInDraftMode(routeId, endPoint);
            }

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    async reoptimizeRoute(): Promise<void> {
        return this.processBackendRequest(async () => {
            await this.sessionSavePromise;

            if (!this.isEditModeInPublishedRoute) {
                return Promise.reject('Reoptimization is available only in published mode');
            }

            const result = await routingSessionService.reoptimizeRoute();

            if (result.status === GeocoderResultStatus.IN_PROGRESS) {
                return true;
            }

            try {
                routeManager.validateBuildResult(result);
            } catch (e) {
                enqueueSnackbarService.sendErrorMessage(e.message);
            }

            return false;
        }).then((result: any) => {
            if (typeof result === 'boolean') {
                return routeEditorManager.setIsChanging(result);
            }
        });
    }

    updateDraftSession(session: Routing.Session.Session, routes: Routing.Route.Route[]): void {
        try {
            routeEditorManager.setIsChanging(true);
            this._currentSession = { session, routes };
            this.reloadSessionsData();
            routeViewerManager.loadRoutesPaths();
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    updateDraftRoute(session: Routing.Session.Session, rebuiltRoute: Routing.Route.Route): void {
        try {
            routeEditorManager.setIsChanging(true);

            const index = this.currentSessionRoutes.findIndex((route) => route.id === rebuiltRoute.id);
            if (index !== -1) {
                this._currentSession!.routes[index] = {
                    ...rebuiltRoute,
                };
            }
            this._currentSession!.session = session;

            this.reloadSessionsData();
            routeViewerManager.loadRoutesPaths();
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    updateLoadedRoute(session: Routing.Session.Session, route: Routing.Route.Route): void {
        try {
            routeEditorManager.setIsChanging(true);

            this._loadedSessionRoute = { session, route };

            this.reloadSessionsData();
            routeViewerManager.loadRoutesPaths();
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    async cancelRoutingBuilding(): Promise<void> {
        try {
            routeManager.cancelBuilding();
            const result = await routingSessionService.cancelAdvancedRouteBuilding();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
        } catch (e) {
            this.handleResponseError(e);
        }
    }

    async discardDraftMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.discardDraftMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            routeViewerManager.clear();
            routeViewerManager.close();
            dispatcher.dispatch(events.CURRENT_ROUTE_RESET);
        });
    }

    async clearDraftMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.clearDraftMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            routeViewerManager.clear();
            routeViewerManager.close();
            dispatcher.dispatch(events.CURRENT_ROUTE_RESET);
        });
    }

    async switchToDesignMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.switchToDesignMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            routeViewerManager.clear();
            routeViewerManager.close();
            dispatcher.dispatch(events.CURRENT_ROUTE_RESET);
        });
    }

    async switchToRebuildMode(sessionId: string | null = null, routeId: string | null = null): Promise<void> {
        return this.processBackendRequest(async () => {
            dispatcher.dispatch(events.ROUTING_SESSION.REBUILD_MODE_ENABLED);
            const result = await routingSessionService.switchToRebuildMode(sessionId, routeId);
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            routeViewerManager.loadRoutesPaths();
        });
    }

    async cancelRebuildMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.cancelRebuildMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            dispatcher.dispatch(events.ROUTING_SESSION.REBUILD_MODE_CANCELED);
            routeViewerManager.loadRoutesPaths();
        });
    }

    async enableEditModeInDraftMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.enableEditModeInDraftMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
        });
    }

    async cancelEditModeInDraftMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.cancelEditModeInDraftMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
            dispatcher.dispatch(events.ROUTING_SESSION.DRAFT_EDIT_CANCELED);
        });
    }

    async saveEditModeInDraftMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.saveEditModeInDraftMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
            dispatcher.dispatch(events.ROUTING_SESSION.DRAFT_EDIT_SAVED);
        });
    }

    async enableEditModePublishedRoute(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.enableEditModePublishedRoute();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
        });
    }

    async cancelEditModePublishedRoute(): Promise<void> {
        return this.processBackendRequest(async () => {
            routeReportInEditModeManager.closeAddPointManuallyModal();
            const result = await routingSessionService.cancelEditModePublishedRoute();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
            dispatcher.dispatch(events.ROUTING_SESSION.PUBLISHED_EDIT_CANCELED);
        });
    }

    async saveEditModePublishedRoute(): Promise<void> {
        return this.processBackendRequest(async () => {
            routeReportInEditModeManager.closeAddPointManuallyModal();
            const result = await routingSessionService.saveEditModePublishedRoute();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
            dispatcher.dispatch(events.ROUTING_SESSION.PUBLISHED_EDIT_SAVED);
        });
    }

    loadRouteForToday(): Routing.Route.Route | null {
        const user = userManager.getCurrentUser();
        const routeToTodayForMe = this.currentSessionRoutes.find(
            (route) => route.user.id === user.id && isToday(route.dateStartAt),
        );
        return routeToTodayForMe ?? null;
    }

    async startTrip(): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.startTrip();
            this.updateSessions(result.currentSession, result.loadedSessionRoute, PathsRequestInitiator.TRIP_MODE);
            this.updateRouteReport(result.currentSession);
        }).then(() => tripModeManager.refreshRoute());
    }

    async endTrip(route: Routing.Route.Route): Promise<void> {
        return this.processBackendRequest(async () => {
            const result = await routingSessionService.endTrip(route.id);
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            this.updateRouteReport(result.currentSession);
        });
    }

    updateInputInCurrentSession(input: Routing.Session.SessionDesignModeInput): void {
        this.currentSession.input = {
            ...this.currentSession.input,
            ...input,
        };
    }

    saveInputDesignModeSession(input: Routing.Session.SessionDesignModeInput): Promise<Routing.Session.Session | null> {
        const hasBrowseUsersPermission = usersPermissionsManager.hasBrowseUsersPermission;
        const currentUser = userManager.currentUser;
        const selectedUsers = input.config.usersIds.filter((user) => currentUser.id === user);
        const data = {
            ...input,
            config: {
                ...input.config,
                usersIds: hasBrowseUsersPermission ? input.config.usersIds : selectedUsers,
            },
        };
        this.updateInputInCurrentSession(data);

        this._sessionSavePromise = routingSessionService
            .saveDesignModeSession(data)
            .then(({ session }) => {
                this.currentSession.input.points = RouteDenormalizer.denormalizeInputPoints(session.input.points);
                return this.currentSession;
            })
            .catch((e) => {
                this.handleResponseError(e);
                return null;
            })
            .finally(() => {
                this._sessionSavePromise = null;
            });

        return this._sessionSavePromise;
    }

    async publishInDraftMode(loadRoute: Routing.Route.Route | null, startTrip: boolean = false): Promise<void> {
        return this.processBackendRequest(async () => {
            const countRoutes = this.currentSessionRoutes.length;

            const result = await routingSessionService.publishInDraftMode(loadRoute, startTrip);
            this.updateSessions(result.currentSession, result.loadedSessionRoute);
            dispatcher.dispatch(events.ROUTING_SESSION.DRAFT_SAVED);

            if (result.loadedSessionRoute === null) {
                routeViewerManager.clear();
                routeViewerManager.close();
            }

            RoutingSessionManager.showMessageRoutesPublished(countRoutes);
        }).then(() => (startTrip ? tripModeManager.refreshRoute() : Promise.resolve()));
    }

    async publishInRebuildMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const countRoutes = this.currentSessionRoutes.length;

            const result = await routingSessionService.publishInRebuildMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);

            routeViewerManager.clear();
            routeViewerManager.close();
            RoutingSessionManager.showMessageRoutesPublished(countRoutes);
        });
    }

    async publishAsInRebuildMode(): Promise<void> {
        return this.processBackendRequest(async () => {
            const countRoutes = this.currentSessionRoutes.length;
            const result = await routingSessionService.publishAsInRebuildMode();
            this.updateSessions(result.currentSession, result.loadedSessionRoute);

            routeViewerManager.clear();
            routeViewerManager.close();
            RoutingSessionManager.showMessageRoutesPublished(countRoutes);
        });
    }

    async deleteDraftRoute(route: Routing.Route.Route): Promise<void> {
        return this.processBackendRequest(async () => {
            await routingSessionService.deleteDraftRoute(route);

            this._currentSession!.routes = this.currentSessionRoutes.filter((r) => r.id !== route.id);
            this.reloadSessionsData();
            dispatcher.dispatch(events.ROUTING_SESSION.DRAFT_DELETED);
        });
    }

    async saveSessionName(sessionName: string): Promise<Routing.Session.Session | null | void> {
        if (this.isDesignMode) {
            this._sessionSavePromise = routingSessionService
                .saveSessionName(sessionName)
                .then((session) => {
                    this._currentSession!.session = session;
                    return session;
                })
                .finally(() => {
                    this._sessionSavePromise = null;
                });

            return this._sessionSavePromise;
        }

        return this.processBackendRequest(async () => {
            this._currentSession!.session = await routingSessionService.saveSessionName(sessionName);
            this.reloadSessionsData();
        });
    }

    async saveRoute(routeId: string, data: Routing.Route.SaveExistsRouteData): Promise<Routing.Route.Route> {
        return this.processBackendRequest(async () => {
            const user = userManager.getCurrentUser();
            const account = userManager.getCurrentAccount();
            const routeLoader = routeLoaderFactory.getManager(user.id, account.id);

            const updatedRoute = await routeLoader.saveExistsRoute(routeId, data);

            if (this.isLoadedRoute && this._loadedSessionRoute!.route!.id === routeId) {
                this._loadedSessionRoute!.route = { ...updatedRoute };
                return updatedRoute;
            }

            const routes = [...this.currentSessionRoutes];
            const index = routes.findIndex((route) => route.id === routeId);
            if (index !== -1) {
                routes[index] = { ...updatedRoute };
            }
            this._currentSession!.routes = routes;
            this.reloadSessionsData();
            routeViewerManager.loadRoutesPaths();

            return updatedRoute;
        });
    }

    getSystemEntity(): Promise<Entity.Entity> {
        if (this._systemEntity) {
            return Promise.resolve(this._systemEntity);
        }
        const account = userManager.getCurrentAccount();
        return dsManagerFactory
            .getManager(account.id)
            .list()
            .then((dataSources: DataSource.DataSource[]) => {
                const systemDataSource = dataSources.find((dataSource) => dataSource.isSystem);
                const entityCounter = systemDataSource
                    ? systemDataSource.entityCounters.find((counter) => {
                          return counter.entity.apiName === SYSTEM_ENTITIES_API_NAMES.routingSession;
                      })
                    : null;
                if (!entityCounter) {
                    throw new Error('System entity was not found');
                }
                this._systemEntity = entityCounter.entity as unknown as Entity.Entity;
                return this._systemEntity;
            });
    }

    setSession(session: Routing.Session.Session): void {
        this._currentSession!.session = session;
        this.reloadSessionsData();
    }

    get isReady(): boolean {
        return this._currentSession !== null;
    }

    get currentSession(): Routing.Session.Session {
        if (!this.isReady) {
            throw new Error('Current routing session is not initialized.');
        }
        return this._currentSession!.session;
    }

    get currentSessionRoutes(): Routing.Route.Route[] {
        if (!this.isReady) {
            throw new Error('Current routing session is not initialized.');
        }
        return this._currentSession!.routes;
    }

    get isLoadedRoute(): boolean {
        return this._loadedSessionRoute !== null && this._loadedSessionRoute.route !== null;
    }

    get isLoadedSessionWithoutRoute(): boolean {
        return this._loadedSessionRoute !== null && this._loadedSessionRoute.route === null;
    }

    get loadedSession(): Routing.Session.Session {
        if (this._loadedSessionRoute === null) {
            throw new Error('Routing session is not loaded.');
        }
        return this._loadedSessionRoute.session;
    }

    get loadedSessionRoute(): Routing.Route.Route {
        if (!this.isLoadedRoute) {
            throw new Error('Routing session is not loaded.');
        }
        return this._loadedSessionRoute!.route!;
    }

    get isShowCurrentSession(): boolean {
        return this.isReady && !this.isShowLoadedSessionRoute;
    }

    get isShowLoadedSessionRoute(): boolean {
        return this.isLoadedRoute && !this.isActionRebuildMode(this._loadedSessionRoute!.route!);
    }

    get isDesignMode(): boolean {
        return (
            this.isShowCurrentSession &&
            [Routing.Session.Status.DESIGN, Routing.Session.Status.BUILDING].includes(this.currentSession.status)
        );
    }

    get isBuilding(): boolean {
        return this.isShowCurrentSession && this.currentSession.status === Routing.Session.Status.BUILDING;
    }

    get isUpdating(): boolean {
        return this.isShowLoadedSessionRoute && this.loadedSession.status === Routing.Session.Status.BUILDING;
    }

    get isPublishedMode(): boolean {
        return (
            this.isShowLoadedSessionRoute &&
            this._loadedSessionRoute!.route!.status === Routing.Route.RouteStatus.PUBLISHED
        );
    }

    get isAnyDraftMode(): boolean {
        return (
            this.isShowCurrentSession &&
            [Routing.Session.Status.DRAFT, Routing.Session.Status.DRAFT_EDIT].includes(this.currentSession.status)
        );
    }

    get isDraftMode(): boolean {
        return this.isShowCurrentSession && this.currentSession.status === Routing.Session.Status.DRAFT;
    }

    get isDraftEditMode(): boolean {
        return this.isShowCurrentSession && this.currentSession.status === Routing.Session.Status.DRAFT_EDIT;
    }

    get isBuilt(): boolean {
        return this.isAnyDraftMode || this.isShowLoadedSessionRoute;
    }

    get isRebuildRouteMode(): boolean {
        return (
            this.isReady &&
            ((this.isLoadedRoute && this.isActionRebuildMode(this._loadedSessionRoute!.route!)) ||
                this.isLoadedSessionWithoutRoute)
        );
    }

    get isEditModeInPublishedRoute(): boolean {
        return this.isReady && this.isLoadedRoute && this.isActionEditMode(this._loadedSessionRoute!.route!);
    }

    get isLoadedRouteUsed(): boolean {
        return this.isReady && this.isLoadedRoute && this._loadedSessionRoute!.route!.actionMode !== null;
    }

    get sessionSavePromise(): Promise<unknown> {
        return this._sessionSavePromise || Promise.resolve();
    }

    reset() {
        this._systemEntity = null;
        this._currentSession = null;
        this._loadedSessionRoute = null;
    }

    private isActionRebuildMode(route: Routing.Route.Route): boolean {
        if (route.actionMode === null) {
            return false;
        }
        const user = userManager.getCurrentUser();
        return (
            route.actionMode.action === Routing.Route.ActionModeAction.REBUILD && route.actionMode.userId === user.id
        );
    }

    private isActionEditMode(route: Routing.Route.Route): boolean {
        if (route.actionMode === null) {
            return false;
        }
        const user = userManager.getCurrentUser();
        // todo: after MD-5409 distinguish edit mode by routingSession.status === PUBLISHED_EDIT and actionMode
        return route.actionMode.action === Routing.Route.ActionModeAction.EDIT && route.actionMode.userId === user.id;
    }

    private isActionTripMode(route: Routing.Route.Route): boolean {
        if (route.actionMode === null) {
            return false;
        }
        const user = userManager.getCurrentUser();
        // todo: after MD-5409 distinguish edit mode by routingSession.status === PUBLISHED_EDIT and actionMode
        return route.actionMode.action === Routing.Route.ActionModeAction.TRIP && route.actionMode.userId === user.id;
    }

    /**
     * @deprecated Use isDraftEditMode
     */
    get isEditRouteMode(): boolean {
        return this.isDraftEditMode;
    }

    get isStartedTripRouteMode(): boolean {
        return this.isReady && this.isLoadedRoute && this.isActionTripMode(this._loadedSessionRoute!.route!);
    }

    private updateSessions(
        currentSession: Routing.Route.CurrentSession,
        loadedSessionRoute: Routing.Route.LoadedSessionRoute | null,
        pathRequestInitiator: PathsRequestInitiator = PathsRequestInitiator.ROUTE_REPORT,
    ) {
        const isCurrentSessionChanged = this._currentSession?.session?.id !== currentSession.session.id;
        this._currentSession = { ...currentSession };
        this._loadedSessionRoute = loadedSessionRoute !== null ? { ...loadedSessionRoute } : null;

        this.reloadSessionsData();
        if (isCurrentSessionChanged) {
            dispatcher.dispatch(events.ROUTING_SESSION.CHANGED);
        }
        routeViewerManager.loadRoutesPaths(pathRequestInitiator);
    }

    private updateRouteReport(session: Routing.Route.CurrentSession): void {
        if (!routeReportManager.showRouteReportData) {
            return;
        }

        let index = routeReportManager.showRouteReportData.routeIndex;
        let route = session.routes[index];
        if (route === undefined) {
            index = 0;
            route = routeReportManager.singleRoute;
        }

        routeReportManager.showRouteReport(route, index);
    }

    private setConfigFromLoadedOrCurrentSession(): void {
        if (
            this._loadedSessionRoute !== null &&
            this._loadedSessionRoute.session.status === Routing.Session.Status.PUBLISHED_EDIT
        ) {
            routeDesignConfigManager.setConfig(this.loadedSession.input.config);
        } else if (this.isReady) {
            routeDesignConfigManager.setConfig(this.currentSession.input.config);
        }
    }

    private reloadSessionsData(): void {
        routeEditorManager.setIsInProgress(this.isBuilding || this.isUpdating);
        this.setConfigFromLoadedOrCurrentSession();

        if (this._loadedSessionRoute === null) {
            tripModeManager.reset();
            routeReportLoadedSessionRouteManager.reset();
        } else {
            routeReportLoadedSessionRouteManager.init(this._loadedSessionRoute);

            if (!this.isStartedTripRouteMode) {
                tripModeManager.reset();
            } else {
                tripModeManager.startTrip(this._loadedSessionRoute.route!);
            }

            if (!this.isRebuildRouteMode && !this.isEditModeInPublishedRoute) {
                routeReportManager.reset();
                routeDesignManager.reset();
                routeDesignConfigManager.reset();
                return;
            }
        }

        if (this.isDesignMode) {
            routeDesignManager.initSession(this.currentSession);
            routeReportManager.reset();
            return;
        }

        if (this.isAnyDraftMode) {
            routeReportManager.initSessionRoutes(this.currentSession, this.currentSessionRoutes);
            routeDesignManager.reset();
            routeDesignConfigManager.reset();
        }
    }

    private static showMessageRoutesPublished(countRoutes: number): void {
        const messageKey = countRoutes > 1 ? 'map_page.snack.x_routes_saved' : 'map_page.snack.x_route_saved';
        enqueueSnackbarService.sendSuccessMessage(i18n.t(messageKey, { countRoutes }));
    }

    private static prepareSimplePoint(geoPoint: Geo.GeoPoint): Routing.Route.DesignSimplePoint {
        const address = formatCoordinatesToAddress(geoPoint.lat, geoPoint.lng);

        return {
            ...geoPoint,
            address,
            addressFields: {},
            countryShort: null,
            objectName: address,
            pointType: PointType.SIMPLE_POINT,
            geoStatus: GeoStatus.OkImported,
        };
    }

    private async processBackendRequest(callable: Function, ...args: any): Promise<any> {
        try {
            routeEditorManager.setIsChanging(true);

            return await callable.call(this, args);
        } catch (e) {
            this.handleResponseError(e);
            return e;
        } finally {
            routeEditorManager.setIsChanging(false);
        }
    }

    private handleResponseError(e: Api.Exception.HttpError) {
        const handleableErrorCodes = [400, 403];
        if (!handleableErrorCodes.includes(e.code)) {
            throw e;
        }
        if (e.exception === Api.Exception.ExtraCodes.INVALID_STATUS_ROUTING_SESSION) {
            confirmReloadSessionManager.openDialog();
        } else {
            enqueueSnackbarService.sendErrorMessage(e.message);
        }
    }

    updateInputInPublishedEditSession(data: Partial<Routing.Session.SessionInput>) {
        this.loadedSession.input = {
            ...this.loadedSession.input,
            ...data,
        };
    }

    saveInputPublishedEditModeSession(data: Partial<Routing.Session.SessionInput>) {
        this.updateInputInPublishedEditSession(data);
        this._sessionSavePromise = routingSessionService
            .savePublishedEditModeSession(this.loadedSession.input)
            .then(() => this.loadedSession)
            .catch((e) => {
                this.handleResponseError(e);
                return null;
            })
            .finally(() => {
                this._sessionSavePromise = null;
            });

        return this._sessionSavePromise;
    }
}

export default RoutingSessionManager;
