import qs from 'qs';
import QueryString from 'qs';
import { accountsManager } from '../../service/AccountsManager';
import { userManager } from '../../service/UserManager';
import { Account, PurposeOfUse } from '../../interfaces/account';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/browser';
import { Feature } from '../../interfaces/signUpFeatures';
import { purposeOfUseManager } from '../../service/PurposeOfUseManager';

export const STAGES = {
    ACCOUNT: 0,
    INTRO: 1,
    INITIAL: 2,
    FEATURES: 3,
    AUTH: 4,
    ENTITIES: 5,
    FIELDS: 6,
    FINISH: 7,
};

interface CompanyData {
    name?: string | null;
    timezone: string | null;
    country: string | null;
    language?: string | null;
}

interface UserData {
    name: string | null;
    email: string | null;
    role: string | null;
    password?: string;
}

class SSUManager {
    public readonly keyForSelectedFeatures = 'selected_features';
    public readonly keyForAccountState = 'account_state';
    public readonly keyForUserState = 'user_data_state';
    public readonly keyForProblemToSolve = 'problem_to_solve_state';
    public readonly keyForSimilarSoft = 'similar_soft';
    public readonly keyForUsersCount = 'users_count';

    private accountId: number | null;
    private dsId: number | null;
    private readonly provider: string | undefined;
    private readonly companyData: CompanyData;
    private readonly plan: string | string[] | QueryString.ParsedQs | QueryString.ParsedQs[] | null;
    private readonly userData: UserData;

    constructor(provider: string | undefined, stage: number) {
        this.accountId = null;
        this.dsId = null;
        this.userData = {} as UserData;
        this.companyData = {} as CompanyData;
        this.provider = provider;
        this.plan = null;

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        // eslint-disable-next-line default-case
        switch (stage) {
            case STAGES.ACCOUNT:
            case STAGES.INTRO:
                this._updateUserData(params['user'] as object);
                this._updateCompanyData(params['company'] as object);
                this.plan = params['plan'] ?? null;
                break;
            case STAGES.INITIAL:
                this.plan = params['plan'] ?? null;
                break;
        }

        const account = userManager.getCurrentAccount();
        if (account) {
            this.accountId = account['id'];
            this.dsId = account['signUpData'] !== null ? account['signUpData']['dsId'] || null : null;
        }
    }

    async fixStage(stage: number) {
        const account = userManager.getCurrentAccount();
        if (account === null) {
            const initialStage = this.isSsoUser() ? STAGES.ACCOUNT : STAGES.INTRO;

            if (stage > STAGES.INITIAL || stage < initialStage) {
                return initialStage;
            }

            return stage;
        }

        const { signUpStage, signUpData } = account;
        const purposeOfUse = await purposeOfUseManager.getPurposeOfUse(account.id);

        if (signUpStage === null) {
            return STAGES.FEATURES;
        }

        if (signUpStage > STAGES.FEATURES && !purposeOfUse) {
            return STAGES.FEATURES;
        }

        if (signUpStage > STAGES.FEATURES && !this.provider) {
            return STAGES.FINISH;
        }

        if (signUpStage > STAGES.FEATURES && !this.provider) {
            return STAGES.FINISH;
        }

        if (signUpStage > STAGES.AUTH && (!signUpData || !signUpData['dsId'])) {
            return STAGES.AUTH;
        }

        return signUpStage;
    }

    isSsoUser() {
        // ненадежный метод
        return !isEmpty(this.userData);
    }

    getIpInfo = () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 10000;

            xhr.onload = () => {
                if (xhr.status === 200) {
                    try {
                        const result = JSON.parse(xhr.response);
                        resolve(result);
                    } catch (e) {
                        reject('Malformed response');
                    }
                } else {
                    console.error('Request ended with error, code:' + xhr.status);
                    reject('Connection error');
                }
            };
            xhr.onerror = () => {
                console.error('Request ended with error, code:' + xhr.status);
                reject('Connection error');
            };
            xhr.ontimeout = () => {
                reject('Connection timeout');
            };

            xhr.open('GET', 'https://ipapi.co/json/');
            xhr.send();
        }).then((data: any) => {
            if (!this.companyData.timezone) {
                this.companyData.timezone = data['timezone'];
            }
            if (!this.companyData.country) {
                this.companyData.country = data['country'];
            }

            return { timezone: this.companyData.timezone, country: this.companyData.country };
        });
    };

    _updateUserData(data: null | { name?: string | null; email?: string | null; role?: string | null }) {
        if (data === null || typeof data !== 'object') {
            return;
        }

        this.userData.name = data['name'] && typeof data['name'] === 'string' ? data['name'] : null;
        this.userData.email = data['email'] && typeof data['email'] === 'string' ? data['email'] : null;
        this.userData.role = data['role'] && typeof data['role'] === 'string' ? data['role'] : null;
    }

    _updateCompanyData(
        data: null | {
            name?: string | null;
            timezone?: string | null;
            country?: string | null;
            language?: string | null;
        },
    ) {
        if (data === null || typeof data !== 'object') {
            return;
        }

        this.companyData['name'] = data['name'] && typeof data['name'] === 'string' ? data['name'] : null;
        this.companyData['timezone'] =
            data['timezone'] && typeof data['timezone'] === 'string' ? data['timezone'] : null;
        this.companyData['country'] = data['country'] && typeof data['country'] === 'string' ? data['country'] : null;
        this.companyData['language'] =
            data['language'] && typeof data['language'] === 'string' ? data['language'] : null;
    }

    getPlan() {
        return this.plan;
    }

    getAccountId() {
        return this.accountId;
    }

    getDataSourceId() {
        return this.dsId;
    }

    setDataSourceId(dsId: number) {
        this.dsId = dsId;
    }

    createConversionGoogle = () => {
        try {
            let iframe = document.createElement('iframe');
            // @ts-ignore
            // noinspection JSConstantReassignment
            iframe.style = 'display:none';
            iframe.src = 'https://mapsly.com/signed-up/';
            document.body.appendChild(iframe);
            iframe.onload = () => {
                document.body.removeChild(iframe);
            };
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    saveInitialData(account: Account, user: UserData) {
        return accountsManager.save(account, user, this.isSsoUser()).then((response: any) => {
            this.accountId = response.account.id;
            this.createConversionGoogle();

            return response;
        });
    }

    saveStage(stage: number) {
        // взять из полей данные для сохранения
        let data = {};
        if (this.dsId) {
            data = { dsId: this.dsId };
        }
        return userManager.setSignUpStage(stage, data);
    }

    saveInterestedIn(interestedIn: PurposeOfUse) {
        return accountsManager.saveInterestedIn(this.accountId, interestedIn);
    }

    getCompanyData() {
        return this.companyData;
    }

    getUserData() {
        return this.userData;
    }

    getProvider() {
        return this.provider;
    }

    saveAccountStateInitialSetup(account: Account) {
        window.localStorage.setItem(this.keyForAccountState, JSON.stringify(account));
    }

    getAccountStateInitialSetup(): Account | null {
        const storedAccount = window.localStorage.getItem(this.keyForAccountState);

        return storedAccount ? JSON.parse(storedAccount) : null;
    }

    saveSelectedFeaturesState(featuresMap: Array<Feature>): void {
        window.localStorage.setItem(this.keyForSelectedFeatures, JSON.stringify(featuresMap));
    }

    saveProblemsToSolveState(problemsToSolve: string): void {
        window.localStorage.setItem(this.keyForProblemToSolve, problemsToSolve);
    }

    getProblemsToSolveState(): null | string {
        const storedValue = window.localStorage.getItem(this.keyForProblemToSolve);

        return storedValue ?? null;
    }

    saveUserDataStateInitialSetup(user_data: UserData): void {
        window.localStorage.setItem(this.keyForUserState, JSON.stringify(user_data));
    }

    getUserDataStateInitialSetup(): UserData | null {
        const storedValue = window.localStorage.getItem(this.keyForUserState);

        return storedValue ? JSON.parse(storedValue) : null;
    }

    saveSimilarSoftToUse(used: boolean, names: string): void {
        window.localStorage.setItem(this.keyForSimilarSoft, JSON.stringify({ used, names }));
    }

    getSimilarSoftToUseState(): { used: boolean; names: string } | null {
        const storedValue = window.localStorage.getItem(this.keyForSimilarSoft);

        return storedValue ? JSON.parse(storedValue) : null;
    }

    saveExpectedUsersCount(usersCount: number): void {
        window.localStorage.setItem(this.keyForUsersCount, usersCount.toString());
    }

    getExpectedUsersCount(): number | null {
        const storedValue = window.localStorage.getItem(this.keyForUsersCount);
        return storedValue ? parseInt(storedValue) : null;
    }

    clearStateForObject(objectKey: string): void {
        window.localStorage.removeItem(objectKey);
    }

    clearStateForInterestedInData(): void {
        this.clearStateForObject(this.keyForProblemToSolve);
        this.clearStateForObject(this.keyForUsersCount);
        this.clearStateForObject(this.keyForSimilarSoft);
    }
}

export default SSUManager;
