import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@material-ui/lab';
import { ListItem, PropTypes, TextField, Tooltip, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { UseAutocompleteProps } from '@material-ui/lab/useAutocomplete/useAutocomplete';

interface Props extends WithStyles {
    getOptionLabel: (option: any) => string;
    getOptionDisabled: (option: any) => boolean;
    renderOption?: (option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
    disabledOptionHint: string | ((option: any) => string);
    disableClearable?: boolean;
    groupBy?: string;
    placeholder: string;
    dataTestId: string;
    className?: string;
    disabled?: boolean;
    inputMargin?: PropTypes.Margin;
    inputRef?: React.RefObject<HTMLInputElement>;
    renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

class DisabledOptionWithHintAutocomplete extends React.PureComponent<
    Props & UseAutocompleteProps<any, boolean, boolean, boolean>
> {
    getDisabledOptionHint = (option: any) => {
        const { getOptionDisabled, disabledOptionHint } = this.props;
        if (!getOptionDisabled(option)) {
            return '';
        }
        if (disabledOptionHint instanceof Function) {
            return disabledOptionHint(option);
        }
        return disabledOptionHint;
    };

    renderOption = (option: any, state: AutocompleteRenderOptionState) => {
        const { renderOption, getOptionLabel, getOptionDisabled } = this.props;
        const label = renderOption ? renderOption(option, state) : getOptionLabel(option);
        if (getOptionDisabled(option)) {
            return (
                <Tooltip title={this.getDisabledOptionHint(option)}>
                    <span style={{ width: '100%' }}>
                        <ListItem
                            dense={true}
                            disabled={true}
                            style={{ width: '100%' }}
                            onClick={(event) => event.stopPropagation()}
                            disableGutters
                        >
                            {label}
                        </ListItem>
                    </span>
                </Tooltip>
            );
        }
        return (
            <ListItem dense={true} style={{ width: '100%' }} disableGutters>
                {label}
            </ListItem>
        );
    };

    render() {
        const {
            placeholder,
            dataTestId,
            inputMargin,
            inputRef,
            renderInput,
            getOptionDisabled,
            value,
            disabledOptionHint,
            ...rest
        } = this.props;

        return (
            <Autocomplete
                {...rest}
                value={value}
                getOptionDisabled={getOptionDisabled}
                renderOption={this.renderOption}
                renderInput={
                    renderInput ??
                    ((params) => (
                        <TextField
                            {...params}
                            data-testid={dataTestId}
                            placeholder={placeholder}
                            error={getOptionDisabled(value)}
                            helperText={getOptionDisabled(value) ? this.getDisabledOptionHint(value) : ''}
                            margin={inputMargin}
                            inputRef={inputRef}
                        />
                    ))
                }
            />
        );
    }
}

const styles = {
    option: {
        cursor: 'default',
        '&[aria-disabled="true"]': {
            pointerEvents: 'all',
        },
        '&[aria-disabled="true"]:hover': {
            backgroundColor: 'unset',
        },
        '&[aria-disabled="true"]:active': {
            backgroundColor: 'unset',
        },
    },
};

export default withStyles(styles)(DisabledOptionWithHintAutocomplete);
