import {
    CalendarFirstDay,
    CalendarResourceGrouping,
    CalendarRoundingStep,
    CalendarFixedFlexible,
} from '../../../service/types';
import { PicklistValue } from '../../types';
import { TFunction } from 'react-i18next';
import { OBJECTIVE_MINIMIZE_COMPLETION, OBJECTIVE_MINIMIZE_DRIVING_TIME } from '../../constants';
import { OptimizationObjectives } from '../../RouteEditor/RouteForm/RoutingConfig/OptimizeSettings';

export type ObjectivePicklistValue = PicklistValue & {
    sum: string;
    desc: string;
};

class CalendarPreferencesFields {
    constructor(private t: TFunction<string>) {}

    getFieldsFixedFlexible = (): PicklistValue[] => {
        // @ts-ignore
        return Object.values(CalendarFixedFlexible).map((v) => {
            const label = `users_calendar_preferences.field_values.fixed_flexible.${v}`;
            return { value: v, label: this.t(label) };
        });
    };

    getResourceGroupings = (): PicklistValue[] => {
        // @ts-ignore
        return Object.values(CalendarResourceGrouping).map((v) => {
            const label = `users_calendar_preferences.field_values.resourceGrouping.${v}`;
            return { value: v, label: this.t(label) };
        });
    };

    getWeekDays = (): PicklistValue[] => {
        const days = Object.values(CalendarFirstDay)
            .filter((value) => isNaN(Number(value)))
            .map((v) => String(v).toLowerCase());

        return days.map((day: string, dayNum: number) => ({
            value: dayNum,
            label: this.t(`users_calendar_preferences.field_values.firstDayOfWeek.${day}`),
        }));
    };

    getOptimizationObjectives = (): ObjectivePicklistValue[] => {
        const allowedObjectives = [OBJECTIVE_MINIMIZE_DRIVING_TIME, OBJECTIVE_MINIMIZE_COMPLETION];
        const objectives = OptimizationObjectives.filter((objective) => allowedObjectives.includes(objective.value));

        return objectives.map((objective) => ({
            value: objective.value,
            label: this.t(objective.key),
            sum: objective.sum_single ? this.t(objective.sum_single) : '',
            desc: objective.desc_single ? this.t(objective.desc_single) : '',
        }));
    };

    getRoundingSteps = (): PicklistValue[] => {
        // @ts-ignore
        return Object.values(CalendarRoundingStep)
            .filter((value) => !isNaN(Number(value)))
            .map((f) => {
                return {
                    value: f,
                    label: `${f} ` + this.t('users_calendar_preferences.field_values.roundingStep.min'),
                };
            });
    };

    capitalizeFirstLetter = (val: string): string => {
        return val.charAt(0).toUpperCase() + val.slice(1);
    };
}

export const calendarPreferencesFields = (t: TFunction<string>): CalendarPreferencesFields => {
    return new CalendarPreferencesFields(t);
};
