import React from 'react';
import { accountsManager } from '../../service/AccountsManager';
import { roleManager } from '../../service/RoleManager';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import './style.css';
import { Redirect, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ProfilePermissions from './ProfilePermissions';
import Button from '@material-ui/core/Button';
import Alert from '../Alert';
import ErrorMessage from '../DataSource/ErrorMessage';
import dsManagerFactory from '../../service/DsManager';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationIconButton from '../CustomButton/ConfirmationIconButton';
import { withTranslation } from 'react-i18next';
import UiFeature from './UiFeature';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import { PERMISSION_MODIFY, UI_FEATURES_DEFAULT } from './constants';
import {
    Grid as DataGrid,
    Table,
    TableFixedColumns,
    TableHeaderRow,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core';
import DottedLink from '../DottedLink';
import LandingLink from '../HelpLink/LandingLink';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import { TYPE_OPEN_FORM, workflowActionManager } from '../../service/WorkflowActionManager';
import SearchFilterModal from '../SearchFilters/SearchFilterModal';
import { reverse, routes } from '../../routes';
import Confirmation from '../Confirmation';
import { USER_ROLE, userManager } from '../../service/UserManager';
import ProfileEditDialog, { FOR_WHOM_LOGGED_IN } from './ProfileEditDialog';
import { v4 as uuidv4 } from 'uuid';
import AddIconButton from '../CustomButton/AddIconButton';
import UiTitleFeature from './UiTitleFeature';
import { LANGUAGE_DE, LANGUAGE_EN, LANGUAGE_ES, LANGUAGE_FR, LANGUAGE_RU } from '../../locales/i18n';
import RecordSharing from './RecordSharing';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import PureFormDialog from '../PureFormDialog';
import SessionManagementForm from '../SecuritySettings/SessionManagementForm';
import FormDialog from '../FormDialog';
import AnalyticsAccessAgreement from './AnalyticsAccessAgreement';
import { HistoryBlocker } from '../HistoryBlocker/HistoryBlocker';
import { SYSTEM_ENTITIES_API_NAMES } from '../../references/systemEntities';
import CalendarEventPermissionModal from './CalendarEventPermissionModal';

const DEFAULT_PROFILE = {
    id: null,
    name: '',
    forWhom: FOR_WHOM_LOGGED_IN,
    hasUsers: false,
    isSystem: false,
    forSharedMap: false,
};

const GridRoot = (props) => <DataGrid.Root {...props} className="c-permissions-list__table" />;
const TableContainer = (props) => <Table.Container {...props} style={{ ...props.style, overflow: 'inherit' }} />;

const SEARCH_FILTER_MODAL_DEFAULTS = {
    profileCode: null,
    formId: null,
    searchBarFields: [],
    show: false,
    invalidSearchBarFields: [],
    formIdIsInvalid: false,
};

class Permissions extends React.Component {
    entityWithBatteryOptimizationWarningIDs = [];
    entityWithBatteryOptimizationWarningCodes = [
        SYSTEM_ENTITIES_API_NAMES.LOCATION_HISTORY,
        SYSTEM_ENTITIES_API_NAMES.USER,
    ];

    constructor(props) {
        super(props);

        this.state = {
            permissions: {},
            dataSources: [],
            calendarEventEntityId: null,
            profiles: [],
            loaded: false,
            isModified: false,
            addProfile: null,
            renameProfile: null,
            isSaving: false,
            errors: null,
            profileErrors: {},
            structure: [],
            subscription: null,
            forms: [],
            searchFilterModal: { ...SEARCH_FILTER_MODAL_DEFAULTS },
            needRedirect: false,
            recordSharingModal: null,
            entityWithBatteryOptimizationWarningWasSwitchedOn: null,
            showAnalyticsAccessAgreement: false,
            showCalendarEventPermissionModal: false,
            temporaryUIFeaturesPermission: null,
            securitySettingsModalProfileCode: null,
        };
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onBeforeUnload);
        this.load();

        dispatcher.subscribe([events.EVENT_ACCOUNT_PERMISSIONS_SAVED], this, () => {
            this.load();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

    onBeforeUnload = (e) => {
        if (this.state.isModified) {
            e.returnValue = this.props.t('permissions.has_unsaved_changed');
        }
    };

    onAddProfile = () => {
        const standardUserProfile = this.getProfileByCode('manager');
        this.setState({
            addProfile: {
                ...DEFAULT_PROFILE,
                accountId: this.props.accountId,
                code: uuidv4(),
                sessionSettings: standardUserProfile.sessionSettings,
            },
        });
    };

    handleCreateDraftProfile = (draftProfile) => {
        this.setState({ isSaving: true });

        roleManager
            .initRoleUIFeaturesPermissions(this.props.accountId, draftProfile.forSharedMap)
            .then((uiFeaturesPermission) => {
                draftProfile.uiFeaturesPermission = uiFeaturesPermission;
                const profiles = [...this.state.profiles, draftProfile];
                this.setState({
                    isSaving: false,
                    profiles: profiles,
                    addProfile: null,
                    structure: this.buildStructure(profiles),
                    isModified: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isSaving: false,
                });
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    handleUpdatedProfile = (updatedProfile) => {
        const profiles = cloneDeep(this.state.profiles);
        let updateProfileIndex = profiles.findIndex((p) => p.code === updatedProfile.code);
        profiles[updateProfileIndex] = updatedProfile;
        this.setState({
            profiles: profiles,
            renameProfile: null,
            structure: this.buildStructure(profiles),
            isModified: true,
        });
    };

    handleCloseProfile = () => {
        this.setState({
            addProfile: null,
            renameProfile: null,
        });
    };

    async load() {
        await Promise.all([
            dsManagerFactory.getManager(this.props.accountId).list(),
            accountsManager.getPermissions(this.props.accountId),
            roleManager.getAccountRoles(this.props.accountId, true),
            subscriptionManagerFactory.getManager(this.props.accountId).getSubscription(),
            workflowActionManager.list(this.props.accountId, TYPE_OPEN_FORM, [], [], 0, 10000),
        ])
            .then((result) => {
                const dataSources = [];
                let calendarEventEntityId = null;
                for (let ds of result[0]) {
                    const entities = [];
                    for (let e of ds.entityCounters) {
                        entities.push({
                            id: e.entity.id,
                            label: e.entity.label,
                            fields: e.entity.fields,
                        });

                        // remembering entities ids for showing BatteryOptimizationModal
                        if (ds.isSystem) {
                            if (this.entityWithBatteryOptimizationWarningCodes.indexOf(e.entity.apiName) !== -1) {
                                this.entityWithBatteryOptimizationWarningIDs.push(e.entity.id);
                            }

                            if (e.entity.apiName === SYSTEM_ENTITIES_API_NAMES.CALENDAR_EVENT) {
                                calendarEventEntityId = e.entity.id;
                            }
                        }
                    }
                    dataSources.push({
                        id: ds.id,
                        name: ds.name,
                        entities,
                    });
                }
                this.setState((state) => {
                    return {
                        dataSources,
                        calendarEventEntityId,
                        permissions: this.processPermissions(result[1], state.permissions),
                        profiles: result[2],
                        loaded: true,
                        isModified: false,
                        structure: this.buildStructure(result[2]),
                        subscription: result[3],
                        forms: result[4].items,
                    };
                });
            })
            .catch((error) => {
                this.setState({
                    loaded: true,
                });
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    processPermissions(permissions, existPermissions) {
        const result = {};
        for (let permission of permissions) {
            result[permission.role.code] = result[permission.role.code] || {};
            if (
                !!existPermissions[permission.role.code] &&
                !!existPermissions[permission.role.code][permission.entity.code]
            ) {
                result[permission.role.code][permission.entity.code] =
                    existPermissions[permission.role.code][permission.entity.code];
                continue;
            }

            const ownerFieldPermissions = {};
            const nonOwnerFieldPermissions = {};
            for (let fieldSetting of permission.settings) {
                ownerFieldPermissions[fieldSetting.fieldId] = fieldSetting.ownerFieldPermission;
                nonOwnerFieldPermissions[fieldSetting.fieldId] = fieldSetting.nonOwnerFieldPermission;
            }
            result[permission.role.code][permission.entity.id] = {
                isAvailable: permission.isAvailable,
                isRecordSharingAvailable: permission.isRecordSharingAvailable,
                recordSharingRules: permission.recordSharingRules,
                ownerPermission: permission.ownerPermission,
                nonOwnerPermission: permission.nonOwnerPermission,
                ownerFieldPermissions: ownerFieldPermissions,
                nonOwnerFieldPermissions: nonOwnerFieldPermissions,
            };
        }
        return result;
    }

    handleChangeOwner = (profileCode, entityId, value) => {
        this.setState((state) => {
            const { permissions } = state;
            permissions[profileCode] = permissions[profileCode] || {};
            permissions[profileCode][entityId] = permissions[profileCode][entityId] || {};
            permissions[profileCode][entityId]['ownerPermission'] = value;
            const ownerFieldPermissions = permissions[profileCode][entityId]['ownerFieldPermissions'] || {};
            Object.keys(ownerFieldPermissions).forEach((fieldId) => {
                permissions[profileCode][entityId]['ownerFieldPermissions'][fieldId] = value;
            });
            return { permissions, isModified: true };
        });
    };

    handleChangeOwnerFields = (profileCode, entityId, settings) => {
        this.setState((state) => {
            const { permissions } = state;
            permissions[profileCode] = permissions[profileCode] || {};
            permissions[profileCode][entityId] = permissions[profileCode][entityId] || {};
            permissions[profileCode][entityId]['ownerFieldPermissions'] = settings;
            return { permissions, isModified: true };
        });
    };

    handleChangeRecordSharingRules = (profileCode, entityId, rules) => {
        this.setState((state) => {
            const { permissions } = state;
            permissions[profileCode] = permissions[profileCode] || {};
            permissions[profileCode][entityId] = permissions[profileCode][entityId] || {};
            permissions[profileCode][entityId]['recordSharingRules'] = rules;
            return { permissions, isModified: true };
        });
    };

    handleChangeNonOwner = (profileCode, entityId, value) => {
        this.setState((state) => {
            const { permissions } = state;
            permissions[profileCode] = permissions[profileCode] || {};
            permissions[profileCode][entityId] = permissions[profileCode][entityId] || {};
            permissions[profileCode][entityId]['nonOwnerPermission'] = value;
            const nonOwnerFieldPermissions = permissions[profileCode][entityId]['nonOwnerFieldPermissions'] || {};
            Object.keys(nonOwnerFieldPermissions).forEach((fieldId) => {
                permissions[profileCode][entityId]['nonOwnerFieldPermissions'][fieldId] = value;
            });
            return { permissions, isModified: true };
        });
    };

    handleChangeNonOwnerFields = (profileCode, entityId, settings) => {
        this.setState((state) => {
            const { permissions } = state;
            permissions[profileCode] = permissions[profileCode] || {};
            permissions[profileCode][entityId] = permissions[profileCode][entityId] || {};
            permissions[profileCode][entityId]['nonOwnerFieldPermissions'] = settings;
            return { permissions, isModified: true };
        });
    };

    handleUpdateProfile = (profile) => {
        this.setState({
            renameProfile: profile,
        });
    };

    handleDeleteProfile = (deletedProfile) => {
        this.setState((state) => {
            const profiles = state.profiles.filter((profile) => profile.code !== deletedProfile.code);
            return {
                isModified: true,
                profiles: profiles,
                structure: this.buildStructure(profiles),
            };
        });
    };

    handleToggleProfile = (profileCode, entityId, value, defaultPermissions) => {
        this.setState((state) => {
            const { permissions } = state;

            let entityWithBatteryOptimizationWarningWasSwitchedOn =
                this.state.entityWithBatteryOptimizationWarningWasSwitchedOn;
            // if specific entity
            if (this.entityWithBatteryOptimizationWarningIDs.indexOf(entityId) !== -1) {
                // was disabled for admin but switched to enabled
                if (!permissions[profileCode]?.[entityId]?.isAvailable && value && profileCode === USER_ROLE.ADMIN) {
                    entityWithBatteryOptimizationWarningWasSwitchedOn = true;
                }
            }

            const permission = {
                ...(permissions[profileCode]?.[entityId] || {}),
                ...defaultPermissions,
                isAvailable: value,
            };
            return {
                permissions: {
                    ...permissions,
                    [profileCode]: {
                        ...permissions[profileCode],
                        [entityId]: permission,
                    },
                },
                isModified: true,
                entityWithBatteryOptimizationWarningWasSwitchedOn,
            };
        });
    };

    handleToggleRecordSharing = (profile, entity, dsId, value, rules) => {
        const profileCode = profile.code;
        const entityId = entity.id;
        this.setState(
            (state) => {
                const { permissions } = state;
                const permission = {
                    ...(permissions[profileCode]?.[entityId] || {}),
                    isRecordSharingAvailable: value,
                };
                return {
                    permissions: {
                        ...permissions,
                        [profileCode]: {
                            ...permissions[profileCode],
                            [entityId]: permission,
                        },
                    },
                    isModified: true,
                };
            },
            () => {
                if (value && !rules.length) {
                    this.handleOpenRecordSharingModal(profile, entity, dsId, rules);
                }
            },
        );
    };

    handleCloseRecordSharingModal = () => {
        this.setState({
            recordSharingModal: null,
        });
    };

    handleOpenRecordSharingModal = (profile, entity, dsId, rules) => {
        this.setState({
            recordSharingModal: {
                profileCode: profile.code,
                entityId: entity.id,
                entityLabel: entity.label,
                profileName: profile.name,
                accountId: profile.accountId,
                dataSourceId: dsId,
                rules,
            },
        });
    };

    handleHistoryBlockerCancel = (cb) => {
        this.handleCancel().finally(() => cb(true));
    };
    handleHistoryBlockerSave = (cb) => {
        this.handleSave(() => cb(true));
    };

    handleCancel = async () => {
        const promise = this.load();
        this.setState({
            needRedirect: this.props.redirectable,
        });
        await promise;
    };

    handleSave = (callback = noop) => {
        const permissionsRequest = [];
        const { accountId, enqueueSnackbar, t } = this.props;
        const { profiles, permissions } = this.state;
        let entityWithBatteryOptimizationWarningEnabled = false;

        for (let profileCode of Object.keys(permissions)) {
            for (let entityId of Object.keys(permissions[profileCode])) {
                if (profiles.find((profile) => profile.code === profileCode) === undefined) {
                    continue;
                }
                const permission = permissions[profileCode][entityId];
                permissionsRequest.push({
                    isAvailable: permission['isAvailable'] === undefined ? false : permission['isAvailable'],
                    isRecordSharingAvailable:
                        permission['isRecordSharingAvailable'] === undefined
                            ? false
                            : permission['isRecordSharingAvailable'],
                    recordSharingRules:
                        permission['recordSharingRules'] === undefined ? [] : permission['recordSharingRules'],
                    ownerPermission: permission['ownerPermission'] === undefined ? null : permission['ownerPermission'],
                    nonOwnerPermission:
                        permission['nonOwnerPermission'] === undefined ? null : permission['nonOwnerPermission'],
                    ownerFieldPermissions:
                        permission['ownerFieldPermissions'] === undefined ? null : permission['ownerFieldPermissions'],
                    nonOwnerFieldPermissions:
                        permission['nonOwnerFieldPermissions'] === undefined
                            ? null
                            : permission['nonOwnerFieldPermissions'],
                    profileCode: profileCode,
                    entityId: parseInt(entityId),
                });

                if (this.entityWithBatteryOptimizationWarningIDs.indexOf(Number(entityId)) !== -1) {
                    entityWithBatteryOptimizationWarningEnabled = true;
                }
            }
        }

        this.setState(
            {
                isSaving: true,
            },
            () => {
                roleManager
                    .saveRolesAndPermissions(accountId, profiles, permissionsRequest)
                    .then(() => {
                        this.props.enqueueSnackbar(t('snack_bar.setting_saved'), { variant: 'success' });
                        this.setState({
                            isModified: false,
                            isSaving: false,
                            needRedirect: this.props.redirectable,
                        });

                        if (
                            entityWithBatteryOptimizationWarningEnabled &&
                            this.state.entityWithBatteryOptimizationWarningWasSwitchedOn
                        ) {
                            dispatcher.dispatch(events.SHOW_BATTERY_OPTIMIZATION_MODAL, { hideSettingsPath: true });
                        }
                    })
                    .catch((error) => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                        const details = [];
                        for (const [name, message] of error.details.entries()) {
                            details.push({ name, message });
                        }
                        this.setState({
                            errors: {
                                type: 'danger',
                                message: error.message,
                                details: details,
                            },
                            isSaving: false,
                        });
                    })
                    .finally(callback);
            },
        );
    };

    handleCloseErrors = () => {
        this.setState({
            errors: null,
        });
    };

    handleCloseProfileError = (profile) => {
        this.setState((state) => {
            return {
                roleErrors: { ...state.roleErrors, [profile.code]: null },
            };
        });
    };

    handleChangeSessionSettings = (sessionSettings) => {
        const profiles = cloneDeep(this.state.profiles);
        const profile = profiles.find((p) => p.code === this.state.securitySettingsModalProfileCode);
        profile.sessionSettings = sessionSettings;
        this.setState({
            profiles,
            isModified: true,
            securitySettingsModalProfileCode: null,
        });
    };

    handleChangeUiFeaturesPermission = (profileCode) => (uiFeaturesPermission) => {
        const profile = this.state.profiles.find((p) => p.code === profileCode);
        const calendarEventPermission = this.state.permissions[profileCode][this.state.calendarEventEntityId];

        const isAdminOrSqlLabFeature = this.checkAdminOrSqlLabFeatureEnabled(
            profile.uiFeaturesPermission,
            uiFeaturesPermission,
        );

        const isShowCalendarEventPermissionModal =
            this.checkCalendarUiOrSharingBrowseUsersEnabled(profile.uiFeaturesPermission, uiFeaturesPermission) &&
            !(
                (calendarEventPermission['isAvailable'] &&
                    calendarEventPermission['nonOwnerPermission'] === PERMISSION_MODIFY) ||
                calendarEventPermission['isRecordSharingAvailable']
            );

        if (isAdminOrSqlLabFeature) {
            this.setState({
                showAnalyticsAccessAgreement: true,
                analyticsAccessAgreementProfile: profile,
                temporaryUIFeaturesPermission: uiFeaturesPermission,
            });
        } else {
            if (isShowCalendarEventPermissionModal) {
                this.openCalendarEventPermissionModal(profile.name);
            }
            this.saveUiFeaturesPermission(profileCode, uiFeaturesPermission);
        }
    };

    saveUiFeaturesPermission = (profileCode, uiFeaturesPermission) => {
        const profiles = cloneDeep(this.state.profiles);
        const profile = profiles.find((p) => p.code === profileCode);
        profile.uiFeaturesPermission = uiFeaturesPermission;

        this.setState({
            profiles,
            isModified: true,
        });
    };

    checkCalendarUiOrSharingBrowseUsersEnabled = (oldUIFeaturesPermission, newUIFeaturesPermission) => {
        return (
            (oldUIFeaturesPermission?.calendar?.enable === false ||
                oldUIFeaturesPermission?.calendar?.features?.ui?.enable === false ||
                oldUIFeaturesPermission?.sharing?.enable === false ||
                oldUIFeaturesPermission?.sharing?.features?.browse_users?.enable === false) &&
            newUIFeaturesPermission?.calendar?.enable === true &&
            newUIFeaturesPermission?.calendar?.features?.ui?.enable === true &&
            newUIFeaturesPermission?.sharing?.enable === true &&
            newUIFeaturesPermission?.sharing?.features?.browse_users?.enable === true
        );
    };

    checkAdminOrSqlLabFeatureEnabled = (oldUIFeaturesPermission, newUIFeaturesPermission) => {
        const oldSubFeatures =
            oldUIFeaturesPermission?.analytics?.features?.view_charts_and_dashboards?.subFeatures?.create_or_modify
                ?.subSubFeatures;
        const newsSubFeatures =
            newUIFeaturesPermission?.analytics?.features?.view_charts_and_dashboards?.subFeatures?.create_or_modify
                ?.subSubFeatures;

        const adminEnabled = oldSubFeatures?.admin?.enable === false && newsSubFeatures?.admin?.enable === true;
        const sqlLabEnabled = oldSubFeatures?.sql_lab?.enable === false && newsSubFeatures?.sql_lab?.enable === true;

        return adminEnabled || sqlLabEnabled;
    };

    handleAnalyticsAgreementSave = () => {
        const { analyticsAccessAgreementProfile, temporaryUIFeaturesPermission } = this.state;

        this.saveUiFeaturesPermission(analyticsAccessAgreementProfile.code, temporaryUIFeaturesPermission);

        this.setState({
            showAnalyticsAccessAgreement: false,
            analyticsAccessAgreementProfile: null,
            temporaryUIFeaturesPermission: null,
        });
    };

    handleAnalyticsAgreementCancel = () => {
        this.setState({
            showAnalyticsAccessAgreement: false,
            analyticsAccessAgreementProfile: null,
            temporaryUIFeaturesPermission: null,
        });
    };

    openCalendarEventPermissionModal = (profileName) => {
        this.setState({
            showCalendarEventPermissionModal: true,
            calendarEventPermissionModalProfileName: profileName,
        });
    };

    closeCalendarEventPermissionModal = () => {
        this.setState({
            showCalendarEventPermissionModal: false,
            calendarEventPermissionModalProfileName: null,
        });
    };

    handleChangeSearchFilter = (profileCode, { formId, searchBarFields }) => {
        const profiles = cloneDeep(this.state.profiles);
        const profile = profiles.find((p) => p.code === profileCode);
        profile.formId = formId;
        profile.searchBarFields = searchBarFields;
        this.setState({
            profiles,
            isModified: true,
        });
    };

    handleChangeSearchFilterModal =
        (profileCode) =>
        ({ show, invalidSearchBarFields, formIdIsInvalid }) => {
            const profile = this.state.profiles.find((p) => p.code === profileCode);
            this.setState({
                searchFilterModal: {
                    profileCode,
                    formId: profile.formId,
                    searchBarFields: profile.searchBarFields,
                    show,
                    invalidSearchBarFields,
                    formIdIsInvalid,
                },
            });
        };

    headerCellContent = (props) => {
        if (props.column.name === 'type') {
            return <TableHeaderRow.Cell {...props} style={{ ...props.style, border: 'unset' }} />;
        }
        return (
            <TableHeaderRow.Cell
                {...props}
                style={{ ...props.style, fontWeight: 600, fontSize: '100%', color: 'black' }}
            />
        );
    };

    calculateFirstColumnWidth(language) {
        switch (language) {
            case LANGUAGE_EN:
                return 300;
            case LANGUAGE_RU:
                return 350;
            case LANGUAGE_ES:
                return 350;
            case LANGUAGE_DE:
                return 380;
            case LANGUAGE_FR:
                return 440;
            default:
                return 300;
        }
    }

    buildStructure = (profiles) => {
        const structure = {
            columns: [
                {
                    name: 'type',
                    title: ' ',
                },
            ],
            exts: [
                {
                    columnName: 'type',
                    align: 'left',
                    width: this.calculateFirstColumnWidth(this.props.i18n.language),
                },
            ],
        };

        profiles.forEach((profile) => {
            const profileError = this.state.profileErrors[profile.code] || null;
            structure.columns.push({
                name: profile.code,
                title: (
                    <span className="c-permission__table-header-cell">
                        {profile.forSharedMap && (
                            <Tooltip title={this.props.t('permissions.profile.for_shared_map')}>
                                <i className="fas fa-share-alt" style={{ marginRight: '.5em' }} />
                            </Tooltip>
                        )}
                        {profile.isSystem ? (
                            <span>{profile.name}</span>
                        ) : (
                            <Tooltip
                                title={this.props.t('permissions.rename_profile', { profileName: `"${profile.name}"` })}
                            >
                                <DottedLink
                                    onClick={() => this.handleUpdateProfile(profile)}
                                    data-testid="permissions.rename_profile"
                                    className="c-permission__profile-rename"
                                >
                                    {profile.name}
                                </DottedLink>
                            </Tooltip>
                        )}
                        {!profile.isSystem && (
                            <span className="c-permission__profile-delete-entity">
                                <Tooltip
                                    title={profile.hasUsers ? this.props.t('permissions.dont_remove_profile') : ''}
                                >
                                    <span>
                                        <ConfirmationIconButton
                                            iconName="delete"
                                            color={profile.hasUsers ? 'default' : 'secondary'}
                                            disabled={profile.hasUsers}
                                            component="span"
                                            onClick={() => this.handleDeleteProfile(profile)}
                                            label=""
                                            processing={false}
                                            text={this.props.t('permissions.remove_profile')}
                                        />
                                    </span>
                                </Tooltip>
                            </span>
                        )}
                        {profileError && (
                            <Alert canClose onClose={() => this.handleCloseProfileError(profile)} placement="context">
                                {profileError}
                            </Alert>
                        )}
                    </span>
                ),
            });
            structure.exts.push({
                columnName: profile.code,
                align: 'center',
                width: 200,
            });
        });

        return structure;
    };

    buildDataSourcePermissions = (rows = []) => {
        const { dataSources, profiles, permissions } = this.state;

        dataSources.forEach((ds) => {
            rows.push({
                type: <span className="c-permissions_data_source_title">{ds.name}</span>,
            });
            let row = {
                type: ds.entities.map((entity) => (
                    <ul className="c-permissions__entity" key={entity.id}>
                        <li>{entity.label}</li>
                        <li>{this.props.t('permissions.owner')}</li>
                        <li>{this.props.t('permissions.non_owner')}</li>
                        <li>{this.props.t('record_sharing')}</li>
                    </ul>
                )),
            };
            profiles.forEach((profile) => {
                row[profile.code] = (
                    <Grid item key={profile.code} className="c-profile">
                        {
                            <ProfilePermissions
                                entities={ds.entities}
                                dataSourceId={ds.id}
                                profile={profile}
                                permissions={permissions[profile.code] || {}}
                                key={ds.id}
                                toggle={this.handleToggleProfile}
                                toggleRecordSharing={this.handleToggleRecordSharing}
                                onChangeOwner={this.handleChangeOwner}
                                onChangeOwnerFields={this.handleChangeOwnerFields}
                                onChangeNonOwner={this.handleChangeNonOwner}
                                onChangeNonOwnerFields={this.handleChangeNonOwnerFields}
                                hasUsers={profile.hasUsers}
                                onCloseProfileError={this.handleCloseProfileError}
                                subscription={this.state.subscription}
                                openRecordSharingModal={this.handleOpenRecordSharingModal}
                            />
                        }
                    </Grid>
                );
            });
            rows.push(row);
        });
    };

    closeSecuritySettingsModal = () => {
        this.setState({
            securitySettingsModalProfileCode: null,
        });
    };

    openSecuritySettingsModal = (profileCode) => {
        this.setState({
            securitySettingsModalProfileCode: profileCode,
        });
    };

    buildFeaturesUI = (rows = []) => {
        const { t } = this.props;
        const { profiles } = this.state;
        rows.push({
            type: <span className="c-permissions_ui_features_title">{t('permissions.ui_features')}</span>,
        });

        const securitySettingsRow = {
            type: t('security_settings'),
        };
        profiles.forEach((profile) => {
            securitySettingsRow[profile.code] = (
                <DottedLink onClick={() => this.openSecuritySettingsModal(profile.code)}>
                    {t('security_settings.configure')}
                </DottedLink>
            );
        });
        rows.push(securitySettingsRow);

        Object.keys(UI_FEATURES_DEFAULT).forEach((key) => {
            let row = {
                type: (
                    <UiTitleFeature
                        key={`module_title_${key}`}
                        moduleName={key}
                        subscription={this.state.subscription}
                    />
                ),
            };
            profiles.forEach((profile) => {
                row[profile.code] = (
                    <UiFeature
                        key={`${profile.code}_${key}`}
                        profile={profile}
                        nameModule={key}
                        onChange={this.handleChangeUiFeaturesPermission(profile.code)}
                        onChangeSearchFilterModal={this.handleChangeSearchFilterModal(profile.code)}
                        subscription={this.state.subscription}
                        forms={this.state.forms}
                        dataSources={this.state.dataSources}
                    />
                );
            });
            rows.push(row);
        });
    };

    buildRowsTable = () => {
        let rows = [];
        this.buildDataSourcePermissions(rows);
        this.buildFeaturesUI(rows);
        return rows;
    };

    handleCloseSearchFilter = () => {
        this.setState({
            searchFilterModal: { ...SEARCH_FILTER_MODAL_DEFAULTS },
        });
    };

    handleSaveSearchFilter = ({ formId, searchBarFields }) => {
        const { profileCode } = this.state.searchFilterModal;
        this.handleCloseSearchFilter();
        this.handleChangeSearchFilter(profileCode, { formId, searchBarFields });
    };

    handleSaveRecordSharingSettings = (rules) => {
        const { profileCode, entityId } = this.state.recordSharingModal;
        this.handleChangeRecordSharingRules(profileCode, entityId, rules);
        this.handleCloseRecordSharingModal();
    };

    getProfileByCode(code) {
        for (const profile of this.state.profiles) {
            if (profile.code === code) {
                return profile;
            }
        }

        return null;
    }

    render() {
        if (this.state.needRedirect) {
            return (
                <Redirect
                    to={reverse(routes.admin.account.index, {
                        accountId: userManager.getCurrentAccount() ? undefined : this.props.accountId,
                    })}
                />
            );
        }
        if (!this.state.loaded) {
            return this.props.t('loading');
        }
        const { recordSharingModal, showCalendarEventPermissionModal } = this.state;
        const rows = this.buildRowsTable();
        return (
            <React.Fragment>
                <Grid container>
                    <Grid container spacing={2} style={{ margin: '-8px -8px 0 -8px' }}>
                        <Grid item>
                            {this.props.bigHeader ? (
                                <h1 style={{ margin: 0 }}>{this.props.t('permissions.title')}</h1>
                            ) : (
                                <h2 style={{ margin: 0 }}>{this.props.t('permissions.title')}</h2>
                            )}
                        </Grid>
                        <Grid item>
                            <Tooltip title={this.props.t('permissions.new_profile')}>
                                <AddIconButton onClick={this.onAddProfile} />
                            </Tooltip>
                        </Grid>

                        <Grid item style={{ display: 'flex' }}>
                            <Grid container alignItems="center">
                                <LandingLink useLeadingIcon useTrailingIcon article="4337620" style={{ fontSize: 16 }}>
                                    {this.props.t('help')}
                                </LandingLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.errors && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Alert canClose onClose={this.handleCloseErrors} type={this.state.errors.type}>
                                    {this.state.errors.message}
                                    <ErrorMessage details={this.state.errors.details} />
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    {this.state.showAnalyticsAccessAgreement && (
                        <FormDialog
                            title={this.props.t('permission.analytics.access_agreement_title')}
                            onCancel={this.handleAnalyticsAgreementCancel}
                            onSave={this.handleAnalyticsAgreementSave}
                            cancelButtonTitle="NO"
                            saveButtonTitle="YES"
                            cancelButtonColor="inherit"
                        >
                            <AnalyticsAccessAgreement profile={this.state.analyticsAccessAgreementProfile} />
                        </FormDialog>
                    )}
                    {showCalendarEventPermissionModal && (
                        <CalendarEventPermissionModal
                            onClose={this.closeCalendarEventPermissionModal}
                            profileName={this.state.calendarEventPermissionModalProfileName}
                        ></CalendarEventPermissionModal>
                    )}

                    <DataGrid rows={rows} columns={this.state.structure.columns} rootComponent={GridRoot}>
                        <VirtualTable
                            height={'100%'}
                            containerComponent={TableContainer}
                            cellComponent={withStyles(CellStylesBody)(Table.Cell)}
                            noDataCellComponent={withStyles(CellStylesBody)(Table.Cell)}
                            stubCellComponent={withStyles(CellStylesBody)(TableHeaderRow.Cell)}
                            columnExtensions={this.state.structure.exts}
                            stubHeaderCellComponent={withStyles(CellStylesBody)(TableHeaderRow.Cell)}
                        />
                        <TableHeaderRow cellComponent={withStyles(CellStylesHeader)(this.headerCellContent)} />
                        <TableFixedColumns leftColumns={['type']} />
                    </DataGrid>

                    {this.state.addProfile && (
                        <ProfileEditDialog
                            title={this.props.t('permissions.create_profile')}
                            onSave={this.handleCreateDraftProfile}
                            onClose={this.handleCloseProfile}
                            profile={this.state.addProfile}
                            existingProfiles={this.state.profiles}
                            loading={this.state.isSaving}
                        />
                    )}
                    {this.state.renameProfile && (
                        <ProfileEditDialog
                            title={this.props.t('permissions.rename_profile', { profileName: '' })}
                            onSave={this.handleUpdatedProfile}
                            onClose={this.handleCloseProfile}
                            profile={this.state.renameProfile}
                            existingProfiles={this.state.profiles}
                            loading={this.state.isSaving}
                        />
                    )}
                    {this.state.searchFilterModal.show && (
                        <SearchFilterModal
                            formId={this.state.searchFilterModal.formId}
                            forms={this.state.forms}
                            searchBarFields={this.state.searchFilterModal.searchBarFields}
                            onClose={this.handleCloseSearchFilter}
                            onSave={this.handleSaveSearchFilter}
                            invalidSearchBarFields={this.state.searchFilterModal.invalidSearchBarFields}
                            isInvalidFormId={this.state.searchFilterModal.formIdIsInvalid}
                        />
                    )}
                    {recordSharingModal !== null && (
                        <RecordSharing
                            onClose={this.handleCloseRecordSharingModal}
                            onSave={this.handleSaveRecordSharingSettings}
                            rules={recordSharingModal.rules}
                            entityId={recordSharingModal.entityId}
                            entityLabel={recordSharingModal.entityLabel}
                            profileName={recordSharingModal.profileName}
                            accountId={recordSharingModal.accountId}
                            dataSourceId={recordSharingModal.dataSourceId}
                        />
                    )}
                </Grid>

                {this.state.securitySettingsModalProfileCode !== null && (
                    <PureFormDialog
                        open
                        title={this.props.t('security_settings.session_management_form.header')}
                        onClose={this.closeSecuritySettingsModal}
                    >
                        <SessionManagementForm
                            sessionSettings={
                                this.getProfileByCode(this.state.securitySettingsModalProfileCode).sessionSettings
                            }
                            onSave={this.handleChangeSessionSettings}
                        />
                    </PureFormDialog>
                )}
                <Grid container justifyContent="flex-end" className="c-permissions_ui_bottom-panel">
                    <Confirmation text={this.props.t('permission.discard.confirmation')} onConfirm={this.handleCancel}>
                        <Button
                            variant="contained"
                            color="default"
                            disabled={!this.state.isModified || this.state.isSaving}
                            data-testid="permission.discard"
                        >
                            {this.props.t('permission.discard')}
                        </Button>
                    </Confirmation>
                    <Button
                        onClick={this.handleSave}
                        variant="contained"
                        color="primary"
                        disabled={!this.state.isModified || this.state.isSaving}
                        data-testid="permission.save"
                    >
                        {this.props.t('permission.save')}
                    </Button>
                </Grid>

                <HistoryBlocker
                    isBlocking={this.state.isModified}
                    onSave={this.handleHistoryBlockerSave}
                    onDiscard={this.handleHistoryBlockerCancel}
                />
            </React.Fragment>
        );
    }
}

const CellStylesBody = () => ({
    cell: {
        border: 'unset',
        verticalAlign: 'top',
        '&:first-child': {
            paddingLeft: '18px',
        },
    },
});

const CellStylesHeader = () => ({
    cell: {
        border: 'unset',
    },
});

Permissions.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    bigHeader: PropTypes.bool,
    redirectable: PropTypes.bool,
};

Permissions.defaultProps = {
    bigHeader: true,
    redirectable: true,
};

export default withTranslation()(withRouter(withSnackbar(Permissions)));
