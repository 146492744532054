import React from 'react';
import {
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Button,
    FormControl,
    FormLabel,
    Box,
    Grid,
} from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { getDetail } from '../../../service/WorkflowActionManager';
import './../style.css';
import FormControlSelect from '../AbstractForm/FormControlSelect';
import AbstractForm from '../AbstractForm/AbstractForm';
import validator from 'validator';
import { POSITION, MESSAGE_TYPE, MESSAGE_TYPE_INPUT } from './Action';
import Hint from '../../Hint';
import PureFormDialog from '../../PureFormDialog';
import { FormActions } from '../../PureFormDialog/Form';
import FormulaInput from '../AbstractForm/FormulaInput';
import { isActiveEntity } from '../../../utils';

const t = i18n.t.bind(i18n);

class Form extends AbstractForm {
    validate() {
        return new Promise((resolve, reject) => {
            const errors = new Map();
            const { currentAction, currentEntity } = this.state;
            if (!currentAction.name) {
                errors.set('name', t('errors.not_empty'));
            }
            if (!currentAction.callContext.isValidForEmpty()) {
                errors.set('callContext', t('errors.not_empty'));
            }
            if (currentEntity && !isActiveEntity(currentEntity)) {
                errors.set('callContext', t('automation_elements.form.entity.inactive_error'));
            }

            const message = getDetail('message', currentAction.details, '');
            const messageType = getDetail('messageType', currentAction.details, '');
            const position = getDetail('position', currentAction.details, '');

            if (validator.isEmpty(message)) {
                errors.set('message', t('errors.invalid_value'));
            }
            if (validator.isEmpty(messageType)) {
                errors.set('messageType', t('errors.invalid_value'));
            }
            if (!validator.isIn(position, Object.values(POSITION))) {
                errors.set('position', t('errors.invalid_value'));
            }

            if (errors.size === 0) {
                resolve(currentAction);
                return;
            }
            reject(errors);
        });
    }

    renderStylingInput() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        if (getDetail('messageTypeInput', details, MESSAGE_TYPE_INPUT.FIXED) === MESSAGE_TYPE_INPUT.FIXED) {
            return (
                <Grid container alignItems="flex-end" spacing={1}>
                    <Grid item xs={9}>
                        <FormControlSelect
                            fullWidth
                            margin="dense"
                            label={t('workflow_actions.form.show_message.messageType')}
                            name="messageType"
                            value={getDetail('messageType', details, '')}
                            onChange={this.handleDetailsChange}
                            error={errors.has('messageType')}
                            helperText={errors.get('messageType')}
                            required
                            data-testid="workflow_actions.form.show_message.messageType"
                        >
                            <MenuItem value={MESSAGE_TYPE.SUCCESS}>
                                {t('workflow_actions.form.show_message.messageType.' + MESSAGE_TYPE.SUCCESS)}
                            </MenuItem>
                            <MenuItem value={MESSAGE_TYPE.INFO}>
                                {t('workflow_actions.form.show_message.messageType.' + MESSAGE_TYPE.INFO)}
                            </MenuItem>
                            <MenuItem value={MESSAGE_TYPE.WARNING}>
                                {t('workflow_actions.form.show_message.messageType.' + MESSAGE_TYPE.WARNING)}
                            </MenuItem>
                            <MenuItem value={MESSAGE_TYPE.ERROR}>
                                {t('workflow_actions.form.show_message.messageType.' + MESSAGE_TYPE.ERROR)}
                            </MenuItem>
                        </FormControlSelect>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            color="primary"
                            onClick={this.changeStylingInput}
                            data-testid="workflow_actions.form.show_message.messageType.formula"
                        >
                            {t('workflow_actions.form.show_message.messageType.formula')}&nbsp;
                            <Hint type="tooltip">
                                {t('workflow_actions.form.show_message.messageType.formula.hint')}
                            </Hint>
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                    <FormControl fullWidth margin="dense" error={errors.has('messageTypeFormula')}>
                        <FormulaInput
                            label={t('workflow_actions.form.show_message.messageType')}
                            fullWidth
                            margin="dense"
                            name="messageTypeFormula"
                            value={getDetail('messageTypeFormula', details, '')}
                            helperText={errors.get('messageTypeFormula') || ''}
                            error={errors.has('messageTypeFormula')}
                            multiline
                            callContext={this.state.currentAction.callContext}
                            onChange={this.handleDetailsChange}
                        />
                        <FormLabel position="bottom">
                            {t('workflow_actions.form.show_message.messageType.formula.description')}
                            <Box fontFamily="Monospace">success, warning, error, progress</Box>
                        </FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.changeStylingInput}
                        data-testid="workflow_actions.form.show_message.messageType.fixed"
                    >
                        {t('workflow_actions.form.show_message.messageType.fixed')}&nbsp;
                        <Hint type="tooltip">{t('workflow_actions.form.show_message.messageType.fixed.hint')}</Hint>
                    </Button>
                </Grid>
            </Grid>
        );
    }

    changeStylingInput = () => {
        this.handleDetailsChange({
            target: {
                name: 'messageTypeInput',
                value:
                    getDetail('messageTypeInput', this.state.currentAction.details, MESSAGE_TYPE_INPUT.FIXED) ===
                    MESSAGE_TYPE_INPUT.FIXED
                        ? MESSAGE_TYPE_INPUT.FORMULA
                        : MESSAGE_TYPE_INPUT.FIXED,
            },
        });
    };

    render() {
        const currentAction = this.state.currentAction;
        const details = currentAction.details;
        const errors = this.state.errors;

        return (
            <>
                <PureFormDialog
                    title={t('workflow_actions.form.invoke_url.entity.modal.title')}
                    onClose={this.handleCloseEntitySelect}
                    open={!this.state.entitySelected}
                    maxWidth="xs"
                    fullWidth
                >
                    <form noValidate autoComplete="off">
                        {this.renderEntitySelect(currentAction, errors)}
                        <FormActions />
                    </form>
                </PureFormDialog>

                <form noValidate autoComplete="off">
                    <TextField
                        autoFocus
                        label={t('workflow_actions.form.show_message.name')}
                        data-testid="workflow_actions.form.show_message.name"
                        fullWidth
                        required
                        margin="dense"
                        name="name"
                        value={currentAction.name || ''}
                        helperText={errors.get('name') || ''}
                        error={errors.has('name')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        label={t('workflow_actions.form.show_message.api_name')}
                        data-testid="workflow_actions.form.show_message.api_name"
                        fullWidth
                        margin="dense"
                        name="apiName"
                        value={currentAction.apiName || ''}
                        helperText={errors.get('apiName') || ''}
                        error={errors.has('apiName')}
                        InputProps={{ disableUnderline: false }}
                        onChange={this.handleInputChange}
                    />

                    {this.renderEntitySelect(currentAction, errors)}

                    <FormulaInput
                        label={t('workflow_actions.form.show_message.message')}
                        fullWidth
                        margin="dense"
                        multiline
                        name="message"
                        value={getDetail('message', details, '')}
                        helperText={errors.get('message') || ''}
                        error={errors.has('message')}
                        onChange={this.handleDetailsChange}
                        callContext={this.state.currentAction.callContext}
                    />

                    {this.renderStylingInput()}

                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={4}>
                            <FormControlSelect
                                fullWidth
                                margin="dense"
                                label={t('workflow_actions.form.show_message.position')}
                                name="position"
                                value={getDetail('position', details, '')}
                                onChange={this.handleDetailsChange}
                                error={errors.has('position')}
                                helperText={errors.get('position')}
                                required
                                data-testid="workflow_actions.form.show_message.position"
                            >
                                <MenuItem value={POSITION.TOP}>
                                    {t('workflow_actions.form.show_message.position.' + POSITION.TOP)}
                                </MenuItem>
                                <MenuItem value={POSITION.BOTTOM}>
                                    {t('workflow_actions.form.show_message.position.' + POSITION.BOTTOM)}
                                </MenuItem>
                            </FormControlSelect>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={getDetail('autoHide', details, false)}
                                        onChange={this.handleDetailsChange}
                                        name="autoHide"
                                        color="primary"
                                    />
                                }
                                margin="dense"
                                label={t('workflow_actions.form.show_message.autoHide')}
                            />
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
}

export { Form as ShowMessageForm };
