import { makeAutoObservable } from 'mobx';
import { routePointsLayer } from 'service/MapPage';

class MapPageManager {
    private _isUserInitialized = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isInitialized(): boolean {
        return this._isUserInitialized && routePointsLayer.isReady;
    }

    set isUserInitialized(isInitialized: boolean) {
        this._isUserInitialized = isInitialized;
    }

    get isUserInitialized(): boolean {
        return this._isUserInitialized;
    }
}

export default MapPageManager;
