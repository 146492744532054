import React from 'react';
import dispatcher from '../../service/dispatcher';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import moment from 'moment-timezone';
import { Autocomplete } from '@material-ui/lab';

interface Props extends WithTranslation {
    label: string;
    value: string;
    onChange: (timezone: string | null) => void;
}

interface State {
    timezoneMap: Map<string, TimezoneOption> | null;
    timezones: TimezoneOption[] | null;
}

type TimezoneOption = {
    label: string;
    value: string;
};

class TimezonePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            timezoneMap: null,
            timezones: null,
        };
    }

    componentDidMount() {
        const timeZones = moment.tz.names(); //TODO: probably change to getting timezones from 'timezones.ts' file
        const timezoneArray: TimezoneOption[] = [];
        const timezoneMap: Map<string, TimezoneOption> = new Map<string, TimezoneOption>();
        for (const i in timeZones) {
            const timeZone = timeZones[i];
            const tzOffset = moment.tz(timeZone).format('Z');
            const timeZoneOption = {
                label: timeZone + ' (GMT' + tzOffset + ')',
                value: timeZone,
            };
            timezoneArray.push(timeZoneOption);
            timezoneMap.set(timeZone, timeZoneOption);
        }

        this.setState((state: State) => {
            return { ...state, timezones: timezoneArray, timezoneMap };
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    render() {
        const { label, value, onChange } = this.props;
        const { timezones, timezoneMap } = this.state;

        let timezoneOption: TimezoneOption | null = null;
        if (timezoneMap) {
            timezoneOption = timezoneMap.get(value) ?? null;
        }

        return (
            <Autocomplete
                options={timezones ?? []}
                getOptionLabel={(option) => option.label}
                value={timezoneOption}
                onChange={(_, v) => onChange(v?.value ?? null)}
                renderInput={(params) => <TextField {...params} fullWidth label={label} />}
            />
        );
    }
}

export default withTranslation()(TimezonePicker);
