import { withTranslation, WithTranslation } from 'react-i18next';

import React from 'react';
import { Subscription } from '../../../interfaces/account';
import TextField from '@material-ui/core/TextField';
import AiTokenSlider from './AiTokenSlider';
import { FormLabel } from '@material-ui/core';
import { formatMoneyWithCommas } from '../MoneyFormat';

interface AiTokensSubscriptionEditFormProps extends WithTranslation {
    subscription: Subscription;
    errors: Map<string, any>;
    onChange: (key: string, value: any) => void;
}

class AiTokensSubscriptionEditForm extends React.Component<AiTokensSubscriptionEditFormProps, any> {
    handleInputChange = (event: any) => {
        this.props.onChange(event.target.name as string, event.target.value || null);
    };

    render() {
        const { subscription, errors, onChange, t } = this.props;

        return (
            <React.Fragment>
                <div style={{ marginTop: 32 }}>{t('billing.edit_form.section.ai')}</div>
                <FormLabel>{t('billing.edit_form.section.ai.additional_custom_tokens')}</FormLabel>
                <AiTokenSlider
                    value={subscription.additionalCustomAiTokens}
                    onChange={(value) => onChange('additionalCustomAiTokens', value)}
                />
                <TextField
                    label={t('billing.edit_form.section.ai.users_with_price', {
                        price: formatMoneyWithCommas(subscription.currency, subscription.plan.priceAiUsersPm),
                    })}
                    data-testid="billing.edit_form.section.ai.users_with_price"
                    placeholder={t('billing.edit_form.section.ai.users_with_price', {
                        price: formatMoneyWithCommas(subscription.currency, subscription.plan.priceAiUsersPm),
                    })}
                    type="number"
                    fullWidth
                    margin="dense"
                    name="aiUsers"
                    value={subscription.aiUsers}
                    helperText={errors.get('aiUsers') || ''}
                    error={errors.has('aiUsers')}
                    onChange={this.handleInputChange}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            step: 1,
                        },
                    }}
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(AiTokensSubscriptionEditForm);
