import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import DottedLink from '../DottedLink';
import MessagesViewDialog from './MessagesViewDialog';
import Calculator from '../../service/ImportMessages/Calculator';
import './style.css';
import {
    DataSource,
    ImportMessage,
    LEVEL,
    ORIGIN,
    SortedDsMessages,
    SortedFieldMessages,
} from '../../service/ImportMessages/types';
import { Account } from 'interfaces';
import MessageText from './MessageText';

interface Props extends WithTranslation {
    dataSource: DataSource;
    subscription?: null | Account.Subscription;
}

interface State {
    showModalLevel: null | LEVEL;
}

class DataSourceFirstMessages extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModalLevel: null,
        };
    }

    handleOpenModal = (level: LEVEL) => {
        this.setState({ showModalLevel: level });
    };

    handleCloseModal = () => {
        this.setState({ showModalLevel: null });
    };

    render() {
        const { dataSource, subscription } = this.props;
        const { showModalLevel } = this.state;
        const sortedInfoMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.INFO], subscription);
        const sortedWarningMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.WARNING], subscription);
        const sortedErrorMessages = Calculator.calculateSortedMessages([dataSource], [LEVEL.ERROR], subscription);
        return (
            <>
                {this.renderFirstMessage(sortedErrorMessages, LEVEL.ERROR)}
                {this.renderFirstMessage(sortedWarningMessages, LEVEL.WARNING)}
                {this.renderFirstMessage(sortedInfoMessages, LEVEL.INFO)}
                {showModalLevel && (
                    <MessagesViewDialog
                        dataSources={[dataSource]}
                        level={showModalLevel}
                        onClose={this.handleCloseModal}
                        subscription={subscription ?? null}
                    />
                )}
            </>
        );
    }

    renderFirstMessage = (sortedMessages: SortedDsMessages[], level: LEVEL) => {
        if (sortedMessages.length === 0) {
            return null;
        }
        const sortedDsMessages: SortedDsMessages = sortedMessages[0];
        const first = this.getFirstMessage(sortedDsMessages);
        if (first === null) {
            return null;
        }
        const messageText =
            first.origin === ORIGIN.INTERNAL ? first.message : '[' + first.httpCode + '] ' + first.message;

        return (
            <Alert severity={level} className="c-import-messages-setup-first-message-alert">
                <Grid container direction="row" wrap="nowrap">
                    <Grid item className="c-import-messages-setup-first-message">
                        <Typography noWrap>
                            <MessageText text={messageText} />
                        </Typography>
                    </Grid>
                    {
                        <Grid item style={{ marginLeft: 5 }}>
                            <DottedLink
                                onClick={() => this.handleOpenModal(level)}
                                data-testid="data_source.import_messages.open_button"
                            >
                                {this.props.t('data_source.import_messages.open_button')}
                            </DottedLink>
                        </Grid>
                    }
                </Grid>
            </Alert>
        );
    };

    getFirstMessage(sortedMessage: SortedDsMessages) {
        const dsMessages = sortedMessage.messages;
        if (dsMessages.length > 0) {
            return dsMessages[0];
        }
        const entityMessages = ([] as ImportMessage[]).concat(...sortedMessage.entities.map((e) => e.messages));
        if (entityMessages.length > 0) {
            return entityMessages[0];
        }
        const fields = ([] as SortedFieldMessages[]).concat(...sortedMessage.entities.map((e) => e.fields));
        const fieldMessages = ([] as ImportMessage[]).concat(...fields.map((f) => f.messages));
        if (fieldMessages.length > 0) {
            return fieldMessages[0];
        }
        return null;
    }

    getCountMessage(sortedMessage: SortedDsMessages) {
        let result = sortedMessage.messages.length;
        for (let entityMessages of sortedMessage.entities) {
            result += entityMessages.messages.length;
            for (let fieldMessages of entityMessages.fields) {
                result += fieldMessages.messages.length;
            }
        }
        return result;
    }
}

export default withTranslation('translations', { withRef: true })(DataSourceFirstMessages);
