import { action, computed, makeAutoObservable } from 'mobx';
import { Routing, User } from 'interfaces';
import { routeReportLoadedSessionRouteManager, routeViewerManager, routingSessionManager } from 'service/MapPage';
import { formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMAT_DATEFNS } from '../../utils';
import { userManager } from '../UserManager';
import dispatcher from '../dispatcher';
import events from '../../events';
import { PathsRequestInitiator } from 'interfaces/ws/paths';

interface NavigateMenuData {
    element: HTMLElement;
    route: Routing.Route.Route;
    activityIndex: number;
}

interface ShowRouteReportData {
    route: Routing.Route.Route;
    routeIndex: number;
}

export enum TABS {
    ROUTES = 1,
    UNASSIGNED_JOBS = 2,
}

const emptyFilters: Readonly<Routing.RouteReport.RouteFilters> = {
    userId: null,
    date: null,
    syncViewer: true,
};

/**
 * Отображение роутов в драфт моде.
 */
class RouteReportManager {
    private _isOpenNavigateMenu = false;
    private _navigateMenuData: NavigateMenuData | null = null;

    private _session: Routing.Session.Session | null = null;
    private _routes: Routing.Route.Route[] = [];
    private _users: User.User[] = [];
    private _inputUsers: User.User[] = [];
    private _dates: string[] = [];
    private _filters: Routing.RouteReport.RouteFilters = { ...emptyFilters };

    private _isShowRouteList: boolean = true;
    private _isShowRouteReport: boolean = false;
    private _showRouteReportData: ShowRouteReportData | null = null;

    private _currentTab: TABS = TABS.ROUTES;

    constructor() {
        makeAutoObservable(this, {
            currentTab: computed,
            showMultipleRoutes: computed,
            showSingleRoute: computed,
            isShowRouteList: computed,
            isShowRouteReport: computed,
            showRouteReportData: computed,
            singleRoute: computed,
            isInit: computed,
            isOpenNavigateMenu: computed,
            navigateMenuData: computed,
            routes: computed,
            users: computed,
            inputUsers: computed,
            dates: computed,
            filters: computed,
            session: computed,

            changeTab: action,
            initSessionRoutes: action,
            syncFilterWithRouteViewer: action,
            setRoutes: action,
            setFilterByUser: action,
            setFilterByDate: action,
            setSyncViewer: action,
            openNavigateMenu: action,
            closeNavigateMenu: action,
            showRouteReport: action,
            showRouteList: action,
            reset: action,
        });
    }

    initSessionRoutes(session: Routing.Session.Session, routes: Routing.Route.Route[]): void {
        this._session = { ...session };
        this._inputUsers = [...session.inputUsers].sort((a, b) => {
            return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        });
        this.setRoutes(routes);
    }

    get isInit(): boolean {
        return this._session !== null;
    }

    get isOpenNavigateMenu(): boolean {
        return this._isOpenNavigateMenu;
    }

    get navigateMenuData(): NavigateMenuData {
        if (this._navigateMenuData === null) {
            throw new Error('Navigate menu is not open.');
        }
        return this._navigateMenuData;
    }

    openNavigateMenu = (data: NavigateMenuData) => {
        this._isOpenNavigateMenu = true;
        this._navigateMenuData = data;
    };

    closeNavigateMenu = () => {
        this._isOpenNavigateMenu = false;
        this._navigateMenuData = null;
    };

    get routes(): Routing.Route.Route[] {
        return this._routes;
    }

    get users(): User.User[] {
        return this._users;
    }

    get inputUsers(): User.User[] {
        return this._inputUsers;
    }

    get dates(): string[] {
        return this._dates;
    }

    get filters(): Routing.RouteReport.RouteFilters {
        return this._filters;
    }

    get session(): Routing.Session.Session {
        if (this._session === null) {
            throw new Error('The routing session is not initialized.');
        }
        return this._session!;
    }

    // TODO может вынести в другое место?
    isActivityEntityPoint(activity: Routing.Route.Activity): activity is Routing.Route.ActivityEntityPoint {
        return (
            'entityId' in activity && activity.entityId !== null && 'recordId' in activity && activity.recordId !== null
        );
    }

    isActivityProspectingPoint(activity: Routing.Route.Activity): activity is Routing.Route.ActivityProspectingPoint {
        return 'entityId'! in activity && 'recordId' in activity && activity.recordId !== null;
    }

    private hasUnassignedJobs(): boolean {
        return this.session.unassignedJobs.length > 0;
    }

    get showMultipleRoutes(): boolean {
        return this.hasUnassignedJobs() || this.routes.length !== 1;
    }

    get showSingleRoute(): boolean {
        return !this.hasUnassignedJobs() && this.routes.length === 1;
    }

    isMultipleUser = (): boolean => {
        return this.users.length > 1;
    };

    get currentTab(): TABS {
        return this._currentTab;
    }

    changeTab(tab: TABS): void {
        this._currentTab = tab;
        dispatcher.dispatch(events.ROUTE_REPORT.TAB_CHANGED);
    }

    setRoutes = (routes: Routing.Route.Route[]): void => {
        if (routes.length === 0) {
            this._routes = routes;
            return;
        }

        const uniqueUsers: Map<number, User.User> = new Map();
        const uniqueDates: Set<string> = new Set();
        const currentUser = userManager.getCurrentUser();

        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            uniqueUsers.set(route.user!.id, route.user!);

            const date = formatInTimeZone(route.dateStartAt, currentUser.actualTimezone, DATE_FORMAT_DATEFNS);
            uniqueDates.add(date);
        }

        this._users = [...uniqueUsers.values()].sort((a, b) => {
            return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        });
        this._dates = [...uniqueDates.values()].sort((a, b) => a.localeCompare(b));
        this._routes = routes;

        // TODO может ли быть кол-во роутов 0?
        this._filters = {
            ...this._filters,
            userId: this.isMultipleUser() ? null : this._users[0].id,
            date: this._dates.length > 1 ? null : this._dates[0],
        };

        if (this._showRouteReportData !== null) {
            for (let route of this._routes) {
                if (this._showRouteReportData.route.id === route.id) {
                    this._showRouteReportData.route = { ...route };
                    break;
                }
            }
        }
    };

    setFilterByUser = (userId: number | null) => {
        this._filters.userId = userId;
        this.updateRouteViewer();
        dispatcher.dispatch(events.ROUTE_REPORT.FILTER_CHANGED);
    };

    setFilterByDate = (date: string | null) => {
        this._filters.date = date;
        this.updateRouteViewer();
        dispatcher.dispatch(events.ROUTE_REPORT.FILTER_CHANGED);
    };

    setSyncViewer = (syncViewer: boolean) => {
        this._filters.syncViewer = syncViewer;
        this.updateRouteViewer();
    };

    getFilteredRoutes = (): Routing.Route.Route[] => {
        return this._routes.filter((route) => {
            if (this._filters.userId !== null) {
                if (route.user!.id !== this._filters.userId) {
                    return false;
                }
            }
            if (this._filters.date !== null) {
                const currentUser = userManager.getCurrentUser();
                const date = formatInTimeZone(route.dateStartAt, currentUser.actualTimezone, DATE_FORMAT_DATEFNS);
                if (date !== this._filters.date) {
                    return false;
                }
            }
            return !route.isDraftDeleted;
        });
    };

    get singleRoute(): Routing.Route.Route {
        if (this.showMultipleRoutes) {
            throw new Error('Multiple routes mode');
        }
        if (this._routes.length === 0) {
            throw new Error('Routes are empty.');
        }
        return this._routes[0];
    }

    get isShowRouteList(): boolean {
        return this._isShowRouteList;
    }

    get isShowRouteReport(): boolean {
        return this._isShowRouteReport;
    }

    get showRouteReportData(): ShowRouteReportData | null {
        return this._showRouteReportData;
    }

    showRouteReport = (route: Routing.Route.Route, routeIndex: number) => {
        this._isShowRouteReport = true;
        this._isShowRouteList = false;
        this._showRouteReportData = { route, routeIndex };
        dispatcher.dispatch(events.ROUTE_REPORT.OPEN_SINGLE, route.id);
    };

    showRouteList = () => {
        const isStateChanged = !this._isShowRouteList;
        this._isShowRouteList = true;
        this._isShowRouteReport = false;
        this._showRouteReportData = null;
        if (isStateChanged) {
            dispatcher.dispatch(events.ROUTE_REPORT.RETURN_TO_LIST);
        }
    };

    reset(): void {
        this._routes = [];
        this._users = [];
        this._inputUsers = [];
        this._dates = [];
        this.showRouteList();
    }

    public syncFilterWithRouteViewer = () => {
        if (!this._filters.syncViewer) {
            return;
        }

        if (
            routeViewerManager.selectedDatetimeIntervalStart !== null &&
            routeViewerManager.selectedDatetimeIntervalStart === routeViewerManager.selectedDatetimeIntervalEnd &&
            this._dates.includes(routeViewerManager.selectedDatetimeIntervalStart)
        ) {
            this._filters.date = routeViewerManager.selectedDatetimeIntervalStart;
        }

        const usersIds = [];
        for (const selectedUser of routeViewerManager.selectedUsers) {
            if (this._users.some((user) => user.id === selectedUser.id)) {
                usersIds.push(selectedUser.id);
            }
        }
        if (usersIds.length === this._users.length) {
            this._filters.userId = null;
        } else if (usersIds.length === 1) {
            this._filters.userId = usersIds[0];
        }
    };

    private updateRouteViewer() {
        if (!this._filters.syncViewer) {
            return;
        }

        let startDate = this._filters.date,
            endDate = this._filters.date;
        if (this._filters.date === null) {
            const currentUser = userManager.getCurrentUser();
            for (const route of this.routes) {
                if (!route.dateStartAt) {
                    continue;
                }
                const routeStartDate = formatInTimeZone(
                    route.dateStartAt,
                    currentUser.actualTimezone,
                    DATE_FORMAT_DATEFNS,
                );

                if (!startDate || routeStartDate < startDate) {
                    startDate = routeStartDate;
                }
                if (!endDate || routeStartDate > endDate) {
                    endDate = routeStartDate;
                }
            }
        }

        const users = [];
        if (this._filters.userId !== null) {
            users.push(this._users.find((user) => user.id === this._filters.userId)!);
        } else {
            users.push(...this._users);
        }

        routeViewerManager.selectedUsers = users;
        routeViewerManager.selectedDatetimeIntervalStart = startDate;
        routeViewerManager.selectedDatetimeIntervalEnd = endDate;
        if (users.length === 1) {
            routeViewerManager.multiselect = false;
        }
        routeViewerManager.requestPaths(PathsRequestInitiator.ROUTE_REPORT);
    }

    getDisplayRoute = (): Routing.Route.Route | null => {
        let loadedRoute: Routing.Route.Route | null = null;

        if (routingSessionManager.isDraftMode) {
            if (this.showSingleRoute) {
                loadedRoute = this.singleRoute;
            } else {
                loadedRoute = this.showRouteReportData?.route || null;
            }
        } else {
            if (routingSessionManager.isShowLoadedSessionRoute) {
                loadedRoute = routeReportLoadedSessionRouteManager.route;
            }
        }

        return loadedRoute;
    };
}

export default RouteReportManager;
