import React from 'react';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import { withTranslation } from 'react-i18next';

class DateConstantInput extends React.PureComponent {
    render() {
        const { value, showTimeControl, disabled, onChange, error, t } = this.props;

        const strToDate = (str) => {
            const m = str.match(/^(\d{4})-(\d{2})-(\d{2})(\s(\d{2}):(\d{2}))?$/);
            if (m === null) {
                return null;
            }
            const date = new Date();
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.setFullYear(m[1]);
            date.setMonth(m[2] - 1);
            date.setDate(m[3]);
            date.setHours(m?.[5] ?? 0);
            date.setMinutes(m?.[6] ?? 0);
            return date;
        };

        const handleValueChange = (date) => {
            const dateToStr = (date) => {
                const f = (value) => {
                    return value < 10 ? '0' + value : value;
                };
                return date.getFullYear() + '-' + f(date.getMonth() + 1) + '-' + f(date.getDate());
            };

            const dateTimeToStr = (date) => {
                const f = (value) => {
                    return value < 10 ? '0' + value : value;
                };
                return (
                    date.getFullYear() +
                    '-' +
                    f(date.getMonth() + 1) +
                    '-' +
                    f(date.getDate()) +
                    ' ' +
                    f(date.getHours()) +
                    ':' +
                    f(date.getMinutes())
                );
            };

            onChange(showTimeControl ? dateTimeToStr(date) : dateToStr(date));
        };

        const date = strToDate(value);

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    error={!!error}
                    label={t('expresion_builder.date')}
                    format="yyyy-MM-dd"
                    margin="none"
                    inputVariant="outlined"
                    value={date}
                    onChange={handleValueChange}
                    style={{ width: '120px' }}
                    disabled={disabled}
                    TextFieldComponent={TextField}
                    helperText={error || ''}
                />
                {showTimeControl && (
                    <TimePicker
                        error={!!error}
                        label={t('expresion_builder.time')}
                        margin="none"
                        inputVariant="outlined"
                        value={date}
                        onChange={handleValueChange}
                        style={{ width: '90px', marginLeft: '8px' }}
                        disabled={disabled}
                    />
                )}
            </MuiPickersUtilsProvider>
        );
    }
}

export default withTranslation('translations', { withRef: true })(DateConstantInput);
