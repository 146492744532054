import React, { Children } from 'react';
import PropTypes from 'prop-types';
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { CustomPaging, DataTypeProvider, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { withStyles } from '@material-ui/core/styles';
import { TableLoadingState } from '../TableLoadingState';
import {
    BOOLEAN_FILTER_OPERATIONS,
    DATE_FILTER_OPERATIONS,
    DISTANCE_FILTER_OPERATIONS,
    FOREIGN_LOOKUP_FILTER_OPERATIONS,
    getDateFilterValue,
    getDateTimeFilterValue,
    getNumericFilterValue,
    MULTI_LOOKUP_FILTER_OPERATIONS,
    NUMERIC_FILTER_OPERATIONS,
    OPERATOR_BETWEEN,
    OPERATOR_IS_EMPTY,
    OPERATOR_IS_NOT_EMPTY,
    POLYMORPHIC_LOOKUP_FILTER_OPERATIONS,
    STRING_FILTER_OPERATIONS,
} from '../utils/tableFilter';
import Icon from '@material-ui/core/Icon';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './style.css';
import debounce from 'lodash/debounce';
import events from '../../events';
import dispatcher from '../../service/dispatcher';
import territoryManagerFactory from '../../service/Territories/TerritoryManager';
import { adapterManager } from '../../service/AdapterManager';
import Checkbox from '@material-ui/core/Checkbox';
import { sortFunc, userTimezoneToUtc } from '../../utils';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { MultipleSelect } from '../Controls';
import BigAutocomplete, { MAX_COUNT } from '../Territories/BigAutocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import { SingleRowMultipleAutocomplete } from '../SingleRowMultipleAutocomplete';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import { FIELD_DISTANCE } from '../Map/constants';
import { TABLE_VIEW_DEFAULT_PAGE_SIZE, TABLE_VIEW_PAGE_SIZES } from 'components/constants';
import mapStateManagerFactory from 'service/MapStateManagerFactory';
import { userManager } from 'service/UserManager';
import DateBetweenFilter from '../utils/DateBetweenFilter';
import {
    TYPE_BIGINT,
    TYPE_BOOLEAN,
    TYPE_DATE,
    TYPE_DATETIME,
    TYPE_DATETIME_RANGE,
    TYPE_FLOAT,
    TYPE_INTEGER,
    TYPE_STRING,
    TYPE_TEXT,
    TYPE_TEXT_ARRAY,
    TYPE_FILES,
} from '../../service/types';
import { DateFilterCell } from './DateFilterCell';
import { DateTimeFilterCell } from './DateTimeFilterCell';
import { FieldType } from '../types';

const t = i18n.t.bind(i18n);

/**
 * @deprecated Use components/DataTable/DataTable.tsx instead
 */
class DataTable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filterDelay = 500;
        this.pageSizes = TABLE_VIEW_PAGE_SIZES;
        this.defaultPageSize = TABLE_VIEW_DEFAULT_PAGE_SIZE;

        this.sorting = [];
        this.filters = [];

        this.state = {
            structure: null,
            records: null,
            pagination: {
                current: 0,
                size: this.defaultPageSize,
            },
            totalCount: 0,
            key: 0,
        };

        this.numericFilterOperations = NUMERIC_FILTER_OPERATIONS;
        this.distanceFilterOperations = DISTANCE_FILTER_OPERATIONS;
        this.stringFilterOperations = STRING_FILTER_OPERATIONS;
        this.multiLookupFilterOperations = MULTI_LOOKUP_FILTER_OPERATIONS;
        this.polymorphicLookupFilterOperations = POLYMORPHIC_LOOKUP_FILTER_OPERATIONS;
        this.foreignLookupFilterOperations = FOREIGN_LOOKUP_FILTER_OPERATIONS;
        this.booleanFilterOperations = BOOLEAN_FILTER_OPERATIONS;
        this.dateFilterOperations = DATE_FILTER_OPERATIONS;

        this.typesOfColumns = new Map();

        this.InteractiveRow = ({ children, onClick, row, ...rest }) => {
            return (
                <Table.Row {...rest} onClick={() => this.handleRowClick(row)}>
                    {children}
                </Table.Row>
            );
        };

        this.handleFiltersChanged = debounce(this.handleFiltersChanged.bind(this), this.filterDelay);

        this.filterMessages = DataTable.getFilterMessages(t);
        this.pagingPanelMessages = DataTable.getPagingMessages(t);
    }

    getFilters() {
        return this.convertFrontFiltersToServerFilters(this.filters);
    }

    static getFilterMessages = (t) => {
        return {
            filterPlaceholder: t('react_grid.filters.filterPlaceholder'),
            contains: t('react_grid.filters.contains'),
            notContains: t('react_grid.filters.notContains'),
            startsWith: t('react_grid.filters.startsWith'),
            endsWith: t('react_grid.filters.endsWith'),
            equal: t('react_grid.filters.equal'),
            notEqual: t('react_grid.filters.notEqual'),
            greaterThan: t('react_grid.filters.greaterThan'),
            greaterThanOrEqual: t('react_grid.filters.greaterThanOrEqual'),
            lessThan: t('react_grid.filters.lessThan'),
            lessThanOrEqual: t('react_grid.filters.lessThanOrEqual'),
            between: t('data_table.between'),
            isEmpty: t('data_table.is_empty'),
            isNotEmpty: t('data_table.is_not_empty'),
            clearFilter: t('data_table.clear_filter'),
        };
    };

    static getPagingMessages = (t) => {
        return {
            showAll: t('react_grid.paging_panel.show_all'),
            rowsPerPage: t('react_grid.paging_panel.row_of_page'),
            info: t('react_grid.paging_panel.description'),
        };
    };

    setupStructure(fields, pageSize) {
        let size = pageSize ?? this.state.pagination.size;
        if (!this.pageSizes.includes(size)) {
            size = this.pageSizes[0];
        }

        this.setState({
            structure: this.buildStructure(fields),
            totalCount: 0,
            records: null,
            pagination: {
                current: 0,
                size,
            },
        });
    }

    buildStructure(fields) {
        return this.buildDefaultStructure(fields);
    }

    buildDefaultStructure(fields) {
        const numericColumns = [];
        const distanceColumns = [];
        const dateColumns = [];
        const dateTimeColumns = [];
        const stringColumns = [];
        const multiSelectColumns = [];
        const selectColumns = [];
        const booleanColumns = [];
        const noDataColumns = [];
        const columns = [];
        const exts = [];
        const webLinkColumns = [];
        const territoriesColumns = [];
        const multiLookupColumns = [];
        const polymorphicLookupColumns = [];
        const foreignLookupColumns = [];

        this.typesOfColumns = new Map();

        for (let field of fields) {
            columns.push({
                id: field.id,
                name: field.columnName || field.name,
                columnName: field.columnName,
                apiName: field.name,
                originalApiName: field.originalApiName,
                title: field.title,
                type: field.type,
                lookup: field.lookup || null,
                picklist: field.picklist || [],
                getCellValue: field.getCellValue || null,
                defaultValue: field.defaultValue,
            });
            this.typesOfColumns.set(field.columnName, field.type);

            if (field.isLink) {
                webLinkColumns.push(field.columnName);
                continue;
            }

            if (field.type === 'json' && field.isMultiLookup) {
                multiLookupColumns.push(field.columnName);
                continue;
            }

            if (field.isPolymorphicLookup) {
                polymorphicLookupColumns.push(field.columnName);
                continue;
            }

            if (field.isForeignLookup) {
                foreignLookupColumns.push(field.columnName);
                continue;
            }

            if (field.name === 'mapsly_territories') {
                territoriesColumns.push(field.columnName);
                continue;
            }

            if (field.columnName === FIELD_DISTANCE) {
                distanceColumns.push(field.columnName);
                continue;
            }

            if (field.type === TYPE_TEXT_ARRAY || field.type === TYPE_FILES || field.type === FieldType.EMAILS) {
                multiSelectColumns.push(field.columnName);
                continue;
            }

            if (field.picklist) {
                selectColumns.push(field.columnName);
                continue;
            }

            switch (field.type) {
                case TYPE_INTEGER:
                case TYPE_BIGINT:
                case TYPE_FLOAT:
                    numericColumns.push(field.columnName);
                    break;
                case TYPE_STRING:
                case TYPE_TEXT:
                    stringColumns.push(field.columnName);
                    break;
                case TYPE_BOOLEAN:
                    booleanColumns.push(field.columnName);
                    break;
                case TYPE_DATE:
                    dateColumns.push(field.columnName);
                    break;
                case TYPE_DATETIME:
                    dateTimeColumns.push(field.columnName);
                    break;
                default:
                    noDataColumns.push(field.columnName);
            }
        }

        const noSortingColumns = noDataColumns.map((column) => ({ columnName: column, sortingEnabled: false }));
        for (let column of polymorphicLookupColumns) {
            noSortingColumns.push({ columnName: column, sortingEnabled: false });
        }

        return {
            numericColumns,
            distanceColumns,
            dateColumns,
            dateTimeColumns,
            stringColumns,
            booleanColumns,
            noDataColumns,
            columns,
            exts,
            webLinkColumns,
            territoriesColumns,
            multiLookupColumns,
            multiSelectColumns,
            selectColumns,
            noSortingColumns,
            polymorphicLookupColumns,
            foreignLookupColumns,
        };
    }

    getFields() {
        return Promise.resolve([]);
    }

    handleRowClick = (row) => {
        this.props.onRowClick && this.props.onRowClick(row);
    };

    setupReloadOnTimeZoneChange() {
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            const filterWithDateTime = this.filters.find(
                (filter) => this.typesOfColumns.get(filter.columnName) === TYPE_DATETIME && !!filter.value,
            );
            if (filterWithDateTime !== undefined) {
                this.loadData();
            }
        });
    }

    componentDidMount() {
        this.getFields().then((columns) => {
            this.setupStructure(columns); /// async?
            this.loadData();
        });
        this.setupReloadOnTimeZoneChange();
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleSortingChanged = (sorting) => {
        this.sorting = sorting;
        this.loadData();
    };

    handleFiltersChanged = (filters) => {
        const f = [];
        for (let filter of filters) {
            try {
                // eslint-disable-next-line no-unused-vars
                const _checkValue = this.convertFrontFilterToServerFilter(filter);
                f.push(filter);
            } catch (e) {}
        }
        if (JSON.stringify(this.filters) === JSON.stringify(f)) {
            return;
        }
        this.applyFilters(f);
    };

    convertFrontFiltersToServerFilters(filters) {
        const f = [];
        for (let filter of filters) {
            try {
                f.push(this.convertFrontFilterToServerFilter(filter));
            } catch (e) {
                // do nothing
            }
        }

        return f;
    }

    convertFrontFilterToServerFilter(filter) {
        const result = { ...filter };
        const type = this.typesOfColumns.get(filter.columnName);
        if (type === TYPE_INTEGER || type === TYPE_BIGINT || type === TYPE_FLOAT) {
            result.value = getNumericFilterValue(filter);
            return result;
        }
        if (type === TYPE_DATE) {
            result.value = getDateFilterValue(filter);
            return result;
        }
        if (type === TYPE_DATETIME) {
            result.value = getDateTimeFilterValue(filter);
            if (result.value === null) {
                return result;
            }

            const user = userManager.getCurrentUser();
            if (!user) {
                return result;
            }

            if (Array.isArray(result.value)) {
                result.value = result.value.map((v) => userTimezoneToUtc(v, user));
            } else {
                result.value = userTimezoneToUtc(result.value, user);
            }
            return result;
        }
        if (result.operation === OPERATOR_BETWEEN) {
            result.value = result.value.split(', ');
        }
        return result;
    }

    applyFilters(filters) {
        const timer = Timer.init(TIMER_OPERATIONS.DataTable.applyFilters, true).startChild(
            TIMER_OPERATIONS.DataTable.applyFilters,
        );
        this.filters = filters;
        this.loadData(true, timer);
    }

    handleCurrentPageChanged = (currentPage) => {
        this.setState(
            (state) => {
                state.records = null;
                state.pagination.current = currentPage;
                return state;
            },
            () => this.loadData(false),
        );
    };

    handlePageSizeChanged = (pageSize) => {
        this.setState(
            (state) => {
                state.records = null;
                state.pagination.size = pageSize;
                state.pagination.current = 0;
                return state;
            },
            () => {
                const user = userManager.getCurrentUser();
                const mapStateManager = mapStateManagerFactory.getManager(user.id);
                mapStateManager.setTableViewPageSize(this.props.entityId, pageSize);

                this.loadData();
            },
        );
    };

    requestData(ignorePage = false, parentTimer = null) {
        return null;
    }

    loadData(clearPage = true, timer = null) {
        if (timer instanceof Timer) {
            timer.tryTerminateAfter(3000);
        }
        const promise = this.requestData(clearPage, timer);
        if (promise === null) {
            return;
        }

        promise
            .then((data) => {
                this.setState((state) => {
                    return {
                        records: data.items,
                        totalCount: parseInt(data.total),
                        pagination: {
                            current: clearPage ? 0 : state.pagination.current,
                            size: state.pagination.size,
                        },
                    };
                });
            })
            .catch((error) => {
                this.props.onLoadingError && this.props.onLoadingError(error.message);
                this.setState((state) => {
                    return {
                        records: [],
                        totalCount: 0,
                        pagination: {
                            current: 0,
                            size: state.pagination.size,
                        },
                    };
                });
            })
            .finally(() => {
                if (timer) {
                    timer.end();
                }
            });
    }

    render() {
        const structure = this.state.structure;

        if (structure === null) {
            return <div>{t('loading')}</div>;
        }

        const recordsAreLoading = this.state.records === null;

        return (
            <DataGrid className="c-data-table" rows={this.state.records || []} columns={structure.columns}>
                <SortingState
                    onSortingChange={this.handleSortingChanged}
                    columnExtensions={structure.noSortingColumns}
                />
                <DataTypeProvider
                    for={structure.numericColumns}
                    availableFilterOperations={this.numericFilterOperations}
                />
                <DataTypeProvider for={structure.dateColumns} availableFilterOperations={this.dateFilterOperations} />
                <DataTypeProvider
                    for={structure.dateTimeColumns}
                    availableFilterOperations={this.dateFilterOperations}
                />
                <DataTypeProvider
                    for={structure.stringColumns}
                    availableFilterOperations={this.stringFilterOperations}
                />
                <DataTypeProvider
                    for={structure.booleanColumns}
                    availableFilterOperations={this.booleanFilterOperations}
                />
                <FilteringState defaultFilters={this.filters} onFiltersChange={this.handleFiltersChanged} />
                <Table
                    rowComponent={this.InteractiveRow}
                    columnExtensions={structure.exts}
                    cellComponent={StyledCell}
                    noDataCellComponent={() => (
                        <TableLoadingState columnCount={structure.columns.length} loading={recordsAreLoading} />
                    )}
                />
                <TableHeaderRow
                    cellComponent={StyledHeaderCell}
                    contentComponent={HeaderCellContent}
                    sortLabelComponent={withStyles(rightLabelHeaderStyles)(TableHeaderRow.SortLabel)}
                    showSortingControls
                />
                <TableFilterRow
                    showFilterSelector
                    iconComponent={FilterIcon}
                    cellComponent={StyledFilterCell}
                    messages={this.filterMessages}
                />

                <PagingState
                    currentPage={this.state.pagination.current}
                    onCurrentPageChange={this.handleCurrentPageChanged}
                    onPageSizeChange={this.handlePageSizeChanged}
                    pageSize={this.state.pagination.size}
                />
                <CustomPaging totalCount={this.state.totalCount} />
                <PagingPanel pageSizes={this.pageSizes} messages={this.pagingPanelMessages} />
            </DataGrid>
        );
    }
}

DataTable.propTypes = {
    onLoadingError: PropTypes.func,
    onRowClick: PropTypes.func,
};

const FilterCell = (props) => {
    const { column, filter } = props;
    if (column.name === 'mapsly_territories') {
        return <TerritoriesFilterCell {...props} />;
    }
    if (column.type === TYPE_TEXT_ARRAY) {
        return <MultiPicklistFilterCell {...props} />;
    }
    if (column.lookup) {
        return <TranslatedLookupFilterCell {...props} lookup={column.lookup} />;
    }
    if (!column.type) {
        return <TableCell className={props.classes && props.classes.cell} />;
    }
    if ([TYPE_INTEGER, TYPE_BIGINT, TYPE_FLOAT].includes(column.type)) {
        return <NumericFilterCell {...props} />;
    }
    if (filter && (filter.operation === OPERATOR_IS_EMPTY || filter.operation === OPERATOR_IS_NOT_EMPTY)) {
        return <TranslatedStringFilterCell {...props} />;
    }
    if (column.type === TYPE_DATE) {
        return <DateFilterCell {...props} />;
    }
    if (column.type === TYPE_DATETIME) {
        return <DateTimeFilterCell {...props} />;
    }
    if (column.type === TYPE_DATETIME_RANGE) {
        return <DateBetweenFilter {...props} />;
    }
    return <TranslatedStringFilterCell {...props} />;
};

const Row = ({ children, classes, ...rest }) => {
    return (
        <Table.Row {...rest} className={classes.row || null}>
            {children}
        </Table.Row>
    );
};
const rowStyles = {
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
        },
    },
};
const HoverStyledRow = withStyles(rowStyles)(Row);

const NumericFilterCell = (props) => {
    const style = {};
    try {
        if (getNumericFilterValue(props.filter) !== null) {
            style['backgroundColor'] = 'lavender';
        }
    } catch (e) {
        style['backgroundColor'] = 'mistyrose';
    }

    return <TableFilterRow.Cell {...props} style={style} />;
};

class StringFilterCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.currentSelect = null;
        this.currentEditForm = null;
        this.initStateFilter = false;
        this.currentFilter = null;
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_ENTITY_DATA_FILTER_CLEARED], this, () => {
            if (this.isStateFilter(this.currentSelect)) {
                this.clearFilter();
            }
        });

        dispatcher.subscribe([events.ENTITY_DATA_TABLE_ENTITY_CHANGED], this, () => {
            if (this.isStateFilter(this.currentSelect)) {
                this.clearSelect();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    clearEditForm = () => {
        if (this.currentEditForm && this.currentEditForm.props.onChange) {
            this.currentEditForm.props.onChange(undefined);
        }
    };

    clearSelect = () => {
        if (this.currentSelect && this.currentSelect.props.onChange) {
            const defaultValue = this.currentSelect.props.availableValues.find((item) => item !== 'clearFilter');
            this.currentSelect.props.onChange(defaultValue);
        }
    };

    clearFilter = () => {
        this.props.onFilter(null);
        this.clearEditForm();
        this.clearSelect();
    };

    setCurrentEditForm(editForm) {
        this.currentEditForm = editForm;
    }

    setCurrentSelect(select) {
        this.currentSelect = select;
    }

    isStateFilter = (select) => {
        return select.props.value === 'isEmpty' || select.props.value === 'isNotEmpty';
    };

    getAvailableValues = (select) => {
        let availableValues = [...select.props.availableValues];

        if (this.isStateFilter(select)) {
            availableValues.unshift('clearFilter');
        }

        return availableValues;
    };

    needRebuild = () => {
        const selectElement = this.getSelectComponent();
        if (this.currentEditForm === null || this.currentSelect === null) {
            return true;
        }

        if (JSON.stringify(this.props.filter) !== JSON.stringify(this.currentFilter)) {
            return true;
        }

        return this.currentSelect.props.value !== selectElement.props.value;
    };

    buildComponents = () => {
        if (!this.needRebuild()) {
            return;
        }

        const selectElement = this.getSelectComponent();
        const onFilter = this.props.onFilter;
        const filter = this.props.filter;
        const availableValues = this.getAvailableValues(selectElement);
        const newSelect = React.cloneElement(selectElement, {
            availableValues: availableValues,
            toggleButtonComponent: (ref) => {
                const button = React.Children.only(ref.children);
                const setButtonRef = (butRef) => {
                    ref.buttonRef(butRef);

                    switch (button.props.type) {
                        case 'isEmpty':
                        case 'isNotEmpty': {
                            if (!this.initStateFilter) {
                                onFilter({ value: button.props.type, operation: button.props.type });
                                this.initStateFilter = true;
                            }
                            break;
                        }
                        case 'clearFilter':
                            if (filter !== null) {
                                this.clearFilter();
                            }
                            break;
                        default:
                            if (this.initStateFilter) {
                                this.clearEditForm();
                            }
                            this.initStateFilter = false;
                            break;
                    }
                };
                const toggleComponent = selectElement.props.toggleButtonComponent(ref);
                return React.cloneElement(toggleComponent, {
                    buttonRef: setButtonRef,
                });
            },
        });

        this.setCurrentSelect(newSelect);
        let newEditForm = null;

        if (this.isStateFilter(newSelect)) {
            newEditForm = (
                <span style={{ width: '100%' }}>
                    {newSelect.props.value === 'isEmpty' ? t('data_table.is_empty') : t('data_table.is_not_empty')}
                </span>
            );
        }

        this.setCurrentEditForm(newEditForm === null ? this.getEditForm() : newEditForm);
        this.currentFilter = this.props.filter;
    };

    getEditForm = () => {
        return this.props.children.find((element) => {
            return element.props.availableValues === undefined;
        });
    };

    getSelectComponent = () => {
        return Children.toArray(this.props.children).find((element) => {
            return element.props.availableValues !== undefined;
        });
    };

    render() {
        const style = {};
        this.buildComponents();
        if (!this.isStateFilter(this.currentSelect) && this.props.filter !== null && this.props.filter.value !== null) {
            style['backgroundColor'] = 'lavender';
        }

        const { t, i18n, tReady, ...rest } = this.props;
        return (
            <TableFilterRow.Cell {...rest} style={style}>
                {this.currentSelect} {this.currentEditForm}
            </TableFilterRow.Cell>
        );
    }
}

const TranslatedStringFilterCell = withTranslation()(StringFilterCell);

class TerritoriesFilterCell extends React.PureComponent {
    componentDidMount() {
        dispatcher.subscribe(events.TERRITORIES_LOADED, this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleFilter = (event, territories) => {
        const value = territories.map((terr) => terr.id);
        this.props.onFilter(value.length > 0 ? { value, operation: 'in' } : null);
    };

    render() {
        const manager = territoryManagerFactory.getCurrentManager();
        if (!manager) {
            return null;
        }

        if (manager.getTerritoriesCurrentNames() === null) {
            return null;
        }
        let territories = [...manager.getTerritoriesCurrentNames()];

        const { filter, classes } = this.props;
        const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
        const isFilterEmpty = !Boolean(filter?.value.length);

        return (
            <TableFilterRow.Cell {...this.props} className={classes.cell} style={style}>
                <BigAutocomplete
                    width={300}
                    options={territories}
                    handleFilter={this.handleFilter}
                    selected={filter || { value: [] }}
                    multiple={true}
                    overflowText={t('territories.panel.first_batch_data_placeholder', { count: MAX_COUNT })}
                >
                    <TextField
                        style={{ minWidth: 140 }}
                        name="search"
                        placeholder={isFilterEmpty && t('territories.panel.data_placeholder')}
                        data-testid="territories.panel.data"
                    />
                </BigAutocomplete>
            </TableFilterRow.Cell>
        );
    }
}

class AdapterFilterCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            adapters: [],
        };
    }

    componentDidMount() {
        adapterManager.list().then((adapters) => {
            this.setState({
                adapters: adapters.sort(sortFunc('name')),
            });
        });
    }

    handleFilter = (e) => {
        const value = e.target.value;
        this.props.onFilter(value.length > 0 ? { value, operation: 'in' } : null);
    };

    handleRenderSelected = (selected) => {
        const adapterNames = [];
        for (let adapter of this.state.adapters) {
            if (selected.indexOf(adapter.id) > -1) {
                adapterNames.push(adapter.name);
            }
        }

        return adapterNames.join(', ');
    };

    render() {
        const { filter, classes } = this.props;
        const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
        const options = this.state.adapters;
        return (
            <TableFilterRow.Cell {...this.props} className={classes.cell} style={style}>
                <MultipleSelect
                    value={filter ? filter.value : []}
                    displayEmpty
                    onChange={this.handleFilter}
                    renderValue={this.handleRenderSelected}
                    fullWidth
                    data-testid={'adapter_filter_cell'}
                >
                    {options.map((adapter) => (
                        <MenuItem key={adapter.id} value={adapter.id}>
                            <Checkbox
                                checked={filter ? filter.value.indexOf(adapter.id) > -1 : false}
                                color="primary"
                            />
                            {adapter.name}
                        </MenuItem>
                    ))}
                </MultipleSelect>
            </TableFilterRow.Cell>
        );
    }
}

const LookupFilterCell = (props) => {
    const { filter, onFilter, classes } = props;
    const { t, i18n, tReady, lookup, ...restProps } = props;
    const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
    return (
        <TableFilterRow.Cell {...restProps} className={classes.cell} style={style}>
            <Select
                value={filter ? filter.value : ''}
                displayEmpty
                onChange={(e) => onFilter(e.target.value !== '' ? { value: e.target.value, operation: 'equal' } : null)}
                fullWidth
                data-testid="lookup_filter_cell"
            >
                <MenuItem value="" data-testid="data_table.any">
                    {t('data_table.any')}
                </MenuItem>
                {lookup.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </TableFilterRow.Cell>
    );
};

const TranslatedLookupFilterCell = withTranslation()(LookupFilterCell);

class PicklistFilterCell extends React.PureComponent {
    handleFilter = (event, options) => {
        const value = options?.map((option) => option.value);
        this.props.onFilter(value.length > 0 ? { value, operation: 'in' } : null);
    };

    render() {
        const { filter, classes } = this.props;
        const { picklist, disableSorting } = this.props.column;
        const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
        const options = disableSorting ? [...picklist] : [...picklist].sort(sortFunc('label'));
        const values = options.filter((option) => filter?.value.includes(option.value));

        return (
            <TableFilterRow.Cell {...this.props} className={classes.cell} style={style}>
                <SingleRowMultipleAutocomplete
                    options={options || []}
                    value={values || []}
                    getOptionLabel={(option) => option.label}
                    size="small"
                    fullWidth
                    disableClearable={true}
                    onChange={this.handleFilter}
                    renderOption={(option) => (
                        <React.Fragment>
                            <Checkbox color="primary" checked={values.includes(option) || false} />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} />}
                />
            </TableFilterRow.Cell>
        );
    }
}

class MultiPicklistFilterCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            picklist: Array.isArray(this.props.column.picklist) ? this.props.column.picklist : [],
            loading: props.column.picklist instanceof Promise,
        };

        if (props.column.picklist instanceof Promise) {
            props.column.picklist.then((picklist) => {
                this.setState({ picklist, loading: false });
            });
        }
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_FIELD_PICKLIST_CHANGED, this, ({ fieldId, picklist }) => {
            if (fieldId === this.props.column.id) {
                this.setPicklist(picklist);
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    setPicklist = (picklist) => {
        if (picklist instanceof Promise) {
            this.setState({ loading: true });
            picklist.then((picklist) => {
                this.setState({ picklist, loading: false });
            });
        } else {
            this.setState({ picklist });
        }
    };

    handleFilter = (event, options) => {
        const value = options?.map((option) => option.value);
        this.props.onFilter(value.length > 0 ? { value, operation: 'in' } : null);
    };

    render() {
        const { filter, classes } = this.props;
        const { picklist } = this.state;
        const style = filter && filter.value !== '' ? { backgroundColor: 'lavender' } : {};
        const options = Array.isArray(picklist) ? [...picklist].sort(sortFunc('label')) : [];

        return (
            <TableFilterRow.Cell {...this.props} className={classes && classes.cell} style={style}>
                <SingleRowMultipleAutocomplete
                    options={options || []}
                    value={options.filter((option) => filter?.value.includes(option.value)) || []}
                    getOptionLabel={(option) => option.label}
                    size="small"
                    fullWidth
                    disableClearable={true}
                    loading={this.state.loading}
                    onChange={this.handleFilter}
                    renderOption={(option) => (
                        <React.Fragment>
                            <Checkbox color="primary" checked={filter?.value.includes(option.value) || false} />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </TableFilterRow.Cell>
        );
    }
}

const BooleanFilterCell = (props) => {
    const { filter, onFilter, classes } = props;
    const { t, i18n, tReady, ...restProps } = props;
    return (
        <TableFilterRow.Cell {...restProps} className={classes.cell}>
            <Select
                value={filter ? filter.value : ''}
                displayEmpty
                onChange={(e) => onFilter(e.target.value ? { value: e.target.value, operation: 'equal' } : '')}
                fullWidth
                data-testid="boolean_filter_cell"
            >
                <MenuItem value="" data-testid="data_table.any">
                    {t('data_table.any')}
                </MenuItem>
                <MenuItem value="1" data-testid="data_table.yes">
                    {t('data_table.yes')}
                </MenuItem>
                <MenuItem value="0" data-testid="data_table.no">
                    {t('data_table.no')}
                </MenuItem>
            </Select>
        </TableFilterRow.Cell>
    );
};

const TranslatedBooleanFilterCell = withTranslation()(BooleanFilterCell);

const HeaderCellContent = (props) => {
    const p = { ...props, align: 'center' };
    return <TableHeaderRow.Content {...p} />;
};

const FilterIcon = ({ type, ...restProps }) => {
    if (type === 'between') {
        return <Icon>settings_ethernet</Icon>;
    }
    if (type === 'isEmpty') {
        return <span className="custom-filter-icon-empty">«»</span>;
    }
    if (type === 'isNotEmpty') {
        return <span className="custom-filter-icon-not-empty">«...»</span>;
    }
    if (type === 'clearFilter') {
        return <Icon>clear</Icon>;
    }
    return <TableFilterRow.Icon type={type} {...restProps} />;
};

const regularPaddingCellStyles = (theme) => ({
    cell: {
        '&:first-child': {
            paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
            paddingRight: theme.spacing(1),
        },
    },
});

const rightIconCellStyles = () => ({
    flexContainer: {
        flexFlow: 'row-reverse',
    },
});

const filterCellStyles = (theme) => ({ ...regularPaddingCellStyles(theme), ...rightIconCellStyles(theme) });

const rightLabelHeaderStyles = {
    sortLabelRight: {
        flexDirection: 'row',
    },
};

const SortLabel = withStyles(rightLabelHeaderStyles)(TableHeaderRow.SortLabel);
const StyledFilterCell = withStyles(filterCellStyles)(FilterCell);
const StyledCell = withStyles(regularPaddingCellStyles)(Table.Cell);
const StyledHeaderCell = withStyles(regularPaddingCellStyles)(TableHeaderRow.Cell);

export default withTranslation('translations', { withRef: true })(DataTable);

export {
    DataTable,
    TranslatedBooleanFilterCell as BooleanFilterCell,
    TranslatedStringFilterCell as StringFilterCell,
    FilterCell,
    TerritoriesFilterCell,
    NumericFilterCell,
    DateFilterCell,
    DateTimeFilterCell,
    FilterIcon,
    HeaderCellContent,
    PicklistFilterCell,
    MultiPicklistFilterCell,
    AdapterFilterCell,
    HoverStyledRow,
    SortLabel,
    StyledCell,
    StyledFilterCell,
    StyledHeaderCell,
};
