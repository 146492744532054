import apiRoutes, { reverse } from 'api/apiRoutes';
import BackendService from '../api/BackendService';
import { Filter, OPERATOR_EQUAL, OPERATOR_IN } from 'components/utils/tableFilter';
import { Sorting } from '@devexpress/dx-react-grid';
import hash from 'object-hash';
import basePointManager from './BasePointManager';
import qsManager from './QueryStringManager';
import filterParamsManager from './FilterParamsManager';
import dispatcher from './dispatcher';
import events from '../events';
import { STANDARD_FIELDS } from '../standardFields';
import metadataManager from './MetadataManager';
import copy from 'copy-to-clipboard';
import { formatCoordinatesToAddress } from '../utils';

export interface RecordManagerInterface {
    getRecordsWithCount(request: RecordsRequest): Promise<RecordsResponse>;
    getRecord(entityId: number, recordId: string, columnNames?: string[]): Promise<RecordResponse>;
}

export interface Record {
    [prop: string]: any;
}

export interface RecordResponse {
    record: Record;
}

export type WithDataQuery = {
    recordIds: string[];
    labels: string[];
    data: Array<number[] | string[]>;
};

const WITH_DATA_COLUMN_PREFIX = 'mapsly_additional';

const WITH_DATA_COLUMN_REGEXP = new RegExp(`^${WITH_DATA_COLUMN_PREFIX}_(\\d|[1-9]\\d*)$`);

export const withDataQueryColumnName: (index: Number) => string = (index) =>
    `${WITH_DATA_COLUMN_PREFIX}_${index.toFixed()}`;

export const withDataQueryColumnNames: (withData?: WithDataQuery) => string[] = (withData?) =>
    withData?.data.map((_data, index) => withDataQueryColumnName(index)) ?? [];

export const withIdsFilter: (ids?: string[]) => Filter[] = (ids) => {
    if (!ids) {
        return [];
    }

    return ids.length
        ? [
              {
                  columnName: STANDARD_FIELDS.ID,
                  operation: OPERATOR_IN,
                  value: ids,
              },
          ]
        : [
              {
                  columnName: STANDARD_FIELDS.ID,
                  operation: OPERATOR_EQUAL,
                  value: '',
              },
          ];
};

export const isWithDataColumnNameLike: (columnName: string) => boolean = (columnName) =>
    WITH_DATA_COLUMN_REGEXP.test(columnName);

export const isWithDataColumnName: (withData: WithDataQuery | undefined, columnName: string) => boolean = (
    withData,
    columnName,
) => Number(WITH_DATA_COLUMN_REGEXP.exec(columnName)?.[1]) < (withData?.data.length ?? 0);

export interface RecordsRequest {
    entityId: number;
    layerId?: number;
    viewId?: number;
    sections?: string | null;
    filters?: Filter[];
    sorting?: Sorting[];
    offset?: number;
    limit?: number;
    apiNames?: string[];
    resultType?: string;
    withData?: WithDataQuery;
}

export interface RecordsResponse {
    items: Record[] | string;
    total: number;
}

export class RecordManager extends BackendService implements RecordManagerInterface {
    private records: Map<string, Record>;

    constructor() {
        super();

        this.records = new Map();

        dispatcher.subscribe(events.WS_ENTITIES_CHANGED, this, (payload: { entityIds: Array<number> }) =>
            this.handleEntityUpdated(payload.entityIds),
        );
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload: { changesEntities: Array<number> }) =>
            this.handleEntityUpdated(payload.changesEntities),
        );
        dispatcher.subscribe(events.WS_UPDATE_RECORDS_RESPONSE, this, this.handleRecordUpdated);
        dispatcher.subscribe(events.WS_ENTITY_RECORDS_MODIFIED, this, this.handleRecordModified);
        dispatcher.subscribe(events.BASE_POINT, this, () => {
            this.records = new Map();
        });
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, () => {
            this.records = new Map();
        });
    }

    handleEntityUpdated = (entityIds: Array<number>) => {
        for (const key of this.records.keys()) {
            const [eId] = key.split('_');
            if (entityIds.includes(parseInt(eId))) {
                this.records.delete(key);
            }
        }
    };
    handleRecordUpdated = (event: { entityId: number; error: any }) => {
        if (event.error) {
            return;
        }
        for (const key of this.records.keys()) {
            const [eId] = key.split('_');
            if (parseInt(eId) === event.entityId) {
                this.records.delete(key);
            }
        }
    };
    handleRecordModified = (event: { entityId: string; pointIds: Array<string>; modificationType: string }) => {
        if (event.modificationType !== 'update') {
            return;
        }
        for (const pointId of event.pointIds) {
            this.records.delete(RecordManager.getRecordsKey(event.entityId, pointId));
        }
    };

    async getRecordsWithCount(request: RecordsRequest): Promise<RecordsResponse> {
        const { entityId, ...params } = request;
        (params as any).basePoint = RecordManager.getBasePoint();
        (params as any).urlParams = qsManager.getSearchParams();
        (params as any).filterParams = filterParamsManager.getFilterParams();
        const url = reverse(apiRoutes.entity.records, { entityId });
        return this.requestApi(url + '?_h=' + hash.MD5(params), 'POST', params);
    }

    async getRecordsWithHasMore(request: RecordsRequest): Promise<RecordsResponse> {
        const { entityId, ...params } = request;
        (params as any).basePoint = RecordManager.getBasePoint();
        (params as any).urlParams = qsManager.getSearchParams();
        (params as any).filterParams = filterParamsManager.getFilterParams();
        const url = reverse(apiRoutes.entity.records.hasMore, { entityId });
        return this.requestApi(url + '?_h=' + hash.MD5(params), 'POST', params);
    }

    async getRecord(
        entityId: number,
        recordId: string,
        apiNames?: string[],
        force: boolean = false,
    ): Promise<RecordResponse> {
        const recordsKey = RecordManager.getRecordsKey(entityId, recordId);

        const url = reverse(apiRoutes.entity.record, { entityId, recordId });
        const promise =
            this.records.has(recordsKey) && !force
                ? Promise.resolve({ record: this.records.get(recordsKey) as Record })
                : this.requestApi(url, 'POST', { basePoint: RecordManager.getBasePoint() }).then(
                      (response: RecordResponse) => {
                          this.records.set(recordsKey, response.record);
                          return response;
                      },
                  );

        return promise.then((response: RecordResponse) => {
            if (apiNames && apiNames.length) {
                const record: Record = {};
                for (const apiName of apiNames) {
                    record[apiName] = response.record[apiName] ?? null;
                }
                return { record };
            }
            return response;
        });
    }

    async getDemoRecord(entityId: number, force: boolean = false): Promise<RecordResponse> {
        const recordsKey = RecordManager.getRecordsKey(entityId, null);

        const url = reverse(apiRoutes.entity.demoRecord, { entityId });
        return this.records.has(recordsKey) && !force
            ? Promise.resolve({ record: this.records.get(recordsKey) as Record })
            : this.requestApi(url, 'POST', { basePoint: RecordManager.getBasePoint() }).then(
                  (response: RecordResponse) => {
                      this.records.set(recordsKey, response.record);
                      return response;
                  },
              );
    }

    private static getRecordsKey(...params: any[]): string {
        return params.join('_');
    }

    private static getBasePoint(): { lat: number; lng: number } | undefined {
        const basePoint = basePointManager.getBasePoint();
        return basePoint ? { lat: basePoint.lat, lng: basePoint.lng } : undefined;
    }

    async getAddressToCopyToClipboard(entityId: number, recordId: string) {
        let entity;
        let record;
        let address = '';
        try {
            entity = await metadataManager.requestEntityForUser(entityId);
            record = (await this.getRecord(entityId, recordId))['record'];
        } catch (e) {
            return '';
        }

        if (entity.pinField && record[entity.pinField]) {
            address = record[entity.pinField];
        } else if (entity.useLatLngFields && entity.latField && entity.lngField) {
            const isCoordinateTypeValid = (coordinate: any) =>
                typeof coordinate === 'string' || typeof 'coordinate' === 'number';
            const lat = record[entity.latField];
            const lng = record[entity.lngField];
            if (isCoordinateTypeValid(lat) && isCoordinateTypeValid(lng)) {
                address = formatCoordinatesToAddress(lat, lng);
            }
        }

        return address;
    }

    async copyAddressToClipboard(entityId: number, recordId: string) {
        const address = await this.getAddressToCopyToClipboard(entityId, recordId);

        copy(address);

        return address;
    }
}

export const recordManager = new RecordManager();
