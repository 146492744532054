import BackendService from '../api/BackendService';
import { Feature } from '../interfaces/signUpFeatures';
import apiRoutes, { reverse } from '../api/apiRoutes';

class DefaultFeaturesManager extends BackendService {
    private defaultFeaturesStructure: Array<Feature> | null = null;

    getDefaultFeaturesStructure(): Promise<Array<Feature>> {
        if (null !== this.defaultFeaturesStructure) {
            return Promise.resolve(this.defaultFeaturesStructure);
        }

        const url = reverse(apiRoutes.interestingFeatures.defaultStructure);

        return this.requestApi(url, 'GET').then((response: Array<Feature>) => {
            this.defaultFeaturesStructure = response;

            return response;
        });
    }
}

export const defaultFeaturesManager = new DefaultFeaturesManager();
