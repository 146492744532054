import React from 'react';
import { fileManager } from '../../service/File/FileManager';
import { MapslyFile, FileType, FileMeta } from 'interfaces/file';
import './style.css';
import { Menu, MenuItem } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { weAreInAndroidNativeApp } from '../../utils';

enum Capture {
    Environment = 'environment',
    User = 'user',
}

interface FileButtonProps
    extends WithTranslation,
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    children: React.ReactNode;
    onFileChange: (files: MapslyFile[]) => void;
    allowedTypes?: FileType[];
    fileMeta?: FileMeta;
    multiple?: boolean;
}

interface FileButtonState {
    anchorEl: any;
}

class FileInputButton extends React.PureComponent<FileButtonProps, FileButtonState> {
    private defaultInput: React.RefObject<HTMLInputElement>;
    private captureInput: React.RefObject<HTMLInputElement>;

    constructor(props: FileButtonProps) {
        super(props);

        this.state = {
            anchorEl: null,
        };

        this.defaultInput = React.createRef();
        this.captureInput = React.createRef();
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []).map((file) => {
            return fileManager.createFile(file, file.name, this.props.fileMeta);
        });
        this.props.onFileChange(files);
        event.target.value = '';
    };

    handleLabelClick = (event: React.MouseEvent) => {
        if (weAreInAndroidNativeApp()) {
            this.openMenu(event);
        } else {
            this.handleDefaultClick();
        }
    };

    openMenu = (event: React.MouseEvent) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        if (this.state.anchorEl !== null) {
            this.setState({ anchorEl: null });
        }
    };

    handleDefaultClick = () => {
        this.defaultInput.current && this.defaultInput.current.click();
        this.closeMenu();
    };

    handleCaptureClick = () => {
        this.captureInput.current && this.captureInput.current.click();
        this.closeMenu();
    };

    renderInput = (ref: React.RefObject<HTMLInputElement>, capture?: Capture) => {
        const { allowedTypes = [], multiple = false } = this.props;

        const accept = [];
        if (capture) {
            accept.push('image/*,.heic,.heif');
        } else if (!allowedTypes.includes(FileType.Document)) {
            for (const type of allowedTypes) {
                accept.push(`${type}/*`);
                if (type === FileType.Image) {
                    accept.push('.heic,.heif');
                }
                if (type === FileType.Audio) {
                    accept.push('audio/mp3,audio/wav');
                }
            }
        }

        return (
            <input
                type="file"
                accept={accept.length ? accept.join(',') : undefined}
                onChange={this.handleChange}
                multiple={multiple}
                style={{ display: 'none' }}
                ref={ref}
                capture={capture}
            />
        );
    };

    render() {
        const { children, onFileChange, fileMeta, multiple, allowedTypes, t, tReady, ...rest } = this.props;

        if (weAreInAndroidNativeApp()) {
            return (
                <React.Fragment>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={this.state.anchorEl !== null}
                        onClose={this.closeMenu}
                        disableAutoFocusItem
                    >
                        <MenuItem
                            onClick={this.handleCaptureClick}
                            data-testid="file_input.file_input_button.from_camera"
                        >
                            {this.props.t('file_input.input_button.from_camera')}
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleDefaultClick}
                            data-testid="file_input.file_input_button.from_gallery"
                        >
                            {this.props.t('file_input.input_button.from_gallery')}
                        </MenuItem>
                    </Menu>

                    <span {...rest} onClick={this.handleLabelClick}>
                        {children}
                        {this.renderInput(this.defaultInput)}
                        {this.renderInput(this.captureInput, Capture.Environment)}
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span {...rest} onClick={this.handleLabelClick}>
                    {children}
                    {this.renderInput(this.defaultInput)}
                </span>
            );
        }
    }
}

export default withTranslation()(FileInputButton);
