import React from 'react';
import { TextField, Button, Typography, InputAdornment } from '@material-ui/core';
import i18n from '../../../locales/i18n';
import { FormulaEditor } from '../../FormulaEditor';
import PropTypes from 'prop-types';
import { CallContext } from '../../utils/CallContext';
import { FormActions } from '../../PureFormDialog/Form';
import PureFormDialog from '../../PureFormDialog';
import Hint from '../../Hint';
const t = i18n.t.bind(i18n);

class FormulaInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showFormulaModal: false,
            showWarningModal: false,
            currentEvent: null,
        };
    }

    handleOpenFormulaModal = () => {
        if (this.props.disabled) {
            return;
        }
        this.setState({ showFormulaModal: true });
    };

    handleCloseFormulaModal = () => {
        this.setState({ showFormulaModal: false });
    };

    handleFormulaModal = (value, { isTwigTemplate }) => {
        const event = {
            target: {
                type: 'formula',
                name: this.props.name,
                value: value,
                isTwigTemplate,
            },
        };

        if (this.props.noTemplateWarning && !isTwigTemplate) {
            this.setState({ showWarningModal: true, currentEvent: event });
        } else {
            this.saveFormula(event);
        }
    };

    handleWarningModal = () => {
        this.saveFormula(this.state.currentEvent);
        this.closeWarningModal();
    };

    closeWarningModal = () => {
        this.setState({ showWarningModal: false, currentEvent: null });
    };

    saveFormula = (event) => {
        this.setState({ showFormulaModal: false }, () => this.props.onChange(event));
    };

    renderEndAdornment = () => {
        const { hideEditButton, hint, disabled } = this.props;

        if (hideEditButton && !hint) {
            return null;
        }

        return (
            <InputAdornment position="end">
                {hint && (
                    <Hint type="tooltip" iProps={{ style: { margin: '0 5px' } }}>
                        {hint}
                    </Hint>
                )}
                {!hideEditButton && (
                    <Button
                        size="small"
                        color="primary"
                        disabled={disabled}
                        onClick={this.handleOpenFormulaModal}
                        data-testid="workflow_actions.form.edit"
                    >
                        {t('workflow_actions.form.edit')}
                    </Button>
                )}
            </InputAdornment>
        );
    };

    render() {
        const {
            value,
            callContext,
            noTemplateWarning,
            hideEditButton,
            rawFormula,
            picklist,
            multiPicklist,
            label,
            hint,
            ...rest
        } = this.props;

        return (
            <>
                <TextField
                    onClick={this.handleOpenFormulaModal}
                    value={value}
                    InputProps={{
                        readOnly: true,
                        endAdornment: this.renderEndAdornment(),
                    }}
                    label={label}
                    {...rest}
                />
                <FormulaEditor
                    open={this.state.showFormulaModal}
                    onSave={this.handleFormulaModal}
                    onCancel={this.handleCloseFormulaModal}
                    value={value}
                    callContext={callContext}
                    rawFormula={rawFormula}
                    picklist={picklist}
                    multiPicklist={multiPicklist}
                />

                <PureFormDialog
                    open={this.state.showWarningModal}
                    title={t('formula_editor.warning.title')}
                    onClose={this.closeWarningModal}
                    maxWidth="sm"
                    fullWidth
                >
                    <Typography>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('formula_editor.warning.message', {
                                    interpolation: { prefix: '{{{', suffix: '}}}' },
                                }),
                            }}
                        />
                    </Typography>

                    <FormActions closeText={t('formula_editor.warning.cancel')}>
                        <Button color="primary" onClick={this.handleWarningModal}>
                            {t('formula_editor.warning.submit')}
                        </Button>
                    </FormActions>
                </PureFormDialog>
            </>
        );
    }
}

FormulaInput.propTypes = {
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    noTemplateWarning: PropTypes.bool.isRequired,
    rawFormula: PropTypes.bool,
    picklist: PropTypes.array,
    multiPicklist: PropTypes.bool,
    hideEditButton: PropTypes.bool,
    disabled: PropTypes.bool,
};

FormulaInput.defaultProps = {
    noTemplateWarning: false,
    multiPicklist: false,
    disabled: false,
};

export default FormulaInput;
