import React from 'react';
import './style.css';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SubscriptionPanel from './SubscriptionPanel';
import Icon from '@material-ui/core/Icon';
import { USER_ROLE } from '../../service/UserManager';
import { withTranslation } from 'react-i18next';
import Alert from '../Alert';
import BillingPanel from './BillingPanel';
import SuperAdminPanel from './SuperAdminPanel';
import { accountsManager } from '../../service/AccountsManager';
import { limitationsManager } from '../../service/LimitationsManager';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import { sharedMapManager } from '../../service/SharedMapManager';
import { withSnackbar } from 'notistack';
import Backdrop from '../Backdrop';

class BillingContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: null,
            sharedMapsSettings: null,
            subscription: null,
            subscriptionFormEditOpen: false,
            accountLimitations: null,
            loading: true,
        };
    }

    /**
     * todo add WS event fired when account limitations was changed
     */
    componentDidMount() {
        this.loadAccountAndSubscription();

        dispatcher.subscribe([events.WS_USERS_DELETED, events.EVENT_USER_CREATED], this, () => {
            this.loadAccountAndSubscription();
        });

        limitationsManager.load(this.props.accountId).then((accountLimitations) => {
            this.setState({ accountLimitations });
        });

        this.loadSharedMapsSettings();

        dispatcher.subscribe(events.EVENT_SUBSCRIPTION_CHANGED, this, (data) => {
            const subscription = data.subscription;
            if (this.props.accountId !== null && subscription.accountId === this.props.accountId) {
                this.setState({
                    subscription,
                });
            }
            this.loadSharedMapsSettings();
        });
        dispatcher.subscribe(events.EVENT_ACCOUNT_LIMITATIONS_CHANGED, this, ({ limitations }) => {
            if (this.props.accountId !== null && limitations.accountId === this.props.accountId) {
                this.setState({ accountLimitations: limitations });
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.EVENT_SUBSCRIPTION_CHANGED, this);
    }

    loadSharedMapsSettings = () => {
        sharedMapManager.getSettings(this.props.accountId).then((sharedMapsSettings) => {
            this.setState({
                sharedMapsSettings,
            });
        });
    };

    loadAccountAndSubscription = () => {
        accountsManager.load(this.props.accountId).then((account) => {
            this.setState({
                account,
                subscription: account.subscription,
                loading: false,
            });
        });
    };

    render() {
        const { t } = this.props;
        const isSuperAdmin = this.props.user.role.code === USER_ROLE.SUPERADMIN;
        const isSwitchedSuperAdmin = this.props.user.switchedRole === USER_ROLE.SUPERADMIN;
        const sharedMapsEnabled = this.state.sharedMapsSettings?.enabled ?? false;

        return (
            <Backdrop loading={this.state.loading}>
                {isSuperAdmin && (
                    <div>
                        <Alert type="warning" placement="popover">
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Icon className="icon-warning">warning</Icon>
                                </Grid>
                                <Grid item style={{ marginLeft: 10 }}>
                                    <h3>{t('billing.warning_about_ghosting')}</h3>
                                </Grid>
                            </Grid>
                        </Alert>
                    </div>
                )}
                {this.state.account && (
                    <div>
                        <div className="c-billing">
                            <SubscriptionPanel
                                subscription={this.state.subscription}
                                user={this.props.user}
                                account={this.state.account}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                subscribeAutoStart={this.props.subscribeAutoStart}
                            />
                            <BillingPanel
                                subscription={this.state.subscription}
                                user={this.props.user}
                                account={this.state.account}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                updateAutoStart={this.props.updateAutoStart}
                            />
                        </div>
                        {(isSuperAdmin || isSwitchedSuperAdmin) && (
                            <div className="c-admin-panel">
                                <SuperAdminPanel
                                    user={this.props.user}
                                    subscription={this.state.subscription}
                                    accountLimitations={this.state.accountLimitations}
                                    enqueueSnackbar={this.props.enqueueSnackbar}
                                    sharedMapsEnabled={sharedMapsEnabled}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Backdrop>
        );
    }
}

BillingContainer.propTypes = {
    accountId: PropTypes.number,
    user: PropTypes.object.isRequired,
    subscribeAutoStart: PropTypes.bool,
    updateAutoStart: PropTypes.bool,
};

BillingContainer.defaultProps = {
    subscribeAutoStart: false,
    updateAutoStart: false,
};

export default withTranslation()(withSnackbar(BillingContainer));
