import React from 'react';
import { fabric } from 'fabric';

interface CurrentProps {
    onReady: (canvas: fabric.Canvas) => void;
    isDrawingMode: boolean;
    backgroundColor?: string;
    width?: number;
    height?: number;
    brushWidth?: number;
    style?: any;
}

class Canvas extends React.PureComponent<CurrentProps> {
    private canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    private canvas: fabric.Canvas | null = null;

    componentDidMount() {
        this.canvas = new fabric.Canvas(this.canvasRef.current, this.props);
        this.canvas.freeDrawingBrush.width = this.props.brushWidth ?? 5;
        this.canvas.renderAll();

        this.props.onReady(this.canvas);
    }

    componentWillUnmount() {
        this.canvas?.dispose();
    }

    render() {
        return <canvas ref={this.canvasRef} style={this.props.style} />;
    }
}

export default Canvas;
