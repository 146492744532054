import { format } from 'date-fns';
import { Routing } from 'interfaces';
import dateHelper from 'service/Date/DateHelper';

export class DepartingDateTimeData {
    private _date: Date | null;
    isToday: boolean;
    isNow: boolean;
    isTimeChanged: boolean;

    constructor(departingDateTime?: Routing.Appointment.AppointmentCalendarDepartingDateTime) {
        if (departingDateTime) {
            this._date = departingDateTime.date;
            this.isToday = departingDateTime.isToday;
            this.isNow = departingDateTime.isNow;
            this.isTimeChanged = departingDateTime.isTimeChanged;
        } else {
            this._date = null;
            this.isToday = true;
            this.isNow = true;
            this.isTimeChanged = false;
        }
    }

    set date(value: Date) {
        this._date = value;
    }

    // WARNING: using departingCalendarData.date in dependencies of useEffect will cause infinite loop because this getter returns new Date() every time
    // use departingCalendarData as dependency instead
    get date(): Date {
        let date = this._date;
        if (date === null) {
            date = dateHelper.createFromDeviceDate(new Date()).getDate();
        }

        if (!this.isToday) {
            return date;
        }

        const oldDate = new Date(date.getTime());
        const today = dateHelper.createFromDeviceDate(new Date()).getDate();
        today.setHours(oldDate.getHours(), oldDate.getMinutes(), 0, 0);

        return today;
    }

    get dateUTCString(): string {
        return this.date.toUTCString();
    }

    isDateInThePast(): boolean {
        const todayString =
            format(dateHelper.createFromDeviceDate(new Date()).getDisplayDate(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
        const todayTimestamp = new Date(todayString).getTime();
        return this.date.getTime() < todayTimestamp;
    }
}
