import React from 'react';
import Tagify from '@yaireo/tagify/dist/tagify.min';
import '@yaireo/tagify/dist/tagify.css';
import './style.css';
import {
    Icon,
    IconButton,
    CircularProgress,
    ClickAwayListener,
    Paper,
    Popper,
    TextField,
    withStyles,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

export const INTERPOLATOR_LEFT = '[[';
export const INTERPOLATOR_RIGHT = ']]';

class TaggedTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listAnchorEl: null,
        };

        this.tagify = null;
        this.inputRef = React.createRef();

        this.filterOptions = createFilterOptions({
            stringify: (option) => {
                let keywords = [option.title];
                if (Array.isArray(option.keywords)) {
                    keywords = keywords.concat(option.keywords);
                }
                return keywords.join(' ');
            },
        });
    }

    componentDidMount() {
        this.initTagify();
    }

    componentWillUnmount() {
        this.destroyTagify();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.destroyTagify();
            this.initTagify();
        }
    }

    initTagify = () => {
        const { onUpdate } = this.props;
        const settings = {
            mode: 'mix',
            tagTextProp: 'title',
            mixTagsInterpolator: [INTERPOLATOR_LEFT, INTERPOLATOR_RIGHT],
            whitelist: this.props.options,
            trim: false,
            duplicates: true,
            enforceWhitelist: true,
            editTags: false,
            transformTag: this.transformTag,
            dropdown: {
                enabled: false,
            },
            callbacks: {
                change: this.handleChange,
                input: this.handleInput,
            },
        };

        this.tagify = new Tagify(this.inputRef.current, settings);
        onUpdate({ tags: this.tagify.value });
    };

    destroyTagify = () => {
        if (this.tagify) {
            this.tagify.destroy();
        }
    };

    addTag = (value) => {
        if (this.tagify) {
            this.tagify.addTags([value], false);
        }
    };

    handleOpenList = (e) => {
        this.setState({
            listAnchorEl: e.currentTarget,
        });
    };

    handleCloseList = () => {
        this.setState({
            listAnchorEl: null,
        });
    };

    handleInput = (e) => {
        e.detail.tagify.triggerChangeEvent();
    };

    transformTag = (tag) => {
        const { options } = this.props;
        const option = options.find((option) => option.value === tag.value);
        if (option) {
            for (const [key, value] of Object.entries(option)) {
                tag[key] = value;
            }
        }
    };

    handleChange = (event) => {
        const { onUpdate } = this.props;
        onUpdate({ value: event.detail.value, tags: event.detail.tagify.value });
    };

    handleSelectListItem = (event, value) => {
        this.addTag(value);
        this.handleCloseList();
    };

    renderOption = (option) => {
        return <React.Fragment>{option.renderTitle()}</React.Fragment>;
    };

    render() {
        const { listAnchorEl } = this.state;
        const { t, classes, options, value, loading, onUpdate, tReady, i18n, ...rest } = this.props;
        const open = Boolean(listAnchorEl);
        const textValue = value || '';

        return (
            <React.Fragment>
                <div className={clsx(classes.root, loading ? classes.rootLoading : '')}>
                    <div style={{ flex: 1 }} className={classes.fieldContainer}>
                        <TextField
                            disabled={loading}
                            InputProps={{
                                endAdornment: loading ? <CircularProgress size={14} /> : null,
                            }}
                            fullWidth
                            inputRef={this.inputRef}
                            value={textValue}
                            className="c-tagged-text-field"
                            {...rest}
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <IconButton
                            color="primary"
                            onClick={this.handleOpenList}
                            disabled={loading}
                            data-testid="tagged_text_field.insert_tag.btn"
                        >
                            <Icon className="fas fa-tag" fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                {
                    <Popper
                        open={open}
                        anchorEl={listAnchorEl}
                        placement="bottom-start"
                        className={classes.popper}
                        disablePortal
                    >
                        <ClickAwayListener onClickAway={this.handleCloseList}>
                            <Paper className="c-tagged-text-field--paper-autocomplete">
                                <Autocomplete
                                    open
                                    disableClearable={true}
                                    options={options}
                                    getOptionLabel={(option) => option.title}
                                    groupBy={(option) => option.group}
                                    renderOption={this.renderOption}
                                    disablePortal
                                    popupIcon={null}
                                    filterOptions={this.filterOptions}
                                    classes={{
                                        paper: classes.paper,
                                        option: classes.option,
                                        popperDisablePortal: classes.popperDisablePortal,
                                    }}
                                    onChange={this.handleSelectListItem}
                                    renderInput={(params) => (
                                        <div className={classes.inputBaseContainer}>
                                            <TextField
                                                {...params}
                                                className={classes.inputBase}
                                                label={t('tagged_text_field.search_tag')}
                                                data-testid="tagged_text_field.search_tag"
                                                autoFocus
                                            />
                                        </div>
                                    )}
                                    getOptionSelected={() => false}
                                />
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                }
            </React.Fragment>
        );
    }
}

TaggedTextField.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired, // don't mutate this. if you need to change options pass new array of options
    onUpdate: PropTypes.func,
    loading: PropTypes.bool,
    label: PropTypes.element,
    InputLabelProps: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

TaggedTextField.defaultProps = {
    loading: false,
};

const styles = {
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    rootLoading: {
        pointerEvents: 'none',
    },
    fieldContainer: {
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'end',
    },
    inputBaseContainer: {
        padding: 10,
    },
    inputBase: {
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 199,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
    },
    popperDisablePortal: {
        position: 'relative',
        width: '100% !important',
    },
};

class Option {
    value;
    title;
    keywords;
    group;
    meta;
    renderTitleFunc;

    /**
     * @param {*} value
     * @param {string} title
     * @param {string[]} keywords
     * @param {(string|null)} group
     * @param {Object} meta
     */
    constructor(value, title, keywords = [], group = null, meta = {}) {
        this.value = value;
        this.title = title;
        this.keywords = keywords;
        this.group = group;
        this.meta = meta;
    }

    /**
     * @param {(function|null)} func
     */
    setRenderTitle(func) {
        this.renderTitleFunc = func;
    }

    /**
     * @return {string} title
     */
    renderTitle() {
        if (this.renderTitleFunc) {
            return this.renderTitleFunc();
        }
        return this.title;
    }
}

export default withTranslation()(withStyles(styles)(TaggedTextField));
export { Option };
