import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import LiveSearchTableViewForm from '../../components/LiveUpdateTableForm/LiveSearchTableViewForm';
import { IField } from '../../components/types';
import PureFormDialog from '../../components/PureFormDialog';
import { FormAlert } from '../../components/PureFormDialog/Form';
import { trimLookupIdLabel } from '../../utils';

import './style.css';

type ValueType = {
    apiName: string;
    textValue?: string;
};

export interface LiveSearchTableViewDialogProps<T extends Record<string, any> = Record<string, any> & { id: string }>
    extends WithTranslation {
    onClose: () => void;
    onSaveRequest: (record: T) => void;
    onSaveMultiLookupRequest?: (toAdd: T[], toDelete: T[]) => void;
    allowEmptySubmit?: boolean;
    accountId: number;
    dataSourceId: number;
    recordId?: string;
    field: Pick<IField, 'lookupData' | 'originalApiName' | 'label' | 'apiName' | 'type'>;
    value?: ValueType;
}

interface LiveSearchTableViewDialogState {
    searchError: string | null;
}

class LiveSearchTableViewDialog<T extends { id: string } = { id: string }> extends React.PureComponent<
    LiveSearchTableViewDialogProps<T>,
    LiveSearchTableViewDialogState
> {
    constructor(props: LiveSearchTableViewDialogProps) {
        super(props);

        this.state = {
            searchError: null,
        };
    }

    render() {
        const {
            t,
            field,
            value,
            accountId,
            allowEmptySubmit,
            dataSourceId,
            recordId,
            onClose,
            onSaveRequest,
            onSaveMultiLookupRequest,
        } = this.props;
        const { searchError } = this.state;

        const title = t('live_update_table_form.search.title', { label: trimLookupIdLabel(field) });

        return (
            <PureFormDialog open title={title} onClose={onClose} className="c-live-search-form">
                {searchError !== null && (
                    <FormAlert type="danger" onClose={this.handleSearchErrorClose}>
                        {searchError}
                    </FormAlert>
                )}
                <LiveSearchTableViewForm
                    onSubmitError={this.handleSearchError}
                    accountId={accountId}
                    allowEmptySubmit={allowEmptySubmit}
                    dataSourceId={dataSourceId}
                    recordId={recordId!}
                    onSaveRequest={onSaveRequest}
                    onSaveMultiLookupRequest={onSaveMultiLookupRequest ?? noop}
                    field={field}
                    value={value}
                />
            </PureFormDialog>
        );
    }

    handleSearchError = (error: string) => {
        this.setState({ searchError: error });
    };

    handleSearchErrorClose = () => {
        this.setState({ searchError: null });
    };
}

export default withTranslation()(LiveSearchTableViewDialog);
