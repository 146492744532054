import { ComponentType, isValidElement, ReactElement, ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import moment, { CalendarSpec, MomentInput } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import escape from 'lodash/escape';
import BigNumber from 'bignumber.js';
import { Capacitor } from '@capacitor/core';
import { routes } from './routes';
import { Bounds, FieldLookupType, FieldType, IField, PicklistValue } from 'components/types';
import { MapNavigationOption, UserData } from 'service/types';
import InAppBrowserManager from './service/InAppBrowserManager';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfDistance from '@turf/distance';
import turfMidpoint from '@turf/midpoint';
import turfContains from '@turf/boolean-contains';
import { point as turfPoint } from '@turf/helpers';
import addMinutes from 'date-fns/addMinutes';
import { LEVEL_COUNTRY, LEVEL_ZIP, ORDERED_LEVELS } from './components/utils/CompositeAddress';
import { Entity, Geo, Routing } from './interfaces';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { User } from 'interfaces';
import dateHelper from './service/Date/DateHelper';
import { Record } from './service/RecordManager';
import { GEO_FIELDS } from './references/geoFields';
import { getGeoTrueAddress } from './components/utils/GeoTrueAddress';
import { createTheme } from '@material-ui/core';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import { addMilliseconds } from 'date-fns';

let DEBUG_MODE = false;
try {
    DEBUG_MODE = window.localStorage.getItem('debug_mode') === '1';
} catch (e) {
    // pass
}
export { DEBUG_MODE };

const tokenizeRegexp = /[\s@\p{P}]+/gu;
const trimLookupIdRegexp = /(.*)\s\(ID\)$/;
const trimLookupNameRegexp = /(.*)\s\(NAME\)$/;

const logDebug = function (...args: any[]) {
    if (!DEBUG_MODE) {
        return;
    }
    console.log(args);
};

const isModalOpen = () => {
    // todo rewrite using explicit flag, because this is called inside mouse move handler
    return (
        null !== document.querySelector('.MuiModal-root-3') ||
        null !== document.querySelector('.MuiPopover-root') ||
        null !== document.querySelector('.MuiDialog-root')
    );
};

export const splitOnce = (subject: string, delimiter: string): string[] => {
    const indexOf = subject.indexOf(delimiter);

    return indexOf === -1 ? [subject] : [subject.substring(0, indexOf), subject.substring(indexOf + delimiter.length)];
};

const trimLookupNameLabel = (field: IField) => {
    let label = field.label;
    if (
        field.originalApiName &&
        field.apiName &&
        field.originalApiName !== field.apiName &&
        field.apiName.endsWith('_NAME')
    ) {
        const m = label.match(trimLookupNameRegexp);
        if (m) {
            label = m[1];
        }
    }
    return label;
};

export const propertyNameToId = (name: string) => name.replace(/(.*)_NAME$/, '$1_ID');
export const propertyIdToName = (name: string) => name.replace(/(.*)_ID$/, '$1_NAME');
export const propertyIdOrNameToType = (name: string) => name.replace(/(.*)(_ID|_NAME)$/, '$1_TYPE');

const trimLookupIdLabel = (field: Pick<IField, 'originalApiName' | 'label' | 'apiName'>) => {
    let label = field.label;
    if (
        field.originalApiName &&
        field.apiName &&
        field.originalApiName !== field.apiName &&
        field.apiName.endsWith('_ID')
    ) {
        const m = label.match(trimLookupIdRegexp);
        if (m) {
            label = m[1];
        }
    }
    return label;
};

export const DATETIME_FORMAT_NO_TIME = 'YYYY-MM-DD';
export const DATETIME_FORMAT_NO_TIME_DATEFNS = 'yyyy-MM-dd';

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT_DATEFNS = 'yyyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_DATEFNS_NO_SECONDS = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT_DATEFNS = 'yyyy-MM-dd';
export const DATETIME_FORMAT_FOR_EXPORT = 'YYYY-MM-DD\\THH:mm:ss';
export const DATETIME_FORMAT_FOR_EXPORT_DATEFNS = 'yyyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_NO_SECONDS = 'YYYY-MM-DD HH:mm';
export const DATETIME_PICKER_FORMAT = 'MM/DD/YYYY HH:mm';
/**
 * @see https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export const UT_DATETIME_PICKER_FORMAT = 'MM/dd/yyyy HH:mm';

const formatDateTimeForPicker = (dateTime?: MomentInput, user?: UserData) => {
    const value = user
        ? moment.utc(dateTime).utcOffset(moment.tz(dateTime, user.actualTimezone).utcOffset())
        : moment(dateTime);
    return value.format(DATETIME_PICKER_FORMAT);
};

const formatDateForPicker = (dateTime?: MomentInput) => {
    return moment(dateTime).format(DATETIME_FORMAT_NO_TIME);
};

const formatDateToDefault = (dateTime: MomentInput) => {
    return moment(dateTime).format(DATETIME_FORMAT);
};

const formatSeconds = (seconds: number, format: string = 'HH:mm:ss'): string => {
    return moment.utc(seconds * 1000).format(format);
};

const utcToUserTimezone = (dateTime: MomentInput, user: UserData, format = DATETIME_FORMAT) => {
    const timeZone = user.actualTimezone;

    return moment.tz(moment.tz(dateTime, 'UTC'), timeZone).format(format);
};

const userTimezoneToUtc = (dateTime: MomentInput, user: Pick<UserData, 'actualTimezone'>, format = DATETIME_FORMAT) => {
    const timeZone = user.actualTimezone;

    return moment.tz(dateTime, timeZone).utc().format(format);
};

const currentUserDate = (user: UserData) => {
    // todo добавляет сдвиг ко времени браузера, а не к ютс
    return moment.tz(user.actualTimezone).utc();
};

const nowIsBefore = (dateTime: MomentInput, user: UserData) => {
    const currentDateTime = currentUserDate(user).format(DATETIME_FORMAT);
    dateTime = utcToUserTimezone(dateTime, user);
    return moment(currentDateTime).isBefore(dateTime);
};

const calendar = (
    dateTime: MomentInput,
    user: Pick<UserData, 'actualTimezone'>,
    time: MomentInput,
    formats: CalendarSpec,
) => {
    return moment.tz(dateTime, user.actualTimezone).utc().calendar(time, formats);
};

const utcToUserTimezoneNoSeconds = (dateTime: string, user: Pick<UserData, 'actualTimezone'>) => {
    return moment.tz(dateTime, DATETIME_FORMAT, user.actualTimezone).utc().format(DATETIME_FORMAT_NO_SECONDS);
};

/**
 * Formats date to string in given timezone.
 *
 * @param date input Date
 * @param timeZone TimeZone to convert to. Example: 'Europe/Berlin'. Browser TZ by default.
 * @returns string in format 'YYYY-DD-MM HH:MM:SS'
 */
export function formatToDateTimeString(date: number | Date, timeZone?: string) {
    const browserTimezoneToUtcFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
        timeZone,
    });

    const formatted = browserTimezoneToUtcFormatter.format(date);

    return formatted.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$3-$1-$2 $4:$5:$6');
}

const formatDuration = (time: number, t: TFunction) => {
    const days = Math.floor(time / 86400);
    time -= days * 86400;
    const hours = Math.floor(time / 3600);
    time -= hours * 3600;
    let minutes = Math.floor(time / 60);
    time -= minutes * 60;

    if (time) {
        minutes++;
    }

    let result = [];
    if (days) {
        result.push(days + t('route_report.summary.day'));
    }
    if (hours) {
        result.push(hours + t('route_report.summary.hour'));
    }
    if (minutes) {
        result.push(minutes + t('route_report.summary.min'));
    }

    return result.join(' ') || '0';
};

export const formatDistance = (distance: number): string => {
    return distance.toFixed(1);
};

/**
 * Format: Nov 29, Apr 15
 */
//TODO: Rename this method and remove other date formats when they are no longer needed
export const newFormatRouteDate = (date: Date, t: TFunction): string => {
    const dayString = ('0' + date.getDate()).slice(-2);
    const monthString = t('date.month.' + date.getMonth() + '.short');
    return capitalizeFirstLetterAndLowerOther(monthString) + ' ' + dayString;
};

/**
 * Format: 29-JUNE, 15-APR
 */
export const formatRouteDate = (date: Date, t: TFunction): string => {
    const dayString = ('0' + date.getDate()).slice(-2);
    const monthString = t('date.month.' + date.getMonth() + '.short');
    return dayString + '-' + monthString;
};

/**
 * Format: 29-JUNE-23, 15-APR-23
 */
export const formatRouteDateWithYear = (date: Date, t: TFunction, fullYear = false): string => {
    const dayString = ('0' + date.getDate()).slice(-2);
    const monthString = t('date.month.' + date.getMonth() + '.short');
    return (
        dayString + '-' + monthString + '-' + (fullYear ? date.getFullYear() : date.getFullYear().toString().slice(-2))
    );
};

/**
 * Format: 29-JUNE-23 — 15-APR-23
 */
export const formatRangeWithYearFromIso = (rangeStart: string, rangeEnd: string, t: TFunction): string => {
    const periodFromDisplayDate = dateHelper.createFromISOString(rangeStart).getDisplayDate();
    const periodToDisplayDate = dateHelper.createFromISOString(rangeEnd).getDisplayDate();

    return formatRouteDateWithYear(periodFromDisplayDate, t) + ' — ' + formatRouteDateWithYear(periodToDisplayDate, t);
};

export const countWeekDaysInDateRange = (
    rangeStart: Date,
    rangeEnd: Date,
): Map<string, { count: number; date: Date }> => {
    const weekDayCount = new Map<string, { count: number; date: Date }>();
    const rangeStartMoment = moment(rangeStart);
    const rangeEndMoment = moment(rangeEnd);
    while (rangeStartMoment.isSameOrBefore(rangeEndMoment, 'day')) {
        const weekDayName = rangeStartMoment.toDate().toLocaleDateString('en-EN', { weekday: 'long' }).toLowerCase();
        let weekDay = weekDayCount.get(weekDayName);
        if (!weekDay) {
            weekDay = { count: 0, date: rangeStartMoment.toDate() };
        }
        weekDay.count += 1;
        weekDay.date = rangeStartMoment.toDate();
        weekDayCount.set(weekDayName, weekDay);

        rangeStartMoment.add(1, 'days');
    }

    return weekDayCount;
};

export const roundDistance = (number: number) => Math.round(number * 10) / 10;

const roundToDecimal = (number: number, digitsAfterComma: number) =>
    Math.round(number * 10 ** digitsAfterComma) / 10 ** digitsAfterComma;

const addStyle = (str: string) => {
    const node = document.createElement('style');
    node.innerHTML = str;
    document.body.appendChild(node);
    return node;
};

const removeStyle = (node: Node) => {
    document.body.removeChild(node);
};

const formatWithCommas = (x: number) => {
    return x.toLocaleString('en-US');
};

const formatDateForExport = (dateTime: Date) => {
    return dateTime.toISOString().split('T').shift();
};

const formatDateTimeForExport = (dateTime: MomentInput) => {
    return moment(dateTime).format(DATETIME_FORMAT);
};

const userToUserTimezone = (date: Date, fromUser: User.User, toUser: User.User) => {
    const fromUserTimezoneOffset = getTimezoneOffset(fromUser.actualTimezone, date);
    const toUserTimezoneOffset = getTimezoneOffset(toUser.actualTimezone, date);
    const offset = toUserTimezoneOffset - fromUserTimezoneOffset;
    return addMilliseconds(date, offset);
};

const capitalizeFirstLetterAndLowerOther = (str: string) => {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const capitalizeFirstLetter = (str: string) => {
    return str[0].toUpperCase() + str.slice(1);
};

const sortFunc = (key: string) => {
    return (a: { [key: string]: string }, b: { [key: string]: string }) => {
        if (typeof a[key] !== 'string' || typeof b[key] !== 'string') {
            return 0;
        }
        return a[key].toLocaleLowerCase().localeCompare(b[key].toLocaleLowerCase());
    };
};

const isEqual = (x: number, y: number) => Math.abs(x - y) <= 0.00001;
const isEmpty = (value: any) => value === null || value === undefined || value.length === 0;

const isActiveEntity = (entity: Entity.Entity): boolean => {
    return !entity.dataSource.deletedAt && !entity.deletedAt && entity.isIncluded;
};

const isOwnerableField = (field: IField): boolean => {
    return field.isSuitableForOwner;
};

const isSimpleTypeField = (field: IField) => {
    return (
        !field.isDeleted &&
        !field.isLink &&
        !field.isVirtual &&
        (field.type === 'string' ||
            field.type === 'integer' ||
            field.type === 'bigint' ||
            field.type === 'text' ||
            field.type === 'float')
    );
};

const isUpdatableField = (field: IField): boolean => {
    if (!(field.isIncluded && !field.isDeleted && !field.isLink && !field.isVirtual && !field.isCustom)) {
        return false;
    }
    return !isReadonlyField(field);
};

const isReadonlyField = (field: IField): boolean => {
    if (
        field.lookupData !== null &&
        !field.lookupData.linking_lookup_data &&
        field.lookupData.type !== FieldLookupType.FEW
    ) {
        const match = field.apiName.match(/_(ID)$/);
        return match === null || match[1] !== 'ID';
    }
    return field.isReadOnly;
};

const getActiveOrInactiveFieldByApiName = (entity: Entity.Entity, fieldApiName: string): IField | null => {
    let inactiveField = null;
    for (const field of entity.fields) {
        if (field.apiName !== fieldApiName) {
            continue;
        }
        if (!field.isDeleted && field.isIncluded) {
            return field;
        }
        inactiveField = field;
    }
    return inactiveField;
};

export const isSimpleField = (field: IField): boolean => {
    return field.lookupData === null;
};

export const isMultipleLookupField = (field: IField): boolean => {
    return field.lookupData !== null && !!field.lookupData.linking_lookup_data;
};

export const isFewLookup = (field: IField): boolean => {
    return field.lookupData !== null && field.lookupData.type === FieldLookupType.FEW;
};

export const isLookupFieldId = (field: IField): boolean => {
    return field.lookupData !== null && field.apiName.endsWith('_ID');
};

export const isLookupFieldName = (field: IField): boolean => {
    return field.lookupData !== null && field.apiName.endsWith('_NAME');
};

export const isLookupFieldType = (field: IField): boolean => {
    return field.lookupData !== null && field.apiName.endsWith('_TYPE');
};

const isLookupField = (field: IField) => {
    return (
        field.lookupData &&
        field.lookupData.apiName &&
        field.isIncluded &&
        !field.isDeleted &&
        !field.isLink &&
        !field.isVirtual &&
        !field.isReadOnly
    );
};

const isAddressElementField = (field: IField) => {
    return (
        isSimpleTypeField(field) &&
        !field.isSystemVisible &&
        !field.isReadOnly &&
        field.lookupData === null &&
        field.picklist === null
    );
};

const isNotMappedAsAddress = (mappedApiNames: string[] = []) => {
    return (field: IField) => !mappedApiNames.includes(field.apiName) && !field.isPin;
};

const isGeopointFriendlyTypeField = (field: IField) => {
    if (field.apiName === 'id') {
        return false;
    }

    const isValidType = field.type === 'string' || field.type === 'text' || field.type === 'float';
    return (
        isValidType &&
        isSimpleTypeField(field) &&
        !field.isSystemVisible &&
        !field.isReadOnly &&
        field.lookupData === null &&
        field.picklist === null
    );
};

const INT_MAX = Number('2147483647');
const INT_MIN = Number('-2147483648');
const BIGINT_MAX = new BigNumber('9223372036854775807');
const BIGINT_MIN = new BigNumber('-9223372036854775808');

const doesValueMatchType = (value: string | number, type: FieldType) => {
    switch (type) {
        case FieldType.INTEGER:
            if (typeof value === 'number' && Number.isInteger(value)) {
                return true;
            }
            return (
                typeof value === 'string' &&
                value.match(/^-?\d+$/) !== null &&
                Number(value) <= INT_MAX &&
                Number(value) >= INT_MIN
            );
        case FieldType.BIGINT:
            if (typeof value === 'number' && new BigNumber(value).isInteger()) {
                return true;
            }
            return (
                typeof value === 'string' &&
                value.match(/^-?\d+$/) !== null &&
                new BigNumber(value).lte(BIGINT_MAX) &&
                new BigNumber(value).gte(BIGINT_MIN)
            );
        case FieldType.FLOAT:
            if (typeof value === 'number') {
                return true;
            }
            return value.match(/^-?\d+(\.\d+)?$/) !== null;
        default:
            return true;
    }
};

const filterInputCharactersStringToMatchType = (string: string, type: FieldType, oldString: string) => {
    let match;
    switch (type) {
        case FieldType.INTEGER:
        case FieldType.BIGINT:
            match = string.match(/^-?(\d+)?$/);
            if (null === match && oldString) {
                match = oldString.match(/^-?(\d+)?$/);
            }
            if (null === match) {
                return '';
            }
            return match[0];
        case FieldType.FLOAT:
            match = string.match(/^-?(\d+(\.\d+|\.)?)?$/);
            if (null === match && oldString) {
                match = oldString.match(/^-?(\d+(\.\d+|\.)?)?$/);
            }
            if (null === match) {
                return '';
            }
            return match[0];
        default:
            return string;
    }
};

const filterStringValueToMatchType = (value: number | string, type: FieldType) => {
    let match, parsedValue;
    switch (type) {
        case FieldType.INTEGER:
            parsedValue = value;

            if (typeof value !== 'number') {
                match = value.match(/^-?\d+$/);
                if (null === match) {
                    return null;
                }
                parsedValue = Number(match[0]);
            }

            if (parsedValue > INT_MAX || parsedValue < INT_MIN) {
                return '';
            }
            return parsedValue.toString();
        case FieldType.BIGINT:
            if (typeof value === 'number') {
                return value;
            }
            match = value.match(/^-?\d+$/);
            if (null === match) {
                return null;
            }
            parsedValue = new BigNumber(match[0]);
            if (parsedValue.gt(BIGINT_MAX) || parsedValue.lt(BIGINT_MIN)) {
                return '';
            }
            return parsedValue.toFixed();
        case FieldType.FLOAT:
            if (typeof value === 'number') {
                return value;
            }
            match = value.match(/^-?\d+(\.\d+)?$/);
            if (null === match) {
                return null;
            }
            return match[0];
        default:
            return value;
    }
};

const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const replaceAll = (str: string, match: string, replacement: string) => {
    return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
};

const safeString = (value: string) => {
    const ampersandReplacement = uuidv4();
    /** @ts-ignore */
    let newValue = replaceAll(value, '&', ampersandReplacement);
    newValue = escape(newValue);
    /** @ts-ignore */
    return replaceAll(newValue, ampersandReplacement, '&');
};

const weAreInIframe = () => {
    return window.location !== window.parent.location;
};

const weAreInNativeApp = (): boolean => {
    return Capacitor.isNativePlatform();
};

const weAreInIosNativeApp = () => {
    return weAreInNativeApp() && Capacitor.getPlatform() === 'ios';
};

const weAreInAndroidNativeApp = () => {
    return weAreInNativeApp() && Capacitor.getPlatform() === 'android';
};

const nativeAppFriendlyRedirect = (url: string) => {
    if (!weAreInNativeApp()) {
        window.location.href = url;
        return;
    }
    InAppBrowserManager.openBrowser(url);
};

const isLandscapeOrientation = () => {
    return ['landscape-primary', 'landscape-secondary'].includes(window.screen.orientation.type);
};

/**
 * distance in meters using Haversine formula
 * difference with leaflet distance() should not be more 0.01 meter
 */
export const distance = (from: Geo.GeoPoint, to: Geo.GeoPoint): number => {
    return 1000 * turfDistance(turfPoint([from.lng, from.lat]), turfPoint([to.lng, to.lat]));
};

export const midpoint = (p1: Geo.GeoPoint, p2: Geo.GeoPoint): Geo.GeoPoint => {
    const midpoint = turfMidpoint(turfPoint([p1.lng, p1.lat]), turfPoint([p2.lng, p2.lat]));
    return { lng: midpoint.geometry!.coordinates[0], lat: midpoint.geometry!.coordinates[1] };
};

export const pointInBounds = (p: Geo.GeoPoint, b: Bounds): boolean => {
    return turfContains(turfBboxPolygon([b.minLng, b.minLat, b.maxLng, b.maxLat]), turfPoint([p.lng, p.lat]));
};

export const sleep = async (milliseconds: number): Promise<void> => {
    return new Promise((resolve, _reject) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
};

const geoPointRegexp = /^\s*([+-]?\d+(\.\d+)?)\s*,\s*([+-]?\d+(\.\d+)?)\s*$/; // parentheses matters

const parseNotSafeGeoPoint = (from?: string): Geo.NullableGeoPoint | undefined => {
    const matches = geoPointRegexp.exec(from ?? '');
    if (matches?.length !== 5) {
        return undefined;
    }

    return { lat: Number(matches?.[1] || undefined) || null, lng: Number(matches?.[3] || undefined) || null };
};

export const validateGeoPoint = (from?: Geo.NullableGeoPoint): Geo.GeoPoint | undefined => {
    if (typeof from?.lat !== 'number' || typeof from?.lng !== 'number') {
        return undefined;
    }
    if (
        (from?.lat as any) > -90 &&
        (from?.lat as any) < 90 &&
        (from?.lng as any) >= -180 &&
        (from?.lng as any) <= 180
    ) {
        return from as Geo.GeoPoint;
    }

    return undefined;
};

export const parseGeoPoint = (from?: string): Geo.GeoPoint | undefined => validateGeoPoint(parseNotSafeGeoPoint(from));

export const isValidLatLng = (point: any): boolean =>
    typeof point === 'object' && validateGeoPoint(point as Geo.NullableGeoPoint) !== undefined;

export const parseBounds = (from?: string, separator: string = ';'): Bounds | undefined => {
    const points = (from ?? '').split(separator);
    if (points.length !== 2) {
        return undefined;
    }

    const [point1, point2] = points.map(parseGeoPoint);
    if (point1 === undefined || point2 === undefined) {
        return undefined;
    }

    return {
        minLat: Math.min(point1.lat, point2.lat),
        maxLat: Math.max(point1.lat, point2.lat),
        minLng: Math.min(point1.lng, point2.lng),
        maxLng: Math.max(point1.lng, point2.lng),
    };
};

/**
 * N.b.: this is NOT flat projection's geometric center
 */
export const centerOfBounds = (bounds: Readonly<Bounds>): Geo.GeoPoint => ({
    lat: (bounds.minLat + bounds.maxLat) / 2,
    lng: (bounds.minLng + bounds.maxLng) / 2,
});

export const centerOfBoundsArray = (bounds: Readonly<Bounds>): number[] => [
    (bounds.minLat + bounds.maxLat) / 2,
    (bounds.minLng + bounds.maxLng) / 2,
];

/**
 * N.b.: this is NOT flat projection's geometric center
 */
export const centerOfBoundsOrUndefined = (bounds?: Readonly<Bounds>): Geo.GeoPoint | undefined =>
    bounds ? centerOfBounds(bounds) : undefined;

export const encodeHTMLEntities = (s: string): string =>
    s.replace(/[\u00A0-\u9999<>&]/g, (c: string): string => '&#' + c.charCodeAt(0) + ';');

const isMapPage = (pathname: string) => pathname === routes.client || pathname.startsWith('/map/');

const nativeAppFriendlyOpenWindow = (url: string) => {
    if (!weAreInNativeApp()) {
        window.open(url, '_blank', 'noopener=yes,noreferrer=yes');
        return;
    }

    window.location.href = url;
};

export function getUrlNavigateByTypeMap(point: Geo.GeoPoint, variant: MapNavigationOption) {
    let url;
    const locationString = generateLocationStringFromGeoPoint(point);
    switch (variant) {
        case MapNavigationOption.APPLE:
            url = `https://maps.apple.com/?q=${locationString}`;
            break;
        case MapNavigationOption.WAZE:
            url = `https://www.waze.com/ul?ll=${locationString}&navigate=yes`;
            break;
        default:
            url = `https://www.google.com/maps/dir/?api=1&destination=${locationString}`;
    }

    return encodeURI(url);
}

const isSafeApiName = (value: string) => {
    if (value === '') {
        return true;
    }

    return /^[A-Za-z][A-Za-z0-9_]*$/.test(value);
};

const iOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

const isMac = () => {
    return navigator.platform.toLowerCase().indexOf('mac') >= 0;
};

const shouldWeRespectNotch = () => {
    return Capacitor.isNative && Capacitor.getPlatform() === 'ios';
};

export const isUseTrafficAvailable = (mode: Routing.TravelMode.TravelMode) =>
    mode === Routing.TravelMode.SessionTravelMode.DEFAULT || Routing.TravelMode.TravelModesTraffic.includes(mode);

export const isVehicleProfileAvailable = (mode: Routing.TravelMode.TravelMode) =>
    mode !== Routing.TravelMode.SessionTravelMode.DEFAULT && Routing.TravelMode.TravelModesTruck.includes(mode);

export const mapLegacyRouteTravelMode = (
    mode: Routing.Route.LegacyTravelMode | Routing.Settings.VehicleTypeTravelMode,
): Routing.Settings.VehicleTypeTravelMode => {
    switch (mode) {
        case Routing.Route.LegacyTravelMode.FASTEST:
        case Routing.Route.LegacyTravelMode.SHORTEST:
            return Routing.Settings.VehicleTypeTravelMode.CAR;
        case Routing.Route.LegacyTravelMode.TRUCK_FASTEST:
        case Routing.Route.LegacyTravelMode.TRUCK_SHORTEST:
            return Routing.Settings.VehicleTypeTravelMode.TRUCK;
        case Routing.Route.LegacyTravelMode.SCOOTER:
            return Routing.Settings.VehicleTypeTravelMode.SCOOTER;
        case Routing.Route.LegacyTravelMode.BICYCLE:
            return Routing.Settings.VehicleTypeTravelMode.BIKE;
        case Routing.Route.LegacyTravelMode.PEDESTRIAN:
            return Routing.Settings.VehicleTypeTravelMode.FOOT;
    }

    return mode;
};

export const mapLegacySessionTravelMode = (
    mode: Routing.Route.LegacyTravelMode | Routing.TravelMode.TravelMode,
): Routing.TravelMode.TravelMode => {
    if (mode === Routing.TravelMode.SessionTravelMode.DEFAULT) {
        return Routing.TravelMode.SessionTravelMode.DEFAULT;
    }

    return mapLegacyRouteTravelMode(mode);
};

export const getRoutePointAddress = (point: Geo.GeoPointAddress): string => {
    const pointHasAddressFields =
        point.addressFields && Object.values(point.addressFields).filter((item) => item !== null).length > 0;
    if (pointHasAddressFields) {
        const levels = ORDERED_LEVELS.filter((level) => ![LEVEL_ZIP, LEVEL_COUNTRY].includes(level));
        const address = [];
        for (const level of levels) {
            // @ts-ignore
            const addressField = point.addressFields[level];
            if (addressField) {
                address.push(addressField);
            }
        }
        if (address.length > 0) {
            return address.join(', ');
        }
    }

    const pointHasAddress = point.address && point.address.length > 0;
    if (pointHasAddress) {
        return point.address!;
    }

    return formatCoordinatesToAddress(point.lat, point.lng);
};

export const formatAddressForWaitingList = (address: string, record: Record): string => {
    let addressArray = address.split(',');

    const country = record.addressData?.country ?? record.mapsly_geo_true_country;
    if (country) {
        addressArray = addressArray.filter((addressPart: string) => !addressPart.includes(country));
    }

    const zipCode = record.Zip_Code ?? record.mapsly_geo_true_zip;
    if (zipCode) {
        addressArray = addressArray.filter((addressPart: string) => !addressPart.includes(zipCode));
    }

    addressArray = addressArray.map((addressPart: string) => addressPart.replace(/(^,\s*)|(,\s*$)/g, ''));

    return addressArray.join(', ');
};

export const getAddressFromRecord = (record: Record, pinField?: any): string => {
    let pinFieldName = pinField;
    if (record[pinField?.apiName]) {
        pinFieldName = pinField.apiName;
    } else if (record[pinField?.columnName]) {
        pinFieldName = pinField.columnName;
    }

    if (record[pinFieldName]) {
        return pinField.isLookup ? getGeoTrueAddress(record) : record[pinFieldName];
    } else if (record[GEO_FIELDS.LAT] && record[GEO_FIELDS.LNG]) {
        return formatCoordinatesToAddress(record[GEO_FIELDS.LAT], record[GEO_FIELDS.LNG]);
    }

    return '';
};

export const formatCoordinatesToAddress = (lat: string | number, lng: string | number): string => {
    return parseFloat(lat.toString()).toFixed(6) + ', ' + parseFloat(lng.toString()).toFixed(6);
};

export const isPicklistTypeNumber = (picklist: PicklistValue[]) => {
    // TODO Нужен тип integer[], такой же как text[].
    const picklistVal = picklist ? picklist[0] ?? null : null;
    return picklistVal !== null ? typeof picklistVal.value === 'number' : false;
};

let _isCanvasSupported: any = null;
const isCanvasSupported = (): boolean => {
    if (null !== _isCanvasSupported) {
        return _isCanvasSupported;
    }
    const elem = document.createElement('canvas');
    _isCanvasSupported = !!(elem.getContext && elem.getContext('2d'));
    return _isCanvasSupported;
};

export const getDeviceTimezoneOffsetInMinutes = (): number => {
    return -new Date().getTimezoneOffset(); // sign is opposite to one in user.actualTimezoneOffset
};

const getWindowHeight = () => {
    if (!document.body || !document.body.clientHeight) {
        return window.innerHeight;
    }
    return Math.min(document.body.clientHeight, window.innerHeight);
};

export const setCssCustomHeight = () => {
    // При ресайзе окна (например уменьшении высоты) элементы с установленным свойством height: calc(var(--vh, 1vh)*100)
    // сохраняют вычисленную высоту. Это не дает измениться document.body.clientHeight и переменная --vh остается такой же.
    // Для корректной обработки ресайза сбрасываю переменную чтобы в выражении calc(var(--vh, 1vh)*100) был использован fallback,
    // Это приводит к корректному определению clientHeight и единицы --vh
    document.documentElement.style.removeProperty('--vh');
    const windowHeight = getWindowHeight();
    const vh = windowHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const getWindowWidth = () => {
    if (!document.body || !document.body.clientWidth) {
        return window.innerWidth;
    }
    return Math.min(document.body.clientWidth, window.innerWidth);
};

export const setCssCustomWidth = () => {
    document.documentElement.style.removeProperty('--vw');
    const windowWidth = getWindowWidth();
    const vw = windowWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
};

export const getNowWithTzOffsetFromSettings = (user?: UserData | null) => {
    const now = new Date();
    if (!user) {
        return now;
    }

    const a = moment
        .tz(now, user.actualTimezone ?? undefined)
        .utc()
        .toDate();
    const b = moment.utc(now).toDate();
    const actualTimezoneOffset = Math.round((+b - +a) / 60_000);

    const tzOffset = actualTimezoneOffset + now.getTimezoneOffset();
    return addMinutes(now, tzOffset);
};

export const isInSafari = () => {
    //return !!window.GestureEvent;
    return navigator.vendor.match(/[Aa]+pple/g) !== null;
};

/**
 * @see https://developers.google.com/maps/documentation/urls/get-started#directions-action
 */
export const getUrlNavigateByGoogleMaps = (lat: number, lng: number): string => {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
};

export const getEntityPointKey = (entityId: number, recordId: string): string => {
    return `${entityId}_${recordId}`;
};

export const isEntityPoint = (
    point: Pick<Geo.GeoLocationPoint, 'entityId' | 'recordId'> | {},
): point is Pick<Geo.GeoLocationPoint, 'entityId' | 'recordId'> => {
    return 'entityId' in point && point.entityId !== null && point.recordId !== null;
};

/**
 * only use this for location returned by GeoLocationManager
 */
export const isValidLocation = (point: Geo.NullableGeoPoint): point is Geo.GeoPoint => {
    return point.lat !== null && point.lng !== null;
};

const memoizedZxcvbn: (
    password: string,
    userInputs?: string[],
) => Promise<{
    score: PasswordRatingScore;
}> = memoize(async (password: string, userInputs?: string[]) => {
    const zxcvbn = await import('zxcvbn');
    return zxcvbn.default(password, userInputs);
});

const prepareUserInput = (input: string): string[] => [
    input,
    input.replace(tokenizeRegexp, ''),
    ...input.split(tokenizeRegexp),
];

type PasswordRatingScore = 0 | 1 | 2 | 3 | 4;

export interface PasswordRating {
    score: PasswordRatingScore;
    is_too_weak: boolean;
}

export const passwordRating = async (password: string, userInputs?: User.UserInputs): Promise<PasswordRating> => {
    const preparedUserInputs: string[] = userInputs
        ? uniq(
              ['mapsly', ...prepareUserInput(userInputs.email), ...prepareUserInput(userInputs.name)].filter(
                  (value) => !!value,
              ),
          )
        : ['mapsly'];

    const result = await memoizedZxcvbn(password.substring(0, 100), preparedUserInputs);

    return {
        ...result,
        is_too_weak: result.score < 2,
    };
};

/**
 * safe-area-inset-top can be 0 when app launches. It is a known bug in WebKit https://bugs.webkit.org/show_bug.cgi?id=191872
 * So we use 2 sources to detect when it is changed.
 */
export function pinSafeAreas() {
    // Source1 - we insert div element and detecting its resize
    const div = document.createElement('div');
    div.style.height = 'env(safe-area-inset-top, 0)';
    document.body.appendChild(div);
    const observer = new ResizeObserver(() => {
        if (div.getBoundingClientRect().height !== 0) {
            handleMeasureAndCleanup();
        }
    });
    observer.observe(div);

    // Source2 - just wait 3 seconds
    const timeoutId = setTimeout(handleMeasureAndCleanup, 3000);

    function handleMeasureAndCleanup() {
        // first, teardown all listeners
        document.body.removeChild(div);
        observer.disconnect();
        clearTimeout(timeoutId);

        // then, apply it to root element
        document
            .querySelector<HTMLHtmlElement>(':root')!
            .style.setProperty('--ion-safe-area-top', 'env(safe-area-inset-top, 0)');

        // then measure its value
        const __sat = getComputedStyle(document.querySelector(':root')!).getPropertyValue('--ion-safe-area-top');

        // and finally, replace with static value because for some reason it can be resetted to zero when intercom opens
        document.querySelector<HTMLHtmlElement>(':root')!.style.setProperty('--ion-safe-area-top', __sat);
    }
}

export function isValidElementOfType<P>(
    child: ReactNode,
    component: ComponentType<P>,
): child is ReactElement<P, ComponentType<P>> {
    return isValidElement<P>(child) && child.type === component;
}

const defaultTheme = createTheme();
export const MAPSLY_MUI_THEME = createTheme({
    props: {
        MuiTooltip: {
            enterDelay: 700,
            enterNextDelay: 700,
        },
    },
    typography: {
        bodyXS: {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
        },
        bodyS: {
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '18px',
        },
        bodyM: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
    },
    palette: {
        background: {
            default: '#FFF',
            neutral: '#E4E4E4',
            positive: '#5ECD8033',
            negative: '#FC8D8D33',
            warning: '#FFF6E1',
            focuse: '#B5D6DE66',
            secondary: '#F7F7F7',
            tertiaryHover: '#322F3512',
            yell: '#FBB50033',
            orange: '#FF7A1A33',
        },
        border: {
            secondary: '#E4E4E4',
        },
        button: {
            midnight: '#0B113D',
            negative: '#B3261E',
            primary: { light: '#4350AF', main: '#343F92' },
            lavender: { light: '#E5E8FF', main: '#D7DBFE' },
            grey: { light: '#E4E4E4', main: '#929292', dark: '#322F35' },
            white: '#FFFFFF',
            boulder: '#79747E',
        },
        checkbox: {
            grey: { light: '#E4E4E4', main: '#B2B2B2', dark: '#322F35' },
            primary: '#4350AF',
            tertiary: '#929292',
            midnight: '#0B113D',
        },
        colors: {
            blue: '#ABDAE4',
            blue10: '#2D95CF',
            green: '#34A87E',
            red: '#FC8D8D',
            yellow: '#D28B00',
            orange: '#DF5D00',
        },
        textField: {
            secondary: { light: '#E4E4E4', main: '#B2B2B2', dark: '#322F35' },
            primary: '#4350AF',
            tertiary: '#929292',
        },
        icon: {
            text: '#322F35',
            tertiaryDisabled: '#929292',
            tertiary: '#929292',
            positive: '#34A87E',
            primary: '#4350AF',
            primaryInverse: '#FFFFFF',
            secondary: '#79747E',
        },
        shadow: {
            fixedUp2: '0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
        },
        text: {
            primary: '#322F35',
            secondary: '#79747E',
            tertiary: '#929292',
            negative: '#B3261E',
            positive: '#34A87E',
            linkDefault: '#4350AF',
        },
    },
    zIndex: {
        sideBar: 600,
        calendarPopups: 1300,
        snackbar: 9001,
    },
    overrides: {
        MuiDialog: {
            container: {
                paddingTop: 'env(safe-area-inset-top)',
            },
        },
        MuiButton: {
            root: {
                transition:
                    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 100ms cubic-bezier(0.4, 0, 0.2, 1)',
            },
        },
        MuiIconButton: {
            root: {
                padding: 6,
            },
            label: {
                overflow: 'visible',
            },
        },
        MuiCheckbox: {
            root: {
                padding: 6,
            },
        },
        MuiTypography: {
            body1: {
                fontSize: '0.875rem',
            },
        },
        MuiSwitch: {
            switchBase: {
                '&.MuiIconButton-root': {
                    padding: 8,
                },
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: -9,
            },
        },

        MuiTextField: {
            root: {
                '&.warning .MuiFormLabel-root.Mui-error': {
                    color: defaultTheme.palette.warning.main,
                },
                '&.warning .MuiFormHelperText-root.Mui-error': {
                    color: defaultTheme.palette.warning.main,
                },
                '&.warning .MuiInput-underline.Mui-error:after': {
                    borderBottomColor: defaultTheme.palette.warning.main,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                // maxWidth: '100%',
                // maxHeight: '100%',
            },
        },
        MuiIcon: {
            root: {
                // fontSize: 'inherit',
                color: 'currentColor',
                // overflow: 'visible', // MD-6566 - scrollbars appear due to "edit pencit" icon
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiTableCell: {
            root: {
                padding: 8,
            },
            head: {
                fontWeight: 'normal',
            },
        },
        MuiDialogTitle: {
            root: {
                '& .MuiIconButton-label': {
                    overflow: 'hidden',
                },
            },
        },
    },
});

const transformGeoStatusToLocationValidity = (status: Geo.GeoStatus): Geo.LocationValidity => {
    switch (status) {
        case Geo.GeoStatus.Ok:
        case Geo.GeoStatus.OkImported:
            return Geo.LocationValidity.Exact;
        case Geo.GeoStatus.Doubt:
            return Geo.LocationValidity.Valid;
        default:
            return Geo.LocationValidity.Invalid;
    }
};

const generateLocationStringFromGeoPoint = (point: Geo.GeoPoint, spaced: boolean = false): string => {
    const separator = spaced ? ', ' : ',';
    return `${point.lat}${separator}${point.lng}`;
};

/**
 * Convert the number to the format like "1M" or "1.3K".
 * @param number
 * @param fractions
 */
const humanReadableNumber = (number: number, fractions = 1): string => {
    const fractionCoefficient = Math.pow(10, fractions);
    const powers = [
        { power: 6, suffix: 'M' },
        { power: 3, suffix: 'K' },
    ];

    for (const item of powers) {
        const min = Math.pow(10, item.power);

        if (number >= min) {
            const newNumber = Math.round((number * fractionCoefficient) / min) / fractionCoefficient;

            return `${newNumber}${item.suffix}`;
        }
    }

    return `${number}`;
};

export {
    trimLookupNameLabel,
    trimLookupIdLabel,
    logDebug,
    isModalOpen,
    utcToUserTimezone,
    utcToUserTimezoneNoSeconds,
    userTimezoneToUtc,
    formatDuration,
    addStyle,
    removeStyle,
    formatWithCommas,
    formatDateForExport,
    formatDateTimeForExport,
    formatDateTimeForPicker,
    formatDateForPicker,
    userToUserTimezone,
    capitalizeFirstLetterAndLowerOther,
    capitalizeFirstLetter,
    sortFunc,
    formatDateToDefault,
    formatSeconds,
    isEqual,
    isEmpty,
    isActiveEntity,
    isOwnerableField,
    isSimpleTypeField,
    isUpdatableField,
    isReadonlyField,
    getActiveOrInactiveFieldByApiName,
    isLookupField,
    isAddressElementField,
    isNotMappedAsAddress,
    isGeopointFriendlyTypeField,
    safeString,
    doesValueMatchType,
    filterStringValueToMatchType,
    filterInputCharactersStringToMatchType,
    weAreInIframe,
    weAreInNativeApp,
    isMapPage,
    nativeAppFriendlyRedirect,
    nativeAppFriendlyOpenWindow,
    currentUserDate,
    nowIsBefore,
    calendar,
    isSafeApiName,
    iOS,
    isMac,
    shouldWeRespectNotch,
    isCanvasSupported,
    weAreInIosNativeApp,
    weAreInAndroidNativeApp,
    isLandscapeOrientation,
    roundToDecimal,
    transformGeoStatusToLocationValidity,
    generateLocationStringFromGeoPoint,
    humanReadableNumber,
};
