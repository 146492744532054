import isNil from 'lodash/isNil';

import WorkflowManager, { NAMESPACE_GLOBAL } from './WorkflowManager';
import apiRoutes, { reverse } from 'api/apiRoutes';
import i18n from '../locales/i18n';
import { DEFAULT_DETAILS as INVOKE_URL_DEFAULT_DETAILS } from '../components/WorkflowActions/InvokeUrl/Action';
import { CallContext } from '../components/utils/CallContext';

import { DEFAULT_DETAILS as SHOW_MESSAGE_DEFAULT_DETAILS } from '../components/WorkflowActions/ShowMessage/Action';
import { DEFAULT_DETAILS as OPEN_URL_DEFAULT_DETAILS } from '../components/WorkflowActions/OpenUrl/Action';
import { DEFAULT_DETAILS as OPEN_FORM_DEFAULT_DETAILS } from '../components/WorkflowActions/OpenForm/Action';
import { DEFAULT_DETAILS as UPDATE_FORM_DEFAULT_DETAILS } from '../components/WorkflowActions/UpdateForm/Action';

const t = i18n.t.bind(i18n);

export const TYPE_FIELD_UPDATE = 'update_record';
export const TYPE_MATCH_RECORDS = 'match_records';
export const TYPE_INVOKE_URL = 'invoke_url';
export const TYPE_CREATE_RECORD = 'create_record';
export const TYPE_DELETE_RECORD = 'delete_record';
export const TYPE_VISIT_PLANNER = 'visit_planner';
export const TYPE_CODE_STATEMENT = 'code_statement';
export const TYPE_MARK_AS_VISITED = 'mark_as_visited';
export const TYPE_OPEN_FORM = 'open_form';
export const TYPE_CLOSE_FORM = 'close_form';
export const TYPE_SHOW_MESSAGE = 'show_message';
export const TYPE_OPEN_URL = 'open_url';
export const TYPE_SEND_EMAIL = 'send_email';
export const TYPE_SEND_SMS = 'send_sms';
export const TYPE_UPDATE_FORM = 'update_form';
export const TYPE_COMMIT = 'commit';

export const ACTION_TYPE_LIST = [
    {
        value: TYPE_CREATE_RECORD,
        get label() {
            return t('workflow_actions.form.create_record.short_title');
        },
    },
    {
        value: TYPE_FIELD_UPDATE,
        get label() {
            return t('workflow_actions.form.field_update.short_title');
        },
    },
    {
        value: TYPE_DELETE_RECORD,
        get label() {
            return t('workflow_actions.form.delete_record.short_title');
        },
    },
    {
        value: TYPE_MATCH_RECORDS,
        get label() {
            return t('workflow_actions.form.matched_records.title');
        },
    },
    {
        value: TYPE_INVOKE_URL,
        get label() {
            return t('workflow_actions.form.invoke_url.title');
        },
    },
    {
        value: TYPE_SHOW_MESSAGE,
        get label() {
            return t('workflow_actions.form.show_message.title');
        },
    },
    {
        value: TYPE_OPEN_URL,
        get label() {
            return t('workflow_actions.form.open_url.title');
        },
    },
    {
        value: TYPE_OPEN_FORM,
        get label() {
            return t('workflow_actions.form.open_form.title');
        },
    },
    {
        value: TYPE_UPDATE_FORM,
        get label() {
            return t('workflow_actions.form.update_form.title');
        },
    },
    {
        value: TYPE_SEND_EMAIL,
        get label() {
            return t('workflow_actions.form.send_email.title');
        },
    },
    {
        value: TYPE_SEND_SMS,
        get label() {
            return t('workflow_actions.form.send_sms.title');
        },
    },
    {
        value: TYPE_VISIT_PLANNER,
        get label() {
            return t('workflow_actions.form.visit_planner.title');
        },
    },
];

export const DETAIL_FIELDS = 'fields';
export const DETAIL_FIELD = 'field';
export const DETAIL_FIELD_ID = 'field_id';
export const DETAIL_CUSTOM = 'custom';
export const DETAIL_FORMULA = 'formula';
export const DETAIL_BOOLEAN_EXPRESSION = 'boolean_expression';
export const DETAIL_HUMANIZED_EXPRESSION = 'humanized_boolean_expression';
export const DETAIL_RANK = 'rank';
export const DETAIL_RANK_MIN_VALUE = 'rank_min_value';
export const DETAIL_STORE_FIELD = 'store_field';
export const DETAIL_RECORDS_LIMIT = 'records_limit';

export function getDetail(detailKey, details, defaultValue = null) {
    let value = details && details[detailKey];
    if (isNil(value)) {
        value = defaultValue;
    }
    return value;
}

class WorkflowActionManager extends WorkflowManager {
    static getDefaultAction(account, type, callContext = null, namespace = NAMESPACE_GLOBAL) {
        return {
            id: null,
            name: null,
            namespace,
            apiName: null,
            reevaluate: false,
            account,
            type,
            callContext: WorkflowActionManager.getDefaultCallContext(type, callContext ?? CallContext.create()),
            details: WorkflowActionManager.getDefaultDetails(type),
        };
    }

    static getDefaultCallContext(type, callContext) {
        switch (type) {
            case TYPE_FIELD_UPDATE:
            case TYPE_MATCH_RECORDS:
            case TYPE_CODE_STATEMENT:
            case TYPE_COMMIT:
            case TYPE_MARK_AS_VISITED:
                return callContext;
            default:
                return CallContext.create();
        }
    }

    static getDefaultDetails(type) {
        switch (type) {
            case TYPE_FIELD_UPDATE:
                return { [DETAIL_FIELDS]: [{}] };
            case TYPE_INVOKE_URL:
                return INVOKE_URL_DEFAULT_DETAILS;
            case TYPE_CREATE_RECORD:
                return { [DETAIL_FIELDS]: [{}] };
            case TYPE_SHOW_MESSAGE:
                return SHOW_MESSAGE_DEFAULT_DETAILS;
            case TYPE_OPEN_URL:
                return OPEN_URL_DEFAULT_DETAILS;
            case TYPE_OPEN_FORM:
                return OPEN_FORM_DEFAULT_DETAILS;
            case TYPE_UPDATE_FORM:
                return UPDATE_FORM_DEFAULT_DETAILS;
            case TYPE_CODE_STATEMENT:
                return { action: '' };
            default:
                return {};
        }
    }

    get(accountId, actionId) {
        const url = reverse(apiRoutes.account.workflowActions.item, { accountId, actionId });
        return this.requestApi(url, 'GET').then((action) => {
            return this.denormalizeAction(action);
        });
    }

    list(accountId, type = null, filters = [], sorting = [], page = null, pageSize = null) {
        const url = reverse(apiRoutes.account.workflowActions, { accountId });
        return this.requestApi(url, 'GET', { type, filters, sorting, page, pageSize }).then((data) => {
            return {
                ...data,
                items: data.items.map((action) => this.denormalizeAction(action)),
            };
        });
    }

    normalizeAction(action) {
        return {
            ...action,
            groups: this.normalizeGroups(action.groups),
            callContext: this.normalizeCallContext(action.callContext),
        };
    }

    denormalizeAction(action) {
        return {
            ...action,
            groups: this.denormalizeGroups(action.groups),
            callContext: this.denormalizeCallContext(action.callContext),
        };
    }

    save(accountId, action) {
        if (!action.id) {
            const url = reverse(apiRoutes.account.workflowActions, { accountId });
            return this.requestApi(url, 'POST', this.normalizeAction(action)).then((action) => {
                return this.denormalizeAction(action);
            });
        }

        const url = reverse(apiRoutes.account.workflowActions.item, { accountId, actionId: action.id });
        return this.requestApi(url, 'PATCH', this.normalizeAction(action)).then((action) => {
            return this.denormalizeAction(action);
        });
    }

    delete(accountId, actionId) {
        const url = reverse(apiRoutes.account.workflowActions.item, { accountId, actionId });
        return this.requestApi(url, 'DELETE');
    }
}

export const workflowActionManager = new WorkflowActionManager();
