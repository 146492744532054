import dispatcher from '../dispatcher';
import events from '../../events';
import { User } from '../../interfaces/user';

class WorkingHoursExceptionsDialogManager {
    private open: boolean = false;
    private _user?: User;

    openDialog = (user: User): void => {
        if (this.open) {
            return;
        }

        this.open = true;
        this._user = user;
        dispatcher.dispatch(events.USER_WORKING_HOURS_EXCEPTIONS_POPUP_UPDATED);
    };

    close = (): void => {
        if (!this.open) {
            return;
        }

        this.open = false;
        this._user = undefined;
        dispatcher.dispatch(events.USER_WORKING_HOURS_EXCEPTIONS_POPUP_UPDATED);
    };

    isOpen = (): boolean => {
        return this.open;
    };

    get user(): User | undefined {
        return this._user;
    }
}

export const workingHoursExceptionsDialogManager = new WorkingHoursExceptionsDialogManager();
