import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class SignatureManager extends BackendService {
    sign(accountId, url) {
        return this.requestApi(reverse(apiRoutes.account.sign, { accountId }), 'POST', { url });
    }
}

export const signatureManager = new SignatureManager();
