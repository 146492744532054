import React from 'react';
import PropTypes from 'prop-types';
import ExpressionBuilder from '../ExpressionBuilder';
import { expressionManager } from '../../service/ExpressionManager';
import { FormHelperText } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { CallContext } from '../utils/CallContext';

class ExpressionBuilderForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: new Map(),
        };

        this.expressionBuilder = React.createRef();
    }

    submit() {
        this.setState({
            errors: new Map(),
        });
        this.validate()
            .then((result) => this.props.onSubmitSuccess(result))
            .catch((errors) => {
                this.setState({
                    errors,
                });
                this.props.onSubmitError && this.props.onSubmitError();
            });
    }

    validate() {
        return new Promise((resolve, reject) => {
            this.expressionBuilder.current.clearErrors();
            this.expressionBuilder.current.getExpression().then((result) => {
                const errors = new Map();
                if (!result || !result.expression) {
                    errors.set('expression', this.props.t('expresion_builder.wrong_expression'));
                }
                if (errors.size === 0) {
                    resolve(result);
                } else {
                    reject(errors);
                }
            });
        });
    }

    validateExpression = (expression) => {
        return expressionManager.toFormula(expression, this.props.callContext);
    };

    render() {
        return (
            <form noValidate autoComplete="off">
                <ExpressionBuilder
                    callContext={this.props.callContext}
                    onValidate={this.validateExpression}
                    defaultExpression={this.props.expression}
                    ref={this.expressionBuilder}
                    showGroupByPrefix
                    enableFormulaMode
                    addSpecialSection={this.props.addSpecialSection}
                    addOldEntitySection={this.props.addOldEntitySection}
                    addUserSection={this.props.addUserSection}
                    overrideBaseEntitiesIds={this.props.overrideBaseEntitiesIds}
                    overrideTargetEntitiesIds={this.props.overrideTargetEntitiesIds}
                />
                {this.state.errors.has('expression') && (
                    <FormHelperText error>{this.state.errors.get('expression')}</FormHelperText>
                )}
            </form>
        );
    }
}

ExpressionBuilderForm.propTypes = {
    callContext: PropTypes.instanceOf(CallContext).isRequired,
    expression: PropTypes.array.isRequired,
    addSpecialSection: PropTypes.bool,
    addOldEntitySection: PropTypes.bool,
    addUserSection: PropTypes.bool,
    overrideBaseEntitiesIds: PropTypes.array,
    overrideTargetEntitiesIds: PropTypes.array,
};

ExpressionBuilderForm.defaultProps = {
    addSpecialSection: false,
    addOldEntitySection: false,
    addUserSection: true,
};

export default withTranslation('translations', { withRef: true })(ExpressionBuilderForm);
