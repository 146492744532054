import Dexie from 'dexie';
import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import events from '../../events';
import dispatcher from '../dispatcher';
import { userManager } from '../UserManager';
import mapStateManagerFactory from '../MapStateManagerFactory';
import { sortFunc } from 'utils';
import chunk from 'lodash/chunk';
import Timer, { TIMER_OPERATIONS } from '../../handlers/TimerHandler';
import L from 'leaflet';
import DbTerritoriesManager from '../IndexedDb/Territories/DbTerritoriesManager';
import { ExpandMapBounds, FixBounds } from 'components/utils/MapBounds';
import { TERRITORIES_MODULE } from 'components/Permissions/constants.js';
import { DEFAULT_COLOR, TYPE_FUNCTION, TYPE_METRIC } from 'components/Territories/model/TerritoryGroup';
import slugify from 'slugify';
import {
    CreateTerritory,
    MakeRawLayer,
    TERRITORY_COLOR_TYPE,
    TERRITORY_TOLERANCE_TYPE,
    TERRITORY_TYPE,
    TERRITORY_VISUALIZATION_TYPE,
} from 'components/Territories/model/Territory';

export class TerritoryManager extends BackendService {
    constructor(accountId) {
        super();

        this.init = false;
        this.loadingUpdates = false;
        this.accountId = accountId;
        this.territoriesListCurrentNames = [];
        this.territories = new Map();
        this.territoriesParentMap = new Map();
        this.territoriesMapCurrentNames = new Map();
        this.existsTerritoriesCodes = new Map();
        this.existsTerritoriesGroupsCodes = new Map();
        this.territoriesGroups = null;
        this.layersPropertiesMap = new Map();
        this.hasDraft = false;
        this.unsaved = null;
        this.settings = null;
        this.updatesStack = [];
        this.queueModifyGeografies = [];

        this.reloadSettings();

        dispatcher.subscribe(events.WS_TERRITORIES_GROUP_SUCCESS_CHANGE, this, (payload) => {
            if (this.init === false || this.loadingUpdates === true) {
                this.updatesStack.push({ payload, date: new Date() });
                return;
            }
            this.updateStateByPayload(payload);
        });

        dispatcher.subscribe([events.EVENT_ACCOUNT_PERMISSIONS_SAVED, events.WS_ENTITIES_CHANGED], this, () => {
            this.reloadSettings();
        });

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.changesEntities.length === 0) {
                return;
            }

            this.reloadSettings();
        });

        const user = userManager.getCurrentUser();
        this.mapStateManager = user ? mapStateManagerFactory.getManager(user.id) : null;
        dispatcher.subscribe(events.EVENT_CURRENT_USER_CHANGED, this, (user) => {
            if (!user) {
                this.mapStateManager = null;
                return;
            }
            this.mapStateManager = mapStateManagerFactory.getManager(user.id);
        });

        dispatcher.subscribe(events.TERRITORIES_SAVED, this, () => {
            const group = this.getGroupForModifyGeographies();
            if (!group) {
                return;
            }
            this.editTerritoryGroupPolygon(group.uuid);
        });
    }

    addToQueueModifyGeographies(group) {
        this.queueModifyGeografies.push(group);
    }

    getGroupForModifyGeographies() {
        return this.queueModifyGeografies.shift();
    }

    isInit() {
        return this.init;
    }

    getTerritoryGroupUuidByCode(code) {
        return this.existsTerritoriesGroupsCodes.get(code) || null;
    }

    getTerritoryUuidByCode(code) {
        return this.existsTerritoriesCodes.get(code) || null;
    }

    reloadAllData = (payload) => {
        this.updatesStack = [];
        return this.requestTerritories().then((response) => {
            if (payload.userId === undefined || payload.userId === userManager.getCurrentUser().id) {
                dispatcher.dispatch(events.TERRITORIES_SAVED);
            }
            return response;
        });
    };

    updateStateByPayload(payload) {
        this.loadingUpdates = true;
        return this.reloadAllData(payload);
    }

    synchronizedWithUpdatesStack() {
        if (this.updatesStack.length === 0) {
            return Promise.resolve();
        }
        let object = this.updatesStack.shift();
        return this.updateStateByPayload(object.payload);
    }

    getSimplifySourcesDataByUuids(uuids) {
        if (!this.init) {
            return Promise.resolve([]);
        }
        return DbTerritoriesManager.getManager().then((manager) => {
            return manager.getSimplifySourcesData(uuids);
        });
    }

    cleanDb() {
        this.init = false;
        this.territories = new Map();
        this.territoriesMapCurrentNames = new Map();
        this.territoriesGroups = null;
        this.layersPropertiesMap = new Map();
        return DbTerritoriesManager.getManager().then((manager) => {
            return Promise.all([manager.clearTerritoriesLayerData(), manager.clearTerritoriesSourceData()]);
        });
    }

    getFullSourcesDataByUuids(uuids) {
        if (!this.init) {
            return Promise.resolve([]);
        }
        return DbTerritoriesManager.getManager().then((manager) => {
            return manager.getFullSourcesData(uuids);
        });
    }

    getTerritoryGeoJson(uuid) {
        if (!this.init) {
            return Promise.resolve([]);
        }
        return DbTerritoriesManager.getManager().then((manager) => {
            return manager.getFullSourcesData([uuid]).then((results) => {
                return results[0];
            });
        });
    }

    getLayerPropertiesByUuid(uuid) {
        return this.layersPropertiesMap.get(uuid) ?? null;
    }

    getTerritoryNameById(id) {
        return this.territoriesMapCurrentNames.get(id);
    }

    getTerritoriesByUuids(uuids) {
        let result = [];
        uuids.forEach((uuid) => {
            result.push(this.getTerritory(uuid));
        });
        return result;
    }

    getTerritoryGroupByUuid(uuid) {
        if (this.territoriesGroups === null) {
            return null;
        }
        return this.territoriesGroups.find((group) => group.uuid === uuid) || null;
    }

    getTerritoriesParentByGroup(territoryGroup) {
        return this.territoriesParentMap.get(territoryGroup.id) ?? null;
    }

    getTerritoryGroupById(id) {
        if (this.territoriesGroups === null) {
            return null;
        }
        return this.territoriesGroups.find((group) => group.id === id) || null;
    }

    getTerritoriesGroup() {
        return this.territoriesGroups;
    }

    isHasDraft() {
        return this.hasDraft;
    }

    getGroupByTerritory(territory) {
        return this.getTerritoryGroupById(territory.groupId);
    }

    saveTerritory(territory) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);

        if (!territory.id) {
            let group = this.constructor.copyGroup(this.getGroupByTerritory(territory));
            group.territoriesUpdated.push(territory);
            territory.checked = true;
            this.mapStateManager.onTerritorySwitch(territory);
            dispatcher.subscribe(events.TERRITORIES_SAVED, this, () => {
                this.editTerritoryPolygon(territory.uuid);
                dispatcher.unsubscribe(events.TERRITORIES_SAVED, this);
            });
            dispatcher.subscribe(events.WS_TERRITORIES_GROUP_ERROR_CHANGE, this, (payload) => {
                if (payload.userId === userManager.getCurrentUser().id) {
                    dispatcher.unsubscribe(events.TERRITORIES_SAVED, this);
                }
            });

            return this.saveTerritoriesGroupDraft(group);
        }

        let oldTerritory = this.territories.get(territory.uuid);
        let group = this.constructor.copyGroup(this.getGroupByTerritory(oldTerritory));
        group.territoriesUpdated.push(territory);

        return this.saveTerritoriesGroupDraft(group);
    }

    updateTerritoriesGroupGeoPartIds(currentGroupId, selectedAreas) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);
        let geoPartIds = Array.from(selectedAreas.keys()).map((item) => parseInt(item));
        const groupOld = this.getTerritoryGroupById(currentGroupId);
        let territories = groupOld.territories;
        const groupNew = this.constructor.copyGroup(groupOld);

        const newParts = geoPartIds.filter((id) => !groupNew.geoPartIds.includes(id));
        const removeParts = groupNew.geoPartIds.filter((id) => !geoPartIds.includes(id));

        if (removeParts.length > 0) {
            removeParts.forEach((id) => {
                for (let i = 0; i < territories.length; i++) {
                    const terr = territories[i];
                    if (terr.isCreatedAuto && terr.geoPartIds.includes(id)) {
                        terr.isNeedRemove = true;
                        groupNew.territoriesUpdated.push(terr);
                        break;
                    }
                }
            });
        }

        if (newParts.length > 0) {
            newParts.forEach((id) => {
                const properties = selectedAreas.get(id.toString()).geoJson.properties;
                if (properties.NAME !== null && properties.NAME !== '') {
                    let newTerr = CreateTerritory();
                    newTerr.name = properties.NAME;
                    newTerr.groupId = groupNew.id;
                    newTerr.mode = TERRITORY_TYPE.GEO_LIB;
                    newTerr.isCreatedAuto = true;
                    newTerr.geoPartIds = [id];
                    newTerr.colorType = TERRITORY_COLOR_TYPE.GROUP;
                    newTerr.code = this.getUniqueCodeTerritory(properties.NAME, groupNew.territoriesUpdated);
                    newTerr.checked = true;
                    newTerr.toleranceType = TERRITORY_TOLERANCE_TYPE.GROUP;
                    groupNew.territoriesUpdated.push(newTerr);
                    this.mapStateManager.onTerritorySwitch(newTerr);
                }
            });
        }
        groupNew.geoPartIds = geoPartIds;
        return this.saveTerritoriesGroupDraft(groupNew);
    }

    getUniqueCodeTerritory(name, newTerritories) {
        let uniqueName = slugify(String(name)).toLocaleLowerCase();
        let countDuplicate = 1;
        while (this.getTerritoryUuidByCode(uniqueName) !== null) {
            uniqueName = slugify(name + '-' + countDuplicate).toLocaleLowerCase();
            countDuplicate++;
        }

        newTerritories.forEach((newTerr) => {
            if (uniqueName === newTerr.code) {
                uniqueName = slugify(name + '-' + countDuplicate).toLocaleLowerCase();
                countDuplicate++;
            }
        });

        return uniqueName;
    }

    saveTerritoryGroup(territoryGroup) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);

        if (!territoryGroup.id) {
            return this.saveTerritoriesGroupDraft(territoryGroup);
        }

        const groupUpdated = this.constructor.copyGroup(territoryGroup);
        return this.saveTerritoriesGroupDraft(groupUpdated);
    }

    static copyGroup(group) {
        let { territories, ...groupWithoutTerritories } = group;
        groupWithoutTerritories.territoriesUpdated = [];
        return { ...groupWithoutTerritories };
    }

    static createMetric() {
        return {
            uuid: null,
            code: null,
            name: '',
            type: TYPE_METRIC.FUNCTION,
            function: TYPE_FUNCTION.SUM,
            fieldId: null,
            entityId: null,
            demographicId: null,
            demographicResponseType: null,
        };
    }

    getTerritoryById(id) {
        if (this.territories === null) {
            return null;
        }

        for (let territory of this.territories.values()) {
            if (territory.id === id) {
                return territory;
            }
        }
        return null;
    }

    getTerritory(uuid) {
        if (this.territories === null) {
            return null;
        }
        return this.territories.get(uuid) || null;
    }

    isChecked(uuid) {
        const territory = this.getTerritory(uuid);
        if (!territory) {
            return false;
        }
        return territory.checked;
    }

    getColor(uuid) {
        const territory = this.getTerritory(uuid);
        if (!territory) {
            return DEFAULT_COLOR;
        }
        return territory.color || DEFAULT_COLOR;
    }

    toggleTerritory(uuid, checked) {
        const territory = this.getTerritory(uuid);
        if (territory === null) {
            return;
        }
        if (territory.checked !== checked) {
            territory.checked = checked;
            dispatcher.dispatch(events.SWITCH_TERRITORY, territory, checked);
        }
    }

    toggleTerritories(groupUuid, checked) {
        if (this.territories === null) {
            return null;
        }
        let group = this.getTerritoryGroupByUuid(groupUuid);
        if (group === null) {
            group = this.getTerritoryGroupById(0);
        }
        group.territories.map((t) => {
            t.checked = checked;
            return t;
        });
        dispatcher.dispatch(events.SWITCH_TERRITORIES, group.id);
    }

    deleteGroup(groupId) {
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(groupId));
        groupUpdated.isNeedRemove = true;
        this.saveTerritoriesGroupDraft(groupUpdated);
    }

    deleteTerritory(uuid) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);

        const territory = this.getTerritory(uuid);
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(territory.groupId));

        territory.isNeedRemove = true;
        let children = this.getTerritoriesByUuids(territory.children);
        for (let child of children) {
            child.isNeedRemove = true;
            this.mapStateManager.onTerritorySwitch(child);
        }

        if (territory.isCreatedAuto && territory.mode === TERRITORY_TYPE.GEO_LIB) {
            groupUpdated.geoPartIds = groupUpdated.geoPartIds.filter((id) => !territory.geoPartIds.includes(id));
        }
        groupUpdated.territoriesUpdated.push(territory);

        this.saveTerritoriesGroupDraft(groupUpdated);
    }

    getCountAssignedTerritories() {
        let count = 0;
        if (this.territoriesGroups !== null) {
            this.territoriesGroups.forEach((group) => {
                if (group.isAutoAssign) {
                    count += group.territories.length;
                }
            });
        }

        return count;
    }

    getTerritoriesCurrentNames() {
        return this.territoriesListCurrentNames;
    }

    getUnsavedTerritories() {
        if (!this.unsaved || !this.unsaved.unsaved) {
            return null;
        }
        return this.unsaved;
    }

    saveChanges() {
        return this.saveTerritories();
    }

    saveUnsavedTerritories() {
        return this.requestApi(
            reverse(apiRoutes.account.territories.unsaved, { accountId: this.accountId }),
            'POST',
        ).then(() => {
            this.unsaved = null;
        });
    }

    discardChanges() {
        this.resetTerritoriesGroupDraft();
    }

    editTerritoryPolygon(uuid) {
        const territory = this.getTerritory(uuid);
        if (territory === null) {
            return;
        }

        this.toggleTerritory(uuid, true);

        dispatcher.dispatch(events.START_EDIT_TERRITORY_GEOMETRY, uuid);
    }

    editTerritoryGroupPolygon(uuid) {
        const territoryGroup = this.getTerritoryGroupByUuid(uuid);
        if (territoryGroup === null) {
            return;
        }

        dispatcher.dispatch(events.START_EDIT_GROUP_GEOMETRY, territoryGroup.id);
    }

    saveGeometries = () => {
        dispatcher.dispatch(events.SAVE_GEOMETRIES);
    };

    cancelChangeGeometries = () => {
        dispatcher.dispatch(events.STOP_CHANGE_GEOMETRIES);
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);
    };

    saveGeoJson(uuid, geoJson) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);
        const territory = this.getTerritory(uuid);
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(territory.groupId));

        if (!territory) {
            return Promise.resolve();
        }

        territory.sourceData = geoJson !== null ? JSON.stringify(geoJson) : null;
        territory.geoJson = territory.sourceData;
        groupUpdated.territoriesUpdated.push(territory);

        return this.saveTerritoriesGroupDraft(groupUpdated);
    }

    saveTerritoriesFromFile = (groupId, territories) => {
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(groupId ?? 0));

        if (!territories || territories.length === 0) {
            return Promise.resolve();
        }

        setTimeout(() => {
            territories.forEach((territory) => {
                this.mapStateManager.onTerritorySwitch(territory);
            });
        }, 0);

        groupUpdated.territoriesUpdated = territories;
        return this.saveTerritoriesGroupDraft(groupUpdated);
    };

    saveGeoPartIds(uuid, geoPartIds) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);
        const territory = this.getTerritory(uuid);
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(territory.groupId));
        if (!territory) {
            return Promise.resolve();
        }
        territory.geoPartIds = geoPartIds;
        groupUpdated.territoriesUpdated.push(territory);
        return this.saveTerritoriesGroupDraft(groupUpdated);
    }

    saveGeoPartGroupsIds(groupId, geoPartIds) {
        dispatcher.dispatch(events.END_EDIT_GEOMETRY);
        const groupUpdated = this.constructor.copyGroup(this.getTerritoryGroupById(groupId));
        if (!groupUpdated) {
            return;
        }
        groupUpdated.geoPartIds = geoPartIds;
        return this.saveTerritoriesGroupDraft(groupUpdated);
    }

    requestTerritories() {
        const url = reverse(apiRoutes.account.territories.index, { accountId: this.accountId });
        return this.requestApi(url, 'GET').then((response) => {
            return this.setTerritories(response)
                .then(() => {
                    return response;
                })
                .catch((err) => {
                    console.error(err);
                    return response;
                });
        });
    }

    loadTerritoriesGeoJson(ids = [], draft = false) {
        const timer = Timer.init(TIMER_OPERATIONS.TerritoryManager.loadTerritoriesGeoJson, true).startChild(
            TIMER_OPERATIONS.TerritoryManager.loadTerritoriesGeoJson,
            { ids, draft },
        );
        return this.requestApi(
            reverse(apiRoutes.account.territories.territoriesGeoJsonByIds, { accountId: this.accountId }),
            'POST',
            { ids, draft },
        ).finally(() => timer.end());
    }

    async insertRawLayersToMemory(idsTerritoriesFroLoadingGeoJson) {
        let idsArray = chunk(idsTerritoriesFroLoadingGeoJson, 100);
        if (idsArray === null || idsArray.length === 0) {
            return Promise.resolve();
        }

        if (idsArray.length === 0) {
            return Promise.resolve();
        }

        let exitError = false;
        // eslint-disable-next-line no-loop-func
        function handleDbError(e) {
            exitError = e;
            switch (e.name) {
                case 'AbortError':
                    if (e.inner) {
                        return handleDbError(e.inner);
                    }
                    console.error('DB manager ended with Abort error ' + e.message);
                    break;
                case 'QuotaExceededError':
                    console.error('DB manager ended with QuotaExceededError ' + e.message);
                    break;
                default:
                    console.error('DB manager ended with error: ', e);
                    break;
            }
        }

        try {
            do {
                const ids = idsArray.pop();
                const layersProperties = [];
                const simplifySourcesData = [];
                const fullSourcesData = [];

                const response = await this.loadTerritoriesGeoJson(ids, this.hasDraft);
                response.forEach((item) => {
                    let territory = this.territories.get(item.uuid);
                    let rawLayer = MakeRawLayer(territory, item.source_data);

                    layersProperties.push({
                        uuid: territory.uuid,
                        updatedAt: territory.updatedAt,
                        layerProperties: rawLayer.layerProperties,
                    });

                    if (rawLayer.sourceData.simplifySourceData) {
                        simplifySourcesData.push({
                            uuid: territory.uuid,
                            sourceData: rawLayer.sourceData.simplifySourceData,
                        });
                    }

                    if (rawLayer.sourceData.fullSourceData) {
                        fullSourcesData.push({ uuid: territory.uuid, sourceData: rawLayer.sourceData.fullSourceData });
                    }

                    if (item.sourceData !== null && rawLayer.layerProperties.bounds !== null) {
                        territory.hasSourceData = true;
                    }
                });

                const manager = await DbTerritoriesManager.getManager();

                // needed to catch individually because strange behavior of dexie.
                await Dexie.Promise.all([
                    manager.setTerritoryLayersProperties(layersProperties).catch(handleDbError),
                    manager.setSimplifySourcesData(simplifySourcesData).catch(handleDbError),
                    manager.setFullSourcesData(fullSourcesData).catch(handleDbError),
                ]);

                if (exitError) {
                    throw new Error(exitError);
                }

                layersProperties.forEach((item_1) => {
                    if (item_1.layerProperties?.bounds) {
                        item_1.layerProperties.bounds = FixBounds(item_1.layerProperties.bounds);
                    }
                    this.addLayerPropertiesToMap(item_1.uuid, item_1.layerProperties);
                });
            } while (idsArray.length > 0);
        } catch (e) {
            await this.cleanDb();
        }
    }

    createMapTerritories(response) {
        const territories = new Map();
        const existsTerritoriesCodes = new Map();
        const existsTerritoriesGroupsCodes = new Map();
        let layerDataUuids = [];

        this.getGroupsFromResponse(response).forEach((group) => {
            existsTerritoriesGroupsCodes.set(group.code, group.uuid);
            group.territories.forEach((territory) => {
                existsTerritoriesCodes.set(territory.code, territory.uuid);
            });
        });

        this.getTerritoryGroupsByCurrentUser(response).forEach((group) => {
            group.territories.forEach((territory) => {
                territories.set(territory.uuid, territory);
                layerDataUuids.push(territory.uuid);
                const oldTerr = this.territories.get(territory.uuid);
                if (undefined !== oldTerr) {
                    territory.hasSourceData = oldTerr.hasSourceData;
                    if (oldTerr.id === null && territory.id !== null) {
                        this.mapStateManager.onTerritorySwitch(territory);
                    }
                }
                const visible = this.mapStateManager ? this.mapStateManager.isTerritoryVisible(territory) : undefined;
                territory.checked = visible === undefined ? false : visible;
            });
        });

        return DbTerritoriesManager.getManager().then((manager) => {
            return manager.getLayersProperties(layerDataUuids).then((layersData) => {
                const idsTerritoriesForLoadingGeoJson = [];
                layersData.forEach((data, index) => {
                    let territory = territories.get(layerDataUuids[index]);
                    if (data === undefined) {
                        idsTerritoriesForLoadingGeoJson.push(territory.id);
                        return;
                    }

                    if (data.updatedAt !== territory.updatedAt) {
                        idsTerritoriesForLoadingGeoJson.push(territory.id);
                        return;
                    }
                    territory.hasSourceData = data.layerProperties.hasSourceData;
                    if (data.layerProperties?.bounds) {
                        data.layerProperties.bounds = FixBounds(data.layerProperties.bounds);
                    }
                    this.addLayerPropertiesToMap(data.uuid, data.layerProperties);
                });
                this.territories = territories;
                this.existsTerritoriesCodes = existsTerritoriesCodes;
                this.existsTerritoriesGroupsCodes = existsTerritoriesGroupsCodes;
                this.updateCurrentNames(response.currentTerritoriesNames);
                return this.insertRawLayersToMemory(idsTerritoriesForLoadingGeoJson);
            });
        });
    }

    addLayerPropertiesToMap(uuid, layerData) {
        this.layersPropertiesMap.set(uuid, layerData);
    }

    getUuidsByBounds(bounds, zoom, editedTerritoryUuid, editedGroupId, geoLibEnable) {
        let uuidsSimplifySourceData = [];
        let uuidsFullSourceData = [];
        if (!this.init) {
            return { uuidsSimplifySourceData, uuidsFullSourceData };
        }

        let overloadBounds = ExpandMapBounds(bounds, 0.6);
        overloadBounds = L.latLngBounds(L.latLng(bounds.minLat, bounds.minLng), L.latLng(bounds.maxLat, bounds.maxLng));
        this.layersPropertiesMap.forEach((layerProperties, uuid) => {
            if (layerProperties.bounds === null) {
                return;
            }

            if (layerProperties.minZoom > zoom) {
                return;
            }

            const territory = this.getTerritory(uuid);
            if (territory === null) {
                return;
            }

            if (territory.groupId === editedGroupId) {
                return;
            }

            if (!territory.checked || editedTerritoryUuid === territory.uuid) {
                return false;
            }

            const boundsTerr = L.latLngBounds(
                L.latLng(layerProperties.bounds.minLat, layerProperties.bounds.minLng),
                L.latLng(layerProperties.bounds.maxLat, layerProperties.bounds.maxLng),
            );
            if (!overloadBounds.intersects(boundsTerr)) {
                return;
            }

            if (this.hasSimplifySourceData(territory, zoom, layerProperties, geoLibEnable)) {
                uuidsSimplifySourceData.push(uuid);
            } else {
                uuidsFullSourceData.push(uuid);
            }
        });
        return { uuidsSimplifySourceData, uuidsFullSourceData };
    }

    hasSimplifySourceData(territory, zoom, layerProperties, geoLibEnable) {
        if (territory.mode !== TERRITORY_TYPE.GEO_LIB) {
            return false;
        }

        if (
            territory.groupId !== null &&
            (territory.visualizationType === TERRITORY_VISUALIZATION_TYPE.GROUP || territory.isCreatedAuto)
        ) {
            let group = this.getTerritoryGroupById(territory.groupId);
            return group.showUnionArea;
        }

        if (territory.showUnionArea) {
            return true;
        }

        if (geoLibEnable && layerProperties.hasSimplifySourceData) {
            return true;
        }

        return territory.showNameArea && layerProperties.hasSimplifySourceData && layerProperties.minZoomUnion > zoom;
    }

    updateCurrentNames(currentTerritoriesNames) {
        const territoriesMapCurrentNames = new Map();
        currentTerritoriesNames.forEach((item) => {
            territoriesMapCurrentNames.set(item.id, item.name);
        });
        this.territoriesListCurrentNames = currentTerritoriesNames.sort(sortFunc('name'));
        this.territoriesMapCurrentNames = territoriesMapCurrentNames;
    }

    sortData() {
        this.territoriesParentMap = new Map();
        this.territoriesGroups.sort((a, b) => {
            return a.name.toString().localeCompare(b.name);
        });

        this.territoriesGroups.forEach((group) => {
            let parentArray = [];
            group.territories.forEach((territory) => {
                if (territory.parentId === null) {
                    parentArray.push(territory);
                }
            });
            parentArray.sort((a, b) => a.name.toString().localeCompare(b.name));
            this.territoriesParentMap.set(group.id, parentArray);
        });
    }

    setTerritories(response) {
        this.unsaved = {
            total: 0,
            unsaved: 0,
        };
        response.unsaved.forEach((r) => {
            this.unsaved.total += r.total;
            this.unsaved.unsaved += r.unsaved;
        });
        this.hasDraft = response.isDraft;
        return this.createMapTerritories(response).then(() => {
            this.territoriesGroups = this.getTerritoryGroupsByCurrentUser(response);
            this.sortData();
            return this.synchronizedWithUpdatesStack().then(() => {
                this.init = true;
                this.loadingUpdates = false;
                dispatcher.dispatch(events.TERRITORIES_LOADED);
                return response;
            });
        });
    }

    getTerritoryGroupsByCurrentUser(response) {
        const currentUser = userManager.getCurrentUser();
        return this.getGroupsFromResponse(response).filter((group) => group.rbac[currentUser.id]?.isVisible);
    }

    getGroupsFromResponse(response) {
        const canUseDraft = userManager.userHasAccessTo(
            TERRITORIES_MODULE.NAME,
            TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.NAME,
            TERRITORIES_MODULE.FEATURES.SHOW_TERRITORIES_PANE.SUB_FEATURES.MODIFY_TERRITORIES.NAME,
        );

        return canUseDraft && this.hasDraft ? response.draftTerritoriesGroup : response.territoriesGroup;
    }

    saveTerritories() {
        const timer = Timer.init(TIMER_OPERATIONS.TerritoryManager.saveTerritories, true).startChild(
            TIMER_OPERATIONS.TerritoryManager.saveTerritories,
        );
        return this.requestApi(
            reverse(apiRoutes.account.territories.index, { accountId: this.accountId }) + '?operation=save_draft',
            'POST',
        ).finally(() => {
            timer.end();
        });
    }

    saveTerritoriesGroupDraft(group) {
        dispatcher.dispatch(events.TERRITORIES_SAVING);

        return this.requestApi(
            reverse(apiRoutes.account.territories.index, { accountId: this.accountId }) + '?operation=create_draft',
            'POST',
            { territoryGroup: group },
        );
    }

    resetTerritoriesGroupDraft() {
        dispatcher.dispatch(events.TERRITORIES_SAVING);
        return this.requestApi(
            reverse(apiRoutes.account.territories.index, { accountId: this.accountId }) + '?operation=clean_draft',
            'POST',
        );
    }

    saveTerritoriesGroupPermission(group) {
        dispatcher.dispatch(events.TERRITORIES_SAVING);

        return this.requestApi(
            reverse(apiRoutes.account.territories.index, { accountId: this.accountId }) + '?operation=save_permission',
            'POST',
            { territoryGroup: this.constructor.copyGroup(group) },
        );
    }

    saveSettings(settings) {
        return this.requestApi(reverse(apiRoutes.account.territories.settings, { accountId: this.accountId }), 'POST', {
            settings,
        }).then((response) => {
            this.settings = response;
            dispatcher.dispatch(events.TERRITORIES_SETTINGS_LOADED);
        });
    }

    reloadSettings() {
        this.settings = null;
        return this.requestSettings();
    }

    requestSettings() {
        if (this.settings !== null) {
            return Promise.resolve(this.settings);
        }

        return this.requestApi(
            reverse(apiRoutes.account.territories.settings, { accountId: this.accountId }),
            'GET',
        ).then((response) => {
            this.settings = response;
            dispatcher.dispatch(events.TERRITORIES_SETTINGS_LOADED);
            return response;
        });
    }

    isAutoAssignmentEnabled() {
        if (this.settings === null) {
            return null;
        }
        for (let source of this.settings.sources) {
            for (let entity of source.entities) {
                if (entity.hasTerritories) {
                    return true;
                }
            }
        }
        return false;
    }
}

class Factory {
    managers = new Map();
    currentAccountId = null;

    getManager(accountId) {
        accountId = parseInt(accountId);
        this.currentAccountId = accountId;
        if (this.managers.has(accountId)) {
            return this.managers.get(accountId);
        }
        const manager = new TerritoryManager(accountId);
        this.managers.set(accountId, manager);

        return manager;
    }

    getCurrentManager() {
        let accountId = null;
        if (userManager.getCurrentUser() && !userManager.isRoleSuperAdmin()) {
            accountId = userManager.getCurrentUser().accountId;
        } else {
            accountId = this.currentAccountId;
        }

        if (accountId) {
            return this.getManager(accountId);
        }
        return null;
    }
}

export default new Factory();
