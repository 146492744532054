import AbstractEvent from './AbstractEvent';
import AbstractRecord, { Field } from './AbstractRecord';

export interface AddRecordLabels {
    searchTitleFields: string;
    entityLabel: string;
    dataSourceName: string;
}

class AddRecord extends AbstractRecord {
    private readonly latitude: number;
    private readonly longitude: number;
    private readonly labels: AddRecordLabels;

    constructor(
        accountId: number,
        dataSourceId: number,
        entityId: number,
        fields: Array<Field>,
        latitude: number,
        longitude: number,
        labels: AddRecordLabels,
    ) {
        super(accountId, dataSourceId, entityId, fields);

        this.latitude = latitude;
        this.longitude = longitude;
        this.labels = labels;
    }

    public getLatitude(): number {
        return this.latitude;
    }

    public getLongitude(): number {
        return this.longitude;
    }

    public getLabels(): AddRecordLabels {
        return this.labels;
    }

    protected toStorageSpecific(): object {
        return {
            ...super.toStorageSpecific(),
            latitude: this.getLatitude(),
            longitude: this.getLongitude(),
            labels: this.getLabels(),
        };
    }

    public static fromStorage(data: any): AddRecord {
        // @ts-ignore
        return AbstractEvent.fromStorageBase(
            new AddRecord(
                data.accountId,
                data.dataSourceId,
                data.entityId,
                data.fields,
                data.latitude,
                data.longitude,
                data.labels,
            ),
            data,
        );
    }
}

export default AddRecord;
