import { include, reverse } from 'named-urls';

type CompilePattern =
    | string
    | {
          index: string;
      };

const compile = (pattern: CompilePattern, params?: any) => {
    if (typeof pattern === 'object') {
        pattern = pattern.index;
    }
    let result = reverse(pattern.replace(/\([^()]*\)/g, ''), params);
    if (result.substr(-1) === '/') {
        return result.slice(0, -1);
    }
    return result;
};

export { compile as reverse };

export default {
    hey: '/hey',
    forgotPassword: '/forgot_password',
    resetPassword: '/reset_password',
    findPasswordInDataBreaches: '/find_password',
    login: '/auth/login',
    logout: '/auth/logout',
    refresh: '/auth/refresh',
    logoutUser: '/auth/logout/:userId',
    oauth: '/oauth/:provider',
    sso: include('/sso', {
        stage: 'stage/:stage',
        link: 'link',
        linkCheck: 'link/check',
    }),
    geoLib: include('/geo_lib', {
        index: '',
        typeAreas: 'type_areas',
        find: 'find',
        findByNames: 'find/by_names',
        findByIds: 'find/by_ids',
    }),
    accounts: '/accounts',
    leads: '/leads',
    accountsGeo: '/accounts/geo',
    accountsSearch: '/accounts/search',
    /**
     * @deprecated
     */
    accountSearch: '/account/:accountId/search',
    accountRestoreShard: '/account/current/restoreShard',
    accountsSearchAutocomplete: '/accounts/search/autocomplete',
    accountsSearchDetails: '/accounts/search/details',
    accountsSearchTextSearch: '/accounts/search/textSearch',
    accountsCsv: '/accounts/csv/:type',
    searchParams: '/search_params',
    tracking: include('/location_tracking', {
        save: 'save',
        heartbeat: 'heartbeat',
        getPaths: 'get_paths',
        getUserLocation: 'get_user_location/:userId',
    }),
    sharedMaps: include('/sharedmaps', {
        track: 'track',
    }),
    metadata: include('/metadata', {
        entity: 'entities/:entityId',
        dataSource: 'datasources/:dataSourceId',
        account: 'accounts/:accountId',
    }),
    account: include('/accounts/:accountId', {
        index: '',
        restore: 'restore',
        sign: 'sign',
        clearCache: 'clearCache',
        changeTest: 'changeTest',
        permissions: 'permissions',
        sso: 'sso',
        users: include('users', {
            index: '',
            objectCounts: 'objectCounts',
            delete: 'delete',
            find: 'find',
        }),
        user: include('users/:userId', {
            index: '',
            withNewOwner: 'newOwner/:newOwnerId',
            personalSetting: 'personalSettings',
        }),
        sharedMap: include('sharedmaps', {
            index: '',
            detail: ':id',
            settings: 'settings',
            limits: 'limits',
        }),
        roles: include('roles', {
            index: '',
            withPermissions: 'withPermissions',
            initRoleUIFeaturesPermissionsAction: 'initRoleUIFeaturesPermissionsAction',
        }),
        role: 'roles/:roleId',
        dataSourcesDetailed: 'ds_detailed',
        dataSources: 'ds',
        search: 'search',
        searchExportedProspects: 'searchExportedProspects',
        dataSource: include('ds/:dsId', {
            index: '',
            changes: 'changes',
            entities: include('entities', {
                index: '',
                import: 'import',
                availableForImport: 'availableForImport',
            }),
            importSchedules: 'import_schedules',
            entity: include('entities/:entityId', {
                index: '',
                settings: 'settings',
                record: 'records/:recordId',
                recordsCsv: 'records_csv',
                defaults: 'defaults',
                point: include('points/:recordId', {
                    georesult: 'georesult',
                }),
                liveUpdate: 'liveUpdate',
                liveUpdateCount: 'liveUpdateCount',
                liveDelete: 'liveDelete',
                exportProspects: 'exportProspects',
                createRecord: 'createRecord',
                createRecordPrefill: 'createRecordPrefill',
                uniqueLayers: 'uniqueLayers/:fieldId',
                refreshUniqueLayers: 'refreshUniqueLayer/:layerGroupId',
                convertLayerGroup: 'convertLayerGroup/:layerGroupId',
                views: 'views',
                view: include('views/:viewId', {
                    index: '',
                    permission: 'permission',
                }),
                layerGroups: 'layerGroups',
                layerGroup: include('layerGroups/:layerGroupId', {
                    index: '',
                    permission: 'permission',
                }),
            }),
            liveSearchOwner: 'liveSearch/owner',
            liveSearchField: 'liveSearch/entity/:entity',
            liveLookupRecords: 'liveLookupRecords/entity/:entity/:fieldId/:fieldName/:sourceFieldId/:recordId',
            liveSearchLookupRecords: 'liveSearchLookupRecords/entity/:entityName',
            liveUpdateJob: 'liveUpdateJobs',
            liveUpdateJobStop: 'stopLiveUpdateJob/:jobId',
            geocoding: include('geocoding', {
                index: '',
                useCases: ':resultId',
            }),
            prospectsEntities: 'prospectsEntities',
            userParams: 'user-params',
            userCredentials: 'user-credentials',
        }),
        territories: include('territories', {
            index: '',
            settings: 'settings',
            unsaved: 'unsaved',
            territoriesGeoJsonByIds: 'territories_geo_json_by_ids',
        }),
        routes: include('routes', {
            index: '',
            vehicleProfiles: 'vehicle_profiles',
            vehicleProfile: 'vehicle_profiles/:vehicleProfileId',
            addresses: 'addresses',
            buildRoutesSession: 'build_routes_session',
            users_today_routes: 'users_today_routes',
            getPaths: 'get_paths',
            delete: 'delete',
        }),
        route: 'routes/:routeId',
        savedPlaces: 'saved_places',
        savedPlace: 'saved_places/:savedPlaceId',
        eventLog: include('event_log', {
            index: '',
            details: ':id',
        }),
        updateLog: include('update_log', {
            index: '',
        }),
        subscription: include('subscription', {
            index: '',
            plans: 'plans',
            changePlan: 'change_plan/:newPlan',
            changeSeats: 'change_seats/:numberSeats',
            changeRoutingCredits: 'change_additional_routing_credits/:numberRoutingCredits',
            additionalAiTokens: 'additional-ai-tokens',
            additionalTranscriptionSeconds: 'additional-transcription-seconds',
            createPaymentLink: 'create_payment_link',
            history: 'history',
            paymentHistory: 'payment_history',
            updatePaymentInformation: 'update_payment_information',
            switchToCustom: 'switch_to_custom',
            resume: 'resume',
            recalculatePrice: 'recalculate_price',
        }),
        workflowRules: include('workflow_rules', {
            index: '',
            item: ':ruleId',
        }),
        workflowActions: include('workflow_actions', {
            index: '',
            item: ':actionId',
        }),
        workflowButtons: include('workflow_buttons', {
            index: '',
            item: ':buttonId',
        }),
        workflow: include('workflow', {
            executionFlow: include('execution_flow', {
                sessions: include('sessions', {
                    index: '',
                    logs: include(':sessionId/logs', {
                        index: '',
                        context: ':logId/context',
                    }),
                }),
            }),
            schedules: include('schedules', {
                index: '',
                item: ':scheduleId',
            }),
            run: include('run', {
                button: 'button/:buttonId',
                formEvent: 'form_event/:formActionId',
                searchBarFilter: 'search_bar_filter/:formActionId',
            }),
        }),
        limitations: 'limitations',
        dataExchange: include('data_exchange', {
            sessions: include('sessions', {
                index: '',
                logs: ':sessionId/logs',
            }),
            logs: 'logs/:entityId/:recordId',
        }),
        file: include('file', {
            index: '',
            item: ':fileId',
            presign: 'presign',
            multipart: include('multipart', {
                create: '',
                signUploadPart: ':uploadId/:partNumber',
                getUploadParts: ':uploadId',
                complete: ':uploadId/complete',
                abort: ':uploadId',
            }),
            transcript: include(':fileId/transcript', {
                index: '',
                item: ':itemId',
                speakers: include('speakers', {
                    index: '',
                    item: ':speakerId',
                }),
            }),
            retryTranscription: ':fileId/retry-transcription',
            retrySummarization: ':fileId/retry-summarization',
            summary: ':fileId/summary',
        }),
        orgSettings: include('org-settings', {
            index: '',
            smtp: 'smtp',
            sortingOfObjects: 'sorting-of-objects',
        }),
        calendarEventJobs: 'calendar-event-jobs',
        calendars: include('calendars', {
            index: '',
            item: ':calendarId',
        }),
        routingCredits: include('routing-credits', {
            usageList: 'usage-list',
        }),
        aiTokenUsages: include('ai-token-usages', {
            list: '',
        }),
        sessionSettings: include('session_settings/:roleId', {
            index: '',
        }),
        purposeOfUse: 'purpose-of-use',
        routeActivityStatuses: include('route_activity_statuses', {
            index: '',
        }),
    }),
    demographic: include('/demographic', {
        groups: 'groups',
        tree_groups_by_metric: 'tree_groups/:metricId',
        find_metric: 'find_metric',
    }),
    users: '/users',
    user: include('/users/:userId', {
        index: '',
        withNewOwner: 'newOwner/:newOwnerId',
        personalSetting: 'personalSettings',
    }),
    adapters: '/adapters',
    standardAdapter: '/adapters/standard/:adapterId',
    customAdapter: '/adapters/custom',
    schedulePresets: '/schedule_presets',
    entity: include('/entities/:entityId', {
        index: '',
        records: include('records', {
            index: '',
            hasMore: 'hasMore',
        }),
        record: 'records/item/:recordId',
        demoRecord: 'records/demo',
        recordsCsv: 'records_csv',
        defaults: 'defaults',
        view: 'view',
        point: include('points/:recordId', {
            regeocode: 'regeocode',
            georesult: 'georesult',
        }),
    }),
    frameData: '/general',
    frameDataAccount: '/general/:accountId',
    countries: '/countries',
    subscriptionPlans: '/subscription_plans/:countryCode',
    mapSettings: '/map_settings',
    geocoder: include('/geocoder', {
        route: include('route', {
            build: 'build',
            refresh: 'refresh',
        }),
    }),
    expression: include('/expression', {
        validate: 'validate',
        toFormula: 'to_formula',
    }),
    formula: include('/formula', {
        validate: 'validate',
        functions: 'functions',
    }),
    devices: include('/devices', {
        syncInfo: 'sync_info',
    }),
    userReview: include('/user_review', {
        leaveReview: include('leaveReview', {
            writeReview: 'writeReview',
            noThanks: 'noThanks',
            remindMeLater: 'remindMeLater',
            alreadyDidReview: 'iAlreadyDidReview',
        }),
        couldBeBetter: include('couldBeBetter', {
            index: '',
            cancel: 'cancel',
            submitFeedback: 'submitFeedback',
            remindMeLater: 'remindMeLater',
        }),
        setHasCrmAccount: include('setHasCrmAccount', {
            index: '',
        }),
    }),
    routes: include('/routes', {
        settings: include('settings', {
            save: 'save',
            validate: 'validate',
            getUsersSettingsData: 'getUsersSettingsData',
        }),
        routeSession: include('session', {
            init: 'init',
            saveSessionName: 'saveSessionName',
            designMode: include('designMode', {
                switchTo: 'switchTo',
                save: 'save',
                addPoints: 'addPoints',
                updatePoint: 'updatePoint',
                deletePoints: 'deletePoints',
                updatePoints: 'updatePoints',
                movePoints: 'movePoints',
                cancelRoutingBuilding: 'cancelRoutingBuilding',
            }),
            draftMode: include('draft', {
                discard: 'discard',
                clear: 'clear',
                publish: 'publish',
                editMode: include('editMode', {
                    enable: 'enable',
                    cancel: 'cancel',
                    save: 'save',
                    deleteActivity: ':routeId/activity/:activityId',
                    moveActivity: 'moveActivity',
                    addActivity: 'addActivity',
                    addActivities: 'addActivities',
                    editFinish: 'edit-finish',
                    editStart: 'edit-start',
                    routes: include('routes', {
                        deleteRoute: ':routeId',
                    }),
                }),
            }),
            published: include('published', {
                switchToDesign: 'switchToDesign',
                routes: include('routes', {
                    list: '',
                    loadRoute: ':routeId',
                    deleteRoute: ':routeId',
                }),
                rebuildMode: include('rebuildMode', {
                    enable: 'enable',
                    cancel: 'cancel',
                    save: 'save',
                    saveAs: 'saveAs',
                }),
                editMode: include('editMode', {
                    enable: 'enable',
                    cancel: 'cancel',
                    save: 'save',
                    deleteActivity: ':routeId/activity/:activityId',
                    moveActivity: 'moveActivity',
                    addActivity: 'activity',
                    addActivities: 'addActivities',
                    editFinish: 'edit-finish',
                    editStart: 'edit-start',
                    reoptimize: 'reoptimize',
                    updateSession: 'update-session',
                }),
            }),
            trip: include('trip', {
                start: 'start',
                end: 'end',
            }),
        }),
    }),
    frontend: include('/frontend', {
        events: include('events', {
            register: 'register',
            status: 'status',
        }),
    }),
    interestingFeatures: include('/interesting-features', {
        defaultStructure: 'default-structure',
    }),
};
