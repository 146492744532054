import AbstractEvent from './AbstractEvent';

abstract class AbstractAutomation extends AbstractEvent {
    private readonly accountId: number;
    private readonly entityId: number;
    protected additionalContextData: object | null;
    protected readonly contextUlid: string | null;

    constructor(accountId: number, entityId: number, additionalContextData: object | null, contextUlid: string | null) {
        super();

        this.accountId = accountId;
        this.entityId = entityId;
        this.additionalContextData = additionalContextData;
        this.contextUlid = contextUlid;
    }

    public getAccountId(): number {
        return this.accountId;
    }

    public getEntityId(): number {
        return this.entityId;
    }

    public getAdditionalContextData(): object | null {
        return this.additionalContextData;
    }

    public setAdditionalContextData(additionalContextData: object | null): AbstractAutomation {
        this.additionalContextData = additionalContextData;

        return this;
    }

    public getContextUlid(): string | null {
        return this.contextUlid;
    }

    protected toStorageSpecific(): object {
        return {
            accountId: this.getAccountId(),
            entityId: this.getEntityId(),
            additionalContextData: this.getAdditionalContextData(),
            contextUlid: this.getContextUlid(),
        };
    }
}

export default AbstractAutomation;
