import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';

class LiveSearchManager extends BackendService {
    constructor(accountId, dataSourceId) {
        super();
        this.accountId = accountId;
        this.dataSourceId = dataSourceId;
    }

    searchOwner(search) {
        const url = reverse(apiRoutes.account.dataSource.liveSearchOwner, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
        });
        return this.requestApi(url, 'GET', {
            search,
        });
    }

    searchOwnersByIds(ids) {
        const url = reverse(apiRoutes.account.dataSource.liveSearchOwner, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
        });
        return this.requestApi(url, 'GET', {
            ids: ids.join('|'), // encode?
        });
    }

    searchEntity(entity, search) {
        const url = reverse(apiRoutes.account.dataSource.liveSearchField, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
            entity,
        });
        return this.requestApi(url, 'GET', { search });
    }

    fetchLookupRecords(entity, fieldId, fieldName, sourceFieldId, recordId) {
        const url = reverse(apiRoutes.account.dataSource.liveLookupRecords, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
            entity,
            fieldId,
            fieldName,
            sourceFieldId,
            recordId: encodeURIComponent(recordId),
        });
        return this.requestApi(url, 'GET');
    }

    searchLookupRecordsByIds(entityName, ids) {
        const url = reverse(apiRoutes.account.dataSource.liveSearchLookupRecords, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
            entityName,
        });
        return this.requestApi(url, 'GET', {
            ids: ids.join('|'), // encode?
        });
    }

    searchLookupRecords(entityName, search, exclude) {
        const url = reverse(apiRoutes.account.dataSource.liveSearchLookupRecords, {
            accountId: this.accountId,
            dsId: this.dataSourceId,
            entityName,
        });
        return this.requestApi(url, 'GET', {
            search: search, // encode?
            exclude,
        });
    }
}

class Factory {
    managers = new Map();

    getManager(accountId, dataSourceId) {
        const key = accountId + '_' + dataSourceId;
        if (this.managers.has(key)) {
            return this.managers.get(key);
        }
        const manager = new LiveSearchManager(accountId, dataSourceId);
        this.managers.set(key, manager);

        return manager;
    }
}

export default new Factory();
