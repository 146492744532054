import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Confirmation extends React.PureComponent {
    state = {
        anchorEl: null,
    };

    handleConfirmation = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
        this.props.onConfirm && this.props.onConfirm(event);
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
        this.props.onReject && this.props.onReject(event);
    };

    handleClick = (event) => {
        event.stopPropagation();
        if (this.props.disabled) {
            return;
        }
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    render() {
        const {
            t,
            textYes,
            textNo,
            text,
            disabledYesButton,
            disabledMaxWidth,
            confirmColor,
            disabledButtons,
            shouldConfirm,
        } = this.props;
        const child = React.Children.only(this.props.children);

        if (!React.isValidElement(child)) {
            return null;
        }

        if (!shouldConfirm) {
            return React.cloneElement(child, { onClick: this.props.onConfirm });
        }

        const message = typeof text === 'function' ? text() : text || t('confirmation.default_text');
        if (!message) {
            return React.cloneElement(child, { onClick: this.handleConfirmation });
        }

        return (
            <React.Fragment>
                {React.cloneElement(child, { onClick: this.handleClick })}
                <Popover
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    disableEnforceFocus
                    disableScrollLock
                >
                    <Box
                        padding="8px 16px"
                        textAlign="center"
                        fontSize="smaller"
                        maxWidth={disabledMaxWidth ? undefined : '100vw'}
                        minWidth={'200px'}
                        width="min-content"
                    >
                        <div>{message}</div>
                        <Button
                            color="default"
                            onClick={this.handleClose}
                            className="confirmation-no"
                            disabled={disabledButtons}
                            data-testid="confirmation.btn.no"
                        >
                            {textNo || t('no')}
                        </Button>
                        <Button
                            color={confirmColor}
                            onClick={this.handleConfirmation}
                            className="confirmation-yes"
                            disabled={disabledYesButton || disabledButtons}
                            data-testid="confirmation.btn.yes"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {textYes || t('yes')}
                        </Button>
                    </Box>
                </Popover>
            </React.Fragment>
        );
    }
}

Confirmation.propTypes = {
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    textYes: PropTypes.string,
    textNo: PropTypes.string,
    disabled: PropTypes.bool,
    disabledYesButton: PropTypes.bool,
    disabledButtons: PropTypes.bool,
    disabledMaxWidth: PropTypes.bool,
    confirmColor: PropTypes.string,
    shouldConfirm: PropTypes.bool,
};

Confirmation.defaultProps = {
    disabled: false,
    disabledMaxWidth: false,
    confirmColor: 'secondary',
    disabledButtons: false,
    shouldConfirm: true,
};

export default withTranslation()(Confirmation);
