import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { indigo, red } from '@material-ui/core/colors';
import DottedLink from '../DottedLink';
import LandingLink from '../HelpLink/LandingLink';

interface Props extends WithTranslation {
    mainText: string;
    dottedText: string;
    onSwitch: () => void;
    articleNumber?: string;
}

interface State {}

class EditDefaultValuesBlock extends React.PureComponent<Props, State> {
    render() {
        const { t, mainText, dottedText, onSwitch, articleNumber } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <i style={{ marginRight: 10, fontSize: '20px', color: indigo[500] }} className="fas fa-info-circle" />
                <div>
                    <p style={{ marginBlock: 0, color: red[500] }}>{mainText}</p>
                    <p style={{ marginTop: 0 }}>
                        <DottedLink onClick={onSwitch}>{dottedText}</DottedLink>
                        {!!articleNumber && (
                            <>
                                <span> </span>
                                <LandingLink article={articleNumber} useTrailingIcon>
                                    {t('table_view_form.default_properties.learn_more')}
                                </LandingLink>
                            </>
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EditDefaultValuesBlock);
