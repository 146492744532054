import { makeAutoObservable } from 'mobx';
import { Account } from '../../interfaces';

class MassUsersTravelingPreferencesDialogManager {
    private _isOpen: boolean;
    private _account: Account.Account | null = null;

    constructor() {
        makeAutoObservable(this);
        this._isOpen = false;
        this._account = null;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get account(): Account.Account | null {
        return this._account;
    }

    openModal(account: Account.Account) {
        this._isOpen = true;
        this._account = account;
    }

    closeModal() {
        this._isOpen = false;
        this._account = null;
    }
}

export default MassUsersTravelingPreferencesDialogManager;
