import { FC, useCallback, useMemo, useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import AddIconButton from 'components/CustomButton/AddIconButton';
import { WithTranslation, withTranslation } from 'react-i18next';
import { userManager } from 'service/UserManager';
import CalendarsUserGroupsDialog from './CalendarsUserGroupsDialog';
import { UserData } from 'service/types';
import { accountsManager } from 'service/AccountsManager';
import CalendarsTabPanelUserGroupItem from './CalendarsTabPanelUserGroupItem';
import { v4 as uuidv4 } from 'uuid';

interface Props extends WithTranslation {}

const CalendarsTabPanelUserGroups: FC<Props> = ({ t }) => {
    const [isGroupModalOpen, setGroupModalOpen] = useState(false);
    const [groupName, setGroupNamen] = useState('');
    const [usersInGroup, setUsersInGroup] = useState('');
    const account = userManager.getCurrentAccount();
    const users: UserData[] = [...userManager.users].map((user) => user[1]);

    const handleDelete = useCallback(
        (index: number) => {
            const userGroups = [...account.calendarUserGroups];
            if (index > -1) {
                userGroups.splice(index, 1);
            }

            accountsManager.save({
                ...account,
                calendarUserGroups: userGroups,
            });
        },
        [account],
    );

    const handleGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGroupNamen(e.target.value);
    };
    const handleUsersInGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsersInGroup(e.target.value);
    };

    const getUserNameByIds = useCallback(
        (userIds: number[]) => {
            return userIds
                .map((id: number) => {
                    return users.find((user) => user.id === id)?.name;
                })
                .join(', ');
        },
        [users],
    );

    const UsersList = useMemo(() => {
        const calendarUserGroups = account.calendarUserGroups
            .filter((group) => group.name.toLowerCase().includes(groupName.toLowerCase()))
            .filter((group) => {
                const usersList = getUserNameByIds(group.userIds);
                return usersList.toLowerCase().includes(usersInGroup.toLowerCase());
            });

        return calendarUserGroups.map((group, index) => {
            const usersList = getUserNameByIds(group.userIds);
            return (
                <CalendarsTabPanelUserGroupItem
                    key={group.name + uuidv4()}
                    name={group.name}
                    list={usersList}
                    onDelete={() => handleDelete(index)}
                />
            );
        });
    }, [account.calendarUserGroups, groupName, usersInGroup, getUserNameByIds, handleDelete]);
    return (
        <Box paddingTop={4}>
            <Box display="flex" gridGap={20}>
                <Typography variant="h5">{t('calendar_settings.dialog.tabs.user_groups')}</Typography>
                <AddIconButton onClick={() => setGroupModalOpen(true)} />
            </Box>

            <Box display="grid" marginY="20px" gridGap="20px" gridTemplateColumns={'1fr 2fr'}>
                <Box display="grid" gridGap="8px">
                    <Typography variant="h6">
                        {t('calendar_settings.dialog.user_groups.dialog.user_group_name')}
                    </Typography>
                    <TextField
                        label={t('calendar_settings.dialog.user_groups.dialog.search_user_group_name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={groupName}
                        onChange={handleGroupName}
                    />
                </Box>
                <Box display="grid" gridGap="8px">
                    <Typography variant="h6">
                        {t('calendar_settings.dialog.user_groups.dialog.users_in_group')}
                    </Typography>
                    <TextField
                        label={t('calendar_settings.dialog.user_groups.dialog.search_users_in_group')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={usersInGroup}
                        onChange={handleUsersInGroup}
                    />
                </Box>
            </Box>

            <Box>{UsersList}</Box>

            <CalendarsUserGroupsDialog
                isOpen={isGroupModalOpen}
                onClose={() => setGroupModalOpen(false)}
                isMobile={false}
            />
        </Box>
    );
};

export default withTranslation()(CalendarsTabPanelUserGroups);
