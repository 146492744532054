import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import emFactory from '../../service/EntityManager';
import './style.css';
import Hint from '../Hint';
import { trimLookupIdLabel } from '../../utils';
import clsx from 'clsx';

export class FieldSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            entities: new Map(),
            currentFields: this.props.currentFields,
        };
    }

    componentDidMount() {
        this.getEntityFields(this.props.dataSourceId, this.props.entityId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.entityId !== this.props.entityId) {
            this.getEntityFields(this.props.dataSourceId, this.props.entityId);
        }
    }

    getEntityManager = (dataSourceId) => {
        return emFactory.getManager(this.props.accountId, dataSourceId);
    };

    getEntityFields = (dsId, entityId) => {
        this.setState({ currentFields: [] });
        if (!dsId || !entityId) {
            return;
        }

        this.setState({ loading: true });

        if (this.state.entities.has(entityId)) {
            this.setState(
                (state) => {
                    const entity = state.entities.get(entityId);
                    return { currentFields: [...entity.fields], loading: false };
                },
                () => {
                    if (this.props.onCurrentFieldsUpdate) {
                        this.props.onCurrentFieldsUpdate(this.state.currentFields);
                    }
                },
            );
        }
        this.getEntityManager(dsId)
            .getEntities()
            .then((loadedEntities) => {
                this.setState(
                    (state) => {
                        let entities = state.entities;
                        let currentFields = [];
                        for (let entity of loadedEntities) {
                            if (!entities.has(entity.id)) {
                                entities = new Map(entities);
                                entities.set(entity.id, entity);
                            }
                            if (entity.id === entityId) {
                                currentFields = entity.fields;
                                if (this.props.filter) {
                                    currentFields = currentFields.filter(this.props.filter);
                                }
                            }
                        }
                        return { entities, currentFields, loading: false };
                    },
                    () => {
                        if (this.props.onCurrentFieldsUpdate) {
                            this.props.onCurrentFieldsUpdate(this.state.currentFields);
                        }
                    },
                );
            });
    };

    onChange = (event, value) => {
        this.props.onChange(value);
    };

    getFieldByApiName = (apiName) => {
        for (const field of [...this.props.customOptions, ...this.state.currentFields]) {
            if (field.apiName === apiName) {
                return field;
            }
        }
        return null;
    };

    render() {
        let options = [];
        if (this.state.currentFields.length > 0) {
            options = [...this.props.customOptions, ...this.state.currentFields];
        }

        options = options.filter((field) => {
            if (field.lookupData === null) {
                return true;
            }

            const match = field.apiName.match(/_(ID|NAME|TYPE)$/);
            return match === null || match[1] === 'ID';
        });

        return (
            <Autocomplete
                options={options}
                className={clsx('c-fields-select', this.props.hint ? 'c-fields-select__has_hint' : '')}
                getOptionLabel={(option) => trimLookupIdLabel(option)}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionDisabled={(option) => (this.props.disableNotIncluded ? option.isIncluded === false : false)}
                value={this.getFieldByApiName(this.props.value)}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.props.disabled}
                renderInput={(params) => (
                    <React.Fragment>
                        <TextField
                            {...params}
                            required={this.props.required}
                            error={this.props.error}
                            label={this.props.label}
                            InputProps={{
                                ...params.InputProps,
                                ...this.props.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                        {this.props.hint && (
                            <Hint
                                iProps={{
                                    style: { marginLeft: 8 },
                                    className: 'c-fields-select__hint',
                                }}
                                TooltipProps={{
                                    className: 'tooltip-question',
                                }}
                            >
                                {this.props.hint}
                            </Hint>
                        )}
                    </React.Fragment>
                )}
            />
        );
    }
}

FieldSelect.propTypes = {
    label: PropTypes.string.isRequired,
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    dataSourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    currentFields: PropTypes.array,
    customOptions: PropTypes.array,
    onCurrentFieldsUpdate: PropTypes.func,
    InputProps: PropTypes.object,
    hint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    filter: PropTypes.func,
    disableNotIncluded: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

FieldSelect.defaultProps = {
    currentFields: [],
    InputProps: {},
    hint: '',
    customOptions: [],
    required: false,
    disableNotIncluded: false,
    disabled: false,
    error: false,
};
