import { Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import React, { CSSProperties, MouseEventHandler } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface LookupRecordsFormProps<T> extends WithTranslation {
    records: T[];
    toAdd: any[];
    toDelete: any[];
    fieldName?: string;
    sourceField?: string;
    onRemove: MouseEventHandler<HTMLElement>;
    onUndoRemove: MouseEventHandler<HTMLElement>;
    onUndoAdd: MouseEventHandler<HTMLElement>;
}

class LookupRecordsForm<T extends Record<string, any>> extends React.PureComponent<LookupRecordsFormProps<T>> {
    render() {
        const { t, records, sourceField, fieldName, toAdd, toDelete } = this.props;

        const recordIds = records.reduce((acc, r) => acc.add(r.id), new Set<string>());
        // 'records' only represent records found in search. That's why we add virtual record-like objects to list.
        const toAddUnlisted = toAdd
            .filter((it) => !recordIds.has(it.id))
            .map((it) => ({ ...it, [fieldName!]: it.title }));

        return (
            <Table className="c-live-search-form__results">
                <TableBody>
                    {records.concat(toAddUnlisted).map((record) => {
                        const sourceRecordId = record.id;
                        const enqueueAdd = !!toAdd.find((r) => r.id === sourceRecordId);
                        const enqueueRemove = !!toDelete.find((r) => r.id === sourceRecordId);
                        const title =
                            record[fieldName!] ||
                            record[
                                Object.keys(record).find((key) => key.toLowerCase() === fieldName?.toLowerCase())!
                            ] ||
                            record.objectName;
                        const sourceTitle = record[sourceField!];
                        const style: CSSProperties = {};
                        const rowStyle: CSSProperties = {
                            cursor: 'pointer',
                        };
                        if (enqueueRemove) {
                            style.textDecoration = 'line-through';
                        }
                        if (enqueueAdd && !enqueueRemove) {
                            rowStyle.backgroundColor = '#e7ffe7';
                        }
                        return (
                            <TableRow key={sourceRecordId} hover style={rowStyle}>
                                <TableCell align="left">
                                    <span style={style}>{title || sourceTitle}</span>
                                </TableCell>
                                <TableCell align="right">
                                    {!enqueueAdd && !enqueueRemove && (
                                        <Tooltip title={t('live_update_table_form.lookup_records_form.remove.tooltip')}>
                                            <i
                                                className="fas fa-trash"
                                                onClick={this.props.onRemove}
                                                data-record-id={sourceRecordId}
                                                data-record-title={title}
                                            />
                                        </Tooltip>
                                    )}
                                    {!enqueueAdd && enqueueRemove && (
                                        <Tooltip
                                            title={t('live_update_table_form.lookup_records_form.undo_remove.tooltip')}
                                        >
                                            <i
                                                className="fas fa-undo"
                                                onClick={this.props.onUndoRemove}
                                                data-record-id={sourceRecordId}
                                                data-record-title={title}
                                            />
                                        </Tooltip>
                                    )}
                                    {enqueueAdd && !enqueueRemove && (
                                        <Tooltip
                                            title={t('live_update_table_form.lookup_records_form.undo_add.tooltip')}
                                        >
                                            <i
                                                className="fas fa-trash"
                                                onClick={this.props.onUndoAdd}
                                                data-record-id={sourceRecordId}
                                                data-record-title={sourceTitle}
                                            />
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

export default withTranslation('translations', { withRef: true })(LookupRecordsForm);
