import React from 'react';
import { clsx } from 'clsx';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme, createStyles } from '@material-ui/core';

interface DottedLinkProps extends WithStyles<typeof style>, Omit<React.HTMLAttributes<HTMLSpanElement>, 'onClick'> {
    href?: string;
    loading?: boolean;
    disabled?: boolean;
    variant?: 'inactive' | false | undefined;
    noWrap?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

class DottedLink extends React.PureComponent<DottedLinkProps> {
    render() {
        const { classes, loading, disabled, variant, noWrap, onClick, href, ...rest } = this.props;
        const defaultClass = variant === 'inactive' ? classes.inactive : classes.active;

        const className = clsx(
            this.props.className,
            loading || disabled ? classes.disabled : defaultClass,
            noWrap && classes.nowrap,
        );

        return <span {...rest} className={className} onClick={disabled ? undefined : onClick} />;
    }
}

const style = (theme: Theme) =>
    createStyles({
        active: {
            color: theme.palette.primary.main,
            borderBottom: '1px dotted',
            cursor: 'pointer',
        },
        inactive: {
            color: (theme.palette.primary as any).default,
            borderBottom: '1px dotted',
            cursor: 'pointer',
        },
        disabled: {
            color: theme.palette.grey['500'],
            borderBottom: '1px dotted',
        },
        nowrap: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    });

export default withStyles(style)(DottedLink);
