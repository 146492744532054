import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import events from '../../events';
import dispatcher from '../../service/dispatcher';

class SyncRefresh extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.dsId !== this.props.dsId) {
                return;
            }

            switch (payload.status) {
                case 'start':
                    this.props.onSync && this.props.onSync(true);
                    break;
                case 'complete':
                    if (payload.changesEntities && payload.changesEntities.length !== 0) {
                        this.setState({
                            anchorEl: this.ref.current,
                        });
                    }
                    this.props.onSync && this.props.onSync(false);
                    break;
                case 'failure':
                    this.props.onSync && this.props.onSync(false);
                    break;
                // no default
            }
        });
    }

    handleCancelRefresh = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const child = React.Children.only(this.props.children);

        if (!React.isValidElement(child)) {
            return null;
        }

        const { t, dsName, onRefreshRequest } = this.props;

        return (
            <React.Fragment>
                {React.cloneElement(child, {
                    ref: this.ref,
                })}
                <Popover
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleCancelRefresh}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    disableEnforceFocus
                >
                    <div style={{ padding: 16, maxWidth: 300 }}>
                        <div>{t('data_source.sync_refresh.metadata_changes_detected', { dsName })}</div>
                        <div style={{ textAlign: 'center', margin: '8px 0' }}>
                            <Button
                                onClick={this.handleCancelRefresh}
                                color="default"
                                data-testid="data_source.sync_refresh.dialog.close"
                            >
                                {t('dialog.close')}
                            </Button>
                            <Button
                                onClick={onRefreshRequest}
                                color="primary"
                                data-testid="data_source.sync_refresh.refresh_now"
                            >
                                {t('data_source.sync_refresh.refresh_now')}
                            </Button>
                        </div>
                        <div>{t('data_source.sync_refresh.unsaved_changes_will_be_lost')}</div>
                    </div>
                </Popover>
            </React.Fragment>
        );
    }
}

SyncRefresh.propTypes = {
    dsId: PropTypes.number.isRequired,
    dsName: PropTypes.string.isRequired,
    onRefreshRequest: PropTypes.func,
    onSync: PropTypes.func,
};

export default withTranslation('translations', { withRef: true })(SyncRefresh);
