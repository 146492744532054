import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { workflowRuleManager } from '../../service/WorkflowRuleManager';
import WorkflowRuleList from './WorkflowRulesList';
import dsManagerFactory from '../../service/DsManager';
import { accountsManager } from '../../service/AccountsManager';
import WorkflowRuleModal from './WorkflowRuleModal';
import cloneDeep from 'lodash/cloneDeep';
import AutomationElementHeader from '../Workflow/AutomationElementHeader';
import dispatcher from '../../service/dispatcher';
import events from '../../events';

export const TRIGGER_CREATED = 'created';
export const TRIGGER_EDITED = 'edited';
export const TRIGGER_DELETED = 'deleted';

class WorkflowRules extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            totalCount: 0,
            account: null,
            dataSources: [],
            currentRule: null,
        };
    }

    componentDidMount() {
        this.loadAccount();
        this.loadDataSources();

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload) => {
            if (payload.status === 'complete') {
                this.loadDataSources();
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    loadRules = (requestParams = {}) => {
        this.setState({ loading: true, rows: [], totalCount: 0 });

        return workflowRuleManager
            .list(
                this.props.accountId,
                requestParams.filters,
                requestParams.sorting,
                requestParams.currentPage + 1,
                requestParams.pageSize,
            )
            .then((data) => {
                this.setState({ rows: data.items, loading: false, totalCount: data.total });
                return data;
            });
    };

    loadAccount = () => {
        accountsManager
            .load(this.props.accountId)
            .then((account) => {
                this.setState({ account });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    loadDataSources = () => {
        const manager = dsManagerFactory.getManager(this.props.accountId);
        manager.reset();

        this.setState({ dataSources: [] });

        manager
            .list()
            .then((data) => {
                data = data.map((source) => {
                    source.entities = source.entityCounters.map((entityCounter) => entityCounter.entity);
                    return source;
                });
                this.setState({ dataSources: data });
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
            });
    };

    toggleActive = (rule) => {
        const index = this.state.rows.indexOf(rule);
        rule.isActive = !rule.isActive;
        this.updateRuleState(rule, index);
        if (rule.id) {
            workflowRuleManager
                .save(this.props.accountId, rule)
                .then((rule) => {
                    this.updateRuleState(rule, index);
                })
                .catch(() => {
                    rule.isActive = !rule.isActive;
                    this.updateRuleState(rule, index);
                });
        }
    };

    updateRuleState = (rule, index = null) => {
        this.setState((state) => {
            const rows = cloneDeep(state.rows);
            let currentRule = null;

            if (Number.isInteger(index) && index >= 0) {
                rows[index] = rule;
            }
            if (state.currentRule) {
                currentRule = rule;
            }

            return { rows, currentRule };
        });
    };

    saveRule = (rule) => {
        const { currentRule } = this.state;
        this.closeRuleModal();
        this.props.enqueueSnackbar(this.props.t('workflow_rules.notification.saved'), { variant: 'success' });
        this.setState((state) => {
            const rows = [...state.rows].filter((r) => r.id !== currentRule.id);
            rows.unshift(rule);
            return { rows, totalCount: state.totalCount + 1 };
        });
    };

    removeRule = (rule) => {
        this.setState({ loading: true }, () => {
            workflowRuleManager
                .delete(this.props.accountId, rule.id)
                .then(() => {
                    this.setState((state) => {
                        const rows = [...state.rows].filter((row) => row.id !== rule.id);
                        return { rows, totalCount: state.totalCount - 1 };
                    });
                })
                .catch((response) => {
                    // todo notification
                });
        });
    };

    openRuleModal = (rule = null) => {
        if (rule === null) {
            rule = workflowRuleManager.constructor.getDefaultRule();
        }

        this.setState({
            currentRule: rule,
        });
    };

    closeRuleModal = () => {
        this.setState({ currentRule: null });
    };

    render() {
        const showAddButton =
            !!this.state.account && Array.isArray(this.state.dataSources) && this.state.dataSources.length > 0;
        return (
            <React.Fragment>
                <AutomationElementHeader
                    title={this.props.t('workflow_rules.title')}
                    addButtonHint={this.props.t('workflow_rules.hint.create')}
                    type={'rules'}
                    showAddButton={showAddButton}
                    accountId={this.props.accountId}
                    myAccount={this.props.myAccount}
                    onOpenModal={this.openRuleModal}
                />
                <div>
                    <WorkflowRuleList
                        onRequestData={this.loadRules}
                        rows={this.state.rows}
                        total={this.state.totalCount}
                        loading={this.state.loading}
                        dataSources={this.state.dataSources}
                        onRuleEdit={this.openRuleModal}
                        onRuleRemove={this.removeRule}
                        onToggleActive={this.toggleActive}
                    />
                </div>

                {this.state.account && (
                    <WorkflowRuleModal
                        account={this.state.account}
                        dataSources={this.state.dataSources}
                        onSave={this.saveRule}
                        onCancel={this.closeRuleModal}
                        onToggleActive={this.toggleActive}
                        rule={this.state.currentRule}
                    />
                )}
            </React.Fragment>
        );
    }
}

WorkflowRules.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    myAccount: PropTypes.bool.isRequired,
};

WorkflowRules.defaultProps = {
    myAccount: false,
};

export default withTranslation()(withRouter(withSnackbar(WorkflowRules)));
