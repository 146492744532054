import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import head from 'lodash/head';
import memoize from 'memoize-one';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormDialog from '../FormDialog';
import AddressForm from '../AddressForm';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import './SSUEntities.css';
import GeoMapperForm, { SAVE_FIELDS } from '../GeoMapperForm';
import { logDebug, trimLookupIdLabel } from '../../utils';
import { withTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import SearchableEntitiesListForm from '../EntitiesListForm/SearchableEntitiesListForm';
import red from '@material-ui/core/colors/red';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import Backdrop from '../Backdrop';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { SingleRowMultipleAutocomplete } from '../SingleRowMultipleAutocomplete';
import { getMessageAndDetails } from '../DataSource/ErrorMessage';
import { userManager } from '../../service/UserManager';
import WarningOverwriteFieldsDialog from '../WarningOverwriteFieldsDialog';
import WarningProbablyPartialAddressFieldDialog from '../WarningProbablyPartialAddressFieldDialog';

class SSUEntities extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            entities: null,
            dataSource: null,
            addObjectFormModal: false,
            errors: [],
            importEntitiesProcesses: new Set(),
            loadingEntities: new Map(),
        };

        this.account = userManager.getCurrentAccount();

        this.initialPresenceOfAddressField = new Map();
    }
    componentDidMount() {
        const { onProcessing, autoload, t } = this.props;
        onProcessing && onProcessing(t('signup.entities.receiving_structure'));
        if (autoload) {
            this.loadEntities();
        }

        dispatcher.subscribe(events.WS_ENTITIES_IMPORT_PROGRESS, this, this.onEntitiesImportProgress);
    }

    componentWillUnmount() {
        dispatcher.unsubscribe(events.WS_ENTITIES_IMPORT_PROGRESS, this);
    }

    loadEntities() {
        const { entityManager, onReady, onError } = this.props;
        entityManager
            .getEntities(true)
            .then((entities) => {
                this.initialPresenceOfAddressField = new Map();
                for (let entity of entities) {
                    const initialPresenceOfAddressField = this.constructor.doesEntityHaveAddressField(entity);
                    this.initialPresenceOfAddressField.set(entity.name, initialPresenceOfAddressField);
                }

                onReady && onReady(true);
                this.setState({
                    entities,
                });
            })
            .catch((error) => {
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                if (error instanceof Error) {
                    throw error;
                }
            });
        entityManager
            .getDataSource()
            .then((ds) => {
                this.setState({
                    dataSource: ds,
                });
            })
            .catch((error) => {
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                if (error instanceof Error) {
                    throw error;
                }
            });
    }

    quiet() {
        this.setState({
            errors: [],
        });
    }

    checkForErrors() {
        const { t } = this.props;

        const error = {
            message: '',
            details: new Map(),
        };

        let isValid = true;
        const errorDetails = {};

        for (let i = 0; i < this.state.entities.length; i++) {
            if (!this.state.entities[i].isIncluded) {
                continue;
            }
            if (this.state.entities[i].nameFields.length === 0) {
                isValid = false;
                errorDetails[i] = {
                    nameFields: t('signup.entities.require_name_field'),
                };
            }
        }

        if (!isValid) {
            error.message = t('validation_errors');
            error.details.set('entities', errorDetails);
            error.skipSentry = true;
            throw error;
        }
    }

    checkForWarning() {
        const { t } = this.props;
        const warningEntities = [];

        for (let entity of this.state.entities) {
            if (!entity.isIncluded) {
                continue;
            }
            if (
                !this.constructor.doesEntityHaveAddressField(entity) &&
                (entity.isCustom || this.initialPresenceOfAddressField.get(entity.name) === true)
            ) {
                warningEntities.push(entity.label);
            }
        }

        if (warningEntities.length > 0) {
            const warning = {
                message: '',
                skipSentry: true,
            };

            warning.message =
                warningEntities.length === 1
                    ? t('signup.entities.require_address_field_for_object', { name: warningEntities[0] })
                    : t('signup.entities.require_address_field_for_objects', {
                          names: '"' + warningEntities.join('", "') + '"',
                      });

            warning.message += ' ' + t('signup.entities.require_address_field_explanation');

            throw warning;
        }
    }

    static getDefaultImportSchedule() {
        return {
            interval: 30,
        };
    }

    commit(forceWarning = false) {
        const { entityManager, onProcessing, onSuccess, onWarning, onError, t } = this.props;

        // собираем данные в структуру, совместимую с saveEntitiesAction
        if (this.state.entities === null) {
            return Promise.reject(''); //////
        }

        this.setState({
            errors: {},
        });

        try {
            this.checkForErrors();
        } catch (error) {
            this.setState({
                errors: error.details.get('entities'),
            });
            const { message } = getMessageAndDetails(error);
            onError && onError(message);
            return Promise.reject(error);
        }

        if (!forceWarning) {
            try {
                this.checkForWarning();
            } catch (warning) {
                onWarning && onWarning(warning.message);
                return Promise.reject(warning);
            }
        }

        onProcessing && onProcessing(t('signup.entities.settings_are_being_saved'));

        const entities = [];
        for (let entity of this.state.entities) {
            if (entity.isIncluded) {
                if (!entity.schedule) {
                    entity.schedule = this.constructor.getDefaultImportSchedule();
                }
                entities.push(entity);
            } else {
                entities.push({
                    id: entity.id,
                    isIncluded: false,
                });
            }
        }

        return entityManager
            .saveEntities(entities)
            .then(() => {
                onSuccess && onSuccess();
                return Promise.resolve();
            })
            .catch((error) => {
                if (error.details.has('entities')) {
                    this.setState({
                        errors: error.details.get('entities'),
                    });
                }
                const { message } = getMessageAndDetails(error);
                onError && onError(message);
                throw error;
            });
    }

    static doesEntityHaveAddressField(entity) {
        for (let field of entity.fields) {
            if (field.isIncluded && field.isPin) {
                return true;
            }
        }
        return false;
    }

    handleEntityChecked = (entity, isIncluded) => {
        let i = this.state.entities.indexOf(entity);
        if (i === -1) {
            return;
        }
        entity = cloneDeep(entity);
        entity.isIncluded = isIncluded;
        entity.fields.map((field) => (field.isIncluded = isIncluded));

        this.setState((state) => {
            const entities = [...state.entities];
            if (!entity.isIncluded) {
                for (let e of entities) {
                    if (e.id === entity.id) {
                        continue;
                    }
                    if (e.isIncluded && e.isLockedBy && e.isLockedBy.length) {
                        logDebug('Process locked entity', e.name, e.isLockedBy);
                        e.isLockedBy = e.isLockedBy.filter((l) => l.entity.apiName !== entity.name);
                        logDebug('New lock', e.name, e.isLockedBy);
                        if (!e.isLockedBy.length) {
                            logDebug('Entity not locked anymore', e.name);
                            delete e.isLockedBy;
                            e.isIncluded = false;
                        }
                    }
                }
            }
            entities[i] = entity;
            return {
                entities,
            };
        });
    };

    handleEntityGeoMappingSaved = (entity, mapping) => {
        const i = this.state.entities.indexOf(entity);
        if (i === -1) {
            return;
        }

        const newEntity = {
            ...entity,
            useLatLngFields: mapping.useLatLngFields,
            hasSaveGeoFields: mapping.hasSaveGeoFields,
            autoSaveHouseAndStreetType: mapping.autoSaveHouseAndStreetType,
        };
        for (let saveField of SAVE_FIELDS) {
            newEntity[saveField] = null;
            for (let fi = 0; fi < newEntity.fields.length; fi++) {
                const field = newEntity.fields[fi];
                if (field.apiName === mapping[saveField]) {
                    newEntity[saveField] = field.id;
                }
            }
        }

        this.setState((state) => {
            const entities = [...state.entities];
            entities[i] = newEntity;
            return {
                entities,
            };
        });
    };

    handleEntityNameChanged = (entity, name) => {
        let i = this.state.entities.indexOf(entity);
        if (i === -1) {
            return;
        }
        entity = cloneDeep(entity);
        entity.nameFields = name;

        for (let fi = 0; fi < entity.fields.length; fi++) {
            const field = entity.fields[fi];
            if (name.includes(field.apiName)) {
                const newField = { ...field };
                newField.isIncluded = true;
                entity.fields[fi] = newField;
                break;
            }
        }

        this.setState((state) => {
            const entities = [...state.entities];
            entities[i] = entity;
            return {
                entities,
            };
        });
    };

    handleEntityAddressChanged = (entity, addressField) => {
        let i = this.state.entities.indexOf(entity);
        if (i === -1) {
            return;
        }
        entity = cloneDeep(entity);
        if (addressField) {
            addressField.isIncluded = true;
            addressField.isPin = true;
            let isNew = true;
            for (let fi = 0; fi < entity.fields.length; fi++) {
                if (entity.fields[fi].apiName !== addressField.apiName) {
                    entity.fields[fi].isPin = false;
                } else {
                    entity.fields[fi] = addressField;
                    isNew = false;
                }
            }
            if (isNew) {
                entity.fields.push(addressField);
            }
        } else {
            for (let field of entity.fields) {
                field.isPin = false;
            }
        }

        this.setState((state) => {
            const entities = [...state.entities];
            entities[i] = entity;
            return {
                entities,
            };
        });
    };

    handleDeleteCustomField = (entity, field) => {
        let i = this.state.entities.indexOf(entity);
        if (i === -1) {
            return;
        }
        entity = cloneDeep(entity);
        for (let fi = 0; fi < entity.fields.length; fi++) {
            if (entity.fields[fi].apiName === field.apiName) {
                entity.fields.splice(fi, 1);
                break;
            }
        }
        this.setState((state) => {
            const entities = [...state.entities];
            entities[i] = entity;
            return {
                entities,
            };
        });
    };

    necessaryFieldsMapped = (entity) => {
        if (entity.nameFields.length === 0) {
            return false;
        }

        for (let field of entity.fields) {
            if (field.isPin) {
                return true;
            }
        }

        return false;
    };

    handleAddObjectButtonClick = () => {
        this.setState({
            addObjectFormModal: true,
        });
    };

    handleAddObjectFormSave = (entities) => {
        let entitiesToImport = [];
        for (let entity of entities) {
            let originalEntity = this.state.entities.find((stateEntity) => stateEntity.name === entity.name);
            if (originalEntity) {
                if (originalEntity.isIncluded !== entity.isIncluded) {
                    this.handleEntityChecked(originalEntity, entity.isIncluded);
                    if (entity.isIncluded) {
                        entitiesToImport.push(entity);
                    }
                }
            } else if (entity.isIncluded) {
                entitiesToImport.push(entity);
            }
        }

        this.setState({
            addObjectFormModal: false,
        });

        if (entitiesToImport.length > 0) {
            this.importEntities(entitiesToImport);
        }
    };

    handleAddObjectFormCancel = () => {
        this.setState({
            addObjectFormModal: false,
        });
    };

    addLoadingEntities(entities) {
        const loadingEntities = new Map(this.state.loadingEntities);
        for (let entity of entities) {
            loadingEntities.set(entity.name, entity.label);
        }
        this.setState({
            loadingEntities,
        });
    }

    removeLoadingEntitiesByApiNames(loadingEntitiesApiNames) {
        const loadingEntities = new Map(this.state.loadingEntities);
        for (let loadingEntitiesApiName of loadingEntitiesApiNames) {
            loadingEntities.delete(loadingEntitiesApiName);
        }
        this.setState({
            loadingEntities,
        });
    }

    importEntities(entitiesToImport) {
        this.addLoadingEntities(entitiesToImport);
        const entitiesToImportApiNames = entitiesToImport.map((entity) => entity.name);
        return this.props.entityManager
            .importEntities(entitiesToImportApiNames)
            .then(({ processId, alreadyImportedEntitiesApiNames }) => {
                if (null === processId) {
                    this.finishImportProcess(processId, entitiesToImportApiNames);
                    return;
                }

                this.setState((state) => {
                    const importEntitiesProcesses = new Set(state.importEntitiesProcesses);
                    importEntitiesProcesses.add(processId);
                    return {
                        importEntitiesProcesses,
                    };
                });

                if (Array.isArray(alreadyImportedEntitiesApiNames) && alreadyImportedEntitiesApiNames.length > 0) {
                    this.finishImportProcess(null, alreadyImportedEntitiesApiNames);
                }
            });
    }

    onEntitiesImportProgress = ({ entitiesIds, processId, originalEntitiesApiNames }) => {
        if (!this.state.importEntitiesProcesses.has(processId)) {
            return;
        }

        if (Array.isArray(entitiesIds) && entitiesIds.length === 0) {
            this.finishImportProcess(processId, originalEntitiesApiNames);
            return;
        }

        this.props.entityManager.getEntities(true, entitiesIds).then((entities) => {
            const newEntities = [];
            const stateEntities = [...this.state.entities];
            for (let updatedEntity of entities) {
                let stateEntityIndex = stateEntities.findIndex((stateEntity) => stateEntity.id === updatedEntity.id);
                if (stateEntityIndex === -1) {
                    updatedEntity.isIncluded = true;
                    newEntities.push(updatedEntity);
                    stateEntities.push(updatedEntity);
                    stateEntityIndex = stateEntities.length - 1;

                    const initialPresenceOfAddressField = this.constructor.doesEntityHaveAddressField(updatedEntity);
                    this.initialPresenceOfAddressField.set(updatedEntity.name, initialPresenceOfAddressField);
                }

                if (
                    stateEntities[stateEntityIndex].isIncluded &&
                    !this.state.loadingEntities.has(stateEntities[stateEntityIndex].apiName)
                ) {
                    continue;
                }

                const fields = [...stateEntities[stateEntityIndex].fields];
                for (let updatedField of updatedEntity.fields) {
                    if (!fields.find((field) => field.apiName === updatedField.apiName)) {
                        fields.push(updatedField);
                    }
                }
                stateEntities[stateEntityIndex] = {
                    ...stateEntities[stateEntityIndex],
                    fields: updatedEntity.fields,
                };
            }

            this.setState(
                {
                    entities: stateEntities,
                },
                () => {
                    for (const newEntity of newEntities) {
                        this.handleEntityChecked(newEntity, newEntity.isIncluded);
                    }
                    this.finishImportProcess(processId, originalEntitiesApiNames);
                },
            );
        });
    };

    finishImportProcess(processId, importedEntitiesApiNames) {
        for (const stateEntity of this.state.entities) {
            if (stateEntity.isIncluded && importedEntitiesApiNames.includes(stateEntity.name)) {
                this.handleEntityChecked(stateEntity, true);
            }
        }
        this.setState((state) => {
            const importEntitiesProcesses = new Set(state.importEntitiesProcesses);
            if (null !== processId) {
                importEntitiesProcesses.delete(processId);
            }
            return {
                importEntitiesProcesses,
            };
        });
        this.removeLoadingEntitiesByApiNames(importedEntitiesApiNames);
    }

    getAppointmentsWarning() {
        const { dataSource, entities } = this.state;
        const { t } = this.props;

        if (dataSource && dataSource.specialEntities && dataSource.specialEntities.appointment && entities) {
            const appointmentsEntity = head(
                entities.filter((entity) => {
                    return entity.name === dataSource.specialEntities.appointment.entity;
                }),
            );

            if (appointmentsEntity) {
                if (!appointmentsEntity.isIncluded) {
                    return {
                        warning: true,
                        message: t('signup.entities.appointments.warning.not_added', {
                            entityLabel: appointmentsEntity.label,
                        }),
                    };
                }
            } else {
                return {
                    warning: true,
                    message: t('signup.entities.appointments.warning.not_available_entity', {
                        entity: dataSource.specialEntities.appointment.entity,
                    }),
                };
            }
        }

        return { warning: false, message: '' };
    }

    render() {
        const { t } = this.props;
        const appointmentWarning = this.getAppointmentsWarning();

        return (
            <div className={'c-data-source'}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item sm={8}>
                        <p>{t('signup.entities.objects_to_see_on_map')}</p>
                    </Grid>
                    <Grid item sm={4} style={{ textAlign: 'end' }}>
                        <Button variant="contained" color="default" onClick={this.handleAddObjectButtonClick}>
                            <i style={{ marginRight: '5px', fontSize: '18px' }} className="fas fa-plus-circle" />{' '}
                            {this.props.t('ssu.entities.add_object')}
                        </Button>
                    </Grid>
                </Grid>
                <p style={{ fontSize: 'small' }}>{this.props.t('ssu.entities.subtitle')}</p>
                {appointmentWarning.warning && (
                    <p className="appointment-warning">
                        <Icon style={{ marginRight: '8px' }}>report_problem</Icon>
                        {appointmentWarning.message}
                    </p>
                )}
                {this.state.entities === null ? (
                    <div>{t('signup.entities.loading_metadata')}</div>
                ) : (
                    this.state.entities.map((entity, i) => {
                        return (
                            entity.isIncluded && (
                                <Backdrop
                                    key={entity.id}
                                    loading={this.state.loadingEntities.has(entity.id)}
                                    style={{ minHeight: 'unset', marginBottom: 10 }}
                                >
                                    <TranslatedSSUEntity
                                        entity={entity}
                                        dataSource={this.state.dataSource}
                                        errors={this.state.errors[i]}
                                        onEntityChecked={this.handleEntityChecked}
                                        onEntityNameChanged={this.handleEntityNameChanged}
                                        onEntityAddressChanged={this.handleEntityAddressChanged}
                                        onDeleteCustomField={this.handleDeleteCustomField}
                                        onGeoMapperSaved={this.handleEntityGeoMappingSaved}
                                        open={!this.necessaryFieldsMapped(entity)}
                                    />
                                </Backdrop>
                            )
                        );
                    })
                )}
                {Array.from(this.state.loadingEntities)
                    .filter((loadingEntity) => !this.state.entities.find((entity) => entity.name === loadingEntity[0]))
                    .map((loadingEntity) => {
                        const apiName = loadingEntity[0];
                        const label = loadingEntity[1];
                        return (
                            <Backdrop key={apiName} loading style={{ minHeight: 'unset', marginBottom: 5 }}>
                                <SSUEntityDummy label={label} apiName={apiName} />
                            </Backdrop>
                        );
                    })}
                {this.state.addObjectFormModal && (
                    <FormDialog
                        title={this.props.t('ssu.entities.add_object')}
                        onSave={this.handleAddObjectFormSave}
                        onCancel={this.handleAddObjectFormCancel}
                        saveButtonTitle={this.props.t('ok')}
                        maxWidth="md"
                        dialogClassName="c-geo-mapper"
                    >
                        <SearchableEntitiesListForm
                            accountId={this.account.id}
                            dataSourceId={this.state.dataSource.id}
                            entities={this.state.entities || []}
                        />
                    </FormDialog>
                )}
            </div>
        );
    }
}

SSUEntities.propTypes = {
    entityManager: PropTypes.object.isRequired,
    onReady: PropTypes.func,
    onProcessing: PropTypes.func,
    onSuccess: PropTypes.func,
    onWarning: PropTypes.func,
    onError: PropTypes.func,
};

class SSUEntity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAddressField: null,
            editAddressFieldMode: false,
            isGeoMapperOpen: false,
            expanded: this.props.open,
            warningDialogOpen: false,
            tempGeoMapping: null,
            tempGeoDiffFields: null,
            warningPinAddressDialogOpen: false,
            selectPinAddress: null,
        };
    }

    handleDelete = (e) => {
        const { onEntityChecked, entity } = this.props;
        let isIncluded = false;
        onEntityChecked(entity, isIncluded);
    };

    handleNameChanged = (event, fields) => {
        const { onEntityNameChanged, entity } = this.props;
        const values = fields?.map((field) => field.apiName);
        onEntityNameChanged(entity, values);
    };

    handleAddressChanged = (field, confirm) => {
        if (confirm || !field || field?.address) {
            const { onEntityAddressChanged, entity } = this.props;
            onEntityAddressChanged(entity, field);
            return;
        }

        this.setState({
            warningPinAddressDialogOpen: true,
            selectPinAddress: field,
        });
    };

    handleWarningPinAddressDialogConfirmed = () => {
        this.handleAddressChanged(this.state.selectPinAddress, true);
        this.handleWarningPinAddressDialogClosed();
    };

    handleWarningPinAddressDialogClosed = () => {
        this.setState({
            warningPinAddressDialogOpen: false,
            selectPinAddress: null,
        });
    };

    handleOpenAddressBuilder = () => {
        this.setState({
            editAddressFieldMode: true,
        });
    };

    handleCloseAddressBuilder = () => {
        this.setState({
            editAddressFieldMode: false,
        });
    };

    handleSaveAddress = (field) => {
        const { onEntityAddressChanged, entity } = this.props;
        this.setState(
            {
                currentAddressField: field,
                editAddressFieldMode: false,
            },
            () => {
                onEntityAddressChanged(entity, field);
            },
        );
    };

    handleDeleteAddressField = () => {
        const { currentAddressField } = this.state;
        this.setState(
            {
                currentAddressField: null,
                editAddressFieldMode: false,
            },
            () => {
                this.props.onDeleteCustomField(this.props.entity, currentAddressField);
            },
        );
    };

    handleOpenGeoMapper = () => {
        this.setState({
            isGeoMapperOpen: true,
        });
    };

    handleCloseGeoMapper = () => {
        this.setState({
            isGeoMapperOpen: false,
        });
    };

    handleGeoMapperSaved = (mapping, diffFields) => {
        if (!this.state.warningDialogOpen && diffFields.length > 0) {
            this.setState({
                warningDialogOpen: true,
                tempGeoMapping: mapping,
                tempGeoDiffFields: diffFields,
            });
            return;
        }

        this.setState(
            {
                isGeoMapperOpen: false,
            },
            () => {
                this.props.onGeoMapperSaved(this.props.entity, mapping);
            },
        );
    };

    handleGeoMapperSavedWithConfirmation = () => {
        const mapping = this.state.tempGeoMapping;
        this.setState(
            {
                isGeoMapperOpen: false,
                warningDialogOpen: false,
                tempGeoMapping: null,
                tempGeoDiffFields: null,
            },
            () => {
                this.props.onGeoMapperSaved(this.props.entity, mapping);
            },
        );
    };

    handleExpand = (event, expanded) => {
        if (expanded && !this.props.entity.isIncluded) {
            return;
        }
        this.setState({
            expanded,
        });
    };

    getAvailableAddressFields = (fields) => {
        return fields
            .filter((field) => field.lookupData && field.isSuitableForAddress)
            .concat(fields.filter((field) => !field.lookupData && field.isSuitableForAddress));
    };

    getAvailableNameFields = memoize((fields) => {
        return fields.filter((field) => {
            if (field.isVirtual) {
                return false;
            }
            if (field.lookupData === null) {
                return true;
            }

            const match = field.apiName.match(/_(ID|NAME|TYPE)$/);
            return match === null || match[1] === 'ID';
        });
    });

    render() {
        const { entity, t } = this.props;
        const { currentAddressField } = this.state;

        const availableAddressFields = this.getAvailableAddressFields(entity.fields);
        const availableNameFields = this.getAvailableNameFields(entity.fields);

        let errors = new Map();
        if (this.props.errors) {
            errors = new Map(Object.entries(this.props.errors));
        }

        const addressField = this.getAddressField();

        const currentNameFields = [];
        entity.nameFields.forEach((nameApiName) => {
            const field = availableNameFields.find((field) => nameApiName === field.apiName);
            if (field) {
                currentNameFields.push(field);
            }
        });
        const availableCurrentAddressField = availableAddressFields.find(
            (field) => field.apiName === addressField?.apiName,
        );

        const latLngTitle = entity.useLatLngFields
            ? t('signup.entities.using_lat_lng')
            : t('signup.entities.using_address');

        return (
            <Accordion className="c-signup-entity" expanded={this.state.expanded} onChange={this.handleExpand}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{ disabled: !entity.isIncluded }}
                    style={{ padding: '0 24px 0 12px', width: '100%' }}
                >
                    <Grid container alignItems="center">
                        <Grid item sm={10}>
                            <div style={{ fontSize: '0.875em', padding: '12px' }}>
                                {entity.label} <span className="api-name">{entity.name}</span>
                            </div>
                        </Grid>
                        <Grid item sm={2} style={{ textAlign: 'right' }}>
                            <IconButton
                                component="span"
                                onClick={() => {
                                    this.handleDelete(entity);
                                }}
                                data-testid="signup.entities.delete"
                            >
                                <i
                                    style={{
                                        fontSize: '16px',
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        color: red[500],
                                    }}
                                    className={'fas fa-trash'}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                {entity.isIncluded && (
                    <AccordionDetails style={{ display: 'block' }}>
                        <Grid container>
                            <Grid item sm={6} style={{ paddingRight: 8, marginBottom: 8 }}>
                                <div style={{ paddingRight: 25, position: 'relative' }}>
                                    <FormControl fullWidth error={false} className="address-field">
                                        <InputLabel shrink>{t('signup.entities.address_field')}</InputLabel>
                                        <Autocomplete
                                            options={availableAddressFields}
                                            value={availableCurrentAddressField || null}
                                            disabled={currentAddressField !== null}
                                            getOptionLabel={trimLookupIdLabel}
                                            groupBy={(field) =>
                                                field.lookupData
                                                    ? t('data_source.settings.pin_address.lookup_fields_group_name')
                                                    : t('data_source.settings.pin_address.non_lookup_fields_group_name')
                                            }
                                            onChange={(_, value) => this.handleAddressChanged(value, false)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    data-testid="signup.entities.address_field"
                                                    placeholder={t('undefined_picklist_option')}
                                                    margin="normal"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <div style={{ position: 'absolute', right: 0, top: 20 }}>
                                        <Tooltip title={t('signup.entities.address_field_hint')}>
                                            <i className="fas fa-question-circle" />
                                        </Tooltip>
                                    </div>
                                    <div className="custom-address-toolbar">
                                        <span onClick={this.handleOpenAddressBuilder} className="open-dialog-button">
                                            <Icon>{currentAddressField !== null ? 'edit' : 'add'}</Icon>
                                            <span className="button-title">
                                                {currentAddressField !== null
                                                    ? t('signup.entities.edit_address_field')
                                                    : t('signup.entities.new_address_field')}
                                            </span>
                                        </span>
                                        {currentAddressField !== null && (
                                            <IconButton
                                                color="secondary"
                                                onClick={this.handleDeleteAddressField}
                                                style={{ padding: 2 }}
                                                data-testid="signup.entities.delete_address_field"
                                            >
                                                <Icon fontSize="small">close_icon</Icon>
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={5} style={{ paddingLeft: 8 }}>
                                <div style={{ paddingRight: 25, position: 'relative' }}>
                                    <FormControl fullWidth error={errors.has('nameFields')} className="name-fields">
                                        <InputLabel shrink>{t('signup.entities.name_fields')}</InputLabel>
                                        <SingleRowMultipleAutocomplete
                                            options={availableNameFields}
                                            value={currentNameFields}
                                            getOptionLabel={trimLookupIdLabel}
                                            size="small"
                                            disableClearable={true}
                                            onChange={this.handleNameChanged}
                                            renderOption={(field) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={entity.nameFields.includes(field.apiName)}
                                                    />
                                                    {trimLookupIdLabel(field)}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={
                                                        entity.nameFields.length === 0
                                                            ? t('undefined_picklist_option')
                                                            : ''
                                                    }
                                                    margin="normal"
                                                />
                                            )}
                                        />
                                        {errors.has('nameFields') && (
                                            <FormHelperText>{errors.get('nameFields')}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <div style={{ position: 'absolute', right: 0, top: 20 }}>
                                        <Tooltip title={t('signup.entities.name_fields_hint')}>
                                            <i className="fas fa-question-circle" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={1}>
                                <Tooltip title={latLngTitle}>
                                    <IconButton
                                        color="primary"
                                        onClick={this.handleOpenGeoMapper}
                                        className="lat-lng-button"
                                        data-testid="signup.entities.open_geo_mapper"
                                    >
                                        {entity.useLatLngFields ? (
                                            <span className="lat-lng-fields">
                                                <Icon className="fas fa-crosshairs" fontSize="small" />
                                                <Icon className="fas fa-check" fontSize="small" />
                                            </span>
                                        ) : (
                                            <Icon
                                                className="fas fa-crosshairs"
                                                style={{ overflow: 'visible' }}
                                                fontSize="small"
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
                {this.state.editAddressFieldMode && (
                    <FormDialog
                        title={t('data_source.ds_entity.address_field_mapper.title')}
                        onSave={this.handleSaveAddress}
                        onCancel={this.handleCloseAddressBuilder}
                        PaperProps={{ style: { width: 400 } }}
                    >
                        <AddressForm
                            addressField={this.state.currentAddressField || this.constructor.createAddressField()}
                            entity={entity}
                        />
                    </FormDialog>
                )}
                {this.state.isGeoMapperOpen && (
                    <FormDialog
                        title={this.props.t('geomapper.modal.title')}
                        onSave={this.handleGeoMapperSaved}
                        onCancel={this.handleCloseGeoMapper}
                        maxWidth="md"
                        dialogClassName="c-geo-mapper"
                    >
                        <GeoMapperForm entity={entity} dataSource={this.props.dataSource} />
                    </FormDialog>
                )}
                {this.state.warningDialogOpen && (
                    <WarningOverwriteFieldsDialog
                        onSave={this.handleGeoMapperSavedWithConfirmation}
                        onCancel={this.handleWarningDialogCancel}
                        fields={this.state.tempGeoDiffFields}
                    />
                )}
                {this.state.warningPinAddressDialogOpen && (
                    <WarningProbablyPartialAddressFieldDialog
                        onSave={this.handleWarningPinAddressDialogConfirmed}
                        onCancel={this.handleWarningPinAddressDialogClosed}
                    />
                )}
            </Accordion>
        );
    }

    handleWarningDialogCancel = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    getAddressField() {
        for (let field of this.props.entity.fields) {
            if (field.isPin) {
                return field;
            }
        }
        return null;
    }

    static createAddressField() {
        const getRandomInt = (min, max) => {
            return Math.floor(Math.random() * (max - min)) + min;
        };

        return {
            id: 0,
            apiName: 'a' + new Date().getTime() + getRandomInt(100, 1000),
            label: '',
            type: 'text',
            address: {},
            picklist: null,
            lookup: null,
            default: null,
            isIncluded: true,
            isCustom: true,
            isPin: false,
        };
    }
}

SSUEntity.propTypes = {
    autoload: PropTypes.bool,
    entity: PropTypes.object.isRequired,
    dataSource: PropTypes.object.isRequired,
    errors: PropTypes.object,
    onEntityAddressChanged: PropTypes.func.isRequired,
    onEntityNameChanged: PropTypes.func.isRequired,
    onEntityChecked: PropTypes.func.isRequired,
    onDeleteCustomField: PropTypes.func.isRequired,
};

const TranslatedSSUEntity = withTranslation('translations', { withRef: true })(SSUEntity);

class SSUEntityDummy extends React.PureComponent {
    render() {
        const { label, apiName } = this.props;

        return (
            <Accordion className="c-signup-entity">
                <AccordionSummary
                    disabled
                    IconButtonProps={{ disabled: true }}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ padding: '0 24px 0 12px', width: '100%' }}
                >
                    <Grid container alignItems="center">
                        <Grid item sm={10}>
                            <div style={{ fontSize: '0.875em', padding: '12px' }}>
                                {label} <span className="api-name">{apiName}</span>
                            </div>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }} />
            </Accordion>
        );
    }
}

SSUEntities.defaultProps = {
    autoload: false,
};

export default withTranslation('translations', { withRef: true })(SSUEntities);
