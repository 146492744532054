import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import dispatcher from '../../service/dispatcher';
import events from '../../events';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import { removeProtocol } from '../utils/webLinkParser';
import { weAreInIosNativeApp, weAreInNativeApp } from '../../utils';
import InAppBrowserManager from '../../service/InAppBrowserManager';
import config from '../../params';

const MOBILE_SIZE = 500;
const TITLE_SIZE = 30;
const DEFAULT_SIZE_IFRAME = 500;

class WebLinkIframe extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
            width: null,
            height: null,
            loading: false,
        };
    }

    componentDidMount() {
        dispatcher.subscribe(events.EVENT_OPENING_WEB_LINK_IFRAME, this, (props) => {
            if (props.url === undefined) {
                return;
            }

            let parsedUrl = new URL(props.url);

            if (weAreInNativeApp()) {
                if (parsedUrl.host === config.frontAppHost) {
                    window.location.href = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
                    return;
                }

                if (weAreInIosNativeApp()) {
                    InAppBrowserManager.openBrowser(props.url, { toolbar: 'yes' });
                    return;
                }
            }

            const width = props.width !== undefined ? props.width : DEFAULT_SIZE_IFRAME;
            const height = props.height !== undefined ? props.height : DEFAULT_SIZE_IFRAME;
            const url = '//' + removeProtocol(props.url);
            this.setState({
                width,
                height,
                url,
                loading: true,
            });
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    handleOnLoad = () => {
        this.setState({
            loading: false,
        });
    };

    handleCloseDialog = () => {
        this.setState({
            url: null,
        });
    };

    render() {
        if (weAreInIosNativeApp()) {
            return null;
        }

        const url = this.state.url;
        const loading = this.state.loading;
        const isMobile = MOBILE_SIZE > document.body.clientWidth;
        let width = this.state.width > document.body.clientWidth ? document.body.clientWidth : this.state.width;
        let height =
            this.state.height > document.body.clientHeight
                ? document.body.clientHeight - TITLE_SIZE
                : this.state.height;

        if (isMobile) {
            height = document.body.clientHeight - TITLE_SIZE;
            width = document.body.clientWidth;
        }

        let iframeStyle = {
            width,
            height,
            border: 'none',
            borderRadius: 8,
        };

        if (loading) {
            iframeStyle.display = 'none';
        }

        return (
            <Dialog
                open={url !== null}
                maxWidth={false}
                fullScreen={isMobile}
                PaperProps={{
                    style: {
                        margin: 0,
                        backgroundColor: 'unset',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogTitle className="iframe-dialog-title">
                    <Button
                        className="iframe-dialog-title-close"
                        onClick={this.handleCloseDialog}
                        data-testid="weblink_iframe.button.close"
                    >
                        close
                        <Icon>close_icon</Icon>
                    </Button>
                </DialogTitle>
                <div style={{ display: 'flex', backgroundColor: 'white', borderRadius: 8 }}>
                    {loading && (
                        <Grid container justify="center" alignItems="center" style={{ width, height }}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                    {url && <iframe title={url} style={iframeStyle} src={url} onLoad={this.handleOnLoad} />}
                </div>
            </Dialog>
        );
    }
}

export default WebLinkIframe;
