import React, { ChangeEvent } from 'react';
import { pipe } from 'rxjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import {
    currentUserDate,
    DATETIME_FORMAT_FOR_EXPORT,
    filterInputCharactersStringToMatchType,
    formatDateTimeForPicker,
    formatDateToDefault,
    propertyIdOrNameToType,
    propertyIdToName,
    propertyNameToId,
    userTimezoneToUtc,
} from '../../../utils';
import {
    FieldLookupData,
    FieldLookupType,
    FieldPolymorphicLookupData,
    FieldType,
    IField,
    MultiPicklistValue,
    PicklistValue,
} from '../../types';
import PicklistField, { ChangeSelectTargetEvent } from './PicklistField';
import BooleanField from './BooleanField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import DefaultField from './DefaultField';
import FewLookupField, { FewLookupFieldProps } from './FewLookupField';
import LookupField from './LookupField';
import MultiPickListField from './MultiPickListField';
import UniversalLookupField, { UniversalLookupReturnValue } from './UniversalLookupField';
import { userManager } from '../../../service/UserManager';
import FileCollection from '../../FileInput/FileCollection';
import { MapslyFile } from 'interfaces/file';
import FieldTagsInput from '../../FieldTagsInput/FieldTagsInput';
import { emailValidate } from '../../../service/emailValidator';

export const defaultValue = (type: FieldType, picklist: MultiPicklistValue[] | PicklistValue[]) => {
    switch (type) {
        case FieldType.TEXT:
        case FieldType.STRING:
        case FieldType.INTEGER:
        case FieldType.BIGINT:
        case FieldType.FLOAT:
            if (picklist) {
                return null;
            }
            return '';
        case FieldType.BOOLEAN:
            return false;
        case FieldType.DATE:
        case FieldType.DATETIME:
            return formatDateToDefault(currentUserDate(userManager.getCurrentUser()));
        case FieldType.JSON_ARRAY:
        case FieldType.JSON:
        case FieldType.STRINGS:
        case FieldType.INTEGERS:
        case FieldType.FILES:
        case FieldType.EMAILS:
            return [];
        default:
            throw new Error(`Unexpected field type [${type}].`);
    }
};

export type FieldFactoryValueObject = {
    textValue: string;
};

type FieldFactoryPolymorphicValue = {
    apiName: string;
};

interface FieldFactoryProps extends WithTranslation {
    accountId: number;
    dataSourceId: number;
    value: null | string | number | boolean | string[] | number[] | ParsableDate | FieldFactoryValueObject;
    error?: string;
    onChange: (
        /** Record of updated fields values. Field can update multiple fields. */
        value: Record<
            string,
            | string
            | string[]
            | number
            | boolean
            | FieldFactoryPolymorphicValue
            | UniversalLookupReturnValue
            | MapslyFile[]
            | { apiName: string; value: string }
            | null
        >,
    ) => void;
    field: IField;
    values: Record<string, any>;
    updates: Record<string, any>;

    disabled: boolean;
    autoFocus: boolean;
    isSearchOfLabelInPicklist: boolean;
    onFieldLoading?: (loading: boolean) => void;
    onEmailValidate?: (emails: string[]) => void;
}

class FieldFactory extends React.PureComponent<FieldFactoryProps> {
    static defaultProps = {
        disabled: false,
        autoFocus: true,
        isSearchOfLabelInPicklist: false,
    };

    handleSimpleChange = (value: any) => {
        this.props.onChange({ [this.props.field.apiName]: value });
    };

    onChangeText = (event: ChangeEvent<{ value: string; defaultValue: string }>) => {
        this.handleSimpleChange(
            filterInputCharactersStringToMatchType(
                event.currentTarget.value,
                this.props.field.type,
                event.currentTarget.defaultValue,
            ),
        );
    };

    onChangeMultilineText = (event: ChangeEvent<{ value: string }>) => {
        const { lookupData } = this.props.field;
        const value = event.currentTarget.value.split('\n');
        this.handleSimpleChange(lookupData ? value.filter((i) => i) : value);
    };

    onChangeDateTime = (date: MaterialUiPickersDate) => {
        this.handleSimpleChange(
            date
                ? userTimezoneToUtc(formatDateToDefault(date), userManager.getCurrentUser(), DATETIME_FORMAT_FOR_EXPORT)
                : null,
        );
    };

    onChangeDate = (date: string | null) => {
        this.handleSimpleChange(date);
    };

    onChangeBool = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.handleSimpleChange(event.currentTarget.checked);
    };

    onChangeSelect = (event: ChangeSelectTargetEvent) => {
        this.handleSimpleChange(event.target.value);
    };

    onChangeFewLookup: FewLookupFieldProps['onChange'] = (value) => {
        const idApiName = propertyNameToId(this.props.field.apiName);
        const valueApiName = propertyIdToName(this.props.field.apiName);

        this.props.onChange({
            [this.props.field.apiName]: value.map((it) => it.id),
            [idApiName]: value.map((it) => it.id),
            [valueApiName]: value.map((it) => it.title),
        });
    };

    onChangeUniversalLookup = (value: UniversalLookupReturnValue) => {
        const { data } = value;

        this.props.onChange({
            [this.props.field.apiName]: value,
            [data.typeFieldApiName]: {
                apiName: data.typeFieldApiName,
                value: JSON.stringify({
                    entity_api_name: data.entityApiName,
                    data_source_api_name: data.dataSourceApiName,
                }),
            },
        });
    };

    render() {
        const { t, field, error, disabled, autoFocus, onFieldLoading, values } = this.props;
        const value = this.props.value ?? values?.[field.apiName];

        switch (field.type) {
            case FieldType.TEXT:
            case FieldType.STRING:
            case FieldType.INTEGER:
            case FieldType.BIGINT:
            case FieldType.FLOAT:
                if (null !== field.lookupData && field.lookupData.type === FieldLookupType.UNIVERSAL) {
                    return this.renderUniversalLookup();
                }

                if (null !== field.lookupData) {
                    return this.renderAutoComplete(field.lookupData);
                }

                if (field.picklist) {
                    return this.renderPickList();
                }

                return (
                    <DefaultField
                        name={field.apiName}
                        label={field.originalLabel}
                        value={(value as string | number) ?? ''}
                        length={field.length}
                        error={error}
                        onChangeText={this.onChangeText}
                        autoFocus={autoFocus}
                        disabled={disabled}
                    />
                );

            case FieldType.BOOLEAN:
                return (
                    <BooleanField
                        value={value as boolean}
                        name={field.apiName}
                        label={field.originalLabel}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        onChangeBool={this.onChangeBool}
                    />
                );

            case FieldType.DATE:
                return (
                    <DateField
                        error={error}
                        label={field.originalLabel}
                        value={value as ParsableDate}
                        onChangeDate={this.onChangeDate}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        clearable={true}
                    />
                );

            case FieldType.DATETIME:
                const pickerValue = value
                    ? formatDateTimeForPicker(value as string, userManager.getCurrentUser())
                    : value;
                return (
                    <DateTimeField
                        error={error}
                        label={field.originalLabel}
                        value={pickerValue as ParsableDate}
                        onChangeDateTime={this.onChangeDateTime}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        clearable={true}
                    />
                );

            case FieldType.JSON_ARRAY:
                return this.renderMultiPickList();

            case FieldType.JSON:
                if (null !== field.lookupData) {
                    return this.renderAutoComplete(field.lookupData);
                }

                return this.renderMultiPickList();

            case FieldType.STRINGS: {
                if (null !== field.lookupData && field.lookupData.type === FieldLookupType.FEW) {
                    return this.renderFewLookup();
                }

                return this.renderMultiPickList();
            }

            case FieldType.EMAILS:
                return (
                    <FieldTagsInput
                        value={value ?? values?.[field.apiName] ?? []}
                        label={field.originalLabel}
                        disabled={disabled}
                        tagValidate={(tag: string, _: string[]): string =>
                            !emailValidate(tag) ? t('validation_errors') : ''
                        }
                        separators={[';', ',']}
                        onChange={(tags: string[]) => {
                            this.props.onEmailValidate && this.props.onEmailValidate(tags);
                            this.handleSimpleChange(tags);
                        }}
                    ></FieldTagsInput>
                );

            case FieldType.FILES:
                return (
                    <FileCollection
                        accountId={this.props.accountId}
                        files={value as MapslyFile[]}
                        size="small"
                        onChange={this.handleSimpleChange}
                        onUploadProgress={(inProgress) => onFieldLoading && onFieldLoading(inProgress)}
                    />
                );
        }
    }

    renderPickList() {
        const { value, field, disabled, autoFocus, isSearchOfLabelInPicklist, values } = this.props;

        const nextValue = value ?? values?.[field.apiName] ?? '';

        return (
            <PicklistField
                value={nextValue}
                name={field.apiName}
                label={field.originalLabel}
                disabled={disabled}
                autoFocus={autoFocus}
                onChangeSelect={this.onChangeSelect}
                picklist={field.picklist! as PicklistValue[]}
                isSearchOfLabelInPicklist={isSearchOfLabelInPicklist}
            />
        );
    }

    renderMultiPickList() {
        const { value, field, disabled, autoFocus, values } = this.props;

        const nextValue = value ?? values?.[field.apiName] ?? [];

        return (
            <MultiPickListField
                value={nextValue}
                disabled={disabled}
                autoFocus={autoFocus}
                picklist={field.picklist! as MultiPicklistValue[]}
                label={field.originalLabel}
                name={field.apiName}
                onChangeMultilineText={this.onChangeMultilineText}
                onChangeSelect={this.onChangeSelect}
            />
        );
    }

    renderFewLookup() {
        const { values, updates, field, disabled, accountId, dataSourceId, autoFocus } = this.props;

        const nextValues = Object.assign({}, values, updates);

        return (
            <FewLookupField
                accountId={accountId}
                dataSourceId={dataSourceId}
                field={field}
                values={nextValues}
                disabled={disabled}
                autoFocus={autoFocus}
                onChange={this.onChangeFewLookup}
            />
        );
    }

    renderAutoComplete(lookupData: FieldLookupData | FieldPolymorphicLookupData) {
        const { accountId, dataSourceId, autoFocus, disabled, field, value, values, updates } = this.props;

        const apiName = field.apiName ?? '';
        const idApiName = propertyNameToId(apiName);
        const valueApiName = propertyIdToName(apiName);
        const typeApiName = pipe(
            () => propertyIdOrNameToType(apiName),
            // typeApiName must be different from original apiName, otherwise return null
            (it) => (it === field.apiName ? null : it),
        )('');

        const valueId =
            // if we updated record but didn't save to api
            updates?.[field.apiName]?.value ??
            // for untouched state
            values?.[idApiName] ??
            (typeof value === 'object' ? (value as any)?.id : undefined);

        const valueType = values?.[typeApiName ?? ''];

        const nextValue = (() => {
            if (typeof value === 'string') {
                return value;
            }
            if ((value as FieldFactoryValueObject)?.textValue) {
                return (value as FieldFactoryValueObject).textValue;
            }
            if (values?.[valueApiName]) {
                return values?.[valueApiName];
            }
            return null;
        })();

        return (
            <LookupField
                accountId={accountId}
                dataSourceId={dataSourceId}
                field={field}
                name={field.apiName}
                label={field.originalLabel}
                value={nextValue}
                valueId={valueId}
                disabled={disabled}
                autoFocus={autoFocus}
                lookupData={lookupData as FieldPolymorphicLookupData}
                onChange={this.handleSimpleChange}
                recordId={values?.id}
                selectedEntity={(value as any)?.apiName ?? valueType}
            />
        );
    }

    renderUniversalLookup() {
        const { accountId, autoFocus, disabled, field, value, values, updates } = this.props;
        const { lookupData } = field;

        const valueId =
            // if we updated record but didn't save to api
            updates?.[field.apiName]?.value ??
            // for untouched state
            values?.[propertyNameToId(field.name)];

        return (
            <UniversalLookupField
                accountId={accountId}
                field={field}
                name={field.apiName}
                label={field.originalLabel}
                value={typeof value === 'string' ? value : value ? (value as FieldFactoryValueObject).textValue : null}
                valueId={valueId}
                disabled={disabled}
                autoFocus={autoFocus}
                lookupData={lookupData as unknown as FieldPolymorphicLookupData}
                onChange={this.onChangeUniversalLookup}
                recordId={values?.id}
            />
        );
    }
}

export default withTranslation('translations', { withRef: true })(FieldFactory);
