import BackendService from 'api/BackendService';
import apiRoutes, { reverse } from 'api/apiRoutes';
import { Api, Entity } from '../interfaces';
import dispatcher from './dispatcher';
import events from '../events';

class SimpleEntityManager extends BackendService {
    private entities: Map<number, Map<string, Entity.NormalizedEntity>> = new Map();

    constructor() {
        super();

        dispatcher.subscribe(events.WS_DS_METADATA_IMPORT, this, (payload: { changesEntities: number[] }) => {
            for (const entityId of payload.changesEntities) {
                this.entities.delete(entityId);
            }
        });
    }

    get(
        entityId: number,
        filterSystemFields: boolean = false,
        includeInactiveFields: boolean = false,
        force: boolean = false,
    ): Promise<Api.Entity.GetEntityResponse> {
        const key = [+filterSystemFields, +includeInactiveFields].join('_');
        if (!force && this.entities.has(entityId) && this.entities.get(entityId)!.has(key)) {
            return Promise.resolve(this.entities.get(entityId)!.get(key)!);
        }

        const url = reverse(apiRoutes.entity, { entityId });
        return this.requestApi(url, 'GET', { filterSystemFields, includeInactiveFields }).then(
            (entity: Entity.NormalizedEntity) => {
                if (this.entities.has(entityId)) {
                    this.entities.get(entityId)!.set(key, entity);
                } else {
                    this.entities.set(entityId, new Map([[key, entity]]));
                }
                return entity;
            },
        );
    }
}

export const entityManager = new SimpleEntityManager();
