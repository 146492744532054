import React from 'react';
import { accountsManager } from '../../service/AccountsManager';
import './style.css';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class AccountStatusForm extends React.Component {
    submit() {
        const { onSubmitSuccess, onSubmitError, account } = this.props;
        if (account.deleted) {
            accountsManager
                .restore(account.id)
                .then((account) => onSubmitSuccess && onSubmitSuccess(account))
                .catch((error) => onSubmitError && onSubmitError(error.message));
            return;
        }
        accountsManager
            .delete(account.id)
            .then((account) => onSubmitSuccess && onSubmitSuccess(account))
            .catch((error) => onSubmitError && onSubmitError(error.message));
    }

    render() {
        const { t } = this.props;
        return (
            <form noValidate autoComplete="off" className="c-account-main-form">
                {this.props.account.deleted ? t('account.enable.message') : t('account.disable.message')}
            </form>
        );
    }
}

AccountStatusForm.propTypes = {
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    account: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(AccountStatusForm);
