import React from 'react';
import { accountsManager } from '../../service/AccountsManager';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

class AccountChangeTest extends React.Component {
    submit() {
        const { onSubmitSuccess, onSubmitError, account, enqueueSnackbar, t } = this.props;
        accountsManager
            .changeTest(account.id)
            .then((data) => {
                enqueueSnackbar(t('account.test.changed'), { variant: 'success' });
                onSubmitSuccess && onSubmitSuccess();
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
                onSubmitError && onSubmitError();
            });
    }

    render() {
        const { account, t } = this.props;
        return (
            <form noValidate autoComplete="off">
                {account.isTest ? t('account.test.change_to_real') : t('account.test.change_to_test')}
            </form>
        );
    }
}

AccountChangeTest.propTypes = {
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    account: PropTypes.object.isRequired,
};

export default withTranslation('translations', { withRef: true })(withSnackbar(AccountChangeTest));
