import {
    bg,
    cs,
    de,
    el,
    enUS,
    es,
    et,
    fr,
    hr,
    hu,
    it,
    lt,
    lv,
    nl,
    pl,
    ptBR,
    ro,
    ru,
    sk,
    sl,
    sq,
    uk,
} from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const LANGUAGE_EN = 'en';
export const LANGUAGE_RU = 'ru';
export const LANGUAGE_ES = 'es';
export const LANGUAGE_DE = 'de';
export const LANGUAGE_FR = 'fr';
export const LANGUAGE_IT = 'it';
export const LANGUAGE_PT_BR = 'pt-BR';
export const LANGUAGE_BG = 'bg';
export const LANGUAGE_CS = 'cs';
export const LANGUAGE_EL = 'el';
export const LANGUAGE_ET = 'et';
export const LANGUAGE_HR = 'hr';
export const LANGUAGE_HU = 'hu';
export const LANGUAGE_LT = 'lt';
export const LANGUAGE_LV = 'lv';
export const LANGUAGE_NL = 'nl';
export const LANGUAGE_PL = 'pl';
export const LANGUAGE_RO = 'ro';
export const LANGUAGE_SK = 'sk';
export const LANGUAGE_SL = 'sl';
export const LANGUAGE_SQ = 'sq';
export const LANGUAGE_UK = 'uk';


export const LANGUAGES = [
    LANGUAGE_EN,
    LANGUAGE_RU,
    LANGUAGE_ES,
    LANGUAGE_DE,
    LANGUAGE_FR,
    LANGUAGE_IT,
    LANGUAGE_PT_BR,
    LANGUAGE_BG,
    LANGUAGE_CS,
    LANGUAGE_EL,
    LANGUAGE_ET,
    LANGUAGE_HR,
    LANGUAGE_HU,
    LANGUAGE_LT,
    LANGUAGE_LV,
    LANGUAGE_NL,
    LANGUAGE_PL,
    LANGUAGE_RO,
    LANGUAGE_SK,
    LANGUAGE_SL,
    LANGUAGE_SQ,
    LANGUAGE_UK,
];

const LazyImportPlugin = {
    type: 'backend',
    init: function (services, backendOptions, i18nextOptions) {},
    read: function (language, namespace, callback) {
        import(`./${language}.json`)
            .catch(() => {
                return import(`./en.json`);
            })
            .then((obj) => {
                callback(null, obj);
            });
    },

    save: function (language, namespace, data) {},

    create: function (languages, namespace, key, fallbackValue) {
        /* save the missing translation */
    },
};

i18n.use(LanguageDetector)
    .use(LazyImportPlugin)
    .use(initReactI18next)
    .init({
        debug: true,
        load: 'currentOnly',
        fallbackLng: 'en', // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        ns: ['translations'],
        defaultNS: 'translations',
        react: {
            wait: true,
        },
        detection: {
            lookupLocalStorage: 'mapsly_language',
        },
    });

export default i18n;

const dateFnsLocales = {
    LANGUAGE_EN: enUS,
    LANGUAGE_RU: ru,
    LANGUAGE_ES: es,
    LANGUAGE_DE: de,
    LANGUAGE_FR: fr,
    LANGUAGE_IT: it,
    LANGUAGE_PT_BR: ptBR,
    LANGUAGE_BG: bg,
    LANGUAGE_CS: cs,
    LANGUAGE_EL: el,
    LANGUAGE_ET: et,
    LANGUAGE_HR: hr,
    LANGUAGE_HU: hu,
    LANGUAGE_LT: lt,
    LANGUAGE_LV: lv,
    LANGUAGE_NL: nl,
    LANGUAGE_PL: pl,
    LANGUAGE_RO: ro,
    LANGUAGE_SK: sk,
    LANGUAGE_SL: sl,
    LANGUAGE_SQ: sq,
    LANGUAGE_UK: uk,
};
export const dateFnsLocale = dateFnsLocales[i18n.language];
