import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import subscriptionManagerFactory from '../../service/SubscriptionManager';
import React from 'react';
import LoadingButton from '../LoadingButton';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { formatDateTimeForPicker, formatDateToDefault, userTimezoneToUtc } from '../../utils';
import cloneDeep from 'lodash/cloneDeep';
import { withTranslation } from 'react-i18next';
import PureFormDialog from 'components/PureFormDialog';
import { SubscriptionStatus } from '../../service/types';
import ByteField from './ByteField';
import { FormAlert, FormBackdrop } from 'components/PureFormDialog/Form';
import isEqual from 'lodash/isEqual';
import AiTokensSubscriptionEditForm from './AiTokens/AiTokensSubscriptionEditForm';
import { FormLabel } from '@material-ui/core';
import RoutingCreditSlider from './RoutingCredits/RoutingCreditSlider';
import TranscriptionMinutesSubscriptionEditForm from './TranscriptionMinutes/TranscriptionMinutesSubscriptionEditForm';

const STATUS_RULES = {
    [SubscriptionStatus.TRIAL]: [SubscriptionStatus.FREE, SubscriptionStatus.TRIAL, SubscriptionStatus.CUSTOM],
    [SubscriptionStatus.FREE]: [SubscriptionStatus.FREE, SubscriptionStatus.TRIAL, SubscriptionStatus.CUSTOM],
    [SubscriptionStatus.CANCELLED]: [SubscriptionStatus.FREE, SubscriptionStatus.TRIAL, SubscriptionStatus.CANCELLED],
    [SubscriptionStatus.DELETED]: [SubscriptionStatus.FREE, SubscriptionStatus.TRIAL, SubscriptionStatus.DELETED],
    [SubscriptionStatus.CUSTOM]: [SubscriptionStatus.CUSTOM, SubscriptionStatus.FREE, SubscriptionStatus.TRIAL],
};

const CURRENCIES = [
    'ARS',
    'AUD',
    'BRL',
    'CAD',
    'CHF',
    'CNY',
    'CZK',
    'DKK',
    'EUR',
    'GBP',
    'HKD',
    'HUF',
    'INR',
    'JPY',
    'KRW',
    'MXN',
    'NOK',
    'NZD',
    'PLN',
    'RUB',
    'SEK',
    'SGD',
    'THB',
    'TWD',
    'USD',
    'ZAR',
];

class SubscriptionEditForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            subscription: null,
            errorMessage: undefined,
            errors: new Map(),
            openConfirmRefCodChange: false,
            subscriptionHasConversion: null,
        };
    }

    componentDidMount() {
        this.setState({
            subscription: this.getCurrentSubscription(),
        });
    }

    getCurrentSubscription = () => {
        return cloneDeep(this.props.subscription);
    };

    handleCloseAlert = () => {
        this.setState({
            errorMessage: undefined,
        });
    };

    handleInputChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value || null;
        const name = event.target.name;

        this.handleChangeWithValueAndName(name, value);
    };

    handleChangeWithValueAndName = (name, value) => {
        if (name === 'currency') {
            this.recalculateRecordChunkPrice(this.state.subscription, value);
        }

        this.setState((state) => {
            const subscription = { ...state.subscription };
            subscription[name] = value;

            const errors = new Map(state.errors);
            errors.delete(name);

            const errorMessage = errors.size === 0 ? undefined : state.errorMessage;

            return {
                errorMessage,
                errors,
                subscription,
            };
        });
    };

    handleInputChangeSeats = (event) => {
        const value = event.target.value || null;
        let usersAutoUpdate = cloneDeep(this.state.subscription.usersAutoUpdate);
        let errors = cloneDeep(this.state.errors);
        errors.delete('usersAllowed');

        if (value > this.state.subscription.usersPurchased) {
            usersAutoUpdate = false;
        }

        if (value < this.state.subscription.usersPurchased) {
            errors.set('usersAllowed', this.props.t('billing.edit_form.must_more_than_users_purchased'));
        }

        this.setState((state) => {
            state.subscription.usersAllowed = value;
            state.subscription.usersAutoUpdate = usersAutoUpdate;
            state.errors = errors;
            return state;
        });
    };

    handleChangeBillingDate = (nextBillDateNew) => {
        let nextBillDate = formatDateToDefault(nextBillDateNew);
        nextBillDate = userTimezoneToUtc(nextBillDate, this.props.user);
        this.setState((state) => {
            state.subscription.nextBillDate = nextBillDate;
            return state;
        });
    };

    handleChangeExpiresAt = (expiresAtNew) => {
        let expiresAt = formatDateToDefault(expiresAtNew);
        expiresAt = userTimezoneToUtc(expiresAt, this.props.user);
        this.setState((state) => {
            state.subscription.expiresAt = expiresAt;
            return state;
        });
    };

    handleChangeUserAutoUpdate = (event) => {
        const usersAutoUpdate = event.target.checked;
        this.setState((state) => {
            state.subscription.usersAutoUpdate = usersAutoUpdate;
            return state;
        });
    };

    handleChangeGeocoderFallback = (event) => {
        const geocoderFallback = event.target.checked;
        this.setState((state) => ({ subscription: { ...state.subscription, geocoderFallback } }));
    };

    handleChangeSharedMap = (e) => {
        const { name, checked } = e.target;
        this.setState((state) => ({ subscription: { ...state.subscription, [name]: checked } }));
    };

    formatSubscription(subscription) {
        const formatted = cloneDeep(subscription);

        formatted.exportProspectMonthly = parseInt(subscription.exportProspectMonthly);
        formatted.additionalCustomRoutingCredits = parseInt(subscription.additionalCustomRoutingCredits);
        formatted.adminPmCur = parseFloat(subscription.adminPmCur);
        formatted.customSharedMapsPmCur = parseFloat(subscription.customSharedMapsPmCur);
        formatted.maxMapLoadsDay = parseInt(subscription.maxMapLoadsDay);
        formatted.maxMapLoadsMonth = parseInt(subscription.maxMapLoadsMonth);
        formatted.monthlyPlannerCredits = parseInt(subscription.monthlyPlannerCredits);
        formatted.planerPmCur = parseFloat(subscription.planerPmCur);
        formatted.routingCustomPmCur = parseFloat(subscription.routingCustomPmCur);
        formatted.recordCapFree = parseInt(subscription.recordCapFree);
        formatted.recordChunkPmCur = parseFloat(subscription.recordChunkPmCur);
        formatted.recordChunkSize = parseInt(subscription.recordChunkSize);
        formatted.sharedMapsPmCur = parseFloat(subscription.sharedMapsPmCur);
        formatted.storageCapFree = parseInt(subscription.storageCapFree);
        formatted.storageChunkPmCur = parseFloat(subscription.storageChunkPmCur);
        formatted.storageChunkSize = parseInt(subscription.storageChunkSize);
        formatted.territoriesCount = parseInt(subscription.territoriesCount);
        formatted.unusedPlannerCredits = parseInt(subscription.unusedPlannerCredits);
        formatted.usersAllowed = parseInt(subscription.usersAllowed);
        formatted.prospectPmCur = parseFloat(subscription.prospectPmCur);
        formatted.locationHistoryRetentionDays = parseInt(subscription.locationHistoryRetentionDays);

        return formatted;
    }

    handleConfirmCustomize = () => {
        const { subscription } = this.state;

        const manager = subscriptionManagerFactory.getManager(subscription.accountId);
        manager
            .customizeSubscription(this.formatSubscription(subscription))
            .then(() => {
                this.setState(
                    {
                        errorMessage: undefined,
                        errors: new Map(),
                        loading: false,
                    },
                    () => {
                        this.props.onCloseDialog();
                    },
                );

                this.props.onCustomizeSuccess &&
                    this.props.onCustomizeSuccess(this.props.t('billing.edit_form.snack_bar_success'));
            })
            .catch((error) => {
                const state = {
                    errorMessage: error.message,
                    loading: false,
                };

                if (error.details) {
                    state.errors = error.details;
                }

                this.setState(state, () => {
                    this.props.onCustomizeFailure && this.props.onCustomizeFailure(error.message);
                });

                this.props.enqueueSnackbar &&
                    this.props.enqueueSnackbar(error.message, {
                        variant: 'error',
                        persist: true,
                    });
            });

        this.setState({
            errorMessage: undefined,
            errors: new Map(),
            loading: true,
            openConfirmRefCodChange: false,
        });
    };

    recalculateRecordChunkPrice = (subscription, newCurrency) => {
        const oldCurrency = subscription.currency;
        const oldPrice = subscription.plan.prices.find((price) => {
            return price.currency === oldCurrency;
        });

        const newPrice = subscription.plan.prices.find((price) => {
            return price.currency === newCurrency;
        });

        if (oldPrice.originalPriceChunk === subscription.recordChunkPmCur) {
            subscription.recordChunkPmCur = newPrice.originalPriceChunk;
            return;
        }

        const usd = subscription.recordChunkPmCur / oldPrice.rate;
        subscription.recordChunkPmCur = usd * newPrice.rate;
    };

    render() {
        const { t } = this.props;

        if (this.state.subscription === null) {
            return (
                <PureFormDialog
                    className="change-plan-form"
                    classes={{ content: 'dialog-content' }}
                    onClose={this.props.onCloseDialog}
                    open
                    scroll="paper"
                    title={t('billing.edit_form.title')}
                >
                    <FormBackdrop loading>{t('loading')}</FormBackdrop>
                </PureFormDialog>
            );
        }

        const { errorMessage, errors, loading, subscription } = this.state;
        const isNotChange = isEqual(this.props.subscription, this.formatSubscription(subscription));
        const isHasErrors = errors.size > 0;
        const statuses = STATUS_RULES[this.props.subscription.status];
        const isShowSeatsWarning = subscription.usersAllowed > subscription.usersPurchased;

        return (
            <PureFormDialog
                actions={
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.props.onCloseDialog}
                            data-testid="subscription.edit.button.cancel"
                        >
                            {t('button.cancel')}
                        </Button>
                        <LoadingButton
                            color="primary"
                            onClick={this.handleConfirmCustomize}
                            disabled={isNotChange || isHasErrors}
                            loading={loading}
                            data-testid="subscription.edit.button.confirm"
                        >
                            {t('button.confirm')}
                        </LoadingButton>
                    </DialogActions>
                }
                alert={errorMessage ? <FormAlert onClose={this.handleCloseAlert}>{errorMessage}</FormAlert> : null}
                className="change-plan-form"
                classes={{ content: 'dialog-content' }}
                onClose={this.props.onCloseDialog}
                open
                scroll="paper"
                title={t('billing.edit_form.title')}
            >
                <FormBackdrop loading={loading} style={{ paddingBottom: '30px', paddingTop: '30px' }}>
                    <FormControl fullWidth disabled={statuses === undefined}>
                        <InputLabel>{t('billing.status')}</InputLabel>
                        <Select
                            name="status"
                            value={subscription.status}
                            onChange={this.handleInputChange}
                            error={errors.has('status')}
                            data-testid="billing.status"
                        >
                            {statuses &&
                                statuses.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {t('billing.status_' + status)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth disabled={subscription.status !== SubscriptionStatus.TRIAL}>
                        <InputLabel>{t('billing.currency')}</InputLabel>
                        <Select
                            name="currency"
                            value={subscription.currency}
                            error={errors.has('currency')}
                            onChange={this.handleInputChange}
                            data-testid="billing.currency"
                        >
                            {statuses &&
                                CURRENCIES.map((cur) => (
                                    <MenuItem key={cur} value={cur}>
                                        {cur}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {subscription.status === SubscriptionStatus.CUSTOM && (
                        <TextField
                            label={t('billing.edit_form.custom_price_cur', { currency: subscription.currency })}
                            data-testid="billing.edit_form.custom_price_cur"
                            placeholder={t('billing.edit_form.custom_price_cur', { currency: subscription.currency })}
                            type="number"
                            fullWidth
                            margin="dense"
                            name="subPriceTotalCur"
                            value={subscription.subPriceTotalCur}
                            helperText={errors.get('subPriceTotalCur') || ''}
                            error={errors.has('subPriceTotalCur')}
                            onChange={this.handleInputChange}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                },
                            }}
                        />
                    )}

                    <TextField
                        label={t('billing.edit_form.note')}
                        data-testid="billing.edit_form.note"
                        fullWidth
                        margin="dense"
                        name="note"
                        value={subscription.note || ''}
                        helperText={errors.get('note') || ''}
                        error={errors.has('note')}
                        onChange={this.handleInputChange}
                        multiline
                        rows={4}
                    />

                    <TextField
                        label={t('billing.edit_form.custom_territories_count', {
                            currency: subscription.territoriesCount,
                        })}
                        data-testid="billing.edit_form.custom_territories_count"
                        placeholder={t('billing.edit_form.custom_territories_count', {
                            currency: subscription.territoriesCount,
                        })}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="territoriesCount"
                        value={subscription.territoriesCount}
                        helperText={errors.get('territoriesCount') || ''}
                        error={errors.has('territoriesCount')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 100,
                            },
                        }}
                    />

                    <TextField
                        label={t('billing.edit_form.users_allowed')}
                        data-testid="billing.edit_form.users_allowed"
                        placeholder={t('billing.edit_form.users_allowed')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="usersAllowed"
                        value={subscription.usersAllowed}
                        helperText={errors.get('usersAllowed') || ''}
                        error={errors.has('usersAllowed')}
                        onChange={this.handleInputChangeSeats}
                        InputProps={{
                            inputProps: {
                                min: subscription.usersPurchased,
                                step: 1,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.admin_price_modifier')}
                        data-testid="billing.edit_form.admin_price_modifier"
                        placeholder={t('billing.edit_form.admin_price_modifier')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="adminPmCur"
                        value={subscription.adminPmCur}
                        helperText={errors.get('adminPmCur') || ''}
                        error={errors.has('adminPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                step: 0.01,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.planer_price')}
                        data-testid="billing.edit_form.planer_price"
                        placeholder={t('billing.edit_form.planer_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="planerPmCur"
                        value={subscription.planerPmCur}
                        helperText={errors.get('planerPmCur') || ''}
                        error={errors.has('planerPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.0,
                                step: 0.01,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.custom_routing_price')}
                        data-testid="billing.edit_form.custom_routing_price"
                        placeholder={t('billing.edit_form.custom_routing_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="routingCustomPmCur"
                        value={subscription.routingCustomPmCur}
                        helperText={errors.get('routingCustomPmCur') || ''}
                        error={errors.has('routingCustomPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.0,
                                step: 0.01,
                            },
                        }}
                    />
                    <TextField
                        label={t(
                            this.props.sharedMapsEnabled
                                ? 'billing.edit_form.shared_maps_price'
                                : 'billing.edit_form.shared_maps_price_disabled',
                        )}
                        data-testid="billing.edit_form.shared_maps_price"
                        placeholder={t('billing.edit_form.shared_maps_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="customSharedMapsPmCur"
                        value={subscription.customSharedMapsPmCur}
                        helperText={errors.get('customSharedMapsPmCur') || ''}
                        error={errors.has('customSharedMapsPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.0,
                                step: 0.01,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.record_chunk_price')}
                        data-testid="billing.edit_form.record_chunk_price"
                        placeholder={t('billing.edit_form.record_chunk_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="recordChunkPmCur"
                        value={subscription.recordChunkPmCur}
                        helperText={errors.get('recordChunkPmCur') || ''}
                        error={errors.has('recordChunkPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.01,
                                step: 0.01,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.storage_chunk_price')}
                        data-testid="billing.edit_form.storage_chunk_price"
                        placeholder={t('billing.edit_form.storage_chunk_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="storageChunkPmCur"
                        value={subscription.storageChunkPmCur}
                        helperText={errors.get('storageChunkPmCur') || ''}
                        error={errors.has('storageChunkPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.01,
                                step: 0.01,
                            },
                        }}
                    />
                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.usersAutoUpdate}
                                name="usersAutoUpdate"
                                onChange={this.handleChangeUserAutoUpdate}
                                color="primary"
                                data-testid="billing.edit_form.user_auto_update"
                            />
                        }
                        label={t('billing.edit_form.user_auto_update')}
                    />
                    {isShowSeatsWarning && (
                        <div style={{ display: 'flex' }}>
                            <Icon className="icon-warning">warning</Icon>
                            <Typography variant="subtitle2">{t('billing.edit_form.user_auto_update_title')}</Typography>
                        </div>
                    )}

                    <TextField
                        label={t('billing.edit_form.record_cap')}
                        data-testid="billing.edit_form.record_cap"
                        placeholder={t('billing.edit_form.record_cap')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="recordCapFree"
                        value={subscription.recordCapFree}
                        helperText={errors.get('recordCapFree') || ''}
                        error={errors.has('recordCapFree')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.record_chunk_size')}
                        data-testid="billing.edit_form.record_chunk_size"
                        placeholder={t('billing.edit_form.record_chunk_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="recordChunkSize"
                        value={subscription.recordChunkSize}
                        helperText={errors.get('recordChunkSize') || ''}
                        error={errors.has('recordChunkSize')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />
                    <ByteField
                        label={t('billing.edit_form.storage_cap')}
                        data-testid="billing.edit_form.storage_cap"
                        placeholder={t('billing.edit_form.storage_cap')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="storageCapFree"
                        value={subscription.storageCapFree}
                        helperText={errors.get('storageCapFree') || ''}
                        error={errors.has('storageCapFree')}
                        onChange={this.handleInputChange}
                    />
                    <ByteField
                        label={t('billing.edit_form.storage_chunk_size')}
                        data-testid="billing.edit_form.storage_chunk_size"
                        placeholder={t('billing.edit_form.storage_chunk_size')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="storageChunkSize"
                        value={subscription.storageChunkSize}
                        helperText={errors.get('storageChunkSize') || ''}
                        error={errors.has('storageChunkSize')}
                        onChange={this.handleInputChange}
                    />

                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.geocoderFallback}
                                name="usersAutoUpdate"
                                onChange={this.handleChangeGeocoderFallback}
                                color="primary"
                                data-testid="billing.edit_form.geocoder_fallback"
                            />
                        }
                        label={t('billing.edit_form.geocoder_fallback')}
                    />

                    {subscription.isActive ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label={t('billing.edit_form.nex_billing_date')}
                                variant="inline"
                                autoOk={true}
                                format="MM/dd/yyyy"
                                name="nextBillDate"
                                margin="none"
                                onChange={this.handleChangeBillingDate}
                                value={
                                    subscription.nextBillDate
                                        ? formatDateTimeForPicker(subscription.nextBillDate, this.props.user)
                                        : null
                                }
                            />
                            <TimePicker
                                label={t('billing.edit_form.time')}
                                variant="inline"
                                autoOk={true}
                                margin="none"
                                onChange={this.handleChangeBillingDate}
                                value={
                                    subscription.nextBillDate
                                        ? formatDateTimeForPicker(subscription.nextBillDate, this.props.user)
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label={t('billing.edit_form.expires_date')}
                                autoOk={true}
                                format="MM/dd/yyyy"
                                name="expiresAt"
                                margin="none"
                                onChange={this.handleChangeExpiresAt}
                                value={
                                    subscription.expiresAt
                                        ? formatDateTimeForPicker(subscription.expiresAt, this.props.user)
                                        : null
                                }
                            />
                            <TimePicker
                                label={t('billing.edit_form.time')}
                                variant="inline"
                                autoOk={true}
                                margin="none"
                                onChange={this.handleChangeExpiresAt}
                                value={
                                    subscription.expiresAt
                                        ? formatDateTimeForPicker(subscription.expiresAt, this.props.user)
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    )}
                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.automationElementsManagement}
                                name="automationElementsManagement"
                                onChange={this.handleInputChange}
                                color="primary"
                                data-testid="billing.edit_form.automation_elements_management"
                            />
                        }
                        label={t('billing.edit_form.automation_elements_management')}
                    />
                    <TextField
                        label={t('billing.edit_form.unused_planner_credits')}
                        data-testid="billing.edit_form.unused_planner_credits"
                        placeholder={t('billing.edit_form.unused_planner_credits')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="unusedPlannerCredits"
                        value={subscription.unusedPlannerCredits}
                        helperText={errors.get('unusedPlannerCredits') || ''}
                        error={errors.has('unusedPlannerCredits')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                step: 1,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.monthly_planner_credits')}
                        data-testid="billing.edit_form.monthly_planner_credits"
                        placeholder={t('billing.edit_form.monthly_planner_credits')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="monthlyPlannerCredits"
                        value={subscription.monthlyPlannerCredits}
                        helperText={errors.get('monthlyPlannerCredits') || ''}
                        error={errors.has('monthlyPlannerCredits')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                step: 1,
                            },
                        }}
                    />
                    <FormLabel>{t('billing.edit_form.additional_routing_credits')}</FormLabel>
                    <RoutingCreditSlider
                        value={subscription.additionalCustomRoutingCredits}
                        onChange={(value) => this.handleChangeWithValueAndName('additionalCustomRoutingCredits', value)}
                    />

                    <TextField
                        label={t('billing.edit_form.location_history_retention_days')}
                        data-testid="billing.edit_form.location_history_retention_days"
                        placeholder={t('billing.edit_form.location_history_retention_days')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="locationHistoryRetentionDays"
                        value={subscription.locationHistoryRetentionDays}
                        helperText={errors.get('locationHistoryRetentionDays') || ''}
                        error={errors.has('locationHistoryRetentionDays')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />

                    <div style={{ marginTop: 32 }}>{t('shared_map.title')}</div>
                    <TextField
                        label={t('shared_map.limits.max_loads_day')}
                        data-testid="shared_map.limits.max_loads_day"
                        placeholder={t('shared_map.limits.max_loads_day')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxMapLoadsDay"
                        value={subscription.maxMapLoadsDay}
                        helperText={errors.get('maxMapLoadsDay') || ''}
                        error={errors.has('maxMapLoadsDay')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />
                    <TextField
                        label={t('shared_map.limits.max_loads_month')}
                        data-testid="shared_map.limits.max_loads_month"
                        placeholder={t('shared_map.limits.max_loads_month')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="maxMapLoadsMonth"
                        value={subscription.maxMapLoadsMonth}
                        helperText={errors.get('maxMapLoadsMonth') || ''}
                        error={errors.has('maxMapLoadsMonth')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />

                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.sharedMapAllowTable}
                                name="sharedMapAllowTable"
                                onChange={this.handleChangeSharedMap}
                                color="primary"
                                data-testid="billing.edit_form.shared_map_allow_table"
                            />
                        }
                        label={t('billing.edit_form.shared_map_allow_table')}
                    />
                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.sharedMapAllowLayers}
                                name="sharedMapAllowLayers"
                                onChange={this.handleChangeSharedMap}
                                color="primary"
                                data-testid="billing.edit_form.shared_map_allow_layers"
                            />
                        }
                        label={t('billing.edit_form.shared_map_allow_layers')}
                    />
                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.sharedMapAllowButtons}
                                name="sharedMapAllowButtons"
                                onChange={this.handleChangeSharedMap}
                                color="primary"
                                data-testid="billing.edit_form.shared_map_allow_buttons"
                            />
                        }
                        label={t('billing.edit_form.shared_map_allow_buttons')}
                    />
                    <FormControlLabel
                        margin="dense"
                        control={
                            <Switch
                                checked={subscription.sharedMapAllowDataEditing}
                                name="sharedMapAllowDataEditing"
                                onChange={this.handleChangeSharedMap}
                                color="primary"
                                data-testid="billing.edit_form.shared_map_allow_data_editing"
                            />
                        }
                        label={t('billing.edit_form.shared_map_allow_data_editing')}
                    />
                    <div style={{ marginTop: 32 }}>{t('billing.edit_form.section.prospecting')}</div>
                    <TextField
                        label={t('billing.edit_form.export_prospect_monthly')}
                        data-testid="billing.edit_form.export_prospect_monthly"
                        placeholder={t('billing.edit_form.export_prospect_monthly')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="exportProspectMonthly"
                        value={subscription.exportProspectMonthly}
                        helperText={errors.get('exportProspectMonthly') || ''}
                        error={errors.has('exportProspectMonthly')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                step: 1,
                            },
                        }}
                    />
                    <TextField
                        label={t('billing.edit_form.prospecting_price')}
                        data-testid="billing.edit_form.prospecting_price"
                        placeholder={t('billing.edit_form.prospecting_price')}
                        type="number"
                        fullWidth
                        margin="dense"
                        name="prospectPmCur"
                        value={subscription.prospectPmCur}
                        helperText={errors.get('prospectPmCur') || ''}
                        error={errors.has('prospectPmCur')}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputProps: {
                                min: 0.0,
                                step: 0.01,
                            },
                        }}
                    />

                    <AiTokensSubscriptionEditForm
                        subscription={subscription}
                        errors={errors}
                        onChange={this.handleChangeWithValueAndName}
                    />

                    <TranscriptionMinutesSubscriptionEditForm
                        subscription={subscription}
                        errors={errors}
                        onChange={this.handleChangeWithValueAndName}
                    />
                </FormBackdrop>
            </PureFormDialog>
        );
    }
}

SubscriptionEditForm.propTypes = {
    subscription: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.oneOf([null]).isRequired]).isRequired,
    user: PropTypes.object.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    onCustomizeSuccess: PropTypes.func,
    onCustomizeFailure: PropTypes.func,
    sharedMapsEnabled: PropTypes.bool.isRequired,
};

export default withTranslation()(SubscriptionEditForm);
