import React from 'react';
import { Tooltip, IconButton, Icon } from '@material-ui/core';
import DottedLink from '../../DottedLink';
import { getDetail } from '../../../service/WorkflowActionManager';
import { DETAIL_BCC_ADDRESSES, DETAIL_CC_ADDRESSES, DETAIL_SUBJECT, DETAIL_TO_ADDRESSES } from './Action';
import Confirmation from '../../Confirmation';
import AbstractList from '../AbstractList/AbstractList';
import i18n from '../../../locales/i18n';
import { userManager } from '../../../service/UserManager';

const t = i18n.t.bind(i18n);

class List extends AbstractList {
    initColumns() {
        return [
            {
                columnName: 'name',
                type: 'string',
                title: t('workflow_actions.list.send_email.name'),
                getCellValue: (action) => (
                    <React.Fragment>
                        <DottedLink
                            onClick={this.props.onActionEdit.bind(this, action)}
                            data-testid="workflow_actions.list.send_email.name"
                        >
                            {action.name}
                        </DottedLink>
                    </React.Fragment>
                ),
            },
            {
                columnName: 'callContext',
                type: 'text[]',
                title: t('workflow_actions.list.send_email.entity'),
                picklist: this.getEntityPicklist(),
                getCellValue: (action) => {
                    let entityLabel;
                    for (const dataSource of this.props.dataSources) {
                        for (const entity of dataSource.entities) {
                            if (entity.id === action.callContext.getBaseEntityId()) {
                                entityLabel = dataSource.name + ' > ' + entity.label;
                                break;
                            }
                        }
                    }
                    return <span>{entityLabel}</span>;
                },
            },
            {
                columnName: 'recipients',
                type: 'json',
                title: t('workflow_actions.list.send_email.recipients'),
                getCellValue: (action) => {
                    const to = getDetail(DETAIL_TO_ADDRESSES, action.details);
                    const cc = getDetail(DETAIL_CC_ADDRESSES, action.details);
                    const bcc = getDetail(DETAIL_BCC_ADDRESSES, action.details);

                    return (
                        <>
                            <div>
                                <b>{t('workflow_actions.form.send_email.to')}: </b>
                                <span>{to}</span>
                            </div>
                            {cc && (
                                <div>
                                    <b>{t('workflow_actions.form.send_email.cc')}: </b>
                                    <span>{cc}</span>
                                </div>
                            )}
                            {bcc && (
                                <div>
                                    <b>{t('workflow_actions.form.send_email.bcc')}: </b>
                                    <span>{bcc}</span>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                columnName: 'subject',
                type: 'json',
                title: t('workflow_actions.list.send_email.subject'),
                getCellValue: (action) => <span>{getDetail(DETAIL_SUBJECT, action.details)}</span>,
            },
            {
                columnName: 'rules',
                type: 'string',
                title: t('workflow_actions.list.send_email.rules'),
                getCellValue: (action) => <span>{action.rules.map((r) => r.name).join(', ')}</span>,
            },
            {
                columnName: 'updatedBy',
                type: 'string',
                title: t('workflow_actions.list.send_email.updated_by'),
                getCellValue: (action) => <span>{action.updatedBy ? action.updatedBy.name : null}</span>,
            },
            {
                columnName: 'updatedAt',
                type: 'datetime',
                title: t('workflow_actions.list.send_email.updated_at'),
            },
            {
                columnName: 'remove',
                title: null,
                getCellValue: (action) => (
                    <Confirmation
                        text={t('workflow_actions.confirm.remove')}
                        onConfirm={this.props.onActionRemove.bind(this, action)}
                    >
                        <Tooltip title={t('remove')}>
                            <span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={!userManager.automationElementsManagement()}
                                    data-testid="workflow_actions.send_email.confirm.remove"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Confirmation>
                ),
            },
        ];
    }

    initSortingColumnExtensions() {
        return [
            { columnName: 'callContext', sortingEnabled: false },
            { columnName: 'recipients', sortingEnabled: false },
            { columnName: 'subject', sortingEnabled: false },
            { columnName: 'rules', sortingEnabled: false },
        ];
    }
}

export { List as SendEmailList };
