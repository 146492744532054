import React, { RefObject } from 'react';
import { observer } from 'mobx-react';
import './style.css';
import { TFunction, WithTranslation, withTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    withStyles,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DottedLink from 'components/DottedLink';
import { userManager } from 'service/UserManager';
import { Bounds, WorkingHoursException, WorkingHoursPerWeek } from 'components/types';
import WorkingDays, { prepareWorkingDays, WeekDayList } from './WorkingDays';
import ManualLocationInput from 'components/RouteEditor/RouteForm/ManualLocationInput';
import GeoPointTransformer from 'service/GeoPointTransformer';
import {
    BreakTypeEnum,
    MapNavigationOption,
    ShowEarlyLateTags,
    TravelingPreferencesFocusElement,
    UserData,
    UserDataRoutePreferences,
} from 'service/types';
import SelectMapNavigationOption from './SelectMapNavigationOption';
import { massUsersTravelingPreferencesDialogManager, travelingPreferences } from 'service/UserForm';
import VehicleTypeInput from './VehicleTypeInput';
import HasSkillsInput from './HasSkillsInput';
import MaxDistanceInput from './MaxDistanceInput';
import MaxDrivingTimeInput from './MaxDrivingTimeInput';
import MaxJobsInput from './MaxJobsInput';
import MinJobsInput from './MinJobsInput';
import MaxActivitiesInput from './MaxActivitiesInput';
import AllowedOvertimeInput from './AllowedOvertimeInput';
import BreakTypeInput from './BreakTypeInput';
import BreakDurationInput from './BreakDurationInput';
import BreakEarliestTimeInput from './BreakEarliestTimeInput';
import BreakLatestTimeInput from './BreakLatestTimeInput';
import ReturnToFinishLocationInput from './ReturnToFinishLocationInput';
import Spoiler from '../../Spoiler';
import { Theme } from '@material-ui/core/styles/createTheme';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import MeetingDurationInput from './MeetingDurationInput';
import DepartureDelayInput from './DepartureDelayInput';
import { ROUTING_MODULE } from '../../Permissions/constants';
import { Account, Geo, Routing, User } from 'interfaces';
import Alert from '../../Alert';
import dispatcher from '../../../service/dispatcher';
import events from '../../../events';
import Hint from 'components/Hint';
import UsersWorkingHourExceptionsButton from '../../UsersWorkingHourExceptionsButton';
import QuestionHint from '../../Hint/QuestionHint';
import CanJobStartEarlierInput from './CanJobStartEarliernput';
import usersPermissionsManager from '../../../service/Permissions/UsersPermissionsManager';

export interface UserRoutePreferences {
    departureDelay?: number;
    defaultDuration?: number;
    lassoAutoZoom: boolean;
    workingDays: WeekDayList;
    workingHoursExceptions: WorkingHoursException[];
    startPoint: Routing.Route.UserLocationPoint | null;
    endPoint: Routing.Route.UserLocationPoint | null;
    allowedOvertime: number | null;
    breakType: BreakTypeEnum | null;
    breakDuration: number | null;
    minJobs: number | null;
    maxJobs: number | null;
    maxActivities: number | null;
    maxDrivingTime: number | null;
    maxDistance: number | null;
    hasSkills: string[] | null;
    vehicleType: string | null;
    returnToFinishLocation: boolean;
    breakLatestTime: string | null;
    breakEarliestTime: string | null;
    showEarlyLateTags: ShowEarlyLateTags | null;
    canJobStartEarlier: boolean;
    navigationVariant: MapNavigationOption | null;
}

export function preparePreferencesResult(preferences: UserRoutePreferences): UserDataRoutePreferences {
    const {
        workingDays,
        workingHoursExceptions,
        defaultDuration,
        departureDelay,
        startPoint,
        endPoint,
        lassoAutoZoom,
        breakDuration,
        allowedOvertime,
        breakType,
        breakEarliestTime,
        breakLatestTime,
        hasSkills,
        maxActivities,
        maxDistance,
        maxDrivingTime,
        maxJobs,
        minJobs,
        returnToFinishLocation,
        vehicleType,
        showEarlyLateTags,
        canJobStartEarlier,
        navigationVariant,
    } = preferences;
    const weekTimes: WorkingHoursPerWeek = {};

    for (let [day, value] of Object.entries(workingDays)) {
        if (!value.enable) {
            weekTimes[day] = null;
        } else {
            weekTimes[day] = {
                start: {
                    hours: value.start.getHours(),
                    minutes: value.start.getMinutes(),
                },
                end: {
                    hours: value.end.getHours(),
                    minutes: value.end.getMinutes(),
                },
            };
        }
    }

    return {
        weekTimes,
        workingHoursExceptions,
        lassoAutoZoom,
        defaultDuration: defaultDuration || 0,
        departureDelay: departureDelay || 0,
        startPoint,
        endPoint,
        breakDuration: breakDuration,
        breakEarliestTime: breakType === BreakTypeEnum.TIME_WINDOW ? breakEarliestTime : null,
        breakLatestTime: breakType === BreakTypeEnum.TIME_WINDOW ? breakLatestTime : null,
        allowedOvertime: allowedOvertime,
        breakType: breakType,
        hasSkills: hasSkills,
        maxActivities: maxActivities,
        maxDistance: maxDistance,
        maxDrivingTime: maxDrivingTime,
        maxJobs: maxJobs,
        minJobs: minJobs,
        returnToFinishLocation: returnToFinishLocation,
        vehicleType: vehicleType,
        showEarlyLateTags,
        canJobStartEarlier,
        navigationVariant,
    };
}

export function prepareUserRoutePreferences(preferences: UserDataRoutePreferences, t: TFunction): UserRoutePreferences {
    const {
        defaultDuration,
        departureDelay,
        startPoint,
        endPoint,
        lassoAutoZoom,
        breakDuration,
        allowedOvertime,
        breakType,
        breakEarliestTime,
        breakLatestTime,
        hasSkills,
        maxActivities,
        maxDistance,
        maxDrivingTime,
        maxJobs,
        minJobs,
        returnToFinishLocation,
        vehicleType,
        workingHoursExceptions,
        showEarlyLateTags,
        canJobStartEarlier,
        navigationVariant,
    } = preferences;
    const workingDays = prepareWorkingDays(preferences.weekTimes, t);

    return {
        workingDays,
        workingHoursExceptions,
        defaultDuration,
        departureDelay,
        lassoAutoZoom,
        startPoint,
        endPoint,
        breakDuration: breakDuration === undefined ? null : breakDuration,
        breakEarliestTime: breakEarliestTime === undefined ? null : breakEarliestTime,
        breakLatestTime: breakLatestTime === undefined ? null : breakLatestTime,
        allowedOvertime: allowedOvertime === undefined ? null : allowedOvertime,
        breakType: breakType === undefined ? null : breakType,
        hasSkills: hasSkills === undefined ? null : hasSkills,
        maxActivities: maxActivities === undefined ? null : maxActivities,
        maxDistance: maxDistance === undefined ? null : maxDistance,
        maxDrivingTime: maxDrivingTime === undefined ? null : maxDrivingTime,
        maxJobs: maxJobs === undefined ? null : maxJobs,
        minJobs: minJobs === undefined ? null : minJobs,
        returnToFinishLocation: returnToFinishLocation === undefined ? false : returnToFinishLocation,
        vehicleType: vehicleType === undefined ? null : vehicleType,
        showEarlyLateTags,
        canJobStartEarlier,
        navigationVariant,
    };
}

interface TravelingPreferencesProps extends WithTranslation, WithStyles {
    account: Account.Account;
    user?: UserData;
    bounds?: Bounds;
    focusElement?: TravelingPreferencesFocusElement;
    ref: RefObject<TravelingPreferencesForm>;
    onScheduleChange: any;
    classes: {
        advancedPreferencesTitle: string;
    };
    mode?: TravelingPreferencesMode;
    onClose?: () => void;
}

export enum TravelingPreferencesMode {
    PERSONAL,
    NEW_USERS_DEFAULT,
    MASS_EDIT,
    NEW_USER,
}

interface TravelingPreferencesState {
    mode: TravelingPreferencesMode;
    preferences: UserRoutePreferences;
    errors: Map<keyof UserRoutePreferences, string | null>;
    chosenPreferences: Map<keyof UserDataRoutePreferences, boolean>;
    showUpdatedAlert: boolean;
}

class TravelingPreferencesForm extends React.PureComponent<TravelingPreferencesProps, TravelingPreferencesState> {
    private accountPreferences: UserRoutePreferences;
    private userPreferences: UserRoutePreferences;

    constructor(props: TravelingPreferencesProps) {
        super(props);

        const { t, user, account } = props;

        const routingPreferences = user?.routingPreferences || account.routingPreferences;

        this.accountPreferences = prepareUserRoutePreferences(account.routingPreferences, t);
        this.userPreferences = prepareUserRoutePreferences(routingPreferences, t);

        this.state = {
            mode: props.mode !== undefined ? props.mode : TravelingPreferencesMode.PERSONAL,
            preferences: { ...this.userPreferences },
            errors: new Map(),
            chosenPreferences: new Map(),
            showUpdatedAlert: false,
        };
    }

    componentDidMount() {
        dispatcher.subscribe([events.EVENT_USER_CHANGED], this, (user: User.User) => {
            if (this.props.user && this.props.user.id === user.id && !this.state.showUpdatedAlert) {
                this.setState({ showUpdatedAlert: true });
            }
        });
    }

    componentWillUnmount() {
        dispatcher.unsubscribeFromAllEvents(this);
    }

    isValid = (): boolean => {
        const { t } = this.props;
        const { preferences, mode, chosenPreferences } = this.state;
        const errors = new Map(this.state.errors);

        if (
            !errors.has('breakLatestTime') &&
            !errors.has('breakEarliestTime') &&
            preferences.breakLatestTime !== null &&
            preferences.breakEarliestTime !== null
        ) {
            if (preferences.breakLatestTime < preferences.breakEarliestTime) {
                errors.set('breakLatestTime', t('validation.value.min', { min: preferences.breakEarliestTime }));
            } else {
                errors.delete('breakLatestTime');
            }
        }

        let isScheduleValid = true;
        for (const weekDay of Object.values(preferences.workingDays)) {
            if (weekDay.enable && weekDay.start > weekDay.end) {
                isScheduleValid = false;
                break;
            }
        }
        if (!isScheduleValid) {
            errors.set('workingDays', t('validation.value.invalid'));
        } else {
            errors.delete('workingDays');
        }

        this.setState({ errors });

        if (mode === TravelingPreferencesMode.MASS_EDIT) {
            const errorsOfSelectedFields = new Map();
            for (const field of chosenPreferences.keys()) {
                const fieldsToCheckErrorsMap: (keyof UserRoutePreferences)[] = [];
                if (field === 'weekTimes') {
                    fieldsToCheckErrorsMap.push('workingDays');
                } else if (field === 'breakType') {
                    fieldsToCheckErrorsMap.push('breakType');
                    fieldsToCheckErrorsMap.push('breakDuration');
                    fieldsToCheckErrorsMap.push('breakLatestTime');
                    fieldsToCheckErrorsMap.push('breakEarliestTime');
                } else {
                    fieldsToCheckErrorsMap.push(field);
                }
                for (const errorField of fieldsToCheckErrorsMap) {
                    if (chosenPreferences.get(field) === true && errors.has(errorField)) {
                        errorsOfSelectedFields.set(errorField, errors.get(errorField));
                    }
                }
            }
            return errorsOfSelectedFields.size === 0;
        }

        return errors.size === 0;
    };

    onInputValidation = (field: keyof UserRoutePreferences, isValid: boolean, error?: string): Promise<void> => {
        return new Promise((resolve) => {
            const errors = new Map(this.state.errors);

            if (isValid) {
                if (errors.has(field)) {
                    errors.delete(field);
                    return this.setState({ errors }, resolve);
                }
            } else {
                const transformedError = error === undefined ? null : error;
                if (errors.get(field) !== transformedError) {
                    errors.set(field, transformedError);
                    return this.setState({ errors }, resolve);
                }
            }
            return resolve();
        });
    };

    getAccountPreferences = (): UserDataRoutePreferences => {
        const { mode, preferences } = this.state;

        return preparePreferencesResult(
            mode === TravelingPreferencesMode.NEW_USERS_DEFAULT ? preferences : this.accountPreferences,
        );
    };

    getUserPreferences = (): UserDataRoutePreferences => {
        const { mode, preferences } = this.state;

        return preparePreferencesResult(
            mode === TravelingPreferencesMode.NEW_USERS_DEFAULT ? this.userPreferences : preferences,
        );
    };

    getChosenPreferences = (): Map<keyof UserDataRoutePreferences, boolean> => {
        return new Map(this.state.chosenPreferences);
    };

    private handleStartDateChanged = (date: MaterialUiPickersDate, day: string) => {
        this.setState(
            (prev) => {
                const { workingDays } = prev.preferences;
                workingDays[day].start.setHours(date!.getHours(), date!.getMinutes(), 0, 0);

                return {
                    preferences: { ...prev.preferences, workingDays },
                };
            },
            () => {
                this.props.onScheduleChange && this.props.onScheduleChange(this.state.preferences.workingDays);
            },
        );
    };

    private handleEndDateChanged = (date: MaterialUiPickersDate, day: string) => {
        this.setState(
            (prev) => {
                const { workingDays } = prev.preferences;
                workingDays[day].end.setHours(date!.getHours(), date!.getMinutes(), 0, 0);

                return {
                    preferences: { ...prev.preferences, workingDays },
                };
            },
            () => {
                this.props.onScheduleChange && this.props.onScheduleChange(this.state.preferences.workingDays);
            },
        );
    };

    private handleIncludeDayChanged = (day: string) => {
        this.setState(
            (prev) => {
                const { workingDays } = prev.preferences;
                workingDays[day].enable = !workingDays[day].enable;

                return {
                    preferences: { ...prev.preferences, workingDays },
                };
            },
            () => {
                this.props.onScheduleChange && this.props.onScheduleChange(this.state.preferences.workingDays);
            },
        );
    };

    private handleNavigateVariantChanged = (value: MapNavigationOption) => {
        travelingPreferences.setMapNavigationOption(value);
        this.setState((prev) => {
            return {
                preferences: { ...prev.preferences, navigationVariant: value },
            };
        });
    };

    private handleStartLocationCancel = () => {
        this.setState((prev) => {
            return {
                preferences: { ...prev.preferences, startPoint: null },
            };
        });
    };

    private handleStartLocationPointFound = (geoPoint: Geo.GeoLocationPoint) => {
        this.setState((prev) => {
            const point = GeoPointTransformer.transformGeoLocationPoint(geoPoint);
            return {
                preferences: { ...prev.preferences, startPoint: point },
            };
        });
    };

    private handleEndLocationCancel = () => {
        this.setState((prev) => {
            return {
                preferences: { ...prev.preferences, endPoint: null },
            };
        });
    };

    private handleEndLocationPointFound = (geoPoint: Geo.GeoLocationPoint) => {
        this.setState((prev) => {
            const point = GeoPointTransformer.transformGeoLocationPoint(geoPoint);
            return {
                preferences: { ...prev.preferences, endPoint: point },
            };
        });
    };

    private handleModeChange = (mode: TravelingPreferencesMode) => {
        const { preferences } = this.state;
        let newPreferences: UserRoutePreferences;

        switch (mode) {
            case TravelingPreferencesMode.PERSONAL:
                this.accountPreferences = { ...preferences };
                newPreferences = { ...this.userPreferences };
                break;
            case TravelingPreferencesMode.NEW_USERS_DEFAULT:
                this.userPreferences = { ...preferences };
                newPreferences = { ...this.accountPreferences };
                break;
            case TravelingPreferencesMode.MASS_EDIT:
                throw new Error('This mode can be set only from props');
            case TravelingPreferencesMode.NEW_USER:
                throw new Error('This mode can be set only from props');
        }

        this.setState(
            {
                mode,
                preferences: newPreferences,
            },
            () => {
                this.props.onScheduleChange && this.props.onScheduleChange(this.state.preferences.workingDays);
            },
        );
    };

    private handleChangeBreakType = (breakType: BreakTypeEnum | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((prev) => {
                const resetPreferences: Partial<UserRoutePreferences> = {};
                if (breakType !== BreakTypeEnum.TIME_WINDOW) {
                    resetPreferences.breakDuration = null;
                    resetPreferences.breakEarliestTime = null;
                    resetPreferences.breakLatestTime = null;
                }
                return {
                    preferences: { ...prev.preferences, breakType, ...resetPreferences },
                };
            }, resolve);
        });
    };

    private handleChangeBreakDuration = (breakDuration: number | null): Promise<void> => {
        return new Promise((resolve) => {
            this.setState((prev) => {
                const errors = new Map(prev.errors);
                return {
                    preferences: { ...prev.preferences, breakDuration },
                    errors,
                };
            }, resolve);
        });
    };

    private handleShowEarlyLateTagsChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>,
    ): Promise<void> => {
        let showEarlyLateTags: ShowEarlyLateTags | null = e.target.value as ShowEarlyLateTags;
        if (!Object.values(ShowEarlyLateTags).includes(showEarlyLateTags)) {
            showEarlyLateTags = null;
        }

        return new Promise((resolve) => {
            this.setState((prev) => {
                return {
                    preferences: { ...prev.preferences, showEarlyLateTags },
                };
            }, resolve);
        });
    };

    private handleTravelingPreferenceChange = (param: keyof UserRoutePreferences): ((v: any) => Promise<void>) => {
        return (value: any) => {
            return new Promise((resolve) => {
                this.setState((prev) => {
                    return {
                        preferences: { ...prev.preferences, [param]: value },
                    };
                }, resolve);
            });
        };
    };

    private renderPreferencesBlock = (
        preferenceKey: keyof UserDataRoutePreferences,
        children: JSX.Element,
        title?: string,
    ) => {
        // @ts-ignore
        const { mode, chosenPreferences } = this.state;
        if (mode !== TravelingPreferencesMode.MASS_EDIT) {
            return children;
        }

        const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.currentTarget.checked;
            const newChosenPreferences = new Map(chosenPreferences);
            newChosenPreferences.set(preferenceKey, checked);
            this.setState({ chosenPreferences: newChosenPreferences });
        };

        const isActive = Boolean(chosenPreferences.get(preferenceKey));

        return (
            <Grid container alignItems="center">
                <Grid container item xs={3} alignItems="center" wrap="nowrap" style={{ paddingRight: '8px' }}>
                    <Checkbox onChange={onCheckboxChange} checked={isActive} color="primary" />
                    {title}
                </Grid>
                <Grid item xs={9}>
                    {children}
                </Grid>
            </Grid>
        );
    };

    private hasPreferenceChosen = (preferenceKey: keyof UserDataRoutePreferences) => {
        const { mode, chosenPreferences } = this.state;
        if (mode === TravelingPreferencesMode.MASS_EDIT) {
            return chosenPreferences.get(preferenceKey);
        }
        return true;
    };

    private handleMassUpdateLinkClick = () => {
        const account = userManager.getCurrentAccount();
        massUsersTravelingPreferencesDialogManager.openModal(account as Account.Account);
    };

    private handleWorkingHoursExceptionClick = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const { t, bounds, focusElement, account, classes, user } = this.props;
        const { mode, preferences, errors, showUpdatedAlert } = this.state;

        const accountId = userManager.isRoleSuperAdmin() ? undefined : account.id;
        const departureDelayValue = preferences.departureDelay ? preferences.departureDelay.toString() : '0';
        const defaultDurationValue = preferences.defaultDuration ? preferences.defaultDuration.toString() : '0';

        const userHasBasicTravelingPreferencesPermission = userManager.userHasAccessTo(
            ROUTING_MODULE.NAME,
            ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME,
        );
        const userHasModifyBasicTravelingPreferencesPermission = userManager.userHasAccessTo(
            ROUTING_MODULE.NAME,
            ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.NAME,
            ROUTING_MODULE.FEATURES.BASIC_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_BASIC_PREFERENCES.NAME,
        );
        const userHasAdvancedTravelingPreferencesPermission = userManager.userHasAccessTo(
            ROUTING_MODULE.NAME,
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
        );
        const userHasModifyAdvancedTravelingPreferencesPermission = userManager.userHasAccessTo(
            ROUTING_MODULE.NAME,
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_ADVANCED_PREFERENCES.NAME,
        );
        const userHasModifyOtherUsersTravelingPreferencesPermission = userManager.userHasAccessTo(
            ROUTING_MODULE.NAME,
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.NAME,
            ROUTING_MODULE.FEATURES.ADVANCED_TRAVELING_PREFERENCES.SUB_FEATURES.MODIFY_OTHER_USERS_PREFERENCES.NAME,
        );
        const userHasAccessToCalendar = usersPermissionsManager.hasCalendarUiPermission;
        const showModeBlock =
            mode !== TravelingPreferencesMode.MASS_EDIT &&
            mode !== TravelingPreferencesMode.NEW_USER &&
            (userManager.isRoleAdmin() || userHasModifyOtherUsersTravelingPreferencesPermission);
        const showShortInputs = mode === TravelingPreferencesMode.MASS_EDIT;

        return (
            <React.Fragment>
                {showUpdatedAlert && (
                    <Alert type="info">{t('route_editor.modal.routing_preferences.alert.updated_preferences')}</Alert>
                )}
                {showModeBlock && (
                    <React.Fragment>
                        <Divider style={{ marginTop: 10 }} light />
                        <Grid
                            container
                            style={{
                                padding: 5,
                                background:
                                    'repeating-linear-gradient(  -45deg,  #f7f7f7,  #f7f7f7 30px,  #f2f2f2 0px,  #f2f2f2 60px)',
                            }}
                        >
                            {mode === TravelingPreferencesMode.PERSONAL && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        {t('route_editor.modal.routing_preferences.modal.for_others')}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                this.handleModeChange(TravelingPreferencesMode.NEW_USERS_DEFAULT)
                                            }
                                            data-testid="route_editor.modal.routing_preferences.modal.default_link"
                                            className={'userForm__modal__switch_tab__btn'}
                                        >
                                            {t('route_editor.modal.routing_preferences.modal.default_link')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            onClick={this.handleMassUpdateLinkClick}
                                            data-testid="route_editor.modal.routing_preferences.modal.mass_update_link"
                                            className={'userForm__modal__switch_tab__btn'}
                                        >
                                            {t('route_editor.modal.routing_preferences.modal.mass_update_link')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {t('route_editor.modal.routing_preferences.modal.personal_preferences_below')}
                                    </Grid>
                                </React.Fragment>
                            )}
                            {mode !== TravelingPreferencesMode.PERSONAL && (
                                <Grid item xs={12}>
                                    <div className="c-form-dialog-route-preference__default-setting-edit">
                                        <div className="c-form-dialog-route-preference__default-setting-edit-icon">
                                            <i className="fas fa-info-circle" />
                                        </div>
                                        <div className="c-form-dialog-route-preference__default-setting-edit-text">
                                            {t('route_editor.modal.routing_preferences.modal.default_settings_text')}
                                            <br />
                                            <DottedLink
                                                onClick={() => this.handleModeChange(TravelingPreferencesMode.PERSONAL)}
                                                data-testid="route_editor.modal.routing_preferences.modal.return_to_personal_edit"
                                            >
                                                {t(
                                                    'route_editor.modal.routing_preferences.modal.return_to_personal_edit',
                                                )}
                                            </DottedLink>
                                        </div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        <Divider style={{ marginBottom: 10 }} light />
                    </React.Fragment>
                )}

                <div className="c-form-dialog__content traveling-preferences">
                    {userHasBasicTravelingPreferencesPermission && (
                        <React.Fragment>
                            {this.renderPreferencesBlock(
                                'weekTimes',
                                <WorkingDays
                                    disabled={
                                        !userHasModifyBasicTravelingPreferencesPermission ||
                                        !this.hasPreferenceChosen('weekTimes')
                                    }
                                    workingDays={preferences.workingDays}
                                    onStartDateChanged={this.handleStartDateChanged}
                                    onEndDateChanged={this.handleEndDateChanged}
                                    onIncludeDayChanged={this.handleIncludeDayChanged}
                                    bareInput={showShortInputs}
                                />,
                                t('route_editor.modal.routing_preferences.modal.working_hours_title'),
                            )}

                            {userHasAccessToCalendar &&
                                this.renderPreferencesBlock(
                                    'allowedOvertime',
                                    <div className="c-form-dialog-route-preference__margin">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <AllowedOvertimeInput
                                                    disabled={
                                                        !userHasModifyBasicTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('allowedOvertime')
                                                    }
                                                    value={preferences.allowedOvertime}
                                                    onValueChanged={this.handleTravelingPreferenceChange(
                                                        'allowedOvertime',
                                                    )}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('allowedOvertime', isValid, error)
                                                    }
                                                    error={errors.get('allowedOvertime')}
                                                    bareInput={showShortInputs}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('route_editor.modal.routing_preferences.modal.allowed_overtime.label'),
                                )}

                            {this.renderPreferencesBlock(
                                'breakType',
                                <React.Fragment>
                                    <div className="c-form-dialog-route-preference__margin">
                                        <BreakTypeInput
                                            disabled={
                                                !userHasModifyBasicTravelingPreferencesPermission ||
                                                !this.hasPreferenceChosen('breakType')
                                            }
                                            value={preferences.breakType}
                                            onValueChanged={this.handleChangeBreakType}
                                            onInputValidation={(isValid: boolean, error?: string) =>
                                                this.onInputValidation('breakType', isValid, error)
                                            }
                                            error={errors.get('breakType')}
                                            bareInput={showShortInputs}
                                        />
                                    </div>
                                    {preferences.breakType !== null && (
                                        <div className="c-form-dialog-route-preference__margin">
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <BreakDurationInput
                                                        disabled={!userHasModifyBasicTravelingPreferencesPermission}
                                                        value={preferences.breakDuration}
                                                        onValueChanged={this.handleChangeBreakDuration}
                                                        onInputValidation={(isValid: boolean, error?: string) =>
                                                            this.onInputValidation('breakDuration', isValid, error)
                                                        }
                                                        error={errors.get('breakDuration')}
                                                    />
                                                </Grid>
                                                {preferences.breakType === BreakTypeEnum.TIME_WINDOW && (
                                                    <React.Fragment>
                                                        <Grid item xs={4}>
                                                            <BreakEarliestTimeInput
                                                                disabled={
                                                                    !userHasModifyBasicTravelingPreferencesPermission
                                                                }
                                                                value={preferences.breakEarliestTime}
                                                                onValueChanged={this.handleTravelingPreferenceChange(
                                                                    'breakEarliestTime',
                                                                )}
                                                                onInputValidation={(isValid: boolean, error?: string) =>
                                                                    this.onInputValidation(
                                                                        'breakEarliestTime',
                                                                        isValid,
                                                                        error,
                                                                    )
                                                                }
                                                                error={errors.get('breakEarliestTime')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <BreakLatestTimeInput
                                                                disabled={
                                                                    !userHasModifyBasicTravelingPreferencesPermission
                                                                }
                                                                value={preferences.breakLatestTime}
                                                                onValueChanged={this.handleTravelingPreferenceChange(
                                                                    'breakLatestTime',
                                                                )}
                                                                onInputValidation={(isValid: boolean, error?: string) =>
                                                                    this.onInputValidation(
                                                                        'breakLatestTime',
                                                                        isValid,
                                                                        error,
                                                                    )
                                                                }
                                                                error={errors.get('breakLatestTime')}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </div>
                                    )}
                                </React.Fragment>,
                                t('route_editor.modal.routing_preferences.modal.break_type.label'),
                            )}

                            {userHasModifyBasicTravelingPreferencesPermission &&
                                mode === TravelingPreferencesMode.PERSONAL &&
                                this.renderPreferencesBlock(
                                    'workingHoursExceptions',
                                    <UsersWorkingHourExceptionsButton
                                        user={user as User.User}
                                        onClick={this.handleWorkingHoursExceptionClick}
                                    />,
                                )}

                            <h4>{t('route_editor.modal.routing_preferences.modal.default_routing_settings')}</h4>
                            {this.renderPreferencesBlock(
                                'lassoAutoZoom',
                                <>
                                    <FormControlLabel
                                        style={{ margin: 0 }}
                                        control={
                                            <Checkbox
                                                disabled={
                                                    !userHasModifyBasicTravelingPreferencesPermission ||
                                                    !this.hasPreferenceChosen('lassoAutoZoom')
                                                }
                                                checked={preferences.lassoAutoZoom}
                                                onChange={(e) =>
                                                    this.handleTravelingPreferenceChange('lassoAutoZoom')(
                                                        e.target.checked,
                                                    )
                                                }
                                                color="primary"
                                            />
                                        }
                                        label={t('route_editor.modal.routing_preferences.modal.lasso_auto_zoom')}
                                    />
                                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                                        {t('route_editor.modal.routing_preferences.modal.lasso_auto_zoom.hint')}
                                    </Hint>
                                </>,
                                t('users_traveling_preferences_dialog.columns.lassoAutoZoom'),
                            )}

                            {this.renderPreferencesBlock(
                                'startPoint',
                                <div className="c-form-dialog-route-preference__start-point c-form-dialog-route-preference__input-block">
                                    <ManualLocationInput
                                        disabled={
                                            !userHasModifyBasicTravelingPreferencesPermission ||
                                            !this.hasPreferenceChosen('startPoint')
                                        }
                                        key="startPoint"
                                        bounds={bounds}
                                        label={
                                            mode === TravelingPreferencesMode.MASS_EDIT
                                                ? ''
                                                : t('route_editor.modal.routing_preferences.modal.start_point')
                                        }
                                        onPointFound={this.handleStartLocationPointFound}
                                        address={preferences.startPoint?.address ?? null}
                                        autoFocus={focusElement === 'startPoint'}
                                        onCancel={this.handleStartLocationCancel}
                                        accountId={accountId}
                                        hint={t('route_editor.modal.routing_preferences.modal.start_point.hint')}
                                    />
                                </div>,
                                t('route_editor.modal.routing_preferences.modal.start_point'),
                            )}
                            {this.renderPreferencesBlock(
                                'endPoint',
                                <div className="c-form-dialog-route-preference__end-point c-form-dialog-route-preference__input-block">
                                    <ManualLocationInput
                                        disabled={
                                            !userHasModifyBasicTravelingPreferencesPermission ||
                                            !this.hasPreferenceChosen('endPoint')
                                        }
                                        key="endPoint"
                                        bounds={bounds}
                                        label={
                                            mode === TravelingPreferencesMode.MASS_EDIT
                                                ? ''
                                                : t('route_editor.modal.routing_preferences.modal.end_point')
                                        }
                                        onPointFound={this.handleEndLocationPointFound}
                                        address={preferences.endPoint?.address ?? null}
                                        autoFocus={focusElement === 'endPoint'}
                                        onCancel={this.handleEndLocationCancel}
                                        accountId={accountId}
                                        hint={t('route_editor.modal.routing_preferences.modal.end_point.hint')}
                                    />
                                </div>,
                                t('route_editor.modal.routing_preferences.modal.end_point'),
                            )}
                            {this.renderPreferencesBlock(
                                'returnToFinishLocation',
                                <ReturnToFinishLocationInput
                                    disabled={
                                        !userHasModifyBasicTravelingPreferencesPermission ||
                                        !this.hasPreferenceChosen('returnToFinishLocation')
                                    }
                                    value={preferences.returnToFinishLocation}
                                    onValueChanged={this.handleTravelingPreferenceChange('returnToFinishLocation')}
                                    onInputValidation={(isValid: boolean, error?: string) =>
                                        this.onInputValidation('returnToFinishLocation', isValid, error)
                                    }
                                    error={errors.get('returnToFinishLocation')}
                                />,
                                t(
                                    'routing_settings.dialog.tabs.users.form_control.return_to_finish_location.label.short',
                                ),
                            )}
                            {this.renderPreferencesBlock(
                                'defaultDuration',
                                <div className="c-form-dialog-route-preference__default_meeting_duration">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <MeetingDurationInput
                                                disabled={
                                                    !userHasModifyBasicTravelingPreferencesPermission ||
                                                    !this.hasPreferenceChosen('defaultDuration')
                                                }
                                                value={defaultDurationValue}
                                                onValueChanged={this.handleTravelingPreferenceChange('defaultDuration')}
                                                onInputValidation={(isValid: boolean, error?: string) =>
                                                    this.onInputValidation('defaultDuration', isValid, error)
                                                }
                                                error={errors.get('defaultDuration')}
                                                bareInput={showShortInputs}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>,
                                t('route_editor.modal.routing_preferences.modal.default_meeting_duration.short'),
                            )}
                            {this.renderPreferencesBlock(
                                'showEarlyLateTags',
                                <div className="c-form-dialog-route-preference__margin" style={{ display: 'flex' }}>
                                    <FormControl
                                        fullWidth
                                        margin="dense"
                                        error={errors.hasOwnProperty('showEarlyLateTags')}
                                        disabled={
                                            !userHasModifyBasicTravelingPreferencesPermission ||
                                            !this.hasPreferenceChosen('showEarlyLateTags')
                                        }
                                    >
                                        <InputLabel>
                                            {t('route_editor.modal.routing_preferences.modal.show_early_late_tags')}
                                        </InputLabel>
                                        <Select
                                            name="showEarlyLateTags"
                                            value={preferences.showEarlyLateTags}
                                            onChange={this.handleShowEarlyLateTagsChange}
                                        >
                                            {Object.values(ShowEarlyLateTags)
                                                .filter((value) => isNaN(Number(value)))
                                                .map((showEarlyLateTags) => {
                                                    const label = `route_editor.modal.routing_preferences.modal.show_early_late_tags.values.${showEarlyLateTags}.label`;
                                                    return <MenuItem value={showEarlyLateTags}>{t(label)}</MenuItem>;
                                                })}
                                        </Select>
                                        {errors.hasOwnProperty('showEarlyLateTags') && (
                                            <FormHelperText>{errors.get('showEarlyLateTags')}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <QuestionHint style={{ marginTop: 22 }}>
                                        {t('route_editor.modal.routing_preferences.modal.show_early_late_tags.hint')}
                                    </QuestionHint>
                                </div>,
                                t('route_editor.modal.routing_preferences.modal.show_early_late_tags'),
                            )}
                            {this.renderPreferencesBlock(
                                'canJobStartEarlier',
                                <div className="c-form-dialog-route-preference__margin">
                                    <CanJobStartEarlierInput
                                        disabled={
                                            !userHasModifyBasicTravelingPreferencesPermission ||
                                            !this.hasPreferenceChosen('canJobStartEarlier')
                                        }
                                        value={preferences.canJobStartEarlier}
                                        onValueChanged={this.handleTravelingPreferenceChange('canJobStartEarlier')}
                                        onInputValidation={(isValid: boolean, error?: string) =>
                                            this.onInputValidation('canJobStartEarlier', isValid, error)
                                        }
                                        error={errors.get('canJobStartEarlier')}
                                        bareInput={showShortInputs}
                                    />
                                </div>,
                                t('routing_settings.dialog.tabs.users.form_control.can_job_start_earlier.label'),
                            )}
                        </React.Fragment>
                    )}
                    {userHasBasicTravelingPreferencesPermission && userHasAdvancedTravelingPreferencesPermission && (
                        <React.Fragment>
                            <Spoiler
                                style={{ marginTop: 10 }}
                                title={
                                    <span className={classes.advancedPreferencesTitle}>
                                        {t('route_editor.modal.routing_preferences.modal.advanced_preferences')}
                                    </span>
                                }
                            >
                                {this.renderPreferencesBlock(
                                    'departureDelay',
                                    <div className="c-form-dialog-route-preference__default_departure_delay">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <DepartureDelayInput
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('departureDelay')
                                                    }
                                                    value={departureDelayValue}
                                                    onValueChanged={this.handleTravelingPreferenceChange(
                                                        'departureDelay',
                                                    )}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('departureDelay', isValid, error)
                                                    }
                                                    error={errors.get('departureDelay')}
                                                    bareInput={showShortInputs}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('route_editor.modal.routing_preferences.modal.departure_delay'),
                                )}
                                {this.renderPreferencesBlock(
                                    'vehicleType',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__input-block">
                                        <VehicleTypeInput
                                            account={account}
                                            disabled={
                                                !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                !this.hasPreferenceChosen('vehicleType')
                                            }
                                            value={preferences.vehicleType}
                                            onValueChanged={this.handleTravelingPreferenceChange('vehicleType')}
                                            onInputValidation={(isValid: boolean, error?: string) =>
                                                this.onInputValidation('vehicleType', isValid, error)
                                            }
                                            error={errors.get('vehicleType')}
                                            bareInput={showShortInputs}
                                        />
                                    </div>,
                                    t('routing_settings.dialog.tabs.users.form_control.vehicle_type.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'hasSkills',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__input-block">
                                        <HasSkillsInput
                                            account={account}
                                            disabled={
                                                !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                !this.hasPreferenceChosen('hasSkills')
                                            }
                                            value={preferences.hasSkills}
                                            onValueChanged={this.handleTravelingPreferenceChange('hasSkills')}
                                            onInputValidation={(isValid: boolean, error?: string) =>
                                                this.onInputValidation('hasSkills', isValid, error)
                                            }
                                            error={errors.get('hasSkills')}
                                            bareInput={showShortInputs}
                                        />
                                    </div>,
                                    t('routing_settings.dialog.tabs.users.form_control.has_skills.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'maxDistance',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__travelling-advanced-preferences-input">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <MaxDistanceInput
                                                    account={account}
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('maxDistance')
                                                    }
                                                    value={preferences.maxDistance}
                                                    onValueChanged={this.handleTravelingPreferenceChange('maxDistance')}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('maxDistance', isValid, error)
                                                    }
                                                    error={errors.get('maxDistance')}
                                                    bareInput={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('route_editor.modal.routing_preferences.modal.max_distance.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'maxDrivingTime',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__travelling-advanced-preferences-input">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <MaxDrivingTimeInput
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('maxDrivingTime')
                                                    }
                                                    value={preferences.maxDrivingTime}
                                                    onValueChanged={this.handleTravelingPreferenceChange(
                                                        'maxDrivingTime',
                                                    )}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('maxDrivingTime', isValid, error)
                                                    }
                                                    error={errors.get('maxDrivingTime')}
                                                    bareInput={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('route_editor.modal.routing_preferences.modal.max_driving_time.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'maxActivities',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__travelling-advanced-preferences-input">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <MaxActivitiesInput
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('maxActivities')
                                                    }
                                                    value={preferences.maxActivities}
                                                    onValueChanged={this.handleTravelingPreferenceChange(
                                                        'maxActivities',
                                                    )}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('maxActivities', isValid, error)
                                                    }
                                                    error={errors.get('maxActivities')}
                                                    bareInput={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('routing_settings.dialog.tabs.users.form_control.max_activities.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'maxJobs',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__travelling-advanced-preferences-input">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <MaxJobsInput
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('maxJobs')
                                                    }
                                                    value={preferences.maxJobs}
                                                    onValueChanged={this.handleTravelingPreferenceChange('maxJobs')}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('maxJobs', isValid, error)
                                                    }
                                                    error={errors.get('maxJobs')}
                                                    bareInput={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('routing_settings.dialog.tabs.users.form_control.max_jobs.label'),
                                )}
                                {this.renderPreferencesBlock(
                                    'minJobs',
                                    <div className="c-form-dialog-route-preference__margin c-form-dialog-route-preference__travelling-advanced-preferences-input">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <MinJobsInput
                                                    disabled={
                                                        !userHasModifyAdvancedTravelingPreferencesPermission ||
                                                        !this.hasPreferenceChosen('minJobs')
                                                    }
                                                    value={preferences.minJobs}
                                                    onValueChanged={this.handleTravelingPreferenceChange('minJobs')}
                                                    onInputValidation={(isValid: boolean, error?: string) =>
                                                        this.onInputValidation('minJobs', isValid, error)
                                                    }
                                                    error={errors.get('minJobs')}
                                                    bareInput={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>,
                                    t('routing_settings.dialog.tabs.users.form_control.min_jobs.label'),
                                )}
                            </Spoiler>
                        </React.Fragment>
                    )}
                    {mode === TravelingPreferencesMode.PERSONAL && (
                        <SelectMapNavigationOption
                            value={travelingPreferences.mapNavigationOption || ''}
                            onChanged={this.handleNavigateVariantChanged}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const styles = (theme: Theme) => ({
    advancedPreferencesTitle: {
        color: theme.palette.primary.main,
        borderBottom: '1px dotted',
    },
});

export default withTranslation('translations', { withRef: true })(
    withStyles(styles)(observer(TravelingPreferencesForm)),
);
