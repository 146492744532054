import React from 'react';
import PropTypes from 'prop-types';
import { reverse, routes } from '../../routes';
import Link from 'react-router-dom/es/Link';
import { Tooltip } from '@material-ui/core';

class RestrictionMessage extends React.PureComponent {
    render() {
        const { text, tooltip } = this.props;
        const m = typeof text === 'string' ? text.match(/^(.*)<a>(.+)<\/a>(.*)$/) : null;

        const content = (
            <span>
                <i className="fas fa-exclamation-triangle" style={{ marginRight: 8, fontSize: '150%' }} />
                {m === null ? (
                    text
                ) : (
                    <React.Fragment>
                        {m[1]}
                        <Link to={reverse(routes.admin.account.billing.subscribe)}>{m[2]}</Link>
                        {m[3]}
                    </React.Fragment>
                )}
            </span>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                {tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content}
            </div>
        );
    }
}

RestrictionMessage.propTypes = {
    text: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};

export default RestrictionMessage;
