import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormControl, FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Hint from '../../Hint';
import { BreakTypeEnum } from 'service/types';

interface Props extends WithTranslation {
    value: BreakTypeEnum | null;
    onValueChanged: (value: BreakTypeEnum | null) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    bareInput?: boolean;
}

class BreakTypeInput extends React.Component<Props> {
    render() {
        const { t, value, error, disabled, bareInput } = this.props;

        const picklistValues = [
            {
                label: t('route_editor.modal.routing_preferences.modal.break_type.time_window.label'),
                value: BreakTypeEnum.TIME_WINDOW,
            },
        ];

        const stringValue = value === null ? '' : value.toString();

        return (
            <Grid container>
                <Grid item xs>
                    <FormControl fullWidth margin="dense" error={error !== undefined}>
                        {!bareInput && (
                            <InputLabel shrink={true}>
                                {t('route_editor.modal.routing_preferences.modal.break_type.label')}
                            </InputLabel>
                        )}
                        <Select
                            error={!!error}
                            disabled={disabled}
                            fullWidth
                            displayEmpty
                            onChange={this.handleChange}
                            value={stringValue}
                            margin="dense"
                        >
                            <MenuItem key="" value="">
                                <em>{t('route_editor.modal.routing_preferences.modal.break_type.no_break')}</em>
                            </MenuItem>
                            {picklistValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'end', marginBottom: 8, textAlign: 'center' }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>
                        {t('route_editor.modal.routing_preferences.modal.break_type.hint')}
                    </Hint>
                </Grid>
            </Grid>
        );
    }

    private handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { onValueChanged } = this.props;
        let value: BreakTypeEnum | null = e.target.value as BreakTypeEnum;
        if (!Object.values(BreakTypeEnum).includes(value)) {
            value = null;
        }
        onValueChanged(value).then(() => this.validate(value));
    };

    // @ts-ignore unused variable
    private validate = (value: BreakTypeEnum | null): Promise<void> => {
        const { onInputValidation } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };
}

export default withTranslation()(BreakTypeInput);
