export enum SYSTEM_ENTITIES_API_NAMES {
    CALENDAR_EVENT = 'calendar_event',
    CHECK_IN = 'Check_In',
    DEVICE = 'Device',
    FILE = 'File',
    LOCATION_HISTORY = 'Location History',
    ROUTE = 'Route',
    ROUTING_SESSION = 'Routing Session',
    TERRITORY = 'territory',
    TERRITORY_GEOMETRY = 'territory_geometry',
    TERRITORY_GROUP = 'territory_group',
    TRACKING_REPORT = 'TrackingReport',
    USER = 'Mapsly User',
}

export const NO_PIN_SYSTEM_ENTITIES = [
    SYSTEM_ENTITIES_API_NAMES.FILE,
    SYSTEM_ENTITIES_API_NAMES.ROUTE,
    SYSTEM_ENTITIES_API_NAMES.ROUTING_SESSION,
    SYSTEM_ENTITIES_API_NAMES.TERRITORY,
    SYSTEM_ENTITIES_API_NAMES.TERRITORY_GROUP,
    SYSTEM_ENTITIES_API_NAMES.TERRITORY_GEOMETRY,
    SYSTEM_ENTITIES_API_NAMES.TRACKING_REPORT,
];
