import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Hint from 'components/Hint';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';

interface Props extends WithTranslation {
    value: boolean;
    label: string;
    hint: string;
    onValueChanged: (value: boolean) => Promise<void>;
    error?: string | null;
    onInputValidation?: (isValid: boolean, error?: string) => Promise<void>;
    disabled?: boolean;
    disableLabel?: boolean;
}

class ShowBooleanInput extends React.Component<Props> {
    render() {
        const { t, label, hint, value, error, disabled } = this.props;

        return (
            <Grid container>
                <Grid item>
                    <FormControl fullWidth margin="none" error={error !== undefined}>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={value}
                                    onChange={this.handleChange}
                                    color="primary"
                                />
                            }
                            label={t(label)}
                        />
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                    <Hint iProps={{ style: { marginLeft: 8 } }}>{t(hint)}</Hint>
                </Grid>
            </Grid>
        );
    }

    private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { onValueChanged } = this.props;
        const checked = event.target.checked;
        onValueChanged(checked).then(() => this.validate(checked));
    };

    // @ts-ignore unused variable
    private validate = (value: boolean): Promise<void> => {
        const { onInputValidation } = this.props;
        if (!onInputValidation) {
            return Promise.resolve();
        }
        return onInputValidation(true);
    };
}

export default withTranslation()(ShowBooleanInput);
