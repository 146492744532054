import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, FormControl, FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import {
    DEPENDENT_FEATURES_MAP,
    DISABLED_FEATURES_SHARED_MAP,
    DISABLED_MODULES_SHARED_MAP,
    DISABLED_SUB_FEATURES_SHARED_MAP,
    LAYERS_MODULE,
    MAP_MODULE,
    TABLE_MODULE,
    UI_FEATURES_DEFAULT,
} from './constants';
import Confirmation from '../Confirmation';
import { intercomManager } from '../../service/IntercomManager';

class UiFeature extends React.PureComponent {
    handleChangeModule = (e) => {
        let uiFeaturesNew = cloneDeep(this.props.profile.uiFeaturesPermission);
        uiFeaturesNew[this.props.nameModule].enable = e.target.checked;
        this.props.onChange && this.props.onChange(uiFeaturesNew);
    };

    handleChangeFeatures = (feature) => (e) => {
        const enabled = e.target.checked;
        let uiFeaturesNew = cloneDeep(this.props.profile.uiFeaturesPermission);
        uiFeaturesNew[this.props.nameModule].features[feature].enable = enabled;
        if (!enabled) {
            const dependants = DEPENDENT_FEATURES_MAP.filter((item) =>
                item.dependsOn.find((dep) => dep.module === this.props.nameModule && dep.feature === feature),
            );
            dependants.forEach((item) => {
                if (item.subFeature) {
                    uiFeaturesNew[item.module].features[item.feature].subFeatures[item.subFeature].enable = false;
                } else {
                    uiFeaturesNew[item.module].features[item.feature].enable = false;
                }
            });

            Object.keys(uiFeaturesNew[this.props.nameModule].features[feature].subFeatures || []).forEach(
                (subFeature) => {
                    uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].enable = false;

                    Object.keys(
                        uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].subSubFeatures ||
                            [],
                    ).forEach((subSubFeature) => {
                        uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].subSubFeatures[
                            subSubFeature
                        ].enable = false;
                    });
                },
            );
        }
        if (enabled && feature === MAP_MODULE.FEATURES.RIGHT_CLICK.NAME) {
            uiFeaturesNew[MAP_MODULE.NAME].features[MAP_MODULE.FEATURES.RIGHT_CLICK.NAME].subFeatures[
                MAP_MODULE.FEATURES.RIGHT_CLICK.SUB_FEATURES.CREATE_RECORD_FROM_MAP.NAME
            ].enable = true;
        }
        this.props.onChange && this.props.onChange(uiFeaturesNew);
        if (enabled && feature === MAP_MODULE.FEATURES.SEARCH_FILTER.NAME) {
            const { formId, formIdIsInvalid, invalidSearchBarFields } = this.getInvalidFields();
            const doOpen = !formId || formIdIsInvalid || invalidSearchBarFields.length > 0;
            if (!doOpen) {
                return;
            }
            this.handleOpenSearchFilter(formId, invalidSearchBarFields, formIdIsInvalid);
        }
    };

    getInvalidFields() {
        const { formId, searchBarFields } = this.props.profile;
        const invalidSearchBarFields = [];
        let formIdIsInvalid = false;
        if (formId) {
            const form = this.props.forms.find((form) => form.id === formId);
            if (form) {
                const picklists = form.details.fields.filter((field) => field.fieldType === 'picklist');
                for (let searchBarField of searchBarFields) {
                    const found = picklists.find((field) => field.apiName === searchBarField);
                    if (!found) {
                        invalidSearchBarFields.push(searchBarField);
                    }
                }
            } else {
                formIdIsInvalid = true;
            }
        }
        return { formId, formIdIsInvalid, invalidSearchBarFields };
    }

    handleChangeSubFeatures = (feature, subFeature) => (e) => {
        const enabled = e.target.checked;
        let uiFeaturesNew = cloneDeep(this.props.profile.uiFeaturesPermission);
        uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].enable = enabled;
        if (!enabled) {
            const dependants = DEPENDENT_FEATURES_MAP.filter((item) =>
                item.dependsOn.find(
                    (dep) =>
                        dep.module === this.props.nameModule &&
                        dep.feature === feature &&
                        dep.subFeature === subFeature,
                ),
            );
            dependants.forEach((item) => {
                if (item.subFeature) {
                    uiFeaturesNew[item.module].features[item.feature].subFeatures[item.subFeature].enable = false;
                } else {
                    uiFeaturesNew[item.module].features[item.feature].enable = false;
                }
            });

            Object.keys(
                uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].subSubFeatures || [],
            ).forEach((subSubFeature) => {
                uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].subSubFeatures[
                    subSubFeature
                ].enable = false;
            });
        }
        this.props.onChange && this.props.onChange(uiFeaturesNew);
    };

    handleChangeSubSubFeatures = (feature, subFeature, subSubFeature) => (e) => {
        const enabled = e.target.checked;
        let uiFeaturesNew = cloneDeep(this.props.profile.uiFeaturesPermission);
        uiFeaturesNew[this.props.nameModule].features[feature].subFeatures[subFeature].subSubFeatures[
            subSubFeature
        ].enable = enabled;

        this.props.onChange && this.props.onChange(uiFeaturesNew);
    };

    contactSupport = () => {
        intercomManager.showWidget();
    };

    handleOpenSearchFilter = (formId, invalidFields, isInvalidForm) => {
        if (formId === undefined) {
            const { formIdIsInvalid, invalidSearchBarFields, formId } = this.getInvalidFields();
            this.props.onChangeSearchFilterModal({
                show: true,
                invalidSearchBarFields,
                formIdIsInvalid,
                formId,
            });
            return;
        }

        this.props.onChangeSearchFilterModal({
            show: true,
            invalidSearchBarFields: invalidFields,
            formIdIsInvalid: isInvalidForm,
            formId,
        });
    };

    render() {
        const { profile, nameModule, t, subscription } = this.props;
        const uiFeaturesPermission = profile.uiFeaturesPermission;
        const dataModule = uiFeaturesPermission[nameModule];
        const sharedModuleDisabled =
            profile.forSharedMap && DISABLED_MODULES_SHARED_MAP.includes(nameModule)
                ? t('shared_map.feature.disabled')
                : null;
        const sharedMapAllowLayers = profile.forSharedMap && subscription ? subscription.sharedMapAllowLayers : true;
        const sharedMapAllowTable = profile.forSharedMap && subscription ? subscription.sharedMapAllowTable : true;
        let moduleDisabledOrganization = null;
        if (nameModule === TABLE_MODULE.NAME) {
            moduleDisabledOrganization = sharedMapAllowTable === false ? t('shared_map.feature.table.disabled') : null;
        } else if (nameModule === LAYERS_MODULE.NAME) {
            moduleDisabledOrganization =
                sharedMapAllowLayers === false ? t('shared_map.feature.layers.disabled') : null;
        }

        return (
            <React.Fragment>
                <Grid item container justifyContent="center" className="c-permissions_ui_features">
                    {dataModule.allowDisableModule && (
                        <Grid item>
                            {null !== moduleDisabledOrganization ? (
                                <Confirmation
                                    text={moduleDisabledOrganization}
                                    textYes={t('shared_map.feature.disabled.contact')}
                                    textNo={t('close')}
                                    onConfirm={this.contactSupport}
                                >
                                    <FormControlLabel
                                        className="c-permissions_ui_features__switch"
                                        margin="dense"
                                        control={<Switch checked={false} color="primary" />}
                                        label=""
                                    />
                                </Confirmation>
                            ) : (
                                <Tooltip title={sharedModuleDisabled || ''}>
                                    <FormControlLabel
                                        className="c-permissions_ui_features__switch"
                                        margin="dense"
                                        control={
                                            <Switch
                                                onChange={this.handleChangeModule}
                                                checked={sharedModuleDisabled !== null ? false : dataModule.enable}
                                                color="primary"
                                                disabled={sharedModuleDisabled !== null}
                                                data-testid="shared_map.feature.change_module"
                                            />
                                        }
                                        label=""
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                    )}
                </Grid>
                {dataModule.enable &&
                    Object.keys(UI_FEATURES_DEFAULT[nameModule].features).map((feature, idx) => {
                        const dataFeature = dataModule.features[feature];
                        if (dataFeature.removed !== undefined) {
                            return null;
                        }
                        const dependsOn = DEPENDENT_FEATURES_MAP.find(
                            (item) => item.module === nameModule && item.feature === feature,
                        )?.dependsOn;
                        const dependentFeatureDisabled =
                            dependsOn?.reduce((disabled, item) => {
                                if (disabled) {
                                    return true;
                                }
                                if (!uiFeaturesPermission[item.module]?.features[item.feature]?.enable) {
                                    return true;
                                }
                                if (
                                    item.subFeature &&
                                    !uiFeaturesPermission[item.module]?.features[item.feature]?.subFeatures?.[
                                        item.subFeature
                                    ]?.enable
                                ) {
                                    return true;
                                }
                                return false;
                            }, false) ?? false;
                        const sharedFeatureDisabled =
                            profile.forSharedMap &&
                            DISABLED_FEATURES_SHARED_MAP.find((item) => item.feature === feature)
                                ? t('shared_map.feature.disabled')
                                : null;

                        return (
                            <React.Fragment key={`${idx}/${profile.code}/${feature}`}>
                                <Grid container className="c-permissions_ui_features">
                                    <Grid container item alignItems="center">
                                        <Grid item>
                                            <Tooltip title={sharedFeatureDisabled || ''}>
                                                <FormControl margin="dense">
                                                    <Checkbox
                                                        onChange={this.handleChangeFeatures(feature)}
                                                        checked={dataFeature.enable}
                                                        color="primary"
                                                        disabled={
                                                            sharedFeatureDisabled !== null || dependentFeatureDisabled
                                                        }
                                                    />
                                                </FormControl>
                                            </Tooltip>
                                        </Grid>
                                        <Grid>
                                            {feature === MAP_MODULE.FEATURES.SEARCH_FILTER.NAME &&
                                                dataFeature.enable && (
                                                    <button
                                                        onClick={() => this.handleOpenSearchFilter()}
                                                        className="btn-link"
                                                        data-testid="search_filters.edit"
                                                    >
                                                        {t('search_filters.edit')}
                                                    </button>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {dataFeature.subFeatures &&
                                    Object.keys(
                                        UI_FEATURES_DEFAULT[nameModule].features[feature].subFeatures || [],
                                    ).map((subFeature, jdx) => {
                                        if (!dataFeature.enable) {
                                            return (
                                                <Grid
                                                    key={`${idx}_${jdx}_${profile.code}_${subFeature}`}
                                                    container
                                                    className="c-permissions_ui_features"
                                                >
                                                    <Grid item style={{ height: 36 }}></Grid>
                                                </Grid>
                                            );
                                        }
                                        const dataSubFeature = dataFeature.subFeatures[subFeature];
                                        if (dataSubFeature.removed !== undefined) {
                                            return null;
                                        }
                                        const dependsOn = DEPENDENT_FEATURES_MAP.find(
                                            (item) =>
                                                item.module === nameModule &&
                                                item.feature === feature &&
                                                item.subFeature === subFeature,
                                        )?.dependsOn;
                                        const dependentFeatureDisabled =
                                            dependsOn?.reduce((disabled, item) => {
                                                if (disabled) {
                                                    return true;
                                                }
                                                if (
                                                    !uiFeaturesPermission[item.module]?.features[item.feature]?.enable
                                                ) {
                                                    return true;
                                                }
                                                if (
                                                    item.subFeature &&
                                                    !uiFeaturesPermission[item.module]?.features[item.feature]
                                                        ?.subFeatures?.[item.subFeature]?.enable
                                                ) {
                                                    return true;
                                                }
                                                return false;
                                            }, false) ?? false;
                                        if (dataSubFeature.enable && dependentFeatureDisabled) {
                                            this.handleChangeSubFeatures(feature, subFeature);
                                        }
                                        const sharedSubFeatureDisabled =
                                            profile.forSharedMap &&
                                            DISABLED_SUB_FEATURES_SHARED_MAP.find((item) => item.feature === feature)
                                                ? t('shared_map.feature.disabled')
                                                : null;
                                        return (
                                            <React.Fragment key={`${profile.code}_${subFeature}`}>
                                                <Grid container className="c-permissions_ui_features">
                                                    <Grid item>
                                                        <Tooltip title={sharedSubFeatureDisabled || ''}>
                                                            <FormControl margin="dense">
                                                                <Checkbox
                                                                    onChange={this.handleChangeSubFeatures(
                                                                        feature,
                                                                        subFeature,
                                                                    )}
                                                                    checked={dataSubFeature.enable}
                                                                    color="primary"
                                                                    disabled={
                                                                        sharedSubFeatureDisabled ||
                                                                        dependentFeatureDisabled
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                {dataFeature.subFeatures[subFeature].subSubFeatures &&
                                                    Object.keys(
                                                        UI_FEATURES_DEFAULT[nameModule].features[feature].subFeatures[
                                                            subFeature
                                                        ].subSubFeatures || [],
                                                    ).map((subSubFeature) => {
                                                        if (!dataFeature.subFeatures[subFeature].enable) {
                                                            return (
                                                                <Grid
                                                                    container
                                                                    className="c-permissions_ui_features"
                                                                    key={profile.code + '_' + subSubFeature}
                                                                >
                                                                    <Grid item style={{ height: 36 }}></Grid>
                                                                </Grid>
                                                            );
                                                        }
                                                        const dataSubSubFeature =
                                                            dataFeature.subFeatures[subFeature].subSubFeatures[
                                                                subSubFeature
                                                            ];
                                                        if (dataSubSubFeature.removed !== undefined) {
                                                            return null;
                                                        }

                                                        return (
                                                            <Grid
                                                                container
                                                                className="c-permissions_ui_features"
                                                                key={profile.code + '_' + subSubFeature}
                                                            >
                                                                <Grid item>
                                                                    <Tooltip title={''}>
                                                                        <FormControl margin="dense">
                                                                            <Checkbox
                                                                                onChange={this.handleChangeSubSubFeatures(
                                                                                    feature,
                                                                                    subFeature,
                                                                                    subSubFeature,
                                                                                )}
                                                                                checked={dataSubSubFeature.enable}
                                                                                color="primary"
                                                                                disabled={false}
                                                                            />
                                                                        </FormControl>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        );
                                    })}
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}

UiFeature.propTypes = {
    nameModule: PropTypes.string.isRequired,
    profile: PropTypes.object,
    onChange: PropTypes.func,
    subscription: PropTypes.object,
    forms: PropTypes.array,
    dataSources: PropTypes.array,
};

export default withTranslation()(UiFeature);
